import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CuentaCobrarService } from 'src/app/services/api/cuentaxcobrar.service';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';

class FiltroCuentaCobrar {
  auxiliar: string;
  moneda: string;
  fechaMax: Date;
}

@Component({
  selector: 'app-cuenta-cobrar-dialog',
  templateUrl: './cuenta-cobrar-dialog.component.html',
  styleUrls: ['./cuenta-cobrar-dialog.component.css']
})
export class CuentaCobrarDialogComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['select', 'cta_cte', 'tip_doc', 'nro_docu', 'fec_emision', 'fec_vencimiento', 'glosa', 'moneda', 'importe_documento', 'importe_abono', 'importe_saldo', 'cuenta', 'auxiliar', 'ind_estado', 'tipo_cambio', 'ind_signo', 'periodo'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public driver$: Subscription;

  proveedores: any[] = [];

  importeMN: any = 0;
  importeME: any = 0;

  fechaActual: Date;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  selection = new SelectionModel<any>(true, []);

  cliente: any;

  year: any;
  month: any;

  moneda: any;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  filtro: FiltroCuentaCobrar;

  loaderData: boolean;

  constructor(
    private _cuentaxCobrar: CuentaCobrarService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<CuentaCobrarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.fechaActual = new Date();

    this.filtro = new FiltroCuentaCobrar();
  }

  ngOnInit(): void {

    this.proveedores = this.data.clientes;
    this.filtro.auxiliar = this.data.cli_codcli;
    this.obtenerCuentasxCobrar(this.filtro.auxiliar);

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {

    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  calculaTotales(): void {
    console.log('calculandoTotales')
    this.importeMN = 0;
    this.importeME = 0;
    console.log(this.selection.selected)
    this.selection.selected.forEach(item => {

      this.importeMN += item.importe_saldo;
      this.importeME += item.importe_saldo * item.tipo_cambio;
    });

    this.importeMN = this.importeMN.toFixed(2);
    this.importeME = this.importeME.toFixed(2);
  }

  obtenerCuentasxCobrar(cliente): void {
    this.loaderData = true;

    this.driver$ = this._cuentaxCobrar.obtenerCuentaxCobrar(cliente, this.data.periodo).subscribe(
      response => {

        const cuentasPorCobrar = response.filter(item => (item.importe_saldo != 0) )


        this.dataSource = fillTable(cuentasPorCobrar, this.paginator, this.sort)
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )
  }

  buscar(): void {
    this.obtenerCuentasxCobrar(this.filtro.auxiliar ? this.filtro.auxiliar : '');
  }

  aceptar(): void {
    this.dialogRef.close(this.selection.selected)

  }

  seleccionarCliente(cliente): void {
    this.obtenerCuentasxCobrar(cliente);
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.driver$,
      this.period$
    ])
  }


}
