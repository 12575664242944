export class OrdenProduccion {
  tipoDocumento: string;
  fecha: Date;
  estado: string;
  numero: string;
  pedido: string;
  centro: string;
  producto: string;
  cantidad: number;
  unidadMedida: string;
  situacion: string;
  formula: string;
  ruta: string;
  detalle_material: Array<Material>;

  constructor() {
    this.detalle_material = [];
  }

}

export class Material {
  codigo: string;
  codigo_laboratorio: string;
  descripcion: string;
  um: string;
  cantidad: number;
  seleccionado: boolean;

  detalle_envasado: Array<Envasado>;

  constructor() {
    this.detalle_envasado = [];
    this.seleccionado = false;
  }
}

export class Envasado {
  codigo: string;
  descripcion: string;
  um: string;
  cantidad: number;
  distribucion: string;
  enCaja: string;
  orden: string;
  fecha_fabricacion: Date;
}
