export const REPORTES_CAJA_Y_BANCOS = [

  {
    id: "CAJA_BANCO_MCBSI",
    name: "Movimiento de caja y bancos con saldo inicial",
    esDinamico: false,
  },

];

export const REPORTES_DOCUMENTOS_POR_PAGAR = [
  {
    id: "CAJA_BANCO_CPTDS",
    name: "Cuentas por pagar tabulado **doc. con saldo",
    esDinamico: false,
  },
  {
    id: "CAJA_BANCO_CPDS",
    name: "Cuentas por pagar **doc. con saldo",
    esDinamico: false,
  },
  {
    id: "CAJA_BANCO_ECP",
    name: "Estado de cuenta por proveedor",
    esDinamico: false,
  },
  {
    id: "CAJA_BANCO_PEP",
    name: "Pagos efectuados por proveedor",
    esDinamico: false,
  },
  {
    id: "CAJA_BANCO_SPFC",
    name: "Saldos por pagar a fecha corte",
    esDinamico: false,
  },
]

export const REPORTES_FLUJO_CAJA = [
  {
    id: "CAJA_BANCO_AFCC",
    name: "Acumulados flujo caja por categoría",
    esDinamico: false,
  },
  {
    id: "CAJA_BANCO_DFC",
    name: "Detallado flujo caja",
    esDinamico: false,
  },
]

export const REPORTES_CUENTAS_PAGAR_DINAMICOS = [
  {
    id: "CAJA_BANCO_DP",
    name: "Documentos por pagar",
    s26_grupo: "42001",
    codigo: '001',
    esDinamico: true
  },
  {
    id: "CAJA_BANCO_DPAGOS",
    name: "Detallado de pagos",
    s26_grupo: "42002",
    codigo: '002',
    esDinamico: true
  },
]
