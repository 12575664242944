import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { cambiar } from "../../../shared/state/actions/period.actions";
import { Subscription } from "rxjs";
import { ModuleService } from "src/app/services";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { DialogService } from "@shared/services/dialog.service";
import { cambiarLoadingModule } from "@shared/state/actions/loading.actions";
import { SelectQueryPeriodComponent } from "@shared/components/dialogs/select-query-period/select-query-period.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { codigoModulo } from "@data/constants/component/codigo-modulo";

@Component({
  selector: 'app-planilla',
  templateUrl: 'planilla.component.html',
  styleUrls: ['planilla.component.scss']
})
export class PlanillaComponent implements OnInit, OnDestroy {

  CODE_MODULE: string = codigoModulo.planilla_modules_key;
  module$: Subscription;

  year: string;
  month: string;

  period$: Subscription;
  loading$: Subscription;
  nomina$: Subscription;

  lastCodMod: string;

  tipoNomina: string;
  secNomina: string;

  isAllOk: boolean = false;

  // STEPPER
  firstFormGroup: FormGroup = this._formBuilder.group({ firstCtrl: [''] });
  secondFormGroup: FormGroup = this._formBuilder.group({ secondCtrl: [''] });

  mostrarStepper = false;

  constructor(
    private store: Store<PavsoState>,
    private _moduleService: ModuleService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
      this.lastCodMod = state.codMod;
    })

    this.nomina$ = this.store.select('nomina').subscribe(state => {
      this.tipoNomina = state.typeNomina;
      this.secNomina = state.secNomina;

      if (this.tipoNomina && this.secNomina) this.isAllOk = true;

    })

    console.log('lastCodMod', this.lastCodMod)

    // this.CODE_MODULE = localStorage.getItem('codeModule');
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav &&!state.isLoadingEstablishment && state.isLoadingModule) this.establecerPeriodo();
    })

    console.log('modulo planilla')
  }

  establecerPeriodo(): void {
    if (this.CODE_MODULE != "00") {
      console.log('codigo modulo PLANILLA', this.CODE_MODULE)
      this.module$ = this._moduleService.obtenerPeriodoAbierto("01", this.CODE_MODULE).subscribe(
        response => {
          console.log('PERIODO ABIERTO PLANILLA', response)
          if (this.lastCodMod != 'W51') this._dialogService.openDialog(SelectQueryPeriodComponent, {}, '400px', 'auto', '');

          this.store.dispatch(cambiar({ year: response.ANO_CODANO, month: response.MES_CODMES, openingYear: response.ANO_CODANO, openingMonth: response.MES_CODMES, periodos: response.periodos, codMod: this.CODE_MODULE }))
          this.store.dispatch(cambiarLoadingModule({ isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: false, isLoadingTypeNominaDialog: true }))
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      );
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.module$, this.period$, this.loading$]);
  }

}
