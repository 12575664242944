<h1 mat-dialog-title>
    Detalle Cuota
    <button mat-icon-button class="btn-close" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h1>


<mat-list>
    <mat-list-item class="mat-elevation-z0">
        <span mat-line>MONTO TOTAL: S/ {{data.ocm_valvta}}</span>
    </mat-list-item>
    <br>
    <mat-list-item class="mat-elevation-z0" *ngIf="items.length == 0">
        <span mat-line>Sin cuotas</span>
    </mat-list-item>
    <mat-list-item class="mat-elevation-z0" *ngFor="let item of items">
        <span mat-line>MONTO: S/ {{item.OCE_IMPCUO}}</span>
        <div mat-line> {{item.OCE_FECPAG | date: 'dd/MM/yyyy': 'UTC'}} </div>
        <button mat-icon-button color="warn" (click)="eliminarItem(item.id)">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-list-item>
</mat-list>

<br>
<form #f="ngForm" ngNativeValidate (ngSubmit)="agregarCuota(f)" (keydown.enter)="$event.preventDefault()">
    <div class="row">
        <div class="col s6">
            <mat-form-field>
                <mat-label>Monto</mat-label>
                <input matInput placeholder="Monto" type="number" [(ngModel)]="monto" name="monto" required>
            </mat-form-field>
        </div>
        <div class="col s6">
            <mat-form-field>
                <mat-label>Fecha Pago</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="fecha" name="fecha" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col s12">
            <mat-form-field>
                <mat-label>Descripción</mat-label>
                <textarea matInput placeholder="Descripción" [(ngModel)]="descripcion" name="descripcion" required></textarea>
            </mat-form-field>
        </div>
        <div class="col s12">
            <button mat-flat-button type="submit">Agregar</button>
        </div>
    </div>
</form>
