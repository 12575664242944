<app-pavso-title-section [title]="'Seguimiento al cliente'" [module]="'Cuentas por cobrar'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarSeguimiento(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cliente</mat-label>
            <mat-select required n  ame="cliente">
              <mat-option *ngFor="let cliente of clientes" [value]="cliente.as">
                {{cliente.sdf}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">

        </div>
      </div>
    </mat-card>
    <br>

    <div class="row">
      <div class="col s12">
        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Tabla</div>
        </div>
        <div class="mat-elevation-z0 overflow-x">

          <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button matTooltip="Editar"
                  [routerLink]="['/modulo-cobranzas/editar-cliente-comercial', row.cli_codcli]" mat-icon-button
                  color="primary">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
              <td mat-cell *matCellDef="let row" data-label="Item"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="tipo_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Tip. Doc."> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="nro_documento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Documento"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_registro">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Registro </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Registro"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="observacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Observación </th>
              <td mat-cell *matCellDef="let row" data-label="Observación"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_actividad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Actividad </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Actividad"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="vb">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> V.B. </th>
              <td mat-cell *matCellDef="let row" data-label="V.B."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="situacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
              <td mat-cell *matCellDef="let row" data-label="Situación"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="10">No se encontraron registros</td>

            </tr>

          </table>

        </div>
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col s12">
        <button mat-fab color="primary">
          <mat-icon>add_to_photos</mat-icon>
        </button>
      </div>
    </div>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>


  </form>
</div>
