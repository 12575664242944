import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { DocumentoCtaCte } from 'src/app/models';
import { AuthenticationService, ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-config-control-create',
  templateUrl: './config-control-create.component.html',
  styleUrls: ['./config-control-create.component.css']
})
export class ConfigControlCreateComponent implements OnInit {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  cuentas: any[] = [];
  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  configuracion: DocumentoCtaCte;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {
    this.configuracion = new DocumentoCtaCte();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=> {
      this.uid = id;

      if(this.uid == '0') return;
      this.loadMaestros();
    })
  }

  loadMaestros(): void {

    this._contabilidadService.listarPlanDeCuentas().subscribe(
      cuentas => {
        this.cuentas = cuentas;
        this._contabilidadService.obtenerTiposDocumento().subscribe(
          tipos => {
            this.tiposDocumento = tipos;
          },
          error => {
            this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener plan de cuentas', 'OK');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarConfiguracion(f): this.actualizarConfiguracion(f);
  }

  registrarConfiguracion(f: NgForm): void {

    this._contabilidadService.registrarDocumentoCtaCte(this.configuracion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Documento Cta. Cte. registrado', '400px', '400px', '');
        f.resetForm();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarConfiguracion(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/configuraciones-control-cta-cte']);
  }

}
