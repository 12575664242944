import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { Order } from "src/app/models";
import { IParam } from "src/app/services/api/utilities/params";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";
import { PedidoService } from "src/app/services/api/ventas/operaciones/pedido.service";

@Component({
  selector: 'historial-precios-dialog',
  templateUrl: './historial-precios.dialog.html',
  styleUrls: ['./historial-precios.dialog.scss']
})
export class HistorialPreciosDialog implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'Cod_Vendedor', 'Nom_vendedor', 'Telef_vendedor', 'Mail_Vendedor', 'Usuario_Reg'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  clientes: any[] = [];
  productos: any[] = [];
  pedidos: any[] = [];
  loaderData: boolean;

  periodo$: Subscription;

  anioActividad: string;
  mesActividad: string;

  constructor(
    public dialogRef: MatDialogRef<HistorialPreciosDialog>,
    @Inject(MAT_DIALOG_DATA) public order: Order,
    private _productoService: ProductoService,
    private _clienteService: ClienteService,
    private _pedidoService: PedidoService,
    private _snackbarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.periodo$ = this.store.select('period').subscribe(({ year, month }) => {
      this.anioActividad = year;
      this.mesActividad = month;
    })

  }
  ngOnInit(): void {
    this.loadMaestros();
  }

  productos$: Subscription;
  clientes$: Subscription;
  pedidos$: Subscription;

  loadMaestros(): void {
    this.loaderData = true;
    this.productos$ = this._productoService.obtenerProductos().subscribe(
      productos => {
        this.productos = productos;

        this.clientes$ = this._clienteService.obtenerClientes().subscribe(
          clientes => {
            this.clientes = clientes;
            let params: Array<IParam> = [
              {
                param: 'anio',
                value: this.anioActividad
              },
              {
                param: 'mes',
                value: this.mesActividad
              },
            ];
            this.pedidos$ = this._pedidoService.obtenerPedidos('01', {params}).subscribe(
              pedidos => {
                this.pedidos = pedidos;

                if (this.order.CLI_CODCLI) {
                  this.pedidos = this.pedidos.filter(item => item.CLI_CODCLI == this.order.CLI_CODCLI);
                }
                this.dataSource = fillTable(this.pedidos, this.paginator, this.sort);

                this.loaderData = false;

              },
              error => {
                this._snackbarService.showError('Error al obtener pedidos', 'Ok');
              }
            )
          },
          error => {
            this._snackbarService.showError('Error al obtener clientes', 'Ok');
          }
        )
      },
      error => {
        this._snackbarService.showError('Error al obtener productos', 'Ok');
      }
    )

  }

  seleccionarCliente(value): void {

  }

  cerrar(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {

  }

}
