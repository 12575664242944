import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { Subscription } from "rxjs";
import { ConfirmBillCreateComponent } from "../../confirm-bill-create/confirm-bill-create.component";
import * as XLSX from 'xlsx';
import { SnackBarService } from "@shared/services/snackbar.service";

@Component({
  selector: 'cargar-excel-nomina-dialog',
  templateUrl: './cargar-excel-nomina.component.html',
  styleUrls: ['./cargar-excel-nomina.component.scss']
})
export class CargarExcelNominaDialog implements OnInit{
  @Input()
  requiredFileType:string = ".xlsx, .xls, .csv";

  fileName = '';
  uploadProgress:number;
  uploadSub: Subscription;

  buttonName: INameConstant = NAMES_CONSTANTS;

  jsonToSend: any;

  constructor(
    public dialogRef: MatDialogRef<ConfirmBillCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private _snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  subirExcel(): void {
    if(this.jsonToSend["Hoja 1"].length > 1) {

      let contentJson = this.jsonToSend["Hoja 1"];

      let jsonFormatted = [];
      contentJson.forEach((element, key) => {
        if(key == 1) {
          jsonFormatted.push({
            codigo: element.__EMPTY,
            nombre: `${element.__EMPTY_1} ${element.__EMPTY_2} ${element.__EMPTY_3}`,
            importe: element.__EMPTY_10,
            cantidad: element.__EMPTY_11,
            unidad_pago: 0,
            nmt_canhrs: element.__EMPTY_6,
            nmt_canmin: element.__EMPTY_7
          })
        }
      });
      this.dialogRef.close(jsonFormatted);

      return
    }

    this._snackBarService.showError('El Excel debe de tener contenido', 'OK');
  }

  verModelo(): void {
    window.open("https://docs.google.com/spreadsheets/d/1wXnlf6iByjOjcA3OyY3nQeNwDPk3ElRmidyykBM6uXg/edit?usp=sharing", "_blank");
  }

  onFileSelected(event) {
    const file:File = event.target.files[0];

    // excel to JSON
    if(file) {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        this.jsonToSend = jsonData;
      };
      reader.readAsBinaryString(file);

      this.fileName = file.name;
    }
  }

  cancelUpload() {
  this.uploadSub.unsubscribe();
  this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
}
