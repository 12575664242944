import { Injectable, OnDestroy } from "@angular/core";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithScript } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import * as pdfBuilder from "@utils/pdfmaker/builder/caja_banco.pdfmaker";
import { Subscription } from "rxjs";
import { CajaBancoService } from "../../api/cajabanco.service";
import { ConfigurationService } from "../../configuration.service";
import { CuentaPagarExcelService } from "../../reporte-excel/cuenta-pagar-excel.service";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { T } from "@angular/cdk/keycodes";

@Injectable({
  providedIn: 'root'
})
export class TesoreriaReportService implements OnDestroy {

  reporte$: Subscription;
  period$: Subscription;
  year: string;
  month: string;

  constructor(
    private _snackBarService: SnackBarService,
    private _cajaBancoService: CajaBancoService,
    private _configurationService: ConfigurationService,
    public ete: CuentaPagarExcelService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporte(reportSelected: string, headers: any, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "CAJA_BANCO_AFCC":
          console.log('acumulado por categoria')

          this.reporte$ = this._cajaBancoService.obtenerReporteAcumuladoFlujoCajaPorCategoria(headers.year, headers.currency, payload).subscribe(
            response => {
              if (response.data.length > 0) {
                pdfBuilder.reporteAcumuladoFlujoCajaPorCategoria(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte acumulado flujo de caja por categoría", "OK");
              reject(false);
            }
          )
          break;
        case "CAJA_BANCO_DFC":
          this.reporte$ = this._cajaBancoService.obtenerReporteDetalladoFlujoCaja(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.data.length > 0) {
                pdfBuilder.reporteDetalladoFlujoCaja(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de detallado de flujo de caja', 'OK');
              reject(false);
            }
          )
          break;
        case "CAJA_BANCO_MCBSI":
          this.reporte$ = this._cajaBancoService.obtenerReporteMovimientoCajaBancoSaldoInicial(headers.year, headers.month).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.reporteMovimientoCajaBancoSaldoInicial(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de movimiento de caja y banco saldo inicial", "OK");
              reject(false);
            }
          )
          break;
        case "CAJA_BANCO_CPTDS":
          this.reporte$ = this._cajaBancoService.obtenerReporteCuentaPagarTabuladoConSaldo(payload).subscribe(
            response => {
              console.log('reporte tabulado', response)
              pdfBuilder.reporteCuentaPagarTabuladoConSaldo(headers, response);
              resolve(true);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de cuenta por pagar tabulado con saldo", "OK");
              reject(false);
            }
          )
          break;
        case "CAJA_BANCO_CPDS":
          this.reporte$ = this._cajaBancoService.obtenerReporteCuentaPagarConSaldo(payload).subscribe(
            response => {
              console.log('reporte tabulado', response)
              pdfBuilder.reporteCuentaPagarConSaldo(headers, response);
              resolve(true);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de cuenta por pagar tabulado con saldo", "OK");
              reject(false);
            }
          )
          break;
        case "CAJA_BANCO_SPFC":
          if (!otherData.fechaRuta) {
            this._snackBarService.showError("Establecer la fecha de ruta", "OK");
            reject(false);
            return;
          }

          this.reporte$ = this._cajaBancoService.obtenerReporteSaldoPagarFechaCorteDocSaldo(formatDateWithScript(otherData.fechaRuta), payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.reporteSaldoPagarFechaCorteDocSaldo(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para esta fecha ruta ${formatDateWithScript(otherData.fechaRuta)}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de saldo por pagar fecha de corde Doc Saldo", "OK");
              reject(false);
            }
          )
          break;
        case "CAJA_BANCO_ECP":
          this.reporte$ = this._cajaBancoService.obtenerReporteEstadoCuentaPorProveedor(payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.reporteEstadoCuentaProveedor(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year}`, "OK");
              reject(false);

            },
            error => {
              reject(false)
            }
          )
          break;
        case "CAJA_BANCO_PEP":
          this.reporte$ = this._cajaBancoService.obtenerReportePagosEfectuadosProveedor(headers.year, headers.month, payload).subscribe(
            data => {
              if (data.length > 0) {
                pdfBuilder.reportePagosEfectuadosProveedor(headers, data);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período o filtro`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de pagos efectuados por proveedor", "OK");
              reject(false);
            }
          )
          break;
        case "CAJA_BANCO_TCP":
          resolve(true);
          break;
        default:
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporteDinamico(reportSelected: string, headers: any, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case 'CAJA_BANCO_DP':
          this.reporte$ = this._cajaBancoService.obtenerReporteDinamicoDocumentoPagar(payload).subscribe(
            data => {
              console.log('informe de documentos cobrar dinamico', data)
              if (data.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              // Filtrados por indsta == 1
              const filtrados = data.filter(item => item.cco_indsta == '1')

              const columnasSeleccionadas = filtrados.map(item => {

                let obj = {}

                otherData.columns.forEach(element => {
                  if (element.completed) {
                    obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
                  }
                })

                return obj;

              })


              let reportData = {
                title: `Informe de documentos por pagar ${this.year} ${this.month}`,
                data: columnasSeleccionadas,
              }

              this.ete.exportExcelDocumentosPorPagar(reportData);

              resolve(true)
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte acumulado flujo de caja por categoría", "OK");
              reject(false);
            }
          )
          break;
        case 'CAJA_BANCO_DPAGOS':
          this.reporte$ = this._cajaBancoService.obtenerReporteDinamicoDetalladoPagos(this.year, this.month, otherData.payload).subscribe(
            data => {
              console.log('informe de documentos cobrar dinamico', data)
              if (data.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              const columnasSeleccionadas = data.map(item => {

                let obj = {}

                otherData.columns.forEach(element => {
                  if (element.completed) {
                    obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
                  }
                })

                return obj;

              })


              let reportData = {
                title: `Informe de detallado de pagos ${this.year} ${this.month}`,
                data: columnasSeleccionadas,
              }

              this.ete.exportExcelDetalladoPagos(reportData);

              resolve(true)
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte dinámico de documentos por cobrar", "OK");
              reject(false);
            }
          )
          break;

        default:
          break;
      }
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([

      this.reporte$
    ]);
  }

}
