import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class IdentificationGuard implements CanActivate {
  constructor(
    public authService: AuthenticationService,
    public router: Router
  ) {}

  canActivate() {

      if ( this.authService.estaIdentificado() ) return true;

      this.router.navigate(['/ingresar-parametros']);
      return false;

  }

}
