<app-pavso-title-section [title]="'Guías de remisión'" [module]="'Almacén'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <select-search [label]="'Cliente'" [placeholder]="'Ej. Sandro'" [value]="'cli_codcli'"
          [name]="'cli_codcli'" [description]="'description'" [disabled]="loaderData" [data]="clientes"
          (cambioSelect)="filtro.cliente = $event;" [model]="filtro.cliente">
        </select-search>
      </div>
      <div class="col s12 m12 l2">
        <select-search [label]="'Serie'" [placeholder]="'Ej. 0001'" [value]="'sdo_codsdo'"
          [name]="'sdo_codsdo'" [description]="'sdo_codsdo'" [disabled]="loaderData" [data]="series"
          (cambioSelect)="filtro.serie = $event;" [model]="filtro.serie">
        </select-search>

      </div>

      <div class="col s12 m12 l2">
        <select-search [label]="'Almacen'" [placeholder]="'Ej. 0001'" [value]="'ALM_CODALM'"
          [name]="'ALM_CODALM'" [description]="'description'" [disabled]="loaderData" [data]="almacenes"
          (cambioSelect)="filtro.almacen = $event;" [model]="filtro.almacen">
        </select-search>

      </div>

      <div class="col s12 m12 l4">

        <div [ngStyle]="{display: 'flex'}">
          <mat-checkbox [ngStyle]="{marginTop: '10px'}" class="example-margin" [(ngModel)]="filtro.tieneFiltroFecha" name="tieneFiltroFecha"></mat-checkbox>

          <mat-form-field [ngStyle]="{marginLeft: '5px'}">
            <mat-label>Desde</mat-label>
            <input matInput [(ngModel)]="filtro.desde" name="desde" [matDatepicker]="pickerDesde" [disabled]="!filtro.tieneFiltroFecha">
            <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
            <mat-datepicker #pickerDesde></mat-datepicker>
          </mat-form-field>

          <mat-form-field [ngStyle]="{marginLeft: '5px'}">
            <mat-label>Hasta</mat-label>
            <input matInput [(ngModel)]="filtro.hasta" [min]="filtro.desde" name="hasta" [matDatepicker]="pickerHasta" [disabled]="!filtro.tieneFiltroFecha">
            <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
            <mat-datepicker #pickerHasta></mat-datepicker>
          </mat-form-field>
        </div>

      </div>
      <div class="col s12 m12 l12" align="center">
        <button (click)="filtrarGuias()" mat-mini-fab color="primary" type="button">
          <mat-icon>search</mat-icon>
        </button>
      </div>


    </div>
    <div [ngStyle]="{display: 'flex', justifyContent: 'center', marginTop: '5px'}">
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
      </div>
    </div>

  </mat-card>

  <div class="mat-elevation-z0">
    <table-title-action title="Todos las guías" url="/modulo-almacen/guia-de-remision" entity=""
      [params]="['0']"></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

            <div class="pav-btns-container">
              <div class="pav-btn-circle-edit" matTooltip="Editar"
                [routerLink]="['/modulo-almacen/guia-de-remision', row.grc_numdoc]">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
            </div>

          </td>
        </ng-container>

        <ng-container matColumnDef="sdo_codsdo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.sdo_codsdo}} </td>
        </ng-container>

        <ng-container matColumnDef="grc_numdoc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero </th>
          <td mat-cell *matCellDef="let row" data-label="Chofer"> {{row.grc_numdoc}} </td>
        </ng-container>

        <ng-container matColumnDef="grc_fecdoc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{row.grc_fecdoc}} </td>
        </ng-container>

        <ng-container matColumnDef="cli_nomcli">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Destinatario </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{row.cli_nomcli}} </td>
        </ng-container>

        <ng-container matColumnDef="alm_descri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{row.alm_descri}} </td>
        </ng-container>

        <ng-container matColumnDef="mac_nummac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Parte Almacén </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{row.mac_nummac? row.mac_nummac: ''}} </td>
        </ng-container>

        <ng-container matColumnDef="grc_indsta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{(row.grc_indsta == "1")? "VIGENTE": "ANULADO"}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No se encontraron registros "{{input.value}}"</td>

        </tr>

      </table>

    </div>
    <mat-paginator #paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

  </div>

  <div class="row">
    <div class="col s12 m12 l3"></div>
    <div class="col s12 m12 l9">
      <div class="mat-elevation-z0">

        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <div class="overflow-x">
          <table mat-table [dataSource]="dataSourceDetalle" matSort>
            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

                <div class="pav-btns-container">
                  <div class="pav-btn-circle-edit" matTooltip="Editar"
                    [routerLink]="['/modulo-almacen/guia-de-remision', row.numero]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="texto">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.serie}} </td>
            </ng-container>

            <ng-container matColumnDef="usuario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero </th>
              <td mat-cell *matCellDef="let row" data-label="Chofer"> {{row.numero}} </td>
            </ng-container>

            <ng-container matColumnDef="actualizacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{row.fecha_emi | date:'dd/MM/YYYY': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="archivo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{row.nombre_cliente}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDetalle"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDetalle;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8">No se encontraron registros "{{input.value}}"</td>

            </tr>

          </table>

        </div>
        <mat-paginator #paginatorDetalle showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

      </div>
    </div>

  </div>


</div>
