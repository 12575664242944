import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthenticationService, ConfigurationService, NominaService, VentasService } from 'src/app/services';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { InteresBancoDialog } from '@shared/components/dialogs/planilla/interes-banco/interes-banco-dialog.component';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { PeriodoCTS } from 'src/app/models/planilla/operaciones/periodo-cts';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { MatStepper } from '@angular/material/stepper';
import { formatDateWithDash } from '@utils/formats/date.format';
import { fillTable } from '@utils/tables/table';
import { DetailLiquidacionPeriodoCTS } from '@shared/components/dialogs/detail-liquidacion-periodo-cts/detail-liquidacion-periodo-cts.component';

@Component({
  selector: 'app-periodo-cts',
  templateUrl: './periodo-cts.component.html',
  styleUrls: ['./periodo-cts.component.css'],
})
export class PeriodoCtsComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  disabledForm: boolean = true;

  periodoCTS: PeriodoCTS;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['accion', 'cli_codcli', 'cli_nomcli', 'tmo_codtmo', 'nct_tipcam'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string = this.buttonsName.BTN_STORE;

  year: string;
  month: string;

  periodo$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  registrarPeriodo$: Subscription;
  tipoCambio$: Subscription;
  liquidaciones$: Subscription;

  PAIS: string = "01";

  currentMonth: number;

  editCalculaCTS: boolean = false;

  tipoCambio: number;

  text: string;

  constructor(
    private _router: Router,
    private _nominaService: NominaService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _ventasService: VentasService,
    private _dialogService: DialogService,
    public dialog: MatDialog,
  ) {
    this.periodoCTS = new PeriodoCTS();

    this.currentMonth = new Date().getMonth();
    // this.currentMonth = 10;

    this.period$ = this.store.select('period').subscribe(
      state => {
        this.year = state.year;
        this.month = state.month;
      }
    )
    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this.obtenerPeriodoCTS();

  }

  obtenerPeriodoCTS(): void {
    this.periodo$ = this._nominaService.listarPeriodoCTS(this.year, this.month).subscribe(
      periodos => {
        if(periodos.length > 0) {
          this.periodoCTS = periodos[0];
          this.periodoCTS.accion = '2';
          this.btnName = this.buttonsName.BTN_UPDATE;
          this.text = 'actualizado';
          this.currentMonth = Number(this.month) - 1
          this.disabledForm = this.esUnMesValido();

          this.establecerValidacionFormulario();
          this.obtenerTipoCambio();
          return;
        }

        this.periodoCTS = new PeriodoCTS();
        this.periodoCTS.codano = this.year;
        this.periodoCTS.codmes = this.month;

        this.periodoCTS.accion = '1';
        this.btnName = this.buttonsName.BTN_STORE;
        this.text = 'registrado';

        this.disabledForm = this.esUnMesValido();

        this.establecerValidacionFormulario();
      }
    )
  }

  registrarPeriodoCTS(f: NgForm, stepper: MatStepper): void {
    this.loaderReg = true;
    this.periodoCTS.coduse = this._configurationService.obtenerIdUsuario();
    this.periodoCTS.indsta = '1';
    let now = new Date();
    this.periodoCTS.fecupd = now.toISOString();

    this.registrarPeriodo$ = this._nominaService.registrarPeriodoCTS(this.year, this.month, this.periodoCTS).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, `Período CTS ${this.text}`, '400px', 'auto', '').subscribe(
          response => {
            stepper.next();
            this.loaderReg = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )
  }

  obtenerInteresBanco(): void {
    const dialogRef = this.dialog.open(InteresBancoDialog, {
      width: '550px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  establecerValidacionFormulario(): void {
    if(!this.disabledForm) {
      if(this.currentMonth == 4 ) {
        this.periodoCTS.ranini = new Date(`11/01/${Number(this.year) - 1}`);
        this.periodoCTS.ranfin = new Date(`04/30/${this.year}`);
        this.periodoCTS.fecobl = new Date(`04/15/${this.year}`);

      } else if (this.currentMonth == 10) {
        this.periodoCTS.ranini = new Date(`05/01/${this.year}`);
        this.periodoCTS.ranfin = new Date(`10/31/${this.year}`);
        this.periodoCTS.fecobl = new Date(`10/15/${this.year}`);
      }
    }
  }

  esUnMesValido(): boolean {

    if(this.currentMonth == 4 || this.currentMonth == 10 ) return false;
    return true;

  }

  obtenerTipoCambio(): void {
    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.periodoCTS.fecpag)).subscribe(
      response => {
        this.tipoCambio = response[0].tipcam;
        this.obtenerLiquidacionesPeriodoCTS()
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  siguiente(stepper: MatStepper): void {
    stepper.next();
    this.obtenerLiquidacionesPeriodoCTS();
  }

  obtenerLiquidacionesPeriodoCTS(): void {
    this.liquidaciones$ = this._nominaService.obtenerLiquidacionesPeriodoCTS(this.year, this.month).subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  verDetallelLiquidacion(cli_codcli): void {
    const dialogRef = this.dialog.open(DetailLiquidacionPeriodoCTS, {
      width: 'auto',
      data: cli_codcli,
      panelClass: 'pav-dialog-detail-liquid'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/listado-periodo-cts']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.periodo$,
      this.period$,
      this.loading$,
      this.registrarPeriodo$,
      this.tipoCambio$,
      this.liquidaciones$
    ])
  }

}
