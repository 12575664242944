import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ImportacionService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @returns
   */
  listarInvoices() {

    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/importacion/listarinvoices/${this._configurationService.obtenerCompaniaCliente()}`);

  }

}
