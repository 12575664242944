import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';

@Component({
  selector: 'app-ilumi-client-list',
  templateUrl: './ilumi-client-list.component.html',
  styleUrls: ['./ilumi-client-list.component.css']
})
export class IlumiClientListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['nombre', 'dir_fac','acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  detalleCliente$: Subscription;
  cliente$: Subscription;

  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<IlumiClientListComponent>,

  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerClientes()
  }

  obtenerClientes(): void {
    this.cliente$ = this._ventasService.obtenerClientes().subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  seleccionarCliente(data) {

    this.detalleCliente$ = this._ventasService.detalleCliente(data.cli_codcli).subscribe(
      response => this.dialogRef.close(response[0]),
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  cancelar(): void {
    this.dialogRef.close();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.detalleCliente$,
      this.cliente$
    ])
  }

}
