export class RemuneracionFija {

  codcli: string;
  codtmo: string;
  imprem: number | string;
  codnut: string;
  cannut: number;
  isEditing: Boolean;
  correlativo: number;

  constructor() {
    this.codtmo = "SO";
    this.imprem = 0;
    this.cannut = 0;
    this.isEditing = true;
  }
}
