import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ApiVentasOperacionesFacturaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @param periodo
   * @returns
   */
  obtenerMonitoreoFactel(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/fatura/monitoreo-factel/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
     *
     * @param periodo
     * @returns
     */
  obtenerDetalleMonitoreoFactel(cco_codcco): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/fatura/monitoreo-detalle-factel/${this._configurationService.obtenerCompaniaCliente()}/${cco_codcco}`
    );
  }

  /**
   * Obtener pedidos a facturar
   * @returns
   */
  obtenerPedidosAFacturar(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/pedidos-genera-factura`
    )
  }

  /**
   * Obtener pedidos a facturar con filtro
   * @param payload
   * @returns
   */
  obtenerPedidosAFacturarConFiltro(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/pedidos-genera-factura-filtro`,
      payload
    )
  }

  /**
   * Obtener detalle pedido a facturar
   * @param pcc_numpcc
   * @returns
   */
  obtenerDetallePedidoAFacturar(pcc_numpcc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/detalle-pedido-genera-factura/${pcc_numpcc}`
    )
  }

  /**
   * Generar factura
   * @param payload
   * @returns
   */
  generarFactura(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/genera-factura`, payload
    )
  }
}
