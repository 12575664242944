<h1 mat-dialog-title>Interés Banco</h1>
<div mat-dialog-content>
  <br>

  <mat-form-field class="example-full-width">
    <mat-label>Período</mat-label>
    <input matInput placeholder="Período" [(ngModel)]="period" name="period" readonly>
  </mat-form-field>

  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">
    </div>
    <div class="pav-table-header-title">
      Tabla
    </div>
    <div class="pav-button-add-item">
      <button #btnAdd type="button" (click)="agregarItem()" (keyup.enter)="agregarItem()" mat-flat-button [disabled]="loaderData">Agregar item</button>
    </div>
  </div>

  <div class="mat-elevation-z0">

      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" data-label="Acciones">
                <div class="pav-btns-container">
                  <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                  <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                    <mat-icon svgIcon="confirm"></mat-icon>
                  </div>
                  <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="banco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Banco </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Banco">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="mat-form-field-item-banco">
                    <select-search
                      [label]="'Banco'"
                      [placeholder]="'Ej. BCP'"
                      [name]="'BAN_CODBAN'"
                      [value]="'BAN_CODBAN'"
                      [description]="'BAN_NOMBRE'"
                      [data]="bancos"
                      (cambioSelect)="row.codban = $event;"
                      [model]="row.codban">
                    </select-search>


                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.banco}}
                </ng-container>
              </td>
          </ng-container>

          <ng-container matColumnDef="moneda">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Moneda">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="mat-form-field-item-moneda">
                    <mat-select [(ngModel)]="row.codtmo" [name]="'codtmo'+i">
                      <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
                        {{moneda.tmo_deslar}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.codtmo}}
                </ng-container>
              </td>
          </ng-container>

          <ng-container matColumnDef="porcentaje">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Porcentaje </th>
            <td mat-cell *matCellDef="let row; let i = index;" data-label="Porcentaje">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="mat-form-field-item">
                  <input min="0" type="number" matInput placeholder="Porcentaje" [(ngModel)]="row.porint" [name]="'porcentaje'+i">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.porint}}
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros</td>

          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <div class="row">
    <div class="col s12 m12 l6">
      <button class="pav-button-cancel" mat-flat-button (click)="onNoClick()">{{buttonName.BTN_CLOSE}}</button>
    </div>
    <div class="col s12 m12 l6">
      <button class="pav-button-action" color="primary" mat-flat-button (click)="registrarInteresBanco()">{{buttonName.BTN_CONFIRM}}</button>
    </div>
  </div>
</div>
