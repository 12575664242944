export class FiltroReporteVentas {

  tiendas: Array<any>;
  clientes: Array<any>;
  series: Array<any>;
  motivos: Array<any>;
  vendedores: Array<any>;
  productos: Array<any>;
  condiciones: Array<any>;
  inventarios: Array<any>;
  lineas: Array<any>;
  documentos: Array<any>;
  marcas: Array<any>;
  moneda: string;
  fecIni: string;
  fecTer: string;
  periodos: Array<{periodo: string}>

  constructor() {
    this.tiendas = [];
    this.clientes = [];
    this.series = [];
    this.motivos = [];
    this.periodos = [];
    this.vendedores = [];
    this.productos = [];
    this.condiciones = [];
    this.inventarios = [];
    this.lineas = [];
    this.documentos = [];
    this.marcas = [];
    this.moneda = 'SO';
  }

}
