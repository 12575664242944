<mat-progress-bar *ngIf="loaderData" mode="indeterminate"></mat-progress-bar>

<h1 mat-dialog-title>Seleccione Tipo de Nómina</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Tipo de Nómina</mat-label>
    <mat-select [(ngModel)]="tipoNomina" name="tipoNomina" (selectionChange)="seleccinarTipoNomina($event.value)">
      <mat-option *ngFor="let tipo of tipos" [value]="tipo.nti_codnti">
        {{tipo.nti_descri}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
      <mat-label>Secuencia Nómina</mat-label>
      <mat-select [(ngModel)]="secNomina" name="secNomina">
          <div *ngFor="let secuencia of secuencias">
              <mat-option [value]="secuencia.cod">
                    {{secuencia.cod}} | {{secuencia.nfr_descri}}
            </mat-option>
          </div>
      </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <!-- <button class="pav-button-cancel" mat-flat-button (click)="cancelar()">Cancelar</button> -->
  <button class="pav-button-action" mat-flat-button (click)="confirmar()" color="primary" [disabled]="loaderData">Confirmar</button>
</div>
