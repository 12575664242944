import { logo } from "@shared/resources/logo";
import { Canvas, Cell, Img, Line, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";
import { NumeroALetras } from "../../conversions/number2letters.conversion";

export async function verPdfImportaciones(data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([ 40, 40, 40, 40 ]);


  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            await new Img(logo).alignment('left').width(120).build(),
          ]).end
        ).end,

        new Cell(
          new Stack([
            new Txt(`${data.CIA_NOMCIA}`).fontSize(11).alignment('center').color('#000').bold().end,
            pdf.ln(1),
            new Txt(`RUC: ${data.CIA_NUMRUC} ${data.CIA_DIRCIA}`).fontSize(9).alignment('center').color('#000').end,
            pdf.ln(2),
          ]).alignment('center').end ,
        ).end,
        new Cell(
          new Txt('').end
        ).end,

      ]
    ]).layout('noBorders').widths([ 150, 225, 100 ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`ORDEN DE COMPRA N° :`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`${data.ocm_numocm}`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`APROBADO`).fontSize(11).bold().end
          ]).end
        ).end,
        new Table([
          [
            new Cell(
              new Txt(`SE`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`DIA`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`MES`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`AÑO`).fontSize(9).bold().end
            ).end,
          ],
          [
            new Cell(
              new Stack([
                new Txt(`${data.semana}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.dias}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.mes}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.anio}`).fontSize(8).end
              ]).end
            ).end,
          ]
        ]).widths([ 20, 20, 20, 20 ]).end
      ]
    ]).layout('noBorders').widths([ 130, 120, 110, 120 ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('SEÑOR(ES)').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_nomcli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('Condicion').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('RUC').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_codcli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('Tipo OC').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('DIRECCION').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_dircli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('TELEFONO').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_numtlf}`).fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('Estimados señores').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('E-Mail').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('Sirvanse atender el siguiente pedido ').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Att. Sr.').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('según las especificaciones Técnicas').fontSize(7).end
                ]).end
              ).end,
            ]
          ]).layout('noBorders').widths([120, 240, 120]).end
        ).end
      ],

    ]).widths([500]).end
  )

  pdf.add(
    new Table([
      [
        new Table([
          [
            new Cell(
              new Txt(`Descripción Producto/Servicio`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Unidad`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Cantidad`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`P. Unit. S/.`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Total S/.`).fontSize(8).bold().end
            ).end
          ],

        ]).widths([260, 50, 50, 50, 50]).end
      ]
    ]).layout('noBorders').widths([500]).end
  )

  let items = []
  data.detalle_produc.forEach(element => {
    items.push([
      new Cell(
        new Txt(`${element.prd_desesp}`).fontSize(8).end
      ).end,
      new Cell(
        new Txt(`${element.ume_codume}`).fontSize(8).alignment('right').end
      ).end,
      new Cell(
        new Txt(`${element.ocd_canocd}`).fontSize(8).alignment('right').end
      ).end,
      new Cell(
        new Txt(`${(element.ocd_preuni).toFixed(2)}`).alignment('right').fontSize(8).end
      ).end,
      new Cell(
        new Txt(`${(element.ocd_imptot).toFixed(2)}`).alignment('right').fontSize(8).end
      ).end
    ],)
  });

  pdf.add(
    new Table([
      [
        new Table(items).layout('noBorders').widths([260, 50, 50, 50, 50]).end
      ]
    ]).layout('noBorders').widths([500]).end
  )

  pdf.add(
    new Stack([
      pdf.ln(1),
      new Txt('Observaciones').bold().fontSize(8).decoration('underline').end,
      pdf.ln(1)
    ]).end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`Son: ${NumeroALetras((data.ocm_imptot*1.18))}`).alignment('left').fontSize(8).end
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`Valor Venta:`).fontSize(8).alignment('right').bold().end,
            new Txt(`IGV:`).fontSize(8).alignment('right').bold().end,
            new Txt(`Total: `).fontSize(8).alignment('right').bold().end,
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`${data.ocm_valvta.toFixed(2)}`).fontSize(8).alignment('right').end,
            new Txt(`${(data.ocm_valvta * 0.18).toFixed(2)}`).fontSize(8).alignment('right').end,
            new Txt(`${(data.ocm_valvta + data.ocm_valvta * 0.18).toFixed(2)}`).fontSize(8).alignment('right').end,
          ]).end
        ).end,
      ]
    ]).layout('noBorders').widths([360, 50, 50]).end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('Forma de Pago:').fontSize(8).alignment('right').end,
            new Txt('Cuenta:').fontSize(8).alignment('right').end,
            new Txt('F. Entrega:').fontSize(8).alignment('right').end,
            new Txt('Plazo de Entrega:').fontSize(8).alignment('right').end,
            new Txt('Lugar de Entrega:').fontSize(8).alignment('right').end,
            new Txt('Factura A:').fontSize(8).alignment('right').end,
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`${data.cpa_descri}`).fontSize(8).end,
            new Txt('').fontSize(8).end,
            new Txt((new Date()).toLocaleString()).fontSize(8).end,
            new Txt('').fontSize(8).end,
            new Txt(`${data.ldc_dirldc}`).fontSize(8).end,
            new Txt('').fontSize(8).end,
          ]).end
        ).end,
      ]
    ]).widths([ 250, 250 ]).layout('noBorders').end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(8)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('DAPUETO VILLON RENZO').fontSize(8).alignment('center').end,
                  new Txt('V° B°').fontSize(8).bold().alignment('center').end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('BARRETO MAVILA JUAN CARLOS').fontSize(8).alignment('center').end,
                  new Txt('2DO V° B°').fontSize(8).alignment('center').bold().end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('BARRETO MAVILA JUAN CARLOS').fontSize(8).alignment('center').end,
                  new Txt('APROBADO POR').fontSize(8).bold().alignment('center').end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end
      ]
    ]).layout('noBorders').widths([135,25,135,25,135]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Agredeceremos presentar su factura y Guia de remisión con V°.B°. de Almacé Principal, acompañado con una copia de esta OC, Caso contrario no se recibira la factura.').fontSize(8).bold().end
        ).end
      ],
      [
        new Cell(
          new Stack([
            new Txt('Horarios de Atención:').fontSize(8).bold().end,
            new Txt('Recepción de Mercadería (Almacén) - De Lunes a Viernes de 8 am. a 6 pm. y Sabados de 8 am. a 12 pm.').fontSize(8).bold().end,
            new Txt('Recepción de Facturas (Logística) - De Lunes a Viernes de 9 am. a 5.30 pm. y Sabados de 9 am. a 12 pm.').fontSize(8).bold().end
          ]).end
        ).end
      ]
    ]).end
  )

  pdf.create().open();
}

export async function generarPdfImportaciones() {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([ 20, 20, 20, 20 ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("COMERCIALIZADORA ASIA PERU S.A.C.").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("13/09/2021").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("RCOM_REP_DET_VE").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("15:03:11").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("INFORME IMPORTACIÓN TEMPLATE").fontSize(12).bold().alignment('center').end
        ).end
      ]
    ]).layout('noBorders').widths([1140]).end
  );


  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("Cliente").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("TD").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Número").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Fecha").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Línea").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cod. Pro.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Producto").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cantidad").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Precio").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Moneda").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Importe").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Usuario").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Referencia").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Pedido").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Guía Remisión").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cond. Pago").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Vendedor").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Motivo").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Ind. Guía").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Almacén").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Costo Prod.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Departamento").fontSize(8).bold().alignment('center').end
        ).end
      ]
    ]).widths([80,20,30,30,40,50,80,40,30,30,30,60,80,40,50,30,80,30,30,30,30,30]).end
  );

  pdf.add(
    new Table(this.dataDetalleFacturacion).layout('noBorders').widths([85,20,35,30,40,50,83,43,30,30,30,60,80,40,55,30,80,30,30,30,30,30]).end
  );

  pdf.create().open();

}
