import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class AuxiliarService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  registrarAuxiliar(cliente): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/crear`,
      cliente
    )
  }

  editarAuxiliar(cliente): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/modificar`,
      cliente
    )
  }

  obtenerAuxiliar(codcli): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codcli}`
    )
  }

  obtenerAuxiliares(): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/listar/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((clientes: any) => {
        return clientes.map(cliente => {
          cliente['description'] = `${cliente.cli_codcli} | ${cliente.cli_nomcli}`
          cliente['description_reverse'] = `${cliente.cli_nomcli} | ${cliente.cli_codcli}`
          return cliente;
        }).filter(cliente => cliente.cli_indtra === 1)
      })
    )
  }
}
