<h1 mat-dialog-title>Agregar múltiples productos</h1>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-accordion>
  <mat-expansion-panel class="mat-elevation-z0" [ngStyle]="{border: '1px solid #dbdbdb', borderRadius: '10px'}">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filtros avanzados
      </mat-panel-title>

    </mat-expansion-panel-header>

    <div class="row">
      <div class="col s12 m12 l6">
        <select-search [label]="'Familias'" [placeholder]="'Ej. 0001'" [name]="'FPR_CODFPR'" [value]="'FPR_CODFPR'"
          [description]="'description_reverse'" [data]="familias"
          (cambioSelect)="filtro.familias = $event; seleccionarFamilia()" [model]="filtro.familias" [disabled]="loading"
          [multiple]="true"></select-search>
      </div>
      <div class="col s12 m12 l6">
        <mat-form-field>
          <mat-label>Sub Familias</mat-label>
          <mat-select [disabled]="loading" [(ngModel)]="filtro.subfamilias" name="subFamilias" [multiple]="true">
            <mat-option *ngFor="let subfamilia of subfamilias"
              [value]="subfamilia.sfp_codsfp">{{subfamilia.sfp_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l6">
        <select-search [label]="'Líneas'" [placeholder]="'Ej. PRODUCTO CONGELADO'" [name]="'lpd_codlpd'"
          [value]="'lpd_codlpd'" [description]="'lpd_descri'" [data]="lineas" (cambioSelect)="filtro.lineas = $event"
          [model]="lineas" [disabled]="loading" [multiple]="true"></select-search>
      </div>

      <div class="col s12 m12 l6">
        <select-search [label]="configuracion.s70_labcol ? configuracion.s70_labcol: 'Color'" [placeholder]="'Ej. BEIGE'"
          [name]="'col_codcol'" [value]="'col_codcol'" [description]="'col_descri'" [data]="colores"
          (cambioSelect)="filtro.colores = $event" [model]="filtro.colores" [multiple]="true"></select-search>
      </div>

      <div class="col s12 m12 l6">
        <select-search [label]="configuracion.s70_labmod ? configuracion.s70_labmod: 'Modelo'" [placeholder]="'Ej. BEIGE'"
          [name]="'mod_codmod'" [value]="'mod_codmod'" [description]="'mod_descri'" [data]="modelos"
          (cambioSelect)="filtro.modelos = $event" [model]="filtro.modelos" [multiple]="true"></select-search>
      </div>

      <div class="col s12 m12 l6">
        <select-search [label]="'Tipos de inventario'" [placeholder]="'Ej. materia prima'" [name]="'tin_codtin'"
          [value]="'tin_codtin'" [description]="'tin_descri'" [data]="tiposInventario"
          (cambioSelect)="filtro.tiposInventario = $event" [model]="filtro.tiposInventario"
          [disabled]="loading" [multiple]="true"></select-search>
      </div>

      <div class="col s12" align="center">
        <button (click)="filtrar()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
          <mat-icon>search</mat-icon>
        </button>
      </div>

    </div>

  </mat-expansion-panel>

</mat-accordion>

<mat-form-field [ngStyle]="{marginTop: '10px'}">
  <mat-label>Buscar</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Buscar">
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>

<mat-progress-bar mode="indeterminate" *ngIf="loadingFiltros"></mat-progress-bar>

<!-- <div mat-dialog-content [ngStyle]="{marginTop: '10px'}">
  <select-search [ngStyle]="{width: '100%'}" [label]="'Producto'" [placeholder]="'Ej. CANASTA'" [name]="'prd_descri'"
    [value]="'prd_codprd'" [description]="'description'" [data]="data.products" (cambioSelect)="productos = $event;"
    [model]="productos" [multiple]="true">
  </select-search>
</div> -->

<br>
<section class="example-section" [ngStyle]="{marginTop: '0px'}">
  <span class="example-list-section">
    <mat-checkbox class="example-margin" [checked]="allComplete"
      [indeterminate]="someComplete()" (change)="setAll($event.checked)">
      {{lote.name}}
    </mat-checkbox>
  </span>
  <span class="example-list-section">
    <ul [ngStyle]="{overflowX: 'auto', height: '200px'}">
      <li *ngFor="let lot of lote.lotes">
        <mat-checkbox [(ngModel)]="lot.completed" [ngStyle]="{fontSize: '12px'}"
          (ngModelChange)="updateAllComplete()">
          {{lot.prd_codprd}} - {{lot.DESC_ESPA}}
        </mat-checkbox>
      </li>
    </ul>
  </span>
</section>

<div align="center">

  <button mat-raised-button color="primary" (click)="confirmar()">Confirmar</button>

</div>
