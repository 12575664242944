export const LANGUAGE_CRM_ES = {
  print_time: "Hora de impresión",
  date: "Fecha",
  user: "Usuario",
  quotation: "Cotización",
  nro_quotation: "Nro. Cotización",
  order: "Orden pedido",
  nro_order: "Nro. Orden",
  applicant: "Solicitante",
  way_to_pay: "Forma de pago",
  seller: "Vendedor",
  customer_information: "Información del cliente",
  client: "Cliente",
  address: "Dirección",
  ruc: "RUC",
  ubigeous: "Ubigeo",
  dispatch_address: "Dirección de despacho",
  carrier_information: "Dirección del transportista",
  comments: "Comentarios",
  it: "Ítem",
  description: "Descripción",
  model: "Modelo",
  amount: "Cantidad",
  price: "Precio",
  discount: "Descuento",
  valued: "Valorizado",
  total: "Total",
  approval: "Visto bueno",
  passed: "Aprobado"
}

export const LANGUAGE_CRM_EN = {
  print_time: "Print time",
  date: "Date",
  user: "User",
  quotation: "Quotation",
  nro_quotation: "# Quotation",
  order: "Order",
  nro_order: "Nro. Order",
  applicant: "Applicant",
  way_to_pay: "Way to pay",
  seller: "Seller",
  customer_information: "Customer information",
  client: "Client",
  address: "Address",
  ruc: "RUC",
  ubigeous: "Ubigeous",
  dispatch_address: "Dispatch address",
  carrier_information: "Carrier information",
  comments: "Comments",
  it: "Item",
  description: "Description",
  model: "Model",
  amount: "Amount",
  price: "Price",
  discount: "Discount",
  valued: "Valued",
  total: "Total",
  approval: "Approval",
  passed: "Passed"
}

export const LANGUAGE_CRM_PRESUPUESTO_REPORT_ES = {
  print_time: "Hora de impresión",
  date: "Fecha",
  user: "Usuario",
  budget: "Presupuesto",
  client: "Cliente",
  exchange_rate: "Tipo de cambio",
  nro_idtkt_idset: "Nro. Oport. - Nro. Seg.",
  observations: "Observaciones",
  item: "Ítem",
  description: "Descripción",
  amount: "Cantidad",
  unit_cost: "Costo unitario",
  incidence_cost: "Incidencia costo",
  margin: "Márgen %",
  margin_amount: "Importe márgen",
  unit_offer: "US$ Unit. Oferta",
  total_offer: "US$ Total Oferta",
  full_supply: "Total de suministro",
  subtotal: "SubTotal",
  administrative_cost: "Costo Administrativo",
  budget_value: "Valor presupuesto",
  igv: "IGV",
  total_budget: "Total presupuesto"
}

export const LANGUAGE_CRM_PRESUPUESTO_REPORT_EN = {
  print_time: "Print time",
  date: "Date",
  user: "User",
  budget: "Budget",
  client: "Client",
  exchange_rate: "Exchange rate",
  nro_idtkt_idset: "# Opportunity. - # Tracing.",
  observations: "Observations",
  item: "Ítem",
  description: "Description",
  amount: "Amount",
  unit_cost: "Unit cost",
  incidence_cost: "Incidence cost",
  margin: "Margin %",
  margin_amount: "Margin amount",
  unit_offer: "US$ Unit Offer",
  total_offer: "US$ Total Offer",
  full_supply: "Full supply",
  subtotal: "SubTotal",
  administrative_cost: "Administrative cost",
  budget_value: "Budget value",
  igv: "IGV",
  total_budget: "Total budget"
}
