import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";
import { map } from 'rxjs/operators';
import { Product } from "src/app/models";
import axios from "axios";
import { ImagenProducto } from "src/app/models/ventas/product";
import { IParam, generarParams } from "../../utilities/params";


@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @returns
   */
  obtenerProductos({ params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/listaproductos/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    ).pipe(
      map((productos: any) => {
        return productos.map(producto => {
          producto['description'] = `${producto.prd_codprd} | ${producto.DESC_ESPA} | ${producto.DESC_MARCA}`
          producto['codigo'] = `${producto.prd_codprd}`
          producto['descripcion'] = `${producto.DESC_ESPA}`
          producto['description_reverse'] = `${producto.DESC_ESPA} | ${producto.prd_codprd}  ${producto.DESC_MARCA}`
          return producto;
        })
      })
    );
  }

  /**
   *
   * @returns
   */
  obtenerProductosConPaginacion(page: number = 1, count: number = 5, orden: string = 'ASC', columnaorden: string = 'prd_codprd', buscar: string = ''): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/listarpagina/${this._configurationService.obtenerCompaniaCliente()}/${page}/${count}?buscar=${buscar}&orden=${orden}&columnaorden=${columnaorden}`
    );
  }

  /**
 *
 * @returns
 */
  obtenerProductosPipe(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/listaproductos/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((productos: any) => {
        return productos.map(productos => {
          productos['description'] = `${productos.prd_codprd} | ${productos.DESC_ESPA}`
          return productos;
        })
      })
    );
  }

  /**
   *
   * @param code
   * @returns
   */
  obtenerProducto(code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${code}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  registrarProducto(data: Product): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/crear`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarProducto(data: Product): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/modificar`,
      data
    );
  }
  /**
   *
   * @param en_estandares: boolean
   * @returns
   */
  ListarProductos({ params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    ).pipe(
      map((productos: any) => {
        return productos.map(producto => {
          producto['description'] = `${producto.prd_codprd} | ${producto.prd_desesp}`
          return producto;
        })
      })
    );
  }

  registrarImagenProductoSicrono(imagenPayload: ImagenProducto): Promise<any> {
    const headers = {
      'Authorization': `${this._configurationService.obtenerTokenCliente()}`
    };

    return new Promise(async (resolve, reject) => {
      try {
        const data = await axios.post(`${this._configurationService.obtenerUrl()}/api/productos/crearimagen`, imagenPayload, {headers});
        resolve(data.data);

      } catch (error) {
        reject(error)
      }
    })
  }

  obtenerImagenesProducto(prd_codprd: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/mostrarimagenes/${this._configurationService.obtenerCompaniaCliente()}/${prd_codprd}`
    )
  }

  eliminarImagenProducto(pri_idpri: number): Observable<any> {
    return this.http.delete<any>(
      `${this._configurationService.obtenerUrl()}/api/productos/eliminaimagen/${pri_idpri}`
    )
  }
}
