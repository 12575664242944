import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { ComprasService } from "../../api/compras.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import * as pdfBuilder from "@utils/pdfmaker/builder/compras.pdfmaker";

@Injectable({
  providedIn: 'root'
})
export class ComprasReportService {
  reporte$: Subscription;

  constructor(
    private _comprasService: ComprasService,
    private _snackBarService: SnackBarService
  ) {}

  verReporte(reportSelected: string, headers: any, payload: any, otherData: any): Promise<any>  {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case 'COMPRAS_PvsS':
          this.reporte$ = this._comprasService.obtenerDataReportePedidosVsStock().subscribe(
            data => {

              if(data.length <= 0) {
                this._snackBarService.showError('No se encontraron registros para el período o filtro', 'Ok');
                reject(false);
                return;
              }
              resolve(true);
              pdfBuilder.generarReportePDFPedidosVsStock(headers, data);

            },
            error => {
              reject(false);
              this._snackBarService.showError('Error al Obtener Reporte de Pedidos vs Stock', 'Ok');
              return;
            }
          )
          break;

        default:
          break;
      }
    })
  }

}
