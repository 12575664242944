<div>

  <h2 style="text-align: center;">{{'general.dialog.confirmation.title' | translate}}</h2>

  <div mat-dialog-content>

      <p class="pav-confirmation-text">{{message}}</p>

  </div>

  <div mat-dialog-actions style="float: right;">

    <button mat-flat-button color="primary" (click)="dialogRef.close(false)"> No </button>
    <button mat-flat-button (click)="dialogRef.close(true)"> Si </button>

  </div>

</div>
