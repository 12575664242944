import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';

@Injectable({
  providedIn: 'root',
})
export class CostosService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteDetalladoCostosOrdenFabricacion(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/costos/reporte-detallado-costos-orden-fabricacion/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteAnalisisDistribucionManoObraDirecta(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/costos/reporte-analisis-distribucion-mano-obra-directa/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteAnalisisConsistenciaManoObraIndirecta(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/costos/reporte-analisis-consistencia-mano-obra-indirecta/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteAnalisisDistribucionManoObraDirectaAuxiliar(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/costos/reporte-analisis-consistencia-mano-obra-directa-auxiliar/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteSaldosContablesSegunTipoCostoGastosFabrica(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/costos/reporte-saldos-contables-tipo-costo-gasto-fabrica/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteAnalisisDistribucionGastosFabrica(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/costos/reporte-analisis-distribucion-gastos-fabrica/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteSaldosContablesPorClientesGastosVentas(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/costos/reporte-saldos-contables-clientes-gastos-ventas/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerGraficoCostoUnitarioProductoDia(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/costos/reporte-grafico-costo-unitario-producto-dia/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

}
