import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExportExcelService } from 'src/app/services';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-punto-venta-report',
  templateUrl: './punto-venta-report.component.html',
  styleUrls: ['./punto-venta-report.component.css']
})
export class PuntoVentaReportComponent implements OnInit, OnDestroy {

  dataForExcel = [];

  dataDetalleFacturacion = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  period$: Subscription;

  constructor(
    public ete: ExportExcelService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }

}
