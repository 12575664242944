export class AsientoContable {
  CIA_CODCIA: string;
  ANO_CODANO: string;
  MES_CODMES: string;
  SCO_CODSCO: string;
  LDC_CORLDC: string;
  LDC_FECDIA: Date;
  LDC_GLODIA: string;
  LDC_FLALIQ: string;
  LDC_TIPCAM: number;
  TTI_CODTTI: string;
  LDC_TIPCON: any;
  LDC_SITACA: string;
  LDC_KEYANT: any;
  LDC_CORASI: any;
  SUC_CODSUC: string;
  LDC_INDSTA: string;
  LDC_FECUPD: Date;
  LDC_CODUSE: string
  ldc_codant: any;
  detalle_ldi: Array<DetalleLDI>;
  constructor(
  ) {
    this.LDC_INDSTA = "1";
    this.LDC_FECUPD = new Date();
    this.LDC_FECDIA = new Date();
    this.LDC_TIPCAM = 0;
    this.SUC_CODSUC = '01';

    this.detalle_ldi = [];
  }
}

export class DetalleLDI {
  CIA_CODCIA: string;
  ANO_CODANO: string;
  MES_CODMES: string;
  SCO_CODSCO: string;
  LDC_CORLDC: string;
  LDI_CORLDI: string;
  CCN_CODCCN: string;
  CLI_CODCLI: string;
  CCO_CODCCO: string;
  TMO_CODTMO: string;
  LDI_INDDHA: string;
  LDI_TIPCAM: number;
  LDI_TCAMEU: number;
  LDI_IMPNAC: number;
  LDI_IMPMEX: number;
  LDI_INDCCO: string;
  TDO_CODTDO: string;
  LDI_DOCREF: string;
  TDO_DOCREF: any;
  LDI_NUMREF: any;
  LDI_GLOLDI: string;
  FPA_CODFPA: any;
  REF_CODREF: any;
  LDI_INDDES: number;
  LDI_FECEMI: any;
  LDI_FECVEN: any;
  CCS_CODCCS: any;
  SUC_CODSUC: string;
  LDI_INDSTA: string;
  LDI_FECUPD: Date;
  LDI_CODUSE: string;
  REC_NUMREC: any;
  VDE_CODVDE: any;
  ldi_impsal: number;
  fca_codfca: any;
  OCM_NUMOCM: any;
  ldi_indccl: number;
  ldi_useccl: any;
  ldi_fecccl: any;
  ldi_anoccl: any;
  ldi_mesccl: any;
  ldi_gloref: any;
  ldi_impamo: number;
  ldi_impint: number;
  ldi_impseg: number;
  ldi_imppor: number;
  ldi_impcuo: number;
  ldi_impigv: number;

  constructor() {
    this.LDI_INDSTA = '1';
    this.LDI_FECUPD = new Date();

    this.LDI_IMPNAC = 0;
    this.LDI_IMPMEX = 0;
    this.SUC_CODSUC = '01';
  }
}

export class DetalleAsientoContable {
  cia_codcia: any;
  ano_codano: any;
  mes_codmes: any;
  sco_codsco: any;
  ccn_descri: any;
  ldc_corldc: any;
  ldi_corldi: any;
  ccn_codccn: any;
  cli_codcli: string;
  cco_codcco: any;
  tmo_codtmo: any;
  ldi_inddha: any;
  ldi_tipcam: any;
  ldi_tcameu: any;
  ldi_impnac: any;
  ldi_impmex: any;
  ldi_indcco: any;
  tdo_codtdo: any;
  ldi_docref: any;
  tdo_docref: any;
  ldi_numref: any;
  ldi_gloldi: any;
  fpa_codfpa: any;
  ref_codref: any;
  ldi_inddes: any;
  ldi_fecemi: any;
  ldi_fecven: any;
  ccs_codccs: any;
  suc_codsuc: any;
  rec_numrec: any;
  vde_codvde: any;
  ldi_impsal: any;
  fca_codfca: any;
  ocm_numocm: any;
  ldi_indccl: any;
  ldi_anoccl: any;
  ldi_mesccl: any;
  isEditing: boolean;
  isSelected: boolean;
  constructor(
  ) {
    this.isSelected = false;
    this.isEditing = true;
    this.tmo_codtmo = "SO";
    this.ldi_inddha = "D";
    this.tdo_codtdo = "FAC";
    this.ldi_impnac = 0;
    this.ldi_impmex = 0;
    this.ldi_tcameu = 0;
    this.ldi_inddes = 0;
    this.ldi_impsal = 0;
    this.ldi_indccl = 0;
    this.ldi_indcco = "N";
    this.ldi_inddes = 0;
    this.ldi_mesccl = "";
    this.suc_codsuc = "01";
    this.tdo_docref = "";
    this.vde_codvde = "";
  }
}

export class OrdenesAsientoContable {
  cia_codcia: any;
  ocm_numocm: any;
  cco_codcco: any;
  oim_numoim: any;
  occ_impdis: any;
  isEditing: any;
  constructor(
  ) { }
}
