export const REPORTES_COSTOS = [
  {
    id: "COSTOS_DCOF",
    name: "Detallado de costos por orden de fabricación",
    esDinamico: false,
  },
  {
    id: "COSTOS_ECOP",
    name: "Estructura de costos por orden de producción",
    esDinamico: false,
  },
  {
    id: "COSTOS_ADMOD",
    name: "Análisis distribución mano obra directa",
    esDinamico: false,
  },
  {
    id: "COSTOS_ACMOI",
    name: "Análisis consistencia mano obra indirecta",
    esDinamico: false,
  },
  {
    id: "COSTOS_ADMODA",
    name: "Análisis distribución mano obra directa - auxiliar",
    esDinamico: false,
  },
  {
    id: "COSTOS_SCSTCGF",
    name: "Saldos contables según tipo costo gastos fabrica",
    esDinamico: false,
  },
  {
    id: "COSTOS_ADGF",
    name: "Análisis distribución gastos fábrica",
    esDinamico: false,
  },
  {
    id: "COSTOS_SCCGV",
    name: "Saldos contables por clientes gastos ventas",
    esDinamico: false,
  },
];
