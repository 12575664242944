import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { formatDateWithDash } from '@utils/formats/date.format';
import { numberWithCommas } from '@utils/formats/number.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { generarFormatoOrdenCompra } from '@utils/pdfmaker/builder/compras/operaciones/orden-compra.pdfmaker';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ComprasService, ConfigurationService } from 'src/app/services';
import { SolicitudCompraService } from 'src/app/services/api/compras/operaciones/solicitud-compra.service';
import { IParam } from 'src/app/services/api/utilities/params';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { FiltroAprobacionSolicitudCompra } from './models/filtro-aprobacion-solicitud-compra';

@Component({
  selector: 'app-aprobacion-solicitud-compra',
  templateUrl: './aprobacion-solicitud-compra.component.html',
  styleUrls: ['./aprobacion-solicitud-compra.component.css']
})
export class AprobacionSolicitudCompraComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['select', 'acciones', 'rco_numrco', 'rco_fecreq', 'rco_clipro', 'proveedor', 'solicitado_por', 'tmo_codtmo', 'rco_facimp'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  loaderReg: boolean = false;
  loaderData: boolean = false;
  loaderAprobar: boolean = false;
  loaderDesaprobar: boolean = false;
  selection = new SelectionModel<any>(true, []);

  proveedores: any[] = [];
  buttonsName: INameConstant = NAMES_CONSTANTS;

  btnName: string;

  loading$: Subscription;
  aprobacion$: Subscription;

  totalMN: number = 0;
  totalME: number = 0;

  filtro: FiltroAprobacionSolicitudCompra;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _clienteService: ClienteService,
    private _comprasService: ComprasService,
    private _solicitudCompraService: SolicitudCompraService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.filtro = new FiltroAprobacionSolicitudCompra();

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadMaestros();
    })
  }

  loadMaestros(): void {
    this.loaderData = true;
    this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.proveedores = clientes.filter(item => item.cli_indpro == 1);
        this.obtenerSolicitudesDeCompra();
      },
      error => {
        this._snackBarService.showError('Error al obtener proveedores', 'OK');
        this.loaderData = false;
      }
    )

  }

  obtenerSolicitudesDeCompra(): void {

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.selection.clear();

    this.loaderData = true;

    let params: Array<IParam> = [
      {
        param: 'aprobados',
        value: this.filtro.estaAprobado
      }
      // {
      //   param: 'usuarioaprobador',
      //   value: this._configurationService.obtenerIdUsuario()
      // },
    ];

    if (this.filtro.proveedor) {
      params.push({
        param: 'proveedor',
        value: this.filtro.proveedor
      })
    }

    if (this.filtro.fechaSeleccionada) {
      if (this.filtro.desde && this.filtro.hasta) {
        params.push({
          param: 'desde',
          value: formatDateWithDash(this.filtro.desde)
        });
        params.push({
          param: 'hasta',
          value: formatDateWithDash(this.filtro.hasta)
        });
      }
    }

    console.log('params', params);


    this._solicitudCompraService.obtenerSolicitudesCompra({ params }).subscribe(
      response => {

        this.totalMN = 0;
        this.totalME = 0;

        const filtrados = response.filter(item => true);

        filtrados.forEach(item => {

          item['proveedor'] = item.proveedor.cli_nomcli;
          item['solicitado_por'] = item.solicitante.cli_nomcli;

          item['importe'] = numberWithCommas((parseFloat((item.rco_facimp))).toFixed(2));

          (item.tmo_codtmo == 'SO') ? this.totalMN += item.rco_facimp : this.totalME += item.rco_facimp;

        })

        this.totalMN = numberWithCommas(parseFloat(((this.totalMN)).toFixed(2)));
        this.totalME = numberWithCommas(parseFloat(((this.totalME)).toFixed(2)));

        console.log('response', response)
        this.dataSource = fillTable(filtrados, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  aprobar(): void {
    if (this.selection.selected.length == 0) {
      this._snackBarService.showError('No se seleccionaron registros', 'Ok');
      return;
    }
    const payload = this.selection.selected.map(item => ({
      cia_codcia: this._configurationService.obtenerCompaniaCliente(),
      rco_numrco: item.rco_numrco,
      rco_aprrco: 1,
      rco_apruse: this._configurationService.obtenerIdUsuario()
    }))

    this.aprobacion$ = this._solicitudCompraService.aprobarSolicitudesCompra(payload).subscribe(
      response => {
        this._snackBarService.showSuccess('Solicitudes de compras aprobadas', 'Ok');
        this.selection.clear();
        this.obtenerSolicitudesDeCompra();
        this.loaderAprobar = false;
      },
      error => {
        this._snackBarService.showError('Error al aprobar solicitudes de compra', 'Ok')
        this.loaderAprobar = false;
      }
    )
  }

  desaprobar(): void {
    if (this.selection.selected.length == 0) {
      this._snackBarService.showError('No se seleccionaron registros', 'Ok');
      return;
    }
    const payload = this.selection.selected.map(item => ({
      cia_codcia: this._configurationService.obtenerCompaniaCliente(),
      rco_numrco: item.rco_numrco,
      rco_aprrco: 0,
      rco_apruse: this._configurationService.obtenerIdUsuario()
    }))

    this.aprobacion$ = this._solicitudCompraService.aprobarSolicitudesCompra(payload).subscribe(
      response => {
        this._snackBarService.showSuccess('Solicitudes de compras aprobadas', 'Ok');
        this.selection.clear();
        this.obtenerSolicitudesDeCompra();
        this.loaderAprobar = false;
      },
      error => {
        this._snackBarService.showError('Error al aprobar solicitudes de compra', 'Ok')
        this.loaderAprobar = false;
      }
    )
  }

  mostrarPdf(id): void {
    const headers = {
      username: this._configurationService.obtenerIdUsuario()
    }

    this._comprasService.obtenerPdfOrdenCompra(id).subscribe(
      response => {
        if (response.length > 0 && response != null) {
          // verPdfReport(headers, response[0]);
          generarFormatoOrdenCompra(headers, response[0]);

        }
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  buscar(): void {
    this.obtenerSolicitudesDeCompra();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  verMasOpciones(): void { }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
