export class FiltroAprobacionRequerimientoPago {
  proveedor: string;
  tipoFecha: 'FE' | 'FP';
  desde: Date;
  hasta: Date;
  fechaSeleccionada: boolean;
  estaAprobado: boolean;

  constructor() {
    this.tipoFecha = 'FE';
    this.estaAprobado = false;
  }
}
