import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { GrupoActivoFijo } from 'src/app/models/activo-fijo/grupo_activo_fijo';
import { GrupoActivoService } from 'src/app/services/api/activo-fijo/maestros/grupo-activo.service';
import { TipoActivoService } from 'src/app/services/api/activo-fijo/maestros/tipo-activo.service';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';

@Component({
  selector: 'app-grupo-activo-form',
  templateUrl: './grupo-activo-form.component.html',
  styleUrls: ['./grupo-activo-form.component.css']
})
export class GrupoActivoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  tipo$: Subscription;
  grupoActivo$: Subscription;
  loading$: Subscription;

  tiposActivo: any[] = [];

  grupoActivo: GrupoActivoFijo;

  uid: string;

  constructor(
    private _router: Router,
    private _grupoActivoService: GrupoActivoService,
    private _tipoActivoService: TipoActivoService,
    private _snackBarService: SnackBarService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {

    this.grupoActivo = new GrupoActivoFijo();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.loadMaestros();
        if(id == '0') return;

        this.grupoActivo$ = this._grupoActivoService.obtenerGrupoActivo(id).subscribe(
          ([grupo]) => {

            this.grupoActivo = grupo;

            this.usuario = grupo.gac_coduse;
            this.fecha = grupo.gac_fecupd;
          }
        )

      }
    )
  }

  loadMaestros(): void {
    this.tipo$ = this._tipoActivoService.listarTiposActivo().subscribe(
      tipos => {
        this.tiposActivo = tipos;
      },
      _ => {
        this._snackBarService.showError("Error al obtener tipos activos", "OK");
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarGrupoActivo(f): this.actualizarGrupoActivo(f);
  }

  registrarGrupoActivo(f: NgForm): void {

  }

  actualizarGrupoActivo(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/modulo-activo-fijo/grupo-de-activo']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.grupoActivo$,
      this.tipo$,
      this.loading$
    ])
  }
}
