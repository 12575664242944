import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { FlujoCaja } from 'src/app/models/caja-banco/flujo_caja';
import { GrupoFlujoCaja } from 'src/app/models/caja-banco/grupo_flujo_caja';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CajaBancoService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @returns
   */
  obtenerCajayBancos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/listarcajaybancos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param codigo
   * @returns
   */
  mostrarCajaBanco(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/caja-bancos/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`
    );
  }

  /**
   *
   * @param codigo
   * @returns
   */
  obtenerCajaBanco(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/caja-bancos/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`
    );
  }

  /**
   *
   * @param cli_codcli
   * @param periodo
   * @returns
   */
  obtenerCuentaxPagar(cli_codcli, periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxpagar/listar?cia=${this._configurationService.obtenerCompaniaCliente()}&cli=${cli_codcli}&periodo=${periodo}`
    );
  }

  /**
  *
  * @param cli_codcli
  * @param periodo
  * @returns
  */
  obtenerCuentaxPagarPorPeriodo(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxpagar/listar?cia=${this._configurationService.obtenerCompaniaCliente()}&periodo=${periodo}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerCobradores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablastesoreria/listacobrador/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerCuentasContable(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablastesoreria/listacuentacon/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerCuentasContabilidad(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablastesoreria/listacuentacon/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerCuentasBancarias(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablastesoreria/listacuentabanc/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((bancos: any) => {
        return bancos.map(banco => {
          banco['CBA_CODCBA'] = banco['CBA_CODCBA'].trim()
          banco['description'] = `${banco.CBA_CODCBA} | ${banco.CBA_DESCRI}`

          return banco;
        })
      })
    );
  }

  /**
   *
   * @returns
   */
  obtenerChequesBanco(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablastesoreria/listachequebanc/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param year
   * @param tmo
   * @returns
   */
  obtenerReporteAcumuladoFlujoCajaPorCategoria(year, tmo, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-acumulado-flujo-caja-por-categoria/${this._configurationService.obtenerCompaniaCliente()}/${year}/${tmo}`,
      payload
    );
  }

  obtenerReporteEstadoCuentaPorProveedor(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-estado-cuenta-por-proveedor/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteDetalladoFlujoCaja(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-detallado-de-flujo-de-caja/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteMovimientoCajaBancoSaldoInicial(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-movimiento-caja-banco-con-saldo-inicial/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteCuentaPagarTabuladoConSaldo(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-cuentas-por-pagar-tabulado-doc-con-saldo/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReporteCuentaPagarConSaldo(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-cuentas-por-pagar-doc-con-saldo/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    );
  }

  /**
   *
   * @param date
   * @returns
   */
  obtenerReporteSaldoPagarFechaCorteDocSaldo(date, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-saldos-por-pagar-fecha-corte-doc-saldo/${this._configurationService.obtenerCompaniaCliente()}/${date}`,
      payload
    );
  }

  /**
   *
   * @param date
   * @returns
   */
  registraBanco(date): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-saldos-por-pagar-fecha-corte-doc-saldo/${this._configurationService.obtenerCompaniaCliente()}/${date}`
    );
  }

  /**
   *
   * @param date
   * @returns
   */
  actualizarBanco(date): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-saldos-por-pagar-fecha-corte-doc-saldo/${this._configurationService.obtenerCompaniaCliente()}/${date}`
    );
  }

  obtenerDataFiltroCuentasPagar(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/filtro-reporte/cuentas-por-pagar/${this._configurationService.obtenerCompaniaCliente()}`
    )
  }

  obtenerReporteDinamicoDocumentoPagar(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-dinamico-documentos-por-pagar/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  obtenerReporteDinamicoDetalladoPagos(codano: string, codmes: string, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/reporte-dinamico-detallado-de-pagos/${this._configurationService.obtenerCompaniaCliente()}/${codano}/${codmes}`,
      payload
    )
  }

  obtenerDataFiltroConsultaTesoreria(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxpagar/data/filtro/consulta-tesoreria/${this._configurationService.obtenerCompaniaCliente()}`
    )
  }

  obtenerConsultaDocumentoTesoreria(filtro): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxpagar/consulta-documento-tesoreria/${this._configurationService.obtenerCompaniaCliente()}`,
      filtro
    )
  }

  obtenerDetalleConsultaDocumentoTesoreria(codcco): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxpagar/detalle-consulta-documento-tesoreria/${this._configurationService.obtenerCompaniaCliente()}/${codcco}`
    )
  }

  enviarMensajesMasivoWhatsapp(payload): Observable<any> {

    return this.http.post<any>(
      `http://localhost:3000/sendmessage`,
      payload
    )

  }

  enviarMensajesMasivoMail(payload): Observable<any> {

    return this.http.post<any>(
      `http://localhost:3000/sendemail`,
      payload
    )

  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerReportePagosEfectuadosProveedor(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/data/reporte/pago-efectuado-proveedor/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

}
