import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "../../configuration.service";
import { Observable } from "rxjs";
import axios from "axios";

const URL_CONEFACT = "https://visor.contefact.com";

@Injectable({
  providedIn: 'root',
})
export class ContefactService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerXMLBase64CONTEFACT(documentos): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/conefact/xml`,
      documentos
    );
  }

  obtenerDatosRUCCONTEFACT(ruc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/contefact/obtener-datos/${ruc}`
    );
  }

  obtenerXMLBase64CONTEFACTInd(documento): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/conefact/xml/individual`,
      documento
    );
  }

  obtenerXMLBase64CONTEFACTIndividual(documento): Promise<any> {

    const headers = {
      'Authorization': `${this._configurationService.obtenerTokenCliente()}`
    };

    return new Promise(async (resolve, reject) => {

      const data = await axios.post(`${this._configurationService.obtenerUrl()}/api/registrocompras/conefact/xml/individual`, documento, {headers});
      resolve(data.data);
    })

  }

}
