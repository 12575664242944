import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { TipoAtencion } from "src/app/models/crm/tipo-atencion";
import { ConfigurationService, CrmService } from "src/app/services";
import { TipoAtencionService } from "src/app/services/api/crm/maestros/tipo-atencion.service";

@Component({
  selector: 'tipo-atencion-form',
  templateUrl: 'tipo-atencion-form.component.html',
  styleUrls: ['tipo-atencion-form.component.scss']
})
export class TipoAtencionFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  sendForm$: Subscription;
  loading$: Subscription;
  tipoAtencion$: Subscription;

  tipoAtencion: TipoAtencion;

  constructor(
    private store: Store<PavsoState>,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _crmService: CrmService,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private _tipoAtencionService: TipoAtencionService,
  ) {

    this.tipoAtencion = new TipoAtencion();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;

      this.obtenerTipoAtencion();
    })
  }

  obtenerTipoAtencion(): void {
    this.tipoAtencion$ = this._tipoAtencionService.obtenerTipoAtencion(this.uid).subscribe(
      tipo => this.tipoAtencion = tipo[0],
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;

    this.uid == '0' ? this.registrarTipoAtencion(f) : this.actualizarTipoAtencion(f)
  }

  /**
   * Método para registrar un nuevo tipo de atención.
   * @param f
   */
  registrarTipoAtencion(f: NgForm): void {
    this.tipoAtencion.coduse = this._configurationService.obtenerIdUsuario();

    this.sendForm$ = this._tipoAtencionService.registrarTipoAtencion(this.tipoAtencion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.tipo-atencion.success-store', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para actualizar los datos de un tipo de atención existente
   * @param f
   */
  actualizarTipoAtencion(f: NgForm): void {
    this.sendForm$ = this._tipoAtencionService.actualizarTipoAtencion(this.tipoAtencion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.tipo-atencion.success-update', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {
    if(!this.tipoAtencion.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    return true;
  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/tipos-de-atencion'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.loading$,
      this.tipoAtencion$,
    ])
  }
}
