import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { ContabilidadService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { numberWithCommas } from '@utils/formats/number.format';
import { MatDialog } from '@angular/material/dialog';
import { ConsultasMovimientosContablesDialog } from '../dialogs/consultas-movimientos-contables/consultas-movimientos-contables.dialog';

class FiltroConsultaSaldosMensuales {
  cuenta: string;
  moneda: string;

  constructor() {
    this.moneda = 'SO'
  }
}

@Component({
  selector: 'app-consulta-saldos-mensuales',
  templateUrl: './consulta-saldos-mensuales.component.html',
  styleUrls: ['./consulta-saldos-mensuales.component.css']
})
export class ConsultaSaldosMensualesComponent implements OnInit, OnDestroy {

  selected: any;

  displayedColumns: string[] = ['mes_desmes', 'scu_salini', 'scu_debmna', 'scu_habmna', 'saldo_final'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  cuentas: any[] = [];
  monedas: any[] = [];

  year: string;

  filtro: FiltroConsultaSaldosMensuales = new FiltroConsultaSaldosMensuales();

  totalSaldoInicial: number = 0;
  totalDebe: number = 0;
  totalHaber: number = 0;
  totalSaldoFinal: number = 0;

  period$: Subscription;
  loading$: Subscription;

  loaderData: boolean;
  loaderReg: boolean;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _generalService: GeneralService,
    public dialog: MatDialog
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadMaestro();
    })
  }

  loadMaestro(): void {
    this._contabilidadService.listarPlanDeCuentas().subscribe(
      cuentas => {
        this.cuentas = cuentas;

        this._generalService.obtenerMonedas().subscribe(
          monedas => {
            this.monedas = monedas;
          },
          _ => {
            this._snackBarService.showError("Error al obtener monedas", "OK");
          }
        )
      },
      _ => {
        this._snackBarService.showError("Error al obtener cuentas", "OK")
      }
    )
  }

  formatoImporte(importe: any) : string {

    return numberWithCommas(importe.toFixed(2))

  }

  buscarRegistros(): void {

    if(!this.filtro.cuenta) {
      this._snackBarService.showError("Debe seleccionar una cuenta", "OK");
      return;
    }

    this._contabilidadService.verConsultaSaldosMensuales(this.year, this.filtro.cuenta).subscribe(
      consulta => {
        this.dataSource = fillTable(consulta, this.paginator, this.sort);

        this.totalSaldoInicial = 0;
        this.totalDebe = 0;
        this.totalHaber = 0;
        this.totalSaldoFinal = 0;

        this.dataSource.data.forEach(element => {
          this.totalSaldoInicial += element.scu_salini;
          this.totalDebe += element.scu_debmna;
          this.totalHaber += element.scu_habmna;
          this.totalSaldoFinal += element.saldo_final;
        });
      },
      _ => {
        this._snackBarService.showError("Error al obtener consulta de saldos mensuales", "OK");
      }
    )
  }

  selectRow(row): void {
    this.selected = row;
  }

  seleccionarCuenta(): void {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  consultarMovimientos(): void {

    if(!this.selected) {
      this._snackBarService.showError("Debe seleccionar un registro de la tabla", "OK");
      return;
    }

    const dialogRef = this.dialog.open(ConsultasMovimientosContablesDialog, {
      width: 'auto',
      data: {
        moneda: this.filtro.moneda,
        cuenta: this.filtro.cuenta,
        codmes: this.selected.mes_codmes
      } as {moneda: string, cuenta: string, codmes: string},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
    ]);
  }

}
