import { Component, Inject, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: './snackbar-error',
  templateUrl: './snackbar-error.component.html',
  styleUrls: ['./snackbar-error.component.scss']
})
export class SnackbarErrorComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    ) {
      this.playSound()
    }

  ngOnInit(): void {}

  closeSnackbar(): void {
    this.data.snackBar.dismiss();
  }

  playSound() {
    // const audio = new Audio('assets/mp3/error.mp3');
    // audio.play();
  }

}
