<app-pavso-title-section [title]="'Configuración cuentas diferencia de cambio'" [module]="'Utilitarios'" p></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="configurar(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l6 xl3">
                  <mat-form-field>
                      <mat-label>Código Módulo</mat-label>
                      <input type="text" matInput placeholder="Código Módulo" name="codigo_modulo" required [disabled]="loaderData">
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l12 xl6">
                  <mat-form-field>
                      <mat-label>Glosa</mat-label>
                      <input type="text" matInput placeholder="Glosa" name="glosa" required [disabled]="loaderData">
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l6 xl4">
                  <mat-form-field>
                      <mat-label>Cta. Diferencia Cambio por Pérdida</mat-label>
                      <mat-select required name="cambio_perdida" [disabled]="loaderData">
                          <mat-option value="1">
                              item 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6 xl4">
                  <mat-form-field>
                      <mat-label>Cta. Diferencia Cambio por Ganancia</mat-label>
                      <mat-select required name="cambio_ganancia" [disabled]="loaderData">
                          <mat-option value="1">
                              item 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6 xl4">
                  <mat-form-field>
                      <mat-label>Cta. Traslación por Pérdida</mat-label>
                      <mat-select required name="traslacion_perdida" [disabled]="loaderData">
                          <mat-option value="1">
                              item 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6 xl4">
                  <mat-form-field>
                      <mat-label>Cta. Traslación por Ganancia</mat-label>
                      <mat-select required name="traslacion_ganancia" [disabled]="loaderData">
                          <mat-option value="1">
                              item 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6 xl4">
                  <mat-form-field>
                      <mat-label>Tamaño Máximo de Cta. Contable</mat-label>
                      <input type="text" matInput placeholder="Tamaño Máximo de Cta. Contable" name="tamanio" required [disabled]="loaderData">
                  </mat-form-field>
              </div>

          </div>

      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
