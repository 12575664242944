import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'PAV Solutions';

  VAPID_PUBLIC_KEY = "BIZXHXWa9oGk9AdkUIDGYxr6sGyobhsgNDj4Ivq8Jp85RosJt6WEiUGE7aGNsH5rrkl1DV07W3tuyNifTjLEVDc";

  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  // cambio de validacion
  constructor(
    private swUpdate: SwUpdate,
    private matIconRegistry: MatIconRegistry,
    // private _swPush: SwPush,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,

  ) {

    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.matIconRegistry.addSvgIcon(
      "search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "configuration",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/configuration.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "notification",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/notification.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-bottom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/arrow-bottom.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "calendar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/calendar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/edit/edit.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "edit-white",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/edit/edit-white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "check",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/check.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "check4",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/check4.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "espaniol",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/espaniol.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "united-states",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/united-states.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "email",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/email.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "profile",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/profile.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "logout",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/logout.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "setting",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/compras/setting.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "generate",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/compras/generate.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "document-payment",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/compras/document-payment.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "document-error",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/compras/document-error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "copy",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/compras/copy.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "distribute",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/compras/distribute.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "print",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/compras/print.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "xml",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/compras/xml.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "home",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/home.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "app",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/app.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "paper",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/paper.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "profile",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/profile.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "perfil",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/profile.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "more",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/more.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "like",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/like.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "dislike",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/dislike.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "paper2",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/paper2.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "more-horizontal",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/more-horizontal.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "more-vertical",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/more-vertical.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "correo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/correo.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "importacion",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/importacion.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "almacen",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/almacen.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "operaciones",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/operaciones.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ventas",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/ventas.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "produccion",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/produccion.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "crm",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/crm.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "punto-de-venta",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/punto-de-venta.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "contabilidad",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/contabilidad.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "costos",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/costos.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "cobranzas",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/cobranzas.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "compras",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/compras.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "libro-electronico",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/libro-electronico.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "activo-fijo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/activo-fijo.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "utilitarios",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/utilitarios.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "caja-banco",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/caja-banco.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "dashboard",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/dashboard.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "planilla",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu/planilla.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "one",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/numbers/one.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/delete.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "confirm",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/confirm.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/add.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "download",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/download.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "period",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/period.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "menu",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/menu.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "atencion",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/atencion.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "dark",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/dark.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "light",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/light.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "seguimientos",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/seguimientos.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "pdf",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/pdf.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "whatsapp",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/whatsapp.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "mail",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/mail.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "active",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/active.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "inventario",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/inventario.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "extraer",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/extraer.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "alert",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/alert.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "grabar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/grabar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "eliminar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/eliminar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "anular",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/anular.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "volver",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/volver.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "formato",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/formato.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "mas-opciones",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/mas-opciones.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "refresh",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/refresh.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "excel",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/excel.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "descuento",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/descuento.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "copiar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/copiar.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "promocion",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/promocion.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "quitar-item",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/quitar-item.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "remove",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/remove.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/add-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add-circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/add-circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "stock",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/stock.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "report",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/report.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "generate",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/generate.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add-modal-detail",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/add-modal-detail.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "check-circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/check-circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add-lote",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/add-lote.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "send",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/send.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "send2",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/send2.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "add-operation",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/add-operation.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "money-receive-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/MoneyReceiveIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "money-send-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/MoneySendIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "file-user-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/FileUserIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "money-bag-dollar-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/MoneyBagDollarIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "money-donation-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/MoneyDonationIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "person-square-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/PersonSquareIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "square-vector-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/SquareVectorIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "twice-square-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/TwiceSquareIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "archive-down-minimalistic-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/ArchiveDownMinimalisticIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "money-home-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/MoneyHomeIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "percent-house-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/PercentHouseIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "invoice-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/InvoiceIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "shipping-guide-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/ShippingGuideIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ia-vector-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/AiVectorIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "mop-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/MopIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "organization-chart-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/OrganizationChartIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sunat-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/SunatIcon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "upload-square-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg/buttons/UploadSquareIcon.svg")
    );
  }

  ngOnInit(): void {
    this.checkNetworkStatus();

    if (this.swUpdate.isEnabled) {

      this.swUpdate.activateUpdate().then(
        response => { }
      )

      this.swUpdate.checkForUpdate().then(
        response => { }
      )

      this.swUpdate.available.subscribe(() => {

        if (confirm("Nueva Versión del Sistema Disponible. ¿Cargar nueva Versión?")) {

          window.location.reload();
        }
      });
    }



    this.requestSubscription();
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  // To check internet connection stability
  checkNetworkStatus(): void {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        console.log('STATUS', status)
        this.networkStatus = status;
      });
  }

  requestSubscription = () => {
    // if (!this._swPush.isEnabled) {
    //   console.log("Notification is not enabled.");
    //   return;
    // }

    // this._swPush.notificationClicks.subscribe(x => console.log(x));

    // this._swPush.requestSubscription({
    //   serverPublicKey: 'BIZXHXWa9oGk9AdkUIDGYxr6sGyobhsgNDj4Ivq8Jp85RosJt6WEiUGE7aGNsH5rrkl1DV07W3tuyNifTjLEVDc'
    // }).then((response) => {
    //   console.log(JSON.stringify(response));
    // }).catch((error) => console.log(error));
  };

}
