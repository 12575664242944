import { Component, ElementRef, HostListener, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "pav-sliver-actions",
  template: `
    <div class="fixedTopContent">
      fsad
    </div>
  `,
  styleUrls: ["./pav-sliver-actions.component.scss"]
})
export class PavSliverActions{

}
