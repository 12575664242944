<app-pavso-title-section [title]="'CONTABILIDAD'" [module]="'CONFIGURACIÓN EEF'" [usuario]="configuracion.cef_coduse" [fecha]="configuracion.cef_fecupd" [ngClass]="'pav-form'" [estado]="configuracion.cef_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="configuracion.cef_codcef" matInput placeholder="Código" name="cef_codcef" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l9">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="configuracion.cef_descri" matInput placeholder="Descripción" name="cef_descri" required>
          </mat-form-field>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col s12">
          <div class="mat-elevation-z0">
            <table mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="ced_corced">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cód. Nivel </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.ced_corced}} </td>
              </ng-container>

              <ng-container matColumnDef="ced_descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.ced_descri}} </td>
              </ng-container>

              <ng-container matColumnDef="aef_codaef">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Anexo </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.aef_codaef}} </td>
              </ng-container>

              <ng-container matColumnDef="ced_indsig">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Signo </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ced_indsig}} </td>
              </ng-container>

              <ng-container matColumnDef="t34_codt34">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rubro EE.FF. </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.t34_codt34}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">No se encontraron registros</td>

              </tr>

            </table>

            <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col s12">
          <button mat-flat-button color="primary" type="button" (click)="verAnexo()">Anexo</button>
          &nbsp;
          <button mat-flat-button color="primary" type="button">Verifica Cuentas</button>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
