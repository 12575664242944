import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { HeaderReport } from "@data/interfaces/headers.report";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_PLANILLA_INTERFACE_CONTABLE } from "@data/json/reportes/planilla.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subject, Subscription } from "rxjs";
import { FiltroReportePlanilla } from "src/app/models/planilla/filtro-reporte-planilla";
import { ContabilidadService, NominaService } from "src/app/services";
import { HeaderReportService } from "src/app/services/reports/config/header-report.service";
import { NominaReportService } from "src/app/services/reports/modules/nomina-report.service";

@Component({
  selector: 'informes-interface-contable',
  templateUrl: './informes-interface-contable.component.html',
  styleUrls: ['./informes-interface-contable.component.scss']
})
export class InformesInterfaceContableComponent implements OnInit, OnDestroy {

  reportes: any[] = REPORTES_PLANILLA_INTERFACE_CONTABLE;
  reporteSeleccionado: any = [REPORTES_PLANILLA_INTERFACE_CONTABLE[0].id];

  mostrarFiltroAuxiliar: 0 | 1 = 0;
  mostrarFiltroCentro: 0 | 1 = 0;
  mostrarFiltroConcepto: 0 | 1 = 0;
  mostrarFiltroAnual: 0 | 1 = 0;
  mostrarFiltroRangoFecha: 0 | 1 = 0;

  loading: boolean = false;

  year: string;
  month: string;

  yearIni: number;
  monthIni: string;
  yearFin: number;
  monthFin: string;

  trabajadores: any[] = [];
  centros: any[] = [];
  conceptos: any[] = [];


  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  rbAnualMensual: "M" | "A" = "M";

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  loading$: Subscription;
  indicadores$: Subscription;
  colaboradores$: Subscription;
  centros$: Subscription;

  months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  filtro: FiltroReportePlanilla;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _headerReportService: HeaderReportService,
    private _nominaReportService: NominaReportService,
    private _snackBarService: SnackBarService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>
  ) {
    this.filtro = new FiltroReportePlanilla();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;

      this.monthIni = "01";
      this.yearIni = Number(this.year);
      this.monthFin = this.month;
      this.yearFin = Number(this.year);
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  /**
   * Obtiene los maestros de :
   * . colaboradores
   * . centros de costo
   */
  loadData(): void {
    this.seleccionarReporte(REPORTES_PLANILLA_INTERFACE_CONTABLE[0].id);
    this.colaboradores$ = this._nominaService.listarColaboradores().subscribe(
      colaboradores => {
        this.trabajadores = colaboradores;
        this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
          centros => {
            this.centros = centros;
          },
          error => this._snackBarService.showError("Error al obtener centro de costos", "OK")
        )
      },
      error => this._snackBarService.showError("Error al obtener Colaboradores", "OK")
    )
  }

  selectReport(id): void {
    this.reporteSeleccionado[0] = id;
    this.seleccionarReporte(id);
  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;

    let indicador = "";
    this.mostrarFiltroAnual = 0;
    this.mostrarFiltroAuxiliar = 1;
    this.mostrarFiltroCentro = 1;
    this.mostrarFiltroRangoFecha = 0;

    switch (this.reporteSeleccionado[0]) {
      case "PLANILLA_VTAP":
        indicador = "00000"
        break;
      case "PLANILLA_VPV":
        indicador = "00000"
        this.mostrarFiltroAnual = 1;
        this.mostrarFiltroRangoFecha = 1;
        break;
      case "PLANILLA_VPC":
        indicador = "00000"
        this.mostrarFiltroAnual = 1;
        this.mostrarFiltroRangoFecha = 1;
        break;
      case "PLANILLA_VPG":
        indicador = "00000"
        this.mostrarFiltroAnual = 1;
        this.mostrarFiltroRangoFecha = 1;
        break;
      // case "PLANILLA_APGCV":
      //   indicador = "00000"
      //   break;
      default:
        break;
    }

    this.indicadores$ = this._contabilidadService.obtenerInidicadoresFiltro(indicador).subscribe(
      ([response]) => {
        if (response) {
          this.mostrarFiltroAuxiliar = response.ind_auxiliar;
          this.mostrarFiltroCentro = response.ind_centrocosto;
          this.mostrarFiltroConcepto = response.ind_conceptoplanilla;
        }
      }
    )
  }

  verReporte(): void {

    this.loading = true;

    const headers: HeaderReport = this._headerReportService.obtenerCabecera();

    const payload = {
      colaboradores: (this.filtro.colaboradores) ? this.filtro.colaboradores : [],
      conceptos: (this.filtro.conceptos) ? this.filtro.conceptos : [],
      centros: (this.filtro.centros) ? this.filtro.centros : [],
      yearIni: Number(this.yearIni),
      monthIni: Number(this.monthIni),
      yearFin: Number(this.yearFin),
      monthFin: Number(this.monthFin),
    };

    const otherData = {
      rbAnualMensual: this.rbAnualMensual,
      periodoini: `${this.yearIni}/${this.monthIni}`,
      periodofin: `${this.yearFin}/${this.monthFin}`
    }

    this._nominaReportService.verReporteInterfaceContable(this.reporteSeleccionado[0], headers, payload, otherData)
      .then(_ => this.loading = false)
      .catch(_ => this.loading = false)
      .finally(() => this.loading = false)

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.indicadores$,
      this.colaboradores$,
      this.centros$
    ]);
  }
}
