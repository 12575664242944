import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { fillTable } from "@utils/tables/table";

@Component({
  selector: 'resumen-documentos-validos',
  templateUrl: './resumen-documentos-validos.component.html',
  styleUrls: ['./resumen-documentos-validos.component.scss']
})
export class ResumenDocumentosValidosComponent implements OnChanges {

  @Input() documentosValidos: Array<any> = [];
  @Input() documentosNoValidos: Array<any> = [];

  displayedColumnsValidos: string[] = ['nro', 'cpe'];
  dataSourceValidos: MatTableDataSource<any>;
  @ViewChild("paginatorValidos") paginatorValidos: MatPaginator;
  @ViewChild(MatSort) sortValidos: MatSort;

  displayedColumnsNoValidos: string[] = ['nro', 'documento', 'cpe'];
  dataSourceNoValidos: MatTableDataSource<any>;
  @ViewChild("paginatorNoValidos") paginatorNoValidos: MatPaginator;
  @ViewChild(MatSort) sortNoValidos: MatSort;

  constructor() {
    this.dataSourceValidos = fillTable([], this.paginatorValidos, this.sortValidos);
    this.dataSourceNoValidos = fillTable([], this.paginatorNoValidos, this.sortNoValidos);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.documentosValidos) {
      this.dataSourceValidos = fillTable( changes.documentosValidos.currentValue, this.paginatorValidos, this.sortValidos );
    }

    if(changes.documentosNoValidos) {
      console.log('documentos no validos', changes.documentosNoValidos.currentValue)
      this.dataSourceNoValidos = fillTable( changes.documentosNoValidos.currentValue, this.paginatorNoValidos, this.sortNoValidos );
    }
  }
}
