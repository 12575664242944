import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TipoServicioProveedor } from 'src/app/models/compras';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({providedIn: 'root'})
export class TipoServicioProveedorService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarTiposServicioProveedor(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/tiposervicioproveedor/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  registrarTipoServicioProveedor(tipo: TipoServicioProveedor): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/tipo-servicio-proveedor`,
      tipo
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  actualizarTipoServicioProveedor(tipo: TipoServicioProveedor): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/tipo-servicio-proveedor`,
      tipo
    );
  }

}
