<h1 mat-dialog-title>Editar Producto</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Cantidad</mat-label>
                <input matInput type="number" (keyup)="cambioCantidad()" (change)="cambioCantidad()" [(ngModel)]="data.map_canprd" name="map_canprd" [disabled]="disabledCantidad" min="0">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Cantidad O.C.</mat-label>
                <input matInput type="number" name="map_canocc" disabled min="0">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Lote Ref. Pro.</mat-label>
                <input matInput [(ngModel)]="data.map_lotmap" name="map_lotmap">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Sec</mat-label>
                <input matInput [(ngModel)]="data.map_secmap" name="map_secmap">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Costo U.M.N.</mat-label>
                <input matInput type="number" (keyup)="cambioCostoUMN()" (change)="cambioCostoUMN()" [(ngModel)]="data.map_cosmnp" name="map_cosmnp" [disabled]="disabledCost" min="0">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Costo U.M.E.</mat-label>
                <input matInput type="number" (keyup)="cambioCostoUME()" (change)="cambioCostoUME()" [(ngModel)]="data.map_cosmep" name="map_cosmep" [disabled]="disabledCost" min="0">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Imp. M.N. Prod.</mat-label>
                <input matInput type="number" [(ngModel)]="data.map_impmnp" name="map_impmnp" disabled min="0">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Imp. M.E. Prod.</mat-label>
                <input matInput type="number" [(ngModel)]="data.map_impmep" name="map_impmep" disabled min="0">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
            <mat-form-field>
                <mat-label>Glosa</mat-label>
                <input matInput [(ngModel)]="data.map_glomap" name="map_glomap">
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" type="button">{{buttonName.BTN_BACK | translate}}</button>
    <button mat-flat-button color="primary" (click)="actualizar()" type="button">Actualizar</button>
</div>
