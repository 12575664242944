<app-pavso-title-section [title]="'Contratos'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="contratos"
    [tHeaders]="tHeaders"

    title="Todos los contratos"
    url="/modulo-planilla/contrato-de-personal"
    entity=""
    [params]="['0']"
  ></pav-table-list>
</div>
