import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { UbicacionAlmacen } from "src/app/models/almacen/ubicacion-almacen";
import { AlmacenService } from "src/app/services";

@Component({
  selector: 'ubicacion-form',
  templateUrl: './ubicacion-form.component.html',
  styleUrls: ['./ubicacion-form.component.scss']
})
export class UbicacionFormComponent implements OnInit, OnDestroy {

  uid: string;
  btnName: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;

  loaderReg: boolean;
  loaderData: boolean;

  ubicacion: UbicacionAlmacen;

  loading$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _almacenService: AlmacenService
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
    })
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarUbicacion(f): this.actualizarUbicacion(f);
  }

  registrarUbicacion(f: NgForm): void {
    this._almacenService.registrarUbicacionAlmacen(this.ubicacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Ubicación de almacen registrado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  actualizarUbicacion(f: NgForm): void {
    this._almacenService.actualizarUbicacionAlmacen(this.ubicacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Ubicación de almacén actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-almacen/ubicaciones'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
