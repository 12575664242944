import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Product } from 'src/app/models';
import { ConfigurationService, GeneralService } from 'src/app/services';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { TipoInventarioService } from 'src/app/services/api/almacen/configuracion/tipo-inventario.service';
import { ColorService } from 'src/app/services/api/almacen/maestros/color.service';
import { UnidadMedidaService } from 'src/app/services/api/almacen/configuracion/unidad-medida.service';
import { SubfamiliaService } from 'src/app/services/api/almacen/tabla-apoyo/subfamilia.service';
import { ImagenProducto } from 'src/app/models/ventas/product';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FamiliaService } from 'src/app/services/api/almacen/maestros/familia.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fillTable } from '@utils/tables/table';
import { KardexService } from 'src/app/services/api/almacen/operaciones/kardex.service';
import { ConfiguracionTecnica } from 'src/app/models/almacen/inventario/configuracion-tecnica';
import { LineaProductoService } from 'src/app/services/api/produccion/maestros/linea-producto.service';
import { ModeloService } from 'src/app/services/api/almacen/maestros/modelo.service';
import { MarcaService } from 'src/app/services/api/almacen/maestros/marca.service';

@Component({
  selector: 'producto-form',
  templateUrl: './producto-form.component.html',
  styleUrls: ['./producto-form.component.css']
})
export class ProductoFormComponent implements OnInit, OnDestroy {


  images: ImagenProducto[] = [

    // 'https://http2.mlstatic.com/D_NQ_NP_2X_691287-MPE74342047255_022024-F.webp',
    // 'https://http2.mlstatic.com/D_NQ_NP_2X_817133-MPE54808562914_042023-F.webp',
    // 'https://http2.mlstatic.com/D_NQ_NP_2X_641939-MPE54804474067_042023-F.webp',
    // 'https://http2.mlstatic.com/D_NQ_NP_2X_691180-MPE74342177027_022024-F.webp',
    // 'https://http2.mlstatic.com/D_NQ_NP_2X_931860-MPE74341522037_022024-F.webp',
    // 'https://http2.mlstatic.com/D_NQ_NP_2X_617913-MPE74227820632_022024-F.webp'
  ];

  displayedColumns: string[] = ['alm_codalm', 'des_almacen', 'uba_coduba', 'des_ubicacion'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsStockMinimo: string[] = ['alm_codalm', 'des_almacen', 'stock_minimo', 'stock_maximo'];
  dataSourceStockMinimo: MatTableDataSource<any>;

  @ViewChild('paginatorStockMinimo') paginatorStockMinimo: MatPaginator;
  @ViewChild(MatSort) sortStockMinimo: MatSort;

  @Input() id: string;

  loaderData: boolean;
  loaderReg: boolean;

  producto: Product;

  tiposDeInventario: any[] = [];
  familias: any[] = [];

  /** filtrar subfamilias */
  subfamilias: any[] = [];

  colores: any[] = [];

  unidades: any[] = [];

  proveedores: any[] = [];

  lineasProducto: any[] = [];

  modelos: any[] = [];

  marcas: any[] = [];

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  tipoInventario$: Subscription;
  familia$: Subscription;
  subfamilia$: Subscription;
  color$: Subscription;
  unidad$: Subscription;
  loading$: Subscription;
  producto$: Subscription;
  productos$: Subscription;
  productoEncontrado$: Subscription;
  imagenesProducto$: Subscription;
  proveedores$: Subscription;
  periodo$: Subscription;
  ubicacionesAlmacen$: Subscription;
  lineas$: Subscription;
  modelos$: Subscription;
  marcas$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;
  uid: string;

  imagenesACargar: Array<any> = [];
  idProducto: string;
  dialogRef: MatDialogRef<any>;

  isMobile: boolean = false;
  isTablet: boolean = false;

  loaderBuscarProducto: boolean = false;
  loaderBuscarProductos: boolean = false;

  @ViewChild('firstInput') firstInput: ElementRef;

  moduloActual: string;

  configuracion: ConfiguracionTecnica;

  constructor(
    private _dialogService: DialogService,
    private _productoService: ProductoService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _familiaService: FamiliaService,
    private _tipoInventarioService: TipoInventarioService,
    private _unidadMedidaService: UnidadMedidaService,
    private _subfamiliaService: SubfamiliaService,
    private _lineaProductoService: LineaProductoService,
    private readonly _clienteService: ClienteService,
    private readonly _generalService: GeneralService,
    private _colorService: ColorService,
    public _configurationService: ConfigurationService,
    private readonly _marcaService: MarcaService,
    private readonly _kardexService: KardexService,
    private readonly _modeloService: ModeloService,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver

  ) {

    this.initialize();

  }

  initialize(): void {
    this.producto = new Product();

    this.configuracion = new ConfiguracionTecnica();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.producto.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.producto.prd_coduse = this._configurationService.obtenerIdUsuario();
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        console.log('Is Mobile:', this.isMobile);
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        // this.isMobile = result.matches;
        console.log('result => ', result)
        this.isTablet = result.matches;
      });

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.moduloActual = state.codMod;

    })
  }

  ngOnInit(): void {

    setTimeout(() => {
      this.firstInput.nativeElement.focus(); // Refocus (in case any element stole focus)
      // this.firstInput._elementRef.nativeElement.select(); // Select the text for typing
    }, 100);

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });

  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({ id }) => this.uid = id);
    this.loadMaestros();

    this.btnName = this.buttonsName.BTN_ADD;
  }

  loadMaestros(): void {
    this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
      configuracion => {

        console.log('configuracion', configuracion)
        this.configuracion = configuracion[0];
        this.proveedores$ = this._clienteService.obtenerClientes().subscribe(
          clientes => {
            this.proveedores = clientes.filter(item => item.cli_indpro == 1);
            this.tipoInventario$ = this._tipoInventarioService.obtenerTiposDeInventario().subscribe(
              tiposInventario => {
                this.tiposDeInventario = tiposInventario;
                console.log('tipo de inventario', this.tiposDeInventario)
                this.familia$ = this._familiaService.obtenerFamiliasProducto().subscribe(
                  familias => {
                    this.familias = familias;
                    console.log('familias', this.familias)

                    this.color$ = this._colorService.obtenerColores().subscribe(
                      colores => {
                        this.colores = colores;

                        this.unidad$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                          unidades => {
                            this.unidades = unidades;

                            this.lineas$ = this._lineaProductoService.obtenerLineasProducto().subscribe(
                              lineas => {
                                this.lineasProducto = lineas;

                                this.modelos$ = this._modeloService.obtenerModelos().subscribe(
                                  modelos => {

                                    this.marcas$ = this._marcaService.obtenerMarcas().subscribe(
                                      marcas => {
                                        this.marcas = marcas;
                                        console.log('marcas', marcas)
                                        this.modelos = modelos;

                                        if (this.uid != '0') {
                                          this.obtenerProducto();
                                          return;
                                        };
                                        this.loaderData = false;
                                      },
                                      error => {
                                        this._snackBarService.showError('Error al obtener marcas', 'Ok');
                                        this.loaderData = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener modelos', 'Ok');
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener unidades de medida', 'Ok');
                                this.loaderData = false;
                              }

                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener unidades de medida', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener colores', 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener familias', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener tipos de inventario', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener proveedores', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener configuración técnica', 'Ok');
        this.loaderData = false

      }
    )

  }

  obtenerProducto(): void {
    console.log('obteniendo producto')
    this.ubicacionesAlmacen$ = this._generalService.obtenerUbicacionesAlmacen(this.uid).subscribe(
      ubicaciones => {

        this.dataSource = fillTable(ubicaciones, this.paginator, this.sort);

        this.producto$ = this._productoService.obtenerProducto(this.uid).subscribe(
          producto => {
            console.log('Producto', producto);
            this.loaderData = false;
            this.producto = producto;

            this.producto.prd_indser = producto.prd_indser == 1 ? true : false;
            this.producto.prd_indlot = producto.prd_indlot == 1 ? true : false;
            this.producto.prd_venlot = producto.prd_venlot == 1 ? true : false;
            this.producto.prd_indfab = producto.prd_indfab == 1 ? true : false;
            this.producto.prd_indven = producto.prd_indven == 1 ? true : false;
            this.producto.prd_indgen = producto.prd_indgen == 1 ? true : false;
            this.producto.prd_indcon = producto.prd_indcon == 1 ? true : false;
            this.producto.prd_indcom = producto.prd_indcom == 1 ? true : false;
            this.producto.prd_indtal = producto.prd_indtal == 1 ? true : false;
            this.seleccionarFamilia(this.producto.fpr_codfpr)
            this.idProducto = this.producto.prd_codprd;

            console.log({
              prd_indser: this.producto.prd_indser,
              prd_indlot: this.producto.prd_indlot,
              prd_venlot: this.producto.prd_venlot,
              prd_indgen: this.producto.prd_indgen,
              prd_indcon: this.producto.prd_indcon,
              prd_indcom: this.producto.prd_indcom,
              prd_indtal: this.producto.prd_indtal,
            })

            this.imagenesProducto$ = this._productoService.obtenerImagenesProducto(this.producto.prd_codprd.trim()).subscribe(
              imagenes => {
                console.log('=== IMAGeNES', imagenes)
                this.images = imagenes.map(item => item);
              },
              error => {
                this._snackBarService.showError('Error al obtener imagenes del producto', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener producto', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener ubicaciones almacén', 'Ok');
        this.loaderData = false;
      }
    )
  }

  buscarProducto(): void {
    this.loaderBuscarProducto = true;
    this.productoEncontrado$ = this._productoService.obtenerProducto(this.producto.prd_codprd).subscribe(
      producto => {
        this.loaderBuscarProducto = false;
        this.producto = producto;
        this.producto.prd_codprd = null;
      },
      error => {
        this._snackBarService.showError('Error al obtener producto', 'Ok');
        this.loaderBuscarProducto = false;
      }
    )
  }

  generarCodigo(): void {

    if (!this.producto.fpr_codfpr) {
      this._snackBarService.showError('Seleccione una familia', 'Ok');
      return;
    }

    this.loaderBuscarProductos = true;

    this.productos$ = this._productoService.obtenerProductos().subscribe(
      productos => {
        console.log(productos.length)
        const correlativo = (productos.length + 1) < 10 ? `000${productos.length + 1}` : `00${productos.length + 1}`;
        this.producto.prd_codprd = `${this.producto.fpr_codfpr}${correlativo}`;
        this.loaderBuscarProductos = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener producto', 'Ok');
        this.loaderBuscarProductos = false;
      }
    )
  }

  nuevoRegistro(): void {
    this.initialize();
    this.uid = '0';
  }

  seleccionarFamilia(idfamilia): void {
    console.log('cargando', idfamilia)
    this._subfamiliaService.obtenerSubFamilias(idfamilia).subscribe(
      response => {
        this.subfamilias = response;

        console.log('subfamilias', this.subfamilias)
      },
      error => this._snackBarService.showError(error.error.message, 'OK')
    )
  }

  eliminarImagen(img): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de eliminar esta imágen?', '', '', '').subscribe(result => {
      if (result) {
        this.images = this.images.filter(item => item.pri_idpri != img.pri_idpri);

        this._productoService.eliminarImagenProducto(img.pri_idpri).subscribe(
          response => { },
          error => this._snackBarService.showError(error.error.message, 'OK')
        )
      }
    })

  }

  imagenesSeleccioadas(imagenes): void {
    console.log('imagenes seleccionadas', imagenes)
    this.imagenesACargar = imagenes;
    // this.images = this.imagenesACargar.map(item => {
    //   const imagen = new ImagenProducto()

    //   imagen.pri_idpri = item.pri_idpri;
    //   imagen.pri_fotprd = item.img_docb64;
    //   imagen.prd_codprd = this.producto.prd_codprd;
    //   imagen.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    //   imagen.pri_secpri = 1;

    //   return imagen;
    // })
  }

  esValidoFormulario(): boolean {
    if(!this.producto.tin_codtin) {
      this._snackBarService.showError('Seleccione un tipo de inventario', 'Ok');
      return false;
    }

    if(!this.producto.ume_codume) {
      this._snackBarService.showError('Seleccione una unidad de medida', 'Ok');
      return false;
    }

    if(!this.producto.fpr_codfpr) {
      this._snackBarService.showError('Seleccione una familia', 'Ok');
      return false;
    }

    if(this.producto.prd_conten > 0) {

      if(!this.producto.ume_codctn) {
        this._snackBarService.showError('Seleccione un contenido de presentación', 'Ok');
        return false;

      }
    }

    return true;
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;

    if(!this.esValidoFormulario()) {
      this.loaderReg = false;
      return;
    }

    this.producto.prd_indser = this.producto.prd_indser || this.producto.prd_indser == 1 ? 1 : 0;
    this.producto.prd_indlot = this.producto.prd_indlot || this.producto.prd_indlot == 1 ? 1 : 0;
    this.producto.prd_venlot = this.producto.prd_venlot || this.producto.prd_venlot == 1 ? 1 : 0;
    this.producto.prd_indfab = this.producto.prd_indfab || this.producto.prd_indfab == 1 ? 1 : 0;
    this.producto.prd_indven = this.producto.prd_indven || this.producto.prd_indven == 1 ? 1 : 0;
    this.producto.prd_indgen = this.producto.prd_indgen || this.producto.prd_indgen == 1 ? 1 : 0;
    this.producto.prd_indcon = this.producto.prd_indcon || this.producto.prd_indcon == 1 ? 1 : 0;
    this.producto.prd_indcom = this.producto.prd_indcom || this.producto.prd_indcom == 1 ? 1 : 0;
    this.producto.prd_indtal = this.producto.prd_indtal || this.producto.prd_indtal == 1 ? 1 : 0;

    this.uid == '0' ? this.registrarProducto(f) : this.editarProducto(f);
  }

  async registrarProducto(f: NgForm) {

    this._productoService.registrarProducto(this.producto).subscribe(
      async response => {

        console.log('response producto', response)
        this.loaderReg = false;
        this.uid = response.prd_codprd;

        for (let index = 0; index < this.imagenesACargar.length; index++) {
          const element = this.imagenesACargar[index];
          const imagen = new ImagenProducto();
          imagen.prd_codprd = this.producto.prd_codprd;
          imagen.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          imagen.pri_secpri = 1;
          imagen.pri_fotprd = element.img_docb64;

          const a = await this._productoService.registrarImagenProductoSicrono(imagen);
        }


        this._productoService.obtenerImagenesProducto(this.producto.prd_codprd.trim()).subscribe(
          imagenes => {
            this.loaderReg = false;
            this._dialogService.openDialog(SuccessComponent, 'Producto Registrado', '300px', '', '');

            this.images = imagenes.map(item => item);
          },
          error => {
            this.loaderReg = false;
            this._snackBarService.showError(error.error.msg, 'Ok')
          }
        )
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  async editarProducto(f: NgForm) {
    this._productoService.editarProducto(this.producto).subscribe(
      async response => {
        for (let index = 0; index < this.imagenesACargar.length; index++) {
          const element = this.imagenesACargar[index];
          const imagen = new ImagenProducto();
          imagen.prd_codprd = this.producto.prd_codprd;
          imagen.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          imagen.pri_secpri = 1;
          imagen.pri_fotprd = element.img_docb64;

          const a = await this._productoService.registrarImagenProductoSicrono(imagen);
        }

        this._productoService.obtenerImagenesProducto(this.producto.prd_codprd.trim()).subscribe(
          imagenes => {
            this._dialogService.openDialog(SuccessComponent, 'Producto Actualizado', '300px', '', '');
            this.loaderReg = false;
            this.images = imagenes.map(item => item);
          },
          error => {
            this.loaderReg = false;
            this._snackBarService.showError(error.error.msg, 'Ok')
          }
        )
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/productos']);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();

    unsubscribeSubscription([
      this.tipoInventario$,
      this.familia$,
      this.subfamilia$,
      this.color$,
      this.unidad$,
      this.loading$,
      this.producto$,
      this.productos$,
      this.productoEncontrado$,
      this.imagenesProducto$,
      this.proveedores$,
      this.periodo$,
      this.ubicacionesAlmacen$,
      this.lineas$,
      this.modelos$,
      this.marcas$,
    ])

  }

  exito(): void {
    this._dialogService.openDialog(SuccessComponent, 'Servicio Registrado', '300px', '', '');

  }

}
