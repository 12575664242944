import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'button-insert-item-table',
  templateUrl: './button-insert-item-table.component.html',
  styleUrls: ['./button-insert-item-table.component.scss']
})

export class ButtonInsertItemTableComponent implements OnInit {

  @Input() loader: boolean;
  @Output() insertItem = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  insertarItem() {
    this.insertItem.emit()
  }
}
