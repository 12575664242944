import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExportExcelService } from 'src/app/services';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { generarPdfOperaciones } from '@utils/pdfmaker/builder/operaciones.pdfmaker';

@Component({
  selector: 'app-operaciones-report',
  templateUrl: './operaciones-report.component.html',
  styleUrls: ['./operaciones-report.component.css']
})
export class OperacionesReportComponent implements OnInit, OnDestroy {

  dataForExcel = [];

  dataDetalleFacturacion = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  period$: Subscription;

  constructor(
    public ete: ExportExcelService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {
  }

  generarPdf(): void {
    generarPdfOperaciones();1
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }
}
