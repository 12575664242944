<app-pavso-title-section [title]="'Chofer'" [module]="'Ventas'" [estado]="conductor.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" matInput placeholder="Código" [(ngModel)]="conductor.CHO_CODCHO" name="CHO_CODCHO" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Usuario APP</mat-label>
            <input type="text" matInput placeholder="Usuario APP" [(ngModel)]="conductor.CHO_USUAPP" name="CHO_USUAPP" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="text" matInput placeholder="Nombre" [(ngModel)]="conductor.CHO_NOMBRE" name="CHO_NOMBRE" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nro. Licencia</mat-label>
            <input type="text" matInput placeholder="Nro. Licencia" [(ngModel)]="conductor.CHO_NROLIC" name="CHO_NROLIC" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Doc.</mat-label>
            <mat-select name="DID_CODDID" [(ngModel)]="conductor.DID_CODDID">
              <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.DID_CODDID">
                {{tipo.DID_DESABR}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Número Doc.</mat-label>
            <input type="text" matInput placeholder="Ej. 90394494" name="CHO_DOCIDE" [(ngModel)]="conductor.CHO_DOCIDE">
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <br>
    <pav-form-actions
        [disabled]="loaderData"
        [btnName]="btnName"
        [isLoading]="loaderReg"
        urlBack="/modulo-almacen/conductores"
      >
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

  </form>
</div>
