import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { ProveedorFormComponent } from "../proveedor-form/proveedor-form.component";
import { ProveedorDialog } from "../proveedor-dialog/proveedor-dialog.component";
import { EntidadKey } from "@data/constants/component/entity-key.constant";

@Component({
  selector: 'proveedor-select',
  templateUrl: './proveedor-select.component.html',
  styleUrls: ['./proveedor-select.component.scss']
})
export class ProveedorSelect{

  id: string = EntidadKey.proveedor_key;
  dialogComponent: ComponentType<any> = ProveedorDialog;

  @Input() disabled: boolean;
  @Input() required: boolean = false;
  @Input() placeholder: string;
  @Input() clase: string;
  @Input() label: string;
  @Input() value: any;
  @Input() model: any;
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() multiple: boolean = false;
  @Input() iconAction: string;
  @Input() tooltipAction: string;
  @Input() setColorSelected: boolean;
  @Input() canAdd: boolean = false;
  @Input() canEdit: boolean = false;
  @Output() cambioSelect: EventEmitter<any>;
  @Output() clickAction: EventEmitter<any>;

  @Input() data: any[] = [];

  constructor() {

    this.cambioSelect = new EventEmitter<string>();
    this.clickAction = new EventEmitter<any>();

  }

}
