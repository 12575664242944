import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { GiroNegocio } from "src/app/models/ventas/giro-negocio";
import { ConfigurationService, VentasService } from "src/app/services";

@Component({
  selector: 'giro-negocio-form',
  templateUrl: './giro-negocio-form.component.html',
  styleUrls: ['./giro-negocio-form.component.scss']
})
export class GiroNegocioFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;

  uid: string;
  giroNegocio: GiroNegocio;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;


  sendForm$: Subscription;
  loading$: Subscription;
  dataForm$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _configurationService: ConfigurationService
  ) {
    this.giroNegocio = new GiroNegocio();
    this.giroNegocio.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.giroNegocio.gno_coduse = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    })
  }
  loadData(): void {
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;
      if (this.uid != '0') {
        this.mostrarGiroNegocio()
        return
      }

    })
  }
  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarGiroNegocio(f) : this.actualizarGiroNegocio(f);
  }

  mostrarGiroNegocio(): void {
    this.dataForm$ = this._ventasService.mostrarGiroNegocio(this.uid).subscribe(
      response => {
        console.log("giroNegocio", response);
        this.giroNegocio = response;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  registrarGiroNegocio(f: NgForm): void {
    this.sendForm$ = this._ventasService.registrarGiroNegocio(this.giroNegocio).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Giro de Negocio registrado', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }
  actualizarGiroNegocio(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarGiroNegocio(this.giroNegocio).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Giro de Negocio actualizado', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }
  volver(): void {
    this._router.navigate(['/modulo-ventas/giros-de-negocio']);
  }
  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.dataForm$,
      this.loading$
    ])
  }

}
