export class DocumentoCuentaContable {
  cia_codcia: string;
  tdo_codtdo: string;
  ccn_codgas: string;
  ctc_fecupd: Date;
  ctc_coduse: string;
  ctc_indsta: string;
  ctc_indsig: number;

  constructor() {
    this.ctc_indsta = "1";
    this.ctc_fecupd = new Date();
  }
}
