import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_PLANILLA_DINAMICOS } from "@data/json/reportes/planilla.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subject, Subscription } from "rxjs";
import { FiltroReportePlanilla } from "src/app/models/planilla/filtro-reporte-planilla";
import { ContabilidadService, ExportExcelService, GeneralService, NominaService, VentasService } from "src/app/services";
import { NominaReportService } from "src/app/services/reports/modules/nomina-report.service";

@Component({
  selector: 'informe-dinamico-planilla',
  templateUrl: './informe-dinamico-planilla.component.html',
  styleUrls: ['./informe-dinamico-planilla.component.scss']
})
export class InformeDinamicoPlanilla implements OnInit, OnDestroy {

  reporteSeleccionado: any = [REPORTES_PLANILLA_DINAMICOS[0].id];
  reportes: any[] = REPORTES_PLANILLA_DINAMICOS;

  allComplete: boolean = false;

  task: any = {
    name: 'Cabeceras de Informe de Colaboradores',
    completed: false,
    color: 'primary',
    subtasks: [],
  };

  year: string;
  month: string;
  codMod: string;

  loading: boolean = false;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  secNomina: string;
  typeNomina: string;

  period$: Subscription;
  nomina$: Subscription;
  loading$: Subscription;
  listarColumnas$: Subscription;
  listarColab$: Subscription;
  listaCentros$: Subscription;
  reporte$: Subscription;
  columnas$: Subscription;

  trabajadores: any[] = [];
  centros: any[] = [];
  selecciones: any[] = [];
  columnas: any[] = [];
  seleccion: any;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  indicadorCabecera: boolean = true;

  filtro: FiltroReportePlanilla;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _nominaService: NominaService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    public ete: ExportExcelService,
    private _contabilidadService: ContabilidadService,
    private _nominaReporteService: NominaReportService,
    private breakpointObserver: BreakpointObserver,
    private _ventasService: VentasService,
    private readonly _generalService: GeneralService
  ){

    this.filtro = new FiltroReportePlanilla();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
      this.codMod = state.codMod;
    })

    this.nomina$ = this.store.select('nomina').subscribe(state => {
      this.secNomina = state.secNomina;
      this.typeNomina = state.typeNomina;
    })

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;

      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;

      });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  grabar(): void {

  }

  loadData(): void {
    this.loading = true;

    forkObs(
      this._nominaService.listarColumnasInformeColaboradores(this.codMod),
      this._nominaService.listarColaboradores(),
      this._contabilidadService.listarCentroCostos(),
    ).then(data => {
      this.task.subtasks = data[0].map(item => {
        return {
          name: item.TDR_DESTDR, completed: true, id: item.TDR_COLTDR
        }
      })

      this.trabajadores = data[1];

      this.centros = data[2];
      this.loading = false;

    }).catch(error => {
      this._snackBarService.showError("Error al obtener maestros", "OK")
      this.loading = false;
    })

  }

  seleccionarReporte(event): void {
    this.reporteSeleccionado[0] = event.id;
    console.log('this.reporteSeleccionado[0]', this.reporteSeleccionado[0])
    let indicador = "";
    switch (this.reporteSeleccionado[0]) {
      case "PLANILLA_MC":
        indicador = "51002";
        this.indicadorCabecera = true;
        break;
      case "PLANILLA_PM":
        indicador = "51002";
        this.indicadorCabecera = false;
        break;
      case "PLANILLA_PS":
        indicador = "51002";
        this.indicadorCabecera = false;
        break;
      case "PLANILLA_HP":
        indicador = "51002";
        this.indicadorCabecera = false;
        break;
      default:
        break;
    }
  }

  obtenerColumnas(code): void {

    this.loading = true;

    this.columnas$ = this._generalService.listarColumnasInformeDinamico('32', code).subscribe(
      columnas => {
        this.columnas = columnas;

        this.columnas.forEach(element => {
          element['name'] = element.TDR_DESTDR;
          element['completed'] = true;
        });

        this.task.subtasks = this.columnas;
        this.loading = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loading = false;
      }
    )
  }

  generarExcel(): void {

    this.loading = true;

    if(!this.reporteSeleccionado[0]) {
      this._snackBarService.showError('Seleccione un reporte', 'OK');
      this.loading = false;
    }

    // let headers = this.header.subtasks.map(item => {
    //   return item.name;
    // })

    const headers = {
      codano: this.year,
      codmes: this.month,
      tipoNomina: this.typeNomina,
      secNomina: this.secNomina
    }

    const filtros = {

    };
    let otherData = {};

    this._nominaReporteService.verReporteDinamico(this.reporteSeleccionado[0], headers, this.filtro, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)

  }

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => (t.completed = completed));
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.nomina$,
      this.loading$,
      this.listarColumnas$,
      this.listarColab$,
      this.listaCentros$,
      this.reporte$]);
  }

}
