import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Maquina } from 'src/app/models/produccion/maquina';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { forkObs } from '@utils/observables/fork';
import { MaquinaService } from 'src/app/services/api/produccion/maestros/maquina.service';
import { UnidadMedidaService } from 'src/app/services/api/almacen/configuracion/unidad-medida.service';

@Component({
  selector: 'app-maquina-form',
  templateUrl: './maquina-form.component.html',
  styleUrls: ['./maquina-form.component.css']
})
export class MaquinaFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = true;


  maquina: Maquina;

  unidadesDeMedida: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  sendForm$: Subscription;
  maquina$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _maquinasService: MaquinaService,
    private _unidadMedidaService: UnidadMedidaService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService
  ) {
    this.maquina = new Maquina();
    this.maquina.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.maquina.maq_coduse = this._configurationService.obtenerIdUsuario();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;

      this.loadMaestros();
      if (id == '0') {
        this.loaderData = false;
        return
      };
    })
  }

  loadMaestros(): void {
    forkObs(
      this._unidadMedidaService.obtenerUnidadesDeMedida()
    ).then(respuesta => {
      this.unidadesDeMedida = respuesta[0];
      console.log("unidades: ", this.unidadesDeMedida)
      if (this.uid != '0') {
        this.obtenerMaquina();
      }
    })
  }

  obtenerMaquina(): void {
    this.maquina$ = this._maquinasService.obtenerMaquina(this.uid).subscribe(
      (maquina) => {
        this.maquina = maquina;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )

  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarMaquina(f) : this.actualizarMaquina(f);
  }

  registrarMaquina(f: NgForm): void {
    this.sendForm$ = this._maquinasService.registrarMaquina(this.maquina).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Máquina registrada', '400px', '400px', '');
        this.volver()
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarMaquina(f: NgForm): void {
    this.sendForm$ = this._maquinasService.actualizarMaquina(this.maquina).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Máquina actualizada', '400px', '400px', '');
        this.loaderReg = false;
        this.volver()
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-produccion/maquinas']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.maquina$, this.sendForm$, this.loading$])
  }

}
