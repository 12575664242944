<app-pavso-title-section [title]="'Almacén'" [module]="'Almacén'" [estado]="'1'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <mat-tab-group>
    <mat-tab label="Almacenes" labelClass="mat-tab-label-0-0">
      <div class="content-almacen">
        <div class="pav-table-search-container">
          <div class="pav-separator"></div>
          <div class="pav-table-search">
            <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
          </div>
        </div>

        <div class="mat-elevation-z0">
          <table-title-action title="Todos los almacenes" url="/modulo-almacen/almacen" entity=""
            [params]="['0']"></table-title-action>
          <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

          <div class="overflow-x">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-edit" matTooltip="Editar"
                      [routerLink]="['/modulo-almacen/almacen', row.ALM_CODALM]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="alm_codalm">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ALM_CODALM}} </td>
              </ng-container>

              <ng-container matColumnDef="alm_descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.ALM_DESCRI}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>
              </tr>

            </table>
          </div>


          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Configura motivos" labelClass="mat-tab-label-0-1">
      <div class="content-almacen">
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Almacén</mat-label>
              <mat-select>
                <mat-option *ngFor="let almacen of almacenes" [value]="almacen.alm_codalm">
                  {{almacen.alm_descri}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Tipo de Doc.</mat-label>
              <mat-select>
                <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.tdo_codtdo">
                  {{tipo.tdo_deslar}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Motivo</mat-label>
              <mat-select>
                <mat-option *ngFor="let motivo of motivos" [value]="motivo.mmo_codmmo">
                  {{motivo.mmo_descri}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Orden</mat-label>
              <input type="number" min="0" matInput placeholder="Orden">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l5">
            <mat-form-field class="example-full-width">
              <mat-label>Observación</mat-label>
              <input type="text" matInput placeholder="Observación">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Alm. Trans</mat-label>
              <mat-select>
                <mat-option value="trans1">
                  trans 1
                </mat-option>
                <mat-option value="trans2">
                  trans 2
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSourceMotivo" matSort>

            <ng-container matColumnDef="inventario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Inventario </th>
              <td mat-cell *matCellDef="let row" data-label="Inventario"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cuenta_debe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Debe </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta Debe"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cuenta_haber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Haber </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta Haber"> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsMotivo"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsMotivo;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4" *ngIf="!loaderMotivo">No se encontraron registros</td>
            </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col s12">
          <button mat-flat-button color="primary">Establecer</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
