<app-pavso-title-section [title]="'Tipo de impuesto'" [module]="'Contabilidad'" [usuario]="tipoImpuesto.tim_coduse" [fecha]="tipoImpuesto.tim_fecupd" [ngClass]="'pav-form'" [estado]="tipoImpuesto.tim_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input [(ngModel)]="tipoImpuesto.pai_codpai" type="text" matInput placeholder="Código" name="cli_codcli"
              required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6"></div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput placeholder="Descripción" name="descripcion" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Cod. Sunat (T54)</mat-label>
            <mat-select required name="cod_sunat" [disabled]="loaderData">
              <mat-option value="1">
                cod 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col s12"></div>
        <button mat-flat-button class="pav-button-actionVigencia de Impuesto" color="primary"
          (click)="vigenciaImpuesto()">Vigencia de impuesto</button>
      </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/tipos-de-impuesto">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
