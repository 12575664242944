<app-pavso-title-section [title]="'Consulta de situación de documento'" [module]="'Cuentas por cobrar'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l6">
                <mat-form-field>
                    <mat-label>Cliente</mat-label>
                    <mat-select required name="cliente" [disabled]="loaderData">
                        <mat-option *ngFor="let cliente of clientes" [value]="cliente.cli_codcli">
                            {{cliente.cli_nomcli}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
              <div class="col s12 m12 l3">
                  <app-currency-select [moneda]="'SO'" [disable]="loaderData"></app-currency-select>

            </div>
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Tipo Doc.</mat-label>
                    <input type="text" matInput placeholder="Tipo Doc." name="tipo_doc" required [disabled]="loaderData">
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Num. Documento</mat-label>
                    <input type="text" matInput placeholder="Num. Documento" name="num_documento" required [disabled]="loaderData">
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>Estado</mat-label>
                  <mat-select required name="cliente" [disabled]="loaderData">
                      <mat-option value="pendientes">
                        Pendientes
                      </mat-option>
                      <mat-option value="vencidos">
                        Vencidos
                      </mat-option>
                      <mat-option value="porVencer">
                        Por Vencer
                      </mat-option>
                      <mat-option value="cancelados">
                        Cancelados
                      </mat-option>
                      <mat-option value="anulados">
                        Anulados
                      </mat-option>
                      <mat-option value="todos">
                        Todos
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>

    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>
    </div>
    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <button matTooltip="VER DOC" [routerLink]="['/modulo-cobranzas/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                      <mat-icon>edit</mat-icon>
                  </button>
                  <button matTooltip="VER PEDIDO" [routerLink]="['/modulo-cobranzas/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                      <mat-icon>edit</mat-icon>
                  </button>
              </td>
          </ng-container>

          <ng-container matColumnDef="td">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TD </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
          </ng-container>

          <ng-container matColumnDef="nro_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="pedido">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Pedido </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha_emision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="razon_social">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Razon Social </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="cod_banco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Banco </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="descripcion_banco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción Banco </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="linea">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Línea </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="situacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha_vencimiento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="dias">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Días </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="mo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> MO </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="imp_total_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total S/. </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="abono_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Abono S/. </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="saldo_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo S/. </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="imp_total_us">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total US$ </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="abono_us">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Abono US$ </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="saldo_us">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo US$ </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="20">No se encontraron registros</td>

          </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>
    </div>
    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort >


          <ng-container matColumnDef="cuenta_corriente">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuen. Corriente </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
          </ng-container>

          <ng-container matColumnDef="fec_emision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="reg_contable">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Reg. Contable </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="sud_diario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sud. Diario </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="tip_moneda">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="tip_cambio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Cambio </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="imp_nacional">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Nacional (S/.) </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="imp_extranjera">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Extranjera ($/.) </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="cuenta_contable">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Contable </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="doc_operacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. Operación </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="num_operacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Operación </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="fec_operacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Operación </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="linea">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Línea </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="situacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="tip_docu_ref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Docu. Ref. </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="doc_referencia">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. Referencia </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="glosa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="17">No se encontraron registros</td>

          </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
