<app-pavso-title-section [title]="'Cotizaciones'" [module]="'Ventas'"></app-pavso-title-section>

<div class="pav-table-search-container">
  <mat-form-field>
    <mat-label>Fecha Inicio - Fecha Final</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha de inicio inválida</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha de culminación inválida</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Estado</mat-label>
    <mat-select (selectionChange)="cambiarEstado($event.value)" name="estado" value="TODOS">
      <mat-option value="TODOS">
        Todos
      </mat-option>
      <mat-option value="PENDIENTE">
        Pendiente
      </mat-option>
      <mat-option value="APROBADO">
        Aprobado
      </mat-option>
      <mat-option value="DESAPROBADO">
        Desaprobado
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
  </div>
</div>

<div class="pavso-content-form fade-in-image">
  <table-title-action title="Todas las cotizaciones" url="/modulo-ventas/cotizacion" entity=""
    [params]="['0']"></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <div class="mat-elevation-z0 overflow-x">

    <table class="mat-elevation-z0 overflow-x" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <a class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-ventas/cotizacion', row.PCC_NUMPCC]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </a>
            <div class="pav-btn-circle-pdf" matTooltip="Generar pdf" (click)="imprimirPDF(row.PCC_NUMPCC)">
              <mat-icon svgIcon="pdf"></mat-icon>
            </div>
          </div>
          <!-- <div class="pavso-container-action">
                        <button type="button" matTooltip="Editar pedido" [routerLink]="['/modulo-ventas/cotizacion', row.pcc_numpcc]" mat-icon-button color="primary" aria-label="Editar pedido">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button type="button" matTooltip="Imprimir PDF" (click)="imprimirPDF(row.pcc_numpcc)" mat-icon-button color="warn" aria-label="Imprimir PDF">
                            <mat-icon>picture_as_pdf</mat-icon>
                        </button>
                        <button type="button" matTooltip="Enviar Correo" (click)="enviarCorreo(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Enviar correo">
                            <mat-icon>email</mat-icon>
                        </button>
                        <button type="button" [disabled]="row.ESTADO_APROBACION == 'APROBADO'" matTooltip="Aprobar" (click)="aprobar(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Aprobar">
                            <mat-icon>thumb_up_alt</mat-icon>
                        </button>
                        <button type="button" [disabled]="row.ESTADO_APROBACION == 'PENDIENTE'" matTooltip="Establecer a Pendiente" (click)="establecerPendiente(row.pcc_numpcc)" mat-icon-button color="primary">
                            <mat-icon>pending</mat-icon>
                        </button>
                        <button type="button" matTooltip="Desaprobar" (click)="desaprobar(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Desaprobar">
                            <mat-icon>thumb_down_alt</mat-icon>
                        </button>
                        <button *ngIf="row.ESTADO_APROBACION == 'APROBADO'" type="button" matTooltip="Generar Factura" (click)="generarFactura(row)" mat-icon-button color="primary">
                            <mat-icon>receipt</mat-icon>
                        </button>
                    </div> -->

        </td>
      </ng-container>

      <ng-container matColumnDef="PCC_NUMPCC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Cotización </th>
        <td mat-cell *matCellDef="let row" data-label="Nro. Pedido"> {{row.PCC_NUMPCC}} </td>
      </ng-container>

      <ng-container matColumnDef="CLI_CODCLI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento Cliente </th>
        <td mat-cell *matCellDef="let row" data-label="Documento Cliente"> {{row.CLI_CODCLI}} </td>
      </ng-container>

      <ng-container matColumnDef="cli_nomcli">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="PCC_FECDOC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.PCC_FECDOC | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="tmo_descri">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Moneda </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo de Moneda"> {{row.tmo_descri}}
        </td>
      </ng-container>

      <ng-container matColumnDef="PCC_IMPTOT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
        <td mat-cell *matCellDef="let row" data-label="Importe Total"> {{row.PCC_IMPTOT.toFixed(2)}} </td>
      </ng-container>

      <ng-container matColumnDef="vde_nomvde">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
        <td mat-cell *matCellDef="let row" data-label="Vendedor"> {{row.vde_nomvde}} </td>
      </ng-container>

      <ng-container matColumnDef="descri_indsta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let row" data-label="Vendedor">
          <mat-chip-list aria-label="Fish selection">
            <div *ngIf="row.PCC_INDSTA == '1'" class="pav-tag-green">{{row.descri_indsta}}</div>
            <div *ngIf="row.PCC_INDSTA == '0'" class="pav-tag-red">{{row.descri_indsta}}</div>
            <!-- <mat-chip selected *ngIf="row.PCC_INDSTA == 'DESAPROBADO'" class="sc_disapproved">DESAPROBADO</mat-chip> -->
          </mat-chip-list>
        </td>
      </ng-container>

      <ng-container matColumnDef="otk_idtkt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> #Oportunidad </th>
        <td mat-cell *matCellDef="let row" data-label="Vendedor">
          {{row.otk_idtkt}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">No se encontraron registros "{{input.value}}"</td>

      </tr>

    </table>

  </div>

  <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>
