import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Hotkey, HotkeysService } from "angular2-hotkeys";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { ActividadCRM } from "src/app/models/crm/actividad-crm";
import { ConfigurationService } from "src/app/services";
import { ActividadService, EtapaService, GrupoActividadService } from "src/app/services/api/crm/maestros";

@Component({
  selector: 'actividad-crm',
  templateUrl: './actividad-crm-form.component.html',
  styleUrls: ['./actividad-crm-form.component.scss']
})
export class ActividadCRMFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  sendForm$: Subscription;
  loading$: Subscription;
  actividad$: Subscription;
  grupoActividad$: Subscription;
  etapa$: Subscription;

  gruposActividad: any[] = [];
  etapas: any[] = [];

  actividad: ActividadCRM;

  constructor(
    private store: Store<PavsoState>,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _actividadService: ActividadService,
    private _grupoActividadService: GrupoActividadService,
    private _etapaService: EtapaService,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _hotkeysService: HotkeysService
  ) {

    this._hotkeysService.add(new Hotkey('ctrl+b', (event: KeyboardEvent): boolean => {
      console.log('Typed hotkey');
      this.volver();
      return false;
    }));

    this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
      console.log('Typed hotkey');
      this.enviarFormulario(null);
      return false;
    }));

    this.actividad = new ActividadCRM();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadData()
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      this.loadMaestros();

      if(this.uid == '0') {
        return;
      }

    })
  }

  /**
   * Método para obtener los maestro de:
   * . Grupos de actividad
   * . Etapas
   */
  loadMaestros(): void {
    this.grupoActividad$ = this._grupoActividadService.obtenerGruposActividad().pipe(take(1)).subscribe(
      grupos => {
        this.gruposActividad = grupos;

        this.etapa$ = this._etapaService.obtenerEtapas().pipe(take(1)).subscribe(
          etapas => {
            this.etapas = etapas;

            if (this.uid != '0') this.obtenerActividad();
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  trackByGrupo(index, grupo): string {
    return grupo.idgac;
  }

  trackByEtapa(index, etapa): string {
    return etapa.codoet;
  }

  obtenerActividad(): void {
    this.actividad$ = this._actividadService.obtenerActividad(this.uid).subscribe(
      actividad => this.actividad = actividad[0],
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;

    this.uid == '0' ? this.registrarActividad(f) : this.actualizarActividad(f);

  }

  /**
   * Método para registrar una nueva actividad
   * @param f
   */
  registrarActividad(f: NgForm): void {
    this.actividad.coduse = this._configurationService.obtenerIdUsuario();

    this.sendForm$ = this._actividadService.registrarActividad(this.actividad).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.actividad.success-store', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para actualizar los datos de una actividad existente
   * @param f
   */
  actualizarActividad(f: NgForm): void {
    this.sendForm$ = this._actividadService.actualizarActividad(this.actividad).subscribe(
      response => {
        this.playNotification()
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.actividad.success-update', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  playNotification(){
    const audio = new Audio();
    audio.src = "assets/mp3/notification.mp3";
    audio.load();
    audio.play();
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {

    if(!this.actividad.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    };

    if(!this.actividad.idgac) {
      this._snackBarService.showError('Seleccionar el grupo de actividad', 'Ok');
      return false;
    };

    if(!this.actividad.codoet) {
      this._snackBarService.showError('Seleccionar la etapa', 'Ok');
      return false;
    }

    return true;

  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/actividades-crm']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.loading$,
      this.actividad$,
    ]);
  }

}
