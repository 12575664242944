import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ApiTipoDocumentoIdentidadVentasService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerTiposDocumentoIdentidad(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/tipos-documento-identidad`
    );
  }

  obtenerTipoDocumentoIdentidad(coddid): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/tipos-documento-identidad/${coddid}`
    );
  }
}
