import { ComponentType } from "@angular/cdk/portal";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ClienteDialog } from "../cliente-dialog/cliente-dialog.component";
import { EntidadKey } from "@data/constants/component/entity-key.constant";

@Component({
  selector: 'cliente-select',
  templateUrl: './cliente-select.component.html',
  styleUrls: ['./cliente-select.component.scss']
})
export class ClienteSelect {
  id: string = EntidadKey.cliente_key;
  dialogComponent: ComponentType<any> = ClienteDialog;

  @Input() disabled: boolean;
  @Input() required: boolean = false;
  @Input() placeholder: string;
  @Input() clase: string;
  @Input() label: string;
  @Input() value: any;
  @Input() model: any;
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() multiple: boolean = false;
  @Input() iconAction: string;
  @Input() tooltipAction: string;
  @Input() canAdd: boolean = false;
  @Input() canEdit: boolean = false;
  @Output() cambioSelect: EventEmitter<any>;
  @Output() clickAction: EventEmitter<any>;

  @Input() data: any[] = [];

  constructor() {

    this.cambioSelect = new EventEmitter<string>();
    this.clickAction = new EventEmitter<any>();

  }
}
