<h1 mat-dialog-title>Cargar Factura</h1>
<mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>
<div mat-dialog-content>
  <div class="custom-dropzone" ngx-dropzone (change)="onSelect($event)" accept="image/png, image/jpeg, image/jpg, application/pdf">
    <!-- <ngx-dropzone-label>Subir archivo (.pdf, .png, .jpeg, .jpg)</ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview> -->
    <ngx-dropzone-label>
      <div>
        <h2>Subir archivo (.pdf, .png, .jpeg, .jpg)</h2>
      </div>
    </ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-flat-button class="pav-button-cancel" cdkFocusInitial (click)="cancelar()">Cancelar</button>
  <button mat-flat-button class="pav-button-action" (click)="confirmar()" color="primary" [disabled]="files.length == 0">Confirmar</button>
</div>
