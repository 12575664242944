import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { AlmacenService } from 'src/app/services';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-configure-motive-list',
  templateUrl: './configure-motive-list.component.html',
  styleUrls: ['./configure-motive-list.component.css']
})
export class ConfigureMotiveListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'alm_codalm', 'tdo_codtdo', 'mmo_codmmo', 'mic_glomic'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  namesConstant: INameConstant;

  configura$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;


  constructor(
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.namesConstant = NAMES_CONSTANTS;
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.configura$ = this._almacenService.obtenerConfiguraEnlaceMotivoAlmacen().subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK")
        this.loaderData = false;
      }
    )
  }

  loadMaestros(): void {

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.configura$, this.loading$])
  }

}
