import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { FirmaDigital } from 'src/app/models/planilla/maestros/firma-digital';
import { ConfigurationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { ApiNominaMaestrosFirmaDigitalService } from 'src/app/services/api/nomina/maestros/nomina.maestros.firma-digital.service';

@Component({
  selector: 'app-firma-digital-form',
  templateUrl: './firma-digital-form.component.html',
  styleUrls: ['./firma-digital-form.component.css']
})
export class FirmaDigitalFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  files: File[] = [];

  loadUpload: boolean = false;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  firmaDigital: FirmaDigital;

  colaboradores: any[] = [];
  firma$: Subscription;

  uid: string;

  loading$: Subscription;
  sendForm$: Subscription;
  listarColab$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _nominaService: NominaService,
    private _apiFirmaNominaService: ApiNominaMaestrosFirmaDigitalService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {

    this.usuario = this._configurationService.obtenerIdUsuario();

    this.firmaDigital = new FirmaDigital();
    this.firmaDigital.coduse = this._configurationService.obtenerIdUsuario();
    this.firmaDigital.codcia = this._configurationService.obtenerCompaniaCliente();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.loadMaestros();
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
        if(id == '0') return

      }
    )
  }

  trackByColaborador(index, colaborador): string {
    return colaborador? colaborador.codcli: undefined;
  }



  obtenerFirmaDigital(): void {
    this.firma$ = this._apiFirmaNominaService.listarFirmasDigitales().subscribe(
      firmas => {
        this.firmaDigital = firmas.find(item => item.codcli == this.uid);

      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  loadMaestros(): void {
    this.listarColab$ = this._nominaService.listarColaboradores().subscribe(
      colaboradores => {
        this.colaboradores = colaboradores;
        if(this.uid != '0') this.obtenerFirmaDigital();
      },
      error => this._snackBarService.showError("Error al obtener colaboradores", "OK")
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;

    this.uid == '0' ? this.registrarFirmaDigital(f): this.actualizarFirmaDigital(f);
  }

  /**
   * Método para registrar una nueva Firma Digital
   * @param f
   */
  registrarFirmaDigital(f: NgForm): void {

    this.sendForm$ = this._apiFirmaNominaService.registrarFirmaDigital(this.firmaDigital).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Firma digital registrada', '400px', '400px', '');
        f.resetForm();
        this.firmaDigital.base64 = null;
        this.files = [];
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para actualizar firma digital
   * @param f
   */
  actualizarFirmaDigital(f: NgForm) : void {
    this.sendForm$ = this._apiFirmaNominaService.actualizarFirmaDigital(this.firmaDigital).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Firma digital actualizada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/firmas-digitales']);
  }

  onSelect(event): void {

    if(event.addedFiles.length == 0) {
      this._snackBarService.showError('La imagen supera los 80 KB.', 'Ok');
      return;
    }

    this.loadUpload = true

    this.files.push(...event.addedFiles);

    let imagenes = [];
    for (let index = 0; index < this.files.length; index++) {
      const element = this.files[index];

      let reader = new FileReader;

      let urlImageTemp = reader.readAsDataURL(element);

      reader.onloadend = () => {


        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const width = img.naturalWidth;
          const heigth = img.naturalHeight;

          if(width == 850 && heigth == 300) {
            this._snackBarService.showSuccess('Formato de imagen correcta', 'Ok');
            imagenes.push(reader.result)
          } else {
            this.files = [];
            this._snackBarService.showError('Dimensiones incorrectas', 'Ok');
          }
        }

      };
    }

    setTimeout(() => {
      this.loadUpload = false;
      this.firmaDigital.base64 = imagenes[0];
    }, 2000);

  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.firma$, this.loading$, this.listarColab$]);
  }

}
