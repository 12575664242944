import { DescuentoProductoDetalle } from "./tabla-descuento-producto-detalle";

export class DescuentoProductoCabecera {
    cia_codcia: string; // Código de compañía - char(2) - NO
    tbd_numtbd: string; // Número de TBD - char(10) - NO
    periodo: string; // Periodo - char(6) - NO
    tbd_glotbd: string; // Descripción global TBD - varchar(80) - YES
    tbd_indsta: string; // Indicador de estado - char(1) - NO
    tbd_fecupd: any; // Fecha de actualización - datetime - NO
    tbd_coduse: string; // Código de usuario - varchar(30) - NO    
    detalle: Array<DescuentoProductoDetalle>;
    constructor() {
        this.tbd_indsta = "1";
        this.tbd_fecupd = new Date();
        this.detalle=[];
    }
}