import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@shared/services/snackbar.service';
import { VentasService } from 'src/app/services';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-accounts-receivable',
  templateUrl: './accounts-receivable.component.html',
  styleUrls: ['./accounts-receivable.component.css']
})
export class AccountsReceivableComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['td', 'nro_doc', 'pedido', 'fecha_emision', 'razon_social', 'cod_banco', 'descripcion_banco', 'linea', 'situacion', 'fecha_vencimiento', 'dias', 'mo', 'imp_total_soles', 'abono_soles', 'saldo_soles', 'imp_total_dolar', 'abono_dolar', 'saldo_dolar'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['cuenta_corriente', 'fec_emision', 'req_contable', 'sud_diario', 'tip_moneda', 'tip_cambio', 'imp_nacional', 'imp_extranjero', 'cuenta_contable', 'doc_operacion', 'fec_operacion', 'linea', 'situacion', 'tip_doc_ref', 'doc_rec'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  clientes: any[] = [];
  monedas: any[] = [];
  tiposDocumento: any[] = [];


  cliente$: Subscription;
  moneda$: Subscription;
  tipoDocVenta$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService
  ) {
    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.loaderData = true;
    this.cliente$ = this._ventasService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes;

        this.tipoDocVenta$ = this._ventasService.listarTipoDocumentosVenta().subscribe(
          tiposDocumento => {
            this.tiposDocumento = tiposDocumento;
            this.loaderData = false;
          },
          error => {
            this.loaderData = false;
            this._snackBarService.showError(error.error.msg, 'OK');
          }
        )

      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  registrarCuenta(f: NgForm): void {

  }


  volver(): void {
    this._router.navigate(['/modulo-cobranzas/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cliente$,
      this.moneda$,
      this.tipoDocVenta$,
    ])
  }

}
