import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'eventos-venta',
  templateUrl: './eventos-venta.component.html',
  styleUrls: ['./eventos-venta-component.scss']
})
export class EventosVentaComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
