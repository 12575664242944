export class PeriodoNomina {
  codcia: string;
  codano: string;
  codmes: string;
  codnpe: string;
  codnti: string;
	codnfr: string;
	descri: string;
	coduse: string;
	fecupd: Date;
	indsta: string;
	indcie: number;
	fecini: Date;
	fecter: Date;
	diaefe: number;
	horefe: number;
	dialab: number;

  constructor() {
    this.indsta = "1";
    this.fecupd = new Date();
    this.diaefe = 30;
  }
}
