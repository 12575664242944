import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface OptionsData {
  id: string;
  search: string;
  isDialog: boolean;
}

@Component({
  selector: "auxiliar-dialog",
  template: `
    <div mat-dialog-content>

      <app-auxiliar-form [id]="data.id" [search]="data.search" [isDialog]="data.isDialog"></app-auxiliar-form>

    </div>
  `,
  styleUrls: ['./auxiliar-dialog.component.scss']
})
export class AuxiliarDialog {
  constructor(
    public dialogRef: MatDialogRef<AuxiliarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: OptionsData,
  ) {
    console.log('data id', data.id)
  }
}
