<app-pavso-title-section [title]="'Liquidaciones CTS'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todas las liquidaciones CTS" url="/modulo-planilla/liquidacion-de-cts" entity="" [params]="['0']"></table-title-action>

      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <div class="pav-btns-container">
                  <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-planilla/liquidacion-de-cts', row.ano_codano + row.mes_codmes + row.cod_colaborador]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                  <!-- <div class="pav-btn-circle-more">
                    <mat-icon svgIcon="more"></mat-icon>
                  </div> -->
                </div>
                  <!-- <button matTooltip="Editar" [routerLink]="['/modulo-planilla/liquidacion-de-cts', row.ano_codano + row.mes_codmes + row.cod_colaborador]" mat-icon-button color="primary">
                      <mat-icon>edit</mat-icon>
                  </button> -->
              </td>
          </ng-container>

          <ng-container matColumnDef="ano_codano">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
              <td mat-cell *matCellDef="let row" data-label="Año"> {{row.ano_codano}} </td>
          </ng-container>

          <ng-container matColumnDef="mes_codmes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
              <td mat-cell *matCellDef="let row" data-label="Mes"> {{row.mes_codmes}} </td>
          </ng-container>

          <ng-container matColumnDef="cod_colaborador">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cod_colaborador}} </td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
            <td mat-cell *matCellDef="let row" data-label="Colaborador"> {{row.nombre}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">No se encontraron registros "{{input.value}}"</td>
          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
