import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-consulta-situacion-documento',
  templateUrl: './consulta-situacion-documento.component.html',
  styleUrls: ['./consulta-situacion-documento.component.css']
})
export class ConsultaSituacionDocumentoComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'td', 'nro_doc', 'pedido', 'fecha_emision', 'razon_social', 'cod_banco', 'descripcion_banco', 'linea', 'situacion', 'fecha_vencimiento', 'dias', 'mo', 'imp_total_soles', 'abono_soles', 'saldo_soles', 'imp_total_us', 'abono_us', 'saldo_us', 'descripcion'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns2: string[] = ['cuenta_corriente', 'fec_emision', 'reg_contable', 'sud_diario', 'tip_moneda', 'tip_cambio', 'imp_nacional', 'imp_extranjera', 'cuenta_contable', 'doc_operacion', 'num_operacion', 'fec_operacion', 'linea', 'situacion', 'tip_docu_ref', 'doc_referencia', 'glosa'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  loaderData: boolean = false;
  loaderReg: boolean = false;

  clientes: any[] = [];
  monedas: any[] = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  cliente$: Subscription;
  loading$: Subscription;

  constructor(
    private _ventasService: VentasService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  /**
   * Obtiene los maestros de :
   * . clientes
   */
  loadData(): void {
    this.loaderData = true;

    this.cliente$ = this._ventasService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cliente$,
      this.loading$
    ])
  }

}
