<h1 mat-dialog-title>Generar Factura desde Pedido</h1>

<div mat-dialog-content>

    <div class="row">
        <div class="col s12">
            <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input matInput [matDatepicker]="fecha" [(ngModel)]="date" name="date">
                <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                <mat-datepicker #fecha></mat-datepicker>
            </mat-form-field>
        </div>

        <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="generarDocumento" name="generarDocumento">Generar Documento de Venta</mat-checkbox>
        </section>

        <div class="col s12">
            <mat-form-field>
                <mat-label>Tipo de Documento</mat-label>
                <mat-select [(ngModel)]="tipoDeDocumento" name="TDO_CODTDO" [disabled]="!generarDocumento" (selectionChange)="seleccionarTipo($event.value)">
                    <mat-option *ngFor="let tipo of tipoDocumentosVenta" [value]="tipo.tdo_codtdo">
                        {{tipo.tdo_deslar}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col s12">
            <mat-form-field class="example-full-width">
                <mat-label>Serie</mat-label>
                <input matInput [(ngModel)]="serie" name="serie" placeholder="Serie">
            </mat-form-field>
        </div>

        <div class="col s12">
            <mat-form-field class="example-full-width">
                <mat-label>Nro. Documento</mat-label>
                <input matInput placeholder="Nro. Documento" [(ngModel)]="nroDocumentoVenta" name="nroDocumentoVenta">
            </mat-form-field>
        </div>

        <section class="example-section">
            <mat-checkbox class="example-margin" [(ngModel)]="generarGuia" name="generarGuia">Generar guía de remisión</mat-checkbox>
        </section>

        <div class="col s12">
            <mat-form-field>
                <mat-label>Serie de guía</mat-label>
                <mat-select name="TDO_CODTDO" [disabled]="!generarGuia">
                    <mat-option *ngFor="let tipo of tipoDocumentosVenta" [value]="tipo.tdo_codtdo">
                        {{tipo.tdo_deslar}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col s12">
            <mat-form-field class="example-full-width">
                <mat-label>Nro. Documento</mat-label>
                <input matInput placeholder="Nro. Documento" [(ngModel)]="nroDocumento" name="nroDocumento" [disabled]="!generarGuia">
            </mat-form-field>
        </div>

    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
    <button mat-flat-button color="primary">{{buttonName.BTN_ACCEPT}}</button>
</div>
