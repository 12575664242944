import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { fillTable, searchInTable } from "@utils/tables/table";

@Component({
  selector: 'inventario-fisico-list',
  templateUrl: './inventario-fisico-list.component.html',
  styleUrls: ['./inventario-fisico-list.component.scss']
})
export class InventarioFisicoListComponent implements OnInit {

  loaderData: boolean ;

  displayedColumns: string[] = ['acciones', 'ALM_DESCRI', 'mac_nummac', 'mmo_codmmo', 'mmo_descri'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }
}
