import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DetalleCuota, PurchaseOrder } from 'src/app/models';

@Component({
  selector: 'app-add-fee-product',
  templateUrl: './add-fee-product.component.html',
  styleUrls: ['./add-fee-product.component.css']
})
export class AddFeeProductComponent implements OnInit {

  id: any;
  monto: any;
  fecha: any;
  descripcion: any;
  items: any [] = [];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<AddFeeProductComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: PurchaseOrder,
  ) {
    this.items = data.detalle_cuota;
  }

  ngOnInit(): void {
  }

  close(): void {
    this._bottomSheetRef.dismiss(this.data);
    event.preventDefault();
  }

  agregarCuota(f: NgForm): void {
    this.id = new Date().getTime();
    let detalleCuota = new DetalleCuota();
    detalleCuota.id = this.id;
    detalleCuota.OCE_DESCRI = this.descripcion;
    detalleCuota.OCE_INDAPR = 0;
    detalleCuota.OCE_IMPCUO = this.monto;
    detalleCuota.OCE_FECPAG = this.fecha;

    this.items.push(detalleCuota);
    this.data.detalle_cuota =this.items;
    f.resetForm();

  }

  eliminarItem(id) {
    this.data.detalle_cuota.forEach((element, index) => {
      if(element.id == id) this.items.splice(index, 1);
    })
    this.data.detalle_cuota = this.items;
  }

}
