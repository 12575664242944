export class Canal {
  idmtk   : any;
  descri  : any;
  fecupd  : any;
  indsta  : any;
  coduse  : any;

  constructor() {
    this.fecupd = new Date();
    this.indsta = '1';
  }
}
