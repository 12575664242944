
<h1 mat-dialog-title>Copiar pedido</h1>

<div mat-dialog-content>
  <select-search [label]="'Pedido'" [placeholder]="'Ej. 001444441'" [value]="'PCC_NUMPCC'" [name]="'PCC_NUMPCC'"
    [description]="'description'" [model]="pedidoSeleccionado" (cambioSelect)="pedidoSeleccionado = $event"
    [disabled]="loaderData" [data]="pedidos">
  </select-search>
</div>

<div mat-dialog-actions>

  <button type="button" (click)="copiar()" mat-raised-button color="primary" cdkFocusInitial>Copiar</button>
</div>
