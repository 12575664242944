import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { resetEstablecimiento } from "@shared/state/actions/establishment.actions";
import { AuthenticationService } from "src/app/services";

@Component({
  selector: 'header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent implements OnDestroy{

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private store: Store<PavsoState>
  ) {}

  cerrarSesion(): void {
    document.body.classList.remove('dark-theme');
    this._authService.logout();
    this._router.navigate(['/iniciar-sesion']);
    this.store.dispatch(resetEstablecimiento())
  }

  ngOnDestroy(): void {}
}
