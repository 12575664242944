import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Contact } from 'src/app/models';
import { ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { CargoService } from 'src/app/services/api/nomina/maestros/cargo.service';

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.css']
})
export class CustomerAddComponent implements OnInit, OnDestroy {

  contact: Contact;
  cargos: any[] = [];

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  cargo$: Subscription;

  constructor(
    private _cargoService: CargoService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<CustomerAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.contact = new Contact();
    this.contact.cia_codcia = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.obtenerCargos();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  obtenerCargos(): void {
    this.cargo$ = this._cargoService.listarCargos().subscribe(
      response => this.cargos = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  agregarContacto(f: NgForm): void {
    (this.contact.ccl_indcob)? this.contact.ccl_indcob = 1: this.contact.ccl_indcob = 0;

    this.dialogRef.close(this.contact);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cargo$
    ])
  }
}
