import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Planta } from "src/app/models/produccion/maestros/planta";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class PlantaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerPlantas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionplantas/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  obtenerPlanta(pla_codpla:string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionplantas/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${pla_codpla}`
    );
  }

  registrarPlanta(payload: Planta): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionplantas/crear`,
      payload
    );
  }

  editarPlanta(payload: Planta): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionplantas/modificar`,
      payload
    );
  }
}
