export class OpcionFiltro {
  s26_grupo: string;
  s26_descri: string;
  s26_indcli: number;
  s26_indvde: number;
  s26_indtdo: number;
  s26_indcpa: number;
  s26_indmmo: number;
  s26_indsfa: number;
  s26_indfec: number;
  s26_indtin: number;
  s26_indlpd: number;
  s26_indfpr: number;
  s26_indsfp: number;
  s26_indprd: number;
  s26_indcol: number;
  s26_indtpr: number;
  s26_indtie: number;
  s26_indmpr: number;
  s26_indmod: number;
  s26_indtmo: number;
  s26_indalm: number;
  s26_indccs: number;
  s26_indlot: number;
  s26_indser: number;
  s26_indume: 1 | 0;
  s26_serdoc: 1 | 0;

  constructor() {
    this.s26_indcli = 0;
    this.s26_indvde = 0;
    this.s26_indtdo = 0;
    this.s26_indcpa = 0;
    this.s26_indmmo = 0;
    this.s26_indsfa = 0;
    this.s26_indfec = 0;
    this.s26_indtin = 0;
    this.s26_indlpd = 0;
    this.s26_indfpr = 0;
    this.s26_indsfp = 0;
    this.s26_indprd = 0;
    this.s26_indcol = 0;
    this.s26_indtpr = 0;
    this.s26_indtie = 0;
    this.s26_indmpr = 0;
    this.s26_indmod = 0;
    this.s26_indtmo = 0;
    this.s26_indalm = 0;
    this.s26_indccs = 0;
    this.s26_indlot = 0;
    this.s26_indser = 0;
    this.s26_indume = 0;
    this.s26_serdoc = 0;
  }

}
