<app-pavso-title-section [title]="'Productos'" [module]="'Almacén'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action (clickDescargar)="descargarXML()" title="Todos los productos" url="/modulo-almacen/producto" entity="" [params]="['0']"></table-title-action>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <a matTooltip="Editar producto"
                      class="pav-btn-circle-edit"
                      [routerLink]="['/modulo-almacen/producto', row.prd_codprd]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="prd_codprd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.prd_codprd}} </td>
            </ng-container>

            <ng-container matColumnDef="DESC_ESPA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.DESC_ESPA}} </td>
            </ng-container>

            <ng-container matColumnDef="UNIDAD">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad </th>
                <td mat-cell *matCellDef="let row" data-label="Unidad" class="td-responsive"> {{row.UNIDAD}} </td>
            </ng-container>

            <ng-container matColumnDef="COD_INVENTARIO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Inv. </th>
                <td mat-cell *matCellDef="let row" data-label="Cod. Inv." class="td-responsive"> {{row.COD_INVENTARIO}} </td>
            </ng-container>

            <ng-container matColumnDef="DESC_INVENTARIO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Inv. </th>
                <td mat-cell *matCellDef="let row" data-label="Desc. Inv." class="td-responsive"> {{row.DESC_INVENTARIO}} </td>
            </ng-container>

            <ng-container matColumnDef="ESTADO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.ESTADO}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">No se encontraron registros "{{input.value}}"</td>

            </tr>
        </table>
      </div>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
