<h1 mat-dialog-title>Agregar Lote</h1>

<div class="pav-table-header-container">
  <div class="pav-table-header-icon">

  </div>
  <div class="pav-table-header-title">
    Lotes
  </div>
  <div class="pav-table-header-add">
    <div class="isMobile">
      <div class="pav-button-icon-add" (click)="agregarLote()">
        <mat-icon svgIcon="add"></mat-icon>
      </div>
    </div>
    <div class="isWeb">
      <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregarLote()">Agregar</button>
    </div>
  </div>
</div>
<div class="mat-elevation-z0 overflow-x">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" data-label="Acciones">
            <div class="pav-btns-container">
              <div class="pav-btn-circle-delete" (click)="eliminarLote(row)" matTooltip="Eliminar">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
      </ng-container>

      <ng-container matColumnDef="lote">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Lote">
            <ng-container>
              <mat-form-field>
                <input matInput placeholder="Lote" [(ngModel)]="row.mlo_numlot" name="mlo_numlot" required>
              </mat-form-field>
            </ng-container>
          </td>
      </ng-container>

      <ng-container matColumnDef="cantidad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
        <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
          <ng-container>
            <mat-form-field>
              <input matInput placeholder="Cantidad" [(ngModel)]="row.mlo_canprd" name="mlo_canprd" required>
            </mat-form-field>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="fec_ven">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Venc. </th>
        <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
          <ng-container>
            <mat-form-field>
              <mat-label>Fecha Vencimiento</mat-label>
              <input matInput [matDatepicker]="pickerVcto" [(ngModel)]="row.mlo_fecven" name="mlo_fecven" required>
              <mat-datepicker-toggle matSuffix [for]="pickerVcto"></mat-datepicker-toggle>
              <mat-datepicker #pickerVcto></mat-datepicker>
            </mat-form-field>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="fec_fab">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Fab. </th>
        <td mat-cell *matCellDef="let row; let i = index;" data-label="Fec. Fab.">
          <ng-container>
            <mat-form-field>
              <mat-label>Fecha Fabricación</mat-label>
              <input matInput [matDatepicker]="pickerFab" [(ngModel)]="row.mlo_fecfab" name="mlo_fecfab" required>
              <mat-datepicker-toggle matSuffix [for]="pickerFab"></mat-datepicker-toggle>
              <mat-datepicker #pickerFab></mat-datepicker>
            </mat-form-field>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="observacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Observacion </th>
        <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
          <ng-container>
            <mat-form-field>
              <mat-label>Observación</mat-label>
              <textarea matInput placeholder="Observación" [(ngModel)]="row.mlo_glomlo" name="mlo_glomlo" required></textarea>
            </mat-form-field>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>

          <td class="mat-cell" colspan="2">No se encontraron registros</td>

      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>

</div>
<br>
<div class="row">
    <div class="col s12">

        <button mat-flat-button color="primary" type="button" (click)="cerrar()">{{buttonName.BTN_CONFIRM}}</button>
    </div>
</div>
