<app-pavso-title-section [title]="'Guia de Remisión'" [module]="'Almacén'" [usuario]="guiaRemision.grc_coduse"
  [fecha]="guiaRemision.grc_fecupd" [ngClass]="'pav-form'" [estado]="guiaRemision.grc_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <form #f="ngForm" ngNativeValidate (keydown.enter)="$event.preventDefault()" (ngSubmit)="enviarFormulario(f)">
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s6 m12 l2">
          <select-search [label]="'Código'" [placeholder]="'Ej. FAC (factura)'" [name]="'tdo_codtdo'"
            [value]="'TDO_CODTDO'" [description]="'TDO_CODTDO'" [data]="tiposDocumento"
            (cambioSelect)="guiaRemision.tdo_codtdo = $event" [model]="guiaRemision.tdo_codtdo" [disabled]="true">
          </select-search>
        </div>
        <ng-container *ngIf="viewport_numdoc">
          <div class="col s6 m12 l2">

            <select-search [label]="'Serie'" [name]="'sdo_codsdo'" [value]="'serie'" [description]="'serie'"
              [data]="series" (cambioSelect)="guiaRemision.sdo_codsdo = $event; seleccionarSerie();" [model]="guiaRemision.sdo_codsdo"
              [disabled]="uid != '0'">
            </select-search>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>Número</mat-label>
              <!-- este campo es la separacion de la serie y el numero (grc_numdoc = sdo_codsdo + correlativo) -->
              <input type="text" [(ngModel)]="guiaRemision.grc_numdoc" [configText]="['numeros']" matInput
                placeholder="Ej. GRE" [name]="'grc_numdoc'" readonly [maxlength]="10" />
            </mat-form-field>
          </div>
        </ng-container>
        <!-- <div class="col s12 m12 l2" *ngIf="!viewport_numdoc">
          <mat-form-field>
            <mat-label>N° documento</mat-label>
            <input type="text" [(ngModel)]="guiaTexto.grc_numdoc" matInput placeholder="Ej. GRE" name="grc_numdoc"
              readonly />
          </mat-form-field>
        </div> -->
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Fecha de Emisión</mat-label>
            <input matInput name="grc_fecdoc" [disabled]="true" [(ngModel)]="guiaRemision.grc_fecdoc"
              [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- <div class="col s12 m12 l3" *ngIf="viewport_numdoc">
          <mat-form-field>
            <mat-label>N° documento</mat-label>
            <input type="text" [(ngModel)]="guiaTexto.grc_numdoc" matInput placeholder="Ej. GRE" name="grc_numdoc"
              readonly />
          </mat-form-field>
        </div> -->
        <div class="col s12 m12 l4">
          <select-search [label]="'Cliente'" [placeholder]="'Ej. Juan Alvela'" [name]="'cli_codcli'"
            [value]="'cli_codcli'" [description]="'description'" [data]="proveedores" (cambioSelect)="
              guiaRemision.cli_codcli = $event; onChangeProveedor($event); seleccionarCliente()
            " [model]="guiaRemision.cli_codcli" [disabled]="loaderData">
          </select-search>
        </div>
      </div>
      <!-- {{guiaTexto | json}} -->
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <mat-tab-group>
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <mat-tab label="Datos Generales" labelClass="mat-tab-label-0-0">
          <div class="container">
            <div class="row">
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Evento de Generación</mat-label>
                  <input type="number" [(ngModel)]="guiaRemision.egv_numegv" matInput placeholder="Ej. GRE"
                    name="egv_numegv" required />
                </mat-form-field>
              </div>


              <div class="col s12 m12 l2">
                <mat-form-field>
                  <mat-label>N° RUC</mat-label>
                  <input type="text" [(ngModel)]="guiaRemision.cli_numruc" [placeholder]="guiaRemision.cli_numruc" matInput
                    name="cli_numruc" readonly="true" />
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2">
                <mat-form-field>
                  <mat-label>Peso Bruto</mat-label>
                  <input type="number" [(ngModel)]="guiaRemision.grc_pesbru" [placeholder]="'Ingrese peso Bruto'" matInput
                    name="peso_bruto" required />
                </mat-form-field>
              </div>
              <div class="col s12 m6 l2">
                <mat-form-field>
                  <mat-label>Fecha de traslado</mat-label>
                  <!-- <mat-hint>DD/MM/YYYY</mat-hint> -->
                  <input matInput [matDatepicker]="pickergrc_fectra" name="grc_fectra"
                    [(ngModel)]="guiaRemision.grc_fectra" [min]="hoy" [max]="fechaMaxima" [disabled]="uid != '0'" />
                  <mat-datepicker-toggle matSuffix [for]="pickergrc_fectra"></mat-datepicker-toggle>
                  <mat-datepicker #pickergrc_fectra></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col s12 m6 l3">
                <select-search [label]="'Motivo'" [placeholder]="'Ej. Juan Alvela'" [name]="'mmo_codmmo'"
                  [value]="'mmo_codmmo'" [description]="'description'" [data]="motivosMovimiento"
                  (cambioSelect)="guiaRemision.mmo_codmmo = $event" [model]="guiaRemision.mmo_codmmo">
                </select-search>
              </div>

            </div>

            <div class="row">

              <!-- <div class="col s12 m12 l4 xl2">
                  <mat-form-field>
                      <mat-label>Nro. Orden Serv.</mat-label>
                      <input matInput placeholder="Nro. Orden Serv." name="nro_orden_serv">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl1">
                  <button mat-mini-fab color="primary">
                      <mat-icon>download</mat-icon>
                  </button>
              </div> -->
              <!-- <div class="col s12 m12 l3 xl3">
                <mat-form-field>
                  <mat-label>Pedido Venta</mat-label>
                  <input matInput placeholder="Pedido Venta" name="pedido_venta">
                </mat-form-field>
              </div> -->
              <div class="col s12 m12 l3 xl3">
                <mat-form-field>
                  <mat-label>Factura</mat-label>
                  <input matInput placeholder="Factura" name="factura">
                </mat-form-field>
              </div>

            </div>
          </div>
        </mat-tab>
        <mat-tab label="Datos del Despacho" [disabled]="loaderData" labelClass="mat-tab-label-0-1">

          <div class="row">

            <div class="col s12 m12 l3">
              <select-search [label]="'Transportista'" [placeholder]="'Ej. Perez'" [name]="'cli_codcli'"
                [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="transportistas"
                (cambioSelect)="guiaRemision.cli_codtra = $event" [model]="guiaRemision.cli_codtra">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Unid. Trans.</mat-label>
                <mat-select [(ngModel)]="guiaRemision.grc_undtra" name="grc_undtra" required>
                  <mat-option *ngFor="let unidad of unidadesTransporte" [value]="unidad.UTR_CODUTR">
                    {{unidad.UTR_DESCRI}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Certif. de inscripción</mat-label>
                <input [(ngModel)]="guiaRemision.grc_nrocer" matInput placeholder="Nro. Certif." name="grc_nrocer" required>
              </mat-form-field>
            </div>
            <!-- <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Control Desp.</mat-label>
                <input matInput placeholder="Nro. Control Desp." [(ngModel)]="kardex.detalle_guia[0].grc_plaund" name="grc_plaund"
                  required>
              </mat-form-field>
            </div> -->
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Marca</mat-label>
                <input [(ngModel)]="guiaRemision.grc_marund" matInput placeholder="Marca" name="grc_marund" required>
              </mat-form-field>
            </div>


          </div>


          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. de Placa</mat-label>
                <input [(ngModel)]="guiaRemision.grc_plaund" matInput placeholder="Placa" name="grc_plaund" required>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. de Placa Secundario</mat-label>
                <input [(ngModel)]="guiaRemision.grc_placa2" matInput placeholder="Placa" name="grc_placa2">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Chofer</mat-label>
                <mat-select [(ngModel)]="guiaRemision.cho_codcho" name="cho_codcho" required>
                  <mat-option *ngFor="let chofer of choferes" [value]="chofer.CHO_CODCHO">
                    {{chofer.CHO_NOMBRE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. de Licencia </mat-label>
                <input [(ngModel)]="guiaRemision.grc_nrolic" matInput placeholder="Licencia" name="grc_nrolic" required>
              </mat-form-field>
            </div>


          </div>
          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Fecha entrega Transportista</mat-label>
                <input matInput [matDatepicker]="fechaRemision" name="grc_fecdoc" required>
                <mat-datepicker-toggle matSuffix [for]="fechaRemision"></mat-datepicker-toggle>
                <mat-datepicker #fechaRemision></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Agencia Transportista</mat-label>
                <mat-select [(ngModel)]="guiaRemision.cli_agetra" name="cli_agetra" required>
                  <mat-option *ngFor="let agencia of agenciaTransportes" [value]="agencia.cli_codcli">
                    {{agencia.cli_nomcli}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l6">
              <mat-form-field class="example-full-width">
                <mat-label>Observaciones </mat-label>
                <input matInput placeholder="Licencia" name="grc_nrolic" required>
              </mat-form-field>
            </div>
          </div>

          <div class="row">

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Dirección punto de partida</mat-label>
                <mat-select [(ngModel)]="guiaRemision.ldc_codldc" name="ldc_codldc" required>
                  <mat-option *ngFor="let lugar of lugaresDespachoCIA" [value]="lugar.LDC_CODLDC">
                    {{lugar.LDC_DIRLDC}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Dirección transportista</mat-label>
                <mat-select [(ngModel)]="guiaRemision.lde_codtra" name="lde_codtra">
                  <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                    {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Dirección agencia</mat-label>
                <mat-select [(ngModel)]="guiaRemision.lde_codage" name="lde_codage">
                  <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                    {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Dirección punto de llegada</mat-label>
                <mat-select [(ngModel)]="guiaRemision.lde_codlde" name="lde_codlde" required>
                  <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                    {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>



            <!-- <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Tipo Doc.</mat-label>
                <mat-select [(ngModel)]="kardex.detalle_guia[0].tdo_codtdo" name="tdo_codtdo_guia" required>
                  <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.TDO_CODTDO">
                    {{tipo.TDO_DESLAR}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l1">
              <mat-form-field>
                <mat-label>Serie</mat-label>
                <mat-select [(ngModel)]="kardex.detalle_guia[0].sdo_codsdo" name="sdo_codsdo"
                  (selectionChange)="seleccionarSerieGuia($event.value)" required>
                  <mat-option *ngFor="let serie of seriesGuia" [value]="serie.sdo_codsdo">
                    {{serie.sdo_codsdo}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l1">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Doc.</mat-label>
                <input matInput placeholder="Nro. Doc." [(ngModel)]="kardex.detalle_guia[0].grc_numdoc"
                  name="grc_numdoc" required>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Cliente</mat-label>
                <mat-select [(ngModel)]="kardex.detalle_guia[0].cli_codcli" name="cli_codcli_guia" required>
                  <mat-option *ngFor="let cliente of clientes" [value]="cliente.cli_codcli">
                    {{cliente.cli_nomcli}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->

          </div>
          <!-- <br>
          <div class="row">
            <div class="col s12 m12 l12 xl6">
              <select-search [label]="'Transportistas'" [placeholder]="'Ej. Juan Alvela S.A.C'" [name]="'cli_codtra'"
                [value]="'cli_codcli'" [description]="'description'" [data]="transportistas"
                (cambioSelect)="guiaTexto.cli_codtra = $event" [model]="guiaTexto.cli_codtra">
              </select-search>
            </div>
            <div class="col s12 m12 l12 xl4">
              <select-search [label]="'Unidad de Transporte'" [placeholder]="'Ej. UNIDAD A'" [name]="'UTR_CODUTR'"
                [value]="'UTR_CODUTR'" [description]="'description'" [data]="unidadesTransporte"
                (cambioSelect)="guiaTexto.utr_codutr = $event; setUnidadTransporte(); setChofer();"
                [model]="guiaTexto.utr_codutr">
              </select-search>
            </div>
            <div class="col s12 m12 l12 xl2">
              <mat-form-field class="example-full-width">
                <mat-label>Placa</mat-label>
                <input matInput placeholder="Placa" [(ngModel)]="guiaTexto.grc_plaund" [name]="'grc_plaund'" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl4">
              <mat-form-field class="example-full-width">
                <mat-label>No. Control Desp.</mat-label>
                <input matInput placeholder="No. Control Desp." [(ngModel)]="guiaTexto.cde_codcde"
                  name="nro_control_desp" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl4">
              <mat-form-field class="example-full-width">
                <mat-label>Marca</mat-label>
                <input matInput placeholder="Marca" [(ngModel)]="guiaTexto.grc_marund" [name]="'grc_marund'" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl4">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Certif.</mat-label>
                <input matInput placeholder="Nro. Certif." [(ngModel)]="guiaTexto.grc_nrocer" name="grc_nrocer"
                  readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl5">
              <select-search [label]="'Chofer'" [placeholder]="'Ej. Juan Alvela'" [name]="'CHO_CODCHO'"
                [value]="'CHO_CODCHO'" [description]="'description'" [data]="choferes"
                (cambioSelect)="guiaTexto.cho_codcho = $event;setChofer()" [model]="guiaTexto.cho_codcho" readonly>
              </select-search>
            </div>
            <div class="col s12 m12 l12 xl2">
              <mat-form-field class="example-full-width">
                <mat-label>Licencia </mat-label>
                <input matInput placeholder="Licencia" [(ngModel)]="guiaTexto.grc_nrolic" name="grc_nrolic" readonly>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
          </div>
          <mat-divider style="padding-bottom: 1em;"></mat-divider>
          <div class="row">
            <div class="col s12 m12 l12 xl5">
              <select-search [label]="'Agencia Transporte'" [placeholder]="'Ej. Juan Alvela'" [name]="'cli_agetra'"
                [value]="'cli_codcli'" [description]="'description'" [data]="agenciaTransportes"
                (cambioSelect)="guiaTexto.cli_agetra= $event" [model]="guiaTexto.cli_agetra">
              </select-search>
            </div>
            <div class="col s12 m12 l12 xl3">
              <select-search [label]="'Dirección Punto de Partida'" [placeholder]="'Ej. Juan Alvela'"
                [name]="'mmo_codmmo'" [value]="'mmo_codmmo'" [description]="'description'" [data]="motivosMovimiento"
                (cambioSelect)="guiaTexto.mmo_codmmo = $event" [model]="guiaTexto.mmo_codmmo">
              </select-search>
            </div>

            <div class="col s12 m12 l12 xl3">
              <select-search [label]="'Dirección Transportista'" [placeholder]="'Ej. Juan Alvela'" [name]="'mmo_codmmo'"
                [value]="'mmo_codmmo'" [description]="'description'" [data]="motivosMovimiento"
                (cambioSelect)="guiaTexto.mmo_codmmo = $event" [model]="guiaTexto.mmo_codmmo">
              </select-search>
            </div>

            <div class="col s12 m12 l12 xl3">
              <select-search [label]="'Dirección Agencia'" [placeholder]="'Ej. Juan Alvela'" [name]="'mmo_codmmo'"
                [value]="'mmo_codmmo'" [description]="'description'" [data]="motivosMovimiento"
                (cambioSelect)="guiaTexto.mmo_codmmo = $event" [model]="guiaTexto.mmo_codmmo">
              </select-search>
            </div>

            <div class="col s12 m12 l12 xl3">
              <select-search [label]="'Dirección Punto de Llegada'" [placeholder]="'Ej. Juan Alvela'"
                [name]="'mmo_codmmo'" [value]="'mmo_codmmo'" [description]="'description'" [data]="motivosMovimiento"
                (cambioSelect)="guiaTexto.mmo_codmmo = $event" [model]="guiaTexto.mmo_codmmo">
              </select-search>
            </div>
          </div> -->
        </mat-tab>
      </mat-tab-group>
    </mat-card>
    <br>
    <div class="pav-table-header-container">
      <div class="pav-table-header-icon">

      </div>
      <div class="pav-table-header-title">
        Productos
      </div>
      <div class="pav-button-icon-item">
        <mat-label class="pav-title animate__animated animate__bounceIn animate__fast">Peso Bruto: {{pesoBruto}}
          KG</mat-label>
      </div>
      &nbsp;
      &nbsp;

      <button-add-item-table (addItem)="agregarProducto()"></button-add-item-table>
      &nbsp;
      <button-remove-item-table (removeItem)="quitarProducto()"></button-remove-item-table>
      &nbsp;
      <button-insert-item-table (insertItem)="insertarProducto()"></button-insert-item-table>

    </div>
    <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSourceProductos" matSort>

        <ng-container matColumnDef="gtx_itegtx">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> item </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Item">
            <mat-form-field required [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <input type="text" [(ngModel)]="row.gtx_itegtx" matInput [maxlength]="200"
                placeholder="Escriba su descripcion" [name]="'gtx_itegtx' + i">

            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="prd_codprd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Producto">
            <select-search
              [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
              [placeholder]="'Ej. Cliente Bueno'" [name]="'prd_codprd'+i" [value]="'prd_codprd'"
              [description]="'description'" [data]="productos"
              (cambioSelect)="row.prd_codprd = $event; onChangeSeleccionarProducto(row)" [model]="row.prd_codprd">
            </select-search>
          </td>
        </ng-container>
        <ng-container matColumnDef="gtx_glogtx">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripcion">
            <mat-form-field required [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <input type="text" [(ngModel)]="row.gtx_glogtx" matInput [maxlength]="200"
                placeholder="Escriba su descripcion" [name]="'gtx_glogtx' + i">

            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="ume_codume">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad de Medida </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Unidad de Medida">
            <select-search
              [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
              [name]="'ume_codume'+i" [value]="'ume_codume'" [description]="'ume_descri'" [data]="unidadesMedida"
              (cambioSelect)="row.ume_codume = $event" [model]="row.ume_codume" [disabled]="false">
            </select-search>

          </td>
        </ng-container>
        <ng-container matColumnDef="gtx_cangtx">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Unidad de Medida">
            <mat-form-field required [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <input type="number" [(ngModel)]="row.gtx_cangtx" matInput placeholder="Ej. 10" [name]="'cantidad' + i">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="gtx_pesbru">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso Bruto kg </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Unidad de Medida">
            <mat-form-field required [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <input type="number" [(ngModel)]="row.gtx_pesbru" matInput placeholder="Ej. 10" [name]="'peso' + i">
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsProductos"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsProductos;" [class.selected-row]="row === selected"
          (click)="selectRow(row)"></tr>

        <tr class="mat-row" *matNoDataRow>

          <td class="mat-cell" colspan="7">No se encontraron registros</td>

        </tr>
      </table>
      <mat-paginator #paginatorServicios [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page of users"></mat-paginator>

    </div>

    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-almacen/guia-de-remision" (clickNuevo)="nuevoRegistro()">

      <ng-container id="botonesAdicionales">

        <button (click)="liberarGuia()" class="pav-btn-action" color="primary" matTooltip="Liberar guía" type="button">
          <mat-icon class="pav-icon-btn">edit_document</mat-icon>
        </button>

      </ng-container>
    </pav-form-actions>
  </form>
</div>
<!-- fecha de emision

fecha del periodo
indicadores para motivo de movimiento mmp_indpsa = 1 y indsta = 1

fecha de traslado

mostrar vacio el numruc en caso pase -->
