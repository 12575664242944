import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ConceptoDescuento } from "src/app/models/ventas/concepto-descuento";
import { ConfigurationService, VentasService } from "src/app/services";

@Component({
  selector: 'concepto-descuento-form',
  templateUrl: './concepto-descuento-form.component.html',
  styleUrls: ['./concepto-descuento-form.component.scss']
})
export class ConceptoDescuentoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;

  uid: string;
  concepto: ConceptoDescuento;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  loading$: Subscription;
  dataForm$: Subscription;
//MAXLENGTH PROPERTY
  ml:any={
    dco_coddco:4,
    dco_desdco:60,
    dco_obsdco:50,
  }
  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService
  ){
    this.concepto = new ConceptoDescuento();
    this.concepto.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.concepto.dco_coduse = this._configurationService.obtenerIdUsuario();

  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      if(this.uid != '0'){
        this.mostrarConceptoDescuento()
        return
      }
    })
  }
  mostrarConceptoDescuento(): void {
      this.dataForm$ = this._ventasService.mostrarConceptoDescuento(this.uid).subscribe(
      response => {
        console.log("concepto", response);
        this.concepto = response;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarConceptoDescuento(f): this.actualizarConceptoDescuento(f);
  }

  registrarConceptoDescuento(f: NgForm): void {
    this._ventasService.registrarConceptoDescuento(this.concepto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Concepto descuento registrado', '400px', '400px', '')
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  actualizarConceptoDescuento(f: NgForm): void {
    this._ventasService.actualizarConceptoDescuento(this.concepto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Concepto descuento actualizado', '400px', '400px', '')
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/conceptos-descuento']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
