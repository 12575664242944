import { BINARY_TYPE } from "@data/interfaces/binary.type";

export class ConfiguracionTecnica {
  s70_codref: string;
  s70_descri: string;
  s70_motalm: number;
  s70_vertca: number;
  s70_verref1: number;
  s70_verref2: number;
  s70_verccs: number;
  s70_indlot: number;
  s70_indser: number;
  s70_indtal: number;
  s70_guipin: number;
  s70_guipsa: number;
  s70_venlot: number;
  s70_glolot: number;
  s70_indmod: number;
  s70_indcol: number;
  s70_labcol: string;
  s70_labmod: string;
  s70_indcon: number;
  s70_indcom: number;
  s70_indgen: number;
  s70_gloaut: number;
  s70_unime2: number;
  s70_zonadi: 1 | 0;
  s70_indent: 1 | 0;
  s70_indocm: BINARY_TYPE;
  s70_almcom: BINARY_TYPE;

  constructor() {
    this.s70_motalm = 0;
    this.s70_vertca = 0;
    this.s70_verref1 = 0;
    this.s70_verref2 = 0;
    this.s70_verccs = 0;
    this.s70_indlot = 0;
    this.s70_indser = 0;
    this.s70_indtal = 0;
    this.s70_guipin = 0;
    this.s70_guipsa = 0;
    this.s70_venlot = 0;
    this.s70_glolot = 0;
    this.s70_indmod = 0;
    this.s70_indcol = 0;
    this.s70_indcon = 0;
    this.s70_indcom = 0;
    this.s70_indgen = 0;
    this.s70_gloaut = 0;
    this.s70_unime2 = 0;
  }
}
