import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { VentasService } from 'src/app/services';
import { generarPdfVoucher } from '@utils/pdfmaker/builder/ventas.pdfmaker';
import { formatDateClean } from 'src/app/utils/formats/date.format';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { TypeVoucherComponent } from '@shared/components/dialogs/type-voucher/type-voucher.component';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-comprobante-basico-list',
  templateUrl: './comprobante-basico-list.component.html',
  styleUrls: ['./comprobante-basico-list.component.css']
})
export class ComprobanteBasicoListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['select', 'acciones', 'cco_codcco', 'Fecha', 'TipDoc', 'NumDoc', 'Mon', 'Importe', 'IdCliente', 'Cliente'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<any>(true, []);

  fecha_ini: any = null;
  fecha_fin: any = null;

  year = new Date().getFullYear();
  month = new Date().getMonth();

  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date(this.year, this.month + 1, 0))
  });

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  comprobante$: Subscription;
  loading$: Subscription;

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private _router: Router
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.establecerFechaDefecto();
    })
  }

  editarComprobante(id): void {
    this._router.navigate(['editar-comprobante', id]);
  }

  establecerFechaDefecto(): void {
    let hoy = new Date()
    this.fecha_ini = new Date(`${hoy.getFullYear()}/${hoy.getMonth() + 1}/01`);
    this.fecha_fin = new Date();

    this.buscarComprobante();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) return `${this.isAllSelected() ? 'select' : 'deselect'} all`;

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  buscarComprobante(): void {
    let fechaIni = null;
    let fechaFin = null;

    if(this.fecha_fin != null && this.fecha_ini != null) {
      fechaIni = formatDateClean(this.range.value.start);
      fechaFin = formatDateClean(this.range.value.end);

      // let fechaIni = `${date_ini.getFullYear()}${date_ini.getMonth()}${date_ini.getDate()}`;
      // let fechaFin = `${date_fin.getFullYear()}${date_fin.getMonth()}${date_fin.getDate()}`;
    }

    this.comprobante$ = this._ventasService.obtenerComprobantes(fechaIni, fechaFin).subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }


  seleccionarTipo(): void {
    const dialogRef = this.dialog.open(TypeVoucherComponent, {
      width: '400px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {})
  }

  obtenerPdf(): void {
    // llamar al servicio
    generarPdfVoucher({}, {});
  }



  obtenerHora(): string {
    let date = new Date()
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    return `${hour}:${minute}:${seconds}`;
  }

  formatDate(date): string {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.comprobante$
    ])
  }

}
