export class SpCategoria {
  CIA_CODCIA: string;
  CAC_CODCAC: string;
  CAC_DESCRI: string;
  CAC_INDSTA: string;
  CAC_CODUSE: string;
  CAC_FECUPD: Date;
  CCN_CODCCN: string;
  CCN_CODREL: string

  constructor() {
    this.CAC_INDSTA = "1";
    this.CAC_FECUPD = new Date();
  }
}
