import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-consulta-asiento-contable',
  templateUrl: './consulta-asiento-contable.component.html',
  styleUrls: ['./consulta-asiento-contable.component.css'],
})
export class ConsultaAsientoContableComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['LDI_CORLDI', 'CCN_CODCCN', 'ccn_descri', 'CLI_CODCLI', 'CCS_CODCCS', 'TDO_CODTDO', 'LDI_DOCREF', 'LDI_FECEMI', 'LDI_FECVEN', 'TMO_CODTMO', 'LDI_TIPCAM', 'LDI_INDDHA', 'LDI_IMPNAC', 'LDI_IMPMEX', 'LDI_GLOLDI', 'CCO_CODCCO'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  subdiarios: any[] = [];

  estado: string = "1";

  year: string;
  month: string;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._contabilidadService.verConsultaAsientoContable(this.year, this.month, "0301", "00001").subscribe(
      consulta => {
        this.dataSource = fillTable(consulta, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError("Error al obtener  consulta de asiento contable", "OK");
      }
    )
    this.loadMaestros();
  }

  loadMaestros(): void {
    this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => {
        this.subdiarios = subdiarios;
      },
      error => this._snackBarService.showError("Error al obtener subdiarios", "OK")
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }
}
