<app-pavso-title-section [title]="'apps.crm.maestros.actividad.title' | translate" [module]="'CRM'" [estado]="actividad.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div style="overflow: auto; --zoneY:179.375px; --zoneX:495.625px; --zoneColor: rgba(0, 0, 0, 0.7) ; --zoneSize:96.4063px;"
class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.maestros.actividad.form.code' | translate}}</mat-label>
                <input type="number" [(ngModel)]="actividad.idass" matInput [placeholder]="'apps.crm.maestros.actividad.form.code' | translate" name="idass" readonly>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l6">
                <mat-form-field>
                    <mat-label>{{'apps.crm.maestros.actividad.form.description' | translate}}</mat-label>
                    <input type="text" [(ngModel)]="actividad.descri" matInput [placeholder]="'apps.crm.maestros.actividad.form.description' | translate" name="descri" required>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.maestros.actividad.form.activity-group' | translate}}</mat-label>
                <mat-select [(ngModel)]="actividad.idgac" name="idgac">
                  <mat-option *ngFor="let grupo of gruposActividad; trackBy:trackByGrupo" [value]="grupo.idgac">
                    {{grupo.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.maestros.actividad.form.stage' | translate}}</mat-label>
                <mat-select [(ngModel)]="actividad.codoet" name="codoet">
                  <mat-option *ngFor="let etapa of etapas; trackBy: trackByEtapa" [value]="etapa.codoet">
                    {{etapa.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>

    </mat-card>

    <log-updated *ngIf="uid!='0'" [coduse]="actividad.coduse" [fecupd]="actividad.fecupd"></log-updated>

    <br>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-crm/actividades-crm">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>

<!-- <audio id="player" src="assets/mp3/notification.mp3"></audio> -->
