export class OrderPdf {
  CIA_CODCIA: any;
  SUC_CODSUC: any;
  PCC_NUMPCC: any;
  MES_CODMES: any;
  CLI_CODCLI: string;
  cli_nomcli: any;
  PCC_FECDOC: any;
  PCC_FECVEN: any;
  PCC_FECENT: any;
  VDE_CODVDE: any;
  VDE_NOMVDE: any;
  CPA_CODCPA: any;
  CPA_DESCRI: any;
  PCC_OBSPED: any;
  pcc_refped: any;
  PCC_TIPCAM: any;
  ALM_CODALM: any;
  PCC_IMPBRU: any;
  PCC_IMPDES: any;
  PCC_VALVTA: any;
  PCC_IMPIGV: any;
  PCC_IMPTOT: any;
  PCC_SITPCC: any;
  MMO_CODMMO: any;
  pcc_ordcom: any;
  spcc_fecdoc: any;
  mmo_descri: any;
  PCC_FECTRA: any;
  TLP_CODTLP: any;
  tlp_descri: any;
  LPC_CODLPC: any;
  PCC_TASIGV: any;
  PCC_FECCRE: any;
  ZVE_CODZVE: any;
  ZVE_NOMZVE: any;
  PCC_IMPSEG: any;
  PCC_IMPFLE: any;
  PCC_FECTER: any;
  DCM_CODDCM: any;
  SEP_NUMSEP: any;
  PAP_SECPAP: any;
  PAP_INDMAX: any;
  lde_deslar: any;
  desubica: any;
  CIA_NUMRUC: any;
  CIA_NOMCIA: any;
  cli_numruc: any;
  TMO_CODTMO: any;
  TMO_DESLAR: any;
  pap_fecupd: any;
  sdv_dessdv: any;
  diarutdes: any;
  sec_codsec: any;
  spap_fecupd: any;
  pcd_ipenate: any;
  pcd_ipenent: any;
  pcd_ientpar: any;
  pcd_icansus: any;
  pcd_icanate: any;
  docentregado: any;
  pcc_anucom: any;
  cpd_codcpd: any;
  sdv_codsdv: any;
  CIA_DIRCIA: any;
  cli_dircli: any;
  desubica_cliente: any;
  CLI_CODPRO: any;
  cli_nomtra: any;
  cli_dirtra: any;
  desubica_transpor: any;
  PCC_CODUSE: string;
  S10_NOMUSU: any;
  detalle: any;
  constructor(
  ){
    this.pap_fecupd = new Date();
    this.spap_fecupd = new Date();
    this.spcc_fecdoc = new Date();
    this.PCC_FECCRE = new Date();
    this.PCC_FECDOC = new Date();
    this.PCC_FECENT = new Date();
    this.PCC_FECTER = new Date();
    this.PCC_FECTRA = new Date();
    this.PCC_FECVEN = new Date();
  }
}
