export class SolicitudCompra {
  cia_codcia: string;
  rco_numrco: string;
  rco_fecreq: Date;
  rco_gloobs: string;
  cli_codsol: string;
  rco_indtip: string;
  rco_indsta: string;
  rco_coduse: string;
  rco_fecupd: string;
  rco_tipcom: string;
  rco_fecque: string; // Formato fecha: "2019-07-03"
  rco_tipnac: string;
  rco_deldat: string;
  rco_incote: string;
  rco_cliaut: string;
  rco_aprrco: number;
  rco_apruse: string;
  rco_aprfec: Date;
  rco_clipro: string;
  rco_fecofe: string;
  rco_pestot: number;
  rco_meddes: string;
  rco_facimp: number;
  rco_motcom: string;
  rco_codccs: string;
  rco_codcli: string;
  rco_codped: string;
  rco_punlle: string;
  rco_punpar: string;
  rco_valmer: number;
  rco_mattra: string;
  tmo_codtmo: string;
  detalle_rcd: Array<ItemDetalleSolicitudCompra>

  constructor() {
    this.detalle_rcd = [];
  }
}

export class ItemDetalleSolicitudCompra {
  cia_codcia: string;
  rco_numrco: string;
  rcd_corrcd: string;
  prd_codprd: string;
  rcd_cansol: number;
  rcd_cancom: number;
  ume_codume: string;
  rcd_coduse: string;
  rcd_fecupd: string;
  rcd_detalle: string;
  rcd_preuni: number;
  rcd_imptot: number;
  rcd_motcom: string;
  rcd_codccs: string;
  rcd_fecreq: Date;
  rcd_pesrcd: number;
  rcd_codcli: string;
  rcd_codped: string;

  constructor() {
    this.rcd_imptot = 0;
    this.rcd_preuni = 0;
    this.rcd_cansol = 0;
    this.rcd_cancom = 0;
    this.rcd_fecreq = new Date();
    this.rcd_pesrcd = 0;
  }
}
