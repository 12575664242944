import { DiasEntrega } from "./dias-entrega";

export class Establishment {
  CIA_CODCIA: any;
  CLI_CODCLI: string;
  LDE_CODLDE: any;
  LDE_NOMLDE: any;
  LDE_DIRLDE: any;
  PAI_CODPAI: any;
  PAI_NOMLAR: any;
  UBI_CODUBI: any;
  UBI_DESUBI: string;
  ZVE_CODZVE: any;
  ZVE_DESZVE: string;
  LDE_INDGUI: any;
  CTI_CODCTI: any;
  VDE_CODVDE: any;
  LDE_CODANT: any;
  LDE_INDSTA: string;
  LDE_CODUSE: string;
  LDE_FECUPD: any;
  LDE_ORDVIS: any;
  LDE_INOTIE: any;
  TCC_CODTCC: any;
  LDE_TLFLDE: any;
  LDE_NOMCON: any;
  LDE_REFDIR: any;
  LDE_INDPRI: any;
  SUC_CODSUC: any;
  CCS_CODCCS: any;
  detalle_dias_cds:Array<DiasEntrega>;
  isEditing: boolean;
  constructor(
  ){
    this.detalle_dias_cds = []
    this.isEditing = true;
    this.LDE_FECUPD = new Date();
    this.LDE_INDGUI = 0;
    this.VDE_CODVDE = "";
    this.LDE_CODANT = "";
    this.LDE_INDSTA = "1";
    this.LDE_CODUSE = "";
    this.LDE_FECUPD = new Date();
    this.LDE_ORDVIS = 0;
    this.LDE_INOTIE = 0;
    this.TCC_CODTCC = "";
    this.LDE_TLFLDE = "";
    this.LDE_NOMCON = "";
    this.LDE_REFDIR = "";
    this.LDE_INDPRI = 1;
    this.SUC_CODSUC = "";
    this.CCS_CODCCS = "";
    this.CTI_CODCTI = "";
  }
}
