import { Component, NgModule } from "@angular/core";
import { FacturaFormComponent } from "./factura-form/factura-form.component";
import { FacturaListComponent } from "./factura-list/factura-list.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from "@shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { ClienteModule } from "../../maestros/cliente/cliente.module";
import { componentsAdmin } from "src/app/admin/components";
import { GeneralComponentsModule } from "src/app/admin/components/general-components.module";

@NgModule({
  declarations: [FacturaFormComponent, FacturaListComponent],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    GeneralComponentsModule,
    SharedModule,
    ClienteModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    FacturaFormComponent, FacturaListComponent
  ]
})
export class FacturaModule { }
