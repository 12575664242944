import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { ContratoPersonal } from 'src/app/models/planilla/operaciones/contrato';
import { ConfigurationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-contrato-form',
  templateUrl: './contrato-form.component.html',
  styleUrls: ['./contrato-form.component.css'],
})
export class ContratoFormComponent implements OnInit, OnDestroy {
  loaderReg: boolean = false;
  loaderData: boolean = false;

  contratoPersonal: ContratoPersonal;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  colaboradores: any[] = [];
  plantillas: any[] = [];
  cargos: any[] = [];
  representantes: any[] = [];

  contrato$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;
  colaboradores$: Subscription;
  planillas$: Subscription;
  cargos$: Subscription;
  firmas$: Subscription;

  uid: string;
  correlativo: string;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _nominaService: NominaService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {
    this.usuario = this._configurationService.obtenerIdUsuario();

    this.contratoPersonal = new ContratoPersonal();
    this.contratoPersonal.codcia = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) {
        this.loadData();
      }
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      this.loadMaestro();

      if (id == '0') return;
      this.obtenerContrato();
    });
  }

  obtenerContrato(): void {
    this.contrato$ = this._nominaService.listarContratos().subscribe(
      (contratos) => {
        this.contratoPersonal = contratos.find(
          (item) => item.codnmc == this.uid
        );
      },
      (error) => this._snackBarService.showError(error.error.msg, 'OK')
    );
  }

  loadMaestro(): void {
    this._nominaService.obtenerDataFormContrato().subscribe(
      dataForm => {
        this.colaboradores = dataForm.colaboradores;
        this.plantillas = dataForm.plantillas;
        this.cargos = dataForm.cargos;
        this.representantes = dataForm.firmas;
      },
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarContrato(f) : this.actualizarContrato(f);
  }

  /**
   * Método para registrar un nuevo Contrato
   * @param f
   */
  registrarContrato(f: NgForm): void {
    this.contratoPersonal.indcie
      ? (this.contratoPersonal.indcie = 1)
      : (this.contratoPersonal.indcie = 0);

    this.sendForm$ = this._nominaService.registrarContrato(this.contratoPersonal).subscribe(
      (response) => {
        this._dialogService.openDialog(
          SuccessComponent,
          'Contrato registrado',
          '400px',
          '380px',
          ''
        );
        f.resetForm();
        this.loaderReg = false;
      },
      (error) => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    );
  }

  /**
   * Método para actualizar contrato
   * @param f
   */
  actualizarContrato(f: NgForm): void {
    this.contratoPersonal.indcie
      ? (this.contratoPersonal.indcie = 1)
      : (this.contratoPersonal.indcie = 0);

    this.sendForm$ = this._nominaService.actualizarContrato(this.contratoPersonal).subscribe(
      (response) => {
        this._dialogService.openDialog(
          SuccessComponent,
          'Contrato actualizado',
          '400px',
          '380px',
          ''
        );
        this.loaderReg = false;
      },
      (error) => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    );
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/contratos']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.contrato$,
      this.loading$,
      this.colaboradores$,
      this.planillas$,
      this.cargos$,
      this.firmas$,
      this.loading$
    ])
  }
}
