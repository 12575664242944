<app-pavso-title-section [title]="'Consulta de movimientos de cuentas'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <form>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s6">
          <mat-form-field>
            <mat-label>Cuenta Contable</mat-label>
            <mat-select>
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.descri_cuenta}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Mes</mat-label>
            <mat-select>
              <mat-option value="1">
                Mes 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select>
              <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
                {{moneda.tmo_deslar}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s6">
          <mat-form-field>
            <mat-label>Auxiliar</mat-label>
            <mat-select>
              <mat-option *ngFor="let auxiliar of auxiliares" [value]="auxiliar.CODIGO">
                {{auxiliar.NOMBRE}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Tipo Doc.</mat-label>
            <mat-select>
              <mat-option *ngFor="let tipo of tiposDoc" [value]="tipo.TDO_CODTDO">
                {{tipo.TDO_DESCOR}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Nro. Documento</mat-label>
            <input matInput placeholder="Nro. Documento">
          </mat-form-field>
        </div>
        <div class="col s6">
          <mat-form-field>
            <mat-label>Subdiario</mat-label>
            <mat-select>
              <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
                {{subdiario.sco_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s6">
          <mat-form-field>
            <mat-label>Proyecto o Centro de Costo</mat-label>
            <mat-select>
              <mat-option value="1">
                centro 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>

    <br>

    <div class="mat-elevation-z0">

      <table mat-table [dataSource]="dataSource2" matSort >

          <ng-container matColumnDef="mes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
              <td mat-cell *matCellDef="let row" data-label="Mes"> {{row.CHO_CODCHO}} </td>
          </ng-container>

          <ng-container matColumnDef="subdiario_correlativo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Subdiario - Correlativo </th>
              <td mat-cell *matCellDef="let row" data-label="Subdiario - Correlativo"> {{row.CHO_NOMBRE}} </td>
          </ng-container>

          <ng-container matColumnDef="item_detalle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item (Detalle) </th>
              <td mat-cell *matCellDef="let row" data-label="Item (Detalle)"> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="documento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
            <td mat-cell *matCellDef="let row" data-label="Documento"> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="cod_auxiliar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Auxiliar </th>
            <td mat-cell *matCellDef="let row" data-label="Cod. Auxiliar"> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="auxiliar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
            <td mat-cell *matCellDef="let row" data-label="Auxiliar"> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="cta_cte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
            <td mat-cell *matCellDef="let row" data-label="Cta. Cte."> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="debe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Debe </th>
            <td mat-cell *matCellDef="let row" data-label="Debe"> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="haber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Haber </th>
            <td mat-cell *matCellDef="let row" data-label="Haber"> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha_emision">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Emisión"> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="ce_co">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ce. Co. </th>
            <td mat-cell *matCellDef="let row" data-label="Ce. Co."> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <ng-container matColumnDef="glosa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
            <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.CHO_NROLIC}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="12">No se encontraron registros</td>
          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

    <br>

    <button mat-flat-button color="primary">Consulta</button>
  </form>

</div>
