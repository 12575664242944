import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { ContabilidadService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-consulta-movimiento-cta-cte',
  templateUrl: './consulta-movimiento-cta-cte.component.html',
  styleUrls: ['./consulta-movimiento-cta-cte.component.css']
})
export class ConsultaMovimientoCtaCteComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['ldi_messco', 'ldi_corldi', 'ccn_codccn', 'ldi_impdeb', 'ldi_imphab', 'ldi_numdoc', 'cco_fecemi_103', 'ldi_gloldi', 'cco_codcco'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  monedas: any[] = [];

  year: string;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _contabilidadService: ContabilidadService,
    private _generalService: GeneralService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=>{
      this._contabilidadService.verConsultaMovimientoCtaCte(this.year, id, 'SO').subscribe(
        consulta => {
          this.dataSource = fillTable(consulta, this.paginator, this.sort);
        },
        error => {
          this._snackBarService.showError("Error al obtener consulta de movimiento Cta. Cte.", "OK");
        }
      )
    })
    this.loadMaestros();
  }

  loadMaestros(): void {
    this._generalService.obtenerMonedas().subscribe(
      monedas => this.monedas = monedas,
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }

}
