import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { ordenerArregloJSONxLlave } from "@utils/array/order";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { filterMultiCustom } from "@utils/select/select";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'select-report',
  templateUrl: './select-report.component.html',
  styleUrls: ['./select-report.component.scss']
})
export class SelectReportComponent implements OnInit, OnDestroy, OnChanges {

  @Input() reportes: any[] = [];
  @Input() disabled: boolean = true;
  @Output() seleccionarReporte = new EventEmitter<any>();
  @Input() reporteSeleccionado: string;
  /** filtrar responsables */
  protected informes: any[] = ordenerArregloJSONxLlave('name', this.reportes);
  public informeCtrl: FormControl = new FormControl();
  public informeFilterCtrl: FormControl = new FormControl();
  public filteredInformes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('informeSelect', { static: true }) informeSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes reportes', changes);
    if (changes.reportes) {
      this.reportes = changes.reportes.currentValue;
      this.informes = ordenerArregloJSONxLlave('name', this.reportes);

      this.filteredInformes.next(this.informes.slice());

      this.informeFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterInformes();
        });
    }
  }

  ngOnInit(): void {
    this.informes = ordenerArregloJSONxLlave('name', this.reportes);

    this.filteredInformes.next(this.informes.slice());

    this.informeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterInformes();
      });
  }

  seleccionarInforme(idInforme): void {
    const informe = this.reportes.find(item => item.id == idInforme);

    console.log('informe seleccionado', informe);

    this.seleccionarReporte.emit(informe);
  }

  protected filterInformes(): void {

    this.filteredInformes.next(
      filterMultiCustom(this.informes, this.informeFilterCtrl.value, 'name')
    )

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([])
  }
}
