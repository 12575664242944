export class FormulacionHojaRuta {
  producto: string;
  tipoInventario: string;
  unidad: string;
  cantidad: number;
  detalle_formula: Array<Formula>
  constructor() {
    this.detalle_formula = [];
  }
}

export class Formula {
  numero: string;
  descripcion: string;
  cantidad_prod: number;
  estado: boolean;
  seleccionado: boolean;
  detalle_rutas: Array<Ruta>
  detalle_materiales: Array<Material>

  constructor() {
    this.seleccionado = false;
    this.detalle_rutas = [];
    this.detalle_materiales = [];
  }
}

export class Ruta {
  nro: string;
  descripcion: string;
  estado: boolean;
  seleccionado: boolean;
  detalle_hoja_ruta: Array<HojaRuta>

  constructor() {
    this.seleccionado = false;
    this.detalle_hoja_ruta = [];
  }
}

export class Material {
  codigo: string;
  codigo_laboratorio: string;
  descripcion: string;
  um: string;
  cantidad: number;

  constructor() {

  }
}

export class HojaRuta {
  area: string;
  proceso: string;
  actividad: string;
  tiempo: string;

}
