import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProductoFormComponent } from "../producto-form/producto-form.component";
import { ComponentType } from "@angular/cdk/portal";
import { ProductoDialog } from "../producto-dialog/producto-dialog.component";
import { EntidadKey } from "@data/constants/component/entity-key.constant";

@Component({
  selector: 'producto-select',
  templateUrl: './producto-select.component.html',
  styleUrls: ['./producto-select.component.scss']
})
export class ProductoSelect {
  id: string = EntidadKey.producto_key;
  dialogComponent: ComponentType<any> = ProductoDialog;

  @Input() disabled: boolean;
  @Input() required: boolean = false;
  @Input() placeholder: string;
  @Input() clase: string;
  @Input() label: string;
  @Input() value: any;
  @Input() model: any;
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() multiple: boolean = false;
  @Input() iconAction: string;
  @Input() tooltipAction: string;
  @Output() cambioSelect: EventEmitter<any>;
  @Output() clickAction: EventEmitter<any>;

  @Input() data: any[] = [];

  constructor() {

    this.cambioSelect = new EventEmitter<string>();
    this.clickAction = new EventEmitter<any>();

  }
}
