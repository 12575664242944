<app-pavso-title-section [title]="'Tipo de cambio de importación'" [module]="'Contabilidad'" [usuario]="driver.CHO_CODUSE" [fecha]="driver.CHO_FECUPD" [ngClass]="'pav-form'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l4">
                  <mat-form-field>
                      <mat-label>Orden de Importación</mat-label>
                      <input type="text" matInput placeholder="Orden de Importación" name="orden_importacion" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                  <mat-form-field>
                      <mat-label>Fecha</mat-label>
                      <input matInput [matDatepicker]="fechahoy">
                      <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
                      <mat-datepicker #fechahoy></mat-datepicker>
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l6">
                <app-currency-select [moneda]="'SO'" [disable]="loaderData" (cambioMoneda)="$event"></app-currency-select>

              </div>

              <div class="col s12 m12 l6">
                  <mat-form-field>
                      <mat-label>Tipo de Cambio</mat-label>
                      <input type="text" matInput placeholder="Tipo de Cambio" name="tipo_cambio" required>
                  </mat-form-field>
              </div>

          </div>

      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
