<app-pavso-title-section [title]="'Modelos'" [module]="'Ventas'" [usuario]="" [fecha]=""></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todos los modelos" url="/modulo-ventas/modelo-de-producto" entity="" [params]="['0']"></table-title-action>

    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <div class="pav-btns-container">
                  <a matTooltip="Editar modelo"
                    class="pav-btn-circle-edit"
                    [routerLink]="['/modulo-ventas/modelo', row.Cod_Vendedor]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </a>
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="Cod_Vendedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.Cod_Vendedor}} </td>
          </ng-container>

          <ng-container matColumnDef="Nom_vendedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
              <td mat-cell *matCellDef="let row" data-label="Vendedor"> {{row.Nom_vendedor}} </td>
          </ng-container>

          <ng-container matColumnDef="Telef_vendedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.Telef_vendedor}} </td>
          </ng-container>

          <ng-container matColumnDef="Mail_Vendedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mail </th>
              <td mat-cell *matCellDef="let row" data-label="Mail"> {{row.Mail_Vendedor}} </td>
          </ng-container>

          <ng-container matColumnDef="Usuario_Reg">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
              <td mat-cell *matCellDef="let row" data-label="Usuario"> {{row.Usuario_Reg}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

          </tr>

      </table>
    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
