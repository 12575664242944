import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import axios from "axios";
import { Observable } from "rxjs";
import { DocumentoRecepcion, RecepcionCompra } from "src/app/models/compras/recepcion";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ReceptionCompraService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  listarRecepcion(anioPeriodo: string, mesPeriodo: string): Observable<any> {

    let query = '';

    if (anioPeriodo && mesPeriodo) query = `?anio=${anioPeriodo}&mes=${mesPeriodo}&sinregistro=true`

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/recepcioncompras/listar/${this._configurationService.obtenerCompaniaCliente()}${query}`,
    )
  }

  obtenerRecepcion(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/recepcioncompras/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`,
    )
  }

  registrarRecepcion(recepcionCompra: RecepcionCompra): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/recepcioncompras/crear`,
      recepcionCompra
    )
  }

  registrarMasivamente(recepciones: Array<RecepcionCompra>): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/recepcioncompras/crearmasivo`,
      recepciones
    )
  }

  registrarRecepcionAxios(recepcionCompra): Promise<any> {
    const headers = {
      'Authorization': `Bearer ${this._configurationService.obtenerToken()}`
    };

    return axios.post<any>(`${this._configurationService.obtenerUrl()}/api/recepcioncompras/crear`, recepcionCompra, { headers })
  }

  actualizarRecepcion(recepcionCompra): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/recepcioncompras/modificar`,
      recepcionCompra
    )
  }

  adjuntarDocumentos(documentoRecepcion: DocumentoRecepcion[]): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/recepcioncompras/crearimagenes`,
      documentoRecepcion
    )
  }

  obtenerDocumentosRecepcion(idRecepcion): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/recepcioncompras/mostrarimagenes/${this._configurationService.obtenerCompaniaCliente()}/${idRecepcion}`,
    )
  }

  eliminarDocumentoRecepcion(id): Observable<any> {
    return this.http.delete<any>(
      `${this._configurationService.obtenerUrl()}/api/recepcioncompras/eliminaimagen/${id}`,
    )
  }
}
