export class TipoServicioProveedor {

  CIA_CODCIA: string;
  TSE_CODTSE: string;
  TSE_DESTSE: string;
  TSE_DESABR: string;
  TSE_INDSTA: string;
  TSE_CODUSE: string;
  TSE_FECUPD: Date;

  constructor() {
    this.TSE_INDSTA = '1';
    this.TSE_FECUPD = new Date();
  }

}
