import { PavsoState } from "@data/interfaces/state/pavso-state";
import { ActionReducerMap } from "@ngrx/store";
import { languageReducer } from "@shared/state/reducers/language.reducer";
import { loadingReducer } from "@shared/state/reducers/loading.reducer";
import { productReducer } from "./shared/state/reducers";
import { configReducer } from "./shared/state/reducers/config.reducer";
import { currentPageReducer } from "./shared/state/reducers/current-page.reducer";
import { menuStateReducer } from "./shared/state/reducers/menu-state.reducer";
import { nominaReducer } from "./shared/state/reducers/nomina.reducers";
import { periodReducer } from "./shared/state/reducers/period.reducer";
import { permissionReducer } from "./shared/state/reducers/permission.reducer";
import { establecimientoReducer } from "@shared/state/reducers/establishment.reducer";

export const appReducers: ActionReducerMap<PavsoState> = {
  config: configReducer,
  period: periodReducer,
  products: productReducer,
  nomina: nominaReducer,
  permissions: permissionReducer,
  currentPage: currentPageReducer,
  menuState: menuStateReducer,
  loading: loadingReducer,
  language: languageReducer,
  establishment: establecimientoReducer
}
