<app-pavso-title-section [title]="'Invoice'" [module]="'Importaciones'" [ngClass]="'pav-form'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarInvoice(f)" (keydown.enter)="$event.preventDefault()">
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-importaciones/invoices">
      <div id="botonesAdicionales">
      </div>
    </pav-form-actions>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>N° O/I</mat-label>
            <input type="text" matInput placeholder="N° O/I" name="nro_oi" required [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="fecha" [(ngModel)]="invoice.fecha" name="fecha"
              (dateChange)="seleccionarFecha()" [disabled]="loaderData">
            <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
            <mat-datepicker #fecha></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Estado</mat-label>
            <mat-select [(ngModel)]="invoice.estado" name="estado" [disabled]="loaderData">
              <mat-option value="1">
                VIGENTE
              </mat-option>
              <mat-option value="0">
                NO VIVENTE
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>T/C</mat-label>
            <input type="number" matInput placeholder="TC" [(ngModel)]="invoice.tc" name="tc" required disabled>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col s12 m12 l12 xl6">

          <proveedor-select [label]="'Proveedor'" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli'"
            [value]="'cli_codcli'" [description]="'cli_nomcli'" [disabled]="loaderData" [data]="proveedores" [canAdd]="true" [canEdit]="true">
          </proveedor-select>

        </div>

        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Doc. Ref.</mat-label>
            <mat-select [disabled]="loaderData">
              <mat-option value="docref">
                doc ref
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>T/C EU</mat-label>
            <input type="number" matInput placeholder="T/C EU" name="tc" required [disabled]="loaderData">
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <app-currency-select [moneda]="'SO'" [disable]="loaderData" (cambioMoneda)="$event"></app-currency-select>
        </div>

        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Orden de compra</mat-label>
            <mat-select [disabled]="loaderData">
              <mat-option *ngFor="let orden of ordenes" [value]="orden.CODIGO">
                {{orden.NOMBRE}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">
          <button mat-mini-fab color="primary" type="button" matTooltip="Validar Importe" [disabled]="loaderData">
            <mat-icon>check_circle</mat-icon>
          </button>
        </div>

      </div>
    </mat-card>
    <br>
    <mat-tab-group>
      <mat-tab label="Detalle Factura" labelClass="mat-tab-label-0-0">
        <br>
        <button mat-icon-button color="primary" type="button" (click)="seleccionarProducto()">
          <mat-icon>add</mat-icon>
        </button>
        &nbsp;
        <mat-form-field class="pavso_input_search">
          <mat-label>Código de barra</mat-label>
          <input #code matInput [placeholder]="buttonsName.BTN_SEARCH" (keyup.enter)="searchCode(code.value)">
          <mat-icon matSuffix>calendar_view_week</mat-icon>
        </mat-form-field>
        <div class="mat-elevation-z0">

          <table mat-table [dataSource]="dataSource" matSort matSort>
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button type="button" matTooltip="Ver PDF" (click)="mostrarPdf(row.ocm_numocm)" mat-icon-button
                  color="warn">
                  <mat-icon>picture_as_pdf</mat-icon>
                </button>
                <button type="button" matTooltip="Editar producto"
                  [routerLink]="['/modulo-operaciones/editar-orden-de-compra', row.ocm_numocm]" mat-icon-button
                  color="primary">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="item"> {{i + 1}} </td>
            </ng-container>

            <ng-container matColumnDef="codigo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.PRD_CODPRD}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.PRD_DESESP }} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.PCD_CANSOL}} </td>
            </ng-container>

            <ng-container matColumnDef="precio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
              <td mat-cell *matCellDef="let row" data-label="Precio"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="importe_fob">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe FOB </th>
              <td mat-cell *matCellDef="let row" data-label="Importe FOB"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="gasto_invoice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Gasto Invoice </th>
              <td mat-cell *matCellDef="let row" data-label="Gasto Invoice"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="gastos_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Gastos Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Gastos Doc."> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="total_gastos">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Gastos </th>
              <td mat-cell *matCellDef="let row" data-label="Total Gastos"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="total_importacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Importación </th>
              <td mat-cell *matCellDef="let row" data-label="Total Importación"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="precio_mn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio MN </th>
              <td mat-cell *matCellDef="let row" data-label="Precio MN"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="importe_mn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe MN </th>
              <td mat-cell *matCellDef="let row" data-label="Importe MN"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="13">No se encontraron registros</td>

            </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </mat-tab>
      <mat-tab label="Gastos x Distribuir" labelClass="mat-tab-label-0-1">
        <br>
        <button mat-icon-button color="primary" type="button" (click)="seleccionarProducto()">
          <mat-icon>add</mat-icon>
        </button>
        &nbsp;
        <mat-form-field class="pavso_input_search">
          <mat-label>Código de barra</mat-label>
          <input #code matInput [placeholder]="buttonsName.BTN_SEARCH" (keyup.enter)="searchCode(code.value)">
          <mat-icon matSuffix>calendar_view_week</mat-icon>
        </mat-form-field>
        <div class="mat-elevation-z0">

          <table mat-table [dataSource]="dataSourceGasto" matSort matSort>
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button type="button" matTooltip="Ver PDF" (click)="mostrarPdf(row.ocm_numocm)" mat-icon-button
                  color="warn">
                  <mat-icon>picture_as_pdf</mat-icon>
                </button>
                <button type="button" matTooltip="Editar producto"
                  [routerLink]="['/modulo-importaciones/editar-orden-de-compra', row.ocm_numocm]" mat-icon-button
                  color="primary">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="item"> {{i + 1}} </td>
            </ng-container>

            <ng-container matColumnDef="codigo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.PRD_CODPRD}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.PRD_DESESP }} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.PCD_CANSOL}} </td>
            </ng-container>

            <ng-container matColumnDef="precio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
              <td mat-cell *matCellDef="let row" data-label="Precio"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="importe_fob">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe FOB </th>
              <td mat-cell *matCellDef="let row" data-label="Importe FOB"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="gasto_invoice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Gasto Invoice </th>
              <td mat-cell *matCellDef="let row" data-label="Gasto Invoice"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="gastos_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Gastos Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Gastos Doc."> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="total_gastos">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Gastos </th>
              <td mat-cell *matCellDef="let row" data-label="Total Gastos"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="total_importacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Importación </th>
              <td mat-cell *matCellDef="let row" data-label="Total Importación"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="precio_mn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio MN </th>
              <td mat-cell *matCellDef="let row" data-label="Precio MN"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="importe_mn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe MN </th>
              <td mat-cell *matCellDef="let row" data-label="Importe MN"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsGasto"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsGasto;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="13" *ngIf="!loaderGasto">No se encontraron registros</td>
              <td class="mat-cell" colspan="13" *ngIf="loaderGasto">
                <mat-spinner diameter="40"></mat-spinner>
              </td>
            </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </mat-tab>
    </mat-tab-group>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl1">
          <div class="pavso-center-element">
            <button type="button" mat-fab color="primary" matTooltip="Distribuir Gastos">
              <mat-icon>settings</mat-icon>
            </button>
            &nbsp;
            <button type="button" mat-fab color="primary" matTooltip="Cerrar">
              <mat-icon>event_busy</mat-icon>
            </button>
          </div>
        </div>
        <div class="col s12 m12 l12 xl2">
          <mat-form-field>
            <mat-label>Total Cant.</mat-label>
            <input type="number" matInput placeholder="Total Cant." name="total_cant" required>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Situación</mat-label>
            <input type="text" matInput placeholder="Situación" name="situacion" required>
          </mat-form-field>

          <h2>CERRADO</h2>
        </div>
        <div class="col s12 m12 l12 xl2">
          <mat-form-field>
            <mat-label>Importe FOB</mat-label>
            <input type="number" matInput placeholder="Importe FOB" name="importe_fob" required>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Flete y Seguro</mat-label>
            <input type="number" matInput placeholder="Flete y Seguro" name="flete_seguro" required>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Otros Gastos</mat-label>
            <input type="number" matInput placeholder="Otros Gastos" name="otros_gastos" required>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Imp. Merc. No Recibidas</mat-label>
            <input type="number" matInput placeholder="Imp. Merc. No Recibidas" name="imp_merc_no_recibidas" required>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Total Fact. Imp.</mat-label>
            <input type="number" matInput placeholder="Total Fact. Imp." name="total_fact_imp" required disabled>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl1">
          <div class="pavso-center-element">
            <button type="button" mat-fab color="primary" matTooltip="Distribuir Gastos">
              <mat-icon>description</mat-icon>
            </button>
            &nbsp;
            <button type="button" mat-fab color="primary" matTooltip="Cerrar">
              <mat-icon>article</mat-icon>
            </button>
          </div>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Total Gastos</mat-label>
            <input type="number" matInput placeholder="Total Gastos" name="total_gastos" required disabled>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Factor</mat-label>
            <input type="number" matInput placeholder="Factor" name="factor" required>
          </mat-form-field>

          <section class="example-section">
            <mat-checkbox class="example-margin">Importación Sin Gasto</mat-checkbox>
          </section>
          <section class="example-section">
            <mat-checkbox class="example-margin">Ajuste Revisado</mat-checkbox>
          </section>

          <mat-form-field>
            <mat-label>Total MN</mat-label>
            <input type="number" matInput placeholder="Total MN" name="total_mn" required disabled>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">

          <div class="col s12 m12 l12 xl12">
            <h4>Total Por Distribuir</h4>

          </div>
          <div class="col s12">
            <mat-form-field appearance="fill">
              <mat-label>M.N.</mat-label>
              <input type="number" matInput placeholder="M.N." name="total_mn" required>
            </mat-form-field>
          </div>
          <div class="col s12">
            <mat-form-field appearance="fill">
              <mat-label>M.E.</mat-label>
              <input type="number" matInput placeholder="M.E." name="total_mn" required>
            </mat-form-field>
          </div>
          <div class="col s12">
            <mat-form-field>
              <mat-label>S/.</mat-label>
              <input type="number" matInput placeholder="S/." name="total_mn" required>
            </mat-form-field>
          </div>
          <div class="col s12">
            <mat-form-field>
              <mat-label>$</mat-label>
              <input type="number" matInput placeholder="$" name="total_mn" required>
            </mat-form-field>
          </div>
          <div class="col s12">
            <mat-form-field appearance="fill">
              <mat-label>TOTAL M.E.</mat-label>
              <input type="number" matInput placeholder="TOTAL M.E." name="total_me" disabled required>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
  </form>
</div>
