import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigurationService, CostosService, ExportExcelService } from 'src/app/services';
import { reporteAnalisisConsistenciaManoObraIndirecta, reporteAnalisisDistribucionGastosFabrica, reporteAnalisisDistribucionManoObraDirecta, reporteAnalisisDistribucionManoObraDirectaAuxiliar, reporteDetalladoCostosOrdenFabricacion, reporteEstructuraCostosOrdenProduccion, reporteSaldosContablesPorClientesGastosVentas, reporteSaldosContablesSegunTipoCostoGastosFabrica } from '@utils/pdfmaker/builder/costos.pdfmaker';
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from 'src/app/utils/formats/date.format';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { REPORTES_COSTOS } from '@data/json/reportes';
import { HeaderReport } from '@data/interfaces/headers.report';
import { CostosReportService } from 'src/app/services/reports/modules/costos-report.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-costos-report',
  templateUrl: './costos-report.component.html',
  styleUrls: ['./costos-report.component.css']
})
export class CostosReportComponent implements OnInit, OnDestroy {

  dataForExcel = [];

  dataDetalleFacturacion = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  reportes: any[] = REPORTES_COSTOS;

  reporteSeleccionado: any = ['COSTOS_DCOF'];

  loading: boolean = false;

  period$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _costosService: CostosService,
    private _configurationService: ConfigurationService,
    private _costosReportService: CostosReportService,
    public ete: ExportExcelService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

  }

  ngOnInit(): void {
  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;

    let indicador = "";

    switch (this.reporteSeleccionado[0]) {
      case "COSTOS_DCOF":
        indicador = "51002";
        break;
      case "COSTOS_ECOP":
        indicador = "51002";
        break;
      case "COSTOS_ADMOD":
        indicador = "51001";
        break;
      case "COSTOS_ACMOI":
        indicador = "51004";
        break;
      case "COSTOS_ADMODA":
        indicador = "51001";
        break;
      case "COSTOS_SCSTCGF":
        indicador = "51001";
        break
      case "COSTOS_ADGF":
        indicador = "51003";
        break
      case "COSTOS_SCCGV":
        indicador = "51004";
        break
      default:
        break;
    }

  }

  verReporte(): void {
    this.loading = true;
    const headers: HeaderReport = {
      year: this.year,
      month: this.month,
      clientName: this._configurationService.obtenerNombreCompaniaCliente(),
      client: this._configurationService.obtenerClienteId(),
      date: formatDateWithSlash(new Date()),
      time: getHourMinuteSecondsCurrent(new Date()),
      currency: '',
      fecini: '',
      fecter: '',
      username: this._configurationService.obtenerNombreCompaniaCliente(),
      codePage: this._configurationService.obtenerCodigoPagina()
    }

    this._costosReportService.verReporte(this.reporteSeleccionado[0], headers, null,null)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)

    switch (this.reporteSeleccionado[0]) {
      case "COSTOS_DCOF":
        this.loading = true;
        this._costosService.obtenerReporteDetalladoCostosOrdenFabricacion(this.year, this.month).subscribe(
          response => {
            if(response.length > 0) {
              reporteDetalladoCostosOrdenFabricacion(headers, response)
              this.loading = false;
              return;
            }
            this._snackBarService.showError(`No se encontró información para este período ${this.year} - ${this.month}`, "OK");
            this.loading = false;
          },
          error => {
            this._snackBarService.showError("Error al obtener reporte detallado costos orden de fabricación", "OK");
            this.loading = false;
          }
        )
        break;
      case "COSTOS_ECOP":
        reporteEstructuraCostosOrdenProduccion(headers, null);
        break;
      case "COSTOS_ADMOD":
        this.loading = true;
        this._costosService.obtenerReporteAnalisisDistribucionManoObraDirecta(this.year, this.month).subscribe(
          response => {
            if(response.length > 0) {
              reporteAnalisisDistribucionManoObraDirecta(headers, response)
              this.loading = false;
              return;
            }
            this._snackBarService.showError(`No se encontró información para este período ${this.year} - ${this.month}`, "OK");
            this.loading = false;
          },
          error => {
            this._snackBarService.showError("Error al obtener reporte de análisis distribución de mano de obra directa", "OK");
            this.loading = false;
          }
        )
        break;
      case "COSTOS_ACMOI":
        this.loading = false;
        this._costosService.obtenerReporteAnalisisConsistenciaManoObraIndirecta(this.year, this.month).subscribe(
          response => {
            if(response.length > 0) {
              reporteAnalisisConsistenciaManoObraIndirecta(headers, response)
              this.loading = false;
              return;
            }
            this._snackBarService.showError(`No se encontró información para este período ${this.year} - ${this.month}`, "OK");
            this.loading = false;
          },
          error => {
            this._snackBarService.showError("Error al obtener reporte de analisis consistencia mano de obra indirecta", "OK");
            this.loading = false;
          }
        )
        break;
      case "COSTOS_ADMODA":
        this.loading = true;
        this._costosService.obtenerReporteAnalisisDistribucionManoObraDirectaAuxiliar(this.year, this.month).subscribe(
          response => {
            if(response.length > 0) {
              reporteAnalisisDistribucionManoObraDirectaAuxiliar(headers, response)
              this.loading = false;
              return;
            }
            this._snackBarService.showError(`No se encontró información para este período ${this.year} - ${this.month}`, "OK");
            this.loading = false;
          },
          error => {
            this._snackBarService.showError("Error al obtener reporte de analisis distribucion mano obra directa auxiliar", "OK");
            this.loading = false;
          }
        )
        break;
      case "COSTOS_SCSTCGF":
        this.loading = true;
        this._costosService.obtenerReporteSaldosContablesSegunTipoCostoGastosFabrica(this.year, this.month).subscribe(
          response => {
            if(response.length > 0) {
              reporteSaldosContablesSegunTipoCostoGastosFabrica(headers, response)
              this.loading = false;
              return;
            }
            this._snackBarService.showError(`No se encontró información para este período ${this.year} - ${this.month}`, "OK");
            this.loading = false;
          },
          error => {
            this._snackBarService.showError("Error al obtener reporte de saldos contables segun tipo costos gastos fabrica", "OK");
            this.loading = false;
          }
        )
        break;
      case "COSTOS_ADGF":
        this.loading = true;
        this._costosService.obtenerReporteAnalisisDistribucionGastosFabrica(this.year, this.month).subscribe(
          response => {
            if(response.length > 0) {
              reporteAnalisisDistribucionGastosFabrica(headers, response)
              this.loading = false;
              return;
            }
            this._snackBarService.showError(`No se encontró información para este período ${this.year} - ${this.month}`, "OK");
            this.loading = false;
          },
          error => {
            this._snackBarService.showError("Error al obtener reporte analisis deistribucion gastos fabrica", "OK")
            this.loading = false;
          }
        )
        break;
      case "COSTOS_SCCGV":
        this.loading = true;
        this._costosService.obtenerReporteSaldosContablesPorClientesGastosVentas(this.year, this.month).subscribe(
          response => {
            if(response.length > 0) {
              reporteSaldosContablesPorClientesGastosVentas(headers, response)
              this.loading = false;
              return;
            }
            this._snackBarService.showError(`No se encontró información para este período ${this.year} - ${this.month}`, "OK");
            this.loading = false;
          },
          error => {
            this._snackBarService.showError("Error al obtener reporte saldos contables clientes gastos ventas", "OK");
            this.loading = false;
          }
        )
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$])
  }
}
