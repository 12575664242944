export class TipoAtencion {
  idtas: string;
  descri: string;
  fecupd: Date;
  indsta: string;
  coduse: string;

  constructor() {
    this.fecupd = new Date();
    this.indsta = '1';
  }
}
