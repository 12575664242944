<app-pavso-title-section title="Movimientos" [module]="'Almacén'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <div class="pav-table-search-container">
    <div>
      <mat-form-field class="tipo-movimiento">
        <mat-label>Tipo de movimiento</mat-label>
        <mat-select [(ngModel)]="filtro.tipoMovimiento" name="tipoMovimiento">
          <mat-option value="ING">
            INGRESO
          </mat-option>
          <mat-option value="SAL">
            SALIDA
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    &nbsp;
    &nbsp;
    <div>
      <select-search [label]="'Almacén'" [placeholder]="'Ej. ALMACEN P.T.'" [name]="'ALM_CODALM'" [value]="'ALM_CODALM'"
        [description]="'ALM_DESCRI'" [data]="almacenes" (cambioSelect)="filtro.almacenes = $event"
        [model]="filtro.almacenes"></select-search>
    </div>
    &nbsp;
    &nbsp;
    <div>
      <select-search [label]="'Motivo'" [placeholder]="'Ej. Lima'" [name]="'mmo_codmmo'" [value]="'mmo_codmmo'"
        [description]="'mmo_descri'" [data]="motivos" (cambioSelect)="filtro.motivos = $event"
        [model]="filtro.motivos"></select-search>
    </div>
    &nbsp;
    &nbsp;
    <div>
      <select-search [label]="'Auxiliar'" [placeholder]="'Ej. PEREZ'" [name]="'cli_codcli'" [value]="'cli_codcli'"
        [description]="'cli_nomcli'" [data]="auxiliares" (cambioSelect)="filtro.auxiliares = $event"
        [model]="filtro.auxiliares"></select-search>
    </div>
    &nbsp;
    &nbsp;
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div [ngStyle]="{marginTop: '-12px'}">
        <button (click)="obtenerMovimientos()" type="button" mat-mini-fab color="primary" [disabled]="loaderData"
          aria-label="Example icon button with a menu icon">
          <mat-icon>search</mat-icon>
        </button>
      </div>


      <div class="isMobile">
        <div class="pav-table-search">
          <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
        </div>
      </div>

    </div>
    <div class="pav-separator"></div>



  </div>

  <div class="mat-elevation-z0">

    <div class="pavso_card_more_options">
      <div class="pav-table-header-icon"></div>
      <p class="pav-table-header-title">
        {{title}}
      </p>
      <div class="isWeb">
        <div class="pav-table-search">
          <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
        </div>
      </div>
      &nbsp;&nbsp;&nbsp;
      <div class="isMobile">
        <div class="pav-button-icon-download-excel">
          <mat-icon svgIcon="download"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <button class="pav-button-download-excel" mat-flat-button color="primary">
          <mat-icon>file_download</mat-icon>
          Descargar
        </button>
      </div>
      <div class="isMobile">
        <div class="pav-button-icon-add"
          [routerLink]="['/modulo-almacen/kardex', '0','0','0',filtro.tipoMovimiento,'0']">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <a>
          <button class="pav-button-add" mat-flat-button color="primary"
            [routerLink]="['/modulo-almacen/kardex', '0','0','0',filtro.tipoMovimiento,'0']">
            <mat-icon>add</mat-icon>
            Agregar
          </button>
        </a>
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

            <div class="pav-btns-container">
              <div class="pav-btn-circle-edit" matTooltip="Editar"
                (click)="editarMovimiento(row.ano_codano, row.mes_codmes, row.alm_codalm, row.tdo_codtdo, row.mac_nummac)"
                mat-icon-button color="primary">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
              <div class="pav-btn-circle-pdf" matTooltip="Ver PDF"
                (click)="verPdf(row.ano_codano, row.mes_codmes, row.alm_codalm, row.tdo_codtdo, row.mac_nummac)"
                mat-icon-button color="warn">
                <mat-icon svgIcon="pdf"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="ALM_DESCRI">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte"> {{row.ALM_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="mac_nummac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Parte </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte"> {{row.mac_nummac}} </td>
        </ng-container>

        <ng-container matColumnDef="mac_fecmac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte">
            <div [ngStyle]="{width: '85px'}">
              {{row.mac_fecmac}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="mmo_codmmo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte"> {{row.mmo_codmmo}} </td>
        </ng-container>

        <ng-container matColumnDef="mmo_descri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción Motivo </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte"> {{row.mmo_descri}} </td>
        </ng-container>

        <ng-container matColumnDef="cli_nomcli">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
          <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.cliente.cli_nomcli}} </td>
        </ng-container>

        <ng-container matColumnDef="mac_indsta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let row" data-label="Fecha">
            <!-- {{row.mac_indsta == '0' ? 'Anulado': 'Vigente' }} -->
            <div class="pav-title-vigente" *ngIf="row.mac_indsta == '1' || row.mac_indsta == 1">Vigente</div>
            <div class="pav-title-anulado" *ngIf="row.mac_indsta == '0' || row.mac_indsta == 0">Anulado</div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No se encontraron registros "{{input.value}}"</td>

        </tr>

      </table>
    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
