import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_CLIENTE } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";
import { ApiCRMMaestrosClienteService } from "src/app/services/api/crm/maestros/crm.maestros.cliente.service";

@Component({
  selector: 'cliente-crm-list',
  templateUrl: './cliente-crm-list.component.html',
  styleUrls: ['./cliente-crm-list.component.scss']
})
export class ClienteCrmListComponent implements OnInit, OnDestroy {

  clientes$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_CLIENTE;

  clientes: Array<any> = [];

  constructor(
    private _apiCrmClienteService: ApiCRMMaestrosClienteService,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerClientes();
      }
    })
  }

  obtenerClientes(): void {
    this.clientes$ = this._ventasService.obtenerClientes().subscribe(
      clientes => {
        console.log('clientes', clientes)
        this.clientes = clientes
      },
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.clientes$
    ])
  }

}
