import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { AlmacenService, ContabilidadService, GeneralService } from 'src/app/services';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';

class FiltroConsultaMovimientoContable {
  mes: string;
  moneda: string;
  auxiliar: string;
  tipoDocumento: string;
  centroCosto: string;
  nroDocumento: string;
  cuenta: string;
  subdiario: string;
}

@Component({
  selector: 'app-consultas-movimientos-contables',
  templateUrl: './consultas-movimientos-contables.dialog.html',
  styleUrls: ['./consultas-movimientos-contables.dialog.css']
})
export class ConsultasMovimientosContablesDialog implements OnInit, OnDestroy {

  selected: any;

  meses: any[] = [{ id: "01", name: "Enero" }, { id: "02", name: "Febrero" }, { id: "03", name: "Marzo" }, { id: "04", name: "Abril" }, { id: "05", name: "Mayo" }, { id: "06", name: "Junio" }, { id: "07", name: "Julio" }, { id: "08", name: "Agosto" }, { id: "09", name: "Setiembre" }, { id: "10", name: "Octubre" }, { id: "11", name: "Noviembre" }, { id: "12", name: "Diciembre" }];

  displayedColumns: string[] = ['mes', 'subdiario', 'ldi_numdoc', 'cli_codcli', 'cli_nomcli', 'ccn_codccn', 'debe', 'haber', 'cco_fecreg_103', 'cco_fecemi_103', 'ccs_codccs', 'ldi_gloldi'];
  dataSource: MatTableDataSource<any>;
  totalDataStandar: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderStandar: boolean = false;
  loaderBancos: boolean = false;
  loaderCompras: boolean = false;
  loaderVentas: boolean = false;
  loaderHonorarios: boolean = false;
  loaderData: boolean = false;
  loaderReg: boolean = false;

  filtro: FiltroConsultaMovimientoContable = new FiltroConsultaMovimientoContable();

  comprobante: string = "estandar";
  subdiario: string = "0301";
  cuentaBancaria: string;
  auxiliar: string;
  tipoDoc: string;
  cuenta: string;

  selectedIndex: number = 0;


  subdiarios: any[] = [];
  cuentas: any[] = [];
  cuentasContable: any[] = [];
  auxiliares: any[] = [];
  bancos: any[] = [];
  tiposDocumento: any[] = [];
  cuentasBancaria: any[] = [];
  centrosCosto: any[] = [];

  subdiario$: Subscription;
  centrosCosto$: Subscription;
  cuenta$: Subscription;
  cuentaContable$: Subscription;
  auxiliar$: Subscription;
  banco$: Subscription;
  tiposDoc$: Subscription;
  cuentaBancaria$: Subscription;

  anioPeriodo: string;
  mesPeriodo: string;

  selectedRowIndex = -1;

  period$: Subscription;
  loading$: Subscription;

  totalDebe: number = 0;
  totalHaber: number = 0;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _clienteService: ClienteService,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private store: Store<PavsoState>,
    private _router: Router,
    public dialogRef: MatDialogRef<ConsultasMovimientosContablesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.period$ = this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    });

    this.dataSource = fillTable([], this.paginator, this.sort);


  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadMaestros();
    })

    this.filtro.cuenta = this.data.cuenta;
    this.filtro.mes = this.data.codmes;
    this.filtro.moneda = this.data.moneda;

  }

  /**
   * Carga del listado de maestros
   */
  loadMaestros(): void {
    this.loaderData = true;

    this.subdiario$ = this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => {
        console.log('subdiarios', subdiarios)
        this.subdiarios = subdiarios;

        this.banco$ = this._generalService.listarBancos().subscribe(
          bancos => {
            this.bancos = bancos;

            this.cuentaContable$ = this._contabilidadService.obtenerCuentasContable().subscribe(
              cuentasContable => {
                this.cuentasContable = cuentasContable;

                this.auxiliar$ = this._clienteService.obtenerClientes().subscribe(
                  auxiliares => {
                    this.auxiliares = auxiliares;
                    console.log('this.auxiliares', this.auxiliares)

                    this.tiposDoc$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
                      tiposDocumento => {
                        this.tiposDocumento = tiposDocumento;

                        this.obtenerMovimientosCuenta();


                      },
                      error => {
                        this._snackBarService.showError("Error al obtener tipos de documento", "OK");
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError("Error al obtener auxiliares", "OK");
                    this.loaderData = false;
                  }
                )

              },
              error => {
                this._snackBarService.showError("Error al obtener bancos", "OK");
                this.loaderData = false;
              }
            )
          },
          _ => {
            this._snackBarService.showError("Error al obtener bancos", "OK");
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError("Error al obtener subdiarios", "OK");
        this.loaderData = false;
      }
    );

    // this.cuenta$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
    //   cuentas => {
    //     this.cuentas = cuentas;

    //   },
    //   error => {
    //     this._snackBarService.showError("Error al obtener cuentas", "OK");
    //     this.loaderData = false;
    //   }
    // )
  }

  filtrarPorAuxiliar(data: any[]): any[] {

    const filtrados = data.filter(item => item.CLI_CODCLI.trim() == this.filtro.auxiliar.trim());
    return filtrados;

  }

  filtrarPorSubdiario(data: any[]): any[] {

    const filtrados = data.filter(item => item.SCO_CODSCO.trim() == this.filtro.subdiario.trim());
    return filtrados;

  }

  filtrarPorCentroCosto(data: any[]): any[] {

    const filtrados = data.filter(item => item.CCS_CODCCS.trim() == this.filtro.centroCosto.trim());
    return filtrados;

  }

  filtrarPorTipoDoc(data: any[]): any[] {

    const filtrados = data.filter(item => item.TDO_CODTDO.trim() == this.filtro.tipoDocumento.trim());
    return filtrados;

  }

  filtrarPorNroDoc(data: any[]): any[] {

    const filtrados = data.filter(item => item.LDI_DOCREF.trim().includes(this.filtro.nroDocumento))
    return filtrados;

  }

  obtenerMovimientosCuenta(): void {
    this.loaderData = true;
    this._contabilidadService.obtenerReporteMayorContable(this.anioPeriodo, this.filtro.mes).subscribe(
      data => {

        const cuenta = data.find(item => item.cuenta.trim() == this.filtro.cuenta);

        const filtrados = cuenta.detalle.filter(item => item.MES_CODMES == this.filtro.mes)

        // Filtrar por auxiliar
        let filtradosAuxiliar = [];
        if (this.filtro.auxiliar) {
          filtradosAuxiliar = this.filtrarPorAuxiliar(filtrados);
        } else {
          filtradosAuxiliar = filtrados;
        }

        // Filtrar por subdiario
        let filtradosSubdiario = [];
        if (this.filtro.subdiario) {
          filtradosSubdiario = this.filtrarPorSubdiario(filtradosAuxiliar);
        } else {
          filtradosSubdiario = filtradosAuxiliar;
        }

        // Filtrar por centro de costo
        let filtradosCentroCosto = [];
        if (this.filtro.centroCosto) {
          filtradosCentroCosto = this.filtrarPorCentroCosto(filtradosSubdiario);
        } else {
          filtradosCentroCosto = filtradosSubdiario;
        }

        // Filtrar por tipo de documento
        let filtradosTipoDocumento = [];
        if (this.filtro.tipoDocumento) {
          filtradosTipoDocumento = this.filtrarPorTipoDoc(filtradosCentroCosto);
        } else {
          filtradosTipoDocumento = filtradosCentroCosto;
        }

        // Filtrar por número de documento
        let filtradosNroDocumento = [];
        if (this.filtro.nroDocumento) {
          filtradosNroDocumento = this.filtrarPorNroDoc(filtradosTipoDocumento);
        } else {
          filtradosNroDocumento = filtradosTipoDocumento;
        }

        this.dataSource = fillTable(filtradosNroDocumento, this.paginator, this.sort);

        this.calcularTotalDH();

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError("Error al obtener cuentas", "OK");
        this.loaderData = false;
      }
    )
  }

  calcularTotalDH(): void {

    this.totalDebe = 0;
    this.totalHaber = 0;

    const data = this.dataSource.data;

    data.forEach(item => {
      if(this.filtro.moneda == 'SO') {
        if(item.LDI_INDDHA == 'D') {
          this.totalDebe += item.LDI_IMPNAC;
        } else {
          this.totalHaber += item.LDI_IMPNAC;

        }
      } else {
        if(item.LDI_INDDHA == 'D') {
          this.totalDebe += item.LDI_IMPMEX;
        } else {
          this.totalHaber += item.LDI_IMPMEX;

        }
      }
    })

  }

  /**
   * Seleccionar Comprobante
   * @param valor valor a filtrar
   */
  seleccionarComprobante(valor): void {
    switch (valor) {
      case "estandar":
        this.selectedIndex = 0;
        break;
      case "bancos":
        this.selectedIndex = 1;
        break;
      case "compras":
        this.selectedIndex = 2;
        break;
      case "ventas":
        this.selectedIndex = 3;
        break;
      case "honorarios":
        this.selectedIndex = 4;
        break;

      default:
        break;
    }
  }


  verConsultaMovimientoCtaCte(): void {
    if (this.selectedRowIndex != -1) {
      this._router.navigate(['/modulo-contabilidad/movimientos-cta-cte', this.selectedRowIndex])
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.subdiario$,
      this.cuenta$,
      this.cuentaContable$,
      this.auxiliar$,
      this.banco$,
      this.tiposDoc$,
      this.cuentaBancaria$,
      this.period$
    ])

  }
}
