import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class PromocionVentaService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerPromocionesVenta(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/promociones/listar/${this._configurationService.obtenerCompaniaCliente()}`,
    );
  }

  obtenerPromocionVenta(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/promociones/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`,
    );
  }

  registrarPromocionVenta(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/promociones/crear`,
      payload
    );
  }

  editarPromocionVenta(payload): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/promociones/modificar`,
      payload
    );
  }
}
