export class PropietarioActivo {
  cia_codcia: string;
  pac_codpac: string;
  pac_deslar: string;
  pac_descor: string;
  pac_indsta: string;
  pac_fecupd: Date;
  pac_coduse: string;

  constructor() {
    this.pac_indsta = '1';
    this.pac_fecupd = new Date();
  }
}
