<h1 mat-dialog-title class="pav-title-dialog">Ver Descuentos</h1>
<div mat-dialog-content>
  <br>
  <div class="row">
    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>Cantidad</mat-label>
        <input matInput placeholder="Cantidad" (keyup)="cambioDeCantidad()" [(ngModel)]="data.PCD_CANSOL"
          name="PCD_CANSOL" required>
      </mat-form-field>
    </div>

    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>Precio</mat-label>
        <input matInput placeholder="Precio" (keyup)="cambioDeParametroPrecio()" [(ngModel)]="data.PCD_PREUNI"
          name="PCD_PREUNI" required>
      </mat-form-field>
    </div>

    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>Precio Inc. IGV.</mat-label>
        <input matInput placeholder="Precio Inc. IGV." (keyup)="cambioDeParametroPrecioIgv()"
          [(ngModel)]="data.PCD_PRUIGV" name="PCD_PRUIGV" required>
      </mat-form-field>
    </div>

    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>Imp. Bruto</mat-label>
        <input matInput placeholder="Imp. Bruto" [(ngModel)]="data.PCD_IMPBRU" name="PCD_IMPBRU" required disabled>
      </mat-form-field>
    </div>

    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>(%) Descuento</mat-label>
        <input matInput placeholder="(%) Descuento" [(ngModel)]="data.PCD_PORDES" name="PCD_PORDES" required disabled>
      </mat-form-field>
    </div>

    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>Imp. Descuento</mat-label>
        <input matInput placeholder="Imp. Descuento" [(ngModel)]="data.PCD_IMPDES" name="PCD_IMPDES" required disabled>
      </mat-form-field>
    </div>

    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>Valor venta</mat-label>
        <input matInput placeholder="Valor venta" [(ngModel)]="data.PCD_VALVTA" name="PCD_VALVTA" required disabled>
      </mat-form-field>
    </div>

    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>Imp. IGV</mat-label>
        <input matInput placeholder="Imp. IGV" [(ngModel)]="data.PCD_IMPIGV" name="PCD_IMPIGV" required disabled>
      </mat-form-field>
    </div>

    <div class="col s12 m4">
      <mat-form-field class="example-full-width">
        <mat-label>Imp. Total</mat-label>
        <input matInput placeholder="Imp. Total" [(ngModel)]="data.PCD_IMPTOT" name="PCD_IMPTOT" required disabled>
      </mat-form-field>
    </div>

  </div>
  <form #f="ngForm" ngNativeValidate (ngSubmit)="agregarDescuento(f)" (keydown.enter)="$event.preventDefault()">

    <fieldset>
      <legend>Descuento del producto</legend>
      <div class="row">
        <div class="col s12 m12 l5">
          <mat-form-field>
            <mat-label>Tipo de descuento</mat-label>
            <mat-select name="descuento" [(ngModel)]="descuento.dco_coddco" required>
              <mat-option *ngFor="let descuento of descuentos" [value]="descuento.dco_coddco">
                {{descuento.dco_desdco}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l5">
          <mat-form-field class="pavso_input_search">
            <mat-label>Descuento (%)</mat-label>
            <input type="number" matInput placeholder="Descuento (%)" name="descuento" [(ngModel)]="descuento.pds_pordes"
              required min="1">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <button mat-stroked-button color="primary" type="submit">Agregar</button>
        </div>
      </div>
    </fieldset>

    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ITEM </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="ITEM"> {{i + 1}} </td>
        </ng-container>

        <ng-container matColumnDef="cod_desc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> COD. DESC. </th>
          <td mat-cell *matCellDef="let row" data-label="COD. DESC."> {{row.dco_coddco}} </td>
        </ng-container>

        <ng-container matColumnDef="desc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> DESC. </th>
          <td mat-cell *matCellDef="let row" data-label="DESCUENTO"> {{(row.dco_coddco == '0001')? "DESCUENTO ESPECIAL" :
            "DESCUENTO POR REGALO"}} </td>
        </ng-container>

        <ng-container matColumnDef="porc_desc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> % DESC </th>
          <td mat-cell *matCellDef="let row" data-label="% DESC"> {{row.pds_pordes}} </td>
        </ng-container>

        <ng-container matColumnDef="importe_desc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTE DESC </th>
          <td mat-cell *matCellDef="let row" data-label="IMPORTE DESC"> {{row.pds_impdes.toFixed(2)}} </td>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ACCIONES </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones">

            <div class="pav-btns-container">

              <div matTooltip="Eliminar descuento" class="pav-btn-circle-delete" (click)="eliminarDescuento(i)">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>

          <td class="mat-cell">No se encontraron registros</td>

        </tr>
      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </form>

  <br>
</div>

<div mat-dialog-actions align="end">
  <button mat-flat-button class="pav-button-action" (click)="confirmar()" color="primary">Confirmar</button>
</div>
