<h1 mat-dialog-title>Cargar desde Excel</h1>
<div mat-dialog-content>
  <input type="file" class="file-input"
       [accept]="requiredFileType"
       (change)="onFileSelected($event)" #fileUpload>

  <div class="file-upload">

    {{fileName || "Aún no se ha subido ningún archivo."}}

      <button mat-mini-fab color="primary" class="upload-btn"
        (click)="fileUpload.click()">
          <mat-icon>attach_file</mat-icon>

      </button>

  </div>

  <div class="progress">

    <mat-progress-bar class="progress-bar" mode="determinate"
                      [value]="uploadProgress" *ngIf="uploadProgress">

    </mat-progress-bar>

    <mat-icon class="cancel-upload" (click)="cancelUpload()"
              *ngIf="uploadProgress">delete_forever</mat-icon>

  </div>
</div>
<div mat-dialog-actions>
  <button class="pav-button-cancel" mat-flat-button (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
  <button class="pav-button-action" *ngIf="fileName" color="primary" mat-flat-button (click)="subirExcel()">Subir</button>
  <button class="pav-button-action" mat-flat-button color="primary" (click)="verModelo()">Ver Modelo</button>
</div>
