export class Etapa {
  codcia  : string;
  codoet  : string;
  descri  : string;
  indsta  : string;
  fecupd  : Date;
  coduse  : string;
  ordoet  : number;

  constructor() {
    this.fecupd = new Date();
  }
}
