import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Campania } from "src/app/models/crm/campania";
import { ConfigurationService } from "src/app/services";
import { CampaniaService } from "src/app/services/api/crm/maestros/campania.service";
import { SituacionService } from "src/app/services/api/crm/maestros/situacion.service";
import { TipoCampaniaService } from "src/app/services/api/crm/maestros/tipo-campania.service";

@Component({
  selector: 'campania-form',
  templateUrl: './campania-form.component.html',
  styleUrls: ['./campania-form.component.scss']
})
export class CampaniaFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  campania: Campania;

  loading$: Subscription;
  sendForm$: Subscription;
  campania$: Subscription;
  tiposCampania$: Subscription;
  situaciones$: Subscription;

  tiposCampania: any[] = [];
  situaciones: any[] = [];

  constructor(
    private store: Store<PavsoState>,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private _tipoCampaniaService: TipoCampaniaService,
    private _campaniaService: CampaniaService,
    private _situacionService: SituacionService,
    private _dialogService: DialogService,
  ) {

    this.campania = new Campania();
    this.campania.codcia = this._configurationService.obtenerCompaniaCliente();
    this.campania.coduse = this._configurationService.obtenerIdUsuario();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {

      this.uid = id;

      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      this.loadMaestro();

    })
  }

  /**
   * Método para obtener los maestro de:
   * . Tipos de campaña
   * . Situaciones
   */
  loadMaestro(): void {
    forkObs(
      this._tipoCampaniaService.obtenerTiposCampania(),
      this._situacionService.obtenerSituacionesOportunidad()
    ).then(data => {

      this.tiposCampania = data[0];
      this.situaciones = data[1];

      if(this.uid != '0') this.obtenerCampania()
    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", 'Ok')
    })

  }

  obtenerCampania(): void {
    this.campania$ = this._campaniaService.obtenerCampania(this.uid).subscribe(
      campania => {
        this.campania = campania[0];
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
      },
    )
  }

  trackByTipoCampania(index, tipo): string {
    return tipo? tipo.fads: undefined;
  }

  trackBySituacion(index, situacion): string {
    return situacion? situacion.idstk: undefined;
  }

  establecerIndicadores(): void {
    this.campania.indaut = this.campania.indaut? 1: 0;
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.establecerIndicadores();

    this.loaderReg = true;

    this.uid == '0' ? this.registrarCampania(f) : this.actualizarCampania(f);

  }

  /**
   * Método para registrar una nueva campaña
   * @param f
   */
  registrarCampania(f: NgForm): void {
    this.campania.coduse = this._configurationService.obtenerIdUsuario();
    this.sendForm$ = this._campaniaService.registrarCampania(this.campania).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.campania.success-store', '400px', 'auto', '')
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar los datos de una campaña existente
   * @param f
   */
  actualizarCampania(f: NgForm): void {
    this.sendForm$ = this._campaniaService.actualizarCampania(this.campania).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.campania.success-update', '400px', 'auto', '')
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {
    if(!this.campania.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    if(!this.campania.codcoc) {
      this._snackBarService.showError('Seleccionar el tipo de campaña', 'Ok');
      return false;
    }

    return true;
  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/campanias']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.campania$,
      this.tiposCampania$,
      this.situaciones$
    ])

  }
}
