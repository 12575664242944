import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FirmaDigital } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ApiNominaMaestrosFirmaDigitalService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarFirmasDigitales(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-firmas-digitales/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param firma
   * @returns
   */
  registrarFirmaDigital(firma: FirmaDigital): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/firma-digital/${this._configurationService.obtenerCompaniaCliente()}`,
      firma
    );
  }

  /**
   *
   * @param firma
   * @returns
   */
  actualizarFirmaDigital(firma: FirmaDigital): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/firma-digital/${this._configurationService.obtenerCompaniaCliente()}`,
      firma
    );
  }
}
