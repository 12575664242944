import { createReducer, on } from "@ngrx/store";
import { EstablishmentState } from "@data/interfaces/state/establishment.state";
import { cambiarEstablecimiento, resetEstablecimiento } from "../actions/establishment.actions";
import { Tienda } from "src/app/models/ventas";

export const initialState: EstablishmentState = { code: localStorage.getItem('store')? localStorage.getItem('store'): '', establecimientos: [], establecimiento: '', establecimientoObj: new Tienda() };

const _establecimientoReducer = createReducer(
  initialState,
  on(cambiarEstablecimiento, (_, {code, establecimientos, establecimiento, establecimientoObj}) => ({code, establecimientos, establecimiento, establecimientoObj})),
  on(resetEstablecimiento, (_, ) => ({code: '', establecimientos: [], establecimiento: '', establecimientoObj: new Tienda()})),
);

export function establecimientoReducer(state, action) {
  return _establecimientoReducer(state, action)
}
