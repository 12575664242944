import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_NOMINA_LUGAR_TRABAJO } from '@data/constants/table/theader.nomina';
import { ApiNominaMaestrosLugarTrabajoService } from 'src/app/services/api/nomina/maestros/nomina.maestros.lugar-trabajo.service';

@Component({
  selector: 'app-lugar-trabajo-list-p',
  templateUrl: './lugar-trabajo-list-p.component.html',
  styleUrls: ['./lugar-trabajo-list-p.component.css']
})
export class LugarTrabajoListPComponent implements OnInit, OnDestroy {

  lugaresTrabajo: any[] = [];
  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_LUGAR_TRABAJO;

  lugar$: Subscription;
  loading$: Subscription;

  constructor(
    private _apiLugarTrabajoNominaService: ApiNominaMaestrosLugarTrabajoService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this.lugar$ = this._apiLugarTrabajoNominaService.listarLugaresDeTrabajo().subscribe(
      response => this.lugaresTrabajo = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.lugar$, this.loading$]);
  }
}
