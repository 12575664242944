import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {
  private inputBuffer: string = '';
  private lastInputTime: number = 0;
  private readonly inputTimeout: number = 50; // Ajusta este valor según la velocidad de tu pistola
  public barcodeData$ = new Subject<string>();

  constructor(private zone: NgZone) {
    this.initialize();
  }

  private initialize() {
    document.addEventListener('keydown', (event: KeyboardEvent) => this.handleKeydown(event));
  }

  private handleKeydown(event: KeyboardEvent) {
    const currentTime = new Date().getTime();

    if (currentTime - this.lastInputTime > this.inputTimeout) {
      this.inputBuffer = '';
    }

    this.inputBuffer += event.key;
    this.lastInputTime = currentTime;

    if (event.key === 'Enter') {
      this.zone.run(() => {
        this.barcodeData$.next(this.inputBuffer.trim());
        this.inputBuffer = '';
      });
    }
  }
}
