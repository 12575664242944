import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { LineaProduccionService } from 'src/app/services/api/produccion/maestros/linea-produccion.service';
import { LineaOperacionService } from 'src/app/services/api/produccion/maestros/linea-operacion.service';


@Component({
  selector: 'app-linea-prod-operario-list',
  templateUrl: './linea-prod-operario-list.component.html',
  styleUrls: ['./linea-prod-operario-list.component.css']
})
export class LineaProdOperarioListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'lpd_codlpd', 'cli_nomcli','lpd_descri'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  driver$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _lineaOperacionService: LineaOperacionService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerLineasProduccion();
    })
  }

  obtenerLineasProduccion(): void {
    this.loaderData = true;
    this.driver$ = this._lineaOperacionService.obtenerLineasOperacion().subscribe(
      response => {
        this.loaderData = false;

        console.log("linea after 21212",response)
        this.dataSource = fillTable(response, this.paginator, this.sort)
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.driver$,this.loading$
    ])
  }

}
