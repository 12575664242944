import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithDash } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { FiltroMovimientos } from "src/app/models/almacen/filtro-movimientos";
import { ConfigurationService } from "src/app/services";
import { ConsolidadoPartesDTO } from "src/app/services/api/almacen/operaciones/dto/consolidado-partes.dto";
import { KardexService } from "src/app/services/api/almacen/operaciones/kardex.service";

class CopyPartsFields {
  periodo: string;
  tipoDoc: string;
  almacen: string;
  auxiliar: string;
  centroCosto: string;
  motivo: string;
  tipoDocRef: string;
  numDocRef: string;
  fecha: Date;
  partes: string[];

  constructor() {
    this.tipoDoc = 'ING';
    this.partes = [];

    this.fecha = new Date();
  }
}

@Component({
  selector: 'copy-parts-dialog',
  templateUrl: 'copy-parts.dialog.html',
  styleUrls: ['copy-parts.dialog.scss']
})
export class CopyPartsDialog implements OnInit, OnDestroy {

  period$: Subscription;
  periodos: any[] = [];
  partes: any[] = [];

  fields: CopyPartsFields;

  loaderData: boolean = false;
  loader: boolean = false;

  partes$: Subscription;
  consolidado$: Subscription;

  filtro: FiltroMovimientos;

  constructor(
    public dialogRef: MatDialogRef<CopyPartsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly store: Store<PavsoState>,
    private readonly _kardexService: KardexService,
    private readonly _snackBarService: SnackBarService,
    private readonly _configurationService: ConfigurationService
  ) {
    this.fields = new CopyPartsFields();
    this.filtro = new FiltroMovimientos();
    this.filtro.tipoMovimiento = this.fields.tipoDoc;
    console.log('data', data)
  }

  ngOnInit(): void {
    this.period$ = this.store.select('period').subscribe(period => {
      console.log('period.periodos', period.periodos)
      this.periodos = period.periodos;
      this.filtro.anioPeriodo = period.year;
      this.filtro.mesPeriodo = period.month;

    })

    const periodos = [];

    this.periodos.forEach(item => {
      periodos.push({
        ANO_CODANO: item.ANO_CODANO,
        MES_CODMES: item.MES_CODMES,
        description: item.ANO_CODANO + ' - ' + item.MES_CODMES
      })
    })

    this.periodos = periodos;
    const periodo = this.periodos.find(item => item.ANO_CODANO == this.filtro.anioPeriodo && item.MES_CODMES == this.filtro.mesPeriodo)
    this.fields.periodo = periodo.ANO_CODANO + ' - ' + periodo.MES_CODMES

    console.log('periodos', this.periodos)

    this.obtenerPartesAlmacen();
  }

  seleccionarPeriodo(): void {
    const parts = this.fields.periodo.split(' - ');
    this.filtro.anioPeriodo = parts[0];
    this.filtro.mesPeriodo = parts[1];
    this.obtenerPartesAlmacen();
  }

  seleccionarTipoDoc(): void {
    if (this.fields.tipoDoc) {
      this.filtro.tipoMovimiento = this.fields.tipoDoc;
      this.obtenerPartesAlmacen();
    }
  }

  seleccionarAlmacen(): void {
    this.filtro.almacenes = this.fields.almacen;
    this.obtenerPartesAlmacen();

  }

  obtenerPartesAlmacen(): void {
    this.loaderData = true;

    this.partes$ = this._kardexService.listarKardex(this.filtro.anioPeriodo, this.filtro.mesPeriodo, this.filtro).subscribe(
      movimientos => {
        console.log('partes encontrados', movimientos)
        this.partes = movimientos;
        this.partes.forEach(item => {
          item['description'] = `${item['mac_nummac']} | ${item['mac_fecmac']}`
        })

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al listar partes', 'Ok');
        this.loaderData = false;
      }
    )
  }

  enterReferencia01(): void {

    if (this.fields.numDocRef.length > 4) {
      const zeroPad = (num, places) => String(num).padStart(places, '0')

      let numero = zeroPad(this.fields.numDocRef.substring(5, this.fields.numDocRef.length + 1), 8);
      this.fields.numDocRef = this.fields.numDocRef.substring(0, 5) + numero;
    }
  }

  buscar(): void {
    this.filtro.motivos = this.fields.motivo;
    this.filtro.fecha = this.fields.fecha ? formatDateWithDash(this.fields.fecha) : null;
    this.filtro.cliente = this.fields.auxiliar;
    this.filtro.tipoDocRef01 = this.fields.tipoDocRef;
    this.filtro.referencia01 = this.fields.numDocRef;
    this.filtro.centroCosto = this.fields.centroCosto;

    this.partes$ = this._kardexService.listarKardex(this.filtro.anioPeriodo, this.filtro.mesPeriodo, this.filtro).subscribe(
      movimientos => {
        console.log('partes encontrados', movimientos)
        this.partes = movimientos;
      },
      error => {
        this._snackBarService.showError('Error al listar partes', 'Ok');
        this.loaderData = false;
      }
    )
  }

  copiarDetalle(): void {
    this.loader = true;

    if (!this.fields.almacen) {
      this._snackBarService.showError('Seleccionar un almacén', 'Ok');
      this.loader = false;
      return;
    }

    if(this.fields.partes.length == 0) {
      this._snackBarService.showError('Seleccionar partes', 'Ok');
      this.loader = false;
      return;
    }
    console.log('fields.partes', this.fields.partes);

    let consolidados: ConsolidadoPartesDTO[] = [];

    this.fields.partes.forEach(element => {
      const consolidado = new ConsolidadoPartesDTO();
      const [anio, mes] = this.fields.periodo.split(' - ');
      consolidado.cia_codcia = this._configurationService.obtenerCompaniaCliente();
      consolidado.ano_codano = anio;
      consolidado.mes_codmes = mes;
      consolidado.alm_codalm = this.fields.almacen;
      consolidado.tdo_codtdo = this.fields.tipoDoc;
      consolidado.mac_nummac = element;

      consolidados.push(consolidado);
    });

    console.log('consolidados', consolidados);

    this.consolidado$ = this._kardexService.consolidarPartes(consolidados).subscribe(
      response => {
        this.loader = false;
        console.log('response', response)
        this.dialogRef.close(response);
      },
      error => {
        this._snackBarService.showError('Error al consolidar partes', 'Ok');
        this.loader = false;
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.partes$,
      this.consolidado$
    ])
  }
}
