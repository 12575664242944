import { ConceptoDescuentoFormComponent, ConceptoDescuentoListComponent } from "../precio-descuento-promocion/concepto-descuento";
import { ModeloFormComponent, ModeloListComponent } from "./modelo";
import { TipoCambioFormComponent, TipoCambioListComponent } from "./tipo-cambio";
import { ClienteContactoFormDialog, ClienteEstablecimientoFormDialog } from "./cliente";
import { TipoListaPrecioFormComponent, TipoListaPrecioListComponent } from "../precio-descuento-promocion/tipo-lista-precio";
import { ListaPrecioFormComponent, ListaPrecioListComponent } from "../precio-descuento-promocion/lista-precio";
import { UnidadTransporteListComponent } from "./unidad-transporte/unidad-transporte-list/unidad-transporte-list.component";
import { UnidadTransporteFormComponent } from "./unidad-transporte/unidad-transporte-form/unidad-transporte-form.component";
import { EstablecimientoClienteFormComponent, EstablecimientoClienteListComponent } from "./establecimiento-cliente";
import { ContactoClienteFormComponent, ContactoClienteListComponent } from "./contacto-cliente";
import { CodificacionProductoClienteFormComponent } from "./codificacion-producto-cliente";
import { ClienteModule } from "./cliente/cliente.module";
import { ProductoModule } from "./producto/producto.module";
import { SupervisorModule } from "./supervisor/supervisor.module";
import { VendedorModule } from "./vendedor/vendedor.module";

export const maestrosComponentsVentas = [
  TipoCambioFormComponent,
  TipoCambioListComponent,
  ListaPrecioFormComponent,
  ListaPrecioListComponent,
  UnidadTransporteFormComponent,
  UnidadTransporteListComponent,
  ConceptoDescuentoFormComponent,
  ConceptoDescuentoListComponent,
  ModeloListComponent,
  ModeloFormComponent,
  TipoListaPrecioFormComponent,
  TipoListaPrecioListComponent,
  EstablecimientoClienteFormComponent,
  EstablecimientoClienteListComponent,
  ContactoClienteFormComponent,
  ContactoClienteListComponent,
  ClienteContactoFormDialog,
  ClienteEstablecimientoFormDialog,
  CodificacionProductoClienteFormComponent
]

export const maestrosVentasModules = [
  ClienteModule,
  ProductoModule,
  SupervisorModule,
  VendedorModule,
]
