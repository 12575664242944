export class Area {
  cia_codcia: string;
  apr_codapr: string;
  apr_desapr: string;
  apr_indsta: string;
  apr_coduse: string;
  apr_fecupd: Date;
  constructor(){
    this.apr_codapr = '';
    this.apr_indsta = '1';
    this.apr_fecupd = new Date();
  }
}
