import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class SupervisorService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarSupervisores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/supervisor/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerSupervisor(codvde): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/supervisor/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codvde}`
    );
  }

    /**
   *
   * @param supervisor
   * @returns
   */
    registrarSupervisor(supervisor): Observable<any> {
      return this.http.post<any>(
        `${this._configurationService.obtenerUrl()}/api/ventas/supervisor/crear`,
        supervisor
      );
    }

    /**
     *
     * @param supervisor
     * @returns
     */
    actualizarSupervisor(supervisor): Observable<any> {
      return this.http.post<any>(
        `${this._configurationService.obtenerUrl()}/api/ventas/supervisor/modificar`,
        supervisor
      );
    }
}
