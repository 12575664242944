export class Storehouse {
  CIA_CODCIA: string;
  ALM_CODALM: string;
  ALM_DESCRI: string;
  ALM_DESABR: string;
  TAL_CODTAL: string;
  ALM_INDVEN: string;
  ALM_INDTRA: number;
  SUC_CODSUC: string;
  ALM_INDSTA: string;
  ALM_FECUPD: Date;
  ALM_CODUSE: string;
  TIE_CODTIE: string;
  ALM_INDMOV: number;
  UTR_CODUTR: string;
  ALM_CODANT: string;
  ALM_INDWEB: number;
  ALM_IDEALM: number;
  ALM_INDSTK: number;
  ALM_ANEXO: string;
  ALM_INDVAL: string;
  ALM_MOVIL: number;
  TLA_CODTLA: any;
  LDC_CODLDC: any;
  ALM_INDNUE: any;
  constructor(
  ){
    this.ALM_INDSTA = "1";
    this.ALM_FECUPD = new Date()
  }
}
