import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Proceso } from 'src/app/models/produccion/proceso';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ProcesoService } from 'src/app/services/api/produccion/maestros/procesos.service';

@Component({
  selector: 'app-proceso-form',
  templateUrl: './proceso-form.component.html',
  styleUrls: ['./proceso-form.component.css']
})
export class ProcesoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = true;

  proceso: Proceso;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  sendForm$: Subscription;
  proceso$: Subscription;
  loading$: Subscription;

  uid: string;
  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _procesoService: ProcesoService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {
    this.proceso = new Proceso();
    this.proceso.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.proceso.prp_coduse = this._configurationService.obtenerIdUsuario();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({id})=> {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(id == '0') {
        this.loaderData = false;
        return};

      this.obtenerProceso();
    })
  }

  obtenerProceso(): void {
    this.proceso$ = this._procesoService.obtenerProceso(this.uid).subscribe(
      (proceso) => {
        this.proceso = proceso;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarProceso(f): this.actualizarProceso(f);
  }

  registrarProceso(f: NgForm): void {
    this.sendForm$=this._procesoService.registrarProceso(this.proceso).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Proceso registrado', '400px', '400px', '');
        this.volver();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  actualizarProceso(f: NgForm): void {
    this.sendForm$=this._procesoService.actualizarProceso(this.proceso).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Proceso actualizado', '400px', '400px', '');
        this.volver();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-produccion/procesos']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.proceso$, this.sendForm$, this.loading$]);
  }
}
