<ng-container>
  <app-pavso-title-section [title]="'Plan de cuenta'" [module]="'Contabilidad'" [usuario]="planCuenta.ccn_coduse"
    [fecha]="planCuenta.ccn_fecupd" [ngClass]="'pav-form'" [estado]="planCuenta.ccn_indsta"></app-pavso-title-section>
  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
    <div class="pavso-content-form fade-in-image">


      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Código</mat-label>
              <input [(ngModel)]="planCuenta.ccn_codccn" type="text" matInput placeholder="Código" name="ccn_codccn"
                required>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l6">
            <mat-form-field>
              <mat-label>Descripción</mat-label>
              <input [(ngModel)]="planCuenta.ccn_descri" type="text" matInput placeholder="Descripción"
                name="ccn_descri">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Des. Abrev.</mat-label>
              <input [(ngModel)]="planCuenta.ccn_desabr" type="text" matInput placeholder="Des. Abrev."
                name="ccn_desabr">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Cuenta SUNAT</mat-label>
              <input [(ngModel)]="planCuenta.csu_codcsu" type="text" matInput placeholder="Cuenta SUNAT"
                name="csu_codcsu">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Tipo de cuenta</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_indcco" required name="ccn_indcco">
                <mat-option value="P">
                  Por Pagar
                </mat-option>
                <mat-option value="C">
                  Por Cobrar
                </mat-option>
                <mat-option value="B">
                  Bancos
                </mat-option>
                <mat-option value="0">
                  Ninguna
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Moneda</mat-label>
              <mat-select [(ngModel)]="planCuenta.tmo_codtmo" required name="tmo_codtmo">
                <mat-option value="SO">
                  Soles
                </mat-option>
                <mat-option value="DO">
                  Dólares
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <div class="col s12 m12 l3" *ngIf="esCuentaDeDosDigitos()">
            <mat-form-field>
              <mat-label>Col. Balance</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_indclb" required name="ccn_indclb">
                <mat-option value="1">
                  Balance
                </mat-option>
                <mat-option value="2">
                  EEFF Nat. y Func.
                </mat-option>
                <mat-option value="3">
                  EEFF Naturaleza
                </mat-option>
                <mat-option value="4">
                  EEFF Función
                </mat-option>
                <mat-option value="5">
                  Ninguno
                </mat-option>

              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Control Cta. Cte.</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_codclb" required name="ccn_codclb">
                <mat-option value="0">
                  Act. Saldo Documento
                </mat-option>
                <mat-option value="1">
                  Act. Saldo Bancos
                </mat-option>
                <mat-option value="2">
                  Act. Saldo Responsabilidad
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Ind. PCGE</mat-label>
              <input [(ngModel)]="planCuenta.ccn_indpcg" type="text" matInput placeholder="Ind. PCGE" name="ccn_indpcg">
            </mat-form-field>
          </div> -->

          <div class="col s12 m12 l12">
            <section>

              <mat-checkbox class="example-margin" [(ngModel)]="planCuenta.ccn_tipccn" name="ccn_tipccn"
                (change)="seleccionarCBMovimiento()">Movimiento</mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="planCuenta.ccn_indana" name="ccn_indana"
                [disabled]="!planCuenta.ccn_tipccn">Cta. Analítica</mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="planCuenta.ccn_indccs" name="ccn_indccs"
                [disabled]="!planCuenta.ccn_tipccn">Centro de costo</mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="planCuenta.ccn_indccf" name="ccn_indccs"
                [disabled]="!planCuenta.ccn_tipccn">
                <mat-form-field>
                  <mat-label>Centro de Costo Fijo</mat-label>
                  <input [(ngModel)]="planCuenta.ccs_codccs" type="text" matInput [disabled]="!planCuenta.ccn_indccf"
                    placeholder="Centro de Costo Fijo" name="ccs_codccs">
                </mat-form-field>
              </mat-checkbox>
              <mat-checkbox class="example-margin" [(ngModel)]="planCuenta.ccn_indpls" name="ccn_indpls"
                [disabled]="!planCuenta.ccn_tipccn">Cta. Planillas</mat-checkbox>
              <mat-checkbox class="example-margin" name="cierre" [(ngModel)]="planCuenta.ccn_indcie" name="ccn_indcie"
                [disabled]="!planCuenta.ccn_tipccn">
                <mat-form-field>
                  <mat-label>Cierre</mat-label>
                  <input [(ngModel)]="planCuenta.ccn_codcie" type="text" [disabled]="!planCuenta.ccn_indcie" matInput
                    placeholder="Cierre" name="ccn_codcie">
                </mat-form-field>
              </mat-checkbox>
            </section>
          </div>

          <div class="col s12 m12 l12">
            <button class="pav-button-action" mat-flat-button color="primary" type="button" (click)="verModificaciones()">
              Ver Modificaciones
            </button>
            &nbsp;
            <button class="pav-button-action" mat-flat-button color="primary" type="button" (click)="validarCtasDiferenciaCambio()">
              Validación Ctas Diferencia Cambio
            </button>
          </div>
        </div>

        <ng-container *ngIf="planCuenta.ccn_tipccn == 1">

          <fieldset [ngStyle]="{marginTop: '10px', borderRadius: '12px'}">
            <legend [ngStyle]="{fontSize: '12px'}">
              AJUSTE DE DIFERENCIA DE CAMBIO
            </legend>

            <div class="row">

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Tipo de ajuste</mat-label>
                  <mat-select [(ngModel)]="planCuenta.ccn_tipaju" required name="ccn_tipaju">
                    <mat-option value="1">
                      Diferencia Saldo Total
                    </mat-option>
                    <mat-option value="2">
                      Diferencia Analítico
                    </mat-option>
                    <mat-option value="3">
                      Traslación Saldo Total
                    </mat-option>
                    <mat-option value="4">
                      Traslación Saldo Analítico
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Tipo de cambio</mat-label>
                  <mat-select [(ngModel)]="planCuenta.ccn_tcaaju" required name="ccn_tcaaju">
                    <mat-option value="3">
                      Fin de Mes Compra
                    </mat-option>
                    <mat-option value="4">
                      Fin de Mes Venta
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset [ngStyle]="{marginTop: '10px', borderRadius: '12px'}">
            <legend [ngStyle]="{fontSize: '12px'}">
              <mat-checkbox class="example-margin" [(ngModel)]="activateCuentaDestino" name="cuentaDestino">
                CUENTA DE DESTINO

              </mat-checkbox>
            </legend>

            <div class="row">

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Tipo de destino</mat-label>
                  <mat-select [(ngModel)]="planCuenta.ccn_tipdes" required name="ccn_tipdes"
                    [disabled]="!activateCuentaDestino">
                    <mat-option value="0">
                      Plan de Cuentas
                    </mat-option>
                    <mat-option value="1">
                      Centro de Costo
                    </mat-option>

                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l3">

                <select-search [label]="'Cuenta Debe'" [placeholder]="'Ej. 0'" [value]="'ccn_codccn'"
                  [name]="'ccn_codccn'" [disabled]="loaderData" [description]="'description'"
                  [data]="cuentas" [model]="planCuenta.ccn_coddbe">
                </select-search>

                <!-- <mat-form-field>
                  <mat-label>Cuenta debe</mat-label>
                  <mat-select [(ngModel)]="planCuenta.ccn_coddbe" required name="ccn_coddbe">
                    <mat-option value="1">
                      cuenta debe 1
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>

              <div class="col s12 m12 l3">
                <select-search [label]="'Cuenta Transferencia'" [placeholder]="'Ej. 0'" [value]="'ccn_codccn'"
                  [name]="'ccn_codccn'" [disabled]="loaderData" [description]="'description'"
                  [data]="cuentas" [model]="planCuenta.ccn_codtra">
                </select-search>

                <!-- <mat-form-field>
                  <mat-label>Cuenta transferencia</mat-label>
                  <mat-select [(ngModel)]="planCuenta.ccn_codtra" required name="ccn_codtra">
                    <mat-option value="1">
                      cuenta transferencia 1
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>



            </div>

            <div class="row">
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Nro. Cuenta</mat-label>
                  <input [(ngModel)]="planCuenta.ccn_ctaban" type="text" matInput placeholder="Nro. Cuenta"
                    name="ccn_ctaban">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Descrip. Banco</mat-label>
                  <input [(ngModel)]="planCuenta.ccn_banco" type="text" matInput placeholder="Descrip. Banco"
                    name="ccn_banco">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Flujo de Caja</mat-label>
                  <mat-select [(ngModel)]="planCuenta.fca_codfca" required name="fca_codfca">
                    <mat-option *ngFor="let flujo of flujos" [value]="flujo.fca_codfca">
                      {{flujo.fca_descri}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

          </fieldset>

        </ng-container>

      </mat-card>
      <br>

    </div>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/plan-de-cuentas">
      <div id="botonesAdicionales">
      </div>
    </pav-form-actions>
  </form>
</ng-container>
