<app-pavso-title-section [title]="'Cobranzas a la fecha'" [module]="'Reporte'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <select-report [disabled]="loaderFields" [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)" [reporteSeleccionado]="reporteSeleccionado[0]"></select-report>

  <div align="center" [ngStyle]="{marginBottom: '12px'}">
    <button class="pav-button-action" mat-flat-button color="primary" [class.spinner]="loading" [disabled]="loading" (click)="verReporte()">{{LABELS_NAME.BTN_SHOW_REPORT}}</button>
  </div>

  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtros</div>
  </div>
  <div class="pav-filtro-container">

    <div class="row">
      <div class="col s12 m12 l3" *ngIf="opcionFiltro.s26_indcli == 1">
        <select-search
          [label]="'Cliente'"
          [placeholder]="'Ej. PEREZ'"
          [name]="'cli_codcli'"
          [value]="'cli_codcli'"
          [description]="'description'"
          [data]="clientes"
          (cambioSelect)="filtro.clientes = $event"
          [model]="filtro.clientes"
          [multiple]="true"
          ></select-search>

      </div>
      <div class="col s12 m12 l3" *ngIf="opcionFiltro.s26_indvde == 1">

        <select-search
          [label]="'Vendedor'"
          [placeholder]="'Ej. PEREZ'"
          [name]="'VDE_CODVDE'"
          [value]="'VDE_CODVDE'"
          [description]="'VDE_NOMVDE'"
          [data]="vendedores"
          (cambioSelect)="filtro.vendedores = $event"
          [model]="filtro.vendedores"
          [multiple]="true"
          ></select-search>

      </div>
      <div class="col s12 m12 l3" *ngIf="opcionFiltro.s26_indtdo == 1">
        <select-search
          [label]="'Tipo de documento'"
          [placeholder]="'Ej. BVE'"
          [name]="'TDO_CODTDO'"
          [value]="'TDO_CODTDO'"
          [description]="'TDO_DESLAR'"
          [data]="tiposDocumento"
          (cambioSelect)="filtro.tiposDocumento = $event"
          [model]="filtro.tiposDocumento"
          [multiple]="true"
          ></select-search>

      </div>
      <div class="col s12 m12 l3" *ngIf="opcionFiltro.s26_indfec == 1">
        <div [ngStyle]="{display: 'flex'}">
          <div [ngStyle]="{marginTop: '10px'}">
            <mat-checkbox class="example-margin" [(ngModel)]="filtro.estaRangoSeleccionado" name="estaRangoSeleccionado"></mat-checkbox>
          </div>
          <div [ngStyle]="{marginLeft: '5px'}">
            <mat-form-field>
              <mat-label>Rango de Fechas</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [disabled]="!filtro.estaRangoSeleccionado">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Saldo</mat-label>
          <mat-select [(ngModel)]="filtro.saldo" name="saldo">
            <mat-option value="todos">
              Todos
            </mat-option>
            <mat-option value="con_saldo">
              Con Saldo
            </mat-option>
            <mat-option value="cancelados">
              Cancelados
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Ordenar por</mat-label>
          <mat-select>
            <mat-option value="vencimiento">
              Vencimiento
            </mat-option>
            <mat-option value="emision">
              Emisión
            </mat-option>
            <mat-option value="documento">
              Documento
            </mat-option>
            <mat-option value="nro_registro">
              Nro. Registro
            </mat-option>
            <mat-option value="cliente">
              Cliente
            </mat-option>
            <mat-option value="vendedor">
              Vendedor
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Modo</mat-label>
          <mat-select>
            <mat-option value="solo12clientes">
              Solo 12 Clientes
            </mat-option>
            <mat-option value="diferente12clientes">
              Diferente 12 Clientes
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Saldo / Abonado</mat-label>
          <mat-select [(ngModel)]="filtro.criterio" name="criterio">
            <mat-option value="TODOS">
              TODOS
            </mat-option>
            <mat-option value="CON_SALDO">
              Con Saldo
            </mat-option>
            <mat-option value="ABONADO">
              Abonados
            </mat-option>
            <mat-option value="CANCELADOS">
              Cancelados
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-checkbox class="example-margin" [(ngModel)]="filtro.soloCuenta12" name="soloCuenta12">Solo 12 clientes</mat-checkbox>

      </div>
    </div>
  </div>
</div>
