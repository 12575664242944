import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { cambiarDatosNomina } from "@shared/state/actions/nomina.actions";
import { NominaService } from "src/app/services";
import { cambiarLoadingTypeNomina } from "@shared/state/actions/loading.actions";

@Component({
  selector: 'app-select-tipo-nomina',
  templateUrl: './select-tipo-nomina.html',
  styleUrls: ['./select-tipo-nomina.scss']
})
export class SeletTipoNomina implements OnInit, OnDestroy {

  loaderData: boolean;

  tipos: any[] = [];
  secuencias: any[] = [];
  tipoNomina: any;
  secNomina: any;

  year: any;
  month: any;

  tipoNomina$: Subscription;
  secuencia$: Subscription;

  period$: Subscription;
  nomina$: Subscription;
  loading$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SeletTipoNomina>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

  }

  ngOnInit(): void {
    this.nomina$ = this.store.select('nomina').subscribe(({typeNomina, secNomina}) => {
      this.tipoNomina = typeNomina;
      this.secNomina = secNomina;
    })
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && state.isLoadingTypeNominaDialog) {
        this.obtenerTiposNomina();
      }
    })
  }

  obtenerTiposNomina(): void {
    this.loaderData = true;
    this.tipoNomina$ = this._nominaService.obtenerTiposNomina().subscribe(
      response => {
        this.tipos = response;
        this.secuencia$ = this._nominaService.obtenerSecuenciaNomina(`${this.year}${this.month}${this.tipoNomina}`).subscribe(
          secuencias => {
            this.secuencias = secuencias;
            if(secuencias && secuencias.length > 0){
              this.secNomina = secuencias[secuencias.length -1].cod;
            }
            this.loaderData = false;

          },
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  obtenerSecuenciasNomina(code): void {
    this.secuencia$ = this._nominaService.obtenerSecuenciaNomina(code).subscribe(
      secuencias => {
        this.secuencias = secuencias;
        if(secuencias && secuencias.length > 0){
          this.secNomina = secuencias[secuencias.length -1].cod;
        }
        this.loaderData = false;

      },
      error => this._snackBarService.showError("Error al obtener secuencia nómina", "OK")
    )
  }

  seleccinarTipoNomina(value): void {
    const tipoNomina = this.tipos.find(item => item.nti_codnti == value);
    this.store.dispatch(cambiarDatosNomina({typeNomina: value, secNomina: "", nameTypeNomina: tipoNomina.nti_descri}))
    this.obtenerSecuenciasNomina(`${this.year}${this.month}${this.tipoNomina}`);
  }

  cancelar(): void {
    this.dialogRef.close();
    unsubscribeSubscription([
      this.loading$,
      this.tipoNomina$,
      this.secuencia$,
      this.period$,
      this.nomina$
    ]);
  }

  confirmar(): void {
    const tipoNomina = this.tipos.find(item => item.nti_codnti == this.tipoNomina);
    this.store.dispatch(cambiarDatosNomina({typeNomina: this.tipoNomina, secNomina: this.secNomina, nameTypeNomina: tipoNomina.nti_descri}));
    this.store.dispatch(cambiarLoadingTypeNomina({isLoadingCompany: false, isLoadingSidenav: false, isLoadingModule: false, isLoadingEstablishment: false, isLoadingTypeNominaDialog: false}))
    this.dialogRef.close();
    unsubscribeSubscription([
      this.loading$,
      this.tipoNomina$,
      this.secuencia$,
      this.period$,
      this.nomina$
    ]);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoNomina$,
      this.secuencia$,
      this.period$,
      this.nomina$,
      this.loading$
    ]);
  }

}
