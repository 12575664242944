export class ProductPriceList {
  prd_codprd: any;
  prd_desesp: any;
  LPD_PRENAC: any;
  LPD_VTANAC: any;
  LPD_PREDOL: any;
  LPD_VTADOL: any;
  LPD_INDEXC: any;
  LPD_PRMNAC: any;
  LPD_PRMDOL: any;
  LPD_INDSTA: string;
  LPD_CODUSE: string;
  LPD_FECUPD: any;
  LPD_SITLPD: any;
  LPD_ESTADO: any;
  um: any;
  constructor(
  ){
    this.LPD_FECUPD = new Date();
  }
}
