import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { PavsoColors } from '@data/enums/color.enum';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SendEmailComponent } from '@shared/components/dialogs/send-email/send-email.component';
import { DialogService } from '@shared/services/dialog.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services';
import { cambiar } from '../../shared/state/actions/period.actions';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  user: any;
  loaderUpdPwd: boolean = false;

  hide = true;
  hideA = true;
  hideC = true;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;
  openingYear: string;
  openingMonth: string;
  periodos: Array<any>;

  colorGreen: string;
  colorBlue: string;
  colorYellow: string;

  period$: Subscription;
  logoUrl: string;



  constructor(
    private  _auth: AuthenticationService,
    private store: Store<PavsoState>,
    private _dialogService: DialogService
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month, openingYear, openingMonth, periodos}) => {
      this.year = year;
      this.month = month;
      this.openingYear = openingYear;
      this.openingMonth = openingMonth;
      this.periodos = periodos;
    })

    this.colorGreen = PavsoColors.Green;
    this.colorBlue = PavsoColors.BlueLight;
    this.colorYellow = PavsoColors.Yellow;
  }

  ngOnInit(): void {
    this.user = this._auth.getIdentity();
    this.establecerPeriodo();
  }

  establecerPeriodo(): void {
    setTimeout(() => {
      this.store.dispatch(cambiar({year: this.year, month: this.month, openingYear: this.openingYear, openingMonth: this.openingMonth, codMod: "00", periodos: this.periodos}));
    }, 1000);
  }

  actualizarContrasenia(f: NgForm): void {

  }

  subirImagen(a): void {

  }

  onFileSelected(): void {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.logoUrl = event.target.files[0] ?? null;
      };

      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  prueba(): void {
    this._dialogService.openDialog(SendEmailComponent, '', '400px', 'auto', '');
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
    ]);
  }

}
