import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexPlotOptions, ApexTitleSubtitle, ApexTooltip, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { AuthenticationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  noData: ApexNoData;
};

@Component({
  selector: 'app-composicion-cargo-trabajadores',
  templateUrl: './composicion-cargo-trabajadores.component.html',
  styleUrls: ['./composicion-cargo-trabajadores.component.css']
})
export class ComposicionCargoTrabajadoresComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['initial', 'trabajadores', 'edad1', 'edad2', 'edad3', 'edad4', 'edad5'];
  dataSource = [];

  chartOptions: Partial<ChartOptions>;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  totalCargos: number;
  totalGeneral: number;

  apexChart: ApexCharts;

  period$: Subscription;
  loading$: Subscription;
  informe$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _nominaService: NominaService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    // INICIALIZAR GRÁFICO
    this.chartOptions = {
      series: [],
      chart: {
        height: 400,
        type: "bar",
        events: {
          click: function(chart, w, e) {
          }
        }
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
      ],
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        show: true,
        position: "right"
      },
      noData: {
        text: 'Cargando...'
      },
      grid: {
        show: true
      },
      yaxis: {
        title: {
          text: "# Colaboradores",
        },
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "10px"
          },
        }
      },
      xaxis: {
        categories: ['SIN CATEGORÍA'],
        labels: {

          style: {
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
            ],
            fontSize: "7px"
          },
        },
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " ";
          }
        }
      },
      title: {
        text: `Total de Cargos: ${this.totalCargos} | Total de Colaboradores: ${this.totalGeneral}`,
        align: "center",
        style: {
          color: "#444"
        }
      }
    }

    this.apexChart = new ApexCharts(document.querySelector("#chartCargoTrabajadores"), this.chartOptions);

    this.apexChart.render();

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })

  }

  loadData(): void {

    this.informe$ = this._nominaService.obtenerInformeCargoTrabajadores(this.year, this.month).subscribe(
      response => {
        this.totalCargos = response.totalCargos;
        this.totalGeneral = response.totalGeneral;

        this.dataSource = response.datatable;

        this.apexChart.updateSeries(response.categories.length > 0? response.series: [{name: 'Nro. Colaboradores', data: [0]}]);

        this.apexChart.updateOptions({
          xaxis: {
            categories: response.categories.length > 0? response.categories: ['SIN CATEGORÍA'],
            labels: {
              style: {
                colors: [
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                  "#775DD0",
                  "#008FFB",
                  "#00E396",
                  "#FEB019",
                  "#FF4560",
                ],
                fontSize: "7px"
              },
            }
          },
          title: {
            text: `Total de Cargos: ${this.totalCargos} | Total de Colaboradores: ${this.totalGeneral}`,
            align: "center",
            style: {
              color: "#444"
            }
          }
        })
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/informes-de-composicion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.informe$]);
  }

}
