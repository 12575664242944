import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AprobacionSolicitudCompra } from "src/app/models/compras/operaciones/aprobacion-solicitud-compra";
import { SolicitudCompra } from "src/app/models/compras/operaciones/solicitud-compra";
import { ConfigurationService } from "src/app/services/configuration.service";
import { IParam, generarParams } from "../../utilities/params";

@Injectable({
  providedIn: 'root'
})
export class SolicitudCompraService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   * Obtener solicitudes de compra
   * @param param0 parámetros de url
   * @returns
   */
  obtenerSolicitudesCompra({params = []}: {params?: Array<IParam>} = {}): Observable<any> {
    const paramsStr = generarParams(params);

    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/solcompra/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`)

  }

  /**
   * Obtener solicitud de compra
   * @param idSolicitud
   * @returns
   */
  obtenerSolicitudCompra(idSolicitud: string): Observable<any> {

    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/solcompra/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${idSolicitud}`)

  }

  /**
   * Registrar solicitud de compra
   * @param payload instancia de SolicitudCompra
   * @returns
   */
  registrarSolicitudCompra(payload: SolicitudCompra): Observable<any> {

    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/solcompra/crear`, payload)

  }

  /**
   * Actualizar solicitud de compra
   * @param payload instancia de SolicitudCompra
   * @returns
   */
  actualizarSolicitudCompra(payload: SolicitudCompra): Observable<any> {

    return this.http.put<any>(`${this._configurationService.obtenerUrl()}/api/solcompra/modificar`, payload)

  }

  /**
   * Aprobación de solicitudes de compra
   * @param payload Array de solicitudes de compra
   * @returns
   */
  aprobarSolicitudesCompra(payload: Array<AprobacionSolicitudCompra>): Observable<any> {

    return this.http.put<any>(`${this._configurationService.obtenerUrl()}/api/solcompra/aprobacion`, payload)

  }

}
