import { BancoFormComponent, BancoListComponent } from "./banco";
import { CanalVentaFormComponent, CanalVentaListComponent } from "./canal-venta";
import { CondicionPagoModule } from "./condicion-pago/condicion-pago.module";
import { ConductorFormComponent, ConductorListComponent } from "./conductor";
import { GiroNegocioModule } from "./giro-negocio/giro-negocio.module";
import { MotivoMovimientoFormComponent, MotivoMovimientoListComponent } from "./motivo-movimiento";
import { PaisFormComponent, PaisListComponent } from "./pais";
import { TarjetaCreditoFormComponent, TarjetaCreditoListComponent } from "./tarjeta-credito";
import { TipoDocumentoIdentidadFormComponent, TipoDocumentoIdentidadListComponent } from "./tipo-documento-identidad";
import { ZonaVentaFormComponent, ZonaVentaListComponent } from "./zona-venta";

export const tablasApoyoComponentsVentas = [
  BancoListComponent,
  BancoFormComponent,
  CanalVentaFormComponent,
  CanalVentaListComponent,
  ConductorFormComponent,
  ConductorListComponent,
  TipoDocumentoIdentidadFormComponent,
  TipoDocumentoIdentidadListComponent,
  MotivoMovimientoFormComponent,
  MotivoMovimientoListComponent,
  PaisFormComponent,
  PaisListComponent,
  TarjetaCreditoFormComponent,
  TarjetaCreditoListComponent,
  ZonaVentaFormComponent,
  ZonaVentaListComponent
]

export const tablasApoyoModulesVentas = [
  CondicionPagoModule,
  GiroNegocioModule
]
