import { Component, OnDestroy, OnInit } from '@angular/core';
import { PavsoColors } from '@data/enums/color.enum';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { CIA } from 'src/app/models/cia';
import { ConfigurationService } from 'src/app/services';
import { CIAService } from 'src/app/services/api/sistema/cia.service';
import * as actionsConfig from '@shared/state/actions/config.actions';

@Component({
  selector: 'app-my-logo',
  templateUrl: `./my-logo.component.html`,
  styleUrls: ['./my-logo.component.css'],
})
export class MyLogoComponent implements OnInit, OnDestroy {

  colorGreen: string = PavsoColors.Green;
  colorBlue: string = PavsoColors.BlueLight;
  colorYellow: string = PavsoColors.Yellow;

  cia: CIA;

  ciaUpdate$: Subscription;
  cia$: Subscription;
  loading$: Subscription;
  config$: Subscription;

  loaderData: boolean;
  config: any;
  imageBase64: string;

  constructor(
    private readonly _ciaService: CIAService,
    private readonly _configurationService: ConfigurationService,
    private readonly _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.cia = new CIA();
    this.cia.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.loaderData = true;
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment) {
        this.obtenerCIA()
      }
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.config = state;
    })
  }

  obtenerCIA(): void {
    this.loaderData = true;

    this.cia$ = this._ciaService.obtenerCIA().subscribe(
      cia => {
        this.cia = cia;
        console.log('cia', cia);

        this.store.dispatch(actionsConfig.cambiarConfiguracionGeneral({...this.config, logo: cia.CIA_LOGB64? cia.CIA_LOGB64: '', dircia: cia.CIA_DIRCIA }))
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.message, 'Ok');
      }
    )

  }

  onFileSelected(event: any): void {

    const file: File = event.target.files[0];

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageBase64 = reader.result as string;
        this.cia.CIA_LOGB64 = this.imageBase64;
        console.log('Base64 Image:', this.cia.CIA_LOGB64); // This is the Base64 string
      };
      reader.readAsDataURL(file);
    } else {
      this._snackBarService.showError('Formato de imagen no permitido.', 'Ok');
      console.error('Invalid file type. Please upload a JPEG image.');
    }

  }

  guardarCambios(): void {
    this.loaderData = true;
    if(!this.cia.CIA_LOGB64) {
      this._snackBarService.showError('No existe una imagen a cargar', 'Ok');
      return;
    }

    if(this.cia.CIA_LOGB64.trim() == '') {
      this._snackBarService.showError('No existe una imagen a cargar', 'Ok');
      return;
    }

    this.ciaUpdate$ = this._ciaService.modificarCIA(this.cia).subscribe(
      cia => {
        console.log('response', cia);
        this.store.dispatch(actionsConfig.cambiarConfiguracionGeneral({...this.config, logo: cia.CIA_LOGB64? cia.CIA_LOGB64: '', dircia: cia.CIA_DIRCIA}))
        this._snackBarService.showSuccess('Cambios guardados correctamente', 'Ok');
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.message, 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cia$, this.ciaUpdate$, this.loading$, this.config$
    ])
  }

}
