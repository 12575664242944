export class TipoListaPrecio {
  cia_codcia: string;
  tlp_codtlp: string;
  tlp_descri: string;
  tlp_aprped: number;
  tlp_indsta: string;
  tlp_coduse: string;
  tlp_fecupd: Date;

  constructor() {
    this.tlp_indsta = '1';
    this.tlp_fecupd = new Date();
  }
}
