import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AddProductDialogData } from '@data/interfaces/modules/almacen/kardex.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { PreferenciaImpresion } from '@shared/components/dialogs/preferencias-impresion/preferencias-impresion.dialog';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { PavShowProductImageDialog } from '@shared/components/pav-show-product-image-dialog/pav-show-product-image.dialog';
import { SelectSearchComponent } from '@shared/components/select/select-search/select-search.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { agrupar } from '@utils/array/array';
import * as formatoPDFBuilder from '@utils/pdfmaker/builder/almacen/operaciones/kardex.pdfmaker';
import { fillTable } from '@utils/tables/table';
import { Observable, Subscription } from 'rxjs';
import { CanComponentReturn, CanDeactivateGuard } from 'src/app/guards/return.guard';
import { Order, PartDetalleMap } from 'src/app/models';
import { ConfiguracionTecnica } from 'src/app/models/almacen/inventario/configuracion-tecnica';
import { DetalleLote, DetalleSerie, Kardex, PartDetalleGuia, TallasMad } from 'src/app/models/almacen/part';
import { AlmacenService, AuthenticationService, ConfigurationService, ContabilidadService, GeneralService, VentasService } from 'src/app/services';
import { LugarDespachoCIAService } from 'src/app/services/api/almacen/maestros/lugar-despacho-cia.service';
import { TipoDocumentoReferenciaService } from 'src/app/services/api/almacen/maestros/tipo-documento-referencia.service';
import { KardexService } from 'src/app/services/api/almacen/operaciones/kardex.service';
import { PrinterService } from 'src/app/services/api/printer.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { ConfirmationReturnService } from 'src/app/services/confirmation-return.service';
import { addDaysToDate, formatDateClean6Digitos, formatDateWithDash, formatDateWithDashLocal, obtenerMesPeriodDeFecha } from 'src/app/utils/formats/date.format';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { AddLotesDialog } from '../dialogs/add-lotes-dialog/add-lotes.dialog';
import { AddProductDialog } from '../dialogs/add-product-dialog/add-product.dialog';
import { CopyPartsDialog } from '../dialogs/copy-parts-dialog/copy-parts.dialog';
import { KardexBottomSheet } from '../kardex-bottom-sheet/kardex-bottom-sheet';
import { BarcodeScannerService } from 'src/app/services/barcode.service';
import { SerieDocumentoService } from 'src/app/services/api/ventas/maestros/serie-documento.service';
import * as pdfBuilder from '@utils/pdfmaker/builder/almacen/almacen.pdfmaker';
import { GuiaTextoService } from 'src/app/services/api/almacen/operaciones/guia-texto.service';
import { UnidadMedidaService } from 'src/app/services/api/almacen/configuracion/unidad-medida.service';
import { Cliente } from 'src/app/models/ventas';
import { PedidoService } from 'src/app/services/api/ventas/operaciones/pedido.service';
import { SunatService } from 'src/app/services/api/sunat/sunat.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { LoteService } from 'src/app/services/api/almacen/maestros/lote.service';
import { IParam } from 'src/app/services/api/utilities/params';
import { BINARY_TYPE } from '@data/interfaces/binary.type';
import { OrdenCompraService } from 'src/app/services/api/compras/operaciones/orden-compra.service';
import { ProductoMultipleDialog } from 'src/app/admin/apps/ventas/pages/operaciones/pedido';
import { esFechaDiferenteAPeriodo } from '@utils/date/date';
import { PermisoForm } from 'src/app/models/permiso-form';

@Component({
  selector: 'app-kardex-form',
  templateUrl: './kardex-form.component.html',
  styleUrls: ['./kardex-form.component.scss']
})
export class KardexFormComponent implements OnInit, OnDestroy, CanComponentReturn {

  isMobile: boolean = false;
  isTablet: boolean = false;

  selected: PartDetalleMap;

  selectRow(row: PartDetalleMap) {

    if (!row.prd_codprd) {
      return;
    }

    this.selected = row;

    const producto = this.productos.find(item => item.prd_codprd == row.prd_codprd);

    if (producto.prd_indtal == 1) {
      this.selected.prd_indtal = 1;
      this.selectedTalla = null;

      this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);
      this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);
    }

    if (producto.prd_indtal == 0) {
      this.selected.prd_indtal = 0;
      this.selectedTalla = this.selected.tallas_mad[0];

      this.dataSourceLote = fillTable(this.selectedTalla.lotes_mlo, this.paginatorLote, this.sortLote);
      this.dataSourceSerie = fillTable(this.selectedTalla.series_mas, this.paginatorSerie, this.sortSerie);
    }

    this.dataSourceTalla = fillTable(this.selected.tallas_mad, this.paginatorTalla, this.sortTalla);

  }

  selectedTalla: TallasMad;

  selectRowTalla(row: TallasMad) {
    this.selectedTalla = row;

    this.dataSourceLote = fillTable(this.selectedTalla.lotes_mlo, this.paginatorLote, this.sortLote);
    this.dataSourceSerie = fillTable(this.selectedTalla.series_mas, this.paginatorSerie, this.sortSerie);
  }

  selectedLote: DetalleLote;

  selectRowLote(row: any) {
    this.selectedLote = row;
  }

  selectedSerie: DetalleSerie;

  selectRowSerie(row: any) {
    this.selectedSerie = row;
  }

  motivoSeleccionado: { mmo_indtra: number } = { mmo_indtra: 0 };

  // displayedColumns: string[] = ['descripcion', 'codigo', 'um'/*, 'cantidad_oc'*/, 'cantidad', 'ume_codven', 'map_canven', 'costo_ume', 'costo_umn', 'imp_me_prod', 'imp_mn_prod', 'glosa'/*, 'lote_ref_pro'*/, 'item', 'sec'];
  displayedColumns: string[] = ['descripcion', 'codigo', 'um'/*, 'cantidad_oc'*/, 'cantidad', 'costo_ume', 'costo_umn', 'imp_me_prod', 'imp_mn_prod', 'glosa'/*, 'lote_ref_pro'*/, 'item', 'sec'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsTalla: string[] = ['item', 'codigo', 'descripcion', 'um', 'cantidad_oc', 'cantidad', 'lote_ref_pro', 'costo_ume', 'costo_umn', 'sec', 'imp_me_prod', 'imp_mn_prod', 'glosa'];
  dataSourceTalla: MatTableDataSource<any>;

  @ViewChild("paginatorTalla") paginatorTalla: MatPaginator;
  @ViewChild(MatSort) sortTalla: MatSort;

  displayedColumnsLote: string[] = ['lote', 'pais', 'cantidad', 'fec_fabric', 'fec_vcto', 'observacion'];
  dataSourceLote: MatTableDataSource<any>;

  @ViewChild("paginatorLote") paginatorLote: MatPaginator;
  @ViewChild("sortLote") sortLote: MatSort;

  displayedColumnsSerie: string[] = ['serie', 'fec_fabric', 'fec_vcto'];
  dataSourceSerie: MatTableDataSource<any>;

  @ViewChild("paginatorSerie") paginatorSerie: MatPaginator;
  @ViewChild("sortSerie") sortSerie: MatSort;

  kardex: Kardex;

  todosTiposDocumento: any[] = [];
  tiposDocumento: any[] = [];
  tiposDocumento2: any[] = [];
  tiposDocumentoGuia: any[] = [];
  almacenes: any[] = [];
  motivosAll: any[] = [];
  motivos: any[] = [];
  proveedores: any[] = [];
  liquidaciones: any[] = [];
  ordenes: any[] = [];
  ordenesTodos: any[] = [];
  centroCostos: any[] = [];
  invoices: any[] = [];
  productos: any[] = [];
  ordenesInternamiento: any[] = [];
  requerimientos: any[] = [];
  conductores: any[] = [];
  unidades: any[] = [];
  unidadesAll: any[] = [];
  clientes: any[] = [];
  transportistas: any[] = [];
  seriesGuia: any[] = [];
  lugaresDespachoCIA: any[] = [];
  lugaresDespacho: any[] = [];
  lugaresDespachoDesti: any[] = [];
  tiposDocumentoRef: any[] = [];
  series: any[] = [];
  unidadesMedida: any[] = [];
  pedidos: any[] = [];
  pedidosTodos: any[] = [];
  agenciaTransportes: any[] = [];
  aeropuertos: any[] = [];
  paises: any[] = [];
  ubigeosSunat: any[] = [];

  numeroOrdenDisabled = true;

  ubicacionAdicional: string;

  loaderReg: boolean = false;
  loaderRegGuia: boolean = false;

  importeTotalMN: number = 0;
  importeTotalME: number = 0;
  costoTotalMN: number = 0;
  costoTotalME: number = 0;

  loaderData: boolean = false;
  loaderFormato: boolean = false;

  minDate: Date;
  maxDate: Date;

  tipoCambio$: Subscription;
  motivoParteMov$: Subscription;
  producosOrden$: Subscription;
  productosLiq$: Subscription;
  sendForm$: Subscription;
  loading$: Subscription;
  periodoAbierto$: Subscription;
  correlativoGuia$: Subscription;
  preferenciasImpresion$: Subscription;
  period$: Subscription;
  maestros$: Subscription;
  kardexs$: Subscription;

  tiposDocumento$: Subscription;
  tiposDocumentoReferencia$: Subscription;
  almacenes$: Subscription;
  tiposCambio$: Subscription;
  ordenes$: Subscription;
  auxiliares$: Subscription;
  invoices$: Subscription;
  liquidaciones$: Subscription;
  centros$: Subscription;
  productos$: Subscription;
  choferes$: Subscription;
  unidades$: Subscription;
  clientes$: Subscription;
  motivos$: Subscription;
  series$: Subscription;
  establecimiento$: Subscription;
  config$: Subscription;
  unidadesMedida$: Subscription;
  pedidos$: Subscription;
  aeropuertos$: Subscription;
  agenciasTransporte$: Subscription;
  ubigeosSunat$: Subscription;
  paises$: Subscription;

  configuracionTecnica$: Subscription;
  private barcode$: Subscription;

  guiaRemisionPDF$: Subscription;
  transportistas$: Subscription;
  lugaresDespachoCia$: Subscription;
  correlativoDoc$: Subscription;
  pedido$: Subscription;
  lugaresDespacho$: Subscription;
  kardex$: Subscription;


  anioPeriodo: string;
  mesPeriodo: string;

  anioApertura: string;
  mesApertura: string;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  anio: string;
  mes: string;
  almacen: string;
  tipo: string;
  numeroParte: string;

  indOrdCompra: 1 | 0;
  indReqMat: 1 | 0;
  indLiqProd: 1 | 0;
  indOrdProd: 1 | 0;
  indPedido: 1 | 0;
  indRegInvoice: 1 | 0;
  indOrdIntercambio: 1 | 0;
  indTransferencia: 1 | 0;
  indGuiaRemision: 1 | 0;
  indVal: 1 | 0;
  indMotivoExportacion: 1 | 0;
  indMotivoUM2: 1 | 0;
  indObligatorioCosto: BINARY_TYPE = 0;
  umSugerida02: string;


  productoSeleccionado: PartDetalleMap;
  stockDisponible: number = 0;


  configuracionTecnica: ConfiguracionTecnica;

  // Controles de focus y select
  @ViewChild('sAlmacen') almacenSelectComponent: SelectSearchComponent;
  @ViewChild('sMotivo') motivoSelectComponent: SelectSearchComponent;
  @ViewChild('sResponsable') responsableSelectComponent: SelectSearchComponent;
  @ViewChild('sTipoDocRef01') tipoDocRef01SelectComponent: SelectSearchComponent;
  @ViewChild('sTipoDocRef02') tipoDocRef02SelectComponent: SelectSearchComponent;
  @ViewChild('sCentroCosto') centroCostoSelectComponent: SelectSearchComponent;
  @ViewChild('sOrdenCompra') ordenCompraSelectComponent: SelectSearchComponent;
  @ViewChild('iFecha') inputFecha: ElementRef;
  @ViewChild('iTasaCambio') inputTasaCambio: ElementRef;
  @ViewChild('iNumDocRef01') inputNumDocRef01: ElementRef;
  @ViewChild('iNumDocRef02') inputNumDocRef02: ElementRef;
  @ViewChild('numeroDocGuia') inputNumeroDocGuia: ElementRef;

  preferenciaImpresion: PreferenciaImpresion;

  tablaSeleccionada: boolean = false;


  esObligatorioTipoDocRef: boolean = false;

  establecimiento: string;

  logo: string;
  dircia: string;
  ruccia: string;

  modalidadTraslado: string;

  @ViewChild('contextMenuTrigger', { read: MatMenuTrigger }) contextMenuTrigger: MatMenuTrigger;

  @ViewChild('selectContainer') selectContainer: ElementRef;

  rucEmpresa: string;

  permisos$: Subscription;
  currentPage$: Subscription;
  permiso: PermisoForm = new PermisoForm();
  program: string;

  cliente: any;

  constructor(
    public dialog: MatDialog,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _almacenService: AlmacenService,
    private readonly _pedidoService: PedidoService,
    private readonly _generalService: GeneralService,
    private _kardexService: KardexService,
    private _snackBarService: SnackBarService,
    private _guiaService: GuiaTextoService,
    private _router: Router,
    private _ventasService: VentasService,
    private _serieDocumentoService: SerieDocumentoService,
    private _contabilidadService: ContabilidadService,
    private _productoService: ProductoService,
    private _activatedRoute: ActivatedRoute,
    private _clienteService: ClienteService,
    private confirmationReturnService: ConfirmationReturnService,
    private _lugarDespachoCiaService: LugarDespachoCIAService,
    private _unidadMedidaService: UnidadMedidaService,
    private readonly _printerService: PrinterService,
    private canDeactivateGuard: CanDeactivateGuard,
    private _tipoDocumentoReferenciaService: TipoDocumentoReferenciaService,
    private _bottomSheet: MatBottomSheet,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver,
    private readonly barcodeScannerService: BarcodeScannerService,
    private readonly _loteService: LoteService,
    private readonly _sunatService: SunatService,
    private readonly _ordenCompraService: OrdenCompraService,

  ) {

    this.configuracionTecnica = new ConfiguracionTecnica();

    this.productoSeleccionado = new PartDetalleMap();

    this.minDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), new Date().getDate());

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceTalla = fillTable([], this.paginatorTalla, this.sortTalla);

    this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);

    this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

    this.kardex = new Kardex();

    this.kardex.detalle_map = [];

    this.kardex.cia_codcia = this._configurationService.obtenerCompaniaCliente();

    this.kardex.mac_coduse = this._authService.getUsuarioSistema();

    this.minDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo) - 1, 1);
    this.maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

    const now = new Date();
    const mes = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `${now.getMonth() + 1}`;
    const anio = now.getFullYear().toString();

    if (mes == this.mesPeriodo && anio == this.anioPeriodo) {
      this.kardex.mac_fecmac = new Date();
    }

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }

  seleccionarTransportista(): void {

    console.log('unidades', this.unidades);

    if (this.kardex.detalle_guia[0].cli_codtra) {
      this.unidades = this.unidadesAll.filter(unidad => unidad.CLI_CODCLI.trim() == this.kardex.detalle_guia[0].cli_codtra)
    } else {
      this.unidades = this.unidadesAll;
      this.kardex.detalle_guia[0].cli_codtra = null;
    }

    (this.kardex.detalle_guia[0].cli_codtra == this.ruccia) ?
      this.modalidadTraslado = 'PARTICULAR'
      :
      this.modalidadTraslado = 'PÚBLICO'

    console.log('MODALIDAD DE TRASLADO', this.modalidadTraslado)

  }

  verImagen(idProducto): void {

    if (!idProducto) {
      this._snackBarService.showError('Seleccionar un producto', 'OK');
      return;
    }

    const dialogRef = this.dialog.open(PavShowProductImageDialog, {
      width: 'auto',
      data: idProducto,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) {
        this.loadData()
        this.rucEmpresa = this._configurationService.obtenerClienteId();

        console.log('this.rucEmpresa == "20605493603"', this.rucEmpresa == '20605493603')
        console.log('this.rucEmpresa', this.rucEmpresa)
        this.displayedColumnsLote = this.rucEmpresa == '20605493603' ? ['lote', 'pais', 'cantidad', 'fec_fabric', 'fec_vcto', 'observacion'] : ['lote', 'cantidad', 'fec_fabric', 'fec_vcto', 'observacion']
      };

      this.period$ = this.store.select('period').subscribe(({ year, month, openingYear, openingMonth }) => {
        this.anioPeriodo = year;
        this.mesPeriodo = month;
        this.anioApertura = openingYear;
        this.mesApertura = openingMonth;
        this.kardex.ano_codano = this.anioPeriodo;
        this.kardex.mes_codmes = this.mesPeriodo;

        this.minDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo) - 1, 1);
        this.maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

        const now = new Date();
        const mes = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
        const anio = now.getFullYear().toString();

        this.kardex.mac_fecmac = this.maxDate;

        if (mes == this.mesPeriodo && anio == this.anioPeriodo) {
          this.kardex.mac_fecmac = new Date();

          this.seleccionarFecha();
        }

        this.kardex.mac_fecmac = formatDateWithDashLocal(this.kardex.mac_fecmac);
        console.log('formato fecha', this.kardex.mac_fecmac)
      })
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
      this.dircia = state.dircia;
      this.ruccia = state.client;
    })

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
      console.log('codigo de establecimiento', this.establecimiento)
    })

    this.currentPage$ = this.store.select('currentPage').subscribe(state => {
      this.program = state.program;
    })

    this.barcode$ = this.barcodeScannerService.barcodeData$.subscribe((barcode) => {

      if (!barcode || barcode == 'Enter') return;
      console.log('Código de barras escaneado:', barcode);
      console.log('tipo doc ref:', this.kardex.tdo_codtdo);

      if (!this.kardex.tdo_codtdo || this.kardex.tdo_codtdo == 'nuevo') {
        this._snackBarService.showError('Debe seleccionar un tipo documento *', 'Ok');
        return;
      }

      if (!this.kardex.alm_codalm) {
        this._snackBarService.showError('Debe seleccionar un almacen', 'Ok');
        return;
      }

      if (!this.kardex.mmo_codmmo) {
        this._snackBarService.showError('Debe seleccionar un motivo', 'Ok');
        return;
      }

      if (this.esObligatorioTipoDocRef && !this.kardex.mac_tdore1) {
        this._snackBarService.showError('Debe seleccionar un tipo documento de referencia (1)', 'Ok');
        return;
      }

      if (this.esObligatorioTipoDocRef && !this.kardex.mac_numre1) {
        this._snackBarService.showError('Debe establecer un número documento de referencia (1)', 'Ok');
        return;
      }

      const producto = new PartDetalleMap();

      const productoEncontrado = this.productos.find(item => {
        if (!item.prd_codean) return false;
        return item.prd_codean.trim() == barcode.trim().substring(0, barcode.length - 5);
      });
      if (!productoEncontrado) {
        this._snackBarService.showError('Producto no encontrado', 'Ok');
        return;
      }

      let existeEnDetalle = false;

      console.log('productoEncontrado', productoEncontrado)

      this.kardex.detalle_map.forEach(item => {
        console.log('item.prd_codprd', item.prd_codprd)
        if (productoEncontrado.prd_codprd.trim() == item.prd_codprd.trim()) {

          existeEnDetalle = true;
          item.map_canprd += 1;

        }
      });

      producto.prd_codprd = productoEncontrado.prd_codprd;

      if (!existeEnDetalle) {
        this.selectRow(producto);
        this.kardex.detalle_map.push(producto);
      }

      this.kardex.detalle_map.forEach((element, key) => {
        element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
      })

      this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

      this.calcularTotales();

    });
  }

  nuevoRegistro(): void {

    if (this.kardex.mac_nummac) {
      // this._router.navigate(['/modulo-almacen/kardex', '0', '0', '0', 'ING', '0']);
      this.kardex = new Kardex();
      this.dataSource = fillTable([], this.paginator, this.sort);
      this.dataSourceTalla = fillTable([], this.paginatorTalla, this.sortTalla);
      this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);
      this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

      this.kardex.ano_codano = this.anioPeriodo;
      this.kardex.mes_codmes = this.mesPeriodo;

      this.anio = 'string';
      this.mes = '0';
      this.almacen = '0';
      this.tipo = 'ING';
      this.numeroParte = '0';

      this.minDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo) - 1, 1);
      this.maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

      const now = new Date();
      const mes = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
      const anio = now.getFullYear().toString();

      this.kardex.mac_fecmac = this.maxDate;
      console.log('mes', mes)
      console.log('mesPeriodo', this.mesPeriodo)
      console.log('anio', anio)
      console.log('anioPeriodo', this.anioPeriodo)

      if (mes == this.mesPeriodo && anio == this.anioPeriodo) {
        console
        this.kardex.mac_fecmac = new Date();

        this.seleccionarFecha();
      }

      this.kardex.mac_fecmac = formatDateWithDashLocal(this.kardex.mac_fecmac);

      // this.tiposCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDashLocal(this.kardex.mac_fecmac)).subscribe(
      //   tc => {
      //     this.kardex.mac_tipcam = tc[0].tipcam;
      //   },
      //   error => {
      //     this._snackBarService.showError(error.error.msg, 'Ok');
      //   }
      // )
    } else {
      this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de crear un registro nuevo, sus cambios realizados se perderán?', '', '', '').subscribe(result => {
        if (result) {
          // this._router.navigate(['/modulo-almacen/kardex', '0', '0', '0', 'ING', '0']);
          this.kardex = new Kardex();
          this.dataSource = fillTable([], this.paginator, this.sort);
          this.dataSourceTalla = fillTable([], this.paginatorTalla, this.sortTalla);
          this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);
          this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

          this.kardex.ano_codano = this.anioPeriodo;
          this.kardex.mes_codmes = this.mesPeriodo;
          this.anio = 'string';
          this.mes = '0';
          this.almacen = '0';
          this.tipo = 'ING';
          this.numeroParte = '0';

          this.minDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo) - 1, 1);
          this.maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

          const now = new Date();
          const mes = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
          const anio = now.getFullYear().toString();

          this.kardex.mac_fecmac = this.maxDate;
          console.log('mes', mes)
          console.log('mesPeriodo', this.mesPeriodo)
          console.log('anio', anio)
          console.log('anioPeriodo', this.anioPeriodo)

          if (mes == this.mesPeriodo && anio == this.anioPeriodo) {
            console
            this.kardex.mac_fecmac = new Date();

            this.seleccionarFecha();
          }

          this.kardex.mac_fecmac = formatDateWithDashLocal(this.kardex.mac_fecmac);

          // this.tiposCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDashLocal(this.kardex.mac_fecmac)).subscribe(
          //   tc => {
          //     this.kardex.mac_tipcam = tc[0].tipcam;
          //   },
          //   error => {
          //     this._snackBarService.showError(error.error.msg, 'Ok');
          //   }
          // )

        }
      })

    }

  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // Lógica para mostrar el modal de confirmación antes de la navegación

    if (!this.kardex.mac_nummac) {
      console.log('this.kardex.mac_nummac', this.kardex.mac_nummac)
      console.log('ingreso')
      return this.confirmationReturnService.confirm('');
    } else {

    }

  }

  copiarPartes(): void {
    const dialogRef = this.dialog.open(CopyPartsDialog, {
      width: '600px',
      data: {
        almacenes: this.almacenes,
        clientes: this.clientes,
        centros: this.centroCostos,
        tiposDocumento: this.tiposDocumento,
        motivos: this.motivosAll
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        console.log('The dialog was closed');
        this.kardex.detalle_map = result;
        this.kardex.detalle_map.forEach((item, key) => {

          const productoEncontrado = this.productos.find(prod => prod.prd_codprd == item.prd_codprd);

          item.map_impmep = 0;
          item.map_impmev = 0;
          item.map_impmnp = 0;
          item.map_impmnv = 0;
          item.map_cosmep = 0;
          item.map_cosmev = 0;
          item.map_cosmnp = 0;
          item.map_cosmnv = 0;
          item.map_fecupd = new Date();
          item.map_canfac = 0;
          item.map_venfac = 0;
          item.map_salcan = 0;
          item.map_salmna = 0;
          item.map_salmex = 0;
          item.prd_codume = productoEncontrado.UNIDAD;
          item.tipoInventario = productoEncontrado.COD_INVENTARIO;

          if (this.configuracionTecnica.s70_unime2 == 1 && this.indMotivoUM2 == 1) {
            item.ume_codven = this.umSugerida02;
            if (this.umSugerida02 == 'KGS') {
              item.map_canven = item.map_canprd * productoEncontrado.prd_conten;
            }
          }
          // item.ume_codven = productoEncontrado.ume_codctn;
          // item.map_canven = item.map_canprd * productoEncontrado.prd_conten;
          // item.map_canprd = 1;
          item.map_secmap = "01";
          item.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
          item.map_lotmap = "0000000000";
          item.suc_codsuc = "01";
          item.map_glomap = "";

          item.seleccionado = false;

          item.prd_indtal = 0;
          item.prd_indser = 0;
          item.prd_indlot = 0;

          const diasCaducidad = !productoEncontrado.prd_diacad || productoEncontrado.prd_diacad == 0 ? 0 : productoEncontrado.prd_diacad;

          item.tallas_mad.forEach((el, key) => {
            el.seleccionado = false;
            el.mad_impmnp = 0;
            el.mad_impmnv = 0;
            el.mad_impmep = 0;
            el.mad_impmev = 0;

            el.lotes_mlo.forEach((lote, key) => {
              console.log('fecha lote', lote.mlo_fecfab)

              lote.mlo_fecfab = this.kardex.mac_fecmac;
              lote.mlo_fecven = formatDateWithDashLocal(addDaysToDate(lote.mlo_fecfab, diasCaducidad + 1));
              lote.mlo_indsta = "1";
              lote.mlo_impmnp = 0;
              lote.mlo_impmnv = 0;
              lote.mlo_impmep = 0;
              lote.mlo_impmev = 0;

            });
            el.series_mas.forEach((serie, key) => {
              // serie.tal_codtal = "00";
            });
          })

          this.selectRow(item);
        })

        this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

      }


    });
  }

  seleccionarTabla(): void {

    this.tablaSeleccionada = !this.tablaSeleccionada;

  }

  onPaste(event: ClipboardEvent): void {

    if (!this.tablaSeleccionada) return;
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.processPastedData(pastedText);

  }

  processPastedData(pastedText: string): void {

    if (!this.kardex.tdo_codtdo) {
      this._snackBarService.showError('Debe seleccionar un tipo de movimiento', 'Ok');
      return;
    }

    if (!this.kardex.alm_codalm) {
      this._snackBarService.showError('Debe seleccionar un almacén', 'Ok');
      return;
    }

    if (!this.kardex.mmo_codmmo) {
      this._snackBarService.showError('Debe seleccionar un motivo', 'Ok');
      return;
    }

    const rows = pastedText.split('\n').filter(row => row.trim().length > 0);
    const newData: any[] = rows.map(row => {
      const cols = row.split('\t');
      return {
        prd_codprd: cols[0],
        descripcion: cols[1],
        ume_codven: cols[2],
        cantidad: cols[3],
        mlo_numlot: cols[4],
        spr_numser: cols[5],
        map_cosmnp: +cols[6],
        map_cosmep: +cols[7],
        referencia: cols[8],
        codigoISOPais: cols[9]
      };
    });

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    };

    if (newData[0].prd_codprd == "Codigo *") {
      newData.shift();
    }

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    }

    console.log('newData', newData)

    if ((Object.keys(newData[0]).length) != 10) {
      this._snackBarService.showError('Debe de copiar todo el contenido de las 9 filas', 'Ok');
      return
    };

    let existeLote = false;
    let existeSerie = false;

    newData.forEach(item => {
      if (item.mlo_numlot.length > 0) existeLote = true;
      if (item.spr_numser.length > 0) existeSerie = true;
    });

    const agrupado = agrupar(newData, 'prd_codprd');
    const keys = Object.keys(agrupado);

    let primeraAgrupacion = [];

    this.kardex.detalle_map = [];
    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

    this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);

    this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

    keys.forEach(key => {
      const encontrado = agrupado[key].find(item => item.prd_codprd == key);

      const producto = new PartDetalleMap();
      producto.prd_codprd = key.trim();

      console.log('encontrado', encontrado)
      console.log('encontrado.COD_INVENTARIO', encontrado.COD_INVENTARIO)
      console.log('productos', this.productos)

      const productoEncontrado = this.productos.find(item => item.prd_codprd.trim() == key.trim());

      if(!productoEncontrado) {
        this._snackBarService.showError(`Producto ${key} no encontrado en la base de datos`, 'Ok')
        return;
      }

      console.log('producto encontrado', productoEncontrado);

      if (!existeLote && !existeSerie) {
        producto.map_canprd = encontrado.cantidad;
        this._snackBarService.showError(`Producto: ${producto.prd_codprd} no posee lote, ni serie`, 'Ok');
      }
      // producto.ume_codven = encontrado.ume_codven;
      producto.prd_codume = productoEncontrado.UNIDAD;
      producto.map_cosmnp = encontrado.map_cosmnp;
      producto.map_cosmep = encontrado.map_cosmep;
      producto.prd_indlot = productoEncontrado.prd_indlot;
      producto.prd_indser = productoEncontrado.prd_indser;
      producto.prd_indtal = productoEncontrado.prd_indtal;
      if (this.configuracionTecnica.s70_unime2 == 1 && this.indMotivoUM2 == 1) {
        producto.ume_codven = this.umSugerida02;
        if (this.umSugerida02 == 'KGS') {
          producto.map_canven = producto.map_canprd * productoEncontrado.prd_conten;
        }
      }
      // producto.ume_codven = productoEncontrado.ume_codctn;
      // producto.map_canven = producto.map_canprd * productoEncontrado.prd_conten;
      producto.tipoInventario = productoEncontrado.COD_INVENTARIO;

      producto.tallas_mad[0].lotes_mlo;

      if (existeLote && this.configuracionTecnica.s70_indlot == 1 && producto.prd_indlot == 1) {
        let cantidadTotal = 0;
        console.log('agrupado[key]', agrupado[key])
        agrupado[key].forEach(item => {

          const lote = new DetalleLote();
          lote.codigoISOPais = item.codigoISOPais;

          lote.mlo_numlot = lote.codigoISOPais ? `${item.mlo_numlot}${lote.codigoISOPais}` : item.mlo_numlot;
          lote.mlo_canprd = item.cantidad;
          lote.mlo_glomlo = item.referencia;
          lote.mlo_fecfab = this.kardex.mac_fecmac;

          const diasCaducidad = !productoEncontrado.prd_diacad || productoEncontrado.prd_diacad == 0 ? 0 : productoEncontrado.prd_diacad;

          const paisEncontrado = this.paises.find(pais => pais.pai_codiso3 == item.codigoISOPais.trim())
          console.log('pais encontrado', paisEncontrado)
          lote.pai_codpai = paisEncontrado.PAI_CODPAI;

          lote.mlo_fecven = formatDateWithDashLocal(addDaysToDate(lote.mlo_fecfab, diasCaducidad + 1));
          producto.tallas_mad[0].lotes_mlo.push(lote);
          cantidadTotal += +item.cantidad;

        });
        producto.map_canprd = cantidadTotal;
      }
      this.kardex.detalle_map.push(producto);
      console.log('productoAgregado', producto)

      if (existeSerie && this.configuracionTecnica.s70_indser == 1 && producto.prd_indser == 1) {
        agrupado[key].forEach(item => {
          const serie = new DetalleSerie();
          serie.spr_numser = item.spr_numser;
          producto.tallas_mad[0].series_mas.push(serie);

        });
      }
    });

    this.selectRow(this.kardex.detalle_map[0]);

    this.kardex.detalle_map.forEach((element, key) => {
      element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);
    this.dataSourceLote = fillTable(this.kardex.detalle_map[0].tallas_mad[0].lotes_mlo, this.paginatorLote, this.sortLote);
    this.dataSourceSerie = fillTable(this.kardex.detalle_map[0].tallas_mad[0].series_mas, this.paginatorSerie, this.sortLote);

    this.tablaSeleccionada = false;
    console.log('agrupado', primeraAgrupacion)
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({ year, month, almacen, tipo, numeroParte }) => {
      this.anio = year;
      this.mes = month;
      this.almacen = almacen;
      this.tipo = tipo;
      this.kardex.tdo_codtdo = tipo;
      this.numeroParte = numeroParte;
    })

    this.loadMaestros();

    // this.btnName = this.numeroParte == '0' ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;
    this.numeroParte == '0' ? this.canDeactivateGuard.setShowConfirmationDialog(true) : this.canDeactivateGuard.setShowConfirmationDialog(false);

  }

  loadMaestros(): void {

    this.loaderData = true;

    this.permisos$ = this._configurationService.getUserPermisions().subscribe(
      permisos => {

        this.permiso = permisos.find(item => item.interfaz == this.program);

        if(!this.permiso) {
          this.permiso = new PermisoForm();
        }

        this.motivos$ = this._ventasService.obtenerMotivosMovimiento().subscribe(
          motivos => {

            this.motivos = motivos.filter(item => item.mmo_indsta == '1');
            this.motivosAll = motivos.filter(item => item.mmo_indsta == '1');

            console.log('motivos', this.motivos)
            console.log('motivos', this.motivosAll)

            this.configuracionTecnica$ = this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
              configuracion => {

                this.configuracionTecnica = configuracion[0];
                console.log('configuracion', this.configuracionTecnica);

                if (this.configuracionTecnica.s70_unime2 == 1 && this.indMotivoUM2 == 1) {
                  this.displayedColumns = ['descripcion', 'codigo', 'um'/*, 'cantidad_oc'*/, 'cantidad', 'ume_codven', 'map_canven', 'costo_ume', 'costo_umn', 'imp_me_prod', 'imp_mn_prod', 'glosa'/*, 'lote_ref_pro'*/, 'item', 'sec'];
                } else {
                  this.displayedColumns = ['descripcion', 'codigo', 'um'/*, 'cantidad_oc'*/, 'cantidad', 'costo_ume', 'costo_umn', 'imp_me_prod', 'imp_mn_prod', 'glosa'/*, 'lote_ref_pro'*/, 'item', 'sec'];
                }


                this.tiposDocumento$ = this._almacenService.obtenerTipoDocumentoMov().subscribe(
                  (tiposDocumento) => {
                    console.log('tipos documento', tiposDocumento)
                    this.todosTiposDocumento = tiposDocumento.filter(item => item.TDO_INDALM == 1);
                    this.tiposDocumento = tiposDocumento.filter(item => item.TDO_INDALM == 1);
                    this.tiposDocumento2 = tiposDocumento.filter(item => item.TDO_INDALM == 1);
                    this.tiposDocumentoGuia = tiposDocumento.filter(item => item.TDO_INDSTA == 1 && (item.TDO_CODOFI == '50' || item.TDO_CODOFI == '52'));

                    this.almacenes$ = this._almacenService.obtenerAlamacenes().subscribe(
                      almacenes => {
                        this.almacenes = almacenes.filter(item => item.ALM_INDSTA == '1');
                        // filtrar almacenes por tienda
                        if (this.establecimiento && this.establecimiento.trim() != '') {
                          this.almacenes = this.almacenes.filter(item => item.TIE_CODTIE == this.establecimiento);
                        }
                        console.log('almacenes', almacenes)
                        this.tiposCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.kardex.mac_fecmac)).subscribe(
                          tc => {
                            this.kardex.mac_tipcam = tc[0].tipcam;
                            console.log('tipo de cambio', this.kardex.mac_tipcam)

                            this.ordenes$ = this._ordenCompraService.obtenerOrdenesCompra().subscribe(
                              ordenes => {
                                this.ordenes = ordenes;
                                this.ordenesTodos = ordenes;
                                this.ordenes.forEach(item => {
                                  item['description02'] = `${item.ocm_numocm} | ${item.ocm_fecocm} | ${item.cliente.cli_nomcli} `
                                })
                                console.log('ordenes', ordenes)

                                this.auxiliares$ = this._clienteService.obtenerClientes().subscribe(
                                  clientes => {

                                    this.clientes = clientes;
                                    console.log('clientes', clientes)

                                    this.invoices$ = this._almacenService.obtenerInvoices().subscribe(
                                      invoices => {
                                        this.invoices = invoices;

                                        this.liquidaciones$ = this._almacenService.obtenerLiquidaciones().subscribe(
                                          liquidaciones => {
                                            this.liquidaciones = liquidaciones;

                                            this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
                                              centros => {
                                                console.log('centros costo', centros)
                                                this.centroCostos = centros;

                                                this.productos$ = this._productoService.obtenerProductos().subscribe(
                                                  productos => {
                                                    console.log('productos', productos)
                                                    this.productos = productos;

                                                    this.choferes$ = this._almacenService.listarChoferes().subscribe(
                                                      conductores => {
                                                        this.conductores = conductores;
                                                        console.log('choferes', this.conductores)

                                                        this.unidades$ = this._ventasService.listarUnidadesTransporte().subscribe(
                                                          unidades => {
                                                            this.unidades = unidades;
                                                            this.unidadesAll = unidades;
                                                            console.log('=== unidades ===', this.unidades)

                                                            this.transportistas$ = this._almacenService.obtenerTransportistas().subscribe(
                                                              transportistas => {
                                                                this.transportistas = transportistas;

                                                                this.lugaresDespachoCia$ = this._lugarDespachoCiaService.obtenerLugaresDespachoCIA().subscribe(
                                                                  lugaresDespachoCia => {
                                                                    this.lugaresDespachoCIA = lugaresDespachoCia;
                                                                    console.log('lugaresDespachoCIA', this.lugaresDespachoCIA)

                                                                    this.tiposDocumentoReferencia$ = this._tipoDocumentoReferenciaService.obtenerTiposDocumentoReferencia().subscribe(
                                                                      tiposDocumentoRef => {
                                                                        this.tiposDocumentoRef = tiposDocumentoRef;
                                                                        console.log('tipos doc ref', this.tiposDocumentoRef)

                                                                        this.series$ = this._almacenService.listarSerieDocumentos(this.kardex.detalle_guia[0].tdo_codtdo).subscribe(
                                                                          series => {

                                                                            this.unidadesMedida$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                                                                              unidadesMedida => {

                                                                                this.unidadesMedida = unidadesMedida;
                                                                                console.log('unidades de medida', this.unidadesMedida)

                                                                                this.pedidos$ = this._pedidoService.obtenerPedidos('01').subscribe(
                                                                                  pedidos => {

                                                                                    this.pedidos = this.establecimiento != '' ? pedidos.filter(pedido => pedido.TIE_CODTIE.trim() == this.establecimiento) : pedidos;
                                                                                    this.pedidosTodos = this.establecimiento != '' ? pedidos.filter(pedido => pedido.TIE_CODTIE.trim() == this.establecimiento) : pedidos;

                                                                                    console.log('pedidos', this.pedidos)
                                                                                    this.agenciasTransporte$ = this._clienteService.listarTipoServicioCliente().subscribe(
                                                                                      agencias => {
                                                                                        this.agenciaTransportes = agencias;
                                                                                        console.log('agencias transporte', this.agenciaTransportes)

                                                                                        this.aeropuertos$ = this._sunatService.obtenerAeropuertos().subscribe(
                                                                                          aeropuertos => {

                                                                                            this.aeropuertos = aeropuertos;

                                                                                            this.paises$ = this._generalService.listarPaises().subscribe(
                                                                                              paises => {

                                                                                                this.paises = paises;

                                                                                                this.ubigeosSunat$ = this._generalService.listarUbigeos().subscribe(
                                                                                                  ubigeosSunat => {
                                                                                                    this.ubigeosSunat = ubigeosSunat;
                                                                                                    console.log('ubigeos sunat', this.ubigeosSunat)
                                                                                                    this._serieDocumentoService.obtenerSeriesDocumento().subscribe(
                                                                                                      seriesFac => {
                                                                                                        this.series = seriesFac.filter(item => item.tipo_documento == 'GRE');
                                                                                                        // filtrar series por tienda
                                                                                                        if (this.establecimiento && this.establecimiento.trim() != '') {
                                                                                                          this.series = this.series.filter(item => item.tie_codtie == this.establecimiento);
                                                                                                        }
                                                                                                        console.log('serie fac', series)

                                                                                                        // Seleccionar de manera automatica el numero de serie y nro de documento.
                                                                                                        // if (series && series.length > 0) {
                                                                                                        //   this.kardex.detalle_guia[0].sdo_codsdo = `${series[0].sdo_codsdo}`;
                                                                                                        //   this.kardex.detalle_guia[0].grc_numdoc = this.kardex.detalle_guia[0].sdo_codsdo;
                                                                                                        // }

                                                                                                        this.loaderData = false;

                                                                                                        if (this.numeroParte != '0') {
                                                                                                          this.obtenerMovimiento();
                                                                                                          return;
                                                                                                        }

                                                                                                      },
                                                                                                      error => {
                                                                                                        this._snackBarService.showError(error.error.message, 'Ok');
                                                                                                        this.loaderData = false;
                                                                                                      }
                                                                                                    )
                                                                                                  },
                                                                                                  error => {
                                                                                                    this._snackBarService.showError(error.error.message, 'Ok');
                                                                                                    this.loaderData = false;
                                                                                                  }
                                                                                                )


                                                                                              },
                                                                                              error => {
                                                                                                this._snackBarService.showError(error.error.message, 'Ok');
                                                                                                this.loaderData = false;
                                                                                              }
                                                                                            )


                                                                                          },
                                                                                          error => {
                                                                                            this._snackBarService.showError(error.error.message, 'Ok');
                                                                                            this.loaderData = false;
                                                                                          }
                                                                                        )
                                                                                      },
                                                                                      error => {
                                                                                        this._snackBarService.showError(error.error.message, 'Ok');
                                                                                        this.loaderData = false;
                                                                                      }
                                                                                    )

                                                                                  },
                                                                                  error => {
                                                                                    this._snackBarService.showError(error.error.message, 'Ok');
                                                                                    this.loaderData = false;
                                                                                  }
                                                                                )


                                                                              },
                                                                              error => {
                                                                                this._snackBarService.showError(error.error.message, 'Ok');
                                                                                this.loaderData = false;
                                                                              }
                                                                            )
                                                                          },
                                                                          error => {
                                                                            this._snackBarService.showError(error.error.message, 'Ok');
                                                                            this.loaderData = false;
                                                                          }
                                                                        )
                                                                      },
                                                                      error => {
                                                                        this._snackBarService.showError(error.error.message, 'Ok');
                                                                        this.loaderData = false;
                                                                      }
                                                                    )

                                                                  },
                                                                  error => {
                                                                    this._snackBarService.showError(error.error.message, 'Ok');
                                                                    this.loaderData = false;
                                                                  }
                                                                )

                                                              },
                                                              error => {
                                                                this._snackBarService.showError(error.error.message, 'Ok');
                                                                this.loaderData = false;
                                                              }
                                                            )

                                                          },
                                                          error => {
                                                            this.loaderData = false;

                                                          }
                                                        )
                                                      },
                                                      error => {
                                                        this.loaderData = false;

                                                      }
                                                    )

                                                  },
                                                  error => {
                                                    this.loaderData = false;

                                                  }
                                                )
                                              },
                                              error => {
                                                this.loaderData = false;

                                              }
                                            )
                                          },
                                          error => {
                                            this.loaderData = false;

                                          }
                                        )
                                      },
                                      error => {
                                        this.loaderData = false;

                                      }
                                    )
                                  },
                                  error => {
                                    this.loaderData = false;

                                  }
                                )
                              },
                              error => {
                                this.loaderData = false;

                              }
                            )
                          },
                          error => {
                            this.loaderData = false;

                          }
                        )
                      },
                      error => {
                        this.loaderData = false;

                      }
                    )
                  },
                  error => {
                    this.loaderData = false;

                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener configuracion tecnica', 'Ok')
              }
            )

          },
          error => {
            this._snackBarService.showError('Error al obtener motivos de movimiento', 'Ok');
          }
        )

      },
      error => {
        this._snackBarService.showError('Error al listar permisos', 'Ok')
        this.loaderData = false;
      }
    )



  }

  seleccionarSerie(): void {
    this.kardex.detalle_guia[0].grc_numdoc = this.kardex.detalle_guia[0].sdo_codsdo;

    this.correlativoDoc$ = this._ventasService.obtenerNroDocGuia('GRE', this.kardex.detalle_guia[0].sdo_codsdo).subscribe(
      correlativo => {
        console.log('correlativo', correlativo)
        this.kardex.detalle_guia[0].grc_numdoc = correlativo[0].nro_doc;

        if (this.configuracionTecnica.s70_gloaut == 1) {
          if (this.kardex.mmo_codmmo == '0171') {
            this.kardex.mac_numre1 = correlativo[0].nro_doc.slice(0, 4) + (correlativo[0].nro_doc.slice(4, 14)).slice(2, 10);
          }
        }

        setTimeout(() => {
          this.inputNumeroDocGuia.nativeElement.focus();
        });
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
      }
    )

  }

  obtenerMovimiento() {

    this._kardexService.obtenerKardex(this.anio, this.mes, this.almacen, this.tipo, this.numeroParte).subscribe(
      response => {

        console.log('MOVIMIENTO', response)
        if(!response.cliente) {
          this.kardex.cliente = { cli_nomcli: '' }
        }
        this.kardex = response;

        if (this.kardex.detalle_map == null) {
          this.kardex.detalle_map = [];
        }

        if (this.kardex.detalle_map) {
          if (this.kardex.detalle_map.length > 0) {
            const producto = this.productos.find(prod => prod.prd_codprd.trim() == this.kardex.detalle_map[0].prd_codprd.trim());

            this.kardex.detalle_map.forEach(itemDetalle => {
              itemDetalle.tipoInventario = producto.COD_INVENTARIO;
            })
          }
        }


        this.seleccionarAlmacen(this.kardex.alm_codalm);
        this.seleccionarMotivo(this.kardex.mmo_codmmo);

        if (response.detalle_guia) {

          this.kardex.detalle_guia = response.detalle_guia;
          console.log('detalleguia', this.kardex.detalle_guia)
          console.log('chofer', this.kardex.detalle_guia[0].cho_codcho)

          const motivo = this.motivos.find(item => item.mmo_codmmo == this.kardex.mmo_codmmo);
          this.seleccionarResponsable(this.kardex.cli_codcli);
          this.seleccionarAuxiliarPuntoPartida();

          if (motivo.mmo_indgui == 1) {


            this.seleccionarTransportista();
            this.seleccionarDestinatario();

          }

        } else {

          const motivo = this.motivos.find(item => item.mmo_codmmo == this.kardex.mmo_codmmo);
          console.log('motivo desde movimiento', motivo)
          if (motivo.mmo_indgui == 1) {

            this.kardex.detalle_guia = [new PartDetalleGuia()];
            this.seleccionarTransportista();

          }

        }

        localStorage.setItem('tipo-cambio', response.mac_tipcam);

        this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort)

        if (this.kardex.detalle_map) {
          // seleccionar el primer item
          if (this.kardex.detalle_map.length > 0)
            this.selectRow(this.kardex.detalle_map[0]);
          // Establecer unidad de medida de poducto
          this.kardex.detalle_map.forEach(item => {
            item.prd_codume = this.productos.find(producto => producto.prd_codprd == item.prd_codprd).UNIDAD
          })
        }

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )

  }

  seleccionarAgenciasTransportista() {
    if (!this.kardex.detalle_guia[0].cli_agetra) {
      this.seleccionarDestinatario();
      return;
    }

    this.lugaresDespacho$ = this._ventasService.obtenerLugaresDespacho(this.kardex.detalle_guia[0].cli_agetra).subscribe(
      lugaresDespacho => {
        this.lugaresDespacho = lugaresDespacho;
        console.log()
        if (this.lugaresDespacho.length == 0) {
          const destinatario: Cliente = this.clientes.find(cliente => cliente.cli_codcli == this.kardex.detalle_guia[0].cli_codcli);
          console.log(destinatario.cli_dircli)
          this.kardex.detalle_guia[0].grc_lugent = destinatario.cli_dircli;
        }
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
        this.loaderData = false;
      }
    )

  }


  verPDF(): void {
    this.guiaRemisionPDF$ = this._guiaService.mostrarGuiaTexto(this.kardex.detalle_guia[0].grc_numdoc).subscribe(
      guia => {
        console.log('guia', guia)
        const headers = {
          ruc: this._configurationService.obtenerClienteId(),
          logo: this.logo,
          motivo: this.kardex.mmo_codmmo,
          indExportacion: this.indMotivoExportacion,
          nomcia: this._configurationService.obtenerNombreCompaniaCliente(),
          dircia: this.dircia,
          modalidadTraslado: this.modalidadTraslado
        }
        pdfBuilder.verPDFGuiaRemision(headers, guia);
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
      }
    )
  }

  verFormatoGuia2(): void {

    if (!this.kardex.mac_nummac) {
      return;
    }

    if (this.indGuiaRemision == 0) {
      return;
    }

    this.guiaRemisionPDF$ = this._guiaService.mostrarGuiaTexto(this.kardex.detalle_guia[0].grc_numdoc).subscribe(
      guia => {
        console.log('guia', guia)
        const headers = {
          ruc: this._configurationService.obtenerClienteId(),
          logo: this.logo,
          motivo: this.kardex.mmo_codmmo,
          indExportacion: this.indMotivoExportacion,
          nomcia: this._configurationService.obtenerNombreCompaniaCliente(),
          dircia: this.dircia,
          modalidadTraslado: this.modalidadTraslado,
          glosa2: this.kardex.mac_gloma2,
          ubicacion2: this.ubicacionAdicional
        }
        pdfBuilder.verPDFGuiaRemisionV2(headers, guia);
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
      }
    )
  }

  seleccionarPais(row): void {
    // console.log('pais', paisSeleccionado)
    if (!row.pai_codpai) return;

    const paisSeleccionado = this.paises.find(pais => pais.PAI_CODPAI == row.pai_codpai)
    console.log('pais', paisSeleccionado)

    row.mlo_numlot = `${row.mlo_numlot ? row.mlo_numlot.substring(0, 20) : ''}${paisSeleccionado.pai_codiso3 ? paisSeleccionado.pai_codiso3 : ''}`

  }

  seleccionarUbicacionAdicional(): void {

    if (!this.ubicacionAdicional) {
      return;
    }

    return;

    const ubicacion = this.lugaresDespachoCIA.find(item => item.LDC_CODLDC == this.ubicacionAdicional);

    this.kardex.mac_gloma2 = ubicacion.LDC_DIRLDC + '\n';
    console.log('ubicacion', ubicacion)
  }

  seleccionarTipo(): void {
    if (this.isMobile || this.isTablet) {
      setTimeout(() => {
        this.inputFecha.nativeElement.focus();
      });
      return;
    }

    setTimeout(() => {
      this.almacenSelectComponent.onFocus();
    });

    if (this.kardex.tdo_codtdo == 'ING') {
      this.motivos = this.motivosAll.filter(item => item.mmo_indmov == 'I');
      if (this.motivos.length == 0) this.kardex.mmo_codmmo = null;
    } else {
      this.motivos = this.motivosAll.filter(item => item.mmo_indmov == 'S' || item.mmo_indmov == 'T');
      if (this.motivos.length == 0) this.kardex.mmo_codmmo = null;
    }

    console.log('motivos', this.motivos)

  }

  seleccionarTipoDoc01(): void {
    console.log('kardex.mac_tdore1', this.kardex.mac_tdore1)
    if (this.configuracionTecnica.s70_verref1) {
      setTimeout(() => {
        this.inputNumDocRef01.nativeElement.focus();
      });
    }

  }

  seleccionarTipoDoc02(): void {
    console.log('kardex.mac_tdore2', this.kardex.mac_tdore2)
    if (this.configuracionTecnica.s70_verref2) {
      setTimeout(() => {
        this.inputNumDocRef02.nativeElement.focus();
      });
    }
  }

  seleccionarAlmacen(almacen): void {

    if (!almacen) {
      this.motivos = [];
      this.seleccionarMotivo(null);
      this.kardex.mmo_codmmo = null;
      return;
    }

    if (this.isMobile || this.isTablet) {
      setTimeout(() => {
        this.motivoSelectComponent.onFocus();

      });
    } else {
      setTimeout(() => {
        this.inputFecha.nativeElement.focus();
      });
    }

    if (this.configuracionTecnica.s70_motalm == 0) {
      return;
    }

    if (this.configuracionTecnica.s70_motalm == 1) {
      this.motivos$ = this._ventasService.obtenerMotivosMovimiento({
        params: [
          {
            param: 'almacen',
            value: almacen
          },
          {
            param: 'tipodocumento',
            value: this.kardex.tdo_codtdo
          },
        ]
      }).subscribe(
        motivos => {
          console.log('motivos', motivos)
          this.motivos = motivos.filter(item => item.mmo_indsta == '1');
          // this.motivosAll = motivos.filter(item => item.mmo_indsta == '1');

          if (this.kardex.tdo_codtdo == 'ING') {
            this.motivos = this.motivos.filter(item => item.mmo_indmov == 'I' || item.mmo_indmov == 'T');
          } else {
            this.motivos = this.motivos.filter(item => item.mmo_indmov == 'S' || item.mmo_indmov == 'T');
          }

          if (this.motivos.length == 0) {
            this.seleccionarMotivo(null);
            this.kardex.mmo_codmmo = null;
          }

        },
        error => {
          this._snackBarService.showError('Error al obtener motivos de movimiento', 'Ok');

        }
      )
    }

  }

  seleccionarMotivo(motivoId): void {
    console.log('motivoId', motivoId);
    if (!motivoId) {
      this.indOrdCompra = 0;
      this.indReqMat = 0;
      this.indLiqProd = 0;
      this.indOrdProd = 0;
      this.indPedido = 0;
      this.indTransferencia = 0;
      this.indRegInvoice = 0;
      this.indGuiaRemision = 0;
      this.indOrdIntercambio = 0;
      return
    };

    const motivo = this.motivos.find(item => item.mmo_codmmo == motivoId);
    this.motivoSeleccionado = motivo;

    console.log('motivo', motivo)

    this.indOrdCompra = motivo.mmo_indocm;
    if (this.indOrdCompra != 1) this.kardex.ocm_numocm = null;

    this.indReqMat = motivo.mmo_indrma;
    if (this.indReqMat != 1) this.kardex.rma_numrma = null;

    this.indLiqProd = motivo.mmo_indpro;
    if (this.indLiqProd != 1) this.kardex.lfa_numlfa = null;

    this.indOrdProd = motivo.mmo_indopr;
    if (this.indOrdProd != 1) this.kardex.otr_numotr = null;

    this.indPedido = motivo.mmo_pedido;
    if (this.indPedido != 1) this.kardex.lfa_numlfa = null;

    this.indTransferencia = motivo.mmo_indtra;
    this.indMotivoExportacion = motivo.mmo_indexp;

    this.indObligatorioCosto = motivo.mmo_oblcos;

    this.indMotivoUM2 = motivo.mmo_indume2;
    this.umSugerida02 = motivo.ume_codume2;

    console.log('this.indTransferencia', this.indTransferencia);


    this.indVal = motivo.mmo_indval;
    // if (this.indVal != 1) this.kardex.lfa_numlfa = null;

    this.indRegInvoice = motivo.mmo_reginv;
    this.indOrdIntercambio = motivo.mmo_indoin;
    this.indGuiaRemision = motivo.mmo_indgui;

    if (this.indGuiaRemision == 1) {
      if (this.numeroParte == '0') {
        this.kardex.detalle_guia = [new PartDetalleGuia()];
        this.kardex.detalle_guia[0].grc_fecdoc = this.kardex.mac_fecmac;
        this.kardex.detalle_guia[0].grc_fectra = this.kardex.mac_fecmac;
      }


    }
    if (motivo.mmo_indtra == 1 && this.tipo == 'SAL') {

      this.kardex.tdo_coddes = this.kardex.tdo_codtdo == 'SAL' ? 'ING' : 'SALIDA';
      this.kardex.ano_coddes = this.anioPeriodo;
      this.kardex.mes_coddes = this.mesPeriodo;

    }

    if (motivo.mmo_indcbo == 1 && this.tipo == 'ING') {

      this.kardex.tdo_codcom = 'ING';

    }

    setTimeout(() => {
      this.responsableSelectComponent.onFocus();
    });

    const filtrarTipDocReferencia = this.tiposDocumentoRef.filter(item => item.MMO_CODMMO == this.kardex.mmo_codmmo);

    // Validar si es obligatorio ingresar el campo tipo documento referencia (01)
    (filtrarTipDocReferencia.length > 0) ? this.esObligatorioTipoDocRef = true : this.esObligatorioTipoDocRef = false;

    console.log('filtrarTipDocReferencia', filtrarTipDocReferencia)
    //todo: VALIDAR PARA DESPUES
    filtrarTipDocReferencia.length > 0 ? this.tiposDocumento = filtrarTipDocReferencia : this.tiposDocumento = this.todosTiposDocumento;

    if (this.configuracionTecnica.s70_unime2 == 1 && this.indMotivoUM2 == 1) {
      this.displayedColumns = ['descripcion', 'codigo', 'um'/*, 'cantidad_oc'*/, 'cantidad', 'ume_codven', 'map_canven', 'costo_ume', 'costo_umn', 'imp_me_prod', 'imp_mn_prod', 'glosa'/*, 'lote_ref_pro'*/, 'item', 'sec'];
    } else {
      this.displayedColumns = ['descripcion', 'codigo', 'um'/*, 'cantidad_oc'*/, 'cantidad', 'costo_ume', 'costo_umn', 'imp_me_prod', 'imp_mn_prod', 'glosa'/*, 'lote_ref_pro'*/, 'item', 'sec'];
    }

  }

  seleccionarOrdenCompra(): void {
    // Autocompletar auxiliar cuando se selecciona orden de compra
    if (!this.kardex.ocm_numocm) return;

    const ordenCompra = this.ordenesTodos.find(item => item.ocm_numocm == this.kardex.ocm_numocm)
    this.kardex.cli_codcli = ordenCompra.cli_codcli;
  }

  seleccionarPedido(): void {
    // Autocompletar auxiliar cuando se selecciona pedido
    if (!this.kardex.pcc_numpcc) return;

    const pedido = this.pedidosTodos.find(item => item.PCC_NUMPCC == this.kardex.pcc_numpcc)
    this.kardex.cli_codcli = pedido.CLI_CODCLI;
  }

  seleccionarResponsable(codigo): void {

    this.kardex.cli_codcli = codigo;
    // Filtrar ordenes por auxiliar
    if (this.kardex.cli_codcli) {
      this.ordenes = this.ordenesTodos.filter(item => item.cli_codcli == this.kardex.cli_codcli)
      this.pedidos = this.pedidosTodos.filter(item => item.CLI_CODCLI == this.kardex.cli_codcli)
    } else {
      this.ordenes = this.ordenesTodos;
      this.pedidos = this.pedidosTodos;
    }


    if (!this.kardex.cli_codcli) return;

    if (this.configuracionTecnica.s70_verref1 == 1) {
      setTimeout(() => {
        this.tipoDocRef01SelectComponent.onFocus();
      });
    }

    if (this.kardex.mmo_codmmo == '0171') {

      if (!this.kardex.detalle_guia[0].cli_codppt) {
        this.kardex.detalle_guia[0].cli_codppt = this.kardex.cli_codcli;
        this.seleccionarAuxiliarPuntoPartida();
      }
    }


    console.log('this.configuracionTecnica.s70_gloaut', this.configuracionTecnica.s70_gloaut)
    console.log('this.indMotivoExportacion', this.indMotivoExportacion)
    if (this.configuracionTecnica.s70_gloaut == 1) {

      if (this.kardex.mmo_codmmo == '0171') {
        const auxiliar = this.clientes.find(cliente => cliente.cli_codcli == this.kardex.cli_codcli);
        console.log('auxiliar', auxiliar)
        const labelTipDoc = auxiliar.did_coddid == '001' ? 'RUC' : 'DNI';

        if (this.rucEmpresa == '20605493603') {
          this.kardex.mac_glomac = `PRODUCTOR ${auxiliar.cli_nomcli} \nCLP: ${auxiliar.cli_regnac} \n${labelTipDoc}: ${auxiliar.cli_codcli}`;
        }
      }
    }

    const cliente = this.clientes.find(item => this.kardex.cli_codcli == item.cli_codcli);

    if (cliente.ubi_codubi) {
      console.log('cliente', cliente)
      this._ventasService.listarUbicacionSunat(cliente.ubi_codubi).subscribe(
        response => {

          this.kardex.mac_gloma2 = response[0].ubs_descri + '\n';

        },
        error => {
          this._snackBarService.showError(error.error, 'OK');
        }
      )
    }

  }

  seleccionarAuxiliarPuntoPartida(): void {
    this.lugaresDespacho$ = this._ventasService.obtenerLugaresDespacho(this.kardex.detalle_guia[0].cli_codppt).subscribe(
      lugaresDespacho => {
        this.lugaresDespacho = lugaresDespacho;
      },
      error => {
        this._snackBarService.showError(error.error, 'OK');
      }
    )
  }

  seleccionarChofer(chofer_id): void {
    console.log('chofer', chofer_id)
    if (!chofer_id) return;

    const conductor = this.conductores.find(item => item.CHO_CODCHO == chofer_id);
    this.kardex.detalle_guia[0].grc_nrolic = conductor.CHO_NROLIC;
    this.kardex.detalle_guia[0].grc_nomcho = conductor.CHO_NOMBRE;

  }

  seleccionarUnidTransporte(unidad_id): void {

    if (!unidad_id) return;

    const unidad = this.unidades.find(item => item.UTR_CODUTR == unidad_id);
    console.log('unidad', unidad)
    this.kardex.detalle_guia[0].grc_plaund = unidad.UTR_PLAUTR;
    this.kardex.detalle_guia[0].grc_nrocer = unidad.UTR_NROCER;
    this.kardex.detalle_guia[0].grc_marund = unidad.UTR_MARUTR;
    this.kardex.detalle_guia[0].grc_undtra = unidad.UTR_DESCRI.slice(0, 20);
  }

  seleccionarSerieGuia(serie): void {

    if (!serie) return;

    this.correlativoGuia$ = this._almacenService.obtenerCorrelativoGuia(serie).subscribe(
      response => this.kardex.detalle_guia[0].grc_numdoc = response[0].nro_doc,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  agregarProductoMultiple(): void {

    if (!this.kardex.alm_codalm) {
      this._snackBarService.showError('Debe seleccionar un almacen', 'Ok');
      return;
    }

    if (!this.kardex.mmo_codmmo) {
      this._snackBarService.showError('Debe seleccionar un motivo', 'Ok');
      return;
    }

    if (!this.kardex.tdo_codtdo) {
      this._snackBarService.showError('Debe seleccionar un tipo documento **', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(ProductoMultipleDialog, {
      width: '400px',
      data: {
        products: this.productos,
        almacen: this.kardex.alm_codalm,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('resultssss', result)
      result.forEach(item => {
        console.log('item', item)
        const producto = new PartDetalleMap();
        producto.prd_codprd = item.prd_codprd;
        producto.prd_codume = item.UNIDAD;

        console.log('producto.prd_codprd', producto.prd_codprd)
        const product = this.productos.find(prod => prod.prd_codprd == producto.prd_codprd);
        console.log('product', product)
        producto.prd_indlot = product.prd_indlot;
        producto.prd_indser = product.prd_indser;
        producto.prd_indtal = product.prd_indtal;
        producto.tipoInventario = product.COD_INVENTARIO;

        this.selectRow(producto);

        this.kardex.detalle_map.push(producto);

      })

      this.kardex.detalle_map.forEach((element, key) => {
        element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
      })

      this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

      this.calcularTotales();

    })
  }

  agregarProductoModal(): void {

    if (!this.kardex.alm_codalm) {
      this._snackBarService.showError('Debe seleccionar un almacen', 'Ok');
      return;
    }

    if (!this.kardex.mmo_codmmo) {
      this._snackBarService.showError('Debe seleccionar un motivo', 'Ok');
      return;
    }

    if (!this.kardex.tdo_codtdo) {
      this._snackBarService.showError('Debe seleccionar un tipo documento **', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(AddProductDialog, {
      width: '400px',
      data: {
        products: this.productos,
        almacen: this.kardex.alm_codalm,
        canAddDiscount: false,
        canAddPrice: false,
        canAddCost: this.indVal == 1 ? true : false,
        tasaCambio: this.kardex.mac_tipcam
      } as AddProductDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const producto = new PartDetalleMap();
        producto.prd_codprd = result.product;
        producto.map_canprd = result.cantidad;
        producto.map_cosmep = result.costoME;
        producto.map_cosmnp = result.costoMN;
        producto.prd_codume = result.um

        const product = this.productos.find(item => item.prd_codprd == producto.prd_codprd);

        producto.prd_indlot = product.prd_indlot;
        producto.prd_indser = product.prd_indser;
        producto.prd_indtal = product.prd_indtal;
        producto.tipoInventario = product.COD_INVENTARIO;

        this.selectRow(producto);

        this.kardex.detalle_map.push(producto);

        this.kardex.detalle_map.forEach((element, key) => {
          element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
        })

        this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

        this.calcularTotales();
      }

    });
  }

  agregarProducto(): void {

    if (!this.kardex.alm_codalm) {
      this._snackBarService.showError('Debe seleccionar un almacen', 'Ok');
      return;
    }

    if (!this.kardex.mmo_codmmo) {
      this._snackBarService.showError('Debe seleccionar un motivo', 'Ok');
      return;
    }

    if (!this.kardex.tdo_codtdo) {
      this._snackBarService.showError('Debe seleccionar un tipo documento ***', 'Ok');
      return;
    }

    const producto = new PartDetalleMap();

    this.selectRow(producto);

    this.kardex.detalle_map.push(producto);

    this.kardex.detalle_map.forEach((element, key) => {
      element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

    this.calcularTotales();

  }

  eliminarProducto(): void {

    let row = this.kardex.detalle_map.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.kardex.detalle_map.length > 0) row = this.kardex.detalle_map[0];

    let details = [];
    this.kardex.detalle_map.forEach(element => {
      if (element.map_cormap != row.map_cormap) {
        details.push(element)
      }
    });

    this.kardex.detalle_map = details;

    this.kardex.detalle_map.forEach((element, key) => {
      console.log('ids', key)
      element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);
    this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);
    this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

    this.selected = null;

  }

  insertarProducto(): void {
    this.dataSource = fillTable([], this.paginator, this.sort);

    const producto = new PartDetalleMap();

    let i = 0;

    if (this.selected) {

      this.kardex.detalle_map.forEach((item, index) => {
        if (item.map_cormap == this.selected.map_cormap) i = index;
      })

      this.kardex.detalle_map.splice(i, 0, producto);

    } else {

      this.kardex.detalle_map.push(producto);
      // this.kardex.detalle_map = this.kardex.detalle_map.reverse();
    }

    this.kardex.detalle_map.forEach((element, key) => {
      element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    console.log('detalle', this.kardex.detalle_map)

    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);
    console.log('detalle', this.kardex.detalle_map)
    this.calcularTotales();
  }

  completarNroDoc(): void {
    const completado = this.completarDigitosNumDoc(this.kardex.detalle_guia[0].grc_numdoc.slice(4));

    this.kardex.detalle_guia[0].grc_numdoc = this.kardex.detalle_guia[0].sdo_codsdo + completado;
  }

  completarDigitosNumDoc(numdoc: string): string {
    if (numdoc.length < 10) {
      const digitosFaltantes = 10 - numdoc.length;

      for (let index = 0; index < digitosFaltantes; index++) {
        numdoc = '0' + numdoc;
      }

      return numdoc;
    }

    return numdoc;
  }

  establecerCantidad(row: PartDetalleMap): void {
    console.log('mac_tipcam')

    if (this.configuracionTecnica.s70_unime2 == 1 && this.indMotivoUM2 == 1 && this.umSugerida02 == 'KGS') {

      const producto = this.productos.find(producto => producto.prd_codprd == row.prd_codprd);
      row.map_canven = row.map_canprd * producto.prd_conten;

    }

    this.kardex.detalle_map.forEach(item => {
      if (item.map_cormap == row.map_cormap) {
        item.map_impmep = item.map_cosmep * row.map_canprd;
        item.map_impmnp = item.map_cosmnp * row.map_canprd;
      }
    })

    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

  }

  establecerCostoMN(row: PartDetalleMap): void {
    console.log('mac_tipcam')
    this.kardex.detalle_map.forEach(item => {
      if (item.map_cormap == row.map_cormap) {
        item.map_cosmep = Number((row.map_cosmnp / this.kardex.mac_tipcam).toFixed(2));
        item.map_impmep = item.map_cosmep * row.map_canprd;
        item.map_impmnp = item.map_cosmnp * row.map_canprd;
      }
    })

    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

  }

  establecerCostoME(row: PartDetalleMap): void {
    this.kardex.detalle_map.forEach(item => {
      if (item.map_cormap == row.map_cormap) {
        row.map_cosmnp = Number((row.map_cosmep * this.kardex.mac_tipcam).toFixed(2));
        item.map_impmep = item.map_cosmep * row.map_canprd;
        item.map_impmnp = item.map_cosmnp * row.map_canprd;
      }
    })

    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);
  }

  establecerCantidadLote(): void {

    let suma = 0
    this.dataSourceLote.data.forEach(item => {
      suma += item.mlo_canprd;
    })

    console.log('suma', suma);

    this.kardex.detalle_map.forEach(item => {

      if (item.prd_codprd == this.selected.prd_codprd) {
        item.map_canprd = suma;

        if (this.configuracionTecnica.s70_unime2 == 1 && this.indMotivoUM2 == 1 && this.umSugerida02 == 'KGS') {
          const producto = this.productos.find(producto => producto.prd_codprd == item.prd_codprd);
          item.map_canven = item.map_canprd * producto.prd_conten;
        }
      }


    })

    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

  }

  seleccionarProducto(productoSeleccionado: PartDetalleMap) {
    this.selectRowTalla(productoSeleccionado.tallas_mad[0]);
    const producto = this.productos.find(item => item.prd_codprd == productoSeleccionado.prd_codprd);

    productoSeleccionado.tipoInventario = producto.COD_INVENTARIO;
    productoSeleccionado.prd_indlot = producto.prd_indlot;
    productoSeleccionado.prd_indser = producto.prd_indser;
    productoSeleccionado.prd_indtal = producto.prd_indtal;

    if (this.configuracionTecnica.s70_unime2 == 1 && this.indMotivoUM2 == 1) {
      productoSeleccionado.ume_codven = this.umSugerida02;

    }

    // productoSeleccionado.ume_codven = producto.ume_codctn;
    console.log('item seleccionado', productoSeleccionado);

    let nroProductosRepetidos = 0;

    this.establecerCantidad(productoSeleccionado);

    this.selectRow(productoSeleccionado)

    this.kardex.detalle_map.forEach(element => {
      element.seleccionado = false

      if (productoSeleccionado.prd_codprd == element.prd_codprd) {
        nroProductosRepetidos++;
      }

      if (nroProductosRepetidos >= 2) {

        element.prd_codprd = null;
        element.ume_codven = null;
        return;

      }
    });

    if (nroProductosRepetidos >= 2) {
      this._snackBarService.showError('El producto ya ha sido seleccionado', 'Ok');
      this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);
      return;
    }

    if (productoSeleccionado == this.selected) {

      const producto = this.productos.find(item => item.prd_codprd == productoSeleccionado.prd_codprd);

      if (producto.prd_indtal == 1) {

        this.selected.prd_indtal = 1;
        this.selectedTalla = null;

        this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);
        this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

        this.selectedTalla.lotes_mlo = [];
        this.selectedTalla.series_mas = [];

        this.kardex.detalle_map.forEach(item => {
          item.tallas_mad[0].lotes_mlo = [];
          item.tallas_mad[0].series_mas = [];
        })
        console.log('indtal', 1);
      }

      if (producto.prd_indtal == 0) {
        this.selected.prd_indtal = 0;
        console.log('selected', this.selected)
        console.log('selectedTalla', this.selectedTalla)

        this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);
        this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

        this.selectedTalla.lotes_mlo = [];
        this.selectedTalla.series_mas = [];

        this.kardex.detalle_map.forEach(item => {
          item.tallas_mad[0].lotes_mlo = [];
          item.tallas_mad[0].series_mas = [];
        })
        console.log('indtal', 0);
      }

      this.dataSourceTalla = fillTable(this.selected.tallas_mad, this.paginatorTalla, this.sortTalla);
    }


    if (true) {

      if (productoSeleccionado.prd_indlot == 1 && this.configuracionTecnica.s70_indlot == 1) {
        console.log('producto seleccionado con Lote', productoSeleccionado)
        if (productoSeleccionado.tallas_mad[0].lotes_mlo.length == 0) {
          this.agregarLote();
        }
      }

      if (productoSeleccionado.prd_indser == 1 && this.configuracionTecnica.s70_indser == 1) {
        console.log('producto seleccionado con serie', productoSeleccionado)
        if (productoSeleccionado.tallas_mad[0].series_mas.length == 0) {
          this.agregarSerie();
        }
      } else {

      }

    }


    this._ventasService.consultaStock('01', this.anioPeriodo, this.mesPeriodo, this.kardex.alm_codalm, productoSeleccionado.prd_codprd).subscribe(
      response => {
        this.stockDisponible = response[0].disponible;
      },
      error => {
        this._snackBarService.showError('Error al consultar stock', 'Ok');
      }
    )

    if (productoSeleccionado.prd_codprd) {
      const producto = this.productos.find(item => item.prd_codprd == productoSeleccionado.prd_codprd);
      productoSeleccionado.prd_codume = producto.UNIDAD;
    }

  }

  agregarTalla(): void {

    console.log('selected', this.selected)

    const producto = this.kardex.detalle_map.find(item => item == this.selected);

    console.log('seleccionado', producto)

    if (!producto) {
      this._snackBarService.showError('Seleccionar un producto', 'Ok');
      return;
    }

    producto.tallas_mad.push(new TallasMad());

    producto.tallas_mad.forEach((element, key) => {
      element.tal_codtal = (key > 9) ? `${key}` : `0${key}`;
    })

    this.dataSourceTalla = fillTable(producto.tallas_mad, this.paginatorTalla, this.sortTalla);

  }

  eliminarTalla(): void {

    let row = this.selected.tallas_mad.find(item => item == this.selectedTalla);

    if (!row && this.selected.tallas_mad.length > 0) row = this.selected.tallas_mad[0];

    let details = [];
    this.selected.tallas_mad.forEach(element => {
      if (element.tal_codtal != row.tal_codtal) {
        details.push(element)
      }
    });

    this.selected.tallas_mad = details;

    this.selected.tallas_mad.forEach((element, key) => {
      element.tal_codtal = (key > 9) ? `${key}` : `0${key}`;
    })

    this.kardex.detalle_map.forEach(element => {
      if (element == this.selected) {
        element.tallas_mad = details
      }
    });

    this.dataSourceTalla = fillTable(details, this.paginatorTalla, this.sortTalla);

    this.selectedTalla = null;

  }

  insertarTalla(): void {
    const talla = new TallasMad();

    let i = 0;

    if (this.selectedTalla) {
      this.selected.tallas_mad.forEach((item, index) => {
        if (item.tal_codtal == this.selectedTalla.tal_codtal) i = index;
      })

      this.selected.tallas_mad.splice(i, 0, talla);

    } else {
      this.selected.tallas_mad.push(talla);
      this.selected.tallas_mad = this.selected.tallas_mad.reverse();
    }

    this.selected.tallas_mad.forEach((element, key) => {
      console.log('ids', key)
      element.tal_codtal = (key > 9) ? `${key}` : `0${key}`;
    })

    this.kardex.detalle_map.forEach(element => {
      if (element == this.selected) {
        element.tallas_mad = this.selected.tallas_mad
      }
    });

    this.dataSourceTalla = fillTable(this.selected.tallas_mad, this.paginator, this.sort);
  }

  seleccionarTalla(value: boolean, tallaSeleccionada: TallasMad) {
    if (value) {

      const producto = this.kardex.detalle_map.find(item => item.seleccionado);

      producto.tallas_mad.forEach(element => element.seleccionado = false);

      tallaSeleccionada.seleccionado = value;

      this.dataSourceLote = fillTable(tallaSeleccionada.lotes_mlo, this.paginatorLote, this.sortLote);
      this.dataSourceSerie = fillTable(tallaSeleccionada.series_mas, this.paginatorSerie, this.sortSerie);
    }
  }

  agregarLote(): void {

    if (this.selected.prd_indlot == 0) {
      return;
    }

    if (!this.selected.prd_codprd) {
      this._snackBarService.showError('Establecer un producto de la fila seleccionada', 'Ok');
      return;
    }

    if (!this.selectedTalla) {
      this._snackBarService.showError('Seleccionar una talla', 'OK');
      return;
    }

    // Creamos una nueva instancia de lote y establecemos los campos de coduse y codcia
    const lote = new DetalleLote();
    lote.mlo_fecfab = this.kardex.mac_fecmac;
    console.log

    // Agregar dias de caducidad para encontrar la fecha de vencimiento de lote
    const productoSeleccionado = this.productos.find(item => item.prd_codprd == this.selected.prd_codprd);
    if (productoSeleccionado.prd_diacad && productoSeleccionado.prd_diacad > 0) {
      lote.mlo_fecven = addDaysToDate(this.kardex.mac_fecmac, productoSeleccionado.prd_diacad);
    }

    lote.mlo_fecven = addDaysToDate(lote.mlo_fecfab, 1);

    lote.mlo_coduse = this._authService.getUsuarioSistema();
    lote.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    lote.tal_codtal = this.selectedTalla.tal_codtal;

    // Generamos codigo de Lote si motivo=0171 almacen=C101 || almacen=C201
    if ((this.kardex.mmo_codmmo == '0171') && this.kardex.tdo_codtdo == 'ING') {
      if (!this.kardex.tdo_codtdo) {
        this._snackBarService.showError('Seleccionar tipo de parte', 'Ok');
        return;
      }

      if (!this.kardex.mac_tdore1) {
        this._snackBarService.showError('Seleccionar tipo de documento referencia', 'Ok');
        return;
      }

      if (!this.kardex.mac_numre1) {
        this._snackBarService.showError('Establecer número de documento referencia', 'Ok');
        return;
      }

      if (this.kardex.mac_numre1.length != 12) {
        this._snackBarService.showError('El número de documento referencia tiene que ser de 12 dígitos', 'Ok');
        return;
      }

      const almacen = this.almacenes.find(item => item.ALM_CODALM == this.kardex.alm_codalm);
      console.log('almacen', almacen)
      console.log('fecha', formatDateClean6Digitos(this.kardex.mac_fecmac))
      if (almacen.ALM_CODANT.trim().length > 0) {
        lote.mlo_numlot = `${!this.establecimiento || this.establecimiento.trim() == '' ? '01' : this.establecimiento}${formatDateClean6Digitos(this.kardex.mac_fecmac)}${this.kardex.mac_numre1}`
      }
    }

    // Obtenemos la instancia de talla seleccionada
    this.selectedTalla.lotes_mlo.push(lote);

    this.selectedTalla.lotes_mlo.forEach((element, key) => {

      element.correlativo = (key > 9) ? `${key}` : `0${key}`;
    })

    this.kardex.detalle_map.forEach(producto => {
      if (producto == this.selected) {
        producto.tallas_mad.forEach(talla => {
          if (talla == this.selectedTalla) {
            talla.lotes_mlo = this.selectedTalla.lotes_mlo;
          }
        })
      }
    });

    this.dataSourceLote = fillTable(this.selectedTalla.lotes_mlo, this.paginatorLote, this.sortLote);

    let totalCantidad = 0;

    this.selectedTalla.lotes_mlo.forEach(lote => {
      totalCantidad = totalCantidad + lote.mlo_canprd;
    });

    this.selected.map_canprd = totalCantidad;
    this.selected.map_impmnp = this.selected.map_canprd * this.selected.map_cosmnp;
    this.selected.map_impmep = this.selected.map_canprd * this.selected.map_cosmep;

    this.calcularTotales();
  }

  agregarLoteMasivo(): void {

    if(this.kardex.tdo_codtdo == 'ING') return;

    if (this.selected.prd_indlot != 1) return;

    if (!this.kardex.alm_codalm) {
      this._snackBarService.showError('Seleccionar almacén', 'Ok');
      return;
    }

    if (!this.selected) {
      this._snackBarService.showError('Seleccionar un producto', 'Ok');
      return;
    }

    if (!this.selected.prd_codprd) {
      this._snackBarService.showError('Seleccionar un producto', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(AddLotesDialog, {
      width: 'auto',
      data: {
        almacenes: this.almacenes,
        productos: this.productos,
        almacen: this.kardex.alm_codalm,
        producto: this.selected.prd_codprd
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result lote masivo', result)
        let cantidadProducto = 0;
        let lotes = [];

        const seleccionados = result.filter(item => item.completed);

        seleccionados.forEach(element => {

          const lote = new DetalleLote();

          lote.mlo_numlot = element.lot_numlot;
          lote.mlo_fecfab = element.lot_feccre;
          lote.mlo_fecven = element.lot_fecven;

          // Agregar dias de caducidad para encontrar la fecha de vencimiento de lote
          const productoSeleccionado = this.productos.find(item => item.prd_codprd == this.selected.prd_codprd);
          if (productoSeleccionado.prd_diacad && productoSeleccionado.prd_diacad > 0) {
            lote.mlo_fecven = addDaysToDate(this.kardex.mac_fecmac, productoSeleccionado.prd_diacad);
          }

          lote.mlo_fecven = addDaysToDate(lote.mlo_fecfab, 1);

          lote.mlo_coduse = this._authService.getUsuarioSistema();
          lote.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          lote.tal_codtal = this.selectedTalla.tal_codtal;

          if (this.kardex.tdo_codtdo == 'SAL') lote.mlo_canprd = element.stock;

          cantidadProducto += element.stock;
          lotes.push(lote);
        });

        this.kardex.detalle_map.forEach(item => {
          if (item.prd_codprd == this.selected.prd_codprd) {
            (this.kardex.tdo_codtdo == 'SAL') ?
              item.map_canprd = cantidadProducto
              :
              item.map_canprd = 0;
          }
        })

        this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);



        this.kardex.detalle_map.forEach(item => {
          if (item.prd_codprd == this.selected.prd_codprd) {
            item.tallas_mad.forEach(talla => {
              talla.lotes_mlo = lotes;
            })
          }
        })

        this.dataSourceLote = fillTable(lotes, this.paginatorLote, this.sortLote);
      }

    });
  }

  eliminarLote(): void {
    if (this.selected.prd_indlot == 0) {
      return;
    }

    let row = this.selectedTalla.lotes_mlo.find(item => item == this.selectedLote);

    if (!row && this.selectedTalla.lotes_mlo.length > 0) row = this.selectedTalla.lotes_mlo[0];

    let details = [];
    this.selectedTalla.lotes_mlo.forEach(element => {
      if (element.correlativo != row.correlativo) {
        details.push(element)
      }
    });

    console.log('details lote', details)
    this.selectedTalla.lotes_mlo = details;

    this.selectedTalla.lotes_mlo.forEach((element, key) => {
      element.correlativo = (key > 9) ? `${key}` : `0${key}`;
    })

    this.kardex.detalle_map.forEach(producto => {
      if (producto == this.selected) {

        producto.tallas_mad.forEach(talla => {
          if (talla == this.selectedTalla) {
            talla.lotes_mlo = details
          }
        });
      }
    });

    this.dataSourceLote = fillTable(details, this.paginatorLote, this.sortLote);

    this.selectedLote = null;

    this.establecerCantidadLote();
  }

  insertarLote(): void {
    return;
    if (this.selected.prd_indlot == 0) {
      return;
    }
    const lote = new DetalleLote();
    lote.mlo_coduse = this._authService.getUsuarioSistema();
    lote.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    lote.tal_codtal = this.selectedTalla.tal_codtal;

    let i = 0;

    if (this.selectedLote) {
      this.selectedTalla.lotes_mlo.forEach((item, index) => {
        if (item.correlativo == this.selectedLote.correlativo) i = index;
      })

      this.selectedTalla.lotes_mlo.splice(i, 0, lote);

    } else {
      this.selectedTalla.lotes_mlo.push(lote);
      this.selectedTalla.lotes_mlo = this.selectedTalla.lotes_mlo.reverse();
    }

    this.selectedTalla.lotes_mlo.forEach((element, key) => {

      element.correlativo = (key > 9) ? `${key}` : `0${key}`;
    })

    this.kardex.detalle_map.forEach(producto => {
      if (producto == this.selected) {

        producto.tallas_mad.forEach(talla => {

          if (talla == this.selectedTalla) {

            talla.lotes_mlo = this.selectedTalla.lotes_mlo;
          }

        })
      }
    });

    this.dataSourceLote = fillTable(this.selectedTalla.lotes_mlo, this.paginatorLote, this.sortLote);
  }

  agregarSerie(): void {

    if (this.selected.prd_indser == 0) {
      return;
    }

    if (!this.selected.prd_codprd) {
      this._snackBarService.showError('Establecer un producto de la fila seleccionada', 'Ok');
      return;
    }

    if (!this.selectedTalla) {
      this._snackBarService.showError('Seleccionar una talla', 'OK');
      return;
    }

    let serie = new DetalleSerie();

    serie.mas_coduse = this._authService.getUsuarioSistema();
    serie.tal_codtal = this.selectedTalla.tal_codtal;

    this.selectedTalla.series_mas.push(serie);

    this.selectedTalla.series_mas.forEach((element, key) => {
      element.correlativo = (key > 9) ? `${key}` : `0${key}`;
    })

    this.kardex.detalle_map.forEach(producto => {
      if (producto == this.selected) {
        producto.tallas_mad.forEach(talla => {
          if (talla == this.selectedTalla) {
            talla.series_mas = this.selectedTalla.series_mas;
          }
        })
      }
    });

    this.dataSourceSerie = fillTable(this.selectedTalla.series_mas, this.paginatorSerie, this.sortSerie);

    this.calcularTotales();
  }

  eliminarSerie(): void {

    if (this.selected.prd_indser == 0) {
      return;
    }

    let row = this.selectedTalla.series_mas.find(item => item == this.selectedSerie);

    if (!row && this.selectedTalla.series_mas.length > 0) row = this.selectedTalla.series_mas[0];

    let details = [];
    this.selectedTalla.series_mas.forEach(element => {
      if (element.correlativo != row.correlativo) {
        details.push(element)
      }
    });

    console.log('details lote', details)
    this.selectedTalla.series_mas = details;

    this.selectedTalla.series_mas.forEach((element, key) => {
      element.correlativo = (key > 9) ? `${key}` : `0${key}`;
    })

    this.kardex.detalle_map.forEach(producto => {
      if (producto == this.selected) {

        producto.tallas_mad.forEach(talla => {
          if (talla == this.selectedTalla) {
            talla.series_mas = details
          }
        });
      }
    });

    this.dataSourceSerie = fillTable(details, this.paginatorSerie, this.sortSerie);

    this.selectedSerie = null;
  }

  insertarSerie(): void {

    if (this.selected.prd_indser == 0) {
      return;
    }

    const serie = new DetalleSerie();
    serie.mas_coduse = this._authService.getUsuarioSistema();
    serie.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    serie.tal_codtal = this.selectedTalla.tal_codtal;

    let i = 0;

    if (this.selectedSerie) {
      this.selectedTalla.series_mas.forEach((item, index) => {
        if (item.correlativo == this.selectedSerie.correlativo) i = index;
      })

      this.selectedTalla.series_mas.splice(i, 0, serie);

    } else {
      this.selectedTalla.series_mas.push(serie);
      this.selectedTalla.series_mas = this.selectedTalla.series_mas.reverse();
    }

    this.selectedTalla.series_mas.forEach((element, key) => {

      element.correlativo = (key > 9) ? `${key}` : `0${key}`;
    })

    this.kardex.detalle_map.forEach(producto => {
      if (producto == this.selected) {

        producto.tallas_mad.forEach(talla => {

          if (talla == this.selectedTalla) {

            talla.series_mas = this.selectedTalla.series_mas;
          }

        })
      }
    });

    this.dataSourceSerie = fillTable(this.selectedTalla.series_mas, this.paginatorSerie, this.sortSerie);
  }

  seleccionarFecha(): void {

    console.log('obtenerMesPeriodDeFecha(this.kardex.mac_fecmac)', obtenerMesPeriodDeFecha(this.kardex.mac_fecmac))

    if (this.isMobile || this.isTablet) {
      if (this.configuracionTecnica.s70_vertca == 1) {
        setTimeout(() => {
          this.inputTasaCambio.nativeElement.focus()
        });
      } else {
        setTimeout(() => {
          this.almacenSelectComponent.onFocus()
        });
      }
    } else {
      setTimeout(() => {
        this.motivoSelectComponent.onFocus()
      });
    }

    if (obtenerMesPeriodDeFecha(this.kardex.mac_fecmac) != this.mesApertura || new Date(this.kardex.mac_fecmac).getUTCFullYear().toString() != this.anioApertura) {
      this._snackBarService.showError('La fecha tiene que estar dentro del periodo de registro', 'Ok');
      return;
    }

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDashLocal(this.kardex.mac_fecmac)).subscribe(
      response => {
        this.kardex.mac_tipcam = response[0].tipcam;
        localStorage.setItem("tipo-cambio", this.kardex.mac_tipcam.toFixed(2));
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  enterFecha(): void {
    console.log('obtenerMesPeriodDeFecha(this.kardex.mac_fecmac)', obtenerMesPeriodDeFecha(this.kardex.mac_fecmac))

    if (this.isMobile || this.isTablet) {
      if (this.configuracionTecnica.s70_vertca == 1) {
        setTimeout(() => {
          this.inputTasaCambio.nativeElement.focus()
        });
      } else {
        setTimeout(() => {
          this.almacenSelectComponent.onFocus()
        });
      }
    } else {
      setTimeout(() => {
        this.motivoSelectComponent.onFocus()
      });
    }

    if (obtenerMesPeriodDeFecha(this.kardex.mac_fecmac) != this.mesApertura || new Date(this.kardex.mac_fecmac).getUTCFullYear().toString() != this.anioApertura) {
      this._snackBarService.showError('La fecha tiene que estar dentro del periodo de registro', 'Ok');
      return;
    }

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.kardex.mac_fecmac)).subscribe(
      response => {
        this.kardex.mac_tipcam = response[0].tipcam;
        localStorage.setItem("tipo-cambio", this.kardex.mac_tipcam.toFixed(2));
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerProductosOrden(): void {

    if (!this.kardex.ocm_numocm) {
      this._snackBarService.showError('Seleccionar una orden de compra', 'Ok');
      return;
    }

    this.producosOrden$ = this._almacenService.obtenerProductosDesdeOrden(this.kardex.ocm_numocm).subscribe(
      response => {

        this.kardex.detalle_map = [];

        response.forEach(element => {
          let item = new PartDetalleMap();

          item.prd_codprd = element.prd_codprd;
          item.prd_desesp = element.prd_desesp;
          item.map_canprd = element.cantidad;
          item.map_coduse = this._authService.getUsuarioSistema();

          const producto = this.productos.find(item => item.prd_codprd == element.prd_codprd);
          item.prd_codume = producto.UNIDAD

          this.kardex.detalle_map.push(item);

        });

        this.kardex.detalle_map.forEach((element, key) => {
          element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
        })

        this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

        this.calcularTotales();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerRegInvoices(): void { }

  openMenu(event: MouseEvent) {

    event.preventDefault();

    const selectContainerRect = this.selectContainer.nativeElement.getBoundingClientRect();
    const top = selectContainerRect.top + window.scrollY;
    const left = selectContainerRect.left + window.scrollX + 120;

    // Abre el menú contextual
    this.contextMenuTrigger.openMenu();

    // Ajusta la posición del menú manualmente
    setTimeout(() => {
      const menuPanel = document.querySelector('.mat-menu-panel') as HTMLElement;
      if (menuPanel) {
        menuPanel.style.position = 'absolute';
        menuPanel.style.top = `${top}px`;
        menuPanel.style.left = `${left}px`;
        menuPanel.style.transform = 'none';
      }
    }, 0);
  }

  obtenerProductosPedido(): void {

    console.log('this.numeroParte', this.numeroParte)
    if (this.numeroParte != '0') return;

    if (!this.kardex.pcc_numpcc) {
      this._snackBarService.showError('Seleccionar un pedido', 'Ok');
      return;
    }

    console.log('this.kardex.detalle_map', this.kardex.detalle_map)
    if (this.kardex.detalle_map.length != 0) {
      this._snackBarService.showError('Para cargar no debe de haber ningun detalle en el kardex', 'Ok');
      return;
    }

    this.pedido$ = this._pedidoService.obtenerPedido('01', this.kardex.pcc_numpcc).subscribe(
      (pedido: Order) => {
        console.log('pedido', pedido)

        this.kardex.cli_codcli = pedido.CLI_CODCLI;

        if (pedido.detalle_pcd) {
          pedido.detalle_pcd.forEach(productoPedido => {
            const productoKardex = new PartDetalleMap();
            const producto = this.productos.find(item => item.prd_codprd == productoPedido.PRD_CODPRD);

            productoKardex.prd_codprd = productoPedido.PRD_CODPRD;
            productoKardex.prd_codume = producto.UNIDAD;
            productoKardex.map_canprd = productoPedido.PCD_CANSOL;

            if (this.configuracionTecnica.s70_unime2 == 1 && this.indMotivoUM2 == 1) {
              productoKardex.ume_codven = this.umSugerida02;
              if (this.umSugerida02 == 'KGS') {
                productoKardex.map_canven = productoKardex.map_canprd * producto.prd_conten;
              }
            }


            productoKardex.prd_indlot = producto.prd_indlot;
            productoKardex.prd_indser = producto.prd_indser;
            productoKardex.prd_indtal = producto.prd_indtal;
            productoKardex.tipoInventario = producto.COD_INVENTARIO;
            this.establecerCantidad(productoKardex)

            this.kardex.detalle_map.push(productoKardex);


          })

          this.kardex.detalle_map.forEach((element, key) => {
            element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
          })

          // obtener la unidad de medida del producto
          this.kardex.detalle_map.forEach(item => {
            item.prd_codume = this.productos.find(producto => producto.prd_codprd == item.prd_codprd).UNIDAD
            console.log(item.prd_codume)
          })

          this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);
        }
      },
      error => {
        this._snackBarService.showError('Error al obtener pedido', 'Ok');

      }
    )
  }

  obtenerProductosLiq(): void {
    if (!this.kardex.lfa_numlfa) {
      this._snackBarService.showError('Seleccionar una liquidación', 'Ok');
      return;
    }

    this.productosLiq$ = this._almacenService.obtenerProductosDesdeLiq(this.kardex.lfa_numlfa).subscribe(
      response => {
        response.forEach(element => {
          let producto = new PartDetalleMap();

          producto.prd_codprd = element.prd_codprd;
          producto.prd_desesp = element.prd_desesp;
          producto.map_canprd = element.cantidad;

          producto.prd_codprd = element.prd_codprd;
          producto.prd_desesp = element.prd_desesp;

          producto.map_coduse = this._authService.getUsuarioSistema();

          this.kardex.detalle_map.push(producto);

        });

        this.kardex.detalle_map.forEach((element, key) => {
          element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
        })

        this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);

        this.calcularTotales();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerProductosOrdenInternamiento(): void { }

  obtenerProductosRequerimiento(): void { }

  esFormularioValido(): boolean {

    if (esFechaDiferenteAPeriodo(new Date(this.kardex.mac_fecmac), this.anioPeriodo, this.mesPeriodo)) {
      this._snackBarService.showError('La fecha no pertenece al periodo', 'OK');
      return false;
    }

    // if (obtenerMesPeriodDeFecha(this.kardex.mac_fecmac) != this.mesApertura || new Date(this.kardex.mac_fecmac).getFullYear().toString() != this.anioApertura) {
    //   this._snackBarService.showError('La fecha tiene que estar dentro del periodo de registro', 'Ok');
    //   return false;
    // }

    if (this.kardex.detalle_map.length == 0) {

      this._snackBarService.showError('Ingresar al menos un item en productos', 'Ok');
      return false;

    }

    if (this.indMotivoExportacion == 1) {
      this.kardex.detalle_map.forEach(item => {
        const producto = this.productos.find(producto => producto.prd_codprd == item.prd_codprd);

        if (!producto.prd_codsun || producto.prd_codsun.trim() == '') {
          this._snackBarService.showError(`El producto ${producto.DESC_ABRE} no tiene codigo sunat`, 'Ok');
          return false;
        }
      })

    }

    if (this.kardex.mmo_codmmo == '0171' && this.kardex.tdo_codtdo == 'ING') {

      const almacen = this.almacenes.find(item => item.ALM_CODALM == this.kardex.alm_codalm);

      const numLote = `${!this.establecimiento || this.establecimiento.trim() == '' ? '01' : this.establecimiento}${formatDateClean6Digitos(this.kardex.mac_fecmac)}${this.kardex.mac_numre1}`

      let esValidoLote = true;
      this.kardex.detalle_map.forEach(item => {
        if (item.tallas_mad[0].lotes_mlo) {
          item.tallas_mad[0].lotes_mlo.forEach(lote => {
            if (lote.mlo_numlot != numLote) {
              this._snackBarService.showError(`El lote ${lote.mlo_numlot} no coincide con el número de guía`, 'Ok');
              esValidoLote = false;
            }
          })
        }
      })

      if (!esValidoLote) {
        return false;

      }
    }

    if (this.numeroParte != '0') {
      if (this.kardex.mac_indgen == 1) {
        this._snackBarService.showError('El movimiento de almacen fue generado en un proceso automatico, no se puede modificar', 'Ok');
        return false;
      }
    }

    if (!this.kardex.alm_codalm) {
      this._snackBarService.showError('Seleccionar un almacén', 'Ok');
      return false;
    }

    if (!this.kardex.cli_codcli) {
      this._snackBarService.showError('Seleccionar un auxiliar', 'Ok');
      return false;
    }

    if (!this.kardex.mmo_codmmo) {
      this._snackBarService.showError('Seleccionar un motivo', 'Ok');
      return false;
    }

    if (this.kardex.mac_tdore1) {
      if (!this.kardex.mac_numre1) {
        this._snackBarService.showError(`Tiene que Ingresar el Nro de Referencia 1 del Documento ${this.kardex.mac_tdore1}`, 'Ok');
        return false;
      }
    }

    if (this.kardex.mac_tdore2) {
      if (!this.kardex.mac_numre2) {
        this._snackBarService.showError(`Tiene que Ingresar el Nro de Referencia 2 del Documento ${this.kardex.mac_tdore2}`, 'Ok');
        return false;
      }
    }

    if (this.kardex.mac_tipcam <= 0) {
      this._snackBarService.showError('Ingresar un tipo de cambio válido', 'Ok');
      return false;
    }

    // Validad suma de totales cantidad 2
    let totalCantidad = 0;
    this.kardex.detalle_map.forEach(item => {

      if (item.prd_codume) {
        if (item.prd_codume.trim() == 'KGS') {
          totalCantidad += item.map_canprd;
          // esUMkg = false;
        } else {
          if (item.ume_codven) {
            if (item.ume_codven.trim() == 'KGS') {
              totalCantidad += item.map_canven;
            }
          }

        }

      }

    })

    console.log('totalCantidad', totalCantidad)
    if (this.indGuiaRemision == 1) {
      if (totalCantidad > this.kardex.detalle_guia[0].grc_pesbru) {
        this._snackBarService.showError('La suma de las cantidades no puede ser mayor al peso bruto', 'Ok');
        return false;
      }
    }

    if (this.configuracionTecnica.s70_unime2 == 1) {
      console.log('this.configuracionTecnica.s70_unime2', this.configuracionTecnica.s70_unime2)
      if (this.indMotivoUM2 == 1) {
        console.log('this.indMotivoUM2', this.indMotivoUM2)
        let noExisteUnidad = false;

        this.kardex.detalle_map.forEach(element => {

          if (!element.ume_codven) {
            noExisteUnidad = true;
            this._snackBarService.showError('Establecer unidad de medida 2', 'Ok');
          }

          if (!element.map_canven) {
            noExisteUnidad = true;
            this._snackBarService.showError('Establecer cantidad 2', 'Ok');
          }

        });

        if (noExisteUnidad) {
          return false;
        }
      }
    }

    return true;
  }

  esValidoFormularioGuia(): boolean {
    if (this.indGuiaRemision == 1) {
      // Peso bruto
      if (!this.kardex.detalle_guia[0].grc_pesbru) {
        this._snackBarService.showError('Ingresar el peso bruto en el formulario de guía', 'Ok');
        return false;
      }

      // Fecha de traslado
      if (!this.kardex.detalle_guia[0].grc_fectra) {
        this._snackBarService.showError('Ingresar la fecha de traslado en el formulario de guía', 'Ok');
        return false;
      }

      // Destinatario
      if (!this.kardex.detalle_guia[0].cli_codcli) {
        this._snackBarService.showError('Seleccionar el destinatario en el formulario de guía', 'Ok');
        return false;
      }

      // Transportista
      if (!this.kardex.detalle_guia[0].cli_codtra) {
        this._snackBarService.showError('Seleccionar el transportista en el formulario de guía', 'Ok');
        return false;
      }

      // Unidad de Transporte
      if (!this.kardex.detalle_guia[0].utr_codutr) {
        this._snackBarService.showError('Seleccionar una unidad de transporte en el formulario de guía', 'Ok');
        return false;
      }

      // Marca
      if (!this.kardex.detalle_guia[0].grc_marund) {
        this._snackBarService.showError('Establecer una marca en el formulario de guía', 'Ok');
        return false;
      }

      // Chofer
      if (!this.kardex.detalle_guia[0].cho_codcho) {
        this._snackBarService.showError('Seleccionar un chofer en el formulario de guía', 'Ok');
        return false;
      }

      // Punto de partida
      if (!this.kardex.detalle_guia[0].ldc_codldc) {
        this._snackBarService.showError('Seleccionar punto de partida en el formulario de guía', 'Ok');
        return false;
      }

      // Punto de llegada
      if (!this.kardex.detalle_guia[0].lde_codlde) {
        this._snackBarService.showError('Establecer punto de llegada en el formulario de guía', 'Ok');
        return false;
      }

      // Serie guía de remisión
      if (!this.kardex.detalle_guia[0].sdo_codsdo) {
        this._snackBarService.showError('Ingrese la serie de la guia de remisión', 'Ok');
        return false;
      }

      // Tamaño de serie guía de remisión
      console.log('serie', this.kardex.detalle_guia[0].sdo_codsdo)
      console.log('serie', this.kardex.detalle_guia[0].sdo_codsdo.length)
      if (this.kardex.detalle_guia[0].sdo_codsdo.trim().length != 4) {
        this._snackBarService.showError('La serie de la guia de remisión debe tener 4 caracteres', 'Ok');
        return false;
      }

      // Número de documento guîa de remisión
      if (!this.kardex.detalle_guia[0].grc_numdoc) {
        this._snackBarService.showError('Establecer Número de documento en el formulario de guía', 'Ok');
        return false;
      }

      if (!this.kardex.detalle_guia[0].grc_numbul && this.indMotivoExportacion == 1) {
        this._snackBarService.showError('Tiene que Ingresar el N°Bulto en el formulario de guía', 'Ok');
        return false;
      }

      if (!this.kardex.detalle_guia[0].grc_codexp && this.indMotivoExportacion == 1) {
        this._snackBarService.showError('Tiene que Ingresar el código referencia exportación en el formulario de guía', 'Ok');
        return false;
      }

      if (!this.kardex.detalle_guia[0].grc_numexp && this.indMotivoExportacion == 1) {
        this._snackBarService.showError('Tiene que Ingresar el documento de referencia exportación en el formulario de guía', 'Ok');
        return false;
      }

      return true;
    } else {
      return true;
    }
  }

  esValidoConIndicadorMotivo(): boolean {

    const motivo = this.motivos.find(item => item.mmo_codmmo == this.kardex.mmo_codmmo);

    if (this.indOrdCompra == 1 && this.configuracionTecnica.s70_indocm == 1) {

      if (!this.kardex.ocm_numocm) {
        this._snackBarService.showError('Seleccionar una orden de compra', 'Ok');
        return false;
      }

    }

    if (this.indOrdProd) {

      if (!this.kardex.otr_numotr) {
        this._snackBarService.showError('Tiene que Ingresar la Orden de Producción Respectiva', 'Ok');
        return false;
      }

    }

    // if (motivo.mmo_indcom == 1) {
    //   if (!this.kardex.cli_codcli) {
    //     this._snackBarService.showError('Es obligatorio ingresar un Auxiliar o Responsable', 'Ok');
    //     return false;
    //   }
    // }

    if (motivo.mmo_indrma == 1) {
      if (!this.kardex.rma_numrma) {
        this._snackBarService.showError('Es obligatorio ingresar el # de requerimiento', 'Ok');
        return false;
      }
    }

    if (this.tipo == 'ING') {
      if (motivo.mmo_indcbo == 1) {
        if (!this.kardex.alm_codcom || !this.kardex.tdo_codcom || !this.kardex.mac_numcom) {
          this._snackBarService.showError('Es obligatorio ingresar el parte de origen', 'Ok');
          return false;
        }
      }
    }

    if (this.tipo == 'ING') {
      // if (this.indObligatorioCosto == 1) {
      //   for (let index = 0; index < this.kardex.detalle_map.length; index++) {
      //     const element = this.kardex.detalle_map[index];
      //     if (
      //       ) {
      //       this._snackBarService.showError(`Los productos del detalle deben que tener costo`, 'Ok');
      //       return false;
      //     }
      //   }

      // }

      if (motivo.mmo_indval == 1 && this.indObligatorioCosto == 1) {
        let validate = false;
        this.kardex.detalle_map.forEach(element => {
          console.log('map_cosmnp', element.map_cosmnp)
          if (!element.map_cosmep || element.map_cosmep.toString() == '0' ||
            !element.map_cosmnp || element.map_cosmnp.toString() == '0') {
            this._snackBarService.showError('Es obligatorio ingresar el costo de producto', 'Ok');
            validate = true

          }
        });
        if (validate) {
          return false;
        }
      }
    }

    console.log('tipo', this.tipo)
    console.log('motivo.mmo_indtra', motivo.mmo_indtra)


    if (this.kardex.tdo_codtdo == 'SAL') {
      if (motivo.mmo_indtra == 1) {
        if (!this.kardex.alm_coddes) {
          console.log('ingresa a la validación')
          this._snackBarService.showError('Es obligatorio ingresar el almacen de destino', 'Ok');
          return false;
        }

        if (this.kardex.alm_coddes == this.kardex.alm_codalm) {
          this._snackBarService.showError('El almacen de destino no debe ser igual al almacén principal', 'Ok');
          return false;
        }
      }
    }

    if (this.numeroParte != '0') {
      if (motivo.mmo_indtra == 1) {
        this._snackBarService.showError('Las Transferencia entre almacenes no se pueden Modificar, tiene Parte de Destino', 'Ok');
        return false
      }

    }

    return true;
  }

  esValidoTablasTallaLoteSerie(): boolean {
    console.log('detalle', this.kardex.detalle_map);

    this.kardex.detalle_map.forEach(element => {
      const producto = this.productos.find(item => item.prd_codprd == element.prd_codprd);
    });

    return true;
  }

  async esValidoLotesProducto() {

    let esLoteValido = true;

    for (let index = 0; index < this.kardex.detalle_map.length; index++) {
      const item = this.kardex.detalle_map[index];

      if (esLoteValido) {
        console.log('tipoInventario', item.tipoInventario)
        if (item.tipoInventario == '01') {
          for (let index = 0; index < item.tallas_mad[0].lotes_mlo.length; index++) {
            const lote = item.tallas_mad[0].lotes_mlo[index];

            const params: Array<IParam> = [
              {
                param: 'tipoinventario',
                value: '04'
              }
            ]
            const lotesProducto = await this._loteService.obtenerLotesProducto({ params }).toPromise();
            const lotesMateriaPrima = lotesProducto.map(itemLote => itemLote.lot_numlot);

            const loteExisteEnMateriaPrima: boolean = lotesMateriaPrima.includes(lote.mlo_numlot.trim().substring(0, 20));

            if (!loteExisteEnMateriaPrima) {
              esLoteValido = false;
            }

          }

        }

      }
    }

    console.log('esLoteValido', esLoteValido)
    if (!esLoteValido) {
      console.log('no es valido')
      this._snackBarService.showError('Existe un lote que no proviene de inventario Materia Prima', 'Ok')
      return false;
    } else {
      console.log('es valido')
      return true;
    }

  }

  esValidoLotes(): boolean {
    if (this.rucEmpresa == '20605493603') {
      if (this.kardex.mmo_codmmo == '0173') {
        let esValido = true;
        for (let index = 0; index < this.kardex.detalle_map.length; index++) {
          const item = this.kardex.detalle_map[index];
          item.tallas_mad[0].lotes_mlo.forEach(lote => {
            if (!lote.pai_codpai) {
              this._snackBarService.showError('Es obligatorio ingresar el país en el detalle de lote', 'Ok');
              esValido = false;
            }
          })
        }

        if (!esValido) {
          return false
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async enviarFormulario(f: NgForm) {

    this.loaderReg = true;

    if (!this.esFormularioValido()) {
      this.loaderReg = false;
      return;
    }

    if (!this.esValidoConIndicadorMotivo()) {
      this.loaderReg = false;
      return;
    }

    if (!this.esValidoFormularioGuia()) {
      this.loaderReg = false;
      return;
    }

    if (!this.esValidoLotes()) {
      this.loaderReg = false;
      return;
    }

    const esValidoLotesProducto = await this.esValidoLotesProducto();

    if (!esValidoLotesProducto) {
      this.loaderReg = false;
      return;
    }

    this.kardex.mac_fecmac = formatDateWithDash(this.kardex.mac_fecmac)

    this.kardex.detalle_map.forEach(producto => {
      producto.map_coduse = this._configurationService.obtenerIdUsuario();
      producto.cia_codcia = this._configurationService.obtenerCompaniaCliente();
      producto.tallas_mad.forEach(talla => {
        talla.mad_coduse = this._configurationService.obtenerIdUsuario();
        talla.cia_codcia = this._configurationService.obtenerCompaniaCliente();
      });
    });


    if (this.indGuiaRemision == 0) {
      this.kardex.detalle_guia = null;
    } else {
      if (this.indMotivoExportacion == 0) {
        this.kardex.detalle_guia[0].grc_obsgen = '';
      }
    }

    this.kardex.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.kardex.mac_coduse = this._configurationService.obtenerIdUsuario();

    // console.log('kardex', this.kardex);
    // this.loaderReg = false;
    // return;

    (!this.kardex.mac_nummac) ? this.registrarParte(f) : this.editarParte(f);

  }

  registrarParte(f: NgForm): void {

    this.kardex.detalle_map.forEach((element, index) => {
      if (index < 9) {
        element.map_cormap = `000${index + 1}`;
      } else if (index > 8 && index < 99) {
        element.map_cormap = `00${index + 1}`;
      } else if (index > 98 && index < 999) {
        element.map_cormap = `0${index + 1}`;
      } else {
        element.map_cormap = `${index + 1}`;
      }
    })

    console.log('parte almacen', this.kardex)

    this.sendForm$ = this._kardexService.registrarKardex(this.kardex).subscribe(
      response => {
        console.log('response', response)

        this.kardex.tdo_coddes = response.tdo_coddes;
        this.kardex.mac_numdes = response.mac_numdes;

        this.loaderReg = false;
        this.kardex.mac_nummac = response.mac_nummac;
        if (this.indGuiaRemision) {
          this.kardex.detalle_guia[0].ldc_codldc = this.lugaresDespachoCIA[0].LDC_CODLDC;
        }
        this.canDeactivateGuard.setShowConfirmationDialog(false);
        this._dialogService.openDialog(SuccessComponent, `Registro de Parte Exitoso | Nro. ${response.mac_nummac}`, '300px', '', '');
        // if (this.kardex.mac_numre1) {
        //   this.kardex.mac_numre1 = this.kardex.mac_numre1.slice(0, 4) + "-" + this.kardex.mac_numre1.slice(4);
        // }
        // if (this.kardex.mac_numre2) {
        //   this.kardex.mac_numre2 = this.kardex.mac_numre2.slice(0, 4) + "-" + this.kardex.mac_numre2.slice(4);
        // }
        this.anio = this.kardex.ano_codano;
        this.mes = this.kardex.mes_codmes;
        this.almacen = this.kardex.alm_codalm;
        this.tipo = this.kardex.tdo_codtdo;
        this.numeroParte = this.kardex.mac_nummac;
      })


    error => {
      this.loaderReg = false;
      this._snackBarService.showError(error.error.msg, 'Ok');
    }

  }

  validarPeriodo(year, month): boolean {

    const partYear = new Date(this.kardex.mac_fecmac).getFullYear();
    const partMonth = new Date(this.kardex.mac_fecmac).getMonth() + 1;

    const periodYear = parseInt(year);
    const periodMonth = parseInt(month);

    if (periodYear < partYear) return false;

    return (periodMonth < partMonth) ? false : true;

  }

  editarParte(f: NgForm): void {
    this.sendForm$ = this._kardexService.editarKardex(this.kardex).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Parte Actualizado', '300px', '', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK')
      }
    )

  }

  extraerExcel(): void {

  }

  seleccionarDestinatario(): void {

    this.lugaresDespacho$ = this._ventasService.obtenerLugaresDespacho(this.kardex.detalle_guia[0].cli_codcli).subscribe(
      lugaresDespacho => {
        this.lugaresDespachoDesti = lugaresDespacho;
        if (this.lugaresDespachoDesti.length == 0) {
          console.log('destinatario', this.kardex.detalle_guia[0].cli_codcli)
          const destinatario: Cliente = this.clientes.find(cliente => cliente.cli_codcli == this.kardex.detalle_guia[0].cli_codcli);
          console.log(destinatario.cli_dircli)
          this.kardex.detalle_guia[0].grc_lugent = destinatario.cli_dircli;
        }
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
        this.loaderData = false;
      }
    )
  }

  refrescarPagina(): void {
    this.loadMaestros();
  }

  generarFormatoKardex(): void {

    if (this.kardex.mac_nummac == '0' || !this.kardex.mac_nummac) return;

    this.loaderFormato = true;

    const headers = {
      ruc: this._configurationService.obtenerClienteId(),
      logo: this.logo,
      motivo: this.kardex.mmo_codmmo,
      indExportacion: this.indMotivoExportacion,
      indPedido: this.indPedido,
      indOrdCompra: this.indOrdCompra,
      indRegInvoice: this.indRegInvoice,
      indReqMat: this.indReqMat,
      indOrdProd: this.indOrdProd,
      nomcia: this._configurationService.obtenerNombreCompaniaCliente(),
      dircia: this.dircia,
      modalidadTraslado: this.modalidadTraslado,
      glosa2: this.kardex.mac_gloma2,
      ubicacion2: this.ubicacionAdicional,
      coduse: this._configurationService.obtenerIdUsuario()
    }

    if (this.kardex.mac_nummac != '0') {

      this.kardex$ = this._kardexService.obtenerKardex(this.anio, this.mes, this.almacen, this.tipo, this.numeroParte).subscribe(
        kardex => {

          formatoPDFBuilder.generarFormatoParte(headers, kardex)

          this.loaderFormato = false;
        },
        error => {
          this._snackBarService.showError('Error al obtener kardex', 'Ok');
          this.loaderFormato = false;
        }
      )

    }

  }

  imprimirFormato(): void {
    this.preferenciasImpresion$ = this._printerService.obtenerConfiguracionImpresora(this._configurationService.obtenerIdUsuario(), this._configurationService.obtenerPrograma()).subscribe(
      preferencia => {
        this.preferenciaImpresion = preferencia;

      },
      error => {
        this._snackBarService.showError('Error al obtener preferencia', 'Ok')
      }
    )
  }

  calcularTotales(): void {
    this.importeTotalMN = 0;
    this.importeTotalME = 0;
    this.costoTotalMN = 0;
    this.costoTotalME = 0;

    this.kardex.detalle_map.forEach(element => {
      this.importeTotalMN = this.importeTotalMN + element.map_impmnp;
      this.importeTotalME = this.importeTotalME + element.map_impmep;
      this.costoTotalMN = this.costoTotalMN + element.map_cosmnp;
      this.costoTotalME = this.costoTotalME + element.map_cosmep;
    })
  }

  anularKardex(): void {
    if (this.kardex.mac_nummac == '0') {
      console.log('kardex', this.kardex);
      return;
    }

    this.kardex.mac_indsta = '0';

    this.sendForm$ = this._kardexService.editarKardex(this.kardex).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Parte Anulado', '300px', '', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK')
      }
    )

  }

  selectContent(event: FocusEvent) {
    // Selecciona el contenido del input al hacer focus
    if (event.target instanceof HTMLInputElement) {
      event.target.select();
    }
  }

  keyUpReferencia01(): void {

  }

  enterReferencia01(): void {
    if (this.kardex.mac_numre1.length == 12) {
      if (this.configuracionTecnica.s70_verref2 == 1) {
        setTimeout(() => {
          this.tipoDocRef02SelectComponent.onFocus();
        });
      } else {
        if (this.configuracionTecnica.s70_verccs == 1) {
          setTimeout(() => {
            this.centroCostoSelectComponent.onFocus();
          });
          return;
        }
        if (this.indOrdCompra == 1) {
          setTimeout(() => {
            this.ordenCompraSelectComponent.onFocus()
          });
          return;
        }
      }
    }

    if (this.kardex.mac_numre1.length > 4) {
      const zeroPad = (num, places) => String(num).padStart(places, '0')

      let numero = zeroPad(this.kardex.mac_numre1.substring(4, this.kardex.mac_numre1.length + 1), 10);
      this.kardex.mac_numre1 = this.kardex.mac_numre1.substring(0, 4) + numero;
    }
  }

  keyUpReferencia02(): void {

  }

  enterReferencia02(): void {
    if (this.kardex.mac_numre2.length > 4) {
      const zeroPad = (num, places) => String(num).padStart(places, '0')

      let numero = zeroPad(this.kardex.mac_numre2.substring(4, this.kardex.mac_numre2.length + 1), 10);
      this.kardex.mac_numre2 = this.kardex.mac_numre2.substring(0, 4) + numero;
    }
  }

  verMasOpciones(): void {
    this._bottomSheet.open(KardexBottomSheet, {}).afterDismissed().subscribe((result: any) => {

    })
  }

  volver(): void {
    this._router.navigate(['/modulo-almacen/movimientos']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoCambio$,
      this.motivoParteMov$,
      this.producosOrden$,
      this.productosLiq$,
      this.sendForm$,
      this.loading$,
      this.periodoAbierto$,
      this.correlativoGuia$,
      this.preferenciasImpresion$,
      this.period$,
      this.maestros$,
      this.kardexs$,
      this.tiposDocumento$,
      this.tiposDocumentoReferencia$,
      this.almacenes$,
      this.tiposCambio$,
      this.ordenes$,
      this.auxiliares$,
      this.invoices$,
      this.liquidaciones$,
      this.centros$,
      this.productos$,
      this.choferes$,
      this.unidades$,
      this.clientes$,
      this.motivos$,
      this.series$,
      this.establecimiento$,
      this.config$,
      this.unidadesMedida$,
      this.pedidos$,
      this.aeropuertos$,
      this.agenciasTransporte$,
      this.ubigeosSunat$,
      this.paises$,
      this.configuracionTecnica$,
      this.barcode$,
      this.guiaRemisionPDF$,
      this.transportistas$,
      this.lugaresDespachoCia$,
      this.correlativoDoc$,
      this.pedido$,
      this.lugaresDespacho$,
      this.kardex$,
    ])

  }
}
