import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { VentasService } from "src/app/services";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'concepto-descuento-list',
  templateUrl: './concepto-descuento-list.component.html',
  styleUrls: ['./concepto-descuento-list.component.scss']
})
export class ConceptoDescuentoListComponent implements OnInit, OnDestroy {
  loaderData: boolean;



  displayedColumns: string[] = ['acciones', 'dco_coddco', 'dco_desdco', 'dco_obsdco' , 'dco_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading$: Subscription;
  conceptoDescuento$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;


  constructor(
    private _ventasService: VentasService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _snackBar: MatSnackBar

  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerConceptosDescuento();
    })
  }
  obtenerConceptosDescuento(): void {
    this.loaderData = true;
    this.conceptoDescuento$ = this._ventasService.obtenerConceptosDescuento().subscribe(
      response => {
        console.log('Conceptos', response)
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }
  loadData(): void {}

  applyFilter(event: Event): void {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.conceptoDescuento$
    ]);
  }
  copiar(codigo): void {
    this._snackBar.open(`Código copiado ${codigo} !!!`, 'Ok', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 1500
    });
  }

}
