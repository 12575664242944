export class CentroCosto {
  cia_codcia: string;
  ccs_codccs: string;
  ccs_descri: string;
  cli_codcli: string;
  ccs_indsta: string;
  ccs_fecupd: Date;
  ccs_coduse: string;
  ccs_codgru: string;
  ccs_indpry: number;
  tmo_codtmo: string;
  ccs_tipcam: number;
  ccs_imptot: number;
  cac_codcac: string;
  cli_codaux: any;
  oin_numoin: any;
  ccs_tipotr: string;
  ccs_indpro: number;
  prd_codprd: string;
  ccs_finvig: any;
  ccs_diapro: number;
  ung_codung: any;
  maq_codmaq: any;
  tim_codtim: any;
  ccs_fecccs: string;
  oid_codoid: any;
  ccs_usecre: string;
  ccs_feccre: Date;
  eot_codeot: any;
  ccs_indgar: number;
  ccs_garccs: any;
  ccs_ordcom: any;
  ccs_numcot: any;
  mot_codmot: any;
  ccs_indapr: string;
  ccs_usuapr: any;
  ccs_fecapr: any;
  ccs_indciv: number;
  ccs_usuciv: any;
  ccs_fecciv: any;
  ccs_desesp: any;

  constructor() {
    this.ccs_indsta = '1';
    this.ccs_fecupd = new Date();
    this.ccs_imptot = 0;
    this.ccs_tipcam = 0;
  }
}
