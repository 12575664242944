<app-pavso-title-section [title]="'Requerimiento de producción'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarPlanta(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l6 xl3">
                  <mat-form-field>
                      <mat-label>Requerimiento Nro.</mat-label>
                      <input type="text" matInput placeholder="Código" name="codigo" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6 xl3">
                <mat-form-field>
                  <mat-label>Fecha Emisión</mat-label>
                  <input matInput [matDatepicker]="fechaemision">
                  <mat-datepicker-toggle matSuffix [for]="fechaemision"></mat-datepicker-toggle>
                  <mat-datepicker #fechaemision></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l12 xl6">
                <mat-form-field>
                    <mat-label>Solicitado Por</mat-label>
                    <mat-select required name="solicitadopor" [disabled]="loaderData">
                        <mat-option value="1">
                            solicitado por 1
                        </mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l12 xl6">
                <mat-form-field>
                    <mat-label>Aprobado Por</mat-label>
                    <mat-select required name="aprobadopor" [disabled]="loaderData">
                        <mat-option value="1">
                            aprobado por 1
                        </mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l6 xl3">
                  <mat-form-field>
                      <mat-label>Período</mat-label>
                      <input type="text" matInput placeholder="Período" name="descripcion" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6 xl1">
                <button mat-mini-fab color="primary" type="button">
                  <mat-icon>ads_click</mat-icon>
                </button>
              </div>

              <div class="col s12 m12 l12 xl12">
                <mat-form-field>
                  <mat-label>Observaciones</mat-label>
                  <textarea matInput placeholder="Observaciones"></textarea>
                </mat-form-field>
              </div>
          </div>

      </mat-card>
      <br>
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <button matTooltip="Editar" [routerLink]="['/modulo-produccion/editar-chofer', row.CHO_CODCHO]" mat-icon-button color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
            </ng-container>

            <ng-container matColumnDef="producto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                <td mat-cell *matCellDef="let row" data-label="Producto"> {{row.producto}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
            </ng-container>

            <ng-container matColumnDef="formula">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fórmula </th>
              <td mat-cell *matCellDef="let row" data-label="Fórmula"> {{row.formula}} </td>
            </ng-container>

            <ng-container matColumnDef="desc_formula">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Fórmula </th>
              <td mat-cell *matCellDef="let row" data-label="Desc. Fórmula"> {{row.desc_formula}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cantidad}} </td>
            </ng-container>

            <ng-container matColumnDef="um">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
              <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.um}} </td>
            </ng-container>

            <ng-container matColumnDef="atendido">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Atendido </th>
              <td mat-cell *matCellDef="let row" data-label="Atendido"> {{row.atendido}} </td>
            </ng-container>

            <ng-container matColumnDef="pendiente">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Pendiente </th>
              <td mat-cell *matCellDef="let row" data-label="Pendiente"> {{row.pendiente}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
