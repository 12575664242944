import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'monitoreo-comprobante-masivo',
  templateUrl: './monitoreo-comprobante-masivo.component.html',
  styleUrls: ['./monitoreo-comprobante-masivo.component.scss']
})
export class MonitoreoComprobanteMasivoComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
