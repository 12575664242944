import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DocumentToUpload } from "../../registro-compra-form/registro-compra-form.component";
import * as xml2js from 'xml2js';

@Component({
  selector: 'registro-compra-upload-xml',
  template: `
    <h1 mat-dialog-title>Cargar xml</h1>

    <div mat-dialog-content [ngStyle]="{padding: '10px', textAlign: 'center'}">
      <input #xmlInput hidden="true" type="file" (change)="cargarXml($event)"
                accept="text/xml" />
      <button (click)="xmlInput.click()" matTooltip="Cagar xml" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
        <mat-icon>upload</mat-icon>
      </button>

      <p [ngStyle]="{textAlign: 'center'}">{{nameXml}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button class="pav-button-cancel" cdkFocusInitial (click)="cerrar()" [ngStyle]="{width: '122px'}">Cancelar</button>
      <button mat-flat-button class="pav-button-action" (click)="confirmar()" color="primary" [ngStyle]="{width: '122px'}">Confirmar</button>
    </div>
  `,
  styleUrls: ['./registro-compra-upload-xml.dialog.scss']
})
export class RegistroCompraUploadXml {

  nameXml: string;

  constructor(
    public dialogRef: MatDialogRef<RegistroCompraUploadXml>,
    @Inject(MAT_DIALOG_DATA) public documentToUpload: DocumentToUpload,
  ) {}

  cargarXml(fileInputEvent: any): void {
    let file = fileInputEvent.target.files[0];

    console.log('file', file)

    if (!file) return;

    const reader = new FileReader();
    reader.onload = (evt) => {
        const xmlData: string = (evt as any).target.result;
        console.log('xml data', xmlData)
        this.documentToUpload.xmlStr = xmlData;
        this.documentToUpload.xmlFile = file;
        const parser = new xml2js.Parser({ strict: false, trim: true });
        parser.parseString(xmlData, (err, result) => {
          this.documentToUpload.xmlJSON = result;


          let data_to_validate = {
            // cli_codcli: this.registro.cli_codcli
          }
          // this.validaciónCargaXML(data_to_validate);
          // this.loaderData = false;
        });
    };
    reader.readAsText(file);

    this.nameXml = fileInputEvent.target.files[0].name;
  }

  cerrar(): void {
    this.dialogRef.close(this.documentToUpload);
  }

  confirmar(): void {

    this.dialogRef.close(this.documentToUpload);
  }
}
