export class ConfiguracionContabilidad {

  CIA_CODCIA: string;
  S03_CODMOD: string;
  CC1_GLOCC1: string;
  CCN_CUEPER: string;
  CCN_CUEGAN: string;
  CCN_TRAPER: string;
  CCN_TRAGAN: string;
  CCN_CUEIGV: string;
  CCN_IGVSDE: string;
  CCN_IGVNDO: string;
  CCN_NDOAPL: string;
  CCN_NDORET: string;
  CCN_PERIGV: string;
  CCN_CUERES: string;
  CCN_AJUING: string;
  CCN_AJUSAL: string;
  CCN_IND001: number;
  CCN_IND002: number;
  CCN_IND003: number;
  CCN_IND004: number;
  CCN_IND005: number;
  CCN_IND006: number;
  CCN_IND007: number;
  CCN_IND008: number;
  CCN_IND009: number;
  CCN_IND010: number;
  CCN_IND011: number;
  CCN_IND012: number;
  CCN_MAXLEN: number;
  CC1_INDTRA: string;
  CCN_CUEDET: string;
  CC1_AGERET: number;
  CC1_FECAGE: string;
  CC1_RCOOCO: string;
  cco_difcam: string;
  CC1_INDSTA: string;
  CC1_FECUPD: Date;
  CC1_CODUSE: string;
  CCN_anopce: string;
  CCN_bcklen: number;
  CCN_bckind: string;
  SCO_RECCXC: string;
  SCO_RECCXP: string;
  CCN_ncodol: string;
  CCN_nconac: string;
  CCN_cjenac: string;
  CCN_cjedol: string;
  CC1_asidfd: string;
  CC1_asidfh: string;
  CC1_ageper: number;
  CC1_fecper: string;
  CC1_SCOPER: string;
  CC1_TIPPER: string;
  CC1_USUSOL: string;
  CC1_CLASOL: string;
  CCS_CODCCS: string;
  CC1_IDAPI: string;
  cc1_pasapi: string;
  ccn_permna: string;
  ccn_permex: string;
  ccn_retmna: string;
}
