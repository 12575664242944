
export class Order {
  CIA_CODCIA: string;
  SUC_CODSUC: string;
  PCC_NUMPCC: string;
  CLI_CODCLI: string;
  CTI_CODCTI: null;
  PCC_FECDOC: Date;
  MMO_CODMMO: string;
  CPA_CODCPA: string;
  TMO_CODTMO: string;
  VDE_CODVDE: string;
  PCC_INDCAL: string | number;
  PCC_TASIGV: number;
  PCC_TIPCAM: number;
  PCC_IMPBRU: any;
  PCC_PORDES: null;
  PCC_IMPDES: any;
  PCC_VALVTA: any;
  PCC_IMPIGV: any;
  PCC_IMPTOT: any;
  PCC_SITPCC: string;
  PCC_ORDCOM: null;
  PCC_INDTEX: null;
  PCC_REFPCC: string;
  PCC_IMPSEG: number;
  PCC_IMPFLE: number;
  PCC_INDEXP: string;
  PCC_PLAENT: number;
  PCC_FECVEN: null;
  PCC_DIACRE: number;
  PCC_INDIGV: null;
  PCC_PEDMER: null;
  PCC_ORDWEB: null;
  PCC_FECTRA: Date;
  CLI_CODPRO: string;
  CLI_AGETRA: null;
  PCC_INDANT: null;
  ZVE_CODZVE: string;
  PCC_OBSPCC: string;
  EDO_CODEDO: string;
  TLP_CODTLP: string;
  LPC_CODLPC: string;
  PCC_INDTCA: string;
  PCC_INDIMP: number;
  PCC_INDCOM: number;
  PCC_INDDPX: number;
  PCC_FECDES: null;
  TIE_CODTIE: string;
  PCC_FECENT: Date;
  PAI_CODPAI: null;
  UBI_CODPAI: null;
  PCC_DIAPLA: number;
  ALM_CODALM: string;
  PCC_TCALIB: number;
  PCC_INDSTA: string;
  TBD_NUMTBD: null;
  ANO_CODANO: string;
  MES_CODMES: string;
  CCO_CODCCO: null;
  CCO_CODANT: null;
  PCC_INDRES: number;
  PCC_IMPCAN: number;
  PCC_INDTER: string;
  PCC_NOIMDA: number;
  PCC_NUMCUO: number;
  PCC_FECCRE: Date;
  PCC_OBSPED: null;
  LDE_CODLDE: string;
  LDE_CODFAC: null;
  PCC_SUSVAL: number;
  PCC_SUSTOT: number;
  PCC_FECFAC: null;
  PCC_FECTER: null;
  PCC_USETER: string;
  PCC_REFPED: null;
  PAP_SECPAP: number;
  PCC_OBSEXP: string;
  PAP_INDMAX: number;
  SEP_NUMSEP: string;
  PCC_FECUPD: Date;
  PCC_CODUSE: string;
  SEC_CODSEC: string;
  CPD_CODCPD: string;
  PCC_INDOTN: null;
  PCC_OTRNOM: null;
  LDE_AGETRA: null;
  LDE_CODPRO: null;
  PCC_ENTVDE: number;
  TDO_CODTDO: string;
  PCC_NUMPEC: null;
  PCC_PTODES: null;
  PCC_PEDCOM: number;
  PCC_OBSFAC: null;
  PCC_ANUCOM: null;
  ALM_CODDES: null;
  PCC_DESGLO: number;
  ccs_codccs: null;
  lde_fisgui: null;
  pcc_pedant: null;
  PCC_PERFIN: number;
  pcc_impgui: any;
  pcc_impfac: any;
  pcc_rutext: any;
  aup_numaup: null;
  pcc_obsanu: null;
  cia_origen: null;
  cia_numpcc: null;
  pcc_numcot: string;
  detalle_pcd: DetallePcd[];
  cancelacion_pec: null;
  cuotas_pcu: CuotasPcu[];
  dias_pdi: DiasPdi[];
  cliente: { cli_nomcli: string };
  constructor(
  ) {
    // this.PCC_FECDOC = new Date();

    this.detalle_pcd = [];
    this.LDE_CODLDE = null;
    this.CPD_CODCPD = "P";
    this.TMO_CODTMO = "SO";
    this.MMO_CODMMO = "0206";
    this.PCC_INDCAL = "0";
    this.PCC_FECUPD = new Date();
    // this.PCC_FECENT = new Date();
    this.CPA_CODCPA = "112";
    this.PCC_SITPCC = "1";
    this.PCC_IMPSEG = 0;
    this.PCC_IMPFLE = 0;
    this.PCC_INDEXP = "0";
    this.PCC_PLAENT = 0;
    this.PCC_DIACRE = 0;
    this.PCC_INDTCA = "0";
    this.PCC_INDIMP = 0;
    this.PCC_INDCOM = 0;
    this.PCC_INDDPX = 0;
    this.TIE_CODTIE = "01";
    this.PCC_DIAPLA = 0;
    this.PCC_TCALIB = 0;
    this.PCC_INDSTA = "1";
    this.ANO_CODANO = ((new Date()).getFullYear()).toString();
    let mes = ((new Date()).getMonth() + 1).toString();
    this.MES_CODMES = (mes.length == 1) ? "0" + mes : mes;
    this.PCC_INDRES = 0;
    this.PCC_IMPCAN = 0;
    this.PCC_INDTER = "1";
    this.PCC_NOIMDA = 0;
    this.PCC_NUMCUO = 0;
    this.PCC_FECCRE = new Date();
    this.SUC_CODSUC = "01";
    this.PCC_PORDES = null;
    this.PCC_SUSVAL = 0;
    this.PCC_SUSTOT = 0;
    this.PCC_ENTVDE = 0;
    this.PCC_PEDCOM = 0;
    this.PCC_INDIGV = null;
    this.PCC_PEDMER = null;
    this.PCC_ORDWEB = null;
    this.PCC_FECTRA = new Date();
    this.PCC_REFPCC = "  ";
    this.PCC_OBSPCC = "  ";
    this.EDO_CODEDO = "  ";
    this.SEC_CODSEC = "  ";
    this.PCC_NUMPCC = "";
    this.PCC_OBSEXP = "Descuento"
    this.PAP_SECPAP = null;
    this.SEP_NUMSEP = null;
    this.PAP_INDMAX = 0;
    this.PCC_DESGLO = 0;
    this.PCC_PERFIN = 0;
    this.PCC_TASIGV = 18;
    this.PCC_TIPCAM = 0;
    this.TLP_CODTLP = '01'
    this.ALM_CODALM = '0001'
    this.pcc_impgui = false;
    this.pcc_impfac = false;
    this.pcc_rutext = false;
    this.cliente = { cli_nomcli: '' }
  }
}

export interface CuotasPcu {
  cia_codcia: string;
  suc_codsuc: string;
  pcc_numpcc: string;
  pcu_diapcu: number;
  pcu_fecsug: Date;
  pcu_indsta: string;
  pcu_coduse: string;
  pcu_fecuod: string;
}

export interface DiasPdi {
  cia_codcia: string;
  suc_codsuc: string;
  pcc_numpcc: string;
  dia_coddia: string;
  dia_coduse: string;
  dia_fecupd: string;
}

export class DetallePcd {
  PRD_DESESP: string;
  CIA_CODCIA: string;
  SUC_CODSUC: string;
  PCC_NUMPCC: string;
  PRD_CODPRD: string;
  PCD_SECPCD: string;
  PCD_CORPCD: string;
  PCD_CANSOL: number;
  PCD_CANAUT: number;
  PCD_CANTRA: number;
  PCD_CANATE: number;
  PCD_CANFAS: number;
  PCD_CANATS: number;
  PCD_CANFAC: number;
  PCD_CANSUS: number;
  PCD_CANDIS: number;
  PCD_CANVEN: number;
  PCD_CANDES: number;
  PCD_PREUNI: number;
  PCD_PRUIGV: number;
  PCD_IMPBRU: any;
  PCD_PORDES: number;
  PCD_IMPDES: number;
  PCD_VALVTA: any;
  PCD_IMPIGV: any;
  PCD_IMPTOT: any;
  PCD_VTASUS: number;
  PCD_TOTSUS: number;
  PCD_GLOPCD: null;
  PCD_OBSPCD: null;
  PCD_INDODE: number;
  PCD_INDDES: number;
  PCD_OBSPRE: null;
  PCD_USUPRE: null;
  PCD_FECPRE: null;
  PCD_INDSOF: number;
  PCD_INDSOD: number;
  PCD_INDAUT: number;
  PCD_INDATE: number;
  PCD_INDFAC: number;
  UME_CODVEN: string;
  PCD_ATSREG: number;
  PCD_AUTREG: number;
  PCD_FASREG: number;
  PCD_FACREG: number;
  PCD_DESREG: number;
  PCD_OBSADI: null;
  DCM_CODDCM: null;
  PCD_TIPDES: string;
  PCD_PRELIS: number;
  PCD_INDDPR: string;
  PRM_CODPRM: null;
  PCD_CANUNI: number;
  PCD_MEDUNI: null;
  PCD_UNISUS: number;
  PCD_VENFAC: number;
  PCD_CODUSE: string;
  PCD_FECUPD: Date;
  PCD_INDSTA: string;
  PCD_DESGLO: null;
  PCD_VENAUT: number;
  pcd_indesp: null;
  pcd_entrega: null;
  pcd_fecfac: null;
  seleccionado: boolean;
  datos_adicionales_pda: DatosAdicionalesPDA[];
  descuentos_pds: DescuentosPD[];
  entrega_pfe: EntregaPfe[];
  producto: {
    prd_desesp: string;
    ume_codume: string;
  }
  tin_codtin: string;

  constructor(
  ) {
    this.PCD_SECPCD = "01",
      this.PCD_CANFAC = 0;
    this.PCD_CANVEN = 0;
    this.PCD_ATSREG = 0;
    this.PCD_AUTREG = 0;
    this.PCD_CANATS = 0;
    this.PCD_CANDES = 0;
    this.PCD_CANDIS = 0;
    this.PCD_CANFAS = 0;
    this.PCD_CANTRA = 0;
    this.PCD_CANUNI = 0;
    this.PCD_DESREG = 0;
    this.PCD_FACREG = 0;
    this.PCD_FASREG = 0;
    this.PCD_INDATE = 0;
    this.PCD_INDAUT = 1;
    this.PCD_INDDES = 0;
    this.PCD_INDDPR = "1";
    this.PCD_INDFAC = 0;
    this.PCD_INDODE = 0;
    this.PCD_INDSOD = 1;
    this.PCD_INDSOF = 1;
    this.PCD_INDSTA = "1";
    this.PCD_TIPDES = "01";
    this.PCD_TOTSUS = 0;
    this.PCD_UNISUS = 0;
    this.PCD_VENAUT = 0;
    this.PCD_VENFAC = 0;
    this.PCD_VTASUS = 0;
    this.PCD_FECUPD = new Date();
    this.PCD_PRUIGV = 0;
    this.PCD_CANSOL = 1;
    this.PCD_CANAUT = 0;
    this.PCD_CANATE = 0;
    this.PCD_CANSUS = 0;
    this.PCD_IMPDES = 0;
    this.PCD_PORDES = 0;
    this.PCD_PREUNI = 0;
    this.seleccionado = false;

    this.descuentos_pds = [];

    this.producto = {
      prd_desesp: '',
      ume_codume: ''
    }
  }
}

export interface DatosAdicionalesPDA {
  cia_codcia: string;
  suc_codsuc: string;
  pcc_numpcc: string;
  prd_codprd: string;
  pda_corpda: string;
  pda_glopda: string;
  pda_fecupd: string;
  pda_coduse: string;
}

export class DescuentosPD {
  cia_codcia: string;
  suc_codsuc: string;
  pcc_numpcc: string;
  prd_codprd: string;
  pcd_secpcd: string;
  dco_coddco: string;
  pds_corpds: string;
  pds_pordes: number;
  pds_porori: number;
  pds_impdes: number;
  dde_numdde: null;
  pds_usuaut: null;
  pds_fecaut: null;
  pds_impcom: number;
  pds_incdes: number;
  pds_coduse: string;
  pds_indsta: string;
  constructor() {
    this.pds_pordes = 0;
    this.pds_impdes = 0;
    this.pds_indsta = '1';
  }
}

export class EntregaPfe {
  cia_codcia: string;
  suc_codsuc: string;
  pcc_numpcc: string;
  prd_codprd: string;
  pfe_fecent: Date;
  pfe_fecfac: null;
  pfe_canent: number;
  pfe_coduse: string;

  constructor() { }
}
