export class CuentaCorrientePrestamo {
  nroOperacion: any;
  fecha: any;
  estado: any;
  ctaCte: any;
  nroCuotas: any;
  moneda: any;
  importeMN: any;
  importeME: any;
  detalle: Array<DetallePrestamo>;
}

export class DetallePrestamo {
  ano: any;
  mes: any;
  frecuencia: any;
  impCuota: any;
  isEditing: boolean;

  constructor() {
    this.isEditing = true;
  }
}
