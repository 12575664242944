export class SituacionActivo {
  cia_codcia: string;
  saf_codsaf: string;
  saf_descri: string;
  saf_desabr: string;
  saf_indsta: string;
  saf_fecupd: Date;
  saf_coduse: string;

  constructor() {
    this.saf_indsta = '1';
    this.saf_fecupd = new Date();
  }
}
