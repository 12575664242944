import { Injectable } from "@angular/core";
import { HeaderReport } from "@data/interfaces/headers.report";
import * as pdfBuilder from "@utils/pdfmaker/builder/activo_fijo.pdfmaker";

@Injectable({
  providedIn: 'root'
})
export class ActivoFijoReportService {

  constructor() {}

  /**
   *
   * @param reportSelected
   * @param headers
   * @returns
   */
  verReporte(reportSelected: string, headers: HeaderReport): Promise<any> {
    console.log('reportSelected', reportSelected)
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "ACTIVO_FIJO_H":
          console.log('activo fijo historico')
          pdfBuilder.reporteActivoFijoHistorico(headers, null);
          resolve(true);
          break;
        case "ACTIVO_FIJO_HC":
          pdfBuilder.reporteActivoFijoHistoricoComponetizado(headers, null);
          resolve(true);
          break;
        case "ACTIVO_FIJO_U":
          pdfBuilder.reporteActivoFijoPorUbicación(headers, null);
          resolve(true);
          break;
        case "ACTIVO_FIJO_DMAF":
          pdfBuilder.reporteDepreciacionMensualPorActivo(headers, null);
          resolve(true);
          break;
        case "ACTIVO_FIJO_DMA":
          pdfBuilder.reporteDetalleMovimientoPorActivo(headers, null);
          resolve(true);
          break;
        case "ACTIVO_FIJO_RMC":
          pdfBuilder.reporteResumenMovimientoPorCuenta(headers, null);
          resolve(true);
          break;
        case "ACTIVO_FIJO_RMCL":
          pdfBuilder.reporteResumenMovimientosPorClase(headers, null);
          resolve(true);
        break;
        case "ACTIVO_FIJO_RMCG":
          pdfBuilder.reporteResumenMovimientoClaseVSGrupo(headers, null);
          resolve(true);
        break;
        case "ACTIVO_FIJO_RMD":
          pdfBuilder.reporteResumenMovimientoDetallado(headers, null);
          resolve(true);
        break;
        default:
          break;
      }
    })
  }

}
