import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PavSearchDialog } from './pav-search-dialog/pav-search.dialog';
import { Program } from 'src/app/models/program';
import { ClienteDialog } from 'src/app/admin/apps/ventas/pages/maestros/cliente';
import { OptionsData } from 'src/app/admin/apps/ventas/pages/maestros/cliente/cliente-dialog/cliente-dialog.component';
import { ProveedorDialog } from 'src/app/admin/apps/compras/pages/maestros/proveedor';
import { AuxiliarDialog } from 'src/app/admin/apps/contabilidad/pages/maestros/auxiliar';

interface OPTIONS_DATA {
  program: 'CLIENTES' | 'PRODUCTOS' | 'AUXILIARES' | 'CUENTAS' | 'PROVEEDORES',
  label: string
}

@Component({
  selector: 'pav-input-search',
  templateUrl: 'pav-input-search.component.html',
  styleUrls: ['pav-input-search.component.scss']
})
export class PavInputSearchComponent implements OnInit {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() codigo: string = null;
  @Input() descripcion: string = null;
  @Input() type: string;
  @Input() program: string;
  @Input() class: string;
  @Input() name: string;
  @Input() isResponsive: boolean;
  @Input() disabled: boolean;

  @Output() valueChange = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  buscar(): void {
    const dialogRef = this.dialog.open(PavSearchDialog, {
      width: '400px',
      data: {
        program: this.program,
        label: this.label
      } as OPTIONS_DATA,
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.codigo = result.codigo;
        this.descripcion = result.descripcion;
        this.valueChange.emit(result);
        console.log('result', result)
      }

    });
  }

  editar(): void {

    if (!this.codigo) return;

    if (Program.CLIENTES == this.program) {
      const dialogRef = this.dialog.open(ClienteDialog, {
        width: '100%',
        data: {
          id: this.codigo,
          search: undefined,
          isDialog: true
        } as OptionsData,
        panelClass: 'pav-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);

      });
    }
  }

  agregar(): void {
    if (Program.CLIENTES == this.program) {

      const dialogRef = this.dialog.open(ClienteDialog, {
        width: 'auto',
        data: {
          id: '0',
          search: '',
          isDialog: true
        } as OptionsData,
        panelClass: 'pav-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);

      });

    }

    if (Program.PROVEEDORES == this.program) {

      const dialogRef = this.dialog.open(ProveedorDialog, {
        width: 'auto',
        data: {
          id: '0',
          search: '',
          isDialog: true
        } as OptionsData,
        panelClass: 'pav-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);

      });

    }

    if( Program.AUXILIARES == this.program ) {
      const dialogRef = this.dialog.open(AuxiliarDialog, {
        width: 'auto',
        data: {
          id: '0',
          search: '',
          isDialog: true
        } as OptionsData,
        panelClass: 'pav-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);

      });
    }
    console.log(this.program)
  }

}
