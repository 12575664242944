<h1 mat-dialog-title>Anexo</h1>
<button mat-icon-button class="button-close" style="float: right; margin-top: -60px;" (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-content>
  <mat-tab-group #tab [selectedIndex]="selectedIndex">
    <mat-tab disabled="true" label="Anexos" labelClass="mat-tab-label-0-0">
      <mat-form-field class="pavso_input_search">
        <mat-label>{{LABELS_NAME.BTN_SEARCH}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <div class="mat-elevation-z0">

        <table mat-table [dataSource]="dataSourceList" matSort>
          <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <button matTooltip="Editar" (click)="verDetalle(row.aef_codaef)"
                mat-icon-button color="primary">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="aef_codaef">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.aef_codaef}} </td>
          </ng-container>

          <ng-container matColumnDef="aef_descri">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.aef_descri}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsList"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsList;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

          </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[3, 5, 10, 25]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab disabled="true" label="Detalle" labelClass="mat-tab-label-0-1">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="anexo.aef_codaef" matInput placeholder="Código" name="aef_codaef" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l9">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="anexo.aef_descri" matInput placeholder="Descripción" name="aef_descri" required>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <div class="mat-elevation-z0">
            <table mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="ccn_codccn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.ccn_codccn}} </td>
              </ng-container>

              <ng-container matColumnDef="ccn_descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.ccn_descri}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">No se encontraron registros</td>

              </tr>

            </table>

            <mat-paginator #paginator [pageSizeOptions]="[3, 5, 10, 25]"></mat-paginator>
          </div>
        </div>
      </div>
      <br>
      <mat-selection-list>
        <mat-list-option *ngFor="let cuenta of cuentas">
          {{cuenta.ccn_descri}}
        </mat-list-option>
      </mat-selection-list>
      <br>
      <div class="row">
        <div class="col s6">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" #text matInput placeholder="Código" name="codigo" required>
          </mat-form-field>
        </div>
        <div class="col s6">
          <button type="button" color="primary" (click)="buscarCuentas(text)" mat-flat-button>Buscar</button>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <button type="button" color="primary" mat-flat-button>Copiar Todo</button>
          &nbsp;
          <button type="button" color="primary" mat-flat-button (click)="regresar()">Volver</button>
        </div>

      </div>

    </mat-tab>
  </mat-tab-group>
</div>
