<h1 mat-dialog-title>Copiar partes</h1>
<div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" [ngStyle]="{border: '1px solid #dbdbdb', borderRadius: '10px'}">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filtros avanzados
        </mat-panel-title>

      </mat-expansion-panel-header>

      <div class="row">
        <div class="col s12 m12 l4">
          <select-search #sMotivo [label]="'Motivo'" [placeholder]="'Ej. COMPRAS LOCALES'" [value]="'mmo_codmmo'"
            [name]="'mmo_codmmo'" [description]="'mmo_descri'" [disabled]="loaderData" [data]="data.motivos"
            (cambioSelect)="fields.motivo = $event" [model]="fields.motivo">
          </select-search>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input [(ngModel)]="fields.fecha" name="fecha" matInput [matDatepicker]="pickerFecha">
            <mat-datepicker-toggle matSuffix [for]="pickerFecha"></mat-datepicker-toggle>
            <mat-datepicker #pickerFecha></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <select-search [label]="'Auxiliar'" [placeholder]="'Ej. ATR'" [value]="'cli_codcli'" [name]="'cli_codcli'"
            [description]="'cli_nomcli'" [data]="data.clientes" (cambioSelect)="fields.auxiliar = $event"
            [model]="fields.auxiliar">
          </select-search>
        </div>

      </div>
      <div class="row">
        <div class="col s12 m12 l4">
          <select-search [label]="'Centro de costo'" [placeholder]="'Ej. 10001'" [value]="'ccs_codccs'"
            [name]="'ccs_codccs'" [description]="'ccs_descri'" [data]="data.centros"
            (cambioSelect)="fields.centroCosto = $event" [model]="fields.centroCosto">
          </select-search>
        </div>
        <div class="col s12 m12 l4">
          <select-search #sTipoDocRef01 [label]="'Tip. Doc. (01)'" [placeholder]="'Ej. FACTURA'" [name]="'TDO_CODTDO'"
            [value]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'TDO_DESLAR'" [data]="data.tiposDocumento"
            (cambioSelect)="fields.tipoDocRef = $event" [model]="fields.tipoDocRef"></select-search>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label># Referencia (01)</mat-label>
            <input #iNumDocRef01 matInput placeholder="Referencia (FAC)" [(ngModel)]="fields.numDocRef"
              name="mac_numre1" [disabled]="loaderData" (keyup.enter)="enterReferencia01()">
          </mat-form-field>
        </div>

      </div>
      <div align='center'>
        <button type="button" (click)="buscar()" mat-mini-fab color="primary"
          aria-label="Example icon button with a menu icon">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </mat-expansion-panel>

  </mat-accordion>
  <br>
  <div class="row">
    <div class="col s12 m12 l3">

      <select-search [label]="'Período'" [placeholder]="'Ej. 2024 02'" [value]="'description'" [name]="'description'"
        [description]="'description'" [data]="periodos" (cambioSelect)="fields.periodo = $event; seleccionarPeriodo()"
        [model]="fields.periodo">
      </select-search>


    </div>

    <div class="col s12 m12 l3">
      <mat-form-field>
        <mat-label>Tipo Doc.</mat-label>
        <mat-select [(ngModel)]="fields.tipoDoc" name="tipoDoc" (selectionChange)="seleccionarTipoDoc()">
          <mat-option [value]="'ING'">
            Ingreso
          </mat-option>
          <mat-option [value]="'SAL'">
            Salida
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col s12 m12 l3">
      <select-search #sAlmacen [label]="'Almacén'" [placeholder]="'Ej. ALMACÉN P.T.'" [value]="'ALM_CODALM'"
        [name]="'ALM_CODALM'" [description]="'ALM_DESCRI'" [data]="data.almacenes"
        (cambioSelect)="fields.almacen = $event; seleccionarAlmacen()" [model]="fields.almacen">
      </select-search>
    </div>

    <div class="col s12 m12 l3">
      <select-search [multiple]="true" [label]="'Nro Parte'" [placeholder]="'Ej. 000001'" [value]="'mac_nummac'"
        [name]="'mac_nummac'" [description]="'description'" [data]="partes" (cambioSelect)="fields.partes = $event"
        [model]="fields.partes" [multiple]="true">
      </select-search>
    </div>

  </div>
  <div align="end">
    <button [class.spinner]="loader" [disabled]="loader" mat-raised-button color="primary" (click)="copiarDetalle()" type="button">Copiar Detalle</button>

  </div>
</div>
