import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';

@Injectable({
    providedIn: 'root'
})
export class PrinterService {

    constructor(
        private http: HttpClient,
        private _configurationService: ConfigurationService
    ) {}

    /**
     *
     * @returns
     */
    obtenerConfiguracionesImpresora(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/configurations/impresora/listar/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerConfiguracionImpresora(usuario: string, id: string): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/configurations/impresora/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${usuario}/${id}`);

    }

    registrarConfiguracionImpresora(payload): Observable<any> {
      return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/configurations/impresora/crear`, payload)
    }

    actualizarConfiguracionImpresora(payload): Observable<any> {
      return this.http.put<any>(`${this._configurationService.obtenerUrl()}/api/configurations/impresora/modificar`, payload)
    }

}
