export class PromocionVenta {
  numProm: any;
  entraVigencia: any;
  fechaTermino: any;
  estado: any;
  descripcion: any;
  detalle_productos: Array<ProductoPromocion>
  detalle_auspiciadores: Array<ProductoAuspiciador>

  constructor() {
    this.detalle_productos = [];
    this.detalle_auspiciadores = [];
  }
}

export class ProductoPromocion {
  codigo: any;
  descripcion: any;
  um: any;
  cantidad: any;
  descuento: any;
}

export class ProductoAuspiciador {
  codigo: any;
  descripcion: any;
  um: any;
  cantidad: any;
  descuento: any;
}
