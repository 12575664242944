import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { numberWithCommas } from '@utils/formats/number.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { CajaBancoService } from 'src/app/services';

class FiltroCuentaPagar {
  auxiliar: string;
  moneda: string;
  fechaMax: Date;
}
@Component({
  selector: 'rendiciones-por-colaborador-dialog',
  templateUrl: './rendiciones-por-colaborador-dialog.component.html',
  styleUrls: ['./rendiciones-por-colaborador-dialog.component.css']
})
export class RendicionesPorColaboradorDialog implements OnInit, OnDestroy {

  displayedColumns: string[] = ['select', 'cta_cte', 'tip_doc', 'nro_docu', 'fec_emision', 'fec_vencimiento', 'moneda', 'importe_documento', 'importe_abono', 'importe_saldo', 'glosa', 'cuenta', 'auxiliar', 'ind_estado', 'tipo_cambio', 'ind_signo', 'periodo'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public driver$: Subscription;

  proveedores: any[] = [];

  importeMN: any = 0;
  importeME: any = 0;

  fechaActual: Date;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  selection = new SelectionModel<any>(true, []);

  cliente: any;

  year: any;
  month: any;

  moneda: any;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  loaderData: boolean;

  cuentasPorCobrar: any[];

  filtro: FiltroCuentaPagar;

  constructor(
    private _cajaBancoService: CajaBancoService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<RendicionesPorColaboradorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.fechaActual = new Date();

    this.filtro = new FiltroCuentaPagar();

  }

  ngOnInit(): void {
    this.proveedores = this.data.clientes;
    this.filtro.auxiliar = this.data.cli_codcli;
    this.obtenerCuentasxPagar(this.filtro.auxiliar);

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {

    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  calculaTotales(): void {
    console.log('calculandoTotales')
    this.importeMN = 0;
    this.importeME = 0;
    console.log(this.selection.selected)
    this.selection.selected.forEach(item => {

      if(item.moneda == 'DO') {
        this.importeMN += (item.importe_saldo * item.ind_signo);
        this.importeME += (item.importe_saldo * item.ind_signo) * this.data.tipo_cambio;
      } else {
        this.importeMN += (item.importe_saldo * item.ind_signo) / this.data.tipo_cambio;
        this.importeME += item.importe_saldo * item.ind_signo;
      }
    });

    this.importeMN = this.importeMN.toFixed(2);
    this.importeME = this.importeME.toFixed(2);
  }

  obtenerCuentasxPagar(cliente) {

    this.loaderData = true;

    this.driver$ = this._cajaBancoService.obtenerCuentaxPagarPorPeriodo(this.data.periodo).subscribe(
      response => {
        console.log('RENDICIONES POR COLABORADOR', response)
        const cuentasPorPagar = response.filter(item => (item.cli_codocm.trim() == this.data.cli_codcli.trim() && item.importe_saldo != 0) )
        this.dataSource = fillTable(cuentasPorPagar, this.paginator, this.sort);
        if(cuentasPorPagar.length > 0) {
          this.selection.select(...this.dataSource.data),
          this.calculaTotales();
        }
        this.cuentasPorCobrar = response;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  formatoImporte(importe: number): string {
    return numberWithCommas(importe);
  }

  aceptar(): void {
    this.dialogRef.close(this.selection.selected)

  }

  seleccionarCliente(cliente): void {
    this.obtenerCuentasxPagar(cliente);
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  buscar(): void {

    this.obtenerCuentasxPagar(this.filtro.auxiliar? this.filtro.auxiliar : '');
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.driver$,
      this.period$
    ])
  }

}
