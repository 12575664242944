<h1 mat-dialog-title>Documentos por reembolsar</h1>

<div class="row">
  <div class="col s12 m12 l3">
    <select-search [label]="'Auxiliar / Proveedor'" [placeholder]="'Ej. TORRES'" [name]="'cli_codcli'"
      [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="proveedores"
      (cambioSelect)="filtro.auxiliar = $event;" [model]="filtro.auxiliar">
    </select-search>

  </div>
  <div class="col s12 m12 l3">
    <mat-form-field>
      <mat-label>Moneda</mat-label>
      <mat-select [(ngModel)]="filtro.moneda" name="moneda">
        <mat-option value="SO">
          Soles
        </mat-option>
        <mat-option value="DO">
          Dólares
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col s12 m12 l3">
    <mat-form-field>
      <mat-label>Hasta la fecha {{fechaActual | date: 'dd/MM/yyyy'}}</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
  </div>
  <div class="col s12 m12 l3">
    <button (click)="buscar()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>


<div mat-dialog-content>
  <br>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">

    </div>
    <div class="pav-table-header-title">
      Documentos
    </div>
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
    </div>
  </div>
  <div class="mat-elevation-z0 overflow-x">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null; calculaTotales();" [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="cta_cte">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
        <td mat-cell *matCellDef="let row" data-label="Cta. Cte."> {{row.cta_cte}} </td>
      </ng-container>
      <ng-container matColumnDef="tip_doc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> TD </th>
        <td mat-cell *matCellDef="let row" data-label="TD"> {{row.tip_doc}} </td>
      </ng-container>
      <ng-container matColumnDef="nro_docu">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro.Docum. </th>
        <td mat-cell *matCellDef="let row" data-label="Código"> {{row.nro_docu}} </td>
      </ng-container>
      <ng-container matColumnDef="fec_emision">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Emi. </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi."> {{row.fec_emision | date: 'dd/MM/yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="fec_vencimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Venc. </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Venc."> {{row.fec_vencimiento | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="moneda">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> MO </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi."> {{row.moneda}} </td>
      </ng-container>
      <ng-container matColumnDef="importe_documento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Documento </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi.">
          <div [ngStyle]="{width: '80px', textAlign: 'right', paddingRight: '20px'}">
            {{row.importe_documento.toFixed(2)}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="importe_abono">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Abono </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi.">
          <div [ngStyle]="{width: '75px', textAlign: 'right', paddingRight: '20px'}">
            {{row.importe_abono.toFixed(2)}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="importe_saldo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi.">
          <div [ngStyle]="{width: '45px', textAlign: 'right', paddingRight: '20px'}">

            {{(row.importe_saldo * row.ind_signo).toFixed(2)}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="glosa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi.">
          <div [ngStyle]="{width: '250px'}">
            {{row.glosa}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="cuenta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi."> {{row.cuenta}} </td>
      </ng-container>
      <ng-container matColumnDef="auxiliar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi."> {{row.auxiliar}} </td>
      </ng-container>
      <ng-container matColumnDef="ind_estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi."> {{row.ind_estado}} </td>
      </ng-container>
      <ng-container matColumnDef="tipo_cambio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> TC </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi."> {{row.tipo_cambio}} </td>
      </ng-container>
      <ng-container matColumnDef="ind_signo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Signo </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi."> {{row.ind_signo}} </td>
      </ng-container>
      <ng-container matColumnDef="periodo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Período </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Emi."> {{row.ano + row.mes}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="17">No se encontraron registros "{{input.value}}"</td>

      </tr>

    </table>
  </div>
  <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

  <br>

</div>

<div class="row mat-elevation-z2" [ngStyle]="{paddingTop: '15px', marginTop: '20px', borderRadius: '8px'}">
  <div class="col s12 m12 l4">
    <mat-form-field>
      <mat-label>Período</mat-label>
      <input [value]="data.periodo" matInput readonly [ngStyle]="{textAlign: 'right'}">
    </mat-form-field>
  </div>
  <div class="col s12 m12 l4">
    <mat-form-field>
      <mat-label>Importe MN</mat-label>
      <input [value]="formatoImporte(importeME)" matInput readonly [ngStyle]="{textAlign: 'right'}">
    </mat-form-field>
  </div>
  <div class="col s12 m12 l4">
    <mat-form-field>
      <mat-label>Importe ME</mat-label>
      <input [value]="formatoImporte(importeMN)" matInput readonly [ngStyle]="{textAlign: 'right'}">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button class="pav-button-cancel" mat-flat-button (click)="cancelar()">Cancelar</button>
  <button class="pav-button-action" color="primary" mat-flat-button
    (click)="aceptar()">{{buttonsName.BTN_ACCEPT}}</button>
</div>
