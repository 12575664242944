<app-pavso-title-section [title]="'Migración cliente masivo'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div>
      <!-- <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
        matTooltip="Generar tipo de cambio" (click)="obtenerTipoCambio()">
        <mat-icon>currency_exchange</mat-icon>
      </button> -->
    </div>
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="'Buscar'" #input>
    </div>
  </div>

  <div (paste)="onPaste($event)" [ngStyle]="{marginTop: '10px'}">

    <div [ngStyle]="{display: 'flex', justifyContent: 'space-between'}">
      <div [ngStyle]="{display: 'flex', alignItems: 'center', marginBottom: '5px'}">
        <mat-icon matTooltip="Seleccionar tabla para pegar datos copiados desde Excel">info_outline</mat-icon>

        <div type="button" [ngStyle]="{cursor: 'pointer', fontSize: '14px', color: '#4792f9', marginLeft: '5px'}"
          (click)="seleccionarTabla()" color="primary">
          Seleccionar tabla
        </div>

      </div>
      <div>

        <a type="button" mat-button color="primary"
          href="https://pavsoperu-my.sharepoint.com/:x:/g/personal/alfredo_yupanqui_pavsolutions_com/EThTU3DEn5FFur50OVlBghYBW7j5ynnrnsjAzNrkY5_nxg?e=PveVSs"
          target="_blank">Descargar plantilla</a>

      </div>
    </div>

    <div [ngStyle]="{border: tablaSeleccionada ? '3px solid #32b17b': '', borderRadius: '18px'}">

      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">
        </div>
        <div class="pav-table-header-title" [ngStyle]="{display: 'flex', alignItems: 'center'}">
          <p>Registros</p>
          &nbsp;/&nbsp;
          <p [ngStyle]="{color: 'white'}" *ngIf="loaderReg">
            Cargados {{cargados}} de {{totalACargar}} registros
          </p>
        </div>
        &nbsp;

        &nbsp;
        <div>
          <button [disabled]="loaderData" (click)="limpiar()" type="button" mat-mini-fab color="accent"
            [ngStyle]="{background: 'rgb(199 199 199)'}" aria-label="Example icon button with a menu icon"
            matTooltip="Limpiar filtros">
            <mat-icon svgIcon="mop-icon"></mat-icon>
          </button>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Limpiar</span>
          </p>
        </div>

        &nbsp;
        <div>
          <button [disabled]="loaderData" (click)="cargarClientes()" type="button" mat-mini-fab color="accent"
            aria-label="Example icon button with a menu icon" matTooltip="Guardar datos">
            <mat-icon svgIcon="grabar"></mat-icon>
          </button>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Guardar</span>
          </p>
        </div>
        <!-- <div>
          <div class="pav-button-icon-add-multiple" matTooltip="Agregar múltiple" (click)="agregarProductoMultiple()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon [ngStyle]="{color: 'white'}">library_add</mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
          </p>
        </div> -->
        <!-- <div>
          <div class="pav-button-icon-add" matTooltip="Agregar ítem barra" (click)="agregarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-circle"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
          </p>
        </div> -->
        <!-- <div>
          <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="eliminarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="remove"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
          </p>
        </div> -->
        <!-- <div>
          <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-outline"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Insertar</span>
          </p>
        </div> -->
      </div>

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData || loaderReg"></mat-progress-bar>

      <div class="mat-elevation-z0 overflow-x">

        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <!-- <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div> -->
            </td>
          </ng-container>

          <ng-container matColumnDef="cli_codcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.cli_codcli}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_tipper">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Persona </th>
            <td mat-cell *matCellDef="let row" data-label="Proveedor" class="td-responsive"> {{row.cli_tipper}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_apepat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido Paterno </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_apepat}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_apemat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido Materno </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_apemat}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombres </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_nombre}} </td>
          </ng-container>

          <ng-container matColumnDef="did_coddid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.did_coddid}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_numruc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> RUC </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_numruc}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_numdni">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DNI </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_numdni}} </td>
          </ng-container>

          <ng-container matColumnDef="pai_codpai">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.pai_codpai}} </td>
          </ng-container>

          <ng-container matColumnDef="ubs_codubs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubigeo </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.ubs_codubs}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_indcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ind. Cliente </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_indcli}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_indpro">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ind. Proveedor </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_indpro}} </td>
          </ng-container>


          <ng-container matColumnDef="cli_indtra">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ind. Trabajador </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_indtra}} </td>
          </ng-container>


          <ng-container matColumnDef="cli_indgas">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ind. Aux. Gasto </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_indgas}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_inddom">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ind. Domiciliado </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_inddom}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_numcel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Celular </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_numcel}} </td>
          </ng-container>

          <ng-container matColumnDef="vde_codvde">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.vde_codvde}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda Línea de Crédito </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.tmo_codtmo}} </td>
          </ng-container>

          <ng-container matColumnDef="clc_creasi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Línea de Crédito </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.clc_creasi}} </td>
          </ng-container>

          <ng-container matColumnDef="clc_codgru">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Grupo Cliente </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.clc_codgru}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="30">No se encontraron registros</td>
          </tr>

        </table>

      </div>

    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>

  <div class="row">
    <div class="col s12 m12 l6">
      <div class="mat-elevation-z0">
        <h1>Clientes no registrados</h1>
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <div class="overflow-x">
          <table mat-table [dataSource]="dataSourceClientesNoRegistrados" matSort>

            <ng-container matColumnDef="cli_codcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td #container mat-cell *matCellDef="let row" data-label="Código" class="td-responsive">
                {{row.cli_codcli}}
                <!-- <mat-icon class="pav-icon-copy" (click)="copiar(row.cli_codcli)" ngxClipboard [cbContent]="row.cli_codcli" matTooltip="Copiar código" [ngStyle]="{cursor: 'pointer'}">content_copy</mat-icon> -->
              </td>
            </ng-container>

            <ng-container matColumnDef="cli_nomcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cli_glocli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.cli_glocli}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsClientesNoRegistrados"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsClientesNoRegistrados;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6">No se encontraron registros</td>

            </tr>

          </table>
        </div>

        <!-- <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="cambiarPagina($event)"></mat-paginator> -->

          <mat-paginator #paginatorClientesNoRegistrados showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

      </div>
    </div>
    <div class="col s12 m12 l6"></div>
  </div>
</div>
