import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { GrupoFlujoCaja } from "src/app/models/caja-banco/grupo_flujo_caja";
import { CajaBancoService } from "src/app/services";
import { GrupoFlujoCajaService } from "src/app/services/api/tesoreria/maestros/grupo-flujo-caja.service";

@Component({
  selector: 'grupo-flujo-form',
  templateUrl: './grupo-flujo-caja-form.component.html',
  styleUrls: ['./grupo-flujo-caja-form.component.scss']
})
export class GrupoFlujoCajaFormComponent implements OnInit, OnDestroy {

  grupoFlujoCaja: GrupoFlujoCaja;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  flujoCaja$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _cajaBancoService: CajaBancoService,
    private _grupoFlujoCajaService: GrupoFlujoCajaService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _dialogService: DialogService
  ) {

    this.grupoFlujoCaja = new GrupoFlujoCaja();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=>{

      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(id == '0') return;

      this.flujoCaja$ = this._grupoFlujoCajaService.obtenerGrupoFlujoCajas().subscribe(
        ([grupo]) => {
          this.grupoFlujoCaja = grupo;
        },
        error => this._snackBarService.showError(error.error.msg, "OK")

      )

    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarGrupoFlujo(f): this.actualizarGrupoFlujo(f);
  }

  registrarGrupoFlujo(f: NgForm): void {
    this._grupoFlujoCajaService.registrarGrupoFlujoCaja(this.grupoFlujoCaja).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Grupo flujo de caja registrado', '400px', '400px', '')
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarGrupoFlujo(f: NgForm): void {
    this._grupoFlujoCajaService.actualizarGrupoFlujoCaja(this.grupoFlujoCaja).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Grupo flujo de caja actualizado', '400px', '400px', '')
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-cajabanco/grupos-flujo-de-caja']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.flujoCaja$, this.loading$]);
  }

}
