export class OrdenPago {
  CIA_CODCIA: string;
  ANO_CODANO: string;
  MES_CODMES: string;
  SCO_CODSCO: string;
  LDC_CORLDC: string;
  PBA_FECORD: Date;
  PBA_FECCHE: Date;
  CBA_CODCBA: string;
  CBA_CODDES: string;
  TMO_CODTMO: string;
  PBA_TIPCAM: number;
  TDO_CODTDO: string;
  PBA_NUMDOC: string;
  TDO_CODREF: any;
  PBA_NUMREF: string;
  REC_NUMREC: any;
  PBA_ORDPAG: string;
  PBA_GLOPBA: string;
  PBA_DEBHAB: string;
  PBA_IMPSOL: number;
  PBA_IMPDOL: number;
  PBA_MONLIQ: number;
  CLI_CODCLI: string;
  cliente: { cli_nomcli: string };
  PBA_FLALIQ: string;
  PBA_INDENT: string;
  PBA_FECENT: any;
  PBA_FECCON: Date;
  PBA_USUCON: any;
  PBA_INDCON: number;
  ANO_ANOCON: any;
  MES_MESCON: any;
  EDO_CODEDO: string;
  LEA_CODLEA: any;
  PBA_FECVEN: Date;
  VDE_CODVDE: any;
  COB_CODCOB: any;
  ORI_CODASI: string;
  ACA_CODASI: string;
  PBA_SERRET: any;
  PBA_ARCTRA: any;
  PBA_INDSTA: string;
  PBA_CODUSE: string;
  PBA_FECUPD: string;
  SUC_CODSUC: string;
  CBC_NUMCHE: any;
  PBA_INDPAG: number;
  PBA_USUENT: any;
  PBA_CODCLI: string;
  PBA_NUMCAN: number;
  pba_monto: number;
  cco_codref: any;
  pba_intanu: any;
  pba_imppor: any;
  pba_impint: any;
  pba_impseg: any;
  lpb_codlpb: any;
  fca_codfca: string;
  cuentaRendir: string;
  pba_plzdia: number;
  detalle_ldi: Array<ItemDetalleOrdenPago>;
  operacionFrecuente: string;

  TGA_CODTGA: string;

  constructor() {
    this.detalle_ldi = [];
    this.SUC_CODSUC = '01';
    this.PBA_FECORD = new Date();

    this.PBA_FECVEN = new Date();
    this.PBA_INDSTA = '1';

    this.TGA_CODTGA = '0000';

    this.PBA_IMPSOL = 0;
    this.PBA_IMPDOL = 0;
    this.PBA_NUMREF = '';
    this.PBA_ORDPAG = '';
    this.PBA_MONLIQ = 0;
    this.PBA_FLALIQ = '';
    this.PBA_INDENT = '';
    this.PBA_INDCON = 0;
    this.EDO_CODEDO = '';
    this.ORI_CODASI = '';
    this.ACA_CODASI = '';
    this.SUC_CODSUC = '01';
    this.TMO_CODTMO = 'SO';

    this.LDC_CORLDC = '';
    // this.PBA_DEBHAB = '2';

    this.cliente = { cli_nomcli: '' }

  }
}

export class ItemDetalleOrdenPago {
  CIA_CODCIA: string;
  ANO_CODANO: string;
  MES_CODMES: string;
  SCO_CODSCO: string;
  LDC_CORLDC: string;
  LDI_CORLDI: string;
  CCN_CODCCN: string;
  CLI_CODCLI: string;
  CCO_CODCCO: any;
  TMO_CODTMO: string;
  LDI_INDDHA: string;
  LDI_TIPCAM: number;
  LDI_TCAMEU: number;
  LDI_IMPNAC: number;
  LDI_IMPMEX: number;
  LDI_INDCCO: string;
  TDO_CODTDO: string;
  LDI_DOCREF: string;
  TDO_DOCREF: string;
  LDI_NUMREF: string;
  LDI_GLOLDI: string;
  FPA_CODFPA: string;
  REF_CODREF: string;
  LDI_INDDES: number;
  LDI_FECEMI: Date;
  LDI_FECVEN: any;
  CCS_CODCCS: string;
  SUC_CODSUC: string;
  LDI_INDSTA: string;
  LDI_FECUPD: string;
  LDI_CODUSE: string;
  REC_NUMREC: string;
  VDE_CODVDE: string;
  ldi_impsal: number;
  fca_codfca: string;
  OCM_NUMOCM: string;
  ldi_indccl: number;
  ldi_useccl: string;
  ldi_fecccl: string;
  ldi_anoccl: string;
  ldi_mesccl: string;
  ldi_gloref: string;
  ldi_impamo: number;
  ldi_impint: number;
  ldi_impseg: number;
  ldi_imppor: number;
  ldi_impcuo: number;
  ldi_impigv: number;
  ind_ctacte: number;
  ori_codfac: string;
  ldc_corfac: string;
  isEditing: boolean;
  ctacte: {
    cco_impdoc: any;
    cco_salcco: any;
    tmo_codcco: string;
  };
  dias: number;
  ldi_salliq: number;
  cuenta: {
    ccn_descri: string;
    ccn_indana: number;
    ccn_indcco: string;
    ccn_indccs: number;
  }

  constructor() {
    this.ctacte = {
      cco_impdoc: null,
      cco_salcco: null,
      tmo_codcco: ''
    };
    this.cuenta = {
      ccn_descri: '',
      ccn_indana: 0,
      ccn_indcco: '0',
      ccn_indccs: 0
    };
    this.isEditing = false;
    this.dias = 0;
  }
}
