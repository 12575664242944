import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import KeenSlider, { KeenSliderInstance } from "keen-slider";

@Component({
  selector: 'pav-tutorial-carousel',
  templateUrl: './pav-tutorial-carousel.component.html',
  styleUrls: [
    './pav-tutorial-carousel.component.css',
    '../../../../../../node_modules/keen-slider/keen-slider.min.css'
  ]
})
export class PavTutorialCarousel implements OnDestroy{
  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>

  currentSlide: number = 0
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null

  constructor(
    public dialogRef: MatDialogRef<PavTutorialCarousel>,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
      })
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ]
    })
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  comenzar():void {
    this.dialogRef.close();
  }
}
