<app-pavso-title-section [title]="'Consulta de documentos'" [module]="'Compras'"></app-pavso-title-section>

<div class="pav-table-search-container">
  <div>
    <mat-form-field>
      <mat-label>Proveedor</mat-label>
      <mat-select>
        <mat-option *ngFor="let proveedor of proveedores" [value]="proveedor.cli_codcli">
          {{proveedor.cli_nomcli}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
  </div>
</div>
<br>
<div class="mat-elevation-z0">
  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Todos los documentos</div>
    <div class="pav-separator"></div>

    <div class="pav-table-search">
      <b [ngStyle]="{color: 'white'}">Descargar: </b>
      &nbsp;
      <button mat-flat-button color="primary">CDR</button>
      &nbsp;
      <button mat-flat-button color="accent">XML</button>
      &nbsp;
      <button mat-flat-button color="warn">PDF</button>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <div class="overflow-x">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones" class="td-responsive">

          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-compras/registro-de-compra', row.ano + row.mes + row.subdiario + row.correlativo]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>

          </div>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="cliente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Razon Social </th>
        <td mat-cell *matCellDef="let row" data-label="Período" class="td-responsive"> {{row.cliente}} </td>
      </ng-container>

      <ng-container matColumnDef="nro_comprobante">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante </th>
        <td mat-cell *matCellDef="let row" data-label="subdiario" class="td-responsive"> {{row.nro_comprobante}} </td>
      </ng-container>

      <ng-container matColumnDef="f_emision">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> F. Emisión </th>
        <td mat-cell *matCellDef="let row" data-label="Correlativo" class="td-responsive"> {{row.f_emision}} </td>
      </ng-container>

      <ng-container matColumnDef="f_vencimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> F. Vencimiento </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo Documento" class="td-responsive"> {{row.f_vencimiento}}
        </td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let row" data-label="Nro. Documento" class="td-responsive"> {{row.estado}}
        </td>
      </ng-container>

      <ng-container matColumnDef="monto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
        <td mat-cell *matCellDef="let row" data-label="Cod. Proveedor" class="td-responsive"> {{row.monto}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">No se encontraron registros "{{input.value}}"</td>
      </tr>

    </table>

  </div>

  <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
