import { Injectable } from '@angular/core';
import { utils, writeFileXLSX } from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class InventarioExcelService {

  constructor() {}

  exportExcelSaldosProducto(reportData) {
    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }

  exportExcelMovimientosAlmacen(reportData) {
    const ws = utils.json_to_sheet(reportData.data);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }


  exportExcelSaldosLote(reportData) {
    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }

  exportExcelKardexLote(reportData) {
    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }

  reporteDinamicoTest() {
    const ws = utils.json_to_sheet([ { Name: "SheetJS", Index: 0 }]);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, "SheetJSAngularAoO.xlsx");
  }

  generarExcelStockMinimo(reportData: {data: Array<any>, title: string}) {
    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }
}
