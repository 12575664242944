<app-pavso-title-section [title]="'Valorización de inventarios'" [module]="'Almacén'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <mat-horizontal-stepper>
        <mat-step label="Actualiza P/U desde Orden de Compra">
            <div class="pavso-content-stepper" align="center">
                <button mat-flat-button color="primary">Actualizar</button>
            </div>
            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Tabla</div>
            </div>
            <div class="mat-elevation-z0 overflow-x">
              <table mat-table [dataSource]="dataSource" matSort >
                  <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                          <button matTooltip="Editar" [routerLink]="['/modulo-almacen/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                              <mat-icon>edit</mat-icon>
                          </button>
                      </td>
                  </ng-container>

                  <ng-container matColumnDef="anio">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                      <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
                  </ng-container>

                  <ng-container matColumnDef="mes">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
                      <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                  </ng-container>

                  <ng-container matColumnDef="almacen">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="tipo_doc">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="parte">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> #Parte </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="cod_producto">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Producto </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="item">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ítem </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="secuencia">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Secuencia </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="descripcion_producto">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción del Producto </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="cantidad">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="costo_unitario">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo Unitario </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="motivo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
                      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="14">No se encontraron registros</td>
                  </tr>

              </table>

            </div>
            <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            <br>
            <div>
                <button mat-button matStepperNext>Siguiente</button>
            </div>
        </mat-step>

        <mat-step label="Valorización de productos">
            <div class="pavso-content-stepper" align="center">
                <button mat-flat-button color="primary">Actualizar</button>
            </div>
            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Tabla</div>
            </div>
            <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSource1" matSort >
                    <ng-container matColumnDef="acciones">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                            <button matTooltip="Editar" [routerLink]="['/modulo-almacen/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="anio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                        <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
                    </ng-container>

                    <ng-container matColumnDef="mes">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
                        <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                    </ng-container>

                    <ng-container matColumnDef="almacen">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo_doc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="parte">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> #Parte </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Producto </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="item">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ítem </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="secuencia">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Secuencia </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="descripcion_producto">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción del Producto </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cantidad">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="costo_unitario">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo Unitario </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="motivo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="14">No se encontraron registros</td>
                    </tr>

                </table>

            </div>
            <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            <br>
            <div>
                <button mat-button matStepperPrevious>Atrás</button>
                <button mat-button matStepperNext>Siguiente</button>
          </div>
        </mat-step>

        <mat-step label="Extrae Costo Unitario Combos">
            <div class="pavso-content-stepper" align="center">
                <button mat-flat-button color="primary">Actualizar</button>
            </div>
            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Tabla</div>
            </div>
            <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSource2" matSort >
                    <ng-container matColumnDef="acciones">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                            <button matTooltip="Editar" [routerLink]="['/modulo-almacen/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="anio">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                        <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
                    </ng-container>

                    <ng-container matColumnDef="mes">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
                        <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                    </ng-container>

                    <ng-container matColumnDef="almacen">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo_doc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="parte">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> #Parte </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Producto </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="item">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ítem </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="secuencia">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Secuencia </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="descripcion_producto">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción del Producto </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cantidad">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="costo_unitario">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo Unitario </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <ng-container matColumnDef="motivo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
                        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="14">No se encontraron registros</td>
                    </tr>

                </table>

            </div>
            <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            <br>
            <div>
                <button mat-button matStepperPrevious>Atrás</button>
                <button mat-button matStepperNext>Siguiente</button>
            </div>
        </mat-step>


    </mat-horizontal-stepper>
</div>
