export class FiltroConsulta {
  clientes: Array<any>;
  moneda: string;
  diasVencer: number;
  tiposDocumento: Array<any>;
  numdoc: string;
  situacion: string;
  notificados: 'SI' | 'NO' | 'TODOS';
  criterio: 'ABONADO' | 'CON_SALDO' | 'CANCELADOS' | 'TODOS';

  constructor() {
    this.clientes = [];
    this.moneda = 'TODOS';
    this.tiposDocumento = [];
    this.situacion = 'todos';
    this.diasVencer = 7;
    this.notificados = 'TODOS';
    this.criterio = 'CON_SALDO';
  }
}
