export class SituacionOportunidad {
  idstk: number;
  descri: string;
  indmot: number;
  indsta: string;
  coduse: string;
  fecupd: Date;

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
  }
}
