export class Motive {
  cia_codcia: any;
  mmo_codmmo: any;
  mmo_descri: any;
  mmo_desabr: any;
  mmo_codopc: any;
  mmo_pedido: any;
  mmo_indtra: any;
  mmo_indfac: any;
  mmo_indncr: any;
  mmo_indser: any;
  mmo_indtrs: any;
  mmo_indval: any;
  mmo_indgui: any;
  mmo_indpro: any;
  mmo_indtco: any;
  mmo_indexp: any;
  tdo_codref: any;
  mmo_indrma: any;
  mmo_indsta: string;
  mmo_fecupd: any;
  mmo_coduse: string;
  mmo_indsdm: any;
  tga_codtga: any;
  mmo_indoim: any;
  mmo_indocm: any;
  mmo_indoin: any;
  mmo_indopr: any;
  mmo_codofi: any;
  mmo_inexpe: any;
  mmo_indgra: any;
  mmo_inddev: any;
  mmo_ncrsun: any;
  mmo_ndbsun: any;
  mmo_indcbo: any;
  mmo_asicos: any;
  mmo_asicon: any;
  mmo_asicoe: any;
  mmo_asicop: any;
  mmo_cospt: any;
  mmo_otrmov: any;
  mmo_indvin: any;
  mmo_cmovin: any;
  t51_codt51: any;
  t07_codt07: any;
  t17_codt17: any;
  mmo_indots: any;
  t20_codt20: any;
  mmo_indnue: any;
  constructor(
  ){
    this.mmo_indsta = "1";
    this.mmo_fecupd = new Date();
  }
}



interface Observer {

  notify(value?:any, subject?:Subject);

}

class Subject{

  private observers:Observer[] = [];

  attachObserver(oberver:Observer):void{

      this.observers.push(oberver);
  }

  detachObserver(observer:Observer):void{

    let index:number = this.observers.indexOf(observer);

    if(index > -1) this.observers.splice(index, 1);

  }

  protected notifyObservers(value?:any){

      for (var i = 0; i < this.observers.length; ++i) {

          this.observers[i].notify(value, this);
      }
  }
}

class IMDB extends Subject{

  private movies:string[] = [];

  public addMovie(movie:string){

    this.movies.push(movie);
    this.notifyObservers(movie);
  }
}

