import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_CRM_ETAPA } from '@data/constants/table/theader.crm';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { EtapaService } from 'src/app/services/api/crm/maestros/etapa.service';

@Component({
  selector: 'app-etapa-list',
  templateUrl: './etapa-list.component.html',
  styleUrls: ['./etapa-list.component.css']
})
export class EtapaListComponent implements OnInit, OnDestroy {

  etapas$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_ETAPA;

  etapas: Array<any> = [];

  constructor(
    private _etapaService: EtapaService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerEtapas();
      }
    })
  }

  obtenerEtapas(): void {
    this.etapas$ = this._etapaService.obtenerEtapas().subscribe(
      etapas => this.etapas = etapas,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.etapas$,
      this.loading$,
    ])
  }

}
