<app-pavso-title-section [title]="'Color'" [module]="'Almacén'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="color.col_codcol" [maxlength]="4" matInput placeholder="Código" name="col_codcol"
              >
          </mat-form-field>
        </div>

        <div class="col s12 m12 l5">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="color.col_descri" matInput placeholder="Descripción" name="col_descri"
            required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l5">
          <mat-form-field>
            <mat-label>Descri. Abrevi.</mat-label>
            <input type="text" [(ngModel)]="color.col_desabr" matInput placeholder="Descri. Abrevi." name="col_desabr"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Paleta PAL</mat-label>
            <input type="text" [(ngModel)]="color.col_ralcol" matInput placeholder="Paleta PAL" name="col_ralcol"
              >
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-almacen/color-producto" (clickNuevo)="nuevoRegistro()">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
