<h1 mat-dialog-title>Buscar</h1>
<div mat-dialog-content>

  <div *ngIf="data.program == 'CUENTAS'">
    <p [ngStyle]="{fontStyle: '12px'}">Buscar por:</p>
    <mat-radio-group [(ngModel)]="filtro.criterio" aria-label="Select an option">
      <mat-radio-button value="DIGITO">Dígito</mat-radio-button>
      &nbsp;&nbsp;
      <mat-radio-button value="DESCRIPCION">Descripción</mat-radio-button>
    </mat-radio-group>
  </div>

  <br>

  <mat-form-field appearance="legacy">
    <mat-label>{{data.label}}</mat-label>
    <input matInput placeholder="Escriba aquí" [(ngModel)]="input" name="input" (keyup)="keyup()">
  </mat-form-field>

  <mat-selection-list #shoes [multiple]="false">
    <mat-list-option [ngStyle]="{fontSize: '12px'}" *ngFor="let item of listado" [value]="item.codigo" (click)="seleccionar(item)">
      {{item.codigo}} | {{item.descripcion}}
    </mat-list-option>
  </mat-selection-list>
  <!-- <mat-list role="list">
    <mat-list-item role="listitem" [ngStyle]="{fontSize: '12px'}" *ngFor="let cliente of clientes">{{cliente.cli_nomcli}}</mat-list-item>
  </mat-list> -->

</div>
<div mat-dialog-actions>
  <button mat-button (click)="cerrar()" type="button">Cerrar</button>
</div>
