import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { forkObs } from '@utils/observables/fork';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { Formula, FormulacionHojaRuta, HojaRuta, Material, Ruta } from 'src/app/models/produccion/formulacion-hora-ruta';
import { AlmacenService, VentasService } from 'src/app/services';
import { TipoInventarioService } from 'src/app/services/api/almacen/configuracion/tipo-inventario.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';

@Component({
  selector: 'app-formulacion-hoja-ruta',
  templateUrl: './formulacion-hoja-ruta.component.html',
  styleUrls: ['./formulacion-hoja-ruta.component.css']
})
export class FormulacionHojaRutaComponent implements OnInit, OnDestroy {

  displayedColumnsFormula: string[] = ['acciones', 'numero', 'descripcion', 'cantidad_prod', 'estado'];
  dataSourceFormula: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorFormula: MatPaginator;
  @ViewChild(MatSort) sortFormula: MatSort;

  displayedColumnsRuta: string[] = ['acciones', 'nro', 'descripcion', 'estado'];
  dataSourceRuta: MatTableDataSource<any>;

  @ViewChild('paginatorRuta') paginatorRuta: MatPaginator;
  @ViewChild(MatSort) sortRuta: MatSort;

  displayedColumnsMat: string[] = ['acciones', 'item', 'codigo', 'codigo_laboratorio', 'descripcion', 'um', 'cantidad'];
  dataSourceMat: MatTableDataSource<any>;

  @ViewChild('paginatorMat') paginatorMat: MatPaginator;
  @ViewChild(MatSort) sortMat: MatSort;

  displayedColumnsHojaRuta: string[] = ['acciones', 'item', 'area', 'proceso', 'actividad', 'tiempo'];
  dataSourceHojaRuta: MatTableDataSource<any>;

  @ViewChild('paginatorHojaRuta') paginatorHojaRuta: MatPaginator;
  @ViewChild(MatSort) sortHojaRuta: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  productos: any[] = [];
  tiposInventario: any[] = [];

  loading$: Subscription;

  formulacionHoja: FormulacionHojaRuta;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _tipoInventarioService: TipoInventarioService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _productoService: ProductoService,
    private store: Store< PavsoState >
  ) {

    this.formulacionHoja = new FormulacionHojaRuta();

    this.dataSourceFormula = fillTable ([], this.paginatorFormula, this.sortFormula);

    this.dataSourceRuta = fillTable([], this.paginatorRuta, this.sortRuta);

    this.dataSourceMat = fillTable([], this.paginatorMat, this.sortMat);

    this.dataSourceHojaRuta = fillTable([], this.paginatorHojaRuta, this.sortHojaRuta);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadMaestros(): void {
    this.loaderData = true;

    forkObs(
      this._productoService.obtenerProductos(),
      this._tipoInventarioService.obtenerTiposDeInventario()
    ).then(data => {
      this.productos = data[0];
      this.tiposInventario = data[1];
      console.log('tipos', this.tiposInventario)
      this.loaderData = false;
    }).catch(error => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })
  }

  loadData(): void {
    this.loadMaestros();
  }

  agregarFormula(): void {
    const formula = new Formula();

    this.formulacionHoja.detalle_formula.push(formula);

    this.dataSourceFormula = fillTable(this.formulacionHoja.detalle_formula, this.paginatorFormula, this.sortFormula);
  }

  eliminarFormula(index): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const formulas = this.formulacionHoja.detalle_formula.filter((element, i) => index != i );

        this.formulacionHoja.detalle_formula = formulas;

        this.dataSourceFormula = fillTable(this.formulacionHoja.detalle_formula, this.paginatorFormula, this.sortFormula);
      }

    })
  }

  seleccionarFormula(value: boolean, formulaSeleccionada: Formula): void {
    if(value) {
      this.formulacionHoja.detalle_formula.forEach(element => element.seleccionado = false);
      formulaSeleccionada.seleccionado = value;

      this.dataSourceRuta = fillTable(formulaSeleccionada.detalle_rutas, this.paginatorRuta, this.sortRuta);
    }
  }

  agregarRuta(): void {

    const formulaSeleccionada = this.formulacionHoja.detalle_formula.find(item => item.seleccionado);
    console.log('formulasele', formulaSeleccionada)
    if(!formulaSeleccionada) {
      this._snackBarService.showError('Seleccionar una fórmula', 'Ok');
      return;
    }

    const ruta = new Ruta();

    formulaSeleccionada.detalle_rutas.push(ruta);

    this.dataSourceRuta = fillTable(formulaSeleccionada.detalle_rutas, this.paginatorRuta, this.sortRuta);

  }

  eliminarRuta(index): void {
    const formulaSeleccionada: Formula = this.formulacionHoja.detalle_formula.find(item => item.seleccionado);

    if(!formulaSeleccionada) {
      this._snackBarService.showError('Seleccionar una fórmula','OK');
      return;
    }
    console.log('productose el', formulaSeleccionada)

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const rutas = formulaSeleccionada.detalle_rutas.filter((element, i) => index != i )

        formulaSeleccionada.detalle_rutas = rutas;

        this.dataSourceRuta = fillTable(formulaSeleccionada.detalle_rutas, this.paginatorRuta, this.sortRuta);
      }

    })
  }

  seleccionarRuta(value: boolean, rutaSeleccionada: Ruta): void {

    const formulaSeleccionada = this.formulacionHoja.detalle_formula.find(item => item.seleccionado);

    if(!formulaSeleccionada) {
      this._snackBarService.showError('Seleccionar fórmula', 'Ok');
      return;
    }

    if(value) {
      formulaSeleccionada.detalle_rutas.forEach(element => element.seleccionado = false);

      rutaSeleccionada.seleccionado = value;

      this.dataSourceRuta = fillTable(formulaSeleccionada.detalle_rutas, this.paginatorRuta, this.sortRuta);
    }
  }

  agregarMaterial(): void {

    const formulaSeleccionado = this.formulacionHoja.detalle_formula.find(item => item.seleccionado);

    if(!formulaSeleccionado) {
      this._snackBarService.showError('Seleccionar una fórmula', 'Ok');
      return;
    }

    const material = new Material();

    formulaSeleccionado.detalle_materiales.push(material);

    this.dataSourceMat = fillTable(formulaSeleccionado.detalle_materiales, this.paginatorMat, this.sortMat);

  }

  eliminarMaterial(index): void {
    const formulaSeleccionada: Formula = this.formulacionHoja.detalle_formula.find(item => item.seleccionado);

    if(!formulaSeleccionada) {
      this._snackBarService.showError('Seleccionar una fórmula','OK');
      return;
    }

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const materiales = formulaSeleccionada.detalle_materiales.filter((element, i) => index != i )

        formulaSeleccionada.detalle_materiales = materiales;

        this.dataSourceMat = fillTable(formulaSeleccionada.detalle_materiales, this.paginatorMat, this.sortMat);
      }

    })
  }

  agregarHojaRuta(): void {

    const formulaSeleccionada = this.formulacionHoja.detalle_formula.find(item => item.seleccionado);

    if(!formulaSeleccionada) {
      this._snackBarService.showError('Seleccionar una fórmula', 'Ok');
      return;
    }

    const rutaSeleccionada = formulaSeleccionada.detalle_rutas.find(item => item.seleccionado);

    if(!rutaSeleccionada) {
      this._snackBarService.showError('Seleccionar una ruta', 'Ok');
      return;
    }

    const hojaRuta = new HojaRuta();

    rutaSeleccionada.detalle_hoja_ruta.push(hojaRuta);

    this.dataSourceHojaRuta = fillTable(rutaSeleccionada.detalle_hoja_ruta, this.paginatorHojaRuta, this.sortHojaRuta);

  }

  eliminarHojaRuta(index): void {
    const formulaSeleccionada: Formula = this.formulacionHoja.detalle_formula.find(item => item.seleccionado);

    if(!formulaSeleccionada) {
      this._snackBarService.showError('Seleccionar una fórmula','OK');
      return;
    }

    const rutaSeleccionada = formulaSeleccionada.detalle_rutas.find(item => item.seleccionado);

    if(!rutaSeleccionada) {
      this._snackBarService.showError('Seleccionar una ruta', 'Ok');
      return;
    }

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const hojasRuta = rutaSeleccionada.detalle_hoja_ruta.filter((element, i) => index != i )

        rutaSeleccionada.detalle_hoja_ruta = hojasRuta;

        this.dataSourceHojaRuta = fillTable(rutaSeleccionada.detalle_hoja_ruta, this.paginatorHojaRuta, this.sortHojaRuta);
      }

    })
  }

  registrarFormulacion(f: NgForm): void {

  }

  volver(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
