<app-pavso-title-section [title]="'Comprobante básico'" [module]="'Ventas'" [ngClass]="'pav-form'" [estado]="voucher.CCO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarComprobante(f)" (keydown.enter)="$event.preventDefault()">
        <div class="row">
            <div class="col s12 m12 l9">
                <mat-card class="mat-elevation-z0">
                    <div class="row">
                        <div class="col s12 m12 l2">
                            <mat-form-field>
                                <mat-label>Tipo de Documento</mat-label>
                                <mat-select [(ngModel)]="voucher.TDO_CODTDO" name="TDO_CODTDO" disabled>
                                    <mat-option *ngFor="let tipo of tipoDocumentosVenta" [value]="tipo.tdo_codtdo">
                                        {{tipo.tdo_deslar}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l2">
                            <mat-form-field>
                                <mat-label>Fecha</mat-label>
                                <input
                                    [(ngModel)]="voucher.CCO_FECREG" name="CCO_FECREG" matInput [matDatepicker]="picker" placeholder="Fecha" (click)="picker.open()"  (dateChange)="seleccionarFecha()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l1">
                            <mat-form-field class="example-full-width">
                                <mat-label>T.C.</mat-label>
                                <input [(ngModel)]="voucher.CCO_TIPCAM" name="CCO_TIPCAM" type="number" matInput placeholder="T.C." step="any" disabled>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Tas. IGV</mat-label>
                                <input [(ngModel)]="voucher.CCO_TASIGV" name="CCO_TASIGV" type="number" matInput placeholder="Tas. IGV" step="any" >
                                <mat-icon matSuffix>percent</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Serie</mat-label>
                                <input type="text" matInput placeholder="Serie" [(ngModel)]="serie" name="serie" >
                            </mat-form-field>
                        </div>
                        <div class="col s12 m12 l2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Nro. Doc.</mat-label>
                                <input type="text" matInput placeholder="Nro. Documento" disabled [(ngModel)]="voucher.CCO_NUMDOC" name="CCO_NUMDOC" >
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l1">
                          <app-currency-select [moneda]="voucher.TMO_CODTMO" (cambioMoneda)="seleccionarTipoMoneda($event)"></app-currency-select>

                        </div>

                        <div class="col s12 m12 l6">
                            <mat-form-field class="example-full-width">
                                <mat-label>Cliente</mat-label>
                                <input type="text" matInput placeholder="Cliente" [ngModel]="nameClient" name="CLI_CODCLI" (click)="listarClientes()" >
                            </mat-form-field>
                        </div>

                        <div class="col s6 m12 l3">
                            <mat-form-field class="example-full-width">
                                <mat-label>RUC</mat-label>
                                <input type="number" matInput placeholder="RUC" [(ngModel)]="rucClient" name="rucClient" disabled>
                            </mat-form-field>
                        </div>

                        <div class="col s6 m12 l3">
                            <mat-form-field class="example-full-width">
                                <mat-label>DNI / Otros</mat-label>
                                <input type="number" matInput placeholder="DNI / Otros" [(ngModel)]="dniClient" name="dniClient" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col s12 m12 l6">

                          <select-search
                            [label]="'Vendedor'"
                            [placeholder]="'Ej. VENDEDOR'"
                            [name]="'vde_codvde'"
                            [value]="'vde_codvde'"
                            [description]="'vde_nomvde'"
                            [data]="vendedores"
                            (cambioSelect)="detalleDcc.VDE_CODVDE = $event"
                            [model]="detalleDcc.VDE_CODVDE">
                          </select-search>
                        </div>

                        <div class="col s12 m12 l6">
                            <mat-form-field class="example-full-width">
                                <mat-label>Dirección</mat-label>
                                <input type="text" matInput placeholder="Dirección" [(ngModel)]="direccion" name="direccion" >
                            </mat-form-field>
                        </div>

                    </div>
                </mat-card>
            </div>
            <div class="col s12 m12 l3">
                <mat-card class="mat-elevation-z0">

                    <div class="row">
                        <div class="col s12">
                            <mat-checkbox class="example-margin" name="exportacion" [(ngModel)]="detalleDcc.DCC_INDEXP" name="DCC_INDEXP" >Exportación</mat-checkbox>
                            &nbsp;
                            <mat-checkbox class="example-margin" name="tipo" [(ngModel)]="detalleDcc.DCC_INDTEX" name="DCC_INDTEX" >Tipo Texto</mat-checkbox>
                        </div>
                        <div class="col s12 m12 l6">
                            <mat-form-field>
                                <mat-label>Lista</mat-label>
                                <mat-select name="listaPrecios" [(ngModel)]="detalleDcc.TLP_CODTLP" (selectionChange)="seleccionDeLista($event.value)" >
                                    <mat-option value="1" *ngFor="let tipo of tiposPrecio" [value]="tipo.TLP_CODTLP">
                                        {{tipo.tlp_descri}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l6">
                            <mat-form-field class="example-full-width">
                                <mat-label>Nro</mat-label>
                                <input type="number" matInput placeholder="Nro" [(ngModel)]="detalleDcc.LPC_CODLPC" name="LPC_CODLPC" >
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l12">
                            <mat-form-field>
                                <mat-label>Tipo de documento</mat-label>
                                <mat-select [disabled]="!estaActivoReferencia">
                                    <mat-option *ngFor="let tipo of tipoDocumentosVenta" [value]="tipo.tdo_codtdo">
                                        {{tipo.tdo_deslar}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l12">
                            <mat-form-field>
                                <mat-label>Referencia</mat-label>
                                <mat-select [disabled]="!estaActivoReferencia" [(ngModel)]="detalleDcc.CCS_CODCCS" name="CCS_CODCCS">
                                    <mat-option *ngFor="let centro of almacenes" [value]="centro.ccs_codccs">
                                        {{centro.ccs_desesp}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>

        <br>

        <mat-card class="mat-elevation-z0">
            <div class="row">
                <div class="col s12 m12 l2">
                    <mat-form-field>
                        <mat-label>Motivo</mat-label>
                        <mat-select [(ngModel)]="voucher.MMO_CODMMO" name="MMO_CODMMO" >
                            <mat-option *ngFor="let motivo of motivos" [value]="motivo.mmo_codmmo">
                                {{motivo.mmo_descri}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col s12 m12 l2">
                  <select-search
                    [label]="'Condición de Pago'"
                    [placeholder]="'Ej. CONTABLE'"
                    [name]="'CPA_CODCPA'"
                    [value]="'CPA_CODCPA'"
                    [description]="'cpa_descri'"
                    [data]="condiciones"
                    (cambioSelect)="voucher.CPA_CODCPA = $event"
                    [model]="voucher.CPA_CODCPA">
                  </select-search>



                </div>

                <div class="col s12 m12 l2">
                    <mat-form-field>
                        <mat-label>Vencimiento</mat-label>
                        <input [min]="minDate" [max]="maxDate" [(ngModel)]="voucher.CCO_FECVEN" name="CCO_FECVEN" matInput [matDatepicker]="picker1" >
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col s12 m12 l4">
                    <mat-form-field>
                        <mat-label>Calcula Item</mat-label>
                        <mat-select [(ngModel)]="detalleDcc.DCC_INDCAL" name="DCC_INDCAL" >
                            <mat-option [value]="item.fci_codfci" *ngFor="let item of formasCalculoItem">
                                {{item.fci_descri}}
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col s12 m12 l2">
                    <mat-form-field>
                        <mat-label>Tienda</mat-label>
                        <mat-select [(ngModel)]="detalleDcc.TIE_CODTIE" name="TIE_CODTIE" >
                            <mat-option [value]="tienda.tie_codtie" *ngFor="let tienda of tiendas">
                                {{tienda.tie_destie}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-card>

        <br>

        <button mat-icon-button color="primary" type="button" (click)="seleccionarProducto()">
            <mat-icon>add</mat-icon>
        </button>

        &nbsp;
        <mat-form-field class="pavso_input_search">
            <mat-label>Código de barra</mat-label>
            <input #code matInput [placeholder]="LABELS_NAME.BTN_SEARCH" (keyup.enter)="searchCode(code.value)">
            <mat-icon matSuffix>calendar_view_week</mat-icon>
        </mat-form-field>
        <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" data-label="Acciones">
                        <div class="pavso-container-action">
                            <button type="button" matTooltip="Editar producto" (click)="editarProducto(row)" mat-icon-button color="primary">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button type="button" matTooltip="Eliminar producto" (click)="eliminarProducto(row)" mat-icon-button color="warn">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="campo1">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> item </th>
                    <td mat-cell *matCellDef="let row; let i = index" data-label="item"> {{i + 1}} </td>
                </ng-container>

                <ng-container matColumnDef="campo2">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                    <td mat-cell *matCellDef="let row" data-label="Código">
                        {{row.PRD_CODPRD}}

                    </td>
                </ng-container>

                <ng-container matColumnDef="campo3">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec. </th>
                    <td mat-cell *matCellDef="let row" data-label="Sec.">  {{row.DCD_SECDCD}}</td>
                </ng-container>

                <ng-container matColumnDef="campo4">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                    <td mat-cell *matCellDef="let row" data-label="Descripción">
                        <div class="pavso-container-description">
                            {{row.prd_desesp}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="campo6">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
                    <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.UME_CODVEN}} </td>
                </ng-container>

                <ng-container matColumnDef="campo7">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                    <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.DCD_CANVEN}}</td>
                </ng-container>

                <ng-container matColumnDef="campo8">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                    <td mat-cell *matCellDef="let row" data-label="Precio"> {{row.DCD_PREUNI}} </td>
                </ng-container>

                <ng-container matColumnDef="campo9">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio inc. IGV </th>
                    <td mat-cell *matCellDef="let row" data-label="Precio inc. IGV"> {{row.DCD_PRUIGV}} </td>
                </ng-container>

                <ng-container matColumnDef="campo10">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. Venta </th>
                    <td mat-cell *matCellDef="let row" data-label="U.M. Venta"> {{row.UME_CODVEN}} </td>
                </ng-container>
                <ng-container matColumnDef="campo12">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Bruto </th>
                    <td mat-cell *matCellDef="let row" data-label="Importe Bruto"> {{row.DCD_IMPBRU}} </td>
                </ng-container>

                <ng-container matColumnDef="campo13">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> % Desc. </th>
                    <td mat-cell *matCellDef="let row" data-label="% Desc."> {{row.DCD_PORDES}} % </td>
                </ng-container>

                <ng-container matColumnDef="campo14">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Desc. </th>
                    <td mat-cell *matCellDef="let row" data-label="Importe Desc."> {{row.DCD_IMPDES}} </td>
                </ng-container>

                <ng-container matColumnDef="campo15">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor venta </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor venta"> {{row.DCD_VALVTA}} </td>
                </ng-container>

                <ng-container matColumnDef="campo16">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. IGV. </th>
                    <td mat-cell *matCellDef="let row" data-label="Imp. IGV."> {{row.DCD_IMPIGV}} </td>
                </ng-container>

                <ng-container matColumnDef="campo17">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total </th>
                    <td mat-cell *matCellDef="let row" data-label="Imp. Total"> {{row.DCD_IMPTOT}} </td>
                </ng-container>

                <ng-container matColumnDef="campo19">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                    <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.PCD_GLOPCD}} </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="20" *ngIf="!loaderProductos">No se encontraron registros</td>
                    <td class="mat-cell" colspan="20" *ngIf="loaderProductos">
                        <mat-spinner diameter="40"></mat-spinner>
                    </td>
                </tr>

            </table>

        </div>
        <mat-paginator class="mat-elevation-z0" itemsPerPageLabel="Items por página" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        <br>
        <mat-card class="mat-elevation-z0">
            <div class="row">
                <div class="col s12 m12 l3">
                    <div class="pavso_cobranza">
                        <button  class="pavso_boton_cobranza" mat-flat-button color="primary" aria-label="Pagos" type="button" (click)="cobranza()">
                            <mat-icon>local_atm</mat-icon>&nbsp;COBRANZA
                        </button>
                    </div>
                    <mat-form-field class="example-full-width">
                        <mat-label>Obervación</mat-label>
                        <textarea  matInput placeholder="Ex. It makes me feel..." [(ngModel)]="voucher.CCO_GLOCCO" name="CCO_GLOCCO"></textarea>
                    </mat-form-field>

                </div>

                <div class="col s12 m12 l9">
                    <div class="row">

                        <div class="col s12 m12 l2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Ord. Compra</mat-label>
                                <input type="text" matInput placeholder="Ord. Compra" [(ngModel)]="detalleDcc.DCC_ORDCOM" name="DCC_ORDCOM" >
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Descuento</mat-label>
                                <input type="number" matInput placeholder="Descuento" [(ngModel)]="detalleDcc.DCC_IMPDES" name="DCC_IMPDES" step="any" >
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Valor venta</mat-label>
                                <input type="number" matInput placeholder="Valor venta" [(ngModel)]="detalleDcc.DCC_VALVTA" name="DCC_VALVTA" step="any" >
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l2">
                            <mat-form-field class="example-full-width">
                                <mat-label>IGV</mat-label>
                                <input type="number" matInput placeholder="IGV" [(ngModel)]="voucher.CCO_IMPIGV" name="CCO_IMPIGV" step="any" >
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Imp. Bruto</mat-label>
                                <input type="number" matInput placeholder="Imp. Bruto" [(ngModel)]="detalleDcc.DCC_IMPBRU" name="DCC_IMPBRU" step="any" >
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Total</mat-label>
                                <input type="number" matInput placeholder="Total" [(ngModel)]="voucher.CCO_IMPDOC" name="CCO_IMPDOC" step="any" >
                            </mat-form-field>
                        </div>


                        <div class="col s12 m12 l6">
                            <mat-form-field class="example-full-width">
                                <mat-label>MONTO EN DOLARES (US$)</mat-label>
                                <input type="number" step="any" disabled matInput placeholder="MONTO EN DOLARES (US$)" [(ngModel)]="montoDolares" name="montoDolares">
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l6">
                            <mat-form-field class="example-full-width">
                                <mat-label>MONTO EN SOLES (S/.)</mat-label>
                                <input type="number" step="any" disabled matInput placeholder="MONTO EN SOLES (S/.)" [(ngModel)]="montoSoles" name="montoSoles">
                            </mat-form-field>
                        </div>

                        <div class="col s12 m12 l3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Abonado</mat-label>
                                <input type="number" matInput placeholder="Abonado" disabled step="any" [(ngModel)]="detalleDcc.DCC_IMPABO" name="DCC_IMPABO">
                            </mat-form-field>
                        </div>
                        <div class="col s12 m12 l3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Vuelto</mat-label>
                                <input type="number" matInput placeholder="Vuelto" disabled step="any" [(ngModel)]="detalleDcc.DCC_IMPVUE" name="DCC_IMPVUE">
                            </mat-form-field>
                        </div>

                    </div>
                </div>
            </div>
        </mat-card>
        <br>
        <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
          <div id="botonesAdicionales">
          </div>

        </pav-form-actions>

    </form>
</div>
