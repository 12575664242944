import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_CAMPANIA } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { CampaniaService } from "src/app/services/api/crm/maestros/campania.service";

@Component({
  selector: 'campania-list',
  templateUrl: './campania-list.component.html',
  styleUrls: ['./campania-list.component.scss']
})
export class CampaniaListComponent implements OnInit, OnDestroy {

  campanias$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_CAMPANIA;

  campanias: Array<any> = [];

  constructor(
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _campaniaService: CampaniaService
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerCompanias();
      }
    })
  }

  obtenerCompanias(): void {
    this.campanias$ = this._campaniaService.obtenerCampanias().subscribe(
      campanias => this.campanias = campanias,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.campanias$,
      this.loading$,
    ])
  }

}
