export class FiltroGeneraFactura {
  vendedores: Array<any>;
  transportistas: Array<any>;
  clientes: Array<any>;
  zonas: Array<any>;
  ubicacion: 'TODOS' | 'LIMA' | 'PROVINCIA';
  atencionCompleta: boolean | 1 | 0;
  entregaVendedor: boolean | 1 | 0;

  constructor() {
    this.vendedores = [];
    this.transportistas = [];
    this.clientes = [];
    this.zonas = [];

    this.ubicacion = 'TODOS';

    this.atencionCompleta = false;
    this.entregaVendedor = false;
  }
}
