import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlujoCaja } from 'src/app/models/caja-banco';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({providedIn: 'root'})
export class FlujoCajaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

    /**
   *
   * @returns
   */
    obtenerFlujoCajas(): Observable<any> {
      return this.http.get<any>(
        `${this._configurationService.obtenerUrl()}/api/cajabanco/listarflujocajas/${this._configurationService.obtenerCompaniaCliente()}`
      ).pipe(
        map((flujos: any) => {
          return flujos.map(flujo => {
            flujo['fca_descri'] = flujo['fca_descri'] ? flujo['fca_descri']: '';
            return flujo;
          })
        })
      );
    }

    /**
     *
     * @param codigo
     * @returns
     */
    mostrarFlujoCaja(codigo): Observable<any> {
      return this.http.get<any>(
        `${this._configurationService.obtenerUrl()}/api/cajabanco/mostrarflujocaja/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`
      );
    }

    /**
     *
     * @param flujoCaja
     * @returns
     */
    registrarFlujoCaja(flujoCaja: FlujoCaja): Observable<any> {
      return this.http.post<any>(
        `${this._configurationService.obtenerUrl()}/api/cajabanco/flujo-caja`,
        flujoCaja
      );
    }

    /**
     *
     * @param flujoCaja
     * @returns
     */
    actualizarFlujoCaja(flujoCaja: FlujoCaja): Observable<any> {
      return this.http.put<any>(
        `${this._configurationService.obtenerUrl()}/api/cajabanco/flujo-caja`,
        flujoCaja
      );
    }

}
