import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_TIPO_NEGOCIACION } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { CrmService } from "src/app/services";
import { TipoNegociacionService } from "src/app/services/api/crm/maestros/tipo-negociacion.service";

@Component({
  selector: 'tipo-negociacion-list',
  templateUrl: './tipo-negociacion-list.component.html',
  styleUrls: ['./tipo-negociacion-list.component.scss']
})
export class TipoNegociacionListComponent implements OnInit, OnDestroy {

  tiposNegociacion$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_TIPO_NEGOCIACION;

  tiposNegociacion: Array<any> = [];

  constructor(
    private store: Store<PavsoState>,
    private _tipoNegociacionService: TipoNegociacionService,
    private _snackBarService: SnackBarService,
    private _crmService: CrmService,
  ) {}

  ngOnInit(): void {
    this.store.select('loading').subscribe(state => {

      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerTiposNegocio()

    })
  }

  obtenerTiposNegocio(): void {
    this.tiposNegociacion$ = this._tipoNegociacionService.obtenerTiposNegociacion().subscribe(
      tipos => this.tiposNegociacion = tipos,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tiposNegociacion$,
    ])
  }
}
