import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { fillTable } from "@utils/tables/table";

class PermisosAprobacionPedido {
  secuencias: Secuencia[]
}

class Secuencia {
  usuarios: Usuario[]
}

class Usuario {}

@Component({
  selector: 'permisos-aprobacion-pedidos',
  templateUrl: './permisos-aprobacion-pedido.component.html',
  styleUrls: ['./permisos-aprobacion-pedido.component.scss']
})
export class PermisosAprobacionPedidoComponent implements OnInit, OnDestroy {

  selected: any;

  permiso: PermisosAprobacionPedido;

  selectRow(row: any) {

    this.selected = row;

    this.dataSource02 = fillTable(row.usuarios, this.paginator02, this.sort02);

  }

  loaderData: boolean;
  loaderReg: boolean;

  displayedColumns01: string[] = ['secuencia'];
  dataSource01: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator01: MatPaginator;
  @ViewChild(MatSort) sort01: MatSort;

  displayedColumns02: string[] = ['usuario'];
  dataSource02: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator02: MatPaginator;
  @ViewChild(MatSort) sort02: MatSort;

  usuarios: any[] = [];


  constructor() {
    this.dataSource01 = fillTable([], this.paginator01, this.sort01);
    this.dataSource02 = fillTable([], this.paginator02, this.sort02);
  }

  ngOnInit(): void {

  }

  agregarSecuencia(): void {
    let data = this.dataSource01.data;

    data.push({secuencia: `00${data.length + 1}`});

    this.dataSource01 = fillTable(data, this.paginator01, this.sort01);
  }

  agregarUsuario(): void {
    let data = this.dataSource02.data;

    data.push({usuario: ``});

    this.dataSource02 = fillTable(data, this.paginator02, this.sort02);
  }

  verMasOpciones(): void {}

  ngOnDestroy(): void {

  }

}
