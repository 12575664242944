<app-pavso-title-section [title]="'Requerimiento de materiales'" [module]="'Producción'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarPlanta(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l4 xl3">
                  <mat-form-field>
                      <mat-label>Nro. Reque.</mat-label>
                      <input type="text" matInput placeholder="Nro. Reque." name="codigo" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Fecha</mat-label>
                  <input matInput [matDatepicker]="fechaRegistro">
                  <mat-datepicker-toggle matSuffix [for]="fechaRegistro"></mat-datepicker-toggle>
                  <mat-datepicker #fechaRegistro></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                    <mat-label>Solicitado por</mat-label>
                    <mat-select required name="solicitado" [disabled]="loaderData">
                        <mat-option value="1">
                            solicitado 1
                        </mat-option>
                    </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Costo</mat-label>
                  <mat-select required name="costo" [disabled]="loaderData">
                      <mat-option value="1">
                        costo 1
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Situación</mat-label>
                  <mat-select required name="situacion" [disabled]="loaderData">
                      <mat-option value="1">
                        situacion 1
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-checkbox class="example-margin">Indicador de Orden</mat-checkbox>
              </div>
          </div>
      </mat-card>
      <br>
      <h4>Materiales</h4>

      <div class="mat-elevation-z0">

        <table mat-table [dataSource]="dataSourceMat" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <button matTooltip="Editar" [routerLink]="['/modulo-produccion/editar-chofer', row.CHO_CODCHO]" mat-icon-button color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.item}} </td>
            </ng-container>

            <ng-container matColumnDef="codigo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.codigo}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
            </ng-container>

            <ng-container matColumnDef="um">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
              <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.um}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cantidad}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsMat"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsMat;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <br>
      <div class="row">
        <div class="col s12 m12 l6 xl4">
          <div class="mat-elevation-z0">

            <table mat-table [dataSource]="dataSourceOrd" matSort >

                <ng-container matColumnDef="td">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> T.D. </th>
                    <td mat-cell *matCellDef="let row" data-label="T.D."> {{row.item}} </td>
                </ng-container>

                <ng-container matColumnDef="orden">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Orden </th>
                    <td mat-cell *matCellDef="let row" data-label="Orden"> {{row.codigo}} </td>
                </ng-container>

                <ng-container matColumnDef="cantidad">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                    <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.descripcion}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsOrd"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsOrd;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No se encontraron registros</td>

                </tr>

            </table>

            <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
        <div class="col s12 m12 l6 xl2">
          <button mat-flat-button color="primary">Órdenes</button>
        </div>
      </div>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
