import { Component } from "@angular/core";

@Component({
  selector: 'recepcion-documento',
  templateUrl: './recepcion-documento.component.html',
  styleUrls: ['./recepcion-documento.component.scss']
})
export class RecepcionDocumentoComponent {

  recepcionDocumento: RecepcionDocumento;

  constructor() {

    this.recepcionDocumento = new RecepcionDocumento();

  }

}

export class RecepcionDocumento {
  usuario: string;
  estado: string;
}
