import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Seller } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class VendedorService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarVendedores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/vendedor/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerVendedor(codvde): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/vendedor/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codvde}`
    );
  }

    /**
   *
   * @param vendedor
   * @returns
   */
    registrarVendedor(vendedor: Seller): Observable<any> {
      return this.http.post<any>(
        `${this._configurationService.obtenerUrl()}/api/vendedor/crear`,
        vendedor
      );
    }

    /**
     *
     * @param vendedor
     * @returns
     */
    actualizarVendedor(vendedor: Seller): Observable<any> {
      return this.http.put<any>(
        `${this._configurationService.obtenerUrl()}/api/vendedor/modificar`,
        vendedor
      );
    }
}
