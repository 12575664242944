export class TarjetaCredito {
  tcr_codtcr: string;
  tcr_descri: string;
  tcr_indsta: string;
  tcr_coduse: string;
  tcr_fecupd: Date;

  constructor() {
    this.tcr_fecupd = new Date();
    this.tcr_indsta = "1";
  }
}
