import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ProveedorService } from "src/app/services/api/compras/maestros/proveedor.service";

@Component({
  selector: 'consulta-documentos',
  templateUrl: './consulta-documentos.component.html',
  styleUrls: ['./consulta-documentos.component.scss']
})
export class ConsultaDocumentosComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['cliente', 'nro_comprobante', 'f_emision', 'f_vencimiento', 'estado', 'monto'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  period$: Subscription;
  loading$: Subscription;
  year: string;
  month: string;

  loaderData: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  proveedores: any[] = [];
  proveedores$: Subscription;

  constructor(
    private _proveedorService: ProveedorService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerMaestros();
    })
  }

  obtenerMaestros(): void {
    this.loaderData = true;
    this.proveedores$ = this._proveedorService.obtenerProveedores().subscribe(
      proveedores => {
        this.proveedores = proveedores;

        this.obtenerDocumentos();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');

      }
    )
  }

  obtenerDocumentos(): void {
    const documentos = [
      {
        cliente: '20457845841',
        nro_comprobante: 'F001-00000001',
        f_emision: '2023-10-01',
        f_vencimiento: '2023-11-02',
        estado: 'VALIDADO',
        monto: 'PEN 352.00',
      }
    ]

    this.dataSource = fillTable(documentos, this.paginator, this.sort);
    this.loaderData = false;
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.proveedores$])
  }

}
