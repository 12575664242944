<app-pavso-title-section [title]="'Cuentas por cobrar'" [module]="'Cobranzas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarCuenta(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cliente</mat-label>
            <mat-select required name="cliente">
              <mat-option *ngFor="let cliente of clientes" [value]="cliente.cli_codcli">
                {{cliente.cli_nomcli}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <app-currency-select [moneda]="'SO'" [disable]="loaderData"></app-currency-select>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tip. Doc.</mat-label>
            <mat-select required name="tipoDoc">
              <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.tdo_codtdo">
                {{tipo.tdo_deslar}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Num. Documento</mat-label>
            <input type="text" matInput placeholder="Num. Documento" name="num_documento" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Estado</mat-label>
            <mat-select required name="tipoDoc">
              <mat-option value="pendiente">
                Pendiente
              </mat-option>
              <mat-option value="vendidos">
                Vendidos
              </mat-option>
              <mat-option value="porVencer">
                Por vencer
              </mat-option>
              <mat-option value="cancelados">
                Cancelados
              </mat-option>
              <mat-option value="todos">
                Todos
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="row">
      <div class="col s12">
        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Tabla</div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="td">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TD </th>
              <td mat-cell *matCellDef="let row" data-label="TD"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="nro_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Doc."> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="pedido">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Pedido </th>
              <td mat-cell *matCellDef="let row" data-label="Pedido"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_emision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha emisión"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="razon_social">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón social </th>
              <td mat-cell *matCellDef="let row" data-label="Razón social"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="cod_banco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Banco </th>
              <td mat-cell *matCellDef="let row" data-label="Cod. Banco"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion_banco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción Banco </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción Banco"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="linea">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Línea </th>
              <td mat-cell *matCellDef="let row" data-label="Línea"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="situacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
              <td mat-cell *matCellDef="let row" data-label="Situación"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_vencimiento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Vencimiento"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="dias">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Días </th>
              <td mat-cell *matCellDef="let row" data-label="Días"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="mo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mo. </th>
              <td mat-cell *matCellDef="let row" data-label="Mo."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="imp_total_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total S/. </th>
              <td mat-cell *matCellDef="let row" data-label="Imp. Total S/."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="abono_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Abono S/. </th>
              <td mat-cell *matCellDef="let row" data-label="Abono S/."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="saldo_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo S/. </th>
              <td mat-cell *matCellDef="let row" data-label="Saldo S/."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="imp_total_dolar">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total $ </th>
              <td mat-cell *matCellDef="let row" data-label="Imp. Total $"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="abono_dolar">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Abono $ </th>
              <td mat-cell *matCellDef="let row" data-label="Abono $"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="saldo_dolar">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo $ </th>
              <td mat-cell *matCellDef="let row" data-label="Saldo $"> {{row.cli_numtlf}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="18">No se encontraron registros</td>

            </tr>

          </table>

        </div>
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col s12">
        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Tabla</div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSource1" matSort>

            <ng-container matColumnDef="cuenta_corriente">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuen. Corriente </th>
              <td mat-cell *matCellDef="let row" data-label="TD"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="fec_emision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Doc."> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="req_contable">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Req. Contable </th>
              <td mat-cell *matCellDef="let row" data-label="Pedido"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="sud_diario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sud. Diario </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha emisión"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="tip_moneda">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Razón social"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="tip_cambio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Cambio </th>
              <td mat-cell *matCellDef="let row" data-label="Cod. Banco"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="imp_nacional">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Nacional (S/.) </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción Banco"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="imp_extranjero">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Extranjero ($) </th>
              <td mat-cell *matCellDef="let row" data-label="Línea"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="cuenta_contable">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Contable </th>
              <td mat-cell *matCellDef="let row" data-label="Situación"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="doc_operacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. operación </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Vencimiento"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="fec_operacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. operación </th>
              <td mat-cell *matCellDef="let row" data-label="Días"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="linea">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Línea </th>
              <td mat-cell *matCellDef="let row" data-label="Mo."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="situacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
              <td mat-cell *matCellDef="let row" data-label="Imp. Total S/."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="tip_doc_ref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Doc. Ref. </th>
              <td mat-cell *matCellDef="let row" data-label="Abono S/."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="doc_rec">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. Ref. </th>
              <td mat-cell *matCellDef="let row" data-label="Saldo S/."> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="15">No se encontraron registros</td>
            </tr>
          </table>
        </div>
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
