<app-pavso-title-section [title]="'Motivo de movimiento'" [module]="'Ventas'" [usuario]="" [fecha]="" [estado]="motivoMovimiento.mmo_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l1">
            <mat-form-field>
              <mat-label>Código</mat-label>
              <input type="text" [(ngModel)]="motivoMovimiento.mmo_codmmo" [maxlength]="4" matInput placeholder="Código" name="mmo_codmmo"  [readonly]="uid!='0'" (keyup)="cambiarEstadoMMO()" soloNumerosText="numeros"
                >
            </mat-form-field>
          </div>
          <div class="col s12 m12 l5">
            <mat-form-field>
              <mat-label>Nombre</mat-label>
              <input type="text" [(ngModel)]="motivoMovimiento.mmo_descri" [maxlength]="60" matInput placeholder="Ej. COMPRAS NACIONALES" name="mmo_descri" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Nombre Abreviado</mat-label>
              <input type="text" [(ngModel)]="motivoMovimiento.mmo_desabr" [maxlength]="40" matInput placeholder="Ej. OF. COMPRAS NACIONALES" name="mmo_desabr" required>
            </mat-form-field>
          </div>

          <!-- estos campos son de la tabla cliente, pero tal vez el form no es el indicado, ver foto de notion tarea_id #1, debe ser la descripcion del cliente parte inferior izquierda-->
          <div class="col s12 m12 l12">
            <select-search
            [label]="'Tipos de operaciones'"
            [placeholder]="'Ej. Juan Ibañez'"
            [name]="'tga_codtga'"
            [value]="'tga_codtga'"
            [description]="'description'"
            [data]="tiposDeOperaciones"
            (cambioSelect)="motivoMovimiento.tga_codtga = $event"
            [model]="motivoMovimiento.tga_codtga">
          </select-search>
          </div>
          <div class="col s12 m3 l3">
            <mat-form-field>
              <mat-label>Código SUNAT</mat-label>
              <input type="text" [(ngModel)]="motivoMovimiento.mmo_codofi" [maxlength]="2" soloNumerosText="numeros" matInput placeholder="Código SUNAT" name="mmo_codofi" required>
            </mat-form-field>
          </div>
          <div class="col s12 m9 l9"></div>
        </div>
      </mat-card>
      <!-- {{motivoMovimiento | json}} -->
      <br>
      <mat-card class="mat-elevation-z0">
        <mat-tab-group>
          <mat-tab label="Configuración Almacén" labelClass="mat-tab-label-0-0">
            <div class="container">
              <div class="row">
                  <div class="col s12">
                    <div class="col s12" style="padding-bottom: 1em !important;">
                      <fieldset>
                        <legend>Obligar el ingreso de datos en almacén</legend>
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indocm" [name]="'mmo_indocm'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indocm')">Orden de Compra</mat-checkbox>&nbsp;&nbsp;
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indccs" [name]="'mmo_indccs'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indccs')">Centro de Costo</mat-checkbox><!--mmo_indccs -->&nbsp;&nbsp;
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indopr" [name]="'mmo_indopr'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indopr')">Orden de Producción</mat-checkbox>&nbsp;&nbsp;
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indrma" [name]="'mmo_indrma'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indrma')">Requerimiento de Materiales</mat-checkbox>&nbsp;&nbsp;
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indpro" [name]="'mmo_indpro'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indpro')">Liquidación de Produccción</mat-checkbox>
                      </fieldset>
                      <br>
                      <br>
                      <fieldset>
                        <legend>Configuración de valorización</legend>
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indval" [name]="'mmo_indval'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indval')">Ingreso Costo de Kardex</mat-checkbox>&nbsp;&nbsp;
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indcbo" [name]="'mmo_indcbo'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indcbo')">Extrae Valor Combo</mat-checkbox>&nbsp;&nbsp;
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indsdm" [name]="'mmo_indsdm'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indsdm')">Devolución de Materiales</mat-checkbox>&nbsp;&nbsp;
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_inddev" [name]="'mmo_inddev'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_inddev')">Devolución de Mercaderia/Prod. Termina</mat-checkbox>&nbsp;&nbsp;
                        <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indtra" [name]="'mmo_indtra'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indtra')">Transferencia Automática</mat-checkbox>
                      </fieldset>
                    </div>
                    <div class="row" style="margin-top: 1em !important;">
                      <div class="pav-table-header-container" >
                        <div class="pav-table-header-icon">
                        </div>
                        <div class="pav-table-header-title">
                          Tipos de Documento
                        </div>
                        <div class="pav-table-header-add">
                          <div class="isMobile">
                            <div class="pav-button-icon-add" (click)="agregarTipoDocumento()">
                              <mat-icon svgIcon="add"></mat-icon>
                            </div>
                          </div>
                          <div class="isWeb">
                            <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregarTipoDocumento()">Agregar ítem</button>
                          </div>
                        </div>
                      </div>

                      <div class="mat-elevation-z0 overflow-x">
                        <table mat-table [dataSource]="dataSourceTipoDocumento" matSort>
                          <ng-container matColumnDef="acciones">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                              <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                                <div class="pav-btns-container">

                                  <div class="pav-btn-circle-delete" (click)="quitarTipoDocumento(row,i)" matTooltip="Eliminar">
                                    <mat-icon svgIcon="delete"></mat-icon>
                                  </div>
                                </div>
                              </td>
                          </ng-container>
                          <ng-container matColumnDef="tdo_codtdo">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Código | Descripción</th>
                              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                                <ng-container>
                                  <select-search
                                    [label]="'Tipos de Documentos'"
                                    [placeholder]="'Ej. Factura, Boleta'"
                                    [name]="'TDO_CODTDO'"
                                    [value]="'TDO_CODTDO'"
                                    [description]="'description'"
                                    [data]="tipoDocumento"
                                    (cambioSelect)="row.TDO_CODTDO=$event; seleccionarTiposDocumento($event, row)"
                                    [model]="row.TDO_CODTDO">
                                  </select-search>
                                </ng-container>

                              </td>
                          </ng-container>
                          <ng-container matColumnDef="mdr_indref">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Referencia</th>
                            <td mat-cell *matCellDef="let row; let i = index;" data-label="mdr_indref">
                              <ng-container >
                                <select-search
                                [label]="'Solicitar Referencia'"
                                [placeholder]="'Ej. 1, 2'"
                                [name]="'mdr_indref'"
                                [value]="'mdr_indref'"
                                [description]="'mdr_indref'"
                                [data]="referenciasTipoDocumento"
                                (cambioSelect)="row.MDR_INDREF = $event"
                                [model]="row.MDR_INDREF">
                              </select-search>
                              </ng-container>

                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumnsTipoDocumento"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsTipoDocumento;"></tr>
                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="6">No se encontraron registros</td>
                          </tr>
                        </table>
                    </div>

                    <div class="col s12 m12 l3"></div>
                    <div class="col s12 m12 l3"></div>
                  </div>
              </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Configuración Facturación" labelClass="mat-tab-label-0-1">
            <div class="container">
              <div class="row">
                    <div class="col s12">
                      <fieldset>
                        <!-- <legend>Configuración de Facturcion</legend> -->
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_pedido" [name]="'mmo_pedido'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_pedido')">Pedido</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indfac" [name]="'mmo_indfac'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indfac')">Factura</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indncr" [name]="'mmo_indncr'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indncr')">N.Crédito</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indgui" [name]="'mmo_indgui'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indgui')">Guía</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indgra" [name]="'mmo_indgra'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indgra')">Transferencia Gratuita</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indexp" [name]="'mmo_indexp'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indexp')">Exportación</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_indade" [name]="'mmo_indade'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indade')">Adelanto</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_inexpe" [name]="'mmo_inexpe'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_inexpe')">Excluido de Percepción</mat-checkbox>
                      </fieldset>
                  </div>
                  <br>

                  <div class="col s12 m12 l12" style="margin-top:1em;">
                    <div class="col s12 m12 l6">
                      <select-search
                      [label]="'Motivo N/Crédito SUNAT'"
                      [placeholder]="'Ej. Juan Ibañez'"
                      [name]="'C09_CODC09'"
                      [value]="'C09_CODC09'"
                      [description]="'C09_DESCRI'"
                      [data]="motivoNCreditoSunat"
                      (cambioSelect)="motivoMovimiento.mmo_ncrsun = $event"
                      [model]="motivoMovimiento.mmo_ncrsun">
                    </select-search>
                    </div>
                    <div class="col s12 m12 l6">
                      <select-search
                      [label]="'Motivo N/Débito SUNAT'"
                      [placeholder]="'Ej. Juan Ibañez'"
                      [name]="'C10_CODC10'"
                      [value]="'C10_CODC10'"
                      [description]="'C10_DESCRI'"
                      [data]="motivoNDebitoSunat"
                      (cambioSelect)="motivoMovimiento.mmo_ndbsun = $event"
                      [model]="motivoMovimiento.mmo_ndbsun">
                    </select-search>
                    </div>
                    <div class="col s12 m12 l12">
                      <select-search
                      [label]="'Motivo Guia SUNAT'"
                      [placeholder]="'Ej. Juan Ibañez'"
                      [name]="'t20_coddoc'"
                      [value]="'t20_coddoc'"
                      [description]="'description'"
                      [data]="motivoGuiaSUNAT"
                      (cambioSelect)="motivoMovimiento.t20_codt20 = $event"
                      [model]="motivoMovimiento.t20_codt20">
                    </select-search>
                    </div>
                    <div class="col s12 m12 l12">
                      <select-search
                      [label]="'Tipo Operación T51'"
                      [placeholder]="'Ej. Juan Ibañez'"
                      [name]="'t51_codt51'"
                      [value]="'t51_codt51'"
                      [description]="'description'"
                      [data]="tipoOperacionT51"
                      (cambioSelect)="motivoMovimiento.t51_codt51 = $event"
                      [model]="motivoMovimiento.t51_codt51">
                    </select-search>
                    </div>
                    <div class="col s12 m12 l12">
                      <select-search
                      [label]="'Tipo Impuesto'"
                      [placeholder]="'Ej. Juan Ibañez'"
                      [name]="'t07_codt07'"
                      [value]="'t07_codt07'"
                      [description]="'description'"
                      [data]="tipoImpuesto"
                      (cambioSelect)="motivoMovimiento.t07_codt07 = $event"
                      [model]="motivoMovimiento.t07_codt07">
                    </select-search>
                    </div>
                  </div>

              </div>
            </div>
          </mat-tab>
          <mat-tab label="Configuración de Asientos Contables" labelClass="mat-tab-label-0-2">
            <div class="container">
              <div class="row">
                    <div class="col s12">
                      <fieldset>
                        <legend>Asiento de</legend>
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_asicop" [name]="'mmo_asicop'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_asicop')">Consumo de Producción</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_asicoe" [name]="'mmo_asicoe'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_asicoe')">Compras Importadas</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_asicos" [name]="'mmo_asicos'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_asicos')">Costo de Ventas</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_asicon" [name]="'mmo_asicon'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_asicon')">Compras Nacionales</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_cospt"  [name]="'mmo_cospt'"  (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_cospt')" >Costos P.T</mat-checkbox>&nbsp;&nbsp;
                          <mat-checkbox class="example-margin" [(ngModel)]="motivoMovimiento.mmo_otrmov" [name]="'mmo_otrmov'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_otrmov')">Otros Movimientos</mat-checkbox>
                      </fieldset>
                  </div>
                  <br>

                  <div class="col s12 m12 l12" style="margin-top:1em;">
                    <fieldset>
                      <legend>Solo para configuracion de cuentas clase 7 - Sin Movimiento en Almacén</legend>
                      <div class="col s12 m12 l6">
                        <select-search
                        [label]="'Cuenta Contable'"
                        [placeholder]="'Ej. Juan Ibañez'"
                        [name]="'ccn_codccn'"
                        [value]="'ccn_codccn'"
                        [description]="'description'"
                        [data]="cuentaContableVinculada"
                        (cambioSelect)="motivoMovimiento.ccn_codccn = $event;onChangeCuentaConVin()"
                        [model]="motivoMovimiento.ccn_codccn">
                      </select-search>
                      </div>
                      <div class="col s12 m12 l6">
                        <select-search
                        [label]="'Cuenta Vinculados'"
                        [placeholder]="'Ej. Juan Ibañez'"
                        [name]="'ccn_codccn'"
                        [value]="'ccn_codccn'"
                        [description]="'description'"
                        [data]="cuentaContableVinculada"
                        (cambioSelect)="motivoMovimiento.ccn_codvin = $event;onChangeCuentaConVin()"
                        [model]="motivoMovimiento.ccn_codvin">
                      </select-search>
                      </div>
                    </fieldset>
                  </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card>
      <br>
      <br>
      <br>
      <br>
      <br>
      <pav-form-actions
        [disabled]="loaderData"
        [btnName]="btnName"
        [isLoading]="loaderReg"
        urlBack="/modulo-ventas/motivos-de-movimiento"
      >
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>
    </form>
</div>
