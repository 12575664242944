import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfigurationService, VentasService } from 'src/app/services';
import { GenerateExcelComponent } from '@shared/components/dialogs/generate-excel/generate-excel.component';
import { SendEmailComponent } from '@shared/components/dialogs/send-email/send-email.component';
import * as pdfBuilder from '@utils/pdfmaker/builder/ventas.pdfmaker';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { CotizacionService } from 'src/app/services/api/ventas/operaciones/cotizacion.service';
import { ordenerArregloJSONxLlave } from '@utils/array/order';

@Component({
  selector: 'app-cotizaciones-list',
  templateUrl: './cotizaciones-list.component.html',
  styleUrls: ['./cotizaciones-list.component.css']
})
export class CotizacionesListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'PCC_NUMPCC', 'CLI_CODCLI', 'cli_nomcli', 'PCC_FECDOC', 'tmo_descri', 'PCC_IMPTOT', 'vde_nomvde', 'descri_indsta', 'otk_idtkt'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  finalDate = new Date();
  initialDate = new Date(`${this.finalDate.getMonth() + 1}/01/${this.finalDate.getFullYear()}`);

  pedidosTotales = [];

  year = new Date().getFullYear();
  month = new Date().getMonth();

  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date(this.year, this.month + 1, 0))
  });

  buttonsName: INameConstant = NAMES_CONSTANTS;

  estadoPedido$: Subscription;
  pdfPedido$: Subscription;
  pedido$: Subscription;
  loading$: Subscription;

  loaderData: boolean;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _ventasService: VentasService,
    private _cotizacionService: CotizacionService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.obtenerCotizaciones();
    });
  }

  cambiarEstado(valor): void {

    let pedidosFiltrados = [];

    if (valor == "TODOS") {
      this.dataSource = fillTable(this.pedidosTotales, this.paginator, this.sort);
      return;
    }

    this.pedidosTotales.forEach(element => {

      if (element.ESTADO_APROBACION == valor) {
        pedidosFiltrados.push(element);
      }

    });

    this.dataSource = fillTable(pedidosFiltrados, this.paginator, this.sort);

  }

  generarFactura(row): void {

    let temp = JSON.stringify({ temp: { order: row } });

    localStorage.setItem('temp', temp);

    this._router.navigate(['generar-factura']);

  }

  obtenerCotizaciones(): void {
    this.loaderData = true;
    this.pedido$ = this._cotizacionService.obtenerCotizaciones('01').subscribe(
      response => {

        this.dataSource = fillTable( ordenerArregloJSONxLlave('PCC_NUMPCC', response).reverse() , this.paginator, this.sort);

        this.pedidosTotales = response;

        this.loaderData = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )
  }

  imprimirPDF(pedido_id) {
    this.pdfPedido$ = this._cotizacionService.obtenerPDFCotizacion(pedido_id).subscribe(
      response => {
        if (response) {

          const headers = {
            CIA_NOMCIA: this._configurationService.obtenerNombreCliente(),
            CIA_NUMRUC: response[0].CIA_NUMRUC,
            CIA_DIRCIA: response[0].CIA_DIRCIA,
          }

          pdfBuilder.generarFormatoCotizacion(headers, response[0]);

          return;
        }

        this._snackBarService.showError('No se encontro la cotizacion', 'OK');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  generarExcel(): void {

    const dialogRef = this.dialog.open(GenerateExcelComponent, {
      width: '800px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => { })

  }

  enviarCorreo(pedido_id): void {
    const dialogRef = this.dialog.open(SendEmailComponent, {
      width: '300px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => { })
  }

  aprobar(pedido_id): void {
    this.estadoPedido$ = this._ventasService.cambiarEsdoPedido(pedido_id, 1).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Pedido Aprobado', '300px', '', '');
        this.obtenerCotizaciones();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  deaprobar(pedido_id): void {
    this.estadoPedido$ = this._ventasService.cambiarEsdoPedido(pedido_id, 2).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Pedido Desaprobado', '300px', '', '');
        this.obtenerCotizaciones();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  desaprobar(id): void { }

  establecerPendiente(pedido_id): void {
    this.estadoPedido$ = this._ventasService.cambiarEsdoPedido(pedido_id, 5).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Se Estableció el Pedido a Pendiente', '300px', '', '');
        this.obtenerCotizaciones();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerHora(): string {
    let date = new Date()
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    return `${hour}:${minute}:${seconds}`;
  }

  formatDate(date): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.estadoPedido$,
      this.pdfPedido$,
      this.pedido$,
    ])

  }

}
