import { BrowserModule } from "@angular/platform-browser";
import { SupervisorDialog } from "./supervisor-dialog/supervisor-dialog.component";
import { SupervisorFormComponent } from "./supervisor-form/supervisor-form.component";
import { SupervisorListComponent } from "./supervisor-list/supervisor-list.component";
import { SupervisorSelect } from "./supervisor-select/supervisor-select.component";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from "@shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [SupervisorFormComponent, SupervisorListComponent, SupervisorDialog, SupervisorSelect],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    SupervisorFormComponent, SupervisorListComponent, SupervisorDialog, SupervisorSelect
  ]
})
export class SupervisorModule {}
