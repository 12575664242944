<form #f="ngForm" ngNativeValidate (ngSubmit)="agregarDescuento(f)" (keydown.enter)="$event.preventDefault()">

    <div class="row">
        <div class="col s6">
            <mat-form-field>
                <mat-label>Seleccionar tipo de descuento</mat-label>
                <mat-select name="descuento" [(ngModel)]="descuento.dco_coddco">
                    <mat-option *ngFor="let descuento of descuentos" [value]="descuento.dco_coddco">
                        {{descuento.dco_desdco}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col s6">
            <mat-form-field class="pavso_input_search">
                <mat-label>Descuento (%)</mat-label>
                <input type="number" matInput placeholder="Descuento (%)" name="descuento" [(ngModel)]="descuento.discount">
            </mat-form-field>
        </div>
        <div class="col s12">
            <button mat-stroked-button color="primary" type="submit">Agregar</button>
        </div>
    </div>
</form>
<br>
<div class="mat-elevation-z2">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="cod_desc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> COD. DESC. </th>
            <td mat-cell *matCellDef="let row" data-label="Correo Electrónico"> {{row.dco_coddco}} </td>
        </ng-container>

        <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DESCUENTO </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.dco_desdco}} </td>
        </ng-container>

        <ng-container matColumnDef="porc_desc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> % DESC </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.discount}} </td>
        </ng-container>

        <ng-container matColumnDef="importe_desc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTE DESC </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre"> calculando... </td>
        </ng-container>

        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ACCIONES </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button matTooltip="Ver usuario" (click)="eliminarDescuento(row)" mat-icon-button color="primary">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell"></td>

            <td class="mat-cell">No se encontraron registros</td>
            <td class="mat-cell">
                <mat-spinner diameter="40"></mat-spinner>
            </td>
            <td class="mat-cell"></td>
        </tr>
    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<br>
<div>
    <button mat-stroked-button (click)="cancelar()" type="button">{{buttonName.BTN_BACK | translate}}</button>
    &nbsp;
    <button mat-stroked-button (click)="aceptar()" color="primary" type="button">Agregar Descuento</button>
</div>
