<app-pavso-title-section [title]="'Costos'" [module]="'Informe'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>

  <mat-card class="mat-elevation-z0">
    <mat-card-header>
      <mat-card-title>Filtro</mat-card-title>
      <mat-card-subtitle>Seleccionar Filtro</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <br>
  <div align="end">
    <button mat-flat-button color="primary" (click)="verReporte()" [class.spinner]="loading" [disabled]="loading">{{LABELS_NAME.BTN_SHOW_REPORT}}</button>
  </div>
</div>
