import { HeaderReport } from "@data/interfaces/headers.report";
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";
import { numberWithCommas } from "@utils/formats/number.format";
import { formatDateWithSlash } from "@utils/formats/date.format";
import { noBorders, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { PavsoColors } from "@data/enums/color.enum";
import { REPORT_HEADER_COLOR } from "@data/constants/configuration/configuration.constant";
import { number2month } from "@utils/conversions/number2month.conversion";

/**
 * Reporte de Acumulado Flujo Caja por Categoría
 * @param headers
 * @param data
 */
export async function reporteAcumuladoFlujoCajaPorCategoria(headers: HeaderReport, data) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // DATA INITIAL
  let inicialData = [];
  inicialData.push([
    new Cell(
      new Txt("INICIAL").bold().fontSize(10).end
    ).border(noBorders).colSpan(15).end,
    ...obtenerNulls(14),
  ],)
  inicialData.push([
    new Cell(
      new Txt(data.inicial.code).fontSize(7).end,
    ).margin([5,0,0,0]).border(noBorders).end,
    new Cell(
      new Txt(data.inicial.description).fontSize(7).end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["01"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["02"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["03"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["04"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["05"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["06"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["07"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["08"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["09"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["10"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["11"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["12"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(data.inicial["total"].toFixed(2))).fontSize(6).alignment('right').end,
    ).border(noBorders).end,
  ])

  // DATA INGRESOS Y EGRESOS
  let detalle = [];
  data.data.reverse().forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.fca_desflu).bold().fontSize(9).end
      ).border(noBorders).colSpan(15).end,
      ...obtenerNulls(14),
    ],)
    element.detalle.forEach(element2 => {
      detalle.push([
        new Cell(
          new Txt(`${element2.cfu_codcfu} ${element2.Cfu_DESCRI}`).bold().fontSize(8).end
        ).margin([5,0,0,0]).border(noBorders).colSpan(15).end,
        ...obtenerNulls(14),
      ],)
      let [t01,t02,t03,t04,t05,t06,t07,t08,t09,t10,t11,t12,tt] = [0,0,0,0,0,0,0,0,0,0,0,0,0];
      element2.detalle.forEach(element3 => {
        detalle.push([
          new Cell(
            new Txt(element3.fca_codfca).fontSize(6).end,
          ).margin([5,0,0,0]).border(noBorders).end,
          new Cell(
            new Txt(element3.fca_descri).fontSize(6).end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['01'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['02'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['03'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['04'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['05'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['06'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['07'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['08'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['09'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['10'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['11'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['12'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3['total'].toFixed(2))).fontSize(6).alignment('right').end,
          ).border(noBorders).end,
        ],)
        t01+=element3["01"];
        t02+=element3["02"];
        t03+=element3["03"];
        t04+=element3["04"];
        t05+=element3["05"];
        t06+=element3["06"];
        t07+=element3["07"];
        t08+=element3["08"];
        t09+=element3["09"];
        t10+=element3["10"];
        t11+=element3["11"];
        t12+=element3["12"];
        tt+=element3["01"]+element3["02"]+element3["03"]+element3["04"]+element3["05"]+element3["06"]+element3["07"]+element3["08"]+element3["09"]+element3["10"]+element3["11"]+element3["12"];
      });
      detalle.push([
        new Cell(
          new Txt("").fontSize(7).end,
        ).margin([5,0,0,0]).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(t01.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t02.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t03.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t04.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t05.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t06.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t07.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t08.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t09.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t10.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t11.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(t12.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tt.toFixed(2))).bold().fontSize(6).alignment('right').end,
        ).border(topBorder).end,
      ],)
    });
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt("RFIN_ACUM_CATE").fontSize(8).bold().end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Acumulados de Flujo de Caja por Categoría").fontSize(12).bold().alignment('center').end,
            new Txt("EN NUEVOS SOLES").bold().fontSize(8).alignment('center').end,
            new Txt(headers.year).bold().fontSize(8).alignment('center').end
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').bold().fontSize(8).end,
            new Txt(headers.time).alignment('right').bold().fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt("CÓDIGO").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt("DESCRIPCIÓN").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt("ENE").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("FEB").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("MAR").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("ABR").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("MAY").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("JUN").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("JUL").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("AGO").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("SET").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("OCT").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("NOV").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("DIC").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
        new Cell(
          new Txt("TOTAL").end,
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).alignment('center').end,
      ],
      ...inicialData,
      ...detalle,
    ]).headerRows(2).widths([35, 145, 38, 38, 38, 38, 38, 38, 38, 38, 38, 38, 38, 38, 38]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Detallado de Flujo de Caja
 * @param headers
 * @param data
 */
export function reporteDetalladoFlujoCaja(headers: HeaderReport, data) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let ingresos = data.data.find(item => item.fca_tipflu == "I");
  // ingresos = ingresos ? ingresos: {detalle: []}
  let salidas = data.data.find(item => item.fca_tipflu == "S");
  // salidas = salidas ? salidas: {detalle: []}
  console.log('ingresos', ingresos);
  console.log('salidas', salidas);
  let detalleIngresos = [];
  let [totIMNacional, totIMExtranjera] = [0,0];
  if(ingresos) {

    detalleIngresos.push([
      new Cell(
        new Txt("INGRESOS").fontSize(10).bold().end
      ).colSpan(10).border(noBorders).end,
      ...obtenerNulls(9),
    ],);

    ingresos.detalle.forEach(element => {
      detalleIngresos.push([
        new Cell(
          new Txt(`CÓDIGO: ${element.fca_codfca} ${element.fca_descri}`).fontSize(9).bold().end
        ).colSpan(10).border(noBorders).end,
        ...obtenerNulls(9),
      ],)
      let [tMNacional, tMExtranjera] = [0, 0];
      element.detalle.forEach(element2 => {
        detalleIngresos.push([
          new Cell(
            new Txt(`${element2.TDO_CODTDO}-${element2.LDI_DOCREF}`).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(element2.PBA_FECORD)).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(`${element2.ANO_CODANO} ${element2.MES_CODMES} ${element2.SCO_CODSCO} ${element2.LDC_CORLDC}`).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(`${element2.CLI_CODCLI} ${element2.cli_nomcli}`).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element2.CBA_DESCRI).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element2.PBA_NUMDOC).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element2.TMO_CODTMO).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element2.LDI_IMPNAC)).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element2.LDI_IMPMEX)).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(element2.LDI_GLOLDI).fontSize(7).end
          ).border(noBorders).end,
        ],)

        tMNacional += element2.LDI_IMPNAC;
        tMNacional += element2.LDI_IMPMEX;

      });
      detalleIngresos.push([
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(tMNacional.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMExtranjera.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ],)
      totIMNacional += tMNacional;
      totIMExtranjera += tMExtranjera;
    });

    detalleIngresos.push([
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("TOTAL INGRESOS").bold().fontSize(7).end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(totIMNacional.toFixed(2))).bold().fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totIMExtranjera.toFixed(2))).bold().fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
    ],)
  }

  let detalleEgresos = [];
  let [totEMNacional, totEMExtranjera] = [0,0];
  if(salidas) {
    detalleEgresos.push([
      new Cell(
        new Txt("EGRESOS").fontSize(10).bold().end
      ).colSpan(10).border(noBorders).end,
      ...obtenerNulls(9),
    ],);

    salidas.detalle.forEach(element => {
      detalleEgresos.push([
        new Cell(
          new Txt(`CÓDIGO: ${element.fca_codfca} ${element.fca_descri}`).fontSize(9).bold().end
        ).colSpan(10).border(noBorders).end,
        ...obtenerNulls(9),
      ],);
      let [tMNacional, tMExtranjera] = [0, 0];
      element.detalle.forEach(element2 => {
        detalleEgresos.push([
          new Cell(
            new Txt(`${element2.TDO_CODTDO}-${element2.LDI_DOCREF}`).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(element2.PBA_FECORD)).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(`${element2.ANO_CODANO} ${element2.MES_CODMES} ${element2.SCO_CODSCO} ${element2.LDC_CORLDC}`).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(`${element2.CLI_CODCLI} ${element2.cli_nomcli}`).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element2.CBA_DESCRI).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element2.PBA_NUMDOC).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element2.TMO_CODTMO).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element2.LDI_IMPNAC)).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element2.LDI_IMPMEX)).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(element2.LDI_GLOLDI).fontSize(7).end
          ).border(noBorders).end,
        ],)

        tMNacional += element2.LDI_IMPNAC;
        tMNacional += element2.LDI_IMPMEX;

      });
      detalleEgresos.push([
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(tMNacional.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMExtranjera.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ],)

      totEMNacional += tMNacional;
      totEMExtranjera += tMExtranjera;
    })

    detalleEgresos.push([
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("TOTAL EGRESOS").bold().fontSize(7).end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(totEMNacional.toFixed(2))).bold().fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totEMExtranjera.toFixed(2))).bold().fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
    ],)
  }


  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt("RFIN_FLUJ_DETA").fontSize(8).bold().end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Detallado de Flujo de Caja").fontSize(12).alignment('center').bold().end,
            new Txt(`ACUMULADO AL PERIODO ${headers.month} / ${headers.year}`).fontSize(8).alignment('center').bold().end,
          ]).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(`FECHA: ${headers.date}`).fontSize(8).bold().alignment('right').end,
            new Txt(`HORA: ${headers.time}`).fontSize(8).bold().alignment('right').end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Documento").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("F. Emisión").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Referencia").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cliente / Proveedor").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Banco").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("# Ope.").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Mon.").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Moneda Nacional").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Moneda Extranjera").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Glosa").fontSize(9).bold().alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(
          new Txt("INICIAL").fontSize(10).bold().end
        ).colSpan(10).border(noBorders).end,
        ...obtenerNulls(9),
      ],
      [
        new Cell(
          new Txt(`${data.inicial? data.inicial.TDO_CODTDO: ''}-${data.inicial? data.inicial.LDI_DOCREF: ''}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("05/05/05").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${data.inicial ? data.inicial.ANO_CODANO : ''} ${data.inicial ? data.inicial.MES_CODMES : ''}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(data.inicial ? data.inicial.LDI_IMPNAC: '')).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(data.inicial ? data.inicial.LDI_IMPMEX: '')).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ],

      ...detalleIngresos,
      ...detalleEgresos,
      [
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTAL GENERAL").bold().fontSize(7).end
        ).colSpan(2).border(topBorder).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas((totIMNacional + totEMNacional).toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas((totIMExtranjera + totEMExtranjera).toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ]
    ]).headerRows(2).end
  )

  pdf.create().open();

}

/**
 * Reporte de Movimiento de Caja Banco Saldo Inicial
 * @param headers
 * @param data
 */
export function reporteMovimientoCajaBancoSaldoInicial(headers: HeaderReport, data) {

  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  let detalle = [];
  data.forEach(item => {
    detalle.push([
      new Cell(
        new Txt(`BANCO: ${item.cba_codcba} ${item.cba_descri}`).fontSize(7).end
      ).bold().border(noBorders).colSpan(7).end,
      ...obtenerNulls(6),
      new Cell(
        new Txt("SALDO INICIAL:").fontSize(7).bold().alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.tmo_codtmo).fontSize(7).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.saldoInicial.toFixed(2))).fontSize(7).bold().alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt("").end
      ).border(noBorders).colSpan(2).end,
      null
    ],)

    item.detalle.forEach(element => {

      const importe = element.tmo_codtmo == "SO" ? element.pba_impsol: element.pba_impdol;

      detalle.push([
        new Cell(
          new Txt(`${element.TDO_CODTDO}/${element.LDI_DOCREF}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(element.pba_fecord)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(element.pba_fecche)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element.SCO_CODSCO} ${element.LDC_CORLDC}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.CLI_CODCLI).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.cli_nomcli).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element.TDO_CODTDO} ${element.PBA_NUMDOC}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PBA_GLOPBA).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.tmo_codtmo).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(importe.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas((item.saldoInicial + importe).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("VIGENTE").fontSize(7).end
        ).border(noBorders).end,
      ],)
    })
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).bold().fontSize(8).end,
            new Txt("RTES_MOVI_BASA").bold().fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Movimientos de Caja y Bancos con Saldo Inicial").fontSize(12).bold().alignment('center').end,
            new Txt(`${headers.year} ${headers.month}`).fontSize(8).bold().alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt(`Fecha: ${headers.date}`).fontSize(8).bold().alignment('right').end,
            new Txt(`Hora: ${headers.time}`).fontSize(8).bold().alignment('right').end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Documento").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("F. Emisión").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("F. Cheque").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Voucher").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Auxiliar").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Doc. Referencia").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Descripción").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Mon.").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Estado").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,

      [
        new Cell(
          new Txt("TRB/351.010.0447").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("03/12/19").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("03/12/19").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1801 00001").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20101266819").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("CÁMARA DE COMERCIO DE LIMA").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TRB 351.010.0447").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("PAGO DE CUOTA CAMARA DE COMERCIO").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("SO").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-130.00").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("35,464.15").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("VIGENTE").fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("N° Doc. 42").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("S/.").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("-109,511.60").alignment('right').fontSize(7).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("-73,917.45").alignment('right').fontSize(7).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("").end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("S/.").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("-160,807.90").alignment('right').fontSize(7).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("").end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("TOTAL GENERAL").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("US$").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("-275.55").alignment('right').fontSize(7).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end
      ],
    ]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Cuenta por Pagar Tabulado con Saldo
 * @param headers
 * @param data
 */
export function reporteCuentaPagarTabuladoConSaldo(headers: HeaderReport, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let detalle = [];

  let [totSal, tot01, tot02, tot03, tot04, tot05] = [0, 0, 0, 0, 0, 0, 0];

  let [totGNac, totGDol] = [0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.CLI_CODCLI).bold().fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).fontSize(8).bold().end
      ).colSpan(6).border(noBorders).end,
      ...obtenerNulls(5),
      new Cell(
        new Txt(`Cuenta Contable ${element.CCO_CODCCO}`).bold().fontSize(8).end
      ).colSpan(5).border(noBorders).end,
      ...obtenerNulls(4)
    ],)

    let [tSal, t01, t02, t03, t04, t05] = [0, 0, 0, 0, 0, 0, 0];

    element.detalle.forEach(item => {

      let [imp01, imp02, imp03, imp04, imp05] = [0, 0, 0, 0, 0];

      if(item.grupo_cobranza == '01') {
        imp01 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }
      if(item.grupo_cobranza == '02') {
        imp02 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }
      if(item.grupo_cobranza == '03') {
        imp03 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }
      if(item.grupo_cobranza == '04') {
        imp04 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }
      if(item.grupo_cobranza == '05') {
        imp05 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }

      tSal += item.cco_salrea;
      t01 += imp01;
      t02 += imp02;
      t03 += imp03;
      t04 += imp04;
      t05 += imp05;

      totGNac += item.cco_salnac;
      totGDol += item.cco_saldol;

      detalle.push([
        new Cell(
          new Txt(`${item.TDO_CODTDO}/${item.CCO_NUMDOC}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.CCO_FECEMI)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecven)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cpa_descri).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tmo_codtmo).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(( imp01 + imp02 + imp03 + imp04 + imp05 ).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cco_diaven).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp01.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp02.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp03.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp04.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp05.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
      ],)
    })

    detalle.push([
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSal.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt('').alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(t01.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(t02.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(t03.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(t04.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(t05.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
    ],)

    totSal += tSal;
    tot01 += t01;
    tot02 += t02;
    tot03 += t03;
    tot04 += t04;
    tot05 += t05;
  });

  detalle.push([
    new Cell(
      new Txt('TOTAL SOLES').bold().alignment('right').fontSize(7).end
    ).colSpan(5).border(noBorders).end,
    ...obtenerNulls(4),
    new Cell(
      new Txt(numberWithCommas(totSal.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tot01.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot02.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot03.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot04.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot05.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
  ],)

  detalle.push([
    new Cell(
      new Txt('TOTAL GENERAL').bold().alignment('right').fontSize(7).end
    ).colSpan(5).border(noBorders).end,
    ...obtenerNulls(4),
    new Cell(
      new Stack([
        new Txt(`S/. ${numberWithCommas(totGNac.toFixed(2))}`).alignment('right').bold().fontSize(7).end,
        new Txt(`US$ ${numberWithCommas(totGDol.toFixed(2))}`).alignment('right').bold().fontSize(7).end,
      ]).end
    ).border(topBorder).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').bold().fontSize(8).end,
            new Txt('RFIN_FACT_COBR').bold().fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Pagar Tabulado **Doc. con Saldo').alignment('center').bold().fontSize(12).end,
            new Txt('01 de Junio de 2023').alignment('center').bold().fontSize(10).end
          ]).end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt('Fecha : 1/06/2023').alignment('right').bold().fontSize(8).end,
            new Txt('Hora : 09:53:48').alignment('right').bold().fontSize(8).end,
            new Txt('Pág.: 32').alignment('right').bold().fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Documento').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Emisión').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Vcto').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Condición Pago').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Mon.').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Saldo').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Atraso(+Ó-)').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Vigentes').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Venc. <30').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Venc. <60').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Venc. <90').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Venc. >90').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
    ]).widths([80, 40, 40, 80, 30, 60, 60, 60, 60, 60, 60, 60]).end
  )

  pdf.create().open();


}

export function reporteCuentaPagarConSaldo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let detalle = [];

  let [totalImporteSO, totalImporteDO, totalAbonoSO, totalAbonoDO, totalSaldoSO, totalSaldoDO] = [0, 0, 0, 0, 0, 0]

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt('PROVEEDOR:').fontSize(8).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_codcli).fontSize(8).bold().end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(element.cli_nomcli).fontSize(8).bold().end
      ).border(noBorders).colSpan(8).end,
      ...obtenerNulls(7),
    ],)

    let nroItems = element.detalle.length;
    let importeSO = 0;
    let importeDO = 0;
    let abonoSO = 0;
    let abonoDO = 0;
    let saldoSO = 0;
    let saldoDO = 0;

    element.detalle.forEach(item => {

      item.tmo_codtmo == 'SO' ? totalImporteSO += (item.cco_impdoc * item.tdo_indsig): totalImporteDO += (item.cco_impdoc * item.tdo_indsig)
      item.tmo_codtmo == 'SO' ? totalAbonoSO += (item.cco_impabo * item.tdo_indsig) : totalAbonoDO += (item.cco_impabo * item.tdo_indsig)
      item.tmo_codtmo == 'SO' ? totalSaldoSO += (item.cco_impsal * item.tdo_indsig) : totalSaldoDO += (item.cco_impsal * item.tdo_indsig)

      item.tmo_codtmo == 'SO' ? importeSO += (item.cco_impdoc * item.tdo_indsig): importeDO += (item.cco_impdoc * item.tdo_indsig)
      item.tmo_codtmo == 'SO' ? abonoSO += (item.cco_impabo * item.tdo_indsig) : abonoDO += (item.cco_impabo * item.tdo_indsig)
      item.tmo_codtmo == 'SO' ? saldoSO += (item.cco_impsal * item.tdo_indsig) : saldoDO += (item.cco_impsal * item.tdo_indsig)

      detalle.push([
        new Cell(
          new Txt(`${item.tdo_codtdo}/${item.cco_numdoc}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecemi)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecven)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecreg)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cco_codcco).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ocm_numocm).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tmo_codtmo).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas((item.cco_impdoc * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas((item.cco_impabo * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas((item.cco_impsal * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cco_glocco).fontSize(7).end
        ).border(noBorders).end,
      ],)


    })

    detalle.push([
      new Cell(
        new Txt(`Nro Items: ${nroItems}`).fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Stack([
          new Txt('S/').alignment('right').fontSize(7).end,
          new Txt('USD').alignment('right').fontSize(7).end
        ]).end
      ).border(noBorders).colSpan(6).end,
      ...obtenerNulls(5),
      new Cell(
        new Stack([
          new Txt(numberWithCommas(importeSO.toFixed(2))).fontSize(7).bold().alignment('right').end,
          new Txt(numberWithCommas(importeDO.toFixed(2))).fontSize(7).bold().alignment('right').end
        ]).end
      ).border(topBorder).end,
      new Cell(
        new Stack([
          new Txt(numberWithCommas(abonoSO.toFixed(2))).fontSize(7).bold().alignment('right').end,
          new Txt(numberWithCommas(abonoDO.toFixed(2))).fontSize(7).bold().alignment('right').end
        ]).end
      ).border(topBorder).end,
      new Cell(
        new Stack([
          new Txt(numberWithCommas(saldoSO.toFixed(2))).fontSize(7).bold().alignment('right').end,
          new Txt(numberWithCommas(saldoDO.toFixed(2))).fontSize(7).bold().alignment('right').end
        ]).end
      ).border(topBorder).end,
      new Cell(
        new Txt(``).fontSize(8).end
      ).border(noBorders).end,
    ])
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt("DEMO PAVSO").fontSize(8).end,
            new Txt("RFIN_DOCU_COBR").fontSize(8).end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('DOCUMENTOS POR PAGAR **Doc. con Saldo').fontSize(12).bold().alignment('center').end,
            new Txt(`${new Date().getUTCDate()} de ${ number2month(`0${new Date().getUTCMonth()}`) } de ${new Date().getUTCFullYear()}`).fontSize(8).bold().alignment('center').end
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8),
        new Cell(
          new Stack([
            new Txt("").end,
            new Txt("").end,
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('Documento').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Emisión').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Vcto.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Registro').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Cta. Cte.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Ord. Compra').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Mon.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Importe').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Abono').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Saldo').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Referencia').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
      ],

      ...detalle,

      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('TOTAL GENERAL').fontSize(8).bold().alignment('right').end
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt('S/.').fontSize(8).bold().end,
            new Txt('US$').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalImporteSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalImporteDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalAbonoSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalAbonoDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalSaldoSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalSaldoDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
      ]
    ]).headerRows(2).widths([90, 45, 45, 45, 65, 50, 25, 60, 60, 60, 170]).end
  );

  pdf.create().open();
}

/**
 * Reporte de Saldo Pagar Fecha de Corte con Saldo
 * @param headers
 * @param data
 */
export function reporteSaldoPagarFechaCorteDocSaldo(headers: HeaderReport, data) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('portrait');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  let detalle = [];
  data.forEach(item => {
    detalle.push([
      new Cell(
        new Txt(`PROVEEDOR: ${item.CLI_CODCLI}  ${item.cli_nomcli}`).bold().fontSize(8).end
      ).border(noBorders).colSpan(10).end,
      ...obtenerNulls(9),
    ],)
    item.detalle.forEach(element => {
      detalle.push([
        new Cell(
          new Txt(`${element.TDO_CODTDO}/${element.CCO_NUMDOC}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(element.CCO_FECEMI)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(element.CCO_FECVEN)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.CCO_GLOCCO).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.CCO_CODCCO).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.CCN_CODCCN).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.TMO_CODTMO).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-269.15").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("-269.15").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],)
    })
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt("RTES_SALD_PAGC").fontSize(8).bold().end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Saldos por Pagar a Fecha de Corte **Doc. con Saldo").fontSize(12).bold().alignment('center').end,
            new Txt("Fecha de Corte al: 03-06-2020").fontSize(8).bold().alignment('center').end,
          ]).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().alignment('right').end,
            new Txt(headers.time).fontSize(8).bold().alignment('right').end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Documento").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("F. Emisión").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("F. Vcto.").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Glosa").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Cod. Cta. Cte.").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Cuenta").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Mon.").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Importe").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Abono").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Saldo").fontSize(8).bold().end
        ).end,
      ],
      ...detalle,

      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("S/.").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-269.15").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("-269.15").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTAL GENERAL").bold().fontSize(8).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("US$").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-269.15").bold().fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").bold().fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("-269.15").bold().fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("S/.").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-269.15").bold().fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").bold().fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("-269.15").bold().fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
    ]).widths([50,40,40,115,70,40,20,35,30,30]).headerRows(2).end
  )

  pdf.create().open();

}

/**
 * Reporte de Pagos Efecturados Proveedor
 * @param headers
 * @param data
 */
export function reportePagosEfectuadosProveedor(headers, data) {
  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let items = [];

  let totalMNCuenta = 0
  let totalMECuenta = 0

  data.forEach(auxiliar => {
    items.push([
      new Cell(
        new Txt("Auxiliar").bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_code).bold().fontSize(6).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_descri).bold().fontSize(6).end
      ).fillColor("#eee").border(noBorders).colSpan(14).end,
      ...obtenerNulls(13),
    ])

    let totalMNSaldo = 0
    let totalMESaldo = 0

    auxiliar.detalle.forEach(item => {

      let impDebeSoles = (item.ldi_inddha == "D")? item.ldi_impnac: 0
      let impDebeDolares = (item.ldi_inddha == "D")? item.ldi_impmex: 0
      let impHaberSoles = (item.ldi_inddha == "H")? (item.ldi_impnac)*(-1): 0
      let impHaberDolares = (item.ldi_inddha == "H")? (item.ldi_impmex)*(-1): 0
      let saldoSoles = (impDebeSoles == 0)? impHaberSoles: impDebeSoles;
      let saldoDolares = (impDebeDolares == 0)? impHaberDolares: impDebeDolares;

      totalMNSaldo += saldoSoles
      totalMESaldo += saldoDolares

      items.push([
        new Cell(
          new Txt((item.cco_fecreg)?item.cco_fecreg.substring(0, 10): "").fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.ldc_fecdia)?item.ldc_fecdia.substring(0, 10): "").fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecemi)?item.cco_fecemi.substring(0, 10): "").fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecven)?item.cco_fecven.substring(0, 10): "").fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.ano_codano}-${item.mes_codmes}-${item.sco_codsco}-${item.ldc_corldc}-${item.ldi_corldi}`).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codtdo).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_docref).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codban).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.PBA_NUMDOC).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cba_descri).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.sco_descri).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_gloldi).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impDebeSoles.toFixed(2))).alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impHaberSoles.toFixed(2))).alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(saldoSoles.toFixed(2))).alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impDebeDolares.toFixed(2))).alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impHaberDolares.toFixed(2))).alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(saldoDolares.toFixed(2))).alignment('right').fontSize(5).end
        ).border(noBorders).end,
      ])
    });

    items.push([
      new Cell(
        new Txt("TOTAL AUXILIAR          70484848").alignment('right').fontSize(6).end
      ).colSpan(12).border(noBorders).end,
      ...obtenerNulls(11),
      new Cell(
        new Txt("").alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalMNSaldo.toFixed(2))).alignment('right').fontSize(5).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalMESaldo.toFixed(2))).alignment('right').fontSize(5).end
      ).border(topBorder).end,
    ])

    totalMNCuenta += totalMNSaldo
    totalMECuenta += totalMESaldo
  });

  items.push([
    new Cell(
      new Txt(`TOTAL GENERAL`).alignment('right').fontSize(6).end
    ).colSpan(12).border(noBorders).end,
    ...obtenerNulls(11),
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totalMNCuenta.toFixed(2))).alignment('right').fontSize(5).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totalMECuenta.toFixed(2))).alignment('right').fontSize(5).end
    ).border(topBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(10).end,
        ...obtenerNulls(9),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Pagos Efectuados por Proveedor").alignment('center').bold().fontSize(14).end).border(noBorders).colSpan(10).end,
        ...obtenerNulls(9),
        new Cell(new Txt("").alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(13).end,

        ...obtenerNulls(12),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("F. Registro").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("F. Operación").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("F. Emisión").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("F. Vcto.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Correlativo de Asiento").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tip. Doc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Nro. Doc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Doc. Bco.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Operación").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Banco").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Subdiario").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Glosa").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Movimiento Moneda Nacional").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Movimiento Moneda Extranjera").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        ...obtenerNulls(12),
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],

      ...items,
    ]).headerRows(5).widths([26, 26, 26, 26, 65, 15, 42, 20, 30, 45, 60, 70, 30, 30, 35, 30, 30, 35]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Estado Cuenta Proveedor
 * @param headers
 * @param data
 */
export function reporteEstadoCuentaProveedor(headers, data) {
  // Diseño
  const pdf = new PdfMakeWrapper();
  console.log('estado proveedor', data)
  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let items = [];

  let totalMNCuenta = 0
  let totalMECuenta = 0

  data.forEach(proveedor => {
    items.push([
      new Cell(
        new Txt("Auxiliar").bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(proveedor.cli_codcli).bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(proveedor.cli_nomcli).bold().fontSize(7).end
      ).fillColor("#eee").border(noBorders).colSpan(11).end,
      ...obtenerNulls(10),
    ])

    let totalMNSaldo = 0
    let totalMESaldo = 0

    proveedor.detalle.forEach(item => {

      let impDebeSoles = (item.ldi_INDDHA == "D")? item.LDI_IMPNAC: 0
      let impDebeDolares = (item.ldi_INDDHA == "D")? item.LDI_IMPMEX: 0
      let impHaberSoles = (item.ldi_INDDHA == "H")? (item.LDI_IMPNAC)*(-1): 0
      let impHaberDolares = (item.ldi_INDDHA == "H")? (item.LDI_IMPMEX)*(-1): 0
      let saldoSoles = (impDebeSoles == 0)? impHaberSoles: impDebeSoles;
      let saldoDolares = (impDebeDolares == 0)? impHaberDolares: impDebeDolares;

      totalMNSaldo += saldoSoles
      totalMESaldo += saldoDolares

      items.push([
        new Cell(
          new Txt((item.cco_fecemi)?item.cco_fecemi.substring(0, 10): "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecven)?item.cco_fecven.substring(0, 10): "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.ano_codano}-${item.mes_codmes}-${item.sco_codsco}-${item.ldc_corldc}-${item.ldi_corldi}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codtdo).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_docref).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.PBA_NUMDOC).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cba_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.sco_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_gloldi).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ])
    });

    items.push([
      new Cell(
        new Txt("TOTAL AUXILIAR          70484848").alignment('right').fontSize(7).end
      ).colSpan(9).border(noBorders).end,
      ...obtenerNulls(8),
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMNSaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMESaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
    ])

    totalMNCuenta += totalMNSaldo
    totalMECuenta += totalMESaldo
  });

  items.push([
    new Cell(
      new Txt(`TOTAL GENERAL         `).alignment('right').fontSize(6).end
    ).colSpan(9).border(noBorders).end,
    ...obtenerNulls(8),
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totalMNCuenta.toFixed(2))).alignment('right').fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totalMECuenta.toFixed(2))).alignment('right').fontSize(6).end
    ).border(topBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(7).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(6).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Estado de cuenta proveedor").alignment('center').bold().fontSize(14).end).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Acumulado hasta ${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
          ]).end,
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("F. Operación").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("F. Vcto.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Correlativo de Asiento").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tipo Doc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Nro. Doc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Operación").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Banco").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Subdiario").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Glosa").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Movimiento Moneda Nacional").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Movimiento Moneda Extranjera").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        ...obtenerNulls(9),
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],

      ...items,
    ]).headerRows(5).widths([40, 40, 80, 20, 50, 40, 40, 40, 110, 35, 35, 35, 35, 35, 35]).end
  )
  console.log('estado proveedor final')

  pdf.create().open();
}
