import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { Banco } from 'src/app/models/general/banco';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @returns
   */
  listarTipoDePersona(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/tipopersona`
    );
  }

  /**
   *
   * @returns
   */
  listarPaises(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/pais`
    ).pipe(
      map((paises: any) => {
        return paises.map(pais => {
          pais['description'] = `${pais.PAI_CODPAI} | ${pais.pai_nomlar} | ${pais.pai_codiso3}`
          pais['description_reverse'] = `${pais.pai_nomlar} | ${pais.pai_codiso3} | ${pais.PAI_CODPAI}  `
          return pais;
        })
      })
    );
  }

  /**
   *
   * @returns
   */
  listarTipoDocumentoIdentidad(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/tipoidentidad`
    );
  }

  /**
   *
   * @param codpai
   * @returns
   */
  listarUbicacion(codpai): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/general/ubicaciones/${codpai}`
    );
  }

  /**
   *
   * @returns
   */
  listarUbigeos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/ubigeosunat`
    );
  }

  /**
   *
   * @returns
   */
  obtenerCompanias(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/companias`
    );
  }

  /**
   *
   * @returns
   */
  obtenerMonedas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/monedas`
    );
  }

  /**
   *
   * @returns
   */
  listarBancos() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listabancos`
    );
  }

  /**
   *
   * @param banco
   * @returns
   */
  registrarBanco(banco: Banco): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/general/bancos/${this._configurationService.obtenerCompaniaCliente()}`,
      banco
    );
  }

  /**
   *
   * @param banco
   * @returns
   */
  actualizarBanco(banco: Banco): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/general/bancos/${this._configurationService.obtenerCompaniaCliente()}`,
      banco
    );
  }

  obtenerOpcionesFiltrosReporteModulo(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/general/opciones-filtro/${this._configurationService.obtenerCompaniaCliente()}`,
    );
  }

  /**
  * Listar columnas de informe dinamico
  * @param codigo
  * @returns
  */
  listarColumnasInformeDinamico(codigoModulo: string, codigoReporte: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listacamposreporte/${codigoModulo}/${codigoReporte}`
    );
  }

  obtenerUbicacionesAlmacen(codpro: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/general/ubicaciones-almacen/${this._configurationService.obtenerCompaniaCliente()}/${codpro}`
    );
  }

}
