<app-pavso-title-section [title]="'apps.crm.operaciones.consulta.title' | translate"
  [module]="'CRM'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">


  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <select-search [label]="'apps.crm.operaciones.consulta.form.client' | translate" [placeholder]="'Ej. Torres'"
          [name]="'cli_codcli'" [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="clientes"
          [disabled]="loaderData" (cambioSelect)="filtro.clientes = $event" [model]="filtro.clientes" [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">
        <select-search [label]="'apps.crm.operaciones.consulta.form.stage' | translate" [placeholder]="'Ej. primera'"
          [name]="'codoet'" [value]="'codoet'" [description]="'descri'" [data]="etapas" [disabled]="loaderData"
          (cambioSelect)="filtro.etapas = $event" [model]="filtro.etapas" [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search [label]="'apps.crm.operaciones.consulta.form.responsible' | translate"
          [placeholder]="'Ej. Amaro'" [name]="'idres'" [value]="'idres'" [description]="'nombre'"
          [disabled]="loaderData" [data]="responsables" (cambioSelect)="filtro.responsables = $event"
          [model]="filtro.responsables" [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search [label]="'apps.crm.operaciones.consulta.form.business-line' | translate" [disabled]="loaderData"
          [placeholder]="'Ej. Primera'" [name]="'codoln'" [value]="'codoln'" [description]="'descri'" [data]="lineas"
          (cambioSelect)="filtro.lineas = $event" [model]="filtro.lineas" [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search [label]="'apps.crm.operaciones.consulta.form.type-negotiation' | translate"
          [placeholder]="'Ej. tipo 1'" [name]="'codotn'" [value]="'codotn'" [description]="'descri'" [data]="tipos"
          (cambioSelect)="filtro.tiposNegociacion = $event" [model]="filtro.tiposNegociacion" [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search [label]="'apps.crm.operaciones.consulta.form.channel' | translate" [placeholder]="'Ej. Remoto'"
          [name]="'idmtk'" [value]="'idmtk'" [description]="'descri'" [data]="canales"
          (cambioSelect)="filtro.canales = $event" [model]="filtro.canales" [multiple]="true">
        </select-search>

      </div>

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Desde</mat-label>
          <input matInput [matDatepicker]="desde" [(ngModel)]="filtro.desde" name="desde">
          <mat-datepicker-toggle matSuffix [for]="desde"></mat-datepicker-toggle>
          <mat-datepicker #desde></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Hasta</mat-label>
          <input matInput [matDatepicker]="hasta" [(ngModel)]="filtro.hasta" name="hasta">
          <mat-datepicker-toggle matSuffix [for]="hasta"></mat-datepicker-toggle>
          <mat-datepicker #hasta></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <mat-radio-group [(ngModel)]="filtro.situacion" name="situacion" aria-label="Select an option">
          <mat-radio-button value="AMBOS">{{'Ambos'}}</mat-radio-button>
          <mat-radio-button value="1">Cerrado</mat-radio-button>
          <mat-radio-button value="0">Abierto</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col s12 m12 l3">
        <mat-radio-group [(ngModel)]="filtro.moneda" name="moneda" aria-label="Select an option">
          <mat-radio-button value="AMBOS">{{'apps.crm.operaciones.consulta.form.both' | translate}}</mat-radio-button>
          <mat-radio-button value="SO">S/.</mat-radio-button>
          <mat-radio-button value="DO">US$</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col s12">
        <br>
        <button mat-flat-button class="pav-button-custom" color="primary" type="button"
          (click)="limpiarOportunidades()">Limpiar</button>
        &nbsp;
        <button mat-flat-button class="pav-button-custom" (click)="filtrarOportunidades()" color="primary" type="button">Buscar</button>
      </div>
    </div>
  </mat-card>
  <br>

  <mat-tab-group (selectedTabChange)="seleccionarEtapa($event);">
    <mat-tab *ngFor="let etapa of listaEtapa; trackBy: trackByEtapa; let i = index;"
      [labelClass]="'mat-tab-label-0-' + i" [label]="etapa.codoet + ' - ' +etapa.descri">
    </mat-tab>
  </mat-tab-group>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">
    </div>
    <div class="pav-table-header-title">
      Oportunidades
    </div>
    <div class="pav-separator"></div>
    <div class="" [ngStyle]="{color: 'white', fontSize: '10px', fontFamily: 'Figtree-Bold'}">
      {{clientesSeleccionado}}
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <div class="mat-elevation-z0 overflow-x">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.actions' | translate}} </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <button matTooltip="Ver" [routerLink]="['/modulo-crm/registro-de-oportunidad', row.idtkt]" mat-icon-button
            color="primary">
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="otk_idtkt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.ticket' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Ticket"> {{row.idtkt}} </td>
      </ng-container>

      <ng-container matColumnDef="otk_fecreg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.registration-date' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Reg."> {{row.fecreg | date: 'dd/MM/yyyy': 'UTC'}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="descriotn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.consulta.table.request.headers.type' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo"> {{row.descriotn}} </td>
      </ng-container> -->

      <ng-container matColumnDef="cli_nomcli">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.client' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="oms_descri">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.channel' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Canal"> {{row.descrioms}} </td>
      </ng-container>

      <ng-container matColumnDef="ccl_nomccl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.contact' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Contacto"> {{row.nomccl}} </td>
      </ng-container>

      <ng-container matColumnDef="oln_descri">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.business-line' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Línea de negocio"> {{row.descrioln}} </td>
      </ng-container>

      <ng-container matColumnDef="otn_descri">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.type-negotiation' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo de negociación"> {{row.descriotn}} </td>
      </ng-container>

      <ng-container matColumnDef="otk_asunto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.affair' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Asunto"> {{row.asunto}} </td>
      </ng-container>

      <ng-container matColumnDef="oet_descri">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.consulta.table.request.headers.last-stage' | translate}} </th>
        <td mat-cell *matCellDef="let row" data-label="Última etapa"> {{row.oet_descri}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{background: oportunidadSeleccionada == row? '#4493ff': '', color: oportunidadSeleccionada == row? 'white': ''}" (click)="obtenerSeguimientosPorOportunidad(row)"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="11">{{'general.table.data-not-found' | translate}}</td>

      </tr>

    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <br>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">
    </div>
    <div class="pav-table-header-title">
      Seguimientos
    </div>
    <div class="pav-separator"></div>
    <div class="" [ngStyle]="{color: 'white', fontSize: '10px', fontFamily: 'Figtree-Bold'}">
      {{oportunidadSeleccionada.idtkt != '' ? 'Nro. Ticket: ': ''}} {{oportunidadSeleccionada.idtkt}} | {{oportunidadSeleccionada.nomcli}}
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderSeguimientos"></mat-progress-bar>
  <div class="mat-elevation-z0 overflow-x">
    <table mat-table [dataSource]="dataSourceSeguimientos" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <button matTooltip="Ver" [routerLink]="['/modulo-crm/registro-de-oportunidad', row.otk_idtkt]"
            mat-icon-button color="primary">
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="otk_idtkt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Ticket </th>
        <td mat-cell *matCellDef="let row" data-label="Ticket"> {{row.idtkt}} </td>
      </ng-container>

      <ng-container matColumnDef="ost_idset">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nro. Seguimiento </th>
        <td mat-cell *matCellDef="let row" data-label="Nro. Seguim."> {{row.idset}} </td>
      </ng-container>

      <ng-container matColumnDef="otk_fecreg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Fec. Registro </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Registro"> {{row.fecreg | date: 'dd/MM/yyyy': 'UTC'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="fec_programada">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Fec. Programada </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Registro"> {{row.fecreg | date: 'dd/MM/yyyy': 'UTC'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="actividad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Actividad </th>
        <td mat-cell *matCellDef="let row" data-label="Fec. Registro"> {{row.fecreg | date: 'dd/MM/yyyy': 'UTC'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="ccl_nomccl">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Contacto </th>
        <td mat-cell *matCellDef="let row" data-label="Contacto"> {{row.nomccl}} </td>
      </ng-container>

      <ng-container matColumnDef="ost_descri">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Descripción </th>
        <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descri}} </td>
      </ng-container>

      <ng-container matColumnDef="ors_nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Responsable </th>
        <td mat-cell *matCellDef="let row" data-label="Responsable"> {{row.nommbreors}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsSeguimientos"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsSeguimientos;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="11">{{'general.table.data-not-found' | translate}}</td>
      </tr>

    </table>

    <mat-paginator #MatPaginatorSeguimientos showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>


  <br>
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.consulta.form.nro-document' | translate}}</mat-label>
          <input matInput [placeholder]="'apps.crm.operaciones.consulta.form.nro-document' | translate" type="text"
            readonly>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3"></div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.consulta.form.total-mn' | translate}}</mat-label>
          <input class="pav-input-align-right" matInput
            [placeholder]="'apps.crm.operaciones.consulta.form.total-mn' | translate" [(ngModel)]="totalMN"
            name="totalMN" type="number" readonly>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.consulta.form.total-me' | translate}}</mat-label>
          <input class="pav-input-align-right" matInput
            [placeholder]="'apps.crm.operaciones.consulta.form.total-me' | translate" [(ngModel)]="totalME"
            name="totalME" type="number" readonly>
        </mat-form-field>
      </div>
    </div>
  </mat-card>

</div>
