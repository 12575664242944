import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-periodo-calculo-create',
  templateUrl: './periodo-calculo-create.component.html',
  styleUrls: ['./periodo-calculo-create.component.css']
})
export class PeriodoCalculoCreateComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  tipoDoc$: Subscription;
  loading$: Subscription;
  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>
  ) {

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this.driver.CHO_INDSTA = "1";

    this.tipoDoc$ = this._generalService.listarTipoDocumentoIdentidad().subscribe(
      tiposDocumento => this.tiposDocumento = tiposDocumento,
      error => this._snackBarService.showError('Error al obtener tipos de documento', 'OK')

    )
  }

  registrarChofer(f: NgForm): void {

  }

  inicializaMovimientoNomina(): void {
    this._router.navigate(['/inicializa-movimientos-nomina'])
  }

  inicializaParamPeriodo(): void {
    this._router.navigate(['//inicializa-parametrizacion-del-periodo'])
  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoDoc$, this.loading$])
  }

}
