import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-exchange-rate-add',
  templateUrl: './exchange-rate-add.component.html',
  styleUrls: ['./exchange-rate-add.component.css']
})
export class ExchangeRateAddComponent implements OnInit {

  monedas: any[] = [];
  cambio = {
    moneda: null,
    compra: null,
    venta: null,
    descripcion: null,
    usuario: null
  }

  loaderData: boolean;

  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    public dialogRef: MatDialogRef<ExchangeRateAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService: AuthenticationService
  ) {
    this.cambio.usuario = this._authService.getUsuarioSistema();
    this.loaderData = false;
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  agregar(f: NgForm): void {
    this.dialogRef.close(this.cambio);
  }

}
