import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { Subscription } from "rxjs";
import { SolicitudCompraService } from "src/app/services/api/compras/operaciones/solicitud-compra.service";

@Component({
  selector: 'copiar-solicitud',
  templateUrl: './copiar-solicitud.dialog.html',
  styleUrls: ['./copiar-solicitud.dialog.scss']
})
export class CopiarSolicitudDialog implements OnInit {

  solicitudes: any[] = [];

  solicitudSeleccionada: any;

  solicitudes$: Subscription;
  loader: boolean;

  constructor(
    private readonly _solicitudCompraService: SolicitudCompraService,
    private readonly _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<CopiarSolicitudDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.obtenerSolicitudes();
  }

  obtenerSolicitudes(): void {
    this.solicitudes$ = this._solicitudCompraService.obtenerSolicitudesCompra().subscribe(
      solicitudes => {
        this.solicitudes = solicitudes;
        this.loader = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener solicitudes', 'Ok');
        this.loader = false;
      }
    )
  }

  copiar(): void {
    if(!this.solicitudSeleccionada) {
      this._snackBarService.showError('Seleccionar solicitud de compra', 'Ok');
      return;
    }

    this.dialogRef.close(this.solicitudSeleccionada);
  }

}
