<app-pavso-title-section [title]="'Lista de precio cliente'" [module]="'Ventas'"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <select-search
          [label]="'Cliente'"
          [placeholder]="'Ej. Pav Solutions'"
          [name]="'cli_codcli'"
          [value]="'cli_codcli'"
          [description]="'cli_nomcli'"
          [data]="clientes"
          (cambioSelect)="listaPrecioCliente.CLI_CODCLI = $event"
          [model]="listaPrecioCliente.CLI_CODCLI">
        </select-search>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select [(ngModel)]="listaPrecioCliente.PCE_INDSTA" name="BAN_INDSTA">
            <mat-option value="1">
              ACTIVO
            </mat-option>
            <mat-option value="2">
              INACTIVO
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Número</mat-label>
          <input type="text" matInput placeholder="Número" [(ngModel)]="listaPrecioCliente.PCE_CORPCE" name="BAN_NOMBRE" readonly>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Fecha Vigencia</mat-label>
          <input [(ngModel)]="listaPrecioCliente.PCE_FECINI" name="PCE_FECINI" matInput [matDatepicker]="pickerVigencia">
          <mat-datepicker-toggle matSuffix [for]="pickerVigencia"></mat-datepicker-toggle>
          <mat-datepicker #pickerVigencia></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Fecha Término</mat-label>
          <input [(ngModel)]="listaPrecioCliente.PCE_FECFIN" name="PCE_FECFIN" matInput [matDatepicker]="pickerTermino">
          <mat-datepicker-toggle matSuffix [for]="pickerTermino"></mat-datepicker-toggle>
          <mat-datepicker #pickerTermino></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l12">
        <mat-form-field class="example-full-width">
          <mat-label>Observación</mat-label>
          <textarea matInput placeholder="Ej. Observación"></textarea>
        </mat-form-field>
      </div>

    </div>


  </mat-card>
  <br>
    <div class="container">
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">

        </div>
        <div class="pav-table-header-title">
          Detalle
        </div>
        <div class="pav-table-header-add">
          <div class="isMobile">
            <div class="pav-button-icon-add" (click)="agregarPrecio()">
              <mat-icon svgIcon="add"></mat-icon>
            </div>
          </div>
          <div class="isWeb">
            <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregarPrecio()">Agregar ítem</button>
          </div>
        </div>
      </div>
      <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSourcePrecios" matSort>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" data-label="Acciones">
                  <div class="pav-btns-container">

                    <div class="pav-btn-circle-delete" (click)="quitarPrecio(row)" matTooltip="Eliminar">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </div>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="PRD_CODPRD">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">

                  <ng-container>
                    {{row.PRD_CODPRD}}
                  </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="prd_desesp">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">

                <ng-container>
                  <mat-form-field class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="row.PRD_CODPRD" [name]="'PRD_CODPRD' + i">
                      <mat-option *ngFor="let producto of productos" [value]="producto.prd_codprd">
                        {{producto.DESC_ESPA}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ume_codume">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">

                <ng-container>
                  <mat-form-field class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                    <mat-label></mat-label>
                    <input [(ngModel)]="row.producto.ume_codume" [name]="'ume_codume' + i" matInput placeholder="Ej. Producto">
                  </mat-form-field>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCL_PRENAC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio S/. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container>
                  <mat-form-field class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input step="any" type="number" [(ngModel)]="row.PCL_PRENAC" [name]="'PCL_PRENAC' + i" matInput placeholder="Ej. 10">
                  </mat-form-field>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCL_VTANAC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio Vta. S/. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container>
                  <mat-form-field class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input step="any" type="number" [(ngModel)]="row.PCL_VTANAC" [name]="'PCL_VTANAC' + i" matInput placeholder="Ej. 12">
                  </mat-form-field>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCL_PREDOL">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio US$ </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container>
                  <mat-form-field class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input step="any" type="number" [(ngModel)]="row.PCL_PREDOL" [name]="'PCL_PREDOL' + i" matInput placeholder="Ej. 30">
                  </mat-form-field>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCL_VTADOL">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio Vta. US$ </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container>
                  <mat-form-field class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input step="any" type="number" [(ngModel)]="row.PCL_VTADOL" [name]="'PCL_VTADOL' + i" matInput placeholder="Ej. 10">
                  </mat-form-field>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCL_INDSTA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">

                <ng-container>
                  <mat-form-field class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="row.PCL_INDSTA" [name]="'PCL_INDSTA' + i">
                      <mat-option value="1">
                        VIGENTE
                      </mat-option>
                      <mat-option value="0">
                        CESADO
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- {{row.PCL_INDSTA == '1'? 'Vigente': 'No Vigente'}} -->
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsPrecios"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPrecios;"></tr>

            <tr class="mat-row" *matNoDataRow>

                <td class="mat-cell" colspan="2">No se encontraron registros</td>

            </tr>
          </table>
      </div>
      <mat-paginator color="primary" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    </div>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-ventas/lista-de-precio-cliente">
    <div id="botonesAdicionales">

    </div>
  </pav-form-actions>
</form>
