import { Directive, ElementRef, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[toUpperOrLower]'
})
export class TextTransformDirective {
  @Input('toUpperOrLower') transform: string;

  constructor(private el: ElementRef,  @Self() private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;

    if (this.transform === 'upper') {
      this.el.nativeElement.value = initalValue.toUpperCase();
    } else if (this.transform === 'lower') {
      this.el.nativeElement.value = initalValue.toLowerCase();
    }
    this.ngControl.control.setValue(this.el.nativeElement.value);
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
