export class ConceptoDescuento {
  cia_codcia: string;//código de compañía - char(2)
  dco_coddco: string;//código de concepto de descuento - char(4)
  dco_desdco: string;//descripción de concepto de descuento - varchar(60)
  dco_obsdco: string;//descripcion abreviada de concepto de descuento - varchar(50)
  dco_inddco: string;//indicador de ? - char(1)
  dco_indmod: number;//indicador de ? - tinyint
  dco_inddef: number;//indicador de ? - tinyint
  dco_indnmo: number;//indicador de ? - tinyint
  dco_indsta: string;//indicador de estado - char(1)
  dco_fecupd: Date;//fecha de actualización - datetime
  dco_coduse: string;//código de usuario - varchar(30)
  dco_fecdes: Date;//fecha de descuento - datetime, null

  constructor() {
    this.dco_indsta = '1';
    this.dco_fecupd = new Date();
    this.dco_fecdes = new Date();
    this.dco_inddco = '',
    this.dco_indmod = 0,
    this.dco_inddef = 0,
    this.dco_indnmo = 0
  }
}
