import { Injectable } from '@angular/core';
import { utils, writeFileXLSX } from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() {}

  exportExcel(dataSource: {data: any[], title: string}) {

    const ws = utils.json_to_sheet(dataSource.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${dataSource.title}.xlsx`);

  }

  exportExcelBalanceComprobacion(reportData) {

    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }

  exportExcelTable(reportData) {

    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }

}
