import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { DocumentToUpload } from "../registro-compra-form/registro-compra-form.component";
import { RegistroCompraShowImageDialog } from "../registro-compra-dialog/show-image/registro-compra-show-image.dialog";
import { RegistroCompraUploadInvoiceDialog } from "../registro-compra-dialog/upload-invoice-dialog/registro-compra-upload-invoice.dialog";
import { RegistroCompraShowXmlDialog } from "../registro-compra-dialog/show-xml/registro-compra-show-xml.dialog";
import { RegistroCompraUploadXml } from "../registro-compra-dialog/upload-xml-dialog/registro-compra-upload-xml.dialog";
import { GeneracionToken, SunatService, ValidacionComprobante } from "src/app/services/api/sunat/sunat.service";
import { Subscription } from "rxjs";
import { formatDateWithSlash } from "@utils/formats/date.format";

@Component({
  selector: 'registro-compra-bottom-sheet',
  templateUrl: './registro-compra-bottom-sheet.html',
  styleUrls: ['./registro-compra-bottom-sheet.scss']
})
export class RegistroCompraBottomSheet implements OnDestroy{

  generacionToken$: Subscription;
  validacionComprobante$: Subscription;
  loaderValidacion: boolean;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public documentToUpload: DocumentToUpload,
    private _snackBarService: SnackBarService,
    private _sunatService: SunatService,
    private _bottomSheetRef: MatBottomSheetRef<RegistroCompraBottomSheet>,
    public dialog: MatDialog
  ) {
    console.log('upload file en Bottom sheet', this.documentToUpload)
  }

  ngOnDestroy(): void {}

  cargarFactura(): void {
    const dialogRef = this.dialog.open(RegistroCompraUploadInvoiceDialog, {
      width: 'auto',
      data: this.documentToUpload,
      panelClass: 'pav-dialog-container-option-1',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.documentToUpload = result;
      if(this.documentToUpload.invoiceFile) {
        console.log('ingresa a la condicional', result)

        this.loaderValidacion = true;
        const payload = new GeneracionToken();

        if(this.documentToUpload.invoiceJSON.VendorTaxId &&
          this.documentToUpload.invoiceJSON.InvoiceId &&
          this.documentToUpload.invoiceJSON.InvoiceTotal &&
          this.documentToUpload.invoiceJSON.InvoiceDate
          ) {
            this._sunatService.sendFormData(payload).then(
              response => {

                const payloadValidacion = new ValidacionComprobante();
                payloadValidacion.numRuc = this.documentToUpload.invoiceJSON.VendorTaxId.value;
                payloadValidacion.numeroSerie =  this.documentToUpload.invoiceJSON.InvoiceId.value.split('-')[0];
                payloadValidacion.numero = this.documentToUpload.invoiceJSON.InvoiceId.value.split('-')[1];
                payloadValidacion.fechaEmision = formatDateWithSlash(this.documentToUpload.invoiceJSON.InvoiceDate.value);
                payloadValidacion.monto = this.documentToUpload.invoiceJSON.InvoiceTotal.value.amount;
                console.log('response', response)
                console.log('response.access_token', response.data.access_token)
                this._sunatService.consultarValidacionComprobante(payloadValidacion, response.data.access_token).then(
                  responseValidacion => {
                    if(responseValidacion.success) {
                      this._bottomSheetRef.dismiss(this.documentToUpload);
                      this._snackBarService.showSuccess('Comprobante válido', 'Ok');
                      this.loaderValidacion = false;
                      return;
                    }

                    this._bottomSheetRef.dismiss(this.documentToUpload);

                    this._snackBarService.showError('Comprobante no válido', 'Ok');
                    this.loaderValidacion = false;
                  },

                ).catch(error => {
                  this.loaderValidacion = false;
                  this._bottomSheetRef.dismiss(this.documentToUpload);
                  this._snackBarService.showSuccess('Comprobante válido', 'Ok');

                })

              }
            ).catch(
              error => {
                this.loaderValidacion = false;
                console.log('error', error)
              }
            );
          } else {
            this._bottomSheetRef.dismiss(this.documentToUpload);
          }
      }
    });
  }

  verImagen(): void {

    if(!this.documentToUpload.invoiceFile) {
      this._snackBarService.showError('No tiene aun una factura cargada', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(RegistroCompraShowImageDialog, {
      width: 'auto',
      disableClose: true,
      panelClass: 'pav-dialog-container-option-1',
      data: this.documentToUpload
    });

    dialogRef.afterClosed().subscribe(result => {
      this._bottomSheetRef.dismiss(this.documentToUpload);
    })

  }

  cargarXml(): void {
    // this.loaderData = true;
    const dialogRef = this.dialog.open(RegistroCompraUploadXml, {
      width: '300px',
      data: this.documentToUpload,
      panelClass: 'pav-dialog-container-option-1',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('resultado de cargar xml', result)
      this.documentToUpload = result;
      this._bottomSheetRef.dismiss(this.documentToUpload);
    });

  }

  verXml(): void {

    if(!this.documentToUpload.xmlFile) {
      this._snackBarService.showError('No tiene aun un xml cargado', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(RegistroCompraShowXmlDialog, {
      width: 'auto',
      data: this.documentToUpload,
      panelClass: 'pav-dialog-container-option-1',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this._bottomSheetRef.dismiss(this.documentToUpload);
    })

  }
}
