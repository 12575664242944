import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DetalleOtrosIngresosAfectoRenta, OtrosIngresosAfectoRenta } from "src/app/models/planilla/datos-periodo/otros-ingresos-afecto-renta";
import { ConfigurationService, NominaService } from "src/app/services";

@Component({
  selector: 'otros-ingresos-afecto-renta',
  templateUrl: './otros-ingresos-afecto-renta.component.html',
  styleUrls: ['./otros-ingresos-afecto-renta.component.scss']
})
export class OtrosIngresosAfectoRentaComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['actions', 'codcli', 'imprqd'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderReg: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  periodo$: Subscription;
  colaboradores$: Subscription;
  loading$: Subscription;
  otrosIngresos$: Subscription;
  sendForm$: Subscription;

  otrosIngresos: OtrosIngresosAfectoRenta;

  colaboradores: any[] = [];

  constructor(
    private _nominaService: NominaService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>
  ) {

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.otrosIngresos = new OtrosIngresosAfectoRenta();
      this.otrosIngresos.codano = state.year;
      this.otrosIngresos.codmes = state.month;
      this.otrosIngresos.codcia = this._configurationService.obtenerCompaniaCliente();
    })

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadMaestros();
    })
  }

  obtenerOtrosIngresoRenta(): void {
    this.otrosIngresos$ = this._nominaService.obtenerOtrosIngresosRentaQuinta(this.otrosIngresos.codano, this.otrosIngresos.codmes).subscribe(
      response => {
        if(response.length > 0) {
          this.btnName = this.buttonsName.BTN_UPDATE;
          this.otrosIngresos.detalle = response;

          this.dataSource = fillTable(this.otrosIngresos.detalle, this.paginator, this.sort);

          this.otrosIngresos.detalle.forEach(item => {
            const colaborador = this.colaboradores.find(item => item.codcli == item['codcli']);

            item['nomcli'] = colaborador.nomcli;
          })
          return;
        }
        this.dataSource = fillTable([], this.paginator, this.sort);
        this.btnName = this.buttonsName.BTN_STORE;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Obtiene el maestro de :
   * . colaboradores
   */
  loadMaestros(): void {

    this.colaboradores$ = this._nominaService.listarColaboradores().subscribe(
      colaboradores => {

        this.colaboradores = colaboradores;

        this.obtenerOtrosIngresoRenta()

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  registrarOtrosIngresosAfectoRenta5ta(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._nominaService.registrarOtrosIngresosRentaQuinta(this.otrosIngresos).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Otros ingresos afecto a renta de 5ta. registrado', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  agregarItem(): void {

    const item = new DetalleOtrosIngresosAfectoRenta();
    item.coduse = this._configurationService.obtenerIdUsuario();

    this.otrosIngresos.detalle.push(item);

    this.otrosIngresos.detalle.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.otrosIngresos.detalle, this.paginator, this.sort);


  }

  editarItem(row): void {

    row.isEditing = !row.isEditing;

  }

  confirmarItem(row: DetalleOtrosIngresosAfectoRenta): void {
    if(!row.codcli || !row.imprqd) {
      this._snackBarService.showError('Todos los campos de fila deben estar compeltos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.otrosIngresos.detalle.filter(item => item.correlativo != row.correlativo);
        this.otrosIngresos.detalle = filtered;
        this.otrosIngresos.detalle.forEach((element, key) => {
          element['correlativo'] = key;
        })
        this.dataSource = fillTable(this.otrosIngresos.detalle, this.paginator, this.sort);
      }
    })
  }

  seleccionarColaborador(event: MatSelectChange, row) {
    let existe = false;
    this.otrosIngresos.detalle.forEach(item => {
      if(item.isEditing == false)
        if(row.codcli == item.codcli) existe = true;
    });
    if(existe) {
      this._snackBarService.showError('Este colaborador ya existe en la tabla', 'Ok');
      event.source.value = "";
      return;
    }

    const colaborador = this.colaboradores.find(item => item.codcli == row.codcli);

    row.nomcli = colaborador.nomcli;
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.periodo$,
      this.colaboradores$,
      this.loading$,
      this.otrosIngresos$,
      this.sendForm$
    ]);
  }
}
