import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Area } from 'src/app/models/produccion/area';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { AreaService } from 'src/app/services/api/produccion/maestros/area.service';

@Component({
  selector: 'app-area-produccion-form',
  templateUrl: './area-produccion-form.component.html',
  styleUrls: ['./area-produccion-form.component.css']
})
export class AreaProduccionFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = true;

  area: Area;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  area$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _dialogService: DialogService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _areaService: AreaService,
    private _configurationService: ConfigurationService
  ) {
    this.area = new Area();
    this.area.apr_coduse = this._configurationService.obtenerIdUsuario();
    this.area.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(id == '0') {
        this.loaderData = false;
        return};

      this.obtenerAreaProduccion();
    })

  }

  obtenerAreaProduccion(): void {
    this.area$ = this._areaService.obtenerArea(this.uid).subscribe(
      (area) => {
        this.area = area;

        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarAreaProduccion(f): this.actualizarAreaProduccion(f);
  }

  registrarAreaProduccion(f: NgForm): void {
    this.sendForm$=this._areaService.registrarArea(this.area).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Área de producción registrada', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarAreaProduccion(f: NgForm): void {
    this.sendForm$=this._areaService.actualizarArea(this.area).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Área de producción actualizada', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-produccion/areas-de-produccion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.area$, this.loading$, this.sendForm$]);
  }

}
