import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { TipoNegociacion } from "src/app/models/crm/tipo-negociacion";
import { ConfigurationService } from "src/app/services";
import { TipoNegociacionService } from "src/app/services/api/crm/maestros/tipo-negociacion.service";

@Component({
  selector: 'tipo-negociacion-form',
  templateUrl: './tipo-negociacion-form.component.html',
  styleUrls: ['./tipo-negociacion-form.component.scss']
})
export class TipoNegociacionFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  tipo: TipoNegociacion;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  loading$: Subscription;
  sendForm$: Subscription;
  tipo$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _tipoNegociacionService: TipoNegociacionService,
    private _router: Router,
    private store: Store<PavsoState>,
  ) {
    this.tipo = new TipoNegociacion();
    this.tipo.codcia = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;

      this.obtenerTipoNegociacion();
    })
  }

  obtenerTipoNegociacion(): void {
    this.tipo$ = this._tipoNegociacionService.obtenerTipoNegociacion(this.uid).subscribe(
      tipo => this.tipo = tipo[0],
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;
    this.uid == '0' ? this.registrarTipoNegociacion(f): this.actualizarTipoNegociacion(f);
  }

  /**
   * Método para registrar un nuevo tipo de negociación.
   * @param f
   */
  registrarTipoNegociacion(f: NgForm): void {
    this.tipo.coduse = this._configurationService.obtenerIdUsuario();

    this.sendForm$ = this._tipoNegociacionService.registrarTipoNegociacion(this.tipo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.tipo-negociacion.success-store', '400px', 'auto', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar los datos de un tipo de negociación existente
   * @param f
   */
  actualizarTipoNegociacion(f: NgForm): void {
    this.sendForm$ = this._tipoNegociacionService.actualizarTipoNegociacion(this.tipo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.tipo-negociacion.success-update', '400px', 'auto', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {
    if(!this.tipo.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    return true;
  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/tipos-de-negociacion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.loading$,
      this.tipo$,
    ])
  }
}
