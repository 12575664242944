import { logo } from "@shared/resources/logo";
import { Canvas, Cell, Img, Line, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";
import { NumeroALetras } from "../../conversions/number2letters.conversion";
import { noBorders, topBorder } from "../utils/pdfmake.constant";
import { obtenerNulls } from "../utils/pdfmake.function";
import { RegistroCompra } from "src/app/models";
import { PavsoColors } from "@data/enums/color.enum";
import { numberWithCommas } from "@utils/formats/number.format";

/**
 * Método que genera el PDF de Asiento Contable del Registro de Compra
 * @param data Data to set on Header and Body
 */
export async function generarPdfRCAsientoContable(otherData, data: RegistroCompra) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);


  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${otherData.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  // variables
  const now = new Date();
  const subdiario = (otherData.subdiarios.find(item => item.sco_codsco == data.sco_codsco)).sco_descri;
  let detalle = [];
  let [totalDebeNac, totalHaberNac, totalDebeExt, totalHaberExt] = [0, 0, 0, 0];

  data.detalle.forEach(item => {
    totalDebeNac = totalDebeNac + (item.ldi_inddha == "D"? item.ldi_impnac: 0);
    totalHaberNac = totalHaberNac + (item.ldi_inddha == "H"? item.ldi_impnac: 0);
    totalDebeExt = totalDebeExt + (item.ldi_inddha == "D"? item.ldi_impmex: 0);
    totalHaberExt = totalHaberExt + (item.ldi_inddha == "H"? item.ldi_impmex: 0);

    detalle.push([
      new Cell(
        new Txt(item.ldc_corldc).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.ccn_codccn).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("FACTURAS EMITIDAS POR PAGAR M.N. TERCEROS").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.cli_codcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("GARCÍA MARTINEZ ANDY JESUS").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.ccs_codccs).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.tdo_codtdo).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.ldi_docref).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.ldi_tipcam).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt((item.ldi_inddha == "D")? item.ldi_impnac.toFixed(2): "").fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt((item.ldi_inddha == "H")? item.ldi_impnac.toFixed(2): "").fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt((item.ldi_inddha == "D")? item.ldi_impmex.toFixed(2): "").fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt((item.ldi_inddha == "H")? item.ldi_impmex.toFixed(2)  : "").fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.ldi_gloldi).fontSize(7).end
      ).border(noBorders).end,
    ])
  })

  detalle.push([
    new Cell(
      new Txt("").end
    ).border(topBorder).end,
    new Cell(
      new Txt("").end
    ).border(topBorder).end,
    new Cell(
      new Txt("").end
    ).border(topBorder).end,
    new Cell(
      new Txt("").end
    ).border(topBorder).end,
    new Cell(
      new Txt("").end
    ).border(topBorder).end,
    new Cell(
      new Txt(`TOTAL ASIENTO: ${data.sco_codsco} - 00001`).fontSize(7).end
    ).border(topBorder).colSpan(4).end,
    new Cell(
      new Txt("").end
    ).border(topBorder).end,
    new Cell(
      new Txt("").end
    ).border(topBorder).end,
    new Cell(
      new Txt("").end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totalDebeNac.toFixed(2))).fontSize(7).alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totalHaberNac.toFixed(2))).fontSize(7).alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totalDebeExt.toFixed(2))).fontSize(7).alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totalHaberExt.toFixed(2))).fontSize(7).alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(topBorder).end
  ])

  pdf.add(
    new Table([
      [
        new Cell(new Txt(otherData.nombre_cliente).fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt(`${now}`).alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(otherData.ruc).fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Asiento Contable").alignment('center').bold().fontSize(14).end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("7").alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(`PERIODO ${data.ano_codano} ${data.mes_codmes}`).alignment('center').bold().fontSize(10).end,
          ]).end
        ).border(noBorders).colSpan(11).end,
        ...obtenerNulls(10),
        new Cell(
          new Txt("VOUCHER: 00001").bold().fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt(`SUBDIARIO: ${data.sco_codsco} ${subdiario}`).bold().fontSize(8).end
        ).border(noBorders).colSpan(13).end,
        ...obtenerNulls(12),
        new Cell(
          new Txt(`FECHA: ${data.cco_fecreg}`).bold().fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt(`GLOSA: ${data.cco_glocco}`).bold().fontSize(8).end
        ).border(noBorders).colSpan(13).end,
        ...obtenerNulls(12),
        new Cell(
          new Txt(`ESTADO: ${(data.dpc_indsta == "1")?"VIGENTE": "CANCELADO"}`).bold().fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IT").fontSize(8).bold().end
        ).rowSpan(2).end,
        new Cell(
          new Txt("CUENTA").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("DESCRIPCIÓN").fontSize(8).bold().end
        ).rowSpan(2).end,
        new Cell(
          new Txt("COD. AUX.").fontSize(8).bold().end
        ).rowSpan(2).end,
        new Cell(
          new Txt("AUXILIAR").fontSize(8).bold().end
        ).rowSpan(2).end,
        new Cell(
          new Txt("CE.CO.").fontSize(8).bold().end
        ).rowSpan(2).end,
        new Cell(
          new Txt("DOC.").fontSize(8).bold().end
        ).rowSpan(2).end,
        new Cell(
          new Txt("NRO. DOC.").fontSize(8).bold().end
        ).rowSpan(2).end,
        new Cell(
          new Txt("T.C.").fontSize(8).bold().end
        ).rowSpan(2).end,
        new Cell(
          new Txt("MONEDA NACIONAL").fontSize(8).bold().end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("MONEDA EXTRANJERA").fontSize(8).bold().end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("GLOSA").fontSize(8).bold().end
        ).rowSpan(2).end,
      ],
      [
        ...obtenerNulls(9),
        new Cell(
          new Txt("DEBE").bold().fontSize(10).end,
        ).end,
        new Cell(
          new Txt("HABER").bold().fontSize(10).end,
        ).end,
        new Cell(
          new Txt("DEBE").bold().fontSize(10).end,
        ).end,
        new Cell(
          new Txt("HABER").bold().fontSize(10).end,
        ).end,
        null
      ],
      ...detalle,

    ]).headerRows(8).widths([20,35,100,40,125,30,20,45,20,40,40,40,40,80]).end
  )

  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("\n").bold().fontSize(10).end,
        ).end,
        new Cell(
          new Txt("\n").bold().fontSize(10).end,
        ).end,
        new Cell(
          new Txt("\n").bold().fontSize(10).end,
        ).end,
      ],
      [
        new Cell(
          new Txt("HECHO POR: pavsoweb").bold().fontSize(10).alignment('center').end,
        ).end,
        new Cell(
          new Txt("V°B° CONTADOR").bold().fontSize(10).alignment('center').end,
        ).end,
        new Cell(
          new Txt("V°B°").bold().fontSize(10).alignment('center').end,
        ).end,
      ]
    ]).widths([260,260,260]).end
  )

  pdf.create().open();
}

/**
 * show report compras
 * @param data
 */
export async function verPdfReport(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([ 40, 40, 40, 40 ]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            await new Img(logo).alignment('left').width(120).build(),
          ]).end
        ).end,

        new Cell(
          new Stack([
            new Txt(`${data.CIA_NOMCIA}`).fontSize(11).alignment('center').color('#000').bold().end,
            pdf.ln(1),
            new Txt(`RUC: ${data.CIA_NUMRUC} ${data.CIA_DIRCIA}`).fontSize(9).alignment('center').color('#000').end,
            pdf.ln(2),
          ]).alignment('center').end ,
        ).end,
        new Cell(
          new Txt('').end
        ).end,

      ]
    ]).layout('noBorders').widths([ 150, 225, 100 ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`ORDEN DE COMPRA N° :`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`${data.ocm_numocm}`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`APROBADO`).fontSize(11).bold().end
          ]).end
        ).end,
        new Table([
          [
            new Cell(
              new Txt(`SE`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`DIA`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`MES`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`AÑO`).fontSize(9).bold().end
            ).end,
          ],
          [
            new Cell(
              new Stack([
                new Txt(`${data.semana}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.dias}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.mes}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.anio}`).fontSize(8).end
              ]).end
            ).end,
          ]
        ]).widths([ 20, 20, 20, 20 ]).end
      ]
    ]).layout('noBorders').widths([ 130, 120, 110, 120 ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('SEÑOR(ES)').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_nomcli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('Condicion').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('RUC').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_codcli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('Tipo OC').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('DIRECCION').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_dircli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('TELEFONO').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_numtlf}`).fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('Estimados señores').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('E-Mail').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('Sirvanse atender el siguiente pedido ').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Att. Sr.').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('según las especificaciones Técnicas').fontSize(7).end
                ]).end
              ).end,
            ]
          ]).layout('noBorders').widths([120, 240, 120]).end
        ).end
      ],

    ]).widths([500]).end
  )

  pdf.add(
    new Table([
      [
        new Table([
          [
            new Cell(
              new Txt(`Descripción Producto/Servicio`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Unidad`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Cantidad`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`P. Unit. S/.`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Total S/.`).fontSize(8).bold().end
            ).end
          ],

        ]).widths([260, 50, 50, 50, 50]).end
      ]
    ]).layout('noBorders').widths([500]).end
  )

  let items = []
  data.detalle_produc.forEach(element => {
    items.push([
      new Cell(
        new Txt(`${element.prd_desesp}`).fontSize(8).end
      ).end,
      new Cell(
        new Txt(`${element.ume_codume}`).fontSize(8).alignment('right').end
      ).end,
      new Cell(
        new Txt(`${element.ocd_canocd}`).fontSize(8).alignment('right').end
      ).end,
      new Cell(
        new Txt(`${(element.ocd_preuni).toFixed(2)}`).alignment('right').fontSize(8).end
      ).end,
      new Cell(
        new Txt(`${(element.ocd_imptot).toFixed(2)}`).alignment('right').fontSize(8).end
      ).end
    ],)
  });

  pdf.add(
    new Table([
      [
        new Table(items).layout('noBorders').widths([260, 50, 50, 50, 50]).end
      ]
    ]).layout('noBorders').widths([500]).end
  )

  pdf.add(
    new Stack([
      pdf.ln(1),
      new Txt('Observaciones').bold().fontSize(8).decoration('underline').end,
      pdf.ln(1)
    ]).end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`Son: ${NumeroALetras((data.ocm_imptot*1.18))}`).alignment('left').fontSize(8).end
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`Valor Venta:`).fontSize(8).alignment('right').bold().end,
            new Txt(`IGV:`).fontSize(8).alignment('right').bold().end,
            new Txt(`Total: `).fontSize(8).alignment('right').bold().end,
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`${data.ocm_valvta.toFixed(2)}`).fontSize(8).alignment('right').end,
            new Txt(`${(data.ocm_valvta * 0.18).toFixed(2)}`).fontSize(8).alignment('right').end,
            new Txt(`${(data.ocm_valvta + data.ocm_valvta * 0.18).toFixed(2)}`).fontSize(8).alignment('right').end,
          ]).end
        ).end,
      ]
    ]).layout('noBorders').widths([360, 50, 50]).end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('Forma de Pago:').fontSize(8).alignment('right').end,
            new Txt('Cuenta:').fontSize(8).alignment('right').end,
            new Txt('F. Entrega:').fontSize(8).alignment('right').end,
            new Txt('Plazo de Entrega:').fontSize(8).alignment('right').end,
            new Txt('Lugar de Entrega:').fontSize(8).alignment('right').end,
            new Txt('Factura A:').fontSize(8).alignment('right').end,
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`${data.cpa_descri}`).fontSize(8).end,
            new Txt('').fontSize(8).end,
            new Txt((new Date()).toLocaleString()).fontSize(8).end,
            new Txt('').fontSize(8).end,
            new Txt(`${data.ldc_dirldc}`).fontSize(8).end,
            new Txt('').fontSize(8).end,
          ]).end
        ).end,
      ]
    ]).widths([ 250, 250 ]).layout('noBorders').end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(8)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('DAPUETO VILLON RENZO').fontSize(8).alignment('center').end,
                  new Txt('V° B°').fontSize(8).bold().alignment('center').end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('BARRETO MAVILA JUAN CARLOS').fontSize(8).alignment('center').end,
                  new Txt('2DO V° B°').fontSize(8).alignment('center').bold().end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('BARRETO MAVILA JUAN CARLOS').fontSize(8).alignment('center').end,
                  new Txt('APROBADO POR').fontSize(8).bold().alignment('center').end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end
      ]
    ]).layout('noBorders').widths([135,25,135,25,135]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Agredeceremos presentar su factura y Guia de remisión con V°.B°. de Almacé Principal, acompañado con una copia de esta OC, Caso contrario no se recibira la factura.').fontSize(8).bold().end
        ).end
      ],
      [
        new Cell(
          new Stack([
            new Txt('Horarios de Atención:').fontSize(8).bold().end,
            new Txt('Recepción de Mercadería (Almacén) - De Lunes a Viernes de 8 am. a 6 pm. y Sabados de 8 am. a 12 pm.').fontSize(8).bold().end,
            new Txt('Recepción de Facturas (Logística) - De Lunes a Viernes de 9 am. a 5.30 pm. y Sabados de 9 am. a 12 pm.').fontSize(8).bold().end
          ]).end
        ).end
      ]
    ]).end
  )

  pdf.create().open();

}

/**
 * show report order purchase v2
 * @param data
 */
export async function verPdf2(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([ 40, 40, 40, 40 ]);
  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, margin: [ 10, 10, 20, 20 ], alignment: 'right', fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            await new Img(logo).alignment('left').width(120).build(),
          ]).end
        ).end,

        new Cell(
          new Stack([
            new Txt(`${data.CIA_NOMCIA}`).fontSize(11).alignment('center').color('#000').bold().end,
            pdf.ln(1),
            new Txt(`RUC: ${data.CIA_NUMRUC} ${data.CIA_DIRCIA}`).fontSize(9).alignment('center').color('#000').end,
            pdf.ln(2),
          ]).alignment('center').end ,
        ).end,
        new Cell(
          new Txt('').end
        ).end,

      ]
    ]).layout('noBorders').widths([ 150, 225, 100 ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`ORDEN DE COMPRA N° :`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`${data.ocm_numocm}`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`APROBADO`).fontSize(11).bold().end
          ]).end
        ).end,
        new Table([
          [
            new Cell(
              new Txt(`SE`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`DIA`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`MES`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`AÑO`).fontSize(9).bold().end
            ).end,
          ],
          [
            new Cell(
              new Stack([
                new Txt(`${data.semana}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.dias}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.mes}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.anio}`).fontSize(8).end
              ]).end
            ).end,
          ]
        ]).widths([ 20, 20, 20, 20 ]).end
      ]
    ]).layout('noBorders').widths([ 130, 120, 110, 120 ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('SEÑOR(ES)').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_nomcli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('Condicion').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('RUC').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_codcli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('Tipo OC').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('DIRECCION').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_dircli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('TELEFONO').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_numtlf}`).fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('Estimados señores').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('E-Mail').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('Sirvanse atender el siguiente pedido ').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Att. Sr.').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('según las especificaciones Técnicas').fontSize(7).end
                ]).end
              ).end,
            ]
          ]).layout('noBorders').widths([120, 240, 120]).end
        ).end
      ],

    ]).widths([500]).end
  )

  pdf.add(
    new Table([
      [
        new Table([
          [
            new Cell(
              new Txt(`Descripción Producto/Servicio`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Unidad`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Cantidad`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`P. Unit. S/.`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Total S/.`).fontSize(8).bold().end
            ).end
          ],

        ]).widths([260, 50, 50, 50, 50]).end
      ]
    ]).layout('noBorders').widths([500]).end
  )

  let items = []
  data.detalle_produc.forEach(element => {
    items.push([
      new Cell(
        new Txt(`${element.prd_desesp}`).fontSize(8).end
      ).end,
      new Cell(
        new Txt(`${element.ume_codume}`).fontSize(8).alignment('right').end
      ).end,
      new Cell(
        new Txt(`${element.ocd_canocd}`).fontSize(8).alignment('right').end
      ).end,
      new Cell(
        new Txt(`${(element.ocd_preuni).toFixed(2)}`).alignment('right').fontSize(8).end
      ).end,
      new Cell(
        new Txt(`${(element.ocd_imptot).toFixed(2)}`).alignment('right').fontSize(8).end
      ).end
    ],)
  });



  pdf.add(
    new Table([
      [
        new Table(items).layout('noBorders').widths([260, 50, 50, 50, 50]).end
      ]
    ]).layout('noBorders').widths([500]).end
  )

  pdf.add(
    new Stack([
      pdf.ln(1),
      new Txt('Observaciones').bold().fontSize(8).decoration('underline').end,
      pdf.ln(1)
    ]).end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`Son: ${NumeroALetras((data.ocm_imptot*1.18))}`).alignment('left').fontSize(8).end
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`Valor Venta:`).fontSize(8).alignment('right').bold().end,
            new Txt(`IGV:`).fontSize(8).alignment('right').bold().end,
            new Txt(`Total: `).fontSize(8).alignment('right').bold().end,
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`${data.ocm_valvta.toFixed(2)}`).fontSize(8).alignment('right').end,
            new Txt(`${(data.ocm_valvta * 0.18).toFixed(2)}`).fontSize(8).alignment('right').end,
            new Txt(`${(data.ocm_valvta + data.ocm_valvta * 0.18).toFixed(2)}`).fontSize(8).alignment('right').end,
          ]).end
        ).end,
      ]
    ]).layout('noBorders').widths([360, 50, 50]).end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('Forma de Pago:').fontSize(8).alignment('right').end,
            new Txt('Cuenta:').fontSize(8).alignment('right').end,
            new Txt('F. Entrega:').fontSize(8).alignment('right').end,
            new Txt('Plazo de Entrega:').fontSize(8).alignment('right').end,
            new Txt('Lugar de Entrega:').fontSize(8).alignment('right').end,
            new Txt('Factura A:').fontSize(8).alignment('right').end,
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`${data.cpa_descri}`).fontSize(8).end,
            new Txt('').fontSize(8).end,
            new Txt((new Date()).toLocaleString()).fontSize(8).end,
            new Txt('').fontSize(8).end,
            new Txt(`${data.ldc_dirldc}`).fontSize(8).end,
            new Txt('').fontSize(8).end,
          ]).end
        ).end,
      ]
    ]).widths([ 250, 250 ]).layout('noBorders').end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(8)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('DAPUETO VILLON RENZO').fontSize(8).alignment('center').end,
                  new Txt('V° B°').fontSize(8).bold().alignment('center').end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('BARRETO MAVILA JUAN CARLOS').fontSize(8).alignment('center').end,
                  new Txt('2DO V° B°').fontSize(8).alignment('center').bold().end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('BARRETO MAVILA JUAN CARLOS').fontSize(8).alignment('center').end,
                  new Txt('APROBADO POR').fontSize(8).bold().alignment('center').end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end
      ]
    ]).layout('noBorders').widths([135,25,135,25,135]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Agredeceremos presentar su factura y Guia de remisión con V°.B°. de Almacé Principal, acompañado con una copia de esta OC, Caso contrario no se recibira la factura.').fontSize(8).bold().end
        ).end
      ],
      [
        new Cell(
          new Stack([
            new Txt('Horarios de Atención:').fontSize(8).bold().end,
            new Txt('Recepción de Mercadería (Almacén) - De Lunes a Viernes de 8 am. a 6 pm. y Sabados de 8 am. a 12 pm.').fontSize(8).bold().end,
            new Txt('Recepción de Facturas (Logística) - De Lunes a Viernes de 9 am. a 5.30 pm. y Sabados de 9 am. a 12 pm.').fontSize(8).bold().end
          ]).end
        ).end
      ]
    ]).end
  )

  pdf.create().open();
}

/**
 * show report order purchase v3
 * @param data
 */
export async function verPdf3(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([ 40, 40, 40, 40 ]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: headers.username`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            await new Img(logo).alignment('left').width(120).build(),
          ]).end
        ).end,

        new Cell(
          new Stack([
            new Txt(`${data.CIA_NOMCIA}`).fontSize(11).alignment('center').color('#000').bold().end,
            pdf.ln(1),
            new Txt(`RUC: ${data.CIA_NUMRUC} ${data.CIA_DIRCIA}`).fontSize(9).alignment('center').color('#000').end,
            pdf.ln(2),
          ]).alignment('center').end ,
        ).end,
        new Cell(
          new Txt('').end
        ).end,

      ]
    ]).layout('noBorders').widths([ 150, 225, 100 ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`ORDEN DE COMPRA N° :`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`${data.ocm_numocm}`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`APROBADO`).fontSize(11).bold().end
          ]).end
        ).end,
        new Table([
          [
            new Cell(
              new Txt(`SE`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`DIA`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`MES`).fontSize(9).bold().end
            ).end,
            new Cell(
              new Txt(`AÑO`).fontSize(9).bold().end
            ).end,
          ],
          [
            new Cell(
              new Stack([
                new Txt(`${data.semana}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.dias}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.mes}`).fontSize(8).end
              ]).end
            ).end,
            new Cell(
              new Stack([
                new Txt(`${data.anio}`).fontSize(8).end
              ]).end
            ).end,
          ]
        ]).widths([ 20, 20, 20, 20 ]).end
      ]
    ]).layout('noBorders').widths([ 130, 120, 110, 120 ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('SEÑOR(ES)').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_nomcli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('Condicion').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('RUC').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_codcli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('Tipo OC').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('DIRECCION').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_dircli}`).fontSize(8).end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('TELEFONO').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt(`${data.cli_numtlf}`).fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('Estimados señores').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('E-Mail').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('Sirvanse atender el siguiente pedido ').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Att. Sr.').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('según las especificaciones Técnicas').fontSize(7).end
                ]).end
              ).end,
            ]
          ]).layout('noBorders').widths([120, 240, 120]).end
        ).end
      ],

    ]).widths([500]).end
  )

  pdf.add(
    new Table([
      [
        new Table([
          [
            new Cell(
              new Txt(`Descripción Producto/Servicio`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Unidad`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Cantidad`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`P. Unit. S/.`).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt(`Total S/.`).fontSize(8).bold().end
            ).end
          ],

        ]).widths([260, 50, 50, 50, 50]).end
      ]
    ]).layout('noBorders').widths([500]).end
  )

  let items = []
  data.detalle_produc.forEach(element => {
    items.push([
      new Cell(
        new Txt(`${element.prd_desesp}`).fontSize(8).end
      ).end,
      new Cell(
        new Txt(`${element.ume_codume}`).fontSize(8).alignment('right').end
      ).end,
      new Cell(
        new Txt(`${element.ocd_canocd}`).fontSize(8).alignment('right').end
      ).end,
      new Cell(
        new Txt(`${(element.ocd_preuni).toFixed(2)}`).alignment('right').fontSize(8).end
      ).end,
      new Cell(
        new Txt(`${(element.ocd_imptot).toFixed(2)}`).alignment('right').fontSize(8).end
      ).end
    ],)
  });

  pdf.add(
    new Table([
      [
        new Table(items).layout('noBorders').widths([260, 50, 50, 50, 50]).end
      ]
    ]).layout('noBorders').widths([500]).end
  )

  pdf.add(
    new Stack([
      pdf.ln(1),
      new Txt('Observaciones').bold().fontSize(8).decoration('underline').end,
      pdf.ln(1)
    ]).end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`Son: ${NumeroALetras((data.ocm_imptot*1.18))}`).alignment('left').fontSize(8).end
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`Valor Venta:`).fontSize(8).alignment('right').bold().end,
            new Txt(`IGV:`).fontSize(8).alignment('right').bold().end,
            new Txt(`Total: `).fontSize(8).alignment('right').bold().end,
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`${data.ocm_valvta.toFixed(2)}`).fontSize(8).alignment('right').end,
            new Txt(`${(data.ocm_valvta * 0.18).toFixed(2)}`).fontSize(8).alignment('right').end,
            new Txt(`${(data.ocm_valvta + data.ocm_valvta * 0.18).toFixed(2)}`).fontSize(8).alignment('right').end,
          ]).end
        ).end,
      ]
    ]).layout('noBorders').widths([360, 50, 50]).end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('Forma de Pago:').fontSize(8).alignment('right').end,
            new Txt('Cuenta:').fontSize(8).alignment('right').end,
            new Txt('F. Entrega:').fontSize(8).alignment('right').end,
            new Txt('Plazo de Entrega:').fontSize(8).alignment('right').end,
            new Txt('Lugar de Entrega:').fontSize(8).alignment('right').end,
            new Txt('Factura A:').fontSize(8).alignment('right').end,
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt(`${data.cpa_descri}`).fontSize(8).end,
            new Txt('').fontSize(8).end,
            new Txt((new Date()).toLocaleString()).fontSize(8).end,
            new Txt('').fontSize(8).end,
            new Txt(`${data.ldc_dirldc}`).fontSize(8).end,
            new Txt('').fontSize(8).end,
          ]).end
        ).end,
      ]
    ]).widths([ 250, 250 ]).layout('noBorders').end
  )

  pdf.add(
    new Canvas([
      new Line([0,10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(8)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('DAPUETO VILLON RENZO').fontSize(8).alignment('center').end,
                  new Txt('V° B°').fontSize(8).bold().alignment('center').end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('BARRETO MAVILA JUAN CARLOS').fontSize(8).alignment('center').end,
                  new Txt('2DO V° B°').fontSize(8).alignment('center').bold().end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  pdf.ln(4),
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Stack([
                  new Txt('BARRETO MAVILA JUAN CARLOS').fontSize(8).alignment('center').end,
                  new Txt('APROBADO POR').fontSize(8).bold().alignment('center').end,
                  new Txt('19/02/2018').fontSize(8).alignment('center').end,
                ]).end
              ).end,
            ]
          ]).widths([125]).end
        ).end
      ]
    ]).layout('noBorders').widths([135,25,135,25,135]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Agredeceremos presentar su factura y Guia de remisión con V°.B°. de Almacé Principal, acompañado con una copia de esta OC, Caso contrario no se recibira la factura.').fontSize(8).bold().end
        ).end
      ],
      [
        new Cell(
          new Stack([
            new Txt('Horarios de Atención:').fontSize(8).bold().end,
            new Txt('Recepción de Mercadería (Almacén) - De Lunes a Viernes de 8 am. a 6 pm. y Sabados de 8 am. a 12 pm.').fontSize(8).bold().end,
            new Txt('Recepción de Facturas (Logística) - De Lunes a Viernes de 9 am. a 5.30 pm. y Sabados de 9 am. a 12 pm.').fontSize(8).bold().end
          ]).end
        ).end
      ]
    ]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Pedidos vs Stock
 * @param headers
 * @param data
 */
export async function generarReportePDFPedidosVsStock(headers, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, margin: [ 10, 10, 20, 20 ], alignment: 'right', fontSize: 8, color: 'gray', bold: true},
    ]
  });  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.coduse}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`${element.ALM_CODALM} ${element.ALM_DESCRI}`).fontSize(8).bold().alignment('center').end
      ).colSpan(7).border(noBorders).end,
      ...obtenerNulls(6)
    ],);

    element.detalle.forEach(item => {
      detalle.push([
        new Cell(
          new Txt(`${item.fpr_codfpr} ${item.FPR_DESCRI}`).fontSize(7).bold().end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6)
      ],)

      item.detalle.forEach(el => {
        detalle.push( [
          new Cell(
            new Txt('').end
          ).border(noBorders).end,
          new Cell(
            new Txt(el.PRD_CODPRD).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(el.prd_desesp).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(el.ume_codume).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(el.pcd_canpen.toFixed(2))).alignment('right').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(el.spa_canfin.toFixed(2))).alignment('right').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas((el.spa_canfin - el.pcd_canpen).toFixed(0))).alignment('right').fontSize(6).end
          ).border(noBorders).end,
        ],)
      });
    });
  });
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(8).bold().end,
            new Txt('RCOM_PEDI_STOC').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Pedidos Vs Stock').bold().end
        ).alignment('center').border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Fecha: ${headers.date}`).fontSize(8).bold().alignment('right').end,
            new Txt(`Hora: ${headers.hour}`).fontSize(8).bold().alignment('right').end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('').bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('CÓDIGO').bold().end
        ).border(noBorders).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('PRODUCTO').bold().end
        ).border(noBorders).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('U.M.').bold().end
        ).border(noBorders).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('PEDIDOS PEND. APROBADOS').alignment('center').bold().end
        ).border(noBorders).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('STOCK ACTUAL').bold().alignment('center').end
        ).border(noBorders).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('DISPONIBLE').bold().alignment('center').end
        ).border(noBorders).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle
    ]).headerRows(2).widths([30, 35, 200, 30, 65, 65, 65]).end
  )

  pdf.create().open();
}
