import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'tipo-servicio-dialog',
  templateUrl: './tipo-servicio-dialog.component.html',
  styleUrls: ['./tipo-servicio-dialog.component.scss']
})
export class TipoServicioDialog {
  constructor(
    public dialogRef: MatDialogRef<TipoServicioDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data id', data.id)
  }
}
