import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SearchModuleComponent } from "@shared/components/dialogs/search-module/search-module.component";
import { ShortcutsDialog } from "@shared/components/dialogs/shortcuts/shortcuts.component";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    public dialog: MatDialog
  ) {}

  /**
   *
   * @param component
   * @param data
   * @param width
   * @param height
   * @param panelClass
   * @returns
   */
  openDialog(component: ComponentType<any>, data: string | Object, width: string, height: string, panelClass: string): Observable<any> {
    const dialogRef = this.dialog.open(component, {
      width,
      height: 'auto',
      panelClass: 'pav-dialog-container',
      data,
      disableClose: true
    })

    return dialogRef.afterClosed();
  }

  /**
   *
   * @param component
   * @param message
   * @param width
   * @param height
   * @returns
   */
  openDialogLoading(component: ComponentType<any>, message: string, width: string, height: string): Observable<any> {
    const dialogRef = this.dialog.open(component, {
      width,
      height,
      data: message,
      maxWidth: width
    })

    return dialogRef.afterClosed();
  }

  /**
   *
   * @returns
   */
  openDialogSearch() {
    const dialogRef = this.dialog.open(SearchModuleComponent, {
      width: '450px',
      data: '',
      autoFocus: false,
      panelClass: 'pav-dialog-container'
    })

    return dialogRef.afterClosed();
  }

  /**
   *
   * @returns
   */
  openDialogShortcut() {
    const dialogRef = this.dialog.open(ShortcutsDialog, {
      width: 'auto',
      data: '',
      autoFocus: false,
      panelClass: 'pav-dialog-container'
    })

    return dialogRef.afterClosed();
  }

}
