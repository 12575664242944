import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Actividad } from 'src/app/models/produccion/actividad';

@Injectable({
    providedIn: 'root',
  })
  export class ActividadService {
    constructor(
      private http: HttpClient,
      private _configurationService: ConfigurationService
    ) {}
 /**
   *
   * @returns
   */
 obtenerActividades(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionactividades/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerActividad(cod): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionactividades/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param actividad
   * @returns
   */
  registrarActividad(actividad: Actividad): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionactividades/crear`,
      actividad
    );
  }

  /**
   *
   * @param actividad
   * @returns
   */
  actualizarActividad(actividad: Actividad): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionactividades/modificar`,
      actividad
    );
  }
}