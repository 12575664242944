import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'modelo-form',
  templateUrl: './modelo-form.component.html',
  styleUrls: ['./modelo-form.component.scss']
})
export class ModeloFormComponent implements OnInit, OnDestroy {

  loaderData: boolean;
  loaderReg: boolean;
  btnName: string;

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
