<app-pavso-title-section [title]="'Transferencia entre cuentas'" [module]="'Tesorería'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s3">
        <select-search [label]="'Subdiario'" [placeholder]="'Ej.  '" [value]="'sco_codsco'" [name]="'sco_codsco'"
          [description]="'sco_descri'" [disabled]="loaderData" [data]="subdiarios"
          (cambioSelect)="filtro.subdiario = $event; " [model]="filtro.subdiario">
        </select-search>
      </div>
      <div class="col s3">
        <select-search [label]="'Banco'" [placeholder]="'Ej.  '" [value]="'BAN_CODBAN'" [name]="'BAN_CODBAN'"
          [description]="'cba_deslar'" [disabled]="loaderData" [data]="bancos" (cambioSelect)="filtro.banco = $event; "
          [model]="filtro.banco">
        </select-search>
      </div>
      <div class="col s3">
        <select-search [label]="'Auxiliar'" [placeholder]="'Ej.  '" [value]="'cli_codcli'" [name]="'cli_codcli'"
          [description]="'description'" [disabled]="loaderData" [data]="proveedores"
          (cambioSelect)="filtro.proveedor = $event; " [model]="filtro.proveedor">
        </select-search>
      </div>
      <div class="col s3">
        <select-search [label]="'Tipo Doc'" [placeholder]="'Ej.  '" [value]="'TDO_CODTDO'" [name]="'TDO_CODTDO'"
          [description]="'TDO_DESCOR'" [disabled]="loaderData" [data]="tiposDoc"
          (cambioSelect)="filtro.tipoDoc = $event; " [model]="filtro.tipoDoc">
        </select-search>
      </div>
      <div class="col s3">
        <mat-form-field class="example-full-width">
          <mat-label>Número Doc.</mat-label>
          <input matInput placeholder="Ej. F00100000345">
        </mat-form-field>
      </div>

      <div class="col s3">
        <mat-form-field class="example-full-width">
          <mat-label>Monto</mat-label>
          <input matInput placeholder="Ej. 1000">
        </mat-form-field>
      </div>

      <div class="col s3">
        <mat-form-field>
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </mat-card>


  <div class="mat-elevation-z0">

    <!-- <table-title-action title="Todos las egresos" url="/modulo-cajabanco/egreso-banco" entity="" [params]="['0']"></table-title-action> -->

    <div class="pavso_card_more_options">
      <div class="pav-table-header-icon"></div>
      <p class="pav-table-header-title">
        Todas las transferencias
      </p>
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="'Buscar'" #input>
      </div>
      &nbsp;&nbsp;&nbsp;
      <div class="isMobile">
        <div class="pav-button-icon-download-excel">
          <mat-icon svgIcon="download"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <button class="pav-button-download-excel" mat-flat-button color="primary">
          <mat-icon>file_download</mat-icon>
          Descargar
        </button>
      </div>
      <div class="isMobile">
        <div class="pav-button-icon-add" [routerLink]="['/modulo-cajabanco/transferencia-entre-cuenta', '0', '0', '0', '0']">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <a>
          <button class="pav-button-add" mat-flat-button color="primary"
            [routerLink]="['/modulo-cajabanco/transferencia-entre-cuenta', '0', '0', '0', '0']">
            <mat-icon>add</mat-icon>
            Agregar
          </button>
        </a>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let row" data-label="Subdiario">
          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-cajabanco/ingreso-banco', row.ANO_CODANO, row.MES_CODMES, row.SCO_CODSCO, row.LDC_CORLDC]"
              mat-icon-button color="primary">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>

          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="SCO_CODSCO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Subdiario </th>
        <td mat-cell *matCellDef="let row" data-label="Subdiario"> {{row.SCO_CODSCO}} </td>
      </ng-container>

      <ng-container matColumnDef="LDC_CORLDC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo </th>
        <td mat-cell *matCellDef="let row" data-label="Subdiario"> {{row.LDC_CORLDC}} </td>
      </ng-container>

      <ng-container matColumnDef="PBA_FECORD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha">
          <div [ngStyle]="{width: '85px'}">
            {{row.PBA_FECORD}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="CBA_CODCBA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Banco </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Banco">
          {{row.CBA_CODCBA}}
        </td>
      </ng-container>

      <ng-container matColumnDef="TDO_CODTDO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo. Doc. </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono">
          <div [ngStyle]="{width: '50px', textAlign: 'center', paddingRight: '5px'}">
            {{row.TDO_CODTDO}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="PBA_NUMDOC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Doc. </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.PBA_NUMDOC}} </td>
      </ng-container>

      <ng-container matColumnDef="TMO_CODTMO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono">
          <div [ngStyle]="{textAlign: 'center'}">
            {{row.TMO_CODTMO ? 'S/': '$'}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="CLI_CODCLI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.auxiliar.cli_nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="PBA_IMPSOL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto M.N. </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono">
          <div [ngStyle]="{width: '65px', paddingRight: '10px', textAlign: 'right'}">
            {{row.PBA_IMPSOL.toFixed(2)}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="PBA_IMPDOL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto M.E. </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono">
          <div [ngStyle]="{width: '65px', paddingRight: '10px', textAlign: 'right'}">
            {{row.PBA_IMPDOL.toFixed(2)}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="PBA_INDSTA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.PBA_INDSTA ? 'Activo': 'Cancelado'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="12">No se encontraron registros "{{input.value}}"</td>

      </tr>

    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
