export class MovimientoTipoDocumento{
    cia_codcia: string;
    mmo_codmmo: string;
    tdo_codtdo: string;
    tdo_deslar: string;
    mdr_indref: number;
    mdr_coduse: string;
    description: string;
    isEditing: boolean;
    constructor(cia_codcia?:string, mmo_codmmo?:string,mdr_coduse?:string){
        this.cia_codcia = cia_codcia;
        this.mmo_codmmo = mmo_codmmo;
        this.mdr_coduse = mdr_coduse;
        this.isEditing = true;
        this.description ='';
    }
}