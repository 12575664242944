export class MovimientoRemuneracionColaborador {
  codcli: string;
  nomcli: string;
  cannut: number;
  imprem: number | string;
  fecupd: Date;
  codnut: string;
  canhrs: number;
  canmin: number;
  valnmt: number;
  indmod: number;
  valnmt2: number;
  isEditing: Boolean;

  constructor() {
    this.cannut = 0;
    this.imprem = 0;
    this.canhrs = 0;
    this.canmin = 0;
    this.valnmt = 0;
    this.valnmt2 = 0;
    this.indmod = 0;
    this.fecupd = new Date();
    this.isEditing = true;
  }
}
