<app-pavso-title-section [title]="'Línea de Producto'" [module]="'Almacén'" [estado]="linea.lpd_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="linea.lpd_codlpd" matInput placeholder="Código" name="lpd_codlpd"
              readonly>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Inicial</mat-label>
            <input type="text" [(ngModel)]="linea.lpd_indini" [maxlength]="2" matInput placeholder="Inicial" name="lpd_indini"
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cuenta Contable</mat-label>
            <mat-select [(ngModel)]="linea.ccn_codccn" name="ccn_codccn">
              <mat-option *ngFor="let cuenta of cuentasContable" [value]="cuenta.cuenta">
                {{cuenta.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l8">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput [(ngModel)]="linea.lpd_descri" placeholder="Descripción" name="lpd_descri"
              required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Desc. Abrev.</mat-label>
            <input type="text" matInput [(ngModel)]="linea.lpd_desabr" placeholder="Desc. Abrev." name="lpd_desabr"
              required [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-almacen/linea-producto">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
