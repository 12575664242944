export class Purchase {
  CIA_CODCIA: any;
  CCO_CODCCO: any;
  CLI_CODCLI: string;
  CLI_CODDOC: any;
  TDO_CODTDO: any;
  CCO_NUMDOC: any;
  CCO_GLOCCO: any;
  CCO_FECEMI: any;
  CCO_FECVEN: any;
  TMO_CODTMO: any;
  CCN_CODCCN: any;
  CCO_IMPDOC: any;
  CCO_IMPCCO: any;
  CCO_SALCCO: any;
  CCO_SALLBA: any;
  CCO_SALRES: any;
  CCO_IMPABO: any;
  CCO_IMPIGV: any;
  CCO_TASIGV: any;
  CCO_IMPCOR: any;
  CCO_IMPINA: any;
  CCO_IMPAFE: any;
  CCO_INDTCA: any;
  CCO_TIPCAM: any;
  CCO_CODPAG: any;
  MMO_CODMMO: any;
  CPA_CODCPA: any;
  TDO_DOCREF: any;
  CCO_NUMREF: any;
  ANO_CODANO: any;
  MES_CODMES: any;
  SCO_CODSCO: any;
  LDC_CORLDC: any;
  SCO_CODASI: any;
  LDC_CORASI: any;
  CCO_FECREG: any;
  AGE_CODAGE: any;
  CCO_ABOTRA: any;
  CCO_CODANT: any;
  CCO_INDSTA: string;
  CCO_FECUPD: any;
  CCO_CODUSE: string;
  CCO_IMPRET: any;
  CCO_ABORET: any;
  CCO_RETGAS: any;
  IRI_CODIRI: any;
  SUC_CODSUC: any;
  TGA_CODTGA: any;
  CCO_FECREF: any;
  OCM_NUMOCM: any;
  cco_numcuo: any;
  ipi_codipi: any;
  CCO_IMPPER: any;
  CCO_PERMNA: any;
  CCO_MOVIL: any;
  cco_cbmoti: any;
  cco_condic: any;
  cco_code: any;
  sco_descri: any;
  libr_ldc: Array<LibrLdc>;
  asiento: Array<Asiento>;
  detall_dpc: Array<Detall_Dpc>;

  constructor(
  ){
    this.CCO_INDSTA = '1';
    this.CCO_FECUPD = new Date();
  }
}

export class LibrLdc {
    constructor(
        public CIA_CODCIA: any,
        public ANO_CODANO: any,
        public MES_CODMES: any,
        public SCO_CODSCO: any,
        public LDC_CORLDC: any,
        public LDC_FECDIA: any,
        public LDC_GLODIA: any,
        public LDC_FLALIQ: any,
        public LDC_TIPCAM: any,
        public TTI_CODTTI: any,
        public LDC_TIPCON: any,
        public LDC_SITACA: any,
        public LDC_KEYANT: any,
        public LDC_CORASI: any,
        public SUC_CODSUC: any,
        public LDC_INDSTA: string,
        public LDC_FECUPD: any,
        public LDC_coduse: string,
        public ldc_codant: any,
    ){}
}

export class Asiento {
  CIA_CODCIA: any;
  ANO_CODANO: any;
  MES_CODMES: any;
  SCO_CODSCO: any;
  LDC_CORLDC: any;
  LDI_CORLDI: any;
  CCN_CODCCN: any;
  CLI_CODCLI: string;
  CCO_CODCCO: any;
  TMO_CODTMO: any;
  LDI_INDDHA: any;
  LDI_TIPCAM: any;
  LDI_TCAMEU: any;
  LDI_IMPNAC: any;
  LDI_IMPMEX: any;
  LDI_INDCCO: any;
  TDO_CODTDO: any;
  LDI_DOCREF: any;
  TDO_DOCREF: any;
  LDI_NUMREF: any;
  LDI_GLOLDI: any;
  FPA_CODFPA: any;
  REF_CODREF: any;
  LDI_INDDES: any;
  LDI_FECEMI: any;
  LDI_FECVEN: any;
  CCS_CODCCS: any;
  SUC_CODSUC: any;
  LDI_INDSTA: string;
  LDI_FECUPD: any;
  LDI_coduse: string;
  REC_NUMREC: any;
  VDE_CODVDE: any;
  ldi_impsal: any;
  fca_codfca: any;
  OCM_NUMOCM: any;
  ldi_indccl: any;
  ldi_useccl: any;
  ldi_fecccl: any;
  ldi_anoccl: any;
  ldi_mesccl: any;
  constructor(
  ) {
    this.LDI_INDSTA = '1';
    this.LDI_FECUPD = new Date();
  }
}

export class Detall_Dpc {
  CIA_CODCIA: any;
  CCO_CODCCO: any;
  DPC_INDDER: any;
  DPC_TASREN: any;
  DPC_TASIES: any;
  DPC_TASORE: any;
  DPC_IMPREN: any;
  DPC_IMPIES: any;
  DPC_IMPPER: any;
  DPC_IMPORE: any;
  DPC_FECREC: any;
  DPC_VENINI: any;
  DPC_DIFIGV: any;
  DPC_AFCDER: any;
  DPC_AFSDER: any;
  DPC_IMPISC: any;
  DPC_IGVISC: any;
  DPC_TASISC: any;
  DPC_OTRTRI: any;
  DPC_VALARE: any;
  DPC_INDDIF: any;
  DPC_PERINI: any;
  DPC_IMPDIF: any;
  DPC_NUMPOL: any;
  DPC_PARARA: any;
  DPC_IMPFOB: any;
  DPC_IMPFLE: any;
  DPC_IMPSEG: any;
  DPC_IMPCIF: any;
  DPC_IMPADV: any;
  DPC_REIPAP: any;
  DPC_AFEPOL: any;
  DPC_INDDET: any;
  DPC_DEPDET: any;
  DPC_IMPDET: any;
  DPC_FECDET: any;
  TIM_CODTIM: any;
  DPC_TASDET: any;
  DPC_INDNTC: any;
  DPC_ANOTRI: any;
  DPC_MESTRI: any;
  DPC_INDSTA: string;
  DPC_FECUPD: any;
  DPC_coduse: string;
  DPC_OTRREF: any;
  DPC_NUMDUA: any;
  DPC_ANODUA: any;
  OIM_NUMOIM: any;
  DPC_APOAFP: any;
  DPC_SEGAFP: any;
  DPC_COMAFP: any;
  DPC_RETAFP: any;
  CLI_INDRFP: any;
  AFP_CODAFP: any;
  CLI_COMAFP: any;
  sad_codsad: any;
  CCN_CUEGAS: any;
  dpc_impsis: any;
  dpc_impfis: any;
  t30_codt30: any;
  t31_codt31: any;
  constructor(
  ) {
    this.DPC_INDSTA = '1';
    this.DPC_FECUPD = new Date();
  }
}
