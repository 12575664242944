<app-pavso-title-section [title]="'Declaración mensual por ingresos en otra empresa'" [module]="'Planilla'" [estado]="rentaAcumulada.RQA_INDSTA"></app-pavso-title-section>
<!-- <app-pavso-title-section [title]="'Ingresos y retenciones de otras empresas'" [module]="'Planilla'"></app-pavso-title-section> -->

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarRentaAcumulada(f)" (keydown.enter)="$event.preventDefault()">
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Año</mat-label>
            <input [(ngModel)]="rentaAcumulada.ANO_CODANO" type="number" min="0" matInput placeholder="Año" name="anio" required readonly>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6"></div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Colaborador</mat-label>
            <mat-select required name="cod_sunat">
              <mat-option *ngFor="let colaborador of colaboradores" [value]="colaborador.codcli">
                {{colaborador.nomcli}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Ingresos otra empresa</mat-label>
            <input type="text" matInput placeholder="Ingresos otra empresa" type="number" min="0" name="descripcion" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Retenciones otra empresa</mat-label>
            <input type="text" matInput placeholder="Retenciones otra empresa" name="descripcion" required>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
