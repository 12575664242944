import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'side-ai',
  templateUrl: './side-ai.component.html',
  styleUrls: ['./side-ai.component.scss'],
  host: {}
})

export class SideAIComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
