<app-pavso-title-section [title]="'Solicitudes de compra'" [module]="'Compras'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todas las solicitudes" url="/modulo-compras/solicitud-de-compra" entity=""
      [params]="['0']"></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-compras/solicitud-de-compra', row.rco_numrco]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>

          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="rco_numrco">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.rco_numrco}} </td>
      </ng-container>

      <ng-container matColumnDef="cli_nomcli">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor sugerido </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.cli_nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="rco_fecreq">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.rco_fecreq}} </td>
      </ng-container>

      <ng-container matColumnDef="rco_apruse">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario aprobador </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.rco_apruse}} </td>
      </ng-container>

      <ng-container matColumnDef="rco_gloobs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Detalle </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

      </tr>

    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
