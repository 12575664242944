import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { SituacionDocumento } from 'src/app/models';
import { ConfiguraCobranza } from 'src/app/models/cobranzas/configura-cobranza';
import { MensajeWhatsappCuotaPorVencer } from '@data/interfaces/modules/cuentas-x-cobrar/mensaje-whatsapp-cuota-por-vencer';
import { MensajeWhatsappCuotaVencida } from '@data/interfaces/modules/cuentas-x-cobrar/mensaje-whatsapp-cuota-vencida';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CuentaCobrarService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @param cli_codcli
   * @param periodo
   * @returns
   */
  obtenerCuentaxCobrar(cli_codcli, periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/listar?cia=${this._configurationService.obtenerCompaniaCliente()}&cli=${cli_codcli}&periodo=${periodo}`
    );
  }

  obtenerCuentaxCobrarPorPeriodo(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/listar?cia=${this._configurationService.obtenerCompaniaCliente()}&periodo=${periodo}`
    ).pipe(
      map((cuentas: any) => {
        return cuentas.map(cuenta => {
          cuenta['description'] = `${cuenta.cuenta} | ${cuenta.cli_nomcli}`
          cuenta['description_reverse'] = `${cuenta.cli_nomcli} | ${cuenta.cuenta}`
          const fecha = new Date(cuenta.fec_emision);
          cuenta['description_completa'] = `${cuenta.nro_docu} | ${fecha.getDate()}/${fecha.getMonth()}/${fecha.getFullYear()} | ${cuenta.cli_nomcli}`
          return cuenta;
        })
      })
    );
  }

  /**
   *
   * @returns
   */
  listarSituacionCobranza() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascobranza/listasituacioncobranza`
    );
  }

  /**
   *
   * @param situacion
   * @returns
   */
  registrarSituacionDocumento(situacion: SituacionDocumento): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/situacion-de-documento`,
      situacion
    );
  }

  /**
   *
   * @param situacion
   * @returns
   */
  actualizarSituacionDocumento(situacion: SituacionDocumento): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/situacion-de-documento`,
      situacion
    );
  }

  /**
   *
   * @param configuraCobranza
   * @returns
   */
  registrarConfiguraCobranza(configuraCobranza: ConfiguraCobranza): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/configura-cobranza`,
      configuraCobranza
    );
  }

  /**
   *
   * @param configuraCobranza
   * @returns
   */
  actualizarConfiguraCobranza(configuraCobranza: ConfiguraCobranza): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/configura-cobranza`,
      configuraCobranza
    );
  }

  /**
   *
   * @returns
   */
  obtenerDataFiltroCuentaCobrar(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/filtro-reporte/cuentas-por-cobrar/${this._configurationService.obtenerCompaniaCliente()}`
    )
  }

  /**
   *
   * @param date
   * @returns
   */
  obtenerDataReporteDocumentoCobrarDocConSaldo(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/data/reporte/documentos-cobrar-doc-con-saldo/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
  *
  * @param date
  * @returns
  */
  obtenerDataReporteDocumentoCobrarDocConSaldoPorGrupo(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/data/reporte/documentos-cobrar-doc-con-saldo-por-grupo/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   *
   * @param date
   * @returns
   */
  obtenerDataReportePagoEfectuadoCliente(date): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api`
    )
  }

  /**
   *
   * @param date
   * @returns
   */
  obtenerDataReporteTabuladoCuentaPorCobrar(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/data/reporte/tabulado-cuenta-cobrar/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   *
   * @returns
   */
  obtenerDataReporteDinamicoDocumentoCobrar(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/data/reporte/dinamico-cuenta-x-cobrar/${this._configurationService.obtenerCompaniaCliente()}`
    )
  }

  /**
   *
   * @returns
   */
  obtenerDataReporteDinamicoMovimientosCobranza(payload: { codano: string, codmes: string, codcli: string, fecini: string, fecter: string }): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/data/reporte/dinamico-movimiento-cobranza/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  obtenerDataReporteMovimientosCobranza(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/data/reporte/movimiento-cobranza/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  obtenerDataReporteEstadoCuentaCliente(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/data/reporte/estado-cuenta-cliente/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  obtenerDataFiltroConsultaCuentaCobrar(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/data/filtro/consulta-cuentas-por-cobrar/${this._configurationService.obtenerCompaniaCliente()}`
    )
  }

  obtenerConsultaDocumentoCobranza(filtro): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/consulta-documento-cobranza/${this._configurationService.obtenerCompaniaCliente()}`,
      filtro
    )
  }

  obtenerDetalleConsultaDocumentoCobranza(codcco): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/detalle-consulta-documento-cobranza/${this._configurationService.obtenerCompaniaCliente()}/${codcco}`
    )
  }

  enviarMensajesMasivoWhatsappPorVencer(payload: { messages: Array<MensajeWhatsappCuotaPorVencer> }): Observable<any> {

    return this.http.post<any>(
      `https://pavservice.com/pavso-factura-electronica/public/api/notification/whatsapp/letras-por-vencer`,
      payload
    )

  }

  enviarMensajesMasivoWhatsappVencidas(payload: { messages: Array<MensajeWhatsappCuotaVencida> }): Observable<any> {
    return this.http.post<any>(
      `https://pavservice.com/pavso-factura-electronica/public/api/notification/whatsapp/letras-vencidas`,
      payload
    )
  }

  enviarCorreoMasivoLetrasPorVencer(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/correo/masivo/letras-por-vencer`,
      payload
    )
  }

  enviarCorreoMasivoLetrasVencidas(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/correo/masivo/letras-vencidas`,
      payload
    )
  }

  generarContenidoTenorLetrasVencidas(payload: { rucs: Array<string> }): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/genera-contenido-tenor-letras-vencidas/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }


  enviarMensajesMasivoMail(payload): Observable<any> {

    return this.http.post<any>(
      `http://localhost:3000/sendemail`,
      payload
    )

  }


}
