import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { generarInformeLiquidacionCTS } from "@utils/pdfmaker/builder/planilla.pdfmaker";
import { Subscription } from "rxjs";
import { LiquidacionCTS } from "src/app/models";
import { ConfigurationService, NominaService } from "src/app/services";

@Component({
  selector: 'detail-liquidacion-periodo-cts',
  templateUrl: './detail-liquidacion-periodo-cts.component.html',
  styleUrls: ['./detail-liquidacion-periodo-cts.component.scss']
})
export class DetailLiquidacionPeriodoCTS implements OnInit, OnDestroy {

  loaderData: boolean;
  liquidacion: LiquidacionCTS;

  colaboradores: any[];

  year: string;
  month: string;

  liquidacion$: Subscription;
  colaboradores$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DetailLiquidacionPeriodoCTS>,
    @Inject(MAT_DIALOG_DATA) public codcli: any,
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {

    this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.liquidacion = new LiquidacionCTS();

  }

  ngOnInit(): void {
    this.loadMaestros();
  }

  /**
   * Carga de prinicpales maestros del formulario
   */
  loadMaestros(): void {
    this.loaderData = true;
    this.colaboradores$ = this._nominaService.listarColaboradores().subscribe(
      colaboradores => {
        this.colaboradores = colaboradores;

        this.liquidacion$ = this._nominaService.obtenerLiquidacionCTS(`${this.year}${this.month}${this.codcli}`).subscribe(
          response => {
            this.liquidacion = response[0];
            this.loaderData = false;
          },
          error => {
            this._snackBarService.showError('Error al obtener liquidación', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  /**
   * Genera el informe de liquidación
   */
  generarInformeLiquidacion(): void {
    const headers = {
      username: this._configurationService.obtenerIdUsuario(),
    }
    console.log('LIQUIDACION', this.liquidacion);
    generarInformeLiquidacionCTS(headers, this.liquidacion)
  }

  /**
   * Cierra la ventana de diálogo
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Ciclo de vida cuando se pierde el enfoque de un componente
   */
  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.liquidacion$,
      this.colaboradores$
    ]);
  }

}
