import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { fillTable, searchInTable } from '@utils/tables/table';
import { ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-purchase-record-list',
  templateUrl: './purchase-record-list.component.html',
  styleUrls: ['./purchase-record-list.component.css']
})
export class PurchaseRecordListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'correlativo', 'tipo_documento', 'nro_documento', 'glosa', 'fecha_emision'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
      this.obtenerRegistrosDeCompra();
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  obtenerRegistrosDeCompra(): void {
    this._contabilidadService.obtenerRegistroDeCompras(this.year, this.month).subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }

}
