import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RegistroCompra } from 'src/app/models';

interface EventData {
  dpc_impsis: number;
  dpc_impfis: number;
}

@Component({
  selector: 'otros-impuestos',
  templateUrl: 'otros-impuestos.component.html'
})

export class OtrosImpuestosComponent implements OnInit, OnChanges {

  @Input() registroCompra: RegistroCompra = new RegistroCompra();

  @Input() loaderData: boolean = false;
  @Output() calcular: EventEmitter<EventData>;

  constructor() {
    this.calcular = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes otros impuestos', changes)
  }

  ngOnInit() { }

  calculaTotal() {
    this.calcular.emit({
      dpc_impsis: this.registroCompra.dpc_impsis,
      dpc_impfis: this.registroCompra.dpc_impfis
    });
  }
}
