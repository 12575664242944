export class Marca{
    CIA_CODCIA: string;
    MPR_CODMPR: string;
    MPR_DESCRI: string;
    MPR_DESABR: string;
    MPR_INDSTA: string;
    MPR_FECUPD: Date;
    MPR_CODUSE: string;
    MPR_PREfij: string;
  
    constructor() {
      this.MPR_INDSTA = "1";
      this.MPR_FECUPD = new Date();
    }
  }
  