import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { fillTable } from "@utils/tables/table";

@Component({
  selector: 'logs-requerimiento',
  templateUrl: './logs-requerimiento.dialog.html',
  styleUrls: ['./logs-requerimiento.dialog.scss']
})
export class LogsRequerimientosDialog implements OnInit, OnDestroy {

  displayedColumns: string[] = ['usuario_modificacion', 'fecha_modificacion', 'requerimiento', 'cod_solicitado', 'solicitado_por'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
