<app-pavso-title-section [title]="'Giro de negocio'" [module]="'Ventas'" [estado]="giroNegocio.gno_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Código</mat-label>
              <input type="text" [(ngModel)]="giroNegocio.gno_codgno"  matInput [toUpperOrLower]="'upper'" placeholder="Código" name="sup_codsup" required [maxlength]="3" [readonly]="uid!='0'"
                >
            </mat-form-field>
          </div>
          <div class="col s12 m12 l6"></div>

          <div class="col s12 m12 l12">
            <mat-form-field>
              <mat-label>Nombre Giro de Negocio</mat-label>
              <input type="text" [(ngModel)]="giroNegocio.gno_desesp" [maxlength]="50" matInput placeholder="Ej. Distribuidor, Lubricantes" name="sup_descri" required>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <br>
      <pav-form-actions
        [disabled]="loaderData"
        [btnName]="btnName"
        [isLoading]="loaderReg"
        urlBack="/modulo-ventas/giros-de-negocio"
      >
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
  </div>
