import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Turno } from 'src/app/models/produccion/turno';

@Injectable({
    providedIn: 'root',
  })
  export class TurnoService {
    constructor(
      private http: HttpClient,
      private _configurationService: ConfigurationService
    ) {}
/**
  /**
   *
   * @returns
   */
  obtenerTurnos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionturnos/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerTurno(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionturnos/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param turno
   * @returns
   */
  registrarTurno(turno: Turno): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionturnos/crear`,
      turno
    );
  }

  /**
   *
   * @param turno
   * @returns
   */
  actualizarTurno(turno: Turno): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionturnos/modificar`,
      turno
    );
  }
}