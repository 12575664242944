<app-pavso-title-section [title]="'Configuración remuneración'" [module]="'Planilla'" [estado]="configuracion.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Concepto</mat-label>
            <mat-select [(ngModel)]="configuracion.codnco" name="concepto">
              <mat-option *ngFor="let concepto of conceptos" [value]="concepto.codnco">
                {{concepto.descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Grupo</mat-label>
            <mat-select [(ngModel)]="configuracion.codnfc" name="grupo">
              <mat-option *ngFor="let grupo of grupos" [value]="grupo.nfc_codnfc">
                {{grupo.nfc_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>

    <div class="pav-table-header-container">
      <div class="pav-table-header-icon"></div>
      <div class="pav-table-header-title">
        Base de cálculo de los siguientes conceptos
      </div>
      <div class="pav-table-header-add">
        <div class="isMobile">
          <div class="pav-button-icon-add" (click)="agregarItemConfigura()">
            <mat-icon svgIcon="add"></mat-icon>
          </div>
        </div>
        <div class="isWeb">
          <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarItemBase()">Agregar ítem</button>
        </div>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" matSort >
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" data-label="Código">
          <div class="pav-btns-container">
            <div *ngIf="!row.isEditing" (click)="editarItemBase(row)" class="pav-btn-circle-edit" matTooltip="Editar">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
            <div *ngIf="row.isEditing" (click)="confirmarItemBase(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
              <mat-icon svgIcon="confirm"></mat-icon>
            </div>
            <div class="pav-btn-circle-delete" (click)="quitarItemBase(row)" matTooltip="Eliminar">
              <mat-icon svgIcon="delete"></mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="nrb_codnco">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Código">
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
              <mat-label></mat-label>
              <mat-select (selectionChange)="seleccionarConcepto(row)" [(ngModel)]="row.codnrb" [name]="'codnco' + i.toString()">
                <mat-option *ngFor="let concepto of conceptos" [value]="concepto.codnco">
                  {{concepto.codnco}} | {{concepto.descri}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{row.codnrb}} | {{row.desnco}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="nrb_porrem">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> % </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Importe" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                <mat-label></mat-label>
                <input class="pav-input-align-right" min="0" max="100" type="number" matInput placeholder="Porcentaje" [(ngModel)]="row.porrem"
                [name]="'importe' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.porrem}}
            </ng-container>
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3">No se encontraron registros</td>

      </tr>

    </table>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    <br>

    <div class="pav-table-header-container">
      <div class="pav-table-header-icon"></div>
      <div class="pav-table-header-title">
        Configura cuentas contable por tipo de nómina
      </div>
      <div class="pav-table-header-add">
        <div class="isMobile">
          <div class="pav-button-icon-add" (click)="agregarItemConfigura()">
            <mat-icon svgIcon="add"></mat-icon>
          </div>
        </div>
        <div class="isWeb">
          <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarItemConfigura()">Agregar ítem</button>
        </div>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource1" matSort >
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" data-label="Código">
          <div class="pav-btns-container">
            <div *ngIf="!row.isEditing" (click)="editarItemConfigura(row)" class="pav-btn-circle-edit" matTooltip="Editar">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
            <div *ngIf="row.isEditing" (click)="confirmarItemConfigura(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
              <mat-icon svgIcon="confirm"></mat-icon>
            </div>
            <div class="pav-btn-circle-delete" (click)="quitarItemConfigura(row)" matTooltip="Eliminar">
              <mat-icon svgIcon="delete"></mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="nti_codnti">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nómina </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Nómina" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
                <mat-label></mat-label>
                <mat-select (selectionChange)="seleccionarTipo(row)" [(ngModel)]="row.codnti" [name]="'codnti' + i.toString()">
                  <mat-option *ngFor="let tipo of tipos" [value]="tipo.nti_codnti">
                    {{tipo.nti_codnti}} | {{tipo.nti_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codnti}} | {{row.desnti}}
            </ng-container>
          </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codccn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Gasto </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta Gasto" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
                <mat-label></mat-label>
                <mat-select (selectionChange)="seleccionarCuenta(row)" [(ngModel)]="row.codccn" [name]="'codnco1' + i.toString()">
                  <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                    {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codccn}} | {{row.desccn}}
            </ng-container>
          </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codcco">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta x Pagar </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta x Pagar" class="td-responsive">
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
              <mat-label></mat-label>
              <mat-select (selectionChange)="seleccionarCuentaPagar(row)" [(ngModel)]="row.codcco" [name]="'codnco' + i.toString()">
                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                  {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{row.codcco}} | {{row.descco}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codliq">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Liquidación</th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Cta. Liquidación" class="td-responsive">
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
              <mat-label></mat-label>
              <mat-select (selectionChange)="seleccionarCuentaLiquidacion(row)" [(ngModel)]="row.codliq" [name]="'codliq' + i.toString()">
                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                  {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{row.codliq}} | {{row.desliq}}
          </ng-container>
         </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codtra">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Provisión</th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Cta. Provisión" class="td-responsive">
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
              <mat-label></mat-label>
              <mat-select (selectionChange)="seleccionarCuentaProvision(row)" [(ngModel)]="row.codtra" [name]="'codtra' + i.toString()">
                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                  {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{row.codtra}} | {{row.destra}}
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

      <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">No se encontraron registros</td>

      </tr>

    </table>
    <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-planilla/configuracion-remuneracion">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
