<app-pavso-title-section [title]="'Configuración enlace motivo de almacén'"
  [module]="'Almacén'" [usuario]="configureMotive.mic_coduse" [fecha]="configureMotive.mic_fecupd" [ngClass]="'pav-form'" [estado]="configureMotive.mic_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarConfiguracion(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Almacen</mat-label>
            <mat-select [(ngModel)]="configureMotive.alm_codalm" required name="alm_codalm" [disabled]="loaderData">
              <mat-option *ngFor="let almacen of almacenes" [value]="almacen.ALM_CODALM">
                {{almacen.ALM_DESCRI}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Doc.</mat-label>
            <mat-select [(ngModel)]="configureMotive.tdo_codtdo" required name="tdo_codtdo" [disabled]="loaderData">
              <mat-option value="1">
                tipo 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Motivo</mat-label>
            <mat-select [(ngModel)]="configureMotive.mmo_codmmo" required name="mmo_codmmo" [disabled]="loaderData">
              <mat-option *ngFor="let motivo of motivos" [value]="motivo.mmo_codmmo">
                {{motivo.mmo_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Orden Valorizacion</mat-label>
            <input [(ngModel)]="configureMotive.mic_ordpro" type="number" min="0" matInput placeholder="Orden"
              name="mic_ordpro" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Alm. Trans.</mat-label>
            <mat-select [(ngModel)]="configureMotive.alm_codtra" required name="alm_codtra" [disabled]="loaderData">
              <mat-option value="1">
                Alm 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl6">
          <mat-form-field>
            <mat-label>Observación</mat-label>
            <textarea [(ngModel)]="configureMotive.mic_glomic" matInput placeholder="Observación" name="mic_glomic"
              [disabled]="loaderData"></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>

    </div>
    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="tin_codtin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Inventario </th>
          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.tin_codtin}} - {{row.TIN_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="ccn_coddeb">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Debe </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.ccn_coddeb}} </td>
        </ng-container>

        <ng-container matColumnDef="ccn_codhab">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Haber </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ccn_codhab}} </td>
        </ng-container>

        <ng-container matColumnDef="ccn_coddeb2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Debe 2 </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ccn_coddeb2}} </td>
        </ng-container>

        <ng-container matColumnDef="ccn_codhab2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Haber 2</th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ccn_codhab2}} </td>
        </ng-container>

        <ng-container matColumnDef="ccn_coddeb3">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Debe 3 </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ccn_coddeb3}} </td>
        </ng-container>

        <ng-container matColumnDef="ccn_codhab3">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Haber 3 </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ccn_codhab3}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No se encontraron registros</td>
        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-almacen/configurar-enlace-motivos-almacen">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
