<mat-form-field class="pavso_input_search">
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

<div class="mat-elevation-z2">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CLIENTE </th>
            <td mat-cell *matCellDef="let row" data-label="Correo Electrónico"> {{row.cli_nomcli}} </td>
        </ng-container>

        <ng-container matColumnDef="dir_fac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DIRECCION FACTURACION </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.cli_dircli}} </td>
        </ng-container>

        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ACCIONES </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button matTooltip="Seleccionar cliente" (click)="seleccionarCliente(row)" mat-icon-button color="primary">
                    <mat-icon>check_circle_outline</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>

            <td class="mat-cell" colspan="3">No se encontraron registros "{{input.value}}"</td>

        </tr>
    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<br>
<div>
    <button mat-stroked-button color="primary" (click)="cancelar()">{{buttonName.BTN_BACK | translate}}</button>
</div>
