<app-pavso-title-section [title]="'Contacto de cliente'" [module]="'Ventas'" [ngClass]="'pav-form'"
  [estado]="'1'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <mat-card class="mat-elevation-z0">
    <div class="pavso-content-form fade-in-image">
      <div class="row">
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Código</mat-label>
            <input [(ngModel)]="contacto.ccl_codccl" [toUpperOrLower]="'upper'" maxlength="2" type="text"
              name="ccl_codccl" matInput required placeholder="Ej. 02">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Nombre</mat-label>
            <input [(ngModel)]="contacto.ccl_nomccl" name="ccl_nomccl" matInput required placeholder="Ej. Andrea">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <select-search [label]="'Cargo'" [placeholder]="'Ej. Ejemplo'" [name]="'cca_codcca'" [value]="'codnca'"
            [description]="'cca_descri'" [data]="cargos" (cambioSelect)="contacto.cca_codcca = $event"
            [model]="contacto.cca_codcca">
          </select-search>
        </div>

        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Teléfono</mat-label>
            <input [(ngModel)]="contacto.ccl_numtlf" [configText]="['numeros']" name="ccl_numtlf" type="tel" matInput
              placeholder="Ej. +51 999922222">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Correo</mat-label>
            <input [(ngModel)]="contacto.ccl_corele" name="ccl_corele" type="email" appEmailValidator
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" matInput placeholder="Ej. alfredeveloper@gmail.com">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Fecha de nacimiento</mat-label>
            <input [(ngModel)]="contacto.ccl_cumple" name="ccl_cumple" matInput [matDatepicker]="fechaNacimiento">
            <mat-datepicker-toggle matSuffix [for]="fechaNacimiento"></mat-datepicker-toggle>
            <mat-datepicker #fechaNacimiento></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l8">
          <mat-form-field class="example-full-width">
            <mat-label>Referencia</mat-label>
            <input [(ngModel)]="contacto.ccl_glosa" name="ccl_glosa" matInput placeholder="Ej. Andrea">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <mat-checkbox [(ngModel)]="contacto.ccl_indcob" name="ccl_indcob" class="example-margin">Realizar
            cobranza</mat-checkbox>
        </div>
      </div>
    </div>
  </mat-card>
  <pav-form-actions [disabled]="loaderData" [btnName]="'Guardar'" [isLoading]="loaderReg"
    urlBack="/modulo-ventas/contactos-del-cliente" (clickMasOpciones)="verMasOpciones()">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
