<app-pavso-title-section [title]="'Movimiento por concepto'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <mat-form-field class="pavso_input_search">
      <mat-label>{{LABELS_NAME.BTN_SEARCH}}</mat-label>
      <input matInput (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    &nbsp;

    <mat-form-field>
      <mat-label>Seleccionar Concepto</mat-label>
      <mat-select (selectionChange)="seleccionarConcepto($event.value)">
        <mat-option *ngFor="let concepto of conceptos; trackBy: trackByConcepto" [value]="concepto.codnco">
          {{concepto.descri}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-flat-button color="primary" type="button">Actualiza Remuneraciones</button>
    &nbsp;
    <button mat-flat-button color="primary" type="button">Carga Colaboradores</button>

    <div class="mat-elevation-z0">
      <mat-card class="mat-elevation-z0 pavso_card_more_options">
          Todos los movimientos
          <button class="pavso_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
              <button
                  mat-menu-item
                  class="pavso_more_options"
                  color="primary"
                  >
                  <mat-icon>cloud_download</mat-icon>
                  <span>Exportar a Excel</span>
              </button>

          </mat-menu>
      </mat-card>

      <table mat-table [dataSource]="dataSource" matSort >

          <ng-container matColumnDef="cli_codcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del Trabajador </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre del Trabajador"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="nmt_imprem">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
              <td mat-cell *matCellDef="let row" data-label="Importe"> {{row.nmt_imprem}} </td>
          </ng-container>

          <ng-container matColumnDef="nmt_cannut">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiempo </th>
              <td mat-cell *matCellDef="let row" data-label="Tiempo"> {{row.nmt_cannut}} </td>
          </ng-container>

          <ng-container matColumnDef="nut_codnut">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad </th>
            <td mat-cell *matCellDef="let row" data-label="Unidad"> {{row.nut_codnut}} </td>
          </ng-container>

          <ng-container matColumnDef="nmt_canhrs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Horas </th>
            <td mat-cell *matCellDef="let row" data-label="Horas"> {{row.nmt_canhrs}} </td>
          </ng-container>

          <ng-container matColumnDef="nmt_canmin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Minutos </th>
            <td mat-cell *matCellDef="let row" data-label="Minutos"> {{row.nmt_canmin}} </td>
          </ng-container>

          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
            <td mat-cell *matCellDef="let row" data-label="Valor"> {{row.valor}} </td>
          </ng-container>

          <ng-container matColumnDef="modifica">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Modifica </th>
            <td mat-cell *matCellDef="let row" data-label="Modifica"> {{row.modifica}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9">No se encontraron registros "{{input.value}}"</td>
          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

    <mat-form-field class="total">
      <mat-label>TOTAL</mat-label>
      <input [(ngModel)]="total" name="total" matInput placeholder="TOTAL">
    </mat-form-field>

</div>
