import { createReducer, on } from "@ngrx/store";
import { cargarProductos, cargarProductosError, cargarProductosSuccess } from "../actions";

export interface ProductState {
  products: any[],
  loaded: boolean,
  loading: boolean,
  error: any
}

export const initialState: ProductState = {
  products: [],
  loaded: false,
  loading: false,
  error: null
};

const _productReducer = createReducer(
  initialState,
  on(cargarProductos, state => ({...state, loading: true})),

  on(cargarProductosSuccess, (state, {products}) => ({
    ...state,
    loading: false,
    loaded: true,
    products: [...products]
  })),

  on(cargarProductosError, (state, {payload}) => ({
    ...state,
    loading: false,
    loaded: false,
    error: payload
  }))
);

export function productReducer(state, action) {
  return _productReducer(state, action)
}
