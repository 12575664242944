<app-pavso-title-section [title]="'Autorizados de aprobar solicitud de orden de compra'" [module]="'Compras'" [ngClass]="'pav-form'" [estado]="autorizado.asc_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Código</mat-label>
                      <input type="text" matInput placeholder="Código" name="cli_codcli" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6"></div>

              <div class="col s12 m12 l12">
                  <mat-form-field>
                      <mat-label>Descripción</mat-label>
                      <input type="text" matInput placeholder="Descripción" name="descripcion" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l12">
                  <mat-form-field>
                      <mat-label>Descripción Abreviado</mat-label>
                      <input type="text" matInput placeholder="Descripción Abreviado" name="descripcion_abreviado" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Cod. Usuario</mat-label>
                      <input type="text" matInput placeholder="Cod. Usuario" name="cod_usuario" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Usuario Reemplazo</mat-label>
                      <input type="text" matInput placeholder="Usuario Reemplazo" name="usuario_reemplazo" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <app-currency-select [moneda]="'SO'" [disable]="loaderData"></app-currency-select>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Monto</mat-label>
                      <input type="text" matInput placeholder="Monto" name="monto" required [disabled]="loaderData">
                  </mat-form-field>
              </div>

          </div>
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Correo</mat-label>
                      <input type="text" matInput placeholder="Correo" name="correo" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Contraseña</mat-label>
                      <input type="text" matInput placeholder="Contraseña" name="contrasenia" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
          </div>
          <div class="row">
              <div class="col s12">
                  <section class="example-section">
                      <mat-checkbox class="example-margin">Indicador aprobador</mat-checkbox>
                      &nbsp;
                      <mat-checkbox class="example-margin">Mostrar todo</mat-checkbox>
                  </section>
              </div>
          </div>
          <br>
          <div class="row">
              <div class="col s12">
                  <ngx-dropzone (change)="onSelect($event)" [accept]="'image/jpeg, image/jpg, .pdf'">
                      <ngx-dropzone-label>FOTO (formatos permitidos JPEG o JPG)</ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                          <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
                      </ngx-dropzone-preview>
                      <mat-spinner diameter="40" *ngIf="loadUpload"></mat-spinner>
                  </ngx-dropzone>
              </div>
          </div>
      </mat-card>
      <br>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
        urlBack="/modulo-compras/grupos-flujo-de-caja">
        <div id="botonesAdicionales">
        </div>
      </pav-form-actions>

    </form>
</div>
