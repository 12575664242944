<app-pavso-title-section [title]="'Consulta de movimientos cuenta corriente'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s4">
        <mat-form-field>
          <mat-label>Cta. Cte.</mat-label>
          <input matInput placeholder="Cta. Cte.">
        </mat-form-field>
      </div>
      <div class="col s4">
        <mat-form-field>
          <mat-label>Moneda</mat-label>
          <mat-select>
            <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
              {{moneda.tmo_deslar}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </mat-card>
  <br>
  <div class="mat-elevation-z0">
    <table mat-table [dataSource]="dataSource" matSort >

      <ng-container matColumnDef="ldi_messco">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
        <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo"> {{row.ldi_messco}} </td>
      </ng-container>

      <ng-container matColumnDef="ldi_corldi">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Item (Detalle) </th>
        <td mat-cell *matCellDef="let row" data-label="Item (Detalle)"> {{row.ldi_corldi}} </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codccn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.ccn_codccn}} </td>
      </ng-container>

      <ng-container matColumnDef="ldi_impdeb">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Debe </th>
        <td mat-cell *matCellDef="let row" data-label="Debe"> {{row.ldi_impdeb}} </td>
      </ng-container>

      <ng-container matColumnDef="ldi_imphab">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Haber </th>
        <td mat-cell *matCellDef="let row" data-label="Haber"> {{row.ldi_imphab}} </td>
      </ng-container>

      <ng-container matColumnDef="ldi_numdoc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
        <td mat-cell *matCellDef="let row" data-label="Documento"> {{row.ldi_numdoc}} </td>
      </ng-container>

      <ng-container matColumnDef="cco_fecemi_103">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de emisión </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha de emisión"> {{row.cco_fecemi_103}} </td>
      </ng-container>

      <ng-container matColumnDef="ldi_gloldi">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
        <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.ldi_gloldi}} </td>
      </ng-container>

      <ng-container matColumnDef="cco_codcco">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
        <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.cco_codcco}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No se encontraron registros</td>
      </tr>

    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>

<div class="row">
  <div class="col s6">
    <mat-form-field>
      <mat-label>Debe</mat-label>
      <input matInput placeholder="Debe">
    </mat-form-field>
  </div>
  <div class="col s6">
    <mat-form-field>
      <mat-label>Haber</mat-label>
      <input matInput placeholder="Haber">
    </mat-form-field>
  </div>
</div>
