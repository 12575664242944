import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'pav-button-upload-file',
  templateUrl: './pav-button-upload-file.component.html',
  styleUrls: ['./pav-button-upload-file.component.scss']
})
export class PavButtonUploadFileComponent {

  @Output() onUploadFile = new EventEmitter<{base64: string, typeFile: string}>();
  @Input() accept: string;
  isLoading: boolean = false;

  constructor() {
    this.onUploadFile = new EventEmitter<{base64: string, typeFile: string}>();
  }

  handleFileInput(event: Event): void {
    this.isLoading = true;
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (files && files.length > 0) {
      const file = files[0];
      console.log('type file', file.type) // Get the first file

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;

        // Perform actions with the Base64 string here
        console.log('Base64 encoded file:', base64String);
        this.onUploadFile.emit({base64: base64String, typeFile: file.type});
        this.isLoading = false;
      };

      // Read the file as a Data URL (base64)
      reader.readAsDataURL(file);
    } else {
      this.isLoading = false;
    }
  }

}

