<mat-toolbar color="primary" class="mat-elevation-z0">
  <h1>{{'general.sidebar.setting.title' | translate}}</h1>
  <ng-content select="button"></ng-content>
</mat-toolbar>


<div class="pav-configuration-item">
<mat-icon>settings_brightness</mat-icon>

  <span class="pav-settin-title-item">{{'general.sidebar.setting.label.theme' | translate}}:</span>

  &nbsp;
  &nbsp;
  <mat-slide-toggle (toggleChange)="cambiar()"></mat-slide-toggle>
  <mat-icon svgIcon="dark" class="pav-icon-mode" *ngIf="isDarkMode"></mat-icon>
  <mat-icon svgIcon="light" class="pav-icon-mode" *ngIf="!isDarkMode"></mat-icon>
  <span *ngIf="isDarkMode" class="pav-text-mode">{{'general.sidebar.setting.label.dark' | translate}}</span>
  <span *ngIf="!isDarkMode" class="pav-text-mode">{{'general.sidebar.setting.label.light' | translate}}</span>

</div>

<mat-menu>
  <button mat-menu-item>
    <mat-icon>dialpad</mat-icon>
    <span>Redial</span>
  </button>
  <button mat-menu-item disabled>
    <mat-icon>voicemail</mat-icon>
    <span>Check voice mail</span>
  </button>
  <button mat-menu-item>
    <mat-icon>notifications_off</mat-icon>
    <span>Disable alerts</span>
  </button>
</mat-menu>

<div class="pav-configuration-item">
  <mat-icon>notifications</mat-icon>
  <span class="pav-settin-title-item">{{'general.sidebar.setting.label.allow-notification' | translate}}</span>
</div>

<div class="pav-configuration-item">
  <mat-icon>paid</mat-icon>
  <span class="pav-settin-title-item">{{'general.sidebar.setting.label.my-subscription' | translate}}</span>
</div>

<div class="pav-configuration-item" (click)="abrirPanelAtajos()">
  <mat-icon>keyboard_outline</mat-icon>
  <span class="pav-settin-title-item">{{'general.sidebar.setting.label.keyboard-shortcut' | translate}}</span>
</div>

<div class="pav-configuration-item">
  <mat-icon>help_outline</mat-icon>
  <span class="pav-settin-title-item">{{'general.sidebar.setting.label.aid' | translate}}</span>
</div>

<div class="pav-configuration-item">
  <mat-icon>feedback_outline</mat-icon>
  <span class="pav-settin-title-item">{{'general.sidebar.setting.label.send-suggestion' | translate}}</span>
</div>

<div class="pav-configuration-item" (click)="abrirPreferenciasImpresion()">
  <mat-icon>print</mat-icon>
  <span class="pav-settin-title-item">Preferencias de impresión</span>
</div>
