<app-pavso-title-section [title]="'Inicializa parametrización del período'" [module]="'Planilla'">
</app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="mat-elevation-z0">

          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="codigo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="trabajador">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
              <td mat-cell *matCellDef="let row" data-label="Colaborador"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="um">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
              <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="importe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
              <td mat-cell *matCellDef="let row" data-label="Importe"> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">No se encontraron registros</td>
            </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col s12">
          <button mat-flat-button color="primary">{{LABELS_NAME.BTN_ACCEPT}}</button>
        </div>
      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
