<app-pavso-title-section [title]="'Vendedor'" [module]="'Ventas'" [usuario]="seller.VDE_CODUSE" [fecha]="seller.VDE_FECUPD" [ngClass]="'pav-form'" [estado]="seller.VDE_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <div class="row">
      <div class="col s12 m12 l12 xl9" [ngStyle]="{paddingLeft: '0px'}">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input type="text" maxlength="4" [(ngModel)]="seller.VDE_CODVDE" matInput placeholder="Código" name="VDE_CODVDE" required >
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Usuario</mat-label>
                <input type="text" [(ngModel)]="seller.S10_USUARIO" matInput placeholder="Usuario" name="S10_USUARIO" required >
              </mat-form-field>
            </div>

            <div class="col s12 m12 l12 xl12">
              <mat-form-field>
                <mat-label>Nombre</mat-label>
                <input type="text" [(ngModel)]="seller.VDE_NOMVDE" matInput placeholder="Nombre" name="VDE_NOMVDE" required >
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl12">
              <mat-form-field>
                <mat-label>Dirección</mat-label>
                <input type="text" [(ngModel)]="seller.VDE_DIRVDE" matInput placeholder="Dirección" name="VDE_DIRVDE" required >
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col s12 m12 l12 xl3">
        <mat-card class="mat-elevation-z0">
          <h3>Copiar Clientes</h3>
          <mat-form-field>
            <mat-label>Vendedor</mat-label>
            <mat-select required name="vendedor" >
              <mat-option *ngFor="let vendedor of vendedores" [value]="vendedor.VDE_CODVDE">
                {{vendedor.VDE_NOMVDE}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-flat-button color="primary" >Copiar</button>
        </mat-card>

      </div>
    </div>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Teléfono</mat-label>
            <input type="tel" [(ngModel)]="seller.VDE_TELVDE" matInput placeholder="Teléfono" name="VDE_TELVDE" >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input type="email" [(ngModel)]="seller.VDE_CORELE" matInput placeholder="Email" name="VDE_CORELE" >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Sectorista</mat-label>
            <mat-select required [(ngModel)]="seller.SEC_CODSEC" name="SEC_CODSEC" >
              <mat-option *ngFor="let sectorista of sectoristas" [value]="sectorista.sec_codsec">
                {{sectorista.descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Supervisor</mat-label>
            <mat-select [(ngModel)]="seller.SUP_CODSUP" required name="SUP_CODSUP">
              <mat-option *ngFor="let supervisor of supervisores" [value]="supervisor.sup_codsup">
                {{supervisor.sup_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Cod. Anterior</mat-label>
            <input [(ngModel)]="seller.VDE_CODANT" type="text" matInput placeholder="Cod. Anterior" name="VDE_CODANT">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Cod. Homologado</mat-label>
            <input type="text" matInput placeholder="Cod. Homologado" name="cod_homologado">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Segmento</mat-label>
            <mat-select [(ngModel)]="seller.VDS_CODVDS" required name="VDS_CODVDS">
              <mat-option *ngFor="let segmento of segmentos" [value]="segmento.vds_codvds">
                {{segmento.vds_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>% Comisión</mat-label>
            <input [(ngModel)]="seller.VDE_PORCOM" name="VDE_PORCOM" type="number" matInput placeholder="% Comisión" name="comision">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <mat-checkbox [(ngModel)]="seller.VDE_INDTIE" name="VDE_INDTIE">Indicador Tienda</mat-checkbox>
          &nbsp;
          <mat-checkbox >Restricción de motivo de venta</mat-checkbox>
        </div>
        <div class="col s12 m12 l12 xl4">
        </div>
      </div>
    </mat-card>
    <br>
    <div class="row">
      <div class="col s12 m12 l12 xl6">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            Tabla
          </div>
        </div>
        <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSource1" matSort>

            <ng-container matColumnDef="anio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="mes">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cuota">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuota </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_act">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Actualización </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros</td>
            </tr>

          </table>
          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>

      </div>
      <div class="col s12 m12 l12 xl6">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            Tabla
          </div>
        </div>
        <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSource2" matSort>

            <ng-container matColumnDef="dia">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Día </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cod">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="zona">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros</td>
            </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-ventas/vendedores"
    >
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
