import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlmacenService, VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';

@Component({
  selector: 'app-type-voucher',
  templateUrl: './type-voucher.component.html',
  styleUrls: ['./type-voucher.component.css']
})
export class TypeVoucherComponent implements OnInit, OnDestroy {
  type: any;
  serie: any;
  activarSerie = true;

  hoy = new Date();
  tipoDeDocumentosVenta: any = [];

  serieDoc$: Subscription;

  tipoDocVenta$: Subscription;
  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    private _ventasService: VentasService,
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private _router: Router,
    public dialogRef: MatDialogRef<TypeVoucherComponent>,
  ) { }

  ngOnInit(): void {
    this.obtenerTiposDeDocumentoVentas();
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  aceptar(f: NgForm): void {

    localStorage.setItem('voucher', JSON.stringify({typeDocument: this.type, serie: this.serie}));

    this._router.navigate(['/modulo-ventas/comprobante/0']);
    this.dialogRef.close();
  }

  obtenerTiposDeDocumentoVentas(): void {
    this.tipoDocVenta$ = this._ventasService.listarTipoDocumentosVenta().subscribe(
      response => this.tipoDeDocumentosVenta = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  SeleccionarTipo(tipo) {
    this.serieDoc$ = this._almacenService.listarSerieDocumentos(tipo).subscribe(
      response => this.serie = response[1].sdo_codsdo,
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.serieDoc$,
      this.tipoDocVenta$,
    ])

  }
}
