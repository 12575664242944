import { Component } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";

@Component({
  selector: 'upload-documents.bottomsheet',
  templateUrl: 'upload-documents.bottomsheet.html',
  styleUrls: ['upload-documents.bottomsheet.css']
})
export class UploadDocumentsBottomSheet {

  files: File[] = [];

  loadUpload: boolean = false;

  documentos: string[] = ['Documento 01', 'Documento 02', 'Documento 03', 'Documento 04', 'Documento 05', 'Documento 06', 'Documento 07'];

  constructor(private _bottomSheetRef: MatBottomSheetRef<UploadDocumentsBottomSheet>) {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  onSelect(event): void {
    this.loadUpload = true
    this.files.push(...event.addedFiles);

    this.files.forEach(element => {
      let reader = new FileReader;

      // let urlImageTemp = reader.readAsDataURL(element);

      reader.onloadend = () => {

      };
    });

    setTimeout(() => {
      this.loadUpload = false;

    }, 3000);

  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

}
