import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
  export class AgenciaTransporteService {
    constructor(
      private http: HttpClient,
      private _configurationService: ConfigurationService
    ) {}
 /**
   *
   * @returns
   */
    obtenerAgenciasTransporte(): Observable<any> {
    return this.http.get<any>(
        `${this._configurationService.obtenerUrl()}/api/clientes/listar/${this._configurationService.obtenerCompaniaCliente()}?tiposervicio=01`
    ).pipe(
      map((agenciaTransporte: any) => {
        return agenciaTransporte.map(agenciaTransporte => {
          agenciaTransporte['description'] = `${agenciaTransporte.cli_codcli} | ${agenciaTransporte.cli_nomcli}`
          return agenciaTransporte;
        })
      })
    );
    }


}