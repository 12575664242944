import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { ComprasService, ConfigurationService,  } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { LugarDespacho } from 'src/app/models/compras/lugar-despacho';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Subscription } from 'rxjs';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'lugar-despacho-form',
  templateUrl: './lugar-despacho-form.component.html',
  styleUrls: ['./lugar-despacho-form.component.css']
})
export class LugarDespachoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  lugarDespacho: LugarDespacho;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  loading$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _comprasService: ComprasService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService
  ) {
    this.lugarDespacho = new LugarDespacho();
    this.lugarDespacho.LDC_CODUSE = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=>{
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;

      if(this.uid == '0') {
        this.loaderData = false;
        return;
      }

      this.obtenerLugarDespacho();
    })
  }

  obtenerLugarDespacho(): void {
    this._comprasService.listarLugarDespacho().subscribe(
      lugares => {
        this.loaderData = false;
        this.lugarDespacho = lugares.find(item => item.LDC_CODLDC == this.uid);
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarLugarDespacho(f): this.actualizarLugarDespacho(f);
  }

  registrarLugarDespacho(f: NgForm): void {
    this.loaderReg = true;
    this._comprasService.registrarLugarDespacho(this.lugarDespacho).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Lugar de despacho registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarLugarDespacho(f: NgForm): void {
    this.loaderReg = true;
    this._comprasService.actualizarLugarDespacho(this.lugarDespacho).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Lugar de despacho actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-compras/lugares-de-despacho']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }

}
