import { DescuentoProductoSubdetalle } from "./tabla-descuento-producto-subdetalle";

export class DescuentoProductoDetalle {
    cia_codcia: string; // Código de compañía - char(2) - NO
    tbd_numtbd: string; // Número de TBD - char(10) - NO
    prd_codprd: string; // Código de producto - char(14) - NO
    tdd_indsta: string; // Indicador de estado - char(1) - NO
    tdd_coduse: string; // Código de usuario - varchar(30) - NO
    tdd_fecupd: any; // Fecha de actualización - datetime - NO
    descuentos: Array<DescuentoProductoSubdetalle>;
    isEditing: boolean;
    isSelected: boolean;
    constructor() {
        this.tdd_indsta = "1";
        this.tdd_fecupd = new Date();
        this.isEditing = true;
        this.descuentos=[];
        this.isSelected = false;
    }
}