import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fillTable } from '@utils/tables/table';

export interface IMontoDebeHaber {
  name: string;
  mn: number;
  me: number;
}

@Component({
  selector: 'total-contable',
  templateUrl: 'total-contable.component.html'
})

export class TotalContableComponent implements OnInit, OnChanges {

  @Input() montosDebeHaber: IMontoDebeHaber[] = [];

  displayedColumns: string[] = ['name', 'mn', 'me'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {
  }

  ngOnInit() {
    this.dataSource = fillTable(this.montosDebeHaber, this.paginator, this.sort);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngonchanges total debehaber', changes)
  }
}
