<h1 mat-dialog-title>{{'apps.crm.operaciones.contacto.title' | translate}}</h1>
<div mat-dialog-content>
  <div class="pav-container-detail">
    <p class="pav-contact-title">
      {{'apps.crm.operaciones.contacto.client' | translate}}:
    </p>
    <p class="pav-contact-detail">{{ data.nomcli }}</p>
  </div>
  <div class="pav-container-detail">
    <p class="pav-contact-title">
      {{'apps.crm.operaciones.contacto.code' | translate}}:
    </p>
    <p class="pav-contact-detail">{{ data.codcli }}</p>
  </div>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon"></div>
    <div class="pav-table-header-title">{{'apps.crm.operaciones.contacto.table.title' | translate}}</div>
    <div class="pav-table-header-add">
      <div class="isMobile">
        <div class="pav-button-icon-add" (click)="agregarContacto()">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <button
          [disabled]="loaderData"
          mat-flat-button
          class="pav-button-add-item"
          type="button"
          (click)="agregarContacto()"
        >
          Agregar
        </button>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z0 overflow-x">
    <table mat-table [dataSource]="dataSourceContactos" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.contacto.table.headers.actions' | translate}}
        </th>
        <td mat-cell *matCellDef="let row" data-label="Acciones">
          <div class="pav-btns-container">
            <div
              *ngIf="!row.isEditing"
              (click)="editarContacto(row)"
              class="pav-btn-circle-edit"
              matTooltip="Editar"
            >
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
            <div
              *ngIf="row.isEditing"
              (click)="confirmarContacto(row)"
              class="pav-btn-circle-confirm"
              matTooltip="Confirmar"
            >
              <mat-icon svgIcon="confirm"></mat-icon>
            </div>
            <div
              class="pav-btn-circle-delete"
              (click)="quitarContacto(row)"
              matTooltip="Eliminar"
            >
              <mat-icon svgIcon="delete"></mat-icon>
            </div>
          </div>
          <!-- <button type="button" (click)="eliminarContacto(row)" mat-icon-button color="warn">
                      <mat-icon>delete</mat-icon>
                  </button> -->
        </td>
      </ng-container>
      <ng-container matColumnDef="codigo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.contacto.table.headers.code' | translate}}</th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          data-label="Código"
        >
          {{ row.ccl_codccl + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.contacto.table.headers.name' | translate}}</th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          data-label="Nombre"
        >
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item">
              <mat-label></mat-label>
              <input
                min="0"
                required
                type="text"
                matInput
                placeholder="Nombre"
                [(ngModel)]="row.ccl_nomccl"
                [name]="'ccl_nomccl' + i.toString()"
              />
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{ row.ccl_nomccl }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="cargo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.contacto.table.headers.position' | translate}}</th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          data-label="Cargo"
        >
          <ng-container *ngIf="row.isEditing">
            <mat-form-field>
              <mat-label></mat-label>
              <mat-select
                [name]="'cca_codcca' + i"
                [(ngModel)]="row.cca_codcca"
                (selectionChange)="seleccionarCargo($event, row)"
                required
              >
                <mat-option
                  *ngFor="let cargo of cargos"
                  [value]="cargo.codnca"
                >
                  {{ cargo.descri }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="!row.isEditing">
            {{ row.cca_descri }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="telefono">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.contacto.table.headers.telephone' | translate}}
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          data-label="Teléfono"
        >
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="pav-field-telephone">
              <mat-label></mat-label>
              <input
                min="0"
                required
                type="tel"
                matInput
                placeholder="Teléfono"
                [(ngModel)]="row.ccl_numtlf"
                [name]="'ccl_numtlf' + i.toString()"
              />
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{ row.ccl_numtlf }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="correo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.contacto.table.headers.email' | translate}}</th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          data-label="Correo"
        >
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item">
              <mat-label></mat-label>
              <input
                min="0"
                required
                type="email"
                matInput
                placeholder="Correo"
                [(ngModel)]="row.ccl_corele"
                [name]="'ccl_corele' + i.toString()"
              />
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{ row.ccl_corele }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="fecha_nacimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.contacto.table.headers.date_birth' | translate}}
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          data-label="Fecha de nacimiento"
        >
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="pav-field-date-birth">
              <mat-label></mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [name]="'ccl_cumple' + i.toString()"
                [(ngModel)]="row.ccl_cumple"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{ row.ccl_cumple | date: "dd/MM/yyyy" }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="referencia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.contacto.table.headers.reference' | translate}}
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          data-label="Referencia"
        >
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item">
              <mat-label></mat-label>
              <input
                min="0"
                required
                type="text"
                matInput
                placeholder="Referencia"
                [(ngModel)]="row.ccl_glosa"
                [name]="'ccl_glosa' + i.toString()"
              />
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{ row.ccl_glosa }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="cobranza">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{'apps.crm.operaciones.contacto.table.headers.charge' | translate}}
        </th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          data-label="Cobranza"
        >
          <ng-container *ngIf="row.isEditing">
            <mat-checkbox
              class="example-margin"
              [(ngModel)]="row.ccl_indcob"
              [name]="'ccl_indcob' + i"
              >{{ row.ccl_indcob ? "SI" : "NO" }}</mat-checkbox
            >
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{ row.ccl_indcob ? "SI" : "NO" }}
          </ng-container>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsContactos"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumnsContactos"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">No se encontraron registros</td>
      </tr>
    </table>
  </div>

</div>
<mat-progress-bar mode="indeterminate" *ngIf="loaderReg"></mat-progress-bar>

<div mat-dialog-actions>
  <button mat-flat-button class="pav-button-cancel" (click)="cancelar()">{{'general.button.cancel' | translate}}</button>
  <button mat-flat-button class="pav-button-action pav-btn-action" (click)="confirmar()">{{'general.button.confirm' | translate}}</button>
</div>
