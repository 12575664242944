import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({providedIn: 'root'})
export class ModeloService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @returns
   */
  obtenerModelos(): Observable<any> {

    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablainventarios/listar/01/MODELO`)
    .pipe(
      map((modelos: any) => {
        return modelos.map(modelo => {

          modelo['description'] = `${modelo.mod_codmod} | ${modelo.mod_descri}`
          modelo['description_reverse'] = `${modelo.mod_descri} | ${modelo.mod_codmod}`
          return modelo;
        })
      })
    );

  }


}
