export class GuiaTexto {
    //no toda las descripciones del los comentarios de cada campo son correctos
    cia_codcia: string; // Código de compañía - char(2) - NO
    tdo_codtdo: string; // Código de tipo de documento - char(3) - NO
    grc_numdoc: string; // Número de documento GRC - char(14) - NO
    gtx_itegtx: string; // Item de gasto - char(3) - NO
    gtx_glogtx: string; // Descripción global gasto - varchar(200) - NO
    prd_codprd?: string; // Código de producto - char(20) - YES
    ume_codume?: string; // Código de unidad de medida - char(3) - YES
    gtx_cangtx: number; // Cantidad de gasto - numeric - NO
    gtx_pesbru: number; // Peso bruto gasto - numeric - NO
    gtx_indsta: string; // Indicador de estado gasto - char(1) - NO
    gtx_fecupd: Date; // Fecha de actualización gasto - datetime - NO
    gtx_coduse: string; // Código de usuario gasto - varchar(30) - NO
    ccs_codccs?: string; // Código de centro de costos - char(10) - YES
    constructor() {

        this.gtx_indsta = "1";
        this.gtx_fecupd = new Date();
    }
}
