<app-pavso-title-section [title]="'Certificado digital'" [module]="'Utilitarios'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarCertificado(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" matInput placeholder="Código" name="cli_codcli" required [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="text" matInput placeholder="Nombre" name="nombre" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Clave</mat-label>
            <input type="text" matInput placeholder="Clave" name="clave" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Fecha Inicio</mat-label>
            <input matInput [matDatepicker]="fechainicio">
            <mat-datepicker-toggle matSuffix [for]="fechainicio"></mat-datepicker-toggle>
            <mat-datepicker #fechainicio></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Fecha Fin</mat-label>
            <input matInput [matDatepicker]="fechafin">
            <mat-datepicker-toggle matSuffix [for]="fechafin"></mat-datepicker-toggle>
            <mat-datepicker #fechafin></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
