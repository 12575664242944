import { Component, OnInit, ViewChild, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ConfigurationService, VentasService } from 'src/app/services';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { ServiceSupplier } from 'src/app/models/compras/provider';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { ClienteContactoFormDialog } from '../dialogs/cliente-contacto-form/cliente-contacto-form.dialog';
import { ClienteEstablecimientoFormDialog } from '../dialogs/cliente-establecimiento-form/cliente-establecimiento-form.dialog';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { Cliente, DetalleTipserProv } from 'src/app/models/ventas/customer';
import { ContefactService } from 'src/app/services/api/others/contefact.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-cliente-form',
  templateUrl: './cliente-form.component.html',
  styleUrls: ['./cliente-form.component.scss'],
})
export class ClienteFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() id: string;
  @Input() search: string;
  @Input() isDialog: boolean = false;

  loaderReg: boolean = false;
  loadingMaestros: boolean = false;
  loaderBuscarRUC: boolean = false;

  cliente: Cliente;

  selected: DetalleTipserProv;

  selectRow(row: DetalleTipserProv) {
    this.selected = row;
  }

  displayedColumnsServicios: string[] = ['codigo', 'descripcion'];
  dataSourceServicios: MatTableDataSource<any>;
  @ViewChild('paginatorServicios') paginatorServicios: MatPaginator;
  @ViewChild(MatSort) sortServicios: MatSort;

  displayedColumnsContactos: string[] = ['acciones', 'codigo', 'nombre', 'cargo', 'telefono', 'correo', 'fecha_nacimiento', 'referencia', 'cobranza'];
  dataSourceContactos: MatTableDataSource<any>;
  @ViewChild('paginatorContactos') paginatorContactos: MatPaginator;
  @ViewChild(MatSort) sortContactos: MatSort;

  displayedColumnsEstablecimientos: string[] = ['acciones', 'codigo', 'principal', 'direccion', 'referencia', 'pais', 'ubicacion', 'zona'];
  dataSourceEstablecimientos: MatTableDataSource<any>;
  @ViewChild('paginatorEstablecimientos') paginatorEstablecimientos: MatPaginator;
  @ViewChild(MatSort) sortEstablecimientos: MatSort;

  vendedores: any[] = [];
  condiciones: any[] = [];
  zonas: any[] = [];
  paises: any[] = [];
  ubicaciones: any[] = [];
  giros: any[] = [];
  servicios: any[] = [];
  ubicacionesSunat: any[] = [];
  tiposDeLista: any[] = [];
  monedas: any[] = [];
  canales: any[] = [];
  tiposDePersona: any[] = [];
  tiposDeDocumento: any[] = [];
  sectoristas: any[] = [];
  productosComprobante: any[] = [];
  sucursales: any[] = [];
  cargos: any[] = [];
  clientes: any[] = [];
  serviciosTable: any[] = [];
  calificaciones: any[] = [];

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  loading$: Subscription;
  dataForm$: Subscription;
  cliente$: Subscription;
  sendForm$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string = 'general.button.store';

  uid: string;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService,
    private _clienteService: ClienteService,
    private _contefactService: ContefactService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver
  ) {

    this.cliente = new Cliente();
    this.cliente.cia_codcia = this._configurationService.obtenerCompaniaCliente();

    this.cliente.cli_coduse = this._configurationService.obtenerIdUsuario();

    this.cliente.dato_clc.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente()

    this.dataSourceServicios = fillTable([], this.paginatorServicios, this.sortServicios);

    this.dataSourceContactos = fillTable([], this.paginatorContactos, this.sortContactos);

    this.dataSourceEstablecimientos = fillTable([], this.paginatorEstablecimientos, this.sortEstablecimientos);

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }

  initialize(): void {
    this.cliente = new Cliente();
    this.cliente.cia_codcia = this._configurationService.obtenerCompaniaCliente();

    this.cliente.cli_coduse = this._configurationService.obtenerIdUsuario();

    this.cliente.dato_clc.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente()

    this.dataSourceServicios = fillTable([], this.paginatorServicios, this.sortServicios);

    this.dataSourceContactos = fillTable([], this.paginatorContactos, this.sortContactos);

    this.dataSourceEstablecimientos = fillTable([], this.paginatorEstablecimientos, this.sortEstablecimientos);

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id.currentValue && changes.id.currentValue != '0') {
      console.log('ID DESDE CLIENTE', changes.id)
      this.uid = changes.id.currentValue;
      this.cliente.cli_codcli = changes.id.currentValue;
      if( this.uid != '0' && this.uid != undefined ) this.obtenerCliente();

    }

    if(changes.search) {

      console.log('changes')
      const search = changes.search.currentValue;
      if(isNaN(parseInt(search))) return;

      if(search.length == 11) {
        this.cliente.cli_codcli = search;
        this.cliente.cli_tipper = "J";
        this.cliente.did_coddid = '001';
        this.buscarEnRENIEC()
      }

      if(search.length == 8) {
        this.cliente.cli_codcli = search;
        this.cliente.cli_tipper = "N";
        this.cliente.did_coddid = '000';
        this.buscarEnRENIEC()
      }
    }
  }

  loadData(): void {

    this.loadingMaestros = true;

    this._activatedRoute.params.subscribe(({ id }) => this.uid = id)

    this.loadMaestros();

  }

  loadMaestros(): void {

    this.dataForm$ = this._ventasService.obtenerDataFormCliente().subscribe(
      data => {
        console.log('DATA FORM', data)

        this.vendedores = data.vendedores;
        this.condiciones = data.condiciones;
        this.tiposDeLista = data.tiposPrecio;
        this.zonas = data.zonasVenta;
        this.canales = data.segmentos;
        this.tiposDePersona = data.tiposPersona;
        this.tiposDeDocumento = data.tiposDocumentoIdentidad;
        this.tiposDeDocumento.forEach(item => {
          item.DID_CODDID = item.DID_CODDID.trim();
        })
        this.paises = data.paises;
        this.monedas = data.monedas;
        this.calificaciones = data.calificaciones;
        this.ubicaciones = data.ubicaciones;
        this.ubicacionesSunat = data.ubicacionesSunat;
        this.giros = data.girosNegocio;
        this.sectoristas = data.sectoristas;
        this.productosComprobante = data.productosComprobante;
        this.servicios = data.tiposServicioProveedor;
        this.sucursales = data.sucursales;
        this.cargos = data.cargos;
        this.clientes = data.clientes;

        if (this.uid != '0' && this.uid != undefined ) {
          this.obtenerCliente();
          return;
        };

        this.loadingMaestros = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loadingMaestros = false;
      }
    )
  }

  obtenerCliente(): void {

    this.cliente$ = this._clienteService.obtenerCliente(this.uid).subscribe(
      response => {
        this.cliente = response;
        this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
        this.dataSourceEstablecimientos = fillTable(this.cliente.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
        this.dataSourceServicios = fillTable(this.cliente.detalle_tipser_prov, this.paginatorServicios, this.sortServicios);

        this.loadingMaestros = false;

        this.establecerDescripcionesMaestro();
      },
      error => {
        this._snackBarService.showError('Error al obtener cliente', 'Ok');
        this.loadingMaestros = false;
      }
    )

  }

  buscarEnRENIEC(): void {
    this.loaderBuscarRUC = true;
    console.log('this.cliente.cli_codcli', this.cliente.cli_codcli)
    if (!this.cliente.cli_codcli) {
      this._snackBarService.showError('Ingresar códiigo de búsqueda', 'Ok');
      this.loaderBuscarRUC = false;
      return;
    }

    this._contefactService.obtenerDatosRUCCONTEFACT(this.cliente.cli_codcli).subscribe(
      response => {
        console.log('response cliente', response)
        this.loaderBuscarRUC = false;

        if (response.ruc && response.ruc != '') {
          this.cliente = Cliente.fromCONEFACTToCliente(response);

          this.cliente.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          this.cliente.cli_coduse = this._configurationService.obtenerIdUsuario();

          this.cliente.pai_codpai = '01';
          const ubicacion = this.ubicaciones.find(item => item.desubi == `${response.departamento}-${response.provincia}-${response.distrito}`);
          const ubigeo = this.ubicacionesSunat.find(item => item.ubs_descri == `${response.departamento}, ${response.provincia}, ${response.distrito}`);
          console.log('ubicacion', ubicacion)
          console.log('ubigeo', ubigeo)
          this.cliente.ubi_codubi = ubicacion.codubi;
          this.cliente.ubs_codubs = ubigeo.ubs_codubs;

          this.loaderBuscarRUC = false;
        }
      },
      error => {
        this._snackBarService.showError('No se encontraron datos para este documento', 'Ok');
        this.loaderBuscarRUC = false;
      }
    )
  }

  establecerDescripcionesMaestro(): void {
    // Obteniendo descripción de servicio
    this.cliente.detalle_tipser_prov.forEach(item => {
      const servicio = this.servicios.find(it => it.TSE_CODTSE == item.tse_codtse);
      item.tse_descri = servicio.TSE_DESTSE ? servicio.TSE_DESTSE : '';
    })

    // Obteniendo descripción de cargo
    this.cliente.detalle_contacto.forEach(item => {
      const cargo = this.cargos.find(it => it.cca_codcca == item.cca_codcca);
      item.cca_desabr = cargo ? cargo.cca_desabr : '';
    })

    // Obteniendo descripción de país
    this.cliente.detalle_lugar_despacho.forEach(item => {
      const pais = this.paises.find(it => it.PAI_CODPAI == item.PAI_CODPAI);
      item.PAI_DESPAI = pais ? pais.pai_nomlar : '';
    })

    // Obteniendo descripción de ubicación
    this.cliente.detalle_lugar_despacho.forEach(item => {
      const ubicacion = this.ubicaciones.find(it => it.codubi == item.UBI_CODUBI);
      item.UBI_DESUBI = ubicacion ? ubicacion.desubi : '';
    })

    // Obteniendo descripción de zona
    this.cliente.detalle_lugar_despacho.forEach(item => {
      const zona = this.zonas.find(it => it.zve_codzve == item.ZVE_CODZVE);
      item.ZVE_DESZVE = zona ? zona.zve_nomzve : '';
    })
  }

  seleccionarPais(codpai): void {
    if (codpai != '01') this.cliente.ubi_codubi = null;
    if (codpai != '01') this.cliente.ubs_codubs = null;
  }

  validarDni(): void {
    let dniStr = this.cliente.cli_numdni.toString();

    if (dniStr.length > 8) this.cliente.cli_numdni = dniStr.substr(0, dniStr.length - 1);
  }

  validarRuc(): void {

    let rucStr = this.cliente.cli_numruc.toString();

    if (rucStr.length > 11) this.cliente.cli_numruc = rucStr.substr(0, rucStr.length - 1);

  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarCliente(f) : this.editarCliente(f);
  }

  registrarCliente(f: NgForm): void {
    console.log(this.cliente);

    this.loaderReg = true;

    this.cliente.dato_clc.CLI_CODCLI = this.cliente.cli_codcli;

    if (this.cliente.cli_tipper == "J") {

      if ((this.cliente.cli_numruc.toString())[0] != "2" || (this.cliente.cli_numruc.toString())[1] != "0") {
        this._snackBarService.showError('Establecer correctamente el RUC', 'OK');
        this.loaderReg = false;
        return;
      }

    } else {

      this.cliente.cli_nomcli = `${this.cliente.cli_apepat} ${this.cliente.cli_apemat} ${this.cliente.cli_nombre}`;

    }

    this.cliente.cli_inddom = (this.cliente.pai_codpai == "01") ? 1 : 0;

    (this.cliente.dato_clc.CLC_INOPED) ? this.cliente.dato_clc.CLC_INOPED = 1 : this.cliente.dato_clc.CLC_INOPED = 0;

    this._clienteService.registrarCliente(this.cliente).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Cliente Registrado', '350px', '', '');
        f.resetForm();
        this.cliente = new Cliente();

        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, '')
      }
    )
  }

  abrirFormulario(a) {
    if (a == 'nuevo') {
      alert('agregar  pais')
      return;
    };

    alert('editars  pais')

  }

  editarCliente(f: NgForm): void {

    console.log('editar cliente', this.cliente);
    this.sendForm$ = this._clienteService.editarCliente(this.cliente).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Cliente editado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al editar cliente', 'Ok');
        this.loaderReg = false;
      }
    )

  }

  ingresoCodigo(value): void {
    if (this.cliente.cli_tipper == "J") {
      if (this.cliente.did_coddid == "001") {
        if (value.target.value.length == 11) {
          this.cliente.cli_numruc = value.target.value;
        }
      }
      return;
    }

    this.cliente.cli_numdni = value.target.value;

  }

  seleccionarTipoDocumento(value): void {
    if (value == "001") {
      this.cliente.cli_tipper = "J";
      return;
    }
    this.cliente.cli_tipper = "N";

  }

  agregarServicio(): void {

    let item = new DetalleTipserProv();

    this.cliente.detalle_tipser_prov.push(item);

    this.cliente.detalle_tipser_prov.forEach((element, key) => {
      console.log('ids', key)
      element.correlativo = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSourceServicios = fillTable(this.cliente.detalle_tipser_prov, this.paginatorServicios, this.sortServicios);
  }

  eliminarServicio(): void {
    let row = this.cliente.detalle_tipser_prov.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.cliente.detalle_tipser_prov.length > 0) row = this.cliente.detalle_tipser_prov[0];

    let details = [];
    this.cliente.detalle_tipser_prov.forEach(element => {
      if (element.correlativo != row.correlativo) {
        details.push(element)
      }
    });

    this.cliente.detalle_tipser_prov = details;

    this.cliente.detalle_tipser_prov.forEach((element, key) => {
      console.log('ids', key)
      element.correlativo = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSourceServicios = fillTable(details, this.paginatorServicios, this.sortServicios);

    this.selected = null;
  }

  insertarServicio(): void {
    this.dataSourceServicios = fillTable([], this.paginatorServicios, this.sortServicios);

    const producto = new DetalleTipserProv();

    let i = 0;

    if (this.selected) {

      this.cliente.detalle_tipser_prov.forEach((item, index) => {
        if (item.correlativo == this.selected.correlativo) i = index;
      })

      this.cliente.detalle_tipser_prov.splice(i, 0, producto);

    } else {

      this.cliente.detalle_tipser_prov.push(producto);
    }

    this.cliente.detalle_tipser_prov.forEach((element, key) => {
      element.correlativo = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    console.log('detalle', this.cliente.detalle_tipser_prov)

    this.dataSourceServicios = fillTable(this.cliente.detalle_tipser_prov, this.paginatorServicios, this.sortServicios);
  }

  editarServicio(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarServicio(row: ServiceSupplier): void {
    if (!row.tse_codtse) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarServicio(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceServicios.data.filter(item => item.tse_codtse != row.tse_codtse);

        this.cliente.detalle_tipser_prov = filtered;

        this.dataSourceServicios = fillTable(filtered, this.paginatorServicios, this.sortServicios);
      }
    })
  }

  agregarContacto(contacto): void {

    if (this.uid == '0') {
      this._snackBarService.showError('No se puede agregar contactos a un cliente nuevo', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(ClienteContactoFormDialog, {
      data: { contacto, cargos: this.cargos, cliente: this.cliente },
      width: '750px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(({ contacto, esNuevo }) => {
      console.log('contacto', contacto)
      console.log('esNuevo', esNuevo)
      if (esNuevo) {
        const cargo = this.cargos.find(item => item.cca_codcca == contacto.cca_codcca);
        contacto.cca_desabr = cargo ? cargo.cca_descri : "";

        this.cliente.detalle_contacto.push(contacto);
        this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
        return;
      } else {
        let newData = [];
        this.dataSourceContactos.data.forEach(item => {
          if(contacto.ccl_codccl == item.ccl_codccl) {
            console.log('ingreso cod')
            console.log('item.cca_desabr', item.cca_desabr)
            console.log('contacto.cca_desabr', contacto.cca_desabr)
            item.cca_desabr = contacto.cca_desabr;
          }
          newData.push(item);
        })

        this.dataSourceContactos = fillTable(newData, this.paginatorContactos, this.sortContactos)
      }

      this.cliente.detalle_contacto.forEach(item => {
        if (item.ccl_codccl == contacto.ccl_codccl) {
          item = contacto;
        }
      })
    });

  }

  quitarContacto(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceContactos.data.filter(item => item.ccl_codccl != row.ccl_codccl);
        filtered.forEach((element, key) => {
          element['ccl_codccl'] = key;
        })
        this.cliente.detalle_contacto = filtered;
        this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
      }
    })
  }

  agregarEstablecimiento(establecimiento): void {

    if (this.uid == '0') {
      this._snackBarService.showError('No se puede agregar establecimientos a un cliente nuevo', 'Ok');
      return;
    }

    console.log('establecimiento', establecimiento)
    const dialogRef = this.dialog.open(ClienteEstablecimientoFormDialog, {
      data: { establecimiento, paises: this.paises, ubicaciones: this.ubicaciones, zonas: this.zonas, cliente: this.cliente },
      width: '750px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(({ establecimiento, esNuevo }) => {
      console.log('establecimiento', establecimiento)
      if (esNuevo) {
        const pais = this.paises.find(item => item.PAI_CODPAI == establecimiento.PAI_CODPAI);
        establecimiento.PAI_DESPAI = pais ? pais.pai_nomlar : '';
        console.log('pais', pais)
        const ubicacion = this.ubicaciones.find(item => item.codubi == establecimiento.UBI_CODUBI);
        establecimiento.UBI_DESUBI = ubicacion ? ubicacion.desubi : '';
        console.log('ubicacion', ubicacion)
        const zona = this.zonas.find(item => item.zve_codzve == establecimiento.ZVE_CODZVE);
        establecimiento.ZVE_DESZVE = zona ? zona.zve_nomzve : '';
        console.log('zona', zona)
        console.log('establecimiento', establecimiento);
        this.cliente.detalle_lugar_despacho.push(establecimiento);
        this.dataSourceEstablecimientos = fillTable(this.cliente.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
        return;
      }

      this.cliente.detalle_lugar_despacho.forEach(item => {
        if (item.LDE_CODLDE == establecimiento.LDE_CODLDE) {
          item = establecimiento;
        }
      })

    });

  }

  confirmarEstablecimiento(row): void {
    if (!row.LDE_CODLDE || !row.LDE_DIRLDE || !row.LDE_REFDIR || !row.PAI_CODPAI || !row.UBI_CODUBI || !row.ZVE_CODZVE) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarEstablecimiento(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceEstablecimientos.data.filter(item => item.LDE_CODLDE != row.LDE_CODLDE);
        filtered.forEach((element, key) => {
          element['LDE_CODLDE'] = key;
        })
        this.cliente.detalle_lugar_despacho = filtered;
        this.dataSourceEstablecimientos = fillTable(this.cliente.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
      }
    })
  }

  seleccionarServicio($event, row: ServiceSupplier): void {
    const servicio = this.servicios.find(item => item.TSE_CODTSE == $event.value);
    row.tse_descri = servicio.TSE_DESTSE;
  }

  seleccionarTipoPersona(valor): void {
    console.log('seleccionar', valor)
    if (valor == 'J') {
      this.cliente.did_coddid = '001';
      return;
    }

    this.cliente.did_coddid = '000';
  }

  verMasOpciones(): void {
    console.log('ver mas opciones ', 'Ok')
  }

  nuevoRegistro(): void {
    this.initialize();
    this.uid = '0';
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.dataForm$,
      this.cliente$,
      this.sendForm$
    ])
  }
}
