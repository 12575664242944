<h1 mat-dialog-title>Editar producto de orden de compra</h1>
<form #f="ngForm" ngNativeValidate (ngSubmit)="actualizarProducto(f)" (keydown.enter)="$event.preventDefault()">
    <div mat-dialog-content>
        <div class="row">
            <div class="col s12">
                <mat-form-field>
                    <mat-label>Código</mat-label>
                    <input matInput placeholder="Código" disabled [(ngModel)]="data.prd_codprd" name="prd_codprd">
                </mat-form-field>
            </div>
            <div class="col s12">
                <mat-form-field>
                    <mat-label>Descripción</mat-label>
                    <input matInput placeholder="Descripción" disabled [(ngModel)]="data.descripcion" name="descripcion">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Suspendida</mat-label>
                    <input type="number" matInput placeholder="Suspendida" [(ngModel)]="data.ocd_cansus" name="ocd_cansus" min="0">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Atendida</mat-label>
                    <input type="number" matInput placeholder="Atendida" [(ngModel)]="data.ocd_canate" name="ocd_canate" min="0">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Cantidad</mat-label>
                    <input type="number" matInput placeholder="Cantidad" [(ngModel)]="data.ocd_canocd" name="ocd_canocd" (keyup)="cambioImportes()" min="0">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>% Prov.</mat-label>
                    <input type="number" matInput placeholder="% Prov." [(ngModel)]="data.ocd_desprv" name="ocd_desprv" min="0">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>% Prov 2.</mat-label>
                    <input type="number" matInput placeholder="% Prov 2." [(ngModel)]="data.ocd_desprv2" name="ocd_desprv2" min="0">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Precio</mat-label>
                    <input type="number" matInput placeholder="Precio" [(ngModel)]="data.ocd_preuni" name="ocd_preuni" (keyup)="cambioImportes()" min="0" step="any">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Imp. Total</mat-label>
                    <input type="number" matInput placeholder="Imp. Total" [(ngModel)]="data.ocd_imptot" name="ocd_imptot" min="0" disabled step="any">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Mot. Compra</mat-label>
                    <input matInput placeholder="Mot. Compra" [(ngModel)]="data.ocd_motcom" name="ocd_motcom">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Nro. OT</mat-label>
                    <input matInput placeholder="Nro. OT" [(ngModel)]="data.ocd_codccs" name="ocd_codccs">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Fecha Requerida</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="data.ocd_fecreq" name="ocd_fecreq">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Peso</mat-label>
                    <input type="number" matInput placeholder="Peso" [(ngModel)]="data.ocd_pesrcd" name="ocd_pesrcd" min="0">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Prec. IGV</mat-label>
                    <input type="number" matInput placeholder="Prec. IGV" [(ngModel)]="data.ocd_pruigv" name="ocd_pruigv" (keyup)="cambioImportes()" min="0" step="any">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Valor Venta</mat-label>
                    <input type="number" matInput placeholder="Valor Venta" [(ngModel)]="data.ocd_valvta" name="ocd_valvta" disabled min="0" step="any">
                </mat-form-field>
            </div>
            <div class="col s12 m6">
                <mat-form-field>
                    <mat-label>Imp. IGV</mat-label>
                    <input type="number" matInput placeholder="Imp. IGV" [(ngModel)]="data.ocd_impigv" name="ocd_impigv" disabled min="0" step="any">
                </mat-form-field>
            </div>
            <div class="col s12">
                <mat-form-field>
                    <mat-label>Text</mat-label>
                    <textarea matInput placeholder="Text" [(ngModel)]="data.ocd_descri" name="ocd_descri"></textarea>
                </mat-form-field>
            </div>
        </div>

    </div>
    <div mat-dialog-actions>
        <button mat-flat-button (click)="cancelar()" type="button">{{buttonName.BTN_BACK | translate}}</button>
        <button mat-flat-button color="primary" type="submit" >Actualizar</button>
    </div>
</form>
