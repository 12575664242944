import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { Subscription } from "rxjs";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";
import { PavImageZoomDialog } from "../pav-image-zoom/pav-image-zoom.dialog";

@Component({
  selector: 'pav-show-product-image',
  templateUrl: './pav-show-product-image.component.html',
  styleUrls: ['./pav-show-product-image.component.scss']
})
export class PavShowProductImage implements OnChanges, OnInit {

  ngOnInit(): void {
    console.log('idProducto')
    if(this.idProducto) {
      this.obtenerImagenesProducto();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('idProducto')

    if (changes.idProducto) {
      console.log('idProducto')
      if(changes.idProducto.currentValue) {
        console.log('idProducto imagenes')
        this.obtenerImagenesProducto();
      }
    }
  }

  images: any[] = [];

  @Input() idProducto: string;

  imageSelected: any = { pri_fotprd: "https://http2.mlstatic.com/D_NQ_NP_2X_691287-MPE74342047255_022024-F.webp" };
  widthImageSelected: any;
  heightImageSelected: any;

  imagenesProducto$: Subscription;
  loadImages: boolean = false;
  isMobile: boolean = false;

  constructor(
    private _productService: ProductoService,
    private _snackBarService: SnackBarService,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog

  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
      console.log('Is Mobile:', this.isMobile);
    });
  }

  changeImage(image: string) {
    this.imageSelected = image;
  }

  establecerZoomImagen(): void {
    // imageZoom("myimage", "myresult");

    var img, lens, result, cx, cy;
    img = document.getElementById("myimage");
    result = document.getElementById("myresult");
    /*create lens:*/
    // lens = document.createElement("DIV");
    lens = document.getElementById("mylens");
    // lens.setAttribute("class", "img-zoom-lens");
    /*insert lens:*/
    img.parentElement.insertBefore(lens, img);
    /*calculate the ratio between result DIV and lens:*/
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    /*set background properties for the result DIV:*/
    // result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (this.widthImageSelected * cx) + "px " + (this.heightImageSelected * cy) + "px";
    /*execute a function when someone moves the cursor over the image, or the lens:*/
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /*and also for touch screens:*/
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);

    function moveLens(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lens:*/
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      /*prevent the lens from being positioned outside the image:*/
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }

    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

  obtenerImagenesProducto(): void {
    if (this.idProducto) {
      this.loadImages = true;
      this.imagenesProducto$ = this._productService.obtenerImagenesProducto(this.idProducto).subscribe(
        imagenes => {
          this.images = imagenes;
          this.loadImages = false;
          if (this.images.length > 0) {
            this.imageSelected = this.images[0];

            let img = new Image();
            img.src = this.imageSelected.pri_fotprd;

            // img.decode().then(() => {
            //   this.widthImageSelected = img.width;

            //   this.heightImageSelected = img.height;

            //   this.establecerZoomImagen();
            // })

            img.onload = () => {
              this.widthImageSelected = img.width;
              this.heightImageSelected = img.height;
              this.establecerZoomImagen();
            };
          }
        },
        error => {
          this._snackBarService.showError('Error al obtener imágenes de producto', 'Ok')
          this.loadImages = false;
        }
      )
    }
  }

  zoomImagen(image) {
    const dialogRef = this.dialog.open(PavImageZoomDialog, {
      width: '250px',
      data: image,
      panelClass: 'full-screen-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
