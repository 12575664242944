import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlmacenService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { NubefactService } from 'src/app/services/api/nubefact.service';
import { GuiaRemisionService } from 'src/app/services/api/almacen/operaciones/guia-remision.service';
import { IParam } from 'src/app/services/api/utilities/params';
import { SelectionModel } from '@angular/cdk/collections';
import { formatDateWithDashLocal } from '@utils/formats/date.format';

export class FiltroGuiaRemision {
  cliente: string;
  serie: string;
  desde: Date;
  hasta: Date;
  almacen: string;
  tieneFiltroFecha: boolean;

  constructor() {
    this.tieneFiltroFecha = false;
  }
}


@Component({
  selector: 'app-guia-remision-list',
  templateUrl: './guia-remision-list.component.html',
  styleUrls: ['./guia-remision-list.component.css']
})
export class GuiaRemisionListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['select', 'acciones', 'sdo_codsdo', 'grc_numdoc', 'grc_fecdoc', 'cli_nomcli', 'alm_descri', 'mac_nummac', 'grc_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsDetalle: string[] = ['estado', 'texto', 'usuario', 'actualizacion', 'archivo'];
  dataSourceDetalle: MatTableDataSource<any>;

  @ViewChild('paginatorDetalle') paginatorDetalle: MatPaginator;
  @ViewChild(MatSort) sortDetalle: MatSort;

  guiaRemision$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  clientes: any[] = [];
  series: any[] = [];
  almacenes: any[] = [];

  loaderData: boolean;

  filtro: FiltroGuiaRemision;
  clientes$: Subscription;
  almacenes$: Subscription;
  series$: Subscription;

  authenticationNubefact$: Subscription;
  periodo$: Subscription;
  establecimiento$: Subscription;

  anioPeriodo: string;
  mesPeriodo: string;

  establecimiento: string;

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  constructor(
    private readonly _almacenService: AlmacenService,
    private readonly _guiaRemisionService: GuiaRemisionService,
    private readonly store: Store<PavsoState>,
    private readonly _snackBarService: SnackBarService,
    private readonly _clienteService: ClienteService,
    private _nubefactService: NubefactService,
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceDetalle = fillTable([], this.paginatorDetalle, this.sortDetalle);
    this.filtro = new FiltroGuiaRemision();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadMaestros();
    })

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
    })
  }

  loadMaestros(): void {

    this.loaderData = true;

    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
      clientes => {

        this.clientes = clientes;
        this.obtenerGuiasRemision();

        this.series$ = this._almacenService.listarSerieDocumentos('GRE').subscribe(
          series => {
            this.series = series;
            if (this.establecimiento && this.establecimiento.trim() != '') {
              this.series = this.series.filter(item => item.tie_codtie == this.establecimiento);
            }

            this.almacenes$ = this._almacenService.obtenerAlamacenes().subscribe(
              almacenes => {
                this.almacenes = almacenes;
                if (this.establecimiento && this.establecimiento.trim() != '') {
                  this.almacenes = this.almacenes.filter(item => item.TIE_CODTIE == this.establecimiento);
                }

                this.loaderData = false;

              },
              error => {
                this._snackBarService.showError('Error al obtener almacenes', 'Ok');
                this.loaderData = false;
              }
            )

          },
          error => {
            this._snackBarService.showError('Error al obtener series', 'Ok');
            this.loaderData = false;
          }
        )

      },
      error => {
        this._snackBarService.showError('Error al obtener clientes', 'Ok');
        this.loaderData = false;
      }
    )
  }

  obtenerGuiasRemision(): void {
    this.loaderData = true;

    const params: IParam[] = [
      { param: 'anio', value: this.anioPeriodo },
      { param: 'mes', value: this.mesPeriodo },
    ]

    this.guiaRemision$ = this._guiaRemisionService.obtenerGuias({ params }).subscribe(
      response => {
        console.log('guias de remision', response)
        response.forEach(item => {
          item['cli_nomcli'] = item.cliente.cli_nomcli;
          item['alm_descri'] = item.almacen.ALM_DESCRI;
        });
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )
  }

  filtrarGuias(): void {
    this.loaderData = true;

    let params: IParam[] = [
      { param: 'anio', value: this.anioPeriodo },
      { param: 'mes', value: this.mesPeriodo },
    ]

    if (this.filtro.cliente) {
      params.push({ param: 'cliente', value: this.filtro.cliente })
    }

    if (this.filtro.serie) {
      params.push({ param: 'serie', value: this.filtro.serie })
    }

    if (this.filtro.almacen) {
      params.push({ param: 'almacen', value: this.filtro.almacen })
    }

    if (this.filtro.tieneFiltroFecha) {
      if (this.filtro.desde) {
        params.push({
          param: 'desde',
          value: formatDateWithDashLocal(this.filtro.desde)
        })
      }

      if (this.filtro.hasta) {
        params.push({
          param: 'hasta',
          value: formatDateWithDashLocal(this.filtro.hasta)
        })
      }

    }

    this.guiaRemision$ = this._guiaRemisionService.obtenerGuias({ params: params }).subscribe(
      response => {
        console.log('guias de remision', response)
        response.forEach(item => {
          item['cli_nomcli'] = item.cliente.cli_nomcli;
          item['alm_descri'] = item.almacen.ALM_DESCRI;

        });
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.guiaRemision$,
      this.loading$
    ])
  }
}
