import { NgModule } from "@angular/core";
import { UtilitariosComponent } from './utilitarios.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { cajaBancoComponents } from "./pages/caja-y-banco";
import { contaComponents } from "./pages/contabilidad";
import { SharedModule } from "@shared/shared.module";
import { MaterialModule } from "src/app/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PanelUtilitariosComponent } from "./pages/panel/panel-utilitarios.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
@NgModule({
  declarations: [
    UtilitariosComponent,
    PanelUtilitariosComponent,
    ...cajaBancoComponents,
    ...contaComponents
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class UtilitariosModule {}
