<app-pavso-title-section [title]="'Categoría de centro de costo'" [module]="'Contabilidad'" [ngClass]="'pav-form'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" matInput placeholder="Código" name="cli_codcli" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput placeholder="Descripción" name="descripcion" required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Terceros</mat-label>
            <mat-select required name="cta_terceros">
              <mat-option value="1">
                cta 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Relacionados</mat-label>
            <mat-select required name="cta_relacionados">
              <mat-option value="1">
                cta 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

    </mat-card>
    <br>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-contabilidad/categorias-de-centro-de-costo">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
