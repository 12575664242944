export class SaldoInicialConciliacion {

  cia_codcia: string;
  ano_codano: string;
  mes_codmes: string;
  ccn_codccn: string;
  scon_debnac: number;
  scon_habnac: number;
  scon_debmex: number;
  scon_habmex: number;
  con_fecupd: Date;
  con_coduse: string;
  con_indsta: string

  constructor() {
    this.con_indsta = "1";
    this.con_fecupd = new Date();
  }
}
