import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuentaCobrarService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-situacion-documento-list',
  templateUrl: './situacion-documento-list.component.html',
  styleUrls: ['./situacion-documento-list.component.css']
})
export class SituacionDocumentoListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'codigo_situcobra', 'descri_situcobra', 'estado'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  situacion$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _cuentaCobrarService: CuentaCobrarService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerSituacionesCobranza();
    })
  }

  /**
   * Obtiene un listado de situaciones de cobranza
   */
  obtenerSituacionesCobranza(): void {
    this.loaderData = true;
    this.situacion$ = this._cuentaCobrarService.listarSituacionCobranza().subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    );
  }

  /**
   * Realiza el filtro de listado de productos
   * @param event event to get value of searching
   */
  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  /**
   * Destroy any instance
   */
  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.situacion$,
      this.loading$
    ])
  }

}
