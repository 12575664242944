export class Tienda {
  cia_codcia: string;
  tie_codtie: string;
  tie_destie: string;
  tie_desabr: string;
  tie_dirtie: string;
  pai_codpai: string;
  ubi_codubi: string;
  tie_indcal: string;
  tie_preuni: number;
  tie_pruigv: number;
  tie_impbru: number;
  tie_pordes: number;
  tie_impdes: number;
  tie_valvta: number;
  tie_impigv: number;
  tmo_codtmo: string;
  sdo_codgui: string;
  sdo_codfac: string;
  mmo_codmmo: string;
  cpa_codcpa: string;
  cli_codcli: string;
  alm_codalm: string;
  tlp_codtlp: string;
  tie_indtca: number;
  tie_indtal: number;
  tie_codant: string;
  tie_indsta: string;
  tie_fecupd: Date;
  tie_coduse: string;

  constructor() {
    this.tie_indsta = "1";
    this.tie_codtie = "";
    this.tie_fecupd = new Date();
  }
}
