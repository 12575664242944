import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-inicializa-movimiento-nomina',
  templateUrl: './inicializa-movimiento-nomina.component.html',
  styleUrls: ['./inicializa-movimiento-nomina.component.css']
})
export class InicializaMovimientoNominaComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['codigo', 'trabajador', 'cantidad', 'um', 'importe', 'concepto'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tipoDoc$: Subscription;
  loading$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>
  ) {

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  /**
   * obtiene todos los maestros de :
   * . tipos de documento de identidad
   */
  loadData(): void {

    this.driver.CHO_INDSTA = "1";

    this.tipoDoc$ = this._generalService.listarTipoDocumentoIdentidad().subscribe(
      tiposDocumento => this.tiposDocumento = tiposDocumento,
      error => this._snackBarService.showError('Error al obtener tipos de documento', 'OK')
    )
  }

  registrarChofer(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoDoc$, this.loading$]);
  }

}
