
<div class="row pav-panel">
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'apps.crm.panel.headers.nro-opportunities' | translate" [amount]="nroOportunidades" [bgColorTitle]="'pav-yellow'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'apps.crm.panel.headers.open-opportunities' | translate" [amount]="oportunidadesAbie" [bgColorTitle]="'pav-green'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'apps.crm.panel.headers.missed-opportunities' | translate" [amount]="oPerdidas" [bgColorTitle]="'pav-blue-dark'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'apps.crm.panel.headers.closed-opportunities' | translate" [amount]="oportunidadesCerr" [bgColorTitle]="'pav-blue'"></card-dashboard-resume>
  </div>
</div>

<div class="row">
  <!-- Box | Estado de oportunidades -->
  <!-- <div class="col s12 m12 l3">
    <div class="pav-chart-container">
      <div class="pav-chart-header-icon"></div>
      <div class="pav-chart-header-text">Estado de oportunidades</div>
      <div class="pav-separator"></div>
      <div class="pav-chart-header-more">
      </div>
    </div>

    <mat-card class="pav-box-detail mat-elevation-z0">
      <p class="pav-box-currency">SOLES</p>
      <div class="row">
        <div class="col s4">
          <span class="pav-box-item-title">Mes</span>
        </div>
        <div class="col s4 pav-align-center">
          <span class="pav-box-item-title">Cantidad</span>
        </div>
        <div class="col s4 pav-align-right">
          <span class="pav-box-item-title">S/ Importe</span>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <br>
          <mat-divider></mat-divider>
          <span class="pav-box-item-title">Oportunidades en curso</span>
          <mat-divider></mat-divider>

        </div>
      </div>
      <br>
      <div class="row" *ngFor="let item of cuadroEstadoOportunidad.enCurso">
        <div class="col s4">{{item.month}}</div>
        <div class="col s4 pav-align-center">{{item.quantity}}</div>
        <div class="col s4 pav-align-right">{{item.amount.toFixed(2)}}</div>
      </div>

      <div class="row">
        <div class="col s12">
          <br>
          <mat-divider></mat-divider>
          <span class="pav-box-item-title">Oportunidades cerradas</span>
          <mat-divider></mat-divider>
        </div>
      </div>
      <br>

      <div class="row" *ngFor="let item of cuadroEstadoOportunidad.cerrado">
        <div class="col s4" style="font-size: 12px;">{{item.month}}</div>
        <div class="col s4 pav-align-center" style="font-size: 12px;">{{item.quantity}}</div>
        <div class="col s4 pav-align-right" style="font-size: 12px;">{{item.amount.toFixed(2)}}</div>
      </div>
    </mat-card>
  </div> -->
  <!-- Box | Estado de oportunidades end -->

  <!-- Pie | Oportunidades por línea de negocio -->
  <div class="col s12 m12 l3">
    <div class="pav-chart-container">
      <div class="pav-chart-header-icon"></div>
      <div class="pav-chart-header-text">{{'apps.crm.panel.headers.graph-4' | translate}}</div>
      <div class="pav-separator"></div>
      <div class="pav-chart-header-more">
      </div>
    </div>
    <mat-card class="mat-elevation-z0 pav-box-detail" *ngIf="activoOportunidadLineaNegocio">
      <pavso-pie-chart-simple [labels]="labelsOportunidadLineaNegocio" [series]="seriesOportunidadLineaNegocio"></pavso-pie-chart-simple>
    </mat-card>
  </div>
  <!-- Pie | Oportunidades por línea de negocio end -->

  <!-- Pie | Oportunidades por tipo de negociación -->
  <div class="col s12 m12 l3">
    <div class="pav-chart-container">
      <div class="pav-chart-header-icon"></div>
      <div class="pav-chart-header-text">{{'apps.crm.panel.headers.graph-5' | translate}}</div>
      <div class="pav-separator"></div>
      <div class="pav-chart-header-more">
      </div>
    </div>
    <mat-card class="mat-elevation-z0 pav-box-detail" *ngIf="activoOportunidadTipoNegociacion">
      <pavso-pie-chart-simple [labels]="labelsOportunidadTipoNegociacion" [series]="seriesOportunidadTipoNegociacion"></pavso-pie-chart-simple>
    </mat-card>
  </div>
  <!-- Pie | Oportunidades por tipo de negociación end -->

  <!-- Pie | Oportunidades por responsable -->
  <div class="col s12 m12 l6">
    <div class="pav-chart-container">
      <div class="pav-chart-header-icon"></div>
      <div class="pav-chart-header-text">{{'apps.crm.panel.headers.graph-7' | translate}}</div>
      <div class="pav-separator"></div>
      <div class="pav-chart-header-more">
      </div>
    </div>
    <mat-card class="mat-elevation-z0 pav-box-detail" *ngIf="activoOportunidadResponsable">
      <pavso-pie-chart-simple [labels]="labelsOportunidadResponsable" [series]="seriesOportunidadResponsable"></pavso-pie-chart-simple>
    </mat-card>
  </div>
  <!-- Pie | Oportunidades por responsable end -->
</div>
<br>
<div class="row">
  <!-- Chart | Cantidad de oportunidades por mes -->
  <div class="col s12 m12 l3">
    <div class="pav-chart-container">
      <div class="pav-chart-header-icon"></div>
      <div class="pav-chart-header-text">{{'apps.crm.panel.headers.graph-1' | translate}} </div>
      <div class="pav-separator"></div>
      <div class="pav-chart-header-more">
      </div>
    </div>
    <mat-card class="mat-elevation-z0 pav-box-detail" *ngIf="activoGraficoCantidadOpoMes">
      <pavso-bar-chart-basic [categorias]="categoriasCantidadOportunidadesMes" [data]="dataCantidadOportunidadesMes"></pavso-bar-chart-basic>
    </mat-card>
  </div>
  <!-- Chart | Cantidad de oportunidades por mes end -->

  <!-- Chart | Oportunidades en curso por agente -->
  <div class="col s12 m12 l3">
    <div class="pav-chart-container">
      <div class="pav-chart-header-icon"></div>
      <div class="pav-chart-header-text">{{'apps.crm.panel.headers.graph-3' | translate}}</div>
      <div class="pav-separator"></div>
      <div class="pav-chart-header-more">
      </div>
    </div>
    <mat-card class="mat-elevation-z0 pav-box-detail" *ngIf="activoOportunidadEnCursoAgente">
      <pavso-column-chart-basic [categorias]="categoriasOportunidadEnCursoAgente" [data]="dataOportunidadEnCursoAgente"></pavso-column-chart-basic>
    </mat-card>
  </div>
  <!-- Chart | Oportunidades en curso por agente end -->

  <!-- Chart Funnel | Oportunidades por etapa -->
  <div class="col s12 m12 l6">
    <div class="pav-chart-container">
      <div class="pav-chart-header-icon"></div>
      <div class="pav-chart-header-text">{{'apps.crm.panel.headers.graph-6' | translate}}</div>
      <div class="pav-separator"></div>
      <div class="pav-chart-header-more">
      </div>
    </div>
    <mat-card class="mat-elevation-z0 pav-box-detail">
      <div id="funnelChart"></div>

    </mat-card>
  </div>
  <!-- Chart Funnel | Oportunidades por etapa end -->
</div>

<br>

<mat-card class="mat-elevation-z0">
  <form #f="ngForm" ngNativeValidate (ngSubmit)="filtrarOportunidades(f)" (keydown.enter)="$event.preventDefault()">
    <div class="row">
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>{{'apps.crm.panel.form.start-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="desde" [(ngModel)]="filtro.desde" name="desde">
          <mat-datepicker-toggle matSuffix [for]="desde"></mat-datepicker-toggle>
          <mat-datepicker #desde></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>{{'apps.crm.panel.form.end-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="hasta" [(ngModel)]="filtro.hasta" name="hasta">
          <mat-datepicker-toggle matSuffix [for]="hasta"></mat-datepicker-toggle>
          <mat-datepicker #hasta></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.panel.form.responsible' | translate"
          [placeholder]="'Ej. '"
          [name]="'codcli'"
          [value]="'codcli'"
          [description]="'nombre'"
          [data]="responsables"
          (cambioSelect)="filtro.responsable = $event"
          [multiple]="true"
          [model]="filtro.responsable">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.panel.form.client' | translate"
          [placeholder]="'Ej. '"
          [name]="'cli_codcli'"
          [value]="'cli_codcli'"
          [description]="'cli_nomcli'"
          [data]="clientes"
          (cambioSelect)="filtro.cliente = $event"
          [multiple]="true"
          [model]="filtro.cliente">
        </select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search
          [label]="'Tipo de negociacion'"
          [placeholder]="'Ej. '"
          [name]="'codotn'"
          [value]="'codotn'"
          [description]="'descri'"
          [data]="tiposNegociacion"
          (cambioSelect)="filtro.tipoNegociacion = $event"
          [multiple]="true"
          [model]="filtro.tipoNegociacion">
        </select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.panel.form.stage' | translate"
          [placeholder]="'Ej. '"
          [name]="'codoet'"
          [value]="'codoet'"
          [description]="'descri'"
          [data]="etapas"
          (cambioSelect)="filtro.etapa = $event"
          [multiple]="true"
          [model]="filtro.etapa">
        </select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.panel.form.business-line' | translate"
          [placeholder]="'Ej. '"
          [name]="'codoln'"
          [value]="'codoln'"
          [description]="'descri'"
          [data]="lineas"
          (cambioSelect)="filtro.lineaNegocio = $event"
          [multiple]="true"
          [model]="filtro.lineaNegocio">
        </select-search>

      </div>

      <div class="col s12 m12 l2">
        <mat-slide-toggle [(ngModel)]="filtroProgramado" name="filtroProgramado">{{'apps.crm.panel.form.scheduled' | translate}}</mat-slide-toggle>
      </div>

    </div>
    <div class="row">
      <div class="col s12" align="center">
        <button mat-flat-button class="pav-button-custom" color="primary" type="button" (click)="limpiarOportunidades()">Limpiar</button>
        &nbsp;
        <button mat-flat-button class="pav-button-custom" color="primary" type="submit">Buscar</button>
      </div>

    </div>
  </form>
</mat-card>

<br>

<div class="row">
  <div class="col s12">
    <div class="pav-table-header-container">
      <div class="pav-table-header-icon">
      </div>
      <div class="pav-table-header-title">
        {{'apps.crm.panel.headers.opportunities' | translate}}
      </div>
    </div>

    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSourceOportunidades" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="pav-content-icons pav-color-blue-dark">
                  <mat-icon>manage_accounts</mat-icon>
                  <span>{{'apps.crm.panel.table.opportunities.th.actions' | translate}}</span>
                </div>
               </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

                  <div class="pav-btns-container">
                    <!-- <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-compras/condicion-de-pago', row.CPA_CODCPA]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div> -->
                    <button (click)="obtenerSeguimientos(row)" *ngIf="row.isSelected" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                      <mat-icon>radio_button_checked</mat-icon>
                    </button>
                    <button (click)="obtenerSeguimientos(row)" *ngIf="!row.isSelected" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                      <mat-icon>radio_button_unchecked</mat-icon>
                    </button>
                    <!-- <mat-icon>radio_button_checked</mat-icon> -->
                    <!-- <mat-icon>radio_button_unchecked</mat-icon> -->
                    <!-- <div class="pav-btn-circle-more" (click)="obtenerSeguimientos(row.idtkt)">
                      <mat-icon svgIcon="more"></mat-icon>
                    </div> -->
                  </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="ticket">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="pav-content-icons pav-color-blue">
                  <mat-icon>confirmation_number</mat-icon>
                  <span>{{'apps.crm.panel.table.opportunities.th.ticket' | translate}}</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.idtkt}} </td>
          </ng-container>

          <ng-container matColumnDef="dias">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="pav-content-icons pav-color-blue-light">
                  <mat-icon>event</mat-icon>
                  <span>{{'apps.crm.panel.table.opportunities.th.days' | translate}}</span>
                </div>
              </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive pav-amount-align-right"> {{row.days}} </td>
          </ng-container>

          <ng-container matColumnDef="requerimiento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-red">
                <mat-icon>border_color</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.request' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.detalle}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-blue-dark">
                <mat-icon>payments</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.amount' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsivec pav-amount-align-right"> {{row.cosopo.toFixed(2)}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-blue">
                <mat-icon>calendar_month</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.date' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.fecreg | date: 'dd/MM/yyyy': 'UTC'}} </td>
          </ng-container>

          <ng-container matColumnDef="descrioln">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-blue-light">
                <mat-icon>description</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.bussines-line' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Linea de negocio" class="td-responsive"> {{row.descrioln}} </td>
          </ng-container>

          <ng-container matColumnDef="oet_descri">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-red">
                <mat-icon>view_timeline</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.stage' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Etapa" class="td-responsive"> {{row.oet_descri}} </td>
          </ng-container>

          <ng-container matColumnDef="descriotn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-blue-dark">
                <mat-icon>business_center</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.type-negotiation' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Tipo de negociacion" class="td-responsive"> {{row.descriotn}} </td>
          </ng-container>

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-blue">
                <mat-icon>person</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.name' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-blue-light">
                <mat-icon>phone_iphone</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.phone' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.numtlfccl}} </td>
          </ng-container>

          <ng-container matColumnDef="correo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-red">
                <mat-icon>email</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.email' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.corele}} </td>
          </ng-container>

          <ng-container matColumnDef="campania">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons pav-color-blue-dark">
                <mat-icon>groups</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.campaign' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.descriocc}} </td>
          </ng-container>

          <ng-container matColumnDef="canal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="pav-content-icons">
                <mat-icon>manage_search</mat-icon>
                <span>{{'apps.crm.panel.table.opportunities.th.channel' | translate}}</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.descrioms}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsOportunidades"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsOportunidades;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="12">No se encontraron registros</td>
          </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[3, 5, 10, 25, 100]"></mat-paginator>

    <div align="end">
      <mat-form-field class="pav-total">
        <mat-label>{{'apps.crm.panel.table.opportunities.input.total' | translate}}</mat-label>
        <input matInput type="number" [placeholder]="'apps.crm.panel.table.opportunities.input.total' | translate" [value]="totalOportunidades" readonly>
      </mat-form-field>
    </div>

  </div>
</div>

<div class="row">
  <div class="col s12">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderTracings"></mat-progress-bar>

    <mat-tab-group>
      <mat-tab labelClass="mat-tab-label-0-0" [label]="'apps.crm.panel.headers.tracing' | translate">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            {{'apps.crm.panel.headers.tracing' | translate}} | Ticket: {{oportunidadSeleccionada?.idtkt ? oportunidadSeleccionada?.idtkt: 'no seleccionado'}}
          </div>
        </div>

        <div class="overflow-x">
          <table mat-table [dataSource]="dataSourceSeguimientos" matSort>

              <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.nro' | translate}} </th>
                  <td mat-cell *matCellDef="let row" data-label="#" class="td-responsive"> {{row.idset}} </td>
              </ng-container>


              <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.date' | translate}} </th>
                <td mat-cell *matCellDef="let row" data-label="Fecha" class="td-responsive"> {{row.fecreg | date: 'dd/MM/yyyy': 'UTC'}} </td>
              </ng-container>

              <ng-container matColumnDef="responsable">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.responsible' | translate}} </th>
                <td mat-cell *matCellDef="let row" data-label="Responsable" class="td-responsive"> {{row.nommbreors}} </td>
              </ng-container>

              <ng-container matColumnDef="detalle">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.tracking-detail' | translate}} </th>
                <td mat-cell *matCellDef="let row" data-label="Detalle de seguimiento" class="td-responsive"> {{row.descri}} </td>
              </ng-container>

              <ng-container matColumnDef="etapa">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.stage' | translate}} </th>
                  <td mat-cell *matCellDef="let row" data-label="Etapa" class="td-responsive"> {{row.desoet}} </td>
              </ng-container>
              <!-- <ng-container matColumnDef="situacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.situation' | translate}} </th>
                <td mat-cell *matCellDef="let row" data-label="Situación" class="td-responsive"> {{row.descrioet}} </td>
              </ng-container> -->

              <ng-container matColumnDef="modalidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.modality' | translate}} </th>
                <td mat-cell *matCellDef="let row" data-label="Modalidad" class="td-responsive"> {{row.descriomd}} </td>
              </ng-container>

              <ng-container matColumnDef="dias">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.days' | translate}} </th>
                <td mat-cell *matCellDef="let row" data-label="Días" class="td-responsive pav-amount-align-right"> {{row.days}} </td>
              </ng-container>

              <ng-container matColumnDef="prob">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.prob' | translate}} </th>
                <td mat-cell *matCellDef="let row" data-label="% Prob." class="td-responsive pav-amount-align-right"> {{row.pormar}} </td>
               </ng-container>

               <ng-container matColumnDef="actividad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.panel.table.tracing.th.activity' | translate}} </th>
                <td mat-cell *matCellDef="let row" data-label="Actiividad" class="td-responsive"> {{row.descrioas}} </td>
               </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsSeguimientos"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsSeguimientos;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11">{{'general.table.data-not-found' | translate}}</td>
              </tr>

          </table>
        </div>

        <mat-paginator #seguimientosTable showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        <div align="end">
          <mat-form-field class="pav-total">
            <mat-label>{{'apps.crm.panel.table.tracing.input.total' | translate}}</mat-label>
            <input matInput type="number" [placeholder]="'apps.crm.panel.table.tracing.input.total' | translate" [value]="totalSeguimientos" readonly>
          </mat-form-field>
        </div>

      </mat-tab>
      <mat-tab labelClass="mat-tab-label-0-1" [label]="'apps.crm.panel.headers.data-sheet' | translate">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            Ficha técnica  | Ticket: {{oportunidadSeleccionada?.idtkt ? oportunidadSeleccionada?.idtkt: 'no seleccionado'}}
          </div>
        </div>

        <div class="overflow-x">
          <table mat-table [dataSource]="dataSourceFicha" matSort >
              <!-- <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

                      <div class="pav-btns-container">
                        <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-compras/condicion-de-pago', row.CPA_CODCPA]">
                          <mat-icon svgIcon="edit-white"></mat-icon>
                        </div>
                        <div class="pav-btn-circle-more">
                          <mat-icon svgIcon="more"></mat-icon>
                        </div>
                      </div>
                  </td>
              </ng-container> -->

              <ng-container matColumnDef="secopv">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec. </th>
                  <td mat-cell *matCellDef="let row" data-label="Sec." class="td-responsive"> {{row.secopv}} </td>
              </ng-container>

              <ng-container matColumnDef="ordpod">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Orden </th>
                  <td mat-cell *matCellDef="let row" data-label="Orden" class="td-responsive"> {{row.ordpod}} </td>
              </ng-container>

              <ng-container matColumnDef="otr_descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.otr_descri}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsFicha"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsFicha;"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">{{'general.table.data-not-found' | translate}}</td>
              </tr>

          </table>
        </div>

        <mat-paginator #matPaginatorFicha showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        <div align="end">
          <mat-form-field class="pav-total">
            <mat-label>Total horas dedicadas</mat-label>
            <input matInput type="number" placeholder="Total horas dedicadas" [value]="totalHoras" readonly>
          </mat-form-field>
        </div>
      </mat-tab>
    </mat-tab-group>

    <br>
    <!-- <button mat-button (click)="exportarExcelOportunidades()" type="button">Excel oportunidades</button>
    <button mat-button (click)="exportarExcelSeguimientos()" type="button">Excel seguimientos</button> -->
  </div>
</div>
<br>
<br>
<br>
<br>
