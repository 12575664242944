export class Supervisor {
    cia_codcia: string;//código de compañía - char(2)
    sup_codsup: string;//código de supervisor - char(4)
    sup_descri: string;//descripción de supervisor - char(40),null
    sup_desabr: string;//descripción abreviada de supervisor - char(20),null
    cli_codcli: string;//código de cliente - char(12),null
    sup_indsta: string;//indicador de estado - char(1),null
    sup_coduse: string;//código de usuario - varchar(30),null
    sup_fecupd: Date;//fecha de actualización - datetime,null
    cli_nomcli: string;//nombre de cliente - varchar(100),null
  
    constructor() {
        this.sup_indsta = '1';
        this.sup_fecupd = new Date();
    }
  }
  