import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ApiTipoDocumentoIdentidadVentasService } from "src/app/services/api/ventas/maestros/tipo-documento-identidad.service";

@Component({
  selector: 'tipo-documento-identidad-list',
  templateUrl: './tipo-documento-identidad-list.component.html',
  styleUrls: ['./tipo-documento-identidad-list.component.scss']
})
export class TipoDocumentoIdentidadListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'DID_CODDID', 'DID_DESCRI', 'DID_INDSTA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  activoFijo$: Subscription;
  listaTipoDoc$: Subscription;
  loading$: Subscription;

  constructor(
    private _apiTipoDocumentoIdentidadVentas: ApiTipoDocumentoIdentidadVentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerTiposDocumentoIdentidad();
    })
  }

  obtenerTiposDocumentoIdentidad(): void {
    this.loaderData = true;
    this.listaTipoDoc$ = this._apiTipoDocumentoIdentidadVentas.obtenerTiposDocumentoIdentidad().subscribe(
      tiposDoc => {
        this.dataSource = fillTable(tiposDoc, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.listaTipoDoc$
    ])
  }
}
