<app-pavso-title-section [title]="'Tipo de documento'" [module]="'Ventas'" [usuario]="" [fecha]="" [estado]="tipoDoc.DID_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="tipoDoc.DID_CODDID" matInput placeholder="Código" name="CODDID" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="text" [(ngModel)]="tipoDoc.DID_DESCRI" matInput placeholder="Nombre" name="DESCRI" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nombre Abrev.</mat-label>
            <input type="text" [(ngModel)]="tipoDoc.DID_DESABR" matInput placeholder="Nombre Abrev." name="DESABR" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Long. Mínima</mat-label>
            <input type="text" [(ngModel)]="tipoDoc.DID_MINLEN" matInput placeholder="Long. Mínima" name="MINLEN" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Long. Máxima</mat-label>
            <input type="text" [(ngModel)]="tipoDoc.DID_MAXLEN" matInput placeholder="Long. Máxima" name="MAXLEN" required
              >
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-ventas/documentos-de-identidad">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
