<app-pavso-title-section [title]="'Proveedor'" [module]="'Compras'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Código</mat-label>
                      <input type="text" matInput placeholder="Código" name="cli_codcli" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Tipo persona</mat-label>
                      <mat-select required name="tipo_doc">
                          <mat-option *ngFor="let tipo of tiposPersona" [value]="tipo.tpe_codtpe">
                              {{tipo.tpe_descri}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Ap. Paterno</mat-label>
                      <input type="text" matInput placeholder="Ap. Paterno" name="ap_paterno" required>
                  </mat-form-field>
              </div>
          </div>
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Ap. Materno</mat-label>
                      <input type="text" matInput placeholder="Ap. Materno" name="ap_materno" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Nombres</mat-label>
                      <input type="text" matInput placeholder="Nombres" name="nombres" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6">
                  <mat-form-field>
                      <mat-label>Razon Social</mat-label>
                      <input type="text" matInput placeholder="Razon Social" name="razon_social" required>
                  </mat-form-field>
              </div>
              <div class="col s12">
                  <mat-form-field>
                      <mat-label>Nombre Comercial</mat-label>
                      <input type="text" matInput placeholder="Nombre Comercial" name="nombre_comercial" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Tipo Doc.</mat-label>
                      <mat-select required name="tipo_doc">
                          <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.DID_CODDID">
                              {{tipo.did_descri}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>RUC</mat-label>
                      <input type="text" matInput placeholder="RUC" name="ruc" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>DNI</mat-label>
                      <input type="text" matInput placeholder="DNI" name="dni" required>
                  </mat-form-field>
              </div>
              <div class="col s12">
                  <mat-form-field>
                      <mat-label>Dirección</mat-label>
                      <input type="text" matInput placeholder="Dirección" name="direccion" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Pais</mat-label>
                      <mat-select required name="pais">
                          <mat-option *ngFor="let pais of paises" value="pais.PAI_CODPAI">
                              {{pais.pai_nomlar}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Ubicación</mat-label>
                      <mat-select required name="ubicacion">
                          <mat-option *ngFor="let ubicacion of ubicaciones" value="ubicacion.ubi_codubi">
                              {{ubicacion.ubi_desubi}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Ubigeo</mat-label>
                      <mat-select required name="ubigeo">
                          <mat-option *ngFor="let ubigeo of ubigeos" [value]="ubigeo.ubs_codubs">
                              {{ubigeo.ubs_descri}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m3 l3">
                  <mat-form-field>
                      <mat-label>Registro Nacional</mat-label>
                      <input type="text" matInput placeholder="Registro Nacional" name="registro_nacional" required>
                  </mat-form-field>
              </div>
          </div>
          <div class="row">
              <div class="col s12 m12 l3">
                <app-condi-pago-select [condicionPago]="condicionPago" [disable]="loaderData" (cambioCondicionPago)="condicionPago = $event"></app-condi-pago-select>
              </div>
              <div class="col s12 m12 l6">
                  <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="1">Domiciliado</mat-radio-button>
                      &nbsp;
                      <mat-radio-button value="2">Doc. Completos</mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col s12 m3 l3">
                  <mat-form-field>
                      <mat-label>Monto</mat-label>
                      <input type="number" matInput placeholder="Monto" name="monto" required>
                  </mat-form-field>
              </div>
          </div>
      </mat-card>
      <br>
      <mat-tab-group>
          <mat-tab label="Servicios al Proveedor" labelClass="mat-tab-label-0-0">
              <mat-card class="mat-elevation-z0">
                  <div class="mat-elevation-z0">
                      <table mat-table [dataSource]="dataSource" matSort>
                          <ng-container matColumnDef="acciones">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                                  <button matTooltip="Editar" [routerLink]="['/modulo-compras/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                                      <mat-icon>edit</mat-icon>
                                  </button>
                              </td>
                          </ng-container>

                          <ng-container matColumnDef="codigo">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="descripcion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="4">No se encontraron registros</td>
                          </tr>

                      </table>

                      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
              </mat-card>
          </mat-tab>
          <mat-tab label="Contactos" labelClass="mat-tab-label-0-1">
              <mat-card class="mat-elevation-z0">
                  <div class="mat-elevation-z0">
                      <table mat-table [dataSource]="dataSourceContacto" matSort>
                          <ng-container matColumnDef="acciones">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                                  <button matTooltip="Editar" [routerLink]="['/modulo-compras/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                                      <mat-icon>edit</mat-icon>
                                  </button>
                              </td>
                          </ng-container>

                          <ng-container matColumnDef="codigo">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="colaborador">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
                              <td mat-cell *matCellDef="let row" data-label="Colaborador"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="dni">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> DNI </th>
                              <td mat-cell *matCellDef="let row" data-label="DNI"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cargo">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cargo </th>
                              <td mat-cell *matCellDef="let row" data-label="Cargo"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="correo">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
                              <td mat-cell *matCellDef="let row" data-label="Correo"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="telefono">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="celular">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Celular </th>
                              <td mat-cell *matCellDef="let row" data-label="Celular"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="web">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Web </th>
                              <td mat-cell *matCellDef="let row" data-label="Web"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="referencia">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                              <td mat-cell *matCellDef="let row" data-label="Referencia"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="estado">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsContacto"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsContacto;"></tr>

                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="4">No se encontraron registros</td>
                          </tr>

                      </table>

                      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
              </mat-card>
          </mat-tab>
          <mat-tab label="Establecimientos - Lugares de Despacho" labelClass="mat-tab-label-0-2">

              <mat-card class="mat-elevation-z0">
                  <div class="mat-elevation-z0">
                      <table mat-table [dataSource]="dataSourceEstablecimiento" matSort>
                          <ng-container matColumnDef="acciones">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                                  <button matTooltip="Editar" [routerLink]="['/modulo-compras/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                                      <mat-icon>edit</mat-icon>
                                  </button>
                              </td>
                          </ng-container>

                          <ng-container matColumnDef="codigo">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="establecimiento">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Establecimiento </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="direccion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="pais">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="ubicacion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubicación </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsEstablecimiento"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsEstablecimiento;"></tr>

                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="4">No se encontraron registros</td>
                          </tr>

                      </table>

                      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
              </mat-card>

          </mat-tab>
          <mat-tab label="Cuentas Bancarias" labelClass="mat-tab-label-0-3">
              <mat-card class="mat-elevation-z0">
                  <div class="mat-elevation-z0">
                      <table mat-table [dataSource]="dataSourceCuenta" matSort>
                          <ng-container matColumnDef="acciones">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                                  <button matTooltip="Editar" [routerLink]="['/modulo-compras/editar-chofer', row.cli_codcli]" mat-icon-button color="primary">
                                      <mat-icon>edit</mat-icon>
                                  </button>
                              </td>
                          </ng-container>

                          <ng-container matColumnDef="codigo">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="banco">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Banco </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="moneda">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="nro_cuenta">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Cuenta </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cuenta_interbancaria">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Interbancaria </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <ng-container matColumnDef="glosa">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsCuenta"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsCuenta;"></tr>

                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="4">No se encontraron registros</td>
                          </tr>

                      </table>

                      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
              </mat-card>
          </mat-tab>
      </mat-tab-group>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
