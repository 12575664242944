import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { fillTable } from '@utils/tables/table';
import { DetalleLote } from 'src/app/models';
import { AuthenticationService } from 'src/app/services';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { DialogService } from '@shared/services/dialog.service';

@Component({
  selector: 'app-movement-add-lote',
  templateUrl: './movement-add-lote.component.html',
  styleUrls: ['./movement-add-lote.component.css']
})
export class MovementAddLoteComponent implements OnInit {

  buttonName: INameConstant = NAMES_CONSTANTS;

  displayedColumns: string[] = ['acciones', 'lote', 'cantidad', 'fec_ven', 'fec_fab', 'observacion'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<MovementAddLoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<DetalleLote>,
    private _authService: AuthenticationService,
    private _dialogService: DialogService
  ) {

    this.dataSource = fillTable(data, this.paginator, this.sort);

  }

  ngOnInit(): void {
  }

  cerrar(): void {
    this.dialogRef.close(this.data);
    event.preventDefault();
  }

  agregarLote(): void {
    let lote = new DetalleLote();
    // lote.id = this.data.length + 1;
    lote.mlo_coduse = this._authService.getUsuarioSistema();
    // lote.mlo_fecupd = new Date();

    this.data.push(lote);

    this.dataSource = fillTable(this.data, this.paginator, this.sort);;
  }

  eliminarLote(id) {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {
        let lotes = [];
        this.data.forEach(element => {
          // if(element.id != id) lotes.push(element);
        })

        this.data = lotes;
      }

    })
  }

}
