<app-pavso-title-section [title]="'Interface PLAME'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">

        <!-- <input type="file" webkitdirectory directory multiple (change)="subirFolder($event)"/> -->


        <div class="row">
              <h2 align="center">PERÍODO {{year}} - {{month}}</h2>
              <div class="col s12 m12 l4"></div>
              <div class="col s12 m12 l4">
                  <mat-form-field class="example-full-width">
                      <mat-label>Ubicación de Archivo</mat-label>
                      <textarea disabled matInput placeholder="Ubicación de Archivo"></textarea>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l4"></div>
          </div>
          <div class="row">
            <div class="col s12 m12 l3">
              <h1>Remuneraciones colaborador</h1>
              <div>
                <button [class.spinner]="loaderRem" mat-stroked-button type="button" (click)="guardarArchivoRem()" color="primary">Generar archivo .rem</button>
              </div>
            </div>
            <div class="col s12 m12 l3">
              <h1>Jornada laboral del colaborador</h1>
              <div>
                <button [class.spinner]="loaderJor" mat-stroked-button type="button" (click)="guardarArchivoJor()" color="primary">Generar archivo .jor</button>
              </div>
            </div>
            <div class="col s12 m12 l3">
              <h1>Días subsidiados y otros no laborados</h1>
              <div>
                <button [class.spinner]="loaderSnl" mat-stroked-button type="button" (click)="guardarArchivoSnl()" color="primary">Generar archivo .snl</button>
              </div>
            </div>
            <div class="col s12 m12 l3">
              <h1>Seguro vida ley</h1>
              <div>
                <button [class.spinner]="loaderToc" mat-stroked-button type="button" (click)="guardarArchivoToc()" color="primary">Generar archivo .toc</button>
              </div>
            </div>
          </div>

      </mat-card>

    </form>
</div>
