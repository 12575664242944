import { Directive, ElementRef, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appEmailValidator]'
})
export class EmailValidatorDirective {
  private emailRegex: RegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  constructor(private el: ElementRef, @Self() private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initalValue.toLowerCase();
    this.ngControl.control.setValue(this.el.nativeElement.value);
    if (!this.emailRegex.test(this.el.nativeElement.value)) {
      this.el.nativeElement.setCustomValidity('Invalid email format');
    } else {
      this.el.nativeElement.setCustomValidity('');
    }
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
