<app-pavso-title-section
  [title]="'apps.crm.operaciones.orden-pedido.title' | translate"
  [module]="'CRM'"
></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input
        type="text"
        (keyup)="applyFilter($event)"
        [placeholder]="'general.placeholder.search' | translate"
        #input
      />
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action
      [title]="'apps.crm.operaciones.orden-pedido.title' | translate"
      url="/modulo-crm/orden-pedido"
      entity=""
      [params]="['0']"
    ></table-title-action>

    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.orden-pedido.table.header.actions' | translate}}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [style.color]="row.color"
            data-label="Acciones"
          >
            <div class="pav-btns-container">
              <a
                class="pav-btn-circle-edit"
                [routerLink]="[
                  '/modulo-crm/orden-pedido',
                  row.numpcc
                ]"
              >
                <mat-icon svgIcon="edit-white"></mat-icon>
              </a>
              <div
              (click)="generarPDF(row.numpcc)"
                class="pav-btn-circle-edit"
              >
                <mat-icon svgIcon="pdf"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="idtkt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'apps.crm.operaciones.orden-pedido.table.header.nro-opportunity' | translate}}
          </th>
          <td mat-cell *matCellDef="let row" data-label="Código">
            {{ row.idtkt }}
          </td>
        </ng-container>

        <ng-container matColumnDef="numpcc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.orden-pedido.table.header.id' | translate}}</th>
          <td mat-cell *matCellDef="let row" data-label="ID">
            {{ row.numpcc }}
          </td>
        </ng-container>

        <ng-container matColumnDef="fecdoc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.orden-pedido.table.header.date' | translate}}</th>
          <td mat-cell *matCellDef="let row" data-label="Etapa">
            {{ row.fecdoc | date: 'dd/MM/yyyy': 'UTC'}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">
            {{'general.table.data-not-found' | translate}} "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>

    <mat-paginator
      showFirstLastButtons
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
  </div>
</div>
