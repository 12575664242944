import { Component, OnDestroy } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { cambiarIdioma } from "@shared/state/actions/language.actions";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ConfigurationService } from "src/app/services";

@Component({
  selector: 'pav-language',
  templateUrl: './pav-language.component.html',
  styleUrls: ['./pav-language.component.scss']
})
export class PavLanguageComponent implements OnDestroy{

  icon: 'espaniol' | 'united-states';
  title: string;
  iconInactive: 'espaniol' | 'united-states';
  titleInactive: string;

  isSpanish: boolean = true;

  language$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService
  ) {
    const idioma = this._configurationService.obtenerIdioma();

    if(idioma == 'ES') {
      this.isSpanish = true;
      this.icon = "espaniol";
      this.title = "general.label.language.spanish";
      this.iconInactive = "united-states";
      this.titleInactive = "general.label.language.english";
      this.store.dispatch(cambiarIdioma({language: 'ES'}));
      this._configurationService.establecerIdioma('ES');
    } else {
      this.isSpanish = false;
      this.icon = "united-states";
      this.title = "general.label.language.english";
      this.iconInactive = "espaniol";
      this.titleInactive = "general.label.language.spanish";
      this.store.dispatch(cambiarIdioma({language: 'EN'}));
      this._configurationService.establecerIdioma('EN');
    }

  }

  cambiarIdioma(): void {
    this.isSpanish = !this.isSpanish;

    if(this.isSpanish) {
      this.icon = "espaniol";
      this.title = "general.label.language.spanish";
      this.iconInactive = "united-states";
      this.titleInactive = "general.label.language.english";
      this.store.dispatch(cambiarIdioma({language: 'ES'}));
      this._configurationService.establecerIdioma('ES');
    } else {
      this.icon = "united-states";
      this.title = "general.label.language.english";
      this.iconInactive = "espaniol";
      this.titleInactive = "general.label.language.spanish";
      this.store.dispatch(cambiarIdioma({language: 'EN'}));
      this._configurationService.establecerIdioma('EN');
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.language$])
  }
}
