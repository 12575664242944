import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'consulta-stock',
  templateUrl: './consulta-stock.component.html',
  styleUrls: ['./consulta-stock.component.scss']
})
export class ConsultaStockComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
