<app-pavso-title-section [title]="'Otros ingresos afectos a renta de 5ta.'" [module]="'Planilla'"></app-pavso-title-section>
<!-- <app-pavso-title-section [title]="'Ingresos y retenciones de otras empresas'" [module]="'Planilla'"></app-pavso-title-section> -->

<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Año</mat-label>
          <input [(ngModel)]="otrosIngresos.codano" type="text" min="0" matInput placeholder="Año" name="year" required readonly>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Mes</mat-label>
          <input [(ngModel)]="otrosIngresos.codmes" type="text" min="0" matInput placeholder="Año" name="month" required readonly="">
        </mat-form-field>
      </div>

    </div>
  </mat-card>
  <br>
  <mat-card class="mat-elevation-z4 pavso-bottom-actions" align="end">
    <button [class.spinner]="loaderReg" class="pav-button-action" mat-flat-button color="primary" type="submit"
      >{{buttonsName.BTN_STORE}}</button>
  </mat-card>

  <br>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">

    </div>
    <div class="pav-table-header-title">
      Otros ingresos
    </div>
    <div class="pav-table-header-add">
      <div class="isMobile">
        <div class="pav-button-icon-add" (click)="agregarItem()">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <button type="button" mat-flat-button class="pav-button-add-item" (click)="agregarItem()">Agregar ítem</button>
      </div>
    </div>
  </div>
  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarOtrosIngresosAfectoRenta5ta(f)"
    (keydown.enter)="$event.preventDefault()">

    <div class="mat-elevation-z0">

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" data-label="Acciones" class="td-responsive">
            <div class="pav-btns-container">
              <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
              <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                <mat-icon svgIcon="confirm"></mat-icon>
              </div>
              <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="codcli">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Colaborador" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <select-search
                [label]="''"
                [placeholder]="'Ej. '"
                [name]="'codcli'"
                [value]="'codcli'"
                [description]="'nomcli'"
                [data]="colaboradores"
                (cambioSelect)="row.codcli = $event"
                [multiple]="true"
                [model]="row.codcli">
              </select-search>

            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codcli}} | {{row.nomcli}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="imprqd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ingreso afecto de 5ta. cat. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Importe" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                <mat-label></mat-label>
                <input class="pav-input-align-right" required type="number" min="0" matInput placeholder="Importe" [(ngModel)]="row.imprqd"
                [name]="'imprqd' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.imprqd}}
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No se encontraron registros</td>
        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

    <br>
    <br>
    <br>
    <br>
    <br>
    <mat-card class="mat-elevation-z4 pavso-bottom-actions" align="end">
      <button class="pav-button-action" [class.spinner]="loaderReg" mat-flat-button color="primary" type="submit">{{btnName | translate}}</button>
    </mat-card>
  </form>
</div>
