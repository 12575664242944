import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component, DoCheck, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { GuiaRemision } from "src/app/models/almacen/guia-remision";
import { GuiaTexto } from "src/app/models/almacen/guia-texto";
import { ConfigurationService, ContabilidadService, AlmacenService as ModuloAlmacenService, VentasService } from "src/app/services";
import { UnidadMedidaService } from "src/app/services/api/almacen/configuracion/unidad-medida.service";
import { LugarDespachoCIAService } from "src/app/services/api/almacen/maestros/lugar-despacho-cia.service";
import { GuiaTextoService } from "src/app/services/api/almacen/operaciones/guia-texto.service";
import { ProveedorService } from "src/app/services/api/compras/maestros/proveedor.service";
import { AgenciaTransporteService } from "src/app/services/api/produccion/maestros/agencia-transporte.service";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";
import { SerieDocumentoService } from "src/app/services/api/ventas/maestros/serie-documento.service";
@Component({
  selector: 'app-guia-remision-form',
  templateUrl: './guia-remision-form.component.html',
  styleUrls: ['./guia-remision-form.component.css']
})
export class GuiaRemisionFormComponent implements OnInit, OnDestroy, DoCheck {

  selected: any;

  selectRow(row: any) {
    this.selected = row;
  }

  pesoBruto: number
  loaderReg = false
  loaderData = false;

  estoyEditando: boolean;

  hoy: Date;
  fechaMaxima: Date;

  guia_correlativo: string;
  viewport_numdoc: boolean;

  guiaRemision: GuiaRemision;
  uid: string;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  /* Productos VAR */

  displayedColumnsProductos: string[] = ['gtx_itegtx', 'prd_codprd', 'gtx_glogtx', 'ume_codume', 'gtx_cangtx', 'gtx_pesbru']
  dataSourceProductos: MatTableDataSource<GuiaTexto>;

  @ViewChild('paginatorServicios') paginatorServicios: MatPaginator;
  @ViewChild(MatSort) sortServicios: MatSort;


  proveedores: Array<any> = [];
  productos: Array<any> = [];
  series: Array<any> = [];
  unidadesMedida: Array<any> = [];
  tiposDocumento: Array<any> = [];
  motivosMovimiento: Array<any> = [];

  loading$: Subscription;
  sendForm$: Subscription;
  transportistas: any[] = [];
  unidadesTransporte: any[] = [];
  lugaresDespacho: any[] = [];
  lugaresDespachoCIA: any[] = [];
  choferes: any[] = [];
  agenciaTransportes: any[] = [];

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _proveedorService: ProveedorService,
    private _productoService: ProductoService,
    private _serieDocumento: SerieDocumentoService,
    private _guiaTextoService: GuiaTextoService,
    private _lugarDespachoCiaService: LugarDespachoCIAService,
    private _agenciaTransporte: AgenciaTransporteService,
    private _router: Router,
    private _contabilidadService: ContabilidadService,
    private _configurationService: ConfigurationService,
    private _moduloAlmacenService: ModuloAlmacenService,
    private _clienteService: ClienteService,
    private _unidadMedidaService: UnidadMedidaService,
    private breakpointObserver: BreakpointObserver,
  ) {

    this.guiaRemision = new GuiaRemision();
    this.initialize()
  }

  initialize(): void {
    this.guiaRemision.texto_gtx = [];
    this.guiaRemision.tdo_codtdo = 'GRE';
    this.guiaRemision.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.guiaRemision.grc_coduse = this._configurationService.obtenerIdUsuario();
    this.dataSourceProductos = fillTable([], this.paginatorServicios, this.sortServicios);
  }

  ngDoCheck(): void {
    this.totalPesoBruto();
  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    })
    this.breakpointObserver
      .observe(['(min-width: 500px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          console.log('El ancho del viewport es igual o mayor a 500px!');
          this.viewport_numdoc = true;
        } else {
          console.log('El ancho del viewport es menor a 500px!', state.breakpoints['(min-width: 500px)']);
          this.viewport_numdoc = false;
        }
      });
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.uid = params.id;
        this.estoyEditando = this.uid !== '0' ? true : false;
        !this.estoyEditando ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;
        this.loadMaestros();
      }
    })
  }

  tiposDocumento$: Subscription;
  proveedores$: Subscription;
  motivosMovimiento$: Subscription;
  productos$: Subscription;
  series$: Subscription;
  unidades$: Subscription;
  transportistas$: Subscription;
  unidadesTransporte$: Subscription;
  choferes$: Subscription;
  agenciasTransporte$: Subscription;
  lugaresDespachoCia$: Subscription;
  guiaTexto$: Subscription;

  loadMaestros(): void {

    this.tiposDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
      tiposDocumento => {
        this.tiposDocumento = tiposDocumento.filter(item => item.TDO_INDSTA === '1' && item.tdo_indalm === 1);

        this.proveedores$ = this._proveedorService.obtenerProveedores().subscribe(
          proveedores => {
            this.proveedores = proveedores.filter(proveedor => proveedor.cli_indsta === '1');

            this.motivosMovimiento$ = this._ventasService.obtenerMotivosMovimiento().subscribe(
              movimientos => {
                this.motivosMovimiento = movimientos.filter(motivo => motivo.mmo_indsta === '1');

                this.productos$ = this._productoService.ListarProductos().subscribe(
                  productos => {
                    this.productos = productos;
                    console.log('productos', productos)

                    this.series$ = this._serieDocumento.obtenerSeriesDocumento().subscribe(
                      series => {
                        this.series = series;

                        this.unidades$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                          unidades => {
                            this.unidadesMedida = unidades;

                            this.transportistas$ = this._moduloAlmacenService.obtenerTransportistas().subscribe(
                              transportistas => {
                                this.transportistas = transportistas;

                                this.unidadesTransporte$ = this._ventasService.listarUnidadesTransporte().subscribe(
                                  unidadesTransporte => {
                                    this.unidadesTransporte = unidadesTransporte;

                                    this.choferes$ = this._moduloAlmacenService.listarChoferes().subscribe(
                                      choferes => {
                                        this.choferes = choferes;

                                        this.agenciasTransporte$ = this._clienteService.listarTipoServicioCliente().subscribe(
                                          servicios => {
                                            console.log('servicios', servicios)
                                            this.agenciaTransportes = servicios;

                                            this.lugaresDespachoCia$ = this._lugarDespachoCiaService.obtenerLugaresDespachoCIA().subscribe(
                                              lugaresDespachoCia => {
                                                this.lugaresDespachoCIA = lugaresDespachoCia;
                                                console.log('lugares despacho cia', lugaresDespachoCia)

                                                if(this.uid != '0') {
                                                  this.obtenerGuiaTexto();
                                                  return;
                                                }

                                                this.loaderData = false;
                                              },
                                              error => {
                                                this._snackBarService.showError(error.error.message, 'Ok');
                                                this.loaderData = false;
                                              }
                                            )

                                          },
                                          error => {
                                            this._snackBarService.showError(error.error.message, 'Ok');
                                            this.loaderData = false;
                                          }
                                        )
                                      },
                                      error => {
                                        this._snackBarService.showError(error.error.message, 'Ok');
                                        this.loaderData = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError(error.error.message, 'Ok');
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError(error.error.message, 'Ok');
                                this.loaderData = false;
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError(error.error.message, 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError(error.error.message, 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError(error.error.message, 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError(error.error.message, 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.message, 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
        this.loaderData = false;
      }
    )

  }

  obtenerGuiaTexto(): void {
    this.guiaTexto$ = this._guiaTextoService.mostrarGuiaTexto(this.uid).subscribe(
      guia => {
        this.guiaRemision = guia;
        this.estoyEditando ? this.seteoGuia(guia) : this.setFechaMinMax();
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
        this.loaderData = false;
      }
    )
  }

  liberarGuia(): void {}

  seteoGuia(guia: GuiaRemision): void {
    this.guiaRemision = guia;
    console.log("guiatexto", this.guiaRemision)
    this.guia_correlativo = this.guiaRemision.grc_numdoc.substring(4);

    if (this.guiaRemision.utr_codutr) {
      this.setUnidadTransporte();
    }
    if (this.guiaRemision.cho_codcho) {
      this.setChofer();
    }

    this.actualizarTablaProductos();
  }


  setUnidadTransporte() {
    let unidadTransporte = this.unidadesTransporte.find(unidad => unidad.UTR_CODUTR === this.guiaRemision.utr_codutr);

    this.guiaRemision.grc_undtra = unidadTransporte.UTR_DESCRI;
    this.guiaRemision.grc_plaund = unidadTransporte.UTR_PLAUTR;
    this.guiaRemision.grc_marund = unidadTransporte.UTR_MARUTR;
    this.guiaRemision.grc_nrocer = unidadTransporte.UTR_NROCER;
    this.guiaRemision.cho_codcho = unidadTransporte.CHO_CODCHO;


  }
  setChofer() {
    let chofer = this.choferes.find(chofer => chofer.CHO_CODCHO === this.guiaRemision.cho_codcho);
    this.guiaRemision.grc_nomcho = chofer.CHO_NOMBRE;
    this.guiaRemision.grc_nrolic = chofer.CHO_NROLIC.trim();
  }



  setFechaMinMax(): void {
    this.hoy = new Date();
    this.fechaMaxima = new Date();
    this.fechaMaxima.setDate(this.hoy.getDate() + 2);
  }




  /* -------------------------------------------LOGICA DE LA TABLA------------------------------------------- */
  actualizarTablaProductos() {
    this.actualizarItemCorrelativo();
    this.dataSourceProductos = fillTable(this.guiaRemision.texto_gtx, this.paginatorServicios, this.sortServicios);
  }

  actualizarItemCorrelativo() {
    if (this.guiaRemision.texto_gtx == null) {
      this.guiaRemision.texto_gtx = [];
      return;
    }
    for (let index = 0; index < this.guiaRemision.texto_gtx.length; index++) {
      const element = this.guiaRemision.texto_gtx[index];
      element.gtx_itegtx = (index + 1).toString().padStart(3, '0');
    }
  }

  onChangeSeleccionarProducto(row: GuiaTexto) {
    row.ume_codume = this.productos.find(producto => producto.prd_codprd === row.prd_codprd).ume_codume;//seteo la unidad de medida
  }
  totalPesoBruto() {
    this.pesoBruto = 0;
    this.guiaRemision.texto_gtx.forEach(element => {
      element.gtx_pesbru != null ? this.pesoBruto += element.gtx_pesbru : 0;
    });
  }

  agregarProducto() {
    let item = new GuiaTexto();
    item.cia_codcia = this.guiaRemision.cia_codcia;
    item.gtx_coduse = this.guiaRemision.grc_coduse;
    item.tdo_codtdo = this.guiaRemision.tdo_codtdo;
    item.grc_numdoc = this.guiaRemision.grc_numdoc;
    item.gtx_itegtx = (this.dataSourceProductos.data.length + 1).toString().padStart(3, '0');

    this.guiaRemision.texto_gtx.push(item);
    this.actualizarTablaProductos();

  }

  quitarProducto() {
    const index = 0;
    this.guiaRemision.texto_gtx.splice(index, 1);
    this.actualizarTablaProductos();
  }

  seleccionarSerie(): void {
    this._guiaTextoService.obtenerCorrelativoGuiaDoc(this.guiaRemision.tdo_codtdo, this.guiaRemision.sdo_codsdo).subscribe(
      response => {
        console.log('correlativo', response)
        this.guiaRemision.grc_numdoc = response.grc_numdoc;
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
        this.loaderData = false;
      }
    )
  }

  seleccionarCliente(): void {
    this._ventasService.obtenerLugaresDespacho(this.guiaRemision.cli_codcli).subscribe(
      lugaresDespacho => {
        this.lugaresDespacho = lugaresDespacho;
        console.log('lugaresDespacho', this.lugaresDespacho);
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
        this.loaderData = false;
      }
    )
  }

  insertarProducto() { }
  /*--------------------------------------------------------------------------------------------------------- */

  onChangeProveedor(cli_codcli: string): void {
    console.log("entre")
    //este método ira dentro de la respuesta de obtenerGuiaTexto
    this.guiaRemision.cli_numruc = this.proveedores.find(proveedor => proveedor.cli_codcli === cli_codcli).cli_numruc;
    if (this.guiaRemision.cli_numruc === '') {
      this.guiaRemision.cli_numruc = 'SIN RUC'
    }
  }

  esValidoFormulario(): boolean {

    if(!this.guiaRemision.sdo_codsdo) {
      this._snackBarService.showError('Seleccione una serie', 'Ok');
      return false;
    }

    if(this.guiaRemision.grc_numdoc) {
      if(this.guiaRemision.grc_numdoc.length != 14) {
        this._snackBarService.showError('Establecer 14 dígitos', 'Ok');
        return false;
      }
    }

    if(!this.guiaRemision.cli_codcli) {
      this._snackBarService.showError('Seleccione un proveedor', 'Ok');
      return false;
    }

    if(!this.guiaRemision.mmo_codmmo) {
      this._snackBarService.showError('Seleccione un motivo de movimiento', 'Ok');
      return false;
    }

    if(!this.guiaRemision.cli_codtra) {
      this._snackBarService.showError('Seleccione un transportista', 'Ok');
      return false;
    }

    if(!this.guiaRemision.grc_undtra) {
      this._snackBarService.showError('Seleccione una unidad de transporte', 'Ok');
      return false;
    }

    if(!this.guiaRemision.cho_codcho) {
      this._snackBarService.showError('Seleccione un chofer', 'Ok');
      return false;
    }

    if(this.guiaRemision.texto_gtx.length == 0) {
      this._snackBarService.showError('Agregue al menos un producto', 'Ok');
      return false;
    }

    if(!this.guiaRemision.ldc_codldc) {
      this._snackBarService.showError('Seleccione una dirección de partida', 'Ok');
      return false;
    }

    if(!this.guiaRemision.lde_codlde) {
      this._snackBarService.showError('Seleccione una dirección de llegada', 'Ok');
      return false;
    }


    return true;
  }

  nuevoRegistro(): void {
    this.uid = '0';
    this.initialize();
  }

  enviarFormulario(f: NgForm): void {

    if(!this.esValidoFormulario()) {
      this.loaderReg = false;
      return;
    }

    this.loaderReg = true;
    this.uid == '0' ? this.registrarGuiaTexto() : this.actualizarGuiaTexto();
  }

  registrarGuiaTexto(): void {
    this.sendForm$ = this._guiaTextoService.registrarGuiaTexto(this.guiaRemision).subscribe(
      guiaTexto => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, `Guía de text registrado`, '400px', '', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError('Error al registrar guia de texto', 'Ok');
      }
    )
  }

  actualizarGuiaTexto(): void {
    this.sendForm$ = this._guiaTextoService.actualizarGuiaTexto(this.guiaRemision).subscribe(
      guiaTexto => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, `Guía de text registrado`, '400px', '', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError('Error al registrar guia de texto', 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.tiposDocumento$,
      this.proveedores$,
      this.motivosMovimiento$,
      this.productos$,
      this.series$,
      this.unidades$,
      this.transportistas$,
      this.unidadesTransporte$,
      this.choferes$,
      this.agenciasTransporte$,
      this.lugaresDespachoCia$,
      this.guiaTexto$,
      this.sendForm$
    ]);

  }

}
