<app-pavso-title-section [title]="'Supervisor'" [module]="'Ventas'" [usuario]="" [fecha]="" [ngClass]="'pav-form'" [estado]="supervisor.sup_indsta"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Código</mat-label>
              <input type="text" [(ngModel)]="supervisor.sup_codsup" [maxlength]="4" matInput placeholder="Código" name="sup_codsup" required [readonly]="uid!='0'"
                >
            </mat-form-field>
          </div>
          <div class="col s12 m12 l6"></div>

          <div class="col s12 m12 l12">
            <mat-form-field>
              <mat-label>Nombre</mat-label>
              <input type="text" [(ngModel)]="supervisor.sup_descri" [maxlength]="40" matInput placeholder="Ej. OFICINA LIMA" name="sup_descri" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12">
            <mat-form-field>
              <mat-label>Nombre Abreviado</mat-label>
              <input type="text" [(ngModel)]="supervisor.sup_desabr" [maxlength]="20" matInput placeholder="Ej. OF. LIMA" name="sup_desabr" required>
            </mat-form-field>
          </div>
          <!-- estos campos son de la tabla cliente, pero tal vez el form no es el indicado, ver foto de notion tarea_id #1, debe ser la descripcion del cliente parte inferior izquierda-->
          <div class="col s12 m12 l12">
            <select-search
            [label]="'Auxiliar'"
            [placeholder]="'Ej. Juan Ibañez'"
            [name]="'cli_codcli'"
            [description]="'cli_nomcli'"
            [data]="auxiliares"
            (cambioSelect)="supervisor.cli_codcli = $event"
            [value]="supervisor.cli_codcli">
          </select-search>
          </div>
        </div>
      </mat-card>
      <br>
      <pav-form-actions
        [disabled]="loaderData"
        [btnName]="btnName"
        [isLoading]="loaderReg"
        urlBack="/modulo-ventas/supervisores"
      >
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
