<h1 mat-dialog-title>Filtrar comprobante</h1>

<form #f="ngForm" ngNativeValidate (ngSubmit)="aceptar(f)" (keydown.enter)="$event.preventDefault()">
    <div mat-dialog-content>
        <p>Tipos de comprobante</p>

        <mat-radio-group aria-label="Select an option" [(ngModel)]="type" name="type" required>
            <div *ngFor="let tipo of tipoDeDocumentosVenta">
                <mat-radio-button [value]="tipo.tdo_codtdo" (click)="SeleccionarTipo(tipo.tdo_codtdo)">{{tipo.tdo_deslar}}</mat-radio-button>
                <br>
            </div>
        </mat-radio-group>
        <br>
        <div class="row serie">
            <div class="col s6 p-l">
                <mat-checkbox class="example-margin" [(ngModel)]="activarSerie" name="activar">
                    <mat-form-field class="example-full-width">
                        <mat-label>Serie</mat-label>
                        <input [(ngModel)]="serie" matInput placeholder="Serie" name="serie" [disabled]="!activarSerie">
                    </mat-form-field>
                </mat-checkbox>
            </div>
            <div class="col s6">
            </div>
        </div>
        <section class="example-section">
            <mat-checkbox class="example-margin">Solo documentos del pedido {{hoy | date: 'yyyy/MM'}}</mat-checkbox>
        </section>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button color="default" cdkFocusInitial (click)="cancelar()" type="button">{{buttonName.BTN_BACK | translate}}</button>
        <button mat-flat-button color="primary" cdkFocusInitial type="submit">{{buttonName.BTN_ACCEPT}}</button>
    </div>
</form>
