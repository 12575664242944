import { Component, OnDestroy, OnInit } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";

@Component({
  selector: 'situacion-activo-list',
  templateUrl: './situacion-activo-list.component.html',
  styleUrls: ['./situacion-activo-list.component.scss']
})
export class SituacionActivoListComponent implements OnInit, OnDestroy {

  loading$: Subscription;

  constructor(
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([this.loading$])
  }

}
