export class Pais {
  PAI_CODPAI: string;
  PAI_NOMLAR: string;
  PAI_NOMCOR: string;
  PAI_NOMING: string;
  PAI_INDSTA: string;
  PAI_CODUSE: string;
  PAI_FECUPD: Date;
  pai_codant: string;
  PAI_CODISO: string;
  T35_CODT35: string;

  constructor() {
    this.PAI_FECUPD = new Date();
  }
}
