import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MaterialModule } from "src/app/material.module";
import { AlmacenComponent } from "./almacen.component";
import { cierreComponentAlmacen } from "./pages/cierre";
import { configuracionComponentAlmacen } from "./pages/configuracion";
import { informesComponentAlmacen } from "./pages/informes";
import { maestrosComponentAlmacen } from "./pages/maestros";
import { operacionesComponentAlmacen } from "./pages/operaciones";
import { consultasComponentAlmacen } from "./pages/consultas";
import { PanelAlmacenComponent } from "./pages/panel/panel-almacen.component";
import { procesosComponentAlmacen } from "./pages/procesos";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { tablasApoyoComponentAlmacen } from "./pages/tablas-apoyo";
import { NgApexchartsModule } from "ng-apexcharts";
import { monitoreosComponentAlmacen } from "./pages/monitoreo";
import { componentsAdmin } from "../../components";
import { GeneralComponentsModule } from "../../components/general-components.module";


@NgModule({
  declarations: [
    AlmacenComponent,
    PanelAlmacenComponent,
    ...informesComponentAlmacen,
    ...maestrosComponentAlmacen,
    ...operacionesComponentAlmacen,
    ...procesosComponentAlmacen,
    ...configuracionComponentAlmacen,
    ...consultasComponentAlmacen,
    ...cierreComponentAlmacen,
    ...tablasApoyoComponentAlmacen,
    ...monitoreosComponentAlmacen,
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    GeneralComponentsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
})
export class AlmacenModule {}
