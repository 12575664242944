import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'card-dashboard-resume',
  templateUrl: './card-dashboard-resume.component.html',
  styleUrls: ['./card-dashboard-resume.component.scss']
})
export class CardDashboardResumeComponent implements OnInit {
  @Input() title: string;
  @Input() amount: string | number;
  @Input() bgColorTitle: 'pav-yellow' | 'pav-blue-dark' | 'pav-blue' | 'pav-orange' | 'pav-green' | 'pav-primary' | 'pav-secondary' | 'pav-alternative';
  color: string;

  constructor() {
    this.color = '#FFFFFF'
  }

  ngOnInit(): void {
    this.establecerColor();
  }

  establecerColor() {
    switch (this.bgColorTitle) {
      case 'pav-yellow':
        this.color = '#F5C643';
        break;
      case 'pav-green':
        this.color = '#63C99A';
        break;
      case 'pav-blue':
        this.color = '#2360FF';
        break;
      case 'pav-blue-dark':
        this.color = '#00205F';
        break;
      case 'pav-orange':
        this.color = '#EB6945';
        break;
      case 'pav-primary':
        this.color = '#00205F';
        break;
      case 'pav-secondary':
        this.color = '#006bfd';
        break;
      case 'pav-alternative':
        this.color = '#6cd6df';
        break;
      default:
        break;
    }
  }
}
