export class TerminoCompra {
  trc_codtrc: string;
  trc_desesp: string;
  trc_deseng: string;
  trc_indsta: string;
  trc_fecupd: Date;
  trc_coduse: string;

  constructor() {
    this.trc_indsta = '1';
    this.trc_fecupd = new Date();
  }
}
