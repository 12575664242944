<h1 mat-dialog-title>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.title' | translate}} | Ticket {{data.idtkt}}</h1>

<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

<form #f="ngForm" ngNativeValidate (ngSubmit)="registrar(f)" (keydown.enter)="$event.preventDefault()">
  <div mat-dialog-content>
    <br>
    <div class="row">
          <div class="col s12 m12 l4">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.opportunity' | translate}}</mat-label>
                  <input matInput placeholder="Ticket" [ngModel]="data.idtkt" name="idtkt" readonly>
              </mat-form-field>
          </div>

          <div class="col s12 m12 l4">
              <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.nro' | translate}}</mat-label>
              <input matInput placeholder="Nro." readonly>
              </mat-form-field>
          </div>

          <div class="col s12 m12 l4">
            <app-state-select [state]="seguimiento.indsta" (cambioEstado)="seguimiento.indsta = $event;" [disable]="loaderData"></app-state-select>
          </div>
          <div class="col s12 m12 l4">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.date' | translate}}</mat-label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]="seguimiento.fecreg" name="codoet" [disabled]="loaderData">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l8">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.responsible' | translate}}</mat-label>
                  <mat-select required name="responsable" [disabled]="loaderData" [(ngModel)]="seguimiento.idres">
                      <mat-option *ngFor="let responsable of responsables" [value]="responsable.idres">
                          {{responsable.nombre}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l6">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.stage' | translate}}</mat-label>
                  <mat-select required name="etapa" [disabled]="loaderData" [(ngModel)]="seguimiento.codoet">
                      <mat-option *ngFor="let etapa of etapas" [value]="etapa.codoet">
                          {{etapa.descri}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l6">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.scheduled_date' | translate}}</mat-label>
                  <input matInput [matDatepicker]="fechaprogramada" [(ngModel)]="seguimiento.fecpro" name="fecpro" [disabled]="loaderData">
                  <mat-datepicker-toggle matSuffix [for]="fechaprogramada"></mat-datepicker-toggle>
                  <mat-datepicker #fechaprogramada></mat-datepicker>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l12">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.description' | translate}}</mat-label>
                  <textarea matInput placeholder="Descripción" [(ngModel)]="seguimiento.descri" name="descri" [disabled]="loaderData"></textarea>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l6">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.situation' | translate}}</mat-label>
                  <mat-select required name="situacion" [(ngModel)]="seguimiento.idstk" [disabled]="loaderData">
                      <mat-option *ngFor="let situacion of situaciones" [value]="situacion.idstk">
                          {{situacion.descri}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l6">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.oportunidad.dialog.nueva-etapa.reason_sale_suspended' | translate}}</mat-label>
                  <mat-select required name="motivo_vta" [(ngModel)]="seguimiento.codosv" [disabled]="loaderData">
                      <mat-option *ngFor="let motivo of motivos" [value]="motivo.codosv">
                          {{motivo.descri}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
    </div>

  </div>
  <div mat-dialog-actions>
    <button mat-flat-button class="pav-button-cancel" (click)="cancelar()" type="button">{{'general.button.cancel' | translate}}</button>
    <button mat-flat-button class="pav-button-action" cdkFocusInitial [class.spinner]="loaderReg" type="submit" color="primary">{{'general.button.store' | translate}}</button>
  </div>
</form>
