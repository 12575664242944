import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { IlumiProductListComponent } from '../ilumi-product-list/ilumi-product-list.component';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';

export class Discount {
  dco_coddco: any;
  dco_desdco: any;
  discount: any;
  amount: any;
  constructor(
  ) {}
}


@Component({
  selector: 'app-ilumi-product-discount',
  templateUrl: './ilumi-product-discount.component.html',
  styleUrls: ['./ilumi-product-discount.component.css']
})
export class IlumiProductDiscountComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['cod_desc', 'desc', 'porc_desc', 'importe_desc', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  descuentos: any = [];

  descuento: Discount;

  descuentosDeProducto: any = [];

  descuento$: Subscription;

  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<IlumiProductListComponent>,

  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.descuento = new Discount();
  }

  ngOnInit(): void {
    this.obtenerDescuentos()
  }

  obtenerDescuentos(): void {
    this.descuento$ = this._ventasService.obtenerDescuentos().subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  agregarDescuento(f: NgForm) {
    this.descuentosDeProducto.push(this.descuento)
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  eliminarDescuento(row): void {}

  aceptar(): void {

  }

  cancelar(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.descuento$
    ])
  }

}
