import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { formatDateWithDash } from '@utils/formats/date.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { VentasService } from 'src/app/services';

class Paso02 {
  fecha: Date;
  compra: number;
  venta: number;
  difCambioPerdida: number;
  difCambioGanancia: number;
  trasPerdida: number;
  trasGanancia: number;
  eliminar: boolean;

  constructor() {
    const year = new Date().getUTCFullYear();
    const month = new Date().getUTCMonth();
    this.fecha = new Date(year, month + 1, 0)
  }
}

class Paso03 {
  fecha: Date;
  compra: number;
  venta: number;
  difCambioPerdida: number;
  difCambioGanancia: number;
  trasPerdida: number;
  trasGanancia: number;
  eliminar: boolean;

  constructor() {
    const year = new Date().getUTCFullYear();
    const month = new Date().getUTCMonth();
    this.fecha = new Date(year, month + 1, 0)
  }
}

@Component({
  selector: 'app-proceso-general-contabilidad',
  templateUrl: './proceso-general-contabilidad.component.html',
  styleUrls: ['./proceso-general-contabilidad.component.scss']
})
export class ProcesoGeneralContabilidadComponent implements OnInit, OnDestroy {

  loading$: Subscription;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  paso02: Paso02 = new Paso02();
  paso03: Paso03 = new Paso03();

  tipoCambio$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _ventasService: VentasService,
  ) { }



  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.obtenerTipoCambio();
  }

  obtenerTipoCambio(): void {
    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.paso02.fecha)).subscribe(
      tipoCambio => {
        console.log('tipo cambio', tipoCambio)
        this.paso02.compra = tipoCambio[0].tipcam;
        this.paso02.venta = tipoCambio[0].tipcam;
      },
      error => {
        console.log('error', error)
      }
    )
  }

  generar01(): void {
    this.tabGroup.selectedIndex = 1;
  }

  generar02(): void {
    this.tabGroup.selectedIndex = 2;
  }

  generar03(): void {
    this.tabGroup.selectedIndex = 0;
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.loading$])
  }

}
