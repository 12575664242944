<div class="pav-table-search-container">
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" placeholder="Buscar" #input>
  </div>
</div>

<div class="mat-elevation-z0">
  <table-title-action title="Todos los proveedores" url="/modulo-compras/proveedor" entity=""
    [params]="['0']"></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <div class="overflow-x">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-compras/proveedor', row.cli_codcli]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="CODIGO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.cli_codcli}} </td>
      </ng-container>

      <ng-container matColumnDef="NOMBRE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
        <td mat-cell *matCellDef="let row" data-label="Proveedor" class="td-responsive"> {{row.cli_nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="TELEFONO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_numtlf}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>
      </tr>

    </table>

  </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
