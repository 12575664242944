import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { UnidadMedida } from "src/app/models/almacen/unidad_medida";
import { AlmacenService } from "src/app/services";

@Component({
  selector: 'unidad-medida-form',
  templateUrl: './unidad-medida-form.component.html',
  styleUrls: ['./unidad-medida-form.component.scss']
})
export class UnidadMedidaFormComponent implements OnInit, OnDestroy {

  loaderData: boolean = false;
  loaderReg: boolean = false;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  unidad$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  unidadMedida: UnidadMedida;

  uid: string;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _dialogService: DialogService
  ){
    this.unidadMedida = new UnidadMedida();
  }

  ngOnInit(): void {

    this._activatedRoute.params.subscribe(
      params => {
        this.uid = params.id;
      }
    )

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        if(id == '0') return;

        this.unidad$ = this._almacenService.listarUnidadesDeMedida().subscribe(
          unidades => {

            const unidad = unidades.find(item => item.ume_codume == id);
            this.unidadMedida = unidad;

          }
        )

      }
    )
  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;

    this.uid == '0' ? this.registrarUnidadMedida(f) : this.actualizarUnidadMedida(f);

  }

  registrarUnidadMedida(f: NgForm): void {
    this.sendForm$ = this._almacenService.registrarUnidadMedida(this.unidadMedida).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Unidad de medida registrada', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarUnidadMedida(f: NgForm): void {
    this.sendForm$ = this._almacenService.actualizarUnidadMedida(this.unidadMedida).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Unidad de medida actualizada', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-almacen/unidades-de-medida']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.unidad$
    ])
  }
}
