import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, NgForm } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import moment, { Moment } from "moment";
import { Subscription } from "rxjs";
import { DetalleSerie } from "src/app/models";
import { ConfiguracionTecnica } from "src/app/models/almacen/inventario/configuracion-tecnica";
import { AlmacenService } from "src/app/services";
import { KardexService } from "src/app/services/api/almacen/operaciones/kardex.service";
import { PedidoService } from "src/app/services/api/ventas/operaciones/pedido.service";
import { AlmacenService as ModuloAlmacenService } from "src/app/services";
import { IParam } from "src/app/services/api/utilities/params";

@Component({
  selector: 'consultar-documentos-despacho',
  templateUrl: './consultar-documentos-despacho.component.html',
  styleUrls: ['./consultar-documentos-despacho.component.scss']
})
export class ConsultarDocumentosDespachoComponent implements OnInit, OnDestroy {

  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment): void {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['guia_nro', 'fecha_despacho', 'almacen', 'nro_parte', 'nro_pedido', 'ruc', 'factura', 'fecha', 'transportista', 'fecha_entrega'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['producto', 'descripcion', 'cantidad'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  displayedColumns2: string[] = ['lote', 'cantidad', 'fec_fabricacion', 'fec_vencimiento'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  displayedColumnsKardex: string[] = ['movim_almacen', 'almacen', 'fecha', 'cantidad', 'und', 'ot', 'motivo', 'desc_motivo'];
  dataSourceKardex: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorKardex: MatPaginator;
  @ViewChild(MatSort) sortKardex: MatSort;

  displayedColumnsSerie: string[] = ['serie', 'fec_fabric', 'fec_vcto'];
  dataSourceSerie: MatTableDataSource<any>;

  @ViewChild("paginatorSerie") paginatorSerie: MatPaginator;
  @ViewChild("sortSerie") sortSerie: MatSort;

  selected: any;

  selectedSerie: DetalleSerie;

  selectRowSerie(row: any) {
    this.selectedSerie = row;
  }

  loaderKardex: boolean = false;

  pedidos: any[] = [];
  productos: any[] = [];
  lineas: any[] = [];
  almacenes: any[] = [];
  familias: any[] = [];
  marcas: any[] = [];
  transportistas: any[] = [];
  unidadesTransporte: any[] = [];

  producto$: Subscription;
  almacen$: Subscription;
  familia$: Subscription;
  linea$: Subscription;
  marca$: Subscription;
  unidad$: Subscription;
  loading$: Subscription;
  dataFiltro$: Subscription;
  configuracionTecnica$: Subscription;
  period$: Subscription;
  pedidos$: Subscription;
  transportistas$: Subscription;

  configuracionTecnica: ConfiguracionTecnica;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  anioActividad: string;
  mesActividad: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private store: Store<PavsoState>,
    private _kardexService: KardexService,
    private _pedidoService: PedidoService,
    private _moduloAlmacenService: ModuloAlmacenService,
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);

    this.dataSourceKardex = fillTable([], this.paginatorKardex, this.sortKardex);
    this.configuracionTecnica = new ConfiguracionTecnica();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.period$ = this.store.select('period').subscribe(period => {
      this.anioActividad = period.year;
      this.mesActividad = period.month;
    })
  }


  loadData(): void {
    this.loaderData = true;

    this.dataFiltro$ = this._almacenService.obtenerDataFiltroConsultarStock().subscribe(
      dataFiltro => {
        this.productos = dataFiltro.productos;
        console.log('this.productos', this.productos)
        this.lineas = dataFiltro.lineas;
        this.almacenes = dataFiltro.almacenes;
        console.log('this.almacenes', this.almacenes)
        this.familias = dataFiltro.familias;
        this.marcas = dataFiltro.marcas;
        console.log('marcas', this.marcas)
        this.unidadesTransporte = dataFiltro.unidades;

        this.configuracionTecnica$ = this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
          configuracion => {
            this.configuracionTecnica = configuracion;
            let params: Array<IParam> = [
              {
                param: 'anio',
                value: this.anioActividad
              },
              {
                param: 'mes',
                value: this.mesActividad
              },
            ];
            this.pedidos$ = this._pedidoService.obtenerPedidos('01', {params}).subscribe(
              pedidos => {
                console.log('pedidos', pedidos)
                this.pedidos = pedidos;

                this.transportistas$ = this._moduloAlmacenService.obtenerTransportistas().subscribe(
                  transportistas => {
                    this.transportistas = transportistas;
                    this.loaderData = false;
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderData = false;
          }
        )

      },
      error => {
        this._snackBarService.showError('Error al obtener filtros', 'Ok');
        this.loaderData = false;
      }
    )

  }

  realizarConsulta(f: NgForm): void {

  }

  agregarSerie(): void {

  }

  insertarSerie(): void { }

  eliminarSerie(): void { }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.producto$,
      this.almacen$,
      this.familia$,
      this.linea$,
      this.marca$,
      this.unidad$,
      this.loading$
    ])
  }
}
