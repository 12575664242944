import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Cargo } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class CargoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarCargos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/cargos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cargo
   * @returns
   */
  registrarCargo(cargo: Cargo): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/cargos`,
      cargo
    );
  }

  /**
   *
   * @param cargo
   * @returns
   */
  EditarCargo(cargo: Cargo): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/cargos`,
      cargo
    );
  }
}
