export class DocumentoCtaCte {
  cia_codcia: string;
  cla_codcla: string;
  tdo_codtdo: string;
  sig_indsig: number;
  ccn_codmn: string;
  ccn_codme: string;
  sig_indest: string;
  sig_fecupd: Date;
  sig_coduse: string;
  sig_exccob: number;
  sig_indsta: string;

  constructor() {
    this.sig_fecupd = new Date();
  }
}
