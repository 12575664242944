import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService, ContabilidadService } from 'src/app/services';
import { AnnexedComponent } from '@shared/components/dialogs/annexed/annexed.component';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

class ConfiguracionEEFF {
  cef_codcef: any;
  cef_descri: any;
  cef_indsta: any;
  cef_fecupd: any;
  cef_coduse: any;
  detalle: any;
}

@Component({
  selector: 'app-configuracion-eef',
  templateUrl: './configuracion-eef.component.html',
  styleUrls: ['./configuracion-eef.component.css']
})
export class ConfiguracionEefComponent implements OnInit {

  usuario: any;
  fecha: Date = new Date();

  loaderData: boolean = false;
  loaderReg: boolean = false;

  displayedColumns: string[] = ['ced_corced', 'ced_descri', 'aef_codaef', 'ced_indsig', 't34_codt34'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  configuracion: ConfiguracionEEFF;

  uid: string;

  loading$: Subscription;

  constructor(
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog,
    private store: Store<PavsoState>
  ) {

    this.configuracion = new ConfiguracionEEFF();

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        if(id == '0') return

        this._contabilidadService.verEEFF(id).subscribe(
          configuracion => {
            this.configuracion.cef_codcef = configuracion[0].cef_codcef;
            this.configuracion.cef_descri = configuracion[0].cef_descri;
            this.dataSource = fillTable(this.configuracion[0].detalle, this.paginator, this.sort);

            this.loaderData = false;
          },
          error => this.loaderData = false
        )

      }
    )
  }

  verAnexo(): void {
    const dialogRef = this.dialog.open(AnnexedComponent, {
      width: '450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarConfiguracion(f): this.actualizarConfiguracion(f);
  }

  registrarConfiguracion(f: NgForm): void {}

  actualizarConfiguracion(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/tipos-estados-financieros']);
  }

}
