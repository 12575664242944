export const noBorders: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]         = [false,false,false,false];

export const topBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]         = [false,true,false,false];
export const bottomBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]      = [false,false,false,true];
export const rightBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]       = [false,false,true,false];
export const leftBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]        = [true,false,false,false];

export const horizontalBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [false,true,false,true];
export const verticalBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]    = [true,false,true,false];

export const topRightBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [false,true,true,false];
export const topLeftBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [true,true,false,false];
export const bottomRightBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [false,false,true,true];
export const bottomLeftBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [true,false,false,true];

export const openRightBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [true,true,false,true];
export const openTopBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [true,false,true,true];
export const openLeftBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [false,true,true,true];
export const openBottomBorder: [boolean] | [boolean, boolean] | [boolean, boolean, boolean, boolean]  = [true,true,true,false];
