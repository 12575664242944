import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_PLANTILLA_TECNICA } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { PlantillaService } from "src/app/services/api/crm/maestros/plantilla.service";

@Component({
  selector: 'plantilla-tecnica-list',
  templateUrl: 'plantilla-tecnica-list.component.html',
  styleUrls: ['plantilla-tecnica-list.component.scss']
})
export class PlantillaTecnicaListComponent implements OnInit, OnDestroy {

  plantillaTecnica$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_PLANTILLA_TECNICA;

  plantillasTecnica: Array<any> = [];

  constructor(
    private store: Store<PavsoState>,
    private _plantillaService: PlantillaService,
    private _snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerPlantillasTecnica();
      }
    })

  }

  obtenerPlantillasTecnica(): void {
    this.plantillaTecnica$ = this._plantillaService.obtenerPlantillas().subscribe(
      plantillas => {
        this.plantillasTecnica = plantillas
        console.log('plantillas', plantillas)
      },
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.plantillaTecnica$,
      this.loading$,
    ])
  }

}
