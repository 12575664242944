<h1 mat-dialog-title>Agregar Serie</h1>

<div class="row">
    <div class="col s12">
        <mat-card *ngIf="data.length == 0">No se encuentran registros</mat-card>
        <mat-card *ngFor="let item of data">
            {{item.spr_numser}}
            <button mat-icon-button type="button" color="warn" class="btn-delete" (click)="eliminarSerie(item)">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-card>
    </div>
</div>
<br>
<form #f="ngForm" ngNativeValidate (ngSubmit)="AgregarSerie(f)" (keydown.enter)="$event.preventDefault()">
    <div class="row">
        <div class="col s12 m12 l4">
            <mat-form-field>
                <mat-label>Serie /Pasaporte</mat-label>
                <input matInput placeholder="Serie /Pasaporte" [(ngModel)]="serie" name="serie" required>
            </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Fecha Fabricación</mat-label>
              <input matInput [matDatepicker]="pickerFab" [(ngModel)]="fechaFab" name="fechaFab" required>
              <mat-datepicker-toggle matSuffix [for]="pickerFab"></mat-datepicker-toggle>
              <mat-datepicker #pickerFab></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Fecha Vencimiento</mat-label>
              <input matInput [matDatepicker]="pickerVcto" [(ngModel)]="fechaVcto" name="fechaVcto" required>
              <mat-datepicker-toggle matSuffix [for]="pickerVcto"></mat-datepicker-toggle>
              <mat-datepicker #pickerVcto></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col s12">
            <button mat-flat-button type="submit">Agregar</button>
            &nbsp;
            <button mat-flat-button color="primary" type="button" (click)="cerrar()">{{buttonName.BTN_ACCEPT}}</button>
        </div>
    </div>

</form>
