import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_NOMINA_TIPOS_DOCUMENTO_LEGAJO } from '@data/constants/table/theader.nomina';
import { ApiNominaMaestrosTipoDocumentoLegajoService } from 'src/app/services/api/nomina/maestros/nomina.maestros.tipo-documento-legajo.service';

@Component({
  selector: 'app-tipo-documento-legajo-list',
  templateUrl: './tipo-documento-legajo-list.component.html',
  styleUrls: ['./tipo-documento-legajo-list.component.css']
})
export class TipoDocumentoLegajoListComponent implements OnInit, OnDestroy {

  tipoDoc$: Subscription;
  loading$: Subscription;

  lugaresTrabajo: any[] = [];
  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_TIPOS_DOCUMENTO_LEGAJO;

  constructor(
    private _apiNominaTipoDocumentoLegajo: ApiNominaMaestrosTipoDocumentoLegajoService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.obtenerTiposDocumentoLegajo();
    })
  }

  obtenerTiposDocumentoLegajo(): void {
    this.tipoDoc$ = this._apiNominaTipoDocumentoLegajo.listarTipoDocumentosLegajo().subscribe(
      response => {
        this.lugaresTrabajo = response;
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoDoc$, this.loading$]);
  }

}
