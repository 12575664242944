<h1 mat-dialog-title>Agregar lotes masivamente</h1>
<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
<div mat-dialog-content>
  <div class="row" [ngStyle]="{marginTop: '5px'}">
    <div class="col s12 m12 l3">
      <select-search [label]="'Período'" [placeholder]="'Ej. 2024 02'" [value]="'description'" [name]="'description'"
        [description]="'description'" [data]="periodos" (cambioSelect)="fields.periodo = $event; seleccionarPeriodo()"
        [model]="fields.periodo">
      </select-search>
    </div>
    <div class="col s12 m12 l3">
      <select-search #sAlmacen [label]="'Almacén'" [placeholder]="'Ej. ALMACÉN P.T.'" [value]="'ALM_CODALM'"
        [name]="'ALM_CODALM'" [description]="'ALM_DESCRI'" [data]="data.almacenes"
        (cambioSelect)="fields.almacen = $event" [model]="fields.almacen">
      </select-search>
    </div>
    <div class="col s12 m12 l3">
      <select-search [label]="'Producto'" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd'" [value]="'prd_codprd'"
        [description]="'description'" [data]="data.productos" (cambioSelect)="fields.producto = $event"
        [model]="fields.producto">
      </select-search>
    </div>
    <div class="col s12 m12 l3">
      <mat-form-field class="example-full-width">
        <mat-label>Referencia texto</mat-label>
        <input matInput placeholder="Ej. 000047" [(ngModel)]="fields.referencia" name="referencia">
      </mat-form-field>
    </div>
    <div class="col s12 m12 l3">
      <mat-form-field>
        <mat-label>Fecha creación</mat-label>
        <input matInput [matDatepicker]="fechaCreacion">
        <mat-datepicker-toggle matSuffix [for]="fechaCreacion"></mat-datepicker-toggle>
        <mat-datepicker #fechaCreacion></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col s12 m12 l3">
      <mat-form-field>
        <mat-label>Fecha vencimiento</mat-label>
        <input matInput [matDatepicker]="fechaVencimiento">
        <mat-datepicker-toggle matSuffix [for]="fechaVencimiento"></mat-datepicker-toggle>
        <mat-datepicker #fechaVencimiento></mat-datepicker>
      </mat-form-field>
    </div>

  </div>
  <div class="row" align="center">
    <button mat-mini-fab type="button" (click)="obtenerLotes()" color="primary"
      aria-label="Example icon button with a menu icon">
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <div class="row">

    <div class="col s12 m12 l4">
      <section class="example-section">
        <span class="example-list-section">
          <mat-checkbox class="example-margin" [checked]="allComplete"
            [indeterminate]="someComplete()" (change)="setAll($event.checked)">
            {{lote.name}}
          </mat-checkbox>
        </span>
        <span class="example-list-section">
          <ul>
            <li *ngFor="let lot of lote.lotes">
              <mat-checkbox [(ngModel)]="lot.completed"
                (ngModelChange)="updateAllComplete()">
                {{lot.lot_numlot}} - Stock: {{lot.stock}}
              </mat-checkbox>
            </li>
          </ul>
        </span>
      </section>

      <!-- <select-search [label]="'Lotes'" [placeholder]="'Ej. CANASTA'" [name]="'lot_numlot'" [value]="'lot_numlot'"
        [description]="'lot_numlot'" [data]="lotes" (cambioSelect)="fields.lotes = $event" [model]="fields.lotes"
        [multiple]="true">
      </select-search> -->
    </div>
  </div>

  <div class="row">
    <div class="col s12" align="end">
      <button mat-raised-button color="primary" (click)="establecerLotes()" type="button">Aceptar</button>
    </div>
  </div>
</div>
