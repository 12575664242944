<app-pavso-title-section [title]="'Tipos de documento legajo'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="lugaresTrabajo"
    [tHeaders]="tHeaders"

    title="Todos los tipos de documento"
    url="/modulo-planilla/tipo-de-documento-legajo"
    entity=""
    [params]="['0']"
  ></pav-table-list>
</div>
