<app-pavso-title-section [estado]="movimientoBanco.PBA_INDSTA" [title]="'Egreso Banco'" [module]="'Tesorería'"
  ngClass="pav-form" [otraInformacion]="estaConciliado ? 'Conciliado' : ''"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s4 m6 l1">
          <mat-form-field class="example-full-width">
            <mat-label>Año</mat-label>
            <input type="number" min="0" matInput placeholder="Año" [(ngModel)]="movimientoBanco.ANO_CODANO"
              name="ano_codano" readonly>
          </mat-form-field>
        </div>

        <div class="col s4 m6 l1">
          <mat-form-field class="example-full-width">
            <mat-label>Mes</mat-label>
            <input type="number" min="0" matInput placeholder="Mes" [(ngModel)]="movimientoBanco.MES_CODMES"
              name="mes_codmes" readonly>
          </mat-form-field>
        </div>

        <ng-container *ngIf="isMobile || isTablet">
          <div class="col s4 m6 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Voucher</mat-label>
              <input type="text" [(ngModel)]="movimientoBanco.LDC_CORLDC" name="LDC_CORLDC" matInput placeholder="Voucher"
                name="voucher" disabled>
            </mat-form-field>
          </div>
        </ng-container>

        <div class="col s12 m12 l3">

          <select-search #sSubdiario label="Subdiario" [placeholder]="'Ej. ASIENTO DE APERTURA'" name="sco_codsco"
            [value]="'sco_codsco'" [description]="'description'" [data]="subdiarios"
            (cambioSelect)="movimientoBanco.SCO_CODSCO = $event" [model]="movimientoBanco.SCO_CODSCO">
          </select-search>

        </div>

        <ng-container *ngIf="!isMobile && !isTablet">
          <div class="col s6 m6 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Voucher</mat-label>
              <input type="text" [(ngModel)]="movimientoBanco.LDC_CORLDC" name="LDC_CORLDC" matInput placeholder="Voucher"
                name="voucher" disabled>
            </mat-form-field>
          </div>
        </ng-container>

        <div class="col s6 m6 l2">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="movimientoBanco.PBA_FECORD" name="cco_fecreg"
              (dateChange)="seleccionarFecha()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s6 m6 l1">
          <mat-form-field class="example-full-width">
            <mat-label>T/C</mat-label>
            <input [ngStyle]="{textAlign: 'right'}" #iTC type="number" min="0" matInput placeholder="T/C"
              [(ngModel)]="movimientoBanco.PBA_TIPCAM" name="PBA_TIPCAM" step="any" (keyup)="establecerTipoCambio()"
              [disabled]="loaderData" (focus)="focusTC()">
          </mat-form-field>
        </div>

      </div>
      <div class="row">

        <div class="col s12 m12 l4">
          <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
            <div [ngStyle]="{width: '90%'}">
              <select-search label="Cta. Banco" [placeholder]="'Ej. BANCO DE CRÉDITO'" [name]="'CBA_CODCBA'"
                [disabled]="loaderData" [value]="'CBA_CODCBA'" [description]="'CBA_DESCRI'" [data]="cuentasBancaria"
                (cambioSelect)="movimientoBanco.CBA_CODCBA = $event; seleccionarBanco()" [model]="movimientoBanco.CBA_CODCBA">
              </select-search>
            </div>

            <div [ngStyle]="{marginLeft: '10px', marginBottom: '15px'}">
              {{movimientoBanco.TMO_CODTMO == 'SO' ? 'S/': '$'}}
            </div>
          </div>
        </div>

        <div class="col s6 m6 l4 xl2">
          <select-search [label]="'Tipo de Documento'" [placeholder]="'Ej. DOC'" [name]="'TDO_CODTDO'"
            [value]="'TDO_CODTDO'" [description]="'description'" [data]="tiposDoc"
            (cambioSelect)="movimientoBanco.TDO_CODTDO = $event; seleccionarTipoDoc()" [model]="movimientoBanco.TDO_CODTDO">
          </select-search>

        </div>

        <div class="col s6 m6 l4 xl2">
          <mat-form-field>
            <mat-label>Nro. Doc.</mat-label>
            <input #iNroDoc type="text" maxlength="20" matInput placeholder="Nro. Doc."
              [(ngModel)]="movimientoBanco.PBA_NUMDOC" name="cco_numdoc" [disabled]="loaderData"
              (keyup)="autocompletarNroDoc()" (keyup.enter)="establecerNroDoc($event)" [required]="true">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <select-search [label]="'Operación Frecuente'" [placeholder]="'Ej. TORRES'" [name]="'code'"
            [value]="'code'" [description]="'description'" [data]="operacionesFrecuentes"
            (cambioSelect)="movimientoBanco.operacionFrecuente = $event; seleccionarOperacionFrecuente()" [model]="movimientoBanco.operacionFrecuente">
          </select-search>

          <!-- <mat-form-field class="select-operacion-frecuente">
            <mat-label>Operación Frecuente</mat-label>
            <mat-select [(ngModel)]="egresoBanco.operacionFrecuente" name="operacionFrecuente"
              (contextmenu)="onRightClick($event)"
              [disabled]="!egresoBanco.PBA_TIPCAM || !egresoBanco.SCO_CODSCO || egresoBanco.SCO_CODSCO == '0'"
              (selectionChange)="seleccionarOperacionFrecuente()">
              <mat-option *ngFor="let operacion of operacionesFrecuentes" [value]="operacion.code">
                {{ operacion.description }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <mat-menu #matMenu="matMenu">
          <button mat-menu-item (click)="refrescarOperacionesFrecuentes()">Refrescar</button>
        </mat-menu>
        <div style="display:none;" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="matMenu"></div>

      </div>
      <div class="row">
        <div class="col s12 m12 l4">

          <select-search [label]="'Auxiliar'" [placeholder]="'Ej. TORRES'" [name]="'cli_codcli'" [value]="'cli_codcli'"
            [description]="'description'" [data]="auxiliares"
            (cambioSelect)="movimientoBanco.CLI_CODCLI = $event; seleccionarAuxiliar()" [model]="movimientoBanco.CLI_CODCLI">
          </select-search>

        </div>

        <div class="col s12 m12 l4">

          <select-search [label]="'A la Orden de'" [placeholder]="'Ej. TORRES'" [name]="'cli_codcli'"
            [value]="'cli_codcli'" [description]="'description'" [data]="auxiliares"
            (cambioSelect)="movimientoBanco.PBA_CODCLI = $event" [model]="movimientoBanco.PBA_CODCLI">
          </select-search>

        </div>

        <div class="col s12 m12 l4">
          <select-search [label]="'Flujo de caja'" [placeholder]="'Ej. TORRES'" [name]="'fca_codfca'"
            [value]="'fca_codfca'" [description]="'fca_descri'" [data]="flujos"
            (cambioSelect)="movimientoBanco.fca_codfca = $event; seleccionarFlujoCaja()" [model]="movimientoBanco.fca_codfca">
          </select-search>

          <!-- <mat-form-field>
            <mat-label>Flujo de caja</mat-label>
            <mat-select [(ngModel)]="egresoBanco.fca_codfca" name="fca_codfcaPrincipal"
              (selectionChange)="seleccionarFlujoCaja()">
              <mat-option *ngFor="let flujo of flujos" [value]="flujo.fca_codfca">
                {{flujo.fca_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>

        <div class="col s12 m12 l8">
          <mat-form-field class="example-full-width">
            <mat-label>Descripción</mat-label>
            <input matInput placeholder="Descripción" [(ngModel)]="movimientoBanco.PBA_GLOPBA" name="cco_glocco" />
          </mat-form-field>
        </div>

        <div class="col s6 m6 l2">
          <mat-form-field>
            <mat-label></mat-label>
            <input #iImporteDol [readonly]="movimientoBanco.TMO_CODTMO == 'DO' || !movimientoBanco.TMO_CODTMO"
              [ngStyle]="{textAlign: 'right'}" matInput type="number" min="0" class="formato-moneda"
              [(ngModel)]="movimientoBanco.PBA_IMPSOL" name="montoSoles" step="any" (keyup)="calcularMontos($event)"
              placeholder="0" (focus)="focusImporteDol()">
            <span matPrefix class="formato-moneda">S/.&nbsp;</span>
          </mat-form-field>
        </div>

        <div class="col s6 m6 l2">
          <mat-form-field>
            <mat-label></mat-label>
            <input #iImporteSol [readonly]="movimientoBanco.TMO_CODTMO == 'SO' || !movimientoBanco.TMO_CODTMO"
              [ngStyle]="{textAlign: 'right'}" matInput type="number" min="0" class="formato-moneda"
              [(ngModel)]="movimientoBanco.PBA_IMPDOL" name="montoDolares" step="any" (keyup)="calcularMontos($event)"
              placeholder="0" (focus)="focusImporteSol()">
            <span matPrefix class="formato-moneda">US$&nbsp;</span>
          </mat-form-field>
        </div>

      </div>
    </mat-card>

    <br>

    <ng-container *ngIf="isMobile || isTablet">
      <mat-card class="mat-elevation-z0" [ngStyle]="{marginBottom: '20px'}" align="center">
        <div class="pav-separator">

          <button class="pav-btn-action-table" color="primary" matTooltip="Ajuste < 0.05" type="button"
            (click)="ajusteDiferencia()">
            <mat-icon class="pav-icon-btn-table" svgIcon="setting"></mat-icon>
          </button>
          <button class="pav-btn-action-table" color="primary" matTooltip="Borrar Destinos" type="button"
            (click)="borrarAsientosDestino()">
            <mat-icon class="pav-icon-btn-table" svgIcon="document-error"></mat-icon>
          </button>
          <button class="pav-btn-action-table" color="primary" matTooltip="Documentos por pagar" type="button"
            (click)="documentosPorPagar()">
            <mat-icon class="pav-icon-btn-table" svgIcon="document-payment"></mat-icon>
          </button>
          <button class="pav-btn-action-table" color="primary" matTooltip="Documentos por cobrar" type="button"
            (click)="documentosPorCobrar()">
            <mat-icon class="pav-icon-btn-table" svgIcon="document-payment"></mat-icon>
          </button>
          <button class="pav-btn-action-table" color="primary" matTooltip="Copiar item" type="button"
            (click)="copiarItem()">
            <mat-icon class="pav-icon-btn-table" svgIcon="copy"></mat-icon>
          </button>

        </div>
        <div class="pav-table-search" [ngStyle]="{marginTop: '15px'}">
          <!-- <input [ngStyle]="{width: '220px !important'}" type="text" (keyup)="applyFilter($event)"
            [placeholder]="LABELS_NAME.BTN_SEARCH" #input> -->
        </div>
      </mat-card>
    </ng-container>
    <div (paste)="onPaste($event)">

      <div [ngStyle]="{display: 'flex', justifyContent: 'space-between'}">
        <div [ngStyle]="{display: 'flex', alignItems: 'center', marginBottom: '5px'}">
          <mat-icon matTooltip="Seleccionar tabla para pegar datos copiados desde Excel">info_outline</mat-icon>

          <div type="button" [ngStyle]="{cursor: 'pointer', fontSize: '14px', color: '#4792f9', marginLeft: '5px'}"
            (click)="seleccionarTabla()" color="primary">
            Seleccionar tabla
          </div>

        </div>
        <div>

          <a type="button" mat-button color="primary"
            href="https://docs.google.com/spreadsheets/d/1-GFrlR2rPgrVnHK-ILwV-9CZaZKhH5gF_O4LUms5pwg/edit?usp=sharing"
            target="_blank">Descargar plantilla</a>

        </div>
      </div>
      <div [ngStyle]="{border: tablaSeleccionada ? '3px solid #32b17b': '', borderRadius: '18px'}">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Detalle
          </div>
          <div class="pav-separator" *ngIf="!isMobile && !isTablet">

            <button class="pav-btn-action-table-ajuste" color="primary" matTooltip="Ajuste < 0.05" type="button"
              (click)="ajusteDiferencia()">
              <mat-icon class="pav-icon-btn-table" svgIcon="setting"></mat-icon>
            </button>
            <button class="pav-btn-action-table-borrar-destino" color="primary" matTooltip="Borrar Destinos" type="button"
              (click)="borrarAsientosDestino()">
              <mat-icon class="pav-icon-btn-table" svgIcon="document-error"></mat-icon>
            </button>
            <button class="pav-btn-action-table-cuenta-pagar" color="primary" matTooltip="Documentos por pagar" type="button"
              (click)="documentosPorPagar()">
              <mat-icon class="pav-icon-btn-table" svgIcon="money-send-icon"></mat-icon>
            </button>
            <button class="pav-btn-action-table-cuenta-cobrar" color="primary" matTooltip="Documentos por cobrar" type="button"
              (click)="documentosPorCobrar()">
              <mat-icon class="pav-icon-btn-table" svgIcon="money-receive-icon"></mat-icon>
            </button>
            <button class="pav-btn-action-table-copiar-item" color="primary" matTooltip="Copiar item" type="button"
              (click)="copiarItem()">
              <mat-icon class="pav-icon-btn-table" svgIcon="copy"></mat-icon>
            </button>

          </div>
          <ng-container *ngIf="!isMobile && !isTablet">
            <!-- <div class="pav-table-search">
              <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
            </div> -->
            &nbsp;
            &nbsp;
          </ng-container>

          <div class="pav-button-icon-add-modal" matTooltip="Agregar ítem" (click)="agregarItemModal()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-modal-detail"></mat-icon>
          </div>
          &nbsp;

          <button-add-item-table (addItem)="agregarItem()"></button-add-item-table>

          &nbsp;
          <button-remove-item-table (removeItem)="eliminarItem()"></button-remove-item-table>

          &nbsp;
          <button-insert-item-table (insertItem)="insertarItem()"></button-insert-item-table>

        </div>

        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>



            <ng-container matColumnDef="ldi_corldi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> It. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="It.">
                <mat-form-field [ngStyle]="{width: '55px'}" [class.mat-form-field-row]="row === selected"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput placeholder="It." [(ngModel)]="row.LDI_CORLDI" [name]="'LDI_CORLDI' + i.toString()"
                    readonly>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="indicador">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IND </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="IND">
                <mat-form-field [ngStyle]="{width: '30px'}" [class.mat-form-field-row]="row === selected"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput placeholder="Ind." [(ngModel)]="row.LDI_INDCCO" [name]="'ldi_indcco' + i.toString()"
                    readonly>
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="cli_codcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Proveedor">
                <div [ngStyle]="{width: '150px'}">
                  <select-search [label]="''"
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [placeholder]="'Ej. TORRES'" [name]="'cli_codcli' + i" [value]="'cli_codcli'"
                    [description]="'description'" [data]="clientes" (cambioSelect)="row.CLI_CODCLI = $event"
                    [model]="row.CLI_CODCLI">
                  </select-search>
                </div>

              </td>
            </ng-container>



            <ng-container matColumnDef="tdo_codtdo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> T.D. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Doc.">
                <div [ngStyle]="{width: '110px'}">
                  <select-search [label]="''"
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                    [placeholder]="'Ej. DOC'" [name]="'TDO_CODTDO' + i" [value]="'TDO_CODTDO'"
                    [description]="'description'" [data]="tiposDocumento" (cambioSelect)="row.TDO_CODTDO = $event; seleccionarTipoDocRow(row)"
                    [model]="row.TDO_CODTDO">
                  </select-search>
                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_docref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Nro. Doc.">
                <div>
                  <mat-form-field [ngStyle]="{width: '115px'}" [class.mat-form-field-row]="row === selected"
                    class="mat-form-field-table form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input matInput placeholder="Nro. Doc." [(ngModel)]="row.LDI_DOCREF"
                      [name]="'nro_doc' + i.toString()">
                  </mat-form-field>
                </div>
              </td>
            </ng-container>



            <ng-container matColumnDef="ccn_codccn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta">
                <div [ngStyle]="{width: '150px'}">
                  <select-search [label]="''"
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [placeholder]="'Ej. 42'" [name]="'ccn_codccn' + i" [value]="'ccn_codccn'" [description]="'description'"
                    [data]="cuentasTotales" (cambioSelect)="row.CCN_CODCCN = $event; seleccionarCuenta(row)"
                    [model]="row.CCN_CODCCN">
                  </select-search>
                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="ccs_codccs">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="C. Costo">
                <div [ngStyle]="{width: '120px'}">
                  <select-search [label]="''"
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                    [placeholder]="'Ej. Centro'" [name]="'ccs_codccs' + i" [value]="'ccs_codccs'"
                    [description]="'description'" [data]="centrosCosto" (cambioSelect)="row.CCS_CODCCS = $event"
                    [model]="row.CCS_CODCCS">
                  </select-search>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="tmo_codtmo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Mon.">
                <div>
                  <mat-form-field style="width: 60px;" [class.mat-form-field-row]="row === selected"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <mat-select #iMoneda [disabled]="loaderData" [(ngModel)]="row.TMO_CODTMO" name="TMO_CODTMO"
                      [name]="'moneda' + i.toString()">
                      <mat-option value="SO">
                        S/
                      </mat-option>
                      <mat-option value="DO">
                        $
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_tipcam">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> T. C. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="T. Cambio">

                <mat-form-field [ngStyle]="{width: '60px'}" [class.mat-form-field-row]="row === selected"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input min="0" type="number" matInput placeholder="T. Cambio" [(ngModel)]="row.LDI_TIPCAM"
                    [name]="'LDI_TIPCAM' + i.toString()" step="any">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_inddha">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="D/H">
                <div [ngStyle]="{width: '55px'}">
                  <mat-form-field class="form-field-small form-field-cell" [class.mat-form-field-row]="row === selected">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="row.LDI_INDDHA" [name]="'dh' + i.toString()">
                      <mat-option value="D">
                        D
                      </mat-option>
                      <mat-option value="H">
                        H
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_impnac">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe MN </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe MN">

                <mat-form-field [ngStyle]="{width: '100px'}" [class.mat-form-field-row]="row === selected"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" (keyup)="calculoImportesMN(row)" [readonly]="row.TMO_CODTMO == 'DO'" min="0" type="number" matInput
                    placeholder="Importe MN" [(ngModel)]="row.LDI_IMPNAC" (keyup.enter)="verificarImporte(row)"
                    [name]="'importe_mn' + i.toString()" step="any">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_impmex">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe ME </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe ME">

                <mat-form-field [ngStyle]="{width: '100px'}" [class.mat-form-field-row]="row === selected"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" (keyup)="calculoImportesME(row)" [readonly]="row.TMO_CODTMO == 'SO'"
                    (keyup.enter)="verificarImporte(row)" min="0" type="number" matInput placeholder="Importe ME"
                    [(ngModel)]="row.LDI_IMPMEX" [name]="'importe_me' + i.toString()" step="any">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_impsal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo Doc.</th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Saldo Doc.">

                <mat-form-field [ngStyle]="{width: '100px'}" [class.mat-form-field-row]="row === selected"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" readonly min="0" type="number" matInput placeholder="Saldo Doc."
                    [(ngModel)]="row.ldi_impsal" [name]="'ldi_impsal' + i.toString()" step="any">
                </mat-form-field>

              </td>
            </ng-container>
            ldi_impsal

            <ng-container matColumnDef="ldi_gloldi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa">
                <mat-form-field class="form-field-small form-field-cell" [class.mat-form-field-row]="row === selected">
                  <mat-label></mat-label>
                  <input type="text" matInput placeholder="Glosa" [(ngModel)]="row.LDI_GLOLDI"
                    [name]="'glosa' + i.toString()">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="cco_codcco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cta. Cte." style="width: 100px">
                <div [ngStyle]="{width: '150px'}">
                  <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                    style="width: 130px">
                    <mat-label></mat-label>
                    <input type="text" matInput placeholder="Cta. Cte." readonly [(ngModel)]="row.CCO_CODCCO"
                      [name]="'cco_codcco' + i.toString()">
                  </mat-form-field>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="orden_compra">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ord. Compra </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Ord. Compra" style="width: 100px">
                <div [ngStyle]="{width: '150px'}">
                  <select-search [label]="''"
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [placeholder]="'Ej. 000001'" [name]="'ocm_numocm' + i" [value]="'ocm_numocm'"
                    [description]="'description'" [data]="ordenes" (cambioSelect)="row.OCM_NUMOCM = $event"
                    [model]="row.OCM_NUMOCM">
                  </select-search>
                </div>
              </td>
            </ng-container>



            <ng-container matColumnDef="flujo_caja">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Flujo Caja </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Flujo Caja" style="width: 100px">
                <div [ngStyle]="{width: '150px'}">
                  <select-search [label]="''"
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [placeholder]="'Ej. Flujo'" [name]="'fca_codfca' + i" [value]="'fca_codfca'"
                    [description]="'fca_descri'" [data]="flujos" (cambioSelect)="row.fca_codfca = $event"
                    [model]="row.fca_codfca">
                  </select-search>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="dias">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Días </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Días" style="width: 100px">
                <ng-container>
                  <mat-form-field [ngStyle]="{width: '45px'}" [class.mat-form-field-row]="row === selected"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input matInput placeholder="2" [name]="'dias' + i.toString()" type="number" min="0"
                      [(ngModel)]="row.dias" (keyup)="establecerDias(row)">
                  </mat-form-field>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="fecha_vencimiento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Venc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Días" style="width: 100px">
                <mat-form-field [ngStyle]="{width: '120px'}" [class.mat-form-field-row]="row === selected"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput [matDatepicker]="pickerFecVenc" [(ngModel)]="row.LDI_FECVEN"
                    [name]="'fecvencimiento' + i">
                  <mat-datepicker-toggle matSuffix [for]="pickerFecVenc"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFecVenc></mat-datepicker>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="req_pago">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Req. Pago </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Req. Pago" style="width: 100px">
                req. pago
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
              (click)="selectRow(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="20">No se encontraron registros</td>
            </tr>

          </table>

        </div>
        <mat-paginator #paginator1 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

      </div>
    </div>

    <br>
    <div class="row" style="margin-bottom: 90px !important;">
      <div class="col s12 m12 l4">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Debe / Haber
          </div>

        </div>

        <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSource3">

            <ng-container matColumnDef="name" sticky>
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="mn">
              <th mat-header-cell *matHeaderCellDef> Soles </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.mn.toFixed(2)}} </td>
            </ng-container>

            <ng-container matColumnDef="me">
              <th mat-header-cell *matHeaderCellDef> Dolares </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.me.toFixed(2)}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros</td>
            </tr>

          </table>

        </div>
      </div>
    </div>


  </div>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" [loaderFormato]="loaderFormato"
    urlBack="/modulo-cajabanco/egresos-banco" (clickNuevo)="registroNuevo()" (clickFormato)="generarFormato()"
    (anular)="anular()">
    <ng-container id="botonesAdicionales">
      <button *ngIf="movimientoBanco.LDC_CORLDC" class="pav-btn-action" color="primary" matTooltip="Agregar operación frecuente"
        type="button" (click)="agregarOperacionFrecuente()">
        <mat-icon class="pav-icon-btn" svgIcon="add-operation"></mat-icon>
      </button>
    </ng-container>
  </pav-form-actions>
</form>
