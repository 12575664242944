import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "../../configuration.service";
import { Observable } from "rxjs";
import { OrdenPago } from "src/app/models/caja-banco/orden-pago";
import { IParam, generarParams } from "../utilities/params";

@Injectable({
  providedIn: 'root'
})
export class OrdenPagoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerOrdenesPago(ano_codano: string, mes_codmes: string, { params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ordenpago/listar/${this._configurationService.obtenerCompaniaCliente()}/${ano_codano}/${mes_codmes}${paramsStr}`
    );
  }

  obtenerOrdenPago(ano_codano: string, mes_codmes: string, SCO_CODSCO: string, LDC_CORLDC: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ordenpago/mostrar/01/${ano_codano}/${mes_codmes}/${SCO_CODSCO}/${LDC_CORLDC}`
    );
  }

  registrarOrdenPago(payload: OrdenPago): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ordenpago/crear`,
      payload
    );
  }

  editarOrdenPago(payload: OrdenPago): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ordenpago/modificar`,
      payload
    );
  }

}
