import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'consulta-cuenta-corriente-pedido',
  templateUrl: './consulta-cuenta-corriente-pedido.component.html',
  styleUrls: ['./consulta-cuenta-corriente-pedido.component.scss']
})
export class ConsultaCuentaCorrientePedidoComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
