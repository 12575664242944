export class CanalVenta {
  vds_codvds: string;
  vds_descri: string;
  vds_diamor: number;
  vds_indsta: string;

  constructor() {
    this.vds_indsta = '1';
  }
}
