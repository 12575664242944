import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SnackBarService } from "@shared/services/snackbar.service";

@Component({
  selector: 'pav-upload-images',
  templateUrl: './pav-upload-images.component.html',
  styleUrls: ['./pav-upload-images.component.scss']
})
export class PavUploadImagesComponent {

  @Input() title: string = "Imágenes";
  @Input() requirement: string = "Ingrese una o varias capturas de imagen que muestren la situación a resolver. Tamaño máximo de la imagen 1 MB.";
  @Input() loaderData: boolean;
  @Input() maxSizeImage: number;
  @Output() selectionImages: EventEmitter<any[]>;

  files: File[] = [];
  loadUpload: boolean = false;
  imagesUploaded: any[] = [];

  constructor(
    private _snackBarService: SnackBarService
  ) {
    this.selectionImages = new EventEmitter<any[]>();
  }

  onSelect(event): void {

    if (event.addedFiles.length == 0) {
      this._snackBarService.showError('Tamaño Máximo permitido 2MB', 'Ok');
      this.loadUpload = false;
      return;
    }
    console.log('files =>', this.files)

    this.loadUpload = true
    const a = event.addedFiles;
    this.files.push(...a);
    console.log('files despues =>', this.files)

    for (let index = 0; index < this.files.length; index++) {
      const element = this.files[index];

      let reader = new FileReader;

      let urlImageTemp = reader.readAsDataURL(element);

      reader.onloadend = () => {

        this.imagesUploaded.push({
          img_nomarc: element.name,
          img_extarc: element.type.split('/')[1],
          img_docb64: reader.result
        })



      };
    }

    setTimeout(() => {
      this.loadUpload = false;
      this.selectionImages.emit(this.imagesUploaded);
    }, 3000);


  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
    console.log('this.files', this.files)
    this.imagesUploaded = this.files;
    this.selectionImages.emit(this.imagesUploaded);
  }

}
