import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LineaOperacion } from 'src/app/models/produccion/maestros/linea-operacion';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
  export class LineaOperacionService {
    constructor(
      private http: HttpClient,
      private _configurationService: ConfigurationService
    ) {}

/**
   *
   * @returns
   */
obtenerLineasOperacion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionlineaoperacion/listar/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((linea_operacion: any) => {
        return linea_operacion.map(linea_operacion => {
          linea_operacion['cli_nomcli'] = `${linea_operacion.operario.cli_nomcli}`
          linea_operacion['lpd_descri'] = `${linea_operacion.linea.lpd_descri}`
          return linea_operacion;
        })
      })
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  mostrarLineaOperacion(linea_producto:string, secuencia:string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionlineaoperacion/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${linea_producto}/${secuencia}`
    );
  }

  /**
   *
   * @param linea
   * @returns
   */
  registrarLineaOperacion(linea: LineaOperacion): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionlineaoperacion/crear`,
      linea
    );
  }

  /**
   *
   * @param linea
   * @returns
   */
  actualizarLineaOperacion(linea: LineaOperacion): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionlineaoperacion/modificar`,
      linea
    );
  }
}
