import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { MotivoMovimiento } from "src/app/models/ventas/motivo-movimiento";
import { MovimientoTipoDocumento } from "src/app/models/ventas/motivo-movimiento-tipo-documento";
import { AlmacenService, ConfigurationService, ContabilidadService, VentasService } from "src/app/services";
import { TipoGastoService } from "src/app/services/api/contabilidad/maestros/tipo-operacion.service";
@Component({
  selector: 'motivo-movimiento-form',
  templateUrl: './motivo-movimiento-form.component.html',
  styleUrls: ['./motivo-movimiento-form.component.scss']
})
export class MotivoMovimientoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;

  uid: string;
  motivoMovimiento: MotivoMovimiento;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  year: string;
  month: string;


  tiposDeOperaciones: any[] = [];
  cuentaContableVinculada: any[] = [];
  motivoNCreditoSunat: any[] = [];
  motivoNDebitoSunat: any[] = [];
  motivoGuiaSUNAT: any[] = [];
  tipoOperacionT51: any[] = [];
  tipoImpuesto: any[] = [];
  tipoDocumento:any[]=[];
  referenciasTipoDocumento:any[]=[
    {mdr_indref: '1'},
    {mdr_indref: '2'}

  ];

  //Utilizado para Tipos de documento / tdo_reftdo es un campo que aun no esta en la tabla motivo movimiento es sugerencia de lavaro el nombre
  displayedColumnsTipoDocumento: string[] = ['acciones', 'tdo_codtdo', 'mdr_indref'];
  dataSourceTipoDocumento: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginatorTipoDocumento: MatPaginator;
  @ViewChild(MatSort) sortTipoDocumento: MatSort;


  sendForm$: Subscription;
  loading$: Subscription;
  dataForm$: Subscription;

  tiposDeOperaciones$: Subscription;
  cuentaContableVinculada$: Subscription;

  motivoNCreditoSunat$: Subscription;
  motivoNDebitoSunat$: Subscription;

  motivoGuiaSUNAT$: Subscription;

  tipoOperacionT51$: Subscription;
  tipoImpuesto$: Subscription;
  tipoDocumento$: Subscription;
  period$: Subscription;


  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _contabilidadService: ContabilidadService,
    private _tipoGastoService: TipoGastoService,
    private _configurationService: ConfigurationService,
    private _almacenService: AlmacenService
  ) {
    this.dataSourceTipoDocumento = fillTable([], this.paginatorTipoDocumento, this.sortTipoDocumento);

    this.period$= this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
    this.motivoMovimiento = new MotivoMovimiento();
    this.motivoMovimiento.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.motivoMovimiento.mmo_coduse = this._configurationService.obtenerIdUsuario();
    this.motivoMovimiento.mmo_codmmo = '';
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.dataForm$,
      this.loading$,
      this.tiposDeOperaciones$,
      this.period$,
      this.cuentaContableVinculada$,
      this.tipoDocumento$,
      this.tipoImpuesto$,
      this.tipoOperacionT51$,
      this.motivoGuiaSUNAT$,
      this.motivoNDebitoSunat$,
      this.motivoNCreditoSunat$
    ])
  }

  cambiarEstadoMMO(){
    this.motivoMovimiento.referencias_mdr.map(item => item.mmo_codmmo = this.motivoMovimiento.mmo_codmmo);
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarMotivoMovimiento(f): this.actualizarMotivoMovimiento(f);
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
    this.uid = id;
    this.btnName = this.uid == '0' ?  this.buttonsName.BTN_STORE: this.buttonsName.BTN_UPDATE;
    this.loadMaestros();
  })
  }

  loadMaestros(): void {
    forkObs(
      this._tipoGastoService.obtenerTiposGasto(),
      this._almacenService.listarMotivoCreditoSunat(),
      this._almacenService.listarMotivoDebitoSunat(),
      this._contabilidadService.obtenerCatalogoSUNATporCodigo('20'),
      this._contabilidadService.obtenerCatalogoSUNATporCodigo('51'),
      this._contabilidadService.obtenerCatalogoSUNATporCodigo('07'),
      this._contabilidadService.obtenerTiposDocumento(),
      this._contabilidadService.obtenerCuentasContablePipe()
    ).then((result) => {
      this.tiposDeOperaciones = result[0];
      console.log('tipo de operacion', this.tiposDeOperaciones)
      this.motivoNCreditoSunat = result[1];
      this.motivoNDebitoSunat = result[2];
      this.motivoGuiaSUNAT = result[3].map(item => {item.description = item.t20_coddoc+' | '+item.t20_descri; return item});
      this.tipoOperacionT51 = result[4].map(item => {item.description = item.t51_codt51+' | '+item.t51_descri; return item});
      this.tipoImpuesto = result[5].map(item => {item.description = item.t07_codt07+' | '+item.t07_descri; return item});
      this.tipoDocumento = result[6].filter(item => item.TDO_INDSTA === '1' && item.tdo_indalm === 1);
      this.cuentaContableVinculada = result[7].filter(cuenta =>
        cuenta.ccn_codccn[0] === '7' &&
        cuenta.ccn_indsta === '1' &&
        cuenta.ccn_tipccn === 1);

      if(this.uid != '0') this.mostrarMotivoMovimiento();
    }).catch(error => console.error(error));

  }




  /** Checkbox Lógica:  si true es 1 sino 0  */
  onNgModelChangeCheckbox(event, tipo: string) {
    console.log("event. ", event, " tipo: ", tipo)
    this.motivoMovimiento[tipo] = event == true ? 1 : 0
  }


  obtenerTiposDeOperaciones():void{
    this.tiposDeOperaciones$ = this._tipoGastoService.obtenerTiposGasto().subscribe(
      response => {
        console.log("tipos de operaciones", response);
        this.tiposDeOperaciones = response;
        this.obtenerMotivoNCreditoSunat();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }
  obtenerMotivoNCreditoSunat():void{
    this.motivoNCreditoSunat$ = this._almacenService.listarMotivoCreditoSunat().subscribe(
      response => {
        console.log("motivoNCreditoSunat", response);
        this.motivoNCreditoSunat = response;
        this.obtenerMotivoNDebitoSunat();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }
  obtenerMotivoNDebitoSunat():void{
    this.motivoNDebitoSunat$ = this._almacenService.listarMotivoDebitoSunat().subscribe(
      response => {
        console.log("motivoNDebitoSunat", response);
        this.motivoNDebitoSunat = response;
        this.obtenerTipoOperacionT51();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    );
  }

  obtenerTiposDeDocumento():void{
    this.tipoDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
      response => {
        console.log("tipoDocumento", response);
        this.tipoDocumento = response.filter(item => item.TDO_INDSTA === '1' && item.tdo_indalm === 1);
        console.log("tipoDocumento filtrado", this.tipoDocumento);
        this.obtenerCuentaContableVinculadaVenta();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    );

  }

/*** Lista las cuentas contable que se utilizaran para contable y vinculadas */
  obtenerCuentaContableVinculadaVenta():void{
    this.cuentaContableVinculada$ = this._contabilidadService.obtenerCuentasContablePipe().subscribe(
      cuentaContableVinculada => {
      console.log("consultaCuentaContableSin filtrar", cuentaContableVinculada);
      //filtro para Perú
      cuentaContableVinculada = cuentaContableVinculada
      .filter(cuenta =>
        cuenta.ccn_codccn[0] === '7' &&
        cuenta.ccn_indsta === '1' &&
        cuenta.ccn_tipccn === 1);
      //CUENTA CONTABLE / VINCULADA tienen la misma lista pero en el formulario tiene que ser diferente
      this.cuentaContableVinculada = cuentaContableVinculada;
      console.log("consultaCuentaContable filtrado", cuentaContableVinculada);

      if(this.uid != '0'){this.mostrarMotivoMovimiento()}},
      error => {
        this._snackBarService.showError("Error al obtener  cuenta contable de asiento contable", "OK");
      }
    );
  }



  /*** Verifica que la cuenta contable no sea igual a la cuenta vinculada*/
  onChangeCuentaConVin(){
    if(this.motivoMovimiento.ccn_codccn === this.motivoMovimiento.ccn_codvin){
      this._snackBarService.showError("La cuenta contable no puede ser igual a la cuenta vinculada", "OK");
      this.motivoMovimiento.ccn_codvin = null;
    }
  }


  mostrarMotivoMovimiento():void{
    this.dataForm$ = this._ventasService.mostrarMotivoMovimiento(this.uid).subscribe(
      response => {
        console.log("motivo: ", response);
        this.motivoMovimiento = response;
        if(!this.motivoMovimiento.referencias_mdr ){
          this.motivoMovimiento.referencias_mdr = [];
        }
        //rellenar la tabla de tipos de documento
        this.motivoMovimiento.referencias_mdr = this.motivoMovimiento.referencias_mdr.map(item => {
          this.tipoDocumento.forEach(tipoDocumento => {
            if(item.tdo_codtdo === tipoDocumento.TDO_CODTDO){
              item.description = tipoDocumento.description;
            }
          })
          ; return item});
          console.log("soy las referencias", this.motivoMovimiento.referencias_mdr)
        this.dataSourceTipoDocumento = fillTable(this.motivoMovimiento.referencias_mdr, this.paginatorTipoDocumento, this.sortTipoDocumento);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }
  registrarMotivoMovimiento(f: NgForm): void {
    console.log("detalle de motivo", this.motivoMovimiento)
    this.sendForm$ = this._ventasService.registrarMotivoMovimiento(this.motivoMovimiento).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Motivo de Moviemiento registrado', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }
  actualizarMotivoMovimiento(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarMotivoMovimiento(this.motivoMovimiento).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Motivo de Moviemiento actualizado', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
        error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }
  /* Lógica para la tabla de Tipos de Documento con sus referencias */

  agregarTipoDocumento():void{
    let item = new MovimientoTipoDocumento();

    item.cia_codcia = this.motivoMovimiento.cia_codcia;
    item.mdr_coduse = this.motivoMovimiento.mmo_coduse;
    item.mmo_codmmo = this.motivoMovimiento.mmo_codmmo;
    console.log("soy un nuevo tipo ",item)
    this.motivoMovimiento.referencias_mdr.push(item);
    this.dataSourceTipoDocumento = fillTable(this.motivoMovimiento.referencias_mdr, this.paginatorTipoDocumento, this.sortTipoDocumento);
   }

  editarTipoDocumento(row): void {
    debugger
    row.isEditing = !row.isEditing;
  }

  confirmarTipoDocumento(row:MovimientoTipoDocumento):void{
    if(!row.tdo_codtdo || !row.mdr_indref ) {
    return this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok');
    }
    row.isEditing = !row.isEditing;
  }

  quitarTipoDocumento(row:MovimientoTipoDocumento,index:number):void{

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.motivoMovimiento.referencias_mdr.filter((item,i) => item.tdo_codtdo !== row.tdo_codtdo && i !== index);
        this.motivoMovimiento.referencias_mdr = filtered;
        this.dataSourceTipoDocumento = fillTable(filtered, this.paginatorTipoDocumento, this.sortTipoDocumento);
      }
    })
  }
  seleccionarTiposDocumento($event, row: MovimientoTipoDocumento): void {
    const noAgregar = this.motivoMovimiento.referencias_mdr.filter(item => item.tdo_codtdo === $event).length >1;
    if(noAgregar){
      this._snackBarService.showError('No se puede agregar el mismo tipo de documento', 'Ok');
      row.description =''
      row.tdo_codtdo =''
      return;
    }

    const tiposDocumento = this.tipoDocumento.find(item => item.TDO_CODTDO == $event);

    row.description = tiposDocumento.description;


  }

  /////////////////////////////////////////////////////////////////////////


  /* OBTENER CATALOGOS
  TIPO DE OPERACION T51 esta será ejecuta al resolver ...
  TIPOS DE IMPUESTOS
  MOTIVO GUIA SUNAT
  */

  obtenerTipoOperacionT51():void{
    const CODIGO_T51 = '51';
    this.tipoOperacionT51$ = this._contabilidadService.obtenerCatalogoSUNATporCodigo(CODIGO_T51).subscribe(
      response => {
        this.tipoOperacionT51 = response.map(item => {item.description = item.t51_codt51+' | '+item.t51_descri; return item});
        console.log("tipoOperacionT51", this.tipoOperacionT51);
        this.obtenerMotivoGuiaSunat();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  obtenerMotivoGuiaSunat():void{
    const CODIGO_GUIASUNAT = '20';
    this.motivoGuiaSUNAT$ = this._contabilidadService.obtenerCatalogoSUNATporCodigo(CODIGO_GUIASUNAT).subscribe(
      response => {
        this.motivoGuiaSUNAT = response.map(item => {item.description = item.t20_coddoc+' | '+item.t20_descri; return item});
        console.log("motivoGuiaSUNAT", this.motivoGuiaSUNAT);
        this.obtenerTiposImpuesto();
      },error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /** Código para servicio 07*/
  obtenerTiposImpuesto():void{
    const CODIGO_TIPO_IMPUESTO = '07';
    this.tipoImpuesto$ = this._contabilidadService.obtenerCatalogoSUNATporCodigo(CODIGO_TIPO_IMPUESTO).subscribe(
      response => {
        this.tipoImpuesto = response.map(item => {item.description = item.t07_codt07+' | '+item.t07_descri; return item});
        console.log("tipoImpuesto", this.tipoImpuesto);
        this.obtenerTiposDeDocumento();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    );
  }


  volver(): void {
    this._router.navigate(['/modulo-ventas/motivos-de-movimiento']);
  }


}
