import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Cobrador } from "src/app/models/cobranzas";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class CobradorService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerCobradores(): Observable<any> {

    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablastesoreria/listacobrador/${this._configurationService.obtenerCompaniaCliente()}`);

  }

  /**
   *
   * @param cobrador
   * @returns
   */
  registrarCobrador(cobrador: Cobrador): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/cobrador`,
      cobrador
    );
  }

  /**
   *
   * @param cobrador
   * @returns
   */
  actualizarCobrador(cobrador: Cobrador): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/cobrador`,
      cobrador
    );
  }
}
