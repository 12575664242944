import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class TiendaService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {

  }

  obtenerTiendas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tiendas/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  obtenerTienda(idTienda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tiendas/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${idTienda}`
    );
  }

}
