import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { TipoActivoService } from 'src/app/services/api/activo-fijo/maestros/tipo-activo.service';

@Component({
  selector: 'app-transferencia-retiro-financiero',
  templateUrl: './transferencia-retiro-financiero.component.html',
  styleUrls: ['./transferencia-retiro-financiero.component.css']
})
export class TransferenciaRetiroFinancieroComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['activo', 'descripcion', 'act_soles', 'act_us', 'dpc_soles', 'dpc_us', 'act_ajust', 'dpc_ajust'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tiposActivo: any[] = [];

  loading$: Subscription;
  tiposActivo$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _tipoActivoService: TipoActivoService,
    private _snackBarService: SnackBarService
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this.loadMaestros();
  }

  loadMaestros(): void {
    this.tiposActivo$ = this._tipoActivoService.listarTiposActivo().subscribe(
      tiposActivo => {
        this.tiposActivo = tiposActivo;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de activo', 'Ok')

      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
