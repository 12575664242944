<div class="pav-table-header-container">
  <div class="pav-table-header-icon">

  </div>
  <div class="" [ngStyle]="{marginRight: '20px', color: 'white'}">
    Documentos
  </div>
  <div class="pav-separator"></div>

  <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarDocumento()"
    [ngStyle]="{display: loaderData? 'none': ''}">
    <mat-icon svgIcon="add-circle"></mat-icon>
  </div>
  <!-- &nbsp;
  <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="quitarDocumento()"
    [ngStyle]="{display: loaderData? 'none': ''}">
    <mat-icon svgIcon="remove"></mat-icon>
  </div> -->

  <div class="pav-table-header-add">

  </div>

</div>
<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

<div class="overflow-x">
  <table mat-table [dataSource]="dataSourceDocumentos" matSort class="table-cell">

    <ng-container matColumnDef="item">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
      <td mat-cell *matCellDef="let row; let i = index" [style.color]="row.color" data-label="Item">
        <p [ngStyle]="{color: 'bold', marginLeft: '10px', fontWeight: 'bold'}">{{i + 1}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="ccd_codtdo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Doc. </th>
      <td mat-cell *matCellDef="let row; let i = index" data-label="Tipo de documento">
        <select-search
          [clase]="!row? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
          [label]="''" [placeholder]="'Ej. TORRES'" [name]="'tdl_codtdl' + i" [value]="'tdl_codtdl'"
          [description]="'tdl_descri'" [data]="tiposLegajo" (cambioSelect)="row.ccd_codtdo = $event" [model]="row.ccd_codtdo">
        </select-search>
      </td>
    </ng-container>

    <ng-container matColumnDef="ccd_glosa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Comentario </th>
      <td mat-cell *matCellDef="let row; let i = index" data-label="Comentario">
        <div>
          <mat-form-field style="padding-left: 12px !important" [class.mat-form-field-row]="row"
            class="mat-form-field-table form-field-small form-field-cell">
            <input matInput placeholder="" [(ngModel)]="row.ccd_glosa" [name]="'comentario' + i">
          </mat-form-field>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="adjuntar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div [ngStyle]="{textAlign: 'right', width: '95px'}">
          Adjuntar
        </div>
      </th>
      <td mat-cell *matCellDef="let row; let i = index" data-label="Adjuntar">
        <div [ngStyle]="{display: 'flex', alignItems: 'center', marginLeft: '20px'}">
          <ng-container *ngIf="row.ccd_tiparc">
            <ng-container *ngIf="row.ccd_tiparc.includes('image/')">

              <div
                [ngStyle]="{marginRight: '5px', border: '2px solid #222', width: '50px', height: '50px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                <img [src]="row.ccd_fotccd" width="40" height="40" alt="Imagen" [ngStyle]="{cursor: 'pointer'}">
              </div>
            </ng-container>
            <ng-container *ngIf="row.ccd_tiparc == 'application/pdf'">
              <div
                [ngStyle]="{marginRight: '5px', border: '2px solid #222', width: '50px', height: '50px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                <mat-icon>picture_as_pdf</mat-icon>
              </div>
            </ng-container>
            <ng-container *ngIf="row.ccd_tiparc == 'application/zip'">
              <div
                [ngStyle]="{marginRight: '5px', border: '2px solid #222', width: '50px', height: '50px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                <mat-icon>folder_zip</mat-icon>
              </div>
            </ng-container>
            <ng-container *ngIf="row.ccd_tiparc == 'text/xml'">
              <div
                [ngStyle]="{marginRight: '5px', border: '2px solid #222', width: '50px', height: '50px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                <span [ngStyle]="{fontWeight: 'bold', fontSize: '14px'}">XML</span>
              </div>
            </ng-container>
          </ng-container>

          <pav-button-upload-file accept="image/*, application/pdf, .xml"
            (onUploadFile)="handleOnUploadFile(row, $event)"></pav-button-upload-file>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="visualizar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Visualizar </th>
      <td mat-cell *matCellDef="let row; let i = index" data-label="Visualizar">
        <button (click)="openFile(row.ccd_fotccd, row.ccd_tiparc)" [ngStyle]="{marginLeft: '20px'}" mat-mini-fab
          color="primary" aria-label="Example icon button with a menu icon">
          <mat-icon>visibility</mat-icon>
        </button>

      </td>
    </ng-container>

    <ng-container matColumnDef="eliminar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Eliminar </th>
      <td mat-cell *matCellDef="let row; let i = index" data-label="Visualizar">
        <ng-container *ngIf="row.ccd_idccd">
          <button (click)="quitarDocumento(row.ccd_idccd)" [ngStyle]="{marginLeft: '20px'}" mat-mini-fab color="warn"
            aria-label="Example icon button with a menu icon">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="ccd_uploaded">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Está grabado? </th>
      <td mat-cell *matCellDef="let row; let i = index" data-label="Visualizar" [ngStyle]="{textAlign: 'center'}">

        <mat-icon *ngIf="row.ccd_uploaded" svgIcon="check-circle"></mat-icon>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDocumentos"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDocumentos;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="15">No se encontraron registros</td>
    </tr>

  </table>
</div>
<mat-paginator #paginatorDocumentos [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
<br>
<div align="right">
  <button [disabled]="loaderUpload" [class.spinner]="loaderUpload" color="primary" mat-raised-button
    (click)="adjuntarDocumentos()">Adjuntar documentos</button>
</div>
