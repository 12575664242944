import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Order } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";
import { IParam, generarParams } from "../../utilities/params";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PedidoService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerPedidos(codsuc, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    console.log('codsuc', codsuc)
    console.log('params', params)
    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidoserp/listar/${this._configurationService.obtenerCompaniaCliente()}/${codsuc}${paramsStr}`
    ).pipe(
      map((pedidos: any) => {
        return pedidos.map(pedido => {
          pedido['description'] = `${pedido.PCC_NUMPCC} | ${pedido.PCC_FECDOC} | ${pedido.cliente.cli_nomcli}`
          return pedido;
        })
      })
    );;
  }

  obtenerPedido(codsuc, codPedido): Observable<Order> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidoserp/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codsuc}/${codPedido}`
    );
  }

  registrarPedido(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidoserp/crear`,
      payload
    );
  }

  editarPedido(payload): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidoserp/modificar`,
      payload
    );
  }

  aprobacionPedidos(payload): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidoserp/aprobacion`,
      payload
    );
  }
}
