export class LineaNegocio {
  codcia  : string;
  codoln  : string;
  descri  : string;
  fecupd  : Date;
  indsta  : string;
  coduse  : string;

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
  }
}
