<app-pavso-title-section [title]="'Órdenes de importación'" [module]="'Importaciones'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>
  <br>
  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Tabla</div>
  </div>

  <div class="mat-elevation-z0 overflow-x">

    <table mat-table [dataSource]="dataSource" matSort matSort >
        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button matTooltip="Ver PDF" (click)="mostrarPdf(row.ocm_numocm)" mat-icon-button color="warn">
                    <mat-icon>picture_as_pdf</mat-icon>
                </button>
                <button matTooltip="Editar orden de compra" [routerLink]="['/modulo-importaciones/editar-orden-de-compra', row.ocm_numocm]" mat-icon-button color="primary">
                    <mat-icon>edit</mat-icon>
                </button>

            </td>
        </ng-container>

        <ng-container matColumnDef="ocm_numocm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. O/I </th>
            <td mat-cell *matCellDef="let row" data-label="Código"> {{row.ocm_numocm}} </td>
        </ng-container>

        <ng-container matColumnDef="toc_codtoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo O/I </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.toc_codtoc}} </td>
        </ng-container>

        <ng-container matColumnDef="ocm_fecocm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ocm_fecocm | date: 'dd/MM/yyyy': 'UTC'}} </td>
        </ng-container>

        <ng-container matColumnDef="ldc_dirldc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="5">No se encontraron registros "{{input.value}}"</td>
        </tr>

    </table>

  </div>
  <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  <br>
  <div class="row">
      <div class="col s12 m12 l12 xl6">
        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Tabla</div>
        </div>
        <div class="mat-elevation-z0 overflow-x">

            <table mat-table [dataSource]="dataSource1" matSort matSort>

                <ng-container matColumnDef="ver_req">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver Req.</th>
                    <td mat-cell *matCellDef="let row" data-label="Código"> {{row.ocm_numocm}} </td>
                </ng-container>

                <ng-container matColumnDef="num_req">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Req. </th>
                    <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.toc_codtoc}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_emision">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Emisión </th>
                    <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ocm_fecocm | date: 'dd/MM/yyyy': 'UTC'}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_pago">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Pago </th>
                    <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <ng-container matColumnDef="moneda">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
                    <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <ng-container matColumnDef="importe">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                    <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6" *ngIf="!loader1">No se encontraron registros</td>
                    <td class="mat-cell" colspan="6" *ngIf="loader1">
                        <mat-spinner diameter="40"></mat-spinner>
                    </td>
                </tr>

            </table>

            <mat-paginator #matPaginator1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
      <div class="col s12 m12 l12 xl6">
        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Tabla</div>
        </div>
        <div class="mat-elevation-z0 overflow-x">

            <table mat-table [dataSource]="dataSource2" matSort matSort>

                <ng-container matColumnDef="ver_doc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver Doc. </th>
                    <td mat-cell *matCellDef="let row" data-label="Ver Doc."> {{row.ocm_numocm}} </td>
                </ng-container>

                <ng-container matColumnDef="tipo_doc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
                    <td mat-cell *matCellDef="let row" data-label="Tipo Doc."> {{row.toc_codtoc}} </td>
                </ng-container>

                <ng-container matColumnDef="num_doc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Documento </th>
                    <td mat-cell *matCellDef="let row" data-label="Num. Documento"> {{row.ocm_fecocm | date: 'dd/MM/yyyy': 'UTC'}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_emision">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Emisión </th>
                    <td mat-cell *matCellDef="let row" data-label="Fec. Emisión"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <ng-container matColumnDef="moneda">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
                    <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <ng-container matColumnDef="importe">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                    <td mat-cell *matCellDef="let row" data-label="Importe"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <ng-container matColumnDef="abono">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Abono </th>
                    <td mat-cell *matCellDef="let row" data-label="Abono"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <ng-container matColumnDef="saldo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo </th>
                    <td mat-cell *matCellDef="let row" data-label="Saldo"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                    <td mat-cell *matCellDef="let row" data-label="Código"> {{row.ldc_dirldc}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="9" *ngIf="!loader2">No se encontraron registros</td>
                    <td class="mat-cell" colspan="9" *ngIf="loader2">
                        <mat-spinner diameter="40"></mat-spinner>
                    </td>
                </tr>

            </table>

            <mat-paginator #matPaginator2 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
  </div>
</div>
