<app-pavso-title-section [title]="'Consulta de asiento contable'" [module]="'Contabilidad'" [estado]="estado"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s1">
        <mat-form-field>
          <mat-label>Año</mat-label>
          <input [(ngModel)]="year" matInput placeholder="Año">
        </mat-form-field>
      </div>
      <div class="col s1">
        <mat-form-field>
          <mat-label>Mes</mat-label>
          <input [(ngModel)]="month" matInput placeholder="Mes">
        </mat-form-field>
      </div>
      <div class="col s4">
        <mat-form-field>
          <mat-label>Subdiario</mat-label>
          <mat-select>
            <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
              {{subdiario.sco_codsco}} - {{subdiario.sco_descri}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s2">
        <mat-form-field>
          <mat-label>Voucher</mat-label>
          <input matInput placeholder="Voucher">
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field>
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s2">
        <mat-form-field>
          <mat-label>T.C.</mat-label>
          <input matInput placeholder="T.C." appTipoCambioFormat step="any" min="0" type="number">
        </mat-form-field>
      </div>
      <div class="col s7">
        <mat-form-field>
          <mat-label>Glosa</mat-label>
          <input matInput placeholder="Glosa">
        </mat-form-field>
      </div>
    </div>

  </mat-card>
  <br>
  <div class="mat-elevation-z0 overflow-x">
    <table mat-table [dataSource]="dataSource" matSort >

      <ng-container matColumnDef="LDI_CORLDI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> It. </th>
        <td mat-cell *matCellDef="let row" data-label="It."> {{row.LDI_CORLDI}} </td>
      </ng-container>

      <ng-container matColumnDef="CCN_CODCCN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
        <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.CCN_CODCCN}} </td>
      </ng-container>

      <ng-container matColumnDef="ccn_descri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.ccn_descri}} </td>
      </ng-container>

      <ng-container matColumnDef="CLI_CODCLI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
        <td mat-cell *matCellDef="let row" data-label="Auxiliar"> {{row.CLI_CODCLI}} </td>
      </ng-container>

      <ng-container matColumnDef="CCS_CODCCS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
        <td mat-cell *matCellDef="let row" data-label="C. Costo"> {{row.CCS_CODCCS}} </td>
      </ng-container>

      <ng-container matColumnDef="TDO_CODTDO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
        <td mat-cell *matCellDef="let row" data-label="Doc."> {{row.TDO_CODTDO}} </td>
      </ng-container>

      <ng-container matColumnDef="LDI_DOCREF">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
        <td mat-cell *matCellDef="let row" data-label="Nro. Doc."> {{row.LDI_DOCREF}} </td>
      </ng-container>

      <ng-container matColumnDef="LDI_FECEMI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Emisión </th>
        <td mat-cell *matCellDef="let row" data-label="Emisión"> {{row.LDI_FECEMI}} </td>
      </ng-container>

      <ng-container matColumnDef="LDI_FECVEN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vencimiento </th>
        <td mat-cell *matCellDef="let row" data-label="Vencimiento"> {{row.LDI_FECVEN}} </td>
      </ng-container>

      <ng-container matColumnDef="TMO_CODTMO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
        <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.TMO_CODTMO}} </td>
      </ng-container>

      <ng-container matColumnDef="LDI_TIPCAM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Cambio </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo Cambio"> {{row.LDI_TIPCAM}} </td>
      </ng-container>

      <ng-container matColumnDef="LDI_INDDHA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
        <td mat-cell *matCellDef="let row" data-label="D/H"> {{row.LDI_INDDHA}} </td>
      </ng-container>

      <ng-container matColumnDef="LDI_IMPNAC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe M.N. </th>
        <td mat-cell *matCellDef="let row" data-label="Importe M.N."> {{row.LDI_IMPNAC}} </td>
      </ng-container>

      <ng-container matColumnDef="LDI_IMPMEX">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe M.E. </th>
        <td mat-cell *matCellDef="let row" data-label="Importe M.E."> {{row.LDI_IMPMEX}} </td>
      </ng-container>

      <ng-container matColumnDef="LDI_GLOLDI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
        <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.LDI_GLOLDI}} </td>
      </ng-container>

      <ng-container matColumnDef="CCO_CODCCO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
        <td mat-cell *matCellDef="let row" data-label="Cta. Cte."> {{row.CCO_CODCCO}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="14">No se encontraron registros</td>
      </tr>

    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>

<div class="row">
  <div class="col s6">
    <mat-form-field>
      <mat-label>Debe</mat-label>
      <input matInput placeholder="Debe">
    </mat-form-field>
  </div>
  <div class="col s6">
    <mat-form-field>
      <mat-label>Haber</mat-label>
      <input matInput placeholder="Haber">
    </mat-form-field>
  </div>
</div>
