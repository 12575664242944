import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { ordenerArregloJSONxLlave } from "@utils/array/order";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { Factura } from "src/app/models/ventas";
import { ConfigurationService, FacturaService } from "src/app/services";

@Component({
  selector: 'factura-list',
  templateUrl: './factura-list.component.html',
  styleUrls: ['./factura-list.component.scss']
})
export class FacturaListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'CCO_CODCCO', 'CLI_CODCLI', 'cli_nomcli', 'CCO_FECEMI', 'CCO_FECVEN', 'TMO_CODTMO', 'CCO_IMPDOC'];
  dataSource: MatTableDataSource<Factura>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  loaderData: boolean;
  loaderXml: boolean;

  facturas$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  factura$: Subscription;
  facturaXML$: Subscription;
  config$: Subscription;

  anioActividad: string;
  mesActividad: string;

  logo: string;
  dircia: string;

  constructor(
    private _facturaService: FacturaService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.anioActividad = state.year;
      this.mesActividad = state.month;
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
      this.dircia = state.dircia;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.obtenerFacturas();
    });
  }

  obtenerFacturas(): void {
    this.loaderData = true;

    this.facturas$ = this._facturaService.obtenerFacturas({
      params: [
        {
          param: 'anio',
          value: this.anioActividad
        },
        {
          param: 'mes',
          value: this.mesActividad
        }
      ]
    }).subscribe(
      facturas => {
        console.log('facturas', facturas)
        this.loaderData = false;
        this.dataSource = fillTable( ordenerArregloJSONxLlave('CCO_CODCCO', facturas), this.paginator, this.sort);
      }
    )
  }

  generarXML(row): void {
    console.log('generarxml')
    console.log('row', row)
    this.loaderXml = true;

    this.factura$ = this._facturaService.obtenerFactura(row.CCO_CODCCO).subscribe(
      factura => {
        factura['CLI_NOMCLI'] = row.cliente.cli_nomcli
        console.log('factura', factura)
        const payloadFactura = this._facturaService.generarPayload(factura)

        this.facturaXML$ = this._facturaService.enviarAValidacionSUNAT(payloadFactura).subscribe(
          xml => {
            console.log('xml', xml)
            const blob = new Blob([xml], { type: 'application/xml' });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = payloadFactura.NumeroRUC + "-" + payloadFactura.TipoDocumento + "-" + payloadFactura.NumeroDocumento;
            a.click();
            window.URL.revokeObjectURL(url);

            this.loaderXml = false;
          },
          error => {
            this._snackBarService.showError('Error al generar XML', 'Ok');
            this.loaderXml = false;
          }
        );
      },
      error => {
        this._snackBarService.showError('Error al obtener factura', 'Ok');
        this.loaderXml = false;
      }
    )

  }

  generarPDF(cco_codcco: string): void {
    const headers = {
      ruc: this._configurationService.obtenerClienteId(),
      razonSocial: this._configurationService.obtenerNombreCliente(),
      logo: this.logo,
      nomcia: this._configurationService.obtenerNombreCompaniaCliente(),
      dircia: this.dircia,
    }
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.facturas$,
      this.loading$,
      this.periodo$,
      this.factura$,
      this.facturaXML$,
    ]);
  }

}
