import { Component, DoCheck, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'proveedor-dialog',
  template: `
    <div mat-dialog-content>

      <app-proveedor-form [id]="data.id" [isDialog]="data.isDialog" [idARegistrar]="data.idARegistrar" (closeDialog)="cerrar($event)" [ngClassTitle]="''" [ngStyleControls]="{top: '0px'}"></app-proveedor-form>

    </div>
  `,
  styleUrls: ['proveedor-dialog.component.scss']
})
export class ProveedorDialog {
  constructor(
    public dialogRef: MatDialogRef<ProveedorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data', data)
  }

  cerrar(event): void {
    if(event) this.dialogRef.close();

  }
}
