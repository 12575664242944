<app-pavso-title-section [title]="'Control SIRE Compras'" [module]="'Contabilidad'"
  [ngClass]="'pav-form'"></app-pavso-title-section>
<mat-tab-group>
  <mat-tab label="Propuesta SUNAT" labelClass="mat-tab-label-0-1">
    <form action="">
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l4">
            <div [ngStyle]="{display: 'flex'}">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Ticket</mat-label>
                <input matInput placeholder="Ej. 00000">
              </mat-form-field>
              <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
                <mat-icon>manage_search</mat-icon>
              </button>
            </div>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Moneda</mat-label>
              <mat-select [(ngModel)]="filtro.moneda" name="moneda">
                <mat-option [value]="'SO'">
                  Soles
                </mat-option>
                <mat-option [value]="'DO'">
                  Dólares
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col s12 m12">
            <button
              [ngStyle]="{height: '45px', width: isMobile || isTablet ? '100%': '180px', background: '#F7C91B', color: '#222'}"
              mat-stroked-button (click)="descargarSIRE()" color="primary" [class.spinner]="loaderSIRE"
              [disabled]="loaderSIRE || loaderData" matTooltip="Descargar SIRE">
              <mat-icon>cloud_download</mat-icon>
              Descargar SIRE
            </button>

            <button
              [ngStyle]="{height: '45px', width: isMobile || isTablet ? 'auto': 'auto', background: '#6cd6df', color: '#222', marginLeft: '5px'}"
              mat-stroked-button (click)="errorRegistrosSIRESunat()" color="primary" [disabled]="loaderData"
              matTooltip="Error registros SIRE">
              <mat-icon>warning</mat-icon>
              Error registros SIRE SUNAT
            </button>
          </div>
        </div>
      </mat-card>

      <div class="mat-elevation-z0">
        <table-title-action title="Detalle" url="/modulo-contabilidad/centro-de-costo" entity="" [params]="['0']"
          (clickDescargar)="descargarExcel()"></table-title-action>
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <div class="overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <div class="pav-btns-container">
                  <div class="pav-btn-circle-edit" matTooltip="Editar"
                    [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código" class="td-responsive"> {{i + 1}}
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_carsun">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CAR SUNAT </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.psc_carsun}}
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_fecemi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecemi | date:
                'dd/MM/YYYY': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_fecven">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecven | date:
                'dd/MM/YYYY': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_tipdoc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipdoc}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_serdoc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie Documento </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_serdoc}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_anodua">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_anodua}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_numini">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. Inicial </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numini}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_numfin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. Final </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numfin}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_tipide">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento Identidad </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipide}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_numide">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento Identidad </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numide}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_proraz">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_proraz}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_bigrdg">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> BI Gravado DG </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_bigrdg.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_igvdg">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV / IPM DG </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_igvdg.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_bigdgnd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> BI Gravado DGNG </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_bigdgnd.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_igvdgng">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV / IPM DGNG </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_igvdgng.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_bigrdng">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> BI Gravado DNG </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_bigrdng.toFixed(2)}}
                </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="psc_igvdng">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV / IPM DNG </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_igvdng.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_vaadng">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Adq. NG </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_vaadng.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_impisc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ISC </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_impisc.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_icbper">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ICBPER </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_icbper.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_otrtri">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Tributos </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_otrtri.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_imptot">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_imptot.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_codtmo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_codtmo}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_tipcam">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de cambio </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_tipcam.toFixed(3)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="psc_fecref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de emisión doc. Modificado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecref}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_docref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. Modificado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_docref}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_serref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie Doc. Modificado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_serref}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_coddam">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> DAM O DSI </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_coddam}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_numref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Modificado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numref}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clabss">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Clasificación BSS y SSS </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clabss}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_idprop">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Proyecto Operadores </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_idprop}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_porpar">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Porcentaje Participación </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_porpar}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_impimb">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IMB </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_impimb}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_carori">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CAR Original </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_carori}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_detracc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Detracción </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_detracc}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_tipnot">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Nota </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipnot}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_estcom">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Est. Comp. </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_estcom}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_incal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Incal </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_incal}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_coduse">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_coduse}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_fecupd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Act. </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecupd}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU1 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu1}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu2">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU2 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu2}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu3">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU3 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu3}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU4 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu4}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU5 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu5}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu6">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU6 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu6}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu7">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU7 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu7}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu8">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU8 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu8}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu9">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU9 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu9}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU10 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu10}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU11 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu11}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU12 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu12}} </td>
            </ng-container>
            <ng-container matColumnDef="psc_clu13">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU13 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu13}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu14">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU14 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu14}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu15">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU15 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu15}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu16">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU16 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu16}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu17">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU17 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu17}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu18">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU18 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu18}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu19">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU19 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu19}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu20">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU20 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu20}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu21">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU21 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu21}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu22">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU22 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu22}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu23">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU23 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu23}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu24">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU24 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu24}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu25">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU25 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu25}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu26">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU26 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu26}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu27">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU27 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu27}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu28">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU28 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu28}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu29">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU29 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu29}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu30">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU30 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu30}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu31">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU31 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu31}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu32">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU32 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu32}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu33">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU33 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu33}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu34">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU34 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu34}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu35">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU35 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu35}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu36">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU36 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu36}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu37">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU37 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu37}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu38">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU38 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu38}} </td>
            </ng-container>

            <ng-container matColumnDef="psc_clu39">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU39 </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu39}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="81">No se encontraron registros </td>

            </tr>

          </table>

        </div>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Cantidad ítems</mat-label>
              <input readonly [(ngModel)]="dataSource.data.length" name="cantidad_items" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total BI Gravado DG</mat-label>
              <input readonly [(ngModel)]="totalBIGravadoDG" name="totalBIGravadoDG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Total IGV/IPM DG</mat-label>
              <input readonly [(ngModel)]="totalIGVIPMDG" name="totalIGVIPMDG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total BI Gravado DGNG</mat-label>
              <input readonly [(ngModel)]="totalBIGravadoDGNG" name="totalBIGravadoDGNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Total IGV/IPM DGNG</mat-label>
              <input readonly [(ngModel)]="totalIGVIPMDGNG" name="totalIGVIPMDGNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total BI Gravado DNG</mat-label>
              <input readonly [(ngModel)]="totalBIGravadoDNG" name="totalBIGravadoDNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Total IGV/IPM DNG</mat-label>
              <input readonly [(ngModel)]="totalIGVIPMDNG" name="totalIGVIPMDNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total Valor Adq. NG</mat-label>
              <input readonly [(ngModel)]="totalValorAdqNG" name="totalValorAdqNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Total ISC</mat-label>
              <input readonly [(ngModel)]="totalISC" name="totalISC" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total ICBPER</mat-label>
              <input readonly [(ngModel)]="totalICPPER" name="totalICPPER" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Total Otros Tributos</mat-label>
              <input readonly [(ngModel)]="totalOtrosTributos" name="totalOtrosTributos" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </form>

  </mat-tab>
  <mat-tab label="Reemplazo PAV" labelClass="mat-tab-label-0-2">
    <div class="mat-elevation-z0">
      <table-title-action title="Todos los centros" url="/modulo-contabilidad/centro-de-costo" entity=""
        [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource2" matSort>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar"
                  [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="f01">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="f02">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CUO </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.ccs_descri}}
            </td>
          </ng-container>

          <ng-container matColumnDef="f03">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f04">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f05">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f06">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f07">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f08">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año DUA </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f09">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total no otorga Crédito </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. Identidad </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f12">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. RUC / Doc. Identidad </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f13">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Razon Social </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f14">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imp. Adq. Gravadas </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f15">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV o Imp. Municipal </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f16">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imp. Adq. No Gravadas </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>


          <ng-container matColumnDef="f17">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV No Gravadas </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f18">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imp. sin Derecho a Crédito Fiscal </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f19">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV sin Derecho </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f20">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Adq. No Gravadas </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f21">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ISC </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f22">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IBP </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f23">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Tributos </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f24">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f25">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Cambio </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f26">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Comp. Pago </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f27">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Comprob. Modifica </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f28">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie Modifica </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f29">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de DUA </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f30">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f31">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f32">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Act. </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros </td>

          </tr>

        </table>

      </div>


      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>


  </mat-tab>
  <mat-tab label="Documentos en PAV y no en SUNAT" labelClass="mat-tab-label-0-3">
    <div class="mat-elevation-z0">
      <table-title-action title="Todos los centros" url="/modulo-contabilidad/centro-de-costo" entity=""
        [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource3" matSort>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar"
                  [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="f01">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="f02">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.ccs_descri}}
            </td>
          </ng-container>

          <ng-container matColumnDef="f03">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f04">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f05">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f06">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f07">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f08">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f09">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CUO </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Observación </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros </td>

          </tr>

        </table>

      </div>


      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </mat-tab>
  <mat-tab label="Documentos en SUNAT y no en PAV" labelClass="mat-tab-label-0-4">
    <div class="mat-elevation-z0">
      <table-title-action title="Todos los centros" url="/modulo-contabilidad/centro-de-costo" entity=""
        [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource4" matSort>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar"
                  [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="f01">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="f02">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CAR SUNAT </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.ccs_descri}}
            </td>
          </ng-container>

          <ng-container matColumnDef="f03">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f04">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f05">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f06">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año DUA </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f07">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f08">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Doc Identidad </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f09">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros </td>

          </tr>

        </table>

      </div>


      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </mat-tab>
</mat-tab-group>
