import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/models';
import { ConfigurationService } from 'src/app/services';
import { TipoInventarioService } from 'src/app/services/api/almacen/configuracion/tipo-inventario.service';
import { UnidadMedidaService } from 'src/app/services/api/almacen/configuracion/unidad-medida.service';
import { ColorService } from 'src/app/services/api/almacen/maestros/color.service';
import { FamiliaService } from 'src/app/services/api/almacen/maestros/familia.service';
import { SubfamiliaService } from 'src/app/services/api/almacen/tabla-apoyo/subfamilia.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';

@Component({
  selector: 'app-producto-form',
  templateUrl: './producto-form.component.html',
  styleUrls: ['./producto-form.component.css']
})
export class ProductoFormComponent implements OnInit, OnDestroy {

  producto: Product;

  loaderData: boolean;
  loaderReg: boolean;

  tiposDeInventario: any[] = [];
  familias: any[] = [];
  subfamilias: any[] = [];
  colores: any[] = [];
  unidades: any[] = [];
  tipoInventario$: Subscription;
  familia$: Subscription;
  subfamilia$: Subscription;
  color$: Subscription;
  unidad$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _dialogService: DialogService,
    private _productoService: ProductoService,
    private _tipoInventarioService: TipoInventarioService,
    private _familiaService: FamiliaService,
    private _subfamiliaService: SubfamiliaService,
    private _colorService: ColorService,
    private _unidadMedidaService: UnidadMedidaService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    public _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {

    this.producto = new Product();
    this.producto.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.producto.prd_coduse = this._configurationService.obtenerIdUsuario();

  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });

  }

  tiposInventario$: Subscription;
  familias$: Subscription;
  subfamilias$: Subscription;
  colores$: Subscription;
  unidades$: Subscription;

  loadData(): void {
    this.tiposInventario$ = this._tipoInventarioService.obtenerTiposDeInventario().subscribe(
      tiposInventario => {
        this.tiposDeInventario = tiposInventario;

        this.familia$ = this._familiaService.obtenerFamiliasProducto().subscribe(
          familias => {
            this.familias = familias;

            this.subfamilia$ = this._subfamiliaService.obtenerSubFamilias(this.producto.fpr_codfpr).subscribe(
              subfamilias => {
                this.subfamilias = subfamilias

                this.color$ = this._colorService.obtenerColores().subscribe(
                  colores => {
                    this.colores = colores;

                    this.unidad$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                      unidades => {
                        this.unidades = unidades;
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener unidades', 'OK')
                      }
                    )

                  },
                  error => {
                    this._snackBarService.showError('Error al obtener colores', 'OK')

                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener subfamilias', 'OK')

              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener familias', 'OK')

          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de inventario', 'OK')

      }
    )

  }

  seleccionarFamilia(idfamilia): void {
    this._subfamiliaService.obtenerSubFamilias(idfamilia).subscribe(
      response => this.subfamilias = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  registrarProducto(f: NgForm): void {
    this._productoService.registrarProducto(this.producto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Producto Registrado', '300px', '', '');

        f.resetForm();
      },

    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/productos']);
  }

  ngOnDestroy(): void {

    unsubscribeSubscription([
      this.tipoInventario$,
      this.familia$,
      this.subfamilia$,
      this.color$,
      this.unidad$,
    ])

  }

  exito(): void {
    this._dialogService.openDialog(SuccessComponent, 'Servicio Registrado', '300px', '', '');

  }

}
