export class DocumentSerie {
  cia_codcia: string;
  tdo_codtdo: string;
  sdo_codsdo: string;
  sdo_docini: number;
  sdo_docfin: number;
  sdo_numlin: number;
  ldc_codldc: string;
  sdo_indsta: string;
  sdo_coduse: string;
  sdo_fecupd: Date;
  sdo_indfgu: number;
  sdo_datgui: number;
  suc_codsuc: string;
  sdo_tabele: number;
  sdo_facele: number;

  constructor(
  ){
    this.sdo_fecupd = new Date();
    this.sdo_indsta = '1';
  }
}
