<app-pavso-title-section [title]="'Tipo de cambio'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarTipoCambioCierre(f)" (keydown.enter)="$event.preventDefault()">
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s4">
          <mat-form-field>
            <mat-label>Período</mat-label>
            <input [(ngModel)]="tipoCambio.ctc_fecupd" name="ctc_fecupd" matInput [matDatepicker]="dp" [formControl]="date">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event)"
                            (monthSelected)="chosenMonthHandler($event, dp)"
                            panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s4">
          <mat-form-field>
            <mat-label>Compra</mat-label>
            <input min="0" matInput placeholder="Compra" [(ngModel)]="tipoCambio.ctc_tcacom" name="ctc_tcacom" type="number">
          </mat-form-field>
        </div>
        <div class="col s4">
          <mat-form-field>
            <mat-label>Venta</mat-label>
            <input min="0" matInput placeholder="Venta" [(ngModel)]="tipoCambio.ctc_tcaven" name="ctc_tcaven" type="number">
          </mat-form-field>
        </div>
      </div>
    </mat-card>

    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
  </form>

</div>
