<app-pavso-title-section [title]="'Grupo de activo'" [module]="'Activo fijo'" [usuario]="usuario" [fecha]="fecha" [ngClass]="'pav-form'" [estado]="grupoActivo.gac_indsta"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Grupo de Activo</mat-label>
            <input type="text" [(ngModel)]="grupoActivo.gac_codgac" matInput placeholder="Grupo de Activo" name="gac_codgac" required
              >
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo de activo</mat-label>
            <mat-select [(ngModel)]="grupoActivo.taf_codtaf" required name="taf_codtaf">
              <mat-option *ngFor="let tipo of tiposActivo" [value]="tipo.codigo_tipoacti">
                {{tipo.descri_tipoacti}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="grupoActivo.gac_deslar" matInput placeholder="Descripción" name="gac_deslar" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción Corta</mat-label>
            <input type="text" [(ngModel)]="grupoActivo.gac_descor" matInput placeholder="Descripción Corta" name="gac_descor" required
              >
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-activo-fijo/grupos-de-activo">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
