import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ListaRequisito, RegiListOrd } from 'src/app/models/crm/lista-requisito';
import { ConfigurationService } from 'src/app/services';
import { ApiCRMOperacionesListaRequisito } from 'src/app/services/api/crm/operaciones/crm.operaciones.lista-requisito.service';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';

@Component({
  selector: 'app-listar-requisitos',
  templateUrl: './listar-requisitos.component.html',
  styleUrls: ['./listar-requisitos.component.css']
})
export class ListarRequisitosComponent implements OnInit, OnDestroy {
  loaderData: boolean;
  loaderReg: boolean;

  displayedColumns: string[] = ['acciones', 'item', 'cod_req', 'requisito', 'completo', 'observaciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  fechaRegistro: Date = new Date();
  usuario: string;
  minDate: Date = new Date();

  listaRequisito: ListaRequisito;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string = 'general.button.store';

  listaRequisito$: Subscription;
  sendForm$: Subscription;

  requisitos: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _configurationService: ConfigurationService,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _apiListaRequisitoCrmService: ApiCRMOperacionesListaRequisito,
    public dialogRef: MatDialogRef<ListarRequisitosComponent>,
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.listaRequisito = new ListaRequisito();
    this.listaRequisito.codcia = this._configurationService.obtenerCompaniaCliente();
    console.log('data', this.data)
    this.listaRequisito.idtkt = this.data.idtkt;
    this.listaRequisito.idset = this.data.idset;
    console.log('this.listaRequisito', this.listaRequisito)
  }

  ngOnInit(): void {
    this.obtenerListaRequisito();
  }

  obtenerListaRequisito(): void {
    this.listaRequisito$ = this._apiListaRequisitoCrmService.obtenerListaRequisito(this.listaRequisito.idtkt).subscribe(
      lista => {
        console.log('LISTA DE REQUISITO', lista)
        if(lista.length > 0) {
          this.listaRequisito = lista[0];
          this.listaRequisito.idset = this.data.idset;
          this.dataSource = fillTable(this.listaRequisito.REGI_LIST_ORD, this.paginator, this.sort);
          this.btnName = 'general.button.update';
          return;
        }

        this.btnName = 'general.button.store';

      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  agregarItem() {

    let item = new RegiListOrd();

    this.listaRequisito.REGI_LIST_ORD.push(item);

    this.listaRequisito.REGI_LIST_ORD.forEach((element, key) => {
      element['corord'] = key > 8? (key + 1).toString(): `0${key + 1}`;
    })

    this.dataSource = fillTable(this.listaRequisito.REGI_LIST_ORD, this.paginator, this.sort);
  }

  editarItem(row) {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row) {
    if(!row.codreq || !row.desreq) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarItem(row) {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.listaRequisito.REGI_LIST_ORD.filter(item => item.corord != row.corord);
        filtered.forEach((element, key) => {
          element['corord'] = key > 8? (key+1).toString(): `0${key + 1}`;
        })

        this.listaRequisito.REGI_LIST_ORD = filtered;

        this.dataSource = fillTable(filtered, this.paginator, this.sort);
      }
    })
  }

  enviarFormulario(f: NgForm): void {
    console.log(this.listaRequisito);

    this.loaderReg = true;

    this.establecerIndicadores();

    this.btnName == this.buttonsName.BTN_STORE ? this.registrarListaRequisito(f): this.actualizarListaRequisito(f)
  }

  establecerIndicadores(): void {
    this.listaRequisito.REGI_LIST_ORD.forEach(item => {
      item.indter ? item.indter = 1: item.indter = 0;
    })
  }

  registrarListaRequisito(f: NgForm): void {

    this.sendForm$ = this._apiListaRequisitoCrmService.registrarListaRequisito(this.listaRequisito).subscribe(
      response => {
        this._snackBarService.showSuccess('Lista de requisito registrado', 'Ok');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )

  }

  actualizarListaRequisito(f: NgForm): void {

    this.sendForm$ = this._apiListaRequisitoCrmService.actualizarListaRequisito(this.listaRequisito).subscribe(
      response => {
        this._snackBarService.showSuccess('Lista de requisito actualizado', 'Ok');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.listaRequisito$,
      this.sendForm$,
    ]);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
