<div class="pav-title-section" [ngClass]="ngClass" [ngStyle]="{display: isMobile || isTablet ? 'block': 'flex'}">

  <div [ngStyle]="{display: isMobile || isTablet ? 'block': 'flex', alignItems: 'center'}">
    <h1 class="pav-title animate__animated animate__bounceIn animate__fast">{{title}}</h1>

    <span class="pav-title-slash">/</span>

    <h2 class="pav-title-module">{{module }}</h2>
  </div>

  <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
    <ng-container *ngIf="estado">
      <div class="pav-title-vigente" *ngIf="estado == '1' || estado == 1">VIGENTE</div>
      <div class="pav-title-anulado" *ngIf="estado == '0' || estado == 0">ANULADO</div>
      <div class="pav-title-anulado" *ngIf="estado == '6' || estado == 6">ANULADO</div>
    </ng-container>
    &nbsp;
    <ng-container *ngIf="establecimiento != ''">
      <div class="pav-title-establecimiento">
        <mat-icon [ngStyle]="{marginTop: '3px', fontSize: '20px'}">storefront</mat-icon>
        {{establecimientoDescri}}
      </div>
    </ng-container>
    &nbsp;
    <ng-container *ngIf="estadoAprOrdenCompra">
      <div class="pav-title-establecimiento">
        <mat-icon [ngStyle]="{marginTop: '3px', fontSize: '20px'}">verified</mat-icon>
        {{estadoAprOrdenCompra == 1 ? 'Aprobado': 'Pendiente'}}
      </div>
    </ng-container>
    <div class="pav-additional-info" [ngStyle]="{marginLeft: '5px'}">
      {{otraInformacion}}
    </div>
  </div>

  <div [ngStyle]="{display: isMobile || isTablet ? 'flex': 'block'}">
    <div class="pav-title-user">
      {{usuario | titlecase}}
    </div>

    <div class="pav-title-date">
      <span class="pavso-field-user-date">{{fecha | date: 'dd/MM/yyyy, h:mm a'}}</span>
    </div>
  </div>
</div>
<ng-content></ng-content>
