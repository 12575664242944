import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@shared/services/snackbar.service';
import * as pdfBuilder from '@utils/pdfmaker/builder/compras/operaciones/registro-compra.pdfmaker';
import { ConfigurationService } from 'src/app/services';
import { UnidadMedidaService } from 'src/app/services/api/almacen/configuracion/unidad-medida.service';
import { FileService } from 'src/app/services/api/compras/operaciones/file.service';
import * as xml2js from 'xml2js';

export class RecepcionFile {
  id: number;
  cia_codcia: string;
  ccr_codccr: string;
  cco_codcco: string;
  cfe_codext: string;
  cfe_arcfis: string;
  cfe_nomarc: string;
  cfe_coduse: string;
  cfe_fecupd: Date;

  constructor() {
    this.cfe_fecupd = new Date();
  }
}

@Component({
  selector: 'upload',
  templateUrl: 'upload-xml-recepcion.component.html',
  styleUrls: ['upload-xml-recepcion.component.css']
})

export class UploadXMLRecepcionDialog implements OnInit {

  recepcionXML: RecepcionFile;

  loaderData: boolean;

  unidades: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<UploadXMLRecepcionDialog>,
    private readonly _configurationService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _fileService: FileService,
    private readonly _snackBarService: SnackBarService,
    private readonly _unidadMedidaService: UnidadMedidaService
  ) {
    this.initialize();
  }

  initialize(): void {
    this.recepcionXML = new RecepcionFile();
    this.recepcionXML.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.recepcionXML.cfe_coduse = this._configurationService.obtenerIdUsuario();
    this.recepcionXML.cfe_codext = 'xml';
    this.recepcionXML.ccr_codccr = this.data;
    this.recepcionXML.id = 0;

  }

  ngOnInit(): void {
    this.obtenerXML();
  }

  obtenerXML(): void {
    this.loaderData = true;

    this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
      unidades => {
        console.log('unidades', unidades)
        this.unidades = unidades;
        this._fileService.listarDocumentos(this.data).subscribe(
          response => {
            console.log('xmls', response)
            if(response.length > 0) {
              this.recepcionXML = response.find(item => item.cfe_codext == 'xml');
            }
            this.loaderData = false;
          },
          error => {
            this._snackBarService.showError('Error al obtener xml','Ok')
            this.loaderData = false;
          }
        )

      },
      error => {
        this._snackBarService.showError('Error al obtener unidades de medida', 'Ok')
        this.loaderData = false;
      }
    )

  }

  xmlContent: string | null = null;
  selectedFile: File | null = null;
  fileName: string | null = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      this.fileName = this.selectedFile.name; // Guardar el nombre del archivo
      this.recepcionXML.cfe_nomarc = this.selectedFile.name; // Guardar el nombre del archivo

    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = reader.result as string;
      this.xmlContent = text;
      this.recepcionXML.cfe_arcfis = btoa(unescape(encodeURIComponent(text)))
      console.log('convertido')
    };
    reader.readAsText(this.selectedFile);
  }

  onUpload(): void {
    if (!this.selectedFile || !this.recepcionXML.cfe_arcfis) {
      // this._snackBarService.showError('Por favor selecciona un archivo XML primero.', 'Ok');
      this.dialogRef.close();
      return;
    }

    this._fileService.registrarDocumento(this.recepcionXML).subscribe(
      response => {
        this._snackBarService.showSuccess('Archivo XML cargado correctamente.', 'Ok');
        this.dialogRef.close();
      },
      error => {
        this._snackBarService.showError(error.error.error, 'Ok');
      }
    )
  }

  descargarXML(): void {

    // Decode Base64 to raw binary data
    const decodedData = atob(this.recepcionXML.cfe_arcfis);

    // Convert the binary data to a Uint8Array
    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: "application/xml" });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = this.recepcionXML.cfe_nomarc;

    // Append the link to the document and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);

  }

  verFormatoPDF(): void {

    const parser = new xml2js.Parser({ strict: false, trim: true });

    parser.parseString(atob(this.recepcionXML.cfe_arcfis), (err, result) => {

      console.log('JSON PDF', result)

      if(result.INVOICE) {

        const { INVOICE } = result;

        const data = {
          numdoc: INVOICE['CBC:ID'][0],
          razonSocial: INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYLEGALENTITY'][0]['CBC:REGISTRATIONNAME'][0],
          numruc: INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'] ? INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'],
          domicilioFiscal: INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYLEGALENTITY'][0]['CAC:REGISTRATIONADDRESS'][0]['CAC:ADDRESSLINE'][0]['CBC:LINE'][0],
          ciudad: INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYLEGALENTITY'][0]['CAC:REGISTRATIONADDRESS'][0]['CBC:CITYNAME'][0],
          distrito: INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYLEGALENTITY'][0]['CAC:REGISTRATIONADDRESS'][0]['CBC:DISTRICT'][0],
          departamento: INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYLEGALENTITY'][0]['CAC:REGISTRATIONADDRESS'][0]['CBC:COUNTRYSUBENTITY'][0],
          fechaEmision: INVOICE['CBC:ISSUEDATE'][0],
          formaPago: INVOICE['CAC:PAYMENTTERMS'][0]['CBC:PAYMENTMEANSID'][0],
          nombreCliente: INVOICE['CAC:ACCOUNTINGCUSTOMERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYLEGALENTITY'][0]['CBC:REGISTRATIONNAME'][0],
          rucCliente: INVOICE['CAC:ACCOUNTINGCUSTOMERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'],
          direccionCliente: INVOICE['CAC:ACCOUNTINGCUSTOMERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYLEGALENTITY'][0]['CAC:REGISTRATIONADDRESS'][0]['CAC:ADDRESSLINE'][0]['CBC:LINE'][0],
          moneda: INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] == 'PEN' ? 'SOLES': 'DLARES',
          detalle: INVOICE['CAC:INVOICELINE'].map(item => ({
            cantidad: item['CBC:INVOICEDQUANTITY'][0]['_'],
            unidadMedida: this.unidades.find(unidad => unidad.ume_codofi == item['CBC:INVOICEDQUANTITY'][0]['$']['UNITCODE']).ume_descri ,
            descripcion: item['CAC:ITEM'][0]['CBC:DESCRIPTION'][0],
            valorUnitario: item['CAC:PRICE'][0]['CBC:PRICEAMOUNT'][0]['_'],
            icbper: '0.00'})),
          importeLetras: INVOICE['CBC:NOTE'][0]['_'],
          subtotal: INVOICE['CAC:TAXTOTAL'][0]['CAC:TAXSUBTOTAL'][0]['CBC:TAXABLEAMOUNT'][0]['_'],
          anticipo: INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PREPAIDAMOUNT'][0]['_'],
          total: INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_'],
          igv: INVOICE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_'],
          valorVenta: INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_'],
        }

        const detalle = INVOICE['CAC:INVOICELINE']

        pdfBuilder.generarPDFXMLSIRE({}, data)

      } else {
        this._snackBarService.showError('por el momento se imprimen Faturas, estamos implementando los otros casos', 'Ok')
      }


    });


  }

  eliminarXML(): void {
    if(this.recepcionXML.id == 0) {
      return;
    }

    this._fileService.eliminarDocumento(this.recepcionXML.id).subscribe(
      response => {
        this._snackBarService.showSuccess('Archivo XML eliminado correctamente.', 'Ok');
        this.initialize();
        this.obtenerXML();
      },
      error => {
        this._snackBarService.showError(error.error.error, 'Ok');
      }
    )
  }

}
