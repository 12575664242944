import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PartDetalleGuia, Kardex, PartDetalleMap, DetalleLote, DetalleSerie } from 'src/app/models';
import { AuthenticationService, ConfigurationService, VentasService, AlmacenService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { KardexService } from 'src/app/services/api/almacen/operaciones/kardex.service';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { forkObs } from '@utils/observables/fork';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';

@Component({
  selector: 'app-parte-salida',
  templateUrl: './parte-salida.component.html',
  styleUrls: ['./parte-salida.component.css']
})
export class ParteSalidaComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'item', 'codigo', 'descripcion', 'um', 'cantidad_oc', 'cantidad', 'lote_ref_pro', 'costo_ume' , 'costo_umn', 'sec', 'imp_me_prod', 'imp_mn_prod' , 'glosa'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsLote: string[] = ['acciones', 'lote', 'cantidad', 'fec_fabric', 'fec_vcto', 'observacion'];
  dataSourceLote: MatTableDataSource<any>;

  @ViewChild("paginatorLote") paginatorLote: MatPaginator;
  @ViewChild("sortLote") sortLote: MatSort;

  displayedColumnsSerie: string[] = ['acciones', 'serie', 'fec_fabric', 'fec_vcto'];
  dataSourceSerie: MatTableDataSource<any>;

  @ViewChild("paginatorSerie") paginatorSerie: MatPaginator;
  @ViewChild("sortSerie") sortSerie: MatSort;

  displayedColumnsGuia: string[] = ['item', 'codigo', 'descripcion', 'um', 'cantidad', 'costo_mn', 'costo_me', 'importe_mn', 'imporet_me', 'glosa', 'sec', 'lote_stock'];
  dataSourceGuia: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorGuia: MatPaginator;
  @ViewChild(MatSort) sortGuia: MatSort;

  loaderGuia = false;
  loaderReg = false;

  parteSalida: Kardex;
  almacenes: any[] = [];
  motivos: any[] = [];
  choferes: any[] = [];
  unidades: any[] = [];
  auxiliares: any[] = [];
  tiposDocumento: any[] = [];
  transportistas: any[] = [];
  clientes: any[] = [];
  requerimientos: any[] = [];
  series: any[] = [];
  seriesGuia: any[] = [];
  centros: any[] = [];
  invoices: any[] = [];
  productos: any[] = [];

  numeroOrdenDisabled = true;

  nroDoc: any;

  importeTotalMN: number = 0;
  importeTotalME: number = 0;
  costoTotalMN: number = 0;
  costoTotalME: number = 0;

  loaderData: boolean = false;

  minDate: Date;

  tipoCambio$: Subscription;
  correlativoGuia$: Subscription;
  motivosParteMov$: Subscription;
  validGuiaRemision$: Subscription;
  addMovimiento$: Subscription;
  productsReq$: Subscription;
  loading$: Subscription;
  obtenerMovimient$: Subscription;
  serieDoc$: Subscription;

  isDetalleGuia: boolean;

  year: string;
  month: string;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  anio;
  mes;
  almacen;
  tipo;
  numeroParte;

  constructor(
    public dialog: MatDialog,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _almacenService: AlmacenService,
    private _kardexService: KardexService ,
    private store: Store<PavsoState>,
    private _authService: AuthenticationService,
    private _snackBarService: SnackBarService,
    private _router: Router,
    private _clienteService: ClienteService,
    private _ventasService: VentasService,
    private _productoService: ProductoService,
    private _activatedRoute: ActivatedRoute
  ) {

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);

    this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

    this.parteSalida = new Kardex();
    this.parteSalida.detalle_map = [];
    this.parteSalida.detalle_guia = [new PartDetalleGuia()];

    this.parteSalida.tdo_codtdo = "SAL";
    // this.parteSalida.tdo_codcom = "GRE";
    this.parteSalida.mac_coduse = this._authService.getUsuarioSistema();
    this.parteSalida.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    // this.parteSalida.mac_indnue = 1;
    this.parteSalida.mac_coduse = this._authService.getUsuarioSistema();

    this.parteSalida.detalle_guia[0].cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.parteSalida.detalle_guia[0].grc_indnue = 1;
    this.parteSalida.detalle_guia[0].ano_codano = this.parteSalida.ano_codano;
    this.parteSalida.detalle_guia[0].mes_codmes = this.parteSalida.mes_codmes;
    this.parteSalida.detalle_guia[0].grc_coduse = this._authService.getUsuarioSistema();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({year, month, almacen, tipo, numeroParte}) => {
      this.anio = year;
      this.mes = month;
      this.almacen = almacen;
      this.tipo = tipo;
      this.numeroParte = numeroParte;
    })

    this.loadMaestros();

    this.btnName = this.numeroParte == '0'? this.buttonsName.BTN_STORE: this.buttonsName.BTN_UPDATE;
  }

  loadMaestros(): void {
    this.loaderData = true;

    forkObs(
      this._almacenService.obtenerAlamacenes(),
      this._almacenService.listarChoferes(),
      this._ventasService.listarUnidadesTransporte(),
      this._clienteService.obtenerClientes(),
      this._almacenService.obtenerTipoDocumentoMov(),
      this._almacenService.listarSerieDocumentos(this.tipo),
      this._ventasService.obtenerTipoCambio(formatDateWithDash(this.parteSalida.mac_fecmac)),
      this._almacenService.listarRequerimientoMateriales(),
      this._almacenService.obtenerTransportistas(),
      this._almacenService.obtenerInvoices(),
      this._ventasService.obtenerMotivosMovimiento(),
      this._productoService.obtenerProductos()
    ).then(data => {
      this.almacenes = data[0];
      this.choferes = data[1];
      this.unidades = data[2];
      this.auxiliares = data[3].filter(item => item.cli_indtra == 1);
      this.clientes = data[3].filter(item => item.cli_indcli == 1);
      this.tiposDocumento = data[4];
      this.series = data[5];
      this.parteSalida.mac_tipcam = data[6][0].tipcam;
      localStorage.setItem("tipo-cambio", this.parteSalida.mac_tipcam.toFixed(2));
      this.requerimientos = data[7];
      this.transportistas = data[8];
      this.invoices = data[9];
      this.motivos = data[10];
      this.productos = data[11];

      if(this.numeroParte != '0') {
        this.obtenerMovimiento();
        return;
      }

      this.loaderData = false;

    }).catch(error => {
    })

  }

  obtenerMovimiento() {

    this.obtenerMovimient$ = this._almacenService.obtenerMovimiento(this.anio, this.mes, this.almacen, this.tipo, this.numeroParte).subscribe(
      response => {

        this.parteSalida = response[0]

        this.dataSource = fillTable(this.parteSalida.detalle_map, this.paginator, this.sort)

        this.parteSalida.detalle_guia = response[0].detalle_guia;

        localStorage.setItem("tipo-cambio", response[0].mac_tipcam);

        if(response[0].detalle_guia) {
          this.isDetalleGuia = true;
          this.loaderData = false;
        } else {
          this.isDetalleGuia = false;
        }

        this.loadData();
      },
      error => this._snackBarService.showError(error.error.msg,'OK')
    )

  }

  seleccionarSerie(serie): void {
    this.correlativoGuia$ = this._almacenService.obtenerCorrelativoGuia(serie).subscribe(
      response => this.nroDoc = response[0].nro_doc,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  seleccionarAlmacen(almacen): void {
    this.motivosParteMov$ = this._almacenService.listarMotivosParteMovimientoSalida(almacen).subscribe(
      response => this.motivos = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  agregarProducto(): void {

    const producto = new PartDetalleMap();

    this.parteSalida.detalle_map.push(producto);

    this.dataSource = fillTable(this.parteSalida.detalle_map, this.paginator, this.sort);

    this.calcularTotales();

  }

  eliminarProducto(row): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSource.data.filter(item => item.prd_codprd != row.prd_codprd);

        this.parteSalida.detalle_map = filtered;

        this.dataSource = fillTable(this.parteSalida.detalle_map, this.paginator, this.sort);
      }
    })

  }

  seleccionarProducto(value: boolean, productoSeleccionado: PartDetalleMap) {
    if(value) {
      this.parteSalida.detalle_map.forEach(element => element.seleccionado = false);
      console.log('aaa', this.parteSalida.detalle_map)
      productoSeleccionado.seleccionado = value;

      // this.dataSourceLote = fillTable(productoSeleccionado.detalle_lote, this.paginatorLote, this.sortLote);
      // this.dataSourceSerie = fillTable(productoSeleccionado.detalle_serie, this.paginatorSerie, this.sortSerie);
    }
  }

  agregarLote(): void {
    const productoSeleccionado: PartDetalleMap = this.parteSalida.detalle_map.find(item => item.seleccionado);
    console.log('prodo', productoSeleccionado)

    if(!productoSeleccionado) {
      this._snackBarService.showError('Seleccionar un producto','OK');
      return;
    }

    const lote = new DetalleLote();

    lote.mlo_coduse = this._authService.getUsuarioSistema();

    // productoSeleccionado.detalle_lote.push(lote);

    // this.dataSourceLote = fillTable(productoSeleccionado.detalle_lote, this.paginatorLote, this.sortLote);

    let totalCantidad = 0;

    // productoSeleccionado.detalle_lote.forEach(lote => {
    //   totalCantidad = totalCantidad + lote.mlo_canprd;
    // });

    productoSeleccionado.map_canprd = totalCantidad;
    productoSeleccionado.map_impmnp = productoSeleccionado.map_canprd * productoSeleccionado.map_cosmnp;
    productoSeleccionado.map_impmep = productoSeleccionado.map_canprd * productoSeleccionado.map_cosmep;

    this.calcularTotales();
  }

  eliminarLote(index) {
    const productoSeleccionado: PartDetalleMap = this.parteSalida.detalle_map.find(item => item.seleccionado);
    console.log('prodo', productoSeleccionado)

    if(!productoSeleccionado) {
      this._snackBarService.showError('Seleccionar un producto','OK');
      return;
    }
    console.log('productose el', productoSeleccionado)

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {
        console.log('logs');

        let lotes = [];
        // productoSeleccionado.detalle_lote.forEach((element, i) => { if(index != i) lotes.push(element)} )

        // productoSeleccionado.detalle_lote = lotes;

        // this.dataSourceLote = fillTable(productoSeleccionado.detalle_lote, this.paginatorLote, this.sortLote);
      }

    })
  }

  agregarSerie(): void {

    const productoSeleccionado: PartDetalleMap = this.parteSalida.detalle_map.find(item => item.seleccionado);
    console.log('prodo', productoSeleccionado)

    if(!productoSeleccionado) {
      this._snackBarService.showError('Seleccionar un producto','OK');
      return;
    }

    let serie = new DetalleSerie();

    serie.mas_coduse = this._authService.getUsuarioSistema();
    serie.tal_codtal = "00";

    // productoSeleccionado.detalle_serie.push(serie);

    // this.dataSourceSerie = fillTable(productoSeleccionado.detalle_serie, this.paginatorSerie, this.sortSerie);

    this.calcularTotales();
  }

  eliminarSerie(index) {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const productoSeleccionado: PartDetalleMap = this.parteSalida.detalle_map.find(item => item.seleccionado);
        console.log('prodo', productoSeleccionado)

        if(!productoSeleccionado) {
          this._snackBarService.showError('Seleccionar un producto','OK');
          return;
        }

        let series = [];

        // productoSeleccionado.detalle_serie.forEach((element, i) => { if(index != i) series.push(element)} )

        // productoSeleccionado.detalle_serie = series;
        // this.dataSourceSerie = fillTable(productoSeleccionado.detalle_serie, this.paginatorSerie, this.sortSerie);
      }
    })

  }

  calcularTotales(): void {
    this.importeTotalMN = 0;
    this.importeTotalME = 0;
    this.costoTotalMN = 0;
    this.costoTotalME = 0;

    this.parteSalida.detalle_map.forEach(element => {
      this.importeTotalMN = this.importeTotalMN + element.map_impmnp;
      this.importeTotalME = this.importeTotalME + element.map_impmep;
      this.costoTotalMN = this.costoTotalMN + element.map_cosmnp;
      this.costoTotalME = this.costoTotalME + element.map_cosmep;
    });
  }

  seleccionarMotivo(motivo): void {
    const motivoEncontrado = this.motivos.find(item => item.mmo_codmmo == motivo);

    motivoEncontrado == 1 ? this.numeroOrdenDisabled = false: this.numeroOrdenDisabled = true;
  }

  seleccionarFecha(): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.parteSalida.mac_fecmac)).subscribe(
      response => {
        this.parteSalida.mac_tipcam = response[0].tipcam;
        localStorage.setItem("tipo-cambio", this.parteSalida.mac_tipcam.toFixed(2));
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  enviarFormulario(f: NgForm): void {
    this.numeroParte == '0' ? this.registrarParteDeSalida(f): this.editarParteDeSalida(f);
  }

  registrarParteDeSalida(f: NgForm): void {

    if(validarPeriodo(this.parteSalida.mac_fecmac, this.year, this.month)) {
      this.parteSalida.detalle_guia[0].mmo_codmmo = this.parteSalida.mmo_codmmo;
      this.parteSalida.detalle_guia[0].alm_codalm = this.parteSalida.alm_codalm;

      this.loaderReg = true;
      this.parteSalida.detalle_map.forEach((element, index) => {
        if (index < 9) {
          element.map_cormap = `000${index + 1}`;
        } else if(index > 8 && index < 99) {
          element.map_cormap = `00${index + 1}`;
        } else if(index > 98 && index < 999) {
          element.map_cormap = `0${index + 1}`;
        } else {
          element.map_cormap = `${index +1}`;
        }
      });

      if(this.loaderData) {

        if( this.parteSalida.detalle_guia[0].grc_numdoc.length == 14 ) {
          this.validGuiaRemision$ = this._almacenService.validarGuiaRemisión(this.parteSalida.detalle_guia[0].tdo_codtdo, this.parteSalida.detalle_guia[0].grc_numdoc).subscribe(
            response => {
              if(response.message == "El numero de Guia no Existe") {
                this.addMovimiento$ = this._kardexService.registrarKardex(this.parteSalida).subscribe(
                  response => {
                    this.loaderReg = false;
                    this._dialogService.openDialog(SuccessComponent, 'Registro de Parte Salida Exitoso', '300px', '', '');
                  },
                  error => this.loaderReg = false
                )
              } else {
                this._snackBarService.showError('El número de guía ya existe', 'OK');
                this.loaderReg = false;
              }
            },
            error => {
              this.loaderReg = false;
              this._snackBarService.showError('Este número de guía no existe', 'OK');
            }
          )

        } else {
          this._snackBarService.showError('Numero de documento guía de remisión no válido', 'OK');
          this.loaderReg = false;
        }

      } else {
        this.parteSalida.detalle_guia = null;
        this.addMovimiento$ = this._kardexService.registrarKardex(this.parteSalida).subscribe(
          response => {
            this.loaderReg = false;
            this._dialogService.openDialog(SuccessComponent, 'Registro de Parte de Salida Exitoso', '300px', '', '');

            this.volver();
          },
          error => this.loaderReg = false
        )
      }
    } else {
      this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK')
    }
  }

  editarParteDeSalida(f: NgForm): void {}

  obtenerProductosRequerimiento(): void {

    if(!this.parteSalida.rma_numrma) {
      this._snackBarService.showError('Seleccionar una orden de compra', 'Ok');
      return;
    }

    this.productsReq$ = this._almacenService.obtenerProductosDesdeReq(this.parteSalida.rma_numrma).subscribe(
      response => {

        response.forEach(element => {
          let producto = new PartDetalleMap();

          producto.prd_codprd = element.prd_codprd;
          producto.prd_desesp = element.rmd_descri;
          producto.map_canprd = element.rmd_canpro;

          producto.map_coduse = this._authService.getUsuarioSistema();

          this.parteSalida.detalle_map.push(producto);

        });

        this.parteSalida.detalle_map = this.parteSalida.detalle_map;
        this.dataSource = fillTable(this.parteSalida.detalle_map, this.paginator, this.sort);
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')

    )
  }

  seleccionarChofer(chofer_id): void {

    const conductor = this.choferes.find(item => item.CHO_CODCHO == chofer_id);
    this.parteSalida.detalle_guia[0].grc_nrolic = conductor.CHO_NROLIC;
    this.parteSalida.detalle_guia[0].grc_nomcho = conductor.CHO_NOMBRE;

  }

  seleccionarUnidTransporte(unidad_id): void {

    const unidad = this.unidades.find(item => item.UTR_CODUTR == unidad_id);

    this.parteSalida.detalle_guia[0].grc_plaund = unidad.UTR_PLAUTR;
    this.parteSalida.detalle_guia[0].grc_nrocer = unidad.UTR_NROCER;
    this.parteSalida.detalle_guia[0].grc_marund = unidad.UTR_MARUTR;
    this.parteSalida.detalle_guia[0].grc_undtra = unidad.UTR_DESCRI;

  }

  listarSeriesGuia(): void {
    this.serieDoc$ = this._almacenService.listarSerieDocumentos(this.parteSalida.detalle_guia[0].tdo_codtdo).subscribe(
      response => {
        this.seriesGuia = response;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  seleccionarSerieGuia(serie): void {
    this.correlativoGuia$ = this._almacenService.obtenerCorrelativoGuia(serie).subscribe(
      response => this.parteSalida.detalle_guia[0].grc_numdoc = response[0].nro_doc,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  volver():void {
    this._router.navigate(['/modulo-almacen/movimientos']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoCambio$,
      this.correlativoGuia$,
      this.motivosParteMov$,
      this.validGuiaRemision$,
      this.addMovimiento$,
      this.serieDoc$,
      this.productsReq$,
      this.loading$
    ])

  }
}
