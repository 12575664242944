<mat-sidenav-container class="example-container">
  <mat-sidenav #drawer mode="over" position="end">

  </mat-sidenav>
  <mat-sidenav-content class="pavso-container">
    <div [ngStyle]="{marginTop: '100px'}">

      <router-outlet></router-outlet>
      <mat-card *ngIf="!isAllOk" class="mat-elevation-z0 pav-alert-warning">Es importante seleccionar el tipo de nómina y secuencia.</mat-card>
    </div>
    <mat-card class="pav-btn-workflow" *ngIf="mostrarStepper">
      <!-- <div class="example-input-wrapper">
        <label for="duration">Animation duration:</label>
        <input id="duration" value="2000" type="number" min="0" step="100" #duration>
      </div> -->

      <!-- <mat-vertical-stepper [linear]="false" #stepper [animationDuration]="duration.value"> -->
      <mat-vertical-stepper [linear]="false" #stepper >
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Paso 01</ng-template>
            <!-- <mat-form-field>
              <input matInput placeholder="Last name, First name" formControlName="firstCtrl" required>
            </mat-form-field> -->
            <div>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>Paso 02</ng-template>
            <!-- <mat-form-field>
              <input matInput placeholder="Address" formControlName="secondCtrl" required>
            </mat-form-field> -->
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          Paso 03.
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="stepper.reset()">Reset</button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
