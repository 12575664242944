import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexPlotOptions, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from "ng-apexcharts";
import { Subscription } from "rxjs";
import { CrmService } from "src/app/services";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'crm-graph-one',
  templateUrl: './crm-graph-one.component.html',
  styleUrls: ['./crm-graph-one.component.scss']
})
export class CrmGraphOneComponent implements OnInit, OnDestroy {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  displayedColumns: string[] = ['country', 'amount'];
  dataSource = [
    {
      country: 'BOLIVIA',
      amount: 44
    },
    {
      country: 'ECUADOR',
      amount: 76
    },
    {
      country: 'PERU',
      amount: 35
    },
    {
      country: 'MONTO TOTAL',
      amount: 155
    },
  ];

  periodo$: Subscription;
  loading$: Subscription;
  estadistico$: Subscription;

  year: string;
  month: string;

  loaderData: boolean;

  constructor(
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _crmService: CrmService
  ) {

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.chartOptions = {
      series: [
        {
          name: "BOLIVIA",
          data: [44]
        },
        {
          name: "ECUADOR",
          data: [76]
        },
        {
          name: "PERÚ",
          data: [35]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "",
        ]
      },
      yaxis: {
        title: {
          text: "S/. (Monto)"
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "S/. " + val + " ";
          }
        }
      }
    };

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      console.log('cambio loading')
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerDatosEstadistico()
    })
  }

  obtenerDatosEstadistico(): void {

    this.loaderData = true;

    this.estadistico$ = this._crmService.obtenerGrafico01(this.year, this.month).subscribe(
      response => {

        console.log('data', response)

        this.chartOptions = {
          series: response.series,
          chart: {
            type: "bar",
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              // endingShape: "rounded"
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: [
              "",
            ]
          },
          yaxis: {
            title: {
              text: "S/. (Monto)"
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return "S/. " + val + " ";
              }
            }
          }
        };

        this.dataSource = response.dataSource;

        this.loaderData = false;

      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  ngOnDestroy(): void {
    if(this.periodo$) this.periodo$.unsubscribe();
  }

}
