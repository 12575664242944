<app-pavso-title-section [title]="'MOTIVOS'" [module]="'MOTIVO'" [estado]="motive.mmo_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarMotivo(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Código</mat-label>
            <input matInput placeholder="Código" [(ngModel)]="motive.mmo_codmmo" name="mmo_codmmo">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" [(ngModel)]="motive.mmo_descri" name="mmo_descri">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nombre Abreviado</mat-label>
            <input matInput placeholder="Nombre Abreviado" [(ngModel)]="motive.mmo_desabr" name="mmo_desabr">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cod. Sunat</mat-label>
            <input matInput placeholder="Cod. Sunat" [(ngModel)]="motive.mmo_codofi" name="mmo_codofi">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Motivo Vinculado</mat-label>
            <input matInput placeholder="Motivo Vinculado" [(ngModel)]="motive.mmo_cmovin" name="mmo_cmovin">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m12 l6">
          <mat-card class="mat-elevation-z0">
            <mat-tab-group>
              <mat-tab label="Acciones" labelClass="mat-tab-label-0-0">
                <section class="example-section">
                  <div class="row">
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_pedido" name="pedido">Pedido
                      </mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indtra" name="mmo_indtra">
                        Transferencia</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indpro" name="mmo_indpro">Reporte
                        producción</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_pedido" name="pedido">Registrar
                        invoice</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indrma" name="mmo_indrma">
                        Requerimiento de materiales</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indopr" name="mmo_indopr">Orden de
                        producción</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indgra" name="mmo_indgra">
                        Transferencia gratuita</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indvin" name="mmo_indvin">Motivo
                        vinculado</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_pedido" name="pedido">Movimiento de
                        almacén</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indfac" name="mmo_indfac">Factura
                      </mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indtrs" name="mmo_indtrs">Tránsito
                      </mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indexp" name="mmo_indexp">Exportación
                      </mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indots" name="mmo_indots">O.T.
                      </mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indncr" name="mmo_indncr">N. Crédito
                      </mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indval" name="mmo_indval">Valor
                        Kardex</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indoin" name="mmo_indoin">Ord.
                        Internamiento</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_pedido" name="pedido">Parte de
                        ingreso</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indsdm" name="mmo_indsdm">Devolución
                        de materiales</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_inexpe" name="mmo_inexpe">Excluido de
                        percepción</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_inddev" name="mmo_inddev">Devolución
                        de mercadería / Prod. Termina</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_pedido" name="pedido">Extrae valor
                        combo</mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indser" name="mmo_indser">Serie
                      </mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indgui" name="mmo_indgui">Guía
                      </mat-checkbox>
                    </div>
                    <div class="col s12 m12 l12 xl6">
                      <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_indocm" name="mmo_indocm">Ord. Compra
                      </mat-checkbox>
                    </div>
                  </div>
                </section>
              </mat-tab>
              <mat-tab label="Asientos constables" labelClass="mat-tab-label-0-1">
                <div class="row">
                  <div class="col s12 m12 l12 xl6">
                    <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_asicop" name="mmo_asicop">Asiento de
                      consumo de producción</mat-checkbox>
                  </div>
                  <div class="col s12 m12 l12 xl6">
                    <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_asicoe" name="mmo_asicoe">Asiento de
                      compras importadas</mat-checkbox>
                  </div>
                  <div class="col s12 m12 l12 xl6">
                    <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_asicos" name="mmo_asicos">Asiento de
                      costo de ventas</mat-checkbox>
                  </div>
                  <div class="col s12 m12 l12 xl6">
                    <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_asicon" name="mmo_asicon">Asiento de
                      compras nacionales</mat-checkbox>
                  </div>
                  <div class="col s12 m12 l12 xl6">
                    <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_cospt" name="mmo_cospt">Asiento de
                      costos P.T.</mat-checkbox>
                  </div>
                  <div class="col s12 m12 l12 xl6">
                    <mat-checkbox class="example-margin" [(ngModel)]="motive.mmo_otrmov" name="mmo_otrmov">Asiento de
                      otros movimientos</mat-checkbox>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </mat-card>
        </div>
        <div class="col s12 m12 l6">
          <div class="mat-elevation-z0">
            <table mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="codigo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
              </ng-container>

              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
              </ng-container>

              <ng-container matColumnDef="cod_sucursal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Sucursal </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-almacen/editar-cliente-comercial', row.cli_codcli]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                  </div>

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros</td>

              </tr>

            </table>

            <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </div>
      <br>
      <div class="row">

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Motivo N/Crédito SUNAT</mat-label>
            <mat-select [(ngModel)]="motive.mmo_ncrsun" name="mmo_ncrsun">
              <mat-option *ngFor="let motivo of motivosCredito" [value]="motivo.C09_CODC09">
                {{motivo.C09_DESCRI}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Motivo N/débito SUNAT</mat-label>
            <mat-select [(ngModel)]="motive.mmo_ndbsun" name="mmo_ndbsun">
              <mat-option *ngFor="let motivo of motivosDebito" [value]="motivo.C10_CODC10">
                {{motivo.C10_DESCRI}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>T. Op. T51</mat-label>
            <mat-select [(ngModel)]="motive.t51_codt51" name="t51_codt51">
              <mat-option value="1">
                T 1
              </mat-option>
              <mat-option value="2">
                T 2
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo impuesto</mat-label>
            <mat-select [(ngModel)]="motive.t07_codt07" name="t07_codt07">
              <mat-option value="1">
                impuesto 1
              </mat-option>
              <mat-option value="2">
                impuesto 2
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <p class="pavso-title-consideration">Solo para configuración de cuentas clase 7 - Sin Movimiento Almacén</p>

        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Cta. Contable</mat-label>
            <input matInput placeholder="Cta. Contable">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Cuenta vinculados</mat-label>
            <input matInput placeholder="Cuenta vinculados">
          </mat-form-field>
        </div>
      </div>


    </mat-card>
  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-almacen/motivos">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
