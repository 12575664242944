import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_RESPONSABLE } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ResponsableService } from "src/app/services/api/crm/maestros/responsable.service";

@Component({
  selector: 'responsable-list',
  templateUrl: 'responsable-list.component.html',
  styleUrls: ['responsable-list.component.scss']
})
export class ResponsableListComponent implements OnInit, OnDestroy {

  responsables$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_RESPONSABLE;

  responsables: Array<any> = [];

  public chart: any;

  constructor(
    private store: Store<PavsoState>,
    private _responsableCrmService: ResponsableService,
    private _snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerResponsables();
      }
    })
  }

  obtenerResponsables(): void {
    this.responsables$ = this._responsableCrmService.obtenerResponsables().subscribe(
      responsables => {
        this.responsables = responsables
        console.log('responsables', responsables)
      },
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.responsables$,
      this.loading$,
    ])
  }
}
