<h1 mat-dialog-title>Preferencias de impresión</h1>

<div>
  <h5 [ngStyle]="{marginTop: '0px'}">Forma</h5>
  <div class="row">
    <div class="col s12 m12">
      <mat-radio-group [(ngModel)]="preferencia.s73_indvpr" name="s73_indvpr" aria-label="Select an option">
        <mat-radio-button [value]="1">Vista previa</mat-radio-button>
        <mat-radio-button [value]="0">Impresión</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <h5>Tamaño</h5>
  <div class="row">
    <div class="col s12 m12">
      <mat-radio-group [(ngModel)]="preferencia.s73_tampap" name="s73_tampap" aria-label="Select an option">
        <mat-radio-button value="A4">A4</mat-radio-button>
        <mat-radio-button value="A7">A7</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">

  <button [class.spinner]="loaderUpdate" [disabled]="loaderUpdate" mat-raised-button color="primary" (click)="aceptar()" type="button">Aceptar</button>

</div>

