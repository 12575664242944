import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexPlotOptions, ApexTitleSubtitle, ApexTooltip, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { AuthenticationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-composicion-edadsexo-trabajadores',
  templateUrl: './composicion-edadsexo-trabajadores.component.html',
  styleUrls: ['./composicion-edadsexo-trabajadores.component.css']
})
export class ComposicionEdadsexoTrabajadoresComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['initial', 'edad1', 'edad2', 'edad3', 'edad4', 'edad5', 'trabajadores'];
  dataSource = [];

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  apexChart: ApexCharts;

  period$: Subscription;
  loading$: Subscription;
  informe$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _nominaService: NominaService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = [
      {
        initial: "FEMENINO",
        edad1: 2,
        edad2: 4,
        edad3: 4,
        edad4: 3,
        edad5: 2,
        trabajadores: 15,
      },
      {
        initial: "MASCULINO",
        edad1: 12,
        edad2: 11,
        edad3: 10,
        edad4: 30,
        edad5: 8,
        trabajadores: 71,
      },
      {
        initial: "TOTALES",
        edad1: 14,
        edad2: 15,
        edad3: 14,
        edad4: 33,
        edad5: 10,
        trabajadores: 56,
      }
    ]

  }

  ngOnInit(): void {

    // INICIALIZAR GRAFICO
    this.chartOptions = {
      series: [],
      chart: {
        type: "bar",
        height: 350,
        events: {
          click: function(chart, w, e) {
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "21-30",
          "31-40",
          "41-50",
          "51-60",
          "60-(+)",
        ]
      },
      yaxis: {
        title: {
          text: "# Colaboradores"
        },
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px"
          },
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "" + val + " ";
          }
        }
      },
      title: {
        text: `# Colaboradores Vigentes y Cesados`,
        align: "center",
        style: {
          color: "#444"
        }
      }
    }

    this.apexChart = new ApexCharts(document.querySelector("#chartEdadSexoTrabajadores"), this.chartOptions);

    this.apexChart.render();

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this.informe$ = this._nominaService.obtenerInformeEdadSexoTrabajadores(this.year, this.month).subscribe(
      response => {
        response.datatable.forEach(item => {
          item['total'] = item['edad1'] + item['edad2'] + item['edad3'] + item['edad4'] + item['edad5'];
        });
        this.dataSource = response.datatable;

        const dataDefault = [
          {
            name: "Femenino",
            data: [0, 0, 0, 0, 0]
          },
          {
            name: "Masculino",
            data: [0, 0, 0, 0, 0]
          },
        ]

        this.apexChart.updateSeries((response.chart.length > 0)? response.chart: dataDefault)
        this.apexChart.updateOptions({
          title: {
            text: `# Colaboradores Vigentes y Cesados`,
            align: "center",
            style: {
              color: "#444"
            }
          }
        })
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/informes-de-composicion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.informe$]);
  }
}
