import { HeaderReport } from '@data/interfaces/headers.report';
import { noBorders, topBorder } from '@utils/pdfmaker/utils/pdfmake.constant';
import { obtenerNulls } from '@utils/pdfmaker/utils/pdfmake.function';
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';

export function documentoConstanciaTrabajo(headers: HeaderReport, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');

  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `${data.CIA_DIRCIA}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'center'},
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("CONSTANCIA DE TRABAJO").decoration('underline').alignment('center').fontSize(16).bold().end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("\n\n\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("El que suscribe, constata que:").fontSize(10).alignment('justify').end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt(`El Sr(a). ${data.cli_nomcli}, identificado(a) con DNI N° ${data.cli_numdni} se encuentra laborando en nuestra empresa como ${data.NCA_DESCRI}, desde el ${data.trp_fecing}.`).fontSize(10).alignment('justify').end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("Demostrando las cualidades deseadas por la empresa acordes a su puesto de trabajo. Se expide esta Constancia de Solicitud del interesado para los fines que crea conveniente.").fontSize(10).alignment('justify').end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("\n\n\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt(`Lima, ${data.fecha_actual}`).fontSize(10).alignment('right').end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("\n\n\n\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Stack([
            new Txt("").fontSize(10).alignment('center').end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(data.nombre_gerente).fontSize(10).alignment('center').end,
            new Txt(data.cargo_gerente).fontSize(10).alignment('center').end,
          ]).end
        ).border(topBorder).end,

      ],


    ]).widths(["*","*"]).end
  );

  pdf.create().open();
}

export function documentoCertificadoTrabajo(headers: HeaderReport, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([45, 35, 45, 35]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `${data.CIA_DIRCIA}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'center'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("CERTIFICADO DE TRABAJO").decoration('underline').fontSize(16).alignment('center').bold().end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt("\n\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt(`${data.CIA_NOMCIA} con RUC ${data.CIA_NUMRUC}, con domicilio fiscal en ${data.CIA_DIRCIA}, y debidamente representada por el Sr. ${data.nombre_gerente}.`).fontSize(10).alignment('justify').end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("CERTIFICA:").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt(`Que el Sr(a). ${data.cli_nomcli}, Identificado(a) con DNI N° ${data.cli_numdni} ha laborado en nuestra empresa como ${data.NCA_DESCRI}, durante el período comprendido desde el ${data.trp_fecing} hasta el ${data.trp_fecces}, demostrando durante su permanencia responsabilidad, honestidad y dedicación en las labores encomendadas.`).end
        ).colSpan(2).border(noBorders).alignment('justify').end,
        null
      ],
      [
        new Cell(
          new Txt("\n\n\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt(`Lima, ${data.fecha_actual}`).fontSize(10).alignment('right').end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("\n\n\n\n\n").end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Stack([
            new Txt("").fontSize(10).alignment('right').end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(data.nombre_gerente).fontSize(10).alignment('center').end,
            new Txt(data.cargo_gerente).fontSize(10).alignment('center').end,
          ]).end
        ).border(topBorder).end
      ],

    ]).widths(["*", "*"]).end
  );

  pdf.create().open();
}
