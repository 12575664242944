import { Component } from "@angular/core";

@Component({
  selector: 'button-whatsapp',
  templateUrl: './button-whatsapp.component.html',
  styleUrls: ['./button-whatsapp.component.scss']
})
export class ButtonWhatsappComponent {

  constructor() {}

}
