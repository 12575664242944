<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
<div class="pav-period-container">

  <div class="pav-period-title">{{'general.dialog.activity-period.title' | translate}}</div>

  <div class="pav-period-content">
    <div class="row">
      <div class="col s12 m12p-l-0">
        <mat-form-field [ngStyle]="{width: '300px'}">
          <mat-label>Período</mat-label>
          <mat-select [(ngModel)]="periodo" name="periodo" (selectionChange)="obtenerTiposNomina()">
            <mat-option *ngFor="let periodo of periodos" [value]="periodo.ANO_CODANO + periodo.MES_CODMES">
              {{periodo.ANO_CODANO}} - {{periodo.MES_CODMES}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="col s12 m12 l6 p-l-0">
        <mat-form-field>
          <mat-label>{{'general.dialog.activity-period.form.year' | translate}}</mat-label>
          <mat-select [(ngModel)]="yearPeriod" name="yearPeriod" (selectionChange)="obtenerTiposNomina()">
            <mat-option *ngFor="let year of years" [value]="year.id">
              {{year.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l6 p-r-0">
        <mat-form-field>
          <mat-label>{{'general.dialog.activity-period.form.month' | translate}}</mat-label>
          <mat-select [(ngModel)]="monthPeriod" name="monthPeriod" (selectionChange)="obtenerTiposNomina()">
            <div *ngFor="let month of months">
              <mat-option [value]="month.id">
                {{month.name}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="col s12 m12 l6 p-l-0" *ngIf="isNomina">
        <mat-form-field>
          <mat-label>{{'general.dialog.activity-period.form.type-payroll' | translate}}</mat-label>
          <mat-select [(ngModel)]="tipoNomina" name="tipoNomina" (selectionChange)="seleccinarTipoNomina($event.value)">
            <mat-option *ngFor="let tipo of tipos" [value]="tipo.nti_codnti">
              {{tipo.nti_descri}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l6 p-r-0" *ngIf="isNomina">
        <mat-form-field>
          <mat-label>{{'general.dialog.activity-period.form.payroll-sequence' | translate}}</mat-label>
          <mat-select [(ngModel)]="secNomina" name="secNomina">
              <div *ngFor="let secuencia of secuencias">
                  <mat-option [value]="secuencia.cod">
                        {{secuencia.cod}} | {{secuencia.nfr_descri}}
                </mat-option>
              </div>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="pav-period-actions">
    <button mat-flat-button class="pav-query-period-btn-cancel" (click)="cancelar()">{{'general.button.cancel' | translate}}</button>
    <button mat-flat-button class="pav-query-period-btn-accept" (click)="consultar()" color="primary" [disabled]="loaderData">{{'general.button.accept' | translate}}</button>
  </div>
</div>
