<mat-list role="list">
  <mat-list-item role="listitem">
    <span mat-line>Adjuntar documentos</span>
    <span mat-line>Subir archivo (.pdf, .jpeg, .jpg)</span>
    <button (click)="cargarDocumentos()" matTooltip="Cargar documentos" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>upload</mat-icon>
    </button>

  </mat-list-item>
  <mat-divider></mat-divider>

</mat-list>
