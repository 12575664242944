<app-pavso-title-section [title]="'Tipo de operación'" [module]="'Contabilidad'" [usuario]="tipoOperacion.tga_coduse"
  [fecha]="tipoOperacion.tga_fecupd" [ngClass]="'pav-form'"
  [estado]="tipoOperacion.tga_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.tga_codtga" matInput placeholder="Código" name="tga_codtga"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.tga_descri" matInput placeholder="Descripción"
              name="tga_descri" required>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Ctas. B&S</mat-label>
            <mat-select required [disabled]="loaderData" [(ngModel)]="tipoOperacion.T30_CODT30" name="t30_codt30">
              <mat-option *ngFor="let tipo of tiposBienServicio" [value]="tipo.t30_codt30"
                [matTooltip]="tipo.t30_dest30">
                {{tipo.t30_dest30}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field>
            <mat-label>Ctas. B&S</mat-label>
            <mat-select [(ngModel)]="tipoOperacion.T30_CODT30" required name="T30_CODT30">
              <mat-option value="1">
                ctas 1
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Cta Cte MN</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.ccn_codmna" matInput placeholder="Cta. Cta Cte MN"
              name="ccn_codmna">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Cta Cte ME</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.ccn_codmex" matInput placeholder="Cta. Cta Cte ME"
              name="ccn_codmex">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Imp. MN</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.ccn_impmna" matInput placeholder="Cta. Imp. MN"
              name="ccn_impmna">
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>

      <div class="pav-separator"></div>

      &nbsp;
      <button-add-item-table (addItem)="agregarItem()"></button-add-item-table>
      &nbsp;
      <button-remove-item-table (removeItem)="quitarItem()"></button-remove-item-table>

      &nbsp;
      <button-insert-item-table (insertItem)="insertarItem()"></button-insert-item-table>

    </div>
    <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="aau_iteaau">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> It. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Código">
            <div [ngStyle]="{width: '50px'}">
              <mat-form-field [class.mat-form-field-row]="row === selected"
                class="mat-form-field-table form-field-small form-field-cell">
                <input [ngStyle]="{textAlign: 'right'}" readonly matInput placeholder="" [value]="row.aau_iteaau"
                  [name]="'aau_iteaau' + i">
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ccn_codccn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Cuenta">

            <pav-input-search-id label="''" placeholder="ej. producto 01"
              [class]="row === selected? 'form-field-small form-field-cell mat-form-field-row': 'form-field-small  form-field-cell'"
              (valueChange)="row.ccn_codccn = $event.codigo; row.cuenta.ccn_descri = $event.descripcion;"
              [codigo]="row.ccn_codccn" [isResponsive]="isMobile || isTablet" [name]="'ccn_descri' + i"
              [descripcion]="row.cuenta.ccn_descri" [program]="'CUENTAS'"></pav-input-search-id>

          </td>
        </ng-container>

        <ng-container matColumnDef="ccn_descri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
            <mat-form-field [class.mat-form-field-row]="row === selected"
              class="mat-form-field-table form-field-small form-field-cell">
              <input [ngStyle]="{textAlign: 'right'}" readonly matInput placeholder="" [value]="row.cuenta.ccn_descri"
                [name]="'ccn_descri' + i">
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="ccs_descri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="C. Costo">
            <select-search [label]="''" [placeholder]="'Ej. FAC'"
              [clase]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
              [name]="'ccs_codccs' + i" [value]="'ccs_codccs'" [disabled]="loaderData" [description]="'ccs_descri'"
              [data]="centrosCosto" [required]="false" (cambioSelect)="row.ccs_codccs = $event;"
              [model]="row.ccs_codccs">
            </select-search>
          </td>
        </ng-container>

        <ng-container matColumnDef="aau_inddha">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="D/H">
            <mat-form-field [class.mat-form-field-row]="row === selected"
              class="mat-form-field-table form-field-small form-field-cell">
              <mat-select [disabled]="loaderData" [(ngModel)]="row.aau_inddha" name="aau_inddha"
                [name]="'aau_inddha' + i.toString()">
                <mat-option value="D">
                  D
                </mat-option>
                <mat-option value="H">
                  H
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

        </ng-container>

        <ng-container matColumnDef="aau_poraau">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> % Distrib. </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="% Distrib.">
            <mat-form-field [class.mat-form-field-row]="row === selected"
              class="mat-form-field-table form-field-small form-field-cell">
              <input [ngStyle]="{textAlign: 'right'}" matInput placeholder="" type="number" (keyup)="keyupPorcentaje()" [(ngModel)]="row.aau_poraau"
                [name]="'aau_poraau' + i">
            </mat-form-field>

          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
          (click)="selectRow(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">No se encontraron registros</td>

        </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <select-search [label]="'Tipo Doc. Req. Pago'" [placeholder]="'Ej. FAC'" [name]="'TDO_CODTDO'"
            [value]="'TDO_CODTDO'" [disabled]="loaderData || !tipoOperacion.tga_indrqp" [description]="'description'"
            [data]="tiposDocumento" [required]="true" (cambioSelect)="tipoOperacion.tdo_codtdo = $event;"
            [model]="tipoOperacion.tdo_codtdo">
          </select-search>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Total %</mat-label>
            <input [ngStyle]="{textAlign: 'right'}" type="text" [(ngModel)]="totalPorcentaje" matInput placeholder="Total %" name="totalPorcentaje" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-checkbox class="example-margin" [(ngModel)]="tipoOperacion.tga_indrqp" name="tga_indrqp">Requerimiento de
            Pago</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="tipoOperacion.tga_indfij" name="tga_indfij">Gasto
            Fijo</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="tipoOperacion.tga_indimp" name="tga_indimp  ">Gastos de
            Importación</mat-checkbox>
        </div>
      </div>

    </mat-card>
  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/tipos-de-operacion">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
