<div class="chat">
  <div class="messages">

    <div></div>

    <div class="time">
      Hoy a las 11:41
    </div>

    <div *ngFor="let message of messages" [ngClass]="{'message': true, 'parker': !message.isGpt, 'stark': message.isGpt}">
      {{message.message}}
    </div>

    <div class="message stark">
      <div class="typing typing-1"></div>
      <div class="typing typing-2"></div>
      <div class="typing typing-3"></div>
    </div>

  </div>
  <div class="input">
    <mat-icon [ngStyle]="{cursor: 'pointer', marginRight: '5px'}">photo_camera</mat-icon>
    <mat-icon [ngStyle]="{cursor: 'pointer', marginRight: '5px'}">mood</mat-icon>
    <!-- <i class="fas fa-camera"></i> -->
    <!-- <i class="far fa-laugh-beam"></i> -->
    <input placeholder="Escriba su consulta!" type="text" (keyup.enter)="enviarMensaje()" [(ngModel)]="inputMessage" name="inputMessage"/>
    <mat-icon [ngStyle]="{cursor: 'pointer', marginLeft: '5px'}">mic</mat-icon>
    <!-- <i class="fas fa-microphone"></i> -->
  </div>
</div>
