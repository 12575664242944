export class FlujoCaja {
  cia_codcia: string;
  fca_codfca: string;
  fca_descri: string;
  fca_coduse: string;
  fca_fecupd: Date;
  fca_indsta: string;
  fca_tipflu: string;
  cac_codcac: string;

  constructor() {
    this.fca_indsta = '1';
    this.fca_fecupd = new Date();
  }
}
