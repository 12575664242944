import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Driver } from 'src/app/models';
import { AuthenticationService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-categoria-centro-costo-form',
  templateUrl: './categoria-centro-costo-form.component.html',
  styleUrls: ['./categoria-centro-costo-form.component.css']
})
export class CategoriaCentroCostoFormComponent implements OnInit {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      if(this.uid == '0') return;


    })


  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarCategoria(f): this.actualizarCategoria(f);
  }

  registrarCategoria(f: NgForm): void {}

  actualizarCategoria(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/categorias-de-centro-de-costo']);
  }

}
