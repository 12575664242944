import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@shared/services/snackbar.service';

class FiltroOperacionExtornar {
  periodo: string;
  subdiario: string;
  voucher: string;
}

@Component({
  selector: 'operacion-extornar-dialog',
  templateUrl: 'operacion-extornar.dialog.html',
  styleUrls: ['operacion-extornar.dialog.css']
})

export class OperacionExtornarDialog implements OnInit {

  filtro: FiltroOperacionExtornar = new FiltroOperacionExtornar();

  constructor(
    public dialogRef: MatDialogRef<OperacionExtornarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBarService: SnackBarService
  ) {
    this.filtro.periodo = data.periodo;
    console.log('operaciones', data.operacionesFrecuentes)
  }

  ngOnInit() { }

  obtenerOperaciones(f: NgForm): void {

    if(!this.filtro.periodo) {
      this._snackBarService.showError('Establecer el período', 'Ok');
      return;
    }

    if(!this.filtro.subdiario) {
      this._snackBarService.showError('Establecer el subdiario', 'Ok');
      return;
    }

    if(!this.filtro.voucher) {
      this._snackBarService.showError('Establecer el voucher', 'Ok');
      return;
    }
    // "202407180100009"
    const operacion = {
      ano_codano: this.filtro.periodo.substring(0, 4),
      mes_codmes: this.filtro.periodo.substring(4, 6),
      sco_codsco: this.filtro.subdiario,
      ldc_corldc: this.filtro.voucher
    }
    //`${this.filtro.periodo.trim()}${this.filtro.subdiario.trim()}${this.filtro.voucher.trim()}`;// this.data.operacionesFrecuentes.find(item => item.code == `${this.filtro.periodo.trim()}${this.filtro.subdiario.trim()}${this.filtro.voucher.trim()}`)
    // if(!operacion) {
    //   this._snackBarService.showError(`Operación ${this.filtro.periodo.trim()}${this.filtro.subdiario.trim()}${this.filtro.voucher.trim()} no encontrada`, 'Ok');
    //   return;
    // }

    this.dialogRef.close(operacion)

  }

}
