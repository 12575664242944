export class OtrosIngresosAfectoRenta {
  codcia: string;
  codano: string;
  codmes: string;
  detalle: Array<DetalleOtrosIngresosAfectoRenta>

  constructor() {
    this.detalle = [];
  }
}

export class DetalleOtrosIngresosAfectoRenta {
  codcli: string;
  nomcli: string;
  imprqd: number;
  fecupd: Date;
  coduse: string;
  correlativo: any;
  isEditing: boolean;

  constructor() {
    this.fecupd = new Date();
    this.isEditing = true;
  }
}
