import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'desasocia-guia-factura-nota-credito',
  templateUrl: './dosasocia-guia-factura-nota-credito.component.html',
  styleUrls: ['./desasocia-guia-factura-nota-credito.component.scss']
})
export class DesasociaGuiaFacturaNotaCreditoComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
