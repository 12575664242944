export class RegistroVentas {
  cia_codcia: any;
  cco_codcco: any;
  cli_codcli: string;
  cli_coddoc: any;
  tdo_codtdo: any;
  cco_numdoc: any;
  cco_glocco: any;
  cco_fecemi: any;
  cco_fecven: any;
  tmo_codtmo: any;
  ccn_codccn: any;
  cco_impdoc: any;
  cco_impcco: any;
  cco_salcco: any;
  cco_sallba: any;
  cco_salres: any;
  cco_impabo: any;
  cco_impigv: any;
  cco_tasigv: any;
  cco_impcor: any;
  cco_impina: any;
  cco_impafe: any;
  cco_indtca: any;
  cco_tipcam: any;
  cco_codpag: any;
  mmo_codmmo: any;
  cpa_codcpa: any;
  tdo_docref: any;
  cco_numref: any;
  ano_codano: any;
  mes_codmes: any;
  sco_codsco: any;
  ldc_corldc: any;
  sco_codasi: any;
  ldc_corasi: any;
  cco_fecreg: any;
  age_codage: any;
  cco_abotra: any;
  cco_indsta: string;
  cco_coduse: string;
  cco_codant: any;
  cco_impret: any;
  cco_aboret: any;
  cco_retgas: any;
  iri_codiri: any;
  suc_codsuc: any;
  tga_codtga: any;
  cco_fecref: any;
  ocm_numocm: any;
  cco_numcuo: any;
  ipi_codipi: any;
  cco_impper: any;
  cco_permna: any;
  cco_movil: any;
  cco_cbmoti: any;
  cco_condic: any;
  cco_code: any;
  dpc_indder: any;
  dpc_fecrec: any;
  dpc_indsta: string;
  dpc_fecupd: any;
  dpc_coduse: string;
  dpc_tasren: any;
  dpc_tasies: any;
  dpc_tasore: any;
  dpc_impren: any;
  dpc_impies: any;
  dpc_impore: any;
  dpc_difigv: any;
  dpc_afcder: any;
  dpc_afsder: any;
  dpc_impisc: any;
  dpc_igvisc: any;
  dpc_tasisc: any;
  dpc_otrtri: any;
  dpc_valare: any;
  dpc_inddif: any;
  dpc_perini: any;
  dpc_impdif: any;
  dpc_numpol: any;
  dpc_parara: any;
  dpc_impfob: any;
  dpc_impfle: any;
  dpc_impseg: any;
  dpc_impcif: any;
  dpc_impadv: any;
  dpc_reipap: any;
  dpc_afepol: any;
  dpc_inddet: any;
  dpc_inddet_c: any;
  tim_codtim: any;
  dpc_tasdet: any;
  dpc_depdet: any;
  dpc_impdet: any;
  dpc_fecdet: any;
  dpc_indntc: any;
  dpc_anotri: any;
  dpc_mestri: any;
  dpc_impper: any;
  dpc_numdua: any;
  dpc_anodua: any;
  oim_numoim: any;
  sad_codsad: any;
  dpc_impsis: any;
  dpc_impfis: any;
  t30_codt30: any;
  t31_codt31: any;
  valvta: any;
  detalle: Array<DetailSaleRecord>;
  ordenes: Array<OrdersSaleRecord>;
  constructor(
  ){
    this.cco_indsta = '1';
    this.dpc_fecupd = new Date();
    this.cco_impigv = 0;
    this.cco_impdoc = 0;
    this.valvta = 0;
  }
}

export class DetailSaleRecord {
  cia_codcia: any;
  ano_codano: any;
  mes_codmes: any;
  sco_codsco: any;
  ccn_descri: any;
  ldc_corldc: any;
  ldi_corldi: any;
  ccn_codccn: any;
  cli_codcli: string;
  cco_codcco: any;
  tmo_codtmo: any;
  ldi_inddha: any;
  ldi_tipcam: any;
  ldi_tcameu: any;
  ldi_impnac: any;
  ldi_impmex: any;
  ldi_indcco: any;
  tdo_codtdo: any;
  ldi_docref: any;
  tdo_docref: any;
  ldi_numref: any;
  ldi_gloldi: any;
  fpa_codfpa: any;
  ref_codref: any;
  ldi_inddes: any;
  ldi_fecemi: any;
  ldi_fecven: any;
  ccs_codccs: any;
  suc_codsuc: any;
  rec_numrec: any;
  vde_codvde: any;
  ldi_impsal: any;
  fca_codfca: any;
  ocm_numocm: any;
  ldi_indccl: any;
  ldi_anoccl: any;
  ldi_mesccl: any;
  isEditing: boolean;
  isSelected: boolean;
  constructor(
  ){
    this.isSelected = false;
    this.isEditing = true;
  }
}

export class OrdersSaleRecord {
  cia_codcia: any;
  ocm_numocm: any;
  cco_codcco: any;
  oim_numoim: any;
  occ_impdis: any;
  isEditing: any;
  constructor(
  ) {}
}
