import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AuthenticationService, ComprasService, ContabilidadService, VentasService } from 'src/app/services';
import { OrdenCompraService } from 'src/app/services/api/compras/operaciones/orden-compra.service';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { LogsRequerimientosDialog } from '../dialogs';

@Component({
  selector: 'app-requerimiento-pago-form',
  templateUrl: './requerimiento-pago-form.component.html',
  styleUrls: ['./requerimiento-pago-form.component.css']
})
export class RequerimientoPagoFormComponent implements OnInit, OnDestroy {

  loaderData: boolean = false;
  loaderReg: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  proveedores: any[] = [];
  ordenDe: any[] = [];
  clientes: any[] = [];
  centros: any[] = [];
  monedas: any[] = [];
  ordenesCompra: any[] = [];
  tiposRetencion: any[] = [];

  minDate: Date;

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _authService: AuthenticationService,
    private _ventasService: VentasService,
    private _contabilidadService: ContabilidadService,
    private _ordenCompraService: OrdenCompraService,
    private _compraService: ComprasService,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    private store: Store<PavsoState>
  ) {

    this.initialize();

  }

  initialize(): void {
    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;
    })

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  clientes$: Subscription;
  centros$: Subscription;
  ordenesCompra$: Subscription;
  tiposRetencion$: Subscription;

  loadMaestros(): void {
    this.clientes$ = this._ventasService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes.filter(item => item.cli_indpro == 1);
        this.proveedores = clientes;
        this.ordenDe = clientes;
        console.log('clientes', clientes)

        this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
          centros => {
            console.log('centros', centros)
            this.centros = centros;

            this.ordenesCompra$ = this._ordenCompraService.obtenerOrdenesCompra().subscribe(
              ordenes => {
                this.ordenesCompra = ordenes;

                this.tiposRetencion$ = this._compraService.listarTiposRetencion().subscribe(
                  tipos => {
                    this.loaderData = false;
                    this.tiposRetencion = tipos;
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener tipos', 'Ok')
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener centros de costo', 'Ok')
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener centros de costo', 'Ok')
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener proveedores', 'Ok')
        this.loaderData = false;
      }
    );

  }

  loadData(): void {
    this.loaderData = true;

    this.loadMaestros();
  }

  logsRequerimiento(): void {
    const dialogRef = this.dialog.open(LogsRequerimientosDialog, {
      width: 'auto',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  registrarRequerimiento(f: NgForm): void {
    if (validarPeriodo(new Date(), this.year, this.month)) {

    }
  }

  registroNuevo(): void {
    this.initialize();
    this.uid = '0';
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$
    ])

  }

  volver(): void { }

}
