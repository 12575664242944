import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { OperacionFrecuente } from "src/app/models/caja-banco/operacion-frecuente";
import { ConfigurationService } from "src/app/services";
import { OperacionFrecuenteService } from "src/app/services/api/tesoreria/operacion-frecuente.service";

@Component({
  selector: 'grabar-operacion-frecuente-dialog',
  templateUrl: './grabar-operacion-frecuente.dialog.html',
  styleUrls: ['./grabar-operacion-frecuente.dialog.scss']
})
export class GrabarOperacionFrecuenteDialog implements OnDestroy {

  operacionFrecuente: OperacionFrecuente;

  registrar$: Subscription;

  loaderReg: boolean;

  constructor(
    public dialogRef: MatDialogRef<GrabarOperacionFrecuenteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _operacionFrecuenteService: OperacionFrecuenteService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService
  ) {
    this.operacionFrecuente = new OperacionFrecuente();
    this.operacionFrecuente.sco_codsco = data.subdiario;
    this.operacionFrecuente.ano_codano = data.anio;
    this.operacionFrecuente.mes_codmes = data.mes;
    this.operacionFrecuente.ldc_corldc = data.voucher;
  }

  registrarOperacionFrecuente(): void {

    this.operacionFrecuente.ofb_coduse = this._configurationService.obtenerIdUsuario();
    this.operacionFrecuente.cia_codcia = this._configurationService.obtenerCompaniaCliente();

    console.log('operacion', this.operacionFrecuente);

    this.loaderReg = true;

    this.registrar$ = this._operacionFrecuenteService.registrarOperacionFrecuente(this.operacionFrecuente).subscribe(
      data => {
        this.dialogRef.close(data);
        this.loaderReg = false;
        this._snackBarService.showSuccess('Operación frecuente registrada', 'Ok');
      },
      error => {
        this._snackBarService.showError('Operación frecuente ya registrada', 'Ok');
        this.loaderReg = false;
      }
    );
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.registrar$])
  }

}
