import { createReducer, on } from "@ngrx/store";
import { LoadingState } from "@data/interfaces/state/loading.state";
import { cambiarLoadingCompany, cambiarLoadingEstablishment, cambiarLoadingModule, cambiarLoadingSidenav, cambiarLoadingTypeNomina } from "../actions/loading.actions";

export const initialState: LoadingState = {
  isLoadingCompany: true,
  isLoadingSidenav: true,
  isLoadingTypeNominaDialog: true,
  isLoadingEstablishment: true,
  isLoadingModule: true,
};

const _loadingReducer = createReducer(
  initialState,
  on(cambiarLoadingCompany, (state, props) => state = {isLoadingCompany: props.isLoadingCompany, isLoadingModule: props.isLoadingModule, isLoadingEstablishment: props.isLoadingEstablishment, isLoadingSidenav: props.isLoadingSidenav, isLoadingTypeNominaDialog: props.isLoadingTypeNominaDialog}),
  on(cambiarLoadingModule, (state, props) => state = {isLoadingCompany: props.isLoadingCompany, isLoadingModule: props.isLoadingModule, isLoadingEstablishment: props.isLoadingEstablishment, isLoadingSidenav: props.isLoadingSidenav, isLoadingTypeNominaDialog: props.isLoadingTypeNominaDialog}),
  on(cambiarLoadingEstablishment, (state, props) => state = {isLoadingCompany: props.isLoadingCompany, isLoadingModule: props.isLoadingModule, isLoadingEstablishment: props.isLoadingEstablishment, isLoadingSidenav: props.isLoadingSidenav, isLoadingTypeNominaDialog: props.isLoadingTypeNominaDialog}),
  on(cambiarLoadingSidenav, (state, props) => state = {isLoadingCompany: props.isLoadingCompany, isLoadingModule: props.isLoadingModule, isLoadingEstablishment: props.isLoadingEstablishment, isLoadingSidenav: props.isLoadingSidenav, isLoadingTypeNominaDialog: props.isLoadingTypeNominaDialog}),
  on(cambiarLoadingTypeNomina, (state, props) => state = {isLoadingCompany: props.isLoadingCompany, isLoadingModule: props.isLoadingModule, isLoadingEstablishment: props.isLoadingEstablishment, isLoadingSidenav: props.isLoadingSidenav, isLoadingTypeNominaDialog: props.isLoadingTypeNominaDialog}),
);

export function loadingReducer(state, action) {
  return _loadingReducer(state, action)
}
