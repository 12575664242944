<h1 mat-dialog-title>Consultar stock mínimo</h1>

<div mat-dialog-content>
  <div class="row">
    <div class="col s12 m12 l4">
      <select-search [label]="'Tipo inventario'" [placeholder]="'Ej. TIPO INVENTARIO'" [name]="'tipoInventario'"
        [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="data.tiposInventario"
        [model]="filtro.tipoInventario" (cambioSelect)="filtro.tipoInventario = $event"></select-search>
    </div>
    <div class="col s12 m12 l4">
      <select-search [label]="'Línea'" [placeholder]="'Ej. LINEA'" [name]="'linea'" [value]="'lpd_codlpd'"
        [description]="'lpd_descri'" [data]="data.lineas" [model]="filtro.linea"
        (cambioSelect)="filtro.linea = $event"></select-search>
    </div>
    <div class="col s12 m12 l4">
      <select-search [label]="'Familia'" [placeholder]="'Ej. FAMILIA'" [name]="'familia'" [value]="'FPR_CODFPR'"
        [description]="'FPR_DESABR'" [data]="data.familias" [model]="filtro.familia"
        (cambioSelect)="filtro.familia = $event; seleccionarFamilia()"></select-search>
    </div>
    <div class="col s12 m12 l4">
      <select-search [label]="'Subfamilia'" [placeholder]="'Ej. SUBFAMILIA'" [name]="'familia'" [value]="'sfp_codsfp'"
        [description]="'sfp_descri'" [data]="subfamilias" [model]="filtro.subfamilia"
        (cambioSelect)="filtro.subfamilia = $event"></select-search>
    </div>
    <div class="col s12 m12 l4">
      <select-search [label]="'Marca'" [placeholder]="'Ej. MARCA'" [name]="'marca'" [value]="'MPR_CODMPR'"
        [description]="'MPR_DESABR'" [data]="data.marcas" [model]="filtro.marca"
        (cambioSelect)="filtro.marca = $event"></select-search>
    </div>
    <div class="col s12 m12 l4">
      <select-search [label]="'Proveedor'" [placeholder]="'Ej. LINEA'" [name]="'proveedor'" [value]="'cli_codcli'"
        [description]="'cli_nomcli'" [data]="data.proveedores" [model]="filtro.proveedor"
        (cambioSelect)="filtro.proveedor = $event"></select-search>
    </div>
    <div class="col s12 m12 l8">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="filtro.estado" name="estado">
        <mat-radio-button value="1" [ngStyle]="{color: '#004101', fontWeight: 'bold'}">Controlado</mat-radio-button>
        <mat-radio-button value="2" [ngStyle]="{color: '#ced52d', fontWeight: 'bold'}">Límite</mat-radio-button>
        <mat-radio-button value="3" [ngStyle]="{color: '#fc0001', fontWeight: 'bold'}">Crítico</mat-radio-button>
        <mat-radio-button value="4">Todo</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null;" [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="producto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-compras/solicitud-de-compra', row.rco_numrco]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>

          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.rco_numrco}} </td>
      </ng-container>

      <ng-container matColumnDef="unidad_medida">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.cli_nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="marca">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> marca </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.rco_fecreq}} </td>
      </ng-container>

      <ng-container matColumnDef="stock_actual">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock actual </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.rco_apruse}} </td>
      </ng-container>

      <ng-container matColumnDef="stock_minimo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock mínimo </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <ng-container matColumnDef="stock_maximo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock máximo </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <ng-container matColumnDef="orden_transito">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Orden tránsito </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <ng-container matColumnDef="lote_ideal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote idel </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <ng-container matColumnDef="compra_sugerida">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Compra sugerida </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <ng-container matColumnDef="familia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Familia </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <ng-container matColumnDef="subfamilia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Subfamilia </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_gloobs}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="13">No se encontraron registros</td>

      </tr>

    </table>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancelar()">Cancelar</button>
  <button mat-stroked-button color="primary" (click)="mover()">Mover</button>
</div>
