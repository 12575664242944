import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { MotivoParadaMaquinaService } from 'src/app/services/api/produccion/maestros/motivo-parada-maquina.service';
import { MotivoParadaMaquina } from 'src/app/models/produccion/maestros/motivo-parada-maquina';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
@Component({
  selector: 'app-motivo-parada-maquina-form',
  templateUrl: './motivo-parada-maquina-form.component.html',
  styleUrls: ['./motivo-parada-maquina-form.component.css']
})
export class MotivoParadaMaquinaFormComponent implements OnInit, OnDestroy {
  loaderReg: boolean = false;
  loaderData: boolean = true;

  motivoParadaMaquina: MotivoParadaMaquina;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  loading$: Subscription;
  sendForm$: Subscription;
  motivoParadaMaquina$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _snackbarService: SnackBarService,
    private _motivoParadaMaquinaService: MotivoParadaMaquinaService
  ) {
    this.motivoParadaMaquina = new MotivoParadaMaquina();
    this.motivoParadaMaquina.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.motivoParadaMaquina.mpm_coduse = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;
      if (this.uid !== '0') {
        this.mostrarMotivoParadaMaquina();
        return;
      }

      this.loaderData = false;

    })
  }


  mostrarMotivoParadaMaquina() {
    this.motivoParadaMaquina$ = this._motivoParadaMaquinaService.mostrarMotivoParadaMaquina(this.uid).subscribe(
      response => {
        this.motivoParadaMaquina = response;
        this.loaderData = false;
      }, error => {
        this._snackbarService.showError('Error al obtener el motivo de parada de maquina', 'OK');
        this.loaderData = false;
      }
    )
  }

  enviarFormulario(): void {
    this.loaderReg = true;
    this.uid === '0' ? this.registrarMotivoParadaMaquina() : this.actualizarMotivoParadaMaquina();
  }

  registrarMotivoParadaMaquina(): void {
    this.sendForm$ = this._motivoParadaMaquinaService.registrarMotivoParadaMaquina(this.motivoParadaMaquina).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Registrado correctamente', '400px', '400px', '');
        this.volver();
      }, error => {
        this.loaderReg = false;
        this._snackbarService.showError('Error al registrar el motivo de parada de maquina', 'OK');
      }
    )
  }

  actualizarMotivoParadaMaquina(): void {
    this.sendForm$ = this._motivoParadaMaquinaService.actualizarMotivoParadaMaquina(this.motivoParadaMaquina).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Actualizado correctamente', '400px', '400px', '');
        this.volver();
      }, error => {
        this.loaderReg = false;
        this._snackbarService.showError('Error al actualizar el motivo de parada de maquina', 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-produccion/motivos-parada-maquina']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.sendForm$, this.loading$, this.motivoParadaMaquina$]);
  }
}
