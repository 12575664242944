import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'cuentas-pagar-fecha-corte',
  templateUrl: './cuentas-pagar-fecha-corte.component.html',
  styleUrls: ['./cuentas-pagar-fecha-corte.component.scss']
})
export class CuentasPagarFechaCorte implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
