<app-pavso-title-section [title]="'Liquidación CTS'" [module]="'Planilla'" [estado]="liquidacion.nct_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l1">
                  <mat-form-field>
                      <mat-label>Año</mat-label>
                      <input type="number" [(ngModel)]="liquidacion.ano_codano" matInput placeholder="Año" name="ano_codano" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l1">
                  <mat-form-field>
                      <mat-label>Mes</mat-label>
                      <input type="number" [(ngModel)]="liquidacion.mes_codmes" matInput placeholder="Mes" name="mes_codmes" required>
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l7">
                  <mat-form-field>
                      <mat-label>Colaboradores</mat-label>
                      <mat-select [(ngModel)]="liquidacion.cli_codcli" required name="cli_codcli">
                          <mat-option *ngFor="let colaborador of colaboradores; trackBy: trackByColaborador" [value]="colaborador.codcli">
                              {{colaborador.nomcli}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Fecha de Registro</mat-label>
                      <input [(ngModel)]="liquidacion.nct_fecemi" name="nct_fecemi"

                      matInput [matDatepicker]="fecharegistro">
                      <mat-datepicker-toggle matSuffix [for]="fecharegistro"></mat-datepicker-toggle>
                      <mat-datepicker #fecharegistro></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Período de liquidación Desde</mat-label>
                      <input [(ngModel)]="liquidacion.nct_iniliq" name="nct_iniliq" matInput [matDatepicker]="periododesde">
                      <mat-datepicker-toggle matSuffix [for]="periododesde"></mat-datepicker-toggle>
                      <mat-datepicker #periododesde></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Hasta</mat-label>
                      <input [(ngModel)]="liquidacion.nct_terliq" name="nct_terliq" matInput [matDatepicker]="periodohasta">
                      <mat-datepicker-toggle matSuffix [for]="periodohasta"></mat-datepicker-toggle>
                      <mat-datepicker #periodohasta></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Tipo de Cambio</mat-label>
                      <input [(ngModel)]="liquidacion.nct_tipcam" type="number" name="nct_tipcam" matInput placeholder="Tipo de Cambio" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <app-currency-select [moneda]="liquidacion.tmo_codtmo" (cambioMoneda)="liquidacion.tmo_codtmo = $event"></app-currency-select>

              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Fecha de Depósito</mat-label>
                      <input [(ngModel)]="liquidacion.nct_fecdep" name="nct_fecdep" matInput [matDatepicker]="fechadeposito">
                      <mat-datepicker-toggle matSuffix [for]="fechadeposito"></mat-datepicker-toggle>
                      <mat-datepicker #fechadeposito></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Período del servicio que se cancela Desde</mat-label>
                      <input [(ngModel)]="liquidacion.nct_fecini" name="nct_fecini" matInput [matDatepicker]="periodocanceladesde">
                      <mat-datepicker-toggle matSuffix [for]="periodocanceladesde"></mat-datepicker-toggle>
                      <mat-datepicker #periodocanceladesde></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Hasta</mat-label>
                      <input [(ngModel)]="liquidacion.nct_fecter" name="nct_fecter" matInput [matDatepicker]="periodocancelahasta">
                      <mat-datepicker-toggle matSuffix [for]="periodocancelahasta"></mat-datepicker-toggle>
                      <mat-datepicker #periodocancelahasta></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12">
                  <h4>Tiempo Computable</h4>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Meses</mat-label>
                      <input [(ngModel)]="liquidacion.nct_nummes" type="number" matInput placeholder="Meses" name="nct_nummes" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Días</mat-label>
                      <input [(ngModel)]="liquidacion.nct_numdia" type="number" matInput placeholder="Días" name="nct_numdia" required>
                  </mat-form-field>
              </div>
          </div>
      </mat-card>
      <br>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <h4>CONCEPTOS REMUNERATIVOS</h4>
                  <mat-form-field>
                      <mat-label>Básico</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_combas" placeholder="Básico" name="nct_combas" required>
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label>Asig. Familiar</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_asifam" placeholder="Asig. Familiar" name="nct_asifam" required>
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label>Prom. Rem. Variable</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_provar" placeholder="Prom. Rem. Variable" name="nct_provar" required>
                  </mat-form-field>

                  <mat-form-field>
                      <mat-label>Otras Remunerac.</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_otrrem" placeholder="Otras Remunerac." name="nct_otrrem" required>
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label>Ult. Gratificación</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_comgra" placeholder="Ult. Gratificación" name="nct_comgra" required>
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label>Importe</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_comtot" placeholder="Importe" name="nct_comtot" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <h4>REMUNERACIÓN COMPUTABLE</h4>
                  <mat-form-field>
                      <mat-label>Por Año</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_remano" placeholder="Por Año" name="nct_remano" required>
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label>Un Dozavo del Mes</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_remmes" placeholder="Un Dozavo del Mes" name="nct_remmes" required>
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label>Una Treintava parte de un Dozavo</mat-label>
                      <input type="number" matInput [(ngModel)]="liquidacion.nct_remdia" placeholder="Una Treintava parte de un Dozavo" name="nct_remdia" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6">
                  <h4>LIQUIDACIÓN DE CTS CANCELATORIA</h4>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label>Por Año</mat-label>
                          <input type="number" [(ngModel)]="liquidacion.nct_canano" matInput placeholder="Por Año" name="nct_canano" required>
                      </mat-form-field>
                  </div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input type="number" [(ngModel)]="liquidacion.nct_impano" matInput name="nct_impano" required>
                      </mat-form-field>
                  </div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label>Por Mes</mat-label>
                          <input type="number" [(ngModel)]="liquidacion.nct_canmes" matInput placeholder="Por Mes" name="nct_canmes" required>
                      </mat-form-field>
                  </div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input type="number" [(ngModel)]="liquidacion.nct_impmes" matInput name="nct_impmes" required>
                      </mat-form-field>
                  </div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label>Por Días</mat-label>
                          <input type="number" [(ngModel)]="liquidacion.nct_candia" matInput placeholder="Por Días" name="nct_candia" required>
                      </mat-form-field>
                  </div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input type="number" [(ngModel)]="liquidacion.nct_impdia" matInput name="nct_impdia" required>
                      </mat-form-field>
                  </div>
                  <div class="col s6"></div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label>Total</mat-label>
                          <input type="number" [(ngModel)]="liquidacion.nct_impnet" matInput placeholder="Total" name="nct_impnet" required>
                      </mat-form-field>
                  </div>
                  <div class="col s6"></div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label>Interés</mat-label>
                          <input [(ngModel)]="liquidacion.nct_impint" type="number" matInput placeholder="Interés" name="nct_impint" required>
                      </mat-form-field>
                  </div>

                  <div class="col s6"></div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label>Importe a Depositar</mat-label>
                          <input [(ngModel)]="liquidacion.nct_impdep" type="number" matInput placeholder="Importe a Depositar" name="nct_impdep" required>
                      </mat-form-field>
                  </div>
                  <div class="col s6"></div>
                  <div class="col s6">
                      <mat-form-field>
                          <mat-label>Importe M.E.</mat-label>
                          <input [(ngModel)]="liquidacion.nct_impmex" type="number" matInput placeholder="Importe M.E." name="nct_impmex" required>
                      </mat-form-field>
                  </div>
              </div>
          </div>
      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
