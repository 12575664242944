<app-pavso-title-section [title]="'Unidades de transporte'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todas las unidades" url="/modulo-almacen/unidad-de-transporte" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <a matTooltip="Editar unidad de transporte"
                      class="pav-btn-circle-edit"
                      [routerLink]="['/modulo-almacen/unidad-de-transporte', row.UTR_CODUTR]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="UTR_CODUTR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.UTR_CODUTR}} </td>
            </ng-container>

            <ng-container matColumnDef="UTR_DESCRI">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.UTR_DESCRI}} </td>
            </ng-container>

            <ng-container matColumnDef="UTR_PLAUTR">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Placa </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.UTR_PLAUTR}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>
      </div>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
