import { Component, Inject } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";

@Component({
  selector: 'orden-compra-bottom-sheet',
  template: `
    <mat-list role="list">
      <mat-list-item role="listitem">
        <span mat-line>Cargar Excel</span>
        <span mat-line>Subir archivo (.csv)</span>

      </mat-list-item>

    </mat-list>
  `,
  styleUrls: ['./orden-compra-bottom-sheet.component.scss']
})
export class OrdenCompraBottomSheet {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<OrdenCompraBottomSheet>,
  ) { }


}
