<div class="pav-table-search-container">
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" [placeholder]="'general.placeholder.search' | translate" #input>
  </div>
</div>

<div class="mat-elevation-z0">
  <div class="pavso_card_more_options">
    <div class="pav-table-header-icon"></div>
    <p class="pav-table-header-title">
      <!-- {{'general.table.title' | translate}} -->
      {{title}}
    </p>
    <div class="isMobile">
      <div class="pav-button-icon-download-excel">
        <mat-icon svgIcon="download"></mat-icon>
      </div>
    </div>
    <div class="isWeb">
      <button class="pav-button-download-excel" mat-flat-button color="primary" (click)="exportarExcel()">
        <mat-icon>file_download</mat-icon>
        Descargar
      </button>
    </div>
    <div class="isMobile">
      <div class="pav-button-icon-add" [routerLink]="obtenerParametros()">
        <mat-icon svgIcon="add"></mat-icon>
      </div>
    </div>
    <div class="isWeb">
      <a [routerLink]="obtenerParametros()">
        <button class="pav-button-add" mat-flat-button color="primary">
          <mat-icon>add</mat-icon>
          Agregar
        </button>
      </a>
    </div>
  </div>

  <div class="overflow-x">
    <table mat-table [dataSource]="dataSource" matSort matTableResponsive>
      <ng-container matColumnDef="accion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'general.table.header.action' | translate}} </th>
          <td mat-cell *matCellDef="let row" data-label="Acciones" class="td-responsive">
            <div class="pav-btns-container">
              <a class="pav-btn-circle-edit" [routerLink]="[url, row[uid]]" matTooltip="Editar">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </a>

              <ng-container *ngIf="entity == 'colaborador'">
                <a *ngIf="row.situacion == 'CESADO'" matTooltip="Activar" class="pav-btn-circle-active" [routerLink]="[url, row[uid]]">
                  <mat-icon svgIcon="active"></mat-icon>
                </a>
              </ng-container>
            </div>
          </td>
      </ng-container>

      <ng-container *ngFor="let header of tHeaders" [matColumnDef]="header.key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{header.header | translate}} </th>
          <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive">
            <span *ngIf="header.key != 'indsta' && header.key != 'situacion'">{{row[header.key]}}</span>
            <span *ngIf="header.key == 'indsta'" [ngClass]="{'pav-tag-green': row[header.key] == '1', 'pav-tag-red': row[header.key] == '0'}">{{row[header.key] == '1' ? 'ACTIVO': 'INACTIVO'}}</span>
            <span *ngIf="header.key == 'situacion'" [ngClass]="{'pav-tag-green': row[header.key] == 'ACTIVO', 'pav-tag-red': row[header.key] == 'CESADO'}">{{row[header.key]}}</span>
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{'general.table.data-not-found' | translate}} "{{input.value}}"</td>
      </tr>
    </table>
  </div>
  <mat-paginator [pageSize]="pageSize" showFirstLastButtons [pageSizeOptions]="[3, 5, 10, 25, 100]"></mat-paginator>

</div>
