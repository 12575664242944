import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DocumentToUpload } from "../../registro-compra-form/registro-compra-form.component";

@Component({
  selector: 'registro-compra-show-xml-dialog',
  template: `
    <h1 matDialogTitle>XML</h1>
    <mat-divider></mat-divider>
    <div mat-dialog-content>
      <pre>
        <code class="language-javascript">
          {{documentToUpload.xmlStr}}
        </code>
      </pre>
    </div>
    <div mat-dialog-actions align="center">
      <button mat-button (click)="cerrar()" class="pav-button-action">Confirmar</button>
    </div>
  `,
  styleUrls: ['registro-compra-show-xml.dialog.scss']
})
export class RegistroCompraShowXmlDialog  {
  constructor(
    public dialogRef: MatDialogRef<RegistroCompraShowXmlDialog>,
    @Inject(MAT_DIALOG_DATA) public documentToUpload: DocumentToUpload,
  ) {}

  cerrar(): void {
    this.dialogRef.close(this.documentToUpload);
  }
}
