interface IDescuentoProductoSubdetalle{
    dco_coddco: string; // Código de DCO - char(4) - NO
}
export class DescuentoProductoSubdetalle{
    cia_codcia: string; // Código de compañía - char(2) - NO
    tbd_numtbd: string; // Número de TBD - char(10) - NO
    prd_codprd: string; // Código de producto - char(14) - NO
    descuento: IDescuentoProductoSubdetalle; // Código de DCO - char(4) - NO
    dco_desdco: string; // Descripción de DCO - varchar(80) - YES//este campo no esta en la bd sino en su tabla padre
    tds_cortds: string; // Código correlativo del descuento - char(2) - NO
    tds_portds: number; // Código de puerto TDS - numeric - NO
    tds_fecupd: Date; // Fecha de actualización TDS - datetime - NO
    tds_coduse: string; // Código de usuario TDS - varchar(30) - NO
    
    constructor() {
        this.tds_fecupd = new Date();
        this.dco_desdco = "";
    }

}