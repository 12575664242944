<app-pavso-title-section [title]="'apps.crm.maestros.cliente.title' | translate" [module]="'CRM'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <pav-table-list
    [data]="clientes"
    [tHeaders]="tHeaders"

    title="Todos los clientes"
    url="/modulo-crm/cliente-crm"
    entity=""
    [pageSize]="10"
    [params]="['0']"
  ></pav-table-list>

  <!-- <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los canales" url="/modulo-crm/canal" entity="canal" [params]="['0']"></table-title-action>

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <button matTooltip="Editar canal" [routerLink]="['/modulo-crm/canal', row.oms_idmtk]" mat-icon-button color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="oms_idmtk">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.oms_idmtk}} </td>
            </ng-container>

            <ng-container matColumnDef="oms_descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.oms_descri}} </td>
            </ng-container>

            <ng-container matColumnDef="oms_indsta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.oms_indsta}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div> -->
</div>
