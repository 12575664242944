export class FiltroMovimientos {
  anioPeriodo: string;
  mesPeriodo: string;
  almacenes: string;
  motivos: string;
  auxiliares: string;
  tipoMovimiento: string;
  fecha: string;
  centroCosto: string;
  tipoDocRef01: string;
  referencia01: string;
  tipoDocRef02: string;
  referencia02: string;
  cliente: string;

  constructor() {
    this.tipoMovimiento  = 'ING';
  }
}
