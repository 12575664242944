import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { PropietarioActivo } from "src/app/models/crm/propietario-activo";
import { PropietarioActivoService } from "src/app/services/api/activo-fijo/maestros/propietario-activo.service";

@Component({
  selector: 'propietario-activo-form',
  templateUrl: './propietario-activo-form.component.html',
  styleUrls: ['./propietario-activo-form.component.scss']
})
export class PropietarioActivoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  propietario: PropietarioActivo;

  loading$: Subscription;
  sendForm$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _propietarioActivoService: PropietarioActivoService,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.uid == '0'? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
    })
  }

  enviarFormulario(): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarPropietario(): this.actualizarPropietario();
  }

  registrarPropietario(): void {
    this.sendForm$ = this._propietarioActivoService.registrarPropietarioActivo(this.propietario).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Propietario activo registrado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarPropietario(): void {
    this.sendForm$ = this._propietarioActivoService.actualizarPropietarioActivo(this.propietario).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Propietario activo actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$
    ])
  }
}
