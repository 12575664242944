<h1 mat-dialog-title>Agregar producto</h1>
<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
<div mat-dialog-content>

  <div class="row">
    <div class="col s12">
      <div [ngStyle]="{display: 'flex', justifyContent: 'space-between'}">
        <p [ngStyle]="{fontSize: '12px'}">
          Stk fisico: {{stockFisico}}
        </p>
        <p [ngStyle]="{fontSize: '12px'}">
          Stk dispon.: {{stockDisponible}}
        </p>
        <p [ngStyle]="{fontSize: '12px'}">
          Stk reserv.: {{stockReservado}}
        </p>

        <div [ngStyle]="{paddingTop: '10px'}" matTooltip="Ver imagen del producto">
          <mat-icon (click)="verImagen()" [ngStyle]="{cursor: 'pointer'}">photo_camera</mat-icon>
        </div>

      </div>

    </div>
    <div class="col s12">
      <div [ngStyle]="{display: 'flex', width: '100%'}">
        <select-search [ngStyle]="{width: '100%'}" [label]="'Producto'" [placeholder]="'Ej. CANASTA'"
          [name]="'prd_descri'" [value]="'prd_codprd'" [description]="'description'" [data]="data.products"
          (cambioSelect)="productoSeleccionado.product = $event; seleccionarProducto()"
          [model]="productoSeleccionado.product" [disabled]="loaderData">
        </select-search>

      </div>
    </div>

    <div class="col s12">
      <mat-form-field class="example-full-width">
        <mat-label>Cantidad</mat-label>
        <input [disabled]="loaderData" #iCantidad type="number" [(ngModel)]="productoSeleccionado.cantidad" name="cantidad" matInput
          placeholder="Ej. 10" (focus)="inputCantidad.nativeElement.select()" (keyup.enter)="enterCantidad()">
      </mat-form-field>
    </div>

    <div class="col s12" *ngIf="data.canAddPrice">
      <mat-form-field class="example-full-width">
        <mat-label>Precio</mat-label>
        <input [disabled]="loaderData" #iPrecio type="number" [(ngModel)]="productoSeleccionado.precio" name="precio" matInput [readonly]="data.canEditPrice == 0"
          placeholder="Ej. 23.00" (focus)="inputPrecio.nativeElement.select()" (keyup.enter)="enterPrecio()">
      </mat-form-field>
    </div>

    <div class="col s6" *ngIf="data.canAddDiscount">
      <mat-form-field class="example-full-width">
        <mat-label>(%) Descuento</mat-label>
        <input [disabled]="loaderData" #iPorDescuento type="number" [(ngModel)]="productoSeleccionado.porDesc" name="porDesc" matInput
          placeholder="Ej. 10" (focus)="inputPorDescuento.nativeElement.select()" (keyup.enter)="enterPorDescuento()">
      </mat-form-field>
    </div>

    <div class="col s6" *ngIf="data.canAddDiscount">
      <mat-form-field class="example-full-width">
        <mat-label>Importe Desc.</mat-label>
        <input [disabled]="loaderData" type="number" [(ngModel)]="productoSeleccionado.impDesc" name="impDesc" matInput placeholder="Ej. 10"
          readonly>
      </mat-form-field>
    </div>

    <ng-container *ngIf="data.canAddCost">

      <div class="col s12">
        <mat-form-field class="example-full-width">
          <mat-label>Costo M.N.</mat-label>
          <input [disabled]="loaderData" #iCostoMN type="number" [(ngModel)]="productoSeleccionado.costoMN" name="costoMN" matInput
            placeholder="Ej. 10" (keyup)="keyupCostoMN()" (keyup.enter)="enterCostoMN()">
        </mat-form-field>
      </div>
      <div class="col s12">
        <mat-form-field class="example-full-width">
          <mat-label>Costo M.E.</mat-label>
          <input [disabled]="loaderData" #iCostoME type="number" [(ngModel)]="productoSeleccionado.costoME" name="costoME" matInput
            placeholder="Ej. 10">
        </mat-form-field>
      </div>
    </ng-container>
  </div>
</div>
<div>
  <div class="">
    <div>
      <button [ngStyle]="{
        'width': '100%'
      }" mat-flat-button (click)="onNoClick()" class="pav-button-cancel">Cancelar</button>
    </div>
    <div [ngStyle]="{marginTop: '10px'}">
      <button [ngStyle]="{
        'width': '100%',
        'color': 'white'
      }" mat-flat-button cdkFocusInitial (click)="confirmar()" class="pav-button-add">Agregar</button>
    </div>
  </div>

</div>
