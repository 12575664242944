
export class OrdenPedido {
  codcia: string;
  codsuc: string;
  numpcc: string;
  codcli: string;
  dircli: string;
  codcti: string;
  fecdoc: Date;
  codmmo: string;
  codcpa: string;
  codtmo: string;
  codvde: string;
  indcal: string;
  tasigv: number;
  tipcam: number;
  impbru: number;
  pordes: number;
  impdes: number;
  valvta: number;
  impigv: number;
  imptot: number;
  sitpcc: string;
  ordcom: string;
  indtex: string;
  refpcc: string;
  impseg: number;
  impfle: number;
  indexp: string;
  plaent: number;
  fecven: Date;
  diacre: number;
  indigv: string;
  pedmer: string;
  ordweb: string;
  fectra: Date;
  codpro: string;
  agetra: string;
  indant: string;
  codzve: string;
  obspcc: string;
  codedo: string;
  codtlp: string;
  codlpc: string;
  indtca: string;
  indimp: number;
  indcom: number;
  inddpx: number;
  fecdes: Date;
  codtie: string;
  fecent: Date;
  codpai: string;
  codubi: string;
  diapla: number;
  codalm: string;
  tcalib: number;
  indsta: string;
  numtbd: string;
  codano: string;
  codmes: string;
  codcco: string;
  codant: string;
  indres: number;
  impcan: number;
  indter: string;
  noimda: number;
  numcuo: number;
  feccre: Date;
  obsped: string;
  codlde: string;
  codfac: string;
  susval: number;
  sustot: number;
  fecfac: Date;
  fecter: Date;
  useter: string;
  refped: string;
  secpap: number;
  obsexp: string;
  indmax: number;
  numsep: string;
  fecupd: Date;
  coduse: string;
  codsec: string;
  codcpd: string;
  indotn: number;
  otrnom: string;
  agelde: string;
  codpde: string;
  entvde: number;
  codtdo: string;
  numpec: string;
  ptodes: string;
  pedcom: number;
  obsfac: string;
  anucom: string;
  coddes: string;
  desglo: number;
  condic: string;
  valofe: string;
  forpag: string;
  nomcon: string;
  carcon: string;
  tlfcon: string;
  maicon: string;
  solici: string;
  indap1: number;
  indap2: number;
  codap1: string;
  fecap1: Date;
  fecap2: Date;
  tieent: string;
  paiori: string;
  embarq: string;
  llegada: string;
  tiptra: string;
  ciuemb: string;
  embarc: string;
  flete: number;
  seguro: number;
  codccm: string;
  solofe: string;
  fecsol: Date;
  avimpo: string;
  codccl: string;
  idtkt: string;
  idset: string;
  COTI_COME_PCD: Array<CotiComePcd>;
  COTI_COME_CUOT_PCU: Array<CotiComeCuotPcu>;
  COTI_COME_DIAS_PDI: Array<CotiComeDiasPdi>;

  constructor() {

    this.numpcc = null;

    this.fecupd = new Date();
    this.indsta = '1';
    this.tipcam = 0;
    this.codmmo = '0206';
    this.refpcc = ' ';
    this.indimp = 0;
    this.indcom = 0;
    this.inddpx = 0;

    this.impbru = 0;
    this.valvta = 0;
    this.impigv = 0;
    this.impdes = 0;
    this.imptot = 0;
    this.plaent = 0;
    this.diacre = 0;
    this.codedo = '1';

    this.impseg = 0;
    this.impfle = 0;
    this.indexp = '0';
    this.codtie = '01';
    this.diapla = 0;
    this.tcalib = 0;
    this.indres = 0;
    this.impcan = 0;
    this.noimda = 0;
    this.numcuo = 0;
    this.susval = 0;
    this.sustot = 0;
    this.obsexp = '';
    this.indmax = 0;
    this.codsec = '';
    this.entvde = 0;
    this.pedcom = 0;
    this.desglo = 0;
    this.indap1 = 0;
    this.indap2 = 0;
    this.indter = '0';

    this.COTI_COME_PCD = [];
    this.COTI_COME_CUOT_PCU = [];
    this.COTI_COME_DIAS_PDI = [];
  }
}

export class CotiComePcd {
  codprd: string;
  secpcd: string;
  corpcd: string;
  cansol: number;
  canaut: number;
  cantra: number;
  canate: number;
  canfas: number;
  canats: number;
  canfac: number;
  cansus: number;
  candis: number;
  canven: number;
  candes: number;
  preuni: number;
  pruigv: number;
  impbru: number;
  pordes: number;
  impdes: number;
  valvta: number;
  impigv: number;
  imptot: number;
  vtasus: number;
  totsus: number;
  glopcd: string;
  obspcd: string;
  indode: number;
  inddes: number;
  obspre: string;
  usupre: string;
  fecpre: Date;
  indsof: number;
  indsod: number;
  indaut: number;
  indate: number;
  indfac: number;
  codven: string;
  atreg: number;
  autreg: number;
  fasreg: number;
  facreg: number;
  desreg: number;
  obsadi: string;
  coddcm: string;
  tipdes: string;
  prelis: number;
  inddpr: string;
  codprm: string;
  canuni: number;
  meduni: string;
  unisus: number;
  venfac: number;
  coduse: string;
  fecupd: Date;
  indsta: string;
  desglo: number;
  indfot: number;
  indimp: number;
  indesp: number;
  entrega: string;
  atsreg: string;
  COTI_COME_PROD_DESC_PDS: Array<CotiComeProdDescPds>;
  COTI_COME_ESPE_ITEM_CCI: Array<CotiComeEspeItemCci>;
  COTI_COME_DATO_ADIC_PDA: Array<CotiComeDatoAdicPda>;
  isEditing: boolean;
  isSelect: boolean;

  constructor(){
    this.fecupd = new Date();
    this.indsta = '1';
    this.isEditing = true;
    this.isSelect = false;
    this.cansol = 0;
    this.preuni = 0;
    this.pruigv = 0;
    this.impbru = 0;
    this.pordes = 0;
    this.impdes = 0;
    this.valvta = 0;
    this.impigv = 0;
    this.imptot = 0;
    this.canaut = 0;

		this.cantra = 0;
		this.canate = 0;
		this.canfas = 0;
		this.canats = 0;
		this.canfac = 0;
		this.cansus = 0;
    this.candis = 0;
    this.canven = 0;
    this.candes = 0;
    this.vtasus = 0;

    this.totsus = 0;
    this.indode = 0;
    this.inddes = 0;
    this.indsof = 0;
    this.indsod = 0;
    this.indaut = 0;
    this.indate = 0;
    this.indfac = 0;
    this.atreg = 0;
    this.autreg = 0;
    this.fasreg = 0;
    this.facreg = 0;
    this.desreg = 0;
    this.prelis = 0;
    this.canuni = 0;
    this.unisus = 0;
    this.venfac = 0;
    this.desglo = 0;
    this.indfot = 0;
    this.indimp = 0;
    this.indesp = 0;

    this.inddpr = '0';

    this.atsreg = '0';

    this.COTI_COME_PROD_DESC_PDS = [];
    this.COTI_COME_ESPE_ITEM_CCI = [];
    this.COTI_COME_DATO_ADIC_PDA = [];
  }
}

export class CotiComeProdDescPds {
  codprd: string;
  secpcd: string;
  coddco: string;
  corpds: string;
  pordes: number;
  porori: number;
  impdes: number;
  numdde: string;
  usuaut: string;
  fecaut: Date;
  impcom: number;
  incdes: number;
  indsta: string;
  fecupd: Date;
  coduse: string;
  isEditing: boolean;

  constructor(){
    this.fecupd = new Date();
    this.indsta = '1';
    this.isEditing = true;
  }
}

export class CotiComeCuotPcu {

  diapcu: number;
  fecsug: Date;
  indsta: string;
  coduse: string;
  fecupd: Date;
  isEditing: boolean;

  constructor(){
    this.fecupd = new Date();
    this.indsta = '1';
    this.isEditing = true;
  }
}

export class CotiComeDatoAdicPda {

  codprd: string;
  corpda: string;
  glopda: string;
  coduse: string;
  fecupd: Date;
  isEditing: boolean;

  constructor(){
    this.fecupd = new Date();
    this.isEditing = true;
  }
}

export class CotiComeDiasPdi {
  coddia: string;
  coduse: string;
  fecupd: Date;
  isEditing: boolean;

  constructor(){
    this.fecupd = new Date();
    this.isEditing = true;
  }
}

export class CotiComeEspeItemCci {

  codprd: string;
  secpcd: string;
  seccci: string;
  descri: string;
  isEditing: boolean;

  constructor(){
    this.isEditing = true;
  }
}


/***
 * quitar motivo (X)
 * direccion fact (X)
 * lugar despacho (X)
 * atender completo (X)
 * entregar vendedor (X)
 * copiar pedido o cotizacion
 * tabla dias de entrega
 * documentos generados por pedido
 * emit doc, entrega, zona de venta (X)
 * centro de costo o proyecto (X)
 * transportista (X)
 * agenci prov (X)
 * suc age (X)
 * toda la caja de almacen (X)
 * en el detalle, susnpende y promocion (X)
 * check de terminado (X)
 * abonado no va (X)
 * observacion comprobante (X)
 *
 *
 * convertir a cotizacion y ver cotizacion
 */
