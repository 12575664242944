export class Permisos {
  canCreate: boolean;
  canDownloadToExcel: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canView: boolean;
  canUpload: boolean;
  canDownload: boolean;
  canApprove: boolean;
  canReject: boolean;
  canSend: boolean;
  canReceive: boolean;
  canCancel: boolean;
  canPrint: boolean;
  canExport: boolean;
  canImport: boolean;
  canExportToPdf: boolean;
  canExportToXml: boolean;
  canExportToCsv: boolean;

  constructor() {
      this.canCreate = false;
      this.canDownloadToExcel = false;
      this.canEdit = false;
      this.canDelete = false;
      this.canView = false;
      this.canUpload = false;
      this.canDownload = false;
      this.canApprove = false;
      this.canReject = false;
      this.canSend = false;
      this.canReceive = false;
      this.canCancel = false;
      this.canPrint = false;
      this.canExport = false;
      this.canImport = false;
      this.canExportToPdf = false;
      this.canExportToXml = false;
      this.canExportToCsv = false;
  }
}
