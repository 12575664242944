<app-pavso-title-section [title]="'Modelo'" [module]="'Almacén'" [estado]="modelo.estado"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input [(ngModel)]="modelo.codigo" type="text" matInput placeholder="Grupo de Activo" name="gac_codgac" required
              >
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input [(ngModel)]="modelo.descripcion" type="text" matInput placeholder="Descripción" name="gac_deslar" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción Corta</mat-label>
            <input [(ngModel)]="modelo.descripcionCorta" type="text" matInput placeholder="Descripción Corta" name="gac_descor" required
              >
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [btnName]="''" [isLoading]="loaderReg"
      urlBack="/modulo-activo-fijo/grupos-de-activo">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
