import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Contact, DocumentSerie, Driver, Establishment, Seller, TransportUnit } from 'src/app/models';
import { Brand } from 'src/app/models/ventas/brand';
import { CanalVenta } from 'src/app/models/ventas/canal-venta';
import { ConceptoDescuento } from 'src/app/models/ventas/concepto-descuento';
import { TarjetaCredito } from 'src/app/models/ventas/tarjeta-credito';
import { Tienda } from 'src/app/models/ventas/tienda';
import { TipoDocumentoIdentidad } from 'src/app/models/ventas/tipo-documento-identidad';
import { TipoListaPrecio } from 'src/app/models/ventas/tipo-lista-precio';
import { ZonaVenta } from 'src/app/models/ventas/zona-venta';
import { ConfigurationService } from '../configuration.service';
import { Supervisor } from 'src/app/models/ventas/supervisor';
import { GiroNegocio } from 'src/app/models/ventas/giro-negocio';
import { MotivoMovimiento } from 'src/app/models/ventas/motivo-movimiento';
import { map } from 'rxjs/operators';
import { IParam, generarParams } from './utilities/params';

@Injectable({
  providedIn: 'root',
})
export class VentasService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeComprobantesXCliente(filtro, year, month): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/informe-comprobantes-por-cliente/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      filtro
    );
  }

  /**
   *
   * @returns
   */
  obtenerClientesComerciales(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/listaclientes/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cli_codcli
   * @returns
   */
  obtenerClienteComercial(cli_codcli): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/mostrarcliente/${this._configurationService.obtenerCompaniaCliente()}/${cli_codcli}`
    );
  }

  /**
   *
   * @param cli_codcli
   * @returns
   */
  obtenerCliente(cli_codcli): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${cli_codcli}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  agregarClienteComercial(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/creareditarcliente`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarClienteComercial(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/creareditarcliente`,
      data
    );
  }

  /**
   *
   * @returns
   */
  obtenerListaDePrecios(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/listarlistaprecios/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param tipo
   * @param code
   * @returns
   */
  obtenerListaDePrecio(tipo, code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/mostrarlistapreciosproductos/${this._configurationService.obtenerCompaniaCliente()}/${tipo}/${code}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerTiposDeListaDePrecios(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/listatipolista/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  registrarTipoListaDePrecio(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/creareditartipolista`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarTipoListaDePrecio(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/creareditartipolista`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  registrarListaDePrecio(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/creareditarlistaprecio`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarListaDePrecio(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/creareditarlistaprecio`,
      data
    );
  }

  /**
   *
   * @returns
   */
  obtenerPedidos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidos/listapedidos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param pcc_numpcc
   * @returns
   */
  obtenerPedido(pcc_numpcc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidos/mostrarpedido/${this._configurationService.obtenerCompaniaCliente()}/${pcc_numpcc}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  registrarPedido(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidos/creareditarpedido`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarPedido(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidos/creareditarpedido`,
      data
    );
  }

  /**
   *
   * @param pcc_numpcc
   * @returns
   */
  mostrarPdfPedido(pcc_numpcc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidos/mostrarpedidopdf/${this._configurationService.obtenerCompaniaCliente()}/${pcc_numpcc}`
    );
  }

  /**
   *
   * @param pcc_numpcc
   * @param estado
   * @returns
   */
  cambiarEsdoPedido(pcc_numpcc, estado): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/pedidos/aprobarpedido/${this._configurationService.obtenerCompaniaCliente()}/${pcc_numpcc}/pavso/${estado}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  obtenerPedidosFiltro(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listagenerarcomprobantepedido`,
      data
    );
  }

  /**
   *
   * @param pcc_numpcc
   * @returns
   */
  obtenerPedidoComprobante(pcc_numpcc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listacomprobantexpedido/${this._configurationService.obtenerCompaniaCliente()}/${pcc_numpcc}`
    );
  }

  /**
   *
   * @param fecha_ini
   * @param fecha_fin
   * @returns
   */
  obtenerComprobantes(fecha_ini, fecha_fin): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listacomprobantes/${this._configurationService.obtenerCompaniaCliente()}/${fecha_ini}/${fecha_fin}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerTotalComprobantes(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listacomprobantes/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  obtenerComprobante(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/mostrarcomprobante/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  crearComprobante(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/creareditarcomprobante`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarComprobante(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/creareditarcomprobante`,
      data
    );
  }

  /**
   *
   * @param tipo
   * @param serie
   * @returns
   */
  obtenerCorrelativoComprobante(tipo, serie): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/correlativocomprobante/${this._configurationService.obtenerCompaniaCliente()}/${tipo}/${serie}`
    );
  }

  /**
   *
   * @param tipo
   * @param cli_codcli
   * @returns
   */
  obtenerReferenciaDocumento(tipo, cli_codcli): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/documentoreferenciancrndb/${this._configurationService.obtenerCompaniaCliente()}/${tipo}/${cli_codcli}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerCuentaContableVenta(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/cuentacontableventa/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerSerieFacturacion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listaseriefac/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }



  /**
   *
   * @returns
   */
  obtenerTiposPrecio(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/tipoprecios/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((tipos: any) => {
        return tipos.map(tipo => {
          tipo['description'] = `${tipo.TLP_CODTLP} | ${tipo.tlp_descri}`
          return tipo;
        })
      })
    );
  }

  /**
   *
   * @returns
   */
  obtenerClientes(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/clientes/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param client_id
   * @returns
   */
  detalleCliente(client_id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/datoscliente/${this._configurationService.obtenerCompaniaCliente()}/${client_id}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerVendedores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/vendedores/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param client_id
   * @returns
   */
  obtenerLugaresDespacho(client_id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/lugardespachos/${this._configurationService.obtenerCompaniaCliente()}/${client_id}`
    );
  }

  obtenerConfiguracionTecnicaVentas(): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablaconta/listar/${this._configurationService.obtenerCompaniaCliente()}/CONFIGURACIONTECNICAVENTAS?registrounico=true`
    );
  }

  /**
   *
   * @returns
   */
  obtenerDescuentosPipe(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/descuentos/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((descuentos: any) => {
        return descuentos.map(descuento => {
          descuento['description'] = `${descuento.dco_coddco} | ${descuento.dco_desdco}`
          return descuento;
        })
      })
    )
  }
  obtenerDescuentos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/descuentos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod_product
   * @param cod_moneda
   * @param cod_cliente
   * @param tipo_lista
   * @param nro_lista
   * @param fecha
   * @returns
   */
  listaDePreciosProducto(
    cod_product,
    cod_moneda,
    cod_cliente,
    tipo_lista,
    nro_lista,
    fecha
  ): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/listaprecioproducto/${this._configurationService.obtenerModo()}/${cod_product}/${cod_moneda}/${cod_cliente}/${tipo_lista}/${nro_lista}/${fecha}`
    );
  }

  /**
   *
   * @param cod_tipo_lista
   * @returns
   */
  listaDePrecios(cod_tipo_lista): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/listaprecios/${this._configurationService.obtenerCompaniaCliente()}/${cod_tipo_lista}?estado=1`
    );
  }

  /**
   *
   * @param fecha
   * @returns
   */
  obtenerTipoCambio(fecha): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/consultatipocambio/01/${fecha}/SO`
    );
  }

  /**
   *
   * @param client_id
   * @returns
   */
  obtenerSucursalAgencias(client_id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/agenciasucursal/${this._configurationService.obtenerCompaniaCliente()}/${client_id}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerFormaCalculaItem(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/formacalculoitem`
    );
  }

  /**
   *
   * @returns
   */
  listarTiendas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/listatienda/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param store
   * @returns
   */
  registrarTienda(store: Tienda): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/tienda`,
      store
    );
  }

  /**
   *
   * @param store
   * @returns
   */
  actualizarTienda(store: Tienda): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/tienda`,
      store
    );
  }

  /**
   *
   * @param body
   * @param year
   * @param month
   * @returns
   */
  informeRegistroVentas(body, year, month) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/registroventa/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      body
    );
  }

  /**
   *
   * @param body
   * @returns
   */
  informeRegistroDetalladoVentas(body): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/detafacturacion/${this._configurationService.obtenerCompaniaCliente()}`,
      body
    );
  }

  /**
   *
   * @returns
   */
  listarTipoDocumentosVenta(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/listadocumentoventa`
    );
  }

  /**
   *
   * @returns
   */
  listarSectoristas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/sectorista/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarCalificacion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/calificacion`
    );
  }

  /**
   *
   * @returns
   */
  listarZonaVenta(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/zonaventa`
    );
  }

  /**
   *
   * @returns
   */
  listarGiroDeNegocio(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/gironegocio/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param ubicacion_id
   * @returns
   */
  listarUbicacionSunat(ubicacion_id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/ubicacionubi/${ubicacion_id}`
    );
  }

  /**
   *
   * @param sucursal_id
   * @param anio
   * @param mes
   * @param almacen_id
   * @param producto_id
   * @returns
   */
  consultaStock(
    sucursal_id,
    anio,
    mes,
    almacen_id,
    producto_id
  ): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/consultastock/${this._configurationService.obtenerCompaniaCliente()}/${sucursal_id}/${anio}/${mes}/${almacen_id}/${producto_id}`
    );
  }

  /**
   *
   * @returns
   */
  listarProductosComprobante(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/productocomprobante`
    );
  }

  /**
   *
   * @returns
   */
  listarSegmentos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/segmento`
    );
  }

  /**
   *
   * @param cliente_id
   * @param despacho_id
   * @returns
   */
  obtenerDiasEntrega(cliente_id, despacho_id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/diasentregas/${this._configurationService.obtenerCompaniaCliente()}/${cliente_id}/${despacho_id}`
    );
  }

  /**
   *
   * @returns
   */
  listarUnidadesTransporte() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listarunidadtransporte/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((unidadesTransportes: any) => {
        return unidadesTransportes.map(unidadesTransporte => {
          unidadesTransporte['description'] = `${unidadesTransporte.UTR_CODUTR} | ${unidadesTransporte.UTR_DESCRI}`
          return unidadesTransporte;
        })
      })
    );
  }

  /**
   *
   * @param transportUnit
   * @returns
   */
  registrarUnidadTransporte(transportUnit: TransportUnit) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/unidad-de-transporte`,
      transportUnit
    );
  }

  /**
   *
   * @param transportUnit
   * @returns
   */
  actualizarUnidadTransporte(transportUnit: TransportUnit) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/unidad-de-transporte`,
      transportUnit
    );
  }

  /**
   *
   * @param conductor
   * @returns
   */
  registrarConductor(conductor: Driver) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/conductor`,
      conductor
    );
  }

  /**
   *
   * @param conductor
   * @returns
   */
  actualizarConductor(conductor: Driver) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/conductor`,
      conductor
    );
  }

  /**
   *
   * @param tarjeta
   * @returns
   */
  registrarTarjetaCredito(tarjeta: TarjetaCredito) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/tarjeta-de-credito`,
      tarjeta
    );
  }

  /**
   *
   * @param tarjeta
   * @returns
   */
  actualizarTarjetaCredito(tarjeta: TarjetaCredito) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/tarjeta-de-credito`,
      tarjeta
    );
  }

  /**
   *
   * @returns
   */
  obtenerZonasVenta(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/zona-de-venta/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param zona
   * @returns
   */
  registrarZonaVenta(zona: ZonaVenta) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/zona-de-venta`,
      zona
    );
  }

  /**
   *
   * @param zona
   * @returns
   */
  actualizarZonaVenta(zona: ZonaVenta) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/zona-de-venta`,
      zona
    );
  }

  /**
   *
   * @returns
   */
  obtenerCanalesVenta() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/canal-de-venta`
    )
  }

  /**
   *
   * @param canal
   * @returns
   */
  registrarCanalVenta(canal: CanalVenta) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/canal-de-venta`,
      canal
    );
  }
  /**
 *
 * @param canal
 * @returns
 */
  actualizarCanalVenta(canal: CanalVenta) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/canal-de-venta`,
      canal
    );
  }
  /**
   *
   * @returns
   */
  obtenerTiposDocumentoIdentidad(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/tipoidentidad`,
    );
  }

  /**
   *
   * @param tipoDoc
   * @returns
   */
  registrarTipoDocumentoIdentidad(tipoDoc: TipoDocumentoIdentidad) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/tipo-documento-identidad`,
      tipoDoc
    );
  }

  /**
   *
   * @param tipoDoc
   * @returns
   */
  actualizarTipoDocumentoIdentidad(tipoDoc: TipoDocumentoIdentidad) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/tipo-documento-identidad`,
      tipoDoc
    );
  }

  /**
   *
   * @param marca
   * @returns
   */
  registrarMarca(marca: Brand) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/marca`,
      marca
    );
  }

  /**
   *
   * @param marca
   * @returns
   */
  actualizarMarca(marca: Brand) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/marca`,
      marca
    );
  }

  /**
   *
   * @returns
   */
  obtenerDataFormCliente(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/forms/cliente/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   * Obtener Nro. Doc. Factura
   * @param tipoDoc
   * @param serie
   * @returns
   */
  obtenerNroDocFactura(tipoDoc, serie): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/nro-documento-factura/${this._configurationService.obtenerCompaniaCliente()}/${tipoDoc}/${serie}`
    );
  }

  /**
   * Obtener Nro. Doc. Guía de Remisión
   * @param tipoDoc
   * @param serie
   * @returns
   */
  obtenerNroDocGuia(tipoDoc, serie): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/nro-documento-guia/${this._configurationService.obtenerCompaniaCliente()}/${tipoDoc}/${serie}`
    );
  }

  /**
   * Obtener Data Filtro Ventas
   * @returns
   */
  obtenerDataFiltroVentas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/filtro-reporte/ventas/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  obtenerReporteHistorialPedidos(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-ventas/historial-pedidos/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  obtenerReporteDetalladoPedidos(year, month, filtro): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-ventas/detallado-pedidos/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      filtro
    );
  }

  /**
   *
   * @param filtro
   *
   * @returns
   */
  obtenerReporteRegistroVentas(filtro, year, month): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-ventas/registro-ventas/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      filtro
    );
  }

  /**
   *
   * @param filtro
   * @param year
   * @returns
   */
  obtenerReporteResumenAnual(filtro, year): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-ventas/resumen-anual/${this._configurationService.obtenerCompaniaCliente()}/${year}`,
      filtro
    );
  }

  /**
   *
   * @param filtro
   * @returns
   */
  obtenerReporteResumenTrimestral(filtro): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-ventas/resumen-trimestral/${this._configurationService.obtenerCompaniaCliente()}`,
      filtro
    );
  }

  /**
   *
   * @param filtro
   * @returns
   */
  obtenerReporteProductoDejadosVender(year, filtro): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-ventas/productos-dejados-vender/${this._configurationService.obtenerCompaniaCliente()}/${year}`,
      filtro
    )
  }

  /**
   *
   * @param payload
   * @returns
   */
  obtenerResumenAnual(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/resumen-anual/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   *
   * @param year
   * @param payload
   * @returns
   */
  obtenerResumenPeriodo(year, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/resumen-periodo/${this._configurationService.obtenerCompaniaCliente()}/${year}`,
      payload
    )
  }

  /**
   *
   * @param payload
   * @returns
   */
  obtenerInformeDinamicoMaestroClientes(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-dinamico/maestro-clientes/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   *
   * @param payload
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeDinamicoRegistroVentas(payload, year, month): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-dinamico/registro-ventas/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    )
  }

  /**
   *
   * @param payload
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeDinamicoRegistroDetalladoVentas(payload, year, month): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-dinamico/registro-detallado-ventas/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    )
  }

  /**
   * Informe diagrama de PARETO
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeDiagramaPareto(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-diagrama-pareto/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  obtenerInformeDinamicoProductoAnual(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/reporte-dinamico/producto-anual/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  obtenerDias(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablaventa/listar/${this._configurationService.obtenerCompaniaCliente()}/DIAS`
    )
  }

  /* REGISTRAR ESTABLECIMIENTO */
  registrarEstablecimiento(establecimiento: Establishment) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/agregarlugar`,
      establecimiento
    );
  }

  registrarContacto(contacto: Contact) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/agregarcontacto`,
      contacto
    );
  }
  actualizarContacto(contacto: Contact) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/modificarcontacto`,
      contacto
    );
  }

  actualizarEstablecimiento(establecimiento: Establishment) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/modificarlugar`,
      establecimiento
    );
  }

  /*CRUD TIPO LISTA PRECIO */
  obtenerTipoListaPrecio(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tipolistaprecio/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }
  mostrarTipoListaPrecio(tipo_lista_uid: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tipolistaprecio/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${tipo_lista_uid}`
    );
  }
  /**
   *
   * @param tipoLista
   * @returns
   */
  registrarTipoListaPrecio(tipoLista: TipoListaPrecio) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/tipolistaprecio/crear`,
      tipoLista
    );
  }

  /**
   *
   * @param tipoLista
   * @returns
   */
  actualizarTipoListaPrecio(tipoLista: TipoListaPrecio) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/tipolistaprecio/modificar`,
      tipoLista
    );
  }
  /*CRUD CONCEPTO DESCUENTO */
  /**
   *
   * @param concepto
   * @returns
   */
  registrarConceptoDescuento(concepto: ConceptoDescuento) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/descuento/crear`,
      concepto
    );
  }

  /**
   *
   * @param concepto
   * @returns
   */
  actualizarConceptoDescuento(concepto: ConceptoDescuento) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/descuento/modificar`,
      concepto
    );
  }

  obtenerConceptosDescuento(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/descuento/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }
  mostrarConceptoDescuento(concepto_uid: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/descuento/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${concepto_uid}`
    );
  }

  /*CRUD SUPERVISOR*/

  obtenerSupervisores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/supervisor/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }
  mostrarSupervisor(supervisor_uid: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/supervisor/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${supervisor_uid}`
    );
  }
  registrarSupervisor(supervisor: Supervisor) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/supervisor/crear`,
      supervisor
    );
  }
  actualizarSupervisor(supervisor: Supervisor) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/supervisor/modificar`,
      supervisor
    );
  }

  /*CRUD GIRO-NEGOCIO*/

  obtenerGirosNegocio(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/gironegocio/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }
  mostrarGiroNegocio(giro_negocio_uid: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/gironegocio/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${giro_negocio_uid}`
    );
  }
  registrarGiroNegocio(giro_negocio: GiroNegocio) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/gironegocio/crear`,
      giro_negocio
    );
  }
  actualizarGiroNegocio(giro_negocio: GiroNegocio) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/gironegocio/modificar`,
      giro_negocio
    );
  }
  /*CRUD MOTIVO MOVIMIENTO*/

  obtenerMotivosMovimiento({ params = [] }: { params?: Array<IParam> } = {}): Observable<any> {
    // { numero = 0, texto = '' }: { numero?: number, texto?: string } = {}

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/motivos/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    ).pipe(
      map((motivo: any) => {
        return motivo.map(motivos => {
          motivos['description'] = `${motivos.mmo_codmmo} | ${motivos.mmo_descri}`
          return motivos;
        })
      })
    );
  }
  mostrarMotivoMovimiento(motivoMovimiento_uid: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/motivos/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${motivoMovimiento_uid}`
    );
  }
  registrarMotivoMovimiento(motivoMovimiento: MotivoMovimiento) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/motivos/crear`,
      motivoMovimiento
    );
  }
  actualizarMotivoMovimiento(motivoMovimiento: MotivoMovimiento) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/motivos/modificar`,
      motivoMovimiento
    );
  }

  /* Descuento Producto CRUD */
  obtenerDescuentoProductoSubdetalle(prd_codprd: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/descuento-producto-subdetalle/listar/${this._configurationService.obtenerCompaniaCliente()}/${prd_codprd}`
    );
  }


  private buttonClickSubject = new Subject<void>();

  buttonClick$ = this.buttonClickSubject.asObservable();

}


