import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { Observable } from 'rxjs';
import { CIA } from 'src/app/models/cia';

@Injectable({providedIn: 'root'})
export class CIAService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {

  }

  obtenerCIA(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cia/mostrar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  obtenerCIAs(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cia/listar`
    );
  }

  modificarCIA(payload: CIA): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/cia/modificar`,
      payload
    );
  }

}
