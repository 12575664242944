import { BINARY_TYPE } from "@data/interfaces/binary.type";

export class PlanCuenta {
  cia_codcia: string;
  ccn_codccn: string;
  ccn_descri: string;
  ccn_desabr: string;
  ccn_tipccn: BINARY_TYPE;
  ccn_indcco: string;
  tcn_codtcn: string;
  tmo_codtmo: string;
  ccn_indccs: BINARY_TYPE;
  ccn_indana: BINARY_TYPE;
  ccn_inddes: BINARY_TYPE;
  ccn_tipdes: any;
  ccn_codtra: any;
  ccn_coddbe: any;
  ccn_indtra: BINARY_TYPE;
  ccn_indcie: BINARY_TYPE;
  ccn_codcie: any;
  ccn_indpls: BINARY_TYPE;
  ccn_indclb: string;
  ccn_tipaju: string;
  ccn_tcaaju: string;
  ccn_indsta: string;
  ccn_coduse: string;
  ccn_fecupd: Date;
  ccn_tipacm: any;
  ccn_banco: any;
  ccn_ctaban: any;
  ccn_ctaequ: any;
  ccn_indpcg: any;
  ccn_glopcg: any;
  ccn_indexr: BINARY_TYPE;
  csu_codcsu: any;
  ccn_indesg: any;
  fca_codfca: any;
  ccn_codclb: any;
  ccs_codccs: any;
  ccn_indnat: BINARY_TYPE;
  ccn_indccf: BINARY_TYPE;
  ccn_uselog: string;
  ccn_feclog: any;
  ccn_logope: any;

  constructor() {
    this.ccn_indsta = '1';
    this.ccn_fecupd = new Date();
    this.ccn_tipccn = 0;
    this.ccn_indccs = 0;
    this.ccn_indana = 0;
    this.ccn_inddes = 0;
    this.ccn_indtra = 0;
    this.ccn_indcie = 0;
    this.ccn_indpls = 0;
    this.ccn_indexr = 0;
    this.ccn_indnat = 0;
    this.ccn_indccf = 0;
  }

}
