export class LineaProducto {

  cia_codcia: string;
  lpd_codlpd: string;
  lpd_descri: string;
  lpd_desabr: string;
  lpd_indini: string;
  lpd_indsta: string;
  lpd_coduse: string;
  lpd_fecupd: Date;
  ccn_codccn: string;
  tco_codtco: string;
  lpd_indlis: 1 | 0;

  constructor() {
    this.lpd_indlis = 1;
    this.lpd_codlpd = '';
  }

}
