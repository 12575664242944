import { CommonModule } from "@angular/common";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MaterialModule } from "src/app/material.module";
import { CrmComponent } from "./crm.component";
import { informesComponentsCrm } from "./pages/informes";
import { maestrosComponentsCrm, maestrosModulesCRM } from "./pages/maestros";
import { operacionesComponentsCrm } from "./pages/operaciones";
import { consultasComponentsCrm } from "./pages/consultas";
import { PanelCrmComponent } from './pages/panel/panel-crm.component';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { HotkeyModule } from "angular2-hotkeys";
import { NgApexchartsModule } from "ng-apexcharts";

@NgModule({
  declarations: [
    CrmComponent,
    PanelCrmComponent,
    ...informesComponentsCrm,
    ...maestrosComponentsCrm,
    ...operacionesComponentsCrm,
    ...consultasComponentsCrm
  ],
  exports: [],
  providers: [
    { provide: LOCALE_ID, useValue: "es-PE" }
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    ...maestrosModulesCRM,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    HotkeyModule.forRoot()
  ]
})
export class CrmModule {}
