<app-pavso-title-section [title]="'Tipo de cambio'" [module]="'Ventas'" [ngClass]="'pav-form'" [estado]="exchangeRate.TCA_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="exchangeRate.TCA_FECTCA" name="fecha"
              (dateChange)="cambioDeFecha($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <app-currency-select [moneda]="exchangeRate.TMO_MONNAC"
            (cambioMoneda)="exchangeRate.TMO_MONNAC = $event"></app-currency-select>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="row">
      <div class="col s12">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            Tabla
          </div>
        </div>
        <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSource" matSort >

            <ng-container matColumnDef="mon">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Moneda"> {{exchangeRate.TMO_MONEXT}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción">
                <mat-form-field>
                  <mat-label></mat-label>
                  <input matInput type="text" placeholder="Descripción">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="compra">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Compra </th>
              <td mat-cell *matCellDef="let row" data-label="Compra">
                <mat-form-field>
                  <input matInput type="number" [(ngModel)]="exchangeRate.TCA_COMOFI" name="tca_comofi" step="any" required>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="venta">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Venta </th>
              <td mat-cell *matCellDef="let row" data-label="Venta">
                <mat-form-field>
                  <input matInput type="number" [(ngModel)]="exchangeRate.TCA_VENOFI" name="tca_venofi" step="any" required>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="usuario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
              <td mat-cell *matCellDef="let row" data-label="Usuario"> {{usuario}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">No se encontraron registros</td>
            </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-ventas/tipos-de-cambio"
      (clickNuevo)="nuevoRegistro()"
    >
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
