<app-pavso-title-section [title]="'Tipo lista de precio'" [module]="'Ventas'" [usuario]="tipoListaPrecio.tlp_coduse" [fecha]="tipoListaPrecio.tlp_fecupd" [estado]="tipoListaPrecio.tlp_indsta"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="tipoListaPrecio.tlp_codtlp" [maxlength]="2" matInput placeholder="Código" name="tlp_codtlp" required [readonly]="uid!='0'"
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="tipoListaPrecio.tlp_descri" [maxlength]="50" matInput placeholder="Descripción" name="tlp_descri" required
              >
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-ventas/tipo-lista-precio">
      <div id="botonesAdicionales">

      </div>
    </pav-form-actions>

  </form>
</div>
