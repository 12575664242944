<!-- <p>guia-texto-list works!</p> -->
<app-pavso-title-section [title]="'Guias de Texto'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">

      <table-title-action title="Todos las Guias de Texto" url="/modulo-almacen/guia-de-remision-texto" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <a matTooltip="Editar Guia"
                      class="pav-btn-circle-edit"
                      [routerLink]="['/modulo-almacen/guia-de-remision-texto', row.grc_numdoc]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="sdo_codsdo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.sdo_codsdo}} </td>
            </ng-container>
            <ng-container matColumnDef="grc_cordoc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.grc_cordoc}} </td>
            </ng-container>

            <ng-container matColumnDef="grc_fecdoc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
                <td mat-cell *matCellDef="let row" data-label="Chofer"> {{row.grc_fecdoc}} </td>
            </ng-container>
            <ng-container matColumnDef="cli_codcli">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Proovedor </th>
                <td mat-cell *matCellDef="let row" data-label="Chofer"> {{row.cli_codcli}} </td>
            </ng-container>
            <ng-container matColumnDef="grc_indsta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{(row.grc_indsta == "1")? "VIGENTE": "ANULADO"}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
