import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { forkObs } from '@utils/observables/fork';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { SeguimientoOportunidad } from 'src/app/models/crm/seguimiento_oportunidad';
import { ConfigurationService, CrmService } from 'src/app/services';
import { EtapaService } from 'src/app/services/api/crm/maestros/etapa.service';
import { ResponsableService } from 'src/app/services/api/crm/maestros/responsable.service';
import { SituacionService } from 'src/app/services/api/crm/maestros/situacion.service';
import { SeguimientoOportunidadService } from 'src/app/services/api/crm/operaciones';

@Component({
  selector: 'app-new-stage-opportunity',
  templateUrl: './new-stage-opportunity.component.html',
  styleUrls: ['./new-stage-opportunity.component.css']
})
export class NewStageOpportunityComponent implements OnInit, OnDestroy {

  loaderData: boolean = false;
  loaderReg: boolean = false;

  buttonName: INameConstant = NAMES_CONSTANTS;

  responsables: any[] = [];
  etapas: any[] = [];
  situaciones: any[] = [];
  motivos: any[] = [];

  seguimiento: SeguimientoOportunidad;

  sendForm$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<NewStageOpportunityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _crmService: CrmService,
    private _etapaService: EtapaService,
    private _responsableService: ResponsableService,
    private _seguimientoOportunidadService: SeguimientoOportunidadService,
    private _situacionService: SituacionService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService
  ) {
    this.seguimiento = new SeguimientoOportunidad();

    this.seguimiento.codcia = this._configurationService.obtenerCompaniaCliente();
    this.seguimiento.coduse = this._configurationService.obtenerIdUsuario();
    this.seguimiento.idtkt = this.data.idtkt;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.loadMaestros();
  }

  loadMaestros(): void {
    this.loaderData = true;

    forkObs(
      this._responsableService.obtenerResponsables(),
      this._situacionService.obtenerSituacionesOportunidad(),
      this._etapaService.obtenerEtapas(),
      this._crmService.obtenerMotivosVentaSuspendida()
    ).then(([responsables, situaciones, etapas, motivosVenta]) => {

      this.responsables = responsables;
      this.situaciones = situaciones;
      this.etapas = etapas;
      this.motivos = motivosVenta;
      this.loaderData = false;
    }).catch(error => {
      this._snackBarService.showError('Error al cargar maestros', 'Ok');

    })

  }

  registrar(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._seguimientoOportunidadService.registrarSeguimientoOportunidad(this.seguimiento).subscribe(
      response => {
        this._snackBarService.showSuccess('Seguimiento registrado', 'Ok');
        this.loaderReg = false;
        this.dialogRef.close();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.sendForm$])
  }

}
