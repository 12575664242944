<mat-card class="mat-elevation-z0">
  <div class="row">
    <div class="col s12">
      <ng-container *ngFor="let item of items">
        <b>{{item.name}}:</b> {{item.id}} >
      </ng-container>

    </div>
  </div>
</mat-card>
