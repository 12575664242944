export class TransportUnit {
  CIA_CODCIA: string;
  UTR_CODUTR: string;
  UTR_DESCRI: string;
  UTR_PLAUTR: string;
  UTR_NROCER: string;
  UTR_MARUTR: string;
  UTR_CONFIG: string;
  CHO_CODCHO: string;
  UTR_CODANT: string;
  UTR_INDSTA: string;
  UTR_FECUPD: Date;
  UTR_CODUSE: string;
  CLI_CODCLI: string;
  utr_regmtc: string;
  utr_peresp: string;
  utr_numesp: string;
  constructor(
  ) {
    this.UTR_FECUPD = new Date();
    this.UTR_INDSTA = "1";
  }
}
