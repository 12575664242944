import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material.module';
import { CuentaCobrarDialogComponent } from './components/dialogs/cuenta-por-pagar/cuenta-cobrar-dialog/cuenta-cobrar-dialog.component';
import { ImgBrokenDirective } from './directives/img-broken.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
    declarations: [...fromComponents.components, ...fromDirectives.directives, CuentaCobrarDialogComponent],
    imports: [
        BrowserModule,
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        NgxMatSelectSearchModule,
        NgApexchartsModule,
        TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
        }),
        NgxDropzoneModule
    ],
    exports: [
        ...fromComponents.components,
        ...fromDirectives.directives,
        ImgBrokenDirective
    ]
})
export class SharedModule { }
