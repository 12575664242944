import { Component, OnInit, ViewChild } from "@angular/core";
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexPlotOptions, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'crm-graph-three',
  templateUrl: './crm-graph-three.component.html',
  styleUrls: ['./crm-graph-three.component.scss']
})
export class CrmGraphThreeComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  displayedColumns: string[] = ['zone', 'amount'];
  dataSource = [
    {
      zone: 'AREQUIPA',
      amount: 44
    },
    {
      zone: 'ATE',
      amount: 76
    },
    {
      zone: 'CUSCO',
      amount: 35
    },
    {
      zone: 'GUAYAQUIL',
      amount: 35
    },
    {
      zone: 'HUARI',
      amount: 35
    },
    {
      zone: 'LA PAZ',
      amount: 35
    },
    {
      zone: 'MOTUPE',
      amount: 35
    },
    {
      zone: 'PUCALLPA',
      amount: 35
    },
    {
      zone: 'QUITO',
      amount: 35
    },
    {
      zone: 'SANTA CRUZ',
      amount: 35
    },
    {
      zone: 'MONTO TOTAL',
      amount: 155
    },
  ];


  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "AREQUIPA",
          data: [44]
        },
        {
          name: "ATE",
          data: [76]
        },
        {
          name: "CUSCO",
          data: [45]
        },
        {
          name: "GUAYAQUIL",
          data: [35]
        },
        {
          name: "HUARI",
          data: [29]
        },
        {
          name: "LA PAZ",
          data: [37]
        },
        {
          name: "MOTUPE",
          data: [74]
        },
        {
          name: "PUCALLPA",
          data: [23]
        },
        {
          name: "QUITO",
          data: [45]
        },
        {
          name: "SANTA CRUZ",
          data: [23]
        },
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct"
        ]
      },
      yaxis: {
        title: {
          text: "S/. (Monto)"
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "S/. " + val + " ";
          }
        }
      }
    };
  }

  ngOnInit(): void {

  }
}
