import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { TipoDocumentoIdentidad } from "src/app/models/ventas/tipo-documento-identidad";
import { ConfigurationService, VentasService } from "src/app/services";
import { ApiTipoDocumentoIdentidadVentasService } from "src/app/services/api/ventas/maestros/tipo-documento-identidad.service";

@Component({
  selector: 'tipo-documento-identidad-form',
  templateUrl: './tipo-documento-identidad-form.component.html',
  styleUrls: ['./tipo-documento-identidad-form.component.scss']
})
export class TipoDocumentoIdentidadFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;

  tipoDoc: TipoDocumentoIdentidad;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  sendForm$: Subscription;
  listadoTipoDoc$: Subscription;
  loading$: Subscription;

  constructor(
    private _ventasService: VentasService,
    private _apiTipoDocVentasService: ApiTipoDocumentoIdentidadVentasService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _router: Router,
    private store: Store<PavsoState>
  ) {
    this.tipoDoc = new TipoDocumentoIdentidad();
    this.tipoDoc.DID_CODUSE = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=>{
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(this.uid == '0') return;

      this.obtenerDocumentoIdentidad();
    })
  }

  obtenerDocumentoIdentidad(): void {
    this.listadoTipoDoc$ = this._apiTipoDocVentasService.obtenerTipoDocumentoIdentidad(this.uid).subscribe(
      tipoDoc => {
        console.log('tipo doc', tipoDoc)
        this.tipoDoc = tipoDoc[0];
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarTipoDocumentoIdentidad(f): this.actualizarTipoDocumentoIdentidad(f);
  }

  registrarTipoDocumentoIdentidad(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._ventasService.registrarTipoDocumentoIdentidad(this.tipoDoc).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de documento identidad registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarTipoDocumentoIdentidad(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._ventasService.actualizarTipoDocumentoIdentidad(this.tipoDoc).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de documento identidad actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/documentos-de-identidad'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.listadoTipoDoc$
    ])
  }
}
