import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable, searchInTable } from '@utils/tables/table';
import { ContabilidadService} from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { AsientoContableService } from 'src/app/services/api/contabilidad/operaciones/asiento-contable.service';

@Component({
  selector: 'app-asiento-contable-list',
  templateUrl: './asiento-contable-list.component.html',
  styleUrls: ['./asiento-contable-list.component.css']
})
export class AsientoContableListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'ANO_CODANO', 'MES_CODMES', 'SCO_CODSCO', 'LDC_CORLDC', 'LDC_FECDIA', 'LDC_GLODIA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  subdiarios: any[] = [];

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _asientoContableService: AsientoContableService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
      this.laodData();
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadMaestros();
    })
  }

  laodData(): void {
    this.loaderData = true;
    this._asientoContableService.obtenerAsientosContables(this.year, this.month).subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
        this.loaderData = false;
      }
    )
  }

  loadMaestros(): void {
    this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => this.subdiarios = subdiarios,
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  seleccionarSubdiario(subdiario): void {
    if(subdiario != "All") {
      this.dataSource.filter = subdiario.trim().toLowerCase();
      if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
      return;
    }

    this.dataSource.filter = ("").trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }

}
