<div class="row fade-in-image">
  <div class="col s12 m12 l6">
    <div class="sign-in-container">

      <form #f="ngForm" ngNativeValidate (ngSubmit)="acceder(f)">

        <img src="assets/images/logo.png" alt="logo" class="pav-logo" width="100px">
        <h1>Ingresa aquí</h1>

        <mat-form-field>
          <mat-label>Usuario</mat-label>
          <input matInput placeholder="Usuario" [(ngModel)]="user.usuario" name="usuario" required type="email">
          <mat-icon matSuffix>person_outline</mat-icon>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Ingresa tu contraseña</mat-label>
          <input matInput placeholder="Ingresa tu contraseña" [type]="hide ? 'password' : 'text'"
            [(ngModel)]="user.password" name="password" required>
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field *ngIf="!esProduccion">
          <mat-label>Elige una compañía</mat-label>
          <mat-select [(ngModel)]="modo" name="modo" (selectionChange)="seleccionarCompania($event.value)">
            <mat-option value="99">
              Compañia Prueba
            </mat-option>
            <mat-option value="01">
              Compañia Producción
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <button class="button" type="submit">{{messageButton}}</button>
      </form>

    </div>
  </div>
  <div class="col s12 m12 l6 p-r-l-0">
    <div class="pav-portada"></div>
  </div>
</div>
