<app-pavso-title-section [title]="'Línea de documento'" [module]="'Cuentas por cobrar'" [usuario]="linea.lcc_coduse"
  [fecha]="linea.lcc_fecupd" [estado]="linea.lcc_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="linea.lcc_codlcc" matInput placeholder="Código" name="lcc_codlcc" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="linea.lcc_descri" matInput placeholder="Descripción" name="lcc_descri"
              required [disabled]="loaderData">
          </mat-form-field>
        </div>

      </div>

    </mat-card>
    <br>


  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/lineas-de-documentos">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
