<app-pavso-title-section [title]="'Configurar cobranzas'" [module]="'Cuentas por cobrar'" [estado]="'1'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarConfiguracion(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Subsidiario</mat-label>
                      <mat-select required name="nro_doc_iden" [disabled]="loaderData">
                          <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
                              {{subdiario.sco_descri}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Banco</mat-label>
                      <mat-select required name="banco" [disabled]="loaderData">
                          <mat-option *ngFor="let banco of bancos" [value]="banco.sd">
                              {{banco.sd}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Línea</mat-label>
                      <mat-select required name="linea" [disabled]="loaderData">
                          <mat-option value="1">
                              linea 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Cuenta Contable S/.</mat-label>
                      <mat-select required name="cuenta_soles" [disabled]="loaderData">
                          <mat-option value="1">
                              cuenta contable 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Cuenta Contable $.</mat-label>
                      <mat-select required name="cuenta_dolar" [disabled]="loaderData">
                          <mat-option value="1">
                              cuenta contable 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Portes y Comisiones</mat-label>
                      <mat-select required name="porte_comision" [disabled]="loaderData">
                          <mat-option value="1">
                              porte 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Intereses Cargados</mat-label>
                      <mat-select required name="interes_cargado" [disabled]="loaderData">
                          <mat-option value="1">
                              interes 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Gastos de Protesto</mat-label>
                      <mat-select required name="gasto_protesto" [disabled]="loaderData">
                          <mat-option value="1">
                              gasto 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Intereses Abonados</mat-label>
                      <mat-select required name="interes_abonado" [disabled]="loaderData">
                          <mat-option value="1">
                              porte 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Centro de Costo</mat-label>
                      <mat-select required name="centro_costo" [disabled]="loaderData">
                          <mat-option *ngFor="let centro of centros" [value]="centro.dsfa">
                              {{centro.fasdf}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Otros Ingresos</mat-label>
                      <mat-select required name="otros_ingresos" [disabled]="loaderData">
                          <mat-option value="1">
                              otro 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>

      </mat-card>
      <br>
      <div class="pav-filtro-header">
        <div class="pav-filtro-header-icon"></div>
        <div class="pav-filtro-header-title">Tabla</div>
      </div>
      <div class="mat-elevation-z0">

          <table mat-table [dataSource]="dataSource" matSort >

              <ng-container matColumnDef="situacion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
                  <td mat-cell *matCellDef="let row" data-label="Num. Requerimiento"> {{row.cli_codcli}} </td>
              </ng-container>

              <ng-container matColumnDef="cuenta_sol">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta S/. </th>
                  <td mat-cell *matCellDef="let row" data-label="Fec. Emisión"> {{row.cli_nomcli}} </td>
              </ng-container>

              <ng-container matColumnDef="cuenta_dolar">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta $/. </th>
                  <td mat-cell *matCellDef="let row" data-label="Fec. Pago"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="cuenta_enlace_sol">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Enlace S/. </th>
                  <td mat-cell *matCellDef="let row" data-label="RUC"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="cuenta_enlace_dolar">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Enlace $/. </th>
                  <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="solicitante">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitante </th>
                  <td mat-cell *matCellDef="let row" data-label="Solicitado por"> {{row.cli_numtlf}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="8">No se encontraron registros</td>
              </tr>

          </table>

      </div>
      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
