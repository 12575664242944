<div class="row">
  <div class="col s12">
    <mat-card class="mat-elevation-z0">
      <div id="chart">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [markers]="chartOptions.markers"
          [stroke]="chartOptions.stroke"
          [yaxis]="chartOptions.yaxis"
          [dataLabels]="chartOptions.dataLabels"
          [title]="chartOptions.title"
          [fill]="chartOptions.fill"
          [tooltip]="chartOptions.tooltip"
          [legend]="chartOptions.legend"
        ></apx-chart>
      </div>
    </mat-card>
  </div>
</div>
