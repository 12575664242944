import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DetalleSerie } from 'src/app/models';
import { AuthenticationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-movement-add-serie',
  templateUrl: './movement-add-serie.component.html',
  styleUrls: ['./movement-add-serie.component.css']
})
export class MovementAddSerieComponent implements OnInit {

  serie: any = "";
  fechaFab: any = "";
  fechaVcto: any = "";

  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    private dialogRef: MatDialogRef<MovementAddSerieComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<DetalleSerie>,
    private _authService: AuthenticationService,
    private _snackBarService: SnackBarService,
  ) {
    (this.data == null)? this.data = []: this.data = this.data;
  }

  ngOnInit(): void {
  }

  cerrar(): void {
    if(this.data.length == parseInt(localStorage.getItem('cantidad-parte-ingreso'))) {
      this.dialogRef.close(this.data);
      return;
    }

    this._snackBarService.showError('Cantidad de producto debe de coincidir con la cantidad de número de serie', 'OK');

  }

  AgregarSerie(f: NgForm): void {
    let serie = new DetalleSerie();
    // serie.id = this.data.length + 1;
    serie.spr_numser = this.serie;
    // serie.mas_fecfab = this.fechaFab;
    serie.mas_fecven = this.fechaVcto;
    serie.mas_coduse = this._authService.getUsuarioSistema();
    // serie.mas_fecupd = new Date();
    serie.tal_codtal = "00";

    this.data.push(serie);
  }

  eliminarSerie(id) {
    let series = [];
    this.data.forEach(element => {
      // if(element.id != id) series.push(element);
    })
    this.data = series;
  }

}
