export class ContratoPersonal {
  codcia: string;
  codnmc: string;
  descri: string;
  codcli: string;
  fecemi: Date;
  fecini: Date;
  fecter: Date;
  codrep: string;
  codtmo: string;
  imprem: number;
  codnca: string;
  codne0: string;
  coduse: string;
  fecupd: Date;
  indsta: string;
  indcie: number;
  nombres: any;

  constructor() {
    this.indsta = "1";
    this.fecupd = new Date();
  }
}
