export class LineaDocumento {
  lcc_codlcc: string;
  lcc_descri: string;
  lcc_desabr: string;
  lcc_indsta: string;
  lcc_coduse: string;
  lcc_fecupd: Date;

  constructor() {
    this.lcc_indsta = "1";
    this.lcc_fecupd = new Date();
  }
}
