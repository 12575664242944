import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { TarjetaCredito } from "src/app/models/ventas/tarjeta-credito";
import { VentasService } from "src/app/services";
@Component({
  selector: 'tarjeta-credito-form',
  templateUrl: './tarjeta-credito-form.component.html',
  styleUrls: ['./tarjeta-credito-form.component.scss']
})
export class TarjetaCreditoFormComponent implements OnInit, OnDestroy {
  loaderReg: boolean;
  loaderData: boolean;

  uid: string;
  tarjeta: TarjetaCredito;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  sendForm$: Subscription;
  loading$: Subscription;

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private store: Store<PavsoState>
  ) {
    this.tarjeta = new TarjetaCredito();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      if(this.uid == '0') return;
    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarTarjetaCredito(f): this.actualizarTarjetaCredito(f);
  }

  registrarTarjetaCredito(f: NgForm): void {
    this.sendForm$ = this._ventasService.registrarTarjetaCredito(this.tarjeta).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tarjeta de crédito registrada', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarTarjetaCredito(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarTarjetaCredito(this.tarjeta).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tarjeta de crédito actualizada', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/tarjetas-de-credito']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$
    ])
  }
}
