import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AddProductDialogData } from "@data/interfaces/modules/almacen/kardex.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { PavShowProductImageDialog } from "@shared/components/pav-show-product-image-dialog/pav-show-product-image.dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithDash } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";

@Component({
  selector: 'add-product-dialog',
  templateUrl: './add-product.dialog.html',
  styleUrls: ['./add-product.dialog.scss']
})
export class AddProductDialog implements OnInit, OnDestroy {

  loaderData: boolean = false;

  anioPeriodo: string;
  mesPeriodo: string;

  stockFisico: number = 0;
  stockDisponible: number = 0;
  stockReservado: number = 0;

  productoSeleccionado: IProductoSeleccionado = {
    product: '',
    name: '',
    precio: 0,
    cantidad: 0,
    porDesc: 0,
    impDesc: 0,
    um: '',
    costoMN: 0,
    costoME: 0
  };

  listaPrecio$: Subscription;
  consultaStock$: Subscription;
  productos$: Subscription;

  @ViewChild('iPrecio') inputPrecio: ElementRef;
  @ViewChild('iCantidad') inputCantidad: ElementRef;
  @ViewChild('iPorDescuento') inputPorDescuento: ElementRef;
  @ViewChild('iCostoMN') inputCostoMN: ElementRef;
  @ViewChild('iCostoME') inputCostoME: ElementRef;

  isMobile: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddProductDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AddProductDialogData,
    private _ventasService: VentasService,
    private _productoService: ProductoService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver

  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
      console.log('Is Mobile:', this.isMobile);
    });
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.listaPrecio$, this.consultaStock$])
  }

  ngOnInit(): void {
    console.log('data', this.data)
    this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })

    this.obtenerProductos();
  }

  obtenerProductos(): void {

    this.loaderData = true;

    this.productos$ = this._productoService.obtenerProductos().subscribe(
      response => {
        this.data.products = response;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  verImagen(): void {
    if(!this.productoSeleccionado.product) {
      this._snackBarService.showError('Seleccionar un producto', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(PavShowProductImageDialog, {
      width: 'auto',
      data: this.productoSeleccionado.product,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  seleccionarProducto(): void {

    const producto = this.data.products.find(item => item.prd_codprd == this.productoSeleccionado.product)
    this.productoSeleccionado.name = producto.DESC_ESPA;
    console.log('producto', producto)

    this.consultaStock$ = this._ventasService.consultaStock('01', this.anioPeriodo, this.mesPeriodo, this.data.almacen, this.productoSeleccionado.product).subscribe(
      response => {
        console.log('response', response)
        this.stockFisico = response[0].fisico;
        this.stockDisponible = response[0].disponible;
        this.stockReservado = response[0].reservado;

        this.listaPrecio$ = this._ventasService.listaDePreciosProducto(this.productoSeleccionado.product, this.data.moneda, this.data.cliente, this.data.tipoListaPrecio, this.data.listaPrecio, formatDateWithDash(new Date())).subscribe(
          response => {
            console.log('precio', response)
            this.productoSeleccionado.precio = response[0].pre_prenac;
            this.inputCantidad.nativeElement.focus();
            this.inputCantidad.nativeElement.select();

          },
          error => this._snackBarService.showError(error.error.msg, 'OK')
        )
      },
      error => {
        this._snackBarService.showError('Error al consultar stock', 'Ok');
      }
    )
  }

  confirmar(): void {

    if (this.productoSeleccionado.product != '' && this.productoSeleccionado.cantidad != 0) {

      this.productoSeleccionado.um = this.data.products.find(product => product.prd_codprd == this.productoSeleccionado.product).UNIDAD;

      this.dialogRef.close(this.productoSeleccionado);
      return;

    }

    this._snackBarService.showError('Debe seleccionar un producto y una cantidad', 'Ok');
  }

  enterCantidad(): void {
    if (this.data.canAddPrice) {
      this.inputPrecio.nativeElement.focus();
      this.inputPrecio.nativeElement.select();
    }
    if (this.data.canAddCost) {
      this.inputCostoMN.nativeElement.focus();
      this.inputCostoMN.nativeElement.select();
    }
  }

  enterPrecio(): void {
    this.inputPorDescuento.nativeElement.focus();
    this.inputPorDescuento.nativeElement.select();
  }

  enterPorDescuento(): void {
    this.confirmar();
  }

  keyupCostoMN(): void {
    this.productoSeleccionado.costoME = parseFloat((this.productoSeleccionado.costoMN / (this.data.tasaCambio)).toFixed(2))
  }

  enterCostoMN(): void {
    this.confirmar();
  }

}



interface IProductoSeleccionado {
  product: string;
  name: string;
  cantidad: number;
  precio: number;
  porDesc: number;
  impDesc: number;
  um: string;
  costoMN: number;
  costoME: number;
}
