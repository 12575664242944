import { Component, Input, OnInit, ViewChild } from "@angular/core";

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexFill,
  ApexPlotOptions,
  ApexStroke
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  fill: ApexFill;
  stroke: ApexStroke;
};

@Component({
  selector: 'pavso-bar-chart-basic',
  templateUrl: './bar-chart-basic.component.html',
  styleUrls: ['./bar-chart-basic.component.scss']
})
export class BarChartBasicComponent implements OnInit {

  @Input() categorias: Array<string>;
  @Input() data: Array<any>;
  @Input() isHorizontal: boolean = true;

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    console.log('cate bar chart', this.categorias)
    console.log('data bar chart', this.data)
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: this.data,
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: this.isHorizontal,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        // offsetX: -6,
        style: {
          // fontSize: "12px",
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: this.categorias
      },
      fill: {
        colors: ['#008ffb', '#6cd6df', '#fdb017']
      },
    };

    this.load();

  }

  load() : void {
    let chartComprobantes = new ApexCharts(document.querySelector("#chartProject"), this.chartOptions);
        chartComprobantes.render();
        chartComprobantes.updateOptions({
          series: this.data,
          plotOptions: {
            bar: {
              dataLabels: {
                position: "top" // top, center, bottom
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function(val) {
              return val + "";
            },
            offsetY: -20,
            style: {
              fontSize: "10px",
              colors: ['#fff']
            }
          },
          chart: {
            height: 350,
            type: "bar",
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
                customIcons: []
              },
              export: {
                csv: {
                  filename: undefined,
                  columnDelimiter: ',',
                  headerCategory: 'category',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString()
                  }
                },
                svg: {
                  filename: undefined,
                },
                png: {
                  filename: undefined,
                }
              },
              autoSelected: 'zoom'
            },
          },
          title: {
            text: "Gráfico"
          },
          xaxis: {
            categories: this.categorias
          },
          fill: {
            colors: ['#008ffb', '#6cd6df', '#fdb017']
          },

          colors: ['#008ffb', '#6cd6df', '#fdb017']
        })
  }

}
