import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ApiPaisVentasService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerPaises(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/paises`
    );
  }

  obtenerPais(codpai): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/paises/${codpai}`
    );
  }
}
