import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { LoginGuard } from '../guards/login.guard';
import { ProfileComponent } from './profile/profile.component';

import { ROUTES } from '@data/constants/routes/routes.constant';
import { EnterParametersComponent } from './error/enter-parameters/enter-parameters.component';
import { IdentificationGuard } from '../guards/identification.guard';
import { NgModule } from '@angular/core';
import { ActivoFijoComponent } from './apps/activo-fijo/activo-fijo.component';
import { AlmacenComponent } from './apps/almacen/almacen.component';
import { OperacionesComponent } from './apps/operaciones/operaciones.component';
import { VentasComponent } from './apps/ventas/ventas.component';
import { ComprasComponent } from './apps/compras/compras.component';
import { ImportacionesComponent } from './apps/importaciones/importaciones.component';
import { PlanillaComponent } from './apps/planilla/planilla.component';
import { CajaBancoComponent } from './apps/caja-y-bancos/caja-banco.component';
import { ContabilidadComponent } from './apps/contabilidad/contabilidad.component';
import { CobranzasComponent } from './apps/cobranzas/cobranzas.component';
import { CrmComponent } from './apps/crm/crm.component';
import { PuntoVentaComponent } from './apps/punto-de-venta/punto-venta.component';
import { LibroElectronicoComponent } from './apps/libro-electronico/libro-electronico.component';
import { CostosComponent } from './apps/costos/costos.component';
import { UtilitariosComponent } from './apps/utilitarios/utilitarios.component';
import { ProduccionComponent } from './apps/produccion/produccion.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { GeneralComponent } from './apps/general/general.component';
import { MyLogoComponent } from './my-logo/my-logo.component';

export const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        canActivate: [LoginGuard],
        children: [
            // Mi Aplicación
            ROUTES.DASHBOARD,
            // General
            {
              path: '',
              component: GeneralComponent,
              loadChildren: () => import('./apps/general/general-child-routes.module').then(m => m.GeneralChildRoutesModule)
            },

            // Almacén
            {
              path: 'modulo-almacen',
              component: AlmacenComponent,
              loadChildren: () => import('./apps/almacen/almacen-child-routes.module').then(m => m.AlmacenChildRoutesModule)
            },

            // Operaciones
            {
              path: 'modulo-operaciones',
              component: OperacionesComponent,
              loadChildren: () => import('./apps/operaciones/operaciones-child-routes.module').then(m => m.OperacionesChildRoutesModule)
            },

            // Ventas
            {
              path: 'modulo-ventas',
              data: {animation: 'FilterPage'},
              component: VentasComponent,
              loadChildren: () => import('./apps/ventas/ventas-child-routes.module').then(m => m.VentasChildRoutesModule)
            },

            // Compras
            {
              path: 'modulo-compras',
              component: ComprasComponent,
              loadChildren: () => import('./apps/compras/compras-child-routes.module').then(m => m.ComprasChildRoutesModule)
            },

            // Importaciones
            {
              path: 'modulo-importaciones',
              component: ImportacionesComponent,
              loadChildren: () => import('./apps/importaciones/importaciones-child-routes.module').then(m => m.ImportacionesChildRoutesModule)
            },

            // Planilla
            {
              path: 'modulo-planilla',
              component: PlanillaComponent,
              loadChildren: () => import('./apps/planilla/planilla-child-routes.module').then(m => m.PlanillaChildRoutesModule)
            },

            // Caja y Bancos
            {
              path: 'modulo-cajabanco',
              component: CajaBancoComponent,
              loadChildren: () => import("./apps/caja-y-bancos/caja-banco-child-routes.module").then(m => m.CajaBancoChildRoutesModule)
            },

            // Contabilidad
            {
              path: 'modulo-contabilidad',
              component: ContabilidadComponent,
              loadChildren: () => import("./apps/contabilidad/contabilidad-child-routes.module").then(m => m.ContabilidadChildRoutesModule)
            },

            // conbranzas
            {
              path: 'modulo-cobranzas',
              component: CobranzasComponent,
              loadChildren: () => import("./apps/cobranzas/cobranzas-child-routes.module").then(m => m.CobranzasChildRoutesModule)
            },

            // crm
            {
              path: 'modulo-crm',
              component: CrmComponent,
              loadChildren: () => import("./apps/crm/crm-child-routes.module").then(m => m.CrmChildRoutesModule)
            },

            // activo fijo
            {
              path: 'modulo-activo-fijo',
              component: ActivoFijoComponent,
              loadChildren: () => import('./apps/activo-fijo/activo-fijo-child-routes.module').then(m => m.ActivoFijoChildRoutesModule)
            },

            // punto de venta
            {
              path: 'modulo-punto-de-venta',
              component: PuntoVentaComponent,
              loadChildren: () => import('./apps/punto-de-venta/punto-venta-child-routes.module').then(m => m.PuntoVentaChildRoutesModule)
            },

            // libros electrónicos
            {
              path: 'modulo-libros-electronicos',
              component: LibroElectronicoComponent,
              loadChildren: () => import('./apps/libro-electronico/libro-electronico-child-routes.module').then(m => m.LibroElectronicoChildRoutesModule)
            },

            // costos
            {
              path: 'modulo-costos',
              component: CostosComponent,
              loadChildren: () => import('./apps/costos/costos-child-routes.module').then(m => m.CostosChildRoutesModule)
            },

            //utilitarios
            {
              path: 'modulo-utilitarios',
              component: UtilitariosComponent,
              loadChildren: () => import('./apps/utilitarios/utilitarios-child-routes.module').then(m => m.UtilitariosChildRoutesModule)
            },

            //producción
            {
              path: 'modulo-produccion',
              component: ProduccionComponent,
              loadChildren: () => import('./apps/produccion/produccion-child-routes.module').then(m => m.ProduccionChildRoutesModule)
            },

            { path: 'ingresar-parametros', component: EnterParametersComponent, data: {titulo: 'Requerimiento de Materiales Producción', codeMod: '22', modulo: 'Producción'} },
            { path: '', redirectTo: '/mi-perfil', pathMatch: 'full', data: {titulo: "mi perfil"} },
            { path: 'mi-perfil', canActivate: [IdentificationGuard], component: ProfileComponent, data: {titulo: 'Mi Perfil', codeMod: '1A', modulo: 'General'} },
            { path: 'mi-logo', canActivate: [IdentificationGuard], component: MyLogoComponent, data: {titulo: 'Mi Logo', codeMod: '1A', modulo: 'General'} },
            { path: 'notificaciones', canActivate: [IdentificationGuard], component: NotificationsComponent, data: {titulo: 'Notificaciones', codeMod: '1A', modulo: 'Notificaciones'} },

        ],
    },
];
@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AdminRoutingModule {}
