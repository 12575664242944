<app-pavso-title-section [title]="'Cuenta de caja y bancos'" [module]="'Caja y bancos'" [estado]="cuentaCajaBanco.CBA_INDSTA">
</app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarCuenta(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">

      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_CODCBA" matInput placeholder="Código" name="CBA_CODCBA" required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_DESCRI" matInput placeholder="Descripción" name="CBA_DESCRI">
          </mat-form-field>
        </div>


      </div>

      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Desc. Costo</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.cba_deslar" matInput placeholder="Desc. Costo" name="cba_deslar">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Banco</mat-label>
            <mat-select [(ngModel)]="cuentaCajaBanco.BAN_CODBAN" required name="BAN_CODBAN">
              <mat-option *ngFor="let banco of bancos" [value]="banco.BAN_CODBAN">
                {{banco.BAN_NOMABR}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo cuenta</mat-label>
            <mat-select [(ngModel)]="cuentaCajaBanco.TCT_CODTCT" required name="TCT_CODTCT">
              <mat-option value="1">
                TIPO CUENTA 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nro. Cuenta</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_NUMCTA" matInput placeholder="Nro. Cuenta" name="CBA_NUMCTA">
          </mat-form-field>
        </div>





      </div>
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha de apertura</mat-label>
            <input [(ngModel)]="cuentaCajaBanco.CBA_FECAPE" name="CBA_FECAPE" matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <app-currency-select [moneda]="cuentaCajaBanco.TMO_CODTMO" (cambioMoneda)="cuentaCajaBanco.TMO_CODTMO = $event" [disable]="loaderData"></app-currency-select>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cuenta Contable</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CCN_CODCCN" matInput placeholder="Cuenta Contable" name="CCN_CODCCN">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha cierre</mat-label>
            <input [(ngModel)]="cuentaCajaBanco.CBA_FECCIE" name="CBA_FECCIE" matInput [matDatepicker]="fechacierre">
            <mat-datepicker-toggle matSuffix [for]="fechacierre"></mat-datepicker-toggle>
            <mat-datepicker #fechacierre></mat-datepicker>
          </mat-form-field>
        </div>





      </div>

      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Sectorista</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_NOMSEC" matInput placeholder="Sectorista" name="CBA_NOMSEC">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Agencia</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_NOMAGE" matInput placeholder="Agencia" name="CBA_NOMAGE">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Direc. Agencia</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_DIRAGE" matInput placeholder="Direc. Agencia" name="CBA_DIRAGE">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Telf. Agencia</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_TELAGE" matInput placeholder="Telf. Agencia" name="CBA_TELAGE">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Funcionario</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_FUNPBA" matInput placeholder="Funcionario" name="CBA_FUNPBA">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código Tributario</mat-label>
            <input type="text" [(ngModel)]="cuentaCajaBanco.CBA_CODBCO" matInput placeholder="Código Tributario" name="CBA_CODBCO">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código Auxiliar</mat-label>
            <mat-select [(ngModel)]="cuentaCajaBanco.CLI_CODCLI" required name="CLI_CODCLI">
              <mat-option *ngFor="let auxiliar of auxiliares" [value]="auxiliar.cli_codcli">
                {{auxiliar.cli_nomcli}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
  </form>
</div>
