<app-pavso-title-section [title]="'Modelo'" [module]="'Ventas'" [usuario]="" [fecha]=""></app-pavso-title-section>

<pav-form-actions
    [disabled]="loaderData"
    [btnName]="btnName"
    [isLoading]="loaderReg"
    urlBack="/modulo-ventas/modelos-de-producto"
  >
    <div id="botonesAdicionales">
    </div>

</pav-form-actions>
