import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.css']
})
export class CurrencySelectComponent implements OnInit, OnDestroy {

  monedas: Array<any>;

  @Input() disable: boolean;
  @Input() name: string;
  @Input() moneda: string;
  @Input() clases: string;
  @Input() ancho: string;
  @Input() pt: string;
  @ViewChild('iMoneda') monedaElement: MatSelect;

  @Output() cambioMoneda: EventEmitter<string>;

  moneda$: Subscription;

  constructor(
    private _generalService: GeneralService,
    private _snackBarService: SnackBarService,
  ) {

    this.monedas = [];
    this.cambioMoneda = new EventEmitter<string>();
    this.moneda = "SO";
    this.ancho = "";
  }

  ngOnInit(): void {
    this.obtenerMonedas();
  }

  obtenerMonedas(): void {
    this.moneda$ = this._generalService.obtenerMonedas().subscribe(
      monedas => {
        this.monedas = monedas;
      },
      error => {
        this._snackBarService.showError("Error al obtener monedas", 'OK');
      }
    )
  }

  seleccionarMoneda(event): void {
    this.cambioMoneda.emit(event.value);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.moneda$,
    ])
  }
}
