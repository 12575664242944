import { FormControl } from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export function filterMultiCustom(data: Array<any>, search: string, key: string): Array<any> {

  if (!data) return;

  // get the search keyword
  if (!search) return data.slice();

  search = search.toLowerCase();

  return data.filter(item => item[key].toLowerCase().indexOf(search) > -1)

}

export function initSelectSearch(filteredReplaySubject: ReplaySubject<any[]>, filterCtrl: FormControl, array: Array<any>, field: string): void {
  let _onDestroy = new Subject<void>();

  // load the initial bank list
  filteredReplaySubject.next(array.slice());

  // listen for search field value changes
  filterCtrl.valueChanges
    .pipe(takeUntil(_onDestroy))
    .subscribe(() => {
      filterSelect(filteredReplaySubject, filterCtrl, array, field);
    });
}

export function filterSelect(filteredReplaySubject: ReplaySubject<any[]>, filterCtrl: FormControl, array: Array<any>, field: string): void {
  if (!array) return;

  let search = filterCtrl.value;

  if (!search) {
    filteredReplaySubject.next(array.slice());
    return;
  } else {
    search = search.toLowerCase();
  }

  filteredReplaySubject.next(
    array.filter(item => item[field].toLowerCase().indexOf(search) > -1)
  );
}

