import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GeneralService, VentasService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-cliente-list',
  templateUrl: './cliente-list.component.html',
  styleUrls: ['./cliente-list.component.css']
})
export class ClienteListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'cli_codcli', 'cli_nomcli', 'cli_corele', 'cli_numtlf', 'cli_fecupd'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  customer$: Subscription;
  loading$: Subscription;
  tiposDocumento$: Subscription;
  tiposPersona$: Subscription;

  tiposDocumento: any[] = [];
  tiposPersona: any[] = [];

  clientes: any[] = [];

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  filtro: FiltroCliente;

  constructor(
    private _ventasService: VentasService,
    private _clienteService: ClienteService,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private store: Store<PavsoState>,
    private _snackBar: MatSnackBar
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.filtro = new FiltroCliente();

  }

  columnaActiva: string;
  direccionColumna: string;

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadMaestros();
    })

    this.sort.sortChange.subscribe((event) => {


      this.columnaActiva = event.active
      console.log('Column:', event.active);
      this.direccionColumna = event.direction
      console.log('Direction:', event.direction);
      this.obtenerClienteComerciales();

      // You can perform additional actions based on the sort event
      // For example, you might want to trigger an API call to fetch sorted data
    });
  }

  loadMaestros(): void {

    forkObs(
      this._ventasService.obtenerTiposDocumentoIdentidad(),
      this._generalService.listarTipoDePersona(),
    ).then(data => {
      this.tiposDocumento = data[0];
      this.tiposPersona = data[1];

      this.obtenerClienteComerciales();
    }).catch(err => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })

  }

  obtenerClienteComerciales(): void {
    this.loaderData = true;
    // this.customer$ = this._clienteService.obtenerClientes().subscribe(
    this.customer$ = this._clienteService.obtenerClientesConPaginacion(this.currentPage, this.pageSize, this.direccionColumna, this.columnaActiva, this.buscador).subscribe(
      response => {
        this.pageSize = response.registrosPagina;
        this.totalRegistros = response.totalRegistros;
        this.clientes = response.registros;
        this.dataSource.data = this.clientes;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  seleccionarColumna(nombreColumna): void {
    console.log('nombreColumna', nombreColumna)
  }

  pageSize: any;
  currentPage: any = 1;
  totalRegistros: number;

  onPageChange(event): void {
    console.log('event page',event)
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.obtenerClienteComerciales();
  }

  applyFilter(event: Event): void {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  buscador: string;

  buscarEnClientes(event) {
    console.log('event', event)
    this.buscador = event.target.value;
    this.obtenerClienteComerciales();
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.customer$,
      this.loading$
    ])
  }

  buscarClientes(): void {

    if(this.filtro.procedencia == 'N' && this.filtro.tipoPersona == 'N' && this.filtro.tipoDocumento == 'N') {
      this.dataSource = fillTable(this.clientes, this.paginator, this.sort);
      return;
    }

    if(this.filtro.procedencia != 'N' && this.filtro.tipoPersona == 'N' && this.filtro.tipoDocumento == 'N') {
      const filtrados = this.clientes.filter(item => {

        if(this.filtro.procedencia == '00') {

          return item.pai_codpai != '01';

        }

        return item.pai_codpai == '01'

      })
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      return;
    }

    if(this.filtro.procedencia == 'N' && this.filtro.tipoPersona != 'N' && this.filtro.tipoDocumento == 'N') {
      const filtrados = this.clientes.filter(
        item => item.cli_tipper == this.filtro.tipoPersona
      )
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      return;
    }

    if(this.filtro.procedencia == 'N' && this.filtro.tipoPersona == 'N' && this.filtro.tipoDocumento != 'N') {
      const filtrados = this.clientes.filter(
        item => item.did_coddid == this.filtro.tipoDocumento
      )
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      return;
    }

    if(this.filtro.procedencia != 'N' && this.filtro.tipoPersona != 'N' && this.filtro.tipoDocumento == 'N') {
      const filtrados = this.clientes.filter(
        item => {
          if(this.filtro.procedencia == '00') {

            return item.cli_tipper == this.filtro.tipoPersona && item.pai_codpai != '01';

          }

          return item.cli_tipper == this.filtro.tipoPersona && item.pai_codpai == '01'
        }
      )
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      return;
    }

    if(this.filtro.procedencia != 'N' && this.filtro.tipoPersona == 'N' && this.filtro.tipoDocumento != 'N') {
      const filtrados = this.clientes.filter(
        item => {
          if(this.filtro.procedencia == '00') {

            return item.did_coddid == this.filtro.tipoDocumento && item.pai_codpai != '01';

          }

          return item.did_coddid == this.filtro.tipoDocumento && item.pai_codpai == '01'
        }
      )
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      return;
    }

    if(this.filtro.procedencia == 'N' && this.filtro.tipoPersona != 'N' && this.filtro.tipoDocumento != 'N') {
      const filtrados = this.clientes.filter(
        item => item.cli_tipper == this.filtro.tipoPersona &&
        item.did_coddid == this.filtro.tipoDocumento
      )
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      return;
    }

    if(this.filtro.procedencia != 'N' && this.filtro.tipoPersona != 'N' && this.filtro.tipoDocumento != 'N') {
      const filtrados = this.clientes.filter(
        item => {
          if(this.filtro.procedencia == '00') {

            return item.cli_tipper == this.filtro.tipoPersona && item.did_coddid == this.filtro.tipoDocumento && item.pai_codpai != '01';

          }

          return item.cli_tipper == this.filtro.tipoPersona && item.did_coddid == this.filtro.tipoDocumento &&item.pai_codpai == '01'
        }

      )
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      return;
    }


  }

  copiar(codigo): void {

    this._snackBar.open(`Código copiado ${codigo} !!!`, 'Ok', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 1500
    });

  }

}

class FiltroCliente {
  procedencia: string;
  tipoDocumento: string;
  tipoPersona: string;

  constructor() {
    this.procedencia = 'N';
    this.tipoDocumento = 'N';
    this.tipoPersona = 'N';
  }
}
