<app-pavso-title-section [title]="'Choferes'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los choferes" url="/modulo-almacen/conductor" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <div class="pav-btns-container">
                  <a matTooltip="Editar chofer"
                    class="pav-btn-circle-edit"
                    [routerLink]="['/modulo-almacen/conductor', row.CHO_CODCHO]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </a>
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="CHO_CODCHO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.CHO_CODCHO}} </td>
          </ng-container>

          <ng-container matColumnDef="CHO_NOMBRE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Chofer </th>
              <td mat-cell *matCellDef="let row" data-label="Chofer"> {{row.CHO_NOMBRE}} </td>
          </ng-container>

          <ng-container matColumnDef="CHO_NROLIC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Licencia </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{row.CHO_NROLIC}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
