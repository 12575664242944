import { PermissionState } from "@data/interfaces/permission.state";
import { createReducer, on } from "@ngrx/store";
import { cambiarPermiso } from "../actions/permission.actions";

export const initialState: PermissionState = {
  isCreate: false,
  isDelete: false,
  isCancel: false,
  isEdit: false,
  isOptional: false,
  isExecute: false,
  isPrintFormat: false,
  isDownLoadToExcel: false,
};

const _permissionReducer = createReducer(
  initialState,
  on(cambiarPermiso, (_, {isCreate, isDelete, isCancel, isEdit, isOptional, isExecute, isPrintFormat, isDownLoadToExcel}) =>  {
    return {isCreate, isDelete, isCancel, isEdit, isOptional, isExecute, isPrintFormat, isDownLoadToExcel}
  }),
);

export function permissionReducer(state, action) {
  return _permissionReducer(state, action)
}
