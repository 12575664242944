import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MotivoParadaMaquina } from "src/app/models/produccion/maestros/motivo-parada-maquina";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class MotivoParadaMaquinaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}
/**
   *
   * @returns
   */
  obtenerMotivosParadaMaquina(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionmotivoparada/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  mostrarMotivoParadaMaquina(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionmotivoparada/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param motivoparadamaquina
   * @returns
   */
  registrarMotivoParadaMaquina(motivoparadamaquina: MotivoParadaMaquina): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionmotivoparada/crear`,
      motivoparadamaquina
    );
  }

  /**
   *
   * @param motivoparadamaquina
   * @returns
   */
  actualizarMotivoParadaMaquina(motivoparadamaquina: MotivoParadaMaquina): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionmotivoparada/modificar`,
      motivoparadamaquina
    );
  }
}