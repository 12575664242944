import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Item, Order } from 'src/app/models';
import { AlmacenService, AuthenticationService, ConfigurationService } from 'src/app/services';
import { IlumiProductListComponent } from '@shared/components/dialogs/ilumi/ilumi-product-list/ilumi-product-list.component';
import { Subscription } from 'rxjs';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { DetallePcd } from 'src/app/models/ventas/order';

@Component({
  selector: 'inventario-fisico-form',
  templateUrl: './inventario-fisico-form.component.html',
  styleUrls: ['./inventario-fisico-form.component.css']
})
export class InventarioFisicoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;

  displayedColumns: string[] = ['acciones', 'item', 'codigo', 'descripcion', 'cod_anterior', 'marca', 'part_number', 'um', 'cantidad_inventario'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  usuario: any;
  fecha: Date = new Date();
  producto: Item;

  order: Order;

  loaderData: boolean = false;

  almacenes: any [] = [];

  almacen$: Subscription;
  product$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    private _authService: AuthenticationService,
    private _configurationService: ConfigurationService,
    private _almacenService: AlmacenService,
    private _authenticationService: AuthenticationService,
    private _productoService: ProductoService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.usuario = this._authService.getUsuarioSistema();

    this.order = new Order();
    this.order.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.order.PCC_TASIGV = 18.00;
    this.order.PCC_TIPCAM = 3.640;
    this.order.PCC_CODUSE = this._authenticationService.getUsuarioSistema();

    this.producto = new Item();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  /**
   * Load any master list or default values
   */
  loadData(): void {
    this.loaderData = true;
    this.almacen$ = this._almacenService.obtenerAlamacenes().subscribe(
      almacenes => {

        this.loaderData = false;
        this.almacenes = almacenes;

      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  /**
   * Muestra un Mat Dialog para la selección de un/unos productos
   */
  seleccionarProducto(): void {

    const dialogRef = this.dialog.open(IlumiProductListComponent, {
      width: '800px',
      data: {},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

        console.log('result', result)
        let validator = false;

        this.order.detalle_pcd.forEach(element => {
          if(element.PRD_CODPRD == result.prd_codprd) {
            validator = true;
            element.PCD_CANSOL++;
            element.PCD_IMPBRU = element.PCD_CANSOL * element.PCD_PREUNI;
            element.PCD_IMPBRU = element.PCD_IMPBRU.toFixed(2);
            element.PCD_VALVTA = element.PCD_IMPBRU - element.PCD_IMPDES;
            element.PCD_VALVTA = element.PCD_VALVTA.toFixed(2);
            element.PCD_IMPTOT = element.PCD_CANSOL * element.PCD_PRUIGV - element.PCD_IMPDES;
            element.PCD_IMPTOT = element.PCD_IMPTOT.toFixed(2);
            element.PCD_IMPIGV = element.PCD_IMPTOT - element.PCD_VALVTA;
            element.PCD_IMPIGV = element.PCD_IMPIGV.toFixed(2);
          }
        })

        if(!validator) {

          let producto = new DetallePcd();

          producto.PRD_CODPRD = result.prd_codprd;
          producto.UME_CODVEN = result.UNIDAD;
          producto.PRD_DESESP = result.DESC_ESPA;
          producto.PCD_CANSOL = 1;
          producto.PCD_CANAUT = 0;
          producto.PCD_CANATE = 0;
          producto.PCD_CANFAC = 0;
          producto.PCD_CANSUS = 0;
          producto.PCD_IMPDES = 0;
          producto.PCD_PORDES = 0;
          producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
          producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
          producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
          producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

          this.order.detalle_pcd.push(producto)
          // this.consultarPrecioProducto(producto)


        }

        this.calcularTotales();

        this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

      }
    })

  }

  /**
   * Busca un determinado producto en base al código de barra
   * @param value value to search
   */
  searchCode(value): void {

    this.product$ = this._productoService.obtenerProducto(value).subscribe(
      producto => {

        let validator = false;

        this.order.detalle_pcd.forEach(element => {
          if(element.PRD_CODPRD == producto[0].prd_codprd) {
            validator = true;
            element.PCD_CANSOL++;
            element.PCD_IMPBRU = element.PCD_CANSOL * element.PCD_PREUNI;
            element.PCD_IMPBRU = element.PCD_IMPBRU.toFixed(2);
            element.PCD_VALVTA = element.PCD_IMPBRU - element.PCD_IMPDES;
            element.PCD_VALVTA = element.PCD_VALVTA.toFixed(2);
            element.PCD_IMPTOT = element.PCD_CANSOL * element.PCD_PRUIGV - element.PCD_IMPDES;
            element.PCD_IMPTOT = element.PCD_IMPTOT.toFixed(2);
            element.PCD_IMPIGV = element.PCD_IMPTOT - element.PCD_VALVTA;
            element.PCD_IMPIGV = element.PCD_IMPIGV.toFixed(2);
          }
        })
        if(!validator) {

          let producto = new DetallePcd();

          producto.PRD_CODPRD = producto[0].prd_codprd;
          producto.UME_CODVEN = producto[0].ume_codume;
          producto.PRD_DESESP = producto[0].prd_desesp;
          producto.PCD_CANSOL = 1;
          producto.PCD_CANAUT = 0;
          producto.PCD_CANATE = 0;
          producto.PCD_CANFAC = 0;
          producto.PCD_CANSUS = 0;
          producto.PCD_IMPDES = 0;
          producto.PCD_PORDES = 0;
          producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
          producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
          producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
          producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

          this.order.detalle_pcd.push(producto)
          // this.consultarPrecioProducto(producto)

        }

        this.calcularTotales();

        this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  /**
   * Calcula los totales de importe
   */
  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;

    this.order.detalle_pcd.forEach(element => {
      valorVenta += Number(element.PCD_VALVTA);
      impBruto += Number(element.PCD_IMPBRU);
      impIGV += Number(element.PCD_IMPIGV);
      descuento += Number(element.PCD_IMPDES);
      impTotal += Number(element.PCD_IMPTOT);
    })

    this.order.PCC_VALVTA = valorVenta;
    this.order.PCC_VALVTA = this.order.PCC_VALVTA.toFixed(2);

    this.order.PCC_IMPBRU = impBruto;
    this.order.PCC_IMPBRU = this.order.PCC_IMPBRU.toFixed(2);

    this.order.PCC_IMPIGV = impIGV;
    this.order.PCC_IMPIGV = this.order.PCC_IMPIGV.toFixed(2);

    this.order.PCC_IMPDES = descuento;
    this.order.PCC_IMPDES = this.order.PCC_IMPDES.toFixed(2);

    this.order.PCC_IMPTOT = impTotal;
    this.order.PCC_IMPTOT = this.order.PCC_IMPTOT.toFixed(2);
  }

  /**
   * Realiza el filtro de listado de productos
   * @param event event to get value of searching
   */
  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  agregarItem(): void {}

  insertarItem(): void {}

  quitarItem(): void {}

  /**
   * Destroy any subscription
   */
  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.almacen$,
      this.product$,
      this.loading$
    ])
  }

}
