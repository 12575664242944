import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmationReturnService } from '../services/confirmation-return.service';

export interface CanComponentReturn {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard
  implements CanDeactivate<CanComponentReturn>
{
  private showConfirmationDialog: boolean = true;

  constructor(private confirmationService: ConfirmationReturnService) {}

  setShowConfirmationDialog(value: boolean): void {
    this.showConfirmationDialog = value;
  }

  canDeactivate(
    component: CanComponentReturn
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.showConfirmationDialog && component.canDeactivate
      ? this.confirmationService.confirm('Are you sure you want to leave this page?')
      : true;

  }
}
