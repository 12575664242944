<app-pavso-title-section [title]="'apps.crm.maestros.cliente.title' | translate"
  [module]="'CRM'" [estado]="cliente.cli_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarCliente(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">
    <div class="row">
      <section>
        <mat-card *ngIf="errorMessage$| async as errorMessage" class="notification">{{errorMessage}}</mat-card>
      </section>
    </div>

    <div class="row">
      <div class="col s12 m12 l9">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Código" name="cli_codcli"
                  [(ngModel)]="cliente.cli_codcli" (change)="ingresoCodigo($event)" (keyup)="ingresoCodigo($event)"
                  required>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Tipo Persona</mat-label>
                <mat-select [disabled]="loaderData" required name="cli_tipper" [(ngModel)]="cliente.cli_tipper"
                  (selectionChange)="seleccionarTipoPersona($event.value)">
                  <mat-option *ngFor="let tipo of tiposDePersona" [value]="tipo.tpe_codtpe">
                    {{tipo.tpe_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Tipo documento</mat-label>
                <mat-select [disabled]="loaderData" required name="did_coddid" [(ngModel)]="cliente.did_coddid"
                  (selectionChange)="seleccionarTipoDocumento($event.value)">
                  <mat-option *ngFor="let tipo of tiposDeDocumento" [value]="tipo.DID_CODDID">
                    {{tipo.DID_DESCRI}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l9" *ngIf="!isNatural">
              <mat-form-field>
                <mat-label>Razon social</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Razon social" name="razonsocial"
                  [(ngModel)]="razonsocial" [disabled]="isNatural">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="!isNatural">
              <mat-form-field>
                <mat-label>RUC</mat-label>
                <input [disabled]="loaderData" type="number" matInput placeholder="RUC" name="ruc" [(ngModel)]="ruc"
                  [disabled]="isRuc" min="0" max="99999999999" (keyup)="validarRuc()">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="isNatural">
              <mat-form-field>
                <mat-label>Ape. Paterno</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Ape. Paterno" name="cli_apepat"
                  [(ngModel)]="cliente.cli_apepat" [disabled]="!isNatural">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="isNatural">
              <mat-form-field>
                <mat-label>Ape. Materno</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Ape. Materno" name="cli_apemat"
                  [(ngModel)]="cliente.cli_apemat" [disabled]="!isNatural">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="isNatural">
              <mat-form-field>
                <mat-label>Nombres</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Nombres" name="cli_nombre"
                  [(ngModel)]="cliente.cli_nombre" [disabled]="!isNatural">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="isNatural">
              <mat-form-field>
                <mat-label>DNI / Otros</mat-label>
                <input [disabled]="loaderData" type="number" matInput placeholder="DNI" name="dni" [(ngModel)]="dni"
                  [disabled]="!isRuc">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l9">
              <mat-form-field>
                <mat-label>Dirección</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Direccion" name="cli_dircli"
                  [(ngModel)]="cliente.cli_dircli" [value]="transcript$ | async">

                <!-- <button matSuffix mat-mini-fab *ngIf="listening$ | async; else mic" (click)="stop()" type="button" color="primary">
                                    <mat-icon class="soundwave">mic</mat-icon>
                                </button>
                                <ng-template #mic>
                                    <button matSuffix mat-mini-fab (click)="start()" type="button" color="primary">
                                        <mat-icon>mic</mat-icon>
                                    </button>
                                </ng-template> -->
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Sucursal</mat-label>
                <mat-select [disabled]="loaderData" name="suc_codsuc" [(ngModel)]="cliente.suc_codsuc" #sucursalSelect>
                  <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.SUC_CODSUC">
                    {{sucursal.suc_nomsuc}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <select-search [disabled]="loaderData" [placeholder]="'País'" [name]="'PAI_CODPAI'" [value]="'PAI_CODPAI'"
                [description]="'pai_nomlar'" [data]="paises" (cambioSelect)="cliente.pai_codpai = $event"
                [model]="cliente.pai_codpai"></select-search>
            </div>

            <div class="col s12 m12 l3">
              <select-search [disabled]="loaderData" [placeholder]="'Ubicación'" [name]="'codubi'" [value]="'codubi'"
                [description]="'desubi'" [data]="ubicaciones" (cambioSelect)="cliente.ubi_codubi = $event"
                [model]="cliente.ubi_codubi"></select-search>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Teléfono</mat-label>
                <input [disabled]="loaderData" required type="tel" type="tel" matInput placeholder="Teléfono"
                  name="cli_numtlf" [(ngModel)]="cliente.cli_numtlf">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Teléfono 2</mat-label>
                <input [disabled]="loaderData" required type="tel" type="tel" matInput placeholder="Teléfono 2"
                  name="cli_numfax" [(ngModel)]="cliente.cli_numfax">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3" *ngIf="!isNatural">
              <mat-form-field>
                <mat-label>Contacto</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Contacto" name="cli_nomcon"
                  [(ngModel)]="cliente.cli_nomcon">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="!isNatural">
              <mat-form-field>
                <mat-label>Cargo</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Cargo" name="cli_carcon"
                  [(ngModel)]="cliente.cli_carcon">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="!isNatural">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input [disabled]="loaderData" type="email" matInput placeholder="E-mail" name="cli_corele"
                  [(ngModel)]="cliente.cli_corele">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="!isNatural">
              <select-search [disabled]="loaderData" [placeholder]="'Giro de Negocio'" [name]="'gno_codgno'"
                [value]="'gno_codgno'" [description]="'gno_desesp'" [data]="giros"
                (cambioSelect)="cliente.gno_codgno = $event" [model]="cliente.gno_codgno"></select-search>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col s12 m12 l3">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12">
              <mat-form-field>
                <mat-label>Obligaciones tributarias</mat-label>
                <textarea [disabled]="loaderData" matInput placeholder="Obligaciones tributarias"
                  name="obligaciones"></textarea>
              </mat-form-field>
            </div>
            <div class="col s12">
              <select-search [disabled]="loaderData" [placeholder]="'Ubig. Sunat'" [name]="'ubs_codubs'"
                [value]="'ubs_codubs'" [description]="'ubs_descri'" [data]="ubicacionesSunat"
                (cambioSelect)="cliente.ubs_codubs = $event" [model]="cliente.ubs_codubs"></select-search>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <br>
    <mat-card class="mat-elevation-z0">
      <mat-tab-group backgroundColor="primary">
        <mat-tab label="Datos Comerciales" [disabled]="loaderData">
          <div class="container">
            <div class="row">
              <div class="col s12">
                <div class="col s12 m12 l3">
                  <select-search [disabled]="loaderData" [placeholder]="'Vendedor'" [name]="'VDE_CODVDE'"
                    [value]="'VDE_CODVDE'" [description]="'VDE_NOMVDE'" [data]="vendedores"
                    (cambioSelect)="cliente.dato_clc[0].codvde = $event"
                    [model]="cliente.dato_clc[0].codvde"></select-search>
                </div>

                <div class="col s12 m12 l3">
                  <select-search [disabled]="loaderData" [placeholder]="'Condi. Pago.'" [name]="'CPA_CODCPA'"
                    [value]="'CPA_CODCPA'" [description]="'CPA_DESCRI'" [data]="condiciones"
                    (cambioSelect)="cliente.dato_clc[0].codcpa = $event"
                    [model]="cliente.dato_clc[0].codcpa"></select-search>
                </div>

                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Sectorista</mat-label>
                    <mat-select required [(ngModel)]="cliente.dato_clc[0].codsec" name="codsec">
                      <mat-option *ngFor="let sectorista of sectoristas" [value]="sectorista.sec_codsec">
                        {{sectorista.descri}}
                      </mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Tipo de Lista</mat-label>
                    <mat-select required name="codtlp" [(ngModel)]="cliente.dato_clc[0].codtlp">
                      <mat-option *ngFor="let tipo of tiposDeLista" [value]="tipo.TLP_CODTLP">
                        {{tipo.tlp_descri}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l3">
                  <select-search [disabled]="loaderData" [placeholder]="'Zona Vta.'" [name]="'codzve'"
                    [value]="'codzve'" [description]="'zve_nomzve'" [data]="zonas"
                    (cambioSelect)="cliente.dato_clc[0].codzve = $event"
                    [model]="cliente.dato_clc[0].codzve"></select-search>
                </div>

                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Canal</mat-label>
                    <mat-select required [(ngModel)]="cliente.dato_clc[0].codvds" name="codvds">
                      <mat-option *ngFor="let canal of canales" [value]="canal.vds_codvds">
                        {{canal.vds_descri}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Cod. Prod. en Comprobantes</mat-label>
                    <mat-select required [(ngModel)]="cliente.dato_clc[0].indprd" name="indprd">
                      <mat-option *ngFor="let comprobante of productosComprobante" [value]="comprobante.prc_codprc">
                        {{comprobante.prc_descri}}
                      </mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l3">
                  <section class="example-section">
                    <mat-checkbox class="example-margin" name="inoped" [(ngModel)]="cliente.dato_clc[0].inoped">No
                      Registrar Pedido</mat-checkbox>
                  </section>
                </div>
              </div>


            </div>
          </div>

        </mat-tab>
        <mat-tab label="Datos adicionales" [disabled]="loaderData">
          <div class="container">
            <div class="row">
              <div class="col s12">
                <div class="row">
                  <div class="col s12 m12 l4">
                    <app-currency-select [moneda]="cliente.dato_clc[0].codtmo" [disable]="loaderData"
                      (cambioMoneda)="cliente.dato_clc[0].codtmo = $event"></app-currency-select>
                  </div>
                  <div class="col s12 m12 l4">
                    <mat-form-field required class="example-full-width">
                      <mat-label>Importe</mat-label>
                      <input type="number" [(ngModel)]="cliente.dato_clc[0].creasi" matInput placeholder="Importe"
                        name="creasi">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l4">
                    <mat-form-field>
                      <mat-label>Calificación</mat-label>
                      <mat-select required [(ngModel)]="cliente.dato_clc[0].codtcc" name="codtcc">
                        <mat-option *ngFor="let calificacion of calificaciones" [value]="calificacion.tcc_codtcc">
                          {{calificacion.tcc_destcc}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col s12">
                <div class="row">

                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Servicios Ofrecidos" [disabled]="loaderData">

          <div class="container">
            <div class="row">
              <div class="col s12">
                <div class="pav-table-header-container">
                  <div class="pav-table-header-icon">

                  </div>
                  <div class="pav-table-header-title">
                    Servicios Ofrecidos
                  </div>
                  <div class="pav-table-header-add">
                    <div class="isMobile">
                      <div class="pav-button-icon-add" (click)="agregarServicio()">
                        <mat-icon svgIcon="add"></mat-icon>
                      </div>
                    </div>
                    <div class="isWeb">
                      <button [disabled]="loaderData" mat-flat-button class="pav-button-add-item" type="button"
                        (click)="agregarServicio()">Agregar ítem</button>
                    </div>
                  </div>
                </div>
                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceServicios" matSort>

                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div *ngIf="!row.isEditing" (click)="editarServicio(row)" class="pav-btn-circle-edit"
                            matTooltip="Editar">
                            <mat-icon svgIcon="edit-white"></mat-icon>
                          </div>
                          <div *ngIf="row.isEditing" (click)="confirmarServicio(row)" class="pav-btn-circle-confirm"
                            matTooltip="Confirmar">
                            <mat-icon svgIcon="confirm"></mat-icon>
                          </div>
                          <div class="pav-btn-circle-delete" (click)="quitarServicio(row)" matTooltip="Eliminar">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                        </div>
                        <!-- <butt<div class="pav-btns-container">
                                          <div *ngIf="!row.isEditing" (click)="editarContacto(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                                            <mat-icon svgIcon="edit-white"></mat-icon>
                                          </div>
                                          <div *ngIf="row.isEditing" (click)="confirmarContacto(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                                            <mat-icon svgIcon="confirm"></mat-icon>
                                          </div>
                                          <div class="pav-btn-circle-delete" (click)="quitarContacto(row)" matTooltip="Eliminar">
                                            <mat-icon svgIcon="delete"></mat-icon>
                                          </div>
                                        </div> -->
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="codigo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <mat-select required [(ngModel)]="row.tse_codtse" [name]="'tse_codtse'+i"
                              (selectionChange)="seleccionarServicio($event, row)">
                              <mat-option *ngFor="let servicio of servicios"
                                [value]="servicio.TSE_CODTSE">{{servicio.TSE_DESTSE}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{row.tse_descri}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsServicios"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsServicios;"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="2">No se encontraron registros</td>

                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </mat-tab>
        <mat-tab label="Contactos" [disabled]="loaderData">
          <div class="container">

            <div class="row">
              <div class="col s12">
                <div class="pav-table-header-container">
                  <div class="pav-table-header-icon">

                  </div>
                  <div class="pav-table-header-title">
                    Contactos
                  </div>
                  <div class="pav-table-header-add">
                    <div class="isMobile">
                      <div class="pav-button-icon-add" (click)="agregarContacto()">
                        <mat-icon svgIcon="add"></mat-icon>
                      </div>
                    </div>
                    <div class="isWeb">
                      <button [disabled]="loaderData" mat-flat-button class="pav-button-add-item" type="button"
                        (click)="agregarContacto()">Agregar ítem</button>
                    </div>
                  </div>
                </div>
                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceContactos" matSort>

                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div *ngIf="!row.isEditing" (click)="editarContacto(row)" class="pav-btn-circle-edit"
                            matTooltip="Editar">
                            <mat-icon svgIcon="edit-white"></mat-icon>
                          </div>
                          <div *ngIf="row.isEditing" (click)="confirmarContacto(row)" class="pav-btn-circle-confirm"
                            matTooltip="Confirmar">
                            <mat-icon svgIcon="confirm"></mat-icon>
                          </div>
                          <div class="pav-btn-circle-delete" (click)="quitarContacto(row)" matTooltip="Eliminar">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                        </div>
                        <!-- <button type="button" (click)="eliminarContacto(row)" mat-icon-button color="warn">
                                              <mat-icon>delete</mat-icon>
                                          </button> -->
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="codigo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Código"> {{row.ccl_codccl + 1}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="nombre">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Nombre">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <input min="0" required type="text" matInput placeholder="Nombre"
                              [(ngModel)]="row.ccl_nomccl" [name]="'ccl_nomccl' + i.toString()">
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{row.ccl_nomccl}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="cargo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cargo </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Cargo">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field>
                            <mat-label></mat-label>
                            <mat-select [name]="'cca_codcca' + i" [(ngModel)]="row.cca_codcca"
                              (selectionChange)="seleccionarCargo($event, row)" required>
                              <mat-option *ngFor="let cargo of cargos" [value]="cargo.codnca">
                                {{cargo.descri}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="!row.isEditing">
                          {{row.cca_descri}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="telefono">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <input min="0" required type="tel" matInput placeholder="Teléfono"
                              [(ngModel)]="row.ccl_numtlf" [name]="'ccl_numtlf' + i.toString()">
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{row.ccl_numtlf}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="correo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Correo">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <input min="0" required type="email" matInput placeholder="Correo"
                              [(ngModel)]="row.ccl_corele" [name]="'ccl_corele' + i.toString()">
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{row.ccl_corele}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha_nacimiento">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de nacimiento </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Fecha de nacimiento">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <input matInput [matDatepicker]="picker" [name]="'ccl_cumple' + i.toString()"
                              [(ngModel)]="row.ccl_cumple" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>

                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{row.ccl_cumple | date: 'dd/MM/yyyy'}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="referencia">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Referencia">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <input min="0" required type="text" matInput placeholder="Referencia"
                              [(ngModel)]="row.ccl_glosa" [name]="'ccl_glosa' + i.toString()">
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{row.ccl_glosa}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="cobranza">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cobranza </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Cobranza">
                        <ng-container *ngIf="row.isEditing">
                          <mat-checkbox class="example-margin" [(ngModel)]="row.ccl_indcob"
                            [name]="'ccl_indcob' + i">{{(row.ccl_indcob)?"SI": "NO"}}</mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{(row.ccl_indcob)?"SI": "NO"}}
                        </ng-container>
                      </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumnsContactos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsContactos;"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="9">No se encontraron registros</td>

                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Establecimientos - Lugares de despacho" [disabled]="loaderData">
          <div class="container">
            <div class="row">
              <div class="col s12">
                <div class="pav-table-header-container">
                  <div class="pav-table-header-icon">

                  </div>
                  <div class="pav-table-header-title">
                    Establecimientos
                  </div>
                  <div class="pav-table-header-add">
                    <div class="isMobile">
                      <div class="pav-button-icon-add" (click)="agregarEstablecimiento()">
                        <mat-icon svgIcon="add"></mat-icon>
                      </div>
                    </div>
                    <div class="isWeb">
                      <button [disabled]="loaderData" mat-flat-button class="pav-button-add-item" type="button"
                        (click)="agregarEstablecimiento()">Agregar ítem</button>
                    </div>
                  </div>
                </div>
                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceEstablecimientos" matSort>

                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div *ngIf="!row.isEditing" (click)="editarEstablecimiento(row)" class="pav-btn-circle-edit"
                            matTooltip="Editar">
                            <mat-icon svgIcon="edit-white"></mat-icon>
                          </div>
                          <div *ngIf="row.isEditing" (click)="confirmarEstablecimiento(row)"
                            class="pav-btn-circle-confirm" matTooltip="Confirmar">
                            <mat-icon svgIcon="confirm"></mat-icon>
                          </div>
                          <div class="pav-btn-circle-delete" (click)="quitarEstablecimiento(row)" matTooltip="Eliminar">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                        </div>
                        <!-- <button type="button" (click)="eliminarEstablecimiento(row)" mat-icon-button color="warn" aria-label="Quitar contacto">
                                              <mat-icon>delete</mat-icon>
                                          </button> -->
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="codigo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. </th>
                      <td mat-cell *matCellDef="let row" data-label="Cod."> {{row.LDE_CODLDE}} </td>
                    </ng-container>

                    <ng-container matColumnDef="principal">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Principal </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Principal">
                        <ng-container *ngIf="row.isEditing">
                          <mat-checkbox class="example-margin" [(ngModel)]="row.LDE_INDPRI"
                            [name]="'LDE_INDPRI' + i">{{(row.LDE_INDPRI)?"SI": "NO"}}</mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{(row.LDE_INDPRI)?"SI": "NO"}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="direccion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Dirección">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <input min="0" required type="text" matInput placeholder="Dirección"
                              [(ngModel)]="row.LDE_DIRLDE" [name]="'LDE_DIRLDE' + i.toString()">
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{row.LDE_DIRLDE}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="referencia">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Referencia">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <input min="0" required type="text" matInput placeholder="Referencia"
                              [(ngModel)]="row.LDE_REFDIR" [name]="'LDE_REFDIR' + i.toString()">
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!row.isEditing">
                          {{row.LDE_REFDIR}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="pais">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pais </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Pais">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field>
                            <mat-label></mat-label>
                            <mat-select [name]="'PAI_CODPAI' + i" [(ngModel)]="row.PAI_CODPAI"
                              (selectionChange)="seleccionarPais($event, row)" required>
                              <mat-option *ngFor="let pais of paises" [value]="pais.PAI_CODPAI">
                                {{pais.pai_nomlar}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="!row.isEditing">
                          {{row.pai_nomlar}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="ubicacion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubicación </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Ubicación">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field>
                            <mat-label></mat-label>
                            <mat-select [name]="'UBI_CODUBI' + i" [(ngModel)]="row.UBI_CODUBI"
                              (selectionChange)="seleccionarUbicacionTabla($event, row)" required>
                              <mat-option *ngFor="let ubicacion of ubicaciones" [value]="ubicacion.codubi">
                                {{ubicacion.desubi}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="!row.isEditing">
                          {{row.UBI_DESUBI}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="zona">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Zona">
                        <ng-container *ngIf="row.isEditing">
                          <mat-form-field>
                            <mat-label></mat-label>
                            <mat-select [name]="'ZVE_CODZVE' + i" [(ngModel)]="row.ZVE_CODZVE"
                              (selectionChange)="seleccionarZona($event, row)" required>
                              <mat-option *ngFor="let zona of zonas" [value]="zona.zve_codzve">
                                {{zona.zve_nomzve}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="!row.isEditing">
                          {{row.ZVE_DESZVE}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsEstablecimientos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsEstablecimientos;"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="8">No se encontraron registros</td>

                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-crm/clientes-crm">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
