export class UbicacionAlmacen {
  CIA_CODCIA: string;
  UBA_CODUBA: string;
  UBA_DESABR: string;
  UBA_DESCRI: string;
  UBA_INDSTA: string;
  UBA_FECUPD: Date;
  UBA_: string;

  constructor() {
    this.UBA_INDSTA = '1';
    this.UBA_FECUPD = new Date();
  }
}
