import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { LineaProducto } from "src/app/models/almacen/linea-producto";
import { ConfigurationService, ContabilidadService } from "src/app/services";
import { LineaService } from "src/app/services/api/almacen/maestros/linea.service";

@Component({
  selector: 'linea-producto-form',
  templateUrl: './linea-producto-form.component.html',
  styleUrls: ['./linea-producto-form.component.scss']
})
export class LineaProductoFormComponent implements OnInit, OnDestroy {

  loaderData: boolean;
  loaderReg: boolean;
  linea: LineaProducto;

  cuentasContable: any[] = [];

  uid: string;

  sendForm$: Subscription;
  cuentas$: Subscription;
  linea$: Subscription;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _lineaService: LineaService,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _contabilidadService: ContabilidadService,
    private _configurationService: ConfigurationService,

  ) {
    this.linea = new LineaProducto();
    this.linea.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.linea.lpd_coduse = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(params => {
      this.uid = params.id;
    })

    this.loadMaestros();
  }

  loadMaestros(): void {
    this.cuentas$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
      cuentas => {
        this.cuentasContable = cuentas;

        if(this.uid != '0') {
          this.obtenerLineaProducto();
          return;

        }

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener cuentas', 'Ok');
        this.loaderData = false;
      }
    )
  }

  obtenerLineaProducto(): void {
    this.linea$ = this._lineaService.obtenerLineaProducto(this.uid).subscribe(
      response => {
        this.linea = response;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener linea de producto', 'Ok');
        this.loaderData = false;
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarLineaProducto() : this.actualizarLineaProducto();
  }

  registrarLineaProducto(): void {
    this.sendForm$ = this._lineaService.registrarLineaProducto(this.linea).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Línea de producto registrada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al registrar linea', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarLineaProducto(): void {
    this.sendForm$ = this._lineaService.actualizarLineaProducto(this.linea).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Línea de producto actualizada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al actualizar linea', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.sendForm$])
  }
}
