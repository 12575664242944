import moment from "moment";
import { RecepcionCompra } from "./recepcion";

export class RegistroCompra {
  cia_codcia: any;
  cco_codcco: string;
  cli_codcli: string;
  cliente: {cli_nomcli: string}
  cli_coddoc: any;
  tdo_codtdo: any;
  cco_numdoc: any;
  cco_glocco: any;
  cco_fecemi: any;
  cco_fecven: any;
  tmo_codtmo: any;
  ccn_codccn: string;
  cco_impdoc: any;
  cco_impcco: any;
  cco_salcco: any;
  cco_sallba: any;
  cco_salres: any;
  cco_impabo: any;
  cco_impigv: any;
  cco_tasigv: any;
  cco_impcor: any;
  cco_impina: any;
  cco_impafe: any;
  cco_indtca: any;
  cco_tipcam: any;
  cco_codpag: any;
  mmo_codmmo: any;
  cpa_codcpa: any;
  tdo_docref: any;
  cco_numref: any;
  ano_codano: any;
  mes_codmes: any;
  sco_codsco: any;
  ldc_corldc: any;
  sco_codasi: any;
  ldc_corasi: any;
  cco_fecreg: any;
  age_codage: any;
  cco_abotra: any;
  cco_indsta: string;
  cco_coduse: string;
  cco_codant: any;
  cco_impret: any;
  cco_aboret: any;
  cco_retgas: any;
  iri_codiri: any;
  suc_codsuc: any;
  tga_codtga: any;
  cco_fecref: any;
  ocm_numocm: any;
  cco_numcuo: any;
  ipi_codipi: any;
  cco_impper: any;
  cco_permna: any;
  cco_movil: any;
  cco_cbmoti: any;
  cco_condic: any;
  cco_code: any;
  dpc_indder: any;
  dpc_fecrec: any;
  dpc_indsta: string;
  dpc_fecupd: any;
  dpc_coduse: string;
  dpc_tasren: any;
  dpc_tasies: any;
  dpc_tasore: any;
  dpc_impren: any;
  dpc_impies: any;
  dpc_impore: any;
  dpc_difigv: any;
  dpc_afcder: any;
  dpc_afsder: any;
  dpc_impisc: any;
  dpc_igvisc: any;
  dpc_tasisc: any;
  dpc_otrtri: any;
  dpc_valare: any;
  dpc_inddif: any;
  dpc_perini: any;
  dpc_impdif: any;
  dpc_numpol: any;
  dpc_parara: any;
  dpc_impfob: any;
  dpc_impfle: any;
  dpc_impseg: any;
  dpc_impcif: any;
  dpc_impadv: any;
  dpc_reipap: any;
  dpc_afepol: any;
  dpc_inddet: any;
  dpc_inddet_c: any;
  tim_codtim: any;
  dpc_tasdet: any;
  dpc_depdet: number | string;
  dpc_impdet: any;
  dpc_fecdet: any;
  dpc_indntc: any;
  dpc_anotri: any;
  dpc_mestri: any;
  dpc_impper: any;
  dpc_numdua: any;
  dpc_anodua: any;
  oim_numoim: any;
  sad_codsad: any;
  dpc_impsis: any;
  dpc_impfis: any;
  t30_codt30: any;
  t31_codt31: any;
  valvta: any;
  detalle: Array<DetailPurchaseRecord>;
  ordenes: Array<OrdersPurchaseRecord>;

  cco_codref: any;
  constructor(
  ){
    this.valvta = 0;
    this.cco_tasigv = 18; // obtener desde un servicio
    this.dpc_indsta = '1';
    this.cco_fecreg = new Date();
    this.cco_fecemi = new Date();
    this.dpc_fecupd = new Date();
    this.cco_tipcam = 0;
    this.detalle = [];
    this.ordenes = [];
    // this.cpa_codcpa = '112';

    this.dpc_afcder = 0;
    this.dpc_afsder = 0;
    this.dpc_tasren = 0;
    this.dpc_impren = 0;
    this.dpc_difigv = 0;
    this.dpc_impsis = 0;
    this.dpc_impfis = 0;
    this.cco_impina = 0;
    this.cco_impafe = 0;
    this.cco_impigv = 0;
    this.cco_impdoc = 0;

    this.dpc_tasdet = 0;
    this.dpc_impdet = 0;
    this.cco_impper = 0;
    this.dpc_depdet = 0;

    this.dpc_impfob = 0;
    this.dpc_impfle = 0;
    this.dpc_impseg = 0;
    this.dpc_impcif = 0;
    this.dpc_impadv = 0;

    this.dpc_indder = '1';

    this.age_codage = null;
    this.cco_aboret = 0
    this.cco_abotra = 0
    this.cco_cbmoti = null
    this.cco_codant = null
    this.cco_code = null
    this.cco_codpag = "P"
    this.cco_condic = null
    this.cco_impabo = 0
    this.cco_impcco = this.cco_impdoc * (-1);
    this.cco_impcor = 0
    this.cco_impret = 0
    this.cco_indtca = ""
    this.cco_movil = ""
    this.cco_numcuo = ""
    this.cco_permna = null
    this.cco_retgas = null
    this.cco_salcco = null
    this.cco_sallba = 0
    this.cco_salres = 0

    this.dpc_fecrec = null
    this.dpc_fecupd = new Date();
    this.dpc_impdet = 0
    this.dpc_igvisc = 0
    this.dpc_impdif = 0
    this.dpc_impies = 0
    this.dpc_impisc = 0
    this.dpc_impore = 0
    this.dpc_impper = 0
    this.dpc_inddet = null
    this.dpc_inddet_c = null
    this.dpc_inddif = "0"
    this.dpc_indntc = 0
    this.dpc_indsta = "1"
    this.cco_indsta = "1"
    this.dpc_mestri = null
    this.dpc_numdua = null
    this.dpc_numpol = null
    this.dpc_otrtri = 0
    this.dpc_parara = null
    this.dpc_perini = null
    this.dpc_reipap = null
    this.dpc_tasies = null
    this.dpc_tasisc = null
    this.dpc_tasore = null
    this.dpc_valare = null
    this.ipi_codipi = null
    this.ldc_corasi = null
    this.ldc_corldc = "00001"
    this.mmo_codmmo = null
    this.oim_numoim = null
    this.sco_codasi = null
    this.suc_codsuc = "01"
    this.dpc_tasren = 0;
    this.dpc_impren = 0;
    this.tdo_docref = "";
    this.cco_numref = "";

    this.dpc_fecdet = moment();

    this.cli_coddoc = null
    this.dpc_afepol = 0
    this.dpc_anotri = null


    this.tmo_codtmo = "SO";
    this.cco_indsta = "1";
    this.iri_codiri = "00";
    this.cliente = {cli_nomcli: ''}
  }

  static fromRecepcionToRegistroCompra(recepcion: RecepcionCompra): RegistroCompra {
    const registroCompra = new RegistroCompra();
    registroCompra.cco_numdoc = recepcion.CCO_NUMDOC;
    registroCompra.cli_codcli = recepcion.CLI_CODCLI;
    registroCompra.cli_coddoc = recepcion.CLI_CODDOC;
    registroCompra.sad_codsad = recepcion.sad_codsad;
    registroCompra.cco_fecreg = recepcion.CCO_FECREG;
    registroCompra.cco_fecemi = recepcion.CCO_FECEMI;
    registroCompra.dpc_fecupd = new Date();
    registroCompra.cco_tipcam = recepcion.CCO_TIPCAM;
    registroCompra.detalle = recepcion.detalle;
    registroCompra.ordenes = [];
    registroCompra.cpa_codcpa = recepcion.CPA_CODCPA;

    registroCompra.cia_codcia = recepcion.CIA_CODCIA;
    registroCompra.cco_codcco = recepcion.CCO_CODCCO;
    registroCompra.tdo_codtdo = recepcion.TDO_CODTDO;
    registroCompra.cco_glocco = recepcion.CCO_GLOCCO;
    registroCompra.cco_fecven = recepcion.CCO_FECVEN;
    registroCompra.tmo_codtmo = recepcion.TMO_CODTMO;
    registroCompra.ccn_codccn = recepcion.CCN_CODCCN;
    registroCompra.cco_impdoc = recepcion.CCO_IMPDOC;
    registroCompra.cco_impcco = 0;
    registroCompra.cco_salcco = 0;
    registroCompra.cco_sallba = 0;
    registroCompra.cco_salres = 0;
    registroCompra.cco_impabo = 0;
    registroCompra.cco_impigv = recepcion.CCO_IMPIGV;
    registroCompra.cco_tasigv = recepcion.CCO_TASIGV;
    registroCompra.cco_impcor = recepcion.CCO_IMPCOR;
    registroCompra.cco_impina = recepcion.CCO_IMPINA;
    registroCompra.cco_impafe = recepcion.CCO_IMPAFE;
    registroCompra.cco_indtca = recepcion.CCO_INDTCA;
    registroCompra.cco_codpag = recepcion.CCO_CODPAG;
    registroCompra.mmo_codmmo = recepcion.MMO_CODMMO;
    registroCompra.tdo_docref = recepcion.TDO_DOCREF;
    registroCompra.cco_numref = recepcion.CCO_NUMREF;
    registroCompra.ano_codano = recepcion.ANO_CODANO;
    registroCompra.mes_codmes = recepcion.MES_CODMES;
    registroCompra.sco_codsco = recepcion.SCO_CODSCO;
    registroCompra.ldc_corldc = "0001";
    registroCompra.sco_codasi = '';
    registroCompra.ldc_corasi = '';
    registroCompra.age_codage = recepcion.AGE_CODAGE;
    registroCompra.cco_abotra = recepcion.CCO_ABOTRA;
    registroCompra.cco_indsta = recepcion.CCO_INDSTA;
    registroCompra.cco_coduse = recepcion.CCO_CODUSE;
    registroCompra.cco_codant = recepcion.CCO_CODANT;
    registroCompra.cco_impret = recepcion.CCO_IMPRET;
    registroCompra.cco_aboret = recepcion.CCO_ABORET;
    registroCompra.cco_retgas = recepcion.CCO_RETGAS;
    registroCompra.iri_codiri = recepcion.IRI_CODIRI;
    registroCompra.suc_codsuc = recepcion.SUC_CODSUC;
    registroCompra.tga_codtga = recepcion.TGA_CODTGA;
    registroCompra.cco_fecref = recepcion.CCO_FECREF;
    registroCompra.ocm_numocm = recepcion.OCM_NUMOCM;
    registroCompra.cco_numcuo = recepcion.cco_numcuo;
    registroCompra.ipi_codipi = recepcion.ipi_codipi;
    registroCompra.cco_impper = recepcion.CCO_IMPPER;
    registroCompra.cco_permna = recepcion.CCO_PERMNA;
    registroCompra.cco_movil = recepcion.cco_movil;
    registroCompra.cco_cbmoti = recepcion.cco_cbmoti;
    registroCompra.cco_condic = recepcion.cco_condic;
    registroCompra.cco_code = recepcion.cco_code;
    registroCompra.dpc_indder = recepcion.DPC_INDDER;
    registroCompra.dpc_fecrec = recepcion.DPC_FECREC;
    registroCompra.dpc_indsta = recepcion.CCO_INDSTA;
    registroCompra.dpc_coduse = recepcion.CCO_CODUSE;
    registroCompra.dpc_tasren = recepcion.DPC_TASREN;
    registroCompra.dpc_tasies = recepcion.DPC_TASIES;
    registroCompra.dpc_tasore = recepcion.DPC_TASORE;
    registroCompra.dpc_impren = recepcion.DPC_IMPREN;
    registroCompra.dpc_impies = recepcion.DPC_IMPIES;
    registroCompra.dpc_impore = recepcion.DPC_IMPORE;
    registroCompra.dpc_difigv = recepcion.DPC_DIFIGV;
    registroCompra.dpc_afcder = recepcion.DPC_AFCDER;
    registroCompra.dpc_afsder = recepcion.DPC_AFSDER;
    registroCompra.dpc_impisc = recepcion.DPC_IMPISC;
    registroCompra.dpc_igvisc = recepcion.DPC_IGVISC;
    registroCompra.dpc_tasisc = recepcion.DPC_TASISC;
    registroCompra.dpc_otrtri = recepcion.DPC_OTRTRI;
    registroCompra.dpc_valare = recepcion.DPC_VALARE;
    registroCompra.dpc_inddif = recepcion.DPC_INDDIF;
    registroCompra.dpc_perini = recepcion.DPC_PERINI;
    registroCompra.dpc_impdif = recepcion.DPC_IMPDIF;
    registroCompra.dpc_numpol = recepcion.DPC_NUMPOL;
    registroCompra.dpc_parara = recepcion.DPC_PARARA;
    registroCompra.dpc_impfob = recepcion.DPC_IMPFOB;
    registroCompra.dpc_impfle = recepcion.DPC_IMPFLE;
    registroCompra.dpc_impseg = recepcion.DPC_IMPSEG;
    registroCompra.dpc_impcif = recepcion.DPC_IMPCIF;
    registroCompra.dpc_impadv = recepcion.DPC_IMPADV;
    registroCompra.dpc_reipap = recepcion.DPC_REIPAP;
    registroCompra.dpc_afepol = recepcion.DPC_AFEPOL;
    registroCompra.dpc_inddet = recepcion.DPC_INDDET;
    registroCompra.dpc_inddet_c = recepcion.DPC_INDDET;
    registroCompra.tim_codtim = recepcion.TIM_CODTIM;
    registroCompra.dpc_tasdet = recepcion.DPC_TASDET;
    registroCompra.dpc_depdet = 0;
    registroCompra.dpc_impdet = recepcion.DPC_IMPDET;
    registroCompra.dpc_fecdet = recepcion.DPC_FECDET;
    registroCompra.dpc_indntc = recepcion.DPC_INDNTC;
    registroCompra.dpc_anotri = recepcion.DPC_ANOTRI;
    registroCompra.dpc_mestri = recepcion.DPC_MESTRI;
    registroCompra.dpc_impper = recepcion.DPC_IMPPER;
    registroCompra.dpc_numdua = recepcion.DPC_NUMDUA;
    registroCompra.dpc_anodua = recepcion.DPC_ANODUA;
    registroCompra.oim_numoim = recepcion.OIM_NUMOIM;
    registroCompra.dpc_impsis = recepcion.dpc_impsis;
    registroCompra.dpc_impfis = recepcion.dpc_impfis;
    registroCompra.t30_codt30 = recepcion.t30_codt30;
    registroCompra.t31_codt31 = recepcion.t31_codt31;
    registroCompra.valvta = recepcion.valvta;

    return registroCompra;

  }
}

export class DetailPurchaseRecord {
  cia_codcia: any;
  ano_codano: any;
  mes_codmes: any;
  sco_codsco: any;
  ccn_descri: any;
  ldc_corldc: any;
  ldi_corldi: any;
  ccn_codccn: string;
  cuenta: {ccn_descri: string};
  cli_codcli: string;
  cco_codcco: string;
  tmo_codtmo: any;
  ldi_inddha: any;
  ldi_tipcam: any;
  ldi_tcameu: any;
  ldi_impnac: any;
  ldi_impmex: any;
  ldi_indcco: any;
  tdo_codtdo: any;
  ldi_docref: any;
  tdo_docref: any;
  ldi_numref: any;
  ldi_gloldi: any;
  fpa_codfpa: any;
  ref_codref: any;
  ldi_inddes: any;
  ldi_fecemi: any;
  ldi_fecven: any;
  ccs_codccs: any;
  suc_codsuc: any;
  rec_numrec: any;
  vde_codvde: any;
  ldi_impsal: any;
  fca_codfca: any;
  ocm_numocm: any;
  ldi_indccl: any;
  ldi_anoccl: any;
  ldi_mesccl: any;
  isEditing: boolean;
  isSelected: boolean;
  focused: boolean;
  constructor(
  ){
    this.isEditing = true;
    this.isSelected = false;
    this.focused = false;

    this.tmo_codtmo = "SO";
    this.ldi_inddha = "D";
    this.tdo_codtdo = "FAC";
    this.ldi_impnac = 0;
    this.ldi_impmex = 0;
    this.ldi_tcameu = 0;
    this.ldi_inddes = 0;
    this.fca_codfca = "";

    this.ldi_impsal = 0;
    this.ldi_indccl = 0;
    this.ldi_indcco = "N";
    this.ldi_inddes = 0;
    this.ldi_mesccl = "";

    this.suc_codsuc = "01";
    this.tdo_docref = "";
    this.vde_codvde = "";

    this.cuenta = {ccn_descri: ''};
  }
}

export class OrdersPurchaseRecord {
  cia_codcia: any;
  ocm_numocm: any;
  cco_codcco: any;
  oim_numoim: any;
  occ_impdis: any;
  isEditing: any;
  constructor(
  ) {
    this.isEditing = true;
  }
}
