<app-pavso-title-section [title]="'Serie de documento'" [module]="'Ventas'" [estado]="documentSerie.sdo_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <select-search
            [label]="'Tipo de Documento'"
            [placeholder]="'Ej. Factura'"
            [name]="'tdo_codtdo'"
            [description]="'tdo_deslar'"
            [data]="tiposDocumento"
            (cambioSelect)="documentSerie.tdo_codtdo= $event"
            [value]="documentSerie.tdo_codtdo">
          </select-search>
        </div>
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Serie de Documento</mat-label>
            <input [(ngModel)]="documentSerie.sdo_codsdo" type="text" matInput placeholder="Serie de Documento" name="sdo_codsdo" required>
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Serie Inicial</mat-label>
            <input type="text" matInput placeholder="Serie Inicial" name="serie_inicial" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Serie Final</mat-label>
            <input type="text" matInput placeholder="Serie Final" name="serie_final" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Número de Item para el Formato de Impresión</mat-label>
            <input type="text" matInput placeholder="Número de Item para el Formato de Impresión" name="formato" required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Sucursal</mat-label>
            <mat-select required name="sucursal">
              <mat-option *ngFor="let sucursal of sucursales" value="sucursal.SUC_CODSUC">
                {{sucursal.suc_nomsuc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Lugar de Despacho Asociado</mat-label>
            <mat-select required name="despacho">
              <mat-option value="1">
                lugar de despacho 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Formato Electrónico</mat-label>
            <mat-select required name="despacho">
              <mat-option value="1">
                formato electronico
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <section class="pavso-cb-serie">
            <mat-checkbox [ngStyle]="{marginTop: '15px'}">Serie de Factura Guía</mat-checkbox>
          </section>
        </div>
      </div>
    </mat-card>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
        urlBack="/modulo-ventas/series-de-documento">
        <div id="botonesAdicionales">

        </div>
    </pav-form-actions>
  </form>
</div>
