import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";

@Component({
  selector: 'motivo-movimiento-list',
  templateUrl: './motivo-movimiento-list.component.html',
  styleUrls: ['./motivo-movimiento-list.component.scss']
})
export class MotivoMovimientoListComponent implements OnInit, OnDestroy {
  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'mmo_codmmo', 'mmo_descri', 'mmo_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  motivosMovimiento$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService

  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }
  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerMotivosMovimiento();
    })
  }

obtenerMotivosMovimiento(): void {
    this.loaderData = true;
    this.motivosMovimiento$ = this._ventasService.obtenerMotivosMovimiento().subscribe(
      response => {
        console.log('MotivosMovimiento', response)
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.motivosMovimiento$
    ]);
  }

}
