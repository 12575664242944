import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'producto-dialog',
  styleUrls: ['./producto-dialog.component.scss'],
  templateUrl: './producto-dialog.component.html'
})
export class ProductoDialog {
  constructor(
    public dialogRef: MatDialogRef<ProductoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data id', data.id)
  }
}
