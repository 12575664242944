<app-pavso-title-section [title]="'Configuración cierre'" [module]="'Contabilidad'"
></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Período</mat-label>
            <input type="text" matInput placeholder="Período" name="periodo" required>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="mat-elevation-z0">
      <mat-card class="mat-elevation-z0 pavso_card_more_options">
        Todas las configuraciones
        <button class="pavso_more_options" mat-icon-button [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="pavso_more_options" color="primary">
            <mat-icon>cloud_download</mat-icon>
            <span>Exportar a Excel</span>
          </button>

        </mat-menu>
      </mat-card>
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="cuenta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.cuenta}} </td>
        </ng-container>

        <ng-container matColumnDef="grupo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Grupo </th>
          <td mat-cell *matCellDef="let row" data-label="Grupo"> {{row.grupo}} </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción Cuenta Título </th>
          <td mat-cell *matCellDef="let row" data-label="Descripción Cuenta Título"> {{row.descripcion}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No se encontraron registros</td>
        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-contabilidad/configuraciones-asiento">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
