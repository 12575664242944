import { Component, OnDestroy, OnInit } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { cambiarLoadingModule } from "@shared/state/actions/loading.actions";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ModuleService } from "src/app/services";
import { cambiar } from "../../../shared/state/actions/period.actions";
import { codigoModulo } from "@data/constants/component/codigo-modulo";

@Component({
  selector: 'app-activo-fijo',
  templateUrl: './activo-fijo.component.html',
  styleUrls: ['./activo-fijo.component.scss']
})
export class ActivoFijoComponent implements OnInit, OnDestroy {

  codeModule: string = codigoModulo.activo_fijo_modules_key;
  module$: Subscription;

  year: string;
  month: string;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _moduleService: ModuleService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
    // this.codeModule = localStorage.getItem('codeModule');
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && state.isLoadingModule) this.establecerPeriodo();;
    })
  }

  establecerPeriodo(): void {
    if (this.codeModule != "00") {
      this.module$ = this._moduleService.obtenerPeriodoAbierto("01", this.codeModule).subscribe(
        response => {
          this.store.dispatch(cambiar({ year: response.ANO_CODANO, month: response.MES_CODMES, openingYear: response.ANO_CODANO, openingMonth: response.MES_CODMES, codMod: this.codeModule, periodos: response.periodos }));
          this.store.dispatch(cambiarLoadingModule({ isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: false, isLoadingTypeNominaDialog: false }))
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      );
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.module$, this.loading$]);
  }
}
