<mat-card class="mat-elevation-z0">
  <div class="row">
    <div class="col s12">
      <mat-form-field>
        <mat-label>Seleccionar un informe</mat-label>
        <mat-select
          (selectionChange)="seleccionarInforme($event.value)"
          [(ngModel)]="reporteSeleccionado"
          name="res_idres"
          placeholder="Informes"
          [disabled]="disabled"
          #informeSelect
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="informeFilterCtrl"
              placeholderLabel="ej. Informe de..."
              noEntriesFoundLabel="'No encotrado'"
            ></ngx-mat-select-search>
          </mat-option>

          <ng-container
          *ngFor="let informe of filteredInformes | async"
          >
            <mat-option

              [value]="informe.id"
            >
              <img *ngIf="!informe.esDinamico" src="https://www.freeiconspng.com/uploads/download-16x16-pdf-icon-png-3.png" alt="Excel" width="15">
              <img *ngIf="informe.esDinamico" src="assets/images/excel.png" width="18" alt="Excel">
              &nbsp;
              {{ informe.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-card>
<br>
