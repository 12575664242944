import { Component, OnDestroy, OnInit } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SelectClosePeriodComponent } from "@shared/components/dialogs/select-close-period/select-close-period.component";
import { SelectQueryPeriodComponent } from "@shared/components/dialogs/select-query-period/select-query-period.component";
import { SeletTipoNomina } from "@shared/components/dialogs/select-tipo-nomina/select-tipo-nomina";
import { DialogService } from "@shared/services/dialog.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
@Component({
  selector: 'side-controls',
  templateUrl: './side-controls.component.html',
  styleUrls: ['./side-controls.component.scss']
})
export class SideControlsComponent implements OnInit, OnDestroy{

  yearPeriodApertura: any;
  monthPeriodApertura: any;

  yearPeriodActividad: any;
  monthPeriodActividad: any;

  isNomina: boolean = false;
  nameTipoNomina: string;

  typeNomina: string;
  secNomina: string;
  nameTypeNomina: string;

  period$: Subscription;
  nomina$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _dialogService: DialogService
  ) {

    this.period$ = this.store.select('period').subscribe(({month, year, openingYear, openingMonth, codMod}) => {
      this.yearPeriodActividad = year;
      this.monthPeriodActividad = month;
      this.yearPeriodApertura = openingYear;
      this.monthPeriodApertura = openingMonth;
      this.isNomina = (codMod == 'W51')? true: false;
    });

    this.nomina$ = this.store.select('nomina').subscribe(state => {
      this.typeNomina = state.typeNomina;
      this.secNomina = state.secNomina;
      this.nameTipoNomina = state.nameTypeNomina;
    });
  }

  ngOnInit(): void {}

  consultarPeriodo(): void {

    this._dialogService.openDialog(SelectQueryPeriodComponent, {}, '400px', 'auto', '')

  }

  cerrarPeriodo(): void {

    this._dialogService.openDialog(SelectClosePeriodComponent, {}, '400px', 'auto', '');

  }

  /**
   * Seleccionar tipo de nómina
   */
  cambiarTipoNomina(): void {

    this._dialogService.openDialog(SeletTipoNomina, {}, '300px', '', '');

  }

  ngOnDestroy() {
    unsubscribeSubscription([this.period$, this.nomina$]);
  }
}
