export const REPORTES_VENTAS = [
  {
    id: "VENTAS_P",
    name: "Pedidos",
    esDinamico: false
  },
  {
    id: "VENTAS_DP",
    name: "Detallado de Pedidos",
    esDinamico: false
  },
  {
    id: "VENTAS_RA",
    name: "Resumen Anual (Estadístico general)",
    esDinamico: false
  },
  {
    id: "VENTAS_RP",
    name: "Resumen Períodico",
    esDinamico: false
  },
  {
    id: "VENTAS_CC",
    name: "Comprobantes por cliente",
    esDinamico: false
  },
  {
    id: "VENTAS_PDV",
    name: "Productos dejados de vender",
    esDinamico: false
  },
  {
    id: "VENTAS_RV",
    name: "Registro de Ventas",
    esDinamico: false
  },
  {
    id: "VENTAS_RDV",
    name: "Registro Detallado de Ventas",
    esDinamico: false
  },
];

export const REPORTES_VENTAS_DINAMICOS = [
  {
    id: "VENTAS_RV",
    name: "Registro de ventas",
    esDinamico: true,
    code: '003'
  },
  {
    id: "VENTAS_RDV",
    name: "Registro detallado de ventas",
    esDinamico: true,
    code: '002'
  },
  {
    id: "VENTAS_MC",
    name: "Maestro de clientes",
    esDinamico: true,
    code: '001'
  },
  {
    id: "VENTAS_PA",
    name: "Resumen Producto Anual",
    esDinamico: true,
    code: '004'
  },
  {
    id: "VENTAS_CA",
    name: "Resumen Cliente Anual",
    esDinamico: true,
    code: '005'
  },
]
