import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { cambiarLoadingModule } from "@shared/state/actions/loading.actions";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MenuService, SidebarService } from "src/app/services";

@Component({
  selector: 'search-module',
  templateUrl: './search-module.component.html',
  styleUrls: ['./search-module.component.scss']
})
export class SearchModuleComponent implements OnInit, OnDestroy{

  loaderData: boolean = false;

  myControl = new FormControl();
  options: any[];
  filteredOptions: Observable<string[]>;
  submenus: any[];
  modulos: any[];

  buttonName: INameConstant = NAMES_CONSTANTS;


  periodo$: Subscription;
  modulos$: Subscription;
  language$: Subscription;

  codMod: string;
  language: string;

  constructor(
    private _sideBarService: SidebarService,
    public dialogRef: MatDialogRef<SearchModuleComponent>,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<PavsoState>,
    private _menuService: MenuService,
    private _snackBarService: SnackBarService
  ) {
    // this.submenus = this._sideBarService.menuClient[1].submenus;
    // this.options = this._sideBarService.menuClient[1].submenus.map(item => item.title);

    this.periodo$ = this.store.select('period').subscribe(({codMod}) => {
      this.codMod = codMod;
    })

    this.language$ = this.store.select('language').subscribe(state => {
      this.language = state.language;

    })
  }

  ngOnInit(): void {

    this.obtenerModulos();

  }

  obtenerModulos(): void {
    this.loaderData = true;
    this.modulos$ = this._menuService.obtenerMenu(this.language).subscribe(
      menus => {
        console.log('menu', menus)
        this.modulos = menus.map(item => ({codeMod: item.codeMod, title: item.title}))

        this.submenus = this.modulos;
        this.options = this.modulos.map(item => item.title);

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  buscar(): void {
    console.log('buscar')
    const option = this.submenus.find(item => item.title.toLowerCase().trim() == this.myControl.value.toLowerCase());
    console.log('option', option)
    console.log('codMod', this.codMod)
    if(option.codeMod == 'W51') {
      if(this.codMod == 'W51') {
        this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: false, isLoadingTypeNominaDialog: false}));
        console.log('primero')
        return;
      }
      this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: true}));
      console.log('segundo')
      return;
    }

    this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: false}));

    this._router.navigate([`/${option.url}`]);
    this.dialogRef.close();

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.periodo$
    ])
  }
}
