import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_NOMINA_LIQUIDACIONES } from "@data/constants/table/theader.nomina";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { NominaService } from "src/app/services";

@Component({
  selector: 'app-listado-liquidaciones',
  templateUrl: './listado-liquidaciones.component.html',
  styleUrls: ['./listado-liquidaciones.component.scss']
})
export class ListadoLiquidacionesComponent implements OnInit, OnDestroy{

  year: string;
  month: string;

  period$: Subscription;
  loading$: Subscription;
  liquidaciones$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_LIQUIDACIONES;

  liquidaciones: Array<any> = [];

  constructor(
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.obtenerLiquidaciones();
    })
  }

  obtenerLiquidaciones(): void {
    this.liquidaciones$ = this._nominaService.obtenerLiquidaciones(this.year, this.month).subscribe(
      liquidaciones => this.liquidaciones = liquidaciones,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.liquidaciones$]);
  }

}
