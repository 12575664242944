import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root'
})
export class PurchaseOrderService {

    constructor(
        private http: HttpClient,
        private _configurationService: ConfigurationService
    ) {}

    /**
     *
     * @returns
     */
    obtenerTiposDocumento(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasordenpago/listatipodocum`);

    }

    /**
     *
     * @returns
     */
    obtenerCuentasContabilidad(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasordenpago/listacuentacon/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerCuentasBancarias(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasordenpago/listacuentabanc/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerClientes(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasordenpago/listacliente/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerVendedores(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasordenpago/listavendedores/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerTiposCambio(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasordenpago/listatipocambio/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerChequeBanco(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasordenpago/listachequebanc/${this._configurationService.obtenerCompaniaCliente()}`);

    }

}
