import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { LiquidacionBeneficio } from 'src/app/models/planilla/operaciones/liquidacion-beneficio';
import { ConfigurationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { generaPdfLiquidacionBeneficiosColaborador } from '@utils/pdfmaker/builder/planilla.pdfmaker';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { HeaderReport } from '@data/interfaces/headers.report';
import { HeaderReportService } from 'src/app/services/reports/config/header-report.service';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-liquidacion-beneficio-social',
  templateUrl: './liquidacion-beneficio-social.component.html',
  styleUrls: ['./liquidacion-beneficio-social.component.css']
})
export class LiquidacionBeneficioSocialComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  colaboradores: any[] = [];
  motivos: any[] = [];
  colaborador: any;

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['nco_codnco', 'nco_descri', 'npc_impnpc'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  year: string;
  month: string;

  estado: any;

  liquidacion: LiquidacionBeneficio;

  liquidacion$: Subscription;
  colaborador$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  informe$: Subscription;
  sendForm$: Subscription;
  motivos$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _nominaService: NominaService,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService,
    private _headerReportService: HeaderReportService
  ) {
    this.period$ = this.store.select('period').subscribe(
      state => {
        this.year = state.year;
        this.month = state.month;
      }
    )

    this.liquidacion = new LiquidacionBeneficio();
    this.liquidacion.codcia = this._configurationService.obtenerCompaniaCliente();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.listarMaestros();
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
        if(this.uid == "0") {
          this.liquidacion.codano = this.year;
          this.liquidacion.codmes = this.month;
          return;
        }
      }
    )
  }

  trackByColaborador(index, colaborador): string {
    return colaborador? colaborador.codcli: undefined;
  }

  trackByMotivo(index, motivo): string {
    return motivo? motivo.nmc_codnmc: undefined;
  }

  blurBasico(): void {
    this.liquidacion.rembas = Number(this.liquidacion.rembas).toFixed(2);
  }

  blurAsignacion(): void {
    this.liquidacion.asifam = Number(this.liquidacion.asifam).toFixed(2);
  }

  blurOtrasRem(): void {
    this.liquidacion.otrrem = Number(this.liquidacion.otrrem).toFixed(2);
  }

  blurPartUlt(): void {
    this.liquidacion.ultgra = Number(this.liquidacion.ultgra).toFixed(2);
  }

  obtenerLiquidacion(): void {
    this.liquidacion$ = this._nominaService.obtenerLiquidacion(this.uid).subscribe(
      (liquidacion) => {
        this.liquidacion = liquidacion[0];

        this.dataSource = fillTable(liquidacion[0].detalle, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError("Error al obtener liquidación", "OK");
      }
    )
  }

  /**
   * Obtiene los maestros de :
   * . colaboradores
   * . motivos de cese
   */
  listarMaestros(): void {
    forkObs(
      this._nominaService.listarColaboradores(),
      this._nominaService.listarMotivoCese()
    ).then(([colaboradores, motivos]) => {
      this.colaboradores = colaboradores;
      this.motivos = motivos;

      if (this.uid != '0') this.obtenerLiquidacion();
    }).catch(error => {
      this._snackBarService.showError('Error al obtener maestros', 'OK')
    })

  }

  enviarFormulario(f: NgForm): void {

    this.convertirImportesANumeros();

    this.loaderReg = true;
    this.uid == '0' ? this.registrarLiquidacionBeneficio(f): this.actualizarLiquidacionBeneficio(f);

  }

  convertirImportesANumeros(): void{
    this.liquidacion.rembas = Number(this.liquidacion.rembas);
    this.liquidacion.asifam = Number(this.liquidacion.asifam);
    this.liquidacion.otrrem = Number(this.liquidacion.otrrem);
    this.liquidacion.ultgra = Number(this.liquidacion.ultgra);
  }


  registrarLiquidacionBeneficio(f: NgForm): void {
    this.sendForm$ = this._nominaService.registrarLiquidacionBeneficioSocial(this.liquidacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Liquidación beneficio social registrado', '400px', '400px', '')
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError('Error al registrar liquidación de beneficio social', 'OK');
      }
    )
  }

  actualizarLiquidacionBeneficio(f: NgForm): void {
    this.sendForm$ = this._nominaService.actualizarLiquidacionBeneficioSocial(this.liquidacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Liquidación de beneficio social actualizado', '400px', '400px', 'Ok');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError('Error al actualizar liquidación de beneficio social', 'OK');
      }
    )
  }

  generaPdf(): void {
    const headers: HeaderReport = this._headerReportService.obtenerCabecera()
    this.informe$ = this._nominaService.obtenerInformeLiquidacionBeneficios(this.year, this.month, this.liquidacion.codcli).subscribe(
      response => generaPdfLiquidacionBeneficiosColaborador(headers, response),
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/liquidaciones-de-beneficios-sociales']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.liquidacion$,
      this.colaborador$,
      this.period$,
      this.loading$,
      this.informe$,
      this.sendForm$,
      this.motivos$]);
  }

}
