export const REPORTES_COMPRAS = [
  {
    id: "COMPRAS_RC",
    name: "Registros de compra",
    esDinamico: false,
  },
  {
    id: "COMPRAS_OC",
    name: "Órdenes de compra",
    esDinamico: false,
  },
  {
    id: "COMPRAS_PvsS",
    name: "Pedidos Vs Stock",
    esDinamico: false,
  },
];
