import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { CuentaPagarDialogComponent } from "@shared/components/dialogs/cuenta-por-pagar/cuenta-pagar-dialog/cuenta-pagar-dialog.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { addDaysToDate, formatDateWithDash } from "@utils/formats/date.format";
import { numberWithCommas } from "@utils/formats/number.format";
import { roundToDecimals } from "@utils/formats/round.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { DetailPurchaseRecord, RegistroCompra } from "src/app/models/compras/purchase_record";
import { DocumentoRecepcion, RecepcionCompra } from "src/app/models/compras/recepcion";
import { AlmacenService, ComprasService, ConfigurationService, ContabilidadService, VentasService } from "src/app/services";
import { ReceptionCompraService } from "src/app/services/api/compras/operaciones/reception.service";
import { OrdenCompraModalListComponent } from "../../../orden-compra";
import { OrdenCompraService } from "src/app/services/api/compras/operaciones/orden-compra.service";
import { ConfiguracionTecnica } from "src/app/models/contabilidad/configuracion-tecnica";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ConfiguracionContabilidad } from "src/app/models/contabilidad/configuracion-contabilidad";
import { BINARY_TYPE } from "@data/interfaces/binary.type";

@Component({
  selector: 'recepcion-compra-form',
  templateUrl: './recepcion-compra-form.component.html',
  styleUrls: ['./recepcion-compra-form.component.scss']
})
export class RecepcionFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() loader: boolean;
  @Input() recepcion: RecepcionCompra;
  @Input() maestros: { proveedores: any[], auxiliares: any[] }
  @Output() onVolverListadoRecepcion: EventEmitter<any>;

  selected: any;
  loaderReg: boolean;

  selectRow(row: any) {
    this.selected = row;
  }

  displayedColumns: string[] = ['ldi_corldi', 'ccn_codccn', 'cli_codcli', 'ccs_codccs', 'tdo_codtdo', 'ldi_docref', 'tmo_codtmo', 'ldi_tipcam', 'ldi_inddha', 'ldi_impnac', 'ldi_impmex'/*'debemn', 'habermn', 'debeme', 'haberme'*/, 'ldi_gloldi', 'cco_codcco'];
  dataSource: MatTableDataSource<DetailPurchaseRecord>;
  @ViewChild('paginator1') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsDocumentos: string[] = ['item', 'tipo_documento', 'comentario', 'adjuntar', 'visualizar'];
  dataSourceDocumentos: MatTableDataSource<DetailPurchaseRecord>;
  @ViewChild('paginatorDocumentos') paginatorDocumentos: MatPaginator;
  @ViewChild(MatSort) sortDocumentos: MatSort;

  displayedColumns3: string[] = ['name', 'mn', 'me'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;

  recepcionCompra: RecepcionCompra;
  loaderData: boolean;

  cuentas: any[] = [];
  subdiarios: any[] = [];
  proveedores: any[] = [];
  auxiliares: any[] = [];
  ordenesCompra: any[] = [];
  tiposOperacion: any[] = [];
  tiposDocumento: any[] = [];
  tiposDocumentoRef: any[] = [];
  condiciones: any[] = [];
  tiposBienServicio: any[] = [];
  tiposRenta: any[] = [];
  tiposRetencion: any[] = [];
  tempTiposImpRetencion: any[] = [];
  tiposImpRetencion: any[] = [];
  signosctacte: any[] = [];
  centros: any[] = [];
  tiposOperacionRetencion: any[] = [];
  ordenesGenerica: any[] = [];
  configuracionTecnica: ConfiguracionTecnica;
  configuracionContabilidad: ConfiguracionContabilidad = new ConfiguracionContabilidad();

  disableImportaciones: boolean;
  disableAnoDua: boolean;
  esPercepcion: boolean;

  tipoCambio$: Subscription;
  tipoOperacion$: Subscription;
  sendForm$: Subscription;

  tituloTasa: string = "Retención";

  montosDebeHaber: any;

  year: string;
  month: string;

  minDate: Date;
  maxDate: Date;

  isMobile: boolean = false;
  isTablet: boolean = false;

  indDocReferencia: BINARY_TYPE = 0;

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _almacenService: AlmacenService,
    private _ordenCompraService: OrdenCompraService,
    private _comprasService: ComprasService,
    private _dialogService: DialogService,
    private _contabilidadService: ContabilidadService,
    private _recepcionService: ReceptionCompraService,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver
  ) {

    this.recepcionCompra = new RecepcionCompra();
    this.onVolverListadoRecepcion = new EventEmitter();
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.configuracionTecnica = new ConfiguracionTecnica();

    this.montosDebeHaber = [
      {
        name: "Debe",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Haber",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Diferencia",
        mn: 0.00,
        me: 0.00
      }
    ];

    this.dataSource3 = fillTable(this.montosDebeHaber, this.paginator, this.sort);
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        console.log('Is Mobile:', this.isMobile);
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        // this.isMobile = result.matches;
        console.log('result => ', result)
        this.isTablet = result.matches;
      });
  }

  ngOnInit(): void {
    this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
      this.recepcionCompra.ANO_CODANO = this.year;
      this.recepcionCompra.MES_CODMES = this.month;

      this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
      this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

      this.recepcionCompra.CCO_FECREG = this.maxDate;
      this.recepcionCompra.CCO_FECVEN = this.maxDate;
      this.recepcionCompra.CCO_FECEMI = this.maxDate;

      console.log('formulario de recepcion')
    })
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.recepcion) {

      // Comprobar si es tipo retencion o no
      this.recepcionCompra = changes.recepcion.currentValue;
      this.recepcionCompra.documentos = [];

      console.log('recepcion compra changes', changes.recepcion.currentValue)
      if (!this.recepcionCompra.detalle) this.recepcionCompra.detalle = [];

      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort)

      this.consultasPrincipales(new Date(this.recepcionCompra.CCO_FECEMI))

      // Determinar el indicador de referencia;
      let tipo = this.tiposDocumento.find(item => item.TDO_CODTDO == this.recepcionCompra.TDO_CODTDO);
      this.indDocReferencia = tipo.tdo_indref;
    };

    if (changes.maestros) {
      this.proveedores = changes.maestros.currentValue.proveedores
      this.auxiliares = changes.maestros.currentValue.auxiliares;
      this.tiposDocumento = changes.maestros.currentValue.tiposDocumento;
      this.tiposDocumentoRef = changes.maestros.currentValue.tiposDocumentoRef;
      this.condiciones = changes.maestros.currentValue.condicionesPago;
      this.subdiarios = changes.maestros.currentValue.subdiarios;
      if (this.subdiarios.length == 1) {
        this.recepcionCompra.SCO_CODSCO = this.subdiarios[0].sco_codsco;
      }
      this.ordenesCompra = changes.maestros.currentValue.ordenes;
      this.tiposOperacion = changes.maestros.currentValue.tiposOperacion;
      this.tiposBienServicio = changes.maestros.currentValue.tiposBienServicio;
      this.centros = changes.maestros.currentValue.centros;
      this.cuentas = changes.maestros.currentValue.cuentas;
      this.signosctacte = changes.maestros.currentValue.signosCtaCte;
      this.tiposRetencion = changes.maestros.currentValue.tiposRetencion;
      this.tiposOperacionRetencion = changes.maestros.currentValue.tiposOperacionRetencion;
      this.ordenesGenerica = changes.maestros.currentValue.ordenes;
      this.configuracionTecnica = changes.maestros.currentValue.configuracionTecnica;
      this.configuracionContabilidad = changes.maestros.currentValue.configuracionContabilidad;
      // this.
      console.log('CUENTAS', this.cuentas)
      console.log('configuracionTecnica', this.configuracionTecnica)
    };

  }

  obtnerOrdenesPorCliente(cliente): void {
    this._ordenCompraService.obtenerOrdenesCompraPorCliente(cliente).subscribe(
      ordenes => {
        console.log('ordenes cliente', ordenes)
        console.log('ordenes genericos', this.ordenesGenerica)
        this.ordenesCompra = [...this.ordenesGenerica, ...ordenes];
        console.log('ordenes totales antes', this.ordenesCompra)
        this.ordenesCompra = this.ordenesCompra.filter(item => (item.ocm_indsta == '1' && item.ocm_aprcom == 1 && item.ocm_indcie == 0));
        console.log('ordenes totales', this.ordenesCompra)
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  consultasPrincipales(fecha: Date): void {

    this._comprasService.obtenerTiposImpuestoRetencionPorFechaEmision(fecha).subscribe(
      response => {
        this.tiposImpRetencion = [];
        this.tempTiposImpRetencion = response;

        console.log('tiposImpuestoRetencion', response);

        this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.recepcionCompra.CCO_FECEMI)).subscribe(
          tipoCambio => {
            console.log('tipo de cambio en recepcion', tipoCambio)
            this.recepcionCompra.CCO_TIPCAM = tipoCambio[0].tipcam;
            if (this.recepcionCompra.CCO_TIPCAM == 0) {
              this.recepcionCompra.CCO_TIPCAM = 3.8;
            }
            this._ordenCompraService.obtenerOrdenesCompraPorCliente(this.recepcionCompra.CLI_CODCLI).subscribe(
              ordenes => {
                console.log('ordenes cliente', ordenes)
                console.log('ordenes genericos', this.ordenesGenerica)
                this.ordenesCompra = [...this.ordenesGenerica, ...ordenes];
                console.log('ordenes totales antes', this.ordenesCompra)
                this.ordenesCompra = this.ordenesCompra.filter(item => (item.ocm_indsta == '1' && item.ocm_aprcom == 1 && item.ocm_indcie == 0));
                console.log('ordenes totales', this.ordenesCompra);

                if (this.recepcionCompra.SCO_CODSCO) {
                  this.tipoOperacion$ = this._comprasService.obtenerUltimoRegistroTipoOperacion(this.recepcionCompra.CLI_CODCLI, this.recepcionCompra.SCO_CODSCO).subscribe(
                    tipoOperacion => {
                      if (!(Object.keys(tipoOperacion).length === 0)) {
                        this.recepcionCompra.TGA_CODTGA = tipoOperacion.tga_codtga;
                      }

                    }, error => {
                      this._snackBarService.showError(error.error.msg, 'Ok');

                    }
                  )

                }
              },
              error => {
                // this._snackBarService.showError(error.error.msg, 'Ok')
              }
            )
          },
          error => {
            // this._snackBarService.showError(error.error.msg, 'Ok')
          }
        )
      },
      error => {
        // this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  volver(): void {
    this.onVolverListadoRecepcion.emit(this.recepcionCompra);
  }

  seleccionarSubdiario(event): void {

    const subdiario = this.subdiarios.find(item => item.sco_codsco == event);

    this.recepcionCompra.CCN_CODCCN = this.recepcionCompra.TMO_CODTMO == 'SO' ? subdiario.ccn_codmn.trim() : subdiario.ccn_codme.trim();


    this.tipoOperacion$ = this._comprasService.obtenerUltimoRegistroTipoOperacion(this.recepcionCompra.CLI_CODCLI, this.recepcionCompra.SCO_CODSCO).subscribe(
      tipoOperacion => {
        if (!(Object.keys(tipoOperacion).length === 0)) {
          this.recepcionCompra.TGA_CODTGA = tipoOperacion.tga_codtga;
        }

      }, error => {
        this._snackBarService.showError(error.error.msg, 'Ok');

      }
    )
  }

  cambiarFechaEmision(event): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(event.value._d)).subscribe(
      response => {
        this.recepcionCompra.CCO_TIPCAM = response[0].tipcam;

      },
    )
  }

  establecerEmision(): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.recepcionCompra.CCO_FECEMI)).subscribe(
      response => this.recepcionCompra.CCO_TIPCAM = response[0].tipcam
    )
  }

  focusInput(evento): void {
    evento.target.select();
  }

  seleccionarProveedor(): void {
    this.recepcionCompra.CLI_CODDOC = this.recepcionCompra.CLI_CODCLI;
  }

  seleccionarMoneda(event): void {

    const subdiario = this.subdiarios.find(item => item.sco_codsco == this.recepcionCompra.SCO_CODSCO);

    this.recepcionCompra.CCN_CODCCN = this.recepcionCompra.TMO_CODTMO == 'SO' ? subdiario.ccn_codmn : subdiario.ccn_codme;

  }

  calculaTotal(): void {
    this.recepcionCompra.valvta = (this.recepcionCompra.CCO_IMPAFE ? this.recepcionCompra.CCO_IMPAFE : 0) +
      (this.recepcionCompra.CCO_IMPINA ? this.recepcionCompra.CCO_IMPINA : 0) +
      (this.recepcionCompra.DPC_IMPADV ? this.recepcionCompra.DPC_IMPADV : 0);

    this.recepcionCompra.DPC_IMPCIF = (this.recepcionCompra.DPC_IMPFOB ? this.recepcionCompra.DPC_IMPFOB : 0) +
      (this.recepcionCompra.DPC_IMPFLE ? this.recepcionCompra.DPC_IMPFLE : 0) +
      (this.recepcionCompra.DPC_IMPSEG ? this.recepcionCompra.DPC_IMPSEG : 0);

    console.log('this.recepcionCompra.DPC_IMPPER', (this.recepcionCompra.DPC_IMPPER ? this.recepcionCompra.DPC_IMPPER : 0))
    this.recepcionCompra.CCO_IMPDOC = (this.recepcionCompra.CCO_IMPAFE ? this.recepcionCompra.CCO_IMPAFE : 0) +
      (this.recepcionCompra.CCO_IMPINA ? this.recepcionCompra.CCO_IMPINA : 0) +
      (this.recepcionCompra.CCO_IMPIGV ? this.recepcionCompra.CCO_IMPIGV : 0) -
      (this.recepcionCompra.DPC_IMPREN ? this.recepcionCompra.DPC_IMPREN : 0) +
      (this.recepcionCompra.DPC_IMPPER ? this.recepcionCompra.DPC_IMPPER : 0) +
      (this.recepcionCompra.dpc_impfis ? this.recepcionCompra.dpc_impfis : 0) +
      (this.recepcionCompra.dpc_impsis ? this.recepcionCompra.dpc_impsis : 0);

    // const ln_importe = roundToDecimals(((this.recepcionCompra.CCO_IMPDOC - this.recepcionCompra.CCO_IMPINA) * this.recepcionCompra.DPC_TASDET) / 100, 2);
    // this.recepcionCompra.DPC_IMPDET = (this.recepcionCompra.TMO_CODTMO == "SO") ? ln_importe : roundToDecimals(ln_importe * this.recepcionCompra.CCO_TIPCAM, 1);
    this.recepcionCompra.CCO_IMPIGV = roundToDecimals((this.recepcionCompra.CCO_IMPAFE * (this.recepcionCompra.CCO_TASIGV / 100) + this.recepcionCompra.DPC_DIFIGV), 2)
    console.log('recepcionCompra.CCO_IMPIGV', this.recepcionCompra.CCO_IMPIGV)
    console.log({ cco_impina: this.recepcionCompra.CCO_IMPINA, cco_impafe: this.recepcionCompra.CCO_IMPAFE, cco_impigv: this.recepcionCompra.CCO_IMPIGV })

    this.recepcionCompra.CCO_IMPDOC = (this.recepcionCompra.CCO_IMPINA ? this.recepcionCompra.CCO_IMPINA : 0) +
      (this.recepcionCompra.CCO_IMPAFE ? this.recepcionCompra.CCO_IMPAFE : 0) +
      (this.recepcionCompra.CCO_IMPIGV ? this.recepcionCompra.CCO_IMPIGV : 0) +
      (this.recepcionCompra.DPC_IMPREN ? this.recepcionCompra.DPC_IMPREN : 0) +
      (this.recepcionCompra.DPC_IMPPER ? this.recepcionCompra.DPC_IMPPER : 0) +
      (this.recepcionCompra.dpc_impfis ? this.recepcionCompra.dpc_impfis : 0) +
      (this.recepcionCompra.dpc_impsis ? this.recepcionCompra.dpc_impsis : 0);
  }

  establecerAfecto(): void {
    this.recepcionCompra.valvta = this.recepcionCompra.CCO_IMPINA + this.recepcionCompra.CCO_IMPAFE;

    this.calculaTotal();
  }

  seleccionarTipoOperacion(): void {

    console.log('tipoOperacion', this.recepcionCompra.TGA_CODTGA)

    const tipoOpe = this.tiposOperacion.find(item => item.tga_codtga == this.recepcionCompra.TGA_CODTGA);

    console.log('tipoOpe', tipoOpe)
    if (tipoOpe) {

      if (tipoOpe && tipoOpe.ccn_codmna && tipoOpe.ccn_codmex && tipoOpe.ccn_codmna != "" && tipoOpe.ccn_codmex != "") {

        (this.recepcionCompra.TMO_CODTMO == "SO") ? this.recepcionCompra.CCN_CODCCN = tipoOpe.ccn_codmna : this.recepcionCompra.CCN_CODCCN = tipoOpe.ccn_codmex;
      } else {
        let subdiario = this.subdiarios.find(item => item.sco_codsco == this.recepcionCompra.SCO_CODSCO)
        if (subdiario && subdiario.ccn_codmn != "" && subdiario.ccn_codme != "" && subdiario.ccn_codme && subdiario.ccn_codmn) {
          (this.recepcionCompra.TMO_CODTMO == "SO") ? this.recepcionCompra.CCN_CODCCN = subdiario.ccn_codmn : this.recepcionCompra.CCN_CODCCN = subdiario.ccn_codme;
        }
      }

    }

    if (tipoOpe) {
      this.recepcionCompra.t30_codt30 = tipoOpe["T30_CODT30"];

      console.log('this.recepcionCompra.t30_codt30', this.recepcionCompra.t30_codt30)
      console.log('this.recepcionCompra.TIM_CODTIM', this.recepcionCompra.TIM_CODTIM)
    }

    this.recepcionCompra.t30_codt30 = '5';

    if (this.recepcionCompra.CCO_IMPDOC >= 700) {
      //TODO: REALIZAR EL QUERY
      // this.recepcionCompra.IRI_CODIRI = '01';

      if (this.recepcionCompra.IRI_CODIRI != "02") {

        this._contabilidadService.obtenerObligacionesTributarias().subscribe(
          response => {
            console.log('obligaciones tributarias', response)
            if (this.recepcionCompra['reencion'] == "SI" && this.recepcionCompra.CCO_IMPDOC >= response[0].mont_det) {
              this.recepcionCompra.IRI_CODIRI = "02"
            }
            if (this.recepcionCompra.CCO_IMPDOC >= response[0].mont_det && this.recepcionCompra['reencion'] != "SI") {
              this.recepcionCompra.IRI_CODIRI = "01";
              this.recepcionCompra.DPC_TASDET = response[0].porc_det;
              this.recepcionCompra.DPC_IMPDET = parseInt((this.recepcionCompra.DPC_TASDET * this.recepcionCompra.CCO_IMPDOC / 100).toFixed(0));
              this.recepcionCompra.TIM_CODTIM = tipoOpe["tim_codtim"];
            }
          }
        )

      }
    }

  }

  seleccionarTipoDoc(): void {
    let tipo = this.tiposDocumento.find(item => item.TDO_CODTDO == this.recepcionCompra.TDO_CODTDO);
    this.indDocReferencia = tipo.tdo_indref;

    // Restablecer a null valores del tipo doc referencia
    if (this.indDocReferencia == 0) {
      this.recepcionCompra.TDO_DOCREF = null;
      this.recepcionCompra.CCO_NUMREF = null;
      this.recepcionCompra.CCO_FECREF = null;
    }

    if (this.recepcionCompra.TDO_CODTDO) {
      console.log('ingresaste', this.recepcionCompra.TDO_CODTDO)


      console.log('tipo de documento seleccionado', tipo);

      (tipo.tdo_indext == 1) ? this.habilitarImportaciones() : this.desabilitarImportaciones();
      (this.recepcionCompra.TDO_CODTDO == "DUA") ? this.disableAnoDua = false : this.disableAnoDua = true;
      console.log('disableAnoDua', this.disableAnoDua)
      this._almacenService.listarSerieDocumentos(this.recepcionCompra.TDO_CODTDO).subscribe(
        series => {
          console.log('series', series)
          if (series && series.length > 0) {
            this.recepcionCompra.CCO_NUMDOC = `${series[0].sdo_codsdo}`;

            this.recepcionCompra.detalle.forEach(item => item.ldi_docref = this.recepcionCompra.CCO_NUMDOC)

            this.recepcionCompra.sad_codsad = series[0].sdo_codsdo;

          }

        },
        error => this._snackBarService.showError(error.error.msg, "OK")

      )
    }

    this.recepcionCompra.detalle.forEach(item => item.tdo_codtdo = this.recepcionCompra.TDO_CODTDO);

  }

  seleccionarTipoDocRef(): void {

    if (this.recepcionCompra.TDO_CODTDO == this.recepcionCompra.TDO_DOCREF) {
      this.recepcionCompra.TDO_DOCREF = null;
      this._snackBarService.showError('Seleccionar un documento diferente al tipo de documento', 'Ok');
      return
    };

    this._almacenService.listarSerieDocumentos(this.recepcionCompra.TDO_DOCREF).subscribe(
      series => {
        this.recepcionCompra.CCO_NUMREF = series[0].sdo_codsdo;

      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )

  }

  autocompletarNroDocRef(): void {

    if (this.recepcionCompra.CCO_NUMREF.length <= 4) {
      this._snackBarService.showError('Escribir los 4 primeros digitos', 'Ok');
      return;
    }

    const completado = this.completarDigitosNumDoc(this.recepcionCompra.CCO_NUMREF.slice(4));

    this.recepcionCompra.CCO_NUMREF = this.recepcionCompra.CCO_NUMREF.substring(0, 4) + completado;


    // Establecer fecha de referencia
    this._comprasService.listarRegistrosDeCompraPeriodo(this.year, this.month);

  }

  completarDigitosNumDoc(numdoc: string): string {
    if (numdoc.length < 8) {
      const digitosFaltantes = 8 - numdoc.length;

      for (let index = 0; index < digitosFaltantes; index++) {
        numdoc = '0' + numdoc;
      }

      return numdoc;
    }

    return numdoc;
  }

  desabilitarImportaciones(): void {
    this.recepcionCompra.DPC_IMPFOB = 0;
    this.recepcionCompra.DPC_IMPFLE = 0;
    this.recepcionCompra.DPC_IMPSEG = 0;
    this.recepcionCompra.DPC_IMPCIF = 0;
    this.recepcionCompra.DPC_IMPADV = 0;

    this.disableImportaciones = true;
  }

  habilitarImportaciones(): void {
    this.recepcionCompra.DPC_IMPFOB = 0;
    this.recepcionCompra.DPC_IMPFLE = 0;
    this.recepcionCompra.DPC_IMPSEG = 0;
    this.recepcionCompra.DPC_IMPCIF = 0;
    this.recepcionCompra.DPC_IMPADV = 0;

    this.disableImportaciones = false;
  }

  establecerSerie(): void {

    this.recepcionCompra.CCO_NUMDOC = this.recepcionCompra.sad_codsad + this.recepcionCompra.CCO_NUMDOC.substring(this.recepcionCompra.sad_codsad.length + 1, this.recepcionCompra.CCO_NUMDOC.length + 1);

  }

  autocompletarNroDoc(): void {
    this.recepcionCompra.detalle.forEach(item => {
      item.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
    })
  }

  handleOnUploadFile(row: DocumentoRecepcion, event): void {
    row.ccd_fotccd = event.base64;
    row.ccd_tiparc = event.type;
  }

  openFile(fileBase64, fileType): void {
    const byteCharacters = atob(fileBase64.split('base64,')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');

    // const url = `data:application/octet-stream;base64,${base64String}`;
    // console.log('fileBase64', fileBase64)
    // window.open(fileBase64, '_blank');
  }

  seleccionarCondicionPago(event): void {
    const condicion = this.condiciones.find(item => item.CPA_CODCPA == this.recepcionCompra.CPA_CODCPA);

    if (condicion) {
      this.recepcionCompra.CCO_FECVEN = addDaysToDate(new Date(this.recepcionCompra.CCO_FECEMI), condicion.CPA_DIAPLA)
    }

  }

  establecerInafecto(): void {

    this.recepcionCompra.valvta = this.recepcionCompra.CCO_IMPINA + this.recepcionCompra.CCO_IMPAFE;

    this.calculaTotal();
  }

  validacionesOrdenCompra(): boolean {

    if (!this.recepcionCompra.OCM_NUMOCM) {
      this._snackBarService.showError('Seleccionar una orden de compra', 'Ok');
      return false;
    }

    const ordenCompra = this.ordenesCompra.find(item => item.ocm_numocm == this.recepcionCompra.OCM_NUMOCM);
    console.log('orden compra encontrado', ordenCompra)

    if (ordenCompra.ocm_aprcom == 0) {
      this._snackBarService.showError('La orden de compra no está Aprobada. Coordine con el responsable para su Aprobación', 'Ok');
      return false;
    }

    if ((ordenCompra.ocm_numocm[0] != 'E') && (ordenCompra.ocm_indgen != 1)) {
      if (this.recepcionCompra.TMO_CODTMO != ordenCompra.tmo_codtmo) {
        this._snackBarService.showError('La moneda de la Orden Compra es diferente al del Comprobante', 'Ok');
        return false;
      }
    }

    if (
      (ordenCompra.cli_codcli != this.recepcionCompra.CLI_CODCLI) &&
      (ordenCompra.ocm_indgen != 1)
    ) {
      if (ordenCompra.ocm_numocm[0] != 'E') {
        this._snackBarService.showError('El proveedor es diferente a la de la orden de compra', 'Ok');
        return false;
      }
    }

    if ((this.recepcionCompra.TDO_CODTDO == 'NCR') || (this.recepcionCompra.TDO_CODTDO == 'NDB')) {
      if (!this.recepcionCompra.TDO_DOCREF || !this.recepcionCompra.CCO_NUMREF) {
        this._snackBarService.showError('Tiene que ingresar el documento y número de referencia', 'Ok');
        return false;
      }
    }

    if ((this.recepcionCompra.CCO_IMPINA > 0) && (this.recepcionCompra.CCO_IMPAFE > 0)) {
      if ((this.recepcionCompra.CCO_IMPIGV == 0) && (this.recepcionCompra.IRI_CODIRI == '02')) {
        this._snackBarService.showError('El comprobante no puede ser RETENCION por que el igv es inafecto!', 'Ok');
        return false;
      }

      if ((this.recepcionCompra.CCO_IMPIGV == 0) && (this.recepcionCompra.IRI_CODIRI == '01')) {
        this._snackBarService.showError('El comprobante no puede ser DETRACCION por que el igv es inafecto!', 'Ok');
        return false;
      }
    }

    return true;

  }

  cambiarTipoRetencion(tipo): void {

    console.log('tipo ', tipo)
    if (tipo.value == "03") {
      this.esPercepcion = true;
      this.tiposImpRetencion = this.tempTiposImpRetencion.filter(item => item.codigo == "PER")
      this.tituloTasa = "Percepción";
      this.recepcionCompra.DPC_TASDET = 0;
      this.recepcionCompra.DPC_IMPDET = 0;
      this.recepcionCompra.DPC_IMPPER = 0;
      // this.recepcionCompra.DPC_DEPDET = 0;
      this.recepcionCompra.DPC_FECDET = new Date();
      this.recepcionCompra.TIM_CODTIM = "PER";
      this.calculaTotal();

      const tipoImpuesto = this.tiposImpRetencion.find(item => this.recepcionCompra.TIM_CODTIM == item.codigo);
      this.recepcionCompra.DPC_TASDET = tipoImpuesto.porc_det;
      this.recepcionCompra.DPC_IMPPER = parseFloat((this.recepcionCompra.CCO_IMPDOC * this.recepcionCompra.DPC_TASDET / 100).toFixed(2));

      this.calculaTotal();
    } else if (tipo.value == "00") {

      this.recepcionCompra.TIM_CODTIM = "NA";
      this.recepcionCompra.DPC_TASDET = 0;
      this.recepcionCompra.DPC_IMPDET = 0;
      this.recepcionCompra.DPC_IMPPER = 0;
      // this.recepcionCompra.DPC_DEPDET = 0;
      this.recepcionCompra.DPC_FECDET = new Date();
      this.tiposImpRetencion = [];
      this.calculaTotal();
    } else if (tipo.value == "02") {
      this.esPercepcion = false;
      this.tituloTasa = "Retención";
      this.tiposImpRetencion = this.tempTiposImpRetencion.filter(item => item.codigo == "RET")
      this.recepcionCompra.DPC_TASDET = 0;
      this.recepcionCompra.DPC_IMPDET = 0;
      this.recepcionCompra.DPC_IMPPER = 0;
      // this.recepcionCompra.DPC_DEPDET = 0;
      this.recepcionCompra.DPC_FECDET = new Date();
      this.recepcionCompra.TIM_CODTIM = "RET";
      this.calculaTotal();

      const tipoImpuesto = this.tiposImpRetencion.find(item => this.recepcionCompra.TIM_CODTIM == item.codigo);
      this.recepcionCompra.DPC_TASDET = tipoImpuesto.porc_det;
      this.recepcionCompra.DPC_IMPPER = (this.recepcionCompra.CCO_IMPDOC * this.recepcionCompra.DPC_TASDET / 100);
      this.calculaTotal();
    } else {
      this.esPercepcion = false;
      this.tiposImpRetencion = this.tempTiposImpRetencion;

      this.tituloTasa = "Detracción";
      this.recepcionCompra.DPC_TASDET = 0;
      this.recepcionCompra.DPC_IMPDET = 0;
      this.recepcionCompra.DPC_IMPPER = 0;
      this.recepcionCompra.TIM_CODTIM = null;
      // this.recepcionCompra.DPC_DEPDET = 0;
      this.recepcionCompra.DPC_FECDET = new Date();
      this.calculaTotal();
    }
  }

  cambiarTipoImpuestoRetencion(): void {
    if ((this.recepcionCompra.CCO_IMPAFE + this.recepcionCompra.CCO_IMPINA) > 0) {
      const tipoImpuesto = this.tiposImpRetencion.find(item => this.recepcionCompra.TIM_CODTIM == item.codigo);
      this.recepcionCompra.DPC_TASDET = tipoImpuesto.porc_det;
      if (this.recepcionCompra.TMO_CODTMO == 'DO') {
        this.recepcionCompra.DPC_IMPDET = ((this.recepcionCompra.CCO_IMPDOC * this.recepcionCompra.CCO_TIPCAM) * this.recepcionCompra.DPC_TASDET / 100);
        return;
      }
      this.recepcionCompra.DPC_IMPDET = (this.recepcionCompra.CCO_IMPDOC * this.recepcionCompra.DPC_TASDET / 100);
      return;
    }

    // this._snackBarService.showError('El afecto tiene que ser mayor que 0', 'Ok');
  }

  generarAsiento(): void {
    if (!this.validacionesOrdenCompra()) {
      return;
    }

    if (!this.recepcionCompra.CCO_TIPCAM || this.recepcion.CCO_TIPCAM == 0) {
      this._snackBarService.showError("Establecer un Tipo de Cambio", "OK");
      return;
    }

    if (!this.recepcionCompra.SCO_CODSCO) {
      this._snackBarService.showError("Seleccionar un subdiario", "OK");

      return;
    }

    if (!this.recepcionCompra.CCN_CODCCN) {
      this._snackBarService.showError("Seleccionar una Cuenta", "OK");

      return;
    }

    if (!this.recepcionCompra.TGA_CODTGA) {
      this._snackBarService.showError("Seleccionar un tipo de operación", "OK");

      return;
    }

    if (!this.recepcionCompra.OCM_NUMOCM) {
      this._snackBarService.showError("Seleccionar una Orden de Compra", "OK");

      return;
    }

    if (this.recepcionCompra.detalle.length > 0) {
      this._snackBarService.showError("Es necesario que la tabla se encuentre vacía.", "OK");
      return;
    }

    // let [ps_cia, ps_suc, ps_sco, ps_codtga, ps_cliente] = [this._configurationService.obtenerCompaniaCliente(), "01", this.purchaseRecord.sco_codsco, this.purchaseRecord.tga_codtga, this.purchaseRecord.cli_codcli];

    // Verificar percepcion

    let li_perigv = 0;

    const tipoDoc = this.tiposDocumento.find(item => item.TDO_CODTDO == this.recepcionCompra.TDO_CODTDO);

    if (!this.recepcionCompra.TDO_CODTDO) {
      this._snackBarService.showError("Establecer un Tipo de Documento", "OK")
      return;
    }

    li_perigv = tipoDoc.TDO_PERIGV;

    if (this.recepcionCompra.CCO_CODCCO) {
      this.recepcionCompra.detalle.forEach(item => {
        if (item.tdo_codtdo == 'DPP' && item.cco_codcco) {
          this._snackBarService.showError("No se puede generar asiento, tiene cuenta corriente de Detracción ...Modifique manualmente.", "OK");
          return;
        }
      });
    }

    if (!this.recepcionCompra.TGA_CODTGA) {
      this._snackBarService.showError("Es necesario ingresar el tipo de operación", "OK");
      return;
    }

    this.recepcionCompra.detalle = [];

    if (!this.recepcionCompra.SCO_CODSCO) {
      this._snackBarService.showError("Ingresar el subdiario contable", "OK");
      return;
    }

    let tipoOp = this.tiposOperacion.find(element => element.tga_codtga == this.recepcionCompra.TGA_CODTGA);
    console.log('this.tiposOperacion', this.tiposOperacion)
    console.log('tipoOp', tipoOp)

    if (tipoOp) {
      if (!tipoOp.detalle) {
        this._snackBarService.showError("Advertencia : No existe detalle de configuración de asiento automático para la transacción " + this.recepcionCompra.TGA_CODTGA, "Ok");
        return;
      }
    }

    let ps_inddom;

    if (!this.recepcionCompra.CLI_CODCLI) {
      this._snackBarService.showError("Error: No existe el proveedor del documento", "OK");
      return;
    }

    console.log('proveedores', this.proveedores)
    const prov = this.proveedores.find(element => element.cli_codcli == this.recepcionCompra.CLI_CODCLI);

    if (prov) ps_inddom = prov.cli_inddom;

    // Si la cuenta contable de provisión está configurada propiamente para la transaccion
    let ps_ccn_codmn, ps_ccn_codme;

    let tipoOpe = this.tiposOperacion.find(element => element.tga_codtga == this.recepcionCompra.TGA_CODTGA)

    const subdiario = this.subdiarios.find(element => element.sco_codsco == this.recepcionCompra.SCO_CODSCO)

    if (tipoOpe.ccn_codmna && tipoOpe.ccn_codmex) {
      ps_ccn_codmn = tipoOpe.ccn_codmna;
      ps_ccn_codme = tipoOpe.ccn_codmex;
    } else {
      ps_ccn_codmn = subdiario.ccn_codmn;
      ps_ccn_codme = subdiario.ccn_codme;
    }

    if (this.recepcionCompra.CCN_CODCCN) {
      const cuenta = this.cuentas.find(element => element.cuenta == this.recepcionCompra.CCN_CODCCN)

      if (this.recepcionCompra.TMO_CODTMO != cuenta.moneda) {
        this._snackBarService.showError("Error: Moneda de Cuenta por Pagar no corresponde Moneda de la Cuenta Contable " + this.recepcionCompra.CCN_CODCCN, "OK");
        return;
      }

      if (this.recepcionCompra.TMO_CODTMO == "SO") {
        ps_ccn_codmn = this.recepcionCompra.CCN_CODCCN;
      } else {
        ps_ccn_codme = this.recepcionCompra.CCN_CODCCN;
      }

    } else {
      if (this.recepcionCompra.TMO_CODTMO == "SO") {
        this.recepcionCompra.CCN_CODCCN = ps_ccn_codmn;
      } else {
        this.recepcionCompra.CCN_CODCCN = ps_ccn_codme;
      }
    }

    // ** Valida cuenta contable de provision
    if (this.recepcionCompra.TMO_CODTMO == "SO" && !ps_ccn_codmn) {
      this._snackBarService.showError(`Error: No existe la cuenta contable de registro de provisión
        Verifique la cuentas contables asignadas para el Subdiario ${this.recepcionCompra.SCO_CODSCO}`, "OK");
      return;
    }

    if (this.recepcionCompra.TMO_CODTMO == "DO" && !ps_ccn_codme) {
      this._snackBarService.showError(`Error: No existe la cuenta contable de registro de provisión.
        "Verifique la cuentas contables asignadas para el Subdiario ${this.recepcionCompra.SCO_CODSCO}`, "OK");
      return;
    }

    // * por regularizar
    let ps_igvsde = '401121' //&&tmpval.cct_igvsde
    let ps_igvndo = '401121' //&&tmpval.cct_igvndo
    let ls_ndoapl = '401121' //&&tmpval.cct_ndoapl
    let ls_ndoret = '401141' //&&tmpval.cct_ndoret
    let ls_perigv = '401117' //&&tmpval.cct_perigv

    // *** Contabiliza cuenta 42
    let ps_cuenta, pn_impnac, pn_impmex;
    let ps_tmo_mn = "SO";

    if (this.recepcionCompra.TMO_CODTMO == ps_tmo_mn) {
      ps_cuenta = ps_ccn_codmn
      pn_impnac = this.recepcionCompra.CCO_IMPDOC;
      pn_impmex = pn_impnac / this.recepcionCompra.CCO_TIPCAM;
    } else {
      ps_cuenta = ps_ccn_codme
      pn_impmex = this.recepcionCompra.CCO_IMPDOC;
      pn_impnac = pn_impmex * this.recepcionCompra.CCO_TIPCAM;
    }

    // *--- Valida documento
    let ps_valor = ps_cuenta.substring(0, 1);
    let ps_codtdo = this.recepcionCompra.TDO_CODTDO;

    console.log('signosctacte', this.signosctacte)

    const signo = this.signosctacte.find(item => item.tdo_codtdo == this.recepcionCompra.TDO_CODTDO && item.cla_codcla == ps_valor);

    if (!signo) {
      this._snackBarService.showError(`Error: Código de Documento ${ps_codtdo} no esta relacionado con la Clase Contable ${ps_valor}, cuenta ${ps_cuenta}`, "OK");
      return;
    }

    let li_item = 1;
    ps_cuenta = this.recepcionCompra.CCN_CODCCN;

    if (ps_cuenta) {

      ps_valor = this.recepcionCompra.CCO_CODCCO;
      let itemDetalle = new DetailPurchaseRecord();
      itemDetalle.isSelected = false;
      itemDetalle.ldi_corldi = '0000' + li_item;
      itemDetalle.ccn_codccn = ps_cuenta.trim();
      // itemDetalle.ccs_codccs = null;
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
      itemDetalle.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
      itemDetalle.cli_codcli = this.recepcionCompra.CLI_CODCLI;
      itemDetalle.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'H' : 'D';
      itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle.ldi_indcco = 'N';

      console.log('ITEM DETALLE', itemDetalle)
      this.recepcionCompra.detalle.push(itemDetalle);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);

    }

    //  *** Contabiliza cuenta 40 de Igv
    if (li_perigv != 1) {

      ps_cuenta = (tipoOpe.ccn_impmna) ? tipoOpe.ccn_impmna : subdiario.ccn_codimp;

    } else {
      ps_cuenta = li_perigv;
    }

    console.log('ps_cuenta cuenta 40 igv', ps_cuenta)

    let ps_indder = this.recepcionCompra.DPC_INDDER;

    if (ps_cuenta && (ps_indder == '1' || ps_indder == '3') && this.recepcionCompra.CCO_IMPIGV > 0) {
      if (this.recepcionCompra.TMO_CODTMO == ps_tmo_mn) {
        if (ps_indder == '1') {
          pn_impnac = this.recepcionCompra.CCO_IMPIGV;
        } else {
          pn_impnac = this.recepcionCompra.CCO_IMPIGV * this.recepcionCompra.CCO_TASIGV / 100;
        }
        pn_impmex = pn_impnac / this.recepcionCompra.CCO_TIPCAM
      } else {
        if (ps_indder == '1') {
          pn_impmex = this.recepcionCompra.CCO_IMPIGV;
        } else {
          pn_impmex = this.recepcionCompra.CCO_IMPIGV * this.recepcionCompra.CCO_TASIGV / 100;
        }
        pn_impnac = pn_impmex * this.recepcionCompra.CCO_TIPCAM

      }

      console.log('pn_impnac', pn_impnac)
      console.log('pn_impmex', pn_impmex)

      li_item++;
      ps_valor = this.recepcionCompra.CCO_CODCCO;
      let itemDetalle2 = new DetailPurchaseRecord();
      itemDetalle2.isSelected = false;
      itemDetalle2.ldi_corldi = `0000${li_item}`;
      itemDetalle2.ccn_codccn = ps_cuenta.trim();
      // itemDetalle2.ccs_codccs = null;
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());

      itemDetalle2.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle2.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle2.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle2.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle2.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
      itemDetalle2.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle2.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle2.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle2.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle2.ldi_indcco = 'N';
      console.log('ITEM DETALLE 2', itemDetalle2)
      this.recepcionCompra.detalle.push(itemDetalle2);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    }

    // *** Contabiliza cuenta de Igv sin derecho
    ps_cuenta = ps_igvsde

    if (ps_cuenta && (ps_indder == '2' || ps_indder == '3') && this.recepcionCompra.CCO_IMPIGV > 0) {
      if (this.recepcionCompra.TMO_CODTMO == ps_tmo_mn) {
        if (ps_indder == '2') {
          pn_impnac = this.recepcionCompra.CCO_IMPIGV;
        } else {
          pn_impnac = this.recepcionCompra.CCO_IMPIGV - (this.recepcionCompra.DPC_AFCDER * this.recepcionCompra.CCO_TASIGV / 100); // redondesar a 2
        }
        pn_impmex = pn_impnac / this.recepcionCompra.CCO_TIPCAM

      } else {
        if (ps_indder == '2') {
          pn_impmex = this.recepcionCompra.CCO_IMPIGV;
        } else {
          pn_impmex = this.recepcionCompra.CCO_IMPIGV - (this.recepcionCompra.DPC_AFCDER * this.recepcionCompra.CCO_TASIGV / 100); // redondesar a 2
        }
        pn_impnac = pn_impmex * this.recepcionCompra.CCO_TIPCAM
      }

      li_item++;
      ps_valor = this.recepcionCompra.CCO_CODCCO;
      let itemDetalle3 = new DetailPurchaseRecord();
      itemDetalle3.isSelected = false;
      itemDetalle3.ldi_corldi = `0000${li_item}`;
      itemDetalle3.ccn_codccn = ps_cuenta.trim();
      // itemDetalle3.ccs_codccs = null;
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
      itemDetalle3.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle3.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle3.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle3.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle3.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
      itemDetalle3.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle3.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle3.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle3.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle3.ldi_indcco = 'N';
      console.log('ITEM DETALLE 3', itemDetalle3)
      this.recepcionCompra.detalle.push(itemDetalle3);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    }

    let valCompra = this.recepcionCompra.CCO_IMPINA + this.recepcionCompra.CCO_IMPAFE;
    // *** Contabiliza cuenta 40 de Igv Para no domiciliados
    ps_cuenta = ps_igvndo;
    if (this.recepcionCompra.TMO_CODTMO == ps_tmo_mn) {
      pn_impnac = valCompra * this.recepcionCompra.CCO_TASIGV / 100
      pn_impmex = pn_impnac / this.recepcionCompra.CCO_TIPCAM
    } else {
      pn_impmex = valCompra * this.recepcionCompra.CCO_TASIGV / 100
      pn_impnac = pn_impmex * this.recepcionCompra.CCO_TIPCAM
    }

    if (ps_cuenta && ps_inddom == 0 && (pn_impnac > 0 || pn_impmex > 0) && ps_indder == '4') {
      li_item++;

      ps_valor = this.recepcionCompra.CCO_CODCCO;
      let itemDetalle4 = new DetailPurchaseRecord();

      itemDetalle4.isSelected = false;
      itemDetalle4.ldi_corldi = `0000${li_item}`;
      itemDetalle4.ccn_codccn = ps_cuenta.trim();
      // itemDetalle4.ccs_codccs = null;
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
      itemDetalle4.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle4.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle4.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle4.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle4.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
      itemDetalle4.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle4.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle4.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle4.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle4.ldi_indcco = 'N';

      console.log('ITEM DETALLE 4', itemDetalle4)

      this.recepcionCompra.detalle.push(itemDetalle4);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);

    }

    // *** 2.-Contabiliza cuenta 401120 de Igv Por no domiciliados
    ps_cuenta = ls_ndoapl;
    if (this.recepcionCompra.TMO_CODTMO == ps_tmo_mn) {
      pn_impnac = valCompra * this.recepcionCompra.CCO_TASIGV / 100;
      pn_impmex = pn_impnac / this.recepcionCompra.CCO_TIPCAM;
    } else {
      pn_impmex = valCompra * this.recepcionCompra.CCO_TASIGV / 100;
      pn_impnac = pn_impmex * this.recepcionCompra.CCO_TIPCAM;
    }

    if (ps_cuenta && ps_inddom == 0 && (pn_impnac > 0 || pn_impmex > 0) && ps_indder == '4') {
      li_item++;
      ps_valor = this.recepcionCompra.CCO_CODCCO;
      let itemDetalle5 = new DetailPurchaseRecord();

      itemDetalle5.isSelected = false;
      itemDetalle5.ldi_corldi = `0000${li_item}`;
      itemDetalle5.ccn_codccn = ps_cuenta.trim();
      itemDetalle5.ccn_codccn = '';
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
      itemDetalle5.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle5.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle5.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle5.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle5.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
      itemDetalle5.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle5.ldi_inddha = (signo.sig_indsig == -1) ? 'H' : 'D';
      itemDetalle5.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle5.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle5.ldi_indcco = 'N';

      console.log('ITEM DETALLE 5', itemDetalle5)

      this.recepcionCompra.detalle.push(itemDetalle5);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    }

    // *** Contabiliza cuenta 40 de Renta

    ps_cuenta == '40172001';

    if (this.recepcionCompra.TMO_CODTMO == ps_tmo_mn) {
      pn_impnac = this.recepcionCompra.DPC_IMPREN;
      pn_impmex = pn_impnac / this.recepcionCompra.CCO_TIPCAM;
    } else {
      pn_impmex = this.recepcionCompra.DPC_IMPREN;
      pn_impnac = pn_impmex * this.recepcionCompra.CCO_TIPCAM;
    }

    if (ps_cuenta && this.recepcionCompra.DPC_IMPREN > 0) {
      li_item++;
      ps_valor = this.recepcionCompra.CCO_CODCCO;

      let itemDetalle6 = new DetailPurchaseRecord();

      itemDetalle6.isSelected = false;
      itemDetalle6.ldi_corldi = `0000${li_item}`;
      itemDetalle6.ccn_codccn = ps_cuenta.trim();
      // itemDetalle6.ccs_codccs = null;
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
      itemDetalle6.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle6.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle6.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle6.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle6.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
      itemDetalle6.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle6.ldi_inddha = (signo.sig_indsig == -1) ? 'H' : 'D';
      itemDetalle6.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle6.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle6.ldi_indcco = 'N'

      console.log('ITEM DETALLE 6', itemDetalle6)

      this.recepcionCompra.detalle.push(itemDetalle6);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    }

    let ps_codccs, pn_porcen;

    // *** Contabiliza cuenta Gasto
    let pn_valvta = valCompra;
    if (pn_valvta != 0) {
      if (tipoOpe.detalle) {
        tipoOpe.detalle.forEach(item => {
          ps_cuenta = item.ccn_codccn;
          ps_codccs = item.ccs_codccs;
          pn_porcen = (item.aau_poraau) ? item.aau_poraau : 0;

          if (this.recepcionCompra.TMO_CODTMO == ps_tmo_mn) {
            pn_impnac = pn_valvta * pn_porcen / 100;
            pn_impmex = pn_impnac / this.recepcionCompra.CCO_TIPCAM;
          } else {
            pn_impmex = pn_valvta * pn_porcen / 100;
            pn_impnac = pn_impmex * this.recepcionCompra.CCO_TIPCAM;
          }

          if (ps_cuenta) {
            li_item++;
            let itemDetalle7 = new DetailPurchaseRecord();

            itemDetalle7.isSelected = false;
            itemDetalle7.ldi_corldi = `0000${li_item}`;
            itemDetalle7.ccn_codccn = ps_cuenta.trim();
            // itemDetalle7.ccs_codccs = ps_codccs;
            const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
            itemDetalle7.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
            itemDetalle7.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
            itemDetalle7.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
            itemDetalle7.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
            itemDetalle7.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
            itemDetalle7.cli_codcli = this.recepcionCompra.CLI_CODCLI;
            itemDetalle7.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
            itemDetalle7.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
            itemDetalle7.ldi_impnac = roundToDecimals(pn_impnac, 2);
            itemDetalle7.ldi_impmex = roundToDecimals(pn_impmex, 2);
            itemDetalle7.ldi_indcco = 'N';
            console.log('ITEM DETALLE 7', itemDetalle7)
            this.recepcionCompra.detalle.push(itemDetalle7);
            this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
          }
        });
      }
    }

    // ***************DETRACCION*****************
    // ***Contabilidad cuenta 42 de Detracción***

    if (this.recepcionCompra.DPC_IMPDET > 0 && this.recepcionCompra.IRI_CODIRI == '01') {
      li_item++;
      ps_cuenta = this.recepcionCompra.CCN_CODCCN;
      pn_impnac = this.recepcionCompra.DPC_IMPDET;
      pn_impmex = this.recepcionCompra.DPC_IMPDET / this.recepcionCompra.CCO_TIPCAM;

      if (ps_cuenta.length > 0 && ps_cuenta) {
        ps_valor = this.recepcionCompra.CCO_CODCCO;

        let itemDetalle8 = new DetailPurchaseRecord();

        itemDetalle8.isSelected = false;
        itemDetalle8.cco_codcco = ps_valor;
        itemDetalle8.ldi_corldi = `0000${li_item}`;
        itemDetalle8.ccn_codccn = ps_cuenta.trim();
        // itemDetalle8.ccs_codccs = null;
        const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
        itemDetalle8.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
        itemDetalle8.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
        itemDetalle8.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
        itemDetalle8.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
        itemDetalle8.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
        itemDetalle8.cli_codcli = this.recepcionCompra.CLI_CODCLI;
        itemDetalle8.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
        itemDetalle8.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
        itemDetalle8.ldi_impnac = roundToDecimals(pn_impnac, 0);
        itemDetalle8.ldi_impmex = roundToDecimals(pn_impmex, 0);
        itemDetalle8.ldi_indcco = 'N';
        console.log('ITEM DETALLE 8', itemDetalle8)
        this.recepcionCompra.detalle.push(itemDetalle8);
        this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
      }

      if (subdiario.ccn_coddet && this.recepcionCompra.DPC_IMPDET > 0) {
        li_item++;
        ps_cuenta = subdiario.ccn_coddet;

        let itemDetalle9 = new DetailPurchaseRecord();

        itemDetalle9.isSelected = false;
        itemDetalle9.ldi_corldi = `0000${li_item}`;
        itemDetalle9.ccn_codccn = ps_cuenta.trim();
        const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
        itemDetalle9.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
        // itemDetalle9.ccs_codccs = null;
        itemDetalle9.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
        itemDetalle9.tdo_codtdo = 'DPP';
        itemDetalle9.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
        itemDetalle9.tmo_codtmo = 'SO';
        itemDetalle9.cli_codcli = this.recepcionCompra.CLI_CODCLI;
        itemDetalle9.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
        itemDetalle9.ldi_inddha = 'H';
        itemDetalle9.ldi_impnac = roundToDecimals(pn_impnac, 0);
        itemDetalle9.ldi_impmex = roundToDecimals(pn_impmex, 0);
        itemDetalle9.ldi_indcco = 'N';
        console.log('ITEM DETALLE 9', itemDetalle9)
        this.recepcionCompra.detalle.push(itemDetalle9);
        this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
      }

    }


    // if (this.recepcionCompra.DPC_IMPDET > 0 && this.recepcionCompra.TIM_CODTIM != 'PER') {
    //   li_item++;
    //   ps_cuenta = this.recepcionCompra.CCN_CODCCN;
    //   pn_impnac = this.recepcionCompra.DPC_IMPDET;
    //   pn_impmex = this.recepcionCompra.DPC_IMPDET / this.recepcionCompra.CCO_TIPCAM;

    //   if (ps_cuenta) {
    //     ps_valor = this.recepcionCompra.CCO_CODCCO;

    //     let itemDetalle10 = new DetailPurchaseRecord();

    //     itemDetalle10.isSelected = false;
    //     itemDetalle10.cco_codcco = ps_valor;
    //     itemDetalle10.ldi_corldi = `0000${li_item}`;
    //     itemDetalle10.ccn_codccn = ps_cuenta.trim();
    //     const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
    //     itemDetalle10.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
    //     // itemDetalle10.ccs_codccs = null;
    //     itemDetalle10.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
    //     itemDetalle10.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
    //     itemDetalle10.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
    //     itemDetalle10.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
    //     itemDetalle10.cli_codcli = this.recepcionCompra.CLI_CODCLI;
    //     itemDetalle10.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
    //     itemDetalle10.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
    //     itemDetalle10.ldi_impnac = roundToDecimals(pn_impnac, 2);
    //     itemDetalle10.ldi_impmex = roundToDecimals(pn_impmex, 2);
    //     itemDetalle10.ldi_indcco = 'N';
    //     console.log('ITEM DETALLE 10', itemDetalle10)
    //     this.recepcionCompra.detalle.push(itemDetalle10);
    //     this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    //   }

    //   console.log('this.recepcionCompra.DPC_IMPDET ========', this.recepcionCompra.DPC_IMPDET)
    //   if (subdiario.ccn_coddet && this.recepcionCompra.DPC_IMPDET > 0) {
    //     li_item++;
    //     ps_cuenta = subdiario.ccn_coddet;

    //     let itemDetalle11 = new DetailPurchaseRecord();

    //     itemDetalle11.isSelected = false;
    //     itemDetalle11.ldi_corldi = `0000${li_item}`;
    //     itemDetalle11.ccn_codccn = ps_cuenta.trim();
    //     const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
    //     itemDetalle11.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
    //     itemDetalle11.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
    //     itemDetalle11.tdo_codtdo = 'DPP';
    //     itemDetalle11.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
    //     itemDetalle11.tmo_codtmo = 'SO';
    //     itemDetalle11.cli_codcli = this.recepcionCompra.CLI_CODCLI;
    //     itemDetalle11.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
    //     itemDetalle11.ldi_inddha = 'H';
    //     itemDetalle11.ldi_impnac = roundToDecimals(pn_impnac, 2);
    //     itemDetalle11.ldi_impmex = roundToDecimals(pn_impmex, 2);
    //     itemDetalle11.ldi_indcco = 'N';
    //     console.log('ITEM DETALLE 11', itemDetalle11)
    //     this.recepcionCompra.detalle.push(itemDetalle11);
    //     this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    //   }

    // }

    //       ************************************************

    // *!*         ***************PERCEPCION*****************
    // *!*         *** Contabilidad cuenta 40 de Percepción***

    let ls_cuenta = this.configuracionContabilidad.ccn_permna;

    if (this.recepcionCompra.IRI_CODIRI == '02') {
      ls_cuenta = this.configuracionContabilidad.ccn_retmna;
    }
    if (this.recepcionCompra.IRI_CODIRI == '03') {
      ls_cuenta = this.configuracionContabilidad.ccn_permna;
    }

    if (this.recepcionCompra.TMO_CODTMO == ps_tmo_mn) {
      pn_impnac = this.recepcionCompra.DPC_IMPPER;
      pn_impmex = pn_impnac / this.recepcionCompra.CCO_TIPCAM;
    } else {
      pn_impmex = this.recepcionCompra.DPC_IMPPER;
      pn_impnac = pn_impmex * this.recepcionCompra.CCO_TIPCAM;
    }

    if (ls_cuenta && this.recepcionCompra.DPC_IMPPER > 0) {
      li_item++;
      ps_valor = this.recepcionCompra.CCO_CODCCO;

      let itemDetalle12 = new DetailPurchaseRecord();

      itemDetalle12.isSelected = false;
      itemDetalle12.ldi_corldi = `0000${li_item}`;
      itemDetalle12.ccn_codccn = ls_cuenta.trim();
      itemDetalle12.cco_codcco = '';
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
      itemDetalle12.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle12.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle12.cli_codcli = this.recepcionCompra.CLI_CODCLI;
      itemDetalle12.tmo_codtmo = 'SO';//this.recepcionCompra.TMO_CODTMO;
      itemDetalle12.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle12.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle12.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle12.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle12.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle12.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle12.ldi_indcco = 'N';

      console.log('ITEM DETALLE 12', itemDetalle12)

      this.recepcionCompra.detalle.push(itemDetalle12);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    }

    if (this.recepcionCompra.dpc_impsis != 0) {
      li_item++;
      let itemDetalle13 = new DetailPurchaseRecord();

      itemDetalle13.isSelected = false;
      itemDetalle13.ldi_corldi = `0000${li_item}`;
      itemDetalle13.ccn_codccn = '64190003';
      itemDetalle13.cco_codcco = '';
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
      itemDetalle13.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle13.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle13.cli_codcli = this.recepcionCompra.CLI_CODCLI;
      itemDetalle13.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
      itemDetalle13.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle13.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle13.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle13.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle13.ldi_impnac = (this.recepcionCompra.TMO_CODTMO == 'SO') ? roundToDecimals(this.recepcionCompra.dpc_impsis, 2) : roundToDecimals(this.recepcionCompra.dpc_impsis * this.recepcionCompra.CCO_TIPCAM, 2);
      itemDetalle13.ldi_impmex = (this.recepcionCompra.TMO_CODTMO == 'SO') ? roundToDecimals(this.recepcionCompra.dpc_impsis / this.recepcionCompra.CCO_TIPCAM, 2) : roundToDecimals(this.recepcionCompra.dpc_impsis, 2);
      itemDetalle13.ldi_indcco = 'N';
      console.log('ITEM DETALLE 13', itemDetalle13)

      this.recepcionCompra.detalle.push(itemDetalle13);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    }

    if (this.recepcionCompra.dpc_impfis != 0) {
      li_item++;
      let itemDetalle14 = new DetailPurchaseRecord();

      itemDetalle14.isSelected = false;
      itemDetalle14.ldi_corldi = `0000${li_item}`;
      itemDetalle14.ccn_codccn = '64190002';
      itemDetalle14.cco_codcco = '';
      const cuenta = this.cuentas.find(item => item.cuenta.trim() == ps_cuenta.trim());
      itemDetalle14.ccs_codccs = cuenta && (cuenta.ind_cencos == 1 ? this.recepcionCompra.CCS_CODCCS : null);
      itemDetalle14.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
      itemDetalle14.cli_codcli = this.recepcionCompra.CLI_CODCLI;
      itemDetalle14.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
      itemDetalle14.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
      itemDetalle14.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle14.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
      itemDetalle14.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
      itemDetalle14.ldi_impnac = (this.recepcionCompra.TMO_CODTMO == 'SO') ? roundToDecimals(this.recepcionCompra.dpc_impfis, 2) : roundToDecimals(this.recepcionCompra.dpc_impfis * this.recepcionCompra.CCO_TIPCAM, 2);
      itemDetalle14.ldi_impmex = (this.recepcionCompra.TMO_CODTMO == 'SO') ? roundToDecimals(this.recepcionCompra.dpc_impfis / this.recepcionCompra.CCO_TIPCAM, 2) : roundToDecimals(this.recepcionCompra.dpc_impfis, 2);
      itemDetalle14.ldi_indcco = 'N';
      console.log('ITEM DETALLE 14', itemDetalle14)

      this.recepcionCompra.detalle.push(itemDetalle14);
      this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
    }

    this.establecerDebeHaber();
    this.ajusteDiferencia();
    // if (this.registro.detalle.length > 0) {
    //   setTimeout(() => {
    //     this.selectCosto.first.open();
    //     this.selectCosto.first.focus();
    //   }, 1);
    // }

  }

  establecerDebeHaber(): void {

    this.montosDebeHaber[0].mn = 0;
    this.montosDebeHaber[0].me = 0;

    this.montosDebeHaber[1].mn = 0;
    this.montosDebeHaber[1].me = 0;

    this.montosDebeHaber[2].mn = 0;
    this.montosDebeHaber[2].me = 0;

    this.recepcionCompra.detalle.forEach(element => {
      if (element.ldi_inddha == "D") {
        this.montosDebeHaber[0].mn += Number(element.ldi_impnac);
        this.montosDebeHaber[0].me += Number(element.ldi_impmex);
      } else {
        this.montosDebeHaber[1].mn += Number(element.ldi_impnac);
        this.montosDebeHaber[1].me += Number(element.ldi_impmex);
      }
    });

    this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
    this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

  }

  ajusteDiferencia(): void {
    const diferencia_mn = this.dataSource3.data[2].mn;
    const diferencia_me = this.dataSource3.data[2].me;

    console.table({ diferencia_mn, diferencia_me })

    if (diferencia_mn == 0 && diferencia_me == 0) {
      return;
    }

    if (this.recepcionCompra.detalle.length == 0) {
      this._snackBarService.showError("Agregar items en el detalle", "OK");
      return;
    }

    if (!((Math.abs(diferencia_mn) <= 0.05 && Math.abs(diferencia_mn) > 0) || (Math.abs(diferencia_me) <= 0.05 && Math.abs(diferencia_me) > 0))) {
      this._snackBarService.showError("La diferencia tiene que ser > 0 y <= a 0.05", "OK");
      return;
    }

    let ps_tmo_mn;
    let pn_impnac;
    let pn_impmex;
    let ps_codtdo;
    let ps_codccs;
    let ps_cuenta;
    let ls_cad;

    ps_tmo_mn = this.recepcionCompra.TMO_CODTMO;

    pn_impnac = Math.abs(diferencia_mn);
    pn_impmex = Math.abs(diferencia_me);

    ps_codtdo = (diferencia_mn < 0 || diferencia_me < 0) ? "AJP" : "AJG";
    ps_codccs = "";

    this._contabilidadService.configuraTipoDocCtaContable(ps_codtdo).subscribe(
      response => {
        console.log('response', response)
        ps_cuenta = response[0].ccn_codmn;
        ps_codccs = response[0].ccs_codccs;

        if (ps_cuenta) {

          let detailItem = new DetailPurchaseRecord();

          detailItem.ldi_inddha = (diferencia_mn < 0 || diferencia_me < 0) ? "D" : "H";
          detailItem.ldi_tcameu = 0;
          detailItem.ldi_inddes = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.ldi_indcco = "N";
          detailItem.ldi_inddes = 0;
          detailItem.ldi_mesccl = "";
          detailItem.sco_codsco = this.recepcionCompra.SCO_CODSCO;
          detailItem.suc_codsuc = "01";
          detailItem.tdo_docref = "";
          detailItem.vde_codvde = "";

          detailItem.ccn_codccn = ps_cuenta.trim();
          detailItem.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
          detailItem.tmo_codtmo = ps_tmo_mn;
          detailItem.tdo_codtdo = ps_codtdo;
          detailItem.ldi_docref = this.recepcionCompra.CCO_NUMDOC;
          detailItem.ldi_tipcam = 0;
          detailItem.ldi_inddha = (ps_codtdo == "AJP") ? "D" : "H";
          detailItem.ldi_impnac = roundToDecimals(pn_impnac, 2);
          detailItem.ldi_impmex = roundToDecimals(pn_impmex, 2);
          detailItem.ldi_indcco = "N";

          if (ps_codtdo == "AJP" && ps_codccs > 0) {
            detailItem.ccs_codccs = ps_codccs;
          }

          this.recepcionCompra.detalle.push(detailItem);

          this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);

          this.recepcionCompra.detalle.forEach((element, key) => {
            element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
          })

          // setTimeout(() => {
          //   this.inputDescripcion.last.nativeElement.focus();
          //   this.inputDescripcion.last.nativeElement.slect();
          // }, 1);

          this.montosDebeHaber[0].mn = 0;
          this.montosDebeHaber[0].me = 0;
          this.montosDebeHaber[1].mn = 0;
          this.montosDebeHaber[1].me = 0;
          this.montosDebeHaber[2].mn = 0;
          this.montosDebeHaber[2].me = 0;

          this.recepcionCompra.detalle.forEach(element => {
            if (element.ldi_inddha == "D") {
              this.montosDebeHaber[0].mn += element.ldi_impnac;
              this.montosDebeHaber[0].me += element.ldi_impmex;
            } else {
              this.montosDebeHaber[1].mn += element.ldi_impnac;
              this.montosDebeHaber[1].me += element.ldi_impmex;
            }
          });

          this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
          this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

        }
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )

  }

  borrarAsientosDestino(): void {
    console.log('borrar destino')
    let detalleNuevo = [];
    this.recepcionCompra.detalle.forEach(item => {
      if (item.ldi_corldi[0] != '6' && item.ldi_corldi[0] != '7') {
        detalleNuevo.push(item);
      }
    });

    this.recepcionCompra.detalle = detalleNuevo;
    this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);
  }

  documentosPorPagar(): void {
    const dialogRef = this.dialog.open(CuentaPagarDialogComponent, {
      width: '1000px',
      data: {
        cli_codcli: (this.recepcionCompra.CLI_CODCLI) ? this.recepcionCompra.CLI_CODCLI : '',
        periodo: `${this.year}${this.month}`,
        clientes: this.proveedores,
        tipo_cambio: this.recepcionCompra.CCO_TIPCAM
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        result.forEach(item => {
          let detailItem = new DetailPurchaseRecord();

          detailItem.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          detailItem.ano_codano = this.year;
          detailItem.mes_codmes = this.month;
          detailItem.ldi_corldi = `0000${this.recepcionCompra.detalle.length + 1}`;
          detailItem.tmo_codtmo = item.moneda;
          detailItem.ldi_inddha = "D";
          detailItem.tdo_codtdo = item.tip_doc;
          detailItem.ldi_impnac = (item.moneda == "SO") ? item.importe_saldo : item.importe_saldo / item.tipo_cambio;
          detailItem.ldi_impmex = (item.moneda == "SO") ? item.importe_saldo * item.tipo_cambio : item.importe_saldo;
          detailItem.ldi_tcameu = 0;
          detailItem.ldi_inddes = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.ldi_fecemi = item.fec_emision;
          detailItem.ldi_fecven = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.ldi_indcco = "N";
          detailItem.ldi_inddes = 0;
          detailItem.ldi_mesccl = "";
          detailItem.sco_codsco = item.subdiario;
          detailItem.suc_codsuc = "01";
          detailItem.tdo_docref = "";
          detailItem.vde_codvde = "";
          detailItem.cco_codcco = item.cta_cte;
          detailItem.ccn_codccn = item.cuenta;
          detailItem.cli_codcli = item.auxiliar;
          detailItem.ldi_docref = item.nro_docu;
          detailItem.ldi_tipcam = item.tipo_cambio;
          detailItem.ldi_gloldi = item.glosa;

          this.recepcionCompra.detalle.push(detailItem);
          this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);

          // setTimeout(() => {
          //   this.selectCosto.first.open();
          // }, 1);
        });
      }
    })
  }

  copiarItem(): void {

    if (!this.selected) {
      this._snackBarService.showError('Debe seleccionar un item', "OK");
      return;
    }

    if (this.selected.ldi_corldi[0] == '6' || this.selected.ldi_corldi[0] == '7') {
      this._snackBarService.showError('No es permitido copiar un ítem que comience con el correlativo 6 o 7', "OK");
      return;
    }


    let item = new DetailPurchaseRecord();
    item.cia_codcia = this.selected.cia_codcia;
    item.ano_codano = this.selected.ano_codano;
    item.mes_codmes = this.selected.mes_codmes;
    item.ldi_anoccl = this.selected.ldi_anoccl;
    item.ldi_mesccl = this.selected.ldi_mesccl;
    item.tmo_codtmo = this.selected.tmo_codtmo;
    item.ldi_fecemi = this.selected.ldi_fecemi;
    item.ldi_fecven = this.selected.ldi_fecven;
    item.sco_codsco = this.selected.sco_codsco;
    item.ldi_tipcam = this.selected.ldi_tipcam;
    item.ldi_gloldi = this.selected.ldi_gloldi;
    item.ccn_codccn = this.selected.ccn_codccn;
    item.cli_codcli = this.selected.ccn_codccn;
    item.tdo_codtdo = this.selected.tdo_codtdo;
    item.ldi_docref = this.selected.ldi_docref;
    item.tmo_codtmo = this.selected.tmo_codtmo;
    item.ldi_tipcam = this.selected.ldi_tipcam;
    item.ldi_inddha = this.selected.ldi_inddha;
    item.ldi_gloldi = this.selected.ldi_gloldi;
    item.cco_codcco = this.selected.cco_codcco;

    item.ldi_impnac = 0;
    item.ldi_impmex = 0;
    item.ccs_codccs = null;

    this.borrarAsientosDestino();

    this.recepcionCompra.detalle.push(item);

    this.recepcionCompra.detalle.forEach((element, key) => {
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort)

  }

  agregarItem(): void {

    if (!this.recepcionCompra.CCO_TIPCAM || this.recepcionCompra.CCO_TIPCAM == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.recepcionCompra.CCN_CODCCN) {
      this._snackBarService.showError("Establecer Cuenta", "OK");

      return;
    }

    const detailItem = new DetailPurchaseRecord();

    detailItem.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    detailItem.ano_codano = this.year;
    detailItem.mes_codmes = this.month;

    detailItem.ldi_anoccl = this.year;
    detailItem.ldi_mesccl = this.month;
    detailItem.ldi_fecemi = this.recepcionCompra.CCO_FECEMI;
    detailItem.ldi_fecven = this.recepcionCompra.CCO_FECVEN;

    detailItem.sco_codsco = this.recepcionCompra.SCO_CODSCO;

    detailItem.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
    detailItem.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
    detailItem.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
    detailItem.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
    detailItem.ldi_docref = this.recepcionCompra.CCO_NUMDOC;

    this.recepcionCompra.detalle.push(detailItem);

    this.recepcionCompra.detalle.forEach((element, key) => {
      element.ldi_corldi = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);

    // setTimeout(() => {
    //   // this.cuentaTotalSelect.open();
    //   this.selectCuentas.last.open();
    //   this.selectCuentas.last.focus();
    // }, 1);

  }

  quitarItem(): void {

    let row = this.recepcionCompra.detalle.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.recepcionCompra.detalle.length > 0) row = this.recepcionCompra.detalle[0];

    let details = [];
    this.recepcionCompra.detalle.forEach(element => {
      if (element.ldi_corldi != row.ldi_corldi) {
        details.push(element)
      }
    });

    this.recepcionCompra.detalle = details;

    this.recepcionCompra.detalle.forEach((element, key) => {
      element.ldi_corldi = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;

    this.establecerDebeHaber()
  }

  insertarItem(): void {

    if (!this.recepcionCompra.CCO_TIPCAM || this.recepcionCompra.CCO_TIPCAM == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.recepcionCompra.CCN_CODCCN) {
      this._snackBarService.showError("Establecer Cuenta", "OK");

      return;
    }

    const registro = new DetailPurchaseRecord();

    registro.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    registro.ano_codano = this.year;
    registro.mes_codmes = this.month;

    registro.ldi_anoccl = this.year;
    registro.ldi_mesccl = this.month;
    registro.tmo_codtmo = this.recepcionCompra.TMO_CODTMO;
    registro.ldi_fecemi = this.recepcionCompra.CCO_FECEMI;
    registro.ldi_fecven = this.recepcionCompra.CCO_FECVEN;
    registro.sco_codsco = this.recepcionCompra.SCO_CODSCO;
    registro.ldi_tipcam = this.recepcionCompra.CCO_TIPCAM;
    registro.ldi_gloldi = this.recepcionCompra.CCO_GLOCCO;
    registro.tdo_codtdo = this.recepcionCompra.TDO_CODTDO;
    registro.ldi_docref = this.recepcionCompra.CCO_NUMDOC;

    let i = 0;

    if (this.selected) {
      this.recepcionCompra.detalle.forEach((item, index) => {
        if (item.ldi_corldi == this.selected.ldi_corldi) i = index;
      })

      this.recepcionCompra.detalle.splice(i, 0, registro);
    } else {
      this.recepcionCompra.detalle.push(registro);
      this.recepcionCompra.detalle = this.recepcionCompra.detalle.reverse();
    }

    this.recepcionCompra.detalle.forEach((element, key) => {
      console.log('ids', key)
      element.ldi_corldi = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    console.log('detalle', this.recepcionCompra.detalle)

    this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);

  }

  agregarDocumento(): void {
    const documento = new DocumentoRecepcion()
    console.log('this.recepcionCompra.documentos', this.recepcionCompra.documentos)
    this.recepcionCompra.documentos.push(documento);
    this.dataSourceDocumentos = fillTable(this.recepcionCompra.documentos, this.paginatorDocumentos, this.sortDocumentos);

  }

  insertarDocumento(): void { }

  quitarDocumento(): void { }

  seleccionarOrdenCompra(): void {
    const ordenCompra = this.ordenesCompra.find(item => item.ocm_numocm == this.recepcionCompra.OCM_NUMOCM)
    if (ordenCompra) {
      this.recepcionCompra.CCS_CODCCS = ordenCompra.ccs_codccs;
    }
  }

  abrirModalOrdenCompra(): void {

    const dialogRef = this.dialog.open(OrdenCompraModalListComponent, {
      disableClose: true,
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.recepcionCompra.OCM_NUMOCM = result.ocm_numocm;
      this.recepcionCompra.OCM_DESCRI = result.description;
    });

  }

  calculoImportesME(row: DetailPurchaseRecord): void {
    row.ldi_impnac = roundToDecimals(Number(row.ldi_impmex) * Number(row.ldi_tipcam), 2);
    this.establecerDebeHaber();
  }

  calculoImportesMN(row: DetailPurchaseRecord): void {
    row.ldi_impmex = roundToDecimals(Number(row.ldi_impnac) / Number(row.ldi_tipcam), 2);
    this.establecerDebeHaber();
  }

  seleccionarDH(value) {
    this.establecerDebeHaber();
  }

  establecerEntero(): void {
    this.recepcionCompra.CCO_TIPCAM = Number(this.recepcionCompra.CCO_TIPCAM);
    this.recepcionCompra.CCO_IMPAFE = Number(this.recepcionCompra.CCO_IMPAFE);
    this.recepcionCompra.CCO_IMPINA = Number(this.recepcionCompra.CCO_IMPINA);

    this.recepcionCompra.detalle.forEach(element => {
      element.ldi_tipcam = Number(element.ldi_tipcam);
      element.ldi_impnac = Number(element.ldi_impnac);
      element.ldi_impmex = Number(element.ldi_impmex);

    })
  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;

    this.recepcionCompra.detalle.forEach((element, key) => {
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.establecerEntero();

    if (!this.validacionesOrdenCompra()) {
      this.loaderReg = false;
      return;
    }

    if (!this.recepcionCompra.OCM_NUMOCM) {
      this._snackBarService.showError('Seleccionar una orden de compra', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.recepcionCompra.CPA_CODCPA) {
      this._snackBarService.showError('Seleccionar una condición de pago', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.recepcionCompra.TGA_CODTGA) {
      this._snackBarService.showError('Seleccionar un tipo de operación', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.recepcionCompra.t30_codt30) {
      this._snackBarService.showError('Seleccionar una clasificación de bien y servicio', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.recepcionCompra.CCN_CODCCN) {
      this._snackBarService.showError('Seleccionar una cuenta contable', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.recepcionCompra.SCO_CODSCO) {
      this._snackBarService.showError('Seleccionar un subdiario contable', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.recepcionCompra.sad_codsad) {
      this._snackBarService.showError('Establecer un número de serie', 'Ok');
      this.loaderReg = false;
      return;
    }

    if(!this.esValidoFechasEmiReg(this.recepcionCompra.CCO_FECEMI, this.recepcionCompra.CCO_FECREG)){
      this._snackBarService.showError('Verificar si la fecha de emisión es menor o igual a la fecha de registro', 'Ok');
      this.loaderReg = false;
      return;
    }

    if(!this.recepcionCompra.DPC_AFCDER) this.recepcionCompra.DPC_AFCDER = 0;

    // if (this.recepcionCompra.CCO_NUMDOC.length >= 12) {
    //   this._snackBarService.showError('El número de documento tiene que tener 12 digitos', 'Ok');
    //   this.loaderReg = false;
    //   return;
    // }
    this.recepcionCompra.CCO_CODCCO ? this.actualizarRegistroCompra(f) : this.crearRegistroCompra(f);

  }

  crearRegistroCompra(f: NgForm): void {

    // this.registro.cco_numdoc =  this.registro.sad_codsad + this.registro.cco_numdoc;
    this.loaderReg = false;

    this.sendForm$ = this._comprasService.crearRegistroDeCompra(RegistroCompra.fromRecepcionToRegistroCompra(this.recepcionCompra)).subscribe(
      (response: RegistroCompra) => {

        this._dialogService.openDialog(SuccessComponent, `Registro de Compra Creado Exitosamente. Nro Asiento: ${response.cco_codcco}`, '400px', '', '');

        const payload = {
          CIA_CODCIA: this._configurationService.obtenerCompaniaCliente(),
          CCR_CODCCR: this.recepcionCompra.CCR_CODCCR,
          CCO_CODCCO: this.recepcionCompra.CCO_CODCCO,
        }

        this.recepcionCompra.CCO_CODCCO = response.cco_codcco;

        this._comprasService.mostrarRegistroDeCompra(response.cco_codcco).subscribe(
          registroCompra => {

            this.recepcionCompra.detalle = registroCompra.detalle;
            this.dataSource = fillTable(this.recepcionCompra.detalle, this.paginator, this.sort);

            this._recepcionService.actualizarRecepcion(payload).subscribe(
              responseRecepcion => {
                // this._snackBarService.showSuccess('Recepcion actualizada', 'Ok');
                this.loaderReg = false;
              },
              error => {
                this._snackBarService.showError(error.error.msg, "OK")
                this.loaderReg = false;
              }
            )

          }
        )


      },
      error => {
        this.loaderReg = false;
        console.log(error)
        this._snackBarService.showError(error.error.msg, "OK")
      }
    )

  }

  actualizarRegistroCompra(f: NgForm): void {

    // this.registro.cco_numdoc =  this.registro.sad_codsad + this.registro.cco_numdoc;
    this.loaderReg = false;

    this.sendForm$ = this._comprasService.editarRegistroDeCompra(RegistroCompra.fromRecepcionToRegistroCompra(this.recepcionCompra)).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, `Registro de Compra Actualizado Exitosamente. Nro Asiento: ${response.cco_codcco}`, '400px', '', '');

        this.loaderReg = false;
        // this.recepcionCompra.CCO_CODCCO = response.cco_codcco;

        // const payload = {
        //   CIA_CODCIA: this._configurationService.obtenerCompaniaCliente(),
        //   CCR_CODCCR: this.recepcionCompra.CCR_CODCCR,
        //   CCO_CODCCO: this.recepcionCompra.CCO_CODCCO,
        // }

        // this._recepcionService.actualizarRecepcion(payload).subscribe(
        //   responseRecepcion => {
        //     this._snackBarService.showSuccess('Recepcion actualizada', 'Ok');
        //   },
        //   error => {
        //     this._snackBarService.showError(error.error.msg, "OK")
        //     this.loaderReg = false;
        //   }
        // )

      },
      error => {
        this.loaderReg = false;
        console.log(error)
        this._snackBarService.showError(error.error.msg, "OK")
      }
    )

  }

  esValidoFechasEmiReg(fechaEmision: Date | string, fechaRegistro: Date | string): boolean {
    const fEmision = new Date(fechaEmision);
    const fRegistro = new Date(fechaRegistro);
    return fEmision <= fRegistro;
  }

  numeroConDosDecimales(numero): string {

    return numberWithCommas(numero.toFixed(2));

  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoCambio$,
      this.sendForm$,
      this.tipoOperacion$,

    ])
  }
}
