import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Responsable } from "src/app/models/crm/responsable";
import { ConfigurationService, VentasService } from "src/app/services";
import { ResponsableService } from "src/app/services/api/crm/maestros/responsable.service";

@Component({
  selector: 'responsable-form',
  templateUrl: 'responsable-form.component.html',
  styleUrls: ['responsable-form.component.scss']
})
export class ResponsableFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  responsable: Responsable;

  uid: string;

  loading$: Subscription;
  sendForm$: Subscription;
  responsable$: Subscription;
  clientes$: Subscription;

  clientes: any[] = [];

  constructor(
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService,
    private _dialogService: DialogService,
    private _responsableService: ResponsableService,
    private _router: Router,
    private _configurationService: ConfigurationService,
  ) {
    this.responsable = new Responsable();
    this.responsable.codcia = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.loadMaestros();

      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;

    })
  }

  loadMaestros(): void {

    this.clientes$ = this._ventasService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes;

        if(this.uid != '0') this.obtenerResponsable();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  trackByCliente(index, cliente): string {
    return cliente.cli_codcli;
  }

  obtenerResponsable(): void {

    this.responsable$ = this._responsableService.obtenerResponsable(this.uid).subscribe(
      responsable => {
        this.responsable = responsable[0];
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;
    this.uid == '0' ? this.registrarResponsable(f): this.actualizarResponsable(f);
  }

  /**
   * Método para registrar un nuevo responsable.
   * @param f
   */
  registrarResponsable(f: NgForm): void {
    this.sendForm$ = this._responsableService.registrarResponsable(this.responsable).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.responsable.success-store', '400px', 'auto', '')
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar los datos de un responsable
   * @param f
   */
  actualizarResponsable(f: NgForm): void {
    this.sendForm$ = this._responsableService.actualizarResponsable(this.responsable).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.responsable.success-update', '400px', 'auto', '')
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {

    if(!this.responsable.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    return true;

  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/responsables']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.responsable$,
    ])
  }

}
