<app-pavso-title-section [title]="'apps.crm.informe.title' | translate" [module]="'CRM'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)" [reporteSeleccionado]="reporteSeleccionado[0]" [disabled]="loading"></select-report>

  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">{{'apps.crm.informe.filter' | translate}}</div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <div class="pav-filtro-container">

    <div class="row">
      <div class="col s12 m12 l3">

        <select-search
          [label]="'Canal'"
          [placeholder]="'Ej. Remoto'"
          [name]="'idmtk'"
          [value]="'idmtk'"
          [description]="'descri'"
          [data]="canales"
          (cambioSelect)="filtro.canal = $event"
          [model]="filtro.canal"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'Vendedor'"
          [placeholder]="'Ej. Sábato'"
          [name]="'VDE_CODVDE'"
          [value]="'VDE_CODVDE'"
          [description]="'VDE_NOMVDE'"
          [data]="vendedores"
          (cambioSelect)="filtro.vendedor = $event"
          [model]="filtro.vendedor"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'Línea'"
          [placeholder]="'Ej. Linea'"
          [name]="'codoln'"
          [value]="'codoln'"
          [description]="'descri'"
          [data]="lineas"
          (cambioSelect)="filtro.linea = $event"
          [model]="filtro.linea"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">
        <select-search
          [label]="'apps.crm.informe.filter-form.type-of-negotiation' | translate"
          [placeholder]="'Ej. Tipo de negociación'"
          [name]="'codotn'"
          [value]="'codotn'"
          [description]="'descri'"
          [data]="tiposNegociacion"
          (cambioSelect)="filtro.tipoNegociacion = $event"
          [model]="filtro.tipoNegociacion"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.informe.filter-form.client' | translate"
          [placeholder]="'Ej. Torres'"
          [name]="'cli_codcli'"
          [value]="'cli_codcli'"
          [description]="'description'"
          [data]="clientes"
          (cambioSelect)="filtro.cliente = $event"
          [model]="filtro.cliente"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.informe.filter-form.good-or-service' | translate"
          [placeholder]="'Ej. servicio'"
          [name]="'prd_codprd'"
          [value]="'prd_codprd'"
          [description]="'DESC_ESPA'"
          [data]="bienes"
          (cambioSelect)="filtro.bienServicio = $event"
          [model]="filtro.bienServicio"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.informe.filter-form.responsible' | translate"
          [placeholder]="'Ej. responsable'"
          [name]="'idres'"
          [value]="'idres'"
          [description]="'nombre'"
          [data]="responsables"
          (cambioSelect)="filtro.responsable = $event"
          [model]="filtro.responsable"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.informe.filter-form.stage' | translate"
          [placeholder]="'Ej. etapa'"
          [name]="'codoet'"
          [value]="'codoet'"
          [description]="'descri'"
          [data]="etapas"
          (cambioSelect)="filtro.etapa = $event"
          [model]="filtro.etapa"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'apps.crm.informe.filter-form.type-attention' | translate"
          [placeholder]="'Ej. Tipo de atención'"
          [name]="'idtas'"
          [value]="'idtas'"
          [description]="'descri'"
          [data]="tiposAtencion"
          (cambioSelect)="filtro.tipoAtencion = $event"
          [model]="filtro.tipoAtencion"
          [multiple]="true">
        </select-search>

      </div>

    </div>

  </div>
  <br>

  <div align="center">
    <button class="pav-button-action" mat-flat-button color="primary" (click)="verReporte()" [class.spinner]="loading" [disabled]="loading">{{'general.button.generate-report' | translate}}</button>
  </div>
  <br>
  <app-pavso-title-section [title]="'Gráficos' | translate" [module]="'CRM'"></app-pavso-title-section>

  <br>
  <h2>Oportunidades</h2>
  <div class="row">
    <div class="col s12 m12 l3">
      <button [routerLink]="['/modulo-crm/crm-graph-01']" class="pav-button-action" color="primary" type="button" mat-flat-button>01 - Oportunidades por pais</button>
    </div>
    <div class="col s12 m12 l3">
      <button [routerLink]="['/modulo-crm/crm-graph-02']" class="pav-button-action" color="primary" type="button" mat-flat-button>02 - Oportunidades por empresa </button>
    </div>
    <div class="col s12 m12 l3">
      <button [routerLink]="['/modulo-crm/crm-graph-03']" class="pav-button-action" color="primary" type="button" mat-flat-button>03 - Resultados plantas </button>
    </div>
    <div class="col s12 m12 l3">
      <button [routerLink]="['/modulo-crm/crm-graph-04']" class="pav-button-action" color="primary" type="button" mat-flat-button>04 - Oportunidades por marca </button>
    </div>
  </div>

  <h2>Cotizaciones</h2>
  <div class="row">
    <div class="col s12 m12 l3">
      <button [routerLink]="['/modulo-crm/crm-graph-05']" class="pav-button-action" color="primary" type="button" mat-flat-button>01 - Cotizaciones por pais</button>
    </div>

    <div class="col s12 m12 l3">
      <button [routerLink]="['/modulo-crm/crm-graph-06']" class="pav-button-action" color="primary" type="button" mat-flat-button>02 - Cotizaciones por marca </button>
    </div>
  </div>
</div>
