import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Color } from "src/app/models/almacen";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerColores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/producto/colores/listar`
    );
  }

  obtenerColor(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/producto/colores/mostrar/${id}`
    );

  }

  registrarColor(color: Color): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/producto/colores/crear`,
      color
    )
  }

  actualizarColor(color: Color): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/producto/colores/modificar`,
      color
    )
  }

}
