<app-pavso-title-section [title]="'Descuento Producto'" [module]="'Ventas'" [usuario]="" [fecha]=""></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todos los descuentos por producto" url="/modulo-ventas/descuento-por-producto/" entity="" [params]="['0']"></table-title-action>

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <table mat-table [dataSource]="dataSource" matSort >
        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <a matTooltip="Editar descripcion"
                  class="pav-btn-circle-edit"
                  [routerLink]="['/modulo-ventas/descuento-por-producto', row.tbd_numtbd]">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </a>
              </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="tbd_numtbd">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row" data-label="Código"> {{row.tbd_numtbd}} </td>
        </ng-container>

        <ng-container matColumnDef="tbd_glotbd">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripcion </th>
            <td mat-cell *matCellDef="let row" data-label="descripcion"> {{row.tbd_glotbd}} </td>
        </ng-container>

        <ng-container matColumnDef="tbd_indsta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.tbd_indsta == '1'? 'ACTIVO':'INACTIVO'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="3">No se encontraron registros "{{input.value}}"</td>

        </tr>

    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
