import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { CajaBancoService, ContabilidadService, AlmacenService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';

class FiltroConsultaMovimientoContable {
  comprobante: string;
  mes: string;
  auxiliar: string;
  tipoDocumento: string;
  nroDocumento: string;
  cuenta: string;
  subdiario: string;
  banco: string;
  cuentaBancaria: string;
}

@Component({
  selector: 'app-consultas-movimientos-contables',
  templateUrl: './consultas-movimientos-contables.component.html',
  styleUrls: ['./consultas-movimientos-contables.component.css']
})
export class ConsultasMovimientosContablesComponent implements OnInit, OnDestroy {

  meses: any[] = [{ id: "01", name: "Enero" }, { id: "02", name: "Febrero" }, { id: "03", name: "Marzo" }, { id: "04", name: "Abril" }, { id: "05", name: "Mayo" }, { id: "06", name: "Junio" }, { id: "07", name: "Julio" }, { id: "08", name: "Agosto" }, { id: "09", name: "Setiembre" }, { id: "10", name: "Octubre" }, { id: "11", name: "Noviembre" }, { id: "12", name: "Diciembre" }];
  mes: string;

  displayedColumns: string[] = ['ldi_messco', 'ldi_numdoc', 'ccn_codccn', 'cli_nomcli', 'ccs_codccs', 'cco_fecreg_103', 'cco_fecemi_103', 'ldi_gloldi'];
  dataSource: MatTableDataSource<any>;
  totalDataStandar: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsBanco: string[] = ['ldi_messco', 'ldi_numdoc', 'cco_fecemi_103', 'cli_nomcli', 'tmo_codtmo', 'importe'];
  dataSourceBanco: MatTableDataSource<any>;
  totalDataBancos: any[];

  @ViewChild("pBanco") paginatorBanco: MatPaginator;
  @ViewChild(MatSort) sortBanco: MatSort;

  displayedColumnsCompras: string[] = ['ldi_messco', 'ldi_numdoc', 'ccn_codccn', 'cco_fecreg_103', 'cco_fecemi_103', 'cli_nomcli', 'ccs_codccs', 'tmo_codtmo', 'importe'];
  dataSourceCompras: MatTableDataSource<any>;
  totalDataCompras: any[];

  @ViewChild("pCompras") paginatorCompras: MatPaginator;
  @ViewChild(MatSort) sortCompras: MatSort;

  displayedColumnsVentas: string[] = ['ldi_messco', 'ldi_numdoc', 'ccn_codccn', 'ccs_codccs', 'cco_fecreg_103', 'cco_fecemi_103', 'cli_nomcli', 'ldi_numdoc2', 'tmo_codtmo', 'importe'];
  dataSourceVentas: MatTableDataSource<any>;
  totalDataVentas: any[];

  @ViewChild("pVentas") paginatorVentas: MatPaginator;
  @ViewChild(MatSort) sortVentas: MatSort;

  displayedColumnsHonorario: string[] = ['ldi_messco', 'ldi_numdoc', 'ccn_codccn', 'ccs_codccs', 'cco_fecreg_103', 'cco_fecemi_103', 'cli_nomcli', 'ldi_numdoc2', 'tmo_codtmo', 'importe'];
  dataSourceHonorario: MatTableDataSource<any>;
  totalDataHonorarios: any[];

  @ViewChild("pHonorarios") paginatorHonorario: MatPaginator;
  @ViewChild(MatSort) sortHonorario: MatSort;

  loaderStandar: boolean = false;
  loaderBancos: boolean = false;
  loaderCompras: boolean = false;
  loaderVentas: boolean = false;
  loaderHonorarios: boolean = false;
  loaderData: boolean = false;
  loaderReg: boolean = false;

  filtro: FiltroConsultaMovimientoContable = new FiltroConsultaMovimientoContable();

  opcionComprobantes: string = "estandar";
  subdiario: string = "0301";
  cuentaBancaria: string;
  auxiliar: string;
  tipoDoc: string;

  selectedIndex: number = 0;


  subdiarios: any[] = [];
  cuentasContable: any[] = [];
  auxiliares: any[] = [];
  bancos: any[] = [];
  tiposDocumento: any[] = [];
  cuentasBancaria: any[] = [];

  subdiario$: Subscription;
  cuenta$: Subscription;
  cuentaContable$: Subscription;
  auxiliar$: Subscription;
  banco$: Subscription;
  tiposDoc$: Subscription;
  cuentaBancaria$: Subscription;

  year: string;

  selectedRowIndex = -1;

  period$: Subscription;
  loading$ : Subscription;
  cuenta: {ccn_descri: string} = {ccn_descri: ''};

  constructor(
    private _contabilidadService: ContabilidadService,
    private _clienteService: ClienteService,
    private _snackBarService: SnackBarService,
    private _cajaBancoService: CajaBancoService,
    private _generalService: GeneralService,
    private store: Store<PavsoState>,
    private _router: Router
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceBanco = fillTable([], this.paginatorBanco, this.sortBanco);

    this.dataSourceCompras = fillTable([], this.paginatorCompras, this.sortCompras);

    this.dataSourceVentas = fillTable([], this.paginatorVentas, this.sortVentas);

    this.dataSourceHonorario = fillTable([], this.paginatorHonorario, this.sortHonorario);
  }

  ngOnInit(): void {

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.mes = state.month;
    });

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadMaestros();
    })

  }

  /**
   * Carga del listado de maestros
   */
  loadMaestros(): void {
    this.loaderData = true;

    this.subdiario$ = this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => {
        console.log('subdiarios', subdiarios)
        this.subdiarios = subdiarios;

        this.banco$ = this._generalService.listarBancos().subscribe(
          bancos => {
            console.log('bancos', bancos)
            this.bancos = bancos;

            this.auxiliar$ = this._clienteService.obtenerClientes().subscribe(
              clientes => {
                console.log('clientes', clientes)
                this.auxiliares = clientes;
                console.log('this.auxiliares', this.auxiliares)

                this.tiposDoc$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
                  tiposDocumento => {
                    console.log('tiposDocumento', tiposDocumento)
                    this.tiposDocumento = tiposDocumento;

                    this.cuentaBancaria$ = this._cajaBancoService.obtenerCajayBancos().subscribe(
                      cuentasBancarias => {
                        console.log('cajas y banco', cuentasBancarias)
                        this.cuentasBancaria = cuentasBancarias;

                        this.obtenerDatosConsulta();

                      },
                      error => {
                        this._snackBarService.showError("Error al obtener cuentas bancarias", "OK");
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError("Error al obtener tipos de documento", "OK");
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError("Error al obtener auxiliares", "OK");
                this.loaderData = false;
              }
            )

          },
          _ => {
            this._snackBarService.showError("Error al obtener bancos", "OK");
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError("Error al obtener subdiarios", "OK");
        this.loaderData = false;
      }
    );
  }

  obtenerDatosConsulta(): void {
    this.cargarDataTable();
  }

  /**
   * Seleccionar Comprobante
   * @param valor valor a filtrar
   */
  seleccionarComprobante(valor): void {
    switch (valor) {
      case "estandar":
        this.selectedIndex = 0;
        break;
      case "bancos":
        this.cargarDataTable();
        this.selectedIndex = 1;
        break;
      case "compras":
        this.selectedIndex = 2;
        break;
      case "ventas":
        this.selectedIndex = 3;
        break;
      case "honorarios":
        this.selectedIndex = 4;
        break;

      default:
        break;
    }
  }

  /**
   * Seleccionar Subdiario
   * @param valor subdiario ID
   */
  seleccionarSubdiario(valor): void {
    this.loaderStandar = true;
    this._contabilidadService.verConsultaMovimientoContableStandar(this.year, valor).subscribe(
      standar => {
        this.dataSource = fillTable(standar, this.paginator, this.sort);
        this.totalDataStandar = standar;
        this.filtrarStandar();
        this.loaderStandar = false;
      },
      error => {
        this._snackBarService.showError("Error al obtener Consulta Movimiento Contable Standar", "OK");
        this.loaderStandar = false;
      }
    )
  }

  /**
   * Seleccionar Auxiliar
   * @param valor auxiliar ID
   */
  seleccionarAuxiliar(valor): void {
    switch (this.opcionComprobantes) {
      case "estandar":
        this.filtrarStandar();
        break;
      case "bancos":
        this.filtrarBanco();
        break;
      case "compras":
        this.filtrarCompras();
        break;
      case "ventas":
        this.filtrarVentas();
        break;
      case "honorarios":
        this.filtrarHonorarios();
        break;

      default:
        break;
    }
  }

  /**
   * Seleccionar Tipo de Documento
   * @param valor Tipo de docuemnto ID
   */
  seleccionarTipoDocumento(valor): void {
    switch (this.opcionComprobantes) {
      case "estandar":
        this.filtrarStandar();
        break;
      case "bancos":
        this.filtrarBanco();
        break;
      case "compras":
        this.filtrarCompras();
        break;
      case "ventas":
        this.filtrarVentas();
        break;
      case "honorarios":
        this.filtrarHonorarios();
        break;

      default:
        break;
    }
  }

  filtrar(): void {
    console.log('filtro', this.filtro)
  }

  movimientosCtaCte(): void {

  }

  consulta(): void {

  }

  /**
   * Seleccionar Banco
   * @param valor Banco ID
   */
  seleccionarBanco(valor): void {
    this.filtrarBanco();
  }

  /**
   * Seleccionar Cuenta Bancaria
   * @param valor Cuenta Bancaria ID
   */
  seleccionarCuentaBancaria(valor): void {
    this.filtrarBanco();
  }

  seleccionarCuenta(valor): void {
    switch (this.opcionComprobantes) {
      case "estandar":
        this.filtrarStandar();
        break;
      case "bancos":
        // this.filtrarBanco();
        break;
      case "compras":
        this.filtrarCompras();
        break;
      case "ventas":
        this.filtrarVentas();
        break;
      case "honorarios":
        this.filtrarHonorarios();
        break;

      default:
        break;
    }
  }

  /**
   * Seleccionar Mes
   * @param valor Mes in Number
   */
  seleccionarMes(valor): void {
    switch (this.opcionComprobantes) {
      case "estandar":
        this.filtrarStandar();
        break;
      case "bancos":
        this.filtrarBanco();
        break;
      case "compras":
        this.filtrarCompras();
        break;
      case "ventas":
        this.filtrarVentas();
        break;
      case "honorarios":
        this.filtrarHonorarios();
        break;

      default:
        break;
    }
  }

  /**
   * Filtrar tabla de la pestaña standar
   */
  filtrarStandar(): void {
    let filtrados = this.totalDataStandar;
    if (this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }

    if (this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar)
    }

    if (this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc)
    }

    if (this.filtro.cuenta) {
      filtrados = filtrados.filter(item => item.ccn_codccn == this.filtro.cuenta)
    }
    this.dataSource = fillTable(filtrados, this.paginator, this.sort);
  }

  /**
   * Filtrar tabla de la pestaña banco
   */
  filtrarBanco(): void {
    let filtrados = this.totalDataBancos;
    if (this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }
    if (this.cuentaBancaria) {
      filtrados = filtrados.filter(item => item.Cba_codcba == this.cuentaBancaria);
    }
    if (this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar);
    }
    if (this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc);
    }
    this.dataSourceBanco = fillTable(filtrados, this.paginatorBanco, this.sortBanco);
  }

  /**
   * Filtrar tabla de la pestaña compras
   */
  filtrarCompras(): void {
    let filtrados = this.totalDataCompras;
    if (this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }
    if (this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar);
    }
    if (this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc);
    }
    if (this.cuenta) {
      filtrados = filtrados.filter(item => item.ccn_codccn == this.cuenta)
    }
    this.dataSourceCompras = fillTable(filtrados, this.paginatorCompras, this.sortCompras);
  }

  /**
   * Filtrar tabla de la pestaña Ventas
   */
  filtrarVentas(): void {
    let filtrados = this.totalDataVentas;
    if (this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }
    if (this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar);
    }
    if (this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc);
    }
    if (this.cuenta) {
      filtrados = filtrados.filter(item => item.ccn_codccn == this.cuenta)
    }
    this.dataSourceVentas = fillTable(filtrados, this.paginatorVentas, this.sortVentas);
  }

  /**
   * Filtrar tabla de la pestaña de honorarios
   */
  filtrarHonorarios(): void {
    let filtrados = this.totalDataHonorarios;
    if (this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }
    if (this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar);
    }
    if (this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc);
    }
    if (this.cuenta) {
      filtrados = filtrados.filter(item => item.ccn_codccn == this.cuenta)
    }
    this.dataSourceHonorario = fillTable(filtrados, this.paginatorHonorario, this.sortHonorario);
  }

  /**
   * Carga de datos en las pestañas de bancos, compras, ventas y honorarios
   */
  cargarDataTable(): void {
    this.loaderBancos = true;
    this.loaderCompras = true;
    this.loaderVentas = true;
    this.loaderHonorarios = true;

    this._contabilidadService.verConsultaMovimientoContable(this.year, this.mes).subscribe(

      data => {

        console.log('data', data)

        this.dataSource = fillTable(data, this.paginator, this.sort);
        this.dataSourceBanco = fillTable(data, this.paginator, this.sort);
        this.dataSourceCompras = fillTable(data, this.paginator, this.sort);
        this.dataSourceHonorario = fillTable(data, this.paginator, this.sort);
        this.dataSourceVentas = fillTable(data, this.paginator, this.sort);

        this.loaderData = false;

      },
      error => {
        this._snackBarService.showError('Error al obtener Consulta Movimiento Contable', 'OK');
      }

    )
  }

  verConsultaMovimientoCtaCte(): void {
    if (this.selectedRowIndex != -1) {
      this._router.navigate(['/modulo-contabilidad/movimientos-cta-cte', this.selectedRowIndex])
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.subdiario$,
      this.cuenta$,
      this.cuentaContable$,
      this.auxiliar$,
      this.banco$,
      this.tiposDoc$,
      this.cuentaBancaria$,
      this.period$
    ])


  }
}
