<app-pavso-title-section [title]="'Zona de venta'" [module]="'Ventas'" [usuario]="zona.ZVE_CODUSE" [fecha]="zona.ZVE_FECUPD" [estado]="zona.ZVE_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="zona.ZVE_CODZVE" matInput placeholder="Código" name="ZVE_CODZVE" required
              >
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="zona.ZVE_OBSZVE" matInput placeholder="Descripción" name="ZVE_OBSZVE" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Descri. Abrevi.</mat-label>
            <input type="text" [(ngModel)]="zona.ZVE_NOMZVE" matInput placeholder="Descri. Abrevi." name="ZVE_NOMZVE" required
              >
          </mat-form-field>
        </div>

      </div>

    </mat-card>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-ventas/zonas-de-venta">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
