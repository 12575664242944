import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'movimiento-series-producto',
  templateUrl: './movimiento-series-producto.component.html',
  styleUrls: ['./movimiento-series-producto.component.scss']
})
export class MovimientoSeriesProductoComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
