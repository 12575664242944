<app-pavso-title-section [title]="'Registro de compras'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <mat-form-field class="pavso_input_search">
        <mat-label>{{LABELS_NAME.BTN_SEARCH}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

    &nbsp;



    <button [routerLink]="['/modulo-contabilidad/registro-de-compra']" class="pavso_btn_add" mat-flat-button color="primary">
        <mat-icon>add</mat-icon>
        {{LABELS_NAME.BTN_ADD}}
    </button>

    <div class="mat-elevation-z0">
        <mat-card class="mat-elevation-z0 pavso_card_more_options">
            Todos los registros de compra
            <button class="pavso_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    class="pavso_more_options"
                    color="primary"
                    >
                    <mat-icon>cloud_download</mat-icon>
                    <span>Exportar a Excel</span>
                </button>

            </mat-menu>
        </mat-card>
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <button mat-icon-button matTooltip="Editar pedido" [routerLink]="['/modulo-compras/editar-registro-de-compra', row.cco_codcco]" color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="correlativo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.correlativo}} </td>
            </ng-container>

            <ng-container matColumnDef="tipo_documento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.tipo_documento}} </td>
            </ng-container>

            <ng-container matColumnDef="nro_documento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.nro_documento}} </td>
            </ng-container>

            <ng-container matColumnDef="glosa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.glosa}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_emision">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emi. </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.fecha_emision | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
