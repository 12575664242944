import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Etapa } from 'src/app/models/crm/etapa';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { EtapaService } from 'src/app/services/api/crm/maestros/etapa.service';

@Component({
  selector: 'app-etapa-form',
  templateUrl: './etapa-form.component.html',
  styleUrls: ['./etapa-form.component.css']
})
export class EtapaFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  etapa: Etapa;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  loading$: Subscription;
  sendForm$: Subscription;
  etapa$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _etapaService: EtapaService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
  ) {

    this.etapa = new Etapa();
    this.etapa.codcia = this._configurationService.obtenerCompaniaCliente();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id
      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;

      this.obtenerEtapa()
    })
  }

  obtenerEtapa(): void {

    this.etapa$ = this._etapaService.obtenerEtapa(this.uid).subscribe(
      etapa => this.etapa = etapa[0],
      error => this._snackBarService.showError(error.error.msg, "OK")
    )

  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;
    this.uid == '0' ? this.registrarEtapa(f): this.actualizarEtapa(f);
  }

  /**
   * Método para registrar una nueva etapa
   * @param f
   */
  registrarEtapa(f: NgForm): void {
    this.etapa.coduse = this._configurationService.obtenerIdUsuario();
    this.sendForm$ = this._etapaService.registrarEtapa(this.etapa).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.etapa.success-store', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  /**
   * Método para actualizar los datos de una etapa existente
   * @param f
   */
  actualizarEtapa(f: NgForm): void {
    this.sendForm$ = this._etapaService.actualizarEtapa(this.etapa).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.etapa.success-update', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {

    if(!this.etapa.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    return true;

  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/etapas-de-negociacion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.etapa$,
    ])
  }
}
