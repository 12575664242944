import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { ComprasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { TipoServicioProveedor } from 'src/app/models/compras/tipo-servicio-proveedor';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { TipoServicioProveedorService } from 'src/app/services/api/compras/maestros/tipo-servicio-proveedor.service';

@Component({
  selector: 'tipo-servicio-form',
  templateUrl: './tipo-servicio-form.component.html',
  styleUrls: ['./tipo-servicio-form.component.css']
})
export class TipoServicioFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  tipoServicio: TipoServicioProveedor;

  usuario: any;
  fecha: Date = new Date();

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _comprasService: ComprasService,
    private _tipoServicioProveedorService: TipoServicioProveedorService,
  ) {

    this.tipoServicio = new TipoServicioProveedor();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=>{
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(this.uid == '0') {
        this.loaderData = false;
        return;
      }

      this.obtenerTipoServicioProveedor();
    })
  }

  obtenerTipoServicioProveedor(): void{
    this.loaderData = true;
    this._tipoServicioProveedorService.listarTiposServicioProveedor().subscribe(
      tipos => {
        {
          this.tipoServicio = tipos.find(item => item.TSE_CODTSE == this.uid);
          this.loaderData = false;
        }
      },
      error => {
        {
          this.loaderData = false;
          this._snackBarService.showError(error.error.msg, 'Ok');
        }
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarTipoServicio(f): this.actualizarTipoServicio(f);
  }

  registrarTipoServicio(f: NgForm): void {
    this.loaderReg = true;
    this._tipoServicioProveedorService.registrarTipoServicioProveedor(this.tipoServicio).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de servicio proveedor registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarTipoServicio(f: NgForm): void {
    this.loaderReg = true;
    this._tipoServicioProveedorService.actualizarTipoServicioProveedor(this.tipoServicio).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de servicio proveedor actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-compras/tipos-de-servicio']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
