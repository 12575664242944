<app-pavso-title-section [title]="'Turno'" [module]="'Producción'" [estado]="turno.tur_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="turno.tur_codtur" matInput placeholder="Código" name="tur_codtur"  [disabled]="true">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="turno.tur_destur" matInput placeholder="Descripción" name="tur_destur" required [disabled]="loaderData">
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Hora Inicio</mat-label>
            <input type="time"  [(ngModel)]="turno.tur_horini" matInput placeholder="Hora Inicio" name="tur_horini" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Hora Fin</mat-label>
            <input type="time" [(ngModel)]="turno.tur_horfin" matInput placeholder="Hora Fin" name="tur_horfin" required [disabled]="loaderData" >
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-produccion/turnos"
    ></pav-form-actions>

  </form>
</div>
