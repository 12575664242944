import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { TipoCambioCierre } from 'src/app/models';
import { ConfigurationService, ContabilidadService } from 'src/app/services';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-tipo-cambio-cierre',
  templateUrl: './tipo-cambio-cierre.component.html',
  styleUrls: ['./tipo-cambio-cierre.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class TipoCambioCierreComponent implements OnInit {

  date = new FormControl(moment());
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tipoCambio: TipoCambioCierre;

  loaderData: boolean;
  loaderReg: boolean;

  constructor(
    private _configurationService: ConfigurationService,
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService
  ) {
    this.tipoCambio = new TipoCambioCierre();
  }

  ngOnInit(): void {
  }

  chosenYearHandler(normalizedYear: Moment): void {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  registrarTipoCambioCierre(f: NgForm): void{
    const year = new Date(this.tipoCambio.ctc_fecupd).getFullYear().toString();
    const month = new Date(this.tipoCambio.ctc_fecupd).getMonth().toString();

    this.tipoCambio.ctc_coduse = this._configurationService.obtenerIdUsuario();
    this.tipoCambio.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.tipoCambio.ano_codano = year;
    this.tipoCambio.mes_codmes = month.length > 1 ? month: `0${month}`;

    this._contabilidadService.registrarTipoCambioCierre(this.tipoCambio).subscribe(
      tipo => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de cambio cierre registrado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
      }
    )

  }
}
