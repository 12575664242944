<app-pavso-title-section [title]="'Cliente'" [module]="'Ventas'" [ngClass]="isDialog ? 'pav-dialog' : 'pav-form'"
  [estado]="cliente.cli_indsta"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-progress-bar mode="indeterminate" *ngIf="loadingMaestros"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" matInput placeholder="Código" name="cli_codcli" [(ngModel)]="cliente.cli_codcli"
              (change)="ingresoCodigo($event)" (keyup)="ingresoCodigo($event)" required>
            <button *ngIf="!loaderBuscarRUC" type="button" mat-icon-button matSuffix
              matTooltip="Buscar desde Consulta SUNAT" (click)="buscarEnRENIEC()" matTooltip="Consultar Sunat">
              <mat-icon svgIcon="sunat-icon"></mat-icon>
            </button>
            <button *ngIf="loaderBuscarRUC" type="button" mat-icon-button matSuffix>
              <mat-spinner diameter="20"></mat-spinner>
            </button>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Persona</mat-label>
            <mat-select required name="cli_tipper" [(ngModel)]="cliente.cli_tipper"
              (selectionChange)="seleccionarTipoPersona($event.value)">
              <mat-option *ngFor="let tipo of tiposDePersona" [value]="tipo.tpe_codtpe">
                {{tipo.tpe_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo documento</mat-label>
            <mat-select required name="did_coddid" [disabled]="cliente.cli_tipper == 'J'"
              [(ngModel)]="cliente.did_coddid" (selectionChange)="seleccionarTipoDocumento($event.value)">
              <mat-option *ngFor="let tipo of tiposDeDocumento" [value]="tipo.DID_CODDID">
                {{tipo.DID_DESABR}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="cliente.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Nro. Documento</mat-label>
            <input type="text" matInput placeholder="Ej 70707070" name="cli_numdni" [(ngModel)]="cliente.cli_numdni"
              (keyup)="validarDni()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="cliente.cli_tipper == 'J'">
          <mat-form-field>
            <mat-label>Nro. Documento</mat-label>
            <input type="text" matInput placeholder="Ej. 2020202020203" name="cli_numruc"
              [(ngModel)]="cliente.cli_numruc" min="0" max="99999999999" (keyup)="validarRuc()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l9" *ngIf="cliente.cli_tipper == 'J'">
          <mat-form-field>
            <mat-label>Razon social</mat-label>
            <input type="text" matInput placeholder="Razon social" name="razonsocial" [(ngModel)]="cliente.cli_nomcli">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="cliente.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Ape. Paterno</mat-label>
            <input type="text" matInput placeholder="Ape. Paterno" name="cli_apepat" [(ngModel)]="cliente.cli_apepat">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="cliente.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Ape. Materno</mat-label>
            <input type="text" matInput placeholder="Ape. Materno" name="cli_apemat" [(ngModel)]="cliente.cli_apemat">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="cliente.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Nombres</mat-label>
            <input type="text" matInput placeholder="Nombres" name="cli_nombre" [(ngModel)]="cliente.cli_nombre">
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <mat-tab-group>
        <mat-tab label="Datos Generales" labelClass="mat-tab-label-0-0">
          <br>
          <div class="row">

            <div class="col s12 m12 l6">
              <mat-form-field>
                <mat-label>Dirección</mat-label>
                <input type="text" matInput placeholder="Direccion" name="cli_dircli" [(ngModel)]="cliente.cli_dircli">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <select-search [label]="'Sucursales'" [placeholder]="'Ej. Lima'" [name]="'SUC_CODSUC'"
                [value]="'SUC_CODSUC'" [description]="'suc_nomsuc'" [data]="sucursales"
                (cambioSelect)="cliente.suc_codsuc = $event" [model]="cliente.suc_codsuc"></select-search>
            </div>

            <div class="col s12 m12 l3">
              <select-search (abrirFormulario)="abrirFormulario($event)" [label]="'País'" [placeholder]="'Ej. Perú'"
                [name]="'PAI_CODPAI'" [value]="'PAI_CODPAI'" [description]="'pai_nomlar'" [data]="paises"
                (cambioSelect)="cliente.pai_codpai = $event; seleccionarPais($event)"
                [model]="cliente.pai_codpai"></select-search>
            </div>

            <div class="col s12 m12 l3">
              <select-search [required]="cliente.pai_codpai == '01'" [disabled]="cliente.pai_codpai != '01'"
                [label]="'Ubicación'" [placeholder]="'Ej. Ubicación'" [name]="'codubi'" [value]="'codubi'"
                [description]="'desubi'" [data]="ubicaciones" (cambioSelect)="cliente.ubi_codubi = $event"
                [model]="cliente.ubi_codubi"></select-search>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Teléfono</mat-label>
                <input required type="tel" type="tel" matInput placeholder="Teléfono" name="cli_numtlf"
                  [(ngModel)]="cliente.cli_numtlf">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Teléfono 2</mat-label>
                <input type="tel" type="tel" matInput placeholder="Teléfono 2" name="cli_numfax"
                  [(ngModel)]="cliente.cli_numfax">
              </mat-form-field>
            </div>

            <!-- <div class="col s12 m12 l3" *ngIf="cliente.cli_tipper == 'J'">
              <mat-form-field>
                <mat-label>Contacto</mat-label>
                <input type="text" matInput placeholder="Contacto" name="cli_nomcon" [(ngModel)]="cliente.cli_nomcon">
              </mat-form-field>
            </div> -->
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input type="email" matInput placeholder="E-mail" name="cli_corele" [(ngModel)]="cliente.cli_corele">
              </mat-form-field>
            </div>

          </div>
          <div class="row">

            <!-- <div class="col s12 m12 l3" *ngIf="cliente.cli_tipper == 'J'">
              <select-search [label]="'Cargo'" [placeholder]="'Ej. Cargo'" [name]="'cca_codcca'"
                [description]="'cca_descri'" [data]="cargos" (cambioSelect)="cliente.cli_carcon = $event"
                [value]="cliente.cli_carcon">
              </select-search>

            </div> -->

            <div class="col s12 m12 l3" *ngIf="cliente.cli_tipper == 'J'">
              <select-search [label]="'Giro de Negocio'" [placeholder]="'Ej. Ejemplo'" [name]="'gno_codgno'"
                [description]="'gno_desesp'" [data]="giros" (cambioSelect)="cliente.gno_codgno = $event"
                [value]="cliente.gno_codgno">
              </select-search>
            </div>

            <div class="col s12 m12 l3">
              <select-search [required]="cliente.pai_codpai == '01'" [disabled]="cliente.pai_codpai != '01'"
                [label]="'Ubig. Sunat'" [placeholder]="'Ubig. Sunat'" [name]="'ubs_codubs'" [value]="'ubs_codubs'"
                [description]="'ubs_descri'" [data]="ubicacionesSunat" (cambioSelect)="cliente.ubs_codubs = $event"
                [model]="cliente.ubs_codubs"></select-search>
            </div>

            <div class="col s12">
              <mat-form-field>
                <mat-label>Obligaciones tributarias</mat-label>
                <textarea readonly matInput placeholder="Obligaciones tributarias" name="obligaciones"></textarea>
              </mat-form-field>
            </div>

          </div>
        </mat-tab>
        <mat-tab label="Contactos" labelClass="mat-tab-label-0-1">
          <div class="container">

            <div class="row">
              <div class="col s12">
                <div class="pav-table-header-container">
                  <div class="pav-table-header-icon">

                  </div>
                  <div class="pav-table-header-title">
                    Contactos
                  </div>
                  <div class="pav-table-header-add">
                    <div class="isMobile">
                      <div class="pav-button-icon-add" (click)="agregarContacto(null)">
                        <mat-icon svgIcon="add"></mat-icon>
                      </div>
                    </div>
                    <div class="isWeb">
                      <button mat-flat-button class="pav-button-add-item" type="button"
                        (click)="agregarContacto(null)">Agregar ítem</button>
                    </div>
                  </div>
                </div>
                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceContactos" matSort>

                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div (click)="agregarContacto(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                            <mat-icon svgIcon="edit-white"></mat-icon>
                          </div>
                          <div class="pav-btn-circle-delete" (click)="quitarContacto(row)" matTooltip="Eliminar">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                        </div>

                      </td>
                    </ng-container>
                    <ng-container matColumnDef="codigo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Código"> {{row.ccl_codccl}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nombre">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Nombre">
                        <ng-container>
                          {{row.ccl_nomccl}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="cargo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cargo </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Cargo">

                        <ng-container>
                          {{row.cca_desabr}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="telefono">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">

                        <ng-container>
                          {{row.ccl_numtlf}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="correo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Correo">

                        <ng-container>
                          {{row.ccl_corele}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha_nacimiento">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de nacimiento </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Fecha de nacimiento">

                        <ng-container>
                          {{row.ccl_cumple | date: 'dd/MM/yyyy'}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="referencia">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Referencia">

                        <ng-container>
                          {{row.ccl_glosa}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="cobranza">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cobranza </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Cobranza">

                        <ng-container>
                          {{(row.ccl_indcob)?"SI": "NO"}}
                        </ng-container>

                      </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumnsContactos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsContactos;"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="9">No se encontraron registros</td>

                    </tr>

                  </table>
                  <mat-paginator #paginatorContactos showFirstLastButtons #paginatorContacto
                    [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </div>
          </div>
          `
        </mat-tab>
        <mat-tab label="Establecimientos" labelClass="mat-tab-label-0-2">
          <div class="container">
            <div class="row">
              <div class="col s12">
                <div class="pav-table-header-container">
                  <div class="pav-table-header-icon">

                  </div>
                  <div class="pav-table-header-title">
                    Establecimientos
                  </div>
                  <div class="pav-table-header-add">
                    <div class="isMobile">
                      <div class="pav-button-icon-add" (click)="agregarEstablecimiento(null)">
                        <mat-icon svgIcon="add"></mat-icon>
                      </div>
                    </div>
                    <div class="isWeb">
                      <button mat-flat-button class="pav-button-add-item" type="button"
                        (click)="agregarEstablecimiento(null)">Agregar ítem</button>
                    </div>
                  </div>
                </div>
                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceEstablecimientos" matSort>

                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div (click)="agregarEstablecimiento(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                            <mat-icon svgIcon="edit-white"></mat-icon>
                          </div>
                          <div class="pav-btn-circle-delete" (click)="quitarEstablecimiento(row)" matTooltip="Eliminar">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                        </div>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="codigo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. </th>
                      <td mat-cell *matCellDef="let row" data-label="Cod."> {{row.LDE_CODLDE}} </td>
                    </ng-container>

                    <ng-container matColumnDef="principal">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Es Principal </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Principal">
                        <ng-container>
                          {{(row.LDE_INDPRI)?"SI": "NO"}}
                        </ng-container>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="direccion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Dirección">

                        <ng-container>
                          {{row.LDE_DIRLDE}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="referencia">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Referencia">

                        <ng-container>
                          {{row.LDE_REFDIR}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="pais">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Pais">

                        <ng-container>
                          {{row.PAI_DESPAI}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="ubicacion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubicación </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Ubicación">

                        <ng-container>
                          {{row.UBI_DESUBI}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <ng-container matColumnDef="zona">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Zona">

                        <ng-container>
                          {{row.ZVE_DESZVE}}
                        </ng-container>

                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsEstablecimientos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsEstablecimientos;"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="8">No se encontraron registros</td>

                    </tr>

                  </table>
                  <mat-paginator showFirstLastButtons #paginatorEstablecimientos
                    [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Información Comercial" labelClass="mat-tab-label-0-3">
          <div class="container">
            <div class="row">
              <div class="col s12">
                <div class="col s12 m12 l3">
                  <select-search label="Vendedor" [placeholder]="'Vendedor'" [name]="'VDE_CODVDE'"
                    [value]="'VDE_CODVDE'" [description]="'VDE_NOMVDE'" [data]="vendedores"
                    (cambioSelect)="cliente.dato_clc.VDE_CODVDE = $event"
                    [model]="cliente.dato_clc.VDE_CODVDE"></select-search>
                </div>

                <div class="col s12 m12 l3">
                  <select-search label="Condi. Pago." [placeholder]="'Condi. Pago.'" [name]="'CPA_CODCPA'"
                    [value]="'CPA_CODCPA'" [description]="'CPA_DESCRI'" [data]="condiciones"
                    (cambioSelect)="cliente.dato_clc.CPA_CODCPA = $event"
                    [model]="cliente.dato_clc.CPA_CODCPA"></select-search>
                </div>

                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Sectorista</mat-label>
                    <mat-select required [(ngModel)]="cliente.dato_clc.SEC_CODSEC" name="codsec">
                      <mat-option *ngFor="let sectorista of sectoristas" [value]="sectorista.sec_codsec">
                        {{sectorista.descri}}
                      </mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l3">
                  <select-search label="Tipo de Lista" [placeholder]="'Tipo de Lista'" [name]="'TLP_CODTLP'"
                    [value]="'TLP_CODTLP'" [description]="'tlp_descri'" [data]="tiposDeLista"
                    (cambioSelect)="cliente.dato_clc.TLP_CODTLP = $event" [model]="cliente.dato_clc.TLP_CODTLP">
                  </select-search>
                </div>

                <div class="col s12 m12 l3">
                  <select-search label="Zona Vta." [placeholder]="'Zona Vta.'" [name]="'zve_codzve'"
                    [value]="'zve_codzve'" [description]="'zve_nomzve'" [data]="zonas"
                    (cambioSelect)="cliente.dato_clc.ZVE_CODZVE = $event"
                    [model]="cliente.dato_clc.ZVE_CODZVE"></select-search>
                </div>

                <div class="col s12 m12 l3">
                  <select-search label="Canal de Venta" [placeholder]="'Canal de Venta'" [name]="'vds_codvds'"
                    [value]="'vds_codvds'" [description]="'vds_descri'" [data]="canales"
                    (cambioSelect)="cliente.dato_clc.VDS_CODVDS = $event"
                    [model]="cliente.dato_clc.VDS_CODVDS"></select-search>
                </div>

                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Imprimir Cod. Prod. en Comprobantes</mat-label>
                    <mat-select required [(ngModel)]="cliente.dato_clc.CLI_INDPRD" name="indprd">
                      <mat-option value="1">
                        SI
                      </mat-option>
                      <mat-option value="0">
                        NO
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l3">
                  <section class="example-section" [ngStyle]="{paddingTop: '10px'}">
                    <mat-checkbox class="example-margin" name="clc_inoped" [(ngModel)]="cliente.dato_clc.CLC_INOPED">No
                      Registrar Pedido</mat-checkbox>
                  </section>
                </div>

                <div class="col s12">
                  <fieldset>
                    <legend>Códigos internos del cliente</legend>

                    <mat-checkbox class="example-margin">Imprimir en Guía</mat-checkbox>
                    &nbsp;
                    <mat-checkbox class="example-margin">Imprimir en Factura</mat-checkbox>
                  </fieldset>

                </div>

                <div class="col s12 m12 l3">
                </div>
                <div class="col s12 m12 l3">
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Información Crediticia" labelClass="mat-tab-label-0-4">
          <div class="container">
            <div class="row">
              <div class="col s12">
                <div class="row">
                  <div class="col s12 m12 l3">
                    <select-search [label]="'Moneda'" [placeholder]="'Ej. SO'" [name]="'TMO_CODTMO'"
                      [description]="'tmo_deslar'" [data]="monedas" [value]="cliente.dato_clc.TMO_CODTMO"
                      (cambioSelect)="cliente.dato_clc.TMO_CODTMO = $event"></select-search>
                  </div>
                  <div class="col s12 m12 l3">
                    <mat-form-field required class="example-full-width">
                      <mat-label>Importe</mat-label>
                      <input type="number" [(ngModel)]="cliente.dato_clc.CLC_CREASI" matInput placeholder="Importe"
                        name="creasi">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l3">
                    <select-search label="Calificación" [placeholder]="'Ej. Cliente Bueno'" [name]="'tcc_codtcc'"
                      [description]="'tcc_destcc'" [data]="calificaciones"
                      (cambioSelect)="cliente.dato_clc.TCC_CODTCC = $event" [value]="cliente.dato_clc.TCC_CODTCC">
                    </select-search>
                  </div>
                  <div class="col s12 m12 l3">
                    <select-search label="Aval 1" [placeholder]="'Ej. Aval 1'" [name]="'cli_codcli'"
                      [description]="'cli_nomcli'" [data]="clientes">
                    </select-search>
                  </div>
                  <div class="col s12 m12 l3">

                    <select-search label="Aval 2" [placeholder]="'Ej. Aval 2'" [name]="'cli_codcli'"
                      [description]="'cli_nomcli'" [data]="clientes">
                    </select-search>

                  </div>
                </div>
              </div>
              <div class="col s12">
                <div class="row">

                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Servicios Ofrecidos" labelClass="mat-tab-label-0-5">

          <div class="container">
            <div class="row">
              <div class="col s12">
                <div class="pav-table-header-container">
                  <div class="pav-table-header-icon">

                  </div>
                  <div class="pav-table-header-title">
                    Servicios Ofrecidos
                  </div>
                  <div class="pav-table-header-add">
                    <div [ngStyle]="{display: 'flex'}">
                      <div>

                        <button-add-item-table (addItem)="agregarServicio()"></button-add-item-table>
                        <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                          <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
                        </p>
                      </div>
                      &nbsp;
                      <div>
                        <button-remove-item-table (removeItem)="eliminarServicio()"></button-remove-item-table>

                        <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                          <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
                        </p>
                      </div>
                      &nbsp;
                      <div>
                        <button-insert-item-table (insertItem)="insertarServicio()"></button-insert-item-table>
                        <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                          <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Insertar</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceServicios" matSort>

                    <ng-container matColumnDef="codigo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                        <mat-form-field class="example-full-width">
                          <mat-label></mat-label>
                          <input matInput placeholder="Ex. 0445" [(ngModel)]="row.tse_codtse" name="'codigo' + i">
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="descripcion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Servicio">
                        <ng-container>
                          <mat-form-field class="mat-form-field-item">
                            <mat-label></mat-label>
                            <mat-select required [(ngModel)]="row.tse_codtse" [name]="'tse_codtse'+i"
                              (selectionChange)="seleccionarServicio($event, row)">
                              <mat-option *ngFor="let servicio of servicios"
                                [value]="servicio.TSE_CODTSE">{{servicio.TSE_DESTSE}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </ng-container>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsServicios"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsServicios;" [class.selected-row]="row === selected" (click)="selectRow(row)"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="2">No se encontraron registros</td>

                    </tr>
                  </table>
                  <mat-paginator #paginatorServicios [pageSizeOptions]="[5, 10, 25, 100]"
                    aria-label="Select page of users">

                  </mat-paginator>

                </div>
              </div>
            </div>
          </div>

        </mat-tab>

      </mat-tab-group>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loadingMaestros" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-ventas/clientes" (clickMasOpciones)="verMasOpciones()" (clickNuevo)="nuevoRegistro()">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
