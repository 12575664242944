<app-pavso-title-section [title]="'Registro de secuencia de nómina'" [module]="'Planilla'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">

      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Secuencia</mat-label>
            <input type="text" matInput placeholder="Secuencia" name="secuencia" required >
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput placeholder="Descripción" name="descripcion" required >
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha de Inicio</mat-label>
            <input matInput [matDatepicker]="fechainicio">
            <mat-datepicker-toggle matSuffix [for]="fechainicio"></mat-datepicker-toggle>
            <mat-datepicker #fechainicio></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha de Término</mat-label>
            <input matInput [matDatepicker]="fechatermino">
            <mat-datepicker-toggle matSuffix [for]="fechatermino"></mat-datepicker-toggle>
            <mat-datepicker #fechatermino></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Nómina</mat-label>
            <mat-select required name="tipo_nomina" >
              <mat-option value="tipo_nomina">
                tipo 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Frecuencia</mat-label>
            <mat-select required name="frecuencia" >
              <mat-option value="1">
                frecuencia 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Días Efectivos</mat-label>
            <input type="number" matInput placeholder="Días Efectivos" name="dias_efectivos" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Horas Efectivas</mat-label>
            <input type="number" matInput placeholder="Horas Efectivas" name="horas_efectivas" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Días Laborables</mat-label>
            <input type="number" matInput placeholder="Días Laborables" name="dias_laborables" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-checkbox class="example-margin">Nómina Cerrada</mat-checkbox>
        </div>
      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
