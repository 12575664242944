export class PermisoForm {

  interfaz: string;
  descrip: string;
  usuario: string;
  estado: string;
  ind_Nuevo: '1' | '0';
  ind_Modificar: '1' | '0';
  ind_Anulado: '1' | '0';
  ind_Eliminar: '1' | '0';
  ind_formato: '1' | '0';
  ind_ejecucion: 0 | 1;

  constructor() {
    this.ind_Nuevo = '1';
    this.ind_Modificar = '1';
    this.ind_Anulado = '1';
    this.ind_Eliminar = '0';
    this.ind_formato = '1';
    this.ind_ejecucion = 0;
  }

}
