<app-pavso-title-section [title]="'Edición de contrato'" [module]="'Planilla'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="editaContrato.codne0" matInput placeholder="Código" name="codne0"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="editaContrato.descri" matInput placeholder="Descripción"
              name="descri" required>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="pav-table-header-container">
      <div class="pav-table-header-icon">
      </div>
      <div class="pav-table-header-title">
        Tabla
      </div>
    </div>
    <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSource" matSort >

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Código" class="td-responsive"> {{i + 1}} </td>
        </ng-container>

        <ng-container matColumnDef="descripcion1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Descripción" class="td-responsive">
            <mat-form-field class="pav-mat-form-margin-top">
              <mat-label>Descripción</mat-label>
              <input matInput placeholder="Descripción" [(ngModel)]="row.descri" [name]="'descripcion1'+ i.toString()">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="dato1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dato </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Dato" class="td-responsive">
            <mat-form-field class="pav-mat-form-margin-top">
              <mat-label>Dato</mat-label>
              <mat-select [(ngModel)]="row.codndc" [name]="'dato1'+i">
                <mat-option *ngFor="let dato of datos" [value]="dato.ndc_codndc">
                  {{dato.ndc_codndc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="descripcion2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Descripción" class="td-responsive">
            <mat-form-field class="pav-mat-form-margin-top">
              <mat-label>Descripción</mat-label>
              <input matInput placeholder="Descripción" [(ngModel)]="row.descri1" [name]="'descripcion2'+ i.toString()">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="dato2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dato </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Dato" class="td-responsive">
            <mat-form-field class="pav-mat-form-margin-top">
              <mat-label>Dato</mat-label>
              <mat-select [(ngModel)]="row.codndc1" [name]="'dato2'+i">
                <mat-option *ngFor="let dato of datos" [value]="dato.ndc_codndc">
                  {{dato.ndc_codndc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="descripcion3">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Descripción" class="td-responsive">
            <mat-form-field class="pav-mat-form-margin-top">
              <mat-label>Descripción</mat-label>
              <input matInput placeholder="Descripción" [(ngModel)]="row.descri2" [name]="'descripcion3'+ i.toString()">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="dato3">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dato </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Dato" class="td-responsive">
            <mat-form-field class="pav-mat-form-margin-top">
              <mat-label>Dato</mat-label>
              <mat-select [(ngModel)]="row.codndc2" [name]="'dato3'+i">
                <mat-option *ngFor="let dato of datos" [value]="dato.ndc_codndc">
                  {{dato.ndc_codndc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="descripcion4">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Descripción" class="td-responsive">
            <mat-form-field class="pav-mat-form-margin-top">
              <mat-label>Descripción</mat-label>
              <input matInput placeholder="Descripción" [(ngModel)]="row.descri3" [name]="'descripcion4'+ i.toString()">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="dato4">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dato </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Dato" class="td-responsive">
            <mat-form-field class="pav-mat-form-margin-top">
              <mat-label>Dato</mat-label>
              <mat-select [(ngModel)]="row.codndc3" [name]="'dato4'+i">
                <mat-option *ngFor="let dato of datos" [value]="dato.ndc_codndc">
                  {{dato.ndc_codndc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">No se encontraron registros</td>

        </tr>

      </table>
    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-planilla/configuraciones-de-contrato">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
