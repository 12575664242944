export const REPORTES_PRODUCCION =  [
  {
    id: "PRODUCCION_BM",
    name: "Balance de materiales",
    esDinamico: false,
  },
  {
    id: "PRODUCCION_PF",
    name: "Productos fabricados",
    esDinamico: false,
  },
  {
    id: "PRODUCCION_DCM",
    name: "Detalle de consumo mat.",
    esDinamico: false,
  },
  {
    id: "PRODUCCION_LF",
    name: "Liq. de fabricaciones / oper.",
    esDinamico: false,
  },
  {
    id: "PRODUCCION_PD",
    name: "Producción diaria",
    esDinamico: false,
  },
];
