import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { InteresBancoDetalle } from "src/app/models/planilla/interes-banco";
import { GeneralService, NominaService } from "src/app/services";
import { ConfirmationComponent } from "../../confirmation/confirmation.component";
import { forkObs } from "@utils/observables/fork";

@Component({
  selector: 'interes-banco-dialog',
  templateUrl: './interes-banco-dialog.component.html',
  styleUrls: ['./interes-banco-dialog.component.scss']
})
export class InteresBancoDialog implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'banco', 'moneda', 'porcentaje'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonName: INameConstant = NAMES_CONSTANTS;

  loaderData: boolean;

  period$: Subscription;
  period: string;

  detalle: Array<InteresBancoDetalle> = [];

  monedas: any [] = [];

  /** filtrar bancos */
  bancos: any[] = [];

  year: string;
  month: string;

  constructor(
    public dialogRef: MatDialogRef<InteresBancoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<PavsoState>,
    private _generalService: GeneralService,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _nominaService: NominaService
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.period = `${state.year}-${state.month}`;
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {
    this.loadMaestros();
  }

  obtenerInteresBanco(): void {
    this._nominaService.obtenerDetalleInteresCTS(this.year, this.month).subscribe(
      response => {
        response.forEach((item, key) => {
          item['isEditing'] = false;
          item['correlativo'] = key;

          const banco = this.bancos.find(ele => ele.BAN_CODBAN == item.codban);
          item['banco'] = banco.BAN_NOMBRE;
        })

        this.detalle = response;

        this.dataSource = fillTable(this.detalle, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  loadMaestros(): void {

    forkObs(
      this._generalService.listarBancos(),
      this._generalService.obtenerMonedas()
    ).then(([bancos, monedas]) => {
      this.bancos = bancos;

      this.monedas = monedas;
      this.obtenerInteresBanco();
    }).catch(error => {
      this._snackBarService.showError('Error al obtener maestros', 'OK');
    })

  }

  agregarItem() {

    const item = new InteresBancoDetalle();

    this.detalle.push(item);

    this.detalle.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.detalle, this.paginator, this.sort);

  }

  editarItem(row) {
    row.isEditing = !row.isEditing;

  }

  confirmarItem(row: InteresBancoDetalle) {
    if(!row.codban || !row.codtmo || !row.porint) {
      this._snackBarService.showError('Todos los campos de fila deben estar compeltos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;
  }

  quitarItem(row) {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.detalle.filter(item => item.correlativo != row.correlativo);
        this.detalle = filtered;
        this.detalle.forEach((element, key) => {
          element['correlativo'] = key;
        })
        this.dataSource = fillTable(this.detalle, this.paginator, this.sort);
      }
    })
  }

  seleccionarBanco(event: MatSelectChange, row) {
    let existe = false;

    this.detalle.forEach(item => {
      if(item.isEditing == false)
        if(row.codban == item.codban) existe = true;
    });

    if(existe) {
      this._snackBarService.showError('Este banco ya existe en la tabla', 'Ok');
      event.source.value = "";
      return;
    }

    const banco = this.bancos.find(item => item.BAN_CODBAN == row.codban);
    row.banco = banco.BAN_NOMBRE;
  }

  registrarInteresBanco() {
    this._nominaService.registrarInteresBancoCTS(this.year, this.month, this.detalle).subscribe(
      response => {
        this._snackBarService.showSuccess('Interes de banco registrado', 'Ok');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
  }
}
