<div style="height: 1px" *ngIf="isMobile || isTablet"></div>

<app-pavso-title-section [title]="'Kardex'" [module]="'Almacén'" [usuario]="kardex.mac_coduse"
  [fecha]="kardex.mac_fecupd" [ngClass]="'pav-form'" [estado]="kardex.mac_indsta"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()"
  (keyup.enter)="$event.preventDefault()">

  <div class="pavso-content-form fade-in-image">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s4 m3 l2">
          <mat-form-field>
            <mat-label>Tipo</mat-label>
            <mat-select [disabled]="loaderData" [(ngModel)]="kardex.tdo_codtdo" name="tdo_codtdo"
              (selectionChange)="seleccionarTipo()">
              <mat-option value="ING">
                INGRESO
              </mat-option>
              <mat-option value="SAL">
                SALIDA
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <ng-container *ngIf="isMobile || isTablet">
          <div class="col s5 m5 l2">
            <mat-form-field>
              <mat-label>Fecha</mat-label>
              <input #iFecha maxlength="10" required matInput [matDatepicker]="picker" [(ngModel)]="kardex.mac_fecmac"
                name="mac_fecmac" (dateChange)="seleccionarFecha()" [disabled]="loaderData"
                (keyup.enter)="enterFecha()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

          </div>
        </ng-container>
        <ng-container *ngIf="isMobile || isTablet">
          <div class="col s3 m4 l1" *ngIf="configuracionTecnica.s70_vertca == 1">
            <mat-form-field class="example-full-width">
              <mat-label>T. Cambio</mat-label>
              <input #iTipoCambio [ngStyle]="{textAlign: 'right'}" required matInput placeholder="Nro Parte"
                [(ngModel)]="kardex.mac_tipcam" name="mac_tipcam">
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngIf="!isMobile && !isTablet">
          <div class="col s8 m8 l3">
            <select-search #sAlmacen [label]="'Almacén'" [placeholder]="'Ej. ALMACÉN P.T.'" [value]="'ALM_CODALM'"
              [name]="'ALM_CODALM'" [description]="'description'" [disabled]="loaderData" [data]="almacenes"
              (cambioSelect)="kardex.alm_codalm = $event; seleccionarAlmacen($event)" [model]="kardex.alm_codalm">
            </select-search>
          </div>

          <div class="col s4 m4 l1">
            <mat-form-field class="example-full-width">
              <mat-label>Nro. Parte</mat-label>
              <input matInput [(ngModel)]="kardex.mac_nummac" placeholder="Nro. Parte" name="mac_nummac" readonly>
            </mat-form-field>
          </div>
        </ng-container>

        <div *ngIf="isMobile || isTablet" class="col s12" style="display: flex;">
          <div style="padding-right: 15px;
          width: 60%;">
            <select-search #sAlmacen [label]="'Almacén'" [placeholder]="'Ej. ALMACÉN P.T.'" [value]="'ALM_CODALM'"
              [name]="'ALM_CODALM'" [description]="'description'" [disabled]="loaderData" [data]="almacenes"
              (cambioSelect)="kardex.alm_codalm = $event; seleccionarAlmacen($event)" [model]="kardex.alm_codalm">
            </select-search>
          </div>

          <div style="width: 35%">
            <mat-form-field class="example-full-width">
              <mat-label>Nro. Parte</mat-label>
              <input matInput [(ngModel)]="kardex.mac_nummac" placeholder="Nro. Parte" name="mac_nummac" disabled>
            </mat-form-field>
          </div>
        </div>

        <ng-container *ngIf="!isMobile && !isTablet">
          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>Fecha</mat-label>
              <input #iFecha maxlength="10" required matInput [matDatepicker]="picker" [(ngModel)]="kardex.mac_fecmac"
                name="mac_fecmac" (dateChange)="seleccionarFecha()" [disabled]="loaderData"
                (keyup.enter)="enterFecha()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="!isMobile && !isTablet">
          <div class="col s12 m12 l1" *ngIf="this.configuracionTecnica.s70_vertca == 1">
            <mat-form-field class="example-full-width">
              <mat-label>T. Cambio</mat-label>
              <input #iTipoCambio [ngStyle]="{textAlign: 'right'}" required matInput placeholder="Nro Parte"
                [(ngModel)]="kardex.mac_tipcam" name="mac_tipcam">
            </mat-form-field>
          </div>
        </ng-container>

        <div class="col s12 m6 l2">
          <select-search #sMotivo [label]="'Motivo'" [placeholder]="'Ej. COMPRAS LOCALES'" [value]="'mmo_codmmo'"
            [name]="'mmo_codmmo'" [description]="'description'" [disabled]="loaderData" [data]="motivos"
            [disabled]="loaderData" (cambioSelect)="kardex.mmo_codmmo = $event; seleccionarMotivo($event)"
            [model]="kardex.mmo_codmmo">
          </select-search>
        </div>
        <div class="col s12 m6 l2">

          <pav-input-search label="Auxiliar o Responsable" placeholder="ej. Plaza Vea" [isResponsive]="true"
            (valueChange)="cliente = $event; seleccionarResponsable($event.codigo); " [codigo]="kardex.cli_codcli"
            [descripcion]="kardex.cliente.cli_nomcli" width="'100%'" [program]="'CLIENTES'"></pav-input-search>

          <!-- <select-search #sResponsable [label]="'Auxiliar o Responsable'" [placeholder]="'Ej. GARCÍA'"
            [value]="'cli_codcli'" [name]="'CODIGO'" [disabled]="loaderData" [description]="'description'"
            [data]="clientes" (cambioSelect)="kardex.cli_codcli = $event; seleccionarResponsable()"
            [model]="kardex.cli_codcli">
          </select-search> -->
        </div>
      </div>
      <div class="row">

        <ng-container *ngIf="!isMobile && !isTablet">
          <div class="col s6 m6 l2" *ngIf="this.configuracionTecnica.s70_verref1 == 1">
            <select-search #sTipoDocRef01 [label]="'Tip. Doc. (01)'" [placeholder]="'Ej. FACTURA'" [name]="'TDO_CODTDO'"
              [value]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'TDO_DESLAR'" [data]="tiposDocumento"
              (cambioSelect)="kardex.mac_tdore1 = $event; seleccionarTipoDoc01()"
              [model]="kardex.mac_tdore1"></select-search>
          </div>
          <div class="col s6 m6 l2" *ngIf="this.configuracionTecnica.s70_verref1 == 1">
            <mat-form-field class="example-full-width">
              <mat-label>Referencia (01)</mat-label>
              <input #iNumDocRef01 matInput placeholder="Referencia (FAC)" [(ngModel)]="kardex.mac_numre1"
                name="mac_numre1" [disabled]="loaderData" (keyup)="keyUpReferencia01()"
                (keyup.enter)="enterReferencia01()">
            </mat-form-field>
          </div>

          <div class="col s6 m6 l2" *ngIf="this.configuracionTecnica.s70_verref2 == 1">
            <select-search #sTipoDocRef02 [label]="'Tip. Doc. (02)'" [placeholder]="'Ej. FACTURA'"
              [value]="'TDO_CODTDO'" [name]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'TDO_DESLAR'"
              [data]="tiposDocumento" (cambioSelect)="kardex.mac_tdore2 = $event; seleccionarTipoDoc02()"
              [model]="kardex.mac_tdore2"></select-search>
          </div>
          <div class="col s6 m6 l2" *ngIf="this.configuracionTecnica.s70_verref2 == 1">
            <mat-form-field class="example-full-width">
              <mat-label>Referencia (02)</mat-label>
              <input #iNumDocRef02 matInput placeholder="Referencia (GRE)" [(ngModel)]="kardex.mac_numre2"
                name="mac_numre2" [disabled]="loaderData" (keyup)="keyUpReferencia02()"
                (keyup.enter)="enterReferencia02()">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l2" *ngIf="indTransferencia == 1">

            <select-search [label]="'Almacén '+ ((kardex.tdo_codtdo == 'SAL') ? 'Destino': 'Origen')"
              [placeholder]="'Ej. ALMACÉN P.T.'" [value]="'ALM_CODALM'" [name]="'alm_coddes'"
              [description]="'ALM_DESCRI'" [disabled]="loaderData" [data]="almacenes"
              (cambioSelect)="kardex.alm_coddes = $event;" [model]="kardex.alm_coddes"
              [disabled]="loaderData || !!(kardex.mac_nummac)">
            </select-search>

          </div>

          <!-- <div class="col s12 m12 l1" *ngIf="kardex.tdo_codtdo == 'SAL' && indTransferencia == 1">
            <mat-form-field class="example-full-width">
              <mat-label>Año</mat-label>
              <input readonly matInput placeholder="Parte" [disabled]="loaderData || kardex.mac_nummac" [(ngModel)]="kardex.ano_coddes"
                name="ano_coddes">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l1" *ngIf="kardex.tdo_codtdo == 'SAL' && indTransferencia == 1">
            <mat-form-field class="example-full-width">
              <mat-label>Mes</mat-label>
              <input readonly matInput placeholder="Parte" [disabled]="loaderData || kardex.mac_nummac" [(ngModel)]="kardex.mes_coddes"
                name="mes_coddes">
            </mat-form-field>
          </div> -->

          <div class="col s12 m12 l1" *ngIf="indTransferencia == 1">
            <mat-form-field class="example-full-width">
              <mat-label>Tipo Doc.</mat-label>
              <input readonly matInput placeholder="Tipo Doc." [disabled]="loaderData || kardex.mac_nummac"
                [(ngModel)]="kardex.tdo_coddes" name="tdo_coddes">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l1" *ngIf="indTransferencia == 1">
            <mat-form-field class="example-full-width">
              <mat-label>Parte</mat-label>
              <input matInput maxlength="5" placeholder="Parte" [disabled]="loaderData || kardex.mac_nummac"
                [(ngModel)]="kardex.mac_numdes" name="mac_numdes">
            </mat-form-field>
          </div>


        </ng-container>
        <div class="row" *ngIf="(isMobile || isTablet) && this.configuracionTecnica.s70_verref1 == 1">
          <div class="col s6 m6 l2">
            <select-search #sTipoDocRef01 [label]="'Tip. Doc. (01)'" [placeholder]="'Ej. FACTURA'" [name]="'TDO_CODTDO'"
              [value]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'TDO_DESLAR'" [data]="tiposDocumento"
              (cambioSelect)="kardex.mac_tdore1 = $event; seleccionarTipoDoc01()" [required]="esObligatorioTipoDocRef"
              [model]="kardex.mac_tdore1"></select-search>
          </div>
          <div class="col s6 m6 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Referencia (01)</mat-label>
              <input #iNumDocRef01 matInput placeholder="Referencia (FAC)" [(ngModel)]="kardex.mac_numre1"
                name="mac_numre1" [disabled]="loaderData" (keyup)="keyUpReferencia01()"
                [required]="esObligatorioTipoDocRef" (keyup.enter)="enterReferencia01()">
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="(isMobile || isTablet) && this.configuracionTecnica.s70_verref2 == 1">
          <div class="col s6 m6 l2">
            <select-search #sTipoDocRef02 [label]="'Tip. Doc. (02)'" [placeholder]="'Ej. FACTURA'"
              [value]="'TDO_CODTDO'" [name]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'TDO_DESLAR'"
              [data]="tiposDocumento" (cambioSelect)="kardex.mac_tdore2 = $event; seleccionarTipoDoc02()"
              [model]="kardex.mac_tdore2"></select-search>
          </div>
          <div class="col s6 m6 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Referencia (02)</mat-label>
              <input #iNumDocRef02 matInput placeholder="Referencia (GRE)" [(ngModel)]="kardex.mac_numre2"
                name="mac_numre2" [disabled]="loaderData" (keyup)="keyUpReferencia02()"
                (keyup.enter)="enterReferencia02()">
            </mat-form-field>
          </div>
        </div>
        <div class="col s12 m6 l2" *ngIf="this.configuracionTecnica.s70_verccs == 1">
          <select-search #sCentroCosto [label]="'Centro de Costo'" [placeholder]="'Ej. centro'" [value]="'ccs_codccs'"
            [name]="'ccs_codccs'" [disabled]="loaderData" [description]="'description'" [data]="centroCostos"
            (cambioSelect)="kardex.ccs_codccs = $event" [model]="kardex.ccs_codccs">
          </select-search>
        </div>
        <div class="col s12 m6 l4" *ngIf="indOrdCompra == 1 && this.configuracionTecnica.s70_indocm == 1">
          <select-search #sOrdenCompra [label]="'Orden de compra'" [placeholder]="'Ej. 00000000'" [name]="'ocm_numocm'"
            [value]="'ocm_numocm'" [disabled]="loaderData" [description]="'description02'" [data]="ordenes"
            (cambioSelect)="kardex.ocm_numocm = $event; seleccionarOrdenCompra()" [model]="kardex.ocm_numocm"
            tooltipAction="Obtener Productos" iconAction="get_app" (clickAction)="obtenerProductosOrden()">
          </select-search>
        </div>

        <div class="col s12 m6 l3" *ngIf="indReqMat == 1">

          <select-search [label]="'Requerimiento Material'" [placeholder]="'Ej. COMPRAS LOCALES'" [name]="'nro_req'"
            [value]="'nro_req'" [disabled]="loaderData" [description]="'nro_req'" [data]="requerimientos"
            (cambioSelect)="kardex.rma_numrma = $event;" iconAction="get_app" tooltipAction="Obtener productos"
            (clickAction)="obtenerProductosRequerimiento()" [model]="kardex.rma_numrma">
          </select-search>

        </div>

        <div class="col s12 m6 l3" *ngIf="indLiqProd == 1">

          <select-search [label]="'Liquidación de Producción'" [placeholder]="'Ej. PT'" [name]="'Nro_liqui'"
            [value]="'Nro_liqui'" [disabled]="loaderData" [description]="'observa'" [data]="liquidaciones"
            (cambioSelect)="kardex.lfa_numlfa = $event" [model]="kardex.lfa_numlfa" tooltipAction="Obtener Productos"
            iconAction="get_app" (clickAction)="obtenerProductosLiq()"></select-search>

        </div>

        <div class="col s12 m6 l3" *ngIf="indOrdProd == 1">

          <select-search [label]="'Órden de Producción'" [placeholder]="'Ej. PT'" [name]="'Nro_liqui'"
            [value]="'Nro_liqui'" [disabled]="loaderData" [description]="'observa'" [data]="liquidaciones"
            (cambioSelect)="kardex.otr_numotr = $event" [model]="kardex.otr_numotr" tooltipAction="Obtener Productos"
            iconAction="get_app" (clickAction)="obtenerProductosLiq()"></select-search>

        </div>

        <!-- <div class="col s12 m6 l3" *ngIf="indPedido == 1">

          <select-search [label]="'Pedido'" [placeholder]="'Ej. PT'" [name]="'Nro_liqui'" [value]="'Nro_liqui'"
            [disabled]="loaderData" [description]="'observa'" [data]="liquidaciones"
            (cambioSelect)="kardex.lfa_numlfa = $event" [model]="kardex.lfa_numlfa" tooltipAction="Obtener Productos"
            iconAction="get_app" (clickAction)="obtenerProductosLiq()"></select-search>

        </div> -->

        <div class="col s12 m6 l3" *ngIf="indRegInvoice == 1">
          <select-search [label]="'#Reg. Invoice'" [placeholder]="'Ej. '" [name]="'codigo'" [value]="'codigo'"
            [disabled]="loaderData" [description]="'Documento'" [data]="invoices"
            (cambioSelect)="kardex.oim_numoim = $event" [model]="kardex.oim_numoim" tooltipAction="Obtener Productos"
            iconAction="get_app" (clickAction)="obtenerRegInvoices()"></select-search>

        </div>

        <div class="col s12 m6 l3" *ngIf="indOrdIntercambio == 1">

          <select-search [label]="'Orden de internamiento'" [placeholder]="'Ej. '" [name]="'orden_internamiento'"
            [value]="'ord'" [disabled]="loaderData" [description]="'descri'" [data]="ordenesInternamiento"
            (cambioSelect)="kardex.lfa_numlfa = $event" [model]="kardex.lfa_numlfa" tooltipAction="Obtener Productos"
            iconAction="get_app" (clickAction)="obtenerProductosOrdenInternamiento()"></select-search>

        </div>

      </div>
    </mat-card>
    <br>

    <mat-tab-group>
      <mat-tab label="Referencias" labelClass="mat-tab-label-0-0">
        <!-- <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar> -->

        <mat-card class="mat-elevation-z0">

          <div class="row">

            <div class="col s12 m12 l2">

              <select-search [label]="'Centro de Costo'" [placeholder]="'Ej. centro'" [value]="'ccs_codccs'"
                [name]="'ccs_codccs'" [disabled]="loaderData" [description]="'description'" [data]="centroCostos"
                (cambioSelect)="kardex.ccs_codccs = $event" [model]="kardex.ccs_codccs">
              </select-search>

            </div>

            <!-- <div class="col s12 m12 l2">

              <select-search [label]="'Almacén Procedencia'" [placeholder]="'Ej. ALMACÉN P.T.'" [value]="'ALM_CODALM'"
                [name]="'ALM_CODALM'" [description]="'ALM_DESCRI'"
                [disabled]="loaderData || motivoSeleccionado.mmo_indtra == 0" [data]="almacenes"
                (cambioSelect)="kardex.alm_codcom = $event; seleccionarAlmacen($event)" [model]="kardex.alm_codcom">
              </select-search>

            </div> -->
            <div class="col s12 m12 l1">
              <mat-form-field class="example-full-width">
                <mat-label>Tip. Doc.</mat-label>
                <input readonly matInput placeholder="Tip. Doc."
                  [disabled]="loaderData || motivoSeleccionado.mmo_indtra == 0" [(ngModel)]="kardex.tdo_codcom"
                  name="tdo_codcom">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l1">
              <mat-form-field class="example-full-width">
                <mat-label>Parte</mat-label>
                <input matInput maxlength="5" placeholder="Parte"
                  [disabled]="loaderData || motivoSeleccionado.mmo_indtra == 0" [(ngModel)]="kardex.mac_numcom"
                  name="mac_numcom">
              </mat-form-field>
            </div>

            <!-- <div class="col s12 m12 l2" *ngIf="tipo == 'SAL' && indTransferencia == 1">

              <select-search [label]="'Almacén Destino'" [placeholder]="'Ej. ALMACÉN P.T.'" [value]="'ALM_CODALM'"
                [name]="'ALM_CODALM'" [description]="'ALM_DESCRI'" [disabled]="loaderData" [data]="almacenes"
                (cambioSelect)="kardex.alm_coddes = $event; seleccionarAlmacen($event)" [model]="kardex.alm_coddes">
              </select-search>

            </div>

            <div class="col s12 m12 l1" *ngIf="tipo == 'SAL' && indTransferencia == 1">
              <mat-form-field class="example-full-width">
                <mat-label>Año</mat-label>
                <input readonly matInput placeholder="Parte" [disabled]="loaderData" [(ngModel)]="kardex.ano_coddes"
                  name="ano_coddes">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l1" *ngIf="tipo == 'SAL' && indTransferencia == 1">
              <mat-form-field class="example-full-width">
                <mat-label>Mes</mat-label>
                <input readonly matInput placeholder="Parte" [disabled]="loaderData" [(ngModel)]="kardex.mes_coddes"
                  name="mes_coddes">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l1" *ngIf="tipo == 'SAL' && indTransferencia == 1">
              <mat-form-field class="example-full-width">
                <mat-label>Tipo Doc.</mat-label>
                <input readonly matInput placeholder="Parte" [disabled]="loaderData" [(ngModel)]="kardex.tdo_coddes"
                  name="ano_coddes">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l1" *ngIf="tipo == 'SAL' && indTransferencia == 1">
              <mat-form-field class="example-full-width">
                <mat-label>Parte</mat-label>
                <input matInput maxlength="5" placeholder="Parte" [disabled]="loaderData"
                  [(ngModel)]="kardex.mac_numdes" name="mac_numdes">
              </mat-form-field>
            </div> -->

            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Glosa</mat-label>
                <textarea rows="3" (keyup.enter)="kardex.mac_glomac = kardex.mac_glomac + '\n'" matInput
                  placeholder="Glosa" [(ngModel)]="kardex.mac_glomac" name="mac_glomac"
                  [disabled]="loaderData"></textarea>
              </mat-form-field>
            </div>

            <!-- <div class="col s12 m12 l3" *ngIf="indOrdCompra == 1">
              <select-search [label]="'Orden de compra'" [placeholder]="'Ej. 00000000'" [name]="'CODIGO'"
                [value]="'CODIGO'" [disabled]="loaderData" [description]="'CODIGO'" [data]="ordenes"
                (cambioSelect)="kardex.ocm_numocm = $event" [model]="kardex.ocm_numocm"
                tooltipAction="Obtener Productos" iconAction="get_app" (clickAction)="obtenerProductosOrden()">
              </select-search>
            </div> -->

            <div class="col s12 m12 l3" *ngIf="indReqMat == 1">

              <select-search [label]="'Requerimiento Material'" [placeholder]="'Ej. COMPRAS LOCALES'" [name]="'nro_req'"
                [value]="'nro_req'" [disabled]="loaderData" [description]="'nro_req'" [data]="requerimientos"
                (cambioSelect)="kardex.rma_numrma = $event;" iconAction="get_app" tooltipAction="Obtener productos"
                (clickAction)="obtenerProductosRequerimiento()" [model]="kardex.rma_numrma">
              </select-search>

            </div>

            <div class="col s12 m12 l3" *ngIf="indLiqProd == 1">

              <select-search [label]="'Liquidación de Producción'" [placeholder]="'Ej. PT'" [name]="'Nro_liqui'"
                [value]="'Nro_liqui'" [disabled]="loaderData" [description]="'observa'" [data]="liquidaciones"
                (cambioSelect)="kardex.lfa_numlfa = $event" [model]="kardex.lfa_numlfa"
                tooltipAction="Obtener Productos" iconAction="get_app"
                (clickAction)="obtenerProductosLiq()"></select-search>

            </div>

            <div class="col s12 m12 l3" *ngIf="indOrdProd == 1">

              <select-search [label]="'Órden de Producción'" [placeholder]="'Ej. PT'" [name]="'Nro_liqui'"
                [value]="'Nro_liqui'" [disabled]="loaderData" [description]="'observa'" [data]="liquidaciones"
                (cambioSelect)="kardex.otr_numotr = $event" [model]="kardex.otr_numotr"
                tooltipAction="Obtener Productos" iconAction="get_app"
                (clickAction)="obtenerProductosLiq()"></select-search>

            </div>

            <div class="col s12 m12 l4" *ngIf="indPedido == 1">

              <select-search [label]="'Pedido'" [placeholder]="'Ej. 00025'" [name]="'PCC_NUMPCC'" [value]="'PCC_NUMPCC'"
                [disabled]="loaderData" [description]="'description'" [data]="pedidos"
                (cambioSelect)="kardex.pcc_numpcc = $event; seleccionarPedido()" [model]="kardex.pcc_numpcc"
                tooltipAction="Obtener Productos" iconAction="get_app"
                (clickAction)="obtenerProductosPedido()"></select-search>

            </div>

            <div class="col s12 m12 l3" *ngIf="indRegInvoice == 1">
              <select-search [label]="'#Reg. Invoice'" [placeholder]="'Ej. '" [name]="'codigo'" [value]="'codigo'"
                [disabled]="loaderData" [description]="'Documento'" [data]="invoices"
                (cambioSelect)="kardex.oim_numoim = $event" [model]="kardex.oim_numoim"
                tooltipAction="Obtener Productos" iconAction="get_app"
                (clickAction)="obtenerRegInvoices()"></select-search>

            </div>

            <div class="col s12 m12 l3" *ngIf="indOrdIntercambio == 1">

              <select-search [label]="'Orden de internamiento'" [placeholder]="'Ej. '" [name]="'orden_internamiento'"
                [value]="'ord'" [disabled]="loaderData" [description]="'descri'" [data]="ordenesInternamiento"
                (cambioSelect)="kardex.lfa_numlfa = $event" [model]="kardex.lfa_numlfa"
                tooltipAction="Obtener Productos" iconAction="get_app"
                (clickAction)="obtenerProductosOrdenInternamiento()"></select-search>

            </div>

          </div>
        </mat-card>

        <br>

      </mat-tab>
      <mat-tab label="Guía de remisión"
        *ngIf="(configuracionTecnica.s70_guipin == 1 || configuracionTecnica.s70_guipsa == 1) && indGuiaRemision == 1"
        labelClass="mat-tab-label-0-1">
        <!-- <mat-tab label="Guía de remisión" [disabled]="kardex.tdo_codtdo == 'ING'" labelClass="mat-tab-label-0-1"> -->

        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s6 m12 l1">
              <select-search [label]="'Código'" [placeholder]="'Ej. FAC (factura)'" [name]="'tdo_codtdo'"
                [value]="'TDO_CODTDO'" [description]="'TDO_CODTDO'" [data]="tiposDocumento"
                (cambioSelect)="kardex.detalle_guia[0].tdo_codtdo = $event" [model]="kardex.detalle_guia[0].tdo_codtdo"
                [disabled]="true">
              </select-search>
            </div>
            <div class="col s6 m12 l1">

              <mat-form-field>
                <mat-label>Serie</mat-label>
                <mat-select [disabled]="kardex.mac_nummac" (selectionChange)="seleccionarSerie()" [name]="'sdo_codsdo'"
                  [(ngModel)]="kardex.detalle_guia[0].sdo_codsdo">
                  <mat-option *ngFor=" let serie of series " [value]="serie.serie" (click)="seleccionarSerie()">
                    {{serie.serie}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Número</mat-label>
                <!-- este campo es la separacion de la serie y el numero (grc_numdoc = sdo_codsdo + correlativo) -->
                <input [disabled]="kardex.mac_nummac" #numeroDocGuia type="text"
                  [(ngModel)]="kardex.detalle_guia[0].grc_numdoc" matInput placeholder="Ej. GRE" [name]="'grc_numdoc'"
                  [maxlength]="14" (keyup.enter)="completarNroDoc(); $event.preventDefault()" />
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Fecha emisión</mat-label>
                <input matInput [matDatepicker]="fechaRemision" [(ngModel)]="kardex.detalle_guia[0].grc_fecdoc"
                  name="grc_fecdoc" required>
                <mat-datepicker-toggle matSuffix [for]="fechaRemision"></mat-datepicker-toggle>
                <mat-datepicker #fechaRemision></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Fecha de traslado</mat-label>
                <input matInput [matDatepicker]="fectra" [(ngModel)]="kardex.detalle_guia[0].grc_fectra"
                  [min]="kardex.detalle_guia[0].grc_fecdoc" name="grc_fectra" required />
                <mat-datepicker-toggle matSuffix [for]="fectra"></mat-datepicker-toggle>
                <mat-datepicker #fectra></mat-datepicker>
              </mat-form-field>
            </div>

          </div>
          <div class="row">

            <div class="col s12 m12 l4">
              <select-search [label]="'Destinatario'" [placeholder]="'Ej. CANASTA'" [name]="'cli_codcli_dest'"
                [value]="'cli_codcli'" [description]="'description'" [data]="clientes"
                (cambioSelect)="kardex.detalle_guia[0].cli_codcli = $event; seleccionarDestinatario()"
                [model]="kardex.detalle_guia[0].cli_codcli">
              </select-search>

            </div>
            <div class="col s12 m12 l4">
              <select-search [label]="'Transportista'" [placeholder]="'Ej. CANASTA'" [name]="'cli_codtra'"
                [value]="'cli_codcli'" [description]="'description'" [data]="transportistas"
                (cambioSelect)="kardex.detalle_guia[0].cli_codtra = $event; seleccionarTransportista()"
                [model]="kardex.detalle_guia[0].cli_codtra">
              </select-search>

            </div>
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Unid. Trans.</mat-label>
                <mat-select [(ngModel)]="kardex.detalle_guia[0].utr_codutr" name="utr_codutr"
                  (selectionChange)="seleccionarUnidTransporte($event.value)" required>
                  <mat-option *ngFor="let unidad of unidades" [value]="unidad.UTR_CODUTR">
                    {{unidad.UTR_DESCRI}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Certif. de inscripción</mat-label>
                <input matInput placeholder="Nro. Certif." [(ngModel)]="kardex.detalle_guia[0].grc_nrocer"
                  name="grc_nrocer" required>
              </mat-form-field>
            </div>
            <!-- <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Control Desp.</mat-label>
                <input matInput placeholder="Nro. Control Desp." [(ngModel)]="kardex.detalle_guia[0].grc_plaund" name="grc_plaund"
                  required>
              </mat-form-field>
            </div> -->
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Marca</mat-label>
                <input matInput placeholder="Marca" [(ngModel)]="kardex.detalle_guia[0].grc_marund" name="grc_marund"
                  required>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. de Placa</mat-label>
                <input matInput placeholder="Placa" [(ngModel)]="kardex.detalle_guia[0].grc_plaund" name="grc_plaund"
                  required>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. de Placa Secundario</mat-label>
                <input matInput placeholder="Placa" [(ngModel)]="kardex.detalle_guia[0].grc_placa2" name="grc_placa2">
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col s12 m12 l3">
              <select-search [label]="'Chofer'" [placeholder]="'Ej. CHOFER'" [value]="'CHO_CODCHO'"
                [name]="'cho_codchos'" [description]="'CHO_NOMBRE'" [data]="conductores"
                (cambioSelect)="kardex.detalle_guia[0].cho_codcho = $event; seleccionarChofer($event)"
                [model]="kardex.detalle_guia[0].cho_codcho">
              </select-search>
              <!-- <select-search #sAlmacen [label]="'Almacén'" [placeholder]="'Ej. ALMACÉN P.T.'" [value]="'ALM_CODALM'"
                [name]="'ALM_CODALM'" [description]="'ALM_DESCRI'" [disabled]="loaderData" [data]="almacenes"
                (cambioSelect)="kardex.alm_codalm = $event; seleccionarAlmacen($event)" [model]="kardex.alm_codalm">
              </select-search> -->
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. de Licencia </mat-label>
                <input matInput placeholder="Licencia" [(ngModel)]="kardex.detalle_guia[0].grc_nrolic" name="grc_nrolic"
                  required>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="configuracionTecnica.s70_indent == 1">
              <mat-form-field>
                <mat-label>Fecha entrega Transportista</mat-label>
                <input matInput [matDatepicker]="fechaEntregaTransportista">
                <mat-datepicker-toggle matSuffix [for]="fechaEntregaTransportista"></mat-datepicker-toggle>
                <mat-datepicker #fechaEntregaTransportista></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- <div class="col s12 m12 l3">
              <select-search [label]="'Agencia Transportista'" [placeholder]="'Ej. AGENCIA'" [value]="'cli_codcli'"
                [name]="'cli_agetra'" [description]="'cli_nomcli'" [data]="agenciaTransportes"
                (cambioSelect)="kardex.detalle_guia[0].cli_agetra = $event; seleccionarAgenciasTransportista();"
                [model]="kardex.detalle_guia[0].cli_agetra">
              </select-search>

            </div> -->
          </div>
          <div class="row">
            <div class="col s12 m12 l4">
              <select-search [label]="'Auxiliar punto de partida'" [placeholder]="'Ej. GARCÍA'" [value]="'cli_codcli'"
                [name]="'cli_codppt'" [disabled]="loaderData" [description]="'description'" [data]="clientes"
                (cambioSelect)="kardex.detalle_guia[0].cli_codppt = $event; seleccionarAuxiliarPuntoPartida()"
                [model]="kardex.detalle_guia[0].cli_codppt">
              </select-search>

            </div>

            <div class="col s12 m12 l4" *ngIf="kardex.detalle_guia[0].cli_codppt != '20605493603'">
              <mat-form-field>
                <mat-label>Dirección punto de partida</mat-label>
                <!-- lde_pptaux si es diferente a inka-->
                <mat-select [(ngModel)]="kardex.detalle_guia[0].lde_codppt" name="lde_codppt" required>
                  <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                    {{lugar.LDE_DIRLDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4" *ngIf="kardex.detalle_guia[0].cli_codppt == '20605493603'">
              <mat-form-field>
                <mat-label>Dirección punto de partida</mat-label>
                <!-- lde_pptaux -->
                <mat-select [(ngModel)]="kardex.detalle_guia[0].ldc_codldc" name="ldc_codldc" required>
                  <mat-option *ngFor="let lugar of lugaresDespachoCIA" [value]="lugar.LDC_CODLDC">
                    {{lugar.LDC_DIRLDC}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Dirección punto de llegada</mat-label>
                <mat-select [(ngModel)]="kardex.detalle_guia[0].lde_codlde" name="lde_codlde" required>
                  <mat-option *ngFor="let lugar of lugaresDespachoDesti" [value]="lugar.LDE_CODLDE">
                    {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-form-field *ngIf="lugaresDespacho.length == 0">
                <mat-label>Dirección punto de llegada</mat-label>
                <input [(ngModel)]="kardex.detalle_guia[0].grc_lugent" name="grc_lugent" matInput placeholder="Ex. Av. Proceres 102">
              </mat-form-field> -->
            </div>
          </div>

          <div class="row">
            <div class="col s12 m12 l6">
              <div class="row">
                <!-- <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Dirección transportista</mat-label>
                    <mat-select [(ngModel)]="kardex.detalle_guia[0].lde_codtra" name="lde_codtra">
                      <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                        {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div> -->
                <!-- <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Dirección agencia</mat-label>
                    <mat-select [(ngModel)]="kardex.detalle_guia[0].lde_codage" name="lde_codage">
                      <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                        {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div> -->

                <div class="col s12 m12 l6">
                  <!-- <mat-checkbox class="example-margin">Invertir dirección</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Mismo punto de llegada</mat-checkbox>
                  &nbsp; -->
                  <mat-checkbox class="example-margin">Compra</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Transporte M1L</mat-checkbox>
                </div>
                <div class="col s12 m12 l6">
                  <mat-form-field>
                    <mat-label>Peso Bruto</mat-label>
                    <input type="number" [(ngModel)]="kardex.detalle_guia[0].grc_pesbru" matInput placeholder="Ej. GRE"
                      [name]="'grc_pesbru'" required />
                  </mat-form-field>
                </div>
              </div>

            </div>
            <div class="col s12 m12 l6">
              <fieldset [ngStyle]="{borderRadius: '10px', borderColor: '#5ebcc4', border: '2px solid #6dd6df'}"
                *ngIf="indMotivoExportacion == 1">
                <legend [ngStyle]="{color: '#5ebcc4'}">Referencia Exportación</legend>
                <div class="row">
                  <div class="col s12 m12 l6" *ngIf="indMotivoExportacion == 1">
                    <select-search [label]="'Código aeropuerto'" [placeholder]="'Ej. AGENCIA'" [value]="'t64_codt64'"
                      [name]="'t64_codt64'" [description]="'t64_descri'" [data]="aeropuertos"
                      (cambioSelect)="kardex.detalle_guia[0].t64_codt64 = $event;"
                      [model]="kardex.detalle_guia[0].t64_codt64">
                    </select-search>
                  </div>
                  <div class="col s12 m12 l6" *ngIf="indMotivoExportacion == 1">
                    <mat-form-field>
                      <mat-label>Bultos o Pallets</mat-label>
                      <input type="number" [(ngModel)]="kardex.detalle_guia[0].grc_numbul" matInput
                        placeholder="Ej. GRE" [name]="'grc_numbul'" />
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l6" *ngIf="indMotivoExportacion == 1">

                    <select-search [label]="'Cod. Ref. Exp.'" [placeholder]="'Ej. ABC'" [name]="'grc_codexp'"
                      [value]="'TDO_CODTDO'" [description]="'TDO_DESLAR'" [data]="tiposDocumentoGuia"
                      (cambioSelect)="kardex.detalle_guia[0].grc_codexp = $event;"
                      [model]="kardex.detalle_guia[0].grc_codexp">
                    </select-search>

                  </div>
                  <div class="col s12 m12 l6" *ngIf="indMotivoExportacion == 1">
                    <mat-form-field>
                      <mat-label>Doc. Ref. Exp.</mat-label>
                      <input type="text" [(ngModel)]="kardex.detalle_guia[0].grc_numexp" matInput placeholder="Ej. GRE"
                        [name]="'grc_numexp'" />
                    </mat-form-field>
                  </div>
                </div>

              </fieldset>

            </div>

          </div>

          <div [ngStyle]="{display: 'flex', justifyContent: 'space-between'}">

            <div [ngStyle]="{width: '50%'}">
              <mat-form-field class="example-full-width" *ngIf="indMotivoExportacion == 1">
                <mat-label>Glosa</mat-label>
                <textarea (keyup.enter)="kardex.detalle_guia[0].grc_obsgen = kardex.detalle_guia[0].grc_obsgen + '\n'"
                  matInput placeholder="Glosa" rows="7" [(ngModel)]="kardex.detalle_guia[0].grc_obsgen"
                  name="grc_obsgen" [disabled]="loaderData"></textarea>
              </mat-form-field>
            </div>

            <div [ngStyle]="{display: 'flex', marginTop: '5px'}">
              <div>
                <button mat-button type="button" matTooltip="Ver PDF" (click)="verPDF()"
                  class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#EB6945'}">
                  <mat-icon svgIcon="pdf"></mat-icon>
                </button>
                <p class="pav-tag-button" *ngIf="isMobile">Ver PDF</p>
              </div>

              <div>
                <button mat-button type="button" matTooltip="Liberar guía"
                  class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#2360FF'}">
                  <mat-icon svgIcon="formato"></mat-icon>
                </button>
                <p class="pav-tag-button" *ngIf="isMobile">Liberar</p>
              </div>
              <!-- <div [ngStyle]="{marginLeft: '5px'}">
                <button [class.spinner]="loaderRegGuia" mat-button matTooltip="Grabar guía vinculada"
                  [disabled]="loaderRegGuia" class="mat-elevation-z4 pav-button-action-general"
                  [ngStyle]="{backgroundColor: '#00205F'}" type="submit">
                  <mat-icon svgIcon="grabar" *ngIf="!loaderRegGuia"></mat-icon>
                </button>
                <p class="pav-tag-button" *ngIf="isMobile">Grabar</p>
              </div> -->
            </div>
          </div>

          <!-- <div class="row">


            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Glosa</mat-label>
                <input matInput placeholder="Glosa" [(ngModel)]="kardex.detalle_guia[0].grc_obsgrc" name="grc_obsgrc"
                  required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l5">
              <button mat-flat-button color="primary" type="button" disabled>Liberar Guía</button>
              &nbsp;
              <button mat-flat-button color="primary" type="button">Ingresar Guía</button>
            </div>


          </div> -->
        </mat-card>
      </mat-tab>
      <mat-tab label="Consulta Stock" labelClass="mat-tab-label-0-2">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l3">
              <select-search [disabled]="true" [label]="'Almacén'" [placeholder]="'Ej. ALMACÉN P.T.'"
                [value]="'ALM_CODALM'" [name]="'ALM_CODALM'" [description]="'ALM_DESCRI'" [data]="almacenes"
                (cambioSelect)="kardex.alm_codalm = $event; seleccionarAlmacen($event)" [model]="kardex.alm_codalm">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <select-search [disabled]="true" [label]="'Producto'" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd'"
                [value]="'prd_codprd'" [description]="'DESC_ESPA'" [data]="productos"
                (cambioSelect)="productoSeleccionado.prd_codprd = $event" [model]="productoSeleccionado.prd_codprd">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Stock Disponible</mat-label>
                <input readonly type="number" [ngStyle]="{textAlign: 'right'}" [(ngModel)]="stockDisponible"
                  name="stockDisponible" matInput placeholder="Stock Disponible">
              </mat-form-field>

            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Adicionales" labelClass="mat-tab-label-0-3" *ngIf="configuracionTecnica.s70_zonadi == 1">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">

              <select-search [label]="'Ubicación'" [placeholder]="'Ej. '" [name]="'ubs_codubs'" [value]="'ubs_codubs'"
                [disabled]="loaderData" [description]="'ubs_descri'" [data]="ubigeosSunat"
                (cambioSelect)="ubicacionAdicional = $event; seleccionarUbicacionAdicional()"
                [model]="ubicacionAdicional"></select-search>

            </div>
            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Ubigeo Glosa 2</mat-label>
                <textarea rows="3" (keyup.enter)="kardex.mac_gloma2 = kardex.mac_gloma2 + '\n'" matInput
                  placeholder="Glosa" [(ngModel)]="kardex.mac_gloma2" name="mac_gloma2"
                  [disabled]="loaderData"></textarea>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <button mat-button type="button" matTooltip="Ver Formato Guia 2" (click)="verFormatoGuia2()"
                class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#EB6945'}">
                <mat-icon svgIcon="pdf"></mat-icon>
              </button>
              <p class="pav-tag-button" *ngIf="isMobile">Ver PDF</p>
            </div>
          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

    <br>
    <div (paste)="onPaste($event)">
      <div [ngStyle]="{display: 'flex', justifyContent: 'space-between'}">
        <div [ngStyle]="{display: 'flex', alignItems: 'center', marginBottom: '5px'}">
          <mat-icon matTooltip="Seleccionar tabla para pegar datos copiados desde Excel">info_outline</mat-icon>

          <div type="button" [ngStyle]="{cursor: 'pointer', fontSize: '14px', color: '#4792f9', marginLeft: '5px'}"
            (click)="seleccionarTabla()" color="primary">
            Seleccionar tabla
          </div>

        </div>
        <div>

          <a type="button" mat-button color="primary"
            href="https://docs.google.com/spreadsheets/d/1fmLMbvlsh_e9tFJZ3381sNDGHDkVf6RQ/edit?usp=sharing&ouid=108595286027340309141&rtpof=true&sd=true"
            target="_blank">Descargar plantilla</a>

        </div>
      </div>
      <div [ngStyle]="{border: tablaSeleccionada ? '3px solid #32b17b': '', borderRadius: '18px'}">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            Productos
          </div>
          &nbsp;
          &nbsp;
          <div>
            <div class="pav-button-icon-add-modal" matTooltip="Agregar ítem" (click)="agregarProductoModal()"
              [ngStyle]="{display: loaderData? 'none': ''}">
              <mat-icon svgIcon="add-modal-detail"></mat-icon>
            </div>
            <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
              <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
            </p>
          </div>
          &nbsp;
          <div>
            <div class="pav-button-icon-add-multiple" matTooltip="Agregar múltiple" (click)="agregarProductoMultiple()"
              [ngStyle]="{display: loaderData? 'none': ''}">
              <mat-icon [ngStyle]="{color: 'white'}">library_add</mat-icon>
            </div>
            <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
              <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
            </p>
          </div>
          &nbsp;
          <div>
            <button-add-item-table (addItem)="agregarProducto()"></button-add-item-table>

            <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
              <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
            </p>
          </div>
          &nbsp;
          <div>
            <button-remove-item-table (removeItem)="eliminarProducto()"></button-remove-item-table>

            <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
              <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
            </p>
          </div>
          &nbsp;
          <div>
            <button-insert-item-table (insertItem)="insertarProducto()"></button-insert-item-table>
            <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
              <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Insertar</span>
            </p>
          </div>
        </div>

        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
                <div [ngStyle]="{display: 'flex'}" (contextmenu)="openMenu($event)" #selectContainer>
                  <div [ngStyle]="{width: '500px'}">
                    <select-search
                      [clase]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                      [label]="''" [placeholder]="'Ej. CANASTA'" [name]="'prd_descri' + row.map_cormap"
                      [value]="'prd_codprd'" [disabled]="loaderData" [description]="'description'" [data]="productos"
                      (cambioSelect)="row.prd_codprd = $event; seleccionarProducto(row)" [model]="row.prd_codprd">
                    </select-search>
                  </div>
                  <!-- TODO: NO ELIMINAR <div [ngStyle]="{paddingTop: '10px', marginLeft: '5px', marginRight: '5px'}"
                    matTooltip="Ver imagen del producto">
                    <mat-icon (click)="verImagen(row.prd_codprd)"
                      [ngStyle]="{cursor: 'pointer'}">photo_camera</mat-icon>
                  </div> -->
                  <mat-menu #contextMenu="matMenu">
                    <button mat-menu-item (click)="verImagen(row.prd_codprd)">Ver imágen del producto</button>
                  </mat-menu>

                  <button #contextMenuTrigger [matMenuTriggerFor]="contextMenu" style="display: none;"></button>

                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="codigo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <mat-form-field [class.mat-form-field-row]="row === selected" [ngStyle]="{width:'100px'}"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. AR354" [(ngModel)]="row.prd_codprd"
                    [name]="'prd_codprd' + row.map_cormap" readonly>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="um">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width:'50px'}">
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. UND" readonly [(ngModel)]="row.prd_codume"
                    [name]="'prd_codume' + row.map_cormap">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad</th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
                <mat-form-field [class.mat-form-field-row]="row === selected" [ngStyle]="{width:'70px'}"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input (focus)="selectContent($event)" [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput
                    placeholder="Ej. 10" [(ngModel)]="row.map_canprd" [name]="'map_canprd' + row.map_cormap"
                    [readonly]="selected && selected.prd_indlot == 1" (keyup)="establecerCantidad(row)">
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="ume_codven" *ngIf="configuracionTecnica.s70_unime2 == 1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. 2 </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa">
                <div [ngStyle]="{width:'90px'}">
                  <select-search
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                    [label]="''" [placeholder]="''" [name]="'ume_codven' + row.map_cormap" [value]="'ume_codume'"
                    [disabled]="loaderData" [description]="'ume_descri'" [data]="unidadesMedida"
                    (cambioSelect)="row.ume_codven = $event; establecerCantidad(row)" [model]="row.ume_codven">
                  </select-search>
                </div>

              </td>
            </ng-container>
            <ng-container matColumnDef="map_canven" *ngIf="configuracionTecnica.s70_unime2 == 1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad 2 </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa">
                <mat-form-field [ngStyle]="{width:'80px'}" [class.mat-form-field-row]="row === selected"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input (focus)="selectContent($event)" matInput placeholder="Ej. 0" [(ngModel)]="row.map_canven"
                    [name]="'map_canven' + row.map_cormap" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </td>
            </ng-container>


            <ng-container matColumnDef="costo_ume">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo U.M.E. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Costo U.M.E.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width:'100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 10"
                    (focus)="selectContent($event)" (keyup)="establecerCostoME(row)" [(ngModel)]="row.map_cosmep"
                    [name]="'map_cosmep' + row.map_cormap" step="any">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="costo_umn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo U.M.N. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Costo U.M.N.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width:'100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 10"
                    (focus)="selectContent($event)" [(ngModel)]="row.map_cosmnp" [name]="'map_cosmnp' + row.map_cormap"
                    step="any" (keyup)="establecerCostoMN(row)">
                </mat-form-field>
              </td>
            </ng-container>



            <ng-container matColumnDef="imp_me_prod">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. ME Prod. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. ME Prod.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width:'100px'}">
                  <mat-label></mat-label>
                  <input readonly [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 100"
                    [(ngModel)]="row.map_impmep" [name]="'map_impmep' + row.map_cormap" step="any">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="imp_mn_prod">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. MN Prod. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. MN Prod.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width:'100px'}">
                  <mat-label></mat-label>
                  <input readonly [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 10"
                    [(ngModel)]="row.map_impmnp" [name]="'map_impmnp' + row.map_cormap" step="any">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="glosa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. Observaciones" [(ngModel)]="row.map_glomap"
                    [name]="'map_glomap' + row.map_cormap">
                </mat-form-field>
              </td>
            </ng-container>


            <!-- <ng-container matColumnDef="lote_ref_pro">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote Ref. Pro. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Lote Ref. Pro.">
                <mat-form-field [class.mat-form-field-row]="row === selected" [ngStyle]="{width:'150px'}"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. 2222" [(ngModel)]="row.map_lotmap"
                    [name]="'map_lotmap' + row.map_cormap">
                </mat-form-field>
              </td>
            </ng-container> -->

            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Item">
                <mat-form-field [class.mat-form-field-row]="row === selected" [ngStyle]="{width:'60px'}"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. COR" [(ngModel)]="row.map_cormap"
                    [name]="'map_cormap' + row.map_cormap" readonly>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="sec">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Sec">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width:'60px'}">
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. 1111" [(ngModel)]="row.map_secmap" (focus)="selectContent($event)"
                    [name]="'map_secmap' + row.map_cormap" readonly>
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
              (click)="selectRow(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="14">No se encontraron registros</td>
            </tr>

          </table>
        </div>
      </div>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>

    <ng-container *ngIf="configuracionTecnica.s70_indtal == 1">
      <ng-container *ngIf="selected">
        <ng-container *ngIf="selected.prd_indtal == 1">

          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">
            </div>
            <div class="pav-table-header-title">
              Tallas
            </div>
            &nbsp;
            &nbsp;
            <button-add-item-table (addItem)="agregarTalla()"></button-add-item-table>

            &nbsp;
            <button-remove-item-table (removeItem)="eliminarTalla()"></button-remove-item-table>

            &nbsp;
            <button-insert-item-table (insertItem)="insertarTalla()"></button-insert-item-table>

          </div>

          <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSourceTalla" matSort>
              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarTalla()">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </div>
                    <div [ngStyle]="{marginLeft: '15px'}">
                      <mat-checkbox [(ngModel)]="row.seleccionado" [name]="'seleccionado' + i" class="example-margin"
                        (change)="seleccionarTalla($event.checked, row)"></mat-checkbox>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Item">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'60px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input matInput placeholder="Ej. UND" [value]="row.tal_codtal" [name]="'tal_codtal' + i" readonly>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="codigo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'80px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input matInput placeholder="Ej. UND" [(ngModel)]="row.prd_codprd" [name]="'prd_codprd' + i"
                      readonly>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
                  <div [ngStyle]="{marginTop: '5px'}">
                    <select-search
                      [clase]="row === selectedTalla? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
                      [label]="''" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd' + i" [value]="'prd_codprd'"
                      [disabled]="loaderData" [description]="'DESC_ESPA'" [data]="productos"
                      (cambioSelect)="row.prd_codprd = $event" [model]="row.prd_codprd">
                    </select-search>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="um">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'100px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input matInput placeholder="Ej. UND" [(ngModel)]="row.ume_codume" [name]="'ume_codume' + i">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="cantidad_oc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad O.C. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad O.C.">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'150px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input type="number" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 10" min="0"
                      [(ngModel)]="row.map_canocc" [name]="'map_canocc' + i">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad</th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'150px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 10"
                      [(ngModel)]="row.map_canprd" [name]="'map_canprd' + i">
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="lote_ref_pro">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote Ref. Pro. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Lote Ref. Pro.">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'150px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input matInput placeholder="Ej. 2222" [(ngModel)]="row.map_lotmap2" [name]="'map_lotmap2' + i">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="costo_ume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo U.M.E. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Costo U.M.E.">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'150px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 10"
                      [(ngModel)]="row.map_cosmep" [name]="'map_cosmep' + i" step="any">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="costo_umn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo U.M.N. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Costo U.M.N.">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'150px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 10"
                      [(ngModel)]="row.map_cosmnp" [name]="'map_cosmnp' + i" step="any">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="sec">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Sec">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'150px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input matInput placeholder="Ej. 1111" [(ngModel)]="row.map_secmap" [name]="'map_secmap' + i">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="imp_me_prod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. ME Prod. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. ME Prod.">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'150px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 100"
                      [(ngModel)]="row.map_impmep" [name]="'map_impmep' + i" step="any">
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="imp_mn_prod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. MN Prod. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. MN Prod.">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla" [ngStyle]="{width:'150px'}"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" matInput placeholder="Ej. 10"
                      [(ngModel)]="row.map_impmnp" [name]="'map_impmnp' + i" step="any">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="glosa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa">
                  <mat-form-field [class.mat-form-field-row]="row === selectedTalla"
                    class="form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input matInput placeholder="Ej. Observaciones" [(ngModel)]="row.map_glomap"
                      [name]="'map_glomap' + i">
                  </mat-form-field>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTalla"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTalla;"
                [class.selected-row]="row === selectedTalla" (click)="selectRowTalla(row)"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="14">No se encontraron registros</td>
              </tr>

            </table>
          </div>

          <mat-paginator #paginatorTalla showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        </ng-container>
      </ng-container>
    </ng-container>

    <br>
    <div class="row">
      <div class="col s12 m12 l6" *ngIf="configuracionTecnica.s70_indlot == 1">

        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            Lotes
          </div>
          &nbsp;
          &nbsp;
          <ng-container *ngIf="selected">

            <div *ngIf="kardex.tdo_codtdo == 'SAL'" class="pav-button-icon-add"
              [matTooltip]="selected.prd_indlot == 1 ? 'Agregar lote masivo': ''"
              [ngStyle]="{cursor: selected.prd_indlot == 1 ? 'pointer': '', display: loaderData? 'none': '', background: selected.prd_indlot == 1 ? 'rgb(99, 201, 154)': 'rgb(99, 201, 154, .5)'}"
              (click)="agregarLoteMasivo()">
              <mat-icon svgIcon="add-lote"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-add" [matTooltip]="selected.prd_indlot == 1 ? 'Agregar lote': ''"
              [ngStyle]="{cursor: selected.prd_indlot == 1 ? 'pointer': '', display: loaderData? 'none': '', background: selected.prd_indlot == 1 ? 'rgb(247, 201, 27)': 'rgb(247, 201, 27, .5)'}"
              (click)="agregarLote()">
              <mat-icon svgIcon="add-circle"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-delete" [matTooltip]="selected.prd_indlot == 1 ? 'Eliminar lote': ''"
              (click)="eliminarLote()"
              [ngStyle]="{display: loaderData? 'none': '', background: selected.prd_indlot == 1 ? 'rgb(235, 105, 69)': 'rgb(235, 105, 69, .5)'}">
              <mat-icon svgIcon="remove"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-insert" [matTooltip]="selected.prd_indlot == 1 ? 'Insertar lote': ''"
              (click)="insertarLote()"
              [ngStyle]="{display: loaderData? 'none': '', background: selected.prd_indlot == 1 ? 'rgb(35, 96, 255)': 'rgb(35, 96, 255, .5)' }">
              <mat-icon svgIcon="add-outline"></mat-icon>
            </div>
          </ng-container>
        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSourceLote" matSort>

            <ng-container matColumnDef="lote">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container>
                  <mat-form-field [class.mat-form-field-row]="row === selectedLote" [ngStyle]="{width: '195px'}"
                    class="form-field-small form-field-cell">
                    <input [readonly]="kardex.mmo_codmmo == '0171'" matInput placeholder="Lote"
                      [(ngModel)]="row.mlo_numlot" [name]="'mlo_numlot'+i" required>
                  </mat-form-field>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="pais">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">

                <div [ngStyle]="{width: '80px'}"
                  *ngIf="kardex.mmo_codmmo == '0173' && selected.tipoInventario == '01' ">

                  <select-search [disabled]="!!kardex.mac_nummac || loaderData" [label]="''" [placeholder]="'Ej. PERÚ'"
                    [value]="'PAI_CODPAI'"
                    [clase]="row === selectedLote? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                    [name]="'PAI_CODPAI' + i" [description]="'description_reverse'" [data]="paises"
                    (cambioSelect)="row.pai_codpai = $event; seleccionarPais(row)" [model]="row.pai_codpai">
                  </select-search>

                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Cliente">
                <mat-form-field [ngStyle]="{width: '60px'}" [class.mat-form-field-row]="row === selectedLote"
                  class="form-field-small form-field-cell">
                  <input (focus)="selectContent($event)" [ngStyle]="{textAlign: 'right'}" matInput type="number" min="0"
                    placeholder="Cantidad" [(ngModel)]="row.mlo_canprd" [name]="'mlo_canprd'+i"
                    (keyup)="establecerCantidadLote()" required />
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="fec_fabric">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Ingreso </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                <mat-form-field [ngStyle]="{width: '130px'}" [class.mat-form-field-row]="row === selectedLote"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput [matDatepicker]="pickerFab" [(ngModel)]="row.mlo_fecfab" [name]="'mlo_fecfab'+i">
                  <mat-datepicker-toggle matSuffix [for]="pickerFab"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFab></mat-datepicker>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="fec_vcto">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vcto. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">

                <mat-form-field [ngStyle]="{width: '130px'}" [class.mat-form-field-row]="row === selectedLote"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput [min]="row.mlo_fecfab" [matDatepicker]="pickerVcto" [(ngModel)]="row.mlo_fecven"
                    [name]="'mlo_fecven'+i">
                  <mat-datepicker-toggle matSuffix [for]="pickerVcto"></mat-datepicker-toggle>
                  <mat-datepicker #pickerVcto></mat-datepicker>
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="observacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia / Texto </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                <mat-form-field [ngStyle]="{width: '200px'}" [class.mat-form-field-row]="row === selectedLote"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <textarea matInput placeholder="Observación" [(ngModel)]="row.mlo_glomlo"
                    [name]="'mlo_glomlo'+i"></textarea>
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsLote"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsLote;" [class.selected-row]="row === selectedLote"
              (click)="selectRowLote(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6">No se encontraron registros</td>
            </tr>

          </table>

        </div>
        <mat-paginator #paginatorLote [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        <br>
      </div>
      <div class="col s12 m12 l6" *ngIf="configuracionTecnica.s70_indser == 1">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            Series
          </div>
          &nbsp;
          &nbsp;
          <ng-container *ngIf="selected">
            <div class="pav-button-icon-add" [matTooltip]="selected.prd_indser == 1 ? 'Agregar serie': ''"
              (click)="agregarSerie()"
              [ngStyle]="{display: loaderData? 'none': '', background: selected.prd_indser == 1 ? 'rgb(247, 201, 27)': 'rgb(247, 201, 27, .5)'}">
              <mat-icon svgIcon="add-circle"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-delete" [matTooltip]="selected.prd_indser == 1 ? 'Eliminar serie': ''"
              (click)="eliminarSerie()"
              [ngStyle]="{display: loaderData? 'none': '', background: selected.prd_indser == 1 ? 'rgb(235, 105, 69)': 'rgb(235, 105, 69, .5)'}">
              <mat-icon svgIcon="remove"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-insert" [matTooltip]="selected.prd_indser == 1 ? 'Insertar serie': ''"
              (click)="insertarSerie()"
              [ngStyle]="{display: loaderData? 'none': '', background: selected.prd_indser == 1 ? 'rgb(35, 96, 255)': 'rgb(35, 96, 255, .5)' }">
              <mat-icon svgIcon="add-outline"></mat-icon>
            </div>
          </ng-container>
        </div>

        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSourceSerie" matSort>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                <div class="pav-btns-container">
                  <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarSerie()">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="serie">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie /Pasaporte </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <mat-form-field [class.mat-form-field-row]="row === selectedSerie"
                  class="form-field-small form-field-cell">
                  <mat-label>Serie /Pasaporte</mat-label>
                  <input matInput placeholder="Serie /Pasaporte" [(ngModel)]="row.spr_numser" [name]="'spr_numser' + i"
                    required>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="fec_fabric">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Ingreso </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Cliente">
                <mat-form-field [class.mat-form-field-row]="row === selectedSerie"
                  class="form-field-small form-field-cell">
                  <mat-label>Fecha Fabricación</mat-label>
                  <input matInput [matDatepicker]="pickerFab" [(ngModel)]="row.mas_fecfab" [name]="'fechaFab'+i">
                  <mat-datepicker-toggle matSuffix [for]="pickerFab"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFab></mat-datepicker>
                </mat-form-field>
              </td>

            </ng-container>

            <ng-container matColumnDef="fec_vcto">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. de Vcto. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                <mat-form-field [class.mat-form-field-row]="row === selectedSerie"
                  class="form-field-small form-field-cell">
                  <mat-label>Fecha Vencimiento</mat-label>
                  <input matInput [matDatepicker]="pickerVcto" [(ngModel)]="row.mas_fecven" [name]="'mas_fecven'+i">
                  <mat-datepicker-toggle matSuffix [for]="pickerVcto"></mat-datepicker-toggle>
                  <mat-datepicker #pickerVcto></mat-datepicker>
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsSerie"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsSerie;"
              [class.selected-row]="row === selectedSerie" (click)="selectRowSerie(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros</td>
            </tr>

          </table>
        </div>
        <mat-paginator #paginatorSerie [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>

    <mat-card class="mat-elevation-z0">

      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Imp. MN Prod.</mat-label>
            <input matInput min="0" placeholder="Imp. MN Prod." type="number" [ngStyle]="{textAlign: 'right'}" readonly
              [(ngModel)]="importeTotalMN" name="importeTotalMN">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Cost. Uni. MN</mat-label>
            <input matInput min="0" placeholder="Cost. Uni. MN" type="number" [ngStyle]="{textAlign: 'right'}" readonly
              [(ngModel)]="costoTotalMN" name="costoTotalMN">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Imp. ME Prod.</mat-label>
            <input matInput min="0" placeholder="Imp. ME Prod." type="number" [ngStyle]="{textAlign: 'right'}" readonly
              [(ngModel)]="importeTotalME" name="importeTotalME">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Cost. Uni. ME</mat-label>
            <input matInput min="0" placeholder="Cost. Uni. ME" type="number" [ngStyle]="{textAlign: 'right'}" readonly
              [(ngModel)]="costoTotalME" name="costoTotalME">
          </mat-form-field>
        </div>

      </div>

    </mat-card>

    <!-- <code>{{kardex | json}}</code> -->
  </div>

  <!-- {{this.selected | json}} -->
  <pav-form-actions [disabled]="loaderData" [permiso]="permiso" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-almacen/parte-de-ingreso" (clickReset)="refrescarPagina()" (clickFormato)="generarFormatoKardex()"
    (anular)="anularKardex()" (clickImprimir)="imprimirFormato()" (clickMasOpciones)="verMasOpciones()"
    (clickNuevo)="nuevoRegistro()" [loaderFormato]="loaderFormato">
    <div id="botonesAdicionales">
      <button class="pav-btn-action" #btnGeneraAsiento color="primary" matTooltip="Extraer excel" type="button"
        (click)="extraerExcel()">
        <mat-icon class="pav-icon-btn" svgIcon="excel"></mat-icon>
      </button>
      <button class="pav-btn-action" color="primary" matTooltip="Copiar partes" type="button" (click)="copiarPartes()">
        <mat-icon class="pav-icon-btn">copy_all</mat-icon>
      </button>

    </div>
  </pav-form-actions>
</form>
