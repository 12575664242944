<app-pavso-title-section [title]="'AFP'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="afps"
    [tHeaders]="tHeaders"

    title="Todos los AFPs"
    url="/modulo-planilla/afp"
    entity=""
    [params]="['0']"
  ></pav-table-list>

</div>
