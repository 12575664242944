export class CuentaCajaBanco {
  CIA_CODCIA: string;
  CBA_CODCBA: string;
  BAN_CODBAN: string;
  TCT_CODTCT: string;
  CBA_NUMCTA: string;
  CBA_DESCRI: string;
  TMO_CODTMO: string;
  CBA_INDPGM: string;
  CBA_FECAPE: Date;
  CBA_IMPAPE: number;
  CBA_FECCIE: Date;
  CBA_ULTCIE: Date;
  CCN_CODCCN: string;
  CBA_NOMSEC: string;
  CBA_NOMAGE: string;
  CBA_DIRAGE: string;
  CBA_TELAGE: string;
  CBA_FUNPBA: string;
  CBA_CONPBA: string;
  CBA_CODBCO: string;
  CLI_CODCLI: string;
  CBA_INDSTA: string;
  CBA_CODUSE: string;
  CBA_FECUPD: Date;
  cba_deslar: string;
  CBA_LETORD: string;

  constructor() {
    this.CBA_INDSTA = '1';
    this.CBA_FECUPD = new Date();
  }
}
