<div class="pav-table-search-container">
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonName.BTN_SEARCH" #input>
  </div>
</div>

<div class="mat-elevation-z0">
  <div class="pavso_card_more_options">
    <div class="pav-table-header-icon"></div>
    <p class="pav-table-header-title">
      Tabla
    </p>
    <div class="isMobile">
      <div class="pav-button-icon-download-excel">
        <mat-icon svgIcon="download"></mat-icon>
      </div>
    </div>
    <div class="isWeb">
      <button class="pav-button-download-excel" mat-flat-button color="primary">
        <mat-icon>file_download</mat-icon>
        {{buttonName.BTN_DOWNLOAD_EXCEL}}
      </button>
    </div>
    <div class="isMobile">
      <div class="pav-button-icon-add" [routerLink]="obtenerParametros()">
        <mat-icon svgIcon="add"></mat-icon>
      </div>
    </div>
    <div class="isWeb">
      <button [routerLink]="obtenerParametros()" class="pav-button-add" mat-flat-button color="primary">
        <mat-icon>add</mat-icon>
        {{buttonName.BTN_ADD}} {{entity}}
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort >
    <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" data-label="Acciones">
          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="[editLink, row[uid]]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
          </div>
        </td>
    </ng-container>

    <ng-container *ngFor="let header of tHeaders" [matColumnDef]="header.key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{header.header}} </th>
        <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row[header.key]}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>
    </tr>
  </table>
</div>
