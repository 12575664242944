import { Component, OnDestroy, OnInit } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthenticationService, NominaService, ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { REPORTES_PLANILLA_REMUNERACION } from '@data/json/reportes';
import { HeaderReport } from '@data/interfaces/headers.report';
import { HeaderReportService } from 'src/app/services/reports/config/header-report.service';
import { NominaReportService } from 'src/app/services/reports/modules/nomina-report.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { FiltroReportePlanilla } from 'src/app/models/planilla/filtro-reporte-planilla';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-informes-remuneracion',
  templateUrl: './informes-remuneracion.component.html',
  styleUrls: ['./informes-remuneracion.component.css']
})
export class InformesRemuneracionComponent implements OnInit, OnDestroy {

  trabajadores: any[] = [];
  centros: any[] = [];
  conceptos: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  yearIni: number;
  monthIni: string;
  yearFin: number;
  monthFin: string;

  months = ["01","02","03","04","05","06","07","08","09","10","11","12"];

  now: number;

  reportes: any[] = REPORTES_PLANILLA_REMUNERACION;

  reporteSeleccionado: any = [REPORTES_PLANILLA_REMUNERACION[0].id];

  mostrarFiltroAuxiliar: 0 | 1 = 0;
  mostrarFiltroCentro: 0 | 1 = 0;
  mostrarFiltroConcepto: 0 | 1 = 0;
  mostrarFiltroRangoFecha: 0 | 1 = 0;

  loading: boolean = false;

  period$: Subscription;
  loading$: Subscription;
  indicadores$: Subscription;
  colaboradores$: Subscription;
  centros$: Subscription;
  conceptos$: Subscription;

  filtro: FiltroReportePlanilla;

  constructor(
    private _authService: AuthenticationService,
    private _headerReportService: HeaderReportService,
    private _contabilidadService: ContabilidadService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _nominaReportService: NominaReportService
  ) {

    this.filtro = new FiltroReportePlanilla();

    this.now = new Date().getFullYear();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
      this.monthIni = "01";
      this.yearIni = Number(this.year);
      this.monthFin = this.month;
      this.yearFin = Number(this.year);
    })

    this.usuario = this._authService.getUsuarioSistema();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
    this.seleccionarReporte(REPORTES_PLANILLA_REMUNERACION[0].id);

  }

  /**
   * Obtiene los maestros de :
   * . colaboradores
   * . conceptos
   * . centros de costo
   */
  loadData(): void {

    forkObs(
      this._nominaService.listarColaboradores(),
      this._nominaService.listarConceptos(),
      this._contabilidadService.listarCentroCostos()
    ).then(data => {

      this.trabajadores = data[0];
      this.conceptos = data[1].filter(item => item.ind_estado == "1");
      this.centros = data[2];

    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK")
    })

  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;

    let indicador = "";
    this.mostrarFiltroRangoFecha = 0;
    switch (this.reporteSeleccionado[0]) {
      case "PLANILLA_HRC":
        this.mostrarFiltroRangoFecha = 1;
        indicador = "51005"
        break;
      case "PLANILLA_CRP":
        indicador = "51001"
        break;
      case "PLANILLA_CBC":
        indicador = "51004"
        break;
      default:
        break;
    }

    this.indicadores$ = this._contabilidadService.obtenerInidicadoresFiltro(indicador).subscribe(
      ([response]) => {
        if(response) {
          this.mostrarFiltroAuxiliar = response.ind_auxiliar;
          this.mostrarFiltroCentro = response.ind_centrocosto;
          this.mostrarFiltroConcepto = response.ind_conceptoplanilla;
        }
      }
    )
  }

  verReporte(): void {

    this.loading = true;

    const headers: HeaderReport = this._headerReportService.obtenerCabecera()

    const otherData = {
      periodoini: `${this.yearIni} / ${this.monthIni}`,
      periodofin: `${this.yearFin} / ${this.monthFin}`,
    }

    const payload = {
      colaboradores: (this.filtro.colaboradores)? this.filtro.colaboradores: [],
      conceptos: (this.filtro.conceptos)? this.filtro.conceptos: []
    };

    this._nominaReportService.verReporteRemuneracion(this.reporteSeleccionado[0], headers, payload, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.indicadores$,
      this.colaboradores$,
      this.conceptos$,
      this.centros$
    ])
  }

}
