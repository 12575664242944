<app-pavso-title-section [title]="'Dinámicos'" [module]="'Reporte'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <select-report [disabled]="loaderFields" [reportes]="reportes"
    (seleccionarReporte)="seleccionarReporte($event)" [reporteSeleccionado]="reporteSeleccionado[0]"></select-report>

  <div class="" align="center" [ngStyle]="{marginBottom: '12px'}">
    <button class="pav-button-action" mat-flat-button color="primary" [class.spinner]="loading" [disabled]="loading"
      (click)="verReporte()">{{buttonsName.BTN_SHOW_REPORT | translate}}</button>
  </div>

  <div class="row">
    <div class="col s12 m12 l6">
      <div class="pav-filtro-header">
        <div class="pav-filtro-header-icon"></div>
        <div class="pav-filtro-header-title">Cabeceras</div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderFields"></mat-progress-bar>

      <div class="pav-filtro-container">
        <div class="row">
          <div class="col s12">
            <section class="example-section" *ngIf="!loaderFields">
              <span class="example-list-section">
                <mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someComplete()"
                  (change)="setAll($event.checked)">
                  {{task.name}}
                </mat-checkbox>
              </span>
              <span class="example-list-section">

                <div class="row">
                  <ul>
                    <div *ngFor="let informe of task.subtasks" class="col s12 m12 l4">
                      <li>
                        <mat-checkbox class="pav-cb-item" [(ngModel)]="informe.completed"
                          (ngModelChange)="updateAllComplete()">
                          {{informe.name}}
                        </mat-checkbox>
                      </li>
                    </div>
                  </ul>
                </div>

                <!-- <ul>
                        <li *ngFor="let subtask of task.subtasks">
                        <mat-checkbox class="pav-cb-item" [(ngModel)]="subtask.completed"
                          (ngModelChange)="updateAllComplete()">
                          {{subtask.name}}
                        </mat-checkbox>
                        </li>
                    </ul> -->
              </span>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12 m12 l6">
      <div class="pav-filtro-header">
        <div class="pav-filtro-header-icon"></div>
        <div class="pav-filtro-header-title">Filtros</div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderFields"></mat-progress-bar>
      <div class="pav-filtro-container">
        <div class="row">
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Moneda</mat-label>
              <mat-select [(ngModel)]="filtro.moneda" name="currency">
                <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
                  {{moneda.tmo_deslar}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <select-search [label]="'Proveedor'" [placeholder]="'Ej. PEREZ'" [name]="'CODIGO'" [value]="'CODIGO'" [description]="'description'"
              [data]="proveedores" (cambioSelect)="filtro.proveedores = $event" [model]="filtro.proveedores">
            </select-search>

          </div>
          <div class="col s12 m12 l4">
            <select-search [label]="'Sucursal'" [placeholder]="'Ej. LIMA'" [name]="'CODIGO'" [value]="'CODIGO'" [description]="'NOMBRE'"
              [data]="sucursales" (cambioSelect)="filtro.sucursales = $event" [model]="filtro.sucursales">
            </select-search>

          </div>
          <div class="col s12 m12 l4">

            <select-search [label]="'Tipo de documento'" [placeholder]="'Ej. FAC'" [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'"
              [description]="'TDO_DESLAR'" [data]="tiposDoc" (cambioSelect)="filtro.tiposDocumento = $event"
              [model]="filtro.tiposDocumento">
            </select-search>

          </div>
          <!-- <div class="col s12 m12 l4">

            <select-search [label]="'Cuenta Contable'" [placeholder]="'Ej. 40'" [name]="'ccn_codccn'" [value]="'ccn_codccn'"
              [description]="'ccn_desabr'" [data]="cuentas" (cambioSelect)="filtro.cuentasContable = $event"
              [model]="filtro.cuentasContable">
            </select-search>

          </div> -->
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Fecha de Corte</mat-label>
              <input [(ngModel)]="filtro.fechaCorte" matInput [matDatepicker]="picker" name="fechaCorte">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Estado</mat-label>
              <mat-select [(ngModel)]="filtro.estado" name="estado">
                <!-- <mat-option value="consaldo">
                  Con saldo
                </mat-option>
                <mat-option value="consaldo">
                  Con saldo
                </mat-option> -->
                <mat-option value="consaldo">
                  Con saldo
                </mat-option>
                <mat-option value="cancelados">
                  Cancelados
                </mat-option>
                <mat-option value="todos">
                  Todos
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Ordenado por</mat-label>
              <mat-select [(ngModel)]="filtro.ordenadoPor" name="ordenadoPor">
                <mat-option value="vencimiento">
                  Vencimiento
                </mat-option>
                <mat-option value="emision">
                  Emisión
                </mat-option>
                <mat-option value="documento">
                  Documento
                </mat-option>
                <mat-option value="nro_registro">
                  Nro. de registro
                </mat-option>
                <mat-option value="proveedor">
                  Proveedor
                </mat-option>

              </mat-select>
            </mat-form-field>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
