<mat-progress-bar *ngIf="loaderData" mode="indeterminate"></mat-progress-bar>
<h1 mat-dialog-title>Pedidos</h1>

<div class="row">
  <div class="col s12 m12 l6">
    <select-search
      [label]="'Producto'"
      [placeholder]="'Ej. CANASTA'"
      [name]="'prd_codprd'"
      [value]="'prd_codprd'"
      [description]="'DESC_ESPA'"
      [data]="productos"
      ></select-search>
  </div>
  <div class="col s12 m12 l6">
    <select-search
      [label]="'Cliente'"
      [placeholder]="'Ej. TORRES'"
      [name]="'cli_codcli'"
      [value]="'cli_codcli'"
      [description]="'cli_nomcli'"
      [data]="clientes"
      (cambioSelect)="order.CLI_CODCLI; seleccionarCliente($event)"
      [model]="order.CLI_CODCLI"
      ></select-search>
  </div>
</div>

<div class="row">
  <div class="col s12">
    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
              <td mat-cell *matCellDef="let row" data-label="Acciones">
                {{row.PCC_FECDOC | date: 'dd/MM/yyyy'}}
              </td>
          </ng-container>

          <ng-container matColumnDef="Cod_Vendedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.TMO_CODTMO}} </td>
          </ng-container>

          <ng-container matColumnDef="Nom_vendedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
              <td mat-cell *matCellDef="let row" data-label="Vendedor"> {{row.VDE_CODVDE}} </td>
          </ng-container>

          <ng-container matColumnDef="Telef_vendedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio Unitario </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.Telef_vendedor}} </td>
          </ng-container>

          <ng-container matColumnDef="Mail_Vendedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio Inc. IGV </th>
              <td mat-cell *matCellDef="let row" data-label="Mail"> {{row.Mail_Vendedor}} </td>
          </ng-container>

          <ng-container matColumnDef="Usuario_Reg">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
              <td mat-cell *matCellDef="let row" data-label="Usuario"> {{row.TDO_CODTDO}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6">No se encontraron registros</td>

          </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>

<div mat-dialog-actions>
  <button mat-flat-button class="pav-button-cancel" (click)="cerrar()">Cerrar</button>
  <button mat-flat-button class="pav-button-action" color='primary' cdkFocusInitial>Confirmar</button>
</div>

