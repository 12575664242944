import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AuthenticationService, ConfigurationService, CuentaCobrarService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { SituacionDocumento } from 'src/app/models';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-situacion-documento-form',
  templateUrl: './situacion-documento-form.component.html',
  styleUrls: ['./situacion-documento-form.component.css']
})
export class SituacionDocumentoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  situacion$: Subscription;
  loading$: Subscription;

  situacion: SituacionDocumento;

  uid: string;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _cuentaCobrarService: CuentaCobrarService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService

  ) {
    this.situacion = new SituacionDocumento();
    this.situacion.sdc_coduse = this._configurationService.obtenerIdUsuario();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;

    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

        if(id == '0') return;

        this.situacion$ = this._cuentaCobrarService.listarSituacionCobranza().subscribe(
          situaciones => {
            const situacion = situaciones.find(item => item.codigo_situcobra == id)
            this.situacion = situacion;
            this.loaderData = false;
          },
          error => {
            this.loaderData = false;
            this._snackBarService.showError(error.error.msg, 'OK');
          }
        )

      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarSituacionDocumento(f): this.actualizarSituacionDocumento(f);
  }

  registrarSituacionDocumento(f: NgForm): void {
    this._cuentaCobrarService.registrarSituacionDocumento(this.situacion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Situación de documento registrado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg,'Ok');
      }
    )
  }

  actualizarSituacionDocumento(f: NgForm): void {
    this._cuentaCobrarService.actualizarSituacionDocumento(this.situacion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Situación de documento actualizado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg,'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-cobranzas/situaciones-de-documento']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.situacion$,
      this.loading$
    ])
  }

}
