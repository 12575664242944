<app-pavso-title-section [title]="'Período de nómina'" [module]="'Planilla'" [estado]="periodo.indsta"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l2">
                <mat-form-field>
                    <mat-label>Secuencia</mat-label>
                    <input type="text" [(ngModel)]="periodo.codnpe" matInput placeholder="Secuencia" name="codnpe" required disabled>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l7">
                <mat-form-field>
                    <mat-label>Descripción</mat-label>
                    <input type="text" [(ngModel)]="periodo.descri" matInput placeholder="Descripción" name="descri" required>
                </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Fecha de inicio</mat-label>
                    <input [(ngModel)]="periodo.fecini" name="fecini" required

                    matInput [matDatepicker]="fecini">
                    <mat-datepicker-toggle matSuffix [for]="fecini"></mat-datepicker-toggle>
                    <mat-datepicker #fecini></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Período de término</mat-label>
                    <input [(ngModel)]="periodo.fecter" required [min]="periodo.fecini" name="fecter" matInput [matDatepicker]="fecter">
                    <mat-datepicker-toggle matSuffix [for]="fecter"></mat-datepicker-toggle>
                    <mat-datepicker #fecter></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Tipo de nómina</mat-label>
                <mat-select [(ngModel)]="periodo.codnti" name="codnti" required>
                  <mat-option *ngFor="let tipo of tipos" [value]="tipo.nti_codnti">
                    {{tipo.nti_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Frecuencia</mat-label>
                <mat-select [(ngModel)]="periodo.codnfr" name="codnfr" required>
                  <mat-option *ngFor="let frecuencia of frecuencias" [value]="frecuencia.nfr_codnfr">
                    {{frecuencia.nfr_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Días efectivos</mat-label>
                    <input class="pav-input-align-right" [(ngModel)]="periodo.diaefe" min="0" max="30" type="number" matInput placeholder="Días Efectivos" name="diaefe" required disabled>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Horas efectivas</mat-label>
                    <input class="pav-input-align-right" [(ngModel)]="periodo.horefe" min="0" max="240" type="number" matInput placeholder="Horas Efectivas" name="horefe" required>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>Días laborables</mat-label>
                  <input class="pav-input-align-right" [(ngModel)]="periodo.dialab" min="0" max="28" type="number" matInput placeholder="Días Laborables" name="dialab" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-checkbox class="example-margin" [(ngModel)]="periodo.indcie" name="indcie">Nómina Cerrada</mat-checkbox>
            </div>
            <div class="col s12" *ngIf="uid != '0' && secNomina == '01'">
              <button class="pav-button-action" [class.spinner]="loaderInicializa" mat-flat-button color="primary" type="button" (click)="inicializaPlanilla()">Inicializa planillas</button>
            </div>
        </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-planilla/listado-de-periodos-de-nomina">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
