export class FiltroAprobacionRequerimientoMateriales {
  proveedor: string;
  desde: Date;
  hasta: Date;
  fechaSeleccionada: boolean;
  estaAprobado: boolean;

  constructor() {
    this.estaAprobado = false;
  }
}
