import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { CuentaCajaBanco } from 'src/app/models/caja-banco/cuenta-caja-banco';
import { AlmacenService, AuthenticationService, CajaBancoService, GeneralService } from 'src/app/services';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';

@Component({
  selector: 'app-cash-bank-account-create',
  templateUrl: './cash-bank-account-create.component.html',
  styleUrls: ['./cash-bank-account-create.component.css']
})
export class CashBankAccountCreateComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  monedas: any[] = [];

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  cuentaCajaBanco: CuentaCajaBanco;

  cajaBanco$: Subscription;
  banco$: Subscription;
  auxiliar$: Subscription;
  loading$: Subscription;

  bancos: any[] = [];
  auxiliares: any[] = [];

  uid: string;

  constructor(
    private _authService: AuthenticationService,
    private _cajaBancoService: CajaBancoService,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _clienteService: ClienteService,
    private store: Store<PavsoState>,
    private _router: Router
  ) {
    this.cuentaCajaBanco = new CuentaCajaBanco();

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.loadMaestros();
      if(id == '0') this.loaderData = false;

    })
  }

  obtenerCajaBanco(): void {
    this.cajaBanco$ = this._cajaBancoService.obtenerCajaBanco(this.uid).subscribe(
      ([cajaBanco]) => {

        this.cuentaCajaBanco = cajaBanco;
        this.loaderData = false;
        this.loadMaestros();

      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError("Error al obtener caja y banco", "OK");
      }
    )

  }

  loadMaestros(): void {
    this.banco$ = this._generalService.listarBancos().subscribe(
      bancos => {
        this.bancos = bancos;
        console.log('bancos', bancos)

        this.auxiliar$ = this._clienteService.obtenerClientes().subscribe(
          auxiliares => {
            this.auxiliares = auxiliares;

            if(this.uid != '0') this.obtenerCajaBanco();
          },
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  registrarCuenta(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/modulo-cajabanco/cuentas-de-caja-y-bancos'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cajaBanco$,
      this.banco$,
      this.auxiliar$,
      this.loading$
    ])
  }

}
