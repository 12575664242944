<app-pavso-title-section [title]="'Liquidación de beneficios sociales'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="liquidaciones"
    [tHeaders]="tHeaders"

    title="Todas las liquidaciones"
    url="/modulo-planilla/liquidacion-de-beneficios-sociales"
    entity=""
    [params]="['0']"
  ></pav-table-list>

</div>
