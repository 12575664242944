<div class="row pav-panel">
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'O/C emitidas del año'" [amount]="189"
      [bgColorTitle]="'pav-primary'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'O/C emitidas  en el mes'" [amount]="96"
      [bgColorTitle]="'pav-secondary'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'O/C pendiente de aprobación'" [amount]="3"
      [bgColorTitle]="'pav-alternative'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'O/C con atraso de entrega'" [amount]="5"
      [bgColorTitle]="'pav-primary'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'Comprobantes registrados del año'" [amount]="155"
      [bgColorTitle]="'pav-secondary'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'Comprobantes registrados en el mes'" [amount]="72"
      [bgColorTitle]="'pav-alternative'"></card-dashboard-resume>
  </div>
</div>

<div class="row">
  <div class="col s12 m12 l8">
    <mat-card class="mat-elevation-z0">
      <pavso-bar-chart-basic [data]="dataChart" [categorias]="categoriesChart" [isHorizontal]="false"></pavso-bar-chart-basic>
    </mat-card>
  </div>
  <div class="col s12 m12 l4">
    <div [ngStyle]="{display: 'flex', justifyContent: 'right'}">
      <img src="assets/images/imagen-inicio.png" class="pav-welcome-image" alt="">

    </div>
  </div>
</div>
