<app-pavso-title-section [title]="'Banco'" [module]="'Contabilidad'" [estado]="banco.BAN_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Código</mat-label>
                      <input type="text" [(ngModel)]="banco.BAN_CODBAN" matInput placeholder="Código" name="BAN_CODBAN"required>
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Descripción</mat-label>
                      <input type="text" [(ngModel)]="banco.BAN_NOMBRE" matInput placeholder="Descripción" name="BAN_NOMBRE">
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Des. Abrev.</mat-label>
                      <input type="text" [(ngModel)]="banco.BAN_NOMABR" matInput placeholder="Des. Abrev." name="BAN_NOMABR">
                  </mat-form-field>
              </div>

          </div>
      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
