import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CondicionPago } from 'src/app/models/compras';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({providedIn: 'root'})
export class CondicionPagoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @returns
   */
  obtenerCondicionesPago(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/condicionpagos`
    ).pipe(
      map((condiciones: any) => {
        return condiciones.map(condicion => {
          condicion['description'] = `${condicion.CPA_CODCPA} | ${condicion.CPA_DESCRI} `
          condicion['description_reverse'] = `${condicion.CPA_DESCRI} | ${condicion.CPA_CODCPA}`
          return condicion;
        })
      })
    );
  }

  /**
   *
   * @param condicionPago
   * @returns
   */
  registrarCondicionPago(condicionPago: CondicionPago): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/condicion-de-pago`,
      condicionPago
    );
  }

  /**
   *
   * @param condicionPago
   * @returns
   */
  actualizarCondicionPago(condicionPago: CondicionPago): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/condicion-de-pago`,
      condicionPago
    );
  }

}
