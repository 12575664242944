import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'supervisor-dialog',
  template: `
    <div mat-dialog-content>

      <supervisor-form [id]="data.id"></supervisor-form>

    </div>
  `,
  styleUrls: ['./supervisor-dialog.component.scss']
})
export class SupervisorDialog {
  constructor(
    public dialogRef: MatDialogRef<SupervisorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data id', data.id)
  }
}
