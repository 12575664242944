<app-pavso-title-section [title]="'Genera asiento'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <mat-radio-group [(ngModel)]="tipoAsiento" (change)="radioChange($event)">
      <mat-radio-button value="1">Genera Asiento de Apertura</mat-radio-button>
      <mat-radio-button value="2">Genera Asiento de Cierre</mat-radio-button>
    </mat-radio-group>
  </mat-card>
  <br>
  <mat-card class="mat-elevation-z0">
    <div class="row" *ngIf="tipoAsiento == '1'">
      <div class="col s4">
        <mat-form-field>
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s2">
        <mat-form-field>
          <mat-label>Compra</mat-label>
          <input matInput placeholder="Compra" type="number" min="0">
        </mat-form-field>
      </div>
      <div class="col s2">
        <mat-form-field>
          <mat-label>Venta</mat-label>
          <input matInput placeholder="Venta" type="number" min="0">
        </mat-form-field>
      </div>
      <div class="col s2">
        <mat-form-field>
          <mat-label>Cta. Contable</mat-label>
          <input matInput placeholder="Venta">
        </mat-form-field>
      </div>
      <div class="col s2">
        <div class="pavso-cb-delete">
          <mat-checkbox class="example-margin">Eliminar</mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="tipoAsiento == '2'">
      <div class="col s2">
        <div class="pavso-cb-delete">
          <mat-checkbox class="example-margin">Eliminar</mat-checkbox>
        </div>
      </div>
    </div>
  </mat-card>

</div>
