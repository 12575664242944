import { BINARY_TYPE } from "@data/interfaces/binary.type";

export class OrdenCompra {
  cia_codcia:    string;
  ocm_numocm:    string;
  ocm_gloocm:    string;
  ocm_fecocm:    any;
  cli_codcli:    string;
  cliente: {cli_nomcli: string;}
  ocm_coduse:    string;
  ocm_fecupd:    Date;
  ocm_indsta:    string;
  ocm_imptot:    number;
  tmo_codtmo:    string;
  ocm_cantot:    number;
  ocm_indsit:    number;
  ocm_tipcam:    number;
  ocm_fecent:    Date;
  cpa_codcpa:    string;
  ocm_valvta:    number;
  ocm_impigv:    number;
  ano_codano:    string;
  mes_codmes:    string;
  suc_codsuc:    string;
  ocm_numcot:    string;
  ocm_tasigv:    number;
  ocm_aprcom:    BINARY_TYPE;
  ocm_aprge1:    number;
  ocm_aprge2:    number;
  ldc_codldc:    string;
  ldc_dirldc:    string;
  ocm_indtex:    number | boolean;
  ocm_indcal:    string;
  ocm_indcie:    number;
  ccs_codccs:    string;
  ocm_nomcon:    string;
  ocm_indnot:    number | boolean;
  ocm_concom:    string;
  toc_codtoc:    string;
  ocm_impfle:    number;
  ocm_impseg:    number;
  ocm_tcaeur:    number;
  cli_codref:    string;
  ocm_descuento: number;
  ocm_impper:    number;
  ocm_tasper:    number;
  ocm_numint:    string;
  ocm_useapr:    string;
  ocm_aprfec:    Date;
  ocm_cliaut:    string;
  lde_codlde:    string;
  alm_codalm:    string;
  ocm_usecie:    string;
  ocm_feccie:    Date;
  rco_numrco:    string;
  ocm_indgen:    number;
  detalle_ocd:   DetalleOcd[];
  entrega_oce:   EntregaOce[];

  constructor() {

    let month = new Date().getMonth() + 1;

    this.detalle_ocd = [];
    this.ocm_fecocm = new Date();

    this.ocm_fecupd = new Date();
    this.ano_codano = (new Date().getFullYear()).toString();
    this.mes_codmes = (month > 9)? month.toString(): `0${month}`;
    this.entrega_oce = [];
    this.ocm_fecent = new Date();
    this.ocm_indsta = "1";

    this.ocm_indcal = "0"

    this.alm_codalm = "";
    this.ccs_codccs = "";
    this.lde_codlde = "";
    this.ocm_aprcom = 1;
    this.ocm_aprfec = new Date();
    this.ocm_aprge1 = 0;
    this.ocm_aprge2 = 0;
    this.ocm_concom = "";
    this.ocm_descuento = 0;
    this.ocm_feccie = new Date("1900-01-01");
    this.ocm_impper = 0;
    this.ocm_indcie = 0;
    this.ocm_indsit = 0;
    this.ocm_numint = "";
    this.ocm_numocm = "";
    this.ocm_tasigv = 18;
    this.ocm_tasper = 0;
    this.ocm_tcaeur = 0;
    this.ocm_usecie = "";
    this.suc_codsuc = "01";

    this.ocm_impfle = 0;
    this.ocm_impseg = 0;
    this.ocm_cantot = 0;
    this.ocm_valvta = 0;
    this.ocm_impigv = 0;
    this.ocm_imptot = 0;

    this.detalle_ocd = [];
    this.entrega_oce = [];

    this.cliente = {cli_nomcli: ''};
  }
}

export class DetalleOcd {
  cia_codcia:        string;
  ocm_numocm:        string;
  prd_codprd:        string;
  prd_um:        string;
  ocd_secocd:        string;
  ocd_corocd:        string;
  ocd_canocd:        number;
  ocd_preuni:        number;
  ocd_pruigv:        number;
  ocd_imptot:        number;
  ocd_valvta:        number;
  ocd_impigv:        number;
  ocd_canate:        number;
  eum_codume:        string;
  ocd_caneum:        number;
  ocd_descri:        string;
  ocd_cansus:        number;
  ocd_preprv:        number;
  ocd_desprv:        number;
  ocd_desprv2:       number;
  ocd_motcom:        string;
  ocd_codccs:        string;
  ocd_fecreq:        Date;
  ocd_pesrcd:        number;
  ocd_codcli:        string;
  ocd_codped:        string;
  seleccionado: boolean;
  requerimiento_ocr: null;

  constructor() {
    this.seleccionado = false;
    // this.ocd_canocd = ;
    this.ocd_caneum = 0;
    this.ocd_codcli = "";
    this.ocd_codped = "";
    this.ocd_imptot = 0;
    this.ocd_valvta = 0;
    this.ocd_preprv = 0;
    this.ocd_pesrcd = 0;
    this.ocd_pruigv = 0;
    this.ocd_impigv = 0;
    this.ocd_preuni = 0;
    this.ocd_desprv = 0;
    this.ocd_desprv2 = 0;
    this.ocd_cansus = 0;
    this.ocd_canate = 0;
    this.ocd_secocd = "001";
    this.ocd_fecreq = new Date();
  }
}

export class EntregaOce {
  CIA_CODCIA: string;
  OCM_NUMOCM: string;
  OCE_COROCE: string;
  OCE_DESCRI: string;
  OCE_INDAPR: number;
  OCE_IMPCUO: number;
  OCE_FECPAG: null;
}



export class PurchaseOrder {
  cia_codcia: any;
  ocm_numocm: any;
  ocm_gloocm: any;
  ocm_fecocm: any;
  cli_codcli: string;
  ocm_coduse: string;
  ocm_fecupd: any;
  ocm_indsta: string;
  ocm_imptot: any;
  tmo_codtmo: any;
  ocm_cantot: any;
  ocm_indsit: any;
  ocm_tipcam: any;
  ocm_fecent: any;
  cpa_codcpa: any;
  ocm_valvta: any;
  ocm_impigv: any;
  ano_codano: any;
  mes_codmes: any;
  suc_codsuc: any;
  ocm_numcot: any;
  ocm_tasigv: any;
  ocm_aprcom: any;
  ocm_aprge1: any;
  ocm_aprge2: any;
  ldc_codldc: any;
  ldc_dirldc: any;
  ocm_indtex: any;
  ocm_indcal: any;
  ocm_indcie: any;
  ccs_codccs: any;
  ocm_nomcon: any;
  ocm_indnot: any;
  ocm_concom: any;
  toc_codtoc: any;
  ocm_impfle: any;
  ocm_impseg: any;
  ocm_tcaeur: any;
  cli_codref: any;
  ocm_descuento: any;
  ocm_impper: any;
  ocm_tasper: any;
  ocm_numint: any;
  ocm_useapr: any;
  ocm_aprfec: any;
  ocm_cliaut: any;
  lde_codlde: any;
  alm_codalm: any;
  ocm_usecie: any;
  ocm_feccie: any;
  ocm_indnue: any;
  ocm_jeflog: any;
  detalle_prod: Array<DetalleProd>;
  detalle_cuota: Array<DetalleCuota>;
  constructor(
  ) {

    let month = new Date().getMonth() + 1;

    this.detalle_prod = [];
    this.ocm_fecocm = new Date();

    this.ocm_fecupd = new Date();
    this.ano_codano = (new Date().getFullYear()).toString();
    this.mes_codmes = (month > 9)? month.toString(): `0${month}`;
    this.detalle_cuota = [];
    this.ocm_fecent = new Date();
    this.ocm_indsta = "1";

    this.ocm_indnue = 1;
    this.ocm_indcal = "0"

    this.alm_codalm = "";
    this.ccs_codccs = "";
    this.lde_codlde = "";
    this.ocm_aprcom = 1;
    this.ocm_aprfec = new Date();
    this.ocm_aprge1 = 0;
    this.ocm_aprge2 = 0;
    this.ocm_concom = "";
    this.ocm_descuento = 0;
    this.ocm_feccie = new Date("1900-01-01");
    this.ocm_impper = 0;
    this.ocm_indcie = 0;
    this.ocm_indsit = 0;
    this.ocm_numint = "";
    this.ocm_numocm = "";
    this.ocm_tasigv = 0;
    this.ocm_tasper = 0;
    this.ocm_tcaeur = 0;
    this.ocm_usecie = "";
    this.suc_codsuc = "01";

    this.ocm_impfle = 0;
    this.ocm_impseg = 0;
    this.ocm_cantot = 0;
    this.ocm_valvta = 0;
    this.ocm_impigv = 0;
    this.ocm_imptot = 0;
  }
}

export class DetalleProd {
  prd_codprd: any;
  ocd_secocd: any;
  ocd_corocd: any;
  ocd_canocd: any;
  ocd_preuni: any;
  ocd_pruigv: any;
  ocd_imptot: any;
  ocd_valvta: any;
  ocd_impigv: any;
  ocd_canate: any;
  eum_codume: any;
  ocd_caneum: any;
  ocd_descri: any;
  ocd_cansus: any;
  ocd_preprv: any;
  ocd_desprv: any;
  ocd_desprv2: any;
  ocd_motcom: any;
  ocd_codccs: any;
  ocd_fecreq: any;
  ocd_pesrcd: any;
  ocd_codcli: any;
  ocd_codped: any;
  detalle_reqcomp: any;
  detalle_item: any;
  descripcion: any  ;
  constructor(
  ){
    this.detalle_item = [];
  }
}

export class DetalleCuota {
  id: any;
  OCE_COROCE: any;
  OCE_DESCRI: any;
  OCE_INDAPR: any;
  OCE_IMPCUO: any;
  OCE_FECPAG: any;
  constructor(
  ){}
}

export class DetalleItem {
  id: any;
  ocf_corocf: any;
  ocf_descri: any;
  ocf_coduse: string;
  constructor(
  ) {}
}
