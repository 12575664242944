<app-pavso-title-section [title]="'Liquidación de beneficios sociales'" [module]="'Planilla'" [estado]="liquidacion.indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l1">
                  <mat-form-field>
                      <mat-label>Año</mat-label>
                      <input type="text" [(ngModel)]="liquidacion.codano" matInput placeholder="Año" name="anio" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l1">
                  <mat-form-field>
                      <mat-label>Mes</mat-label>
                      <input type="text" [(ngModel)]="liquidacion.codmes" matInput placeholder="Mes" name="mes" required >
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Colaborador</mat-label>
                      <mat-select [(ngModel)]="liquidacion.codcli" required name="cli_codcli" >
                          <mat-option *ngFor="let col of colaboradores; trackBy: trackByColaborador" [value]="col.codcli">
                            {{col.nomcli}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l2">
                  <mat-form-field>
                      <mat-label>Fecha de Registro</mat-label>
                      <input [(ngModel)]="liquidacion.fecreg" name="fecreg" matInput [matDatepicker]="fecharegistro">
                      <mat-datepicker-toggle matSuffix [for]="fecharegistro"></mat-datepicker-toggle>
                      <mat-datepicker #fecharegistro></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l2">
                  <mat-form-field>
                      <mat-label>Ult. Fecha de CTS</mat-label>
                      <input [(ngModel)]="liquidacion.ultcts" matInput [matDatepicker]="ultfechacts" name="ultcts">
                      <mat-datepicker-toggle matSuffix [for]="ultfechacts"></mat-datepicker-toggle>
                      <mat-datepicker #ultfechacts></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Tipo de Cambio</mat-label>
                      <input class="pav-input-align-right" [(ngModel)]="liquidacion.tipcam" type="number" matInput placeholder="Tipo de Cambio" name="tipcam" required  step="any">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Motivo Cese</mat-label>
                    <mat-select [(ngModel)]="liquidacion.codmce" required name="codmce" >
                        <mat-option *ngFor="let motivo of motivos; trackBy: trackByMotivo" [value]="motivo.nmc_codnmc">
                          {{motivo.nmc_descri}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <button class="pav-button-other" mat-flat-button (click)="generaPdf()" type="button">Imprimir</button>
              </div>
          </div>
      </mat-card>
        <br>
      <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l4">
              <div class="pav-card-header green">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Tiempo de servicio
                </div>
              </div>
              <div class="pav-container-data">
                <mat-form-field>
                    <mat-label>Años</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.tsrano" type="number" matInput placeholder="Años" name="tsrano" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Meses</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.tsrmes" type="number" matInput placeholder="Meses" name="tsrmes" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Días</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.tsrdia" type="number" matInput placeholder="Días" name="tsrdia" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Meses Gratificación Trunca</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.mesgra" type="number" matInput placeholder="Meses Gratificación Trunca" name="mesgra" required  step="any">
                </mat-form-field>
              </div>
            </div>
            <div class="col s12 m12 l4">
              <div class="pav-card-header yellow">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Tiempo de CTS
                </div>
              </div>

              <div class="pav-container-data">
                <mat-form-field>
                    <mat-label>Años</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.tctano" matInput placeholder="Años" name="tctano" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Meses</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.tctmes" matInput placeholder="Meses" name="tctmes" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Días</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.tctdia" matInput placeholder="Días" name="tctdia" required  step="any">
                </mat-form-field>
              </div>
            </div>

            <div class="col s12 m12 l4">
              <div class="pav-card-header blue">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Datos
                </div>
              </div>

              <div class="pav-container-data">
                <mat-form-field>
                    <mat-label>Vacaciones Años</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.tvtano" matInput placeholder="Vacaciones Años" name="tvtano" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Tiempo Vacaciones Truncas Meses</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.tvtmes" matInput placeholder="Tiempo Vacaciones Truncas Meses" name="tvtmes" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Días</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.tvtdia" matInput placeholder="Días" name="tvtdia" required  step="any">
                </mat-form-field>
                <button class="pav-button-other" mat-flat-button type="button">Calcular base imponible</button>

              </div>
            </div>

        </div>
      </mat-card>
      <br>
      <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l4">
              <div class="pav-card-header green">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Remuneración fija
                </div>
              </div>

              <div class="pav-container-data">
                <mat-form-field>
                    <mat-label>Básico (a)</mat-label>
                    <input class="pav-input-align-right" (blur)="blurBasico()" #basico (focus)="basico.select()" min="0" type="number" [(ngModel)]="liquidacion.rembas" matInput placeholder="Básico (a)" name="rembas" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Asignación Fam. (b)</mat-label>
                    <input class="pav-input-align-right" (blur)="blurAsignacion()" #asignacion (focus)="asignacion.select()" min="0" type="number" [(ngModel)]="liquidacion.asifam" matInput placeholder="Asignación Fam. (b)" name="asifam" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Otras Remuneraciones (c)</mat-label>
                    <input class="pav-input-align-right" (blur)="blurOtrasRem()" #otrasRem (focus)="otrasRem.select()" min="0" type="number" [(ngModel)]="liquidacion.otrrem" matInput placeholder="Otras Remuneraciones (c)" name="otrrem" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>6 Parte Ult. Gratif. (d)</mat-label>
                    <input class="pav-input-align-right" (blur)="blurPartUlt()" #partUlt (focus)="partUlt.select()" min="0" type="number" [(ngModel)]="liquidacion.ultgra" matInput placeholder="6 Parte Ult. Gratif. (d)" name="ultgra" required  step="any">
                </mat-form-field>
              </div>
            </div>
            <div class="col s12 m12 l4">
              <div class="pav-card-header yellow">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Remuneración variable
                </div>
              </div>

              <div class="pav-container-data">
                <mat-form-field>
                    <mat-label>CTS (e)</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.varcts" matInput placeholder="CTS (e)" name="varcts" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Gratificaciones (f)</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.vargra" matInput placeholder="Gratificaciones (f)" name="vargra" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Vacaciones (g)</mat-label>
                    <input class="pav-input-align-right" min="0" type="number" [(ngModel)]="liquidacion.varvac" matInput placeholder="Vacaciones (g)" name="varvac" required  step="any">
                </mat-form-field>
              </div>
            </div>
            <div class="col s12 m12 l4">
              <div class="pav-card-header blue">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Importe indemnizable
                </div>
              </div>
              <div class="pav-container-data">
                <mat-form-field>
                  <mat-label>CTS (a+b+c+d+e)</mat-label>
                  <input class="pav-input-align-right" type="number" min="0" [(ngModel)]="liquidacion.rincts" matInput placeholder="CTS (a+b+c+d+e)" name="rincts" required  step="any">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Gratificaciones (a+b+f)</mat-label>
                  <input class="pav-input-align-right" type="number" min="0" [(ngModel)]="liquidacion.ringra" matInput placeholder="Gratificaciones (a+b+f)" name="ringra" required  step="any">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Vacaciones (a+b+g)</mat-label>
                  <input class="pav-input-align-right" type="number" min="0" [(ngModel)]="liquidacion.rinvac" matInput placeholder="Vacaciones (a+b+g)" name="rinvac" required  step="any">
                </mat-form-field>
              </div>
            </div>
        </div>
      </mat-card>
      <br>
      <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l4">
              <div class="pav-card-header green">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Vacaciones adelantadas
                </div>
              </div>

              <div class="pav-container-data">
                <mat-form-field>
                    <mat-label>Días</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.diavaa" type="number" matInput placeholder="Días" name="diavaa" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Importe</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.impvaa" type="number" matInput placeholder="Importe" name="impvaa" required  step="any">
                </mat-form-field>
              </div>

            </div>
            <div class="col s12 m12 l4">
              <div class="pav-card-header yellow">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Aplic. descuentos
                </div>
              </div>

              <div class="pav-container-data">
                <mat-form-field>
                    <mat-label>Importe</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.apldes" type="number" matInput placeholder="Importe" name="apldes" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Indemnización</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.impind" type="number" matInput placeholder="Indemnización" name="impind" required  step="any">
                </mat-form-field>
              </div>

            </div>
            <div class="col s12 m12 l4">
              <div class="pav-card-header blue">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Aporte voluntariado
                </div>
              </div>

              <div class="pav-container-data">
                <mat-form-field>
                    <mat-label>Incentivo por Cese</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.incces" type="number" matInput placeholder="Incentivo por Cese" name="incces" required  step="any">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Importe</mat-label>
                    <input class="pav-input-align-right" min="0" [(ngModel)]="liquidacion.apovol" type="number" matInput placeholder="Importe" name="apovol" required  step="any">
                </mat-form-field>

                <button class="pav-button-other" mat-flat-button type="button">Calcular liquidación</button>
              </div>
              <div class="row">
                  <div class="s12 m12 l6">
                  </div>
                  <div class="s12 m12 l6">
                  </div>
              </div>
            </div>
        </div>
      </mat-card>
      <br>
      <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l4">
              <div class="pav-card-header green">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Datos
                </div>
              </div>
              <div class="row">
                  <div class="col s4">
                      <h1 class="pav-title-cell">LIQUID.:</h1>
                  </div>
                  <div class="col s4">
                      <h1 class="pav-title-cell">VACAC.:</h1>
                  </div>
                  <div class="col s4">
                      <h1 class="pav-title-cell">CTS.</h1>
                  </div>
              </div>
              <div class="row">
                  <div class="col s4">
                      <h1 class="pav-title-cell">Por Año:</h1>
                  </div>
                  <div class="col s4">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input class="pav-input-align-right" [(ngModel)]="liquidacion.pvaano" type="number" matInput placeholder="" name="pvaano" required  step="any">
                      </mat-form-field>
                  </div>
                  <div class="col s4">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input class="pav-input-align-right" [(ngModel)]="liquidacion.pctano"  type="number" matInput placeholder="" name="pctano" required  step="any">
                      </mat-form-field>
                  </div>
              </div>
              <div class="row">
                  <div class="col s4">
                      <h1 class="pav-title-cell">Por Meses:</h1>
                  </div>
                  <div class="col s4">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input class="pav-input-align-right" [(ngModel)]="liquidacion.pvames" type="number" matInput placeholder="" name="pvames" required  step="any">
                      </mat-form-field>
                  </div>
                  <div class="col s4">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input class="pav-input-align-right" [(ngModel)]="liquidacion.pctmes" type="number" matInput placeholder="" name="pctmes" required  step="any">
                      </mat-form-field>
                  </div>
              </div>
              <div class="row">
                  <div class="col s4">
                      <h1 class="pav-title-cell">Por Días:</h1>
                  </div>
                  <div class="col s4">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input class="pav-input-align-right" [(ngModel)]="liquidacion.pvadia" type="number" matInput placeholder="" name="pvadia" required  step="any">
                      </mat-form-field>
                  </div>
                  <div class="col s4">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input class="pav-input-align-right" [(ngModel)]="liquidacion.pctdia" type="number" matInput placeholder="" name="pctdia" required  step="any">
                      </mat-form-field>
                  </div>
              </div>
              <div class="row">
                  <div class="col s4">
                      <h1 class="pav-title-cell">Total:</h1>
                  </div>
                  <div class="col s4">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input class="pav-input-align-right" [(ngModel)]="liquidacion.totvac" type="number" matInput placeholder="" name="totvac" required  step="any">
                      </mat-form-field>
                  </div>
                  <div class="col s4">
                      <mat-form-field>
                          <mat-label></mat-label>
                          <input class="pav-input-align-right" [(ngModel)]="liquidacion.totcts" type="number" matInput placeholder="" name="totcts" required  step="any">
                      </mat-form-field>
                  </div>
              </div>
            </div>
            <div class="col s12 m12 l2">
              <div class="pav-card-header yellow">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Gratif.
                </div>
              </div>
              <div class="pav-container-data">
                  <mat-form-field>
                      <mat-label></mat-label>
                      <input class="pav-input-align-right" type="number" [(ngModel)]="liquidacion.pgrmes" matInput placeholder="" name="pgrmes" required  step="any">
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label></mat-label>
                      <input class="pav-input-align-right" type="number" [(ngModel)]="liquidacion.bongra" matInput placeholder="" name="bongra" required  step="any">
                  </mat-form-field>
                  <mat-form-field>
                      <mat-label></mat-label>
                      <input class="pav-input-align-right" type="number" [(ngModel)]="liquidacion.totgra" matInput placeholder="" name="totgra" required  step="any">
                  </mat-form-field>
              </div>
            </div>
            <div class="col s12 m12 l6">
              <div class="pav-card-header blue">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Datos
                </div>
              </div>
              <div class="row">
                  <p class="pav-paragraph">Si va a recalcular la liquidación primero debe de eliminar todos los items y grabar. Luego puede calcular liquidación nuevamente</p>

                  <table mat-table [dataSource]="dataSource" matSort >

                      <ng-container matColumnDef="nco_codnco">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.codnco}} </td>
                      </ng-container>

                      <ng-container matColumnDef="nco_descri">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                          <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descri}} </td>
                      </ng-container>

                      <ng-container matColumnDef="npc_impnpc">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                          <td mat-cell *matCellDef="let row" data-label="Importe"> {{row.impnpc}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                      <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="4">No se encontraron registros</td>

                      </tr>

                  </table>
              </div>
            </div>
        </div>
      </mat-card>
      <br>
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>Total Remuneraciones</mat-label>
              <input class="pav-input-align-right" type="number" min="0" [(ngModel)]="liquidacion.totrem" matInput placeholder="Total Remuneraciones" name="totrem" required  step="any">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>Total Deducciones</mat-label>
              <input class="pav-input-align-right" type="number" min="0" [(ngModel)]="liquidacion.totded" matInput placeholder="Total Deducciones" name="totded" required  step="any">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>Neto a Pagar</mat-label>
              <input class="pav-input-align-right" type="number" min="0" [(ngModel)]="liquidacion.netpag" matInput placeholder="Neto a Pagar" name="netpag" required  step="any">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>M.E.</mat-label>
              <input class="pav-input-align-right" type="number" min="0" [(ngModel)]="liquidacion.netpme" matInput placeholder="M.E." name="netpme" required  step="any">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <button class="pav-button-other" type="button" mat-flat-button>Imprime Carta Banco</button>
          </div>
        </div>
      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>
    </form>
</div>
