import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { fillTable } from '@utils/tables/table';
import { AuthenticationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { EditaContrato } from 'src/app/models/planilla/edita-contrato';

@Component({
  selector: 'app-configuracion-contrato-create',
  templateUrl: './configuracion-contrato-create.component.html',
  styleUrls: ['./configuracion-contrato-create.component.css']
})
export class ConfiguracionContratoCreateComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['item', 'descripcion1', 'dato1', 'descripcion2', 'dato2', 'descripcion3', 'dato3', 'descripcion4', 'dato4'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  editaContrato: EditaContrato;
  datos: any[] = [];

  uid: string;

  loading$: Subscription;
  dataContrato$: Subscription;
  editaContrato$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _nominaService: NominaService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {

    this.editaContrato = new EditaContrato();

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
        this.loadMaestro();
        if(id == '0') return

      }
    )
  }

  obtenerEditaContrato(): void {
    this.editaContrato$ = this._nominaService.obtenerEdicionContrato(this.uid).subscribe(
      response => {
        if(response.length > 0) {
          this.editaContrato = response[0];

          this.dataSource = fillTable(this.editaContrato.EDIC_CONT_NEC, this.paginator, this.sort);
        }
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  /**
   * Obtiene el maestro de :
   * datos de contrato
   */
  loadMaestro(): void {
    this.dataContrato$ = this._nominaService.listarDatoContrato().subscribe(
      datos => {
        this.datos = datos
        this.obtenerEditaContrato();
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0'? this.registrarEditarContrato(f): this.actualizarEditarContrato(f);
  }

  registrarEditarContrato(f: NgForm): void {}

  actualizarEditarContrato(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-planilla/configuraciones-de-contrato']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.loading$, this.dataContrato$, this.editaContrato$])
  }
}
