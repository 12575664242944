import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { Banco } from 'src/app/models/general/banco';
import { AuthenticationService, ConfigurationService, GeneralService } from 'src/app/services';

@Component({
  selector: 'app-bank-create',
  templateUrl: './bank-create.component.html',
  styleUrls: ['./bank-create.component.css']
})
export class BankCreateComponent implements OnInit {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  banco: Banco;
  uid: string;

  bancos$: Subscription;
  loading$: Subscription;

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _generalService: GeneralService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _dialogService: DialogService
  ) {
    this.banco = new Banco();
    this.banco.BAN_CODUSE = this._configurationService.obtenerIdUsuario();
    this.banco.BAN_INDNBC = 1;
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        if(id == '0') return;
        this.obtenerBanco();
      }
    )
  }

  obtenerBanco(): void {
    this.bancos$ = this._generalService.listarBancos().subscribe(
      bancos => {
        this.banco = bancos.find(item => item.BAN_CODBAN == this.uid);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0'? this.registrarBanco(f): this.actualizarBanco(f)
  }

  registrarBanco(f: NgForm): void {
    this._generalService.registrarBanco(this.banco).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Banco registrado', '400px', '400px', '');
        f.resetForm();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarBanco(f: NgForm): void {
    this._generalService.actualizarBanco(this.banco).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Banco actualizado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-general/maestro-bancos'])
  }

}
