<app-pavso-title-section [title]="'Línea de Operación'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l1">
          <mat-form-field>
            <mat-label>Secuencia</mat-label>
            <input [(ngModel)]="lineaOperacion.lpo_seclpo" type="text" matInput placeholder="Secuencia" name="lpo_seclpo" [disabled]="true">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <select-search
          [label]="'Linea de Producto'"
          [name]="'lpd_codlpd'"
          [value]="'lpd_codlpd'"
          [description]="'description'"
          [data]="lineasProducto"
          (cambioSelect)="lineaOperacion.lpd_codlpd = $event"
          [model]="lineaOperacion.lpd_codlpd"
          [required]="true"
          [disabled]="uid!=='0'">
        </select-search>
        </div>
        <div class="col s12 m12 l5">          
          <select-search
          [label]="'Operarios'"
          [name]="'cli_codcli'"
          [value]="'cli_codcli'"
          [description]="'description'"
          [data]="operarios"
          (cambioSelect)="lineaOperacion.cli_codcli = $event"
          [model]="lineaOperacion.cli_codcli"
          [required]="true">
        </select-search>
      </div>
      </div>
<!-- {{lineaOperacion | json}} -->
    </mat-card>
    <br>
    <pav-form-actions
    [disabled]="loaderData"
    [btnName]="btnName"
    [isLoading]="loaderReg"
    urlBack="/modulo-produccion/lineas-de-produccion-operario"
  ></pav-form-actions>

  </form>
</div>
