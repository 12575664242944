<app-pavso-title-section [title]="'Contrato de personal'" [module]="'Planilla'" [estado]="contratoPersonal.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="contratoPersonal.codnmc" matInput placeholder="Código" name="codnmc"
              required disabled>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="contratoPersonal.descri" matInput placeholder="Descripción" name="descri"
              required>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Colaborador</mat-label>
            <mat-select [(ngModel)]="contratoPersonal.codcli" required name="codcli">
              <mat-option *ngFor="let colaborador of colaboradores" [value]="colaborador.codcli">
                {{colaborador.nomcli}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha de emisión</mat-label>
            <input [(ngModel)]="contratoPersonal.fecemi" name="fecemi" matInput [matDatepicker]="fechaemision">
            <mat-datepicker-toggle matSuffix [for]="fechaemision"></mat-datepicker-toggle>
            <mat-datepicker #fechaemision></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha de inicio</mat-label>
            <input [(ngModel)]="contratoPersonal.fecini" name="fecini" matInput [matDatepicker]="fechainicio">
            <mat-datepicker-toggle matSuffix [for]="fechainicio"></mat-datepicker-toggle>
            <mat-datepicker #fechainicio></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha de término</mat-label>
            <input [(ngModel)]="contratoPersonal.fecter" name="fecter" matInput [matDatepicker]="fechatermino">
            <mat-datepicker-toggle matSuffix [for]="fechatermino"></mat-datepicker-toggle>
            <mat-datepicker #fechatermino></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <div class="pav-card-header green">
            <div class="pav-block"></div>
            <div class="pav-card-header-title">
              Remuneración
            </div>
          </div>
          <br>
          <div class="col s12">
            <mat-form-field>
              <mat-label>Moneda</mat-label>
              <mat-select required name="moneda" [(ngModel)]="contratoPersonal.codtmo" name="codtmo">
                <mat-option value="SO">
                  SOLES
                </mat-option>
                <mat-option value="DO">
                  EN DÓLARES
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12">
            <mat-form-field>
              <mat-label>Remuneración</mat-label>
              <input class="pav-input-align-right" [(ngModel)]="contratoPersonal.imprem" type="number" min="0" matInput placeholder="Remuneración"
                name="imprem" required>
            </mat-form-field>
          </div>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cargo</mat-label>
            <mat-select [(ngModel)]="contratoPersonal.codnca" required name="codnca">
              <mat-option *ngFor="let cargo of cargos" [value]="cargo.codnca">
                {{cargo.descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Plantilla</mat-label>
            <mat-select [(ngModel)]="contratoPersonal.codne0" required name="codne0">
              <mat-option *ngFor="let plantilla of plantillas" [value]="plantilla.ne0_codne0">
                {{plantilla.ne0_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Representante</mat-label>
            <mat-select required name="codrep" [(ngModel)]="contratoPersonal.codrep">
              <mat-option *ngFor="let representante of representantes" [value]="representante.codcli">
                {{representante.descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l1">
          <mat-checkbox class="example-margin" [(ngModel)]="contratoPersonal.indcie" name="indcie">Cerrado
          </mat-checkbox>
        </div>
      </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-planilla/contratos">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
