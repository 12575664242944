import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Cotizacion } from "src/app/models/ventas/cotizacion";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerCotizaciones(codsuc): Observable<Cotizacion[]> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cotizaciones/listar/01/${codsuc}`
    ).pipe(
      map((cotizaciones: any) => {
        return cotizaciones.map(cotizacion => {

          const codigoMoneda = cotizacion.TMO_CODTMO == 'SO' ? 'S/' : '$'

          cotizacion['description'] = `${cotizacion.PCC_NUMPCC} | ${codigoMoneda} ${cotizacion.PCC_IMPTOT} |  ${this.formatearFecha(cotizacion.PCC_FECCRE)} | ${cotizacion.cliente.cli_nomcli}`
          cotizacion['cli_nomcli'] = cotizacion.cliente.cli_nomcli;
          cotizacion['vde_nomvde'] = cotizacion.vendedor.vde_nomvde;
          cotizacion['descri_indsta'] = cotizacion.PCC_INDSTA == '1'? 'APROBADO': 'PENDIENTE';
          cotizacion['tmo_descri'] = cotizacion.TMO_CODTMO == 'SO'? 'SOLES': 'DÓLARES';
          return cotizacion;
        })
      })
    );
  }

  formatearFecha(fecha) {
    // Convertir la cadena de fecha a un objeto Date
    let fechaObj = new Date(fecha);

    // Obtener los componentes de la fecha
    let dia = fechaObj.getDate();
    let mes = fechaObj.getMonth() + 1; // Los meses comienzan desde 0
    let año = fechaObj.getFullYear();

    // Formatear la fecha en dd/mm/yyyy
    let fechaFormateada = `${dia}/${mes}/${año}`;

    return fechaFormateada;
}

  obtenerCotizacion(codsuc, codCotizacion): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cotizaciones/mostrar/01/${codsuc}/${codCotizacion}`
    );
  }

  registrarCotizacion(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cotizaciones/crear`,
      payload
    );
  }

  editarCotizacion(payload): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/cotizaciones/modificar`,
      payload
    );
  }

  obtenerPDFCotizacion(numpcc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cotizaciones/mostrar/pdf/${this._configurationService.obtenerCompaniaCliente()}/${numpcc}`
    );
  }
}
