import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cambiar } from '@shared/state/actions/period.actions';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { cambiarLoadingTypeNomina } from '@shared/state/actions/loading.actions';
import { cambiarDatosNomina } from '@shared/state/actions/nomina.actions';
import { NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-select-query-period',
  templateUrl: './select-query-period.component.html',
  styleUrls: ['./select-query-period.component.css']
})
export class SelectQueryPeriodComponent implements OnInit, OnDestroy {

  months =
  [
    {
      id: "01",
      name: "ENERO",
    },
    {
      id:"02",
      name: "FEBRERO",
    },
    {
      id: "03",
      name: "MARZO",
    },
    {
      id: "04",
      name: "ABRIL",
    },
    {
      id: "05",
      name: "MAYO",
    },
    {
      id: "06",
      name: "JUNIO",
    },
    {
      id: "07",
      name: "JULIO",
    },
    {
      id: "08",
      name: "AGOSTO",
    },
    {
      id: "09",
      name: "SETIEMBRE",
    },
    {
      id: "10",
      name: "OCTUBRE",
    },
    {
      id: "11",
      name: "NOVIEMBRE",
    },
    {
      id: "12",
      name: "DICIEMBRE"
    }
  ];

  loaderData: boolean;

  years: any[] = [];

  monthToday: any;
  yearToday: any;
  today: Date;

  periodo: string;
  yearPeriod: any;
  monthPeriod: any;

  openingYear: any;
  openingMonth: any;
  codMod: any;
  periodos: Array<any>;

  buttonName: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  nomina$: Subscription;
  loading$: Subscription;

  tipos: any[] = [];
  secuencias: any[] = [];
  tipoNomina: any;
  secNomina: any;
  isNomina: boolean;

  constructor(
    public dialogRef: MatDialogRef<SelectQueryPeriodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<PavsoState>,
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month, openingYear, openingMonth, codMod, periodos}) => {
      if(!this.obtenerLocalStoragePeriodo()) {

      }
      this.yearPeriod = year;
      this.monthPeriod = month;
      this.periodo = `${year}${month}`;
      this.openingYear = openingYear;
      this.openingMonth = openingMonth;
      this.codMod = codMod;
      this.periodos = periodos;
      this.isNomina = (codMod == 'W51')? true: false;
    })

    this.today = new Date();
    this.yearToday = this.today.getFullYear();

    this.establecerAnios()

  }

  /**
   * Método que establece los últimos años a partir del año 2000
   */
  establecerAnios(): void {
    for (let index = 2000; index <= this.yearToday; index++) {
      this.years.push({
        id: index.toString(),
        name: index.toString()
      })
    }
  }

  ngOnInit(): void {
    this.nomina$ = this.store.select('nomina').subscribe(({typeNomina, secNomina}) => {
      this.tipoNomina = typeNomina;
      this.secNomina = secNomina;
    })
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && state.isLoadingTypeNominaDialog) {
        this.obtenerTiposNomina();
      }
    })
  }

  /**
   * Método que obtiene los tipos de nómina y establece
   * la primera secuencia de nómina por defecto
   */
  obtenerTiposNomina(): void {

    this.loaderData = true;

    forkObs(
      this._nominaService.obtenerTiposNomina(),
      this._nominaService.obtenerSecuenciaNomina(`${this.yearPeriod}${this.monthPeriod}${this.tipoNomina}`)
    ).then(([tipos, secuencias]) => {

      this.tipos = tipos;
      this.secuencias = secuencias;
      if(secuencias && secuencias.length > 0){
        this.secNomina = secuencias[secuencias.length -1].cod;
      }
      this.loaderData = false;

    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK")
    })

  }

  cancelar(): void {
    this.store.dispatch(cambiarLoadingTypeNomina({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: false, isLoadingTypeNominaDialog: false}))

    this.dialogRef.close();
  }

  /**
   * Establece el periodo de actividad y
   * si se el modulo es planilla, establece los datos de nomina y secuencia,
   * además establecemos el loading de tipo nómina en falso
   */
  consultar() {

    this.yearPeriod = this.periodo.substring(0, 4);
    this.monthPeriod = this.periodo.slice(-2);

    this.establecerLocalStoragePeriodo(this.yearPeriod, this.monthPeriod);

    this.store.dispatch(cambiar({year: this.yearPeriod, month: this.monthPeriod, openingYear: this.openingYear, openingMonth: this.openingMonth, codMod: this.codMod, periodos: this.periodos}))
    this.store.dispatch(cambiarLoadingTypeNomina({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: false, isLoadingTypeNominaDialog: false}))

    if(this.isNomina) {
      const tipoNomina = this.tipos.find(item => item.nti_codnti == this.tipoNomina);
      this.store.dispatch(cambiarDatosNomina({typeNomina: this.tipoNomina, secNomina: this.secNomina, nameTypeNomina: tipoNomina.nti_descri}));
      this.store.dispatch(cambiarLoadingTypeNomina({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: false, isLoadingTypeNominaDialog: false}))
    }

    this.dialogRef.close([this.yearPeriod, this.monthPeriod]);
    unsubscribeSubscription([
      this.loading$,
      this.period$,
      this.nomina$
    ]);

  }

  establecerLocalStoragePeriodo(ano_codano, mes_codmes): void {
    localStorage.setItem('ANO_CODANO', ano_codano); // Año del periodo
    localStorage.setItem('MES_CODMES', mes_codmes);
  }

  obtenerLocalStoragePeriodo(): boolean {

    if(!localStorage.getItem('ANO_CODANO') && !localStorage.getItem('MES_CODMES')) return false;

    this.yearPeriod = localStorage.getItem('ANO_CODANO');
    this.monthPeriod = localStorage.getItem('MES_CODMES');
    console.log('entro a establecer')

    return true;
  }

  /**
   * Cambia el estado de tipo nómina
   * y obtiene las secuencias nómina respectiva
   * al tipo de nómina
   * @param value
   */
  seleccinarTipoNomina(value): void {
    const tipoNomina = this.tipos.find(item => item.nti_codnti == value);
    this.store.dispatch(cambiarDatosNomina({typeNomina: value, secNomina: "", nameTypeNomina: tipoNomina.nti_descri}))
    this.obtenerSecuenciasNomina(`${this.yearPeriod}${this.monthPeriod}${this.tipoNomina}`);
  }

  obtenerSecuenciasNomina(code): void {

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.nomina$,
      this.loading$,
    ]);

  }

}
