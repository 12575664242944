import { PeriodState } from "@data/interfaces/state/period-state";
import { createReducer, on } from "@ngrx/store";
import { cambiar } from "../actions/period.actions";

const year = new Date().getFullYear();
const month = new Date().getMonth() + 1;

const initialYear = year.toString();
const initialMonth = (month <10)? `0${month}`: month.toString();

export const initialState: PeriodState = {
  year  : initialYear,
  month : initialMonth,
  openingYear  : initialYear,
  openingMonth : initialMonth,
  codMod  : "00",
  periodos: []
};

const _periodReducer = createReducer(
  initialState,
  on(cambiar, (_, {year, month, openingYear, openingMonth, codMod, periodos}) =>  {
    return {year, month, openingYear, openingMonth, codMod, periodos}
  }),
);

export function periodReducer(state, action) {
  return _periodReducer(state, action)
}
