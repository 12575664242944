<h1 mat-dialog-title>Cargar XML</h1>
<mat-progress-bar *ngIf="loaderData" mode="indeterminate"></mat-progress-bar>

<div mat-dialog-content>

  <mat-card [ngStyle]="{margin: '10px 0px'}">
    <div [ngStyle]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
      <div>
        <h1 [ngStyle]="{fontWeight: 'bold', fontSize: '12px'}">
          XML {{fileName}}
        </h1>
      </div>
      <div>
        <button *ngIf="recepcionXML.id == 0" mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="fileInput.click()" matTooltip="Cargar XML" [disabled]="loaderData">
          <mat-icon>upload_file</mat-icon>
        </button>
        <button *ngIf="recepcionXML.id != 0" mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="descargarXML()" matTooltip="Descargar XML" [disabled]="loaderData">
          <mat-icon>download</mat-icon>
        </button>
        <button *ngIf="recepcionXML.id != 0" mat-icon-button color="accent" aria-label="Example icon button with a home icon" (click)="verFormatoPDF()" matTooltip="Ver PDF" [disabled]="loaderData">
          <mat-icon>picture_as_pdf</mat-icon>
        </button>
        <button *ngIf="recepcionXML.id != 0" mat-icon-button color="warn" aria-label="Example icon button with a home icon" (click)="eliminarXML()" matTooltip="Eliminar" [disabled]="loaderData">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>

  <input type="file" #fileInput (change)="onFileSelected($event)" accept=".xml" style="display: none;"/>

</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onUpload()">{{!this.recepcionXML.cfe_arcfis ? 'Cerrar': 'Grabar'}}</button>
</div>
