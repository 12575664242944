import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OpenClosePeriod } from '@data/interfaces/open-close-period';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { ConfigurationService, MenuService, ModuleService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';

@Component({
  selector: 'app-select-close-period',
  templateUrl: './select-close-period.component.html',
  styleUrls: ['./select-close-period.component.css']
})
export class SelectClosePeriodComponent implements OnInit, OnDestroy {

  body: OpenClosePeriod;
  action: string = 'A';
  codmod: string;

  modulos: any[];

  cierrePeriodo$: Subscription;

  buttonName: INameConstant = NAMES_CONSTANTS;

  estado: 'A' | 'C' = 'A';

  period$: Subscription;
  language$: Subscription;

  anioActividad: string;
  mesActividad: string;

  anioApertura: string;
  mesApertura: string;

  language: string;

  constructor(
    public dialogRef: MatDialogRef<SelectClosePeriodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _moduleService: ModuleService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private _menuService: MenuService,
    private store: Store<PavsoState>,
    public dialog: MatDialog,
  ) {

    this.body = {
      codcia: this._configurationService.obtenerCompaniaCliente(),
      codsuc: "01",
      codano: null,
      codmes: null,
      codmod: this.codmod,
      action: this.action,
    }

    this.period$ = this.store.select('period').subscribe(({openingYear, openingMonth, year, month, codMod}) => {
      this.anioActividad = year;
      this.mesActividad = month;
      this.anioApertura = openingYear;
      this.mesApertura = openingMonth;
      this.body.codmod = codMod;
      this.codmod = codMod;
      console.log('cdmod', this.codmod)
    })

    this.language$ = this.store.select('language').subscribe(state => {
      this.language = state.language;
    })

    this.modulos = [];

  }

  ngOnInit(): void {
    this.obtenerModulos()
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  obtenerModulos(): void {
      this._menuService.obtenerMenu(this.language).subscribe(
        menus => {
          this.modulos = menus.map(item => ({code: item.codeMod, name: item.title}))
          console.log('menus', this.modulos)
        },
        error => {
          this._snackBarService.showError(error.error.msg, 'Ok')
        }
      )
  }

  abrircerrar(f: NgForm): void {
    this.body.action = this.estado;

    if(this.estado == 'A') {
      this.body.codano = this.anioActividad;
      this.body.codmes = this.mesActividad;
    } else {
      this.body.codano = this.anioApertura;
      this.body.codmes = this.mesApertura;
    }

    this.cierrePeriodo$ = this._moduleService.abrirCerrarPeriodo(this.body).subscribe(
      response => {
        this._snackBarService.showSuccess(`Cierre de período | Año:${this.body.codano} Mes: ${this.body.codmes} exitoso`, 'Ok')
        this.dialogRef.close();
        window.location.reload();
      },
      err => {
        this._snackBarService.showError('Error al cerrar período', 'OK');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cierrePeriodo$,
      this.period$,
    ])

  }

}
