import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexPlotOptions, ApexTitleSubtitle, ApexTooltip, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { AuthenticationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  noData: ApexNoData;
};

@Component({
  selector: 'app-composicion-edad-trabajadores',
  templateUrl: './composicion-edad-trabajadores.component.html',
  styleUrls: ['./composicion-edad-trabajadores.component.css']
})
export class ComposicionEdadTrabajadoresComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['initial', 'trabajadores', 'edad1', 'edad2', 'edad3', 'edad4', 'edad5'];
  dataSource = [];

  chartOptions: Partial<ChartOptions>;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  total: string | number = 0;

  apexChart: ApexCharts;

  period$: Subscription;
  loading$: Subscription;
  informe$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = [];

  }

  ngOnInit(): void {

    // INICIALIZAR GRÁFICO
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function(chart, w, e) {
          }
        }
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0"
      ],
      noData: {
        text: 'Cargando...'
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        show: true,
        position: "bottom"
      },
      grid: {
        show: true
      },
      yaxis: {
        title: {
          text: "# Colaboradores",
        },
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px"
          },
        }
      },
      xaxis: {
        categories: [
          "21-30",
          "31-40",
          "41-50",
          "51-60",
          "60-(+)"
        ],
        labels: {
          style: {
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
            ],
            fontSize: "12px"
          },
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + "";
          }
        }
      },
      title: {
        text: `Total Colaboradores: ${this.total}`,
        // offsetY: 320,
        align: "center",
        style: {
          color: "#444"
        }
      }
    };

    this.apexChart = new ApexCharts(document.querySelector("#chartEdadTrabajadores"), this.chartOptions);

    this.apexChart.render();

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this.informe$ = this._nominaService.obtenerInformeEdadTrabajadores(this.year, this.month).subscribe(
      response => {
        this.dataSource = response.datatable;
        this.total = response.datatable[0].trabajadores;

        this.apexChart.updateSeries([
          response.chart
        ])

        this.apexChart.updateOptions({
          title: {
            text: `Total Colaboradores: ${this.total}`,
            // offsetY: 320,
            align: "center",
            style: {
              color: "#444"
            }
          }
        })

      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/informes-de-composicion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.informe$]);
  }

}
