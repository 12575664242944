import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { AlmacenService } from "src/app/services";

@Component({
  selector: 'paso-saldo',
  templateUrl: './paso-saldo.component.html',
  styleUrls: ['./paso-saldo.component.scss']
})
export class PasoSaldoComponent implements OnInit, OnDestroy {

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;

  displayedColumns: string[] = ['prd_codprd', 'prd_desesp', 'ume_codume', 'alm_codalm', 'ALM_DESCRI', 'spa_canini', 'spa_caning', 'spa_cansal', 'spa_canfin', 'spa_mepfin', 'TIN_DESCRI'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  year: string;
  month: string;

  loadingRevisar: boolean;
  loadingProcesar: boolean;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
    ) {
      this.period$ = this.store.select('period').subscribe(state => {
        this.year = state.year;
        this.month = state.month;
      })

      this.dataSource = fillTable([], this.paginator, this.sort);
    }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  revisar(): void {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.loadingRevisar = true;
    this._almacenService.revisarSaldoNegativo(this.year, this.month).subscribe(
      response => {
        if(response.length > 0) {
          this.dataSource = fillTable(response, this.paginator, this.sort);
          this.loadingRevisar = false;
          this._snackBarService.showSuccess('Revisión realizada', 'Ok');
          return;
        }
        this._snackBarService.showError('No se obtuvieron valores para este período', 'Ok');
        this.loadingRevisar = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loadingRevisar = false;
      }
    )
  }

  procesar(): void {

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$]);
  }
}
