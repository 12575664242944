import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'vendedor-dialog',
  template: `
    <div mat-dialog-content>
      <vendedor-form [id]="data.id"></vendedor-form>
    </div>
  `,
  styleUrls: ['./vendedor-dialog.component.scss']
})
export class VendedorDialog {
  constructor(
    public dialogRef: MatDialogRef<VendedorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data id', data.id)
  }
}
