<app-pavso-title-section [title]="'Resultados por planta cervecera'" [module]="'CRM'"></app-pavso-title-section>

<div class="row">
  <div class="col s3">
    <div class="pav-chart-container">
      <div class="pav-chart-header-icon"></div>
      <div class="pav-chart-header-text">Cuadro 03</div>
      <div class="pav-separator"></div>
      <div class="pav-chart-header-more">
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="zone">
        <th mat-header-cell *matHeaderCellDef> ZONA DE VENTA </th>
        <td mat-cell *matCellDef="let element"> {{element.zone}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> MONTO </th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="col s9">
    <mat-card class="mat-elevation-z8">
      <div id="chart">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions"
          [yaxis]="chartOptions.yaxis"
          [legend]="chartOptions.legend"
          [fill]="chartOptions.fill"
          [stroke]="chartOptions.stroke"
          [tooltip]="chartOptions.tooltip"
          [xaxis]="chartOptions.xaxis"
        ></apx-chart>
      </div>
    </mat-card>
  </div>
</div>
