import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { Subscription } from 'rxjs';
import { ConfiguracionTecnica } from 'src/app/models/almacen/inventario/configuracion-tecnica';
import { AlmacenService } from 'src/app/services';
import { KardexService } from 'src/app/services/api/almacen/operaciones/kardex.service';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { FiltroConsultaStock } from './models/filtro-consulta-stock';
import { IParam } from 'src/app/services/api/utilities/params';
import { FiltroMovimientos } from 'src/app/models/almacen/filtro-movimientos';
import { TipoInventarioService } from 'src/app/services/api/almacen/configuracion/tipo-inventario.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-consultar-stock',
  templateUrl: './consultar-stock.component.html',
  styleUrls: ['./consultar-stock.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ConsultarStockComponent implements OnInit, OnDestroy {

  date = new FormControl(moment());

  productoSeleccionado: any;

  almacenSeleccionado: any;

  chosenYearHandler(normalizedYear: Moment): void {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['prd_codprd', 'prd_desesp', 'MPR_DESCRI', 'ume_codume', 'spa_cansal', 'FPR_DESCRI', 'TIN_DESCRI', 'modelo', 'part_number'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsDetalleAlmacen: string[] = ['almacen', 'descripcion', 'saldo', 'inicial', 'ingresos', 'salidas'];
  dataSourceDetalleAlmacen: MatTableDataSource<any>;

  @ViewChild('paginatorDetalleAlmacen') paginatorDetalleAlmacen: MatPaginator;
  @ViewChild(MatSort) sortDetalleAlmacen: MatSort;

  displayedColumnsDetalleLote: string[] = ['lote', 'cantidad', 'fec_fabricacion', 'fec_vencimiento', 'lot_glolot'];
  dataSourceDetalleLote: MatTableDataSource<any>;

  @ViewChild('paginatorDetalleLote') paginatorDetalleLote: MatPaginator;
  @ViewChild(MatSort) sortDetalleLote: MatSort;

  displayedColumnsKardex: string[] = ['tdo_codtdo', 'alm_descri', 'mac_fecmac', 'mac_cantra', 'ume_codume', 'ot', 'mmo_codmmo', 'mmo_descri'];
  dataSourceKardex: MatTableDataSource<any>;

  @ViewChild('paginatorKardex') paginatorKardex: MatPaginator;
  @ViewChild(MatSort) sortKardex: MatSort;

  displayedColumnsLote: string[] = ['tdo_codtdo', 'spl_numlot', 'ALM_DESCRI', 'prd_codprd', 'mac_fecmac', 'map_canprd', 'ume_codume', 'mmo_codmmo', 'mmo_descri', 'cli_nomcli'];
  dataSourceLote: MatTableDataSource<any>;

  @ViewChild('paginatorLote') paginatorLote: MatPaginator;
  @ViewChild(MatSort) sortLote: MatSort;


  displayedColumnsAlmacenSerie: string[] = ['movim_almacen', 'almacen', 'fecha', 'doc', 'nro_parte', 'motivo', 'desc_motivo', 'producto', 'des_producto'];
  dataSourceAlmacenSerie: MatTableDataSource<any>;

  @ViewChild('paginatorAlmacenSerie') paginatorAlmacenSerie: MatPaginator;
  @ViewChild(MatSort) sortAlmacenSerie: MatSort;


  displayedColumnsSerie: string[] = ['almacen', 'serie', 'saldo', 'fec_ven', 'fec_fab', 'serie_madre', 'glosa'];
  dataSourceSerie: MatTableDataSource<any>;

  @ViewChild('paginatorSerie') paginatorSerie: MatPaginator;
  @ViewChild(MatSort) sortSerie: MatSort;

  loaderKardex: boolean = false;

  productos: any[] = [];
  lineas: any[] = [];
  almacenes: any[] = [];
  familias: any[] = [];
  marcas: any[] = [];
  unidadesTransporte: any[] = [];
  tiposInventario: any[] = [];
  lotes: any[] = [];

  producto$: Subscription;
  almacen$: Subscription;
  familia$: Subscription;
  linea$: Subscription;
  marca$: Subscription;
  unidad$: Subscription;
  loading$: Subscription;
  dataFiltro$: Subscription;
  configuracionTecnica$: Subscription;
  consulta$: Subscription;
  consultaDetalleLote$: Subscription;
  period$: Subscription;
  establecimiento$: Subscription;
  tipoInventario$: Subscription;
  consultarDetalle$: Subscription;
  listarKardex$: Subscription;

  configuracion: ConfiguracionTecnica;

  filtroConsultaStock: FiltroConsultaStock;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  anioPeriodo: string;
  mesPeriodo: string;

  establecimiento: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private _kardexService: KardexService,
    private _tipoInventarioService: TipoInventarioService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceDetalleAlmacen = fillTable([], this.paginatorDetalleAlmacen, this.sortDetalleAlmacen);
    this.dataSourceDetalleLote = fillTable([], this.paginatorDetalleLote, this.sortDetalleLote);

    this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);

    this.dataSourceAlmacenSerie = fillTable([], this.paginatorAlmacenSerie, this.sortAlmacenSerie);
    this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

    this.dataSourceKardex = fillTable([], this.paginatorKardex, this.sortKardex);

    this.configuracion = new ConfiguracionTecnica();

    this.filtroConsultaStock = new FiltroConsultaStock();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.period$ = this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
    })
  }


  loadData(): void {
    this.loaderData = true;

    this.dataFiltro$ = this._almacenService.obtenerDataFiltroConsultarStock().subscribe(
      dataFiltro => {
        console.log('dataFiltro', dataFiltro)
        this.productos = dataFiltro.productos;
        this.productos.forEach(item => item['description'] = `${item.prd_codprd} | ${item.DESC_ESPA}`)
        console.log('this.productos', this.productos)

        this.lineas = dataFiltro.lineas;

        this.almacenes = dataFiltro.almacenes;
        this.almacenes.forEach(item => item['description'] = `${item.ALM_CODALM} | ${item.ALM_DESCRI}`)
        console.log('this.almacenes', this.almacenes)

        if(this.establecimiento && this.establecimiento.trim() != '') {
          this.almacenes = this.almacenes.filter(item => item.TIE_CODTIE == this.establecimiento);
        }

        this.familias = dataFiltro.familias;

        this.marcas = dataFiltro.marcas;
        console.log('marcas', this.marcas)

        this.unidadesTransporte = dataFiltro.unidades;

        this.configuracionTecnica$ = this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
          configuracion => {
            console.log('configuracion', configuracion)
            this.configuracion = configuracion[0];

            if(this.configuracion.s70_venlot == 1 && this.configuracion.s70_glolot == 0) {
              this.displayedColumnsDetalleLote = ['lote', 'cantidad', 'fec_fabricacion', 'fec_vencimiento'];
            }

            if(this.configuracion.s70_glolot == 1 && this.configuracion.s70_venlot == 0) {
              this.displayedColumnsDetalleLote = ['lote', 'cantidad', 'fec_fabricacion', 'lot_glolot'];
            }

            if(this.configuracion.s70_venlot == 0 && this.configuracion.s70_glolot == 0) {
              this.displayedColumnsDetalleLote = ['lote', 'cantidad', 'fec_fabricacion'];
            }

            if(this.configuracion.s70_glolot == 1 && this.configuracion.s70_venlot == 0) {
              this.displayedColumnsDetalleLote = ['lote', 'cantidad', 'fec_fabricacion', 'fec_vencimiento', 'lot_glolot'];
            }

            this._tipoInventarioService.obtenerTiposDeInventario().subscribe(
              tiposInventario => {
                this.tiposInventario = tiposInventario;
                this.buscar();
                this.loaderData = false;
              },
              error => {
                this._snackBarService.showError('Error al obtener tipos de inventario', 'Ok');
                this.loaderData = false;
              }
            )

          },
          error => {
            this.loaderData = false;
            this._snackBarService.showError('Error al obtener configuracion almacen', 'Ok');

          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener filtros', 'Ok');
        this.loaderData = false;
      }
    )

  }

  consultarDetalle(row): void {

    this.productoSeleccionado = row;
    this.dataSourceDetalleAlmacen = fillTable([], this.paginatorDetalleAlmacen, this.sortDetalleAlmacen);

    const filtros = {
      productos: [row.prd_codprd],
      familias: [],
      subFamilias: [],
      almacenes: [],
      tiposInventario: [],
      lineas: [],
      marcas: [],
      establecimiento: !this.establecimiento || this.establecimiento == '' ? '': this.establecimiento
    }

    this._almacenService.obtenerInformeSaldoPorProducto(this.anioPeriodo, this.mesPeriodo, filtros).subscribe(
      detalleAlmacen => {

        this.dataSourceDetalleAlmacen = fillTable(detalleAlmacen, this.paginatorDetalleAlmacen, this.sortDetalleAlmacen);

        this.loaderData = false;

      },
      error => {
        this._snackBarService.showError('Error al obtener detalle almacen', 'Ok');
        this.loaderData = false;
      }
    )


  }

  consultarDetalleLote(row): void {

    this.almacenSeleccionado = row;

    const params: IParam[] = [];

    params.push({
      param: 'producto',
      value: this.productoSeleccionado.prd_codprd
    })

    params.push({
      param: 'almacen',
      value: row.alm_codalm
    })


    this.consultaDetalleLote$ = this._almacenService.consultarStockDetalleLote(this.anioPeriodo, this.mesPeriodo, { params }).subscribe(
      detalleLote => {

        const filtrados = detalleLote.filter(item => item.spa_canfin > 0);
        console.log('filtrados', filtrados)
        this.dataSourceDetalleLote = fillTable(filtrados, this.paginatorDetalleLote, this.sortDetalleLote);

      },
      error => {
        this._snackBarService.showError('Error al obtener detalle lote', 'Ok');
        this.loaderData = false;
      }
    )
  }

  seleccionarLote(event): void {

    console.log(event)
    if(event) {
      const lotes = this.lotes.filter(item => item.spl_numlot == event);
      console.log('lotes',lotes)
      this.dataSourceLote = fillTable(lotes, this.paginatorLote, this.sortLote);
      return;
    }

    this.dataSourceLote = fillTable(this.lotes, this.paginatorLote, this.sortLote);

  }

  buscar(): void {
    this.loaderData = true;

    const params: IParam[] = [];

    if (this.filtroConsultaStock.producto) {
      params.push({
        param: 'producto',
        value: this.filtroConsultaStock.producto
      })
    }

    if (this.filtroConsultaStock.tipoInventario) {
      params.push({
        param: 'tipoinvntario',
        value: this.filtroConsultaStock.tipoInventario
      })
    }

    if (this.filtroConsultaStock.almacen) {
      params.push({
        param: 'almacen',
        value: this.filtroConsultaStock.almacen
      })
    }

    this.consulta$ = this._almacenService.consultarStockProductosConsolidado(this.anioPeriodo, this.mesPeriodo, { params }).subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);

        const filtro: FiltroMovimientos = new FiltroMovimientos();
        filtro.almacenes = this.filtroConsultaStock.almacen;

        this.listarKardex$ = this._kardexService.listarKardex(this.anioPeriodo, this.mesPeriodo, filtro).subscribe(
          movimientos => {

            movimientos.forEach(element => {
              element['alm_descri'] = element.almacen.ALM_DESCRI;

            });

            this.dataSourceKardex = fillTable(movimientos, this.paginatorKardex, this.sortKardex);

            const params2: IParam[] = [];

            this.consultarDetalle$ = this._almacenService.consultarStockKardexLote(this.anioPeriodo, this.mesPeriodo, { params: params2 }).subscribe(
              detalleKardexLote => {
                this.lotes = detalleKardexLote;
                this.dataSourceLote = fillTable(detalleKardexLote, this.paginatorLote, this.sortLote);
                this.loaderData = false;
              },
              error => {
                this._snackBarService.showError('Error al obtener detalle lote', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener resultado de consulta stock', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener resultado de consulta stock', 'Ok');
        this.loaderData = false;
      }
    )
  }

  consultarStockLote(): void {
    console.log('consultar')


  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.producto$,
      this.almacen$,
      this.familia$,
      this.linea$,
      this.marca$,
      this.unidad$,
      this.loading$,
      this.dataFiltro$,
      this.configuracionTecnica$,
      this.consulta$,
      this.consultaDetalleLote$,
      this.period$,
      this.establecimiento$,
      this.tipoInventario$,
      this.consultarDetalle$,
      this.listarKardex$,
    ])
  }
}
