import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { searchInTable } from "@utils/tables/table";

@Component({
  selector: 'lugar-despacho-list',
  templateUrl: './lugar-despacho-list.component.html',
  styleUrls: ['./lugar-despacho-list.component.scss']
})
export class LugarDespachoListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'MPR_CODMPR', 'MPR_DESCRI', 'MPR_INDSTA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  ngOnInit(): void {

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {

  }
}
