import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ClaseActivo } from "src/app/models/crm/clase-activo";
import { ClaseActivoService } from "src/app/services/api/activo-fijo/maestros/clase-activo.service";

@Component({
  selector: 'clase-activo-form',
  templateUrl: './clase-activo-form.component.html',
  styleUrls: ['./clase-activo-form.component.scss']
})
export class ClaseActivoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  claseActivo: ClaseActivo;

  loading$: Subscription;

  constructor(
    private _claseActivoService: ClaseActivoService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=>{
      this.uid = id;

      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

    })
  }

  enviarFormulario(): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarClase(): this.actualizarClase();
  }

  registrarClase(): void {
    this._claseActivoService.registrarClaseActivo(this.claseActivo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Clase activo registrado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarClase(): void {
    this._claseActivoService.actualizarClaseActivo(this.claseActivo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Clase activo actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
