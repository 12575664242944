<app-pavso-title-section [title]="'Requerimiento de materiales'" [module]="'Operaciones'" [estado]="requerimientoMaterial.rma_indsta">
</app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarRequerimiento(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">

      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Nro. Reque.</mat-label>
            <input matInput placeholder="Nro. Reque." name="nro_req" disabled
              [(ngModel)]="requerimientoMaterial.rma_numrma">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input  matInput [max]="maxDate"
              [matDatepicker]="fechahoy" name="rma_fecpro" [(ngModel)]="requerimientoMaterial.rma_fecpro"
              [disabled]="loaderData">
            <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
            <mat-datepicker #fechahoy></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Situacion</mat-label>
            <mat-select name="situacion" [disabled]="loaderData">
              <mat-option>
                situacion 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Solicitado por</mat-label>
            <mat-select [(ngModel)]="requerimientoMaterial.cli_codcli" name="cli_codcli" [disabled]="loaderData">
              <mat-option *ngFor="let cliente of clientes" [value]="cliente.cli_codcli">
                {{cliente.cli_nomcli}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Centro de costos</mat-label>
            <mat-select [(ngModel)]="requerimientoMaterial.ccs_codccs" name="ccs_codccs" [disabled]="loaderData">
              <mat-option *ngFor="let centro of centros" [value]="centro.ccs_codccs">
                {{centro.ccs_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>

    <button mat-icon-button color="primary" type="button" (click)="seleccionarProducto()">
      <mat-icon>add</mat-icon>
    </button>
    &nbsp;
    <mat-form-field class="pavso_input_search">
      <mat-label>Código de barra</mat-label>
      <input #code matInput [placeholder]="LABELS_NAME.BTN_SEARCH" (keyup.enter)="searchCode(code.value)">
      <mat-icon matSuffix>calendar_view_week</mat-icon>
    </mat-form-field>

    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>
      <div class="pav-separator"></div>
    </div>
    <div class="mat-elevation-z0">

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
            <button matTooltip="Editar producto" (click)="editarProducto(row)" type="button" mat-icon-button
              color="primary">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="item"> {{i + 1}} </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.PRD_CODPRD}} </td>
        </ng-container>

        <ng-container matColumnDef="part_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Part Number </th>
          <td mat-cell *matCellDef="let row" data-label="Part Number"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row" data-label="Descripción">
            <div class="pavso-container-description">
              {{row.PRD_DESESP}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="cantidad">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
          <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.PCD_CANSOL}} </td>
        </ng-container>

        <ng-container matColumnDef="um">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
          <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.UME_CODVEN}} </td>
        </ng-container>

        <ng-container matColumnDef="cantidad_atendida">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Atendida </th>
          <td mat-cell *matCellDef="let row" data-label="Cantidad Atendida"> {{row.PCD_CANSOL}} </td>
        </ng-container>

        <ng-container matColumnDef="observaciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Observaciones </th>
          <td mat-cell *matCellDef="let row" data-label="Observaciones"> {{row.PCD_GLOPCD}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">No se encontraron registros</td>

        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl4">
          <!-- <producto-select></producto-select> -->
          <mat-form-field>
            <mat-label>Producto</mat-label>
            <mat-select name="producto" [disabled]="loaderData">
              <mat-option *ngFor="let producto of productosArr; trackBy: trackByFn" [value]="producto.prd_codprd">
                {{producto.prd_desesp}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Cantidad</mat-label>
            <input matInput placeholder="Cantidad" name="cantidad" type="number" [disabled]="loaderData">
          </mat-form-field>

          <section class="example-section">
            <mat-checkbox class="example-margin" [disabled]="loaderData">Indicador de transformación</mat-checkbox>
          </section>
        </div>

        <div class="col s12 m12 l12 xl4">
          <mat-form-field>
            <mat-label>Almacen</mat-label>
            <mat-select name="ALM_CODALM" [disabled]="loaderData" [disabled]="loaderData">
              <mat-option [value]="almacen.alm_codalm" *ngFor="let almacen of almacenes; trackBy: trackByFn">
                {{almacen.alm_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Producto</mat-label>
            <mat-select name="producto" [disabled]="loaderData">
              <mat-option *ngFor="let producto of productosArr" [value]="producto.prd_codprd">
                {{producto.prd_desesp}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Stock Disponible</mat-label>
            <input matInput placeholder="Stock Disponible" name="stock_disponible" type="number"
              [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl4">
          <mat-form-field>
            <mat-label>Especificaciones</mat-label>
            <textarea matInput placeholder="Especificaciones" [disabled]="loaderData"></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl4">
          <p>Aprobación:</p>
          <h3>APROBADO</h3>

        </div>
        <div class="col s12 m12 l12 xl4">
          <mat-form-field>
            <mat-label>Archivo</mat-label>
            <input matInput placeholder="Archivo" name="archivo" [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl4">
          <button mat-fab color="primary" [disabled]="loaderData">
            <mat-icon>image</mat-icon>
          </button>
          &nbsp;
          <button mat-fab color="primary" [disabled]="loaderData">
            <mat-icon>search</mat-icon>
          </button>
          &nbsp;
          <button mat-fab color="primary" [disabled]="loaderData">
            <mat-icon>print</mat-icon>
          </button>
          &nbsp;
          <button mat-fab color="primary" [disabled]="loaderData">
            <mat-icon>description</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>


</div>
