<app-pavso-title-section [title]="'Composición por cantidad colaboradores'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-chart-container">
    <div class="pav-chart-header-icon"></div>
    <div class="pav-chart-header-text">Período {{year}}</div>
    <div class="pav-separator"></div>
    <div class="pav-chart-header-more">
    </div>
  </div>
  <div class="pav-chart-content">

    <div class="row">
      <div class="col s12">
        <div id="chartCantidadTrabajadores">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m12 l2"></div>
      <div class="col s12 m12 l8">
        <table mat-table [dataSource]="dataSource1" class="mat-elevation-z0">

          <ng-container matColumnDef="initial">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"> {{element.initial}} </td>
          </ng-container>

          <ng-container matColumnDef="ene">
            <th mat-header-cell *matHeaderCellDef> ENE </th>
            <td mat-cell *matCellDef="let element"> {{element.ene}} </td>
          </ng-container>

          <ng-container matColumnDef="feb">
            <th mat-header-cell *matHeaderCellDef> FEB </th>
            <td mat-cell *matCellDef="let element"> {{element.feb}} </td>
          </ng-container>

          <ng-container matColumnDef="mar">
            <th mat-header-cell *matHeaderCellDef> MAR </th>
            <td mat-cell *matCellDef="let element"> {{element.mar}} </td>
          </ng-container>

          <ng-container matColumnDef="abr">
            <th mat-header-cell *matHeaderCellDef> ABR </th>
            <td mat-cell *matCellDef="let element"> {{element.abr}} </td>
          </ng-container>

          <ng-container matColumnDef="may">
            <th mat-header-cell *matHeaderCellDef> MAY </th>
            <td mat-cell *matCellDef="let element"> {{element.may}} </td>
          </ng-container>

          <ng-container matColumnDef="jun">
            <th mat-header-cell *matHeaderCellDef> JUN </th>
            <td mat-cell *matCellDef="let element"> {{element.jun}} </td>
          </ng-container>

          <ng-container matColumnDef="jul">
            <th mat-header-cell *matHeaderCellDef> JUL </th>
            <td mat-cell *matCellDef="let element"> {{element.jul}} </td>
          </ng-container>

          <ng-container matColumnDef="ago">
            <th mat-header-cell *matHeaderCellDef> AGO </th>
            <td mat-cell *matCellDef="let element"> {{element.ago}} </td>
          </ng-container>

          <ng-container matColumnDef="set">
            <th mat-header-cell *matHeaderCellDef> SET </th>
            <td mat-cell *matCellDef="let element"> {{element.set}} </td>
          </ng-container>

          <ng-container matColumnDef="oct">
            <th mat-header-cell *matHeaderCellDef> OCT </th>
            <td mat-cell *matCellDef="let element"> {{element.oct}} </td>
          </ng-container>

          <ng-container matColumnDef="nov">
            <th mat-header-cell *matHeaderCellDef> NOV </th>
            <td mat-cell *matCellDef="let element"> {{element.nov}} </td>
          </ng-container>

          <ng-container matColumnDef="dic">
            <th mat-header-cell *matHeaderCellDef> DIC </th>
            <td mat-cell *matCellDef="let element"> {{element.dic}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
        </table>
      </div>
      <div class="col s12 m12 l2"></div>
    </div>
    <br>
    <div class="row">
      <div class="col s12">
        <div id="chartCantidadVigenteCesado">
        </div>
      </div>
    </div>
  </div>
</div>
<mat-card class="mat-elevation-z0">

  <button class="pav-button-cancel" mat-flat-button (click)="volver()" type="button"
    >{{buttonsName.BTN_BACK | translate}}</button>

</mat-card>
