import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ContabilidadService, GeneralService } from 'src/app/services';

@Component({
  selector: 'app-configurar-documento-cobranza',
  templateUrl: './configurar-documento-cobranza.component.html',
  styleUrls: ['./configurar-documento-cobranza.component.css']
})
export class ConfigurarDocumentoCobranzaComponent implements OnInit, OnDestroy {

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['situacion', 'cuenta_sol', 'cuenta_dolar', 'cuenta_enlace_sol', 'cuenta_enlace_dolar', 'solicitante'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderData: boolean = false;
  loaderReg: boolean = false;

  subdiarios: any[] = [];
  bancos: any[] = [];
  centros: any[] = [];

  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _generalService: GeneralService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  subdiarios$: Subscription;
  bancos$: Subscription;
  centros$: Subscription;
  loadData(): void {

    this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => {
        this.subdiarios = subdiarios;

        this.bancos$ = this._generalService.listarBancos().subscribe(
          bancos => {
            this.bancos = bancos;

            this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
              centros => {
                this.centros = centros;
                this.loaderData = false;
              },
              error => {
                this._snackBarService.showError("Error al obtener centros", "OK")
                this.loaderData = false;
              }
            )

          },
          error => {
            this._snackBarService.showError("Error al obtener bancos", "OK")
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError("Error al obtener subdiarios", "OK")
        this.loaderData = false;
      }
    )


  }

  registrarConfiguracion(f: NgForm): void {

  }

  volver(): void {}

}
