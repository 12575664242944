export class Cobrador {
  CIA_CODCIA: string;
  COB_CODCOB: string;
  VDE_CODVDE: string;
  COB_NOMCOB: string;
  COB_DIRCOB: string;
  COB_INDSTA: string;
  COB_CODUSE: string;
  COB_FECUPD: Date;

  constructor() {
    this.COB_FECUPD = new Date();
    this.COB_INDSTA = '1';
  }
}
