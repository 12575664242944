import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AlmacenService, ConfigurationService, VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { UnidadTransporteService } from 'src/app/services/api/almacen/maestros/unidad-transporte.service';
import { ConductorService } from 'src/app/services/api/almacen/maestros/conductor.service';
import { UnidadTransporte } from 'src/app/models/almacen/maestros/unidad-transporte';

@Component({
  selector: 'app-unidad-transporte-form',
  templateUrl: './unidad-transporte-form.component.html',
  styleUrls: ['./unidad-transporte-form.component.css']
})
export class UnidadTransporteFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  transportUnit: UnidadTransporte;

  choferes: any[] = [];
  transportistas: any[] = [];

  chofer$: Subscription;
  transportista$: Subscription;
  sendForm$: Subscription;
  loading$: Subscription;
  unidadesTransporte$: Subscription;
  transportistas$: Subscription;
  choferes$: Subscription;

  buttonName: INameConstant = NAMES_CONSTANTS;

  btnName: string;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private _conductorService: ConductorService,
    private _ventasService: VentasService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _unidadTransporteService: UnidadTransporteService,
    private store: Store<PavsoState>
  ) {

    this.initialize();

  }

  initialize(): void {
    this.transportUnit = new UnidadTransporte();
    this.transportUnit.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.transportUnit.UTR_CODUSE = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.loadMaestros();
    })
  }

  obtenerUnidadTransporte() {
    this._unidadTransporteService.obtenerUnidadTransporte(this.uid).subscribe(
      unidad => {

        this.transportUnit = unidad;
        this.loaderData = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  loadMaestros(): void {

    this.choferes$ = this._conductorService.obtenerConductores().subscribe(
      choferes => {
        this.choferes = choferes;

        this.transportista$ = this._almacenService.obtenerTransportistas().subscribe(
          transportistas => {
            this.transportistas = transportistas;

            if(this.uid != '0') {
              this.obtenerUnidadTransporte()
              return;
            };

            this.loaderData = false;
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )

  }

  esValidoFormulario(): boolean {

    if(this.transportUnit.UTR_PLAUTR.length != 7) {
      this._snackBarService.showError('La placa debe contener 7 caracteres', 'Ok');
      return false;
    }

    return true;
  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;

    if(!this.esValidoFormulario()) {
      this.loaderReg = false;
      return;
    }

    this.uid == '0' ? this.registrarUnidad(f): this.actualizarUnidad(f);
  }

  registrarUnidad(f: NgForm): void {

    this.sendForm$ = this._unidadTransporteService.registrarUnidadTransporte(this.transportUnit).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Unidad de transporte registrado', '400px', '400px', '');
        this.transportUnit.UTR_CODUTR = response.UTR_CODUTR;
        this.uid = response.UTR_CODUTR;
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )

  }

  actualizarUnidad(f: NgForm): void {
    this.sendForm$ = this._unidadTransporteService.actualizarUnidadTransporte(this.transportUnit).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Unidad de transporte actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  registroNuevo(): void {
    this.initialize();
    this.uid = '0';
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.chofer$,
      this.transportista$,
      this.sendForm$
    ])
  }

}
