<app-pavso-title-section [title]="'Turnos'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">

      <table-title-action title="Todos los turnos" url="/modulo-produccion/turno" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <a matTooltip="Editar turno"
                      class="pav-btn-circle-edit"
                      [routerLink]="['/modulo-produccion/turno', row.tur_codtur]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="tur_codtur">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.tur_codtur}} </td>
            </ng-container>

            <ng-container matColumnDef="tur_destur">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.tur_destur}} </td>
            </ng-container>

            <ng-container matColumnDef="tur_horini">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Hora Inicio </th>
              <td mat-cell *matCellDef="let row" data-label="Hora Inicio"> {{row.tur_horini}} </td>
            </ng-container>


            <ng-container matColumnDef="tur_horfin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Hora Término </th>
              <td mat-cell *matCellDef="let row" data-label="Hora Término"> {{row.tur_horfin}} </td>
            </ng-container>


            <ng-container matColumnDef="tur_indsta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.tur_indsta == '1' ? 'VIGENTE': 'ANULADO'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
