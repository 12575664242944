<h1 mat-dialog-title>Adjuntar documentos</h1>

<div align="right" [ngStyle]="{marginBottom: '5px'}">
  <button mat-button (click)="agregar()" color="accent">Agregar</button>
</div>
<div class="overflow-x overflow-y">
  <table mat-table [dataSource]="dataSourceImagenes" matSort
    [ngStyle]="{border: '4px #ddd solid', borderRadius: '15px'}">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Archivo </th>
      <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.name}} </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tamaño </th>
      <td mat-cell *matCellDef="let row" data-label="CIA" class="td-responsive">
        {{row.size}}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
      <td mat-cell *matCellDef="let row" data-label="Correlativo" class="td-responsive"> {{row.type}} </td>
    </ng-container>

    <ng-container matColumnDef="lastModifiedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
      <td mat-cell *matCellDef="let row" data-label="Proveedor" class="td-responsive"> {{row.lastModifiedDate
        |
        date}}
      </td>
    </ng-container>

    <ng-container matColumnDef="descripcion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
      <td mat-cell *matCellDef="let row" data-label="Proveedor" class="td-responsive"> {{row.detalle}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsImagenes"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsImagenes;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="5">No se encontraron registros</td>
    </tr>

  </table>

</div>

<mat-paginator #paginatorImagenes showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancelar</button>
  <button color="primary" mat-button cdkFocusInitial (click)="guardar()">Guardar</button>
</div>
