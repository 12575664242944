<app-pavso-title-section [title]="'Procesos generales'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <mat-radio-group [(ngModel)]="tipo">
      <mat-radio-button value="1">Exporta Archivo DAOT Ingresos</mat-radio-button>
      <mat-radio-button value="2">Exporta Archivo DAOT Costo</mat-radio-button>
    </mat-radio-group>
  </mat-card>
  <br>

  <button color="primary" mat-stroked-button>Extraer Información</button>
  &nbsp;
  <button color="primary" mat-stroked-button>Revisa Información</button>
  &nbsp;
  <button color="primary" mat-stroked-button>Genera Archivo TXT</button>
  <br>

  <mat-card class="mat-elevation-z0">
    <button mat-flat-button color="primary" (click)="generaReporte()">Generar Reporte</button>
    &nbsp;
    <button mat-flat-button (click)="generaReporteExcel()">{{LABELS_NAME.BTN_GENERATE_EXCEL}}</button>
  </mat-card>
</div>
