import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DetalleDescuentoVoucher, DetalleProductoVoucher } from 'src/app/models';
import { AuthenticationService, VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit, OnDestroy {

  producto: DetalleProductoVoucher;

  displayedColumns: string[] = ['item', 'cod_desc', 'desc', 'porc_desc', 'importe_desc', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  descuentos: any = [];

  descuento: DetalleDescuentoVoucher;

  descuentosDeProducto: Array<DetalleDescuentoVoucher> = [];

  descuentoService$: Subscription;

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<EditProductComponent>,
    private _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: DetalleProductoVoucher
  ) {
    this.producto = new DetalleProductoVoucher();

    this.dataSource = fillTable(this.data.detalle_descuento, this.paginator, this.sort);
    this.descuento = new DetalleDescuentoVoucher();
  }

  ngOnInit(): void {
    this.obtenerDescuentos();

    if(this.data.detalle_descuento) {
      this.descuentosDeProducto = this.data.detalle_descuento;
      this.dataSource = fillTable(this.descuentosDeProducto, this.paginator, this.sort);
      return;
    }

    this.data.detalle_descuento = [];

  }

  obtenerDescuentos(): void {
    this.descuentoService$ = this._ventasService.obtenerDescuentos().subscribe(
      response => this.descuentos = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  agregarDescuento(f: NgForm) {

    let descuento = new DetalleDescuentoVoucher();

    descuento.dco_coddco = this.descuento.dco_coddco;
    descuento.ddd_pordes = this.descuento.ddd_pordes;
    descuento.ddd_impdes = this.data.DCD_VALVTA * (descuento.ddd_pordes / 100);

    this.descuentosDeProducto.push(descuento);

    this.dataSource = fillTable(this.descuentosDeProducto, this.paginator, this.sort);

    this.data.detalle_descuento = this.descuentosDeProducto;

    this.data.DCD_VALVTA = this.data.DCD_VALVTA - this.data.DCD_VALVTA * (descuento.ddd_pordes / 100);
    this.data.DCD_VALVTA = this.data.DCD_VALVTA.toFixed(2);

    this.data.DCD_IMPDES = this.data.DCD_IMPBRU - this.data.DCD_VALVTA;
    this.data.DCD_IMPDES = this.data.DCD_IMPDES.toFixed(2);

    this.data.DCD_PORDES = (this.data.DCD_IMPDES * 100 ) / this.data.DCD_IMPBRU;
    this.data.DCD_PORDES = this.data.DCD_PORDES.toFixed(2);

    this.data.DCD_IMPTOT = this.data.DCD_CANVEN * this.data.DCD_PRUIGV - this.data.DCD_IMPDES;
    this.data.DCD_IMPTOT = this.data.DCD_IMPTOT.toFixed(2);

    this.data.DCD_IMPIGV = this.data.DCD_IMPTOT - this.data.DCD_VALVTA;
    this.data.DCD_IMPIGV = this.data.DCD_IMPIGV.toFixed(2);

    descuento.ddd_coduse = this._authService.getUsuarioSistema();
    descuento.ddd_fecupd = new Date();
    descuento.ddd_indsta = "1";
    descuento.ddd_pordes = this.descuento.ddd_pordes;
    descuento.ddd_incdes = (descuento.ddd_impdes * 1.18).toFixed(2);

    descuento.dcm_numdcm = null;
    descuento.ddd_impaju = 0;
    descuento.ddd_corddd = "01"
  }

  eliminarDescuento(i) {

    let descuentos = []

    this.descuentosDeProducto.forEach((element, index) => {
      if(i != index) descuentos.push(element)
    })

    let element = this.descuentosDeProducto[i];

    this.data.DCD_VALVTA = parseFloat(this.data.DCD_VALVTA) + parseFloat(element.ddd_impdes);
    this.data.DCD_VALVTA = parseFloat(this.data.DCD_VALVTA).toFixed(2);

    this.data.DCD_IMPDES = this.data.DCD_IMPBRU - this.data.DCD_VALVTA;
    this.data.DCD_IMPDES = this.data.DCD_IMPDES.toFixed(2);

    this.data.DCD_PORDES = (this.data.DCD_IMPDES * 100 ) / this.data.DCD_IMPBRU;
    this.data.DCD_PORDES = this.data.DCD_PORDES.toFixed(2);

    this.data.DCD_IMPTOT = this.data.DCD_CANVEN * this.data.DCD_PRUIGV - this.data.DCD_IMPDES;
    this.data.DCD_IMPTOT = this.data.DCD_IMPTOT.toFixed(2);

    this.data.DCD_IMPIGV = this.data.DCD_IMPTOT - this.data.DCD_VALVTA;
    this.data.DCD_IMPIGV = this.data.DCD_IMPIGV.toFixed(2);

    this.descuentosDeProducto = descuentos;

    this.dataSource = fillTable(this.descuentosDeProducto, this.paginator, this.sort);

    this.data.detalle_descuento = this.descuentosDeProducto;

  }

  actualizarProducto(): void {

    this.data.detalle_descuento.forEach((element, index) => {
      element.dco_coddco = (index + 1).toString();

      if(element.dco_coddco.length == 1) {
        element.dco_coddco = "000" + element.dco_coddco;
      }
      if(element.dco_coddco.length == 2) {
        element.dco_coddco = "00" + element.dco_coddco;
      }
      if(element.dco_coddco.length == 3) {
        element.dco_coddco = "0" + element.dco_coddco;
      }
    })

    this.data.detalle_descuento = this.descuentosDeProducto;
    this.dialogRef.close(this.data);
  }

  cambioDeCantidad(): void {
    this.data.DCD_IMPBRU = this.data.DCD_CANVEN * this.data.DCD_PREUNI;
    this.data.DCD_IMPBRU = this.data.DCD_IMPBRU.toFixed(2);
    this.data.DCD_VALVTA = this.data.DCD_IMPBRU - this.data.DCD_IMPDES;
    this.data.DCD_VALVTA = this.data.DCD_VALVTA.toFixed(2);
    this.data.DCD_IMPTOT = this.data.DCD_CANVEN * this.data.DCD_PRUIGV - this.data.DCD_IMPDES;
    this.data.DCD_IMPTOT = this.data.DCD_IMPTOT.toFixed(2);
    this.data.DCD_IMPIGV = this.data.DCD_IMPTOT - this.data.DCD_VALVTA;
    this.data.DCD_IMPIGV = this.data.DCD_IMPIGV.toFixed(2);
  }

  cambioDeParametroPrecio(): void {
    this.data.DCD_PRUIGV = this.data.DCD_PREUNI * 1.18;
    this.data.DCD_PRUIGV = this.data.DCD_PRUIGV.toFixed(2);
    this.data.DCD_IMPBRU = this.data.DCD_CANVEN * this.data.DCD_PREUNI;
    this.data.DCD_IMPBRU = this.data.DCD_IMPBRU.toFixed(2);
    this.data.DCD_VALVTA = this.data.DCD_IMPBRU - this.data.DCD_IMPDES;
    this.data.DCD_VALVTA = this.data.DCD_VALVTA.toFixed(2);
    this.data.DCD_IMPTOT = this.data.DCD_CANVEN * this.data.DCD_PRUIGV - this.data.DCD_IMPDES;
    this.data.DCD_IMPTOT = this.data.DCD_IMPTOT.toFixed(2);
    this.data.DCD_IMPIGV = this.data.DCD_IMPTOT - this.data.DCD_VALVTA;
    this.data.DCD_IMPIGV = this.data.DCD_IMPIGV.toFixed(2);
  }

  cambioDeParametroPrecioIgv(): void {
    this.data.DCD_PREUNI = this.data.DCD_PRUIGV / 1.18;
    this.data.DCD_PREUNI = this.data.DCD_PREUNI.toFixed(2);
    this.data.DCD_IMPBRU = this.data.DCD_CANVEN * this.data.DCD_PREUNI;
    this.data.DCD_IMPBRU = this.data.DCD_IMPBRU.toFixed(2);
    this.data.DCD_VALVTA = this.data.DCD_IMPBRU - this.data.DCD_IMPDES;
    this.data.DCD_VALVTA = this.data.DCD_VALVTA.toFixed(2);
    this.data.DCD_IMPTOT = this.data.DCD_CANVEN * this.data.DCD_PRUIGV - this.data.DCD_IMPDES;
    this.data.DCD_IMPTOT = this.data.DCD_IMPTOT.toFixed(2);
    this.data.DCD_IMPIGV = this.data.DCD_IMPTOT - this.data.DCD_VALVTA;
    this.data.DCD_IMPIGV = this.data.DCD_IMPIGV.toFixed(2);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  aceptar(): void {}

  cancelar(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.descuentoService$
    ])
  }
}
