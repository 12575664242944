<app-pavso-title-section [title]="'Auxiliar'" [module]="'Contabilidad'" [ngClass]="isDialog ? 'pav-dialog' : 'pav-form'"
  [estado]="auxiliar.cli_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" matInput placeholder="Código" name="cli_codcli" [(ngModel)]="auxiliar.cli_codcli"
            (change)="ingresoCodigo($event)" (keyup)="ingresoCodigo($event)" required>
            <button *ngIf="!loaderBuscarRUC" type="button" mat-icon-button matSuffix
              matTooltip="Buscar desde Consulta SUNAT" (click)="buscarEnRENIEC()" matTooltip="Consultar Sunat">
              <mat-icon svgIcon="sunat-icon"></mat-icon>
            </button>
            <button *ngIf="loaderBuscarRUC" type="button" mat-icon-button matSuffix>
              <mat-spinner diameter="20"></mat-spinner>
            </button>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo persona</mat-label>
            <mat-select [(ngModel)]="auxiliar.cli_tipper" name="cli_tipper"
              (selectionChange)="seleccionarTipoPersona($event.value)">
              <mat-option *ngFor="let tipo of tiposPersona" [value]="tipo.tpe_codtpe">
                {{tipo.tpe_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Doc.</mat-label>
            <mat-select required name="tipo_doc" [(ngModel)]="auxiliar.did_coddid"
              (selectionChange)="seleccionarTipoDocumento($event.value)">
              <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.DID_CODDID">
                {{tipo.DID_DESABR}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3" *ngIf="auxiliar.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Nro. Documento</mat-label>
            <input type="text" matInput placeholder="Ej 70707070" name="cli_numdni" [(ngModel)]="auxiliar.cli_numdni"
              (keyup)="validarDni()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="auxiliar.cli_tipper == 'J'">
          <mat-form-field>
            <mat-label>Nro. Documento</mat-label>
            <input type="text" matInput placeholder="Ej. 2020202020203" name="cli_numruc"
              [(ngModel)]="auxiliar.cli_numruc" min="0" max="99999999999" (keyup)="validarRuc()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l9" *ngIf="auxiliar.cli_tipper == 'J'">
          <mat-form-field>
            <mat-label>Razon social</mat-label>
            <input type="text" matInput placeholder="Razon social" name="razonsocial" [(ngModel)]="auxiliar.cli_nomcli">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="auxiliar.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Ape. Paterno</mat-label>
            <input type="text" matInput placeholder="Ape. Paterno" name="cli_apepat" [(ngModel)]="auxiliar.cli_apepat">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="auxiliar.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Ape. Materno</mat-label>
            <input type="text" matInput placeholder="Ape. Materno" name="cli_apemat" [(ngModel)]="auxiliar.cli_apemat">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="auxiliar.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Nombres</mat-label>
            <input type="text" matInput placeholder="Nombres" name="cli_nombre" [(ngModel)]="auxiliar.cli_nombre">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="uid == '0'">
          <button type="button" mat-button color="primary" (click)="consultarNroDocumento()"
            *ngIf="auxiliar.cli_tipper == 'N'">
            <mat-icon>search</mat-icon>
            Consulta DNI
          </button>
          <button type="button" mat-button color="primary" (click)="consultarNroDocumento()"
            *ngIf="auxiliar.cli_tipper == 'J'">
            <mat-icon>search</mat-icon>
            Consulta RUC
          </button>
        </div>
      </div>

    </mat-card>

    <br>
    <mat-tab-group>
      <mat-tab label="Datos generales" labelClass="mat-tab-label-0-0">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12">
              <mat-form-field>
                <mat-label>Nombre Comercial</mat-label>
                <input type="text" matInput placeholder="Nombre Comercial" name="nombre_comercial" required>
              </mat-form-field>
            </div>
            <div class="col s12">
              <mat-form-field>
                <mat-label>Dirección</mat-label>
                <input type="text" matInput placeholder="Dirección" name="direccion" required>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <select-search [label]="'Pais'" [placeholder]="'Ej. PERU'" [name]="'PAI_CODPAI'" [value]="'PAI_CODPAI'"
                [description]="'pai_nomlar'" [data]="paises" (cambioSelect)="auxiliar.pai_codpai = $event"
                [model]="auxiliar.pai_codpai">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'Ubicación'" [placeholder]="'Ej. AYACUCHO'" [name]="'ubi_codubi'"
                [value]="'ubi_codubi'" [description]="'ubi_desubi'" [data]="ubicaciones"
                (cambioSelect)="auxiliar.ubi_codubi = $event" [model]="auxiliar.ubi_codubi">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'Ubigeo'" [placeholder]="'Ej. AYACUCHO'" [name]="'ubs_codubs'"
                [value]="'ubs_codubs'" [description]="'ubs_descri'" [data]="ubigeos"
                (cambioSelect)="auxiliar.ubs_codubs = $event" [model]="auxiliar.ubs_codubs">
              </select-search>

            </div>
            <div class="col s12 m3 l3">
              <mat-form-field>
                <mat-label>Registro Nacional</mat-label>
                <input type="text" matInput placeholder="Registro Nacional" name="registro_nacional" required>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12 l3">
              <select-search [label]="'Condi. Pago'" [placeholder]="'Ej. CONTADO'" [name]="'CPA_CODCPA'"
                [value]="'CPA_CODCPA'" [description]="'cpa_descri'" [data]="condiciones"
                (cambioSelect)="auxiliar.dato_clc.CPA_CODCPA= $event" [model]="auxiliar.dato_clc.CPA_CODCPA">
              </select-search>

            </div>
            <div class="col s12 m12 l6">
              <mat-radio-group aria-label="Select an option">
                <mat-radio-button value="1">Domiciliado</mat-radio-button>
                &nbsp;
                <mat-radio-button value="2">Doc. Completos</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col s12 m3 l3">
              <mat-form-field>
                <mat-label>Monto</mat-label>
                <input type="number" matInput placeholder="Monto" name="monto" required>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Servicios al Proveedor" labelClass="mat-tab-label-0-1">

        <div class="container">
          <div class="row">
            <div class="col s12">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">

                </div>
                <div class="pav-table-header-title">
                  Servicios Ofrecidos
                </div>
                <div class="pav-table-header-add">
                  <div class="isMobile">
                    <div class="pav-button-icon-add" (click)="agregarServicio()">
                      <mat-icon svgIcon="add"></mat-icon>
                    </div>
                  </div>
                  <div class="isWeb">
                    <button mat-flat-button class="pav-button-add-item" type="button"
                      (click)="agregarServicio()">Agregar ítem</button>
                  </div>
                </div>
              </div>
              <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSourceServicios" matSort>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div class="pav-btn-circle-delete" (click)="quitarServicio(row)" matTooltip="Eliminar">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                      <ng-container *ngIf="row.isEditing">
                        <mat-form-field class="mat-form-field-item">
                          <mat-label></mat-label>
                          <mat-select required [(ngModel)]="row.tse_codtse" [name]="'tse_codtse'+i"
                            (selectionChange)="seleccionarServicio($event, row)">
                            <mat-option *ngFor="let servicio of servicios"
                              [value]="servicio.TSE_CODTSE">{{servicio.TSE_DESTSE}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="!row.isEditing">
                        {{row.tse_descri}}
                      </ng-container>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsServicios"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsServicios;"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="2">No se encontraron registros</td>

                  </tr>
                </table>
                <mat-paginator #paginatorServicios [pageSizeOptions]="[5, 10, 25, 100]"
                  aria-label="Select page of users">

                </mat-paginator>

              </div>
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab label="Contactos" labelClass="mat-tab-label-0-2">
        <div class="container">

          <div class="row">
            <div class="col s12">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">

                </div>
                <div class="pav-table-header-title">
                  Contactos
                </div>
                <div class="pav-table-header-add">
                  <div class="isMobile">
                    <div class="pav-button-icon-add" (click)="agregarContacto(null)">
                      <mat-icon svgIcon="add"></mat-icon>
                    </div>
                  </div>
                  <div class="isWeb">
                    <button mat-flat-button class="pav-button-add-item" type="button"
                      (click)="agregarContacto(null)">Agregar ítem</button>
                  </div>
                </div>
              </div>
              <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSourceContactos" matSort>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div (click)="agregarContacto(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                          <mat-icon svgIcon="edit-white"></mat-icon>
                        </div>
                        <div class="pav-btn-circle-delete" (click)="quitarContacto(row)" matTooltip="Eliminar">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Código"> {{row.ccl_codccl}} </td>
                  </ng-container>

                  <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Nombre">
                      <ng-container>
                        {{row.ccl_nomccl}}
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="cargo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cargo </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Cargo">

                      <ng-container>
                        {{row.cca_desabr}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="telefono">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">

                      <ng-container>
                        {{row.ccl_numtlf}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="correo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Correo">

                      <ng-container>
                        {{row.ccl_corele}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha_nacimiento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de nacimiento </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Fecha de nacimiento">

                      <ng-container>
                        {{row.ccl_cumple | date: 'dd/MM/yyyy': 'UTC'}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="referencia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Referencia">

                      <ng-container>
                        {{row.ccl_glosa}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="cobranza">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cobranza </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Cobranza">

                      <ng-container>
                        {{(row.ccl_indcob)?"SI": "NO"}}
                      </ng-container>

                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayedColumnsContactos"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsContactos;"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="9">No se encontraron registros</td>

                  </tr>

                </table>
                <mat-paginator #paginatorContactos showFirstLastButtons #paginatorContacto
                  [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
              </div>
            </div>
          </div>
        </div>
        `
      </mat-tab>
      <mat-tab label="Establecimientos" labelClass="mat-tab-label-0-2">
        <div class="container">
          <div class="row">
            <div class="col s12">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">

                </div>
                <div class="pav-table-header-title">
                  Establecimientos
                </div>
                <div class="pav-table-header-add">
                  <div class="isMobile">
                    <div class="pav-button-icon-add" (click)="agregarEstablecimiento(null)">
                      <mat-icon svgIcon="add"></mat-icon>
                    </div>
                  </div>
                  <div class="isWeb">
                    <button mat-flat-button class="pav-button-add-item" type="button"
                      (click)="agregarEstablecimiento(null)">Agregar ítem</button>
                  </div>
                </div>
              </div>
              <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSourceEstablecimientos" matSort>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div (click)="agregarEstablecimiento(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                          <mat-icon svgIcon="edit-white"></mat-icon>
                        </div>
                        <div class="pav-btn-circle-delete" (click)="quitarEstablecimiento(row)" matTooltip="Eliminar">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. </th>
                    <td mat-cell *matCellDef="let row" data-label="Cod."> {{row.LDE_CODLDE}} </td>
                  </ng-container>

                  <ng-container matColumnDef="principal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Es Principal </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Principal">
                      <ng-container>
                        {{(row.LDE_INDPRI)?"SI": "NO"}}
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="direccion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Dirección">

                      <ng-container>
                        {{row.LDE_DIRLDE}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="referencia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Referencia">

                      <ng-container>
                        {{row.LDE_REFDIR}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="pais">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Pais">

                      <ng-container>
                        {{row.PAI_DESPAI}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ubicacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubicación </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Ubicación">

                      <ng-container>
                        {{row.UBI_DESUBI}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="zona">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Zona">

                      <ng-container>
                        {{row.ZVE_DESZVE}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsEstablecimientos"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsEstablecimientos;"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="8">No se encontraron registros</td>

                  </tr>

                </table>
                <mat-paginator showFirstLastButtons #paginatorEstablecimientos
                  [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Cuentas Bancarias" labelClass="mat-tab-label-0-4">
        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Tabla</div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSourceCuenta" matSort>
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button matTooltip="Editar" [routerLink]="['/modulo-contabilidad/editar-chofer', row.cli_codcli]"
                  mat-icon-button color="primary">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="codigo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="banco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Banco </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="moneda">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="nro_cuenta">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Cuenta </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cuenta_interbancaria">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Interbancaria </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="glosa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsCuenta"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCuenta;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros</td>
            </tr>

          </table>

        </div>
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </mat-tab>
    </mat-tab-group>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">

        <fieldset>
          <legend>Tipos</legend>
          <div class="col s12 m12 l3">
            <mat-checkbox class="example-margin" [(ngModel)]="auxiliar.cli_indcli"
              name="cli_indcli">Cliente</mat-checkbox>
          </div>

          <div class="col s12 m12 l3">
            <mat-checkbox class="example-margin" [(ngModel)]="auxiliar.cli_indpro"
              name="cli_indpro">Proveedor</mat-checkbox>
          </div>

          <div class="col s12 m12 l3">
            <mat-checkbox class="example-margin">Colaborador</mat-checkbox>
          </div>
          <div class="col s12 m12 l3">
            <mat-checkbox class="example-margin" [(ngModel)]="auxiliar.cli_indtra" name="cli_indtra">Aux.
              Gasto</mat-checkbox>
          </div>
        </fieldset>

      </div>
    </mat-card>


  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/auxiliares">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
