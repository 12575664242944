export class ConfiguraCobranza {
  cia_codcia: string;
  lcc_codlcc: string;
  ban_codban: string;
  sco_codsco: string;
  ccn_codmna: string;
  ccn_codmex: string;
  ccn_codpor: string;
  ccn_codint: string;
  ccn_codgas: string;
  ccn_codabo: string;
  ccc_coduse: string;
  ccc_fecupd: Date;
  ccc_indsta: string;
  ccs_codccs: string;
  ccn_otring: string;

  constructor() {
    this.ccc_fecupd = new Date();
    this.ccc_indsta = '1';
  }
}
