export class Invoice {
  tc: any;
  fecha: any;
  proveedor: any;
  orden: any;
  moneda: any;
  estado: string;
  usuario: any;
  constructor(
  ){}
}
