import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    constructor(
        private http: HttpClient,
        private _configurationService: ConfigurationService
    ) {}

    /**
     *
     * @returns
     */
    listarClientes(): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}helpdesk/clientes?cia=${this._configurationService.obtenerModo()}`);

    }

    /**
     *
     * @returns
     */
    listarMotivos(): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}tabla/motivosticket`);

    }

    /**
     *
     * @returns
     */
    listarTiposDeAtencion(): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}tabla/tiposatencionticket`);

    }

    /**
     *
     * @param cli_cidcli
     * @returns
     */
    listarModulos(cli_cidcli): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}helpdesk/moduloscliente/${this._configurationService.obtenerModo()}&${cli_cidcli}`);

    }

    /**
     *
     * @param cli_cidcli
     * @returns
     */
    listarUsuarios(cli_cidcli): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}helpdesk/usuarioscliente/${this._configurationService.obtenerModo()}&${cli_cidcli}`);

    }

    /**
     *
     * @param cli_cidcli
     * @returns
     */
    listarContratos(cli_cidcli): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}helpdesk/contratoscliente/${this._configurationService.obtenerModo()}&${cli_cidcli}`);

    }

    /**
     *
     * @returns
     */
    listarSituaciones(): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}tabla/situacionesticket`, );

    }

    /**
     *
     * @returns
     */
    listarNiveles(): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}tabla/nivelcriticidadticket`, );

    }

    /**
     *
     * @param params
     * @returns
     */
    listarTickets(params): Observable<any> {

      return this.http.post<any>(`${environment.apiUrl}ticket/lista`, params);

    }

    /**
     *
     * @param id
     * @returns
     */
    obtenerTicket(id): Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}helpdesk/ticket/${this._configurationService.obtenerModo()}/${id}`);

    }

    /**
     *
     * @param params
     * @returns
     */
    registrarTicket(params): Observable<any> {

      return this.http.post<any>(`${environment.apiUrl}helpdesk/creareditarticket`, params);

    }

    /**
     *
     * @param params
     * @returns
     */
    actualizarTicket(params): Observable<any> {

      return this.http.post<any>(`${environment.apiUrl}helpdesk/creareditarticket`, params);

    }

    /**
     *
     * @param params
     * @returns
     */
    asociarDocumentosATicket(params) : Observable<any> {

      return this.http.post<any>(`${environment.apiUrl}helpdesk/asociaarchivos`, params);

    }

    /**
     *
     * @param ticket_id
     * @returns
     */
    listarDocumentosAsociados(ticket_id) : Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}helpdesk/listararchivos/${this._configurationService.obtenerModo()}/${ticket_id}`);

    }

    /**
     *
     * @param ticket_id
     * @returns
     */
    consultarLogTickets(ticket_id) : Observable<any> {

      return this.http.get<any>(`${environment.apiUrl}helpdesk/logticket/${this._configurationService.obtenerModo()}/${ticket_id}`);

    }

}
