import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Cotizacion } from "src/app/models/crm";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerCotizaciones(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/cotizacion/${this._configurationService.obtenerCompaniaCliente()}`,
    );
  }

  obtenerCotizacion(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/cotizacion/${this._configurationService.obtenerCompaniaCliente()}/${id}`,
    );
  }

  registrarCotizacion(cotizacion: Cotizacion): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/cotizacion/${this._configurationService.obtenerCompaniaCliente()}`,
      cotizacion
    );
  }

  actualizarCotizacion(cotizacion: Cotizacion): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/cotizacion/${this._configurationService.obtenerCompaniaCliente()}`,
      cotizacion
    );
  }

  obtenerDataFormCotizacion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/forms/cotizacion/${this._configurationService.obtenerCompaniaCliente()}`
    )
  }

}
