import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Canal } from 'src/app/models/crm/canal';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { CanalService } from 'src/app/services/api/crm/maestros/canal.service';

@Component({
  selector: 'app-canal-form',
  templateUrl: './canal-form.component.html',
  styleUrls: ['./canal-form.component.css']
})
export class CanalFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  canal: Canal;

  uid: string;

  loading$: Subscription;
  sendForm$: Subscription;
  canal$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _canalService: CanalService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>,
  ) {

    this.canal = new Canal();

    this.canal.coduse = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.loadData();
      }
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=>{
      this.uid = id;
      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';
      if(id == '0') return;
      this.obtenerCanal();
    })

  }

  /**
   * Metodo para obtener un canal en específico
   */
  obtenerCanal(): void {
    this.loaderData = true;
    this.canal$ = this._canalService.obtenerCanal(this.uid).subscribe(
      ([canal]) => {
        this.canal = canal;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
        this.loaderData = false;
      }
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    console.log('form valido')
    if(!this.formularioValido()) return ;

    this.loaderReg = true;
    this.uid == '0' ? this.registrarCanal(f): this.actualizarCanal(f);
  }

  /**
   * Método para registrar un nuevo canal
   * @param f
   */
  registrarCanal(f: NgForm): void {
    this.canal.coduse = this._configurationService.obtenerIdUsuario();
    this._canalService.registrarCanal(this.canal).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.canal.success-store', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError('Error al registrar canal', 'Ok');
      }
    )
  }

  /**
   * Método para actualizar los datos de un canal existente
   * @param f
   */
  actualizarCanal(f: NgForm): void {
    this._canalService.actualizarCanal(this.canal).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.canal.success-update', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError('Error al actualizar canal', 'Ok');
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {
    if(!this.canal.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    return true;
  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/canales']);
  }

  /**
   * Ciclo de vida del componentte que se activa
   * cuando se deja de usar el componente.
   */
  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.canal$,
    ])
  }

}
