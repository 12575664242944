import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "../../../configuration.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Proveedor } from "src/app/models";

@Injectable({
  providedIn: 'root',
})
export class ProveedorService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerProveedores(): Observable<any> {
    // return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/clientes/listar/${this._configurationService.obtenerCompaniaCliente()}?es_proveedor=true`)
    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/clientes/listar/${this._configurationService.obtenerCompaniaCliente()}?es_proveedor=true`)
    .pipe(
      map((proveedor: any) => {
        return proveedor.map(proveedores => {
          proveedores['description'] = `${proveedores.cli_codcli} | ${proveedores.cli_nomcli}`
          proveedores['description_reverse'] = `${proveedores.cli_nomcli} | ${proveedores.cli_codcli}`
          return proveedores;
        })
      })
    )
  }

  obtenerProveedor(codigo: string): Observable<any> {
    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/clientes/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`)
  }

  registrarProveedor(payload: any): Observable<any> {
    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/clientes/crear`, payload)
  }

  registrarProveedoresMasivamente(payload: {proveedoresARegistrar: Array<Proveedor>, proveedoresAActualizar: Array<Proveedor>}): Observable<any> {
    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/clientes/crear-masivamente`, payload)
  }

  editarProveedor(payload: any): Observable<any> {
    return this.http.put<any>(`${this._configurationService.obtenerUrl()}/api/clientes/modificar`, payload)
  }

  verificarExistenciaProveedores(payload: {rucs: Array<string>}): Observable<any> {
    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/clientes/proveedores/verficar-existencia/${this._configurationService.obtenerCompaniaCliente()}`, payload)
  }

  verificarRucsActivosSUNAT(payload: {rucs: Array<string>}): Observable<any> {
    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/clientes/proveedores/verficar-estado-activo-sunat`, payload)
  }

  obtenerDatosProveedoresSUNAT(payload: {rucs: Array<string>}): Observable<any> {
    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/clientes/proveedores/obtener-datos`, payload)
  }
}
