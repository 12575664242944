<app-pavso-title-section [title]="'Listado de períodos CTS'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-table-filter">

    </div>
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los registros" url="/modulo-planilla/periodo-cts" entity="" [params]="['0']"></table-title-action>

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <div class="pav-btns-container">
                      <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-planilla/periodo-cts', row.ano_codano + row.mes_codmes]">
                        <mat-icon svgIcon="edit-white"></mat-icon>
                      </div>
                      <!-- <div class="pav-btn-circle-more">
                        <mat-icon svgIcon="more"></mat-icon>
                      </div> -->
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="ano_codano">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Período </th>
                <td mat-cell *matCellDef="let row" data-label="Período"> {{row.ano_codano}} - {{row.mes_codmes}} </td>
            </ng-container>

            <ng-container matColumnDef="ncp_ranini">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Inicio </th>
                <td mat-cell *matCellDef="let row" data-label="Fecha de Inicio"> {{row.ncp_ranini | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="ncp_ranfin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Término </th>
                <td mat-cell *matCellDef="let row" data-label="Fecha de Término"> {{row.ncp_ranfin | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="ncp_fecpag">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Pago </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha de Término"> {{row.ncp_fecpag | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
