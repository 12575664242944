import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@shared/services/snackbar.service';
import { ordenerArregloJSONxLlave } from '@utils/array/order';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { ConfiguracionTecnica } from 'src/app/models/almacen/inventario/configuracion-tecnica';
import { TipoInventarioService } from 'src/app/services/api/almacen/configuracion/tipo-inventario.service';
import { ColorService } from 'src/app/services/api/almacen/maestros/color.service';
import { FamiliaService } from 'src/app/services/api/almacen/maestros/familia.service';
import { ModeloService } from 'src/app/services/api/almacen/maestros/modelo.service';
import { KardexService } from 'src/app/services/api/almacen/operaciones/kardex.service';
import { SubfamiliaService } from 'src/app/services/api/almacen/tabla-apoyo/subfamilia.service';
import { LineaProductoService } from 'src/app/services/api/produccion/maestros/linea-producto.service';
import { IParam } from 'src/app/services/api/utilities/params';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';

class FiltroProductoMultiple {
  familias: string[];
  subfamilias: string[];
  lineas: string[];
  colores: string[];
  modelos: string[];
  tiposInventario: string[]

  constructor() {
    this.familias = []
    this.subfamilias = []
    this.lineas = []
    this.colores = []
    this.modelos = []
    this.tiposInventario = []
  }
}

@Component({
  selector: 'producto-multiple',
  templateUrl: 'producto-multiple.dialog.html'
})

export class ProductoMultipleDialog implements OnInit, OnChanges, OnDestroy {

  loading: boolean = false;
  loadingFiltros: boolean = false;

  configuracion: ConfiguracionTecnica;
  productos: any[] = [];
  familias: any[] = []
  subfamilias: any[] = []
  lineas: any[] = []
  colores: any[] = []
  modelos: any[] = []
  tiposInventario: any[] = []

  filtro: FiltroProductoMultiple;

  familias$: Subscription;
  subfamilias$: Subscription;
  lineas$: Subscription;
  colores$: Subscription;
  modelos$: Subscription;
  tiposInventario$: Subscription;

  productos$: Subscription;
  configuracion$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ProductoMultipleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _familiaService: FamiliaService,
    private readonly _subfamiliaService: SubfamiliaService,
    private readonly _lineaService: LineaProductoService,
    private readonly _colorService: ColorService,
    private readonly _modeloService: ModeloService,
    private readonly _productoService: ProductoService,
    private readonly _kardexService: KardexService,
    private readonly _tipoInventarioService: TipoInventarioService,
    private _snackBarService: SnackBarService
  ) {
    this.configuracion = new ConfiguracionTecnica()
    this.filtro = new FiltroProductoMultiple()
  }

  lote: any = {
    name: 'Productos',
    completed: false,
    color: 'primary',
    lotes: this.data.products,
  };

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.lote.lotes != null && this.lote.lotes.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.lote.lotes == null) {
      return false;
    }
    return this.lote.lotes.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  getCompleted(): any[] {
    return this.lote.lotes.filter(t => t.completed);
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.lote.lotes == null) {
      return;
    }
    this.lote.lotes.forEach(t => (t.completed = completed));
  }

  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.loaderData()
  }

  loaderData(): void {
    this.loading = true;

    this.productos$ = this._productoService.obtenerProductos().subscribe(
      productos => {

        this.productos = productos;

        this.familias$ = this._familiaService.obtenerFamiliasProducto().subscribe(
          familias => {

            this.familias = familias;

            this.lineas$ = this._lineaService.obtenerLineasProducto().subscribe(
              lineas => {

                this.lineas = lineas;

                this.colores$ = this._colorService.obtenerColores().subscribe(
                  colores => {

                    this.colores = colores;

                    this.modelos$ = this._modeloService.obtenerModelos().subscribe(
                      modelos => {

                        this.tiposInventario$ = this._tipoInventarioService.obtenerTiposDeInventario().subscribe(
                          tiposInventario => {
                            this.tiposInventario = tiposInventario;
                            this.modelos = modelos;

                            this.configuracion$ = this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
                              configuracion => {
                                console.log(configuracion)
                                this.configuracion = configuracion[0];
                                this.loading = false;
                                this.filtrar()
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener configuración técnica', 'Ok')
                                this.loading = false;
                              }
                            )
                          },
                          error => {
                            this.loading = false;
                            this._snackBarService.showError('Error al obtener tipos de inventario', 'Ok')
                          }
                        )
                      },
                      error => {
                        this.loading = false;
                        this._snackBarService.showError('Error al obtener modelos', 'Ok')
                      })
                  },
                  error => {
                    this.loading = false;
                    this._snackBarService.showError('Error al obtener colores', 'Ok')
                  }
                )
              },
              error => {
                this.loading = false;
                this._snackBarService.showError('Error al obtener lineas', 'Ok')
              }
            )
          },
          error => {
            this.loading = false;
            this._snackBarService.showError('Error al obtener familias', 'Ok')
          }
        )

      },
      error => {

      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lote.lotes = this.productos.filter(producto => (producto.DESC_ESPA).toLowerCase().indexOf(filterValue.toLowerCase()) > -1)

  }

  filtrar() {
    this.loadingFiltros = true;
    let params: IParam[] = [];

    if (this.filtro.familias.join(',')) {
      params.push({ param: 'familia', value: this.filtro.familias.join(',') })
    }

    if (this.filtro.subfamilias.join(',')) {
      params.push({ param: 'subfamilia', value: this.filtro.subfamilias.join(',') })
    }

    if (this.filtro.lineas.join(',')) {
      params.push({ param: 'linea', value: this.filtro.lineas.join(',') })
    }

    if (this.filtro.colores.join(',')) {
      params.push({ param: 'categoria', value: this.filtro.colores.join(',') })
    }

    if (this.filtro.modelos.join(',')) {
      params.push({ param: 'calibre', value: this.filtro.modelos.join(',') })
    }

    if (this.filtro.tiposInventario.join(',')) {
      params.push({ param: 'tipoinventario', value: this.filtro.tiposInventario.join(',') })
    }

    this.productos$ = this._productoService.ListarProductos({params}).subscribe(
      productos => {
        this.setAll(false);
        this.lote.lotes = ordenerArregloJSONxLlave('prd_codprd', productos);
        this.loadingFiltros = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener productos', 'Ok');
        this.loadingFiltros = true;
      }
    )

  }

  confirmar(): void {

    console.log('this.getCompleted()', this.getCompleted())

    const productsSelected = this.getCompleted().map(product => {
      const prod = this.productos.find(item => item.prd_codprd == product.prd_codprd)
      return prod;
    })

    this.dialogRef.close(productsSelected);
  }

  seleccionarFamilia(): void {

    this.subfamilias$ = this._subfamiliaService.obtenerSubFamilias(this.filtro.familias).subscribe(
      subfamilias => {
        this.subfamilias = subfamilias;
      },
      error => {
        this._snackBarService.showError('Error al obtener subfamilias', 'Ok')
      }
    )

  }



  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.familias$,
      this.subfamilias$,
      this.colores$,
      this.modelos$,
      this.lineas$
    ])
  }
}
