<app-pavso-title-section [title]="'Máquinas'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">

      <table-title-action title="Todas las máquinas" url="/modulo-produccion/maquina" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <div class="pav-btns-container">
                      <a matTooltip="Editar area de producción"
                        class="pav-btn-circle-edit"
                        [routerLink]="['/modulo-produccion/maquina', row.maq_codmaq]">
                        <mat-icon svgIcon="edit-white"></mat-icon>
                      </a>
                    </div>
                  </td>
            </ng-container>

            <ng-container matColumnDef="maq_codmaq">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.maq_codmaq}} </td>
            </ng-container>

            <ng-container matColumnDef="maq_desmaq">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.maq_desmaq}} </td>
            </ng-container>

            <ng-container matColumnDef="ume_codume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad Medida </th>
                <td mat-cell *matCellDef="let row" data-label="Unidad Medida"> {{row.ume_codume}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
