export class MotivoVentaSuspendida {
  codcia  : string;
  codosv  : string;
  descri  : string;
  indsta  : string;
  fecupd  : Date;
  coduse  : string;

  constructor() {
    this.fecupd = new Date();
  }
}
