<h1 mat-dialog-title>Agregar contacto</h1>
<form #f="ngForm" ngNativeValidate (ngSubmit)="agregarContacto(f)" (keydown.enter)="$event.preventDefault()">
    <div mat-dialog-content>

        <div class="row">
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Nombre</mat-label>
                    <input type="text" matInput placeholder="Nombre" name="ccl_nomccl" [(ngModel)]="contact.ccl_nomccl" required>
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Cargo</mat-label>
                    <mat-select name="cca_codcca" [(ngModel)]="contact.cca_codcca" required>
                        <mat-option *ngFor="let cargo of cargos" [value]="cargo.cca_codcca">
                            {{cargo.cca_descri}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Descripción cargo</mat-label>
                    <input matInput placeholder="Descripción cargo" name="description_job">
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Teléfono</mat-label>
                    <input type="tel" matInput placeholder="Teléfono" name="phone" [(ngModel)]="contact.ccl_numtlf" required>
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Correo</mat-label>
                    <input type="email" matInput placeholder="Correo" name="email" [(ngModel)]="contact.ccl_corele" required>
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Fecha Nacimiento</mat-label>
                    <input matInput [matDatepicker]="picker" name="date_birth" [(ngModel)]="contact.ccl_cumple"required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Referencia</mat-label>
                    <input type="text" matInput placeholder="Referencia" name="reference" [(ngModel)]="contact.ccl_glosa" required>
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <section class="example-section">
                    <mat-checkbox class="example-margin" name="collection" [(ngModel)]="contact.ccl_indcob">Cobranza</mat-checkbox>
                </section>
            </div>

        </div>

    </div>
    <div mat-dialog-actions>
        <button type="button" mat-stroked-button mat-button (click)="onNoClick()">{{LABELS_NAME.BTN_BACK | translate}}</button>
        <button mat-stroked-button mat-button color="primary" type="submit">{{LABELS_NAME.BTN_ADD}}</button>

    </div>
</form>
