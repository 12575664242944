<app-pavso-title-section [title]="'Kardex'" [module]="'Libros electrónicos'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <mat-radio-group >
    <mat-radio-button value="1">Inventario Permanente en Unidades Físicas</mat-radio-button>
    <mat-radio-button value="2">Inventario Permanente Valorizado</mat-radio-button>
  </mat-radio-group>

  <br>
  <mat-card class="mat-elevation-z0">
    <button color="primary" mat-stroked-button>Extraer Información</button>
    &nbsp;
    <button color="primary" mat-stroked-button>Revisa Información</button>
    &nbsp;
    <button color="primary" mat-stroked-button>Genera Archivo TXT</button>
  </mat-card>
</div>
