import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ConfigurationService } from "src/app/services";
import { RegistroCompraService } from "src/app/services/api/compras/operaciones/registro-compra.service";
import { ExportExcelContabilidad } from "src/app/services/reporte-excel/contabilidad-excel.service";

class FiltroSIRECompras {
  moneda: string;

  constructor() {
    this.moneda = 'SO';
  }
}

@Component({
  selector: 'control-sire-ventas',
  templateUrl: './control-sire-ventas.component.html',
  styleUrls: ['./control-sire-ventas.component.scss']
})
export class ControlSIREVentasComponent implements OnInit, OnDestroy {

  isMobile: boolean = false;
  isTablet: boolean = false;

  filtro: FiltroSIRECompras;

  loaderData: boolean;
  loaderSIRE: boolean;

  displayedColumns: string[] = ['acciones', 'col01', 'col02', 'col03',
  'col04', 'col05', 'col06', 'col07', 'col08',
  'col09', 'col10', 'col11', 'col12', 'col13',
  'col14', 'col15', 'col16', 'col17', 'col18', 'col19', 'col20',
    'col21', 'col22', 'col23', 'col24', 'col25',
    'col26', 'col27', 'col28', 'col29', 'col30',
    'col31', 'col32', 'col33', 'col34', 'col35', 'col36', 'col37', 'col38',
    'col39', 'col40', 'col41'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  displayedColumns2: string[] = ['acciones', 'f01', 'f02', 'f03', 'f04',
    'f05', 'f06', 'f07', 'f08', 'f09',
    'f10', 'f11', 'f12', 'f13', 'f14',
    'f15', 'f16', 'f17', 'f18', 'f19', 'f20', 'f21',
    'f22', 'f23', 'f24', 'f25', 'f26',
    'f27', 'f28', 'f29', 'f30', 'f31'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  displayedColumns3: string[] = ['acciones', 'f01', 'f02', 'f03', 'f04',
    'f05', 'f06', 'f07', 'f08', 'f09',
    'f10', 'f11'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;

  displayedColumns4: string[] = ['acciones', 'f01', 'f02', 'f03', 'f04',
    'f05', 'f06', 'f07', 'f08', 'f09',
    'f10', 'f11'];
  dataSource4: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator4: MatPaginator;
  @ViewChild(MatSort) sort4: MatSort;

  anioPeriodo: string;
  mesPeriodo: string;

  consultaSIRE$: Subscription;

  totalBIGravadoDG: number = 0;
  totalIGVIPMDG: number = 0;
  totalBIGravadoDGNG: number = 0;
  totalIGVIPMDGNG: number = 0;
  totalBIGravadoDNG: number = 0;
  totalIGVIPMDNG: number = 0;
  totalValorAdqNG: number = 0;
  totalISC: number = 0;
  totalICPPER: number = 0;
  totalOtrosTributos: number = 0;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly _configurationService: ConfigurationService,
    private readonly _registroCompraService: RegistroCompraService,
    private readonly _snackBarService: SnackBarService,
    private readonly _exportExcelContabilidad: ExportExcelContabilidad,
    private store: Store<PavsoState>
  ) {

    this.filtro = new FiltroSIRECompras();

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSource2 = fillTable([], this.paginator2, this.sort2);
    this.dataSource3 = fillTable([], this.paginator3, this.sort3);
    this.dataSource4 = fillTable([], this.paginator4, this.sort4);

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

    this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })
  }

  ngOnInit(): void {

  }

  descargarSIRE(): void {
    this.loaderSIRE = true;
    const payload = {
      codcia: this._configurationService.obtenerCompaniaCliente(),
      codano: this.anioPeriodo,
      codmes: this.mesPeriodo,
      coduse: this._configurationService.obtenerIdUsuario(),
      codtmo: this.filtro.moneda,
    }

    this.consultaSIRE$ = this._registroCompraService.obtenerComprasSIRE(payload).subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.totalBIGravadoDG = 0;
        this.totalIGVIPMDG = 0;
        this.totalBIGravadoDGNG = 0;
        this.totalIGVIPMDGNG = 0;
        this.totalBIGravadoDNG = 0;
        this.totalIGVIPMDNG = 0;
        this.totalValorAdqNG = 0;
        this.totalISC = 0;
        this.totalICPPER = 0;
        this.totalOtrosTributos = 0;
        response.forEach(element => {
          this.totalBIGravadoDG += element.psc_bigrdg;
          this.totalIGVIPMDG += element.psc_igvdg;
          this.totalBIGravadoDGNG += element.psc_bigdgnd;
          this.totalIGVIPMDGNG += element.psc_igvdgng;
          this.totalBIGravadoDNG += element.psc_bigrdng;
          this.totalIGVIPMDNG += element.psc_igvdng;
          this.totalValorAdqNG += element.psc_vaadng;
          this.totalISC += element.psc_impisc;
          this.totalICPPER += element.psc_icbper;
          this.totalOtrosTributos += element.psc_otrtri;

          this.totalBIGravadoDG = parseFloat(this.totalBIGravadoDG.toFixed(2));
          this.totalIGVIPMDG = parseFloat(this.totalIGVIPMDG.toFixed(2));
          this.totalBIGravadoDGNG = parseFloat(this.totalBIGravadoDGNG.toFixed(2));
          this.totalIGVIPMDGNG = parseFloat(this.totalIGVIPMDGNG.toFixed(2));
          this.totalBIGravadoDNG = parseFloat(this.totalBIGravadoDNG.toFixed(2));
          this.totalIGVIPMDNG = parseFloat(this.totalIGVIPMDNG.toFixed(2));
          this.totalValorAdqNG = parseFloat(this.totalValorAdqNG.toFixed(2));
          this.totalISC = parseFloat(this.totalISC.toFixed(2));
          this.totalICPPER = parseFloat(this.totalICPPER.toFixed(2));
          this.totalOtrosTributos = parseFloat(this.totalOtrosTributos.toFixed(2));
        });
        this.loaderSIRE = false;
      }, error => {
        this._snackBarService.showError('Error al descargar el SIRE', 'Ok');
        this.loaderSIRE = false;
      }
    )
  }

  descargarExcel(): void {
    console.log('descargar excel')
    if (this.dataSource.data.length === 0) {
      this._snackBarService.showError('No hay datos para descargar', 'Ok');
      return;
    }

    const dataExcel = this.dataSource.data.map((item, index) => ({
      'Fila': index + 1,
      'CAR SUNAT': item.psc_carsun,
      'Fecha Emisión': item.psc_fecemi,
      'Fecha Vencimiento': item.psc_fecven,
      'Tipo Documento': item.psc_tipdoc,
      'Serie Documento': item.psc_serdoc,
      'Año': item.psc_anodua,
      'Nro. Doc. Inicial': item.psc_numini,
      'Nro. Doc. Final': item.psc_numfin,
      'Tipo Documento Identidad': item.psc_tipide,
      'Nro. Documento Identidad': item.psc_numide,
      'Razón Social': item.psc_proraz,
      'BI Gravado DG': item.psc_bigrdg,
      'IGV / IPM DG': item.psc_igvdg,
      'BI Gravado DGNG': item.psc_bigdgnd,
      'IGV / IPM DGNG': item.psc_igvdgng,
      'BI Gravado DNG': item.psc_bigrdng,
      'IGV / IPM DNG': item.psc_igvdng,
      'Valor Adq. NG': item.psc_vaadng,
      'ISC': item.psc_impisc,
      'ICBPER': item.psc_icbper,
      'Otros Tributos': item.psc_otrtri,
      'Importe Total': item.psc_imptot,
      'Moneda': item.psc_codtmo,
      'Tipo de cambio': item.psc_tipcam,
      'Fecha de emisión doc. Modificado': item.psc_fecref,
      'Tipo Doc. Modificado': item.psc_docref,
      'Serie Doc. Modificado': item.psc_serref,
      'DAM O DSI': item.psc_coddam,
      'Nro. Modificado': item.psc_numref,
      'Clasificación BSS y SSS': item.psc_clabss,
      'ID Proyecto Operadores': item.psc_idprop,
      'Porcentaje Participación': item.psc_porpar,
      'IMB': item.psc_impimb,
      'CAR Original': item.psc_carori,
      'Detracción': item.psc_detracc,
      'Tipo Nota': item.psc_tipnot,
      'Est. Comp.': item.psc_estcom,
      'Incal': item.psc_incal,
      'Usuario': item.psc_coduse,
      'Fecha Act.': item.psc_fecupd,
      'CLU1': item.psc_clu1,
      'CLU2': item.psc_clu2,
      'CLU3': item.psc_clu3,
      'CLU4': item.psc_clu4,
      'CLU5': item.psc_clu5,
      'CLU6': item.psc_clu6,
      'CLU7': item.psc_clu7,
      'CLU8': item.psc_clu8,
      'CLU9': item.psc_clu9,
      'CLU10': item.psc_clu10,
      'CLU11': item.psc_clu11,
      'CLU12': item.psc_clu12,
      'CLU13': item.psc_clu13,
      'CLU14': item.psc_clu14,
      'CLU15': item.psc_clu15,
      'CLU16': item.psc_clu16,
      'CLU17': item.psc_clu17,
      'CLU18': item.psc_clu18,
      'CLU19': item.psc_clu19,
      'CLU20': item.psc_clu20,
      'CLU21': item.psc_clu21,
      'CLU22': item.psc_clu22,
      'CLU23': item.psc_clu23,
      'CLU24': item.psc_clu24,
      'CLU25': item.psc_clu25,
      'CLU26': item.psc_clu26,
      'CLU27': item.psc_clu27,
      'CLU28': item.psc_clu28,
      'CLU29': item.psc_clu29,
      'CLU30': item.psc_clu30,
      'CLU31': item.psc_clu31,
      'CLU32': item.psc_clu32,
      'CLU33': item.psc_clu33,
      'CLU34': item.psc_clu34,
      'CLU35': item.psc_clu35,
      'CLU36': item.psc_clu36,
      'CLU37': item.psc_clu37,
      'CLU38': item.psc_clu38,
      'CLU39': item.psc_clu39
    }));

    this._exportExcelContabilidad.exportExcelSIRE({ data: dataExcel, title: `Sire Compras | ${this.anioPeriodo}-${this.mesPeriodo}` });
  }

  errorRegistrosSIRESunat(): void { }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.consultaSIRE$])
  }

}
