import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class TipoActivoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
  *
  * @returns
  */
  listarTiposActivo(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/activofijo/listartipoactivo/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerTipoActivo(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/activofijo/mostrartipoactivo/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }


}
