import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { NominaService } from "src/app/services";

@Component({
  selector: 'app-movimiento-concepto',
  templateUrl: './movimiento-concepto.component.html',
  styleUrls: ['./movimiento-concepto.component.scss']
})
export class MovimientoConceptoComponent implements OnInit, OnDestroy{

  displayedColumns: string[] = ['cli_codcli', 'cli_nomcli', 'nmt_imprem', 'nmt_cannut', 'nut_codnut', 'nmt_canhrs', 'nmt_canmin', 'valor', 'modifica'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  conceptos: any[] = [];

  year: string;
  month: string;

  tipoNomina: string;
  secNomina: string;

  total: number;

  nomina$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  detalle$: Subscription;
  conceptos$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>
  ){

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    });

    this.nomina$ = this.store.select('nomina').subscribe(state => {
      this.tipoNomina = state.typeNomina;
      this.secNomina = state.secNomina;
    })

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadMaestros();
    })
  }

  /**
   * Obtiene los maestros de :
   * . conceptos remunerativos
   */
  loadMaestros(): void {
    this.conceptos$ = this._nominaService.listarConceptosRemuneracion(this.year, this.month).subscribe(
      conceptos => this.conceptos = conceptos,
      error => this._snackBarService.showError("Error al obtener conceptos", "OK")
    )
  }

  trackByConcepto(index, concepto): string {
    return concepto? concepto.codnco: undefined;
  }

  seleccionarConcepto(event): void {
    this.total = 0;
    this.detalle$ = this._nominaService.listarDetalleMovimientosRemuneracion(`${this.year}${this.month}${this.tipoNomina}${this.secNomina}`, event).subscribe(
      detalle => {
        detalle.forEach(element => {
          this.total += element.nmt_imprem;
        });

        this.total = Number(this.total.toFixed(2))
        this.dataSource = fillTable(detalle, this.paginator, this.sort);
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.nomina$,
      this.loading$,
      this.detalle$,
      this.conceptos$
    ])
  }
}
