import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { LugarDespachoCIA } from "src/app/models/compras/lugar-despacho-cia";
import { AlmacenService } from "src/app/services";

@Component({
  selector: 'lugar-despacho-form',
  templateUrl: './lugar-despacho-form.component.html',
  styleUrls: ['./lugar-despacho-form.component.scss']
})
export class LugarDespachoFormComponent implements OnInit, OnDestroy {

  loaderData: boolean;
  loaderReg: boolean;

  lugar: LugarDespachoCIA;

  ubicaciones: any[] = [];

  sendForm$: Subscription;
  ubicaciones$: Subscription;

  uid: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _almacenService: AlmacenService
  ) {
    this.lugar = new LugarDespachoCIA();

  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(params => {
      this.uid = params.id;
    })

    this.loadMaestros();
  }

  loadMaestros(): void{
    this.ubicaciones$ = this._almacenService.obtenerUbicacionesAlmacen().subscribe(
      ubicaciones => {
        this.ubicaciones = ubicaciones;
        this.loaderData = false;

        if(this.uid !+ '0') {
          this.obtenerLugarDespacho();
          return;
        }

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener ubicaciones', 'Ok');
        this.loaderData = false;
      }
    )
  }

  obtenerLugarDespacho(): void {}

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarLugarDespacho(f): this.actualizarLugarDespacho(f);
  }

  registrarLugarDespacho(f: NgForm): void {}

  actualizarLugarDespacho(f: NgForm): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.ubicaciones$
    ])
  }
}
