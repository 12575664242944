export class FiltroReporteCompras {
  tiendas: Array<any>;
  clientes: Array<any>;
  series: Array<any>;
  motivos: Array<any>;
  vendedores: Array<any>;
  productos: Array<any>;
  condiciones: Array<any>;
  inventarios: Array<any>;
  documentos: Array<any>;
  marcas: Array<any>;
  moneda: string;
  fecIni: string;
  fecFin: string;

  constructor() {
    this.tiendas = [];
    this.clientes = [];
    this.series = [];
    this.motivos = [];
    this.vendedores = [];
    this.productos = [];
    this.condiciones = [];
    this.inventarios = [];
    this.documentos = [];
    this.marcas = [];
    this.moneda = 'SO';
  }
}
