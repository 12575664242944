export class FiltroConsultaStock {

  producto: string;
  tipoInventario: string;
  talla: string;
  lote: string;
  almacen: string;

  constructor() {

  }

}
