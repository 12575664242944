<app-pavso-title-section [title]="'Auxiliares'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los auxiliares" url="/modulo-contabilidad/auxiliar" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-contabilidad/auxiliar', row.cli_codcli]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                  </div>

                </td>
            </ng-container>

            <ng-container matColumnDef="cli_codcli">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cli_nomcli">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="cli_numtlf">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

      </div>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
