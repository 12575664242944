export class FiltroReportePlanilla {
  colaboradores: Array<any>;
  centros: Array<any>;
  generos: Array<any>;
  conceptos: Array<any>;
  tiposDocumento: Array<any>;

  constructor() {
    this.colaboradores = [];
    this.centros = [];
    this.generos = [];
    this.conceptos = [];
    this.tiposDocumento = [];
  }
}
