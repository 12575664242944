<app-pavso-title-section [title]="'Interface PLE'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderAction"></mat-progress-bar>

  <mat-card class="mat-elevation-z0">
    <mat-radio-group [(ngModel)]="tipo" (change)="seleccionarOpcion($event)">
      <mat-radio-button value="1">Reg. de Compras Domiciliados</mat-radio-button>
      <mat-radio-button value="2">Reg. de Compras No Domiciliados</mat-radio-button>
      <mat-radio-button value="3">Reg. de Ventas</mat-radio-button>
      <mat-radio-button value="4">Reg. Diario</mat-radio-button>
      <mat-radio-button value="5">Reg. Mayor</mat-radio-button>
      <mat-radio-button value="6">Reg. de Retenciones</mat-radio-button>
    </mat-radio-group>
  </mat-card>
  <br>

  <button color="primary" mat-stroked-button *ngIf="showButtonExtrae" (click)="extraeInformacionCompras()">Extrae Información</button>
  &nbsp;
  <button color="primary" mat-stroked-button (click)="generarTxt()">Genera TXT</button>
  &nbsp;
  <button color="primary" mat-stroked-button *ngIf="showButtonRevisa" (click)="revisaInformacion()">Revisa</button>
  &nbsp;
  <button color="primary" mat-stroked-button  *ngIf="showButtonGeneraArchivo" (click)="generarArchivoPlanDeCuentas()">Genera Archivo Plan de Cuenta</button>
  <br>
  <ng-container *ngIf="showRevisaComprasDom">
    <mat-card class="mat-elevation-z0">
      <mat-card-header>
        <mat-card-title>Revisa Registro de Compras Domiciliados</mat-card-title>
        <mat-card-subtitle>Período 2020 03</mat-card-subtitle>
      </mat-card-header>
      <div class="mat-elevation-z0 overflow-x">

        <table mat-table [dataSource]="dataSource1" matSort >

            <ng-container matColumnDef="campo1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
                <td mat-cell *matCellDef="let row" data-label="Fila"> {{row.item}} </td>
            </ng-container>
            <ng-container matColumnDef="campo2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CUO (1) </th>
                <td mat-cell *matCellDef="let row" data-label="CUO (1)"> {{row.CUO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo3">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo (2) </th>
                <td mat-cell *matCellDef="let row" data-label="Correlativo (2)"> {{row.correlativ}} </td>
            </ng-container>
            <ng-container matColumnDef="campo4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Comprobante (3) </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Emisión Comprobante (3)"> {{row.lrc_fecemi | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="campo5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Vencimiento (4) </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha de Vencimiento (4)"> {{row.lrc_fecven | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="campo6">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Comprobante (5) </th>
              <td mat-cell *matCellDef="let row" data-label="Tipo Comprobante (5)"> {{row.tdo_codofi}} </td>
            </ng-container>
            <ng-container matColumnDef="campo7">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie (6) </th>
              <td mat-cell *matCellDef="let row" data-label="Serie (6)"> {{row.lrc_serie}} </td>
            </ng-container>
            <ng-container matColumnDef="campo8">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Año DUA (7) </th>
              <td mat-cell *matCellDef="let row" data-label="Año DUA (7)"> {{row.lrc_anodua}} </td>
            </ng-container>
            <ng-container matColumnDef="campo9">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante (8) </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Comprobante (8)"> {{row.lrc_numdoc}} </td>
            </ng-container>
            <ng-container matColumnDef="campo10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total No Otorga Crédito (9) </th>
              <td mat-cell *matCellDef="let row" data-label="Importe Total No Otorga Crédito (9)"> {{row.lrc_consol}} </td>
            </ng-container>
            <ng-container matColumnDef="campo11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. Identidad (10) </th>
              <td mat-cell *matCellDef="let row" data-label="Tipo Doc. Identidad (10)"> {{row.did_codofi}} </td>
            </ng-container>
            <ng-container matColumnDef="campo12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. RUC / Doc. Identidad (11) </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. RUC / Doc. Identidad (11)"> {{row.cli_codcli}} </td>
            </ng-container>
            <ng-container matColumnDef="campo13">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Razon Social (12) </th>
              <td mat-cell *matCellDef="let row" data-label="Razon Social (12)"> {{row.cli_nomcli}} </td>
            </ng-container>
            <ng-container matColumnDef="campo14">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imponible Adq. Gravadas (13) </th>
              <td mat-cell *matCellDef="let row" data-label="Base Imponible Adq. Gravadas (13)"> {{row.baimpoexpo}} </td>
            </ng-container>
            <ng-container matColumnDef="campo15">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV o Imp. Municipal (14) </th>
              <td mat-cell *matCellDef="let row" data-label="IGV o Imp. Municipal (14)"> {{row.igvpm}} </td>
            </ng-container>
            <ng-container matColumnDef="campo16">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imponible Adq. No Gravadas (15) </th>
              <td mat-cell *matCellDef="let row" data-label="Base Imponible Adq. No Gravadas (15)"> {{row.bainograva}} </td>
            </ng-container>
            <ng-container matColumnDef="campo17">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV No Gravadas (16) </th>
              <td mat-cell *matCellDef="let row" data-label="IGV No Gravadas (16)"> {{row.igvpm2}} </td>
            </ng-container>
            <ng-container matColumnDef="campo18">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imponible sin Derecho a Crédito Fiscal (17) </th>
              <td mat-cell *matCellDef="let row" data-label="Base Imponible sin Derecho a Crédito Fiscal (17)"> {{row.baimnofisc}} </td>
            </ng-container>
            <ng-container matColumnDef="campo19">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV sin Derecho (18) </th>
              <td mat-cell *matCellDef="let row" data-label="IGV sin Derecho (18)"> {{row.igvpm3}} </td>
            </ng-container>
            <ng-container matColumnDef="campo20">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Adq. No Gravadas (19) </th>
              <td mat-cell *matCellDef="let row" data-label="Valor Adq. No Gravadas (19)"> {{row.adqnograva}} </td>
            </ng-container>
            <ng-container matColumnDef="campo21">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ISC (20) </th>
              <td mat-cell *matCellDef="let row" data-label="ISC (20)"> {{row.isc}} </td>
            </ng-container>
            <ng-container matColumnDef="campo22">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Tributos (21) </th>
              <td mat-cell *matCellDef="let row" data-label="Otros Tributos (21)"> {{row.otrconcept}} </td>
            </ng-container>
            <ng-container matColumnDef="campo23">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total (22) </th>
              <td mat-cell *matCellDef="let row" data-label="Importe Total (22)"> {{row.imptotadq}} </td>
            </ng-container>
            <ng-container matColumnDef="campo24">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Cambio (23) </th>
              <td mat-cell *matCellDef="let row" data-label="Tipo de Cambio (23)"> {{row.lrc_tipcam}} </td>
            </ng-container>
            <ng-container matColumnDef="campo25">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Comp. Pago (24) </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Emisión Comp. Pago (24)"> {{row.lrc_fecref | date  }} </td>
            </ng-container>
            <ng-container matColumnDef="campo26">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Comprob. Modifica (25) </th>
              <td mat-cell *matCellDef="let row" data-label="Tip. Comprob. Modifica (25)"> {{row.tdo_refofi}} </td>
            </ng-container>
            <ng-container matColumnDef="campo27">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie Modifica (26) </th>
              <td mat-cell *matCellDef="let row" data-label="Serie Modifica (26)"> {{row.lrc_serref}} </td>
            </ng-container>
            <ng-container matColumnDef="campo28">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de DUA (27) </th>
              <td mat-cell *matCellDef="let row" data-label="Código de DUA (27)"> {{row.cod_aduana}} </td>
            </ng-container>
            <ng-container matColumnDef="campo29">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante Modifica (28) </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Comprobante Modifica (28)"> {{row.lrc_numref}} </td>
            </ng-container>
            <ng-container matColumnDef="campo30">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Detracción (29) </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Detracción (29)"> {{row.LRC_FECDET | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="campo31">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Constancia Detracción (30) </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Constancia Detracción (30)"> {{row.lrc_depdet}} </td>
            </ng-container>
            <ng-container matColumnDef="campo32">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Marca del Comprobante (31) </th>
              <td mat-cell *matCellDef="let row" data-label="Marca del Comprobante (31)"> {{row.mar_retenc}} </td>
            </ng-container>
            <ng-container matColumnDef="campo33">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado (32) </th>
              <td mat-cell *matCellDef="let row" data-label="Estado (32)"> {{row.lrc_estad}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="34">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12">
          <mat-form-field>
            <mat-label>Cantidad</mat-label>
            <input type="number" matInput placeholder="Cantidad" [(ngModel)]="totalesComp.cantidad" name="cantidad">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tot. Bas. Imponible</mat-label>
            <input type="text" matInput placeholder="Tot. Bas. Imponible" name="tot_base_imponible" [value]="totalesComp.tot_base_imponible | number : '1.2-2'">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Valor Adquisiciones no Gravadas</mat-label>
            <input type="text" matInput placeholder="Valor Adquisiciones no Gravadas" [value]="totalesComp.valor_adq_no_grav | number : '1.2-2'" name="valor_adq_no_grav">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Total IGV</mat-label>
            <input type="text" matInput placeholder="Total IGV" [value]="totalesComp.total_igv | number : '1.2-2'" name="total_igv">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Importe Total</mat-label>
            <input type="text" matInput placeholder="Importe Total" [value]="totalesComp.importe_total | number : '1.2-2'">
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="showRevisaComprasNoDom">

    <mat-card class="mat-elevation-z0">
      <mat-card-header>
        <mat-card-title>Revisa Registro de Compras No Domiciliados</mat-card-title>
        <mat-card-subtitle>Período 2020 03</mat-card-subtitle>
      </mat-card-header>
      <div class="mat-elevation-z0 overflow-x">

        <table mat-table [dataSource]="dataSource2" matSort >

            <ng-container matColumnDef="campo1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
                <td mat-cell *matCellDef="let row" data-label="Fila"> {{row.item}} </td>
            </ng-container>
            <ng-container matColumnDef="campo2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CUO (1) </th>
                <td mat-cell *matCellDef="let row" data-label="CUO"> {{row.CUO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo3">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo (2)</th>
                <td mat-cell *matCellDef="let row" data-label="Correlativo"> {{row.correlativ}} </td>
            </ng-container>
            <ng-container matColumnDef="campo4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Comprobante (3)</th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Emisión Comprobante"> {{row.lrc_fecemi | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="campo5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Comprobante de Pago (4)</th>
              <td mat-cell *matCellDef="let row" data-label="Tipo de Comprobante de Pago"> {{row.tdo_codofi}} </td>
            </ng-container>
            <ng-container matColumnDef="campo6">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie del Comprobante de Pago (5)</th>
              <td mat-cell *matCellDef="let row" data-label="Serie del Comprobante de Pago"> {{row.lrc_serie}} </td>
            </ng-container>
            <ng-container matColumnDef="campo7">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Número del Comprobante de Pago (6)</th>
              <td mat-cell *matCellDef="let row" data-label="Número del Comprobante de Pago"> {{row.lrc_numdoc}} </td>
            </ng-container>
            <ng-container matColumnDef="campo8">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor de las Adquisiciones (7)</th>
              <td mat-cell *matCellDef="let row" data-label="Valor de las Adquisiciones"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo9">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Conceptos Adicionales (8)</th>
              <td mat-cell *matCellDef="let row" data-label="Otros Conceptos Adicionales"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total de las Adquisiciones (9)</th>
              <td mat-cell *matCellDef="let row" data-label="Importe Total de las Adquisiciones"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Comprobante de Pago (10)</th>
              <td mat-cell *matCellDef="let row" data-label="Tipo de Comprobante de Pago"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie del Comprobante de Pago (11)</th>
              <td mat-cell *matCellDef="let row" data-label="Serie del Comprobante de Pago"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo13">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Año de Emisión de la DUA o DS (12)</th>
              <td mat-cell *matCellDef="let row" data-label="Año de Emisión de la DUA o DS"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo14">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de Comprobante de Pago (13)</th>
              <td mat-cell *matCellDef="let row" data-label="Número de Comprobante de Pago"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo15">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto de Retención del IGV (14)</th>
              <td mat-cell *matCellDef="let row" data-label="Monto de Retención del IGV"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo16">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de la Moneda (Tabla 4) (15)</th>
              <td mat-cell *matCellDef="let row" data-label="Código de la Moneda (Tabla 4)"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo17">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Cambio (16)</th>
              <td mat-cell *matCellDef="let row" data-label="Tipo de Cambio"> {{row.lrc_tipcam}} </td>
            </ng-container>
            <ng-container matColumnDef="campo18">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> País de la Residencia del Sujeto no Domiciliado (17)</th>
              <td mat-cell *matCellDef="let row" data-label="País de la Residencia del Sujeto no Domiciliado"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo19">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellidos y nombres, denominación o razón social (18)</th>
              <td mat-cell *matCellDef="let row" data-label="Apellidos y nombres, denominación o razón social"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo20">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Domicilio en el extranjero del sujeto no domiciliado (19)</th>
              <td mat-cell *matCellDef="let row" data-label="Domicilio en el extranjero del sujeto no domiciliado"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo21">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de identificación del sujeto no domiciliado (20)</th>
              <td mat-cell *matCellDef="let row" data-label="Número de identificación del sujeto no domiciliado"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo22">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de identificación fiscal del beneficiario efectivo de los pagos (21)</th>
              <td mat-cell *matCellDef="let row" data-label="Número de identificación fiscal del beneficiario efectivo de los pagos"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo23">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellidos y nombres, denominación o razon social del beneficiario efectivo de los pagos (22)</th>
              <td mat-cell *matCellDef="let row" data-label="Apellidos y nombres, denominación o razon social del beneficiario efectivo de los pagos"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo24">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Pais de la residencia del beneficiario efectivo de los pagos (23)</th>
              <td mat-cell *matCellDef="let row" data-label="Pais de la residencia del beneficiario efectivo de los pagos"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo25">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Vínculo entre el contribuyente y el residente en el extranjero (24)</th>
              <td mat-cell *matCellDef="let row" data-label="Vínculo entre el contribuyente y el residente en el extranjero"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo26">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Renta Bruta (25)</th>
              <td mat-cell *matCellDef="let row" data-label="Renta Bruta"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo27">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Deducción / Costo de Enajenación de Bienes de capital (26)</th>
              <td mat-cell *matCellDef="let row" data-label="Deducción / Costo de Enajenación de Bienes de capital"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo28">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Renta Neta (27)</th>
              <td mat-cell *matCellDef="let row" data-label="Renta Neta"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo29">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tasa de Retención (28)</th>
              <td mat-cell *matCellDef="let row" data-label="Tasa de Retención"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo30">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Impuesto Retenido (29)</th>
              <td mat-cell *matCellDef="let row" data-label="Impuesto Retenido"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo31">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Convenios para evitar la doble imposición (30)</th>
              <td mat-cell *matCellDef="let row" data-label="Convenios para evitar la doble imposición"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo32">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Exoneración aplicada (31)</th>
              <td mat-cell *matCellDef="let row" data-label="Exoneración aplicada"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo33">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Renta (32)</th>
              <td mat-cell *matCellDef="let row" data-label="Tipo de Renta"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo34">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Modalidad del servicio prestado por el no domiciliado (33)</th>
              <td mat-cell *matCellDef="let row" data-label="Modalidad del servicio prestado por el no domiciliado"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo35">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Aplicación del penúltimo párrafo del Art. 76 de la Ley del impuesto a la renta (34)</th>
              <td mat-cell *matCellDef="let row" data-label="Aplicación del penúltimo párrafo del Art. 76 de la Ley del impuesto a la renta"> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <ng-container matColumnDef="campo36">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado que identifica la oportunidad de la anotación o indicación si esta corresponde a un ajuste. (35)</th>
              <td mat-cell *matCellDef="let row" data-label="Estado que identifica la oportunidad de la anotación o indicación si esta corresponde a un ajuste."> {{row.CHO_CODCHO}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="36">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Cantidad</mat-label>
            <input type="text" matInput placeholder="Cantidad" [(ngModel)]="totalesCompND.cantidad" name="cantidad">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Total de Adquisiciones</mat-label>
            <input type="text" matInput placeholder="Total de Adquisiciones" [value]="totalesCompND.total_adq | number : '1.2-2'" name="total_adq">
          </mat-form-field>
        </div>
      </div>
    </mat-card>

  </ng-container>

  <ng-container *ngIf="showRevisaVentas">
    <mat-card class="mat-elevation-z0">
      <mat-card-header>
        <mat-card-title>Revisa Registro de Ventas</mat-card-title>
        <mat-card-subtitle>Período 2020 03</mat-card-subtitle>
      </mat-card-header>
      <div class="mat-elevation-z0 overflow-x">

        <table mat-table [dataSource]="dataSource3" matSort >

            <ng-container matColumnDef="campo1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
                <td mat-cell *matCellDef="let row" data-label="Fila"> {{row.item}} </td>
            </ng-container>
            <ng-container matColumnDef="campo2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CUO (1)</th>
                <td mat-cell *matCellDef="let row" data-label="CUO (1)"> {{row.ldc_corldc}} </td>
            </ng-container>
            <ng-container matColumnDef="campo3">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo (2)</th>
                <td mat-cell *matCellDef="let row" data-label="Correlativo (2)"> {{row.lrv_correl}} </td>
            </ng-container>
            <ng-container matColumnDef="campo4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Comprobante (3)</th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Emisión Comprobante (3)"> {{row.cco_fecemi | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="campo5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de vencimiento (4)</th>
              <td mat-cell *matCellDef="let row" data-label="Fecha de vencimiento (4)"> {{row.cco_fecven | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="campo6">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo comprobante (5)</th>
              <td mat-cell *matCellDef="let row" data-label="Tipo comprobante (5)"> {{row.tdo_codofi}} </td>
            </ng-container>
            <ng-container matColumnDef="campo7">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie (6)</th>
              <td mat-cell *matCellDef="let row" data-label="Serie (6)"> {{row.lrv_serie}} </td>
            </ng-container>
            <ng-container matColumnDef="campo8">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante (7)</th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Comprobante (7)"> {{row.lrv_num}} </td>
            </ng-container>
            <ng-container matColumnDef="campo9">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Registro Tickets o cintas emitidos (8)</th>
              <td mat-cell *matCellDef="let row" data-label="Registro Tickets o cintas emitidos (8)"> {{row.lrv_ticket}} </td>
            </ng-container>
            <ng-container matColumnDef="campo10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc identidad (9)</th>
              <td mat-cell *matCellDef="let row" data-label="Tipo Doc identidad (9)"> {{row.did_codofi}} </td>
            </ng-container>
            <ng-container matColumnDef="campo11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro RUC / Doc. Identidad (10)</th>
              <td mat-cell *matCellDef="let row" data-label="Nro RUC / Doc. Identidad (10)"> {{row.cli_codcli}} </td>
            </ng-container>
            <ng-container matColumnDef="campo12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Razon Social (11)</th>
              <td mat-cell *matCellDef="let row" data-label="Razon Social (12)"> {{row.cli_nomcli}} </td>
            </ng-container>
            <ng-container matColumnDef="campo13">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Facturado de la exportación (12)</th>
              <td mat-cell *matCellDef="let row" data-label="Valor Facturado de la exportación (13)"> {{row.lrv_valexp}} </td>
            </ng-container>
            <ng-container matColumnDef="campo14">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Base imponible Oper. Gravadas (13)</th>
              <td mat-cell *matCellDef="let row" data-label="Base imponible Oper. Gravadas (14)"> {{row.lrv_impafe}} </td>
            </ng-container>
            <ng-container matColumnDef="campo15">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe de la oper. exonerada (14)</th>
              <td mat-cell *matCellDef="let row" data-label="Importe de la oper. exonerada (15)"> {{row.lrv_impexo}} </td>
            </ng-container>
            <ng-container matColumnDef="campo16">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe de la oper. inafecta (15)</th>
              <td mat-cell *matCellDef="let row" data-label="Importe de la oper. inafecta (16)"> {{row.lrv_impina}} </td>
            </ng-container>
            <ng-container matColumnDef="campo17">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Impuesto selectivo al consumo (16)</th>
              <td mat-cell *matCellDef="let row" data-label="Impuesto selectivo al consumo (17)"> {{row.lrv_impisc}} </td>
            </ng-container>
            <ng-container matColumnDef="campo18">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV / IPM (17)</th>
              <td mat-cell *matCellDef="let row" data-label="IGV / IPM (18)"> {{row.lrv_impigv}} </td>
            </ng-container>
            <ng-container matColumnDef="campo19">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imponible Ope. Gravada Arroz Pilado (18)</th>
              <td mat-cell *matCellDef="let row" data-label="Base Imponible Ope. Gravada Arroz Pilado (19)"> {{row.lrv_bimpar}} </td>
            </ng-container>
            <ng-container matColumnDef="campo20">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Impuesto Arroz Pilado (19)</th>
              <td mat-cell *matCellDef="let row" data-label="Base Imponible Ope. Gravada Arroz Pilado (19)"> {{row.lrv_imparr}} </td>
            </ng-container>
            <ng-container matColumnDef="campo21">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Tributos (20)</th>
              <td mat-cell *matCellDef="let row" data-label="Otros Tributos (20)"> {{row.lrv_otrimp}} </td>
            </ng-container>
            <ng-container matColumnDef="campo22">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total (21)</th>
              <td mat-cell *matCellDef="let row" data-label="Importe Total (21)">  </td>
            </ng-container>
            <ng-container matColumnDef="campo23">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de cambio (22)</th>
              <td mat-cell *matCellDef="let row" data-label="Tipo de cambio (22)"> {{row.lrv_tipcam}} </td>
            </ng-container>
            <ng-container matColumnDef="campo24">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión comp. pago (23)</th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Emisión comp. pago (23)"> {{row.lrv_fecref | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>
            <ng-container matColumnDef="campo25">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Comprob. Modifica (24)</th>
              <td mat-cell *matCellDef="let row" data-label="Tip. Comprob. Modifica (24)"> {{row.tdo_refofi}} </td>
            </ng-container>
            <ng-container matColumnDef="campo26">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie modifica o dep. aduanera (25)</th>
              <td mat-cell *matCellDef="let row" data-label="Serie modifica o dep. aduanera (25)"> {{row.lrv_serref}} </td>
            </ng-container>
            <ng-container matColumnDef="campo27">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante modifica o num. DUA (26)</th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Comprobante modifica o num. DUA (26)"> {{row.lrv_numref}} </td>
            </ng-container>
            <ng-container matColumnDef="campo28">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado (27)</th>
              <td mat-cell *matCellDef="let row" data-label="Estado (27)"> {{row.lrv_indsta}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="28">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12">
          <mat-form-field>
            <mat-label>Cantidad</mat-label>
            <input type="text" matInput placeholder="Cantidad" [(ngModel)]="totalesVentas.cantidad"  name="cantidad">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Tot. Bas. Imponible Gravadas</mat-label>
            <input type="text" matInput placeholder="Tot. Bas. Imponible Gravadas" [value]="totalesVentas.tot_bas_imp_grav | number : '1.2-2'"  name="tot_bas_imp_grav">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Total IGV</mat-label>
            <input type="text" matInput placeholder="Total IGV" [value]="totalesVentas.total_igv | number : '1.2-2'"  name="total_igv">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Valor Fac. de Exportación</mat-label>
            <input type="text" matInput placeholder="Valor Fac. de Exportación" [value]="totalesVentas.valor_fac_expo | number : '1.2-2'"  name="valor_fac_expo">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Inafecta</mat-label>
            <input type="text" matInput placeholder="Inafecta" [value]="totalesVentas.inafecta | number : '1.2-2'"  name="inafecta">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Exonerada</mat-label>
            <input type="text" matInput placeholder="Exonerada" [value]="totalesVentas.exonerado | number : '1.2-2'"  name="exonerado">
          </mat-form-field>
          <mat-form-field>
            <mat-label>ISC</mat-label>
            <input type="text" matInput placeholder="ISC" [value]="totalesVentas.isc | number : '1.2-2'"  name="isc">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Arroz Pilado</mat-label>
            <input type="text" matInput placeholder="Arroz Pilado" [value]="totalesVentas.arroz_pilado | number : '1.2-2'"  name="arroz_pilado">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Imp. Arroz Pilado</mat-label>
            <input type="text" matInput placeholder="Imp. Arroz Pilado" [value]="totalesVentas.imp_arroz_pilado | number : '1.2-2'"  name="imp_arroz_pilado">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Otros Tributos</mat-label>
            <input type="text" matInput placeholder="Otros Tributos" [value]="totalesVentas.otros_tributos | number : '1.2-2'"  name="otros_tributos">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Importe Total</mat-label>
            <input type="text" matInput placeholder="Importe Total" [value]="totalesVentas.importe_total | number : '1.2-2'"  name="importe_total">
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </ng-container>
</div>
