import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ContabilidadService } from "src/app/services";
import { AsientoContableService } from "src/app/services/api/contabilidad/operaciones/asiento-contable.service";

class IConsultaAsiento {
  codano: string;
  codmes: string;
  codsco: string;
  corldc: string;
}

@Component({
  selector: 'app-asiento-contable-copy-dialog',
  template: `
    <h1>Encontrar asiento contable</h1>
    <form #f="ngForm" ngNativeValidate (ngSubmit)="consultar(f)" (keydown.enter)="$event.preventDefault()">
      <div class="row">
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Año</mat-label>
            <input type="number" matInput placeholder="Ej. 2020" [(ngModel)]="consultaAsiento.codano" name="codano">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Mes</mat-label>
            <input type="number" matInput placeholder="Ej. 06" [(ngModel)]="consultaAsiento.codmes" name="codmes">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
        <select-search
          [label]="'SubDiario'" [placeholder]="'Ej. ASIENTO DE APERTURA'" [name]="'sco_codsco'" [value]="'sco_codsco'"
          [description]="'sco_descri'" [data]="subdiarios" (cambioSelect)="consultaAsiento.codsco = $event;"
          [model]="consultaAsiento.codsco"></select-search>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Nro. asiento</mat-label>
            <input type="text" matInput placeholder="Ej. 00001" [(ngModel)]="consultaAsiento.corldc" name="corldc">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col s12" align="center">
          <button type="submit" mat-button class="pav-button-action">Consultar</button>
        </div>
      </div>
    </form>
  `,
  styleUrls: ['./asiento-contable-copy-dialog.component.scss']
})
export class AsientoContableCopyDialog implements OnInit, OnDestroy {

  subdiarios: any[] = [];

  consultaAsiento: IConsultaAsiento;

  subdiarios$: Subscription;
  consultaAsiento$: Subscription;

  loaderData: boolean;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _asientoContableService: AsientoContableService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<AsientoContableCopyDialog>,
    private store: Store<PavsoState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.consultaAsiento = new IConsultaAsiento();

    this.store.select('period').subscribe(({ year, month }) => {
      this.consultaAsiento.codano = year;
      this.consultaAsiento.codmes = month;
    })
  }


  ngOnInit(): void {
    this.loadMaestros();
  }

  loadMaestros(): void {
    this.loaderData = true;
    this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => {
        this.subdiarios = subdiarios;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
        this.loaderData = false;
      }
    )
  }

  consultar(f: NgForm): void {

    const { codano, codmes, codsco, corldc } = this.consultaAsiento;

    this.consultaAsiento$ = this._asientoContableService.obtenerAsientoContable(codano, codmes, codsco, corldc).subscribe(
      asientoContable => {
        this.dialogRef.close(asientoContable);
        this._snackBarService.showSuccess('Consulta exitosa', 'Ok');
      },
      error => {
        this._snackBarService.showError('Error al consultar asiento contable', 'Ok');
        this.loaderData = false;
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.subdiarios$])
  }
}
