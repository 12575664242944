<app-pavso-title-section
  [title]="'Genera Comprobantes desde Pedido'"
  [module]="'Ventas'" [ngClass]="'pav-form'"
></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <form
    #f="ngForm"
    ngNativeValidate
    (ngSubmit)="generarFactura(f)"
    (keydown.enter)="$event.preventDefault()"
  >
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <select-search [multiple]="true" [label]="'Vendedor'" [placeholder]="'Ej. PEREZ'" [name]="'VDE_CODVDE'" [value]="'VDE_CODVDE'" [description]="'VDE_NOMVDE'" [data]="vendedores" (cambioSelect)="filtroGeneraFactura.vendedores = $event" [model]="filtroGeneraFactura.vendedores"></select-search>

        </div>
        <div class="col s12 m12 l3">

          <select-search [multiple]="true" [label]="'Transportista'" [placeholder]="'Ej. PEREZ'" [name]="'cli_codcli'" [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="transportistas" (cambioSelect)="filtroGeneraFactura.transportistas = $event" [model]="filtroGeneraFactura.transportistas"></select-search>

        </div>
        <div class="col s12 m12 l3">

          <select-search [multiple]="true" [label]="'Cliente'" [placeholder]="'Ej. PEREZ'" [name]="'cli_codcli'" [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="clientes" (cambioSelect)="filtroGeneraFactura.clientes = $event" [model]="filtroGeneraFactura.clientes"></select-search>

        </div>
        <div class="col s12 m12 l3">

          <select-search [multiple]="true" [label]="'Zona Vta.'" [placeholder]="'Ej. LIMA CENTRO'" [name]="'ZVE_CODZVE'" [value]="'ZVE_CODZVE'" [description]="'ZVE_NOMZVE'" [data]="zonas" (cambioSelect)="filtroGeneraFactura.zonas = $event" [model]="filtroGeneraFactura.zonas"></select-search>

        </div>
        <div class="col s12 m12 l3">
          <mat-checkbox #entrada name="fechaEntrada">
            <mat-form-field>
              <mat-label>Desde - Hasta</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start date"
                  [disabled]="!entrada.checked"
                />
                <input [disabled]="!entrada.checked" matEndDate formControlName="end" placeholder="End date" />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error
                *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                >Invalid start date</mat-error
              >
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                >Invalid end date</mat-error
              >
            </mat-form-field>
          </mat-checkbox>
        </div>
        <div class="col s12 m12 l3">
          <mat-checkbox #fechaActual name="fechaActual">
            <mat-form-field>
              <mat-label>Fecha actual</mat-label>
              <input
                matInput
                [matDatepicker]="fechaactual"
                [disabled]="!fechaActual.checked"
                name="fechaactual"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fechaactual"
              ></mat-datepicker-toggle>
              <mat-datepicker #fechaactual></mat-datepicker>
            </mat-form-field>
          </mat-checkbox>
        </div>
        <div class="col s12 m12 l3">
          <mat-radio-group [(ngModel)]="filtroGeneraFactura.ubicacion" name="ubicacion" aria-label="Select an option">
            <mat-radio-button value="TODOS">Todos</mat-radio-button>

            <mat-radio-button value="LIMA">Lima</mat-radio-button>

            <mat-radio-button value="PROVINCIA">Provincia</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col s12">
          <mat-checkbox
            class="example-margin"
            [(ngModel)]="generarSalida"
            name="generarSalida"
            >Generar Salida al Generar Comprobante</mat-checkbox
          >
          &nbsp;
          <mat-checkbox [(ngModel)]="filtroGeneraFactura.atencionCompleta" name="atencionCompleta" class="example-margin">Atender Completo</mat-checkbox>
          &nbsp;
          <mat-checkbox [(ngModel)]="filtroGeneraFactura.entregaVendedor" name="entregaVendedor" class="example-margin">Entrega Vendedor</mat-checkbox>
          &nbsp;
          <mat-checkbox class="example-margin">Por día de entrega</mat-checkbox>

        </div>
      </div>
      <br>
      <button
        type="button"
        class="pav-button-action"
        mat-flat-button
        color="primary"
        (click)="buscarPedidos()"
      >
        Buscar
      </button>
    </mat-card>
    <br />

    <div>
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon"></div>
        <div class="pav-table-header-title">Pedidos</div>
        <div class="pav-table-header-add">
          <div class="isMobile"></div>
          <div class="isWeb"></div>
        </div>
      </div>
      <div class="mat-elevation-z0 overflow-x">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td
              mat-cell
              *matCellDef="let row"
              [style.color]="row.color"
              data-label="Acciones"
            >
              <div class="pavso-container-action">


              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="campo1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Nro. Pedido
            </th>
            <td mat-cell *matCellDef="let row" data-label="Nro. Pedido">
              {{ row.PCC_NUMPCC ? row.PCC_NUMPCC : row.pcc_numpcc }}
            </td>
          </ng-container>

          <ng-container matColumnDef="campo2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Documento Cliente
            </th>
            <td mat-cell *matCellDef="let row" data-label="Documento Cliente">
              {{ row.CLI_CODCLI ? row.CLI_CODCLI : row.cli_codcli }}
            </td>
          </ng-container>

          <ng-container matColumnDef="campo3">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
            <td mat-cell *matCellDef="let row" data-label="Cliente">
              {{ row.CLI_NOMCLI ? row.CLI_NOMCLI : row.cli_nomcli }}
            </td>
          </ng-container>

          <ng-container matColumnDef="campo4">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
            <td mat-cell *matCellDef="let row" data-label="Fecha">
              {{
                (row.PCC_FECDOC ? row.PCC_FECDOC : row.pcc_fecdoc)
                  | date : "dd/MM/yyyy"
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="campo5">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Tipo de Moneda
            </th>
            <td mat-cell *matCellDef="let row" data-label="Tipo de Moneda">
              {{ row.tmo_codtmo == "SO" ? "Soles" : "Dolares" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="campo6">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Importe Total
            </th>
            <td mat-cell *matCellDef="let row" data-label="Importe Total" [ngStyle]="{textAlign: 'right'}">
              {{ row.PCC_IMPTOT ? row.PCC_IMPTOT : row.pcc_imptot }}
            </td>
          </ng-container>

          <ng-container matColumnDef="campo7">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendedor</th>
            <td mat-cell *matCellDef="let row" data-label="Vendedor">
              {{ row.VDE_CODVDE ? row.VDE_CODVDE : row.vde_nomvde }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="obtenerDetallePedido(row, row.pcc_numpcc)"
          ></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" *ngIf="!loaderOrders">
              No se encontraron registros
            </td>
            <td class="mat-cell" colspan="8" *ngIf="loaderOrders">
              <mat-spinner diameter="40"></mat-spinner>
            </td>
          </tr>
        </table>

        <mat-paginator
          showFirstLastButtons
          [pageSizeOptions]="[5, 10, 25, 100]"
          [pageSize]="5"
        ></mat-paginator>
      </div>
    </div>

    <br />

    <div class="row">
      <div class="col s12 m12 l8">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon"></div>
          <div class="pav-table-header-title">Detalle</div>
          <div class="pav-table-header-add">
            <div class="isMobile"></div>
            <div class="isWeb"></div>
          </div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSourceProductos" matSort>
            <ng-container matColumnDef="campo1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Código</th>
              <td mat-cell *matCellDef="let row" data-label="Código">
                {{ row.PRD_CODPRD ? row.PRD_CODPRD : row.prd_codprd }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo2">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Sec.</th>
              <td mat-cell *matCellDef="let row" data-label="Sec."></td>
            </ng-container>

            <ng-container matColumnDef="campo3">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</th>
              <td mat-cell *matCellDef="let row" data-label="Descripción">
                {{ row.PRD_DESESP ? row.PRD_DESESP : row.prd_desesp }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo4">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>U.M.</th>
              <td mat-cell *matCellDef="let row" data-label="U.M.">
                {{ row.UME_CODVEN ? row.UME_CODVEN : row.ume_codume }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo5">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
              <td mat-cell *matCellDef="let row" data-label="Cantidad" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_CANSOL ? row.PCD_CANSOL : row.pcd_cansol }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo6">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Facturado</th>
              <td mat-cell *matCellDef="let row" data-label="Precio" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_PREUNI ? row.PCD_PREUNI : row.pcd_canfac }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo7">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Despachado</th>
              <td mat-cell *matCellDef="let row" data-label="Precio inc. IGV" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_PRUIGV ? row.PCD_PRUIGV : row.pcd_canate }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo8">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Pendiente</th>
              <td mat-cell *matCellDef="let row" data-label="Precio inc. IGV" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_PRUIGV ? row.PCD_PRUIGV : row.pcd_penfac }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo9">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Procesar</th>
              <td mat-cell *matCellDef="let row" data-label="Precio inc. IGV" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_PRUIGV ? row.PCD_PRUIGV : row.pcd_canpro }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Stock</th>
              <td mat-cell *matCellDef="let row" data-label="Precio inc. IGV" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_PRUIGV ? row.PCD_PRUIGV : row.spa_canfin }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Stock 2</th>
              <td mat-cell *matCellDef="let row" data-label="Precio inc. IGV" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_PRUIGV ? row.PCD_PRUIGV : row.spa_proyec }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>U.M. Venta</th>
              <td mat-cell *matCellDef="let row" data-label="U.M. Venta">
                {{ row.UME_CODVEN ? row.UME_CODVEN : row.ume_codven }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo13">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Cantidad Venta
              </th>
              <td mat-cell *matCellDef="let row" data-label="Cantidad Venta" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_CANSOL ? row.PCD_CANSOL : row.pcd_penfve }}
              </td>
            </ng-container>

            <ng-container matColumnDef="campo14">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Procesar Venta
              </th>
              <td mat-cell *matCellDef="let row" data-label="Importe Bruto" [ngStyle]="{textAlign: 'right'}">
                {{ row.PCD_IMPBRU ? row.PCD_IMPBRU : row.pcd_venpro }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsProductos"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsProductos"
            ></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="20" *ngIf="!loaderProductos">
                No se encontraron registros
              </td>
              <td class="mat-cell" colspan="20" *ngIf="loaderProductos">
                <mat-spinner diameter="40"></mat-spinner>
              </td>
            </tr>
          </table>

        </div>
        <mat-paginator
          #paginatorDetalle
          showFirstLastButtons
          [pageSizeOptions]="[5, 10, 25, 100]"
          [pageSize]="5"
        ></mat-paginator>
      </div>
      <div class="col s12 m12 l4">
        <mat-card class="mat-elevation-z0">
          <mat-card-header>
            <mat-checkbox [(ngModel)]="generaFactura.indfac" name="indfac" class="example-margin"></mat-checkbox>
            <mat-card-title>Datos factura</mat-card-title>
          </mat-card-header>
            <div class="row">

              <div class="col s12">
                <mat-form-field>
                  <mat-label>Fecha</mat-label>
                  <input
                    matInput
                    [(ngModel)]="generaFactura.fecdoc"
                    [matDatepicker]="fecdoc"
                    name="fecdoc"
                    [disabled]="!generaFactura.indfac"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="fecdoc"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #fecdoc></mat-datepicker>
                </mat-form-field>

              </div>
              <div class="col s12">
                <mat-form-field>
                  <mat-label>Documento</mat-label>
                  <mat-select [disabled]="!generaFactura.indfac" (selectionChange)="seleccionarDocumento($event.value)" [(ngModel)]="generaFactura.codtdo" name="codtdo">
                    <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.tdo_codtdo">
                      {{tipo.tdo_deslar}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s6">
                <mat-form-field>
                  <mat-label>Serie</mat-label>
                  <mat-select [disabled]="!generaFactura.indfac" (selectionChange)="obtenerNroDocFactura()" [(ngModel)]="generaFactura.numser" name="serieFactura">
                    <mat-option *ngFor="let serie of seriesDocumento" [value]="serie.sdo_codsdo">
                      {{serie.tdo_codtdo}} - {{serie.sdo_codsdo}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s6">
                <mat-form-field class="example-full-width">
                  <mat-label>Nro. Doc.</mat-label>
                  <input [disabled]="!generaFactura.indfac" matInput placeholder="Nro. Doc." [(ngModel)]="generaFactura.numfac" name="numfac">
                </mat-form-field>
              </div>
            </div>
        </mat-card>
        <br>
        <mat-card class="mat-elevation-z0">
          <mat-card-header>
            <mat-checkbox [(ngModel)]="generaFactura.indgui" name="indgui" class="example-margin"></mat-checkbox>
            <mat-card-title>Datos guía de remisión</mat-card-title>
          </mat-card-header>

          <div class="row">
            <div class="col s12">
              <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input
                  matInput
                  [disabled]="!generaFactura.indgui"
                  [(ngModel)]="generaFactura.fechaGuia"
                  [matDatepicker]="fechaguia"
                  name="fechaguia"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="fechaguia"
                ></mat-datepicker-toggle>
                <mat-datepicker #fechaguia></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s6">
              <mat-form-field>
                <mat-label>Serie Guía</mat-label>
                <mat-select [disabled]="!generaFactura.indgui" (selectionChange)="obtenerNroDocGuia()" [(ngModel)]="generaFactura.sergui" name="sergui">
                  <mat-option *ngFor="let serie of seriesDocumentoGuia" [value]="serie.sdo_codsdo">
                    {{serie.tdo_codtdo}} - {{serie.sdo_codsdo}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s6">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Doc.</mat-label>
                <input [disabled]="!generaFactura.indgui" matInput placeholder="Nro. Doc." [(ngModel)]="generaFactura.nrogui" name="nrogui">
              </mat-form-field>
            </div>
            <div class="col s12">
              <select-search [label]="'Transportista'" [placeholder]="'Ej. PEREZ'" [name]="'cli_codcli'" [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="transportistas" (cambioSelect)="generaFactura.clitra = $event" [model]="generaFactura.clitra"></select-search>

            </div>
          </div>
        </mat-card>

        <br>

        <!-- <button color="primary" mat-flat-button>Boton</button>
        &nbsp;&nbsp;
        <button color="primary" mat-flat-button>Generar comprobante</button> -->
      </div>
    </div>

    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
          (clickMasOpciones)="verMasOpciones()">
          <div id="botonesAdicionales">

          </div>
      </pav-form-actions>
  </form>
</div>
