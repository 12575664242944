import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { MotivoRechazoService } from 'src/app/services/api/produccion/maestros/motivo-rechazo.service';
@Component({
  selector: 'app-motivo-rechazo-list',
  templateUrl: './motivo-rechazo-list.component.html',
  styleUrls: ['./motivo-rechazo-list.component.css']
})
export class MotivoRechazoListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'mre_codmre', 'mre_desmre'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  driver$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

 constructor(
    private _motivoRechazoService: MotivoRechazoService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
   }
  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerMotivosRechazo();
    })
  }

  obtenerMotivosRechazo(): void {
    this.loaderData = true;
    this.driver$ = this._motivoRechazoService.obtenerMotivosRechazo().subscribe(
      response => {
        this.loaderData = false;
        this.dataSource = fillTable(response, this.paginator, this.sort);
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener las lineas de producción','OK');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.driver$,this.loading$
    ])
  }
  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }


}
