<app-pavso-title-section [title]="'CAJA Y BANCOS'" [module]="'FLUJO DE CAJA'" [estado]="flujoCaja.fca_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="flujoCaja.fca_codfca" matInput placeholder="Código" name="fca_codfca" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="flujoCaja.fca_descri" matInput placeholder="Descripción" name="fca_descri" required>
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Grupo</mat-label>
            <mat-select [(ngModel)]="flujoCaja.cac_codcac" required name="cac_codcac">
              <mat-option *ngFor="let grupo of grupos" [value]="grupo.CFU_CODCFU">
                {{grupo.CFU_DESCRI}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo</mat-label>
            <input [(ngModel)]="flujoCaja.fca_tipflu" type="text" matInput placeholder="Tipo" name="fca_tipflu">
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <br>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>


  </form>
</div>
