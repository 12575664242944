import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { NominaService } from "src/app/services";

@Component({
  selector: 'app-listado-liquidacion-cts',
  templateUrl: './listado-liquidacion-cts.component.html',
  styleUrls: ['./listado-liquidacion-cts.component.scss']
})
export class ListadoLiquidacionCTSComponent implements OnInit, OnDestroy {

  year: string;
  month: string;

  displayedColumns: string[] = ['acciones', 'ano_codano', 'mes_codmes', 'cod_colaborador', 'nombre'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  loading$: Subscription;
  liquidaciones$: Subscription;

  constructor(
    private _nominaService: NominaService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService
  ){
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this.liquidaciones$ = this._nominaService.obtenerLiquidacionesCTS(this.year, this.month).subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.liquidaciones$]);
  }

}
