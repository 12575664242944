<app-pavso-title-section [title]="'AFP'" [module]="'Planilla'" [ngClass]="'pav-form'" [estado]="afp.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="afp.codafp" matInput placeholder="Código" name="codafp" required disabled>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6"></div>

        <div class="col s12 m12">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="afp.descri" matInput placeholder="Descripción" name="descri" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Auxiliar</mat-label>
            <mat-select [(ngModel)]="afp.codcli" required name="codcli">
              <mat-option *ngFor="let auxiliar of auxiliares; trackBy: trackByAuxiliar" [value]="auxiliar.CODIGO">
                {{auxiliar.NOMBRE}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Iniciales AFP Net</mat-label>
            <input type="text" [(ngModel)]="afp.codini" matInput placeholder="Iniciales AFP Net" name="codini" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <div class="pav-checkbox">
            <mat-checkbox class="example-margin" [(ngModel)]="afp.exconp" name="exconp">Organismo Estado</mat-checkbox>
          </div>
        </div>
      </div>

    </mat-card>
    <br>
    <div class="pav-table-header-container">
      <div class="pav-table-header-icon">
      </div>
      <div class="pav-table-header-title">
        Tabla
      </div>
      <div class="pav-table-header-add">
        <div class="isMobile">
          <div class="pav-button-icon-add" (click)="agregarItem()">
            <mat-icon svgIcon="add"></mat-icon>
          </div>
        </div>
        <div class="isWeb">
          <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarItem()">Agregar ítem</button>
        </div>
      </div>
    </div>
    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" data-label="Código">
            <div class="pav-btns-container">
              <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
              <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                <mat-icon svgIcon="confirm"></mat-icon>
              </div>
              <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="codnco">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Concepto </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Código">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
                <mat-label></mat-label>
                <mat-select required (selectionChange)="seleccionarConcepto(row)" [(ngModel)]="row.codnco" [name]="'codnco' + i.toString()">
                  <mat-option *ngFor="let concepto of conceptos; trackBy: trackByConcepto" [value]="concepto.codnco">
                    {{concepto.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codnco}} | {{row.desnco}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="porafp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Porcentaje (%) </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Porcentaje (%)" class="pav-td-center">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                <mat-label></mat-label>
                <input (blur)="blurPorcentaje(row)" #porcentaje (focus)="porcentaje.select()" class="pav-input-align-right" min="0" max="100" required type="number" matInput placeholder="Porcentaje" [(ngModel)]="row.porafp"
                [name]="'porcentaje' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing" style="text-align: center;">
              {{row.porafp.toFixed(2)}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="topafp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto tope </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Monto tope" class="pav-td-center">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                <mat-label></mat-label>
                <input class="pav-input-align-right" [disabled]="row.codnco != '505'" min="0" required type="number" matInput placeholder="Monto Tope" [(ngModel)]="row.topafp"
                [name]="'montotope' + i.toString()" required>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              <div style="text-align: center;">
                {{row.topafp.toFixed(2)}}
              </div>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="codccn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
                <mat-label></mat-label>
                <mat-select required (selectionChange)="seleccionarConcepto(row)" [(ngModel)]="row.codccn" [name]="'codccn' + i.toString()">
                  <mat-option *ngFor="let cuenta of cuentas; trackBy: trackByCuenta" [value]="cuenta.cuenta">
                    {{cuenta.descri_cuenta}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codccn}}
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No se encontraron registros</td>

        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-planilla/afps">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
