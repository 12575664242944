import { Component } from "@angular/core";

@Component({
  selector: 'presupuesto-cotizacion-decoracion-list',
  templateUrl: './presupuesto-cotizacion-decoracion-list.component.html',
  styleUrls: ['./presupuesto-cotizacion-decoracion-list.component.scss']
})
export class PresupuestoCotizacionDecoracionListComponent {

}
