import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { AlmacenService } from "src/app/services";

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexLegend
} from "ng-apexcharts";
import { InventarioExcelService } from "src/app/services/reporte-excel/inventario-excel.service";
import { retornaDiferenciaDias } from "@utils/date/date";
import { agrupar } from "@utils/array/array";
import { formatDateWithSlash } from "@utils/formats/date.format";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
};

@Component({
  selector: 'app-panel-almacen',
  templateUrl: './panel-almacen.component.html',
  styleUrls: ['./panel-almacen.component.scss']
})
export class PanelAlmacenComponent implements OnInit, OnDestroy {

  loading$: Subscription;
  period$: Subscription;
  data$: Subscription;
  lotesVencidos$: Subscription;

  anioPeriodo: string;
  mesPeriodo: string;

  data: any[] = [];
  dataSaldosLote: any[] = [];
  lotesVencidos: any[] = [];

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;

  anioChart: string = '2024';
  anioChart2: string = '2024';

  constructor(
    private readonly _snackBarService: SnackBarService,
    private readonly _almacenService: AlmacenService,
    private readonly _inventarioService: InventarioExcelService,
    private store: Store<PavsoState>
  ) {
    this.chartOptions = {
      series: [
        {
          name: "serie1",
          data: [44, 55, 41, 64]
        },
      ],
      chart: {
        type: "bar",
        height: 430
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"]
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: ['Enero', "Frebrero", 'Marzo', 'Abril']
      },
      title: {
        text: "Tasa de retorno de stock (%)"
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
      }
    };

    this.chartOptions2 = {
      series: [
        {
          name: "serie1",
          data: [48, 36, 85, 35]
        },
      ],
      chart: {
        type: "bar",
        height: 430
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"]
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: ['Enero', "Frebrero", 'Marzo', 'Abril']
      },
      title: {
        text: "Tasa de venta directa (%)"
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
      }
    };
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.period$ = this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })
  }

  loadData(): void {
    this.data$ = this._almacenService.consultarStockProductosConsolidado(this.anioPeriodo, this.mesPeriodo).subscribe(
      data => {
        console.log('data', data)
        this.data = data.filter(item => item.prd_stomin > 0)//.prd_stomin > 0 && item.spa_canfin < item.prd_stomin);

        this.lotesVencidos$ = this._almacenService.consultarStockDetalleLote(this.anioPeriodo, this.mesPeriodo).subscribe(
          lotes => {
            this.dataSaldosLote = lotes;
            const agrupado = agrupar(lotes, 'prd_codprd');
            const keys = Object.keys(agrupado);



            let primeraAgrupacion = [];
            keys.forEach(key => {
              const encontrado = agrupado[key].find(item => item.prd_codprd == key);

              let lotesVencidos = 0;
              let lotesPorVencer = 0;

              agrupado[key].forEach(element => {
                if (retornaDiferenciaDias(element.lot_fecven, new Date().toISOString()) >= 0 && element.spa_canfin > 0) {
                  lotesVencidos++;
                }
                if (retornaDiferenciaDias(new Date().toISOString(), element.lot_fecven) > 0 && retornaDiferenciaDias(new Date().toISOString(), element.lot_fecven) <= 3 && element.spa_canfin > 0) {
                  lotesPorVencer++;
                }
              });

              primeraAgrupacion.push({
                prd_codprd: key,
                prd_desesp: encontrado.prd_desesp,
                nroLotesVencidos: lotesVencidos,
                nroLotesPorVencer: lotesPorVencer,
                detalle: agrupado[key]
              })
            })

            this.lotesVencidos = primeraAgrupacion;

          },
          error => {
            this._snackBarService.showError(error.error.message, 'Ok');
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.message, 'Ok');
      }
    )
  }

  retornaTagVencimiento(fechaVen: string): number {
    // TODO: verificar que el servicio de fecha de vencimiento no retorne null
    const diferencia = retornaDiferenciaDias(new Date().toISOString(), fechaVen ? fechaVen : new Date().toISOString());
    return diferencia;
  }

  generarExcel(): void {

    const dataExcel = this.data.map(item => ({
      CODIGO_PRODUCTO: item.prd_codprd,
      PRODUCTO: item.prd_desesp,
      STOCK_ACTUAL: item.spa_canfin,
      POR_DEBAJO_STOCK_MINIMO: item.prd_stomin,
      DIFERENCIA: item.spa_canfin - item.prd_stomin,
    })
    )

    this._inventarioService.generarExcelStockMinimo({ data: dataExcel, title: `Tabla Stock Mínimo | ${this.anioPeriodo} ${this.mesPeriodo}` });

  }

  generarExcelLotesVencidos(): void {
    const filtrados = this.dataSaldosLote.filter(item => {
      console.log('diferencia')
      return retornaDiferenciaDias(item.lot_fecven, new Date().toISOString()) >= 0 && item.spa_canfin > 0;

    })

    const dataExcel = filtrados.map(item => {
      return ({
        CODIGO_PRODUCTO: item.prd_codprd,
        PRODUCTO: item.prd_desesp,
        LOTE: item.spa_numlot,
        SALDO: item.spa_canfin,
        FECHA_CREACION: formatDateWithSlash(item.lot_feccre),
        FECHA_VENCIMIENTO: formatDateWithSlash(item.lot_fecven),
      })

    })

    this._inventarioService.generarExcelStockMinimo({ data: dataExcel, title: `Tabla Lotes Vencidos | ${this.anioPeriodo} ${this.mesPeriodo}` });
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.period$,
      this.data$,
    ])
  }

}
