import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BINARY_TYPE } from '@data/interfaces/binary.type';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-pavso-title-section',
  templateUrl: './pavso-title-section.component.html',
  styleUrls: ['./pavso-title-section.component.css']
})
export class PavsoTitleSectionComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() module: string;
  @Input() estadoAprOrdenCompra: BINARY_TYPE;
  @Input() usuario: string;
  @Input() fecha: Date;
  @Input() estado: string | number;
  @Input() otraInformacion?: string;
  @Input() ngClass: string = 'pav-form';

  establecimiento: string = '';
  establecimientoDescri: string = '';
  establecimientos: any[];
  establecimiento$: Subscription;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _authService: AuthenticationService,
    private breakpointObserver: BreakpointObserver,
    private store: Store<PavsoState>
  ) {
    this.usuario = this._authService.getUsuarioSistema();
    this.fecha = new Date();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Method not implemented.', changes);
  }

  ngOnInit(): void {
    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
      this.establecimientoDescri = state.establecimiento;
      this.establecimientos = state.establecimientos;
    })

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });
  }

}
