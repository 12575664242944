export class GrupoActivoFijo {
  cia_codcia: string;
  gac_codgac: string;
  taf_codtaf: string;
  gac_deslar: string;
  gac_descor: string;
  gac_indsta: string;
  gac_coduse: string;
  gac_fecupd: Date;

  constructor() {
    this.gac_indsta = '1';
    this.gac_fecupd = new Date();
  }
}
