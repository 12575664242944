import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<SuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
  ) {
    this.playSound()
  }

  ngOnInit(): void {
  }

  onNoClick(): void {

    this.dialogRef.close();

  }

  playSound() {
    // const audio = new Audio('assets/mp3/success.mp3');
    // audio.play();
  }

  ngOnDestroy(): void {}

}
