<app-pavso-title-section [title]="'Período CTS'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <mat-horizontal-stepper #stepper>
      <mat-step label="Período CTS" [mat-dialog-close]="true">
        <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarPeriodoCTS(f, stepper)" (keydown.enter)="$event.preventDefault()">

          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Período</mat-label>
                <input [disabled]="disabledForm" matInput type="text" [value]="periodoCTS.codano + periodoCTS.codmes" name="periodo" readonly required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Fecha obligación</mat-label>
                <input [disabled]="disabledForm" matInput [matDatepicker]="fechaobligacion" [(ngModel)]="periodoCTS.fecobl" name="fecobl" required>
                <mat-datepicker-toggle matSuffix [for]="fechaobligacion"></mat-datepicker-toggle>
                <mat-datepicker #fechaobligacion></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Fecha inicio</mat-label>
                <input disabled matInput [matDatepicker]="fechainicio" [(ngModel)]="periodoCTS.ranini" name="ranini" required>
                <mat-datepicker-toggle matSuffix [for]="fechainicio"></mat-datepicker-toggle>
                <mat-datepicker #fechainicio></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Fecha término</mat-label>
                <input disabled matInput [matDatepicker]="fechatermino" [(ngModel)]="periodoCTS.ranfin" name="ranfin" required>
                <mat-datepicker-toggle matSuffix [for]="fechatermino"></mat-datepicker-toggle>
                <mat-datepicker #fechatermino></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Fecha pago</mat-label>
                <input [min]="periodoCTS.fecobl" [disabled]="disabledForm" matInput [matDatepicker]="fechadeposito" [(ngModel)]="periodoCTS.fecpag" name="fecpag" (dateChange)="obtenerTipoCambio()" required>
                <mat-datepicker-toggle matSuffix [for]="fechadeposito"></mat-datepicker-toggle>
                <mat-datepicker #fechadeposito></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <button [disabled]="disabledForm" class="pav-button-other" mat-flat-button color="primary" (click)="obtenerInteresBanco()" type="button">
                <mat-icon>percent</mat-icon>
                Intereses
              </button>
            </div>
          </div>

          <div>
            <button [disabled]="disabledForm" class="pav-button-action" mat-flat-button color="primary" type="submit" [class.spinner]="loaderReg">{{btnName | translate}}</button>
          </div>
        </form>
      </mat-step>

      <mat-step label="Calcula CTS">
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Fecha</mat-label>
              <input matInput [(ngModel)]="periodoCTS.fecpag" [matDatepicker]="fechahoy" name="fechahoy" disabled readonly>
              <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
              <mat-datepicker #fechahoy></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Tipo de cambio</mat-label>
              <input type="number" min="0" matInput placeholder="Tipo Cambio" name="tipo_cambio" [(ngModel)]="tipoCambio" required readonly>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <button mat-flat-button color="primary" (click)="siguiente(stepper)" [disabled]="disabledForm">{{buttonsName.BTN_ACCEPT}}</button>
          </div>
        </div>
      </mat-step>

      <mat-step label="Listar colaboradores">
        <div class="row">
          <div class="col s12">
            <div class="mat-elevation-z0">

              <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="accion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acción </th>
                  <td mat-cell *matCellDef="let row" data-label="Acción">
                    <div class="pav-btns-container">

                      <div class="pav-btn-circle-more" (click)="verDetallelLiquidacion(row.cli_codcli)">
                        <mat-icon svgIcon="more"></mat-icon>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="cli_codcli">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                  <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
                </ng-container>

                <ng-container matColumnDef="cli_nomcli">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
                  <td mat-cell *matCellDef="let row" data-label="Colaborador"> {{row.cli_nomcli}} </td>
                </ng-container>

                <ng-container matColumnDef="tmo_codtmo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
                  <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.tmo_codtmo}} </td>
                </ng-container>

                <ng-container matColumnDef="nct_tipcam">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe a depositar</th>
                  <td mat-cell *matCellDef="let row" data-label="Importe a depositar" class="pav-td-right"> {{row.nct_impdep}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="5">No se encontraron registros</td>

                </tr>
              </table>

              <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-step>

    </mat-horizontal-stepper>

  </mat-card>

</div>
