<app-pavso-title-section [title]="'Motivos de Parada de Maquina'" [module]="'Producción'" [estado]="motivoParadaMaquina.mpm_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario()" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l1">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input [(ngModel)]="motivoParadaMaquina.mpm_codmpm" type="text" matInput placeholder="Código" name="lpo_seclpo" [disabled]="true">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l8">
            <mat-form-field>
                <mat-label>Descripción</mat-label>
                <input [(ngModel)]="motivoParadaMaquina.mpm_desmpm" type="text" matInput placeholder="Ej. CORTE DE LUZ" name="mpm_desmpm" [disabled]="loaderData">
            </mat-form-field>
        </div>

      </div>
<!-- {{lineaOperacion | json}} -->
    </mat-card>
    <br>
    <pav-form-actions
        [disabled]="loaderData"
        [btnName]="btnName"
        [isLoading]="loaderReg"
        urlBack="/modulo-produccion/motivos-parada-maquina"
  ></pav-form-actions>

  </form>
</div>
