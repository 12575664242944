<div class="pav-container-button" [matMenuTriggerFor]="menuNotification">
  <mat-icon svgIcon="notification"></mat-icon>
</div>

<mat-menu #menuNotification="matMenu" style="width: 375px !important;">
  <div class="pav-notification-container">
    <h1>{{'general.menu.notifications.title' | translate}}</h1>

    <div class="pav-notification-row" *ngFor="let notificacion of notificaciones">
      <div class="pav-container-icon">
        <div class="pav-figure-container">
          <mat-icon svgIcon="email"></mat-icon>
        </div>
      </div>
      <div class="pav-containter-text">
        <div class="pav-not-title">Nueva solicitud registrada desde un di...</div>
        <div class="pav-not-subtitle">Hace 20 min.</div>
      </div>
    </div>
    <div class="pav-notification-action" routerLink="/notificaciones">
      {{'general.menu.notifications.show-notification' | translate}}
    </div>
  </div>
</mat-menu>
