export class LiquidacionCTS {
  cia_codcia: any;
  ano_codano: any;
  mes_codmes: any;
  cli_codcli: string;
  nct_fecemi: any;
  nct_iniliq: any;
  nct_terliq: any;
  nct_tipcam: any;
  tmo_codtmo: any;
  nct_fecdep: any;
  nct_fecini: any;
  nct_fecter: any;
  nct_nummes: any;
  nct_numdia: any;
  nct_asifam: any;
  nct_combas: any;
  nct_provar: any;
  nct_comgra: any;
  nct_comtot: any;
  nct_remano: any;
  nct_remmes: any;
  nct_remdia: any;
  nct_canano: any;
  nct_canmes: any;
  nct_candia: any;
  nct_impano: any;
  nct_impmes: any;
  nct_impdia: any;
  nct_impnet: any;
  nct_fecupd: any;
  nct_indsta: string;
  nct_coduse: string;
  nct_impdep: any;
  nct_impint: any;
  nct_otrrem: any;
  nct_impmex: any;
  nct_alimen: any;
  nct_bonreg: any;
  cli_nomcli: any;

  constructor(){
    this.nct_tipcam = 0;
    this.nct_nummes = 0;
    this.nct_numdia = 0;
    this.nct_combas = 0;
    this.nct_asifam = 0;
    this.nct_provar = 0;
    this.nct_otrrem = 0;
    this.nct_comgra = 0;
    this.nct_comtot = 0;
    this.nct_remano = 0;
    this.nct_remmes = 0;
    this.nct_remdia = 0;
    this.nct_canano = 0;
    this.nct_impano = 0;
    this.nct_canmes = 0;
    this.nct_impmes = 0;
    this.nct_impint = 0;
    this.nct_candia = 0;
    this.nct_impdia = 0;
    this.nct_impdep = 0;
    this.nct_impnet = 0;
    this.nct_impmex = 0;
  }
}
