export class ConfiguracionAdelanto {
  codcia: string;
  codano: string;
  codmes: string;
  porna1: number;
  porna2: number;
  porna3: number;
  coduse: string;
  fecupd: Date;
  indsta: string;

  constructor() {
    this.fecupd = new Date();
    this.indsta = '1';
  }
}
