export const generarParams = (paramsArray: Array<IParam>): string => {

  let params = '';

  for (let index = 0; index < paramsArray.length; index++) {
    const item = paramsArray[index];

    if(index == 0) {
      params += `?${item.param}=${item.value}`;
      continue;
    }

    params += `&${item.param}=${item.value}`;

  }

  return params;

}

export interface IParam {
  param: string;
  value: any;
}
