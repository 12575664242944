<app-pavso-title-section [title]="'Líneas Producción Operario'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">

      <table-title-action title="Todas las líneas de producción" url="/modulo-produccion/linea-de-produccion-operario" entity="" [params]="['0','0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <a matTooltip="Editar Linea de Operario"
                      class="pav-btn-circle-edit"
                      [routerLink]="['/modulo-produccion/linea-de-produccion-operario', row.lpd_codlpd,row.lpo_seclpo]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="lpd_codlpd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.lpd_codlpd}} </td>
            </ng-container>

            <ng-container matColumnDef="cli_nomcli">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Operario </th>
                <td mat-cell *matCellDef="let row" data-label="Chofer"> {{row.cli_nomcli}} </td>
            </ng-container>
            <ng-container matColumnDef="lpd_descri">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Linea </th>
              <td mat-cell *matCellDef="let row" data-label="Chofer"> {{row.lpd_descri}} </td>
          </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
