import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, ContabilidadService, GeneralService } from 'src/app/services';
import { VigenciaImpuestoComponent } from '@shared/components/dialogs/vigencia-impuesto/vigencia-impuesto.component';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { TipoImpuesto } from 'src/app/models/contabilidad/tipo-impueto';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-tipo-impuesto-form',
  templateUrl: './tipo-impuesto-form.component.html',
  styleUrls: ['./tipo-impuesto-form.component.css']
})
export class TipoImpuestoFormComponent implements OnInit {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  tiposDocumento: any[] = [];

  tipoImpuesto: TipoImpuesto;

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _dialogService: DialogService,
    public dialog: MatDialog,
    private _activatedRoute: ActivatedRoute
  ) {
    this.tipoImpuesto = new TipoImpuesto();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=> {
      this.uid = id;
      if(this.uid == '0') return;
    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarTipo(f): this.actualizarTipo(f);
  }

  registrarTipo(f: NgForm): void {
    this._contabilidadService.registrarTiposImpuesto(this.tipoImpuesto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de impuesto registrado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarTipo(f: NgForm): void {}

  vigenciaImpuesto(): void {
    const dialogRef = this.dialog.open(VigenciaImpuestoComponent, {
      width: '650px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/tipos-de-impuesto']);
  }

}
