import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import axios from "axios";

@Injectable({ providedIn: 'root' })
export class NubefactService {

  private url: string = "https://gre-test.nubefact.com/v1/";
  private client_id: string = "test-85e5b0ae-255c-4891-a595-0b98c65c9854";
  private client_secret: string = "test-Hty/M6QshYvPgItX2P0+Kw==";
  private username: string = "MODDATOS";
  private password: string = "MODDATOS";
  private scope: string = "https://api-cpe.sunat.gob.pe"

  constructor(
    private http: HttpClient
  ) { }

  async obtenerNubefactToken(ruc: string): Promise<any> {

    const data = new URLSearchParams();
    data.append('client_id', this.client_id);
    data.append('client_secret', this.client_secret);
    data.append('grant_type', "password");
    data.append('username', ruc + this.username);
    data.append('password', this.password);
    data.append('scope', this.scope);

    const response = await axios.post<any>(`${this.url}clientessol/${this.client_id}/oauth2/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })

    return response;

  }

  /**
   *
   * @param nomDocumento Tiene la siguiente estructura 20605493603-09-T002-00000021
   * @param payload
   * @returns
   */
  enviarComprobanteSUNAT(nomDocumento, payload: { archivo: ArchivoComprobantePayload}): Observable<any> {
    return this.http.post<any>(`${this.url}contribuyente/gem/comprobantes/${nomDocumento}`, payload)
  }

}

export interface ArchivoComprobantePayload {

  nomArchivo: string;
  arcGreZip: string;
  hashZip: string;

}
