import { M, N } from "@angular/cdk/keycodes";
import { bottomBorder, bottomLeftBorder, bottomRightBorder, horizontalBorder, leftBorder, noBorders, openBottomBorder, openLeftBorder, openRightBorder, openTopBorder, rightBorder, verticalBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";

export async function generarPDFXMLSIRE(headers, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [10, 10, 20, 20], color: 'gray', bold: true },
    ]
  });

  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [5, 2, 10, 10], alignment: 'right' },
    ];
  })

  let detalle = [];

  data.detalle.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.cantidad).fontSize(8).end
      ).border(leftBorder).end,
      new Cell(
        new Txt(element.unidadMedida).fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(decodeURIComponent(escape(element.descripcion)).replace(/�/g, "Ñ")).fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.valorUnitario).alignment('right').fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.icbper).alignment('right').fontSize(8).end
      ).border(rightBorder).end,
    ],)
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(data.razonSocial).bold().fontSize(9).end,
            new Txt(data.domicilioFiscal).fontSize(9).end,
            new Txt(`${data.distrito} - ${decodeURIComponent(escape(data.ciudad)).replace(/�/g, "Ñ")} - ${data.departamento}`).fontSize(9).end,
          ]).end
        ).margin([0, 10]).border(openRightBorder).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('FACTURA ELECTRONICA').alignment('center').bold().fontSize(10).end,
                  new Txt(`RUC: ${data.numruc}`).alignment('center').bold().fontSize(10).end,
                  new Txt(data.numdoc).alignment('center').bold().fontSize(10).end,
                ]).end
              ).end
            ]
          ]).widths([170]).end
        ).margin([0, 10]).border(openLeftBorder).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('Fecha de Emisión').fontSize(8).end
        ).border(leftBorder).end,
        new Cell(
          new Txt(`: ${data.fechaEmision}`).fontSize(8).bold().end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Forma de pago: ').fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(data.formaPago).fontSize(8).bold().end
        ).border(rightBorder).end,
      ], [
        new Cell(
          new Txt('Señor(es)').fontSize(8).end
        ).border(leftBorder).end,
        new Cell(
          new Txt(`: ${data.nombreCliente}`).fontSize(8).bold().end
        ).border(rightBorder).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt('RUC').fontSize(8).end
        ).border(leftBorder).end,
        new Cell(
          new Txt(`: ${data.rucCliente}`).fontSize(8).bold().end
        ).border(rightBorder).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt('Dirección del cliente').fontSize(8).end
        ).border(leftBorder).end,
        new Cell(
          new Txt(`: ${data.direccionCliente}`).fontSize(8).bold().end
        ).border(rightBorder).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt('Tipo de moneda').fontSize(8).end
        ).border(leftBorder).end,
        new Cell(
          new Txt(`: ${data.moneda}`).fontSize(8).bold().end
        ).border(rightBorder).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt('Observación').fontSize(8).end
        ).border(leftBorder).end,
        new Cell(
          new Txt(': ').fontSize(8).bold().end
        ).border(rightBorder).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('Cantidad').fontSize(8).bold().end
              ).border(openRightBorder).end,
              new Cell(
                new Txt('Unidad Medida').fontSize(8).bold().end
              ).border(horizontalBorder).end,
              new Cell(
                new Txt('Descripción').fontSize(8).bold().end
              ).border(horizontalBorder).end,
              new Cell(
                new Txt('Valor Unitario').alignment('right').fontSize(8).bold().end
              ).border(horizontalBorder).end,
              new Cell(
                new Txt('ICBPER').alignment('right').fontSize(8).bold().end
              ).border(openLeftBorder).end,
            ],
            ...detalle,

          ]).widths([75, 75, 210, 75, 75]).end
        ).border(verticalBorder).colSpan(6).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Stack([
            new Table([
              [
                new Cell(
                  new Txt('Valor de venta de operaciones gratuitas:').fontSize(10).end
                ).border(noBorders).end,
                new Cell(
                  new Txt('S/ 0.00').fontSize(10).end
                ).end,
              ],

            ]).end,
            new Txt(data.importeLetras).bold().fontSize(10).end
          ]).end
        ).alignment('center').colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('Sub Total Ventas :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt(`S/ ${data.subtotal}`).fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Anticipos :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt(`S/ ${data.anticipo}`).fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Descuentos :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt('S/ 0.00').fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Valor venta :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt(`S/ ${data.valorVenta}`).fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('ISC :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt('S/ 0.00').fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('IGV :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt(`S/ ${data.igv}`).fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('ICBPER :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt('S/ 0.00').fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Otros Cargos :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt('S/ 0.00').fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Otros Tributos :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt('S/ 0.00').fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Monto de redondeo :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt('S/ 0.00').fontSize(9).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Importe total :').fontSize(9).end
              ).border(noBorders).end,
              new Cell(
                new Txt(`S/ ${data.total}`).fontSize(9).end
              ).end,
            ]
          ]).widths([160, 95]).end
        ).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('Esta es una representación impresa de la factura electrónica, generada en el Sistema de SUNAT. Puede verificarla utilizando su clave SOL.').alignment('center').fontSize(12).end
              ).end
            ]
          ]).end
        ).border(openTopBorder).colSpan(6).end,
        ...obtenerNulls(5),
      ]
    ]).widths([85, 85, 85, 85, 85, 85]).end
  )

  pdf.create().open();


}
