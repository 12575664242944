export const codigoModulo = Object.freeze({
  compras_modules_key: "W11",
  contabilidad_modules_key: "W41",
  planilla_modules_key: "W51",
  activo_fijo_modules_key: "W45",
  cuenta_cobrar_modules_key: "W43",
  cuenta_pagar_modules_key: "W42",
  punto_venta_modules_key: "W35",
  ventas_modules_key: "W32",
  crm_modules_key: "W31",
  produccion_modules_key: "W22",
  almacen_modules_key: "W13",
  importaciones_modules_key: "W12",
  costos_modules_key: "W47",
  libros_electronicos_modules_key: "W48",
  utilitarios_modules_key: "W91",
})
