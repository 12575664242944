<app-pavso-title-section [title]="'Promociones de venta'" [module]="'Ventas'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todos los bancos" url="/modulo-ventas/promocion-de-venta" entity="" [params]="['0']"></table-title-action>

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <div matTooltip="Editar canal de venta"
              class="pav-btn-circle-edit"
              [routerLink]="['/modulo-ventas/promocion-de-venta', row.BAN_CODBAN]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="BAN_CODBAN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let row" data-label="Código"> {{row.BAN_CODBAN}} </td>
      </ng-container>

      <ng-container matColumnDef="BAN_NOMBRE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.BAN_NOMBRE}} </td>
      </ng-container>

      <ng-container matColumnDef="BAN_INDSTA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.BAN_INDSTA == '1' ? 'VIGENTE': 'ANULADO'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>
      </tr>


    </table>

    <mat-paginator color="primary" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
