import { Component, OnInit, ViewChild } from "@angular/core";
import { ApexChart, ApexNonAxisChartSeries, ApexResponsive, ChartComponent } from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

@Component({
  selector: 'crm-graph-four',
  templateUrl: './crm-graph-four.component.html',
  styleUrls: ['./crm-graph-four.component.scss']
})
export class CrmGraphFourComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  displayedColumns: string[] = ['brand', 'amount'];
  dataSource = [
    {
      brand: 'HAFFMANS',
      amount: 44
    },
    {
      brand: 'UNION',
      amount: 76
    },
    {
      brand: 'WITTEMANN',
      amount: 35
    },
    {
      brand: 'MONTO TOTAL',
      amount: 155
    },
  ];

  ngOnInit(): void {
    this.chartOptions = {
      series: [44, 55, 13],
      chart: {
        width: 380,
        type: "pie"
      },
      labels: ["HAFFMANS", "UNION", "WITTEMANN"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }
}
