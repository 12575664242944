import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'copiar-presupuesto-dialog',
  templateUrl: './copiar-presupuesto.dialog.html',
  styleUrls: ['./copiar-presupuesto.dialog.scss']
})
export class CopiarPresupuestoDialog implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<CopiarPresupuestoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}
