import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IParam, generarParams } from '../../utilities/params';

@Injectable({
  providedIn: 'root',
})
export class ClienteService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  registrarCliente(cliente): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/crear`,
      cliente
    )
  }

  editarCliente(cliente): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/modificar`,
      cliente
    )
  }

  obtenerCliente(codcli): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codcli}`
    )
  }

  obtenerClientes({ params = [] }: { params?: Array<IParam> } = {}): Observable<any>{

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    ).pipe(
      map((clientes: any) => {
        return clientes.map(cliente => {
          cliente['description'] = `${cliente.cli_codcli} | ${cliente.cli_nomcli}`
          cliente['codigo'] = `${cliente.cli_codcli}`
          cliente['descripcion'] = `${cliente.cli_nomcli}`
          cliente['description_reverse'] = `${cliente.cli_nomcli} | ${cliente.cli_codcli}`
          return cliente;
        })
      })
    )
  }

  obtenerClientesConPaginacion(page: number = 1, count: number = 5, orden: string = 'ASC', columnaorden: string = 'cli_codcli', buscar: string = ''): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/listarpagina/${this._configurationService.obtenerCompaniaCliente()}/${page}/${count}?buscar=${buscar}&orden=${orden}&columnaorden=${columnaorden}`
    )
  }

  listarTipoServicioCliente(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/listar/${this._configurationService.obtenerCompaniaCliente()}?tiposervicio=01`
    )
  }

}
