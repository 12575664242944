import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'condicion-pago-dialog',
  template: `
    <div mat-dialog-content>
      <condicion-pago-form></condicion-pago-form>
    </div>
  `,
  styleUrls: ['./condicion-pago-dialog.component.scss']
})
export class CondicionPagoDialog {
  constructor(
    public dialogRef: MatDialogRef<CondicionPagoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('data id', data.id)
  }
}
