<app-pavso-title-section [title]="'Configuración adelanto'" [module]="'Planilla'" [estado]="configuracion.indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Año</mat-label>
            <input class="pav-input-align-right" type="text" matInput [(ngModel)]="configuracion.codano" placeholder="Año" name="codano" required readonly>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Mes</mat-label>
            <input class="pav-input-align-right" type="text" matInput [(ngModel)]="configuracion.codmes" placeholder="Mes" name="codmes" required readonly>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Porcentaje</mat-label>
            <input class="pav-input-align-right" min="0" max="100" type="number" matInput [(ngModel)]="configuracion.porna1" placeholder="Porcentaje" name="porna1" required>
          </mat-form-field>
        </div>
      </div>
    </mat-card>

    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
