<app-pavso-title-section [title]="'Reportes CTS'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l6">
          <fieldset>
            <legend>Reportes</legend>
            <button type="button" mat-button color="primary" (click)="abrirCtsPagar()">
              CTS A PAGAR
            </button>
            &nbsp;
            <button type="button" mat-button color="primary" (click)="abrirCtsAcumulada()">
                CTS ACUMULADA
            </button>
          </fieldset>
        </div>

      </div>
    </mat-card>
</div>
