import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MaterialModule } from "src/app/material.module";
import { CobranzasComponent } from "./cobranzas.component";
import { consultasComponentCobranzas } from "./pages/consultas";
import { informesComponentCobranzas } from "./pages/informes";
import { maestrosComponentCobranzas } from "./pages/maestros";
import { operacionesComponentCobranzas } from "./pages/operaciones";
import { PanelCobranzasComponent } from "./pages/panel/panel-cobranzas.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";

@NgModule({
  declarations: [
    CobranzasComponent,
    PanelCobranzasComponent,
    ...consultasComponentCobranzas,
    ...informesComponentCobranzas,
    ...maestrosComponentCobranzas,
    ...operacionesComponentCobranzas
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class CobranzasModule {}
