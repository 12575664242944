import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Area } from 'src/app/models/produccion/area';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({
    providedIn: 'root',
  })
  export class AreaService {
    constructor(
      private http: HttpClient,
      private _configurationService: ConfigurationService
    ) {}
  /**
   *
   * @returns
   */
  obtenerAreas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionareas/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerArea(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionareas/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param area
   * @returns
   */
  registrarArea(area: Area): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionareas/crear`,
      area
    );
  }

  /**
   *
   * @param area
   * @returns
   */
  actualizarArea(area: Area): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionareas/modificar`,
      area
    );
  }
}
