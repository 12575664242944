import { NgModule } from "@angular/core";
import { ImportacionesComponent } from './importaciones.component';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { informesComponentsImportaciones } from "./pages/informes";
import { operacionesComponentImportaciones } from "./pages/operaciones";
import { SharedModule } from "@shared/shared.module";
import { MaterialModule } from "src/app/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PanelImportacionesComponent } from "./pages/panel/panel-importaciones.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { maestrosComprasModules } from "../compras/pages/maestros";

@NgModule({
  declarations: [
    ImportacionesComponent,
    PanelImportacionesComponent,
    ...informesComponentsImportaciones,
    ...operacionesComponentImportaciones
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    ...maestrosComprasModules,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class ImportacionesModule {}

