import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { DocumentoRecepcion } from "src/app/models/compras/recepcion";
import { TipoLegajo } from "src/app/models/planilla/maestros/tipoLegajo";
import { ConfigurationService } from "src/app/services";
import { ReceptionCompraService } from "src/app/services/api/compras/operaciones/reception.service";
import { TipoLegajoServie } from "src/app/services/api/nomina/maestros/tipo-legajo.service";

@Component({
  selector: 'pav-upload-file-modal',
  templateUrl: 'pav-upload-file.modal.html',
  styleUrls: ['pav-upload-file.modal.scss']
})
export class PavUploadFileModal implements OnDestroy, OnInit {

  displayedColumnsDocumentos: string[] = ['item', 'ccd_codtdo', 'ccd_glosa', 'adjuntar', 'visualizar', 'eliminar', 'ccd_uploaded'];
  dataSourceDocumentos: MatTableDataSource<DocumentoRecepcion>;
  @ViewChild('paginatorDocumentos') paginatorDocumentos: MatPaginator;
  @ViewChild(MatSort) sortDocumentos: MatSort;

  loaderData: boolean;
  loaderUpload: boolean;

  documentos: Array<DocumentoRecepcion> = [];
  tiposLegajo: Array<TipoLegajo> = []

  documentosRecepcion$: Subscription;
  adjuntarDocumento$: Subscription;
  quitarDocumento$: Subscription;
  tiposLegajo$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PavUploadFileModal>,
    @Inject(MAT_DIALOG_DATA) public idRecepcion: string,
    private _configurationService: ConfigurationService,
    private _receptionService: ReceptionCompraService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _tipoLegajoService: TipoLegajoServie
  ) {
    console.log('id', idRecepcion)

  }

  ngOnInit(): void {
    this.loadMaestros();
  }

  loadMaestros(): void {
    this.tiposLegajo$ = this._tipoLegajoService.obtenerTiposLegajo().subscribe(
      tiposLegajo => {
        this.tiposLegajo = tiposLegajo;
        if (this.idRecepcion) {
          this.obtenerDocumentosRecepcion();
        }
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de legajo', 'Ok');
      }
    )
  }


  obtenerDocumentosRecepcion(): void {
    this.loaderData = true;
    this.documentosRecepcion$ = this._receptionService.obtenerDocumentosRecepcion(this.idRecepcion).subscribe(
      documentos => {
        documentos.forEach(documento => {
          documento.ccd_uploaded = true;
          if (!documento.ccd_tiparc) {
            documento.ccd_tiparc = documento.ccd_fotccd.split('data:')[1].split(';base64,')[0];
          }
        });
        this.documentos = documentos;
        console.log('tipo')
        this.dataSourceDocumentos = fillTable(documentos, this.paginatorDocumentos, this.sortDocumentos);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener documentos en recepción', 'Ok');
        this.loaderData = false;
      }
    )
  }

  quitarDocumento(id): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {

        this.quitarDocumento$ = this._receptionService.eliminarDocumentoRecepcion(id).subscribe(
          documento => {
            const documentosFiltrados = this.documentos.filter(documento => documento.ccd_idccd != id)
            this.documentos = documentosFiltrados;
            this.dataSourceDocumentos = fillTable(documentosFiltrados, this.paginatorDocumentos, this.sortDocumentos);
            console.log('documento', documento)
          },
          error => {
            this._snackBarService.showError('Error al eliminar documento en recepción', 'Ok');
          }
        )
      }
    })
  }

  agregarDocumento(): void {

    const documento = new DocumentoRecepcion()
    documento.ccr_codccr = this.idRecepcion;
    documento.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    console.log('this.recepcionCompra.documentos', this.documentos)
    this.documentos.push(documento);
    this.dataSourceDocumentos = fillTable(this.documentos, this.paginatorDocumentos, this.sortDocumentos);

  }

  adjuntarDocumentos(): void {
    this.loaderUpload = true;
    console.log('todos documentos', this.documentos)
    const documentosACargar = this.documentos.filter(documento => !documento.ccd_uploaded);
    console.log('documentos a cargar', documentosACargar)

    if (documentosACargar.length == 0) {
      this._snackBarService.showError('No hay documentos para adjuntar', 'Ok');
      this.loaderUpload = false;
      return;
    }

    documentosACargar.forEach(documento => {
      if (!documento.ccd_fotccd) {
        this._snackBarService.showError('Debe de cargar un en todos los ítems', 'Ok');
        this.loaderUpload = false;
        return;
      }
    });

    this.adjuntarDocumento$ = this._receptionService.adjuntarDocumentos(documentosACargar).subscribe(
      response => {
        this.dataSourceDocumentos = fillTable([], this.paginatorDocumentos, this.sortDocumentos)
        this.obtenerDocumentosRecepcion();
        this._snackBarService.showSuccess('Documentos adjuntados correctamente', 'Ok');
        this.loaderUpload = false;

      },
      error => {
        this._snackBarService.showError('Error al adjuntar documentos', 'Ok');
        this.loaderUpload = false;
      }
    )
  }

  handleOnUploadFile(row: DocumentoRecepcion, event): void {
    console.log('event', event)
    row.ccd_fotccd = event.base64;
    row.ccd_tiparc = event.typeFile;
  }

  openFile(fileBase64, fileType): void {
    if (!fileBase64) {
      return;
    }
    console.log('fileBase64', fileBase64)
    console.log('fileBase64', fileType)
    if (fileType.includes('image/') || fileType == 'application/pdf') {
      const byteCharacters = atob((fileBase64.split('base64,')[1]));

      try {
        // Convert the Base64 data to a Blob
        const byteCharacters = atob((fileBase64.split('base64,')[1]))
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Open the URL in a new tab
        const newWindow = window.open();

        if (fileType.includes('image/')) {
          newWindow.document.write('<html><head><title>Image</title></head><body><img src="' + blobUrl + '" /></body></html>');
          return;
        }

        if (fileType == 'application/pdf') {
          newWindow.document.write('<html><head><title>PDF</title></head><body><embed src="' + blobUrl + '" type="application/pdf" width="100%" height="100%"/></body></html>');

        }

      } catch (error) {
        console.error('Error opening file:', error);
      }

    }

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.documentosRecepcion$,
      this.adjuntarDocumento$,
      this.quitarDocumento$,
      this.tiposLegajo$
    ])
  }

}
