<div class="pav-state-title">{{'general.dialog.opening-period.title' | translate}}</div>
<form #f="ngForm" ngNativeValidate (ngSubmit)="abrircerrar(f)" (keydown.enter)="$event.preventDefault()">
  <div mat-dialog-content>
    <p class="pav-rb-title">{{'general.dialog.opening-period.form.choose-action' | translate}}</p>
    <mat-radio-group color="primary" [(ngModel)]="estado" name="estado" aria-label="Select an option">
      <div class="pav-group-rb">
        <div class="pav-rb-state">
          <mat-radio-button value="A">{{'general.dialog.opening-period.form.rb-open' | translate}}</mat-radio-button>
        </div>
        <div class="separator"></div>
        <div class="pav-rb-state">
          <mat-radio-button value="C">{{'general.dialog.opening-period.form.rb-close' | translate}}</mat-radio-button>
        </div>
      </div>
    </mat-radio-group>

    <div [ngStyle]="{marginTop: '10px'}">
      <strong *ngIf="estado == 'A'">Período a aperturar: {{anioActividad}} - {{mesActividad}}</strong>
    </div>

    <p class="pav-rb-title">{{'general.dialog.opening-period.form.module' | translate}}</p>

    <mat-form-field>
      <mat-label></mat-label>
      <mat-select [(ngModel)]="codmod" name="codmod" required disabled>
        <mat-option *ngFor="let modulo of modulos" [value]="modulo.code">
          {{modulo.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="row">

    </div>
  </div>
  <br>
  <div align="center">
    <button class="pav-btn-cancel" mat-flat-button (click)="cancelar()" type="button">{{'general.button.cancel' |
      translate}}</button>
    <button class="pav-btn-confirm" mat-flat-button color="primary" type="submit">{{'general.button.confirm' |
      translate}}</button>
  </div>
</form>
