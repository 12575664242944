import { REPORT_HEADER_COLOR } from "@data/constants/configuration/configuration.constant";
import { HeaderReport } from "@data/interfaces/headers.report";
import { numberWithCommas } from "@utils/formats/number.format";
import { bottomBorder, horizontalBorder, noBorders, openLeftBorder, openRightBorder, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";
import { formatDateWithSlash } from "../../formats/date.format";
import { PavsoColors } from "@data/enums/color.enum";

/**
 * Reporte cuenta 40
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function cuenta40Report(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("FORMATO 3.10: LIBRO DE INVENTARIOS Y BALANCES DETALLES DEL SALDO CUENTA 40 - TRIBUTOS POR PAGAR").fontSize(12).bold().end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("PERÍODO").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.year}`).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("RUC").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.client}`).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("DENOMINACIÓN").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.clientName}`).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("CUENTA TRIBUTOS POR PAGAR").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("SALDO CONTABLE FINAL").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end
      ],
      [
        new Cell(
          new Txt("CÓDIGO").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DENOMINACIÓN").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV CUENTA PROPIA").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-359,456.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("401110").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("IGV Servicos Prestados por No Domiciliados").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("112.44").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTALES").alignment('right').fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("112.44").fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
      ],
    ]).headerRows(6).widths([100, 470, 200]).end
  )
  pdf.create().open();

}

/**
 * Reporte Balance Comprobacion Estados Financieros
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function balancecomprobacioneeffReport(headers: HeaderReport, response) {

  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let items = [];

  let [totalMD, totalMH, total01, total02, total03, total04, total05, total06, total07, total08, total09, total10] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


  response.forEach(row => {

    let saldoIniDebe = (row.DEBE_INI > row.HABER_INI) ? row.DEBE_INI - row.HABER_INI : 0;
    let saldoIniHabe = (row.HABER_INI > row.DEBE_INI) ? row.HABER_INI - row.DEBE_INI : 0;
    let saldoCierreDeudor = ((saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) > 0) ? saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA : 0;
    let saldoCierreAcreedor = ((saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) < 0) ? Math.abs(saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) : 0;
    let cuentaInventarioActivo = (row.CCN_INDCLB == '1') ? saldoCierreDeudor : 0;
    let cuentaInventarioPasivo = (row.CCN_INDCLB == '1') ? saldoCierreAcreedor : 0;
    let resultadoFuncionPerdida = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '4' || row.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
    let resultadoFuncionGanancia = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '4' || row.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;
    let resultadoNaturalezaPerdida = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '3' || row.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
    let resultadoNaturalezaGanancia = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '3' || row.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;

    total01 += saldoIniDebe;
    total02 += saldoIniHabe;
    totalMD += row.SCU_DEBMNA;
    totalMH += row.SCU_HABMNA;
    total03 += saldoCierreDeudor;
    total04 += saldoCierreAcreedor;
    total05 += cuentaInventarioActivo;
    total06 += cuentaInventarioPasivo;
    total07 += resultadoFuncionPerdida;
    total08 += resultadoFuncionGanancia;
    total09 += resultadoNaturalezaPerdida;
    total10 += resultadoNaturalezaGanancia;

    items.push([
      new Cell(
        new Txt(row.CCN_CODCCN).fontSize(6).end,
      ).border(noBorders).end,
      new Cell(
        new Txt(row.CCN_DESCRI).fontSize(6).end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoIniDebe.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoIniHabe.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(row.SCU_DEBMNA)).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(row.SCU_HABMNA)).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoCierreDeudor.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoCierreAcreedor.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(cuentaInventarioActivo.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(cuentaInventarioPasivo.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoFuncionPerdida.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoFuncionGanancia.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoNaturalezaPerdida.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoNaturalezaGanancia.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
    ])
  });

  let totalActivoParcial = (total05 < total06) ? Math.abs(total05 - total06) : 0;
  let totalPasivoParcial = (total05 > total06) ? Math.abs(total05 - total06) : 0;
  let totalPerdidaParcial = (total07 < total08) ? Math.abs(total07 - total08) : 0;
  let totalGananciaParcial = (total07 > total08) ? Math.abs(total07 - total08) : 0;
  let totalNtPerdidaParcial = (total09 < total10) ? Math.abs(total09 - total10) : 0;
  let totalNtGananciaParcial = (total09 > total10) ? Math.abs(total09 - total10) : 0;

  let totalActivo = (total05 > 0) ? total05 + totalActivoParcial : 0;
  let totalPasivo = (total06 > 0) ? total06 + totalPasivoParcial : 0;
  let totalPerdida = (total07 > 0) ? total07 + totalPerdidaParcial : 0;
  let totalGanancia = (total08 > 0) ? total08 + totalGananciaParcial : 0;
  let totalNatPerdida = (total09 > 0) ? total09 + totalNtPerdidaParcial : 0;
  let totalNatGanancia = (total10 > 0) ? total10 + totalNtGananciaParcial : 0;


  pdf.add(
    new Table([
      [
        new Cell(new Txt(`${headers.clientName}`).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Balance de Comprobación EEFF").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("7").alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`PERIODO ${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(10).end,
          ]).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("").end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("Cuenta").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Descripción").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Saldos Iniciales").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Movimientos del Mes").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Saldos al Cierre").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Cuenta de Inventario").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Resultados por Función").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Resultados por Naturaleza").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        null
      ],
      [
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Deudor").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Acreedor").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Activo").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Pasivo").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Perdidas").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ganancias").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Perdidas").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ganancias").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end
      ],
      ...items,
      [
        new Cell(
          new Txt("").fontSize(6).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTALES").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(total01.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total02.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalMD.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalMH.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total03.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total04.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total05.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total06.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total07.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total08.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total09.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total10.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,

      ],
      [
        new Cell(
          new Txt("").fontSize(7).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("UTILIDAD PÉRDIDA DEL EJERCICIO").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalActivoParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalPasivoParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalPerdidaParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalGananciaParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalNtPerdidaParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalNtGananciaParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,

      ],
      [
        new Cell(
          new Txt("").fontSize(6).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTAL GENERAL").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalActivo.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalPasivo.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalPerdida.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalGanancia.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNatPerdida.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNatGanancia.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,

      ],
    ]).headerRows(5).widths([30, 150, 38, 38, 38, 37, 38, 40, 38, 38, 46, 46, 46, 46]).end
  )

  pdf.create().open();

}

/**
 * Reporte Balance Comprobacion Sunat
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function balancecomprobacioneeffsunatReport(headers: HeaderReport, response) {

  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let items = [];

  let [totalMD, totalMH, total01, total02, total03, total04, total05, total06, total07, total08, total09, total10] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  response.forEach(row => {

    let saldoIniDebe = (row.DEBE_INI > row.HABER_INI) ? row.DEBE_INI - row.HABER_INI : 0;
    let saldoIniHabe = (row.HABER_INI > row.DEBE_INI) ? row.HABER_INI - row.DEBE_INI : 0;
    let saldoCierreDeudor = ((saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) > 0) ? saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA : 0;
    let saldoCierreAcreedor = ((saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) < 0) ? Math.abs(saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) : 0;
    let cuentaInventarioActivo = (row.CCN_INDCLB == '1') ? saldoCierreDeudor : 0;
    let cuentaInventarioPasivo = (row.CCN_INDCLB == '1') ? saldoCierreAcreedor : 0;
    let resultadoFuncionPerdida = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '4' || row.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
    let resultadoFuncionGanancia = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '4' || row.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;
    let resultadoNaturalezaPerdida = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '3' || row.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
    let resultadoNaturalezaGanancia = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '3' || row.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;

    total01 += saldoIniDebe;
    total02 += saldoIniHabe;
    totalMD += row.SCU_DEBMNA;
    totalMH += row.SCU_HABMNA;
    total03 += saldoCierreDeudor;
    total04 += saldoCierreAcreedor;
    total05 += cuentaInventarioActivo;
    total06 += cuentaInventarioPasivo;
    total07 += resultadoFuncionPerdida;
    total08 += resultadoFuncionGanancia;
    total09 += resultadoNaturalezaPerdida;
    total10 += resultadoNaturalezaGanancia;

    items.push([
      new Cell(
        new Txt(row.CCN_CODCCN).fontSize(6).end,
      ).border(noBorders).end,
      new Cell(
        new Txt(row.CCN_DESCRI).fontSize(6).end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoIniDebe.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoIniHabe.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(row.SCU_DEBMNA.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(row.SCU_HABMNA.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoCierreDeudor.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoCierreAcreedor.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(cuentaInventarioActivo.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(cuentaInventarioPasivo.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoFuncionPerdida.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoFuncionGanancia.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoNaturalezaPerdida.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoNaturalezaGanancia.toFixed(2))).fontSize(6).alignment('right').end,
      ).border(noBorders).end,
    ])
  });

  let totalActivoParcial = (total05 < total06) ? Math.abs(total05 - total06) : 0;
  let totalPasivoParcial = (total05 > total06) ? Math.abs(total05 - total06) : 0;
  let totalPerdidaParcial = (total07 < total08) ? Math.abs(total07 - total08) : 0;
  let totalGananciaParcial = (total07 > total08) ? Math.abs(total07 - total08) : 0;
  let totalNtPerdidaParcial = (total09 < total10) ? Math.abs(total09 - total10) : 0;
  let totalNtGananciaParcial = (total09 > total10) ? Math.abs(total09 - total10) : 0;

  let totalActivo = (total05 > 0) ? total05 + totalActivoParcial : 0;
  let totalPasivo = (total06 > 0) ? total06 + totalPasivoParcial : 0;
  let totalPerdida = (total07 > 0) ? total07 + totalPerdidaParcial : 0;
  let totalGanancia = (total08 > 0) ? total08 + totalGananciaParcial : 0;
  let totalNatPerdida = (total09 > 0) ? total09 + totalNtPerdidaParcial : 0;
  let totalNatGanancia = (total10 > 0) ? total10 + totalNtGananciaParcial : 0;

  pdf.add(
    new Table([
      [
        new Cell(new Txt(`${headers.clientName}`).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Balance de Comprobación EEFF SUNAT").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`PERIODO ${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(10).end,
          ]).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("").end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("Cuenta").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Descripción").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Saldos Iniciales").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Movimientos del Mes").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Saldos al Cierre").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Cuenta de Inventario").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Resultados por Función").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Resultados por Naturaleza").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Deudor").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Acreedor").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Activo").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Pasivo").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Perdidas").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ganancias").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Perdidas").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ganancias").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end
      ],
      ...items,
      [
        new Cell(
          new Txt("").fontSize(7).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTALES").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(total01.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total02.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalMD.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalMH.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total03.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total04.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total05.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total06.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total07.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total08.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total09.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total10.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(topBorder).end,

      ],
      [
        new Cell(
          new Txt("").fontSize(7).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("UTILIDAD PÉRDIDA DEL EJERCICIO").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalActivoParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalPasivoParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalPerdidaParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalGananciaParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalNtPerdidaParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalNtGananciaParcial.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(noBorders).end,

      ],
      [
        new Cell(
          new Txt("").fontSize(7).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTAL GENERAL").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalActivo.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalPasivo.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalPerdida.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalGanancia.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNatPerdida.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNatGanancia.toFixed(2))).fontSize(6).alignment('right').end,
        ).border(horizontalBorder).end,

      ],
    ]).headerRows(5).widths([35, 165, 35, 35, 35, 35, 35, 44, 35, 35, 46, 46, 46, 46]).end
  )

  pdf.create().open();
}

/**
 * Reporte Balance Comprobacion Estados Financieros
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function balancecomprobacioneeffAnualReport(headers: HeaderReport, response) {

  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let items = [];

  let [totalMD, totalMH, total01, total02, total03, total04, total05, total06, total07, total08, total09, total10] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


  response.forEach(row => {

    let saldoIniDebe = (row.DEBE_INI > row.HABER_INI) ? row.DEBE_INI - row.HABER_INI : 0;
    let saldoIniHabe = (row.HABER_INI > row.DEBE_INI) ? row.HABER_INI - row.DEBE_INI : 0;
    let saldoCierreDeudor = ((saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) > 0) ? saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA : 0;
    let saldoCierreAcreedor = ((saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) < 0) ? Math.abs(saldoIniDebe - saldoIniHabe + row.SCU_DEBMNA - row.SCU_HABMNA) : 0;
    let cuentaInventarioActivo = (row.CCN_INDCLB == '1') ? saldoCierreDeudor : 0;
    let cuentaInventarioPasivo = (row.CCN_INDCLB == '1') ? saldoCierreAcreedor : 0;
    let resultadoFuncionPerdida = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '4' || row.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
    let resultadoFuncionGanancia = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '4' || row.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;
    let resultadoNaturalezaPerdida = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '3' || row.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
    let resultadoNaturalezaGanancia = (row.CCN_INDCLB == '2' || row.CCN_INDCLB == '3' || row.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;

    total01 += saldoIniDebe;
    total02 += saldoIniHabe;
    totalMD += row.SCU_DEBMNA;
    totalMH += row.SCU_HABMNA;
    total03 += saldoCierreDeudor;
    total04 += saldoCierreAcreedor;
    total05 += cuentaInventarioActivo;
    total06 += cuentaInventarioPasivo;
    total07 += resultadoFuncionPerdida;
    total08 += resultadoFuncionGanancia;
    total09 += resultadoNaturalezaPerdida;
    total10 += resultadoNaturalezaGanancia;

    items.push([
      new Cell(
        new Txt(row.CCN_CODCCN).fontSize(7).end,
      ).border(noBorders).end,
      new Cell(
        new Txt(row.CCN_DESCRI).fontSize(7).end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoIniDebe.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoIniHabe.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(row.SCU_DEBMNA)).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(row.SCU_HABMNA)).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoCierreDeudor.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoCierreAcreedor.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(cuentaInventarioActivo.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(cuentaInventarioPasivo.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoFuncionPerdida.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoFuncionGanancia.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoNaturalezaPerdida.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(resultadoNaturalezaGanancia.toFixed(2))).fontSize(7).alignment('right').end,
      ).border(noBorders).end,
    ])
  });

  let totalActivoParcial = (total05 < total06) ? Math.abs(total05 - total06) : 0;
  let totalPasivoParcial = (total05 > total06) ? Math.abs(total05 - total06) : 0;
  let totalPerdidaParcial = (total07 < total08) ? Math.abs(total07 - total08) : 0;
  let totalGananciaParcial = (total07 > total08) ? Math.abs(total07 - total08) : 0;
  let totalNtPerdidaParcial = (total09 < total10) ? Math.abs(total09 - total10) : 0;
  let totalNtGananciaParcial = (total09 > total10) ? Math.abs(total09 - total10) : 0;

  let totalActivo = (total05 > 0) ? total05 + totalActivoParcial : 0;
  let totalPasivo = (total06 > 0) ? total06 + totalPasivoParcial : 0;
  let totalPerdida = (total07 > 0) ? total07 + totalPerdidaParcial : 0;
  let totalGanancia = (total08 > 0) ? total08 + totalGananciaParcial : 0;
  let totalNatPerdida = (total09 > 0) ? total09 + totalNtPerdidaParcial : 0;
  let totalNatGanancia = (total10 > 0) ? total10 + totalNtGananciaParcial : 0;

  pdf.add(
    new Table([
      [
        new Cell(new Txt(`${headers.clientName}`).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Balance de Comprobación EEFF Anual").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("7").alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`PERIODO ${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(10).end,
          ]).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("").end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("Cuenta").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Descripción").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Saldos Iniciales").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Movimientos del Año").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Saldos al Cierre").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Cuenta de Inventario").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Resultados por Función").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Resultados por Naturaleza").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Deudor").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Acreedor").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Activo").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Pasivo").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Perdidas").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ganancias").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Pérdidas").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ganancias").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end
      ],
      ...items,
      [
        new Cell(
          new Txt("").fontSize(7).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTALES").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(total01.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total02.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalMD.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalMH.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total03.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total04.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total05.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total06.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total07.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total08.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total09.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total10.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(topBorder).end,

      ],
      [
        new Cell(
          new Txt("").fontSize(7).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("UTILIDAD PÉRDIDA DEL EJERCICIO").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalActivoParcial.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalPasivoParcial.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalPerdidaParcial.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalGananciaParcial.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalNtPerdidaParcial.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalNtGananciaParcial.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(noBorders).end,

      ],
      [
        new Cell(
          new Txt("").fontSize(7).margin([5, 0, 0, 0]).end,
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTAL GENERAL").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalActivo.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalPasivo.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalPerdida.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalGanancia.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNatPerdida.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNatGanancia.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(horizontalBorder).end,

      ],
    ]).headerRows(5).widths([50, 150, 35, 35, 35, 35, 35, 44, 35, 35, 46, 46, 46, 46]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Balance General
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function balanceGeneral(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // variables
  let now = new Date();

  // detalle
  let detalle = [];

  let [totalDeudorGeneral, totalAcreedorGeneral] = [0, 0];

  response.forEach(element => {
    detalle.push([
      new Cell(
        new Stack([
          new Txt(element.AEF_DESCRI_1).alignment('center').bold().end,
        ]).alignment('center').end
      ).colSpan(6).border(noBorders).end,
      ...obtenerNulls(5),
    ],);

    let [totalDeudor, totalAcreedor] = [0, 0];

    element.detalle.forEach(item => {
      let [totalDebe2, totalHaber2] = [0, 0];

      item.detalle.forEach(it => {
        let [totalDebeIni, totalHaberIni] = [0, 0];
        it.detalle.forEach(element => {
          let [debeIni, haberIni] = [0, 0];

          debeIni = (element.DEBE_INI - element.HABER_INI) > 0 ? element.DEBE_INI - element.HABER_INI : 0;
          haberIni = (element.DEBE_INI - element.HABER_INI) < 0 ? element.HABER_INI - element.DEBE_INI : 0;

          totalDebeIni += debeIni;
          totalHaberIni += haberIni;
        });
        let [tempTotalDebe, tempTotalHaber] = [totalDebeIni, totalHaberIni];

        let totalD2 = (tempTotalDebe - tempTotalHaber) > 0 ? tempTotalDebe - tempTotalHaber : 0;
        let totalH2 = (tempTotalDebe - tempTotalHaber) < 0 ? tempTotalHaber - tempTotalDebe : 0;

        totalDebe2 += totalD2;
        totalHaber2 += totalH2;

      });

      let total2 = totalDebe2 - totalHaber2;
      detalle.push([
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.AEF_DESCRI_2).bold().fontSize(10).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(numberWithCommas(total2.toFixed(2))).alignment('right').fontSize(10).bold().end
        ).border(bottomBorder).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
      ],)

      item.detalle.forEach(it => {
        let [totalDebeIni, totalHaberIni] = [0, 0];

        it.detalle.forEach(element => {
          let [debeIni, haberIni] = [0, 0];

          debeIni = (element.DEBE_INI - element.HABER_INI) > 0 ? element.DEBE_INI - element.HABER_INI : 0;
          haberIni = (element.DEBE_INI - element.HABER_INI) < 0 ? element.HABER_INI - element.DEBE_INI : 0;

          totalDebeIni += debeIni;
          totalHaberIni += haberIni;
        });

        let total1 = totalDebeIni - totalHaberIni;

        detalle.push([
          new Cell(
            new Txt("").end
          ).border(noBorders).end,
          new Cell(
            new Txt(it.AEF_DESCRI).fontSize(10).end
          ).border(noBorders).colSpan(3).end,
          ...obtenerNulls(2),
          new Cell(
            new Txt(numberWithCommas(total1.toFixed(2))).alignment('right').fontSize(10).end
          ).border(noBorders).end,
          new Cell(
            new Txt("").end
          ).border(noBorders).end,
        ],)

      });

      detalle.push([
        new Cell(
          new Txt("\n").end,
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
      ],)

      if (element.CED_CORCED == "01") {
        totalDeudor += total2;
      } else {
        totalAcreedor += total2;
      }
    });


    if (element.CED_CORCED == "01") {
      totalDeudorGeneral = totalDeudor;
      detalle.push([
        new Cell(
          new Txt("").end,
        ).border(noBorders).end,
        new Cell(
          new Txt(`TOTAL ${element.AEF_DESCRI_1}`).bold().fontSize(10).end,
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(numberWithCommas(totalDeudor.toFixed(2))).fontSize(10).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").end,
        ).border(noBorders).end,
      ],)
      detalle.push([
        new Cell(
          new Txt("\n").end,
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
      ],)
    } else {
      totalAcreedorGeneral = totalAcreedor;

      detalle.push([
        new Cell(
          new Txt("").end,
        ).border(noBorders).end,
        new Cell(
          new Txt(`RESULTADO DEL EJERCICIO`).fontSize(10).end,
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(numberWithCommas((totalDeudorGeneral + totalAcreedorGeneral).toFixed(2))).fontSize(10).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").end,
        ).border(noBorders).end,
      ],)
      detalle.push([
        new Cell(
          new Txt("\n").end,
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
      ],)
      detalle.push([
        new Cell(
          new Txt("").end,
        ).border(noBorders).end,
        new Cell(
          new Txt(`TOTAL ${element.AEF_DESCRI_1}`).bold().fontSize(10).end,
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(numberWithCommas((totalDeudorGeneral).toFixed(2))).fontSize(10).alignment('right').end,
        ).border(noBorders).end,
        new Cell(
          new Txt("").end,
        ).border(noBorders).end,
      ],)
      detalle.push([
        new Cell(
          new Txt("\n").end,
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
      ],)
    }

  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(8).end,
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(formatDateWithSlash(now)).fontSize(8).alignment('right').end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt(headers.client).fontSize(8).bold().end,
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("").fontSize(8).bold().end,
        ).colSpan(2).alignment('right').border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt("Balance General").alignment('center').bold().end,
            new Txt(`${headers.year} - ${headers.month}`).alignment('center').fontSize(10).bold().end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(9).bold().end,
            new Txt("\n").end,
          ]).alignment('center').end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
      ],
      ...detalle,
    ]).widths([50, 80, 125, 125, 80, 50]).end
  )

  pdf.create().open();
}

/**
 * Balance General Analítico
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function balanceGeneralAnalitico(headers: HeaderReport, response) {

  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // variables
  const now = new Date()

  // Creacion del contenido;
  let detalle = [];
  let [totalDeudor, totalAcreedor] = [0, 0];

  response.forEach((item, key) => {

    detalle.push(
      [
        new Cell(
          new Txt(item.CED_CORCED).fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.AEF_DESCRI_1).fontSize(7).bold().end
        ).border(noBorders).colSpan(4).end,
      ],
    );


    let [totalDebe, totalHaber] = [0, 0];

    item.detalle.forEach(item2 => {
      detalle.push(
        [
          new Cell(
            new Txt(item2.CED_CORCED).fontSize(7).bold().end
          ).border(noBorders).end,
          new Cell(
            new Txt(item2.AEF_DESCRI_2).fontSize(7).bold().end
          ).border(noBorders).colSpan(4).end,
        ],
      );

      let [totalDebe2, totalHaber2] = [0, 0];

      item2.detalle.forEach(item3 => {
        detalle.push(
          [
            new Cell(
              new Txt(item3.CED_CORCED).fontSize(7).bold().end
            ).border(noBorders).end,
            new Cell(
              new Txt(item3.AEF_DESCRI).fontSize(7).bold().end
            ).border(noBorders).colSpan(4).end,
          ],
        )

        let [totalDebeIni, totalHaberIni] = [0, 0];

        item3.detalle.forEach(element => {

          let [debeIni, haberIni] = [0, 0];

          debeIni = (element.DEBE_INI - element.HABER_INI) > 0 ? element.DEBE_INI - element.HABER_INI : 0;
          haberIni = (element.DEBE_INI - element.HABER_INI) < 0 ? element.HABER_INI - element.DEBE_INI : 0;

          totalDebeIni += debeIni;
          totalHaberIni += haberIni;

          detalle.push(
            [
              new Cell(
                new Txt("").end
              ).border(noBorders).end,
              new Cell(
                new Txt(element.CCN_CODCCN).fontSize(7).end
              ).border(noBorders).end,
              new Cell(
                new Txt(element.CCN_DESCRI).fontSize(7).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(debeIni.toFixed(2))).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(haberIni.toFixed(2))).fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ]
          )

        });

        let tempTotalDebe = totalDebeIni;
        let tempTotalHaber = totalHaberIni;

        totalDebeIni = (tempTotalDebe - tempTotalHaber) > 0 ? tempTotalDebe - tempTotalHaber : 0;
        totalHaberIni = (tempTotalDebe - tempTotalHaber) < 0 ? tempTotalHaber - tempTotalDebe : 0;

        detalle.push(
          [
            new Cell(
              new Txt("").end
            ).border(noBorders).colSpan(3).end,
            ...obtenerNulls(2),
            new Cell(
              new Txt(numberWithCommas(totalDebeIni.toFixed(2))).fontSize(7).alignment('right').end
            ).border(topBorder).end,
            new Cell(
              new Txt(numberWithCommas(totalHaberIni.toFixed(2))).fontSize(7).alignment('right').end
            ).border(topBorder).end,
          ],
        )

        totalDebe2 += totalDebeIni;
        totalHaber2 += totalHaberIni;

      });

      let [tempTotalDebe2, temptotalHaber2] = [totalDebe2, totalHaber2];

      totalDebe2 = (tempTotalDebe2 - temptotalHaber2) > 0 ? tempTotalDebe2 - temptotalHaber2 : 0;
      totalHaber2 = (tempTotalDebe2 - temptotalHaber2) < 0 ? temptotalHaber2 - tempTotalDebe2 : 0;

      detalle.push(
        [
          new Cell(
            new Txt("").end
          ).border(noBorders).colSpan(3).end,
          ...obtenerNulls(2),
          new Cell(
            new Txt(numberWithCommas(totalDebe2.toFixed(2))).fontSize(7).alignment('right').end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(totalHaber2.toFixed(2))).fontSize(7).alignment('right').end
          ).border(topBorder).end,
        ],
      )

      totalDebe += totalDebe2;
      totalHaber += totalHaber2;

    });

    if (item.CED_CORCED == "01") {
      totalDeudor = totalDebe;
      detalle.push([
        new Cell(
          new Txt(`TOTAL ${item.AEF_DESCRI_1}`).bold().fontSize(8).end
        ).border(openRightBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("").fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt((item.AEF_DESCRI_1 == "ACTIVO") ? numberWithCommas(totalDebe.toFixed(2)) : (0).toFixed(2)).fontSize(7).alignment('right').end
        ).border(openLeftBorder).end,
        new Cell(
          new Txt((item.AEF_DESCRI_1 == "ACTIVO") ? (0).toFixed(2) : numberWithCommas(totalHaber.toFixed(2))).fontSize(7).alignment('right').end
        ).border(openLeftBorder).end,
      ],)
    } else {
      totalAcreedor += totalHaber;
    }

    if (response.length - 1 == key) {
      const resultadoEjercicio = totalDeudor - totalAcreedor;

      detalle.splice(detalle.length - 2, 0, [
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("RESULTADOS DEL EJERCICIO").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(resultadoEjercicio.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ]);
      detalle.push([
        new Cell(
          new Txt(`TOTAL ${item.AEF_DESCRI_1}`).bold().fontSize(8).end
        ).border(openRightBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("").fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt((item.AEF_DESCRI_1 == "ACTIVO") ? numberWithCommas(totalDebe.toFixed(2)) : (0).toFixed(2)).fontSize(7).alignment('right').end
        ).border(openLeftBorder).end,
        new Cell(
          new Txt((item.AEF_DESCRI_1 == "ACTIVO") ? (0).toFixed(2) : numberWithCommas((totalAcreedor + resultadoEjercicio).toFixed(2))).fontSize(7).alignment('right').end
        ).border(openLeftBorder).end,
      ],)
      // detalle.push([
      //   new Cell(
      //     new Txt("").end
      //   ).border(noBorders).end,
      //   new Cell(
      //     new Txt("").fontSize(7).end
      //   ).border(noBorders).end,
      //   new Cell(
      //     new Txt("RESULTADOS DEL EJERCICIO").fontSize(7).end
      //   ).border(noBorders).end,
      //   new Cell(
      //     new Txt("").fontSize(7).alignment('right').end
      //   ).border(noBorders).end,
      //   new Cell(
      //     new Txt((totalDeudor - totalAcreedor).toFixed(2)).fontSize(7).alignment('right').end
      //   ).border(noBorders).end,
      // ])
    }

  });

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt(formatDateWithSlash(now)).alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt(headers.client).fontSize(8).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("Balance General Analítico").alignment('center').bold().fontSize(12).end).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt("").end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(`PERIODO ${headers.year} ${headers.month}`).alignment('center').bold().fontSize(8).end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(8).end,
          ]).end
        ).border(noBorders).end,
        new Cell(new Txt("").end).border(noBorders).end,
        new Cell(new Txt("").end).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("Cod").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Cuenta").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Descripción").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Saldo Actual").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        null
      ],
      [
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("Deudor").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Acreedor").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,

    ]).headerRows(5).widths([30, 110, 220, 77, 77]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Estado de G/P por Función
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function estadoGPporFuncion(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // Variable
  let items = [];
  response.forEach(element2 => {
    element2.detalle.forEach(element4 => {
      element4.detalle.forEach(element6 => {
        items.push([
          new Cell(
            new Txt(element6.AEF_DESCRI).fontSize(8).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(((parseFloat(element6.totalMontoMes)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element6.totalMontoMes)).toFixed(2))})` : numberWithCommas(element6.totalMontoMes)).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(((parseFloat(element6.totalMontoAcumulado)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element6.totalMontoAcumulado)).toFixed(2))})` : numberWithCommas(element6.totalMontoAcumulado)).fontSize(8).alignment('right').end
          ).border(noBorders).end
        ],)
      });
      items.push([
        new Cell(
          new Txt(`    ${element4.DESCRI}`).fontSize(8).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(((parseFloat(element4.totalMontoMes4)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element4.totalMontoMes4)).toFixed(2))})` : numberWithCommas(element4.totalMontoMes4)).fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(((parseFloat(element4.totalMontoAcumulado4)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element4.totalMontoAcumulado4)).toFixed(2))})` : numberWithCommas(element4.totalMontoAcumulado4)).fontSize(8).bold().alignment('right').end
        ).border(topBorder).end
      ],)
    });
    items.push([
      new Cell(
        new Txt(`        ${element2.DESCRI}`).fontSize(8).bold().end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(((parseFloat(element2.totalMontoMes2)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element2.totalMontoMes2)).toFixed(2))})` : numberWithCommas(parseFloat(element2.totalMontoMes2).toFixed(2))).fontSize(8).bold().alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(((parseFloat(element2.totalMontoAcumulado2)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element2.totalMontoAcumulado2)).toFixed(2))})` : numberWithCommas(parseFloat(element2.totalMontoAcumulado2).toFixed(2))).fontSize(8).bold().alignment('right').end
      ).border(topBorder).end
    ],)
  });
  // Diseño

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt("ESTADO DE GANANCIAS Y PERDIDAS POR FUNCIÓN").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt("").end).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(10).end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("DEL MES").fontSize(8).bold().alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("ACUMULADO").fontSize(8).bold().alignment('right').end
        ).border(noBorders).end
      ],
      ...items,

    ]).widths([100, 275, 70, 70]).headerRows(4).end
  )
  pdf.create().open();
}

/**
 * Reporte de Estado de G/P por Función Analítico
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function estadoGPporFuncionAnalitico(headers: HeaderReport, response) {
  //Design
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // Variable
  let items = [];

  response.forEach(element => {
    let totalMontoMes = 0;
    let totalMontoAcumulado = 0;
    element.detalle.forEach(item => {
      let moneda = 'SO'
      let montoMes = (item.SCU_DEBMNA - item.SCU_HABMNA) * item.CED_INDSIG;
      let montoAcumulado = (item.DEBE_INI - item.HABER_INI + item.SCU_DEBMNA - item.SCU_HABMNA) * item.CED_INDSIG;
      items.push([
        new Cell(
          new Txt(item.CCN_CODCCN).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.CCN_DESCRI).fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(((montoMes) < 0) ? `(${Math.abs(montoMes).toFixed(2)})` : montoMes.toFixed(2))).alignment('right').fontSize(8).end
        ).margin([5, 5, 5, 5]).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(((montoAcumulado) < 0) ? `(${Math.abs(montoAcumulado).toFixed(2)})` : montoAcumulado.toFixed(2))).alignment('right').fontSize(8).end
        ).border(noBorders).end,
      ])

      totalMontoMes += montoMes;
      totalMontoAcumulado += montoAcumulado;
    });

    items.push([
      new Cell(
        new Txt("").end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.AEF_DESCRI).fontSize(9).bold().end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(((totalMontoMes) < 0) ? `(${Math.abs(totalMontoMes).toFixed(2)})` : totalMontoMes.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(((totalMontoAcumulado) < 0) ? `(${Math.abs(totalMontoAcumulado).toFixed(2)})` : totalMontoAcumulado.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
    ])
  });

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("ESTADO G/P POR FUNCIÓN ANALÍTICO").alignment('center').bold().fontSize(10).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(10).end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("DEL MES").alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("ACUMULADO").alignment('right').fontSize(9).end
        ).border(noBorders).end,
      ],
      ...items,

    ]).headerRows(4).widths([35, 70, 240, 70, 70]).end
  )
  pdf.create().open();
}

/**
 * Reporte de Estado de G/P por Naturaleza
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function estadoGPporNaturaleza(headers: HeaderReport, response) {
  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // Variable
  let items = [];
  response.forEach(element2 => {
    element2.detalle.forEach(element4 => {
      element4.detalle.forEach(element6 => {
        items.push([
          new Cell(
            new Txt(element6.AEF_DESCRI).fontSize(9).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(((parseFloat(element6.totalMontoMes)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element6.totalMontoMes)).toFixed(2))})` : (element6.totalMontoMes)).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(((parseFloat(element6.totalMontoAcumulado)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element6.totalMontoAcumulado)).toFixed(2))})` : numberWithCommas(element6.totalMontoAcumulado)).fontSize(8).alignment('right').end
          ).border(noBorders).end
        ],)
      });
      items.push([
        new Cell(
          new Txt(`    ${element4.DESCRI}`).fontSize(9).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(((parseFloat(element4.totalMontoMes4)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element4.totalMontoMes4)).toFixed(2))})` : numberWithCommas(element4.totalMontoMes4)).fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(((parseFloat(element4.totalMontoAcumulado4)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element4.totalMontoAcumulado4)).toFixed(2))})` : numberWithCommas(element4.totalMontoAcumulado4)).fontSize(8).bold().alignment('right').end
        ).border(topBorder).end
      ],)
    });
    items.push([
      new Cell(
        new Txt(`        ${element2.DESCRI}`).fontSize(9).bold().margin([40, 40, 40, 40]).end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(((parseFloat(element2.totalMontoMes2)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element2.totalMontoMes2)).toFixed(2))})` : numberWithCommas(parseFloat(element2.totalMontoMes2).toFixed(2))).fontSize(8).bold().alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(((parseFloat(element2.totalMontoAcumulado2)) < 0) ? `(${numberWithCommas(Math.abs(parseFloat(element2.totalMontoAcumulado2)).toFixed(2))})` : numberWithCommas(parseFloat(element2.totalMontoAcumulado2).toFixed(2))).fontSize(8).bold().alignment('right').end
      ).border(topBorder).end
    ],)
  });

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt("ESTADO DE GANANCIAS Y PERDIDAS POR NATURALEZA").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("7").alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt("").end).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(10).end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("DEL MES").fontSize(8).bold().alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("ACUMULADO").fontSize(8).bold().alignment('right').end
        ).border(noBorders).end
      ],

      ...items,
    ]).widths([100, 275, 70, 70]).headerRows(4).end
  )
  pdf.create().open();
}

/**
 * Reporte de Estado de G/P por Naturaleza Analítico
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function estadoGPporNaturalezaAnalitico(headers: HeaderReport, response) {

  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 25, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // Variable
  let items = [];

  response.forEach(element => {
    let totalMontoMes = 0;
    let totalMontoAcumulado = 0;
    element.detalle.forEach(item => {
      let montoMes = (item.SCU_DEBMNA - item.SCU_HABMNA) * item.CED_INDSIG;
      let montoAcumulado = (item.DEBE_INI - item.HABER_INI + item.SCU_DEBMNA - item.SCU_HABMNA) * item.CED_INDSIG;
      items.push([
        new Cell(
          new Txt(item.CCN_CODCCN).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.CCN_DESCRI).fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(((montoMes) < 0) ? `(${Math.abs(montoMes).toFixed(2)})` : montoMes.toFixed(2))).alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(((montoAcumulado) < 0) ? `(${Math.abs(montoAcumulado).toFixed(2)})` : montoAcumulado.toFixed(2))).alignment('right').fontSize(8).end
        ).border(noBorders).end,
      ])

      totalMontoMes += montoMes;
      totalMontoAcumulado += montoAcumulado;
    });

    items.push([
      new Cell(
        new Txt("").end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.AEF_DESCRI).fontSize(9).bold().end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(((totalMontoMes) < 0) ? `(${Math.abs(totalMontoMes).toFixed(2)})` : totalMontoMes.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(((totalMontoAcumulado) < 0) ? `(${Math.abs(totalMontoAcumulado).toFixed(2)})` : totalMontoAcumulado.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
    ])
  });

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("ESTADO G/P POR NATURALEZA ANALÍTICO").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).end,
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').fontSize(10).end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(9).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("DEL MES").alignment('right').bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("ACUMULADO").alignment('right').bold().fontSize(8).end
        ).border(noBorders).end,
      ],
      ...items,
    ]).headerRows(4).widths([35, 95, 240, 70, 70]).end
  )
  pdf.create().open();
}

/**
 * Reporte de Libro Diario
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function librodiarioReport(headers: HeaderReport, response) {
  /** Generar reporte */
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  /** Variables */
  let items = [];

  let [totalNacDebe, totalNacHabe, totalExtDebe, totalExtHabe] = [0, 0, 0, 0];

  response.forEach(subdiario => {
    items.push([
      new Cell(
        new Txt(`SUBDIARIO ${subdiario.subdiario_code}`).fontSize(7).bold().end
      ).colSpan(2).border(noBorders).end,
      new Cell(
        new Txt(subdiario.subdiario_code).fontSize(7).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(subdiario.subdiario_name).fontSize(7).bold().end
      ).colSpan(10).border(noBorders).end,
      new Cell(
        new Txt("").bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("").bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("").bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("").bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("").bold().end
      ).border(noBorders).end,
      ...obtenerNulls(4),
    ])

    let [monNacDebeSub, monNacHaberSub, monExtDebeSub, monExtHaberSub] = [0, 0, 0, 0];

    subdiario.detalle.forEach(voucher => {
      items.push([
        new Cell(
          new Txt(`VOUCHER: ${voucher.voucher_code}`).fontSize(7).bold().end
        ).colSpan(2).border(noBorders).end,
        new Cell(
          new Txt("00103").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("FECHA: ").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(voucher.voucher_date)).fontSize(7).bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("REFERENCIA: ").fontSize(7).bold().end
        ).border(noBorders).end,

        new Cell(
          new Txt(voucher.voucher_name).fontSize(7).bold().end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("ESTADO:").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(voucher.voucher_state).fontSize(7).bold().end
        ).border(noBorders).end
      ])

      let [monNacDebe, monNacHaber, monExtDebe, monExtHaber] = [0, 0, 0, 0];

      voucher.detalle.forEach(item => {
        monNacDebe += item.ldi_impnac_debe;
        monNacHaber += item.ldi_impnac_habe;
        monExtDebe += item.ldi_impmex_debe;
        monExtHaber += item.ldi_impmex_habe;
        items.push([
          new Cell(
            new Txt(item.cuenta).fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(item.descri_cuenta).fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(item.auxiliar).fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(item.centro_costo).fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(item.tipo_docu).fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(item.nro_docu).fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(item.tipo_cambio).fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(numberWithCommas(item.ldi_impnac_debe.toFixed(2))).alignment('right').fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(numberWithCommas(item.ldi_impnac_habe.toFixed(2))).alignment('right').fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(numberWithCommas(item.ldi_impmex_debe.toFixed(2))).alignment('right').fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(numberWithCommas(item.ldi_impmex_habe.toFixed(2))).alignment('right').fontSize(6).end
          ).border(bottomBorder).end,
          new Cell(
            new Txt(item.glosa).fontSize(6).end
          ).border(bottomBorder).end,
        ])
      });

      items.push([
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`TOTAL ASIENTO: ${subdiario.subdiario_code} - ${voucher.voucher_code}`).bold().fontSize(8).end
        ).colSpan(4).border(topBorder).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(numberWithCommas(monNacDebe.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(monNacHaber.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(monExtDebe.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(monExtHaber.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ]);

      monNacDebeSub += monNacDebe;
      monNacHaberSub += monNacHaber;
      monExtDebeSub += monExtDebe;
      monExtHaberSub += monExtHaber;
    });

    items.push([
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(`TOTAL SUBDIARIO: ${subdiario.subdiario_code}`).bold().fontSize(8).end
      ).colSpan(4).border(noBorders).end,
      ...obtenerNulls(3),
      new Cell(
        new Txt(numberWithCommas(monNacDebeSub.toFixed(2))).alignment('right').fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(monNacHaberSub.toFixed(2))).alignment('right').fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(monExtDebeSub.toFixed(2))).alignment('right').fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(monExtHaberSub.toFixed(2))).alignment('right').fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
    ])

    totalNacDebe += monNacDebeSub;
    totalNacHabe += monNacHaberSub;
    totalExtDebe += monExtDebeSub;
    totalExtHabe += monExtHaberSub;
  });

  pdf.add(
    new Table([
      [
        new Cell(new Txt("FORMATO 5.1 : LIBRO DIARIO").fontSize(12).bold().end).border(noBorders).colSpan(12).end,
        ...obtenerNulls(11),
      ],
      [
        new Cell(new Txt("Período:").fontSize(8).bold().end).border(noBorders).end,
        new Cell(new Txt(`    ${headers.year} ${headers.month}`).fontSize(8).end).border(noBorders).colSpan(11).end,
        ...obtenerNulls(10),
      ],
      [
        new Cell(new Txt("RUC :").fontSize(8).bold().end).border(noBorders).end,
        new Cell(new Txt(`    ${headers.client}`).fontSize(8).end).border(noBorders).colSpan(11).end,
        ...obtenerNulls(10),
      ],
      [
        new Cell(new Txt("Razón Social:").bold().fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt("Cuenta").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Denominación").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Auxiliar").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Ceco").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Doc.").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Nro. Doc.").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("T.C.").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Moneda Nacional").fontSize(7).bold().alignment('center').end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Moneda Extranjera").fontSize(7).bold().alignment('center').end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Glosa").fontSize(7).bold().alignment('center').end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
      ],
      [
        new Cell(
          new Txt("").end,
        ).end,
        new Cell(
          new Txt("").end,
        ).end,
        new Cell(
          new Txt("").end,
        ).end,
        new Cell(
          new Txt("").end,
        ).end,
        new Cell(
          new Txt("").end,
        ).end,
        new Cell(
          new Txt("").end,
        ).end,
        new Cell(
          new Txt("").end,
        ).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().end,
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("").end,
        ).end,
      ],
      ...items,
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(openRightBorder).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("TOTAL GENERAL").bold().fontSize(8).end
        ).colSpan(4).border(horizontalBorder).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(numberWithCommas(totalNacDebe.toFixed(2))).alignment('right').fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNacHabe.toFixed(2))).alignment('right').fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalExtDebe.toFixed(2))).alignment('right').fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalExtHabe.toFixed(2))).alignment('right').fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(openLeftBorder).end,
      ]
    ]).headerRows(6).widths([40, 90, 60, 40, 20, 45, 25, 55, 55, 45, 45, 180]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Libro Diario Resumen
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function librodiarioresumentReport(headers: HeaderReport, response) {

  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })


  /** Variables */

  let items = [];

  let [totalNacDebe, totalNacHabe, totalExtDebe, totalExtHabe] = [0, 0, 0, 0];

  response.forEach(subdiario => {
    items.push([
      new Cell(
        new Txt(`SUBDIARIO`).fontSize(9).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(`${subdiario.subdiario_code} ${subdiario.subdiario_name}`).fontSize(9).bold().end
      ).colSpan(8).border(noBorders).end,
      ...obtenerNulls(7),
    ])

    let [monNacDebeSub, monNacHaberSub, monExtDebeSub, monExtHaberSub] = [0, 0, 0, 0];

    subdiario.detalle.forEach(voucher => {
      items.push([
        new Cell(
          new Txt(`VOUCHER: ${voucher.voucher_code}      FECHA: ${voucher.voucher_date}      REFERENCIA: ${voucher.voucher_name} `).fontSize(9).bold().end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt(`ESTADO: ${voucher.voucher_state}`).fontSize(9).bold().end
        ).colSpan(2).border(noBorders).end,
        null
      ])

      let [monNacDebe, monNacHaber, monExtDebe, monExtHaber] = [0, 0, 0, 0];

      voucher.detalle.forEach(item => {
        monNacDebe += item.ldi_impnac_debe;
        monNacHaber += item.ldi_impnac_habe;
        monExtDebe += item.ldi_impmex_debe;
        monExtHaber += item.ldi_impmex_habe;
        items.push([
          new Cell(
            new Txt(item.cuenta).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(item.descri_cuenta).fontSize(7).end
          ).colSpan(2).border(noBorders).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(numberWithCommas(item.ldi_impnac_debe.toFixed(2))).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(item.ldi_impnac_habe.toFixed(2))).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(item.ldi_impmex_debe.toFixed(2))).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(item.ldi_impmex_habe.toFixed(2))).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt("").fontSize(7).end
          ).colSpan(2).border(noBorders).end,
          null
        ])
      });

      items.push([
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`TOTAL ASIENTO: ${subdiario.subdiario_code} - ${voucher.voucher_code}`).bold().fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(monNacDebe.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(monNacHaber.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(monExtDebe.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(monExtHaber.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        null
      ]);

      monNacDebeSub += monNacDebe;
      monNacHaberSub += monNacHaber;
      monExtDebeSub += monExtDebe;
      monExtHaberSub += monExtHaber;
    });

    items.push([
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(`TOTAL SUBDIARIO: ${subdiario.subdiario_code}`).bold().fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(monNacDebeSub.toFixed(2))).bold().fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(monNacHaberSub.toFixed(2))).bold().fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(monExtDebeSub.toFixed(2))).bold().fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(monExtHaberSub.toFixed(2))).bold().fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).colSpan(2).border(noBorders).end,
      null
    ])

    totalNacDebe += monNacDebeSub;
    totalNacHabe += monNacHaberSub;
    totalExtDebe += monExtDebeSub;
    totalExtHabe += monExtHaberSub;
  });
  /** Generar reporte */




  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("Libro Diario Resumen").alignment('center').bold().fontSize(12).end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('right').fontSize(8).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt("").end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
        ).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Cuenta").bold().fontSize(7).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Denominación").bold().fontSize(7).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).rowSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Moneda Nacional").bold().fontSize(7).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Moneda Extranjera").bold().fontSize(7).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("").fontSize(7).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).rowSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("").fontSize(7).end
        ).end,
        new Cell(
          new Txt("").fontSize(7).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).end,
        new Cell(
          new Txt("Debe").bold().fontSize(7).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").bold().fontSize(7).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").bold().fontSize(7).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").bold().fontSize(7).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        null
      ],
      ...items,
      [
        new Cell(
          new Txt("").fontSize(7).end
        ).border(openRightBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("TOTAL GENERAL").bold().fontSize(7).alignment('right').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNacDebe.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalNacHabe.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalExtDebe.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalExtHabe.toFixed(2))).bold().fontSize(7).alignment('right').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).colSpan(2).border(openRightBorder).end,
        null
      ],
    ]).headerRows(5).widths([50, 150, 190, 45, 45, 55, 55, 100, 40]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Libro Caja y Banco
 * @param response data to fill report
 * @param headers data to fill headers
 */
export async function librocajaybancoReport(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('portrait');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // pdf.add(
  // new Table().headerRows(2).widths([258, 258, 258]).layout('noBorders').end
  // )
  let detalle = [];

  let total = 0;
  response.forEach(element => {
    detalle.push([
      new Cell(
        new Txt("CUENTA:").fontSize(6).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cuenta_code).fontSize(6).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cuenta_name).fontSize(6).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("SALDO ANTERIOR").fontSize(6).bold().end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt("").fontSize(6).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(total.toFixed(2)).alignment('right').fontSize(6).bold().end
      ).border(noBorders).end,
    ],)

    element.detalle.forEach(item => {

      item.ind_debe_haber == "D" ? headers.currency == "SO" ? total += item.ldi_impnac_debe : total += item.ldi_impmex_debe : headers.currency == "SO" ? total -= item.ldi_impnac_habe : total += item.ldi_impmex_habe;

      detalle.push([
        new Cell(
          new Txt(formatDateWithSlash(new Date())).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.cod_subdiario} ${item.corr_asiento}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.item).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.concepto).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tipo_docu).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.nro_docu).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.moneda).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((headers.currency == "SO") ? numberWithCommas(item.ldi_impnac_debe.toFixed(2)) : numberWithCommas(item.ldi_impmex_debe.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((headers.currency == "SO") ? numberWithCommas(item.ldi_impnac_habe.toFixed(2)) : numberWithCommas(item.ldi_impmex_habe.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(total.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ],)
    });
  });


  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt(`RUC: ${headers.client}`).fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Libro de Caja y Bancos").alignment('center').bold().fontSize(10).end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(new Txt("7").alignment('right').fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([

            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(8).end,
            new Txt(`${headers.currency}`).alignment('center').bold().fontSize(8).end,

          ]).end,
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(new Txt("").alignment('left').end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Fecha").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Nro. Asiento").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("It.").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Glosa").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("T.D.").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Doc.").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Mo.").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Debe").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Haber").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Saldo").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
      ],
      ...detalle
    ]).headerRows(4).widths([35, 45, 25, 180, 20, 45, 20, 30, 30, 40]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Analisis Gasto
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function analisisgastoReport(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(10).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(10).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("Análisis de Gasto - 6 y 33").alignment('center').bold().fontSize(12).end).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('right').fontSize(10).end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([

            new Txt(`${headers.year}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').bold().fontSize(10).end,

          ]).end,
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('left').end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("Concepto").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(openRightBorder).end,
        new Cell(
          new Txt("Ene.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Feb.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Mar.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Abr.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("May.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Jun.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Jul.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Ago.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Set.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Oct.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Nov.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Dic.").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Total").fontSize(8).bold().alignment('right').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(openRightBorder).end,
      ],
      [
        new Cell(
          new Txt("COMPRAS").fontSize(8).bold().end
        ).border(noBorders).colSpan(14).end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(
          new Txt("602110 COMPRA MN NACIONAL PARA PROD").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("602110 COMPRA MN NACIONAL PARA PROD").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("602110 COMPRA MN NACIONAL PARA PROD").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("GASTOS DE PERSONAL, DIRECTORES Y GERENTES").fontSize(8).bold().end
        ).border(noBorders).colSpan(14).end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(
          new Txt("602110 COMPRA MN NACIONAL PARA PROD").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("602110 COMPRA MN NACIONAL PARA PROD").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("602110 COMPRA MN NACIONAL PARA PROD").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("1,252.00").fontSize(7).alignment('right').end
        ).border(topBorder).end,
      ],
    ]).headerRows(4).widths([160, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Mayor Analítico
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function mayorAnalitico(headers: HeaderReport, response) {

  /** Variables */
  let items = [];

  let totalGeneralDebe = 0;
  let totalGeneralHaber = 0;
  response.forEach(cuenta => {
    let saldoInicialDebe = ((cuenta.inidebe - cuenta.inihabe) > 0) ? cuenta.inidebe - cuenta.inihabe : 0
    let saldoInicialHaber = ((cuenta.inidebe - cuenta.inihabe) < 0) ? Math.abs(cuenta.inidebe - cuenta.inihabe) : 0

    items.push([
      new Cell(
        new Txt(`CUENTA:   ${cuenta.cuenta}    ${cuenta.descri}         SALDO INICIAL:`).bold().fontSize(7).end
      ).border(noBorders).colSpan(9).end,
      ...obtenerNulls(8),
      new Cell(
        new Txt(numberWithCommas(saldoInicialDebe.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoInicialHaber.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end
    ])

    let montoCambiante = (saldoInicialDebe == 0) ? saldoInicialHaber : saldoInicialDebe;

    let totalDebe = 0;
    let totalHabe = 0;


    cuenta.detalle.forEach(item => {
      let montoDebe = (item.LDI_INDDHA == "D") ? item.LDI_IMPNAC : 0;
      let montoHaber = (item.LDI_INDDHA == "H") ? item.LDI_IMPNAC : 0;

      totalDebe += montoDebe;
      totalHabe += montoHaber;

      // let valorCambiante = (item.LDI_INDDHA == "D")? (montoCambiante + item.LDI_IMPNAC): (montoCambiante - item.LDI_IMPNAC);
      if (item.LDI_INDDHA == "D") {
        montoCambiante += item.LDI_IMPNAC
      } else {
        montoCambiante -= item.LDI_IMPNAC
      }
      // (item.LDI_INDDHA == "D")? : ;
      items.push(
        [
          new Cell(
            new Txt(item.SCO_CODSCO).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(item.LDC_CORLDC).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(item.LDI_CORLDI).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt((item.LDC_FECDIA) ? item.LDC_FECDIA.toString().substring(0, 10) : "").fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(item.CLI_CODCLI).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(item.CCS_CODCCS).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(`${item.TDO_CODTDO}    ${item.LDI_DOCREF}`).fontSize(7).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(item.LDI_GLOLDI).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(montoDebe.toFixed(2))).alignment('right').fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(montoHaber.toFixed(2))).alignment('right').fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(Math.abs(montoCambiante).toFixed(2))).alignment('right').fontSize(7).end
          ).border(noBorders).end,
        ])


    });

    let saldoFinalDebe = (saldoInicialDebe + totalDebe - totalHabe > 0) ? saldoInicialDebe + totalDebe - totalHabe : 0;
    let saldoFinalHaber = (saldoInicialDebe + totalDebe - totalHabe < 0) ? Math.abs(saldoInicialDebe + totalDebe - totalHabe) : 0;


    items.push(
      [
        new Cell(
          new Txt("- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -").fontSize(14).end
        ).border(noBorders).colSpan(12).end,
        ...obtenerNulls(11),
      ],
      [
        new Cell(
          new Txt(`MOVIMIENTOS DEL MES CUENTA:      ${cuenta.cuenta}`).bold().alignment('right').fontSize(7).end
        ).colSpan(9).border(noBorders).end,
        ...obtenerNulls(8),
        new Cell(
          new Txt(numberWithCommas(totalDebe.toFixed(2))).alignment('right').fontSize(7).end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalHabe.toFixed(2))).alignment('right').fontSize(7).end
        ).border(topBorder).end,
        new Cell(
          new Txt("").alignment('right').fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("SALDO FINAL").alignment('right').bold().fontSize(7).end
        ).colSpan(9).border(noBorders).end,
        ...obtenerNulls(8),
        new Cell(
          new Txt(numberWithCommas(saldoFinalDebe.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(saldoFinalHaber.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").alignment('right').fontSize(7).end
        ).border(noBorders).end,
      ]
    )

    totalGeneralDebe += totalDebe;
    totalGeneralHaber += totalHabe;

  });
  items.push(
    [
      new Cell(
        new Txt("TOTAL GENERAL MOVIMIENTOS").alignment('right').bold().fontSize(7).end
      ).colSpan(9).border(noBorders).end,
      ...obtenerNulls(8),
      new Cell(
        new Txt(numberWithCommas(totalGeneralDebe.toFixed(2))).alignment('right').fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totalGeneralHaber.toFixed(2))).alignment('right').fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
    ]
  )

  /** Diseño */
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(new Txt("").end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(new Txt("Mayor Analítico").alignment('center').bold().fontSize(12).end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("7").alignment('right').fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').bold().fontSize(10).end
          ]).end,
        ).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('left').end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Subd.").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Nro. Asie.").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("It.").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fecha").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Auxiliar").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ce. Co.").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Doc.").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Nro. Doc.").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Glosa").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],
      ...items,

    ]).headerRows(4).widths([30, 60, 30, 60, 60, 30, 20, 60, 160, 60, 60, 60]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Registro de Honorarios
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function registroDeHonorarios(headers: HeaderReport, response) {

  // Variable
  let items = [];

  let total01 = 0;
  let total02 = 0;
  let total03 = 0;

  response.forEach(element => {
    items.push([
      new Cell(
        new Txt(element.ldc_corldc).fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt(element.cco_fecemi.substring(0, 10)).fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt(element.cco_fecven.substring(0, 10)).fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt(element.tdo_codofi).fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt(element.serie).fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt(element.cco_numdoc).fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt(element.t02_coddoc).fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt(element.cli_codcli).fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt((element.tmo_codtmo == "SO") ? numberWithCommas(element.cco_impina_mn) : numberWithCommas(element.cco_impina_me)).fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt((element.tmo_codtmo == "SO") ? numberWithCommas(element.dpc_impren_mn) : numberWithCommas(element.dpc_impren_me)).fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt((element.tmo_codtmo == "SO") ? numberWithCommas(element.cco_impdoc_mn) : numberWithCommas(element.cco_impdoc_me)).fontSize(7).alignment('right').end
      ).border(noBorders).end,
    ],)
    total01 += element.cco_impina_mn;
    total02 += element.dpc_impren_mn;
    total03 += element.cco_impdoc_mn;
  });

  items.push(
    [
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(total01.toFixed(2))).fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(total02.toFixed(2))).fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(total03.toFixed(2))).fontSize(7).alignment('right').end
      ).border(topBorder).end,
    ],
    [
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(openRightBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(horizontalBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(horizontalBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(horizontalBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(horizontalBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(horizontalBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(horizontalBorder).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).alignment('center').border(horizontalBorder).end,
      new Cell(
        new Txt("TOTALES").bold().fontSize(7).alignment('right').end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(total01.toFixed(2))).fontSize(7).alignment('right').end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(total02.toFixed(2))).fontSize(7).alignment('right').end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(total03.toFixed(2))).fontSize(7).alignment('right').end
      ).border(openRightBorder).end,
    ]
  )

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(10).end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(new Txt("").end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(10).end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(new Txt("Registro de Honorarios").alignment('center').bold().fontSize(14).end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('right').fontSize(10).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([

            new Txt(`${headers.currency}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,

          ]).end,
        ).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('left').end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Número Correlativo").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(3).end,
        new Cell(
          new Txt("Fecha de Emisión").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(3).end,
        new Cell(
          new Txt("Fecha de Pago").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(3).end,
        new Cell(
          new Txt("Comprobante de Pago").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Información Del Proveedor").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Base Imponible").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(3).end,
        new Cell(
          new Txt("Impuesto a la Renta").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(3).end,
        new Cell(
          new Txt("Importe Neto").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(3).end,
      ],
      [
        ...obtenerNulls(3),
        new Cell(
          new Txt("Tipo").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Serie").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Nro. del Compro").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Documento de Identidad").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("Apellidos y Nombres").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        ...obtenerNulls(3),
      ],
      [
        ...obtenerNulls(6),
        new Cell(
          new Txt("Tipo").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Número").fontSize(8).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        ...obtenerNulls(4),
      ],
      ...items,

    ]).headerRows(6).widths([60, 45, 45, 30, 30, 55, 30, 70, 190, 45, 45, 45]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Resumen Centro de Costo
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function resumencentrocostoReport(headers: HeaderReport, response) {

  /** Variables */
  let datos = [];

  let [totalUlt01, totalUlt02, totalUlt03, totalUlt04, totalUlt05, totalUlt06, totalUlt07, totalUlt08, totalUlt09, totalUlt10, totalUlt11, totalUlt12, totalUltT] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  response.forEach(element => {
    datos.push([
      new Cell(
        new Txt("Centro de Costo: ").bold().fontSize(8).end
      ).border(noBorders).fontSize(10).end,
      new Cell(
        new Txt(`${element.centro_costo_code} ${element.centro_costo_name}`).end
      ).border(noBorders).bold().fontSize(8).colSpan(13).end,
      ...obtenerNulls(12),
    ])
    let [total01, total02, total03, total04, total05, total06, total07, total08, total09, total10, total11, total12, totalT] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    element.centro_costo_detalle.forEach(item => {

      let totalHorizontal = item.importe_mes01 + item.importe_mes02 + item.importe_mes03 + item.importe_mes04 + item.importe_mes05 + item.importe_mes06 + item.importe_mes07 + item.importe_mes08 + item.importe_mes09 + item.importe_mes10 + item.importe_mes11 + item.importe_mes12;
      total01 += item.importe_mes01;
      total02 += item.importe_mes02;
      total03 += item.importe_mes03;
      total04 += item.importe_mes04;
      total05 += item.importe_mes05;
      total06 += item.importe_mes06;
      total07 += item.importe_mes07;
      total08 += item.importe_mes08;
      total09 += item.importe_mes09;
      total10 += item.importe_mes10;
      total11 += item.importe_mes11;
      total12 += item.importe_mes12;
      totalT += totalHorizontal;
      datos.push([
        new Cell(
          new Txt(`${item.cuenta} ${item.CUENTA_DESCRI_3D}`).end
        ).border(noBorders).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes01.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes02.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes03.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes04.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes05.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes06.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes07.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes08.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes09.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes10.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes11.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(item.importe_mes12.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totalHorizontal.toFixed(2))).end
        ).border(noBorders).alignment('right').fontSize(6).end,
      ])
    });

    datos.push([
      new Cell(
        new Txt(`TOTAL ${element.centro_costo_code}`).bold().end
      ).border(noBorders).alignment('left').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total01.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total02.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total03.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total04.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total05.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total06.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total07.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total08.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total09.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total10.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total11.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(total12.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
      new Cell(
        new Txt(numberWithCommas(totalT.toFixed(2))).end
      ).border(topBorder).alignment('right').fontSize(6).end,
    ])

    totalUlt01 += total01;
    totalUlt02 += total02;
    totalUlt03 += total03;
    totalUlt04 += total04;
    totalUlt05 += total05;
    totalUlt06 += total06;
    totalUlt07 += total07;
    totalUlt08 += total08;
    totalUlt09 += total09;
    totalUlt10 += total10;
    totalUlt11 += total11;
    totalUlt12 += total12;
    totalUltT = totalUlt01 + totalUlt02 + totalUlt03 + totalUlt04 + totalUlt05 + totalUlt06 + totalUlt07 + totalUlt08 + totalUlt09 + totalUlt10 + totalUlt11 + totalUlt12;

  });

  /** Diseño */
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);

  // pdf.footer(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  pdf.add(
    new Table([

      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(new Txt("").end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(new Txt("Resumen por Centro de Costo").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([

            new Txt(`${headers.year}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').bold().fontSize(10).end,

          ]).end,
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],

      [
        new Cell(
          new Txt("").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(openRightBorder).fontSize(8).end,
        new Cell(
          new Txt("Ene.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Feb.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Mar.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Abr.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("May.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Jun.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Jul.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Ago.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Set.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Oct.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Nov.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Dic.").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).bold().fontSize(8).alignment('right').end,
        new Cell(
          new Txt("Total").end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(openLeftBorder).bold().fontSize(8).alignment('right').end,
      ],
      ...datos,
      [
        new Cell(
          new Txt("TOTAL GENERAL").bold().end
        ).border(openRightBorder).alignment('left').fontSize(6).end,
        new Cell(
          new Txt(totalUlt01.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt02.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt03.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt04.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt05.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt06.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt07.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt08.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt09.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt10.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt11.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUlt12.toFixed(2).toString()).end
        ).border(horizontalBorder).alignment('right').fontSize(6).end,
        new Cell(
          new Txt(totalUltT.toFixed(2).toString()).end
        ).border(openLeftBorder).alignment('right').fontSize(6).end,
      ],
    ]).headerRows(4).widths([140, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 42]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Centro de Costo Vs Cuenta
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function centrocostovscuentaReport(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(10).end).border(noBorders).end,
        new Cell(new Txt("").end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).border(noBorders).end
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(10).end).border(noBorders).end,
        new Cell(new Txt("Resumen Mensual Centro Costo VS Cuenta").alignment('center').bold().fontSize(14).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("7").alignment('right').fontSize(10).end).border(noBorders).end
      ],
      [
        new Cell(new Txt("").end).border(noBorders).end,
        new Cell(
          new Stack([

            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,

          ]).end,
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").alignment('left').end).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("Cuenta").bold().fontSize(10).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Descripción").bold().fontSize(10).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe MN").bold().fontSize(10).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe MF").bold().fontSize(10).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(
          new Txt("Centro de Costo:").fontSize(10).bold().end,
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt("94363911").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("HONORARIOS PROFESIONALES").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').fontSize(9).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("94363911").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("HONORARIOS PROFESIONALES").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').fontSize(9).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTAL").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').fontSize(9).end
        ).border(topBorder).end,
        new Cell(
          new Txt("10,200.00").alignment('right').fontSize(9).end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("CENTRO DE COSTO:").fontSize(10).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt("100001 OPERACIONES").fontSize(10).bold().end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("94363911").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("HONORARIOS PROFESIONALES").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').fontSize(9).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("94363911").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("HONORARIOS PROFESIONALES").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').bold().fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').bold().fontSize(9).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTAL OPERACIONES").bold().fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10,200.00").alignment('right').bold().fontSize(9).end
        ).border(topBorder).end,
        new Cell(
          new Txt("10,200.00").alignment('right').bold().fontSize(9).end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(9).end
        ).border(openRightBorder).end,
        new Cell(
          new Txt("TOTAL GENERAL").bold().fontSize(9).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("10,200.00").alignment('right').bold().fontSize(9).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("10,200.00").alignment('right').bold().fontSize(9).end
        ).border(openRightBorder).end,
      ],
    ]).headerRows(4).widths([120, 440, 100, 100]).end
  )


  pdf.create().open();

}

/**
 * Reporte de Saldo Analíticos Resumen
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function saldosanaliticosresumentReport(headers: HeaderReport, response) {
  // Variable
  let items = [];
  response.forEach(cuenta => {
    items.push([
      new Cell(
        new Txt("CUENTA").fontSize(7).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(cuenta.cuenta).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(cuenta.descri).fontSize(7).end
      ).border(noBorders).colSpan(6).end,
      ...obtenerNulls(5),
    ])

    let totalCuentaMN = 0;
    let totalCuentaME = 0;

    cuenta.detalle.forEach(auxiliar => {
      items.push([
        new Cell(
          new Txt("AUXILIAR:").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(auxiliar.auxiliar).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(auxiliar.descri).fontSize(7).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ])

      let totalAuxiliarMN = 0;
      let totalAuxiliarME = 0;

      auxiliar.detalle.forEach(element => {

        totalAuxiliarMN += element.importe_mn;
        totalAuxiliarME += element.importe_me;

        items.push([
          new Cell(
            new Txt(element.fecha_asiento.substring(0, 10)).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element.item).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element.tipo_docu).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element.numero_docu).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element.glosa).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element.cta_cte).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element.importe_mn.toFixed(2))).fontSize(7).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element.importe_me.toFixed(2))).fontSize(7).alignment('right').end
          ).border(noBorders).end,
        ])
      });

      totalCuentaMN += totalAuxiliarMN;
      totalCuentaME += totalAuxiliarME;
      items.push([
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTAL AUXILIAR").bold().fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(auxiliar.auxiliar).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalAuxiliarMN.toFixed(2))).fontSize(7).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totalAuxiliarME.toFixed(2))).fontSize(7).alignment('right').end
        ).border(topBorder).end,
      ])
    });

    items.push([
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("TOTAL CUENTA").bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(cuenta.cuenta).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalCuentaMN.toFixed(2))).fontSize(7).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totalCuentaME.toFixed(2))).fontSize(7).alignment('right').end
      ).border(topBorder).end,
    ])
  });

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  // pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("Saldos Analíticos Resumen").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("7").alignment('right').fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([

            new Txt(`Período ${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,

          ]).end,
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Fecha de la operación").bold().fontSize(8).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Número Correlativo del Asiento").bold().fontSize(8).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("T.D.").bold().fontSize(8).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Número Doc.").bold().fontSize(8).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Descripción de la Operación").bold().fontSize(8).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cta. Cte.").bold().fontSize(8).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo M.N.").bold().fontSize(8).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo M.E.").bold().fontSize(8).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],
      ...items,

    ]).headerRows(4).widths([80, 140, 30, 75, 190, 80, 65, 65]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Analítico por Cuenta Contable
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function analiticoPorCuentaContable(headers: HeaderReport, response) {
  // Variables

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let items = [];

  let totalMNCuenta = 0
  let totalMECuenta = 0

  response.forEach(auxiliar => {
    items.push(
      [
        new Cell(
          new Txt("Cuenta").bold().fontSize(6).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(auxiliar.cuenta).bold().fontSize(6).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("").bold().fontSize(6).end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
      ],
      [
      new Cell(
        new Txt("Auxiliar").bold().fontSize(7).end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_code).bold().fontSize(7).end
      ).colSpan(3).border(noBorders).end,
      ...obtenerNulls(2),
      new Cell(
        new Txt(auxiliar.auxiliar_descri).bold().fontSize(7).end
      ).border(noBorders).colSpan(9).end,
      ...obtenerNulls(8),
    ])

    let totalMNSaldo = 0
    let totalMESaldo = 0

    auxiliar.detalle.forEach(item => {

      let impDebeSoles = (item.ldi_INDDHA == "D") ? item.LDI_IMPNAC : 0
      let impDebeDolares = (item.ldi_INDDHA == "D") ? item.LDI_IMPMEX : 0
      let impHaberSoles = (item.ldi_INDDHA == "H") ? (item.LDI_IMPNAC) * (-1) : 0
      let impHaberDolares = (item.ldi_INDDHA == "H") ? (item.LDI_IMPMEX) * (-1) : 0
      let saldoSoles = (impDebeSoles == 0) ? impHaberSoles : impDebeSoles;
      let saldoDolares = (impDebeDolares == 0) ? impHaberDolares : impDebeDolares;

      totalMNSaldo += saldoSoles
      totalMESaldo += saldoDolares

      items.push([
        new Cell(
          new Txt((item.LDC_FECDIA) ? item.LDC_FECDIA.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecemi) ? item.cco_fecemi.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecven) ? item.cco_fecven.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.ANO_CODANO_LDI}-${item.MES_CODMES_LDI}-${item.sco_codsco}-${item.ldc_CORldc}-${item.ldi_corldi}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codtdo).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_docref).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cco_codcco).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_gloldi).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impDebeSoles.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impHaberSoles.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(saldoSoles.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impDebeDolares.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impHaberDolares.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(saldoDolares.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ])
    });

    items.push([
      new Cell(
        new Txt("TOTAL AUXILIAR          70484848").alignment('right').fontSize(6).end
      ).colSpan(8).border(noBorders).end,
      ...obtenerNulls(7),
      new Cell(
        new Txt("").alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalMNSaldo.toFixed(2))).alignment('right').fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalMESaldo.toFixed(2))).alignment('right').fontSize(6).end
      ).border(topBorder).end,
    ])

    totalMNCuenta += totalMNSaldo
    totalMECuenta += totalMESaldo
  });

  items.push([
    new Cell(
      new Txt(`TOTAL CUENTA          ${response[0].cuenta}`).alignment('right').fontSize(6).end
    ).colSpan(8).border(noBorders).end,
    ...obtenerNulls(7),
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totalMNCuenta.toFixed(2))).alignment('right').fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totalMECuenta.toFixed(2))).alignment('right').fontSize(6).end
    ).border(topBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Analítico Por Cuenta Contable").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Acumulado hasta ${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
          ]).end,
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("Fecha de la Operación").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Fecha de Emi.").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Fecha de Venc.").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Correlativo de Asiento").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tipo Doc.").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Nro. Doc.").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Cta. Cte.").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Glosa o Descripción de la Operación").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Movimiento Moneda Nacional").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Movimiento Moneda Extranjera").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        ...obtenerNulls(8),
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],

      ...items,
    ]).headerRows(5).widths([40, 35, 35, 75, 20, 40, 55, 135, 40, 40, 40, 40, 40, 40]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Resumen Auxiliar VS Cuenta
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function resumenAuxiliarCuenta(headers: HeaderReport, response) {

  // Variables
  let items = [];

  let totalGeneralMN = 0;
  let totalGeneralME = 0;

  response.forEach(auxiliar => {
    items.push([
      new Cell(
        new Txt(`Auxiliar :     ${auxiliar.auxiliar_code}`).bold().fontSize(8).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt(auxiliar.auxiliar_descri).bold().fontSize(8).alignment('center').end
      ).border(noBorders).colSpan(3).end,
      new Cell(
        new Txt("").bold().fontSize(8).alignment('center').end
      ).end,
      new Cell(
        new Txt("").bold().fontSize(8).alignment('center').end
      ).end,
    ])

    let totalAuxiliarMN = 0;
    let totalAuxiliarME = 0;

    auxiliar.detalle.forEach(cuenta => {
      let totalSaldoMN = 0;
      let totalSaldoME = 0;

      cuenta.detalle.forEach(item => {
        totalSaldoMN += item.importe_mn;
        totalSaldoME += item.importe_me;
      });

      items.push([
        new Cell(
          new Txt(cuenta.cuenta).fontSize(8).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(cuenta.descri).fontSize(8).alignment('left').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalSaldoMN.toFixed(2))).fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalSaldoME.toFixed(2))).fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ])

      totalAuxiliarMN += totalSaldoMN;
      totalAuxiliarME += totalSaldoME;
    });

    items.push([
      new Cell(
        new Txt("").fontSize(8).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt("TOTAL AUXILIAR: 75481414").bold().fontSize(8).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalAuxiliarMN.toFixed(2))).bold().fontSize(8).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totalAuxiliarME.toFixed(2))).bold().fontSize(8).alignment('right').end
      ).border(topBorder).end,
    ])

    totalGeneralMN += totalAuxiliarMN;
    totalGeneralME += totalAuxiliarME;

  });

  items.push([
    new Cell(
      new Txt("").fontSize(8).alignment('center').end
    ).border(noBorders).end,
    new Cell(
      new Txt("").bold().fontSize(8).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totalGeneralMN.toFixed(2))).bold().fontSize(8).alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totalGeneralME.toFixed(2))).bold().fontSize(8).alignment('right').end
    ).border(topBorder).end,
  ],)

  // Diseños
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt("").end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).end
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt("Resumen por Auxiliar vs Cuenta").alignment('center').bold().fontSize(12).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").alignment('right').fontSize(10).end).border(noBorders).end
      ],
      [
        new Cell(new Txt("").end).border(noBorders).end,
        new Cell(
          new Stack([

            new Txt(`Acumulado hasta: ${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,

          ]).end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").alignment('left').end).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("Cuenta").bold().fontSize(8).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Descripción de la Operación").bold().fontSize(8).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("M.N. Saldo").bold().fontSize(8).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("M.N. Saldo").bold().fontSize(8).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],
      ...items,
    ]).headerRows(4).widths([100, 230, 95, 95]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Resumen Cuenta VS Documento
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function resumenCuentaDocumento(headers: HeaderReport, response) {
  // Variables
  let items = [];

  let totalGeneralMN = 0;
  let totalGeneralME = 0;

  response.forEach(auxiliar => {
    items.push([
      new Cell(
        new Txt(`Auxiliar :     ${auxiliar.auxiliar_code}`).bold().fontSize(8).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt(auxiliar.auxiliar_descri).bold().fontSize(8).alignment('center').end
      ).border(noBorders).colSpan(3).end,
      new Cell(
        new Txt("").bold().fontSize(8).alignment('center').end
      ).end,
      new Cell(
        new Txt("").bold().fontSize(8).alignment('center').end
      ).end,
    ])

    let totalMN = 0;
    let totalME = 0;

    auxiliar.detalle.forEach(item => {
      items.push([
        new Cell(
          new Txt(`${item.tdo_codtdo}    ${item.ldi_docref}`).fontSize(8).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.LDC_FECDIA.substring(0, 10)}       ${item.LDI_GLOLDI}      ${item.cco_codcco}`).fontSize(8).alignment('left').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(item.LDI_IMPNAC.toFixed(2))).fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(item.LDI_IMPMEX.toFixed(2))).fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ])

      totalMN += item.LDI_IMPNAC;
      totalME += item.LDI_IMPMEX;
    });

    items.push([
      new Cell(
        new Txt("").fontSize(8).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt(`TOTAL AUXILIAR:     ${auxiliar.auxiliar_code}`).bold().fontSize(8).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalMN.toFixed(2))).bold().fontSize(8).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totalME.toFixed(2))).bold().fontSize(8).alignment('right').end
      ).border(topBorder).end,
    ])

    totalGeneralMN += totalMN;
    totalGeneralME += totalME;

  });

  items.push([
    new Cell(
      new Txt(`TOTAL DE LA CUENTA:     ${response[0].cuenta}`).bold().fontSize(8).alignment('right').end
    ).colSpan(2).border(noBorders).end,
    new Cell(
      new Txt("").bold().fontSize(8).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totalGeneralMN.toFixed(2))).bold().fontSize(8).alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totalGeneralME.toFixed(2))).bold().fontSize(8).alignment('right').end
    ).border(topBorder).end,
  ])

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt("").end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).end
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt("Resumen por Cuenta vs Documento").alignment('center').bold().fontSize(12).end).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).end
      ],
      [
        new Cell(new Txt("").end).border(noBorders).end,
        new Cell(
          new Stack([

            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,

          ]).end,
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(new Txt("").alignment('left').end).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("Cuenta").bold().fontSize(8).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Descripción de la Operación").bold().fontSize(8).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("M.N. Saldo").bold().fontSize(8).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("M.N. Saldo").bold().fontSize(8).alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],

      ...items

    ]).headerRows(4).widths([100, 230, 95, 95]).end
  )

  pdf.create().open();

}

/**
 * Reporte Libro Ingreso y Gasto
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function libroingresoygastosReport(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("FORMATO 2.2: LIBRO DE INGRESOS Y GASTOS - RENTAS DE CUARTA CATEGORÍA").fontSize(12).bold().end
        ).colSpan(12).border(noBorders).end,
        ...obtenerNulls(11),
      ],
      [
        new Cell(
          new Txt(`${headers.year} ${headers.month}`).fontSize(10).bold().end
        ).colSpan(12).border(noBorders).end,
        ...obtenerNulls(11),
      ],
      [
        new Cell(
          new Txt("FECHA DE COBRO, INGRESO O PUESTA A DISPOSICIÓN").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("FECHA DE EMISIÓN (dd/mm/aaaa)").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("INCISO APLICABLE (1)").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("COMPROBANTE DE PAGO EMITIDO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("USUARIO DEL SERVICIO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("RENTA BRUTA").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("IMPUESTO A LA RENTA RETENIDO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("OTRAS RETENCIONES").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("MONTO COBRADO, INGRESADO O PUESTO A DISPOSICIÓN").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end
      ],
      [
        ...obtenerNulls(3),
        new Cell(
          new Txt("SERIE").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("NÚMERO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("TIPO DE DOCUMENTO (TABLA 2)").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Nro. DE DOCUMENTO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓ SOCIAL").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("06/12/2021").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("06/12/2021").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("02").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("E001").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("000006").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("06").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10104709121").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("PACO SOLIMANO LUIS").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("06/12/2021").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("06/12/2021").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("02").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("E001").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("000006").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("06").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10104709121").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("PACO SOLIMANO LUIS").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("06/12/2021").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("06/12/2021").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("02").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("E001").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("000006").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("06").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10104709121").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("PACO SOLIMANO LUIS").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("06/12/2021").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("06/12/2021").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("02").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("E001").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("000006").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("06").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("10104709121").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("PACO SOLIMANO LUIS").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(6).end
        ).border(openRightBorder).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("TOTALES").fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(6).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("5,000.00").alignment('right').fontSize(6).end
        ).border(openRightBorder).end,
      ]
    ]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Cuenta 10
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function cuenta10Report(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })


  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("FORMATO 3.2: LIBRO DE INVENTARIOS Y BALANCES - DETALLE DEL SALDO CUENTA 10 - CAJA Y BANCOS").fontSize(12).bold().end,
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      [
        new Cell(
          new Txt("PERÍODO").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.year}`).fontSize(8).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("RUC").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.client}`).fontSize(8).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("DENOMINACIÓN").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.clientName}`).fontSize(8).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("CUENTA").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("REFERENCIA DE LA CUENTA").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("SALDO CONTABLE FINAL").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Código").fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Denominación").fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Entidad Financiera").fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Número de la Cuenta").fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Moneda").fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Deudor").fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Acreedor").fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(
          new Txt("102144").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("BCO DE CRÉDITO DEL PERU MN CTA C 02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("191-2551805-02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("119.114.91").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("102144").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("BCO DE CRÉDITO DEL PERU MN CTA C 02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("191-2551805-02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("119.114.91").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("102144").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("BCO DE CRÉDITO DEL PERU MN CTA C 02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("191-2551805-02").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("1").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("119.114.91").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTALES").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("119.114.91").fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(topBorder).end,
      ],
    ]).headerRows(6).widths([80, 210, 120, 120, 60, 70, 70]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Cuenta 12
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function cuenta12Report(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("FORMATO 3.3: LIBRO DE INVENTARIOS Y BALANCES DETALLES DEL SALDO CUENTA 12 - CLIENTES").fontSize(12).bold().end
        ).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("PERÍODO").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.year}`).fontSize(8).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt("RUC").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.client}`).fontSize(8).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt("DENOMINACIÓN").bold().fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${headers.clientName}`).fontSize(8).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt("INFORMACIÓN DEL CLIENTE").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("MONTO DE LA CUENTA POR COBRAR").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(3).end,
        new Cell(
          new Txt("FECHA DE EMISIÓN - DEL COMPROBANTE DE PAGO").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(3).end,
      ],
      [
        new Cell(
          new Txt("DOCUMENTO DE IDENTIDAD").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("DENOMINACIÓN O RAZÓN SOCIAL").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("TIPO (TABLA 2)").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("NÚMERO").alignment('center').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("121 Facturas, Boletas y otros Comprobantes por Cobrar").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("1212 Facturas Emitidas en Cartera").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
      ],

      [
        new Cell(
          new Txt("06").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20565212541").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("A & S AVIATON PACIFIC S.A.C. OCC DC 185").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-14.00").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("25/04/2017").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("SUBTORAL").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("-14.00").fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ],

      [
        new Cell(
          new Txt("06").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20565212541").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("A & S AVIATON PACIFIC S.A.C. OCC DC 185").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-14.00").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("25/04/2017").fontSize(8).end
        ).border(noBorders).end,
      ],

      [
        new Cell(
          new Txt("06").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20565212541").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("A & S AVIATON PACIFIC S.A.C. OCC DC 185").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-14.00").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("25/04/2017").fontSize(8).end
        ).border(noBorders).end,
      ],

      [
        new Cell(
          new Txt("06").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20565212541").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("A & S AVIATON PACIFIC S.A.C. OCC DC 185").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-14.00").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("25/04/2017").fontSize(8).end
        ).border(noBorders).end,
      ],

      [
        new Cell(
          new Txt("06").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20565212541").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("A & S AVIATON PACIFIC S.A.C. OCC DC 185").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("-14.00").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("25/04/2017").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("SUBTORAL").fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("-14.00").fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").fontSize(8).end
        ).border(openRightBorder).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("SALDO FINAL TOTAL").fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("-14.00").fontSize(8).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(openRightBorder).end,
      ],
    ]).widths([100, 100, 350, 100, 100]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Genera pdf contabilidad
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function generarPdfContabilidad(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageSize({ width: 1200, height: 400 })
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })


  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt(headers.date).fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("RCOM_REP_DET_VE").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("INFORME CONTABILIDAD TEMPLATE").fontSize(12).bold().alignment('center').end
        ).end
      ]
    ]).layout('noBorders').widths([1140]).end
  );


  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("Cliente").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("TD").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Número").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Fecha").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Línea").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cod. Pro.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Producto").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cantidad").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Precio").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Moneda").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Importe").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Usuario").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Referencia").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Pedido").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Guía Remisión").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cond. Pago").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Vendedor").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Motivo").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Ind. Guía").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Almacén").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Costo Prod.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Departamento").fontSize(8).bold().alignment('center').end
        ).end
      ]
    ]).widths([80, 20, 30, 30, 40, 50, 80, 40, 30, 30, 30, 60, 80, 40, 50, 30, 80, 30, 30, 30, 30, 30]).end
  );

  pdf.add(
    new Table(this.dataDetalleFacturacion).layout('noBorders').widths([85, 20, 35, 30, 40, 50, 83, 43, 30, 30, 30, 60, 80, 40, 55, 30, 80, 30, 30, 30, 30, 30]).end
  );

  pdf.create().open();

}

/**
 * Reporte de Conciliación Bancaria
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function reporteConciliacionBancaria(headers: HeaderReport, response) {

  // Variable
  let items = [];

  let totalGeneralDebe = 0;
  let totalGeneralHaber = 0;

  response.forEach(cuenta => {

    let saldoInicialDebe = ((cuenta.DEBE_INI_S - cuenta.HABER_INI_S) > 0) ? cuenta.DEBE_INI_S - cuenta.HABER_INI_S : 0
    let saldoInicialHaber = ((cuenta.DEBE_INI_S - cuenta.HABER_INI_S) < 0) ? Math.abs(cuenta.DEBE_INI_S - cuenta.HABER_INI_S) : 0
    items.push(
      [
        new Cell(
          new Txt(`Entidad Bancaria        ${cuenta.descri}`).bold().fontSize(8).end
        ).border(noBorders).colSpan(13).end,
        ...obtenerNulls(12),
      ],
      [
        new Cell(
          new Txt(`Nro. Cuenta:        ${cuenta.cuenta}               Tipo Moneda:        ${headers.currency}`).bold().fontSize(8).end
        ).border(noBorders).colSpan(13).end,
        ...obtenerNulls(12),
      ], [
      new Cell(
        new Txt("SALDO INICIAL").bold().fontSize(7).alignment('right').end
      ).colSpan(9).border(noBorders).end,
      ...obtenerNulls(8),
      new Cell(
        new Txt(numberWithCommas(saldoInicialDebe.toFixed(2))).fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoInicialHaber.toFixed(2))).fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).alignment('right').end
      ).border(noBorders).end
    ])

    let montoCambiante = (saldoInicialDebe == 0) ? saldoInicialHaber : saldoInicialDebe;

    let totalDebe = 0;
    let totalHabe = 0;

    cuenta.detalle.forEach(element => {

      let montoDebe = (element.LDI_INDDHA == "D") ? element.LDI_IMPNAC : 0;
      let montoHaber = (element.LDI_INDDHA == "H") ? element.LDI_IMPNAC : 0;

      totalDebe += montoDebe;
      totalHabe += montoHaber;

      // let valorCambiante = (item.LDI_INDDHA == "D")? (montoCambiante + item.LDI_IMPNAC): (montoCambiante - item.LDI_IMPNAC);
      if (element.LDI_INDDHA == "D") {
        montoCambiante += element.LDI_IMPNAC
      } else {
        montoCambiante -= element.LDI_IMPNAC
      }

      items.push([
        new Cell(
          new Txt(element.CCN_CODCCN).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.LDC_CORLDC).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.LDI_CORLDI).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt((element.LDC_FECDIA) ? element.LDC_FECDIA.substring(0, 10) : "").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.CLI_CODCLI).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.CLI_NOMCLI).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.TDO_CODTDO).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.LDI_DOCREF).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.LDI_GLOLDI).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(Math.abs(montoDebe).toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(Math.abs(montoHaber).toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(Math.abs(montoCambiante).toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1    2020 01").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ])
    });

    let saldoFinalDebe = (saldoInicialDebe + totalDebe - totalHabe > 0) ? saldoInicialDebe + totalDebe - totalHabe : 0;
    let saldoFinalHaber = (saldoInicialDebe + totalDebe - totalHabe < 0) ? Math.abs(saldoInicialDebe + totalDebe - totalHabe) : 0;

    items.push([
      new Cell(
        new Txt("SALDO SEGUN BANCO").bold().fontSize(7).alignment('right').end
      ).border(noBorders).colSpan(11).end,
      ...obtenerNulls(10),
      new Cell(
        new Txt(numberWithCommas(montoCambiante.toFixed(2))).fontSize(7).alignment('right').bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).bold().end
      ).border(noBorders).end,
    ],
      [
        new Cell(
          new Txt("").bold().fontSize(7).alignment('right').end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt(`MOVIMIENTO DEL MES CUENTA: ${cuenta.cuenta}`).fontSize(7).alignment('right').bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(saldoFinalDebe.toFixed(2))).fontSize(7).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(saldoFinalHaber.toFixed(2))).fontSize(7).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").bold().fontSize(7).alignment('right').end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("MOVIMIENTO ACUMULADO DE LA CUENTA:").fontSize(7).alignment('right').bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(montoCambiante.toFixed(2))).fontSize(7).alignment('right').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").fontSize(7).alignment('right').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").bold().fontSize(7).alignment('right').end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("SALDO DISPONIBLE:").fontSize(7).alignment('right').bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(montoCambiante.toFixed(2))).fontSize(7).alignment('right').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
      ],)
  });

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).colSpan(4).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").end).colSpan(5).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).colSpan(3).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").fontSize(8).end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("Conciliación Bancaria").alignment('center').bold().fontSize(12).end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('right').fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").end).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').bold().fontSize(10).end
          ]).end,
        ).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('left').end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Subd.").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Nro. Asie.").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("It.").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fecha").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Doc. Aux.").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Auxiliar").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Doc.").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Nro. Doc.").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Glosa").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ingresos").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Egresos").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Per. Conciliado").bold().fontSize(7).alignment('center').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(
          new Txt("CONCILIACIONES").bold().fontSize(8).end
        ).colSpan(13).border(noBorders).end,
        ...obtenerNulls(12),
      ],
      ...items,
      [
        new Cell(
          new Txt("NO CONCILIACIONES").bold().fontSize(8).end
        ).colSpan(13).border(noBorders).end,
        ...obtenerNulls(12),
      ],
      [
        new Cell(
          new Txt("1701").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("00001").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("000001").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20/04/2021").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20121212121").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("LATINOS PERU").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TRB").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("WN3885").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TRANSFERENCIAS ENTRE CUENTAS").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20,000.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("20,000.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("1    2020 01").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -").fontSize(14).end
        ).border(noBorders).colSpan(13).end,
        ...obtenerNulls(12),
      ],
      [
        new Cell(
          new Txt("").bold().fontSize(7).alignment('right').end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("MOVIMIENTO DEL MES CUENTA: 104105").fontSize(7).alignment('right').bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("120.00").fontSize(7).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("0.00").fontSize(7).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").bold().fontSize(7).alignment('right').end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("MOVIMIENTO ACUMULADO DE LA CUENTA:").fontSize(7).alignment('right').bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("120.00").fontSize(7).alignment('right').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").fontSize(7).alignment('right').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").bold().fontSize(7).alignment('right').end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("SALDO DISPONIBLE:").fontSize(7).alignment('right').bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("120.00").fontSize(7).alignment('right').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).bold().end
        ).border(noBorders).end,
      ],
    ]).headerRows(4).widths([22, 20, 25, 38, 45, 100, 30, 50, 140, 50, 50, 50, 65]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Movimiento de Caja y Banco Saldo Inicial
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function movimientoCajaBancoSaldoInicial(headers: HeaderReport, response) {

  // Variable
  let items = [];

  let totalImporteGeneralSoles = 0;
  let totalImporteGeneralDolares = 0;

  response.forEach(element => {

    let ultimoElemento = element.detalle[element.detalle.length - 1];

    let saldoInicial = 0;


    if (ultimoElemento.tmo_codtmo == "DO") {
      saldoInicial = ((ultimoElemento.scu_debmex - ultimoElemento.scu_habmex) > 0) ? ultimoElemento.scu_debmex - ultimoElemento.scu_habmex : 0;

    } else {

      saldoInicial = ((ultimoElemento.scu_debnac - ultimoElemento.scu_habnac) > 0) ? ultimoElemento.scu_debnac - ultimoElemento.scu_habnac : 0;
    }

    items.push([
      new Cell(
        new Txt("BANCO:").bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(`${element.codigo}   ${element.descri} ${element.cuenta}      `).end
      ).fontSize(7).colSpan(7).bold().border(noBorders).end,
      ...obtenerNulls(6),
      new Cell(
        new Txt("SALDO INICIAL:").bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.moneda).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(saldoInicial.toFixed(2))).fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt("").bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").bold().fontSize(7).end
      ).border(noBorders).end,
    ],)

    let montoCambiante = saldoInicial;

    let totalImporte = 0;

    element.detalle.forEach(item => {

      montoCambiante = montoCambiante + item.pba_impsol;

      totalImporte += item.pba_impsol;

      items.push([
        new Cell(
          new Txt(`${item.TDO_CODTDO}/${item.LDI_DOCREF}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.pba_fecord) ? item.pba_fecord.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.pba_fecche) ? item.pba_fecche.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.SCO_CODSCO} ${item.LDC_CORLDC}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.CLI_CODCLI).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cli_nomcli).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.TDO_CODTDO + ' ' + item.PBA_NUMDOC).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt("351.010.0447").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.PBA_GLOPBA).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tmo_codtmo).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(item.pba_impsol.toFixed(2))).fontSize(6).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(montoCambiante.toFixed(2))).fontSize(6).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.pba_indsta == "1") ? "VIGENTE" : "CANCELADO").fontSize(6).alignment('center').end
        ).border(noBorders).end
      ],)

    });

    if (element.moneda == "SO") {
      totalImporteGeneralSoles += totalImporte;

    } else {
      totalImporteGeneralDolares += totalImporte;
    }

    items.pop();

    items.push([
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("Nro. Doc.    7").fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt((element.moneda == "DO") ? "US$" : "S/.").fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totalImporte.toFixed(2))).fontSize(6).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(montoCambiante.toFixed(2))).color("blue").fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end
    ],)
  });

  items.push([
    new Cell(
      new Txt("").fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTAL GENERAL").fontSize(7).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt("US$").fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(totalImporteGeneralDolares.toFixed(2)).bold().fontSize(7).alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(7).alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end
  ],
    [
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("S/.").fontSize(8).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalImporteGeneralSoles.toFixed(2)).bold().fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end
    ])
  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).colSpan(4).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).colSpan(5).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).colSpan(3).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(new Txt("").fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Movimientos de Caja y Bancos con Saldo Inicial").alignment('center').bold().fontSize(12).end).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
        new Cell(new Txt("7").alignment('right').fontSize(8).end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(new Txt("").end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`${headers.year} ${headers.month}`).alignment('center').bold().fontSize(10).end,
            new Txt(`${headers.currency}`).alignment('center').bold().fontSize(10).end
          ]).end,
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
        new Cell(new Txt("").alignment('left').end).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt("Documento").fontSize(7).alignment('center').bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("F. Emisión").fontSize(7).alignment('center').bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("F. Cheque").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Voucher").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Doc. Aux.").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Auxiliar").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Doc.").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Referencia").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Descripción").fontSize(7).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("M").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Importe").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Saldo").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Estado").fontSize(7).bold().alignment('center').end
        ).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
      ],
      ...items,



    ]).headerRows(4).widths([50, 35, 35, 40, 45, 120, 45, 50, 120, 15, 40, 40, 50]).end
  )

  pdf.create().open();

}

/**
 * Reporte DAOT Ingresos
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function reporteDaotIngresos(headers: HeaderReport, response) {

  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).end,
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Reporte DAOT Ingresos").alignment('center').bold().fontSize(12).end).border(noBorders).end,
        new Cell(new Txt("").alignment('right').fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`${headers.year}`).alignment('center').bold().fontSize(10).end,
            new Txt("").alignment('center').bold().fontSize(10).end
          ]).end,
        ).border(noBorders).end,
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Nro.").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(openRightBorder).end,
        new Cell(
          new Txt("Tip. Pers.").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Coc. Proveedor").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Coc. Nombre o Razón").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Nro. Doc.").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Importe Soles").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Importe Dólares").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(openLeftBorder).end,
      ],
      [
        new Cell(
          new Txt("1").fontSize(7).end
        ).border(noBorders).alignment('center').end,
        new Cell(
          new Txt("J").fontSize(7).end
        ).border(noBorders).alignment('center').end,
        new Cell(
          new Txt(`${headers.client}`).fontSize(7).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt("CENCOSUD RETAIL PERU S.A.").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20141525263").fontSize(7).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt("254,145.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("65,478.20").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("TOTAL GENERAL").fontSize(7).bold().end
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Txt("745,784.00").fontSize(7).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("213,784.00").fontSize(7).bold().alignment('right').end
        ).border(topBorder).end
      ]
    ]).widths([40, 55, 90, 280, 90, 90, 90]).end
  )
  pdf.create().open();

}

/**
 * Reporte DAOT Costos
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function reporteDaotCostos(headers: HeaderReport, response) {

  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).colSpan(3).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).end,
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).colSpan(3).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Reporte DAOT Costos").alignment('center').bold().fontSize(12).end).border(noBorders).end,
        new Cell(new Txt("7").alignment('right').fontSize(8).end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`${headers.year}`).alignment('center').bold().fontSize(10).end,
            new Txt("").alignment('center').bold().fontSize(10).end
          ]).end,
        ).border(noBorders).end,
        new Cell(new Txt("").alignment('left').end).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Nro.").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(openRightBorder).end,
        new Cell(
          new Txt("Tip. Pers.").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Cod. Proveedor").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Cod. Nombre o Razón").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Nro. Doc.").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Importe Soles").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(horizontalBorder).end,
        new Cell(
          new Txt("Importe Dólares").bold().fontSize(8).alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(openLeftBorder).end,
      ],
      [
        new Cell(
          new Txt("1").fontSize(7).end
        ).border(noBorders).alignment('center').end,
        new Cell(
          new Txt("J").fontSize(7).end
        ).border(noBorders).alignment('center').end,
        new Cell(
          new Txt(`${headers.client}`).fontSize(7).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt("CENCOSUD RETAIL PERU S.A.").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20141525263").fontSize(7).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt("254,145.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("65,478.20").fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("TOTAL GENERAL").fontSize(7).bold().alignment('right').end
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Txt("745,784.00").fontSize(7).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("213,784.00").fontSize(7).bold().alignment('right').end
        ).border(topBorder).end
      ]
    ]).widths([40, 55, 90, 280, 90, 90, 90]).end
  )
  pdf.create().open();

}

/**
 * Reporte de Consolidado Compras Locales
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function reporteConsolidadoComprasLocales(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt(headers.date).alignment('right').fontSize(8).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Consolidado de Compras Locales - Almacén - Contabilidad").alignment('center').bold().fontSize(12).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt("7").alignment('right').fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Fecha Almacén: 01/01/2020     Fecha Reg. Compra: 01/01/2020 al 01/01/2020`).alignment('center').bold().fontSize(10).end,
          ]).end,
        ).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Nro. Orden Compra").alignment('center').fontSize(8).bold().end
        ).border(openRightBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fecha").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("RUC").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Razón Social").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Mon. O.C.").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Imp. OCS/IGV").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe $ Reg. Compra").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe S/. Reg. Compra").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe $ Almacén").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).fillColor("#0beda5").color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe S/. Almacén").alignment('center').fontSize(8).bold().end
        ).border(horizontalBorder).fillColor("#0beda5").color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe Diferencia").alignment('center').fontSize(8).bold().end
        ).border(openRightBorder).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(
          new Txt("L20208383874").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("03/01/2020").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20141252632").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DRESDEN FOOD INGREDIENTES S.A.").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DO").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("415.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("L20208383874").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("03/01/2020").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20141252632").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DRESDEN FOOD INGREDIENTES S.A.").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DO").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("415.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("L20208383874").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("03/01/2020").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20141252632").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DRESDEN FOOD INGREDIENTES S.A.").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DO").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("415.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("L20208383874").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("03/01/2020").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20141252632").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DRESDEN FOOD INGREDIENTES S.A.").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DO").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("415.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("L20208383874").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("03/01/2020").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("20141252632").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DRESDEN FOOD INGREDIENTES S.A.").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("DO").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("415.00").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("416.00").fontSize(7).alignment('right').end
        ).border(noBorders).fillColor("#0beda5").end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ]
    ]).headerRows(4).widths([70, 45, 55, 130, 30, 50, 70, 70, 60, 60, 60]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Plan de Cuentas
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function planDeCuentas(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  // variables
  let now = new Date();

  // detalle
  let detalle = [];

  response.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.ccn_codccn).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_descri).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_indsta == "1" ? "ACTIVO" : "CANCELADO").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_tipccn == 1 ? "SI" : "NO").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_indccs == 1 ? "SI" : "NO").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_indana == 1 ? "SI" : "NO").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_inddes == 1 ? "SI" : "NO").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_indcie == 1 ? "SI" : "NO").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_indpls == 1 ? "SI" : "NO").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.tmo_codtmo).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("01/01/1900").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("NINGUNO").fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(6).end
      ).border(noBorders).end,
    ],)
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(8).end,
        ).border(noBorders).colSpan(14).end,

        ...obtenerNulls(13),
        new Cell(
          new Txt(formatDateWithSlash(now)).fontSize(8).alignment('right').end,
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt(headers.client).fontSize(8).bold().end,
        ).colSpan(14).border(noBorders).end,
        ...obtenerNulls(13),
        new Cell(
          new Txt("").fontSize(9).bold().end,
        ).colSpan(4).alignment('right').border(noBorders).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Stack([
            new Txt("Plan Contable").alignment('center').bold().end,
            new Txt("\n").end,
          ]).alignment('center').end
        ).colSpan(18).border(noBorders).end,
        ...obtenerNulls(17),
      ],
      [
        new Cell(
          new Txt("Cuenta").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Descripción").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Estado").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Indicadores").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt("Mo.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tipo de destino").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Cta. Debe").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Cta. Transf.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Cta. Cierre").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tipo de Ajuste").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tipo de Cambio").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tipo de Cuenhta").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Flujo Caja Sugeri.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Mov.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CeCo.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Analit.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Destin.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cierr.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Plls.").fontSize(7).bold().end
        ).alignment('center').color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("").fontSize(8).bold().end
        ).colSpan(9).end,
        ...obtenerNulls(8),
      ],

      ...detalle,
    ]).headerRows(5).widths([30, 135, 30, 20, 20, 22, 24, 20, 15, 15, 40, 40, 40, 40, 40, 35, 35, 40]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Registro de Compras
 * @param response from service
 * @param data another data
 */
export function registroDeComprasReport(headers, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 25, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })
  // Detalle
  let detalle = [];

  let [totGenAfe, totGenIgv, totGenIna, totGenIna2, totGenDoc] = [0, 0, 0, 0, 0]
  response.forEach(item => {
    detalle.push([
      new Cell(
        new Txt(`${item.sco_codsco} ${item.sco_descri}`).fontSize(6).end
      ).colSpan(29).border(noBorders).end,
      ...obtenerNulls(28),
    ],)

    let [totAfe, totIgv, totIna, totIna2, totDoc] = [0, 0, 0, 0, 0];

    item.detalle.forEach(item1 => {
      const impAfe = item1.tmo_codtmo == "SO" ? item1.cco_impafe_mn : item1.cco_impafe_me;
      const impIgv = item1.tmo_codtmo == "SO" ? item1.cco_impigv_mn : item1.cco_impigv_me;
      const impIna = item1.tmo_codtmo == "SO" ? item1.cco_impina_mn : item1.cco_impina_me;
      const impIna2 = item1.tmo_codtmo == "SO" ? item1.cco_impina7018_mn : item1.cco_impina7018_me;
      const impDoc = item1.tmo_codtmo == "SO" ? item1.cco_impdoc_mn : item1.cco_impdoc_me;

      totAfe += impAfe;
      totIgv += impIgv;
      totIna += impIna;
      totIna2 += impIna2;
      totDoc += impDoc;

      detalle.push([
        new Cell(
          new Txt(item1.ldc_corldc).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item1.cco_fecemi)).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item1.cco_fecven)).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.tdo_codofi).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.serie).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.cco_numdoc).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.t02_coddoc).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.cli_codcli).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.cli_nomcli).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impAfe.toFixed(2))).fontSize(4).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impIgv.toFixed(2))).fontSize(4).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impIna.toFixed(2))).fontSize(4).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impIna2.toFixed(2))).fontSize(4).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(4).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(impDoc.toFixed(2))).fontSize(4).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.dpc_depdet).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item1.dpc_fecdet)).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.cco_tipcam).fontSize(4).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item1.cco_fecref)).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.tdo_docref).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.serieref).fontSize(4).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item1.cco_numref).fontSize(4).end
        ).border(noBorders).end,
      ],)
    });

    detalle.push([
      new Cell(
        new Txt("").end
      ).border(noBorders).colSpan(10).end,
      ...obtenerNulls(9),
      new Cell(
        new Txt(numberWithCommas(totAfe.toFixed(2))).fontSize(4).alignment('right').end
      ).border(topBorder).bold().end,
      new Cell(
        new Txt(numberWithCommas(totIgv.toFixed(2))).fontSize(4).alignment('right').end
      ).border(topBorder).bold().end,
      new Cell(
        new Txt("").end
      ).border(noBorders).colSpan(4).end,
      ...obtenerNulls(3),
      new Cell(
        new Txt(numberWithCommas(totIna.toFixed(2))).fontSize(4).alignment('right').end
      ).border(topBorder).bold().end,
      new Cell(
        new Txt(numberWithCommas(totIna2.toFixed(2))).fontSize(4).alignment('right').end
      ).border(topBorder).bold().end,
      new Cell(
        new Txt("").end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(totDoc.toFixed(2))).fontSize(4).alignment('right').end
      ).border(topBorder).bold().end,
      new Cell(
        new Txt("").end
      ).border(noBorders).colSpan(8).end,
      ...obtenerNulls(7),
    ],)

    totGenAfe += totAfe;
    totGenIgv += totIgv;
    totGenIna += totIna;
    totGenIna2 += totIna2;
    totGenDoc += totDoc;
  });

  detalle.push([
    new Cell(
      new Txt("").end
    ).border(openRightBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("TOTALES").fontSize(4).bold().alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(totGenAfe.toFixed(2))).fontSize(4).alignment('right').end
    ).border(horizontalBorder).bold().end,
    new Cell(
      new Txt(numberWithCommas(totGenIgv.toFixed(2))).fontSize(4).alignment('right').end
    ).border(horizontalBorder).bold().end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(totGenIna.toFixed(2))).fontSize(4).alignment('right').end
    ).border(horizontalBorder).bold().end,
    new Cell(
      new Txt(numberWithCommas(totGenIna2.toFixed(2))).fontSize(4).alignment('right').end
    ).border(horizontalBorder).bold().end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(totGenDoc.toFixed(2))).fontSize(4).alignment('right').end
    ).border(horizontalBorder).bold().end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(openRightBorder).end,
  ])
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt("LATINOS").fontSize(5).alignment('left').end,
            new Txt("25415263254").fontSize(5).alignment('left').end,
            new Txt("RCON_ASD_ASDSD").fontSize(5).alignment('left').end,
          ]).end
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt("").fontSize(5).end,
            new Txt("REGISTRO DE COMPRAS POR COMPROBANTE").bold().fontSize(8).alignment('center').end,
            new Txt("En Nuevos Soles").bold().fontSize(6).alignment('center').end,
            new Txt(`${headers.yaer} ${headers.month}`).bold().fontSize(6).alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(19).end,
        ...obtenerNulls(18),
        new Cell(
          new Stack([
            new Txt("11/05/2021    05:12:00").alignment('right').fontSize(5).end,
            new Txt("1").fontSize(5).alignment('right').end,
            new Txt("").fontSize(5).end,
          ]).end
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("NÚMERO CORRELATIVO DEL REGISTRO O CÓDIGO ÚNICO DE LA OPERACIÓN").bold().fontSize(4).alignment('center').margin([0, 50, 0, 0]).end
        ).rowSpan(3).end,
        new Cell(
          new Txt("FECHA DE EMISIÓN DEL COMPROBANTE DE PAGO O DOCUMENTO").bold().fontSize(4).alignment('center').margin([0, 55, 0, 0]).end
        ).rowSpan(3).end,
        new Cell(
          new Txt("FECHA DE VCTO O FECHA DE PAGO").bold().fontSize(4).alignment('center').margin([0, 60, 0, 0]).end
        ).rowSpan(3).end,
        new Cell(
          new Txt("COMPROBANTE DE PAGO O DOCUMENTO").bold().fontSize(4).alignment('center').margin([0, 15, 0, 0]).end
        ).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("INFORMACIÓN DEL PROVEEDOR").bold().fontSize(4).alignment('center').end
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("ADQUISICIONES GRAVADAS DESTINADAS A OPERACIONES GRAVADAS Y/O DE EXPORTACIÓN").bold().fontSize(4).alignment('center').end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("ADQUISICIONES GRAVADAS DESTINADAS A OPERACIONES GRAVADAS Y/O DE EXPORTACIÓN Y A OPERACIONES NO GRAVADAS").bold().fontSize(4).alignment('center').end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("ADQUISICIONES GRAVADAS DESTINADAS A OPERACIONES NO").bold().fontSize(4).alignment('center').end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("VALOR DE LAS ADQUISICIONES NO GRAVADAS").bold().fontSize(4).alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("ISC").bold().fontSize(4).alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("IMPUESTO AL CONSUMO DE LAS BOLSAS DE PLÁSTICOS").bold().fontSize(4).alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("OTROS TRIBUTOS Y CARGOS").bold().fontSize(4).alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("IMPORTE TOTAL").bold().fontSize(4).alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("N° DE COMPROBANTE DE PAGO EMITIDO POR SUJETO NO DOMICILIADO (2)").bold().fontSize(4).alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("CONSTANCIA DE DEPÓSITO DE DETRACCIÓN (3)").bold().fontSize(4).alignment('center').end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("TIPO DE CAMBIO").bold().fontSize(4).alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("REFERENCIA DEL COMPROBANTE DE PAGO O DOCUMENTO ORIGINAL QUE SE MODIFICA").bold().fontSize(4).alignment('center').end
        ).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        ...obtenerNulls(3),
        new Cell(
          new Txt("TIPO (TABLA 10)").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("SERIE O CÓDIGO DE LA DEPENDENCIA ADUANERA (TABLA 11)").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("AÑO DE EMISIÓN DE LA DUA O DSI").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("N° DEL COMPROBANTE DE PAGO, DOCUMENTO, N° DE ORDEN DEL FORMULARIO FÍSICO O VIRTUAL, N° DUA, DSI O LIQQUIDACIÓN DE COBRANZA U OTROS DOCUMENTOS EMITIDOS POR SUNAT PARA ACREDITAR EL CRÉDITO FISCAL EN LA ").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("DOCUMENTO DE IDENTIDAD").bold().fontSize(4).alignment('center').end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("BASE IMPONIBLE").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("IGV").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("BASE IMPONIBLE").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("IGV").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("BASE IMPONIBLE").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("IGV").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("NÚMERO").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("FECHA DE EMISIÓN").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("FECHA").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("TIPO").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("SERIE").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("N° DEL COMPROBANTE DE PAGO O DOCUMENTO").bold().fontSize(4).alignment('center').end
        ).rowSpan(2).end,
      ],
      [
        ...obtenerNulls(7),
        new Cell(
          new Txt("TIPO (TABLA 2)").bold().fontSize(4).alignment('center').end
        ).end,
        new Cell(
          new Txt("NÚMERO").bold().fontSize(4).alignment('center').end
        ).end,
        ...obtenerNulls(20),
      ],
      ...detalle,
    ]).widths([20, 22, 22, 10, 10, 20, 25, 10, 25, 90, 19, 18, 12, 10, 12, 10, 17, 13, 15, 15, 18, 20, 20, 22, 13, 22, 10, 12, 20]).headerRows(4).end
  )

  pdf.create().open();

}
