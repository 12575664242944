import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { LineaNegocio } from "src/app/models/crm/linea-negocio";
import { ConfigurationService } from "src/app/services";
import { LineaNegocioService } from "src/app/services/api/crm/maestros/linea-negocio.service";

@Component({
  selector: 'linea-negocio-form',
  templateUrl: './linea-negocio-form.component.html',
  styleUrls: ['./linea-negocio-form.component.scss']
})
export class LineaNegocioFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  linea: LineaNegocio;

  uid: string;

  loading$: Subscription;
  lineaNegocio$: Subscription;
  sendForm$: Subscription;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _lineNegocioService: LineaNegocioService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>,
  ) {
    this.linea = new LineaNegocio();
    this.linea.codcia = this._configurationService.obtenerCompaniaCliente();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadData()
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {

      this.uid = id;

      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;


      this.obtenerLineaNegocio();
    })
  }

  obtenerLineaNegocio(): void {
    this.lineaNegocio$ = this._lineNegocioService.obtenerLineaNegocio(this.uid).subscribe(
      linea => this.linea = linea[0],
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    if(!this.formularioValido()) return ;

    this.loaderReg = true;
    this.uid == '0' ? this.registrarLineaNegocio(f): this.actualizarLineaNegocio(f);
  }

  /**
   * Método para registrar una nueva línea de negocio.
   * @param f
   */
  registrarLineaNegocio(f: NgForm): void {
    this.linea.coduse = this._configurationService.obtenerIdUsuario();

    this.sendForm$ = this._lineNegocioService.registrarLineaNegocio(this.linea).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.linea-negocio.success-store', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar los datos de una línea de negocio
   * @param f
   */
  actualizarLineaNegocio(f: NgForm): void {
    this.sendForm$ = this._lineNegocioService.actualizarLineaNegocio(this.linea).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.linea-negocio.success-update', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {
    if(!this.linea.indsta) {
      this._snackBarService.showError('Seleccionar la línea de negocio', 'Ok');
      return false;
    }

    return true;
  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/lineas-de-negocio']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.lineaNegocio$,
    ])
  }

}
