export class RequisitoCliente {
  codcia: string;
  numorc: string;
  fecreg: Date;
  desobj: string;
  plaent: number;
  indsta: string;
  fecupd: Date;
  coduse: string;
  codolc: string;
  indent: string;
  idtkt: string;
  idset: string;
  REGI_LIST_ORD: Array<RegiListOrd>

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
  }
}

export class RegiListOrd {
  codcia: string;
  numorc: string;
  codreq: string;
  desreq: string;
  corord: string;
  numofe: string;
  numite: string;
  pagnum: number;
  pagane: number;
  indter: number;
  observ: string;
  fecpro: Date;

  constructor() {}
}
