import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'subfamilia-list',
  templateUrl: './subfamilia-list.component.html',
  styleUrls: ['./subfamilia-list.component.scss']
})
export class SubFamiliaListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
