import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { Auth } from '../models/auth';
import { ConfigurationService } from './configuration.service';
import { UserClient } from '../models/user-client';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    token: any;

    constructor(
        private http: HttpClient,
        private _configurationService: ConfigurationService
    ) {
        this.cargarStorage()
    }

    /**
     *
     * @returns
     */
    estaLogueado() {
        if(localStorage.getItem('token')) {
            return ( localStorage.getItem('token').length > 5 ) ? true : false;
        }
    }

    /**
     *
     * @returns
     */
    estaIdentificado() {
      const client = this._configurationService.obtenerClienteId();
      const companyClient = this._configurationService.obtenerCompaniaCliente();
      const url = this._configurationService.obtenerUrl();
      if(client && companyClient && url) return true;

      return false

    }

    /**
     *
     * @returns
     */
    cargarStorage() {

      if ( localStorage.getItem('token')) {
        this.token = localStorage.getItem('token');
        return
      }

      this.token = '';

    }

    getIdentity() {
        return JSON.parse(localStorage.getItem('user'));
    }

    getUsuarioSistema() {
      return JSON.parse(localStorage.getItem('user'))['usuariosistema'];
    }

    login(user: Auth): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}login/`, user);
    }

    logout() {
      localStorage.clear();
    }

    verificarUsuarioCliente(payload: UserClient): Observable<any> {

      return this.http.post<any>(
        `${this._configurationService.obtenerUrl()}/api/usuario/verificar-cliente`,
        payload
      );

    }
}
