<app-pavso-title-section [title]="'Configuración de documentos con control'" [module]="'Contabilidad'">
</app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todas las configuraciones" url="/modulo-contabilidad/configuracion-control-cta-cte"
      entity="" [params]="['0']"></table-title-action>

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
            <div class="pav-btns-container">
              <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-contabilidad/configuracion-control-cta-cte', row.tipo_documento]">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="clase">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Clase </th>
          <td mat-cell *matCellDef="let row" data-label="Clase" class="td-responsive"> {{row.clase}} </td>
        </ng-container>

        <ng-container matColumnDef="tipo_documento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento </th>
          <td mat-cell *matCellDef="let row" data-label="Tipo Documento" class="td-responsive"> {{row.tipo_documento}}
          </td>
        </ng-container>

        <ng-container matColumnDef="signo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Signo </th>
          <td mat-cell *matCellDef="let row" data-label="Signo" class="td-responsive"> {{row.signo}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

        </tr>

      </table>

    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
