<app-pavso-title-section [title]="'Parte de salida'" [module]="'Almacén'" [estado]="parteSalida.mac_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l1">
        <mat-form-field>
          <mat-label>Tipo</mat-label>
          <mat-select disabled [(ngModel)]="parteSalida.tdo_codtdo">
            <mat-option value="ING">
              INGRESO
            </mat-option>
            <mat-option value="SAL">
              SALIDA
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Almacen</mat-label>
          <mat-select [(ngModel)]="parteSalida.alm_codalm" name="alm_codalm"
            (selectionChange)="seleccionarAlmacen($event.value)" required [disabled]="loaderData">
            <mat-option *ngFor="let almacen of almacenes" [value]="almacen.ALM_CODALM">
              {{almacen.ALM_DESCRI}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l1">
        <mat-form-field class="example-full-width">
          <mat-label>Nro. Parte</mat-label>
          <input required matInput placeholder="Nro. Parte" name="mac_tipcam" disabled>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Fecha</mat-label>
          <input [min]="minDate" maxlength="10" matInput [matDatepicker]="picker" [(ngModel)]="parteSalida.mac_fecmac"
            name="mac_fecmac" (dateChange)="seleccionarFecha()" required [disabled]="loaderData">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l1">
        <mat-form-field class="example-full-width">
          <mat-label>T. Cambio</mat-label>
          <input matInput placeholder="T. Cambio" [(ngModel)]="parteSalida.mac_tipcam" name="mac_tipcam" disabled
            required>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l1">
        <mat-form-field class="example-full-width">
          <mat-label>Período (Año)</mat-label>
          <input matInput placeholder="Año" [(ngModel)]="parteSalida.ano_codano" name="ano_codano" disabled required>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l1">
        <mat-form-field class="example-full-width">
          <mat-label>Período (Mes)</mat-label>
          <input matInput placeholder="Mes" [(ngModel)]="parteSalida.mes_codmes" name="mes_codmes" disabled required>
        </mat-form-field>
      </div>

    </div>
  </mat-card>
  <br>
  <mat-tab-group>
    <mat-tab label="Generales" [disabled]="loaderData" labelClass="mat-tab-label-0-0">
      <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <mat-card class="mat-elevation-z0">

          <div class="row">

            <div class="col s12 m12 l3">

              <select-search [label]="'Auxiliar'" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli'"
                [value]="'cli_codcli'" [disabled]="loaderData" [description]="'cli_nomcli'" [data]="auxiliares"
                (cambioSelect)="parteSalida.cli_codcli = $event" [model]="parteSalida.cli_codcli">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'Motivo'" [placeholder]="'Ej. COMPRAS LOCALES'" [name]="'mmo_codmmo'"
                [value]="'mmo_codmmo'" [disabled]="loaderData" [description]="'mmo_descri'" [data]="motivos"
                (cambioSelect)="parteSalida.mmo_codmmo = $event; seleccionarMotivo($event)"
                [model]="parteSalida.mmo_codmmo">
              </select-search>

            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Almacén de Destino</mat-label>
                <mat-select [disabled]="numeroOrdenDisabled" [(ngModel)]="parteSalida.alm_coddes" name="alm_coddes"
                  [disabled]="loaderData">
                  <mat-option *ngFor="let almacen of almacenes" [value]="almacen.ALM_CODALM">
                    {{almacen.ALM_DESCRI}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>O/T</mat-label>
                <mat-select [(ngModel)]="parteSalida.ccs_codccs" name="ccs_codccs" [disabled]="loaderData">
                  <mat-option *ngFor="let centro of centros" [value]="centro.ccs_codccs">
                    {{centro.ccs_desesp}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l1">
              <select-search [label]="'Tipo'" [placeholder]="'Ej. FACTURA'" [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'"
                [disabled]="loaderData" [description]="'TDO_DESLAR'" [data]="tiposDocumento"
                (cambioSelect)="parteSalida.mac_tdore1 = $event" [model]="parteSalida.mac_tdore1"></select-search>

            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Referencia (FAC)</mat-label>
                <input matInput placeholder="Referencia (FAC)" [(ngModel)]="parteSalida.mac_numre1" name="mac_numre1"
                  [disabled]="loaderData">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l1">

              <select-search [label]="'Tipo'" [placeholder]="'Ej. FACTURA'" [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'"
                [disabled]="loaderData" [description]="'TDO_DESLAR'" [data]="tiposDocumento"
                (cambioSelect)="parteSalida.mac_tdore2 = $event" [model]="parteSalida.mac_tdore2"></select-search>

            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Referencia (SIR)</mat-label>
                <input matInput placeholder="Referencia (FAC)" [(ngModel)]="parteSalida.mac_numre2" name="mac_numre2"
                  [disabled]="loaderData">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Glosa</mat-label>
                <input matInput placeholder="Glosa" [(ngModel)]="parteSalida.mac_glomac" name="mac_glomac"
                  [disabled]="loaderData">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>#Reg. Invoice</mat-label>
                <mat-select [(ngModel)]="parteSalida.oim_numoim" name="oim_numoim" [disabled]="loaderData">
                  <mat-option *ngFor="let invoice of invoices" [value]="invoice.codigo">
                    {{invoice.Documento}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12 l3">

              <select-search [label]="'Requerimiento'" [placeholder]="'Ej. COMPRAS LOCALES'" [name]="'nro_req'"
                [value]="'nro_req'" [disabled]="loaderData" [description]="'nro_req'" [data]="requerimientos"
                (cambioSelect)="parteSalida.rma_numrma = $event;" iconAction="get_app" tooltipAction="Obtener productos"
                (clickAction)="obtenerProductosRequerimiento()" [model]="parteSalida.rma_numrma">
              </select-search>

            </div>

            <div class="col s12 m12 l3">

              <select-search [label]="'Pedido'" [placeholder]="'Ej. 00000001'" [name]="'nro_req'"
                [value]="'nro_req'" [disabled]="loaderData" [description]="'nro_req'" [data]="requerimientos"
                (cambioSelect)="parteSalida.rma_numrma = $event;" iconAction="get_app" tooltipAction="Obtener productos"
                (clickAction)="obtenerProductosRequerimiento()" [model]="parteSalida.rma_numrma">
              </select-search>

            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Origen Código</mat-label>
                <input matInput placeholder="Origen Código" [disabled]="loaderData">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">

              <div class="col s12 m12 l3">
                <mat-form-field class="example-full-width">
                  <mat-label></mat-label>
                  <input matInput placeholder="" [disabled]="loaderData">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field class="example-full-width">
                  <mat-label></mat-label>
                  <input matInput placeholder="" [disabled]="loaderData">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field class="example-full-width">
                  <mat-label></mat-label>
                  <input matInput placeholder="" [disabled]="loaderData">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field class="example-full-width">
                  <mat-label></mat-label>
                  <input matInput placeholder="" [disabled]="loaderData">
                </mat-form-field>
              </div>

            </div>
          </div>
        </mat-card>
        <br>

        <div class="row">
          <div class="col s12">

            <div class="pav-table-header-container">
              <div class="pav-table-header-icon">
              </div>
              <div class="pav-table-header-title">
                Productos
              </div>
              <div class="pav-table-header-add">
                <div class="isMobile">
                  <div class="pav-button-icon-add" (click)="agregarProducto()">
                    <mat-icon svgIcon="add"></mat-icon>
                  </div>
                </div>
                <div class="isWeb">
                  <button type="button" mat-flat-button class="pav-button-add-item"
                    (click)="agregarProducto()">Agregar</button>
                </div>
              </div>
            </div>

            <div class="mat-elevation-z0 overflow-x">
              <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                    <div class="pav-btns-container">
                      <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarProducto(row)">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </div>
                      <div [ngStyle]="{marginLeft: '15px', marginTop: '4px'}">
                        <mat-checkbox [(ngModel)]="row.seleccionado" [name]="'seleccionado' + i" class="example-margin"
                          (change)="seleccionarProducto($event.checked, row)"></mat-checkbox>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Item"> {{i + 1}} </td>
                </ng-container>

                <ng-container matColumnDef="codigo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                  <td mat-cell *matCellDef="let row" data-label="Código"> {{row.prd_codprd}} </td>
                </ng-container>

                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
                    <div [ngStyle]="{marginTop: '14px'}">
                      <select-search [label]="'Producto'" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd' + i"
                        [value]="'prd_codprd'" [disabled]="loaderData" [description]="'DESC_ESPA'" [data]="productos"
                        (cambioSelect)="row.prd_codprd = $event" [model]="row.prd_codprd">
                      </select-search>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="um">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
                    <mat-form-field [ngStyle]="{width:'100px', marginTop: '14px'}" class="example-full-width">
                      <mat-label></mat-label>
                      <input matInput placeholder="Ej. UND" [(ngModel)]="row.ume_codven" [name]="'ume_codven' + i">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="cantidad_oc">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad O.C. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad O.C.">
                    <mat-form-field [ngStyle]="{width:'150px', marginTop: '14px'}" class="example-full-width">
                      <mat-label>Cantidad O.C.</mat-label>
                      <input matInput placeholder="Ej. UND" [(ngModel)]="row.map_canocc" [name]="'map_canocc' + i">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="cantidad">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad</th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
                    <mat-form-field [ngStyle]="{width:'150px', marginTop: '14px'}" class="example-full-width">
                      <mat-label>Cantidad</mat-label>
                      <input type="number" matInput placeholder="Ej. 10" [(ngModel)]="row.map_canprd"
                        [name]="'map_canprd' + i">
                    </mat-form-field>

                  </td>
                </ng-container>

                <ng-container matColumnDef="lote_ref_pro">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote Ref. Pro. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Lote Ref. Pro.">
                    <mat-form-field [ngStyle]="{width:'150px', marginTop: '14px'}" class="example-full-width">
                      <mat-label>Lote Ref. Pro.</mat-label>
                      <input matInput placeholder="Ej. 2222" [(ngModel)]="row.map_lotmap" [name]="'map_lotmap' + i">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="costo_ume">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo U.M.E. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Costo U.M.E.">
                    <mat-form-field [ngStyle]="{width:'150px', marginTop: '14px'}" class="example-full-width">
                      <mat-label>Costo U.M.E.</mat-label>
                      <input type="number" matInput placeholder="Ej. 10" [(ngModel)]="row.map_cosmep"
                        [name]="'map_cosmep' + i">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="costo_umn">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo U.M.N. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Costo U.M.N.">
                    <mat-form-field [ngStyle]="{width:'150px', marginTop: '14px'}" class="example-full-width">
                      <mat-label>Costo U.M.N.</mat-label>
                      <input type="number" matInput placeholder="Ej. 10" [(ngModel)]="row.map_cosmnp"
                        [name]="'map_cosmnp' + i">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="sec">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Sec">
                    <mat-form-field [ngStyle]="{width:'150px', marginTop: '14px'}" class="example-full-width">
                      <mat-label>Sec</mat-label>
                      <input matInput placeholder="Ej. 1111" [(ngModel)]="row.map_secmap" [name]="'map_secmap' + i">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="imp_me_prod">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. ME Prod. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. ME Prod.">
                    <mat-form-field [ngStyle]="{width:'150px', marginTop: '14px'}" class="example-full-width">
                      <mat-label>Imp. ME Prod.</mat-label>
                      <input type="number" matInput placeholder="Ej. 100" [(ngModel)]="row.map_impmep"
                        [name]="'map_impmep' + i">
                    </mat-form-field>

                  </td>
                </ng-container>

                <ng-container matColumnDef="imp_mn_prod">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. MN Prod. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. MN Prod.">
                    <mat-form-field [ngStyle]="{width:'150px', marginTop: '14px'}" class="example-full-width">
                      <mat-label>Imp. MN Prod.</mat-label>
                      <input type="number" matInput placeholder="Ej. 10" [(ngModel)]="row.map_impmnp"
                        [name]="'map_impmnp' + i">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="glosa">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa">
                    <mat-form-field [ngStyle]="{marginTop: '14px'}" class="example-full-width">
                      <mat-label>Glosa</mat-label>
                      <input matInput placeholder="Ej. Observaciones" [(ngModel)]="row.map_glomap"
                        [name]="'map_glomap' + i">
                    </mat-form-field>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="14">No se encontraron registros</td>
                </tr>

              </table>
            </div>
            <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            <br>
          </div>
        </div>

        <div class="row">
          <div class="col s12 m12 l6">
            <div class="pav-table-header-container">
              <div class="pav-table-header-icon">
              </div>
              <div class="pav-table-header-title">
                Lotes
              </div>
              <div class="pav-table-header-add">
                <div class="isMobile">
                  <div class="pav-button-icon-add" (click)="agregarLote()">
                    <mat-icon svgIcon="add"></mat-icon>
                  </div>
                </div>
                <div class="isWeb">
                  <button type="button" mat-flat-button class="pav-button-add-item"
                    (click)="agregarLote()">Agregar</button>
                </div>
              </div>
            </div>
            <div class="mat-elevation-z0 overflow-x">
              <table mat-table [dataSource]="dataSourceLote" matSort>

                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                    <div class="pav-btns-container">
                      <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarLote(i)">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="lote">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote </th>
                  <td mat-cell *matCellDef="let row" data-label="Código">
                    <ng-container>
                      <mat-form-field [ngStyle]="{width: '100px', marginTop: '14px'}">
                        <input matInput placeholder="Lote" [(ngModel)]="row.mlo_numlot" name="mlo_numlot" required>
                      </mat-form-field>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container matColumnDef="cantidad">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                  <td mat-cell *matCellDef="let row" data-label="Cliente">
                    <mat-form-field [ngStyle]="{width: '100px', marginTop: '14px'}">
                      <input matInput type="number" placeholder="Cantidad" [(ngModel)]="row.mlo_canprd"
                        name="mlo_canprd" required>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="fec_fabric">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Fabric. </th>
                  <td mat-cell *matCellDef="let row" data-label="Teléfono">
                    <mat-form-field [ngStyle]="{width: '150px', marginTop: '14px'}">
                      <mat-label>Fecha Fabricación</mat-label>
                      <input matInput [matDatepicker]="pickerFab" [(ngModel)]="row.mlo_fecfab" name="mlo_fecfab"
                        required>
                      <mat-datepicker-toggle matSuffix [for]="pickerFab"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFab></mat-datepicker>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="fec_vcto">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vcto. </th>
                  <td mat-cell *matCellDef="let row" data-label="Teléfono">

                    <mat-form-field [ngStyle]="{width: '150px', marginTop: '14px'}">
                      <mat-label>Fecha Vencimiento</mat-label>
                      <input matInput [matDatepicker]="pickerVcto" [(ngModel)]="row.mlo_fecven" name="mlo_fecven"
                        required>
                      <mat-datepicker-toggle matSuffix [for]="pickerVcto"></mat-datepicker-toggle>
                      <mat-datepicker #pickerVcto></mat-datepicker>
                    </mat-form-field>

                  </td>
                </ng-container>

                <ng-container matColumnDef="observacion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Observación </th>
                  <td mat-cell *matCellDef="let row" data-label="Teléfono">
                    <mat-form-field [ngStyle]="{width: '200px', marginTop: '14px'}">
                      <mat-label>Observación</mat-label>
                      <textarea matInput placeholder="Observación" [(ngModel)]="row.mlo_glomlo" name="mlo_glomlo"
                        required></textarea>
                    </mat-form-field>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsLote"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsLote;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="5">No se encontraron registros</td>
                </tr>

              </table>
            </div>
            <mat-paginator #paginatorLote class="mat-elevation-z0" [pageSizeOptions]="[3, 5, 10, 25]"></mat-paginator>
            <br>
          </div>
          <div class="col s12 m12 l6">
            <div class="pav-table-header-container">
              <div class="pav-table-header-icon">
              </div>
              <div class="pav-table-header-title">
                Series
              </div>
              <div class="pav-table-header-add">
                <div class="isMobile">
                  <div class="pav-button-icon-add" (click)="agregarSerie()">
                    <mat-icon svgIcon="add"></mat-icon>
                  </div>
                </div>
                <div class="isWeb">
                  <button type="button" mat-flat-button class="pav-button-add-item"
                    (click)="agregarSerie()">Agregar</button>
                </div>
              </div>
            </div>
            <div class="mat-elevation-z0 overflow-x">
              <table mat-table [dataSource]="dataSourceSerie" matSort>

                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                    <div class="pav-btns-container">
                      <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarSerie(i)">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="serie">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie /Pasaporte </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                    <mat-form-field [ngStyle]="{marginTop: '14px'}">
                      <mat-label>Serie /Pasaporte</mat-label>
                      <input matInput placeholder="Serie /Pasaporte" [(ngModel)]="row.spr_numser"
                        [name]="'spr_numser' + i" required>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="fec_fabric">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Fabric. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Cliente">
                    <mat-form-field [ngStyle]="{marginTop: '14px'}">
                      <mat-label>Fecha Fabricación</mat-label>
                      <input matInput [matDatepicker]="pickerFab" [(ngModel)]="row.mas_fecfab" [name]="'fechaFab'+i"
                        required>
                      <mat-datepicker-toggle matSuffix [for]="pickerFab"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFab></mat-datepicker>
                    </mat-form-field>
                  </td>

                </ng-container>

                <ng-container matColumnDef="fec_vcto">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. de Vcto. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                    <mat-form-field [ngStyle]="{marginTop: '14px'}">
                      <mat-label>Fecha Vencimiento</mat-label>
                      <input matInput [matDatepicker]="pickerVcto" [(ngModel)]="row.mas_fecven" [name]="'mas_fecven'+i"
                        required>
                      <mat-datepicker-toggle matSuffix [for]="pickerVcto"></mat-datepicker-toggle>
                      <mat-datepicker #pickerVcto></mat-datepicker>
                    </mat-form-field>
                  </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSerie"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSerie;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No se encontraron registros</td>
                </tr>

              </table>

              <mat-paginator #paginatorSerie [pageSizeOptions]="[3, 5, 10, 25]"></mat-paginator>
            </div>
            <br>
          </div>
        </div>

        <br>
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Imp. MN Prod.</mat-label>
                <input matInput min="0" placeholder="Imp. MN Prod." type="number" disabled [(ngModel)]="importeTotalMN"
                  name="importeTotalMN">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Cost. Uni. MN</mat-label>
                <input matInput min="0" placeholder="Cost. Uni. MN" type="number" disabled [(ngModel)]="costoTotalMN"
                  name="costoTotalMN">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Imp. ME Prod.</mat-label>
                <input matInput min="0" placeholder="Imp. ME Prod." type="number" disabled [(ngModel)]="importeTotalME"
                  name="importeTotalME">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Cost. Uni. ME</mat-label>
                <input matInput min="0" placeholder="Cost. Uni. ME" type="number" disabled [(ngModel)]="costoTotalME"
                  name="costoTotalME">
              </mat-form-field>
            </div>

          </div>

        </mat-card>
        <br>
        <br>
        <br>
        <br>
        <br>

        <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
          urlBack="/modulo-almacen/parte-de-salida">
          <div id="botonesAdicionales">

          </div>
        </pav-form-actions>

      </form>

    </mat-tab>
    <mat-tab label="Ingreso" disabled labelClass="mat-tab-label-0-1"> Ingreso </mat-tab>
    <mat-tab label="Guía de remisión" [disabled]="loaderData" labelClass="mat-tab-label-0-2">
      <form action="">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Tipo Doc.</mat-label>
                <mat-select [(ngModel)]="parteSalida.detalle_guia[0].tdo_codtdo" name="tdo_codtdo_guia" required>
                  <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.TDO_CODTDO">
                    {{tipo.TDO_DESLAR}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l1">
              <mat-form-field>
                <mat-label>Serie</mat-label>
                <mat-select [(ngModel)]="parteSalida.detalle_guia[0].sdo_codsdo" name="sdo_codsdo"
                  (selectionChange)="seleccionarSerieGuia($event.value)" required>
                  <mat-option *ngFor="let serie of seriesGuia" [value]="serie.sdo_codsdo">
                    {{serie.sdo_codsdo}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l1">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Doc.</mat-label>
                <input matInput placeholder="Nro. Doc." [(ngModel)]="parteSalida.detalle_guia[0].grc_numdoc"
                  name="grc_numdoc" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input matInput [matDatepicker]="fechaRemision" [(ngModel)]="parteSalida.detalle_guia[0].grc_fecdoc"
                  name="grc_fecdoc" required>
                <mat-datepicker-toggle matSuffix [for]="fechaRemision"></mat-datepicker-toggle>
                <mat-datepicker #fechaRemision></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <app-state-select [state]="parteSalida.detalle_guia[0].grc_indsta"
                (cambioEstado)="parteSalida.detalle_guia[0].grc_indsta = $event"></app-state-select>
            </div>
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Cliente</mat-label>
                <mat-select [(ngModel)]="parteSalida.detalle_guia[0].cli_codcli" name="cli_codcli_guia" required>
                  <mat-option *ngFor="let cliente of clientes" [value]="cliente.cli_codcli">
                    {{cliente.cli_nomcli}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Transporte</mat-label>
                <mat-select [(ngModel)]="parteSalida.detalle_guia[0].cli_codtra" name="cli_codtra" required>
                  <mat-option *ngFor="let transportista of transportistas" [value]="transportista.cli_codcli">
                    {{transportista.cli_nomcli}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Chofer</mat-label>
                <mat-select [(ngModel)]="parteSalida.detalle_guia[0].cho_codcho" name="cho_codcho"
                  (selectionChange)="seleccionarChofer($event.value)" required>
                  <mat-option *ngFor="let chofer of choferes" [value]="chofer.CHO_CODCHO">
                    {{chofer.CHO_NOMBRE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Licencia </mat-label>
                <input matInput placeholder="Licencia" [(ngModel)]="parteSalida.detalle_guia[0].grc_nrolic"
                  name="grc_nrolic" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Unid. Trans.</mat-label>
                <mat-select [(ngModel)]="parteSalida.detalle_guia[0].utr_codutr" name="utr_codutr"
                  (selectionChange)="seleccionarUnidTransporte($event.value)" required>
                  <mat-option *ngFor="let unidad of unidades" [value]="unidad.UTR_CODUTR">
                    {{unidad.UTR_DESCRI}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Placa</mat-label>
                <input matInput placeholder="Placa" [(ngModel)]="parteSalida.detalle_guia[0].grc_plaund"
                  name="grc_plaund" required>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Certif.</mat-label>
                <input matInput placeholder="Nro. Certif." [(ngModel)]="parteSalida.detalle_guia[0].grc_nrocer"
                  name="grc_nrocer" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Marca</mat-label>
                <input matInput placeholder="Marca" [(ngModel)]="parteSalida.detalle_guia[0].grc_marund"
                  name="grc_marund" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Glosa</mat-label>
                <input matInput placeholder="Glosa" [(ngModel)]="parteSalida.detalle_guia[0].grc_obsgrc"
                  name="grc_obsgrc" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l5">
              <button mat-flat-button color="primary" type="button" disabled>Liberar Guía</button>
              &nbsp;
              <button mat-flat-button color="primary" type="button">Ingresar Guía</button>
            </div>

          </div>
        </mat-card>
      </form>


    </mat-tab>
  </mat-tab-group>

</div>
