import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Driver } from 'src/app/models';
import { ConfigurationService, NominaService } from 'src/app/services';
import { Store } from '@ngrx/store';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { SnackBarService } from '@shared/services/snackbar.service';
import { CuentaCorrientePrestamo, DetallePrestamo } from 'src/app/models/planilla/cuenta-corriente-prestamo';
import { fillTable } from '@utils/tables/table';
import { DialogService } from '@shared/services/dialog.service';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';

@Component({
  selector: 'app-cuenta-corriente-prestamo',
  templateUrl: './cuenta-corriente-prestamo.component.html',
  styleUrls: ['./cuenta-corriente-prestamo.component.css']
})
export class CuentaCorrientePrestamoComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];
  monedas: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['actions', 'anio', 'mes', 'frecuencia', 'imp_cuota'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  minDate: Date;

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  loading$: Subscription;
  frecuencia$: Subscription;

  frecuencias: any[] = [];

  cuentaCorriente: CuentaCorrientePrestamo;

  detalle: Array<DetallePrestamo> = [];

  importeD: number | string;
  importeS: number | string;

  constructor(
    private _router: Router,
    private _nominaService: NominaService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>
  ) {
    this.cuentaCorriente = new CuentaCorrientePrestamo();

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

    this.driver = new Driver();
    this.usuario = this._configurationService.obtenerIdUsuario();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  trackByFrecuencia(index, frecuencia): string {
    return frecuencia? frecuencia.nfr_codnfr: undefined;
  }

  loadData(): void {
    this.frecuencia$ = this._nominaService.obtenerFrecuencias().subscribe(
      frecuencias => this.frecuencias = frecuencias,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  blurImporteD(): void {
    this.importeD = Number(this.importeD).toFixed(2)
  }

  blurImporteS(): void {
    this.importeS = Number(this.importeS).toFixed(2)
  }

  agregarItem(): void {
    this.detalle.push(new DetallePrestamo());
    this.detalle.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.detalle, this.paginator, this.sort);
  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row): void {
    if(!row.ano || !row.mes || !row.frecuencia || !row.impCuota) {
      this._snackBarService.showError('Todos los campos de fila deben estar compeltos', 'Ok')
      return;
    }
    row.isEditing = !row.isEditing;

  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSource.data.filter(item => item.correlativo != row.correlativo);
        filtered.forEach((element, key) => {
          element['correlativo'] = key;
        })
        this.detalle = filtered;
        this.dataSource = fillTable(filtered, this.paginator, this.sort);
      }
    })
  }

  registrarChofer(f: NgForm): void {
    if(validarPeriodo(new Date(), this.year, this.month)) {}
  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.frecuencia$]);
  }

}
