<app-pavso-title-section [title]="'Concepto remunerativo'" [module]="'Planilla'" [estado]="conceptoRemunerativo.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Código</mat-label>
            <input [(ngModel)]="conceptoRemunerativo.codnco" name="codnco" matInput placeholder="Código" disabled/>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Tipo de concepto</mat-label>
            <mat-select [(ngModel)]="conceptoRemunerativo.indtip" name="nco_indtip">
              <mat-option *ngFor="let tipo of tipos" [value]="tipo.ntc_indtip">
                {{ tipo.ntc_descri }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l8">
          <mat-form-field class="example-full-width">
            <mat-label>Descripción</mat-label>
            <input [(ngModel)]="conceptoRemunerativo.descri" name="descri" matInput placeholder="Descripción"/>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Unidad conc.</mat-label>
            <mat-select [(ngModel)]="conceptoRemunerativo.codnut" name="codnut">
              <mat-option *ngFor="let unidad of unidades" [value]="unidad.nut_codnut">
                {{ unidad.nut_descri }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12">
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indimp" name="indimp">Imprimir unidad de concepto en boleta de pago</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indvar" name="indvar">Rem. variable</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indafp" name="indafp">AFP</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indren" name="indren">RENTA</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indqui" name="indqui">QUINCENA</mat-checkbox>
        </div>
      </div>
    </mat-card>

    <br />
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l6">
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.AFEAFP" name="AFEAFP">Afecto a
            AFP</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.aferta" name="aferta">Afecto a
            renta</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.afects" name="afects">Afecto a
            CTS</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.afegra" name="afegra">Afecto a
            gratificación</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.afevac" name="afevac">Afecto a
            vacaciones</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin">Calcular Quincena</mat-checkbox>
        </div>
        <div class="col s12 m12 l6">
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indctg" name="indctg">Rem.
            cómputo total gratificación</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.inddef" name="inddef">Aplica
            días efectivos</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indfis" name="indfis">Aplica
            semana fija</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.impdef" name="inddef">Aplica
            importe definido</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indina" name="indina">Solo
            descuenta inasistencia</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.desess" name="desess">Aplica
            descuento Essalud</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indddu" name="indddu">Descuenta
            días de utilidades</mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indsub" name="indsub">Subsidio
          </mat-checkbox>
          <br />
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indoct" name="indoct">Rem.
            cómputo total CTS</mat-checkbox>
        </div>
      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Cod. SUNAT</mat-label>
            <input matInput [(ngModel)]="conceptoRemunerativo.codofi" name="codofi" placeholder="Cod. Sunat"/>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Cod. grupo</mat-label>
            <input matInput [(ngModel)]="conceptoRemunerativo.codgru" name="codgru" placeholder="Cod. Grupo"/>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Cod. subsidios - faltas PLAME</mat-label>
            <input matInput [(ngModel)]="conceptoRemunerativo.subfal" name="subfal"
              placeholder="Cod. Subsidios - faltas PLAME"/>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12">
          <h4>Configuración contables</h4>
          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.excgas" name="excgas">Excluir
            cuenta de gastos</mat-checkbox>

          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.excpag" name="excpag">Excluir
            cuentas por pagar</mat-checkbox>

          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indpvs" name="indpvs">Genera
            provisiones</mat-checkbox>

          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indapv" name="indapv">Aplica
            provisiones (Conceptos liquid. truncas)</mat-checkbox>

          <mat-checkbox class="example-margin" [(ngModel)]="conceptoRemunerativo.indcos" name="indcos">Indicador
            de costo</mat-checkbox>

        </div>
      </div>
      <br>
      <div class="row">
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Auxiliar</mat-label>
            <mat-select [(ngModel)]="conceptoRemunerativo.codcli" name="codcli">
              <mat-option *ngFor="let auxiliar of auxiliares" [value]="auxiliar.codcli">
                {{ auxiliar.nomcli }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-planilla/concepto-remunerativo">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
