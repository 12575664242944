<app-pavso-title-section [title]="'Series de documento'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todas las series" url="/modulo-ventas/serie-de-documento" entity="" [params]="['0', '0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <div class="pav-btns-container">
                  <div matTooltip="Editar serie de documento"
                    class="pav-btn-circle-edit"
                    [routerLink]="['/modulo-ventas/serie-de-documento', row.tipo_documento, row.serie]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="tipo_documento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.tipo_documento}} </td>
          </ng-container>

          <ng-container matColumnDef="Descrip">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.Descrip}} </td>
          </ng-container>

          <ng-container matColumnDef="serie">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.serie}} </td>
          </ng-container>

          <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{(row.estado == "1")? "VIGENTE": "ANULADO"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
