<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

<div [ngStyle]="{ display: isMobile ? '' : 'flex', justifyContent: 'space-between' }">

  <!-- <h3 [ngStyle]="{fontSize: '16px'}">Archivos XML</h3> -->

  <div [ngStyle]="{display: 'flex', flexDirection: isMobile || isTablet ? 'column': 'row', alignItems: 'center', marginBottom: '10px'}">

    <input #inputFile hidden multiple type="file" (change)="cargarXmls($event)" accept=".xml" />

    <div [ngStyle]="{marginRight: '5px', width: '100%'}">

      <button mat-flat-button (click)="inputFile.click()" [ngStyle]="{border: '1px solid rgba(0, 0, 0, 0.12)', height: '45px', width: isMobile || isTablet ? '100%': '175px',  background: '#EB6945', color: 'white'}">
        <mat-icon>download</mat-icon>
        Cargar XML
      </button>

    </div>

    <div [ngStyle]="{width: '100%', marginTop: isMobile || isTablet ? '5px': '0px'}">

      <button [ngStyle]="{border: '1px solid rgba(0, 0, 0, 0.12)', marginRight: '5px', height: '45px', width: isMobile || isTablet ? '100%': '175px', color: '#222', background: '#6cd6df' }" [disabled]="!(this.dataSourceImagenes.data.length > 0)" mat-flat-button
        color="primary" (click)="moverXMLsARecepcion()" [class.spinner]="loaderEnviarRecepcion">
        <mat-icon>transform</mat-icon>
        Enviar a recepción
      </button>

    </div>

  </div>
</div>

<div class="overflow-x overflow-y">
  <table mat-table [dataSource]="dataSourceImagenes" matSort
    [ngStyle]="{border: '4px #ddd solid', borderRadius: '15px'}">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> XML </th>
      <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.name}} </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tamaño </th>
      <td mat-cell *matCellDef="let row" data-label="CIA" class="td-responsive">
        {{obtenerFormatoResumidoBytes(row.size)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
      <td mat-cell *matCellDef="let row" data-label="Correlativo" class="td-responsive"> {{row.type}} </td>
    </ng-container>

    <ng-container matColumnDef="lastModifiedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
      <td mat-cell *matCellDef="let row" data-label="Proveedor" class="td-responsive"> {{row.lastModifiedDate
        |
        date}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsImagenes"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsImagenes;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No se encontraron registros</td>
    </tr>

  </table>

</div>

<mat-paginator #paginatorImagenes showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

<mat-card *ngIf="documentosValidos.length > 0 || documentosNoValidos.length > 0">
  <mat-list>
    <!-- <div mat-subheader>Documentos cargados</div> -->
    <!-- <mat-list-item *ngFor="let folder of documentosValidos" [ngStyle]="{height: '40px'}">
      <mat-icon mat-list-icon>description</mat-icon>
      <div mat-line>{{folder.name}}</div>
      <div mat-line> {{folder.updated | date}} </div>
    </mat-list-item> -->
    <mat-divider></mat-divider>
    <div mat-subheader>Documentos no cargados</div>
    <mat-list-item *ngFor="let note of documentosNoValidos" [ngStyle]="{height: '40px'}">
      <mat-icon mat-list-icon>description</mat-icon>
      <div mat-line>{{note.mensaje}}</div>
      <div mat-line> {{note.estado}} </div>
    </mat-list-item>
  </mat-list>
</mat-card>
