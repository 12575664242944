import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss']
})
export class NotificationButtonComponent implements OnInit, OnDestroy {

  notificaciones: Array<any>;

  constructor(){
    this.notificaciones = [1,2,3,4,5]
  }

  ngOnInit():void {}

  ngOnDestroy(): void {}
}
