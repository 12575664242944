<div [ngStyle]="{display: 'flex'}">
  <mat-form-field [ngStyle]="{width: tooltipAction && iconAction? '80%': ''}">
    <mat-label>{{label}}</mat-label>
    <mat-select [ngClass]="setColorSelected? 'selected-option': ''" [(ngModel)]="model" [ngClass]="clase" [required]="required" (selectionChange)="seleccionar($event)"
      [name]="name" [placeholder]="placeholder" #inputSelect [multiple]="multiple" [disabled]="disabled">
      <mat-option>
        <ngx-mat-select-search noEntriesFoundLabel="'No se encontraron coincidencias'" placeholderLabel="Buscar ..."
          [formControl]="filterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-option (click)="agregar()" [ngStyle]="{backgroundColor: '#EAF0F9'}" *ngIf="canAdd">
        <mat-icon>add</mat-icon>
        Agregar {{label}}
      </mat-option>
      <mat-option (click)="model=null">Ninguno</mat-option>
      <div *ngFor="let item of filtered | async" [ngStyle]="{display: 'flex', alignItems: 'center'}">
        <mat-option [value]="item[value]" [ngStyle]="{flexGrow: 1}">
          {{item[description]}}
        </mat-option>
        <button color="primary" type="button" (click)="editar(item[value])" mat-icon-button [ngStyle]="{color: '#00205F'}" *ngIf="canEdit">
          <mat-icon [ngStyle]="{fontSize: '20px'}">edit</mat-icon>
        </button>
      </div>
    </mat-select>
    <!-- <mat-hint *ngIf="model">CÓDIGO: {{model}}</mat-hint> -->

  </mat-form-field>
  <button (click)="clickButton()" *ngIf="tooltipAction && iconAction" mat-icon-button matSuffix [matTooltip]="tooltipAction" type="button" [ngStyle]="{marginTop: '5px', color: '#00205F'}">
    <mat-icon>{{iconAction}}</mat-icon>
  </button>
</div>
