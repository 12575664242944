
<mat-toolbar class="mat-elevation-z0" color="primary">
  <div [ngStyle]="{display: 'flex', justifyContent: 'space-between', width: '280px', alignItems: 'center'}">
    <h1 [ngStyle]="{fontSize: '14px'}">Pav AI</h1>
    <div>
      <ng-content select="mat-icon"></ng-content>
    </div>
  </div>
</mat-toolbar>

<pavso-chat></pavso-chat>

