import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { Establishment } from "src/app/models";
import { DiasEntrega } from "src/app/models/ventas/dias-entrega";
import { ConfigurationService, GeneralService, VentasService } from "src/app/services";

@Component({
  selector: 'establecimiento-cliente-form',
  templateUrl: './establecimiento-cliente-form.component.html',
  styleUrls: ['./establecimiento-cliente-form.component.scss']
})
export class EstablecimientoClienteFormComponent implements OnInit, OnDestroy {

  loaderData: boolean;
  loaderReg: boolean;

  establecimiento: Establishment;

  displayedColumnsDiasEntrega: string[] = ['acciones', 'dia', 'datosAdicionales', 'hora_inicio', 'hora_maxima', 'referencia'];
  dataSourceDiasEntrega: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginatorDiasEntrega: MatPaginator;
  @ViewChild(MatSort) sortDiasEntrega: MatSort;

  paises: any[] = [];
  ubicaciones: any[] = [];
  zonas: any[] = [];
  diasEntrega: any[] = [];

  loading$: Subscription;
  paises$: Subscription;
  ubicaciones$: Subscription;
  zonas$: Subscription;
  diasEntrega$: Subscription;
  sendForm$: Subscription;

  uid: string;

  constructor(
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _generalService: GeneralService,
    private _ventasService: VentasService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService
  ) {
    this.establecimiento = new Establishment();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.cargarMaestros();

    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.cargarMaestros();
    })

  }

  obtenerEstablecimiento(): void {
    this.loaderData = false;
  }

  cargarMaestros(): void {
    this.loaderData = true;

    this.paises$ = this._generalService.listarPaises().subscribe(
      paises => {
        this.paises = paises;

        this.ubicaciones$ = this._generalService.listarUbicacion('01').subscribe(
          ubicaciones => {
            this.ubicaciones = ubicaciones;

            this.zonas$ = this._ventasService.obtenerZonasVenta().subscribe(
              zonas => {
                this.zonas = zonas;
                console.log('zonas', this.zonas)

                this.diasEntrega$ = this._ventasService.obtenerDias().subscribe(
                  diasEntrega => {
                    this.diasEntrega = diasEntrega;

                    if(this.uid != '0') {
                      this.obtenerEstablecimiento()
                      return;
                    }

                    this.loaderData = false;
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid != '0' ? this.actualizarEstablecimiento(f): this.registrarEstablecimiento(f)
  }

  registrarEstablecimiento(f: NgForm): void {
    this.sendForm$ = this._ventasService.registrarEstablecimiento(this.establecimiento).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Establecimiento Registrado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )

  }

  actualizarEstablecimiento(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarEstablecimiento(this.establecimiento).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Establecimiento actualizado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  agregarDiasEntrega(): void {
    let item = new DiasEntrega();
    item.dia_coduse = this._configurationService.obtenerIdUsuario();

    if(this.establecimiento.CIA_CODCIA && this.establecimiento.CLI_CODCLI){
      item.cia_codcia = this.establecimiento.CIA_CODCIA;
      item.cli_codcli = this.establecimiento.CLI_CODCLI;
    }

    this.establecimiento.detalle_dias_cds.push(item);

    this.dataSourceDiasEntrega = fillTable(this.establecimiento.detalle_dias_cds, this.paginatorDiasEntrega, this.sortDiasEntrega);
  }

  quitarDiasEntrega(row, index): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceDiasEntrega.data.filter((item,i) => item.dia_coddia != row.dia_coddia && i != index);
        this.establecimiento.detalle_dias_cds = filtered;
        this.dataSourceDiasEntrega = fillTable(filtered, this.paginatorDiasEntrega, this.sortDiasEntrega);
      }
    })
  }

  seleccionarDiasEntrega($event, row): void {
    const diaEntrega = this.diasEntrega.find(item => item.dia_coddia == $event.value);
    row.dia_desesp = diaEntrega.dia_desesp;
  }
  hayDatosAdicionales($event, row): void {
    console.log("soy :",$event.checked)
    row.isAditionalActive = $event.checked
  }

  verMasOpciones(): void {

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.paises$,
      this.ubicaciones$,
      this.zonas$,
      this.diasEntrega$,
      this.sendForm$,
    ])
  }
}
