import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Supervisor } from "src/app/models/ventas/supervisor";
import { AlmacenService, ConfigurationService, VentasService } from "src/app/services";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";

@Component({
  selector: 'supervisor-form',
  templateUrl: './supervisor-form.component.html',
  styleUrls: ['./supervisor-form.component.scss']
})
export class SupervisorFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;
  auxiliares: any[] =[];
  //=
  // [
  //   {cli_codcli: 'T00001', cli_nomcli: 'TENORIO DOMINGUEZ AUGUSTO'},
  //   {cli_codcli: 'T00002      ', cli_nomcli: 'CASTRO VENTOSILLA JUAN GERARDO'},
  //   {cli_codcli: 'T00003', cli_nomcli: 'CASTAÑEDA RODAS SILVIA ROSA'},
  //   {cli_codcli: 'T00012      ', cli_nomcli: 'MORE MALPARTOIDA KARINA'}
  // ];

  uid: string;
  supervisor: Supervisor;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  sendForm$: Subscription;
  loading$: Subscription;
  dataForm$: Subscription;
  auxiliares$: Subscription;
  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _clienteService: ClienteService,
    private _almacenService: AlmacenService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _configurationService: ConfigurationService
  ) {
    this.supervisor = new Supervisor();
    this.supervisor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.supervisor.sup_coduse = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarSupervisor(f): this.actualizarSupervisor(f);
  }

  loadData(): void {
    this.loaderData = true;
      this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.btnName = this.uid == '0' ?  this.buttonsName.BTN_STORE: this.buttonsName.BTN_UPDATE;
      this.listarAuxiliares()
    })
  }

  listarAuxiliares(): void{
    this.auxiliares$ = this._clienteService.obtenerClientes().subscribe(
      response => {
        //filtramos los auxiliares que son trabajadores
        const filtered = response.filter(auxiliar => auxiliar.cli_indtra === 1 && auxiliar.cli_indsta ==='1')
        for (let i = 0; i < filtered.length; i++) {
          this.auxiliares.push(
            {
              cli_nomcli: filtered[i].cli_codcli + ' - ' + filtered[i].cli_nomcli,
              cli_codcli: filtered[i].cli_codcli
            }
          )
        }
        console.log(this.auxiliares);

        this.uid!='0'?this.mostrarSupervisor():null;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  mostrarSupervisor():void{
    this.dataForm$ = this._ventasService.mostrarSupervisor(this.uid).subscribe(
      response => {
        console.log("supervisor", response);
        this.supervisor = response;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  registrarSupervisor(f: NgForm): void {
    this.sendForm$ = this._ventasService.registrarSupervisor(this.supervisor).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Supervisor registrado', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }
  actualizarSupervisor(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarSupervisor(this.supervisor).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Supervisor actualizado', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
        error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/supervisores']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.dataForm$,
      this.loading$,
      this.auxiliares$
    ])
  }

}
