<app-pavso-title-section [title]="'Descuento producto'" [module]="'Ventas'" [estado]="descuento.tbd_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <!-- coger este codigo y ponerlo entre la cabecera de la tabla y el detalle
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar> -->

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <!-- <select-search
              [label]="'Nro de Tabla'"
              [placeholder]="'Ej. Juan Ibañez'"
              [name]="'cli_codcli'"
              [value]="'cli_codcli'"
              [description]="'description'"
              [data]="clientes"
              (cambioSelect)="descuento.cli_codcli = $event; "
              [model]="descuento.cli_codcli">
            </select-search> -->
          <mat-form-field>
            <mat-label>Nro. Tabla</mat-label>
            <input type="text" [(ngModel)]="descuento.tbd_numtbd" [maxlength]="10" matInput placeholder="Nro. Tabla"
              name="tbd_numtbd" readonly>
          </mat-form-field>
        </div>
        <!-- <div class="col s12 m12 l3"></div> -->

        <div class="col s12 m12 l6"></div>

        <div class="col s12 m12 l12">
          <mat-form-field>
            <mat-label>Observaciones</mat-label>
            <input type="text" #observaciones [(ngModel)]="descuento.tbd_glotbd" [maxlength]="80" matInput
              placeholder="Ej. OFICINA LIMA" name="tbd_glotbd" required>
            <mat-hint align="end">{{observaciones.value.length}} / 80</mat-hint>

          </mat-form-field>
        </div>
        <!-- {{descuento | json}}         -->
      </div>
    </mat-card>
    <br>

    <div class="mat-elevation-z0 overflow-x">
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">

        </div>
        <div class="pav-table-header-title">
          Productos de Cliente
        </div>
        <div class="pav-table-header-add">
          <div class="isMobile">
            <div class="pav-button-icon-add" (click)="agregarProducto()"> <!-- (click)="agregarProductoCliente()" -->
              <mat-icon svgIcon="add"></mat-icon>
            </div>
          </div>
          <div class="isWeb">
            <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarProducto()">Agregar
              ítem</button> <!-- (click)="agregarProductoCliente()" -->
          </div>
        </div>
      </div>

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <table mat-table [dataSource]="dataSourceProducto" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Código" class="td-responsive">
            <div class="pav-btns-container">
              <div class="pav-btn-circle-delete" (click)="quitarProductoCombo(i)" matTooltip="Eliminar">
                <!-- (click)="quitarDiasEntrega(row,i)" -->
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
              <div class="pav-btn-circle-checkbox" matTooltip="Confirmar">
                <mat-checkbox [color]="'primary'" [disabled]="row.prd_codprd == '' || !row.prd_codprd"
                  [(ngModel)]="row.isSelected" [name]="'isSelected'+i"
                  (ngModelChange)="onCheckboxChange(i,row);"></mat-checkbox>
                <!-- <mat-checkbox [color]="'primary'" [(ngModel)]="row.isSelected" [name]="'mmo_indocm'" (ngModelChange)="onNgModelChangeCheckbox($event,'mmo_indocm')">Ord. Compra</mat-checkbox>&nbsp;&nbsp; -->

              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="prd_codprd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Código | Descripción de Producto</th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="prd_codprd">
            <ng-container *ngIf="row.isEditing">
              <div class="col s12 m12 l3">
                <select-search [label]="'Productos'" [placeholder]="'Ej. BOLSA SUPERTRIGO EL TACO'"
                  [name]="'prd_codprd'" [value]="'prd_codprd'" [description]="'description'" [data]="comboProductos"
                  (cambioSelect)="validarProductoRepetido($event,row)" [model]="row.prd_codprd" [required]="true">
                </select-search>
              </div>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="2">No se encontraron registros </td> <!-- {{input.value}} -->
        </tr>
      </table>
      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>
    <div class="mat-elevation-z0 overflow-x">
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">
        </div>
        <div class="pav-table-header-title">
          Descuentos
        </div>
        <div class="pav-table-header-add">
          <div class="isMobile">
            <div class="pav-button-icon-add" (click)="agregarProducto()"> <!-- (click)="agregarProductoCliente()" -->
              <mat-icon svgIcon="add"></mat-icon>
            </div>
          </div>
          <div class="isWeb">
            <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarDescuento()">Agregar
              ítem</button> <!-- (click)="agregarProductoCliente()" -->
          </div>
        </div>
      </div>

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <table mat-table [dataSource]="dataSourceDescuento" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Código" class="td-responsive">
            <div class="pav-btns-container">
              <div class="pav-btn-circle-delete" (click)="quitarDescuentoCombo(i)" matTooltip="Eliminar">
                <!-- (click)="quitarDiasEntrega(row,i)" -->
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="dco_coddco">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Código | Descripción</th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="dco_coddco">
            <div class="col s12 m12 l3">
              <select-search [label]="''" [placeholder]="'DESCUENTO'" [name]="'dco_coddco'" [value]="'dco_coddco'"
                [description]="'description'" [data]="comboDescuento" (cambioSelect)="row.dco_coddco = $event"
                [model]="row.dco_coddco" [required]="true">
              </select-search>
            </div>
        </ng-container>
        <ng-container matColumnDef="tds_portds">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>% Descuento</th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="tds_portds">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>% Descuento</mat-label>
                <input type="text" [(ngModel)]="row.tds_portds" soloNumerosText matInput placeholder="Ej. 2020"
                  [name]="'tds_portds'+i" required>
              </mat-form-field>
            </div>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsDescuento"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDescuento;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No se encontraron registros </td> <!-- {{input.value}} -->

        </tr>
      </table>
      <mat-paginator showFirstLastButtons #paginatorDescuento [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>
    <br>
    <br>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-ventas/descuentos-por-producto">
      <div id="botonesAdicionales">

      </div>
    </pav-form-actions>
  </form>
</div>
