import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ClienteCRMDialog } from "./cliente-crm-dialog/cliente-crm-dialog.component";
import { ClienteCrmFormComponent } from "./cliente-crm-form/cliente-crm-form.component";
import { ClienteCrmListComponent } from "./cliente-crm-list/cliente-crm-list.component";
import { ClienteCRMSelect } from "./cliente-crm-select/cliente-crm-select.component";
import { HttpLoaderFactory } from "src/app/app.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MaterialModule } from "src/app/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [ClienteCrmFormComponent, ClienteCrmListComponent, ClienteCRMDialog, ClienteCRMSelect],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    ClienteCrmFormComponent, ClienteCrmListComponent, ClienteCRMDialog, ClienteCRMSelect
  ]
})
export class ClienteCRMModule {}
