import { Component, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { fillTable, searchInTable } from "@utils/tables/table";
import { ExportExcelService } from "src/app/services";

export interface IHeaderKey {
  header: string;
  key: string;
}

@Component({
  selector: 'pav-table-list',
  templateUrl: './pav-table-list.component.html',
  styleUrls: ['./pav-table-list.component.scss']
})
export class PavTableListComponent implements OnInit, OnChanges {

  uid: string;

  @Input() data: Array<any> = [];
  dataSource: MatTableDataSource<any>;

  @Input() tHeaders: Array<IHeaderKey> = [];
  @Input() editLink: Array<any>;

  @Input() title: string;
  @Input() url: string;
  @Input() entity: string;
  @Input() params: Array<string> = [];
  @Input() pageSize: number = 5;

  buttonName: INameConstant = NAMES_CONSTANTS;

  displayedColumns: string[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _exportExcelService: ExportExcelService,
  ) {}

  ngOnInit(): void {

    let columnas: Array<string> = this.tHeaders.map(item => {
      return item.key;
    });

    this.uid = columnas[0];

    this.displayedColumns = ['accion', ...columnas];

    this.dataSource = fillTable(this.data, this.paginator, this.sort);

  }

  ngOnChanges(): void {

    this.dataSource = fillTable(this.data, this.paginator, this.sort)

  }

  applyFilter(event: Event): void {

    this.dataSource = searchInTable(event, this.dataSource )

  }

  obtenerParametros(): Array<string> {
    return [this.url, ...this.params];
  }

  exportarExcel(): void {

    let dataForExcel = [];

    this.data.forEach((row: any) => {
      dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: this.title,
      data: dataForExcel,
      headers: Object.keys(this.data[0]),
    }

    this._exportExcelService.exportExcelTable(reportData);
  }

}
