
export { REPORTES_ACTIVO_FIJO } from '@data/json/reportes/activofijo.reporte.json';


export { REPORTES_CAJA_Y_BANCOS } from '@data/json/reportes/cajabanco.reporte.json';

export { REPORTES_COBRANZAS } from './cobranzas.reporte.json';

export { REPORTES_COMPRAS } from './compras.reporte.json';

export { REPORTES_COSTOS } from './costos.reporte.json';

export { REPORTES_CRM } from './crm.reporte.json';

export { REPORTES_IMPORTACIONES } from './importaciones.reporte.json';

export { REPORTES_LIBROS_ELECTRONICOS } from './libroselectronicos.reporte.json';

export { REPORTES_OPERACIONES } from './operaciones.reporte.json';

export { REPORTES_PLANILLA_DEPENDIENTES } from './planilla.reporte.json';
export { REPORTES_PLANILLA_GESTION } from './planilla.reporte.json';
export { REPORTES_PLANILLA_MENSUALES } from './planilla.reporte.json';
export { REPORTES_PLANILLA_NOMINA } from './planilla.reporte.json';
export { REPORTES_PLANILLA_REMUNERACION } from './planilla.reporte.json';

export { REPORTES_PRODUCCION } from './produccion.reporte.json';

export { REPORTES_PUNTO_DE_VENTA } from './puntoventa.reporte.json';

export { REPORTES_UTILITARIOS } from './utilitarios.reporte.json';

export { REPORTES_VENTAS } from './ventas.reporte.json';

export { retornarCódigoFiltroInformeNomina } from './planilla.reporte.json';
