export class Kardex {
  cia_codcia: string;
  ano_codano: string;
  mes_codmes: string;
  alm_codalm: string;
  tdo_codtdo: string;
  mac_nummac: string;
  mac_fecmac: any;
  mac_glomac: string;
  mac_gloma2: string;
  mmo_codmmo: string;
  opc_coropc: null;
  mac_tdore1: null;
  mac_numre1: string;
  mac_tdore2: null;
  mac_numre2: string;
  ano_coddes: string;
  mes_coddes: string;
  alm_coddes: string;
  tdo_coddes: string;
  mac_numdes: string;
  ano_codtra: null;
  mes_codtra: null;
  alm_codtra: null;
  tdo_codtra: null;
  mac_numtra: null;
  cco_codcco: null;
  mac_refpro: null;
  mac_tipcam: number;
  cli_codcli: string;
  pcc_numpcc: null;
  odc_numodc: null;
  grc_codtdo: null;
  grc_numdoc: null;
  egv_numegv: null;
  suc_codsuc: string;
  prd_codtra: null;
  mac_cantra: number;
  mac_indgen: number;
  rma_numrma: null;
  mac_indsta: string;
  mac_coduse: string;
  mac_fecupd: Date;
  sdm_codsdm: null;
  lfa_numlfa: null;
  oim_numoim: null;
  ocm_numocm: string;
  oin_numoin: null;
  otr_numotr: null;
  ccs_codccs: null;
  alm_codcom: string;
  tdo_codcom: string;
  mac_numcom: string;
  detalle_map: PartDetalleMap[];
  detalle_guia: Array<PartDetalleGuia>
  almacen: any;
  cliente: {cli_nomcli: string};
  constructor(
  ) {
    let month = new Date().getMonth() + 1;

    this.mac_indsta = "1";
    this.suc_codsuc = "01";
    this.mac_fecupd = new Date();
    this.mac_cantra = 0;
    this.mac_indgen = 0;
    this.mac_indsta = "1";
    this.mac_fecupd = new Date();
    this.mac_fecmac = new Date();

    this.detalle_guia = [new PartDetalleGuia()];
    this.detalle_map = [];

    this.cliente = {cli_nomcli: ''};
  }
}

export class PartDetalleMap {
  cia_codcia: string;
  ano_codano: string;
  mes_codmes: string;
  alm_codalm: string;
  tdo_codtdo: string;
  mac_nummac: string;
  prd_codprd: string;
  prd_codume: string;
  prd_desesp: string;
  map_secmap: string;
  map_cormap: string;
  map_lotmap: string;
  map_canprd: number;
  map_impmnp: number;
  map_impmnv: number;
  map_impmep: number;
  map_impmev: number;
  map_glomap: string;
  map_cosmnp: number;
  map_cosmnv: number;
  map_cosmep: number;
  map_cosmev: number;
  suc_codsuc: string;
  pcc_numpcc: null;
  odc_numodc: null;
  prd_codtra: null;
  map_canfac: number;
  map_ref001: string;
  map_canven: number;
  ume_codven: string;
  ume_codume: string;
  map_coduse: string;
  map_fecupd: Date;
  tdo_codgtr: null;
  grc_numgtr: null;
  tic_codtic: null;
  map_venfac: number;
  cco_codcco: null;
  map_salcan: number;
  map_salmna: number;
  map_salmex: number;
  map_refasi: null;
  ccs_codccs: null;
  seleccionado: boolean;
  tallas_mad: TallasMad[];
  distribucion_mpd: null;
  especificaciones_mae: null;
  tipoInventario: string;
  prd_indtal: 0 | 1;
  prd_indser: 0 | 1;
  prd_indlot: 0 | 1;

  constructor(
  ) {
    this.map_impmep = 0;
    this.map_impmev = 0;
    this.map_impmnp = 0;
    this.map_impmnv = 0;
    this.map_cosmep = 0;
    this.map_cosmev = 0;
    this.map_cosmnp = 0;
    this.map_cosmnv = 0;
    this.map_fecupd = new Date();
    this.map_canfac = 0;
    this.map_venfac = 0;
    this.map_salcan = 0;
    this.map_salmna = 0;
    this.map_salmex = 0;
    this.map_canprd = null;
    this.map_secmap = "01";
    this.map_cormap = "0001";
    this.map_lotmap = "0000000000";
    this.suc_codsuc = "01";
    this.map_glomap = "";

    this.tallas_mad = [new TallasMad()];

    this.seleccionado = false;

    this.prd_indtal = 0;
    this.prd_indser = 0;
    this.prd_indlot = 0;

  }
}

export class TallasMad {
  cia_codcia: string;
  ano_codano: string;
  mes_codmes: string;
  alm_codalm: string;
  tdo_codtdo: string;
  mac_nummac: string;
  prd_codprd: string;
  tal_codtal: string;
  mad_canprd: number;
  mad_impmnp: number;
  mad_impmnv: number;
  mad_impmep: number;
  mad_impmev: number;
  mad_glomad: null;
  mad_indsta: null;
  mad_coduse: string;
  mad_fecupd: string;
  mad_fecven: null;
  correlativo: string;
  seleccionado: boolean;
  lotes_mlo: DetalleLote[];
  series_mas: DetalleSerie[];

  constructor() {

    this.seleccionado = false;

    this.tal_codtal = '00';

    this.mad_impmnp = 0;
    this.mad_impmnv = 0;
    this.mad_impmep = 0;
    this.mad_impmev = 0;
    this.mad_canprd = 0;
    this.lotes_mlo = [];
    this.series_mas = [];

  }
}

export class DetalleLote {
  cia_codcia: string;
  ano_codano: string;
  mes_codmes: string;
  alm_codalm: string;
  tdo_codtdo: string;
  mac_nummac: string;
  prd_codprd: string;
  tal_codtal: string;
  mlo_numlot: string;
  mlo_canprd: number;
  mlo_impmnp: number;
  mlo_impmnv: number;
  mlo_impmep: number;
  mlo_impmev: number;
  mlo_glomlo: null;
  mlo_indsta: string;
  mlo_coduse: string;
  mlo_fecupd: string;
  mlo_fecfab: any;
  mlo_fecven: any;
  correlativo: string;
  pai_codpai: string;
  codigoISOPais: string;
  constructor(
  ) {
    this.mlo_indsta = "1";
    this.mlo_impmnp = 0;
    this.mlo_impmnv = 0;
    this.mlo_impmep = 0;
    this.mlo_impmev = 0;
    this.mlo_canprd = null;
    this.mlo_fecfab = new Date();

  }
}

export class DetalleSerie {
  cia_codcia: string;
  ano_codano: string;
  mes_codmes: string;
  alm_codalm: string;
  tdo_codtdo: string;
  mac_nummac: string;
  prd_codprd: string;
  tal_codtal: string;
  spr_numser: string;
  mas_descri: string;
  mas_fecven: Date;
  mas_coduse: string;
  correlativo: string;
  constructor(
  ) {
    this.tal_codtal = "00";
  }
}

export class PartDetalleGuia {
  cia_codcia: any;
  tdo_codtdo: any;
  grc_numdoc: any;
  grc_fecdoc: any;
  cli_codcli: string;
  grc_obsgrc: any;
  cli_codtra: any;
  cli_agetra: any;
  mmo_codmmo: any;
  cho_codcho: any;
  grc_nomcho: any;
  grc_nrolic: any;
  utr_codutr: any;
  grc_plaund: any;
  grc_nrocer: any;
  grc_marund: any;
  grc_undtra: any;
  lde_codlde: any;
  ldc_codldc: any;
  ldc_codldc2: any;
  grc_punint: any;
  grc_lugent: string;
  lde_codtra: any;
  lde_codage: any;
  pai_codpai: any;
  ubi_codubi: any;
  ano_codano: any;
  mes_codmes: any;
  alm_codalm: any;
  tdo_codsal: any;
  mac_nummac: any;
  sdo_codsdo: any;
  grc_indcod: any;
  tmo_codtmo: any;
  egv_numegv: any;
  grc_ordcom: any;
  grc_tipgrc: any;
  grc_tipanu: any;
  cco_codcco: any;
  grc_fecpgd: any;
  grc_indpgd: any;
  cde_codcde: any;
  grc_ordpgd: any;
  grc_entreg: any;
  cde_entreg: any;
  grc_nroenv: any;
  grc_penprg: any;
  grc_indsta: string;
  grc_coduse: string;
  grc_fecupd: any;
  grc_gloanu: any;
  ref_codtdo: any;
  ref_numdoc: any;
  grc_idegrc: any;
  grc_idetem: any;
  grc_codcon: any;
  grc_condic: any;
  grc_code: any;
  grc_moti: any;
  grc_fectra: Date;
  grc_indate: any;
  grc_fecate: any;
  grc_ordite: any;
  grc_desanu: any;
  grc_indenv: any;
  grc_indnue: any;
  grc_pesbru: any;
  grc_numbul: any;
  grc_codexp: any;
  grc_numexp: any;
  grc_obsgen: any;
  grc_placa2: any;
  t64_codt64: any;
  cli_codppt: any;
  lde_codppt: any;

  constructor(
  ) {
    this.grc_indsta = "1";
    this.grc_fecdoc = new Date();
    this.grc_fecupd = new Date();
    this.grc_indenv = 0;
    this.grc_idegrc = 0;
    this.egv_numegv = 0;
    this.grc_entreg = 0;
    this.grc_indpgd = 0;
    this.grc_nroenv = 0;
    this.grc_penprg = 1;
    this.grc_tipanu = 0;
    this.grc_idetem = 0;
    this.grc_indcod = ".";
    this.grc_tipgrc = "1";
    this.ldc_codldc = "01";
    this.tmo_codtmo = "SO";
    this.grc_fectra = new Date();
    this.tdo_codsal = "SAL";
    this.mac_nummac = "";
    this.grc_numdoc = "";
    this.tdo_codtdo = "GRE";
    this.grc_obsgrc = '';
    this.grc_obsgen = `Booking:
Contendedor:
P. Aduana:
P. Línea:
P. Planta:
P. Senasa:
Termo Registro:
`
  }
}
