import { ActividadCRMModule } from "./actividad-crm/actividad-crm.module";
import { CampaniaFormComponent, CampaniaListComponent } from "./campania";
import { CanalModule } from "./canal/canal.module";
import { ClienteCRMModule } from "./cliente-crm/cliente-crm.module";
import { EtapaModule } from "./etapa/etapa.module";
import { GrupoActividadFormComponent, GrupoActividadListComponent } from "./grupo-actividad";
import { GrupoActividadModule } from "./grupo-actividad/grupo-actividad.module";
import { LineaNegocioFormComponent, LineaNegocioListComponent } from "./linea-negocio";
import { LineaNegocioModule } from "./linea-negocio/linea-negocio.module";
import { PlantillaTecnicaFormComponent, PlantillaTecnicaListComponent } from "./plantilla-tecnica";
import { ResponsableFormComponent, ResponsableListComponent } from "./responsable";
import { SituacionOportunidadFormComponent, SituacionOportunidadListComponent } from "./situacion-oportunidad";
import { SituacionOportunidadModule } from "./situacion-oportunidad/situacion-oportunidad.module";
import { TipoAtencionModule } from "./tipo-atencion/tipo-atencion.module";
import { TipoCampaniaModule } from "./tipo-campania/tipo-campania.module";
import { TipoNegociacionModule } from "./tipo-negociacion/tipo-negociacion.module";

export const maestrosComponentsCrm = [
  PlantillaTecnicaListComponent,
  PlantillaTecnicaFormComponent,
  ResponsableListComponent,
  ResponsableFormComponent,
  CampaniaFormComponent,
  CampaniaListComponent
]

export const maestrosModulesCRM = [
  EtapaModule,
  CanalModule,
  ClienteCRMModule,
  ActividadCRMModule,
  TipoAtencionModule,
  TipoCampaniaModule,
  TipoNegociacionModule,
  SituacionOportunidadModule,
  LineaNegocioModule,
  GrupoActividadModule
]
