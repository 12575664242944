<app-pavso-title-section [title]="'Cobrador'" [module]="'Cuentas por cobrar'" [estado]="cobrador.COB_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <div class="row">
      <div class="col s12">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Código</mat-label>
                <input type="text" [(ngModel)]="cobrador.COB_CODCOB" matInput placeholder="Código" name="COB_CODCOB"
                  required [disabled]="loaderData">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Nombre</mat-label>
                <input type="text" [(ngModel)]="cobrador.COB_NOMCOB" matInput placeholder="Nombre" name="COB_NOMCOB"
                  required [disabled]="loaderData">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Dirección</mat-label>
                <input type="text" [(ngModel)]="cobrador.COB_DIRCOB" matInput placeholder="Dirección" name="COB_DIRCOB"
                  required [disabled]="loaderData">
              </mat-form-field>
            </div>

          </div>

        </mat-card>
        <br>
      </div>

    </div>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
