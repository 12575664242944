import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithDash } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { CotiComeEspeItemCci, CotiComePcd, CotiComeProdDescPds, Cotizacion } from "src/app/models/crm/cotizacion";
import { Presupuesto } from "src/app/models/crm/presupuesto";
import { ConfigurationService, VentasService } from "src/app/services";
import { CotizacionService } from "src/app/services/api/crm/operaciones/cotizacion.service";

@Component({
  selector: 'cotizacion-form',
  templateUrl: './cotizacion-form.component.html',
  styleUrls: ['./cotizacion-form.component.scss']
})
export class CotizacionFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;

  cotizacion: Cotizacion;

  displayedColumns: string[] = ['acciones', 'campo1', 'campo2', 'campo3', 'campo4', 'campo5', 'campo6', 'campo7','campo8','campo9','campo10','campo11','campo12','campo13','campo14','campo15'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsDescuento: string[] = ['acciones', 'descuento', 'porcentaje'];
  dataSourceDescuento: MatTableDataSource<any>;
  @ViewChild('paginatorDescuento') paginatorDescuento: MatPaginator;
  @ViewChild(MatSort) sortDescuento: MatSort;

  displayedColumnsEspecificacion: string[] = ['acciones', 'item', 'descripcion'];
  dataSourceEspecificacion: MatTableDataSource<any>;
  @ViewChild('paginatorEspecificacion') paginatorEspecificacion: MatPaginator;
  @ViewChild(MatSort) sortEspecificacion: MatSort;

  canales: any[] = [];
  formas: any[] = [];
  tiposPrecio: any[] = [];
  descuentos: any[] = [];

  buttonsName: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  vendedores$: Subscription;
  condiciones$: Subscription;
  canales$: Subscription;
  clientes$: Subscription;
  formas$: Subscription;
  tiposPrecio$: Subscription;
  productos$: Subscription;
  descuentos$: Subscription;
  sendForm$: Subscription;
  cotizacion$: Subscription;
  compras$: Subscription;

  clientes: any[] = [];
  vendedores: any[] = [];
  condiciones: any[] = [];
  productos: any[] = [];

  periodo$: Subscription;

  uid: string;
  btnName: string;
  nroCotization: any;

  year: string;
  month: string;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,
    private _cotizacionService: CotizacionService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
  ) {
    this.cotizacion = new Cotizacion();

    this.cotizacion.coduse = this._configurationService.obtenerIdUsuario();

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.cotizacion.codano = state.year;
      this.year = state.year;
      this.cotizacion.codmes = state.month;
      this.month = state.month;
    });

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);
    this.dataSourceEspecificacion = fillTable([], this.paginatorEspecificacion, this.sortEspecificacion);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {

      this.uid = id;

      this.loadMaestros();

      if(this.uid == '0') {
        this.btnName = 'general.button.store';
        return;
      }

      this.btnName = 'general.button.update';

    })
  }

  obtenerDesdePresupuesto(): void {
    const quoteFromBudget = localStorage.getItem('quote-from-budget');

    if(quoteFromBudget) {

      const presupuesto: Presupuesto = JSON.parse(quoteFromBudget);

      this.cotizacion.tipcam = Number(presupuesto.tipcam);
      this.cotizacion.codtmo = presupuesto.codtmo;
      this.cotizacion.idtkt = presupuesto.idtkt;
      this.cotizacion.idset = presupuesto.idset;
      this.cotizacion.codcli = presupuesto.codcli;

      const cliente = this.clientes.find(item => item.cli_codcli == this.cotizacion.codcli)
      this.cotizacion.dircli = cliente.cli_dircli;

      this.cotizacion.codcpd = presupuesto.idmtk;


      let detalleCotizacion = [];

      presupuesto.PRES_COTI_OPD.forEach(item => {
        const detalleItem = new CotiComePcd();
        detalleItem.codprd = item.codprd;
        detalleItem.cansol = Number(item.canopd);
        detalleItem.preuni = presupuesto.codtmo == 'SO'? item.cosofs: item.cosofd;
        detalleItem.pruigv = (detalleItem.preuni * (1 + this.cotizacion.tasigv / 100)).toFixed(2);
        detalleItem.impbru = (detalleItem.cansol * detalleItem.preuni).toFixed(2);
        detalleItem.valvta = Number(detalleItem.impbru) - detalleItem.impdes;
        detalleItem.impigv = (detalleItem.valvta * this.cotizacion.tasigv / 100).toFixed(2);
        detalleItem.imptot = (detalleItem.valvta + Number(detalleItem.impigv)).toFixed(2);

        console.log('detalelItem',detalleItem)

        detalleCotizacion.push(detalleItem);
      })

      this.cotizacion.COTI_COME_PCD = detalleCotizacion;

      this.dataSource = fillTable(detalleCotizacion, this.paginator, this.sort);

      localStorage.removeItem('quote-from-budget');

      // this.dataSource = fillTable(this.cotizacion.COTI_COME_PCD, this.paginator, this.sort);

      this.calcularTotales();
    }
  }

  seleccionarFila(row): void {

  }

  obtenerCotizacion(): void {
    this.cotizacion$ = this._cotizacionService.obtenerCotizacion(this.uid).subscribe(
      cotizacion => {
        console.log('COTIZACIÓN', cotizacion)
        this.cotizacion = cotizacion[0];
        this.establecerCamposDefecto();
        this.dataSource = fillTable(this.cotizacion.COTI_COME_PCD, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  establecerCamposDefecto(): void {
    this.cotizacion.codmmo = this.cotizacion.codmmo? this.cotizacion.codmmo: '0206';
    this.cotizacion.refpcc = this.cotizacion.refpcc? this.cotizacion.refpcc: '';

    this.cotizacion.indsta = this.cotizacion.indsta? this.cotizacion.indsta: '1';
    this.cotizacion.tipcam = this.cotizacion.tipcam? this.cotizacion.tipcam: 0;
    this.cotizacion.indimp = this.cotizacion.indimp? this.cotizacion.indimp: 0;
    this.cotizacion.indcom = this.cotizacion.indcom? this.cotizacion.indcom: 0;
    this.cotizacion.inddpx = this.cotizacion.inddpx? this.cotizacion.inddpx: 0;
    this.cotizacion.impbru = this.cotizacion.impbru? this.cotizacion.impbru: 0;
    this.cotizacion.valvta = this.cotizacion.valvta? this.cotizacion.valvta: 0;
    this.cotizacion.impigv = this.cotizacion.impigv? this.cotizacion.impigv: 0;
    this.cotizacion.impdes = this.cotizacion.impdes? this.cotizacion.impdes: 0;
    this.cotizacion.imptot = this.cotizacion.imptot? this.cotizacion.imptot: 0;
    this.cotizacion.plaent = this.cotizacion.plaent? this.cotizacion.plaent: 0;
    this.cotizacion.diacre = this.cotizacion.diacre? this.cotizacion.diacre: 0;
    this.cotizacion.codedo = this.cotizacion.codedo? this.cotizacion.codedo: '1';
    this.cotizacion.impseg = this.cotizacion.impseg? this.cotizacion.impseg: 0;
    this.cotizacion.impfle = this.cotizacion.impfle? this.cotizacion.impfle: 0;
    this.cotizacion.indexp = this.cotizacion.indexp? this.cotizacion.indexp: '0';
    this.cotizacion.codtie = this.cotizacion.codtie? this.cotizacion.codtie: '01';
    this.cotizacion.diapla = this.cotizacion.diapla? this.cotizacion.diapla: 0;
    this.cotizacion.tcalib = this.cotizacion.tcalib? this.cotizacion.tcalib: 0;
    this.cotizacion.indres = this.cotizacion.indres? this.cotizacion.indres: 0;
    this.cotizacion.impcan = this.cotizacion.impcan? this.cotizacion.impcan: 0;
    this.cotizacion.noimda = this.cotizacion.noimda? this.cotizacion.noimda: 0;
    this.cotizacion.numcuo = this.cotizacion.numcuo? this.cotizacion.numcuo: 0;
    this.cotizacion.susval = this.cotizacion.susval? this.cotizacion.susval: 0;
    this.cotizacion.sustot = this.cotizacion.sustot? this.cotizacion.sustot: 0;
    this.cotizacion.obsexp = this.cotizacion.obsexp? this.cotizacion.obsexp: '';
    this.cotizacion.indmax = this.cotizacion.indmax? this.cotizacion.indmax: 0;
    this.cotizacion.codsec = this.cotizacion.codsec? this.cotizacion.codsec: '';
    this.cotizacion.entvde = this.cotizacion.entvde? this.cotizacion.entvde: 0;
    this.cotizacion.pedcom = this.cotizacion.pedcom? this.cotizacion.pedcom: 0;
    this.cotizacion.desglo = this.cotizacion.desglo? this.cotizacion.desglo: 0;
    this.cotizacion.indap1 = this.cotizacion.indap1? this.cotizacion.indap1: 0;
    this.cotizacion.indap2 = this.cotizacion.indap2? this.cotizacion.indap2: 0;
    this.cotizacion.indter = this.cotizacion.indter? this.cotizacion.indter: '0';
    this.cotizacion.codano = this.cotizacion.codano? this.cotizacion.codano: this.year;
    this.cotizacion.codmes = this.cotizacion.codmes? this.cotizacion.codano: this.month;
    this.cotizacion.fecupd = this.cotizacion.fecupd? this.cotizacion.fecupd: new Date();
    this.cotizacion.coduse = this.cotizacion.coduse? this.cotizacion.coduse: this._configurationService.obtenerIdUsuario();

    this.cotizacion.COTI_COME_PCD.forEach(producto => {
      producto.cansol? producto.cansol = producto.cansol: producto.cansol = 0;
      producto.preuni? producto.preuni = producto.preuni: producto.preuni = 0;
      producto.pruigv? producto.pruigv = producto.pruigv: producto.pruigv = 0;
      producto.impbru? producto.impbru = producto.impbru: producto.impbru = 0;
      producto.pordes? producto.pordes = producto.pordes: producto.pordes = 0;
      producto.impdes? producto.impdes = producto.impdes: producto.impdes = 0;
      producto.valvta? producto.valvta = producto.valvta: producto.valvta = 0;
      producto.impigv? producto.impigv = producto.impigv: producto.impigv = 0;
      producto.imptot? producto.imptot = producto.imptot: producto.imptot = 0;
      producto.canaut? producto.canaut = producto.canaut: producto.canaut = 0;
      producto.cantra? producto.cantra = producto.cantra: producto.cantra = 0;
      producto.canate? producto.canate = producto.canate: producto.canate = 0;
      producto.canfas? producto.canfas = producto.canfas: producto.canfas = 0;
      producto.canats? producto.canats = producto.canats: producto.canats = 0;
      producto.canfac? producto.canfac = producto.canfac: producto.canfac = 0;
      producto.cansus? producto.cansus = producto.cansus: producto.cansus = 0;
      producto.candis? producto.candis = producto.candis: producto.candis = 0;
      producto.canven? producto.canven = producto.canven: producto.canven = 0;
      producto.candes? producto.candes = producto.candes: producto.candes = 0;
      producto.vtasus? producto.vtasus = producto.vtasus: producto.vtasus = 0;
      producto.totsus? producto.totsus = producto.totsus: producto.totsus = 0;
      producto.indode? producto.indode = producto.indode: producto.indode = 0;
      producto.inddes? producto.inddes = producto.inddes: producto.inddes = 0;
      producto.indsof? producto.indsof = producto.indsof: producto.indsof = 0;
      producto.indsod? producto.indsod = producto.indsod: producto.indsod = 0;
      producto.indaut? producto.indaut = producto.indaut: producto.indaut = 0;
      producto.indate? producto.indate = producto.indate: producto.indate = 0;
      producto.indfac? producto.indfac = producto.indfac: producto.indfac = 0;
      producto.atreg? producto.atreg = producto.atreg: producto.atreg = 0;
      producto.autreg? producto.autreg = producto.autreg: producto.autreg = 0;
      producto.fasreg? producto.fasreg = producto.fasreg: producto.fasreg = 0;
      producto.facreg? producto.facreg = producto.facreg: producto.facreg = 0;
      producto.desreg? producto.desreg = producto.desreg: producto.desreg = 0;
      producto.prelis? producto.prelis = producto.prelis: producto.prelis = 0;
      producto.canuni? producto.canuni = producto.canuni: producto.canuni = 0;
      producto.unisus? producto.unisus = producto.unisus: producto.unisus = 0;
      producto.venfac? producto.venfac = producto.venfac: producto.venfac = 0;
      producto.desglo? producto.desglo = producto.desglo: producto.desglo = 0;
      producto.indfot? producto.indfot = producto.indfot: producto.indfot = 0;
      producto.indimp? producto.indimp = producto.indimp: producto.indimp = 0;
      producto.indesp? producto.indesp = producto.indesp: producto.indesp = 0;
      producto.inddpr? producto.inddpr = producto.inddpr: producto.inddpr = '0';
      producto.atsreg? producto.atsreg = producto.atsreg: producto.atsreg = '0';
      producto.COTI_COME_PROD_DESC_PDS? producto.COTI_COME_PROD_DESC_PDS = producto.COTI_COME_PROD_DESC_PDS: producto.COTI_COME_PROD_DESC_PDS = [];
      producto.COTI_COME_ESPE_ITEM_CCI? producto.COTI_COME_ESPE_ITEM_CCI = producto.COTI_COME_ESPE_ITEM_CCI: producto.COTI_COME_ESPE_ITEM_CCI = [];
      producto.COTI_COME_DATO_ADIC_PDA? producto.COTI_COME_DATO_ADIC_PDA = producto.COTI_COME_DATO_ADIC_PDA: producto.COTI_COME_DATO_ADIC_PDA = [];
    });

  }

  seleccionarCliente(clienteForm): void {
    const cliente = this.clientes.find(item => item.cli_codcli == clienteForm.value);
    this.cotizacion.codcli = cliente.cli_codcli;
    this.cotizacion.dircli = cliente.cli_dircli;
  }

  trackByVendedor(index, vendedor): string {
    return vendedor? vendedor.VDE_CODVDE: undefined;
  }

  trackByCondicion(index, condicion): string {
    return condicion? condicion.CPA_CODCPA: undefined;
  }

  trackByFormaCalculo(index, forma): string {
    return forma? forma.fci_codfci: undefined;
  }

  trackByListaPrecio(index, listaPrecio): string {
    return listaPrecio? listaPrecio.TLP_CODTLP: undefined;
  }

  trackByCanal(index, canal): string {
    return canal? canal.idmtk: undefined;
  }

  trackByCliente(index, cliente): string {
    return cliente? cliente.cli_codcli: undefined;
  }

  trackByProducto(index, producto): string {
    return producto? producto.prd_codprd: undefined;
  }

  trackByDescuento(index, descuento): string {
    return descuento? descuento.dco_coddco: undefined;
  }

  /**
   * Método para obtener los maestro de:
   * . Vendedores
   * . Condiciones
   * . Canales
   * . Clientes
   * . Formulas
   * . Tipos Precio
   * . Productos
   * . Tipos Impuestos
   * . Descuentos
   */
  loadMaestros(): void {

    this._cotizacionService.obtenerDataFormCotizacion().subscribe(
      dataForm => {
        this.vendedores = dataForm.vendedores;
        this.condiciones = dataForm.condiciones;
        this.canales = dataForm.canales;
        this.clientes = dataForm.clientes;
        this.productos = dataForm.productos;

        this.formas = dataForm.formas;
        this.tiposPrecio = dataForm.tiposPrecio;
        this.descuentos = dataForm.descuentos;
        this.cotizacion.tasigv = dataForm.tiposImpuesto[0].porc_det;

        if(this.uid != '0') {
          this.obtenerCotizacion();
          return;
        }

        this.loaderData = false;
        this.obtenerDesdePresupuesto();

      },
      error => this._snackBarService.showError(error.error.msg, 'Ok')

    )



  }

  cambiarTasaIGV(): void {
    this.cotizacion.COTI_COME_PCD.forEach(item => {
      item.pruigv = item.preuni  * (1 + this.cotizacion.tasigv / 100);
      item.impigv = item.pruigv * item.cansol;
    });

    this.calcularTotales();
  }

  cambiarCantidad(row: CotiComePcd): void {

    this.calcularTotalRow(row);

  }

  cambiarPrecio(row): void {

    this.calcularTotalRow(row);

  }

  calcularTotalRow(row): void {
    row.pruigv = row.preuni  * (1 + this.cotizacion.tasigv / 100);
    row.impbru = row.cansol * row.preuni;
    row.valvta =  row.impbru - row.impdes;
    row.imptot = row.cansol * row.pruigv - row.impdes;
    // row.IMPIGV = row.IMPTOT - row.VALVTA;
    row.impigv = row.pruigv * row.cansol;

    this.calcularTotales();
  }

  agregarItem(): void {

    let producto = new CotiComePcd();
    producto.secpcd = '01';

    this.cotizacion.COTI_COME_PCD.push(producto);

    this.cotizacion.COTI_COME_PCD.forEach((element, key) => {
      element['corpcd'] = key + 1 > 9 ? `0${(key+1)}`:  `00${key + 1}`;
    });

    this.dataSource = fillTable(this.cotizacion.COTI_COME_PCD, this.paginator, this.sort);

  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row: CotiComePcd): void {
    if(!row.codprd) {
      this._snackBarService.showError('Establecer un producto', 'Ok')
      return;
    }

    if(!row.codven) {
      this._snackBarService.showError('Establecer unidad de medida', 'Ok')
      return;
    }

    if(!row.cansol) {
      this._snackBarService.showError('Establecer cantidad', 'Ok')
      return;
    }

    if(!row.preuni) {
      this._snackBarService.showError('Establecer precio unitario', 'Ok')
      return;
    }

    if(!row.glopcd) {
      this._snackBarService.showError('Establecer glosa', 'Ok')
      return;
    }
    row.isEditing = !row.isEditing;
  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSource.data.filter(item => item.corpcd != row.corpcd);
        filtered.forEach((element, key) => {
          element['corpcd'] = key + 1 > 9 ? `0${(key+1)}`:  `00${key + 1}`;
        })
        this.cotizacion.COTI_COME_PCD = filtered;
        this.dataSource = fillTable(filtered, this.paginator, this.sort);
      }
    })
  }

  seleccionarCB(value, index, row): void {

    this.dataSourceDescuento = fillTable(row.COTI_COME_PROD_DESC_PDS, this.paginatorDescuento, this.sortDescuento);
    this.dataSourceEspecificacion = fillTable(row.COTI_COME_ESPE_ITEM_CCI, this.paginatorEspecificacion, this.sortEspecificacion);

    console.log('value', value)
    console.log('index', index)
    console.log('row', row)

    if(value.checked) {
      this.cotizacion.COTI_COME_PCD.forEach((item, key) => {

        if(key != index) item['isSelect'] = false;

      })
    }

  }

  cambiarFecha(): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.cotizacion.fecdoc)).subscribe(
      tipoCambio => {
        this.cotizacion.tipcam = tipoCambio[0].tipcam;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  agregarDescuento(): void {
    let isSelected = false;
    let itemSelected: CotiComePcd;
    this.cotizacion.COTI_COME_PCD.forEach(item => {
      if(item.isSelect) {
        isSelected = true;
        itemSelected = item;
      }
    });

    if(!isSelected) {
      this._snackBarService.showError('Se debe de seleccionar un producto', 'Ok');
      return;
    }

    itemSelected.COTI_COME_PROD_DESC_PDS.push(new CotiComeProdDescPds());
    itemSelected.COTI_COME_PROD_DESC_PDS.forEach((element, key) => {
      element['corpds'] = key + 1 > 9 ? `0${(key+1)}`:  `00${key + 1}`;
    })

    this.dataSourceDescuento = fillTable(itemSelected.COTI_COME_PROD_DESC_PDS, this.paginatorDescuento, this.sortDescuento);
  }

  editarDescuento(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarDescuento(row): void {
    if(!row.coddco) {
      this._snackBarService.showError('Establecer descuento', 'Ok')
      return;
    }

    if(!row.pordes) {
      this._snackBarService.showError('Establecer porcentaje de descuento', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

   quitarDescuento(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceDescuento.data.filter(item => item.corpds != row.corpds);
        filtered.forEach((element, key) => {
          element['corpds'] = key + 1 > 9 ? `0${(key+1)}`:  `00${key + 1}`;
        })

        const producto = this.cotizacion.COTI_COME_PCD.find(item => item.isSelect);

        producto.COTI_COME_PROD_DESC_PDS = filtered;
        console.log('this.cotizacion', this.cotizacion);
        this.dataSourceDescuento = fillTable(filtered, this.paginatorDescuento, this.sortDescuento);
      }
    })
  }

  agregarEspecificacion(): void {
    let isSelected = false;
    let itemSelected: CotiComePcd;
    this.cotizacion.COTI_COME_PCD.forEach(item => {
      if(item.isSelect) {
        isSelected = true;
        itemSelected = item;
      };
    });

    if(!isSelected) {
      this._snackBarService.showError('Se debe de seleccionar un producto', 'Ok');
      return;
    }

    itemSelected.COTI_COME_ESPE_ITEM_CCI.push(new CotiComeEspeItemCci());
    itemSelected.COTI_COME_ESPE_ITEM_CCI.forEach((element, key) => {
      element['seccci'] = key + 1 > 9 ? (key+1).toString():  `0${key + 1}`;
    })

    this.dataSourceEspecificacion = fillTable(itemSelected.COTI_COME_ESPE_ITEM_CCI, this.paginatorEspecificacion, this.sortEspecificacion);

  }

  editarEspecificacion(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarEspecificacion(row): void {
    if(!row.descri) {
      this._snackBarService.showError('Establecer descripción', 'Ok')
      return;
    }
    row.isEditing = !row.isEditing;

  }

  quitarEspecificacion(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceEspecificacion.data.filter(item => item.seccci != row.seccci);
        filtered.forEach((element, key) => {
          element['seccci'] = key + 1 > 9 ? (key+1).toString():  `0${key + 1}`;
        })

        const producto = this.cotizacion.COTI_COME_PCD.find(item => item.isSelect);

        producto.COTI_COME_ESPE_ITEM_CCI = filtered;
        // this.cotizacion.COTI_COME_ESPE_ITEM_CCI = filtered;

        this.dataSourceEspecificacion = fillTable(filtered, this.paginatorEspecificacion, this.sortEspecificacion);
      }
    })
  }

  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;

    this.cotizacion.COTI_COME_PCD.forEach(element => {
      valorVenta += Number(element.valvta);
      impBruto += Number(element.impbru);
      impIGV += Number(element.impigv);
      descuento += Number(element.impdes);
      impTotal += Number(element.imptot);
    })

    this.cotizacion.valvta = valorVenta;

    this.cotizacion.impbru = impBruto;

    this.cotizacion.impigv = impIGV;

    this.cotizacion.impdes = descuento;

    this.cotizacion.imptot = impTotal;
  }

  copiarCotizacion(): void {

    if(this.nroCotization.trim()) {
      this._cotizacionService.obtenerCotizacion(this.nroCotization.trim()).subscribe(
        cotizacion => {
          this.cotizacion = cotizacion[0];
          this.dataSource = fillTable(this.cotizacion.COTI_COME_PCD, this.paginator, this.sort);
        },
        error => {
          this._snackBarService.showError(error.error.msg, 'Ok');
        }
      )
    }

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;

    this.cotizacion.indter ? '1': '0';

    this.cotizacion.COTI_COME_PCD.forEach(item => {
      item.coduse = this._configurationService.obtenerIdUsuario();

      item.COTI_COME_PROD_DESC_PDS.forEach(el => {
        el.porori = 0;
        el.impdes = 10;
        el.impcom = 10;
        el.incdes = 10,
        el.coduse = this._configurationService.obtenerIdUsuario();
      })
    })

    this.uid == '0' ? this.registrarCotizacion(f): this.actualizarCotizacion(f);
  }

  generarOrdenPedido(): void {
    localStorage.setItem('order-from-quote', JSON.stringify(this.cotizacion));
    this._router.navigate(['/modulo-crm/orden-pedido', 0]);
  }

  /**
   * Método para registrar una nueva cotización
   * @param f
   */
  registrarCotizacion(f: NgForm): void {
    this.sendForm$ = this._cotizacionService.registrarCotizacion(this.cotizacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.operaciones.cotizacion.success-store', '400px', 'auto', '');
        f.resetForm();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar cotización
   * @param f
   */
  actualizarCotizacion(f: NgForm): void {
    this.sendForm$ = this._cotizacionService.actualizarCotizacion(this.cotizacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.operaciones.cotizacion.success-update', '400px', 'auto', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  formularioValido(): boolean {
    if(!this.cotizacion.codcpd) {
      this._snackBarService.showError('Seleccionar canal', 'Ok');
      return false;
    }

    if(!this.cotizacion.codtmo) {
      this._snackBarService.showError('Seleccionar moneda', 'Ok');
      return false;
    }

    if(!this.cotizacion.codcli) {
      this._snackBarService.showError('Seleccionar cliente', 'Ok');
      return false;
    }

    if(!this.cotizacion.codvde) {
      this._snackBarService.showError('Seleccionar vendedor', 'Ok');
      return false;
    }

    if(!this.cotizacion.codcpa) {
      this._snackBarService.showError('Seleccionar condición de pago', 'Ok');
      return false;
    }

    if(!this.cotizacion.indcal) {
      this._snackBarService.showError('Seleccionar calcula item', 'Ok');
      return false;
    }

    if(!this.cotizacion.codtlp) {
      this._snackBarService.showError('Seleccionar lista de precio', 'Ok');
      return false;
    }

    if(this.cotizacion.COTI_COME_PCD.length == 0) {
      this._snackBarService.showError('Ingresar como mínimo un producto', 'Ok');
    }

    return true;
  }

  volver(): void {
    this._router.navigate(['/modulo-crm/cotizacion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.vendedores$,
      this.condiciones$,
      this.canales$,
      this.clientes$,
      this.formas$,
      this.tiposPrecio$,
      this.productos$,
      this.sendForm$,
      this.cotizacion$
    ])
  }
}
