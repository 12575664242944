import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { formatDateWithDash } from '@utils/formats/date.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subject, Subscription } from 'rxjs';
import { PresCotiOpd, Presupuesto } from 'src/app/models/crm/presupuesto';
import { ConfigurationService, VentasService } from 'src/app/services';
import { ApiCRMOperacionesPresupuestoService } from 'src/app/services/api/crm/operaciones/crm.operaciones.presupuesto.service';

@Component({
  selector: 'app-presupuesto-form',
  templateUrl: './presupuesto-form.component.html',
  styleUrls: ['./presupuesto-form.component.css']
})
export class PresupuestoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['acciones', 'item', 'cod_grupo', 'codigo', 'texto', 'cantidad_total', 'costo_unit', 'total_nac', 'incidencia_costo', 'margen_ganancia', 'imp_margen_us', 'dolar_unit_oferta', 'soles_unit_oferta', 'total_oferta', 'total_oferta_dolar', 'sec'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  minDate: Date = new Date();

  presupuesto: Presupuesto;

  responsables$: Subscription;
  grupos$: Subscription;
  productos$: Subscription;
  sendForm$: Subscription;
  presupuesto$: Subscription;
  monedas$: Subscription;
  tipoCambio$: Subscription;
  loading$: Subscription;
  impuestosRetencion$: Subscription;

  responsables: any[] = [];
  grupos: any[] = [];
  monedas: any[] = [];

  totalNacBien: number = 0;
  costoBien: number = 0;
  totalOfeBien: number = 0;

  totalNacEquipo: number = 0;
  costoEquipo: number = 0;
  totalOfeEquipo: number = 0;

  totalNacServicio: number = 0;
  costoServicio: number = 0;
  totalOfeServicio: number = 0;

  margenGanancia: number = 0;

  igv: number;

  productos: any[] = [];

  uid: string;

  constructor(
    private _apiPresupuestoCrmService: ApiCRMOperacionesPresupuestoService,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _router: Router,
    private store: Store<PavsoState>
  ) {

    this.presupuesto = new Presupuesto();
    this.presupuesto.coduse = this._configurationService.obtenerIdUsuario();

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this.loadMaestros();

    this._activatedRoute.params.subscribe(({idtkt, idset, numopc}) => {
      this.presupuesto.idtkt = idtkt;
      this.presupuesto.idset = idset;
      this.presupuesto.numopc = numopc;
    })

  }

  // trackByResponsable(index, responsable): string {
  //   return responsable? responsable.idres: null;
  // }

  // trackByMoneda(index, moneda): string {
  //   return moneda? moneda.TMO_CODTMO: null;
  // }

  // trackByGrupo(index, grupo): string {
  //   return grupo? grupo.opg_codopg: null;
  // }

  // trackByProducto(index, producto): string {
  //   return producto? producto.prd_codprd: null;
  // }

  /**
   * Método para obtener los maestro de:
   * . Responsables
   * . Grupos de presupuesto
   * . Productos
   * . Monedas
   * . Tipos de impuesto
   */
  loadMaestros(): void {
    this.loaderData = true;
    this._apiPresupuestoCrmService.obtenerDataFormPresupuesto().subscribe(
      dataForm => {
        this.responsables = dataForm.responsables? dataForm.responsables: [];
        this.grupos = dataForm.gruposPresupuesto? dataForm.gruposPresupuesto: [];
        this.productos = dataForm.productos? dataForm.productos: [];

        this.monedas = dataForm.monedas? dataForm.monedas: [];
        this.igv = dataForm.tiposImpuestoRetencion[0].porc_det;
        this.loadPresupuesto();
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderData = false;
      }
    )
  }

  /**
   * Carga el presupuesto ya sea desde el id del prespuesto
   * o desde la oportunidad y el seguimiento
   */
  loadPresupuesto(): void {
    if(this.presupuesto.numopc != '0') {
      this.presupuesto$ = this._apiPresupuestoCrmService.obtenerPresupuesto(this.presupuesto.numopc).subscribe(
        response => {
          if(response.length > 0) {

            this.presupuesto = response[0];

            if(!this.presupuesto.margen) this.presupuesto.margen = 0;

            // Establecer descripcion de producto
            this.presupuesto.PRES_COTI_OPD.forEach(element => {
              const producto = this.productos.find(item => item.prd_codprd == element['codprd'])
              element['descri'] = producto? producto.DESC_ESPA: '';
            });

            this.establecerSecueciaDetalle();

            this.presupuesto.impadm = 0;
            this.presupuesto.PRES_COTI_OPD? this.presupuesto.PRES_COTI_OPD = this.presupuesto.PRES_COTI_OPD: this.presupuesto.PRES_COTI_OPD = [];
            this.dataSource = fillTable(this.presupuesto.PRES_COTI_OPD, this.paginator, this.sort);
            this.establecerFormatoNumero();
            this.calcularTotalBES();

          }
        },
        error => {
          this.cambiarFecha();
          this._snackBarService.showError(error.error.msg, 'Ok');
        }
      )
    } else {
      this.presupuesto.numopc = null;
      if(this.presupuesto.idtkt == '0' && this.presupuesto.idset == '0') {

        this.presupuesto.idtkt = null;
        this.presupuesto.idset = null;
      } else {

        this.presupuesto$ = this._apiPresupuestoCrmService.obtenerPresupuestoOportunidadSeguimiento(this.presupuesto.idtkt, this.presupuesto.idset).subscribe(
          response => {

            console.log('PRESPUESTO', response)

            if(response.length > 0) {
              this.presupuesto = response[0];
              if(!this.presupuesto.margen) this.presupuesto.margen = 0;

              // Establecer descripcion de producto
              this.presupuesto.PRES_COTI_OPD.forEach(element => {
                const producto = this.productos.find(item => item.prd_codprd == element['codprd'])
                element['descri'] = producto? producto.DESC_ESPA: '';
                element.impmar = element.totnac * Number(this.presupuesto.margen);
              });

              this.establecerSecueciaDetalle();

              this.presupuesto.impadm = 0;
              this.presupuesto.PRES_COTI_OPD? this.presupuesto.PRES_COTI_OPD = this.presupuesto.PRES_COTI_OPD: this.presupuesto.PRES_COTI_OPD = [];
              this.dataSource = fillTable(this.presupuesto.PRES_COTI_OPD, this.paginator, this.sort);
              this.establecerFormatoNumero();

              this.calcularTotalBES()
            }
          },
          error => {
            this.cambiarFecha();

            this._snackBarService.showError(error.error.msg, 'Ok');
          }
        )
      }
    }
  }

  copiarPresupuesto(numopc): void {

    if(numopc.trim()) {
      this.presupuesto$ = this._apiPresupuestoCrmService.obtenerPresupuesto(numopc.value.trim()).subscribe(
        response => {
          if(response.length > 0) {
            this.presupuesto = response[0];

            if(!this.presupuesto.margen) this.presupuesto.margen = 0;

            // Establecer descripcion de producto
            this.presupuesto.PRES_COTI_OPD.forEach(element => {
              const producto = this.productos.find(item => item.prd_codprd == element['codprd'])
              element['descri'] = producto? producto.DESC_ESPA: '';
            });

            this.establecerSecueciaDetalle();

            this.presupuesto.impadm = 0;
            this.presupuesto.PRES_COTI_OPD? this.presupuesto.PRES_COTI_OPD = this.presupuesto.PRES_COTI_OPD: this.presupuesto.PRES_COTI_OPD = [];
            this.dataSource = fillTable(this.presupuesto.PRES_COTI_OPD, this.paginator, this.sort);
            this.establecerFormatoNumero();
          }
        },
        error => {
          this._snackBarService.showError(error.error.msg, 'Ok');
        }
      )
    }

  }

  seleccionarGrupo(row): void {

    const grupo = this.grupos.find(item => item.opg_codopg == row.codopg);
    row.descriopg = grupo.opg_descri;

    this.calcularTotalBES();

  }

  seleccionarProducto(row): void {

    const producto = this.productos.find(item => item.prd_codprd == row.codprd);
    row.descri = producto.DESC_ESPA;

    const productos: Array<PresCotiOpd> = this.presupuesto.PRES_COTI_OPD.filter(item => item.codprd == row.codprd);

    productos.forEach((item, index) => {
      item.secopd = (index + 1 > 9) ? (index + 1).toString(): `0${index + 1}`;

    });

  }

  establecerSecueciaDetalle(): void {
    const agrupado = this.agrupar(this.presupuesto.PRES_COTI_OPD, 'codprd');
    const keys = Object.keys(agrupado);

    keys.forEach(key => {
      let filtrado = this.presupuesto.PRES_COTI_OPD.filter(item => item.codprd == key);

      filtrado.forEach((item, index) => {
        item['secopd'] = (index + 1 > 9) ? (index + 1).toString(): `0${index + 1}`
      })
    });

    this.presupuesto.PRES_COTI_OPD.forEach
  }

  agrupar(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  cambiarFecha(): void {
    console.log('this.presupuesto.fecopc', this.presupuesto.fecopc)
    console.log('formatDateWithDash(this.presupuesto.fecopc)', formatDateWithDash(this.presupuesto.fecopc))
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.presupuesto.fecopc)).subscribe(
      tipoCambio => {
        this.presupuesto.tipcam = tipoCambio[0].tipcam.toFixed(4);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  cambiarCantidad(row): void {

    this.calcularImporteRow(row);

    }

  cambiarCosto(row): void {

    this.calcularImporteRow(row);

  }

  cambiarPorIncidencia(row: PresCotiOpd): void {

    this.calcularImporteRow(row);

  }

  calcularImporteRow(row: PresCotiOpd): void {
    row.totnac = Number(row.canopd) * Number(row.cosuni);

    row.margen = ((row.porinc / 100) * Number(row.cosuni)).toFixed(2);
    row.impmar = Number(row.margen) * Number(row.canopd);

    row.cosofd = this.presupuesto.codtmo == 'SO'? (Number(row.margen) + Number(row.cosuni)) / Number(this.presupuesto.tipcam): Number(row.margen) + Number(row.cosuni);
    row.cosofs = this.presupuesto.codtmo == 'SO'? Number(row.margen) + Number(row.cosuni): (Number(row.margen) + Number(row.cosuni)) * Number(this.presupuesto.tipcam);

    row.totofs = Number(row.cosofs)  * Number(row.canopd);
    row.totofd = Number(row.cosofd) * Number(row.canopd);

    this.calcularTotalBES();
  }

  blurMargen(): void {

    this.presupuesto.margen = Number(this.presupuesto.margen).toFixed(2);

  }

  blurTipoCambio(): void {

    this.presupuesto.tipcam = Number(this.presupuesto.tipcam).toFixed(4);

  }

  blurTipoCambioEur(): void {

    this.presupuesto.tipeur = Number(this.presupuesto.tipeur).toFixed(4);

  }

  blurPotencialTotal(): void {

    this.presupuesto.numkwp = Number(this.presupuesto.numkwp).toFixed(2);

  }

  blurCostoServicio(): void {

    this.presupuesto.marser = Number(this.presupuesto.marser).toFixed(2);
    this.calcularTotalBES();

  }

  blurCostoAdmin(): void {

    this.presupuesto.maradm = Number(this.presupuesto.maradm).toFixed(2);
    this.calcularTotalBES();

  }

  blurCantidadProducto(row: PresCotiOpd): void {

    row.canopd = Number(row.canopd).toFixed(2);

  }

  blurCostoUnitarioProducto(row: PresCotiOpd): void {

    row.cosuni = Number(row.cosuni).toFixed(2);

  }

  establecerFormatoNumero(): void {
    this.presupuesto.tipcam = Number(this.presupuesto.tipcam).toFixed(2);
    this.presupuesto.margen = Number(this.presupuesto.margen).toFixed(2);
    this.presupuesto.tipeur = Number(this.presupuesto.tipeur).toFixed(2);
    this.presupuesto.numkwp = Number(this.presupuesto.numkwp).toFixed(2);
    this.presupuesto.marser = Number(this.presupuesto.marser).toFixed(2);
    this.presupuesto.maradm = Number(this.presupuesto.maradm).toFixed(2);
  }

  calcularTotalBES(): void {
    // Total Nac
    this.totalNacBien = 0;
    this.totalNacEquipo = 0;
    this.totalNacServicio = 0;

    // Incidencia
    this.costoBien = 0;
    this.costoEquipo = 0;
    this.costoServicio = 0;

    // Total ofertada
    this.totalOfeBien = 0;
    this.totalOfeEquipo = 0;
    this.totalOfeServicio = 0;

    this.presupuesto.PRES_COTI_OPD.map(item => {

      item.totofd = item.totofd? item.totofd : 0;

      if(item.codopg == '001') {
        this.totalNacBien = this.totalNacBien + item.totnac;
        this.costoBien = this.costoBien + Number(item.impmar);
        this.totalOfeBien = this.totalNacBien + this.costoBien;
      }

      if(item.codopg == '002') {
        this.totalNacEquipo = this.totalNacEquipo + item.totnac;
        this.costoEquipo = this.costoEquipo + Number(item.impmar);
        this.totalOfeEquipo = this.totalNacEquipo + this.costoEquipo;
      }

      if(item.codopg == '003') {
        this.totalNacServicio = this.totalNacServicio + item.totnac;
        this.costoServicio = this.costoServicio + Number(item.impmar);
        this.totalOfeServicio = this.totalNacServicio + this.costoServicio;
      }

    })

    this.presupuesto.subtot = this.totalOfeBien + this.totalOfeEquipo + this.totalOfeServicio;

    this.presupuesto.impadm = (this.totalNacBien + this.totalNacEquipo + this.totalNacServicio) * Number(this.presupuesto.maradm) / 100;
    this.presupuesto.impser = this.totalNacServicio * Number(this.presupuesto.marser) / 100;

    this.presupuesto.valvta = this.presupuesto.impadm + this.presupuesto.subtot;
    this.presupuesto.impigv = this.presupuesto.subtot * (this.igv / 100);
    this.presupuesto.imptot = this.presupuesto.subtot * (1 + this.igv / 100);

  }

  completarIndicadores(): void {
    this.presupuesto.indsis = this.presupuesto.indsis ? 1: 0;
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.completarIndicadores();

    this.loaderReg = true;


    this.uid == '0' ? this.registrarPresupuesto(f): this.actualizarPresupuesto(f);
  }



  /**
   * Método para registrar un nuevo presupuesto
   * @param f
   */
  registrarPresupuesto(f: NgForm): void {
    this.presupuesto.tipcam = Number(this.presupuesto.tipcam);
    this.presupuesto.tipeur = Number(this.presupuesto.tipeur);
    this.presupuesto.margen = Number(this.presupuesto.margen);
    this.presupuesto.marser = Number(this.presupuesto.marser);
    this.presupuesto.maradm = Number(this.presupuesto.maradm);
    this.presupuesto.numkwp = Number(this.presupuesto.numkwp);

    this.presupuesto.PRES_COTI_OPD.forEach(item => {
      item['canopd'] = Number(item['canopd']);
      item['cosuni'] = Number(item['cosuni']);
      item['margen'] = Number(item['margen']);
    })

    this.sendForm$ = this._apiPresupuestoCrmService.registrarPresupuesto(this.presupuesto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.operaciones.presupuesto.success-store', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar presupuesto
   * @param f
   */
  actualizarPresupuesto(f: NgForm): void {
    this.presupuesto.tipcam = Number(this.presupuesto.tipcam);
    this.presupuesto.tipeur = Number(this.presupuesto.tipeur);
    this.presupuesto.margen = Number(this.presupuesto.margen);
    this.presupuesto.marser = Number(this.presupuesto.marser);
    this.presupuesto.maradm = Number(this.presupuesto.maradm);
    this.presupuesto.numkwp = Number(this.presupuesto.numkwp);

    this.presupuesto.PRES_COTI_OPD.forEach(item => {
      item['canopd'] = Number(item['canopd']);
      item['cosuni'] = Number(item['cosuni']);
      item['margen'] = Number(item['margen']);
    })

    this.establecerCamposDefecto();

    this.sendForm$ = this._apiPresupuestoCrmService.actualizarPresupuesto(this.presupuesto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.operaciones.presupuesto.success-update', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  formularioValido(): boolean {
    if(this.presupuesto.PRES_COTI_OPD.length == 0) {
      this._snackBarService.showError('Ingresar al menos un producto', 'Ok');
      return false;
    }

    if(!this.presupuesto.cliaut) {
      this._snackBarService.showError('Seleccionar una autorización', 'Ok');
      return false;
    }

    if(!this.presupuesto.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    if(!this.presupuesto.codtmo) {
      this._snackBarService.showError('Seleccionar la moneda', 'Ok');
      return false;
    }



    return true;
  }

  establecerCamposDefecto(): void {
    // Campos generales
    this.presupuesto.indapr? this.presupuesto.indapr = this.presupuesto.indapr: this.presupuesto.indapr = 0;
    this.presupuesto.indkwp? this.presupuesto.indkwp = this.presupuesto.indkwp: this.presupuesto.indkwp = 0;
    this.presupuesto.indsis? this.presupuesto.indsis = this.presupuesto.indsis: this.presupuesto.indsis = 0;
    this.presupuesto.utilid? this.presupuesto.utilid = this.presupuesto.utilid: this.presupuesto.utilid = 0;
    this.presupuesto.impadm? this.presupuesto.impadm = this.presupuesto.impadm: this.presupuesto.impadm = 0;
    this.presupuesto.margen? this.presupuesto.margen = this.presupuesto.margen: this.presupuesto.margen = 0;
    this.presupuesto.maradm? this.presupuesto.maradm = this.presupuesto.maradm: this.presupuesto.maradm = 0;
    this.presupuesto.marser? this.presupuesto.marser = this.presupuesto.marser: this.presupuesto.marser = 0;
    this.presupuesto.subtot? this.presupuesto.subtot = this.presupuesto.subtot: this.presupuesto.subtot = 0;
    this.presupuesto.impadm? this.presupuesto.impadm = this.presupuesto.impadm: this.presupuesto.impadm = 0;
    this.presupuesto.valvta? this.presupuesto.valvta = this.presupuesto.valvta: this.presupuesto.valvta = 0;
    this.presupuesto.impigv? this.presupuesto.impigv = this.presupuesto.impigv: this.presupuesto.impigv = 0;
    this.presupuesto.imptot? this.presupuesto.imptot = this.presupuesto.imptot: this.presupuesto.imptot = 0;
  }

  generarCotizacion(): void {
    localStorage.setItem('quote-from-budget', JSON.stringify(this.presupuesto));
    this._router.navigate(['/modulo-crm/cotizacion', '0']);
  }

  agregarItem(): void {

    let item = new PresCotiOpd();
    item.porinc = Number(this.presupuesto.margen);

    this.presupuesto.PRES_COTI_OPD.push(item);

    this.presupuesto.PRES_COTI_OPD.forEach((element, key) => {
      element['coropd'] = `00${key+1}`;
    })

    this.dataSource = fillTable(this.presupuesto.PRES_COTI_OPD, this.paginator, this.sort);

  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row): void {

    row.isEditing = !row.isEditing;

    this.calcularTotalBES();
    this.presupuesto.PRES_COTI_OPD.forEach(item => {

    });

  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSource.data.filter(item => item.coropd != row.coropd);
        filtered.forEach((element, key) => {
          element['coropd'] = `00${key+1}`;;
        })
        this.presupuesto.PRES_COTI_OPD = filtered;
        this.dataSource = fillTable(filtered, this.paginator, this.sort);
      }
    })
  }

  cambiarMargen(event): void {
    this.presupuesto.margen = event.toFixed(2)
  }

  convertirAFloat(number): number {
    return Number(number)
  }

  volver(): void {
    this._router.navigate(['/modulo-crm/presupuesto'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.responsables$,
      this.grupos$,
      this.productos$,
      this.sendForm$,
      this.presupuesto$,
      this.monedas$,
      this.tipoCambio$,
      this.loading$,
      this.impuestosRetencion$
    ])

  }
}
