import { Component } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { PedidoListDialog } from "../pedido-list-dialog/pedido-list-dialog";

@Component({
  selector: 'pedido-bottom-sheet',
  templateUrl: './pedido-bottom-sheet.html',
  styleUrls: ['./pedido-bottom-sheet.scss']
})
export class PedidoBottomSheet {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<PedidoBottomSheet>,
    public dialog: MatDialog
  ) {}

  verPedidos(): void {
    const dialogRef = this.dialog.open(PedidoListDialog, {
      width: 'auto',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      this._bottomSheetRef.dismiss(result);
    });
  }
}
