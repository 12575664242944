<h1 mat-dialog-title>Cambios de requerimiento</h1>

<div class="mat-elevation-z0 overflow-x">

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="usuario_modificacion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario modificación </th>
      <td mat-cell *matCellDef="let row" data-label="Código"> {{row.prd_codprd}} </td>
    </ng-container>

    <ng-container matColumnDef="fecha_modificacion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha modificación </th>
      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.prd_desesp}} </td>
    </ng-container>

    <ng-container matColumnDef="requerimiento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Requerimiento </th>
      <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.MPR_DESCRI}} </td>
    </ng-container>

    <ng-container matColumnDef="cod_solicitado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. solicitado </th>
      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ume_codume}} </td>
    </ng-container>

    <ng-container matColumnDef="solicitado_por">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitado por </th>
      <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.spa_cansal}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{cursor: 'pointer'}"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="19">No se encontraron registros</td>
    </tr>

  </table>

</div>
<mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
