import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { UbicacionActivoFijo } from 'src/app/models/activo-fijo/ubicacion_activo_fijo';
import { AuthenticationService } from 'src/app/services';
import { UbicacionActivoService } from 'src/app/services/api/activo-fijo/maestros/ubicacion-activo.service';

@Component({
  selector: 'app-ubicacion-activo-form',
  templateUrl: './ubicacion-activo-form.component.html',
  styleUrls: ['./ubicacion-activo-form.component.css'],
})
export class UbicacionActivoFormComponent implements OnInit, OnDestroy {
  loaderReg: boolean = false;
  loaderData: boolean = false;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  ubicacion: UbicacionActivoFijo;
  ubicacion$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _ubicacionActivoService: UbicacionActivoService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>
  ) {
    this.ubicacion = new UbicacionActivoFijo();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      this.loadMaestros();
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      if (id == '0') return;
    });
  }

  obtenerUbicacionActivo(): void {
    this.ubicacion$ = this._ubicacionActivoService
      .obtenerUbicacionActivo(this.uid)
      .subscribe(
        ([ubicacion]) => {
          this.ubicacion = ubicacion;

        },
        (error) => {
          this._snackBarService.showError(
            'Error al obtener ubicación activo',
            'OK'
          );
        }
      );
  }

  loadMaestros(): void {
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0'
      ? this.registrarUbicacionActivo(f)
      : this.actualizarUbicacionActivo(f);
  }

  registrarUbicacionActivo(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._ubicacionActivoService.registrarUbicacionActivo(this.ubicacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Ubicación de activo registrado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarUbicacionActivo(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._ubicacionActivoService.actualizarUbicacionActivo(this.ubicacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Ubicación de activo actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-activo-fijo/ubicacion-activo']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.ubicacion$]);
  }
}
