<app-pavso-title-section [title]="'Tipo de lista precio'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <div class="pav-table-search-container">
      <div class="pav-separator"></div>
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
      </div>
    </div>

      <div class="mat-elevation-z0">

        <table-title-action title="Todos los tipo de lista de precio" url="/modulo-ventas/tipo-lista-precio" entity="" [params]="['0']"></table-title-action>

        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <table mat-table [dataSource]="dataSource" matSort >

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <a
                      class="pav-btn-circle-edit"
                      [routerLink]="['/modulo-ventas/tipo-lista-precio', row.tlp_codtlp]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="tlp_codtlp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td #container mat-cell *matCellDef="let row" data-label="Código" class="td-responsive">
                  {{row.tlp_codtlp}}
                </td>
            </ng-container>

            <ng-container matColumnDef="tlp_descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.tlp_descri}}  </td>
            </ng-container>

            <ng-container matColumnDef="tlp_indsta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.tlp_indsta == '1'? 'ACTIVO':'INACTIVO'}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
  </div>
