export class Color {
  col_codcol: string;
  col_descri: string;
  col_desabr: string;
  col_indsta: string;
  col_coduse: string;
  col_fecupd: Date;
  col_ralcol: string;

  constructor() {
    this.col_indsta = '1';
    this.col_fecupd = new Date();
  }
}
