<h1 mat-dialog-title>Agregar establecimiento</h1>
<form #f="ngForm" ngNativeValidate (ngSubmit)="agregarEstablecimiento(f)" (keydown.enter)="$event.preventDefault()">
    <div mat-dialog-content>

        <div class="row">
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Establecimiento</mat-label>
                    <input matInput placeholder="Establecimiento" name="LDE_NOMLDE" [(ngModel)]="establishment.LDE_NOMLDE" required>
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Dirección</mat-label>
                    <input matInput placeholder="Dirección" name="LDE_DIRLDE" [(ngModel)]="establishment.LDE_DIRLDE" required>
                </mat-form-field>
            </div>
            <div class="col s12 m4">
                <mat-form-field>
                    <mat-label>Referencia</mat-label>
                    <input matInput placeholder="Referencia" name="LDE_REFDIR" [(ngModel)]="establishment.LDE_REFDIR" required>
                </mat-form-field>
            </div>
            <div class="col s12 m4">

              <select-search
                [label]="'País'"
                [placeholder]="'Ej. PERÚ'"
                [name]="'PAI_CODPAI'"
                [value]="'PAI_CODPAI'"
                [description]="'pai_nomlar'"
                [data]="paises"
                (cambioSelect)="establishment.PAI_CODPAI = $event;"
                [model]="establishment.PAI_CODPAI">
              </select-search>

            </div>

            <div class="col s12 m4">
              <select-search
                [label]="'Ubicación'"
                [placeholder]="'Ej. LIMA'"
                [name]="'ubi_codubi'"
                [value]="'ubi_codubi'"
                [description]="'ubi_desubi'"
                [data]="ubicaciones"
                (cambioSelect)="establishment.UBI_CODUBI = $event;"
                [model]="establishment.UBI_CODUBI">
              </select-search>
            </div>

            <div class="col s12 m4">
              <select-search
                [label]="'Zona de Venta'"
                [placeholder]="'Ej. LIMA'"
                [name]="'ubi_codubi'"
                [value]="'ubi_codubi'"
                [description]="'zve_nomzve'"
                [data]="zonas"
                (cambioSelect)="establishment.ZVE_CODZVE = $event;"
                [model]="establishment.ZVE_CODZVE">
              </select-search>
            </div>

            <div class="col s12 m4">
                <section class="example-section">
                    <mat-checkbox class="example-margin" name="LDE_INDPRI" [(ngModel)]="establishment.LDE_INDPRI">Principal</mat-checkbox>
                </section>
            </div>

        </div>

    </div>
    <div mat-dialog-actions>
        <button type="button" mat-stroked-button mat-button (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
        <button mat-stroked-button mat-button color="primary" type="submit">Registrar</button>

    </div>
</form>
