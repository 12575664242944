<app-pavso-title-section [title]="'Interés de banco CTS'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarInteresBancoCTS(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Período</mat-label>
            <input [(ngModel)]="periodo" matInput type="text" name="periodo" readonly>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="pav-table-header-container">
      <div class="pav-table-header-icon">

      </div>
      <div class="pav-table-header-title">
        Tabla
      </div>
      <div class="pav-table-header-add">
        <button mat-flat-button class="pav-button-add-item" type="button"
          (click)="agregarItem()">Agregar ítem</button>
      </div>
    </div>
    <div class="mat-elevation-z0">

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" data-label="Acciones" class="td-responsive">
            <div class="pav-btns-container">
              <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
              <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm"
                matTooltip="Confirmar">
                <mat-icon svgIcon="confirm"></mat-icon>
              </div>
              <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="codban">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Banco </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Banco">
            <ng-container *ngIf="row.isEditing">
              <select-search
                [label]="''"
                [placeholder]="'Ej. '"
                [name]="'BAN_CODBAN'"
                [value]="'BAN_CODBAN'"
                [description]="'BAN_NOMBRE'"
                [data]="bancos"
                (cambioSelect)="row.codban = $event"
                [multiple]="true"
                [model]="row.codban">
              </select-search>

            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codban}} | {{row.nombic}}
            </ng-container>

          </td>
        </ng-container>

        <ng-container matColumnDef="codtmo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Moneda">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-mat-form-margin-top">
                <mat-label></mat-label>
                <mat-select [(ngModel)]="row.codtmo" [name]="'moneda'+i">
                  <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
                    {{moneda.tmo_deslar}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codtmo}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="porint">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Porcentaje </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Porcentaje">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                <mat-label></mat-label>
                <input min="0" max="30" required type="number" matInput placeholder="Porcentaje" [(ngModel)]="row.porint"
                  [name]="'porcentaje' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.porint}}
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No se encontraron registros</td>

        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
