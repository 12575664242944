import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-control-cierre-contable',
  templateUrl: './control-cierre-contable.component.html',
  styleUrls: ['./control-cierre-contable.component.css']
})
export class ControlCierreContableComponent implements OnInit {

  displayedColumns: string[] = ['codigo', 'nombre_largo', 'descripcion_corta', 'estado'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
  }

}
