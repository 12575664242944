import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Banco } from "src/app/models/general/banco";
import { BancosVentasService } from "src/app/services/api/ventas/maestros/bancos.service";

@Component({
  selector: 'banco-form',
  templateUrl: './banco-form.component.html',
  styleUrls: ['./banco-form.component.scss']
})
export class BancoFormComponent implements OnInit, OnDestroy {

  uid: string;

  banco$: Subscription;
  sendForm$: Subscription;

  loaderReg: boolean;
  loaderData: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  btnName: string = 'general.button.store';

  loading$: Subscription;

  banco: Banco;

  constructor(
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _apiBancosVentasService: BancosVentasService,
    private _snackBarService: SnackBarService,
    private _router: Router
  ) {
    this.banco = new Banco();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({id}) => this.uid = id)

    if(this.uid == '0') {
      this.btnName = 'general.button.store';
      return;
    }

    this.btnName = 'general.button.update';
    this.obtenerBanco();

  }

  obtenerBanco(): void {
    this.banco$ = this._apiBancosVentasService.obtenerBanco(this.uid).subscribe(
      banco => {
        this.banco = banco[0];
        console.log('banco', banco)
      },
      error => {
        this._snackBarService.showError('Error al obtener banco', 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {

    this.uid == '0' ? this.registrarBanco(f): this.editarBanco(f);

  }

  registrarBanco(f: NgForm): void {
  }

  editarBanco(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/bancos'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.banco$, this.sendForm$
    ])
  }

}
