export class DiasEntrega {
    cia_codcia:string;
    cli_codcli:string;
    lde_codlde:Date;
    dia_coddia:string;
    dia_desesp:string;
    dia_horini:string;
    dia_horfin:string;
    dia_refest:string;
    dia_fecupd:Date;
    dia_coduse:string;
    isAditionalActive:boolean;
    isEditing: boolean;
    constructor(){
        this.isEditing = true;
        this.isAditionalActive =false;
        this.dia_fecupd = new Date();
    }
}