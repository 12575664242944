import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { ConfiguracionRentaQuinta, DetalleConfiguraRentaQuinta } from 'src/app/models/planilla/configuraciones/configuracion-renta-quinta';
import { AuthenticationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { DialogService } from '@shared/services/dialog.service';

@Component({
  selector: 'configuracion-renta-quinta-create',
  templateUrl: './configuracion-renta-quinta-create.component.html',
  styleUrls: ['./configuracion-renta-quinta-create.component.css']
})
export class ConfiguracionRentaQuintaCreateComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['actions', 'cornqd', 'descri', 'numuit', 'porren', 'monto'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  year: string;

  configuraRentaQuinta: ConfiguracionRentaQuinta;

  configuracionRenta$: Subscription;
  period$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>,
    private _nominaService: NominaService,
    private _dialogService: DialogService
  ) {

    this.usuario = this._authService.getUsuarioSistema();
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.configuraRentaQuinta = new ConfiguracionRentaQuinta();

    this.period$ = this.store.select('period').subscribe(state =>{
      this.year = state.year;
    })

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this.btnName = this.buttonsName.BTN_STORE;

    this.configuracionRenta$ = this._nominaService.obtenerConfiguracionRentaQuinta(this.year).subscribe(
      configuracionRenta => {
        this.configuraRentaQuinta = configuracionRenta;

        this.dataSource = fillTable(configuracionRenta.detalle, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError("Error al obtener renta de quinta", "OK");
      }
    )

  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarConfiguraRentaQuinta(f): this.actualizarConfiguraRentaQuinta(f);
  }

  registrarConfiguraRentaQuinta(f: NgForm): void {}

  actualizarConfiguraRentaQuinta(f: NgForm): void {}

  obtenerAnioAnterior(): void {
    const previousYear = Number(this.year) - 1;

    this.configuracionRenta$ = this._nominaService.obtenerConfiguracionRentaQuinta(previousYear.toString()).subscribe(
      configuracionRenta => {
        this.configuraRentaQuinta = configuracionRenta;

        this.dataSource = fillTable(configuracionRenta.detalle, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError("Error al obtener renta de quinta", "OK");
      }
    )
  }

  agregarItem(): void {

    this.configuraRentaQuinta.detalle.push(new DetalleConfiguraRentaQuinta());

    this.configuraRentaQuinta.detalle.forEach((element, key) => {
      element['correlativo'] = key;
    })

    this.dataSource = fillTable(this.configuraRentaQuinta.detalle, this.paginator, this.sort);

  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row): void {
    if(!row.descri || !row.numuit || !row.porren || !row.monto) {
      this._snackBarService.showError('Todos los campos de fila deben estar compeltos', 'Ok')
      return;
    }
    row.isEditing = !row.isEditing;

  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSource.data.filter(item => item.correlativo != row.correlativo);
        filtered.forEach((element, key) => {
          element['correlativo'] = key;
        })
        this.configuraRentaQuinta.detalle = filtered;
        this.dataSource = fillTable(filtered, this.paginator, this.sort);
      }
    })
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/rentas-de-quinta']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.configuracionRenta$, this.period$, this.loading$]);
  }

}
