<h1 mat-dialog-title>Copiar presupuesto</h1>

<div>
  <mat-form-field class="example-full-width">
    <mat-label>Nro. presupuesto</mat-label>
    <input matInput placeholder="Ej. 01254">
  </mat-form-field>
</div>

<div>
  <button mat-stroked-button color="primary">Copiar</button>

</div>
