<h1 mat-dialog-title>Generar Excel</h1>
<div mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Opciones" labelClass="mat-tab-label-0-0">
            <div class="content-generate-excel">
                <div class="row">
                    <div class="col s12 m7">
                        <mat-card class="mat-elevation-z0">
                            <mat-checkbox [(ngModel)]="seleccionar" name="seleccionar" class="example-margin">Seleccionar</mat-checkbox>
                            <br>
                            <br>
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="generateModel.typeDate">
                                <mat-radio-button [disabled]="!seleccionar" value="1" selected="true">Fecha de emisión</mat-radio-button>
                                &nbsp;
                                <mat-radio-button [disabled]="!seleccionar" value="2">Fecha de entrega</mat-radio-button>
                            </mat-radio-group>
                            <br>
                            <mat-form-field>
                                <mat-label>Desde - Hasta</mat-label>
                                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                                    <input matStartDate formControlName="start" placeholder="Start date">
                                    <input matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>

                                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                            </mat-form-field>
                        </mat-card>
                        <br>
                        <mat-card class="mat-elevation-z0">
                            <mat-checkbox [(ngModel)]="seleccionarMoneda" name="seleccionarMoneda" class="example-margin">Seleccionar moneda</mat-checkbox>

                            <app-currency-select [moneda]="generateModel.moneda" [disable]="!seleccionarMoneda" (cambioMoneda)="$event"></app-currency-select>

                        </mat-card>
                        <br>
                        <mat-card class="mat-elevation-z0">
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="generateModel.filtro">
                                <mat-radio-button value="todos">Todos</mat-radio-button>
                                &nbsp;
                                <mat-radio-button value="pendientes">Pendientes</mat-radio-button>
                                &nbsp;
                                <mat-radio-button value="aprobados">Aprobados</mat-radio-button>
                            </mat-radio-group>
                        </mat-card>
                    </div>
                    <div class="col s12 m5">
                        <section class="example-section">
                            <span class="example-list-section">
                                <mat-checkbox class="example-margin"
                                    [checked]="allComplete"
                                    [indeterminate]="someComplete()"
                                    (change)="setAll($event.checked)">
                                    {{task.name}}
                                </mat-checkbox>
                            </span>
                            <span class="example-list-section">
                                <ul>
                                    <li *ngFor="let subtask of task.subtasks">
                                    <mat-checkbox [(ngModel)]="subtask.completed"
                                    (ngModelChange)="updateAllComplete()">
                                        {{subtask.name}}
                                    </mat-checkbox>
                                    </li>
                                </ul>
                            </span>
                        </section>

                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Otros" labelClass="mat-tab-label-0-1">
            Otros
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions class="align-right">
    <button mat-flat-button (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
    <button mat-flat-button color="primary">Generar</button>
</div>
