import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ConfiguracionDeduccion, DeduDetaNdd } from "src/app/models/planilla/configuraciones/configuracion-deduccion";
import { ConfigurationService, ContabilidadService, NominaService } from "src/app/services";
import { ApiNominaConfiguracionesConceptoRemuneracionService } from "src/app/services/api/nomina/configuraciones/nomina.configuraciones.concepto-remuneracion.service";

@Component({
  selector: 'app-configuracion-deduccion-create',
  templateUrl: './configuracion-deduccion-create.component.html',
  styleUrls: ['./configuracion-deduccion-create.component.scss']
})
export class ConfiguracionDeduccionCreateComponent implements OnInit, OnDestroy{

  configuracion: ConfiguracionDeduccion;
  loaderReg: boolean = false;
  loaderData: boolean = false;

  conceptos: any [] =  [];
  tipos: any[] = [];
  cuentas: any[] = [];

  displayedColumns: string[] = ['action', 'nti_codnti', 'ccn_codccn', 'ccn_codcco', 'ccn_codliq'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  year: string;
  month: string;

  period$: Subscription;
  loading$: Subscription;
  concepto$: Subscription;
  tipoNomina$: Subscription;
  planCuenta$: Subscription;
  sendForm$: Subscription;
  listarConfig$: Subscription;
  verConfig$: Subscription;

  uid: string;

  periodo: string;

  detalle: Array<DeduDetaNdd> = [];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _nominaService: NominaService,
    private _apiConceptoRemunerativoNominaService: ApiNominaConfiguracionesConceptoRemuneracionService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService,
    private _contabilidadService: ContabilidadService
  ) {
    this.configuracion = new ConfiguracionDeduccion();
    this.configuracion.coduse = this._configurationService.obtenerIdUsuario();
    this.configuracion.codcia = this._configurationService.obtenerCompaniaCliente();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
      this.configuracion.codano = this.year;
      this.configuracion.codmes = this.month;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({periodo, cod}) => {
        this.uid = cod;
        this.periodo = periodo;
        this.loadMaestros();
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
        if(periodo == '0') return
      }
    )
  }

  obtenerConfiguraDeduccion(): void {
    this.verConfig$ = this._nominaService.verConfiguracionDeduccion(this.periodo, this.uid).subscribe(
      configuracion => {
        this.configuracion = configuracion[0];
        this.configuracion.DEDU_DETA_NDD = configuracion[0].detalle;
        this.detalle = configuracion[0].detalle;
        const datasource = this.configuracion.DEDU_DETA_NDD.filter(item => item.codnti);
        this.dataSource = fillTable(datasource, this.paginator, this.sort);

        this.obtenerDescripcionCodigos();
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  obtenerDescripcionCodigos(): void {
    this.configuracion.DEDU_DETA_NDD.forEach(item => {
      item['desccn'] = (this.cuentas.find(ele => ele.cuenta == item['codccn'])).descri_cuenta
      item['descco'] = (this.cuentas.find(ele => ele.cuenta == item['codcco'])).descri_cuenta
      item['desliq'] = (this.cuentas.find(ele => ele.cuenta == item['codliq'])).descri_cuenta
    });
    const datasource = this.configuracion.DEDU_DETA_NDD.filter(el => el.codnti);
    this.dataSource = fillTable(datasource, this.paginator, this.sort);

  }

  /**
   * Obtiene los maestros de :
   * . Conceptos remunerativos
   * . Tipos de nómina
   * . Planes de cuenta
   */
  loadMaestros(): void {

    forkObs(
      this._apiConceptoRemunerativoNominaService.listarConceptosRemunerativos(),
      this._nominaService.obtenerTiposNomina(),
      this._contabilidadService.listarPlanDeCuentas()
    ).then(data => {
      this.conceptos = data[0];
      this.tipos = data[1];
      this.cuentas = data[2];

      if(this.uid != '0') {
        this.obtenerConfiguraDeduccion();
        return;
      }
    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", 'Ok');
    })

  }

  agregarItem(): void {
    this.detalle.push(new DeduDetaNdd());
    this.detalle.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.detalle, this.paginator, this.sort);
  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.detalle.filter(item => item.correlativo != row.correlativo);
        this.detalle = filtered;

        this.detalle.forEach((element, key) => {
          element['correlativo'] = key;

        })
        this.dataSource = fillTable(this.detalle, this.paginator, this.sort);
      }
    })
  }

  seleccionarTipo(row): void {
    const tipo = this.tipos.find(item => item.nti_codnti == row.codnti);
    row.desnti = tipo.nti_descri;
  }

  seleccionarCuenta(row): void {
    const cuenta = this.cuentas.find(item => item.cuenta == row.codnco);
    row.desnco = cuenta.descri_cuenta;
  }

  seleccionarCuentaPagar(row): void {
    const cuenta = this.cuentas.find(item => item.cuenta == row.codcco);
    row.descco = cuenta.descri_cuenta;
  }

  seleccionarCuentaLiquidacion(row): void {
    const cuenta = this.cuentas.find(item => item.cuenta == row.codliq);
    row.desliq = cuenta.descri_cuenta;
  }

  enviarFormulario(f: NgForm): void {
    if(!this.estaVerificadoDetalle()) {
      this._snackBarService.showError('Es necesario confirmar todas las filas de la tabla', 'Ok');
      return;
    }
    this.configuracion.DEDU_DETA_NDD = this.detalle;
    this.loaderReg = true;
    this.validarDatosDetalle();
    this.uid == '0' ? this.registrarDeduccion(f): this.actualizarDeduccion(f);
  }

  registrarDeduccion(f: NgForm): void {
    this.loaderReg = false;
    this.sendForm$ = this._nominaService.registrarDeduccionAporte(this.configuracion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Deducción aporte registrado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  actualizarDeduccion(f: NgForm): void {
    this.sendForm$ = this._nominaService.actualizarDeduccionAporte(this.configuracion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Deducción aporte actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  validarDatosDetalle(): void {
    this.configuracion.DEDU_DETA_NDD.forEach(item => {
      item['coduse'] = this._configurationService.obtenerIdUsuario();
    });
  }

  estaVerificadoDetalle(): boolean {
    let isValid: boolean = true;

    this.detalle.forEach(item => {
      if(item['isEditing']) isValid = false;
    })

    return isValid;
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/configuracion-deduccion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.concepto$,
      this.tipoNomina$,
      this.planCuenta$,
      this.sendForm$,
      this.listarConfig$,
      this.verConfig$,
    ]);
  }
}
