<app-pavso-title-section [title]="'Migración registro de ventas'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div>
      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
        matTooltip="Generar tipo de cambio" (click)="obtenerTipoCambio()">
        <mat-icon>currency_exchange</mat-icon>
      </button>
    </div>
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="'Buscar'" #input>
    </div>
  </div>

  <div (paste)="onPaste($event)" [ngStyle]="{marginTop: '10px'}">

    <div [ngStyle]="{display: 'flex', justifyContent: 'space-between'}">
      <div [ngStyle]="{display: 'flex', alignItems: 'center', marginBottom: '5px'}">
        <mat-icon matTooltip="Seleccionar tabla para pegar datos copiados desde Excel">info_outline</mat-icon>

        <div type="button" [ngStyle]="{cursor: 'pointer', fontSize: '14px', color: '#4792f9', marginLeft: '5px'}"
          (click)="seleccionarTabla()" color="primary">
          Seleccionar tabla
        </div>

      </div>
      <div>

        <a type="button" mat-button color="primary"
          href="https://pavsoperu-my.sharepoint.com/:x:/g/personal/alfredo_yupanqui_pavsolutions_com/EThTU3DEn5FFur50OVlBghYBW7j5ynnrnsjAzNrkY5_nxg?e=PveVSs"
          target="_blank">Descargar plantilla</a>

      </div>
    </div>

    <div [ngStyle]="{border: tablaSeleccionada ? '3px solid #32b17b': '', borderRadius: '18px'}">

      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">
        </div>
        <div class="pav-table-header-title">
          Registros
        </div>
        &nbsp;
        &nbsp;
        <div>
          <button [disabled]="loaderData" (click)="limpiar()" type="button" mat-mini-fab color="accent"
            [ngStyle]="{background: 'rgb(199 199 199)'}" aria-label="Example icon button with a menu icon"
            matTooltip="Limpiar filtros">
            <mat-icon svgIcon="mop-icon"></mat-icon>
          </button>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Limpiar</span>
          </p>
        </div>
        &nbsp;
        <!-- <div>
          <div class="pav-button-icon-add-multiple" matTooltip="Agregar múltiple" (click)="agregarProductoMultiple()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon [ngStyle]="{color: 'white'}">library_add</mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
          </p>
        </div> -->
        &nbsp;
        <!-- <div>
          <div class="pav-button-icon-add" matTooltip="Agregar ítem barra" (click)="agregarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-circle"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
          </p>
        </div> -->
        &nbsp;
        <!-- <div>
          <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="eliminarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="remove"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
          </p>
        </div> -->
        &nbsp;
        <!-- <div>
          <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-outline"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Insertar</span>
          </p>
        </div> -->
      </div>

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="mat-elevation-z0 overflow-x">

        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <!-- <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div> -->
            </td>
          </ng-container>

          <ng-container matColumnDef="ano_codano">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ano_codano}} </td>
          </ng-container>

          <ng-container matColumnDef="mes_codmes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
            <td mat-cell *matCellDef="let row" data-label="Proveedor" class="td-responsive"> {{row.mes_codmes}} </td>
          </ng-container>

          <ng-container matColumnDef="sco_codsco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. de Subdiario Contable </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.sco_codsco}} </td>
          </ng-container>

          <ng-container matColumnDef="ldc_corldc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.ldc_corldc}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cco_fecreg}} </td>
          </ng-container>

          <ng-container matColumnDef="tga_codtga">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Operación </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.tga_codtga}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_codcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_codcli}} </td>
          </ng-container>

          <ng-container matColumnDef="tdo_codtdo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de documento </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.tdo_codtdo}} </td>
          </ng-container>

          <ng-container matColumnDef="sad_codsad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.sad_codsad}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de documento </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cco_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.tmo_codtmo}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_tasigv">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.cco_tasigv}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_glocco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
              <div [ngStyle]="{width: '400px'}">
                {{row.cco_glocco}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Contable </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
              <div [ngStyle]="{width: '400px'}">
                {{row.ccn_codccn}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Emision </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.cco_fecemi}} </td>
          </ng-container>

          <ng-container matColumnDef="tdo_docref">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Documento Referencia </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.tdo_docref}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_numref">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de Documento Referencia </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cco_numref}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecref">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Referencia </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cco_fecref}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_tipcam">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Cambio </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}">
              {{row.cco_tipcam}}
            </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecven">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Vcto. </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.cco_fecven}} </td>
          </ng-container>

          <ng-container matColumnDef="exportacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Exportación </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.exportacion}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_impina">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Inafecto </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.cco_impina}} </td>
          </ng-container>

          <ng-container matColumnDef="exonerado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Exonerado </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.exonerado}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_impafe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Afecto </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.cco_impafe}} </td>
          </ng-container>

          <ng-container matColumnDef="ajuste_igv">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ajuste IGV </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.ajuste_igv}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_impigv">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe IGV </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.cco_impigv}} </td>
          </ng-container>

          <ng-container matColumnDef="valvta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor de Venta </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.valvta}} </td>
          </ng-container>

          <ng-container matColumnDef="valvta_retencion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor de Retención </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.valvta_retencion}} </td>
          </ng-container>


          <ng-container matColumnDef="cco_impdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"
              [ngStyle]="{textAlign: 'right'}"> {{row.cco_impdoc}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="30">No se encontraron registros "{{input.value}}"</td>
          </tr>

        </table>

      </div>

    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
