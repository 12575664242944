import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { AlmacenService } from 'src/app/services';
import { GuiaTextoService } from 'src/app/services/api/almacen/operaciones/guia-texto.service';
import { IParam } from 'src/app/services/api/utilities/params';

@Component({
  selector: 'app-guia-texto-list',
  templateUrl: './guia-texto-list.component.html',
  styleUrls: ['./guia-texto-list.component.css']
})
export class GuiaTextoListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'sdo_codsdo','grc_cordoc', 'grc_fecdoc', 'cli_codcli', 'grc_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  guiaRemision$: Subscription;
  loading$: Subscription;
  period$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loaderData: boolean;
  anio: string;
  mes: string;
  params: Array<IParam>;
  constructor(
    private _guiaTextoService: GuiaTextoService,
    private _almacenService: AlmacenService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    ) {

      this.dataSource = fillTable([], this.paginator, this.sort);
      this.period$ = this.store.select('period').subscribe(state => {
          this.anio = state.year;
          this.mes = state.month;
          this.params = [
            {param: 'anio', value: this.anio},
            {param: 'mes', value: this.mes}
          ]
          console.log("anio", this.anio);
          console.log("mes", this.mes);
          console.log("params", this.params);
        }
      )
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerGuiasRemision();
    })
  }

  obtenerGuiasRemision(): void {
    this.loaderData = true;
    this.guiaRemision$ = this._guiaTextoService.obtenerGuiasTexto(
      {params: this.params}
      ).subscribe(
      response => {
        console.log("GuiaTexto", response)
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.guiaRemision$,
      this.loading$
    ])
  }

}
