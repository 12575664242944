<app-pavso-title-section [title]="'Lugares de despacho'" [module]="'Compras'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todos los lugares" url="/modulo-compras/lugar-de-despacho" entity=""
      [params]="['0']"></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

            <div class="pav-btns-container">
              <div class="pav-btn-circle-edit" matTooltip="Editar"
                [routerLink]="['/modulo-compras/lugar-de-despacho', row.LDC_CODLDC]">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="LDC_CODLDC">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.LDC_CODLDC}} </td>
        </ng-container>

        <ng-container matColumnDef="LDC_NOMLDC">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.LDC_NOMLDC}} </td>
        </ng-container>

        <ng-container matColumnDef="LDC_INDSTA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.LDC_INDSTA == '1' ?
            'Vigente': 'Anulado'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

        </tr>

      </table>
    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
