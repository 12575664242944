import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { VentasService } from "../../api/ventas.service";
import * as pdfBuilder from "@utils/pdfmaker/builder/ventas.pdfmaker";
import { SnackBarService } from "@shared/services/snackbar.service";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ExportExcelService } from "../../reporte-excel/export-excel.service";
import { ConfigurationService } from "../../configuration.service";
import { VentasExcelService } from "../../reporte-excel/ventas-excel.service";

@Injectable({
  providedIn: 'root'
})
export class VentasReportService {

  reporte$: Subscription;
  period$: Subscription;

  year: string;
  month: string;

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    public ete: VentasExcelService,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporte(reporteSeleccionado: string, headers, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('REPORTE SELECCIONADO', reporteSeleccionado)
      switch (reporteSeleccionado) {
        case "VENTAS_P":

          this.reporte$ = this._ventasService.obtenerReporteHistorialPedidos(this.year, this.month).subscribe(
            response => {
              console.log('generando reporte de historial de pedidos')
              pdfBuilder.generarReporteHistorialPedidos(headers, response, otherData)
              resolve(true)
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de historial de pedidos', 'Ok');
              reject(false)
            }
          )

          break;
        case "VENTAS_DP":

          this.reporte$ = this._ventasService.obtenerReporteDetalladoPedidos(this.year, this.month, payload).subscribe(
            response => {
              console.log('generando reporte de detallado de pedidos')
              pdfBuilder.generarReporteDetalladoPedidos(headers, response, otherData)
              resolve(true)
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de historial de pedidos', 'Ok');
              reject(false)
            }
          )

          break;
        case "VENTAS_RV":
          this.reporte$ = this._ventasService.obtenerReporteRegistroVentas(payload, this.year, this.month).subscribe(
            data => {
              console.log('registro de ventas data', data)
              pdfBuilder.generarReporteRegistroVentas(headers, data, otherData)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de registro de ventas", "Ok");
              reject(false)
            }
          )
          break;
        case "VENTAS_RDV":
          this.reporte$ = this._ventasService.obtenerInformeDinamicoRegistroDetalladoVentas(payload, headers.year, headers.month).subscribe(
            data => {
              console.log('detallado de ventas data', data)
              pdfBuilder.generarPdfDetalleVentaReport(headers, data)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de detallado de ventas", "Ok");
              reject(false)
            }
          )
          break;
        case "VENTAS_RA":
          this.reporte$ = this._ventasService.obtenerReporteResumenAnual(payload, this.year).subscribe(
            data => {
              console.log('resumen anual data', data)
              pdfBuilder.generarReporteEstadisticoGeneral(headers, data, otherData)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de resumen anual", "Ok");
              reject(false)
            }
          )
          break;
        case "VENTAS_RP":
          console.log('entrando')
          if (!payload.periodos) {
            this._snackBarService.showError('Seleccionar períodos a mostrar en el Reporte', 'Ok');
            reject(false)
            return;
          }
          this.reporte$ = this._ventasService.obtenerReporteResumenTrimestral(payload).subscribe(
            data => {
              console.log('resumen trimestral data', data)
              if(data.length == 0) {
                this._snackBarService.showError('No se encontraron datos para este período', 'Ok');
                reject(false)
                return;
              }

              pdfBuilder.generarReporteResumenGuiaVentasCantidadValor(headers, data, otherData)
              resolve(true)

            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de resumen trimestral", "Ok");
              reject(false)
            }
          )
          break;
        case "VENTAS_CC":
          this.reporte$ = this._ventasService.obtenerInformeComprobantesXCliente(payload, headers.year, headers.month).subscribe(
            data => {

              if (data.length == 0) {
                this._snackBarService.showError('No se encontraron datos para este período', 'Ok');
                reject(false)
                return;
              }

              console.log('comprobantes por cliente data', data)
              pdfBuilder.generarPDFComprobantesCliente(headers, data)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de comprobantes por cliente", "Ok");
              reject(false)
            }
          )
          break;
        case "VENTAS_PDV":
          this.reporte$ = this._ventasService.obtenerReporteProductoDejadosVender(headers.year, payload).subscribe(
            data => {
              console.log('productos dejados vender data', data)
              pdfBuilder.generarReporteProductosDejadosVender(headers, data)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de productos dejados a vender", "Ok");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  /**
   * Generar reporte dinámico Ventas
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   */
  verReporteDinamico(reporteSeleccionado: string, headers, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {

      switch (reporteSeleccionado) {
        case 'VENTAS_RV':
          console.log('dinamico registro ventas')
          this.reporte$ = this._ventasService.obtenerInformeDinamicoRegistroVentas(payload, this.year, this.month).subscribe(
            data => {
              console.log('registro de ventas data', data)
              if (data.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              let dataForExcel = [];

              data.forEach((row: any) => {
                dataForExcel.push(Object.values(row))
              })

              let reportData = {
                title: `Informe de Registro de ventas`,
                data: dataForExcel,
                headers: Object.keys(data[0]),
                razonSocial: this._configurationService.obtenerNombreCompaniaCliente(),
                ruc: this._configurationService.obtenerClienteId()
              }

              this.ete.exportExcelRegistroVentas(reportData);

              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de registro de ventas", "Ok");
              reject(false)
            }
          )
          break;
        case 'VENTAS_RDV':
          console.log('dinamico registro detallado de ventas')
          this.reporte$ = this._ventasService.obtenerInformeDinamicoRegistroDetalladoVentas(payload, this.year, this.month).subscribe(
            data => {
              console.log('registro detallado ventas data', data)
              if (data.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              let dataForExcel = [];

              data.forEach((row: any) => {
                dataForExcel.push(Object.values(row))
              })

              let reportData = {
                title: `Informe de Registro detallado de ventas`,
                data: dataForExcel,
                headers: Object.keys(data[0]),
                razonSocial: this._configurationService.obtenerNombreCompaniaCliente(),
                ruc: this._configurationService.obtenerClienteId()
              }

              this.ete.exportExcelRegistroDetalladoVentas(reportData);

              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de detallado de ventas", "Ok");
              reject(false)
            }
          )
          break;
        case 'VENTAS_MC':
          console.log('dinamico maestro clientes')
          this.reporte$ = this._ventasService.obtenerInformeDinamicoMaestroClientes(payload).subscribe(
            response => {
              console.log('clientes', response)
              if (response.length < 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener información', 'Ok');
                reject(true);
                return;
              }

              let dataForExcel = [];

              response.forEach((row: any) => {
                dataForExcel.push(Object.values(row))
              })

              let reportData = {
                title: `Informe de Maestro de Clientes`,
                data: dataForExcel,
                headers: Object.keys(response[0]),
                razonSocial: this._configurationService.obtenerNombreCompaniaCliente(),
                ruc: this._configurationService.obtenerClienteId()
              }

              this.ete.exportExcelMaestroClientes(reportData);

              resolve(true);
            },
            error => {
              this._snackBarService.showError(error.error.msg, 'OK');
              reject(false);
            }
          )
          break;
        case 'VENTAS_PA':
          console.log('dinamico producto anual')
          this.reporte$ = this._ventasService.obtenerInformeDinamicoProductoAnual(headers.codano, headers.codmes).subscribe(
            data => {
              console.log('reporte producto anual data', data)
              try {
                if (data.length <= 0) {
                  this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                  reject(false)
                  return;
                }

                let dataForExcel = [];

                data.forEach((row: any) => {
                  dataForExcel.push(Object.values(row))
                })

                let reportData = {
                  title: `Informe de Producto anual`,
                  data: dataForExcel,
                  headers: Object.keys(data[0]),
                  razonSocial: this._configurationService.obtenerNombreCompaniaCliente(),
                  ruc: this._configurationService.obtenerClienteId()
                }

                this.ete.exportExcelProductoAnual(reportData);

                resolve(true)
              } catch (error) {
                this._snackBarService.showError('Error al generar reporte', 'Ok');
                reject(false)
              }
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de producto anual", "Ok");
              reject(false)
            }
          )
          break;
        case 'VENTAS_CA':
          console.log('dinamico cliente anual');
          resolve(true)
          break;
        default:
          break;
      }

    })
  }
}
