<h1 mat-dialog-title>Nuevo Proveedor</h1>

<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarProveedor(f)" (keydown.enter)="$event.preventDefault()">
  <div mat-dialog-content>

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <div class="row">
      <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input [(ngModel)]="proveedor.cli_codcli" type="text" matInput placeholder="Código" name="cli_codcli" required disabled>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
          <mat-form-field>
              <mat-label>Tipo persona</mat-label>
              <mat-select required name="cli_tipper" [(ngModel)]="proveedor.cli_tipper" disabled>
                  <mat-option *ngFor="let tipo of tiposPersona" [value]="tipo.tpe_codtpe">
                      {{tipo.tpe_descri}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <app-state-select [state]="'1'" (cambioEstado)="estado" [disable]="true"></app-state-select>
      </div>
      <div class="col s12 m12 l3" *ngIf="!esJuridico">
          <mat-form-field>
              <mat-label>Ap. Paterno</mat-label>
              <input type="text" matInput placeholder="Ap. Paterno" name="ap_paterno" required disabled>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l3" *ngIf="!esJuridico">
          <mat-form-field>
              <mat-label>Ap. Materno</mat-label>
              <input type="text" matInput placeholder="Ap. Materno" name="ap_materno" required disabled>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l3" *ngIf="!esJuridico">
          <mat-form-field>
              <mat-label>Nombres</mat-label>
              <input type="text" matInput placeholder="Nombres" name="nombres" required disabled>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l6">
          <mat-form-field>
              <mat-label>Razon Social</mat-label>
              <input type="text" matInput placeholder="Razon Social" [(ngModel)]="proveedor.cli_nomcli" name="razon_social" required disabled>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l6">
          <mat-form-field>
              <mat-label>Nombre Comercial</mat-label>
              <input type="text" matInput placeholder="Nombre Comercial" [(ngModel)]="proveedor.cli_nomcli" name="nombre_comercial" required disabled>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Tipo Doc.</mat-label>
          <mat-select required name="did_coddid" [(ngModel)]="proveedor.did_coddid" disabled>
            <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.DID_CODDID">
              {{tipo.did_descri}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3" *ngIf="esJuridico">
        <mat-form-field>
          <mat-label>RUC</mat-label>
          <input type="text" matInput placeholder="RUC" name="ruc" [(ngModel)]="proveedor.cli_codcli" required disabled>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3" *ngIf="!esJuridico">
          <mat-form-field>
              <mat-label>Documento de Identidad</mat-label>
              <input type="text" matInput placeholder="Documento de Identidad" name="dni" [(ngModel)]="proveedor.cli_codcli" required disabled>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l6">
          <mat-form-field>
              <mat-label>Dirección</mat-label>
              <input type="text" matInput placeholder="Dirección" name="direccion" [(ngModel)]="proveedor.cli_dircli" required disabled>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
          <mat-form-field>
              <mat-label>Pais</mat-label>
              <mat-select required name="pais" [(ngModel)]="proveedor.pai_codpai" disabled>
                  <mat-option *ngFor="let pais of paises" [value]="pais.PAI_CODPAI">
                      {{pais.pai_nomlar}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
          <mat-form-field>
              <mat-label>Ubicación</mat-label>
              <mat-select required name="ubicacion" [(ngModel)]="proveedor.ubi_codubi">
                  <mat-option *ngFor="let ubicacion of ubicaciones" [value]="ubicacion.ubi_codubi">
                      {{ubicacion.ubi_desubi}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
          <mat-form-field>
              <mat-label>Ubigeo</mat-label>
              <mat-select required name="ubigeo" [(ngModel)]="proveedor.ubs_codubs" disabled>
                  <mat-option *ngFor="let ubigeo of ubigeos" [value]="ubigeo.ubs_codubs">
                      {{ubigeo.ubs_descri}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>

    </div>
    <br>
  </div>
  <div mat-dialog-actions align="end">
    <mat-spinner diameter="40" *ngIf="loaderReg"></mat-spinner>
    <button class="pav-button-cancel" *ngIf="!loaderReg" mat-flat-button (click)="volver()" type="button" [disabled]="loaderData">{{LABELS_NAME.BTN_BACK | translate}}</button>
    &nbsp;
    <button class="pav-button-action" *ngIf="!loaderReg" mat-flat-button color="primary" type="submit" [disabled]="loaderData">{{LABELS_NAME.BTN_STORE | translate}}</button>
  </div>
</form>


