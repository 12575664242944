import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'cobranza-a-la-fecha-corte',
  templateUrl: './cobranza-a-la-fecha-corte.component.html',
  styleUrls: ['./cobranza-a-la-fecha-corte.component.scss']
})
export class CobranzaALaFechaCorteComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
