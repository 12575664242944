<!-- [matTooltip]="lastmenu.title" matTooltipPosition="right"
                      matTooltipClass="pavso-tooltip-color" -->
<mat-nav-list class="pav-menu" [ngStyle]="{height: tieneMasDeUnaCompaniaCleinte ? 'calc(100% - 357px)' : 'calc(100% - 300px)'}">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div *ngFor="let menu of menu">
    <!-- NIVEL 1 -->
    <a mat-list-item routerLinkActive="active-link" [ngClass]="{'active-link': menu.showSubmenu}"
      (click)="menu.showSubmenu = !menu.showSubmenu; seleccionarModulo(menu)"
      [routerLink]="menu.url">
      <span class="pavso-one-line-text" *ngIf="menu.isExpanded || menu.isShowing">{{ menu.title }}</span>
      <mat-icon mat-list-icon [svgIcon]="menu.icon" class="mat-icons"></mat-icon>
      <span class="spacer"></span>
      <div *ngIf="menu.submenus && menu.submenus.length > 0">
        <mat-icon class="menu-button" [ngClass]="{ rotated: menu.showSubmenu }"
          *ngIf="menu.isExpanded || menu.isShowing">expand_more</mat-icon>
      </div>
    </a>
    <!-- NIVEL 1 END -->
    <ng-container *ngIf="menu.submenus">
      <div *ngIf="menu.submenus.length > 0">
        <div class="submenu" [ngClass]="{ expanded: menu.showSubmenu && menu.codeMod == moduloActual }" *ngIf="menu.isShowing || menu.isExpanded">
          <div *ngFor="let submenu of menu.submenus">
            <!-- NIVEL 2 -->
            <mat-list-item style="margin-left: 33px; height: 35px !important;" (click)="submenu.showSubmenu = !submenu.showSubmenu" [routerLink]="submenu.url"
              [ngClass]="{'active-submodule-link': submenu.showSubmenu}">
              <div>
                <mat-icon mat-list-icon style="width: 15px !important; height: 15px !important; margin-top: -5px;" svgIcon="paper"></mat-icon>
              </div>
              <div class="pavso-one-line-text" *ngIf="submenu.isExpanded || submenu.isShowing">
                {{submenu.title }}
              </div>
              <span class="spacer"></span>
              <div *ngIf="submenu.submenus && submenu.submenus.length > 0">
                <mat-icon class="menu-button" [ngClass]="{ rotated: submenu.showSubmenu }"
                  *ngIf="submenu.isExpanded || submenu.isShowing">expand_more</mat-icon>
              </div>
            </mat-list-item>
            <!-- NIVEL 2 END -->
            <ng-container *ngIf="submenu.submenus">
              <div *ngIf="submenu.submenus.length > 0">
                <div class="submenu" [ngClass]="{ expanded: submenu.showSubmenu }"
                  *ngIf="submenu.isShowing || submenu.isExpanded">
                  <div *ngFor="let lastmenu of submenu.submenus">
                    <!-- NIVEL 3 -->
                    <mat-list-item style="height: 35px !important; margin-left: 67px;"
                      *ngIf="lastmenu.estado_menu == '1'"
                      (click)="lastmenu.showSubmenu = !lastmenu.showSubmenu"
                      (click)="verPagina(lastmenu.program, menu.codeMod, lastmenu.xfunction)"
                      routerLinkActive="active-menu-link"
                      class="active-submodule-alter">
                      <a [routerLink]="lastmenu.url" class="pavso-one-line-text" [style]="{width: '100%'}">
                        <span class="pavso-one-line-text module" *ngIf="lastmenu.isExpanded || lastmenu.isShowing">
                          {{lastmenu.title }}
                        </span>
                        <!-- <mat-icon mat-list-icon svgIcon="paper"></mat-icon> -->
                        <span class="spacer"></span>
                        <div *ngIf="lastmenu.submenus && lastmenu.submenus.length > 0">
                          <mat-icon class="menu-button" [ngClass]="{ rotated: lastmenu.showSubmenu }" *ngIf="
                              lastmenu.isExpanded || lastmenu.isShowing
                            ">expand_more</mat-icon>
                        </div>

                      </a>
                    </mat-list-item>
                    <!-- NIVEL 3 END -->
                    <ng-container *ngIf="lastmenu.submenus && lastmenu.submenus.length > 0">
                      <div class="submenu" [ngClass]="{ expanded: lastmenu.showSubmenu }" *ngIf="
                          lastmenu.isShowing || lastmenu.isExpanded
                        ">
                        <div *ngFor="let ultmenu of lastmenu.submenus">
                          <!-- NIVEL 4 -->
                          <mat-list-item style="height: 35px !important; margin-left: 86px; opacity: .8 !important;" (click)="
                              ultmenu.showSubmenu = !ultmenu.showSubmenu
                            " [routerLink]="ultmenu.url" (click)="verPagina(ultmenu.program, menu.codeMod, ultmenu.xfunction)"
                            routerLinkActive="active-menu-link">
                            <span class="pavso-one-line-text" *ngIf="
                                ultmenu.isExpanded || ultmenu.isShowing
                              ">{{ ultmenu.title }}</span>
                            <span class="spacer"></span>
                            <div *ngIf="ultmenu.submenus && ultmenu.submenus.length > 0">
                              <mat-icon class="menu-button" [ngClass]="{
                                  rotated: ultmenu.showSubmenu
                                }" *ngIf="
                                  ultmenu.isExpanded ||
                                  ultmenu.isShowing
                                ">expand_more</mat-icon>
                            </div>
                          </mat-list-item>
                          <!-- NIVEL 4 END -->
                          <!-- <div class="submenu" [ngClass]="{
                              expanded: ultmenu.showSubmenu
                            }" *ngIf="
                              ultmenu.isShowing || ultmenu.isExpanded
                            ">
                            <a *ngFor="let item of ultmenu.submenus" class="subitem pavso-one-line-text" mat-list-item
                              [routerLink]="item.url" [matTooltip]="item.title | uppercase" matTooltipPosition="right"
                              matTooltipClass="pavso-tooltip-color">
                              <mat-icon>{{ item.icon }}</mat-icon>
                              <span>{{ item.title }} uuuuuu</span>
                            </a>
                          </div> -->
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</mat-nav-list>
