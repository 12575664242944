<app-pavso-title-section [title]="'Área de producción'" [module]="'Producción'" [estado]="area.apr_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="area.apr_codapr" matInput placeholder="Código" name="apr_codapr" [disabled]="true">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="area.apr_desapr" matInput placeholder="Descripción" name="apr_desapr" required [disabled]="loaderData">
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-produccion/areas-de-produccion"
    ></pav-form-actions>
  </form>
</div>
