import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Color } from "src/app/models/almacen";
import { ConfigurationService } from "src/app/services";
import { ColorService } from "src/app/services/api/almacen/maestros/color.service";

@Component({
  selector: 'color-form',
  templateUrl: './color-form.component.html',
  styleUrls: ['./color-form.component.scss']
})
export class ColorFormComponent implements OnInit, OnDestroy {

  uid: string;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  loaderReg: boolean;
  loaderData: boolean;

  loading$: Subscription;
  sendForm$: Subscription;
  color$: Subscription;

  color: Color;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private _colorService: ColorService,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService
  ) {

    this.color = new Color();
    this.initialize();
  }

  initialize(): void {
    this.color.col_coduse = this._configurationService.obtenerIdUsuario();
    this.color.col_indsta = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      if (this.uid != '0') {
        this.obtenerColor();
        return;
      }
      this.loaderData = false;
    })
  }

  obtenerColor(): void {
    this.color$ = this._colorService.obtenerColor(this.uid).subscribe(
      color => {
        this.color = color;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener color', 'Ok');
        this.loaderData = false;
      }
    )
  }

  esFormularioValido(): boolean {
    if (this.color.col_codcol.length != 4) {
      this._snackBarService.showError('El código de color debe tener 4 caracteres', 'Ok');
      this.loaderReg = false;
      return false;
    }

    return true;
  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;
    if (!this.esFormularioValido()) {
      return;
    }

    this.uid == '0' ? this.registrarColor(f) : this.actualizarColor(f)
  }

  registrarColor(f: NgForm): void {
    this.sendForm$ = this._colorService.registrarColor(this.color).subscribe(
      color => {
        this._dialogService.openDialog(SuccessComponent, 'Color Registrado', '350px', '', '');
        this.loaderReg = false;

      },
      error => {
        this._snackBarService.showError('Error al registrar color', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarColor(f: NgForm): void {
    this.sendForm$ = this._colorService.actualizarColor(this.color).subscribe(
      color => {
        this._dialogService.openDialog(SuccessComponent, 'Color Actualizado', '350px', '', '');
        this.loaderReg = false;

      },
      error => {
        this._snackBarService.showError('Error al actualizar color', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  nuevoRegistro(): void {
    this.color = new Color();
    this.uid = '0';
    this.initialize();
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$
    ])
  }

}
