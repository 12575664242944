<app-pavso-title-section [title]="'Presupuesto cotización decoración'" [module]="'Ventas'" [usuario]="'usuario'"
  [ngClass]="'pav-form'" [estado]="'Activo'"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>N/Presupuesto</mat-label>
          <input matInput placeholder="Ej. 0000474"  >
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l1">
        <mat-form-field class="example-full-width">
          <mat-label>T.C.</mat-label>
          <input matInput placeholder="Ej. 3.4">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Moneda</mat-label>
          <mat-select>
            <mat-option [value]="'SO'">
              Soles
            </mat-option>
            <mat-option [value]="'DO'">
              Dólares
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <cliente-select [disabled]="loaderData" [label]="'Cliente'" [placeholder]="'Ej. GERENCIA'" [name]="'cli_codcli'"
          [value]="'cli_codcli'" [description]="'description'" [data]="clientes" [canAdd]="true" [canEdit]="true">
        </cliente-select>
      </div>

      <div class="col s12 m12 l3">
        <select-search [disabled]="loaderData" [label]="'Vendedor'" [placeholder]="'Ej. GERENCIA'" [name]="'VDE_CODVDE'"
          [value]="'VDE_CODVDE'" [description]="'VDE_NOMVDE'" [data]="vendedores"></select-search>
      </div>
      <div class="col s12 m12 l9">
        <mat-form-field class="example-full-width">
          <mat-label>Detalle</mat-label>
          <textarea matInput placeholder="Ej. Detalle"></textarea>
        </mat-form-field>
      </div>
    </div>

  </mat-card>

  <div class="container-stock">
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Detalle</div>
      <div class="pav-separator"></div>
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="'Buscar'" #input>
      </div>
    </div>
    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.prd_codprd}} </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.prd_desesp}} </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.MPR_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="cod_producto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. producto </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ume_codume}} </td>
        </ng-container>

        <ng-container matColumnDef="desc_producto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. producto </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.spa_cansal}} </td>
        </ng-container>

        <ng-container matColumnDef="ambiente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ambiente </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.FPR_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="ref_ambiente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref. ambiente </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.TIN_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="texto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Texto </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="obs_caida">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Obs. caída </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="cantidad">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="factor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Factor </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="ancho">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ancho </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="alto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Alto </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="ajuste_alto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ajuste alto </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="panos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> # Paños </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="panos_mandos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> #Paños / Mandos </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="apertura">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Apertura </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="instalacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Instalación </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="parcial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Parcial </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{cursor: 'pointer'}"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="19">No se encontraron registros "{{input.value}}"</td>
        </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <div class="container-stock">
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Detalle 2</div>
      <div class="pav-separator"></div>
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="'Buscar'" #input>
      </div>
    </div>
    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource2" matSort>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.prd_codprd}} </td>
        </ng-container>

        <ng-container matColumnDef="familia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fam. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.prd_desesp}} </td>
        </ng-container>

        <ng-container matColumnDef="des_familia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. familia </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.MPR_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="subfamilia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Subfam. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ume_codume}} </td>
        </ng-container>

        <ng-container matColumnDef="desc_subfamilia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Subfamilia </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.spa_cansal}} </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.FPR_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.TIN_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="ume">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> UME </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="factor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Factor </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="npt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro P.T. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="calc_tela">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Calc. Tela </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="cant_digit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Digit. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="cant_calc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Calc. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="precio_unitario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio unitario </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="parcial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Parcial </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns2;" [ngStyle]="{cursor: 'pointer'}"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="16">No se encontraron registros "{{input.value}}"</td>
        </tr>

      </table>

    </div>
    <mat-paginator #paginator2 showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

  <pav-form-actions [disabled]="loaderData" [btnName]="'Guardar'" [isLoading]="loaderReg"
    urlBack="/modulo-almacen/parte-de-ingreso">
    <div id="botonesAdicionales">

      <button class="pav-btn-action" #btnGeneraAsiento color="primary" matTooltip="Copiar presupuesto" type="button"
        (click)="copiarPresupuesto()">
        <mat-icon class="pav-icon-btn">content_copy</mat-icon>
      </button>
      <button class="pav-btn-action" color="primary" matTooltip="Ver cotización" type="button"
        (click)="verCotizacion()">
        <mat-icon class="pav-icon-btn">visibility</mat-icon>
      </button>
      <button class="pav-btn-action" color="primary" matTooltip="Mover a cotización" type="button"
        (click)="moverCotizacion()">
        <mat-icon class="pav-icon-btn">rebase_edit</mat-icon>
      </button>

    </div>
  </pav-form-actions>
</form>
