export class Maquina {

  cia_codcia: string;
  maq_codmaq: string;
  maq_desmaq: string;
  maq_capmaq: number;
  ume_codume: string;
  maq_indsta: string;
  maq_fecupd: Date;
  maq_coduse: string;

  constructor() {
    this.maq_fecupd = new Date();
    this.maq_indsta = '1';
  }
}
