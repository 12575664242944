<select-search-crud
  [id]="id"
  [dialogComponent]="dialogComponent"
  [disabled]="disabled"
  [required]="required"
  [placeholder]="placeholder"
  [clase]="clase"
  [label]="label"
  [value]="value"
  [model]="model"
  [name]="name"
  [description]="description"
  [multiple]="multiple"
  [iconAction]="iconAction"
  [tooltipAction]="tooltipAction"
  [data]="data"
  (cambioSelect)="cambioSelect.emit($event)"
  (clickAction)="clickAction.emit($event)"
  [canAdd]="canAdd"
  [canEdit]="canEdit"
  >
</select-search-crud>

