export class TipoNegociacion {

  codcia: string;
  codotn: string;
  descri: string;
  indsta: string;
  fecupd: Date;
  coduse: string;

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
  }
}
