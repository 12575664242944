import { IHeaderKey } from "@shared/components/table/table-content/table-content.component"

export const THEADER_CRM_ACTIVIDAD: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'idass'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
]

export const THEADER_CRM_CANAL: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'idmtk'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
]

export const THEADER_CRM_CLIENTE: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'cli_codcli'
  },
  {
    header: 'Nombre',
    key: 'cli_nomcli'
  },
  {
    header: 'RUC',
    key: 'cli_numruc'
  },
]

export const THEADER_CRM_ETAPA: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codoet'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
  {
    header: 'Orden',
    key: 'ordoet'
  },
  {
    header: 'Situacion',
    key: 'indsta'
  },

]

export const THEADER_CRM_GRUPO_ACTIVIDAD: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'idgac'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
]

export const THEADER_CRM_LINEA_NEGOCIO: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codoln'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
]

export const THEADER_CRM_LISTA_REQUISITO: Array<IHeaderKey> = [
  {
    header: 'Estado',
    key: 'oms_indsta'
  },
]

export const THEADER_CRM_PLANTILLA_TECNICA: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codpoc'
  },
  {
    header: 'Descripción',
    key: 'descri'
  }
]

export const THEADER_CRM_RESPONSABLE: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'idres'
  },
  {
    header: 'Responsable',
    key: 'nombre'
  },
  {
    header: 'Estado',
    key: 'indsta'
  }
]

export const THEADER_CRM_SITUACION_OPORTUNIDAD: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'idstk'
  },
  {
    header: 'Descripción',
    key: 'descri'
  }
]

export const THEADER_CRM_TIPO_ATENCION: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'idtas'
  },
  {
    header: 'Descripción',
    key: 'descri'
  }
]

export const THEADER_CRM_CAMPANIA: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codcso'
  },
  {
    header: 'Glosa',
    key: 'glosa'
  }
]

export const THEADER_CRM_TIPO_CAMPANIA: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codcoc'
  },
  {
    header: 'Descripción',
    key: 'descri'
  }
]

export const THEADER_CRM_TIPO_NEGOCIACION: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codotn'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
]

export const THEADER_CRM_COTIZACIONES: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'numpcc'
  },
  {
    header: 'Vendedor',
    key: 'codvde'
  },
]
