import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Contact } from "src/app/models";
import { VentasService } from "src/app/services";
import { CargoService } from "src/app/services/api/nomina/maestros/cargo.service";

@Component({
  selector: 'contacto-cliente-form',
  templateUrl: './contacto-cliente-form.component.html',
  styleUrls: ['./contacto-cliente-form.component.scss']
})
export class ContactoClienteFormComponent implements OnInit, OnDestroy {

  loaderData: boolean;
  loaderReg: boolean;

  contacto: Contact;
  cargos: any[] = [];

  cargos$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  uid: string;

  constructor(
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _cargoService: CargoService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
  ) {
    this.contacto = new Contact();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();

    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.cargarMaestros();
    })
  }

  cargarMaestros(): void {
    this.loaderData = true;
    this.cargos$ = this._cargoService.listarCargos().subscribe(
      cargos => {
        this.cargos = cargos;
        console.log('cargos', this.cargos)

        if(this.uid != '0') {
          this.obtenerContacto()
          return;
        };

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  obtenerContacto(): void {

  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;

    this.uid != '0' ? this.actualizarContacto(f): this.registrarContacto(f)
  }

  registrarContacto(f: NgForm): void {
    this.sendForm$ = this._ventasService.registrarContacto(this.contacto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Contacto Registrado', '300px', '', '');
        this.loaderReg = false;
      },
      error =>{
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  actualizarContacto(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarContacto(this.contacto).subscribe(
      response => {

        this._dialogService.openDialog(SuccessComponent, 'Contacto Actualizado', '300px', '', '');
        this.loaderReg = false;
      },
      error =>{
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  verMasOpciones(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cargos$,
      this.loading$,
      this.sendForm$
    ])
  }
}
