import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { Contact } from 'src/app/models';
import { ConfigurationService, VentasService } from 'src/app/services';
import { CargoService } from 'src/app/services/api/nomina/maestros/cargo.service';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { Cliente, DetalleContacto } from 'src/app/models/ventas/customer';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {

  loaderData: boolean = false;
  loaderReg: boolean = false;

  cliente: Cliente;

  displayedColumnsContactos: string[] = ['acciones', 'codigo', 'nombre', 'cargo', 'telefono', 'correo', 'fecha_nacimiento', 'referencia', 'cobranza'];
  dataSourceContactos: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorContactos: MatPaginator;
  @ViewChild(MatSort) sortContactos: MatSort;

  cargos$: Subscription;
  cliente$: Subscription;
  sendForm$: Subscription;

  cargos: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _cargoService: CargoService,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,
  ) {
    this.cliente = new Cliente();
    this.dataSourceContactos = fillTable([], this.paginatorContactos, this.sortContactos);
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.loaderData = true;
    this.cargos$ = this._cargoService.listarCargos().subscribe(
      cargos => {
        this.cargos = cargos;

        this.cliente$ = this._ventasService.obtenerClienteComercial(this.data.codcli).subscribe(
          cliente => {
            console.log('cliente', cliente);
            this.cliente = cliente[0];

            this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
            this.loaderData = false;
          },
          error => {
            this.loaderData = false;
            this._snackBarService.showError(error.error.msg, 'Ok');
          }
        )
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  agregarContacto() {

    let item = new DetalleContacto();
    item.cia_codcia = this._configurationService.obtenerCompaniaCliente();

    this.cliente.detalle_contacto.push(item);

    this.cliente.detalle_contacto.forEach((element, key) => {
      element['ccl_codccl'] = 'key';
    })

    this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
  }

  editarContacto(row) {
    row.isEditing = !row.isEditing;
  }

  confirmarContacto(row) {
    if(!row.ccl_nomccl || !row.cca_codcca || !row.ccl_numtlf || !row.ccl_corele || !row.ccl_cumple || !row.ccl_glosa) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;
  }

  quitarContacto(row) {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceContactos.data.filter(item => item.ccl_codccl != row.ccl_codccl);
        filtered.forEach((element, key) => {
          element['ccl_codccl'] = key;
        })
        this.cliente.detalle_contacto = filtered;
        this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
      }
    })
  }

  seleccionarCargo($event, row: Contact): void {
    const cargo = this.cargos.find(item => item.codnca == $event.value);
    row.cca_descri = cargo.descri;
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  confirmar(): void {
    this.loaderReg = true;
    this.sendForm$ = this._ventasService.editarClienteComercial(this.cliente).subscribe(
      response => {
        console.log('response to update', response)
        this._snackBarService.showSuccess('Lista de contactos actualizado', 'Ok');
        this.loaderReg = false;
        // this.dialogRef.close();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

}
