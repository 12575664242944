export class PeriodoCTS {

  codcia: string;
  codano: string;
  codmes: string;
  ranini: Date;
  ranfin: Date;
  fecpag: Date;
  fecobl: Date;
  porint: number;
  coduse: string;
  fecupd: any;
  indsta: string;
  accion: string;

  constructor() {
    this.indsta = "1";
    this.fecupd = new Date();
  }
}
