export function agregarUnDia(fecha: Date | string) {

  const fec = new Date(fecha);

  fec.setDate(fec.getDate() + 1);

  return fec;
}

export function esLaMismaFecha(fecha01: Date | string, fecha02: Date | string) {
  const fec01 = new Date(fecha01);
  const fec02 = new Date(fecha02);

  const [day01, month01, year01] = [fec01.getDate(), fec01.getMonth(), fec01.getFullYear()];
  const [day02, month02, year02] = [fec02.getDate(), fec02.getMonth(), fec02.getFullYear()];

  if (day01 == day02 && month01 == month02 && year01 == year02) return true

  return false;
}

export function retornaDiferenciaDias(fecha01: string, fecha02: string): number {

  // Convierte las cadenas de fecha a objetos Date
  const fecha1: Date = new Date(fecha01);
  const fecha2: Date = new Date(fecha02);

  // Calcula la diferencia en milisegundos
  const diferenciaMilisegundos: number = fecha2.getTime() - fecha1.getTime();

  // Convierte la diferencia a días
  const diferenciaDias: number = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));

  return diferenciaDias;
}

export function esFechaDiferenteAPeriodo(fecha: Date, anioPeriodo: string, mesPeriodo: string): boolean {

  const ano_codano = fecha.getUTCFullYear().toString();
  const mes_codmes = (fecha.getUTCMonth() + 1) < 10 ? `0${fecha.getUTCMonth() + 1}`: (fecha.getUTCMonth() + 1).toString();

  if(ano_codano != anioPeriodo || mes_codmes != mesPeriodo) {
    return true;
  }

  return false;
}
