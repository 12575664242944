import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ConfiguracionRemuneracion, NomiDeduDetaNdd, NomiRemuBaseNrb } from "src/app/models/planilla/configuraciones/configuracion-remuneracion";
import { ConfigurationService, ContabilidadService, NominaService } from "src/app/services";
import { ApiNominaConfiguracionesConceptoRemuneracionService } from "src/app/services/api/nomina/configuraciones/nomina.configuraciones.concepto-remuneracion.service";

@Component({
  selector: 'app-configuracion-remuneracion-create',
  templateUrl: 'configuracion-remuneracion-create.component.html',
  styleUrls: ['configuracion-remuneracion-create.component.scss']
})
export class ConfiguracionRemuneracionCreateComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['action', 'nrb_codnco', 'nrb_porrem'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['action', 'nti_codnti', 'ccn_codccn', 'ccn_codcco', 'ccn_codliq', 'ccn_codtra'];
  dataSource1: MatTableDataSource<any>

  @ViewChild("paginator1") paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  configuracion: ConfiguracionRemuneracion;

  conceptos: any[] = [];
  grupos: any[] = [];
  cuentas: any[] = [];
  tipos: any[] = [];

  uid: string;
  periodo: string;

  loading$: Subscription;
  period$: Subscription;
  concepto$: Subscription;
  grupo$: Subscription;
  cuenta$: Subscription;
  tipoNomina$: Subscription;
  listarConfig$: Subscription;
  verConfig$: Subscription;
  sendForm$: Subscription;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _nominaService: NominaService,
    private _apiConceptoRemunerativoNominaService: ApiNominaConfiguracionesConceptoRemuneracionService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _contabilidadService: ContabilidadService,
    private store: Store<PavsoState>
  ) {
    this.configuracion = new ConfiguracionRemuneracion();

    this.period$ = this.store.select('period').subscribe(state => {
      this.configuracion.codano = state.year;
      this.configuracion.codmes = state.month;
    })

    this.configuracion.codcia = this._configurationService.obtenerCompaniaCliente();
    this.configuracion.coduse = this._configurationService.obtenerIdUsuario();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({periodo, cod}) => {
        this.uid = cod;
        this.periodo = periodo;
        this.loadMaestros();
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
        if( periodo == '0') return
      }
    )
  }

  obtenerConfiguracionRemuneracion(): void {
    this.verConfig$ = this._nominaService.verConfiguracionRemuneracion(this.periodo, this.uid).subscribe(
      response => {
        this.configuracion = response[0];

        this.configuracion.NOMI_REMU_BASE_NRB = response[0].detalle1;
        this.configuracion.NOMI_DEDU_DETA_NDD = response[0].detalle2;

        this.configuracion.NOMI_REMU_BASE_NRB.forEach((element, key) => {
          element['correlativo'] = key;
        })

        this.configuracion.NOMI_DEDU_DETA_NDD.forEach((element, key) => {
          element['correlativo'] = key;
        })

        this.dataSource = fillTable(this.configuracion.NOMI_REMU_BASE_NRB, this.paginator, this.sort);
        this.dataSource1 = fillTable(this.configuracion.NOMI_DEDU_DETA_NDD, this.paginator1, this.sort1);

        this.obtenerDescripcionCodigos();
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )

  }

  obtenerDescripcionCodigos(): void {
    this.configuracion.NOMI_REMU_BASE_NRB.forEach(item => {
      item['desnco'] = (this.conceptos.find(ele => ele.codnco == item['codnrb'])).descri
    });
    this.dataSource = fillTable(this.configuracion.NOMI_REMU_BASE_NRB, this.paginator, this.sort);

    this.configuracion.NOMI_DEDU_DETA_NDD.forEach(item => {
      item['descco'] = (this.cuentas.find(ele => ele.cuenta == item['codcco'])).descri_cuenta
      item['desliq'] = (this.cuentas.find(ele => ele.cuenta == item['codliq'])).descri_cuenta
      item['destra'] = (this.cuentas.find(ele => ele.cuenta == item['codtra'])).descri_cuenta
    });
    this.dataSource1 = fillTable(this.configuracion.NOMI_DEDU_DETA_NDD, this.paginator1, this.sort1);

  }

  loadMaestros(): void {

    forkObs(
      this._apiConceptoRemunerativoNominaService.listarConceptosRemunerativos(),
      this._nominaService.listarGrupos(),
      this._contabilidadService.listarPlanDeCuentas(),
      this._nominaService.obtenerTiposNomina()
    ).then(data => {
      this.conceptos = data[0];
      this.grupos = data[1];
      this.cuentas = data[2];
      this.tipos = data[3];
      if(this.uid != '0') this.obtenerConfiguracionRemuneracion();

    }).catch(error => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })

  }

  agregarItemBase(): void {
    this.configuracion.NOMI_REMU_BASE_NRB.push(new NomiRemuBaseNrb());
    this.configuracion.NOMI_REMU_BASE_NRB.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.configuracion.NOMI_REMU_BASE_NRB, this.paginator, this.sort);
  }

  editarItemBase(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItemBase(row): void {

    if(!row.codnrb || !row.porrem) {
      this._snackBarService.showError('Tiene que ingresar los datos en la tabla Base de cálculo', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;
  }

  quitarItemBase(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.configuracion.NOMI_REMU_BASE_NRB.filter(item => item.correlativo != row.correlativo);
        this.configuracion.NOMI_REMU_BASE_NRB = filtered;

        this.configuracion.NOMI_REMU_BASE_NRB.forEach((element, key) => {
          element['correlativo'] = key;

        })
        this.dataSource = fillTable(this.configuracion.NOMI_REMU_BASE_NRB, this.paginator, this.sort);
      }
    })
  }

  agregarItemConfigura(): void {
    this.configuracion.NOMI_DEDU_DETA_NDD.push(new NomiDeduDetaNdd());
    this.configuracion.NOMI_DEDU_DETA_NDD.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource1 = fillTable(this.configuracion.NOMI_DEDU_DETA_NDD, this.paginator, this.sort);
  }

  editarItemConfigura(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItemConfigura(row): void {
    if(!row.codnti || !row.codccn || !row.codcco || !row.codcco || !row.codliq || !row.codtra) {
      this._snackBarService.showError('Tiene que ingresar los datos en la tabla Base de cálculo', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;
  }

  quitarItemConfigura(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.configuracion.NOMI_DEDU_DETA_NDD.filter(item => item.correlativo != row.correlativo);
        this.configuracion.NOMI_DEDU_DETA_NDD = filtered;

        this.configuracion.NOMI_DEDU_DETA_NDD.forEach((element, key) => {
          element['correlativo'] = key;

        })
        this.dataSource1 = fillTable(this.configuracion.NOMI_DEDU_DETA_NDD, this.paginator, this.sort);
      }
    })
  }

  seleccionarConcepto(row): void {
    const concepto = this.conceptos.find(item => item.codnco == row.codnrb);
    row.desnco = concepto.descri;
  }

  seleccionarCuenta(row): void {
    const cuenta = this.cuentas.find(item => item.cuenta == row.codccn);
    row.desccn = cuenta.descri_cuenta;
  }

  seleccionarCuentaPagar(row): void {
    const cuenta = this.cuentas.find(item => item.cuenta == row.codcco);
    row.descco = cuenta.descri_cuenta;
  }

  seleccionarCuentaLiquidacion(row): void {
    const cuenta = this.cuentas.find(item => item.cuenta == row.codliq);
    row.desliq = cuenta.descri_cuenta;
  }

  seleccionarCuentaProvision(row): void {
    const cuenta = this.cuentas.find(item => item.cuenta == row.codtra);
    row.destra = cuenta.descri_cuenta;
  }

  seleccionarTipo(row): void {
    const tipo = this.tipos.find(item => item.nti_codnti == row.codnti);
    row.desnti = tipo.nti_descri;
  }

  enviarFormulario(f: NgForm): void {
    this.configuracion.cannut = 0;
    this.loaderReg = true;
    if(!this.esValidoDetalle()) {
      this._snackBarService.showError('Ninguna fila debe de estar en modo edición', 'Ok');
      return;
    }
    this.uid == '0' ? this.registrarConfiguracionRemuneracion(f): this.actualizarConfiguracionRemuneracion(f);
  }

  registrarConfiguracionRemuneracion(f: NgForm): void {
    this.sendForm$ = this._nominaService.registrarConfiguracionRemuneracion(this.configuracion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Configuración remuneración registrado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarConfiguracionRemuneracion(f: NgForm): void {
    this.sendForm$ = this._nominaService.actualizarConfiguracionRemuneracion(this.configuracion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Configuración remuneración actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  esValidoDetalle(): boolean {
    let isValid: boolean = true;

    this.configuracion.NOMI_REMU_BASE_NRB.forEach(item => {
      if(item['isEditing']) isValid = false;
    })

    this.configuracion.NOMI_DEDU_DETA_NDD.forEach(item => {
      if(item['isEditing']) isValid = false;
    })

    return isValid;
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/configuracion-remuneracion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.period$,
      this.listarConfig$,
      this.verConfig$,
      this.concepto$,
      this.grupo$,
      this.cuenta$,
      this.tipoNomina$,
      this.sendForm$
    ])
  }
}
