import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { VentasService } from 'src/app/services';

@Component({
  selector: 'app-lista-precio-list',
  templateUrl: './lista-precio-list.component.html',
  styleUrls: ['./lista-precio-list.component.css']
})
export class ListaPrecioListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'LPC_CODLPC', 'LPC_GLOLPC'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  priceList$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _ventasService: VentasService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerListaDePrecios();
    })
  }

  obtenerListaDePrecios(): void {
    this.priceList$ = this._ventasService.obtenerListaDePrecios().subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'Ok')

    )
  }

  editarListaPrecio(tipo, code): void {
    this._router.navigate(['/modulo-ventas/lista-de-precio', tipo, code]);
  }

  async verPdf(id) {

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.priceList$,
      this.loading$
    ])
  }
}
