<app-pavso-title-section [title]="'Interface PDT Honorarios'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <mat-radio-group [(ngModel)]="tipo">
      <mat-radio-button value="1">Honorarios</mat-radio-button>
      <mat-radio-button value="2">Retenciones de 4ta Categoría</mat-radio-button>
    </mat-radio-group>
  </mat-card>
  <br>

  <button color="primary" mat-stroked-button>Extraer Información</button>
  &nbsp;
  <button color="primary" mat-stroked-button>Generar Archivo TXT de Prestadores de Renta de 4ta</button>
  &nbsp;
  <button color="primary" mat-stroked-button>Generar Archivo TXT DT. Honorarios</button>
  <br>
</div>
