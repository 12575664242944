import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Driver } from 'src/app/models';
import { AuthenticationService, ConfigurationService, VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ConductorService } from 'src/app/services/api/almacen/maestros/conductor.service';

@Component({
  selector: 'app-conductor-form',
  templateUrl: './conductor-form.component.html',
  styleUrls: ['./conductor-form.component.css']
})
export class ConductorFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  btnName: string;

  conductor: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tipoDoc$: Subscription;
  loading$: Subscription;
  conductor$: Subscription;
  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _conductorService: ConductorService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {

    this.initialize();
  }

  initialize(): void {
    this.conductor = new Driver();
    this.conductor.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.conductor.CHO_CODUSE = this._configurationService.obtenerIdUsuario();
    this.conductor.CHO_CODUSE = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }


  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({id})=>{
      this.uid = id;
      this.loadMaestros();
      if(this.uid == '0') return;
    })
  }

  loadMaestros(): void {
    this.tipoDoc$ = this._ventasService.obtenerTiposDocumentoIdentidad().subscribe(
      tiposDocumento => {
        this.tiposDocumento = tiposDocumento;
        console.log('tipo de documento', this.tiposDocumento)
        this.loaderData = false;

        if(this.uid != '0') this.obtenerConductor();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )

  }

  obtenerConductor(): void {
    this.conductor$ = this._conductorService.obtenerConductor(this.uid).subscribe(
      conductor => {
        console.log('conductor', conductor)
        this.conductor = conductor;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;

    if(!this.esUnFormularioValido()) {
      this.loaderReg = false;
      return;
    }

    this.uid == '0' ? this.registrarConductor(f): this.actualizarConductor(f);
  }

  esUnFormularioValido(): boolean {

    // if(!this.conductor.DID_CODDID) {
    //   this._snackBarService.showError('Seleccionar un tipo de documento de identidad', 'Ok');
    //   return false;
    // }

    return true;
  }

  registrarConductor(f: NgForm): void {

    this._conductorService.registrarConductor(this.conductor).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Conductor registrado', '400px', '400px', '');
        this.uid = response.CHO_CODCHO;
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarConductor(f: NgForm): void {

    this._conductorService.actualizarConductor(this.conductor).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Conductor actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  nuevoRegistro(): void {
    this.uid = '0';
    this.initialize();
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoDoc$]);
  }
}
