<app-pavso-title-section [title]="'Unidad de medida'" [module]="'Almacén'" [estado]="unidadMedida.ume_indsta"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="unidadMedida.ume_codume" matInput placeholder="Código" name="ume_codume" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Código Oficial</mat-label>
            <input type="text" [(ngModel)]="unidadMedida.ume_codofi" matInput placeholder="Código Oficial" name="ume_codofi" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l8">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="unidadMedida.ume_descri" matInput placeholder="Descripción" name="ume_descri" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción Abrevi.</mat-label>
            <input type="text" [(ngModel)]="unidadMedida.ume_desabr" matInput placeholder="Descripción Abrevi." name="ume_desabr" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-almacen/unidades-de-medida">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
  </form>
</div>
