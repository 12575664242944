<app-pavso-title-section [title]="'Ubicación de activo'" [module]="'Activo fijo'" ngClass="pav-form" [estado]="ubicacion.uac_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="ubicacion.uac_deslar" matInput placeholder="Código" name="uac_deslar"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">

        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="ubicacion.uac_deslar" matInput placeholder="Descripción" name="uac_deslar"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción Corta</mat-label>
            <input type="text" [(ngModel)]="ubicacion.uac_descor" matInput placeholder="Descripción Corta"
              name="uac_descor" required>
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <br>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-activo-fijo/ubicaciones-de-activo">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
