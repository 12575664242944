import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ZonaVenta } from "src/app/models/ventas/zona-venta";
import { VentasService } from "src/app/services";

@Component({
  selector: 'zona-venta-form',
  templateUrl: './zona-venta-form.component.html',
  styleUrls: ['./zona-venta-form.component.scss']
})
export class ZonaVentaFormComponent implements OnInit, OnDestroy {
  loaderReg: boolean;
  loaderData: boolean;

  uid: string;
  zona: ZonaVenta;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  zonaVenta$: Subscription;
  sendForm$: Subscription;
  loading$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasSerivce: VentasService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private _router: Router
  ) {
    this.zona = new ZonaVenta();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      if(this.uid == '0') return;
      this.obtenerZonaVenta();
    })
  }

  obtenerZonaVenta(): void {
    this.zonaVenta$ = this._ventasSerivce.obtenerZonasVenta().subscribe(
      zonas => {
        this.zona = zonas.find(item => item.ZVE_CODZVE == this.uid);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarZonaVenta(f): this.actualizarZonaVenta(f);
  }

  registrarZonaVenta(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._ventasSerivce.registrarZonaVenta(this.zona).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Zona de venta registrada', '400px', '409px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarZonaVenta(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._ventasSerivce.actualizarZonaVenta(this.zona).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Zona de venta actualizada', '400px', '409px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/zonas-de-venta']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.zonaVenta$,
      this.sendForm$
    ])
  }
}
