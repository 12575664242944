<app-pavso-title-section [title]="'Período de nómina'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>


  <div class="mat-elevation-z0">
    <table-title-action title="Todos los períodos" url="/modulo-planilla/periodo-de-nomina" entity="" [params]="['0']"></table-title-action>

      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones"  class="td-responsive">
                <div class="pav-btns-container">
                  <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-planilla/periodo-de-nomina', row.codnpe]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="codnpe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Período </th>
              <td mat-cell *matCellDef="let row" data-label="Año" class="td-responsive"> {{row.codnpe}} </td>
          </ng-container>

          <ng-container matColumnDef="descri">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row" data-label="Mes" class="td-responsive"> {{row.descri}} </td>
          </ng-container>

          <ng-container matColumnDef="desnfr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción Frecuencia </th>
              <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.desnfr}} </td>
          </ng-container>

          <ng-container matColumnDef="desnti">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
            <td mat-cell *matCellDef="let row" data-label="Colaborador" class="td-responsive"> {{row.desnti}} </td>
          </ng-container>

          <ng-container matColumnDef="indcie">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
            <td mat-cell *matCellDef="let row" data-label="Colaborador" class="td-responsive"> {{row.indcie == 0 ? 'Cerrado': 'Abierto'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">No se encontraron registros "{{input.value}}"</td>
          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
