import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { Storehouse } from 'src/app/models';
import { AlmacenService, AuthenticationService, ConfigurationService } from 'src/app/services';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';

@Component({
  selector: 'app-almacen-form',
  templateUrl: './almacen-form.component.html',
  styleUrls: ['./almacen-form.component.css']
})
export class AlmacenFormComponent implements OnInit, OnDestroy {

  storehouse: Storehouse;

  establecimientos: any = [];
  tiposAlmacen: any = [];
  sucursales: any = [];

  loaderData: boolean = false;
  loaderReg: boolean = false;

  establecimiento$: Subscription;
  tipoAlmacen$: Subscription;
  sucursal$: Subscription;
  regAlmacen$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;

  constructor(
    private _almacenService: AlmacenService,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _router: Router,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _activatedRoute: ActivatedRoute
  ) {
    this.storehouse = new Storehouse();
    this.storehouse.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.storehouse.ALM_CODUSE = this._authService.getUsuarioSistema();
    this.storehouse.ALM_FECUPD = new Date();
    this.storehouse.ALM_IDEALM = 1;
    this.storehouse.ALM_INDNUE = 1;
    this.storehouse.ALM_INDMOV = 0;
    this.storehouse.ALM_INDVEN = "0";
    this.storehouse.ALM_INDWEB = 0;
    this.storehouse.ALM_MOVIL = 0;
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })


  }

  loadData(): void {

    this.loaderData = true;

    this._activatedRoute.params.subscribe(params => {
      this.uid = params.id;
      this.loadMaestros()
    })

  }

  loadMaestros(): void {
    this.establecimiento$ = this._almacenService.obtenerEstablecimientos().subscribe(
      establecimientos => {
        this.establecimientos = establecimientos;

        this.tipoAlmacen$ = this._almacenService.obtenerTiposDeAlmacen().subscribe(
          tiposAlmacen => {
            this.tiposAlmacen = tiposAlmacen;

            this.sucursal$ = this._almacenService.listarSucursales().subscribe(
              sucursales => {
                this.sucursales = sucursales;

                if(this.uid != '0') {
                  this.obtenerAlmacen();
                  return;
                }

                this.loaderData = false;
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  obtenerAlmacen(): void {

  }

  enviarFormulario(f: NgForm): void {

    this.uid == '0' ? this.registrarAlmacen(f): this.actualizarAlmacen(f);

  }

  registrarAlmacen(f: NgForm): void {

    (this.storehouse.ALM_INDSTK)? this.storehouse.ALM_INDSTK = 1: this.storehouse.ALM_INDSTK = 0;
    (this.storehouse.ALM_INDTRA)? this.storehouse.ALM_INDTRA = 1: this.storehouse.ALM_INDTRA = 0;
    (this.storehouse.ALM_INDVAL)? this.storehouse.ALM_INDVAL = "1": this.storehouse.ALM_INDVAL = "0";

    this.regAlmacen$ = this._almacenService.registrarAlmacen(this.storehouse).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Almacen registrado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }

    )
  }

  actualizarAlmacen(f: NgForm): void {
    this.regAlmacen$ = this._almacenService.actualizarAlmacen(this.storehouse).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Almacen actualizado', '350px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )

  }

  volver():void {
    this._router.navigate(['/modulo-almacen/almacen']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.establecimiento$,
      this.tipoAlmacen$,
      this.sucursal$,
      this.regAlmacen$,
      this.loading$
    ])

  }

}
