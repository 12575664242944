<app-pavso-title-section [title]="title" [module]="'Compras'"></app-pavso-title-section>

<div class="pav-table-search-container">
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
  </div>
</div>

<div class="mat-elevation-z0">
  <table-title-action [title]="'Todos los registros ' + year + ' - ' + month" [url]="'/modulo-compras/' + shortUrl"
    entity="" [params]="[typeForm, '0']" [validacionPeriodo]="true"></table-title-action>

  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <div class="overflow-x">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones" class="td-responsive">
          <!-- <button mat-icon-button matTooltip="Editar pedido" [routerLink]="['/modulo-compras/registro-de-compra', row.ano + row.mes + row.subdiario + row.correlativo]" color="primary">
                      <mat-icon>edit</mat-icon>
                  </button> -->
          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-compras/' + shortUrl, typeForm, row.ano + row.mes + row.subdiario + row.correlativo]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
            <!-- <div class="pav-btn-circle-more">
                      <mat-icon svgIcon="more"></mat-icon>
                    </div> -->
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="periodo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Período </th>
        <td mat-cell *matCellDef="let row" data-label="Período" class="td-responsive"> {{row.ano}}{{row.mes}} </td>
      </ng-container>

      <ng-container matColumnDef="subdiario">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Subdiario </th>
        <td mat-cell *matCellDef="let row" data-label="subdiario" class="td-responsive"> {{row.subdiario}} </td>
      </ng-container>

      <ng-container matColumnDef="correlativo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo </th>
        <td mat-cell *matCellDef="let row" data-label="Correlativo" class="td-responsive"> {{row.correlativo}} </td>
      </ng-container>

      <ng-container matColumnDef="tipo_documento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> T. D. </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo Documento" class="td-responsive"> {{row.tipo_documento}}
        </td>
      </ng-container>

      <ng-container matColumnDef="nro_documento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento </th>
        <td mat-cell *matCellDef="let row" data-label="Nro. Documento" class="td-responsive"> {{row.nro_documento}}
        </td>
      </ng-container>

      <ng-container matColumnDef="auxiliar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Proveedor </th>
        <td mat-cell *matCellDef="let row" data-label="Cod. Proveedor" class="td-responsive"> {{row.auxiliar}} </td>
      </ng-container>

      <ng-container matColumnDef="nombre_auxiliar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
        <td mat-cell *matCellDef="let row" data-label="Proveedor" class="td-responsive"> {{row.nombre_auxiliar}} </td>
      </ng-container>

      <ng-container matColumnDef="fecha_emision">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha Emi." class="td-responsive"> {{row.fecha_emision}}
        </td>
      </ng-container>

      <ng-container matColumnDef="glosa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
        <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.glosa}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">No se encontraron registros "{{input.value}}"</td>
      </tr>

    </table>

  </div>

  <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
