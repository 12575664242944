import { Component } from "@angular/core";

@Component({
  selector: 'general-component',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent {

}
