import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { REPORTES_COMPRAS } from '@data/json/reportes';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from '@utils/formats/date.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { FiltroReporteCompras } from 'src/app/models/compras/filtro-reporte-compras';
import { AlmacenService, ComprasService, ConfigurationService, ExportExcelService, GeneralService, VentasService } from 'src/app/services';
import { TipoInventarioService } from 'src/app/services/api/almacen/configuracion/tipo-inventario.service';
import { CondicionPagoService } from 'src/app/services/api/compras/maestros/condicion-pago.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { ComprasReportService } from 'src/app/services/reports/modules/compras-report.service';

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.css']
})
export class PurchaseReportComponent implements OnInit, OnDestroy {

  loaderFields: boolean = false;
  loading: boolean;

  task: any = {
    name: 'Campos de pedido',
    completed: false,
    color: 'primary',
    subtasks: []
  };

  allComplete: boolean = false;

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => t.completed = completed);
  }

  tiendas: any[] = [];
  series: any[] = [];
  motivos: any[] = [];
  vendedores: any[] = [];
  productos: any[] = [];
  condiciones: any[] = [];
  inventarios: any[] = [];
  lineas: any[] = [];
  documentos: any[] = [];
  marcas: any[] = [];
  monedas: any[] = [];

  clientes: any[] = [];

  dataForExcel = [];

  dataDetalleFacturacion = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  range: FormGroup;

  columnas: any[] = [];

  year: string;
  month: string;

  reporteSeleccionado: any = ['COMPRAS_RC'];

  reportes: any[] = REPORTES_COMPRAS;

  columnas$: Subscription;
  tiendas$: Subscription;
  cliente$: Subscription;
  serie$: Subscription;
  motivo$: Subscription;
  vendedor$: Subscription;
  producto$: Subscription;
  condicion$: Subscription;
  tipoInventario$: Subscription;
  linea$: Subscription;
  tipoDoc$: Subscription;
  marca$: Subscription;
  moneda$: Subscription;

  period$: Subscription;
  loading$: Subscription;

  filtro: FiltroReporteCompras;

  constructor(
    private _snackBarService: SnackBarService,
    public ete: ExportExcelService,
    private _ventasService: VentasService,
    private _productoService: ProductoService,
    private _comprasService: ComprasService,
    private _condicionPagoService: CondicionPagoService,
    private _comprasReporteService: ComprasReportService,
    private _configurationService: ConfigurationService,
    private _generalService: GeneralService,
    private _almacenService: AlmacenService,
    private _tipoInventarioService: TipoInventarioService,
    private store: Store<PavsoState>
  ) {
    this.filtro = new FiltroReporteCompras();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingCompany) this.loadData();

    })
  }

  loadData(): void {
    this.tiendas$ = this._ventasService.listarTiendas().subscribe(
      tiendas => {
        this.tiendas = tiendas;

        this.cliente$ = this._ventasService.obtenerClientesComerciales().subscribe(
          clientes => {
            this.clientes = clientes;

            this.serie$ = this._ventasService.obtenerSerieFacturacion().subscribe(
              series => {
                this.series = series;

                this.motivo$ = this._almacenService.obtenerMotivos().subscribe(
                  motivos => {
                    this.motivos = motivos;

                    this.vendedor$ = this._ventasService.obtenerVendedores().subscribe(
                      vendedores => {
                        this.vendedores = vendedores;

                        this.producto$ = this._productoService.obtenerProductos().subscribe(
                          productos => {
                            this.productos = productos;

                            this.condicion$ = this._condicionPagoService.obtenerCondicionesPago().subscribe(
                              condiciones => {
                                this.condiciones = condiciones;

                                this.tipoInventario$ = this._tipoInventarioService.obtenerTiposDeInventario().subscribe(
                                  inventarios => {
                                    this.inventarios = inventarios;

                                    this.linea$ = this._almacenService.obtenerLineasDeProducto().subscribe(
                                      lineas => {
                                        this.lineas = lineas;

                                        this.tipoDoc$ = this._almacenService.obtenerTipoDocumentoMov().subscribe(
                                          documentos => {
                                            this.documentos = documentos;

                                            this.marca$ = this._almacenService.obtenerMarcasDeProducto().subscribe(
                                              marcas => {
                                                this.marcas = marcas;

                                                this.moneda$ = this._generalService.obtenerMonedas().subscribe(
                                                  monedas => {
                                                    this.monedas = monedas;

                                                    this.columnas$ = this._generalService.listarColumnasInformeDinamico('1A', '002').subscribe(
                                                      columnas => {
                                                        this.columnas = columnas;
                                                        this.columnas.forEach(element => {
                                                          element['name'] = element.TDR_DESTDR;
                                                          element['completed'] = true;
                                                        });

                                                        this.task.subtasks = this.columnas;
                                                        this.loaderFields = false;
                                                      },
                                                      error => {
                                                        this._snackBarService.showError(error.error.msg, 'OK');
                                                        this.loaderFields = false;
                                                      }
                                                    )
                                                  },
                                                  error => {
                                                    this._snackBarService.showError(error.error.msg, 'OK');
                                                    this.loaderFields = false;
                                                  }
                                                )
                                              },
                                              error => {
                                                this._snackBarService.showError(error.error.msg, 'OK');
                                                this.loaderFields = false;
                                              }
                                            )
                                          },
                                          error => {
                                            this._snackBarService.showError(error.error.msg, 'OK');
                                            this.loaderFields = false;
                                          }
                                        )
                                      },
                                      error => {
                                        this._snackBarService.showError(error.error.msg, 'OK');
                                        this.loaderFields = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError(error.error.msg, 'OK');
                                    this.loaderFields = false;
                                  },

                                )
                              },
                              error => {
                                this._snackBarService.showError(error.error.msg, 'OK');
                                this.loaderFields = false;

                              }
                            )
                          },
                          error => {

                            this._snackBarService.showError(error.error.msg, 'OK');
                            this.loaderFields = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError(error.error.msg, 'OK');
                        this.loaderFields = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'OK');
                    this.loaderFields = false;
                  }
                )
              })
          })
      })
  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;

    let indicador = "";
    switch (this.reporteSeleccionado[0]) {
      case "COMPRAS_RC":
        indicador = "51002"
        break;
      case "COMPRAS_OC":
        indicador = "51002"
        break;
      default:
        indicador = ""
        break;
    }
  }

  verReporte(): void {
    this.loading = true;

    let headers = {
      codano: this.year,
      codmes: this.month,
      coduse: this._configurationService.obtenerIdUsuario(),
      date: formatDateWithSlash(new Date()),
      hour: getHourMinuteSecondsCurrent(new Date()),
      currency: 'SO'
    };

    let otherData = {};

    this._comprasReporteService.verReporte(this.reporteSeleccionado[0], headers, {}, otherData)
      .then(_ => this.loading = false)
      .catch(_ => this.loading = false)
      .finally(() => this.loading = false)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.columnas$,
      this.tiendas$,
      this.cliente$,
      this.serie$,
      this.motivo$,
      this.vendedor$,
      this.producto$,
      this.condicion$,
      this.tipoInventario$,
      this.linea$,
      this.tipoDoc$,
      this.marca$,
      this.moneda$,
      this.period$,
    ])

  }
}
