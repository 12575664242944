/*export class FiltroReporteAlmacen {

  familias: string;
  subFamilias: string;
  almacenes: string;
  responsables: string;
  motivos: string;
  productos: string;
  tiposInventario: string;
  lineas: string;
  tiposDocumento: string;
  moneda: any;
  marcas: string;
  colores: string;
  tecnologias: string;
  lote: string;
  unidadMedida: string;

  constructor() {

    this.moneda = 'SO';

  }

}
*/


export class FiltroReporteAlmacen {

  familias: Array<any>;
  subFamilias: Array<any>;
  almacenes: Array<any>;
  responsables: Array<any>;
  motivos: Array<any>;
  productos: Array<any>;
  tiposInventario: Array<any>;
  lineas: Array<any>;
  tiposDocumento: Array<any>;
  moneda: string;
  marcas: Array<any>;
  colores: Array<any>;
  tecnologias: Array<any>;
  lote: string;
  unidadesMedida: Array<any>;
  series: Array<any>;
  establecimiento: string;

  constructor() {

    this.moneda = 'SO';
    this.familias = [];
    this.subFamilias = [];
    this.almacenes = [];
    this.responsables = [];
    this.motivos = [];
    this.productos = [];
    this.tiposInventario = [];
    this.lineas = [];
    this.tiposDocumento = [];
    this.marcas = [];
    this.colores = [];
    this.tecnologias = [];
    this.unidadesMedida = [];
    this.series = [];

    this.establecimiento = '';
  }

}
