import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MotivoRechazo } from "src/app/models/produccion/maestros/motivo-rechazo";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class MotivoRechazoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}
/**
   *
   * @returns
   */
  obtenerMotivosRechazo(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionmotivorechazo/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  mostrarMotivoRechazo(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionmotivorechazo/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param motivo_rechazo
   * @returns
   */
  registrarMotivoRechazo(motivo_rechazo: MotivoRechazo): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionmotivorechazo/crear`,
      motivo_rechazo
    );
  }

  /**
   *
   * @param motivo_rechazo
   * @returns
   */
  actualizarMotivoRechazo(motivo_rechazo: MotivoRechazo): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/produccionmotivorechazo/modificar`,
      motivo_rechazo
    );
  }
}