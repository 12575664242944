<app-pavso-title-section [title]="'Control SIRE No Domiciliados'" [module]="'Contabilidad'"
  [ngClass]="'pav-form'"></app-pavso-title-section>
  <form action="">
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l4">
          <div [ngStyle]="{display: 'flex'}">
            <mat-form-field class="example-full-width">
              <mat-label>Nro. Ticket</mat-label>
              <input matInput placeholder="Ej. 00000">
            </mat-form-field>
            <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
              <mat-icon>manage_search</mat-icon>
            </button>
          </div>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select [(ngModel)]="filtro.moneda" name="moneda">
              <mat-option [value]="'SO'">
                Soles
              </mat-option>
              <mat-option [value]="'DO'">
                Dólares
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
      <div class="row">
        <div class="col s12 m12">
          <button
            [ngStyle]="{height: '45px', width: isMobile || isTablet ? '100%': 'auto', background: '#F7C91B', color: '#222'}"
            mat-stroked-button (click)="descargarSIRE()" color="primary" [class.spinner]="loaderSIRE"
            [disabled]="loaderSIRE || loaderData" matTooltip="Descargar SIRE">
            <mat-icon>cloud_download</mat-icon>
            Cargar Información Compras no Domiciliadas
          </button>
        </div>
      </div>
    </mat-card>

    <div class="mat-elevation-z0">
      <table-title-action title="Detalle" url="/modulo-contabilidad/centro-de-costo" entity="" [params]="['0']"
        (clickDescargar)="descargarExcel()"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar"
                  [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col01">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
            <td mat-cell *matCellDef="let row; let i = index;" data-label="Código" class="td-responsive"> {{i + 1}}
            </td>
          </ng-container>

          <ng-container matColumnDef="col02">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CUO (1) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecemi | date:
              'dd/MM/YYYY': 'UTC'}} </td>
          </ng-container>

          <ng-container matColumnDef="col03">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo (2) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecven | date:
              'dd/MM/YYYY': 'UTC'}} </td>
          </ng-container>

          <ng-container matColumnDef="col04">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Comprobante (3) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="col05">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Comprobante de Pago (4) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_serdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="col06">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie del Comprobante de Pago (5) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numini}} </td>
          </ng-container>

          <ng-container matColumnDef="col07">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. del Comprobante de Pago (6) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numfin}} </td>
          </ng-container>

          <ng-container matColumnDef="col08">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor de las adquisiciones (7) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipide}} </td>
          </ng-container>

          <ng-container matColumnDef="col09">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Conceptos adicionales (8) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numide}} </td>
          </ng-container>

          <ng-container matColumnDef="col10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total de las Adquisiciones (9) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_proraz}} </td>
          </ng-container>

          <ng-container matColumnDef="col11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Comprobante de Pago (10) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_bigrdg.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col12">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie de Comprobante de Pago (11) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_bigrdg.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col13">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año de Emisión de la DUA o DS (12) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_igvdg.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col14">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número del Comprobante de Pago (13) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_bigdgnd.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col15">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto de Retención del IGV (14) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_igvdgng.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col16">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código de la Moneda (Tabla 4) (15) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_bigrdng.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col17">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Cambio (16) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_igvdng.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col18">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> País de la Residencia del Sujeto no Domiciliado (17) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_vaadng.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col19">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellidos y Nombres, denominación o razon social (18) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_impisc.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col20">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Domicilio en el extrajero del sujeto no domiciliado (19)</th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_icbper.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col21">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de identificación del sujeto no domiciliado (20) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_otrtri.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col22">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de identificación fiscal del beneficiario efectivo de los pagos (21) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_imptot.toFixed(2)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col23">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellidos y Nombres, denominación o razon social del beneficiario efectivo de los pagos (22) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_codtmo}} </td>
          </ng-container>

          <ng-container matColumnDef="col24">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> País de la residencia del beneficiario efectivo de los pagos (23) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
              <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                {{row.psc_tipcam.toFixed(3)}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="col25">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Vínculo entre el contribuyente y el residente en el extranjero (24) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecref}} </td>
          </ng-container>

          <ng-container matColumnDef="col26">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Renta Bruta (25) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_docref}} </td>
          </ng-container>

          <ng-container matColumnDef="col27">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Deducción / Costo de enajenación de bienes de capital (26) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_serref}} </td>
          </ng-container>

          <ng-container matColumnDef="col28">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Renta Neta (27) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_coddam}} </td>
          </ng-container>

          <ng-container matColumnDef="col29">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tasa de Retención (28) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_coddam}} </td>
          </ng-container>

          <ng-container matColumnDef="col30">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Impuesto Retenido (29) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numref}} </td>
          </ng-container>

          <ng-container matColumnDef="col31">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Convenios para evitar la doble imposición (30) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_idprop}} </td>
          </ng-container>

          <ng-container matColumnDef="col32">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Exoneración aplicada (31) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_porpar}} </td>
          </ng-container>

          <ng-container matColumnDef="col33">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Renta (32) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_impimb}} </td>
          </ng-container>

          <ng-container matColumnDef="col34">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Modalidad del servicio prestado por el no domiciliado (33) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_carori}} </td>
          </ng-container>

          <ng-container matColumnDef="col35">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Aplicación del penúltimo párrafo del Art. 76 de la ley del impuesto a la renta (34) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_detracc}} </td>
          </ng-container>

          <ng-container matColumnDef="col36">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado que identifica a la oportunidad de la anotación o indicación ésta corresponde a un ajuste (35) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipnot}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="81">No se encontraron registros </td>

          </tr>

        </table>

      </div>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Cantidad ítems</mat-label>
            <input readonly [(ngModel)]="dataSource.data.length" name="cantidad_items"
              [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <div [ngStyle]="{display: 'flex'}">
            <mat-form-field class="example-full-width">
              <mat-label>Archivo</mat-label>
              <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
            </mat-form-field>
            <button [ngStyle]="{marginLeft: '5px'}" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
              <mat-icon>done_all</mat-icon>
            </button>
          </div>

        </div>
        <div class="col s12 m12 l2">
          <button mat-button color="primary">Genera TXT SIRE</button>

        </div>
        <div class="col s12 m12 l2">

        </div>
        <div class="col s12 m12 l2">

        </div>
        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Total Adquisiciones</mat-label>
            <input readonly [(ngModel)]="totalICPPER" name="totalICPPER" [ngStyle]="{textAlign: 'right'}" matInput
              placeholder="Ej. 23">
          </mat-form-field>

        </div>
      </div>
    </mat-card>
  </form>

