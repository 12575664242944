import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Angular2Txt } from 'angular2-txt';
import { ConfigurationService, ContabilidadService } from 'src/app/services';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

class TotalesVentas {
  cantidad: any;
  tot_bas_imp_grav: any;
  inafecta: any;
  arroz_pilado: any;
  otros_tributos: any;
  total_igv: any;
  exonerado: any;
  imp_arroz_pilado: any;
  importe_total: any;
  valor_fac_expo: any;
  isc: any;
  constructor(
  ){
    this.cantidad = 0;
    this.tot_bas_imp_grav = 0;
    this.inafecta = 0;
    this.arroz_pilado = 0;
    this.otros_tributos = 0;
    this.total_igv = 0;
    this.exonerado = 0;
    this.imp_arroz_pilado = 0;
    this.importe_total = 0;
    this.valor_fac_expo = 0;
    this.isc = 0;
  }
}

class TotalesCompras {
  cantidad: any;
  tot_base_imponible: any;
  valor_adq_no_grav: any;
  total_igv: any;
  importe_total: any;
  constructor(
  ){
    this.cantidad = 0;
    this.tot_base_imponible = 0;
    this.valor_adq_no_grav = 0;
    this.total_igv = 0;
    this.importe_total = 0;
  }
}

class TotalesCompND {
  cantidad: any;
  total_adq: any;
  constructor(
  ){
    this.cantidad = 0;
    this.total_adq = 0;
  }
}

@Component({
  selector: 'app-interface-ple',
  templateUrl: './interface-ple.component.html',
  styleUrls: ['./interface-ple.component.css']
})
export class InterfacePleComponent implements OnInit, OnDestroy {

  displayedColumns1: string[] = ['campo1', 'campo2', 'campo3', 'campo4', 'campo5', 'campo6', 'campo7', 'campo8', 'campo9', 'campo10', 'campo11', 'campo12', 'campo13', 'campo14', 'campo15', 'campo16', 'campo17', 'campo18', 'campo19', 'campo20', 'campo21', 'campo22', 'campo23', 'campo24', 'campo25', 'campo26', 'campo27', 'campo28', 'campo29', 'campo30', 'campo31', 'campo32', 'campo33'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  displayedColumns2: string[] = ['campo1', 'campo2', 'campo3', 'campo4', 'campo5', 'campo6', 'campo7', 'campo8', 'campo9', 'campo10', 'campo11', 'campo12', 'campo13', 'campo14', 'campo15', 'campo16', 'campo17', 'campo18', 'campo19', 'campo20', 'campo21', 'campo22', 'campo23', 'campo24', 'campo25', 'campo26', 'campo27', 'campo28', 'campo29', 'campo30', 'campo31', 'campo32', 'campo33', 'campo34', 'campo35', 'campo36'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  displayedColumns3: string[] = ['campo1', 'campo2', 'campo3', 'campo4', 'campo5', 'campo6', 'campo7', 'campo8', 'campo9', 'campo10', 'campo11', 'campo12', 'campo13', 'campo14', 'campo15', 'campo16', 'campo17', 'campo18', 'campo19', 'campo20', 'campo21', 'campo22', 'campo23', 'campo24', 'campo25', 'campo26', 'campo27', 'campo28'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;


  loaderAction: boolean;

  tipo: any;
  showButtonExtrae: boolean;
  showButtonRevisa: boolean;
  showButtonGeneraArchivo: boolean;

  showRevisaComprasDom: boolean;
  showRevisaComprasNoDom: boolean;
  showRevisaVentas: boolean;

  data = [
    {
      name: "Test 1",
      age: 13,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' "
    },
    {
      name: 'Test 2',
      age: 11,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' "
    },
    {
      name: 'Test 4',
      age: 10,
      average: 8.2,
      approved: true,
      description: "using 'Content here, content here' "
    },
  ];

  options = {
    fieldSeparator: '|',
    quoteStrings: '',
    decimalseparator: '.',
    showTitle: false,
    useBom: false
  };

  year: string;
  month: string;

  totalesVentas: TotalesVentas;
  totalesComp: TotalesCompras;
  totalesCompND: TotalesCompND;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _dialogService: DialogService,
    private _contabilidadService: ContabilidadService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
  ) {
    this.totalesVentas = new TotalesVentas();
    this.totalesComp = new TotalesCompras();
    this.totalesCompND = new TotalesCompND();

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })
    this.tipo = "1";
    this.showButtonExtrae = true;
    this.showButtonRevisa = true;
    this.showButtonGeneraArchivo = false;

    this.loaderAction = false;

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);

    this.dataSource3 = fillTable([], this.paginator3, this.sort3);

    this.showRevisaComprasDom = false;
    this.showRevisaComprasNoDom = false;
    this.showRevisaVentas = false;
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  /**
   *
   * @param event
   */
  seleccionarOpcion(event): void {

    switch (event.value) {
      case "1":
        this.showButtonExtrae = true;
        this.showButtonRevisa = true;
        this.showButtonGeneraArchivo = false;
        break;
      case "2":
        this.showButtonExtrae = true;
        this.showButtonRevisa = true;
        this.showButtonGeneraArchivo = false;
        break;
      case "3":
        this.showButtonExtrae = true;
        this.showButtonRevisa = true;
        this.showButtonGeneraArchivo = false;
        break;
      case "4":
        this.showButtonExtrae = true;
        this.showButtonRevisa = false;
        this.showButtonGeneraArchivo = true;
        break;
      case "5":
        this.showButtonExtrae = true;
        this.showButtonRevisa = false;
        this.showButtonGeneraArchivo = false;
        break;
      case "6":
        this.showButtonExtrae = false;
        this.showButtonRevisa = false;
        this.showButtonGeneraArchivo = false;
        break;
      default:
        break;
    }

  }

  extraeInformacion(): void {
    this.loaderAction = true;
    switch (this.tipo) {
      case "1":
        this._contabilidadService.extraerInformacionLibroElectronicoCompras(this.year, this.month, "SO").subscribe(
          response => {
            this._dialogService.openDialog(SuccessComponent, 'Información Extraída', '300px', '', '');

            this.loaderAction = false;
          },
          error => {
            this._snackBarService.showError("Error al extraer información", "OK");
            this.loaderAction = false;
          }
        )
        break;
      case "2":
        this._contabilidadService.extraerInformacionComprasNoDomiciliados(this.year, this.month, "SO").subscribe(
          response => {
            this._dialogService.openDialog(SuccessComponent, 'Información Extraída', '300px', '', '');

            this.loaderAction = false;
          },
          error => {
            this._snackBarService.showError("Error al extraer información", "OK");
            this.loaderAction = false;
          }
        )
        break;
      case "3":
        this._contabilidadService.extraeInformacionVentas(this.year, this.month, "SO").subscribe(
          response => {
            this._dialogService.openDialog(SuccessComponent, 'Información Extraída', '300px', '', '');

            this.loaderAction = false;
          },
          error => {
            this._snackBarService.showError("Error al extraer información", "OK");
            this.loaderAction = false;
          }
        )
        break;
      case "4":
        this._contabilidadService.extraeInformacionLibroDiario(this.year, this.month).subscribe(
          response => {
            this._dialogService.openDialog(SuccessComponent, 'Información Extraída', '300px', '', '');

            this.loaderAction = false;
          },
          error => {
            this._snackBarService.showError("Error al extraer información", "OK");
            this.loaderAction = false;
          }
        )
        break;
      case "5":
        this._contabilidadService.extraeInformacionLibroMayor(this.year, this.month).subscribe(
          response => {
            this._dialogService.openDialog(SuccessComponent, 'Información Extraída', '300px', '', '');

            this.loaderAction = false;
          },
          error => {
            this._snackBarService.showError("Error al extraer información", "OK");
            this.loaderAction = false;
          }
        )
        break;
      case "6":

        break;
      default:
        break;
    }
  }

  generarArchivoPlanDeCuentas(): void {
    this._contabilidadService.generaArchivoPlanDeCuenta(this.year, this.month).subscribe(
      response => {
        let dataTxt = [];
        const year = this.year;
        const month = this.month;
        const ultimoDia = new Date(parseInt(year), parseInt(month), 0);
        response.forEach(element => {
          dataTxt.push({
            ls_periodo: `${year}${month}${ultimoDia.getDate()}`,
            ls_codccn: element.ccn_descri.trim(),
            ls_placnt: "01",
            ls_despla: "PLAN CONTABLE GENERAL EMPRESARIAL",
            ls_space1: "",
            ls_space2: "",
            ls_indsta: element.ccn_indsta
          })
        });
        new Angular2Txt(dataTxt, "plancuenta", this.options);
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  generarTxt(): void {

    switch (this.tipo) {
      case "1":
        this._contabilidadService.generaTxtCompras(`${this.year}${this.month}`).subscribe(
          response => {
            this.data = response
            let dataTxt = [];
            response.forEach(element => {
              dataTxt.push({
                periodo: element.periodo,
                cuo: element.CUO.trim(),
                correlativ: element.correlativ.trim(),
                lrc_fecemi: (element.lrc_fecemi)? element.lrc_fecemi.substring(0,10): '',
                lrc_fecven: (element.lrc_fecven)? element.lrc_fecven.substring(0,10): '',
                tdo_codofi: element.tdo_codofi,
                lrc_serie: element.lrc_serie.trim(),
                lrc_anodua: element.lrc_anodua.trim(),
                lrc_numdoc: element.lrc_numdoc.trim(),
                lrc_consol: element.lrc_consol.trim(),
                did_codofi: element.did_codofi.trim(),
                cli_codcli: element.cli_codcli.trim(),
                cli_nomcli: element.cli_nomcli.trim(),
                baimpoexpo: element.baimpoexpo,
                igvpm: element.igvpm,
                bainograva: element.bainograva,
                igvpm2: element.igvpm2,
                baimnofisc: element.baimnofisc,
                igvpm3: element.igvpm3,
                adqnograva: element.adqnograva,
                isc: element.isc,
                otrconcept: element.otrconcept,
                imptotadq: element.imptotadq,
                tmo_codtmo: element.tmo_codtmo,
                lrc_tipcam: element.lrc_tipcam,
                lrc_fecref: (element.lrc_fecref)? element.lrc_fecref.substring(0,10): '',
                tdo_refofi: element.tdo_refofi,
                lrc_serref: element.lrc_serref.trim(),
                cod_aduana: element.cod_aduana.trim(),
                lrc_numref: element.lrc_numref.trim(),
                LRC_FECDET: "",
                lrc_depdet: element.lrc_depdet.trim(),
                mar_retenc: element.mar_retenc.trim(),
                lrc_cbsa30: element.lrc_cbsa30,
                lrc_icpnci: element.lrc_icpnci.trim(),
                lrc_ertcam: element.lrc_ertcam.trim(),
                lrc_inpnh: element.lrc_inpnh.trim(),
                lrc_pexigv: element.lrc_pexigv.trim(),
                lrc_incdni: element.lrc_incdni.trim(),
                lrc_cpcmp: element.lrc_cpcmp.trim(),
                lrc_estad: element.lrc_estad,
              })
            });
            new Angular2Txt(dataTxt, `LE${this._configurationService.obtenerCiaNumRuc()}${this.year}${this.month}00080100001111_REG_COMPRAS`, this.options);
          },
          error => this._snackBarService.showError(error.error.msg, "")
        )
        break;
      case "2":
        this._contabilidadService.generaTxtComprasNoDomiciliados(`${this.year}${this.month}`).subscribe(
          response => {
            this.data = response;
            new Angular2Txt(this.data, `LE${this._configurationService.obtenerCiaNumRuc()}${this.year}${this.month}00080100001111_REG_COMPRAS`, this.options);
          },
          error => this._snackBarService.showError(error.error.msg, "")
        )
        break;
      case "3":
        this._contabilidadService.generaTxtVentas(`${this.year}${this.month}`).subscribe(
          response => {

            let dataTxt = [];

            response.forEach(element => {
              dataTxt.push({
                ls_periodo: element.lrv_period.trim(),
                ls_codCUO: element.ldc_corldc.trim(),
                ls_Correlativo: element.lrv_correl.trim(),
                ls_fecemi: element.cco_fecemi.substring(0, 10),
                ls_fecven: (element.cco_fecven)? element.cco_fecven.substring(0, 10): '',
                ls_codofi: element.tdo_codofi.trim(),
                ls_serie: element.lrv_serie.trim(),
                ls_numdoc: element.lrv_num.trim(),
                ls_ticket: element.lrv_ticket.trim(),
                ls_coddoc02: element.did_codofi.trim(),
                ls_codcli: element.cli_codcli.trim(),
                ls_nomcli: element.cli_nomcli.trim(),
                ls_valexpo: element.lrv_valexp,
                ls_impafemn: element.lrv_impafe,
                ls_desbim: element.lrv_desbim,
                ls_impigvmn: element.lrv_impigv,
                ls_desigv: element.lrv_desigv,
                ls_impoexon: element.lrv_impexo,
                ls_impinamn: element.lrv_impina,
                ls_isc: element.lrv_impisc,
                ls_bimparroz: element.lrv_bimpar,
                ls_imparroz: element.lrv_imparr,
                ls_otrostributos: element.lrv_otrimp,
                ls_impdocmn: element.lrv_impdoc,
                ls_codtmo: element.tmo_codtmo.trim(),
                ls_tipcambio: element.lrv_tipcam,
                ls_fecref: element.lrv_fecref.substring(0, 10),
                ls_tdorefofi: element.tdo_refofi.trim(),
                ls_serieref: element.lrv_serref.trim(),
                ls_numref: element.lrv_numref.trim(),
                ls_indnci: element.lrv_indnci.trim(),
                ls_errtca: element.lrv_errtca.trim(),
                ls_icpcmp: element.lrv_icpcmp.trim(),
                ls_estado: element.lrv_indsta.trim(),
              });

            });

            this.data = response;
            new Angular2Txt(dataTxt, `LE${this._configurationService.obtenerCiaNumRuc()}${this.year}${this.month}00140100001111_REG_VENTAS`, this.options);
          },
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
        break;
      case "4":
        this._contabilidadService.generaTxtLibroDiario(`${this.year}${this.month}`).subscribe(
          response => {

            let dataTxt = [];

            response.forEach(element => {
              dataTxt.push({
                ls_periodo: element.lrv_period.trim(),
                ls_codCUO: element.ldc_corldc.trim(),
                ls_Correlativo: element.lrv_correl.trim(),
                ls_fecemi: element.cco_fecemi.substring(0, 10),
                ls_fecven: (element.cco_fecven)? element.cco_fecven.substring(0, 10): '',
                ls_codofi: element.tdo_codofi.trim(),
                ls_serie: element.lrv_serie.trim(),
                ls_numdoc: element.lrv_num.trim(),
                ls_ticket: element.lrv_ticket.trim(),
                ls_coddoc02: element.did_codofi.trim(),
                ls_codcli: element.cli_codcli.trim(),
                ls_nomcli: element.cli_nomcli.trim(),
                ls_valexpo: element.lrv_valexp,
                ls_impafemn: element.lrv_impafe,
                ls_desbim: element.lrv_desbim,
                ls_impigvmn: element.lrv_impigv,
                ls_desigv: element.lrv_desigv,
                ls_impoexon: element.lrv_impexo,
                ls_impinamn: element.lrv_impina,
                ls_isc: element.lrv_impisc,
                ls_bimparroz: element.lrv_bimpar,
                ls_imparroz: element.lrv_imparr,
                ls_otrostributos: element.lrv_otrimp,
                ls_impdocmn: element.lrv_impdoc,
                ls_codtmo: element.tmo_codtmo.trim(),
                ls_tipcambio: element.lrv_tipcam,
                ls_fecref: element.lrv_fecref.substring(0, 10),
                ls_tdorefofi: element.tdo_refofi.trim(),
                ls_serieref: element.lrv_serref.trim(),
                ls_numref: element.lrv_numref.trim(),
                ls_indnci: element.lrv_indnci.trim(),
                ls_errtca: element.lrv_errtca.trim(),
                ls_icpcmp: element.lrv_icpcmp.trim(),
                ls_estado: element.lrv_indsta.trim(),
              });

            });

            this.data = response;
            new Angular2Txt(dataTxt, `LE${this._configurationService.obtenerCiaNumRuc()}${this.year}${this.month}00050100001111`, this.options);
          },
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
        break;
      case "5":
        this._contabilidadService.generaTxtLibroMayor(`${this.year}${this.month}`).subscribe(
          response => {

            let dataTxt = [];

            response.forEach(element => {
              dataTxt.push({
                ls_periodo: element.lrv_period.trim(),
                ls_codCUO: element.ldc_corldc.trim(),
                ls_Correlativo: element.lrv_correl.trim(),
                ls_fecemi: element.cco_fecemi.substring(0, 10),
                ls_fecven: (element.cco_fecven)? element.cco_fecven.substring(0, 10): '',
                ls_codofi: element.tdo_codofi.trim(),
                ls_serie: element.lrv_serie.trim(),
                ls_numdoc: element.lrv_num.trim(),
                ls_ticket: element.lrv_ticket.trim(),
                ls_coddoc02: element.did_codofi.trim(),
                ls_codcli: element.cli_codcli.trim(),
                ls_nomcli: element.cli_nomcli.trim(),
                ls_valexpo: element.lrv_valexp,
                ls_impafemn: element.lrv_impafe,
                ls_desbim: element.lrv_desbim,
                ls_impigvmn: element.lrv_impigv,
                ls_desigv: element.lrv_desigv,
                ls_impoexon: element.lrv_impexo,
                ls_impinamn: element.lrv_impina,
                ls_isc: element.lrv_impisc,
                ls_bimparroz: element.lrv_bimpar,
                ls_imparroz: element.lrv_imparr,
                ls_otrostributos: element.lrv_otrimp,
                ls_impdocmn: element.lrv_impdoc,
                ls_codtmo: element.tmo_codtmo.trim(),
                ls_tipcambio: element.lrv_tipcam,
                ls_fecref: element.lrv_fecref.substring(0, 10),
                ls_tdorefofi: element.tdo_refofi.trim(),
                ls_serieref: element.lrv_serref.trim(),
                ls_numref: element.lrv_numref.trim(),
                ls_indnci: element.lrv_indnci.trim(),
                ls_errtca: element.lrv_errtca.trim(),
                ls_icpcmp: element.lrv_icpcmp.trim(),
                ls_estado: element.lrv_indsta.trim(),
              });

            });

            this.data = response;
            new Angular2Txt(dataTxt, `LE${this._configurationService.obtenerCiaNumRuc()}${this.year}${this.month}00140100001111`, this.options);
          },
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
        break;
      case "6":

        break;
      default:
        break;
    }

  }

  revisaInformacion(): void {

    switch (this.tipo) {
      case "1":
        this._contabilidadService.revisaInformacionCompras(`${this.year}${this.month}`).subscribe(
          response => {
            this.dataSource1 = fillTable(response, this.paginator1, this.sort1);
            this.showRevisaComprasDom = true;
            this.showRevisaComprasNoDom = false;
            this.showRevisaVentas = false;

            this.totalesComp.cantidad = response.length
            response.forEach(element => {
              this.totalesComp.tot_base_imponible += element.baimpoexpo;

              this.totalesComp.valor_adq_no_grav += element.adqnograva;
              this.totalesComp.total_igv += element.igvpm;
              this.totalesComp.importe_total += element.imptotadq;
            });
          },
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
        break;
      case "2":
        this._contabilidadService.revisaInformacionComprasNoDomiciliados(`${this.year}${this.month}`).subscribe(
          response => {
            this.dataSource2 = fillTable(response, this.paginator2, this.sort2);
            this.showRevisaComprasDom = false;
            this.showRevisaComprasNoDom = true;
            this.showRevisaVentas = false;

            this.totalesCompND.cantidad = response.length
            response.forEach(element => {

            });
          },
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
        break;
      case "3":
        this._contabilidadService.revisaInformacionVentas(`${this.year}${this.month}`).subscribe(
          response => {
            this.dataSource3 = fillTable(response, this.paginator3, this.sort3);
            this.showRevisaComprasDom = false;
            this.showRevisaComprasNoDom = false;
            this.showRevisaVentas = true;

            this.totalesVentas.cantidad = response.length
            response.forEach(element => {
              this.totalesVentas.tot_bas_imp_grav += element.lrv_impafe;
              this.totalesVentas.inafecta += element.lrv_impina;
              this.totalesVentas.arroz_pilado += element.lrv_bimpar;
              this.totalesVentas.otros_tributos += element.lrv_otrimp;
              // this.totalesVentas.total_igv += element.total_igv;
              this.totalesVentas.exonerado += element.lrv_impexo;
              this.totalesVentas.imp_arroz_pilado += element.lrv_bimpar;
              // this.totalesVentas.importe_total += element.importe_total;
              this.totalesVentas.valor_fac_expo += element.lrv_valexp;
              this.totalesVentas.isc += element.lrv_impisc;
            });
          },
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
        break;
      case "4":

        break;
      case "5":

        break;
      case "6":

        break;
      default:
        break;
    }


  }

  extraeInformacionCompras(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }

}
