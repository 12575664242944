<app-pavso-title-section [title]="'Marca'" [module]="'Ventas'" [estado]="brand.MPR_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="brand.MPR_CODMPR" matInput placeholder="Código" name="LDC_CODLDC" [disabled]="loaderData" readonly>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l5">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput [(ngModel)]="brand.MPR_DESCRI" placeholder="Descripción" name="MPR_DESCRI" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l5">
          <mat-form-field>
            <mat-label>Descri. Abrev.</mat-label>
            <input type="text" matInput [(ngModel)]="brand.MPR_DESABR" placeholder="Descri. Abrev." name="MPR_DESABR" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Prefijo</mat-label>
            <input type="text" matInput [(ngModel)]="brand.MPR_PREfij" placeholder="Prefijo" name="MPR_PREfij" [maxlength]="2" [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-almacen/marca-producto">

    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
