import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { TipoListaPrecio } from "src/app/models/ventas/tipo-lista-precio";
import { ConfigurationService, VentasService } from "src/app/services";

@Component({
  selector: 'tipo-lista-precio-form',
  templateUrl: './tipo-lista-precio-form.component.html',
  styleUrls: ['./tipo-lista-precio-form.component.scss']
})
export class TipoListaPrecioFormComponent implements OnInit, OnDestroy{
  loaderReg: boolean;
  loaderData: boolean;

  uid: string;
  tipoListaPrecio: TipoListaPrecio;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  sendForm$: Subscription;
  loading$: Subscription;
  dataForm$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _configurationService: ConfigurationService
  ) {
    this.tipoListaPrecio = new TipoListaPrecio();
    this.tipoListaPrecio.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.tipoListaPrecio.tlp_coduse = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
      this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.btnName = this.uid == '0' ?  this.buttonsName.BTN_STORE: this.buttonsName.BTN_UPDATE;
      if(this.uid != '0'){
        this.mostrarTipoListaPrecio()
        return
      }

    })
  }
  mostrarTipoListaPrecio(): void {
      this.dataForm$ = this._ventasService.mostrarTipoListaPrecio(this.uid).subscribe(
      response => {
        console.log("tipolistaprecio", response);
        this.tipoListaPrecio = response;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarTipoListaPrecio(f): this.actualizarTipoListaPrecio(f);
  }

  registrarTipoListaPrecio(f: NgForm): void {
    this.sendForm$ = this._ventasService.registrarTipoListaPrecio(this.tipoListaPrecio).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de lista precio registrado', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarTipoListaPrecio(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarTipoListaPrecio(this.tipoListaPrecio).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de lista precio actualizado', '400px', '400px', '');
        this.loaderReg = false;
        this.volver();
      },
        error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/tipo-lista-precio']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.dataForm$,
      this.loading$
    ])
  }
}
