import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SeguimientoOportunidad } from "src/app/models/crm/seguimiento_oportunidad";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class SeguimientoOportunidadService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerSeguimientosOportunidad(tkt): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/seguimientos-oportunidad/${this._configurationService.obtenerCompaniaCliente()}/${tkt}`
    );
  }

  obtenerSeguimientosPorEtapa(idtkt): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/seguimientos-etapa/${this._configurationService.obtenerCompaniaCliente()}/${idtkt}`
    );
  }

  obtenerSeguimientoOportunidad(tkt, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/seguimientos-oportunidad/${this._configurationService.obtenerCompaniaCliente()}/${tkt}/${cod}`
    );
  }

  registrarSeguimientoOportunidad(seguimiento: SeguimientoOportunidad): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/seguimiento-oportunidad`,
      seguimiento
    );
  }

  actualizarSeguimientoOportunidad(seguimiento: SeguimientoOportunidad): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/seguimiento-oportunidad`,
      seguimiento
    );
  }
}
