<app-pavso-title-section [title]="'Solicitud de compra'" [module]="'Compras'" [ngClass]="'pav-form'"
  [estado]="'1'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>N° Requerimiento</mat-label>
            <input [(ngModel)]="solicitudCompra.rco_numrco" type="text" matInput placeholder="N° Requerimiento"
              name="rco_numrco" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="fechaReg" [(ngModel)]="solicitudCompra.rco_fecreq" name="rco_fecreq"
              [disabled]="loaderData">
            <mat-datepicker-toggle matSuffix [for]="fechaReg"></mat-datepicker-toggle>
            <mat-datepicker #fechaReg></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Procedencia</mat-label>
            <mat-select [(ngModel)]="solicitudCompra.rco_tipnac" name="rco_tipnac" [disabled]="loaderData">
              <mat-option value="N">
                Nacional
              </mat-option>
              <mat-option value="I">
                Internacional
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="deliverydate" [disabled]="loaderData">
            <mat-datepicker-toggle matSuffix [for]="deliverydate"></mat-datepicker-toggle>
            <mat-datepicker #deliverydate></mat-datepicker>
          </mat-form-field>
        </div>


      </div>

      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Tipo de compra</mat-label>
            <mat-select [(ngModel)]="solicitudCompra.rco_tipcom" name="rco_tipcom" [disabled]="loaderData">
              <mat-option value="tipocompra">
                tipo de compra 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <app-currency-select [moneda]="solicitudCompra.tmo_codtmo" [disable]="loaderData"
            (cambioMoneda)="solicitudCompra.tmo_codtmo = $event"></app-currency-select>
        </div>
        <div class="col s12 m12 l12 xl3">
          <select-search [label]="'Solcitante'" [placeholder]="'Ej. HUGO PEREZ'" [name]="'cli_codcli'"
            [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="solicitantes"
            [model]="solicitudCompra.cli_codsol" (cambioSelect)="solicitudCompra.cli_codsol = $event">
          </select-search>

        </div>
        <div class="col s12 m12 l12 xl3">

          <select-search [label]="'Autorización'" [placeholder]="'Ej. PAEZ CARDENAS'" [name]="'cli_codcli'"
            [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="aprobadores"
            [model]="solicitudCompra.rco_cliaut" (cambioSelect)="solicitudCompra.rco_cliaut = $event">
          </select-search>

        </div>
        <div class="col s12 m12 l12 xl6">
          <mat-form-field class="example-full-width">
            <mat-label>Detalle</mat-label>
            <textarea [(ngModel)]="solicitudCompra.rco_gloobs" name="rco_gloobs" matInput placeholder="Detalle"
              [disabled]="loaderData"></textarea>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">
          <div [ngStyle]="{display: 'flex'}">
            <select-search [label]="'Proveedor Sugerido'" [placeholder]="'Ej. JUAN RULFO'" [name]="'cli_codcli'"
              [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="proveedores"
              [model]="solicitudCompra.rco_clipro" (cambioSelect)="solicitudCompra.rco_clipro = $event">
            </select-search>

            <button mat-mini-fab color="primary" type="button" [disabled]="loaderData" [ngStyle]="{marginLeft: '5px'}">
              <mat-icon>settings</mat-icon>
            </button>

          </div>

        </div>

      </div>

    </mat-card>

    <br>

    <mat-tab-group dynamicHeight color="primary">
      <mat-tab label="Ofertas" labelClass="mat-tab-label-0-0">
        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Ofertas</div>
        </div>

        <div class="mat-elevation-z0">

          <table mat-table [dataSource]="dataSource" matSort matSort>

            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.ocm_numocm}} </td>
            </ng-container>

            <ng-container matColumnDef="tipo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.toc_codtoc}} </td>
            </ng-container>

            <ng-container matColumnDef="id_proveedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Id. Proveedor </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ocm_fecocm }} </td>
            </ng-container>

            <ng-container matColumnDef="nombre_proveedor">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Proveedor </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="contacto">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Contacto </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="mo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mo. </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="importe_ref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Ref. </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="comentario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Comentario </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="adjunto">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjunto </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button type="button" matTooltip="Ver PDF" (click)="mostrarPdf(row.ocm_numocm)" mat-icon-button
                  color="warn">
                  <mat-icon>picture_as_pdf</mat-icon>
                </button>
                &nbsp;
                <button type="button" matTooltip="Editar"
                  [routerLink]="['/modulo-compras/editar-orden-de-compra', row.ocm_numocm]" mat-icon-button
                  color="edit">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="10">No se encontraron registros</td>
            </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </mat-tab>
      <mat-tab label="Artículos" labelClass="mat-tab-label-0-1">
        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title"
            [ngStyle]="{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }">
            <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
              <div>
                Artículos
              </div>

            </div>

            <div [ngStyle]="{display: 'flex' }">
              <div>

                <button-add-item-table (addItem)="agregarArticulo()"></button-add-item-table>

                <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
                </p>
              </div>
              &nbsp;
              <div>
                <button-remove-item-table (removeItem)="eliminarArticulo()"></button-remove-item-table>

                <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
                </p>
              </div>
              &nbsp;
              <div>
                <button-insert-item-table (insertItem)="insertarArticulo()"></button-insert-item-table>
                <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Insertar</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mat-elevation-z0 overflow-x">

          <table mat-table [dataSource]="dataSourceArticulo" matSort matSort>

            <ng-container matColumnDef="prd_codprd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Producto">
                <select-search
                  [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
                  [label]="''" [placeholder]="'Ej. xxxxx'" [name]="'prd_codprd' + i" [value]="'prd_codprd'"
                  [description]="'description'" [data]="productos" [model]="row.prd_codprd">
                </select-search>
              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_detalle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input [(ngModel)]="row.rcd_detalle" [name]="'rcd_detalle' + i" matInput placeholder="Eg. Detalle">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="ume_codume">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '70px'}">
                  <mat-label></mat-label>
                  <input [(ngModel)]="row.ume_codume" [name]="'ume_codume' + i" matInput placeholder="Eg. 12">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_cansol">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '70px'}">
                  <mat-label></mat-label>
                  <input type="number" min="0" [ngStyle]="{textAlign: 'right'}" [(ngModel)]="row.rcd_cansol"
                    [name]="'rcd_cansol' + i" matInput placeholder="Eg. 12">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_preuni">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Prec. Unitario </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Prec. Unitario">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '70px'}">
                  <mat-label></mat-label>
                  <input type="number" min="0" [ngStyle]="{textAlign: 'right'}" [(ngModel)]="row.rcd_preuni"
                    [name]="'rcd_preuni' + i" matInput placeholder="Eg. 12">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_imptot">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Prec. Total </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Prec. Total">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '70px'}">
                  <mat-label></mat-label>
                  <input type="number" min="0" [ngStyle]="{textAlign: 'right'}" [(ngModel)]="row.rcd_imptot"
                    [name]="'rcd_imptot' + i" matInput placeholder="Eg. 12">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_motcom">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mot. Compra </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Mot. Compra">
                <select-search
                  [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
                  [label]="''" [placeholder]="'Ej. GONZALES'" [name]="'rcd_motcom' + i" [value]="'cli_codcli'"
                  [description]="'cli_nomcli'" [data]="[]" (cambioSelect)="row.rcd_motcom = $event"
                  [model]="row.rcd_motcom"></select-search>
              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_fecreq">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Requerida </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Fecha Requerida">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput [matDatepicker]="pickerArray[i]" [(ngModel)]="row.rcd_fecreq"
                    [name]="'rcd_fecreq' + i">
                  <mat-datepicker-toggle matSuffix [for]="pickerArray[i]"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_pesrcd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Peso">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '70px'}">
                  <mat-label></mat-label>
                  <input type="number" min="0" [(ngModel)]="row.rcd_pesrcd" [name]="'rcd_pesrcd' + i"
                    [ngStyle]="{textAlign: 'right'}" matInput placeholder="Eg. 12">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_codped">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. OT </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Nro. OT">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '70px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" [(ngModel)]="row.rcd_codped" [name]="'rcd_codped' + i"
                    matInput placeholder="Eg. 12">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="rcd_codcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Cliente">
                <select-search
                  [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
                  [label]="''" [placeholder]="'Ej. GONZALES'" [name]="'rcd_codcli' + i" [value]="'cli_codcli'"
                  [description]="'cli_nomcli'" [data]="clientes" (cambioSelect)="row.rcd_codcli = $event"
                  [model]="row.rcd_codcli"></select-search>
              </td>
            </ng-container>

            <ng-container matColumnDef="oc_cliente">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> OC. Cliente </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="OC. Cliente">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '70px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" [(ngModel)]="row.oc_cliente" [name]="'oc_cliente' + i"
                    matInput placeholder="Eg. 12">
                </mat-form-field>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsArticulo"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsArticulo;" [class.selected-row]="row === selected"
              (click)="selectRow(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="13" *ngIf="!loaderArticulo">No se encontraron registros</td>
              <td class="mat-cell" colspan="13" *ngIf="loaderArticulo">
                <mat-spinner diameter="40"></mat-spinner>
              </td>
            </tr>

          </table>

        </div>
        <mat-paginator #paginatorArticulo showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </mat-tab>
      <mat-tab label="Datos Complementarios" labelClass="mat-tab-label-0-2">
        <br>
        <div class="row">
          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>Fecha requerida por el usuario</mat-label>
              <input matInput [matDatepicker]="fecharequerida">
              <mat-datepicker-toggle matSuffix [for]="fecharequerida"></mat-datepicker-toggle>
              <mat-datepicker #fecharequerida></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>Fecha ofertada al cliente</mat-label>
              <input [(ngModel)]="solicitudCompra.rco_fecofe" name="rco_fecofe" matInput
                [matDatepicker]="fechaofertada">
              <mat-datepicker-toggle matSuffix [for]="fechaofertada"></mat-datepicker-toggle>
              <mat-datepicker #fechaofertada></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>Peso Total</mat-label>
              <input [(ngModel)]="solicitudCompra.rco_pestot" type="number" min="0" matInput placeholder="Peso Total"
                name="rco_pestot" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>Medidas</mat-label>
              <input [(ngModel)]="solicitudCompra.rco_meddes" type="number" min="0" matInput placeholder="Medidas"
                name="medidas" required>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>Motivo Compra</mat-label>
              <mat-select [(ngModel)]="solicitudCompra.rco_motcom" name="rco_motcom">
                <mat-option value="motivocompra">
                  motivo compra
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>N° de OT</mat-label>
              <input type="text" matInput placeholder="N° de OT" name="nro_ot" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl3">
            <select-search [label]="'Cliente'" [placeholder]="'Ej. JUAN RULFO'" [name]="'cli_codcli'"
              [description]="'cli_nomcli'" [data]="clientes">
            </select-search>
          </div>
          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>OC. Cliente</mat-label>
              <input type="text" matInput placeholder="OC. Cliente" name="oc_cliente" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>Valor mercadería a transportar</mat-label>
              <input [(ngModel)]="solicitudCompra.rco_valmer" type="number" min="0" matInput
                placeholder="Valor mercadería a transportar" name="rco_valmer" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl3">
            <mat-form-field>
              <mat-label>Material Transporte</mat-label>
              <input [(ngModel)]="solicitudCompra.rco_mattra" type="text" matInput placeholder="Material Transporte"
                name="rco_mattra" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl6">
            <mat-form-field class="example-full-width">
              <mat-label>Punto Partida</mat-label>
              <textarea [(ngModel)]="solicitudCompra.rco_punpar" name="rco_punpar" matInput
                placeholder="Punto Partida"></textarea>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l12 xl6">
            <mat-form-field class="example-full-width">
              <mat-label>Punto Llegada</mat-label>
              <textarea [(ngModel)]="solicitudCompra.rco_punlle" name="rco_punlle" matInput
                placeholder="Punto Llegada"></textarea>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l5">
            <button type="button" mat-fab color="primary">
              <mat-icon>description</mat-icon>
            </button>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>

  </div>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-compras/solicitudes-de-compra" (clickFormato)="generarFormatoPDFSolicitudCompra()"
    (clickNuevo)="nuevoRegistro()">
    <div id="botonesAdicionales">
      <button class="pav-btn-action" #btnGeneraAsiento color="primary" matTooltip="Copiar solicitud" type="button"
        (click)="copiarSolicitud()">
        <mat-icon class="pav-icon-btn">content_copy</mat-icon>
      </button>
      <button class="pav-btn-action" color="primary" matTooltip="Mover a orden de compra" type="button"
        (click)="moverAOrdenCompra()">
        <mat-icon class="pav-icon-btn">rebase_edit</mat-icon>
      </button>
      <button class="pav-btn-action" color="primary" matTooltip="Consultar stock mínimo" type="button"
        (click)="consultarStockMinimo()">
        <mat-icon class="pav-icon-btn">content_paste_search</mat-icon>
      </button>
    </div>
  </pav-form-actions>
</form>
