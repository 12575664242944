import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Subscription } from 'rxjs';
import { DocumentSerie } from 'src/app/models';
import { VentasService, AlmacenService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SerieDocumentoService } from 'src/app/services/api/ventas/maestros/serie-documento.service';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-serie-documento-form',
  templateUrl: './serie-documento-form.component.html',
  styleUrls: ['./serie-documento-form.component.css']
})
export class SerieDocumentoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;

  documentSerie: DocumentSerie;

  tiposDocumento: any[] = [];
  sucursales: any[] = [];
  lugares: any[] = [];

  tipoDocumentVenta$: Subscription;
  sucursal$: Subscription;
  loading$: Subscription;
  serieDocumento$: Subscription;
  lugarDespacho$: Subscription;

  loaderData: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;
  type: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService,
    private _serieService: SerieDocumentoService,
    private _almacenService: AlmacenService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private store: Store<PavsoState>
  ) {
    this.documentSerie = new DocumentSerie();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({type, id})=> {
      this.uid = id;
      this.type = type;
      this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE: this.buttonsName.BTN_UPDATE;

      this.loadMaestros();
    })
  }

  loadMaestros(): void {

    forkObs(
      this._ventasService.listarTipoDocumentosVenta(),
      this._almacenService.listarSucursales()
    ).then(data => {
      this.tiposDocumento = data[0];
      this.sucursales = data[1];

      if(this.uid != '0') {
        this.obtenerSerieDocumento();
        return;
      }
      this.loaderData = false;

    }).catch(err => {
      this._snackBarService.showError('Error al obtener maestros', 'OK');
    })

  }

  obtenerSerieDocumento(): void {
    this.serieDocumento$ = this._serieService.obtenerSerieDocumento(this.type, this.uid).subscribe(
      serieDocumento => {
        console.log('serie documento', serieDocumento);
        if(serieDocumento.length > 0) {

          this.documentSerie.tdo_codtdo = serieDocumento[0].tipo_documento;
          this.documentSerie.sdo_indsta = serieDocumento[0].estado;
          this.documentSerie.sdo_codsdo = serieDocumento[0].serie;
          this.documentSerie.sdo_coduse = serieDocumento[0].usuario_reg;
          this.documentSerie.sdo_fecupd = serieDocumento[0].fecha_reg;
          this.loaderData = false;

        }
      },
      error => {
        this._snackBarService.showError('Error alobtener serie de documento', 'Ok');
        this.loaderData = false;
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarSerieDocumento(f): this.actualizarSerieDocumento(f);
  }

  registrarSerieDocumento(f: NgForm): void {
    this._serieService.registrarSerieDocumento(this.documentSerie).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Serie documento registrado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarSerieDocumento(f: NgForm): void {
    this._serieService.actualizarSerieDocumento(this.documentSerie).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Serie documento actualizado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/series-de-documento']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoDocumentVenta$,
      this.sucursal$,
    ])
  }
}
