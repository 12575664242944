import { NgModule } from "@angular/core";
import { EtapaFormComponent } from "./etapa-form/etapa-form.component";
import { EtapaListComponent } from "./etapa-list/etapa-list.component";
import { EtapaDialog } from "./etapa-dialog/etapa-dialog.component";
import { EtapaSelect } from "./etapa-select/etapa-select.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from "@shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";

@NgModule({
  declarations: [EtapaFormComponent, EtapaListComponent, EtapaDialog, EtapaSelect],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    EtapaFormComponent, EtapaListComponent, EtapaDialog, EtapaSelect
  ]
})
export class EtapaModule {}
