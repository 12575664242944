  import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AlmacenService, ConfigurationService, ContabilidadService, NominaService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Afp, AfpDetaNaf } from 'src/app/models/planilla/maestros/afp';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { ApiNominaMaestrosAfpService } from 'src/app/services/api/nomina/maestros/nomina.maestros.afp.service';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-afp-form',
  templateUrl: './afp-form.component.html',
  styleUrls: ['./afp-form.component.css'],
})
export class AfpFormComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'action',
    'codnco',
    'porafp',
    'topafp',
    'codccn',
  ];

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  tiposDocumento: any[] = [];
  auxiliares: any[] = [];
  conceptos: any[] = [];
  cuentas: any[] = [];

  tipo$: Subscription;
  auxiliar$: Subscription;
  afp$: Subscription;
  concepto$: Subscription;
  planCuenta$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  afp:  Afp;
  year: string;
  month: string;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _almacenService: AlmacenService,
    private _contabilidadService: ContabilidadService,
    private _activatedRoute: ActivatedRoute,
    private _nominaService: NominaService,
    private _apiAfpNominaService: ApiNominaMaestrosAfpService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {
    this.afp = new Afp();

    this.period$ = this.store.select('period').subscribe((state) => {
      this.year = state.year;
      this.month = state.month;
      this.afp.codano = this.year;
      this.afp.codmes = this.month;
    });

    this.afp.codcia = this._configurationService.obtenerCompaniaCliente();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe((state) => {

      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();

    });
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      this.loadMaestros();
      if (id == '0') return;
    });
  }

  trackByAuxiliar(index, auxiliar): string {
    return auxiliar? auxiliar.CODIGO: undefined;
  }

  trackByConcepto(index, concepto): string {
    return concepto? concepto.codnco: undefined;
  }

  trackByCuenta(index, cuenta): string {
    return cuenta? cuenta.cuenta: undefined;
  }

  seleccionarConcepto(row): void {

    const concepto = this.conceptos.find(item => item.codnco == row.codnco);
    row.desnco = concepto.descri;

  }

  obtenerAfp(): void {
    this.afp$ = this._apiAfpNominaService.verAFP(this.year, this.month, this.uid).subscribe(
      ([afp]) => {
        this.afp = afp;
        this.afp.codano = this.year;
        this.afp.codmes = this.month;

        this.afp.AFP_DETA_NAF = afp.AFP_DETA_NAF;

        this.afp.AFP_DETA_NAF.map(item => {
          item['codccn'] = item.codccn.trim();
          item['isEditing'] = false;
        });

        this.dataSource = fillTable(this.afp.AFP_DETA_NAF, this.paginator, this.sort);

      },
      (error) => this._snackBarService.showError(error.error.msg, 'OK')

    );
  }

  agregarItem(): void {

    this.afp.AFP_DETA_NAF.push(new AfpDetaNaf());
    this.afp.AFP_DETA_NAF.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.afp.AFP_DETA_NAF, this.paginator, this.sort);

  }

  editarItem(row: AfpDetaNaf): void {
    row.porafp = Number(row.porafp).toFixed(2);
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row: AfpDetaNaf): void {
    row.porafp = Number(row.porafp);
    row.isEditing = !row.isEditing;
  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.afp.AFP_DETA_NAF.filter(item => item.correlativo != row.correlativo);
        this.afp.AFP_DETA_NAF = filtered;

        this.afp.AFP_DETA_NAF.forEach((element, key) => {

          element['correlativo'] = key;

        })
        this.dataSource = fillTable(this.afp.AFP_DETA_NAF, this.paginator, this.sort);
      }
    })
  }

  /**
   * Cargamos los maestros para el formulario:
   * . auxiliares
   * . conceptos dedución
   * . planes de cuenta
   */
  loadMaestros(): void {

    forkObs(
      this._almacenService.listarAuxiliares(),
      this._nominaService.listarConceptosDeducciones(this.year, this.month),
      this._contabilidadService.listarPlanDeCuentas()
    ).then(data => {
      this.auxiliares = data[0];
      this.conceptos = data[1];
      this.cuentas = data[2];

      if(this.uid != '0') {
        this.obtenerAfp()
        return;
      }
    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", 'Ok')
    })

  }

  blurPorcentaje(row): void {
    row.porafp = Number(row.porafp).toFixed(2)
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    console.log('afp', this.afp)

    if(!this.esValidoDetalle()) {
      this._snackBarService.showError('Ninguna fila debe de estar en modo edición', 'Ok')
      return;
    }

    this.loaderReg = true;
    this.afp.exconp ? this.afp.exconp = 1: this.afp.exconp = 0;

    this.uid == '0' ? this.registrarAFP(f) : this.actualizarAFP(f);
  }

  /**
   * Método para registrar un nuevo AFP
   * @param f
   */
  registrarAFP(f: NgForm): void {

    this.sendForm$ = this._apiAfpNominaService.registrarAFP(this.afp).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'AFP registrado', '400px', '400px', '');
        this.loaderReg = false;
        this.afp.AFP_DETA_NAF = [];
        f.resetForm();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para actualizar AFP
   * @param f
   */
  actualizarAFP(f: NgForm): void {

    this.sendForm$ = this._apiAfpNominaService.actualizarAFP(this.afp).subscribe(
      _ => {
        this._dialogService.openDialog(SuccessComponent, 'AFP actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  esValidoDetalle(): boolean {

    let isValid: boolean = true;

    this.afp.AFP_DETA_NAF.forEach(item => {
      if(item['isEditing']) isValid = false;
    })

    return isValid;
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/afps']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipo$,
      this.auxiliar$,
      this.period$,
      this.loading$,
      this.planCuenta$,
      this.concepto$,
      this.afp$,
      this.sendForm$
    ]);
  }
}
