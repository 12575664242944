<mat-sidenav-container class="example-container">
  <mat-sidenav #drawer mode="over" position="end"></mat-sidenav>
  <mat-sidenav-content class="pavso-container">
    <div class="pavso-content-form fade-in-image">
      <div class="row">
        <div class="col s12">
          <app-pavso-title-section [title]="modulo" [module]="title"></app-pavso-title-section>
        </div>
        <div class="col s12 m12 l12 xl4">
          <mat-card class="example-card">
            <mat-card-header>
              <mat-card-title class="title-card">Pedidos</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div>
                  <mat-progress-bar *ngIf="loaderPedidos" mode="indeterminate"></mat-progress-bar>
                  <mat-card>
                    <p>
                      <b>Pedidos en curso por atender</b>
                    </p>
                    <mat-divider></mat-divider>
                    <div class="pav-amount">
                      <span>
                        {{pedidosAtender}}
                      </span>
                    </div>
                  </mat-card>
                </div>
                <div>
                  <mat-progress-bar *ngIf="loaderPedidos" mode="indeterminate"></mat-progress-bar>
                  <mat-card>
                    <p>
                      <b>Pedidos por aprobar</b>
                    </p>
                    <mat-divider></mat-divider>
                    <div class="pav-amount">
                      <span>
                        {{pedidosAprobar}}
                      </span>
                    </div>
                  </mat-card>
                </div>
                <div>

                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-progress-bar *ngIf="loadGraficoVendedores" mode="indeterminate"></mat-progress-bar>
          <mat-card class="example-card">
            <div id="chartVendedores"></div>
          </mat-card>
        </div>

        <div class="col s12 m12 l12 xl4">
          <mat-card class="example-card">
            <mat-card-header>
              <mat-card-title>Ventas</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="">
                  <mat-card>
                    <p>
                      <b>Artículos vendidos</b>
                    </p>
                    <mat-divider></mat-divider>
                    <div class="pav-amount">{{cantidadVenta}}</div>
                  </mat-card>
                </div>
                <div class="">
                  <mat-card>
                    <p>
                      <b>Importe de venta</b>
                    </p>
                    <mat-divider></mat-divider>
                    <div class="pav-amount">{{importeVenta}}</div>
                  </mat-card>
                </div>
                <!-- <div class="">
                  <mat-card class="mat-elevation-z0">
                    <p>
                      <b>Devoluciones</b>
                    </p>
                    <mat-divider></mat-divider>
                    <div class="pav-amount">{{devolucion}}</div>
                  </mat-card>
                </div> -->
              </div>
            </mat-card-content>
          </mat-card>
          <mat-progress-bar *ngIf="loadGraficoVenta" mode="indeterminate"></mat-progress-bar>
          <mat-card class="example-card">
            <div id="chartVentas"></div>
          </mat-card>
        </div>

        <div class="col s12 m12 l12 xl4">
          <mat-card class="example-card">
            <mat-card-header>
              <mat-card-title>Comprobantes electrónicos</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="">
                  <mat-card>
                    <p>
                      <b>Comprobantes aceptados</b>
                    </p>
                    <mat-divider></mat-divider>
                    <div class="pav-amount">23</div>
                  </mat-card>
                </div>
                <div class="">
                  <mat-card>
                    <p>
                      <b>Comprobantes rechazados</b>
                    </p>
                    <mat-divider></mat-divider>
                    <div class="pav-amount">0</div>
                  </mat-card>
                </div>
                <!-- <div class="">
                  <mat-card class="mat-elevation-z0">
                    <p>
                      <b>Comprobantes por enviar</b>
                    </p>
                    <mat-divider></mat-divider>
                    <div class="pav-amount">0</div>
                  </mat-card>
                </div> -->
              </div>
            </mat-card-content>

          </mat-card>
          <mat-progress-bar *ngIf="loadGraficoComprobante" mode="indeterminate"></mat-progress-bar>
          <mat-card class="example-card">
            <div id="chartComprobantes"></div>
          </mat-card>
        </div>

      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
