import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { formatDateWithSlash } from "@utils/formats/date.format";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/services/configuration.service";
import { IParam, generarParams } from "../../utilities/params";

@Injectable({
  providedIn: 'root'
})
export class OrdenCompraService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}
  /**
  *
  * @returns
  */
  obtenerOrdenesCompra({params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ordencompra/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    ).pipe(
      map((ordenes: any) => {
        return ordenes.map(orden => {
          orden['description'] = `${orden.ocm_numocm} | ${formatDateWithSlash(orden.ocm_fecocm)} | ${orden.cliente.cli_nomcli}`
          return orden;
        })
      })
    )
  }

  obtenerOrdenesCompraConPaginacion(page: number = 1, count: number = 5, orden: string = 'ASC', columnaorden: string = 'ocm_numocm', buscar: string = ''): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ordencompra/listarpagina/${this._configurationService.obtenerCompaniaCliente()}/${page}/${count}?buscar=${buscar}&orden=${orden}&columnaorden=${columnaorden}`
    )
  }

  obtenerOrdenesCompraPorCliente(cliente: string = ''): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ordencompra/listar/${this._configurationService.obtenerCompaniaCliente()}?cliente=${cliente}`
    ).pipe(
      map((ordenes: any) => {
        return ordenes.map(orden => {
          orden['description'] = `${orden.ocm_numocm} | ${formatDateWithSlash(orden.ocm_fecocm)}`
          return orden;
        })
      })
    )
  }

  obtenerOrdenesCompraGenerico(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ordencompra/listar/${this._configurationService.obtenerCompaniaCliente()}?generico=true`
    ).pipe(
      map((ordenes: any) => {
        return ordenes.map(orden => {
          orden['description'] = `${orden.ocm_numocm} | ${formatDateWithSlash(orden.ocm_fecocm)} | ${orden.cliente.cli_nomcli}`
          return orden;
        })
      })
    )
  }

  /**
   *
   * @param code
   * @returns
   */
  obtenerOrdenCompra(code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ordencompra/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${code}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  registrarOrdenCompra(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ordencompra/crear`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarOrdenCompra(data): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ordencompra/modificar`,
      data
    );
  }

  aprobarOrdenesCompra(data: Array<any>): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ordencompra/aprobacion`,
      data
    );
  }
}
