<h1 mat-dialog-title>Completar Numero factura</h1>
<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

<div class="row">
  <div class="col s12 m12 l4">
    <select-search [label]="'Tipo Doc.'" [placeholder]="'Ej. FAC'" [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'"
      [disabled]="true" [description]="'description'" [data]="tiposDocumento" [required]="true"
      (cambioSelect)="registro.tdo_codtdo = $event; seleccionarTipoDoc()" [model]="registro.tdo_codtdo">
    </select-search>

  </div>

  <div class="col s12 m12 l4">
    <select-search [label]="'Serie'" [placeholder]="'Ej. 0001'" [name]="'sdo_codsdo'" [value]="'sdo_codsdo'"
      [disabled]="loaderData" [description]="'sdo_codsdo'" [data]="series"
      [required]="true" (cambioSelect)="registro.sad_codsad = $event; seleccionarSerie()"
      [model]="registro.sad_codsad">
    </select-search>
  </div>

  <div class="col s12 m12 l4">
    <mat-form-field>
      <mat-label>Nro. Doc.</mat-label>
      <input #iNroDoc type="text" maxlength="12" matInput placeholder="Nro. Doc." required
        [(ngModel)]="registro.cco_numdoc" name="cco_numdoc" [disabled]="loaderData" (keyup.tab)="autocompletarNroDoc()"
        (keyup.enter)="autocompletarNroDoc()">
    </mat-form-field>
  </div>
</div>

<div>
  <button mat-flat-button color="primary" (click)="aceptar()">Aceptar</button>

</div>
