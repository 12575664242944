import { Component } from "@angular/core";

@Component({
    selector: 'shortcut-dialog',
    templateUrl: 'shortcuts.component.html',
    styleUrls: ['shortcuts.component.scss']
})
export class ShortcutsDialog{

    constructor() {}

}
