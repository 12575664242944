import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services'
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from 'src/app/utils/formats/date.format';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { REPORTES_ACTIVO_FIJO } from '@data/json/reportes/index';
import { ActivoFijoReportService } from 'src/app/services/reports/modules/activo-fijo-report.service';
import { HeaderReport } from '@data/interfaces/headers.report';
import { _USER_RUNTIME_CHECKS } from '@ngrx/store/src/tokens';

@Component({
  selector: 'app-activo-fijo-report',
  templateUrl: './activo-fijo-report.component.html',
  styleUrls: ['./activo-fijo-report.component.css']
})
export class ActivoFijoReportComponent implements OnInit, OnDestroy {

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  columnas$: Subscription;
  detalleVenta$: Subscription;

  year: string;
  month: string;

  reporteSeleccionado: any = [REPORTES_ACTIVO_FIJO[0].id];

  reportes: any[] = REPORTES_ACTIVO_FIJO;
  loading: boolean;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>,
    private _activoFijoReportService: ActivoFijoReportService
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.columnas$,
      this.detalleVenta$,
      this.period$
    ])
  }

  ngOnInit(): void {
  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;

    let indicador = "";
    switch (this.reporteSeleccionado[0]) {
      case "ACTIVO_FIJO_H":
        indicador = "51002"
        break;
      case "ACTIVO_FIJO_HC":
        indicador = "51002"
        break;
      case "ACTIVO_FIJO_U":
        indicador = "51001"
        break;
      case "ACTIVO_FIJO_DMAF":
        indicador = "51001"
        break;
      case "ACTIVO_FIJO_DMA":
        indicador = "51001"
        break;
      case "ACTIVO_FIJO_RMC":
        indicador = "51001"
        break;
      case "ACTIVO_FIJO_RMCL":
        indicador = "51001";
        break;
      case "ACTIVO_FIJO_RMCG":
        indicador = "51001"
        break;
      case "ACTIVO_FIJO_DMA":
        indicador = "51001"
        break;
      case "ACTIVO_FIJO_RMD":
        indicador = "51001"
        break;
      default:
        indicador = "";
        break;
    }
  }

  verReporte(): void {
    this.loading = true;

    const headers: HeaderReport = {
      year: this.year,
      month: this.month,
      clientName: this._configurationService.obtenerNombreCompaniaCliente(),
      client: this._configurationService.obtenerClienteId(),
      date: formatDateWithSlash(new Date()),
      fecini: '',
      fecter: '',
      time: getHourMinuteSecondsCurrent(new Date()),
      currency: '',
      username: this._configurationService.obtenerNombreCompaniaCliente(),
      codePage: this._configurationService.obtenerCodigoPagina()
    }

    this._activoFijoReportService.verReporte(this.reporteSeleccionado[0], headers)
    .then(_ => {
      this.loading = false;
    })
    .catch(_ => {
      this.loading = false;
    })
    .finally(() => {
      this.loading = false;
    })
  }

}
