<app-pavso-title-section [title]="'Firma digital'" [module]="'Planilla'" [estado]="firmaDigital.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m12 l9">
          <mat-form-field>
            <mat-label>Colaborador</mat-label>
            <mat-select [(ngModel)]="firmaDigital.codcli" required name="codcli">
              <mat-option *ngFor="let colaborador of colaboradores; trackBy: trackByColaborador"
                [value]="colaborador.codcli">
                {{colaborador.nomcli}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l9">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="firmaDigital.descri" matInput placeholder="Código" name="descri" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Correo</mat-label>
            <input type="email" [(ngModel)]="firmaDigital.email" matInput placeholder="Correo" name="email" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Teléfono</mat-label>
            <input type="tel" [(ngModel)]="firmaDigital.telcgf" matInput placeholder="Télefono" name="telcgf" required>
          </mat-form-field>
        </div>
        <!-- <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Otro</mat-label>
                      <input type="text" [(ngModel)]="firmaDigital.faxcgf" matInput placeholder="Otro" name="faxcgf" required [disabled]="loaderData">
                  </mat-form-field>
              </div> -->
      </div>
    </mat-card>
    <br>
    <div class="pav-filtro-header green">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Mis imágenes</div>
    </div>
    <div class="pav-filtro-container">
      <img *ngIf="firmaDigital.urlima" [src]="firmaDigital.urlima" alt="" width="200px">
    </div>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12">
          <ngx-dropzone class="custom-dropzone" [maxFileSize]="80000" [accept]="'image/*'" (change)="onSelect($event)">
            <ngx-dropzone-label>
              <div>
                <h2>Selecciona Firma (formatos permitidos JPEG o JPG) o PDF!</h2>
                <h3>Tamaño max. 80 KB, Dimensión: 850 x 300</h3>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
              [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
            <mat-spinner diameter="40" *ngIf="loadUpload"></mat-spinner>

          </ngx-dropzone>

        </div>
      </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-planilla/firmas-digitales">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
