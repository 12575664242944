import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { TipoActivoFijo } from 'src/app/models/activo-fijo/tipo_activo_fijo';
import {
  AuthenticationService,
  ContabilidadService,
} from 'src/app/services';
import { TipoActivoService } from 'src/app/services/api/activo-fijo/maestros/tipo-activo.service';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';

@Component({
  selector: 'app-tipo-activo-form',
  templateUrl: './tipo-activo-form.component.html',
  styleUrls: ['./tipo-activo-form.component.css'],
})
export class TipoActivoFormComponent implements OnInit, OnDestroy {
  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  tipoActivo: TipoActivoFijo;

  tipoActivo$: Subscription;
  cuenta$: Subscription;
  loading$: Subscription;

  cuentas: any[] = [];

  uid: string;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _tipoActivoFijo: TipoActivoService,
    private _snackBarService: SnackBarService,
    private _contabilidadService: ContabilidadService,
    private store: Store<PavsoState>
  ) {
    this.tipoActivo = new TipoActivoFijo();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      this.loadMaestros();
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;
      if (id == '0') return;
      this.obtenerTipoActivo();
    });
  }

  obtenerTipoActivo(): void {
    this.tipoActivo$ = this._tipoActivoFijo.obtenerTipoActivo(this.uid).subscribe(
      ([tipo]) => {
        this.tipoActivo = tipo;
      },
      (error) =>
        this._snackBarService.showError(
          'Error al obtener lista de tipos de activo',
          'OK'
        )
    );
  }

  /**
   * Obtiene maestros de :
   * . planes de cuenta
   */
  loadMaestros(): void {

    this.cuenta$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
      (cuentas) => (this.cuentas = cuentas),
      (error) =>
        this._snackBarService.showError(error.error.msg, 'OK')
    );
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0'
      ? this.registrarTipoActivo(f)
      : this.actualizarTipoActivo(f);
  }

  registrarTipoActivo(f: NgForm): void {}

  actualizarTipoActivo(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-activo-fijo/tipo-de-activo']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoActivo$, this.cuenta$, this.loading$]);
  }
}
