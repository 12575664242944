import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "producto-dialog",
  template: `
    <div mat-dialog-content>
      <producto-form [id]="data.id"></producto-form>
    </div>
  `,
  styleUrls: ["producto-dialog.component.scss"],
})
export class ProductoDialog {
  constructor(
    public dialogRef: MatDialogRef<ProductoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
