import { PaisCreateComponent } from './pais/pais-create/pais-create.component';
import { PaisListComponent } from './pais/pais-list/pais-list.component';
import { SunatTreintaCreateComponent } from './sunat-treinta/sunat-treinta-create/sunat-treinta-create.component';
import { SunatTreintaListComponent } from './sunat-treinta/sunat-treinta-list/sunat-treinta-list.component';
import { SunatTreintacuatroCreateComponent } from './sunat-treintacuatro/sunat-treintacuatro-create/sunat-treintacuatro-create.component';
import { SunatTreintacuatroListComponent } from './sunat-treintacuatro/sunat-treintacuatro-list/sunat-treintacuatro-list.component';
import { SunatTreintaunoCreateComponent } from './sunat-treintauno/sunat-treintauno-create/sunat-treintauno-create.component';
import { SunatTreintaunoListComponent } from './sunat-treintauno/sunat-treintauno-list/sunat-treintauno-list.component';
import { UbigeoCreateComponent } from './ubigeos/ubigeo-create/ubigeo-create.component';
import { UbigeoListComponent } from './ubigeos/ubigeo-list/ubigeo-list.component';
export const contableComponents = [
  PaisCreateComponent,
  PaisListComponent,
  SunatTreintaCreateComponent,
  SunatTreintaListComponent,
  SunatTreintacuatroCreateComponent,
  SunatTreintacuatroListComponent,
  SunatTreintaunoCreateComponent,
  SunatTreintaunoListComponent,
  UbigeoCreateComponent,
  UbigeoListComponent
]
