<app-pavso-title-section [title]="'Tarjeta de crédito'" [module]="'Ventas'" [usuario]="tarjeta.tcr_coduse" [fecha]="tarjeta.tcr_fecupd" [estado]="tarjeta.tcr_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="tarjeta.tcr_codtcr" matInput placeholder="Código" name="tcr_codtcr" required
              >
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="tarjeta.tcr_descri" matInput placeholder="Descripción" name="tcr_descri" required
              >
          </mat-form-field>
        </div>

      </div>

    </mat-card>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-ventas/tarjetas-de-credito">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
