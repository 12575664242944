import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  menuClient: any = [
    {
      title: 'Inicio',
      icon: 'home',
      url: '/ingresar-parametros',
      submenus: [],
      isExpanded: true,
      showSubmenu: false,
      isShowing: false,
    },
    {
      title: 'Mi Aplicación',
      icon: 'app',
      url: '/dashboard',
      submenus: [
        {
          title: 'Dashboard',
          icon: 'dashboard',
          url: 'dashboard',
          submenus: [],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '00',
        },
        {
          title: 'Compras',
          icon: 'crop_square',
          url: 'modulo-compras',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Aut. de aprob. compras',
                  icon: 'list_alt',
                  url: 'modulo-compras/autorizados-de-aprobar-compras',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
                {
                  title: 'Aut. de aprob. solic. de compra',
                  icon: 'list_alt',
                  url: 'modulo-compras/autorizados-de-aprobar-solicitudes-de-compra',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
                {
                  title: 'Cond. de pago',
                  icon: 'list_alt',
                  url: 'modulo-compras/condiciones-de-pago',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
                {
                  title: 'Lug. de despacho',
                  icon: 'list_alt',
                  url: 'modulo-compras/lugares-de-despacho',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
                {
                  title: 'Proveedores',
                  icon: 'list_alt',
                  url: 'modulo-compras/proveedores',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
                {
                  title: 'Tipos de servicio',
                  icon: 'list_alt',
                  url: 'modulo-compras/tipos-de-servicio',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '1A',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Aprobaciones',
                  icon: 'post_add',
                  submenus: [
                    {
                      title: 'Aprob. Solicitud de Compra',
                      icon: 'post_add',
                      url: 'modulo-compras/aprobacion-solicitud-de-compra',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '1A',
                    },
                    {
                      title: 'Aprob. Ordenes de Compra',
                      icon: 'post_add',
                      url: 'modulo-compras/aprobacion-ordenes-de-compra',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '1A',
                    },
                  ],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
                {
                  title: 'Orden de compra',
                  icon: 'post_add',
                  url: 'modulo-compras/ordenes-de-compra',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
                {
                  title: 'Registro de compra',
                  icon: 'post_add',
                  url: 'modulo-compras/registros-de-compra',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
                {
                  title: 'Solicitud de Compra',
                  icon: 'post_add',
                  url: 'modulo-compras/solicitudes-de-compra',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '1A',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '1A',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Compras',
                  icon: 'summarize',
                  url: 'modulo-compras/imforme-de-compras',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },

              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '1A',
            },
            {
              title: 'Carpeta',
              icon: 'dns',
              submenus: [
                {
                  title: 'Lead Time',
                  icon: 'summarize',
                  url: 'modulo-compras/lead-time',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '1A',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '1A',
        },
        {
          title: 'Importaciones',
          icon: 'crop_square',
          url: 'modulo-importaciones',
          submenus: [
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Órdenes de Importación',
                  icon: 'post_add',
                  url: 'modulo-importaciones/ordenes-de-importacion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '11',
                },
                {
                  title: 'Registro Invoice',
                  icon: 'post_add',
                  url: 'modulo-importaciones/invoices',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '11',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '11',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '11',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Importaciones',
                  icon: 'summarize',
                  url: 'modulo-importaciones/informe-de-importaciones',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '11',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '11',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '11',
        },
        {
          title: 'Almacen',
          icon: 'crop_square',
          url: 'modulo-almacen',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Almacen',
                  icon: 'list_alt',
                  url: 'modulo-almacen/almacen',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
                {
                  title: 'Config. Enlace Mot. Almacén',
                  icon: 'list_alt',
                  url: 'modulo-almacen/configurar-enlace-motivos-almacen',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
                {
                  title: 'Motivos',
                  icon: 'list_alt',
                  url: 'modulo-almacen/motivos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '16',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Guia Remisión Texto',
                  icon: 'post_add',
                  url: 'modulo-almacen/listar-guia-de-remision-texto',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
                {
                  title: 'Inventario Físico',
                  icon: 'post_add',
                  url: 'modulo-almacen/inventario-fisico',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
                {
                  title: 'Movimientos',
                  icon: 'post_add',
                  url: 'modulo-almacen/movimientos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
                {
                  title: 'Parte de ingreso',
                  icon: 'post_add',
                  url: 'modulo-almacen/parte-de-ingreso',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
                {
                  title: 'Parte de salida',
                  icon: 'post_add',
                  url: 'modulo-almacen/parte-de-salida',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '16',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [
                {
                  title: 'Val. de Inventario',
                  icon: 'dns',
                  url: 'modulo-almacen/valorizacion-de-inventario',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '16',
            },
            {
              title: 'Consultas',
              icon: 'fact_check',
              submenus: [
                {
                  title: 'Consultar Stock',
                  icon: 'fact_check',
                  url: 'modulo-almacen/consultar-stock',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '16',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Almacen',
                  icon: 'summarize',
                  url: 'modulo-almacen/informe-movimientos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '16',
                },

              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '16',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '16',
        },
        {
          title: 'Operaciones',
          icon: 'crop_square',
          url: 'modulo-operaciones',
          submenus: [
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Aprob. Req. de materiales',
                  icon: 'post_add',
                  url: 'modulo-operaciones/aprobacion-de-requerimiento-de-materiales',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '23',
                },
                {
                  title: 'Req. de materiales',
                  icon: 'post_add',
                  url: 'modulo-operaciones/requerimientos-de-materiales',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '23',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '23',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '23',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Operaciones',
                  icon: 'summarize',
                  url: 'modulo-operaciones/informe-de-operaciones',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '23',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '23',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '23',
        },
        {
          title: 'Ventas',
          icon: 'crop_square',
          url: 'modulo-ventas',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Choferes',
                  icon: 'list_alt',
                  url: 'modulo-ventas/choferes',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Clientes',
                  icon: 'list_alt',
                  url: 'modulo-ventas/clientes',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Lista de precio',
                  icon: 'list_alt',
                  url: 'modulo-ventas/lista-de-precios',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Productos',
                  icon: 'list_alt',
                  url: 'modulo-ventas/productos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Series de documento',
                  icon: 'list_alt',
                  url: 'modulo-ventas/series-de-documento',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Tipos de cambio',
                  icon: 'list_alt',
                  url: 'modulo-ventas/tipos-de-cambio',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Unid. de Transporte',
                  icon: 'list_alt',
                  url: 'modulo-ventas/unidades-de-transporte',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Vendedores',
                  icon: 'list_alt',
                  url: 'modulo-ventas/vendedores',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '32',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Comprobantes',
                  icon: 'post_add',
                  url: 'modulo-ventas/comprobantes',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Comprobantes Básicos',
                  icon: 'post_add',
                  url: 'modulo-ventas/comprobantes-basicos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Generar Factura',
                  icon: 'post_add',
                  url: 'modulo-ventas/generar-factura',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Monitoreo de Factura',
                  icon: 'post_add',
                  url: 'modulo-ventas/monitoreo-de-factura-electronica',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
                {
                  title: 'Pedidos',
                  icon: 'post_add',
                  url: 'modulo-ventas/pedidos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '32',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '32',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Ventas',
                  icon: 'summarize',
                  url: 'modulo-ventas/informe-detallado-de-ventas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '32',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '32',
            },
            {
              title: 'Consultas',
              icon: 'fact_check',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '32',
            },
            {
              title: 'Cierre',
              icon: 'event_busy',
              url: '/cierre-de-ventas',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '32',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '32',
        },
        {
          title: 'Punto de Venta',
          icon: 'crop_square',
          url: 'modulo-punto-de-venta',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '35',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '35',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '35',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Punto de venta',
                  icon: 'summarize',
                  url: 'modulo-punto-de-venta/informe-de-punto-de-venta',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '35',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '35',
            },
            {
              title: 'Consultas',
              icon: 'fact_check',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '35',
            },
            {
              title: 'Cierre',
              icon: 'event_busy',
              url: '/cierre-de-ventas',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '35',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '35',
        },
        {
          title: 'Caja y bancos',
          icon: 'crop_square',
          url: 'modulo-cajabanco',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Cuentas de caja y bancos',
                  icon: 'list_alt',
                  url: 'modulo-cajabanco/cuentas-de-caja-y-bancos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
                {
                  title: 'Flujos de caja',
                  icon: 'list_alt',
                  url: 'modulo-cajabanco/flujos-de-caja',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
                {
                  title: 'Grupos Flujo de caja',
                  icon: 'list_alt',
                  url: 'modulo-cajabanco/grupos-flujo-de-caja',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '42',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Aprob. requerimiento de pago',
                  icon: 'post_add',
                  url: 'modulo-cajabanco/aprobacion-requerimiento-de-pago',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },

                {
                  title: 'Egresos Banco',
                  icon: 'post_add',
                  url: 'modulo-cajabanco/egresos-banco',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
                {
                  title: 'Ingresos Banco',
                  icon: 'post_add',
                  url: 'modulo-cajabanco/ingresos-banco',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
                {
                  title: 'Requerimiento de pago',
                  icon: 'post_add',
                  url: 'modulo-cajabanco/requerimiento-de-pago',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '42',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [
                {
                  title: 'Conciliación Bancaria',
                  icon: 'post_add',
                  url: 'modulo-cajabanco/conciliacion-bancaria',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '42',
            },
            {
              title: 'Consultas',
              icon: 'dns',
              submenus: [
                {
                  title: 'Cuentas por pagar',
                  icon: 'post_add',
                  url: 'modulo-cajabanco/cuentas-por-pagar',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '42',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Caja y Bancos',
                  icon: 'summarize',
                  url: 'modulo-cajabanco/informe-de-caja-y-bancos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '42',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '42',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '42',
        },
        {
          title: 'Cobranzas',
          icon: 'crop_square',
          url: 'modulo-cobranzas',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Cobradores',
                  icon: 'list_alt',
                  url: 'modulo-cobranzas/cobradores',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
                {
                  title: 'Configura cobranzas',
                  icon: 'list_alt',
                  url: 'modulo-cobranzas/configura-documento-cobranzas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
                {
                  title: 'Líneas de Documentos',
                  icon: 'list_alt',
                  url: 'modulo-cobranzas/lineas-de-documentos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
                {
                  title: 'Situaciones de Documento',
                  icon: 'list_alt',
                  url: 'modulo-cobranzas/situaciones-de-documento',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '43',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Canje por letra',
                  icon: 'post_add',
                  url: 'modulo-cobranzas/canje-por-letra',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
                {
                  title: 'Planilla de cobranzas',
                  icon: 'post_add',
                  url: 'modulo-cobranzas/planilla-de-cobranzas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
                {
                  title: 'Planilla Letras Banco',
                  icon: 'post_add',
                  url: 'modulo-cobranzas/planilla-letras-banco',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
                {
                  title: 'Seguimiento al Cliente',
                  icon: 'post_add',
                  url: 'modulo-cobranzas/seguimiento-al-cliente',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '43',
            },
            {
              title: 'Consultas',
              icon: 'fact_check',
              submenus: [
                {
                  title: 'Consulta Ctas. Cobrar',
                  icon: 'fact_check',
                  url: 'modulo-cobranzas/consulta-cuentas-por-cobrar',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
                {
                  title: 'Consulta Situación Documento',
                  icon: 'fact_check',
                  url: 'modulo-cobranzas/consulta-situacion-documento',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '43',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Cobranzas',
                  icon: 'summarize',
                  url: 'modulo-cobranzas/informe-de-cobranzas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '43',
                },

              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '43',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '43',
        },
        {
          title: 'Contabilidad',
          icon: 'crop_square',
          url: 'modulo-contabilidad',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Auxiliares',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/auxiliares',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Bancos',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/bancos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Centros de Costo',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/centros-de-costo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Categorías Centros de Costo',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/categorias-de-centro-de-costo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Configuración Asiento Cierre',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/configuraciones-asiento',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Configuracion Tipo Documento',
                  icon: 'list_alt',
                  submenus: [
                    {
                      title: 'Config. Doc. con Control Cta. Cte.',
                      icon: 'list_alt',
                      url: 'modulo-contabilidad/configuraciones-control-cta-cte',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'Config. por Tipo Documento',
                      icon: 'list_alt',
                      url: 'modulo-contabilidad/configuraciones-por-tipo-documento',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'Tipos de Documento',
                      icon: 'list_alt',
                      url: 'modulo-contabilidad/tipos-de-documento',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                  ],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Plan de cuentas',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/plan-de-cuentas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Sub. contables',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/subdiarios-contable',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Tablas de Apoyo',
                  icon: 'crop_square',
                  submenus: [
                    {
                      title: 'Paises',
                      icon: 'summarize',
                      url: 'modulo-contabilidad/paises',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'Sunat 30',
                      icon: 'summarize',
                      url: 'modulo-contabilidad/sunats-30',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'Sunat 31',
                      icon: 'summarize',
                      url: 'modulo-contabilidad/sunats-31',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'Sunat 34',
                      icon: 'summarize',
                      url: 'modulo-contabilidad/sunats-34',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'Ubigeos',
                      icon: 'summarize',
                      url: 'modulo-contabilidad/ubigeos',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                  ],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Tipo Cambio Importación',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/tipos-de-cambio-de-importacion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Tipos de Impuesto',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/tipos-de-impuesto',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Tipo de Operación',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/tipos-de-operacion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Tipos Estados Financieros',
                  icon: 'list_alt',
                  url: 'modulo-contabilidad/tipos-estados-financieros',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '41',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Asientos Contables',
                  icon: 'post_add',
                  url: 'modulo-contabilidad/asientos-contables',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Registro de Ventas',
                  icon: 'post_add',
                  url: 'modulo-contabilidad/registros-de-venta',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Registro de compra',
                  icon: 'post_add',
                  url: 'modulo-contabilidad/registros-de-compra',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '1A',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '41',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [
                {
                  title: 'General',
                  icon: 'dns',
                  url: 'modulo-contabilidad/procesos-de-contabilidad',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Interface',
                  icon: 'dns',
                  submenus: [
                    {
                      title: 'DAOT',
                      icon: 'dns',
                      url: 'modulo-contabilidad/interface-daot-contabilidad',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'PDT Honorarios',
                      icon: 'dns',
                      url: 'modulo-contabilidad/interface-pdt',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'PDT Percepciones',
                      icon: 'dns',
                      url: 'modulo-contabilidad/pdt-percepciones',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'PDT Retenciones',
                      icon: 'dns',
                      url: 'modulo-contabilidad/pdt-retenciones',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'Planilla PLAME',
                      icon: 'dns',
                      url: 'modulo-contabilidad/interface-planilla-plame',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                    {
                      title: 'PLE 5.0',
                      icon: 'dns',
                      url: 'modulo-contabilidad/interface-ple',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '41',
                    },
                  ],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Conciliación Bancaria',
                  icon: 'dns',
                  url: 'modulo-contabilidad/conciliacion-bancaria-contabilidad',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '41',
            },
            {
              title: 'Consultas',
              icon: 'fact_check',
              submenus: [
                {
                  title: 'Saldos Mensuales',
                  icon: 'fact_check',
                  url: 'modulo-contabilidad/consultas-de-saldos-mensuales',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Movimientos Contables',
                  icon: 'fact_check',
                  url: 'modulo-contabilidad/consultas-de-movimientos-contables',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '41',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'PDFs',
                  icon: 'summarize',
                  url: 'modulo-contabilidad/reporte-pdf-contabilidad',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Dinámicos',
                  icon: 'summarize',
                  url: 'modulo-contabilidad/reporte-dinamico-contabilidad',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '41',
            },
            {
              title: 'Cierre',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Genera Asiento',
                  icon: 'summarize',
                  url: '/genera-asiento-contabilidad',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Tipo de Cambio de Cierre',
                  icon: 'summarize',
                  url: '/tipo-de-cambio-cierre',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
                {
                  title: 'Control de Cierre Contable',
                  icon: 'summarize',
                  url: '/control-de-cierre-contable',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '41',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '41',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '41',
        },
        {
          title: 'Planilla',
          icon: 'crop_square',
          url: 'modulo-planilla',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'AFP',
                  icon: 'list_alt',
                  url: 'modulo-planilla/afps',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Cargos',
                  icon: 'list_alt',
                  url: 'modulo-planilla/cargos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Colaboradores',
                  icon: 'list_alt',
                  url: 'modulo-planilla/trabajadores',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Configura Adelanto',
                  icon: 'list_alt',
                  url: 'modulo-planilla/configura-adelanto',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Conceptos Remunerativos',
                  icon: 'list_alt',
                  url: 'modulo-planilla/concepto-remunerativo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Configuración de Remuneración',
                  icon: 'list_alt',
                  url: 'modulo-planilla/configuracion-remuneracion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Configuración de Deducción',
                  icon: 'list_alt',
                  url: 'modulo-planilla/configuracion-deduccion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Config. Contratos',
                  icon: 'list_alt',
                  url: 'modulo-planilla/configuraciones-de-contrato',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Contratos',
                  icon: 'list_alt',
                  url: 'modulo-planilla/contratos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Firmas Digitales',
                  icon: 'list_alt',
                  url: 'modulo-planilla/firmas-digitales',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Lugares de Trabajo',
                  icon: 'list_alt',
                  url: 'modulo-planilla/lugares-de-trabajo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Rentas de quinta',
                  icon: 'list_alt',
                  url: 'modulo-planilla/rentas-de-quinta',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Tipos de Documento Legajo',
                  icon: 'list_alt',
                  url: 'modulo-planilla/tipos-de-documento-legajo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '51',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Cuenta Corriente / Préstamos',
                  icon: 'post_add',
                  url: 'modulo-planilla/cuenta-corriente-prestamos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Edita Deducciones',
                  icon: 'post_add',
                  url: 'modulo-planilla/edita-deducciones',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Liquid. Beneficios Soc.',
                  icon: 'post_add',
                  url: 'modulo-planilla/liquidaciones-de-beneficios-sociales',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Mov. de trabajadores',
                  icon: 'post_add',
                  url: 'modulo-planilla/movimiento-de-trabajadores',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Mov. Por Nómina',
                  icon: 'post_add',
                  url: 'modulo-planilla/movimientos-por-nomina',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Período de Nómina',
                  icon: 'post_add',
                  url: 'modulo-planilla/listado-de-periodos-de-nomina',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Remuneraciones fijas',
                  icon: 'post_add',
                  url: 'modulo-planilla/remuneraciones-fijas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Movimiento por Concepto',
                  icon: 'post_add',
                  url: 'modulo-planilla/movimientos-por-concepto',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Remuneración Mensual',
                  icon: 'post_add',
                  url: 'modulo-planilla/remuneracion-mensual',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Utilidades',
                  icon: 'post_add',
                  submenus: [
                    {
                      title: 'Participación de Utilidades',
                      icon: 'post_add',
                      url: 'modulo-planilla/participacion-de-utilidades',
                      submenus: [],
                      isExpanded: true,
                      showSubmenu: false,
                      isShowing: false,
                      codeMod: '51',
                    },
                  ],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '51',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Composición',
                  icon: 'summarize',
                  url: 'modulo-planilla/informes-de-composicion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Dependientes',
                  icon: 'summarize',
                  url: 'modulo-planilla/informes-dependientes',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Gestión',
                  icon: 'summarize',
                  url: 'modulo-planilla/informes-de-gestion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Mensuales',
                  icon: 'summarize',
                  url: 'modulo-planilla/informes-mensuales',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Nómina',
                  icon: 'summarize',
                  url: 'modulo-planilla/informes-de-nomina',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Remuneración',
                  icon: 'summarize',
                  url: 'modulo-planilla/informes-de-remuneracion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Interface Contable',
                  icon: 'summarize',
                  url: 'modulo-planilla/informes-de-interface-contable',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Dinámicos',
                  icon: 'summarize',
                  url: 'modulo-planilla/informe-dinamico-planilla',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '51',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [
                {
                  title: 'Interface PLAME',
                  icon: 'dns',
                  url: 'modulo-planilla/interface-plame',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '51',
            },
            {
              title: 'CTS',
              icon: 'post_add',
              submenus: [
                // {
                //   title: 'Período de Cálculo',
                //   icon: 'post_add',
                //   url: 'modulo-planilla/listado-de-periodo-de-calculo',
                //   submenus: [],
                //   isExpanded: true,
                //   showSubmenu: false,
                //   isShowing: false,
                //   codeMod: '51',
                // },
                {
                  title: 'Calcula de CTS',
                  icon: 'post_add',
                  url: 'modulo-planilla/listado-periodo-cts',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Intereses de Banco CTS',
                  icon: 'post_add',
                  url: 'modulo-planilla/intereses-de-banco-cts',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Liquidación de CTS',
                  icon: 'post_add',
                  url: 'modulo-planilla/listado-liquidacion-de-cts',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
                {
                  title: 'Reportes de CTS',
                  icon: 'post_add',
                  url: 'modulo-planilla/reportes-de-cts',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '51',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '51',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '51',
        },
        {
          title: 'Activo Fijo',
          icon: 'crop_square',
          url: 'modulo-activo-fijo',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Activo Fijo',
                  icon: 'list_alt',
                  url: 'modulo-activo-fijo/activos-fijos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '45',
                },
                {
                  title: 'Grupos de Activo',
                  icon: 'list_alt',
                  url: 'modulo-activo-fijo/grupos-de-activo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '45',
                },
                {
                  title: 'Tipos de Activo',
                  icon: 'list_alt',
                  url: 'modulo-activo-fijo/tipos-de-activo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '45',
                },
                {
                  title: 'Ubicaciones de Activo',
                  icon: 'list_alt',
                  url: 'modulo-activo-fijo/ubicaciones-de-activo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '45',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '45',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Retiros, Trans. Financieros',
                  icon: 'post_add',
                  url: 'modulo-activo-fijo/transferencias-y-o-retiros-financieros',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '45',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '45',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Activo Fijo',
                  icon: 'summarize',
                  url: 'modulo-activo-fijo/informe-de-activo-fijo',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '45',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '45',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '45',
        },
        {
          title: 'CRM',
          icon: 'crop_square',
          url: 'modulo-crm',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Canales',
                  icon: 'post_add',
                  url: 'modulo-crm/canales',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '36',
                },
                {
                  title: 'Etapas',
                  icon: 'post_add',
                  url: 'modulo-crm/etapas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '36',
                },
                {
                  title: 'Lista de Requisitos',
                  icon: 'post_add',
                  url: 'modulo-crm/lista-de-requisitos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '36',
                },
                {
                  title: 'Situaciones de Servicio',
                  icon: 'post_add',
                  url: 'modulo-crm/situaciones-de-servicio',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '36',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [
                {
                  title: 'Registro de Oportunidad',
                  icon: 'post_add',
                  url: 'modulo-crm/registros-de-oportunidades',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '36',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'CRM',
                  icon: 'summarize',
                  url: 'modulo-crm/informe-de-crm',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '36',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
            {
              title: 'Consultas',
              icon: 'fact_check',
              submenus: [
                {
                  title: 'Consulta de Seguimiento de Oportunidad',
                  icon: 'fact_check',
                  url: 'modulo-crm/consulta-seguimiento-oportunidad',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '36',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
            {
              title: 'Cierre',
              icon: 'event_busy',
              url: 'modulo-crm/cierre-de-ventas',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '36',
        },
        {
          title: 'Lib. Electrónicos',
          icon: 'crop_square',
          url: 'modulo-libros-electronicos',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Participación Accionaria',
                  icon: 'list_alt',
                  url: 'modulo-libros-electronicos/participaciones-accionarias',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '4B',
            },
            {
              title: 'Kardex',
              icon: 'list_alt',
              url: 'modulo-libros-electronicos/kardex-libros-electronicos',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '4B',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '4B',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '4B',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Libro electrónico',
                  icon: 'summarize',
                  url: 'modulo-libros-electronicos/informe-de-libros-electronicos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '4B',
            },
            {
              title: 'Consultas',
              icon: 'fact_check',
              submenus: [
                {
                  title: 'Estado Situación Financiera',
                  icon: 'fact_check',
                  url: 'modulo-libros-electronicos/estado-situación-financiera',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '4B',
            },
            {
              title: 'Cierre',
              icon: 'event_busy',
              url: 'modulo-libros-electronicos/cierre-de-ventas',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '4B',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '4B',
        },
        {
          title: 'Costos',
          icon: 'crop_square',
          url: 'modulo-costos',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '47',
            },
            {
              title: 'Operaciones',
              icon: 'post_add',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '47',
            },
            {
              title: 'Procesos',
              icon: 'dns',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '47',
            },
            {
              title: 'Informes',
              icon: 'summarize',
              submenus: [
                {
                  title: 'Costos',
                  icon: 'summarize',
                  url: 'modulo-costos/informe-de-costos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Gráfico Costo Unitario Producto',
                  icon: 'summarize',
                  url: 'modulo-costos/informe-de-grafico-costo-unitario-producto',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '47',
            },
            {
              title: 'Consultas',
              icon: 'fact_check',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '47',
            },
            {
              title: 'Cierre',
              icon: 'event_busy',
              url: '/cierre-de-ventas',
              submenus: [],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '47',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '47',
        },
        {
          title: 'Utilitarios',
          icon: 'crop_square',
          url: 'modulo-utilitarios',
          submenus: [
            {
              title: 'Contabilidad',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Config. Cta. Dif. Cambio',
                  icon: 'summarize',
                  url: 'modulo-utilitarios/configuracion-cuenta-diferencia-cambio',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Certificados digitales',
                  icon: 'summarize',
                  url: 'modulo-utilitarios/certificados-digitales-contabilidad',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },

            {
              title: 'Caja y Banco',
              icon: 'dns',
              submenus: [
                {
                  title: 'Saldo Inicial Bancos',
                  icon: 'summarize',
                  url: 'modulo-utilitarios/saldo-inicial-bancos-conciliacion-bancaria',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '91',
        },
        {
          title: 'Producción',
          icon: 'crop_square',
          url: 'modulo-produccion',
          submenus: [
            {
              title: 'Maestros',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Actividades',
                  icon: 'summarize',
                  url: 'modulo-produccion/actividades',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Áreas de Producción',
                  icon: 'summarize',
                  url: 'modulo-produccion/areas-de-produccion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Líneas de Prod. Operario',
                  icon: 'summarize',
                  url: 'modulo-produccion/lineas-de-produccion-operario',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Máquinas',
                  icon: 'summarize',
                  url: 'modulo-produccion/maquinas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Plantas',
                  icon: 'summarize',
                  url: 'modulo-produccion/plantas',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Procesos',
                  icon: 'dns',
                  url: 'modulo-produccion/procesos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Turnos',
                  icon: 'summarize',
                  url: 'modulo-produccion/turnos',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
            {
              title: 'Estandares',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Form. y Hoja Ruta',
                  icon: 'summarize',
                  url: 'modulo-produccion/formulacion-y-hoja-de-ruta',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
            {
              title: 'Operaciones',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Explosión de Materiales',
                  icon: 'summarize',
                  url: 'modulo-produccion/explosion-de-materiales',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Liquidación de Prod.',
                  icon: 'summarize',
                  url: 'modulo-produccion/liquidacion-de-produccion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Orden de Producción',
                  icon: 'summarize',
                  url: 'modulo-produccion/orden-de-produccion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Req. de Materiales Prod.',
                  icon: 'summarize',
                  url: 'modulo-produccion/requerimiento-de-materiales-produccion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
                {
                  title: 'Req. de Producción',
                  icon: 'summarize',
                  url: 'modulo-produccion/requerimiento-de-produccion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
            {
              title: 'Informes',
              icon: 'list_alt',
              submenus: [
                {
                  title: 'Producción',
                  icon: 'summarize',
                  url: 'modulo-produccion/informes-de-produccion',
                  submenus: [],
                  isExpanded: true,
                  showSubmenu: false,
                  isShowing: false,
                  codeMod: '4B',
                },
              ],
              isExpanded: true,
              showSubmenu: false,
              isShowing: false,
              codeMod: '36',
            },
          ],
          isExpanded: true,
          showSubmenu: false,
          isShowing: false,
          codeMod: '22',
        },
      ],
      isExpanded: true,
      showSubmenu: true,
      isShowing: false,
    },
    {
      title: 'Mi Perfil',
      icon: 'profile',
      url: '/mi-perfil',
      submenus: [],
      isExpanded: true,
      showSubmenu: false,
      isShowing: false,
    },
  ];

  constructor() {}
}
