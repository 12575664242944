<app-pavso-title-section [title]="'Colaboradores'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="colaboradores"
    [tHeaders]="tHeaders"

    title="Todos los colaboradores"
    url="/modulo-planilla/colaborador"
    entity=""
    [params]="['0']"
  ></pav-table-list>

  <mat-card class="mat-elevation-z0 pav-announcement-period">Colaboradores del período {{year}}-{{month}}</mat-card>
</div>
