<form #f="ngForm" ngNativeValidate (ngSubmit)="agregar(f)" (keydown.enter)="$event.preventDefault()">
    <h1 mat-dialog-title>TIPO DE CAMBIO</h1>
    <div mat-dialog-content>
      <app-currency-select [moneda]="cambio.moneda" [disable]="loaderData" (cambioMoneda)="$event"></app-currency-select>

        <mat-form-field>
            <mat-label>Compra</mat-label>
            <input matInput type="number" placeholder="Compra" [(ngModel)]="cambio.compra" name="compra" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Venta</mat-label>
            <input matInput type="number" placeholder="Venta" [(ngModel)]="cambio.venta" name="venta" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea matInput placeholder="Descripción" [(ngModel)]="cambio.descripcion" name="descripcion" required></textarea>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()" type="button">{{buttonName.BTN_BACK | translate}}</button>
      <button mat-button type="submit">Agregar</button>
    </div>
</form>
