
<mat-sidenav-container class="example-container">
  <mat-sidenav #drawer mode="over" position="end">
  </mat-sidenav>
  <mat-sidenav-content class="pavso-container">
    <!-- <pav-loader></pav-loader> -->
    <div [ngStyle]="{marginTop: '100px'}">
      <router-outlet></router-outlet>
    </div>
    <!-- <div class="pav-card-float-right">
      <mat-card class="mat-elevation-z10 pav-container-resume" *ngIf="isShowing">
        <div class="example-button-container">
          <button class="pav-button-forward" (click)="cambiarShowing()" mat-mini-fab color="primary">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
        <h1 class="pav-status-title">{{month}}/{{year}}</h1>
        <div class="pav-container-float">
          <div class="pav-container-float-item">
            <h1 class="pav-card-float-title">Oportunidades</h1>
            <span class="pav-card-float-status" matBadge="4" matBadgeOverlap="false">Abiertas</span>
          </div>
          <div class="pav-container-float-item">
            <h1 class="pav-card-float-title">Seguimientos</h1>
            <span class="pav-card-float-status" matBadge="5" matBadgeOverlap="false">Contactar</span>
          </div>
          <div class="pav-container-float-item">
            <h1 class="pav-card-float-title">Presupuestos</h1>
            <span class="pav-card-float-status" matBadge="3" matBadgeOverlap="false">Realizados</span>
          </div>
          <div class="pav-container-float-item">
            <h1 class="pav-card-float-title">Cotizaciones</h1>
            <span class="pav-card-float-status" matBadge="1" matBadgeOverlap="false">Realizados</span>
          </div>
          <div class="pav-container-float-item">
            <h1 class="pav-card-float-title">Pedidos</h1>
            <span class="pav-card-float-status" matBadge="0" matBadgeOverlap="false">Realizados</span>
          </div>
        </div>
      </mat-card>
      <div class="example-button-container" *ngIf="!isShowing">
        <button class="pav-button-back" (click)="cambiarShowing()" mat-mini-fab color="primary">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </div> -->
  </mat-sidenav-content>
</mat-sidenav-container>
