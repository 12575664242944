<app-pavso-title-section [title]="'Configuración'" [module]="'Contabilidad'"
[usuario]="configuracion.sig_coduse" [fecha]="configuracion.sig_fecupd" [ngClass]="'pav-form'" [estado]="configuracion.sig_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Clase</mat-label>
            <input [(ngModel)]="configuracion.cla_codcla" type="text" matInput placeholder="Clase" name="cla_codcla"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Doc.</mat-label>
            <mat-select [(ngModel)]="configuracion.tdo_codtdo" required name="tdo_codtdo">
              <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.TDO_CODTDO">
                {{tipo.TDO_DESCOR}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Signo (1) Debe (-1) Haber</mat-label>
            <input type="number" [(ngModel)]="configuracion.sig_indsig" matInput placeholder="Signo" name="sig_indsig"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cuenta MN</mat-label>
            <mat-select [(ngModel)]="configuracion.ccn_codmn" required name="ccn_codmn">
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cuenta ME</mat-label>
            <mat-select [(ngModel)]="configuracion.ccn_codme" required name="ccn_codme">
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

    </mat-card>
    <br>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-contabilidad/configuraciones-control-cta-cte">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
