export { ActividadCRM } from "./actividad-crm";
export { Campania } from "./campania";
export { Canal } from "./canal";
export { ClaseActivo } from "./clase-activo";
export { ClienteCRM } from "./cliente-crm";
export { Cotizacion } from "./cotizacion";
export { Especificacion } from "./especificacion";
export { Etapa } from "./etapa";
export { FiltroConsultaSeguimiento } from "./filtro-consulta-seguimiento";
export { FiltroOportunidad } from "./filtro-oportunidad";
export { FiltroReporteCRM } from "./filtro-reporte-crm";
export { GrupoActividad } from "./grupo-actividad";
export { LineaNegocio } from "./linea-negocio";
export { ListaRequisito } from "./lista-requisito";
export { MotivoVentaSuspendida } from "./motivo-venta-suspendida";
export { OrdenPedido } from "./orden-pedido";
export { PlantillaFichaTecnica } from "./plantilla-ficha-tecnica";
export { Presupuesto } from "./presupuesto";
export { PropietarioActivo } from "./propietario-activo";
export { RegistroOportunidad } from "./registro_oportunidad";
export { RequisitoCliente } from "./requisito-cliente";
export { Responsable } from "./responsable";
export { SeguimientoOportunidad } from "./seguimiento_oportunidad";
export { SituacionOportunidad } from "./situacion_oportunidad";
export { TipoAtencion } from "./tipo-atencion";
export { TipoCampania } from "./tipo-campania";
export { TipoNegociacion } from "./tipo-negociacion";
