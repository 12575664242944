import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DescuentoProductoCabecera } from "src/app/models/ventas/tabla-descuento-producto";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class DescuentoProductoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerDescuentosProductos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/desproducto/listar/${this._configurationService.obtenerCompaniaCliente()}`,

    );
  }

  mostrarDescuentoProducto(tbd_numtbd:string): Observable<any> {

      return this.http.get<any>(
        `${this._configurationService.obtenerUrl()}/api/desproducto/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${tbd_numtbd}`,
      );

  }

  registrarDescuentoProducto(payload:DescuentoProductoCabecera): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/desproducto/crear`,
      payload
    )
  }

  modificarDescuentoProducto(payload:DescuentoProductoCabecera): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/desproducto/modificar`,
      payload
    )
  }
}
