import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'consulta-ticket-dialog',
  templateUrl: './consulta-ticket.dialog.html',
  styleUrls: ['./consulta-ticket.dialog.scss']
})
export class ConsultaTicketDialog {

  constructor(
    public dialogRef: MatDialogRef<ConsultaTicketDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
}
