import { Injectable, OnDestroy } from "@angular/core";
import { HeaderReport } from "@data/interfaces/headers.report";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import * as pdfBuilder from "@utils/pdfmaker/builder/costos.pdfmaker";
import { Subscription } from "rxjs";
import { CostosService } from "../../api/costos.service";

@Injectable({
  providedIn: 'root'
})
export class CostosReportService implements OnDestroy {

  reporte$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _costosService: CostosService
  ) {}

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporte(reportSelected: string, headers: HeaderReport, payload: any, otherData: any): Promise<any> {

    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "COSTOS_DCOF":
          this.reporte$ = this._costosService.obtenerReporteDetalladoCostosOrdenFabricacion(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.reporteDetalladoCostosOrdenFabricacion(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte detallado costos orden de fabricación", "OK");
              reject(false);
            }
          )
          break;
        case "COSTOS_ECOP":
          pdfBuilder.reporteEstructuraCostosOrdenProduccion(headers, null);
          resolve(true);
          break;
        case "COSTOS_ADMOD":
          this.reporte$ = this._costosService.obtenerReporteAnalisisDistribucionManoObraDirecta(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.reporteAnalisisDistribucionManoObraDirecta(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de análisis distribución de mano de obra directa", "OK");
              reject(false);
            }
          )
          break;
        case "COSTOS_ACMOI":
          this.reporte$ = this._costosService.obtenerReporteAnalisisConsistenciaManoObraIndirecta(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.reporteAnalisisConsistenciaManoObraIndirecta(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de analisis consistencia mano de obra indirecta", "OK");
              reject(false);
            }
          )
          break;
        case "COSTOS_ADMODA":
          this.reporte$ = this._costosService.obtenerReporteAnalisisDistribucionManoObraDirectaAuxiliar(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.reporteAnalisisDistribucionManoObraDirectaAuxiliar(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de analisis distribucion mano obra directa auxiliar", "OK");
              reject(false);
            }
          )
          break;
        case "COSTOS_SCSTCGF":
          this.reporte$ = this._costosService.obtenerReporteSaldosContablesSegunTipoCostoGastosFabrica(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.reporteSaldosContablesSegunTipoCostoGastosFabrica(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de saldos contables segun tipo costos gastos fabrica", "OK");
              reject(false);
            }
          )
          break;
        case "COSTOS_ADGF":
          this.reporte$ = this._costosService.obtenerReporteAnalisisDistribucionGastosFabrica(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.reporteAnalisisDistribucionGastosFabrica(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte analisis deistribucion gastos fabrica", "OK")
              reject(false);
            }
          )
          break;
        case "COSTOS_SCCGV":
          this.reporte$ = this._costosService.obtenerReporteSaldosContablesPorClientesGastosVentas(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.reporteSaldosContablesPorClientesGastosVentas(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte saldos contables clientes gastos ventas", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.reporte$]);
  }
}
