<app-pavso-title-section [title]="'INFORME'" [module]="'PRODUCCIÓN'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>


  <mat-card class="mat-elevation-z0">
    <mat-card-header>
      <mat-card-title>Filtro</mat-card-title>
      <mat-card-subtitle>Seleccionar Filtro</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-toolbar>
        <span>No Tiene Filtros</span>
      </mat-toolbar>
    </mat-card-content>
  </mat-card>
  <br>

  <div align="end">
    <button mat-flat-button color="primary" (click)="verReporte()">{{LABELS_NAME.BTN_SHOW_REPORT}}</button>
  </div>
</div>
