<h1 mat-dialog-title>EDITAR PRODUCTO</h1>
<div mat-dialog-content>

    <div class="row">
        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>Cantidad</mat-label>
                <input matInput placeholder="Cantidad" (keyup)="cambioDeCantidad()" [(ngModel)]="data.DCD_CANVEN" name="DCD_CANVEN" required>
            </mat-form-field>
        </div>

        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>Precio</mat-label>
                <input matInput placeholder="Precio" (keyup)="cambioDeParametroPrecio()" [(ngModel)]="data.DCD_PREUNI" name="DCD_PREUNI" required>
            </mat-form-field>
        </div>

        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>Precio Inc. IGV.</mat-label>
                <input matInput placeholder="Precio Inc. IGV." (keyup)="cambioDeParametroPrecioIgv()" [(ngModel)]="data.DCD_PRUIGV" name="DCD_PRUIGV" required>
            </mat-form-field>
        </div>

        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>Imp. Bruto</mat-label>
                <input matInput placeholder="Imp. Bruto" [(ngModel)]="data.DCD_IMPBRU" name="DCD_IMPBRU" required disabled>
            </mat-form-field>
        </div>

        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>(%) Descuento</mat-label>
                <input matInput placeholder="(%) Descuento" [(ngModel)]="data.DCD_PORDES" name="DCD_PORDES" required disabled>
            </mat-form-field>
        </div>

        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>Imp. Descuento</mat-label>
                <input matInput placeholder="Imp. Descuento" [(ngModel)]="data.DCD_IMPDES" name="DCD_IMPDES" required disabled>
            </mat-form-field>
        </div>

        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>Valor venta</mat-label>
                <input matInput placeholder="Valor venta" [(ngModel)]="data.DCD_VALVTA" name="DCD_VALVTA" required disabled>
            </mat-form-field>
        </div>

        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>Imp. IGV</mat-label>
                <input matInput placeholder="Imp. IGV" [(ngModel)]="data.DCD_IMPIGV" name="DCD_IMPIGV" required disabled>
            </mat-form-field>
        </div>

        <div class="col s12 m4">
            <mat-form-field class="example-full-width">
                <mat-label>Imp. Total</mat-label>
                <input matInput placeholder="Imp. Total" [(ngModel)]="data.DCD_IMPTOT" name="DCD_IMPTOT" required disabled>
            </mat-form-field>
        </div>

    </div>

    <form #f="ngForm" ngNativeValidate (ngSubmit)="agregarDescuento(f)" (keydown.enter)="$event.preventDefault()">

        <div class="row">
            <div class="col s5">
                <mat-form-field>
                    <mat-label>Seleccionar tipo de descuento</mat-label>
                    <mat-select name="dco_coddco" [(ngModel)]="descuento.dco_coddco" required>
                        <mat-option *ngFor="let descuento of descuentos" [value]="descuento.dco_coddco">
                            {{descuento.dco_desdco}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col s5">
                <mat-form-field class="pavso_input_search">
                    <mat-label>Descuento (%)</mat-label>
                    <input type="number" matInput placeholder="Descuento (%)" name="ddd_pordes" [(ngModel)]="descuento.ddd_pordes" required min="1">
                </mat-form-field>
            </div>
            <div class="col s2">
                <button mat-stroked-button color="primary" type="submit">Agregar</button>
            </div>
        </div>
    </form>
    <br>

    <div class="mat-elevation-z2">

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ITEM </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="ITEM"> {{i + 1}} </td>
            </ng-container>

            <ng-container matColumnDef="cod_desc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> COD. DESC. </th>
                <td mat-cell *matCellDef="let row" data-label="COD. DESC."> {{row.dco_coddco}} </td>
            </ng-container>

            <ng-container matColumnDef="desc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> DESC. </th>
                <td mat-cell *matCellDef="let row" data-label="DESCUENTO"> {{(row.dco_coddco == '0001')? "Desc Especial" : "Desc. Regalo"}} </td>
            </ng-container>

            <ng-container matColumnDef="porc_desc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> % DESC </th>
                <td mat-cell *matCellDef="let row" data-label="% DESC"> {{row.ddd_pordes.toFixed(2)}} </td>
            </ng-container>

            <ng-container matColumnDef="importe_desc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IMPORTE DESC </th>
                <td mat-cell *matCellDef="let row" data-label="IMPORTE DESC"> {{row.ddd_impdes.toFixed(2)}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ACCIONES </th>
                <td mat-cell *matCellDef="let row; let i = index" [style.color]="row.color" data-label="Acciones">
                    <button matTooltip="Eliminar descuento" (click)="eliminarDescuento(i)" mat-icon-button color="warn">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>

                <td class="mat-cell" colspan="2">No se encontraron registros</td>

            </tr>
        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>
</div>

<div mat-dialog-actions>
    <button mat-flat-button (click)="actualizarProducto()" color="primary">GUARDAR CAMBIOS</button>
</div>
