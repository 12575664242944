import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class TipoCambioService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  registrarTipoCambio(tipoCambio: any): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/tipocambio/crear`,
      tipoCambio
    );
  }

  actualizarTipoOperacion(tipoCambio: any): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/tipocambio/modificar`,
      tipoCambio
    );
  }

  obtenerTipoCambio(fecha: string, monedaNacional: string, monedaExtranjera: string): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tipocambio/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${fecha}/${monedaNacional}/${monedaExtranjera}`
    );
  }

  obtenerTiposCambio(): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tipocambio/listar/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }


}
