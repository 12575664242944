import { logo } from "@shared/resources/logo";
import { numberWithCommas } from "@utils/formats/number.format";
import { bottomBorder, horizontalBorder, noBorders, openRightBorder, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Canvas, Cell, Columns, Img, Line, PdfMakeWrapper, QR, Stack, Table, Txt } from "pdfmake-wrapper";
import { OrderPdf } from "src/app/models";
import { NumeroALetras } from "../../conversions/number2letters.conversion";
import { formatDateWithDash, formatDateWithScript, formatDateWithSlash, getHourMinuteSecondsCurrent } from "../../formats/date.format";
import { openLeftBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { PavsoColors } from "@data/enums/color.enum";
import { number2month } from "@utils/conversions/number2month.conversion";
import { COLOR_GRAY } from "@data/constants/configuration/configuration.constant";
import { formatoHoras } from "@utils/conversions/number2time.conversion";

/**
 * Reporte de registro de ventas
 * @param headers
 * @param data
 */
export function generarPdfReport(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageSize({ width: 1200, height: 400 })
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalleRegistroVentas = [];

  data.forEach(element => {
    let dateemi = new Date(element.CCO_FECEMI);
    let dateven = new Date(element.CCO_FECVEN);

    detalleRegistroVentas.push(
      [
        new Cell(
          new Txt(element.CCO_CODCCO).fontSize(5).end
        ).end,
        new Cell(
          new Txt(`${dateemi.getDate()}/${dateemi.getMonth()}/${dateemi.getFullYear()}`).fontSize(5).end
        ).end,
        new Cell(
          new Txt(`${dateven.getDate()}/${dateven.getMonth()}/${dateven.getFullYear()}`).fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.TDO_CODOFI).fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.serie).fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.CCO_NUMDOC).fontSize(5).end
        ).end,
        new Cell(
          new Txt("").fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.cli_numruc).fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.cli_nomcli).fontSize(5).end
        ).end,
        new Cell(
          new Txt("").fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.CCO_IMPAFE).fontSize(5).end
        ).end,
        new Cell(
          new Txt("").fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.CCO_IMPINA).fontSize(5).end
        ).end,
        new Cell(
          new Txt("").fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.CCO_IMPIGV).fontSize(5).end
        ).end,
        new Cell(
          new Txt("").fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.CCO_IMPDOC).fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.CCO_TIPCAM).fontSize(5).end
        ).end,
        new Cell(
          new Txt("").fontSize(5).end
        ).end,
        new Cell(
          new Txt("").fontSize(5).end
        ).end,
        new Cell(
          new Txt("").fontSize(5).end
        ).end,
        new Cell(
          new Txt(element.CCO_NUMREF).fontSize(5).end
        ).end,
      ]
    )
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt("").end
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt("Registro de Ventas").fontSize(12).bold().alignment('center').end,
            new Txt(`${headers.year} ${headers.month}`).alignment('center').fontSize(9).bold().end,
            new Txt("En Nuevos Soles").alignment('center').fontSize(9).bold().end
          ]).end
        ).border(noBorders).colSpan(16).end,
        ...obtenerNulls(15),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').bold().fontSize(8).end,
            new Txt(headers.hour).alignment('right').bold().fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("NÚMERO CORRELATIVO DEL REGISTRO O CÓDIGO ÚNICO DE LA OPERACIÓN").fontSize(8).bold().alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("FECHA DE EMISIÓN DEL COMPROBANTE DE PAGO O DOCUMENTO").fontSize(8).bold().alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("FECHA DE VCTO. Y/O DE PAGO").fontSize(8).bold().alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("COMPROBANTE DE PAGO O DOCUMENTO").fontSize(8).bold().alignment('center').end
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("INFORMACIÓN DEL CLIENTE").fontSize(8).bold().alignment('center').end
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("VALOR DE FACTURADO DE LA EXPORTACIÓN").fontSize(8).bold().alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("BASE IMPONIBLE DE LA OPERACIÓN GRAVADA").fontSize(8).bold().alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("IMPORTE DE LA OPERACIÓN EXONERADA O INAFECTA").fontSize(8).bold().alignment('center').end
        ).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("OTROS TRIBUTOS Y CARGOS QUE NO FORMAN PARTE DE LA BASE IMPONIBLE").fontSize(8).bold().alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("IMPORTE TOTAL DEL COMPROBANTE DE PAGO").fontSize(8).bold().alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("TIPO DE CAMBIO").fontSize(8).bold().alignment('center').end
        ).rowSpan(3).end,
        new Cell(
          new Txt("REFERENCIA DEL COMPROBANTE DE PAGO O DOCUMENTO ORIGINAL QUE SE MODIFICA").fontSize(8).bold().alignment('center').end
        ).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        ...obtenerNulls(3),
        new Cell(
          new Txt("TIPO (TABLA 10)").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("Nro. DE SERIE O Nro. DE SERIE DE LA MÁQUINA REGISTRADORA").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("NÚMERO").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("DOCUMENTO DE IDENTIDAD").fontSize(8).bold().alignment('center').end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("APELLIDOS Y NOMBRES DENOMINACIÓN O RAZÓN SOCIAL").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("EXONERADA").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("INAFECTA").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("ISC").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("IGV Y/0 IRM").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("FECHA").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("TIPO DE (Tabla 10)").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("SERIE").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt("Nro. DEL COMPROBANTE DE PAGO O DOCUMENTO").fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
      ],
      [
        ...obtenerNulls(6),
        new Cell(
          new Txt("TIPO TABLA 2").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("NÚMERO").fontSize(8).bold().alignment('center').end
        ).end,
        ...obtenerNulls(14),
      ],
      ...detalleRegistroVentas
    ]).end
  )
  pdf.create().open();
}

/**
 * Reporte de Detallado de Facturación
 * @param headers
 * @param data
 */
export function generarPdfDetalleVentaReport(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');

  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  const detalle = data.map(element => {
    return [
      new Cell(
        new Txt(element.cli_nomcli).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.TDO_CODTDO).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.CCO_NUMDOC).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(formatDateWithSlash(element.CCO_FECEMI)).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Línea").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Cod. Pro.").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Producto").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Cantidad").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Precio").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.TMO_CODTMO).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.CCO_IMPDOC).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Usuario").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Referencia").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.PCC_NUMPCC).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Guía Remisión").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.CPA_DESCRI).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.VDE_NOMVDE).fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Motivo").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Ind. Guía").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Almacén").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Costo Prod.").fontSize(6).bold().end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt("Departamento").fontSize(6).bold().end
      ).color(PavsoColors.Black).end
    ]
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt("COMERCIALIZADORA ASIA PERU S.A.C.").fontSize(8).alignment('left').end,
            new Txt("RCOM_REP_DET_VE").fontSize(8).alignment('left').end
          ]).end
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("DETALLADO DE VENTAS").fontSize(12).bold().alignment('center').end
        ).colSpan(16).end,
        ...obtenerNulls(15),
        new Cell(
          new Stack([
            new Txt("13/09/2021").fontSize(8).alignment('right').end,
            new Txt("15:03:11").fontSize(8).alignment('right').end
          ]).end
        ).colSpan(3).end,
        ...obtenerNulls(2)
      ],
      [
        new Cell(
          new Txt("Cliente").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("TD").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Número").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fecha").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Línea").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cod. Pro.").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Producto").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cantidad").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Precio").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Moneda").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Usuario").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Referencia").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Pedido").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Guía Remisión").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cond. Pago").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Vendedor").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Motivo").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Ind. Guía").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Almacén").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Costo Prod.").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Departamento").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
      ],
      ...detalle
    ]).headerRows(2).widths([65, 15, 15, 15, 25, 35, 65, 25, 15, 15, 15, 45, 65, 25, 35, 15, 65, 15, 15, 15, 15, 15]).layout('noBorders').end
  );

  pdf.create().open();
}

/**
 * Reporte de Comprobantes por Cliente
 * @param hearders headers to set
 * @param data data to fill report
 */
export function generarPDFComprobantesCliente(headers, data) {

  console.log('data', data)
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([30, 30, 30, 30]);

  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });

  let detalle = [];

  let [totalGeneral1, totalGeneral2, totalGeneral3] = [0, 0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt("Cliente").bold().fontSize(11).end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(`${element.CLI_CODCLI}   ${element.cli_nomcli}`).bold().fontSize(10).end
      ).border(noBorders).colSpan(9).end,
      ...obtenerNulls(8),
    ],);

    let [totalCliente1, totalCliente2, totalCliente3] = [0, 0, 0];

    element.detalle.forEach(element2 => {
      detalle.push([
        new Cell(
          new Txt(`Documento: FAC ${element2.CCO_NUMDOC}   F.   ${formatDateWithSlash(element2.CCO_FECEMI)}:    Moneda   ${element2.TMO_CODTMO}   224.58   ${element2.CCO_IMPIGV}   ${element2.CCO_IMPDOC}   Referencia`).bold().fontSize(8).end
        ).border(noBorders).colSpan(11).end,
        ...obtenerNulls(10),
      ],)

      let [total1, total2, total3] = [0, 0, 0];

      element2.detalle.forEach(element3 => {

        let valvta = headers.tmo_codtmo == 'SO' ? element3.dcd_valvtas : element3.dcd_valvtad;
        let impigv = headers.tmo_codtmo == 'SO' ? element3.dcd_impigvs : element3.dcd_impigvd;
        let imptot = headers.tmo_codtmo == 'SO' ? element3.dcd_imptots : element3.dcd_imptotd;

        total1 += valvta;
        total2 += impigv;
        total3 += imptot;

        detalle.push([
          new Cell(
            new Txt("01").fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.PRD_CODPRD).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.prd_desesp).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.dcd_candcd).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3.DCD_PREUNI.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element3.DCD_PRUIGV.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(headers.tmo_codtmo == 'SO' ? numberWithCommas(element3.dcc_impbrus.toFixed(2)) : numberWithCommas(element3.dcc_impbrud.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(headers.tmo_codtmo == 'SO' ? numberWithCommas(element3.dcd_impdess.toFixed(2)) : numberWithCommas(element3.dcd_impdesd.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(valvta.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(impigv.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(imptot.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
        ],)
      });

      totalCliente1 += total1;
      totalCliente2 += total2;
      totalCliente3 += total3;

      detalle.push([
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(total1.toFixed(2))).bold().fontSize(6).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total2.toFixed(2))).bold().fontSize(6).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(total3.toFixed(2))).bold().fontSize(6).alignment('right').end
        ).border(topBorder).end,
      ],)

    });

    totalGeneral1 += totalCliente1;
    totalGeneral2 += totalCliente2;
    totalGeneral3 += totalCliente3;

    detalle.push([
      new Cell(
        new Txt("").end
      ).border(noBorders).end,
      new Cell(
        new Txt("").end
      ).border(noBorders).end,
      new Cell(
        new Txt("").end
      ).border(noBorders).end,
      new Cell(
        new Txt("").end
      ).border(noBorders).end,
      new Cell(
        new Txt("").end
      ).border(noBorders).end,
      new Cell(
        new Txt("").end
      ).border(noBorders).end,
      new Cell(
        new Txt("Total Cliente").bold().fontSize(6).alignment('center').end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(totalCliente1.toFixed(2))).bold().fontSize(6).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totalCliente2.toFixed(2))).bold().fontSize(6).alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totalCliente3.toFixed(2))).bold().fontSize(6).alignment('right').end
      ).border(topBorder).end,
    ],)
  });

  detalle.push([
    new Cell(
      new Txt("").end
    ).border(openRightBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("").end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt("Total General").bold().fontSize(6).alignment('center').end
    ).colSpan(2).border(horizontalBorder).end,
    ...obtenerNulls(1),
    new Cell(
      new Txt(numberWithCommas(totalGeneral1.toFixed(2))).bold().fontSize(6).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(totalGeneral2.toFixed(2))).bold().fontSize(6).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(totalGeneral3.toFixed(2))).bold().fontSize(6).alignment('right').end
    ).border(openLeftBorder).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(6).alignment('left').end,
            new Txt("RCOM_COMP_CLIE").fontSize(6).alignment('left').end
          ]).end,
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("COMPROBANTES POR CLIENTE").fontSize(10).bold().alignment('center').end,
            new Txt(headers.tmo_codtmo == "SO" ? "EN NUEVOS SOLES" : "EN DÓLARES").fontSize(8).bold().alignment('center').end
          ]).end,
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Stack([
            new Txt(headers.now).fontSize(7).alignment('right').end,
            new Txt(headers.time).fontSize(7).alignment('right').end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Sec.").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cod. Prod.").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Producto").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Qt.").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Pre. Unit.").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Pre. IGV").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Imp. Bruto").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Imp. Dscto.").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Val. Venta").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Imp. IGV").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Imp. Total").alignment('center').bold().fontSize(7).end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
      ],
      ...detalle,
    ]).headerRows(2).widths([15, 30, 150, 30, 30, 30, 30, 30, 30, 30, 30]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Pedido de Venta
 * @param headers
 * @param pedido
 */
export async function crearFormatoPedidoPdf(headers, data) {

  console.log('data', data)

  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, color: 'gray', fontSize: 10, bold: true, margin: [5, 2, 10, 10], alignment: 'right' },
    ];
  })
  let detalle = []

  data.detalle.forEach((element, index) => {
    detalle.push(
      [
        new Cell(
          new Txt(element.PCD_CORPCD).fontSize(7).end,
        ).fillColor(index % 2 == 0 ? '#eee' : 'white').border(noBorders).end,
        new Cell(
          new Txt(element.prd_desesp).fontSize(7).end,
        ).fillColor(index % 2 == 0 ? '#eee' : 'white').border(noBorders).end,
        new Cell(
          new Txt(element.PRD_CODPRD).fontSize(7).end,
        ).fillColor(index % 2 == 0 ? '#eee' : 'white').border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.PCD_CANSOL.toFixed(2))).alignment('right').fontSize(7).end,
        ).fillColor(index % 2 == 0 ? '#eee' : 'white').border(noBorders).end,
        new Cell(
          new Txt(element.PCD_PREUNI.toFixed(2)).alignment('right').fontSize(7).end,
        ).fillColor(index % 2 == 0 ? '#eee' : 'white').border(noBorders).end,
        new Cell(
          new Txt(element.PCD_PORDES.toFixed(2)).alignment('right').fontSize(7).end,
        ).fillColor(index % 2 == 0 ? '#eee' : 'white').border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.PCD_IMPTOT.toFixed(2))).alignment('right').fontSize(7).end,
        ).fillColor(index % 2 == 0 ? '#eee' : 'white').border(noBorders).end
      ]

    )
  });

  pdf.add(
    new Table([
      [
        new Cell(

          new Stack([
            headers.logo && await new Img(headers.logo).alignment('left').width(80).build(),
            pdf.ln(1),
          ]).end
        ).border(noBorders).end,
        // ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.CIA_NOMCIA).fontSize(9).bold().alignment('center').end,
            new Txt(`RUC: ${headers.CIA_NUMRUC}`).fontSize(9).alignment('center').end,
            new Txt(`${headers.CIA_DIRCIA}`).fontSize(9).alignment('center').end,
            pdf.ln(1),

          ]).alignment('center').end,
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Table([
            [
              new Cell(
                new Txt(`PEDIDO DE VENTA`).alignment('center').fontSize(7).end
              ).end
            ],
            [
              new Cell(
                new Txt(`PEDIDO N° : ${data?.PCC_NUMPCC}`).alignment('center').fontSize(7).end
              ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end
            ],
            [
              new Cell(
                new Txt(``).alignment('center').fontSize(7).end
              ).end
            ],
          ]).layout({
            hLineColor: (i, node) => {
              return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
            },
            vLineColor: (i, node) => {
              return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
            },
            hLineWidth: (i, node) => {
              return 1;
            },
            vLineWidth: (i, node) => {
              return 1;
            }
          }).widths([120]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1)
      ],
      // [
      //   new Cell(
      //     new Txt(`PEDIDO N° : PV${data?.PCC_NUMPCC}`).fontSize(10).bold().end,
      //   ).colSpan(4).end,
      //   ...obtenerNulls(3),
      //   new Cell(
      //     new Txt(data?.sdv_dessdv).bold().end
      //   ).colSpan(3).end,
      //   ...obtenerNulls(2),
      // ],
      [
        new Cell(
          new Txt(`Solicitante : ${data?.PCC_CODUSE}`).fontSize(9).bold().end,
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Fecha : ${formatDateWithDash(new Date(data?.PCC_FECCRE))}`).fontSize(9).bold().end,
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`Forma de Pago : ${data?.CPA_DESCRI}`).fontSize(9).bold().end,
        ).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt(`Vendedor : ${data?.VDE_NOMVDE}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt('INFORMACIÓN DEL CLIENTE').fontSize(10).alignment('center').bold().end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`Cliente : ${data?.cli_nomcli}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6),

      ],
      [
        new Cell(
          new Txt(`Dirección : ${data.cli_dircli ? data.cli_dircli : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`RUC : ${data?.CLI_CODCLI}`).fontSize(9).bold().end,
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`Ubigeo : ${data?.desubica_cliente}`).fontSize(9).bold().end,
        ).colSpan(4).end,
        ...obtenerNulls(3)
      ],
      [
        new Cell(
          new Txt(`Dirección de Despacho : ${data?.lde_deslar}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt('INFORMACIÓN DEL TRANSPORTISTA').fontSize(10).alignment('center').bold().end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`Nombre o Razón Social : ${data.cli_nomtra ? data.cli_nomtra : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      [
        new Cell(
          new Txt(`Dirección : ${data.cli_dirtra ? data.cli_dirtra : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`RUC : ${data.CLI_CODPRO ? data.CLI_CODPRO : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)

      ],
      [
        new Cell(
          new Txt(`Ubigeo :${data?.desubica_transpor}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Stack([
            new Txt('Comentarios').fontSize(9).bold().end,
            new Txt(data?.PCC_OBSPED).end,
          ]).end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt('It.').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Descripción').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Código').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Cantidad').fontSize(8).alignment('right').bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Precio').alignment('right').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Dscto.').alignment('right').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Valorizado').alignment('right').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end
      ],
      ...detalle
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([50, 155, 55, 55, 55, 60, 60]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').fontSize(8).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt('Valor venta:').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt(data?.PCC_VALVTA ? numberWithCommas(data?.PCC_VALVTA.toFixed(2)) : '0.00').fontSize(8).alignment('right').bold().end,
        ).end
      ],
      [
        new Cell(
          new Txt('').fontSize(8).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt('Importe IGV:').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt(data?.PCC_IMPIGV ? numberWithCommas(data?.PCC_IMPIGV.toFixed(2)) : '0.00').fontSize(8).alignment('right').bold().end,
        ).end
      ],
      [
        new Cell(
          new Txt('Son: ' + NumeroALetras(data?.PCC_IMPTOT)).fontSize(8).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt('Total:').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt(data?.PCC_IMPTOT ? numberWithCommas(data?.PCC_IMPTOT.toFixed(2)) : '0.00').fontSize(8).alignment('right').bold().end,
        ).end
      ]
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([385, 60, 80]).end,
  )

  pdf.add(
    pdf.ln(4)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end,
        new Cell(
          new Txt('\n\n\n\n').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end
      ],
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('').fontSize(8).alignment('center').end,
            new Txt('').fontSize(8).bold().alignment('center').end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end,
        new Cell(
          new Stack([
            new Txt('Aprobador').fontSize(8).bold().alignment('center').end
          ]).end
        ).border(topBorder).end,
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end
      ],
      [
        new Cell(
          new Txt('\n\n').end
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4)
      ],
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([90, 145, 40, 145, 90]).end
  )


  pdf.create().open();
}

export async function imprimirFormatoPedidoPdf(headers, data) {

  console.log('data', data)

  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalle = []

  data.detalle.forEach(element => {
    detalle.push(
      [
        new Cell(
          new Txt(element.PCD_CORPCD).fontSize(8).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.prd_desesp).fontSize(8).end,
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(8).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_CANSOL.toFixed(2)).alignment('right').fontSize(8).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_PREUNI.toFixed(2)).alignment('right').fontSize(8).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_PORDES.toFixed(2)).alignment('right').fontSize(8).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_IMPTOT.toFixed(2)).alignment('right').fontSize(8).end,
        ).border(noBorders).end
      ]

    )
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(logo).alignment('left').width(80).build(),
            pdf.ln(1),
          ]).end
        ).border(noBorders).end,
        // ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.CIA_NOMCIA).fontSize(9).bold().alignment('center').end,
            new Txt(`RUC: ${headers.CIA_NUMRUC}`).fontSize(9).alignment('center').end,
            new Txt(`${headers.CIA_DIRCIA}`).fontSize(9).alignment('center').end,
            pdf.ln(1),

          ]).alignment('center').end,
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            // new Columns([
            //   new Txt('Hora de impresión: ').fontSize(7).end,
            //   new Txt(obtenerHora()).alignment('right').fontSize(7).end
            // ]).end,
            // new Columns([
            //   new Txt('Fecha: ').fontSize(7).end,
            //   new Txt(formatDateWithDash(new Date())).alignment('right').fontSize(7).end
            // ]).end,
            // new Columns([
            //   new Txt('Página: ').fontSize(7).end,
            //   new Txt('1').alignment('right').fontSize(7).end
            // ]).end,
            // new Txt('______________').bold().end,
            new Txt(`Usuario : ${JSON.parse(localStorage.getItem('user')).usuariosistema}`).alignment('center').fontSize(7).end
          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt('PEDIDO DE VENTA').alignment('center').fontSize(10).bold().end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`PEDIDO N° : PV${data?.PCC_NUMPCC}`).fontSize(10).bold().end,
        ).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(data?.sdv_dessdv).bold().end
        ).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt(`Solicitante : ${data?.PCC_CODUSE}`).fontSize(9).bold().end,
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Fecha : ${formatDateWithDash(new Date(data?.PCC_FECCRE))}`).fontSize(9).bold().end,
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`Forma de Pago : ${data?.CPA_DESCRI}`).fontSize(9).bold().end,
        ).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt(`Vendedor : ${data?.VDE_NOMVDE}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt('INFORMACIÓN DEL CLIENTE').fontSize(10).alignment('center').bold().end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`Cliente : ${data?.cli_nomcli}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6),

      ],
      [
        new Cell(
          new Txt(`Dirección : ${data.cli_dircli ? data.cli_dircli : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`RUC : ${data?.CLI_CODCLI}`).fontSize(9).bold().end,
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`Ubigeo : ${data?.desubica_cliente}`).fontSize(9).bold().end,
        ).colSpan(4).end,
        ...obtenerNulls(3)
      ],
      [
        new Cell(
          new Txt(`Dirección de Despacho : ${data?.lde_deslar}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt('INFORMACIÓN DEL TRANSPORTISTA').fontSize(10).alignment('center').bold().end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`Nombre o Razón Social :${data.cli_nomtra ? data.cli_nomtra : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      [
        new Cell(
          new Txt(`Dirección : ${data.cli_dirtra ? data.cli_dirtra : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`RUC : ${data.CLI_CODPRO ? data.CLI_CODPRO : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)

      ],
      [
        new Cell(
          new Txt(`Ubigeo :${data?.desubica_transpor}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Stack([
            new Txt('Comentarios').fontSize(9).bold().end,
            new Txt(data?.PCC_OBSPED).end,
          ]).end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt('It.').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Descripción').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Modelo').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Cantidad').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Precio').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Dscto.').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Valorizado').fontSize(8).bold().end,
        ).end
      ],
      ...detalle
    ]).widths([50, 155, 55, 55, 55, 60, 60]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Son: ' + NumeroALetras(data?.PCC_IMPTOT)).fontSize(8).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt('Total:').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt(data?.PCC_IMPTOT ? data?.PCC_IMPTOT.toFixed(2) : '0.00').fontSize(8).alignment('right').bold().end,
        ).end
      ]
    ]).widths([385, 60, 80]).end,
  )

  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Columns([
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(2),
            new Txt(data?.S10_NOMUSU).fontSize(8).alignment('center').end,
            new Txt('Solicitante').fontSize(8).bold().alignment('center').end
          ]).end
        ]
      ]).widths(['*', 0]).end,
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(2),
            new Txt('Aprobador').fontSize(8).bold().alignment('center').end
          ]).end
        ]
      ]).widths(['*', 0]).end,
      // new Table([
      //   [
      //     pdf.ln(5),
      //   ],
      //   [
      //     new Stack([
      //       pdf.ln(2),
      //       new Txt('Aprobado').fontSize(8).bold().alignment('center').end
      //     ]).end
      //   ]
      // ]).widths([ '*', 0 ]).end
    ]).end
  )

  pdf.create().print();
}

export async function crearFormatoPedidoTicket(headers, data) {

  const pdf = new PdfMakeWrapper();
  pdf.pageSize('A7')

  pdf.pageMargins([10, 10, 10, 10]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalle = []

  data.detalle.forEach(element => {
    detalle.push(
      [
        new Cell(
          new Txt(element.PCD_CORPCD).fontSize(6).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.prd_desesp).fontSize(6).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_CANSOL.toFixed(2)).alignment('right').fontSize(6).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_PREUNI.toFixed(2)).alignment('right').fontSize(6).end,
        ).border(noBorders).end,

        new Cell(
          new Txt(element.PCD_IMPTOT.toFixed(2)).alignment('right').fontSize(6).end,
        ).border(noBorders).end
      ]

    )
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(logo).alignment('left').width(40).build(),
            pdf.ln(1),
          ]).end
        ).border(noBorders).end,

        new Cell(
          new Stack([
            new Txt(headers.CIA_NOMCIA).fontSize(7).bold().alignment('left').end,
            new Txt(`RUC: ${headers.CIA_NUMRUC}`).fontSize(7).alignment('left').end,
            new Txt(`${headers.CIA_DIRCIA}`).fontSize(7).alignment('left').end,
            pdf.ln(1),

          ]).alignment('center').end,
        ).border(noBorders).end,

      ],
    ]).end
  )

  pdf.add(
    new Table([

      [
        new Cell(
          new Txt(`Pedido de venta N°: PV${data?.PCC_NUMPCC}`).alignment('left').fontSize(6).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Solicitante: ${data?.PCC_CODUSE}`).fontSize(6).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt(`Fecha: ${formatDateWithScript(new Date(data?.PCC_FECCRE))}`).alignment('right').fontSize(6).bold().end,
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt(`Forma de Pago: ${data?.CPA_DESCRI}`).fontSize(6).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt(`Vendedor: ${data?.VDE_NOMVDE}`).fontSize(6).bold().alignment('right').end,
        ).border(noBorders).end,
      ],
      // [
      //   new Cell(
      //     new Txt('Información del cliente').fontSize(6).alignment('left').bold().end
      //   ).border(noBorders).colSpan(2).end,
      //   ...obtenerNulls(1)
      // ],
      [
        new Cell(
          new Txt(`Cliente: ${data?.cli_nomcli}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),

      ],

      [
        new Cell(
          new Txt(`RUC: ${data?.CLI_CODCLI}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Ubigeo: ${data?.desubica_cliente}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Dirección de Despacho: ${data?.lde_deslar}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Dirección: ${data.cli_dircli ? data.cli_dircli : ''}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],

    ]).widths([85, 85]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('It.').fontSize(7).bold().end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt('Descripción').fontSize(7).bold().end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt('Cantidad').fontSize(7).bold().end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt('Precio').fontSize(7).bold().end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt('Valorizado').fontSize(7).bold().end,
        ).border(bottomBorder).end
      ],
      ...detalle
    ]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Son: ' + NumeroALetras(data?.PCC_IMPTOT)).fontSize(6).bold().end,
        ).border(noBorders).end,

      ]
    ]).end,
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').fontSize(7).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt('Total:').fontSize(7).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt(data?.PCC_IMPTOT ? data?.PCC_IMPTOT.toFixed(2) : '0.00').fontSize(7).alignment('right').bold().end,
        ).border(noBorders).end
      ]
    ]).widths([110, 20, 30]).end
  )

  pdf.create().open();
}

export async function imprimirFormatoPedidoTicket(headers, data) {

  const pdf = new PdfMakeWrapper();
  pdf.pageSize('A7')

  pdf.pageMargins([10, 10, 10, 10]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalle = []

  data.detalle.forEach(element => {
    detalle.push(
      [
        new Cell(
          new Txt(element.PCD_CORPCD).fontSize(6).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.prd_desesp).fontSize(6).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_CANSOL.toFixed(2)).alignment('right').fontSize(6).end,
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_PREUNI.toFixed(2)).alignment('right').fontSize(6).end,
        ).border(noBorders).end,

        new Cell(
          new Txt(element.PCD_IMPTOT.toFixed(2)).alignment('right').fontSize(6).end,
        ).border(noBorders).end
      ]

    )
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(logo).alignment('left').width(40).build(),
            pdf.ln(1),
          ]).end
        ).border(noBorders).end,

        new Cell(
          new Stack([
            new Txt(headers.CIA_NOMCIA).fontSize(7).bold().alignment('left').end,
            new Txt(`RUC: ${headers.CIA_NUMRUC}`).fontSize(7).alignment('left').end,
            new Txt(`${headers.CIA_DIRCIA}`).fontSize(7).alignment('left').end,
            pdf.ln(1),

          ]).alignment('center').end,
        ).border(noBorders).end,

      ],
    ]).end
  )

  pdf.add(
    new Table([

      [
        new Cell(
          new Txt(`Pedido de venta N°: PV${data?.PCC_NUMPCC}`).alignment('left').fontSize(6).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Solicitante: ${data?.PCC_CODUSE}`).fontSize(6).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt(`Fecha: ${formatDateWithScript(new Date(data?.PCC_FECCRE))}`).alignment('right').fontSize(6).bold().end,
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt(`Forma de Pago: ${data?.CPA_DESCRI}`).fontSize(6).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt(`Vendedor: ${data?.VDE_NOMVDE}`).fontSize(6).bold().alignment('right').end,
        ).border(noBorders).end,
      ],
      // [
      //   new Cell(
      //     new Txt('Información del cliente').fontSize(6).alignment('left').bold().end
      //   ).border(noBorders).colSpan(2).end,
      //   ...obtenerNulls(1)
      // ],
      [
        new Cell(
          new Txt(`Cliente: ${data?.cli_nomcli}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),

      ],

      [
        new Cell(
          new Txt(`RUC: ${data?.CLI_CODCLI}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Ubigeo: ${data?.desubica_cliente}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Dirección de Despacho: ${data?.lde_deslar}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Dirección: ${data.cli_dircli ? data.cli_dircli : ''}`).fontSize(6).bold().end,
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],

    ]).widths([85, 85]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('It.').fontSize(7).bold().end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt('Descripción').fontSize(7).bold().end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt('Cantidad').fontSize(7).bold().end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt('Precio').fontSize(7).bold().end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt('Valorizado').fontSize(7).bold().end,
        ).border(bottomBorder).end
      ],
      ...detalle
    ]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Son: ' + NumeroALetras(data?.PCC_IMPTOT)).fontSize(6).bold().end,
        ).border(noBorders).end,

      ]
    ]).end,
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').fontSize(7).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt('Total:').fontSize(7).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt(data?.PCC_IMPTOT ? data?.PCC_IMPTOT.toFixed(2) : '0.00').fontSize(7).alignment('right').bold().end,
        ).border(noBorders).end
      ]
    ]).widths([110, 20, 30]).end
  )

  pdf.create().print();
}

/**
 * Rerporte de Pedido de Venta
 * @param headers
 * @param pedido
 */
export async function crearPedidoVentaPdf(headers, pedido: OrderPdf) {

  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(pedido.CIA_NOMCIA).fontSize(11).bold().alignment('center').end,
            pdf.ln(1),
            new Txt(`RUC: ${pedido.CIA_NUMRUC} ${pedido.CIA_DIRCIA}`).fontSize(10).alignment('center').end

          ]).alignment('center').end,
        ).end,

        new Cell(
          new Stack([
            new Txt('______________________').bold().end,
            new Columns([
              new Txt('Hora de impresión: ').fontSize(7).end,
              new Txt(obtenerHora()).alignment('right').fontSize(7).end
            ]).end,
            pdf.ln(1),
            new Columns([
              new Txt('Fecha: ').fontSize(7).end,
              new Txt(formatDateWithDash(new Date())).alignment('right').fontSize(7).end
            ]).end,
            pdf.ln(1),
            new Columns([
              new Txt('Páginas: ').fontSize(7).end,
              new Txt('1').alignment('right').fontSize(7).end
            ]).end,
            new Txt('______________________').bold().end,
            new Txt(`Usuario : ${JSON.parse(localStorage.getItem('user')).usuariosistema}`).alignment('center').fontSize(7).end
          ]).end
        ).end
      ],
      [
        new Cell(
          new Txt('PEDIDO DE VENTA').alignment('center').bold().end
        ).colSpan(2).width(500).end
      ],
      [
        new Columns(
          [
            new Txt(`PEDIDO N° : PV${pedido.PCC_NUMPCC}`).bold().end,
            new Txt(pedido.sdv_dessdv).bold().end
          ]
        ).end

      ],
      [
        new Columns([
          new Table([
            [
              new Txt('Solicitante :').fontSize(10).bold().end,
              new Txt(pedido.PCC_CODUSE).fontSize(10).end
            ]
          ]).widths([55, 80]).layout('noBorders').end,
          new Table([
            [
              new Txt('Fecha :').fontSize(10).bold().end,
              new Txt(formatDateWithDash(new Date(pedido.PCC_FECCRE))).fontSize(10).end
            ]
          ]).widths([35, 80]).layout('noBorders').end,
          new Table([
            [
              new Txt('Forma de Pago :').fontSize(10).bold().end,
              new Txt(pedido.CPA_DESCRI).fontSize(10).end
            ]
          ]).widths([75, 60]).layout('noBorders').end,
        ]).end
      ],
      [
        new Columns([
          new Table([[
            new Txt('Vendedor :').fontSize(10).bold().end,
            new Txt(pedido.VDE_NOMVDE).fontSize(10).end
          ]]).widths([55, 440]).layout('noBorders').end
        ]).end,
      ],
      [
        new Txt('INFORMACIÓN DEL CLIENTE').alignment('center').bold().end
      ],
      [
        new Stack([
          new Columns([
            new Table([[
              new Txt('Cliente :').fontSize(10).bold().end,
              new Txt(pedido.cli_nomcli).fontSize(10).end
            ]]).widths([55, 420]).layout('noBorders').end

          ]).end,
          new Columns([
            new Table([[
              new Txt('Dirección :').fontSize(10).bold().end,
              new Txt(pedido.cli_dircli).fontSize(10).end
            ]]).widths([55, 420]).layout('noBorders').end
          ]).end,
          new Columns([
            new Table([[
              new Txt('RUC :').fontSize(10).bold().end,
              new Txt(pedido.CLI_CODCLI).fontSize(10).end
            ]]).widths([55, 165]).layout('noBorders').end,
            new Table([[
              new Txt('Ubigeo :').fontSize(10).bold().end,
              new Txt(pedido.desubica_cliente).fontSize(10).end
            ]]).widths([45, 175]).layout('noBorders').end,

          ]).end,
          new Table([[
            new Txt('Dirección de Despacho :').fontSize(10).bold().end,
            new Txt(pedido.lde_deslar).fontSize(10).end
          ]]).widths([110, 370]).layout('noBorders').end,
        ]).end
      ],
      [
        new Txt('INFORMACIÓN DEL TRANSPORTISTA').alignment('center').bold().end
      ],
      [
        new Stack([
          new Columns([
            new Table([[
              new Txt('Nombre o Razón Social :').fontSize(10).bold().end,
              new Txt(pedido.cli_nomtra).fontSize(10).end
            ]]).widths([55, 420]).layout('noBorders').end,
          ]).end,
          new Columns([
            new Table([[
              new Txt('Dirección :').fontSize(10).bold().end,
              new Txt(pedido.cli_dirtra).fontSize(10).end
            ]]).widths([55, 420]).layout('noBorders').end
          ]).end,
          new Columns([
            new Table([[
              new Txt('RUC :').fontSize(10).bold().end,
              new Txt(pedido.CLI_CODPRO).fontSize(10).end
            ]]).widths([55, 165]).layout('noBorders').end,
            new Table([[
              new Txt('Ubigeo :').fontSize(10).bold().end,
              new Txt(pedido.desubica_transpor).fontSize(10).end
            ]]).widths([45, 175]).layout('noBorders').end,

          ]).end,
        ]).end
      ],
      [
        new Stack([
          new Table([[
            new Txt('Comentarios').fontSize(10).bold().end,
          ]]).layout('noBorders').end,
          new Txt(pedido.PCC_OBSPED).end,
          pdf.ln(2)
        ]).end
      ],
      [new Columns([
        new Txt('It.').fontSize(9).bold().end,
        new Txt('Descripción').fontSize(9).bold().end,
        new Txt('Modelo').fontSize(9).bold().end,
        new Txt('Cantidad').fontSize(9).bold().end,
        new Txt('Precio').fontSize(9).bold().end,
        new Txt('Dscto.').fontSize(9).bold().end,
        new Txt('Valorizado').fontSize(9).bold().end,
      ]).end]
    ]).end
  )

  pedido.detalle.forEach(element => {
    pdf.add(
      new Columns([
        new Txt(element.PCD_CORPCD).fontSize(9).end,
        new Txt(element.prd_desesp).fontSize(9).end,
        new Txt(element.PRD_CODPRD).fontSize(9).end,
        new Txt(element.PCD_CANSOL).fontSize(9).end,
        new Txt(numberWithCommas(element.PCD_PRUIGV.toFixed(2))).fontSize(9).end,
        new Txt(numberWithCommas(element.PCD_IMPDES.toFixed(2))).fontSize(9).end,
        new Txt(numberWithCommas(element.PCD_IMPTOT.toFixed(2))).fontSize(9).end,
      ]).end
    )
  });

  pdf.add(
    new Canvas([
      new Line([0, 10], [510, 10]).end
    ]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([[
      new Txt('Son: ' + NumeroALetras(pedido.PCC_IMPTOT)).fontSize(10).bold().end,
      new Table([
        [
          new Txt('Total:').fontSize(10).bold().end,
          new Txt(numberWithCommas(pedido.PCC_IMPTOT.toFixed(2))).fontSize(10).alignment('right').bold().end,
        ],
      ]).widths([60, 100]).end
    ]]).widths([320, 150]).layout('noBorders').end,
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [''],
    ]).widths(['*', 0]).end
  )

  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Columns([
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(1),
            new Txt(pedido.S10_NOMUSU).fontSize(10).alignment('center').end,
            new Txt('Solicitante').fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths(['*', 0]).end,
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(2),
            new Txt('Visto Bueno').fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths(['*', 0]).end,
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(2),
            new Txt('Aprobado').fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths(['*', 0]).end
    ]).end
  )

  pdf.create().open();
}

/**
 * Reporte de Pedido de Venta
 * @param headers
 * @param pedido
 */
export async function generarFormatoCotizacion(headers, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalle = []

  data.detalle.forEach(element => {
    detalle.push(
      [
        new Cell(
          new Txt(element.PCD_CORPCD).fontSize(8).end,
        ).border(openRightBorder).end,
        new Cell(
          new Txt(element.prd_desesp).fontSize(8).end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('').fontSize(8).end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(element.PCD_CANSOL.toFixed(2)).alignment('right').fontSize(8).end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(element.PCD_PREUNI.toFixed(2)).alignment('right').fontSize(8).end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(element.PCD_PORDES.toFixed(2)).alignment('right').fontSize(8).end,
        ).border(horizontalBorder).end,
        new Cell(
          new Txt(element.PCD_IMPTOT.toFixed(2)).alignment('right').fontSize(8).end,
        ).border(openLeftBorder).end
      ]

    )
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(logo).alignment('left').width(80).build(),
            pdf.ln(1),
          ]).end
        ).border(noBorders).end,
        // ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.CIA_NOMCIA).fontSize(9).bold().alignment('center').end,
            new Txt(`RUC: ${headers.CIA_NUMRUC}`).fontSize(9).alignment('center').end,
            new Txt(`${headers.CIA_DIRCIA}`).fontSize(9).alignment('center').end,
            pdf.ln(1),

          ]).alignment('center').end,
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            // new Columns([
            //   new Txt('Hora de impresión: ').fontSize(7).end,
            //   new Txt(obtenerHora()).alignment('right').fontSize(7).end
            // ]).end,
            // new Columns([
            //   new Txt('Fecha: ').fontSize(7).end,
            //   new Txt(formatDateWithDash(new Date())).alignment('right').fontSize(7).end
            // ]).end,
            // new Columns([
            //   new Txt('Página: ').fontSize(7).end,
            //   new Txt('1').alignment('right').fontSize(7).end
            // ]).end,
            // new Txt('______________').bold().end,
            new Txt(`Usuario : ${JSON.parse(localStorage.getItem('user')).usuariosistema}`).alignment('center').fontSize(7).end
          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt('COTIZACIÓN').color('#6b6d7e').alignment('center').fontSize(10).bold().end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`COTIZACIÓN N° : PV${data?.PCC_NUMPCC}`).fontSize(10).bold().end,
        ).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(data?.sdv_dessdv).bold().end
        ).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt(`Solicitante : ${data?.PCC_CODUSE}`).fontSize(9).bold().end,
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Fecha : ${formatDateWithDash(new Date(data?.PCC_FECCRE))}`).fontSize(9).bold().end,
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`Forma de Pago : ${data?.CPA_DESCRI}`).fontSize(9).bold().end,
        ).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt(`Vendedor : ${data.VDE_NOMVDE ? data.VDE_NOMVDE : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt('INFORMACIÓN DEL CLIENTE').fontSize(10).alignment('center').bold().end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`Cliente : ${data.cli_nomcli ? data.cli_nomcli : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6),

      ],
      [
        new Cell(
          new Txt(`Dirección : ${data.cli_dircli ? data.cli_dircli : ''}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(`RUC : ${data?.CLI_CODCLI}`).fontSize(9).bold().end,
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`Ubigeo : ${data.desubica_cliente ? data.desubica_cliente : ''}`).fontSize(9).bold().end,
        ).colSpan(4).end,
        ...obtenerNulls(3)
      ],
      [
        new Cell(
          new Txt(`Dirección de Despacho : ${data?.lde_deslar}`).fontSize(9).bold().end,
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      // [
      //   new Cell(
      //     new Txt('INFORMACIÓN DEL TRANSPORTISTA').fontSize(10).alignment('center').bold().end
      //   ).colSpan(7).end,
      //   ...obtenerNulls(6)
      // ],
      // [
      //   new Cell(
      //     new Txt(`Cliente :${data?.cli_nomtra}`).fontSize(9).bold().end,
      //   ).colSpan(7).end,
      //   ...obtenerNulls(6),
      // ],
      // [
      //   new Cell(
      //     new Txt(`Dirección : ${data?.cli_dirtra}`).fontSize(9).bold().end,
      //   ).colSpan(7).end,
      //   ...obtenerNulls(6)
      // ],
      // [
      //   new Cell(
      //     new Txt(`RUC : ${data?.CLI_CODPRO}`).fontSize(9).bold().end,
      //   ).colSpan(7).end,
      //   ...obtenerNulls(6)

      // ],
      // [
      //   new Cell(
      //     new Txt(`Ubigeo :${data?.desubica_transpor}`).fontSize(9).bold().end,
      //   ).colSpan(7).end,
      //   ...obtenerNulls(6)
      // ],
      [
        new Cell(
          new Stack([
            new Txt('Comentarios').fontSize(9).bold().end,
            new Txt(data?.PCC_OBSPED).end,
          ]).end
        ).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt('It.').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Descripción').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Modelo').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Cantidad').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Precio').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Dscto.').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Valorizado').fontSize(8).bold().end,
        ).end
      ],
      ...detalle
    ]).widths([50, 155, 55, 55, 55, 60, 60]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Son: ' + NumeroALetras(data?.PCC_IMPTOT)).fontSize(8).bold().end,
        ).border(noBorders).end,
        new Cell(
          new Txt('Total:').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt(data?.PCC_IMPTOT ? data?.PCC_IMPTOT.toFixed(2) : '0.00').fontSize(8).alignment('right').bold().end,
        ).end
      ]
    ]).widths([385, 60, 80]).end,
  )

  pdf.add(
    pdf.ln(2)
  )

  // pdf.add(
  //   new Columns([
  //     new Table([
  //       [
  //         pdf.ln(5),
  //       ],
  //       [
  //         new Stack([
  //           pdf.ln(2),
  //           new Txt(data?.S10_NOMUSU).fontSize(8).alignment('center').end,
  //           new Txt('Solicitante').fontSize(8).bold().alignment('center').end
  //         ]).end
  //       ]
  //     ]).widths([ '*', 0 ]).end,
  //     new Table([
  //       [
  //         pdf.ln(5),
  //       ],
  //       [
  //         new Stack([
  //           pdf.ln(2),
  //           new Txt('Visto Bueno').fontSize(8).bold().alignment('center').end
  //         ]).end
  //       ]
  //     ]).widths([ '*', 0 ]).end,
  //     new Table([
  //       [
  //         pdf.ln(5),
  //       ],
  //       [
  //         new Stack([
  //           pdf.ln(2),
  //           new Txt('Aprobado').fontSize(8).bold().alignment('center').end
  //         ]).end
  //       ]
  //     ]).widths([ '*', 0 ]).end
  //   ]).end
  // )

  pdf.create().open();
}

/**
 * Generar PDF Monitoreo
 * @param headers
 * @param data
 */
export async function generarPdfMonitoreo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([40, 40, 40, 40]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            await new Img(logo).alignment('left').width(200).build(),
          ]).end
        ).end,

        new Cell(
          new Stack([
            new Txt(`Calle Carlos Gutierrez Noriega Nro 185`).fontSize(9).alignment('left').color('#142762').end,
            new Txt(`Urb. México - La Victoria - Lima - Lima`).fontSize(9).alignment('left').color('#142762').end,
            new Txt(`Telef: 233-234343 / 323-846352`).fontSize(9).alignment('left').color('#142762').end,
            new Txt(`Entel: 976355373`).fontSize(9).alignment('left').color('#142762').end,
            new Txt(`RPC: 983665544 / 965242422`).fontSize(9).alignment('left').color('#142762').end,
            new Txt(`RPC: 983665544`).fontSize(9).alignment('left').color('#142762').end,
            new Txt('').end,
            new Txt(`oleohidraulicservice@service.com`).fontSize(9).alignment('left').bold().color('#142762').end,
            new Txt(`ventas@oleohidraulic.com`).fontSize(9).alignment('left').bold().color('#142762').end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Table([
          [
            new Cell(
              new Stack([
                new Txt('RUC N° 20522747298').fontSize(11).alignment('center').bold().end,
              ]).end
            ).end,
          ],
          [
            new Cell(
              new Stack([
                new Txt('FACTURA').bold().fontSize(11).alignment('center').end,
                new Txt('ELECTRÓNICA').bold().fontSize(11).alignment('center').end,
              ]).end
            ).end

          ],
          [
            new Cell(
              new Stack([
                new Txt('F001-0000001263').bold().fontSize(11).alignment('center').end,
              ]).end
            ).end
          ]
        ]).end
      ]
    ]).layout('noBorders').widths([200, 160, 5, 120]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(`Especialistas en Sistemas Oleodráulicos en Maquinaria Minera e Industrial`).fontSize(9).alignment('center').color('#142762').bold().end,
            new Txt(`Suministro de Repuestos, Reparación - Mantenimiento de Bombas, Válvulas, Electroválvula y cilindros`).fontSize(9).alignment('center').color('#142762').bold().end,

          ]).alignment('center').end,
        ).end,
      ],
    ]).layout('noBorders').widths([400, 5]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Nombre/Razon Social:`).fontSize(7).alignment('left').bold().end,
                new Txt(`B BRAUN MEDICAL PERU S.A.`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([71, 180]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Ruc:`).fontSize(7).alignment('right').bold().end,
                new Txt(`20515252522`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([28, 80]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Fecha Emisión:`).fontSize(7).alignment('right').bold().end,
                new Txt(`05/04/2021`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([63, 50]).end,

          ]).alignment('center').end,
        ).end,
      ],
      [
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Dirección:`).fontSize(7).alignment('left').bold().end,
                new Txt(`AV. SEPARADORA INDUSTRIAL NRO. 867 URB. MIGUEL GRAU ATE`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([33, 210]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Moneda:`).fontSize(7).alignment('right').bold().end,
                new Txt(`DOLARES`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([28, 80]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Fecha Vencimiento:`).fontSize(7).alignment('right').bold().end,
                new Txt(`05/04/2021`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([63, 50]).end,

          ]).alignment('center').end,
        ).end,
      ],

    ]).widths([295, 80, 110]).layout('noBorders').end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Guía Remisión:`).fontSize(7).alignment('left').bold().end,
                new Txt(`0001-6512`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([50, 50]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Orden Compra:`).fontSize(7).alignment('left').bold().end,
                new Txt(`YCS/4500326546`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([50, 80]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Forma de Pago:`).fontSize(7).alignment('left').bold().end,
                new Txt(`FACTURA A 30 DÍAS`).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([50, 75]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Pedido Venta:`).fontSize(7).alignment('right').bold().end,
                new Txt(``).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([44, 80]).end,

          ]).alignment('center').end,
        ).end,
      ],
    ]).widths([125, 140, 120, 90]).layout('noBorders').end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Item').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Código').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Descripción').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('U.M.').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Cantidad').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('V. Unitario').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Descuento').fontSize(8).bold().end,
        ).end,
        new Cell(
          new Txt('Valor Total').fontSize(8).bold().end,
        ).end,
      ],
      [
        new Cell(
          new Txt('1').fontSize(8).end,
        ).end,
        new Cell(
          new Txt('S0003').fontSize(8).end,
        ).end,
        new Cell(
          new Txt('SERVICIO DE MANTENIMIENTO DE CILINDROS HIDRAULICAS DE LA ENVASADORA SAM25-1').fontSize(8).end,
        ).end,
        new Cell(
          new Txt('ZZ').fontSize(8).end,
        ).end,
        new Cell(
          new Txt('1.00').fontSize(8).end,
        ).end,
        new Cell(
          new Txt('2500.00').fontSize(8).end,
        ).end,
        new Cell(
          new Txt('').fontSize(8).end,
        ).end,
        new Cell(
          new Txt('2500.00').fontSize(8).end,
        ).end,
      ],
    ]).widths([20, 30, 200, 20, 35, 40, 40, 45]).end
  )

  pdf.ln()

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(`SON: DOS MIL NOVECIENTOS CINCUENTA Y 00 / 100 DOLARES AMERICANOS`).fontSize(8).alignment('left').bold().end,
            pdf.ln(1),
            new Txt(`OPERACIÓN SUJETA AL SISTEMA DE PAGO DE OBLIGACIONES TRIBUTARIAS CON EL GOBIERNO CENTRAL DETRACCION 12.00% CUENTA CTE. BANCO DE LA NACION MN NRO 00-002-020580`).fontSize(8).alignment('left').bold().end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('Op. Gravada').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('USD').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('2500.00').alignment('right').fontSize(8).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('Op. Exonerada').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('USD').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('0.00').alignment('right').fontSize(8).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('Op. Inafecta').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('USD').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('0.00').alignment('right').fontSize(8).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('Op. Gratuita').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('USD').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('0.00').alignment('right').fontSize(8).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('I.G.V.').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('USD').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('450.00').alignment('right').fontSize(8).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('IMPORTE TOTAL').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('USD').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('2950.00').alignment('right').fontSize(8).bold().end
              ).end
            ]
          ]).layout('noBorders').widths([80, 40, 40]).end,
        ).end,
      ],
    ]).layout('noBorders').widths([300, 186]).end
  )

  pdf.add(
    pdf.ln(15)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Stack([
            new Txt('Código de seguridad (Hash): ').fontSize(8).end,
            new Txt('Fl+iR2AkQSzUKOD1d9gnLDS+rAE=').fontSize(8).end,
            pdf.ln(2),
          ]).end
        ).end,
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('Para abonar el concepto de facturas, utilice los canales de pago: Depósito en efectivo o Transferencia Bancaria.').fontSize(8).end,
                  pdf.ln(1),
                  new Txt('BANCO CREDITO').fontSize(8).bold().end,
                  new Txt('SOLES').fontSize(8).end,
                  new Txt('194 2522497 0 66').fontSize(8).end,
                ]).end
              ).end
            ]
          ]).end
        ).end,
        new Cell(
          new Stack([
            new QR('my code').fit(100).alignment('center').end,
            pdf.ln(1),
            new Txt('Este documento puede ser validado en').alignment('center').fontSize(8).end,
            new Txt('www.pavsofact.com').alignment('center').fontSize(8).end,
            pdf.ln(1),
            new Txt('Representación impresa de la Factura Electrónica').fontSize(8).alignment('center').end,
            new Txt('Autorizado a ser emisor electrónico mediante R.I. SUNAT').fontSize(8).alignment('center').end,
            new Txt('N° 155-2017 / Anexo IV').fontSize(8).alignment('center').end
          ]).end
        ).end
      ]
    ]).layout('noBorders').widths([300, 180]).end
  )

  pdf.create().open();
}

/**
 * Generar PDF Voucher
 * @param headers
 * @param data
 */
export async function generarPdfVoucher(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([30, 30, 30, 30]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(headers.logo).alignment('left').width(100).build(),
          ]).end
        ).end,

        new Cell(
          new Stack([
            new Txt(headers.razonSocial).alignment('center').bold().end,
            // new Txt(`Soluciones para tu Organización.`).fontSize(8).bold().alignment('center').end,
            new Txt(headers.dircia).fontSize(8).alignment('center').end,
            // new Txt(`Urb. México - La Victoria - Lima - Lima`).fontSize(8).alignment('left').end,

            new Txt(`Telef: 233-234343 / 323-846352`).fontSize(8).alignment('center').end,

            new Txt(`pavsolutions.com`).fontSize(8).alignment('center').bold().end,


          ]).alignment('center').end,
        ).end,
        new Cell(
          new Txt('').end
        ).end,
        new Table([
          [
            new Cell(
              new Stack([
                new Txt(`RUC N° ${headers.ruc}`).fontSize(11).alignment('center').bold().end,
              ]).end
            ).end,
          ],
          [
            new Cell(
              new Stack([
                new Txt('FACTURA').bold().fontSize(11).alignment('center').color('white').end,
                new Txt('ELECTRÓNICA').bold().fontSize(11).alignment('center').color('white').end,
              ]).end
            ).fillColor('#919090').end

          ],
          [
            new Cell(
              new Stack([
                new Txt(data.cco_numdoc).bold().fontSize(11).alignment('center').end,
              ]).end
            ).end
          ]
        ]).layout({
          hLineColor: (i, node) => {
            return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
          },
          vLineColor: (i, node) => {
            return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
          },
          hLineWidth: (i, node) => {
            return 1;
          },
          vLineWidth: (i, node) => {
            return 1;
          }
        }).widths([140]).end
      ]
    ]).layout('noBorders').widths([110, 245, 5, 140]).end
  )

  // pdf.add(
  //   new Table([
  //     [
  //       new Cell(
  //         new Stack([
  //           new Txt(`Especialistas en Sistemas Oleodráulicos en Maquinaria Minera e Industrial`).fontSize(8).alignment('center').bold().end,
  //           new Txt(`Suministro de Repuestos, Reparación - Mantenimiento de Bombas, Válvulas, Electroválvula y cilindros`).fontSize(8).alignment('center').bold().end,

  //         ]).alignment('center').end,
  //       ).end,
  //     ],
  //   ]).layout('noBorders').widths([400, 5]).end
  // )
  pdf.ln(2);

  pdf.add(
    new Table([
      [

        new Cell(
          new Stack([
            new Txt('\n').end,
            new Table([
              [
                new Txt(`Nombre/Razon Social:`).fontSize(7).alignment('left').bold().end,
                new Txt(data.cli_nomcli).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([71, 180]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([
            new Txt('\n').end,
            new Table([
              [
                new Txt(`Ruc:`).fontSize(7).alignment('right').bold().end,
                new Txt(data.cli_codcli).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([28, 80]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([
            new Txt('\n').end,
            new Table([
              [
                new Txt(`Fecha Emisión:`).fontSize(7).alignment('right').bold().end,
                new Txt(data.cco_fecemi).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([69, 50]).end,

          ]).alignment('center').end,
        ).end,
      ],
      [
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Dirección:`).fontSize(7).alignment('left').bold().end,
                new Txt(data.cli_dircli).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([33, 210]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Moneda:`).fontSize(7).alignment('right').bold().end,
                new Txt(data.tmo_descri).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([28, 80]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Fecha Vencimiento:`).fontSize(7).alignment('right').bold().end,
                new Txt(data.cco_fecven).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([68, 50]).end,

          ]).alignment('center').end,
        ).end,
      ],

    ]).widths([315, 80, 110]).layout('noBorders').end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Guía Remisión:`).fontSize(7).alignment('left').bold().end,
                new Txt(data.cco_numgui).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([50, 50]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Orden Compra:`).fontSize(7).alignment('left').bold().end,
                new Txt(data.dcc_ordcom).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([50, 80]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([

            new Table([
              [
                new Txt(`Forma de Pago:`).fontSize(7).alignment('left').bold().end,
                new Txt(data.cpa_descri).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([50, 75]).end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([
            new Table([
              [
                new Txt(`Pedido Venta:`).fontSize(7).alignment('right').bold().end,
                new Txt(data.pcc_numpcc).fontSize(7).alignment('left').end,
              ]
            ]).layout('noBorders').widths([50, 110]).end,

          ]).alignment('center').end,
        ).end,
      ],
    ]).widths([125, 145, 140, 150]).layout('noBorders').end
  )

  pdf.add(
    pdf.ln(1)
  )

  let detalle = [];
  data.detalle_dcd.forEach((item, index) => {
    detalle.push([
      new Cell(
        new Txt(index + 1).fontSize(7).end,
      ).end,
      new Cell(
        new Txt(item.PRD_CODPRD).fontSize(7).end,
      ).end,
      new Cell(
        new Txt('SERVICIO DE MANTENIMIENTO DE CILINDROS HIDRAULICAS DE LA ENVASADORA SAM25-1').fontSize(7).end,
      ).end,
      new Cell(
        new Txt(item.UME_CODVEN).fontSize(7).end,
      ).end,
      new Cell(
        new Txt(item.DCD_CANDCD.toFixed(2)).fontSize(7).alignment('right').end,
      ).end,
      new Cell(
        new Txt(item.DCD_PREUNI.toFixed(2)).fontSize(7).alignment('right').end,
      ).end,
      new Cell(
        new Txt('0.00').fontSize(7).end,
      ).end,
      new Cell(
        new Txt(item.DCD_IMPBRU.toFixed(2)).fontSize(7).alignment('right').end,
      ).end,
    ])
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('Item').fontSize(7).bold().end,
        ).fillColor('#b4b4b4').end,
        new Cell(
          new Txt('Código').fontSize(7).bold().end,
        ).fillColor('#b4b4b4').end,
        new Cell(
          new Txt('Descripción').fontSize(7).bold().end,
        ).fillColor('#b4b4b4').end,
        new Cell(
          new Txt('U.M.').fontSize(7).bold().end,
        ).fillColor('#b4b4b4').end,
        new Cell(
          new Txt('Cantidad').fontSize(7).bold().end,
        ).fillColor('#b4b4b4').end,
        new Cell(
          new Txt('V. Unitario').fontSize(7).bold().end,
        ).fillColor('#b4b4b4').end,
        new Cell(
          new Txt('Descuento').fontSize(7).bold().end,
        ).fillColor('#b4b4b4').end,
        new Cell(
          new Txt('Valor Total').fontSize(7).bold().end,
        ).fillColor('#b4b4b4').end,
      ],
      ...detalle
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([20, 30, 235, 20, 35, 40, 40, 45]).end
  )

  pdf.ln()

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(`SON: ${ NumeroALetras(data.CCO_IMPAFE) } Y 00 / 100 ${data.TMO_CODTMO == 'SO' ? 'SOLES': 'DÓLARES AMERICANOS'}`).fontSize(7).alignment('left').bold().end,
            pdf.ln(1),
            // new Txt(`OPERACIÓN SUJETA AL SISTEMA DE PAGO DE OBLIGACIONES TRIBUTARIAS CON EL GOBIERNO CENTRAL DETRACCION 12.00% CUENTA CTE. BANCO DE LA NACION MN NRO 00-002-020580`).fontSize(7).alignment('left').bold().end,

          ]).alignment('center').end,
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('Op. Gravada').alignment('right').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt(data.CCO_IMPAFE.toFixed(2)).alignment('right').fontSize(7).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('Op. Exonerada').alignment('right').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt(data.cco_impexo.toFixed(2)).alignment('right').fontSize(7).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('Op. Inafecta').alignment('right').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt(data.CCO_IMPINA.toFixed(2)).alignment('right').fontSize(7).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('Op. Gratuita').alignment('right').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt(data.cco_impotc.toFixed(2)).alignment('right').fontSize(7).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('I.G.V.').alignment('right').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt(data.CCO_IMPIGV.toFixed(2)).alignment('right').fontSize(7).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('IMPORTE TOTAL').alignment('right').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(7).bold().end
              ).end,
              new Cell(
                new Txt(data.CCO_IMPDOC.toFixed(2)).alignment('right').fontSize(7).bold().end
              ).end
            ]
          ]).layout('noBorders').widths([80, 35, 55]).end,
        ).end,
      ],
    ]).layout('noBorders').widths([340, 186]).end
  )

  pdf.add(
    pdf.ln(4)
  )

  if(data.cuotas_ccu.length > 0) {
    pdf.add(
      new Table([
        [
          new Cell(
            new Txt('DETALLE PAGO:').bold().fontSize(8).end
          ).fillColor('#b4b4b4').colSpan(3).end,
          ...obtenerNulls(2)
        ],
        [
          new Cell(
            new Txt('CUOTAS').bold().fontSize(8).end
          ).fillColor('#b4b4b4').end,
          new Cell(
            new Txt('IMPORTE DE CUOTA').bold().fontSize(8).end
          ).fillColor('#b4b4b4').end,
          new Cell(
            new Txt('FECHA DE PAGO').bold().fontSize(8).end
          ).fillColor('#b4b4b4').end,

        ],
        [
          new Cell(
            new Txt('CUOTA 001').fontSize(7).end
          ).end,
          new Cell(
            new Txt('20.77').fontSize(7).end
          ).end,
          new Cell(
            new Txt('03/01/2024').fontSize(7).end
          ).end,
        ],
      ]).layout({
        hLineColor: (i, node) => {
          return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
        },
        vLineColor: (i, node) => {
          return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
        },
        hLineWidth: (i, node) => {
          return 1;
        },
        vLineWidth: (i, node) => {
          return 1;
        }
      }).end
    )
  }


  pdf.add(
    pdf.ln(15)
  )



  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).end,
        new Cell(
          new Stack([
            new Txt('Código de seguridad (hash): ').alignment('center').fontSize(8).end,
            new Txt('Fl+iR2AkQSzUKOD1d9gnLDS+rAE=').alignment('center').fontSize(8).end,
            pdf.ln(1),
          ]).end
        ).end,
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('Para abonar el concepto de facturas, utilice los canales de pago: Depósito en efectivo o Transferencia Bancaria.').fontSize(8).end,
                  pdf.ln(1),
                  new Txt('BANCO CREDITO').fontSize(8).bold().end,
                  new Txt('SOLES').fontSize(8).end,
                  new Txt('1942522497066').fontSize(8).end,
                ]).end
              ).end
            ]
          ]).layout({
            hLineColor: (i, node) => {
              return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
            },
            vLineColor: (i, node) => {
              return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
            },
            hLineWidth: (i, node) => {
              return 1;
            },
            vLineWidth: (i, node) => {
              return 1;
            }
          }).end
        ).end,
        new Cell(
          new Stack([
            new QR('my code').fit(100).alignment('center').end,
            pdf.ln(1),
            // new Txt('Este documento puede ser validado en').alignment('center').fontSize(8).end,
            // new Txt('www.pavsofact.com').alignment('center').fontSize(8).end,
            pdf.ln(1),
            new Txt('Representación impresa de la Factura Electrónica').fontSize(8).alignment('center').end,
            new Txt('Autorizado a ser emisor electrónico mediante R.I. SUNAT').fontSize(8).alignment('center').end,
            // new Txt('N° 155-2017 / Anexo IV').fontSize(8).alignment('center').end
          ]).end
        ).end
      ]
    ]).layout('noBorders').widths([330, 200]).end
  )

  pdf.create().open();
}

/**
 * Historial de pedidos
 * @param headers
 * @param data
 * @param otherData
 */
export function generarReporteHistorialPedidos(headers, data, otherData) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  const detalle = data.map(element => {
    return [
      new Cell(
        new Txt(element.cli_nomcli).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.TDO_CODTDO).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.CCO_NUMDOC).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.CCO_FECEMI).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.TMO_DESLAR).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.pcc_impdocs).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.pcc_numpcc).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.CPA_DESCRI).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.VDE_NOMVDE).fontSize(6).end
      ).color(PavsoColors.Black).end,
      new Cell(
        new Txt(element.tie_destie).fontSize(6).end
      ).color(PavsoColors.Black).end,

    ]
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(localStorage.getItem('name-company-client')).fontSize(8).alignment('left').end,
            new Txt("RCOM_REP_DET_VE").fontSize(8).alignment('left').end
          ]).end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("HISTORIAL DE PEDIDOS").fontSize(12).bold().alignment('center').end
        ).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).fontSize(8).alignment('right').end,
            new Txt(getHourMinuteSecondsCurrent(new Date())).fontSize(8).alignment('right').end
          ]).end
        ).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt("Cliente").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("TD").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Número").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fecha").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Moneda").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Importe").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Pedido").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cond. Pago").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Vendedor").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Tienda").fontSize(6).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
      ],
      ...detalle
    ]).headerRows(2).widths([85, 20, 35, 45, 35, 45, 65, 50, 50, 50]).layout('noBorders').end
  );

  pdf.create().open();
}

/**
 * Detallado de pedidos
 * @param headers
 * @param data
 * @param otherData
 */
export function generarReporteDetalladoPedidos(headers, data, otherData) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalle = [];

  let tValorVenta = 0;
  let tImpIGV = 0;
  let tImpTotal = 0;

  data.forEach(item => {
    detalle.push([
      new Cell(
        new Txt(`${'PEDIDO Nro. '}     ${item.PCC_NUMPCC}  ${formatDateWithSlash(item.PCC_FECDOC)}     ${item.cli_codcli} ${item.cli_nomcli}       ${item.ubicacion}    ${item.PCC_cpa_deslar}   ${item.TMO_CODTMO}   ${'S/ ' + item.PCD_IMPTOT.toFixed(2)}`).bold().fontSize(9).end
      ).border(noBorders).colSpan(10).end,
      ...obtenerNulls(9)
    ],
      [
        new Cell(
          new Txt('OBSERV.').bold().fontSize(9).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('O. COMPRA').bold().fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.PCC_ORDCOM).bold().fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt('RUTA').bold().fontSize(9).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3)
      ],)

    item.detalle.forEach(element => {
      detalle.push([
        new Cell(
          new Txt('').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.prd_codprd).fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.pcd_secpcd).fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.prd_desesp).fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.pcd_cansol).alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.pcd_canate).alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.pcd_cansus).alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.pcd_valvta.toFixed(2)).alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_IMPIGV.toFixed(2)).alignment('right').fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.PCD_IMPTOT.toFixed(2)).alignment('right').fontSize(9).end
        ).border(noBorders).end,
      ],)

      tValorVenta += element.pcd_valvta;
      tImpIGV += element.PCD_IMPIGV;
      tImpTotal += element.PCD_IMPTOT;

    });

    detalle.push(
      [
        new Cell(
          new Txt('').fontSize(9).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Items:').bold().fontSize(9).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.detalle.length).bold().fontSize(9).end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6)
      ]
    )
  })

  detalle.push([
    new Cell(
      new Txt('').fontSize(9).end
    ).colSpan(5).border(noBorders).end,
    ...obtenerNulls(4),
    new Cell(
      new Txt('TOTAL GENERAL: ').alignment('right').bold().fontSize(9).end
    ).colSpan(2).border(noBorders).end,
    ...obtenerNulls(1),
    new Cell(
      new Txt(tValorVenta.toFixed(2)).alignment('right').fontSize(9).end
    ).border(topBorder).end,
    new Cell(
      new Txt(tImpIGV.toFixed(2)).alignment('right').fontSize(9).end
    ).border(topBorder).end,
    new Cell(
      new Txt(tImpTotal.toFixed(2)).alignment('right').fontSize(9).end
    ).border(topBorder).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(localStorage.getItem('name-company-client')).fontSize(8).alignment('left').end,
            new Txt("RCOM_REP_DET_VE").fontSize(8).alignment('left').end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("DETALLADO DE PEDIDOS").fontSize(11).bold().alignment('center').end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).fontSize(8).alignment('right').end,
            new Txt(getHourMinuteSecondsCurrent(new Date())).fontSize(8).alignment('right').end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('NRO.').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('CÓDIGO').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('SEC').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('PRODUCTO').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('CANTIDAD SOLICITADA').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('CANTIDAD ATENDIDA').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('CANTIDAD SUSP.').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('VALOR VENTA').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('IMP. IGV').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,
        new Cell(
          new Txt('IMP. TOTAL').fontSize(9).alignment('center').end
        ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).bold().end,

      ],

      ...detalle,


    ]).headerRows(2).widths([60, 60, 35, 200, 60, 60, 60, 60, 60, 60]).end
  )

  pdf.create().open();
}

/**
 * Registro de ventas
 * @param headers
 * @param data
 */
export function generarReporteRegistroVentas(headers, data, otherData) {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalle = [];

  let totGenAfe = 0;
  let totGenIna = 0;
  let totGenIgv = 0;
  let totGenImp = 0;

  let counterTotal = 0;

  data.forEach(item => {
    let totAfe = 0;
    let totIna = 0;
    let totIgv = 0;
    let totImp = 0;

    let counterTipoDoc = 0;

    item.detalle.forEach(item2 => {
      let totalAfe = 0;
      let totalIna = 0;
      let totalIgv = 0;
      let totalImp = 0;

      item2.detalle.forEach(row => {

        counterTipoDoc++;
        counterTotal++;

        detalle.push([
          new Cell(
            new Txt(`${row.TDO_CODTDO} ${row.CCO_NUMDOC}`).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(row.CCO_FECEMI)).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(row.CLI_CODCLI).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(row.cli_nomcli).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(otherData.moneda).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(otherData.moneda == 'SO' ? row.cco_impafes.toFixed(2) : numberWithCommas(row.cco_impafed.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(otherData.moneda == 'SO' ? row.cco_impinas.toFixed(2) : numberWithCommas(row.cco_impinad.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(otherData.moneda == 'SO' ? row.cco_impigvs.toFixed(2) : numberWithCommas(row.cco_impigvd.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(otherData.moneda == 'SO' ? row.cco_impdocs.toFixed(2) : numberWithCommas(row.cco_impdocd.toFixed(2))).fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(row.CCO_NUMREF).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(row.PCC_NUMPCC).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt('Ord. Compra').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(`${row.MMO_CODMMO} ${row.mmo_descri}`).fontSize(6).end
          ).border(noBorders).end,
        ],)

        totalAfe += otherData.moneda == 'SO' ? row.cco_impafes : row.cco_impafed;
        totalIna += otherData.moneda == 'SO' ? row.cco_impinas : row.cco_impinad;
        totalIgv += otherData.moneda == 'SO' ? row.cco_impigvs : row.cco_impigvd;
        totalImp += otherData.moneda == 'SO' ? row.cco_impdocs : row.cco_impdocd;
      })
      detalle.push([
        new Cell(
          new Txt('').bold().fontSize(6).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Total Serie B001').bold().fontSize(6).end
        ).colSpan(2).alignment('center').border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(totalAfe.toFixed(2)).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(totalIna.toFixed(2)).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(totalIgv.toFixed(2)).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(totalImp.toFixed(2)).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3)
      ],)

      totAfe += totalAfe;
      totIna += totalIna;
      totIgv += totalIgv;
      totImp += totalImp;
    })

    detalle.push([
      new Cell(
        new Txt('Total tipodoc:').bold().fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(`${counterTipoDoc}`).fontSize(6).bold().end
      ).border(noBorders).colSpan(4).end,
      ...obtenerNulls(3),
      new Cell(
        new Txt(totAfe.toFixed(2)).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(totIna.toFixed(2)).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(totIgv.toFixed(2)).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(totImp.toFixed(2)).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt('').end
      ).border(noBorders).colSpan(4).end,
      ...obtenerNulls(3)
    ],)

    totGenAfe += totAfe;
    totGenIna += totIna;
    totGenIgv += totIgv;
    totGenImp += totImp;

  });

  detalle.push([
    new Cell(
      new Txt('Total documentos:').bold().fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(`${counterTotal}`).bold().fontSize(6).end
    ).border(noBorders).colSpan(2).end,
    ...obtenerNulls(1),
    new Cell(
      new Txt('Total General').bold().fontSize(6).end
    ).colSpan(2).alignment('center').border(noBorders).end,
    ...obtenerNulls(1),
    new Cell(
      new Txt(totGenAfe.toFixed(2)).alignment('right').bold().fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt(totGenIna.toFixed(2)).alignment('right').bold().fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt(totGenIgv.toFixed(2)).alignment('right').bold().fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt(totGenImp.toFixed(2)).alignment('right').bold().fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt('').end
    ).border(noBorders).colSpan(4).end,
    ...obtenerNulls(3)
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(8).end,
            new Txt('RCOM_REGI_VENT').fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt('Registro de Ventas').alignment('center').bold().fontSize(10).end,
            new Txt(`Periodo ${otherData.codano} - ${otherData.codmes}`).alignment('center').fontSize(9).end,
            new Txt(otherData.moneda == 'SO' ? 'EN NUEVOS SOLES' : 'EN DÓLARES').alignment('center').fontSize(7).end,
          ]).end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt('Documento').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openRightBorder).end,
        new Cell(
          new Txt('F. Emisión').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('R.U.C.').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Nombre o razón social').bold().fontSize(7).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Moneda').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Afectos').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Inafectos').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Imp. IGV Ven').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Importe total').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Doc. Referencia').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Pedido').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Ord. Compra').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('Motivo').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openLeftBorder).end,
      ],
      ...detalle,




    ]).widths([60, 40, 40, 110, 20, 40, 40, 50, 50, 50, 50, 50, 80]).end
  )

  pdf.create().open();

}

/**
 * Reporte estadístico general
 * @param headers
 * @param data
 */
export function generarReporteEstadisticoGeneral(headers, data, otherData) {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  const agrupador1 = otherData.agrupador1;
  const agrupador2 = otherData.agrupador2;

  let detalle = [];

  let [totEne, totFeb, totMar, totAbr, totMay, totJun, totJul, totAgo, totSet, totOct, totNov, totDic, totGen] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  if (!agrupador1 && !agrupador2) {

    data.forEach(element => {
      console.log('ELEMENT', element)
      detalle.push([
        new Cell(
          new Txt(`${element.PRD_CODPRD} ${element.prd_desesp}`).fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[0].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[1].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[2].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[3].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[4].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[5].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[6].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[7].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[8].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[9].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[10].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[11].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.detalle[12].valor.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(noBorders).end,
      ],)

      totEne += element.detalle[0].valor;
      totFeb += element.detalle[1].valor;
      totMar += element.detalle[2].valor;
      totAbr += element.detalle[3].valor;
      totMay += element.detalle[4].valor;
      totJun += element.detalle[5].valor;
      totJul += element.detalle[6].valor;
      totAgo += element.detalle[7].valor;
      totSet += element.detalle[8].valor;
      totOct += element.detalle[9].valor;
      totNov += element.detalle[10].valor;
      totDic += element.detalle[11].valor;
      totGen += element.detalle[12].valor;
    });

    detalle.push([
      new Cell(
        new Txt('TOTAL').fontSize(6).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totEne.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totFeb.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totMar.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totAbr.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totMay.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totJun.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totJul.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totAgo.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totSet.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totOct.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totNov.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totDic.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totGen.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(topBorder).end,
    ],)

  }

  if (agrupador1 && !agrupador2) {
    data.forEach(element => {
      detalle.push([
        new Cell(
          new Txt(``).decoration('underline').fontSize(7).bold().end
        ).colSpan(14).border(noBorders).end,
        ...obtenerNulls(13)
      ],)

      let [tEne, tFeb, tMar, tAbr, tMay, tJun, tJul, tAgo, tSet, tOct, tNov, tDic, tTot] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      element.detalle.forEach(item => {

        tEne += item.detalle[0].valor;
        tFeb += item.detalle[1].valor;
        tMar += item.detalle[2].valor;
        tAbr += item.detalle[3].valor;
        tMay += item.detalle[4].valor;
        tJun += item.detalle[5].valor;
        tJul += item.detalle[6].valor;
        tAgo += item.detalle[7].valor;
        tSet += item.detalle[8].valor;
        tOct += item.detalle[9].valor;
        tNov += item.detalle[10].valor;
        tDic += item.detalle[11].valor;
        tTot += item.detalle[12].valor;

        totEne += item.detalle[0].valor;
        totFeb += item.detalle[1].valor;
        totMar += item.detalle[2].valor;
        totAbr += item.detalle[3].valor;
        totMay += item.detalle[4].valor;
        totJun += item.detalle[5].valor;
        totJul += item.detalle[6].valor;
        totAgo += item.detalle[7].valor;
        totSet += item.detalle[8].valor;
        totOct += item.detalle[9].valor;
        totNov += item.detalle[10].valor;
        totDic += item.detalle[11].valor;
        totGen += item.detalle[12].valor;

        if (otherData.verDetalle) {
          detalle.push([
            new Cell(
              new Txt(`${item.PRD_CODPRD} ${item.prd_desesp}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[0].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[1].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[2].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[3].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[4].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[5].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[6].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[7].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[8].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[9].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[10].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[11].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(item.detalle[12].valor.toFixed(2))).alignment('right').fontSize(6).end
            ).border(noBorders).end,
          ],)
        }
      })

      detalle.push([
        new Cell(
          new Txt(`${element.agrupado1} ${element.agrupado1_descri}`).fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(tEne.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tFeb.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMar.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tAbr.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMay.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tJun.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tJul.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tAgo.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tSet.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tOct.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tNov.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tDic.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tTot.toFixed(2))).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
      ],)

    })

    detalle.push([
      new Cell(
        new Txt('TOTAL').fontSize(6).bold().end
      ).border(openRightBorder).end,
      new Cell(
        new Txt(numberWithCommas(totEne.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totFeb.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totMar.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totAbr.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totMay.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totJun.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totJul.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totAgo.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totSet.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totOct.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totNov.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totDic.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totGen.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(openLeftBorder).end,
    ],)
  }

  if (agrupador1 && agrupador2) {
    data.forEach(element => {
      detalle.push([
        new Cell(
          new Txt(``).decoration('underline').fontSize(7).bold().end
        ).colSpan(14).border(noBorders).end,
        ...obtenerNulls(13)
      ],)

      let [tEneCli, tFebCli, tMarCli, tAbrCli, tMayCli, tJunCli, tJulCli, tAgoCli, tSetCli, tOctCli, tNovCli, tDicCli, tTotCli] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      element.detalle.forEach(item => {
        detalle.push([
          new Cell(
            new Txt(``).fontSize(7).bold().end
          ).colSpan(14).border(noBorders).end,
          ...obtenerNulls(13)
        ],)

        let [tEne, tFeb, tMar, tAbr, tMay, tJun, tJul, tAgo, tSet, tOct, tNov, tDic, tTot] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        item.detalle.forEach(el => {

          tEne += el.detalle[0].valor;
          tFeb += el.detalle[1].valor;
          tMar += el.detalle[2].valor;
          tAbr += el.detalle[3].valor;
          tMay += el.detalle[4].valor;
          tJun += el.detalle[5].valor;
          tJul += el.detalle[6].valor;
          tAgo += el.detalle[7].valor;
          tSet += el.detalle[8].valor;
          tOct += el.detalle[9].valor;
          tNov += el.detalle[10].valor;
          tDic += el.detalle[11].valor;
          tTot += el.detalle[12].valor;

          totEne += el.detalle[0].valor;
          totFeb += el.detalle[1].valor;
          totMar += el.detalle[2].valor;
          totAbr += el.detalle[3].valor;
          totMay += el.detalle[4].valor;
          totJun += el.detalle[5].valor;
          totJul += el.detalle[6].valor;
          totAgo += el.detalle[7].valor;
          totSet += el.detalle[8].valor;
          totOct += el.detalle[9].valor;
          totNov += el.detalle[10].valor;
          totDic += el.detalle[11].valor;
          totGen += el.detalle[12].valor;

          if (otherData.verDetalle) {
            detalle.push([
              new Cell(
                new Txt(`${el.PRD_CODPRD} ${el.prd_desesp}`).fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[0].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[1].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[2].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[3].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[4].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[5].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[6].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[7].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[8].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[9].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[10].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[11].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(el.detalle[12].valor.toFixed(2))).alignment('right').fontSize(6).end
              ).border(noBorders).end,
            ],)
          }
        })

        tEneCli += tEne;
        tFebCli += tFeb;
        tMarCli += tMar;
        tAbrCli += tAbr;
        tMayCli += tMay;
        tJunCli += tJun;
        tJulCli += tJul;
        tAgoCli += tAgo;
        tSetCli += tSet;
        tOctCli += tOct;
        tNovCli += tNov;
        tDicCli += tDic;
        tTotCli += tTot;

        detalle.push([
          new Cell(
            new Txt(`- ${item.agrupado2} ${item.agrupado2_descri}`).fontSize(6).bold().end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(tEne.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tFeb.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tMar.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tAbr.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tMay.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tJun.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tJul.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tAgo.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tSet.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tOct.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tNov.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tDic.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tTot.toFixed(2))).alignment('right').fontSize(6).bold().end
          ).border(topBorder).end,
        ],)

      })

      detalle.push([
        new Cell(
          new Txt(`${element.agrupado1} ${element.agrupado1_descri}`).color(PavsoColors.BlueDark).fontSize(6).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(tEneCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tFebCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMarCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tAbrCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMayCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tJunCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tJulCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tAgoCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tSetCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tOctCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tNovCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tDicCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tTotCli.toFixed(2))).color(PavsoColors.BlueDark).alignment('right').fontSize(6).bold().end
        ).border(topBorder).end,
      ],)


    })

    detalle.push([
      new Cell(
        new Txt('TOTAL').fontSize(6).bold().end
      ).border(openRightBorder).end,
      new Cell(
        new Txt(numberWithCommas(totEne.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totFeb.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totMar.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totAbr.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totMay.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totJun.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totJul.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totAgo.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totSet.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totOct.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totNov.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totDic.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(horizontalBorder).end,
      new Cell(
        new Txt(numberWithCommas(totGen.toFixed(2))).alignment('right').fontSize(6).bold().end
      ).border(openLeftBorder).end,
    ],)
  }


  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RCOM_ESTA_PROD').fontSize(7).end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('ESTADÍSTICO GENERAL').alignment('center').bold().fontSize(9).end,
            new Txt(headers.moneda == 'SO' ? 'EN NUEVOS SOLES' : 'EN DÓLARES').alignment('center').bold().fontSize(7).end,
            new Txt(headers.codano).alignment('center').bold().fontSize(6).end,
          ]).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt('14/04/2023').alignment('right').fontSize(7).end,
            new Txt('10:58:26').alignment('right').fontSize(7).end,
          ]).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt('PRODUCTO').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('ENE').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('FEB').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('MAR').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('ABR').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('MAY').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('JUN').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('JUL').alignment('center').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('AGO').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('SET').alignment('center').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('OCT').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('NOV').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('DIC').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('TOTAL').alignment('center').fontSize(8).bold().end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
      ],

      ...detalle,

    ]).layout({
      hLineColor: () => COLOR_GRAY,
      vLineColor: () => COLOR_GRAY
    }).decorationColor('red').headerRows(2).widths([215, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35]).end
  )

  pdf.create().open();

}

/**
 * Reporte resumen guia ventas cantidad valor
 * @param headers
 * @param data
 */
export function generarReporteResumenGuiaVentasCantidadValor(headers, data, otherData) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalle = [];

  console.log('data trimestral', data)

  data.forEach(item => {
    let row = [];

    let total

    for (let index = 0; index < item.detalle.length; index++) {
      const periodo = item.detalle[index];
      row.push(
        new Cell(
          new Txt(periodo.dcd_candcd).alignment('right').fontSize(7).end
        ).border(noBorders).end,
      )
      row.push(
        new Cell(
          new Txt(periodo.valor.toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
      )
    }

    if (otherData.maxPeriodos * 2 != row.length) {

      for (let index = 0; index < otherData.maxPeriodos - row.length / 2; index++) {
        row.push(new Cell(
          new Txt('0.00').alignment('right').fontSize(7).end
        ).border(noBorders).end,)
        row.push(new Cell(
          new Txt('0.00').alignment('right').fontSize(7).end
        ).border(noBorders).end,)
      }

    }

    detalle.push([
      new Cell(
        new Txt(item.PRD_CODPRD).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.prd_desesp).fontSize(7).end
      ).border(noBorders).end,

      ...row

    ],)
  });

  let periodos = [
    new Cell(
      new Txt('Código').fontSize(8).bold().end
    ).fillColor(PavsoColors.Blue).color(PavsoColors.White).rowSpan(2).end,
    new Cell(
      new Txt('Descripción').fontSize(8).bold().end
    ).fillColor(PavsoColors.Blue).color(PavsoColors.White).rowSpan(2).end,
  ];

  otherData.periodos.forEach(item => {
    periodos.push(
      new Cell(
        new Txt(`${number2month(item.month)} ${item.year}`).alignment('center').fontSize(8).bold().end
      ).fillColor(PavsoColors.Blue).color(PavsoColors.White).colSpan(2).end
    )
    periodos.push(new Cell(new Txt('').end).end)
  });

  let valores = [
    new Cell(
      new Txt('').fontSize(8).end
    ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
    new Cell(
      new Txt('').fontSize(8).end
    ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
  ];

  console.log('periodos', periodos)

  otherData.periodos.forEach(item => {
    valores.push(
      new Cell(
        new Txt('Cantidad').alignment('center').bold().fontSize(8).end
      ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
    )
    valores.push(
      new Cell(
        new Txt('Valores').alignment('center').bold().fontSize(8).end
      ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
    )
  });

  let widths = [];

  for (let index = 0; index < otherData.maxPeriodos; index++) {

    widths.push(50, 50)

  }

  console.log('WITHS', widths)
  console.log('VALORES', valores)


  console.log('DETALLE', detalle)

  let totales = [];

  for (let index = 0; index < detalle[0].length - 2; index++) {

    totales.push(0)
  }

  console.log('total init', totales)

  detalle.forEach(item => {
    console.log('item', item)

    console.log('value', item[2].text)
    totales.forEach((el, index) => {
      totales[index] += Number(item[index + 2].text);
    })
  })

  console.log('totales', totales);

  totales.forEach(item => {
    console.log('item total', item)
  })

  let totalesReporte = [
    new Cell(
      new Txt('').fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('Total').fontSize(7).alignment('right').bold().end
    ).border(topBorder).end,


  ];

  totales.forEach(item => {
    totalesReporte.push(
      new Cell(
        new Txt(numberWithCommas(item.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
    );
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RCOM_GUIA_CNTV').fontSize(7).end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('Resumen Guia Ventas Cantidad - Valor').alignment('center').bold().fontSize(10).end,
            new Txt('EN NUEVO SOLES').alignment('center').fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(otherData.maxPeriodos * 2).end,
        ...obtenerNulls(otherData.maxPeriodos * 2 - 1),
        new Cell(
          new Stack([
            new Txt('14/04/2023').alignment('right').fontSize(7).end,
            new Txt('10:51:41').alignment('right').fontSize(7).end
          ]).end
        ).border(noBorders).end,
      ],
      periodos,

      valores,

      ...detalle,
      totalesReporte
    ]).widths([50, 150, ...widths]).end
  )

  pdf.create().open();

}

/**
 * Reporte productosDejadosVender
 * @param headers
 * @param data
 */
export function generarReporteProductosDejadosVender(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  let detalle = [];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.prd_codprd).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.prd_desesp).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ume_Codume).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.lpd_descri).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.fpr_descri).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.sfp_descri).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.mod_descri).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.tin_codtin).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.prd_canant.toFixed(2))).alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.PRD_VALANT.toFixed(2))).alignment('right').fontSize(6).end
      ).border(noBorders).end,
    ])
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RINV_PROD_DEVE').fontSize(7).end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Productos Dejados de Vender').fontSize(10).bold().alignment('center').end,
            new Txt(headers.codano).fontSize(7).bold().alignment('right').alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt('14/04/23').fontSize(7).alignment('right').end,
            new Txt('11:05:44').fontSize(7).alignment('right').end,
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt('COD. PROD.').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openRightBorder).end,
        new Cell(
          new Txt('DESCRIPCIÓN').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('UND').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('LÍNEA').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('FAMILIA').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('SUBFAMILIA').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('MODELO').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('TIN').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('CANTIDAD AÑO ANTERIOR').alignment('center').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(horizontalBorder).end,
        new Cell(
          new Txt('VALOR AÑO ANTERIOR').alignment('center').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openLeftBorder).end,
      ],
      ...detalle
    ]).widths([50, 180, 45, 55, 65, 65, 65, 35, 80, 70]).end
  )

  pdf.create().open();

}


function obtenerHora(): string {
  let date = new Date()
  const hour = date.getHours();
  const minute = date.getMinutes();
  const seconds = date.getSeconds();
  return `${hour}:${minute}:${seconds}`;
}
