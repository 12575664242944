export class Presupuesto {
  numopc: string;
  fecopc: Date;
  tipcam: number | string;
  tipeur: number | string;
  margen: number | string;
  numpcc: string;
  fecupd: Date;
  coduse: string;
  indsta: string;
  obsopc: string;
  idtkt: string;
  idset: string;
  indapr: number;
  fecapr: Date;
  usuapr: string;
  cliaut: string;
  marser: number | string;
  maradm: number | string;
  valvta: number;
  impigv: number;
  imptot: number;
  indkwp: number;
  numkwp: number | string;
  subtot: number;
  impadm: number;
  impser: number;
  indsis: number;
  codcli: string;
  codtmo: string;
  utilid: number;
  codvde: string;
  idmtk: string;
  PRES_COTI_OPD: Array<PresCotiOpd>;

  constructor() {
    this.fecupd = new Date();
    this.fecopc = new Date();
    this.PRES_COTI_OPD = [];
    this.indapr = 0;
    this.indkwp = 0;
    this.indsis = 0;
    this.utilid = 0;
    this.impadm = 0;
    this.margen = 0;
    this.maradm = 0;
    this.marser = 0;

    this.subtot = 0;
    this.impadm = 0;
    this.valvta = 0;
    this.impigv = 0;
    this.imptot = 0;

    this.indsta = '1';
  }
}

export class PresCotiOpd {
  numopc: string;
  coropd: string;
  codopg: string;
  codprd: string;
  descri: string;
  canopd: number | string;
  cosuni: number | string;
  totnac: number;
  porinc: number;
  margen: number | string;
  cosofd: number;
  cosofs: number;
  totofs: number;
  totofd: number;
  impmar: number;
  secopd: string;
  correlativo: any;
  isEditing: boolean;

  constructor() {
    this.isEditing = true;
    this.canopd = 0;
    this.cosuni = 0;
    this.totnac = 0;
    this.porinc = 0;
    this.margen = 0;
    this.impmar = 0;
    this.cosofd = 0;
    this.cosofs = 0;
    this.totofs = 0;
    this.totofd = 0;
  }
}
