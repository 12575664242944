import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_SITUACION_OPORTUNIDAD } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { SituacionService } from "src/app/services/api/crm/maestros/situacion.service";

@Component({
  selector: 'situacion-oportunidad-list',
  templateUrl: 'situacion-oportunidad-list.component.html',
  styleUrls: ['situacion-oportunidad-list.component.scss']
})
export class SituacionOportunidadListComponent implements OnInit, OnDestroy {

  situaciones$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_SITUACION_OPORTUNIDAD;
  situaciones: Array<any> = [];

  constructor(
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _situacionService: SituacionService
  ) {}

  ngOnInit(): void {
    this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerSituacionesOportunidad()
    })
  }

  obtenerSituacionesOportunidad(): void {
    this._situacionService.obtenerSituacionesOportunidad().subscribe(
      situaciones => this.situaciones = situaciones,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.situaciones$,
      this.loading$,
    ])
  }
}
