import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'subfamilia-form',
  templateUrl: './subfamilia-form.component.html',
  styleUrls: ['./subfamilia-form.component.scss']
})
export class SubFamiliaFormComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
