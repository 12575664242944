import { PavsoColors } from "@data/enums/color.enum";
import { bottomBorder, bottomLeftBorder, bottomRightBorder, horizontalBorder, leftBorder, noBorders, rightBorder, topBorder, topLeftBorder, topRightBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";

export function generarFormatoSolicitudCompra(headers, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });

  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('OLEOHIDRAULICS SERVICES S.A.C.').alignment('center').fontSize(10).bold().end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('\nSOLICITUD DE COMPRA: 2023000527').fontSize(10).bold().end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('\nPENDIENTE').fontSize(10).bold().end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('DIA').bold().fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('MES').bold().fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('AÑO').bold().fontSize(8).bold().end
              ).end
            ],
            [
              new Cell(
                new Txt('21').bold().fontSize(8).end
              ).end,
              new Cell(
                new Txt('07').bold().fontSize(8).end
              ).end,
              new Cell(
                new Txt('2023').bold().fontSize(8).end
              ).end
            ],
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('SOLICITANTE').fontSize(8).bold().end
        ).border(topLeftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('BRAVO CONTRERAS SULMIRA').fontSize(8).end
        ).border(topBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(topBorder).end,
        new Cell(
          new Txt('TIPO DE COMPRA').fontSize(8).bold().end
        ).border(topBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('SUMINISTRO').fontSize(8).end
        ).border(topRightBorder).colSpan(2).end,
        ...obtenerNulls(1),

      ],
      [
        new Cell(
          new Txt('MOTIVO').fontSize(8).bold().end
        ).border(topLeftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('ORDEN DE TRABAJO').fontSize(8).end
        ).border(topBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(topBorder).end,
        new Cell(
          new Txt('').end
        ).border(topBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('NACIONAL').fontSize(8).end
        ).border(topRightBorder).colSpan(2).end,
        ...obtenerNulls(1),

      ],
      [
        new Cell(
          new Txt('FECHA REQUERIDA POR EL SOLICITANTE').fontSize(8).bold().end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('01/01/1900').fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('').end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('ORDEN TRABAJO').fontSize(8).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('F2300074').fontSize(8).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('FECHA OFERTADA AL CLIENTE').fontSize(8).bold().end
        ).border(bottomLeftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(bottomBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(bottomBorder).end,
        new Cell(
          new Txt('').end
        ).border(bottomBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(bottomRightBorder).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Item').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Código').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Descripción Producto/Servicio').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Unidad').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Cantidad').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('P. Unit.').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Total').fontSize(7).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
      ],
      [
        new Cell(
          new Txt('001').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('1600569').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('VÁLVULAS CHECK DE 2 VÍAS NPT 1" 350 BAR (VU N 1" DN25 1111 PN350) ').fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('UND').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('12.00').alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('0.00').alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('0.00').alignment('right').fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('').end
        ).border(horizontalBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('Total').fontSize(7).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('0.00').alignment('right').fontSize(7).bold().end
        ).border(horizontalBorder).end,
      ],
      [
        new Cell(
          new Txt('Observaciones').bold().fontSize(8).decoration('underline').end
        ).border(noBorders).colSpan(2).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt('').bold().end
        ).border(noBorders).end,
      ],
    ]).widths([40, 40, 60, 60, 120, 40, 40, 40, 40]).end
  )

  pdf.create().open();

}
