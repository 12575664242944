import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { CentroCosto } from 'src/app/models/contabilidad/centro_costo';
import { AuthenticationService, ContabilidadService, AlmacenService, ConfigurationService, CajaBancoService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CentroCostoService } from 'src/app/services/api/contabilidad/maestros/centro-costo.service';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { GrupoFlujoCajaService } from 'src/app/services/api/tesoreria/maestros/grupo-flujo-caja.service';

@Component({
  selector: 'app-centro-costo-form',
  templateUrl: './centro-costo-form.component.html',
  styleUrls: ['./centro-costo-form.component.css']
})
export class CentroCostoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  monedas: any[] = [];
  responsables: any[] = [];
  auxiliares: any[] = [];
  categorias: any[] = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  centro: CentroCosto;

  uid: string;

  loading$: Subscription;
  centroCosto$: Subscription;
  categorias$: Subscription;
  clientes$: Subscription;
  sendForm$: Subscription;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private _clienteService: ClienteService,
    private _centroCostoService: CentroCostoService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _cajaBancoService: CajaBancoService,
    private _grupoFlujoCajaService: GrupoFlujoCajaService,
    private store: Store<PavsoState>
  ) {
    this.centro = new CentroCosto();
    this.centro.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.centro.ccs_coduse = this._configurationService.obtenerIdUsuario();

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this.loaderData = true;

    this._activatedRoute.params.subscribe( params => {
      this.uid = params.id;
      console.log('uid', this.uid)
      this.loadMaestros();

      if(this.uid != '0') {
        this.obtenerCentroCosto();
        return;
      }

      this.loaderData = false;

    })
  }

  loadMaestros(): void {
    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.auxiliares = clientes.filter(cliente => cliente.cli_indtra == 1);
        this.responsables = clientes.filter(cliente => cliente.cli_indtra == 1 && cliente.cli_indpro == 1);

        this.categorias$ = this._grupoFlujoCajaService.obtenerGrupoFlujoCajas().subscribe(
          categorias => {
            this.categorias = categorias;
          },
          error => {
            this._snackBarService.showError("Error al obtener grupos de flujo caja", "OK");
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener auxiliares', 'OK');
      }
    )
  }

  copiarCentroCosto(): void {
    this.centroCosto$ = this._centroCostoService.obtenerCentroCosto(this.centro.ccs_codccs).subscribe(
      (centro) => {
        this.centro = centro
        this.usuario = centro.ccs_coduse;
        this.fecha = centro.ccs_fecupd;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK")
      }
    )
  }

  obtenerCentroCosto(): void {
    this.centroCosto$ = this._centroCostoService.obtenerCentroCosto(this.uid).subscribe(
      (centro) => {
        this.centro = centro
        this.usuario = centro.ccs_coduse;
        this.fecha = centro.ccs_fecupd;

      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK")
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarCentro(f): this.actualizarCentro(f);
  }

  registrarCentro(f: NgForm): void {
    this.sendForm$ = this._centroCostoService.registrarCentroCosto(this.centro).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Centro de costo Registrado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
      }
    )
  }

  actualizarCentro(f: NgForm): void {
    this.sendForm$ = this._centroCostoService.actualizarCentroCosto(this.centro).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Centro de costo actualizado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/centros-de-costo']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.centroCosto$,
      this.sendForm$
    ])
  }

}
