<app-pavso-title-section [title]="'PDFs'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)" [reporteSeleccionado]="reporteSeleccionado[0]"></select-report>

  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">{{'Filtro'}}</div>
  </div>

  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

  <div class="pav-filtro-container">
    <div class="row">
      <div class="col s12 m12 l4">
        <select-search
          [multiple]="true"
          [label]="'Tienda'"
          [placeholder]="'Ej. Tienda Azul'"
          [name]="'tie_codtie'"
          [value]="'tie_codtie'"
          [description]="'tie_destie'"
          [data]="tiendas"
          (cambioSelect)="filtro.tiendas = $event"
          [model]="filtro.tiendas"
          [disabled]="loading"
        ></select-search>

      </div>
      <div class="col s12 m12 l4">
        <select-search
          [multiple]="true"
          [label]="'Cliente'"
          [placeholder]="'Ej. Cliente 2'"
          [name]="'cli_codcli'"
          [value]="'cli_codcli'"
          [description]="'cli_nomcli'"
          [data]="clientes"
          (cambioSelect)="filtro.clientes = $event"
          [model]="filtro.clientes"
          [disabled]="loading"
        ></select-search>
      </div>
      <div class="col s12 m12 l4">
        <select-search
          [multiple]="true"
          [label]="'Serie'"
          [placeholder]="'Ej. 001'"
          [name]="'serie'"
          [value]="'serie'"
          [description]="'serie'"
          [data]="series"
          (cambioSelect)="filtro.series = $event"
          [model]="filtro.series"
          [disabled]="loading"
        ></select-search>

      </div>
      <div class="col s12 m12 l4">
        <select-search
          [multiple]="true"
          [label]="'Motivo'"
          [placeholder]="'Ej. Motivo 01'"
          [name]="'mmo_codmmo'"
          [value]="'mmo_codmmo'"
          [description]="'mmo_descri'"
          [data]="motivos"
          (cambioSelect)="filtro.motivos = $event"
          [model]="filtro.motivos"
          [disabled]="loading"
        ></select-search>
      </div>
      <div class="col s12 m12 l4">
        <select-search
          [multiple]="true"
          [label]="'Vendedor'"
          [placeholder]="'Ej. Vendedor 01'"
          [name]="'codvde'"
          [value]="'codvde'"
          [description]="'nomvde'"
          [data]="vendedores"
          (cambioSelect)="filtro.vendedores = $event"
          [model]="filtro.vendedores"
          [disabled]="loading"
        ></select-search>

      </div>
      <div class="col s12 m12 l4">
        <select-search
          [multiple]="true"
          [label]="'Producto'"
          [placeholder]="'Ej. Producto 01'"
          [name]="'prd_codprd'"
          [value]="'prd_codprd'"
          [description]="'DESC_ESPA'"
          [data]="productos"
          (cambioSelect)="filtro.productos = $event"
          [model]="filtro.productos"
          [disabled]="loading"
        ></select-search>
      </div>
      <div class="col s12 m12 l4">
        <select-search
          [multiple]="true"
          [label]="'Condición'"
          [placeholder]="'Ej. Condición 01'"
          [name]="'CPA_CODCPA'"
          [value]="'CPA_CODCPA'"
          [description]="'CPA_DESCRI'"
          [data]="condiciones"
          (cambioSelect)="filtro.condiciones = $event"
          [model]="filtro.condiciones"
          [disabled]="loading"
        ></select-search>
      </div>
      <div class="col s12 m12 l4">

        <select-search
          [multiple]="true"
          [label]="'Tipo de Inventario'"
          [placeholder]="'Ej. Tipo de Inventario 01'"
          [name]="'TIN_CODTIN'"
          [value]="'TIN_CODTIN'"
          [description]="'TIN_DESCRI'"
          [data]="inventarios"
          (cambioSelect)="filtro.inventarios = $event"
          [model]="filtro.inventarios"
          [disabled]="loading"
        ></select-search>

      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Líneas</mat-label>
          <mat-select [disabled]="loading" multiple>
            <mat-option *ngFor="let topping of lineas" [value]="topping.lpd_codlpd">{{topping.lpd_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">

        <select-search
          [multiple]="true"
          [label]="'Tipo de Documento'"
          [placeholder]="'Ej. Tipo de Documento 01'"
          [name]="'tdo_codtdo'"
          [value]="'tdo_codtdo'"
          [description]="'tdo_deslar'"
          [data]="documentos"
          (cambioSelect)="filtro.documentos = $event"
          [model]="filtro.documentos"
          [disabled]="loading"
        ></select-search>

      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Marcas</mat-label>
          <mat-select [disabled]="loading" multiple>
            <mat-option *ngFor="let topping of marcas" [value]="topping.MPR_CODMPR">{{topping.MPR_DESCRI}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Monedas</mat-label>
          <mat-select [disabled]="loading" multiple>
            <mat-option *ngFor="let topping of monedas" [value]="topping.TMO_CODTMO">{{topping.tmo_deslar}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Rango de Fechas</mat-label>
          <mat-date-range-input [disabled]="loading" [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l2" *ngFor="let periodo of filtro.periodos; let i =index;">

        <mat-form-field class="example-full-width">
          <mat-label>Período</mat-label>
          <input matInput [(ngModel)]="periodo.periodo" [name]="'periodo01'+i" placeholder="Ej. 2023/05" appFormatoAnoMes/>
        </mat-form-field>

      </div>


    </div>
  </div>
  <br>

</div>
<mat-card class="mat-elevation-z0">

  <button class="pav-button-action" mat-flat-button color="primary" (click)="verReporte()" [class.spinner]="loading" [disabled]="loading">{{LABELS_NAME.BTN_SHOW_REPORT}}</button>

</mat-card>
