export class ListaPrecioCliente {
  CIA_CODCIA:     string;
  CLI_CODCLI:     string;
  cliente:        Cliente;
  PCE_CORPCE:     string;
  PCE_OBSPCE:     null;
  PCE_PORDES:     null;
  PCE_FECINI:     Date;
  PCE_FECFIN:     Date;
  PCE_FECINO:     null;
  PCE_FECFIO:     null;
  CTI_CODCTI:     null;
  PCE_INDSTA:     string;
  PCE_CODUSE:     string;
  PCE_FECUPD:     Date;
  VPR_NUMVPR:     null;
  CPA_CODCPA:     null;
  CCS_CODCCS:     null;
  detalle_precio: DetallePrecio[];

  constructor() {
    this.detalle_precio = [];
    this.cliente = new Cliente();
    this.PCE_FECUPD = new Date();
    this.PCE_INDSTA = '1';
  }
}

export class Cliente {
  cli_nomcli: string;
}

export class DetallePrecio {
  PCE_CORPCE: string;
  PRD_CODPRD: string;
  producto:   Producto;
  PCL_PRENAC: number;
  PCL_VTANAC: number;
  PCL_PREDOL: number;
  PCL_VTADOL: number;
  PCL_INCDES: null;
  PCL_FECINI: Date;
  PCL_FECFIN: Date;
  PCL_INDEXC: number;
  PCL_INDOFE: number;
  PCL_PRENAO: number;
  PCL_VTANAO: number;
  PCL_PREDOO: number;
  PCL_VTADOO: number;
  PCL_FECINO: null;
  PCL_FECFIO: null;
  PCL_INDSTA: string;
  PCL_CODUSE: string;
  PCL_FECUPD: Date;

  constructor() {
    this.PCL_FECUPD = new Date();
    this.producto = new Producto();
    this.PCL_PRENAC = 0;
    this.PCL_VTANAC = 0;
    this.PCL_PREDOL = 0;
    this.PCL_VTADOL = 0;
    this.PCL_INDSTA = '1';

    this.PCL_INDEXC = 0;
    this.PCL_INDOFE = 0;
    this.PCL_PRENAO = 0;
    this.PCL_VTANAO = 0;
    this.PCL_PREDOO = 0;
    this.PCL_VTADOO = 0;
  }
}

export class Producto {
  prd_desesp: string;
  ume_codume: string;
}
