import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';

class Anexo {
  aef_codaef: any;
  aef_descri: any;
  detalle: any;
}

@Component({
  selector: 'app-annexed',
  templateUrl: './annexed.component.html',
  styleUrls: ['./annexed.component.css']
})
export class AnnexedComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['ccn_codccn', 'ccn_descri'];
  dataSource: MatTableDataSource<any>;

  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsList: string[] = ['accion', 'aef_codaef', 'aef_descri'];
  dataSourceList: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorList: MatPaginator;
  @ViewChild(MatSort) sortList: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  selectedIndex: number;

  anexo: Anexo;

  payloadDetail: any[] = [];

  cuentas: any[] = [];

  buscaCuentaAnexo$: Subscription;
  anexoEEFF$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<AnnexedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService
  ) {
    this.anexo = new Anexo();

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loadData();
    this.selectedIndex = 0;
  }

  loadData(): void {
    this.anexoEEFF$ = this._contabilidadService.listarAnexoEEFF().subscribe(
      anexos => this.dataSourceList = fillTable(anexos, this.paginatorList, this.sortList),
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  verDetalle(id): void {
    this.selectedIndex = 1;
    this.anexoEEFF$ = this._contabilidadService.verAnexoEEFF(id).subscribe(
      anexo => {
        this.anexo.aef_codaef = anexo[0].aef_codaef;
        this.anexo.aef_descri = anexo[0].aef_descri;
        this.payloadDetail = anexo[0].detalle;
        this.dataSource = fillTable(anexo[0].detalle, this.paginator, this.sort);
      }
    )
  }

  buscarCuentas(text): void {
    const payload = {
      search: text.value,
      detalle: this.payloadDetail
    }
    this.buscaCuentaAnexo$ = this._contabilidadService.buscarCuentasEnAnexo(payload).subscribe(
      response => this.cuentas = response,
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceList.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceList.paginator) this.dataSourceList.paginator.firstPage();

  }

  regresar(): void {
    this.selectedIndex = 0;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.buscaCuentaAnexo$,
      this.anexoEEFF$
    ])
  }
}
