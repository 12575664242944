import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'permiso-aprobacion-pedido',
  templateUrl: './permiso-aprobacion-pedido.component.html',
  styleUrls: ['./permiso-aprobacion-pedido.component.scss']
})
export class PermisoAprobacionPedidoComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
