import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class BancosVentasService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerBancos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/bancos`
    );
  }

  obtenerBanco(codban): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/bancos/${codban}`
    );
  }

}
