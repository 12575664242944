<app-pavso-title-section [title]="'apps.crm.maestros.campania.title' | translate" [module]="'CRM'" [estado]="campania.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>{{'apps.crm.maestros.campania.form.code' | translate}}</mat-label>
                    <input type="text" [(ngModel)]="campania.codcso" matInput [placeholder]="'apps.crm.maestros.campania.form.code' | translate" name="codcso" readonly>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l6">
                <mat-form-field>
                    <mat-label>{{'apps.crm.maestros.campania.form.description' | translate}}</mat-label>
                    <input type="text" [(ngModel)]="campania.glosa" matInput [placeholder]="'apps.crm.maestros.campania.form.description' | translate" name="descri" required>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.maestros.campania.form.type-campaign' | translate}}</mat-label>
                <mat-select [(ngModel)]="campania.codcoc" name="codcoc">
                  <mat-option *ngFor="let tipo of tiposCampania; trackBy:trackByTipoCampania" [value]="tipo.codcoc">
                    {{tipo.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.maestros.campania.form.start-date' | translate}}</mat-label>
                <input [(ngModel)]="campania.fecini" matInput [matDatepicker]="fechaini" name="fecini">
                <mat-datepicker-toggle matSuffix [for]="fechaini"></mat-datepicker-toggle>
                <mat-datepicker #fechaini></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.maestros.campania.form.end-date' | translate}}</mat-label>
                <input [(ngModel)]="campania.fecter" matInput [matDatepicker]="fechater" name="fecter">
                <mat-datepicker-toggle matSuffix [for]="fechater"></mat-datepicker-toggle>
                <mat-datepicker #fechater></mat-datepicker>
              </mat-form-field>
            </div>
            <!-- <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.maestros.campania.form.situation' | translate}}</mat-label>
                <mat-select [(ngModel)]="campania.situacion" name="idstk">
                  <mat-option *ngFor="let situacion of situaciones; trackBy:trackBySituacion" [value]="situacion.idstk">
                    {{situacion.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
            <div class="col s12 m12 l3">
              <button mat-button class="pav-button-action" type="button">{{'general.button.close' | translate}}</button>
            </div>
            <!-- <div class="col s12 m12 l3">

              <mat-slide-toggle [(ngModel)]="campania.indaut" name="indaut">{{'apps.crm.maestros.campania.form.restricted-authorization' | translate}}</mat-slide-toggle>

            </div> -->
        </div>

    </mat-card>
    <log-updated *ngIf="uid!='0'" [coduse]="campania.coduse" [fecupd]="campania.fecupd"></log-updated>


  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-crm/campanias">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
