<h1 mat-dialog-title>Enviar Correo Electrónico</h1>
<div mat-dialog-content>
    <mat-form-field class="example-chip-list">
        <mat-label>Correos electrónicos</mat-label>
        <mat-chip-list #chipList aria-label="Correos electrónicos">
            <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable"
                    [removable]="removable" (removed)="remove(fruit)">
                {{fruit.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Nuevo Correo electrónico..."
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
    </mat-form-field>
</div>
<div>
    <button mat-flat-button class="pav-button-cancel" (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
    <button mat-flat-button class="pav-button-send" cdkFocusInitial>Enviar</button>
</div>
