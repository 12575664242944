import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { PlanCuenta } from 'src/app/models';
import { AuthenticationService, CajaBancoService, ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { PlanCuentaService } from 'src/app/services/api/contabilidad/maestros/plan-cuenta.service';
import { MatDialog } from '@angular/material/dialog';
import { ModificacionesDialog, ValidacionCtaDiferenciaCambio } from '../dialogs';
import { FlujoCajaService } from 'src/app/services/api/tesoreria/maestros/fljujo-caja.service';

@Component({
  selector: 'app-plan-cuenta-form',
  templateUrl: './plan-cuenta-form.component.html',
  styleUrls: ['./plan-cuenta-form.component.css']
})
export class PlanCuentaFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  buttonsName: INameConstant = NAMES_CONSTANTS;

  planCuenta: PlanCuenta;

  year: string;
  month: string;

  monedas: any[] = [];
  tiposCambio: any[] = [];
  flujos: any[] = [];
  cuentas: any[] = [];


  activateCuentaDestino: boolean = false;

  tipoCambio$: Subscription;
  flujo$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  planCuenta$: Subscription;
  cuentas$: Subscription;
  sendForm$: Subscription;

  uid: string;

  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _cajaBancoService: CajaBancoService,
    private _flujoCajaService: FlujoCajaService,
    private _planCuentaService: PlanCuentaService,
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
    public dialog: MatDialog
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.usuario = this._authService.getUsuarioSistema();
    this.planCuenta = new PlanCuenta();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.loadMaestros();
      }
    )
  }

  esCuentaDeDosDigitos(): boolean {
    if(!this.planCuenta.ccn_codccn) return false;
    return this.planCuenta.ccn_codccn.length == 2;
  }

  loadMaestros(): void {
    this.tipoCambio$ = this._contabilidadService.listarTipoCambio(this.year, this.month).subscribe(
      tiposCambio => {
        this.tiposCambio = tiposCambio;

        this.flujo$ = this._flujoCajaService.obtenerFlujoCajas().subscribe(
          flujos => {
            this.flujos = flujos;

            this.cuentas$ = this._planCuentaService.obtenerPlanCuentas().subscribe(
              cuentas => {

                this.cuentas = cuentas;
                console.log('cuentas', this.cuentas)

                if(this.uid != '0') {
                  this.obtenerPlanCuenta();
                  return;
                }
                this.loaderData = false;

              },
              error => {
                this._snackBarService.showError("Error al obtener cuentas", "OK");
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError("Error al obtener flujos de caja", "OK");
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
        this.loaderData = false;
      }
    )
  }

  obtenerPlanCuenta(): void {
    this.planCuenta$ = this._planCuentaService.obtenerPlanCuenta(this.uid).subscribe(
      planCuenta => {
        this.planCuenta = planCuenta;
        this.loaderData = false;

      },
      error => {
        this._snackBarService.showError("Error al obtener plan de cuenta", "OK");
        this.loaderData = false;
      }
    )
  }

  verModificaciones(): void {
    const dialogRef = this.dialog.open(ModificacionesDialog, {
      width: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  validarCtasDiferenciaCambio(): void {
    const dialogRef = this.dialog.open(ValidacionCtaDiferenciaCambio, {
      width: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarPlanDeCuenta(f): this.actualizarPlanDeCuenta(f);
  }

  registrarPlanDeCuenta(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._planCuentaService.registrarPlanCuenta(this.planCuenta).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Plan de cuenta registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarPlanDeCuenta(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._planCuentaService.actualizarPlanCuenta(this.planCuenta).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Plan de cuenta actualizado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  seleccionarCBMovimiento(): void {
    console.log('ind movimiento', this.planCuenta.ccn_tipccn);
    if(!this.planCuenta.ccn_tipccn) {
      this.planCuenta.ccn_indana = 0;
      this.planCuenta.ccn_indccs = 0;
      this.planCuenta.ccn_indccf = 0;
      this.planCuenta.ccn_indpls = 0;
      this.planCuenta.ccs_codccs = null;
      this.planCuenta.ccn_codcie = null;
    }
  }

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/plan-de-cuentas']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoCambio$,
      this.flujo$,
      this.period$,
      this.loading$,
      this.planCuenta$,
      this.sendForm$
    ])
  }

}
