import { HttpClient, HttpClientModule } from "@angular/common/http";
import { SituacionOportunidadDialog } from "./situacion-oportunidad-dialog/situacion-oportunidad-dialog.component";
import { SituacionOportunidadFormComponent } from "./situacion-oportunidad-form/situacion-oportunidad-form.component";
import { SituacionOportunidadListComponent } from "./situacion-oportunidad-list/situacion-oportunidad-list.component";
import { SituacionOportunidadSelect } from "./situacion-oportunidad-select/situacion-oportunidad-select.component";
import { HttpLoaderFactory } from "src/app/app.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "@shared/shared.module";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MaterialModule } from "src/app/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [SituacionOportunidadFormComponent, SituacionOportunidadListComponent, SituacionOportunidadDialog, SituacionOportunidadSelect],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    SituacionOportunidadFormComponent, SituacionOportunidadListComponent, SituacionOportunidadDialog, SituacionOportunidadSelect
  ]
})
export class SituacionOportunidadModule {}
