export class TipoInventario {
  TIN_CODTIN: string;
  TIN_DESCRI: string;
  TIN_DESABR: string;
  TIN_INDSTA: string;
  TIN_FECUPD: Date;
  TIN_CODUSE: string;
  TIN_CODOFI: string;

  constructor(){
    this.TIN_INDSTA = "1";
    this.TIN_FECUPD = new Date();
  }
}
