import { Component, Input, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { fillTable, searchInTable } from "@utils/tables/table";

export interface IHeaderKey {
  header: string;
  key: string;
}

@Component({
  selector: 'table-cotent',
  templateUrl: './table-content.component.html',
  styleUrls: ['./table-content.component.scss']
})
export class TableContent {
  dataSource: MatTableDataSource<any>;
  @Input() data: Array<any> = [];
  @Input() tHeaders: Array<IHeaderKey> = [];
  @Input() columns: Array<string> = [];
  @Input() editLink: string;
  @Input() uid: string;

  @Input() title: string;
  @Input() url: string;
  @Input() entity: string;
  @Input() params: Array<string>;

  buttonName: INameConstant = NAMES_CONSTANTS;

  displayedColumns: string[] = ['accion', ...this.columns];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {
    const haeders = this.tHeaders.map(item => item.key);

    this.dataSource = fillTable(this.data, this.paginator, this.sort);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable( event, this.dataSource )
  }

  obtenerParametros(): Array<string> {
    return [this.url, ...this.params];
  }

}
