import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@shared/services/snackbar.service';
import { AlmacenService, ContabilidadService } from 'src/app/services';

class FormProps {
  tdo_codtdo: string;
  sad_codsad: string;
  cco_numdoc: string;
}

@Component({
  selector: 'completar-num-factura',
  templateUrl: 'completar-num-factura.component.html'
})

export class CompletarNumFacturaComponent implements OnInit {

  loaderData: boolean = false;

  registro: FormProps = new FormProps();
  tiposDocumento: any[] = [];
  series: any[] = [];

  @ViewChild('iNroDoc') nroDocElement: ElementRef;
  @ViewChild('iSerie') serieElement: ElementRef;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _snackBarService : SnackBarService,
    private _almacenService: AlmacenService,
    public dialogRef: MatDialogRef<CompletarNumFacturaComponent>,

  ) { }

  ngOnInit() {
    this.loadMaestros();
  }

  loadMaestros() {

    this.loaderData = true;
    this._contabilidadService.obtenerTiposDocumento().subscribe(
      response => {
        console.log('maestros', response)
        this.tiposDocumento = response;

        this.registro.tdo_codtdo = 'FAC';

        this.loaderData = false;
        this.seleccionarTipoDoc();
      },
      error => {
        console.log('error', error)
        this.loaderData = false;
      }
    )
  }

  seleccionarTipoDoc() {

    if (this.registro.tdo_codtdo) {

      this._almacenService.listarSerieDocumentos(this.registro.tdo_codtdo).subscribe(
        series => {
          console.log('series', series)
          this.series = series;
          if (series && series.length > 0) {
            this.registro.cco_numdoc = `${series[0].sdo_codsdo}`;

            this.registro.sad_codsad = series[0].sdo_codsdo;

          }

        },
        error => this._snackBarService.showError(error.error.msg, "OK")

      )
    } else {
      this.nroDocElement.nativeElement.focus()
      this.nroDocElement.nativeElement.select()
    }

  }

  aceptar() {
    this.dialogRef.close(this.registro);
  }

  seleccionarSerie(): void {
    this.registro.cco_numdoc = `${this.registro.sad_codsad}`;
  }

  autocompletarNroDoc(): void {

    if (!this.registro.sad_codsad) {
      this._snackBarService.showError('Establecer numero de serie', 'Ok');
      return;
    }

    if (this.registro.cco_numdoc.length <= 4) {
      this._snackBarService.showError('Escribir el número de documento', 'Ok');
      return;
    }

    let completado = '';

    if(this.registro.cco_numdoc.includes(this.registro.sad_codsad)) {
      const letras = this.registro.cco_numdoc.substring(4)
      console.log('letras', letras)
      completado = this.completarDigitosNumDoc(letras);
      console.log('letras', completado)
    } else {
      completado = this.completarDigitosNumDoc(this.registro.cco_numdoc);
    }
    console.log('this.registro.cco_numdoc.slice(4)', this.registro.cco_numdoc.slice(4))

    this.registro.cco_numdoc = this.registro.sad_codsad + completado;


  }

  completarDigitosNumDoc(numdoc: string): string {
    if (numdoc.length < 8) {
      const digitosFaltantes = 8 - numdoc.length;

      for (let index = 0; index < digitosFaltantes; index++) {
        numdoc = '0' + numdoc;
      }

      return numdoc;
    }

    return numdoc;
  }


}
