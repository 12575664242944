import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimalPlaces]'
})
export class DecimalPlacesDirective {

  cantidadDecimales: number = 2;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const value = this.el.nativeElement.value;
    // const decimalPlaces = event.target.attributes.decimalPlaces.value;
    const decimalPlaces = this.cantidadDecimales;
    if(decimalPlaces == 0) {
      this.el.nativeElement.value.replaceAll(".", "");
      this.el.nativeElement.value = parseFloat(value).toFixed(0);
    }
    if (value.includes('.')) {
      const parts = value.split('.');
      if (parts[1].length > decimalPlaces) {
        this.el.nativeElement.value = parseFloat(value).toFixed(decimalPlaces);
      }
    }
  }
}

@Directive({
  selector: '[appDecimalPlaces3]'
})
export class DecimalPlacesDirective3 {

  cantidadDecimales: number = 3;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const value = this.el.nativeElement.value;
    // const decimalPlaces = event.target.attributes.decimalPlaces.value;
    const decimalPlaces = this.cantidadDecimales;
    if(decimalPlaces == 0) {
      this.el.nativeElement.value.replaceAll(".", "");
      this.el.nativeElement.value = parseFloat(value).toFixed(0);
    }
    if (value.includes('.')) {
      const parts = value.split('.');
      if (parts[1].length > decimalPlaces) {
        this.el.nativeElement.value = parseFloat(value).toFixed(decimalPlaces);
      }
    }
  }
}
