<app-pavso-title-section [title]="'Centro de costo'" [module]="'Contabilidad'" [usuario]="usuario" [fecha]="fecha"
  [ngClass]="'pav-form'" [estado]="centro.ccs_indsta">
</app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <div [ngStyle]="{display: 'flex'}">
            <mat-form-field>
              <mat-label>Código</mat-label>
              <input type="text" [(ngModel)]="centro.ccs_codccs" matInput placeholder="Código" name="ccs_codccs"
                required>
            </mat-form-field>

            <button (click)="copiarCentroCosto()" mat-icon-button color="primary"
              aria-label="Example icon button with a home icon" type="button">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </div>

        <div class="col s12 m12 l3">
          <mat-checkbox class="example-margin" [(ngModel)]="centro.ccs_indpry" name="ccs_indpry">Proyecto</mat-checkbox>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo OT</mat-label>
            <mat-select [(ngModel)]="centro.ccs_tipotr" required name="ccs_tipot">
              <mat-option value="1">
                tipo ot 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <textarea [(ngModel)]="centro.ccs_descri" matInput placeholder="Descripción" name="ccs_descri"></textarea>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <select-search
              [label]="'Responsable'"
              [placeholder]="'Ej. SEBASTIAN'"
              [name]="'cli_codcli'"
              [value]="'cli_codcli'"
              [description]="'description'"
              [data]="auxiliares"
              [model]="centro.cli_codcli"
              (cambioSelect)="centro.cli_codcli = $event"
            ></select-search>
          <!-- <mat-form-field>
            <mat-label>Responsable</mat-label>
            <mat-select [(ngModel)]="centro.cli_codcli" required name="cli_codcli">
              <mat-option value="1">
                responsable 1
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <div class="col s12 m12 l3">

          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select [(ngModel)]="centro.tmo_codtmo" required name="tmo_codtmo">
              <mat-option [value]="'SO'">
                Soles
              </mat-option>
              <mat-option [value]="'DO'">
                Dólares
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Cambio</mat-label>
            <input [(ngModel)]="centro.ccs_tipcam" type="text" matInput placeholder="Tipo Cambio" [ngStyle]="{textAlign: 'right'}" name="ccs_tipcam"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Importe</mat-label>
            <input type="number" [(ngModel)]="centro.ccs_imptot" matInput placeholder="Importe" [ngStyle]="{textAlign: 'right'}" name="ccs_imptot"
              required>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Categoría</mat-label>
            <mat-select [(ngModel)]="centro.cac_codcac" required name="cac_codcac">
              <mat-option *ngFor="let categoria of categorias" [value]="categoria.CFU_CODCFU">
                {{categoria.CFU_DESCRI}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Grupo</mat-label>
            <input type="text" matInput placeholder="Grupo" name="grupo" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <select-search [label]="'Auxiliar'" [placeholder]="'Ej. SEBASTIAN'" [name]="'cli_codcli'"
            [value]="'cli_codcli'" [description]="'description'" [data]="auxiliares" [model]="centro.cli_codaux"
            (cambioSelect)="centro.cli_codaux = $event"></select-search>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-checkbox class="example-margin" [(ngModel)]="centro.ccs_indapr" name="ccs_indapr">Aprobado</mat-checkbox>
          <mat-checkbox class="example-margin">Costo Genérico</mat-checkbox>
        </div>
      </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/centros-de-costo">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
