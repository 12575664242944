import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Cargo } from 'src/app/models';
import { ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { CargoService } from 'src/app/services/api/nomina/maestros/cargo.service';

@Component({
  selector: 'app-cargo-form',
  templateUrl: './cargo-form.component.html',
  styleUrls: ['./cargo-form.component.scss']
})
export class CargoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;
  cargo: Cargo;

  cargo$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  requiredCodigo: boolean = true;

  uid: string;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _cargoService: CargoService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {
    this.cargo = new Cargo();

    this.usuario = this._configurationService.obtenerIdUsuario();

    this.cargo.codcia = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(id == '0') return;
      this.obtenerCargo();
    })
  }

  obtenerCargo(): void {
    this.cargo$ = this._cargoService.listarCargos().subscribe(
      cargos => this.cargo = cargos.find(item => item.codnca == this.uid),
      error => this._snackBarService.showError('Error al obtener tipos de documento', 'OK')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    this.cargo.coduse = this.usuario;
    this.uid == '0'? this.registrarCargo(f): this.actualizarCargo(f);
  }

  /**
   * Método para registrar un nuevo Cargo
   * @param f
   */
  registrarCargo(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._cargoService.registrarCargo(this.cargo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Cargo Registrado', '400px', '400px', '');
        f.resetForm();
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para actualizar contrato
   * @param f
   */
  actualizarCargo(f:NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._cargoService.EditarCargo(this.cargo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Cargo Actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, "OK");
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/cargos']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cargo$,
      this.loading$,
      this.sendForm$
    ])
  }

}
