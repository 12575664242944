import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fillTable } from '@utils/tables/table';
import { DialogService } from '@shared/services/dialog.service';
import { Establishment } from "src/app/models";
import { SnackBarService } from "@shared/services/snackbar.service";
import { ConfigurationService, VentasService } from "src/app/services";
import { DiasEntrega } from "src/app/models/ventas/dias-entrega";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";


@Component({
  selector: 'cliente-establecimiento-form-dialog',
  templateUrl: './cliente-establecimiento-form.dialog.html',
  styleUrls: ['./cliente-establecimiento-form.dialog.css'],
})
export class ClienteEstablecimientoFormDialog implements OnInit{
  establecimiento: Establishment;
  existeEstablecimiento: boolean = false;
  diasEntrega:any[]
  loaderReg: boolean;
  //Utilizado para Dias de entrega
  displayedColumnsDiasEntrega: string[] = ['acciones', 'dia', 'datosAdicionales', 'hora_inicio', 'hora_maxima', 'referencia'];
  dataSourceDiasEntrega: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginatorDiasEntrega: MatPaginator;
  @ViewChild(MatSort) sortDiasEntrega: MatSort;

  paises: any[] = [];
  ubicaciones: any[] = [];
  zonas: any[] = [];
  sendForm$: Subscription;
  mostrarCodigo: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ClienteEstablecimientoFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,

  ) {
    this.dataSourceDiasEntrega = fillTable([], this.paginatorDiasEntrega, this.sortDiasEntrega);

    this.establecimiento = new Establishment();

    this.paises = data.paises;
    this.ubicaciones = data.ubicaciones;
    this.zonas = data.zonas;

    if(data.establecimiento) {
      console.log('entro')
      this.existeEstablecimiento = true;
      this.establecimiento = data.establecimiento;

    }
    else{
      this.establecimiento.CIA_CODCIA = data.cliente.cia_codcia;
      this.establecimiento.CLI_CODCLI = data.cliente.cli_codcli;
      this.establecimiento.LDE_INDSTA = '1';
      this.establecimiento.LDE_CODUSE = this._configurationService.obtenerIdUsuario();
    }
  }
  ngOnInit(){
      this.obtenerDias();
  }

  obtenerDias(){
      this._ventasService.obtenerDias().subscribe(
        (res) => {
          console.log("suscrito", res)
          this.diasEntrega=res;
          if(this.existeEstablecimiento){
            this.establecimiento.detalle_dias_cds.forEach(item => {
              const dia = this.diasEntrega.find(dia => dia.dia_coddia == item.dia_coddia);
              item.dia_desesp = dia? dia.dia_desesp: '';
            })
            this.dataSourceDiasEntrega = fillTable(this.establecimiento.detalle_dias_cds, this.paginatorDiasEntrega, this.sortDiasEntrega);
          }
        }
      )
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.existeEstablecimiento ? this.editarEstablecimiento(f): this.registrarEstablecimiento(f)

  }

  registrarEstablecimiento(f: NgForm): void {
    this.sendForm$ = this._ventasService.registrarEstablecimiento(this.establecimiento).subscribe(
      response => {
        this._snackBarService.showSuccess( 'Establecimiento registrado', 'Ok');
        this.dialogRef.close({establecimiento:this.establecimiento, esNuevo:true});
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  editarEstablecimiento(f: NgForm): void {

    this.sendForm$ = this._ventasService.actualizarEstablecimiento(this.establecimiento).subscribe(
      response => {
        this._snackBarService.showSuccess( 'Establecimiento actualizado', 'Ok');
        this.dialogRef.close({establecimiento:this.establecimiento, esNuevo:false});
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }
  agregarDiasEntrega():void{

    let item = new DiasEntrega();
    item.dia_coduse = this._configurationService.obtenerIdUsuario();
    if(this.establecimiento.CIA_CODCIA && this.establecimiento.CLI_CODCLI){
      item.cia_codcia = this.establecimiento.CIA_CODCIA;
      item.cli_codcli = this.establecimiento.CLI_CODCLI;
    }

    this.establecimiento.detalle_dias_cds.push(item);

    this.dataSourceDiasEntrega = fillTable(this.establecimiento.detalle_dias_cds, this.paginatorDiasEntrega, this.sortDiasEntrega);
   }
  editarDiasEntrega(row): void {
    row.isEditing = !row.isEditing;
  }
  confirmarDiasEntrega(row:DiasEntrega, index:number):void{
    if(!row.dia_coddia ) {

    return this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok');
    }
    row.isEditing = !row.isEditing;
  }

  hayDatosAdicionales($event,row:DiasEntrega){
    console.log("soy :",$event.checked)
    row.isAditionalActive = $event.checked
  }

  quitarDiasEntrega(row:DiasEntrega,index:number):void{
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceDiasEntrega.data.filter((item,i) => item.dia_coddia != row.dia_coddia && i != index);
        this.establecimiento.detalle_dias_cds = filtered;
        this.dataSourceDiasEntrega = fillTable(filtered, this.paginatorDiasEntrega, this.sortDiasEntrega);
      }
    })
  }
  seleccionarDiasEntrega($event, row: DiasEntrega): void {
    const diaEntrega = this.diasEntrega.find(item => item.dia_coddia == $event.value);
    row.dia_desesp = diaEntrega.dia_desesp;
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.sendForm$]);
  }

  // confirmarServicio(row: ServiceSupplier): void {
  //   if(!row.tse_codtse ) {
  //     this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
  //     return;
  //   }

  //   row.isEditing = !row.isEditing;

  // }

  // quitarServicio(row): void {
  //   this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
  //     if(result) {
  //       const filtered = this.dataSourceDiasEntrega.data.filter(item => item.correlativo != row.correlativo);
  //       filtered.forEach((element, key) => {
  //         element['correlativo'] = key;
  //       })
  //       this.establecimiento.detalle_dias_cds = filtered;
  //       this.dataSourceDiasEntrega = fillTable(filtered, this.paginatorDiasEntrega, this.sortDiasEntrega);
  //     }
  //   })
  // }
}
