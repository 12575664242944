import { HttpClient } from "@angular/common/http";
import { ConfigurationService } from "../../configuration.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { OperacionFrecuente } from "src/app/models/caja-banco/operacion-frecuente";

@Injectable({
  providedIn: 'root'
})
export class OperacionFrecuenteService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerOperacionesFrecuentes() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/operacionfrecuente/listar/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((operaciones: any) => {
        return operaciones.map(operacion => {
          operacion['description'] = `${operacion.ofb_descri} | ${operacion.ano_codano}${operacion.mes_codmes}${operacion.sco_codsco}${operacion.ldc_corldc}`
          operacion['code'] = `${operacion.ano_codano}${operacion.mes_codmes}${operacion.sco_codsco}${operacion.ldc_corldc}`
          return operacion;
        })
      })
    );
  }

  obtenerOperacionFrecuente(codano, codmes, codsco, corldc) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/operacionfrecuente/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codano}/${codmes}/${codsco}/${corldc}`
    );
  }

  registrarOperacionFrecuente(payload: OperacionFrecuente) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/operacionfrecuente/crear`,
      payload
    );
  }

  actualizarOperacionFrecuente(payload: OperacionFrecuente) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/operacionfrecuente/modificar`,
      payload
    );
  }
}
