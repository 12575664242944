<app-pavso-title-section [title]="'Condición de pago'" [module]="'Ventas'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (keydown.enter)="$event.preventDefault()">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Código</mat-label>
          <input matInput placeholder="Código" [(ngModel)]="condicion.CPA_CODCPA" name="CPA_CODCPA">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" [(ngModel)]="condicion.CPA_DESCRI" name="CPA_DESCRI">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre Abreviado</mat-label>
          <input matInput placeholder="Nombre Abreviado" [(ngModel)]="condicion.CPA_DESABR" name="CPA_DESABR">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select [(ngModel)]="condicion.CPA_INDSTA" name="CPA_INDSTA">
            <mat-option value="1">
              ACTIVO
            </mat-option>
            <mat-option value="2">
              INACTIVO
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Plazo</mat-label>
          <input matInput placeholder="Nombre Abreviado" [(ngModel)]="condicion.CPA_DIAPLA" name="CPA_DIAPLA">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Grupo</mat-label>
          <mat-select [(ngModel)]="condicion.GCP_CODGCP" name="GCP_CODGCP">
            <mat-option value="01">
              CONTADO
            </mat-option>
            <mat-option value="02">
              CRÉDITO
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Nro. Cuotas</mat-label>
          <input type="number" matInput placeholder="Nro. Cuotas" [(ngModel)]="condicion.CPA_NROCUO" name="CPA_NROCUO">
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <pav-form-actions
    [disabled]="loaderData"
    [btnName]="btnName"
    [isLoading]="loaderReg"
    urlBack="/modulo-ventas/condiciones-de-pago"
  >
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
