
import { LanguageState } from "@data/interfaces/state/language.state";
import { createReducer, on } from "@ngrx/store";
import { cambiarIdioma } from "../actions/language.actions";

const defaultLanguage = localStorage.getItem('language');

export const initialState: LanguageState = {language: defaultLanguage};

const _languageReducer = createReducer(
  initialState,
  on(cambiarIdioma, (state, props) => state = {language: props.language}),
);

export function languageReducer(state, action) {
  return _languageReducer(state, action)
}
