import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultaCobranzaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  actualizarLogsUltimosMensajesWhatsapp(payload: {cia_codcia: string, estado: boolean, documentos: Array<string>}): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/facturas/whatsapplog`,
      payload
    )
  }

}
