import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Marca } from "src/app/models/almacen";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class MarcaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerMarcas(): Observable<any> {

    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/producto/marca/listar/${this._configurationService.obtenerCompaniaCliente()}`);

  }

  /**
   *
   * @param id
   * @returns
   */
  obtenerMarca(id: string): Observable<any> {
    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/producto/marca/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${id}`)
  }

  /**
   *
   * @param marca
   * @returns
   */
  registrarMarca(marca: Marca): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/producto/marca/crear`,
      marca
    );
  }

  /**
   *
   * @param marca
   * @returns
   */
  actualizarMarca(marca: Marca): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/producto/marca/modificar`,
      marca
    );
  }
}
