import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable, searchInTable } from '@utils/tables/table';
import { cargarProductos } from '@shared/state/actions';
import { Subscription } from 'rxjs';
import { ExportExcelService } from 'src/app/services';

@Component({
  selector: 'app-producto-list',
  templateUrl: './producto-list.component.html',
  styleUrls: ['./producto-list.component.css']
})
export class ProductoListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'prd_codprd', 'DESC_ESPA', 'UNIDAD', 'COD_INVENTARIO', 'DESC_INVENTARIO', 'ESTADO'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  products$: Subscription;
  loading$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _exportExcelService: ExportExcelService
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loaderData = true;
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.products$ = this.store.select('products').subscribe(({products})=> {
          this.dataSource = fillTable(products, this.paginator, this.sort);
          this.loaderData = false;
        })

        this.store.dispatch(cargarProductos());
      }
    })

  }

  descargarXML(): void {
    console.log('descargar xml');
    console.log(this.dataSource.data)

    const dataToExport = [];

    this.dataSource.data.forEach(item => {
      if(item.ESTADO == 'VIGENTE') {
        dataToExport.push({
          'CÓDIGO': item.prd_codprd,
          'DESRIPCIÓN': item.DESC_ESPA,
          'UNIDAD': item.UNIDAD,
          'COD_INVENTARIO': item.COD_INVENTARIO,
          'DESCRIPCION_INVENTARIO': item.DESC_INVENTARIO,
          'FAMILIA': item.DESC_FAMILIA,
          'CODIGO_SUNAT': item.prd_codsun,
          'MARCA': item.DESC_MARCA,
          'CONTENIDO': item.prd_conten,
          'ESTADO': item.ESTADO,
        })
      }
    })
    console.log(this.dataSource.data)
    this._exportExcelService.exportExcel({title: 'Listado de productos', data: dataToExport});
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {

  }

}
