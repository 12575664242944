import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { ConfigurationService } from 'src/app/services';
import { Store } from '@ngrx/store';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import moment from 'moment';
import { ConciliacionBancaria } from 'src/app/models';
import { DetalleConciliacionBancaria } from 'src/app/models/caja-banco/conciliacion_bancaria';
import { MatButton } from '@angular/material/button';
import { MatSelect } from '@angular/material/select';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';

@Component({
  selector: 'app-conciliacion-bancaria',
  templateUrl: './conciliacion-bancaria.component.html',
  styleUrls: ['./conciliacion-bancaria.component.css'],

})
export class ConciliacionBancariaComponent implements OnInit, OnDestroy {

  date = new FormControl(moment());

  tiposDoc: any[] = [];
  auxiliares: any[] = [];
  cuentas: any[] = [];

  auxiliarFilterCtrl: FormControl;
  cuentaTotalFilterCtrl: FormControl;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  displayedColumns: string[] = ['acciones', 'voucher', 'fecha_voucher', 'fecha_operacion', 'tdo_codtdo', 'ldi_docref', 'ingresos', 'salidas', 'tmo_codtmo', 'periodo_conciliacion', 'ind_c', 'nombre_aux', 'ldi_gloldi', 'cli_codcli', 'ccn_codccn', 'cuenta_bancaria'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  fecha: Date = new Date();

  year: string;
  month: string;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  conciliacionBancaria: ConciliacionBancaria;
  montosDebeHaber:any;
  @ViewChild('btnAdd') buttonAddRefElement: MatButton;
  @ViewChildren("cuentaTotalSelect") selectCuentas: QueryList<MatSelect>;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService
  ) {

    this.conciliacionBancaria = new ConciliacionBancaria();
    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
      this.conciliacionBancaria.ano_codano = year;
      this.conciliacionBancaria.mes_codmes = month;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.conciliacionBancaria.detalle = [];
    this.conciliacionBancaria.ordenes = [];
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  registrarConciliacion(f: NgForm): void {
    if(validarPeriodo(this.fecha, this.year, this.month)) {
      this._dialogService.openDialog(SuccessComponent, 'Conciliación Bancaria Registrada', '300px', '', '');
      return;
    }

    this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK');

  }

  agregarItem(): void {
    let detailItem = new DetalleConciliacionBancaria();

    detailItem.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    detailItem.ano_codano = this.year;
    detailItem.mes_codmes = this.month;
    detailItem.ldi_corldi = `0000${this.conciliacionBancaria.detalle.length + 1}`;

    detailItem.tmo_codtmo = "SO";
    detailItem.ldi_inddha = "D";
    detailItem.tdo_codtdo = "FAC";
    detailItem.ldi_impnac = 0;
    detailItem.ldi_impmex = 0;
    detailItem.ldi_tcameu = 0;
    detailItem.ldi_inddes = 0;
    detailItem.fca_codfca = "";
    detailItem.ldi_anoccl = this.year;
    detailItem.ldi_mesccl = this.month;
    detailItem.ldi_fecemi = this.conciliacionBancaria.cco_fecemi;
    detailItem.ldi_fecven = this.conciliacionBancaria.cco_fecven;
    detailItem.ldi_impsal = 0;
    detailItem.ldi_indccl = 0;
    detailItem.ldi_indcco = "N";
    detailItem.ldi_inddes = 0;
    detailItem.ldi_mesccl = "";
    detailItem.sco_codsco = this.conciliacionBancaria.sco_codsco;
    detailItem.suc_codsuc = "01";
    detailItem.tdo_docref = "";
    detailItem.vde_codvde = "";
    detailItem.ldi_tipcam = this.conciliacionBancaria.cco_tipcam;
    detailItem.ldi_gloldi = this.conciliacionBancaria.cco_glocco;
    detailItem.ldi_tipcam = this.conciliacionBancaria.cco_tipcam;

    this.conciliacionBancaria.detalle.push(detailItem);
    this.dataSource = fillTable(this.conciliacionBancaria.detalle, this.paginator, this.sort);

    this.conciliacionBancaria.detalle.forEach((element, key) => {
      element.ldi_corldi = (key > 9)?`000${key + 1}`: `0000${key + 1}`;
    })

  }

  agregar(): void {
    const producto = new DetalleConciliacionBancaria();

    this.conciliacionBancaria.detalle.push(producto);

    this.dataSource = fillTable(this.conciliacionBancaria.detalle, this.paginator, this.sort);
  }

  quitar(index): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const detalle = this.conciliacionBancaria.detalle.filter((element, i) => index != i );

        this.conciliacionBancaria.detalle = detalle;

        this.dataSource = fillTable(this.conciliacionBancaria.detalle, this.paginator, this.sort);
      }

    })
  }

  confirmarNuevoItem(item: DetalleConciliacionBancaria): void {
    if(
      item.ccn_codccn &&
      item.tdo_codtdo &&
      item.ldi_docref &&
      item.tmo_codtmo &&
      item.ldi_inddha &&
      item.ldi_impnac &&
      item.ldi_impmex &&
      item.ldi_gloldi
      ) {
      item.isEditing = !item.isEditing;
      this.montosDebeHaber[0].mn = 0;
      this.montosDebeHaber[0].me = 0;
      this.montosDebeHaber[1].mn = 0;
      this.montosDebeHaber[1].me = 0;
      this.montosDebeHaber[2].mn = 0;
      this.montosDebeHaber[2].me = 0;

      this.conciliacionBancaria.detalle.forEach(element => {
        if(element.ldi_inddha == "D") {
          this.montosDebeHaber[0].mn += element.ldi_impnac;
          this.montosDebeHaber[0].me += element.ldi_impmex;
        } else {
          this.montosDebeHaber[1].mn += element.ldi_impnac;
          this.montosDebeHaber[1].me += element.ldi_impmex;
        }
      });

      this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
      this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

      this.buttonAddRefElement.focus();
    } else {
      this._snackBarService.showError("Es necesario completar todos los campos", "OK");
    }
  }

  quitarItem(id): void {
    let details = [];
    this.conciliacionBancaria.detalle.forEach(element => {
      if(element.ldi_corldi != id) {
        details.push(element)
      }
    });

    this.conciliacionBancaria.detalle = details;
    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.conciliacionBancaria.detalle.forEach((element, key) => {
      element.ldi_corldi = `0000${key + 1}`;
    });
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$]);
  }
}
