import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";

@Component({
  selector: 'contacto-cliente-list',
  templateUrl: './contacto-cliente-list.component.html',
  styleUrls: ['./contacto-cliente-list.component.scss']
})
export class ContactoClienteListComponent implements OnInit, OnDestroy {
  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'cli_codcli', 'cli_nomcli', 'cli_corele', 'cli_numtlf', 'cli_fecupd'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  loading$: Subscription;
  clientes$: Subscription;

  clientes: any[] = [];
  clienteSeleccionado: string;

  constructor(
    private store: Store<PavsoState>,
    private _clienteService: ClienteService,
    private _snackBarService: SnackBarService
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort)
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.cargarMaestro()
    });
  }

  cargarMaestro(): void {
    this.loaderData = true;

    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {

    this.dataSource = searchInTable(event, this.dataSource)

  }

  obtenerContactosPorCliente(): void {
    const clienteId = this.clienteSeleccionado;
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.clientes$
    ])
  }
}
