import { formatDateClean, formatDateWithDash } from "@utils/formats/date.format";

export class ExchangeRate {
  PAI_CODPAI: string;
  TCA_FECTCA: string;
  TMO_MONNAC: string;
  TMO_MONEXT: string;
  TCA_COMOFI: number;
  TCA_VENOFI: number;
  ANO_CODANO: string;
  MES_CODMES: string;
  DIA_CODDIA: string;
  TCA_INDSTA: string;
  TCA_FECUPD: Date;
  TCA_CODUSE: string;
  constructor(
  ) {
    this.PAI_CODPAI = "01";
    this.TMO_MONNAC = "SO";
    this.TMO_MONEXT = "DO";
    this.TCA_INDSTA = "1";
    this.TCA_FECTCA = formatDateWithDash(new Date());
    this.TCA_COMOFI = 0;
    this.TCA_VENOFI = 0;
  }
}
