export class MotivoParadaMaquina{
    cia_codcia:string;
    mpm_codmpm:string;
    mpm_desmpm:string;
    mpm_indsta:string;
    mpm_fecupd:Date;
    mpm_coduse:string;
    constructor(){
        this.mpm_codmpm = '';
        this.mpm_indsta = '1',
        this.mpm_fecupd = new Date(); 
    }
}