<mat-tab-group>
  <mat-tab label="Cargar Nuevo">
    <br>
    <ngx-dropzone (change)="onSelect($event)" [accept]="'image/jpeg, image/jpg, .pdf'">
      <ngx-dropzone-label>Cargar imágenes (formatos permitidos JPEG o JPG) o PDF!</ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
      </ngx-dropzone-preview>
      <mat-spinner diameter="40" *ngIf="loadUpload"></mat-spinner>
    </ngx-dropzone>

    <br>
    <div align="end">
      <button mat-stroked-button color="primary">Cargar Documento(s)</button>
    </div>
    <br>
  </mat-tab>
  <mat-tab label="Mis documentos">
    <br>
    <div [ngStyle]="{height: '240px'}">
      <div class="document" *ngFor="let documento of documentos">
        <div class="document-content">
          <p>
            {{documento}}
          </p>
          <div>
            <button mat-mini-fab color="primary" aria-label="Example icon button with a home icon">
              <mat-icon>description</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

  </mat-tab>
</mat-tab-group>
