<h1 mat-dialog-title>Detalle de liquidación</h1>

<div mat-dialog-content>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l1">
        <mat-form-field>
          <mat-label>Año</mat-label>
          <input
            type="number"
            [(ngModel)]="liquidacion.ano_codano"
            matInput
            placeholder="Año"
            name="ano_codano"
            required
            readonly
          />
        </mat-form-field>
      </div>
      <div class="col s12 m12 l1">
        <mat-form-field>
          <mat-label>Mes</mat-label>
          <input
            type="number"
            [(ngModel)]="liquidacion.mes_codmes"
            matInput
            placeholder="Mes"
            name="mes_codmes"
            required
            readonly
          />
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <app-state-select
          [state]="liquidacion.nct_indsta"
          (cambioEstado)="liquidacion.nct_indsta = $event"
        ></app-state-select>
      </div>
      <div class="col s12 m12 l7">
        <mat-form-field class="readonly-wrapper">
          <mat-label>Colaboradores</mat-label>
          <mat-select
            [(ngModel)]="liquidacion.cli_codcli"
            required
            name="cli_codcli"
            class="readonly-block"
            disabled
          >
            <mat-option
              *ngFor="let colaborador of colaboradores"
              [value]="colaborador.codcli"
            >
              {{ colaborador.nomcli }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Fecha de Registro</mat-label>
          <input
            [(ngModel)]="liquidacion.nct_fecemi"
            name="nct_fecemi"
            matInput
            [matDatepicker]="fecharegistro"
            readonly
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fecharegistro"
          ></mat-datepicker-toggle>
          <mat-datepicker #fecharegistro></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Período de liquidación Desde</mat-label>
          <input
            [(ngModel)]="liquidacion.nct_iniliq"
            name="nct_iniliq"
            matInput
            [matDatepicker]="periododesde"
            readonly
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="periododesde"
          ></mat-datepicker-toggle>
          <mat-datepicker #periododesde></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Hasta</mat-label>
          <input
            [(ngModel)]="liquidacion.nct_terliq"
            name="nct_terliq"
            matInput
            [matDatepicker]="periodohasta"
            readonly
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="periodohasta"
          ></mat-datepicker-toggle>
          <mat-datepicker #periodohasta></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Tipo de Cambio</mat-label>
          <input
            [value]="liquidacion.nct_tipcam.toFixed(2)"
            type="text"
            name="nct_tipcam"
            class="pav-input-align-right"
            matInput
            placeholder="Tipo de Cambio"
            required
            [disabled]="loaderData"
            readonly
          />
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <app-currency-select
          [moneda]="liquidacion.tmo_codtmo"
          [disable]="true"
          (cambioMoneda)="liquidacion.tmo_codtmo = $event"
        ></app-currency-select>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Fecha de Depósito</mat-label>
          <input
            [(ngModel)]="liquidacion.nct_fecdep"
            name="nct_fecdep"
            matInput
            [matDatepicker]="fechadeposito"
            readonly
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fechadeposito"
          ></mat-datepicker-toggle>
          <mat-datepicker #fechadeposito></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Período del servicio que se cancela Desde</mat-label>
          <input
            [(ngModel)]="liquidacion.nct_fecini"
            name="nct_fecini"
            matInput
            [matDatepicker]="periodocanceladesde"
            readonly
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="periodocanceladesde"
          ></mat-datepicker-toggle>
          <mat-datepicker #periodocanceladesde></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Hasta</mat-label>
          <input
            [(ngModel)]="liquidacion.nct_fecter"
            name="nct_fecter"
            matInput
            [matDatepicker]="periodocancelahasta"
            readonly
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="periodocancelahasta"
          ></mat-datepicker-toggle>
          <mat-datepicker #periodocancelahasta></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <div class="p"></div>
  <div class="pav-card-header blue">
    <div class="pav-block"></div>
    <div class="pav-card-header-title title-card">
      Tiempo computable
    </div>
  </div>
  <mat-card class="mat-elevation-z0">
    <div class="row">

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Meses</mat-label>
          <input
            [value]="liquidacion.nct_nummes.toFixed(2)"
            type="text"
            class="pav-input-align-right"
            matInput
            placeholder="Meses"
            name="nct_nummes"
            required
            readonly
          />
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Días</mat-label>
          <input
            [value]="liquidacion.nct_numdia.toFixed(2)"
            class="pav-input-align-right"
            type="text"
            matInput
            placeholder="Días"
            name="nct_numdia"
            required
            readonly
          />
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <div class="row">
    <div class="col s12 m12 l3">
      <div class="pav-card-header blue-dark">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          Conceptos remunerativos

        </div>
      </div>
      <mat-card class="mat-elevation-z0">
        <mat-form-field>
          <mat-label>Básico</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_combas.toFixed(2)"
            placeholder="Básico"
            name="nct_combas"
            required
            readonly
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Asig. Familiar</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_asifam.toFixed(2)"
            placeholder="Asig. Familiar"
            name="nct_asifam"
            required
            readonly
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Prom. Rem. Variable</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_provar.toFixed(2)"
            placeholder="Prom. Rem. Variable"
            name="nct_provar"
            required
            readonly
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Otras Remunerac.</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_otrrem.toFixed(2)"
            placeholder="Otras Remunerac."
            name="nct_otrrem"
            required
            readonly
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ult. Gratificación</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_comgra.toFixed(2)"
            placeholder="Ult. Gratificación"
            name="nct_comgra"
            required
            readonly
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Importe</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_comtot.toFixed(2)"
            placeholder="Importe"
            name="nct_comtot"
            required
            readonly
          />
        </mat-form-field>
      </mat-card>
    </div>
    <div class="col s12 m12 l3">
      <div class="pav-card-header blue">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          Remuneración computable
        </div>
      </div>
      <mat-card class="mat-elevation-z0">
        <mat-form-field>
          <mat-label>Por Año</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_remano.toFixed(2)"
            placeholder="Por Año"
            name="nct_remano"
            required
            readonly
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Un Dozavo del Mes</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_remmes.toFixed(2)"
            placeholder="Un Dozavo del Mes"
            name="nct_remmes"
            required
            readonly
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Una Treintava parte de un Dozavo</mat-label>
          <input
            type="text"
            class="pav-input-align-right"
            matInput
            [value]="liquidacion.nct_remdia.toFixed(2)"
            placeholder="Una Treintava parte de un Dozavo"
            name="nct_remdia"
            required
            readonly
          />
        </mat-form-field>
      </mat-card>
    </div>
    <div class="col s12 m12 l6">
      <div class="pav-card-header green">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          Liquidación de CTS cancelatorio

        </div>
      </div>
      <mat-card class="mat-elevation-z0">

        <div class="row">
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Cantidad por Año</mat-label>
              <input
                type="text"
                class="pav-input-align-right"
                [value]="liquidacion.nct_canano.toFixed(2)"
                matInput
                placeholder="Cantidad por Año"
                name="nct_canano"
                required
                readonly
              />
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Importe por Año</mat-label>
              <input
                type="text"
                class="pav-input-align-right"
                [value]="liquidacion.nct_impano.toFixed(2)"
                matInput
                placeholder="Importe por Año"
                name="nct_impano"
                required
                readonly
              />
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
          </div>

        </div>
        <div class="row">
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Cantidad por Mes</mat-label>
              <input
                type="text"
                class="pav-input-align-right"
                [value]="liquidacion.nct_canmes.toFixed(2)"
                matInput
                placeholder="Cantidad por Mes"
                name="nct_canmes"
                required
                readonly
              />
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Importe por Mes</mat-label>
              <input
                type="text"
                class="pav-input-align-right"
                [value]="liquidacion.nct_impmes.toFixed(2)"
                matInput
                placeholder="Importe por Mes"
                name="nct_impmes"
                required
                readonly
              />
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Interés</mat-label>
              <input
                [value]="liquidacion.nct_impint.toFixed(2)"
                type="text"
                class="pav-input-align-right"
                matInput
                placeholder="Interés"
                name="nct_impint"
                required
                readonly
              />
            </mat-form-field>
          </div>

          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Cantidad por Días</mat-label>
              <input
                type="text"
                class="pav-input-align-right"
                [value]="liquidacion.nct_candia.toFixed(2)"
                matInput
                placeholder="Cantidad por Días"
                name="nct_candia"
                required
                readonly
              />
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Importe por Días</mat-label>
              <input
                type="text"
                class="pav-input-align-right"
                placeholder="Importe por Días"
                [value]="liquidacion.nct_impdia.toFixed(2)"
                matInput
                name="nct_impdia"
                required
                readonly
              />
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Importe a Depositar</mat-label>
              <input
                [value]="liquidacion.nct_impdep.toFixed(2)"
                type="text"
                class="pav-input-align-right"
                matInput
                placeholder="Importe a Depositar"
                name="nct_impdep"
                required
                readonly
              />
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4"></div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Total</mat-label>
              <input
                type="text"
                class="pav-input-align-right"
                [value]="liquidacion.nct_impnet.toFixed(2)"
                matInput
                placeholder="Total"
                name="nct_impnet"
                required
                readonly
              />
            </mat-form-field>
          </div>


          <div class="col s12 m12 l4"></div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Importe M.E.</mat-label>
              <input
                [value]="liquidacion.nct_impmex.toFixed(2)"
                type="text"
                class="pav-input-align-right"
                matInput
                placeholder="Importe M.E."
                name="nct_impmex"
                required
                readonly
              />
            </mat-form-field>
          </div>
        </div>

      </mat-card>
    </div>
  </div>


</div>
<div mat-dialog-actions align="end">
  <button class="pav-button-action" (click)="generarInformeLiquidacion()" mat-flat-button color="primary" type="button">Generar PDF</button>

  <button class="pav-button-action" color="primary" mat-flat-button (click)="onNoClick()">Aceptar</button>
</div>
