import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClaseActivo } from "src/app/models/crm";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class ClaseActivoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerClasesActivo(): Observable<any> {
    return null;
  }

  /**
   *
   * @param clase
   * @returns
   */
  registrarClaseActivo(clase: ClaseActivo): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl}`,
      clase
    );
  }

  /**
   *
   * @param clase
   * @returns
   */
  actualizarClaseActivo(clase: ClaseActivo): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl}`,
      clase
    );
  }
}
