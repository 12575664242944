import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from 'src/app/material.module';
import { ActivoFijoComponent } from './activo-fijo.component';
import { informesComponentActivoFijo } from './pages/informes';
import { maestrosComponentActivoFijo } from './pages/maestros';
import { operacionesComponentActivoFijo } from './pages/operaciones';
import { PanelActivoFijoComponent } from './pages/panel/panel-activo-fijo.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { maestrosComprasModules } from '../compras/pages/maestros';
import { GeneralComponentsModule } from '../../components/general-components.module';

@NgModule({
  declarations: [
    ActivoFijoComponent,
    PanelActivoFijoComponent,
    ...informesComponentActivoFijo,
    ...maestrosComponentActivoFijo,
    ...operacionesComponentActivoFijo
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    SharedModule,
    FormsModule,
    NgxDropzoneModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    ...maestrosComprasModules,
    GeneralComponentsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),

  ]
})
export class ActivoFijoModule { }
