import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OrdenPago } from "src/app/models/caja-banco/orden-pago";

@Component({
  selector: 'agregar-item-modal',
  templateUrl: './agregar-item-modal.dialog.html',
  styleUrls: ['./agregar-item-modal.dialog.scss']
})
export class AgregarItemModalDialog implements OnInit {

  dataToSend: AgregarItemModalProps

  constructor(
    public dialogRef: MatDialogRef<AgregarItemModalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {
      proveedores: Array<any>,
      tiposDocumento: Array<any>,
      cuentasTotales: Array<any>,
      ordenes: Array<any>,
      centrosCosto: Array<any>,
      flujos: Array<any>,
      egresoBanco: OrdenPago
    },
  ) {
    this.dataToSend = new AgregarItemModalProps();
    this.dataToSend.proveedor = this.data.egresoBanco.CLI_CODCLI;
    this.dataToSend.moneda = this.data.egresoBanco.TMO_CODTMO;
    this.dataToSend.flujo = this.data.egresoBanco.fca_codfca;
    this.dataToSend.tipoCambio = this.data.egresoBanco.PBA_TIPCAM;
    this.dataToSend.dias = 0;
    this.dataToSend.dh = 'D';

  }

  ngOnInit(): void {
    this.establecerDias(this.dataToSend);
  }

  seleccionarCuenta(): void {
    const cuenta = this.data.cuentasTotales.find(item => item.cuenta == this.dataToSend.cuenta);

    if(cuenta.mondeda) {
      this.dataToSend.moneda = cuenta.moneda;
      return;
    }

    this.dataToSend.moneda = this.data.egresoBanco.TMO_CODTMO;
  }

  calculoImportesMN(row: AgregarItemModalProps): void {
    this.dataToSend.importeME = Number((row.importeMN / row.tipoCambio).toFixed(2));
    // this.establecerDebeHaber();
  }

  calculoImportesME(row: AgregarItemModalProps): void {
    this.dataToSend.importeMN = Number((this.dataToSend.importeME * this.dataToSend.tipoCambio).toFixed(2));
    // this.establecerDebeHaber();
  }

  establecerDias(row: AgregarItemModalProps): void {
    var newDate = new Date(this.data.egresoBanco.PBA_FECORD);
    newDate.setDate(this.data.egresoBanco.PBA_FECORD.getDate() + row.dias);

    this.dataToSend.fechaVencimiento = newDate;
  }

  aceptar(): void {
    this.dialogRef.close(this.dataToSend);
  }

}

class AgregarItemModalProps {
  proveedor: string;
  tipoDocumento: string;
  nroDocumento: string;
  dias: number;
  fechaVencimiento: Date;
  cuenta: string;
  ordenCompra: string;
  centroCosto: string;
  moneda: string;
  tipoCambio: number;
  dh: string;
  importeMN: number;
  importeME: number;
  flujo: string;
  glosa: string;
}
