import { Component, OnDestroy, OnInit } from '@angular/core';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_NOMINA_CONTRATOS } from '@data/constants/table/theader.nomina';

@Component({
  selector: 'app-contrato-list',
  templateUrl: './contrato-list.component.html',
  styleUrls: ['./contrato-list.component.css']
})
export class ContratoListComponent implements OnInit, OnDestroy {

  contrato$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_CONTRATOS;

  contratos: Array<any> = [];

  constructor(
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) {
        this.obtenerContratos();
      }
    })
  }

  obtenerContratos(): void {
    this.contrato$ = this._nominaService.listarContratos().subscribe(
      contratos => this.contratos = contratos,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.contrato$,
      this.loading$
    ])
  }

}
