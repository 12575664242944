<app-pavso-title-section [title]="'Firmas digitales'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="firmas"
    [tHeaders]="tHeaders"

    title="Todas las firmas"
    url="/modulo-planilla/firma-digital"
    entity=""
    [params]="['0']"
  ></pav-table-list>

</div>
