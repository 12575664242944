<app-pavso-title-section [title]="'apps.crm.maestros.tipo-negociacion.title' | translate" [module]="'CRM'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="tiposNegociacion"
    [tHeaders]="tHeaders"

    title="Todos los tipos"
    url="/modulo-crm/tipo-de-negociacion"
    entity=""
    [pageSize]="10"
    [params]="['0']"
  ></pav-table-list>

</div>
