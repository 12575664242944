import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PropietarioActivo } from "src/app/models/crm";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class PropietarioActivoService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerPropietariosActivo(): Observable<any> {
    return null;
  }

  /**
   *
   * @param propietario
   * @returns
   */
  registrarPropietarioActivo(propietario: PropietarioActivo): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl}`,
      propietario
    );
  }

  /**
   *
   * @param propietario
   * @returns
   */
  actualizarPropietarioActivo(propietario: PropietarioActivo): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl}`,
      propietario
    );
  }
}
