<app-pavso-title-section [title]="'Planta'" [module]="'Producción'" [estado]="planta.pla_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="planta.pla_codpla" matInput placeholder="Código" name="pla_codpla"  [disabled]="true" readonly>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="planta.pla_despla" matInput placeholder="Descripción" name="pla_despla" required [disabled]="loaderData">
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-produccion/plantas"
    ></pav-form-actions>
    <!-- {{ planta | json}} -->

  </form>
</div>
