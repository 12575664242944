import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatTabGroup } from "@angular/material/tabs";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatBytes } from "@utils/conversions/formatBytes";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ComprasService, ConfigurationService, ContabilidadService, GeneralService } from "src/app/services";
import { ProveedorService } from "src/app/services/api/compras/maestros/proveedor.service";
import { SunatService } from "src/app/services/api/sunat/sunat.service";
import * as xml2js from 'xml2js';
import { MatDialog } from "@angular/material/dialog";
import { RecepcionCompra } from "src/app/models/compras/recepcion";
import { SelectionModel } from "@angular/cdk/collections";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { OrdenCompraService } from "src/app/services/api/compras/operaciones/orden-compra.service";
import { Proveedor } from "src/app/models";
import { ReceptionCompraService } from "src/app/services/api/compras/operaciones/reception.service";
import { ContefactService } from "src/app/services/api/others/contefact.service";
import { numberWithCommas } from "@utils/formats/number.format";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { retornaDiferenciaDias } from "@utils/date/date";
import { ConfiguracionTecnica } from "src/app/models/contabilidad/configuracion-tecnica";
import { PavUploadFileModal } from "@shared/components/modal/pav-upload-file-modal/pav-upload-file.modal";
import { ConfiguracionContabilidad } from "src/app/models/contabilidad/configuracion-contabilidad";
import { TipoGastoService } from "src/app/services/api/contabilidad/maestros/tipo-operacion.service";
import { ordenerArregloJSONxLlave } from "@utils/array/order";
import { generarPDFXMLSIRE } from "@utils/pdfmaker/builder/compras/operaciones/registro-compra.pdfmaker";
import { UploadXMLRecepcionDialog } from "./dialog/upload-xml-recepcion/upload-xml-recepcion.component";
import { FileService } from "src/app/services/api/compras/operaciones/file.service";
import { CondicionPagoService } from "src/app/services/api/compras/maestros/condicion-pago.service";

@Component({
  selector: 'registro-compra-masivo',
  templateUrl: './registro-compra-masivo.component.html',
  styleUrls: ['./registro-compra-masivo.component.scss'],
})
export class RegistroCompraMasivoComponent implements OnInit, OnDestroy {

  @ViewChild('tabGroupFirst') tabGroupFirst: MatTabGroup;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  displayedColumns: string[] = ['select', 'correlativo', 'accion', 'estado_recepcion', 'CLI_CODCLI', 'CLI_NOMCLI', 'TDO_CODTDO', 'sad_codsad', 'CCO_NUMDOC', 'CCO_FECEMI', 'CCO_GLOCCO', 'CCO_TASIGV', 'TMO_CODTMO', 'CCO_IMPINA', 'CCO_IMPAFE', 'CCO_IMPIGV', 'CCO_IMPDOC', 'CCO_CODCCO', 'CCO_CODUSE', 'CCO_FECUPD', 'condicion', 'estado', 'reencion', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild("paginatorRecepcion") paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderData: boolean;
  loaderDataDescargas: boolean;
  loaderRegistrarEnCompras: boolean;
  loaderListarRecepcion: boolean;

  progresoCarga: number = 0;

  recepcion: RecepcionCompra;

  uploadingFilesXML: boolean;
  movingFilesXMLToReception: boolean;
  validatingFilesXMLWithSUNAT: boolean;

  catalogos$: Subscription;
  consultaExistenciaRucs$: Subscription;
  loaderRegistrarRecepcion$: Subscription;
  registrarMasivamenteRecepcion$: Subscription;
  periodo$: Subscription;
  recepciones$: Subscription;
  registrosCompra$: Subscription;
  clientes$: Subscription;
  condiciones$: Subscription;
  tiposDocumento$: Subscription;
  tiposOperacion$: Subscription;
  tiposOperacionRetencion$: Subscription;
  tiposBienServicio$: Subscription;
  subdiarios$: Subscription;
  ordenes$: Subscription;
  centros$: Subscription;
  cuentas$: Subscription;
  registrarProveedoresMasivamente$: Subscription;
  signoCtaCte$: Subscription;
  tiposRetencion$: Subscription;
  ubigeos$: Subscription;
  datosXML$: Subscription;
  configuracionTecnica$: Subscription;
  configuracionContabilidad$: Subscription;

  tiposDocumentoSUNAT: any[] = [];
  tiposDocumento: any[] = [];
  tiposDocumentoRef: any[] = [];
  recepciones: any[] = [];

  proveedoresNoExistentes: any[] = [];
  proveedoresARegistrar: Proveedor[] = [];
  proveedoresAActuralizarIndPro: Proveedor[] = [];
  ubigeos: any[] = [];

  selection = new SelectionModel<any>(true, []);

  anioPeriodo: string;
  mesPeriodo: string;
  configuracionTecnica: ConfiguracionTecnica = new ConfiguracionTecnica();
  configuracionContabilidad: ConfiguracionContabilidad = new ConfiguracionContabilidad();

  maestrosRegistroCompra = {
    proveedores: [],
    auxiliares: [],
    tiposDocumento: [],
    tiposDocumentoRef: [],
    condicionesPago: [],
    subdiarios: [],
    ordenes: [],
    tiposOperacion: [],
    tiposBienServicio: [],
    centros: [],
    cuentas: [],
    signosCtaCte: [],
    tiposRetencion: [],
    tiposOperacionRetencion: [],
    configuracionTecnica: this.configuracionTecnica,
    configuracionContabilidad: this.configuracionContabilidad
  }

  maestrosDescarga = {
    recepciones: [],
    registrosCompra: []
  }

  estadoRecepcion: string = 'TODOS';
  isMobile: boolean = false;

  constructor(
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _sunatService: SunatService,
    private _proveedorService: ProveedorService,
    private _contefactService: ContefactService,
    private _clienteService: ClienteService,
    private _comprasService: ComprasService,
    private _condicionPagoService: CondicionPagoService,
    private _generalService: GeneralService,
    private _contabilidadService: ContabilidadService,
    private _tipoGastoService: TipoGastoService,
    private _ordenCompraService: OrdenCompraService,
    private _recepcionService: ReceptionCompraService,
    private store: Store<PavsoState>,
    private readonly _fileService: FileService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver

  ) {

    this.recepcion = new RecepcionCompra();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    });

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

  }

  ngOnInit(): void {
    this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.cargarMaestros();
    })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  cargarMaestros(): void {
    this.loaderData = true;
    this.loaderDataDescargas = true;

    this.configuracionTecnica$ = this._contabilidadService.obtenerConfiguracionTecnica().subscribe(
      configuracion => {
        console.log('configuracion', configuracion)
        if (configuracion && configuracion.length > 0) {
          this.configuracionTecnica = configuracion[0];
        }

        this.recepciones$ = this._recepcionService.listarRecepcion(this.anioPeriodo, this.mesPeriodo).subscribe(
          recepciones => {

            this.recepciones = recepciones.filter(item => item.CCO_CODCCO == '');
            console.log('recepciones ', this.recepciones.filter(item => item.CCO_CODCCO == ''))

            this.dataSource = fillTable(ordenerArregloJSONxLlave('CCO_FECEMI', this.recepciones), this.paginator, this.sort)

            this.registrosCompra$ = this._comprasService.listarRegistrosDeCompraPeriodo(this.anioPeriodo, this.mesPeriodo).subscribe(
              registrosCompra => {
                console.log('registroscompra', registrosCompra)
                const registros = registrosCompra.filter(item => (item.tdo_codtdo == 'FAC' || item.tdo_codtdo == 'NCR' || item.tdo_codtdo == 'NDB') && item.ano_codano == this.anioPeriodo && item.mes_codmes == this.mesPeriodo);

                this.maestrosDescarga = { registrosCompra: registros, recepciones }

                this.catalogos$ = this._sunatService.obtenerMaestrosSunat('01').subscribe(
                  tiposDocumento => {
                    this.tiposDocumentoSUNAT = tiposDocumento;

                    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
                      clientes => {
                        const auxiliares = clientes;
                        const proveedores = clientes.filter(item => item.cli_indpro == 1);

                        this.tiposDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
                          tiposDoc => {
                            console.log('TIPOS DOCUMENTO', tiposDoc)
                            this.tiposDocumento = tiposDoc.filter(item => item.tdo_indcom == 1);
                            this.tiposDocumentoRef = this.tiposDocumento;

                            this.condiciones$ = this._condicionPagoService.obtenerCondicionesPago().subscribe(
                              condicionesPago => {

                                console.log('condiciones de pago', condicionesPago)
                                this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
                                  subdiarios => {

                                    console.log('SUBDIARIOS', subdiarios)
                                    const subs = subdiarios.filter(item => item.sco_indcom == 1);

                                    this.ordenes$ = this._ordenCompraService.obtenerOrdenesCompraGenerico().subscribe(
                                      ordenes => {

                                        console.log('ordenes', ordenes)
                                        this.tiposOperacion$ = this._tipoGastoService.obtenerTiposGasto().subscribe(
                                          tiposOperacion => {

                                            console.log('tipos de operacion', tiposOperacion)
                                            this.tiposBienServicio$ = this._contabilidadService.obtenerTiposBienServicio().subscribe(
                                              tiposBienServicio => {

                                                this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
                                                  centros => {

                                                    this.cuentas$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
                                                      cuentas => {

                                                        this.signoCtaCte$ = this._contabilidadService.listarSignoCtaCte().subscribe(
                                                          signosCtaCte => {

                                                            this.tiposRetencion$ = this._comprasService.listarTiposRetencion().subscribe(

                                                              tiposRetencion => {
                                                                console.log('tiposRetencion', tiposRetencion)

                                                                this.tiposOperacionRetencion$ = this._comprasService.obtenerTiposOperacionRetencion().subscribe(
                                                                  tiposOperacionRetencion => {

                                                                    console.log('TIPOS RETENCIONRES', tiposRetencion)

                                                                    this.ubigeos$ = this._generalService.listarUbigeos().subscribe(
                                                                      ubigeos => {

                                                                        this.ubigeos = ubigeos;
                                                                        console.log('ubigeos', this.ubigeos)

                                                                        this.configuracionContabilidad$ = this._contabilidadService.obtenerConfiguracionContabilidad().subscribe(
                                                                          configConta => {
                                                                            this.configuracionContabilidad = configConta;

                                                                            this.maestrosRegistroCompra = {
                                                                              proveedores,
                                                                              auxiliares,
                                                                              tiposDocumento: this.tiposDocumento,
                                                                              tiposDocumentoRef: this.tiposDocumentoRef,
                                                                              condicionesPago,
                                                                              subdiarios: subs,
                                                                              ordenes,
                                                                              tiposOperacion,
                                                                              tiposBienServicio,
                                                                              centros,
                                                                              cuentas,
                                                                              signosCtaCte,
                                                                              tiposRetencion,
                                                                              tiposOperacionRetencion,
                                                                              configuracionTecnica: this.configuracionTecnica,
                                                                              configuracionContabilidad: this.configuracionContabilidad
                                                                            }

                                                                            this.loaderListarRecepcion = false;
                                                                            this.loaderDataDescargas = false;
                                                                            this.loaderData = false;
                                                                          },
                                                                          error => {
                                                                            this._snackBarService.showError('Error al obtener configuración de contabilidad', 'Ok');
                                                                            this.loaderData = false;
                                                                          }
                                                                        )

                                                                      },
                                                                      error => {
                                                                        this._snackBarService.showError(error.error.msg, 'OK');
                                                                        this.loaderData = false;
                                                                      }
                                                                    )
                                                                  },
                                                                  error => {
                                                                    this._snackBarService.showError(error.error.msg, 'OK');
                                                                    this.loaderData = false;

                                                                  }
                                                                )
                                                              },
                                                              error => {
                                                                this._snackBarService.showError(error.error.msg, 'OK');
                                                                this.loaderData = false;

                                                              }
                                                            )

                                                          },
                                                          error => {
                                                            this._snackBarService.showError(error.error.msg, 'OK');
                                                            this.loaderData = false;
                                                          }
                                                        )

                                                      },
                                                      error => {
                                                        this._snackBarService.showError(error.error.msg, 'OK');
                                                        this.loaderData = false;

                                                      }
                                                    )

                                                  },
                                                  error => {
                                                    this._snackBarService.showError(error.error.msg, 'OK');
                                                    this.loaderData = false;
                                                  }
                                                )
                                              },
                                              error => {
                                                this._snackBarService.showError(error.error.msg, 'OK');
                                                this.loaderData = false;
                                              }
                                            )
                                          },
                                          error => {
                                            this._snackBarService.showError(error.error.msg, 'OK');
                                            this.loaderData = false;
                                          }
                                        )

                                      },
                                      error => {
                                        this._snackBarService.showError(error.error.msg, 'OK');
                                        this.loaderData = false;
                                      }
                                    )

                                  },
                                  error => {
                                    this._snackBarService.showError(error.error.msg, 'OK');
                                    this.loaderData = false;
                                  }
                                )

                              },
                              error => {
                                this._snackBarService.showError(error.error.msg, 'OK');
                                this.loaderData = false;
                              }
                            )

                          },
                          error => {
                            this._snackBarService.showError(error.error.msg, 'OK');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError(error, 'OK');
                        this.loaderData = false;
                      }
                    )

                  },
                  error => {
                    this._snackBarService.showError(error, 'OK');
                    this.loaderData = false;
                  }
                )

              },
              error => {
                this.loaderData = false;
                this.loaderDataDescargas = false;
                this._snackBarService.showError('Error al obtener registros de compra', 'Ok');
              }
            )
          },
          error => {
            this.loaderData = false;
            this.loaderDataDescargas = false;
            this._snackBarService.showError('Error al obtener recepciones', 'Ok')
          }
        )

      },
      error => {
        this._snackBarService.showError('Error al obtener configuración técnica', 'Ok');
      }
    )

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  /**
   * Filtrar por estado de recepción
   * PENDIENTES: Recepciones que aún no han sido registradas
   * REGISTRADAS: Recepciones registradas
   * TODOS: Todas las recepciones
   * @param estado
   */
  seleccionarEstadoRecepcion(estado): void {
    if (estado == 'REGISTRADOS') {
      const filtrados = this.recepciones.filter(item => item.CCO_CODCCO != "")
      this.dataSource = fillTable(filtrados, this.paginator, this.sort)
    }

    if (estado == 'PENDIENTES') {
      const filtrados = this.recepciones.filter(item => item.CCO_CODCCO == "")
      this.dataSource = fillTable(filtrados, this.paginator, this.sort)
    }

    if (estado == 'TODOS') {
      this.dataSource = fillTable(this.recepciones, this.paginator, this.sort)
    }
  }

  /**
   * funcion a ejecutar cuando desde Detalle formulario recepción le
   * dan clic a volver.
   * @param recepcion
   */
  volverAListadoRecepcion(recepcion): void {

    this.dataSource.data.forEach(item => {
      if (item.CCR_CODCCR == recepcion.CCR_CODCCR) {
        item = recepcion;
      }
    })

    this.dataSource = fillTable(this.dataSource.data, this.paginator, this.sort);

    this.tabGroup.selectedIndex = 0;

  }

  /**
   * Obtener Formato Bytes Ej. 1200 bytes 1.2 KB
   * @param bytes
   * @returns
   */
  obtenerFormatoResumidoBytes(bytes) {
    return formatBytes(bytes)
  }

  /**
   * Acción que se realiza desde el componente de Cargar XMLs manualmente
   * @param data
   */
  establecerXMLARecepcion(data) {
    console.log('data', data);
    this.tabGroupFirst.selectedIndex = 1;

    this.loaderListarRecepcion = true;

    const rucs = data.map(item => item.CLI_CODCLI);
    console.log('rucs', rucs);

    this.consultaExistenciaRucs$ = this._proveedorService.verificarExistenciaProveedores({ rucs }).subscribe(
      response => {
        console.log('existencia proveedores', response)

        const proveedoresEncontradosConsultaAPI = response.rucsFiltrados.filter(item =>Object.keys(item.proveedor).length !== 0)

        const proveedoresNoEncontradosConsultaAPI = response.rucsFiltrados.filter(item => Object.keys(item.proveedor).length === 0)

        console.log('proveedoresEncontradosConsultaAPI', proveedoresEncontradosConsultaAPI)
        console.log('proveedoresNoEncontradosConsultaAPI', proveedoresNoEncontradosConsultaAPI)


        if(proveedoresNoEncontradosConsultaAPI.length > 0) {
          const rucs = proveedoresNoEncontradosConsultaAPI.map(item => item.ruc)
          const msg = rucs.join(', ')
          this._snackBarService.showError(`Los siguientes rucs no se encontraron ${msg}`, 'Ok')
        }

        this.proveedoresARegistrar = [];

        proveedoresEncontradosConsultaAPI.forEach((item, index) => {

          const ruc = response.rucsFiltrados[index];

          const proveedor = Proveedor.fromCONEFACTToProvider(ruc.proveedor);

          proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          proveedor.cli_coduse = this._configurationService.obtenerIdUsuario();
          const ubigeo = this.ubigeos.find(item => item.ubs_descri == `${ruc.proveedor.departamento}, ${ruc.proveedor.provincia}, ${ruc.proveedor.distrito}`);
          proveedor.ubs_codubs = ubigeo ? ubigeo.ubs_codubs : null;
          proveedor.ubi_codubi = ubigeo ? ubigeo.ubi_codubi : null;

          if(!ruc.existe) {
            this.proveedoresARegistrar.push(proveedor);
          }
          localStorage.setItem('proveedoresARegistrar', JSON.stringify(this.proveedoresARegistrar))

          item['existeProveedor'] = ruc.existe && ruc.cliente[0].cli_indpro == 1 ? 'SI' : 'NO';
          item['existe'] = ruc.existe ? 'SI' : 'NO';
          item['cli_indpro'] = ruc.cliente.length > 0 && ruc.cliente[0].cli_indpro;
          item['razonSocial'] = ruc.proveedor.razon ? ruc.proveedor.razon : ruc.proveedor.nombre;
          item['condicion'] = ruc.proveedor.condicion;
          item['estado'] = ruc.proveedor.estado;
          item['reencion'] = ruc.proveedor.reencion ? ruc.proveedor.reencion[0] + ruc.proveedor.reencion[1] : 'No';
          item['proveedor'] = ruc.proveedor;
          item['cliente'] = ruc.cliente;

        });


        const recepcions = this.dataSource.data;

        // COMPLETAR DATOS DE XML
        const recepcionesARegistrar = data.map((item) => {
          const recepcion = new RecepcionCompra();
          recepcion.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          recepcion.ANO_CODANO = this.anioPeriodo;
          recepcion.MES_CODMES = this.mesPeriodo;
          recepcion.CCO_CODUSE = item.CCO_CODUSE;
          recepcion.CCO_FECEMI = item.CCO_FECEMI;
          recepcion.CCO_FECUPD = item.CCO_FECUPD;
          recepcion.CCO_GLOCCO = item.CCO_GLOCCO;
          recepcion.CCO_IMPAFE = item.CCO_IMPAFE;
          recepcion.CCO_IMPDOC = item.CCO_IMPDOC;
          recepcion.CCO_IMPIGV = item.CCO_IMPIGV;
          recepcion.CCO_IMPINA = item.CCO_IMPINA;
          recepcion.CCO_NUMDOC = item.CCO_NUMDOC;
          recepcion.CCO_TASIGV = item.CCO_TASIGV;
          recepcion.CLI_CODCLI = item.CLI_CODCLI;
          recepcion.CLI_CODDOC = item.CLI_CODCLI;
          recepcion.TDO_CODTDO = item.TDO_CODTDO
          recepcion.TMO_CODTMO = item.TMO_CODTMO;
          recepcion.sad_codsad = item.sad_codsad;
          recepcion.valvta = item.valvta;

          return recepcion;
        })

        this._proveedorService.registrarProveedoresMasivamente({ proveedoresARegistrar: this.proveedoresARegistrar, proveedoresAActualizar: this.proveedoresAActuralizarIndPro }).subscribe(
          proveedoresRegistrados => {

            this._snackBarService.showSuccess('Proveedores registrados correctamente', 'Ok');

            console.log('recepciones a registrar', recepcionesARegistrar)
            this._clienteService.obtenerClientes().subscribe(
              clientes => {
                const proveedores = clientes.filter(item => item.cli_indpro == 1);
                this.maestrosRegistroCompra = { ...this.maestrosRegistroCompra, proveedores, auxiliares: clientes }
                this.registrarMasivamenteRecepcion$ = this._recepcionService.registrarMasivamente(recepcionesARegistrar).subscribe(
                  recepciones => {
                    this._snackBarService.showSuccess('Recepciones registradas correctamente', 'Ok');

                    recepciones.forEach((item, index) => {
                      recepcionesARegistrar[index].CCR_CODCCR = item.CCR_CODCCR;
                    })

                    this.dataSource = fillTable([...recepcionesARegistrar, ...recepcions], this.paginator, this.sort)

                    const proveedoresExistentes = proveedoresEncontradosConsultaAPI.filter(item => item.existe == 'SI' && item.cli_indpro == 1)
                    const proveedoresNoExistentes = proveedoresEncontradosConsultaAPI.filter(item => item.existe == 'NO' || (item.existe == 'SI' && item.cli_indpro == 0))


                    console.log('proveedoresExistentes', proveedoresExistentes)
                    console.log('proveedoresNoExistentes', proveedoresNoExistentes)

                    this.loaderListarRecepcion = false;
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok');

                    this.loaderListarRecepcion = false;
                  }
                )
              },
              error => {
                this.loaderListarRecepcion = false;
                this._snackBarService.showError(error.error.msg, 'Ok');
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');

            this.loaderListarRecepcion = false;
          }
        )

      },
      error => {
        console.log('error', error)
        this.loaderListarRecepcion = false;
      }
    )

  }

  /**
   * Acción a realizar cuando se selecciona una fila de la tabla.
   * @param row
   */
  seleccionarItem(row: RecepcionCompra): void {

    // row.TDO_CODTDO = row.TDO_CODTDO == "Factura" ? "FAC" : "";
    row.ANO_CODANO = this.anioPeriodo;
    row.MES_CODMES = this.mesPeriodo;

    console.log('fila seleccionada', row)

    this.recepcion = row;
    this.tabGroup.selectedIndex = 1;

  }

  /**
   * Evento que se dispara cuando el tab cambia de descargas masivas a carga masivas
   * @param documentos
   */
  moverATabCargaMasiva(documentos): void {

    this.loaderListarRecepcion = true;

    let xmlsJSON = [];
    console.log('documentos', documentos)
    console.log('documentos length', documentos.length)
    documentos.forEach(documento => {
      console.log('documento', documento)
      const parser = new xml2js.Parser({ strict: false, trim: true });
      console.log('parser', parser)
      parser.parseString(documento.xmlStr, (err, result) => {
        console.log('err', err)
        console.log('result', result)
        if(!err) {
          if(result['dpc_urlpdf']) result['dpc_urlpdf'] = documento.pdf_url;

          xmlsJSON.push(result);
        }
      });

    });

    // console.log('xmlsJSON', xmlsJSON)

    this.tabGroupFirst.selectedIndex = 1;

    this.cargarDocumentosXMLARecepcion(xmlsJSON)

  }

  actualizarRecepciones(): void {
    this.tabGroupFirst.selectedIndex = 1;

    this.recepciones$ = this._recepcionService.listarRecepcion(this.anioPeriodo, this.mesPeriodo).subscribe(
      recepciones => {
        this.recepciones = recepciones.filter(item => item.CCO_CODCCO == '');
        console.log('recepciones ', this.recepciones.filter(item => item.CCO_CODCCO == ''))

        this.dataSource = fillTable(ordenerArregloJSONxLlave('CCO_FECEMI', this.recepciones), this.paginator, this.sort)
        // this.dataSource = fillTable(recepciones, this.paginator, this.sort)
      },
      error => {
        this._snackBarService.showError('Error al obtener recepciones', 'Ok')
      }
    )
  }

  abrirModalCargarImagenes(row: RecepcionCompra): void {
    console.log('row', row.CCR_CODCCR)
    const dialogRef = this.dialog.open(PavUploadFileModal, {
      width: '600px',
      data: row.CCR_CODCCR,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loaderListarRecepcion = true;
      this._recepcionService.listarRecepcion(this.anioPeriodo, this.mesPeriodo).subscribe(
        recepciones => {

          this.recepciones = recepciones.filter(item => item.CCO_CODCCO == '');
          this.loaderListarRecepcion = false;
          this.dataSource = fillTable(ordenerArregloJSONxLlave('CCO_FECEMI', this.recepciones), this.paginator, this.sort)
        },
        error => {
          this._snackBarService.showError('Error al obtener recepciones', 'Ok')
          this.loaderListarRecepcion = false;
        }
      )
      console.log('The dialog was closed');
    });
  }

  abrirModalCargarXML(row: RecepcionCompra): void {
    console.log('row', row.CCR_CODCCR)
    const dialogRef = this.dialog.open(UploadXMLRecepcionDialog, {
      width: '400px',
      data: row.CCR_CODCCR,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loaderListarRecepcion = true;
      this._recepcionService.listarRecepcion(this.anioPeriodo, this.mesPeriodo).subscribe(
        recepciones => {

          this.recepciones = recepciones.filter(item => item.CCO_CODCCO == '');
          this.loaderListarRecepcion = false;
          this.dataSource = fillTable(ordenerArregloJSONxLlave('CCO_FECEMI', this.recepciones), this.paginator, this.sort)
        },
        error => {
          this._snackBarService.showError('Error al obtener recepciones', 'Ok')
          this.loaderListarRecepcion = false;
        }
      )
      console.log('The dialog was closed');
    });
  }

  cargarDocumentosXMLARecepcion(xmls): void {
    // Transformar JSON XML to JSON Standar
    console.log('xmls STR', JSON.stringify(xmls))
    console.log('xmls', xmls)
    const registrosRecepcion = xmls.map((document, index) => {

      if (document.INVOICE) {
        const INVOICE = document.INVOICE;

        if (INVOICE['CBC:INVOICETYPECODE']) {

          const recepcion = new RecepcionCompra();
          recepcion.dpc_urlpdf = document.dpc_urlpdf;

          recepcion.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          recepcion.ANO_CODANO = this.anioPeriodo;
          recepcion.MES_CODMES = this.mesPeriodo;

          recepcion.estado = 'En recepción';
          recepcion.correlativo = (index + 1) > 9 ? index + 1 : `0${index + 1}`;
          recepcion.CLI_CODCLI = INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'] ? INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'];
          recepcion.CLI_CODDOC = recepcion.CLI_CODCLI;
          recepcion.sad_codsad = INVOICE['CBC:ID'][0].split('-')[0];
          recepcion.CCO_NUMDOC = INVOICE['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(INVOICE['CBC:ID'][0].split('-')[1]);
          recepcion.CCO_FECEMI = new Date(INVOICE['CBC:ISSUEDATE'][0]);
          recepcion.TMO_CODTMO = INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : INVOICE['CBC:DOCUMENTCURRENCYCODE'][0];
          // recepcion.TDO_CODTDO = this.tiposDocumentoSUNAT.find(item => item.id == tipoDocumento).descripcion,
          console.log('tipos documento', this.tiposDocumento)
          recepcion.TDO_CODTDO = this.tiposDocumento.find(item => item.TDO_CODOFI == INVOICE['CBC:INVOICETYPECODE'][0]['_']).TDO_CODTDO;
          recepcion.CCO_GLOCCO = INVOICE['CAC:INVOICELINE'][0]['CAC:ITEM'][0]['CBC:DESCRIPTION'][0];
          recepcion.CCO_TASIGV = INVOICE['CAC:INVOICELINE'][0]['CAC:TAXTOTAL'][0]['CAC:TAXSUBTOTAL'][0]['CAC:TAXCATEGORY'][0]['CBC:PERCENT'][0];
          recepcion.CCO_TASIGV = parseFloat(recepcion.CCO_TASIGV.toString());
          recepcion.CCO_IMPIGV = INVOICE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_'];
          recepcion.CCO_IMPIGV = parseFloat(recepcion.CCO_IMPIGV.toString());
          recepcion.CCO_IMPDOC = INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_'];
          recepcion.CCO_IMPDOC = parseFloat(recepcion.CCO_IMPDOC.toString());
          recepcion.CCO_IMPAFE = INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_'] : INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_'];
          recepcion.CCO_IMPAFE = parseFloat(recepcion.CCO_IMPAFE.toString());

          if (recepcion.CCO_IMPIGV == 0) {
            recepcion.CCO_IMPINA = recepcion.CCO_IMPAFE;
            recepcion.CCO_IMPAFE = 0;
          } else {
            recepcion.CCO_IMPAFE = recepcion.CCO_IMPAFE;
            recepcion.CCO_IMPINA = 0;

            recepcion.CCO_IMPDOC = parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

            if ((recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV) != recepcion.CCO_IMPDOC) {
              if (INVOICE["CAC:ALLOWANCECHARGE"]) {
                recepcion.dpc_impfis = parseFloat(INVOICE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
              } else {
                recepcion.CCO_IMPINA = parseFloat((recepcion.CCO_IMPDOC - (recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV)).toFixed(2))

              }
            }
          }

          recepcion.valvta = recepcion.CCO_IMPINA + recepcion.CCO_IMPAFE + recepcion.dpc_impfis + recepcion.dpc_impsis;
          recepcion.CCO_IMPDOC = recepcion.valvta + recepcion.CCO_IMPIGV;

          recepcion.CCO_CODUSE = this._configurationService.obtenerIdUsuario();
          recepcion.CCO_FECUPD = new Date();

          const maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

          recepcion.CCO_FECREG = maxDate;
          recepcion.CCO_FECVEN = recepcion.CCO_FECEMI;
          if (recepcion.CCO_FECEMI == recepcion.CCO_FECVEN) recepcion.CPA_CODCPA = '112';

          if (INVOICE["CAC:PAYMENTTERMS"]) {
            const formaPago = INVOICE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
            if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
              recepcion.CPA_CODCPA = '112';
            }

            if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

              console.log('formapago', formaPago)
              const fecVen = INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'] ?
                new Date(INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString()
                : new Date(INVOICE["CAC:PAYMENTTERMS"][2]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
              const fecToday = new Date().toUTCString();
              recepcion.CCO_FECVEN = INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'] ?
                new Date(INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]) :
                new Date(INVOICE["CAC:PAYMENTTERMS"][2]['CBC:PAYMENTDUEDATE'][0]);
              console.log('fecha hoy', fecToday)
              console.log('fecha ven', fecVen)

              const dias = retornaDiferenciaDias(fecVen, fecToday);
              console.log('dias', dias)
            }

          }

          recepcion.TMO_CODTMO = recepcion.TMO_CODTMO == "PEN" ? "SO" : "DO";

          return recepcion;

        } else {

          const tipoDocumento = INVOICE['N2:INVOICETYPECODE'][0]['_'] ? INVOICE['N2:INVOICETYPECODE'][0]['_'] : INVOICE['N2:INVOICETYPECODE'][0];
          console.log('tipo de documento', tipoDocumento)
          console.log('(tipoDocumento).descripcion', this.tiposDocumentoSUNAT.find(item => item.id == tipoDocumento).descripcion)
          const recepcion = new RecepcionCompra();

          recepcion.estado = 'En recepción';
          recepcion.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          recepcion.ANO_CODANO = this.anioPeriodo;
          recepcion.MES_CODMES = this.mesPeriodo;

          recepcion.correlativo = (index + 1) > 9 ? index + 1 : `0${index + 1}`;
          recepcion.CLI_CODCLI = INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N2:CUSTOMERASSIGNEDACCOUNTID'] ? INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N2:CUSTOMERASSIGNEDACCOUNTID'][0]['_'] : INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N1:PARTY'][0]['N1:PARTYIDENTIFICATION'][0]['N2:ID'][0]['_'];
          recepcion.CLI_CODDOC = recepcion.CLI_CODCLI;
          recepcion.sad_codsad = INVOICE['N2:ID'][0]['_'].split('-')[0];
          recepcion.CCO_NUMDOC = INVOICE['N2:ID'][0]['_'].split('-')[0] + this.completarCerosIzquierda(INVOICE['N2:ID'][0]['_'].split('-')[1]);
          recepcion.CCO_FECEMI = INVOICE['N2:ISSUEDATE'][0]['_'] ? new Date(INVOICE['N2:ISSUEDATE'][0]['_']) : new Date(INVOICE['N2:ISSUEDATE'][0]);
          recepcion.TMO_CODTMO = INVOICE['N2:DOCUMENTCURRENCYCODE'][0]['_'] ? INVOICE['N2:DOCUMENTCURRENCYCODE'][0]['_'] : INVOICE['N2:DOCUMENTCURRENCYCODE'][0];
          // recepcion.TDO_CODTDO = this.tiposDocumentoSUNAT.find(item => item.id == tipoDocumento).descripcion,
          recepcion.TDO_CODTDO = this.tiposDocumento.find(item => item.TDO_CODOFI == INVOICE['N2:INVOICETYPECODE'][0]['_']).TDO_CODTDO;
          recepcion.CCO_GLOCCO = INVOICE['N1:INVOICELINE'][0]['N1:ITEM'][0]['N2:DESCRIPTION'][0];
          recepcion.CCO_TASIGV = INVOICE['N1:INVOICELINE'][0]['N1:TAXTOTAL'][0]['N1:TAXSUBTOTAL'][0]['N1:TAXCATEGORY'][0]['N2:PERCENT'][0];
          recepcion.CCO_TASIGV = parseFloat(recepcion.CCO_TASIGV.toString());
          recepcion.CCO_IMPIGV = INVOICE['N1:TAXTOTAL'][0]['N2:TAXAMOUNT'][0]['_'];
          recepcion.CCO_IMPIGV = parseFloat(recepcion.CCO_IMPIGV.toString());
          recepcion.CCO_IMPDOC = INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_'];
          recepcion.CCO_IMPDOC = parseFloat(recepcion.CCO_IMPDOC.toString());
          recepcion.CCO_IMPAFE = INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:LINEEXTENSIONAMOUNT'] ? INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:LINEEXTENSIONAMOUNT'][0]['_'] : INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_'];
          recepcion.CCO_IMPAFE = parseFloat(recepcion.CCO_IMPAFE.toString());

          if (recepcion.CCO_IMPIGV == 0) {
            recepcion.CCO_IMPINA = recepcion.CCO_IMPAFE;
            recepcion.CCO_IMPAFE = 0;
          } else {
            recepcion.CCO_IMPAFE = recepcion.CCO_IMPAFE;
            recepcion.CCO_IMPINA = 0;

            recepcion.CCO_IMPDOC = parseFloat(INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_']);
            if ((recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV) != recepcion.CCO_IMPDOC) {

              if (INVOICE["N1:ALLOWANCECHARGE"]) {
                recepcion.dpc_impfis = parseFloat(INVOICE["N1:ALLOWANCECHARGE"][0]["N2:AMOUNT"][0]["_"]);
              } else {
                recepcion.CCO_IMPINA = parseFloat((recepcion.CCO_IMPDOC - (recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV)).toFixed(2))
              }

            }
          }

          recepcion.valvta = recepcion.CCO_IMPINA + recepcion.CCO_IMPAFE + recepcion.dpc_impfis + recepcion.dpc_impsis;
          recepcion.CCO_IMPDOC = recepcion.valvta + recepcion.CCO_IMPIGV;

          recepcion.CCO_CODUSE = this._configurationService.obtenerIdUsuario();
          recepcion.CCO_FECUPD = new Date();

          const maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

          recepcion.CCO_FECREG = maxDate;
          recepcion.CCO_FECVEN = recepcion.CCO_FECEMI;
          if (recepcion.CCO_FECEMI == recepcion.CCO_FECVEN) recepcion.CPA_CODCPA = '112';

          recepcion.TMO_CODTMO = recepcion.TMO_CODTMO == "PEN" ? "SO" : "DO";

          if (INVOICE["N1:PAYMENTTERMS"]) {
            const formaPago = INVOICE["N1:PAYMENTTERMS"].find(item => item["N2:ID"] == 'FormaPago');
            if (formaPago["N2:PAYMENTMEANSID"][0] == 'Contado') {
              recepcion.CPA_CODCPA = '112';
            }

            if (formaPago["N2:PAYMENTMEANSID"][0] == 'Credito') {

              console.log('formapago', formaPago)
              const fecVen = new Date(INVOICE["N1:PAYMENTTERMS"][1]['N2:PAYMENTDUEDATE'][0]).toUTCString();
              const fecToday = new Date().toUTCString();
              recepcion.CCO_FECVEN = new Date(INVOICE["N1:PAYMENTTERMS"][1]['N2:PAYMENTDUEDATE'][0]);
              console.log('fecha hoy', fecToday)
              console.log('fecha ven', fecVen)

              const dias = retornaDiferenciaDias(fecVen, fecToday);
              console.log('dias', dias)
            }

          }

          return recepcion;

        }
      }

      if (document.CREDITNOTE) {
        const CREDITNOTE = document.CREDITNOTE;

        const recepcion = new RecepcionCompra();

        recepcion.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
        recepcion.ANO_CODANO = this.anioPeriodo;
        recepcion.MES_CODMES = this.mesPeriodo;

        recepcion.estado = 'En recepción';
        recepcion.correlativo = (index + 1) > 9 ? index + 1 : `0${index + 1}`;
        recepcion.CLI_CODCLI = CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'] ? CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'];
        recepcion.CLI_CODDOC = recepcion.CLI_CODCLI;
        recepcion.sad_codsad = CREDITNOTE['CBC:ID'][0].split('-')[0];
        recepcion.CCO_NUMDOC = CREDITNOTE['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(CREDITNOTE['CBC:ID'][0].split('-')[1]);
        recepcion.CCO_FECEMI = new Date(CREDITNOTE['CBC:ISSUEDATE'][0]);
        recepcion.TMO_CODTMO = CREDITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? CREDITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : CREDITNOTE['CBC:DOCUMENTCURRENCYCODE'][0];
        // recepcion.TDO_CODTDO = this.tiposDocumentoSUNAT.find(item => item.id == tipoDocumento).descripcion,
        recepcion.TDO_CODTDO = 'NCR';
        recepcion.CCO_GLOCCO = CREDITNOTE['CAC:CREDITNOTELINE'][0]['CAC:ITEM'][0]['CBC:DESCRIPTION'][0];
        recepcion.CCO_TASIGV = parseFloat((CREDITNOTE['CAC:CREDITNOTELINE'][0]['CAC:TAXTOTAL'][0]['CAC:TAXSUBTOTAL'][0]['CAC:TAXCATEGORY'][0]['CBC:PERCENT'][0]).toString());
        recepcion.CCO_IMPIGV = parseFloat((CREDITNOTE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_']).toString());

        recepcion.CCO_IMPDOC = parseFloat((CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString());
        recepcion.TDO_DOCREF = this.tiposDocumento.find(item => item.TDO_CODOFI == CREDITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:DOCUMENTTYPECODE'][0]['_']).TDO_CODTDO;
        recepcion.CCO_NUMREF = CREDITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(CREDITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:ID'][0].split('-')[1]);

        recepcion.CCO_IMPAFE = CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_'] : CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_'];
        recepcion.CCO_IMPAFE = parseFloat(recepcion.CCO_IMPAFE.toString());

        if (recepcion.CCO_IMPIGV == 0) {
          recepcion.CCO_IMPINA = recepcion.CCO_IMPAFE;
          recepcion.CCO_IMPAFE = 0;
        } else {
          recepcion.CCO_IMPAFE = recepcion.CCO_IMPAFE;
          recepcion.CCO_IMPINA = 0;

          recepcion.CCO_IMPDOC = parseFloat(CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);
          if (recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV != recepcion.CCO_IMPDOC) {
            if (CREDITNOTE["CAC:ALLOWANCECHARGE"]) {
              recepcion.dpc_impfis = parseFloat(CREDITNOTE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
            } else {
              recepcion.CCO_IMPINA = parseFloat((recepcion.CCO_IMPDOC - (recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV)).toFixed(2))

            }

          }
        }

        recepcion.valvta = recepcion.CCO_IMPINA + recepcion.CCO_IMPAFE + recepcion.dpc_impfis + recepcion.dpc_impsis;
        recepcion.CCO_IMPDOC = recepcion.valvta + recepcion.CCO_IMPIGV;

        recepcion.CCO_CODUSE = this._configurationService.obtenerIdUsuario();
        recepcion.CCO_FECUPD = new Date();

        const maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

        recepcion.CCO_FECREG = maxDate;
        recepcion.CCO_FECVEN = recepcion.CCO_FECEMI;
        if (recepcion.CCO_FECEMI == recepcion.CCO_FECVEN) recepcion.CPA_CODCPA = '112';

        recepcion.TMO_CODTMO = recepcion.TMO_CODTMO == "PEN" ? "SO" : "DO";

        if (CREDITNOTE["CAC:PAYMENTTERMS"]) {
          const formaPago = CREDITNOTE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
            recepcion.CPA_CODCPA = '112';
          }

          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

            console.log('formapago', formaPago)
            const fecVen = new Date(CREDITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
            const fecToday = new Date().toUTCString();
            recepcion.CCO_FECVEN = new Date(CREDITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]);
            console.log('fecha hoy', fecToday)
            console.log('fecha ven', fecVen)

            const dias = retornaDiferenciaDias(fecVen, fecToday);
            console.log('dias', dias)
          }

        }

        return recepcion;
      }

      if (document.DEBITNOTE) {
        const DEBITNOTE = document.DEBITNOTE;

        const recepcion = new RecepcionCompra();

        recepcion.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
        recepcion.ANO_CODANO = this.anioPeriodo;
        recepcion.MES_CODMES = this.mesPeriodo;

        recepcion.estado = 'En recepción';
        recepcion.correlativo = (index + 1) > 9 ? index + 1 : `0${index + 1}`;
        recepcion.CLI_CODCLI = DEBITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'] ? DEBITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : DEBITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'];
        recepcion.CLI_CODDOC = recepcion.CLI_CODCLI;
        recepcion.sad_codsad = DEBITNOTE['CBC:ID'][0].split('-')[0];
        recepcion.CCO_NUMDOC = DEBITNOTE['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(DEBITNOTE['CBC:ID'][0].split('-')[1]);
        recepcion.CCO_FECEMI = new Date(DEBITNOTE['CBC:ISSUEDATE'][0]);
        recepcion.TMO_CODTMO = DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0];
        // recepcion.TDO_CODTDO = this.tiposDocumentoSUNAT.find(item => item.id == tipoDocumento).descripcion,
        recepcion.CCO_FECVEN
        recepcion.TDO_CODTDO = 'NDB';
        recepcion.CCO_GLOCCO = DEBITNOTE['CAC:DEBITNOTELINE'][0]['CAC:ITEM'][0]['CBC:DESCRIPTION'][0];
        recepcion.CCO_TASIGV = parseFloat((DEBITNOTE['CAC:DEBITNOTELINE'][0]['CAC:TAXTOTAL'][0]['CAC:TAXSUBTOTAL'][0]['CAC:TAXCATEGORY'][0]['CBC:PERCENT'][0]).toString());
        recepcion.CCO_IMPIGV = parseFloat((DEBITNOTE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_']).toString());

        recepcion.CCO_IMPDOC = parseFloat((DEBITNOTE['CAC:REQUESTEDMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString());
        recepcion.TDO_DOCREF = this.tiposDocumento.find(item => item.TDO_CODOFI == DEBITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:DOCUMENTTYPECODE'][0]).TDO_CODTDO;
        recepcion.CCO_NUMREF = DEBITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(DEBITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:ID'][0].split('-')[1]);

        recepcion.CCO_IMPAFE = DEBITNOTE['CAC:REQUESTEDMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? DEBITNOTE['CAC:REQUESTEDMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_'] : DEBITNOTE['CAC:REQUESTEDMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_'];
        recepcion.CCO_IMPAFE = parseFloat(recepcion.CCO_IMPAFE.toString());

        if (recepcion.CCO_IMPIGV == 0) {
          recepcion.CCO_IMPINA = recepcion.CCO_IMPAFE;
          recepcion.CCO_IMPAFE = 0;
        } else {
          recepcion.CCO_IMPAFE = recepcion.CCO_IMPAFE;
          recepcion.CCO_IMPINA = 0;

          recepcion.CCO_IMPDOC = parseFloat(DEBITNOTE['CAC:REQUESTEDMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);
          if (recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV != recepcion.CCO_IMPDOC) {
            if (DEBITNOTE["CAC:ALLOWANCECHARGE"]) {
              recepcion.dpc_impfis = parseFloat(DEBITNOTE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
            } else {
              recepcion.CCO_IMPINA = parseFloat((recepcion.CCO_IMPDOC - (recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV)).toFixed(2))

            }

          }
        }

        recepcion.valvta = recepcion.CCO_IMPINA + recepcion.CCO_IMPAFE + recepcion.dpc_impfis + recepcion.dpc_impsis;
        recepcion.CCO_IMPDOC = recepcion.valvta + recepcion.CCO_IMPIGV;

        recepcion.CCO_CODUSE = this._configurationService.obtenerIdUsuario();
        recepcion.CCO_FECUPD = new Date();

        const maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

        recepcion.CCO_FECREG = maxDate;
        recepcion.CCO_FECVEN = recepcion.CCO_FECEMI;
        if (recepcion.CCO_FECEMI == recepcion.CCO_FECVEN) recepcion.CPA_CODCPA = '112';

        recepcion.TMO_CODTMO = recepcion.TMO_CODTMO == "PEN" ? "SO" : "DO";

        if (DEBITNOTE["CAC:PAYMENTTERMS"]) {
          const formaPago = DEBITNOTE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
            recepcion.CPA_CODCPA = '112';
          }

          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

            console.log('formapago', formaPago)
            const fecVen = new Date(DEBITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
            const fecToday = new Date().toUTCString();
            recepcion.CCO_FECVEN = new Date(DEBITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]);
            console.log('fecha hoy', fecToday)
            console.log('fecha ven', fecVen)

            const dias = retornaDiferenciaDias(fecVen, fecToday);
            console.log('dias', dias)
          }

        }

        return recepcion;
      }
      // CBC => N2
      // CAC => N1
    })

    // console.log('registrosRecepcion', registrosRecepcion)
    // return;

    this.movingFilesXMLToReception = false;

    const rucs = registrosRecepcion.map(item => item.CLI_CODCLI);

    this.consultaExistenciaRucs$ = this._proveedorService.verificarExistenciaProveedores({ rucs }).subscribe(
      response => {

        this.proveedoresARegistrar = [];
        this.proveedoresAActuralizarIndPro = [];

        registrosRecepcion.forEach((item, index) => {

          const ruc = response.rucsFiltrados[index];
          console.log('RUC', ruc)
          item['existeProveedor'] = ruc.existe && ruc.cliente[0].cli_indpro == 1 ? 'SI' : 'NO';
          item['existe'] = ruc.existe ? 'SI' : 'NO';
          item['cli_indpro'] = ruc.cliente.length > 0 && ruc.cliente[0].cli_indpro;
          item['razonSocial'] = ruc.proveedor.razon;
          item['condicion'] = ruc.proveedor.condicion;
          item['estado'] = ruc.proveedor.estado;
          item['reencion'] = 'No';
          item['proveedor'] = ruc.proveedor;
          item['cliente'] = ruc.cliente;
          item['ubigeo'] = null;
          item.CLI_NOMCLI = ruc.proveedor.razon;
          item.proveedor.cli_nomcli = ruc.proveedor.razon;
          // Verificar si existe o no el proveedor

          if (!ruc.existe) {
            const proveedor = Proveedor.fromCONEFACTToProvider(ruc.proveedor);
            proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
            proveedor.cli_coduse = this._configurationService.obtenerIdUsuario();
            const ubigeo = this.ubigeos.find(item => item.ubs_descri == `${ruc.proveedor.departamento}, ${ruc.proveedor.provincia}, ${ruc.proveedor.distrito}`);
            proveedor.ubs_codubs = ubigeo ? ubigeo.ubs_codubs : null;
            proveedor.ubi_codubi = ubigeo ? ubigeo.ubi_codubi : null;
            item['ubigeo'] = proveedor.ubs_codubs;
            this.proveedoresARegistrar.push(proveedor)
            console.log('proveedoresARegistrar', this.proveedoresARegistrar);
          }

          if (ruc.existe && ruc.cliente[0].cli_indpro == 0) {
            const proveedor = Proveedor.fromCONEFACTToProvider(ruc.proveedor);
            proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
            proveedor.cli_coduse = this._configurationService.obtenerIdUsuario();
            const ubigeo = this.ubigeos.find(item => item.ubs_descri == `${ruc.proveedor.departamento}, ${ruc.proveedor.provincia}, ${ruc.proveedor.distrito}`);
            proveedor.ubs_codubs = ubigeo ? ubigeo.ubs_codubs : null;
            proveedor.ubi_codubi = ubigeo ? ubigeo.ubi_codubi : null;
            item['ubigeo'] = proveedor.ubs_codubs;
            this.proveedoresAActuralizarIndPro.push(proveedor)
          }

        });

        console.log('proveedores a registrar', this.proveedoresARegistrar)

        console.log('RECEPCIONES', registrosRecepcion)
        console.log('this.dataSource.data', this.dataSource.data)

        this.recepciones = [...registrosRecepcion, ...this.dataSource.data];
        console.log('primera carga', registrosRecepcion)
        console.log('primera carga data', this.dataSource.data)
        this.dataSource = fillTable([...registrosRecepcion, ...this.dataSource.data], this.paginator, this.sort)
        this.toggleAllRows();

        const proveedoresExistentes = registrosRecepcion.filter(item => item.existe == 'SI' && item.cli_indpro == 1)
        const proveedoresNoExistentes = registrosRecepcion.filter(item => item.existe == 'NO' || (item.existe == 'SI' && item.cli_indpro == 0))

        console.log('proveedoresExistentes', proveedoresExistentes)
        console.log('proveedoresNoExistentes', proveedoresNoExistentes)

        this.proveedoresNoExistentes = proveedoresNoExistentes;
        /**
         * Genera un modal para visualizar los proveedores que no existen
         */

        if (this.proveedoresARegistrar.length > 0 || this.proveedoresAActuralizarIndPro.length > 0) {
          this._proveedorService.registrarProveedoresMasivamente({ proveedoresARegistrar: this.proveedoresARegistrar, proveedoresAActualizar: this.proveedoresAActuralizarIndPro }).subscribe(
            resultado => {
              this._snackBarService.showSuccess('Proveedores registrados correctamente', 'Ok');

              this._clienteService.obtenerClientes().subscribe(
                clientes => {
                  const proveedores = clientes.filter(item => item.cli_indpro == 1);
                  this.maestrosRegistroCompra = { ...this.maestrosRegistroCompra, proveedores, auxiliares: clientes }
                  this.registrarMasivamenteRecepcion$ = this._recepcionService.registrarMasivamente(registrosRecepcion).subscribe(
                    recepciones => {
                      this._snackBarService.showSuccess('Recepciones registradas correctamente', 'Ok');
                      recepciones.forEach((item, index) => {
                        this.dataSource.data[index].CCR_CODCCR = item.CCR_CODCCR
                      })

                      this.dataSource = fillTable(this.dataSource.data, this.paginator, this.sort);

                      this.loaderListarRecepcion = false;
                    },
                    error => {
                      this._snackBarService.showError(error.error.msg, 'Ok');

                      this.loaderListarRecepcion = false;
                    }
                  )
                },
                error => {
                  this.loaderListarRecepcion = false;
                  this._snackBarService.showError(error.error.msg, 'Ok');
                }
              )
            },
            error => {
              this.loaderListarRecepcion = false;
              this._snackBarService.showError(error.error.msg, 'Ok');
            }
          )

        } else {
          this.loaderListarRecepcion = false;

          this.registrarMasivamenteRecepcion$ = this._recepcionService.registrarMasivamente(registrosRecepcion).subscribe(
            recepciones => {
              this._snackBarService.showSuccess('Recepciones registradas correctamente', 'Ok');
              recepciones.forEach((item, index) => {
                this.dataSource.data[index].CCR_CODCCR = item.CCR_CODCCR
              })

              this.dataSource = fillTable(this.dataSource.data, this.paginator, this.sort);
              this.loaderListarRecepcion = false;
            },
            error => {
              this._snackBarService.showError(error.error.msg, 'Ok');

              this.loaderListarRecepcion = false;
            }
          )

        }

      },
      error => {
        console.log('error', error)
        this.loaderListarRecepcion = false;
      }
    )
  }

  /**
   * Completa los ceros a la izquierda para completar a los 8 dígitos
   * @param numero
   * @returns
   */
  completarCerosIzquierda(numero) {
    // Convierte el número a cadena
    let numeroStr = numero.toString();

    // Calcula la cantidad de ceros necesarios
    let cerosNecesarios = 8 - numeroStr.length;

    // Completa ceros a la izquierda
    let numeroCompleto = '0'.repeat(cerosNecesarios) + numeroStr;

    return numeroCompleto;
  }

  /**
   * Descarga un XML desde la opción de 3 puntos que esta al final del
   * cualquier registro de recepción
   * @param row
   */
  descargarXML(row): void {
    console.log('row', row)
    const payload = {
      documentos: [
        {
          ruc: row.CLI_CODCLI,
          codofi: '01',
          serie: row.sad_codsad,
          numeroDoc: parseInt(row.CCO_NUMDOC.substring(4)).toString(),
        }
      ]
    }

    this.datosXML$ = this._contefactService.obtenerXMLBase64CONTEFACT(payload).subscribe(
      (documentosXML: Array<{ rspta: string, cpe: string, estado: string, xml: string, cdr: string }>) => {
        console.log('documentosXML', documentosXML)
        // Decode Base64 to raw binary data
        const decodedData = atob(documentosXML[0].xml);

        // Convert the binary data to a Uint8Array
        const uint8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
          uint8Array[i] = decodedData.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: "application/xml" });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = documentosXML[0].cpe;

        // Append the link to the document and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up
        document.body.removeChild(downloadLink);
      },
    )
  }

  verPdf(url): void {
    window.open(url, '_blank');
  }

  verPdfXML(row): void {

    this._fileService.listarDocumentos(row.CCR_CODCCR).subscribe(
      files => {
        const fileXML = files.find(item => item.cfe_codext == 'xml');


        const parser = new xml2js.Parser({ strict: false, trim: true });

        parser.parseString(atob(fileXML.cfe_arcfis), (err, result) => {

          console.log('JSON PDF', result)

          const data = {

          }
          generarPDFXMLSIRE({}, result)

        });
      }
    )

  }

  formatNumberWithComma(number: any): string {
    return numberWithCommas(number);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.catalogos$,
      this.consultaExistenciaRucs$,
      this.loaderRegistrarRecepcion$,
      this.registrarMasivamenteRecepcion$,
      this.periodo$,
      this.recepciones$,
      this.registrosCompra$,
      this.clientes$,
      this.condiciones$,
      this.tiposDocumento$,
      this.tiposOperacion$,
      this.tiposBienServicio$,
      this.subdiarios$,
      this.ordenes$,
      this.centros$,
      this.cuentas$,
      this.registrarProveedoresMasivamente$,
      this.signoCtaCte$,
      this.tiposRetencion$,
      this.ubigeos$,
      this.datosXML$,
    ])
  }

}
