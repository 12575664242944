import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { ContratoPersonal, LiquidacionBeneficio, PeriodoCTS, PeriodoNomina } from 'src/app/models';
import { RemuneracionFija } from 'src/app/models/planilla/datos-periodo/remuneracion-fija';
import { MovimientoRemuneracionColaborador } from 'src/app/models/planilla/operaciones/movimiento-remuneracion-colaborador';
import { EditaDeduccion } from 'src/app/models/planilla/operaciones/edita-deduccion';
import { ConfiguracionAdelanto } from 'src/app/models/planilla/configuraciones/configuracion-adelanto';
import { ConfiguracionDeduccion } from 'src/app/models/planilla/configuraciones/configuracion-deduccion';
import { ConfiguracionRemuneracion } from 'src/app/models/planilla/configuraciones/configuracion-remuneracion';
import { OtrosIngresosAfectoRenta } from 'src/app/models/planilla/datos-periodo/otros-ingresos-afecto-renta';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NominaService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarNomina(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listaAfp/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @param ant
   * @param nti
   * @param payload
   * @returns
   */
  obtenerInformePDFBoletaPago(cod, ant, nti, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-reporte-pdf-boleta-pago/${this._configurationService.obtenerCompaniaCliente()}/${cod}${ant}${nti}`,
      payload
    );
  }

  /**
   *
   * @param codnpe
   * @returns
   */
  obtenerInformePDFPlanillaCalculada(codnpe): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-planilla-calculada/${this._configurationService.obtenerCompaniaCliente()}/${codnpe}`
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerInformePDFPlanillaRemuneracionAFP(
    ano,
    mes,
    payload
  ): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-planilla-remuneraciones-afp/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformePDFPlanillaLineal(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-planilla-lineal/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param npe
   * @param payload
   * @returns
   */
  obtenerInformePDFPlanillaLinealNomina(npe, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-planilla-lineal-nomina/${this._configurationService.obtenerCompaniaCliente()}/${npe}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformePDFResumenPlanillaLiquidados(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-resumen-planilla-liquidados/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformePDFPlanillaConceptoAfectoAFP(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-planilla-de-concepto-afecto-afp/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformePDFResumenPlanilla(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-resumen-planilla/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformePDFPlanillaResumenPorConcepto(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-informe-planilla-resumen-por-concepto/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param code
   * @param payload
   * @returns
   */
  obtenerInformePDFNetAPagar(code, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-informe-neto-a-pagar/${this._configurationService.obtenerCompaniaCliente()}/${code}`,
      payload
    );
  }

  /**
   *
   * @param code
   * @param payload
   * @returns
   */
  obtnerInformePDFRevisaRentaCalculada(code, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-informe-reviso-renta-calculada/${this._configurationService.obtenerCompaniaCliente()}/${code}`,
      payload
    );
  }

  /**
   *
   * @returns
   */
  obtenerTiposNomina(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/tipos-nomina`
    );
  }

  /**
   *
   * @param code
   * @returns
   */
  obtenerSecuenciaNomina(code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/secuencias-nomina/${this._configurationService.obtenerCompaniaCliente()}/${code}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerRemuneracionesTrabajador(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-informe-remuneraciones-trabajadores/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeGratificacionesAPagar(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-informe-gratificaciones-a-pagar/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @returns
   */
  obtenerInformeCantidadTrabajadores(year): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/cantidad-trabajadores/${this._configurationService.obtenerCompaniaCliente()}/${year}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeSexoTrabajadores(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/composicion-sexo-trabajadores/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeEdadTrabajadores(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/composicion-edad-trabajadores/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeCargoTrabajadores(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/composicion-cargo-trabajadores/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeGradoInstruccionTrabajadores(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/composicion-grado-instruccion-trabajadores/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeEdadSexoTrabajadores(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/composicion-edad-sexo-trabajadores/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeLugarTrabajoEdadSexoTrabajadores(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/composicion-lugar-trabajo-edad-sexo-trabajadores/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @returns
   */
  listarColaboradores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasnomina/colaboradores/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((colaboradores) => {
        return colaboradores.map(colaborador => {
          colaborador['description'] = `${colaborador['codcli']} | ${colaborador['nomcli']}`
          return colaborador;
        })
      })
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  listarColaboradoresPeriodo(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/colaboradores-periodo-nomina/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @returns
   */
  listarConceptos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasnomina/conceptos`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  listarConceptosRemuneracion(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/conceptos-remuneracion/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    ).pipe(
      map((conceptos) => {
        return conceptos.map(concepto => {
          concepto['description'] = `${concepto.descri} | (${concepto.codnco})`;
          return concepto;
        })
      })
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param cod
   * @returns
   */
  listarDetalleConceptosRemuneracion(year, month, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/detalle-conceptos-remuneracion/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}/${cod}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  listarConceptosDeducciones(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/conceptos-deducciones/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param npe
   * @param cod
   * @returns
   */
  verDetalleConceptoDeducciones(npe, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/detalle-conceptos-deducciones/${this._configurationService.obtenerCompaniaCliente()}/${npe}/${cod}`
    );
  }

  /**
   *
   * @param npe
   * @param cod
   * @returns
   */
  listarDetalleMovimientosRemuneracion(npe, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/detalle-movimiento-remuneracion/${this._configurationService.obtenerCompaniaCliente()}/${npe}/${cod}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeRemuneracionesNomina(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/remuneraciones-nomina/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeCambiosRemuneraciones(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-cambios-remuneraciones/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeCuentasBancoTrabajador(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-cuentas-banco-colaborador/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeControlVacacional(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-control-vacacional/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeControlVacacionalFisico(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-control-vacacional-fisico/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeDiasEfectivosLaborales(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-dias-efectivos-laborales/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeAcumuladoConceptoTrabajador(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-acumulado-concepto-colaborador/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeAcumuladoConceptoTrabajadorAnual(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-acumulado-concepto-colaborador-anual/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param periodoini
   * @param periodofin
   * @param payload
   * @returns
   */
  obtenerInformeAcumuladoConceptoGrupo(
    periodoini,
    periodofin,
    payload
  ): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-acumulado-concepto-grupo/${this._configurationService.obtenerCompaniaCliente()}/${periodoini}/${periodofin}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeAcumuladoColaboradorConcepto(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-acumulado-colaborador-concepto/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeDependienteEdades(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-dependiente-por-edades/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerInformeDependienteColaborador(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-dependiente-por-colaborador/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param dni
   * @returns
   */
  obtenerInformeLiquidacionBeneficios(year, month, dni): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-liquidacion-beneficios-colaborador/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}/${dni}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  obtenerReporteColaboradores(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-de-colaboradores/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerLiquidaciones(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listado-liquidaciones/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param code
   * @returns
   */
  obtenerLiquidacion(code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/liquidacion-benericio-social/${this._configurationService.obtenerCompaniaCliente()}/${code}`
    );
  }

  /**
   *
   * @param liquidacion
   * @returns
   */
  registrarLiquidacionBeneficioSocial(liquidacion: LiquidacionBeneficio): Observable<any> {
    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/nomina/liquidacion-beneficio`, liquidacion)
  }

  /**
   *
   * @param liquidacion
   * @returns
   */
  actualizarLiquidacionBeneficioSocial(liquidacion: LiquidacionBeneficio): Observable<any> {
    return this.http.put<any>(`${this._configurationService.obtenerUrl()}/api/nomina/liquidacion-beneficio`, liquidacion)
  }

  /**
   *
   * @returns
   */
  listarMotivoCese(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasnomina/maestro/motivos-cese`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerLiquidacionesCTS(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/liquidaciones-cts/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerLiquidacionesPeriodoCTS(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/liquidaciones-periodo-cts/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   *
   * @param code
   * @returns
   */
  obtenerLiquidacionCTS(code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/liquidacion-cts/${this._configurationService.obtenerCompaniaCliente()}/${code}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  obtenerPeriodosNomina(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listado-periodo-calculo/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @param code
   * @returns
   */
  obtenerPeriodoNomina(code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-periodo-calculo/${this._configurationService.obtenerCompaniaCliente()}/${code}`
    );
  }

  /**
   *
   * @param payload
   * @returns
   */
  registrarPeriodoNomina(payload: PeriodoNomina): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/periodo-nomina/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   *
   * @param payload
   * @returns
   */
  actualizarPeriodoNomina(payload: PeriodoNomina): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/periodo-nomina/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   *
   * @returns
   */
  obtenerFrecuencias(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-frecuencias`
    );
  }

  /**
   *
   * @param year
   * @returns
   */
  obtenerConfiguracionRentaQuinta(year): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/configuracion-renta-quinta/${this._configurationService.obtenerCompaniaCliente()}/${year}`
    );
  }

  /**
   *
   * @returns
   */
  listarContratos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-contratos-personal/01/2020`
    );
  }

  /**
   *
   * @param contrato
   * @returns
   */
  registrarContrato(contrato: ContratoPersonal): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/contratos-personal/01`,
      contrato
    );
  }

  /**
   *
   * @param contrato
   * @returns
   */
  actualizarContrato(contrato: ContratoPersonal): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/contratos-personal/01`,
      contrato
    );
  }

  /**
   *
   * @returns
   */
  listarPlantillas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-plantillas/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarEstadoCivil(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-estado-civil/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarLugarDeNacimiento(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-lugar-nacimiento/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarTipoDePersonal(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-tipos-de-personal/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarSexo(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-sexo/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarExcepcionAporte(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-excepcion-aporte/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarComisionAfp(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-comision-afp/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarGradoInstruccion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-grado-de-instruccion/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarSituaciones(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-situaciones/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarTiposConcepto(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/tipos-concepto/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarUnidades(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/unidades-tiempo-pago/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarGrupos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/grupos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  listarConfiguracionRemuneracion(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/configuracion-remuneracion-periodo/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param periodo
   * @param cod
   * @returns
   */
  verConfiguracionRemuneracion(periodo, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/ver-configuracion-remuneracion-periodo/${this._configurationService.obtenerCompaniaCliente()}/${periodo}/${cod}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  listarConfiguracionDeduccion(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/configuracion-deduccion-periodo/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param periodo
   * @param cod
   * @returns
   */
  verConfiguracionDeduccion(periodo, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/ver-configuracion-deduccion-periodo/${this._configurationService.obtenerCompaniaCliente()}/${periodo}/${cod}`
    );
  }

  /**
   *
   * @returns
   */
  listarEdicionContrato(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-edicion-de-contratos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarDatoContrato(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-dato-contrato/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerEdicionContrato(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/ver-edicion-de-contrato/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param codigo
   * @returns
   */
  listarColumnasInformeColaboradores(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listacamposreporte/${codigo}/001`
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerReporteValidacionTrabajadorAsientoPlanilla(ano, mes, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-reporte-validacion-trabajador-asiento-planilla/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerReporteProvisionGratificacion(ano, mes, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-reporte-provision-gratificacion/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param payload
   * @returns
   */
  obtenerReporteProvisionGratificacionAnual(ano, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-reporte-provision-gratificacion-anual/${this._configurationService.obtenerCompaniaCliente()}/${ano}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerReporteProvisionCTS(ano, mes, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-reporte-provision-cts/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param payload
   * @returns
   */
  obtenerReporteProvisionCTSAnual(ano, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-reporte-provision-cts-anual/${this._configurationService.obtenerCompaniaCliente()}/${ano}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerReporteProvisionVacaciones(ano, mes, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-reporte-provision-vacaciones/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param payload
   * @returns
   */
  obtenerReporteProvisionVacacionesAnual(ano, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/obtener-reporte-provision-vacaciones-anual/${this._configurationService.obtenerCompaniaCliente()}/${ano}`,
      payload
    );
  }

  /**
   *
   * @param npe
   * @param nco
   * @returns
   */
  listarMovimientosNomina(npe, nco): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/movimientos-nomina/${this._configurationService.obtenerCompaniaCliente()}/${npe}/${nco}`
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @returns
   */
  listarPeriodoCTS(ano, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-periodo-cts/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param periodoCTS
   * @returns
   */
  registrarPeriodoCTS(year, month, periodoCTS: PeriodoCTS): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/periodo-cts/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      periodoCTS
    )
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerReportePlanillaMensual(ano, mes, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/reporte-dinamico-planilla-mensual/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param npe
   * @param payload
   * @returns
   */
  obtenerReportePlanillaSecuencia(npe, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/reporte-dinamico-planilla-secuencia/${this._configurationService.obtenerCompaniaCliente()}/${npe}`,
      payload
    );
  }

  /**
   *
   * @param cli_codcli
   * @param ano_codano
   * @param mes_codmes
   * @returns
   */
  obtenerDataConstanciaTrabajo(cli_codcli, ano_codano, mes_codmes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/constancia-de-trabajo/${this._configurationService.obtenerCompaniaCliente()}/${cli_codcli}/${ano_codano}/${mes_codmes}`
    );
  }

  /**
   *
   * @param cli_codcli
   * @param ano_codano
   * @param mes_codmes
   * @returns
   */
  obtenerDataCertificadoTrabajo(cli_codcli, ano_codano, mes_codmes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/certificado-de-trabajo/${this._configurationService.obtenerCompaniaCliente()}/${cli_codcli}/${ano_codano}/${mes_codmes}`
    );
  }

  /**
   *
   * @param payload
   * @returns
   */
  obtenerReporteContrato(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/reporte-contratos/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    );
  }

  /**
   *
   * @returns
   */
  listarVinculosFamiliar(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/vinculos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param remuneraciones
   * @param year
   * @param month
   * @param nco
   * @returns
   */
  registrarRemuneracionesFijas(remuneraciones: Array<RemuneracionFija>, year, month, nco): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/remuneraciones-fijas/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}/${nco}`,
      remuneraciones
    );
  }

  /**
   *
   * @param movimientos
   * @param npe
   * @param nco
   * @returns
   */
  registrarMovimientoRemuneracionColaborador(movimientos: Array<MovimientoRemuneracionColaborador>, npe, nco): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/movimiento-remuneracion-colaborador/${this._configurationService.obtenerCompaniaCliente()}/${npe}/${nco}`,
      movimientos
    );
  }

  /**
   *
   * @param items
   * @param npe
   * @param nco
   * @returns
   */
  registrarEditaDeduccionNomina(items: Array<EditaDeduccion>, npe, nco): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/edita-deduccion-nomina/${this._configurationService.obtenerCompaniaCliente()}/${npe}/${nco}`,
      items
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @returns
   */
  obtenerDataFormColaborador(ano, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/forms/colaborador/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerDataFormContrato(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/forms/contrato/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerOtrosIngresosRentaQuinta(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/otros-ingresos-afecto-renta/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   *
   * @param otrosIngresos
   * @returns
   */
  registrarOtrosIngresosRentaQuinta(otrosIngresos: OtrosIngresosAfectoRenta) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/otros-ingresos-afecto-renta`,
      otrosIngresos
    );
  }

  /**
   *
   * @returns
   */
  obtenerDeclaracionMensualIngreso(): Observable<any> {
    return null;
  }

  /**
   *
   * @param renta
   * @returns
   */
  registrarDeclaracionMensualIngreso(renta): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/renta-ingresos-renta`,
      renta
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerConfiguracionAdelanto(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/configuracion-adelanto/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
    );
  }

  /**
   *
   * @param configuracion
   * @returns
   */
  registrarConfiguracionAdelanto(configuracion: ConfiguracionAdelanto) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/configuracion-adelanto`,
      configuracion
    );
  }

  /**
   *
   * @param configuracion
   * @returns
   */
  actualizarConfiguracionAdelanto(configuracion: ConfiguracionAdelanto) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/configuracion-adelanto`,
      configuracion
    );
  }

  /**
   *
   * @param configuracion
   * @returns
   */
  registrarDeduccionAporte(configuracion: ConfiguracionDeduccion) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/deduccion-aporte`,
      configuracion
    );
  }

  /**
   *
   * @param configuracion
   * @returns
   */
  actualizarDeduccionAporte(configuracion: ConfiguracionDeduccion) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/deduccion-aporte`,
      configuracion
    );
  }

  /**
   *
   * @param configuracion
   * @returns
   */
  registrarConfiguracionRemuneracion(configuracion: ConfiguracionRemuneracion) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/configuracion-remuneracion`,
      configuracion
    );
  }

  /**
   *
   * @param configuracion
   * @returns
   */
  actualizarConfiguracionRemuneracion(configuracion: ConfiguracionRemuneracion) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/configuracion-remuneracion`,
      configuracion
    );
  }

  /**
   *
   * @param payload
   * @returns
   */
  inicializaPlanilla(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/inicializa-planilla`,
      payload
    )
  }

  /**
   *
   * @param payload
   * @returns
   */
  obtenerInformeDocumentoLegajo(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/informe-documento-legajo/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   * Interface Plame | Info Remueración Colaborador
   * @param req
   * @param res
   * @returns
   */
  obtenerDataRemuneracionColaborador(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/interface-plame/remuneracion-colaborador/data/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   * Interface Plame | Info Jornada Laboral Colaborador
   * @param req
   * @param res
   * @returns
   */
   obtenerDataJornadaLaboralColaborador(year, month, npe): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/interface-plame/jornada-laboral-colaborador/data/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}/${npe}`
    )
  }

  /**
   * Interface Plame | Info Días Subsidiados no laborados
   * @param req
   * @param res
   * @returns
   */
   obtenerDataInfoDiasSubsidiadosNoLaborados(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/interface-plame/dias-subsidiados-no-laborados/data/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   * Interface Plame | Info Seguro vital ley
   * @param req
   * @param res
   * @returns
   */
   obtenerDataSeguroVitalLey(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/interface-plame/seguro-vida-ley/data/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerDetalleInteresCTS(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/interes-banco-cts/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   *
   * @param year
   * @param month
   * @param payload
   * @returns
   */
  registrarInteresBancoCTS(year, month, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/interes-banco-cts/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`,
      payload
    )
  }
}
