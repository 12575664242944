import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notificaciones: any[] = [1,2,3,4];

  constructor(
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

}
