<app-pavso-title-section [title]="'Participación de utilidades'" [module]="'Planilla'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Período</mat-label>
                      <input type="text" matInput placeholder="Período" name="periodo" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Fecha de Cálculo</mat-label>
                      <input matInput [matDatepicker]="fechacalculo">
                      <mat-datepicker-toggle matSuffix [for]="fechacalculo"></mat-datepicker-toggle>
                      <mat-datepicker #fechacalculo></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Renta Neta</mat-label>
                      <input type="text" matInput placeholder="Renta Neta" name="renta_neta" required>
                  </mat-form-field>
              </div>

          </div>
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Días %</mat-label>
                      <input type="number" matInput placeholder="Días %" name="nro_licencia" required >
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Total Días Trab.</mat-label>
                      <input type="number" matInput placeholder="Total Días Trab." name="total_dias_trab" required >
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Porc. Dist.</mat-label>
                      <input type="number" matInput placeholder="Porc. Dist." name="porc_dist" required >
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Sueldo %</mat-label>
                      <input type="number" matInput placeholder="Sueldo %" name="sueldo" required >
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Total de Rem.</mat-label>
                      <input type="number" matInput placeholder="Total de Rem." name="total_rem" required >
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Renta a Distribuir</mat-label>
                      <input type="number" matInput placeholder="Renta a Distribuir" name="renta_a_distribuir" required >
                  </mat-form-field>
              </div>

          </div>
      </mat-card>
      <br>
      <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSource" matSort >
              <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ítem </th>
                  <td mat-cell *matCellDef="let row" data-label="Ítem"> {{row.cli_codcli}} </td>
              </ng-container>
              <ng-container matColumnDef="cliente">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                  <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
              </ng-container>
              <ng-container matColumnDef="nombre_trabajador">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del Colaborador </th>
                  <td mat-cell *matCellDef="let row" data-label="Nombre del Colaborador"> {{row.cli_numtlf}} </td>
              </ng-container>
              <ng-container matColumnDef="dia_efec">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Día Efec. </th>
                  <td mat-cell *matCellDef="let row" data-label="Día Efec."> {{row.cli_numtlf}} </td>
              </ng-container>
              <ng-container matColumnDef="sueldo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sueldo </th>
                  <td mat-cell *matCellDef="let row" data-label="Sueldo"> {{row.cli_numtlf}} </td>
              </ng-container>
              <ng-container matColumnDef="importe_dia">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Día </th>
                  <td mat-cell *matCellDef="let row" data-label="Importe Día"> {{row.cli_numtlf}} </td>
              </ng-container>
              <ng-container matColumnDef="importe_sueldo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Sueldo </th>
                  <td mat-cell *matCellDef="let row" data-label="Importe Sueldo"> {{row.cli_numtlf}} </td>
              </ng-container>
              <ng-container matColumnDef="participacion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Participación </th>
                  <td mat-cell *matCellDef="let row" data-label="Participación"> {{row.cli_numtlf}} </td>
              </ng-container>
              <ng-container matColumnDef="renta_quinta">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Renta 5ta </th>
                  <td mat-cell *matCellDef="let row" data-label="Renta 5ta"> {{row.cli_numtlf}} </td>
              </ng-container>
              <ng-container matColumnDef="renta_quinta_2">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Renta 5ta 2 </th>
                  <td mat-cell *matCellDef="let row" data-label="Renta 5ta 2"> {{row.cli_numtlf}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="10">No se encontraron registros</td>

              </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <br>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Importe Días</mat-label>
                      <input type="number" matInput placeholder="Importe Días" name="importe_dias" required >
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Sueldo</mat-label>
                      <input type="number" matInput placeholder="Sueldo" name="sueldo" required >
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Total</mat-label>
                      <input type="number" matInput placeholder="Total" name="total" required >
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <button mat-stroked-button type="button" color="primary">1. Cargar Datos Colaboradores</button>
                  <br>
                  <button mat-stroked-button type="button" color="primary">2. Distribuir Utilidades</button>
                  <br>
                  <button mat-stroked-button type="button" color="primary">3. Calcular Renta</button>
                  <br>
                  <mat-form-field>
                      <mat-label>AÑO + MES</mat-label>
                      <input type="number" matInput placeholder="AÑO + MES" name="anio_mes" required >
                  </mat-form-field>
              </div>

          </div>
      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
