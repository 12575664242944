<mat-sidenav-container class="example-container">
  <mat-sidenav #drawer mode="over" position="end">

  </mat-sidenav>
  <mat-sidenav-content class="pavso-container">
    <div [ngStyle]="{marginTop: '100px'}">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
