import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { GrupoFlujoCaja } from 'src/app/models/caja-banco';
import { FlujoCaja } from 'src/app/models/caja-banco/flujo_caja';
import { CajaBancoService } from 'src/app/services';
import { FlujoCajaService } from 'src/app/services/api/tesoreria/maestros/fljujo-caja.service';
import { GrupoFlujoCajaService } from 'src/app/services/api/tesoreria/maestros/grupo-flujo-caja.service';

@Component({
  selector: 'app-flujo-caja-form',
  templateUrl: './flujo-caja-form.component.html',
  styleUrls: ['./flujo-caja-form.component.css']
})
export class FlujoCajaFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  flujoCaja: FlujoCaja;

  grupos: any[] = [];
  uid: string;

  flujoCaja$: Subscription;
  loading$: Subscription;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _cajaBancoService: CajaBancoService,
    private _grupoFlujoCajaService: GrupoFlujoCajaService,
    private _flujoCajaService: FlujoCajaService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>
  ) {
    this.flujoCaja = new FlujoCaja();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      if(id == '0') return
      this.uid = id;
      this.flujoCaja$ = this._flujoCajaService.mostrarFlujoCaja(id).subscribe(
        ([flujo]) => {
          this.flujoCaja = flujo;
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    })
  }

  loadMaestros(): void {
    this._grupoFlujoCajaService.obtenerGrupoFlujoCajas().subscribe(
      grupos => {
        this.grupos = grupos;
      },
      error => {
        this._snackBarService.showError("Error al obtener grupos de flujo caja", "OK");
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0'? this.registrarFlujo(f): this.actualizarFlujo(f);
  }

  registrarFlujo(f: NgForm): void {
    this._flujoCajaService.registrarFlujoCaja(this.flujoCaja).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Flujo de caja registrado', '400px', '400px', '');
        f.resetForm();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarFlujo(f: NgForm): void {
    this._flujoCajaService.registrarFlujoCaja(this.flujoCaja).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Flujo de caja actualizada', '400px', '400px', '');

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-cajabanco/flujos-de-caja']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.flujoCaja$, this.loading$])
  }
}
