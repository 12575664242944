export class Especificacion {
    cia_codcia: string;
    poc_codpoc: string;
    poc_descri: string;
    poc_indsta: string;
    poc_fecupd: Date;
    poc_coduse: string;

    constructor() {
        this.poc_fecupd = new Date();
        this.poc_indsta = '1';
    }
}
