

<div class="row pavso-container" [ngStyle]="{marginTop: '150px'}">
  <mat-progress-bar *ngIf="loaderData" mode="indeterminate"></mat-progress-bar>

  <mat-card class="pav-card-dashboard mat-elevation-z0">
    <mat-card-header [style.background]="colorYellow">
      <mat-card-title>
        <mat-icon>bookmark</mat-icon>
        {{'profile.header.logo' | translate}}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div align="center">
        <div class="pav-logo-client" *ngIf="!cia.CIA_LOGB64 && !imageBase64">
          <span>L</span>

        </div>
        <div class="pav-logo-client" *ngIf="!imageBase64 && !loaderData">

          <img [src]="cia.CIA_LOGB64" width="100" height="100" alt="Mi Logo" [ngStyle]="{borderRadius: '8px'}">
        </div>

        <div class="pav-logo-client" *ngIf="imageBase64">

          <img [src]="imageBase64" width="100" height="100" alt="Mi Logo" [ngStyle]="{borderRadius: '8px'}">
        </div>
      </div>

      <br>
      <mat-divider></mat-divider>
      <br>
      <div class="pav-personal-content-button" align="center">
        <button [disabled]="loaderData" mat-button (click)="fileInput.click()">{{'profile.button.change-logo' | translate}}</button>
        <input hidden (change)="onFileSelected($event)" accept=".jpg, .jpeg, image/png" #fileInput type="file">

        <button [disabled]="loaderData" type='button' (click)="guardarCambios()" mat-button color="primary">Guardar Cambios</button>

      </div>
    </mat-card-content>
  </mat-card>

</div>
