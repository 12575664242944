<h1 class="pav-shortcuts-title">Atajos del sistema PAV Solutions</h1>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Generales (Presionar ⌘ o ctrl)
      </mat-panel-title>
      <mat-panel-description>
        <!-- This is a summary of the content -->
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-list role="list">
      <mat-divider></mat-divider>
      <mat-list-item role="listitem">(shift + C) | Ver configuración</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item role="listitem">(shift + F) | Buscar módulo</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item role="listitem">(shift + 1) | Período de actividad</mat-list-item>
      <mat-list-item role="listitem">(shift + 2) | Abrir / Cerrar período</mat-list-item>

      <mat-divider></mat-divider>
    </mat-list>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Maestros (Presionar ⌘ o ctrl)
      </mat-panel-title>
      <mat-panel-description>
        <!-- This is a summary of the content -->
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-list role="list">
      <mat-divider></mat-divider>
      <mat-list-item role="listitem">(R) | Recargar página</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item role="listitem">(N) | Nuevo registro</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item role="listitem">(B) | Volver</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item role="listitem">(S) | Guardar</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Tabla Detalle
      </mat-panel-title>
      <mat-panel-description>
        <!-- This is a summary of the content -->
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-list role="list">
      <mat-divider></mat-divider>
      <mat-list-item role="listitem"> (ctrl + shift + I) | Insertar registro</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item role="listitem">(ctrl + shift + A) | Agregar registro</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>
