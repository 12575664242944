export class Driver {

  CIA_CODCIA: string;
  CHO_CODCHO: string;
  CHO_NOMBRE: string;
  CHO_NROLIC: string;
  CHO_INDSTA: string;
  CHO_FECUPD: Date;
  CHO_CODUSE: string;
  DID_CODDID: string;
  CHO_DOCIDE: string;
  CHO_USUAPP: string;

  constructor(
  ){
    this.CHO_FECUPD = new Date();
    this.CHO_INDSTA = "1";
  }
}
