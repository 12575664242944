<app-pavso-title-section [title]="'Conciliación bancaria'" [module]="'Caja y bancos'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarConciliacion(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Banco</mat-label>
            <mat-select required name="cta_banco">
              <mat-option value="1">
                cta banco 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <mat-checkbox class="example-margin">Saldo del período</mat-checkbox>
        </div>
        <div class="col s12 m12 l1">
          <mat-form-field class="example-full-width">
            <mat-label>Año</mat-label>
            <input type="number" min="0" matInput placeholder="Año" [(ngModel)]="conciliacionBancaria.ano_codano"
              name="ano_codano" disabled>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l1">
          <mat-form-field class="example-full-width">
            <mat-label>Mes</mat-label>
            <input type="number" min="0" matInput placeholder="Mes" [(ngModel)]="conciliacionBancaria.mes_codmes"
              name="mes_codmes" disabled>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l5">
          <mat-checkbox>Seleccionar</mat-checkbox>
          <br>
          <mat-radio-group>
            <mat-radio-button value="1">Fecha de Orden</mat-radio-button>

            <mat-radio-button value="2">Fecha Entrega</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Desde - Hasta</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-radio-group>
            <mat-radio-button value="1">Pendientes</mat-radio-button>

            <mat-radio-button value="2">Concluidas</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col s12 m12 l4">
          <mat-checkbox>Todos</mat-checkbox> &nbsp;
          <mat-checkbox>Ingresos</mat-checkbox> &nbsp;
          <mat-checkbox>Salidas</mat-checkbox>

        </div>

        <div class="col s12 m12 l1">
          <mat-form-field>
            <mat-label>Saldo</mat-label>
            <input type="number" min="0" matInput placeholder="Saldo" name="saldo" required>
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <br>
    <div class="row">
      <div class="col s12">

        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Tabla</div>
          <div class="pav-separator"></div>
          <div class="pav-table-search">
            <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
          </div>
          &nbsp;
          &nbsp;
          <div class="pav-table-header-add">
            <div class="isMobile">
              <div class="pav-button-icon-add" (click)="agregar()">
                <mat-icon svgIcon="add"></mat-icon>
              </div>
            </div>
            <div class="isWeb">
              <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregar()">Agregar</button>
            </div>
          </div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones">

                <ng-container>
                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="quitar(i)">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </div>

                  </div>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="voucher">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Voucher </th>
              <td mat-cell *matCellDef="let row" data-label="Voucher"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_voucher">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Voucher </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha de Voucher"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_operacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de operación </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha de operación"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="tdo_codtdo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Doc.">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 70px;" class="mat-form-field-table">
                    <mat-label>Doc.</mat-label>
                    <mat-select [(ngModel)]="row.tdo_codtdo" [name]="'doc' + i.toString()">
                      <mat-option *ngFor="let tipo of tiposDoc" [value]="tipo.TDO_CODTDO">
                        {{tipo.TDO_CODTDO}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.tdo_codtdo}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_docref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Nro. Doc.">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 100px;" class="mat-form-field-table">
                    <mat-label>Nro. Doc.</mat-label>
                    <input matInput placeholder="Nro. Doc." [(ngModel)]="row.ldi_docref"
                      [name]="'nro_doc' + i.toString()">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ldi_docref}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ingresos">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ingresos </th>
              <td mat-cell *matCellDef="let row" data-label="Ingresos"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="salidas">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Salidas </th>
              <td mat-cell *matCellDef="let row" data-label="Salidas"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="tmo_codtmo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Mon.">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 70px;" class="mat-form-field-table">
                    <mat-label>Moneda</mat-label>
                    <mat-select #iMoneda required [disabled]="loaderData" [(ngModel)]="row.tmo_codtmo" name="tmo_codtmo"
                      [name]="'moneda' + i.toString()">
                      <mat-option value="SO">
                        Soles
                      </mat-option>
                      <mat-option value="DO">
                        Dólares
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.tmo_codtmo}}
                </ng-container>
              </td>
            </ng-container>


            <ng-container matColumnDef="periodo_conciliacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Período Conciliación </th>
              <td mat-cell *matCellDef="let row" data-label="Período Conciliación"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="ind_c">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ind_C </th>
              <td mat-cell *matCellDef="let row" data-label="Ind_C"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="nombre_aux">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre_Aux </th>
              <td mat-cell *matCellDef="let row" data-label="Nombre_Aux"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="ldi_gloldi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="mat-form-field-table">
                    <mat-label>Glosa</mat-label>
                    <input type="text" matInput placeholder="Glosa" [(ngModel)]="row.ldi_gloldi"
                      [name]="'glosa' + i.toString()" (keyup.enter)="confirmarNuevoItem(row)">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ldi_gloldi}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="cli_codcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Auxiliar">
                <ng-container *ngIf="row.isEditing">

                  <select-search
                    [label]="'Auxiliar'"
                    [placeholder]="'Ej. TORRES'"
                    [name]="'cli_codcli' + i"
                    [value]="'cli_codcli'"
                    [description]="'cli_nomcli'"
                    [data]="auxiliares"
                    (cambioSelect)="row.cli_codcli = $event;"
                    [model]="row.cli_codcli"
                  ></select-search>

                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.cli_codcli}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ccn_codccn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 100px;" class="mat-form-field-table">

                    <select-search
                      [label]="'Cuenta'"
                      [placeholder]="'Ej. 42'"
                      [name]="'cuenta' + i"
                      [value]="'cuenta'"
                      [description]="'description'"
                      [data]="cuentas"
                      (cambioSelect)="row.ccn_codccn = $event;"
                      [model]="row.ccn_codccn"
                    ></select-search>

                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ccn_codccn}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="cuenta_bancaria">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Bancaria </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta Bancaria"> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="16">No se encontraron registros</td>

            </tr>

          </table>

        </div>
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl2">
          <mat-form-field>
            <mat-label>Nro. Movimiento</mat-label>
            <input type="number" min="0" matInput placeholder="Nro. Movimiento" name="nro_movimiento" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl2">
          <button mat-flat-button color="primary">Grabar</button>
        </div>
        <div class="col s12 m12 l12 xl2">
          <mat-form-field>
            <mat-label>Total M.N.</mat-label>
            <input type="number" min="0" matInput placeholder="Total M.N." name="total_mn" required readonly>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl2">
          <mat-form-field>
            <mat-label>Total M.E.</mat-label>
            <input type="number" min="0" matInput placeholder="Total M.E." name="total_me" required readonly>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl2">
          <mat-form-field>
            <mat-label>Pendiente M.N.</mat-label>
            <input type="number" min="0" matInput placeholder="Pendiente M.N." name="pendiente_mn" required readonly>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl2">
          <mat-form-field>
            <mat-label>Pendiente M.E.</mat-label>
            <input type="number" min="0" matInput placeholder="Pendiente M.E." name="pendiente_me" required readonly>
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>


  </form>
</div>
