import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Program } from 'src/app/models/program';
import { PlanCuentaService } from 'src/app/services/api/contabilidad/maestros/plan-cuenta.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';

export enum CriterioBusquedaPavInputSearchEnum  {
  DIGITO = 'DIGITO',
  DESCRIPCION = 'DESCRIPCION'
}
export class FiltroPavInpurtSearch {

  criterio: CriterioBusquedaPavInputSearchEnum.DESCRIPCION | CriterioBusquedaPavInputSearchEnum.DIGITO;

  constructor() {
    this.criterio = CriterioBusquedaPavInputSearchEnum.DIGITO;
  }
}
@Component({
  selector: 'pav-search-dialog',
  templateUrl: 'pav-search.dialog.html'
})

export class PavSearchDialog implements OnInit, OnDestroy {

  listado$: Subscription;

  listado: any[] = [];

  input: string;

  filtro: FiltroPavInpurtSearch;

  constructor(
    public dialogRef: MatDialogRef<PavSearchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _clienteService: ClienteService,
    private readonly _productoService: ProductoService,
    private readonly _planCuentaService: PlanCuentaService,
  ) {
    this.filtro = new FiltroPavInpurtSearch();
  }

  ngOnInit() { }

  cerrar(): void {
    this.dialogRef.close();
  }

  keyup(): void {

    if(this.data.program == Program.CLIENTES) {
      this.listado$ = this._clienteService.obtenerClientes({params: [{param: 'buscar', value: this.input}, {param: 'limite', value: 6}]}).subscribe(
        clientes => {
          this.listado = clientes.filter(item => item.cli_indcli == 1);
        },
        error => {
          console.log(error);
        }
      );
    }

    if(this.data.program == Program.PROVEEDORES) {
      this.listado$ = this._clienteService.obtenerClientes({params: [{param: 'buscar', value: this.input}, {param: 'limite', value: 6}]}).subscribe(
        clientes => {
          this.listado = clientes.filter(item => item.cli_indpro == 1);
        },
        error => {
          console.log(error);
        }
      );
    }

    if(this.data.program == Program.PRODUCTOS) {
      this.listado$ = this._productoService.obtenerProductos({params: [{param: 'buscar', value: this.input}, {param: 'limite', value: 6}]}).subscribe(
        productos => {
          console.log('productos', productos)
          this.listado = productos;
        },
        error => {
          console.log(error);
        }
      );
    }

    if(this.data.program == Program.AUXILIARES) {
      console.log('auxiliares')
      this.listado$ = this._clienteService.obtenerClientes({params: [{param: 'buscar', value: this.input}, {param: 'limite', value: 6}]}).subscribe(
        auxiliares => {
          this.listado = auxiliares.filter(item => item);
        },
        error => {
          console.log(error);
        }
      );
    }

    if(this.data.program == Program.CUENTAS) {

      // Build parameters
      const parameters = this.filtro.criterio == CriterioBusquedaPavInputSearchEnum.DIGITO ?
      [{param: 'inicia', value: this.input}, {param: 'limite', value: 6}] :
      [{param: 'descri', value: this.input}, {param: 'limite', value: 6}];

      this.listado$ = this._planCuentaService.obtenerPlanCuentas({params: parameters}).subscribe(
        cuentas => {
          this.listado = cuentas.filter(item => item);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  seleccionar(item: any): void {
    console.log(item)
    this.dialogRef.close(item);
  }



  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.listado$
    ])
  }
}
