export class FiltroAprobacionPedido {
  proveedor: string;
  desde: Date;
  hasta: Date;
  fechaSeleccionada: boolean;
  estaAprobado: boolean;
  situacion: '1' | '0';

  constructor() {
    this.estaAprobado = false;
    this.situacion = '0';
  }
}
