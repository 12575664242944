<app-pavso-title-section [title]="'Planilla letras bancos'" [module]="'Cuentas por cobrar'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Origen</mat-label>
            <mat-select required name="origen" [disabled]="loaderData">
              <mat-option value="1">
                origen 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Registro</mat-label>
            <input type="text" matInput placeholder="Registro" name="registro" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="fechahoy">
            <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
            <mat-datepicker #fechahoy></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>T.C.</mat-label>
            <input type="number" matInput placeholder="T.C." name="tc" appTipoCambioFormat step="any" min="0" required [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>

      <div class="row">

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Banco</mat-label>
            <mat-select required name="cta_banco" [disabled]="loaderData">
              <mat-option value="1">
                cta banco 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nro. de Operación</mat-label>
            <mat-select required name="nro_operacion" [disabled]="loaderData">
              <mat-option value="1">
                cta banco 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <app-currency-select [moneda]="'SO'" [disable]="loaderData"></app-currency-select>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Auxiliar</mat-label>
            <mat-select required name="auxiliar" [disabled]="loaderData">
              <mat-option value="1">
                auxiliar 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Banco</mat-label>
            <mat-select required name="banco" [disabled]="loaderData">
              <mat-option value="1">
                banco 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo</mat-label>
            <mat-select required name="tipo" [disabled]="loaderData">
              <mat-option value="1">
                tipo 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Glosa</mat-label>
            <input type="text" matInput placeholder="Glosa" name="glosa" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Línea</mat-label>
            <mat-select required name="linea" [disabled]="loaderData">
              <mat-option value="1">
                linea 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Nro. Cuenta</mat-label>
            <input type="text" matInput placeholder="Nro. Cuenta" name="nro_cuenta" required [disabled]="loaderData">
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col s4">
          <h2>CARGOS</h2>
          <mat-form-field>
            <mat-label>Portes y Comisiones</mat-label>
            <input type="text" matInput placeholder="Portes y Comisiones" name="portes_comisiones" required
              [disabled]="loaderData">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Gastos de protesto</mat-label>
            <input type="text" matInput placeholder="Gastos de protesto" name="gastos_protesto" required
              [disabled]="loaderData">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Intereses</mat-label>
            <input type="text" matInput placeholder="Intereses" name="intereses" required [disabled]="loaderData">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Letras</mat-label>
            <input type="text" matInput placeholder="Letras" name="letras" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s4">
          <h2>ABONOS</h2>
          <mat-form-field>
            <mat-label>Intereses</mat-label>
            <input type="text" matInput placeholder="Intereses" name="intereses_2" required [disabled]="loaderData">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Letras</mat-label>
            <input type="text" matInput placeholder="Letras" name="letras" required [disabled]="loaderData">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Línea Garantía</mat-label>
            <input type="text" matInput placeholder="Línea Garantía" name="linea_garantia" required
              [disabled]="loaderData">
          </mat-form-field>

        </div>
        <div class="col s4">
          <h2>MOVIMIENTO LIBRO BANCOS</h2>
          <mat-form-field>
            <mat-label>Total Cargos</mat-label>
            <input type="number" min="0" matInput placeholder="Total Cargos" name="intereses_2" required
              [disabled]="loaderData">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Total Abonos</mat-label>
            <input type="number" min="0" matInput placeholder="Total Abonos" name="total_abonos" required
              [disabled]="loaderData">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Total Diferencia</mat-label>
            <input type="number" min="0" matInput placeholder="Total Diferencia" name="total_diferencia" required
              [disabled]="loaderData">
          </mat-form-field>

        </div>
      </div>
      <div class="row">
        <div class="col s4">

          <mat-form-field>
            <mat-label>Otros Ingresos</mat-label>
            <input type="number" min="0" matInput placeholder="Otros Ingresos" name="otros_ingresos" required
              [disabled]="loaderData">
          </mat-form-field>

        </div>
      </div>

    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>
    </div>
    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort >
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
            <button matTooltip="Editar" [routerLink]="['/modulo-cobranzas/editar-chofer', row.cli_codcli]"
              mat-icon-button color="primary">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="sit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SIT </th>
          <td mat-cell *matCellDef="let row" data-label="SIT"> {{row.cli_codcli}} </td>
        </ng-container>

        <ng-container matColumnDef="operacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Operación </th>
          <td mat-cell *matCellDef="let row" data-label="Operación"> {{row.cli_nomcli}} </td>
        </ng-container>

        <ng-container matColumnDef="cliente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="nro_letra">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Letra </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Letra"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="nro_banco">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Banco </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Banco"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="fec_venc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Venc. </th>
          <td mat-cell *matCellDef="let row" data-label="Fec. Venc."> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="mo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> MO </th>
          <td mat-cell *matCellDef="let row" data-label="MO"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="importe_nacional">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Nacional </th>
          <td mat-cell *matCellDef="let row" data-label="Importe Nacional"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="importe_dolares">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Dólares </th>
          <td mat-cell *matCellDef="let row" data-label="Importe Dólares"> {{row.cli_numtlf}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="11">No se encontraron registros</td>
        </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l4">
          <table mat-table [dataSource]="dataSource2" class="mat-elevation-z0">

            <ng-container matColumnDef="codigo" sticky>
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="mn">
              <th mat-header-cell *matHeaderCellDef> Nacional </th>
              <td mat-cell *matCellDef="let row" data-label="Soles"> {{row.mn}} </td>
            </ng-container>

            <ng-container matColumnDef="me">
              <th mat-header-cell *matHeaderCellDef> Dolares </th>
              <td mat-cell *matCellDef="let row" data-label="Dolares"> {{row.me}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros</td>
            </tr>

          </table>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Línea Crédito</mat-label>
            <input type="number" min="0" matInput placeholder="Otros Ingresos" name="otros_ingresos" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Voucher Banco</mat-label>
            <textarea matInput placeholder="Voucher Banco"></textarea>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <button mat-flat-button color="primary">Consultar Asiento</button>
          &nbsp;
          <button mat-flat-button color="primary">Cuentas x Cobrar</button>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
  </form>
</div>
