import { NgModule } from "@angular/core";
import { ContratoFormComponent } from "./contrato-form/contrato-form.component";
import { ContratoListComponent } from "./contrato-list/contrato-list.component";
import { ContratoDialog } from "./contrato-dialog/contrato-dialog.component";
import { ContratoSelect } from "./contrato-select/contrato-select.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from "@shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";

@NgModule({
  declarations: [ContratoFormComponent, ContratoListComponent, ContratoDialog, ContratoSelect],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    ContratoFormComponent, ContratoListComponent, ContratoDialog, ContratoSelect
  ]
})
export class ContratoModule {}
