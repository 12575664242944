import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlmacenService, GeneralService } from 'src/app/services';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { Subject, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { InventariosReportService } from 'src/app/services/reports/modules/inventarios-report.service';
import { FiltroReporteAlmacen } from 'src/app/models/almacen';
import { REPORTES_ALMACEN_UNIDADES } from '@data/json/reportes/almacen.reporte.json';
import { OpcionFiltro } from 'src/app/models/general/opciones-filtro';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConfiguracionTecnica } from 'src/app/models/almacen/inventario/configuracion-tecnica';
import { KardexService } from 'src/app/services/api/almacen/operaciones/kardex.service';
import { ColorService } from 'src/app/services/api/almacen/maestros/color.service';
import * as pdfBuilder from '@utils/pdfmaker/builder/almacen/almacen.pdfmaker';
import { IParam } from 'src/app/services/api/utilities/params';
import { UnidadMedidaService } from 'src/app/services/api/almacen/configuracion/unidad-medida.service';
import { SubfamiliaService } from 'src/app/services/api/almacen/tabla-apoyo/subfamilia.service';
import { ModeloService } from 'src/app/services/api/almacen/maestros/modelo.service';

export interface OtherDataFiltroAlmacen {
  params: Array<IParam>,
  filtro: FiltroReporteAlmacen,
  columns?: Array<any>
}

@Component({
  selector: 'app-informes-unidades',
  templateUrl: './informes-unidades.component.html',
  styleUrls: ['./informes-unidades.component.css']
})
export class InformesUnidadesComponent implements OnInit, OnDestroy {

  loaderFields: boolean = false;

  familias: any[] = [];
  subfamilias: any[] = [];
  almacenes: any[] = [];

  public responsables: any[] = [];

  public motivos: any[] = [];

  public productos: any[] = [];

  public documentos: any[] = [];
  public colores: any[] = [];
  public tecnologias: any[] = [];

  tiposInventario: any[] = [];
  lineas: any[] = [];
  unidadesMedida: any[] = [];
  modelos: any[] = [];

  marcas: any[] = [];
  monedas: any[] = [];

  displayedColumns: string[] = ['acciones', 'mac_nummac', 'mac_fecmac', 'mac_glomac'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tipoMovimiento = "ING";

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  range: FormGroup;

  familia = "0001";

  movimiento$: Subscription;
  columnas$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  dataForm$: Subscription;
  filtros$: Subscription;
  unidadesMedida$: Subscription;
  modelos$: Subscription;

  opcionesFiltro: any[] = [];
  opcionFiltro: OpcionFiltro;
  configuracion: ConfiguracionTecnica;

  reportes: any[] = REPORTES_ALMACEN_UNIDADES

  reporteSeleccionado: any = ['ALMACEN_MM'];

  filtroAlmacen: FiltroReporteAlmacen;

  loading: boolean = true;

  year: string;
  month: string;

  protected _onDestroy = new Subject<void>();
  isMobile: boolean = false;

  color$: Subscription;
  establecimiento$: Subscription;
  establecimiento: string;

  constructor(
    private _snackBarService: SnackBarService,
    private _router: Router,
    private _almacenService: AlmacenService,
    private _almacenReporteService: InventariosReportService,
    private _generalService: GeneralService,
    private readonly _kardexService: KardexService,
    private store: Store<PavsoState>,
    private readonly _colorService: ColorService,
    private readonly _unidadMedidaService: UnidadMedidaService,
    private readonly _subfamiliaService: SubfamiliaService,
    private readonly _modeloService: ModeloService,
    private breakpointObserver: BreakpointObserver

  ) {
    this.configuracion = new ConfiguracionTecnica();

    this.opcionFiltro = new OpcionFiltro();

    this.filtroAlmacen = new FiltroReporteAlmacen();

    this.dataSource = fillTable([], this.paginator, this.sort);

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        console.log('Is Mobile:', this.isMobile);
      });
  }


  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
      this.filtroAlmacen.establecimiento = state.code;
    })

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    });
  }

  loadData(): void {
    this.loaderFields = true;
    this.loading = true;

    this.dataForm$ = this._almacenService.obtenerDataFiltroAlmacen().subscribe(
      dataForm => {
        this.familias = dataForm.familias;
        this.familias.forEach(item => item['description_reverse'] = `${item['fpr_descri']} | ${item['FPR_CODFPR']}`)
        this.familias.forEach(item => item['FPR_CODFPR'].trim())

        this.subfamilias = dataForm.subFamilias;
        this.almacenes = dataForm.almacenes;
        this.almacenes.forEach(item => item['description_reverse'] = `${item['ALM_DESCRI']} | ${item['ALM_CODALM']}`)
        this.almacenes.forEach(item => item['ALM_CODALM'] = item['ALM_CODALM'].trim())

        if (this.establecimiento && this.establecimiento.trim() != '') {
          this.almacenes = this.almacenes.filter(item => item.TIE_CODTIE == this.establecimiento);
        }

        this.responsables = dataForm.responsables;

        this.motivos = dataForm.motivos;
        this.motivos.forEach(item => item['description_reverse'] = `${item['mmo_descri']} | ${item['mmo_codmmo']}`)
        this.motivos.forEach(item => item['mmo_codmmo'] = item['mmo_codmmo'].trim())

        this.productos = dataForm.productos;
        this.productos.forEach(item => item['description_reverse'] = `${item['DESC_ESPA']} | ${item['prd_codprd']}`)
        this.productos.forEach(item => item['prd_codprd'] = item['prd_codprd'].trim())

        this.tiposInventario = dataForm.tiposInventario;
        this.lineas = dataForm.lineas;

        this.documentos = [{ tipo: 'INGRESO', code: 'ING' }, { tipo: 'SALIDA', code: 'SAL' }];
        console.log('tiposDocumento', this.documentos)

        this.monedas = dataForm.monedas;
        this.marcas = dataForm.marcas;
        console.log('marcas', this.marcas)

        this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
          configuracion => {

            this.configuracion = configuracion[0];

            this.color$ = this._colorService.obtenerColores().subscribe(
              colores => {

                this.colores = colores;

                this.filtros$ = this._generalService.obtenerOpcionesFiltrosReporteModulo().subscribe(
                  response => {
                    console.log('opciones filtro', response)

                    this.opcionesFiltro = response;

                    this.unidadesMedida$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                      unidades => {
                        this.unidadesMedida = unidades;

                        this.modelos$ = this._modeloService.obtenerModelos().subscribe(
                          modelos => {
                            this.modelos = modelos;

                            this.seleccionarReporte({ id: 'ALMACEN_MM' })
                            this.loaderFields = false;
                            this.loading = false;
                          },
                          error => {
                            this._snackBarService.showError(error.error.msg, 'Ok');
                            this.loaderFields = false;
                            this.loading = false;
                          }
                        )

                      },
                      error => {
                        this._snackBarService.showError(error.error.msg, 'Ok');
                        this.loaderFields = false;
                        this.loading = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok');
                    this.loaderFields = false;
                    this.loading = false;
                  }
                )

              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderFields = false;
                this.loading = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderFields = false;
            this.loading = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderFields = false;
        this.loading = false;
      }
    )

  }

  seleccionarFamilia(): void {
    this._subfamiliaService.obtenerSubFamilias(this.filtroAlmacen.familias).subscribe(
      response => {
        this.subfamilias = response;

        console.log('subfamilias', this.subfamilias)
      },
      error => this._snackBarService.showError(error.error.message, 'OK')
    )
  }

  seleccionarReporte(event): void {

    this.filtroAlmacen = new FiltroReporteAlmacen();

    this.reporteSeleccionado[0] = event.id;

    switch (this.reporteSeleccionado[0]) {
      case "ALMACEN_MM":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16002");
        break;
      case "ALMACEN_KM":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16002");
        break;
      case "ALMACEN_KA":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16002");
        break;
      case "ALMACEN_SP":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16001");
        break;
      case "ALMACEN_SL":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16005");
        break;
      case "ALMACEN_KL":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16006");
        break;
      case "ALMACEN_TPL":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16006");
        break;
      case "ALMACEN_RPC":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16002");
        break;
      default:
        break;
    }

    console.log('opcionFiltro', this.opcionFiltro)
  }

  editarMovimiento(year, month, almacen, tipo, numeroParte) {

    if (this.tipoMovimiento == "ING") {
      this._router.navigate(['/modulo-almacen/editar-parte-de-ingreso', year, month, almacen, tipo, numeroParte])
      return;
    }

    this._router.navigate(['/modulo-almacen/editar-parte-de-salida', year, month, almacen, tipo, numeroParte])

  }

  verPdf(mac_nummac): void { }

  nuevoMovimiento(): void {
    if (this.tipoMovimiento == "ING") {
      this._router.navigate(['/parte-de-ingreso']);
      return;
    }

    this._router.navigate(['/parte-de-salida']);

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  verReporte(): void {

    this.loading = true;

    let headers = {
      codano: this.year,
      codmes: this.month,
      currency: this.filtroAlmacen.moneda
    };

    let otherData: OtherDataFiltroAlmacen = {
      params: [],
      filtro: this.filtroAlmacen,
    };

    console.log('join', this.filtroAlmacen.lineas.join(','));

    if (this.filtroAlmacen.lineas.join(',')) {
      otherData.params.push({
        param: 'linea',
        value: this.filtroAlmacen.lineas.join(',')
      })
    }

    if (this.filtroAlmacen.tecnologias.join(',')) {
      otherData.params.push({
        param: 'modelo',
        value: this.filtroAlmacen.tecnologias.join(',')
      })
    }
    console.log('Reporte seleccionado', this.reporteSeleccionado)
    if(this.reporteSeleccionado[0] == "ALMACEN_TPL") {
      if (this.filtroAlmacen.lote) {
        otherData.params.push({
          param: 'loteseccion',
          value: this.filtroAlmacen.lote
        })
      }

    } else {
      if (this.filtroAlmacen.lote) {
        otherData.params.push({
          param: 'lote',
          value: this.filtroAlmacen.lote
        })
      }
    }

    if (this.filtroAlmacen.familias.join(',')) {
      otherData.params.push({
        param: 'familia',
        value: this.filtroAlmacen.familias.join(',')
      })
    }

    if (this.filtroAlmacen.subFamilias.join(',')) {
      otherData.params.push({
        param: 'subfamilia',
        value: this.filtroAlmacen.subFamilias.join(',')
      })
    }

    if (this.filtroAlmacen.almacenes.join(',')) {
      otherData.params.push({
        param: 'almacen',
        value: this.filtroAlmacen.almacenes.join(',')
      })
    }

    if (this.filtroAlmacen.tiposInventario.join(',')) {
      otherData.params.push({
        param: 'tipoinventario',
        value: this.filtroAlmacen.tiposInventario.join(',')
      })
    }

    if (this.filtroAlmacen.unidadesMedida.join(',')) {
      otherData.params.push({
        param: 'unidadmedida',
        value: this.filtroAlmacen.unidadesMedida.join(',')
      })
    }

    if (this.filtroAlmacen.motivos.join(',')) {
      otherData.params.push({
        param: 'motivo',
        value: this.filtroAlmacen.motivos.join(',')
      })
    }

    if (this.filtroAlmacen.colores.join(',')) {
      otherData.params.push({
        param: 'categoria',
        value: this.filtroAlmacen.colores.join(',')
      })
    }

    if (this.filtroAlmacen.tiposDocumento.join(',')) {
      otherData.params.push({
        param: 'tipodocumento',
        value: this.filtroAlmacen.tiposDocumento.join(',')
      })
    }

    if (this.filtroAlmacen.productos.join(',')) {
      otherData.params.push({
        param: 'producto',
        value: this.filtroAlmacen.productos.join(',')
      })
    }

    if(this.filtroAlmacen.marcas.join(',')) {
      otherData.params.push({
        param: 'marca',
        value: this.filtroAlmacen.marcas.join(',')
      })
    }

    if(this.establecimiento != '' && this.establecimiento) {
      otherData.params.push({
        param: 'tienda',
        value: this.establecimiento
      })
    }

    this._almacenReporteService.verReporte(this.reporteSeleccionado[0], headers, otherData)
      .then(_ => this.loading = false)
      .catch(_ => this.loading = false)
      .finally(() => this.loading = false);

  }

  verListadoGuiaPDF(): void {
    console.log('guia pdf')
    pdfBuilder.listadoGuia({}, []);

  }

  verListadoGuiaDetalladoPDF(): void {
    pdfBuilder.listadoGuiaDetallado({}, []);
  }

  verDetalle(): void {

    this._almacenService.obtenerInformeKardexLote(this.year, this.month, this.filtroAlmacen).subscribe(
      data => {
        console.log('data', data)
        pdfBuilder.generarReporteResumenProductoPorClasificados({}, []);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.movimiento$,
      this.columnas$,
      this.loading$,
      this.periodo$,
      this.dataForm$,
      this.filtros$,
      this.unidadesMedida$,
      this.modelos$,
    ])
  }
}
