import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'conductor-form',
  templateUrl: './conductor-form.component.html',
  styleUrls: ['./conductor-form.component.scss']
})
export class ConductorAlmacenFormComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

}
