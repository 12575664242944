import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { AlmacenService, AuthenticationService, VentasService } from 'src/app/services';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';

@Component({
  selector: 'app-almacen-list',
  templateUrl: './almacen-list.component.html',
  styleUrls: ['./almacen-list.component.css']
})
export class AlmacenListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'alm_codalm', 'alm_descri'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsMotivo: string[] = ['inventario', 'cuenta_debe', 'cuenta_haber'];
  dataSourceMotivo: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginatorMotivo: MatPaginator;
  @ViewChild(MatSort) sortMotivo: MatSort;

  loaderMotivo: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  almacenes: any[] = [];
  tiposDocumento: any[] = [];
  motivos: any[] = [];

  loaderData: boolean = false;

  almacen$: Subscription;
  tipodoc$: Subscription;
  motivo$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _ventasService: VentasService,
    private _almacenService: AlmacenService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceMotivo = fillTable([], this.paginatorMotivo, this.sort);

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.almacen$ = this._almacenService.obtenerAlamacenes().subscribe(
      almacenes => {
        this.almacenes = almacenes;

        this.tipodoc$ = this._ventasService.listarTipoDocumentosVenta().subscribe(
          tiposDocumento => {
            this.tiposDocumento = tiposDocumento;

            this.motivo$ = this._almacenService.obtenerMotivos().subscribe(
              motivos => {
                this.motivos = motivos;
                this.obtenerAlmacenes();
                this.loaderData = false;
              },
              error => {
                this._snackBarService.showError(error.error.msg, "OK")
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, "OK")
            this.loaderData = false;
          }
        )
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK")
        this.loaderData = false;

      }
    )

  }

  obtenerAlmacenes(): void {
    this.almacen$ = this._almacenService.obtenerAlamacenes().subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.almacen$,
      this.tipodoc$,
      this.motivo$,
      this.loading$
    ])

  }

}
