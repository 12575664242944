import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Auth } from '../../models/auth';
import { AuthenticationService } from '../../services/authentication.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { LoadingDialog } from '@shared/components/dialogs/loading/loading.component';
import { environment } from '../../../environments/environment';
import { MaestrosLS } from 'src/app/models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  hide = true;
  hideChange = true;
  hideChangeConfirm = true;
  mode = new FormControl('over');
  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  passwordChange: string;
  passwordChangeConfirm: string;

  codigo: string;
  email: string;
  password: string;

  isNatural = true;
  messageError = '';
  messageButton = 'Ingresar';

  user: Auth;

  recuerdame: boolean = false;
  modo: string = '01';

  auth$: Subscription;

  loading: boolean = false;

  language: string = 'es';
  esProduccion = environment.production;

  constructor(
    private _auth: AuthenticationService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
  ) {


    this.user = new Auth('', '', '');
  }

  ngOnInit(): void {
    this.establecerModo();
  }

  acceder(f: NgForm): void {
    this.loading = true;
    this.messageButton = 'Verificando';
    this.user.cia = this.modo;

    this.auth$ = this._auth.login(this.user).subscribe(
      response => {
        this.loading = false;

        // (response.usuariopav == 1)? this._router.navigate(['/dashboard']): this._router.navigate(['/dashboard']);

        this.messageButton = 'Ingresar';
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', JSON.stringify(response));
        localStorage.setItem('active-tutorial', 'true');
        this._configurationService.establecerTema(false);
        this._configurationService.establecerModo(this.modo);
        this._configurationService.establecerIdioma('ES');

        // Crear la instancia de maestros en local storage
        const maestros = new MaestrosLS();
        this._configurationService.establecerMaestrosLS(maestros);

        this._dialogService.openDialogLoading(LoadingDialog, 'Iniciando Sesión', '100%', '100vh')

      },
      error => {
        this.loading = false;
        this.messageButton = 'Ingresar';
        this._snackBarService.showError('Credenciales incorrectas', 'OK');
        // this._snackBarService.showError(error.error.msg, 'OK');
      }
    );
  }

  cambiarModo(): void {
    (this.modo == '99')? this.modo = '01': this.modo = '99';
  }

  establecerModo(): void {
    this._configurationService.establecerModo('99');
  }

  seleccionarCompania(valor): void {
    this.modo = valor;
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.auth$
    ])
  }

}
