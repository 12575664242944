<app-pavso-title-section [title]="'Transferencias y/o retiros - financieros'" [module]="'Activo fijo'" [estado]="'1'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
    <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Número</mat-label>
                    <input type="text" matInput placeholder="Número" name="numero"required>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Fecha</mat-label>
                    <input matInput [matDatepicker]="fechahoy">
                    <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
                    <mat-datepicker #fechahoy></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Tipo</mat-label>
                    <mat-select required name="estado">
                        <mat-option *ngFor="let tipo of tiposActivo" [value]="tipo.codigo_tipoacti">
                            {{tipo.descri_tipoacti}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </mat-card>
    <br>
    <div class="mat-elevation-z0">

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="activo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Activo </th>
                <td mat-cell *matCellDef="let row" data-label="Activo"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="act_soles">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Act. S/. </th>
                <td mat-cell *matCellDef="let row" data-label="Act. S/."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="act_us">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Act. US$. </th>
                <td mat-cell *matCellDef="let row" data-label="Act. US$."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="dpc_soles">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dpc. S/. </th>
                <td mat-cell *matCellDef="let row" data-label="Dpc. S/."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="dpc_us">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dpc. US$. </th>
                <td mat-cell *matCellDef="let row" data-label="Dpc. US$."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="act_ajust">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Act. Ajust. </th>
                <td mat-cell *matCellDef="let row" data-label="Act. Ajust."> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="dpc_ajust">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dpc. Ajust. </th>
                <td mat-cell *matCellDef="let row" data-label="Dpc. Ajust."> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
