import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  title: string;
  buttonName: string;

  constructor(
    public location: Location,
    private _configurationService: ConfigurationService
  ) {
    const idioma = this._configurationService.obtenerIdioma();

    if(idioma == 'ES') {
      this.title = 'Página no encontrada en PAVSO ERP';
      this.buttonName = 'VOLVER A LA PÁGINA ANTERIOR'
      return;
    }
    this.title = 'Page not found in PAVSO ERP';
    this.buttonName = 'RETURN TO THE PREVIOUS PAGE'
  }

  ngOnInit(): void {}


  volver(): void {
    this.location.back();
  }

}
