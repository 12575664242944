<app-pavso-title-section [title]="'ALMACÉN'" [module]="'CREAR ALMACÉN'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarAlmacen(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">
          <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                  <mat-label>Código</mat-label>
                  <input matInput placeholder="Código" [(ngModel)] = "storehouse.ALM_CODALM" name="ALM_CODALM" [disabled]="loaderData">
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                  <mat-label>Descripción</mat-label>
                  <input matInput placeholder="Descripción" [(ngModel)] = "storehouse.ALM_DESCRI" name="ALM_DESCRI" [disabled]="loaderData">
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                  <mat-label>Descri. Abrevi.</mat-label>
                  <input matInput placeholder="Descri. Abrevi." [(ngModel)] = "storehouse.ALM_DESABR" name="ALM_DESABR" [disabled]="loaderData">
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>Establecimiento</mat-label>
                  <mat-select [(ngModel)] = "storehouse.LDC_CODLDC" name="LDC_CODLDC" [disabled]="loaderData">
                      <mat-option *ngFor="let establecimiento of establecimientos" [value]="establecimiento.LDC_CODLDC">
                          {{establecimiento.LDC_NOMLDC}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <app-state-select [state]="storehouse.ALM_INDSTA" (cambioEstado)="storehouse.ALM_INDSTA = $event"></app-state-select>
          </div>
          <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>Tipo de almacenes</mat-label>
                  <mat-select [(ngModel)] = "storehouse.TLA_CODTLA" name="TLA_CODTLA" [disabled]="loaderData">
                      <mat-option *ngFor="let tipo of tiposAlmacen" [value]="tipo.TLA_CODTLA">
                          {{tipo.TLA_DESCRI}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
              <section class="example-section">
                  <mat-checkbox class="example-margin" [(ngModel)] = "storehouse.ALM_INDSTK" [disabled]="loaderData" name="ALM_INDSTK">Es un almacén de tránsito</mat-checkbox><br>
                  <mat-checkbox class="example-margin" [(ngModel)] = "storehouse.ALM_INDTRA" [disabled]="loaderData" name="ALM_INDTRA">Controlar stock</mat-checkbox><br>
                  <mat-checkbox class="example-margin" [(ngModel)] = "storehouse.ALM_INDVAL" [disabled]="loaderData" name="ALM_INDVAL">No incluir en valorización</mat-checkbox>
              </section>
          </div>
          <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>Sucursal</mat-label>
                  <mat-select [(ngModel)] = "storehouse.SUC_CODSUC" name="SUC_CODSUC" [disabled]="loaderData">
                      <mat-option *ngFor="let sucursal of sucursales" [value]="sucursal.SUC_CODSUC">
                          {{sucursal.suc_nomsuc}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-checkbox class="example-margin" [(ngModel)] = "storehouse.ALM_INDSTK" [disabled]="loaderData" name="ALM_INDSTK">Es un almacén de almacén de móvil (Unidad de transporte)</mat-checkbox><br>

          </div>
          <div class="col s12 m12 l3">
            <select-search #sTipoDocRef01 [label]="'Unidad de Trans.'" [placeholder]="'Ej. FACTURA'" [name]="'TDO_CODTDO'"
              [value]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'TDO_DESLAR'" [data]="[]"
              ></select-search>
          </div>
      </div>
    </mat-card>
  </div>

  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-almacen/almacenes">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
