export class UnidadMedida {
  ume_codume: string;
  ume_descri: string;
  ume_desabr: string;
  ume_indsta: string;
  ume_coduse: string;
  ume_fecupd: Date;
  ume_codant: string;
  ume_codofi: string;
  ume_indtie: number;

  constructor() {
    this.ume_indsta = "1";
    this.ume_fecupd = new Date();
  }
}
