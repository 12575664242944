<div>
  <div class="pav-success-icon">
    <div class="circle">
      <div class="circle-inside">
        <mat-icon svgIcon="check"></mat-icon>
      </div>
    </div>
  </div>

  <h2 class="pav-success-title">{{'general.dialog.success.title' | translate}}</h2>

  <div mat-dialog-content>

    <div class="pav-success-message">
      {{message | translate}}
    </div>

  </div>
  <div class="pav-success-actions">
    <button class="pav-button-ok" mat-flat-button mat-dialog-close="true"> OK </button>
  </div>

</div>
