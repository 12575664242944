import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FiltroAddLoteMasivo } from "../../models/filtro-add-lote-masivo";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { KardexService } from "src/app/services/api/almacen/operaciones/kardex.service";
import { SnackBarService } from "@shared/services/snackbar.service";

class AddLotesFields {
  periodo: string;
  almacen: string;
  producto: string;
  referencia: string;
  lotes: Array<string>;

  constructor() {}
}

@Component({
    selector: 'add-lotes-dialog',
    templateUrl: './add-lotes.dialog.html',
    styleUrls: ['./add-lotes.dialog.scss']
})
export class AddLotesDialog implements OnInit, OnDestroy {

  loaderData: boolean;
  periodos: any[] = [];
  lotes: any[] = [];
  fields: AddLotesFields;
  filtro: FiltroAddLoteMasivo;

  period$: Subscription;
  lotes$: Subscription;

  lote: any = {
    name: 'Lotes',
    completed: false,
    color: 'primary',
    lotes: [],
  };

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.lote.lotes != null && this.lote.lotes.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.lote.lotes == null) {
      return false;
    }
    return this.lote.lotes.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.lote.lotes == null) {
      return;
    }
    this.lote.lotes.forEach(t => (t.completed = completed));
  }

  constructor(
    public dialogRef: MatDialogRef<AddLotesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly store: Store<PavsoState>,
    private readonly _kardexService: KardexService,
    private readonly _snackBarService: SnackBarService
  ) {
    this.fields = new AddLotesFields();
    this.fields.almacen = data.almacen;
    this.fields.producto = data.producto;
    this.filtro = new FiltroAddLoteMasivo();


  }

  ngOnInit(): void {
    this.period$ = this.store.select('period').subscribe(period => {
      console.log('period.periodos', period.periodos)
      this.periodos = period.periodos;
      this.filtro.anioPeriodo = period.year;
      this.filtro.mesPeriodo = period.month;
    })

    const periodos = [];

    this.periodos.forEach(item => {
      periodos.push({
        ANO_CODANO: item.ANO_CODANO,
        MES_CODMES: item.MES_CODMES,
        description: item.ANO_CODANO + ' - ' + item.MES_CODMES
      })
    })

    this.periodos = periodos;
    const periodo = this.periodos.find(item => item.ANO_CODANO == this.filtro.anioPeriodo && item.MES_CODMES == this.filtro.mesPeriodo)
    this.fields.periodo = periodo.ANO_CODANO + ' - ' + periodo.MES_CODMES;

    this.obtenerLotes();
  }

  seleccionarPeriodo(): void {
    const parts = this.fields.periodo.split(' - ');
    this.filtro.anioPeriodo = parts[0];
    this.filtro.mesPeriodo = parts[1];


  }

  obtenerLotes(): void {
    this.loaderData = true;

    if(!this.fields.almacen) {
      this._snackBarService.showError('Debe seleccionar un almacen', 'Ok');
      this.loaderData = false;
      return;
    }

    if(!this.fields.producto) {

      this._snackBarService.showError('Debe seleccionar un producto', 'Ok');
      this.loaderData = false;
      return;
    }

    this.lotes$ = this._kardexService.consultaStockPorLote('01', this.filtro.anioPeriodo, this.filtro.mesPeriodo, this.fields.almacen, this.fields.producto).subscribe(
      lotes => {
        console.log('lotes encontrados', lotes)
        this.lotes = lotes;
        this.lote.lotes = lotes;

        if(this.lotes.length > 0) {
          this.setAll(true);
        }

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al listar lotes', 'Ok');
        this.loaderData = false;
      }
    )
  }

  establecerLotes(): void {
    this.dialogRef.close(this.lote.lotes);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }

}
