export const REPORTES_PLANILLA_DEPENDIENTES = [
  {
    id: "PLANILLA_DRES",
    name: "Dependientes por rango de edades / sexo",
    esDinamico: false,
  },
  {
    id: "PLANILLA_DC",
    name: "Dependientes del colaborador",
    esDinamico: false,
  },
];
export const REPORTES_PLANILLA_GESTION = [
  {
    id: "PLANILLA_CV",
    name: "Control vacacional",
    esDinamico: false,
  },
  {
    id: "PLANILLA_CVF",
    name: "Control vacacional - físico",
    esDinamico: false,
  },
  {
    id: "PLANILLA_DEL",
    name: "Días efectivos laborados",
    esDinamico: false,
  },
  {
    id: "PLANILLA_ACC",
    name: "Acumulados de conceptos por colaborador",
    esDinamico: false,
  },
  {
    id: "PLANILLA_ACCol",
    name: "Acumulados de colaborador por concepto",
    esDinamico: false,
  },
  {
    id: "PLANILLA_ACCA",
    name: "Acumulados de conceptos por colaborador anual",
    esDinamico: false,
  },
  {
    id: "PLANILLA_ACG",
    name: "Acumulados de conceptos por grupo",
    esDinamico: false,
  },
  {
    id: "PLANILLA_C",
    name: "Listado de contratos",
    esDinamico: false,
  },
  {
    id: "PLANILLA_TDL",
    name: "Documentos de legajo",
    esDinamico: false,
  },
];
export const REPORTES_PLANILLA_MENSUALES = [
  {
    id: "PLANILLA_PRA",
    name: "Planilla de remuneraciones por AFP",
    esDinamico: false,
  },
  {
    id: "PLANILLA_PL",
    name: "Planilla lineal",
    esDinamico: false,
  },
  {
    id: "PLANILLA_PCAA",
    name: "Planilla de conceptos afecto AFP",
    esDinamico: false,
  },
  {
    id: "PLANILLA_RP",
    name: "Resumen de planilla",
    esDinamico: false,
  },
  {
    id: "PLANILLA_RPL",
    name: "Resumen de planilla liquidados",
    esDinamico: false,
  },
];
export const REPORTES_PLANILLA_NOMINA = [
  {
    id: "PLANILLA_BP",
    name: "Boleta de pago",
    esDinamico: false,
  },
  {
    id: "PLANILLA_NP",
    name: "Neto a pagar",
    esDinamico: false,
  },
  {
    id: "PLANILLA_PLN",
    name: "Planilla lineal por nómina",
    esDinamico: false,
  },
  {
    id: "PLANILLA_PRC",
    name: "Planilla resumen por concepto",
    esDinamico: false,
  },
  {
    id: "PLANILLA_PC",
    name: "Planilla calculada",
    esDinamico: false,
  },
  {
    id: "PLANILLA_GP",
    name: "Gratificación a pagar",
    esDinamico: false,
  },
  {
    id: "PLANILLA_RRC",
    name: "Reviso renta calculada",
    esDinamico: false,
  },
  {
    id: "PLANILLA_RC",
    name: "Remuneraciones del colaborador",
    esDinamico: false,
  },
];
export const REPORTES_PLANILLA_REMUNERACION = [
  {
    id: "PLANILLA_HRC",
    name: "Historial de remuneración por colaborador",
    esDinamico: false,
  },
  {
    id: "PLANILLA_CRP",
    name: "Cambios de remuneración por período",
    esDinamico: false,
  },
  {
    id: "PLANILLA_CBC",
    name: "Cuentas de banco por colaborador",
    esDinamico: false,
  },
];

export const REPORTES_PLANILLA_INTERFACE_CONTABLE = [
  {
    id: "PLANILLA_VTAP",
    name: "Validación por trabajador para asiento planilla",
    esDinamico: false,
  },
  {
    id: "PLANILLA_VPV",
    name: "Validación de provisión de vacaciones",
    esDinamico: false,
  },
  {
    id: "PLANILLA_VPC",
    name: "Validación de provisión de CTS",
    esDinamico: false,
  },
  {
    id: "PLANILLA_VPG",
    name: "Validación de provisión de gratificación",
    esDinamico: false,
  },
  // {
  //   id: "PLANILLA_APGCV",
  //   name: "Acumulado de provisiones grat. - CTS - vacac."
  // },
];

export const REPORTES_PLANILLA_DINAMICOS = [
  {
    id: "PLANILLA_MC",
    name: "Maestro de colaboradores",
    esDinamico: true,
  },
  {
    id: "PLANILLA_PM",
    name: "Planilla mensual",
    esDinamico: true,
  },
  {
    id: "PLANILLA_PS",
    name: "Planilla secuencia",
    esDinamico: true,
  },
  {
    id: "PLANILLA_HP",
    name: "Historial de provisiones",
    esDinamico: true,
  },
]

export function retornarCódigoFiltroInformeNomina(reporteSeleccionado: string): string {
  switch (reporteSeleccionado) {
    case "PLANILLA_BP":
      return "51002"
    case "PLANILLA_PC":
      return "51004"
    case "PLANILLA_NP":
      return "51002"
    case "PLANILLA_RRC":
      return "51004"
    case "PLANILLA_RC":
      return "51001"
    case "PLANILLA_PLN":
      return "51001"
    case "PLANILLA_PRC":
      return "51003"
    case "PLANILLA_GP":
      return "51004"
    default:
      return "";
  }
}
