<app-pavso-title-section [title]="'Aprobación de requerimiento de pago'" [module]="'Compras'"
  [ngClass]="'pav-form'"></app-pavso-title-section>
<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

<div class="pavso-content-form fade-in-image">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l12 xl4">

        <select-search [label]="'Proveedor'" [placeholder]="'Ej. SOFIA'" [name]="'cli_codcli'" [value]="'cli_codcli'"
          [description]="'cli_nomcli'" [data]="proveedores" [model]="filtro.proveedor" (cambioSelect)="filtro.proveedor = $event"></select-search>

      </div>
      <div class="col s12 m12 l12 xl2">
        <mat-radio-group [(ngModel)]="filtro.tipoFecha" name="tipoFecha">
          <mat-radio-button [value]="'FE'" [ngStyle]="{fontSize: '14px'}">Fecha de emisión</mat-radio-button>

          <mat-radio-button [ngStyle]="{marginTop: '5px', fontSize: '14px'}" [value]="'FP'">Fecha de pago</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="col s12 m12 l12 xl3">
        <mat-form-field>
          <mat-label>Desde</mat-label>
          <input [(ngModel)]="filtro.desde" name="desde" matInput [matDatepicker]="pickerDesde">
          <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
          <mat-datepicker #pickerDesde></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l12 xl3">
        <mat-form-field>
          <mat-label>Hasta</mat-label>
          <input [(ngModel)]="filtro.hasta" name="hasta" matInput [matDatepicker]="pickerHasta">
          <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
          <mat-datepicker #pickerHasta></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col s12 m12 l12 xl3">
        <mat-radio-group [(ngModel)]="filtro.estaAprobado" name="estado">
          <mat-radio-button [value]="false">Pendiente</mat-radio-button>
          &nbsp;
          <mat-radio-button [value]="true">Aprobado</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col s12 m12 l12 xl4">
        <mat-checkbox class="example-margin">Fecha de pago hasta hoy</mat-checkbox>

      </div>

    </div>
    <div class="row" [ngStyle]="{marginTop: isTablet || isMobile? '1px': '0px'}">
      <div class="col s12" align="center">
        <button mat-mini-fab color="primary" type="button" (click)="buscar()" aria-label="Example icon button with a menu icon">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
  <br>
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <!-- <div class="col s12 m12 l12 xl2">
        <mat-form-field>
          <mat-label>Nro. Docum</mat-label>
          <input matInput placeholder="Nro. Docum">
        </mat-form-field>
      </div> -->
      <div class="col s12 m12 l12 xl4" [ngStyle]="{marginTop: '5px'}">
        <button mat-stroked-button [ngStyle]="{background: 'rgb(99, 201, 154)', width: isTablet || isMobile ? '100%': 'auto'}">Aprobar</button>
        &nbsp;
        <button mat-stroked-button [ngStyle]="{background: 'rgb(247, 201, 27)', width: isTablet || isMobile ? '100%': 'auto'}">Pendiente</button>
        &nbsp;
        <button mat-stroked-button [ngStyle]="{background: 'rgb(213, 139, 119)', width: isTablet || isMobile ? '100%': 'auto', marginBottom: isTablet || isMobile ? '12px': '0px'}">Rechazar</button>
      </div>
      <div class="col s12 m12 l12 xl2">
        <!-- <mat-form-field>
          <mat-label>Nro. Docum</mat-label>
          <input matInput placeholder="Nro. Docum">
        </mat-form-field> -->
      </div>
      <div class="col s12 m12 l12 xl2">
        <mat-form-field>
          <mat-label>Total M.N.</mat-label>
          <input type="text" [value]="totalMN" name="totalMN" min="0" matInput placeholder="Total M.N." [ngStyle]="{textAlign: 'right'}" readonly>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l12 xl2">
        <mat-form-field>
          <mat-label>Total M.E.</mat-label>
          <input type="text" [value]="totalME" name="totalME" min="0" matInput placeholder="Total M.E." [ngStyle]="{textAlign: 'right'}" readonly>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <div class="mat-elevation-z0">
    <div class="pav-table-header-container">
      <div class="pav-table-header-icon">

      </div>
      <div class="pav-table-header-title">
        Requerimientos
      </div>
      <div class="pav-separator"></div>
      <div class="pav-table-search" [ngStyle]="{width: isTablet || isMobile ? '120px': 'auto'}">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
      </div>
    </div>
    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones" class="td-responsive">
            <button matTooltip="Ver orden de compra" (click)="mostrarPdf(row.ocm_numocm)" mat-icon-button color="warn">
              <mat-icon>picture_as_pdf</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="num_requerimiento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. requerimiento </th>
          <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ano_codano}} </td>
        </ng-container>

        <ng-container matColumnDef="fec_emision">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Emisión </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.mes_codmes}} </td>
        </ng-container>

        <ng-container matColumnDef="fec_pago">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Pago </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.ocm_numocm}} </td>
        </ng-container>

        <ng-container matColumnDef="ruc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> RUC </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.ocm_fecocm | date:
            'dd/MM/yyyy': 'UTC'}} </td>
        </ng-container>

        <ng-container matColumnDef="cliente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.ocm_fecupd | date:
            'dd/MM/yyyy': 'UTC'}} </td>
        </ng-container>

        <ng-container matColumnDef="solicitado_por">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitado por </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cli_codcli}} </td>
        </ng-container>

        <ng-container matColumnDef="proveedor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.cliente.cli_nomcli}}
          </td>
        </ng-container>

        <ng-container matColumnDef="moneda">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.clienref.cli_nomcli}}
          </td>
        </ng-container>

        <ng-container matColumnDef="importe">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
            <div [ngStyle]="{textAlign: 'center'}">
              {{row.tmo_codtmo}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ord_compra">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ord. compra </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
            <div [ngStyle]="{width: '60px', textAlign: 'right'}">
              {{row.importe}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="req_compra">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Req. Compra </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
            <div [ngStyle]="{width: '60px', textAlign: 'right'}">
              {{row.importe}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ot">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> OT </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
            <div [ngStyle]="{width: '60px', textAlign: 'right'}">
              {{row.importe}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="descripcion_ot">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción OT </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
            <div [ngStyle]="{width: '60px', textAlign: 'right'}">
              {{row.importe}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="fec_aprobacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Aprobación </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
            <div [ngStyle]="{width: '60px', textAlign: 'right'}">
              {{row.importe}}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">No se encontraron registros "{{input.value}}"</td>
        </tr>

      </table>
    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
  <br>
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l4">
        <mat-form-field class="example-full-width">
          <mat-label>Consulta Saldos Bancos con Saldo Diario</mat-label>
          <textarea matInput placeholder="Eg. ..."></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-card>

</div>

<pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
  (clickMasOpciones)="verMasOpciones()">
  <div id="botonesAdicionales">

  </div>
</pav-form-actions>
