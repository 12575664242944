export class CondicionPago {
  CPA_CODCPA: string;
  CPA_DESCRI: string;
  CPA_DESABR: string;
  CPA_DIAPLA: number;
  CPA_INDVTA: number;
  CPA_INDCOM: number;
  CPA_INDCUO: number;
  CPA_APRPED: number;
  GCP_CODGCP: string;
  CPA_INDCRE: number;
  cpa_indcan: number;
  CPA_INDSTA: string;
  CPA_FECUPD: Date;
  CPA_CODUSE: string;
  CPA_NROCUO: number;
  cpa_indper: string;

  constructor() {
    this.CPA_FECUPD = new Date();
    this.CPA_INDSTA = '1';
  }

}
