import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExportExcelService } from 'src/app/services';
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from 'src/app/utils/formats/date.format';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { SnackBarService } from '@shared/services/snackbar.service';
import { VentasService } from 'src/app/services/api/ventas.service';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { REPORTES_VENTAS } from '@data/json/reportes';
import { Subject, Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { FiltroReporteVentas } from 'src/app/models/ventas/filtro-reporte-ventas';
import { VentasReportService } from 'src/app/services/reports/modules/ventas-report.service';

@Component({
  selector: 'app-pdfs-ventas',
  templateUrl: './pdfs.component.html',
  styleUrls: ['./pdfs.component.css']
})
export class PdfsVentasComponent implements OnInit, OnDestroy {

  public tiendas: any[] = [];

  public clientes: any[] = [];

  public series: any[] = [];

  public motivos: any[] = [];

  public vendedores: any[] = [];

  public productos: any[] = [];

  public condiciones: any[] = [];

  public inventarios: any[] = [];

  public documentos: any[] = [];

  dataForExcel = [];

  dataDetalleFacturacion = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  range: FormGroup;

  lineas: any[] = [];
  marcas: any[] = [];
  monedas: any[] = [];

  year: string;
  month: string;

  detalleRegistroVentas: any[] = [];

  reporteSeleccionado: any = ['VENTAS_CC'];

  reportes: any[] = REPORTES_VENTAS;

  loading: boolean = false;

  columna$: Subscription;
  period$: Subscription;
  loading$: Subscription;

  filtro: FiltroReporteVentas;

  protected _onDestroy = new Subject<void>();

  constructor(
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService,
    private _ventasReporteService: VentasReportService,
    private store: Store<PavsoState>,
    public ete: ExportExcelService
  ) {

    this.filtro = new FiltroReporteVentas();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
      this.filtro.periodos = [{periodo: `${this.year}/${this.month}`}, {periodo: ''}, {periodo: ''} , {periodo: ''}]
    })

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loading = true;

    this._ventasService.obtenerDataFiltroVentas().subscribe(
      dataFiltro => {
        this.tiendas = dataFiltro.tiendas;

        this.clientes = dataFiltro.clientes;
        console.log('clientes', this.clientes)

        this.series = dataFiltro.series;

        this.motivos = dataFiltro.motivos;

        this.vendedores = dataFiltro.vendedores;

        this.productos = dataFiltro.productos;

        this.condiciones = dataFiltro.condiciones;

        this.inventarios = dataFiltro.tiposInventario;

        this.lineas = dataFiltro.lineas;

        this.documentos = dataFiltro.documentos;

        this.marcas = dataFiltro.marcas;

        this.monedas = dataFiltro.monedas;

        this.loading = false;

      },
      error => {
        this.loading = false;
        this._snackBarService.showError('Error al obtener data filtro', 'Ok');
      }
    )

  }

  seleccionarReporte(event): void {
    this.reporteSeleccionado[0] = event.id;
    let indicador = "";
    switch (this.reporteSeleccionado[0]) {
      case "VENTAS_P":
        indicador = "51002"
        break;
      case "VENTAS_RV":
        indicador = "51002"
        break;
      case "VENTAS_RDV":
        indicador = "51001"
        break;
      case "VENTAS_CC":
        indicador = "51001"
        break;
      default:
        break;
    }
  }

  verReporte(): void {

    this.loading = true;

    const headers = {
      year: this.year,
      month: this.month,
      now: formatDateWithSlash(new Date()),
      hour: getHourMinuteSecondsCurrent(new Date())
    }

    const otherData = {
      moneda: this.filtro.moneda,
      codano: this.year,
      codmes: this.month,
    }

    this._ventasReporteService.verReporte(this.reporteSeleccionado[0], headers, this.filtro, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.columna$,
      this.period$
    ])
  }
}
