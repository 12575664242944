<app-pavso-title-section [title]="'Consultar Stock'" [module]="'Almacén'"
  [ngClass]="'pav-form'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (keydown.enter)="$event.preventDefault()">
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="">
      <div id="botonesAdicionales">
      </div>
    </pav-form-actions>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l4">
          <select-search [label]="'Producto'" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd'" [value]="'prd_codprd'"
            [description]="'description'" [data]="productos" [model]="filtroConsultaStock.producto"
            (cambioSelect)="filtroConsultaStock.producto = $event">
          </select-search>
        </div>
        <div class="col s12 m12 l4">

          <select-search [label]="'Línea'" [placeholder]="'Ej. LINEA'" [name]="'lpd_codlpd'" [value]="'lpd_codlpd'"
            [description]="'lpd_descri'" [multiple]="true" [data]="lineas">
          </select-search>

        </div>
        <div class="col s12 m12 l4">

          <select-search [label]="'Almacen'" [placeholder]="'Ej. ALMACEN'" [name]="'ALM_CODALM'" [value]="'ALM_CODALM'"
            [description]="'description'" [multiple]="true" [data]="almacenes" [model]="filtroConsultaStock.almacen"
            (cambioSelect)="filtroConsultaStock.almacen = $event">
          </select-search>

        </div>
        <div class="col s12 m12 l4">

          <select-search [label]="'Familia'" [placeholder]="'Ej. FAMILIA'" [name]="'FPR_CODFPR'" [value]="'FPR_CODFPR'"
            [description]="'fpr_descri'" [multiple]="true" [data]="familias">
          </select-search>

        </div>
        <div class="col s12 m12 l4">

          <select-search [label]="'Tipo inventario'" [placeholder]="'Ej. INVENTARIO'" [name]="'tin_codtin'"
            [value]="'tin_codtin'" [description]="'tin_descri'" [multiple]="true" [data]="tiposInventario" [model]="filtroConsultaStock.tipoInventario"
            (cambioSelect)="filtroConsultaStock.tipoInventario = $event">
          </select-search>

        </div>
        <div class="col s12 m12 l4">

          <select-search [label]="'Marca'" [placeholder]="'Ej. MARCA'" [name]="'MPR_CODMPR'" [value]="'MPR_CODMPR'"
            [description]="'MPR_DESCRI'" [multiple]="true" [data]="marcas">
          </select-search>

        </div>
        <!-- <div class="col s12 m12 l4">
          <select-search [label]="'Marca'" [placeholder]="'Ej. MODELO'" [name]="'UTR_CODUTR'" [value]="'UTR_CODUTR'"
            [description]="'MPR_DESCRI'" [multiple]="true" [data]="[]">
          </select-search>
        </div> -->
        <div class="col s12 m12 l4">
          <select-search [label]="'Unid.'" [placeholder]="'Ej. UNIDAD'" [name]="'MPR_CODMPR'" [value]="'MPR_CODMPR'"
            [description]="'UTR_DESCRI'" [multiple]="true" [data]="unidadesTransporte">
          </select-search>

        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Período</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date" [disabled]="loaderData">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div align="center">
        <button type="button" (click)="buscar()" mat-mini-fab color="primary"
          aria-label="Example icon button with a menu icon">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </mat-card>
    <br>
    <mat-tab-group>
      <mat-tab label="Stock" labelClass="mat-tab-label-first">
        <div class="container-stock">
          <div class="pav-filtro-header">
            <div class="pav-filtro-header-icon"></div>
            <div class="pav-filtro-header-title">Por producto</div>
            <div class="pav-separator"></div>
            <div class="pav-table-search">
              <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
            </div>
          </div>
          <div class="mat-elevation-z0 overflow-x">

            <table mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="prd_codprd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código" [class.mat-form-field-row]="row === productoSeleccionado"> {{row.prd_codprd}} </td>
              </ng-container>

              <ng-container matColumnDef="prd_desesp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === productoSeleccionado"> {{row.prd_desesp}} </td>
              </ng-container>

              <ng-container matColumnDef="MPR_DESCRI">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Marca </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente" [class.mat-form-field-row]="row === productoSeleccionado"> {{row.MPR_DESCRI}} </td>
              </ng-container>

              <ng-container matColumnDef="ume_codume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Und. </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === productoSeleccionado"> {{row.ume_codume}} </td>
              </ng-container>

              <ng-container matColumnDef="spa_cansal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === productoSeleccionado">
                  <div [ngStyle]="{width: '40px', textAlign: 'right', paddingRight: '8px'}">
                    {{row.spa_canfin.toFixed(2)}}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="FPR_DESCRI">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Familia </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === productoSeleccionado"> {{row.FPR_DESCRI}} </td>
              </ng-container>

              <ng-container matColumnDef="TIN_DESCRI">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Inventario </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === productoSeleccionado"> {{row.TIN_DESCRI}} </td>
              </ng-container>

              <ng-container matColumnDef="modelo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Modelo </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === productoSeleccionado"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="part_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Part. Number </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === productoSeleccionado"> {{row.cli_numtlf}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === productoSeleccionado" (click)="consultarDetalle(row)" [ngStyle]="{cursor: 'pointer'}"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="12">No se encontraron registros "{{input.value}}"</td>
              </tr>

            </table>

          </div>
          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
        <div class="container-stock">
          <div class="row">
            <div class="col s12 m12 l12 xl7">
              <div class="pav-filtro-header">
                <div class="pav-filtro-header-icon"></div>
                <div class="pav-filtro-header-title">Por almacén</div>
              </div>
              <div class="mat-elevation-z0 overflow-x">

                <table mat-table [dataSource]="dataSourceDetalleAlmacen" matSort>

                  <ng-container matColumnDef="almacen">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacen </th>
                    <td mat-cell *matCellDef="let row" data-label="Código" [class.mat-form-field-row]="row === almacenSeleccionado"> {{row.alm_codalm}} </td>
                  </ng-container>

                  <ng-container matColumnDef="descripcion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                    <td mat-cell *matCellDef="let row" data-label="Cliente" [class.mat-form-field-row]="row === almacenSeleccionado"> {{row.ALM_DESCRI}} </td>
                  </ng-container>

                  <ng-container matColumnDef="saldo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo </th>
                    <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === almacenSeleccionado">
                      <div [ngStyle]="{width: '40px', textAlign: 'right', paddingRight: '8px'}">
                        {{row.spa_canfin.toFixed(2)}}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="inicial">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Inicial </th>
                    <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === almacenSeleccionado">
                      <div [ngStyle]="{width: '40px', textAlign: 'right', paddingRight: '8px'}">
                        {{row.spa_canini.toFixed(2)}}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ingresos">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ingresos </th>
                    <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === almacenSeleccionado">
                      <div [ngStyle]="{width: '40px', textAlign: 'right', paddingRight: '8px'}">
                        {{row.spa_caning.toFixed(2)}}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="salidas">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Salidas </th>
                    <td mat-cell *matCellDef="let row" data-label="Teléfono" [class.mat-form-field-row]="row === almacenSeleccionado">
                      <div [ngStyle]="{width: '40px', textAlign: 'right', paddingRight: '8px'}">
                        {{row.spa_cansal.toFixed(2)}}
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsDetalleAlmacen"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsDetalleAlmacen;" [class.selected-row]="row === almacenSeleccionado" (click)="consultarDetalleLote(row)" [ngStyle]="{cursor: 'pointer'}"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8">No se encontraron registros</td>

                  </tr>

                </table>

              </div>
              <mat-paginator #paginatorDetalleAlmacen showFirstLastButtons
                [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
            </div>
            <div class="col s12 m12 l12 xl5">
              <div class="pav-filtro-header">
                <div class="pav-filtro-header-icon"></div>
                <div class="pav-filtro-header-title">Por lote</div>
              </div>
              <div class="mat-elevation-z0 overflow-x">

                <table mat-table [dataSource]="dataSourceDetalleLote" matSort>

                  <ng-container matColumnDef="lote">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote </th>
                    <td mat-cell *matCellDef="let row" data-label="Lote"> {{row.spa_numlot}} </td>
                  </ng-container>

                  <ng-container matColumnDef="cantidad">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                    <td mat-cell *matCellDef="let row" data-label="Cantidad">
                      <div [ngStyle]="{width: '40px', textAlign: 'right', paddingRight: '8px'}">
                        {{row.spa_canfin}}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fec_fabricacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. de Registro </th>
                    <td mat-cell *matCellDef="let row" data-label="Fec. de Registro"> {{row.lot_feccre | date: 'dd/MM/YYY':
                      'UTC'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="fec_vencimiento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vencimiento </th>
                    <td mat-cell *matCellDef="let row" data-label="Fec. Vencimiento"> {{row.lot_fecven | date: 'dd/MM/YYY':
                      'UTC'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="lot_glolot">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                    <td mat-cell *matCellDef="let row" data-label="Referencia"> {{row.lot_glolot}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsDetalleLote"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsDetalleLote;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="5">No se encontraron registros</td>

                  </tr>

                </table>

              </div>
              <mat-paginator #paginatorDetalleLote showFirstLastButtons
                [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Kardex"
        [labelClass]="configuracion.s70_indlot == 0 && configuracion.s70_indser == 0 ? 'mat-tab-label-last': 'mat-tab-label-0-1'">
        <div class="container-stock">
          <mat-card class="mat-elevation-z0">
            <div class="row">
              <div class="col s12 m12 l12 xl2">
                <mat-form-field>
                  <mat-label>Código</mat-label>
                  <input matInput placeholder="Código">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l12 xl2">
                <mat-form-field>
                  <mat-label>Inicial</mat-label>
                  <input matInput placeholder="Inicial">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l12 xl2">
                <mat-form-field>
                  <mat-label>Ingresos</mat-label>
                  <input matInput placeholder="Ingresos">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l12 xl2">
                <mat-form-field>
                  <mat-label>Salidas</mat-label>
                  <input matInput placeholder="Salidas">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l12 xl2">
                <mat-form-field>
                  <mat-label>Final</mat-label>
                  <input matInput placeholder="Final">
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="container-stock">
          <div class="row">
            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Tabla</div>
            </div>
            <div class="mat-elevation-z0 overflow-x">

              <table mat-table [dataSource]="dataSourceKardex" matSort>

                <ng-container matColumnDef="tdo_codtdo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Movim. Almacén </th>
                  <td mat-cell *matCellDef="let row" data-label="Movim. Almacén"> {{row.tdo_codtdo}} {{row.mac_nummac}} </td>
                </ng-container>

                <ng-container matColumnDef="alm_descri">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                  <td mat-cell *matCellDef="let row" data-label="Almacén"> {{row.alm_descri}} </td>
                </ng-container>

                <ng-container matColumnDef="mac_fecmac">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                  <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.mac_fecmac | date: 'dd/MM/YYYY': 'UTC'}} </td>
                </ng-container>

                <ng-container matColumnDef="mac_cantra">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                  <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.mac_cantra}} </td>
                </ng-container>

                <ng-container matColumnDef="ume_codume">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Und. </th>
                  <td mat-cell *matCellDef="let row" data-label="Und."> {{row.ume_codume}} </td>
                </ng-container>

                <ng-container matColumnDef="ot">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> OT </th>
                  <td mat-cell *matCellDef="let row" data-label="OT"> {{row.ot}} </td>
                </ng-container>

                <ng-container matColumnDef="mmo_codmmo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
                  <td mat-cell *matCellDef="let row" data-label="Motivo"> {{row.mmo_codmmo}} </td>
                </ng-container>

                <ng-container matColumnDef="mmo_descri">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Motivo </th>
                  <td mat-cell *matCellDef="let row" data-label="Desc. Motivo"> {{row.mmo_descri}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsKardex"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsKardex;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="12" *ngIf="!loaderKardex">No se encontraron registros</td>
                  <td class="mat-cell" colspan="12" *ngIf="loaderKardex">
                    <mat-spinner diameter="40"></mat-spinner>
                  </td>
                </tr>

              </table>

            </div>
            <mat-paginator #paginatorKardex showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Por Lote"
        [labelClass]="configuracion.s70_indlot == 1 && configuracion.s70_indser == 0 ? 'mat-tab-label-last': 'mat-tab-label-0-2'"
        *ngIf="configuracion.s70_indlot == 1">

        <mat-card class="mat-elevation-z0">
          <div class="row">

            <div class="col s12 m12 l4">
              <select-search [label]="'Lote'" [placeholder]="'Ej. 1200011444'" [name]="'lote'"
                [value]="'spl_numlot'" [description]="'spl_numlot'" [data]="lotes" (cambioSelect)="seleccionarLote($event)">
              </select-search>
            </div>



          </div>
        </mat-card>
        <div class="mat-elevation-z0 overflow-x">

          <table mat-table [dataSource]="dataSourceLote" matSort>

            <ng-container matColumnDef="tdo_codtdo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Movim. Almacén </th>
              <td mat-cell *matCellDef="let row" data-label="Movim. Almacén"> {{row.tdo_codtdo}} {{row.mac_nummac}} </td>
            </ng-container>

            <ng-container matColumnDef="spl_numlot">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote </th>
              <td mat-cell *matCellDef="let row" data-label="Lote"> {{row.spl_numlot}} </td>
            </ng-container>

            <ng-container matColumnDef="ALM_DESCRI">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
              <td mat-cell *matCellDef="let row" data-label="Almacén"> {{row.ALM_DESCRI}} </td>
            </ng-container>

            <ng-container matColumnDef="prd_codprd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Producto </th>
              <td mat-cell *matCellDef="let row" data-label="Almacén"> {{row.prd_codprd}} </td>
            </ng-container>

            <ng-container matColumnDef="mac_fecmac">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.mac_fecmac | date: 'dd/MM/YYYY'}} </td>
            </ng-container>

            <ng-container matColumnDef="map_canprd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.map_canprd}} </td>
            </ng-container>

            <ng-container matColumnDef="ume_codume">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Und. </th>
              <td mat-cell *matCellDef="let row" data-label="Und."> {{row.ume_codume}} </td>
            </ng-container>

            <ng-container matColumnDef="mmo_codmmo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
              <td mat-cell *matCellDef="let row" data-label="Motivo"> {{row.mmo_codmmo}} </td>
            </ng-container>

            <ng-container matColumnDef="mmo_descri">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Motivo </th>
              <td mat-cell *matCellDef="let row" data-label="Desc. Motivo"> {{row.mmo_descri}} </td>
            </ng-container>

            <ng-container matColumnDef="cli_nomcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
              <td mat-cell *matCellDef="let row" data-label="OT"> {{row.cli_nomcli}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsLote"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsLote;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="12">No se encontraron registros</td>

            </tr>

          </table>

        </div>
        <mat-paginator #paginatorLote showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </mat-tab>
      <mat-tab label="Por serie"
        [labelClass]="configuracion.s70_indser == 1 && configuracion.s70_indlot == 1 ? 'mat-tab-label-last': 'mat-tab-label-last'"
        *ngIf="configuracion.s70_indser == 1"
        >

        <mat-card class="mat-elevation-z0">
          <div class="row">

            <div class="col s12 m12 l4">
              <select-search [label]="'Producto'" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd'"
                [value]="'prd_codprd'" [description]="'DESC_ESPA'" [data]="productos">
              </select-search>
            </div>

            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Buscar por serie</mat-label>
                <input matInput placeholder="Buscar por serie">
              </mat-form-field>
            </div>

          </div>

          <div>
            <div class="mat-elevation-z0 overflow-x">

              <table mat-table [dataSource]="dataSourceAlmacenSerie" matSort>

                <ng-container matColumnDef="movim_almacen">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Movim. Almacén </th>
                  <td mat-cell *matCellDef="let row" data-label="Movim. Almacén"> {{row.cli_codcli}} </td>
                </ng-container>

                <ng-container matColumnDef="almacen">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                  <td mat-cell *matCellDef="let row" data-label="Almacén"> {{row.cli_nomcli}} </td>
                </ng-container>

                <ng-container matColumnDef="fecha">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emi.</th>
                  <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="doc">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
                  <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="nro_parte">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro parte </th>
                  <td mat-cell *matCellDef="let row" data-label="Und."> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="motivo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
                  <td mat-cell *matCellDef="let row" data-label="Motivo"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="desc_motivo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Motivo </th>
                  <td mat-cell *matCellDef="let row" data-label="Desc. Motivo"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="producto">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                  <td mat-cell *matCellDef="let row" data-label="OT"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="des_producto">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Producto </th>
                  <td mat-cell *matCellDef="let row" data-label="OT"> {{row.cli_numtlf}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsAlmacenSerie"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsAlmacenSerie;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="12">No se encontraron registros</td>

                </tr>

              </table>

            </div>
            <mat-paginator #paginatorAlmacenSerie showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
          <div>
            <div class="mat-elevation-z0 overflow-x">

              <table mat-table [dataSource]="dataSourceSerie" matSort>

                <ng-container matColumnDef="almacen">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                  <td mat-cell *matCellDef="let row" data-label="Almacén"> {{row.cli_nomcli}} </td>
                </ng-container>

                <ng-container matColumnDef="serie">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
                  <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="saldo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo </th>
                  <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_ven">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Ven. </th>
                  <td mat-cell *matCellDef="let row" data-label="Und."> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_fab">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Fab. </th>
                  <td mat-cell *matCellDef="let row" data-label="Motivo"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="serie_madre">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie Madre </th>
                  <td mat-cell *matCellDef="let row" data-label="Desc. Motivo"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="glosa">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                  <td mat-cell *matCellDef="let row" data-label="OT"> {{row.cli_numtlf}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSerie"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSerie;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="12">No se encontraron registros</td>

                </tr>

              </table>

            </div>
            <mat-paginator #paginatorSerie showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

  </form>
</div>
