<app-pavso-title-section [title]="'apps.crm.maestros.actividad.title' | translate" [module]="'CRM'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <pav-table-list
    [data]="actividades"
    [tHeaders]="tHeaders"
    title="Todas las actividades"
    url="/modulo-crm/actividad-crm"
    entity=""
    [pageSize]="10"
    [params]="['0']"
  ></pav-table-list>

</div>
