import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Driver } from 'src/app/models';
import { AuthenticationService, ContabilidadService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { VentasService } from 'src/app/services';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { VendedorService } from 'src/app/services/api/ventas/maestros/vendedor.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { formatDateWithDash } from '@utils/formats/date.format';

class CanjeLetra  {
  cli_codcli: string;
  vde_codvde: string;
  cli_nomcli: string;
  sco_codsco: string;
  caja_indsta: '0' | '1';
  fecha: Date;

  constructor() {
    this.fecha = new Date();
  }
}

@Component({
  selector: 'app-canje-letra-create',
  templateUrl: './canje-letra-create.component.html',
  styleUrls: ['./canje-letra-create.component.css']
})
export class CanjeLetraCreateComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  canjeLetra: CanjeLetra;

  subdiarios: any[] = [];
  clientes: any[] = [];
  vendedores: any[] = [];

  usuario: any;
  fecha: Date;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  loading$: Subscription;
  subdiarios$: Subscription;
  clientes$: Subscription;
  vendedores$: Subscription;
  tipoCambio$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  cliente: any;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _contabilidadService: ContabilidadService,
    private _ventasService: VentasService,
    private _vendedorService: VendedorService,
    private _authService: AuthenticationService,
    private _clienteService: ClienteService,

  ) {

    this.canjeLetra = new CanjeLetra();
    this.usuario = this._authService.getUsuarioSistema();
    this.fecha = new Date();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  /**
   * Obtiene los maestros de :
   * . subdiarios
   * . clientes
   * . vendedores
   */
  loadData(): void {
    this.loaderData = true;

    this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => {
        this.subdiarios = subdiarios;

        this.clientes$ = this._clienteService.obtenerClientes().subscribe(
          clientes => {
            this.clientes = clientes;

            this.vendedores$ = this._vendedorService.listarVendedores().subscribe(
              vendedores => {
                this.vendedores = vendedores;
                this.loaderData = false;
              },
              error => {
                this._snackBarService.showError('Error al obtener vendedores', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener clientes', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
        this.loaderData = false;
      }
    )

  }

  enviarFormulario(f: NgForm): void {

    this.uid == '0' ? this.registrarCanjeLetra(f) : this.actualizarCanjeLetra(f);
  }

  registrarCanjeLetra(f: NgForm): void {

  }

  actualizarCanjeLetra(f: NgForm): void {

  }

  obtenerTipoCambio(): void {
    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.canjeLetra.fecha)).subscribe(
      tipoCambio => {
        console.log('tipoCambio', tipoCambio)
      },
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'Ok');

      }
    )
  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.subdiarios$,
      this.clientes$,
      this.vendedores$,
    ])
  }

}
