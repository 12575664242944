<app-pavso-title-section [title]="'Conceptos descuento'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <div class="pav-table-search-container">
      <div class="pav-separator"></div>
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
      </div>
    </div>

      <div class="mat-elevation-z0">

        <table-title-action title="Todos los conceptos de descuento" url="/modulo-ventas/concepto-descuento" entity="" [params]="['0']"></table-title-action>

        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <table mat-table [dataSource]="dataSource" matSort >

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <a
                      class="pav-btn-circle-edit"
                      [routerLink]="['/modulo-ventas/concepto-descuento', row.dco_coddco]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="dco_coddco">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td #container mat-cell *matCellDef="let row" data-label="Código" class="td-responsive">
                  {{row.dco_coddco}}
                  <!-- <mat-icon class="pav-icon-copy" (click)="copiar(row.dco_desdco)" ngxClipboard [cbContent]="row.dco_desdco" matTooltip="Copiar código" [ngStyle]="{cursor: 'pointer'}">content_copy</mat-icon> -->
                </td>
            </ng-container>

            <ng-container matColumnDef="dco_desdco">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.dco_desdco}}  </td>
            </ng-container>

            <ng-container matColumnDef="dco_obsdco">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción Abreviada </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción Abreviada" class="td-responsive"> {{row.dco_obsdco}}  </td>
            </ng-container>

            <ng-container matColumnDef="dco_indsta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.dco_indsta == '1'? 'ACTIVO':'INACTIVO'}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
  </div>
