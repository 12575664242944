import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarErrorComponent } from "@shared/components/snackbar/snackbar-error/snackbar-error.component";
import { SnackbarSuccessComponent } from "@shared/components/snackbar/snackbar-success/snackbar-success.component";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(
    private _snackBar: MatSnackBar,
  ) {}

  /**
   *
   * @param message
   * @param action
   */
  showError(message, action, callback?: Function) {
    const snackBarRef = this._snackBar.openFromComponent(SnackbarErrorComponent, {
      panelClass: ['alert-snackbar'],
      data: { message: message, snackBar: this._snackBar, action },
      duration: 30000
    })

    snackBarRef.onAction().subscribe(() => {
      console.log('action done')
      if(callback) {
        callback();
      }
    });
  }

  /**
   *
   * @param message
   * @param action
   */
  showSuccess(message, action) {
    this._snackBar.openFromComponent(SnackbarSuccessComponent, {
      panelClass: ['green-snackbar'],
      data: { message: message, snackBar: this._snackBar, action },
      duration: 30000
    })
  }
}
