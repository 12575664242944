import { Component } from "@angular/core";

@Component({
  selector: 'colaborador-select',
  template: ``,
  styleUrls: ['./colaborador-select.component.scss']
})
export class ColaboradorSelect {

}
