<app-pavso-title-section [title]="'Órden de producción'" [module]="'Producción'" [estado]="ordenProduccion.estado"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l6 xl3">
          <mat-form-field>
            <mat-label>Tipo de Documento</mat-label>
            <mat-select required name="tipo_documento" [disabled]="loaderData">
              <mat-option value="1">
                tipo 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6 xl3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="fecha">
            <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
            <mat-datepicker #fecha></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6 xl3">
          <mat-form-field>
            <mat-label>Número</mat-label>
            <input type="number" matInput placeholder="Número" name="numero" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6 xl3">
          <select-search [label]="'Pedido'" [placeholder]="'Ej. 1222020'" [name]="'pcc_numpcc'" [value]="'pcc_numpcc'"
            [description]="'pcc_numpcc'" [data]="pedidos" (cambioSelect)="ordenProduccion.pedido = $event"
            [model]="ordenProduccion.pedido">
          </select-search>
        </div>
        <div class="col s12 m12 l6 xl3">
          <select-search [label]="'Proyecto o CeCo'" [placeholder]="'Ej. ASCENSOR'" [name]="'ccs_codccs'"
            [value]="'ccs_codccs'" [description]="'ccs_descri'" [data]="centros"
            (cambioSelect)="ordenProduccion.centro = $event" [model]="ordenProduccion.centro">
          </select-search>

        </div>
        <div class="col s12 m12 l12 xl3">
          <select-search [label]="'Producto'" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd'" [value]="'prd_codprd'"
            [description]="'DESC_ESPA'" [data]="productos" (cambioSelect)="ordenProduccion.producto = $event"
            [model]="ordenProduccion.producto">
          </select-search>
        </div>
        <div class="col s12 m12 l6 xl3">
          <mat-form-field>
            <mat-label>Cantidad</mat-label>
            <input type="number" min="0" matInput placeholder="Cantidad" name="cantidad" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6 xl2">
          <mat-form-field>
            <mat-label>Unidad Medida</mat-label>
            <input type="number" min="0" matInput placeholder="" name="cantidad_abrev" required [disabled]="loaderData" readonly>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l5 xl3">
          <mat-form-field>
            <mat-label>Situación</mat-label>
            <mat-select required name="situacion" [disabled]="loaderData">
              <mat-option value="1">
                situacion 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l5 xl3">
          <mat-form-field>
            <mat-label>Fórmula</mat-label>
            <mat-select required name="formula" [disabled]="loaderData">
              <mat-option value="1">
                formula 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l1">
          <button type="button" mat-mini-fab color="primary">
            <mat-icon>email</mat-icon>
          </button>
        </div>
        <div class="col s12 m12 l5 xl2">
          <mat-form-field>
            <mat-label>Ruta</mat-label>
            <mat-select required name="formula" [disabled]="loaderData">
              <mat-option value="1">
                formula 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Materiales</div>
      <div class="pav-separator"></div>

      <div>
        <button mat-flat-button class="pav-button-add" (click)="agregarMaterial()" type="button"
          color="primary">Agregar</button>
      </div>
    </div>

    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSourceMat" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row; let i = index;" [style.color]="row.color" data-label="Acciones">
            <div class="pav-btns-container">
              <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarMaterial(i)">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
              <div [ngStyle]="{marginLeft: '15px', marginTop: '4px'}">
                <mat-checkbox [(ngModel)]="row.seleccionado" [name]="'seleccionado' + i" class="example-margin"
                  (change)="seleccionarMaterial($event.checked, row)"></mat-checkbox>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Item"> {{row.item}} </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
            <mat-form-field class="example-full-width">
              <mat-label></mat-label>
              <input [(ngModel)]="row.codigo" [name]="'codigo'+i" matInput placeholder="Ej. 0014">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="cod_laboral">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Laboral </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Cod. Laboral">
            <mat-form-field class="example-full-width">
              <mat-label></mat-label>
              <input [(ngModel)]="row.description" [name]="'description'+i" matInput placeholder="Ex. 0014">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
            <mat-form-field class="example-full-width">
              <mat-label></mat-label>
              <input [(ngModel)]="row.description" [name]="'description'+i"  matInput placeholder="Ej. Descripción">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="um">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
            <mat-form-field class="example-full-width">
              <mat-label></mat-label>
              <input [(ngModel)]="row.um" [name]="'um'+i" matInput placeholder="Ej. UND">
            </mat-form-field>
          </td>

        </ng-container>

        <ng-container matColumnDef="cantidad">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
            <mat-form-field class="example-full-width">
              <mat-label></mat-label>
              <input [(ngModel)]="row.cantidad" [name]="'cantidad'+i" matInput placeholder="Ej. 0">
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMat"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMat;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No se encontraron registros</td>

        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l6 xl6">
          <mat-radio-group>
            <mat-radio-button value="1">Calcula Distribución</mat-radio-button>
            <mat-radio-button value="2">Calcula Cantidad</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col s12 m12 l6 xl6">
          <button type="button" mat-flat-button color="primary">Carga los Productos que Pueden Fabricar</button>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Envasado</div>
      <div class="pav-separator"></div>

      <div>
        <button mat-flat-button class="pav-button-add" (click)="agregarEnvasado()" type="button"
          color="primary">Agregar</button>
      </div>
    </div>
    <div class="mat-elevation-z0">

      <table mat-table [dataSource]="dataSourceEnv" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row; let i = index;" [style.color]="row.color" data-label="Acciones">
            <div class="pav-btns-container">
              <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarEnvasado(i)">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.codigo}} </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="um">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
          <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.um}} </td>
        </ng-container>

        <ng-container matColumnDef="cantidad">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
          <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cantidad}} </td>
        </ng-container>

        <ng-container matColumnDef="distribucion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Distribución PQT </th>
          <td mat-cell *matCellDef="let row" data-label="Distribución PQT"> {{row.distribucion}} </td>
        </ng-container>

        <ng-container matColumnDef="en_caja">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> En Caja </th>
          <td mat-cell *matCellDef="let row" data-label="En Caja"> {{row.en_caja}} </td>
        </ng-container>

        <ng-container matColumnDef="orden">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Orden </th>
          <td mat-cell *matCellDef="let row" data-label="Orden"> {{row.orden}} </td>
        </ng-container>
        <ng-container matColumnDef="fecha_fabricacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Fabricación </th>
          <td mat-cell *matCellDef="let row" data-label="Fecha Fabricación"> {{row.fecha_fabricacion}} </td>
        </ng-container>
        <ng-container matColumnDef="caja">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Caja </th>
          <td mat-cell *matCellDef="let row" data-label="Caja"> {{row.caja}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsEnv"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsEnv;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="11">No se encontraron registros</td>

        </tr>

      </table>

      <mat-paginator #paginatorEnvasado showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>
    <div class="row">

      <div class="col s12 m12 l12 xl12">

        <div class="pav-filtro-header">
          <div class="pav-filtro-header-icon"></div>
          <div class="pav-filtro-header-title">Lista de Requerimiento Generado</div>
          <div class="pav-separator"></div>

          <div>
            <button mat-flat-button class="pav-button-add" (click)="agregarMaterial()" type="button"
              color="primary">Agregar</button>
          </div>
        </div>
        <div class="mat-elevation-z0">

          <table mat-table [dataSource]="dataSourceLista" matSort>

            <ng-container matColumnDef="codigo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.codigo}} </td>
            </ng-container>

            <ng-container matColumnDef="cliente">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cliente}} </td>
            </ng-container>

            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.estado}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsLista"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsLista;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="3">No se encontraron registros</td>

            </tr>

          </table>

          <mat-paginator #paginatorLista showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <div class="col s12 m12 l12 xl12">
          <button type="button" mat-flat-button color="primary">Nuevo Requerim. de Materiales</button>
        </div>
      </div>
    </div>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
