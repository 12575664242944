import { Component, OnDestroy, OnInit } from '@angular/core';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { AuthenticationService, CompanyClientService, ConfigurationService } from 'src/app/services';
import * as actionsConfig from '@shared/state/actions/config.actions';
import { Subject, Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { cambiarLoadingCompany } from '@shared/state/actions/loading.actions';
import { UserClient } from 'src/app/models/user-client';
import { Router } from '@angular/router';
import { CIAService } from 'src/app/services/api/sistema/cia.service';

@Component({
  selector: 'side-setting',
  templateUrl: './side-setting.component.html',
  styleUrls: ['./side-setting.component.scss'],
})
export class SideSettingComponent implements OnInit, OnDestroy {

  user: any;
  clientId: any;
  url: any;
  urlId: any;
  urls: any = [];
  companiesClient: any = [];
  companyClient: any;
  ciaNumRuc: any;
  modo: string;

  /** filtrar motivos */
  clients: any[] = [];


  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  company$: Subscription;
  clienteUser$: Subscription;
  verificarUsuario$: Subscription;
  config$: Subscription;
  config: any;

  constructor(
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _companyClientService: CompanyClientService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
  ) { }

  ngOnInit(): void {
    this.user = this._authService.getIdentity();
    this.modo = this._configurationService.obtenerModo();
    this.clientId = this._configurationService.obtenerClienteId();
    this.url = this._configurationService.obtenerUrl();
    this.companyClient = this._configurationService.obtenerCompaniaCliente();

    this.config$ = this.store.select('config').subscribe(state => {
      this.config = state;
    })

    this.store.dispatch(actionsConfig.cambiarConfiguracionGeneral({ client: this.clientId, company_client: this.companyClient, logo: '', dircia: '', ...this.config }));

    this.obtenerClientes();
  }

  obtenerClientes(): void {

    this.clients = this._configurationService.obtenerClientes();

    if (this.clientId) {
      this.seleccionarCliente(this.clientId)

      return;
    }

    this.clientId = this.clients[0].cli_codcli;
    this.seleccionarCliente(this.clientId)

  }

  seleccionarCliente(clientId): void {
    const cliente = this.clients.find(item => item.cli_codcli == this.clientId);
    this._configurationService.establecerNombreCliente(cliente.cli_nomcli);

    this.clientId = clientId;
    this._configurationService.establecerClienteId(this.clientId);

    this.urls = cliente.urls;

    if (this.urls && this.urls.length > 0) {
      this.url = this.urls[0].csa_urlcsa;
      this.urlId = this.urls[0].csa_corcsa;
      this._configurationService.establecerUrl(this.url);

      const usuarioCliente: UserClient = {
        csa_usuapi: this.urls[0].csa_usuapi,
        csa_pasapi: this.urls[0].csa_pasapi
      }

      this.verificarUsuario$ = this._authService.verificarUsuarioCliente(usuarioCliente).subscribe(
        response => {
          localStorage.setItem('token-client', response.token);
          this.company$ = this._companyClientService.obtenerCompanias(this.url).subscribe(
            companiasCliente => {
              this.companiesClient = companiasCliente;
              this._configurationService.establecerCompaniasCliente(JSON.stringify(this.companiesClient))
              this.store.dispatch(
                actionsConfig.cambiarConfiguracionGeneral({
                  client: this.clientId,
                  company_client: this.companyClient,
                  logo: '',
                  dircia: '',
                  ...this.config
                })
              );

              console.log('obtener compania cliente', localStorage.getItem('company-client'))

              // si existe una compania cliente
              if (this.companyClient) {
                const compania = this.companiesClient.find(item => item.CIA_CODCIA == this.companyClient);
                this._configurationService.establecerCompaniaCliente(
                  this.companyClient
                );
                this._configurationService.establecerNombreCompaniaCliente(
                  compania.cia_nomcia
                );

                this._configurationService.establecerPais(compania.pai_codpai);

                this.store.dispatch(cambiarLoadingCompany({ isLoadingCompany: false, isLoadingSidenav: true, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: true }));
                return;
              }

              // si no existe una compañia cliente seleccionada

              if (this.companiesClient && this.companiesClient.length > 0) {
                this.companyClient = this.companiesClient[0].CIA_CODCIA;
                this._configurationService.establecerCompaniaCliente(
                  this.companyClient
                );
                this._configurationService.establecerNombreCompaniaCliente(
                  this.companiesClient[0].cia_nomcia
                );

                this._configurationService.establecerPais(this.companiesClient[0].pai_codpai);

                this.store.dispatch(cambiarLoadingCompany({ isLoadingCompany: false, isLoadingSidenav: true, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: true }));

              }
            },
            (error) => {
              this._snackBarService.showError(error.error.msg, 'OK');
            }
          );
        },
        error => {
          this._snackBarService.showError('Error al verificar usuario cliente', 'OK');
        }
      )
    } else {
      // this._snackBarService.showError('ESTE CLIENTE NO POSEE URLS', 'OK');
      this.companiesClient = [];
      this._configurationService.removerUrl();

      this.store.dispatch(cambiarLoadingCompany({ isLoadingCompany: false, isLoadingSidenav: true, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: true }));

      this._router.navigate(['/ingresar-parametros']);
    }
  }

  seleccionarCompaniaCliente(valor): void {
    this.companyClient = valor;
    console.log('companies cliente', this.companiesClient)
    const compania = this.companiesClient.find(item => item.CIA_CODCIA == valor);

    this._configurationService.establecerCompaniaCliente(
      this.companyClient
    );

    this._configurationService.establecerCiaNumRuc(compania.CIA_NUMRUC);

    this._configurationService.establecerNombreCompaniaCliente(
      compania.cia_nomcia
    );

    this.store.dispatch(
      actionsConfig.cambiarConfiguracionGeneral({
        client: this.clientId,
        company_client: this.companyClient,
        logo: '',
        dircia: '',
        ...this.config
      })
    );

    this.store.dispatch(cambiarLoadingCompany({ isLoadingCompany: false, isLoadingSidenav: true, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: true }));



  }

  seleccionarUrl(id): void { }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.clienteUser$,
      this.company$,
      this.verificarUsuario$,
    ])
  }
}
