<app-pavso-title-section [title]="'apps.crm.maestros.canal.title' | translate"
  [module]="'CRM'" [estado]="canal.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>{{'apps.crm.maestros.canal.form.code' | translate}}</mat-label>
            <input type="text" [(ngModel)]="canal.idmtk" matInput placeholder="Código" name="idmtk"
              [disabled]="loaderData" readonly>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>{{'apps.crm.maestros.canal.form.description' | translate}}</mat-label>
            <input type="text" [(ngModel)]="canal.descri" matInput
              [placeholder]="'apps.crm.maestros.canal.form.description' | translate" name="descri" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <log-updated *ngIf="uid!='0'" [coduse]="canal.coduse" [fecupd]="canal.fecupd"></log-updated>

    <br>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-crm/canales">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
