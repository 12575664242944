<app-pavso-title-section [title]="'Permisos de Aprobación de pedidos'" [module]="'Ventas'"
  [ngClass]="'pav-form'"></app-pavso-title-section>

<div class="row">
  <div class="col s12 m12 l6">
    <div align="end" (click)="agregarSecuencia()">
      <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource01" matSort>

        <ng-container matColumnDef="secuencia">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Secuencia </th>
          <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.secuencia}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns01"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns01;" [class.selected-row]="row === selected"
        (click)="selectRow(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">No se encontraron registros</td>
        </tr>

      </table>
    </div>
  </div>
  <div class="col s12 m12 l6">
    <div align="end">
      <button (click)="agregarUsuario()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource02" matSort>

        <ng-container matColumnDef="usuario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
          <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive">
            <mat-form-field>
              <mat-label></mat-label>
              <mat-select>
                <mat-option *ngFor="let usuario of usuarios" [value]="usuario.value">
                  {{usuario.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns02"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns02;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">No se encontraron registros</td>
        </tr>

      </table>
    </div>
  </div>
</div>
<pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
  (clickMasOpciones)="verMasOpciones()">
  <div id="botonesAdicionales">

  </div>
</pav-form-actions>
