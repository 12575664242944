export class TipoCambioCierre {
  cia_codcia: string;
  ano_codano: string;
  mes_codmes: string;
  ctc_tcacom: number;
  ctc_tcaven: number;
  ctc_fecupd: Date;
  ctc_coduse: string;

  constructor() {
    this.ctc_fecupd = new Date();
  }
}
