<app-pavso-title-section [title]="'Producto Compuesto'" [module]="'Ventas'" ></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Código</mat-label>
            <input matInput placeholder="Código" name="codigo" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Código SUNAT</mat-label>
            <input matInput placeholder="Código SUNAT" name="codigoSunat" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Estado</mat-label>
            <mat-select>
              <mat-option value="1">ACTIVO</mat-option>
              <mat-option value="0">ANULADO</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field class="example-full-width">
            <mat-label>Descripción</mat-label>
            <input matInput placeholder="Descripción" name="descripcion" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field class="example-full-width">
            <mat-label>Descripción Abreviada</mat-label>
            <input matInput placeholder="Descripción Abreviada" name="descripcionAbreviada" required>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>

    <mat-tab-group>
      <mat-tab label="Datos Generales" labelClass="mat-tab-label-0-0">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l3">

              <select-search
                [label]="'Tipo de Inventario'"
                [placeholder]="'Ej. Inventario'"
                [name]="'tipo'"
                [value]="'tipo'"
                [description]="'tipo'"
                [data]="tiposInventario"
                (cambioSelect)="producto.tipoInventario = $event"
                [model]="producto.tipoInventario">
              </select-search>

            </div>
            <div class="col s12 m12 l3">

              <select-search
                [label]="'Línea'"
                [placeholder]="'Ej. Línea'"
                [name]="'linea'"
                [value]="'linea'"
                [description]="'linea'"
                [data]="lineas"
                (cambioSelect)="producto.linea = $event"
                [model]="producto.linea">
              </select-search>

            </div>
            <div class="col s12 m12 l3">

              <select-search
                [label]="'Familia'"
                [placeholder]="'Ej. Torres'"
                [name]="'familia'"
                [value]="'familia'"
                [description]="'familia'"
                [data]="familias"
                (cambioSelect)="producto.familia = $event"
                [model]="producto.familia">
              </select-search>

            </div>
            <div class="col s12 m12 l3">

              <select-search
                [label]="'Subfamilia'"
                [placeholder]="'Ej. Torres'"
                [name]="'subfamilia'"
                [value]="'subfamilia'"
                [description]="'subfamilia'"
                [data]="subfamilias"
                (cambioSelect)="producto.subfamilia = $event"
                [model]="producto.subfamilia">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <select-search
                [label]="'Marca'"
                [placeholder]="'Ej. Nike'"
                [name]="'marca'"
                [value]="'marca'"
                [description]="'marca'"
                [data]="marcas"
                (cambioSelect)="producto.marca = $event"
                [model]="producto.marca">
              </select-search>
            </div>
            <div class="col s12 m12 l3">
              <select-search
                [label]="'Modelo'"
                [placeholder]="'Ej. Tike'"
                [name]="'modelo'"
                [value]="'modelo'"
                [description]="'modelo'"
                [data]="modelos"
                (cambioSelect)="producto.modelo = $event"
                [model]="producto.modelo">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <select-search
                [label]="'Tecnología'"
                [placeholder]="'Ej. Nueva'"
                [name]="'tecnologia'"
                [value]="'tecnologia'"
                [description]="'tecnologia'"
                [data]="tecnologias"
                (cambioSelect)="producto.tecnologia = $event"
                [model]="producto.tecnologia">
              </select-search>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Serie</mat-label>
                <input matInput placeholder="Serie">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <h1 class="pav-title-section">Control de Almacén</h1>
            <div class="col s12 m12 l3">
              <select-search
                [label]="'Unidad de presentación'"
                [placeholder]="'Ej. UND'"
                [name]="'unidad'"
                [value]="'unidad'"
                [description]="'unidad'"
                [data]="unidades"
                (cambioSelect)="producto.unidad = $event"
                [model]="producto.unidad">
              </select-search>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Contenido</mat-label>
                <input matInput placeholder="Contenido">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <select-search
                [label]="'Medida'"
                [placeholder]="'Ej. UND'"
                [name]="'medida'"
                [value]="'medida'"
                [description]="'medida'"
                [data]="medidas"
                (cambioSelect)="producto.medida = $event"
                [model]="producto.medida">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Código de Proveedor</mat-label>
                <input matInput placeholder="Código de Proveedor">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Código EAN</mat-label>
                <input matInput placeholder="Código EAN">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-checkbox [(ngModel)]="producto.otraPresentacion" name="otraPresentacion" class="example-margin">Otra presentación</mat-checkbox>
            </div>
            <div class="col s12 m12 l3">
              <select-search
                [label]="'Producto Base'"
                [disabled]="!producto.otraPresentacion"
                [placeholder]="'Ej. Canasta'"
                [name]="'producto'"
                [value]="'producto'"
                [description]="'producto'"
                [data]="productos"
                (cambioSelect)="producto.producto = $event"
                [model]="producto.producto">
              </select-search>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Cantidad</mat-label>
                <input [disabled]="!producto.otraPresentacion" matInput placeholder="Cantidad">
              </mat-form-field>
            </div>

            <div class="col s12">
              <mat-checkbox class="example-margin">Producto compuesto</mat-checkbox>
              &nbsp;
              &nbsp;
              <mat-checkbox class="example-margin">Producto controlado</mat-checkbox>
            </div>

          </div>
          <div class="row">
            <h1 class="pav-title-section">Control de Stock mínimo y máximo</h1>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Stk. Mínimo</mat-label>
                <input matInput placeholder="Stk. Mínimo">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Stk. Máximo</mat-label>
                <input matInput placeholder="Stk. Máximo">
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Características" labelClass="mat-tab-label-0-1">

        <mat-card class="mat-elevation-z0">
          <div class="row">
            <h1 class="pav-title-section">Pesos</h1>
            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Peso Bruto</mat-label>
                <input matInput placeholder="Peso Bruto">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Peso Neto</mat-label>
                <input matInput placeholder="Peso Neto">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Galones</mat-label>
                <input disabled matInput placeholder="Galones">
              </mat-form-field>
            </div>
            <h1 class="pav-title-section">Dimensiones</h1>
            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Largo</mat-label>
                <input matInput placeholder="Largo">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Ancho</mat-label>
                <input matInput placeholder="Ancho">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Altura</mat-label>
                <input matInput placeholder="Altura">
              </mat-form-field>
            </div>
            <div class="col s12">
              <mat-form-field class="example-full-width">
                <mat-label>Especificaciones</mat-label>
                <textarea matInput placeholder="Especificaciones"></textarea>
              </mat-form-field>
            </div>
            <h1 class="pav-title-section">Datos para serie</h1>
            <div class="pav-table-header-container">
              <div class="pav-table-header-icon">

              </div>
              <div class="pav-table-header-title">
                Caraterísticas Adicionales
              </div>
              <div class="pav-table-header-add">
                <div class="isMobile">
                  <div class="pav-button-icon-add" (click)="agregarCaracteristica()">
                    <mat-icon svgIcon="add"></mat-icon>
                  </div>
                </div>
                <div class="isWeb">
                  <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregarCaracteristica()">Agregar ítem</button>
                </div>
              </div>
            </div>
            <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSourceCaracteristicas" matSort>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div class="pav-btn-circle-delete" (click)="quitarCaracteristica(row, i)" matTooltip="Eliminar">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="sec">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec. </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Sec.">
                        <ng-container>
                          <mat-form-field class="example-full-width">
                            <mat-label></mat-label>
                            <input matInput placeholder="Sec.">
                          </mat-form-field>
                        </ng-container>
                      </td>
                  </ng-container>

                  <ng-container matColumnDef="orden">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Órden </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Órden">
                      <ng-container>
                        <mat-form-field class="example-full-width">
                          <mat-label></mat-label>
                          <input matInput placeholder="Orden">
                        </mat-form-field>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="descripcion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                      <ng-container>
                        <mat-form-field class="example-full-width">
                          <mat-label></mat-label>
                          <input matInput placeholder="Descripción">
                        </mat-form-field>
                      </ng-container>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsCaracteristicas"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsCaracteristicas;"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="4">No se encontraron registros</td>

                  </tr>
                </table>
                <mat-paginator #matPagCaracteristicas [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>

            </div>
          </div>
        </mat-card>

      </mat-tab>
      <mat-tab label="Conversiones" labelClass="mat-tab-label-0-2">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Conversiones
          </div>
          <div class="pav-table-header-add">
            <div class="isMobile">
              <div class="pav-button-icon-add" (click)="agregarConversion()">
                <mat-icon svgIcon="add"></mat-icon>
              </div>
            </div>
            <div class="isWeb">
              <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregarConversion()">Agregar ítem</button>
            </div>
          </div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSourceConversiones" matSort>

              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-delete" (click)="quitarConversion(row, i)" matTooltip="Eliminar">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="unidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                  <ng-container>
                    <mat-form-field class="example-full-width">
                      <mat-label></mat-label>
                      <input matInput placeholder="Unidad">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                  <ng-container>
                    <mat-form-field class="example-full-width">
                      <mat-label></mat-label>
                      <input matInput placeholder="Descripción">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Equivalente </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                  <ng-container>
                    <mat-form-field class="example-full-width">
                      <mat-label></mat-label>
                      <input matInput placeholder="Cantidad Equivalente">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="codigo_almacen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código Almacén </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                  <ng-container>
                    <mat-form-field class="example-full-width">
                      <mat-label></mat-label>
                      <input matInput placeholder="Codigo almacen">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsConversiones"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsConversiones;"></tr>

              <tr class="mat-row" *matNoDataRow>

                <td class="mat-cell" colspan="6">No se encontraron registros</td>

              </tr>
            </table>
            <mat-paginator #matPagConversiones [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>

        </div>
      </mat-tab>
      <mat-tab label="Ubicaciones por almacén" labelClass="mat-tab-label-0-4">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Ubicaciones por Almacén
          </div>
          <div class="pav-table-header-add">
            <div class="isMobile">
              <div class="pav-button-icon-add" (click)="agregarUbicacion()">
                <mat-icon svgIcon="add"></mat-icon>
              </div>
            </div>
            <div class="isWeb">
              <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregarUbicacion()">Agregar ítem</button>
            </div>
          </div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSourceUbicaciones" matSort>

              <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                    <div class="pav-btns-container">
                      <div class="pav-btn-circle-delete" (click)="quitarUbicacion(row, i)" matTooltip="Eliminar">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </div>
                    </div>
                  </td>
              </ng-container>

              <ng-container matColumnDef="almacen">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                    <ng-container>
                      <mat-form-field class="example-full-width">
                        <mat-label></mat-label>
                        <input matInput placeholder="Almacen">
                      </mat-form-field>
                    </ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="ubicacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubicación </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                  <ng-container>
                    <mat-form-field class="example-full-width">
                      <mat-label></mat-label>
                      <input matInput placeholder="Ubicacion">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsUbicaciones"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsUbicaciones;"></tr>

              <tr class="mat-row" *matNoDataRow>

                  <td class="mat-cell" colspan="5">No se encontraron registros</td>

              </tr>
            </table>
            <mat-paginator #matPagUbicaciones [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>

        </div>
      </mat-tab>
      <mat-tab label="Configuración de control de inventario" labelClass="mat-tab-label-0-5">
        <mat-card>
          <div class="row">
            <div class="col s12 m12 l4">
              <mat-checkbox class="example-margin" [(ngModel)]="producto.controlPorLote" name="controlPorLote">Control por lote</mat-checkbox><br>
              <mat-checkbox class="example-margin" [disabled]="!producto.controlPorLote">Fecha de fabricación</mat-checkbox><br>
              <mat-checkbox class="example-margin" [disabled]="!producto.controlPorLote">Fecha de vencimiento</mat-checkbox>
            </div>

            <div class="col s12 m12 l4">
              <mat-checkbox class="example-margin">Control por serie</mat-checkbox>
            </div>
            <div class="col s12 m12 l4">
              <mat-checkbox class="example-margin">Control por tallas</mat-checkbox>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Imágenes" labelClass="mat-tab-label-0-6">
        <mat-card class="mat-elevation-z0">
          <mat-list>
            <div mat-subheader>Imágenes</div>
            <mat-list-item [ngStyle]="{'border': '1px solid #cec8c8', 'borderRadius': '10px'}" *ngFor="let folder of [{name: 'IMG_20191212_132335.jpeg', updated: '147.14 KB'}]">
              <mat-icon mat-list-icon>folder</mat-icon>
              <div mat-line>{{folder.name}}</div>
              <div mat-line> {{folder.updated}} </div>
            </mat-list-item>
          </mat-list>
        </mat-card>
      </mat-tab>
      <mat-tab label="Integración Contable" labelClass="mat-tab-label-0-7">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Cta. Contable Vta.</mat-label>
                <input matInput placeholder="Cta. Contable Vta." name="codigo" required>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

  </form>
</div>
