<app-pavso-title-section [title]="'apps.crm.maestros.situacion-oportunidad.title' | translate" [module]="'CRM'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="situaciones"
    [tHeaders]="tHeaders"

    title="Todas las situaciones"
    url="/modulo-crm/situacion-de-oportunidad"
    entity=""
    [pageSize]="10"
    [params]="['0']"
  ></pav-table-list>

</div>
