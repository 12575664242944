<!-- <tour-step-template></tour-step-template> -->
<div *ngIf="!networkStatus" class="pav-not-connection">
  <img class="pav-img-not-connection" src="assets/images/no-conexion.gif" alt="Sin conexión">
  <img class="pav-img-logo"  src="assets/images/logo-light.png" alt="">
  <h1>
    No es posible conectarse a internet
  </h1>
  <h2>Pavso retornará al conectarse</h2>
</div>
<router-outlet *ngIf="networkStatus"></router-outlet>
