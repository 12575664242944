export class RegistroOportunidad {
  codcia: string;
  idtkt: string;
  fecreg: Date;
  codcli: string;
  idmtk: number;
  idstk: number;
  idtas: number;
  asunto: string;
  idmod: string;
  detalle: string;
  fecupd: Date;
  coduse: string;
  indsta: string;
  idusc: string;
  idapr: string;
  codcso: string;
  fecent: Date;
  codncr: string;
  feceva: Date;
  indeva: number;
  caleva: string;
  feccre: Date;
  obseva: string;
  codprd: string;
  numtra: number;
  cosopo: number;
  numusu: number;
  codtmo: string;
  codoln: string;
  codose: string;
  numins: number;
  numkwp: number;
  cararc: string;
  nomarc: string;
  codolt: string;
  codnom: string;
  codotn: string;
  codoet: string;
  codomc: string;
  pormar: number;
  feclic: Date;
  codvde: string;
  codccs: string;
  codccl: string;

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
    this.fecreg = new Date();
    this.feccre = new Date();
  }
}
