export class Cargo {
  codcia: any;
  codnca: any;
  descri: any;
  indsta: any;
  coduse: any;

  constructor() {
    this.indsta = "1";
  }
}
