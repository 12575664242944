<app-pavso-title-section [title]="'Explosión de materiales'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l6 xl3">
                <mat-form-field>
                    <mat-label>Requerimiento Nro.</mat-label>
                    <input type="text" matInput placeholder="Requerimiento Nro." name="requerimiento_nro" required [disabled]="loaderData">
                </mat-form-field>
            </div>
            <div class="col s12 m12 l6 xl3">
              <mat-form-field>
                <mat-label>Fecha de Emisión</mat-label>
                <input matInput [matDatepicker]="fechaemision">
                <mat-datepicker-toggle matSuffix [for]="fechaemision"></mat-datepicker-toggle>
                <mat-datepicker #fechaemision></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l6 xl3">
                <button mat-flat-button color="priamry" type="button">Genera Resumen de Explosión</button>
            </div>

          </div>

      </mat-card>
      <br>

      <div class="pav-filtro-header">
        <div class="pav-filtro-header-icon"></div>
        <div class="pav-filtro-header-title">Productos</div>
        <div class="pav-separator"></div>

        <div>
          <button mat-flat-button class="pav-button-add" type="button" (click)="agregarProducto()"
            color="primary">Agregar</button>
        </div>
      </div>

      <div class="mat-elevation-z0 overflow-x">

          <table mat-table [dataSource]="dataSource" matSort >
              <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row; let i = index;"data-label="Acciones">
                      <div class="pav-btns-container">
                        <div class="pav-btn-circle-delete" (click)="eliminarProducto(i)" matTooltip="Eliminar">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>
                  </td>
              </ng-container>

              <ng-container matColumnDef="tipo_inventario">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Inventario </th>
                  <td mat-cell *matCellDef="let row" data-label="Tipo Inventario"> {{row.tipo_inventario}} </td>
              </ng-container>

              <ng-container matColumnDef="producto">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                  <td mat-cell *matCellDef="let row" data-label="Producto"> {{row.producto}} </td>
              </ng-container>

              <ng-container matColumnDef="descripcion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                  <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
              </ng-container>

              <ng-container matColumnDef="um">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
                <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.um}} </td>
              </ng-container>

              <ng-container matColumnDef="cantidad_requerida">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Requerida </th>
                <td mat-cell *matCellDef="let row" data-label="Cantidad Requerida"> {{row.cantidad_requerida}} </td>
              </ng-container>

              <ng-container matColumnDef="cantidad_stock">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Stock </th>
                <td mat-cell *matCellDef="let row" data-label="Cantidad Stock"> {{row.cantidad_stock}} </td>
              </ng-container>

              <ng-container matColumnDef="cantidad_produccion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad de Producción </th>
                <td mat-cell *matCellDef="let row" data-label="Cantidad de Producción"> {{row.cantidad_produccion}} </td>
              </ng-container>

              <ng-container matColumnDef="cantidad_compra">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad de Compra </th>
                <td mat-cell *matCellDef="let row" data-label="Cantidad de Compra"> {{row.cantidad_compra}} </td>
              </ng-container>

              <ng-container matColumnDef="cantidad_final">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad de Final </th>
                <td mat-cell *matCellDef="let row" data-label="Cantidad de Final"> {{row.cantidad_final}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="10">No se encontraron registros</td>

              </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <br>
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l6 xl5">
            <mat-form-field>
              <mat-label>Total Requerido</mat-label>
              <textarea matInput placeholder="Total Requerido"></textarea>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l6 xl5">
            <mat-form-field>
              <mat-label>Total Final</mat-label>
              <textarea matInput placeholder="Total Final"></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <br>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
