import { ActivoFijoModule } from "./activo-fijo/activo-fijo.module";
import { AlmacenModule } from "./almacen/almacen.module";
import { CajaBancoModule } from "./caja-y-bancos/caja-banco.module";
import { CobranzasModule } from "./cobranzas/cobranzas.module";
import { ComprasModule } from "./compras/compras.module";
import { ProveedorModule } from "./compras/pages/maestros/proveedor/proveedor.module";
import { TipoServicioModule } from "./compras/pages/maestros/tipo-servicio/tipo-servicio.module";
import { ContabilidadModule } from "./contabilidad/contabilidad.module";
import { CostosModule } from "./costos/costos.module";
import { CrmModule } from "./crm/crm.module";
import { GeneralModule } from "./general/general.module";
import { ImportacionesModule } from "./importaciones/importaciones.module";
import { LibroElectronicoModule } from "./libro-electronico/libro-electronico.module";
import { OperacionesModule } from "./operaciones/operaciones.module";
import { PlanillaModule } from "./planilla/planilla.module";
import { ProduccionModule } from "./produccion/produccion.module";
import { PuntoVentaModule } from "./punto-de-venta/punto-venta.module";
import { UtilitariosModule } from "./utilitarios/utilitarios.module";
import { VentasModule } from "./ventas/ventas.module";

export const appsModule = [
  GeneralModule,
  ActivoFijoModule,
  AlmacenModule,
  CajaBancoModule,
  CobranzasModule,
  ComprasModule,
  ContabilidadModule,
  CostosModule,
  CrmModule,
  ImportacionesModule,
  LibroElectronicoModule,
  OperacionesModule,
  PlanillaModule,
  ProduccionModule,
  PuntoVentaModule,
  UtilitariosModule,
  VentasModule,
]
