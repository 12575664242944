<div class="pav-language-cotainer" [matMenuTriggerFor]="menuLanguage">

  <div class="pav-language-icon">
    <mat-icon class="pav-language-icon-flag" [svgIcon]="icon"></mat-icon>
  </div>

  <p class="pav-language-text">{{title | translate}}</p>

  <div>
    <mat-icon class="pav-language-icon-arrow" svgIcon="arrow-bottom"></mat-icon>
  </div>
</div>

<mat-menu #menuLanguage="matMenu" xPosition="before">
  <div class="pav-language-cotainer-menu" (click)="cambiarIdioma()">

    <div class="pav-language-icon-menu">
      <mat-icon class="pav-language-icon-flag" [svgIcon]="iconInactive"></mat-icon>
    </div>

    <p class="pav-language-text-menu">{{titleInactive | translate}}</p>

  </div>
</mat-menu>
