export class AutorizadoAprobarCompras {
  cia_codcia: string;
  cli_codcli: string;
  aoc_indapr: number;
  aoc_indsta: string;
  aoc_coduse: string;
  aoc_fecupd: Date;
  aoc_usecod: string;
  aoc_userem: string;
  tmo_codtmo: string;
  aoc_impaoc: number;
  cli_rutfot: string;

  constructor() {
    this.aoc_fecupd = new Date();
    this.aoc_indsta = '1';
  }
}
