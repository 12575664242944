export class FiltroOportunidad {
  desde: Date;
  hasta: Date;
  responsable: Array<any>;
  cliente: Array<any>;
  tipoNegociacion: Array<any>;
  etapa: Array<any>;
  lineaNegocio: Array<any>;

  constructor() {
    this.responsable = [];
    this.cliente = [];
    this.tipoNegociacion = [];
    this.etapa = [];
    this.lineaNegocio = [];
  }
}
