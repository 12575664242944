import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LineaNegocio } from "src/app/models/crm/linea-negocio";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class LineaNegocioService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerLineasNegocio(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/linea-negocio/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerLineaNegocio(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/linea-negocio/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param linea
   * @returns
   */
  registrarLineaNegocio(linea: LineaNegocio): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/linea-negocio`,
      linea
    );
  }

  /**
   *
   * @param linea
   * @returns
   */
  actualizarLineaNegocio(linea: LineaNegocio): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/linea-negocio`,
      linea
    );
  }
}
