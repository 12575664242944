<app-pavso-title-section [title]="'Aprobación de solicitud de compra'" [module]="'Compras'"
  [ngClass]="'pav-form'"></app-pavso-title-section>
<mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

<div class="pavso-content-form fade-in-image">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l12 xl4">

        <select-search [label]="'Proveedor'" [placeholder]="'Ej. SOFIA'" [name]="'cli_codcli'" [value]="'cli_codcli'"
          [description]="'cli_nomcli'" [data]="proveedores" [model]="filtro.proveedor" (cambioSelect)="filtro.proveedor = $event"></select-search>

      </div>
      <div class="col s12 m12 l12 xl2" [ngStyle]="{marginTop: '10px'}">
        <section>
          <mat-checkbox [(ngModel)]="filtro.fechaSeleccionada" name="fechaSeleccionada" class="example-margin">Seleccionar Fecha</mat-checkbox>
        </section>
      </div>
      <div class="col s12 m12 l12 xl3">
        <mat-form-field>
          <mat-label>Desde - Hasta</mat-label>
          <mat-date-range-input [disabled]="!filtro.fechaSeleccionada" [formGroup]="range" [rangePicker]="picker">
            <input [(ngModel)]="filtro.desde" name="desde" matStartDate formControlName="start" placeholder="Desde">
            <input [(ngModel)]="filtro.hasta" name="hasta" matEndDate formControlName="end" placeholder="Hasta">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l12 xl3" [ngStyle]="{marginTop: '10px'}">
        <mat-radio-group [(ngModel)]="filtro.estaAprobado" name="estado">
          <mat-radio-button [value]="false">Pendiente</mat-radio-button>
          &nbsp;
          <mat-radio-button [value]="true">Aprobado</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row" [ngStyle]="{marginTop: isTablet || isMobile? '10px': '0px'}">
      <div class="col s12" align="center">
        <button mat-mini-fab color="primary" type="button" (click)="buscar()" aria-label="Example icon button with a menu icon">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
  <br>
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <!-- <div class="col s12 m12 l12 xl2">
        <mat-form-field>
          <mat-label>Nro. Docum</mat-label>
          <input matInput placeholder="Nro. Docum">
        </mat-form-field>
      </div> -->
      <div class="col s12 m12 l12 xl4" [ngStyle]="{marginTop: '5px'}">
        <button (click)="aprobar()" [class.spinner]="loaderAprobar" mat-stroked-button [ngStyle]="{background: 'rgb(99, 201, 154)', width: isTablet || isMobile ? '100%': 'auto'}">Aprobar</button>
        &nbsp;
        <button (click)="desaprobar()" [class.spinner]="loaderDesaprobar" mat-stroked-button [ngStyle]="{background: 'rgb(247, 201, 27)', width: isTablet || isMobile ? '100%': 'auto'}">Pendiente</button>
        <!-- &nbsp;
        <button mat-stroked-button [ngStyle]="{background: 'rgb(213, 139, 119)', width: isTablet || isMobile ? '100%': 'auto', marginBottom: isTablet || isMobile ? '12px': '0px'}">Rechazar</button> -->
      </div>
      <div class="col s12 m12 l12 xl2">
        <!-- <mat-form-field>
          <mat-label>Nro. Docum</mat-label>
          <input matInput placeholder="Nro. Docum">
        </mat-form-field> -->
      </div>
      <div class="col s12 m12 l12 xl3">
        <mat-form-field>
          <mat-label>Total M.N.</mat-label>
          <input type="text" [value]="totalMN" name="totalMN" min="0" matInput placeholder="Total M.N." [ngStyle]="{textAlign: 'right'}" readonly>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l12 xl3">
        <mat-form-field>
          <mat-label>Total M.E.</mat-label>
          <input type="text" [value]="totalME" name="totalME" min="0" matInput placeholder="Total M.E." [ngStyle]="{textAlign: 'right'}" readonly>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <div class="mat-elevation-z0">
    <div class="pav-table-header-container">
      <div class="pav-table-header-icon">

      </div>
      <div class="pav-table-header-title">
        Solicitudes
      </div>
      <div class="pav-separator"></div>
      <div class="pav-table-search" [ngStyle]="{width: isTablet || isMobile ? '120px': 'auto'}">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
      </div>
    </div>
    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones" class="td-responsive">
            <button matTooltip="Ver PDF" (click)="mostrarPdf(row.ocm_numocm)" mat-icon-button color="warn">
              <mat-icon>picture_as_pdf</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="rco_numrco">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitud compra </th>
          <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.rco_numrco}} </td>
        </ng-container>

        <ng-container matColumnDef="rco_fecreq">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha emisión </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.rco_fecreq}} </td>
        </ng-container>

        <ng-container matColumnDef="rco_clipro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> RUC </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_clipro}} </td>
        </ng-container>

        <ng-container matColumnDef="proveedor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor sugerido </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.proveedor}} </td>
        </ng-container>

        <ng-container matColumnDef="solicitado_por">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitado por </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.solicitado_por}} </td>
        </ng-container>

        <ng-container matColumnDef="tmo_codtmo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.tmo_codtmo}} </td>
        </ng-container>

        <ng-container matColumnDef="rco_facimp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive"> {{row.rco_facimp}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">No se encontraron registros "{{input.value}}"</td>
        </tr>

      </table>
    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>

<pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
  (clickMasOpciones)="verMasOpciones()">
  <div id="botonesAdicionales">

  </div>
</pav-form-actions>
