import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';
import { forkObs } from '@utils/observables/fork';
import { PlantaService } from 'src/app/services/api/produccion/maestros/planta.service';
import { LiquidacionFabricacion, ManoObra, MaterialUtilizado, ProductoProcesado } from 'src/app/models/produccion/operaciones/liquidacion-fabricacion';
import { DialogService } from '@shared/services/dialog.service';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';

@Component({
  selector: 'app-liquidacion-fabricacion',
  templateUrl: './liquidacion-fabricacion.component.html',
  styleUrls: ['./liquidacion-fabricacion.component.css']
})
export class LiquidacionFabricacionComponent implements OnInit, OnDestroy {

  uid: string;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  tiposDocumento: any[] = [];
  plantas: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['item', 'descripcion', 'tipo', 'numero', 'fecha'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsProd: string[] = ['acciones', 'item', 'orden_produccion', 'cod_prod', 'descripcion', 'um', 'cantidad_orden', 'cantidad_liq', 'linea', 'op', 'operario', 'prod_merma', 'cantidad_merma'];
  dataSourceProd: MatTableDataSource<any>;

  @ViewChild("paginatorProductos") paginatorProd: MatPaginator;
  @ViewChild(MatSort) sortProd: MatSort;

  displayedColumnsMano: string[] = ['acciones', 'item', 'cod_trab', 'nombre_trab', 'cod_prp', 'proceso', 'cod_act', 'actividad_operacion', 'tiempo'];
  dataSourceMano: MatTableDataSource<any>;

  @ViewChild("paginatorManoObra") paginatorMano: MatPaginator;
  @ViewChild(MatSort) sortMano: MatSort;

  displayedColumnsMat: string[] = ['acciones', 'item', 'nro_requerimiento', 'codigo', 'descripcion', 'um', 'cantidad', 'tipo', 'nro_doc'];
  dataSourceMat: MatTableDataSource<any>;

  @ViewChild("paginatorMateriales") paginatorMat: MatPaginator;
  @ViewChild(MatSort) sortMat: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string = this.buttonsName.BTN_STORE;

  loading$: Subscription;
  sendForm$: Subscription;

  liquidacion: LiquidacionFabricacion;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _plantaService: PlantaService
  ) {

    this.liquidacion = new LiquidacionFabricacion();
    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceMano = fillTable([], this.paginatorMano, this.sortMano);

    this.dataSourceProd = fillTable([], this.paginatorProd, this.sortProd);

    this.dataSourceMat = fillTable([], this.paginatorMat, this.sortMat);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.loadMaestros();
  }

  loadMaestros(): void {
    forkObs(
      this._plantaService.obtenerPlantas()
    ).then(data => {
      this.plantas = data[0];
      console.log('plantas', this.plantas)
      this.loaderData = false;
    }).catch(error => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })
  }

  agregarProducto(): void {
    const producto = new ProductoProcesado();

    this.liquidacion.detalle_producto_procesado.push(producto);

    this.dataSourceProd = fillTable(this.liquidacion.detalle_producto_procesado, this.paginatorProd, this.sortProd);
  }

  eliminarProducto(index): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const productos = this.liquidacion.detalle_producto_procesado.filter((_, i) => index != i );

        this.liquidacion.detalle_producto_procesado = productos;

        this.dataSourceProd = fillTable(this.liquidacion.detalle_producto_procesado, this.paginatorProd, this.sortProd);
      }

    })
  }

  agregarManoObra(): void {
    const manoObra = new ManoObra();

    this.liquidacion.detalle_mano_obra.push(manoObra);

    this.dataSourceMano = fillTable(this.liquidacion.detalle_mano_obra, this.paginatorMano, this.sortMano);
  }

  eliminarManoObra(index): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const manos = this.liquidacion.detalle_mano_obra.filter((_, i) => index != i );

        this.liquidacion.detalle_mano_obra = manos;

        this.dataSourceMano = fillTable(this.liquidacion.detalle_mano_obra, this.paginatorMano, this.sortMano);
      }

    })
  }

  agregarMaterial(): void {
    const material = new MaterialUtilizado();

    this.liquidacion.detalle_material_utilizado.push(material);

    this.dataSourceMat = fillTable(this.liquidacion.detalle_material_utilizado, this.paginatorMat, this.sortMat);
  }

  eliminarMaterial(index): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const materiales = this.liquidacion.detalle_material_utilizado.filter((_, i) => index != i );

        this.liquidacion.detalle_material_utilizado = materiales;

        this.dataSourceMat = fillTable(this.liquidacion.detalle_material_utilizado, this.paginatorMat, this.sortMat);
      }

    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarLiquidacion(f): this.editarLiquidacion(f);
  }

  registrarLiquidacion(f: NgForm): void {

  }

  editarLiquidacion(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.loading$])
  }

}
