export class LiquidacionFabricacion {
  planta: string;
  estado: string;
  detalle_producto_procesado: Array<ProductoProcesado>;
  detalle_mano_obra: Array<ManoObra>;
  detalle_material_utilizado: Array<MaterialUtilizado>;

  constructor() {
    this.detalle_producto_procesado = [];
    this.detalle_mano_obra = [];
    this.detalle_material_utilizado = [];
  }
}

export class ProductoProcesado {

}

export class ManoObra {}

export class MaterialUtilizado {}
