export class Seller {
  CIA_CODCIA: string;
  VDE_CODVDE: string;
  VDE_NOMVDE: string;
  VDE_DIRVDE: string;
  VDE_TELVDE: string;
  VDE_INDTIE: string;
  VDE_PORCOM: number;
  TMO_CODTMO: string;
  VDE_IMPCUO: number;
  S10_USUARIO: string;
  VDE_RUTFOT: string;
  VDE_INDSTA: string;
  VDE_CODUSE: string;
  VDE_FECUPD: Date;
  SEC_CODSEC: string;
  VDS_CODVDS: string;
  SUP_CODSUP: string;
  VDE_CORELE: string;
  VDE_CODANT: string;



  constructor(
  ){
    this.VDE_INDSTA = '1';
    this.VDE_FECUPD = new Date();
  }
}
