<p class="pavso-img-support">
  {{title}}
  <button type="button" mat-icon-button color="primary" [matMenuTriggerFor]="infoImage">
    <mat-icon>help_outline</mat-icon>
  </button>
  <mat-menu #infoImage="matMenu" yPosition="above">
    <div class="pavso-info-content">

    </div>
  </mat-menu>
</p>
<div class="col s12">
  <ngx-dropzone (change)="onSelect($event)" [accept]="
      'image/jpeg, image/png, image/jpg, image/png, image/webp'
    " [disabled]="loaderData" [maxFileSize]="maxSizeImage">
    <ngx-dropzone-label>{{requirement}}</ngx-dropzone-label>

    <ng-container *ngFor="let f of files">
      <ngx-dropzone-image-preview *ngIf="f.type != 'application/pdf'" ngProjectAs="ngx-dropzone-preview" [file]="f"
        [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
      </ngx-dropzone-image-preview>

      <!-- <ngx-dropzone-preview *ngIf="f.type == 'application/pdf'" [removable]="true" ngProjectAs="ngx-dropzone-preview"
        [file]="f" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
      </ngx-dropzone-preview> -->
    </ng-container>

    <mat-spinner diameter="40" *ngIf="loadUpload"></mat-spinner>
  </ngx-dropzone>
</div>
