export class Banco {
  BAN_CODBAN: string;
  BAN_NOMBRE: string;
  BAN_NOMABR: string;
  BAN_INDNBC: number;
  BAN_INDSTA: string;
  BAN_CODUSE: string;
  BAN_FECUPD: Date;

  constructor() {
    this.BAN_FECUPD = new Date();
    this.BAN_INDSTA = '1';
  }
}
