import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MaterialModule } from "src/app/material.module";
import { ComprasComponent } from "./compras.component";
import { carpetaComponentCompras } from "./pages/carpeta";
import { informesComponentCompras } from "./pages/informes";
import { maestrosComprasComponent, maestrosComprasModules } from "./pages/maestros";
import { operacionesComponentCompras } from "./pages/operaciones";
import { PanelComprasComponent } from "./pages/panel/panel-compras.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { componentsAdmin } from "../../components";
import { GeneralComponentsModule } from "../../components/general-components.module";
import { TotalContableComponent } from "./pages/operaciones/registro-compra";

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    ComprasComponent,
    PanelComprasComponent,
    ...carpetaComponentCompras,
    ...informesComponentCompras,
    ...maestrosComprasComponent,
    ...operacionesComponentCompras,
  ],
  exports: [
    TotalContableComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    GeneralComponentsModule,
    NgxMatSelectSearchModule,
    NgxMaskModule.forRoot(),
    ...maestrosComprasModules,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class ComprasModule {}
