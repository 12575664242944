import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatRipple } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { HistorialPreciosDialog } from "@shared/components/dialogs/ventas/historial-precios/historial-precios.dialog";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithDash } from "@utils/formats/date.format";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { Cliente, Factura, Order } from "src/app/models/ventas";
import { CuotasCCU, DescuentoDDD, DetalleDcd } from "src/app/models/ventas/factura";
import { AlmacenService, AuthenticationService, ComprasService, ConfigurationService, ContabilidadService, FacturaService, VentasService } from "src/app/services";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";
import { PedidoService } from "src/app/services/api/ventas/operaciones/pedido.service";
import { PromocionVentaService } from "src/app/services/api/ventas/precio-descuento-promociones/promocion-venta.service";
import { FacturaBottomSheet } from "../factura-bottom-sheet/factura-bottom-sheet";
import { UnidadMedidaService } from "src/app/services/api/almacen/configuracion/unidad-medida.service";
import { MatSelect } from "@angular/material/select";
import { generarPdfVoucher } from "@utils/pdfmaker/builder/ventas.pdfmaker";
import { IParam } from "src/app/services/api/utilities/params";
import { ConfiguracionTecnicaVentas } from "src/app/models/ventas/configuracion-tecnica-ventas";
import { TiendaService } from "src/app/services/api/sistema/tienda.service";
import { BINARY_TYPE } from "@data/interfaces/binary.type";
import { CondicionPagoService } from "src/app/services/api/compras/maestros/condicion-pago.service";

@Component({
  selector: 'factura-form',
  templateUrl: './factura-form.component.html',
  styleUrls: ['./factura-form.component.scss']
})
export class FacturaFormComponent {

  descuento: DescuentoDDD;

  selected: DetalleDcd;
  loaderXml: boolean;

  selectRow(row: DetalleDcd) {
    this.selected = row;
    this.dataSourceDescuento = fillTable(this.selected.descuento_ddd, this.paginatorDescuento, this.sortDescuento);

  }

  selectedDescuento: any;

  selectRowDescuento(row: any) {
    this.selectedDescuento = row;
  }

  selectedCuota: any;

  selectRowCuota(row: any) {
    this.selectedCuota = row;
  }

  vendedores: any[] = [];
  transportistas: any[] = [];
  agencias: any[] = [];
  condiciones: any[] = [];
  zonas: any[] = [];
  productos: any[] = [];
  tiposDocumento: any[] = [];

  loaderDocumentos: boolean = false;
  loaderDiasEntrega: boolean = false;
  loaderProductos: boolean = false;
  loaderDescuentos: boolean = false;
  loaderStock: boolean = false;

  displayedColumns: string[] = ['PCD_CORPCD', 'PRD_CODPRD', 'sec', 'PRM_CODPRM', 'UME_CODVEN', 'DCD_CANDCD', 'PCD_PREUNI', 'PCD_PRUIGV', 'PCD_IMPBRU', 'PCD_PORDES', 'PCD_IMPDES', 'PCD_VALVTA', 'PCD_IMPIGV', 'PCD_IMPTOT', 'PCD_GLOPCD'];
  dataSource: MatTableDataSource<any>;

  @ViewChild('paginatorDetalle') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsDescuento: string[] = ['cod_desc', 'desc', 'porc_desc', 'importe_desc'];
  dataSourceDescuento: MatTableDataSource<any>;

  @ViewChild('paginatorDescuento') paginatorDescuento: MatPaginator;
  @ViewChild(MatSort) sortDescuento: MatSort;

  displayedColumnsAnticipos: string[] = ['acciones', 'td', 'nDocumento', 'fecha', 'mo', 'importe', 'aplicado', 'cancela', 'codCtaCte'];
  dataSourceAnticipos: MatTableDataSource<any>;

  @ViewChild('paginatorAnticipos') paginatorAnticipos: MatPaginator;
  @ViewChild(MatSort) sortAnticipos: MatSort;

  displayedColumnsCobranzas: string[] = ['acciones', 'fecha', 'recibo', 'importe', 'planilla', 'cobrador', 'vendedor', 'deposito'];
  dataSourceCobranzas: MatTableDataSource<any>;

  @ViewChild('paginatorCobranzas') paginatorCobranzas: MatPaginator;
  @ViewChild(MatSort) sortCobranzas: MatSort;

  columnsDocumentos: string[] = ['campo1', 'campo2', 'campo3'];
  documentos: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDocumento: MatPaginator;
  @ViewChild(MatSort) sortDocumento: MatSort;

  displayedColumnsCuotas: string[] = ['CCU_CORCCU', 'CCU_FECSUG', 'CCU_IMPCCU'];
  dataSourceCuotas: MatTableDataSource<any>;

  @ViewChild('paginatorCuotas') paginatorCuotas: MatPaginator;
  @ViewChild(MatSort) sortCuotas: MatSort;

  factura: Factura;

  loaderData: boolean;
  loaderFormato: boolean;
  loaderReg: boolean;

  canales: any = [];
  motivos: any = [];
  companias: any = [];
  monedas: any = [];
  zonasVenta: any = [];
  tiposPrecio: any = [];
  clientes: any = [];
  lugaresDespacho: any = [];
  diasEntrega: any = [];
  centros: any = [];
  formasCalculoItem: any = [];
  almacenes: any = [];
  sucursales: any = [];
  unidadesMedida: any = [];
  pedidos: any = [];
  promociones: any = [];
  descuentos: any = [];
  tiposRetencion: any = [];
  tiposImpRetencion: any = [];
  tempTiposImpRetencion: any = [];
  tiposImpuestoRetencion: any = [];
  tiposOperacionRetencion: any = [];
  series: any = [];

  cod_prod: String;
  prod: String;
  fisico: Number;
  disponible: Number;

  @ViewChild(MatRipple) ripple: MatRipple;
  direccion: string = "";

  usuario: any;
  fecha: Date = new Date();

  motivos$: Subscription;
  tipoCambio$: Subscription;
  consultaStock$: Subscription;
  centroCosto$: Subscription;
  diasEntrega$: Subscription;
  lugarDespacho$: Subscription;
  sucursal$: Subscription;
  formaCalcula$: Subscription;
  tiposPrecio$: Subscription;
  clientes$: Subscription;
  sendForm$: Subscription;
  factura$: Subscription;
  tiposDocumento$: Subscription;
  detalleCliente$: Subscription;
  vendedores$: Subscription;
  condiciones$: Subscription;
  zonas$: Subscription;
  facturaXML$: Subscription;
  transportistas$: Subscription;
  almacenes$: Subscription;
  productos$: Subscription;
  UnidadesMedida$: Subscription;
  pedidos$: Subscription;
  pedido$: Subscription;
  promociones$: Subscription;
  descuentos$: Subscription;
  tiposRetencion$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  cotizacion$: Subscription;
  serie$: Subscription;
  establecimientoStore$: Subscription;
  generaNroDocumento$: Subscription;
  series$: Subscription;
  establecimiento$: Subscription;
  tiposOperacionRetencion$: Subscription;
  config$: Subscription;
  formato$: Subscription;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string = '';

  minDate: Date;
  maxDate: Date

  tabSeleccionado: number;

  esPercepcion: boolean;

  tituloTasa: string;

  establecimientoSeleccionado: string;
  configuracionTecnica: ConfiguracionTecnicaVentas;

  @ViewChild('msAlmacen') mySelect: MatSelect;

  establecimiento: string;
  logo: string;
  dircia: string;

  indCredido: BINARY_TYPE = 0;

  constructor(
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _pedidoService: PedidoService,
    private readonly _tiendaService: TiendaService,
    private _facturaService: FacturaService,
    private _clienteService: ClienteService,
    private _productoService: ProductoService,
    private _comprasService: ComprasService,
    private _condicionPagoService: CondicionPagoService,
    private _contabilidadService: ContabilidadService,
    private _almacenService: AlmacenService,
    private _unidadMedidaService: UnidadMedidaService,
    private _configurationService: ConfigurationService,
    private _authenticationService: AuthenticationService,
    private _snackBarService: SnackBarService,
    private _promocionVentaService: PromocionVentaService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private store: Store<PavsoState>
  ) {

    this.configuracionTecnica = new ConfiguracionTecnicaVentas();

    this.factura = new Factura();

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;

      this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
      this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

      const now = new Date();
      const mes = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
      const anio = now.getFullYear().toString();

      this.factura.CCO_FECEMI = this.maxDate;
      this.factura.CCO_FECVEN = this.maxDate;

      console.log('ingreso a fecha =', mes == this.month && anio == this.year)


      if (mes == this.month && anio == this.year) {
        this.factura.CCO_FECEMI = new Date();
        this.factura.CCO_FECVEN = new Date();

        this.seleccionarFecha();

      }
      // this.factura.fec = this.maxDate;
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
      this.dircia = state.dircia;
    })

    this.establecimientoStore$ = this.store.select('establishment').subscribe(state => {
      this.establecimientoSeleccionado = state.code;
      this.factura.SUC_CODSUC = '01';
      this.factura.docu_dcc.SUC_CODSUC = '01';
      this.factura.docu_dcc.TIE_CODTIE = !state.code || state.code == '' ? '01' : state.code;

    })

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);
    this.dataSourceAnticipos = fillTable([], this.paginatorAnticipos, this.sortDescuento);
    this.dataSourceCobranzas = fillTable([], this.paginatorCobranzas, this.sortDescuento);
    this.dataSourceCuotas = fillTable([], this.paginatorCuotas, this.sortCuotas);

    this.documentos = fillTable([], this.paginatorDocumento, this.sortDocumento);

    this.factura.ANO_CODANO = this.year;
    this.factura.MES_CODMES = this.month;
    this.factura.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

    this.factura.CCO_CODUSE = this._authenticationService.getUsuarioSistema();
    // this.factura.SUC_CODSUC = this.establecimientoSeleccionado;

  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    });

    this.seleccionarFecha()

  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
    })

    // this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;
    this.btnName = this.uid == '0' || this.uid.includes('pedido') ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;


    this.loadMaestros();
  }

  loadMaestros(): void {

    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes.filter(item => item.cli_indcli == 1);
        console.log('clientes', clientes)

        this.motivos$ = this._almacenService.obtenerMotivos().subscribe(
          motivos => {
            this.motivos = motivos;

            this.vendedores$ = this._ventasService.obtenerVendedores().subscribe(
              vendedores => {
                this.vendedores = vendedores;

                this.condiciones$ = this._condicionPagoService.obtenerCondicionesPago().subscribe(
                  condiciones => {
                    console.log('condiciones', condiciones)
                    this.condiciones = condiciones;

                    this.zonas$ = this._ventasService.obtenerZonasVenta().subscribe(
                      zonas => {
                        this.zonas = zonas;

                        this.tiposPrecio$ = this._ventasService.obtenerTiposPrecio().subscribe(
                          tiposPrecio => {
                            this.tiposPrecio = tiposPrecio;

                            this.transportistas$ = this._almacenService.obtenerTransportistas().subscribe(
                              transportistas => {
                                this.transportistas = transportistas;
                                this.agencias = transportistas;

                                this.formaCalcula$ = this._ventasService.obtenerFormaCalculaItem().subscribe(
                                  formaCalcula => {
                                    this.formasCalculoItem = formaCalcula;

                                    this.almacenes$ = this._almacenService.obtenerAlamacenes().subscribe(
                                      almacenes => {
                                        (!almacenes)
                                          ? this.almacenes = []
                                          : this.almacenes = almacenes;
                                        if (this.establecimientoSeleccionado && this.establecimientoSeleccionado.trim() != '') {
                                          this.almacenes = this.almacenes.filter(item => item.TIE_CODTIE == this.establecimientoSeleccionado);
                                        }
                                        this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.factura.CCO_FECREG)).subscribe(
                                          tipoCambio => {
                                            console.log('tipo de cambio', tipoCambio)
                                            this.factura.CCO_TIPCAM = tipoCambio[0].tipcam;
                                            localStorage.setItem("tipo-cambio", this.factura.CCO_TIPCAM.toFixed(4));

                                            this.centroCosto$ = this._contabilidadService.listarCentroCostos().subscribe(
                                              centrosCosto => {
                                                this.centros = centrosCosto;

                                                this.productos$ = this._productoService.obtenerProductos().subscribe(
                                                  productos => {
                                                    this.productos = productos;

                                                    this.UnidadesMedida$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                                                      unidades => {
                                                        this.unidadesMedida = unidades;
                                                        let params: Array<IParam> = [
                                                          {
                                                            param: 'anio',
                                                            value: this.year
                                                          },
                                                          {
                                                            param: 'mes',
                                                            value: this.month
                                                          },
                                                        ];
                                                        this.pedidos$ = this._pedidoService.obtenerPedidos('01', { params }).subscribe(
                                                          pedidos => {
                                                            console.log('pedidos', pedidos)
                                                            this.pedidos = pedidos;

                                                            this.promociones$ = this._promocionVentaService.obtenerPromocionesVenta().subscribe(
                                                              promociones => {
                                                                this.promociones = promociones;

                                                                this.descuentos$ = this._ventasService.obtenerDescuentos().subscribe(
                                                                  descuentos => {
                                                                    this.descuentos = descuentos;

                                                                    this.tiposDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
                                                                      tiposDocumento => {
                                                                        this.tiposDocumento = tiposDocumento;
                                                                        console.log('tipos de documento', this.tiposDocumento)

                                                                        this.tiposRetencion$ = this._comprasService.listarTiposRetencion().subscribe(
                                                                          tiposRetencion => {
                                                                            this.tiposRetencion = tiposRetencion.filter(item => item.IRI_CODIRI != '03' && item.IRI_CODIRI != '00');
                                                                            console.log('tipos de retencion', this.tiposRetencion)

                                                                            this.tiposPrecio$ = this._ventasService.listaDePrecios(this.factura.docu_dcc.TLP_CODTLP).subscribe(
                                                                              response => {

                                                                                this.tiposOperacionRetencion$ = this._comprasService.obtenerTiposImpuestoRetencionPorFechaEmision(this.factura.CCO_FECEMI).subscribe(
                                                                                  tiposImpuestoRetencion => {
                                                                                    console.log('tiposImpuestoRetencion', tiposImpuestoRetencion)
                                                                                    this.tiposImpRetencion = tiposImpuestoRetencion;
                                                                                    this.tempTiposImpRetencion = tiposImpuestoRetencion;


                                                                                    this.series$ = this._almacenService.listarSerieDocumentos(this.factura.TDO_CODTDO).subscribe(
                                                                                      async series => {

                                                                                        this.series = series;
                                                                                        if (this.series && this.series.length > 0) {
                                                                                          this.factura.serie = this.series[0].sdo_codsdo;
                                                                                        }
                                                                                        console.log('series', this.series)

                                                                                        const tienda = await this._tiendaService.obtenerTienda(this.factura.docu_dcc.TIE_CODTIE).toPromise()
                                                                                        console.log('tienda===', tienda)
                                                                                        if (tienda) {
                                                                                          this.factura.docu_dcc.DCC_INDCAL = tienda.tie_indcal;
                                                                                          this.factura.serie = tienda.sdo_codfac;
                                                                                        }

                                                                                        this.generaNroDocumento$ = this._ventasService.obtenerNroDocFactura(this.factura.TDO_CODTDO, this.factura.serie).subscribe(
                                                                                          nroDoc => {
                                                                                            console.log('nroDoc', nroDoc)
                                                                                            this.factura.CCO_NUMDOC = nroDoc[0].nro_doc.trim();


                                                                                            if (this.uid != '0' && !this.uid.includes('pedido')) {
                                                                                              this.obtenerFactura()
                                                                                              return;
                                                                                            }

                                                                                            if (this.uid.includes('pedido')) {
                                                                                              this.obtenerFacturaDesdePedido();
                                                                                            }

                                                                                            // this.seleccionDeLista(this.factura.docu_dcc.TLP_CODTLP)
                                                                                            this.loaderData = false;

                                                                                            console.log('LISTA PRECIO', response)
                                                                                            // this.factura.LPC_CODLPC = response.length > 0 ? response[0].LPC_CODLPC : null;
                                                                                            const listas = response.reverse();
                                                                                            this.factura.docu_dcc.LPC_CODLPC = listas.length > 0 ? listas[0].LPC_CODLPC : null;


                                                                                          },
                                                                                          error => {
                                                                                            this._snackBarService.showError(error.error.msg, 'OK');
                                                                                            this.loaderReg = false;
                                                                                          }
                                                                                        )
                                                                                      },
                                                                                      error => {
                                                                                        this._snackBarService.showError(error.error.msg, 'OK');
                                                                                        this.loaderReg = false;
                                                                                      }
                                                                                    )
                                                                                  },
                                                                                  error => {
                                                                                    this._snackBarService.showError(error.error.msg, 'OK');
                                                                                    this.loaderReg = false;
                                                                                  }
                                                                                )

                                                                              },
                                                                              error => {
                                                                                this._snackBarService.showError(error.error.msg, 'OK');
                                                                                this.loaderReg = false;
                                                                              }
                                                                            )

                                                                          },
                                                                          error => {
                                                                            this._snackBarService.showError('Error al obtener tipos de retencion', 'Ok');
                                                                            this.loaderData = false;
                                                                          }
                                                                        )
                                                                      },
                                                                      error => {
                                                                        this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
                                                                        this.loaderData = false;
                                                                      }
                                                                    )
                                                                  },
                                                                  error => {
                                                                    this._snackBarService.showError('Error al obtener descuentos', 'Ok');
                                                                    this.loaderData = false;
                                                                  }
                                                                )
                                                              },
                                                              error => {
                                                                this._snackBarService.showError('Error al obtener promociones', 'Ok');
                                                                this.loaderData = false;
                                                              }
                                                            )
                                                          },
                                                          error => {
                                                            this._snackBarService.showError('Error al obtener pedidos', 'Ok');
                                                            this.loaderData = false;
                                                          }
                                                        )
                                                      },
                                                      error => {
                                                        this._snackBarService.showError('Error al obtener unidades de medida', 'Ok');
                                                        this.loaderData = false;
                                                      }
                                                    )
                                                  },
                                                  error => {
                                                    this._snackBarService.showError('Error al obtener productos', 'Ok');
                                                    this.loaderData = false;
                                                  }
                                                )
                                              },
                                              error => {
                                                this._snackBarService.showError('Error al obtener centros de costo', 'Ok');
                                                this.loaderData = false;
                                              }
                                            )
                                          },
                                          error => {
                                            this._snackBarService.showError('Error al obtener tipo de cambio', 'Ok');
                                            this.loaderData = false;
                                          }
                                        )
                                      },
                                      error => {
                                        this._snackBarService.showError('Error al obtener almacenes', 'Ok');
                                        this.loaderData = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener formas de calculo de item', 'Ok');
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener transportistas', 'Ok');
                                this.loaderData = false;
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener tipos de precio', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener zonas de venta', 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener condiciones de pago', 'Ok');
                    this.loaderData = false;

                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener vendedores', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError("Error al obtener motivos", 'Ok');
            this.loaderData = false;

          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener clientes', 'Ok');
        this.loaderData = false;

      }
    )

  }

  obtenerFactura(): void {

    this.factura$ = this._facturaService.obtenerFactura(this.uid).subscribe(
      factura => {

        console.log('-====== FACTURA ======', factura)
        this.factura = factura;
        this.factura.serie = this.factura.CCO_NUMDOC.substring(0, 4);

        this.seleccionarCliente();
        this.seleccionarCondicionPago();

        this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort);

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener factura', 'Ok');
        this.loaderData = false;
      }
    )

  }

  seleccionarCondicionPago(): void {

    const condicion = this.condiciones.find(item => item.CPA_CODCPA.trim() == this.factura.CPA_CODCPA.trim());

    console.log('condicion', condicion)
    this.indCredido = condicion.CPA_INDCRE;

    if (this.indCredido == 0) {
      this.factura.cuotas_ccu = [];
      this.dataSourceCuotas = fillTable(this.factura.cuotas_ccu, this.paginatorCuotas, this.sortCuotas);
    }

  }

  cambiarTipoImpuestoRetencion(): void {
    if (this.factura.CCO_IMPAFE > 0) {
      const tipoImpuesto = this.tiposImpRetencion.find(item => this.factura.docu_dcc.tim_codtim == item.codigo);
      this.factura.docu_dcc.dcc_tasdet = tipoImpuesto.porc_det;
      if (this.factura.TMO_CODTMO == 'DO') {
        this.factura.docu_dcc.dcc_impdet = ((Number(this.factura.CCO_IMPDOC) * this.factura.CCO_TIPCAM) * this.factura.docu_dcc.dcc_tasdet / 100);
        return;
      }
      this.factura.docu_dcc.dcc_impdet = (Number(this.factura.CCO_IMPDOC) * this.factura.docu_dcc.dcc_tasdet / 100);
      return;
    }

  }

  seleccionarFecha(): void {

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.factura.CCO_FECEMI)).subscribe(
      response => {
        this.factura.CCO_TIPCAM = response[0].tipcam;
        localStorage.setItem("tipo-cambio", this.factura.CCO_TIPCAM.toFixed(4));
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  validarFormulario(): boolean {

    if (!this.factura.CPA_CODCPA) {
      this._snackBarService.showError('Seleccionar la condición de pago para la factura.', 'OK');
      return false;
    }

    if (!this.factura.CLI_CODCLI) {
      this._snackBarService.showError('Seleccionar la cliente para la factura.', 'OK');
      return false;
    }

    if (!(this.factura.CCO_TIPCAM > 0)) {
      this._snackBarService.showError('Establecer un tipo de cambio para la factura.', 'OK');
      return false;
    }

    if (!this.factura.docu_dcc.LPC_CODLPC) {
      this._snackBarService.showError('Seleccionar un tipo de lista precio y número de lista', 'OK');
      return false;
    }

    if (this.factura.detalle_dcd.length == 0) {
      this._snackBarService.showError('Ingresar al menos un producto', 'OK');
      return false;
    }

    if (this.factura.generaParteAlmacen) {
      if (!this.factura.docu_dcc.ALM_CODALM) {
        this._snackBarService.showError('Seleccionar Almacén', 'OK');
        return false;
      }
    }

    return true;
  }

  generarNumeroDocumento(): void {
    if (!this.factura.TDO_CODTDO) {
      this._snackBarService.showError('Seleccionar un tipo de documento', 'OK');
      return;
    }

    if (!this.factura.serie) {
      this._snackBarService.showError('Ingresar una serie', 'OK');
      return;
    }

    this.generaNroDocumento$ = this._ventasService.obtenerNroDocFactura(this.factura.TDO_CODTDO, this.factura.serie).subscribe(
      nroDoc => {
        console.log('nroDoc', nroDoc)
        this.factura.CCO_NUMDOC = nroDoc[0].nro_doc.trim();
      }
    )
  }

  autocompletarNroDoc(): void {
    this.factura.detalle_dcd.forEach(item => {
      // item.cor = this.factura.cco_numdoc;
    })
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;

    if (!this.validarFormulario()) {
      this.loaderReg = false;
      return;
    }

    this.uid == '0' ? this.registrarFactura(f) : this.editarFactura(f);

  }

  generarXML(factura: Factura): void {
    console.log('generarxml')
    this.loaderXml = true;

    this.factura$ = this._facturaService.obtenerFactura(factura.CCO_CODCCO).subscribe(
      factura => {

        factura['CLI_NOMCLI'] = this.clientes.find(item => item.cli_codcli == factura.CLI_CODCLI).cli_nomcli;//row.cliente.cli_nomcli

        console.log('factura', factura)
        const payloadFactura = this._facturaService.generarPayload(factura)

        this.facturaXML$ = this._facturaService.enviarAValidacionSUNAT(payloadFactura).subscribe(
          xml => {
            console.log('xml', xml)
            const blob = new Blob([xml], { type: 'application/xml' });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = payloadFactura.NumeroRUC + "-" + payloadFactura.TipoDocumento + "-" + payloadFactura.NumeroDocumento;
            a.click();
            window.URL.revokeObjectURL(url);

            this.loaderXml = false;
          },
          error => {
            this._snackBarService.showError('Error al generar XML', 'Ok');
            this.loaderXml = false;
          }
        );
      },
      error => {
        this._snackBarService.showError('Error al obtener factura', 'Ok');
        this.loaderXml = false;
      }
    )

  }

  establecerNroDoc(event): void {

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    let numero = zeroPad(this.factura.CCO_NUMDOC.substring(5, this.factura.CCO_NUMDOC.length + 1), 8); // "000005"

    this.factura.CCO_NUMDOC = this.factura.CCO_NUMDOC.substring(0, 5) + numero;

  }

  registrarFactura(f: NgForm): void {

    console.log('factura', this.factura);

    // if(!validarPeriodo(this.factura.PCC_FECDOC, this.year, this.month)) {
    //   this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK');
    //   this.loaderReg = false;
    //   return;
    // }

    this.generarCorrelativo();

    // this.factura.LPC_CODLPC = this.factura.LPC_CODLPC.toString();
    // this.factura.LPC_CODLPC.replace(/"/g, "'");

    this.sendForm$ = this._facturaService.registrarFactura(this.factura).subscribe(
      response => {
        console.log(response)
        this.factura.CCO_CODCCO = response.CCO_CODCCO;
        this._dialogService.openDialog(SuccessComponent, 'Factura Registrada', '350px', '', '');
        this.loaderReg = false;

        this.generarXML(this.factura);

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )

  }

  editarFactura(f: NgForm): void {
    console.log('factura', this.factura)
    // delete this.factura.cli_numruc;

    this.generarCorrelativo();

    this.sendForm$ = this._facturaService.editarFactura(this.factura).subscribe(
      response => {
        console.log('response', response)
        this._dialogService.openDialog(SuccessComponent, 'Factura Actualizada', '350px', '', '');
        this.loaderReg = false;

      },
      error => {
        this._snackBarService.showError('Error al actualizar factura', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  blurPrecio(row: DetalleDcd): void {
    console.log('precio', row.DCD_PREUNI.toFixed(2))
    row.DCD_PREUNI = Number(row.DCD_PREUNI.toFixed(2))
  }

  generarCorrelativo(): void {
    this.factura.detalle_dcd.forEach((element, index) => {

      element.DCD_CORDCD = (index + 1).toString();
      element.DCD_CANVEN = element.DCD_CANDCD;
      element.DCD_CODUSE = (this._authenticationService.getIdentity()).usuariosistema;
      // element.PCD_PRELIS = element.DCD_PRUIGV;

      if (element.DCD_CORDCD.length == 1) element.DCD_CORDCD = "00" + element.DCD_CORDCD
      if (element.DCD_CORDCD.length == 2) element.DCD_CORDCD = "0" + element.DCD_CORDCD

    });
  }

  seleccionDeLista(tipo_lista): void {
    this.tiposPrecio$ = this._ventasService.listaDePrecios(tipo_lista).subscribe(
      response => {
        console.log('LISTA PRECIO', response)
        // this.factura.LPC_CODLPC = response.length > 0 ? response[0].LPC_CODLPC : null;
        const listas = response.reverse();
        this.factura.docu_dcc.LPC_CODLPC = listas.length > 0 ? listas[0].LPC_CODLPC : null;
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  agregarProducto(): void {

    if (!this.factura.docu_dcc.TLP_CODTLP) {
      this._dialogService.openDialog(ConfirmationComponent, '¿Continuar sin seleccionar una lista de precio?', '', '', '').subscribe(result => {

        if (result) {

          const producto = new DetalleDcd();

          producto.DCD_IMPBRU = producto.DCD_CANDCD * producto.DCD_PREUNI;
          producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;
          producto.DCD_IMPTOT = producto.DCD_CANDCD * producto.DCD_PRUIGV - producto.DCD_IMPDES;
          producto.DCD_IMPIGV = producto.DCD_IMPTOT - producto.DCD_VALVTA;

          this.factura.detalle_dcd.push(producto)

          this.calcularTotales();

          this.factura.detalle_dcd.forEach((element, key) => {
            element.DCD_CORDCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
          })

          this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort);

        } else {
          this.tabSeleccionado = 2;
        }

      })

      return;
    }

    const producto = new DetalleDcd();

    producto.DCD_IMPBRU = producto.DCD_CANDCD * producto.DCD_PREUNI;
    producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;
    producto.DCD_IMPTOT = producto.DCD_CANDCD * producto.DCD_PRUIGV - producto.DCD_IMPDES;
    producto.DCD_IMPIGV = producto.DCD_IMPTOT - producto.DCD_VALVTA;

    this.factura.detalle_dcd.push(producto)

    this.calcularTotales();

    this.factura.detalle_dcd.forEach((element, key) => {
      element.DCD_CORDCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort);

  }

  cambiarTipoRetencion(tipo): void {

    console.log(tipo.value)

    if (tipo.value == "02") {
      this.tituloTasa = "Retención";
      this.tiposImpRetencion = this.tempTiposImpRetencion.filter(item => item.codigo == "RET")
      console.log('tiposImpRetencion', this.tiposImpRetencion)
      console.log('tiposImpRetencion', this.tiposImpRetencion)
      // this.registro.dpc_tasdet = 0;
      // this.registro.dpc_impdet = 0;
      // this.registro.cco_impper = 0;
      // this.registro.dpc_depdet = 0;
      // this.registro.dpc_fecdet = "";
      // this.registro.tim_codtim = "RET";
    } else {
      this.esPercepcion = false;
      this.tiposImpRetencion = this.tempTiposImpRetencion.filter(item => item.codigo != "PER");
      this.tiposImpRetencion = this.tempTiposImpRetencion.filter(item => item.tim_inddet == 1);
      console.log('tiposImpRetencion', this.tiposImpRetencion)
      this.tituloTasa = "Detracción";
      // this.registro.dpc_tasdet = 0;
      // this.registro.dpc_impdet = 0;
      // this.registro.cco_impper = 0;
      // this.registro.dpc_depdet = 0;
      // this.registro.dpc_fecdet = "";
      // this.tipoImpuestoRetElement.open();
      // this.tipoImpuestoRetElement.focus();
    }
  }

  consultarPrecioProducto(producto: DetalleDcd): void {
    const now = new Date();

    const fecha = formatDateWithDash(now);

    if (!this.factura) {
      this._snackBarService.showError('Seleccionar lista', 'OK');
    }

    if (!this.factura) {
      this._snackBarService.showError('Establecer Nro de lista', 'OK');
    }

    this.tiposPrecio$ = this._ventasService.listaDePreciosProducto(producto.PRD_CODPRD, this.factura.TMO_CODTMO, this.factura.CLI_CODCLI, this.factura.docu_dcc.TLP_CODTLP, this.factura.docu_dcc.LPC_CODLPC, fecha).subscribe(
      response => {
        console.log('precio producto', response)
        if (this.factura.TMO_CODTMO == "SO") {
          producto.DCD_PREUNI = response[0].pre_prenac;
          producto.DCD_PRUIGV = response[0].pre_vtanac;
        } else {
          producto.DCD_PREUNI = response[0].pre_predol;
          producto.DCD_PRUIGV = response[0].pre_vtadol;
        }

        producto.DCD_IMPBRU = producto.DCD_CANDCD * producto.DCD_PREUNI;

        producto.DCD_IMPDES = producto.DCD_IMPBRU * (producto.DCD_PORDES / 100)

        producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;

        producto.DCD_IMPIGV = this.factura.docu_dcc.DCC_INDCAL == '0' ? 0 : producto.DCD_VALVTA * this.factura.CCO_TASIGV / 100;

        producto.DCD_IMPTOT = producto.DCD_IMPIGV + producto.DCD_VALVTA;

        this.calcularTotales();

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  eliminarProducto(): void {

    let row = this.factura.detalle_dcd.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.factura.detalle_dcd.length > 0) row = this.factura.detalle_dcd[0];

    let details = [];
    this.factura.detalle_dcd.forEach(element => {
      if (element.DCD_CORDCD != row.DCD_CORDCD) {
        details.push(element)
      }
    });

    this.factura.detalle_dcd = details;

    this.factura.detalle_dcd.forEach((element, key) => {
      element.DCD_CORDCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;

  }

  consultarStock(): void {

  }

  insertarProducto(): void {

    if (!this.factura) {
      this._dialogService.openDialog(ConfirmationComponent, '¿Continuar sin seleccionar una lista de precio?', '', '', '').subscribe(result => {
        const producto = new DetalleDcd();

        producto.DCD_IMPBRU = producto.DCD_CANDCD * producto.DCD_PREUNI;
        producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;
        producto.DCD_IMPTOT = producto.DCD_CANDCD * producto.DCD_PRUIGV - producto.DCD_IMPDES;
        producto.DCD_IMPIGV = producto.DCD_IMPTOT - producto.DCD_VALVTA;

        let i = 0;

        if (this.selected) {
          this.factura.detalle_dcd.forEach((item, index) => {
            if (item.DCD_CORDCD == this.selected.DCD_CORDCD) i = index;
          })

          this.factura.detalle_dcd.splice(i, 0, producto);
        } else {
          this.factura.detalle_dcd.push(producto);
          this.factura.detalle_dcd = this.factura.detalle_dcd.reverse();
        }

        this.factura.detalle_dcd.forEach((element, key) => {
          console.log('ids', key)
          element.DCD_CORDCD = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
        })

        console.log('detalle', this.factura.detalle_dcd)

        this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort);
      });

      return;
    }
    const producto = new DetalleDcd();

    producto.DCD_IMPBRU = producto.DCD_CANDCD * producto.DCD_PREUNI;
    producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;
    producto.DCD_IMPTOT = producto.DCD_CANDCD * producto.DCD_PRUIGV - producto.DCD_IMPDES;
    producto.DCD_IMPIGV = producto.DCD_IMPTOT - producto.DCD_VALVTA;

    let i = 0;

    if (this.selected) {
      this.factura.detalle_dcd.forEach((item, index) => {
        if (item.DCD_CORDCD == this.selected.DCD_CORDCD) i = index;
      })

      this.factura.detalle_dcd.splice(i, 0, producto);
    } else {
      this.factura.detalle_dcd.push(producto);
      this.factura.detalle_dcd = this.factura.detalle_dcd.reverse();
    }

    this.factura.detalle_dcd.forEach((element, key) => {
      console.log('ids', key)
      element.DCD_CORDCD = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    console.log('detalle', this.factura.detalle_dcd)

    this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort);
  }

  agregarCuota(): void {

    const cuota = new CuotasCCU();
    cuota.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    cuota.CCU_CODUSE = this._configurationService.obtenerIdUsuario();

    this.factura.cuotas_ccu.push(cuota);

    this.factura.cuotas_ccu.forEach((element, key) => {
      console.log('ids', key)
      element.CCU_CORCCU = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSourceCuotas = fillTable(this.factura.cuotas_ccu, this.paginatorCuotas, this.sortCuotas);

  }

  insertarCuota(): void {

    const cuota = new CuotasCCU();
    cuota.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    cuota.CCU_CODUSE = this._configurationService.obtenerIdUsuario();

    let i = 0;

    if (this.selectedCuota) {
      this.factura.cuotas_ccu.forEach((item, index) => {
        if (item.CCU_CORCCU == this.selectedCuota.CCU_CORCCU) i = index;
      })

      this.factura.cuotas_ccu.splice(i, 0, cuota);
    } else {
      this.factura.cuotas_ccu.push(cuota);
      this.factura.cuotas_ccu = this.factura.cuotas_ccu.reverse();
    }

    const cuotas = this.factura.cuotas_ccu.map((element, key) => ({
      ...element,
      CCU_CORCCU: element.CCU_CORCCU = (key > 9) ? `0${key + 1}` : `00${key + 1}`
    }))

    console.log('detalle cuotas', this.factura.cuotas_ccu)

    this.dataSourceCuotas = fillTable(cuotas, this.paginatorCuotas, this.sortCuotas);

  }

  eliminarCuota(): void {
    let row = this.factura.cuotas_ccu.find(item => item == this.selectedCuota);

    console.log('fila seleccionada', row);

    if (!row && this.factura.cuotas_ccu.length > 0) row = this.factura.cuotas_ccu[0];

    let details = [];
    this.factura.cuotas_ccu.forEach(element => {
      if (element.CCU_CORCCU != row.CCU_CORCCU) {
        details.push(element)
      }
    });

    this.factura.cuotas_ccu = details;

    this.factura.cuotas_ccu.forEach((element, key) => {
      element.CCU_CORCCU = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSourceCuotas = fillTable(this.factura.cuotas_ccu, this.paginatorCuotas, this.sortCuotas);

    this.selectedCuota = null;
  }

  agregarDescuento(): void {

    const producto = this.factura.detalle_dcd.find(item => item == this.selected);
    console.log('producto', producto)
    if (!producto) {
      this._snackBarService.showError('Seleccionar un producto', 'Ok');
      return;
    }

    console.log('precio', Number(producto.DCD_PREUNI))
    if (!(Number(producto.DCD_PREUNI) > 0)) {
      this._snackBarService.showError('Establecer un precio del producto', 'Ok');
      return;
    }

    const descuento = new DescuentoDDD();

    producto.descuento_ddd = producto.descuento_ddd ? producto.descuento_ddd : [];
    producto.descuento_ddd.push(descuento);

    this.dataSourceDescuento = fillTable(producto.descuento_ddd, this.paginatorDescuento, this.sortDescuento);

    producto.descuento_ddd.forEach((element, index) => {

      console.log('indexz', index)
      element.dco_coddco = (index + 1).toString();
      console.log('dco_coddco', element.dco_coddco)
      if (element.dco_coddco.length == 1) {
        element.dco_coddco = "000" + element.dco_coddco;
      }
      if (element.dco_coddco.length == 2) {
        element.dco_coddco = "00" + element.dco_coddco;
      }
      if (element.dco_coddco.length == 3) {
        element.dco_coddco = "0" + element.dco_coddco;
      }
    })
  }

  insertarDescuento(): void { }

  eliminarDescuento(): void {

    const producto: DetalleDcd = this.factura.detalle_dcd.find(item => item.seleccionado);

    this.dataSourceDescuento = fillTable(producto.descuento_ddd, this.paginatorDescuento, this.sortDescuento);

    let totalDes = 1;
    producto.descuento_ddd.forEach(descuento => {

      totalDes *= (1 - descuento.ddd_pordes / 100);
      console.log('totalDes', totalDes);

    });

    producto.DCD_PORDES = (1 - totalDes) * 100;
    producto.DCD_IMPDES = producto.DCD_IMPBRU * (producto.DCD_PORDES / 100);
    producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;
    producto.DCD_IMPIGV = this.factura.docu_dcc.DCC_INDCAL == '0' ? 0 : producto.DCD_VALVTA * this.factura.CCO_TASIGV / 100;
    producto.DCD_IMPTOT = producto.DCD_IMPIGV + producto.DCD_VALVTA;

    this.calcularTotales()

    producto.descuento_ddd.forEach((element, index) => {
      element.dco_coddco = (index + 1).toString();
      if (element.dco_coddco.length == 1) {
        element.dco_coddco = "000" + element.dco_coddco;
      }
      if (element.dco_coddco.length == 2) {
        element.dco_coddco = "00" + element.dco_coddco;
      }
      if (element.dco_coddco.length == 3) {
        element.dco_coddco = "0" + element.dco_coddco;
      }
    })


  }

  cambiarFilaDescuento(row: DescuentoDDD): void {

    let producto = this.factura.detalle_dcd.find(item => item == this.selected);

    row.ddd_impdes = producto.DCD_IMPBRU * (row.ddd_pordes / 100);
    row.ddd_coduse = this._configurationService.obtenerIdUsuario();
    row.ddd_incdes = (row.ddd_impdes * 1.18);

    // row.pds_porori = 0;
    // row.pds_impcom = 0;

    let totalDes = 1;
    producto.descuento_ddd.forEach(descuento => {

      totalDes *= (1 - descuento.ddd_pordes / 100);

    });

    producto.DCD_PORDES = (1 - totalDes) * 100;
    producto.DCD_IMPDES = producto.DCD_IMPBRU * (producto.DCD_PORDES / 100);
    producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;
    producto.DCD_IMPIGV = this.factura.docu_dcc.DCC_INDCAL == '0' ? 0 : producto.DCD_VALVTA * this.factura.CCO_TASIGV / 100;
    producto.DCD_IMPTOT = producto.DCD_IMPIGV + producto.DCD_VALVTA;

    this.calcularTotales()

  }

  agregarAnticipo(): void { }

  agregarCobranza(): void { }

  seleccionarCliente(): void {


    const cliente: Cliente = this.clientes.find(item => item.cli_codcli == this.factura.CLI_CODCLI);
    this.factura.cli_numruc = this.factura.CLI_CODCLI;
    this.factura.CLI_CODDOC = this.factura.CLI_CODCLI;

    this.factura.CCO_CODPAG = 'C'
    if (cliente) {
      // this.factura.VDE_CODVDE = cliente.dato_clc.VDE_CODVDE;
      // this.factura.CPA_CODCPA = cliente.dato_clc.CPA_CODCPA;
      // this.factura.ZVE_CODZVE = cliente.dato_clc.ZVE_CODZVE;
      this.direccion = cliente.cli_dircli;
      console.log('cliente', cliente)

      if (this.uid == '0') {

        if (cliente.tpl_codtlp) this.factura.docu_dcc.TLP_CODTLP = cliente.tpl_codtlp;

      }

      if (cliente.cli_numruc) {
        this.factura.TDO_CODTDO = "FAC";
      }

      if (cliente.cli_numdni) {
        this.factura.TDO_CODTDO = "BVE";
      }

      this._ventasService.obtenerDiasEntrega(this.factura.CLI_CODCLI, this.factura.docu_dcc.LDE_CODLDE).subscribe(
        diasEntrega => {
          this.diasEntrega = diasEntrega;

          this._contabilidadService.listarCentroCostos().subscribe(
            centros => {
              this.centros = centros;

              this._ventasService.obtenerLugaresDespacho(this.factura.CLI_CODCLI).subscribe(
                lugaresDespacho => {
                  this.lugaresDespacho = lugaresDespacho;

                  this.seleccionDeLista(this.factura.docu_dcc.TLP_CODTLP);

                },
                error => {
                  this._snackBarService.showError('Error al obtener lugares de despacho', 'Ok');
                  this.loaderData = false;
                }
              )
            },
            error => {
              this._snackBarService.showError('Error al obtener centros de costo', 'Ok');
              this.loaderData = false;
            }
          )

        },
        error => {
          this._snackBarService.showError('Error al obtener días de entrega', 'Ok');
          this.loaderData = false;
        }
      ),
        forkObs(
          // this._ventasService.obtenerSucursalAgencias(this.factura.docu_dcc.),
        ).then(data => {



        }).catch(error => {
          this._snackBarService.showError("Error al obtener maestros", 'OK')
        })

    }

  }

  seleccionarProducto(valor, row): void {
    const producto = this.productos.find(item => item.prd_codprd == valor);
    row.UME_CODVEN = producto.UNIDAD;

    if (this.factura) {
      let now = new Date();

      let fecha = formatDateWithDash(now);

      if (!this.factura) {
        this._snackBarService.showError('Seleccionar lista', 'OK');
      }

      if (!this.factura) {
        this._snackBarService.showError('Establecer Nro de lista', 'OK');
      }

      this.tiposPrecio$ = this._ventasService.listaDePreciosProducto(producto.PRD_CODPRD, this.factura.TMO_CODTMO, this.factura.CLI_CODCLI, this.factura.docu_dcc.TLP_CODTLP, this.factura.docu_dcc.LPC_CODLPC, fecha).subscribe(
        response => {
          if (this.factura.TMO_CODTMO == "SO") {
            row.PCD_PREUNI = response[0].pre_prenac;
            row.PCD_PRUIGV = response[0].pre_vtanac;
          } else {
            row.PCD_PREUNI = response[0].pre_predol;
            row.PCD_PRUIGV = response[0].pre_vtadol;
          }

          row.PCD_IMPBRU = row.PCD_CANSOL * row.PCD_PREUNI;

          row.PCD_IMPDES = row.PCD_IMPBRU * (row.PCD_PORDES / 100)

          row.PCD_VALVTA = row.PCD_IMPBRU - row.PCD_IMPDES;

          row.PCD_IMPIGV = this.factura.docu_dcc.DCC_INDCAL == '0' ? 0 : row.PCD_VALVTA * this.factura.CCO_TASIGV / 100;

          row.PCD_IMPTOT = row.PCD_IMPIGV + row.PCD_VALVTA;

          this.calcularTotales();

        },
        error => this._snackBarService.showError(error.error.msg, 'OK')
      )
    };
    // this.calcularTotales;
  }

  checkProducto(value: boolean, productoSeleccionado: DetalleDcd): void {
    if (value) {
      this.factura.detalle_dcd.forEach(element => element.seleccionado = false);

      productoSeleccionado.seleccionado = value;

      this.dataSourceDescuento = fillTable(productoSeleccionado.descuento_ddd ? productoSeleccionado.descuento_ddd : [], this.paginatorDescuento, this.sortDescuento);

    }
  }

  seleccionarPromocion(codigo, fila): void {

  }

  seleccionarLugarDespacho(lugar_id): void {
    this.lugaresDespacho.forEach(element => {
      if (element.LDE_CODLDE == lugar_id) this.factura.docu_dcc.ZVE_CODZVE = element.zve_codzve;
    });
  }

  extraerPromociones(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de verificar si tiene alguna promoción?', '', '', '').subscribe(result => {

    })
  }

  verHistorialPrecios(): void {
    const dialogRef = this.dialog.open(HistorialPreciosDialog, {
      width: '560px',
      data: this.factura,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  suspenderFactura(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de suspender factura?', '', '', '').subscribe(result => {

    })
  }

  seleccionarTipoDescuento(value, row): void {
    row.dco_coddco = (value == "0001") ? "DESCUENTO ESPECIAL" : "DESCUENTO POR REGALO";
  }

  seleccionarTipoDoc(value): void {
    this.serie$ = this._almacenService.listarSerieDocumentos(this.factura.TDO_CODTDO).subscribe(
      series => {
        console.log('series', series)
        if (series) {
          this.factura.serie = series[0].sdo_codsdo;
        }
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  clickMasOpciones(): void {
    this._bottomSheet.open(FacturaBottomSheet).afterDismissed().subscribe(data => {

      if (data) {
        this.loaderData = true;
        this._facturaService.obtenerFactura(data).subscribe(
          factura => {

            // this.factura = factura;

            this.seleccionarCliente();

            this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort);

            this.loaderData = false;
          },
          error => {

            this.loaderData = false;
            this._snackBarService.showError("Error al obtener factura", 'OK');

          }
        )
      }

    });
  }

  calcularMontosFila(row: DetalleDcd): void {

    row.DCD_IMPBRU = row.DCD_CANDCD * row.DCD_PREUNI;

    if (this.factura.docu_dcc.DCC_INDCAL == '1') row.DCD_PRUIGV = (this.factura.CCO_TASIGV / 100 + 1) * row.DCD_PREUNI;

    row.DCD_IMPDES = row.DCD_IMPBRU * (row.DCD_PORDES / 100)

    row.DCD_VALVTA = row.DCD_IMPBRU - row.DCD_IMPDES;

    row.DCD_IMPIGV = this.factura.docu_dcc.DCC_INDCAL == '0' ? 0 : row.DCD_VALVTA * this.factura.CCO_TASIGV / 100;

    row.DCD_IMPTOT = row.DCD_IMPIGV + row.DCD_VALVTA;

    this.calcularTotales();
  }

  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;

    this.factura.detalle_dcd.forEach(element => {
      valorVenta += Number(element.DCD_VALVTA);
      impBruto += Number(element.DCD_IMPBRU);
      impIGV += Number(element.DCD_IMPIGV);
      descuento += Number(element.DCD_IMPDES);
      impTotal += Number(element.DCD_IMPTOT);
    })

    this.factura.CCO_VALVTA = valorVenta;
    this.factura.CCO_VALVTA = this.factura.CCO_VALVTA.toFixed(2);

    this.factura.docu_dcc.DCC_IMPBRU = impBruto;
    this.factura.docu_dcc.DCC_IMPBRU = this.factura.docu_dcc.DCC_IMPBRU.toFixed(2);

    this.factura.CCO_IMPIGV = impIGV;
    this.factura.CCO_IMPIGV = this.factura.CCO_IMPIGV.toFixed(2);

    this.factura.docu_dcc.DCC_IMPDES = descuento;
    this.factura.docu_dcc.DCC_IMPDES = this.factura.docu_dcc.DCC_IMPDES.toFixed(2);

    this.factura.CCO_IMPDOC = impTotal;
    this.factura.CCO_IMPDOC = this.factura.CCO_IMPDOC.toFixed(2);

    console.log('tipo cambio', this.factura.CCO_TIPCAM)
    if (!this.factura.CCO_TIPCAM) {
      this._snackBarService.showError('Establecer un tipo de cambio para la conversión en soles o dólares', 'Ok');
      return;
    }

    if (this.factura.TMO_CODTMO == 'SO') {
      this.factura.CCO_IMPSOLES = this.factura.CCO_IMPDOC;
      this.factura.CCO_IMPDOLAR = (parseInt(this.factura.CCO_IMPDOC) / this.factura.CCO_TIPCAM).toFixed(2);
    }

    if (this.factura.TMO_CODTMO == 'DO') {
      this.factura.CCO_IMPDOLAR = this.factura.CCO_IMPDOC;
      this.factura.CCO_IMPSOLES = (parseInt(this.factura.CCO_IMPDOC) * this.factura.CCO_TIPCAM).toFixed(2);
    }
  }

  focusInput(evento): void {
    evento.target.select();
  }

  seleccionarCalculaItem(calculaItem): void {
    if (calculaItem == '0') {
      this.factura.detalle_dcd.forEach(item => {
        item.DCD_PRUIGV = 0;
        item.DCD_IMPIGV = 0;
        this.calcularMontosFila(item);
      })

      this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort);
      this.calcularTotales();

      return;
    }

    this.factura.detalle_dcd.forEach(item => {
      item.DCD_PRUIGV = (this.factura.CCO_TASIGV / 100 + 1) * item.DCD_PREUNI;
      item.DCD_IMPIGV = item.DCD_IMPBRU * item.DCD_PRUIGV;
      this.calcularMontosFila(item);
    })

    this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort);

    this.calcularTotales();

  }

  reset(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Desea restablecer el formulario?', '', '', '').subscribe(
      response => {
        if (response) {

          this.direccion = '';

          this.factura = new Factura();

          this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
          this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

          this.factura.CCO_FECREG = this.minDate;
          this.factura.CCO_FECREG = this.factura.CCO_FECREG;

          this.dataSource = fillTable([], this.paginator, this.sort);
          this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);

          this.documentos = fillTable([], this.paginatorDocumento, this.sortDocumento);

          this.factura.ANO_CODANO = this.year;
          this.factura.MES_CODMES = this.month;
          this.factura.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

          this.factura.CCO_CODUSE = this._authenticationService.getUsuarioSistema();

        }
      }
    )
  }

  imprimirFormato(): void {
    console.log('logo', this.logo)
    this.loaderFormato = true;

    // if(!this.logo) {
    //   this._snackBarService.showError('No se ha cargado la imagen del logo', 'Ok');
    //   return;
    // }

    const headers = {
      ruc: this._configurationService.obtenerClienteId(),
      razonSocial: this._configurationService.obtenerNombreCliente(),
      logo: this.logo,
      nomcia: this._configurationService.obtenerNombreCompaniaCliente(),
      dircia: this.dircia,
    }

    const content = {
      cli_nomcli: this.clientes.find(item => item.cli_codcli == this.factura.CLI_CODCLI).cli_nomcli,
      cli_codcli: this.factura.CLI_CODCLI,
      cco_numdoc: this.factura.CCO_NUMDOC,
      cco_fecemi: this.factura.CCO_FECEMI,
      cli_dircli: this.direccion,
      tmo_descri: this.factura.TMO_CODTMO == 'SO' ? 'Soles' : 'Dólares',
      cco_fecven: this.factura.CCO_FECVEN,
      dcc_ordcom: this.factura.docu_dcc.DCC_ORDCOM,
      pcc_numpcc: this.factura.docu_dcc.PCC_NUMPCC,
      cpa_descri: this.condiciones.find(item => item.CPA_CODCPA == this.factura.CPA_CODCPA).CPA_DESCRI,
      detalle_dcd: this.factura.detalle_dcd,
      cuotas_ccu: this.factura.cuotas_ccu,
      CCO_IMPAFE: this.factura.CCO_IMPAFE,
      cco_impexo: this.factura.cco_impexo,
      CCO_IMPINA: this.factura.CCO_IMPINA,
      cco_impotc: this.factura.cco_impotc,
      CCO_IMPDOC: this.factura.CCO_IMPDOC,
      CCO_IMPIGV: this.factura.CCO_IMPIGV,
    }

    if (this.uid != '0') {

      this.formato$ = this._facturaService.obtenerFactura(this.factura.CCO_CODCCO).subscribe(
        data => {
          console.log('formato', data)
          generarPdfVoucher(headers, content);
          this.loaderFormato = false;

        },
        error => {
          this.loaderFormato = false;
          this._snackBarService.showError('Error al obtener información de formato', 'Ok');
          return;
        }
      )

    }
  }

  focusCampo(evento): void {
    evento.target.select();
  }

  obtenerDatosPedido(): void {

    if (!this.factura.docu_dcc.PCC_NUMPCC) {
      this._snackBarService.showError('Seleccionar un pedido', 'Ok');
      return;
    }

    console.log('copia pedido', this.factura.docu_dcc.PCC_NUMPCC)

    this.pedido$ = this._pedidoService.obtenerPedido('01', this.factura.docu_dcc.PCC_NUMPCC).subscribe(
      pedido => {
        console.log('pedido encontrado', pedido)
        this.factura.TDO_CODTDO = pedido.TDO_CODTDO;
        this.factura.CCO_TIPCAM = pedido.PCC_TIPCAM;
        this.factura.MMO_CODMMO = pedido.MMO_CODMMO;
        this.factura.CCO_TASIGV = pedido.PCC_TASIGV;
        this.factura.docu_dcc.VDE_CODVDE = pedido.VDE_CODVDE;
        this.factura.TMO_CODTMO = pedido.TMO_CODTMO;
        this.factura.CLI_CODCLI = pedido.CLI_CODCLI;
        this.factura.CPA_CODCPA = pedido.CPA_CODCPA;
        this.factura.CCO_FECVEN = pedido.PCC_FECENT;
        this.factura.docu_dcc.ALM_CODALM = pedido.ALM_CODALM;
        this.factura.CCO_FECEMI = pedido.PCC_FECDOC;

        this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.factura.CCO_FECEMI)).subscribe(
          response => {
            this.factura.CCO_TIPCAM = response[0].tipcam;
            localStorage.setItem("tipo-cambio", this.factura.CCO_TIPCAM.toFixed(4));

            this.lugarDespacho$ = this._ventasService.obtenerLugaresDespacho(this.factura.CLI_CODCLI).subscribe(
              lugaresDespacho => {
                this.lugaresDespacho = lugaresDespacho;
                this.seleccionarCliente();
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'OK')
              }
            )
          },
          error => this._snackBarService.showError(error.error.msg, 'OK')
        )

        this.factura.detalle_dcd = [];

        pedido.detalle_pcd.forEach(item => {
          const facturaDetalleItem = new DetalleDcd();
          const producto = this.productos.find(producto => producto.prd_codprd == item.PRD_CODPRD);
          console.log('producto', producto)
          facturaDetalleItem.PRD_CODPRD = item.PRD_CODPRD;
          facturaDetalleItem.DCD_CORDCD = item.PCD_CORPCD;
          facturaDetalleItem.DCD_IMPBRU = item.PCD_IMPBRU;
          facturaDetalleItem.DCD_VALVTA = item.PCD_VALVTA;
          facturaDetalleItem.DCD_IMPIGV = item.PCD_IMPIGV;
          facturaDetalleItem.DCD_IMPTOT = item.PCD_IMPTOT;
          facturaDetalleItem.DCD_PREUNI = item.PCD_PREUNI;
          facturaDetalleItem.UME_CODVEN = producto.UNIDAD;
          facturaDetalleItem.DCD_CANDCD = item.PCD_CANSOL;

          this.factura.detalle_dcd.push(facturaDetalleItem);
        });

        this.dataSource = fillTable(this.factura.detalle_dcd, this.paginator, this.sort)

        this.calcularTotales();
        // this.factura.detalle_dcd = pedido.detalle_pcd;
      }
    )

    // this._dialogService.openDialog(ConfirmationComponent, '¿Desea obtener datos del pedido?', '', '', '').subscribe(result => {
    //   if(result) {

    //   }
    // })
  }

  seleccionarCBParteAlmacen(): void {
    console.log('genera factura', this.factura.generaParteAlmacen);

    if (this.factura.generaParteAlmacen) {

    }
  }

  obtenerFacturaDesdePedido(): void {
    const idPedido = this.uid.slice(7)

    this.factura.docu_dcc.PCC_NUMPCC = idPedido;
    console.log('idPedido', idPedido)
    this.obtenerDatosPedido();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/comprobantes']);
  }

  nuevoRegistro(): void {
    this.factura = new Factura();
    this.uid = '0';
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceAnticipos = fillTable([], this.paginatorAnticipos, this.sortAnticipos);
    this.dataSourceCobranzas = fillTable([], this.paginatorCobranzas, this.sortCobranzas);
    this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);


  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.motivos$,
      this.tipoCambio$,
      this.consultaStock$,
      this.centroCosto$,
      this.diasEntrega$,
      this.lugarDespacho$,
      this.sucursal$,
      this.formaCalcula$,
      this.tiposPrecio$,
      this.clientes$,
      this.sendForm$,
      this.factura$,
      this.tiposDocumento$,
      this.detalleCliente$,
      this.vendedores$,
      this.condiciones$,
      this.zonas$,
      this.transportistas$,
      this.almacenes$,
      this.productos$,
      this.UnidadesMedida$,
      this.pedidos$,
      this.promociones$,
      this.descuentos$,
      this.tiposRetencion$,
      this.period$,
      this.loading$,
      this.cotizacion$,
      this.config$,
      this.formato$
    ])
  }

}
