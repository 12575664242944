<app-pavso-title-section [title]="'Aplicaciones varias'" [module]="'Contabilidad'" [estado]="asientoContable.LDC_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarAsientoContable(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m6 l1">
          <mat-form-field class="example-full-width">
            <mat-label>Año</mat-label>
            <input #iAno min="1900" type="number" [(ngModel)]="asientoContable.ANO_CODANO" name="ano_codano" matInput placeholder="Año" disabled (keyup.enter)="establecerAno()">
          </mat-form-field>
        </div>

        <div class="col s12 m6 l1">
          <mat-form-field class="example-full-width">
            <mat-label>Mes</mat-label>
            <input #iMes min="0" type="number" [(ngModel)]="asientoContable.MES_CODMES" name="mes_codmes" matInput placeholder="Mes" disabled (keyup.enter)="establecerMes()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
              <mat-label>SubDiario</mat-label>
              <mat-select #iSubdiario (selectionChange)="seleccionarSubdiario($event)"  required [disabled]="loaderData" [(ngModel)]="asientoContable.SCO_CODSCO" name="sco_codsco">
                  <mat-option *ngFor="let subdiario of subsidiarios" [value]="subdiario.sco_codsco">
                      {{subdiario.sco_descri}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input
            #iFecha

            maxlength="10"
            matInput [matDatepicker]="fecha" [disabled]="loaderData" [(ngModel)]="asientoContable.LDC_FECDIA" name="cco_fecreg" (dateChange)="cambiarFecha($event)" (keyup.enter)="establecerFecha()">
            <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
            <mat-datepicker #fecha></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3 xl1">
          <mat-form-field>
            <mat-label>T.C.</mat-label>
            <input #iTc appTipoCambioFormat step="any" min="0" type="number" matInput placeholder="T.C." [disabled]="loaderData" [(ngModel)]="asientoContable.LDC_TIPCAM" name="cco_tipcam" (keyup.enter)="establecerTC()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l1">
          <mat-form-field>
              <mat-label>Voucher</mat-label>
              <input type="text" matInput placeholder="Voucher" disabled [(ngModel)]="asientoContable.LDC_CORASI" name="cco_codcco">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl2">

          <select-search
            [label]="'Tipo Doc.'"
            [placeholder]="'Ej. DNI'"
            [name]="'TDO_CODTDO'"
            [value]="'TDO_CODTDO'"
            [description]="'description'"
            [data]="cuentasTotales"
            (cambioSelect)="asientoContable.TTI_CODTTI = $event"
            [model]="asientoContable.TTI_CODTTI">
          </select-search>

        </div>

        <div class="col s12 m12 l3 xl1">
            <mat-form-field>
                <mat-label>Serie</mat-label>
                <input #iSerie type="text" maxlength="4" matInput placeholder="Serie" [disabled]="loaderData" name="sad_codsad" (keyup.enter)="establecerSerie()">
            </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl2">
          <mat-form-field>
            <mat-label>Nro. Doc.</mat-label>
            <input #iNroDoc type="text" maxlength="13" matInput placeholder="Nro. Doc." [(ngModel)]="asientoContable.LDC_CORLDC" name="cco_numdoc" [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3 xl2">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select #iMoneda required [disabled]="loaderData"  name="tmo_codtmo" name="tmo_codtmo" (selectionChange)="seleccionarMoneda($event)">
                <mat-option value="SO">
                  Soles
                </mat-option>
                <mat-option value="DO">
                  Dólares
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l5">

          <select-search
            [label]="'Proveedor'"
            [placeholder]="'Ej. DNI'"
            [name]="'cli_codcli'"
            [value]="'cli_codcli'"
            [description]="'cli_nomcli'"
            [data]="proveedores">
          </select-search>

        </div>

        <div class="col s12 m12 l9 xl12">
          <mat-form-field>
              <mat-label>Glosa</mat-label>
              <input #iGlosa type="text" matInput placeholder="Glosa" [disabled]="loaderData" [(ngModel)]="asientoContable.LDC_GLODIA" name="cco_glocco" (keyup.enter)="establecerGlosa()">
          </mat-form-field>
        </div>
      </div>

    </mat-card>

    <button #btnAdd mat-icon-button color="primary" type="button" (click)="agregarItem()" (keyup.enter)="agregarItem()">
      <mat-icon>add</mat-icon>
    </button>
    &nbsp;
    <mat-form-field class="pavso_input_search">
        <mat-label>{{LABELS_NAME.BTN_SEARCH}}</mat-label>
        <input #code matInput [placeholder]="LABELS_NAME.BTN_SEARCH" (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="ldi_corldi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> It </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="It" class="td-responsive">

              <ng-container>
                {{row.ldi_corldi}}
              </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="ind">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> IND </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="IND" class="td-responsive">
            <ng-container>
              ind
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="cli_codcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="Cliente" class="td-responsive">
              <ng-container *ngIf="row.isEditing">

                <select-search
                  [label]="'Auxiliar'"
                  [placeholder]="'Ej. DNI'"
                  [name]="'cli_codcli'"
                  [value]="'cli_codcli'"
                  [description]="'cli_nomcli'"
                  [data]="auxiliares"
                  (cambioSelect)="row.cli_codcli = $event"
                  [model]="row.cli_codcli">
                </select-search>

              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.cli_codcli}}
              </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="cco_codcco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="Cta. Cte." style="width: 100px" class="td-responsive">
              {{row.cco_codcco}}
            </td>
        </ng-container>

        <ng-container matColumnDef="tdo_codtdo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Doc." class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field style="width: 70px;" class="mat-form-field-table">
                <mat-label>Doc.</mat-label>
                <mat-select [(ngModel)]="row.tdo_codtdo" [name]="'doc' + i.toString()">
                  <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.TDO_CODTDO">
                    {{tipo.TDO_CODTDO}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.tdo_codtdo}}
            </ng-container>
          </td>
      </ng-container>

      <ng-container matColumnDef="ldi_docref">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Nro. Doc." class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field style="width: 100px;" class="mat-form-field-table">
                <mat-label>Nro. Doc.</mat-label>
                <input matInput placeholder="Nro. Doc." [(ngModel)]="row.ldi_docref" [name]="'nro_doc' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.ldi_docref}}
            </ng-container>
          </td>
      </ng-container>

      <ng-container matColumnDef="dias">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Días </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Días" class="td-responsive">
          <ng-container>
            días
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="fec_emision">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Emisión </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Fec. Emisión" class="td-responsive">
          <ng-container>
            fec. emision
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="fec_vencimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vencimiento </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Fec. Vencimiento" class="td-responsive">
          <ng-container>
            fec. vencimiento
          </ng-container>
        </td>
      </ng-container>


        <ng-container matColumnDef="ccn_codccn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <select-search
                [label]="'Cuenta'"
                [placeholder]="'Ej. 42'"
                [name]="'cuenta'"
                [value]="'cuenta'"
                [description]="'description'"
                [data]="cuentasTotales"
                (cambioSelect)="row.ccn_codccn = $event"
                [model]="row.ccn_codccn">
              </select-search>

            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.ccn_codccn}}
            </ng-container>
          </td>
        </ng-container>


        <ng-container matColumnDef="ccs_codccs">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="C. Costo" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field style="width: 100px;" class="mat-form-field-table">
                <mat-label>C. Costo</mat-label>
                <mat-select #costoSelect [(ngModel)]="row.ccs_codccs" [name]="'centro_costo' + i.toString()">
                  <mat-option *ngFor="let centro of centros" [value]="centro.ccs_codccs">
                    {{centro.ccs_codccs}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.ccs_codccs}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="Mon." class="td-responsive">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field style="width: 70px;" class="mat-form-field-table">
                  <mat-label>Moneda</mat-label>
                  <mat-select #iMoneda required [disabled]="loaderData" [(ngModel)]="row.tmo_codtmo" name="tmo_codtmo" [name]="'moneda' + i.toString()">
                      <mat-option value="SO">
                        Soles
                      </mat-option>
                      <mat-option value="DO">
                        Dólares
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.tmo_codtmo}}
              </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="ldi_tipcam">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> T. C. </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="T. C." class="td-responsive">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field style="width: 50px;" class="mat-form-field-table">
                  <mat-label>T. Cambio</mat-label>
                  <input min="0" type="number" matInput placeholder="T. Cambio" [(ngModel)]="row.ldi_tipcam" [name]="'t_cambio' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.ldi_tipcam}}
              </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="ldi_impnac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe MN </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Importe MN" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field style="width: 60px;" class="mat-form-field-table">
                <mat-label>Importe MN</mat-label>
                <input (keyup)="calculoImportesMN(row)" min="0" type="number" matInput placeholder="Importe MN" [(ngModel)]="row.ldi_impnac" [name]="'importe_mn' + i.toString()" step="any">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.ldi_impnac}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="ldi_impmex">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe ME </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="Importe ME" class="td-responsive">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field style="width: 60px;" class="mat-form-field-table">
                  <mat-label>Importe ME</mat-label>
                  <input (keyup)="calculoImportesME(row)" min="0" type="number" matInput placeholder="Importe ME" [(ngModel)]="row.ldi_impmex" [name]="'importe_me' + i.toString()" step="any">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.ldi_impmex}}
              </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="ldi_inddha">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="D/H" style="width: 100px;" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field style="width: 50px;" class="mat-form-field-table">
                <mat-label>D/H</mat-label>
                <mat-select [(ngModel)]="row.ldi_inddha" [name]="'dh' + i.toString()">
                  <mat-option value="D">
                    D
                  </mat-option>
                  <mat-option value="H">
                    H
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.ldi_inddha}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="ldi_gloldi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa" class="td-responsive">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="mat-form-field-table">
                  <mat-label>Glosa</mat-label>
                  <input type="text" matInput placeholder="Glosa" [(ngModel)]="row.ldi_gloldi" [name]="'glosa' + i.toString()" (keyup.enter)="confirmarNuevoItem(row)">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.ldi_gloldi}}
              </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="ret">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Retención </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Retención" class="td-responsive">
            <ng-container>
              retención
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="imp_ret">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Retención </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Imp. Retención" class="td-responsive">
            <ng-container>
              imp. retención
            </ng-container>
          </td>
        </ng-container>


        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row; let i = index" [style.color]="row.color" data-label="Acciones" style="width: 100px">
              <div style="width: 100px;">
                <mat-checkbox [(ngModel)]="row.isSelected" [name]="'seleccionable' + i" class="example-margin" (click)="filaSeleccionada(row, $event)"></mat-checkbox>

                <button *ngIf="!row.isEditing" mat-icon-button color="primary" type="button" matTooltip="Editar Ítem" (click)="row.isEditing = !row.isEditing">
                  <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="row.isEditing" mat-icon-button color="primary" type="button" matTooltip="Actualizar Ítem" (click)="confirmarNuevoItem(row)">
                  <mat-icon>done</mat-icon>
                </button>
                <button mat-icon-button color="primary" type="button" matTooltip="Eliminar Ítem" (click)="quitarItem(row.ldi_corldi)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="15">No se encontraron registros</td>
        </tr>

      </table>

      <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>
    <div class="row" style="margin-bottom: 90px !important;">
      <div class="col s12 m12 l12 xl4">

        <div class="mat-elevation-z0">
            <table mat-table [dataSource]="dataSource3">

                <ng-container matColumnDef="name" sticky>
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let row" data-label="" class="td-responsive"> {{row.name}} </td>
                </ng-container>

                <ng-container matColumnDef="mn">
                    <th mat-header-cell *matHeaderCellDef> M.N. </th>
                    <td mat-cell *matCellDef="let row" data-label="M.N." class="td-responsive"> {{row.mn.toFixed(2)}} </td>
                </ng-container>

                <ng-container matColumnDef="me">
                    <th mat-header-cell *matHeaderCellDef> M.E. </th>
                    <td mat-cell *matCellDef="let row" data-label="M.E." class="td-responsive"> {{row.me.toFixed(2)}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No se encontraron registros</td>
                </tr>

            </table>

        </div>

      </div>
      <div class="col s12 m12 l12 xl2">
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Nacional</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            3482349
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col s12 m12 l12 xl2">
        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Dólares</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            3482349
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <br>
    <mat-card class="pavso-bottom-actions">
        <div class="row">
            <div class="col s12 m12 l8 xl6">

              <button mat-mini-fab color="primary" matTooltip="Extraer Excel" type="button" (click)="extraerExcel()">
                  <mat-icon>description</mat-icon>
              </button>
              &nbsp;
              <button mat-mini-fab color="primary" matTooltip="Cuentas por Pagar" type="button" (click)="documentosPorPagar()">
                  <mat-icon>article</mat-icon>
              </button>
              &nbsp;
              <button mat-mini-fab color="primary" matTooltip="Cuentas por Cobrar" type="button" (click)="documentosPorCobrar()">
                  <mat-icon>assignment</mat-icon>
              </button>
              &nbsp;
              <button mat-mini-fab color="primary" matTooltip="Ajuste < 0.05" type="button" (click)="ajusteDiferencia()">
                  <mat-icon>calculate</mat-icon>
              </button>
              &nbsp;
              <button mat-mini-fab color="primary" matTooltip="Retenciones" type="button" (click)="retenciones()">
                  <mat-icon>task</mat-icon>
              </button>
            </div>
            <div class="col s12 m12 l4 xl6" align="end">
                <mat-spinner diameter="40" *ngIf="loaderReg"></mat-spinner>

                <button class="pav-button-action" *ngIf="!loaderReg" mat-flat-button color="primary" type="submit" >
                  {{btnName | translate}}
                </button>
            </div>
        </div>
    </mat-card>

  </form>
</div>
