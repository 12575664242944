import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConceptoRemunerativo } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ApiNominaConfiguracionesConceptoRemuneracionService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarConceptosRemunerativos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/conceptos-remunerativos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param conceptoRemunerativo
   * @returns
   */
  registrarConceptoRemunerativo(conceptoRemunerativo: ConceptoRemunerativo): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/conceptos-remunerativo`,
      conceptoRemunerativo
    );
  }

  /**
   *
   * @param conceptoRemunerativo
   * @returns
   */
  actualizarConceptoRemunerativo(conceptoRemunerativo: ConceptoRemunerativo): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/conceptos-remunerativo`,
      conceptoRemunerativo
    );
  }
}
