import { Factura } from "./factura";
import { Order } from "./order";

export class FacturaXML {
  RazonSocial: string;
  NumeroRUC: string;
  NombreComercial: string;
  DomicilioFiscal: string;
  Urbanizacion: string;
  CiudadCia: string;
  ProvinciaCia: string;
  DistritoCia: string;
  Ubigeo: string;
  TipoDocumentoIdentidad: string;
  Cli_NumeroRuc: string;
  Cli_RazonSocial: string;
  Cli_TipoDocumentoIdentidad: string;
  IndDetrac: string;
  IndTransferenciaGratuita: string;
  PorcDetra: string;
  MontoDetra: string;
  ProdDetra: string;
  CuenBancoDetracc: string;
  CodEmiFiscal: string;
  Moneda: string;
  TipoFacturacat51: string;
  NumLineas: string;
  TipoDocumento: string;
  MotivoVenta: string;
  NumeroDocumento: string;
  Exportacion: string;
  TotalFactura: number;
  FechaEmision: Date;
  CuentaCorriente: string;
  NumeroReferenciaNCR: string;
  DescripcionReferenciaNCR: string;
  DocumentoReferenciaNCR: null;
  TipoNota: string;
  ImporteLetra: string;
  ImpuestoFactura: number;
  TotalValorVenta: number;
  TotalValorVentaGrabada: number;
  TotalValorVentaInafecta: number;
  TotalValorVentaExonerada: number;
  TotalVentaOperacionesGratu: number;
  FormaPago: string;
  ImporteDePago: number;
  TotalDescuento: number;
  TotalAnticipo: number;
  DetalleProduc: DetalleProduc[];
  Anticipos: Anticipo[];
  DocuReferencia: DocuReferencia[];
  Impuesto: any;

  constructor() {}

  static dataPedidoToFacturaXML(pedido: Order): FacturaXML {
    const facturaXML = new FacturaXML();

    facturaXML.RazonSocial = pedido.cliente.cli_nomcli;

    return facturaXML;
  }
}

export interface Anticipo {
  NumDOC: string;
  importe: number;
  Moneda: string;
  InstructionID: string;
  Correlativo: string;
}

export interface DetalleProduc {
  Correaltivo: string;
  CodigoProducto: string;
  CodigoProductoSunat: string;
  CantidadItem: number;
  DescripcionItem: string;
  UnidadMedidaItem: string;
  ValorUnitarioItem: number;
  ValorUnitarioIncluidoIgvItem: number;
  TipoPrecioCat16: string;
  ValorPrecioReferencial: string;
  ImporteBrutoItem: number;
  ValorVentaItem: number;
  DescuentoProduc: DescuentoProduc[];
  Impuesto: Impuesto[];
}

export interface DescuentoProduc {
  ItemDescuento: string;
  PorcentajeDescuento: number;
  ImporteDescuente: number;
  ConceptoDescuento: string;
  BaseDescuento: number;
}

export interface Impuesto {
  CodigoTributo: string;
  CodigoAfectacionIGV: string;
  Importe: number;
  NombreTributo: string;
  TasaImpuesto: number;
  ImporteValorVenta: number;
  TipoAfectacionTab07: string;
  CodigoInternacional: string;
}

export interface DocuReferencia {
  TipoDocumentoReferencia: string;
  Correlativo: string;
  NumeroDocumentoReferencia: string;
}
