export class RentaAcumulada {
  CIA_CODCIA: string;
  ANO_CODANO: string;
  CLI_CODCLI: string;
  RQA_INGRQA: number;
  RQA_RETRQA: number;
  RQA_TIPAFP: string;
  RQA_INGAFP: number;
  RQA_COMAFP: number;
  RQA_PRIAFP: number;
  RQA_APOAFP: number;
  RQA_TOTAFP: number;
  RQA_INDSTA: string;
  RQA_CODUSE: string;
  RQA_FECUPD: Date;
  RQA_DIAAFP: number;
  RQA_DIADIF: number;
  RQA_DIAFAL: number;
  RQA_DIAUTI: number;
  RQA_INGUTI: number;
  RQA_RESUTI: number;
  RQA_DIFUTI: number;
  RQA_TOTUTI: number;

  constructor() {
    this.RQA_INDSTA = '1';
    this.RQA_FECUPD = new Date();
  }
}
