import { PermisoAprobacionPedidoComponent } from "./permiso-aprobacion-pedido/permiso-aprobacion-pedido.component";
import { SerieDocumentoFormComponent, SerieDocumentoListComponent } from "./serie-documento";
import { TiendaModule } from "./tienda/tienda.module";

export const ventasConfiguracionesComponents = [
  PermisoAprobacionPedidoComponent,
  SerieDocumentoFormComponent,
  SerieDocumentoListComponent
]

export const configuracionModulesVentas = [
  TiendaModule
]
