import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { CargarColaboradoresNominaDialog } from "@shared/components/dialogs/planilla/cargar-colaboradores-nomina/cargar-colaboradores-nomina.component";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RemuneracionFija } from "src/app/models/planilla/datos-periodo/remuneracion-fija";
import { NominaService } from "src/app/services";
import { forkObs } from "@utils/observables/fork";

@Component({
  selector: 'app-remuneraciones-fijas',
  templateUrl: './remuneraciones-fijas.component.html',
  styleUrls: ['./remuneraciones-fijas.component.scss']
})
export class RemuneracionesFijasComponent implements OnInit, OnDestroy {
  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['actions', 'nomcli', 'imprem', 'cannut', 'codnut'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  conceptos: any[] = [];
  unidades: any[] = [];

  year: string;
  month: string;

  total: number;

  nomina$: Subscription;
  detalleConRem$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  concepto$: Subscription;
  unidad$: Subscription;
  colaborador$: Subscription;
  registrarRem$: Subscription;

  concepto: string;

  remuneraciones: Array<RemuneracionFija>;

  unidad: string;

   /** filtrar proveedores */
  colaboradores: any[] = [];

  estanHabilitados: boolean = false;

  constructor(
    private _snackBarService: SnackBarService,
    private _nominaService: NominaService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
    public dialog: MatDialog
  ){
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.total = 0;

    this.store.select('permissions').subscribe(({isCreate, isCancel}) => {

    })

    this.period$ = this.store.select('period').subscribe(state => {
      state
      this.year = state.year;
      this.month = state.month;
      console.log(`year ${this.year} month ${this.month}`)
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadMaestros();
    })
  }

  /**
   * Obtiene los maestros de :
   * . conceptos de remuneración
   * . colaboradores
   * . unidades
   */
  loadMaestros(): void {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.remuneraciones = [];
    this.concepto = null;

    forkObs(
      this._nominaService.listarConceptosRemuneracion(this.year, this.month),
      this._nominaService.listarColaboradores(),
      this._nominaService.listarUnidades()
    ).then(data => {
      this.conceptos = data[0];
      this.colaboradores = data[1];
      this.unidades = data[2];

    }).catch(err => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })

  }

  seleccionarConcepto(event): void {
    const concepto = this.conceptos.find(item => item.codnco == event);
    this.unidad = concepto.codnut;

    this.total = 0;

    this.detalleConRem$ = this._nominaService.listarDetalleConceptosRemuneracion(this.year, this.month, event).subscribe(
      detalle => {
        detalle.forEach((element,key) => {
          console.log('element', element)
          this.total += element.imprem;
          element['isEditing'] = true;
          element['correlativo'] = key;
          element['codnut'] = this.unidad;
          element['imprem'] = Number(element['imprem']).toFixed(2);
        });
        this.dataSource = fillTable(detalle, this.paginator, this.sort);
        this.remuneraciones = detalle;
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  editarTodos(): void {
    this.estanHabilitados = !this.estanHabilitados;
    this.remuneraciones.forEach(movimiento => {
      movimiento.isEditing = true;
    });

  }

  confirmarTodos(): void {
    this.estanHabilitados = !this.estanHabilitados;
    this.remuneraciones.forEach(item => {
      item.isEditing = true;
    });
  }

  seleccionarColaborador(event: MatSelectChange, row) {
    let existe = false;
    this.remuneraciones.forEach(item => {
      if(item.isEditing == false)
        if(row.codcli == item.codcli) existe = true;
    });

    if(existe) {
      this._snackBarService.showError('Este colaborador ya existe en la tabla', 'Ok');
      event.source.value = "";
      return;
    }
    const colaborador = this.colaboradores.find(item => item.codcli == row.codcli);
    row.nomcli = colaborador.nomcli;
  }

  agregarItem(): void {
    if(!this.concepto) {
      this._snackBarService.showError('Seleccionar un concepto', 'Ok')
      return;
    }

    const item = new RemuneracionFija();
    item.codnut = this.unidad;

    this.remuneraciones.push(item);

    this.remuneraciones.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.remuneraciones, this.paginator, this.sort);


  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;

    this.total = 0;

    this.remuneraciones.forEach(element => {
      this.total += Number(element.imprem);
    });

  }

  confirmarItem(row: RemuneracionFija): void {
    if(!row.codcli || !row.imprem || !row.cannut) {
      this._snackBarService.showError('Todos los campos de fila deben estar compeltos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

    this.total = 0;

    this.remuneraciones.forEach(element => {
      this.total += Number(element.imprem);
    })
  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.remuneraciones.filter(item => item.correlativo != row.correlativo);
        this.remuneraciones = filtered;
        this.total = 0;
        this.remuneraciones.forEach((element, key) => {
          element['correlativo'] = key;
          this.total += Number(element.imprem);
        })
        this.dataSource = fillTable(this.remuneraciones, this.paginator, this.sort);
      }
    })
  }

  cargarColaboradores(): void {
    if(!this.concepto) {
      this._snackBarService.showError('Seleccionar un concepto', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(CargarColaboradoresNominaDialog, {
      width: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {

      this.estanHabilitados = !this.estanHabilitados;

      let colaboradoresEnTabla = this.dataSource.data;
      const colaboradoresCargados = result;

      let colaboradoresAgregados = [];

      colaboradoresCargados.forEach(it => {
        let existe = false;
        colaboradoresEnTabla.forEach(item => {
          if(it.codigo.trim() == item.codcli.trim()) {
            existe = true;
          }
        })
        if(!existe) {
          colaboradoresAgregados.push({
            codcli: it.codigo,
            nomcli: it.name,
            imprem: 0,
            cannut: 0,
            codnut: this.unidad,
            isEditing: true,
          })
        };
      })

      colaboradoresAgregados.forEach(item => colaboradoresEnTabla.push(item));

      this.remuneraciones = colaboradoresEnTabla;

      this.dataSource = fillTable(colaboradoresEnTabla, this.paginator, this.sort);
      colaboradoresAgregados.forEach(item => this.total += item.imprem);
      this.total = Number(this.total);

    })
  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  esValidoTabla(): boolean {
    let validacion = true;
    this.remuneraciones.forEach(item => {
      if(!item.codcli || !item.imprem || !item.cannut) {
        validacion = false;
      }
    })

    return validacion;
  }

  blurImporte(row): void {
    row.imprem = Number(row.imprem).toFixed(2)

    this.total = 0;
    this.remuneraciones.forEach(item => {
      this.total += Number(item.imprem)
    })
  }

  registrarRemuneracionesColaborador(): void {

    this.remuneraciones.forEach(item => {
      item.imprem = Number(item.imprem);
    })

    if(!this.esValidoTabla()) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok');
      return ;
    };

    if(!this.concepto || this.remuneraciones.length == 0) {
      this._snackBarService.showError('Establecer registros para registrar', 'Ok');
      return;
    }

    let isValid = true;
    this.remuneraciones.forEach(item => {
      if(item.isEditing) isValid = false;
    });

    this.loaderReg = true;
    this.remuneraciones.forEach(element => {
      element['tmo_codtmo'] = "SO";
    })

    this.registrarRem$ = this._nominaService.registrarRemuneracionesFijas(this.remuneraciones, this.year, this.month, this.concepto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Remuneración fija registrada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.nomina$,
      this.period$,
      this.loading$,
      this.concepto$,
      this.colaborador$,
      this.unidad$,
      this.detalleConRem$,
      this.registrarRem$
    ])
  }
}
