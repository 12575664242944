import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { fillTable } from "@utils/tables/table";
import { ConductorService } from "src/app/services/api/almacen/maestros/conductor.service";

@Component({
  selector: 'conductor-list',
  templateUrl: './conductor-list.component.html',
  styleUrls: ['./conductor-list.component.scss']
})
export class ConductorListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['CON_CODCON', 'CON_DESCON'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private store: Store<PavsoState>,
    private _conductorService: ConductorService,
    private _snackBarService: SnackBarService,
  ) {

  }
  // year : Anio de actividad
  // month : mes de actividad

  ngOnInit(): void {
    this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)
        this.obtenerConductores();

    })
  }

  ngOnDestroy(): void {

  }

  obtenerConductores(): void {
    this._conductorService.obtenerConductores().subscribe(
      conductores => {
        this.dataSource = fillTable(conductores, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError('Error al obtener conductores', 'Ok')
      }
    )


  }
}
