import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DetalleItem, DetalleProd } from 'src/app/models';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-add-detail-product',
  templateUrl: './add-detail-product.component.html',
  styleUrls: ['./add-detail-product.component.css']
})
export class AddDetailProductComponent implements OnInit {

  id: any;
  item: any;
  description: any;
  itemsProducto: any [] = [];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<AddDetailProductComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DetalleProd,
    private _authService: AuthenticationService,
  ) {
    this.itemsProducto = this.data.detalle_item;
  }

  ngOnInit(): void {
  }

  close(): void {
    this._bottomSheetRef.dismiss(this.data);
    event.preventDefault();
  }

  agregarItem(f: NgForm): void {
    this.id = new Date().getTime();
    let detalleItem = new DetalleItem();
    detalleItem.id = this.id;
    detalleItem.ocf_descri = this.description;
    detalleItem.ocf_coduse = this._authService.getUsuarioSistema()

    this.itemsProducto.push(detalleItem);
    this.data.detalle_item =this.itemsProducto;
    f.resetForm();

  }

  eliminarItem(id) {
    this.data.detalle_item.forEach((element, index) => {
      if(element.id == id) this.itemsProducto.splice(index, 1);
    })
    this.data.detalle_item = this.itemsProducto;
  }

}
