import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Pais } from "src/app/models/ventas/pais";
import { ApiPaisVentasService } from "src/app/services/api/ventas/maestros/pais.service";

@Component({
  selector: 'pais-form',
  templateUrl: './pais-form.component.html',
  styleUrls: ['./pais-form.component.scss']
})
export class PaisFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;

  btnName: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;

  uid: string;

  loading$: Subscription;
  pais$: Subscription;

  pais: Pais;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _apiPaisVentasService: ApiPaisVentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _router: Router
  ) {
    this.pais = new Pais();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => this.uid = id)

    if(this.uid == '0') {
      this.btnName = 'general.button.store';
      return;
    }

    this.btnName = 'general.button.update';
    this.obtenerPais();
  }

  obtenerPais(): void {
    this.pais$ = this._apiPaisVentasService.obtenerPais(this.uid).subscribe(
      pais => {
        console.log('pais', pais)
        this.pais = pais[0];
      },
      error => {
        this._snackBarService.showError('Error al obtener país', 'Ok');
      }
    )
  }

  registrarPais(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-ventas/paises'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }


}
