import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LineaDocumento } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class LineaDocumentoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
  *
  * @returns
  */
  listarLineaDocumento() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascobranza/listalineacobranza`
    );
  }



  /**
   *
   * @param linea
   * @returns
   */
  registrarLineaDocumento(linea: LineaDocumento): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/linea-de-documento`,
      linea
    );
  }

  /**
   *
   * @param linea
   * @returns
   */
  actualizarLineaDocumento(linea: LineaDocumento): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/cuentasxcobrar/linea-de-documento`,
      linea
    );
  }
}
