import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable, searchInTable } from '@utils/tables/table';
import { AuthenticationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { EditaDeduccion } from 'src/app/models/planilla/operaciones/edita-deduccion';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { MatSelectChange } from '@angular/material/select';
import { CargarColaboradoresNominaDialog } from '@shared/components/dialogs/planilla/cargar-colaboradores-nomina/cargar-colaboradores-nomina.component';
import { MatDialog } from '@angular/material/dialog';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-edita-deducciones',
  templateUrl: './edita-deducciones.component.html',
  styleUrls: ['./edita-deducciones.component.css']
})
export class EditaDeduccionesComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  total: number = 0;

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['actions', 'cli_codcli', 'ndt_imprem'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  conceptos: any[] = [];

  concepto: string;

  year: string;
  month: string;

  tipoNomina: string;
  secNomina: string;

  npe_codnpe: string;

  concepto$: Subscription;
  detalle$: Subscription;
  period$: Subscription;
  nomina$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  registros: Array<EditaDeduccion> = [];

  colaboradores: any[] = [];

  estanHabilitados: boolean = false;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>,
    public dialog: MatDialog
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.nomina$ = this.store.select('nomina').subscribe(state => {
      this.tipoNomina = state.typeNomina;
      this.secNomina = state.secNomina;

      this.npe_codnpe = `${this.year}${this.month}${state.typeNomina}${state.secNomina}`;

    })

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  trackByConcepto(index, concepto): string {
    return concepto? concepto.codnco: undefined;
  }

  trackByColaborador(index, colaborador): string {
    return colaborador? colaborador.codcli: undefined
  }

  /**
   * Obtiene los maestros de :
   * . conceptos de deducción
   * . colaboradores
   */
  loadData(): void {

    forkObs(
      this._nominaService.listarConceptosDeducciones(this.year, this.month),
      this._nominaService.listarColaboradores()
    ).then(([conceptos, colaboradores]) => {
      this.conceptos = conceptos.filter(item => item.indtip == "D");
      this.colaboradores = colaboradores;

    }).catch(error => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })

  }

  seleccionarColaborador(event: MatSelectChange, row) {
    let existe = false;
    this.registros.forEach(item => {
      if(item.isEditing == false)
        if(row.codcli == item.codcli) existe = true;
    });
    if(existe) {
      this._snackBarService.showError('Este colaborador ya existe en la tabla', 'Ok');
      event.source.value = "";
      return;
    }

    const colaborador = this.colaboradores.find(item => item.codcli == row.codcli);

    row.nomcli = colaborador.nomcli;
  }

  seleccionarConcepto(event): void {
    this.detalle$ = this._nominaService.verDetalleConceptoDeducciones(`${this.year}${this.month}${this.tipoNomina}${this.secNomina}`, event).subscribe(
      detalle => {
        this.total = 0;
        detalle.forEach((item, key) => {
          this.total += item.imprem;
          item['correlativo'] = key;
          item['isEditing'] = true;
          item['imprem'] = Number(item['imprem']).toFixed(2);
        });

        this.total = Number(this.total.toFixed(2));
        this.registros = detalle;

        this.dataSource = fillTable(detalle, this.paginator, this.sort);

      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  esValidoTabla(): boolean {
    let validacion = true;
    this.registros.forEach(item => {
      if(!item.codcli) {
        validacion = false;
      }
    })

    return validacion;
  }

  blurImporte(row): void {

    row.imprem = Number(row.imprem).toFixed(2)

    this.total = 0;
    this.registros.map(item => {
      this.total += Number(item.imprem);
    })

  }

  registrarEditaDeducciones(f: NgForm): void {

    this.registros.forEach(item => {
      item.imprem = Number(item.imprem);
    })

    console.log('registros', this.registros)

    if(!this.esValidoTabla()) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok');
      return ;
    };

    this.estanHabilitados = !this.estanHabilitados;
    this.registros.forEach(item => {
      item.isEditing = true;
    });

    let isValid = true;
    this.registros.forEach(item => {
      if(item.isEditing) isValid = false;
    });

    // if(!isValid) {
    //   this._snackBarService.showError('Ninguna fila debe de estar en modo edición', 'Ok');
    //   return;
    // }

    console.log('this.registros', this.registros);

    this.loaderReg = true;
    this.sendForm$ = this._nominaService.registrarEditaDeduccionNomina(this.registros, this.npe_codnpe, this.concepto).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Edita deducciones registrado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  editarTodos(): void {
    this.estanHabilitados = !this.estanHabilitados;
    this.registros.forEach(movimiento => {
      movimiento.isEditing = true;
    });

  }

  confirmarTodos(): void {
    this.estanHabilitados = !this.estanHabilitados;
    this.registros.forEach(item => {
      item.isEditing = true;
    });
  }

  doubleClick(event, row): void {
    /*
    if(row.isEditing) {
      if(!row.codcli) {
        this._snackBarService.showError('Todos los campos de fila deben estar compeltos', 'Ok')
        return;
      }
      row.isEditing = !row.isEditing;
      this.total = 0;
      this.registros.map(item => {
        this.total += item.imprem;
      })

      return;
    }

    row.isEditing = !row.isEditing;
    this.inicializarSelectColaboradores();
    */
  }

  agregarItem(): void {
    if(!this.concepto) {
      this._snackBarService.showError('Seleccionar un concepto', 'Ok')
      return;
    }

    this.registros.push(new EditaDeduccion());
    this.registros.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.registros, this.paginator, this.sort);
  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row): void {
    if(!row.codcli) {
      this._snackBarService.showError('Todos los campos de fila deben estar compeltos', 'Ok')
      return;
    }
    row.isEditing = !row.isEditing;
    this.total = 0;
    this.registros.map(item => {
      this.total += Number(item.imprem);
    })
  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSource.data.filter(item => item.correlativo != row.correlativo);
        filtered.forEach((element, key) => {
          element['correlativo'] = key;
        })
        this.registros = filtered;
        this.dataSource = fillTable(filtered, this.paginator, this.sort);
      }
    })
  }

  cargarColaboradores(): void {
    if(!this.concepto) {
      this._snackBarService.showError('Seleccionar un concepto', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(CargarColaboradoresNominaDialog, {
      width: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {

      let colaboradoresEnTabla = this.dataSource.data;
      const colaboradoresCargados = result;

      this.estanHabilitados = !this.estanHabilitados;

      let colaboradoresAgregados = [];

      colaboradoresCargados.forEach(it => {
        let existe = false;
        colaboradoresEnTabla.forEach(item => {
          if(it.codigo.trim() == item.codcli.trim()) {
            existe = true;
          }
        })
        if(!existe) {
          colaboradoresAgregados.push({
            codcli: it.codigo,
            nomcli: it.name,
            imprem: 0,
            isEditing: true,
          })
        };
      })

      colaboradoresAgregados.forEach(item => colaboradoresEnTabla.push(item));

      this.registros = colaboradoresEnTabla;

      this.dataSource = fillTable(colaboradoresEnTabla, this.paginator, this.sort);
      colaboradoresAgregados.forEach(item => this.total += item.imprem);
      this.total = Number(this.total);

    })
  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.concepto$,
      this.detalle$,
      this.period$,
      this.nomina$,
      this.loading$,
      this.sendForm$
    ]);
  }

}
