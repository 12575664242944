import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Motive } from 'src/app/models';
import { AlmacenService, AuthenticationService, ConfigurationService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-motivo-form',
  templateUrl: './motivo-form.component.html',
  styleUrls: ['./motivo-form.component.scss']
})
export class MotivoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['codigo', 'nombre', 'cod_sucursal', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  motive: Motive;

  motivo$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  motivosCredito: any[] = [];
  motivosDebito: any[] = []

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _router: Router,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _almacenService: AlmacenService,
    private store: Store<PavsoState>
  ) {
    this.motive = new Motive();
    this.motive.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.motive.mmo_fecupd = new Date();
    this.motive.mmo_coduse = this._authService.getUsuarioSistema();
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadMaestros();
    })
  }

  loadMaestros(): void {
    this._almacenService.listarMotivoCreditoSunat().subscribe(
      motivosCredito => {
        this.motivosCredito = motivosCredito;

        this._almacenService.listarMotivoDebitoSunat().subscribe(
          motivosDebito => this.motivosDebito = motivosDebito,
          error => this._snackBarService.showError(error.error.msg, "OK")
        )
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  registrarMotivo(f: NgForm): void {
    this.loaderReg = true;
    this.motivo$ = this._almacenService.registrarMotivo(this.motive).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Motivo registrado', '300px', '', '');
        f.resetForm();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK')
      }
    )
  }

  volver():void {
    this._router.navigate(['/modulo-almacen/motivos']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.motivo$,
      this.loading$
    ])
  }

}
