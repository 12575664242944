export class VigenciaImpuesto {
  PAI_CODPAI: string;
  TIM_CODTIM: string;
  TIV_FECVIG: Date;
  TIV_PORTIM: number;
  TIV_IMPAPL: number;
  TIV_FECUPD: Date;
  TIV_CODUSE: string;

  constructor() {
    this.TIV_FECUPD = new Date();
  }
}
