import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlmacenService } from 'src/app/services';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-certificado-digital-conta-list',
  templateUrl: './certificado-digital-conta-list.component.html',
  styleUrls: ['./certificado-digital-conta-list.component.css']
})
export class CertificadoDigitalContaListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'CHO_CODCHO', 'CHO_NOMBRE', 'CHO_NROLIC'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  conductor$: Subscription;
  loading$: Subscription;
  constructor(
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.obtenerConductores();
    })
  }

  obtenerConductores(): void {
    this.conductor$ = this._almacenService.listarChoferes().subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.conductor$]);
  }

}
