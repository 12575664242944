import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { LoaderService } from "src/app/services/loader.service";

@Component({
    selector: 'pav-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss']
})
export class LoaderComponent implements OnInit {

  isLoading: Subject<boolean>;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.isLoading = this.loaderService.isLoading;
    }, 0);
  }

}
