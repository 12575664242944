<app-pavso-title-section [title]="'Tipo de documento'" [module]="'Contabilidad'" [usuario]="driver.CHO_CODUSE" [fecha]="driver.CHO_FECUPD" [ngClass]="'pav-form'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" matInput placeholder="Código" name="cli_codcli" required [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput placeholder="Descripción" name="descripcion" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cod. Sunat</mat-label>
            <input type="text" matInput placeholder="Cod. Sunat" name="cod_sunat" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nro. Licencia</mat-label>
            <input type="text" matInput placeholder="Nro. Licencia" name="nro_licencia" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-checkbox class="example-margin">Generar Cuenta Corriente</mat-checkbox>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Signo</mat-label>
            <input type="text" matInput placeholder="Signo" name="signo" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Dígito Serie</mat-label>
            <input type="text" matInput placeholder="Dígito Serie" name="digito_serie" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Dígito Número</mat-label>
            <input type="text" matInput placeholder="Dígito Número" name="digito_numero" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Excepción Estado SUNAT</mat-label>
            <input type="text" matInput placeholder="Excepción Estado SUNAT" name="excepcion_estado_sunat" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-checkbox class="example-margin">Prov. Compras</mat-checkbox>
        </div>
        <div class="col s12 m12 l2">
          <mat-checkbox class="example-margin">Almacén</mat-checkbox>
        </div>
        <div class="col s12 m12 l2">
          <mat-checkbox class="example-margin">Banco</mat-checkbox>
        </div>
        <div class="col s12 m12 l2">
          <mat-checkbox class="example-margin">Rend. Cuentas</mat-checkbox>
        </div>
        <div class="col s12 m12 l2">
          <mat-checkbox class="example-margin">Ref. Doc. Compras</mat-checkbox>
        </div>
      </div>

    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/tipos-de-documento">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
