import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TipoAtencion } from "src/app/models/crm/tipo-atencion";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class TipoAtencionService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerTiposAtencion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/tipo-atencion/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerTipoAtencion(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/tipo-atencion/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  registrarTipoAtencion(tipo: TipoAtencion): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/tipo-atencion`,
      tipo
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  actualizarTipoAtencion(tipo: TipoAtencion): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/tipo-atencion`,
      tipo
    );
  }
}
