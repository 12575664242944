export class ClienteCRM {
  coduse: string;
  idmtk: string;
  indsta: string;
  descri: string;

  constructor() {
    this.indsta = '1';
  }
}
