export class TipoImpuesto {
  tim_idtim: number;
  pai_codpai: string;
  tim_codtim: string;
  tim_descri: string;
  tim_indsta: string;
  tim_coduse: string;
  tim_fecupd: Date;
  tim_inddet: number;
  t54_codt54: string;

  constructor() {
    this.tim_indsta = "1";
    this.tim_fecupd = new Date();
  }
}
