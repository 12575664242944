<app-pavso-title-section [title]="'Asientos de planilla'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="scrollmenu">
    <a *ngFor="let reporte of reportes" [ngClass]="{'pav-report-item': reporteSeleccionado == reporte.id, '': reporteSeleccionado != reporte.id}" (click)="reporteSeleccionado[0]=reporte.id; seleccionarReporte()">
      <p>
        {{reporte.name}}
      </p>
    </a>
  </div>
  <br>

  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtro</div>
  </div>
  <div class="pav-filtro-container">
    <div class="row">

      <div class="col s12 m12 l4" *ngIf="mostrarFiltroAuxiliar == 1">

        <select-search
          [label]="'Colaborador'"
          [placeholder]="'Ej. '"
          [name]="'codcli'"
          [value]="'codcli'"
          [description]="'nomcli'"
          [data]="trabajadores"

          [multiple]="true"
          >
        </select-search>

      </div>
      <div class="col s12 m12 l4" *ngIf="mostrarFiltroCentro == 1">

        <select-search
          [label]="'Centro de costo'"
          [placeholder]="'Ej. '"
          [name]="'ccs_codccs'"
          [value]="'ccs_codccs'"
          [description]="'ccs_descri'"
          [data]="centros"

          [multiple]="true"
          >
        </select-search>

      </div>


    </div>
  </div>
  <br>
  <div align="center">
    <button class="pav-button-action" mat-flat-button color="primary" (click)="verReporte()" [class.spinner]="loading" [disabled]="loading">{{buttonsName.BTN_SHOW_REPORT}}</button>
  </div>

</div>
