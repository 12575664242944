import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Driver } from 'src/app/models';
import { AuthenticationService, AlmacenService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-planilla-letra-banco',
  templateUrl: './planilla-letra-banco.component.html',
  styleUrls: ['./planilla-letra-banco.component.css']
})
export class PlanillaLetraBancoComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'sit', 'operacion', 'cliente', 'nombre', 'nro_letra', 'nro_banco', 'fec_venc', 'mo', 'importe_nacional', 'importe_dolares'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns2: string[] = ['codigo', 'mn', 'me'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  auxiliares: any[] = [];
  cuentasBanco: any[] = [];
  monedas: any[] = [];
  bancos: any[] = [];
  lineas: any[] = [];

  fecha: Date = new Date();
  usuario: any;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  auxiliar$: Subscription;
  loading$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>
  ) {

    this.driver = new Driver();

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.dataSource2 = new MatTableDataSource([
      {
        name: "Ingresadas",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Renovadas",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Canceladas",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Devueltas",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Protestadas",
        mn: 0.00,
        me: 0.00
      }
    ]);
  }

  /**
   * Obtiene los maestros de :
   * . auxiliares
   */
  loadData(): void {
    this.loaderData = true;

    this.driver.CHO_INDSTA = "1";

    this.auxiliar$ = this._almacenService.listarAuxiliares().subscribe(
      auxiliares => {
        this.auxiliares = auxiliares;
        this.loaderData = false;

      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  registrarChofer(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.auxiliar$,
      this.loading$
    ])
  }

}
