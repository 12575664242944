import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComprasService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { verPdfImportaciones } from '@utils/pdfmaker/builder/importacion.pdfmaker';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { OrdenCompraService } from 'src/app/services/api/compras/operaciones/orden-compra.service';

@Component({
  selector: 'app-orden-importacion-list',
  templateUrl: './orden-importacion-list.component.html',
  styleUrls: ['./orden-importacion-list.component.css']
})
export class OrdenImportacionListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'ocm_numocm', 'toc_codtoc', 'ocm_fecocm', 'ldc_dirldc'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['ver_req', 'num_req', 'fec_emision', 'fec_pago', 'moneda', 'importe'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild("matPaginator1") paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  loader1: boolean = false;

  displayedColumns2: string[] = ['ver_doc', 'tipo_doc', 'num_doc', 'fec_emision', 'moneda', 'importe', 'abono', 'saldo', 'codigo'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild("matPaginator2") paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  loader2: boolean = false;
  orden$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  constructor(
    private _snackBarService: SnackBarService,
    private _comprasService: ComprasService,
    private _ordenCompraService: OrdenCompraService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerOrdenesDeCompra();
    })
  }

  obtenerOrdenesDeCompra(): void {
    this.orden$ = this._ordenCompraService.obtenerOrdenesCompra().subscribe(
      response => {
        this.dataSource = fillTable(response.filter((element) => element.toc_codtoc == "E"), this.paginator, this.sort);
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  mostrarPdf(id): void {
    this._comprasService.obtenerPdfOrdenCompra(id).subscribe(
      response => {
        if(response.length > 0 && response != null) {
          verPdfImportaciones(response[0]);
        }
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.orden$
    ])
  }
}
