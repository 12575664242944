<div class="row">
  <div class="col s12 m12 l6">
    <div class="overflow-x">
      <h3>Documentos válidos</h3>
      <table mat-table [dataSource]="dataSourceValidos" matSort>

        <ng-container matColumnDef="nro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Estado" class="td-responsive"> {{ i + 1 }} </td>
        </ng-container>

        <ng-container matColumnDef="cpe">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
          <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.cpe}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsValidos"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsValidos;" [ngStyle]="{cursor: 'pointer'}"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="20">No se encontraron registros</td>
        </tr>

      </table>

    </div>

    <mat-paginator #paginatorValidos showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

  </div>
  <div class="col s12 m12 l6">
    <div class="overflow-x">
      <h3>Documentos no válidos</h3>
      <table mat-table [dataSource]="dataSourceNoValidos" matSort>

        <ng-container matColumnDef="nro">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Estado" class="td-responsive"> {{ i + 1 }} </td>
        </ng-container>


        <ng-container matColumnDef="documento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
          <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.documento}} </td>
        </ng-container>

        <ng-container matColumnDef="cpe">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
          <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.cpe}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsNoValidos"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsNoValidos;" [ngStyle]="{cursor: 'pointer'}"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="20">No se encontraron registros</td>
        </tr>

      </table>

    </div>

    <mat-paginator #paginatorNoValidos showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
