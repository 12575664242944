<h1 mat-dialog-title>Copiar solicitud</h1>

<div class="row">
  <div class="col s12 m12 l12">

    <select-search [label]="'Solicitud'" [placeholder]="'Ej. 10003'" [name]="'rco_numrco'" [value]="'rco_numrco'"
      [description]="'rco_numrco'" [data]="solicitudes" [model]="solicitudSeleccionada"
      (cambioSelect)="solicitudSeleccionada = $event">
    </select-search>

  </div>
  <div class="col s12" align="end">
    <button mat-stroked-button color="primary" (click)="copiar()">Copiar</button>
  </div>
</div>
