export class GeneraFactura {

  fechaGuia: Date;
  documentoGuia: string;
  transportistaGuia: string;

  codcia: any;
  codsuc: any;
  numpcc: any;
  indfac: any;
  codtdo: any;
  numser: any;
  numfac: any;
  fecdoc: any;
  codfor: any;
  indgui: any;
  sergui: any;
  nrogui: any;
  codtie: any;
  clitra: any;
  codcho: any;
  nomcho: any;
  nrolic: any;
  nrocer: any;
  codutr: any;
  marund: any;
  plaund: any;
  punint: any;
  codldc: any;
  codage: any;
  codpai: any;
  indsal: any;
  numegv: any;

  constructor() {}

}
