<div class="pav-filtro-header">
  <div class="pav-filtro-header-icon"></div>
  <div class="pav-filtro-header-title">Producto</div>
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)"
      [placeholder]="'Buscar...'" #input>
  </div>
</div>

<div class="mat-elevation-z0">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
            <td mat-cell *matCellDef="let row" data-label="Correo Electrónico"> {{row.DESC_ESPA}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.prd_codprd}} </td>
        </ng-container>

        <ng-container matColumnDef="um">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad de medida </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.UNIDAD}} </td>
        </ng-container>

        <ng-container matColumnDef="tipo_inventario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de inventario </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.DESC_INVENTARIO}} </td>
        </ng-container>

        <ng-container matColumnDef="linea_producto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Línea de producto </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre"> {{row.lpd_descri}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="seleccionarProducto(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">No se encontraron registros "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<br>
<div align="end">
    <button mat-flat-button type="button" class="pav-button-cancel" (click)="cancelar()">{{buttonName.BTN_CLOSE | translate}}</button>
</div>
