import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { Subscription } from "rxjs";
import { ConfigurationService } from "src/app/services";
import { PrinterService } from "src/app/services/api/printer.service";

export class PreferenciaImpresion {
  cia_codcia: string;
  s10_usuario: string;
  s07_codopc: string;
  s73_indvpr: number;
  s73_tampap: string;
  s73_fecupd: Date;

  constructor() {
    this.s73_indvpr = 1;
    this.s73_tampap = 'A7';

    this.s73_fecupd = new Date();
  }
}

@Component({
  selector: 'app-preferencias-impresion-dialog',
  templateUrl: './preferencias-impresion.dialog.html',
  styleUrls: ['./preferencias-impresion.dialog.scss']
})
export class PreferenciasImpresionDialog implements OnInit {

  preferencia: PreferenciaImpresion;
  printerPreference$: Subscription;
  program$: Subscription;
  modificarPreferencia$: Subscription;
  program: string;

  loaderUpdate: boolean;

  constructor(
    public dialogRef: MatDialogRef<PreferenciasImpresionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _printerService: PrinterService,
    private readonly _configurationService: ConfigurationService,
    private readonly _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.preferencia = new PreferenciaImpresion();
    this.preferencia.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.preferencia.s10_usuario = this._configurationService.obtenerIdUsuario();
    this.preferencia.s07_codopc = this._configurationService.obtenerPrograma();
  }

  ngOnInit(): void {
    this.program$ = this.store.select('currentPage').subscribe(state => {
      this.program = state.program;
      console.log('this.program', this.program)
      this.obtenerConfiguracion()
    })
  }

  obtenerConfiguracion(): void {

    this.printerPreference$ = this._printerService.obtenerConfiguracionImpresora(this._configurationService.obtenerIdUsuario(), this.program).subscribe(
      preferencia => {
        console.log('preferencia', preferencia)
        this.preferencia.s73_indvpr = preferencia.s73_indvpr;
        this.preferencia.s73_tampap = preferencia.s73_tampap;
      },
      error => {
        this._snackBarService.showError('Error al obtener preferencias de impresión', 'Ok');
      }
    )
  }

  aceptar(): void {
    this.loaderUpdate = true;
    console.log('this.preferencia', this.preferencia)
    this.modificarPreferencia$ = this._printerService.actualizarConfiguracionImpresora(this.preferencia).subscribe(
      response => {
        this.loaderUpdate = false;
        this._snackBarService.showSuccess('Preferencia de impresión actualizada', 'Ok');
        this.dialogRef.close(this.preferencia);
      },
      error => {
        this.loaderUpdate = false;
        this._snackBarService.showError('Error al actualizar preferencia de impresión', 'Ok')
      }
    )

  }

}
