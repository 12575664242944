export class MaestrosLS {
  clientes: Array<any>;
  proveedores: Array<any>;
  auxiliares: Array<any>;
  tiposDocumento: Array<any>;
  tiposMoneda: Array<any>;
  flujosCaja: Array<any>;
  productos: Array<any>;
  motivos: Array<any>;
  condicionesPago: Array<any>;
  almacenes: Array<any>;
  lugaresDespacho: Array<any>;
  vendedores: Array<any>;
  centrosCosto: Array<any>;
  tiposPrecio: Array<any>;
  transportistas: Array<any>;
  unidadesMedida: Array<any>;
  cotizaciones: Array<any>;
  promocionesVenta: Array<any>;
  descuentos: Array<any>;
  formasCalculo: Array<any>;

  constructor() {
    this.clientes = [];
    this.proveedores = [];
    this.auxiliares = [];
    this.tiposDocumento = [];
    this.tiposMoneda = [];
    this.flujosCaja = [];
    this.productos = [];
    this.motivos = [];
    this.condicionesPago = [];
    this.almacenes = [];
    this.lugaresDespacho = [];
    this.vendedores = [];
    this.centrosCosto = [];
    this.tiposPrecio = [];
    this.transportistas = [];
    this.unidadesMedida = [];
    this.cotizaciones = [];
    this.promocionesVenta = [];
    this.descuentos = [];
    this.formasCalculo = [];

  }
}
