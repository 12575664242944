<app-pavso-title-section [title]="'Paso de saldos'" [module]="'Almacén'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <!-- <button mat-flat-button (click)="isLinear = !isLinear" id="toggle-linear">
    {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
  </button> -->
  <mat-stepper [linear]="isLinear" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Revisar Saldo Negativo</ng-template>
        <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="prd_codprd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.prd_codprd}} </td>
            </ng-container>

            <ng-container matColumnDef="prd_desesp">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descri. Prod. </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.prd_desesp}} </td>
            </ng-container>

            <ng-container matColumnDef="ume_codume">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Unid. Med. </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.ume_codume}} </td>
            </ng-container>

            <ng-container matColumnDef="alm_codalm">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Almacen </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.alm_codalm}} </td>
            </ng-container>

            <ng-container matColumnDef="ALM_DESCRI">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacen </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.ALM_DESCRI}} </td>
            </ng-container>

            <ng-container matColumnDef="spa_canini">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Inicial </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.spa_canini}} </td>
            </ng-container>

            <ng-container matColumnDef="spa_caning">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ingresos </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.spa_caning}} </td>
            </ng-container>

            <ng-container matColumnDef="spa_cansal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Salidas </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.spa_cansal}} </td>
            </ng-container>

            <ng-container matColumnDef="spa_canfin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Final </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.spa_canfin}} </td>
            </ng-container>

            <ng-container matColumnDef="spa_mnpfin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Final M.N. </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.spa_mnpfin}} </td>
            </ng-container>

            <ng-container matColumnDef="spa_mepfin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Final M.E. </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.spa_mepfin}} </td>
            </ng-container>

            <ng-container matColumnDef="TIN_DESCRI">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Inventario </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.TIN_DESCRI}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="11">No se encontraron registros</td>
            </tr>

          </table>

          <mat-paginator color="primary" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
        <div class="btn-actions">
          <button mat-flat-button color="primary" (click)="revisar()" [class.spinner]="loadingRevisar" [disabled]="loadingRevisar">Revisar</button>
          <button mat-button matStepperNext>Siguiente</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="Procesar Paso de Saldo">
      <form [formGroup]="secondFormGroup">

        <div>
          <button mat-button matStepperPrevious>Anterior</button>
          <button mat-flat-button color="primary" (click)="procesar()" [class.spinner]="loadingProcesar" [disabled]="loadingProcesar">Procesar</button>
          <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
        </div>
      </form>
    </mat-step>

  </mat-stepper>

</div>
