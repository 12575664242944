<div class="row pav-panel">
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'Artículos debajo del stock mínimo'" [amount]="2"
      [bgColorTitle]="'pav-yellow'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'(%) Rotacion de inventarios '" [amount]="0"
      [bgColorTitle]="'pav-green'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'Dias de inventario'" [amount]="0"
      [bgColorTitle]="'pav-blue'"></card-dashboard-resume>
  </div>
</div>

<div class="row">
  <div class="col s12 m12 l6">
    <mat-card class="mat-elevation-z0">
      <mat-list>
        <div mat-subheader>Stock mínimo</div>
        <mat-list-item *ngFor="let item of data">
          <mat-icon mat-list-icon>shopping_bag</mat-icon>
          <div mat-line>{{item.prd_codprd}} | {{item.prd_desesp}}</div>
          <div mat-line> Stock actual: {{item.spa_canfin}} | Por debajo del stock mínimo: {{item.prd_stomin}} |
            Diferencia: {{item.spa_canfin - item.prd_stomin}} </div>
          <div mat-line>Lote ideal de compra: 0</div>
        </mat-list-item>
        <mat-divider></mat-divider>

      </mat-list>
      <br>
      <button mat-button color="accent" (click)="generarExcel()">
        <mat-icon svgIcon="excel"></mat-icon>
        Descargar Excel
      </button>

    </mat-card>

  </div>
  <div class="col s12 m12 l6">
    <mat-card class="mat-elevation-z0">
      <mat-list>
        <div mat-subheader>Lotes vencidos</div>
        <mat-list-item *ngFor="let item of lotesVencidos">
          <mat-icon mat-list-icon>turned_in</mat-icon>
          <div mat-line>{{item.prd_codprd}} | {{item.prd_desesp}}</div>
          <div mat-line>
            <span [ngStyle]="{color: 'red'}">{{item.nroLotesVencidos}} Vencidos</span>
            <span [ngStyle]="{color: 'orange', marginLeft: '10px'}">{{item.nroLotesPorVencer}} Por vencer</span>

          </div>
        </mat-list-item>
        <mat-divider></mat-divider>

      </mat-list>
      <br>
      <button mat-button color="accent" (click)="generarExcelLotesVencidos()">
        <mat-icon svgIcon="excel"></mat-icon>
        Descargar Excel
      </button>

    </mat-card>
  </div>
</div>
<br />
<div class="row">
  <div class="col s12 m12 l6">
    <mat-card class="mat-elevation-z0">

      <div id="chart">
        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis" [title]="chartOptions.title"
          [stroke]="chartOptions.stroke" [legend]="chartOptions.legend"></apx-chart>
      </div>

      <div class="row">
        <div class="col s12 m12 l3"></div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Año</mat-label>
            <mat-select [(ngModel)]="anioChart" name="chart">
              <mat-option *ngFor="let year of ['2024','2023','2022','2021','2020']" [value]="year">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3"></div>
      </div>

    </mat-card>
  </div>
  <div class="col s12 m12 l6">
    <mat-card class="mat-elevation-z0">

      <div id="chart">
        <apx-chart [series]="chartOptions2.series" [chart]="chartOptions2.chart" [dataLabels]="chartOptions2.dataLabels"
          [plotOptions]="chartOptions2.plotOptions" [xaxis]="chartOptions2.xaxis" [title]="chartOptions2.title"
          [stroke]="chartOptions2.stroke" [legend]="chartOptions2.legend"></apx-chart>
      </div>
      <div class="row">
        <div class="col s12 m12 l3"></div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Año</mat-label>
            <mat-select [(ngModel)]="anioChart2" name="chart2">
              <mat-option *ngFor="let year of ['2024','2023','2022','2021','2020']" [value]="year">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3"></div>
      </div>

    </mat-card>
  </div>
</div>
