import { Injectable } from '@angular/core';
import { utils, writeFileXLSX } from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class VentasExcelService {

  constructor() { }

  exportExcelMaestroClientes(reportData) {

    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }

  exportExcelRegistroVentas(reportData) {

    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }

  exportExcelRegistroDetalladoVentas(reportData) {

    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }

  exportExcelProductoAnual(reportData) {

    const ws = utils.json_to_sheet(reportData.data);

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFileXLSX(wb, `${reportData.title}.xlsx`);

  }
}
