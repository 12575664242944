import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { TipoCampania } from "src/app/models/crm/tipo-campania";
import { ConfigurationService } from "src/app/services";
import { TipoCampaniaService } from "src/app/services/api/crm/maestros/tipo-campania.service";

@Component({
  selector: 'tipo-campania-form',
  templateUrl: 'tipo-campania-form.component.html',
  styleUrls: ['tipo-campania-form.component.scss']
})
export class TipoCampaniaFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  tipoCampania: TipoCampania;

  loading$: Subscription;
  sendForm$: Subscription;
  tipoCampania$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _tipoCampaniaServie: TipoCampaniaService,
    private _activatedRoute: ActivatedRoute,

    private _dialogService: DialogService,
  ) {

    this.tipoCampania = new TipoCampania();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;

      this.obtenerTipoCampania();
    })
  }

  obtenerTipoCampania(): void {
    this.loaderReg
    this.tipoCampania$ = this._tipoCampaniaServie.obtenerTipoCampania(this.uid).subscribe(
      tipoCampania => this.tipoCampania = tipoCampania[0],
      error => this._snackBarService.showError(error.error.msg, 'Ok'),
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;

    this.uid == '0' ? this.registrarTipoCampania(f) : this.actualizarTipoCampania(f);
  }

  /**
   * Método para registrar un nuevo tipo de campaña.
   * @param f
   */
  registrarTipoCampania(f: NgForm): void {
    this.tipoCampania.coduse = this._configurationService.obtenerIdUsuario();
    this.sendForm$ = this._tipoCampaniaServie.registrarTipoCampania(this.tipoCampania).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.tipo-campania.success-store', '400px', 'auto', '')
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar los datos de un tipo de campaña existente
   * @param f
   */
  actualizarTipoCampania(f: NgForm): void {
    this.sendForm$ = this._tipoCampaniaServie.actualizarTipoCampania(this.tipoCampania).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.tipo-campania.success-update', '400px', 'auto', '')
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {
    if(!this.tipoCampania.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    return true;
  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/tipos_campania']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.tipoCampania$,
    ])
  }

}
