import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-req-materiales-prod',
  templateUrl: './req-materiales-prod.component.html',
  styleUrls: ['./req-materiales-prod.component.css']
})
export class ReqMaterialesProdComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumnsOrd: string[] = ['td', 'orden', 'cantidad'];
  dataSourceOrd: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorOrd: MatPaginator;
  @ViewChild(MatSort) sortOrd: MatSort;

  displayedColumnsMat: string[] = ['acciones', 'item', 'codigo', 'descripcion', 'um', 'cantidad'];
  dataSourceMat: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorMat: MatPaginator;
  @ViewChild(MatSort) sortMat: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tipoDoc$: Subscription;
  loading$: Subscription;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _authService: AuthenticationService
  ) {

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();

    this.dataSourceMat = fillTable([], this.paginatorMat, this.sortMat);

    this.dataSourceOrd = fillTable([], this.paginatorOrd, this.sortOrd);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.driver.CHO_INDSTA = "1";

    this.tipoDoc$ = this._generalService.listarTipoDocumentoIdentidad().subscribe(
      tiposDocumento => {
        this.tiposDocumento = tiposDocumento;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  registrarPlanta(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoDoc$]);
  }

}
