import { Subscription } from "rxjs";

/**
 * Unsubscribe Subscriptions When fall in cycle OnDestroy
 * @param subscriptions Subscriptions
 */
export function unsubscribeSubscription(subscriptions: Array<Subscription>): void {
  subscriptions.forEach(subscription => {
    if(subscription) {
      subscription.unsubscribe();
      console.log('unsubscribe')
    }
  });
}
