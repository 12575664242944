import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";

class Modelo {

  codigo: string;
  descripcion: string;
  descripcionCorta: string;
  estado: string;

  constructor() {}

}

@Component({
  selector: 'modelo-form-almacen',
  templateUrl: './modelo-form.component.html',
  styleUrls: ['./modelo-form.component.scss']
})
export class ModeloFormComponent implements OnInit, OnDestroy {

  modelo: Modelo;

  loaderData: boolean = false;
  loaderReg: boolean = false;

  constructor() {
    this.modelo = new Modelo();
  }

  ngOnInit(): void {

  }

  enviarFormulario(f: NgForm): void {}

  ngOnDestroy(): void {

  }
}
