
<button mat-mini-fab class="isWeb" [ngStyle]="{marginTop: '2px', background: '#0368c1'}" (click)="consultarPeriodo()" matTooltip="Consultar Período" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
  <mat-icon class="pav-icon-period" svgIcon="calendar"></mat-icon>
</button>
<p class="pavso-tag-periodo">
  {{'general.label.header.activity-period' | translate}}:&nbsp;
</p>
<p class="pavso-date-period">{{ yearPeriodActividad }}-{{ monthPeriodActividad }} </p>
<!-- <button *ngIf="isNomina" class="pavso-button-period isWeb m-l-20" (click)="cambiarTipoNomina()" matTooltip="Cambiar Tipo Nómina" mat-icon-button>
  <mat-icon class="pav-icon-period" svgIcon="calendar"></mat-icon>
</button> -->

<p *ngIf="isNomina" class="pavso-tag-periodo isWeb">
  &nbsp;| Nómina:
</p>
<p *ngIf="isNomina" class="pavso-date-period">
  <!-- {{ yearPeriodActividad }} - {{ monthPeriodActividad }} -  -->
  {{
  typeNomina
  }} - {{ secNomina }} - {{ nameTipoNomina | titlecase}}</p>
<button mat-mini-fab class="isWeb m-l-20" [ngStyle]="{marginTop: '2px', background: '#0368c1'}" (click)="cerrarPeriodo()" matTooltip="Abrir / Cerrar Período" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
  <mat-icon class="pav-icon-period" svgIcon="calendar"></mat-icon>
</button>

<p class="pavso-tag-periodo">
  {{'general.label.header.opening-period' | translate}}:&nbsp;
</p>
<p class="pavso-date-period">{{ yearPeriodApertura }}-{{ monthPeriodApertura }}</p>

<p *ngIf="isNomina" class="pavso-date-period"></p>

<mat-menu #menuOtros="matMenu">
  <div class="pavso-periodo-nomina">
    <p>Período Nómina: 2021-12-1-99</p>
    <p>Nómina: EMPLEADOS</p>
  </div>
</mat-menu>
