import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { THEADER_CRM_ACTIVIDAD } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Hotkey, HotkeysService } from "angular2-hotkeys";
import { Subscription } from "rxjs";
import { ActividadService } from "src/app/services/api/crm/maestros/actividad.service";

@Component({
  selector: 'actividad-crm-list',
  templateUrl: './actividad-crm-list.component.html',
  styleUrls: ['./actividad-crm-list.component.scss']
})
export class ActividadCRMListComponent implements OnInit, OnDestroy {

  actividades$: Subscription;
  loading$: Subscription;
  loaderData: boolean;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_ACTIVIDAD;

  actividades: Array<any> = [];

  constructor(
    private store: Store<PavsoState>,
    private _actividadService: ActividadService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _hotkeysService: HotkeysService
  ) {

    this._hotkeysService.add(new Hotkey('ctrl+r', (event: KeyboardEvent): boolean => {
      console.log('Typed hotkey');
      this.obtenerActividades();
      return false;
    }));

    this._hotkeysService.add(new Hotkey('ctrl+n', (event: KeyboardEvent): boolean => {
      console.log('Typed hotkey');
      this.nuevaActividad();
      return false;
    }));

  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerActividades();
    })

  }

  obtenerActividades(): void {
    this.loaderData = true;
    this.actividades$ = this._actividadService.obtenerActividades().subscribe(
      actividades => {
        this.actividades = actividades;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  nuevaActividad(): void {
    this._router.navigate(['/modulo-crm/actividad-crm', '0']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.actividades$,
      this.loading$,
    ])
  }

}
