import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TipoDocumentoLegajo } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ApiNominaMaestrosTipoDocumentoLegajoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarTipoDocumentosLegajo(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/listar-tipos-de-documento-legajo/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  registrarTipoDocumentoLegajo(tipo: TipoDocumentoLegajo): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/tipo-documentos-legajo`,
      tipo
    )
  }

  /**
   *
   * @param tipo
   * @returns
   */
  actualizarTipoDocumentoLegajo(tipo: TipoDocumentoLegajo): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/tipo-documentos-legajo`,
      tipo
    )
  }
}
