import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithDash } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from "rxjs";
import { FiltroConsultaSeguimiento } from "src/app/models/crm";
import { CrmService, VentasService } from "src/app/services";
import { CanalService } from "src/app/services/api/crm/maestros/canal.service";
import { LineaNegocioService } from "src/app/services/api/crm/maestros/linea-negocio.service";
import { ResponsableService } from "src/app/services/api/crm/maestros/responsable.service";
import { TipoNegociacionService } from "src/app/services/api/crm/maestros/tipo-negociacion.service";
import { OportunidadService } from "src/app/services/api/crm/operaciones/crm.operaciones.oportunidad.service";
import { SeguimientoOportunidadService } from "src/app/services/api/crm/operaciones/seguimiento-oportunidad.service";

@Component({
  selector: 'app-consulta-seguimiento-oportunidad',
  templateUrl: './consulta-seguimiento-oportunidad.component.html',
  styleUrls: ['./consulta-seguimiento-oportunidad.component.scss']
})
export class ConsultaSeguimientoOportunidad implements OnInit, OnDestroy {

  buttonsName: INameConstant = NAMES_CONSTANTS;
  loaderReg: boolean = false;
  loaderSearch: boolean = false;
  loaderData: boolean = false;
  loaderSeguimientos: boolean = false;

  displayedColumns: string[] = ['acciones', 'otk_idtkt', 'otk_fecreg', 'cli_nomcli', 'oms_descri', 'ccl_nomccl', 'oln_descri', 'otn_descri', 'otk_asunto', 'oet_descri'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsSeguimientos: string[] = ['acciones', 'otk_idtkt', 'ost_idset', 'otk_fecreg', 'fec_programada', 'actividad', 'ccl_nomccl', 'ost_descri', 'ors_nombre'];
  dataSourceSeguimientos: MatTableDataSource<any>;

  @ViewChild('MatPaginatorSeguimientos') paginatorSeguimientos: MatPaginator;
  @ViewChild(MatSort) sortSeguimientos: MatSort;

  loading$: Subscription;
  etapa$: Subscription;
  linea$: Subscription;
  canal$: Subscription;
  cliente$: Subscription;
  responsables$: Subscription;
  tiposNegociacion$: Subscription;

  totalMN: number = 0;
  totalME: number = 0;

  listaEtapa: any[] = [];

  clientes: any[] = [];

  etapas: any[] = [];
  responsables: any[] = [];
  oportunidades: any[] = [];

  lineas: any[] = [];
  tipos: any[] = [];
  canales: any[] = [];

  filtro: FiltroConsultaSeguimiento;

  oportunidadSeleccionada: any = {nomcli: '', idtkt: ''};

  etapa: any;

  constructor(
    private _crmService: CrmService,
    private _canalService: CanalService,
    private _tipoNegociacionService: TipoNegociacionService,
    private _lineaNegocioService: LineaNegocioService,
    private _responsableService: ResponsableService,
    private _seguimientoOportunidadService: SeguimientoOportunidadService,
    private _oportunidadService: OportunidadService,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
  ) {

    this.filtro = new FiltroConsultaSeguimiento();
    console.log('filtro', this.filtro)

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceSeguimientos = fillTable([], this.paginatorSeguimientos, this.sortSeguimientos);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  obtenerSeguimientosPorEtapas(row): void {
    this.oportunidadSeleccionada = row.otk_idtkt

    this.etapa$ = this._seguimientoOportunidadService.obtenerSeguimientosPorEtapa(this.oportunidadSeleccionada).subscribe(
      etapas => {
        this.etapas = etapas;
        this.listaEtapa = etapas;

        this.dataSourceSeguimientos = fillTable(etapas[0].seguimientos, this.paginatorSeguimientos, this.sortSeguimientos);
      },
      error => {
        this._snackBarService.showError('Error al obtener seguimientos por etapa', 'Ok');
      }
    )
  }

  obtenerSeguimientosPorOportunidad(row): void {

    this.oportunidadSeleccionada = row;

    this.loaderSeguimientos = true;
    this.seguimientosOportunidad$ = this._seguimientoOportunidadService.obtenerSeguimientosOportunidad(row.idtkt).subscribe(
      seguimientos => {

        this.dataSourceSeguimientos = fillTable(seguimientos, this.paginatorSeguimientos, this.sortSeguimientos);
        this.loaderSeguimientos = false;

      },
      error => {
        this._snackBarService.showError('Error al obtener seguimientos por oportunidad', 'Ok');
        this.loaderSeguimientos = false;
      }
    )
  }

  seguimientosOportunidad$: Subscription;
  registrosOportunidad$: Subscription;

  loadData(): void {
    this.loaderData = true;

    this.registrosOportunidad$ = this._oportunidadService.obtenerRegistrosOportunidadConFiltro(this.filtro).subscribe(
      oportunidades => {
        console.log('oportunidades', oportunidades)
        this.oportunidades = oportunidades;
        this.dataSource = fillTable(oportunidades, this.paginator, this.sort);
        // this.dataSource
        this.seguimientosOportunidad$ = this._seguimientoOportunidadService.obtenerSeguimientosPorEtapa(this.oportunidades[0].idtkt).subscribe(
          seguimientos => {
            this.etapas = seguimientos;
            this.listaEtapa = seguimientos;

            this.canal$ = this._canalService.obtenerCanales().subscribe(
              canales => {
                this.canales = canales;

                this.linea$ = this._lineaNegocioService.obtenerLineasNegocio().subscribe(
                  lineas => {
                    this.lineas = lineas;

                    this.cliente$ = this._ventasService.obtenerClientes().subscribe(
                      clientes => {
                        this.clientes = clientes;

                        this.responsables$ = this._responsableService.obtenerResponsables().subscribe(
                          responsables => {
                            this.responsables = responsables;

                            this.tiposNegociacion$ = this._tipoNegociacionService.obtenerTiposNegociacion().subscribe(
                              tiposNegociacion => {
                                this.tipos = tiposNegociacion;
                                this.loaderData = false;
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener tipos de negociación', 'Ok');
                                this.loaderData = false;
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener responsables', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener clientes', 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener líneas de negocio', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener canales', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener seguimientos por etapa', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener registros de oportunidad', 'Ok');
        this.loaderData = false;
      }
    )

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  trackByCliente(index, cliente): string {
    return cliente ? cliente.cli_codcli : undefined;
  }

  trackByEtapa(index, etapa): string {
    return etapa ? etapa.codoet : undefined;
  }

  trackByResponsable(index, responsable): string {
    return responsable ? responsable.idres : undefined;
  }

  trackByLinea(index, linea): string {
    return linea ? linea.codoln : undefined;
  }

  trackByTipoNegociacion(index, tipo): string {
    return tipo ? tipo.codotn : undefined;
  }

  trackByCanal(index, canal): string {
    return canal ? canal.idmtk : undefined;
  }

  seleccionarEtapa(event): void {
    this.etapa = this.etapas[event.index];
    console.log('etapa', this.etapa);

    const oportunidades = this.oportunidades.filter(item => item.oet_codoet == this.etapa.codoet);
    this.dataSource = fillTable(oportunidades, this.paginator, this.sort);
    // this.dataSourceContactar = fillTable(etapa.seguimientos, this.paginatorContactar, this.sortContactar);
  }

  limpiarOportunidades(): void {
    this.filtro = new FiltroConsultaSeguimiento();
  }

  clientesSeleccionado: string;

  filtrarOportunidades(): void {
    this.loaderData = true;

    console.log('payload', this.filtro)
    this.filtro.fecini = formatDateWithDash(this.filtro.desde);
    this.filtro.fecter = formatDateWithDash(this.filtro.hasta);

    this.clientesSeleccionado = '';

    this.filtro.clientes.forEach(cliente => {
      const client = this.clientes.find(item => item.cli_codcli.trim() == cliente.trim());
      this.clientesSeleccionado += `${client.cli_nomcli}  |  `;
    })

    this._oportunidadService.obtenerRegistrosOportunidadConFiltro(this.filtro).subscribe(
      oportunidades => {
        this.loaderData = false;

        this.oportunidades = oportunidades;
        const oportunidadesFiltradas = this.oportunidades.filter(item => item.oet_codoet == this.etapa.codoet);
        this.dataSource = fillTable(oportunidadesFiltradas, this.paginator, this.sort);

        // console.log('resultados de busqueda', response);
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.etapa$,
      this.linea$,
      this.canal$,
      this.cliente$,
      this.responsables$,
      this.tiposNegociacion$,
    ])

  }

}
