<app-pavso-title-section [title]="'Participación accionaria'" [module]="'Libros electrónicos'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarParticipacion(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Ejercicio (Año)</div>
      <div class="pav-separator"></div>
    </div>

    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource" matSort >
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
            <button matTooltip="Editar participación accionaria"
              [routerLink]="['/modulo-ventas/editar-participacion-accionaria', row.cli_codcli]" mat-icon-button
              color="primary">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
        </ng-container>

        <ng-container matColumnDef="tipo_acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Acciones </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="nro_acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro Acciones </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <ng-container matColumnDef="porcentaje_participaciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> % de Participaciones </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">No se encontraron registros</td>

        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <br>

    <mat-card class="mat-elevation-z0">
      <div align="end">
        <mat-form-field>
          <mat-label>Total</mat-label>
          <input matInput placeholder="Total" type="number">
        </mat-form-field>
      </div>
    </mat-card>

    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
