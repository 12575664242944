import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_ALMACEN_DINAMICOS } from "@data/json/reportes/almacen.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subject, Subscription } from "rxjs";
import { FiltroReporteAlmacen } from "src/app/models/almacen";
import { ConfiguracionTecnica } from "src/app/models/almacen/inventario/configuracion-tecnica";
import { OpcionFiltro } from "src/app/models/general/opciones-filtro";
import { AlmacenService, GeneralService, VentasService } from "src/app/services";
import { ColorService } from "src/app/services/api/almacen/maestros/color.service";
import { ModeloService } from "src/app/services/api/almacen/maestros/modelo.service";
import { KardexService } from "src/app/services/api/almacen/operaciones/kardex.service";
import { SubfamiliaService } from "src/app/services/api/almacen/tabla-apoyo/subfamilia.service";
import { InventariosReportService } from "src/app/services/reports/modules/inventarios-report.service";
import { OtherDataFiltroAlmacen } from "../informes-unidades/informes-unidades.component";
import { UnidadMedidaService } from "src/app/services/api/almacen/configuracion/unidad-medida.service";

@Component({
  selector: 'dinamicos-almacen',
  templateUrl: './dinamicos-almacen.component.html',
  styleUrls: ['./dinamicos-almacen.component.scss']
})
export class DinamicosAlmacenComponent implements OnInit, OnDestroy {

  loaderFields: boolean = false;

  informesAlmacen: any = {
    name: 'Campos',
    completed: false,
    color: 'primary',
    informes: []
  };

  allComplete: boolean = false;

  updateAllComplete(): void {
    this.allComplete = this.informesAlmacen.informes != null && this.informesAlmacen.informes.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.informesAlmacen.informes == null) return false;

    return this.informesAlmacen.informes.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;

    if (this.informesAlmacen.informes == null) return;

    this.informesAlmacen.informes.forEach(t => t.completed = completed);
  }

  familias: any[] = [];
  subfamilias: any[] = [];
  almacenes: any[] = [];

  responsables: any[] = [];
  motivos: any[] = [];
  productos: any[] = [];
  inventarios: any[] = [];
  lineas: any[] = [];
  documentos: any[] = [];
  selecciones: any[] = [];
  tecnologias: any[] = [];
  colores: any[] = [];
  modelos: any[] = [];

  seleccion: any;

  marcas: any[] = [];
  monedas: any[] = [];
  unidadesMedida: any[] = [];

  displayedColumns: string[] = ['acciones', 'mac_nummac', 'mac_fecmac', 'mac_glomac'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  range: FormGroup;

  columnas: any[] = [];

  familia = "0001";

  movimiento$: Subscription;
  columnas$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  dataForm$: Subscription;
  filtros$: Subscription;
  establecimiento$: Subscription;
  unidadesMedida$: Subscription;

  reportes: any[] = REPORTES_ALMACEN_DINAMICOS;

  reporteSeleccionado: any = ['ALMACEN_KLA'];

  filtroAlmacen: FiltroReporteAlmacen;

  loading: boolean = true;

  opcionesFiltro: any[] = [];
  opcionFiltro: OpcionFiltro;

  year: string;
  month: string;

  protected _onDestroy = new Subject<void>();

  isMobile: boolean = false;
  isTablet: boolean = false;

  configuracion: ConfiguracionTecnica;

  establecimiento: string;

  constructor(
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private _ventasService: VentasService,
    private _modeloService: ModeloService,
    private _kardexService: KardexService,
    private _almacenReporteService: InventariosReportService,
    private readonly colorService: ColorService,
    private readonly _subfamiliaService: SubfamiliaService,
    private _generalService: GeneralService,
    private readonly _unidadMedidaService: UnidadMedidaService,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver,

  ) {

    this.configuracion = new ConfiguracionTecnica();

    this.opcionFiltro = new OpcionFiltro();

    this.filtroAlmacen = new FiltroReporteAlmacen();

    this.dataSource = fillTable([], this.paginator, this.sort);

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;

      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;

      });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
      this.filtroAlmacen.establecimiento = state.code;
    });

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    });
  }

  loadData(): void {
    this.loaderFields = true;
    this.loading = true;

    this.dataForm$ = this._almacenService.obtenerDataFiltroAlmacen().subscribe(
      dataForm => {
        this.familias = dataForm.familias;
        this.familias.forEach(item => {
          item['FPR_CODFPR'] = item['FPR_CODFPR'].trim();
          item['description_reverse'] = `${item['fpr_descri']} | ${item['FPR_CODFPR']}`
        })
        console.log('familias', this.familias)

        this.almacenes = dataForm.almacenes;
        this.almacenes.forEach(item => {
          item['description'] = `${item['ALM_CODALM']} | ${item['ALM_DESCRI']}`
          item['description_reverse'] = `${item['ALM_DESCRI']} | ${item['ALM_CODALM']}`
        })


        // Filtrar almacenes por establecimiento
        if (this.establecimiento && this.establecimiento.trim() != '') {
          this.almacenes = this.almacenes.filter(item => item.TIE_CODTIE == this.establecimiento);
        }

        this.responsables = dataForm.responsables;
        this.motivos = dataForm.motivos;
        this.motivos.forEach(item => item['description_reverse'] = `${item['mmo_descri']} | ${item['mmo_codmmo']}`)

        this.productos = dataForm.productos;
        this.productos.forEach(item => item['description_reverse'] = `${item['DESC_ESPA']} | ${item['prd_codprd']}`)

        this.inventarios = dataForm.tiposInventario;
        this.inventarios.forEach(item => item['description_reverse'] = `${item['tin_descri']} | ${item['tin_codtin']}`)

        this.lineas = dataForm.lineas;
        this.lineas.forEach(item => item['description_reverse'] = `${item['lpd_descri']} | ${item['lpd_codlpd']}`)

        this.documentos = [{ tipo: 'INGRESO', code: 'ING' }, { tipo: 'SALIDA', code: 'SAL' }];
        this.monedas = dataForm.monedas;
        this.marcas = dataForm.marcas;
        this.marcas.forEach(item => item['description_reverse'] = `${item['MPR_DESCRI']} | ${item['MPR_CODMPR']}`)

        console.log('almacenes', this.almacenes)

        this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
          configuracion => {

            this.configuracion = configuracion[0];

            if (this.configuracion.s70_indlot == 1 && this.configuracion.s70_indser == 0) {
              this.reportes = this.reportes.filter(item => (item.id != 'ALMACEN_SSA') && (item.id != 'ALMACEN_KSA'))
              console.log('UNO')
              console.log('reportes lista', this.reportes)
            }

            if (this.configuracion.s70_indlot == 0 && this.configuracion.s70_indser == 1) {
              this.reportes = this.reportes.filter(item => (item.id != 'ALMACEN_KLA') && (item.id != 'ALMACEN_SLA'))
              console.log('DOS')
            }

            this.colorService.obtenerColores().subscribe(
              colores => {
                this.colores = colores;

                this._modeloService.obtenerModelos().subscribe(
                  modelos => {
                    this.modelos = modelos;

                    this.filtros$ = this._generalService.obtenerOpcionesFiltrosReporteModulo().subscribe(
                      response => {

                        this.unidadesMedida$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                          unidades => {
                            this.unidadesMedida = unidades;
                            console.log('filtros reporte modulo', response)
                            this.opcionesFiltro = response;

                            this.obtenerColumnas('004');
                            this.seleccionarReporte({ id: 'ALMACEN_KLA',  codigo: '004' })

                            this.loaderFields = false;
                            this.loading = false;
                          },
                          error => {
                            this._snackBarService.showError(error.error.msg, 'Ok');
                            this.loaderFields = false;
                            this.loading = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError(error.error.msg, 'Ok');
                        this.loaderFields = false;
                        this.loading = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok');
                    this.loaderFields = false;
                    this.loading = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderFields = false;
                this.loading = false;
              }
            )


          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderFields = false;
            this.loading = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderFields = false;
        this.loading = false;
      }
    )

  }

  seleccionarFamilia(): void {
    this._subfamiliaService.obtenerSubFamilias(this.filtroAlmacen.familias).subscribe(
      response => {
        this.subfamilias = response;

        console.log('subfamilias', this.subfamilias)
      },
      error => this._snackBarService.showError(error.error.message, 'OK')
    )
  }

  obtenerColumnas(code): void {
    console.log('codigo de reporte', code)
    this.loaderFields = true;

    this.columnas$ = this._generalService.listarColumnasInformeDinamico('16', code).subscribe(
      columnas => {
        this.columnas = columnas;

        this.columnas.forEach(element => {
          element['id'] = element.TDR_DESTDR;
          element['name'] = element.TDR_COLNOM;
          element['completed'] = true;
        });
        console.log('columnas', this.columnas)
        this.informesAlmacen.informes = this.columnas;
        this.loaderFields = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderFields = false;
      }
    )
  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;

    console.log('codigo reporte', event.id)

    this.obtenerColumnas(event.codigo);

    switch (this.reporteSeleccionado[0]) {
      case "ALMACEN_KLA":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16006");
        break;
      case "ALMACEN_KSA":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16008");
        break;
      case "ALMACEN_KUA":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16002");
        break;
      case "ALMACEN_SLA":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16005");
        console.log('ingreso a SLA')
        break;
      case "ALMACEN_SSA":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16007");
        break;
      case "ALMACEN_SUA":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16001");
        break;
      default:
        break;
    }

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  verReporteDinamico(): void {

    this.loading = true;

    if (!this.reporteSeleccionado[0]) {
      this._snackBarService.showError('Seleccione un reporte', 'OK');
      this.loading = false;
      return;
    }

    let headers = {
      codano: this.year,
      codmes: this.month,
      currency: this.filtroAlmacen.moneda
    };

    console.log('this.informesAlmacen.informes', this.informesAlmacen.informes)

    let otherData: OtherDataFiltroAlmacen = {
      columns: this.informesAlmacen.informes,
      params: [],
      filtro: this.filtroAlmacen,
    };

    if (this.filtroAlmacen.lineas.join(',')) {
      otherData.params.push({
        param: 'linea',
        value: this.filtroAlmacen.lineas.join(',')
      })
    }

    if (this.filtroAlmacen.tecnologias.join(',')) {
      otherData.params.push({
        param: 'modelo',
        value: this.filtroAlmacen.tecnologias.join(',')
      })
    }

    if (this.filtroAlmacen.lote) {
      otherData.params.push({
        param: 'lote',
        value: this.filtroAlmacen.lote
      })
    }

    if (this.filtroAlmacen.familias.join(',')) {
      otherData.params.push({
        param: 'familia',
        value: this.filtroAlmacen.familias.join(',')
      })
    }

    if (this.filtroAlmacen.subFamilias.join(',')) {
      otherData.params.push({
        param: 'subfamilia',
        value: this.filtroAlmacen.subFamilias.join(',')
      })
    }

    if (this.filtroAlmacen.almacenes.join(',')) {
      otherData.params.push({
        param: 'almacen',
        value: this.filtroAlmacen.almacenes.join(',')
      })
    }

    if (this.filtroAlmacen.tiposInventario.join(',')) {
      otherData.params.push({
        param: 'tipoinventario',
        value: this.filtroAlmacen.tiposInventario.join(',')
      })
    }

    if (this.filtroAlmacen.unidadesMedida.join(',')) {
      otherData.params.push({
        param: 'unidadmedida',
        value: this.filtroAlmacen.unidadesMedida.join(',')
      })
    }

    if (this.filtroAlmacen.motivos.join(',')) {
      otherData.params.push({
        param: 'motivo',
        value: this.filtroAlmacen.motivos.join(',')
      })
    }


    if (this.filtroAlmacen.colores.join(',')) {
      otherData.params.push({
        param: 'categoria',
        value: this.filtroAlmacen.colores.join(',')
      })
    }

    if (this.filtroAlmacen.tiposDocumento.join(',')) {
      otherData.params.push({
        param: 'tipodocumento',
        value: this.filtroAlmacen.tiposDocumento.join(',')
      })
    }

    if (this.filtroAlmacen.productos.join(',')) {
      otherData.params.push({
        param: 'producto',
        value: this.filtroAlmacen.productos.join(',')
      })
    }

    if (this.filtroAlmacen.marcas.join(',')) {
      otherData.params.push({
        param: 'marca',
        value: this.filtroAlmacen.marcas.join(',')
      })
    }

    if(this.establecimiento != '' && this.establecimiento) {
      otherData.params.push({
        param: 'tienda',
        value: this.establecimiento
      })
    }

    this._almacenReporteService.verReporteDinamico(this.reporteSeleccionado[0], headers, otherData)
      .then(_ => this.loading = false)
      .catch(_ => this.loading = false)
      .finally(() => this.loading = false)
  }

  grabar(): void { }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.movimiento$,
      this.columnas$,
      this.loading$,
      this.periodo$,
      this.dataForm$,
      this.filtros$,
      this.establecimiento$,
      this.unidadesMedida$,
    ])
  }
}
