import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VentasService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SerieDocumentoService } from 'src/app/services/api/ventas/maestros/serie-documento.service';

@Component({
  selector: 'serie-documento-list',
  templateUrl: './serie-documento-list.component.html',
  styleUrls: ['./serie-documento-list.component.css']
})
export class SerieDocumentoListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'tipo_documento', 'Descrip', 'serie', 'estado'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  documentSeries$: Subscription;
  loading$: Subscription;

  constructor(
    private _serieDocumento: SerieDocumentoService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerSeriesDocumento();
    })
  }

  obtenerSeriesDocumento(): void {
    this.loaderData = true;
    this.documentSeries$ = this._serieDocumento.obtenerSeriesDocumento().subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.documentSeries$
    ])
  }
}
