export class GiroNegocio{
    cia_codcia: string;//código de compañía - char(2)
    gno_codgno: string;//código de giro de negocio - char(3)
    gno_desesp: string;//descripción de giro de negocio - char(50),null
    gno_desing: string;//descripción abreviada de giro de negocio - char(50),null
    gno_indsta: string;//indicador de estado - char(1),null
    gno_fecupd: Date;//fecha de actualización - datetime,null
    gno_coduse: string;//código de usuario - varchar(30),null
  
    constructor() {
        this.gno_indsta = '1';
        this.gno_fecupd = new Date();
    }
}