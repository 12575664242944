import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LineaProductoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
  *
  * @returns
  */
  obtenerLineasProducto(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablainventarios/listar/01/LINEAPRODUCTO`
    ).pipe(
      map((lineaproductos: any) => {
        return lineaproductos.map(lineaproducto => {
          lineaproducto['description'] = `${lineaproducto.lpd_codlpd} | ${lineaproducto.lpd_descri}`
          return lineaproducto;
        })
      })
    );
  }


}
