export class AutorizadoAprobarSolicitud {
  cia_codcia: string;
  cli_codcli: string;
  asc_indapr: number;
  asc_indsta: string;
  asc_coduse: string;
  asc_fecupd: Date;
  asc_usecod: string;
  asc_userem: string;
  tmo_codtmo: string;
  asc_impasc: number;
  cli_rutfot: string;
  asc_indtod: number;
  asc_corele: string;
  asc_contra: string;

  constructor() {
    this.asc_indsta = '1';
    this.asc_fecupd = new Date();
  }
}
