import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexPlotOptions, ApexTitleSubtitle, ApexTooltip, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { Driver } from 'src/app/models';
import { AuthenticationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  noData: ApexNoData;
};

@Component({
  selector: 'app-composicion-sexo-trabajadores',
  templateUrl: './composicion-sexo-trabajadores.component.html',
  styleUrls: ['./composicion-sexo-trabajadores.component.css']
})
export class ComposicionSexoTrabajadoresComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['initial', 'total'];
  dataSource = [];

  public chartOptions: Partial<ChartOptions>;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;
  total: number | string = 0;

  apexChart: ApexCharts;

  period$: Subscription;
  loading$: Subscription;
  informe$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = [
      {initial: "Femenino", total: 0},
      {initial: "Masculino", total: 0},
      {initial: "No Definido", total: 0},
      {initial: "Total Trabajadores", total: 0},
    ];

    // Configuración de Grafico
  }

  ngOnInit(): void {
    // INICIALIZAR DATOS DE GRÁFICO
    this.chartOptions = {

      series: [],
      chart: {
        height: 390,
        type: "bar",
        events: {
          click: function(chart, w, e) {
          }
        }
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
      ],
      noData: {
        text: 'Cargando...'
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        show: true,
        position: "bottom"
      },
      grid: {
        show: true
      },
      yaxis: {
        title: {
          text: "# Colaboradores",
        },
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          },
          style: {
            fontSize: "12px"
          },

        }
      },
      xaxis: {
        categories: [
          "Femenino",
          "Masculino",
          "Sin Definir",
        ],
        labels: {
          style: {
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
            ],
            fontSize: "12px"
          },
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " ";
          }
        }
      },
      title: {
        text: `Total de Colaboradores: ${this.total}`,
        offsetY: 360,
        align: "center",
        style: {
          color: "#444"
        }
      }
    };

    this.apexChart = new ApexCharts(document.querySelector("#chartSexoTrabajadores"), this.chartOptions);
    this.apexChart.render();

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this.informe$ = this._nominaService.obtenerInformeSexoTrabajadores(this.year, this.month).subscribe(
      response => {
        this.total = response.datatable[3].total;
        this.dataSource = response.datatable;

        this.apexChart.updateSeries([response.chart]);

        this.apexChart.updateOptions({
          title: {
            text: `Total de Colaboradores: ${this.total}`,
            offsetY: 0,
            align: "center",
            style: {
              color: "#444"
            }
          }
        })
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/informes-de-composicion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.informe$]);
  }

}
