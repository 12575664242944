import { INameConstant } from "@data/interfaces/constants/name.interface";

export const NAMES_CONSTANTS: INameConstant = {
  BTN_ADD: "Nuevo",
  BTN_BACK: "general.button.back",
  BTN_CLOSE: "Cerrar",
  BTN_ACCEPT: "Aceptar",
  BTN_CONFIRM: "Confirmar",
  BTN_LOGOUT: "Cerrar sesión",
  BTN_LOGIN: "Acceder",
  BTN_OK: "Ok",
  BTN_SEARCH: "Buscar",
  BTN_SHOW_CHART_SCHEDULE: "Ver estadístico",
  BTN_SHOW_SCHEDULE: "Ver cronograma",
  BTN_STORE: "general.button.store",
  BTN_UPDATE: "general.button.update",
  BTN_UPLOAD_IMAGE: "Subir imagen",
  BTN_FILTER: "Filtrar",
  BTN_SHOW_REPORT: "Ver informe",
  BTN_GENERATE_EXCEL: "Generar reporte",
  BTN_DOWNLOAD_EXCEL: "Descargar excel"
}
