export class PlantillaFichaTecnica {
  codcia: string;
  codpoc: string;
  descri: string;
  indsta: string;
  fecupd: Date;
  coduse: string;
  especificaciones: Array<EspecificacionTecnica>;

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
    this.especificaciones = [];
  }
}

export class EspecificacionTecnica {
  ordpod: string;
  pod_concep: string;
  refere: any;
  codotr: string;
  codume: any;
  forres: any;
  otr_descri: string;

  constructor() {

  }
}
