export class Campania {
  situacion: string;

  codcso: string;
  codcia: string;
  codcli: string;
  fecini: Date;
  fecter: Date;
  coduse: string;
  fecupd: Date;
  indsta: string;
  indcie: number;
  codcoc: string;
  glosa: string;
  indaut: number;


  constructor() {
    this.fecupd = new Date();
    this.fecini = new Date();
    this.indsta = '1';
    this.indaut = 0;
  }
}
