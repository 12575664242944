import { formatDateWithDash } from "@utils/formats/date.format";
import { RegistroCompra } from "./purchase_record";

export class RecepcionCompra {

  CIA_CODCIA: string;
  CCR_CODCCR: string;
  CCO_CODCCO: string;
  CLI_CODCLI: string;
  CLI_CODDOC: string;
  TDO_CODTDO: string;
  CCO_NUMDOC: string;
  CCO_GLOCCO: string;
  CCO_FECEMI: Date;
  CCO_FECVEN: Date;
  TMO_CODTMO: string;
  CCN_CODCCN: string;
  CCO_IMPDOC: number;
  CCO_IMPIGV: number;
  CCO_TASIGV: number;
  CCO_IMPCOR: number;
  CCO_IMPINA: number;
  CCO_IMPAFE: number;
  CCO_INDTCA: string;
  CCO_TIPCAM: number;
  CCO_CODPAG: string;
  MMO_CODMMO: string;
  CPA_CODCPA: string;
  TDO_DOCREF: string;
  CCO_NUMREF: string;
  ANO_CODANO: string;
  MES_CODMES: string;
  CCO_FECREG: Date;
  AGE_CODAGE: any;
  CCO_ABOTRA: number;
  CCO_CODANT: any;
  CCO_INDSTA: string;
  CCO_FECUPD: Date;
  CCO_CODUSE: string;
  CCO_IMPRET: number;
  CCO_ABORET: number;
  CCO_RETGAS: number;
  IRI_CODIRI: string;
  SUC_CODSUC: string;
  TGA_CODTGA: string;
  CCO_FECREF: Date;
  OCM_NUMOCM: string;
  OCM_DESCRI: string;
  cco_numcuo: any;
  ipi_codipi: any;
  CCO_IMPPER: number;
  CCO_PERMNA: number;
  cco_movil: any;
  cco_cbcorr: any;
  cco_cbmoti: any;
  cco_rbcorr: any;
  cco_condic: any;
  cco_code: any;
  cco_impisc: number;
  tsd_codtsd: any;
  DPC_INDDER: string;
  DPC_TASREN: number;
  DPC_TASIES: number;
  DPC_TASORE: number;
  DPC_IMPREN: number;
  DPC_IMPIES: number;
  DPC_IMPPER: number;
  DPC_IMPORE: number;
  DPC_FECREC: Date;
  DPC_VENINI: any;
  DPC_DIFIGV: number;
  DPC_AFCDER: number;
  DPC_AFSDER: number;
  DPC_IMPISC: number;
  DPC_IGVISC: number;
  DPC_TASISC: number;
  DPC_OTRTRI: number;
  DPC_VALARE: number;
  DPC_INDDIF: string;
  DPC_PERINI: any;
  DPC_IMPDIF: number;
  DPC_NUMPOL: any;
  DPC_PARARA: any;
  DPC_IMPFOB: number;
  DPC_IMPFLE: number;
  DPC_IMPSEG: number;
  DPC_IMPCIF: number;
  DPC_IMPADV: number;
  DPC_REIPAP: number;
  DPC_AFEPOL: number;
  DPC_INDDET: number;
  DPC_DEPDET: string | number;
  DPC_IMPDET: number;
  DPC_FECDET: Date;
  TIM_CODTIM: string;
  DPC_TASDET: number;
  DPC_INDNTC: number;
  DPC_ANOTRI: any;
  DPC_MESTRI: any;
  DPC_OTRREF: any;
  DPC_NUMDUA: any;
  DPC_ANODUA: string;
  OIM_NUMOIM: any;
  DPC_APOAFP: any;
  DPC_SEGAFP: any;
  DPC_COMAFP: any;
  DPC_RETAFP: any;
  CLI_INDRFP: any;
  AFP_CODAFP: any;
  CLI_COMAFP: any;
  sad_codsad: string;
  CCN_CUEGAS: any;
  dpc_impsis: number;
  dpc_impfis: number;
  t30_codt30: string;
  t31_codt31: string;
  cco_codref: string;
  dpc_impbeg: any;
  dpc_igvgex: any;
  dpc_impben: any;
  dpc_igvben: any;
  dpc_impbin: any;
  dpc_igvbin: any;
  dpc_impvng: any;
  dpc_impotc: any;
  APC_AFCDER: number
  SCO_CODSCO: string;
  CCS_CODCCS: string;
  valvta: number;
  estado: string;
  correlativo: string;
  dpc_urlpdf: string;
  proveedor: {
    cli_nomcli: string
  }

  detalle: Array<DetalleRecepcion>;
  documentos: Array<DocumentoRecepcion>;

  constructor() {
    this.CCR_CODCCR = "";
    this.CCO_CODCCO = "";
    this.CCO_INDTCA = "";
    this.TDO_DOCREF = "";
    this.CCO_NUMREF = "";
    // this.CCO_FECREF = new Date();
    this.OCM_NUMOCM = "";
    this.DPC_DEPDET = "";
    this.TIM_CODTIM = "";
    this.DPC_ANODUA = "";
    this.t31_codt31 = "";
    this.cco_codref = "";
    this.CCO_IMPCOR = 0.00;
    this.CCO_IMPINA = 0.00;
    this.CCO_ABOTRA = 0.00;
    this.CCO_IMPRET = 0.00;
    this.CCO_ABORET = 0.00;
    this.CCO_RETGAS = 0;
    this.CCO_IMPPER = 0.00;
    this.CCO_PERMNA = 0.00;
    this.cco_impisc = 0.00;
    this.DPC_TASREN = 0.00;
    this.DPC_TASIES = 0.00;
    this.DPC_TASORE = 0.00;
    this.DPC_IMPREN = 0.00;
    this.DPC_IMPIES = 0.00;
    this.DPC_IMPPER = 0.00;
    this.DPC_IMPORE = 0.00;
    this.DPC_AFSDER = 0.00;
    this.DPC_IMPISC = 0.00;
    this.DPC_IGVISC = 0.00;
    this.DPC_TASISC = 0.00;
    this.DPC_OTRTRI = 0.00;
    this.DPC_VALARE = 0.00;
    this.DPC_DIFIGV = 0.00;
    this.DPC_IMPDIF = 0.00;
    this.DPC_IMPFOB = 0.00;
    this.DPC_IMPFLE = 0.00;
    this.DPC_IMPSEG = 0.00;
    this.DPC_IMPCIF = 0.00;
    this.DPC_IMPADV = 0.00;
    this.DPC_REIPAP = 0.00;
    this.DPC_AFEPOL = 0.00;
    this.DPC_INDDET = 0;
    this.DPC_IMPDET = 0.00;
    this.DPC_TASDET = 0.00;
    this.DPC_INDNTC = 0;
    this.dpc_impsis = 0.00;
    this.dpc_impfis = 0.00;
    this.CCO_TIPCAM = 0;
    this.CCO_IMPIGV = 0;
    this.CCO_IMPDOC = 0;

    this.CCO_CODPAG = "P";
    this.CCO_INDSTA = "1";
    this.IRI_CODIRI = "00";
    this.SUC_CODSUC = "01";
    this.DPC_INDDER = "1";
    this.DPC_INDDIF = "0";
    this.APC_AFCDER = 0.00;
    this.DPC_AFCDER = 0.00;

    this.IRI_CODIRI = '01';

    this.detalle = [];

    this.valvta = 0;

    this.CCO_TASIGV = 18; // obtener desde un servicio

    this.CCO_FECREG = new Date();
    this.CCO_FECEMI = new Date();
    this.CCO_FECUPD = new Date();
    this.detalle = [];
    this.documentos = [];
    this.CPA_CODCPA = '112';

    this.DPC_AFCDER = 0;
    this.DPC_AFSDER = 0;
    this.DPC_TASREN = 0;
    this.DPC_IMPREN = 0;
    this.DPC_DIFIGV = 0;
    this.dpc_impsis = 0;
    this.dpc_impfis = 0;
    this.CCO_IMPINA = 0;
    this.CCO_IMPAFE = 0;
    this.CCO_IMPIGV = 0;
    this.CCO_IMPDOC = 0;

    this.DPC_TASDET = 0;
    this.DPC_IMPDET = 0;
    this.CCO_IMPPER = 0;
    this.DPC_DEPDET = "";

    this.DPC_IMPFOB = 0;
    this.DPC_IMPFLE = 0;
    this.DPC_IMPSEG = 0;
    this.DPC_IMPCIF = 0;
    this.DPC_IMPADV = 0;

    this.DPC_INDDER = '1';

    this.AGE_CODAGE = null;
    this.CCO_ABORET = 0
    this.CCO_ABOTRA = 0
    this.cco_cbmoti = 0
    this.CCO_CODANT = 0
    this.cco_code = null
    this.CCO_CODPAG = "P"
    this.cco_condic = null
    // this.cco_impabo = 0
    this.CCO_IMPDOC = this.CCO_IMPDOC * (-1);
    this.CCO_IMPCOR = 0
    this.CCO_IMPRET = 0
    this.CCO_INDTCA = ""
    this.cco_movil = ""
    this.cco_numcuo = ""
    this.CCO_PERMNA = 0
    this.CCO_RETGAS = 0
    // this.CCO_SALCCO = null
    // this.CCO_SALLBA = 0
    // this.CCO_SALRES = 0

    this.DPC_FECREC = new Date()
    // this.DPC_FECUPD = new Date();
    this.DPC_IMPDET = 0
    this.DPC_IGVISC = 0
    this.DPC_IMPDIF = 0
    this.DPC_IMPIES = 0
    this.DPC_IMPISC = 0
    this.DPC_IMPORE = 0
    this.DPC_IMPPER = 0
    this.DPC_INDDET = 0
    this.DPC_INDDIF = "0"
    this.DPC_INDNTC = 0
    // this.DPC_INDSTA = "1"
    this.CCO_INDSTA = "1"
    this.DPC_MESTRI = 0
    this.DPC_NUMDUA = 0
    this.DPC_NUMPOL = 0
    this.DPC_OTRTRI = 0
    this.DPC_PARARA = 0
    this.DPC_PERINI = 0
    this.DPC_REIPAP = 0
    this.DPC_TASIES = 0
    this.DPC_TASISC = 0
    this.DPC_TASORE = 0
    this.DPC_VALARE = 0
    this.ipi_codipi = 0
    // this.ldc_corasi = null
    // this.ldc_corldc = "00001"
    this.MMO_CODMMO = null
    this.OIM_NUMOIM = null
    this.SCO_CODSCO = null
    this.SUC_CODSUC = "01"
    this.DPC_TASREN = 0;
    this.DPC_IMPREN = 0;
    this.TDO_DOCREF = "";
    this.CCO_NUMREF = "";

    // this.DPC_FECDET = new Date();

    this.CLI_CODDOC = null
    this.DPC_AFEPOL = 0
    this.DPC_ANOTRI = null


    this.TMO_CODTMO = "SO";
    this.CCO_INDSTA = "1";
    this.IRI_CODIRI = "00";

    this.proveedor = {
      cli_nomcli: ''
    }
  }

  static fromRegistroCompraToRecepcion(registroCompra: RegistroCompra): RecepcionCompra {
    const recepcionCompra = new RecepcionCompra();
    recepcionCompra.CCO_NUMDOC = registroCompra.cco_numdoc;
    recepcionCompra.CLI_CODCLI = registroCompra.cli_codcli;
    recepcionCompra.CLI_CODDOC = registroCompra.cli_coddoc;
    recepcionCompra.sad_codsad = registroCompra.sad_codsad;
    recepcionCompra.CCO_FECREG = registroCompra.cco_fecreg;
    recepcionCompra.CCO_FECEMI = registroCompra.cco_fecemi;
    recepcionCompra.CCO_FECUPD = new Date();
    recepcionCompra.CCO_TIPCAM = registroCompra.cco_tipcam;
    recepcionCompra.detalle = registroCompra.detalle;
    // recepcionCompra.ordenes = [];
    recepcionCompra.CPA_CODCPA = registroCompra.cpa_codcpa;

    recepcionCompra.CIA_CODCIA = registroCompra.cia_codcia;
    recepcionCompra.CCO_CODCCO = registroCompra.cco_codcco;
    recepcionCompra.TDO_CODTDO = registroCompra.tdo_codtdo;
    recepcionCompra.CCO_GLOCCO = registroCompra.cco_glocco;
    recepcionCompra.CCO_FECVEN = registroCompra.cco_fecven;
    recepcionCompra.TMO_CODTMO = registroCompra.tmo_codtmo;
    recepcionCompra.CCN_CODCCN = registroCompra.ccn_codccn;
    recepcionCompra.CCO_IMPDOC = registroCompra.cco_impdoc;
    // recepcionCompra.cco_impcco = 0;
    // recepcionCompra.cco_salcco = 0;
    // recepcionCompra.cco_sallba = 0;
    // recepcionCompra.cco_salres = 0;
    // recepcionCompra.cco_impabo = 0;
    recepcionCompra.CCO_IMPIGV = registroCompra.cco_impigv;
    recepcionCompra.CCO_TASIGV = registroCompra.cco_tasigv;
    recepcionCompra.CCO_IMPCOR = registroCompra.cco_impcor;
    recepcionCompra.CCO_IMPINA = registroCompra.cco_impina;
    recepcionCompra.CCO_IMPAFE = registroCompra.cco_impafe;
    recepcionCompra.CCO_INDTCA = registroCompra.cco_indtca;
    recepcionCompra.CCO_CODPAG = registroCompra.cco_codpag;
    recepcionCompra.MMO_CODMMO = registroCompra.mmo_codmmo;
    recepcionCompra.TDO_DOCREF = registroCompra.tdo_docref;
    recepcionCompra.CCO_NUMREF = registroCompra.cco_numref;
    recepcionCompra.ANO_CODANO = registroCompra.ano_codano;
    recepcionCompra.MES_CODMES = registroCompra.mes_codmes;
    recepcionCompra.SCO_CODSCO = registroCompra.sco_codsco;
    // recepcionCompra.ldc_corldc = "0001";
    // recepcionCompra.sco_codasi = '';
    // recepcionCompra.ldc_corasi = '';
    recepcionCompra.AGE_CODAGE = registroCompra.age_codage;
    recepcionCompra.CCO_ABOTRA = registroCompra.cco_abotra;
    recepcionCompra.CCO_INDSTA = registroCompra.cco_indsta;
    recepcionCompra.CCO_CODUSE = registroCompra.cco_coduse;
    recepcionCompra.CCO_CODANT = registroCompra.cco_codant;
    recepcionCompra.CCO_IMPRET = registroCompra.cco_impret;
    recepcionCompra.CCO_ABORET = registroCompra.cco_aboret;
    recepcionCompra.CCO_RETGAS = registroCompra.cco_retgas;
    recepcionCompra.IRI_CODIRI = registroCompra.iri_codiri;
    recepcionCompra.SUC_CODSUC = registroCompra.suc_codsuc;
    recepcionCompra.TGA_CODTGA = registroCompra.tga_codtga;
    recepcionCompra.CCO_FECREF = registroCompra.cco_fecref;
    recepcionCompra.OCM_NUMOCM = registroCompra.ocm_numocm;
    recepcionCompra.cco_numcuo = registroCompra.cco_numcuo;
    recepcionCompra.ipi_codipi = registroCompra.ipi_codipi;
    recepcionCompra.CCO_IMPPER = registroCompra.cco_impper;
    recepcionCompra.CCO_PERMNA = registroCompra.cco_permna;
    recepcionCompra.cco_movil = registroCompra.cco_movil;
    recepcionCompra.cco_cbmoti = registroCompra.cco_cbmoti;
    recepcionCompra.cco_condic = registroCompra.cco_condic;
    recepcionCompra.cco_code = registroCompra.cco_code;
    recepcionCompra.DPC_INDDER = registroCompra.dpc_indder;
    recepcionCompra.DPC_FECREC = registroCompra.dpc_fecrec;
    recepcionCompra.CCO_INDSTA = registroCompra.cco_indsta;
    recepcionCompra.CCO_CODUSE = registroCompra.cco_coduse;
    recepcionCompra.DPC_TASREN = registroCompra.dpc_tasren;
    recepcionCompra.DPC_TASIES = registroCompra.dpc_tasies;
    recepcionCompra.DPC_TASORE = registroCompra.dpc_tasore;
    recepcionCompra.DPC_IMPREN = registroCompra.dpc_impren;
    recepcionCompra.DPC_IMPIES = registroCompra.dpc_impies;
    recepcionCompra.DPC_IMPORE = registroCompra.dpc_impore;
    recepcionCompra.DPC_DIFIGV = registroCompra.dpc_difigv;
    recepcionCompra.DPC_AFCDER = registroCompra.dpc_afcder;
    recepcionCompra.DPC_AFSDER = registroCompra.dpc_afsder;
    recepcionCompra.DPC_IMPISC = registroCompra.dpc_impisc;
    recepcionCompra.DPC_IGVISC = registroCompra.dpc_igvisc;
    recepcionCompra.DPC_TASISC = registroCompra.dpc_tasisc;
    recepcionCompra.DPC_OTRTRI = registroCompra.dpc_otrtri;
    recepcionCompra.DPC_VALARE = registroCompra.dpc_valare;
    recepcionCompra.DPC_INDDIF = registroCompra.dpc_inddif;
    recepcionCompra.DPC_PERINI = registroCompra.dpc_perini;
    recepcionCompra.DPC_IMPDIF = registroCompra.dpc_impdif;
    recepcionCompra.DPC_NUMPOL = registroCompra.dpc_numpol;
    recepcionCompra.DPC_PARARA = registroCompra.dpc_parara;
    recepcionCompra.DPC_IMPFOB = registroCompra.dpc_impfob;
    recepcionCompra.DPC_IMPFLE = registroCompra.dpc_impfle;
    recepcionCompra.DPC_IMPSEG = registroCompra.dpc_impseg;
    recepcionCompra.DPC_IMPCIF = registroCompra.dpc_impcif;
    recepcionCompra.DPC_IMPADV = registroCompra.dpc_impadv;
    recepcionCompra.DPC_REIPAP = registroCompra.dpc_reipap;
    recepcionCompra.DPC_AFEPOL = registroCompra.dpc_afepol;
    recepcionCompra.DPC_INDDET = registroCompra.dpc_inddet;
    recepcionCompra.DPC_INDDET = registroCompra.dpc_inddet;
    recepcionCompra.TIM_CODTIM = registroCompra.tim_codtim;
    recepcionCompra.DPC_TASDET = registroCompra.dpc_tasdet;
    recepcionCompra.DPC_DEPDET = registroCompra.dpc_depdet;
    recepcionCompra.DPC_IMPDET = registroCompra.dpc_impdet;
    recepcionCompra.DPC_FECDET = registroCompra.dpc_fecdet;
    recepcionCompra.DPC_INDNTC = registroCompra.dpc_indntc;
    recepcionCompra.DPC_ANOTRI = registroCompra.dpc_anotri;
    recepcionCompra.DPC_MESTRI = registroCompra.dpc_mestri;
    recepcionCompra.DPC_IMPPER = registroCompra.dpc_impper;
    recepcionCompra.DPC_NUMDUA = registroCompra.dpc_numdua;
    recepcionCompra.DPC_ANODUA = registroCompra.dpc_anodua;
    recepcionCompra.OIM_NUMOIM = registroCompra.oim_numoim;
    recepcionCompra.dpc_impsis = registroCompra.dpc_impsis;
    recepcionCompra.dpc_impfis = registroCompra.dpc_impfis;
    recepcionCompra.t30_codt30 = registroCompra.t30_codt30;
    recepcionCompra.t31_codt31 = registroCompra.t31_codt31;
    recepcionCompra.valvta = registroCompra.valvta;

    return recepcionCompra;

  }

  static map(documentoRecepcion): RecepcionCompra {
    const recepcion = new RecepcionCompra();

    return recepcion;
  }

  static mapList(documentosRecepcion: Array<any>): Array<RecepcionCompra> {

    const recepciones = new Array<RecepcionCompra>();

    documentosRecepcion.forEach(documento => {
      let tipoDocumento = "FAC";
      if (documento.tdo_codtdo == 'Factura') tipoDocumento = "FAC";

      const recepcion = new RecepcionCompra();
      recepcion.CCO_NUMDOC = documento.cco_numdoc;
      recepcion.CLI_CODCLI = documento.cli_codcli;
      recepcion.CLI_CODDOC = documento.cli_codcli;
      recepcion.sad_codsad = documento.sad_codsad;
      recepcion.CCO_FECEMI = new Date(documento.cco_fecemi);
      recepcion.CCO_FECVEN = new Date(documento.cco_fecemi);
      recepcion.CCO_FECREG = new Date();
      recepcion.TDO_CODTDO = tipoDocumento;
      recepcion.CCO_GLOCCO = documento.cco_glocco;
      recepcion.CCO_IMPDOC = parseFloat(documento.cco_impdoc);
      recepcion.CCO_IMPAFE = parseFloat(documento.cco_impafe);
      recepcion.CCO_TASIGV = parseFloat(documento.cco_tasigv);
      recepcion.CCO_IMPIGV = parseFloat(documento.cco_impigv);

      recepciones.push(recepcion);
    });

    return recepciones;

    //   {
    //     "estado": "ACTIVO",
    //     "correlativo": "01",
    //     "cco_coduse": "pavso",
    //     "cco_fecupd": "2023-11-16T20:56:22.560Z",
    //     "existeProveedor": "NO",
    //     "razonSocial": "ENVASADORA SAN GABRIEL S.A.C. - ESG S.A.C.",
    //     "condicion": "HABIDO",
    //     "reencion": "SI"
    // }
  }

}

export class DetalleRecepcion {
  cia_codcia: any;
  ano_codano: any;
  mes_codmes: any;
  sco_codsco: any;
  ccn_descri: any;
  ldc_corldc: any;
  ldi_corldi: any;
  ccn_codccn: any;
  cuenta: {ccn_descri: string};
  cli_codcli: string;
  cco_codcco: any;
  tmo_codtmo: any;
  ldi_inddha: any;
  ldi_tipcam: any;
  ldi_tcameu: any;
  ldi_impnac: any;
  ldi_impmex: any;
  ldi_indcco: any;
  tdo_codtdo: any;
  ldi_docref: any;
  tdo_docref: any;
  ldi_numref: any;
  ldi_gloldi: any;
  fpa_codfpa: any;
  ref_codref: any;
  ldi_inddes: any;
  ldi_fecemi: any;
  ldi_fecven: any;
  ccs_codccs: any;
  suc_codsuc: any;
  rec_numrec: any;
  vde_codvde: any;
  ldi_impsal: any;
  fca_codfca: any;
  ocm_numocm: any;
  ldi_indccl: any;
  ldi_anoccl: any;
  ldi_mesccl: any;
  isEditing: boolean;
  isSelected: boolean;
  focused: boolean;

  constructor(
  ) {
    this.isEditing = true;
    this.isSelected = false;
    this.focused = false;

    this.tmo_codtmo = "SO";
    this.ldi_inddha = "D";
    this.tdo_codtdo = "FAC";
    this.ldi_impnac = 0;
    this.ldi_impmex = 0;
    this.ldi_tcameu = 0;
    this.ldi_inddes = 0;
    this.fca_codfca = "";

    this.ldi_impsal = 0;
    this.ldi_indccl = 0;
    this.ldi_indcco = "N";
    this.ldi_inddes = 0;
    this.ldi_mesccl = "";

    this.suc_codsuc = "01";
    this.tdo_docref = "";
    this.vde_codvde = "";

    this.cuenta = {ccn_descri: ''};
  }
}


export class DocumentoRecepcion {
  ccd_idccd: number
  cia_codcia: string;
  ccr_codccr: string;
  ccd_secccd: number;
  ccd_fotccd: string;
  ccd_tiparc: string;
  ccd_codtdo: string;
  ccd_glosa: string;
  item: number;
  // tipo_documento: any;
  // comentario: any;
  adjuntar: any;
  // typeFile: string;
  visualizar: any;
  ccd_uploaded: boolean;

  constructor() {
    this.ccd_secccd = 1;
    this.ccd_codtdo = "";
    this.ccd_uploaded = false;
  }
}
