import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { fillTable } from '@utils/tables/table';
import { DetalleTipoOperacion, TipoOperacion } from 'src/app/models/contabilidad/tipo_operacion';
import { AuthenticationService, ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { PlanCuentaService } from 'src/app/services/api/contabilidad/maestros/plan-cuenta.service';
import { CentroCostoService } from 'src/app/services/api/contabilidad/maestros/centro-costo.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TipoGastoService } from 'src/app/services/api/contabilidad/maestros/tipo-operacion.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';

@Component({
  selector: 'app-tipo-operacion-form',
  templateUrl: './tipo-operacion-form.component.html',
  styleUrls: ['./tipo-operacion-form.component.scss']
})
export class TipoOperacionFormComponent implements OnInit, OnDestroy {

  selected: any;

  selectRow(row: any) {
    this.selected = row;
  }

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['aau_iteaau', 'ccn_codccn', 'ccn_descri', 'ccs_descri', 'aau_inddha', 'aau_poraau'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  tipoOperacion: TipoOperacion;

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;

  loading$: Subscription;
  cuentas$: Subscription;
  centrosCosto$: Subscription;
  tiposBienServicio$: Subscription;
  tiposDocumento$: Subscription;
  sendForm$: Subscription;

  cuentas: any[] = [];
  centrosCosto: any[] = [];
  tiposBienServicio: any[] = [];
  tiposDocumento: any[] = [];

  cuenta: any;

  isTablet: boolean;
  isMobile: boolean;

  totalPorcentaje: number = 0;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _tipoGastoService: TipoGastoService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _planCuentaService: PlanCuentaService,
    private _centroCostoService: CentroCostoService,
    private breakpointObserver: BreakpointObserver,
    private _dialogService: DialogService,
  ) {
    this.tipoOperacion = new TipoOperacion();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.usuario = this._authService.getUsuarioSistema();
  }


  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });
  }

  loadData(): void {

    this.loaderData = true;

    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;

      this.tiposBienServicio$ = this._contabilidadService.obtenerTiposBienServicio().subscribe(
        tiposBienServicio => {

          this.tiposBienServicio = tiposBienServicio;

          this.cuentas$ = this._planCuentaService.obtenerPlanCuentas().subscribe(
            cuentas => {
              this.cuentas = cuentas;

              this.centrosCosto$ = this._centroCostoService.obtenerCentrosCosto().subscribe(
                centrosCosto => {
                  this.centrosCosto = centrosCosto;

                  this.tiposDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
                    tiposDocumento => {
                      this.tiposDocumento = tiposDocumento;

                      if (id == '0') {
                        this.loaderData = false;
                        return;
                      }

                      this.obtenerTipoDeOperacion();
                    },
                    error => {
                      this._snackBarService.showError(error.error.msg, "OK")
                      this.loaderData = false;
                    }
                  )
                },
                error => {
                  this._snackBarService.showError(error.error.msg, "OK")
                  this.loaderData = false;
                }
              )
            },
            error => {
              this._snackBarService.showError(error.error.msg, "OK");
              this.loaderData = false;
            }
          )
        },
        error => {
          this._snackBarService.showError(error.error.msg, "OK")
          this.loaderData = false;
        }
      )

    })
  }

  tipoGasto$: Subscription;

  obtenerTipoDeOperacion(): void {
    this.tipoGasto$ = this._tipoGastoService.obtenerTipoGasto(this.uid).subscribe(
      (tipoOperacion: TipoOperacion) => {
        this.tipoOperacion = tipoOperacion
        this.usuario = tipoOperacion.tga_coduse;
        this.fecha = tipoOperacion.tga_fecupd;

        this.tipoOperacion.detalle.forEach(item => {
          this.totalPorcentaje = 0;
          this.totalPorcentaje += item.aau_poraau;
          if(!item.cuenta)  {
            item.cuenta = {ccn_descri: ''}
          }
        });

        console.log('tipoOperacion.detalle', tipoOperacion.detalle)

        this.dataSource = fillTable(tipoOperacion.detalle, this.paginator, this.sort);
        this.loaderData = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK")
        this.loaderData = false;
      }
    )

  }

  calcularPorcentajeTotal(): void {
    let totalPorcentaje = 0;
    console.log('detalle', this.tipoOperacion.detalle)
    this.tipoOperacion.detalle.forEach(item => {
      totalPorcentaje += item.aau_poraau;
    })
    console.log('totalPorcentaje', this.totalPorcentaje)
    this.totalPorcentaje = totalPorcentaje;
  }

  keyupPorcentaje(): void {
    console.log('calcular')
    this.calcularPorcentajeTotal();
  }

  agregarItem(): void {

    const item = new DetalleTipoOperacion();

    item.aau_iteaau = '001';
    item.centrocosto.ccs_descri = '';
    this.tipoOperacion.detalle.push(item);
    this.tipoOperacion.detalle.forEach((element, key) => {
      element.aau_iteaau = `${( key + 1 )}`;
    });
    this.calcularPorcentajeTotal();
    this.dataSource = fillTable(this.tipoOperacion.detalle, this.paginator, this.sort)
  }

  quitarItem(): void {

    let row = this.tipoOperacion.detalle.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.tipoOperacion.detalle.length > 0) row = this.tipoOperacion.detalle[0];

    let details = [];
    this.tipoOperacion.detalle.forEach(element => {
      if (element.aau_iteaau != row.aau_iteaau) {
        details.push(element)
      }
    });

    this.tipoOperacion.detalle = details;

    this.tipoOperacion.detalle.forEach((element, key) => {
      element.aau_iteaau = `${( key + 1 )}`;
    });

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;

    this.calcularPorcentajeTotal();
  }

  insertarItem(): void {
    const item = new DetalleTipoOperacion();

    item.aau_iteaau = '001';
    item.centrocosto.ccs_descri = '';

    let i = 0;

    if (this.selected) {
      this.tipoOperacion.detalle.forEach((item, index) => {
        if (item.aau_iteaau == this.selected.ldi_corldi) i = index;
      })

      this.tipoOperacion.detalle.splice(i, 0, item);

    } else {
      this.tipoOperacion.detalle.push(item);
      this.tipoOperacion.detalle = this.tipoOperacion.detalle.reverse();
    }

    this.tipoOperacion.detalle.forEach((element, key) => {
      element.aau_iteaau = `${( key + 1 )}`;
    });

    this.dataSource = fillTable(this.tipoOperacion.detalle, this.paginator, this.sort);

    this.calcularPorcentajeTotal();
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarTipoDeOperacion(f) : this.actualizarTipoDeOperacion(f);
  }

  registrarTipoDeOperacion(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._tipoGastoService.registrarTipoGasto(this.tipoOperacion).subscribe(
      () => {
        this._dialogService.openDialog(SuccessComponent, `Tipo de operación registrado correctamente`, '400px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
      }
    )
  }

  actualizarTipoDeOperacion(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._tipoGastoService.actualizarTipoGasto(this.tipoOperacion).subscribe(
      () => {
        this._dialogService.openDialog(SuccessComponent, `Tipo de operación actualizada correctamente`, '400px', '', '');

        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/tipos-de-operacion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.cuentas$,
      this.centrosCosto$,
      this.tiposBienServicio$
    ])
  }
}
