export class codificacionProductoClienteDetalle{
    cia_codcia: string; // Código de compañía - char(2) - NO
    cli_codcli: string; // Código de cliente - char(12) - NO
    prd_codprd: string; // Código de producto - char(14) - NO
    pic_codprd: string; // Código de producto por cliente - char(20) - NO
    pic_desprd: string; // Descripción del producto por cliente - varchar(150) - NO
    pic_indsta: number; // Indicador de estado - smallint - YES
    pic_coduse: string; // Código de usuario - varchar(30) - YES
    pic_fecupd: Date; // Fecha de actualización - datetime - YES
    isEditing: boolean;

    constructor(){
        this.pic_indsta = 1;
        this.pic_fecupd = new Date();
        this.isEditing = true;
    }

}