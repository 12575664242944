<div class="pav-container-side-control">
  <div class="pav-container-flex">
    <div class="pav-icon" (click)="consultarPeriodo()">
      <div [ngStyle]="{
        background: '#6cd6df',
        borderRadius: '5px',
        width: '28px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'}">
        <mat-icon svgIcon="period"></mat-icon>
      </div>
    </div>
    <div class="pav-title">
      Período actividad:
    </div>
    <div class="pav-content">
      {{yearPeriodActividad}}-{{ monthPeriodActividad }}
      <span *ngIf="isNomina">
        - {{
        typeNomina
        }} - {{ secNomina }} - {{ nameTipoNomina | titlecase}}
      </span>
    </div>
  </div>
  <div class="pav-container-flex">
    <div class="pav-icon" (click)="cerrarPeriodo()">
      <div [ngStyle]="{
        background: '#6cd6df',
        borderRadius: '5px',
        width: '28px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'}">
        <mat-icon svgIcon="period"></mat-icon>
      </div>
    </div>
    <div class="pav-title">
      Período apertura:
    </div>
    <div class="pav-content">{{ yearPeriodApertura }}-{{ monthPeriodApertura }}</div>
  </div>
</div>
