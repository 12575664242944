import { Component, OnInit } from '@angular/core';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { reporteDaotCostos, reporteDaotIngresos } from '@utils/pdfmaker/builder/contabilidad.pdfmaker';

@Component({
  selector: 'app-interface-pdt',
  templateUrl: './interface-pdt.component.html',
  styleUrls: ['./interface-pdt.component.css']
})
export class InterfacePdtComponent implements OnInit {

  tipo: any;

  loading$: Subscription;

  constructor(
    private store: Store<PavsoState>
  ) {
    this.tipo = "1";
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  generaReporte(): void {
    let response;
    let data;
    (this.tipo == "1")? reporteDaotIngresos(response, data): reporteDaotCostos(response, data);

  }
}
