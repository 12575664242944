<app-pavso-title-section [title]="'Ubigeo'" [module]="'Contable'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

        <div class="row">
            <div class="col s12">
                <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
                <mat-card class="mat-elevation-z0">
                    <div class="row">
                        <div class="col s12 m12 l12 xl6">
                            <mat-form-field>
                                <mat-label>Código</mat-label>
                                <input type="text" matInput placeholder="Código" name="codigo" required [disabled]="loaderData">
                            </mat-form-field>
                        </div>
                        <div class="col s12 m12 l12 xl6">
                            <mat-form-field>
                                <mat-label>Nombre</mat-label>
                                <textarea matInput placeholder="Nombre"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card>
                <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
                  <div id="botonesAdicionales">
                  </div>

                </pav-form-actions>
            </div>

        </div>

    </form>
</div>
