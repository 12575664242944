<app-pavso-title-section [title]="'Tipo de activo'" [module]="'Activo fijo'" ngClass="pav-form" [estado]="tipoActivo.taf_indsta"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="tipoActivo.taf_codtaf" matInput placeholder="Código" name="taf_codtaf"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="text" [(ngModel)]="tipoActivo.taf_descri" matInput placeholder="Nombre" name="taf_descri"
              required>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Nombre Abrev.</mat-label>
            <input type="text" [(ngModel)]="tipoActivo.taf_desabr" matInput placeholder="Nombre Abrev."
              name="taf_desabr" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Clase</mat-label>
            <mat-select [(ngModel)]="tipoActivo.cta_codcta" required name="cta_codcta">
              <mat-option value="1">
                clase 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tasa de depreciación (Por Defecto)</mat-label>
            <input type="text" [(ngModel)]="tipoActivo.taf_tasdpc" matInput
              placeholder="Tasa de depreciación (Por Defecto)" name="taf_tasdpc" required>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Cuenta de Activo</mat-label>
            <mat-select required [(ngModel)]="tipoActivo.ccn_codccn" name="cuenta_activo">
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.descri_cuenta}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Cuenta de Depreciación</mat-label>
            <mat-select [(ngModel)]="tipoActivo.ccn_codccn" required name="ccn_codccn">
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.descri_cuenta}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Cuenta de Gastos</mat-label>
            <mat-select required [(ngModel)]="tipoActivo.ccn_codccn" name="cuenta_gastos">
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.descri_cuenta}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m12 l12">
          <mat-checkbox class="example-margin" [(ngModel)]="tipoActivo.taf_indcom" name="taf_indcom">Datos
            Complementarios</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="tipoActivo.taf_indutr" name="taf_indutr">Datos Unidad de
            Transporte</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="tipoActivo.taf_indinm" name="taf_indinm">Datos
            Inmueble</mat-checkbox>
        </div>
      </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-activo-fijo/tipos-de-activo">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
</form>
