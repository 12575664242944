import { Component, OnDestroy, OnInit } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";

@Component({
  selector: 'app-panel-ventas',
  templateUrl: './panel-ventas.component.html',
  styleUrls: ['./panel-ventas.component.scss']
})
export class PanelVentasComponent implements OnDestroy, OnInit {

  loading$: Subscription;
  resumenAnual$: Subscription;
  informe$: Subscription;
  periodo$: Subscription;

  dataResumen: any;
  dataParetoSerie: any;
  dataParetoProducts: any;

  anioPeriodo: string;
  mesPeriodo: string;

  constructor(
    private store: Store<PavsoState>,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.periodo$ = this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        console.log('grafico ventas')
        this.obtenerData()
      };
    })
  }

  obtenerData() {
    const payload = { moneda: 'SO' };

    this.resumenAnual$ = this._ventasService.obtenerResumenAnual(payload).subscribe(
      response => {
        console.log('response resumen anual', response)
        this.dataResumen = response;

        this.informe$ = this._ventasService.obtenerInformeDiagramaPareto(this.anioPeriodo, this.mesPeriodo).subscribe(
          data => {
            console.log('diagrama pareto', {series: data.series, productos: data.productos})
            this.dataParetoSerie = data.series;
            this.dataParetoProducts = data.productos;
          },
          error =>  {
            this._snackBarService.showError('Error al obtener informe de pareto', 'Ok');
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener resumen anual', 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.resumenAnual$,
      this.loading$
    ])
  }

}
