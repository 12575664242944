export class ConfigureMotive {
  codigo: any;
  cia_codcia: any;
  alm_codalm: any;
  tdo_codtdo: any;
  mmo_codmmo: any;
  alm_codtra: any;
  mic_glomic: any;
  mic_ordpro: any;
  mic_indsta: any;
  mic_coduse: any;
  mic_fecupd: any;
  detalle: any;

  constructor(
  ){
    this.mic_indsta = "1";
    this.mic_fecupd = new Date();
  }
}
