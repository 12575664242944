import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GrupoFlujoCaja } from 'src/app/models/caja-banco';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({providedIn: 'root'})
export class GrupoFlujoCajaService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

   /**
   *
   * @returns
   */
   obtenerGrupoFlujoCajas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/grupo-flujo-caja/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param codigo
   * @returns
   */
  mostrarGrupoFlujoCaja(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/grupo-flujo-caja/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`
    );
  }

  /**
   *
   * @param grupoFlujo
   * @returns
   */
  registrarGrupoFlujoCaja(grupoFlujo: GrupoFlujoCaja): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/grupo-flujo-caja`,
      grupoFlujo
    );
  }

  /**
   *
   * @param grupoFlujo
   * @returns
   */
  actualizarGrupoFlujoCaja(grupoFlujo: GrupoFlujoCaja): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/cajabanco/grupo-flujo-caja`,
      grupoFlujo
    );
  }

}
