export const obtenerNulls = (counter):Array<null> => {

  let nulls = [];

  for (let index = 0; index < counter; index++) {

    nulls.push(null)

  }

  return nulls;
}
