<div class="pavso_card_more_options">
  <div class="pav-table-header-icon"></div>
  <p class="pav-table-header-title">
    {{title}}
  </p>
  <div class="isMobile">
    <div (click)="descargar()" class="pav-button-icon-download-excel">
      <mat-icon svgIcon="download"></mat-icon>
    </div>
  </div>
  <div class="isWeb">
    <button (click)="descargar()" [disabled]="!permisos.canDownloadToExcel" class="pav-button-download-excel" mat-flat-button color="primary">
      <mat-icon>file_download</mat-icon>
      Descargar
    </button>
  </div>
  <div class="isMobile">
    <div class="pav-button-icon-add-form" [routerLink]="obtenerParametros()">
      <mat-icon svgIcon="add"></mat-icon>
    </div>
  </div>
  <div class="isWeb">
    <a (click)="nuevoRegistro()">
      <button [disabled]="!permisos.canCreate" class="pav-button-add" mat-flat-button color="primary">
        <mat-icon>add</mat-icon>
        Agregar {{entity}}
      </button>
    </a>
  </div>
</div>
