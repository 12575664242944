import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { PedidoService } from 'src/app/services/api/ventas/operaciones/pedido.service';

@Component({
  selector: 'app-copy-from-order',
  templateUrl: './copy-from-order.component.html',
  styleUrls: ['./copy-from-order.component.css'],
})
export class CopyFromOrderComponent implements OnInit, OnDestroy {

  pedidos$: Subscription;
  pedidos: any[] = [];
  pedidoSeleccionado: string;

  loaderData: boolean = false;

  constructor(
    private readonly _pedidoService: PedidoService,
    private readonly _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<CopyFromOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.obtenerPedidos();
  }

  obtenerPedidos(): void {
    this.loaderData = true;
    this.pedidos$ = this._pedidoService.obtenerPedidos('01').subscribe(
      pedidos =>  {
        console.log('pedidos', pedidos)
        this.pedidos = pedidos;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    );
  }

  copiar(): void {
    this.dialogRef.close(this.pedidoSeleccionado);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.pedidos$])
  }

}
