<app-pavso-title-section [title]="'Familia'" [module]="'Almacén'"
  [estado]="familia.FPR_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="familia.FPR_CODFPR" matInput placeholder="Código" name="FPR_CODFPR"
              readonly>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l5">
          <mat-form-field>
            <mat-label>Desc. Larga</mat-label>
            <input type="text" matInput [(ngModel)]="familia.FPR_DESCRI" placeholder="Descripción" name="FPR_DESCRI"
              (keyup)="typeDescLarga()" required [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l5">
          <mat-form-field>
            <mat-label>Desc. Corta</mat-label>
            <input type="text" matInput [(ngModel)]="familia.FPR_DESABR" placeholder="Descri. Abrev." name="FPR_DESABR"
              required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Cuenta</mat-label>
            <mat-select [(ngModel)]="familia.ccn_codccn" name="ccn_codccn">
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </mat-card>

  </div>
  <br>
  <mat-tab-group>
    <mat-tab label="Tipo de inventario" labelClass="mat-tab-label-0-0">
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">
        </div>
        <div class="pav-table-header-title">
          <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
            <div>
              Tipos de inventario
            </div>

          </div>

        </div>
        &nbsp;
        &nbsp;
        &nbsp;

        &nbsp;
        <div>
          <button-add-item-table (addItem)="agregarTipoInventario()"></button-add-item-table>

          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
          </p>
        </div>
        &nbsp;
        <div>
          <button-remove-item-table (removeItem)="eliminarTipoInventario()"></button-remove-item-table>

          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
          </p>
        </div>

      </div>

      <div class="mat-elevation-z0 overflow-x">
        <table mat-table [dataSource]="dataSourceTiposInventario" matSort class="table-cell">

          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="item">
              <mat-form-field [class.mat-form-field-row]="row === selectedTipoInventario"
                class="form-field-small form-field-cell" [ngStyle]="{width: '60px'}">
                <mat-label></mat-label>
                <input readonly [value]="row.PCD_CORPCD" [name]="'PCD_CORPCD' + i" matInput placeholder="Ej. 001">
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
            <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
              <div [ngStyle]="{display: 'flex'}">

                <mat-form-field [class.mat-form-field-row]="row === selectedTipoInventario"
                  class="form-field-small form-field-cell">
                  <mat-label>Descripción</mat-label>
                  <input matInput placeholder="Ej. Cajas">
                </mat-form-field>

              </div>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTiposInventario"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTiposInventario;"
            [class.selected-row]="row === selectedTipoInventario" (click)="selectRowTipoInventario(row)"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="20">No se encontraron registros</td>
          </tr>

        </table>
      </div>
    </mat-tab>
    <mat-tab label="Sub-Familias" labelClass="mat-tab-label-0-1">
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">
        </div>
        <div class="pav-table-header-title">
          <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
            <div>
              Sub Familias
            </div>

          </div>

        </div>
        &nbsp;
        &nbsp;
        &nbsp;

        &nbsp;
        <div>

          <button-add-item-table (addItem)="agregarSubfamilia()"></button-add-item-table>

          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
          </p>
        </div>
        &nbsp;
        <div>
          <button-remove-item-table (removeItem)="eliminarSubfamilia()"></button-remove-item-table>

          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
          </p>
        </div>

      </div>

      <div class="mat-elevation-z0 overflow-x">
        <table mat-table [dataSource]="dataSourceSubfamilia" matSort class="table-cell">

          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="item">
              <mat-form-field [class.mat-form-field-row]="row === selectedSubfamilia"
                class="form-field-small form-field-cell" [ngStyle]="{width: '60px'}">
                <mat-label></mat-label>
                <input maxlength="4" [(ngModel)]="row.sfp_codsfp" [name]="'sfp_codsfp' + i" matInput
                  placeholder="Ej. 001">
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
            <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
              <div [ngStyle]="{display: 'flex'}">

                <mat-form-field [class.mat-form-field-row]="row === selectedSubfamilia"
                  class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. Cajas" [(ngModel)]="row.sfp_descri" [name]="'sfp_descri' + i">
                </mat-form-field>

              </div>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsSubfamilia"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsSubfamilia;"
            [class.selected-row]="row === selectedSubfamilia" (click)="selectRowSubfamilia(row)"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="20">No se encontraron registros</td>
          </tr>

        </table>
      </div>
    </mat-tab>

  </mat-tab-group>

  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-almacen/familia-producto">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
