<br>
<div
  [ngStyle]="{display: 'flex', flexDirection: isMobile? 'column': 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', width: '100%'}">
  <div [ngStyle]="{display: isMobile || isTablet ? '': 'flex', alignItems: 'center', width: '100%'}">

    <div [ngStyle]="{marginRight: '5px'}">
      <button
        [ngStyle]="{height: '45px', width: isMobile || isTablet ? '100%': '180px', background: '#F7C91B', color: '#222'}"
        mat-stroked-button (click)="cargarSIRE()" color="primary" [class.spinner]="loaderSIRE"
        [disabled]="loaderSIRE || loaderData" matTooltip="Cargar registros desde SIRE">
        <mat-icon>cloud_download</mat-icon>
        Extraer desde SIRE
      </button>
    </div>
    <div [ngStyle]="{marginRight: '5px', marginTop: isMobile || isTablet ? '5px': '0px'}">
      <button mat-stroked-button (click)="agregarDocumento()" color="primary" [disabled]="loaderSIRE || loaderData"
        [ngStyle]="{background: '#6cd6df', width: isMobile || isTablet ? '100%': '180px', height: '45px', color: '#222'}"
        matTooltip="Agregar registro manualmente">
        <mat-icon>add</mat-icon>
        Agregar
      </button>
    </div>
    <div [ngStyle]="{marginTop: isMobile || isTablet ? '5px': '0px'}">
      <button mat-stroked-button [class.spinner]="loaderEnviarCargaMasiva" [disabled]="selection.selected.length == 0"
        color="primary" (click)="enviarDocumentosACargaMasiva()"
        [ngStyle]="{background: '#EB6945', border: '1px solid rgba(0, 0, 0, 0.12)', width: isMobile || isTablet ? '100%': '180px', height: '45px', color: 'white'}"
        matTooltip="Enviar registros a carga masiva los registros seleccionados en la tabla">
        <mat-icon [ngStyle]="{'transform': 'rotateY(180deg)'}">reply</mat-icon>
        Descargar XMLs
      </button>
    </div>
  </div>

  <!-- <button (click)="pruebas()">Pruebas</button> -->

  <div [ngStyle]="{marginTop: isMobile || isTablet ? '5px': ''}">
    <mat-form-field appearance="outline" class="pav-buscar">
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. 20837">
    </mat-form-field>
  </div>
</div>
<div class="overflow-x">

  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"
    [ngStyle]="{position: 'absolute', marginTop: '55px'}"></mat-progress-bar>
  <table mat-table [dataSource]="dataSourceDocumentos" matSort
    [ngStyle]="{border: '4px #ddd solid', borderRadius: '15px'}">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">

        <mat-checkbox *ngIf="row.estado != 'EN_REGISTRO_COMPRA'" (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td mat-cell *matCellDef="let row" data-label="Accion" class="td-responsive">
        <div class="pav-btns-container">
          <div *ngIf="!loaderEnvioRecepcion" matTooltip="Enviar a recepción" (click)="enviarARecepcion(row)"
            class="pav-btn-circle-edit" [ngStyle]="{cursor: 'pointer'}">
            <mat-icon svgIcon="send2"></mat-icon>
          </div>
          <mat-spinner diameter="20" *ngIf="loaderEnvioRecepcion && documentoAEnviar == row.ruc + row.serie + row.numeroDoc"></mat-spinner>

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="razonSocial">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
      <td mat-cell *matCellDef="let row" data-label="Razón Social" class="td-responsive"> {{row.razonSocial}}
      </td>
    </ng-container>

    <ng-container matColumnDef="ruc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> RUC </th>
      <td mat-cell *matCellDef="let row" data-label="RUC" class="td-responsive"> {{row.ruc}} </td>
    </ng-container>

    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
      <td mat-cell *matCellDef="let row" data-label="Tipo Doc." class="td-responsive"> {{row.codofi}}
      </td>
    </ng-container>

    <ng-container matColumnDef="serie">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
      <td mat-cell *matCellDef="let row" data-label="Serie" class="td-responsive"> {{row.serie}} </td>
    </ng-container>

    <ng-container matColumnDef="numeroDoc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento </th>
      <td mat-cell *matCellDef="let row" data-label="Nro. Documento" class="td-responsive"> {{row.numeroDoc}}
      </td>
    </ng-container>

    <ng-container matColumnDef="fechaEmision">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de emisión </th>
      <td mat-cell *matCellDef="let row" data-label="Fecha de emisión" class="td-responsive"> {{row.fechaEmision |
        date: 'dd/MM/yyyy': 'UTC'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="fechaVencimiento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de vencimiento </th>
      <td mat-cell *matCellDef="let row" data-label="Fecha de vencimiento" class="td-responsive"> {{row.fechaVencimiento
        | date: 'dd/MM/yyyy': 'UTC'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="moneda">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
      <td mat-cell *matCellDef="let row" data-label="Moneda" class="td-responsive"> {{row.moneda}}
      </td>
    </ng-container>

    <ng-container matColumnDef="importe">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
      <td mat-cell *matCellDef="let row" data-label="Importe" class="td-responsive">
        <div [ngStyle]="{width: '60px', textAlign: 'right'}">
          {{row.importe ? numeroConDosDecimales(row.importe): 0}}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsDocumentos"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsDocumentos;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="11">No se encontraron registros</td>
    </tr>

  </table>

</div>
&nbsp;
<div
  [ngStyle]="{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isMobile? 'column': 'row' }">
  <mat-card class="mat-elevation-z0" [ngStyle]="{background: 'antiquewhite'}">

    <p [ngStyle]="{fontSize: '14px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
      <mat-icon>info_outline</mat-icon> &nbsp; Se recomienda realizar la descarga de hasta 10 documentos
    </p>

  </mat-card>
  <mat-paginator #paginatorImagenes showFirstLastButtons [pageSizeOptions]="[10]"></mat-paginator>
</div>


<br>
<resumen-documentos-validos [documentosValidos]="documentosValidos"
  [documentosNoValidos]="documentosNoValidos"></resumen-documentos-validos>
