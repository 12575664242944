<div class="pav-header-profile-container">
  <div class="pav-container-img-profile" [matMenuTriggerFor]="menuProfile">
    <p>P</p>
  </div>
  <div [matMenuTriggerFor]="menuProfile" class="pav-header-icon">
    <p>
      <mat-icon style="width: 12px; height: 12px;" svgIcon="arrow-bottom"></mat-icon>
    </p>
  </div>
</div>
<mat-menu #menuProfile="matMenu">
  <div class="pav-profile-container">
    <div class="pav-profile-row" routerLink="/mi-perfil">
      <div class="pav-profile-row-in">
        <div class="pav-profile-icon">
          <mat-icon svgIcon="perfil"></mat-icon>
        </div>
        <div class="pav-profile-text">
          {{'general.menu.sesion.my-profile' | translate}}
        </div>
      </div>
    </div>
    <div class="pav-profile-row" (click)="cerrarSesion()">
      <div class="pav-profile-row-inside">
        <div class="pav-profile-icon">
          <mat-icon svgIcon="logout"></mat-icon>
        </div>
        <div class="pav-profile-text-alter">
          {{'general.menu.sesion.logout' | translate}}
        </div>
      </div>
    </div>
  </div>

</mat-menu>
