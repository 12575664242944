import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { AuthenticationService } from './authentication.service';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { cambiarPermiso } from '../shared/state/actions/permission.actions';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    constructor(
        private http: HttpClient,
        private _configurationService: ConfigurationService,
        private _authService: AuthenticationService,
        private store: Store<PavsoState>
    ) {}

    /**
     *
     * @param language
     * @returns
     */
    obtenerMenu(language): Observable<any> {

      return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/modulos/listamodulos/${this._configurationService.obtenerCompaniaCliente()}&${this._authService.getUsuarioSistema()}&${language}`);

    }

    /**
     *
     * @param codigo
     * @returns
     */
    obtenerPermisos(codigo): Object {

      const menu = this._configurationService.obtenerMenu();
      let item = {};
      menu.forEach(level1 => {
        level1.submenus?.forEach(level2 => {
          level2.submenus?.forEach(level3 => {
            if(level3.program == codigo) {
              item = level3
            }
          });
        });
      });

      if(item['access']) this.store.dispatch(cambiarPermiso(item['access'][0]))
      return item;

    }

}
