<app-pavso-title-section [title]="'Informes de composición'" [module]="'Planilla'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <mat-card class="mat-elevation-z0">
    <mat-list>
      <mat-list-item class="pav-mat-item-other">
        <div class="pav-name-report-composition">
          Composición por género de los colaboradores
        </div>
        <span class="spacer"></span>
        <button mat-button color="primary"
          class="pav-btn-show-report"
          [routerLink]="['/modulo-planilla/composicion-por-sexo-de-los-trabajadores']">
          Ver informe
        </button>
      </mat-list-item>
      <mat-list-item>
        <div class="pav-name-report-composition">
          Composición por edades de los colaboradores
        </div>
        <span class="spacer"></span>
        <button mat-button color="primary"
          class="pav-btn-show-report"
          [routerLink]="['/modulo-planilla/composicion-por-edad-de-los-trabajadores']">Ver informe</button>
      </mat-list-item>
      <mat-list-item class="pav-mat-item-other">
        <div class="pav-name-report-composition">
          Composición por cargo de colaboradores
        </div>
        <span class="spacer"></span>
        <button mat-button color="primary"
          class="pav-btn-show-report"
          [routerLink]="['/modulo-planilla/composicion-por-cargo-de-los-trabajadores']">Ver informe</button>
      </mat-list-item>
      <mat-list-item>
        <div class="pav-name-report-composition">
          Composición por grado de instrucción de colaboradores
        </div>
        <span class="spacer"></span>
        <button mat-button color="primary"
          class="pav-btn-show-report"
          [routerLink]="['/modulo-planilla/composicion-por-grado-de-instruccion-de-los-trabajadores']">Ver informe</button>
      </mat-list-item>
      <mat-list-item class="pav-mat-item-other">
        <div class="pav-name-report-composition">
          Composición por cantidad de colaboradores
        </div>
        <span class="spacer"></span>
        <button mat-button color="primary"
          class="pav-btn-show-report"
          [routerLink]="['/modulo-planilla/composicion-por-cantidad-de-personas']">Ver informe</button>
      </mat-list-item>
      <mat-list-item>
        <div class="pav-name-report-composition">
          Composición por edad y sexo de los colaboradores
        </div>
        <span class="spacer"></span>
        <button mat-button color="primary"
          class="pav-btn-show-report"
          [routerLink]="['/modulo-planilla/composicion-por-edad-y-sexo-de-los-trabajadores']">Ver informe</button>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>
