<app-pavso-title-section [title]="'Situaciones de documentos'" [module]="'Cuentas por cobrar'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos las situaciones" url="/modulo-cobranzas/situacion-de-documento" entity="" [params]="['0']"></table-title-action>

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-cobranzas/situacion-de-documento', row.codigo_situcobra]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="codigo_situcobra">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.codigo_situcobra}} </td>
            </ng-container>

            <ng-container matColumnDef="descri_situcobra">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Chofer </th>
                <td mat-cell *matCellDef="let row" data-label="Chofer"> {{row.descri_situcobra}} </td>
            </ng-container>

            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Licencia </th>
                <td mat-cell *matCellDef="let row" data-label="Nro. Licencia"> {{(row.estado == "1")? "VIGENTE": "ANULADO"}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
