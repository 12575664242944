import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SnackBarService } from "@shared/services/snackbar.service";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { SubfamiliaService } from "src/app/services/api/almacen/tabla-apoyo/subfamilia.service";

class ConsultarStockMinimoDialogData {
  tiposInventario: any[];
  lineas: any[];
  familias: any[];
  subfamilias: any[];
  marcas: any[];
  proveedores: any[];
}

class Filtro {
  tipoInventario: string;
  linea: string;
  familia: string;
  subfamilia: string;
  marca: string;
  proveedor: string;
  estado: string;

  constructor() {
    this.estado = '4';
  }
}

@Component({
  selector: 'consultar-stock-minimo',
  templateUrl: './consultar-stock-minimo.dialog.html',
  styleUrls: ['./consultar-stock-minimo.dialog.scss']
})
export class ConsultarStockMinimoDialog implements OnInit {

  filtro: Filtro;
  subfamilias$: Subscription;
  subfamilias: any[] = [];

  displayedColumns: string[] = ['select', 'producto', 'descripcion', 'unidad_medida', 'marca', 'stock_actual', 'stock_minimo', 'stock_maximo', 'orden_transito', 'lote_ideal', 'compra_sugerida', 'familia', 'subfamilia', 'color'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<any>(true, []);

  constructor(
    public dialogRef: MatDialogRef<ConsultarStockMinimoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConsultarStockMinimoDialogData,
    private readonly _subfamiliaService: SubfamiliaService,
    private readonly _snackBarService: SnackBarService
  ) {
    this.filtro = new Filtro();
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {

    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  cancelar(): void {
    this.dialogRef.close(false);
  }

  mover(): void {
    this.dialogRef.close(true);
  }

  seleccionarFamilia(): void {
    this.subfamilias$ = this._subfamiliaService.obtenerSubFamilias(this.filtro.familia).subscribe(
      subfamilias => {
        this.subfamilias = subfamilias;
      },
      error => {
        this._snackBarService.showError('Error al obtener subfamilias', 'Ok');

      }
    )
  }

}
