<app-pavso-title-section [title]="'Monitoreo de factura'" [module]="'Ventas'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <div class="row">
    <div class="col s12 m12 l9">
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l4">
            <div [ngStyle]="{display: 'flex'}">
              <mat-checkbox class="example-margin" [(ngModel)]="enableRange" name="enableRange">
              </mat-checkbox>
              <mat-form-field>
                <mat-label>Desde - Hasta</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [disabled]="!enableRange">
                  <input matStartDate formControlName="start" placeholder="Fecha inicio" />
                  <input matEndDate formControlName="end" placeholder="Fecha término" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col s12 m12 l3">
            <select-search [label]="'Cliente'" [placeholder]="'Ej. Santo tomas'" [name]="'cli_codcli'"
              [value]="'cli_codcli'" [description]="'description'" [data]="clientes" [model]="filtro.cliente"
              (cambioSelect)="filtro.cliente = $event">
            </select-search>

          </div>
          <div class="col s12 m12 l3">
            <select-search [label]="'Tipo de documento'" [placeholder]="'Ej. FAC'" [name]="'tdo_codtdo'"
              [value]="'tdo_codtdo'" [description]="'tdo_deslar'" [data]="tiposDocumento"
              [model]="filtro.tipodocumento" (cambioSelect)="filtro.tipodocumento = $event">
            </select-search>

          </div>

          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>Nro</mat-label>
              <input matInput placeholder="Nro" [(ngModel)]="filtro.nrodocumento" name="nrodocumento" />
            </mat-form-field>
          </div>

          <div class="col s12 m12 l12" align="center">
            <button type="submit" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" (click)="buscarComprobante()">
              <mat-icon>search</mat-icon>
            </button>
          </div>

        </div>

      </mat-card>
    </div>
    <div class="col s12 m12 l3">
      <mat-card class="mat-elevation-z0">
        <div class="row">

          <div class="col s12">
            <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
              <h1>Certificado Digital</h1>
              <div class="pav-separator"></div>
              <span [ngStyle]="{color: 'green'}">Vigente</span>

            </div>
            <div class="row">
              <div class="col s6">
                <mat-form-field class="pav-date-input">
                  <mat-label>Fecha Apertura</mat-label>
                  <input matInput [matDatepicker]="pickerApertura">
                  <mat-datepicker-toggle matSuffix [for]="pickerApertura"></mat-datepicker-toggle>
                  <mat-datepicker #pickerApertura></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s6">
                <mat-form-field class="pav-date-input">
                  <mat-label>Fecha Finalización</mat-label>
                  <input matInput [matDatepicker]="pickerFin">
                  <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFin></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>


        </div>
      </mat-card>
    </div>
    <div class="col s12" [ngStyle]="{marginTop:'12px'}">
      <mat-card class="mat-elevation-z0">
        <div [ngStyle]="{display: isMobile || isTablet? 'block': 'flex'}">

          <button mat-raised-button [ngStyle]="{marginLeft: '5px'}" type="button" [disabled]="selection.selected.length == 0">
            <img src="https://cdn.icon-icons.com/icons2/112/PNG/512/xml_18893.png" alt="XML" width="30px">
            Generar XML
          </button>
          <button mat-raised-button [ngStyle]="{marginLeft: '5px'}" type="button" [disabled]="selection.selected.length == 0">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUkvtg9L1oBVOoWUMqrwmLVo4Fc4QF5xoNsg&s"
              alt="SUNAT" width="30px">
            Enviar SUNAT
          </button>
          <button mat-raised-button [ngStyle]="{marginLeft: '5px'}" type="button" [disabled]="selection.selected.length == 0">
            <img
              src="https://w7.pngwing.com/pngs/924/655/png-transparent-ticket-computer-icons-cinema-movie-ticket-miscellaneous-text-logo-thumbnail.png"
              alt="Ticket" width="30px">
            Consulta Ticket
          </button>
          <button mat-raised-button [ngStyle]="{marginLeft: '5px'}" type="button" [disabled]="selection.selected.length == 0">
            <img
              src="https://www.unex.es/conoce-la-uex/centros/profesorado/archivos/imagenes/imagenes-secretaria/20151205_566242c218cd9.png/image"
              alt="PDF" width="20px">
            Generar PDF
          </button>
          <button mat-raised-button [ngStyle]="{marginLeft: '5px'}" type="button" [disabled]="selection.selected.length == 0">
            <img
              src="https://w7.pngwing.com/pngs/851/106/png-transparent-computer-icons-email-telephone-website-miscellaneous-blue-angle.png"
              alt="Mail" width="30px">
            Enviar correo</button>
          <button mat-raised-button [ngStyle]="{marginLeft: '5px'}" type="button" [disabled]="selection.selected.length == 0">
            <img src="https://archive.org/download/download-png/dl.png" alt="Mail" width="30px">
            Descargar</button>

          <button mat-raised-button [ngStyle]="{marginLeft: '5px'}" type="button" [disabled]="selection.selected.length == 0">
            <img src="https://cdn-icons-png.flaticon.com/512/3396/3396255.png" alt="Mail" width="30px">
            Libera Documentos</button>
        </div>
      </mat-card>
    </div>
  </div>
  <br />

  <div>
    <div class="mat-elevation-z0">
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon"></div>
        <div class="pav-table-header-title">Comprobantes</div>
        <div class="pav-table-search">
          <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input />
        </div>
        <!-- <div class="pav-table-header-add">
          <button (click)="seleccionarTipo()" mat-flat-button class="pav-button-add" color="primary" type="button">
            <mat-icon>add</mat-icon>
            {{ LABELS_NAME.BTN_ADD }}
          </button>
        </div> -->
      </div>

      <mat-progress-bar *ngIf="loaderMonitoreo" mode="indeterminate"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 170px !important;">Acciones</th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <button type="button" matTooltip="Generar Documentos" mat-icon-button color="warn">
                <span class="name-button">Docs.</span>
              </button>
              <!-- <button (click)="obtenerPdf()" matTooltip="Visualiza PDF" mat-icon-button color="warn">
                <mat-icon>picture_as_pdf</mat-icon>
              </button> -->
              <button type="button" matTooltip="Enviar Sunat" mat-icon-button color="accent">
                <span class="name-button">SUNAT</span>
              </button>
              <button type="button" matTooltip="Enviar Correo" mat-icon-button color="primary">
                <mat-icon>email</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="cco_codcco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Cuenta Corriente
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cuenta Corriente" (click)="seleccionarFila(row)">
              {{ row.cco_codcco }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
            <td mat-cell *matCellDef="let row" data-label="Fecha" (click)="seleccionarFila(row)">
              {{ row.cco_fecemi | date: 'dd/MM/yyyy': 'UTC' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="tdo_codtdo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Doc.</th>
            <td mat-cell *matCellDef="let row" data-label="Doc." (click)="seleccionarFila(row)">
              {{ row.tdo_codtdo }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cco_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Num. Doc.</th>
            <td mat-cell *matCellDef="let row" data-label="Num. Doc." (click)="seleccionarFila(row)">
              {{ row.cco_numdoc }}
            </td>
          </ng-container>

          <ng-container matColumnDef="TMO_CODTMO">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Moneda</th>
            <td mat-cell *matCellDef="let row" data-label="Moneda" (click)="seleccionarFila(row)">
              {{ row.TMO_CODTMO }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cco_impdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Importe</th>
            <td mat-cell *matCellDef="let row" data-label="Importe" (click)="seleccionarFila(row)">
              {{ row.cco_impdoc.toFixed(2) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cli_numruc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>RUC</th>
            <td mat-cell *matCellDef="let row" data-label="RUC" (click)="seleccionarFila(row)">
              {{ row.cli_numruc }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Nombre Cliente
            </th>
            <td mat-cell *matCellDef="let row" data-label="Nombre Cliente" (click)="seleccionarFila(row)">
              {{ row.cli_nomcli }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rfe_estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Estado
            </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" (click)="seleccionarFila(row)">
              {{ row.rfe_estado }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rfe_descri">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Texto
            </th>
            <td mat-cell *matCellDef="let row" data-label="Texto" (click)="seleccionarFila(row)">
              {{ row.rfe_descri }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cco_code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              cco_code
            </th>
            <td mat-cell *matCellDef="let row" data-label="cco_code" (click)="seleccionarFila(row)">
              {{ row.cco_code }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cco_condic">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              cco_condic
            </th>
            <td mat-cell *matCellDef="let row" data-label="cco_condic" (click)="seleccionarFila(row)">
              {{ row.cco_condic }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="14">
              No se encontraron registros "{{ input.value }}"
            </td>
          </tr>
        </table>
      </div>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>

  <br />

  <div class="row">
    <div class="col s12 m12 l7">
      <div class="mat-elevation-z0">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon"></div>
          <div class="pav-table-header-title">Documentos</div>
          <div class="pav-table-header-add"></div>
        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="loaderDataDetalle"></mat-progress-bar>

        <div class="overflow-x">
          <table mat-table [dataSource]="dataSourceArchivo" matSort>
            <ng-container matColumnDef="rfe_estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
              <td mat-cell *matCellDef="let row" data-label="Estado">
                {{ row.rfe_estado }}
              </td>
            </ng-container>

            <ng-container matColumnDef="rfe_descri">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Texto</th>
              <td mat-cell *matCellDef="let row" data-label="Texto">
                {{ row.rfe_descri }}
              </td>
            </ng-container>

            <ng-container matColumnDef="rfe_coduse">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
              <td mat-cell *matCellDef="let row" data-label="Usuario">
                {{ row.rfe_coduse }}
              </td>
            </ng-container>

            <ng-container matColumnDef="rfe_fecupd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Actualizacion
              </th>
              <td mat-cell *matCellDef="let row" data-label="Actualizacion">
                {{ row.rfe_fecupd | date: 'dd/MM/yyyy': 'UTC' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="rfe_nomarc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Archivo</th>
              <td mat-cell *matCellDef="let row" data-label="Archivo">
                {{ row.rfe_nomarc }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsArchivo"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsArchivo"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9">
                No se encontraron registros "{{ input.value }}"
              </td>
            </tr>
          </table>
        </div>

        <mat-paginator #paginatorArchivo showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>

    </div>

    <div class="col s12 m12 l5">
      <mat-card class="mat-elevation-z0">
        <h1>Comunicación baja</h1>
        <div class="row">

          <div class="col s12 m12 l6">
            <button mat-stroked-button color="primary" [ngStyle]="{width: '100%', marginBottom: '10px'}"
              type="button">
              (1.) Generar y Firmar CB XML
            </button>
          </div>
          <div class="col s12 m12 l6">
            <button mat-stroked-button color="primary" [ngStyle]="{width: '100%', marginBottom: '10px'}"
              type="button">
              (2.) Envío Comunicación Baja
            </button>

          </div>

        </div>
      </mat-card>
      <br />
      <mat-card class="mat-elevation-z0">
        <h1>Consulta Estado</h1>
        <button mat-flat-button class="pav-button-custom" type="button" (click)="consultarGetStatusCDR()">
          Consultar GetStatusCDR
        </button>
        &nbsp;
        <button mat-flat-button class="pav-button-custom" type="button" (click)="actualizaMotivoBajaSUNAT()">
          Actualiza Motivo de Baja SUNAT
        </button>
      </mat-card>
    </div>
  </div>

  <!-- <pav-form-actions [disabled]="loaderData" [btnName]="'Grabar'" [isLoading]="loaderReg"
    urlBack="/modulo-compras/solicitudes-de-compra">
    <div id="botonesAdicionales">

    </div>
  </pav-form-actions> -->

</div>
