import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService } from 'src/app/services';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-lead-time',
  templateUrl: './lead-time.component.html',
  styleUrls: ['./lead-time.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class LeadTimeComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumnsExtraerStock: string[] = ['item', 'cod_producto', 'descripcion', 'cant_producto', 'cant_adicional', 'cant_ajustada', 'frecuencia_anual'];
  dataSourceExtraerStock: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorExtraerStock: MatPaginator;
  @ViewChild(MatSort) sortExtraerStock: MatSort;


  displayedColumnsCompraTransito: string[] = ['cod_producto', 'producto', 'cantidad', 'fecha_entrega', 'ordenes_pendientes'];
  dataSourceCompraTransito: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorCompraTransito: MatPaginator;
  @ViewChild(MatSort) sortCompraTransito: MatSort;

  displayedColumnsLoteEconomico: string[] = ['item', 'cod_producto', 'descripcion', 'consumo_anual', 'costo_precio', 'cant_lote', 'ajuste_cantidad', 'cant_producto'];
  dataSourceLoteEconomico: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorLoteEconomico: MatPaginator;
  @ViewChild(MatSort) sortLoteEconomico: MatSort;

  displayedColumnsActualizaLead: string[] = ['item', 'cod_producto', 'descripcion', 'stock_seguridad', 'consumo_anual', 'stock_almacen', 'pedido_despachar', 'stock_disponible', 'punto_pedido', 'faltantes', 'compras_transito', 'fecha_entrega', 'dias', 'lote_economico', 'status'];
  dataSourceActualizaLead: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorActualizaLead: MatPaginator;
  @ViewChild(MatSort) sortActualizaLead: MatSort;

  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment): void {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tipoDoc$: Subscription;
  loading$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _generalService: GeneralService,
    private _authService: AuthenticationService
  ) {
    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();

    this.dataSourceExtraerStock = fillTable([], this.paginatorExtraerStock, this.sortExtraerStock);

    this.dataSourceCompraTransito = fillTable([], this.paginatorCompraTransito, this.sortCompraTransito);

    this.dataSourceLoteEconomico = fillTable([], this.paginatorLoteEconomico, this.sortLoteEconomico);

    this.dataSourceActualizaLead = fillTable([], this.paginatorActualizaLead, this.sortActualizaLead);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.driver.CHO_INDSTA = "1";

    this.tipoDoc$ = this._generalService.listarTipoDocumentoIdentidad().subscribe(
      tiposDocumento => {
        this.tiposDocumento = tiposDocumento;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  registrarChofer(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoDoc$,
      this.loading$
    ])

  }
}
