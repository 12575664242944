import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'resumen-texto-tesoreria',
  templateUrl: './resumen-texto-tesoreria.dialog.html',
  styleUrls: ['./resumen-texto-tesoreria.dialog.scss']
})
export class ResumenTextoTesoreriaDialog implements OnInit {

  displayedColumns: string[] = ['cli_nomcli', 'tdo_codtdo', 'cco_numdoc', 'CCO_FECEMIFormat', 'cco_fecvenformat', 'cco_salnet'];
  dataSource = [];



  constructor(
    public dialogRef: MatDialogRef<ResumenTextoTesoreriaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBarService: SnackBarService
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.dataSource = this.data;

  }

  copiar() {
    let contenido = '';
    this.dataSource.forEach(row => {
      contenido += `${row.cli_nomcli}\t${row.tdo_codtdo}\t${row.cco_numdoc}\t${row.CCO_FECEMIFormat}\t${row.cco_fecvenformat}\t${row.cco_salnet}\n`;
    });

    // Copiar al portapapeles
    navigator.clipboard.writeText(contenido).then(() => {
      this._snackBarService.showSuccess('Contenido copiado !!', 'Ok')
    }).catch(err => {
      // console.error('Error al copiar la tabla: ', err);
    });
  }

}
