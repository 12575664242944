import { PavsoColors } from "@data/enums/color.enum";
import { HeaderReport } from "@data/interfaces/headers.report";
import { logo } from "@shared/resources/logo";
import { NumeroALetras } from "@utils/conversions/number2letters.conversion";
import { formatDateWithDash, formatDateWithSlash } from "@utils/formats/date.format";
import { numberWithCommas } from "@utils/formats/number.format";
import { noBorders, openTopBorder, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";


export async function generarPdfCRM() {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([ 20, 20, 20, 20 ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("COMERCIALIZADORA ASIA PERU S.A.C.").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("13/09/2021").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("RCOM_REP_DET_VE").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("15:03:11").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("INFORME CRM TEMPLATE").fontSize(12).bold().alignment('center').end
        ).end
      ]
    ]).layout('noBorders').widths([1140]).end
  );

  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("Cliente").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("TD").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Número").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Fecha").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Línea").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cod. Pro.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Producto").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cantidad").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Precio").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Moneda").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Importe").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Usuario").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Referencia").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Pedido").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Guía Remisión").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cond. Pago").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Vendedor").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Motivo").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Ind. Guía").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Almacén").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Costo Prod.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Departamento").fontSize(8).bold().alignment('center').end
        ).end
      ]
    ]).widths([80,20,30,30,40,50,80,40,30,30,30,60,80,40,50,30,80,30,30,30,30,30]).end
  );

  pdf.add(
    new Table(this.dataDetalleFacturacion).layout('noBorders').widths([85,20,35,30,40,50,83,43,30,30,30,60,80,40,55,30,80,30,30,30,30,30]).end
  );

  pdf.create().open();

}

export function reporteRegistrosOportunidad(headers: HeaderReport, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `: `, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  data.forEach((item, key) => {
    if(key % 2 == 0) {
      detalle.push([
        new Cell(
          new Txt(item.idtkt).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.detalle).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt( numberWithCommas(item.cosopo.toFixed(2))).alignment('right').fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.fecreg)).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.asunto).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.nomcli).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.nombreors).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descriotn).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.oet_descri).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descrioln).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descrioms).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.nomvde).fontSize(6).end
        ).fillColor('#EFF8FB').border(noBorders).end,
      ])
    } else {
      detalle.push([
        new Cell(
          new Txt(item.idtkt).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.detalle).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt( numberWithCommas(item.cosopo.toFixed(2))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.fecreg)).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.asunto).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.nomcli).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.nombreors).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descriotn).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.oet_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descrioln).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descrioms).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.nomvde).fontSize(6).end
        ).border(noBorders).end,
      ])
    }
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Registros de oportunidad').fontSize(12).bold().alignment('center').end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Stack([
            pdf.ln(1)
          ]).end
        ).border(noBorders).colSpan(12).end,
        ...obtenerNulls(11),
      ],
      [
        new Cell(
          new Txt('Ticket').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Requerimiento').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Importe').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Fecha').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Descripción').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Cliente').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Responsable').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Tipo negociación').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Etapa').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Línea de negocio').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Canal').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
        new Cell(
          new Txt('Vendedor').fontSize(7).bold().alignment('center').end
        ).border(noBorders).fillColor(PavsoColors.Blue).color(PavsoColors.White).end,
      ],
      ...detalle
    ]).headerRows(3).widths([30,110,40,40,90,70,70,55,40,55,40,55]).end
  )

  pdf.create().open();

}

export function reporteSeguimientoDeProyecto(headers: HeaderReport, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);

  let detalle = [];
  let total = 0;
  data.forEach((item, key) => {

    if(key % 2 == 0) {
      detalle.push([
        new Cell(
          new Txt(`${item.idtkt} | ${item.ost_idset}`).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.codcli).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.nomcli).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descrioms).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.nomccl).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descrioln).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.ose_descri).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.ors_nombre).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.oet_descri).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descrioss).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.pcc_fecdoc)).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.cot_imptot_mn? numberWithCommas(item.cot_imptot_mn): '0.00').alignment('right').fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.cot_imptot_me? numberWithCommas(item.cot_imptot_me): '0.00').alignment('right').fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.pcc_ordcom).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.pcc_fecped)).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.pcc_imptot_mn? numberWithCommas(item.pcc_imptot_mn): '0.00').alignment('right').fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.pcc_imptot_me? numberWithCommas(item.pcc_imptot_me): '0.00').alignment('right').fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
      ]);
    } else {
      detalle.push([
        new Cell(
          new Txt(`${item.idtkt} | ${item.ost_idset}`).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.codcli).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.nomcli).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descrioms).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.nomccl).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descrioln).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ose_descri).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ors_nombre).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.oet_descri).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descrioss).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.pcc_fecdoc)).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cot_imptot_mn? numberWithCommas(item.cot_imptot_mn): '0.00').alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cot_imptot_me? numberWithCommas(item.cot_imptot_me): '0.00').alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.pcc_ordcom).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.pcc_fecped)).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.pcc_imptot_mn? numberWithCommas(item.pcc_imptot_mn): '0.00').alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.pcc_imptot_me? numberWithCommas(item.pcc_imptot_me): '0.00').alignment('right').fontSize(5).end
        ).border(noBorders).end,
      ]);
    }

  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).end,
            new Txt("RCRM_SEGU_GENE").end,
          ]).end
        ).border(noBorders).colSpan(3).fontSize(9).bold().end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("SEGUIMIENTO DE OPORTUNIDAD").end
        ).border(noBorders).colSpan(11).fontSize(10).alignment('center').bold().end,
        ...obtenerNulls(10),
        new Cell(
          new Txt(headers.date).end
        ).border(noBorders).colSpan(3).alignment('right').fontSize(9).bold().end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("# Oport. | # Seg.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("RUC").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Nombre").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Canal").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Contacto").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Linea").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Sector").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Responsable").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Etapa").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Situación").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Fec. Coti.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Cot. M.N.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Cot. M.E.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("O. C.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Fec. Acep.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("O.C. M.N.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("O.C. M.E.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
      ],
      ...detalle,

    ]).headerRows(2).widths([30,30,70,40,60,30,45,50,40,30,30,35,35,25,30,35,35]).end
  )

  pdf.create().open();

}

export function reporteVentasPerdidas(headers: HeaderReport, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);

  let detalle = [];
  let total = 0;
  data.forEach((item, key) => {

    if(key % 2 == 0) {
      detalle.push([
        new Cell(
          new Txt(`${item.idtkt} | ${item.ost_idset}`).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.codcli).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.nomcli).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descrioms).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.nomccl).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descrioln).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.ose_descri).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.ors_nombre).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.oet_descri).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.descrioss).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.pcc_fecdoc)).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.cot_imptot_mn? numberWithCommas(item.cot_imptot_mn): '0.00').alignment('right').fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.cot_imptot_me? numberWithCommas(item.cot_imptot_me): '0.00').alignment('right').fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.pcc_ordcom).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.pcc_fecped)).fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.pcc_imptot_mn? numberWithCommas(item.pcc_imptot_mn): '0.00').alignment('right').fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
        new Cell(
          new Txt(item.pcc_imptot_me? numberWithCommas(item.pcc_imptot_me): '0.00').alignment('right').fontSize(5).end
        ).fillColor('#EFF8FB').border(noBorders).end,
      ]);
    } else {
      detalle.push([
        new Cell(
          new Txt(`${item.idtkt} | ${item.ost_idset}`).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.codcli).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.nomcli).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descrioms).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.nomccl).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descrioln).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ose_descri).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ors_nombre).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.oet_descri).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.descrioss).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.pcc_fecdoc)).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cot_imptot_mn? numberWithCommas(item.cot_imptot_mn): '0.00').alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cot_imptot_me? numberWithCommas(item.cot_imptot_me): '0.00').alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.pcc_ordcom).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.pcc_fecped)).fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.pcc_imptot_mn? numberWithCommas(item.pcc_imptot_mn): '0.00').alignment('right').fontSize(5).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.pcc_imptot_me? numberWithCommas(item.pcc_imptot_me): '0.00').alignment('right').fontSize(5).end
        ).border(noBorders).end,
      ]);
    }

  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).end,
            new Txt("RCRM_SEGU_GENE").end,
          ]).end
        ).border(noBorders).colSpan(3).fontSize(9).bold().end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("VENTAS PERDIDAS").end
        ).border(noBorders).colSpan(11).fontSize(10).alignment('center').bold().end,
        ...obtenerNulls(10),
        new Cell(
          new Txt(headers.date).end
        ).border(noBorders).colSpan(3).alignment('right').fontSize(9).bold().end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("# Oport. | # Seg.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("RUC").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Nombre").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Canal").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Contacto").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Linea").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Sector").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Responsable").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Etapa").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Situación").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Fec. Coti.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Cot. M.N.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Cot. M.E.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("O. C.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("Fec. Acep.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("O.C. M.N.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
        new Cell(
          new Txt("O.C. M.E.").end
        ).border(noBorders).fillColor(PavsoColors.Blue).color('white').bold().fontSize(7).end,
      ],
      ...detalle,

    ]).headerRows(2).widths([30,30,70,40,60,30,45,50,40,30,30,35,35,25,30,35,35]).end
  )

  pdf.create().open();

}

export async function generarPDFPresupuesto(headers, data, language) {
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });

  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `${language.user}: ${headers.coduse}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(logo).alignment('left').width(90).build(),
          ]).end
        ).end,
        new Cell(
          new Stack([
            new Txt("NOMBRE DE LA EMPRESA").fontSize(11).bold().alignment('center').end,
            new Txt(`RUC: 3030303030303`).fontSize(10).alignment('center').end,
            new Txt(`DIERCCION`).fontSize(10).alignment('center').end
          ]).alignment('center').end ,
        ).end,

        new Cell(
          new Stack([
            new Txt('______________________').bold().end,
            new Columns([
              new Txt(`${language.print_time}: `).fontSize(7).end,
              new Txt(headers.hour).alignment('right').fontSize(7).end
            ]).end,
            new Columns([
              new Txt(`${language.date}: `).fontSize(7).end,
              new Txt(headers.date).alignment('right').fontSize(7).end
            ]).end,
            new Txt('______________________').bold().end,
            new Txt(`${language.user} : ${headers.coduse}`).alignment('center').fontSize(7).end
          ]).end
        ).end
      ]
    ]).layout('noBorders').widths([ 120, 540, 120 ]).end
  )

  pdf.add(
    pdf.ln(1)
  );

  // DETALLE DE PRODUCTO
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(language.budget).fontSize(10).bold().end
        ).colSpan(2).fillColor(PavsoColors.Green).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('000003').fontSize(10).bold().end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
      ],
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(10).end,
        ...obtenerNulls(9),
      ],
      [
        new Cell(
          new Txt(`${language.client} :`).fontSize(8).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('---------').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`${language.nro_idtkt_idset} :`).fontSize(8).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('---------').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt(`${language.date} :`).fontSize(8).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('---------').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`${language.observations} :`).fontSize(8).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('---------').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt(`${language.exchange_rate} :`).fontSize(8).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('---------').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('').fontSize(8).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt(language.item).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt(language.description).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt(language.amount).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt(language.unit_cost).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt('US$ / Sist. NAC').fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt(language.incidence_cost).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt(language.margin).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt(language.margin_amount).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt(language.unit_offer).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
        new Cell(
          new Txt(language.total_offer).fontSize(8).bold().end
        ).fillColor(PavsoColors.Green).end,
      ],
      [
        new Cell(
          new Txt('001').fontSize(7).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt('producto 00001').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('1').fontSize(7).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt(language.full_supply).bold().fontSize(7).alignment('right').end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt(language.subtotal).bold().fontSize(7).alignment('right').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt(language.administrative_cost).bold().fontSize(7).alignment('right').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt(language.budget_value).bold().fontSize(7).alignment('right').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt(language.igv).bold().fontSize(7).alignment('right').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt(language.total_budget).bold().fontSize(7).alignment('right').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('10.00').fontSize(7).bold().alignment('right').end
        ).border(noBorders).end,
      ]
    ]).widths([30, 240, 55, 55, 55, 55, 55, 55, 55, 55]).end
  )


  pdf.create().open();
}

export async function generarPDFCotizacion(headers, data, language) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([ 40, 40, 40, 40 ]);
  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.coduse}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];
  let total = 0;
  data.detalle.forEach(element => {
    total += element.PCD_IMPTOT;
    detalle.push([
      new Cell(new Txt(element.PCD_CORPCD).fontSize(7).alignment('center').end).border(noBorders).end,
      new Cell(new Txt(element.PCD_GLOPCD? element.PCD_GLOPCD: element.prd_desesp).fontSize(7).end).border(noBorders).end,
      new Cell(new Txt('-').fontSize(7).end).border(noBorders).end,
      new Cell(new Txt(element.PCD_CANSOL).alignment('right').fontSize(7).end).border(noBorders).end,
      new Cell(new Txt(element.PCD_PRUIGV.toFixed(2)).alignment('right').fontSize(7).end).border(noBorders).end,
      new Cell(new Txt((element.PCD_IMPTOT * element.PCD_PORDES / 100).toFixed(2)).alignment('right').fontSize(7).end).border(noBorders).end,
      new Cell(new Txt(element.PCD_IMPTOT.toFixed(2)).alignment('right').fontSize(7).end).border(noBorders).end,
    ])
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(
              logo
            )
            .alignment('left')
            .width(90)
            .build()
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(headers.nomcia).fontSize(11).bold().alignment('center').end,
            new Txt(`${language.ruc}: ${headers.numruc}`).fontSize(10).bold().alignment('center').end,
            new Txt(`${data.CIA_DIRCIA}`).fontSize(10).alignment('center').end
          ]).alignment('center').end ,
        ).border(noBorders).end,

        new Cell(
          new Stack([
            new Columns([
              new Txt(`${language.print_time}: `).fontSize(7).end,
              new Txt(headers.hour).alignment('right').fontSize(7).end
            ]).end,
            new Columns([
              new Txt(`${language.date}: `).fontSize(7).end,
              new Txt(headers.date).alignment('right').fontSize(7).end
            ]).end,
            new Txt(`${language.user} : ${headers.coduse}`).alignment('center').fontSize(7).end
          ]).end
        ).border(topBorder).end
      ]
    ]).widths([ 110, 255, 120 ]).end
  )

  pdf.add(
    pdf.ln(1)
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(language.quotation).alignment('center').fontSize(10).bold().end
        ).width(500).end ],
      [
        new Columns(
          [
            new Txt(`${language.nro_quotation} : ${data.PCC_NUMPCC}`).fontSize(10).bold().end,
            new Txt(data.sdv_dessdv).bold().end
          ]
        ).end
      ],
      [
        new Columns([
          new Table([
            [
              new Txt(`${language.applicant} :`).fontSize(10).bold().end,
              new Txt(data.pcc_coduse).fontSize(10).end
            ]
          ]).widths([ 55, 80 ]).layout('noBorders').end,
          new Table([
            [
              new Txt(`${language.date} :`).fontSize(10).bold().end,
              new Txt(formatDateWithDash(new Date())).fontSize(10).end
            ]
          ]).widths([ 35, 80 ]).layout('noBorders').end,
          new Table([
            [
              new Txt(`${language.way_to_pay} :`).fontSize(10).bold().end,
              new Txt(data.CPA_DESCRI).fontSize(10).end
            ]
          ]).widths([ 75, 60 ]).layout('noBorders').end,
        ]).end
      ],
      [
        new Columns([
          new Table([[
            new Txt(`${language.seller} :`).fontSize(10).bold().end,
            new Txt(data.VDE_NOMVDE).fontSize(10).end
          ]]).widths([ 55, 440 ]).layout('noBorders').end
        ]).end,
      ],
      [
        new Txt(language.customer_information).fontSize(10).alignment('center').bold().end
      ],
      [
        new Stack([
          new Columns([
            new Table([[
              new Txt(`${language.client} :`).fontSize(10).bold().end,
              new Txt(data.cli_nomcli).fontSize(10).end
            ]]).widths([ 55, 420 ]).layout('noBorders').end

          ]).end,
          new Columns([
            new Table([[
              new Txt(`${language.address} :`).fontSize(10).bold().end,
              new Txt(data.cli_dircli).fontSize(10).end
            ]]).widths([ 55, 420 ]).layout('noBorders').end
          ]).end,
          new Columns([
            new Table([[
              new Txt(`${language.ruc} :`).fontSize(10).bold().end,
              new Txt(data.CLI_CODCLI).fontSize(10).end
            ]]).widths([ 55, 165 ]).layout('noBorders').end,
            new Table([[
              new Txt(`${language.ubigeous} :`).fontSize(10).bold().end,
              new Txt(data.desubica_cliente).fontSize(10).end
            ]]).widths([ 45, 175 ]).layout('noBorders').end,

          ]).end,
          new Table([[
            new Txt(`${language.dispatch_address} :`).fontSize(10).bold().end,
            new Txt(data.lde_deslar).fontSize(10).end
          ]]).widths([ 110, 370 ]).layout('noBorders').end,
        ]).end
      ],
      [
        new Txt(language.carrier_information).fontSize(10).alignment('center').bold().end
      ],

      [
        new Stack([
          new Table([[
            new Txt(language.comments).fontSize(10).bold().end,
          ]]).layout('noBorders').end,
          new Txt(data.PCC_OBSPED).fontSize(10).end,
          pdf.ln(2)
        ]).end
      ],

    ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(new Txt(language.it).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.description).fontSize(9).bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.model).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.amount).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.price).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.discount).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.valued).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
      ],
      ...detalle

    ]).widths([30, 169, 50, 50, 50, 50, 50]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([[
      new Txt('Son: ' + NumeroALetras(total)).fontSize(10).bold().end,
      new Table([
        [
          new Txt(`${language.total}:`).fontSize(10).bold().end,
          new Txt(numberWithCommas(total.toFixed(2))).fontSize(10).alignment('right').bold().end,
        ],
      ]).widths([ 60, 100 ]).end
    ]]).widths([ 320, 150 ]).layout('noBorders').end,
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [''],
    ]).widths([ '*', 0 ]).end
  )

  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Columns([
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(1),
            new Txt(data.S10_NOMUSU).fontSize(10).alignment('center').end,
            new Txt(language.applicant).fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths([ '*', 0 ]).end,
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(2),
            new Txt(language.approval).fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths([ '*', 0 ]).end,
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(2),
            new Txt(language.passed).fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths([ '*', 0 ]).end
    ]).end
  )

  pdf.create().open();
}

export async function geenerarPDFOrdenPedido(headers, data, language) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([ 40, 40, 40, 40 ]);
  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.coduse}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];
  let total = 0;
  data.detalle.forEach(element => {
    total += element.PCD_IMPTOT;
    detalle.push([
      new Cell(new Txt(element.PCD_CORPCD).fontSize(7).alignment('center').end).border(noBorders).end,
      new Cell(new Txt(element.prd_desesp).fontSize(7).end).border(noBorders).end,
      new Cell(new Txt('-').fontSize(7).end).border(noBorders).end,
      new Cell(new Txt(element.PCD_CANSOL).alignment('right').fontSize(7).end).border(noBorders).end,
      new Cell(new Txt(element.PCD_PRUIGV.toFixed(2)).alignment('right').fontSize(7).end).border(noBorders).end,
      new Cell(new Txt((element.PCD_IMPTOT * element.PCD_PORDES / 100).toFixed(2)).alignment('right').fontSize(7).end).border(noBorders).end,
      new Cell(new Txt(element.PCD_IMPTOT.toFixed(2)).alignment('right').fontSize(7).end).border(noBorders).end,
    ])
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(
              logo
            )
              .alignment('left')
              .width(90)
              .build()
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(headers.nomcia).fontSize(11).bold().alignment('center').end,
            new Txt(`${language.ruc}: ${headers.numruc}`).fontSize(10).bold().alignment('center').end,
            new Txt(`${data.CIA_DIRCIA}`).fontSize(10).alignment('center').end
          ]).alignment('center').end ,
        ).border(noBorders).end,

        new Cell(
          new Stack([
            new Columns([
              new Txt(`${language.print_time}: `).fontSize(7).end,
              new Txt(headers.hour).alignment('right').fontSize(7).end
            ]).end,
            new Columns([
              new Txt(`${language.date}: `).fontSize(7).end,
              new Txt(headers.date).alignment('right').fontSize(7).end
            ]).end,
            new Txt(`${language.user} : ${headers.coduse}`).alignment('center').fontSize(7).end
          ]).end
        ).border(topBorder).end
      ]
    ]).widths([ 110, 255, 120 ]).end
  )

  pdf.add(
    pdf.ln(1)
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(language.order).alignment('center').fontSize(10).bold().end
        ).width(500).end ],
      [
        new Columns(
          [
            new Txt(`${language.nro_order} : ${data.PCC_NUMPCC}`).fontSize(10).bold().end,
            new Txt(data.sdv_dessdv).bold().end
          ]
        ).end
      ],
      [
        new Columns([
          new Table([
            [
              new Txt(`${language.applicant} :`).fontSize(10).bold().end,
              new Txt(data.pcc_coduse).fontSize(10).end
            ]
          ]).widths([ 55, 80 ]).layout('noBorders').end,
          new Table([
            [
              new Txt(`${language.date} :`).fontSize(10).bold().end,
              new Txt(formatDateWithDash(new Date())).fontSize(10).end
            ]
          ]).widths([ 35, 80 ]).layout('noBorders').end,
          new Table([
            [
              new Txt(`${language.way_to_pay} :`).fontSize(10).bold().end,
              new Txt(data.CPA_DESCRI).fontSize(10).end
            ]
          ]).widths([ 75, 60 ]).layout('noBorders').end,
        ]).end
      ],
      [
        new Columns([
          new Table([[
            new Txt(`${language.seller} :`).fontSize(10).bold().end,
            new Txt(data.VDE_NOMVDE).fontSize(10).end
          ]]).widths([ 55, 440 ]).layout('noBorders').end
        ]).end,
      ],
      [
        new Txt(language.customer_information).fontSize(10).alignment('center').bold().end
      ],
      [
        new Stack([
          new Columns([
            new Table([[
              new Txt(`${language.client} :`).fontSize(10).bold().end,
              new Txt(data.cli_nomcli).fontSize(10).end
            ]]).widths([ 55, 420 ]).layout('noBorders').end

          ]).end,
          new Columns([
            new Table([[
              new Txt(`${language.address} :`).fontSize(10).bold().end,
              new Txt(data.cli_dircli).fontSize(10).end
            ]]).widths([ 55, 420 ]).layout('noBorders').end
          ]).end,
          new Columns([
            new Table([[
              new Txt(`${language.ruc} :`).fontSize(10).bold().end,
              new Txt(data.CLI_CODCLI).fontSize(10).end
            ]]).widths([ 55, 165 ]).layout('noBorders').end,
            new Table([[
              new Txt(`${language.ubigeous} :`).fontSize(10).bold().end,
              new Txt(data.desubica_cliente).fontSize(10).end
            ]]).widths([ 45, 175 ]).layout('noBorders').end,

          ]).end,
          new Table([[
            new Txt(`${language.dispatch_address} :`).fontSize(10).bold().end,
            new Txt(data.lde_deslar).fontSize(10).end
          ]]).widths([ 110, 370 ]).layout('noBorders').end,
        ]).end
      ],
      [
        new Txt(language.carrier_information).fontSize(10).alignment('center').bold().end
      ],

      [
        new Stack([
          new Table([[
            new Txt(language.comments).fontSize(10).bold().end,
          ]]).layout('noBorders').end,
          new Txt(data.PCC_OBSPED).fontSize(10).end,
          pdf.ln(2)
        ]).end
      ],

    ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(new Txt(language.it).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.description).fontSize(9).bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.model).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.amount).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.price).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.discount).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
        new Cell(new Txt(language.valued).fontSize(9).alignment('center').bold().end).border(openTopBorder).end,
      ],
      ...detalle

    ]).widths([30, 169, 50, 50, 50, 50, 50]).end
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([[
      new Txt('Son: ' + NumeroALetras(total)).fontSize(10).bold().end,
      new Table([
        [
          new Txt(`${language.total}:`).fontSize(10).bold().end,
          new Txt(numberWithCommas(total.toFixed(2))).fontSize(10).alignment('right').bold().end,
        ],
      ]).widths([ 60, 100 ]).end
    ]]).widths([ 320, 150 ]).layout('noBorders').end,
  )

  pdf.add(
    pdf.ln(1)
  )

  pdf.add(
    new Table([
      [''],
    ]).widths([ '*', 0 ]).end
  )

  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Columns([
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(1),
            new Txt(data.S10_NOMUSU).fontSize(10).alignment('center').end,
            new Txt(language.applicant).fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths([ '*', 0 ]).end,
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(2),
            new Txt(language.approval).fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths([ '*', 0 ]).end,
      new Table([
        [
          pdf.ln(5),
        ],
        [
          new Stack([
            pdf.ln(2),
            new Txt(language.passed).fontSize(10).bold().alignment('center').end
          ]).end
        ]
      ]).widths([ '*', 0 ]).end
    ]).end
  )

  pdf.create().open();
}
