import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token')? localStorage.getItem('token'): '';
    const tokenClient = localStorage.getItem('token-client')? localStorage.getItem('token-client'): '';

    const tokenAuth = req.urlWithParams.startsWith(environment.apiUrl) ? token: tokenClient;

    const headers = new HttpHeaders({
      // 'Content-Type':  'application/json',
      'Authorization': tokenAuth
    });

    const reqClone = req.clone({
      headers
    });

    return next.handle(reqClone);

  }
}
