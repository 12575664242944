<div style="height: 1px" *ngIf="isMobile || isTablet"></div>
<app-pavso-title-section [title]="'Producto'" [module]="'Ventas'" [ngClass]="'pav-form'"
  [estado]="producto.prd_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">


    <mat-tab-group>
      <mat-tab label="General" labelClass="mat-tab-label-0-0">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <mat-card class="mat-elevation-z0">
          <div class="row">

            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Código</mat-label>
                <input #firstInput matInput placeholder="Código" [disabled]="loaderData" name="codprd" [(ngModel)]="producto.prd_codprd"
                  required>
                <button *ngIf="!loaderBuscarProducto" type="button" mat-icon-button matSuffix
                  matTooltip="Buscar desde Consulta SUNAT" (click)="buscarProducto()">
                  <mat-icon>search</mat-icon>
                </button>
                <button *ngIf="loaderBuscarProducto" type="button" mat-icon-button matSuffix>
                  <mat-spinner diameter="20"></mat-spinner>
                </button>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l4">
              <button *ngIf="!loaderBuscarProductos" mat-mini-fab color="primary" matTooltip="Generar código"
                type="button" (click)="generarCodigo()" [disabled]="loaderData">
                <mat-icon>construction</mat-icon>
              </button>
              <button *ngIf="loaderBuscarProductos" color="primary" mat-mini-fab type="button" mat-icon-button
                matSuffix [disabled]="loaderData">
                <mat-spinner diameter="23"></mat-spinner>
              </button>
            </div>

            <div class="col s12">
              <mat-form-field class="example-full-width">
                <mat-label>Descripción</mat-label>
                <textarea matInput placeholder="Descripción" name="desesp" [(ngModel)]="producto.prd_desesp" [disabled]="loaderData"></textarea>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l9">
              <mat-form-field class="example-full-width">
                <mat-label>Desc. Abrev.</mat-label>
                <input matInput placeholder="Desc. Abrev." name="desabr" [(ngModel)]="producto.prd_desabr" [disabled]="loaderData">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <select-search [label]="'Unidad de Medida'" [placeholder]="'Ej. UNIDAD'" [name]="'ume_codume'"
                [value]="'ume_codume'" [description]="'ume_descri'" [data]="unidades" [disabled]="loaderData"
                (cambioSelect)="producto.ume_codume = $event" [model]="producto.ume_codume"></select-search>
            </div>

            <div class="col s12 m12 l4">
              <select-search [label]="'Tipo de Inventario'" [placeholder]="'Ej. Producto Terminado'"
                [name]="'tin_codtin'" [value]="'tin_codtin'" [description]="'tin_descri'" [data]="tiposDeInventario"
                (cambioSelect)="producto.tin_codtin = $event" [model]="producto.tin_codtin"></select-search>
            </div>

            <div class="col s12 m12 l4">
              <select-search [label]="'Línea de producto'" [placeholder]="'Ej. Producto Terminado'"
                [name]="'lpd_codlpd'" [value]="'lpd_codlpd'" [description]="'lpd_descri'" [data]="lineasProducto"
                (cambioSelect)="producto.lpd_codlpd = $event" [model]="producto.lpd_codlpd" [disabled]="loaderData"></select-search>
            </div>

            <div class="col s12 m12 l4">
              <select-search [label]="'Familia'" [placeholder]="'Ej. MAIZ'" [name]="'FPR_CODFPR'" [value]="'FPR_CODFPR'"
                [description]="'description'" [data]="familias" [disabled]="loaderData"
                (cambioSelect)="producto.fpr_codfpr = $event; seleccionarFamilia($event);"
                [model]="producto.fpr_codfpr"></select-search>
            </div>

            <div class="col s12 m12 l4">
              <select-search [label]="'Subfamilia'" [placeholder]="'Ej. ALVA'" [name]="'sfp_codsfp'"
                [value]="'sfp_codsfp'" [disabled]="loaderData" [description]="'description'" [data]="subfamilias"
                (cambioSelect)="producto.sfp_codsfp = $event;" [model]="producto.sfp_codsfp">
              </select-search>

            </div>

            <div class="col s12 m12 l4" *ngIf="configuracion.s70_indcol == 1">
              <select-search [label]="configuracion.s70_labcol ? configuracion.s70_labcol: 'Color'"
                [placeholder]="'Ej. BEIGE'" [disabled]="loaderData" [name]="'col_codcol'" [value]="'col_codcol'" [description]="'col_descri'"
                [data]="colores" (cambioSelect)="producto.col_codcol = $event"
                [model]="producto.col_codcol"></select-search>
            </div>

            <div class="col s12 m12 l4" *ngIf="configuracion.s70_indmod == 1">
              <select-search [label]="configuracion.s70_labmod ? configuracion.s70_labmod: 'Tecnología'"
                [placeholder]="'Ej. CAL10'" [disabled]="loaderData" [name]="'mod_codmod'" [value]="'mod_codmod'" [description]="'mod_descri'"
                [data]="modelos" (cambioSelect)="producto.mod_codmod = $event"
                [model]="producto.mod_codmod"></select-search>
            </div>
            <div class="col s12 m12 l2">
              <select-search [label]="'Marca'"
                [placeholder]="'Ej. CAL10'" [disabled]="loaderData" [name]="'MPR_CODMPR'" [value]="'MPR_CODMPR'" [description]="'MPR_DESCRI'"
                [data]="marcas" (cambioSelect)="producto.mpr_codmpr = $event"
                [model]="producto.mpr_codmpr"></select-search>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Número de parte / Part Number</mat-label>
                <input [disabled]="loaderData" matInput placeholder="Código Proveedor / Part Number" name="prd_parnum"
                  [(ngModel)]="producto.prd_parnum">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Código EAN</mat-label>
                <input [disabled]="loaderData" matInput placeholder="Código Proveedor / Part Number" name="prd_codean"
                  [(ngModel)]="producto.prd_codean">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Días de caducidad</mat-label>
                <input [disabled]="loaderData" type="number" min="0" matInput placeholder="Ej. 7" name="prd_diacad"
                  [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_diacad">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Código de sunat</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Ej. 0233484" name="prd_codsun"
                  [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_codsun" maxlength="8">
              </mat-form-field>
            </div>

            <div class="col s12">
              <mat-form-field class="example-full-width">
                <mat-label>Especificaciones</mat-label>
                <textarea [disabled]="loaderData" matInput placeholder="Especificaciones" name="prd_gloesp"
                  [(ngModel)]="producto.prd_gloesp"></textarea>
              </mat-form-field>
            </div>

            <div class="" [ngStyle]="{marginBottom:'10px'}">
              <div class="row">
                <div class="col s12 m12 l3">
                  <fieldset [ngStyle]="{borderRadius: '10px', borderColor: '#5ebcc4', border: '2px solid #6dd6df'}">
                    <legend [ngStyle]="{color: '#5ebcc4'}">Contenido</legend>

                    <div class="row">
                      <div class="col s12 m12 l4">
                        <mat-form-field>
                          <mat-label>Cantidad</mat-label>
                          <input [disabled]="loaderData" matInput placeholder="Contenido" name="prd_conten" [(ngModel)]="producto.prd_conten"
                            type="number">

                        </mat-form-field>
                      </div>

                      <div class="col s12 m12 l8">
                        <mat-form-field>
                          <mat-label>Contenido de presentacion</mat-label>
                          <mat-select [disabled]="loaderData" name="ume_codctn" [(ngModel)]="producto.ume_codctn">
                            <mat-option  *ngFor="let unidad of unidades" [value]="unidad.ume_codume">
                              {{unidad.ume_descri}}
                            </mat-option>

                          </mat-select>

                        </mat-form-field>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="col s12 m12 l3">
                  <fieldset [ngStyle]="{borderRadius: '10px', borderColor: '#5ebcc4', border: '2px solid #6dd6df'}">
                    <legend [ngStyle]="{color: '#5ebcc4'}">Pesos KGS</legend>


                    <div class="row">
                      <div class="col s12 m12 l6">
                        <mat-form-field class="example-full-width">
                          <mat-label>Peso Bruto</mat-label>
                          <input [disabled]="loaderData" type="number" matInput placeholder="Peso Bruto" name="prd_pesbru" min="0"
                            [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_pesbru" step="any">
                        </mat-form-field>
                      </div>

                      <div class="col s12 m12 l6">
                        <mat-form-field class="example-full-width">
                          <mat-label>Peso Neto</mat-label>
                          <input [disabled]="loaderData" type="number" matInput placeholder="Peso Neto" name="prd_pesnet" min="0"
                            [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_pesnet" step="any">
                        </mat-form-field>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div class="col s12 m12 l3">
                  <fieldset [ngStyle]="{borderRadius: '10px', borderColor: '#5ebcc4', border: '2px solid #6dd6df'}">
                    <legend [ngStyle]="{color: '#5ebcc4'}">Dimensiones</legend>

                    <div class="row">
                      <div class="col s12 m12 l4">
                        <mat-form-field class="example-full-width">
                          <mat-label>Largo</mat-label>
                          <input [disabled]="loaderData" type="number" matInput placeholder="Largo" name="prd_larprd" min="0"
                            [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_larprd" step="any">
                        </mat-form-field>
                      </div>

                      <div class="col s12 m12 l4">
                        <mat-form-field class="example-full-width">
                          <mat-label>Ancho</mat-label>
                          <input [disabled]="loaderData" type="number" matInput placeholder="Ancho" name="prd_ancprd" min="0"
                            [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_ancprd" step="any">
                        </mat-form-field>
                      </div>

                      <div class="col s12 m12 l4">
                        <mat-form-field class="example-full-width">
                          <mat-label>Alto</mat-label>
                          <input [disabled]="loaderData" type="number" matInput placeholder="Alto" name="prd_altprd" min="0"
                            [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_altprd" step="any">
                        </mat-form-field>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="col s12 m12 l3">
                  <fieldset [ngStyle]="{borderRadius: '10px', borderColor: '#5ebcc4', border: '2px solid #6dd6df'}">
                    <legend [ngStyle]="{color: '#5ebcc4'}">Control de stock</legend>
                    <div class="row">
                      <div class="col s12 m12 l6">
                        <mat-form-field class="example-full-width">
                          <mat-label>Stk Mínimo</mat-label>
                          <input type="number" matInput placeholder="Stk Mínico" name="prd_stomin" min="0"
                            [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_stomin" step="any">
                        </mat-form-field>
                      </div>

                      <div class="col s12 m12 l6">
                        <mat-form-field class="example-full-width">
                          <mat-label>Stk Máximo</mat-label>
                          <input type="number" matInput placeholder="Stk Máximo" name="prd_stomax" min="0"
                            [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_stomax" step="any">
                        </mat-form-field>
                      </div>

                    </div>
                  </fieldset>
                </div>
              </div>

            </div>

            <div class="col s12">
              <fieldset [ngStyle]="{borderRadius: '10px', borderColor: '#5ebcc4', border: '2px solid #6dd6df'}">
                <legend [ngStyle]="{color: '#5ebcc4'}">Tipo de control</legend>

                <div>
                  <mat-checkbox *ngIf="configuracion.s70_indcom" [(ngModel)]="producto.prd_indcom" name="prd_indcom" class="example-margin"
                    [ngStyle]="{color: '#0000000 !important'}">Producto
                    compuesto</mat-checkbox>
                    &nbsp;
                  <mat-checkbox *ngIf="configuracion.s70_indcon" class="example-margin"
                    [(ngModel)]="producto.prd_indcon" name="prd_indcon">Producto
                    controlado</mat-checkbox>
                    &nbsp;
                  <mat-checkbox *ngIf="configuracion.s70_indser == 1" class="example-margin"
                    [(ngModel)]="producto.prd_indser" name="prd_indser">Control por
                    serie</mat-checkbox>
                    &nbsp;
                  <mat-checkbox *ngIf="configuracion.s70_indlot == 1" class="example-margin"
                    [(ngModel)]="producto.prd_indlot" name="prd_indlot">Control por
                    lote</mat-checkbox>
                    &nbsp;
                  <mat-checkbox *ngIf="configuracion.s70_venlot == 1" class="example-margin"
                    [(ngModel)]="producto.prd_indfab" name="prd_indfab">Fecha de
                    ingreso o fab.</mat-checkbox>
                    &nbsp;
                  <mat-checkbox *ngIf="configuracion.s70_venlot == 1" class="example-margin"
                    [(ngModel)]="producto.prd_indven" name="prd_indven">Fecha de
                    vencimiento</mat-checkbox>
                    &nbsp;
                  <mat-checkbox *ngIf="configuracion.s70_indtal == 1" class="example-margin"
                    [(ngModel)]="producto.prd_indtal" name="prd_indtal">Control por
                    tallas</mat-checkbox>
                    &nbsp;
                  <mat-checkbox *ngIf="configuracion.s70_indgen == 1" class="example-margin"
                    [(ngModel)]="producto.prd_indgen" name="prd_indgen">Producto
                    genérico</mat-checkbox>
                </div>


              </fieldset>

            </div>
          </div>
        </mat-card>
        <pav-upload-images title="Imágenes de productos" [loaderData]="loaderData" [maxSizeImage]="20000000000000000"
          (selectionImages)="imagenesSeleccioadas($event)">
        </pav-upload-images>

        <br>
        <div [ngStyle]="{display: 'flex', justifyContent: 'between', marginBottom: '10px'}">
          <ng-container *ngFor="let img of images">
            <div [ngStyle]="{display: 'flex', marginRight: '10px'}">
              <div [ngStyle]="{marginRight: '5px'}">
                <img [src]="img.pri_fotprd" width="40" height="40" alt="Imagen" [ngStyle]="{borderRadius: '5px'}">
              </div>
              <div [ngStyle]="{cursor: 'pointer'}" (click)="eliminarImagen(img)">
                <mat-icon [ngStyle]="{color: 'gray'}">delete</mat-icon>
              </div>

            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="images.length > 0">

          <pav-show-product-image [idProducto]="idProducto"></pav-show-product-image>
        </ng-container>

      </mat-tab>
      <mat-tab label="Datos de Compra" labelClass="mat-tab-label-0-1">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Lote económico</mat-label>
                <input min="0" type="number" matInput placeholder="(En unidades) Ej. 5" name="prd_loteco"
                  [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_loteco">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Compra mínima</mat-label>
                <input min="0" type="number" matInput placeholder="Ej. 5" name="prd_commin"
                  [ngStyle]="{textAlign: 'right'}" [(ngModel)]="producto.prd_commin">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'Proveedor sugerido'" [placeholder]="'Ej. SAC'" [value]="'cli_codcli'"
                [name]="'cli_codcli'" [description]="'cli_nomcli'" [model]="producto.cli_codcli" (cambioSelect)="producto.cli_codcli = $event" [disabled]="loaderData" [data]="proveedores">
              </select-search>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Ubicaciones de Almacén" labelClass="mat-tab-label-0-2">
        <mat-card class="mat-elevation-z0">
          <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="alm_codalm">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
                  {{row.alm_codalm}}
                </td>
              </ng-container>

              <ng-container matColumnDef="des_almacen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Almacén </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">

                </td>
              </ng-container>

              <ng-container matColumnDef="uba_coduba">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubic. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
                  {{row.uba_coduba}}
                </td>
              </ng-container>

              <ng-container matColumnDef="des_ubicacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Ubic.</th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="14">No se encontraron registros</td>
              </tr>

            </table>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Stock mínimo" labelClass="mat-tab-label-0-3">
        <mat-card class="mat-elevation-z0">
          <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSourceStockMinimo" matSort>
              <ng-container matColumnDef="alm_codalm">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
                  {{row.alm_codalm}}
                </td>
              </ng-container>

              <ng-container matColumnDef="des_almacen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. Almacén </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">

                </td>
              </ng-container>

              <ng-container matColumnDef="stock_minimo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock mínimo </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">

                </td>
              </ng-container>

              <ng-container matColumnDef="stock_maximo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock máximo </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
                  {{row.uba_coduba}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsStockMinimo"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsStockMinimo;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="14">No se encontraron registros</td>
              </tr>

            </table>
          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group>


    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      [urlBack]="'/modulo-almacen/productos'" (clickNuevo)="nuevoRegistro()">
      <div id="botonesAdicionales">
      </div>
    </pav-form-actions>

  </form>
</div>
