<app-pavso-title-section [title]="'apps.crm.maestros.linea-negocio.title' | translate" [module]="'CRM'" [estado]="linea.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>{{'apps.crm.maestros.linea-negocio.form.code' | translate}}</mat-label>
                    <input type="text" [(ngModel)]="linea.codoln" matInput [placeholder]="'apps.crm.maestros.linea-negocio.form.code' | translate" name="codcoc" required>
                </mat-form-field>
            </div>

            <div class="col s12 m12 l6">
                <mat-form-field>
                    <mat-label>{{'apps.crm.maestros.linea-negocio.form.description' | translate}}</mat-label>
                    <input type="text" [(ngModel)]="linea.descri" matInput [placeholder]="'apps.crm.maestros.linea-negocio.form.description' | translate" name="descri" required>
                </mat-form-field>
            </div>
        </div>

    </mat-card>
    <log-updated *ngIf="uid!='0'" [coduse]="linea.coduse" [fecupd]="linea.fecupd"></log-updated>

  </div>

  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-crm/lineas-de-negocio">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
