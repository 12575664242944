<app-pavso-title-section [title]="'Consulta de movimientos contables'"
  [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Comprobantes</mat-label>
          <mat-select [(ngModel)]="opcionComprobantes" name="opcionComprobantes"
            (selectionChange)="seleccionarComprobante($event.value)" [disabled]="loaderData">
            <mat-option value="estandar">
              ESTANDAR
            </mat-option>
            <mat-option value="bancos">
              BANCOS
            </mat-option>
            <mat-option value="compras">
              COMPRAS
            </mat-option>
            <mat-option value="ventas">
              VENTAS
            </mat-option>
            <mat-option value="honorarios">
              HONORARIOS
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Mes</mat-label>
          <mat-select (selectionChange)="seleccionarMes($event.value)" [(ngModel)]="filtro.mes" name="mes"
            [disabled]="loaderData">
            <mat-option value="">Ninguno</mat-option>
            <mat-option *ngFor="let mes of meses" [value]="mes.id">
              {{mes.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <select-search [label]="'Auxiliar'" [placeholder]="'Ej. '" [name]="'cli_codcli'" [description]="'description'"
          [data]="auxiliares" [value]="'cli_codcli'" [required]="true"
          (cambioSelect)="filtro.auxiliar = $event; seleccionarAuxiliar($event)" [model]="filtro.auxiliar">
        </select-search>

      </div>
      <div class="col s12 m12 l2">

        <select-search [label]="'Tipo Doc.'" [placeholder]="'Ej. '" [name]="'TDO_CODTDO'" [description]="'description'"
          [data]="tiposDocumento" [value]="'TDO_CODTDO'" [required]="true"
          (cambioSelect)="filtro.tipoDocumento = $event;seleccionarTipoDocumento($event)"
          [model]="filtro.tipoDocumento">
        </select-search>

        <!-- <mat-form-field>
          <mat-label>Tipo Doc.</mat-label>
          <mat-select [(ngModel)]="tipoDoc" name="tipoDoc" (selectionChange)="seleccionarTipoDocumento($event.value)"
            [disabled]="loaderData">
            <mat-option value="">Ninguno</mat-option>
            <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.TDO_CODTDO">
              {{tipo.TDO_DESCOR}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Nro. Documento</mat-label>
          <input matInput placeholder="Nro. Documento" [disabled]="loaderData" [(ngModel)]="filtro.nroDocumento"
            name="nroDocumento">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3" *ngIf="opcionComprobantes == 'compras' || opcionComprobantes == 'ventas' || opcionComprobantes == 'honorarios'">

        <pav-input-search label="Cuenta" placeholder="ej. 42" [isResponsive]="true"
          (valueChange)="cuenta = $event; seleccionarCuenta($event.codigo); " [codigo]="filtro.cuenta"
          [descripcion]="cuenta.ccn_descri" width="'100%'" [program]="'CUENTAS'"></pav-input-search>


        <!-- <select-search [label]="'Cuenta'" [placeholder]="'Ej. '" [name]="'ccn_codccn'" [description]="'ccn_descri'"
          [data]="cuentasContable" [value]="'ccn_codccn'" [required]="true"
          (cambioSelect)="filtro.cuenta = $event;seleccionarCuenta($event)" [model]="filtro.cuenta">
        </select-search> -->

        <!-- <mat-form-field>
          <mat-label>Cuenta</mat-label>
          <mat-select (selectionChange)="seleccionarCuenta($event.value)" [(ngModel)]="filtro.cuenta" name="cuenta">
            <mat-option *ngFor="let cuentaContable of cuentasContable" [value]="cuentaContable.ccn_codccn">
              {{cuentaContable.ccn_descri}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <div class="col s12 m12 l3" *ngIf="opcionComprobantes == 'estandar'">
        <!-- <mat-form-field>
          <mat-label>Subdiario</mat-label>
          <mat-select [(ngModel)]="subdiario" name="subdiario" (selectionChange)="seleccionarSubdiario($event.value)"
            [disabled]="loaderData">
            <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
              {{subdiario.sco_codsco}} - {{subdiario.sco_descri}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <select-search [label]="'Subdiario'" [placeholder]="'Ej. '"
          [name]="'sco_codsco'" [description]="'description'" [data]="subdiarios" [value]="'sco_codsco'"
          [required]="true" (cambioSelect)="filtro.subdiario = $event;seleccionarSubdiario($event)"
          [model]="filtro.subdiario">
        </select-search>
      </div>
      <div class="col s12 m12 l3" *ngIf="opcionComprobantes == 'bancos'">
        <select-search [label]="'Bancos'" [placeholder]="'Ej. '"
          [name]="'codigo_banco'" [value]="'codigo_banco'" [description]="'descri_banco'" [data]="bancos"
          [required]="true" (cambioSelect)="filtro.banco = $event; seleccionarBanco($event)" [model]="filtro.banco">
        </select-search>

        <!-- <mat-form-field>
          <mat-label>Bancos</mat-label>
          <mat-select (selectionChange)="seleccionarBanco($event.value)">
            <mat-option *ngFor="let banco of bancos" [value]="banco.codigo_banco">
              {{banco.codigo_banco}} - {{banco.descri_banco}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <div class="col s12 m12 l3" *ngIf="opcionComprobantes == 'bancos'">
        <select-search [label]="'Cuentas Bancarias'" [placeholder]="'Ej. '"
          [name]="'codigo_ctabanco'" [value]="'codigo_ctabanco'" [description]="'descri_ctabanco'" [data]="cuentasBancaria"
          [required]="true" (cambioSelect)="filtro.cuentaBancaria = $event; seleccionarBanco($event)" [model]="filtro.cuentaBancaria">
        </select-search>
        <!-- <mat-form-field>
          <mat-label>Cuentas Bancarias</mat-label>
          <mat-select [(ngModel)]="cuentaBancaria" name="cuentaBancaria"
            (selectionChange)="seleccionarCuentaBancaria($event.value)">
            <mat-option value="">Ninguno</mat-option>
            <mat-option *ngFor="let cuenta of cuentasBancaria" [value]="cuenta.codigo_ctabanco">
              {{cuenta.descri_ctabanco}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    </div>
    <div align="center">
      <button type="button" (click)="filtrar()" mat-mini-fab color="primary" matTooltip="Buscar"
        aria-label="Example icon button with a menu icon">
        <mat-icon>search</mat-icon>
      </button>
      &nbsp;
      <button type="button" (click)="movimientosCtaCte()" mat-mini-fab color="accent" matTooltip="Movimientos Cta. Cte."
        aria-label="Example icon button with a menu icon">
        <mat-icon>list_alt</mat-icon>
      </button>
      &nbsp;
      <button type="button" (click)="consulta()" mat-mini-fab color="accent" matTooltip="Consulta"
        aria-label="Example icon button with a menu icon">
        <mat-icon>assignment</mat-icon>
      </button>
    </div>
  </mat-card>
  <br>

  <mat-tab-group [selectedIndex]="selectedIndex">
    <mat-tab label="Standar" [disabled]="opcionComprobantes != 'estandar'" labelClass="mat-tab-label-0-0">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo">
              {{row.ano}}-{{row.mes}}-{{row.cod_subdiario}}-{{row.corr_asiento}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Comprobante"> {{row.nro_docu}} </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
            <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.cuenta}} | {{row.descri_cuenta}}</td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
            <td mat-cell *matCellDef="let row" data-label="Auxiliar"> {{row.auxiliar}} | {{row.nombre_auxiliar}} </td>
          </ng-container>

          <ng-container matColumnDef="ccs_codccs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro Costo </th>
            <td mat-cell *matCellDef="let row" data-label="Centro Costo"> {{row.centro_costo}} | {{row.descri_ccosto}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Registro"> {{row.cco_fecreg_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de emisión"> {{row.fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_gloldi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
            <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.glosa}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'highlight': selectedRowIndex == row.ano_codano + row.ano_codano + row.sco_codsco + row.ldi_corldi + row.ldi_numdoc}"
            (click)="selectedRowIndex = row.ldi_messco + row.ldi_numdoc"></tr>

          <tr class="mat-row" *matNoDataRow>

            <td class="mat-cell" colspan="8" *ngIf="!loaderStandar">No se encontraron registros</td>

          </tr>

        </table>
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[25, 100]"></mat-paginator>
      </div>

    </mat-tab>
    <mat-tab label="Bancos" [disabled]="opcionComprobantes != 'bancos'" labelClass="mat-tab-label-0-1">

      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSourceBanco" matSort>

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo">
              {{row.ano}}-{{row.mes}}-{{row.cod_subdiario}}-{{row.corr_asiento}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cheque </th>
            <td mat-cell *matCellDef="let row" data-label="Cheque"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
            <td mat-cell *matCellDef="let row" data-label="Auxiliar"> {{row.auxiliar}} | {{row.nombre_auxiliar}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.moneda}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
            <td mat-cell *matCellDef="let row" data-label="Monto">
              {{row.moneda == 'SO' ? (row.ind_debe_haber == 'D'? row.ldi_impnac_debe: row.ldi_impnac_habe) : (row.ind_debe_haber == 'D'? row.ldi_impmex_debe: row.ldi_impmex_habe)}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsBanco"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsBanco;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6" *ngIf="!loaderBancos">No se encontraron registros</td>

          </tr>

        </table>

        <mat-paginator #pBanco [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Compras" [disabled]="opcionComprobantes != 'compras'" labelClass="mat-tab-label-0-2">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSourceCompras" matSort>

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo">
              {{row.ano}}-{{row.mes}}-{{row.cod_subdiario}}-{{row.corr_asiento}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Comprobante"> {{row.nro_docu}} </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
            <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.cuenta}} | {{row.descri_cuenta}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha Registro"> {{row.cco_fecreg_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha Emisión"> {{row.fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
            <td mat-cell *matCellDef="let row" data-label="Proveedor"> {{row.auxiliar}} | {{row.nombre_auxiliar}} </td>
          </ng-container>

          <ng-container matColumnDef="ccs_codccs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro de costo </th>
            <td mat-cell *matCellDef="let row" data-label="Centro de costo"> {{row.centro_costo}} | {{row.descri_ccosto}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.moneda}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda">
              {{row.moneda == 'SO' ? (row.ind_debe_haber == 'D'? row.ldi_impnac_debe: row.ldi_impnac_habe) : (row.ind_debe_haber == 'D'? row.ldi_impmex_debe: row.ldi_impmex_habe)}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsCompras"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsCompras;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" *ngIf="!loaderCompras">No se encontraron registros</td>

          </tr>

        </table>

        <mat-paginator #pCompras [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Ventas" [disabled]="opcionComprobantes != 'ventas'" labelClass="mat-tab-label-0-3">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSourceVentas" matSort>

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo">
              {{row.ano}}-{{row.mes}}-{{row.cod_subdiario}}-{{row.corr_asiento}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Comprobante"> {{row.nro_docu}} </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
            <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.cuenta}} | {{row.descri_cuenta}} </td>
          </ng-container>

          <ng-container matColumnDef="ccs_codccs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro Costo </th>
            <td mat-cell *matCellDef="let row" data-label="Centro Costo"> {{row.centro_costo}} | {{row.descri_ccosto}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Registro"> {{row.cco_fecreg_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Emisión"> {{row.fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.auxiliar}} | {{row.nombre_auxiliar}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
            <td mat-cell *matCellDef="let row" data-label="Documento"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.moneda}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
            <td mat-cell *matCellDef="let row" data-label="Monto">
              {{row.moneda == 'SO' ? (row.ind_debe_haber == 'D'? row.ldi_impnac_debe: row.ldi_impnac_habe) : (row.ind_debe_haber == 'D'? row.ldi_impmex_debe: row.ldi_impmex_habe)}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsVentas"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsVentas;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" *ngIf="!loaderVentas">No se encontraron registros</td>

          </tr>

        </table>

        <mat-paginator #pVentas [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Honorarios" [disabled]="opcionComprobantes != 'honorarios'" labelClass="mat-tab-label-0-4">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSourceHonorario" matSort>

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo">
              {{row.ano}}-{{row.mes}}-{{row.cod_subdiario}}-{{row.corr_asiento}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Comprobante"> {{row.nro_docu}} </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
            <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.cuenta}} | {{row.descri_cuenta}} </td>
          </ng-container>

          <ng-container matColumnDef="ccs_codccs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro Costo </th>
            <td mat-cell *matCellDef="let row" data-label="Centro Costo"> {{row.centro_costo}} | {{row.descri_ccosto}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Registro"> {{row.cco_fecreg_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de emisión"> {{row.fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Honorario </th>
            <td mat-cell *matCellDef="let row" data-label="Honorario"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
            <td mat-cell *matCellDef="let row" data-label="Documento"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.moneda}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
            <td mat-cell *matCellDef="let row" data-label="Monto">
              {{row.moneda == 'SO' ? (row.ind_debe_haber == 'D'? row.ldi_impnac_debe: row.ldi_impnac_habe) : (row.ind_debe_haber == 'D'? row.ldi_impmex_debe: row.ldi_impmex_habe)}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsHonorario"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsHonorario;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" *ngIf="!loaderHonorarios">No se encontraron registros</td>

          </tr>

        </table>

        <mat-paginator #pHonorarios [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>

  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</div>
