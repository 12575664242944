import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { fillTable } from "@utils/tables/table";

@Component({
  selector: 'kardex-upload-documents-dialog',
  templateUrl: './kardex-upload-documents.dialog.html',
  styleUrls: ['./kardex-upload-documents.dialog.scss']
})
export class KardexUploadDocumentsDialog {

  displayedColumnsImagenes: string[] = ['name', 'size', 'type', 'lastModifiedDate', 'descripcion'];
  dataSourceImagenes: MatTableDataSource<File>;

  @ViewChild(MatPaginator) paginatorImagenes: MatPaginator;
  @ViewChild(MatSort) sortImagenes: MatSort;

  constructor(
    public dialogRef: MatDialogRef<KardexUploadDocumentsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dataSourceImagenes = fillTable([], this.paginatorImagenes, this.sortImagenes);
  }

  agregar(): void {}

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  guardar(): void {}

}
