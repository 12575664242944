import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreferenciasImpresionDialog } from "@shared/components/dialogs/preferencias-impresion/preferencias-impresion.dialog";
import { DialogService } from "@shared/services/dialog.service";
import { ConfigurationService } from "src/app/services";

@Component({
  selector: 'side-configuration',
  templateUrl: 'side-configuration.component.html',
  styleUrls: ['side-configuration.component.scss']
})
export class SideConfigurationComponent implements OnInit, OnDestroy {

  isDarkMode: boolean = false;
  @Output() cambiarTema = new EventEmitter<boolean>();

  constructor(
    private _configurationService: ConfigurationService,
    public dialog: MatDialog,
    private _dialogService: DialogService
  ) {

    this.isDarkMode = this._configurationService.obtenerTema() === 'true';

  }

  ngOnInit(): void { }

  cambiar(): void {
    document.body.classList.toggle('dark-theme');

    this.isDarkMode = !this.isDarkMode;
    this._configurationService.establecerTema(this.isDarkMode);
    this.cambiarTema.emit(this.isDarkMode);
  }

  abrirPanelAtajos(): void {
    this._dialogService.openDialogShortcut();
  }

  abrirPreferenciasImpresion(): void {
    const dialogRef = this.dialog.open(PreferenciasImpresionDialog, {
      width: '350px',
      data: {  },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  ngOnDestroy(): void { }
}
