<app-pavso-title-section [title]="'Pais'" [module]="'Ventas'"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarPais(f)" (keydown.enter)="$event.preventDefault()">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Código</mat-label>
          <input matInput placeholder="Código" [(ngModel)]="pais.PAI_CODPAI" name="PAI_CODPAI">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" [(ngModel)]="pais.PAI_NOMLAR" name="PAI_NOMLAR">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre Abreviado</mat-label>
          <input matInput placeholder="Nombre Abreviado" [(ngModel)]="pais.PAI_NOMCOR" name="PAI_NOMCOR">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Cod. ISO</mat-label>
          <input matInput placeholder="Cod. ISO" [(ngModel)]="pais.PAI_CODISO" name="PAI_CODISO">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Cod. SUNAT</mat-label>
          <input matInput placeholder="Cod. SUNAT" [(ngModel)]="pais.T35_CODT35" name="T35_CODT35">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select [(ngModel)]="pais.PAI_INDSTA" name="PAI_INDSTA">
            <mat-option value="1">
              ACTIVO
            </mat-option>
            <mat-option value="2">
              INACTIVO
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card>

  <br>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-ventas/paises">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
