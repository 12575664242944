import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'enlaza-factura-pedido',
  templateUrl: './enlaza-factura-pedido.component.html',
  styleUrls: ['./enlaza-factura-pedido.component.scss']
})
export class EnlazaFacturaPedidoComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
