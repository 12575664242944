import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Establishment } from 'src/app/models';
import { GeneralService, VentasService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-establishment-add',
  templateUrl: './establishment-add.component.html',
  styleUrls: ['./establishment-add.component.css']
})
export class EstablishmentAddComponent implements OnInit, OnDestroy {

  establishment: Establishment;

  /** filtrar paises */
  paises: any[] = [];
  ubicaciones: any[] = [];
  zonas: any[] = [];

  pais$: Subscription;
  ubicacion$: Subscription;
  zona$: Subscription;

  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    private _generalService: GeneralService,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EstablishmentAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.establishment = new Establishment();
    this.establishment.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

  }

  ngOnInit(): void {

    this.loadData();

  }

  loadData(): void {

    forkObs(
      this._generalService.listarPaises(),
      this._generalService.listarUbicacion(null),
      this._ventasService.listarZonaVenta()
    ).then(([paises, ubicaciones, zonas]) => {

      this.paises = paises;
      this.ubicaciones = ubicaciones;
      this.zonas = zonas;
    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", 'OK')
    })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  agregarEstablecimiento(f: NgForm) {
    (this.establishment.LDE_INDPRI)? this.establishment.LDE_INDPRI = 1: this.establishment.LDE_INDPRI = 0;

    this.dialogRef.close(this.establishment);
  }

  ngOnDestroy(): void {
  }

}
