<app-pavso-title-section [title]="'Producto'" [module]="'Almacén'" [usuario]="producto.prd_coduse" [fecha]="producto.prd_fecupd" [ngClass]="'pav-form'" [estado]="producto.prd_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarProducto(f)" (keydown.enter)="$event.preventDefault()">


  <div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Código</mat-label>
            <input matInput placeholder="Código" name="codprd" [(ngModel)]="producto.prd_codprd" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <button mat-flat-button color="primary" type="button"><mat-icon>integration_instructions</mat-icon></button>
        </div>

        <div class="col s12">
          <mat-form-field class="example-full-width">
            <mat-label>Descripción</mat-label>
            <textarea matInput placeholder="Descripción" name="desesp" [(ngModel)]="producto.prd_desesp"></textarea>
          </mat-form-field>
        </div>

        <div class="col s12">
          <mat-form-field class="example-full-width">
            <mat-label>Desc. Abrev.</mat-label>
            <input matInput placeholder="Desc. Abrev." name="desabr" [(ngModel)]="producto.prd_desabr">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <select-search [label]="'Tipo de Inventario'" [placeholder]="'Ej. tipo'" [name]="'tin_codtin'"
            [description]="'tin_descri'" [data]="tiposDeInventario" (cambioSelect)="producto.tin_codtin = $event"
            [value]="producto.tin_codtin">
          </select-search>
        </div>

        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Código Proveedor / Part Number</mat-label>
            <input matInput placeholder="Código Proveedor / Part Number" name="prd_parnum"
              [(ngModel)]="producto.prd_parnum">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <select-search [label]="'Familia'" [placeholder]="'Ej. Torres'" [name]="'FPR_CODFPR'"
            [description]="'fpr_descri'" [data]="familias" (cambioSelect)="producto.fpr_codfpr = $event"
            [value]="producto.fpr_codfpr">
          </select-search>
        </div>

        <div class="col s12 m12 l4">
          <select-search [label]="'Subfamilia'" [placeholder]="'Ej. Torres'" [name]="'sfp_codsfp'"
            [description]="'sfp_descri'" [data]="subfamilias" (cambioSelect)="producto.sfp_codsfp = $event"
            [value]="producto.sfp_codsfp">
          </select-search>
        </div>

        <div class="col s12 m12 l4">
          <select-search [label]="'Color'" [placeholder]="'Ej. Rojo'" [name]="'col_codcol'" [description]="'col_descri'"
            [data]="colores" (cambioSelect)="producto.col_codcol = $event" [value]="producto.col_codcol">
          </select-search>
        </div>

        <div class="col s12 m12 l4">
          <select-search [label]="'Unidad de medida'" [placeholder]="'Ej. UND'" [name]="'ume_codume'"
            [description]="'ume_descri'" [data]="unidades" (cambioSelect)="producto.ume_codume = $event"
            [value]="producto.ume_codume">
          </select-search>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Peso Bruto</mat-label>
            <input type="number" matInput placeholder="Peso Bruto" name="prd_pesbru" min="0"
              [(ngModel)]="producto.prd_pesbru">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Peso Neto</mat-label>
            <input type="number" matInput placeholder="Peso Neto" name="prd_pesnet" min="0"
              [(ngModel)]="producto.prd_pesnet">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Stk Mínico</mat-label>
            <input type="number" matInput placeholder="Stk Mínico" name="prd_stomin" min="0"
              [(ngModel)]="producto.prd_stomin">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field class="example-full-width">
            <mat-label>Stk Máximo</mat-label>
            <input type="number" matInput placeholder="Stk Máximo" name="prd_stomax" min="0"
              [(ngModel)]="producto.prd_stomax">
          </mat-form-field>
        </div>

        <div class="col s12">
          <mat-form-field class="example-full-width">
            <mat-label>Especificaciones</mat-label>
            <textarea matInput placeholder="Especificaciones" name="prd_gloesp"
              [(ngModel)]="producto.prd_gloesp"></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
  </div>

  <pav-upload-images title="Hola"></pav-upload-images>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-almacen/productos">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
