import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ApiCondicionPagoVentasService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerCondicionesPago(): Observable<any> {

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/condiciones-de-pago`
    );

  }

  obtenerCondicionPago(codcpa): Observable<any> {

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/condiciones-de-pago/${codcpa}`
    );

  }
}
