import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';

@Injectable({
    providedIn: 'root'
})
export class OperacionesService {

    constructor(
        private http: HttpClient,
        private _configurationService: ConfigurationService
    ) {}

    /**
     *
     * @returns
     */
    obtenerClientes(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasreqmateriales/listacliente/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerAlmacenes(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasreqmateriales/listalmaalm/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerProductos(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasreqmateriales/listaproducto/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @returns
     */
    obtenerUnidadesMedida(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasreqmateriales/listaunidmedida`);

    }

    /**
     *
     * @returns
     */
    obtenerMatCab(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasreqmateriales/listareqmatcab/${this._configurationService.obtenerCompaniaCliente()}`);

    }

    /**
     *
     * @param id
     * @returns
     */
    obtenerMatMov(id): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasreqmateriales/listareqmatmov//${this._configurationService.obtenerCompaniaCliente()}/${id}`);

    }

    /**
     *
     * @returns
     */
    obtenerRequerimientoMateriales(): Observable<any> {

        return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/tablasproduccion/listarreqmateriales/${this._configurationService.obtenerCompaniaCliente()}`);

    }
}
