import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appTipoCambioFormat]'
})
export class TipoCambioDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement;
    const value = input.value;

    // Validar si hay un decimal y limitar a 4 dígitos después del punto
    if (value.includes('.')) {
      const [integer, decimal] = value.split('.');
      if (decimal?.length > 4) {
        // Limitar los decimales a 4 dígitos
        input.value = `${integer}.${decimal.substring(0, 4)}`;
        input.value = parseFloat(input.value);
      }
    }
  }
}
