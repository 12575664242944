<div class="row pav-panel">
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'General'" [amount]="0" [bgColorTitle]="'pav-yellow'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'General'" [amount]="0" [bgColorTitle]="'pav-green'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l4">
    <card-dashboard-resume [title]="'General'" [amount]="0" [bgColorTitle]="'pav-blue'"></card-dashboard-resume>
  </div>
</div>
