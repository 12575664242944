import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthenticationService, NominaService } from 'src/app/services';
import { Store } from '@ngrx/store';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { MatDialog } from '@angular/material/dialog';
import { CargarExcelNominaDialog } from '@shared/components/dialogs/planilla/cargar-excel-nomina/cargar-excel-nomina.component';
import { CargarColaboradoresNominaDialog } from '@shared/components/dialogs/planilla/cargar-colaboradores-nomina/cargar-colaboradores-nomina.component';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { MovimientoRemuneracionColaborador } from 'src/app/models/planilla/operaciones/movimiento-remuneracion-colaborador';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { MatSelectChange } from '@angular/material/select';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-movimiento-nomina',
  templateUrl: './movimiento-nomina.component.html',
  styleUrls: ['./movimiento-nomina.component.css']
})
export class MovimientoNominaComponent implements OnInit, OnDestroy {

  nomina: string;
  npe_codnpe: string;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['actions', 'cli_nomcli', 'nmt_imprem', 'nmt_cannut', 'nut_codnut', 'nmt_canhrs', 'nmt_canmin', 'indmod'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  fecha: Date = new Date();

  usuario: any;

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  conceptos: any[] = [];
  unidades: any[] = [];

  total: number = 0;

  mostrarCalculaGratificacion: boolean = false;
  mostrarCargarUtilidades: boolean = false;
  mostrarCalculaPlanilla: boolean = false;
  mostrarCalculaQuincenal: boolean = false;

  configuracion$: Subscription;
  movimiento$: Subscription;
  reporte$: Subscription;

  period$: Subscription;
  nomina$: Subscription;
  loading$: Subscription;
  unidad$: Subscription;
  listarColab$: Subscription;

  movimientos: Array<MovimientoRemuneracionColaborador> = [];

  concepto: string;
  unidad: string;

  colaboradores: any[] = [];

  estanHabilitados: boolean = false;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>,
    public dialog: MatDialog
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.nomina$ = this.store.select('nomina').subscribe(state => {
      this.mostrarCalculaPlanilla = false;
      this.mostrarCalculaQuincenal = false;

      this.nomina = `${this.year}-${this.month}-${state.typeNomina}-${state.secNomina}`;
      this.npe_codnpe = `${this.year}${this.month}${state.typeNomina}${state.secNomina}`;

      if(state.secNomina && state.secNomina != "99") this.mostrarCalculaPlanilla = true;

      if(state.secNomina == "01") this.mostrarCalculaQuincenal = true;

    })

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  /**
   * Obtiene los maestros de :
   * . configuraciones de remuneración
   * . colaboradores
   * . unidades
   */
  loadData(): void {

    forkObs(
      this._nominaService.listarConfiguracionRemuneracion(this.year, this.month),
      this._nominaService.listarColaboradores(),
      this._nominaService.listarUnidades()
    ).then(([conceptos, colaboradores, unidades]) => {
      this.conceptos = conceptos.filter(item => item.indtip == "D");
      this.colaboradores = colaboradores;
      this.unidades = unidades;

    }).catch(error => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })

  }

  trackByConcepto(index, concepto): string {
    return concepto? concepto.codnco: undefined;
  }

  trackByColaborador(index, colaborador): string {
    return colaborador? colaborador.codcli: undefined;
  }

  trackByUnidad(index, unidad): string {
    return unidad? unidad.nut_codnut: undefined;
  }

  seleccionarConcepto(event): void {
    const concepto = this.conceptos.find(item => item.codnco == event);
    this.unidad = concepto.codnut;

    this.mostrarCalculaGratificacion = false;
    this.mostrarCargarUtilidades = false;

    switch (event) {
      case "108":
        this.mostrarCalculaGratificacion = true;
        break;
      case "200":
        this.mostrarCargarUtilidades = true;
        break;
      default:
        break;
    }

    this.total = 0;

    this.movimiento$ = this._nominaService.listarMovimientosNomina(this.npe_codnpe, event).subscribe(
      response => {
        this.movimientos = response;
        this.movimientos.forEach((element, key) => {
          this.total += Number(element.imprem)
          element['correlativo'] = key;
          element['isEditing'] = true;
          element['imprem'] = Number(element['imprem']).toFixed(2);
        });
        this.total = Number(this.total);
        this.dataSource = fillTable(this.movimientos, this.paginator, this.sort);

      },
      error => {
        this._snackBarService.showError("Error al obtener listado de movimientos de nómina", "OK");
      }
    )
  }

  blurImporte(row): void {
    row.imprem = Number(row.imprem).toFixed(2)
    this.total = 0;
    this.movimientos.forEach(item => {
      this.total += Number(item.imprem)
    })
  }

  /**
   * Valida si todos los campos se encuentran
   * en la tabla.
   * @returns
   */
  esValidoTabla(): boolean {
    let validacion = true;
    this.movimientos.forEach(item => {
      if(!item.codcli || !item.cannut || !item.codnut) {
        validacion = false;
      }
    })

    return validacion;
  }

  registrarMovimiento(f: NgForm): void {

    this.movimientos.forEach(item => {
      item.imprem = Number(item.imprem);
    })

    if(!this.esValidoTabla()) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok');
      return ;
    };

    this.establecerIndicadores();

    this.estanHabilitados = !this.estanHabilitados;
    this.movimientos.forEach(movimiento => {
      movimiento.isEditing = true;
    });

    let isValid = true;

    this.movimientos.forEach(item => {
      if(item.isEditing) isValid = false;
    });

    // if(!isValid) {
    //   this._snackBarService.showError('Ninguna fila debe de estar en modo edición', 'Ok');
    //   return;
    // }

    this.movimientos.forEach(element => {
      element['nmt_indmod'] = 0;
    })

    this.loaderReg = true;

    this.reporte$ = this._nominaService.registrarMovimientoRemuneracionColaborador(this.movimientos, this.npe_codnpe, this.concepto).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Movimientos registrados', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )

  }

  establecerIndicadores(): void {
    this.movimientos.forEach(item => {
      item['indmod']? item['indmod'] = 1: item['indmod'] = 0
    });
  }

  cargarColaboradores(): void {

    if(!this.concepto) {
      this._snackBarService.showError('Seleccionar un concepto', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(CargarColaboradoresNominaDialog, {
      width: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {

      let colaboradoresEnTabla = this.dataSource.data;
      const colaboradoresCargados = result;

      let colaboradoresAgregados = [];

      this.estanHabilitados = !this.estanHabilitados;

      colaboradoresCargados.forEach((it, key) => {
        let existe = false;
        colaboradoresEnTabla.forEach(item => {
          if(it.codigo.trim() == item.codcli.trim()) {
            existe = true;
          }
        })
        if(!existe) {

          colaboradoresAgregados.push({
            codcli: it.codigo,
            nomcli: it.name,
            imprem: 0,
            cannut: this.unidad == 'H'? 0: 1,
            codnut: this.unidad,
            canhrs: 0,
            canmin: 0,
            isEditing: true,
            correlativo: key
          })
        };
      })

      colaboradoresAgregados.forEach(item => colaboradoresEnTabla.push(item));

      this.dataSource = fillTable(colaboradoresEnTabla, this.paginator, this.sort);
      colaboradoresAgregados.forEach(item => this.total += item.imprem);
      this.total = Number(this.total);

    });
  }

  cargarDesdeExcel(): void {
    const dialogRef = this.dialog.open(CargarExcelNominaDialog, {
      width: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      let colaboradoresACargar = [...this.dataSource.data, ...result];
      this.dataSource = fillTable(colaboradoresACargar, this.paginator, this.sort);
    });
  }

  seleccionarColaborador(event: MatSelectChange, row): void {
    let existe = false;
    this.movimientos.forEach(item => {
      if(item.isEditing == false)
        if(row.codcli == item.codcli) existe = true;
    });

    if(existe) {
      this._snackBarService.showError('Este colaborador ya existe en la tabla', 'Ok');
      event.source.value = "";
      return;
    }

    const colaborador = this.colaboradores.find(item => item.codcli == row.codcli);
    row.nomcli = colaborador.nomcli;
  }

  calcularTiempo(row: MovimientoRemuneracionColaborador): void {
    row.cannut = Number((row.canhrs + (row.canmin / 60)).toFixed(2));
  }

  editarTodos(): void {
    this.estanHabilitados = !this.estanHabilitados;
    this.movimientos.forEach(movimiento => {
      movimiento.isEditing = true;
    });

  }

  confirmarTodos(): void {
    this.estanHabilitados = !this.estanHabilitados;
    this.movimientos.forEach(movimiento => {
      movimiento.isEditing = true;
    });
  }

  agregarItem(): void {
    if(!this.concepto) {
      this._snackBarService.showError('Seleccionar un concepto', 'Ok')
      return;
    }

    let item = new MovimientoRemuneracionColaborador();
    item.codnut = this.unidad;

    if(item.codnut != 'H') item.cannut = 1;

    this.movimientos.push(item);
    this.total = 0;

    this.movimientos.forEach((element, key) => {
      this.total += Number(element.imprem);
      element['correlativo'] = key;
    })

    this.total = Number(this.total.toFixed(2));

    this.dataSource = fillTable(this.movimientos, this.paginator, this.sort);
  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row): void {
    if(!row.codcli || !row.cannut || !row.codnut) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

    this.total = 0;
    this.movimientos.forEach(item => {
      this.total += Number(item.imprem);
    })
  }

  doubleClick(event, row): void {
    /*
    if(row.isEditing) {
      if(!row.codcli || !row.cannut || !row.codnut) {
        this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
        return;
      }

      row.isEditing = !row.isEditing;

      this.total = 0;
      this.movimientos.forEach(item => {
        this.total += item.imprem;
      })
      return;
    }

    row.isEditing = !row.isEditing;
    this.inicializarSelectColaboradores();
    */
  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSource.data.filter(item => item.correlativo != row.correlativo);
        filtered.forEach((element, key) => {
          element['correlativo'] = key;
        })
        this.movimientos = filtered;
        this.dataSource = fillTable(filtered, this.paginator, this.sort);
      }
    })
  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.configuracion$,
      this.movimiento$,
      this.period$,
      this.nomina$,
      this.loading$,
      this.reporte$,
      this.listarColab$
    ])

  }

}
