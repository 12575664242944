import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ConfigurationService, UserService } from "src/app/services";

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingDialog implements OnInit{

  clienteUser$: Subscription;
  establecimientoUsuario$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<LoadingDialog>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    private _router: Router,
    private _userService: UserService,
    private _configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.clienteUser$ = this._userService.listarClientesDeUsuario(this._configurationService.obtenerIdUsuario()).subscribe(
      clientes => {
        this._configurationService.establecerClientes(clientes);
        this.next();

      }
    )
  }

  next(): void {
    this._router.navigate(['/ingresar-parametros']);
    this.dialogRef.close();
  }
}
