<app-pavso-title-section [title]="'Tipo de servicio'" [module]="'Compras'" [usuario]="tipoServicio.TSE_CODUSE" [fecha]="tipoServicio.TSE_FECUPD" [ngClass]="'pav-form'" [estado]="tipoServicio.TSE_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarTipoServicio(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="tipoServicio.TSE_CODTSE" matInput placeholder="Código" name="TSE_CODTSE" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6"></div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="tipoServicio.TSE_DESTSE" matInput placeholder="Descripción" name="TSE_DESTSE" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción Abreviado</mat-label>
            <input type="text" [(ngModel)]="tipoServicio.TSE_DESABR" matInput placeholder="Descripción Abreviado" name="TSE_DESABR" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-compras/tipos-de-servicio">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
