<app-pavso-title-section [title]="'Codificación de Productos por Cliente'" [module]="'Ventas'" [usuario]="" [fecha]=""
  [ngClass]="'pav-form'" [estado]="codificacionProductoCliente.pca_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <!-- coger este codigo y ponerlo entre la cabecera de la tabla y el detalle
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar> -->

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <select-search [label]="'Clientes'" [placeholder]="'Ej. Juan Ibañez'" [name]="'cli_codcli'"
            [value]="'cli_codcli'" [description]="'description'" [data]="clientes"
            (cambioSelect)="codificacionProductoCliente.cli_codcli = $event; "
            [model]="codificacionProductoCliente.cli_codcli">
          </select-search>
        </div>
        <div class="col s12 m12 l6"></div>

        <!-- {{codificacionProductoCliente | json}}         -->
      </div>
    </mat-card>
    <br>

    <div class="mat-elevation-z0 overflow-x">
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">

        </div>
        <div class="pav-table-header-title">
          Productos de Cliente
        </div>
        <div class="pav-table-header-add">
          <div class="isMobile">
            <div class="pav-button-icon-add" (click)="agregarProductoCliente()">
              <mat-icon svgIcon="add"></mat-icon>
            </div>
          </div>
          <div class="isWeb">
            <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarProductoCliente()">Agregar
              ítem</button>
          </div>
        </div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Código" class="td-responsive">
            <div class="pav-btns-container">
              <div class="pav-btn-circle-delete" (click)="quitarProductoCliente(i)" matTooltip="Eliminar">
                <!-- (click)="quitarDiasEntrega(row,i)" -->
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="prd_codprd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Código | Descripción de Producto</th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="prd_codprd">
            <ng-container *ngIf="row.isEditing">
              <div class="col s12 m12 l3">
                <producto-select [label]="'Productos'" [placeholder]="'Ej. BOLSA SUPERTRIGO EL TACO'"
                  [name]="'prd_codprd'" [value]="'prd_codprd'" [description]="'description'" [data]="comboProductos"
                  (cambioSelect)="row.prd_codprd = $event" [model]="row.prd_codprd">
                </producto-select>
              </div>
            </ng-container>
        </ng-container>


        <ng-container matColumnDef="pic_codprd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Código Producto de Cliente</th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="pic_codprd">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="example-full-width">
                <input matInput soloNumerosText maxlength="20" placeholder="Ej. 20082641" [(ngModel)]="row.pic_codprd"
                  [name]="'pic_codprd'+i">
              </mat-form-field>
            </ng-container>
        </ng-container>
        <ng-container matColumnDef="pic_desprd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion Producto de Cliente</th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="pic_desprd">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="example-full-width">
                <input matInput maxlength="150" placeholder="Ej. TORTILLA ANDINA DE 20 CM" [(ngModel)]="row.pic_desprd"
                  [name]="'pic_desprd'+i">
              </mat-form-field>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No se encontraron registros </td> <!-- {{input.value}} -->

        </tr>
      </table>
      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>



  </form>
</div>
