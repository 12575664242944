<section>
    <div class="row">
        <div class="col s12">
            <div class="pavso-content-form fade-in-image">
                <div align="center">
                  <img src="assets/images/404.gif" alt="">
                </div>
                <div align="center">
                  <h1>{{title}}</h1>
                </div>
            </div>

            <div class="pavso-content-form fade-in-image">
                <div align="center">
                    <button mat-flat-button color="accent" (click)="volver()">{{buttonName}}</button>
                </div>
            </div>
        </div>
    </div>
</section>
