export class UnidadTransporte {
  CIA_CODCIA: string;
  UTR_CODUTR: string;
  UTR_DESCRI: string;
  UTR_PLAUTR: string;
  UTR_NROCER: string;
  UTR_MARUTR: string;
  UTR_CONFIG: string;
  CHO_CODCHO: string;
  utr_codant: string;
  UTR_INDSTA: string;
  UTR_FECUPD: Date;
  UTR_CODUSE: string;
  CLI_CODCLI: string;

  constructor() {}
}
