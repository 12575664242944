<mat-list role="list">
  <mat-list-item role="listitem">
    <span mat-line>Cargar Factura</span>
    <span mat-line>Subir archivo (.pdf, .jpeg, .jpg)</span>
    <button (click)="cargarFactura()" [disabled]="!documentToUpload.isNew" matTooltip="Cagar factura" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>upload</mat-icon>
    </button>
    &nbsp;
    <button *ngIf="documentToUpload.invoiceFile" matTooltip="Ver factura" [ngStyle]="{background: '#63C99A'}" (click)="verImagen()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>photo_library</mat-icon>
    </button>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item role="listitem">
    <span mat-line>Cargar XML</span>
    <span mat-line>Subir archivo (.xml)</span>
    <button [disabled]="!documentToUpload.isNew" (click)="cargarXml()" matTooltip="Cagar xml" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>upload</mat-icon>
    </button>
    &nbsp;
    <button *ngIf="documentToUpload.xmlFile" [ngStyle]="{background: '#EB6945'}" matTooltip="Ver xml" (click)="verXml()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      xml
    </button>
  </mat-list-item>
</mat-list>
<!-- <mat-nav-list>
  <a mat-list-item>
    <span mat-line>Cargar factura</span>
    <span mat-line>Subir archivo (.pdf, .jpeg, .jpg)</span>

    <button (click)="cargarFactura()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>upload</mat-icon>
    </button>
    &nbsp;
    <button [ngStyle]="{background: '#63C99A'}" (click)="verImagen()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>photo_library</mat-icon>
    </button>
  </a>

  <a mat-list-item>
    <span mat-line>Cargar XML</span>
    <span mat-line>Subir archivo (.xml)</span>

    <button (click)="cargarXml()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>upload</mat-icon>
    </button>
    &nbsp;
    <button [ngStyle]="{background: '#EB6945'}" (click)="verXml()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      xml
    </button>
  </a>

</mat-nav-list> -->
<div class="custom-dialog-overlay" *ngIf="loaderValidacion">
  <div class="custom-dialog">
    <div align="center">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    <p>Comprobando la validez del Comprobante...</p>
  </div>
</div>
