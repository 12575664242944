import { Component } from "@angular/core";

@Component({
  selector: 'registro-compra-recepcion',
  templateUrl: './registro-compra-recepcion.component.html',
  styleUrls: ['./registro-compra-recepcion.component.scss']
})
export class RegistroCompraRecepcionComponent {

}
