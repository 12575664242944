import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { TipoInventario } from "src/app/models/almacen/tipo_inventario";
import { AlmacenService } from "src/app/services";

@Component({
  selector: 'tipo-inventario-form',
  templateUrl: './tipo-inventario-form.component.html',
  styleUrls: ['./tipo-inventario-form.component.scss']
})
export class TipoInventarioFormComponent implements OnInit, OnDestroy {

  loaderData: boolean = false;
  loaderReg: boolean = false;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tipoInventario$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  tipoInventario: TipoInventario;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService
  ){
    this.tipoInventario = new TipoInventario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        if(id == '0') return;

        this.tipoInventario$ = this._almacenService.listarTiposDeInventario().subscribe(
          tiposInventario => {

            const tipo = tiposInventario.find(item => item.TIN_CODTIN == id)
            this.tipoInventario = tipo;
            this.loaderData = false;

          },
          error => {

            this._snackBarService.showError('Error al obtener tipos de inventario', 'Ok');
            this.loaderData = false;
          }
        )
      }
    )
  }

  registrarTipoInventario(f: NgForm): void {
    this.sendForm$ = this._almacenService.registrarTipoInventario(this.tipoInventario).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tipo de inventario registrado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-almacen/tipos-de-inventario'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoInventario$,
      this.loading$,
      this.sendForm$,
    ])
  }
}
