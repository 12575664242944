import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SituacionActivo } from "src/app/models/activo-fijo";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class SituacionActivoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @param situacion
   * @returns
   */
    registrarSituacionActivo(situacion: SituacionActivo): Observable<any> {
      return this.http.post<any>(
        `${this._configurationService.obtenerUrl()}/api/activofijo/situacion-activo`,
        situacion
      );
    }

    /**
     *
     * @param situacion
     * @returns
     */
    actualizarSituacionActivo(situacion: SituacionActivo): Observable<any> {
      return this.http.put<any>(
        `${this._configurationService.obtenerUrl()}/api/activofijo/situacion-activo`,
        situacion
      );
    }
}
