<h1 mat-dialog-title>Mover detalle a orden de compra</h1>

<div mat-dialog-content>
  Esta seguro de mover el detalle a una nueva orden de compra?
</div>

<div mat-dialog-actions>
  <button mat-stroked-button (click)="cancelar()">Cancelar</button>
  <button mat-stroked-button cdkFocusInitial (click)="mover()" color="primary">Mover</button>
</div>
