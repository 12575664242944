<mat-card class="mat-elevation-z0">
  <div align="center" *ngIf="loadImages">
    <mat-spinner diameter="40"></mat-spinner>

  </div>
  <div *ngIf="images.length == 0">
    <div
      [ngStyle]="{width: '300px', borderRadius: '5px', border: '1px solid #222222', background: '#eee', paddingRight: '10px', paddingLeft: '10px'}">
      <p [ngStyle]="{fontWeight: 'bold'}"><strong>No se encontraron imagenes</strong></p>
    </div>
  </div>
  <ng-container *ngIf="!isMobile">
    <div [ngStyle]="{display: isMobile ? 'block': 'flex'}" *ngIf="images.length > 0">

      <div [ngStyle]="{display: isMobile? 'flex': 'block'}">

        <div [ngStyle]="{cursor: 'pointer'}" (click)="changeImage(image)" *ngFor="let image of images"
          [ngStyle]="{width: '48px', height: '48px', padding: '2px', border: image == imageSelected ? '2px solid #3483fa': '1px solid rgba(0,0,0,.25)', borderRadius: '10px', marginBottom: '5px'}">
          <img [ngStyle]="{width: '44px', height: '44px'}" [src]="image.pri_fotprd" alt="Imagen producto">

        </div>

      </div>

      <div [ngStyle]="{padding: '10px'}" class="img-zoom-container">
        <img id="myimage" [ngStyle]="{width: '600px', height: '428px'}" [src]="imageSelected.pri_fotprd" alt="">
        <div id="mylens" class="img-zoom-lens"></div>
      </div>

      <div [ngStyle]="{border: '1px solid #dbdbdb', borderRadius: '10px', width: '100%', padding: '5px 15px'}">
        <h1 [ngStyle]="{fontSize: '16px'}">Nombre del producto</h1>
        <p [ngStyle]="{fontSize: '14px'}">Descripción</p>
        <p [ngStyle]="{fontSize: '14px'}"><strong>Unidad medida:</strong> Unidad</p>
        <p [ngStyle]="{fontSize: '14px'}"><strong>Peso bruto:</strong> S/ 10</p>
        <p [ngStyle]="{fontSize: '14px'}"><strong>Peso neto:</strong> S/ 40</p>
        <p [ngStyle]="{fontSize: '14px'}"><strong>Stock mínimo:</strong> 2</p>
        <p [ngStyle]="{fontSize: '14px'}"><strong>Stock máximo:</strong> 20</p>

        <div class="row" align="center">
          <div id="myresult" class="img-zoom-result"
            [ngStyle]="{'background-image': 'url(' + imageSelected.pri_fotprd + ')'}"></div>
        </div>
      </div>

    </div>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <!-- <div class="slider">
      <img *ngFor="let image of images" [src]="image.pri_fotprd" alt="Image 1" />
    </div> -->
    <mat-tab-group>
      <mat-tab *ngFor="let image of images; let i = index" [label]="(i + 1).toString()">
        <br>
        <img [ngStyle]="{width: '100%', height: '100%'}" [src]="image.pri_fotprd" alt="Image 1" (click)="zoomImagen(image.pri_fotprd)" />
      </mat-tab>

    </mat-tab-group>
  </ng-container>
</mat-card>
