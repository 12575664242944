import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { map } from 'rxjs/operators';
import { TipoActividad } from 'src/app/models/produccion/maestros/tipo-actividad';

@Injectable({
    providedIn: 'root',
  })
  export class TipoActividadService {
    constructor(
      private http: HttpClient,
      private _configurationService: ConfigurationService
    ) {}
 /**
   *
   * @returns
   */
 obtenerTipoActividades(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/producciontipoactividad/listar/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((tipoActividades: any) => {
        return tipoActividades.map(tipoActividade => {
          tipoActividade['description'] = `${tipoActividade.tac_codtac} | ${tipoActividade.tac_destac}`
          return tipoActividade;
        })
      })
    );
  }
 
  /**
   *
   * @param cod
   * @returns
   */
  mostrarTipoActividad(cod): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/producciontipoactividad/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param actividad
   * @returns
   */
  registrarTipoActividad(tipoactividad: TipoActividad): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/producciontipoactividad/crear`,
    tipoactividad
    );
  }

  /**
   *
   * @param actividad
   * @returns
   */
  actualizarTipoActividad(tipoactividad: TipoActividad): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/producciontipoactividad/modificar`,
    tipoactividad
    );
  }
}