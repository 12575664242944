export class SituacionDocumento {
  sdc_codsdc: string;
  sdc_descri: string;
  sdc_indsta: string;
  sdc_coduse: string;
  sdc_fecupd: Date;

  constructor() {
    this.sdc_indsta = "1";
    this.sdc_fecupd = new Date();
  }
}
