import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Actividad } from 'src/app/models/produccion/actividad';
import { Subscription, of } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ActividadService } from 'src/app/services/api/produccion/maestros/actvidad.service';
import { forkObs } from '@utils/observables/fork';
import { TipoActividadService } from 'src/app/services/api/produccion/maestros/tipo-actividad.service';

@Component({
  selector: 'app-actividad-form',
  templateUrl: './actividad-form.component.html',
  styleUrls: ['./actividad-form.component.css']
})
export class ActividadFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = true;

  actividad: Actividad;

  tipoActividades: any[] = [];

  usuario: any;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  sendForm$: Subscription;
  actividad$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _actividadService: ActividadService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _tipoActividadService: TipoActividadService
  ) {
    this.actividad = new Actividad();
    this.actividad.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.actividad.act_coduse = this._configurationService.obtenerIdUsuario();

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=> {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      this.loadMaestros()
      if(id == '0') {
        this.loaderData = false;
       return
      };

      //this.obtenerActividad();
    })
  }

  loadMaestros(): void {
    console.log("loadMaestros")
    let obs = {
      description: 'Actividad 1',
      tac_codtac: '01'
    }
    const obs1 =of([obs])//este es una prueba
    forkObs(
      obs1,
      this._tipoActividadService.obtenerTipoActividades()
      ).then(respuesta => {
      this.tipoActividades = respuesta[1];
      console.log("this.tipoActividades",this.tipoActividades)
      if(this.uid != '0'){

        this.obtenerActividad();
      }
    })
    // if(this.uid != '0'){
    //   this.obtenerActividad();
    // }else{
    //   this.loaderData = false;
    // }
  }

  obtenerActividad(): void {
    this.actividad$ = this._actividadService.obtenerActividad(this.uid).subscribe(
      (actividad) => {
        this.actividad = actividad
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )

  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    if(!this.actividad.tac_codtac) {
      this._snackBarService.showError('Complete los campos requeridos', 'OK');
      this.loaderReg = false;
      return;
    }
    this.uid == '0' ? this.registrarActividad(f): this.actualizarActividad(f);
  }

  registrarActividad(f: NgForm): void {
    this.sendForm$=this._actividadService.registrarActividad(this.actividad).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Actividad registrada', '400px', '400px', '0');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg,'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarActividad(f: NgForm): void {
    this.sendForm$=this._actividadService.actualizarActividad(this.actividad).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Actividad actualizada', '400px', '400px', '0');
        this.loaderReg = false;
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg,'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-produccion/actividades']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.actividad$, this.loading$, this.sendForm$])
  }
}
