<app-pavso-title-section [title]="'Composición por edad y género de colaboradores'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-chart-container">
    <div class="pav-chart-header-icon"></div>
    <div class="pav-chart-header-text">Período {{year}} - {{month}}</div>
    <div class="pav-separator"></div>
    <div class="pav-chart-header-more">
    </div>
  </div>
    <div class="pav-chart-content">

        <div class="row">
            <div class="col s12 m12 l2"></div>
            <div class="col s12 m12 l8">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

                    <ng-container matColumnDef="initial">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> {{element.initial}} </td>
                    </ng-container>
                    <ng-container matColumnDef="edad1">
                      <th mat-header-cell *matHeaderCellDef> 21-30 </th>
                      <td mat-cell *matCellDef="let element"> {{element.edad1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="edad2">
                      <th mat-header-cell *matHeaderCellDef> 31-40 </th>
                      <td mat-cell *matCellDef="let element"> {{element.edad2}} </td>
                    </ng-container>

                    <ng-container matColumnDef="edad3">
                      <th mat-header-cell *matHeaderCellDef> 41-50 </th>
                      <td mat-cell *matCellDef="let element"> {{element.edad3}} </td>
                    </ng-container>

                    <ng-container matColumnDef="edad4">
                      <th mat-header-cell *matHeaderCellDef> 51-60 </th>
                      <td mat-cell *matCellDef="let element"> {{element.edad4}} </td>
                    </ng-container>

                    <ng-container matColumnDef="edad5">
                      <th mat-header-cell *matHeaderCellDef> 61-(+) </th>
                      <td mat-cell *matCellDef="let element"> {{element.edad5}} </td>
                    </ng-container>

                    <ng-container matColumnDef="trabajadores">
                        <th mat-header-cell *matHeaderCellDef> Colaboradores </th>
                        <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                      </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div class="col s12 m12 l2"></div>
        </div>
        <br>
        <div class="row">
            <div class="col s12">
                <div id="chartEdadSexoTrabajadores">
                </div>
            </div>
        </div>
      </div>
</div>

<mat-card class="mat-elevation-z0">

  <button class="pav-button-cancel" mat-flat-button (click)="volver()" type="button"
  >{{buttonsName.BTN_BACK | translate}}</button>
</mat-card>
