import { GuiaTexto } from "./guia-texto";

export class GuiaRemision{
    cia_codcia: string; // Código de compañía - char(2) - NO
    tdo_codtdo: string; // Código de TDO - char(3) - NO
    grc_numdoc: string; // Número de documento GRC - char(14) - NO
    grc_fecdoc: Date; // Fecha de documento GRC - smalldatetime - NO
    cli_codcli: string; // Código de cliente(proveedor) - char(12) - NO
    cli_numruc: string; // Número de RUC - char(11) - NO //esto no se envia en el servicio
    grc_obsgrc: string; // Observación GRC - varchar(80) - NO
    cli_codtra: string; // Código de cliente (transportista) - char(12) - YES
    cli_agetra: string; // Agencia de transporte - char(12) - YES
    mmo_codmmo: string; // Código de MMO - char(4) - YES
    mmo_fecupd: Date; // Fecha de actualización MMO - datetime - YES // esto no esta en la tabla de guia texto
    cho_codcho: string; // Código de chofer - char(11) - YES
    grc_nomcho: string; // Nombre de chofer GRC - varchar(80) - YES
    grc_nrolic: string; // Número de licencia GRC - varchar(20) - YES
    utr_codutr: string; // Código de UTR - char(5) - YES
    grc_plaund: string; // Placa de unidad GRC - varchar(8) - YES
    grc_nrocer: string; // Número de certificado GRC - varchar(20) - YES
    grc_marund: string; // Marca de unidad GRC - varchar(20) - YES
    grc_undtra: string; // Unidad de transporte GRC - varchar(20) - YES
    lde_codlde: string; // Código de LDE - char(4) - YES
    ldc_codldc: string; // Código de LDC - char(2) - YES
    grc_punint: string; // Punto de interés GRC - varchar(80) - YES
    grc_lugent: string; // Lugar de entrega GRC - varchar(80) - YES
    lde_codtra: string; // Código de LDE (transportista) - char(4) - YES
    lde_codage: string; // Código de LDE (agencia) - char(4) - YES
    pai_codpai: string; // Código de país - char(2) - YES
    ubi_codubi: string; // Código de ubicación - char(6) - YES
    ano_codano: string; // Código de año - char(4) - NO
    mes_codmes: string; // Código de mes - char(2) - NO
    alm_codalm: string; // Código de almacén - char(4) - YES
    tdo_codsal: string; // Código de TDO (salida) - char(3) - YES
    mac_nummac: string; // Número de máquina - char(5) - YES
    sdo_codsdo: string; // Código de SDO - char(4) - NO
    grc_indcod: string; // Indicador de código GRC - char(1) - NO
    tmo_codtmo: string; // Código de TMO - char(2) - YES
    egv_numegv: number; // Número de EGV - int - YES
    grc_ordcom: string; // Orden de compra GRC - varchar(30) - YES
    grc_tipgrc: string; // Tipo de GRC - char(1) - NO
    grc_tipanu: number; // Tipo de anulación GRC - tinyint - NO
    cco_codcco: string; // Código de CCO - char(15) - YES
    grc_fecpgd: Date; // Fecha de pago GRC - smalldatetime - YES
    grc_indpgd: number; // Indicador de pago GRC - tinyint - NO
    cde_codcde: number; // Código de CDE - int - YES
    grc_ordpgd: number; // Orden de pago GRC - int - YES
    grc_entreg: number; // Entrega GRC - tinyint - NO
    cde_entreg: number; // Entrega CDE - int - YES
    grc_nroenv: number; // Número de envío GRC - tinyint - NO
    grc_penprg: number; // Pendiente de programación GRC - int - NO
    grc_indsta: string; // Indicador de estado GRC - char(1) - NO
    grc_coduse: string; // Código de usuario GRC - varchar(30) - NO
    grc_fecupd: Date; // Fecha de actualización GRC - datetime - NO
    grc_gloanu: string; // Global One - varchar(80) - YES
    ref_codtdo: string; // Código de referencia TDO - char(3) - YES
    ref_numdoc: string; // Número de referencia de documento - char(14) - YES
    grc_idegrc: number; // Identificador GRC - int - NO
    grc_idetem: number; // Identificador temporal GRC - int - NO
    grc_condic: string; // Condición GRC - varchar(250) - YES
    grc_fectra: Date; // Fecha de traspaso GRC - datetime - YES
    grc_placa2: string; // Segunda placa GRC - varchar(8) - YES
    grc_indenv: number; // Indicador de envío GRC - smallint - NO
    grc_obsgen: string; // Observación general GRC - varchar(400) - YES
    grc_pesbru: number; // Peso bruto GRC - numeric - NO
    grc_bienor: number; // Bien o servicio GRC - smallint - NO
    grc_invdir: number; // Inventario directo GRC - smallint - NO
    grc_indcom: number; // Indicador de comercialización GRC - smallint - NO
    grc_indM1L: number; // Indicador M1L GRC - smallint - NO
    t64_codt64: string; // Código de T64 - char(3) - YES
    grc_numtic: string; // Número de ticket GRC - varchar(200) - YES
    grc_code: string; // Código GRC - varchar(5) - YES
    grc_moti: string; // Motivo GRC - varchar(250) - YES
    grc_ptolleg: number; // Punto de llegada GRC - smallint - NO
    grc_error: string; // Error GRC - varchar(8000) - YES
    texto_gtx:Array<GuiaTexto>   ;

    constructor(){
        //crea un objeto json con los campos de la tabla guia remision
        
        // this.grc_numdoc = 'T0010000000003';
        this.grc_indsta = '1';
        this.grc_fecdoc = new Date();
        this.grc_fecupd = new Date();
    }

    objetoPrueba(){
        let miobjeto
        return miobjeto = {
            "cia_codcia": "01",
            "tdo_codtdo": "GRE",
            "grc_numdoc": "T0010000006673",
            "grc_fecdoc": "2023-07-10",
            "cli_codcli": "10218020351",
            "grc_obsgrc": "PRUEBA GUIA TEXTO",
            "cli_codtra": "20512099662",
            "cli_agetra": null,
            "mmo_codmmo": null,
            "cho_codcho": null,
            "grc_nomcho": "",
            "grc_nrolic": "",
            "utr_codutr": null,
            "grc_plaund": "",
            "grc_nrocer": "",
            "grc_marund": "",
            "grc_undtra": null,
            "lde_codlde": null,
            "ldc_codldc": "01",
            "grc_punint": "",
            "grc_lugent": null,
            "lde_codtra": null,
            "lde_codage": null,
            "pai_codpai": null,
            "ubi_codubi": null,
            "ano_codano": "2023",
            "mes_codmes": "08",
            "alm_codalm": null,
            "tdo_codsal": null,
            "mac_nummac": null,
            "sdo_codsdo": "T001",
            "grc_indcod": "",
            "tmo_codtmo": "SO",
            "egv_numegv": null,
            "grc_ordcom": null,
            "grc_tipgrc": "1",
            "grc_tipanu": 0,
            "cco_codcco": null,
            "grc_fecpgd": null,
            "grc_indpgd": 0,
            "cde_codcde": null,
            "grc_ordpgd": null,
            "grc_entreg": 0,
            "cde_entreg": null,
            "grc_nroenv": 0,
            "grc_penprg": 1,
            "grc_indsta": "1",
            "grc_coduse": "pavso",
            "grc_fecupd": "2023-07-10T15:03:26.433",
            "grc_gloanu": null,
            "ref_codtdo": null,
            "ref_numdoc": null,
            "grc_idegrc": 162580,
            "grc_idetem": 0,
            "grc_condic": null,
            "grc_code": null,
            "grc_moti": null,
            "grc_fectra": null,
            "grc_placa2": null,
            "texto_gtx": [
                {
                    "cia_codcia": "01",
                    "tdo_codtdo": "GRE",
                    "grc_numdoc": "T0010000006673",
                    "gtx_itegtx": "001",
                    "gtx_glogtx": "PRUEBA DE LA GUIA TIPO TEXTO item 1",
                    "prd_codprd": "XXXXXXXXXXX",
                    "ume_codume": "UND",
                    "gtx_cangtx": 1,
                    "gtx_pesbru": 10,
                    "gtx_indsta": "1",
                    "gtx_fecupd": "2023-07-10T15:03:26.477",
                    "gtx_coduse": "pavso"
                },
                {
                    "cia_codcia": "01",
                    "tdo_codtdo": "GRE",
                    "grc_numdoc": "T0010000006673",
                    "gtx_itegtx": "002",
                    "gtx_glogtx": "PRUEBA item 2",
                    "prd_codprd": "XXXXXXXXXXX",
                    "ume_codume": "UND",
                    "gtx_cangtx": 2,
                    "gtx_pesbru": 20,
                    "gtx_indsta": "1",
                    "gtx_fecupd": "2023-07-10T15:03:26.500",
                    "gtx_coduse": "pavso"
                }
            ],
            "kardex_map": null
        }
    }
}