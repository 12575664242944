<app-pavso-title-section [title]="'Registros de venta'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>


    <div class="mat-elevation-z0">
      <table-title-action title="Todos los registros" url="/modulo-contabilidad/registro-de-venta" entity="" [params]="['0']"></table-title-action>

      <div class="overflow-x">

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <button mat-icon-button matTooltip="Editar pedido" [routerLink]="['/registro-de-venta', row.ano + row.mes + row.subdiario + row.correlativo]" color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="ano">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                <td mat-cell *matCellDef="let row" data-label="AÑO"> {{row.ano}} </td>
            </ng-container>

            <ng-container matColumnDef="mes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
                <td mat-cell *matCellDef="let row" data-label="MES"> {{row.mes}} </td>
            </ng-container>

            <ng-container matColumnDef="subdiario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Subdiario </th>
                <td mat-cell *matCellDef="let row" data-label="SUBDIARIO"> {{row.subdiario}} </td>
            </ng-container>

            <ng-container matColumnDef="correlativo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo </th>
                <td mat-cell *matCellDef="let row" data-label="CORRELATIVO"> {{row.correlativo}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha_emision">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
                <td mat-cell *matCellDef="let row" data-label="FECHA EMISIÓN"> {{row.fecha_emision | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="auxiliar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código Aux. </th>
                <td mat-cell *matCellDef="let row" data-label="CODIGO AUX."> {{row.auxiliar}} </td>
            </ng-container>

            <ng-container matColumnDef="nombre_auxiliar">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
              <td mat-cell *matCellDef="let row" data-label="AUXILIAR"> {{row.nombre_auxiliar}} </td>
            </ng-container>

            <ng-container matColumnDef="glosa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row" data-label="GLOSA"> {{row.glosa}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>
      </div>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>

