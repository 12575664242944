import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[soloNumerosText]'
})
export class SoloNumerosDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(evento: KeyboardEvent) {
    const tecla = evento.key;
    // Solo permitir números
    if (tecla.length === 1 && !tecla.match(/[0-9]/)) {
      evento.preventDefault();
    }
  }
}