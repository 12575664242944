import { NgModule } from "@angular/core";
import { PlanillaComponent } from './planilla.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { informesComponentsPlanilla } from "./pages/informes";
import { maestrosComponentsPlanilla, maestrosModulosPlanilla } from "./pages/maestros";
import { operacionesComponentPlanilla } from "./pages/operaciones";
import { procesosComponentsPlanilla } from "./pages/procesos";
import { SharedModule } from "@shared/shared.module";
import { MaterialModule } from "src/app/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDropzoneModule } from "ngx-dropzone";
import { BrowserModule } from "@angular/platform-browser";
import { NgApexchartsModule } from "ng-apexcharts";
import { PanelPlanillaComponent } from "./pages/panel/panel-planilla.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { configuracionesComponentsPlanilla } from "./pages/configuraciones";
import { datosPeriodoComponentsPlanilla } from "./pages/datos-periodo";
import { ctsComponentsPlanilla } from "./pages/cts";
import { interfaceContableComponentPlanilla } from "./pages/interface-contable";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
  declarations: [
    PlanillaComponent,
    PanelPlanillaComponent,
    ...informesComponentsPlanilla,
    ...configuracionesComponentsPlanilla,
    ...datosPeriodoComponentsPlanilla,
    ...maestrosComponentsPlanilla,
    ...operacionesComponentPlanilla,
    ...procesosComponentsPlanilla,
    ...ctsComponentsPlanilla,
    ...interfaceContableComponentPlanilla
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    NgxDropzoneModule,
    ReactiveFormsModule,
    BrowserModule,
    NgApexchartsModule,
    NgxMatSelectSearchModule,
    CurrencyMaskModule,
    ...maestrosModulosPlanilla,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class PlanillaModule {}
