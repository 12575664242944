import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { OpenClosePeriod } from '@data/interfaces/open-close-period';
import { IPeriodoAbierto } from '@data/interfaces/periodo_abierto';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @param suc
   * @param mod
   * @returns
   */
  obtenerPeriodoAbierto(suc, mod): Observable<IPeriodoAbierto> {

    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/modulos/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${suc}/${mod}`);

  }

  /**
   *
   * @param data
   * @returns
   */
  abrirPeriodo(data: OpenClosePeriod): Observable<any> {

    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/modulos/cerrarabrirperiodo`, data);

  }

  /**
   *
   * @param data
   * @returns
   */
  abrirCerrarPeriodo(data: OpenClosePeriod): Observable<any> {

    return this.http.post<any>(`${this._configurationService.obtenerUrl()}/api/modulos/cerrarabrirperiodo`, data);

  }

}
