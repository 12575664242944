import { NgModule } from "@angular/core";
import { ProductoListComponent } from "./producto-list/producto-list.component";
import { ProductoDialog } from "./producto-dialog/producto-dialog.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from "@shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { ProductoFormComponent } from "./producto-form/producto-form.component";
import { ProductoSelect } from "./producto-select/producto-select.component";

@NgModule({
  declarations: [ProductoFormComponent, ProductoListComponent, ProductoDialog, ProductoSelect],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    ProductoFormComponent, ProductoListComponent, ProductoDialog, ProductoSelect
  ]
})
export class ProductoModule {}
