import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NominaService } from 'src/app/services';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-listado-periodo-cts',
  templateUrl: './listado-periodo-cts.component.html',
  styleUrls: ['./listado-periodo-cts.component.css']
})
export class ListadoPeriodoCtsComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'ano_codano', 'ncp_ranini', 'ncp_ranfin', 'ncp_fecpag'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  periodo$: Subscription;
  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(
      state => {
        this.year = state.year;
        this.month = state.month;
      }
    )

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.obtenerPeriodosCTS();
    })
  }

  obtenerPeriodosCTS(): void {
    this.periodo$ = this._nominaService.listarPeriodoCTS(this.year, this.month).subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort)
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.periodo$, this.period$, this.loading$])
  }
}
