/**
 * Get date without dash or slash
 * @param date Date
 * @returns
 */
export function formatDateClean(date: Date | string) {

  var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + (d.getUTCDate()),
      year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('');
}

/**
 * Get date without dash or slash
 * @param date Date
 * @returns
 */
export function formatDateClean6Digitos(date: Date | string) {
  var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + (d.getUTCDate()),
      year = d.getUTCFullYear().toString().substring(2,4);

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('');
}

/**
 * Get date without dash or slash
 * @param date Date
 * @returns
 */
 export function formatDateWithDash(date: Date | string) {
  var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + (d.getUTCDate()),
      year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

/**
 * Get date without dash or slash
 * @param date Date
 * @returns
 */
export function formatDateWithDashLocal(date: Date | string) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (d.getDate()),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

/**
 * Get date without dash or slash
 * @param date Date
 * @returns
 */
 export function formatDateWithScript(date: Date | string) {
  var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + (d.getUTCDate()),
      year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day,month,year].join('-');
}

/**
 * Get date without dash or slash
 * @param date Date
 * @returns
 */
 export function formatDateWithSlash(date: Date | string) {
  var d = new Date(date),
      month = '' + (d.getUTCMonth() + 1),
      day = '' + (d.getUTCDate()),
      year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

export function getHourMinuteSecondsCurrent(date: Date | string) {
  let d = new Date(date),
    hour = d.getUTCHours(),
    minute = d.getUTCMinutes(),
    second = d.getUTCSeconds();

  return [hour, minute, second].join(':');
}

/**
 * Get Date summing number of days
 * @param date Date
 * @return Date
 */
export function addDaysToDate(date: Date | string, days: number) {

  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;

}

/**
 * Transforma una fecha a un formato de palabras y números
 * @param date
 */
export function formatDateToSentence(date: Date | string): string {
  let fecha = new Date(date)
  const months2Sentence = {
    "1": "Enero",
    "2": "Febrero",
    "3": "Marzo",
    "4": "Abril",
    "5": "Mayo",
    "6": "Junio",
    "7": "Julio",
    "8": "Agosto",
    "9": "Setiembre",
    "10": "Octubre",
    "11": "Noviembre",
    "12": "Diciembre",
  }

  const days = fecha.getDate();
  const month = fecha.getMonth() + 1;
  const year = fecha.getFullYear();

  return `${days} de ${months2Sentence[month.toString()]} del ${year}`;
}

export function obtenerMesPeriodDeFecha(date: Date | string): string {
  const mes = new Date(date).getUTCMonth() + 1;

  const mesPeriodo = mes > 9 ? `${mes}` : `0${mes}`;

  return mesPeriodo;
}
