import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@shared/services/snackbar.service';
import { ProduccionService } from 'src/app/services/api/produccion.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { TurnoService } from 'src/app/services/api/produccion/maestros/turnos.service';
import { formatoHoras } from '@utils/conversions/number2time.conversion';

@Component({
  selector: 'app-turno-list',
  templateUrl: './turno-list.component.html',
  styleUrls: ['./turno-list.component.css']
})
export class TurnoListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'tur_codtur', 'tur_destur', 'tur_horini', 'tur_horfin', 'tur_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  driver$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _turnoService: TurnoService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
  ) {
    console.log("soy la hora: ",formatoHoras(1.5))
    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerTurnos();
    })
  }

  obtenerTurnos(): void {
    this.loaderData = true;
    this.driver$ = this._turnoService.obtenerTurnos().subscribe(
      response => {
        response.forEach(element => {
            element.tur_horini = formatoHoras(element.tur_horini);
            element.tur_horfin = formatoHoras(element.tur_horfin);
        });
        console.log("soy la respuesta2: ",response)
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }



  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.driver$, this.loading$
    ])
  }

}
