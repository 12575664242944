import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

export function fillTable(data: Array<any>, paginator: MatPaginator, sort: MatSort): MatTableDataSource<any> {
  const datasource = new MatTableDataSource(data);
  datasource.paginator = paginator;
  datasource.sort = sort;

  return datasource;
}

export function searchInTable(event: Event, dataSource: MatTableDataSource<any>): MatTableDataSource<any> {

  const filterValue = (event.target as HTMLInputElement).value;
  dataSource.filter = filterValue.trim().toLowerCase();

  if (dataSource.paginator) dataSource.paginator.firstPage();

  return dataSource;

}
