import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";
import { CrmService } from "src/app/services";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'pavso-column-chart-basic',
  templateUrl: './column-chart-basic.component.html',
  styleUrls: ['./column-chart-basic.component.scss']
})
export class ColumnChartBasicComponent implements OnInit, OnChanges {

  @Input() categorias: Array<string> = [];
  @Input() data: Array<any> = [];

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor(
    private _crmService: CrmService
  ) {
    console.log('categories@@@', this.categorias)
    console.log('data@@@', this.data)
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: this.data,
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: this.categorias
      },
      yaxis: {
        title: {
          text: "Cantidad"
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return " " + val + " ";
          }
        }
      }
    };

    this.obtenerData();
  }

  obtenerData(): void {
    this._crmService.obtenerEstadisticoOportunidadesEnCursoAgente(2023).subscribe(
      response => {
        console.log('responsesssssss22222', response)
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes', changes);
  }
}
