<div class="row" [ngStyle]="{marginTop: '50px'}">
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'Colaboradores activos'" [amount]="colaboradoresActivos" [bgColorTitle]="'pav-blue-dark'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'Colaboradores cesados'" [amount]="colaboradoresCesados" [bgColorTitle]="'pav-blue'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'Contratos vencidos'" [amount]="contratosVencidos" [bgColorTitle]="'pav-yellow'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'Contratos por vencer'" [amount]="contratosPorVencer" [bgColorTitle]="'pav-green'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'Vacaciones vencidas'" [amount]="vacacionesVencidas" [bgColorTitle]="'pav-blue-dark'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'Vacaciones por vencer'" [amount]="vacacionesPorVencer" [bgColorTitle]="'pav-blue'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'Docs. legajo vencido'" [amount]="documentosLegajoVencidos" [bgColorTitle]="'pav-yellow'"></card-dashboard-resume>
  </div>
  <div class="col s12 m12 l3">
    <card-dashboard-resume [title]="'Docs. legajo por vencer'" [amount]="documentosLegajoPorVencer" [bgColorTitle]="'pav-green'"></card-dashboard-resume>
  </div>
</div>

<div class="row">
  <div class="col s12">
    <mat-card class="mat-elevation-z0">
      <div id="chartCantidadTrabajadores">
      </div>
    </mat-card>
  </div>
</div>

<div class="row">
  <div class="col s12">
    <br>
    <br>
    <br>
  </div>
</div>
