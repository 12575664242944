import {
  COLOR_GRAY,
} from '@data/constants/configuration/configuration.constant';
import { HeaderReport } from '@data/interfaces/headers.report';
import { logo } from '@shared/resources/logo';
import { numberWithCommas } from '@utils/formats/number.format';
import { bottomBorder, bottomLeftBorder, bottomRightBorder, horizontalBorder, leftBorder, noBorders, openBottomBorder, openLeftBorder, openRightBorder, openTopBorder, rightBorder, topBorder, topLeftBorder, topRightBorder, verticalBorder } from '@utils/pdfmaker/utils/pdfmake.constant';
import {
  Canvas,
  Cell,
  Columns,
  Img,
  Line,
  PdfMakeWrapper,
  Stack,
  Table,
  Txt,
} from 'pdfmake-wrapper';
import { number2month } from '../../conversions/number2month.conversion';
import {
  formatDateToSentence,
  formatDateWithSlash,
} from '../../formats/date.format';
import { PavsoColors } from '@data/enums/color.enum';
import { obtenerNulls } from '../utils/pdfmake.function';

/**
 * Reporte CTS a Pagar
 */
export async function abrirCtsPagarPlanilla(headers: HeaderReport, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageSize({ width: 1085, height: 400 });
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.codePage).fontSize(8).alignment('left').end)
          .end,
        new Cell(
          new Stack([
            new Txt('CTS A PAGAR').bold().alignment('center').end,
            pdf.ln(1),
            new Txt('PERÍODO 2021 06').fontSize(10).alignment('center').bold()
              .end,
          ]).end
        ).end,
        new Cell(
          new Txt('13/09/2021 7:17 PM').fontSize(8).alignment('right').end
        ).end,
      ],
    ])
      .layout('noBorders')
      .widths('*').end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('').end,
            new Txt('COLABORADOR').fontSize(7).bold().alignment('center').end,
          ]).end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('CONCEPTOS REMUNERATIVOS')
                  .fontSize(7)
                  .bold()
                  .alignment('center').end
              ).end,
            ],
            [new Cell(new Canvas([new Line([0, 0], [405, 0]).end]).end).end],
            [
              new Cell(
                new Table([
                  [
                    new Cell(new Txt('BÁSICO').fontSize(7).bold().end).end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('ASIG. FAM.').fontSize(7).bold().end).end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('ALIMENTACIÓN').fontSize(7).bold().end)
                      .end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('OTRAS REMUN.').fontSize(7).bold().end)
                      .end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('REM. VAR.').fontSize(7).bold().end).end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('UL. GRATIF.').fontSize(7).bold().end).end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('TOTAL').fontSize(7).bold().end).end,
                  ],
                ])
                  .layout('noBorders')
                  .widths([30, 1, 40, 1, 60, 1, 50, 1, 40, 1, 40, 1, 30]).end
              ).end,
            ],
          ]).layout('noBorders').end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('REMUNERACIÓN COMPUTABLE')
                  .fontSize(7)
                  .bold()
                  .alignment('center').end
              ).end,
            ],
            [new Cell(new Canvas([new Line([0, 0], [120, 0]).end]).end).end],
            [
              new Cell(
                new Table([
                  [
                    new Cell(new Txt('POR AÑO').fontSize(7).bold().end).end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('1/12').fontSize(7).bold().end).end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('1/12/30').fontSize(7).bold().end).end,
                  ],
                ]).layout('noBorders').end
              ).end,
            ],
          ])
            .layout('noBorders')
            .widths([120]).end
        ).end,
        new Cell(
          new Table([
            [new Cell(new Txt('T. COMPUTABLE').fontSize(7).bold().end).end],
            [new Cell(new Canvas([new Line([0, 0], [60, 0]).end]).end).end],
            [
              new Cell(
                new Table([
                  [
                    new Cell(new Txt('MESES').fontSize(7).bold().end).end,
                    new Cell(new Canvas([new Line([0, 10], [0, -5]).end]).end)
                      .end,
                    new Cell(new Txt('DÍAS').fontSize(7).bold().end).end,
                  ],
                ]).layout('noBorders').end
              ).end,
            ],
          ]).layout('noBorders').end
        ).end,
        new Cell(new Txt('TOTAL POR MES').fontSize(7).bold().end).end,
        new Cell(new Txt('TOTAL POR DÍA').fontSize(7).bold().end).end,
        new Cell(new Txt('INTERÉS').fontSize(7).bold().end).end,
        new Cell(new Txt('TOTAL A PAGAR').fontSize(7).bold().end).end,
        new Cell(new Txt('NRO. CUENTA').fontSize(7).bold().end).end,
      ],
    ]).widths([115, 395, 120, 60, 60, 50, 50, 60, 50]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('001110011 VALDIVIEZO SUARES SOTO')
            .alignment('center')
            .fontSize(6).end
        ).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('000-1000-4211-441').alignment('right').fontSize(6).end
        ).end,
      ],
      [
        new Cell(
          new Txt('001110011 VALDIVIEZO SUARES SOTO')
            .alignment('center')
            .fontSize(6).end
        ).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('000-1000-4211-441').alignment('right').fontSize(6).end
        ).end,
      ],
      [
        new Cell(
          new Txt('001110011 VALDIVIEZO SUARES SOTO')
            .alignment('center')
            .fontSize(6).end
        ).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('000-1000-4211-441').alignment('right').fontSize(6).end
        ).end,
      ],
      [
        new Cell(
          new Txt('001110011 VALDIVIEZO SUARES SOTO')
            .alignment('center')
            .fontSize(6).end
        ).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('000-1000-4211-441').alignment('right').fontSize(6).end
        ).end,
      ],
      [
        new Cell(new Txt('').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(
          new Stack([
            new Cell(new Canvas([new Line([0, 0], [70, 0]).end]).end).end,
            new Txt('1200.00').alignment('right').fontSize(6).end,
          ]).end
        ).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
      ],
    ])
      .layout('noBorders')
      .widths([
        125, 30, 47, 70, 58, 48, 45, 42, 33, 28, 43, 32, 27, 58, 53, 50, 56, 60,
      ]).end
  );
  pdf.ln(2);

  pdf.add(
    new Table([
      [
        new Cell(new Txt('CAJA PIURA').alignment('left').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
      ],
      [
        new Cell(
          new Txt('001110011 VALDIVIEZO SUARES SOTO')
            .alignment('center')
            .fontSize(6).end
        ).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('000-1000-4211-441').alignment('right').fontSize(6).end
        ).end,
      ],
      [
        new Cell(
          new Txt('001110011 VALDIVIEZO SUARES SOTO')
            .alignment('center')
            .fontSize(6).end
        ).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('000-1000-4211-441').alignment('right').fontSize(6).end
        ).end,
      ],
      [
        new Cell(
          new Txt('001110011 VALDIVIEZO SUARES SOTO')
            .alignment('center')
            .fontSize(6).end
        ).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('000-1000-4211-441').alignment('right').fontSize(6).end
        ).end,
      ],
      [
        new Cell(
          new Txt('001110011 VALDIVIEZO SUARES SOTO')
            .alignment('center')
            .fontSize(6).end
        ).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('1200.00').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('000-1000-4211-441').alignment('right').fontSize(6).end
        ).end,
      ],
      [
        new Cell(new Txt('').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(
          new Stack([
            new Cell(new Canvas([new Line([0, 0], [70, 0]).end]).end).end,
            new Txt('1200.00').alignment('right').fontSize(6).end,
          ]).end
        ).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
      ],
    ])
      .layout('noBorders')
      .widths([
        125, 30, 47, 70, 58, 48, 45, 42, 33, 28, 43, 32, 27, 58, 53, 50, 56, 60,
      ]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(new Txt('').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
        new Cell(
          new Txt('TOTAL A PAGAR').bold().alignment('right').fontSize(6).end
        ).end,
        new Cell(
          new Stack([
            new Cell(new Canvas([new Line([0, 0], [70, 0]).end]).end).end,
            new Txt('1200.00').alignment('right').fontSize(6).end,
          ]).end
        ).end,
        new Cell(new Txt('').alignment('right').fontSize(6).end).end,
      ],
    ])
      .layout('noBorders')
      .widths([
        125, 30, 47, 70, 58, 48, 45, 42, 33, 28, 43, 32, 27, 58, 53, 50, 56, 60,
      ]).end
  );

  pdf.create().open();
}

/**
 * Reporte Abrir CTS Acumulada
 */
export async function abrirCtsAcumuladaPlanilla(headers: HeaderReport, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.codePage).fontSize(8).alignment('left').end)
          .end,
        new Cell(
          new Stack([
            new Txt('CTS A PAGAR').bold().alignment('center').end,
            pdf.ln(1),
            new Txt('PERÍODO 2021 06').fontSize(10).alignment('center').bold()
              .end,
          ]).end
        ).end,
        new Cell(new Txt('13/09/2021').fontSize(8).alignment('right').end).end,
      ],
    ])
      .layout('noBorders')
      .widths([180, 160, 180]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(new Txt('FEC. INICIO').fontSize(8).bold().end).end,
        new Cell(new Txt('FEC. TÉRMINO').fontSize(8).bold().end).end,
        new Cell(new Txt('FEC. DEPÓSITO').fontSize(8).bold().end).end,
        new Cell(new Txt('NRO. CUENTA').fontSize(8).bold().end).end,
        new Cell(new Txt('A PAGAR').alignment('right').fontSize(8).bold().end)
          .end,
      ],
    ])
      .layout('noBorders')
      .widths([100, 100, 100, 100, 100]).end
  );

  pdf.add(
    new Table([
      [new Cell(new Canvas([new Line([0, 0], [535, 0]).end]).end).end],
    ])
      .layout('noBorders')
      .widths([500]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('MONEDA SOLES').fontSize(8).alignment('center').bold().end
        ).end,
      ],
    ])
      .layout('noBorders')
      .widths([520]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(new Txt('CLIENTE').fontSize(8).alignment('left').bold().end)
          .end,
        new Cell(new Txt('T00004').fontSize(8).alignment('left').bold().end)
          .end,
        new Cell(
          new Txt('TENORIO CETRARO AUGUSTO')
            .fontSize(8)
            .alignment('left')
            .bold().end
        ).end,
      ],
    ])
      .widths([40, 40, 150])
      .layout('noBorders').end
  );

  pdf.add(
    new Table([
      [
        new Cell(new Txt('BANCO').fontSize(8).alignment('left').bold().end).end,
        new Cell(
          new Txt('BANCO CONTINENTAL').fontSize(8).alignment('left').bold().end
        ).end,
      ],
    ])
      .widths([40, 150])
      .layout('noBorders').end
  );

  pdf.add(
    new Table([
      [
        new Cell(new Txt('01/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('31/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('15/06/12').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('0011-0119-07412-14200').fontSize(8).alignment('left').end
        ).end,
        new Cell(new Txt('437.50').fontSize(8).alignment('right').end).end,
      ],
      [
        new Cell(new Txt('01/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('31/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('15/06/12').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('0011-0119-07412-14200').fontSize(8).alignment('left').end
        ).end,
        new Cell(new Txt('437.50').fontSize(8).alignment('right').end).end,
      ],
      [
        new Cell(new Txt('01/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('31/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('15/06/12').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('0011-0119-07412-14200').fontSize(8).alignment('left').end
        ).end,
        new Cell(new Txt('437.50').fontSize(8).alignment('right').end).end,
      ],
      [
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('TOTAL BANCO CONTINENTAL')
            .fontSize(7)
            .alignment('left')
            .bold().end
        ).end,
        new Cell(
          new Stack([
            new Canvas([new Line([0, 0], [100, 0]).end]).end,
            new Txt('437.50').fontSize(8).alignment('right').end,
          ]).end
        ).end,
      ],
    ])
      .layout('noBorders')
      .widths([100, 100, 100, 100, 100]).end
  );

  pdf.ln(3);

  pdf.add(
    new Table([
      [
        new Cell(new Txt('CLIENTE').fontSize(8).alignment('left').bold().end)
          .end,
        new Cell(new Txt('T00004').fontSize(8).alignment('left').bold().end)
          .end,
        new Cell(
          new Txt('TENORIO CETRARO AUGUSTO')
            .fontSize(8)
            .alignment('left')
            .bold().end
        ).end,
      ],
    ])
      .widths([40, 40, 150])
      .layout('noBorders').end
  );

  pdf.add(
    new Table([
      [
        new Cell(new Txt('BANCO').fontSize(8).alignment('left').bold().end).end,
        new Cell(
          new Txt('BANCO CONTINENTAL').fontSize(8).alignment('left').bold().end
        ).end,
      ],
    ])
      .widths([40, 150])
      .layout('noBorders').end
  );

  pdf.add(
    new Table([
      [
        new Cell(new Txt('01/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('31/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('15/06/12').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('0011-0119-07412-14200').fontSize(8).alignment('left').end
        ).end,
        new Cell(new Txt('437.50').fontSize(8).alignment('right').end).end,
      ],
      [
        new Cell(new Txt('01/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('31/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('15/06/12').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('0011-0119-07412-14200').fontSize(8).alignment('left').end
        ).end,
        new Cell(new Txt('437.50').fontSize(8).alignment('right').end).end,
      ],
      [
        new Cell(new Txt('01/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('31/06/12').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('15/06/12').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('0011-0119-07412-14200').fontSize(8).alignment('left').end
        ).end,
        new Cell(new Txt('437.50').fontSize(8).alignment('right').end).end,
      ],
      [
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('TOTAL BANCO CONTINENTAL')
            .fontSize(7)
            .alignment('left')
            .bold().end
        ).end,
        new Cell(
          new Stack([
            new Canvas([new Line([0, 0], [100, 0]).end]).end,
            new Txt('437.50').fontSize(8).alignment('right').end,
          ]).end
        ).end,
      ],
    ])
      .layout('noBorders')
      .widths([100, 100, 100, 100, 100]).end
  );

  pdf.ln(3);

  pdf.add(
    new Table([
      [
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(new Txt('').fontSize(8).alignment('left').end).end,
        new Cell(
          new Txt('TOTAL A PAGAR').fontSize(8).alignment('left').bold().end
        ).end,
        new Cell(
          new Stack([
            new Canvas([new Line([0, 0], [100, 0]).end]).end,
            new Txt('24,851.50').fontSize(8).alignment('right').end,
          ]).end
        ).end,
      ],
    ])
      .layout('noBorders')
      .widths([100, 100, 100, 100, 100]).end
  );

  pdf.create().open();
}

/**
 * Reporte Liquidaciones Beneficios Sociales
 */
export async function generaPdfLiquidacionBeneficiosColaborador(
  headers: HeaderReport,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let deducciones = [];

  response.detalle.forEach((it) => {
    if (it.naf_porafp != 0) {
      deducciones.push([
        new Cell(new Txt(it.nco_descri).fontSize(7).end).border(noBorders).end,
        new Cell(new Txt(`${it.naf_porafp} %`).fontSize(7).end)
          .border(noBorders)
          .alignment('right').end,
        new Cell(new Txt('1').fontSize(7).end)
          .border(noBorders)
          .alignment('center').end,
      ]);
    }
  });

  // Establecer Otros Conceptos
  let otrosConceptos = [];

  if (response.nlt_sumgra != 0) {
    otrosConceptos.push([
      new Cell(
        new Txt('Suma Graciosa').alignment('left').bold().fontSize(7).end
      )
        .colSpan(2)
        .border(noBorders).end,
      new Cell(new Txt('').end).border(noBorders).end,
      new Cell(
        new Txt(response.nlt_sumgra).alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
    ]);
  }

  if (response.nlt_apldes != 0) {
    otrosConceptos.push([
      new Cell(new Txt('Descuentos').alignment('left').bold().fontSize(7).end)
        .colSpan(2)
        .border(noBorders).end,
      new Cell(new Txt('').end).border(noBorders).end,
      new Cell(
        new Txt(response.nlt_apldes).alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
    ]);
  }

  if (response.nlt_impvaa != 0) {
    otrosConceptos.push([
      new Cell(
        new Txt('Vacaciones Adelantadas')
          .alignment('left')
          .bold()
          .fontSize(7).end
      )
        .colSpan(2)
        .border(noBorders).end,
      new Cell(new Txt('').end).border(noBorders).end,
      new Cell(
        new Txt(response.nlt_impvaa).alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
    ]);
  }

  if (response.nlt_apovol != 0) {
    otrosConceptos.push([
      new Cell(
        new Txt('Remuneración Bruta(Días trabajados)')
          .alignment('left')
          .bold()
          .fontSize(7).end
      )
        .colSpan(2)
        .border(noBorders).end,
      new Cell(new Txt('').end).border(noBorders).end,
      new Cell(
        new Txt(response.nlt_apovol).alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
    ]);
  }

  if (response.nlt_apovol != 0) {
    otrosConceptos.push([
      new Cell(
        new Txt('Devolución de Renta de 5ta. Categoría')
          .alignment('left')
          .bold()
          .fontSize(7).end
      )
        .colSpan(2)
        .border(noBorders).end,
      new Cell(new Txt('').end).border(noBorders).end,
      new Cell(new Txt('0').alignment('right').bold().fontSize(7).end).border(noBorders).end,
    ]);
  }

  pdf.add(
    new Table([
      [
        new Cell(
          await new Img(
            logo
          )
            .alignment('left')
            .width(120)
            .build()
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('LIQUIDACIÓN DE BENEFICIOS SOCIALES').fontSize(10).bold()
              .end,
            pdf.ln(1),
            new Txt('EN EL REGIMEN DE MICRO Y PEQUEÑA EMPRESA').fontSize(8).end,
          ]).end
        ).border(noBorders).end,
      ],
    ]).widths([170, 360]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(new Txt('').fontSize(7).end).border(noBorders).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('Razon Social del Empleador:').fontSize(7).end
              ).border(noBorders).end,
              new Cell(new Txt(headers.clientName).fontSize(7).end).border(noBorders).end,
            ],
            [
              new Cell(new Txt('RUC:').fontSize(7).end).border(noBorders).end,
              new Cell(new Txt(headers.client).fontSize(7).end).border(noBorders).end,
            ],
          ]).end
        )
          .colSpan(2)
          .border(noBorders).end,
        new Cell(new Txt('').end).border(noBorders).end,
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('DATOS DEL COLABORADOR').fontSize(7).bold().alignment('center').end
              ).border(noBorders).colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(new Txt('COLABORADOR').bold().fontSize(7).end).border(noBorders).end,
              new Cell(new Txt(response.cli_nomcli).fontSize(7).end).border(noBorders).end,
            ],
            [
              new Cell(new Txt('CARGO').bold().fontSize(7).end).border(noBorders).end,
              new Cell(new Txt(response.nca_descri).fontSize(7).end).border(noBorders).end,
            ],
            [
              new Cell(new Txt('MOTIVO DE CESE').bold().fontSize(7).end).border(
                [false, false, false, false]
              ).end,
              new Cell(new Txt(response.mce_descri).fontSize(7).end).border(noBorders).end,
            ],
          ]).end
        ).end,
        new Cell(
          new Table([
            [
              new Cell(new Txt('Fecha : ').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(formatDateWithSlash(response.nlt_fecreg)).fontSize(
                  7
                ).end
              ).border(noBorders).end,
              new Cell(new Txt('Moneda: NUEVOS SOLES').fontSize(7).end)
                .border(noBorders)
                .colSpan(3).end,
              ...obtenerNulls(2),
            ],
            [
              new Cell(new Txt('F. Ingreso : ').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(formatDateWithSlash(response.trp_fecing)).fontSize(
                  7
                ).end
              )
                .colSpan(4)
                .border(noBorders).end,
              ...obtenerNulls(3),
            ],
            [
              new Cell(new Txt('F. Cese : ').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(formatDateWithSlash(response.trp_fecces)).fontSize(
                  7
                ).end
              ).border(noBorders).end,
              new Cell(new Txt('AÑO').fontSize(7).end).border(noBorders).end,
              new Cell(new Txt('MESES').fontSize(7).end).border(noBorders).end,
              new Cell(new Txt('DÍAS').fontSize(7).end).border(noBorders).end,
            ],
            [
              new Cell(new Txt('Tiempo de servicio').fontSize(7).end)
                .colSpan(2)
                .border(noBorders).end,
              ...obtenerNulls(1),
              new Cell(new Txt(response.nlt_tsrano).fontSize(7).end).border(noBorders).end,
              new Cell(new Txt(response.nlt_tsrmes).fontSize(7).end).border(noBorders).end,
              new Cell(new Txt(response.nlt_tsrdia).fontSize(7).end).border(noBorders).end,
            ],
          ]).widths([50, 80, 50, 50, 50]).end
        ).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('1. COMPENSACIÓN POR TIEMPO DE SERVICIOS')
                  .fontSize(7)
                  .bold().end
              )
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(
                new Txt('REMUNERACIÓN COMPUTABLE').fontSize(7).bold().end
              )
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(new Txt('Ram. Básica').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_rembas).fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('Asig. Familiar').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_asifam).fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('1/6 Ult. Gratif.').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_ultgra).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('Otras Remuneraciones').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_otrrem).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('Rem. Variable').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_varcts).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('IMPORTE').bold().fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_rincts)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(topBorder).end,
            ],
          ]).widths([150, 40]).end
        ).border(openLeftBorder).end,
        new Cell(
          new Table([
            [
              new Cell(new Txt('\n').end)
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(new Txt('TIEMPO COMPUTABLE').bold().fontSize(7).end)
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(
                new Txt(response.nlt_tctano).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt('AÑOS').fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_tctmes).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt('MESES').fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_tctdia).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt('DÍAS').fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
          ]).end
        ).border(horizontalBorder).end,
        new Cell(
          new Table([
            [
              new Cell(new Txt('\n').end).border(noBorders)
                .end,
            ],
            [
              new Cell(
                new Txt('IMPORTE LIQUIDADO').bold().fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_pctano).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_pctmes).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_pctdia)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_totcts)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(topBorder).end,
            ],
          ]).end
        ).border(openLeftBorder).end,
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('2. CÁLCULO DE VACACIONES').fontSize(7).bold().end
              )
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(
                new Txt('REMUNERACIÓN COMPUTABLE').fontSize(7).bold().end
              )
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(new Txt('Ram. Básica').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_rembas).fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('Asig. Familiar').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_asifam).fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('Rem. Variable').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_varvac).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('IMPORTE').bold().fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_rinvac)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(topBorder).end,
            ],
          ]).widths([150, 40]).end
        ).border(openLeftBorder).end,
        new Cell(
          new Table([
            [
              new Cell(new Stack([pdf.ln(1)]).end)
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],

            [
              new Cell(new Txt('TIEMPO COMPUTABLE').bold().fontSize(7).end)
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(
                new Txt(response.nlt_tvtano).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt('AÑOS').fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_tvtmes).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt('MESES').fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_tvtdia).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt('DÍAS').fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
          ]).end
        ).border(horizontalBorder).end,
        new Cell(
          new Table([
            [
              new Cell(new Stack([pdf.ln(1)]).end).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt('IMPORTE LIQUIDADO').bold().fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_pvaano).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_pvames).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_pvadia)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_totvac)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(topBorder).end,
            ],
          ]).end
        ).border(openLeftBorder).end,
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('3. GRATIFICACIONES TRUNCAS').fontSize(7).bold().end
              )
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],

            [
              new Cell(
                new Txt('REMUNERACIÓN COMPUTABLE').fontSize(7).bold().end
              )
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(new Txt('Ram. Básica').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_rembas).fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('Asig. Familiar').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_asifam).fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('Rem. Variable').fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_vargra).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(new Txt('IMPORTE').bold().fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_ringra)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(topBorder).end,
            ],
          ]).widths([150, 40]).end
        ).border(openLeftBorder).end,
        new Cell(
          new Table([
            [
              new Cell(new Txt('\n').end)
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(new Txt('TIEMPO COMPUTABLE').bold().fontSize(7).end)
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(
                new Txt(response.nlt_mesgra).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt('MESES').fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_diagra).fontSize(7).alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt('DÍAS').fontSize(7).alignment('right').end
              ).border(noBorders).end,
            ],
          ]).end
        ).border(horizontalBorder).end,
        new Cell(
          new Table([
            [
              new Cell(new Txt('\n').end).border(noBorders)
                .end,
            ],
            [
              new Cell(
                new Txt('IMPORTE LIQUIDADO').bold().fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_pgrmes).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_pgrdia).alignment('right').fontSize(7).end
              ).border(noBorders).end,
            ],
            [
              new Cell(
                new Txt(response.nlt_totgra)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(topBorder).end,
            ],
          ]).end
        ).border(openLeftBorder).end,
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(new Txt('4. DEDUCCIONES').bold().fontSize(7).end)
                .border(noBorders)
                .colSpan(3).end,
              ...obtenerNulls(2),
            ],
            [
              new Cell(
                new Txt('BASE DE CÁLCULO').bold().fontSize(7).end
              ).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_impafe)
                  .bold()
                  .fontSize(7)
                  .alignment('right').end
              ).border(noBorders).end,
              new Cell(
                new Txt(response.afp_descri)
                  .bold()
                  .fontSize(7)
                  .alignment('center').end
              ).border(noBorders).end,
            ],
            ...deducciones,
          ]).widths([150, 40, 50]).end
        )
          .colSpan(2)
          .border(openLeftBorder).end,
        ...obtenerNulls(1),
        new Cell(
          new Table([
            [
              new Cell(new Txt('5. APORTES').bold().fontSize(7).end)
                .border(noBorders)
                .colSpan(2).end,
              ...obtenerNulls(1),
            ],
            [
              new Cell(new Txt('ESSALUD').bold().fontSize(7).end).border(noBorders).end,
              new Cell(new Txt('3,948').bold().fontSize(7).end).border(noBorders).end,
            ],
          ]).widths([40, 20]).end
        ).border(openLeftBorder).end,
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(new Txt('6. OTROS CONCEPTOS').bold().fontSize(7).end)
                .border(noBorders)
                .colSpan(3).end,
              ...obtenerNulls(2),
            ],
            ...otrosConceptos,
          ]).widths([40, 100, 40]).end
        )
          .colSpan(3)
          .end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Table([
            [
              new Cell(new Txt('TOTAL A PAGAR').bold().fontSize(7).end).border(noBorders).end,
              new Cell(
                new Txt(response.nlt_netpag)
                  .alignment('right')
                  .bold()
                  .fontSize(7).end
              ).border(bottomBorder).end,
            ],
          ]).widths([140, 50]).end
        )
          .colSpan(3)
          .end,
        ...obtenerNulls(2),
      ],
    ]).widths([225, 225, 80]).end
  );

  pdf.add(pdf.ln(1));

  pdf.add(
    new Stack([
      new Txt(
        `Por el presente declaro mi conformidad con los montos consignados en la presente liquidación. Asi mismo, dclaro que he percibido oportunamente mis remuneraciones, Gratificaciones y cada uno de los beneficios que por ley me corresponde, siendo la suma de: S/. ${response.nlt_netpag} (${response.imp_letras} NUEVOS SOLES) que por concepto de Beneficios Sociales aparecen consignados en la Liquidación que antecede.`
      ).fontSize(8).end,
      new Txt(
        `No teniendo mas que reclamar por este ni por ningún otro concepto a ${headers.clientName}`
      ).fontSize(8).end,
      new Txt(
        'Por tanto dejo constancia mi conformidad con la culminación de mis períodos vacacionales, habiendo gozado de mi descanso físico en las oportunidades correspondientes y por los plazos pactados con mi empleador.'
      ).fontSize(8).end,
      new Txt(
        `Finalmente, declaro mi conformidad con los descuentos y referencias practicados y los cuales reconozco en su integridad. ${
          response.localidad
        }, ${formatDateToSentence(new Date(response.nlt_fecreg))}`
      ).fontSize(8).end,
    ]).end
  );

  pdf.add(pdf.ln(3));

  pdf.add(
    new Table([
      [
        new Cell(new Txt('').end).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(response.cli_nomcli).alignment('center').fontSize(8).end,
            new Txt(`DNI: ${response.cli_numdni}`)
              .fontSize(8)
              .alignment('center').end,
          ]).end
        ).border(topBorder).end,
      ],
    ]).widths([265, 265]).end
  );

  pdf.create().open();
}

/**
 * Reporte Formato Boleta de Pago Mensual
 */
export async function generarPDFBoletaPago(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');
  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  // pdf.header(function (currentPage, pageCount, pageSize) {
  //   return [
  //     { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, color: 'gray', bold: true},
  //   ]
  // });
  // pdf.footer(function (currentPage, pageCount, pageSize) {
  //   return [
  //     { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
  //   ];
  // })
  let detail = [];
  response.forEach((element, key) => {
    detail.push([
      new Cell(new Txt('NOMBRES').bold().fontSize(9).end).border(leftBorder).end,
      new Cell(new Txt(`: ${element.cli_nomcli}`).fontSize(9).end)
        .border(rightBorder)
        .colSpan(5).end,
      ...obtenerNulls(4),
    ]);

    detail.push([
      new Cell(new Txt('DOCUMENTO').bold().fontSize(9).end).border(leftBorder).end,
      new Cell(
        new Txt(`: ${element.tra_tipdoc} ${element.cli_codcli}`).fontSize(9).end
      ).border(noBorders).end,
      new Cell(new Txt('CUSP').bold().fontSize(9).end).border(noBorders).end,
      new Cell(new Txt(`: ${element.tra_numcus}`).fontSize(9).end).border(noBorders).end,
      new Cell(new Txt('REG. PENSIONARIO').bold().fontSize(9).end).border(noBorders).end,
      new Cell(new Txt(`: ${element.afp_descri}`).fontSize(9).end).border(rightBorder).end,
    ]);

    detail.push([
      new Cell(new Txt('CARGO').bold().fontSize(9).end).border(leftBorder).end,
      new Cell(new Txt(`: ${element.nca_descri}`).fontSize(9).end).border(noBorders).end,
      new Cell(new Txt('ESSALUD').bold().bold().fontSize(9).end).border(noBorders).end,
      new Cell(new Txt(`: ${element.trp_numaut}`).fontSize(9).end).border(noBorders).end,
      new Cell(new Txt('N° DIAS TRABAJADOS').bold().fontSize(9).end).border(noBorders).end,
      new Cell(new Txt(`: ${element.cli_diaefe}`).fontSize(9).end).border(rightBorder).end,
    ]);

    detail.push([
      new Cell(new Txt('F. NACIMIENTO').fontSize(9).bold().end).border(leftBorder).end,
      new Cell(new Txt(`: ${element.cli_fecnac? formatDateWithSlash(element.cli_fecnac): ''}`).fontSize(9).end).border(noBorders).end,
      new Cell(new Txt('CONDICIÓN').fontSize(9).bold().end).border(noBorders).end,
      new Cell(
        new Txt(
          `:  ${element.trp_indper == 1 ? 'PERSONAL DE CONFIANZA' : 'NINGUNO'}`
        ).fontSize(9).end
      ).border(noBorders).end,
      new Cell(new Txt('VACACIONES').bold().fontSize(9).end).border(noBorders).end,
      new Cell(new Txt(`: ${element.trp_diavac? element.trp_diavac: ''}`).fontSize(10).end).border(rightBorder).end,
    ]);

    detail.push([
      new Cell(new Txt('F. INGRESO').bold().fontSize(9).end).border(leftBorder).end,
      new Cell(new Txt(`: ${element.trp_fecing? formatDateWithSlash(element.trp_fecing): ''}`).fontSize(9).end).border(noBorders).end,
      new Cell(new Txt('F.CESE').bold().fontSize(9).end).border(noBorders).end,
      new Cell(new Txt(`: ${element.trp_fecces? formatDateWithSlash(element.trp_fecces): ""}`).fontSize(9).end).border(noBorders).end,
      new Cell(new Txt('REMUNERACIÓN BÁSICA').bold().fontSize(9).end).border(noBorders).end,
      new Cell(new Txt(`: ${element.rtr_rembas}`).fontSize(9).end).border(rightBorder).end,
    ]);

    detail.push([
      new Cell(
        new Txt('REMUNERACIONES').bold().fontSize(10).alignment('center').end
      ).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt('DESCUENTOS AL COLABORADOR')
          .bold()
          .fontSize(10)
          .alignment('center').end
      ).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt('APORTES AL EMPLEADOR')
          .bold()
          .fontSize(10)
          .alignment('center').end
      ).colSpan(2).end,
      ...obtenerNulls(1),
    ]);
    let detalleRemuneraciones = [];
    let detalleDescuentos = [];
    let detalleAportes = [];

    let [totalRemuneraciones, totalDescuentos, totalAportes] = [0, 0, 0];

    element.detalle.forEach((item) => {
      if (item.nco_coding && item.nco_coding != '') {
        detalleRemuneraciones.push(
          new Columns([
            new Txt(item.nco_desing).alignment('left').fontSize(8).end,
            new Txt(item.rep_tieing == 0?'': `${item.rep_tieing}  ${item.nut_coding}`).alignment('right').fontSize(8).end,
            // new Txt(item.nut_coding).alignment('right').fontSize(8).end,
            new Txt(numberWithCommas(item.rep_imping.toFixed(2)))
              .alignment('right')
              .fontSize(8).end,
          ]).end
        );

        totalRemuneraciones += item.rep_imping;
      }
      if (item.nco_codded && item.nco_codded != '') {
        detalleDescuentos.push(
          new Columns([
            new Txt(item.nco_desded).alignment('left').fontSize(8).end,
            new Txt(item.nut_canded2 == "0"?'': `${item.nut_canded2}  ${item.nut_codded}`).alignment('right').fontSize(8).end,
            // new Txt(item.nut_codded?item.nut_codded: '').alignment('right').fontSize(8).end,
            new Txt(numberWithCommas(item.rep_impded.toFixed(2)))
              .alignment('right')
              .fontSize(8).end,
          ]).end
        );

        totalDescuentos += item.rep_impded;
      }
      if (item.nco_codapo && item.nco_codapo != '') {
        detalleAportes.push(
          new Columns([
            new Txt(item.nco_desapo).alignment('left').fontSize(8).end,
            new Txt(numberWithCommas(item.rep_impapo.toFixed(2)))
              .alignment('right')
              .fontSize(8).end,
          ]).end
        );

        totalAportes += item.rep_impapo;
      }
    });

    if (detalleRemuneraciones.length < 21) {
      for (let index = detalleRemuneraciones.length; index < 21; index++) {
        detalleRemuneraciones.push(
          new Columns([
            new Txt(' ').alignment('left').fontSize(8).end,
            new Txt(' ').alignment('right').fontSize(8).end,
            new Txt(' ').alignment('right').fontSize(8).end,
            new Txt(' ').alignment('right').fontSize(8).end,
          ]).end
        );
      }
    }

    if (detalleDescuentos.length < 21) {
      for (let index = detalleDescuentos.length; index < 21; index++) {
        detalleDescuentos.push(
          new Columns([
            new Txt(' ').alignment('left').fontSize(8).end,
            new Txt(' ').alignment('right').fontSize(8).end,
            new Txt(' ').alignment('right').fontSize(8).end,
            new Txt(' ').alignment('right').fontSize(8).end,
          ]).end
        );
      }
    }

    if (detalleAportes.length < 21) {
      for (let index = detalleAportes.length; index < 21; index++) {
        detalleAportes.push(
          new Columns([
            new Txt(' ').alignment('left').fontSize(8).end,
            new Txt(' ').alignment('right').fontSize(8).end,
          ]).end
        );
      }
    }

    let detalle = [
      new Cell(new Stack(detalleRemuneraciones).end).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(new Stack(detalleDescuentos).end).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(new Stack(detalleAportes).end).colSpan(2).end,
      ...obtenerNulls(1),
    ];

    detail.push(detalle);

    detail.push([
      new Cell(
        new Columns([
          new Txt('TOTAL INGRESOS').bold().alignment('left').fontSize(8).end,
          new Txt(numberWithCommas(totalRemuneraciones.toFixed(2))).alignment('right').fontSize(8)
            .end,
        ]).end
      ).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Columns([
          new Txt('TOTAL DESCUENTOS').bold().alignment('left').fontSize(8).end,
          new Txt(numberWithCommas(totalDescuentos.toFixed(2)))
            .alignment('right')
            .fontSize(8).end,
        ]).end
      ).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Columns([
          new Txt('TOTAL APORTE').bold().alignment('left').fontSize(8).end,
          new Txt(numberWithCommas(totalAportes.toFixed(2)))
            .alignment('right')
            .fontSize(8).end,
        ]).end
      ).colSpan(2).end,
      ...obtenerNulls(1),
    ]);

    detail.push([
      new Cell(new Txt('').end).border(leftBorder).colSpan(4)
        .end,
      ...obtenerNulls(3),
      new Cell(new Txt('NETO A RECIBIR').fontSize(10).bold().end).border(noBorders).end,
      new Cell(
        new Txt(
          `S/. ${numberWithCommas(
            (totalRemuneraciones - totalDescuentos).toFixed(2)
          )}`
        )
          .alignment('right')
          .fontSize(10)
          .bold().end
      ).end,
    ]);

    detail.push([
      new Cell(new Txt('').end).border(verticalBorder).colSpan(6)
        .end,
      ...obtenerNulls(5),
    ]);

    detail.push([
      new Cell(
        new Table([
          [
            new Cell(new Txt('').end)
              .border(noBorders)
              .margin([0, 60, 0, 0]).end,
          ],
          [
            new Cell(
              new Txt('EMPLEADOR').fontSize(10).bold().alignment('center').end
            ).border(topBorder).end,
          ],
        ]).widths([375]).end
      )
        .border(bottomLeftBorder)
        .colSpan(3).end,
      ...obtenerNulls(2),
      new Cell(
        new Table([
          [
            new Cell(new Txt('').end)
              .border(noBorders)
              .margin([0, 60, 0, 0]).end,
          ],
          [
            new Cell(
              new Txt(element.cli_nomcli)
                .bold()
                .fontSize(10)
                .alignment('center').end
            ).border(topBorder).end,
          ],
        ]).widths([375]).end
      )
        .border(bottomRightBorder)
        .colSpan(3).end,
      ...obtenerNulls(2),
    ]);

    if(key != response.length - 1) {
      detail.push([
        new Cell(
          new Txt("").pageBreak('after').end
        ).border(verticalBorder).colSpan(6).end,
        ...obtenerNulls(5),
      ])
    }
  });

  pdf.add(
    new Table([
      [
        new Cell(
          // new Txt('').end
          await new Img(logo)
          .alignment('left')
          .margin([0,12,0,0])
          .width(120).build(),
        )
        .colSpan(2)
        .border(topRightBorder).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('\n').end,
            new Txt(headers.clientName).bold().fontSize(9).alignment('center').end,
            new Txt(`RUC: ${headers.client}`).bold().fontSize(9).alignment('center')
              .end,
            new Txt('\n').alignment('center').end,
            new Txt('BOLETA DE REMUNERACIONES')
              .fontSize(10)
              .bold()
              .alignment('center').end,
            new Txt(`${number2month(headers.month)} del ${headers.year}`).bold()
              .fontSize(9)
              .alignment('center').end,
            new Txt('\n').end,
          ]).end
        )
          .colSpan(2)
          .border(topBorder).end,
        ...obtenerNulls(1),
        new Cell(new Txt('').end).border(topLeftBorder).colSpan(2)
          .end,
        ...obtenerNulls(1),
      ],
      ...detail,
    ])
      .headerRows(1)
      .widths([125, 125, 125, 125, 125, 125]).end
  );

  pdf.create().open();
}

/**
 * Reporte Planilla LIneal
 * @param response
 * @param data
 */
export async function generarPDFPlanillaLineal(
  headers: HeaderReport,
  others,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');
  pdf.pageSize({ width: 1500, height: 1000 });

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  // ESTABLECER CABECERA GRUPOS
  let cabecerasTipo = [
    new Cell(new Txt('Código').alignment('center').bold().fontSize(6).end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end,
    new Cell(new Txt('Colaborador').alignment('center').bold().fontSize(6).end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end,
    new Cell(new Txt('INGRESOS').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo1).end,
    ...obtenerNulls(response.groups.tipo1 - 1),
    new Cell(new Txt('DEDUCCIONES').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo2).end,
    ...obtenerNulls(response.groups.tipo2 - 1),
    new Cell(new Txt('Total').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .rowSpan(2).end,
    new Cell(new Txt('APORTES').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo3).end,
    ...obtenerNulls(response.groups.tipo3 - 1),
  ];

  // ESTABLECER CABECERAS

  let cabeceras = [];

  cabeceras.push(
    new Cell(new Txt('').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end
  );

  cabeceras.push(
    new Cell(new Txt('').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end
  );

  response.headers.forEach((element, key) => {
    if (element.nco_indtip == 3 && response.headers[key - 1].nco_indtip == 2) {
      cabeceras.push(null);
      cabeceras.push(
        new Cell(
          new Txt(element.nco_descri).alignment('center').bold().fontSize(6).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end
      );
    } else {
      cabeceras.push(
        new Cell(
          new Txt(element.nco_descri).alignment('center').bold().fontSize(6).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end
      );
    }
  });

  // detalle
  let detalleGeneral = [];
  response.data.forEach((element) => {
    let detalle = [];
    detalle.push(
      new Cell(
        new Txt(`${element.cli_codcli}`).fontSize(6).alignment('left').end
      ).end
    );
    detalle.push(
      new Cell(
        new Txt(`${element.cli_nomcli}`).fontSize(6).alignment('left').end
      ).end
    );

    element.importes.forEach((it) => {
      detalle.push(
        new Cell(
          new Txt(numberWithCommas(it.toFixed(2)))
            .fontSize(6)
            .alignment('right').end
        ).end
      );
    });

    detalleGeneral.push(detalle);
  });

  // totales
  let totalesImp = [];
  response.data[0].importes.forEach((_) => totalesImp.push(0));

  response.data.forEach((item) => {
    totalesImp.forEach((it, key) => {
      totalesImp[key] += item.importes[key];
    });
  });

  let totales = [];
  totales.push(
    new Cell(new Txt('Totales').fontSize(7).bold().end).colSpan(2).end
  );

  totales.push(new Cell(new Txt('').fontSize(7).bold().end).end);

  totalesImp.forEach((item) => {
    totales.push(
      new Cell(
        new Txt(numberWithCommas(item.toFixed(2)))
          .fontSize(6)
          .bold()
          .alignment('right').end
      ).end
    );
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).end,
            new Txt(headers.client).fontSize(8).end,
            new Txt(headers.codePage).fontSize(8).end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Stack([
            new Txt('\n').end,
            new Txt('PLANILLA LINEAL').alignment('center').fontSize(10).bold()
              .end,
            new Columns([
              new Txt(`PERIODO ${headers.year} ${headers.month}`)
                .alignment('center')
                .fontSize(9)
                .bold().end,
              new Txt(`DEL 01/${headers.month}/${headers.year}`)
                .alignment('center')
                .fontSize(9)
                .bold().end,
              new Txt(`AL ${others.lastDay}/${headers.month}/${headers.year}`)
                .alignment('center')
                .fontSize(9)
                .bold().end,
            ]).end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(cabeceras.length - 2).end,
        ...obtenerNulls(cabeceras.length - 3),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').fontSize(8).end,
            new Txt(headers.time).alignment('right').fontSize(8).end,
            new Txt('').end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(new Txt('').end).colSpan(cabeceras.length).end,
        ...obtenerNulls(cabeceras.length - 1),
      ],
      cabecerasTipo,
      cabeceras,
      ...detalleGeneral,
      totales,
    ]).headerRows(4).end
  );

  pdf.create().open();
}

/**
 * Reporte de Planillla Resumen por Concepto
 * @param response
 * @param data
 */
export async function generarPDFPlanillaResumenPorConcepto(
  headers: HeaderReport,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  let [totalIngreso, totalEgreso, totalAportes] = [0, 0, 0];
  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt(item.nco_descri).bold().fontSize(9).end).end,
      new Cell(
        new Txt(numberWithCommas(item.remuneracion.toFixed(2)))
          .alignment('right')
          .fontSize(9).end
      ).end,
      new Cell(
        new Txt(numberWithCommas(item.deducciones.toFixed(2)))
          .alignment('right')
          .fontSize(9).end
      ).end,
      new Cell(
        new Txt(numberWithCommas(item.aporte.toFixed(2)))
          .alignment('right')
          .fontSize(9).end
      ).end,
    ]);

    totalIngreso += item.remuneracion;
    totalEgreso += item.deducciones;
    totalAportes += item.aporte;
  });

  detalle.push([
    new Cell(new Txt('Total').bold().fontSize(9).end).end,
    new Cell(
      new Txt(numberWithCommas(totalIngreso.toFixed(2)))
        .alignment('right')
        .bold()
        .fontSize(9).end
    ).end,
    new Cell(
      new Txt(numberWithCommas(totalEgreso.toFixed(2)))
        .alignment('right')
        .bold()
        .fontSize(9).end
    ).end,
    new Cell(
      new Txt(numberWithCommas(totalAportes.toFixed(2)))
        .alignment('right')
        .fontSize(9).end
    ).end,
  ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).end,
            new Txt(headers.client).fontSize(8).end,
            new Txt(headers.codePage).fontSize(8).end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('Planilla Resumen por Concepto').fontSize(12).bold().end,
            new Txt(`Período ${headers.year} ${headers.month}`)
              .fontSize(10)
              .bold().end,
          ]).alignment('center').end
        )
          .border(noBorders)
          .colSpan(2).end,
          ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).alignment('right').end,
            new Txt(headers.time).fontSize(8).alignment('right').end,
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(new Txt('CONCEPTO').alignment('center').bold().fontSize(10).end).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('INGRESOS').alignment('center').bold().fontSize(10).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('EGRESOS').alignment('center').bold().fontSize(10).end).color(PavsoColors.White).fillColor(PavsoColors.Blue)
          .end,
        new Cell(new Txt('APORTES').alignment('center').bold().fontSize(10).end).color(PavsoColors.White).fillColor(PavsoColors.Blue)
          .end,
      ],
      ...detalle,
    ]).headerRows(2).widths([190, 110, 110, 110]).end
  );

  pdf.create().open();
}

/**
 * Reporte de Remuneración por AFP
 * @param response
 * @param data
 */
export async function generarPDFRemuneracionPorAFP(
  headers: HeaderReport,
  others,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  let [tot1, tot2, tot3, tot4, tot5, tot6] = [0, 0, 0, 0, 0, 0];

  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt(item.afp_descri).bold().fontSize(8).end)
        .border(noBorders)
        .colSpan(15).end,
      ...obtenerNulls(14),
    ]);

    let [totAfecto, totApObl, totCVar, totPriSeg, totTotRetrib, totNeto] = [
      0, 0, 0, 0, 0, 0,
    ];

    item.detalle.forEach((it) => {
      const totalAfecto =
        it.nof_basico +
        it.nof_asifam +
        it.nof_grabon +
        it.nof_vacaci +
        it.nof_horext +
        it.nof_otrrem;

      const totRetri = it.nof_afpcom + it.nof_afpseg;

      const netoAPagar = totRetri + it.nof_afpobl;

      totAfecto += totalAfecto;
      totApObl += it.nof_afpobl;
      totCVar += it.nof_afpcom;
      totPriSeg += it.nof_afpseg;
      totTotRetrib += totRetri;
      totNeto += netoAPagar;

      detalle.push([
        new Cell(
          new Txt(it.cli_codcli).fontSize(7).alignment('left').end
        ).border(noBorders).end,
        new Cell(
          new Txt(it.cli_nomcli).fontSize(6).alignment('left').end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(it.trp_fecing))
            .fontSize(7)
            .alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_basico.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_asifam.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_grabon.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_vacaci.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_horext.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_otrrem.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalAfecto.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_afpobl.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_afpcom.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(it.nof_afpseg.toFixed(2))).fontSize(7).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totRetri.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(netoAPagar.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
      ]);
    });

    detalle.push([
      new Cell(new Txt(item.totCan).bold().fontSize(8).end)
        .border(noBorders)
        .alignment('center').end,
      new Cell(new Txt('').end).border(noBorders).colSpan(8)
        .end,
      ...obtenerNulls(7),
      new Cell(
        new Txt(numberWithCommas(totAfecto.toFixed(2))).alignment('right').fontSize(7).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totApObl.toFixed(2))).alignment('right').fontSize(7).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totCVar.toFixed(2))).alignment('right').fontSize(7).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totPriSeg.toFixed(2))).alignment('right').fontSize(7).bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totTotRetrib.toFixed(2)))
          .fontSize(7)
          .bold().alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totNeto.toFixed(2))).fontSize(7).bold().alignment('right').end
      ).border(topBorder).end,
    ]);

    tot1 += totAfecto;
    tot2 += totApObl;
    tot3 += totCVar;
    tot4 += totPriSeg;
    tot5 += totTotRetrib;
    tot6 += totNeto;
  });

  detalle.push([
    new Cell(new Txt('').bold().fontSize(8).end)
      .border(noBorders)
      .alignment('center').end,
    new Cell(new Txt('').end).border(noBorders).colSpan(8)
      .end,
    ...obtenerNulls(7),
    new Cell(
      new Txt(numberWithCommas(tot1.toFixed(2))).alignment('right').fontSize(7).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot2.toFixed(2))).alignment('right').fontSize(7).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot3.toFixed(2))).alignment('right').fontSize(7).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot4.toFixed(2))).alignment('right').fontSize(7).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot5.toFixed(2))).alignment('right').fontSize(7).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot6.toFixed(2))).alignment('right').fontSize(7).bold().end
    ).border(topBorder).end,
  ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).end,
            new Txt(headers.client).fontSize(8).end,
            new Txt(headers.codePage).fontSize(8).end
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Remuneración por AFP')
              .alignment('center')
              .bold()
              .fontSize(10).end,
            new Txt(
              `PERIODO ${headers.year} ${headers.month}  DEL ${others.dateIni} AL ${others.dateFin}`
            )
              .alignment('center')
              .bold()
              .fontSize(8).end,
            new Txt('\n').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(11).end,
        ...obtenerNulls(10),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').fontSize(8).end,
            new Txt(headers.time).alignment('right').fontSize(8).end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('COLABORADOR').fontSize(7).bold().end)
          .alignment('center')
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('REMUNERACIONES DEL COLABORADOR').fontSize(7).bold().end
        )
          .alignment('center')
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt('AFP').fontSize(7).bold().end)
          .alignment('center')
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(new Txt('NETO A PAGAR AFP').fontSize(7).bold().end)
          .alignment('center')
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(3).end,
      ],
      [
        new Cell(
          new Txt('CÓDIGO')
            .fontSize(10)
            .fontSize(7)
            .alignment('center')
            .bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('APELLIDOS Y NOMBRES')
            .fontSize(7)
            .alignment('center')
            .bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('INGRESO').fontSize(7).alignment('center').bold().end)
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('REMUN. BÁSICA').fontSize(7).alignment('center').bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('ASIGNACIÓN FAMILIAR')
            .fontSize(7)
            .alignment('center')
            .bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('GRATIFIC. Y/O BONIFIC.')
            .fontSize(7)
            .alignment('center')
            .bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('VACACIONES').fontSize(7).alignment('center').bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('HORAS EXTRAS').fontSize(7).alignment('center').bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('OTRAS REMUNERAC.').fontSize(7).alignment('center').bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('TOTAL AFECTO').fontSize(7).alignment('center').bold().end
        )
          .rowSpan(2)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('SISTEMA PRIVADO DE PENSIONES')
            .fontSize(7)
            .alignment('center')
            .bold().end
        )
          .colSpan(4)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        ...obtenerNulls(4),
      ],
      [
        ...obtenerNulls(10),
        new Cell(new Txt('Aporte').fontSize(7).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('Comisión').fontSize(7).bold().alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Seguro').fontSize(7).bold().alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Total deducción').fontSize(7).bold().alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        ...obtenerNulls(1),
      ],
      ...detalle,
    ]).headerRows(4).widths([50, 145, 40, 35, 40, 40, 35, 35, 40, 35, 30, 30, 30, 45, 35]).end
  );

  pdf.create().open();
}

/**
 * Informe de Neto a Pagar
 * @param response
 * @param data
 */
export function obtenerPDFNetoAPagar(headers: HeaderReport, others, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  let total = 0;
  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt(`BANCO   ${item.BAN_NOMBRE}`).fontSize(8).bold().end)
        .colSpan(4)
        .border(noBorders).end,
      ...obtenerNulls(3),
    ]);
    let totalBanco = 0;
    item.detalle.forEach((el) => {
      detalle.push([
        new Cell(new Txt(el.cli_codcli).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt(el.cli_nomcli).fontSize(8).end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc.toFixed(2))).fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ]);

      totalBanco += el.npc_impnpc;
    });
    detalle.push([
      new Cell(new Txt('').end).border(noBorders).colSpan(2)
        .end,
      ...obtenerNulls(1),
      new Cell(new Txt('TOTAL POR BANCO').fontSize(8).bold().end).border(topBorder).end,
      new Cell(new Txt(numberWithCommas(totalBanco.toFixed(2))).fontSize(8).bold().end)
        .border(topBorder)
        .alignment('right').end,
    ]);

    total += totalBanco;
  });

  detalle.push([
    new Cell(new Txt('').end).border(noBorders).colSpan(2)
      .end,
    ...obtenerNulls(1),
    new Cell(new Txt('TOTAL GENERAL').fontSize(8).bold().end).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(total.toFixed(2))).fontSize(8).bold().alignment('right').end
    ).border(topBorder).end,
  ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).end,
            new Txt(headers.client).fontSize(8).end,
            new Txt(headers.codePage).fontSize(8).end
          ]).end
        ).border(noBorders).end,

        new Cell(
          new Stack([
            new Txt('NETO A PAGAR').fontSize(10).alignment('center').bold().end,
            new Txt(
              `PLANILLA ${headers.year}${headers.month}${others.tipoNomina}${others.secNomina}`
            )
              .fontSize(8)
              .alignment('center')
              .bold().end,
            new Txt('\n').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').fontSize(8).end,
            new Txt(headers.time).alignment('right').fontSize(8).end,
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(new Txt('').end).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('COLABORADOR').fontSize(8).bold().end)
          .border(noBorders)
          .colSpan(2)
          .color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('IMPORTE').fontSize(8).bold().alignment('right').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).border(noBorders).end,
      ],
      ...detalle,
    ]).headerRows(2).widths([90, 300, 80, 50]).end
  );

  pdf.create().open();
}

/**
 * Reporte de Planilla Calculada
 * @param response
 * @param data
 */
export function obtenerPDFPlanillaCalculada(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  let [tRemuneracion, tDeduccion, tNeto, tAporte] = [0, 0, 0, 0];
  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt(item.cli_codcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.cli_nomcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.nca_descri).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.afp_descri).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(numberWithCommas(item.rep_imping.toFixed(2))).fontSize(8).end)
        .border(noBorders)
        .alignment('right').end,
      new Cell(new Txt(numberWithCommas(item.rep_impded.toFixed(2))).fontSize(8).end)
        .border(noBorders)
        .alignment('right').end,
      new Cell(new Txt(numberWithCommas(item.neto.toFixed(2))).fontSize(8).end)
        .border(noBorders)
        .alignment('right').end,
      new Cell(new Txt(numberWithCommas(item.rep_impapo.toFixed(2))).fontSize(8).end)
        .border(noBorders)
        .alignment('right').end,
    ]);

    tRemuneracion += item.rep_imping;
    tDeduccion += item.rep_impded;
    tNeto += item.neto;
    tAporte += item.rep_impapo;
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).end,
            new Txt(headers.codePage).fontSize(8).end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('PLANILLA CALCULADA')
              .bold()
              .fontSize(10)
              .alignment('center').end,
            new Txt(
              ''
            )
              .fontSize(8)
              .bold()
              .alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).alignment('right').end,
            new Txt(headers.time).fontSize(8).alignment('right').end,
          ]).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('Código').bold().fontSize(8).end)
          .border(noBorders)
          .color(PavsoColors.White)
          .alignment('center')
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('Colaborador').bold().fontSize(8).end)
          .border(noBorders)
          .color(PavsoColors.White)
          .alignment('center')
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('Cargo').bold().fontSize(8).end)
          .border(noBorders)
          .color(PavsoColors.White)
          .alignment('center')
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('AFP').bold().fontSize(8).end)
          .border(noBorders)
          .color(PavsoColors.White)
          .alignment('center')
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('Remuneración').bold().fontSize(8).end)
          .border(noBorders)
          .color(PavsoColors.White)
          .alignment('center')
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('Deducciones').bold().fontSize(8).end)
          .border(noBorders)
          .color(PavsoColors.White)
          .alignment('center')
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('Neto a Pagar').bold().fontSize(8).end)
          .border(noBorders)
          .color(PavsoColors.White)
          .alignment('center')
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('Aportes').bold().fontSize(8).end)
          .border(noBorders)
          .color(PavsoColors.White)
          .alignment('center')
          .fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
      [
        new Cell(new Txt('').fontSize(8).end)
          .colSpan(4)
          .border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(new Txt(numberWithCommas(tRemuneracion.toFixed(2))).alignment('right').bold().fontSize(8).end).border(topBorder).end,
        new Cell(new Txt(numberWithCommas(tDeduccion.toFixed(2))).alignment('right').bold().fontSize(8).end).border(topBorder).end,
        new Cell(new Txt(numberWithCommas(tNeto.toFixed(2))).alignment('right').bold().fontSize(8).end).border(topBorder).end,
        new Cell(new Txt(numberWithCommas(tAporte.toFixed(2))).alignment('right').bold().fontSize(8).end).border(topBorder).end,
      ],
    ]).headerRows(2).widths([80, 200, 80, 90, 80, 80, 70, 50]).end
  );

  pdf.create().open();
}

/**
 * Reporte de Revisa Renta
 * @param response
 * @param data
 */
export async function obtenerPDFRevisaRenta(headers: HeaderReport, others, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  // detalle
  let detalle = [];
  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt('COLABORADOR').bold().fontSize(8).end).border(noBorders).end,
      new Cell(new Txt('09999999').bold().fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.cli_nomcli).bold().fontSize(8).end)
        .border(noBorders)
        .colSpan(7).end,
      ...obtenerNulls(4),
      new Cell(new Txt('RENTA TOTAL').bold().fontSize(8).end).border(noBorders).end,
      new Cell(
        new Txt(
          numberWithCommas((item.nrr_valren + item.nrr_valrut).toFixed(2))
        )
          .bold()
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
    ]);

    detalle.push([
      new Cell(
        new Txt('Remuneración').fontSize(8).bold().end
      )
      // .decoration()
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt('Bonificación').fontSize(8).bold().end
      )
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .end,
      new Cell(
        new Txt('Total').fontSize(8).bold().alignment('center').end
      )
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .end,
      new Cell(
        new Txt('Renta Calculada').alignment('center').fontSize(8).bold().end
      )
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(5).end,
      ...obtenerNulls(4),
    ])

    detalle.push([
      new Cell(new Txt('Remuneración Fija').fontSize(8).end).border(leftBorder).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_remfij.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt(numberWithCommas(item.nrr_remfij.toFixed(2))).bold().fontSize(8).alignment('right').end).border(rightBorder)
        .end,

      new Cell(new Txt('').end).border(noBorders).colSpan(2)
        .end,
      ...obtenerNulls(1),
      new Cell(new Txt('Proyección de ingresos anuales').fontSize(8).end)
      .colSpan(2)
      .border(noBorders).end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(
        new Txt(numberWithCommas(item.pry_ingresos.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(rightBorder).end,
    ]);

    detalle.push([
      new Cell(new Txt('Gratificación Julio').fontSize(8).end).border(leftBorder).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_grati1.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_grabon1.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas((item.nrr_grati1 + item.nrr_grabon1).toFixed(2)))
        // new Txt(numberWithCommas(item.nrr_facgra1.toFixed(2)))
          .fontSize(8)
          .bold()
          .alignment('right').end
      ).border(rightBorder).end,
      new Cell(new Txt('').end).border(noBorders).colSpan(2)
        .end,
      ...obtenerNulls(1),
      new Cell(new Txt('Crédito anual').fontSize(8).end)
      .colSpan(2)
      .border(noBorders).end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(
        new Stack([
          new Txt('(-) ' + numberWithCommas(item.nqu_creanu.toFixed(2)))
            .fontSize(8)
            .alignment('right').end,
          new Txt('---------------').end
        ]).end
      ).border(rightBorder).end,

    ]);
    detalle.push([
      new Cell(new Txt('Gratificación Diciembre').fontSize(8).end).border(leftBorder).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_grati2.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_grabon2.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas((item.nrr_grati2 + item.nrr_grabon2).toFixed(2)))
        // new Txt(numberWithCommas(item.nrr_facgra2.toFixed(2)))
          .fontSize(8)
          .bold()
          .alignment('right').end
      ).border(rightBorder).end,
      new Cell(new Txt('').end).border(noBorders).colSpan(2)
        .end,
      ...obtenerNulls(1),
      new Cell(new Txt('Base Renta Anual').fontSize(8).end)
        .colSpan(2)
        .border(noBorders).end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_pycren.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(rightBorder).end,

    ]);

    detalle.push([
      new Cell(new Txt('Utilidad').fontSize(8).end).border(leftBorder).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_imputi.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(new Txt('').fontSize(8).alignment('right').end).border(noBorders).end,
      new Cell(new Txt(item.nrr_imputi.toFixed(2)).alignment('right').fontSize(8).end).bold().border(rightBorder)
        .end,
      new Cell(new Txt('').end).border(noBorders).colSpan(2)
        .end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(item.nrr_basre1.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(`${item.nrr_porre1}%`).fontSize(8).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_valre1.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(rightBorder).end,
    ]);
    detalle.push([
      new Cell(new Txt('Ingresos Acumulados Períodos Anteriores').fontSize(8).end).border(leftBorder).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_ingacu.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(new Txt('').fontSize(8).alignment('right').end).border(noBorders).end,
      new Cell(new Txt(numberWithCommas(item.nrr_ingacu.toFixed(2))).bold().fontSize(8).alignment('right').end).border(rightBorder).end,
      new Cell(new Txt('').end).border(noBorders).colSpan(2)
        .end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(item.nrr_basre2.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(`${item.nrr_porre2}%`).fontSize(8).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.nrr_valre2.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(rightBorder).end,

    ]);
    detalle.push([
      new Cell(new Txt('').fontSize(8).end).border(bottomLeftBorder)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(bottomBorder)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(bottomBorder)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(bottomRightBorder)
        .end,
      new Cell(new Txt('').fontSize(8).alignment('right').end).border(noBorders).end,
      new Cell(new Txt('').fontSize(8).alignment('right').end).border(noBorders).end,
      new Cell(
        new Stack([
          new Txt('Proyección Renta Anual').fontSize(8).end,
          new Txt('Renta Acumulada (-)').fontSize(8).end,
        ]).end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Stack([
          new Txt(numberWithCommas(item.nrr_valrea.toFixed(2)))
            .alignment('right')
            .fontSize(8).end,
          new Txt(numberWithCommas(item.nrr_renacu.toFixed(2)))
            .alignment('right')
            .fontSize(8).end,
          new Txt('----------------------').alignment('right').fontSize(8).end,
          new Txt(
            numberWithCommas((item.nrr_valrea - item.nrr_renacu).toFixed(2)) +
              ' / ' +
              item.nrr_nummes
          )
            .bold()
            .alignment('right')
            .fontSize(8).end,
        ]).end
      ).border(rightBorder).end,
    ]);
    detalle.push([
      new Cell(new Txt('Proyección de ingresos anuales').end).border(bottomLeftBorder).fontSize(8).bold().colSpan(3)
        .color(PavsoColors.White)
        .fillColor(PavsoColors.Blue)
        .end,
      ...obtenerNulls(2),
      new Cell(new Txt(numberWithCommas((item.pry_ingresos).toFixed(2))).end).border(bottomBorder).fontSize(8).bold().alignment('right')
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .end,
      new Cell(new Txt(`Proyección Renta Mensual ${headers.month == '05' ? ` - Mayo del ${headers.year}`: ` - Noviembre del ${headers.year}`}`).fontSize(8).end)
        .color(PavsoColors.White)
        .fillColor(PavsoColors.Blue)
        .colSpan(4)
        .bold()
        .border(openLeftBorder).end,
      ...obtenerNulls(3),
      new Cell(
        new Txt(
          item.nrr_nummes == 0 ?
          (0).toFixed(2)
          :
          numberWithCommas(
            ((item.nrr_valrea - item.nrr_renacu) / item.nrr_nummes).toFixed(2)
          )
        )
          .fontSize(8)
          .bold()
          .alignment('right').end
      )
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(openLeftBorder).end,

    ]);
    detalle.push([
      new Cell(new Txt('').end).border(noBorders).colSpan(4)
        .end,
      ...obtenerNulls(3),
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('\n').fontSize(8).end).border(noBorders).end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
    ]);
  });

  pdf.add(
    new Table([

      [
        new Cell(
          new Stack([
            await new Img(
              logo
            )
              .alignment('left')
              .width(70)
              .build(),

          ]).end
        )
          .colSpan(2)
          .bold()
          .border(noBorders).end,
          ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('REVISA RENTA').fontSize(11).bold().color(PavsoColors.Black).alignment('center').end,
            new Txt(
              `PLANILLA ${headers.year}-${headers.month}-${others.tipoNomina}-${others.secNomina}`
            )
              .color(PavsoColors.Black)
              .fontSize(8)
              .bold()
              .alignment('center').end,
            new Txt(headers.clientName).bold().alignment('center').fontSize(8).color(PavsoColors.Black).end,
            // new Txt(headers.username ? headers.codePage: '').bold().alignment('center').fontSize(8).color(PavsoColors.Blue).end,
          ]).end
        )
          .border(noBorders)

          .colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).alignment('right').end,
            new Txt(headers.time).fontSize(8).alignment('right').end,
          ]).end
        )
          .colSpan(2)
          .bold()
          .border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Stack([
            pdf.ln(1)
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8),
      ],
      ...detalle,
    ]).layout({
      hLineColor: () => PavsoColors.Blue,
      vLineColor: () => PavsoColors.Blue
    }).decorationColor('red').headerRows(2).widths([80, 45, 45, 45, 45, 45, 45, 75, 50]).end
  );

  pdf.create().open();
}

/**
 * Informe de Remuneraciones al Trabajador
 * @param response
 * @param data
 */
export function obtenerPDFRemuneracionesAlTrabajador(
  headers: HeaderReport,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  //Cabeceras
  const cabecera = [
    new Cell(
      new Stack([
        new Txt(headers.clientName).bold().fontSize(8).end,
        new Txt(headers.client).bold().fontSize(8).end,
        new Txt(headers.codePage).bold().fontSize(8).end
      ]).end
    ).border(noBorders).end,
    new Cell(
      new Stack([
        new Txt('Remuneraciones del Colaborador')
          .alignment('center')
          .bold()
          .fontSize(10).end,
        new Txt(`PERIODO ${headers.year} ${headers.month}`)
          .alignment('center')
          .bold()
          .fontSize(8).end,
      ]).end
    )
      .colSpan(
        response.groups.tipo1 + response.groups.tipo2 + response.groups.tipo3
      )
      .border(noBorders).end,
    ...obtenerNulls(2),
    new Cell(
      new Stack([
        new Txt(headers.date).bold().fontSize(8).alignment('right').end,
      ]).end
    ).border(noBorders).end,
  ];

  //Tipos
  const tipos = [];
  tipos.push(
    new Cell(new Txt('').end).border(noBorders).end
  );

  if (response.groups.tipo1 > 0) {
    tipos.push(
      new Cell(
        new Txt('INGRESOS').alignment('center').fontSize(10).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(response.groups.tipo1).end
    );
    for (let index = 0; index < response.groups.tipo1 - 1; index++) {
      tipos.push(null);
    }
  }

  if (response.groups.tipo2 > 0) {
    tipos.push(
      new Cell(
        new Txt('DEDUCCIONES').alignment('center').fontSize(10).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(response.groups.tipo2).end
    );
    for (let index = 0; index < response.groups.tipo2 - 1; index++) {
      tipos.push(null);
    }
  }

  if (response.groups.tipo3 > 0) {
    tipos.push(
      new Cell(
        new Txt('APORTES').alignment('center').fontSize(10).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(response.groups.tipo3).end
    );
    for (let index = 0; index < response.groups.tipo3 - 1; index++) {
      tipos.push(null);
    }
  }

  tipos.push(new Cell(new Txt('Total').alignment('center').fontSize(10).bold().end).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end);

  //Nombres Cabeceras
  const nombresCabeceras = [];

  nombresCabeceras.push(
    new Cell(new Txt('CÓDIGO COLABORADOR').fontSize(9).bold().end).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
  );

  response.headers.forEach((element) => {
    nombresCabeceras.push(
      new Cell(new Txt(element.nco_descri).fontSize(9).bold().end).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
    );
  });

  nombresCabeceras.push(null);

  //Detalles
  let detalle = [];

  response.data.forEach((element) => {
    let fila = [];

    fila.push(new Cell(new Txt(element.cli_nomcli).bold().fontSize(8).end).end);

    element.importes.forEach((item) => {
      fila.push(
        new Cell(new Txt(numberWithCommas(item.toFixed(2))).alignment('right').fontSize(8).end).end
      );
    });
    detalle.push(fila);
  });

  pdf.add(
    new Table([
      cabecera,
      tipos,
      nombresCabeceras,
      ...detalle,
      // [
      //   new Cell(
      //     new Txt("TOTAL").bold().fontSize(8).end
      //   ).end,
      //   new Cell(
      //     new Txt("0.00").alignment('right').bold().fontSize(8).end
      //   ).end,
      //   new Cell(
      //     new Txt("0.00").alignment('right').bold().fontSize(8).end
      //   ).end,
      //   new Cell(
      //     new Txt("0.00").alignment('right').bold().fontSize(8).end
      //   ).end,
      //   new Cell(
      //     new Txt("0.00").alignment('right').bold().fontSize(8).end
      //   ).end,
      // ]
    ]).headerRows(3).end
  );

  pdf.create().open();
}

/**
 * Informe de Gratificación a Pagar
 * @param response
 * @param data
 */
export function generarPDFGratificacionAPagar(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  // Detalle
  let detalle = [];

  let total = 0;

  response.forEach((item) => {
    detalle.push([
      new Cell(
        new Txt(`${item.cli_codcli} ${item.cli_nomcli}`).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_combas.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_asifam.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_alimen.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_otrrem.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_provar.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_comtot.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(
          `${item.detalle[0].nct_nummes}      ${numberWithCommas(
            item.detalle[0].nct_numdia
          )}`
        )
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_diaina))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_impmes.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,

      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_impdia.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_impbon.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.detalle[0].nct_impnet.toFixed(2)))
          .fontSize(6)
          .alignment('right').end
      ).border(noBorders).end,
      new Cell(new Txt('').fontSize(6).alignment('right').end).border(noBorders).end,
    ]);

    total += item.detalle[0].nct_impnet;
  });

  detalle.push([
    new Cell(new Txt('').bold().fontSize(9).end)
      .border(noBorders)
      .colSpan(10).end,
    ...obtenerNulls(9),
    new Cell(new Txt('TOTAL A PAGAR').alignment('right').bold().fontSize(6).end)
      .colSpan(2)
      .border(noBorders).end,
    ...obtenerNulls(1),
    new Cell(
      new Txt(numberWithCommas(total.toFixed(2)))
        .alignment('right')
        .bold()
        .fontSize(6).end
    ).border(topBorder).end,
    new Cell(new Txt('').alignment('right').bold().fontSize(6).end).border(noBorders).end,
  ]);
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).bold().fontSize(6).end,
            new Txt(headers.client).bold().fontSize(6).end,
            new Txt(headers.codePage).bold().fontSize(6).end,
          ]).end
        ).colSpan(2)
          .border(noBorders).end,
          ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('GRATIFICACIÓN A PAGAR')
              .alignment('center')
              .bold()
              .fontSize(9).end,
            new Txt(`PERIODO ${headers.year} ${headers.month}`)
              .alignment('center')
              .bold()
              .fontSize(8).end,
          ]).end
        )
          .border(noBorders)
          .colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').bold().fontSize(6).end,
          ]).end
        )
          .border(noBorders)
          .colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt('COLABORADOR').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2).end,
        new Cell(
          new Txt('CONCEPTOSS REMUNERATIVOS')
            .bold()
            .fontSize(6)
            .alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt('MESES / DÍAS COMPUTABLES')
            .bold()
            .fontSize(6)
            .alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2).end,
        new Cell(
          new Txt('DÍAS INASISTENCIA')
            .bold()
            .fontSize(6)
            .alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2).end,
        new Cell(
          new Txt('IMPORTE MESES').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2).end,
        new Cell(
          new Txt('IMPORTE DÍAS').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2).end,
        new Cell(
          new Txt('BONIFICACIÓN').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2).end,
        new Cell(
          new Txt('TOTAL A PAGAR').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2).end,
        new Cell(
          new Txt('APLICA EPS').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2).end,
      ],
      [
        ...obtenerNulls(1),
        new Cell(new Txt('BÁSICO').bold().fontSize(6).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('ASIG. FAM.').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('ALIMENTACIÓN').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('OTRAS REMUN.').bold().fontSize(6).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('PROMEDIO REM. VARIABLE')
            .bold()
            .fontSize(6)
            .alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('TOTAL').bold().fontSize(6).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        ...obtenerNulls(7),
      ],
      [
        new Cell(new Txt('MONEDA SOLES').bold().fontSize(7).end)
          .border(noBorders)
          .colSpan(14).end,
        ...obtenerNulls(13),
      ],
      ...detalle,
    ]).widths([170, 30, 30, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40]).end
  );

  pdf.create().open();
}

/**
 * Informe Resumen Planilla Liquidados
 * @param response
 * @param data
 */
export function generarPDFResumenPlanillaLiquidados(
  headers: HeaderReport,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  let totalIngresos = 0;
  let totalDeducciones = 0;
  let totalAportes = 0;

  response.forEach((item) => {
    if (item.nco_indtip == 1) {
      totalIngresos += item.total;
    } else if (item.nco_indtip == 2) {
      totalDeducciones += item.total;
    } else {
      totalAportes += item.total;
    }

    detalle.push([
      new Cell(new Txt(item.nco_descri).fontSize(8).bold().end).end,
      new Cell(
        new Txt(
          item.nco_indtip == 1
            ? numberWithCommas(item.total.toFixed(2))
            : '0.00'
        )
          .fontSize(10)
          .alignment('right').end
      ).end,
      new Cell(
        new Txt(
          item.nco_indtip == 2
            ? numberWithCommas(item.total.toFixed(2))
            : '0.00'
        )
          .fontSize(10)
          .alignment('right').end
      ).end,
      new Cell(
        new Txt(
          item.nco_indtip == 3
            ? numberWithCommas(item.total.toFixed(2))
            : '0.00'
        )
          .fontSize(10)
          .alignment('right').end
      ).end,
    ]);
  });

  detalle.push([
    new Cell(new Txt('Total').fontSize(10).bold().end).end,
    new Cell(
      new Txt(numberWithCommas(totalIngresos.toFixed(2)))
        .fontSize(10)
        .alignment('right')
        .bold().end
    ).end,
    new Cell(
      new Txt(numberWithCommas(totalDeducciones.toFixed(2)))
        .fontSize(10)
        .alignment('right')
        .bold().end
    ).end,
    new Cell(
      new Txt(numberWithCommas(totalAportes.toFixed(2)))
        .fontSize(10)
        .alignment('right')
        .bold().end
    ).end,
  ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(9).bold().end,
            new Txt(headers.client).fontSize(9).bold().end,
            new Txt(headers.codePage).fontSize(9).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('Resumen Planilla Liquidados')
              .fontSize(12)
              .bold()
              .alignment('center').end,
            new Txt(`PERÍODO ${headers.year} ${headers.month}`)
              .fontSize(9)
              .bold()
              .alignment('center').end,
          ]).end
        )
          .colSpan(2)
          .border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(9).bold().alignment('right').end,
            new Txt(headers.time).fontSize(9).bold().alignment('right').end,
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(new Txt('CONCEPTO').fontSize(10).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('INGRESOS').fontSize(10).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('DEDUCCIONES').fontSize(10).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('APORTES').fontSize(10).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
    ]).headerRows(2).widths([185, 110, 110, 110]).end
  );

  pdf.create().open();
}

/**
 * Informe de Planilla de Concepto Afecto AFP
 * @param response
 * @param data
 */
export function generarPDFPlanillaDeConceptoAfectoAFP(
  headers: HeaderReport,
  others,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');
  pdf.pageSize({ width: 1500, height: 1000 });

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  // ESTABLECER CABECERA GRUPOS
  let cabecerasTipo = [
    new Cell(new Txt('').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end,
    new Cell(new Txt('').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end,
    new Cell(new Txt('INGRESOS').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo1).end,
    ...obtenerNulls(response.groups.tipo1 - 1),
    new Cell(new Txt('DEDUCCIONES').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo2).end,
    ...obtenerNulls(response.groups.tipo2 - 1),
    new Cell(new Txt('Total').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .rowSpan(2).end,
    new Cell(new Txt('APORTES').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo3).end,
    ...obtenerNulls(response.groups.tipo3 - 1),
  ];

  // ESTABLECER CABECERAS
  let cabeceras = [];

  cabeceras.push(
    new Cell(new Txt('Código').alignment('center').bold().fontSize(6).end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end
  );
  cabeceras.push(
    new Cell(new Txt('Colaborador').alignment('center').bold().fontSize(6).end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end
  );

  response.headers.forEach((element, key) => {
    if (element.nco_indtip == 3 && response.headers[key - 1].nco_indtip == 2) {
      cabeceras.push(null);
      cabeceras.push(
        new Cell(
          new Txt(element.nco_descri).alignment('center').bold().fontSize(6).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end
      );
    } else {
      cabeceras.push(
        new Cell(
          new Txt(element.nco_descri).alignment('center').bold().fontSize(6).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end
      );
    }
  });

  // detalle
  let detalleGeneral = [];
  response.data.forEach((element) => {
    let detalle = [];
    detalle.push(
      new Cell(
        new Txt(`${element.cli_codcli}`).fontSize(6).alignment('left').end
      ).end
    );
    detalle.push(
      new Cell(
        new Txt(`${element.cli_nomcli}`).fontSize(6).alignment('left').end
      ).end
    );

    element.importes.forEach((it) => {
      detalle.push(
        new Cell(
          new Txt(numberWithCommas(it.toFixed(2)))
            .fontSize(6)
            .alignment('right').end
        ).end
      );
    });

    detalleGeneral.push(detalle);
  });

  // totales
  let totalesImp = [];
  response.data[0].importes.forEach((_) => totalesImp.push(0));

  response.data.forEach((item) => {
    totalesImp.forEach((it, key) => {
      totalesImp[key] += item.importes[key];
    });
  });

  let totales = [];
  totales.push(
    new Cell(new Txt('Totales').fontSize(7).bold().end).colSpan(2).end
  );
  totales.push(new Cell(new Txt('').fontSize(7).bold().end).end);

  totalesImp.forEach((item) => {
    totales.push(
      new Cell(
        new Txt(numberWithCommas(item.toFixed(2)))
          .fontSize(6)
          .bold()
          .alignment('right').end
      ).end
    );
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).end,
            new Txt(headers.client).fontSize(8).end,
            new Txt(headers.codePage).fontSize(8).end,
            new Txt('').fontSize(8).end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Stack([
            new Txt('\n').end,
            new Txt('PLANILLA DE CONCEPTOS AFECTO AFP')
              .alignment('center')
              .fontSize(10)
              .bold().end,
            new Columns([
              new Txt(`PERIODO ${headers.year} ${headers.month}`)
                .alignment('center')
                .fontSize(9)
                .bold().end,
              new Txt(`DEL 01/${headers.month}/${headers.year}`)
                .alignment('center')
                .fontSize(9)
                .bold().end,
              new Txt(`AL ${others.lastDay}/${headers.month}/${headers.year}`)
                .alignment('center')
                .fontSize(9)
                .bold().end,
            ]).end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(cabeceras.length - 2).end,
        ...obtenerNulls(cabeceras.length - 3),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').fontSize(8).end,
            new Txt(headers.time).alignment('right').fontSize(8).end,
            new Txt('').end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(new Txt('').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(cabeceras.length).end,
        ...obtenerNulls(cabeceras.length - 1),
      ],
      cabecerasTipo,
      cabeceras,
      ...detalleGeneral,
      totales,
    ]).headerRows(4).end
  );

  pdf.create().open();
}

/**
 * Informe Resumen de Planilla
 * @param response
 * @param data
 */
export function generarPDFResumenDePlanilla(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  let totalIngresos = 0;
  let totalDeducciones = 0;
  let totalAportes = 0;

  response.forEach((item) => {
    if (item.nco_indtip == 1) {
      totalIngresos += item.total;
    } else if (item.nco_indtip == 2) {
      totalDeducciones += item.total;
    } else {
      totalAportes += item.total;
    }

    detalle.push([
      new Cell(new Txt(item.nco_descri).fontSize(8).bold().end).end,
      new Cell(
        new Txt(
          item.nco_indtip == 1
            ? numberWithCommas(item.total.toFixed(2))
            : '0.00'
        )
          .fontSize(10)
          .alignment('right').end
      ).end,
      new Cell(
        new Txt(
          item.nco_indtip == 2
            ? numberWithCommas(item.total.toFixed(2))
            : '0.00'
        )
          .fontSize(10)
          .alignment('right').end
      ).end,
      new Cell(
        new Txt(
          item.nco_indtip == 3
            ? numberWithCommas(item.total.toFixed(2))
            : '0.00'
        )
          .fontSize(10)
          .alignment('right').end
      ).end,
    ]);
  });

  detalle.push([
    new Cell(new Txt('Total').fontSize(10).bold().end).end,
    new Cell(
      new Txt(numberWithCommas(totalIngresos.toFixed(2)))
        .fontSize(10)
        .alignment('right')
        .bold().end
    ).end,
    new Cell(
      new Txt(numberWithCommas(totalDeducciones.toFixed(2)))
        .fontSize(10)
        .alignment('right')
        .bold().end
    ).end,
    new Cell(
      new Txt(numberWithCommas(totalAportes.toFixed(2)))
        .fontSize(10)
        .alignment('right')
        .bold().end
    ).end,
  ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(9).bold().end,
            new Txt(headers.client).fontSize(8).end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('Resumen de Planilla')
              .fontSize(12)
              .bold()
              .alignment('center').end,
            new Txt(`PERÍODO ${headers.year} ${headers.month}`)
              .fontSize(9)
              .bold()
              .alignment('center').end,
          ]).end
        )
          .colSpan(2)
          .border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(9).bold().alignment('right').end,
            new Txt(headers.time).fontSize(9).bold().alignment('right').end,
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(new Txt('CONCEPTO').fontSize(10).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('INGRESOS').fontSize(10).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('DEDUCCIONES').fontSize(10).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt('APORTES').fontSize(10).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
    ]).headerRows(2).widths([185, 110, 110, 110]).end
  );

  pdf.create().open();
}

/**
 * Informe Planilla Lineal Nómina
 * @param response
 * @param data
 */
export function generarPDFPlanillaLinealNomina(
  headers: HeaderReport,
  others,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');
  pdf.pageSize({ width: 1500, height: 1000 });

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], ffontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  // ESTABLECER CABECERA GRUPOS
  let cabecerasTipo = [
    new Cell(new Txt('Código').alignment('center').bold().fontSize(6).end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end,
    new Cell(new Txt('Colaborador').alignment('center').bold().fontSize(6).end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end,
    new Cell(new Txt('INGRESOS').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo1).end,
    ...obtenerNulls(response.groups.tipo1 - 1),
    new Cell(new Txt('DEDUCCIONES').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo2).end,
    ...obtenerNulls(response.groups.tipo2 - 1),
    new Cell(new Txt('Total').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .rowSpan(2).end,
    new Cell(new Txt('APORTES').fontSize(10).bold().alignment('center').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .colSpan(response.groups.tipo3).end,
    ...obtenerNulls(response.groups.tipo3 - 1),
  ];

  // ESTABLECER CABECERAS

  let cabeceras = [];

  cabeceras.push(
    new Cell(new Txt('').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end
  );

  cabeceras.push(
    new Cell(new Txt('').end)
      .color(PavsoColors.White)
      .fillColor(PavsoColors.Blue)
      .border(noBorders).end
  );

  response.headers.forEach((element, key) => {
    if (element.nco_indtip == 3 && response.headers[key - 1].nco_indtip == 2) {
      cabeceras.push(null);
      cabeceras.push(
        new Cell(
          new Txt(element.nco_descri).alignment('center').bold().fontSize(6).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end
      );
    } else {
      cabeceras.push(
        new Cell(
          new Txt(element.nco_descri).alignment('center').bold().fontSize(6).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end
      );
    }
  });

  // detalle
  let detalleGeneral = [];
  response.data.forEach((element) => {
    let detalle = [];
    detalle.push(
      new Cell(
        new Txt(`${element.cli_codcli}`).fontSize(6).alignment('left').end
      ).end
    );
    detalle.push(
      new Cell(
        new Txt(`${element.cli_nomcli}`).fontSize(6).alignment('left').end
      ).end
    );

    element.importes.forEach((it) => {
      detalle.push(
        new Cell(
          new Txt(numberWithCommas(it.toFixed(2)))
            .fontSize(6)
            .alignment('right').end
        ).end
      );
    });

    detalleGeneral.push(detalle);
  });

  // totales
  let totalesImp = [];
  response.data[0].importes.forEach((_) => totalesImp.push(0));

  response.data.forEach((item) => {
    totalesImp.forEach((it, key) => {
      totalesImp[key] += item.importes[key];
    });
  });

  let totales = [];
  totales.push(
    new Cell(new Txt('Totales').fontSize(7).bold().end).colSpan(2).end
  );

  totales.push(new Cell(new Txt('').fontSize(7).bold().end).end);

  totalesImp.forEach((item) => {
    totales.push(
      new Cell(
        new Txt(numberWithCommas(item.toFixed(2)))
          .fontSize(6)
          .bold()
          .alignment('right').end
      ).end
    );
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).end,
            new Txt(headers.client).fontSize(8).end,
            new Txt(headers.codePage).fontSize(8).end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Stack([
            new Txt('\n').end,
            new Txt('PLANILLA LINEAL NÓMINA').alignment('center').fontSize(10).bold()
              .end,
            new Columns([
              new Txt(`PERIODO ${headers.year} ${headers.month}`)
                .alignment('center')
                .fontSize(9)
                .bold().end,
              new Txt(`SECUENCIA:  ${others.npe}`)
                .alignment('center')
                .fontSize(9)
                .bold().end,
              new Txt(``)
                .alignment('center')
                .fontSize(9)
                .bold().end,
            ]).end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(cabeceras.length - 2).end,
        ...obtenerNulls(cabeceras.length - 3),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').fontSize(8).end,
            new Txt(headers.time).alignment('right').fontSize(8).end,
            new Txt('').end,
          ]).end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(new Txt('').end).colSpan(cabeceras.length).end,
        ...obtenerNulls(cabeceras.length - 1),
      ],
      cabecerasTipo,
      cabeceras,
      ...detalleGeneral,
      totales,
    ]).headerRows(4).end
  );

  pdf.create().open();
}

/**
 * Informe Planilla Quincenal
 * @param response
 * @param data
 */
export function generarPDFPlanillaQuincenal(headers: HeaderReport, response) {}

/**
 * Informe Historial Remuneracion Colaborador
 * @param response
 * @param data
 */
export function generarPDFHistorialRemuneracionColaborador(
  headers: HeaderReport,
  others,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  let totalGeneral = 0;

  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt('COLABORADOR').fontSize(8).bold().end)
        .border(bottomBorder)
        .colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(new Txt(item.codcli).fontSize(8).end).border(bottomBorder).end,
      new Cell(new Txt(item.nomcli).fontSize(8).end)
        .border(bottomBorder)
        .colSpan(6).end,
      ...obtenerNulls(5),
    ]);

    let totalTrabajador = 0;

    item.detalle.forEach((element) => {
      detalle.push([
        new Cell(new Txt(element.codnco).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt(element.desnco).fontSize(8).end)
          .border(noBorders)
          .colSpan(3).end,
        new Cell(new Txt('').end).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`${element.codano}-${element.codmes}`).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.cannut).fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.codnut).fontSize(8).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.imprem.toFixed(2)))
            .fontSize(8)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(new Txt('').end).border(noBorders).end,
      ]);

      totalTrabajador += element.imprem;
    });

    detalle.push([
      new Cell(new Txt('').end).border(noBorders).colSpan(3)
        .end,
      ...obtenerNulls(2),
      new Cell(new Txt('TOTAL COLABORADOR:').fontSize(8).bold().end).border(noBorders).end,
      new Cell(
        new Txt(item.totalReg).fontSize(8).bold().alignment('right').end
      ).border(topBorder).end,
      new Cell(new Txt('').end).border(noBorders).colSpan(2)
        .end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(totalTrabajador.toFixed(2)))
          .fontSize(8)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas((totalTrabajador / item.totalReg).toFixed(2)))
          .fontSize(8)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
    ]);

    totalGeneral += totalTrabajador;
  });

  detalle.push([
    new Cell(new Txt('').end).border(noBorders).colSpan(3)
      .end,
    ...obtenerNulls(2),
    new Cell(new Txt('TOTAL GENERAL:').fontSize(8).bold().end)
      .border(noBorders)
      .colSpan(2).end,
    new Cell(new Txt('').alignment('right').end).border(noBorders).end,
    new Cell(new Txt('').end).border(noBorders).colSpan(2)
      .end,
    ...obtenerNulls(1),
    new Cell(
      new Txt(numberWithCommas(totalGeneral.toFixed(2)))
        .fontSize(8)
        .bold()
        .alignment('right').end
    ).border(topBorder).end,
    new Cell(new Txt('').end).border(noBorders).end,
  ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.client).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt('Historial de Remuneración por Colaborador')
              .alignment('center')
              .fontSize(10)
              .bold().end,
            new Txt(
              `Rango de Período ${others.periodoini}   ${others.periodofin}`
            )
              .alignment('center')
              .fontSize(8)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().end,
            new Txt(headers.time).fontSize(8).bold().end,
          ]).end
        ).colSpan(2)
          .alignment('right')
          .border(noBorders).end,
          ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('').end).border(noBorders).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).colSpan(2)
          .end,
        ...obtenerNulls(1),
        new Cell(new Txt('CONCEPTO').alignment('center').end)
          .border(noBorders)
          .fontSize(8)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .bold().end,
        new Cell(new Txt('').end)
          .border(noBorders)
          .fontSize(8)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .bold().end,
        new Cell(new Txt('PERIODO').alignment('center').end)
          .border(noBorders)
          .fontSize(8)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .bold().end,
        new Cell(new Txt('TIEMPO').alignment('center').end)
          .border(noBorders)
          .fontSize(8)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .bold().end,
        new Cell(new Txt('UNIDAD').alignment('center').end)
          .border(noBorders)
          .fontSize(8)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .bold().end,
        new Cell(new Txt('IMPORTE').alignment('center').end)
          .border(noBorders)
          .fontSize(8)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .bold().end,
        new Cell(new Txt('PROMEDIO').alignment('center').end)
          .border(noBorders)
          .fontSize(8)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .bold().end,
      ],
      ...detalle,
    ])
      .headerRows(2)
      .widths([15, 50, 50, 110, 50, 50, 35, 55, 55]).end
  );

  pdf.create().open();
}

/**
 * Informe Cambios Remuneración Período
 * @param response
 * @param data
 */
export function generarPDFCambiosRemuneracionPeriodo(
  headers: HeaderReport,
  response
) {

  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, margin: [ 10, 10, 20, 20 ], alignment: 'right', fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt('COLABORADOR').fontSize(8).bold().end)
        .border(noBorders)
        .colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(new Txt(item.cli_codcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.cli_nomcli).fontSize(8).end)
        .border(noBorders)
        .colSpan(4).end,
      ...obtenerNulls(3),
    ]);

    item.detalle.forEach((element) => {
      detalle.push([
        new Cell(new Txt(element.nco_codnco).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt(element.nco_descri).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`${element.ano_codano}-${element.mes_codmes}`)
            .fontSize(8)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.imprem_nue.toFixed(2)))
            .fontSize(8)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.imprem_ant.toFixed(2)))
            .fontSize(8)
            .alignment('right').end
        ).border(noBorders).end,
      ]);
    });
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.client).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Cambios de Remuneración por Período')
              .alignment('center')
              .fontSize(10)
              .bold().end,
            new Txt(`Período ${headers.year} / ${headers.month}`)
              .alignment('center')
              .fontSize(8)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().end,
            new Txt(headers.time).fontSize(8).bold().end,
          ]).end
        )
          .alignment('right')
          .border(noBorders).end,
      ],
      [
        new Cell(new Txt('').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('CONCEPTO').alignment('center').fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('PERÍODO').alignment('right').fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('ACTUAL').alignment('right').fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('ANTERIOR').alignment('right').fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
      ],
      ...detalle,
    ])
      .headerRows(2)
      .widths([25, 60, 50, 130, 75, 75, 75]).end
  );

  pdf.create().open();
}

/**
 * Informe Cuentas Banco Colaborador
 * @param response
 * @param data
 */
export function generarPDFCuentasBancoColaborador(
  headers: HeaderReport,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, margin: [ 10, 10, 20, 20 ], alignment: 'right', fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, ffontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  response.forEach((element) => {
    detalle.push([
      new Cell(
        new Txt(element.cli_codcli).alignment('center').fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).alignment('left').fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.TMO_DESCTS).alignment('center').fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.BAN_NOMCTS).alignment('center').fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.trp_ctacts).alignment('center').fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.TMO_DESHAB).alignment('center').fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.BAN_NOMHAB).alignment('center').fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.trp_ctahab).alignment('center').fontSize(8).end
      ).border(noBorders).end,
    ]);
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.client).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        ).colSpan(2)
          .border(noBorders).end,
          ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Cuentas de Banco por Colaborador')
              .alignment('center')
              .fontSize(10)
              .bold().end,
            new Txt(`Período ${headers.year} ${headers.month}`)
              .alignment('center')
              .fontSize(8)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().end,
            new Txt(headers.time).fontSize(8).bold().end,
          ]).end
        ).colSpan(3)
          .alignment('right')
          .border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('DNI').alignment('center').fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(
          new Txt('COLABORADOR').alignment('center').fontSize(8).bold().end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(
          new Txt('MONEDA CTS').alignment('center').fontSize(8).bold().end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(
          new Txt('BANCO CTS').alignment('center').fontSize(8).bold().end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(
          new Txt('NRO. CUENTA CTS').alignment('center').fontSize(8).bold().end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(
          new Txt('MONEDA HABER').alignment('center').fontSize(8).bold().end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(
          new Txt('BANCO HABER').alignment('center').fontSize(8).bold().end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(
          new Txt('NRO. CUENTA HABER')
            .alignment('center')
            .fontSize(8)
            .bold().end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
      ],
      ...detalle,
    ]).headerRows(2).widths([50, 210, 60, 80, 70, 60, 85, 70]).end
  );

  pdf.create().open();
}

/**
 * Informe Cuentas Banco Colaborador CTS
 */
export function generarPDFCuentasBancoColaboradorCTS(
  headers: HeaderReport,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.client).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Cuentas de Banco por Colaborador - CTS')
              .alignment('center')
              .fontSize(10)
              .bold().end,
            new Txt(`Período ${headers.year} ${headers.month}`)
              .alignment('center')
              .fontSize(8)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().end,
            new Txt(headers.time).end,
          ]).end
        )
          .alignment('right')
          .border(noBorders).end,
      ],
      [
        new Cell(new Txt('CÓDIGO').fontSize(8).bold().end)
          .bold()
          .border(bottomBorder)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('COLABORADOR').fontSize(8).bold().end)
          .bold()
          .border(bottomBorder).end,
        new Cell(new Txt('MONEDA').fontSize(8).bold().end)
          .bold()
          .border(bottomBorder).end,
        new Cell(new Txt('NRO. CUENTA').fontSize(8).bold().end)
          .bold()
          .border(bottomBorder).end,
      ],
      [
        new Cell(new Txt('BANCO').fontSize(8).bold().end)
          .bold()
          .border(noBorders).end,
        new Cell(new Txt('SCOTIABANK').fontSize(8).bold().end)
          .bold()
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('').fontSize(8).bold().end)
          .bold()
          .border(noBorders).end,
        new Cell(new Txt('').fontSize(8).bold().end)
          .bold()
          .border(noBorders).end,
      ],
      [
        new Cell(new Txt('10272469').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('10272469').fontSize(8).end).border(noBorders).end,
        new Cell(
          new Txt('ARAMAYO QUINECHE PILAR SOLEDAD').fontSize(8).end
        ).border(noBorders).end,
        new Cell(new Txt('SOLES').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0449060268').fontSize(8).end).border(noBorders).end,
      ],
    ]).widths([70, 70, 220, 70, 80]).end
  );
  pdf.create().open();
}

/**
 * Informe Cuentas Banco Colaborador Haber
 * @param response
 * @param data
 */
export function generarPDFCuentasBancoColaboradorHaber(
  headers: HeaderReport,
  response
) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.client).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Cuentas de Banco por Colaborador - CTS')
              .alignment('center')
              .fontSize(10)
              .bold().end,
            new Txt(`Período ${headers.year} ${headers.month}`)
              .alignment('center')
              .fontSize(8)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().end,
            new Txt(headers.time).end,
          ]).end
        )
          .alignment('right')
          .border(noBorders).end,
      ],
      [
        new Cell(new Txt('CÓDIGO').fontSize(8).bold().end)
          .bold()
          .border(bottomBorder)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('COLABORADOR').fontSize(8).bold().end)
          .bold()
          .border(bottomBorder).end,
        new Cell(new Txt('MONEDA').fontSize(8).bold().end)
          .bold()
          .border(bottomBorder).end,
        new Cell(new Txt('NRO. CUENTA').fontSize(8).bold().end)
          .bold()
          .border(bottomBorder).end,
      ],
      [
        new Cell(new Txt('BANCO').fontSize(8).bold().end)
          .bold()
          .border(noBorders).end,
        new Cell(new Txt('SCOTIABANK').fontSize(8).bold().end)
          .bold()
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('').fontSize(8).bold().end)
          .bold()
          .border(noBorders).end,
        new Cell(new Txt('').fontSize(8).bold().end)
          .bold()
          .border(noBorders).end,
      ],
      [
        new Cell(new Txt('10272469').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('10272469').fontSize(8).end).border(noBorders).end,
        new Cell(
          new Txt('ARAMAYO QUINECHE PILAR SOLEDAD').fontSize(8).end
        ).border(noBorders).end,
        new Cell(new Txt('SOLES').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0449060268').fontSize(8).end).border(noBorders).end,
      ],
    ]).widths([70, 70, 220, 70, 80]).end
  );

  pdf.create().open();
}

/**
 * Informe de Control Vacacional
 * @param response
 * @param data
 */
export function generarPDFControlVacacional(headers: HeaderReport, response) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt(item.cli_codcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.cli_nomcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.cli_codcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt('').end).border(noBorders).colSpan(10)
        .end,
      ...obtenerNulls(9),
    ]);

    item.detalle.forEach((el) => {
      let diasVacGanados = el.mcv_vacgan;
      detalle.push([
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(el.mcv_permcv).fontSize(8).bold().end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Txt(`Días vac. ganados: ${el.mcv_vacgan}`).fontSize(8).bold().alignment('right').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ])
      el.detalle.forEach((ele) => {
        diasVacGanados -= ele.nav_diavac;
        detalle.push([
          new Cell(new Txt('').end)
            .border(noBorders)
            .colSpan(3).end,
          ...obtenerNulls(2),
          new Cell(
            new Txt(formatDateWithSlash(ele.mcv_fecing)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(new Txt(ele.mcv_venvac).fontSize(8).end).alignment('center').border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(ele.mcv_fecini)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(ele.mcv_fecfin)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(new Txt(ele.mcv_permcv).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(ele.trp_vacini)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(ele.trp_vacfin)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(ele.ano_codano).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(ele.mes_codmes).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(ele.nav_diavac).fontSize(8).alignment('right').end
          ).border(noBorders).end,
        ]);
      });
      detalle.push([
        new Cell(new Txt('').fontSize(8).end)
          .border(noBorders)
          .colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(new Txt('SALDO DEL PERÍODO').fontSize(8).end)
          .border(topBorder)
          .bold()
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt(el.mcv_permcv).end).bold().fontSize(8).border(topBorder).end,
        new Cell(new Txt('').bold().fontSize(8).end).border(topBorder).end,
        new Cell(new Txt(diasVacGanados).alignment('right').bold().fontSize(8).end).border(topBorder).end,
      ]);
    });

    detalle.push([
      new Cell(new Txt('').fontSize(8).end)
        .border(noBorders)
        .colSpan(8).end,
      ...obtenerNulls(7),
      new Cell(new Txt('SALDO DEL COLABORADOR').fontSize(8).end)
        .border(topBorder)
        .bold()
        .colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(new Txt('').end).border(noBorders).end,
      new Cell(new Txt('').bold().fontSize(8).end).border(topBorder).end,
      new Cell(new Txt('0').bold().alignment('right').fontSize(8).end).border(topBorder).end,
    ]);
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Control Vacacional')
              .alignment('center')
              .fontSize(10)
              .bold().end,
            new Txt(`${headers.year} ${headers.month}`)
              .alignment('center')
              .fontSize(8)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt(`Hora: ${headers.date}`)
              .alignment('right')
              .fontSize(8)
              .bold().end,
            new Txt(`Fecha: ${headers.time}`)
              .alignment('right')
              .fontSize(8)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('CÓDIGO').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold()
          .alignment('center').end,
        new Cell(new Txt('APELLIDOS Y NOMBRES').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('DOCUMENTO DE IDENTIDAD').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('FEC. INGRESO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('VENTA DE VACACIONES').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('PERÍODO VACACIONAL GANADO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(3)
          .fontSize(7)
          .bold().end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(
            'PERÍODO DEL DESCANSO EN QUE REDUJO SUS VACACIONES(3)'
          ).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(2)
          .fontSize(7)
          .bold().end,
        ...obtenerNulls(1),
        new Cell(new Txt('PERÍODO VACACIONAL PAGADO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(3)
          .fontSize(7)
          .bold().end,
        ...obtenerNulls(2),
      ],
      [
        ...obtenerNulls(5),
        new Cell(new Txt('DEL').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('AL').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('AÑO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('DEL').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('AL').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('AÑO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('MES').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('DÍAS').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
      ],
      ...detalle,
    ])
      .headerRows(3)
      .widths([45, 160, 45, 48, 45, 43, 43, 43, 43, 43, 43, 43, 43]).end
  );

  pdf.create().open();
}

/**
 * Informe Control Vacacional Fisico
 * @param response
 * @param data
 */
export async function generarPDFControlVacacionalFisico(
  headers: HeaderReport,
  response
) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 15, 15 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  response.forEach((item) => {
    detalle.push([
      new Cell(new Txt(item.cli_codcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.cli_nomcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt(item.cli_codcli).fontSize(8).end).border(noBorders).end,
      new Cell(new Txt('').end).border(noBorders).colSpan(11)
        .end,
      ...obtenerNulls(10),
    ]);

    item.detalle.forEach((el) => {
      let diasVacGanados = el.mcv_vacgan;
      detalle.push([
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(el.mcv_permcv).fontSize(8).bold().end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8),
        new Cell(
          new Txt(`Días vac. ganados: ${el.mcv_vacgan}`).fontSize(8).bold().alignment('right').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ])
      el.detalle.forEach((ele) => {
        diasVacGanados -= ele.nav_diavac;
        detalle.push([
          new Cell(new Txt('').end)
            .border(noBorders)
            .colSpan(3).end,
          ...obtenerNulls(2),
          new Cell(
            new Txt(formatDateWithSlash(ele.mcv_fecing)).fontSize(7).end
          ).border(noBorders).end,
          new Cell(new Txt(ele.mcv_venvac).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(ele.mcv_fecini)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(ele.mcv_fecfin)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(new Txt(ele.mcv_permcv).fontSize(8).end).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(ele.trp_vacini)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(ele.trp_vacfin)).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(ele.ano_codano).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(ele.mes_codmes).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(ele.nav_diavac).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(ele.nav_diafis).fontSize(8).alignment('right').end
          ).border(noBorders).end,
        ]);
      });
      detalle.push([
        new Cell(new Txt('').fontSize(8).end)
          .border(noBorders)
          .colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(new Txt('SALDO DEL PERÍODO').fontSize(8).end)
          .border(topBorder)
          .bold()
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt(el.mcv_permcv).fontSize(8).end).border(topBorder).end,
        new Cell(new Txt('').bold().fontSize(8).end).border(topBorder).end,
        new Cell(new Txt(diasVacGanados).bold().fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt(diasVacGanados).bold().fontSize(8).alignment('right').end).border(topBorder).end,
      ]);
    });

    detalle.push([
      new Cell(new Txt('').fontSize(8).end)
        .border(noBorders)
        .colSpan(8).end,
      ...obtenerNulls(7),
      new Cell(new Txt('SALDO DEL COLABORADOR').fontSize(8).end)
        .border(topBorder)
        .bold()
        .colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(new Txt('').end).border(noBorders).end,
      new Cell(new Txt('').bold().fontSize(8).end).border(topBorder).end,
      new Cell(new Txt('0').bold().alignment('right').fontSize(8).end).border(topBorder).end,
      new Cell(new Txt('0').bold().alignment('right').fontSize(8).end).border(topBorder).end,
    ]);
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Control Vacaciones Físicas')
              .alignment('center')
              .fontSize(10)
              .bold().end,
            new Txt(`${headers.year} ${headers.month}`)
              .alignment('center')
              .fontSize(8)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(10).end,
        ...obtenerNulls(9),
        new Cell(
          new Stack([
            new Txt(`Hora: ${headers.date}`)
              .fontSize(8)
              .bold()
              .alignment('right').end,
            new Txt(`Fecha: ${headers.time}`)
              .fontSize(8)
              .bold()
              .alignment('right').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('CÓDIGO').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold()
          .alignment('center').end,
        new Cell(new Txt('APELLIDOS Y NOMBRES').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('DOC. IDENTIDAD').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('FECHA DE INGRESO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('VENTA DE VACACIONES').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .rowSpan(2)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('PERÍODO VACACIONAL GANADO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(3)
          .fontSize(7)
          .bold().end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(
            'PERÍODO DEL DESCANSO EN QUE REDUJO SUS VACACIONES(3)'
          ).alignment('center').end
        )
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(2)
          .fontSize(7)
          .bold().end,
        ...obtenerNulls(1),
        new Cell(new Txt('PERÍODO VACACIONAL PAGADO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .colSpan(4)
          .fontSize(7)
          .bold().end,
        ...obtenerNulls(3),
      ],
      [
        ...obtenerNulls(5),
        new Cell(new Txt('DEL').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('AL').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('AÑO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('DEL').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('AL').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('AÑO').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('MES').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('DÍAS').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
        new Cell(new Txt('FÍSICOS').alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .fontSize(7)
          .bold().end,
      ],
      ...detalle,
    ])
      .headerRows(3)
      .widths([40, 140, 45, 40, 50, 43, 43, 30, 43, 43, 40, 40, 40, 40]).end
  );

  pdf.create().open();
}

/**
 * Informe Acumulados Concepto Colaborador
 * @param response
 * @param data
 */
export async function generarPDFDiasEfectivosLaborales(
  headers: HeaderReport,
  response
) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  let [
    tEne,
    tFeb,
    tMar,
    tAbr,
    tMay,
    tJun,
    tJul,
    tAgo,
    tSet,
    tOct,
    tNov,
    tDic,
    tTotal,
  ] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  response.forEach((item) => {
    const total =
      item.efe_ene +
      item.efe_feb +
      item.efe_mar +
      item.efe_abr +
      item.efe_may +
      item.efe_jun +
      item.efe_jul +
      item.efe_ago +
      item.efe_set +
      item.efe_oct +
      item.efe_nov +
      item.efe_dic;
    tEne += item.efe_ene;
    tFeb += item.efe_feb;
    tMar += item.efe_mar;
    tAbr += item.efe_abr;
    tMay += item.efe_may;
    tJun += item.efe_jun;
    tJul += item.efe_jul;
    tAgo += item.efe_ago;
    tSet += item.efe_set;
    tOct += item.efe_oct;
    tNov += item.efe_nov;
    tDic += item.efe_dic;
    tTotal += total;
    detalle.push([
      new Cell(new Txt(item.cli_codcli).fontSize(7).end).border(bottomBorder).end,
      new Cell(new Txt(item.cli_nomcli).fontSize(7).end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_ene).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_feb).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_mar).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_abr).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_may).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_jun).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_jul).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_ago).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_set).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_oct).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_nov).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(item.efe_dic).fontSize(7).alignment('right').end).border(bottomBorder).end,
      new Cell(new Txt(total).bold().fontSize(7).alignment('right').end).border(bottomBorder).end,
    ]);
  });

  detalle.push([
    new Cell(new Txt('TOTALES').bold().fontSize(7).end).border(bottomBorder).end,
    new Cell(new Txt('').bold().fontSize(7).end).border(bottomBorder).end,
    new Cell(
      new Txt(tEne.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tFeb.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tMar.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tAbr.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tMay.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tJun.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tJul.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tAgo.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tSet.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tOct.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tNov.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tDic.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(tTotal.toString()).bold().fontSize(7).alignment('right').end
    ).border(horizontalBorder).end,
  ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('REPORTE DE DIAS EFECTIVOS LABORADOS')
              .fontSize(10)
              .bold()
              .alignment('center').end,
            new Txt(`EJERCICIO ${headers.year}`)
              .fontSize(8)
              .bold()
              .alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(9).end,
        ...obtenerNulls(8),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().alignment('right').end,
            new Txt(headers.time).fontSize(8).bold().alignment('right').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(new Txt('CÓDIGO').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('COLABORADOR').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('ENE.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('FEB.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('MAR.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('ABR.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('MAY.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('JUN.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('JUL.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('AGO.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('SET.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('OCT.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('NOV.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('DIC.').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('TOTAL').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
      ],
      ...detalle,
    ])
      .headerRows(2)
      .widths([65, 210, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30]).end
  );
  pdf.create().open();
}

/**
 * Informe Acumulados Concepto Colaborador
 * @param response
 * @param data
 */
export async function generarPDFAcumuladosConceptoColaborador(
  headers: HeaderReport,
  others,
  response
) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];
  response.forEach((item) => {
    detalle.push([
      new Cell(
        new Txt(`COLABORADOR    ${item.cli_codcli}    ${item.cli_nomcli}`)
          .bold()
          .fontSize(8).end
      )
        .border(noBorders)
        .colSpan(9).end,
      ...obtenerNulls(8),
    ]);

    let totalImporteGeneral = 0;
    item.detalle.forEach((el) => {
      detalle.push([
        new Cell(new Txt(el.nco_codnco).bold().fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').bold().fontSize(8).end)
          .border(noBorders)
          .colSpan(8).end,
        ...obtenerNulls(7),
      ]);

      let totalImporte = 0;
      el.detalle.forEach((element) => {
        detalle.push([
          new Cell(
            new Txt(`${element.ano_codano}-${element.mes_codmes}`).fontSize(
              8
            ).end
          ).border(noBorders).end,
          new Cell(new Txt(el.nco_descri).fontSize(8).end).border(noBorders).colSpan(2).end,
          new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.nut_codnut).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_cannut).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_canhrs).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_canmin).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element.npc_impnpc.toFixed(2)))
              .fontSize(8)
              .alignment('right').end
          ).border(noBorders).end,
          new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        ]);
        totalImporte += element.npc_impnpc;
      });

      detalle.push([
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(
          new Txt(el.detalle.length).fontSize(8).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalImporte.toFixed(2)))
            .fontSize(8)
            .alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(
            numberWithCommas((totalImporte / el.detalle.length).toFixed(2))
          )
            .fontSize(8)
            .alignment('right').end
        ).border(noBorders).end,
      ]);

      totalImporteGeneral += totalImporte;
    });

    detalle.push([
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('TOTAL COLABORADOR: ').fontSize(8).end).border(noBorders).end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(
        new Txt(item.totalPeriodos).fontSize(8).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalImporteGeneral.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(openLeftBorder).end,
      new Cell(
        new Txt(
          `PROM(/${item.totalPeriodos})   ` +
            numberWithCommas(
              (totalImporteGeneral / item.totalPeriodos).toFixed(2)
            )
        )
          .fontSize(8)
          .alignment('right').end
      ).border(openLeftBorder).end,
    ]);
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).bold().fontSize(7).end,
            new Txt(headers.codePage).bold().fontSize(7).end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Acumulado por Conceptos Colaborador')
              .alignment('center')
              .bold()
              .fontSize(10).end,
            new Txt(
              `RANGO DE PERIODO ${others.periodoini}  -  ${others.periodofin}`
            )
              .bold()
              .fontSize(8)
              .alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt(headers.date).bold().fontSize(7).alignment('right').end,
            new Txt(headers.time).bold().fontSize(7).alignment('right').end,
          ]).end
        ).colSpan(2)
        .border(noBorders).end,
        null
      ],
      [
        new Cell(new Txt('PERIODO').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('CONCEPTO').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('UM').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('CANTIDAD').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('HORAS').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('MINUTOS').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('IMPORTE').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('PROMEDIO').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
      ],
      ...detalle,
    ]).headerRows(2).widths([40, 85, 50, 50, 50, 50, 50, 50, 50]).end
  );

  pdf.create().open();

}

/**
 * Informe Acumulados Colaborador Concepto
 * @param response
 * @param data
 */
export async function generarPDFAcumuladosColaboradorConcepto(
  headers: HeaderReport,
  others,
  response
) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];
  response.forEach((item) => {
    detalle.push([
      new Cell(
        new Txt(`CONCEPTO    ${item.nco_codnco}    ${item.nco_descri}`)
          .bold()
          .fontSize(8).end
      )
        .border(noBorders)
        .colSpan(9).end,
      ...obtenerNulls(8),
    ]);

    let totalImporteGeneral = 0;
    item.detalle.forEach((el) => {
      detalle.push([
        new Cell(new Txt(el.cli_codcli).bold().fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').bold().fontSize(8).end)
          .border(noBorders)
          .colSpan(8).end,
        ...obtenerNulls(7),
      ]);

      let totalImporte = 0;
      el.detalle.forEach((element) => {
        detalle.push([
          new Cell(
            new Txt(`${element.ano_codano}-${element.mes_codmes}`).fontSize(
              8
            ).end
          ).border(noBorders).end,
          new Cell(new Txt(el.cli_nomcli).fontSize(7).end).border(noBorders).colSpan(2).end,
          new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.nut_codnut).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_cannut).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_canhrs).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_canmin).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element.npc_impnpc.toFixed(2)))
              .fontSize(8)
              .alignment('right').end
          ).border(noBorders).end,
          new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        ]);
        totalImporte += element.npc_impnpc;
      });

      detalle.push([
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(
          new Txt(el.detalle.length).fontSize(8).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalImporte.toFixed(2)))
            .fontSize(8)
            .alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(
            numberWithCommas((totalImporte / el.detalle.length).toFixed(2))
          )
            .fontSize(8)
            .alignment('right').end
        ).border(noBorders).end,
      ]);

      totalImporteGeneral += totalImporte;
    });

    detalle.push([
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('TOTAL CONCEPTO: ').fontSize(8).end).border(noBorders).end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(
        new Txt(item.totalPeriodos).fontSize(8).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalImporteGeneral.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(openLeftBorder).end,
      new Cell(
        new Txt(
          `PROM(/${item.totalPeriodos})   ` +
            numberWithCommas(
              (totalImporteGeneral / item.totalPeriodos).toFixed(2)
            )
        )
          .fontSize(8)
          .alignment('right').end
      ).border(openLeftBorder).end,
    ]);
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).bold().fontSize(7).end,
            new Txt(headers.codePage).bold().fontSize(7).end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Acumulado por colaborador concepto')
              .alignment('center')
              .bold()
              .fontSize(10).end,
            new Txt(
              `RANGO DE PERIODO ${others.periodoini}  -  ${others.periodofin}`
            )
              .bold()
              .fontSize(8)
              .alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt(headers.date).bold().fontSize(7).alignment('right').end,
            new Txt(headers.time).bold().fontSize(7).alignment('right').end,
          ]).end
        ).colSpan(2)
        .border(noBorders).end,
        null
      ],
      [
        new Cell(new Txt('PERIODO').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('COLABORADOR').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('UM').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('CANTIDAD').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('HORAS').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('MINUTOS').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('IMPORTE').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('PROMEDIO').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
      ],
      ...detalle,
    ]).widths([40, 120, 35, 40, 40, 50, 50, 50, 50]).end
  );

  pdf.create().open();

}

/**
 * Informe Acumulados Concepto Colaborador Anual
 * @param response
 * @param data
 */
export async function generarPDFAcumuladosConceptoColaboradorAnual(
  headers: HeaderReport,
  response
) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  response.forEach((item) => {
    detalle.push([
      new Cell(
        new Txt(`${item.cli_codcli} ${item.cli_nomcli}`).bold().fontSize(8).end
      )
        .border(noBorders)
        .colSpan(15).end,
      ...obtenerNulls(14),
    ]);

    let [
      totalGen,
      tEne,
      tFeb,
      tMar,
      tAbr,
      tMay,
      tJun,
      tJul,
      tAgo,
      tSet,
      tOct,
      tNov,
      tDic,
    ] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    item.detalle.forEach((el) => {
      const total =
        el.npc_impnpc_ene +
        el.npc_impnpc_feb +
        el.npc_impnpc_mar +
        el.npc_impnpc_abr +
        el.npc_impnpc_may +
        el.npc_impnpc_jun +
        el.npc_impnpc_jul +
        el.npc_impnpc_ago +
        el.npc_impnpc_set +
        el.npc_impnpc_oct +
        el.npc_impnpc_nov +
        el.npc_impnpc_dic;
      totalGen += total;
      tEne += el.npc_impnpc_ene;
      tFeb += el.npc_impnpc_feb;
      tMar += el.npc_impnpc_mar;
      tAbr += el.npc_impnpc_abr;
      tMay += el.npc_impnpc_may;
      tJun += el.npc_impnpc_jun;
      tJul += el.npc_impnpc_jul;
      tAgo += el.npc_impnpc_ago;
      tSet += el.npc_impnpc_set;
      tOct += el.npc_impnpc_oct;
      tNov += el.npc_impnpc_nov;
      tDic += el.npc_impnpc_dic;
      detalle.push([
        new Cell(new Txt(el.nco_codnco).fontSize(7).end).border(noBorders).end,
        new Cell(new Txt(el.nco_descri).fontSize(7).end).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_ene.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_feb.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_mar.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_abr.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_may.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_jun.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_jul.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_ago.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_set.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_oct.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_nov.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.npc_impnpc_dic.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(total.toFixed(2)))
            .fontSize(7)
            .alignment('right').end
        ).border(noBorders).end,
      ]);
    });

    detalle.push([
      new Cell(new Txt('').end).border(noBorders).end,
      new Cell(new Txt(item.cli_nomcli).fontSize(7).bold().end).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tEne.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tFeb.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tMar.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tAbr.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tMay.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tJun.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tJul.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tAgo.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tSet.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tOct.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tNov.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tDic.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totalGen.toFixed(2)))
          .fontSize(7)
          .bold()
          .alignment('right').end
      ).border(topBorder).end,
    ]);
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).bold().fontSize(8).end,
            new Txt(headers.codePage).bold().fontSize(8).end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
          ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('ACUMULADO DE CONCEPTO POR COLABORADOR ANUAL')
              .alignment('center')
              .bold()
              .fontSize(10).end,
            new Txt(headers.year).alignment('center').bold().fontSize(8).end,
          ]).end
        )
          .border(noBorders)
          .colSpan(10).end,
        ...obtenerNulls(9),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').bold().fontSize(8).end,
            new Txt(headers.time).alignment('right').bold().fontSize(8).end,
          ]).end
        )
          .border(noBorders)
          .colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('CÓDIGO').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(openLeftBorder).end,
        new Cell(new Txt('CONCEPTO').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('ENE.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('FEB.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('MAR.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('ABR.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('MAY.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('JUN.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('JUL.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('AGO.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('SET.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('OCT.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('NOV.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('DIC.').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('TOTAL').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(openLeftBorder).end,
      ],
      ...detalle,
    ])
      .headerRows(2)
      .widths([30, 180, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35, 35]).end
  );
  pdf.create().open();
}

/**
 * Informe Acumulado Concepto Grupo
 * @param response
 * @param data
 */
export async function generarPDFAcumuladoConceptoGrupo(
  headers: HeaderReport,
  others,
  response
) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];
  response.forEach((item) => {
    detalle.push([
      new Cell(
        new Txt(`COLABORADOR    ${item.cli_codcli}    ${item.cli_nomcli}`)
          .bold()
          .fontSize(8).end
      )
        .border(noBorders)
        .colSpan(9).end,
      ...obtenerNulls(8),
    ]);

    let totalImporteGeneral = 0;
    item.detalle.forEach((el) => {
      detalle.push([
        new Cell(new Txt(el.nco_codnco).bold().fontSize(8).end).border(noBorders).end,
        new Cell(new Txt(el.nco_descri).bold().fontSize(8).end)
          .border(noBorders)
          .colSpan(8).end,
        ...obtenerNulls(7),
      ]);

      let totalImporte = 0;
      el.detalle.forEach((element) => {
        detalle.push([
          new Cell(
            new Txt(`${element.ano_codano}-${element.mes_codmes}`).fontSize(
              8
            ).end
          ).border(noBorders).end,
          new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.nut_codnut).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_cannut).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_canhrs).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(new Txt(element.npc_canmin).alignment('center').fontSize(8).end).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(element.npc_impnpc.toFixed(2)))
              .fontSize(8)
              .alignment('right').end
          ).border(noBorders).end,
          new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        ]);
        totalImporte += element.npc_impnpc;
      });

      detalle.push([
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(
          new Txt(el.detalle.length).fontSize(8).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totalImporte.toFixed(2)))
            .fontSize(8)
            .alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt(
            numberWithCommas((totalImporte / el.detalle.length).toFixed(2))
          )
            .fontSize(8)
            .alignment('right').end
        ).border(noBorders).end,
      ]);

      totalImporteGeneral += totalImporte;
    });

    detalle.push([
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('TOTAL COLABORADOR: ').fontSize(8).end).border(noBorders).end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(new Txt('').fontSize(8).end).border(noBorders)
        .end,
      new Cell(
        new Txt(item.totalPeriodos).fontSize(8).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totalImporteGeneral.toFixed(2)))
          .fontSize(8)
          .alignment('right').end
      ).border(openLeftBorder).end,
      new Cell(
        new Txt(
          `PROM(/${item.totalPeriodos})   ` +
            numberWithCommas(
              (totalImporteGeneral / item.totalPeriodos).toFixed(2)
            )
        )
          .fontSize(8)
          .alignment('right').end
      ).border(openLeftBorder).end,
    ]);
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).bold().fontSize(7).end,
            new Txt(headers.codePage).bold().fontSize(7).end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Acumulado de Conceptos Variable por Grupo')
              .alignment('center')
              .bold()
              .fontSize(10).end,
            new Txt(
              `RANGO DE PERIODO ${others.periodoini}  -  ${others.periodofin}`
            )
              .bold()
              .fontSize(8)
              .alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt(headers.date).bold().fontSize(7).alignment('right').end,
            new Txt(headers.time).bold().fontSize(7).alignment('right').end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(new Txt('PERIODO').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('CONCEPTO').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(new Txt('UM').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('CANTIDAD').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('HORAS').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('MINUTOS').bold().fontSize(8).alignment('center').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('IMPORTE').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
        new Cell(new Txt('PROMEDIO').bold().fontSize(8).alignment('right').end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(noBorders).end,
      ],
      ...detalle,
    ]).headerRows(2).widths([40, 85, 50, 50, 50, 50, 50, 50, 50]).end
  );
  pdf.create().open();
}

/**
 * Dependientes Rango Edades Sexo
 * @param response
 * @param data
 */
export async function generarPDFDependientesRangoEdadesSexo(
  headers: HeaderReport,
  response
) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, color: 'gray', bold: false, margin: [ 10, 10, 20, 20 ]},
    ];
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, color: 'gray', fontSize: 8, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).bold().fontSize(8).end,
            new Txt(headers.codePage).bold().fontSize(8).end,
          ]).end
        )
        .border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('Dependientes por Rango de Edades / Sexo')
              .bold()
              .fontSize(10)
              .alignment('center').end,
            new Txt(`${headers.year} - ${headers.month}`)
              .bold()
              .fontSize(8)
              .alignment('center').end,
          ]).end
        )
        .border(noBorders)
        .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(`Fecha: ${headers.date}`).fontSize(8).bold().end,
            new Txt(`Hora: ${headers.time}`).fontSize(8).bold().end,
          ]).end
        )
          .border(noBorders)
          .bold()
          .fontSize(8)
          .alignment('right').end,
      ],
      [
        new Cell(new Txt('Total de registro: ').fontSize(8).bold().end).border(noBorders).end,
        new Cell(new Txt(response.totalRegistros).fontSize(8).bold().end)
          .border(noBorders)
          .colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('').end).border(noBorders).end,
        new Cell(new Txt(response.cabeceras[0]).fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt(response.cabeceras[1]).fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt(response.cabeceras[2]).fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(new Txt('FEMENINO').fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt(response.fila1[0]).fontSize(8).alignment('right').end)
          .end,
        new Cell(new Txt(response.fila1[1]).fontSize(8).alignment('right').end)
          .end,
        new Cell(new Txt(response.fila1[2]).fontSize(8).alignment('right').end)
          .end,
      ],
      [
        new Cell(new Txt('MASCULINO').fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt(response.fila2[0]).fontSize(8).alignment('right').end)
          .end,
        new Cell(new Txt(response.fila2[1]).fontSize(8).alignment('right').end)
          .end,
        new Cell(new Txt(response.fila2[2]).fontSize(8).alignment('right').end)
          .end,
      ],
      [
        new Cell(new Txt('Total').fontSize(8).bold().end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue).end,
        new Cell(new Txt(response.total[0]).fontSize(8).alignment('right').end)
          .end,
        new Cell(new Txt(response.total[1]).fontSize(8).alignment('right').end)
          .end,
        new Cell(new Txt(response.total[2]).fontSize(8).alignment('right').end)
          .end,
      ],
    ]).layout({
      hLineWidth: function() {
        return 1;
      },
      vLineWidth: function() {
        return 1;
      }
    }).widths([120, 140, 140, 115]).end
  );

  pdf.create().open();
}

/**
 * Dependientes Colaborador
 * @param response
 * @param data
 */
export async function generarPDFDependientesColaborador(
  headers: HeaderReport,
  response
) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  let detalle = [];

  response.forEach((item, key) => {
    detalle.push([
      new Cell(new Txt(item.cli_nomcli).bold().fontSize(8).end)
        .border(horizontalBorder)
        .colSpan(3).end,
      ...obtenerNulls(2),
      new Cell(new Txt('T00' + key).bold().fontSize(8).end)
        .border(horizontalBorder)
        .colSpan(5).end,
      ...obtenerNulls(4),
    ]);

    item.detalle.forEach((it) => {
      detalle.push([
        new Cell(new Txt(it.dtr_apepat).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt(it.dtr_apemat).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt(it.dtr_nombre).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt(it.vinculo).fontSize(8).end).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(it.dtr_fecnac)).fontSize(8).end
        ).border(noBorders).end,
        new Cell(new Txt(it.edad).fontSize(8).alignment('center').end).border(noBorders).end,
        new Cell(new Txt(it.did_desabr).fontSize(8).end).border(noBorders).end,
        new Cell(new Txt(it.cli_numdni).fontSize(8).end).border(noBorders).end,
      ]);
    });

    detalle.push([
      new Cell(new Txt('Total Registros').bold().fontSize(8).end).border(topBorder).end,
      new Cell(new Txt(item.detalle.length).bold().fontSize(8).end)
        .border(topBorder)
        .colSpan(7).end,

      ...obtenerNulls(6),
    ]);
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end
          ]).end
          )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Dependientes del Colaborador')
            .fontSize(12)
            .bold()
            .alignment('center').end
        )
          .border(noBorders)
          .colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt(`Fecha: ${headers.date}`)
              .fontSize(8)
              .bold()
              .alignment('right').end,
            new Txt(`Hora: ${headers.time}`)
              .fontSize(8)
              .bold()
              .alignment('right').end,
          ]).end
        ).colSpan(2)
        .border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('Apellido Pat.').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(openLeftBorder).end,
        new Cell(new Txt('Apellido Mat.').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('Nombre').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('Vínculo').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('Fecha Nac.').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('Edad').bold().fontSize(8).end)
          .alignment('center')
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('Tip. Doc.').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,
        new Cell(new Txt('Num. Doc.').bold().fontSize(8).end)
          .color(PavsoColors.White)
          .fillColor(PavsoColors.Blue)
          .border(horizontalBorder).end,

      ],
      ...detalle,
    ]).widths([90, 90, 135, 85, 80, 80, 85, 85]).end
  );

  pdf.create().open();
}

/**
 * Reporte de Validación por Trabajador para Asiento Planilla
 * @param headers
 * @param data
 */
export async function generarPDFValidaciónTrabajadorAsientoPlanilla(headers: HeaderReport, data) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  let [tg1, tg2, tg3, tg4] = [0, 0, 0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`SUBDIARIO: ${element.sco_codsco} ${element.sco_descri}`).fontSize(8).bold().end
      ).border(noBorders).colSpan(12).end,
      ...obtenerNulls(11),
    ],)
    detalle.push([
      new Cell(
        new Txt(`VOUCHER: ${element.ldc_corldc}  FECHA: REFERENCIA: ${element.concepto} ESTADO: ${element.estado}`).fontSize(8).bold().end
      ).border(noBorders).colSpan(12).end,
      ...obtenerNulls(11),
    ],)

    let [tSmnd, tSmnh, tSmed, tSmen] = [0, 0, 0, 0];

    element.detalle.forEach(item => {
      detalle.push([
        new Cell(
          new Txt(`${item.cli_codcli} ${item.cli_nomcli}`).fontSize(8).bold().end
        ).border(noBorders).colSpan(12).end,
        ...obtenerNulls(11),
      ],);

      let [tMND, tMNH, tMED, tMEH] = [0, 0, 0,  0];

      item.detalle.forEach(it => {
        detalle.push([
          new Cell(
            new Txt(`${it.ccn_codccn.trim()}`).decoration('underline').fontSize(8).bold().end
          ).border(noBorders).end,
          new Cell(
            new Txt("").end
          ).border(noBorders).colSpan(11).end,
          ...obtenerNulls(10),
        ],);

        let [tMnd, tMnh, tMed, tMeh] = [0, 0, 0, 0];

        it.detalle.forEach(ite => {
          detalle.push([
            new Cell(
              new Txt(ite.ccn_codccn.trim()).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(ite.ccn_descri.trim()).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(ite.cli_codaux.trim()).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(ite.ccs_codccs).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(ite.tdo_codtdo).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(ite.ldi_docref.trim()).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(ite.ldi_tipcam).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(ite.ldi_impnac_debe.toFixed(2))).alignment('right').fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(ite.ldi_impnac_habe.toFixed(2))).alignment('right').fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(ite.ldi_impmex_debe.toFixed(2))).alignment('right').fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(numberWithCommas(ite.ldi_impmex_habe.toFixed(2))).alignment('right').fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(ite.ldi_gloldi.trim()).fontSize(7).end
            ).border(noBorders).end,
          ],)
          tMnd += ite.ldi_impnac_debe;
          tMnh += ite.ldi_impnac_habe;
          tMed += ite.ldi_impmex_debe;
          tMeh += ite.ldi_impmex_habe;
        })

        detalle.push([
          new Cell(
            new Txt(``).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt("").fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(``).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(``).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(``).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(``).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(``).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(tMnd.toFixed(2))).alignment('right').bold().fontSize(7).end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tMnh.toFixed(2))).alignment('right').bold().fontSize(7).end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tMed.toFixed(2))).alignment('right').bold().fontSize(7).end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(tMeh.toFixed(2))).alignment('right').bold().fontSize(7).end
          ).border(topBorder).end,
          new Cell(
            new Txt(``).fontSize(7).end
          ).border(noBorders).end,
        ],)

        tMND += tMnd;
        tMNH += tMnh;
        tMED += tMed;
        tMEH += tMeh;
      })
      detalle.push([
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(tMND.toFixed(2))).alignment('right').bold().fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMNH.toFixed(2))).alignment('right').bold().fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMED.toFixed(2))).alignment('right').bold().fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tMEH.toFixed(2))).alignment('right').bold().fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).end,
      ],)

      tSmnd += tMND;
      tSmnh += tMNH;
      tSmed += tMED;
      tSmen += tMEH;

    })

    tg1 += tSmnd;
    tg2 += tSmnh;
    tg3 += tSmed;
    tg4 += tSmen;


    detalle.push([
      new Cell(
        new Txt(`TOTAL ASIENTO: ${element.sco_codsco} - ${element.ldc_corldc}`).bold().alignment('right').fontSize(7).end
      ).colSpan(7).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSmnd.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tSmnh.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tSmed.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tSmen.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
    ],)
    detalle.push([
      new Cell(
        new Txt(`TOTAL SUBDIARIO: ${element.sco_codsco}`).bold().alignment('right').fontSize(7).end
      ).colSpan(7).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSmnd.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tSmnh.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tSmed.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tSmen.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
    ],)
  });

  detalle.push([
    new Cell(
      new Txt(`TOTAL GENERAL`).bold().alignment('right').fontSize(7).end
    ).colSpan(7).border(openRightBorder).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(``).fontSize(7).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(``).fontSize(7).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(``).fontSize(7).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(``).fontSize(7).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(``).fontSize(7).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(tg1.toFixed(2))).alignment('right').bold().fontSize(8).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(tg2.toFixed(2))).alignment('right').bold().fontSize(8).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(tg3.toFixed(2))).alignment('right').bold().fontSize(8).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(numberWithCommas(tg4.toFixed(2))).alignment('right').bold().fontSize(8).end
    ).border(horizontalBorder).end,
    new Cell(
      new Txt(``).fontSize(7).end
    ).border(openRightBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Validación por Colaborador por Asiento Planilla").fontSize(10).alignment('center').bold().end,
            new Txt(`${headers.year} ${headers.month}`).fontSize(8).alignment('center').bold().end
          ]).end
        ).colSpan(8).border(noBorders).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).alignment('right').bold().end,
            new Txt(headers.time).fontSize(8).alignment('right').bold().end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("CUENTA").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("DENOMINACIÓN").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("AUXILIAR").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("CE.CO.").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("DOC.").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("NRO. DOC.").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("T.C.").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("MONEDA NACIONAL").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("MONEDA EXTRANJERA").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("GLOSA").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).rowSpan(2).end,
      ],
      [
        ...obtenerNulls(7),
        new Cell(
          new Txt("DEBE").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("HABER").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DEBE").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("HABER").fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White)
        .fillColor(PavsoColors.Blue).end,
        null
      ],
      ...detalle,
    ]).widths([35, 160, 45, 30, 20, 30, 25, 40, 40, 40, 40, 190]).headerRows(3).end
  )

  pdf.create().open();
}

/**
 * Validación de provisión de vacaciones
 * @param headers
 * @param data
 */
 export async function generarPDFValidacionProvisionVacaciones(headers: HeaderReport, data) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  let [t01, t02] = [0, 0];
  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.cli_codcli).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_impcom.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.npv_diacom).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_impprv.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_codccn).fontSize(7).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccs_codccs).fontSize(7).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_codprv).fontSize(7).alignment('center').end
      ).border(noBorders).end,
    ],)

    t01 += element.npv_impcom;
    t02 += element.npv_impprv;
  });

  detalle.push([
    new Cell(
      new Txt(`ITEMS: ${data.length}`).bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES:").alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t01.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t02.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt("Validación de provisión de vacaciones").fontSize(10).bold().alignment('center').end,
            new Txt(`PERIODO ${headers.year} ${headers.month}`).fontSize(9).bold().alignment('center').end
          ]).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(headers.date).bold().fontSize(8).alignment('right').end,
            new Txt(headers.time).bold().fontSize(8).alignment('right').end
          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("CÓDIGO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("COLABORADOR").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERACIÓN CONTABLE").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DÍAS COMPUTABLES").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("IMPORTE PROVISIÓN").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA GASTO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CENTRO DE COSTO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA PROVISIÓN").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,

    ]).headerRows(2).widths([75, 230, 75, 75, 75, 65, 65, 65]).end
  );

  pdf.create().open();

}

/**
 * Validación de provisión de vacaciones
 * @param headers
 * @param data
 */
 export async function generarPDFValidacionProvisionVacacionesAnual(headers: HeaderReport, others, data) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right',  margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  let [t01, t02] = [0, 0];
  data.forEach(item => {

    let subtotal = 0;

    detalle.push([
      new Cell(
        new Txt(item.cli_codcli).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.cli_nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).alignment('center').end
      ).border(noBorders).end,
    ],)

    item.detalle.forEach(element => {
      detalle.push([
        new Cell(
          new Txt('').alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element.ano_codano}-${element.mes_codmes}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_impcom.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.npv_diacom).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_impprv.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccn_codccn).fontSize(7).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccs_codccs).fontSize(7).alignment('center').end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccn_codprv).fontSize(7).alignment('center').end
        ).border(noBorders).end,
      ],)
      subtotal += element.npv_impprv;
      t01 += element.npv_impcom;
      t02 += element.npv_impprv;
    });

    detalle.push([
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('SUBTOTAL').alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(subtotal.toFixed(2)).bold().alignment('right').fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt('').fontSize(7).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).alignment('center').end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).alignment('center').end
      ).border(noBorders).end,
    ],)
  });

  detalle.push([
    new Cell(
      new Txt(`ITEMS: ${data.length}`).bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES:").alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t01.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t02.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(7).end
    ).border(noBorders).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt("Acumulado de provisión de vacaciones").fontSize(10).bold().alignment('center').end,
            new Txt(`Rango de Período ${others.periodoini} - ${others.periodofin}`).fontSize(9).bold().alignment('center').end
          ]).end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Stack([
            new Txt(headers.date).bold().fontSize(8).alignment('right').end,
            new Txt(headers.time).bold().fontSize(8).alignment('right').end
          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("CÓDIGO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("COLABORADOR").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("PERÍODO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERACIÓN CONTABLE").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DÍAS COMPUTABLES").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("IMPORTE PROVISIÓN").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA GASTO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CENTRO DE COSTO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA PROVISIÓN").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,

    ]).headerRows(2).widths([75, 200, 30, 75, 75, 75, 65, 65, 65]).end
  );

  pdf.create().open();

}

/**
 * Reporte de Validación de Provisión de CTS
 * @param headers
 * @param data
 */
export async function generarPDFValidacionProvisionCTS(headers: HeaderReport, data) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  let [t01, t02, t03, t04] = [0, 0, 0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.cli_codcli).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_impcom.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_diacom.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_rembas.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_ultgra.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_remvar.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_impprv.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_codccn).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccs_codccs).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_codprv).alignment('center').fontSize(7).end
      ).border(noBorders).end,
    ],)
    t01 += element.npv_impcom;
    t02 += element.npv_rembas;
    t03 += element.npv_ultgra;
    t04 += element.npv_impprv;
  });

  detalle.push([
    new Cell(
      new Txt(`ITEMS ${data.length}`).bold().fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES").bold().alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t01.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t02.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(t03.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t04.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Validación de Provisión de CTS").fontSize(10).bold().alignment('center').end,
            new Txt(`PERIODO ${headers.year} ${headers.month}`).fontSize(8).bold().alignment('center').end,
            new Txt(`En soles`).fontSize(7).bold().alignment('center').end,
          ]).end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().alignment('right').end,
            new Txt(headers.time).fontSize(8).bold().alignment('right').end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("CÓDIGO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("COLABORADOR").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERACIÓN COMPUTABLE").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DÍAS COMPUT.").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERAC.").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("1/6 GRATIFIC.").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMU. VARIABLE").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("IMPORTE PROVISIÓN").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA GASTO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CENTRO DE COSTO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA PROVISIÓN").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
    ]).widths([45, 170, 57, 57, 57, 57, 57, 57, 50, 50, 50]).headerRows(2).end
  )
  pdf.create().open();

}

/**
 * Reporte de Validación de Provisión de CTS Anual
 * @param headers
 * @param data
 */
 export async function generarPDFValidacionProvisionCTSAnual(headers: HeaderReport, others, data) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  let [t01, t02, t03, t04] = [0, 0, 0, 0];

  data.forEach(item => {
    let subtotal = 0;
    detalle.push([
      new Cell(
        new Txt(item.cli_codcli).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.cli_nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
    ],)

    item.detalle.forEach(element => {
      detalle.push([
        new Cell(
          new Txt('').alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element.ano_codano}-${element.mes_codmes}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_impcom.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_diacom.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_rembas.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_ultgra.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_remvar.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_impprv.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccn_codccn).alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccs_codccs).alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccn_codprv).alignment('center').fontSize(7).end
        ).border(noBorders).end,
      ],)
      subtotal += element.npv_impprv;
      t01 += element.npv_impcom;
      t02 += element.npv_rembas;
      t03 += element.npv_ultgra;
      t04 += element.npv_impprv;
    })

    detalle.push([
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('SUBTOTAL').alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(subtotal.toFixed(2)).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
    ],)
  });

  detalle.push([
    new Cell(
      new Txt(`ITEMS ${data.length}`).bold().fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES").bold().alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t01.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t02.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(t03.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t04.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(8).end
    ).border(noBorders).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Acumulado de Provisión de CTS").fontSize(10).bold().alignment('center').end,
            new Txt(`Rango de Período ${others.periodoini} ${others.periodofin}`).fontSize(8).bold().alignment('center').end,
            new Txt(`En soles`).fontSize(7).bold().alignment('center').end,
          ]).end
        ).colSpan(8).border(noBorders).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().alignment('right').end,
            new Txt(headers.time).fontSize(8).bold().alignment('right').end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("CÓDIGO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("COLABORADOR").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("PERÍODO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERACIÓN COMPUTABLE").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DÍAS COMPUT.").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERAC.").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("1/6 GRATIFIC.").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMU. VARIABLE").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("IMPORTE PROVISIÓN").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA GASTO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CENTRO DE COSTO").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA PROVISIÓN").alignment('center').bold().fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
    ]).widths([45, 140, 30, 57, 57, 57, 57, 57, 57, 50, 50, 50]).headerRows(2).end
  )
  pdf.create().open();

}

/**
 * Reporte de Validación de provisión de Gratificación
 * @param headers
 * @param data
 */
export async function generarPDFValidacionProvisionGratificacion(headers: HeaderReport, data) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  let [t01, t02, t03, t04] = [0, 0, 0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.cli_codcli).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_rembas.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_remvar.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_impcom.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_diacom.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.npv_impprv.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_codccn).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccs_codccs).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_codprv).alignment('center').fontSize(7).end
      ).border(noBorders).end,
    ],)

    t01 += element.npv_rembas;
    t02 += element.npv_remvar;
    t03 += element.npv_impcom;
    t04 += element.npv_impprv;
  });

  detalle.push([
    new Cell(
      new Txt(`ITEMS : ${data.length}`).fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES :").alignment('right').fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t01.toFixed(2))).alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(t02.toFixed(2))).alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(t03.toFixed(2))).alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t04.toFixed(2))).alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).bold().end
    ).border(noBorders).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Validación de Provisión de Gratificación").fontSize(10).bold().alignment('center').end,
            new Txt(`PERIODO ${headers.year} ${headers.month}`).fontSize(8).bold().alignment('center').end,
            new Txt(`En Soles`).fontSize(7).bold().alignment('center').end,
          ]).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').fontSize(8).bold().end,
            new Txt(headers.time).alignment('right').fontSize(8).bold().end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("CÓDIGO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("COLABORADOR").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERAC. BÁSICA").alignment('center').alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("PROM. REM.").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERACIÓN COMPUTABLE").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DÍAS COMPUTABLES").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("IMPORTE PROVISIÓN").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA GASTO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CENTRO DE COSTO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA PROVISIÓN").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,

    ]).widths([45, 175, 65, 65, 65, 65, 65, 55, 55, 55]).headerRows(2).end
  )

  pdf.create().open();
}

/**
 * Reporte de Validación de provisión de Gratificación Anual
 * @param headers
 * @param data
 */
 export async function generarPDFValidacionProvisionGratificacionAnual(headers: HeaderReport, others, data) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  let [t01, t02, t03, t04] = [0, 0, 0, 0];

  data.forEach(item => {

    let subtotal = 0;

    detalle.push([
      new Cell(
        new Txt(item.cli_codcli).alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.cli_nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
    ],)

    item.detalle.forEach(element => {
      detalle.push([
        new Cell(
          new Txt('').alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element.ano_codano}-${element.mes_codmes}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_rembas.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_remvar.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_impcom.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_diacom.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(element.npv_impprv.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccn_codccn).alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccs_codccs).alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccn_codprv).alignment('center').fontSize(7).end
        ).border(noBorders).end,
      ],)

      subtotal += element.npv_impprv;

      t01 += element.npv_rembas;
      t02 += element.npv_remvar;
      t03 += element.npv_impcom;
      t04 += element.npv_impprv;
    })

    detalle.push([
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(``).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('SUBTOTAL').alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(subtotal.toFixed(2)).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').alignment('center').fontSize(7).end
      ).border(noBorders).end,
    ],)

  });

  detalle.push([
    new Cell(
      new Txt(`ITEMS : ${data.length}`).fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES :").alignment('right').fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(t01.toFixed(2))).alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(t02.toFixed(2))).alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(t03.toFixed(2))).alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(t04.toFixed(2))).alignment('right').fontSize(8).bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).bold().end
    ).border(noBorders).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Acumulado de Provisión de Gratificación").fontSize(10).bold().alignment('center').end,
            new Txt(`Rango de Período ${others.periodoini} ${others.periodofin}`).fontSize(8).bold().alignment('center').end,
            new Txt(`En Soles`).fontSize(7).bold().alignment('center').end,
          ]).end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Stack([
            new Txt(headers.date).alignment('right').fontSize(8).bold().end,
            new Txt(headers.time).alignment('right').fontSize(8).bold().end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("CÓDIGO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("COLABORADOR").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("PERÍODO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERAC. BÁSICA").alignment('center').alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("PROM. REM.").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERACIÓN COMPUTABLE").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DÍAS COMPUTABLES").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("IMPORTE PROVISIÓN").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA GASTO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CENTRO DE COSTO").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA PROVISIÓN").alignment('center').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,

    ]).widths([45, 145, 30, 65, 65, 65, 65, 65, 55, 55, 55]).headerRows(2).end
  )

  pdf.create().open();
}

/**
 * Reporte de Acumulado de provisión de CTS
 * @param headers
 * @param data
 */
export async function generarPDFAcumuladoProvisionCTS(headers: HeaderReport, data) {
  let pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, ffontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt("Acumulado de Provision CTS").fontSize(10).bold().alignment('center').end,
            new Txt(`PERIODO ${headers.year} ${headers.month}`).fontSize(8).bold().alignment('center').end,
            new Txt(`En soles`).fontSize(7).bold().alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(11).end,
        ...obtenerNulls(10),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().alignment('right').end,
            new Txt(headers.time).fontSize(8).bold().alignment('right').end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        null
      ],
      [
        new Cell(
          new Txt("COLABORADOR").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("FECHA DE INGRESO").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERACIÓN COMPUTABLE").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("ÚLTIMA GRATIFICACIÓN").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DÍAS COMPUTAB.").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMUNERAC.").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("1/6 GRATIFIC.").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("REMU. VARIABLE").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("IMPORTE PROVISIÓN").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA GASTO").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CENTRO DE COSTO").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CUENTA PROVISIÓN").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("CTS PAGADA").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DIFER.").fontSize(7).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      [
        new Cell(
          new Txt("2021 11").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("01/02/2010").alignment('center').alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("629100").alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("300001").alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("419013").alignment('center').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("T00009 ACUÑA SOTO LORENA SOL").fontSize(7).end
        ).border(openRightBorder).end,
        new Cell(
          new Txt("01/02/2010").fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("0.00").alignment('right').fontSize(7).end
        ).border(openRightBorder).end,
      ],
      [
        new Cell(
          new Txt("ITEMS: 34").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("TOTALES: ").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").bold().alignment('right').fontSize(7).end
        ).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").bold().alignment('right').fontSize(7).end
        ).end,
        new Cell(
          new Txt("0.00").bold().alignment('right').fontSize(7).end
        ).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").bold().alignment('right').fontSize(7).end
        ).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(7).end
        ).border(noBorders).end,
      ]
    ]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Contratos
 * @param headers
 * @param data
 */
export async function generarPDFContratos(headers: HeaderReport, data, other) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [ 10, 10, 20, 20 ], fontSize: 8, color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];
  data.forEach(item => {
    detalle.push([
      new Cell(
        new Txt(item.nmc_codnmc).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.nmc_descri).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.cli_codcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.cli_nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(formatDateWithSlash(item.nmc_fecini)).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(formatDateWithSlash(item.nmc_fecter)).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.NCA_DESCRI).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.ne0_descri).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.TMO_DESCOR).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(item.nmc_imprem.toFixed(2))).fontSize(7).alignment('right').end
      ).border(noBorders).end,
    ],)
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(8).bold().end,
            new Txt(headers.codePage).fontSize(8).bold().end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Listado de Contratos").fontSize(10).bold().alignment('center').end
          ]).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(8).bold().alignment('right').end,
            new Txt(headers.time).fontSize(8).bold().alignment('right').end
          ]).end
        ).colSpan(2).border(noBorders).end,
        null
      ],
      [
        new Cell(
          new Txt("Contrato").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Descripción").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cod. Colaborador").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Colaborador").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fec. Inicio").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fec. Cierre").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cargo").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Planilla").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Moneda").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Remuneración").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
      [
        new Cell(
          new Txt('TOTAL').fontSize(8).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(data.length).fontSize(8).bold().end
        ).colSpan(9).border(topBorder).end,
        ...obtenerNulls(8),
      ]
    ]).headerRows(2).widths([60,100,65,120,60,60,50,75,50,70]).end
  )

  pdf.create().open();

}

/**
 * Reporte de tipo de documento legajo
 * @param headers
 * @param data
 */
export async function generarPDFTipoDocumentoLegajo(headers: HeaderReport, data) {

  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('landscape');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  data.forEach(item => {
    detalle.push( [
      new Cell(
        new Txt(item.codcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.nomcli).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.codtdl).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.codtdl).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.glosa).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(formatDateWithSlash(item.fecing)).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(formatDateWithSlash(item.fecvig)).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(formatDateWithSlash(item.fecven)).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.indsta).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.ind_color).fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.situacion).fontSize(7).end
      ).border(noBorders).end,
    ],)
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(7).end,
            new Txt(headers.codePage).fontSize(7).end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("Reporte de documentos legajo").fontSize(10).alignment('center').bold().end,
            new Txt("").fontSize(8).alignment('center').end
          ]).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(headers.date).fontSize(7).alignment('right').end,
            new Txt(headers.time).fontSize(7).alignment('right').end
          ]).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt("Código").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Colaborador").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Cod.Tipo Doc.").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Tipo Doc.").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Glosa").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fec. ingreso").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fec. vigente").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Fec. vencimiento").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Estado").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Color").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Situación").fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,

      [
        new Cell(
          new Txt(`Total Registros: ${data.length}`).fontSize(8).bold().end
        ).border(topBorder).colSpan(11).end,
        ...obtenerNulls(10),
      ]
    ]).headerRows(2).widths([60, 170, 40, 80, 110, 50, 50, 50, 30, 30, 40]).end
  )

  pdf.create().open();
}

/**
 * Generar Informe de Liquidación CTS
 * @param headers Datos de cabecera
 * @param data Cuerpo del reporte
 */
export async function generarInformeLiquidacionCTS(headers, data) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.pageOrientation('portrait');

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [ 10, 10, 20, 20 ], color: 'gray', bold: true},
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, color: 'gray', bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          await new Img(
            logo
          ).link('https://pavsolutions.com')
            .alignment('left')
            .width(70)
            .build()
        ).border(noBorders).end,
        new Cell(
          new Txt('').alignment('center').bold().end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt('').color(PavsoColors.Blue).bold().alignment('right').fontSize(8).end,
            new Txt('').color(PavsoColors.Blue).bold().alignment('right').fontSize(8).end,
            new Txt('').color(PavsoColors.Blue).bold().alignment('right').fontSize(8).end,

          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt('\n').end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt('LIQUIDACIÓN CTS').alignment('center').bold().end
        ).color('black').fillColor(COLOR_GRAY).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt('Razón social del Empleador: ').alignment('center').bold().fontSize(9).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`RUC `).alignment('center').bold().fontSize(9).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Fecha `).alignment('center').bold().fontSize(9).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('DEMO PAVSO').alignment('center').fontSize(9).end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('20512099662').fontSize(9).alignment('center').end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(formatDateWithSlash(data.nct_fecemi)).fontSize(9).alignment('center').end
        ).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Dirección del Empleador').alignment('center').bold().fontSize(9).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Período que comprende esta liquidación').alignment('center').bold().fontSize(9).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt(data.cli_dircli).fontSize(9).end
        ).border(openTopBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Del: ${formatDateWithSlash(data.nct_iniliq)}`).fontSize(9).end
        ).border(bottomLeftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Al: ${formatDateWithSlash(data.nct_terliq)}`).fontSize(9).end
        ).border(bottomRightBorder).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Entidad Depositaria').fontSize(8).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(topRightBorder).end,
        new Cell(
          new Txt('Nro. Cuenta').fontSize(8).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(topLeftBorder).end,
        new Cell(
          new Txt('Fecha de Depósito').fontSize(8).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).end,
        new Cell(
          new Txt('T. Cambio').fontSize(8).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).end,
        new Cell(
          new Txt('Moneda').fontSize(8).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).end,
        new Cell(
          new Txt('Importe').fontSize(8).bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).end,
      ],
      [
        new Cell(
          new Txt(data.ban_nombre).fontSize(8).end
        ).border(bottomLeftBorder).end,
        new Cell(
          new Txt(data.trp_ctacts).fontSize(8).end
        ).border(bottomRightBorder).end,
        new Cell(
          new Txt(formatDateWithSlash(data.nct_fecdep)).fontSize(8).end
        ).border(openTopBorder).end,
        new Cell(
          new Txt(data.nct_tipcam).alignment('right').fontSize(8).end
        ).border(openTopBorder).end,
        new Cell(
          new Txt(data.tmo_codtmo == 'SO'? 'SOLES': 'DÓLARES').alignment('center').fontSize(8).end
        ).border(openTopBorder).end,
        new Cell(
          new Txt(data.nct_impdep.toFixed(2)).alignment('right').fontSize(8).end
        ).border(openTopBorder).end,
      ],
      [
        new Cell(
          new Txt('DATOS DEL COLABORADOR').bold().alignment('center').fontSize(8).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('PERÍODO DE SERVICIO QUE SE CANCELA').bold().alignment('center').fontSize(8).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt('Colaborador').fontSize(8).end,
        ).border(leftBorder).end,
        new Cell(
          new Txt(data.cli_nomcli).fontSize(8).end,
        ).border(rightBorder).end,
        new Cell(
          new Txt('1. Fecha de Término del período').fontSize(8).end,
        ).border(leftBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(formatDateWithSlash(data.nct_fecter)).alignment('right').fontSize(8).end,
        ).border(rightBorder).end,
      ],
      [
        new Cell(
          new Txt('C. Costo').fontSize(8).end,
        ).border(leftBorder).end,
        new Cell(
          new Txt(data.ccs_descri).fontSize(8).end,
        ).border(rightBorder).end,
        new Cell(
          new Txt('2. Fecha de Inicio del periodo').fontSize(8).end,
        ).border(leftBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(formatDateWithSlash(data.nct_fecini)).alignment('right').fontSize(8).end,
        ).border(rightBorder).end,
      ],
      [
        new Cell(
          new Txt('Cargo').fontSize(8).end,
        ).border(leftBorder).end,
        new Cell(
          new Txt(data.NCA_DESCRI).fontSize(8).end,
        ).border(rightBorder).end,
        new Cell(
          new Txt('').fontSize(8).end,
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Meses').alignment('center').fontSize(8).end,
        ).border(noBorders).end,
        new Cell(
          new Txt('Días').alignment('center').fontSize(8).end,
        ).border(rightBorder).end,
      ],
      [
        new Cell(
          new Txt('F. Ingreso').fontSize(8).end,
        ).border(bottomLeftBorder).end,
        new Cell(
          new Txt('6/11/2006').fontSize(8).end,
        ).border(bottomRightBorder).end,
        new Cell(
          new Txt('Tiempo de Servicio a Liquidar').fontSize(8).end,
        ).border(bottomLeftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.nct_canmes).alignment('center').fontSize(8).end,
        ).border(bottomBorder).end,
        new Cell(
          new Txt(data.nct_candia).alignment('center').fontSize(8).end,
        ).border(bottomRightBorder).end,
      ],
      [
        new Cell(
          new Txt('CONCEPTOS REMUNERATIVOS PARA DETERMINAR LA REMUNERACION COMPUTABLE').bold().fontSize(8).alignment('center').end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(verticalBorder).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('CONCEPTO').fontSize(8).bold().end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('IMPORTE').alignment('right').fontSize(8).bold().end
        ).border(rightBorder).end,
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('Básico').fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(data.nct_combas.toFixed(2))).fontSize(8).alignment('right').end
        ).border(rightBorder).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('Asignación Familiar').fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(data.nct_asifam.toFixed(2))).fontSize(8).alignment('right').end
        ).border(rightBorder).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('Remuneración Variable').fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(data.nct_provar.toFixed(2))).fontSize(8).alignment('right').end
        ).border(rightBorder).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('1/6 Última Gratificación').fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(data.nct_comgra.toFixed(2))).fontSize(8).alignment('right').end
        ).border(rightBorder).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('Total remuneración Mensual Computable').fontSize(8).end
        ).border(bottomBorder).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(numberWithCommas(data.nct_comtot.toFixed(2))).fontSize(8).alignment('right').end
        ).border(openRightBorder).end,
      ],
      [
        new Cell(
          new Txt('REMUNERACIÓN COMPUTABLE MENSUAL PARA LIQUIDAR EL PERÍODO').fontSize(8).alignment('center').bold().end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('Remuneración Mensual Computable ').fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(data.nct_remano.toFixed(2)).alignment('right').fontSize(8).end
        ).border(rightBorder).colSpan(2).end,
        null
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('Un Doceavo de la Remuneración Mensual Computable').fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(numberWithCommas(data.nct_remmes.toFixed(2))).fontSize(8).alignment('right').end
        ).border(rightBorder).colSpan(2).end,
        null
      ],
      [
        new Cell(new Txt('').end).border(leftBorder).end,
        new Cell(
          new Txt('Una Treintava parte de Un Doceavo de la Remuneración Computable Mensual').fontSize(8).end
        ).border(bottomBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(numberWithCommas(data.nct_remdia.toFixed(2))).fontSize(8).alignment('right').end
        ).border(bottomRightBorder).colSpan(2).end,
        null
      ],
      [
        new Cell(
          new Txt('LIQUIDACIÓN DE CTS CANCELATORIA').alignment('center').bold().fontSize(8).end
        ).fillColor(PavsoColors.Blue).color(PavsoColors.White).border(openBottomBorder).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt('TIEMPO EFECTIVO').bold().fontSize(8).end
        ).border(leftBorder).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Txt('IMPORTE').alignment('right').bold().fontSize(8).end
        ).border(rightBorder).end
      ],
      [
        new Cell(
          new Txt(`${data.nct_canano} Años`).fontSize(8).end
        ).border(leftBorder).end,
        new Cell(
          new Txt('Años de la Remuneración Mensual Computable').fontSize(8).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(data.nct_impano.toFixed(2)).alignment('right').fontSize(8).end
        ).border(rightBorder).end
      ],
      [
        new Cell(
          new Txt(`${data.nct_canmes} meses`).fontSize(8).end
        ).border(rightBorder).end,
        new Cell(
          new Txt('Doceavos de la Remuneración Mensual Computable').fontSize(8).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(data.nct_impmes.toFixed(2)).alignment('right').fontSize(8).end
        ).border(rightBorder).end
      ],
      [
        new Cell(
          new Txt(`${data.nct_candia} Días`).fontSize(8).end
        ).border(leftBorder).end,
        new Cell(
          new Txt('Treintava parte de Un Doceavo de la Remuneración Computable Mensual').fontSize(8).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(data.nct_impdia.toFixed(2)).alignment('right').fontSize(8).end
        ).border(bottomRightBorder).end
      ],
      [
        new Cell(
          new Txt('').end
        ).border(bottomLeftBorder).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('Total importe').bold().fontSize(7).end
        ).border(bottomBorder).end,
        new Cell(
          new Txt(data.nct_impnet.toFixed(2)).alignment('right').bold().fontSize(7).end
        ).border(openRightBorder).end
      ],
      [
        new Cell(
          new Txt('Total Importe Neto Depositado').bold().alignment('right').fontSize(7).end
        ).border(openRightBorder).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Txt(data.nct_impdep.toFixed(2)).alignment('right').bold().fontSize(7).end
        ).border(openRightBorder).end
      ],
      [
        new Cell(
          new Txt(`Total Neto Depositado: ${data.letras} SOLES`).fontSize(8).end
        ).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt('Observaciones').fontSize(8).end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Table([
              [
                new Cell(
                  new Txt('Constancia de recibido:').fontSize(7).end
                ).end,
                new Cell(
                  new Txt('Fecha:').fontSize(7).end
                ).end
              ],
              [
                new Cell(
                  new Stack([
                    new Txt('1) De la presente liquidación').fontSize(6).end,
                    new Txt('2) Del documento que entrega por nuestro intermedio el depositario, con el cual se acredita de acuerdo a ley, la titularidad del deposito CTS, antes detallada').fontSize(6).end,
                    pdf.ln(3)
                  ]).end
                ).end,
                new Cell(
                  new Table([
                    [
                      new Cell(
                        new Stack([
                          pdf.ln(5)
                        ]).end
                      ).decorationStyle('dotted').end
                    ]
                  ]).decorationStyle('dotted').widths([50]).end,
                ).alignment('center').end
              ]
            ]).layout('noBorders').widths([195, 90]).end
          ]).end
        ).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt("V'B'").fontSize(8).end,
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            pdf.ln(4)
          ]).end
        ).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt('Firma del Empleador').alignment('center').fontSize(8).end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Firma del Trabajador').alignment('center').fontSize(7).end
        ).colSpan(4).end,
        ...obtenerNulls(3),
      ]
    ]).layout({
      hLineColor: () => PavsoColors.Blue,
      vLineColor: () => PavsoColors.Blue
    }).widths([70, 145, 70, 70, 70, 70]).end
  )

  pdf.create().open();
}
