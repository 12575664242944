<app-pavso-title-section [title]="'Cargo'" [module]="'Planilla'" [estado]="cargo.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input disabled [required]="requiredCodigo" type="text" [(ngModel)]="cargo.codnca" matInput placeholder="Código" name="codnca" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6"></div>

        <div class="col s12 m12">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text"[(ngModel)]="cargo.descri" matInput placeholder="Descripción" name="descri" required>
          </mat-form-field>
        </div>
      </div>

    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-planilla/cargos">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
