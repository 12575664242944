import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-tipo-documento-conta-create',
  templateUrl: './tipo-documento-conta-create.component.html',
  styleUrls: ['./tipo-documento-conta-create.component.css']
})
export class TipoDocumentoContaCreateComponent implements OnInit {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private store: Store<PavsoState>,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute
  ) {

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=> {
      this.uid = id;
      if(this.uid == '0') return;
    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarTipoDocumento(f): this.actualizarTipoDocumento(f);
  }

  registrarTipoDocumento(f: NgForm): void {}

  actualizarTipoDocumento(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/tipos-de-documento']);
  }

}
