import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ConfiguracionAdelanto } from "src/app/models/planilla/configuraciones/configuracion-adelanto";
import { ConfigurationService, NominaService } from "src/app/services";

@Component({
  selector: 'app-configuracion-adelanto',
  templateUrl: './configuracion-adelanto.component.html',
  styleUrls: ['./configuracion-adelanto.component.scss']
})
export class ConfiguracionAdelantoComponent implements OnInit, OnDestroy{

  loaderReg: boolean;
  loaderData: boolean;

  configuracion: ConfiguracionAdelanto;
  buttonsName: INameConstant = NAMES_CONSTANTS;

  btnName: string;

  year: string;
  month: string;

  period$: Subscription;
  sendForm$: Subscription;
  loading$: Subscription;
  configuracionAdelanto$: Subscription;

  isNew: boolean;

  constructor(
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService,
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,

  ) {
    this.configuracion = new ConfiguracionAdelanto();
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.obtenerConfiguracionAdelanto();
    })

  }

  obtenerConfiguracionAdelanto(): void {
    this.configuracionAdelanto$ = this._nominaService.obtenerConfiguracionAdelanto(this.year, this.month).subscribe(
      response => {

        if(response.length == 0) {
          this.btnName = this.buttonsName.BTN_STORE;
          this.isNew = true;
          this.configuracion = new ConfiguracionAdelanto();
          this.configuracion.codano = this.year;
          this.configuracion.codmes = this.month;
          this.configuracion.coduse = this._configurationService.obtenerIdUsuario();
          this.configuracion.codcia = this._configurationService.obtenerCompaniaCliente();
          return;
        }

        this.btnName = this.buttonsName.BTN_UPDATE;
        this.configuracion = response[0];
        this.isNew = false;

      },
      error => {
        this._snackBarService.showError('Error al obtener configuración adelanto', 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.isNew? this.registrarConfiguracionAdelanto(f): this.actualizarConfiguracionAdelanto(f);

  }

  registrarConfiguracionAdelanto(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._nominaService.registrarConfiguracionAdelanto(this.configuracion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Configuración adelanto registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarConfiguracionAdelanto(f: NgForm): void {
    this.sendForm$ = this._nominaService.actualizarConfiguracionAdelanto(this.configuracion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Configuración adelanto actualizada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.sendForm$, this.configuracionAdelanto$, this.loading$]);
  }
}
