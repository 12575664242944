import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { Item } from 'src/app/models';
import { VentasService } from 'src/app/services';
import { AuthenticationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DescuentosPD } from 'src/app/models/ventas/order';

@Component({
  selector: 'app-ilumi-product-edit',
  templateUrl: './ilumi-product-edit.component.html',
  styleUrls: ['./ilumi-product-edit.component.css']
})
export class IlumiProductEditComponent implements OnInit, OnDestroy {

  producto: Item;

  displayedColumns: string[] = ['cod_desc', 'desc', 'porc_desc', 'importe_desc', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  descuentos: any = [];

  descuento: DescuentosPD;

  descuento$: Subscription;
  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<IlumiProductEditComponent>,
    private _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    console.log('descuento detalle', data);

    this.producto = new Item();

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.descuento = new DescuentosPD();
  }

  ngOnInit(): void {
    this.obtenerDescuentos();
    if(this.data.descuentos_pds) {

      this.dataSource = fillTable(this.data.descuentos_pds, this.paginator, this.sort);
      return;
    }

    this.data.descuentos_pds = [];

  }

  obtenerDescuentos(): void {
    this.descuento$ = this._ventasService.obtenerDescuentos().subscribe(
      response => this.descuentos = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  agregarDescuento(f: NgForm) {

    if(this.data.PCD_PREUNI == 0) {
      this._snackBarService.showError('Es necesario establecer el precio del producto', 'Ok');
      return;
    }

    if(!this.descuento.dco_coddco) {
      this._snackBarService.showError('Es necesario seleccionar el tipo de descuento', 'Ok');
      return;
    }

    const descuento = new DescuentosPD();

    descuento.dco_coddco = this.descuento.dco_coddco;
    // descuento.dco_desdco = (this.descuento.dco_desdco == "0001")? "DESCUENTO ESPECIAL": "DESCUENTO POR REGALO";
    descuento.pds_pordes = this.descuento.pds_pordes;
    descuento.pds_impdes = this.data.PCD_VALVTA * (descuento.pds_pordes / 100);

    this.data.descuentos_pds.push(descuento);

    this.dataSource = fillTable(this.data.descuentos_pds, this.paginator, this.sort);

    this.data.PCD_VALVTA = this.data.PCD_VALVTA - this.data.PCD_VALVTA * (descuento.pds_pordes / 100);
    this.data.PCD_VALVTA = this.data.PCD_VALVTA.toFixed(2);

    this.data.PCD_IMPDES = this.data.PCD_IMPBRU - this.data.PCD_VALVTA;
    this.data.PCD_IMPDES = this.data.PCD_IMPDES.toFixed(2);

    this.data.PCD_PORDES = (this.data.PCD_IMPDES * 100 ) / this.data.PCD_IMPBRU;
    this.data.PCD_PORDES = this.data.PCD_PORDES.toFixed(2);

    this.data.PCD_IMPTOT = this.data.PCD_CANSOL * this.data.PCD_PRUIGV - this.data.PCD_IMPDES;
    this.data.PCD_IMPTOT = this.data.PCD_IMPTOT.toFixed(2);

    this.data.PCD_IMPIGV = this.data.PCD_IMPTOT - this.data.PCD_VALVTA;
    this.data.PCD_IMPIGV = this.data.PCD_IMPIGV.toFixed(2);

    descuento.pds_coduse = this._authService.getUsuarioSistema();
    descuento.pds_pordes = this.descuento.pds_pordes;
    descuento.pds_impdes = descuento.pds_impdes;
    descuento.pds_incdes = (descuento.pds_impdes * 1.18);

    descuento.pds_porori = 0;
    descuento.pds_impcom = 0;
    // descuento.pds_impcom = "01"

  }

  eliminarDescuento(i) {

    let descuentos = []
    this.data.descuentos_pds.forEach((element, index) => {
      if(i != index) descuentos.push(element)
    })

    let element = this.data.descuentos_pds[i];

    this.data.PCD_VALVTA = parseFloat(this.data.PCD_VALVTA) + element.amount;
    this.data.PCD_VALVTA = this.data.PCD_VALVTA.toFixed(2);

    this.data.PCD_IMPDES = this.data.PCD_IMPBRU - this.data.PCD_VALVTA;
    this.data.PCD_IMPDES = this.data.PCD_IMPDES.toFixed(2);

    this.data.PCD_PORDES = (this.data.PCD_IMPDES * 100 ) / this.data.PCD_IMPBRU;
    this.data.PCD_PORDES = this.data.PCD_PORDES.toFixed(2);

    this.data.PCD_IMPTOT = this.data.PCD_CANSOL * this.data.PCD_PRUIGV - this.data.PCD_IMPDES;
    this.data.PCD_IMPTOT = this.data.PCD_IMPTOT.toFixed(2);

    this.data.PCD_IMPIGV = this.data.PCD_IMPTOT - this.data.PCD_VALVTA;
    this.data.PCD_IMPIGV = this.data.PCD_IMPIGV.toFixed(2);

    this.data.descuentos_pds = descuentos;

    this.dataSource = fillTable(this.data.descuentos_pds, this.paginator, this.sort);

  }

  confirmar(): void {
    this.data.descuentos_pds.forEach((element, index) => {
      element.dco_coddco = (index + 1).toString();
      if(element.dco_coddco.length == 1) {
        element.dco_coddco = "000" + element.dco_coddco;
      }
      if(element.dco_coddco.length == 2) {
        element.dco_coddco = "00" + element.dco_coddco;
      }
      if(element.dco_coddco.length == 3) {
        element.dco_coddco = "0" + element.dco_coddco;
      }
    })
    this.dialogRef.close();
  }

  cambioDeCantidad(): void {
    this.data.PCD_IMPBRU = this.data.PCD_CANSOL * this.data.PCD_PREUNI;
    this.data.PCD_IMPBRU = this.data.PCD_IMPBRU.toFixed(2);
    this.data.PCD_VALVTA = this.data.PCD_IMPBRU - this.data.PCD_IMPDES;
    this.data.PCD_VALVTA = this.data.PCD_VALVTA.toFixed(2);
    this.data.PCD_IMPTOT = this.data.PCD_CANSOL * this.data.PCD_PRUIGV - this.data.PCD_IMPDES;
    this.data.PCD_IMPTOT = this.data.PCD_IMPTOT.toFixed(2);
    this.data.PCD_IMPIGV = this.data.PCD_IMPTOT - this.data.PCD_VALVTA;
    this.data.PCD_IMPIGV = this.data.PCD_IMPIGV.toFixed(2);
  }

  cambioDeParametroPrecio(): void {
    this.data.PCD_PRUIGV = this.data.PCD_PREUNI * 1.18;
    this.data.PCD_PRUIGV = this.data.PCD_PRUIGV.toFixed(2);
    this.data.PCD_IMPBRU = this.data.PCD_CANSOL * this.data.PCD_PREUNI;
    this.data.PCD_IMPBRU = this.data.PCD_IMPBRU.toFixed(2);
    this.data.PCD_VALVTA = this.data.PCD_IMPBRU - this.data.PCD_IMPDES;
    this.data.PCD_VALVTA = this.data.PCD_VALVTA.toFixed(2);
    this.data.PCD_IMPTOT = this.data.PCD_CANSOL * this.data.PCD_PRUIGV - this.data.PCD_IMPDES;
    this.data.PCD_IMPTOT = this.data.PCD_IMPTOT.toFixed(2);
    this.data.PCD_IMPIGV = this.data.PCD_IMPTOT - this.data.PCD_VALVTA;
    this.data.PCD_IMPIGV = this.data.PCD_IMPIGV.toFixed(2);
  }

  cambioDeParametroPrecioIgv(): void {
    this.data.PCD_PREUNI = this.data.PCD_PRUIGV / 1.18;
    this.data.PCD_PREUNI = this.data.PCD_PREUNI.toFixed(2);
    this.data.PCD_IMPBRU = this.data.PCD_CANSOL * this.data.PCD_PREUNI;
    this.data.PCD_IMPBRU = this.data.PCD_IMPBRU.toFixed(2);
    this.data.PCD_VALVTA = this.data.PCD_IMPBRU - this.data.PCD_IMPDES;
    this.data.PCD_VALVTA = this.data.PCD_VALVTA.toFixed(2);
    this.data.PCD_IMPTOT = this.data.PCD_CANSOL * this.data.PCD_PRUIGV - this.data.PCD_IMPDES;
    this.data.PCD_IMPTOT = this.data.PCD_IMPTOT.toFixed(2);
    this.data.PCD_IMPIGV = this.data.PCD_IMPTOT - this.data.PCD_VALVTA;
    this.data.PCD_IMPIGV = this.data.PCD_IMPIGV.toFixed(2);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.descuento$
    ])
  }
}
