import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { OportunidadService } from 'src/app/services/api/crm/operaciones/crm.operaciones.oportunidad.service';
import { VendedorService } from 'src/app/services/api/ventas/maestros/vendedor.service';
import { ConfigurationService } from 'src/app/services';

@Component({
  selector: 'oportunidad-list',
  templateUrl: './oportunidad-list.component.html',
  styleUrls: ['./oportunidad-list.component.css']
})
export class OportunidadListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'idtkt', 'nomcli', 'asunto', 'detalle'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonName: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  registros$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _oportunidadService: OportunidadService,
    private _configurationService: ConfigurationService,
    private _vendedorService: VendedorService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.loadData();
      }
    })
  }

  loadData(): void {
    this.loaderData = true;
    this.registros$ = this._oportunidadService.obtenerRegistrosOportunidad().subscribe(
      oportunidades => {
        this.dataSource = fillTable(oportunidades, this.paginator, this.sort)
        console.log('registros de oportunidad', oportunidades)

        this._vendedorService.listarVendedores().subscribe(
          vendedores => {
            console.log('vendedores', vendedores)
            console.log('usuariosistema', this._configurationService.obtenerIdUsuario())
            const usuario = this._configurationService.obtenerIdUsuario();
            const vendedor = vendedores.find(vendedor => vendedor.S10_USUARIO == usuario)

            this.loaderData = false;

            if(vendedor) {

              const oportunidadesFiltradas = oportunidades.filter(oportunidad => oportunidad.codvde == vendedor.VDE_CODVDE)
              this.dataSource = fillTable(oportunidadesFiltradas, this.paginator, this.sort)

              return;
            }

            this.dataSource = fillTable(oportunidades, this.paginator, this.sort)
            this.loaderData = false;

            console.log('vendedor', vendedor)
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.registros$
    ])
  }

}
