import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContabilidadService, GeneralService, AlmacenService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable } from '@utils/tables/table';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-consulta-movimiento-cuenta',
  templateUrl: './consulta-movimiento-cuenta.component.html',
  styleUrls: ['./consulta-movimiento-cuenta.component.css']
})
export class ConsultaMovimientoCuentaComponent implements OnInit, OnDestroy {

  displayedColumns2: string[] = ['mes', 'subdiario_correlativo', 'item_detalle', 'documento', 'cod_auxiliar', 'auxiliar', 'cta_cte', 'debe', 'haber', 'fecha_emision', 'ce_co', 'glosa'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  cuentas: any[] = [];
  subdiarios: any[] = [];
  tiposDoc: any[] = [];
  auxiliares: any[] = [];
  monedas: any[] = [];
  centros: any[] = [];

  constructor(
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _almacenService: AlmacenService
  ) {

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);

  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.loadMaestros();
  }

  loadMaestros(): void {

    forkObs(
      this._contabilidadService.listarPlanDeCuentas(),
      this._contabilidadService.obtenerSubdiarios(),
      this._contabilidadService.obtenerTiposDocumento(),
      this._generalService.obtenerMonedas(),
      this._almacenService.listarAuxiliares(),
      this._contabilidadService.listarCentroCostos()
    ).then(data => {
      this.cuentas = data[0];
      this.subdiarios = data[1];
      this.tiposDoc = data[2];
      this.monedas = data[3];
      this.auxiliares = data[4];
      this.centros = data[5];

    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK")
    })

  }

  ngOnDestroy(): void {

  }

}
