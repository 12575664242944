import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-genera-asiento',
  templateUrl: './genera-asiento.component.html',
  styleUrls: ['./genera-asiento.component.css']
})
export class GeneraAsientoComponent implements OnInit {

  tipoAsiento: any;

  constructor() {
    this.tipoAsiento = "1";
  }

  ngOnInit(): void {
  }

  radioChange(event): void {
  }
}
