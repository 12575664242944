import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Driver } from 'src/app/models';
import { AuthenticationService, NominaService } from 'src/app/services';
import { Store } from '@ngrx/store';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { LiquidacionCTS } from 'src/app/models/planilla/liquidacion-cts';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-liquidacion-cts',
  templateUrl: './liquidacion-cts.component.html',
  styleUrls: ['./liquidacion-cts.component.css']
})
export class LiquidacionCtsComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  clientes: any[] = [];
  monedas: any[] = [];

  minDate: Date;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  liquidacion: LiquidacionCTS;

  colaboradores: any[] = [];

  liquidacion$: Subscription;
  colaborador$: Subscription;
  period$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.liquidacion = new LiquidacionCTS();

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.loadMaestros();
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
        if(id == "0") return;

        this.obtenerLiquidacionCTS();

      }
    )
  }

  obtenerLiquidacionCTS(): void {
    this.liquidacion$ = this._nominaService.obtenerLiquidacionCTS(this.uid).subscribe(
      ([response]) => {
        this.liquidacion = response;

        this.loadMaestros();
      },
      error => this._snackBarService.showError("Error al obtener liquidación CTS", "OK")
    )
  }

  /**
   * Obiene los maestros de :
   * . colaboradores
   */
  loadMaestros(): void {
    this.colaborador$ = this._nominaService.listarColaboradores().subscribe(
      colaboradores => this.colaboradores = colaboradores,
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  trackByColaborador(index, colaborador): string {
    return colaborador? colaborador.codcli: undefined;
  }

  registrarChofer(f: NgForm): void {
    if(validarPeriodo(new Date(), this.year, this.month)) {

    }
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/listado-liquidacion-de-cts']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.colaborador$,
      this.liquidacion$,
      this.period$,
      this.loading$
    ]);
  }
}
