import { Component, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { PeriodoNomina } from "src/app/models";
import { AuthenticationService, ConfigurationService, NominaService } from "src/app/services";

@Component({
  selector: "app-periodo-nomina-create",
  templateUrl: "./periodo-nomina-create.component.html",
  styleUrls: ["./periodo-nomina-create.component.scss"]
})
export class PeriodoNominaCreateComponent implements OnDestroy{

  loaderReg: boolean = false;
  loaderData: boolean = false;

  loaderInicializa: boolean = false;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  clientes: any[] = [];
  monedas: any[] = [];

  minDate: Date;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  periodo: PeriodoNomina;

  colaboradores: any[] = [];
  tipos: any[] = [];
  frecuencias: any[] = [];

  period$: Subscription;
  loading$: Subscription;
  tipo$: Subscription;
  frecuencia$: Subscription;
  periodoNomina$: Subscription;
  registraPeriodo$: Subscription;
  actualizaPeriodo$: Subscription;
  secuencia$: Subscription;
  inicializaPlanilla$: Subscription;

  uid: string;

  typeNomina: string;
  secNomina: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService
  ) {

    this.periodo = new PeriodoNomina();
    this.periodo.codcia = this._configurationService.obtenerCompaniaCliente();
    this.periodo.coduse = this._configurationService.obtenerIdUsuario();

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
      this.periodo.codano = year;
      this.periodo.codmes = month;
      this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
      this.periodo.fecini = this.minDate;
    })

    this.secuencia$ = this.store.select('nomina').subscribe(state => {
      this.typeNomina = state.typeNomina;
      this.secNomina = state.secNomina;
    })

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.loadMaestros();
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
        if(id == "0") {
          // this.periodo.codnpe = `${this.year}${this.month}${this.typeNomina}${this.secNomina}`;
          return
        };

      }
    )
  }

  obtenerPeriodoNomina(): void {
    this.periodoNomina$ = this._nominaService.obtenerPeriodoNomina(this.uid).subscribe(
      response => {
        this.periodo = response[0];
        this.periodo.codnpe = response[0].codnpe;
      },
      error => {
        this._snackBarService.showError("Error al obtener liquidación CTS", "OK");
      }
    )
  }

  /**
   * Obtiene los maestros de :
   * . Tipos de nómina
   * . frecuencias
   */
  loadMaestros(): void {

    forkObs(
      this._nominaService.obtenerTiposNomina(),
      this._nominaService.obtenerFrecuencias()
    ).then(data => {
      this.tipos = data[0];

      this.frecuencias = data[1];
      if( this.uid != '0' ) this.obtenerPeriodoNomina();
    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK");
    })

  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;
    this.periodo.indcie? this.periodo.indcie = 1: this.periodo.indcie = 0;

    this.uid == '0' ? this.registrarPeriodoNomina(f) : this.actualizarPeriodoNomina(f);

  }

  registrarPeriodoNomina(f: NgForm): void {
    this.registraPeriodo$ = this._nominaService.registrarPeriodoNomina(this.periodo).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Período nómina registrado', '400px', '396px', '')
        this.loaderReg = false;
        f.resetForm();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )

  }

  actualizarPeriodoNomina(f: NgForm): void {
    this.actualizaPeriodo$ = this._nominaService.actualizarPeriodoNomina(this.periodo).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Período nómina actualizada', '400px', '396px', '')
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )
  }

  inicializaPlanilla(): void {

    if(this.secNomina != '01') {
      this._snackBarService.showError('La secuencia debe terminar en 01', 'Ok');
      return;
    }

    this.loaderInicializa = true;
    const payload = {
      codcia: this._configurationService.obtenerCompaniaCliente(),
      codnpe: this.periodo.codnpe,
    }

    this.inicializaPlanilla$ = this._nominaService.inicializaPlanilla(payload).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Inicializa planilla exitoso', '400px', '400px', '');
        this.loaderInicializa = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderInicializa = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/listado-de-periodos-de-nomina']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.tipo$,
      this.frecuencia$,
      this.loading$,
      this.registraPeriodo$,
      this.actualizaPeriodo$,
      this.secuencia$,
      this.inicializaPlanilla$,
    ]);
  }
}
