import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-valorizacion-inventario',
  templateUrl: './valorizacion-inventario.component.html',
  styleUrls: ['./valorizacion-inventario.component.css']
})
export class ValorizacionInventarioComponent implements OnInit, OnDestroy {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;

  displayedColumns: string[] = ['acciones', 'anio', 'mes', 'almacen', 'tipo_doc', 'parte', 'cod_producto', 'item', 'secuencia', 'descripcion_producto', 'cantidad', 'costo_unitario', 'motivo'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['acciones', 'anio', 'mes', 'almacen', 'tipo_doc', 'parte', 'cod_producto', 'item', 'secuencia', 'descripcion_producto', 'cantidad', 'costo_unitario', 'motivo'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  displayedColumns2: string[] = ['acciones', 'anio', 'mes', 'almacen', 'tipo_doc', 'parte', 'cod_producto', 'item', 'secuencia', 'descripcion_producto', 'cantidad', 'costo_unitario', 'motivo'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  loading$: Subscription;

  constructor(
    private _formBuilder: FormBuilder,
    private store: Store<PavsoState>
    ) {
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);
  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state =>{
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  loadData(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }

}
