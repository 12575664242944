<app-pavso-title-section [title]="'Actividad'" [module]="'Producción'" [estado]="actividad.act_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="actividad.act_codact" matInput placeholder="Código" name="act_codact" required [disabled]="true">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input [(ngModel)]="actividad.act_desact" type="text" matInput placeholder="Descripción" name="act_desact" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l5">
          <select-search
          [label]="'Tipo de Actividad'"
          [name]="'tac_codtac'"
          [value]="'tac_codtac'"
          [description]="'description'"
          [data]="tipoActividades"
          (cambioSelect)="actividad.tac_codtac = $event"
          [model]="actividad.tac_codtac"
          [required]="true">
        </select-search>
      </div>
    </div>
    </mat-card>
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-produccion/actividades"
    ></pav-form-actions>

  </form>
</div>
