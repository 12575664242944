import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService, ConfigurationService, VentasService, AlmacenService, GeneralService, ComprasService } from 'src/app/services';
import { IlumiProductDiscountComponent } from '@shared/components/dialogs/ilumi/ilumi-product-discount/ilumi-product-discount.component';
import { IlumiProductListComponent } from '@shared/components/dialogs/ilumi/ilumi-product-list/ilumi-product-list.component';
import { PaymentComponent } from '@shared/components/dialogs/payment/payment.component';
import { DetalleCobranza, DetalleProductoVoucher, Voucher, DetalleDescuentoVoucher } from 'src/app/models';
import { EditProductComponent } from '@shared/components/dialogs/voucher/edit-product/edit-product.component';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { Store } from '@ngrx/store';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { forkObs } from '@utils/observables/fork';
import { CondicionPagoService } from 'src/app/services/api/compras/maestros/condicion-pago.service';

@Component({
  selector: 'app-comprobante-form',
  templateUrl: './comprobante-form.component.html',
  styleUrls: ['./comprobante-form.component.css'],
})
export class ComprobanteFormComponent implements OnInit, OnDestroy {

  hoy = new Date();

  activarCodigoBarra = true;

  vendedores: any[] = [];
  condiciones: any[] = [];

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  loaderDocumentos: boolean = false;
  loaderDiasEntrega: boolean = false;
  loaderProductos: boolean = false;
  loaderDescuentos: boolean = false;

  displayedColumns: string[] = ['acciones', 'campo1', 'campo2', 'campo3', 'campo4', 'campo5', 'campo6', 'campo7','campo8','campo9','campo10','campo11','campo12','campo13','campo14','campo15','campo16','campo17','campo19'];
  dataSource: MatTableDataSource<DetalleProductoVoucher>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  columnsDocumentos: string[] = ['campo1', 'campo2', 'campo3'];
  documentos: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDocumento: MatPaginator;
  @ViewChild(MatSort) sortDocumento: MatSort;

  canales: any = [];
  motivos: any = [];
  companias: any = [];
  monedas: any = [];
  zonasVenta: any = [];
  tiposPrecio: any = [];
  clientes: any = [];
  lugaresDespacho: any = [];
  diasEntrega: any = [];
  formasCalculoItem: any = [];
  almacenes: any = [];
  sucursales: any = [];
  tiendas: any = [];
  tipoDocumentosVenta: any = [];

  nameClient = "";
  rucClient: any = null;
  dniClient: any = null;

  productos: Array<DetalleProductoVoucher> = [];
  producto: DetalleProductoVoucher;

  // DATOS DE CONSULTA DE ALMACEN
  cod_prod: String;
  prod: String;
  fisico: Number;
  disponible: Number;

  @ViewChild(MatRipple) ripple: MatRipple;
  direccion: string = "";

  voucher: Voucher;
  detalleCobranza: DetalleCobranza;

  serie: any;

  valor_venta: any;

  estaActivoReferencia: boolean = false;
  montoDolares: any = 0;
  montoSoles: any = 0;

  minDate: Date;
  maxDate: Date;

  loaderData: boolean;
  loaderReg: boolean;

  motivo$: Subscription;
  compania$: Subscription;
  moneda$: Subscription;
  vendedor$: Subscription;
  condicionPago$: Subscription;
  tipoPrecio$: Subscription;
  calculaItem$: Subscription;
  almacen$: Subscription;
  tienda$: Subscription;
  clientes$: Subscription;
  tipoDocVenta$: Subscription;
  cuentaContVenta$: Subscription;
  tipoCambio$: Subscription;
  consultaStock$: Subscription;
  producto$: Subscription;
  referenciaDoc$: Subscription;
  listaPrecio$: Subscription;
  crearComprobante$: Subscription;
  detalleCliente$: Subscription;
  comprobante$: Subscription;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  period$: Subscription;
  loading$: Subscription;

  uid: string;

  isTablet: boolean = false;
  isMobile: boolean = false;

  constructor(
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _productoService: ProductoService,
    private _almacenService: AlmacenService,
    private _clienteService: ClienteService,
    private _generalService: GeneralService,
    private _comprasService: ComprasService,
    private _condicionPagoService: CondicionPagoService,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _authenticationService: AuthenticationService,
    private _snackBarService: SnackBarService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    public dialog: MatDialog,
    private store: Store<PavsoState>
    ) {

      this.period$ = this.store.select('period').subscribe(({year, month}) => {
        this.year = year;
        this.month = month;
      })

      this.voucher = new Voucher();

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

      this.maxDate = new Date(currentYear, currentMonth);

      this.dataSource = fillTable([], this.paginator, this.sort);

      this.documentos = fillTable([], this.paginatorDocumento, this.sortDocumento);

      this.voucher.detalle_dcc[0].DCC_CODUSE = this._authService.getUsuarioSistema();

      this.detalleCobranza = new DetalleCobranza();

      this.voucher.TDO_CODTDO = "FAC"; // JSON.parse(localStorage.getItem('voucher')).typeDocument;

      (this.voucher.TDO_CODTDO == "FAC" || this.voucher.TDO_CODTDO == "BVE")? this.estaActivoReferencia = false : this.estaActivoReferencia = true;

      this.serie = '0001'; //JSON.parse(localStorage.getItem('voucher')).serie;
      this.voucher.CCO_GLOCCO = `DOC: ${this.voucher.TDO_CODTDO}/${this.serie}`;

      this.voucher.CCO_CODUSE = this._authService.getUsuarioSistema();
      this.voucher.CLI_CODDOC = this._configurationService.obtenerCiaNumRuc();

      this.voucher.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

      this.producto = new DetalleProductoVoucher();

    }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this.loaderData = true;

    this._activatedRoute.params.subscribe(({id}) => this.uid = id);

    this.btnName = this.uid == '0'? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;

    this.loadMaestros();
  }

  loadMaestros(): void {

    forkObs(
      this._clienteService.obtenerClientes(),
      this._almacenService.obtenerMotivos(),
      this._generalService.obtenerCompanias(),
      this._ventasService.obtenerVendedores(),
      this._condicionPagoService.obtenerCondicionesPago(),
      this._ventasService.obtenerTiposPrecio(),
      this._ventasService.obtenerFormaCalculaItem(),
      this._almacenService.obtenerAlamacenes(),
      this._ventasService.listarTiendas(),
      this._ventasService.listarTipoDocumentosVenta(),
      this._ventasService.obtenerCuentaContableVenta(),
      this._ventasService.obtenerTipoCambio(formatDateWithDash(this.voucher.CCO_FECREG))
    ).then(data => {
      this.clientes = data[0];
      this.motivos = data[1];
      this.companias = data[2];
      this.vendedores = data[3];
      this.condiciones = data[4];
      this.tiposPrecio = data[5];
      this.formasCalculoItem = data[6];
      this.almacenes = data[7];
      this.tiendas = data[8];
      this.tipoDocumentosVenta = data[9];
      this.voucher.CCN_CODCCN = data[10][0].ccn_codmn;

      this.voucher.CCO_TIPCAM = data[11][0].tipcam;
      this.voucher.detalle_dcc[0].DCC_TCALIB = data[11][0].tipcam;
      localStorage.setItem("tipo-cambio", this.voucher.CCO_TIPCAM);
      if(this.uid != '0') {
        this.obtenerComprobante();
        return;
      }
      this.loaderData = false;
    }).catch(error => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
      this.loaderData = false;
    })
  }

  agregarProductoModal(): void {}
  agregarProducto(): void {}
  eliminarProductos(): void {}
  insertarProducto(): void {}

  obtenerComprobante(): void {
    this.comprobante$ = this._ventasService.obtenerComprobante(this.uid).subscribe(
      comprobante => {
        console.log('comprobante', comprobante);
        if(comprobante.length > 0) {
          this.voucher = comprobante[0];
          this.voucher.CLI_CODCLI = this.voucher.CLI_CODCLI.trim();
          this.voucher.CCO_NUMDOC = this.voucher.CCO_NUMDOC.trim();
          this.dataSource = fillTable(this.voucher.detalle_productos, this.paginator, this.sort);
        }
        this.loaderData = false;
        console.log()
      },
      error => {
        this._snackBarService.showError('Error al obtener comprobante', 'Ok');
        this.loaderData = false;
      }
    )
  }

  seleccionarFecha(): void {
    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.voucher.CCO_FECREG)).subscribe(
      tc => {
        this.voucher.CCO_TIPCAM = tc[0].tipcam;
        this.voucher.detalle_dcc[0].DCC_TCALIB = tc[0].tipcam;
        localStorage.setItem("tipo-cambio", this.voucher.CCO_TIPCAM);
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  registrarComprobante(f: NgForm): void {

    if(this.voucher.detalle_productos.length > 0) {
      if(validarPeriodo(new Date(this.voucher.CCO_FECREG), this.year, this.month)) {
        this.productos.forEach((producto, index) => {
          producto.DCD_SECDCD = "01",
          producto.DCD_CORDCD = (index + 1).toString();
          producto.DCD_CODUSE = (this._authenticationService.getIdentity()).usuariosistema;
          producto.DCD_FECUPD = new Date();

          if(producto.DCD_CORDCD.length == 1) producto.DCD_CORDCD = "00" + producto.DCD_CORDCD;
          if (producto.DCD_CORDCD.length == 2) producto.DCD_CORDCD = "0" + producto.DCD_CORDCD;

          let descuentoEspecial = [];
          let descuentoRegalo = [];

          producto.detalle_descuento.forEach((descuento, i) => {
            descuento.ddd_corddd = (i + 1).toString();

            (descuento.dco_coddco == "0001")? descuentoEspecial.push(descuento): descuentoRegalo.push(descuento);


            if(descuento.ddd_corddd.length == 1) descuento.ddd_corddd = "0" + descuento.ddd_corddd;
            if (descuento.ddd_corddd.length == 2) descuento.ddd_corddd = "" + descuento.ddd_corddd;

          });

          let descuentos: Array<DetalleDescuentoVoucher> = [];

          let impDescEspecialIgv = 0;
          let impDescEspecial = 0;

          descuentoEspecial.forEach(element => {
            impDescEspecialIgv += parseFloat(element.ddd_incdes);
            impDescEspecial += parseFloat(element.ddd_impdes);
          });

          let porcentajeEspecial = (100 * impDescEspecialIgv) / parseFloat(producto.DCD_PRUIGV);

          if(descuentoEspecial.length > 0) {
            let descuentoEsp = new DetalleDescuentoVoucher();

            descuentoEsp.dco_coddco = "0001";
            descuentoEsp.ddd_pordes = porcentajeEspecial;
            descuentoEsp.ddd_impdes = impDescEspecial;
            descuentoEsp.ddd_corddd = "01";
            descuentoEsp.ddd_impaju = 0;
            descuentoEsp.ddd_incdes = impDescEspecialIgv;
            descuentoEsp.ddd_fecupd = new Date();
            descuentoEsp.ddd_coduse = this._authService.getUsuarioSistema();
            descuentoEsp.ddd_indsta = "1";

            descuentos.push(descuentoEsp);
          }

          let impDescRegaloIgv = 0;
          let impDescRegalo = 0;

          descuentoRegalo.forEach(element => {
            impDescRegaloIgv += parseFloat(element.ddd_incdes);
            impDescRegalo += parseFloat(element.ddd_impdes);
          });

          let porcentajeRegalo = (100 * impDescRegaloIgv) / parseFloat(producto.DCD_PRUIGV);

          if(descuentoRegalo.length > 0) {
            let descuentoReg = new DetalleDescuentoVoucher();

            descuentoReg.dco_coddco = "0002";
            descuentoReg.ddd_pordes = porcentajeRegalo;
            descuentoReg.ddd_impdes = impDescRegalo;
            descuentoReg.ddd_corddd = "02";
            descuentoReg.ddd_impaju = 0;
            descuentoReg.ddd_incdes = impDescRegaloIgv;
            descuentoReg.ddd_fecupd = new Date();
            descuentoReg.ddd_coduse = this._authService.getUsuarioSistema();
            descuentoReg.ddd_indsta = "1";

            descuentos.push(descuentoReg);

          }

          producto.detalle_descuento = descuentos;

        });

        this.voucher.detalle_dcc[0].DCC_INDFGI = (this.voucher.detalle_dcc[0].DCC_INDFGI)? 1: 0;
        this.voucher.detalle_dcc[0].DCC_INDEXP = (this.voucher.detalle_dcc[0].DCC_INDEXP)? 1: 0;
        this.voucher.detalle_dcc[0].DCC_INDTEX = (this.voucher.detalle_dcc[0].DCC_INDTEX)? "1": "0";
        this.voucher.detalle_dcc[0].DCC_INDPSA = (this.voucher.detalle_dcc[0].DCC_INDPSA)? 1: 0;

        this.voucher.detalle_cobranza = null;
        this.voucher.detalle_dcc[0].LPC_CODLPC = this.voucher.detalle_dcc[0].LPC_CODLPC.toString();
        this.voucher.detalle_dcc[0].LPC_CODLPC.replace(/"/g, "'");;

        this._ventasService.obtenerCorrelativoComprobante(this.voucher.TDO_CODTDO, this.serie).subscribe(
          response => {

            this.voucher.CCO_NUMDOC = response[0].nro_doc;

            this.crearComprobante$ = this._ventasService.crearComprobante(this.voucher).subscribe(
              response => {
                this._dialogService.openDialog(SuccessComponent, 'Comprobante Registrado', '300px', '', '');
                f.resetForm();
                this.volver();
              },
              error => this._snackBarService.showError(error.error.msg, 'OK')
            )
          },
          error => this._snackBarService.showError(error.error.msg, 'OK')
        )
      } else {
        this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK');
      }
    } else {
      this._snackBarService.showError('Ingresar al menos un producto', 'OK');
    }

  }

  seleccionarCondicionPago(id): void {

    this.condiciones.forEach(condicion => {

      if(condicion["CPA_CODCPA"] == id.value) {
        let now = new Date();

        this.voucher.CCO_FECVEN = new Date(this.voucher.CCO_FECVEN.setDate(this.voucher.CCO_FECVEN.getDate() + condicion["CPA_DIAPLA"]));
      }
    });

  }

  seleccionDeLista(tipo_lista): void {
    this.listaPrecio$ = this._ventasService.listaDePrecios(tipo_lista).subscribe(
      response => {
        if(response.length > 0) this.voucher.detalle_dcc[0].LPC_CODLPC = response[0].LPC_CODLPC
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  seleccionarCliente(): void {

    if(this.voucher.CLI_CODCLI.length != 11 && this.voucher.TDO_CODTDO == "FAC") {
      this._snackBarService.showError("SELECCIONAR UNA CLIENTE CON FACTURA", "OK");
      return;
    }

    this.detalleCliente$ = this._ventasService.detalleCliente(this.voucher.CLI_CODCLI).subscribe(
      cliente => {
        this.nameClient = cliente[0].cli_nomcli;
        this.rucClient = cliente[0].cli_numruc;
        this.dniClient = cliente[0].cli_numdni;
        this.voucher.detalle_dcc[0].VDE_CODVDE = cliente[0].VDE_CODVDE;
        this.voucher.CPA_CODCPA = cliente[0].CPA_CODCPA;
        this.voucher.detalle_dcc[0].ZVE_CODZVE = cliente[0].ZVE_CODZVE;
        this.voucher.detalle_dcc[0].TLP_CODTLP = cliente[0].tlp_codtlp;
        this.direccion = cliente[0].cli_dircli;
        this.voucher.detalle_dcc[0].LDE_CODLDE = null;

        this.referenciaDoc$ = this._ventasService.obtenerReferenciaDocumento(this.voucher.TDO_CODTDO, this.voucher.CLI_CODCLI).subscribe(
          response => {
            console.log('referencia doc', response)
          },
          error => this._snackBarService.showError(error.error.msg, 'OK')
        )

        if(cliente[0].cli_numruc) this.voucher.TDO_CODTDO = "FAC";

        if(cliente[0].cli_numdni) this.voucher.TDO_CODTDO = "BVE";

        this.seleccionDeLista(this.voucher.detalle_dcc[0].TLP_CODTLP);

      },
      error =>  {
        this._snackBarService.showError('Error al encontrar cliente', 'Ok');
      }
    )


  }

  seleccionarProducto(): void {

    const dialogRef = this.dialog.open(IlumiProductListComponent, {
      width: '800px',
      data: {},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        let validator = false;
        this.productos.forEach(element => {
          if(element.PRD_CODPRD == result.prd_codprd) {
            validator = true;
            element.DCD_CANVEN++;
            element.DCD_CANDCD = element.DCD_CANVEN;
            element.DCD_CANDES = element.DCD_CANVEN;
            element.DCD_CANDEV = 0;
            element.DCD_CANPED = 0;
            element.DCD_CANREG = 0;
            element.DCD_DESGLO = 0;
            element.DCD_GLODCD = "";
            element.DCD_IMPDIF = 0;
            element.DCD_IMPPER = 0;
            element.DCD_INDADE = 0;
            element.DCD_TASPER = 0;
            element.DCD_VALDIF = 0;
            element.DCD_VENDEV = 0;

            element.DCD_IMPBRU = element.DCD_CANVEN * element.DCD_PREUNI;
            element.DCD_IMPBRU = element.DCD_IMPBRU.toFixed(2);
            element.DCD_VALVTA = element.DCD_IMPBRU - element.DCD_IMPDES;
            element.DCD_VALVTA = element.DCD_VALVTA.toFixed(2);
            element.DCD_IMPTOT = element.DCD_CANVEN * element.DCD_PRUIGV - element.DCD_IMPDES;
            element.DCD_IMPTOT = element.DCD_IMPTOT.toFixed(2);
            element.DCD_IMPIGV = element.DCD_IMPTOT - element.DCD_VALVTA;
            element.DCD_IMPIGV = element.DCD_IMPIGV.toFixed(2);
            element.DCD_PREPED = element.DCD_PRUIGV;
            element.DCD_PRUPED = element.DCD_PREUNI;

          }
        })

        if(!validator) {

          let producto = new DetalleProductoVoucher();

          producto.PRD_CODPRD = result.prd_codprd;
          producto.UME_CODVEN = result.ume_codume;
          producto.prd_desesp = result.prd_desesp;

          producto.DCD_CANVEN = 1;
          producto.DCD_CANDCD = producto.DCD_CANVEN;
          producto.DCD_CANDES = producto.DCD_CANVEN;
          producto.DCD_CANDEV = 0;
          producto.DCD_CANPED = 0;
          producto.DCD_CANREG = 0;
          producto.DCD_DESGLO = 0;
          producto.DCD_GLODCD = "";
          producto.DCD_IMPDIF = 0;
          producto.DCD_IMPPER = 0;
          producto.DCD_INDADE = 0;
          producto.DCD_TASPER = 0;
          producto.DCD_VALDIF = 0;
          producto.DCD_VENDEV = 0;

          producto.DCD_IMPDES = 0;
          producto.DCD_PORDES = 0;
          producto.DCD_IMPBRU = producto.DCD_CANVEN * producto.DCD_PREUNI;
          producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;
          producto.DCD_IMPTOT = producto.DCD_CANVEN * producto.DCD_PRUIGV - producto.DCD_IMPDES;
          producto.DCD_IMPIGV = producto.DCD_IMPTOT - producto.DCD_VALVTA;
          producto.DCD_PREPED = producto.DCD_PRUIGV;
          producto.DCD_PRUPED = producto.DCD_PREUNI;

          this.productos.push(producto)
          this.consultarPrecioProducto(producto)

        }

        this.calcularTotales();

        this.dataSource = fillTable(this.productos, this.paginator, this.sort);
        this.voucher.detalle_productos = this.productos;
      }
    })

  }

  searchCode(value): void {

    this.producto$ = this._productoService.obtenerProducto(value).subscribe(
      producto => {

        let validator = false;
        this.productos.forEach(element => {
          if(element.PRD_CODPRD == producto[0].prd_codprd) {
            validator = true;
            element.DCD_CANVEN++;
            element.DCD_CANDCD = element.DCD_CANVEN;
            element.DCD_CANDES = element.DCD_CANVEN;
            element.DCD_CANDEV = 0;
            element.DCD_CANPED = 0;
            element.DCD_CANREG = 0;
            element.DCD_DESGLO = 0;
            element.DCD_GLODCD = "";
            element.DCD_IMPDIF = 0;
            element.DCD_IMPPER = 0;
            element.DCD_INDADE = 0;
            element.DCD_TASPER = 0;
            element.DCD_VALDIF = 0;
            element.DCD_VENDEV = 0;

            element.DCD_IMPBRU = element.DCD_CANVEN * element.DCD_PREUNI;
            element.DCD_IMPBRU = element.DCD_IMPBRU.toFixed(2);
            element.DCD_VALVTA = element.DCD_IMPBRU - element.DCD_IMPDES;
            element.DCD_VALVTA = element.DCD_VALVTA.toFixed(2);
            element.DCD_IMPTOT = element.DCD_CANVEN * element.DCD_PRUIGV - element.DCD_IMPDES;
            element.DCD_IMPTOT = element.DCD_IMPTOT.toFixed(2);
            element.DCD_IMPIGV = element.DCD_IMPTOT - element.DCD_VALVTA;
            element.DCD_IMPIGV = element.DCD_IMPIGV.toFixed(2);
            element.DCD_PREPED = element.DCD_PRUIGV;
            element.DCD_PRUPED = element.DCD_PREUNI;

          }
        })

        if(!validator) {

          let product = new DetalleProductoVoucher();

          product.PRD_CODPRD = producto[0].prd_codprd;
          product.UME_CODVEN = producto[0].ume_codume;
          product.prd_desesp = producto[0].prd_desesp;

          product.DCD_CANVEN = 1;
          product.DCD_CANDCD = product.DCD_CANVEN;
          product.DCD_CANDES = product.DCD_CANVEN;
          product.DCD_CANDEV = 0;
          product.DCD_CANPED = 0;
          product.DCD_CANREG = 0;
          product.DCD_DESGLO = 0;
          product.DCD_GLODCD = "";
          product.DCD_IMPDIF = 0;
          product.DCD_IMPPER = 0;
          product.DCD_INDADE = 0;
          product.DCD_TASPER = 0;
          product.DCD_VALDIF = 0;
          product.DCD_VENDEV = 0;

          product.DCD_IMPDES = 0;
          product.DCD_PORDES = 0;
          product.DCD_IMPBRU = product.DCD_CANVEN * product.DCD_PREUNI;
          product.DCD_VALVTA = product.DCD_IMPBRU - product.DCD_IMPDES;
          product.DCD_IMPTOT = product.DCD_CANVEN * product.DCD_PRUIGV - product.DCD_IMPDES;
          product.DCD_IMPIGV = product.DCD_IMPTOT - product.DCD_VALVTA;
          product.DCD_PREPED = product.DCD_PRUIGV;
          product.DCD_PRUPED = product.DCD_PREUNI;

          this.productos.push(product)
          this.consultarPrecioProducto(product)

        }

        this.calcularTotales();

        this.dataSource = fillTable(this.productos, this.paginator, this.sort);
        this.voucher.detalle_productos = this.productos;

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  consultarPrecioProducto(producto: DetalleProductoVoucher): void {
    let now = new Date();

    let fecha = formatDateWithDash(now);

    if(!this.voucher.detalle_dcc[0].TLP_CODTLP) {
      this._snackBarService.showError('Seleccionar lista', 'OK');
    }

    if(!this.voucher.detalle_dcc[0].LPC_CODLPC) {
      this._snackBarService.showError('Establecer Nro de lista', 'OK');
    }

    this._ventasService.listaDePreciosProducto(producto.PRD_CODPRD, this.voucher.TMO_CODTMO, this.voucher.CLI_CODCLI, this.voucher.detalle_dcc[0].TLP_CODTLP, this.voucher.detalle_dcc[0].LPC_CODLPC,fecha ).subscribe(
      response => {
        if(this.voucher.TMO_CODTMO == "SO") {
          producto.DCD_PREUNI = response[0].pre_prenac;
          producto.DCD_PRUIGV = response[0].pre_vtanac;
          producto.DCD_PREPED = producto.DCD_PRUIGV;
          producto.DCD_PRUPED = producto.DCD_PREUNI;

        } else {
          producto.DCD_PREUNI = response[0].pre_predol;
          producto.DCD_PRUIGV = response[0].pre_vtadol;
          producto.DCD_PREPED = producto.DCD_PRUIGV;
          producto.DCD_PRUPED = producto.DCD_PREUNI;
        }

        producto.DCD_IMPBRU = producto.DCD_CANVEN * producto.DCD_PREUNI;
        producto.DCD_IMPBRU = producto.DCD_IMPBRU.toFixed(2);
        producto.DCD_VALVTA = producto.DCD_IMPBRU - producto.DCD_IMPDES;
        producto.DCD_VALVTA = producto.DCD_VALVTA.toFixed(2);
        producto.DCD_IMPTOT = producto.DCD_CANVEN * producto.DCD_PRUIGV - producto.DCD_IMPDES;
        producto.DCD_IMPTOT = producto.DCD_IMPTOT.toFixed(2);
        producto.DCD_IMPIGV = producto.DCD_IMPTOT - producto.DCD_VALVTA;
        producto.DCD_IMPIGV = producto.DCD_IMPIGV.toFixed(2);

        this.calcularTotales();

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  editarProducto(row: DetalleProductoVoucher): void {

    const dialogRef = this.dialog.open(EditProductComponent, {
      width: '800px',
      data: row,
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      this.productos.forEach(element => {
        if(element.PRD_CODPRD == result.PRD_CODPRD) element.detalle_descuento = result.detalle_descuento
      })

      this.voucher.detalle_productos = this.productos;
      this.dataSource = fillTable(this.productos, this.paginator, this.sort);

      this.calcularTotales();

    })
  }

  eliminarProducto(row): void {
    let productos = [];
    this.productos.forEach(element => {
      if(element.PRD_CODPRD != row.PRD_CODPRD) productos.push(element)
    })

    this.productos = productos;
    this.dataSource = fillTable(this.productos, this.paginator, this.sort);
    this.voucher.detalle_productos = this.productos;

    this.calcularTotales();
  }

  aplicarDescuentos(row): void {
    const dialogRef = this.dialog.open(IlumiProductDiscountComponent, {
      width: '800px',
      data: {},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {})
  }

  seleccionarTipoMoneda(valor): void {
    this.voucher.TMO_CODTMO = valor;
    this.cuentaContVenta$ = this._ventasService.obtenerCuentaContableVenta().subscribe(
      response => this.voucher.CCN_CODCCN = (valor == "SO")? response[0].ccn_codmn: response[0].ccn_codme,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  consultarStock(row): void {

    let now = new Date();

    if(!this.voucher.SUC_CODSUC) {
      this._snackBarService.showError('No se seleccionó la sucursal', 'OK');
      return;
    }

    if(!this.voucher.detalle_dcc[0].ALM_CODALM) {
      this._snackBarService.showError('No se seleccionó el almacen', 'OK');
      return;
    }

    this.consultaStock$ = this._ventasService.consultaStock(this.voucher.SUC_CODSUC, now.getFullYear(), now.getMonth() + 1, this.voucher.detalle_dcc[0].ALM_CODALM, row.PRD_CODPRD).subscribe(
      response => {
        this.cod_prod = row.PRD_CODPRD;
        this.prod = row.PRD_DESESP;
        this.fisico = response[0].fisico;
        this.disponible = response[0].disponible;

        const rippleRef = this.ripple.launch({
          persistent: true,
          centered: true
        });

        rippleRef.fadeOut();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;

    this.productos.forEach(element => {
      valorVenta += Number(element.DCD_VALVTA);
      impBruto += Number(element.DCD_IMPBRU);
      impIGV += Number(element.DCD_IMPIGV);
      descuento += Number(element.DCD_IMPDES);
      impTotal += Number(element.DCD_IMPTOT);
    })

    this.voucher.CCO_IMPAFE = valorVenta;
    this.voucher.CCO_IMPAFE = this.voucher.CCO_IMPAFE.toFixed(2);

    this.voucher.detalle_dcc[0].DCC_IMPBRU = impBruto;
    this.voucher.detalle_dcc[0].DCC_IMPBRU = this.voucher.detalle_dcc[0].DCC_IMPBRU.toFixed(2);

    this.voucher.CCO_IMPIGV = impIGV;
    this.voucher.CCO_IMPIGV = this.voucher.CCO_IMPIGV.toFixed(2);

    this.voucher.detalle_dcc[0].DCC_IMPDES = descuento;
    this.voucher.detalle_dcc[0].DCC_IMPDES = this.voucher.detalle_dcc[0].DCC_IMPDES.toFixed(2);

    this.voucher.CCO_IMPDOC = impTotal;
    this.voucher.CCO_IMPDOC = this.voucher.CCO_IMPDOC.toFixed(2);

    this.voucher.detalle_dcc[0].DCC_VALVTA = this.voucher.CCO_IMPAFE + this.voucher.CCO_IMPINA;

    this.montoSoles = this.voucher.detalle_dcc[0].DCC_VALVTA;
    this.montoDolares = (this.voucher.detalle_dcc[0].DCC_VALVTA / this.voucher.CCO_TIPCAM).toFixed(2);
    this.voucher.CCO_IMPABO = this.voucher.detalle_dcc[0].DCC_VALVTA;
    this.voucher.CCO_IMPCCO = this.voucher.CCO_IMPDOC;

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  cobranza(): void {
    const dialogRef = this.dialog.open(PaymentComponent, {
      width: '100%',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {})
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/comprobantes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.motivo$,
      this.compania$,
      this.moneda$,
      this.vendedor$,
      this.condicionPago$,
      this.tipoPrecio$,
      this.calculaItem$,
      this.almacen$,
      this.tienda$,
      this.tipoDocVenta$,
      this.cuentaContVenta$,
      this.tipoCambio$,
      this.consultaStock$,
      this.referenciaDoc$,
      this.listaPrecio$,
      this.crearComprobante$,
      this.period$
    ])
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
