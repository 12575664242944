<app-pavso-title-section [title]="'Edita deducción'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <div class="pav-table-search-container">
    <div class="pav-table-filter">
      <mat-form-field>
        <mat-label>Seleccionar concepto</mat-label>
        <mat-select [(ngModel)]="concepto" name="concepto" (selectionChange)="seleccionarConcepto($event.value)">
          <mat-option *ngFor="let concepto of conceptos; trackBy: trackByConcepto" [value]="concepto.codnco">
            {{concepto.descri}} &nbsp;|&nbsp; ({{concepto.codnco}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>
  <br>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">

    </div>
    <div class="pav-table-header-title">
      Deducciones
    </div>
    <div class="pav-table-header-add">
      <div class="isMobile">
        <div class="pav-button-icon-add" (click)="agregarItem()">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <!-- <button [disabled]="loaderData" mat-flat-button class="pav-button-add-item" type="button" (click)="confirmarTodos()" *ngIf="estanHabilitados">Confirmar todos</button>
        &nbsp;
        <button [disabled]="loaderData" mat-flat-button class="pav-button-add-item" type="button" (click)="editarTodos()" *ngIf="!estanHabilitados">Editar todos</button>
        &nbsp; -->
        <button type="button" mat-flat-button class="pav-button-add-item" (click)="agregarItem()">Agregar ítem</button>
      </div>
    </div>
  </div>
  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarEditaDeducciones(f)"
    (keydown.enter)="$event.preventDefault()">

    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" data-label="Acciones" class="td-responsive">
            <div class="pav-btns-container">
              <!-- <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div> -->
              <!-- <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                <mat-icon svgIcon="confirm"></mat-icon>
              </div> -->
              <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="cli_codcli">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Colaborador" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <select-search
                [label]="''"
                [placeholder]="'Ej. '"
                [name]="'codcli'"
                [value]="'codcli'"
                [description]="'nomcli'"
                [data]="colaboradores"
                (cambioSelect)="row.codcli = $event"
                [model]="row.codcli"
                [multiple]="true"
                >
              </select-search>

            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codcli}} | {{row.nomcli}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="ndt_imprem">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Importe" class="td-responsive pav-td-right">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-mat-form-margin-top">
                <mat-label></mat-label>
                <input #importe (focus)="importe.select()" (blur)="blurImporte(row)" step="any" class="pav-input-align-right" required type="number" min="0" matInput placeholder="Importe" [(ngModel)]="row.imprem"
                [name]="'importe' + i.toString()">
              </mat-form-field>

            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.imprem.toFixed(2)}}
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr (dblclick)="doubleClick($event, row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No se encontraron registros</td>
        </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <div align="end">
      <mat-form-field class="total">
        <mat-label>TOTAL</mat-label>
        <input type="number" class="pav-input-align-right" [(ngModel)]="total" name="total" matInput placeholder="TOTAL" readonly>
      </mat-form-field>
    </div>
    <mat-card class="mat-elevation-z0">
      <button (click)="cargarColaboradores()" class="pav-button-load" mat-flat-button color="primary" type="button">Carga Colaboradores</button>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
  </form>
</div>
