export class EditaDeduccion {
  codcli: string;
  imprem: number | string;
  fecupd: Date;
  isEditing: Boolean;

  constructor() {
    this.imprem = 0;
    this.fecupd = new Date();
    this.isEditing = true;
  }
}
