import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

const CLIENT_ID = "55d70386-c756-4b41-a0cc-3f0346031b25";
const CLIENT_SECRET = "ljUxv+WeEjLBz01a32UchQ==";
const RUC = "20513872012";

const URL_PAVFACTEL = "https://pavservice.com:3976/api";

import axios from 'axios';
import { Auxiliar } from "src/app/models/contabilidad/auxiliar";
import { ConfigurationService } from "../../configuration.service";

@Injectable({
  providedIn: 'root'
})
export class SunatService {


  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerDatosDesdeRUC(ruc): Observable<ConsultaNroDocumento> {
    return this.http.get<any>(`https://pavservice.com/pavso-factura-electronica/public/api/consulta/ruc/${ruc}`)
  }

  obtenerDatosDesdeDNI(dni): Observable<ConsultaNroDocumento> {
    return this.http.get<any>(`https://pavservice.com/pavso-factura-electronica/public/api/consulta/dni/${dni}`)
  }

  generarTokenSUNAT(payload: GeneracionToken): Observable<any> {
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/x-www-form-urlencoded'
    });

    const urlEncodedData = new URLSearchParams();

    for (const key in payload) {
      console.log(`key ${key}, payload[key] ${payload[key]}`)
      urlEncodedData.append(key, payload[key]);
    }

    console.log('urlEncodedData', urlEncodedData.toString())
    console.log('urlEncodedData', urlEncodedData.values())

    // const formData = new HttpParams({fromObject: {
    //   grant_type: payload.grant_type,
    //   scope: payload.scope,
    //   client_id: "55d70386-c756-4b41-a0cc-3f0346031b25",
    //   client_secret: "ljUxv+WeEjLBz01a32UchQ==",
    // }});

    return this.http.post<any>(`https://api-seguridad.sunat.gob.pe/v1/clientesextranet/${CLIENT_ID}/oauth2/token/`,
    urlEncodedData.toString(), {headers});
  }

  consultarValidacionComprobante(payload: ValidacionComprobante, token: string): Promise<any> {
    const headers = {
      'Authorization': `Bearer ${token}`
    };

    return axios.post<any>(`https://api.sunat.gob.pe/v1/contribuyente/contribuyentes/${RUC}/validarcomprobante`, payload, {headers})
  }

  sendFormData(formData: any): Promise<any> {


    const url = `https://api-seguridad.sunat.gob.pe/v1/clientesextranet/${CLIENT_ID}/oauth2/token/`;

    // Convierte los datos en formato x-www-form-urlencoded
    const urlEncodedData = new URLSearchParams();
    for (const key in formData) {
      urlEncodedData.append(key, formData[key]);
    }

    // Configura los encabezados
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    // Realiza la solicitud POST
    return axios.post(url, urlEncodedData.toString(), { headers })
  }

  obtenerMaestrosSunat(catalogo: string): Observable<any> {
    return this.http.get<any>(`${URL_PAVFACTEL}/tablasunat/listar/8/${catalogo}`)

  }

  obtenerAeropuertos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/sunat/listar/64`
    );
  }

}

export class GeneracionToken {

  grant_type: string;
  scope: string;
  client_id: string;
  client_secret: string;

  constructor() {
    this.grant_type = "client_credentials";
    this.scope = "https://api.sunat.gob.pe/v1/contribuyente/contribuyentes";
    this.client_id = CLIENT_ID;
    this.client_secret = CLIENT_SECRET;
  }

}

class GeneracionTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

export class ValidacionComprobante {

  numRuc: string;
  codComp: string;
  numeroSerie: string;
  numero: string;
  fechaEmision: string;
  monto: string;

  constructor() {
    this.codComp = "01";
  }

}

export class ValidacionComprobanteResponse {
  success: boolean;
  message: string;
  data: any;
}

export class ConsultaNroDocumento {
  nombre: string;
  tipoDocumento: string;
  numeroDocumento: string;
  estado: string
  condicion: string
  direccion: string
  ubigeo: string
  viaTipo: string
  viaNombre: string
  zonaCodigo: string
  zonaTipo: string
  numero: string
  interior: string
  lote: string
  dpto: string
  manzana: string
  kilometro: string
  distrito: string
  provincia: string
  departamento: string
  apellidoPaterno: string
  apellidoMaterno: string
  nombres: string

  public static obtenerAuxiliar(datosSunat: ConsultaNroDocumento): Auxiliar {
    const auxiliar = new Auxiliar();

    auxiliar.cli_codcli = datosSunat.numeroDocumento;
    auxiliar.cli_nombre = datosSunat.nombres;
    auxiliar.cli_apepat = datosSunat.apellidoPaterno;
    auxiliar.cli_apemat = datosSunat.apellidoMaterno;
    auxiliar.cli_dircli = datosSunat.direccion;
    auxiliar.cli_nomcli = datosSunat.nombre;
    auxiliar.cli_tipper = datosSunat.tipoDocumento == '6'? 'J' : 'N';
    datosSunat.tipoDocumento == '6'? auxiliar.cli_numruc = datosSunat.numeroDocumento: auxiliar.cli_numdni = datosSunat.numeroDocumento;
    datosSunat.tipoDocumento == '6'? auxiliar.did_coddid = '001': auxiliar.did_coddid = '000';


    return auxiliar;
  }
}
