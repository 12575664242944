export class Product {
  cia_codcia: string;
  prd_codprd: string;
  prd_desesp: string;
  prd_desabr: string;
  tin_codtin: string;
  mod_codmod: any;
  fpr_codfpr: any;
  mpr_codmpr: any;
  ume_codume: string;
  lpd_codlpd: string;
  prd_modprd: any;
  prd_pesbru: number;
  prd_pesnet: any;
  prd_stomin: number;
  prd_stomax: number;
  prd_ancprd: any;
  prd_larprd: number;
  prd_altprd: number;
  prd_indsca: number;
  log_codlog: any;
  sfp_codsfp: any;
  prd_rutfot: any;
  prd_feccre: Date;
  prd_usucre: string;
  col_codcol: any;
  prd_codtac: any;
  prd_cosstd: any;
  prd_indvta: number;
  prd_indcom: number | boolean;
  prd_indser: number | boolean;
  prd_codbar: any;
  tpr_codtpr: any;
  tac_codtac: any;
  prd_parnum: any;
  prd_indtal: number| boolean;
  prd_codant: any;
  prd_conten: number;
  ume_codctn: any;
  prd_indpre: number;
  prd_canpre: number;
  prd_codpre: any;
  gra_codgra: any;
  prd_galprd: number;
  uba_coduba: any;
  prd_nrogra: number;
  prd_destec: any;
  prd_codean: any;
  prd_indsta: string;
  prd_fecupd: Date;
  prd_coduse: string;
  prd_indcon: number | boolean;
  prd_codlab: any;
  gpc_codgpc: any;
  cpc_codcpc: any;
  ccn_codccn: string;
  prd_gloesp: any;
  prd_indfve: number;
  prd_indlot: number | boolean;
  prd_venlot: number | boolean;
  prd_indgen: number | boolean;
  pai_codpai: any;
  ume_codequi: any;
  prd_serie: any;
  prd_indfab: any;
  prd_indven: any;
  prd_indfac: number;
  par_codpar: any;
  prd_indper: number;
  prd_ideprd: number;
  prd_codsun: any;
  prd_descol: any;
  prd_diacad: number;
  prd_loteco: number;
  prd_commin: number;
  cli_codcli: string;

  constructor(
  ) {
    this.prd_indsta = '1';
    this.prd_fecupd = new Date();
    this.prd_feccre = new Date();
    this.prd_usucre = "";
    this.prd_indvta = 0;
    this.prd_indcom = 0;
    this.prd_indser = 0;
    this.prd_indtal = 0;
    this.prd_conten = 0;
    this.prd_indpre = 0;
    this.prd_canpre = 0;
    this.prd_galprd = 0;
    this.prd_nrogra = 0;
    this.prd_indcon = 0;
    this.prd_indfve = 0;
    this.prd_indlot = 0;
    this.prd_indfac = 0;
    this.prd_indper = 0;
    this.prd_pesbru = 0;
    this.prd_pesnet = 0;
    this.prd_stomin = 0;
    this.prd_stomax = 0;
    this.prd_ancprd = 0;
    this.prd_larprd = 0;
    this.prd_altprd = 0;
    this.prd_indsca = 0;
    this.prd_diacad = 0;
    this.prd_loteco = 0;
    this.prd_commin = 0;
    this.prd_indgen = 0;
    this.prd_venlot = 0;
  }
}

export class ImagenProducto {
  pri_idpri: number;
  cia_codcia: string;
  prd_codprd: string;
  pri_secpri: number;
  pri_fotprd: string;
}
