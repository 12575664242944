import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Driver } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ConductorService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}


  obtenerConductores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/guias/choferes/listar/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((choferes: any) => {
        return choferes.map(chofer => {
          chofer['description'] = `${chofer.CHO_CODCHO} | ${chofer.CHO_NOMBRE}`
          return chofer;
        })
      })
    );
  }

  obtenerConductor(id: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/guias/choferes/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    )
  }

  /**
   *
   * @param conductor
   * @returns
   */
  registrarConductor(conductor: Driver) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/guias/choferes/crear`,
      conductor
    );
  }

  /**
   *
   * @param conductor
   * @returns
   */
  actualizarConductor(conductor: Driver) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/guias/choferes/modificar`,
      conductor
    );
  }
}
