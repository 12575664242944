import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AutorizadoAprobarSolicitud } from 'src/app/models/compras/autorizado-aprobar-solicitud';
import { AuthenticationService, ComprasService } from 'src/app/services';

@Component({
  selector: 'app-auth-aprobar-solicitud-create',
  templateUrl: './auth-aprobar-solicitud-create.component.html',
  styleUrls: ['./auth-aprobar-solicitud-create.component.css']
})
export class AuthAprobarSolicitudCreateComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  autorizado: AutorizadoAprobarSolicitud;

  monedas: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  loadUpload: boolean = false;

  files: File[] = [];

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private _comprasService: ComprasService
  ) {

    this.autorizado = new AutorizadoAprobarSolicitud();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({id})=> {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;

      if(this.uid == '0') return;
      this.obtenerAutorizado();
    })

  }

  obtenerAutorizado(): void {}

  onSelect(event): void {
    this.loadUpload = true
    this.files.push(...event.addedFiles);

    this.files.forEach(element => {
      let reader = new FileReader;

      // let urlImageTemp = reader.readAsDataURL(element);

      reader.onloadend = () => {

      };
    })

    setTimeout(() => {
      this.loadUpload = false;

    }, 3000);

  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarAutorizado(f): this.actualizarAutorizado(f);
  }

  registrarAutorizado( f: NgForm ): void {
    this.loaderReg = true;

    this._comprasService.registrarAutorizadoAprobarSolicitud(this.autorizado).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Autorizado aprobar solicitud registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarAutorizado(f: NgForm): void {
    this.loaderReg = true;

    this._comprasService.actualizarAutorizadoAprobarSolicitud(this.autorizado).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Autorizado aprobar solicitud actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-compras/autorizados-de-aprobar-solicitudes-de-compra']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }

}
