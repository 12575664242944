// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://162.248.55.12:3978/api/',
  // sourceUrl: 'http://162.248.55.12:3978/api/',
  apiUrl: 'https://pavservice.com:3978/api/',
  sourceUrl: 'https://pavservice.com:3978/api/',
  cia_codcias: '99',
  styleForm: {
    formField: 'outline'
  },
  firebaseConfig: {
    apiKey: "AIzaSyB2VlNKfGn36ouXvUDkcRWAdNIlthA5PbQ",
    authDomain: "devwallpaperproject.firebaseapp.com",
    databaseURL: "https://devwallpaperproject.firebaseio.com",
    projectId: "devwallpaperproject",
    storageBucket: "devwallpaperproject.appspot.com",
    messagingSenderId: "886114732920",
    appId: "1:886114732920:web:9155e09dd7ef14e3f3168d"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
