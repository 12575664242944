import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { PlantillaFichaTecnica } from "src/app/models/crm/plantilla-ficha-tecnica";
import { AuthenticationService, ConfigurationService } from "src/app/services";
import { PlantillaService } from "src/app/services/api/crm/maestros/plantilla.service";

@Component({
  selector: 'plantilla-tecnica-form',
  templateUrl: './plantilla-tecnica-form.component.html',
  styleUrls: ['./plantilla-tecnica-form.component.scss']
})
export class PlantillaTecnicaFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  plantilla: PlantillaFichaTecnica;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  loading$: Subscription;
  sendForm$: Subscription;
  plantilla$: Subscription;

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'ume'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _plantillaService: PlantillaService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.plantilla = new PlantillaFichaTecnica();
    this.plantilla.codcia = this._configurationService.obtenerCompaniaCliente();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id
      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;

      this.obtenerPlantilla()
    })
  }

  obtenerPlantilla(): void {

    this.plantilla$ = this._plantillaService.obtenerPlantilla(this.uid).subscribe(
      plantilla => {
        console.log('plantilla', plantilla)
        this.plantilla = plantilla[0];

        this.dataSource = fillTable(plantilla.especificaciones, this.paginator, this.sort);

      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )

  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;
    this.uid == '0' ? this.registrarPlantilla(f): this.actualizarPlantilla(f);
  }

  /**
   * Método para registrar una nueva plantilla
   * @param f
   */
  registrarPlantilla(f: NgForm): void {
    this.plantilla.coduse = this._configurationService.obtenerIdUsuario();
    this.sendForm$ = this._plantillaService.registrarPlantilla(this.plantilla).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.plantilla.success-store', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  /**
   * Método para actualizar los datos de una plantilla existente
   * @param f
   */
  actualizarPlantilla(f: NgForm): void {
    this.sendForm$ = this._plantillaService.actualizarPlantilla(this.plantilla).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.plantilla.success-update', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {

    if(!this.plantilla.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    return true;

  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/plantilla-especificacion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.plantilla$,
    ])
  }

}
