<app-pavso-title-section [title]="'Tipo de inventario'" [module]="'Almacén'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>


  <div class="mat-elevation-z0 overflow-x">
    <table-title-action title="Todas los tipos de inventario" url="/modulo-almacen/tipo-de-inventario" entity="" [params]="['0']"></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-almacen/tipo-de-inventario', row.TIN_CODTIN]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let row" data-label="Código"> {{row.TIN_CODTIN}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.TIN_DESCRI}} </td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.TIN_INDSTA}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>
      </tr>


    </table>

  </div>
  <mat-paginator color="primary" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
