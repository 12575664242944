<app-pavso-title-section [title]="'Requerimiento de materiales'" [module]="'Operaciones'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los requerimientos" url="/modulo-operaciones/requerimiento-de-materiales" entity="" [params]="['0']"></table-title-action>

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-operaciones/requerimiento-de-materiales', row.cli_codcli]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="rma_numrma">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.rma_numrma}} </td>
            </ng-container>

            <ng-container matColumnDef="cli_nomcli">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Solicitado Por </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="ccs_desesp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro de costos </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ccs_desesp}} </td>
            </ng-container>

            <ng-container matColumnDef="rma_fecpro">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.rma_fecpro}} </td>
            </ng-container>

            <ng-container matColumnDef="rma_indsta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip selected *ngIf="row.rma_indsta == '1'" class="sc_no_vigente">NO VIGENTE</mat-chip>
                        <mat-chip selected *ngIf="row.rma_indsta == '0'" class="sc_vigente">VIGENTE</mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
