export class Colaborador {
  codcia: string;
  codcli: string;
  tipper: string;
  nomcli: string;
  apepat: string;
  apemat: string;
  nombre: string;
  dircli: string;
  discli: string;
  indciv: string;
  indsex: string;
  numdni: string;
  numtlf: string;
  numfax: string;
  corele: string;
  nomcon: string;
  codpai: string;
  codubi: string;
  indtra: number;
  indsta: string;
  coduse: string;
  fecupd: Date;
  indpnj: number;
  inddom: number;
  numcel: string;
  coddid: string;
  fecnac: Date;
  feccre: Date;
  usucre: string;
  emaper: string;

  // otros campos;
  codt09: string;

  MAES_TRAB_PERI_TRP: Array<MaesTrabPeriTrp>;
  MAES_TRAB_TRA: Array<MaesTrabTra>;
  REMU_TRAB_RTR: Array<RemuTrabRtr>;
  LEGA_TRAB_NLE: Array<LegaTrabNle>;
  ASIG_VACA_NAV: Array<AsigVacaNav>;
  DEPE_TRAB_DTR: Array<DepeTrabDtr>;

  constructor() {
    this.MAES_TRAB_PERI_TRP = [new MaesTrabPeriTrp()];
    this.MAES_TRAB_TRA = [new MaesTrabTra()];
    this.REMU_TRAB_RTR = [];
    this.LEGA_TRAB_NLE = [];
    this.ASIG_VACA_NAV = [];
    this.DEPE_TRAB_DTR = [];
    this.tipper = "N";
    this.fecupd = new Date();
    this.indtra = 1;
    this.indpnj = 0
  }
}

export class MaesTrabPeriTrp {
  codano: string;
  codmes: string;
  codnca: string;
  codafp: string;
  numcus: string;
  ctlmar: string;
  codhab: string;
  ctahab: string;
  codtmo: string;
  bancts: string;
  ctacts: string;
  codcts: string;
  tmocts: string;
  codnti: string;
  cssccs: string;
  fecing: Date;
  fecces: Date;
  indsub: number;
  vacini: any;
  vacfin: any;
  indeps: number;
  excapo: any;
  vacper: any;
  numaut: string;
  comafp: string;
  exopen: number;
  diaefe: any;
  tieefe: any;
  horefe: any;
  minefe: any;
  dialab: number;
  indper: number;
  diasub: number;
  codpla: any;
  tipcon: any;
  indbon: number;
  excreg: number;
  excren: number;
  excseg: number;
  codper: string;
  porade: number;

  constructor() {
    this.diasub = 0;
    this.excreg = 0;
    this.excseg = 0;
  }
}

export class MaesTrabTra {

  tra_numcus: string;
  tra_lugnac: string;
  tra_indq50: number;
  t09_coddoc: string;
  isEditing: boolean;

  constructor() {
    this.isEditing = true;
  }

}

export class RemuTrabRtr {
  codnco: string;
  descri: string;
  codcli: string;
  nomcli: string;
  imprem: number;
  codnut: string;
  cannut: number;
  codano: string;
  codcia: string;
  codmes: string;
  itemR: string;
  isEditing: boolean;

  constructor() {
    this.isEditing = true;
  }
}

export class LegaTrabNle {
  cornle: string;
  codtdl: string;
  glosa: string;
  fecing: string;
  fecvig: string;
  fecven: string;
  indsta: string;
  coduse: string;
  fecupd: Date;
  nomarc: any;
  isEditing: boolean;

  documento: string;
  formato: string;

  constructor() {
    this.indsta = "1";
    this.isEditing = true;
    this.fecupd = new Date();

  }
}

export class AsigVacaNav {
  mcv_permcv: string;
  nav_diavac: number;
  nav_fecupd: Date;
  nav_coduse: string;
  nav_diafis: number;

  constructor(nav_coduse) {
    this.nav_fecupd = new Date();
    this.nav_coduse = nav_coduse;
  }
}

export class DepeTrabDtr {
  cordtr: any;
  apepat: string;
  apemat: string;
  nombre: string;
  coddoc: any;
  numdoc: any;
  codvin: any;
  indsta: any;
  fecnac: any;
  indsex: any;
  fecupd: Date;
  coduse: any;
  glosa: any;
  isEditing: boolean;

  constructor(dtr_coduse) {
    this.indsta = '1';
    this.isEditing = true;
    this.fecupd = new Date();
    this.coduse = dtr_coduse;
  }
}
