<app-pavso-title-section [title]="'Informes de dependientes'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>

  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtro</div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="pav-filtro-container">

    <div class="row">
      <div class="col s12 m12 l3">
        <select-search
          [label]="'Colaborador'"
          [placeholder]="'Ej. TORRES'"
          [name]="'codcli'"
          [value]="'codcli'"
          [description]="'nomcli'"
          [data]="trabajadores"
          (cambioSelect)="filtro.colaboradores = $event"
          [multiple]="true"
          [model]="filtro.colaboradores">
        </select-search>

      </div>
      <div class="col s12 m12 l3">
        <select-search
          [label]="'Centro de costo'"
          [placeholder]="'Ej. Centro'"
          [name]="'ccs_codccs'"
          [value]="'ccs_codccs'"
          [description]="'ccs_descri'"
          [data]="centros"
          (cambioSelect)="filtro.centros = $event"
          [multiple]="true"
          [model]="filtro.centros">
        </select-search>

      </div>
      <div class="col s12 m12 l2">
        <select-search
          [label]="'Género'"
          [placeholder]="'Ej. Masculino'"
          [name]="'gpe_codgpe'"
          [value]="'gpe_codgpe'"
          [description]="'gpe_descri'"
          [data]="generos"
          (cambioSelect)="filtro.generos = $event"
          [multiple]="true"
          [model]="filtro.generos">
        </select-search>

      </div>

      <div class="col s6 m6 l2">
        <mat-form-field>
          <mat-label>Rango: Desde</mat-label>
          <input class="pav-input-align-right" min="0" [(ngModel)]="desde1" name="desde1" type="number" matInput placeholder="Ran 1: Desde">
        </mat-form-field>
      </div>
      <div class="col s6 m6 l2">
        <mat-form-field>
          <mat-label>- Hasta</mat-label>
          <input class="pav-input-align-right" min="0" [(ngModel)]="hasta1" name="hasta1" type="number" matInput placeholder="- Hasta">
        </mat-form-field>
      </div>
    </div>
  </div>

  <br>
  <div align="center">
    <button class="pav-button-action" mat-flat-button color="primary" (click)="verReporte()" [class.spinner]="loading" [disabled]="loading">{{LABELS_NAME.BTN_SHOW_REPORT}}</button>
  </div>


</div>
