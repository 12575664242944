<app-pavso-title-section [title]="'Configuración renta de quinta'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l1">
                  <mat-form-field>
                      <mat-label>Año</mat-label>
                      <input class="pav-input-align-right" [(ngModel)]="configuraRentaQuinta.codano" type="text" matInput placeholder="Año" name="codano" readonly required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Importe UIT</mat-label>
                      <input class="pav-input-align-right" min="0" [(ngModel)]="configuraRentaQuinta.monto" type="number" matInput placeholder="Importe UIT" name="monto" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Número UIT</mat-label>
                      <input class="pav-input-align-right" min="0" [(ngModel)]="configuraRentaQuinta.numuit" type="number" matInput placeholder="Número UIT" name="numuit" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Crédito anual</mat-label>
                      <input class="pav-input-align-right" min="0" [(ngModel)]="configuraRentaQuinta.creanu" type="number" matInput placeholder="Crédito Anual" name="creanu" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l2">
                <button mat-stroked-button color="primary" (click)="obtenerAnioAnterior()">Obtener año anterior</button>
              </div>
          </div>
      </mat-card>
      <br>
      <div class="mat-elevation-z0">

        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Configuraciones
          </div>
          <div class="pav-table-header-add">
            <div class="isMobile">
              <div class="pav-button-icon-add" (click)="agregarItem()">
                <mat-icon svgIcon="add"></mat-icon>
              </div>
            </div>
            <div class="isWeb">
              <button type="button" mat-flat-button class="pav-button-add-item" (click)="agregarItem()">Agregar ítem</button>
            </div>
          </div>
        </div>
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" data-label="Acciones" class="td-responsive">
              ...
              <!-- <div class="pav-btns-container">
                <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
                <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                  <mat-icon svgIcon="confirm"></mat-icon>
                </div>
                <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                  <mat-icon svgIcon="delete"></mat-icon>
                </div>
              </div> -->
            </td>
          </ng-container>

            <ng-container matColumnDef="cornqd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Item"> {{row.cornqd}} </td>
            </ng-container>

            <ng-container matColumnDef="descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
                  <ng-container *ngIf="row.isEditing">
                    <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input required type="text" min="0" matInput placeholder="Descripción" [(ngModel)]="row.descri"
                      [name]="'descri' + i.toString()">
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!row.isEditing">
                    {{row.descri}}
                  </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="numuit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. UIT </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Num. UIT" class="pav-td-right">
                  <ng-container *ngIf="row.isEditing">
                    <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input required type="number" min="0" matInput placeholder="Num. UIT" [(ngModel)]="row.numuitacu"
                      [name]="'numuitacu' + i.toString()">
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!row.isEditing">
                    {{row.numuitacu}}
                  </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="porren">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> % Renta </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="% Renta" class="pav-td-right">
                  <ng-container *ngIf="row.isEditing">
                    <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input required type="number" min="0" matInput placeholder="% Renta" [(ngModel)]="row.porren"
                      [name]="'porren' + i.toString()">
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!row.isEditing">
                    {{row.porren}}
                  </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="monto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Monto" class="pav-td-right">
                  <ng-container *ngIf="row.isEditing">
                    <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input required type="number" min="0" matInput placeholder="Monto" [(ngModel)]="row.montoacu"
                      [name]="'montoacu' + i.toString()" step="any">
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!row.isEditing">
                    {{row.montoacu.toFixed(2)}}
                  </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="5">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>
    </form>
</div>
