import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminRoutingModule } from './admin/admin-routing.module';
import { NotFoundComponent } from './error/not-found/not-found.component';

// Impor user
import { LoginComponent } from './auth/login/login.component';
import { LearningComponent } from './learning/learning.omponent';

const AppRoutes: Routes = [

    { path: 'iniciar-sesion', component: LoginComponent },
    { path: 'learning', component: LearningComponent },
    { path: '**', component: NotFoundComponent },
    { path: '', redirectTo: '/iniciar-sesion', pathMatch: 'full' }

];

@NgModule({

  imports: [
      RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
      AdminRoutingModule
  ],
  exports: [
      RouterModule
  ]

})
export class AppRoutingModule {}
