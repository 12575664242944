
//Crear una funcion que recibe un decimal y lo convierte a hora con este formato 24:00

import { formatearCorrelativo } from "./formatCorrelative";

//Ejemplo: 1.5 = 01:30
export function formatoHoras(horas: number): string {
  const horasCompletas = Math.floor(horas);
  const minutos = Math.round((horas - horasCompletas) * 60);
  return `${formatearCorrelativo(2, horasCompletas.toString())}:${minutos.toString().padStart(2, '0')}`;
}

export function parseHoras(horas: any): number {
  const partes = horas.split(':');
  const horasCompletas = parseInt(partes[0]);
  const minutos = parseInt(partes[1]);
  return horasCompletas + minutos / 60;
}
