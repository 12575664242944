import { PavsoColors } from '@data/enums/color.enum';
import { HeaderReport } from '@data/interfaces/headers.report';
import { noBorders, topBorder } from '@utils/pdfmaker/utils/pdfmake.constant';
import { obtenerNulls } from '@utils/pdfmaker/utils/pdfmake.function';
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';

/** Reporte de PDF Activo Fijo */
export function generarPdfActivoFijo(headers: HeaderReport, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageSize({ width: 1200, height: 400 });
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount) {
    return currentPage.toString() + '/' + pageCount;
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  pdf.add(pdf.ln(2));

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('COMERCIALIZADORA ASIA PERU S.A.C.')
            .fontSize(8)
            .alignment('left').end
        ).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt('').end).colSpan(11).end,
        ...obtenerNulls(10),
        new Cell(
          new Txt('13/09/2021').fontSize(8).alignment('right').end
        ).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt('RCOM_REP_DET_VE').fontSize(8).alignment('left').end
        ).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(new Txt('').end).colSpan(11).end,
        ...obtenerNulls(10),
        new Cell(
          new Txt('15:03:11').fontSize(8).alignment('right').end
        ).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt('REPORTE ACTIVO FIJO')
            .fontSize(12)
            .bold()
            .alignment('center').end
        ).colSpan(22).end,
        ...obtenerNulls(21),
      ],
      [
        new Cell(new Txt('Cliente').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('TD').fontSize(8).bold().alignment('center').end).end,
        new Cell(new Txt('Número').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Fecha').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Línea').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Cod. Pro.').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(new Txt('Producto').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Cantidad').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Precio').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Moneda').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Importe').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Usuario').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Referencia').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(new Txt('Pedido').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Guía Remisión').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt('Cond. Pago').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(new Txt('Vendedor').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Motivo').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Ind. Guía').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(new Txt('Almacén').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Costo Prod.').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt('Departamento').fontSize(8).bold().alignment('center').end
        ).end,
      ],
    ]).widths([
      80, 20, 30, 30, 40, 50, 80, 40, 30, 30, 30, 60, 80, 40, 50, 30, 80, 30,
      30, 30, 30, 30,
    ]).end
  );

  pdf.add(
    new Table(this.dataDetalleFacturacion)
      .layout('noBorders')
      .widths([
        85, 20, 35, 30, 40, 50, 83, 43, 30, 30, 30, 60, 80, 40, 55, 30, 80, 30,
        30, 30, 30, 30,
      ]).end
  );

  pdf.create().open();
}

export function reporteActivoFijoHistorico(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount) {
    return currentPage.toString() + '/' + pageCount;
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).alignment('left').fontSize(9).end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Activo Fijo Histórico - FINANCIERO')
              .alignment('center')
              .bold()
              .fontSize(12).end,
            new Txt(`Período: ${headers.year} ${headers.month}`)
              .alignment('center')
              .bold()
              .fontSize(9).end,
          ]).end
        )
          .border(noBorders)
          .colSpan(10).end,
        ...obtenerNulls(9),
        new Cell(
          new Stack([
            new Txt(headers.now).alignment('right').fontSize(9).end,
            new Txt(headers.hour).alignment('right').fontSize(9).end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Cod. de Activo').fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt('Fecha Adquis.').fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt('V. Uti / % Dpc').fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt('Fecha Alta').fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt('Fecha Baja').fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt('Descripción').fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt('C. Costo').fontSize(8).bold().alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt('Valores Iniciales')
            .fontSize(8)
            .bold()
            .alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Depreciación Histórica')
            .fontSize(8)
            .bold()
            .alignment('center').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('Activo').fontSize(8).bold().alignment('center').end).color(PavsoColors.White).fillColor(PavsoColors.Blue)
          .end,
        new Cell(new Txt('Deprec').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('V. Residual').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(new Txt('Del Mes').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Acumulada').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(new Txt('Ajustes').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Deprec. Total').fontSize(8).bold().alignment('center').end
        ).end,
      ],
      [
        new Cell(new Txt('CLASE: 01 TERRENOS').fontSize(9).bold().end)
          .border(noBorders)
          .colSpan(14)
          .fontSize(9)
          .bold().end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(
          new Txt('TIPO 09 Inmueble, propiedad y equipo - Terrenos propios')
            .fontSize(9)
            .bold().end
        )
          .colSpan(14)
          .border(noBorders).end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(new Txt('0901010001').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('07/10/05').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('07/05/05').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('TERENO VENTANILLA - CALLAO').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('ADMINISTRACIÓN').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('1,020,400.00').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(
          new Txt('1,030,400.00').fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
    ]).headerRows(3).end
  );

  pdf.create().open();
}

export function reporteActivoFijoHistoricoComponetizado(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount) {
    return currentPage.toString() + '/' + pageCount;
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).alignment('left').fontSize(9).bold().end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Activo Fijo Histórico Componetizado - FINANCIERO')
              .bold()
              .alignment('center')
              .fontSize(12).end,
            new Txt(`Período: ${headers.year} ${headers.month}`)
              .alignment('center')
              .fontSize(9)
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(9).end,
        ...obtenerNulls(8),
        new Cell(
          new Stack([
            new Txt(headers.now).alignment('right').fontSize(9).bold().end,
            new Txt(headers.hour).alignment('right').fontSize(9).bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Cod. de Activo').fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt('Fecha Adquis.').fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt('V. Uti / % Dpc').fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt('Fecha Alta').fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt('Descripción').fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt('C. Costo').fontSize(8).bold().alignment('center').end
        ).rowSpan(2).end,
        new Cell(
          new Txt('Valores Iniciales')
            .fontSize(8)
            .bold()
            .alignment('center').end
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Depreciación Histórica')
            .fontSize(8)
            .bold()
            .alignment('center').end
        ).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('Activo').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(new Txt('Deprec').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('V. Residual').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(new Txt('Del Mes').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Acumulada').fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(new Txt('Ajustes').fontSize(8).bold().alignment('center').end)
          .end,
        new Cell(
          new Txt('Deprec. Total').fontSize(8).bold().alignment('center').end
        ).end,
      ],
      [
        new Cell(new Txt('ACTIVO PRINICIPAL:').fontSize(9).bold().end)
          .colSpan(13)
          .border(noBorders).end,
        ...obtenerNulls(12),
      ],
      [
        new Cell(new Txt('0901010001').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('07/11/05').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('07/11/05').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('TERRENO VENTANILLA - CALLAO').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('ADMINISTRACIÓN').fontSize(8).end).border(noBorders).end,
        new Cell(
          new Txt('1,020,400.00').fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
    ]).headerRows(3).end
  );

  pdf.create().open();
}

export function reporteActivoFijoPorUbicación(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(9).bold().alignment('left').end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('ACTIVO FIJO POR UBICACIÓN - FINANCIERO')
              .fontSize(12)
              .bold()
              .alignment('center').end,
            new Txt(`Período: ${headers.year} ${headers.month}`)
              .fontSize(9)
              .bold()
              .alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(9).end,
        ...obtenerNulls(8),
        new Cell(new Txt(headers.now).fontSize(9).bold().alignment('right').end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('Cod. de Activo').fontSize(8).bold().end).rowSpan(2)
          .end,
        new Cell(new Txt('Fecha Adquis').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('V. Uti / % Dpc').fontSize(8).bold().end).rowSpan(2)
          .end,
        new Cell(new Txt('Fecha Alta').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('Descripción').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('C. Costo').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('Valores Iniciales').fontSize(8).bold().end).colSpan(3)
          .end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Depreciación Histórica').fontSize(8).bold().end
        ).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        ...obtenerNulls(6),
        new Cell(new Txt('Activo').fontSize(8).bold().end).end,
        new Cell(new Txt('Deprec.').fontSize(8).bold().end).end,
        new Cell(new Txt('V. Residual').fontSize(8).bold().end).end,
        new Cell(new Txt('Del Mes').fontSize(8).bold().end).end,
        new Cell(new Txt('Acumulada').fontSize(8).bold().end).end,
        new Cell(new Txt('Ajustes').fontSize(8).bold().end).end,
        new Cell(new Txt('Deprec. Total').fontSize(8).bold().end).end,
      ],
      [
        new Cell(new Txt('UBICACIÓN:').fontSize(9).bold().end)
          .border(noBorders)
          .colSpan(13).end,
        ...obtenerNulls(12),
      ],
      [
        new Cell(
          new Txt('GRUPO: 0301 Terrenos urbanos - Propios')
            .fontSize(9)
            .bold().end
        )
          .border(noBorders)
          .colSpan(13).end,
        ...obtenerNulls(12),
      ],
      [
        new Cell(new Txt('0901010001').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('07/11/05').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('07/11/05').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('TERRENO VENTANILLA - CALLAO').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('ADMINISTRACIÓN').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
    ]).headerRows(3).end
  );

  pdf.create().open();
}

export function reporteDepreciacionMensualPorActivo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(9).bold().alignment('left').end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('DEPRECIACIÓN MENSUAL POR ACTIVO - FINANCIERO')
              .fontSize(12)
              .bold()
              .alignment('center').end,
            new Txt(headers.year).fontSize(10).bold().alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(16).end,
        ...obtenerNulls(15),
        new Cell(new Txt(headers.now).fontSize(9).alignment('right').bold().end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('TIPO').fontSize(8).bold().end).end,
        new Cell(new Txt('DESCRIPCIÓN').fontSize(8).bold().end).end,
        new Cell(new Txt('VALOR DEL ACTIVO').fontSize(8).bold().end).end,
        new Cell(new Txt('V. UTS. %DEP').fontSize(8).bold().end).end,
        new Cell(new Txt('DEPREC. ACUMULADA').fontSize(8).bold().end).end,
        new Cell(new Txt('ENE').fontSize(8).bold().end).end,
        new Cell(new Txt('FEB').fontSize(8).bold().end).end,
        new Cell(new Txt('MAR').fontSize(8).bold().end).end,
        new Cell(new Txt('ABR').fontSize(8).bold().end).end,
        new Cell(new Txt('MAY').fontSize(8).bold().end).end,
        new Cell(new Txt('JUN').fontSize(8).bold().end).end,
        new Cell(new Txt('JUL').fontSize(8).bold().end).end,
        new Cell(new Txt('AGO').fontSize(8).bold().end).end,
        new Cell(new Txt('SEP').fontSize(8).bold().end).end,
        new Cell(new Txt('OCT').fontSize(8).bold().end).end,
        new Cell(new Txt('NOV').fontSize(8).bold().end).end,
        new Cell(new Txt('DIC').fontSize(8).bold().end).end,
        new Cell(new Txt('TOTAL').fontSize(8).bold().end).end,
        new Cell(new Txt('DEPREC. TOTAL').fontSize(8).bold().end).end,
        new Cell(new Txt('VALOR NETO DEL ACTIVO').fontSize(8).bold().end).end,
      ],
      [
        new Cell(
          new Txt('09 Inmueble, propiedad y equipo - terrenos propios')
            .fontSize(9)
            .bold().end
        )
          .colSpan(20)
          .border(noBorders).end,
        ...obtenerNulls(19),
      ],
      [
        new Cell(new Txt('0233').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('TERRENO VENTANILLA - CALLAO').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0%').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
      [
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
      ],
    ]).headerRows(2).end
  );

  pdf.create().open();
}

export function reporteDetalleMovimientoPorActivo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(9).alignment('left').bold().end
        )
          .colSpan(2)
          .border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Detalle de Movimiento por Activo - FINANCIERO')
              .fontSize(12)
              .alignment('center')
              .bold().end,
            new Txt(`Período: ${headers.year} - ${headers.month}`)
              .fontSize(9)
              .alignment('center')
              .bold().end,
          ]).end
        )
          .colSpan(10)
          .border(noBorders).end,
        ...obtenerNulls(9),
        new Cell(new Txt(headers.now).fontSize(9).alignment('right').bold().end)
          .colSpan(2)
          .border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('Activo').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('Descripción').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(
          new Txt('Fecha').alignment('center').fontSize(8).bold().end
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Reclasificación').alignment('center').fontSize(8).bold().end
        ).colSpan(9).end,
        ...obtenerNulls(8),
      ],
      [
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('Adquis.').fontSize(8).bold().end).end,
        new Cell(new Txt('Alta').fontSize(8).bold().end).end,
        new Cell(new Txt('Baja').fontSize(8).bold().end).end,
        new Cell(new Txt('Saldo').fontSize(8).bold().end).end,
        new Cell(new Txt('Adiciones').fontSize(8).bold().end).end,
        new Cell(new Txt('Retiros').fontSize(8).bold().end).end,
        new Cell(new Txt('Ventas').fontSize(8).bold().end).end,
        new Cell(new Txt('Devolución').fontSize(8).bold().end).end,
        new Cell(new Txt('Ingreso').fontSize(8).bold().end).end,
        new Cell(new Txt('Salida').fontSize(8).bold().end).end,
        new Cell(new Txt('Ajustes').fontSize(8).bold().end).end,
        new Cell(new Txt('Saldo Final').fontSize(8).bold().end).end,
      ],
      [
        new Cell(new Txt('Activo fijo e intangibles').fontSize(9).bold().end)
          .border(noBorders)
          .colSpan(14).end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(
          new Txt('09 Inmuebles, propiedad y equipo - terrenos propios')
            .fontSize(9)
            .bold().end
        )
          .border(noBorders)
          .colSpan(14).end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(
          new Txt('0301 Terrenos Urbanos - Propios').fontSize(9).bold().end
        )
          .border(noBorders)
          .colSpan(14).end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(new Txt('32323').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('32323').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('TERRENO VENTANILLA - CALLAO').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('07/11/20').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('07/05/02').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('08/08/08').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
    ]).headerRows(3).end
  );

  pdf.create().open();
}

export function reporteResumenMovimientoPorCuenta(headers, data) {

  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(9).alignment('left').bold().end
        )
          .colSpan(2)
          .border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Resumen de Movimiento por Cuenta - FINANCIERO')
              .fontSize(12)
              .alignment('center')
              .bold().end,
            new Txt(`Período: ${headers.year} - ${headers.month}`)
              .fontSize(9)
              .alignment('center')
              .bold().end,
          ]).end
        )
          .colSpan(10)
          .border(noBorders).end,
        ...obtenerNulls(9),
        new Cell(new Txt(headers.now).fontSize(9).alignment('right').bold().end)
          .colSpan(2)
          .border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('Activo').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('Descripción').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(
          new Txt('Fecha').alignment('center').fontSize(8).bold().end
        ).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Reclasificación').alignment('center').fontSize(8).bold().end
        ).colSpan(9).end,
        ...obtenerNulls(8),
      ],
      [
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('Adquis.').fontSize(8).bold().end).end,
        new Cell(new Txt('Alta').fontSize(8).bold().end).end,
        new Cell(new Txt('Baja').fontSize(8).bold().end).end,
        new Cell(new Txt('Saldo').fontSize(8).bold().end).end,
        new Cell(new Txt('Adiciones').fontSize(8).bold().end).end,
        new Cell(new Txt('Retiros').fontSize(8).bold().end).end,
        new Cell(new Txt('Ventas').fontSize(8).bold().end).end,
        new Cell(new Txt('Devolución').fontSize(8).bold().end).end,
        new Cell(new Txt('Ingreso').fontSize(8).bold().end).end,
        new Cell(new Txt('Salida').fontSize(8).bold().end).end,
        new Cell(new Txt('Ajustes').fontSize(8).bold().end).end,
        new Cell(new Txt('Saldo Final').fontSize(8).bold().end).end,
      ],
      [
        new Cell(new Txt('Activo fijo e intangibles').fontSize(9).bold().end)
          .border(noBorders)
          .colSpan(14).end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(
          new Txt('09 Inmueble, propiedad y equipo - Terrenos propios')
            .fontSize(9)
            .bold().end
        )
          .border(noBorders)
          .colSpan(14).end,
        ...obtenerNulls(13),
      ],
      [
        new Cell(new Txt('0301').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('Terrenos urbanos - propios').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('08/08/08').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('08/08/08').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('08/08/08').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
    ]).headerRows(3).end
  );

  pdf.create().open();
}

export function reporteResumenMovimientosPorClase(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(9).alignment('left').bold().end
        )
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Resumen de Movimientos por Clase - FINANCIERO')
              .fontSize(12)
              .alignment('center')
              .bold().end,
            new Txt(`Período : ${headers.year} - ${headers.month}`)
              .fontSize(9)
              .alignment('center')
              .bold().end,
          ]).end
        )
          .border(noBorders)
          .colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt(headers.now).fontSize(9).bold().alignment('right').end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('Activo').end).fontSize(9).bold().rowSpan(2).end,
        new Cell(new Txt('Descripción').end).fontSize(9).bold().rowSpan(2).end,
        new Cell(new Txt('Transferencia').alignment('center').end)
          .fontSize(9)
          .bold()
          .colSpan(9).end,
        ...obtenerNulls(8),
      ],
      [
        new Cell(new Txt('').end).end,
        new Cell(new Txt('').end).end,
        new Cell(new Txt('Saldo').fontSize(9).bold().end).end,
        new Cell(new Txt('Adiciones').fontSize(9).bold().end).end,
        new Cell(new Txt('Retiros').fontSize(9).bold().end).end,
        new Cell(new Txt('Ventas').fontSize(9).bold().end).end,
        new Cell(new Txt('Devolución').fontSize(9).bold().end).end,
        new Cell(new Txt('Ingreso').fontSize(9).bold().end).end,
        new Cell(new Txt('Salida').fontSize(9).bold().end).end,
        new Cell(new Txt('Ajustes').fontSize(9).bold().end).end,
        new Cell(new Txt('Saldo Final').fontSize(9).bold().end).end,
      ],
      [
        new Cell(new Txt('Activo Fijo e Intangible').fontSize(9).bold().end)
          .border(noBorders)
          .colSpan(11).end,
        ...obtenerNulls(10),
      ],
      [
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('TERRENOS').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
      [
        new Cell(new Txt('').fontSize(8).bold().end).border(noBorders).end,
        new Cell(new Txt('TOTAL').fontSize(8).bold().end).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
      ],
    ]).headerRows(3).end
  );

  pdf.create().open();
}

export function reporteResumenMovimientoClaseVSGrupo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(9).bold().end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Resumen de movimientos por Clase vs. Grupo - FINANCIERO')
              .fontSize(9)
              .bold()
              .alignment('center').end,
            new Txt(`Período: ${headers.year} - ${headers.month}`)
              .fontSize(9)
              .bold()
              .alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt(headers.now).fontSize(9).bold().alignment('right').end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('Activo').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('Descripción').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(
          new Txt('Reclasificación').fontSize(8).bold().alignment('center').end
        ).colSpan(9).end,
        ...obtenerNulls(8),
      ],
      [
        ...obtenerNulls(2),
        new Cell(new Txt('Saldo').fontSize(8).bold().end).end,
        new Cell(new Txt('Adiciones').fontSize(8).bold().end).end,
        new Cell(new Txt('Retiros').fontSize(8).bold().end).end,
        new Cell(new Txt('Ventas').fontSize(8).bold().end).end,
        new Cell(new Txt('Devolución').fontSize(8).bold().end).end,
        new Cell(new Txt('Ingreso').fontSize(8).bold().end).end,
        new Cell(new Txt('Salida').fontSize(8).bold().end).end,
        new Cell(new Txt('Ajustes').fontSize(8).bold().end).end,
        new Cell(new Txt('Saldo Final').fontSize(8).bold().end).end,
      ],
      [
        new Cell(
          new Txt('Activo Fijo e Intangible').alignment('center').bold().end
        )
          .border(noBorders)
          .colSpan(11).end,
        ...obtenerNulls(10),
      ],
      [
        new Cell(new Txt('TERRENOS').fontSize(9).bold().end)
          .border(noBorders)
          .colSpan(11).end,
        ...obtenerNulls(10),
      ],
      [
        new Cell(new Txt('0301').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(
          new Txt('Terrenos Urbanos - Propios')
            .alignment('right')
            .fontSize(8).end
        ).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
      [
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(topBorder).end,
      ],
    ]).headerRows(3).end
  );

  pdf.create().open();
}

export function reporteResumenMovimientoDetallado(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(9).bold().end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Resumen de Movimientos Detallado - FINANCIERO')
              .fontSize(12)
              .bold()
              .alignment('center').end,
            new Txt(`Período : ${headers.year} - ${headers.month}`)
              .fontSize(9)
              .bold()
              .alignment('center').end,
          ]).end
        )
          .border(noBorders)
          .colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt(headers.now).fontSize(9).bold().alignment('right').end)
          .border(noBorders)
          .colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(new Txt('Activo').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('Descripción').fontSize(8).bold().end).rowSpan(2).end,
        new Cell(new Txt('Reclasificación').fontSize(8).bold().end).colSpan(9)
          .end,
      ],
      [
        ...obtenerNulls(2),
        new Cell(new Txt('Saldo').fontSize(8).bold().end).end,
        new Cell(new Txt('Adiciones').fontSize(8).bold().end).end,
        new Cell(new Txt('Retiros').fontSize(8).bold().end).end,
        new Cell(new Txt('Ventas').fontSize(8).bold().end).end,
        new Cell(new Txt('Devolución').fontSize(8).bold().end).end,
        new Cell(new Txt('Ingreso').fontSize(8).bold().end).end,
        new Cell(new Txt('Salida').fontSize(8).bold().end).end,
        new Cell(new Txt('Ajustes').fontSize(8).bold().end).end,
        new Cell(new Txt('Saldo Final').fontSize(8).bold().end).end,
      ],
      [
        new Cell(
          new Txt('ACTIVO FIJO E INTANGIBLE').alignment('center').bold().end
        )
          .border(noBorders)
          .colSpan(11).end,
        ...obtenerNulls(10),
      ],
      [
        new Cell(new Txt('TERRENOS').fontSize(9).alignment('left').bold().end)
          .border(noBorders)
          .colSpan(11).end,
        ...obtenerNulls(10),
      ],
      [
        new Cell(
          new Txt('0301 Terrenos Urbanos - Propios')
            .fontSize(9)
            .alignment('left')
            .bold().end
        )
          .border(noBorders)
          .colSpan(11).end,
        ...obtenerNulls(10),
      ],
      [
        new Cell(new Txt('0101').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('TERRENO VENTANILLA - CALLAO').fontSize(8).end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
        new Cell(new Txt('0.00').fontSize(8).alignment('right').end).border(noBorders).end,
      ],
      [
        new Cell(new Txt('').fontSize(8).bold().end).border(noBorders).end,
        new Cell(new Txt('TOTAL').fontSize(8).bold().end).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
      ],
      [
        new Cell(new Txt('').fontSize(8).bold().end).border(noBorders).end,
        new Cell(new Txt('TOTAL DE CATEGORIA').fontSize(8).bold().end).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt('0.00').fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
      ],
    ]).headerRows(3).end
  );

  pdf.create().open();
}
