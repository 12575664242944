import { ComponentType } from "@angular/cdk/portal";
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { filterMultiCustom } from "@utils/select/select";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OptionsData } from "src/app/admin/apps/ventas/pages/maestros/cliente/cliente-dialog/cliente-dialog.component";
import { ConfigurationService } from "src/app/services";

@Component({
  selector: 'select-search-crud',
  styleUrls: ['./select-search-crud.component.scss'],
  templateUrl: './select-search-crud.component.html'
})
export class SelectSearchCRUDComponent {

  @Input() id: string;
  @Input() dialogComponent: ComponentType<any>;

  @Input() disabled: boolean;
  @Input() required: boolean = false;
  @Input() placeholder: string;
  @Input() clase: string;
  @Input() label: string;
  @Input() value: any;
  @Input() model: any;
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() multiple: boolean = false;
  @Input() iconAction: string;
  @Input() tooltipAction: string;
  @Input() setColorSelected: boolean;
  @Input() canAdd: boolean = false;
  @Input() canEdit: boolean = false;
  @Output() cambioSelect: EventEmitter<any>;
  @Output() clickAction: EventEmitter<any>;

  @Input() data: any[] = [];
  public ctrl: FormControl = new FormControl();
  public filterCtrl: FormControl = new FormControl();
  public filtered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('inputSelect', { static: true }) ubicacionSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  moduleStore$: Subscription;
  loadingStore$: Subscription;

  codigoModulo: string;
  menu: any;

  constructor(
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService,
    public dialog: MatDialog
  ) {

    this.cambioSelect = new EventEmitter<string>();
    this.clickAction = new EventEmitter<any>();

  }

  ngAfterViewInit(): void {

    this.loadingStore$ = this.store.select('loading').subscribe(stateLoading => {

      if(!stateLoading.isLoadingCompany && !stateLoading.isLoadingSidenav && !stateLoading.isLoadingModule) {

        this.moduleStore$ = this.store.select('period').subscribe(state => {
          this.codigoModulo = state.codMod;
        })

        this.menu = this._configurationService.obtenerMenu();

        const modulo = this.menu.find(item => item.codeMod == this.codigoModulo);

        if(modulo) {
          const boton = modulo.botones.find(item => item.program == this.id);

          if(boton) {
            this.canAdd = true;
            this.canEdit = true;
          }
          console.log('tiene permiso', boton);
        }

      }
    })

  }

  ngOnInit(): void {
    this.filtered.next(this.data.slice());

    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filtered.next(this.data.slice());

    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filter();
      });
  }

  seleccionar(event): void {

    console.log('seleccionado', event)
    this.cambioSelect.emit(event.value);

  }

  clickButton(): void {
    this.clickAction.emit(true);
  }

  agregar(): void {
    console.log('searched', this.filterCtrl.value)
    this.model=null;
    const dialogRef = this.dialog.open(this.dialogComponent, {
      width: 'auto',
      data: {id: '0', search: this.filterCtrl.value, isDialog: true} as OptionsData,
      panelClass: 'pav-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  editar(id): void {
    console.log('id', id)
    const dialogRef = this.dialog.open(this.dialogComponent, {
      width: '100%',
      data: {id, search: undefined, isDialog: true} as OptionsData,
      panelClass: 'pav-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  protected filter(): void {
    this.filtered.next(
      filterMultiCustom(this.data, this.filterCtrl.value, this.description)
    )
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();

    unsubscribeSubscription([this.loadingStore$, this.moduleStore$])
  }

}
