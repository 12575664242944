import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActividadCRM } from "src/app/models/crm/actividad-crm";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ActividadService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerActividades(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/actividad/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerActividad(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/actividad/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param actividad
   * @returns
   */
  registrarActividad(actividad: ActividadCRM): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/actividad`,
      actividad
    );
  }

  /**
   *
   * @param actividad
   * @returns
   */
  actualizarActividad(actividad: ActividadCRM): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/actividad`,
      actividad
    );
  }
}
