import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Subscription } from 'rxjs';
import { Driver } from 'src/app/models';
import { AuthenticationService, VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-customer-follow-up',
  templateUrl: './customer-follow-up.component.html',
  styleUrls: ['./customer-follow-up.component.css']
})
export class CustomerFollowUpComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  displayedColumns: string[] = ['acciones', 'item', 'tipo_doc', 'nro_documento', 'fecha_registro', 'observacion', 'fecha_actividad', 'vb', 'situacion', 'descripcion'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  usuario: any;
  fecha: Date = new Date();

  clientes: any[] = [];

  cliente$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>,
    private _ventasService: VentasService
  ) {

    this.driver = new Driver();

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  /**
   * Obtiene los maestros de :
   * . clientes
   */
  loadData(): void {
    this.driver.CHO_INDSTA = "1";
    this.cliente$ = this._ventasService.obtenerClientes().subscribe(
      clientes => this.clientes = clientes,
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  registrarSeguimiento(f: NgForm): void {

  }

  volver(): void {
    // this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.cliente$, this.loading$]);
  }

}
