import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { PeriodState } from "@data/interfaces/state/period-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { cambiarPaginaActual } from "@shared/state/actions/current-page.actions";
import { cambiarLoadingModule, cambiarLoadingSidenav } from "@shared/state/actions/loading.actions";
import { cambiarMenu } from "@shared/state/actions/menu-state.actions";
import { cambiar } from "@shared/state/actions/period.actions";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { orderKeyStringJSON } from "@utils/sorts/sort";
import { Subscription } from "rxjs";
import { ConfigurationService, MenuService } from "src/app/services";
import { CIAService } from "src/app/services/api/sistema/cia.service";
import * as actionsConfig from '@shared/state/actions/config.actions';
@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy{

  menu: any[] = [];
  client: string;
  companyClient: string;
  urlId: string;

  menu$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  language$: Subscription;
  config$: Subscription;

  loading: boolean = false;

  codMod: string;
  language: string;

  moduloActual: string;
  tieneMasDeUnaCompaniaCleinte: boolean;

  @Output() seleccionarItemMenu: EventEmitter<void>;

  periodo: PeriodState;

  config: any;

  constructor(
    private _menuService: MenuService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private _ciaService: CIAService,
    private store: Store<PavsoState>
  ) {

    this.seleccionarItemMenu = new EventEmitter();

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.codMod = state.codMod;
      this.periodo = state;
    })

  }

  ngOnInit(): void {
    this.language$ = this.store.select('language').subscribe(state => {
      this.language = state.language;
      if(this._configurationService.obtenerIdioma() != state.language) {
        this.obtenerMenu();
      }
    })

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && state.isLoadingSidenav && state.isLoadingModule) {
        this.client = this._configurationService.obtenerClienteId();
        this.urlId  = this._configurationService.obtenerUrl();
        console.table([this.client, this.urlId])
        this.moduloActual = localStorage.getItem('codeModule');
        console.log('MODULO ACTUAL', this.moduloActual)
        this.companyClient = this._configurationService.obtenerCompaniaCliente();
        this.obtenerMenuState();

        const companias = this._configurationService.obtenerCompaniasCliente() ? this._configurationService.obtenerCompaniasCliente(): '[]';
        this.tieneMasDeUnaCompaniaCleinte = JSON.parse(companias).length > 1? true: false;
      }
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.config = state;
    })
  }

  obtenerMenu(): void {
    this.loading = true;
    if(this.client && this.urlId && this.companyClient) {
      this.menu$ = this._menuService.obtenerMenu(this.language).subscribe(
        response => {
          this.menu = response.sort(orderKeyStringJSON("codeMod"));

          if(this._configurationService.obtenerModuloActivo()) {
            const moduloActivo = this._configurationService.obtenerModuloActivo();
            this.menu.forEach(item => {
              if(item.codeMod == moduloActivo) {
                item.showSubmenu = true;
              }
            });
          }

          console.log('MODULO MODIFICADO', this.menu)

          this.menu.forEach(element => {
            if(this.codMod == element.codeMod) {
              element['showSubmenu'] = true;
              element['isShowing'] = true;
            }
          });

          this._configurationService.establecerMenu(response.sort(orderKeyStringJSON("TDO_CODTDO")));
          this.loading = false;
        },
        error => {
          this._snackBarService.showError("Error al obtener el menú", "OK");
          this.loading = false;
          // window.location.reload();
          this.store.dispatch(cambiarMenu({active: false}));
        }
      )
    } else {
      this.menu = [];
      this.loading = false;

    }
  }

  detalleCIA$: Subscription;

  obtenerMenuState(): void {
    console.log('obtener menu state')
    this.loading = true;
    if(this.client && this.urlId && this.companyClient) {
      this.detalleCIA$ = this._ciaService.obtenerCIA().subscribe(
        ciaResponse => {

          this.store.dispatch(actionsConfig.cambiarConfiguracionGeneral({...this.config, logo: ciaResponse.CIA_LOGB64? ciaResponse.CIA_LOGB64: '', dircia: ciaResponse.CIA_DIRCIA}))

          this.menu$ = this._menuService.obtenerMenu(this.language).subscribe(
            response => {
              if(response) {
                console.log('response menu', response)
                this.menu = response.sort(orderKeyStringJSON("codeMod"));

                if(this._configurationService.obtenerModuloActivo()) {
                  const moduloActivo = this._configurationService.obtenerModuloActivo();
                  this.menu.forEach(item => {
                    if(item.codeMod == moduloActivo) {
                      item.showSubmenu = true;

                      item.submenus.forEach(submenu => {
                        if(submenu.submenus) {
                          submenu.submenus.forEach(el => {
                            if(el.program == this._configurationService.obtenerCodigoPagina()) submenu.showSubmenu = true;
                          })
                        }
                      });
                    }
                  });
                }

                console.log(' ++++ menu +++++', this.menu)
                // const sidebar = this._sidebar.menuClient;
                // sidebar[1].submenus = response.sort(orderKeyStringJSON("codeMod"));
                // this.menu = sidebar;
                this._configurationService.establecerMenu(response.sort(orderKeyStringJSON("TDO_CODTDO")));
                this.loading = false;
                this.store.dispatch(cambiarLoadingSidenav({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: true}));
              } else {
                this.menu = [];
                this._snackBarService.showError('No se encontraron módulos disponibles para este usuario', 'Ok')
                this.loading = false
                this.store.dispatch(cambiarLoadingSidenav({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: true}));

              }
            },
            error => {
              this._snackBarService.showError("Error al obtener el menú", "OK");
              this.loading = false;
              // window.location.reload();
              this.store.dispatch(cambiarMenu({active: false}));
            }
          )
        },
        error => {
          this._snackBarService.showError("Error al obtener detalle cia", "OK");
          this.loading = false;
          // window.location.reload();
          this.store.dispatch(cambiarMenu({active: false}));
        }
      )

    } else {
      console.log('false')
      this.menu = [];
      this.loading = false;

    }
  }

  verPagina(program: string, codigo, xfunction: string): void {
    console.log('function', xfunction)
    this.seleccionarItemMenu.emit();
    this.store.dispatch(cambiarPaginaActual({program, xfunction}));
    this._configurationService.establecerCodigoPagina(program);
    this._configurationService.establecerTipoPagina(xfunction);
    this._menuService.obtenerPermisos(program);
    this.store.dispatch(cambiar({...this.periodo}))

    localStorage.setItem('codeModule', codigo)

    if(codigo == 'W51') {

      if(this.codMod == 'W51') {
        this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: false, isLoadingTypeNominaDialog: false}));
        return;
      }

      this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: true, isLoadingModule: true, isLoadingTypeNominaDialog: true}));
      return;
    }

    // this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingModule: true, isLoadingTypeNominaDialog: false}));
    console.log('ver pagina')

  }


  seleccionarModulo(modulo): void {

    console.log('CODE MODULE', modulo)
    localStorage.setItem('codeModule', modulo.codeMod)

    this.moduloActual = modulo.codeMod;

    this.menu.forEach(element => {
      if(element.codeMod != modulo.codeMod) element.showSubmenu = false;
    });

    if(modulo == 'W51') {

      if(this.codMod == 'W51') {
        this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: true, isLoadingTypeNominaDialog: false}));
        return;
      }

      console.log('se lanzo accion')
      this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: true, isLoadingTypeNominaDialog: true}));
      return;
    }

    this.store.dispatch(cambiarLoadingModule({isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: true, isLoadingTypeNominaDialog: false}));

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.menu$, this.loading$, this.periodo$, this.language$])
  }
}
