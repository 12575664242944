import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DocumentToUpload } from "../../registro-compra-form/registro-compra-form.component";

@Component({
  selector: 'registro-compra-show-image-dialog',
  template: `
    <h1 mat-dialog-title>Imagen de factura</h1>
    <div mat-dialog-content class="pav-ticket-img">
      <div class="img-zoom-container">
        <div class="row image-container">

          <!-- <img id="myimage" [src]="imageBase64" alt="Imagen" [width]="width" [height]="height"> -->
          <img id="myimage" [src]="imageBase64" alt="Imagen" width="100%" height="100%">
          <br>
          <!-- <div id="mylens" class="img-zoom-lens"></div> -->

        </div>
        <!-- <div class="row" align="center">
          <div id="myresult" class="img-zoom-result" [ngStyle]="{'background-image': 'url(' + imageBase64 + ')'}"></div>
        </div> -->
      </div>
    </div>
    <div mat-dialog-actions align="center">
      <button class="pav-button-action" mat-button (click)="cerrar()">Confirmar</button>
    </div>
  `,
  styleUrls: ['./registro-compra-show-image.dialog.scss']
})
export class RegistroCompraShowImageDialog {

  width: any;
  height: any;

  imageBase64: any;

  constructor(
    public dialogRef: MatDialogRef<RegistroCompraShowImageDialog>,
    @Inject(MAT_DIALOG_DATA) public documentToUpload: DocumentToUpload,
  ) {

    console.log('document to upload', this.documentToUpload)

  }

  ngOnInit(): void {

    const reader = new FileReader();

    reader.onload = (event) => {
      this.imageBase64 = event.target.result;
      console.log('base 64', this.imageBase64)

    }

    reader.readAsDataURL(this.documentToUpload.invoiceFile);

    // setTimeout(() => {
      let img = new Image();
      img.src = this.imageBase64;

      img.decode().then(() => {

        this.width = img.width;

        this.height = img.height;

        this.establecerZoomImagen();
        console.log('ingreso')
      })
    // }, 1000);
  }

  establecerZoomImagen(): void {
    // imageZoom("myimage", "myresult");

    var img, lens, result, cx, cy;
    img = document.getElementById("myimage");
    result = document.getElementById("myresult");
    /*create lens:*/
    // lens = document.createElement("DIV");
    lens = document.getElementById("mylens");
    // lens.setAttribute("class", "img-zoom-lens");
    /*insert lens:*/
    img.parentElement.insertBefore(lens, img);
    /*calculate the ratio between result DIV and lens:*/
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    /*set background properties for the result DIV:*/
    // result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (this.width * cx) + "px " + (this.height * cy) + "px";
    /*execute a function when someone moves the cursor over the image, or the lens:*/
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /*and also for touch screens:*/
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);

    function moveLens(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lens:*/
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      /*prevent the lens from being positioned outside the image:*/
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }

    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

  cerrar(): void {
    this.dialogRef.close(this.documentToUpload);
  }

}
