export class ListaRequisito {
  codcia        : string;
  numorc        : string;
  fecreg        : Date;
  desobj        : string;
  plaent        : number;
  indsta        : string;
  fecupd        : Date;
  coduse        : string;
  codolc        : string;
  indent        : string;
  idtkt         : string;
  idset         : string;
  REGI_LIST_ORD : Array<RegiListOrd>

  constructor() {
    this.fecupd         = new Date();
    this.indsta         = '1';
    this.REGI_LIST_ORD  = [];
    this.codolc         = '01';
    this.idtkt          = '000001';
    this.REGI_LIST_ORD = []
  }
}

export class RegiListOrd {
  numorc      : string;
  codreq      : string;
  desreq      : string;
  corord      : string;
  numofe      : string;
  numite      : string;
  pagnum      : number;
  pagane      : number;
  indter      : number;
  observ      : string;
  fecpro      : Date;
  isEditing   : boolean;

  constructor() {
    this.isEditing = true;
    this.fecpro = new Date();
  }
}
