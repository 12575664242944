import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Tienda } from "src/app/models/ventas/tienda";
import { GeneralService, VentasService, AlmacenService as ModuloAlmacen } from "src/app/services";
import { AlmacenService } from "src/app/services/api/almacen/tabla-apoyo/almacen.service";

@Component({
  selector: 'tienda-form',
  templateUrl: './tienda-form.component.html',
  styleUrls: ['./tienda-form.component.scss']
})
export class TiendaFormComponent implements OnInit, OnDestroy {

  uid: string;

  tienda: Tienda;

  loaderReg: boolean;
  loaderData: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  paises$: Subscription;
  ubicaciones$: Subscription;
  almacenes$: Subscription;
  motivos$: Subscription;
  sendForm$: Subscription;
  loading$: Subscription;

  paises: any[];
  ubicaciones: any[];
  almacenes: any[];
  motivos: any[];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _ventasService: VentasService,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _almacenService: AlmacenService,
    private _moduloAlmacenService: ModuloAlmacen,
    private _router: Router,
    private store: Store<PavsoState>
  ) {
    this.tienda = new Tienda();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({id})=>{

      this.loadMaestros();

      this.uid = id;

      this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE: this.buttonsName.BTN_UPDATE;

    })
  }

  loadMaestros(): void {

    forkObs(
      this._generalService.listarPaises(),
      this._generalService.listarUbicacion(null),
      this._almacenService.obtenerAlmacenes(),
      this._moduloAlmacenService.obtenerMotivos(),
    ).then(data => {

      this.paises = data[0];
      this.ubicaciones = data[1];
      this.almacenes = data[2];
      this.motivos = data[3];

      if(this.uid != '0') {
        this.obtenerTienda();
        return;
      };
      this.loaderData = false;

    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", 'Ok');
    })

  }

  obtenerTienda(): void {

    this._ventasService.listarTiendas().subscribe(
      tiendas => {
        this.tienda = tiendas.find(item => item.tie_codtie == this.uid);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )

  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarTienda(f) : this.actualizarTienda(f);
  }

  registrarTienda(f: NgForm): void {
    this.sendForm$ = this._ventasService.registrarTienda(this.tienda).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tienda registrada', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarTienda(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarTienda(this.tienda).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Tienda actualizada', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/tiendas'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.paises$,
      this.ubicaciones$,
      this.almacenes$,
      this.motivos$,
      this.sendForm$
    ])
  }

}
