import { Component } from "@angular/core";

@Component({
  selector: 'app-panel-compras',
  templateUrl: './panel-compras.component.html',
  styleUrls: ['./panel-compras.component.scss']
})
export class PanelComprasComponent {
  dataChart: Array<any> = [
    {
      name: "Aprobadas",
      data: [44, 55, 57]
    },
    {
      name: "Atendidas",
      data: [76, 85, 101]
    },
    {
      name: "Registradas",
      data: [17, 20, 18]
    }
  ]

  categoriesChart: Array<any> = [
    "Ene",
    "Feb",
    "Mar",
  ]
}

