export class ConceptoRemunerativo {
  idnco: any;
  codnco: string;
  descri: string;
  feccre: Date;
  coduse: string;
  indsta: string;
  fecupd: Date;
  codnut: string;
  indtip: string;
  indafp: number;
  indren: number;
  indqui: number;
  indvar: number;
  codofi: string;
  indpvs: number;
  aferta: number;
  indimp: number;
  indddu: number;
  indnia: number;
  indoct: number;
  indctg: number;
  inddef: number;
  indfis: number;
  impdef: number;
  AFEAFP: number;
  indina: number;
  desess: number;
  afects: number;
  codgru: string;
  afegra: number;
  afevac: number;
  indsub: number;
  subfal: string;
  excgas: number;
  excpag: number;
  indapv: number;
  indcos: number;
  codcli: string;
  calqui: number;
  nomcli: string;

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
  }
}
