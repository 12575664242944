<app-pavso-title-section [title]="'Banco'" [module]="'Ventas'"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="registrarBanco(f)" (keydown.enter)="$event.preventDefault()">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Código</mat-label>
          <input type="text" matInput placeholder="Código" [(ngModel)]="banco.BAN_CODBAN" name="BAN_CODBAN">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre</mat-label>
          <input type="text" matInput placeholder="Nombre" [(ngModel)]="banco.BAN_NOMBRE" name="BAN_NOMBRE">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre Abreviado</mat-label>
          <input type="text" matInput placeholder="Nombre Abreviado" [(ngModel)]="banco.BAN_NOMABR" name="BAN_NOMABR">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select [(ngModel)]="banco.BAN_INDSTA" name="BAN_INDSTA">
            <mat-option value="1">
              ACTIVO
            </mat-option>
            <mat-option value="2">
              INACTIVO
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-ventas/bancos"
    >
      <div id="botonesAdicionales">
      </div>

  </pav-form-actions>
</form>
