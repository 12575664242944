import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_ALMACEN_VALORIZADOS } from "@data/json/reportes/almacen.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subject, Subscription } from "rxjs";
import { FiltroReporteAlmacen } from "src/app/models/almacen/filtro-reporte-almacen";
import { ConfiguracionTecnica } from "src/app/models/almacen/inventario/configuracion-tecnica";
import { OpcionFiltro } from "src/app/models/general/opciones-filtro";
import { AlmacenService, GeneralService } from "src/app/services";
import { ColorService } from "src/app/services/api/almacen/maestros/color.service";
import { KardexService } from "src/app/services/api/almacen/operaciones/kardex.service";
import { SubfamiliaService } from "src/app/services/api/almacen/tabla-apoyo/subfamilia.service";
import { InventariosReportService } from "src/app/services/reports/modules/inventarios-report.service";
import { OtherDataFiltroAlmacen } from "../informes-unidades/informes-unidades.component";
import { ModeloService } from "src/app/services/api/almacen/maestros/modelo.service";
import { UnidadMedidaService } from "src/app/services/api/almacen/configuracion/unidad-medida.service";

@Component({
  selector: 'informes-valorizados',
  templateUrl: './informes-valorizados.component.html',
  styleUrls: ['./informes-valorizados.component.scss']
})
export class InformesValorizadosComponent implements OnInit, OnDestroy {

  loaderFields: boolean = false;

  familias: any[] = [];
  subfamilias: any[] = [];
  almacenes: any[] = [];
  responsables: any[] = [];
  motivos: any[] = [];
  productos: any[] = [];
  inventarios: any[] = [];
  lineas: any[] = [];
  documentos: any[] = [];
  marcas: any[] = [];
  unidadesMedida: any[] = [];
  monedas: any[] = [];
  modelos: any[] = [];
  colores: any[] = [];
  tecnologias: any[] = [];

  displayedColumns: string[] = ['acciones', 'mac_nummac', 'mac_fecmac', 'mac_glomac'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tipoMovimiento = "ING";

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  range: FormGroup;

  familia = "0001";

  movimiento$: Subscription;
  columnas$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  dataForm$: Subscription;
  colores$: Subscription;
  configuracion$: Subscription;
  filtros$: Subscription;
  modelos$: Subscription;
  establecimiento$: Subscription;
  unidadesMedida$: Subscription;

  reportes: any[] = REPORTES_ALMACEN_VALORIZADOS

  reporteSeleccionado: any = ['ALMACEN_MMV'];

  filtroAlmacen: FiltroReporteAlmacen;

  loading: boolean = true;

  opcionesFiltro: any[] = [];
  opcionFiltro: OpcionFiltro;

  year: string;
  month: string;

  protected _onDestroy = new Subject<void>();

  isMobile: boolean = false;

  configuracion: ConfiguracionTecnica;

  establecimiento: string;

  constructor(
    private _snackBarService: SnackBarService,
    private _router: Router,
    private _almacenService: AlmacenService,
    private _almacenReporteService: InventariosReportService,
    private _generalService: GeneralService,
    private store: Store<PavsoState>,
    private readonly colorService: ColorService,
    private readonly modeloService: ModeloService,
    private _kardexService: KardexService,
    private breakpointObserver: BreakpointObserver,
    private readonly _subfamiliaService: SubfamiliaService,
    private readonly _unidadMedidaService: UnidadMedidaService
  ) {
    this.configuracion = new ConfiguracionTecnica();

    this.opcionFiltro = new OpcionFiltro();

    this.filtroAlmacen = new FiltroReporteAlmacen();

    this.dataSource = fillTable([], this.paginator, this.sort);

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        console.log('Is Mobile:', this.isMobile);
      });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
      this.filtroAlmacen.establecimiento = state.code;
    })

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    });
  }

  loadData(): void {
    // this.loaderFields = true;
    // this.loading = true;

    this.dataForm$ = this._almacenService.obtenerDataFiltroAlmacen().subscribe(
      dataForm => {
        this.familias = dataForm.familias;
        this.familias.forEach(item => item['description_reverse'] = `${item['fpr_descri']} | ${item['FPR_CODFPR']}`)
        this.familias.forEach(item => item['FPR_CODFPR'].trim())

        this.subfamilias = dataForm.subFamilias;
        this.almacenes = dataForm.almacenes;

        this.almacenes.forEach(item => item['description_reverse'] = `${item['ALM_DESCRI']} | ${item['ALM_CODALM']}`)
        this.almacenes.forEach(item => item['description'] = `${item['ALM_CODALM']} | ${item['ALM_DESCRI']}`)
        this.almacenes.forEach(item => item['ALM_CODALM'] = item['ALM_CODALM'].trim())


        // Filtrar almacenes por almacenamiento
        if (this.establecimiento && this.establecimiento.trim() != '') {
          this.almacenes = this.almacenes.filter(item => item.TIE_CODTIE == this.establecimiento);
        }

        this.responsables = dataForm.responsables;
        this.motivos = dataForm.motivos;
        this.productos = dataForm.productos;
        this.inventarios = dataForm.tiposInventario;
        this.lineas = dataForm.lineas;
        this.documentos = [{ tipo: 'INGRESO', code: 'ING' }, { tipo: 'SALIDA', code: 'SAL' }];
        this.monedas = dataForm.monedas;
        this.marcas = dataForm.marcas;
        console.log('almacenes', this.almacenes)

        this.configuracion$ = this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
          configuracion => {

            this.configuracion = configuracion[0];

            this.colores$ = this.colorService.obtenerColores().subscribe(
              colores => {
                this.colores = colores;

                this.modelos$ = this.modeloService.obtenerModelos().subscribe(
                  modelos => {
                    this.modelos = modelos;

                    this.filtros$ = this._generalService.obtenerOpcionesFiltrosReporteModulo().subscribe(
                      response => {

                        this.unidadesMedida$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                          unidades => {
                            this.unidadesMedida = unidades;
                            console.log(response)

                            this.opcionesFiltro = response;

                            this.loaderFields = false;
                            this.loading = false;
                          },
                          error => {
                            this._snackBarService.showError(error.error.msg, 'Ok');
                            this.loaderFields = false;
                            this.loading = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError(error.error.msg, 'Ok');
                        this.loaderFields = false;
                        this.loading = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok');
                    this.loaderFields = false;
                    this.loading = false;
                  }
                )


              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderFields = false;
                this.loading = false;
              }
            )


          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderFields = false;
            this.loading = false;
          }
        )

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderFields = false;
        this.loading = false;
      }
    )

  }

  seleccionarFamilia(): void {
    this._subfamiliaService.obtenerSubFamilias(this.filtroAlmacen.familias).subscribe(
      response => {
        this.subfamilias = response;

        console.log('subfamilias', this.subfamilias)
      },
      error => this._snackBarService.showError(error.error.message, 'OK')
    )
  }

  seleccionarReporte(event): void {

    console.log('seleccionar reporte')
    this.reporteSeleccionado[0] = event.id;

    switch (this.reporteSeleccionado[0]) {
      case "ALMACEN_MMV":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16001");
        break;
      case "ALMACEN_SPV":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16001");
        break;
      case "ALMACEN_KMV":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16002");
        console.log('opcion filtro', this.opcionFiltro)
        break;
      case "ALMACEN_RA":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "16004");
        break;
      default:
        break;
    }

  }

  editarMovimiento(year, month, almacen, tipo, numeroParte) {

    if (this.tipoMovimiento == "ING") {
      this._router.navigate(['/modulo-almacen/editar-parte-de-ingreso', year, month, almacen, tipo, numeroParte])
      return;
    }

    this._router.navigate(['/modulo-almacen/editar-parte-de-salida', year, month, almacen, tipo, numeroParte])

  }

  verPdf(mac_nummac): void { }

  nuevoMovimiento(): void {
    if (this.tipoMovimiento == "ING") {
      this._router.navigate(['/parte-de-ingreso']);
      return;
    }

    this._router.navigate(['/parte-de-salida']);

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  verReporte(): void {

    this.loading = true;

    let headers = {
      codano: this.year,
      codmes: this.month,
      currency: this.filtroAlmacen.moneda
    };

    let otherData: OtherDataFiltroAlmacen = {
      params: [],
      filtro: this.filtroAlmacen,
    };

    if (this.filtroAlmacen.lineas.join(',')) {
      otherData.params.push({
        param: 'linea',
        value: this.filtroAlmacen.lineas.join(',')
      })
    }

    if (this.filtroAlmacen.tecnologias.join(',')) {
      otherData.params.push({
        param: 'modelo',
        value: this.filtroAlmacen.tecnologias.join(',')
      })
    }

    if (this.filtroAlmacen.lote) {
      otherData.params.push({
        param: 'lote',
        value: this.filtroAlmacen.lote
      })
    }

    if (this.filtroAlmacen.familias.join(',')) {
      otherData.params.push({
        param: 'familia',
        value: this.filtroAlmacen.familias.join(',')
      })
    }

    if (this.filtroAlmacen.subFamilias.join(',')) {
      otherData.params.push({
        param: 'subfamilia',
        value: this.filtroAlmacen.subFamilias.join(',')
      })
    }

    if (this.filtroAlmacen.almacenes.join(',')) {
      otherData.params.push({
        param: 'almacen',
        value: this.filtroAlmacen.almacenes.join(',')
      })
    }

    if (this.filtroAlmacen.tiposInventario.join(',')) {
      otherData.params.push({
        param: 'tipoinventario',
        value: this.filtroAlmacen.tiposInventario.join(',')
      })
    }

    if (this.filtroAlmacen.unidadesMedida.join(',')) {
      otherData.params.push({
        param: 'unidadmedida',
        value: this.filtroAlmacen.unidadesMedida.join(',')
      })
    }

    if (this.filtroAlmacen.motivos.join(',')) {
      otherData.params.push({
        param: 'motivo',
        value: this.filtroAlmacen.motivos.join(',')
      })
    }

    if (this.filtroAlmacen.marcas.join(',')) {
      otherData.params.push({
        param: 'marca',
        value: this.filtroAlmacen.marcas.join(',')
      })
    }

    // if(this.filtroAlmacen.tecnologias.join(',')) {
    //   otherData.params.push({
    //     param: 'calibre',
    //     value: this.filtroAlmacen.tecnologias.join(',')
    //   })
    // }
    if (this.filtroAlmacen.colores.join(',')) {
      otherData.params.push({
        param: 'categoria',
        value: this.filtroAlmacen.colores.join(',')
      })
    }

    if (this.filtroAlmacen.tiposDocumento.join(',')) {
      otherData.params.push({
        param: 'tipodocumento',
        value: this.filtroAlmacen.tiposDocumento.join(',')
      })
    }

    if (this.filtroAlmacen.productos.join(',')) {
      otherData.params.push({
        param: 'producto',
        value: this.filtroAlmacen.productos.join(',')
      })
    }

    if (this.filtroAlmacen.marcas.join(',')) {
      otherData.params.push({
        param: 'marca',
        value: this.filtroAlmacen.marcas.join(',')
      })
    }

    if(this.establecimiento != '' && this.establecimiento) {
      otherData.params.push({
        param: 'tienda',
        value: this.establecimiento
      })
    }

    this._almacenReporteService.verReporte(this.reporteSeleccionado[0], headers, otherData)
      .then(_ => this.loading = false)
      .catch(_ => this.loading = false)
      .finally(() => this.loading = false)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.movimiento$,
      this.columnas$,
      this.loading$,
      this.periodo$,
      this.dataForm$,
      this.colores$,
      this.configuracion$,
      this.filtros$,
      this.modelos$,
      this.establecimiento$,
      this.unidadesMedida$,
    ])
  }
}
