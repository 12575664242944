export class ConfiguracionRentaQuinta {
  codano: any;
  monto: any;
  numuit: any;
  creanu: any;
  detalle: Array<DetalleConfiguraRentaQuinta>;

  constructor() {
    this.detalle = [];
  }
}

export class DetalleConfiguraRentaQuinta {
  cornqd: any;
  descri: any;
  numuitacu: any;
  porren: any;
  montoacu: any;
  isEditing: boolean;

  constructor() {
    this.isEditing = true;
  }
}
