export const REPORTES_ANALITICO_CONTABILIDAD = [
  {
    id: "CONTABILIDAD_SAR",
    name: "Saldos analíticos resumen",
    category: "REPORTES ANALÍTICOS",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_ACC",
    name: "Analítico por cuenta contable",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_RAC",
    name: "Resumen por auxiliar vs cuenta",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_RCD",
    name: "Resumen por cuenta vs documento",
    category: "",
    esDinamico: false,
  },
]

export const REPORTES_CENTRO_COSTO_CONTABILIDAD = [
  {
    id: "CONTABILIDAD_RCCO",
    name: "Resumen por centro de costos",
    category: "REPORTES POR CENTRO DE COSTOS",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_RMCCC",
    name: "Resumen mensual centro costo vs cuenta",
    category: "",
    esDinamico: false,
  },
]

export const REPORTES_ESTADOS_FINANCIEROS_CONTABILIDAD = [
  {
    id: "CONTABILIDAD_BG",
    name: "Balance general",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_BGA",
    name: "Balance general analítico",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_EGPF",
    name: "Estado de G/P por función",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_EGPFA",
    name: "Estado de G/P por función analítico",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_EGPN",
    name: "Estado de G/P por naturaleza",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_EGPNA",
    name: "Estado de G/P por naturaleza analítico",
    category: "",
    esDinamico: false,
  },
]

export const REPORTES_CONTABLES_CONTABILIDAD = [
  {
    id: "CONTABILIDAD_LDR",
    name: "Libro diario resumen",
    category: "REPORTES CONTABLES",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_LCB",
    name: "Libro de caja y banco",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_AG633",
    name: "Análisis de gasto - 6 y 33",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_MA",
    name: "Mayor analítico",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_RH",
    name: "Registro de honorarios",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_PC",
    name: "Plan contable",
    category: "",
    esDinamico: false,
  },

  {
    id: "CONTABILIDAD_BCEEFF",
    name: "Balance de comprobación de EE.FF.",
    category: "REPORTES EE.FF.",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_BCEEFFA",
    name: "Balance de comprobación EE.FF. anual",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_BCEEFFS",
    name: "Balance de comprobación EE.FF. SUNAT",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_GED",
    name: "Generar excel dinámico",
    category: "",
    esDinamico: false,
  },

  {
    id: "CONTABILIDAD_RCC",
    name: "Registro de compras por comprobante",
    category: "",
    esDinamico: false,
  },
]

export const FORMATOS_LIBROS_CONTABLES_CONTABILIDAD = [
  {
    id: "CONTABILIDAD_FLIGR4C",
    name: "Formato 2.2: libro ingresos y gastos - rentas de 4ta categoría",
    category: "REPORTES OFICIALES",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_FC10CB",
    name: "Formato 3.2: cuenta 10 - caja y bancos",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_FC12C",
    name: "Formato 3.3: cuenta 12 - clientes",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_FC40TP",
    name: "Formato 3.10: cuenta 40 - tributos por pagar",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_FLD",
    name: "Formato 5.1: libro diario",
    category: "",
    esDinamico: false,
  },
]

export const REPORTES_DAOT_CONTABILIDAD = [
  {
    id: "CONTABILIDAD_RDI",
    name: "Reporte DAOT ingresos",
    category: "",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_RDC",
    name: "Reporte DAOT costos",
    category: "",
    esDinamico: false,
  },
]

export const REPORTES_GESTION_CONTABILIDAD = [
  {
    id: "CONTABILIDAD_CB",
    name: "Conciliación bancaria",
    category: "OTROS",
    esDinamico: false,
  },
  {
    id: "CONTABILIDAD_MCBSI",
    name: "Movimiento de caja bancos con saldo inicial",
    category: "",
    esDinamico: false,
  },

  {
    id: "CONTABILIDAD_CCLAC",
    name: "Consolidado de compras locales - almacen - contabilidad",
    category: "",
    esDinamico: false,
  },
]

export const REPORTES_DINAMICOS_CONTABILIDAD = [
  {
    id: "CONTABILIDAD_RC",
    name: "Registro de Compra",
    esDinamico: true
  },
  {
    id: "CONTABILIDAD_RV",
    name: "Registro de Venta",
    esDinamico: true
  },
  {
    id: "CONTABILIDAD_AC",
    name: "Asiento Contable",
    esDinamico: true
  },
  {
    id: "CONTABILIDAD_RH",
    name: "Registro de Honorarios",
    esDinamico: true
  },
  {
    id: "CONTABILIDAD_BCEF",
    name: "Balance de Comprobación EE.FF.",
    esDinamico: true
  }
];
