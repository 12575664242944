<app-pavso-title-section [title]="'Movimiento por nómina'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <div class="pav-table-search-container">
    <div class="pav-table-info">
      <mat-form-field>
        <mat-label>Planilla</mat-label>
        <input matInput placeholder="Planilla" [(ngModel)]="nomina" name="nomina" readonly>
      </mat-form-field>
    </div>
    <div class="pav-table-filter">
      <mat-form-field>
        <mat-label>Seleccionar concepto</mat-label>
        <mat-select [(ngModel)]="concepto" (selectionChange)="seleccionarConcepto($event.value)" name="nco_codnco">
          <mat-option *ngFor="let concepto of conceptos; trackBy: trackByConcepto" [value]="concepto.codnco">
            {{concepto.descri}} &nbsp;|&nbsp; ({{concepto.codnco}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>
  <br>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">

    </div>
    <div class="pav-table-header-title">
      Movimientos
    </div>
    <div class="pav-table-header-add">

      <div class="isMobile">
        <div class="pav-button-icon-add" (click)="agregarItem()">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <!-- <button [disabled]="loaderData" mat-flat-button class="pav-button-add-item" type="button" (click)="confirmarTodos()" *ngIf="estanHabilitados">Confirmar todos</button>
        &nbsp;
        <button [disabled]="loaderData" mat-flat-button class="pav-button-add-item" type="button" (click)="editarTodos()" *ngIf="!estanHabilitados">Editar todos</button>
        &nbsp; -->
        <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarItem()">Agregar ítem</button>
      </div>
    </div>
  </div>

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarMovimiento(f)" (keydown.enter)="$event.preventDefault()">

      <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" data-label="Acciones" class="td-responsive">
                <div class="pav-btns-container">
                  <!-- <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                  <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                    <mat-icon svgIcon="confirm"></mat-icon>
                  </div> -->
                  <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

              <ng-container matColumnDef="cli_nomcli">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Colaborador" class="td-responsive">
                    <ng-container *ngIf="row.isEditing">

                      <select-search
                        [label]="''"
                        [placeholder]="'Ej. '"
                        [name]="'codcli' + i"
                        [value]="'codcli'"
                        [description]="'nomcli'"
                        [data]="colaboradores"
                        (cambioSelect)="row.codcli = $event"
                        [model]="row.codcli"
                        [multiple]="true"
                        >
                      </select-search>

                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.codcli}} | {{row.nomcli}}
                    </ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="nmt_imprem">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Importe" class="td-responsive pav-td-right">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                        <mat-label></mat-label>
                        <input #importe (blur)="blurImporte(row)" (focus)="importe.select()" step="0.01" class="pav-input-align-right" min="0" required type="number" matInput placeholder="Importe" [(ngModel)]="row.imprem"
                        [name]="'importe' + i.toString()">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.imprem.toFixed(2)}}
                    </ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="nmt_cannut">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Q. Tiempo </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Q. Tiempo" class="td-responsive pav-td-center">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                        <mat-label></mat-label>
                        <input #qTiempo (focus)="qTiempo.select()" step="any" class="pav-input-align-right" min="0" [disabled]="row.codnut == 'H'" required type="number" matInput placeholder="Q. Tiempo" [(ngModel)]="row.cannut"
                        [name]="'qtiempo' + i.toString()">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.cannut}}
                    </ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="nut_codnut">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Unidad" class="td-responsive pav-td-center">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                        <mat-label></mat-label>
                        <mat-select required [(ngModel)]="row.codnut" name="nut_codnut" disabled>
                          <mat-option *ngFor="let unidad of unidades; trackBy: trackByUnidad" [value]="unidad.nut_codnut">{{unidad.nut_descri}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.codnut}}
                    </ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="nmt_canhrs">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Horas </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Horas" class="td-responsive pav-td-right">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                        <mat-label></mat-label>
                        <input #horas (focus)="horas.select()" [disabled]="row.codnut != 'H'" (keyup)="calcularTiempo(row)" class="pav-input-align-right" min="0" required type="number" matInput placeholder="Horas" [(ngModel)]="row.canhrs"
                        [name]="'horas' + i.toString()">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.canhrs}}
                    </ng-container>
                   </td>
              </ng-container>

              <ng-container matColumnDef="nmt_canmin">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Minutos </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Minutos" class="td-responsive pav-td-right">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                        <mat-label></mat-label>
                        <input #minutos (focus)="minutos.select()" [disabled]="row.codnut != 'H'" (keyup)="calcularTiempo(row)" class="pav-input-align-right" min="0" required type="number" matInput placeholder="Minutos" [(ngModel)]="row.canmin"
                        [name]="'minutos' + i.toString()">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.canmin}}
                    </ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="indmod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Modificado </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Minutos" class="td-responsive pav-td-center">
                  <ng-container *ngIf="row.isEditing">
                    <mat-slide-toggle [(ngModel)]="row.indmod" name="indmod"></mat-slide-toggle>
                  </ng-container>
                  <ng-container *ngIf="!row.isEditing">
                    {{row.indmod == 1? 'SI': 'NO'}}
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr (dblclick)="doubleClick($event, row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9">No se encontraron registros "{{input.value}}"</td>
              </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <div align="end">
        <mat-form-field class="total">
          <mat-label>Total Importe</mat-label>
          <input class="pav-input-align-right" type="text" matInput placeholder="Total Importe" [(ngModel)]="total" name="total_importe" readonly>
        </mat-form-field>
      </div>
      <mat-card class="mat-elevation-z0">
        <button class="pav-button-load" mat-flat-button type="button" (click)="cargarColaboradores()">Cargar Colaboradores</button>
        <button class="pav-button-load" mat-flat-button type="button">Cargar Nómina</button>
        <button class="pav-button-load" mat-flat-button type="button" (click)="cargarDesdeExcel()">Cargar desde Excel</button>
        <button *ngIf="mostrarCalculaPlanilla" class="pav-button-load" mat-flat-button type="button">Calcula Planilla</button>
        <button *ngIf="mostrarCalculaGratificacion" class="pav-button-load" mat-flat-button type="button">Calcula Gratificación</button>
        <button *ngIf="mostrarCargarUtilidades" class="pav-button-load" mat-flat-button type="button">Cargar Utilidades</button>
        <button *ngIf="mostrarCalculaQuincenal" class="pav-button-load" mat-flat-button type="button">Calcular Quincena</button>
      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
