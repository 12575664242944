<h1 mat-dialog-title>Consultar Ticket</h1>

<div mat-dialog-content>

  <mat-form-field class="example-full-width">
    <mat-label>Num. Archivo</mat-label>
    <input matInput placeholder="Ex. AAA">
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Num. ticket</mat-label>
    <input matInput placeholder="Ex. BBB">
  </mat-form-field>

</div>

<div align="end">
  <button mat-stroked-button color="primary">Aceptar</button>

</div>
