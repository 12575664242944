import { PavsoColors } from "@data/enums/color.enum";
import { NumeroALetras } from "@utils/conversions/number2letters.conversion";
import { formatDateWithSlash } from "@utils/formats/date.format";
import { numberWithCommas } from "@utils/formats/number.format";
import { bottomBorder, bottomLeftBorder, bottomRightBorder, horizontalBorder, leftBorder, noBorders, openLeftBorder, openRightBorder, rightBorder, topBorder, topLeftBorder, topRightBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, Img, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";

export async function generarFormatoOrdenCompra(headers, data) {
  console.log('orden de compra', data)
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [10, 10, 20, 20], color: 'gray', bold: true },
    ]
  });

  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, color: 'gray', fontSize: 10, bold: true, margin: [5, 2, 10, 10], alignment: 'right' },
    ];
  })

  let detalle = [];

  data.detalle_produc.forEach((item, index) => {
    detalle.push([
      new Cell(
        new Txt(item.ocd_corocd).fontSize(7).end,
      ).border(leftBorder).fillColor(index % 2 == 0 ? '#eee' : 'white').end,
      new Cell(
        new Txt(item.prd_codprd).fontSize(7).end,
      ).border(noBorders).colSpan(2).fillColor(index % 2 == 0 ? '#eee' : 'white').end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(item.prd_desesp).fontSize(7).end,
      ).border(noBorders).fillColor(index % 2 == 0 ? '#eee' : 'white').end,
      new Cell(
        new Txt(item.ume_codume).fontSize(7).end,
      ).border(noBorders).fillColor(index % 2 == 0 ? '#eee' : 'white').end,
      new Cell(
        new Txt(numberWithCommas(item.ocd_canocd)).alignment('right').fontSize(7).end,
      ).border(noBorders).fillColor(index % 2 == 0 ? '#eee' : 'white').end,
      new Cell(
        new Txt(numberWithCommas(item.ocd_preuni.toFixed(2))).alignment('right').fontSize(7).end,
      ).border(noBorders).fillColor(index % 2 == 0 ? '#eee' : 'white').end,
      new Cell(
        new Txt('0.00').alignment('right').fontSize(7).end,
      ).border(noBorders).fillColor(index % 2 == 0 ? '#eee' : 'white').end,
      new Cell(
        new Txt(numberWithCommas(item.ocd_valvta.toFixed(2))).alignment('right').fontSize(7).end,
      ).border(rightBorder).fillColor(index % 2 == 0 ? '#eee' : 'white').end,
    ])
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(headers.logo).alignment('left').width(80).build(),
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(localStorage.getItem('name-company-client')).alignment('center').bold().fontSize(9).end,
            new Txt(data.CIA_DIRCIA).alignment('center').fontSize(9).end,
            // new Txt('Urb. Mexico - La Victoria - Lima - Lima').alignment('center').fontSize(9).end,
            // new Txt('Telef.:323-2264 / 324-3728').alignment('center').fontSize(9).end,
            // new Txt('www.inkafruit.pe').alignment('center').bold().fontSize(9).end,
          ]).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Table(
              [
                [
                  new Cell(
                    new Txt(`RUC Nº ${data.CIA_NUMRUC}`).fontSize(8).alignment('center').bold().end,
                  ).margin([0, 2]).end,
                ],
                [
                  new Cell(
                    new Txt('ORDEN DE COMPRA').fontSize(8).alignment('center').bold().end,
                  ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
                ],
                [
                  new Cell(
                    new Txt(data.ocm_numocm).fontSize(8).alignment('center').bold().end,
                  ).end
                ]

              ]
            ).widths([90]).layout({
              hLineColor: (i, node) => {
                return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
              },
              vLineColor: (i, node) => {
                return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
              },
              hLineWidth: (i, node) => {
                return 1;
              },
              vLineWidth: (i, node) => {
                return 1;
              }
            }).end,
            pdf.ln(1)
          ]).end
        ).alignment('right').border(noBorders).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      // [
      //   new Cell(
      //     new Txt('Especialistas en Sistemas Oleohidráulicas en Maquinaria Minera e Industrias').fontSize(8).bold().end
      //   ).border(noBorders).colSpan(10).end,
      //   ...obtenerNulls(9)
      // ],
      // [
      //   new Cell(
      //     new Txt('Suministro de Repuestos, Reparación - Mantenimiento de Bombas, Válvulas, Electroválvulas y Cilindros').fontSize(8).bold().end
      //   ).border(noBorders).colSpan(10).end,
      //   ...obtenerNulls(9)
      // ],
      [
        new Cell(
          new Txt('SEÑOR(ES)').bold().fontSize(7).end
        ).border(topLeftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.cli_nomcli).fontSize(7).end
        ).border(topBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('ESTADO').bold().fontSize(7).end
        ).border(topBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.ocm_aprcom == 1 ? 'APROBADO' : 'NO APROBADO').bold().fontSize(7).end
        ).border(topRightBorder).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('RUC').bold().fontSize(7).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.cli_codcli).fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('REF. COTIZACIÓN').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').bold().fontSize(7).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('DIRECCIÓN').bold().fontSize(7).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.cli_dircli).fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('FECHA').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(formatDateWithSlash(data.ocm_fecocm)).fontSize(7).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('TELEFONO').bold().fontSize(7).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.suc_numtlf).fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').bold().fontSize(7).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('E-MAIL ').bold().fontSize(7).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.cli_corele).fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').bold().fontSize(7).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('CONTACTO').bold().fontSize(7).end
        ).border(bottomLeftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.cli_desref).fontSize(7).end
        ).border(bottomBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('').bold().fontSize(7).end
        ).border(bottomBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').bold().fontSize(7).end
        ).border(bottomRightBorder).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('Item').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Código').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Descripción Producto/Servicio').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Und.').fontSize(8).bold().end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Cant.').fontSize(8).bold().alignment('right').end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt(`P. Unit. ${headers.moneda == 'SO' ? 'S/.' : 'USD'}`).fontSize(8).bold().alignment('right').end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('Desc %').fontSize(8).bold().alignment('right').end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt(`Total ${headers.moneda == 'SO' ? 'S/.' : 'USD'}`).fontSize(8).bold().alignment('right').end,
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
      ],
      ...detalle,
      [
        new Cell(
          new Txt('').end
        ).border(openRightBorder).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Stack([
            new Txt('Descuento:').fontSize(8).alignment('right').bold().end,
            new Txt('Valor Venta:').fontSize(8).alignment('right').bold().end,
            new Txt('Incluído IGV:').fontSize(8).alignment('right').bold().end,
          ]).end
        ).border(horizontalBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('0.00').fontSize(7).alignment('right').end,
            new Txt(numberWithCommas(data.ocm_valvta.toFixed(2))).fontSize(7).alignment('right').end,
            new Txt(numberWithCommas((data.ocm_valvta + data.ocm_impigv).toFixed(2))).fontSize(7).alignment('right').end,
          ]).end
        ).colSpan(2).border(openLeftBorder).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Son: ${NumeroALetras(data.ocm_imptot)}`).fontSize(8).end
        ).border(openRightBorder).colSpan(5).end,
        ...obtenerNulls(4),
        new Cell(
          new Txt('Total S/.').fontSize(8).alignment('right').alignment('right').bold().end,
        ).border(horizontalBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(data.ocm_imptot.toFixed(2))).fontSize(7).alignment('right').end,
        ).border(openLeftBorder).colSpan(2).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt('Forma de Pago:').fontSize(8).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.cpa_descri).fontSize(8).end
        ).border(rightBorder).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      [
        new Cell(
          new Txt('Fecha de Entrega:').fontSize(8).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(formatDateWithSlash(data.ocm_fecent)).fontSize(8).end
        ).border(rightBorder).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      [
        new Cell(
          new Txt('Lugar de Engrega:').fontSize(8).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.ldc_dirldc).fontSize(8).end
        ).border(rightBorder).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      [
        new Cell(
          new Txt('Facturar a:').fontSize(8).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.CIA_NOMCIA).fontSize(8).end
        ).border(rightBorder).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      [
        new Cell(
          new Txt('Solicitante:').fontSize(8).end
        ).border(leftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.s10_nomusu).fontSize(8).end
        ).border(rightBorder).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      [
        new Cell(
          new Txt('Observaciones:').bold().decoration('underline').fontSize(8).end
        ).border(bottomLeftBorder).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.ocm_gloocm).fontSize(8).end
        ).border(bottomRightBorder).colSpan(7).end,
        ...obtenerNulls(6),
      ],
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([30, 30, 30, 200, 20, 30, 40, 30, 55]).end
  )

  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end,
        new Cell(
          new Txt('\n\n\n\n').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end
      ],
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('').alignment('center').fontSize(8).end,
            new Txt('').alignment('center').fontSize(7).bold().end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end,
        new Cell(
          new Stack([
            new Txt('JEFE DE LOGÍSTICA').alignment('center').fontSize(8).end,
            new Txt('V B').alignment('center').fontSize(7).bold().end
          ]).end
        ).border(topBorder).end,
        new Cell(
          new Txt('').end
        ).border([false, false, false, false]).end
      ],
      [
        new Cell(
          new Txt('\n\n').end
        ).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4)
      ],
      // [
      //   new Cell(
      //     new Stack([
      //       new Txt('1. El proveedor deberá adjuntar a la mercadería entregada una copia de la orden de compra, factura y guía de remisión y entregarlo en almacén de OS.').fontSize(8).bold().end,
      //       new Txt('2. Para productos químicos, el proveedor deberá adjuntar el MSDS del material entregado.').fontSize(8).bold().end,
      //       new Txt('3. El material atendido en el almacén de OS deberá estar adecuadamente embalado.').fontSize(8).bold().end,
      //     ]).end
      //   ).colSpan(5).end,
      //   ...obtenerNulls(4)
      // ]
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([90, 145, 40, 145, 90]).end
  )

  pdf.create().open();

}
