import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GuiaRemision } from "src/app/models/almacen/guia-remision";
import { ConfigurationService } from "src/app/services/configuration.service";
import { IParam, generarParams } from "../../utilities/params";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class GuiaRemisionService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   * Listado de guias
   * @param param0 filtro 0 para el listado de guía
   * @returns
   */
  obtenerGuias({ params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/guia/listar/${this._configurationService.obtenerCompaniaCliente()}/GRE${paramsStr}`
    ).pipe(
      map((guiaTexto: any) => {
        return guiaTexto.map(guiaTexto => {
          guiaTexto['grc_cordoc'] = `${guiaTexto.grc_numdoc.substring(4)}`
          return guiaTexto;
        })
      })
    );
  }

  obtenerReporteListadoGuias({ params = [] }: { params?: Array<IParam> } = {}): Observable<any> {
    const paramsStr = generarParams(params);
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/guia/reporte/${this._configurationService.obtenerCompaniaCliente()}/GRE${paramsStr}`
    ).pipe(
      map((guiaTexto: any) => {
        return guiaTexto.map(guiaTexto => {
          guiaTexto['grc_cordoc'] = `${guiaTexto.grc_numdoc.substring(4)}`
          return guiaTexto;
        })
      })
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  mostrarGuia(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/guia/mostrar/${this._configurationService.obtenerCompaniaCliente()}/GRE/${cod}`
    );
  }

  /**
   *
   * @param maquina
   * @returns
   */
  registrarGuia(guiaTexto: GuiaRemision): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/guia/crear`,
      guiaTexto
    );
  }

  /**
   *
   * @param maquina
   * @returns
   */
  actualizarGuia(guiaTexto: GuiaRemision): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/guia/modificar`,
      guiaTexto
    );
  }

  obtenerCorrelativoGuiaDoc(tipoDoc: string, serie: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/guia/correlativo/${this._configurationService.obtenerCompaniaCliente()}/${tipoDoc}/${serie}`
    );
  }

}
