import { SelectionModel } from "@angular/cdk/collections";
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { fillTable } from "@utils/tables/table";
import { Proveedor } from "src/app/models";
import { ProveedorDialog } from "../../../maestros/proveedor";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ProveedorService } from "src/app/services/api/compras/maestros/proveedor.service";
import { ProveedorMapper } from "src/app/mappers/proveedor.mapper";
import { SnackBarService } from "@shared/services/snackbar.service";
import { ConfigurationService } from "src/app/services";
import { unsubscribeSubscription } from "@utils/others/subscription";

@Component({
  selector: 'registro-compra-proveedor-no-registrado',
  templateUrl: './registro-compra-proveedor-no-registrado.component.html',
  styleUrls: ['./registro-compra-proveedor-no-registrado.component.scss']
})
export class RegistroCompraProveedorNoRegistrado implements OnInit, OnDestroy, OnChanges {

  loaderRegistroProveedor: boolean;

  displayedColumnsProveedores: string[] = ['acciones', 'razon', 'ruc', 'comercial', 'condicion', 'estado', 'fecha_insc', 'direccion', 'telefono', 'ubicacion', 'observaciones'];
  dataSourceProveedores: MatTableDataSource<Proveedor>;

  @ViewChild(MatPaginator) paginatorProveedores: MatPaginator;
  @ViewChild(MatSort) sortProveedores: MatSort;

  selection = new SelectionModel<any>(true, []);

  @Input() proveedores: Array<any> = [];

  obtenerDatosProveedores$: Subscription;
  registrarProveedor$: Subscription;
  actualizarProveedor$: Subscription;

  constructor(
    public dialog: MatDialog,
    private _proveedorService: ProveedorService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService
  ) {
    this.dataSourceProveedores = fillTable(
      this.proveedores
      , this.paginatorProveedores, this.sortProveedores)
  }

  ngOnChanges(changes: SimpleChanges): void {

    console.log('NUEVOS CAMBIOS EN PROVEEDORES NO EXISTENTES', changes)
    if (this.proveedores.length > 0) {
      console.log('proveedores no existentes', this.proveedores)
      const datasource = this.proveedores.map(item => {

        item.proveedor['cli_codcli'] = item.cli_codcli;
        item.proveedor['cli_indpro'] = item.cli_indpro;
        item.proveedor['existeProveedor'] = item.existeProveedor;
        item.proveedor['existe'] = item.existe;
        return item.proveedor;

      })

      this.dataSourceProveedores = fillTable(datasource, this.paginatorProveedores, this.sortProveedores)

    }

  }

  ngOnInit(): void {

  }

  editarDatos(row): void {
    const dialogRef = this.dialog.open(ProveedorDialog, {
      width: 'auto',
      data: {
        id: '0',
        idARegistrar: row.ruc
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  agregarProveedor(): void {
    const dialogRef = this.dialog.open(ProveedorDialog, {
      width: 'auto',
      data: {
        id: '0',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  confirmarRegistroProveedor(proveedorSUNAT): void {

    console.log(proveedorSUNAT)

    this.loaderRegistroProveedor = true;

    if (proveedorSUNAT.existe == "No") {

      const proveedor = ProveedorMapper.map(proveedorSUNAT)
      proveedor.cli_coduse = this._configurationService.obtenerIdUsuario();
      proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();

      this.registrarProveedor$ = this._proveedorService.registrarProveedor(proveedor).subscribe(
        proveedor => {
          this._snackBarService.showSuccess('Proveedor registrado exitosamente', 'OK');
          this.loaderRegistroProveedor = false;
        },
        error => {
          this._snackBarService.showError('Error al registrar proveedor', 'OK');
          this.loaderRegistroProveedor = false;
        }
      )

    } else {

      const payload = {
        cia_codcia: this._configurationService.obtenerCompaniaCliente(),
        cli_codcli: proveedorSUNAT.cli_codcli,
        cli_indpro: 1
      }

      this.actualizarProveedor$ = this._proveedorService.editarProveedor(payload).subscribe(
        proveedor => {
          this._snackBarService.showSuccess('Proveedor registrado exitosamente', 'OK');
          this.loaderRegistroProveedor = false;
        },
        error => {
          this._snackBarService.showError('Error al registrar proveedor', 'OK');
          this.loaderRegistroProveedor = false;
        }
      )
    }

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceProveedores.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceProveedores.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.obtenerDatosProveedores$,
      this.registrarProveedor$,
      this.actualizarProveedor$,
    ])
  }
}
