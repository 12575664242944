import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UbicacionActivoFijo } from "src/app/models/activo-fijo";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class UbicacionActivoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarUbicacionesActivo(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/activofijo/ubicacion-activo/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerUbicacionActivo(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/activofijo/ubicacion-activo/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param ubicacion
   * @returns
   */
  registrarUbicacionActivo(ubicacion: UbicacionActivoFijo): Observable<any> {
    return null;
  }

  /**
   *
   * @param ubicacion
   * @returns
   */
  actualizarUbicacionActivo(ubicacion: UbicacionActivoFijo): Observable<any> {
    return null;
  }
}
