import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { forkObs } from '@utils/observables/fork';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { InteresBancoCTS } from 'src/app/models/planilla/interes-banco-cts';
import { AuthenticationService, GeneralService, NominaService } from 'src/app/services';

@Component({
  selector: 'app-interes-banco-cts',
  templateUrl: './interes-banco-cts.component.html',
  styleUrls: ['./interes-banco-cts.component.css'],
})
export class InteresBancoCtsComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  monedas: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['actions', 'codban', 'codtmo', 'porint'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  bancos$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  monedas$: Subscription;
  detalleInteres$: Subscription;
  sendForm$: Subscription;

  periodo: string;

  intereses: Array<InteresBancoCTS> = [];

  bancos: any[] = [];

  year: string;
  month: string;

  constructor(
    private _generalService: GeneralService,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
    private _nominaService: NominaService
  ) {

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.periodo = `${state.year}-${state.month}`;
      this.year = state.year;
      this.month = state.month;
    })

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  /**
   * Obtiene los maestros de
   * . detalle interes CTS
   * . bancos
   * . monedas
   */
  loadData(): void {

    forkObs(
      this._nominaService.obtenerDetalleInteresCTS(this.year, this.month),
      this._generalService.listarBancos(),
      this._generalService.obtenerMonedas()
    ).then(data => {

      this.intereses = data[0];
      this.intereses.forEach((item, key) => {
        item['isEditing'] = false;
        item['correlativo'] = key;
      })
      this.dataSource = fillTable(this.intereses, this.paginator, this.sort);


      this.bancos = data[1];
      this.monedas = data[2];
    }).catch(err => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })

  }

  registrarInteresBancoCTS(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._nominaService.registrarInteresBancoCTS(this.year, this.month, this.intereses).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Interes banco CTS registrado', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = true;
      }
    )
  }

  seleccionarBanco(event: MatSelectChange, row) {
    let existe = false;
    this.intereses.forEach(item => {
      if(item.isEditing == false)
        if(row.codban == item.codban) existe = true;
    });
    if(existe) {
      this._snackBarService.showError('Este banco ya existe en la tabla', 'Ok');
      event.source.value = "";
      return;
    }

    const banco = this.bancos.find(item => item.BAN_CODBAN == row.codban);

    row.nombic = banco.BAN_NOMBRE;
  }

  agregarItem(): void {

    const item = new InteresBancoCTS();

    this.intereses.push(item);

    this.intereses.forEach((element, key) => {
      element['correlativo'] = key;
    })
    this.dataSource = fillTable(this.intereses, this.paginator, this.sort);

  }

  editarItem(row): void {

    row.isEditing = !row.isEditing;

  }

  confirmarItem(row: InteresBancoCTS): void {
    if(!row.codban || !row.codtmo || !row.porint) {
      this._snackBarService.showError('Todos los campos de fila deben estar compeltos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.intereses.filter(item => item.correlativo != row.correlativo);
        this.intereses = filtered;
        this.intereses.forEach((element, key) => {
          element['correlativo'] = key;
        })
        this.dataSource = fillTable(this.intereses, this.paginator, this.sort);
      }
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.bancos$, this.loading$, this.periodo$, this.monedas$, this.detalleInteres$])
  }

}
