import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { RegistroCompraService } from "src/app/services/api/compras/operaciones/registro-compra.service";
import { SunatService } from "src/app/services/api/sunat/sunat.service";
import { DocumentToUpload } from "../../registro-compra-form/registro-compra-form.component";

@Component({
  selector: 'registro-compra-upload-invoice.dialog',
  templateUrl: './registro-compra-upload-invoice.dialog.html',
  styleUrls: ['./registro-compra-upload-invoice.dialog.scss']
})
export class RegistroCompraUploadInvoiceDialog implements OnDestroy {

  files: File[] = [];

  loader: boolean;

  datosFactura$: Subscription;
  datosRuc$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<RegistroCompraUploadInvoiceDialog>,
    @Inject(MAT_DIALOG_DATA) public documentToUpload: DocumentToUpload,
    private _registroCompraService: RegistroCompraService,
    private _snackBarService: SnackBarService,
    private _sunatService: SunatService
  ) {
  }
  ngOnDestroy(): void {
    unsubscribeSubscription([this.datosFactura$, this.datosRuc$]);
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  cancelar(): void {
    this.dialogRef.close(this.documentToUpload);
  }

  confirmar(): void {
    this.loader = true;

    const formData = new FormData();

    formData.append("document", this.files[0], this.files[0].name);

    this.datosFactura$ = this._registroCompraService.obtenerDatosFactura(formData).subscribe(
      response => {
        console.log('Resultado pavso ia', response)
        console.log('data ia', response.fields)

        this.loader = false;
        response.fields['file'] = this.files[0];
        this.documentToUpload.invoiceJSON = response.fields;
        this.documentToUpload.invoiceFile = this.files[0];
        this.dialogRef.close(this.documentToUpload);

        if(response.fields.VendorTaxId) {
          // this.datosRuc$ = this._sunatService.obtenerDatosDesdeRuc(response.fields.VendorTaxId.value).subscribe(
          //   datos => {
          //     console.log('datos ruc', datos)
          // })
        }

      },
      error => {
        this.loader = false;
        this._snackBarService.showError('Error al subir la imagen', 'Ok');
      }
    )
  }

}

class UploadInvoice {
  document: any;

  constructor() {
    // this.base64 = [];
  }
}
