<h1 mat-dialog-title>Operación a extornar</h1>
<form #f="ngForm" ngNativeValidate (ngSubmit)="obtenerOperaciones(f)" (keydown.enter)="$event.preventDefault()">

  <div mat-dialog-content>
    <div class="row" [ngStyle]="{marginTop: '10px'}">
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Periodo</mat-label>
          <input matInput [(ngModel)]="filtro.periodo" name="periodo" required>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <select-search [label]="'Subdiario'" [placeholder]="'Ej. TORRES'" [name]="'sco_codsco'" [value]="'sco_codsco'"
          [description]="'description'" [data]="data.subdiarios"
          (cambioSelect)="filtro.subdiario = $event;" [model]="filtro.subdiario" [required]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Voucher</mat-label>
          <input matInput [(ngModel)]="filtro.voucher" name="voucher" required>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div mat-dialog-actions>
    <button type="submit" mat-button cdkFocusInitial>Confirmar</button>
  </div>

</form>
