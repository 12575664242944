import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationReturnService {
  constructor(public dialog: MatDialog) {}

  confirm(message: string): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '350px',
      data: 'Usted perderá sus cambios al volver a la página anterior. ¿Desea salir del formulario?',
    });

    return dialogRef.afterClosed().toPromise();
  }
}
