import { Component, Inject, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { AlmacenService } from "src/app/services";

class DocumentoADescargar {
  ruc: string;
  tipo: string;
  codofi: string;
  serie: string;
  numeroDoc: string;
}

@Component({
  selector: 'modal-agregar-documento',
  templateUrl: './modal-agregar-documento.dialog.html',
  styleUrls: ['./modal-agregar-documento.dialog.scss']
})
export class ModalAgregarDocumentoDialog implements OnDestroy {

  loaderData: boolean;
  documento: DocumentoADescargar;
  tiposDoc: Array<any> = [];

  serie$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ModalAgregarDocumentoDialog>,
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.documento = new DocumentoADescargar();
    this.tiposDoc = data;
  }

  addDocumento(f: NgForm): void {
    if (!this.documento.ruc) {
      this._snackBarService.showError('Establecer número de RUC', 'Ok')
      return;
    }

    if (!this.documento.tipo) {
      this._snackBarService.showError('Seleccione un tipo de documento', 'Ok')
      return;
    }

    if (!this.documento.serie) {
      this._snackBarService.showError('Establecer una serie', 'Ok')
      return;
    }

    if (!this.documento.numeroDoc) {
      this._snackBarService.showError('Establecer número de documento', 'Ok')
      return;
    }

    if (!this.documento.serie.includes('F')) {
      this._snackBarService.showError('Ingrese un número de serie válido que inicie con la letra F', 'Ok')
      return;
    }

    this.dialogRef.close(this.documento);
  }

  seleccionarTipoDoc(): void {
    const tipoDocumento = this.tiposDoc.find(item => item.TDO_CODTDO == this.documento.tipo);
    this.documento.codofi = tipoDocumento.TDO_CODOFI;

    this.serie$ = this._almacenService.listarSerieDocumentos(this.documento.tipo).subscribe(
      series => {
        console.log('series', series)
        this.documento.serie = series[1].sdo_codsdo;
      },
      error => {
        this._snackBarService.showError('Error al obtener series', "OK")

      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.serie$
    ])
  }
}
