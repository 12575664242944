export class ProductoCompuesto {
  tipoInventario: string;
  linea: string;
  familia: string;
  subfamilia: string;
  marca: string;
  modelo: string;
  medida: string;
  tecnologia: string;
  unidad: string;
  producto: string;
  proveedor: string;
  otraPresentacion: boolean;
  controlPorLote: boolean;
  caracteristicasAdicionales: Array<CaracteristicaAdicional>;
  conversiones: Array<Conversion>;
  ubicaciones: Array<Ubicacion>;
  imagenes: Array<any>;

  constructor() {
    this.caracteristicasAdicionales = [];
    this.conversiones = [];
    this.ubicaciones = [];
    this.imagenes = [];
  }
}

export class CaracteristicaAdicional {
  sec: any;
  orden: any;
  descripcion: any;
}

export class Conversion {
  codigo: any;
  unidad: any;
  descripcion: any;
  cantidadEquivalente: any;
  codigoAlmacen: any;
}

export class Ubicacion {
  almacen: any;
  descripcionAlmacen: any;
  ubicacion: any;
  descripcionUbicacion: any;
}
