import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { DetallePrecio, ListaPrecioCliente } from "src/app/models/ventas/lista-precio-cliente";
import { ConfigurationService } from "src/app/services";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";
import { ListaPrecioClienteService } from "src/app/services/api/ventas/precio-descuento-promociones/lista-precio-cliente.service";

@Component({
  selector: 'lista-precio-cliente-form',
  templateUrl: './lista-precio-cliente-form.component.html',
  styleUrls: ['./lista-precio-cliente-form.component.scss']
})
export class ListaPrecioClienteFormComponent implements OnInit, OnDestroy {

  listaPrecioCliente: ListaPrecioCliente;
  uid: string;
  correlativo: string;

  displayedColumnsPrecios: string[] = ['acciones', 'PRD_CODPRD', 'prd_desesp', 'ume_codume', 'PCL_PRENAC', 'PCL_VTANAC', 'PCL_PREDOL', 'PCL_VTADOL', 'PCL_INDSTA'];
  dataSourcePrecios: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorPrecios: MatPaginator;
  @ViewChild(MatSort) sortPrecios: MatSort;

  loaderData: boolean;

  sendForm$: Subscription;

  loaderReg: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  btnName: string = 'general.button.store';

  loading$: Subscription;
  clientes$: Subscription;
  productos$: Subscription;
  listaPrecio$: Subscription;

  clientes: any[] = [];
  productos: any[] = [];

  constructor(
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _listaPrecioClienteService: ListaPrecioClienteService,
    private _clienteService: ClienteService,
    private _productoService: ProductoService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _router: Router
  ) {
    this.listaPrecioCliente = new ListaPrecioCliente();
    this.listaPrecioCliente.PCE_CODUSE = this._configurationService.obtenerIdUsuario();
    this.listaPrecioCliente.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

    this.dataSourcePrecios = fillTable([], this.paginatorPrecios, this.sortPrecios);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({id, correlativo}) => {
      this.uid = id;
      this.listaPrecioCliente.CLI_CODCLI = this.uid;
      this.correlativo = correlativo
    })

    this.loadMaestro();

    this.btnName = this.uid == '0' ? 'general.button.store':'general.button.update';

  }

  loadMaestro(): void {

    forkObs(
      this._clienteService.obtenerClientes(),
      this._productoService.obtenerProductos(),
    ).then(data => {
      this.clientes = data[0];
      this.productos = data[1];

      if(this.uid != '0') {
        this.obtenerListaPrecioCliente()
        return;
      };

      this.loaderData = false;

    }).catch(err => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
      this.loaderData = true;
    })

  }

  obtenerListaPrecioCliente(): void {
    this.listaPrecio$ = this._listaPrecioClienteService.obtenerListaPrecioCliente(this.uid, this.correlativo).subscribe(
      listaPrecioCliente => {
        console.log('listaPrecioCliente', listaPrecioCliente);
        this.listaPrecioCliente = listaPrecioCliente;
        this.dataSourcePrecios = fillTable(this.listaPrecioCliente.detalle_precio, this.paginatorPrecios, this.sortPrecios);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener banco', 'Ok');
        this.loaderData = false;
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    console.log('lista precio form', this.listaPrecioCliente)
    this.uid == '0' ? this.registrarListaPrecioCliente(f): this.editarListaPrecioCliente(f);

  }

  registrarListaPrecioCliente(f: NgForm): void {
    this.sendForm$ = this._listaPrecioClienteService.registrarListaPrecioCliente(this.listaPrecioCliente).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Lista de Precio Registrado', '350px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al registrar lista de precio cliente', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  editarListaPrecioCliente(f: NgForm): void {
    this.sendForm$ = this._listaPrecioClienteService.editarListaPrecioCliente(this.listaPrecioCliente).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Lista de Precio Actualizado', '350px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al registrar lista de precio cliente', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  agregarPrecio(): void {

    const precio = new DetallePrecio();
    precio.PCL_CODUSE = this._configurationService.obtenerIdUsuario();

    this.listaPrecioCliente.detalle_precio.unshift(precio);

    this.dataSourcePrecios = fillTable(this.listaPrecioCliente.detalle_precio, this.paginatorPrecios, this.sortPrecios);

  }

  quitarPrecio(row): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const listaPrecios = this.listaPrecioCliente.detalle_precio.filter(item => item.PRD_CODPRD != row.PRD_CODPRD);

        this.listaPrecioCliente.detalle_precio = listaPrecios;

        this.dataSourcePrecios = fillTable(this.listaPrecioCliente.detalle_precio, this.paginatorPrecios, this.sortPrecios);
      }
    })

  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/lista-de-precio-cliente'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$, this.sendForm$
    ])
  }

}
