<app-pavso-title-section [title]="'Canje por letra'" [module]="'Cuentas por cobrar'"
  [estado]="canjeLetra.caja_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarCanjeLetra(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Período</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">

          <select-search [label]="'Subdiario'" [placeholder]="'Ej. 401112'" [name]="'sco_codsco'" [value]="'sco_codsco'"
            [description]="'sco_descri'" [disabled]="loaderData" [data]="subdiarios"
            (cambioSelect)="canjeLetra.sco_codsco = $event" [model]="canjeLetra.sco_codsco">
          </select-search>

        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Voucher</mat-label>
            <input type="text" matInput placeholder="Voucher" name="voucher" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="fecha" name="fecha" [(ngModel)]="canjeLetra.fecha">
            <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
            <mat-datepicker #fecha></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>T.C.</mat-label>
            <input type="number" appTipoCambioFormat step="any" min="0" matInput placeholder="T.C." name="tc" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <pav-input-search label="Cliente" placeholder="ej. Plaza Vea" [isResponsive]="true"
            (valueChange)="cliente = $event;" [codigo]="canjeLetra.cli_codcli"
            [descripcion]="canjeLetra.cli_nomcli" width="'100%'" [program]="'CLIENTES'"></pav-input-search>

        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nro. Letras</mat-label>
            <input type="number" min="0" matInput placeholder="T.C." name="tc" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <app-currency-select [moneda]="'SO'" [disable]="loaderData"></app-currency-select>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Valor</mat-label>
            <input type="number" min="0" matInput placeholder="Valor" name="valor" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <select-search [label]="'Vendedor'" [placeholder]="'Ej. 401112'" [name]="'vde_codvde'" [value]="'vde_codvde'"
            [description]="'vde_descri'" [disabled]="loaderData" [data]="vendedores" [required]="true"
            (cambioSelect)="canjeLetra.vde_codvde = $event" [model]="canjeLetra.vde_codvde">
          </select-search>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Glosa</mat-label>
            <textarea matInput placeholder="Glosa"></textarea>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Importe Nacional</mat-label>
            <input type="number" min="0" matInput placeholder="Importe Nacional" name="importe_nacional" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Importe Dólares</mat-label>
            <input type="number" min="0" matInput placeholder="Importe Dólares" name="importe_dolares" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
