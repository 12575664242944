<h1 mat-dialog-title>Resumen texto</h1>
<div mat-dialog-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="cli_nomcli">
      <th mat-header-cell *matHeaderCellDef> Proveedor </th>
      <td mat-cell *matCellDef="let element"> {{element.cli_nomcli}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="tdo_codtdo">
      <th mat-header-cell *matHeaderCellDef> Tipo Doc. </th>
      <td mat-cell *matCellDef="let element"> {{element.tdo_codtdo}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="cco_numdoc">
      <th mat-header-cell *matHeaderCellDef> N. Doc. </th>
      <td mat-cell *matCellDef="let element"> {{element.cco_numdoc}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="CCO_FECEMIFormat">
      <th mat-header-cell *matHeaderCellDef> Fec. Emi. </th>
      <td mat-cell *matCellDef="let element"> {{element.CCO_FECEMIFormat}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="cco_fecvenformat">
      <th mat-header-cell *matHeaderCellDef> Fec. Venc. </th>
      <td mat-cell *matCellDef="let element"> {{element.cco_fecvenformat}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="cco_salnet">
      <th mat-header-cell *matHeaderCellDef> Saldo </th>
      <td mat-cell *matCellDef="let element"> {{element.cco_salnet}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>

<div mat-dialog-actions [align]="'center'">
  <button mat-flat-button (click)="copiar()">
    <mat-icon>content_copy</mat-icon>
    Copiar
  </button>
</div>
