import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";

@Component({
  selector: 'giro-negocio-list',
  templateUrl: './giro-negocio-list.component.html',
  styleUrls: ['./giro-negocio-list.component.scss']
})
export class GiroNegocioListComponent implements OnInit, OnDestroy {
  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'gno_codgno', 'gno_desesp', 'gno_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  giroNegocio$: Subscription;
  loading$: Subscription;

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerGirosNegocio();
    })
  }

  obtenerGirosNegocio(): void {
    this.loaderData = true;
    this.giroNegocio$=this._ventasService.obtenerGirosNegocio().subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator,this.sort)
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderData = false;
      }

    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.giroNegocio$,
      this.loading$
    ])
  }

}
