import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { GrupoActividad } from "src/app/models/crm/grupo-actividad";
import { ConfigurationService } from "src/app/services";
import { GrupoActividadService } from "src/app/services/api/crm/maestros";

@Component({
  selector: 'grupo-actividad-form',
  templateUrl: './grupo-actividad-form.component.html',
  styleUrls: ['./grupo-actividad-form.component.scss']
})
export class GrupoActividadFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  sendForm$: Subscription;
  loading$: Subscription;
  grupoActividad$: Subscription;

  grupoActividad: GrupoActividad;

  constructor(
    private store: Store<PavsoState>,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _grupoActividadService: GrupoActividadService,
  ) {
    this.grupoActividad = new GrupoActividad();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;

      this.obtenerGrupoActividad()
    })
  }

  obtenerGrupoActividad(): void {

    this.grupoActividad$ = this._grupoActividadService.obtenerGrupoActividad(this.uid).subscribe(
      grupoActividad => this.grupoActividad = grupoActividad[0],
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;

    this.uid == '0' ? this.registrarGrupoActividad(f): this.actualizarGrupoActividad(f)
  }

  /**
   * Método para registrar un nuevo grupo de actividad
   * @param f
   */
  registrarGrupoActividad(f: NgForm): void {
    this.grupoActividad.coduse = this._configurationService.obtenerIdUsuario();

    this.sendForm$ = this._grupoActividadService.registrarGrupoActividad(this.grupoActividad).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.grupo-actividad.success-store', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para actualizar los datos de un grupo de actividad existente
   * @param f
   */
  actualizarGrupoActividad(f: NgForm): void {
    this.sendForm$ = this._grupoActividadService.actualizarGrupoActividad(this.grupoActividad).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.grupo-actividad.success-update', '400px', 'auto', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {
    if(!this.grupoActividad.indsta) {

      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;

    }

    return true;
  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/grupos-de-actividad']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.loading$,
      this.grupoActividad$,
    ])
  }

}
