import { PavsoColors } from "@data/enums/color.enum";
import { bottomBorder, horizontalBorder, leftBorder, noBorders, rightBorder, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, Img, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";
import { Kardex } from "src/app/models";

export async function generarFormatoParte(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [10, 10, 20, 20], color: 'gray', bold: true },
    ]
  });

  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.coduse}`, color: 'gray', fontSize: 10, bold: true, margin: [5, 2, 10, 10], alignment: 'right' },
    ];
  })

  let detalle = [];

  if (data.detalle_map) {
    data.detalle_map.forEach(element => {
      detalle.push([
        new Cell(
          new Txt(element.prd_codprd).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element.producto.prd_desesp} ${element.map_glomap && ('\n' + element.map_glomap)}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.producto.prd_parnum).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.producto.ume_codume).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.map_canprd.toString()).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element.map_canven ? element.map_canven : ''} ${element.unidadventa.ume_descri ? element.unidadventa.ume_descri : ''}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.ccs_codccs? element.ccs_codccs: '').fontSize(7).end
        ).border(noBorders).end,
      ])
    })
  }

  pdf.add(
    new Table([
      [
        new Cell(
          await new Img(headers.logo).alignment('left').width(80).build(),
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('\n').end,
            new Txt(headers.nomcia).alignment('center').fontSize(10).bold().end,
            new Txt(`RUC: ${headers.ruc}`).alignment('center').fontSize(8).end,
            new Txt(headers.dircia).alignment('center').fontSize(8).end,
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Table(
            [
              [
                new Cell(
                  new Txt(`PARTE DE ${data.tdo_codtdo == 'ING' ? 'INGRESO' : 'SALIDA'}`).fontSize(9).bold().end,
                ).margin(4).alignment('center').end
              ],
              [
                new Cell(
                  new Txt(`${data.almacen.ALM_DESCRI}`).fontSize(9).bold().end,
                ).margin(4).alignment('center').fillColor(PavsoColors.Gray).color(PavsoColors.Black).end
              ],
              [
                new Cell(
                  new Txt(`N° ${data.ano_codano}${data.mes_codmes}-${data.alm_codalm}-${data.mac_nummac}`).fontSize(9).end,
                ).margin(4).alignment('center').end
              ]
            ]
          ).widths([150]).layout({
            hLineColor: (i, node) => {
              return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
            },
            vLineColor: (i, node) => {
              return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
            },
            hLineWidth: (i, node) => {
              return 1;
            },
            vLineWidth: (i, node) => {
              return 1;
            }
          }).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2)
      ],
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6)
      ],
      [
        new Cell(
          new Txt(``).alignment('right').fontSize(9).bold().end
        ).border(bottomBorder).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(``).fontSize(9).bold().end
        ).border(bottomBorder).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        new Cell(
          new Txt(`Fecha`).fontSize(8).bold().end
        ).border(leftBorder).end,
        new Cell(
          new Txt(`: ${data.mac_fecmac}`).fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`Referencia 1`).fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${data.mac_tdore1 ? data.mac_tdore1: ''} - ${data.mac_numre1 ? data.mac_numre1: ''}`).fontSize(7).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt(`T. Movimiento`).fontSize(8).bold().end
        ).border(leftBorder).end,
        new Cell(
          new Txt(`: ${data.motivo.mmo_descri}`).fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(`Referencia 2`).fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: ${data.mac_tdore2 ? data.mac_tdore2 : ''}-${data.mac_numre2 ? data.mac_numre2: ''}`).fontSize(7).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt(`Cliente`).fontSize(8).bold().end
        ).border(leftBorder).end,
        new Cell(
          new Txt(`: ${data.cliente.cli_nomcli}`).fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(headers.indPedido == 1? `Pedido`:'').fontSize(8).bold().end,
            new Txt(headers.indOrdCompra ? `Orden de compra:` : '').fontSize(8).bold().end,
            new Txt(headers.indRegInvoice ? `Registro de invoice:` : '').fontSize(8).bold().end,
            new Txt(data.ccs_codccs? 'Centro de costo': '').fontSize(8).bold().end,
            new Txt(headers.indReqMat ? `Requerimiento:` : '').fontSize(8).bold().end,
            new Txt(headers.indOrdProd ? `Orden de producción:` : '').fontSize(8).bold().end,
            new Txt(headers.indOrdProd ? `Reporte de producción:` : '').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(headers.indPedido == 1? data.pcc_numpcc: '').fontSize(7).end,
            new Txt(headers.indOrdCompra == 1? data.ocm_numocm: '').fontSize(7).end,
            new Txt(headers.indRegInvoice == 1? data.oim_numoim: '').fontSize(7).end,
            new Txt(data.ccs_codccs).fontSize(7).end,
            new Txt(headers.indReqMat == 1? data.rma_numrma: '').fontSize(7).end,
            new Txt(headers.indOrdProd == 1? data.otr_numotr: '').fontSize(7).end,
            new Txt(headers.indOrdProd == 1? data.otr_numotr: '').fontSize(7).end,
          ]).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt(`Observaciones`).fontSize(8).bold().end
        ).border(leftBorder).end,
        new Cell(
          new Txt(data.mac_glomac).fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt(headers.indOrdCompra ? `3242342`: '').fontSize(7).end
        ).border(rightBorder).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Código').fontSize(8).bold().end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).border(horizontalBorder).end,
        new Cell(
          new Txt('Descripción').fontSize(8).bold().end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).border(horizontalBorder).end,
        new Cell(
          new Txt('Part Number').fontSize(8).bold().end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).border(horizontalBorder).end,
        new Cell(
          new Txt('Unid.').fontSize(8).bold().end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).border(horizontalBorder).end,
        new Cell(
          new Txt('Cantidad').fontSize(8).bold().end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).border(horizontalBorder).end,
        new Cell(
          new Txt('Referencia').fontSize(8).bold().end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).border(horizontalBorder).end,
        new Cell(
          new Txt('OT').fontSize(8).bold().end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).border(horizontalBorder).end,
      ],
      ...detalle,
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([55, 205, 55, 35, 45, 55, 35]).end
  )

  pdf.add(
    pdf.ln(5)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('\n').alignment('center').fontSize(8).end,
                  new Txt('Responsable').alignment('center').fontSize(7).bold().end
                ]).end
              ).alignment('center').border(topBorder).end
            ]
          ]).layout({
            hLineColor: (i, node) => {
              return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
            },
            vLineColor: (i, node) => {
              return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
            },
            hLineWidth: (i, node) => {
              return 1;
            },
            vLineWidth: (i, node) => {
              return 1;
            }
          }).widths([140]).end
        ).alignment('center').margin(10).border(noBorders).end,


      ]
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#b9b9b9' : '#b9b9b9';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#b9b9b9' : '#b9b9b9';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([80, 155, 40, 70, 155]).end
  )

  pdf.create().open();

}

export async function generarFormatoParteIngreso(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount, pageSize) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', fontSize: 8, margin: [10, 10, 20, 20], color: 'gray', bold: true },
    ]
  });

  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [5, 2, 10, 10], alignment: 'right' },
    ];
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt('OLEOHIDRAULICS SERVICES S.A.C.').alignment('center').fontSize(10).bold().end,
            new Txt('RUC: 20522747298 CAL.CARLOS GUTIERREZ').alignment('center').fontSize(8).end,
            new Txt('NORIEGA NRO. 185 LA VICTORIA').alignment('center').fontSize(8).end,
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Hora de impresión: 09:38:42a.m.`).margin(2).fontSize(6).end,
            new Txt(`Fecha: 22/07/2023`).margin(2).fontSize(6).end,
            new Txt(`Usuario de Impresión: pavso`).margin(2).fontSize(6).end,

          ]).end
        ).colSpan(3).end,
        ...obtenerNulls(2)
      ],
      [
        new Cell(
          new Txt('\n\n').end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('VALE DE INGRESO N° : 202307-ING-00046').fontSize(9).bold().end
        ).border(bottomBorder).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('ALMACEN : 0001 - ALMACEN DE PRODUCTOS').alignment('right').fontSize(9).bold().end
        ).border(bottomBorder).colSpan(5).end,
        ...obtenerNulls(4),
      ],


      [
        new Cell(
          new Txt(`T. Movimiento`).fontSize(7).bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`: SALIDA POR VENTA NACIONAL`).fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Referencia 1`).fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: RMA-012765`).fontSize(7).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt(`Fecha`).fontSize(7).bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`: 03/07/2023`).fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Referencia 2`).fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: SSS - 298273`).fontSize(7).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt(`Proveedor`).fontSize(7).bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`: 03/07/2023`).fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Tipo Moneda OC`).fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(`: SSS - 298273`).fontSize(7).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt(`Nro. Orden`).fontSize(7).bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(``).fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt(`Observaciones`).fontSize(7).bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`SOLICITANTE: JONATHAN CORDOVA`).fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(``).fontSize(7).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(``).fontSize(7).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
      ],



      [
        new Cell(
          new Txt('Item').fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('Código').fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('Código Anterior').fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('Descripción').fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('Part Number').fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('Unid.').fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('Cant.').fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('P. Unitario').fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('P. Total').fontSize(8).bold().end
        ).border(horizontalBorder).end,
      ],
      [
        new Cell(
          new Txt('0001').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('1900812').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('VALVULA DE BOLA DE BAJA PRESION UND 1-1/2" NPT, 25 BAR, BV4L-2-N24-9242-F').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('6100078504').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('UND').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('4.000').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('0.00').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('162.20').fontSize(7).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('162.20').bold().fontSize(7).end
        ).border(noBorders).end,
      ],
    ]).widths([30, 50, 50, 140, 80, 30, 30, 30, 40]).end
  )

  pdf.create().open();

}
