import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { DashboardService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import ApexCharts from 'apexcharts';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatSidenav } from '@angular/material/sidenav';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { cambiar } from '../../../shared/state/actions/period.actions';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  tiles: Tile[] = [
    { text: 'One', cols: 3, rows: 1, color: 'lightblue' },
    { text: 'Two', cols: 1, rows: 2, color: 'lightgreen' },
    { text: 'Three', cols: 1, rows: 1, color: 'lightpink' },
    { text: 'Four', cols: 2, rows: 1, color: '#DDBDF1' },
  ];

  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  mouseenter(): void {
    if (!this.isExpanded) this.isShowing = true;

  }

  mouseleave(): void {
    if (!this.isExpanded) this.isShowing = false;

  }

  title: string = '';
  modulo: string = '';

  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptionsPedidos: any;
  public chartOptionsVentas: any;
  public chartOptionsComprobantes: any;

  countUsers: number = 0;
  countSystems: number = 0;
  countClients: number = 0;

  loaderPedidos: boolean = false;
  loaderVentas: boolean = false;
  loaderComprobantes: boolean = false;

  pedidosAtender: number = 0;
  pedidosAprobar: number = 0;
  pedidosPlazo: number = 0;

  cantidadVenta: number = 0;
  importeVenta: number = 0;
  devolucion: number = 0;

  loadGraficoVenta: boolean = false;
  loadGraficoVendedores: boolean = false;
  loadGraficoComprobante: boolean = false;

  vendedorSubscriber: Subscription;
  ventasSubscriber: Subscription;
  comprobantesSubscriber: Subscription;
  dashboardSubscriber: Subscription;

  isLoad = true;

  year: string;
  month: string;
  openingYear: string;
  openingMonth: string;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _dashboardService: DashboardService,
    private _snackBarService: SnackBarService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store
      .select('period')
      .subscribe(({ year, month, openingYear, openingMonth }) => {
        this.year = year;
        this.month = month;
        this.openingYear = openingYear;
        this.openingMonth = openingMonth;
      });

    this._activatedRoute.data.subscribe((data) => (this.title = data.titulo));
    this._activatedRoute.data.subscribe((data) => (this.modulo = data.modulo));

    this.chartOptionsPedidos = {
      series: [],
      chart: {
        height: 350,
        type: 'bar',
      },
      title: {
        text: '',
      },
      xaxis: {
        categories: [],
      },
    };
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.vendedorSubscriber,
      this.ventasSubscriber,
      this.comprobantesSubscriber,
      this.dashboardSubscriber,
      this.period$,
    ]);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe((state) => {
      if (!state.isLoadingCompany) this.isLoad = false;
      this._router.navigate(['/ingresar-parametros'])
    });
  }

  loadData(): void {
    this.loadGraficoComprobante = true;
    this.loadGraficoVendedores = true;
    this.loadGraficoVenta = true;

    const dataTest = {
      data: {
        data: [258, 451, 236, 123, 247],
        name: 'Cantidad por mes',
      },
      keys: ['Ene', 'Feb', 'Mar', 'Abr', 'May'],
    };

    this.vendedorSubscriber = this.vendedorSubscriber = this._dashboardService
      .graficoVendedores(this.year, this.month)
      .subscribe(
        (dataVendedor) => {
          var chartVendedores = new ApexCharts(
            document.querySelector('#chartVendedores'),
            this.chartOptionsPedidos
          );
          chartVendedores.render();
          chartVendedores.updateOptions({
            series: [dataTest.data],
            chart: {
              height: 350,
              type: 'bar',
              zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4,
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1,
                  },
                },
              },
            },
            colors: ['#6ae8a7', '#ee5e76'],
            title: {
              text: dataTest.data.name,
            },
            xaxis: {
              categories: dataTest.keys,
            },
          });

          this.loadGraficoVendedores = false;

          this.ventasSubscriber = this._dashboardService
            .graficoVentas(this.year)
            .subscribe(
              (dataVentas) => {
                var chartVentas = new ApexCharts(
                  document.querySelector('#chartVentas'),
                  this.chartOptionsPedidos
                );
                chartVentas.render();
                chartVentas.updateOptions({
                  series: [dataTest.data],
                  chart: {
                    height: 350,
                    type: 'bar',
                  },
                  colors: ['#00E396'],
                  title: {
                    text: dataTest.data.name,
                  },
                  xaxis: {
                    categories: dataTest.keys,
                  },
                });

                this.loadGraficoVenta = false;

                this.comprobantesSubscriber = this._dashboardService
                  .graficoComprobantes(this.year)
                  .subscribe(
                    (dataComprobante) => {
                      var chartComprobantes = new ApexCharts(
                        document.querySelector('#chartComprobantes'),
                        this.chartOptionsPedidos
                      );
                      chartComprobantes.render();
                      chartComprobantes.updateOptions({
                        series: [dataTest.data],
                        chart: {
                          height: 350,
                          type: 'bar',
                          toolbar: {
                            show: true,
                            offsetX: 0,
                            offsetY: 0,
                            tools: {
                              download: true,
                              selection: true,
                              zoom: true,
                              zoomin: true,
                              zoomout: true,
                              pan: true,
                              reset: true,
                              customIcons: [],
                            },
                            export: {
                              csv: {
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                  return new Date(timestamp).toDateString();
                                },
                              },
                              svg: {
                                filename: undefined,
                              },
                              png: {
                                filename: undefined,
                              },
                            },
                            autoSelected: 'zoom',
                          },
                        },
                        colors: ['#FF4560'],
                        title: {
                          text: dataTest.data.name,
                        },
                        xaxis: {
                          categories: dataTest.keys,
                        },
                      });
                      this.loadGraficoComprobante = false;

                      this.dashboardSubscriber = this._dashboardService
                        .obtenerDashboard(this.year)
                        .subscribe(
                          (response) => {
                            this.pedidosAtender = response[0].pendiente
                              ? response[0].pendiente
                              : this.pedidosAtender;
                            this.pedidosAprobar = response[0].pendi_aprob
                              ? response[0].pendi_aprob
                              : this.pedidosAprobar;
                            this.pedidosPlazo = response[0].fuera_plazo_atender
                              ? response[0].fuera_plazo_atender
                              : this.pedidosPlazo;

                            this.cantidadVenta = response[0].cantidad_venta
                              ? response[0].cantidad_venta
                              : this.cantidadVenta;
                            this.importeVenta = response[0].importe_venta
                              ? response[0].importe_venta
                              : this.importeVenta;
                            this.devolucion = response[0].devolucion
                              ? response[0].devolucion
                              : this.devolucion;

                            this.loaderPedidos = false;
                          },
                          (error) => {
                            this.loaderPedidos = false;
                            this.loaderVentas = false;
                            this.loaderComprobantes = false;
                          }
                        );
                    },
                    (error) => {
                      this.loaderPedidos = false;
                      this.loaderVentas = false;
                      this.loaderComprobantes = false;
                      this._snackBarService.showError(
                        'Errror al obtener gráfico de comprobantes',
                        'OK'
                      );
                    }
                  );
              },
              (error) => {
                this.loaderPedidos = false;
                this.loaderVentas = false;
                this.loaderComprobantes = false;
                this._snackBarService.showError(
                  'Error al obtener gráfico de ventas',
                  'OK'
                );
              }
            );
        },
        (error) => {
          this.loaderPedidos = false;
          this.loaderVentas = false;
          this.loaderComprobantes = false;
          this._snackBarService.showError(
            'Error al obtener gráfico de vendedores',
            'OK'
          );
        }
      );
  }

  establecerPeriodo(): void {
    this.store.dispatch(
      cambiar({
        year: this.year,
        month: this.month,
        openingYear: this.openingYear,
        openingMonth: this.openingMonth,
        codMod: '00',
        periodos: []
      })
    );
  }
}
