import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { formatDateWithDash } from '@utils/formats/date.format';
import { numberWithCommas } from '@utils/formats/number.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { generarFormatoOrdenCompra } from '@utils/pdfmaker/builder/compras/operaciones/orden-compra.pdfmaker';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ComprasService, ConfigurationService } from 'src/app/services';
import { OrdenCompraService } from 'src/app/services/api/compras/operaciones/orden-compra.service';
import { IParam } from 'src/app/services/api/utilities/params';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { FiltroAprobacionRequerimientoMateriales } from './models/filtro-aprobacion-requerimiento-materiales';

@Component({
  selector: 'app-aprob-requerimiento-materiales',
  templateUrl: './aprob-requerimiento-materiales.component.html',
  styleUrls: ['./aprob-requerimiento-materiales.component.css']
})
export class AprobRequerimientoMaterialesComponent implements OnInit {


  displayedColumns: string[] = ['select', 'acciones', 'ot', 'cliente', 'componente_item', 'req_materiales', 'fec_emision', 'solicitante'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  loaderReg: boolean = false;
  loaderData: boolean = false;
  selection = new SelectionModel<any>(true, []);

  proveedores: any[] = [];
  buttonsName: INameConstant = NAMES_CONSTANTS;

  btnName: string;

  loading$: Subscription;

  totalMN: number = 0;
  totalME: number = 0;

  filtro: FiltroAprobacionRequerimientoMateriales;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _clienteService: ClienteService,
    private _comprasService: ComprasService,
    private _ordenCompraService: OrdenCompraService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.filtro = new FiltroAprobacionRequerimientoMateriales();

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadMaestros();
    })
  }

  loadMaestros(): void {
    this.loaderData = true;
    this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.proveedores = clientes.filter(item => item.cli_indpro == 1);
        this.obtenerOrdenesDeCompra();
      },
      error => {
        this._snackBarService.showError('Error al obtener proveedores', 'OK');
        this.loaderData = false;
      }
    )

  }

  obtenerOrdenesDeCompra(): void {

    let params: Array<IParam> = [
      {
        param: 'vigentes',
        value: true
      },
      {
        param: 'usuarioaprobador',
        value: this._configurationService.obtenerIdUsuario()
      },
      {
        param: 'aprobados',
        value: this.filtro.estaAprobado
      }

    ];

    if(this.filtro.proveedor) {
      params.push({
        param: 'cliente',
        value: this.filtro.proveedor
      })
    }

    if(this.filtro.fechaSeleccionada) {
      if(this.filtro.desde && this.filtro.hasta) {
        params.push({
          param: 'desde',
          value: formatDateWithDash(this.filtro.desde)
        });
        params.push({
          param: 'hasta',
          value: formatDateWithDash(this.filtro.hasta)
        });
      }
    }

    console.log('params', params);


    this._ordenCompraService.obtenerOrdenesCompra({params}).subscribe(
      response => {

        this.totalMN = 0;
        this.totalME = 0;

        const filtrados = response.filter(item => item.ocm_indgen == 0);

        filtrados.forEach(item => {

          item['importe'] = numberWithCommas((parseFloat((item.ocm_imptot))).toFixed(2));

          (item.tmo_codtmo == 'SO') ? this.totalMN += item.ocm_imptot: this.totalME += item.ocm_imptot;

        })

        this.totalMN = numberWithCommas(parseFloat(((this.totalMN)).toFixed(2)));
        this.totalME = numberWithCommas(parseFloat(((this.totalME)).toFixed(2)));

        console.log('response', response)
        this.dataSource = fillTable(filtrados, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  mostrarPdf(id): void {
    const headers = {
      username: this._configurationService.obtenerIdUsuario()
    }

    this._comprasService.obtenerPdfOrdenCompra(id).subscribe(
      response => {
        if(response.length > 0 && response != null) {
          // verPdfReport(headers, response[0]);
          generarFormatoOrdenCompra(headers, response[0]);

        }
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  buscar(): void {
    this.obtenerOrdenesDeCompra();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  verMasOpciones(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }

}
