import { Component, Inject } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { KardexUploadDocumentsDialog } from "../dialogs/kardex-upload-documents-dialog/kardex-upoload-documents.dialog";

@Component({
  selector: 'kardex-bottom-sheet',
  templateUrl: './kardex-bottom-sheet.html',
  styleUrls: ['./kardex-bottom-sheet.scss']
})
export class KardexBottomSheet {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  cargarDocumentos(): void{
    const dialogRef = this.dialog.open(KardexUploadDocumentsDialog, {
      width: 'auto',
      data: {},
      panelClass: 'pav-dialog-container-option-1',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {

    })
  }
}
