<h1 mat-dialog-title>Cargar Colaboradores</h1>
<mat-progress-bar *ngIf="loaderData" mode="indeterminate"></mat-progress-bar>

<div mat-dialog-content>
  <section class="example-section">
    <span class="example-list-section">
      <mat-checkbox class="example-margin"
                    [checked]="allComplete"
                    [color]="task.color"
                    [indeterminate]="someComplete()"
                    (change)="setAll($event.checked)">
        {{task.name}}
      </mat-checkbox>
    </span>
    <span class="example-list-section">
      <ul>
        <li *ngFor="let subtask of task.subtasks">
          <mat-checkbox [(ngModel)]="subtask.completed"
                        [color]="subtask.color"
                        (ngModelChange)="updateAllComplete()">
            {{subtask.name | titlecase}}
          </mat-checkbox>
        </li>
      </ul>
    </span>
  </section>

</div>
<div mat-dialog-actions align="end">
  <button class="pav-btn-cancel" mat-flat-button (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
  <button class="pav-btn-load" mat-flat-button (click)="cargarColaboradores()" cdkFocusInitial>Cargar</button>
</div>
