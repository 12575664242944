import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ListaPrecioCliente } from "src/app/models/ventas/lista-precio-cliente";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class ListaPrecioClienteService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerListasPrecioCliente(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/listarcliente/${this._configurationService.obtenerCompaniaCliente()}`,

    );
  }

  obtenerListaPrecioCliente(codcli, correlativo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/mostrarpreciocliente/${this._configurationService.obtenerCompaniaCliente()}/${codcli}/${correlativo}`,

    );
  }

  registrarListaPrecioCliente(payload: ListaPrecioCliente): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/crearlistapreciocliente`,
      payload
    )
  }

  editarListaPrecioCliente(payload: ListaPrecioCliente): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/precios/modificarlistapreciocliente`,
      payload
    )
  }
}
