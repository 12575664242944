import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Seller } from 'src/app/models';
import { ConfigurationService, VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { VendedorService } from 'src/app/services/api/ventas/maestros/vendedor.service';
import { SupervisorService } from 'src/app/services/api/ventas/maestros/supervisor.service';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'vendedor-form',
  templateUrl: './vendedor-form.component.html',
  styleUrls: ['./vendedor-form.component.css']
})
export class VendedorFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns1: string[] = ['anio', 'mes', 'cuota', 'fecha_act'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  displayedColumns2: string[] = ['dia', 'cod', 'zona', 'fecha'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  seller: Seller;

  vendedores: any[] = [];
  sectoristas: any[] = [];
  supervisores: any[] = [];
  segmentos: any[] = [];

  vendedor$: Subscription;
  sendForm$: Subscription;
  loading$: Subscription;

  vendedores$: Subscription;
  sectoristas$: Subscription;
  supervisores$: Subscription;
  segmentos$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  constructor(
    private _router: Router,
    private _dialogService: DialogService,
    private _vendedorService: VendedorService,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private _activatedRoute: ActivatedRoute,
    private _supervisorService: SupervisorService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {

    this.seller = new Seller();
    this.seller.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.seller.VDE_CODUSE = this._configurationService.obtenerIdUsuario();

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();

    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.btnName = this.uid == '0' ?  this.buttonsName.BTN_STORE: this.buttonsName.BTN_UPDATE;

      this.loadMaestros()

    })
  }

  loadMaestros(): void {
    this.loaderData = true;

    forkObs(
      this._vendedorService.listarVendedores(),
      this._ventasService.listarSectoristas(),
      this._supervisorService.listarSupervisores(),
      this._ventasService.listarSegmentos()
    ).then(data => {
      this.vendedores = data[0];
      this.sectoristas = data[1];
      this.supervisores = data[2];

      this.segmentos = data[3];
      if(this.uid != '0') {
        this.obtenerVendedor();
      };
      this.loaderData = false;
    }).catch(err => {
      this._snackBarService.showError('Error al obtener maestros', 'Ok');
    })

  }

  obtenerVendedor(): void {
    console.log('obteniendo vendedor')
    this.vendedor$ = this._vendedorService.obtenerVendedor(this.uid).subscribe(
      vendedor => {

        console.log('vendedor', vendedor)
        this.seller = vendedor;
        this.seller.VDE_CODUSE = this._configurationService.obtenerIdUsuario();
        this.seller.VDE_FECUPD = new Date();

      },
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;
    this.uid == '0' ? this.registrarVendedor(f): this.actualizarVendedor(f);

  }

  registrarVendedor(f: NgForm): void {
    this.sendForm$ = this._vendedorService.registrarVendedor(this.seller).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Vendedor registrado', '400px', '400px', '');
        f.resetForm();
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  actualizarVendedor(f: NgForm): void {
    this.sendForm$ = this._vendedorService.actualizarVendedor(this.seller).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Vendedor actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/vendedores']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.vendedor$,
      this.loading$,
      this.sendForm$
    ])
  }
}
