<div *ngIf="isLoading | async" class="overlay">

  <mat-progress-spinner class="spinner-data" mode="indeterminate" diameter="30">
  </mat-progress-spinner>


</div>

<!-- <div class="boxes">
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div> -->
