import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ConceptoRemunerativo } from "src/app/models/planilla/configuraciones/concepto-remunerativo";
import { ConfigurationService, NominaService } from "src/app/services";
import { ApiNominaConfiguracionesConceptoRemuneracionService } from "src/app/services/api/nomina/configuraciones/nomina.configuraciones.concepto-remuneracion.service";

@Component({
  selector: 'app-concepto-remunerativo-create',
  templateUrl: './concepto-remunerativo-create.component.html',
  styleUrls: ['./concepto-remunerativo-create.component.scss']
})
export class ConceptoRemunerativoCreateComponent implements OnInit, OnDestroy {
  conceptoRemunerativo: ConceptoRemunerativo;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  auxiliares: any[] = [];
  unidades: any[] = [];
  tipos: any[] = [];

  uid: string;

  loading$: Subscription;
  conceptoRem$: Subscription;
  colaborador$: Subscription;
  unidad$: Subscription;
  tipoConcepto$: Subscription;
  sendForm$: Subscription;

  constructor(
    private _nominaService: NominaService,
    private _apiConceptoRemunerativoNominaService: ApiNominaConfiguracionesConceptoRemuneracionService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _router: Router,
    private store: Store<PavsoState>
  ) {

    this.conceptoRemunerativo = new ConceptoRemunerativo();
    this.conceptoRemunerativo.coduse = this._configurationService.obtenerIdUsuario();

    // para consultar
    this.conceptoRemunerativo.indnia = 0;
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.loadMaestros();
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

        if(id == '0') return;
     }
    )
  }

  obtenerConceptoRemunerativo(): void {

    this.conceptoRem$ = this._apiConceptoRemunerativoNominaService.listarConceptosRemunerativos().subscribe(
      conceptos => {
        const concepto = conceptos.find(item => item.codnco == this.uid);
        this.conceptoRemunerativo = concepto;
      },
      error =>{
        this._snackBarService.showError("Error al obtener Conceptos remunerativos", "OK");
      }
    )

  }

  /**
   * Obtiene los maestros de :
   * . colaboradores
   * . unidades
   * . tipos de concepto
   */
  loadMaestros(): void {

    forkObs(
      this._nominaService.listarColaboradores(),
      this._nominaService.listarUnidades(),
      this._nominaService.listarTiposConcepto()
    ).then(data => {

      this.auxiliares = data[0];
      this.unidades = data[1];
      this.tipos = data[2];

      if(this.uid != '0') this.obtenerConceptoRemunerativo();

    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK")
    })

  }

  enviarFormulario(f: NgForm): void {
    this.normalizarCamposBoolean();

    this.loaderReg = true;
    this.uid == '0'? this.registrarConcepto(f): this.actualizarConcepto(f);
  }

  registrarConcepto(f: NgForm): void {

    this.sendForm$ = this._apiConceptoRemunerativoNominaService.registrarConceptoRemunerativo(this.conceptoRemunerativo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Concepto remunerativo registrado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarConcepto(f: NgForm): void {

    this.sendForm$ = this._apiConceptoRemunerativoNominaService.actualizarConceptoRemunerativo(this.conceptoRemunerativo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Concepto remunerativo actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  normalizarCamposBoolean(): void {
    (this.conceptoRemunerativo.AFEAFP) ? this.conceptoRemunerativo.AFEAFP = 1: this.conceptoRemunerativo.AFEAFP = 0;
    (this.conceptoRemunerativo.afegra) ? this.conceptoRemunerativo.afegra = 1: this.conceptoRemunerativo.afegra = 0;
    (this.conceptoRemunerativo.aferta) ? this.conceptoRemunerativo.aferta = 1: this.conceptoRemunerativo.aferta = 0;
    (this.conceptoRemunerativo.afevac) ? this.conceptoRemunerativo.afevac = 1: this.conceptoRemunerativo.afevac = 0;
    (this.conceptoRemunerativo.excpag) ? this.conceptoRemunerativo.excpag = 1: this.conceptoRemunerativo.excpag = 0;
    (this.conceptoRemunerativo.indapv) ? this.conceptoRemunerativo.indapv = 1: this.conceptoRemunerativo.indapv = 0;
    (this.conceptoRemunerativo.indctg) ? this.conceptoRemunerativo.indctg = 1: this.conceptoRemunerativo.indctg = 0;
    (this.conceptoRemunerativo.inddef) ? this.conceptoRemunerativo.inddef = 1: this.conceptoRemunerativo.inddef = 0;
    (this.conceptoRemunerativo.indsub) ? this.conceptoRemunerativo.indsub = 1: this.conceptoRemunerativo.indsub = 0;
    (this.conceptoRemunerativo.afects) ? this.conceptoRemunerativo.afects = 1: this.conceptoRemunerativo.afects = 0;
    (this.conceptoRemunerativo.desess) ? this.conceptoRemunerativo.desess = 1: this.conceptoRemunerativo.desess = 0;
    (this.conceptoRemunerativo.excgas) ? this.conceptoRemunerativo.excgas = 1: this.conceptoRemunerativo.excgas = 0;
    (this.conceptoRemunerativo.indcos) ? this.conceptoRemunerativo.indcos = 1: this.conceptoRemunerativo.indcos = 0;
    (this.conceptoRemunerativo.indddu) ? this.conceptoRemunerativo.indddu = 1: this.conceptoRemunerativo.indddu = 0;
    (this.conceptoRemunerativo.indfis) ? this.conceptoRemunerativo.indfis = 1: this.conceptoRemunerativo.indfis = 0;
    (this.conceptoRemunerativo.indina) ? this.conceptoRemunerativo.indina = 1: this.conceptoRemunerativo.indina = 0;
    (this.conceptoRemunerativo.indoct) ? this.conceptoRemunerativo.indoct = 1: this.conceptoRemunerativo.indoct = 0;
    (this.conceptoRemunerativo.indpvs) ? this.conceptoRemunerativo.indpvs = 1: this.conceptoRemunerativo.indpvs = 0;
    (this.conceptoRemunerativo.impdef) ? this.conceptoRemunerativo.impdef = 1: this.conceptoRemunerativo.impdef = 0;
    (this.conceptoRemunerativo.indpvs) ? this.conceptoRemunerativo.indpvs = 1: this.conceptoRemunerativo.indpvs = 0;
    (this.conceptoRemunerativo.indpvs) ? this.conceptoRemunerativo.indpvs = 1: this.conceptoRemunerativo.indpvs = 0;
    (this.conceptoRemunerativo.indpvs) ? this.conceptoRemunerativo.indpvs = 1: this.conceptoRemunerativo.indpvs = 0;
    (this.conceptoRemunerativo.indafp) ? this.conceptoRemunerativo.indafp = 1: this.conceptoRemunerativo.indafp = 0;
    (this.conceptoRemunerativo.indimp) ? this.conceptoRemunerativo.indimp = 1: this.conceptoRemunerativo.indimp = 0;
    (this.conceptoRemunerativo.indqui) ? this.conceptoRemunerativo.indqui = 1: this.conceptoRemunerativo.indqui = 0;
    (this.conceptoRemunerativo.indren) ? this.conceptoRemunerativo.indren = 1: this.conceptoRemunerativo.indren = 0;
    (this.conceptoRemunerativo.indvar) ? this.conceptoRemunerativo.indvar = 1: this.conceptoRemunerativo.indvar = 0;
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/concepto-remunerativo'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.conceptoRem$,
      this.colaborador$,
      this.unidad$,
      this.tipoConcepto$,
      this.conceptoRem$,
      this.sendForm$
    ]);
  }
}
