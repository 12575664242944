import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { User } from '../models/user';
import { ConfigurationService } from './configuration.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @param user
   * @returns
   */
  registrarUsuario(user: User): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}register`, user);

  }

  /**
   *
   * @param user
   * @returns
   */
  actualizarUsuario(user: User): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}update`, user);

  }

  /**
   *
   * @param params
   * @returns
   */
  obtenerUsuarios(params): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}listarusuarios`, params);
  }

  /**
   *
   * @param params
   * @returns
   */
  cambiarEstado(params): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}cambiaestado`, params);

  }

  /**
   *
   * @param params
   * @returns
   */
  cambiarContrasenia(params): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}updatepassword`, params);

  }

  /**
   *
   * @param codeeus
   * @returns
   */
  obtenerUsuario(codeeus): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}profile/${codeeus}`);

  }

  /**
   *
   * @param id
   * @returns
   */
  listarClientesDeUsuario(id): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}usuariocliente/${this._configurationService.obtenerModo()}/${id}`);

  }

  obtenerEstablecimientoDeUsuario(): Observable<any> {

    return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/sistema/usuario/mostrar/${this._configurationService.obtenerNombreUsuario()}`);

  }
}
