import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnidadTransporte } from 'src/app/models/almacen/maestros/unidad-transporte';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Injectable({ providedIn: 'root' })
export class UnidadTransporteService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }


  obtenerUnidadesTransporte(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/guias/unidadtransporte/listar/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((unidadesTransporte: any) => {
        return unidadesTransporte.map(unidad => {
          unidad['description'] = `${unidad.UTR_CODUTR} | ${unidad.UTR_DESCRI}`
          return unidad;
        })
      })
    );
  }

  obtenerUnidadTransporte(id: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/guias/unidadtransporte/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    )
  }

  /**
   *
   * @param conductor
   * @returns
   */
  registrarUnidadTransporte(unidadTransporte: UnidadTransporte) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/guias/unidadtransporte/crear`,
      unidadTransporte
    );
  }

  /**
   *
   * @param conductor
   * @returns
   */
  actualizarUnidadTransporte(unidadTransporte: UnidadTransporte) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/guias/unidadtransporte/modificar`,
      unidadTransporte
    );
  }
}
