import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { CajaBancoService, ContabilidadService } from 'src/app/services';
import { OrdenPagoService } from 'src/app/services/api/tesoreria/orden-pago.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';

class FiltroIngresoBanco {
  subdiario: string;
  banco: string;
  tipoDoc: string;
  nroDoc: string;
  proveedor: string;
  monto: string;
  fecha: string;
}

@Component({
  selector: 'transferencia-entre-cuentas-list',
  templateUrl: 'transferencia-entre-cuentas-list.component.html',
  styleUrls: ['transferencia-entre-cuentas-list.component.scss']
})

export class TransferenciaEntreCuentasListComponent implements OnInit {

  displayedColumns: string[] = ['acciones', 'SCO_CODSCO', 'LDC_CORLDC', 'PBA_FECORD', 'CBA_CODCBA', 'TDO_CODTDO', 'PBA_NUMDOC', 'TMO_CODTMO', 'CLI_CODCLI', 'PBA_IMPSOL', 'PBA_IMPDOL', 'PBA_INDSTA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderData: boolean;

  loading$: Subscription;
  subdiarios$: Subscription;
  bancos$: Subscription;
  tiposDoc$: Subscription;
  proveedores$: Subscription;

  filtro: FiltroIngresoBanco;

  subdiarios: any[] = [];
  bancos: any[] = [];
  tiposDoc: any[] = [];
  proveedores: any[] = [];

  egresos$: Subscription;
  anioPeriodo: string;
  mesPeriodo: string;

  constructor(
    private store: Store<PavsoState>,
    private _contabilidadService: ContabilidadService,
    private _cuentaPagarService: CajaBancoService,
    private _clienteService: ClienteService,
    private _snackBarService: SnackBarService,
    private _ordenPagoService: OrdenPagoService,
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.filtro = new FiltroIngresoBanco();
  }

  ngOnInit() {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadMaestros();
    })

    this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })
  }

  loadMaestros(): void {
    this.loaderData = true;
    this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => {

        this.subdiarios = subdiarios.filter(item => item.sco_indtes == 1 && item.sco_indtee == 1);
        console.log('subdiarios', subdiarios)

        this.bancos$ = this._cuentaPagarService.obtenerCuentasBancarias().subscribe(
          bancos => {
            this.bancos = bancos;
            console.log('bancos', bancos)

            this.tiposDoc$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
              tiposDoc => {
                this.tiposDoc = tiposDoc;
                console.log('tiposDoc', tiposDoc)

                this.proveedores$ = this._clienteService.obtenerClientes().subscribe(
                  proveedores => {
                    this.proveedores = proveedores;
                    console.log('proveedores', proveedores)

                    this.obtenerIngresosBanco();
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener clientes', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener bancos', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
        this.loaderData = false;
      }
    )
  }

  obtenerIngresosBanco(): void {

    this.loaderData = true;
    this.egresos$ = this._ordenPagoService.obtenerOrdenesPago(this.anioPeriodo, this.mesPeriodo).subscribe(
      ordenes => {
        console.log('ordenes', ordenes)
        this.dataSource = fillTable(ordenes.filter(item => item.SCO_CODSCO.substring(0, 2) == '17'), this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener ordenes de pago', 'Ok');
        this.loaderData = false;
      }
    )

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
