import { AuthAprobarCompraCreateComponent } from "./auth-aprobar-compra/auth-aprobar-compra-create/auth-aprobar-compra-create.component";
import { AuthAprobarCompraListComponent } from "./auth-aprobar-compra/auth-aprobar-compra-list/auth-aprobar-compra-list.component";
import { AuthAprobarSolicitudCreateComponent } from "./auth-aprobar-solicitud/auth-aprobar-solicitud-create/auth-aprobar-solicitud-create.component";
import { AuthAprobarSolicitudListComponent } from "./auth-aprobar-solicitud/auth-aprobar-solicitud-list/auth-aprobar-solicitud-list.component";
import { CondicionPagoFormComponent, CondicionPagoListComponent } from "./condicion-pago";
import { LugarDespachoFormComponent, LugarDespachoListComponent } from "./lugar-despacho";
import { ProveedorModule } from "./proveedor/proveedor.module";
import { TerminoCompraFormComponent, TerminoCompraListComponent } from "./termino-compra";
import { TipoServicioModule } from "./tipo-servicio/tipo-servicio.module";

export const maestrosComprasComponent = [
  AuthAprobarCompraCreateComponent,
  AuthAprobarCompraListComponent,
  AuthAprobarSolicitudCreateComponent,
  AuthAprobarSolicitudListComponent,
  CondicionPagoFormComponent,
  CondicionPagoListComponent,
  LugarDespachoFormComponent,
  LugarDespachoListComponent,
  TerminoCompraFormComponent,
  TerminoCompraListComponent,
]

export const maestrosComprasModules = [
  ProveedorModule,
  TipoServicioModule
]
