import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { fillTable } from '@utils/tables/table';
import { VigenciaImpuesto } from 'src/app/models';

@Component({
  selector: 'app-vigencia-impuesto',
  templateUrl: './vigencia-impuesto.component.html',
  styleUrls: ['./vigencia-impuesto.component.css']
})
export class VigenciaImpuestoComponent implements OnInit {

  displayedColumns: string[] = ['acciones', 'cli_codcli', 'cli_nomcli', 'cli_numtlf'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonName: INameConstant = NAMES_CONSTANTS;

  vigenciaImpuesto: VigenciaImpuesto;
  constructor(
    public dialogRef: MatDialogRef<VigenciaImpuestoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.vigenciaImpuesto = new VigenciaImpuesto();
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
