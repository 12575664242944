import { REPORTE_HEADER_BG_COLOR, REPORT_HEADER_COLOR } from "@data/constants/configuration/configuration.constant";
import { formatDateWithDash, formatDateWithSlash } from "@utils/formats/date.format";
import { numberWithCommas } from "@utils/formats/number.format";
import { bottomBorder, bottomLeftBorder, bottomRightBorder, horizontalBorder, noBorders, openBottomBorder, openRightBorder, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";

export async function generarPdfCosto() {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([ 20, 20, 20, 20 ]);


  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("COMERCIALIZADORA ASIA PERU S.A.C.").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("13/09/2021").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("RCOM_REP_DET_VE").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("15:03:11").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("INFORME COSTOS TEMPLATE").fontSize(12).bold().alignment('center').end
        ).end
      ]
    ]).layout('noBorders').widths([1140]).end
  );


  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("Cliente").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("TD").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Número").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Fecha").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Línea").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cod. Pro.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Producto").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cantidad").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Precio").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Moneda").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Importe").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Usuario").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Referencia").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Pedido").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Guía Remisión").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cond. Pago").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Vendedor").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Motivo").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Ind. Guía").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Almacén").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Costo Prod.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Departamento").fontSize(8).bold().alignment('center').end
        ).end
      ]
    ]).widths([80,20,30,30,40,50,80,40,30,30,30,60,80,40,50,30,80,30,30,30,30,30]).end
  );

  pdf.add(
    new Table(this.dataDetalleFacturacion).layout('noBorders').widths([85,20,35,30,40,50,83,43,30,30,30,60,80,40,55,30,80,30,30,30,30,30]).end
  );

  pdf.create().open();

}

export function reporteDetalladoCostosOrdenFabricacion(headers, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);

  let detalle = [];

  let [tgDirecta, tgIndirecta, tgGastos, tgImporte, tgCostos] = [0, 0, 0, 0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`${element.tin_codtin} ${element.TIN_DESCRI}`).fontSize(10).bold().alignment('left').end
      ).colSpan(15).border(noBorders).end,
      ...obtenerNulls(14),
    ],)

    let [totCantidad, totDirecta, totIndirecta, totGastos, totImporte, totCostos] = [0, 0, 0, 0, 0, 0];

    element.detalle.forEach(element2 => {
      detalle.push([
        new Cell(
          new Txt(`${element2.char_omc_fecomc}`).fontSize(9).bold().alignment('left').end
        ).colSpan(15).border(noBorders).end,
        ...obtenerNulls(14),
      ],)

      let [tCantidad, tDirecta, tIndirecta, tHoras, tGastos, tImporte, tCosto] = [0, 0, 0, 0, 0, 0, 0];

      element2.detalle.forEach(element3 => {
        detalle.push([
          new Cell(
            new Txt(element3.cmt_ordtra).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.char_omc_fecomc).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.prd_codprd).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.prd_desesp).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.cop_canprd).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.ume_codume).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.prd_pesnet).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.cop_canund).fontSize(8).end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.cop_cosdir).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.cop_cosind).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.cop_hrsliq).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.cop_totgfa).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.cop_impmac).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt(element3.cop_coscop).fontSize(8).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt((element3.cop_coscop / element3.cop_canprd).toFixed(3)).fontSize(8).alignment('right').end
          ).border(noBorders).end,
        ],)
        tCantidad += element3.cop_canund;
        tDirecta += element3.cop_cosdir;
        tIndirecta += element3.cop_cosind;
        tHoras += element3.cop_hrsliq;
        tGastos += element3.cop_totgfa;
        tImporte += element3.cop_impmac;
        tCosto += element3.cop_coscop;
      });

      detalle.push([
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(tCantidad.toFixed(2))).fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tDirecta.toFixed(2))).fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tIndirecta.toFixed(2))).fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tHoras.toFixed(2))).fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tGastos.toFixed(2))).fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tImporte.toFixed(2))).fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(tCosto.toFixed(2))).fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],)

      totCantidad += tCantidad;
      totDirecta += tDirecta;
      totIndirecta += tIndirecta;
      totGastos += tGastos;
      totImporte += tImporte;
      totCostos += tCosto;
    });

    detalle.push([
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).alignment('right').end
      ).border(noBorders).end,
       new Cell(
        new Txt("").fontSize(8).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totCantidad.toFixed(2))).fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totDirecta.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totIndirecta.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt("").fontSize(8).alignment('right').bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totGastos.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totImporte.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totCostos.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt("").fontSize(8).alignment('right').end
      ).border(noBorders).end,
    ])

    tgDirecta += totDirecta;
    tgIndirecta += totIndirecta;
    tgGastos += totGastos;
    tgImporte += totImporte;
    tgCostos += totCostos;

  });

  detalle.push([
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES").fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgDirecta.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tgIndirecta.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(8).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgGastos.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tgImporte.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tgCostos.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt("").fontSize(8).alignment('right').end
    ).border(noBorders).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(9).bold().end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("DETALLADO DE COSTOS POR ORDEN DE FABRICACIÓN").alignment('center').fontSize(12).bold().end,
            new Txt(`Período ${headers.year} - ${headers.month}`).fontSize(9).bold().alignment('center').end,
          ]).end
        ).colSpan(11).border(noBorders).end,
        ...obtenerNulls(10),
        new Cell(
          new Txt(headers.now).fontSize(9).bold().alignment('right').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Orden Fabricación - Mantenimiento").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Fecha Emisión").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Código").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Producto").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Cantidad").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("U.M.").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Peso Neto").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Cantidad KGS").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Mano de obra").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Gastos de Fábrica").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Importe de Material").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Costo Total").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
        new Cell(
          new Txt("Costo Unitario").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).rowSpan(2).end,
      ],
      [
        ...obtenerNulls(8),
        new Cell(
          new Txt("Directa").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).end,
        new Cell(
          new Txt("Indirecta").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).end,
        new Cell(
          new Txt("Nro. de Horas Directa").fontSize(8).bold().end
        ).color(REPORT_HEADER_COLOR).fillColor(REPORTE_HEADER_BG_COLOR).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("0001 FABRICACION").fontSize(10).bold().alignment('center').end
        ).colSpan(15).border(noBorders).end,
        ...obtenerNulls(14),
      ],
      ...detalle,
    ]).headerRows(3).widths([60, 50, 25, 130, 40, 25, 30, 30, 40, 40, 40, 40, 40, 40, 35]).headerRows(3).end
  )

  pdf.create().open();

}

export function reporteEstructuraCostosOrdenProduccion(headers, data): void {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt(headers.clientName).fontSize(9).bold().end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt("Estructura de Costos por Orden de Producción").fontSize(12).bold().alignment('center').end,
            new Txt(`PERIODO: ${headers.year} - ${headers.month}`).fontSize(9).bold().alignment('center').end,
            new Txt("PROCESO: 0001 - FABRICACIÓN").fontSize(9).bold().alignment('center').end,
          ]).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt(headers.now).fontSize(9).bold().alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("ORDEN Nro. : 0001 / 1912000007").fontSize(11).bold().end
        ).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("PRODUCTO: TT02").bold().fontSize(10).end
        ).border(openRightBorder).rowSpan(2).end,
        new Cell(
          new Txt("TORTILLA DE TRIGO DE 17 CM").bold().fontSize(10).end
        ).border(horizontalBorder).rowSpan(2).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("\n").end
        ).end,
        new Cell(
          new Txt("\n").end
        ).end
      ],
      [
        ...obtenerNulls(4),
        new Cell(
          new Txt("Fecha de Emisión").bold().fontSize(9).end
        ).end,
        new Cell(
          new Txt("03/12/19").bold().fontSize(9).end
        ).end
      ],
      [
        new Cell(
          new Txt("PRODUCTO PARA REPROCESO:").bold().fontSize(10).end
        ).border(openBottomBorder).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("TT02R").fontSize(9).bold().end
        ).border(bottomLeftBorder).end,
        new Cell(
          new Txt("TORTILLA DE TRIGO 17 CM REPROCESO").fontSize(9).bold().end
        ).border(bottomBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("KGS").fontSize(9).bold().end
        ).border(bottomBorder).end,
        new Cell(
          new Txt("2.890").fontSize(9).bold().end
        ).border(bottomRightBorder).end
      ],
      [
        new Cell(
          new Txt("TT02 - TORTILLA DE TRIGO DE 17 CM").fontSize(10).bold().end
        ).colSpan(6).fillColor('gray').end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("DISTRIBUCION DE LA MANO DE OBRA").fontSize(9).bold().end
        ).margin([0,20,0,0]).border(bottomBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Horas").fontSize(8).alignment('right').end
        ).margin([0,20,0,0]).border(bottomBorder).end,
        new Cell(
          new Txt("Importe").fontSize(8).alignment('right').end
        ).margin([0,20,0,0]).border(bottomBorder).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).margin([0,20,0,0]).border(bottomBorder).end,
      ],
      [
        new Cell(
          new Txt("M.O. DIRECTA").fontSize(9).bold().end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("TAREO POR COLABORADORES").fontSize(8).end
        ).border(bottomBorder).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("46335490 MACHADO ZAVALA PABLO ANTONIO").fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("1.56").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("20.76").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("6.206 %").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("46335490 MACHADO ZAVALA PABLO ANTONIO").fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(6),
        new Cell(
          new Txt("1.56").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("20.76").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("6.206 %").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("46335490 MACHADO ZAVALA PABLO ANTONIO").fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("1.56").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("20.76").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("6.206 %").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("46335490 MACHADO ZAVALA PABLO ANTONIO").fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("1.56").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("20.76").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("6.206 %").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("46335490 MACHADO ZAVALA PABLO ANTONIO").fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("1.56").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("20.76").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("6.206 %").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("46335490 MACHADO ZAVALA PABLO ANTONIO").fontSize(8).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("1.56").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("20.76").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("6.206 %").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).border(topBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("21.50").fontSize(8).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("334.49").fontSize(8).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("100.000 %").fontSize(8).alignment('right').end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("SubTotal").end
        ).border(topBorder).fontSize(8).bold().end,
        new Cell(
          new Txt("334.49").end
        ).border(topBorder).fontSize(8).bold().alignment('right').end,
        new Cell(
          new Txt("100.000 %").end
        ).border(topBorder).fontSize(8).bold().alignment('right').end,
      ],
      [
        new Cell(
          new Txt("DISTRIBUCION DE LOS GASTOS DE FABRICACION").fontSize(9).bold().end
        ).border(bottomBorder).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("Importe").alignment('right').fontSize(8).end
        ).border(bottomBorder).end,
        new Cell(
          new Txt("%").alignment('right').fontSize(8).end
        ).border(bottomBorder).end,
      ],
      [
        new Cell(
          new Txt("GASTOS DE FABRICACION").fontSize(8).alignment('center').end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt("182.53").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").alignment('center').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("SubTotal").fontSize(8).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("182.53").fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("100.00%").fontSize(8).bold().alignment('right').end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("DISTRIBUCION DE LOS CONSUMOS DE MATERIALES").fontSize(9).bold().end
        ).border(bottomBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Cantidad").alignment('right').fontSize(8).end
        ).border(bottomBorder).end,
        new Cell(
          new Txt("Importe").alignment('right').fontSize(8).end
        ).border(bottomBorder).end,
        new Cell(
          new Txt("%").alignment('right').fontSize(8).end
        ).border(bottomBorder).end,
      ],
      [
        new Cell(
          new Txt("MATERIAS PRIMAS").fontSize(9).bold().end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("IN001").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Manteca Vegetal").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.54").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("18.09").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IN001").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Manteca Vegetal").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.54").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("18.09").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IN001").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Manteca Vegetal").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.54").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("18.09").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IN001").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Manteca Vegetal").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.54").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("18.09").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IN001").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Manteca Vegetal").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.54").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("18.09").alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.54").fontSize(8).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("18.09").fontSize(8).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("ENVASES Y EMBALAJES").fontSize(9).bold().end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("EM001").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("BOLSA TRIGO EL TACO").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("UND").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("213.00").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("38.34").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("SUMINISTROS DIVERSOS").fontSize(9).bold().end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      [
        new Cell(
          new Txt("IN003").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Levamix 2PCar").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.82").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.33").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IN003").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Levamix 2PCar").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.82").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.33").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IN003").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Levamix 2PCar").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.82").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.33").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IN003").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Levamix 2PCar").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.82").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.33").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("IN003").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("Levamix 2PCar").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("KGS").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.82").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("4.33").fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.82").fontSize(8).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("4.33").fontSize(8).alignment('right').end
        ).border(topBorder).end,
        new Cell(
          new Txt("%").fontSize(8).alignment('right').end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("").end
        ).border(noBorders).end,
        new Cell(
          new Txt("SubTotal").fontSize(8).bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("146.80").fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("100.00 %").fontSize(8).alignment('right').bold().end
        ).border(topBorder).end,
      ],
      [
        new Cell(
          new Txt("").end
        ).border(openRightBorder).end,
        new Cell(
          new Txt("").end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("TOTAL").fontSize(8).bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("663.82").fontSize(8).alignment('right').bold().end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt("").bold().end
        ).border(openRightBorder).end,
      ],
    ]).headerRows(1).widths([105,105,105,60,70,60]).end
  )

  pdf.create().open();

}

export function reporteAnalisisDistribucionManoObraDirecta(headers, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  let detalle = [];

  let [totHoras, totImporte] = [0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.lfa_numlfa).fontSize(8).bold().end
      ).border(bottomBorder).end,
      new Cell(
        new Txt(formatDateWithSlash(element.lfa_fecdoc)).fontSize(8).bold().end
      ).border(bottomBorder).end,
      new Cell(
        new Txt(element.tur_codtur).fontSize(8).bold().end
      ).border(bottomBorder).colSpan(6).end,
      ...obtenerNulls(5),
    ],)

    let [tHoras, tImporte] = [0, 0];

    element.detalle.forEach(element2 => {
      detalle.push([
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element2.cli_codcli} ${element2.cli_nomcli}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element2.act_codact} ${element2.act_desact}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.lfc_cosuni).fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.lfc_tietot).fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.lfc_costot).fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],)

      tHoras += element2.lfc_tietot;
      tImporte += element2.lfc_costot;
    })

    detalle.push([
      new Cell(
        new Txt("TOTAL").fontSize(8).bold().alignment('right').end
      ).colSpan(6).border(noBorders).end,
      ...obtenerNulls(5),
      new Cell(
        new Txt( numberWithCommas(tHoras.toFixed(2))).fontSize(8).bold().alignment('right').end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tImporte.toFixed(2))).bold().alignment('right').fontSize(8).end
      ).border(topBorder).end,
    ],)

    totHoras += tHoras;
    totImporte += tImporte;

  });

  detalle.push([
    new Cell(
      new Txt("").fontSize(8).bold().alignment('right').end
    ).colSpan(6).border(noBorders).end,
    ...obtenerNulls(5),
    new Cell(
      new Txt(numberWithCommas(totHoras.toFixed(2))).fontSize(8).bold().alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totImporte.toFixed(2))).bold().alignment('right').fontSize(8).end
    ).border(topBorder).end,
  ])
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(9).bold().end,
            new Txt("RCOL_ANAL_DIST").fontSize(9).bold().end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("ANÁLISIS DE DISTRIBUCIÓN DE MANO DE OBRA DIRECTA").alignment('center').fontSize(10).bold().end,
            new Txt(`PERIODO: ${headers.year} - ${headers.month}`).alignment('center').fontSize(9).bold().end,
          ]).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt(headers.now).fontSize(9).bold().alignment('right').end,
            new Txt(headers.hour).fontSize(9).bold().alignment('right').end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Liquidación").alignment('center').fontSize(9).bold().end
        ).end,
        new Cell(
          new Txt("Fecha").alignment('center').fontSize(9).bold().end
        ).end,
        new Cell(
          new Txt("Turno").alignment('center').fontSize(9).bold().end
        ).end,
        new Cell(
          new Txt("Colaborador").alignment('center').fontSize(9).bold().end
        ).end,
        new Cell(
          new Txt("Actividad").alignment('center').fontSize(9).bold().end
        ).end,
        new Cell(
          new Txt("Costo Hora").alignment('center').fontSize(9).bold().end
        ).end,
        new Cell(
          new Txt("Nro. Horas").alignment('center').fontSize(9).bold().end
        ).end,
        new Cell(
          new Txt("Importe Total").alignment('center').fontSize(9).bold().end
        ).end,
      ],
      ...detalle,
    ]).headerRows(2).widths([50,45,25,160,90,35,35,40]).headerRows(2).end
  )

  pdf.create().open();
}

export function reporteAnalisisConsistenciaManoObraIndirecta(headers, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  let detalle = [];

  let [totHoras, totImporte] = [0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.lfa_numlfa).fontSize(9).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt("Orden:").fontSize(9).bold().end
      ).border(noBorders).colSpan(6).end,
      ...obtenerNulls(5),
    ],)

    let [tHoras, tImporte] = [0, 0];

    element.detalle.forEach(element2 => {
      detalle.push([
        new Cell(
          new Txt("").fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(element2.lfa_fecdoc)).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.tur_codtur).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element2.cli_codcli} ${element2.cli_nomcli}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element2.act_codact} ${element2.act_desact}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.lfc_tiehor).fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.lfc_costot).fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],)

      tHoras += element2.lfc_tiehor;
      tImporte += element2.lfc_costot;
    });

    detalle.push([
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("TOTAL:").fontSize(8).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tHoras.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tImporte.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
    ],)

    totHoras += tHoras;
    totImporte += tImporte;
  });

  detalle.push([
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES:").fontSize(8).bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totHoras.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totImporte.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(9).bold().end,
            new Txt("RCOL_ANAL_DIST").fontSize(9).bold().end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("ANÁLISIS DE CONSISTENCIA DE MANO DE OBRA INDIRECTA").alignment('center').fontSize(12).bold().end,
            new Txt(`PERIODO: ${headers.year} - ${headers.month}`).alignment('center').fontSize(9).bold().end,
          ]).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(headers.now).fontSize(9).bold().alignment('right').end,
            new Txt(headers.hour).fontSize(9).bold().alignment('right').end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Liquidación").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Fecha").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Turno").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Colaborador").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Actividad").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Horas").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Importe").fontSize(8).bold().end
        ).end,
      ],
      [
        new Cell(
          new Txt("Liquidación").fontSize(10).bold().end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
      ],
      ...detalle
    ]).headerRows(2).end
  )

  pdf.create().open();
}

export function reporteAnalisisDistribucionManoObraDirectaAuxiliar(headers, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  let detalle = [];

  let [totHoras, totImporte] = [0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`Colaborador: ${element.cli_codcli} ${element.cli_nomcli}`).fontSize(8).bold().end
      ).border(bottomBorder).colSpan(7).end,
      ...obtenerNulls(6),
    ],)

    let [tHoras, tImporte] = [0, 0];

    element.detalle.forEach(element2 => {
      detalle.push([
        new Cell(
          new Txt(element2.lfa_numlfa).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(element2.lfa_fecdoc)).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.tur_codtur).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element2.act_codact} ${element2.act_desact}`).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.lfc_cosuni).fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.lfc_tietot).fontSize(8).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.lfc_costot).fontSize(8).alignment('right').end
        ).border(noBorders).end,
      ],)

      tHoras += element2.lfc_tietot;
      tImporte += element2.lfc_costot;
    })

    detalle.push([
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("TOTAL").fontSize(8).alignment('left').bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tHoras.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tImporte.toFixed(2))).fontSize(8).alignment('right').bold().end
      ).border(topBorder).end,
    ],)

    totHoras += tHoras;
    totImporte += tImporte;

  });

  detalle.push([
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).alignment('left').bold().end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totHoras.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totImporte.toFixed(2))).fontSize(8).alignment('right').bold().end
    ).border(topBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(9).bold().end,
            new Txt("RCOL_ANAL_DIST").fontSize(9).bold().end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt("ANÁLISIS DE DISTRIBUCIÓN DE MANO DE OBRA DIRECTA - AUXILIAR").alignment('center').fontSize(12).bold().end,
            new Txt(`PERIODO: ${headers.year} - ${headers.month}`).alignment('center').fontSize(9).bold().end,
          ]).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(headers.now).fontSize(9).bold().alignment('right').end,
            new Txt(headers.hour).fontSize(9).bold().alignment('right').end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt("Liquidación").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Fecha").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Turno").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Actividad").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Costo Hora").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Nro. Horas").fontSize(8).bold().end
        ).end,
        new Cell(
          new Txt("Importe Total").fontSize(8).bold().end
        ).end,
      ],
      ...detalle,
    ]).headerRows(2).end
  )

  pdf.create().open();

}

export function reporteSaldosContablesSegunTipoCostoGastosFabrica(headers, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  let detalle = [];

  let [totNacional, totExtranjera] = [0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.ccn_codccn).fontSize(9).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.ccn_descri).fontSize(9).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.ldi_impsol)).fontSize(9).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(element.ldi_impdol)).fontSize(9).alignment('right').end
      ).border(noBorders).end,
    ],)

    totNacional += element.ldi_impsol;
    totExtranjera += element.ldi_impdol;
  })

  detalle.push([
    new Cell(
      new Txt("").fontSize(9).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES").fontSize(9).bold().alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totNacional.toFixed(2))).fontSize(9).bold().alignment('right').end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totExtranjera.toFixed(2))).fontSize(9).bold().alignment('right').end
    ).border(topBorder).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(9).bold().end,
            new Txt("RCOS_SALD_CONT").fontSize(9).bold().end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt("SALDOS CONTABLES SEGUN TIPO DE COSTO: GASTOS DE FABRICA").alignment('center').fontSize(11).bold().end,
            new Txt(`PERIODO: ${headers.year} - ${headers.month}`).alignment('center').fontSize(9).bold().end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(headers.now).alignment('right').fontSize(9).bold().end,
            new Txt(headers.hour).alignment('right').fontSize(9).bold().end
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("CUENTA CONTABLE").fontSize(9).bold().end
        ).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("M. NACIONAL").fontSize(9).bold().end
        ).end,
        new Cell(
          new Txt("M. EXTRANJERA").fontSize(9).bold().end
        ).end,
      ],
      ...detalle
    ]).headerRows(2).end
  )

  pdf.create().open();
}

export function reporteAnalisisDistribucionGastosFabrica(headers, data): void {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  let detalle = [];

  let [totImporte, totImpMO] = [0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`Orden: ${element.cmt_ordtra}  Ce. Co.  ${element.ccs_codccs}   ${element.ccs_descri}`).fontSize(8).bold().end
      ).border(noBorders).colSpan(6).end,
      ...obtenerNulls(5),
    ],)

    let [tImporte, tImpMO] = [0, 0]

    element.detalle.forEach(element2 => {
      detalle.push([
        new Cell(
          new Txt(element2.lfa_numlfa).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithDash(element2.cmt_fecdoc)).fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element2.cli_codcli} ${element2.cli_nomcli}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${element2.act_codact} ${element2.act_desact}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.cmt_impmna).alignment('right').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt(element2.cmt_costot).alignment('right').fontSize(8).end
        ).border(noBorders).end,
      ],)

      tImporte += element2.cmt_impmna;
      tImpMO += element2.cmt_costot;
    });

    detalle.push([
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt("TOTAL").bold().fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tImporte.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(tImpMO.toFixed(2))).alignment('right').bold().fontSize(8).end
      ).border(topBorder).end,
    ],)

    totImporte += tImporte;
    totImpMO += tImpMO;
  });

  detalle.push([
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt("TOTALES").bold().fontSize(8).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(totImporte.toFixed(2))).alignment('right').bold().fontSize(8).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(totImpMO.toFixed(2))).alignment('right').bold().fontSize(8).end
    ).border(topBorder).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(9).bold().end,
            new Txt("RCOS_DIST_GAST").fontSize(9).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt("ANÁLISIS DE DISTRIBUCIÓN DE GASTOS DE FÁBRICA").fontSize(10).bold().alignment('center').end,
            new Txt(`PERIODO: ${headers.year} - ${headers.month}`).fontSize(9).bold().alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt(headers.now).fontSize(9).bold().alignment('right').end,
            new Txt(headers.hour).fontSize(9).bold().alignment('right').end,
          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("LIQUIDACIÓN").fontSize(9).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("FECHA").fontSize(9).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("COLABORADOR").fontSize(9).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("ACTIVIDAD").fontSize(9).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("IMPORTE").fontSize(9).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("IMP. MO").fontSize(9).bold().alignment('center').end
        ).end,
      ],
      [
        new Cell(
          new Txt("0001 FABRICACIÓN").fontSize(9).bold().alignment('center').end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],
      ...detalle,
    ]).headerRows(2).end
  )

  pdf.create().open();

}

export function reporteSaldosContablesPorClientesGastosVentas(headers, data): void {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');

  pdf.pageMargins([ 20, 20, 20, 20 ]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt(headers.clientName).fontSize(9).bold().end,
            new Txt("RCOS_SALD_CLIE").fontSize(9).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt("Saldos Contables por Clientes: Gastos de Ventas").alignment('center').fontSize(12).bold().end,
            new Txt(`PERIODO : ${headers.year}-${headers.month}`).fontSize(9).bold().alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.now).fontSize(9).bold().alignment('right').end,
            new Txt(headers.hour).fontSize(9).bold().alignment('right').end,
          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt("CUENTA CONTABLE").fontSize(9).alignment('center').bold().end
        ).color('white').fillColor('#3478a3').colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("M. NACIONAL").fontSize(9).alignment('center').bold().end
        ).color('white').fillColor('#3478a3').end,
        new Cell(
          new Txt("M. EXTRANJERA").fontSize(9).alignment('center').bold().end
        ).color('white').fillColor('#3478a3').end,
      ],
      [
        new Cell(
          new Txt("3423424 fafdsfdasfsf").fontSize(9).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("0.00").fontSize(9).alignment('right').end
        ).border(noBorders).end,
        new Cell(
          new Txt("0.00").fontSize(9).alignment('right').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt("TOTALES").fontSize(9).alignment('right').bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt("0.00").fontSize(9).alignment('right').bold().end
        ).border(topBorder).end,
        new Cell(
          new Txt("0.00").fontSize(9).alignment('right').bold().end
        ).border(topBorder).end,
      ]
    ]).end
  );

  pdf.create().open();

}
