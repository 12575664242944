import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { codificacionProductoCliente } from "src/app/models/ventas/codificacion-producto-cliente";
import { codificacionProductoClienteDetalle } from "src/app/models/ventas/codificacion-producto-cliente-detalle";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";
@Component({
  selector: 'app-codificacion-producto-cliente-form',
  templateUrl: './codificacion-producto-cliente-form.component.html',
  styleUrls: ['./codificacion-producto-cliente-form.component.css']
})
export class CodificacionProductoClienteFormComponent implements OnInit,OnDestroy {

  loaderData: boolean = false;
  loaderReg: boolean;
  clientes: any[] =[];
  cliente: any;

  displayedColumns: string[] = ['actions', 'prd_codprd', 'pic_codprd', 'pic_desprd'];
  dataSource: MatTableDataSource<codificacionProductoCliente>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  codificacionProductoCliente: codificacionProductoCliente
  codificacionProductoClienteDetalle: Array<codificacionProductoClienteDetalle> //codificacionProductoCliente;

  comboProductos: any[] = [];

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  sendForm$: Subscription;
  loading$: Subscription;
  dataForm$: Subscription;
  cliente$: Subscription;
  producto$: Subscription;
  productoscliente$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _clienteService: ClienteService,
    private store: Store<PavsoState>,
    private _productService: ProductoService
  ) {
    this.codificacionProductoCliente = new codificacionProductoCliente();
    this.codificacionProductoCliente.detalle = [];

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
    console.log("soy unnumero 2", this.toInt("2"));
  }

  toInt(string: string): number {
    return +string;
  }
  toStr(number: number): string {
    return number.toString();
  }

  obtenerProductosCombo():void{
    this.producto$ = this._productService.obtenerProductosPipe().subscribe(
      productos => {
        console.log("soy productos: ", productos);
        this.comboProductos = productos.filter(producto => producto.ESTADO == 'VIGENTE');
        this.loaderData = false;

      }, error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  loadData(): void {
    this.loaderData = true;
    this.codificacionProductoClienteDetalle = [];
    this.obtenerClientes();
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
   // this.uid == '0' ? this.registrarSupervisor(f): this.actualizarSupervisor(f);
  }

  obtenerClientes(): void {
    this.cliente$ = this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes.filter(cliente => cliente.cli_indcli === 1 && cliente.cli_indsta === '1');
        console.log("soy clientes: ",this.clientes);
        this.loaderData = false;
        this.obtenerProductosCombo();

      }
    ,
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
    }
    )
  }
  obtenerProductosCliente(): void {
    // this.productoscliente$=this._almacenService.obtenerProductosCliente(this.codificacionProductoCliente.cli_codcli).subscribe(
    //   productos => {
    //     console.log("soy productos: ", productos);
    //     this.codificacionProductoCliente.detalle = productos;
    //     this.dataSource = fillTable(this.codificacionProductoCliente.detalle, this.paginator, this.sort);
    //   }, error => {
    //     this._snackBarService.showError(error.error.msg, 'Ok');
    //   }
    // )
  }
  agregarProductoCliente(): void {
    if(!this.codificacionProductoCliente.cli_codcli) return this._snackBarService.showError('Debe seleccionar un cliente', 'Ok');
      let item = new codificacionProductoClienteDetalle();

      this.codificacionProductoCliente.detalle.push(item);
      console.log("soy detalle: ", this.codificacionProductoCliente.detalle);
      this.dataSource = fillTable(this.codificacionProductoCliente.detalle, this.paginator, this.sort);
  }

  quitarProductoCliente(index: number): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        this.codificacionProductoCliente.detalle.splice(index, 1);
        this.dataSource = fillTable(this.codificacionProductoCliente.detalle, this.paginator, this.sort);
      }
    })

  }

  registrarSupervisor(f: NgForm): void {
    // this.sendForm$ = this._ventasService.registrarSupervisor(this.supervisor).subscribe(
    //   response => {
    //     this._dialogService.openDialog(SuccessComponent, 'Supervisor registrado', '400px', '400px', '');
    //     this.loaderReg = false;
    //   },
    //   error => {
    //     this._snackBarService.showError(error.error.msg, 'Ok');
    //     this.loaderReg = false;
    //   }
    // )
  }
  actualizarSupervisor(f: NgForm): void {
    // this.sendForm$ = this._ventasService.actualizarSupervisor(this.supervisor).subscribe(
    //   response => {
    //     this._dialogService.openDialog(SuccessComponent, 'Supervisor actualizado', '400px', '400px', '');
    //     this.loaderReg = false;
    //   },
    //     error => {
    //     this._snackBarService.showError(error.error.msg, 'Ok');
    //     this.loaderReg = false;
    //   }
    // )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.dataForm$,
      this.loading$
    ])
  }

}
