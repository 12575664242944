<app-pavso-title-section [title]="'Configuración deducción'" [module]="'Planilla'" [estado]="configuracion.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Concepto</mat-label>
            <mat-select [(ngModel)]="configuracion.codnco" name="codnco">
              <mat-option *ngFor="let concepto of conceptos" [value]="concepto.codnco">
                {{concepto.descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>% Aplicado</mat-label>
            <input class="pav-input-align-right" [(ngModel)]="configuracion.pornde" min="0" max="100" name="pornde" type="number" matInput placeholder="% Aplicado">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>% Aplicado 2 (EPS / Vida ley)</mat-label>
            <input class="pav-input-align-right" [(ngModel)]="configuracion.porotr" min="0" max="100" name="porotr" type="number" matInput placeholder="% Aplicado 2 (EPS / Vida Ley)">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Importe base min.</mat-label>
            <input class="pav-input-align-right" type="number" [(ngModel)]="configuracion.basmin" min="0" name="basmin" matInput placeholder="Importe Base Min.">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <button class="pav-button-other" mat-flat-button color="primary" type="button">
            Copiar configuración a otros meses
          </button>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="pav-table-header-container">
      <div class="pav-table-header-icon"></div>
      <div class="pav-table-header-title">
        Configura cuentas contable por nómina
      </div>
      <div class="pav-table-header-add">
        <div class="isMobile">
          <div class="pav-button-icon-add" (click)="agregarItem()">
            <mat-icon svgIcon="add"></mat-icon>
          </div>
        </div>
        <div class="isWeb">
          <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarItem()">Agregar ítem</button>
        </div>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" matSort >
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" data-label="Código">
          <div class="pav-btns-container">
            <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
            <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
              <mat-icon svgIcon="confirm"></mat-icon>
            </div>
            <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
              <mat-icon svgIcon="delete"></mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="nti_codnti">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nómina </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Nómina" class="td-responsive">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
                <mat-label>Nómina</mat-label>
                <mat-select required (selectionChange)="seleccionarTipo(row)" [(ngModel)]="row.codnti" [name]="'codnti' + i.toString()">
                  <mat-option *ngFor="let tipo of tipos" [value]="tipo.nti_codnti">
                    {{tipo.nti_codnti}} | {{tipo.nti_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codnti}} | {{row.desnti}}
            </ng-container>
          </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codccn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta" class="td-responsive">
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
              <mat-label>Cuenta Gasto</mat-label>
              <mat-select required (selectionChange)="seleccionarCuenta(row)" [(ngModel)]="row.codccn" [name]="'codccn' + i.toString()">
                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                  {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{row.codccn}} | {{row.desccn}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codcco">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta x Pagar </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta x Pagar" class="td-responsive">
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
              <mat-label>Cuenta x Pagar</mat-label>
              <mat-select required (selectionChange)="seleccionarCuentaPagar(row)" [(ngModel)]="row.codcco" [name]="'codcco' + i.toString()">
                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                  {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{row.codcco}} | {{row.descco}}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codliq">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Liquid. </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta x Pagar" class="td-responsive">
          <ng-container *ngIf="row.isEditing">
            <mat-form-field class="mat-form-field-item-concepto pav-mat-form-margin-top">
              <mat-label>Cta. Liquidación</mat-label>
              <mat-select required (selectionChange)="seleccionarCuentaLiquidacion(row)" [(ngModel)]="row.codliq" [name]="'codliq' + i.toString()">
                <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                  {{cuenta.cuenta}} | {{cuenta.descri_cuenta}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!row.isEditing">
            {{row.codliq}} | {{row.desliq}}
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">No se encontraron registros</td>
      </tr>

    </table>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-planilla/configuracion-deduccion">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
