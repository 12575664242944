import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { Familia, Subfamilia, TipoInventario } from "src/app/models/almacen/familia";
import { ConfigurationService, ContabilidadService } from "src/app/services";
import { FamiliaService } from "src/app/services/api/almacen/maestros/familia.service";

@Component({
  selector: 'familia-form',
  templateUrl: './familia-form.component.html',
  styleUrls: ['./familia-form.component.scss']
})
export class FamiliaFormComponent implements OnInit, OnDestroy {

  loaderData: boolean;
  loaderReg: boolean;
  familia: Familia;

  uid: string;

  selectedSubfamilia: any;

  selectRowSubfamilia(row: any) {
    this.selectedSubfamilia = row;
  }

  selectedTipoInventario: any;

  selectRowTipoInventario(row: any) {
    this.selectedTipoInventario = row;
  }

  cuentas: any[] = [];

  sendForm$: Subscription;
  familia$: Subscription;
  cuentas$: Subscription;

  isMobile: boolean = false;
  isTablet: boolean = false;

  displayedColumnsSubfamilia: string[] = ['codigo', 'descripcion'];
  dataSourceSubfamilia: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorSubfamilia: MatPaginator;
  @ViewChild(MatSort) sortSubfamilia: MatSort;

  displayedColumnsTiposInventario: string[] = ['codigo', 'descripcion'];
  dataSourceTiposInventario: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorTiposInventario: MatPaginator;
  @ViewChild(MatSort) sortTiposInventario: MatSort;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _familiaService: FamiliaService,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _contabilidadService: ContabilidadService,
    private _configurationService: ConfigurationService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.familia = new Familia();
    this.familia.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.familia.FPR_CODUSE = this._configurationService.obtenerIdUsuario();
    this.dataSourceSubfamilia = fillTable([], this.paginatorSubfamilia, this.sortSubfamilia);
    this.dataSourceTiposInventario = fillTable([], this.paginatorTiposInventario, this.sortTiposInventario);
  }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this.uid = params.id;
    })

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

    this.loadData();
  }

  loadData(): void {

    this.loaderData = true;

    this.loadMaestros()

  }

  loadMaestros(): void {
    this.cuentas$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
      cuentas => {
        this.cuentas = cuentas;
        console.log('cuentas', this.cuentas)
        if (this.uid != '0') {
          this.obtenerFamilia();
          return
        }

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener cuentas', 'Ok');
      }
    )
  }

  obtenerFamilia(): void {
    this.familia$ = this._familiaService.obtenerFamiliaProducto(this.uid).subscribe(
      response => {
        console.log('familia', response)
        this.familia = response;
        this.dataSourceSubfamilia = fillTable(this.familia.subfamilia, this.paginatorSubfamilia, this.sortSubfamilia);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener familia', 'Ok');
        this.loaderData = false;
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarFamilia() : this.actualizarFamilia();
  }

  typeDescLarga(): void {
    if(this.uid == '0')
    this.familia.FPR_DESABR = this.familia.FPR_DESCRI;
  }

  registrarFamilia(): void {
    this.sendForm$ = this._familiaService.registrarFamiliaProducto(this.familia).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Familia registrada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al registrar familia', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarFamilia(): void {
    this.sendForm$ = this._familiaService.actualizarFamiliaProducto(this.familia).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Familia actualizada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al actualizar familia', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  agregarSubfamilia(): void {
    const subfamillia = new Subfamilia();
    subfamillia.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    subfamillia.sfp_coduse = this._configurationService.obtenerIdUsuario();
    console.log('subfamilia', subfamillia)
    this.familia.subfamilia.push(subfamillia);

    this.dataSourceSubfamilia = fillTable(this.familia.subfamilia, this.paginatorSubfamilia, this.sortSubfamilia);
    console.log('familia despues', this.familia)
  }

  eliminarSubfamilia(): void {}

  agregarTipoInventario(): void {
    const tipoInventario = new TipoInventario();

    this.familia.tiposInventario.push(tipoInventario);

    this.dataSourceTiposInventario = fillTable(this.familia.tiposInventario, this.paginatorTiposInventario, this.sortTiposInventario);
  }

  eliminarTipoInventario(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([this.sendForm$])
  }
}
