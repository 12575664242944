import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(
        private http: HttpClient,
        private _configurationService: ConfigurationService
    ) {}

    /**
     *
     * @param year
     * @returns
     */
    obtenerDashboard(year): Observable<any> {

      return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/pedidos/mostrardatospanel/${this._configurationService.obtenerCompaniaCliente()}/${year}`);

    }

    /**
     *
     * @param year
     * @returns
     */
    graficoVentas(year): Observable<any> {

      return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/prueba/vistaventas/${this._configurationService.obtenerCompaniaCliente()}/${year}`);

    }

    /**
     *
     * @param mes
     * @param year
     * @returns
     */
    graficoVendedores(mes, year): Observable<any> {

      return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/prueba/vistavendedores/${this._configurationService.obtenerCompaniaCliente()}/${mes}/${year}`);

    }

    /**
     *
     * @param year
     * @returns
     */
    graficoComprobantes(year): Observable<any> {

      return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/prueba/vistacomprobantes/${this._configurationService.obtenerCompaniaCliente()}/${year}`);

    }

    /**
     *
     * @param ano
     * @returns
     */
    obtenerDasboardNomina(ano): Observable<any> {
      return this.http.get<any>(`${this._configurationService.obtenerUrl()}/api/dashboard/nomina/${this._configurationService.obtenerCompaniaCliente()}/${ano}`);
    }

}
