import { Component, OnDestroy, OnInit } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { CostosService } from "src/app/services";

@Component({
  selector: 'grafico-costo-unitario',
  templateUrl: './grafico-costo-unitario.component.html',
  styleUrls: ['./grafico-costo-unitario.component.scss']
})
export class GraficoCostoUnitarioComponent implements OnInit, OnDestroy{

  year: string;
  month: string;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _costosService: CostosService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerGraficoCostoUnitarioProducto();
    })

  }

  obtenerGraficoCostoUnitarioProducto() {
    this._costosService.obtenerGraficoCostoUnitarioProductoDia(this.year, this.month).subscribe(
      response => {
      },
      error => {
        this._snackBarService.showError("Error al obtener grafico de costo unitario de producto", "OK");
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }

}
