export class ClaseActivo {
  CTA_CODCTA  : string;
  CTA_DESCRI  : string;
  CTA_INDSTA  : string;
  CTA_CODUSE  : string;
  CTA_FECUPD  : Date;

  constructor() {
    this.CTA_INDSTA = '1';
    this.CTA_FECUPD = new Date();
  }
}
