<app-pavso-title-section [title]="'Consulta de saldos mensuales'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l8">
          <select-search [label]="'Cuenta'" [placeholder]="'Ej. CAJA CHICA'" [name]="'cuenta'"
            [description]="'description'" [data]="cuentas" [value]="'cuenta'" [required]="true"
            (cambioSelect)="filtro.cuenta = $event; seleccionarCuenta()" [model]="filtro.cuenta">
          </select-search>

          <!-- <mat-form-field>
            <mat-label>Cuenta Contable</mat-label>
            <mat-select [(ngModel)]="cuenta" name="cuenta" (selectionChange)="seleccionarCuentaContable($event.value)">
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.cuenta}} - {{cuenta.descri_cuenta}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select [(ngModel)]="filtro.moneda" name="moneda">
              <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
                {{moneda.tmo_deslar}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <button [disabled]="loaderData" (click)="buscarRegistros()" type="button" mat-mini-fab color="primary"
            [ngStyle]="{background: '#0368c1'}" aria-label="Example icon button with a menu icon" matTooltip="Buscar">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>
      <div class="pav-separator"></div>

      <div class="pav-button-icon-add" matTooltip="Ver detalle" (click)="consultarMovimientos()"
        [ngStyle]="{display: loaderData? 'none': ''}">
        <mat-icon svgIcon="organization-chart-icon"></mat-icon>
      </div>
    </div>
    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="mes_desmes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
          <td mat-cell *matCellDef="let row" data-label="Mes"> {{row.mes_desmes}} </td>
        </ng-container>

        <ng-container matColumnDef="scu_salini">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo Inicial </th>
          <td mat-cell *matCellDef="let row" data-label="Saldo Inicial">
            <div [ngStyle]="{width: '80px', textAlign: 'right'}">

              {{formatoImporte(row.scu_salini)}}

            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="scu_debmna">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Debe </th>
          <td mat-cell *matCellDef="let row" data-label="Debe">
            <div [ngStyle]="{width: '80px', textAlign: 'right'}">
              {{formatoImporte(row.scu_debmna)}}

            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="scu_habmna">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Haber </th>
          <td mat-cell *matCellDef="let row" data-label="Haber">
            <div [ngStyle]="{width: '80px', textAlign: 'right'}">
              {{formatoImporte(row.scu_habmna)}}

            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="saldo_final">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo Final </th>
          <td mat-cell *matCellDef="let row" data-label="Saldo Final">
            <div [ngStyle]="{width: '80px', textAlign: 'right'}">
              {{formatoImporte(row.saldo_final)}}

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
        (click)="selectRow(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No se encontraron registros</td>
        </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[25, 100]"></mat-paginator>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s3">
          <mat-form-field>
            <mat-label>Total Saldo Inicial</mat-label>
            <input readonly matInput [value]="formatoImporte(totalSaldoInicial)" name="totalSaldoInicial"
              [ngStyle]="{textAlign: 'right'}" placeholder="Total Saldo Inicial">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Total Debe</mat-label>
            <input readonly matInput [value]="formatoImporte(totalDebe)" name="totalDebe" placeholder="Total Debe"
              [ngStyle]="{textAlign: 'right'}">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Total Haber</mat-label>
            <input readonly matInput [value]="formatoImporte(totalHaber)" name="totalHaber" placeholder="Total Haber"
              [ngStyle]="{textAlign: 'right'}">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Total Saldo Final</mat-label>
            <input readonly matInput [value]="formatoImporte(totalSaldoFinal)" name="totalSaldoFinal"
              [ngStyle]="{textAlign: 'right'}" placeholder="Total Saldo Final">
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>

</div>
