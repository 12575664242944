export class Afp {
  codcia: string;
  codafp: string;
  descri: string;
  coduse: string;
  fecupd: Date;
  porcom: number;
  indsta: string;
  codcli: string;
  codini: string;
  exconp: number;
  codano: string;
  codmes: string;
  AFP_DETA_NAF: Array<AfpDetaNaf>;

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
    this.AFP_DETA_NAF = [];
  }

}

export class AfpDetaNaf {
  codcia: string;
  codano: string;
  codmes: string;
  codafp: string;
  codnco: string;
  porafp: number | string;
  topafp: number;
  codccn: string;
  desnco: string;
  isEditing: boolean;
  correlativo: any;

  constructor() {
    this.isEditing = true;
    this.topafp = 0;
  }
}
