<app-pavso-title-section [title]="'Asientos contables'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div>
      <mat-form-field style="width: auto;">
        <mat-label>Sudiario Contable</mat-label>
        <mat-select (selectionChange)="seleccionarSubdiario($event.value)">
          <mat-option value="All">TODOS</mat-option>
          <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
            {{subdiario.sco_descri}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>

  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los asientos" url="/modulo-contabilidad/asiento-contable" entity="" [params]="['tipoMovimiento', '0', '0', '0', '0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-contabilidad/asiento-contable', '', row.ANO_CODANO, row.MES_CODMES, row.SCO_CODSCO, row.LDC_CORLDC]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                  </div>

                </td>
            </ng-container>

            <ng-container matColumnDef="ANO_CODANO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                <td mat-cell *matCellDef="let row" data-label="Año" class="td-responsive"> {{row.ANO_CODANO}} </td>
            </ng-container>

            <ng-container matColumnDef="MES_CODMES">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
                <td mat-cell *matCellDef="let row" data-label="Mes" class="td-responsive"> {{row.MES_CODMES}} </td>
            </ng-container>

            <ng-container matColumnDef="SCO_CODSCO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Subdiario </th>
                <td mat-cell *matCellDef="let row" data-label="Subdiario" class="td-responsive"> {{row.SCO_CODSCO}} </td>
            </ng-container>

            <ng-container matColumnDef="LDC_CORLDC">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo </th>
                <td mat-cell *matCellDef="let row" data-label="Correlativo" class="td-responsive"> {{row.LDC_CORLDC}} </td>
            </ng-container>

            <ng-container matColumnDef="LDC_FECDIA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let row" data-label="Fecha" class="td-responsive"> {{row.LDC_FECDIA | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="LDC_GLODIA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row" data-label="Glosa" class="td-responsive"> {{row.LDC_GLODIA}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

      </div>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
