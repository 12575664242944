import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService, VentasService, AuthenticationService } from 'src/app/services';
import { PriceList } from 'src/app/models';
import { IlumiProductListComponent } from '@shared/components/dialogs/ilumi/ilumi-product-list/ilumi-product-list.component';
import { ProductPriceList } from 'src/app/models';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-lista-precio-form',
  templateUrl: './lista-precio-form.component.html',
  styleUrls: ['./lista-precio-form.component.scss']
})
export class ListaPrecioFormComponent implements OnInit, OnDestroy {

  loaderProductos: Boolean = false;
  loaderData: boolean;
  loaderReg: boolean;
  btnName: string;

  displayedColumns: string[] = ['acciones', 'codigo', 'descripcion', 'um', 'precio_soles', 'precio_vta_soles', 'precio_dolar', 'precio_vta_dolar'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tiposListaDeprecio = [];

  priceList: PriceList;

  tipoLista$: Subscription;
  loading$: Subscription;
  listaPrecio$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;
  tipo: string;

  constructor(
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute
  ) {

    this.priceList = new PriceList();
    this.priceList.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.priceList.LPC_CODUSE = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

  }

  loadData(): void {

    this._activatedRoute.params.subscribe(params => {
      this.uid = params.id;
      this.tipo = params.type;
    })

    this.loadMaestros();

  }

  loadMaestros(): void {

    this.tipoLista$ = this._ventasService.obtenerTiposDeListaDePrecios().subscribe(
      response => {
        this.tiposListaDeprecio = response;

        if(this.uid != '0') {
          this.obtenerListaPrecio();
        }

      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de lista de precios', 'OK')
      }
    )

  }

  obtenerListaPrecio(): void {
    this.listaPrecio$ = this._ventasService.obtenerListaDePrecio(this.tipo, this.uid).subscribe(
      listaPrecio => {
        console.log('listaPrecio', listaPrecio)
        this.priceList = listaPrecio[0];
        this.priceList.detalle = this.priceList.detalle ? this.priceList.detalle : [];
        this.dataSource = fillTable(this.priceList.detalle, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError('Error al obtener lista de precio', 'OK')
        this.loaderData = false;

      }
    )
  }

  enviarFomulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarListaDePrecio(f) : this.actualizarListaDePrecio(f);
  }

  registrarListaDePrecio(f: NgForm): void {

    if (this.priceList.detalle.length == 0) {
      this._snackBarService.showError('Agregar al menos un producto', 'OK');
      this.loaderReg = false;
      return;
    }

    this._ventasService.registrarListaDePrecio(this.priceList).subscribe(
      response => {

        this._dialogService.openDialog(SuccessComponent, 'Lista de Precio Registrado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )
  }

  actualizarListaDePrecio(f: NgForm): void {
    console.log('actualizar')
    if (this.priceList.detalle.length == 0) {
      this._snackBarService.showError('Agregar al menos un producto', 'OK');
      this.loaderReg = false;
      return;
    }

    this._ventasService.editarListaDePrecio(this.priceList).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Lista de Precio Registrado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )
  }

  onFileChange(ev): void {

    let workBook = null;
    let jsonData = null;

    const reader = new FileReader();
    const file = ev.target.files[0];

    reader.onload = (event) => {

      const data = reader.result;

      workBook = XLSX.read(data, { type: 'binary' });

      jsonData = workBook.SheetNames.reduce((initial, name) => {

        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;

      }, {});


      for (let index = 0; index < jsonData.Hoja1.length; index++) {

        const element = jsonData.Hoja1[index];

        let producto = new ProductPriceList();

        producto.prd_codprd = element.prd_codprd;
        producto.prd_desesp = element.prd_desesp;
        producto.LPD_PRENAC = 0;
        producto.LPD_VTANAC = element.LPD_VTANAC;
        producto.LPD_PREDOL = 0;
        producto.LPD_VTADOL = element.LPD_VTADOL;
        producto.LPD_INDEXC = 0;
        producto.LPD_PRMNAC = 0;
        producto.LPD_PRMDOL = 0;
        producto.LPD_INDSTA = "";
        producto.LPD_CODUSE = this._authService.getUsuarioSistema();
        producto.LPD_FECUPD = new Date();
        producto.LPD_SITLPD = null;
        producto.um = element.ume_codume;

        this.priceList.detalle.push(producto);

        this.dataSource = fillTable(this.priceList.detalle, this.paginator, this.sort);
      }

    }

    reader.readAsBinaryString(file);

  }

  obtenerTipoDePrecioDeLista(): void {

    this._ventasService.obtenerTiposDeListaDePrecios().subscribe(
      response => {
        this.tiposListaDeprecio = response;
      },
    )

  }

  agregarProducto(): void {
    const dialogRef = this.dialog.open(IlumiProductListComponent, {
      width: '800px',
      data: {},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let existeProducto = false;

        this.priceList.detalle.forEach(element => {
          if (result.prd_codprd == element.prd_codprd) existeProducto = true;
        });

        if (!existeProducto) {

          let producto = new ProductPriceList();

          producto.prd_codprd = result.prd_codprd;
          producto.prd_desesp = result.prd_desesp;
          producto.LPD_PRENAC = 0;
          producto.LPD_VTANAC = 0;
          producto.LPD_PREDOL = 0;
          producto.LPD_VTADOL = 0;
          producto.LPD_INDEXC = 0;
          producto.LPD_PRMNAC = 0;
          producto.LPD_PRMDOL = 0;
          producto.LPD_INDSTA = "";
          producto.LPD_CODUSE = this._authService.getUsuarioSistema();
          producto.LPD_FECUPD = new Date();
          producto.LPD_SITLPD = null;
          producto.um = result.ume_codume;

          this.priceList.detalle.push(producto);

          this.dataSource = fillTable(this.priceList.detalle, this.paginator, this.sort);
        }
      }

    })
  }

  /**
   *
   * @param producto_id
   */
  eliminarItem(producto_id): void {

    let productos = [];
    this.priceList.detalle.forEach(element => {
      if (element.prd_codprd != producto_id) productos.push(element)
    });

    this.priceList.detalle = productos;

    this.dataSource = fillTable(this.priceList.detalle, this.paginator, this.sort);

  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/lista-de-precios']);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoLista$,
      this.loading$
    ])
  }
}
