import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService, ComprasService, VentasService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';
import { Auxiliar, BancosCBP } from 'src/app/models/contabilidad/auxiliar';
import { AuxiliarService } from 'src/app/services/api/contabilidad/maestros/auxiliar.service';
import { MatDialog } from '@angular/material/dialog';
import { AuxiliarConsultaDocDialog } from '../auxiliar-consulta-doc-dialog/auxiliar-consulta-doc-dialog.component';
import { ServiceSupplier } from 'src/app/models/compras/provider';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { DialogService } from '@shared/services/dialog.service';
import { ClienteContactoFormDialog, ClienteEstablecimientoFormDialog } from 'src/app/admin/apps/ventas/pages/maestros/cliente';
import { CargoService } from 'src/app/services/api/nomina/maestros/cargo.service';
import { ContefactService } from 'src/app/services/api/others/contefact.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { CondicionPagoService } from 'src/app/services/api/compras/maestros/condicion-pago.service';

@Component({
  selector: 'app-auxiliar-form',
  templateUrl: './auxiliar-form.component.html',
  styleUrls: ['./auxiliar-form.component.css']
})
export class AuxiliarFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() id: string;
  @Input() search: string;
  @Input() isDialog: boolean = false;

  loaderReg: boolean = false;
  loaderData: boolean = false;
  loaderBuscarRUC: boolean = false;

  driver: Driver;

  tiposPersona: any[] = [];
  tiposDocumento: any[] = [];
  paises: any[] = [];
  ubicaciones: any[] = [];
  condiciones: any[] = [];
  ubigeos: any[] = [];
  zonas: any[] = [];
  cargos: any[] = [];
  servicios: any[] = [];
  ubicacionesSunat: any[] = [];

  fecha: Date = new Date();
  usuario: any;

  displayedColumnsServicios: string[] = ['acciones', 'codigo'];
  dataSourceServicios: MatTableDataSource<any>;

  @ViewChild('paginatorServicios') paginatorServicios: MatPaginator;
  @ViewChild(MatSort) sortServicios: MatSort;

  displayedColumnsContactos: string[] = ['acciones', 'codigo', 'nombre', 'cargo', 'telefono', 'correo', 'fecha_nacimiento', 'referencia', 'cobranza'];
  dataSourceContactos: MatTableDataSource<any>;

  @ViewChild("paginatorContactos") paginatorContactos: MatPaginator;
  @ViewChild(MatSort) sortContactos: MatSort;

  displayedColumnsEstablecimientos: string[] = ['acciones', 'codigo', 'principal', 'direccion', 'referencia', 'pais', 'ubicacion', 'zona'];
  dataSourceEstablecimientos: MatTableDataSource<any>;

  @ViewChild("paginatorEstablecimientos") paginatorEstablecimientos: MatPaginator;
  @ViewChild(MatSort) sortEstablecimientos: MatSort;

  displayedColumnsCuenta: string[] = ['acciones', 'codigo', 'banco', 'moneda', 'nro_cuenta', 'cuenta_interbancaria', 'glosa'];
  dataSourceCuenta: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorCuenta: MatPaginator;
  @ViewChild(MatSort) sortCuenta: MatSort;

  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;

  auxiliar: Auxiliar;

  tiposPersona$: Subscription;
  tiposDocumento$: Subscription;
  paises$: Subscription;
  ubicaciones$: Subscription;
  condiciones$: Subscription;
  ubigeos$: Subscription;
  obtenerAuxiliar$: Subscription;
  zonas$: Subscription;
  cargos$: Subscription;
  sendForm$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _comprasService: ComprasService,
    private _condicionPagoService: CondicionPagoService,
    private _generalService: GeneralService,
    private _clienteService: ClienteService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _cargoService: CargoService,
    private _auxiliarService: AuxiliarService,
    private _configurationService: ConfigurationService,
    private _contefactService: ContefactService,
    private store: Store<PavsoState>,
    public dialog: MatDialog
  ) {
    this.auxiliar = new Auxiliar();

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSourceServicios = fillTable([], this.paginatorServicios, this.sortServicios);

    this.dataSourceContactos = fillTable([], this.paginatorContactos, this.sortContactos);

    this.dataSourceCuenta = fillTable([], this.paginatorCuenta, this.sortCuenta);

    this.dataSourceEstablecimientos = fillTable([], this.paginatorEstablecimientos, this.sortEstablecimientos);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id.currentValue && changes.id.currentValue != '0') {
      console.log('ID DESDE AUXILIAR', changes.id)
      this.uid = changes.id.currentValue;
      this.auxiliar.cli_codcli = changes.id.currentValue;
      if (this.uid != '0' && this.uid != undefined) this.obtenerAuxiliar();

    }

    if (changes.search) {

      console.log('changes')
      const search = changes.search.currentValue;
      if (isNaN(parseInt(search))) return;

      if (search.length == 11) {
        this.auxiliar.cli_codcli = search;
        this.auxiliar.cli_tipper = "J";
        this.auxiliar.did_coddid = '001';
        this.buscarEnRENIEC()
      }

      if (search.length == 8) {
        this.auxiliar.cli_codcli = search;
        this.auxiliar.cli_tipper = "N";
        this.auxiliar.did_coddid = '000';
        this.buscarEnRENIEC()
      }
    }
  }

  buscarEnRENIEC(): void {
    this.loaderBuscarRUC = true;
    console.log('this.cliente.cli_codcli', this.auxiliar.cli_codcli)
    if (!this.auxiliar.cli_codcli) {
      this._snackBarService.showError('Ingresar códiigo de búsqueda', 'Ok');
      this.loaderBuscarRUC = false;
      return;
    }

    this._contefactService.obtenerDatosRUCCONTEFACT(this.auxiliar.cli_codcli).subscribe(
      response => {
        console.log('response cliente', response)
        this.loaderBuscarRUC = false;

        if (response.ruc && response.ruc != '') {
          this.auxiliar = Auxiliar.fromCONEFACTToAuxiliar(response);

          this.auxiliar.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          this.auxiliar.cli_coduse = this._configurationService.obtenerIdUsuario();

          this.auxiliar.pai_codpai = '01';
          const ubicacion = this.ubicaciones.find(item => item.desubi == `${response.departamento}-${response.provincia}-${response.distrito}`);
          const ubigeo = this.ubicacionesSunat.find(item => item.ubs_descri == `${response.departamento}, ${response.provincia}, ${response.distrito}`);
          console.log('ubicacion', ubicacion)
          console.log('ubigeo', ubigeo)
          this.auxiliar.ubi_codubi = ubicacion.codubi;
          this.auxiliar.ubs_codubs = ubigeo.ubs_codubs;

          this.loaderBuscarRUC = false;
        }
      },
      error => {
        this._snackBarService.showError('No se encontraron datos para este documento', 'Ok');
        this.loaderBuscarRUC = false;
      }
    )
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
      this.loadMaestros();

    })
  }

  loadMaestros(): void {

    this.tiposPersona$ = this._generalService.listarTipoDePersona().subscribe(
      tiposPersona => {
        this.tiposPersona = tiposPersona;

        this.paises$ = this._generalService.listarPaises().subscribe(
          paises => {
            this.paises = paises;

            this.ubicaciones$ = this._generalService.listarUbicacion('01').subscribe(
              ubicaciones => {
                this.ubicaciones = ubicaciones;

                this._generalService.listarUbigeos().subscribe(
                  ubicacionesSunat => {
                    this.ubicacionesSunat = ubicacionesSunat;

                    this.condiciones$ = this._condicionPagoService.obtenerCondicionesPago().subscribe(
                      condiciones => {
                        this.condiciones = condiciones;

                        this.ubigeos$ = this._generalService.listarUbigeos().subscribe(
                          ubigeos => {
                            this.ubigeos = ubigeos;

                            this.tiposDocumento$ = this._generalService.listarTipoDocumentoIdentidad().subscribe(
                              tiposDocumento => {

                                this.tiposDocumento = tiposDocumento;
                                console.log('tiposDocumento', tiposDocumento)

                                this.zonas$ = this._ventasService.obtenerZonasVenta().subscribe(
                                  zonas => {
                                    this.zonas = zonas;

                                    this.cargos$ = this._cargoService.listarCargos().subscribe(
                                      cargos => {
                                        this.cargos = cargos;
                                        if (this.uid != '0' && this.uid != undefined) {
                                          this.obtenerAuxiliar();
                                          return;
                                        }

                                        this.loaderData = false;
                                      },
                                      error => {
                                        this._snackBarService.showError('Error al obtener cargos', 'OK');
                                        this.loaderData = false;

                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener zonas de venta', 'OK');
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener tipos de documento de identidad', 'OK');
                                this.loaderData = false;
                              }
                            )

                          },
                          error => {
                            this._snackBarService.showError('Error al obtener ubigeos', 'OK');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener condiciones de pago', 'OK');
                        this.loaderData = false;

                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener ubigeos', 'OK');
                    this.loaderData = false;
                  }
                )

              },
              error => {
                this._snackBarService.showError('Error al obtener ubicaciones', 'OK');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener paises', 'OK');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de persona', 'OK');
        this.loaderData = false;
      }
    )

  }

  ingresoCodigo(value): void {
    if (this.auxiliar.cli_tipper == "J") {
      if (this.auxiliar.did_coddid == "001") {
        if (value.target.value.length == 11) {
          this.auxiliar.cli_numruc = value.target.value;
        }
      }
      return;
    }

    this.auxiliar.cli_numdni = value.target.value;

  }

  consultarNroDocumento(): void {

    if (!this.auxiliar.cli_tipper) {
      this._snackBarService.showError('Debe seleccionar el tipo de persona', 'OK');
      return;
    }

    const dialogRef = this.dialog.open(AuxiliarConsultaDocDialog, {
      data: { cli_tipper: this.auxiliar.cli_tipper },
    });

    dialogRef.afterClosed().subscribe((auxiliar: Auxiliar) => {
      if (auxiliar) {
        console.log('auxiliar', auxiliar)
        this.auxiliar = auxiliar;
      }
    });

  }

  obtenerAuxiliar(): void {
    this.obtenerAuxiliar$ = this._auxiliarService.obtenerAuxiliar(this.uid).subscribe(
      auxiliar => {
        this.auxiliar = auxiliar;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener auxiliar', 'OK');
        this.loaderData = false;
      }
    )

  }

  seleccionarTipoPersona(valor): void {
    console.log('seleccionar', valor)
    if (valor == 'J') {
      this.auxiliar.did_coddid = '001';
      return;
    }

    this.auxiliar.did_coddid = '000';
  }

  seleccionarTipoDocumento(value): void {
    if (value == "001") {
      this.auxiliar.cli_tipper = "J";
      return;
    }
    this.auxiliar.cli_tipper = "N";

  }

  seleccionarServicio($event, row: ServiceSupplier): void {
    const servicio = this.servicios.find(item => item.TSE_CODTSE == $event.value);
    row.tse_descri = servicio.TSE_DESTSE;
  }

  validarDni(): void {
    let dniStr = this.auxiliar.cli_numdni.toString();

    if (dniStr.length > 8) this.auxiliar.cli_numdni = dniStr.substr(0, dniStr.length - 1);
  }

  validarRuc(): void {

    let rucStr = this.auxiliar.cli_numruc.toString();

    if (rucStr.length > 11) this.auxiliar.cli_numruc = rucStr.substr(0, rucStr.length - 1);

  }

  agregarCuenta(): void {
    const cuentaBancaria = new BancosCBP();

    this.auxiliar.detalle_bancos_cbp.push(cuentaBancaria);

    this.dataSourceCuenta = fillTable(this.auxiliar.detalle_bancos_cbp, this.paginatorCuenta, this.sortCuenta);

    this.auxiliar.detalle_bancos_cbp.forEach((element, key) => {
      element.cbp_corcbp = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    console.log('proveedor.detalle_cuenta_bancaria', this.auxiliar.detalle_bancos_cbp)

  }

  quitarCuenta(row): void { }

  agregarContacto(contacto): void {

    const dialogRef = this.dialog.open(ClienteContactoFormDialog, {
      data: { contacto, cargos: this.cargos, cliente: this.auxiliar },
      width: '750px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(({ contacto, esNuevo }) => {
      console.log('contacto', contacto)
      console.log('esNuevo', esNuevo)
      if (esNuevo) {
        const cargo = this.cargos.find(item => item.cca_codcca == contacto.cca_codcca);
        contacto.cca_desabr = cargo ? cargo.cca_descri : "";

        this.auxiliar.detalle_contacto.push(contacto);
        this.dataSourceContactos = fillTable(this.auxiliar.detalle_contacto, this.paginatorContactos, this.sortContactos);
        return;
      }

      this.auxiliar.detalle_contacto.forEach(item => {
        if (item.ccl_codccl == contacto.ccl_codccl) {
          item = contacto;
        }
      })
    });

  }

  quitarContacto(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceContactos.data.filter(item => item.ccl_codccl != row.ccl_codccl);
        filtered.forEach((element, key) => {
          element['ccl_codccl'] = key;
        })
        this.auxiliar.detalle_contacto = filtered;
        this.dataSourceContactos = fillTable(this.auxiliar.detalle_contacto, this.paginatorContactos, this.sortContactos);
      }
    })
  }

  agregarEstablecimiento(establecimiento): void {

    console.log('establecimiento', establecimiento)
    const dialogRef = this.dialog.open(ClienteEstablecimientoFormDialog, {
      data: { establecimiento, paises: this.paises, ubicaciones: this.ubicaciones, zonas: this.zonas, cliente: this.auxiliar },
      width: '750px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(({ establecimiento, esNuevo }) => {
      console.log('establecimiento', establecimiento)
      if (esNuevo) {
        const pais = this.paises.find(item => item.PAI_CODPAI == establecimiento.PAI_CODPAI);
        establecimiento.PAI_DESPAI = pais ? pais.pai_nomlar : '';
        console.log('pais', pais)
        const ubicacion = this.ubicaciones.find(item => item.codubi == establecimiento.UBI_CODUBI);
        establecimiento.UBI_DESUBI = ubicacion ? ubicacion.desubi : '';
        console.log('ubicacion', ubicacion)
        const zona = this.zonas.find(item => item.zve_codzve == establecimiento.ZVE_CODZVE);
        establecimiento.ZVE_DESZVE = zona ? zona.zve_nomzve : '';
        console.log('zona', zona)
        console.log('establecimiento', establecimiento);
        this.auxiliar.detalle_lugar_despacho.push(establecimiento);
        this.dataSourceEstablecimientos = fillTable(this.auxiliar.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
        return;
      }

      this.auxiliar.detalle_lugar_despacho.forEach(item => {
        if (item.LDE_CODLDE == establecimiento.LDE_CODLDE) {
          item = establecimiento;
        }
      })

    });

  }

  confirmarEstablecimiento(row): void {
    if (!row.LDE_CODLDE || !row.LDE_DIRLDE || !row.LDE_REFDIR || !row.PAI_CODPAI || !row.UBI_CODUBI || !row.ZVE_CODZVE) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarEstablecimiento(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceEstablecimientos.data.filter(item => item.LDE_CODLDE != row.LDE_CODLDE);
        filtered.forEach((element, key) => {
          element['LDE_CODLDE'] = key;
        })
        this.auxiliar.detalle_lugar_despacho = filtered;
        this.dataSourceEstablecimientos = fillTable(this.auxiliar.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
      }
    })
  }

  agregarServicio(): void {

    let item = new ServiceSupplier();

    this.auxiliar.detalle_tipser_prov.push(item);

    this.auxiliar.detalle_tipser_prov.forEach((element, key) => {
      element['correlativo'] = key;
    })

    this.dataSourceServicios = fillTable(this.auxiliar.detalle_tipser_prov, this.paginatorServicios, this.sortServicios);
  }

  editarServicio(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarServicio(row: ServiceSupplier): void {
    if (!row.tse_codtse) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarServicio(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceServicios.data.filter(item => item.tse_codtse != row.tse_codtse);

        this.auxiliar.detalle_tipser_prov = filtered;
        this.dataSourceServicios = fillTable(filtered, this.paginatorServicios, this.sortServicios);
      }
    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarAuxiliar(f) : this.actualizarAuxiliar(f);
  }

  registrarAuxiliar(f: NgForm): void {

    console.log(this.auxiliar);

    this.loaderReg = true;

    this.auxiliar.dato_clc.CLI_CODCLI = this.auxiliar.cli_codcli;

    if (this.auxiliar.cli_tipper == "J") {

      if ((this.auxiliar.cli_numruc.toString())[0] != "2" || (this.auxiliar.cli_numruc.toString())[1] != "0") {
        this._snackBarService.showError('Establecer correctamente el RUC', 'OK');
        this.loaderReg = false;
        return;
      }

    } else {

      this.auxiliar.cli_nomcli = `${this.auxiliar.cli_apepat} ${this.auxiliar.cli_apemat} ${this.auxiliar.cli_nombre}`;

    }

    this.auxiliar.cli_inddom = (this.auxiliar.pai_codpai == "01") ? 1 : 0;

    (this.auxiliar.dato_clc.CLC_INOPED) ? this.auxiliar.dato_clc.CLC_INOPED = 1 : this.auxiliar.dato_clc.CLC_INOPED = 0;

    this._clienteService.registrarCliente(this.auxiliar).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Auxiliar Registrado', '350px', '', '');
        f.resetForm();
        this.auxiliar = new Auxiliar();

        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, '')
      }
    )
  }

  actualizarAuxiliar(f: NgForm): void {
    console.log('editar cliente', this.auxiliar);
    this.sendForm$ = this._clienteService.editarCliente(this.auxiliar).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Cliente editado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al editar cliente', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/auxiliares']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
    ])
  }

}
