export class Turno {

  cia_codcia: string;
  tur_codtur: string;
  tur_destur: string;
  tur_horini: string | number;
  tur_horfin: string | number;
  tur_indsta: string;
  tur_fecupd: Date;
  tur_coduse: string;

  constructor() {
    this.tur_codtur = '';
    this.tur_indsta = '1';
    this.tur_fecupd = new Date();
  }
}
