import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';

interface Post {
  id: number;
  title: string;
  body: string;
}

@Component({
  selector: 'selector-name',
  template: `
    <div>
      <h1 *ngIf="data$ | async as myData">{{myData.title}}</h1>

    </div>
  `

})

export class LearningComponent implements OnInit {

  data$: Observable<Post>
  private http = inject(HttpClient)


  constructor() { }

  ngOnInit() {
    // this.data$ = this.http.get<Post>('http://jsonplaceholder.typicode.com/posts/1')
  }
}
