<app-pavso-title-section [title]="'Estado situación financiera'" [module]="'Libros electrónicos'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <h3>{{fecha | date: 'd/M/yyyy, h:mm a'}}</h3>

    <button mat-flat-button color="primary">
        Extraer Datos
    </button>
    &nbsp;
    <button mat-flat-button color="primary">
        Generar XML
    </button>
  </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>
      <div class="pav-separator"></div>
    </div>
    <div class="mat-elevation-z0">

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

                    <div class="pav-btns-container">
                      <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-libros-electronicos/editar-chofer', row.cli_codcli]">
                        <mat-icon svgIcon="edit-white"></mat-icon>
                      </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="fila">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
            </ng-container>

            <ng-container matColumnDef="periodo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Período </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="codigo_catalogo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código Catálogo </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="codigo_rubro">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código Rubro de Estado Financiero </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="saldo_contable">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo Contable (S/.) </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
