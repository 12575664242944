<app-pavso-title-section [title]="'Tipos de documento de identidad'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todos los tipos" url="/modulo-ventas/documento-de-identidad" entity="" [params]="['0']"></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <a matTooltip="Editar tipo documento"
              class="pav-btn-circle-edit"
              [routerLink]="['/modulo-ventas/documento-de-identidad', row.DID_CODDID]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="DID_CODDID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let row" data-label="Código"> {{row.DID_CODDID}} </td>
      </ng-container>

      <ng-container matColumnDef="DID_DESCRI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.DID_DESCRI}} </td>
      </ng-container>

      <ng-container matColumnDef="DID_INDSTA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.DID_INDSTA == '1' ? 'VIGENTE': 'ANULADO'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>
      </tr>

    </table>

    <mat-paginator color="primary" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
