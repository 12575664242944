<app-pavso-title-section [title]="'PLANILLA'" [module]="'MOVIMIENTO DE COLABORADORES'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Planilla</mat-label>
                      <input matInput [matDatepicker]="picker">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Concepto</mat-label>
                      <mat-select required name="concepto">
                          <mat-option value="1">
                              Concepto 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>

      </mat-card>
      <br>
      <mat-form-field class="pavso_input_search">
          <mat-label>{{LABELS_NAME.BTN_SEARCH}}</mat-label>
          <input matInput (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      &nbsp;

      <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSource" matSort>

              <ng-container matColumnDef="codigo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                  <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
              </ng-container>

              <ng-container matColumnDef="nombre_trabajador">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del Colaborador </th>
                  <td mat-cell *matCellDef="let row" data-label="Nombre del Colaborador"> {{row.cli_nomcli}} </td>
              </ng-container>

              <ng-container matColumnDef="importe">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                  <td mat-cell *matCellDef="let row" data-label="Importe"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="q_tiempo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Q. Tiempo </th>
                  <td mat-cell *matCellDef="let row" data-label="Q. Tiempo"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="unidad">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad </th>
                  <td mat-cell *matCellDef="let row" data-label="Unidad"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="horas">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Horas </th>
                  <td mat-cell *matCellDef="let row" data-label="Horas"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="minutos">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Minutos </th>
                  <td mat-cell *matCellDef="let row" data-label="Minutos"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="valor">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="valor_2">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor 2 </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor 2"> {{row.cli_numtlf}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="9">No se encontraron registros "{{input.value}}"</td>

              </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <br>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Total Importe</mat-label>
                      <input type="number" matInput placeholder="Total Importe" name="total_importe" disabled>
                  </mat-form-field>
              </div>
          </div>
      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
