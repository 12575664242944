import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { tieneDuplicados } from '@utils/array/array';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { Cliente } from 'src/app/models/ventas';
import { ConfigurationService, GeneralService, VentasService } from 'src/app/services';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { VendedorService } from 'src/app/services/api/ventas/maestros/vendedor.service';

@Component({
  selector: 'cliente-masivo',
  templateUrl: 'cliente-masivo.component.html',
  styleUrls: ['cliente-masivo.component.scss']
})

export class ClienteMasivoComponent implements OnInit, OnDestroy {

  loaderData: boolean = false;
  loaderReg: boolean = false;
  tablaSeleccionada: boolean = false;

  displayedColumns: string[] = [
    'acciones',
    'cli_codcli',
    'cli_tipper',
    'cli_nomcli',
    'cli_apepat',
    'cli_apemat',
    'cli_nombre',
    'did_coddid',
    'cli_numruc',
    'cli_numdni',
    'pai_codpai',
    'ubs_codubs',
    'cli_indcli',
    'cli_indpro',
    'cli_indtra',
    'cli_indgas',
    'cli_inddom',
    'cli_numcel',
    'vde_codvde',
    'tmo_codtmo',
    'clc_creasi',
    'clc_codgru'
  ];

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsClientesNoRegistrados: string[] = [
    'cli_codcli',
    'cli_nomcli',
    'cli_glocli'
  ];

  dataSourceClientesNoRegistrados: MatTableDataSource<any>;

  @ViewChild('paginatorClientesNoRegistrados') paginatorClientesNoRegistrados: MatPaginator;
  @ViewChild(MatSort) sortClientesNoRegistrados: MatSort;

  isTablet: boolean = false;
  isMobile: boolean = false;

  paises$: Subscription;
  paises: any[] = [];

  vendedores$: Subscription;
  vendedores: any[] = [];

  ubigeos$: Subscription;
  ubigeos: any[] = [];

  zonasVenta$: Subscription;
  zonasVenta: any[] = [];

  loading$: Subscription;

  totalACargar: number;
  cargados: number;
  noCargados: number;
  clientesNoRegistrados: Array<{cli_codcli: string, cli_nomcli: string, cli_glocli: string}> = [];

  constructor(
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _vendedorService: VendedorService,
    private _ventasService: VentasService,
    private _clienteService: ClienteService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit() {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this.paises$ = this._generalService.listarPaises().subscribe(
      paises => {
        this.paises = paises;

        this.vendedores$ = this._vendedorService.listarVendedores().subscribe(
          vendedores => {
            this.vendedores = vendedores;

            this.ubigeos$ = this._generalService.listarUbigeos().subscribe(
              ubigeos => {
                this.ubigeos = ubigeos;

                this.zonasVenta$ = this._ventasService.obtenerZonasVenta().subscribe(
                  zonasVenta => {
                    this.zonasVenta = zonasVenta;

                    this.loaderData = false;

                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  onPaste(event: ClipboardEvent): void {
    console.log('on paste')
    if (!this.tablaSeleccionada) return;
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.processPastedData(pastedText);

  }

  limpiar(): void {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async cargarClientes() {

    if (this.dataSource.data.length == 0) {
      this._snackBarService.showError('No existen datos a cargar', 'Ok');
      return;
    }

    this.loaderReg = true;

    const clientes = this.dataSource.data.map(client => {
      const cliente = new Cliente();
      cliente.cia_codcia = this._configurationService.obtenerCompaniaCliente();
      cliente.cli_coduse = this._configurationService.obtenerIdUsuario();

      cliente.cli_codcli = client.cli_codcli;
      cliente.cli_tipper = client.cli_tipper;
      cliente.cli_nomcli = client.cli_nomcli;
      cliente.cli_apepat = client.cli_apepat;
      cliente.cli_apemat = client.cli_apemat;
      cliente.cli_nombre = client.cli_nombre;
      cliente.cli_dircli = client.cli_dircli;
      cliente.did_coddid = client.did_coddid;
      cliente.cli_numruc = client.cli_numruc;
      cliente.cli_numdni = client.cli_numdni;
      cliente.cli_numtlf = client.cli_numtlf;
      cliente.cli_numfax = client.cli_numfax;
      cliente.cli_corele = client.cli_corele;
      cliente.cli_nomcon = client.cli_nomcon;
      cliente.cli_carcon = client.cli_carcon;
      cliente.pai_codpai = client.pai_codpai;
      cliente.ubs_codubs = client.ubs_codubs;
      cliente.zve_codzve = client.zve_codzve;
      cliente.dato_clc.TLP_CODTLP = client.tlp_codtlp;
      cliente.gno_codgno = client.gno_codgno;
      cliente.cli_indcli = client.cli_indcli;
      cliente.cli_indpro = client.cli_indpro;
      cliente.cli_indtra = client.cli_indtra;
      cliente.cli_indgas = client.cli_indgas;

      cliente.cli_inddom = client.cli_inddom;
      cliente.cli_numcel = client.cli_numcel;

      cliente.dato_clc.VDE_CODVDE = client.vde_codvde;

      cliente.dato_clc.TMO_CODTMO = client.tmo_codtmo;
      cliente.dato_clc.CLC_CREASI = client.clc_creasi;
      cliente.dato_clc.CLC_CODGRU = client.clc_codgru;

      return cliente;
    })

    console.log('clientes', clientes)

    let clienteARegistrar;
    this.totalACargar = clientes.length;
    let contador = 0;
    this.clientesNoRegistrados = [];
    for (let index = 0; index < clientes.length; index++) {
      try {
        const cliente = clientes[index];
        console.log('cliente', cliente)
        this.cargados = index + 1;
        contador++;
        clienteARegistrar = cliente;
        // await this.delay(1000);
        const resp = await this._clienteService.registrarCliente(cliente).toPromise();
        console.log('resp', resp)

      } catch (error) {
        console.log('error', error)
        this._snackBarService.showError(`Error al registrar clientes masivo ${clienteARegistrar.cli_codcli}`, 'Ok');
        clienteARegistrar.cli_glocli = error.error.msg;
        this.clientesNoRegistrados.push(clienteARegistrar);

      }
    }

    console.log({ contador })
    console.log({ clientes })
    console.log('terminado')

    this.dataSourceClientesNoRegistrados = fillTable(this.clientesNoRegistrados, this.paginatorClientesNoRegistrados, this.sortClientesNoRegistrados);
    this._snackBarService.showSuccess('Clientes registrados', 'Ok');
    this.loaderReg = false;

  }

  seleccionarTabla(): void {

    this.tablaSeleccionada = !this.tablaSeleccionada;

  }

  processPastedData(pastedText: string): void {

    const rows = pastedText.split('\n').filter(row => row.trim().length > 0);
    const newData: any[] = rows.map(row => {

      const cols = row.split('\t');
      console.log('cols', cols)
      return {
        cli_codcli: cols[0].trim(),
        cli_tipper: cols[1].trim(),
        cli_nomcli: cols[2].trim(),
        cli_apepat: cols[3].trim(),
        cli_apemat: cols[4].trim(),
        cli_nombre: cols[5].trim(),
        cli_dircli: cols[6].trim(),
        did_coddid: cols[7].trim(),
        cli_numruc: cols[8].trim(),
        cli_numdni: cols[9].trim(),
        cli_numtlf: cols[10].trim(),
        cli_numfax: cols[11].trim(),
        cli_corele: cols[12].trim(),
        cli_nomcon: cols[13].trim(),
        cli_carcon: cols[14].trim(),
        pai_codpai: cols[15].trim(),
        ubs_codubs: cols[16].trim(),
        zve_codzve: cols[17].trim(),
        tlp_codtlp: cols[18].trim(),
        gno_codgno: cols[19].trim(),
        cli_indcli: +cols[20],
        cli_indpro: +cols[21],
        cli_indtra: +cols[22],
        cli_indgas: +cols[23],
        es_proyecto: cols[24],
        cli_inddom: +cols[25],
        cli_numcel: cols[26].trim(),
        web: cols[27],
        fb: cols[28],
        vde_codvde: cols[29],
        banco: cols[30],
        soles: cols[31],
        dolares: cols[32],
        rubro: cols[33],
        tmo_codtmo: cols[34].trim(),
        clc_creasi: +cols[35],
        clc_codgru: cols[36].trim(),
      };

    });

    console.log('newData', newData)

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    };

    if ((Object.keys(newData[0]).length) != 37) {
      this._snackBarService.showError('Debe de copiar todo el contenido de las 29 filas', 'Ok');
      return
    };

    // Verificar si el codigo de cliente
    let esValidoCodigoCliente = true;
    newData.forEach(item => {
      if (item.cli_codcli.length != 8 && item.cli_codcli.length != 11) {
        console.log('item.cli_codcli', item.cli_codcli.length)
        esValidoCodigoCliente = false;
      }
    })

    // Verificar nombre del cliente
    let esValidoNombreCliente = true;
    newData.forEach(item => {
      if (item.cli_nomcli.trim() == '') {
        esValidoNombreCliente = false;
      }
    })

    if (!esValidoNombreCliente) {
      this._snackBarService.showError('No se ingresó el nombre del cliente en un registro', 'OK');
      return;
    }

    // Verificar dirección del cliente
    // let esValidoDireccionCliente = true;
    // newData.forEach(item => {
    //   if (item.cli_dircli.trim() == '') {
    //     esValidoDireccionCliente = false;
    //   }
    // })

    // if (!esValidoDireccionCliente) {
    //   this._snackBarService.showError('No se ingresó la dirección del cliente en un registro', 'OK');
    //   return;
    // }

    // Verificar tipo de documento
    let esValidoTipoDocumento = true;
    newData.forEach(item => {
      if (item.did_coddid.trim() == '') {
        esValidoTipoDocumento = false;
      }
    })

    if (!esValidoTipoDocumento) {
      this._snackBarService.showError('No se ingresó el tipo de documento del cliente en un registro', 'OK');
      return;
    }

    // Verificar si existen documentos duplicados
    const codigosCliente = newData.map(item => item.cli_codcli);

    if (tieneDuplicados(codigosCliente)) {
      this._snackBarService.showError('No puede haber documentos duplicados', 'OK');
      return;
    }

    // Verificar si es valido RUC
    let esValidoRUC = true;
    newData.forEach(item => {
      if (item.cli_tipper == 'J') {
        if (item.cli_numruc.length == 11 && item.cli_numruc.substring(0, 2) != '20') {
          esValidoRUC = false;
        }
      }
    })
    if (!esValidoRUC) {
      this._snackBarService.showError('No se encontró el RUC', 'OK');
      return;
    }

    // Verificar si es valido DNI
    let esValidoDNI = true;
    newData.forEach(item => {
      if (item.cli_tipper == 'N') {
        if ((item.cli_numdni.length == 11) && item.cli_numruc.substring(0, 2) != '10') {
          esValidoDNI = false;
        }
      }
    })
    if (!esValidoDNI) {
      this._snackBarService.showError('No se encontró el RUC de persona natural', 'OK');
      return;
    }

    // Verificar si tiene nombre y apellidos si es persona natural
    let esClienteNaturalValido = true;
    newData.forEach(item => {
      if (item.cli_tipper == 'N' && item.cli_codcli.substring(0, 2) == '10') {
        if (item.cli_nomcli == '' || item.cli_apepat == '' || item.cli_apemat == '') {
          esClienteNaturalValido = false;
        }
      }
    })

    // Verificar pais
    let esCodigoPaisValido = true;
    newData.forEach(item => {
      const pais = this.paises.find(pais => pais.PAI_CODPAI.trim() == item.pai_codpai.trim());
      if (!pais) {
        esCodigoPaisValido = false;
      }
    })
    if (!esCodigoPaisValido) {
      this._snackBarService.showError('No se encontró el código de país', 'OK');
      return;
    }

    // Verificar existencia de ubigeo
    let esCodigoUbigeoValido = true;
    newData.forEach(item => {
      const ubigeo = this.ubigeos.find(ubigeo => ubigeo.ubs_codubs.trim() == item.ubs_codubs.trim());

      if (!ubigeo) {
        esCodigoUbigeoValido = false;
      }
    })
    if (!esCodigoUbigeoValido) {
      this._snackBarService.showError('No se encontró el código de ubigeo', 'OK');
      return;
    }

    // Es válido indicador cliente
    let esValidoIndicadorCliente = true;
    newData.forEach(item => {
      if (item.cli_indcli != 0 && item.cli_indcli != 1) {
        esValidoIndicadorCliente = false;
      }
    })
    if (!esValidoIndicadorCliente) {
      this._snackBarService.showError('El indicador de cliente solo puede ser 0 o 1', 'OK');
      return;
    }

    // Es válido indicador proveedor
    let esValidoIndicadorProveedor = true;
    newData.forEach(item => {
      if (item.cli_indpro != 0 && item.cli_indpro != 1) {
        esValidoIndicadorProveedor = false;
      }
    })
    if (!esValidoIndicadorProveedor) {
      this._snackBarService.showError('El indicador de proveedor solo puede ser 0 o 1', 'OK');
      return;
    }

    // Es válido indicador trabajador
    let esValidoIndicadorTrabajador = true;
    newData.forEach(item => {
      if (item.cli_indtra != 0 && item.cli_indtra != 1) {
        esValidoIndicadorTrabajador = false;
      }
    })
    if (!esValidoIndicadorTrabajador) {
      this._snackBarService.showError('El indicador de trabajador solo puede ser 0 o 1', 'OK');
      return;
    }

    // Es válido indicador gasto auxiliar
    let esValidoIndicadorGastoAuxiliar = true;
    newData.forEach(item => {
      if (item.cli_indgas != 0 && item.cli_indgas != 1) {
        esValidoIndicadorGastoAuxiliar = false;
      }
    })
    if (!esValidoIndicadorGastoAuxiliar) {
      this._snackBarService.showError('El indicador de gasto auxiliar solo puede ser 0 o 1', 'OK');
      return;
    }

    // Es válido indicador domiliado
    let esValidoIndicadorDomiciliado = true;
    newData.forEach(item => {
      if (item.cli_inddom != 0 && item.cli_inddom != 1) {
        esValidoIndicadorDomiciliado = false;
      }
    })
    if (!esValidoIndicadorDomiciliado) {
      this._snackBarService.showError('El indicador de domiciliado solo puede ser 0 o 1', 'OK');
      return;
    }

    // Validar si el código de vendedor a ingresar existe
    let esValidoCodigoVendedorValido = true;
    newData.forEach(item => {
      if (item.vde_codvde.trim() != '') {
        const vendedor = this.vendedores.find(vendedor => vendedor.VDE_CODVDE.trim() == item.vde_codvde.trim());
        if (!vendedor) {
          esValidoCodigoVendedorValido = false;
        }
      }
    })
    if (!esValidoCodigoVendedorValido) {
      this._snackBarService.showError('No se encontró el código de vendedor', 'OK');
      return;
    }

    // Validar si el código de vendedor a ingresar existe
    let esValidoCodigoZonaVentaValido = true;
    newData.forEach(item => {
      if (item.zve_codzve.trim() != '') {
        const zonaVenta = this.zonasVenta.find(zona => zona.ZVE_OBSZVE.trim() == item.zve_codzve.trim());
        if (!zonaVenta) {
          esValidoCodigoZonaVentaValido = false;
        }
      }
    })
    if (!esValidoCodigoZonaVentaValido) {
      this._snackBarService.showError('No se encontró el código de zona venta', 'OK');
      return;
    }

    // Validar si la moneda ingresada es válido
    let esValidoMoneda = true;
    newData.forEach(item => {
      if (item.tmo_codtmo.trim()) {
        if (item.tmo_codtmo.trim() != 'SOLES' && item.tmo_codtmo.trim() != 'DÓLAR') {
          esValidoMoneda = false;
          return;
        }
        item.tmo_codtmo = item.tmo_codtmo == 'SOLES' ? 'SO' : 'DO'
      }
    })
    if (!esValidoMoneda) {
      this._snackBarService.showError('La moneda ingresada no es válida', 'OK');
      return;
    }

    console.log('newData', newData)
    this.dataSource = fillTable(newData, this.paginator, this.sort);

    this.tablaSeleccionada = false;
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.paises$,
      this.vendedores$,
      this.ubigeos$,
      this.loading$,
      this.zonasVenta$
    ])
  }

}
