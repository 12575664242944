import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Item, MaterialRequirement, Order } from 'src/app/models';
import { AuthenticationService, ConfigurationService, VentasService, ContabilidadService, AlmacenService } from 'src/app/services';
import { IlumiProductListComponent } from '@shared/components/dialogs/ilumi/ilumi-product-list/ilumi-product-list.component';
import { Subscription } from 'rxjs';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { Store } from '@ngrx/store';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Router } from '@angular/router';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { DetallePcd } from 'src/app/models/ventas/order';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-material-requirement-create',
  templateUrl: './material-requirement-create.component.html',
  styleUrls: ['./material-requirement-create.component.css']
})
export class MaterialRequirementCreateComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'item', 'codigo', 'part_number', 'descripcion', 'cantidad', 'um', 'cantidad_atendida', 'observaciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderReg: boolean = false;

  range: FormGroup;

  loaderData: boolean = false;

  almacenes: any[] = [];
  clientes: any[] = [];
  centros: any[] = [];
  situaciones: any[] = [];
  productosArr: any[] = [];

  requerimientoMaterial: MaterialRequirement;

  fecha: Date;
  usuario: any;

  producto: Item;

  order: Order;

  minDate: Date;
  maxDate: Date;

  almacen$: Subscription;
  cliente$: Subscription;
  centroCosto$: Subscription;
  producto$: Subscription;
  loading$: Subscription;

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;

  constructor(
    private _dialogService: DialogService,
    private _contabilidadService: ContabilidadService,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private _ventasService: VentasService,
    private _productoService: ProductoService,
    private _router: Router,
    public dialog: MatDialog,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());
    this.maxDate = new Date(currentYear + 1, 11, 31);

    this.order = new Order();
    this.order.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.order.PCC_TASIGV = 18.00;
    this.order.PCC_TIPCAM = 3.640;

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.usuario = this._authService.getUsuarioSistema();
    this.fecha = new Date();

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 13)),
      end: new FormControl(new Date(year, month, 16))
    });


    this.requerimientoMaterial = new MaterialRequirement();
    this.requerimientoMaterial.detalle_materiales = [];
    this.requerimientoMaterial.rma_indsta = "1";
    this.requerimientoMaterial.rma_fecpro = new Date();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  trackByFn(index, item) {
    return item.id; // unique id corresponding to the item
  }

  loadData(): void {
    this.loaderData = true;

    forkObs(
      this._almacenService.obtenerAlamacenes(),
      this._ventasService.obtenerClientesComerciales(),
      this._contabilidadService.listarCentroCostos(),
      this._productoService.obtenerProductos()
    ).then(data => {
      this.almacenes = data[0];
      this.clientes = data[1];
      this.centros = data[2];
      this.productosArr = data[3];
      this.loaderData = false;

    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", 'OK')
      this.loaderData = false;
    })

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  registrarRequerimiento(f: NgForm): void {

    if(this.requerimientoMaterial.detalle_materiales.length > 0) {
      if(validarPeriodo(this.fecha, this.year, this.month)) {
        this._dialogService.openDialog(SuccessComponent, 'Registro de Requerimiento de Materiales Exitoso', '300px', '', '');
        return;
      }

      this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK')
      return;
    }

    this._snackBarService.showError('Ingresar al menos un producto', 'OK');

  }

  editarProducto(id): void {}

  seleccionarProducto(): void {

    const dialogRef = this.dialog.open(IlumiProductListComponent, {
      width: '800px',
      data: {},
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

        let validator = false;
        this.order.detalle_pcd.forEach(element => {
          if(element.PRD_CODPRD == result.prd_codprd) {
            validator = true;
            element.PCD_CANSOL++;
            element.PCD_IMPBRU = element.PCD_CANSOL * element.PCD_PREUNI;
            element.PCD_IMPBRU = element.PCD_IMPBRU.toFixed(2);
            element.PCD_VALVTA = element.PCD_IMPBRU - element.PCD_IMPDES;
            element.PCD_VALVTA = element.PCD_VALVTA.toFixed(2);
            element.PCD_IMPTOT = element.PCD_CANSOL * element.PCD_PRUIGV - element.PCD_IMPDES;
            element.PCD_IMPTOT = element.PCD_IMPTOT.toFixed(2);
            element.PCD_IMPIGV = element.PCD_IMPTOT - element.PCD_VALVTA;
            element.PCD_IMPIGV = element.PCD_IMPIGV.toFixed(2);
          }
        })

        if(!validator) {

          let producto = new DetallePcd();

          producto.PRD_CODPRD = result.prd_codprd;
          producto.UME_CODVEN = result.ume_codume;
          producto.PRD_DESESP = result.prd_desesp;
          producto.PCD_CANSOL = 1;
          producto.PCD_CANAUT = 0;
          producto.PCD_CANATE = 0;
          producto.PCD_CANFAC = 0;
          producto.PCD_CANSUS = 0;
          producto.PCD_IMPDES = 0;
          producto.PCD_PORDES = 0;
          producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
          producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
          producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
          producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

          this.order.detalle_pcd.push(producto)
          // this.consultarPrecioProducto(producto)
        }

        this.calcularTotales();

        this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort)
      }
    })

  }

  searchCode(value): void {

    this._productoService.obtenerProducto(value).subscribe(
      producto => {

        let validator = false;

        this.order.detalle_pcd.forEach(element => {
          if(element.PRD_CODPRD == producto[0].prd_codprd) {
            validator = true;
            element.PCD_CANSOL++;
            element.PCD_IMPBRU = element.PCD_CANSOL * element.PCD_PREUNI;
            element.PCD_IMPBRU = element.PCD_IMPBRU.toFixed(2);
            element.PCD_VALVTA = element.PCD_IMPBRU - element.PCD_IMPDES;
            element.PCD_VALVTA = element.PCD_VALVTA.toFixed(2);
            element.PCD_IMPTOT = element.PCD_CANSOL * element.PCD_PRUIGV - element.PCD_IMPDES;
            element.PCD_IMPTOT = element.PCD_IMPTOT.toFixed(2);
            element.PCD_IMPIGV = element.PCD_IMPTOT - element.PCD_VALVTA;
            element.PCD_IMPIGV = element.PCD_IMPIGV.toFixed(2);
          }
        })

        if(!validator) {

          let producto = new DetallePcd();

          producto.PRD_CODPRD = producto[0].prd_codprd;
          producto.UME_CODVEN = producto[0].ume_codume;
          producto.PRD_DESESP = producto[0].prd_desesp;
          producto.PCD_CANSOL = 1;
          producto.PCD_CANAUT = 0;
          producto.PCD_CANATE = 0;
          producto.PCD_CANFAC = 0;
          producto.PCD_CANSUS = 0;
          producto.PCD_IMPDES = 0;
          producto.PCD_PORDES = 0;
          producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
          producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
          producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
          producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

          this.order.detalle_pcd.push(producto)
          this.consultarPrecioProducto(producto)
        }

        this.calcularTotales();

        this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort)

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;
    this.order.detalle_pcd.forEach(element => {
      valorVenta += Number(element.PCD_VALVTA);
      impBruto += Number(element.PCD_IMPBRU);
      impIGV += Number(element.PCD_IMPIGV);
      descuento += Number(element.PCD_IMPDES);
      impTotal += Number(element.PCD_IMPTOT);
    })

    this.order.PCC_VALVTA = valorVenta;
    this.order.PCC_VALVTA = this.order.PCC_VALVTA.toFixed(2);

    this.order.PCC_IMPBRU = impBruto;
    this.order.PCC_IMPBRU = this.order.PCC_IMPBRU.toFixed(2);

    this.order.PCC_IMPIGV = impIGV;
    this.order.PCC_IMPIGV = this.order.PCC_IMPIGV.toFixed(2);

    this.order.PCC_IMPDES = descuento;
    this.order.PCC_IMPDES = this.order.PCC_IMPDES.toFixed(2);

    this.order.PCC_IMPTOT = impTotal;
    this.order.PCC_IMPTOT = this.order.PCC_IMPTOT.toFixed(2);
  }

  consultarPrecioProducto(producto: DetallePcd): void {
    let now = new Date();

    let fecha = formatDateWithDash(now);

    if(!this.order.TLP_CODTLP) {
      this._snackBarService.showError('Seleccionar lista', 'OK');
    }

    if(!this.order.LPC_CODLPC) {
      this._snackBarService.showError('Establecer Nro de lista', 'OK');
    }

    this._ventasService.listaDePreciosProducto(producto.PRD_CODPRD, this.order.TMO_CODTMO, this.order.CLI_CODCLI, this.order.TLP_CODTLP, this.order.LPC_CODLPC,fecha ).subscribe(
      response => {
        if(this.order.TMO_CODTMO == "SO") {
          producto.PCD_PREUNI = response[0].pre_prenac;
          producto.PCD_PRUIGV = response[0].pre_vtanac;
        } else {
          producto.PCD_PREUNI = response[0].pre_predol;
          producto.PCD_PRUIGV = response[0].pre_vtadol;
        }

        producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
        producto.PCD_IMPBRU = producto.PCD_IMPBRU.toFixed(2);
        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
        producto.PCD_VALVTA = producto.PCD_VALVTA.toFixed(2);
        producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
        producto.PCD_IMPTOT = producto.PCD_IMPTOT.toFixed(2);
        producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;
        producto.PCD_IMPIGV = producto.PCD_IMPIGV.toFixed(2);

        this.calcularTotales();

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-operaciones/requerimientos-de-materiales']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.almacen$,
      this.cliente$,
      this.centroCosto$,
      this.producto$,
      this.period$
    ])
  }
}
