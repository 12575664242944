import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_VENTAS_DINAMICOS } from "@data/json/reportes/ventas.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subject, Subscription } from "rxjs";
import { FiltroReporteVenta } from "src/app/models/ventas/filtro-reporte-venta";
import { GeneralService, VentasService } from "src/app/services";
import { VentasReportService } from "src/app/services/reports/modules/ventas-report.service";

@Component({
  selector: 'dinamicos-ventas',
  templateUrl: './dinamicos-ventas.component.html',
  styleUrls: ['./dinamicos-ventas.component.scss']
})
export class DinamicosVentasComponent implements OnInit, OnDestroy {

  filtroReporteVenta: FiltroReporteVenta;

  tiendas: any[] = [];
  monedas: any[] = [];
  documentos: any[] = [];
  supervisores: any[] = [];
  series: any[] = [];
  motivos: any[] = [];
  vendedores: any[] = [];
  clientes: any[] = [];
  condiciones: any[] = [];
  productos: any[] = [];
  inventarios: any[] = [];
  canales: any[] = [];
  lineas: any[] = [];
  marcas: any[] = [];
  colores: any[] = [];
  modelos: any[] = [];
  zonas: any[] = [];

  loading$: Subscription;
  dataFiltro$: Subscription;
  periodo$: Subscription;

  loaderColumnas: boolean;

  protected _onDestroy = new Subject<void>();

  reportes: any[] = REPORTES_VENTAS_DINAMICOS;
  reporteSeleccionado: any = ['VENTAS_RV'];

  loading: boolean;
  loaderData: boolean;
  year: string;
  month: string;

  years: any[] = [];

  yearToday: number = new Date().getFullYear();
  yearPeriod: string = this.yearToday.toString();
  monthPeriod: string;

  months =
    [
      {
        id: "01",
        name: "ENERO",
      },
      {
        id: "02",
        name: "FEBRERO",
      },
      {
        id: "03",
        name: "MARZO",
      },
      {
        id: "04",
        name: "ABRIL",
      },
      {
        id: "05",
        name: "MAYO",
      },
      {
        id: "06",
        name: "JUNIO",
      },
      {
        id: "07",
        name: "JULIO",
      },
      {
        id: "08",
        name: "AGOSTO",
      },
      {
        id: "09",
        name: "SETIEMBRE",
      },
      {
        id: "10",
        name: "OCTUBRE",
      },
      {
        id: "11",
        name: "NOVIEMBRE",
      },
      {
        id: "12",
        name: "DICIEMBRE"
      }
    ];

  task: any = {
    name: 'Campos cabecera',
    completed: false,
    color: 'primary',
    subtasks: []
  };

  allComplete: boolean = false;

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => t.completed = completed);
  }

  columnas$: Subscription;
  columnas: any[] = [];
  agrupadores: any[] = [];
  agrupadores2: any[] = [];
  selecciones: any[] = [];

  seleccion: any;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private store: Store<PavsoState>,
    private _ventasService: VentasService,
    private _ventasReporteService: VentasReportService,
    private _snackBarService: SnackBarService,
    private readonly _generalService: GeneralService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.filtroReporteVenta = new FiltroReporteVenta();

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;

      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;

      });
  }

  ngOnInit(): void {
    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadMaestros();
    })

    this.establecerAnios();
  }

  establecerAnios(): void {
    for (let index = 2000; index <= this.yearToday; index++) {
      this.years.push({
        id: index.toString(),
        name: index.toString()
      });
    }
    console.log(this.years)
  }

  grabar(): void {

  }

  loadMaestros(): void {
    this.loaderData = true;
    this.dataFiltro$ = this._ventasService.obtenerDataFiltroVentas().subscribe(
      data => {

        this.tiendas = data.tiendas;

        this.documentos = data.documentos;

        this.series = data.series;

        this.monedas = data.monedas;

        this.motivos = data.motivos;

        this.vendedores = data.vendedores;

        this.clientes = data.clientes;

        this.condiciones = data.condiciones;

        this.productos = data.productos;

        this.canales = data.canales;

        this.marcas = data.marcas;

        this.colores = data.colores;

        this.zonas = data.zonasVenta;

        this.inventarios = data.tiposInventario;

        this.lineas = data.lineas;

        this.agrupadores = data.agrupadores;
        this.agrupadores2 = data.agrupadores;
        console.log('agrupadores', this.agrupadores)

        this.supervisores = data.supervisores;
        this.loaderData = false;
      },
      error => {

        console.log(error)
        this._snackBarService.showError('Error al obtener data filtro ventas', 'Ok');
        this.loaderData = false;

      }
    )
  }

  seleccionarAgrupador(event): void {

    this.agrupadores2 = this.agrupadores.filter(item => item.grv_campo != event.value);
  }

  cleanAgrupadores(): void {
    this.filtroReporteVenta.agrupador1 = null;
    this.filtroReporteVenta.agrupador2 = null;
  }

  agregarPeriodo(): void {
    console.log(this.yearPeriod, this.monthPeriod);

    const periodoEncontrado = this.filtroReporteVenta.periodos.find(item => item.id == `${this.yearPeriod}${this.monthPeriod}`);

    if (periodoEncontrado) return;

    if (this.filtroReporteVenta.periodos.length > 3) return;

    this.filtroReporteVenta.periodos.push({
      id: `${this.yearPeriod}${this.monthPeriod}`,
      year: this.yearPeriod,
      month: this.monthPeriod
    })
  }

  quitarPeriodo(id): void {
    const periodos = this.filtroReporteVenta.periodos.filter(item => item.id != id);
    this.filtroReporteVenta.periodos = periodos;
  }

  seleccionarReporte(event): void {
    this.loaderColumnas = true;
    console.log('reporteSeleccionado', this.reporteSeleccionado)
    this.reporteSeleccionado[0] = event.id;

    let indicador = "";

    switch (this.reporteSeleccionado[0]) {
      case "VENTAS_P":
        indicador = "51002"
        this.loaderColumnas = false;
        break;
      case "VENTAS_RV":
        indicador = "51002"
        this.loaderColumnas = false;
        break;
      case "VENTAS_RDV":
        indicador = "51002";
        this.loaderColumnas = false;
        break;
      case "VENTAS_MC":
        indicador = "51002"
        this.loaderColumnas = false;
        break;
      case "VENTAS_RA":
        indicador = "51002"
        this.task.subtasks = [];
        this.loaderColumnas = false;
        break;
      case "VENTAS_RT":
        indicador = "51002"
        this.task.subtasks = [];
        this.loaderColumnas = false;
        break;
      case "VENTAS_CC":
        indicador = "51002"
        this.task.subtasks = [];
        this.loaderColumnas = false;
        break;
      case "VENTAS_PDV":
        indicador = "51002"
        this.task.subtasks = [];
        this.loaderColumnas = false;
        break;
      default:
        this.task.subtasks = [];
        this.loaderColumnas = false;
        break;
    }

    this.obtenerColumnas(event.code);
  }

  obtenerColumnas(code): void {

    this.loading = true;

    this.columnas$ = this._generalService.listarColumnasInformeDinamico('32', code).subscribe(
      columnas => {
        this.columnas = columnas;

        this.columnas.forEach(element => {
          element['name'] = element.TDR_DESTDR;
          element['completed'] = true;
        });

        this.task.subtasks = this.columnas;
        this.loading = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loading = false;
      }
    )
  }

  verReporteDinamico(): void {

    console.log('filtro', this.filtroReporteVenta)

    this.loading = true;

    const headers = {
      codano: this.year,
      codmes: this.month,
      moneda: this.filtroReporteVenta.moneda,
    }

    const otherData = {
      moneda: this.filtroReporteVenta.moneda,
      codano: this.year,
      codmes: this.month,
      periodos: this.filtroReporteVenta.periodos,
      agrupador1: this.filtroReporteVenta.agrupador1,
      agrupador2: this.filtroReporteVenta.agrupador2,
      verDetalle: this.filtroReporteVenta.verDetalle,
    }

    console.log('otherData', otherData)

    this._ventasReporteService.verReporteDinamico(this.reporteSeleccionado[0], headers, this.filtroReporteVenta, otherData)
      .then(_ => this.loading = false)
      .catch(_ => this.loading = false)
      .finally(() => this.loading = false)

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.dataFiltro$,
      this.columnas$
    ])
  }

}
