import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'periodo-calculo-list',
  templateUrl: './periodo-calculo-list.component.html',
  styleUrls: ['./periodo-calculo-list.component.html']
})
export class PeriodoCalculoListComponent implements OnInit {

  constructor(){}

  ngOnInit(): void {

  }
}
