<mat-sidenav-container class="example-container">
  <mat-sidenav #drawer mode="over" position="end">

  </mat-sidenav>
  <mat-sidenav-content class="pavso-container">
    <app-pavso-title-section [title]="'profile.title' | translate" [module]="'PAVSO'"></app-pavso-title-section>

    <div class="row">
      <div class="col s12 m12 l6">
        <mat-card class="pav-card-dashboard mat-elevation-z0">
          <mat-card-header [style.background]="colorGreen">
            <mat-card-title>
              <mat-icon>bookmark</mat-icon>
              {{'profile.header.personal' | translate}}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="pav-personal-content">
              <div class="pav-personal-content-title">
                {{'profile.form.name' | translate}}
              </div>
              <div class="pav-personal-content-detail">
                {{user.nombre}}
              </div>
            </div>
            <div class="pav-personal-content">
              <div class="pav-personal-content-title">
                {{'profile.form.lastname' | translate}}
              </div>
              <div class="pav-personal-content-detail">
                {{user.apellido}}
              </div>
            </div>
            <div class="pav-personal-content">
              <div class="pav-personal-content-title">
                {{'profile.form.email' | translate}}
              </div>
              <div class="pav-personal-content-detail">
                {{user.correo}}
              </div>
            </div>
            <div class="pav-personal-content">
              <div class="pav-personal-content-title">
                {{'profile.form.type-user' | translate}}
              </div>
              <div class="pav-personal-content-detail">
                {{user.usuariosistema}}
              </div>
            </div>
            <div class="pav-personal-content">
              <div class="pav-personal-content-title">
                {{'profile.form.password' | translate}}
              </div>
              <div class="pav-personal-content-detail">
                *************
              </div>
            </div>
            <br>
            <mat-divider></mat-divider>
            <br>
            <div class="pav-personal-content">
              <div class="pav-personal-content-title">
                {{'profile.form.photo' | translate}}
              </div>
              <div class="pav-personal-content-detail">
                {{'profile.form.custom' | translate}}
              </div>
              <div class="pav-personal-content-image">
                P
              </div>
            </div>
            <mat-divider></mat-divider>
            <br>
            <div class="pav-personal-content">
              <div class="pav-personal-content-title">
                Logo
              </div>
              <div [routerLink]="'/mi-logo'" [ngStyle]="{cursor: 'pointer'}" class="pav-personal-content-detail">
                Cambiar Logo
              </div>
              <div class="pav-personal-content-image">
                L
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col s12 m12 l6">
        <mat-card class="pav-card-dashboard mat-elevation-z0">
          <mat-card-header [style.background]="colorBlue">
            <mat-card-title>
              <mat-icon>bookmark</mat-icon>
              {{'profile.header.security' | translate}}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="pav-security-content-description">{{'profile.header.badge' | translate}}</p>

            <div class="pav-security-container-input">
              <mat-form-field>
                <mat-label>{{'profile.form.current-password' | translate}}</mat-label>
                <input matInput [type]="hideA ? 'password' : 'text'" name="current_password" required>
                <button mat-icon-button matSuffix (click)="hideA = !hideA" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideA">
                <mat-icon>{{hideA ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'profile.form.new-password' | translate}}</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" name="new_password" required>
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <br>
            <button class="pav-button-other" color="primary" mat-flat-button>{{'profile.button.update-password' | translate}}</button>
          </mat-card-content>
        </mat-card>
        <!-- <button mat-raised-button (click)="prueba()">prueba</button> -->
      </div>
    </div>

    <br>
    <br>
  </mat-sidenav-content>
</mat-sidenav-container>

