<app-pavso-title-section [title]="'Liquidación de producción'" [module]="'Producción'" [estado]="liquidacion.estado"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l12 xl6">
            <div class="row">
                <div class="col s12 m12 l4">
                  <mat-form-field>
                      <mat-label>Liquidación</mat-label>
                      <input type="text" matInput placeholder="Liquidación" name="liquidacion" required [disabled]="loaderData">
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l4">
                  <mat-form-field>
                    <mat-label>Fecha</mat-label>
                    <input matInput [matDatepicker]="fechaRegistro">
                    <mat-datepicker-toggle matSuffix [for]="fechaRegistro"></mat-datepicker-toggle>
                    <mat-datepicker #fechaRegistro></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l12">
                  <select-search
                    [label]="'Planta'"
                    [placeholder]="'Ej. Of. Principal'"
                    [name]="'pla_codpla'"
                    [value]="'pla_codpla'"
                    [description]="'pla_despla'"
                    [data]="plantas"
                    (cambioSelect)="liquidacion.planta = $event"
                    [model]="liquidacion.planta">
                  </select-search>

                </div>
                <div class="col s12 m12 l12">
                  <mat-form-field>
                      <mat-label>Turno</mat-label>
                      <mat-select required name="turno" [disabled]="loaderData">
                          <mat-option value="1">
                              turno 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12">
                  <mat-form-field>
                      <mat-label>Situación</mat-label>
                      <mat-select required name="situacion" [disabled]="loaderData">
                          <mat-option value="1">
                              situacion 1
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12">
                  <mat-form-field>
                    <mat-label>Glosa</mat-label>
                    <textarea matInput placeholder="Glosa"></textarea>
                  </mat-form-field>
                </div>
            </div>
            <mat-card class="mat-elevation-z0">
              <button class="pavso-button" mat-flat-button color="primary" type="button">Genera Partes Salida Consumo 0138</button>
              &nbsp;
              <button class="pavso-button" mat-flat-button color="primary" type="button">Genera Partes Ingreso Merma 0142</button>
              &nbsp;
              <button mat-flat-button color="primary" type="button">Carga Órdenes</button>
            </mat-card>
          </div>
          <div class="col s12 m12 l12 xl6">
            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Tabla</div>
              <div class="pav-separator"></div>

              <div>
                <button mat-flat-button class="pav-button-add" type="button"
                  color="primary">Agregar</button>
              </div>
            </div>

            <div class="mat-elevation-z0 overflow-x">
              <table mat-table [dataSource]="dataSource" matSort >

                  <ng-container matColumnDef="item">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                      <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                  </ng-container>

                  <ng-container matColumnDef="descripcion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                      <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
                  </ng-container>

                  <ng-container matColumnDef="tipo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                      <td mat-cell *matCellDef="let row" data-label="Tipo"> {{row.tipo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="numero">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Número </th>
                    <td mat-cell *matCellDef="let row" data-label="Número"> {{row.numero}} </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                    <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.fecha}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="5">No se encontraron registros</td>

                  </tr>

              </table>

              <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
            <br>

          </div>
        </div>
      </mat-card>
      <br>
      <mat-tab-group>
        <mat-tab label="Productos Fabricados (Proceso)" labelClass="mat-tab-label-0-0">
          <div class="pavso-tab-container">
            <div class="row">
              <div class="col s12">
                <div class="pav-filtro-header">
                  <div class="pav-filtro-header-icon"></div>
                  <div class="pav-filtro-header-title">Productos</div>
                  <div class="pav-separator"></div>

                  <div>
                    <button mat-flat-button class="pav-button-add" type="button" (click)="agregarProducto()"
                      color="primary">Agregar</button>
                  </div>
                </div>
                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceProd" matSort >
                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div class="pav-btn-circle-delete" (click)="eliminarProducto(i)" matTooltip="Eliminar">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                      <ng-container matColumnDef="item">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                          <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                      </ng-container>

                      <ng-container matColumnDef="orden_produccion">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Orden Producción </th>
                          <td mat-cell *matCellDef="let row" data-label="Orden Producción"> {{row.descripcion}} </td>
                      </ng-container>

                      <ng-container matColumnDef="cod_prod">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Prod. </th>
                          <td mat-cell *matCellDef="let row" data-label="Cod. Prod."> {{row.tipo}} </td>
                      </ng-container>

                      <ng-container matColumnDef="descripcion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                        <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.numero}} </td>
                      </ng-container>

                      <ng-container matColumnDef="um">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
                        <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="cantidad_orden">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Orden </th>
                        <td mat-cell *matCellDef="let row" data-label="Cantidad Orden"> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="cantidad_liq">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Liq. </th>
                        <td mat-cell *matCellDef="let row" data-label="Cantidad Liq."> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="linea">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Línea </th>
                        <td mat-cell *matCellDef="let row" data-label="Línea"> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="op">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Op. </th>
                        <td mat-cell *matCellDef="let row" data-label="Op."> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="operario">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Operario </th>
                        <td mat-cell *matCellDef="let row" data-label="Operario"> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="prod_merma">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prod. Merma </th>
                        <td mat-cell *matCellDef="let row" data-label="Prod. Merma"> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="cantidad_merma">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Merma </th>
                        <td mat-cell *matCellDef="let row" data-label="Cantidad Merma"> {{row.fecha}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsProd"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsProd;"></tr>

                      <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="12">No se encontraron registros</td>

                      </tr>

                  </table>

                  <mat-paginator #paginatorProductos showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Mano de Obra" labelClass="mat-tab-label-0-1">
          <div class="pavso-tab-container">
            <div class="row">
              <div class="col s12">
                <div class="pav-filtro-header">
                  <div class="pav-filtro-header-icon"></div>
                  <div class="pav-filtro-header-title">Personal</div>
                  <div class="pav-separator"></div>

                  <div>
                    <button mat-flat-button class="pav-button-add" type="button" (click)="agregarManoObra()"
                      color="primary">Agregar</button>
                  </div>
                </div>

                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceMano" matSort >
                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div class="pav-btn-circle-delete" (click)="eliminarManoObra(i)" matTooltip="Eliminar">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                      <ng-container matColumnDef="item">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                          <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                      </ng-container>

                      <ng-container matColumnDef="cod_trab">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Trab. </th>
                          <td mat-cell *matCellDef="let row" data-label="Cod. Trab."> {{row.descripcion}} </td>
                      </ng-container>

                      <ng-container matColumnDef="nombre_trab">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Trab. </th>
                          <td mat-cell *matCellDef="let row" data-label="Nombre Trab."> {{row.tipo}} </td>
                      </ng-container>

                      <ng-container matColumnDef="cod_prp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Prp. </th>
                        <td mat-cell *matCellDef="let row" data-label="Cod. Prp."> {{row.numero}} </td>
                      </ng-container>

                      <ng-container matColumnDef="proceso">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Proceso </th>
                        <td mat-cell *matCellDef="let row" data-label="Proceso"> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="cod_act">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Act. </th>
                        <td mat-cell *matCellDef="let row" data-label="Cod. Act."> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="actividad_operacion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actividad / Operación </th>
                        <td mat-cell *matCellDef="let row" data-label="Actividad / Operación"> {{row.fecha}} </td>
                      </ng-container>

                      <ng-container matColumnDef="tiempo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiempo </th>
                        <td mat-cell *matCellDef="let row" data-label="Tiempo"> {{row.fecha}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsMano"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsMano;"></tr>

                      <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="8">No se encontraron registros</td>

                      </tr>

                  </table>

                  <mat-paginator #paginatorManoObra showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col s12 m12 l5 xl3">
                <mat-form-field>
                  <mat-label>Nro. Liquidación</mat-label>
                  <input type="text" matInput placeholder="Nro. Liquidación" name="nro_liquidacion" required [disabled]="loaderData">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l1">
                <button type="button" mat-mini-fab color="primary">
                  <mat-icon>email</mat-icon>
                </button>
              </div>
              <div class="col s12 m12 l4 xl2">
                <button type="button" mat-flat-button color="primary">
                  Copiar Mano de Obra
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Materiales Utilizados" labelClass="mat-tab-label-0-2">
          <div class="pavso-tab-container">
            <div class="row">
              <div class="col s12">
                <div class="pav-filtro-header">
                  <div class="pav-filtro-header-icon"></div>
                  <div class="pav-filtro-header-title">Materiales</div>
                  <div class="pav-separator"></div>

                  <div>
                    <button mat-flat-button class="pav-button-add" type="button" (click)="agregarMaterial()"
                      color="primary">Agregar</button>
                  </div>
                </div>
                <div class="mat-elevation-z0 overflow-x">
                  <table mat-table [dataSource]="dataSourceMat" matSort >
                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div class="pav-btn-circle-delete" (click)="eliminarMaterial(i)" matTooltip="Eliminar">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                      <ng-container matColumnDef="item">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                          <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                      </ng-container>

                      <ng-container matColumnDef="nro_requerimiento">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Requerimiento </th>
                          <td mat-cell *matCellDef="let row" data-label="Nro. Requerimiento"> {{row.nro_requerimiento}} </td>
                      </ng-container>

                      <ng-container matColumnDef="codigo">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.codigo}} </td>
                      </ng-container>

                      <ng-container matColumnDef="descripcion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                        <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
                      </ng-container>

                      <ng-container matColumnDef="um">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
                        <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.um}} </td>
                      </ng-container>

                      <ng-container matColumnDef="cantidad">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                        <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cantidad}} </td>
                      </ng-container>

                      <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                        <td mat-cell *matCellDef="let row" data-label="Tipo"> {{row.tipo}} </td>
                      </ng-container>

                      <ng-container matColumnDef="nro_doc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
                        <td mat-cell *matCellDef="let row" data-label="Nro. Doc."> {{row.nro_doc}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsMat"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsMat;"></tr>

                      <tr class="mat-row" *matNoDataRow>
                          <td class="mat-cell" colspan="8">No se encontraron registros</td>

                      </tr>

                  </table>

                  <mat-paginator #paginatorMateriales showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col s12 m12 l3">
                <button mat-flat-button color="primary" type="button">Carga Materiales</button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
