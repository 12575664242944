<app-pavso-title-section [title]="'Remuneración fija'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <div class="pav-table-search-container">
    <div class="pav-table-filter">

      <select-search
        [label]="'Concepto'"
        [placeholder]="'Ej. BÁSICO'"
        [name]="'codnco'"
        [value]="'codnco'"
        [description]="'description'"
        [data]="conceptos"
        (cambioSelect)="concepto = $event; seleccionarConcepto($event)"
        [model]="concepto"
      ></select-search>

    </div>
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

  <br>

  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">
    </div>
    <div class="pav-table-header-title">
      Todas las remuneraciones
    </div>
    <div class="pav-table-header-add">
      <div class="isMobile">
        <div class="pav-button-icon-add" (click)="agregarItem()">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">

        <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarItem()">Agregar ítem</button>
      </div>
    </div>
  </div>

  <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort >

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive">
            <div class="pav-btns-container">
              <!-- <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
              <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                <mat-icon svgIcon="confirm"></mat-icon>
              </div> -->
              <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

          <ng-container matColumnDef="nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="Colaborador" class="td-responsive">
              <ng-container>

                <select-search
                  [label]=""
                  [placeholder]="'Ej. TORRES'"
                  [name]="'codcli' + i"
                  [value]="'codcli'"
                  [description]="'description'"
                  [data]="colaboradores"
                  (cambioSelect)="row.codcli = $event;"
                  [model]="row.codcli"
                ></select-search>

              </ng-container>

            </td>
          </ng-container>

          <ng-container matColumnDef="imprem">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe" class="td-responsive pav-td-center">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="pav-mat-form-margin-top mat-form-field-item">
                    <mat-label></mat-label>
                    <input #importe (blur)="blurImporte(row)" (focus)="importe.select()" step="any" class="pav-input-align-right" required min="0" type="number" matInput placeholder="Importe" [(ngModel)]="row.imprem"
                    [name]="'importe' + i.toString()">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.imprem.toFixed(2)}}
                </ng-container>
              </td>
          </ng-container>

          <ng-container matColumnDef="cannut">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiempo </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Tiempo" class="td-responsive pav-td-center">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="pav-mat-form-margin-top mat-form-field-item">
                    <mat-label></mat-label>
                    <input #tiempo (focus)="tiempo.select()" step="any" class="pav-input-align-right" required min="0" type="number" matInput placeholder="Tiempo" [(ngModel)]="row.cannut"
                    [name]="'tiempo' + i.toString()">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.cannut.toFixed(2)}}
                </ng-container>
              </td>
          </ng-container>

          <ng-container matColumnDef="codnut">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="Unidad" class="td-responsive pav-td-center">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-mat-form-margin-top mat-form-field-item">
                  <mat-label></mat-label>
                  <mat-select required [(ngModel)]="row.codnut" name="nut_codnut" disabled>
                    <mat-option *ngFor="let unidad of unidades" [value]="unidad.nut_codnut">{{unidad.nut_descri}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.codnut}}
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">No se encontraron registros "{{input.value}}"</td>

          </tr>
      </table>
      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
  <div align="end">
    <mat-form-field class="total">
      <mat-label>TOTAL</mat-label>
      <input class="pav-input-align-right" [value]="total.toFixed(2)" step='0.01' name="total" matInput placeholder="TOTAL" readonly>
    </mat-form-field>
  </div>
  <mat-card class="mat-elevation-z0">
    <button (click)="cargarColaboradores()" class="pav-button-load" mat-flat-button type="button">Carga Colaboradores</button>
  </mat-card>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</div>
