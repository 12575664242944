import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { HeaderReport } from "@data/interfaces/headers.report";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_CONTABLES_CONTABILIDAD } from "@data/json/reportes/contabilidad.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ConfigurationService, ContabilidadService, ExportExcelService, GeneralService } from "src/app/services";
import { AuxiliarService } from "src/app/services/api/contabilidad/maestros/auxiliar.service";
import { ContabilidadReportService } from "src/app/services/reports/modules/contabilidad-report.service";

class FiltroReporte {
  moneda: string;
  cuenta: string;
  auxiliar: string;
  subdiario: string;
  centro: string;
  digito: string;

  constructor() {
    this.moneda = 'SO';
  }
}

@Component({
  selector: 'reporte-contables',
  templateUrl: './reporte-contables.component.html',
  styleUrls: ['./reporte-contables.component.scss']
})
export class ReporteContablesComponent implements OnInit, OnDestroy {

  monedas: any[] = [];

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });


  cuentas: any[] = [];
  subdiarios: any[] = [];
  auxiliares: any[] = [];
  centros: any[] = [];

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;

  loaderData: boolean;
  loading: boolean;

  reporteSeleccionado: any = ['CONTABILIDAD_LDR'];

  reportes: any[] = REPORTES_CONTABLES_CONTABILIDAD;

  period$: Subscription;
  loading$: Subscription;
  cuentas$: Subscription;
  subdiarios$: Subscription;
  centros$: Subscription;
  auxiliares$: Subscription;
  monedas$: Subscription;

  filtro: FiltroReporte;
  constructor(
    private _snackBarService: SnackBarService,
    private _contabilidadService: ContabilidadService,
    private _contabilidadReportService: ContabilidadReportService,
    private _configurationService: ConfigurationService,
    private _generalService: GeneralService,
    private _auxiliarService: AuxiliarService,
    private store: Store<PavsoState>,
    public ete: ExportExcelService

  ) {

    this.filtro = new FiltroReporte();

    this.loaderData = false;

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingCompany) this.loadData();
    })

  }

  loadData(): void {
    this.loaderData = true;

    this.cuentas$ = this._contabilidadService.obtenerCuentasContable().subscribe(
      cuentas =>  {
        this.cuentas = cuentas;

        this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
          subdiarios => {
            this.subdiarios = subdiarios;

            this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
              centros => {
                this.centros = centros;

                this.auxiliares$ = this._auxiliarService.obtenerAuxiliares().subscribe(
                  auxiliares => {
                    this.auxiliares = auxiliares;

                    this.monedas$ = this._generalService.obtenerMonedas().subscribe(
                      monedas => {
                        this.monedas = monedas;
                        this.loaderData = false;
                      },
                      error => {
                        this.loaderData = false;
                        this._snackBarService.showSuccess('Error al obtener monedas', 'Ok')
                      }
                    )
                  },
                  error => {
                    this.loaderData = false;
                    this._snackBarService.showError('Error al obtener auxiliares', 'Ok')
                  }
                )
              },
              error => {
                this.loaderData = false;
                this._snackBarService.showError('Error al obtener centros de costos', 'Ok');
              }
            )
          },
          error => {
            this.loaderData = false;
            this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
          }
        )
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError("Error al obtener cuentas contables", "OK");
      }
    )

  }

  seleccionarReporte(event): void {
    this.reporteSeleccionado[0] = event.id;
  }

  verReporte(): void {
    this.loading = true;

    const headers: HeaderReport = {
      year: this.year,
      month: this.month,
      clientName: this._configurationService.obtenerNombreCompaniaCliente(),
      client: this._configurationService.obtenerClienteId(),
      currency: this.filtro.moneda,
      fecini: '',
      fecter: '',
      date: formatDateWithSlash(new Date()),
      time: getHourMinuteSecondsCurrent(new Date()),
      username: this._configurationService.obtenerNombreUsuario(),
      codePage: this._configurationService.obtenerCodigoPagina()
    }

    const otherData = {
      cuenta: this.filtro.cuenta
    }

    this._contabilidadReportService.verReporte(this.reporteSeleccionado[0], headers, null, otherData)
    .then(_ => this.loading = false)
    .catch( _ => this.loading = false)
    .finally( () => this.loading = false)

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.cuentas$,
      this.subdiarios$,
      this.centros$,
      this.auxiliares$,
      this.monedas$,
    ]);
  }
}
