import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";

export const THEADER_NOMINA_AFP: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codafp',
  },
  {
    header: 'Descripción',
    key: 'descri',
  },
];

export const THEADER_NOMINA_CARGO: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codnca'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
  {
    header: 'Estado',
    key: 'nomsta'
  }
];

export const THEADER_NOMINA_COLABORADOR: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codcli'
  },
  {
    header: 'Colaborador',
    key: 'nomcli'
  },
  {
    header: 'Fecha de ingreso',
    key: 'fecing'
  },
  {
    header: 'Fecha de cese',
    key: 'fecces'
  },
  {
    header: 'Situación',
    key: 'situacion'
  }
];

export const THEADER_NOMINA_FIRMA_DIGITAL: Array<IHeaderKey> = [
  {
    key: 'codcli',
    header: 'Código'
  },
  {
    key: 'descri',
    header: 'Descripción'
  },
  {
    key: 'email',
    header: 'Email'
  }
];

export const THEADER_NOMINA_LUGAR_TRABAJO: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codpla'
  },
  {
    header: 'Descripción',
    key: 'despla'
  },
  {
    header: 'Código',
    key: 'coduse'
  }
];

export const THEADER_NOMINA_TIPOS_DOCUMENTO_LEGAJO: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codtdl'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
  {
    header: 'Usuario Creador',
    key: 'coduse'
  }
]

export const THEADER_NOMINA_CONTRATOS: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codnmc'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
  {
    header: 'Fecha de inicio',
    key: 'fecinicio'
  },
  {
    header: 'Estado',
    key: 'nomcie'
  },
]

export const THEADER_NOMINA_LIQUIDACIONES: Array<IHeaderKey> = [
  {
    header: 'Período',
    key: 'periodo'
  },
  {
    header: 'Código',
    key: 'codcli'
  },
  {
    header: 'Colaborador',
    key: 'nomcie'
  },
]

export const THEADER_NOMINA_CONCEPTOS: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codnco'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
  {
    header: 'Fecha de creación',
    key: 'feccreacion'
  },
]

export const THEADER_NOMINA_CONFIGURACION_DEDUCCION: Array<IHeaderKey> = [
  {
    header: 'Código',
    key: 'codnco'
  },
  {
    header: 'Descripción',
    key: 'descri'
  },
  {
    header: 'Usuario creador',
    key: 'coduse'
  },
]
