import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';

@Component({
  selector: 'app-participacion-accionaria-create',
  templateUrl: './participacion-accionaria-create.component.html',
  styleUrls: ['./participacion-accionaria-create.component.css']
})
export class ParticipacionAccionariaCreateComponent implements OnInit {

  displayedColumns: string[] = ['acciones', 'codigo', 'nombre', 'tipo_acciones', 'nro_acciones', 'porcentaje_participaciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _generalService: GeneralService,
    private _authService: AuthenticationService
  ) {

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.driver.CHO_INDSTA = "1";

    this._generalService.listarTipoDocumentoIdentidad().subscribe(
      tiposDocumento => {
        this.tiposDocumento = tiposDocumento;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  registrarParticipacion(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/modulo-libros-electronicos/participaciones-accionarias']);
  }

}
