import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { formatBytes } from "@utils/conversions/formatBytes";
import { retornaDiferenciaDias } from "@utils/date/date";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ConfigurationService } from "src/app/services";
import * as xml2js from 'xml2js';

@Component({
  selector: 'cargar-xml-file-component',
  templateUrl: './cargar-xml-file.component.html',
  styleUrls: ['./cargar-xml-file.component.scss']
})
export class CargarXMLFileComponent implements OnDestroy, OnChanges, OnInit {

  uploadingFilesXML: boolean;
  movingFilesXMLToReception: boolean;
  validatingFilesXMLWithSUNAT: boolean;

  loaderData: boolean;
  loaderEnviarRecepcion: boolean;

  displayedColumnsImagenes: string[] = ['name', 'size', 'type', 'lastModifiedDate'];
  dataSourceImagenes: MatTableDataSource<File>;

  @ViewChild(MatPaginator) paginatorImagenes: MatPaginator;
  @ViewChild(MatSort) sortImagenes: MatSort;

  consultaExistenciaRucs$: Subscription;

  @Input() tiposDocumentoSUNAT: any[] = [];
  tiposDocumento: any[] = []

  @Output() onMoverXMLARecepcion: EventEmitter<Array<any>>;

  isMobile: boolean;
  isTablet: boolean;

  anioPeriodo: string;
  mesPeriodo: string;

  documentosValidos: any[] = [];
  documentosNoValidos: any[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {

    this.dataSourceImagenes = fillTable([], this.paginatorImagenes, this.sortImagenes);
    this.onMoverXMLARecepcion = new EventEmitter();
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }
  ngOnInit(): void {
    this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tiposDocumento = this.tiposDocumentoSUNAT;
    console.log('tiposDocumento sunat', this.tiposDocumento)
  }



  /**
   * Obtener Formato Bytes Ej. 1200 bytes 1.2 KB
   * @param bytes
   * @returns
   */
  obtenerFormatoResumidoBytes(bytes) {
    return formatBytes(bytes)
  }

  /**
   * Completa los ceros a la izquierda para completar a los 8 dígitos
   * @param numero
   * @returns
   */
  completarCerosIzquierda(numero) {
    // Convierte el número a cadena
    let numeroStr = numero.toString();

    // Calcula la cantidad de ceros necesarios
    let cerosNecesarios = 8 - numeroStr.length;

    // Completa ceros a la izquierda
    let numeroCompleto = '0'.repeat(cerosNecesarios) + numeroStr;

    return numeroCompleto;
  }

  moverXMLsARecepcion() {

    this.loaderEnviarRecepcion = true;
    // let file = fileInputEvent.target.files[0];
    this.movingFilesXMLToReception = true;
    const xmls = this.dataSourceImagenes.data;
    console.log('XMLS', xmls)
    let xmlsJSON = [];

    xmls.forEach(xml => {
      const reader = new FileReader();

      reader.onload = (evt) => {
        const xmlData: string = (evt as any).target.result;

        const parser = new xml2js.Parser({ strict: false, trim: true });

        parser.parseString(xmlData, (err, result) => {
          xmlsJSON.push(result);
        });
      };

      reader.readAsText(xml);

    })

    setTimeout(() => {
      // Transformar JSON XML to JSON Standar

      console.log('xmlsJSON', xmlsJSON)
      const estandarJSON = xmlsJSON.map((document, index) => {

        try {
          console.log('document', document)
          if (document.INVOICE) {
            const INVOICE = document.INVOICE;
            if (INVOICE['CBC:INVOICETYPECODE']) {
              let CCO_IMPAFE = INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_']) : parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);
              let CCO_IMPIGV = parseFloat(INVOICE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_'])
              let CCO_IMPDOC = parseFloat((INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString());
              let CCO_IMPINA = 0;

              console.log('vereificacion', CCO_IMPIGV == 0)

              if (CCO_IMPIGV == 0) {
                CCO_IMPINA = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPAFE = 0;
              } else {
                CCO_IMPAFE = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPINA = 0;

                if (CCO_IMPAFE + CCO_IMPIGV != CCO_IMPDOC) {
                  CCO_IMPINA = parseFloat((CCO_IMPDOC - (CCO_IMPAFE + CCO_IMPIGV)).toFixed(2))
                }
              }

              let valvta = CCO_IMPINA + CCO_IMPAFE;
              CCO_IMPDOC = CCO_IMPAFE + CCO_IMPINA + CCO_IMPIGV;

              const tmo = INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : INVOICE['CBC:DOCUMENTCURRENCYCODE'][0];

              return {
                estado: 'En recepción',
                correlativo: (index + 1) > 9 ? index + 1 : `0${index + 1}`,
                CLI_CODCLI: INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'] ? INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'],
                sad_codsad: INVOICE['CBC:ID'][0].split('-')[0],
                CCO_NUMDOC: INVOICE['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(INVOICE['CBC:ID'][0].split('-')[1]),
                CCO_FECEMI: new Date(INVOICE['CBC:ISSUEDATE'][0]),
                TMO_CODTMO: tmo == 'PEN' ? 'SO' : 'DO',
                TDO_CODTDO: this.tiposDocumento.find(item => item.TDO_CODOFI == INVOICE['CBC:INVOICETYPECODE'][0]['_']).TDO_CODTDO,
                CCO_GLOCCO: (INVOICE['CAC:INVOICELINE'][0]['CAC:ITEM'][0]['CBC:DESCRIPTION'][0]).slice(0, 200),
                CCO_TASIGV: parseFloat((INVOICE['CAC:INVOICELINE'][0]['CAC:TAXTOTAL'][0]['CAC:TAXSUBTOTAL'][0]['CAC:TAXCATEGORY'][0]['CBC:PERCENT'][0]).toString()),
                CCO_IMPIGV: parseFloat((INVOICE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_']).toString()),
                CCO_IMPDOC: parseFloat((INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString()),
                CCO_IMPAFE,
                CCO_IMPINA,
                CCO_CODUSE: this._configurationService.obtenerIdUsuario(),
                valvta,
                CCO_FECUPD: new Date()
              }

            } else {

              let CCO_IMPAFE = INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:LINEEXTENSIONAMOUNT'] ? INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:LINEEXTENSIONAMOUNT'][0]['_'] : INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_'];
              let CCO_IMPIGV = INVOICE['N1:TAXTOTAL'][0]['N2:TAXAMOUNT'][0]['_']
              let CCO_IMPDOC = parseFloat((INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_']).toString());
              let CCO_IMPINA = 0;

              if (CCO_IMPIGV == 0) {
                CCO_IMPINA = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPAFE = 0;
              } else {
                CCO_IMPAFE = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPINA = 0;

                if (CCO_IMPAFE + CCO_IMPIGV != CCO_IMPDOC) {
                  CCO_IMPAFE = parseFloat((CCO_IMPDOC - (CCO_IMPAFE + CCO_IMPIGV)).toFixed(2))
                }
              }

              let valvta = CCO_IMPINA + CCO_IMPAFE;
              CCO_IMPDOC = CCO_IMPAFE + CCO_IMPINA + CCO_IMPIGV;

              const tmo = INVOICE['N2:DOCUMENTCURRENCYCODE'][0]['_'] ? INVOICE['N2:DOCUMENTCURRENCYCODE'][0]['_'] : INVOICE['N2:DOCUMENTCURRENCYCODE'][0];

              return {
                estado: 'En recepción',
                correlativo: (index + 1) > 9 ? index + 1 : `0${index + 1}`,
                CLI_CODCLI: INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N2:CUSTOMERASSIGNEDACCOUNTID'] ? INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N2:CUSTOMERASSIGNEDACCOUNTID'][0]['_'] : INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N1:PARTY'][0]['N1:PARTYIDENTIFICATION'][0]['N2:ID'][0]['_'],
                sad_codsad: INVOICE['N2:ID'][0]['_'] ? INVOICE['N2:ID'][0]['_'].split('-')[0] : INVOICE['N2:ID'][0].split('-')[0],
                CCO_NUMDOC: INVOICE['N2:ID'][0]['_'].split('-')[0] + this.completarCerosIzquierda(INVOICE['N2:ID'][0]['_'].split('-')[1]),
                CCO_FECEMI: INVOICE['N2:ISSUEDATE'][0]['_'] ? new Date(INVOICE['N2:ISSUEDATE'][0]['_']) : new Date(INVOICE['N2:ISSUEDATE'][0]),
                TMO_CODTMO: tmo == 'PEN' ? 'SO' : 'DO',
                TDO_CODTDO: this.tiposDocumento.find(item => item.TDO_CODOFI == INVOICE['N2:INVOICETYPECODE'][0]['_']).TDO_CODTDO,
                CCO_GLOCCO: INVOICE['N1:INVOICELINE'][0]['N1:ITEM'][0]['N2:DESCRIPTION'][0],
                CCO_TASIGV: parseFloat((INVOICE['N1:INVOICELINE'][0]['N1:TAXTOTAL'][0]['N1:TAXSUBTOTAL'][0]['N1:TAXCATEGORY'][0]['N2:PERCENT'][0]).toString()),
                CCO_IMPIGV: parseFloat((INVOICE['N1:TAXTOTAL'][0]['N2:TAXAMOUNT'][0]['_']).toString()),
                CCO_IMPDOC: parseFloat((INVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_']).toString()),
                CCO_IMPAFE,
                CCO_IMPINA,
                valvta,
                CCO_CODUSE: this._configurationService.obtenerIdUsuario(),
                CCO_FECUPD: new Date()
              }

            }
          }

          if (document.SELFBILLEDINVOICE) {
            const SELFBILLEDINVOICE = document.SELFBILLEDINVOICE;
            console.log('SELFBILLEDINVOICE')
            if (SELFBILLEDINVOICE['CBC:INVOICETYPECODE']) {
              let CCO_IMPAFE = SELFBILLEDINVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? parseFloat(SELFBILLEDINVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_']) : parseFloat(SELFBILLEDINVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);
              let CCO_IMPIGV = parseFloat(SELFBILLEDINVOICE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_'])
              let CCO_IMPDOC = parseFloat((SELFBILLEDINVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString());
              let CCO_IMPINA = 0;

              if (CCO_IMPIGV == 0) {
                CCO_IMPINA = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPAFE = 0;
              } else {
                CCO_IMPAFE = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPINA = 0;

                if (CCO_IMPAFE + CCO_IMPIGV != CCO_IMPDOC) {
                  CCO_IMPINA = parseFloat((CCO_IMPDOC - (CCO_IMPAFE + CCO_IMPIGV)).toFixed(2))
                }
              }

              let valvta = CCO_IMPINA + CCO_IMPAFE;
              CCO_IMPDOC = CCO_IMPAFE + CCO_IMPINA + CCO_IMPIGV;

              const tmo = SELFBILLEDINVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? SELFBILLEDINVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : SELFBILLEDINVOICE['CBC:DOCUMENTCURRENCYCODE'][0];

              return {
                estado: 'En recepción',
                correlativo: (index + 1) > 9 ? index + 1 : `0${index + 1}`,
                CLI_CODCLI: SELFBILLEDINVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'] ? SELFBILLEDINVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : SELFBILLEDINVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'],
                sad_codsad: SELFBILLEDINVOICE['CBC:ID'][0].split('-')[0],
                CCO_NUMDOC: SELFBILLEDINVOICE['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(SELFBILLEDINVOICE['CBC:ID'][0].split('-')[1]),
                CCO_FECEMI: new Date(SELFBILLEDINVOICE['CBC:ISSUEDATE'][0]),
                TMO_CODTMO: tmo == 'PEN' ? 'SO' : 'DO',
                TDO_CODTDO: this.tiposDocumento.find(item => item.TDO_CODOFI == SELFBILLEDINVOICE['CBC:INVOICETYPECODE'][0]['_']).TDO_CODTDO,
                CCO_GLOCCO: (SELFBILLEDINVOICE['CAC:INVOICELINE'][0]['CAC:ITEM'][0]['CBC:DESCRIPTION'][0]).slice(0, 200),
                CCO_TASIGV: parseFloat((SELFBILLEDINVOICE['CAC:INVOICELINE'][0]['CAC:TAXTOTAL'][0]['CAC:TAXSUBTOTAL'][0]['CAC:TAXCATEGORY'][0]['CBC:PERCENT'][0]).toString()),
                CCO_IMPIGV: parseFloat((SELFBILLEDINVOICE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_']).toString()),
                CCO_IMPDOC: parseFloat((SELFBILLEDINVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString()),
                CCO_IMPAFE,
                CCO_IMPINA,
                CCO_CODUSE: this._configurationService.obtenerIdUsuario(),
                valvta,
                CCO_FECUPD: new Date()
              }

            } else {

              let CCO_IMPAFE = SELFBILLEDINVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:LINEEXTENSIONAMOUNT'] ? SELFBILLEDINVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:LINEEXTENSIONAMOUNT'][0]['_'] : SELFBILLEDINVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_'];
              let CCO_IMPIGV = SELFBILLEDINVOICE['N1:TAXTOTAL'][0]['N2:TAXAMOUNT'][0]['_']
              let CCO_IMPDOC = parseFloat((SELFBILLEDINVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_']).toString());
              let CCO_IMPINA = 0;

              if (CCO_IMPIGV == 0) {
                CCO_IMPINA = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPAFE = 0;
              } else {
                CCO_IMPAFE = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPINA = 0;

                if (CCO_IMPAFE + CCO_IMPIGV != CCO_IMPDOC) {
                  CCO_IMPAFE = parseFloat((CCO_IMPDOC - (CCO_IMPAFE + CCO_IMPIGV)).toFixed(2))
                }
              }

              let valvta = CCO_IMPINA + CCO_IMPAFE;
              CCO_IMPDOC = CCO_IMPAFE + CCO_IMPINA + CCO_IMPIGV;

              const tmo = SELFBILLEDINVOICE['N2:DOCUMENTCURRENCYCODE'][0]['_'] ? SELFBILLEDINVOICE['N2:DOCUMENTCURRENCYCODE'][0]['_'] : SELFBILLEDINVOICE['N2:DOCUMENTCURRENCYCODE'][0];

              return {
                estado: 'En recepción',
                correlativo: (index + 1) > 9 ? index + 1 : `0${index + 1}`,
                CLI_CODCLI: SELFBILLEDINVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N2:CUSTOMERASSIGNEDACCOUNTID'] ? SELFBILLEDINVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N2:CUSTOMERASSIGNEDACCOUNTID'][0]['_'] : SELFBILLEDINVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['N1:PARTY'][0]['N1:PARTYIDENTIFICATION'][0]['N2:ID'][0]['_'],
                sad_codsad: SELFBILLEDINVOICE['N2:ID'][0]['_'] ? SELFBILLEDINVOICE['N2:ID'][0]['_'].split('-')[0] : SELFBILLEDINVOICE['N2:ID'][0].split('-')[0],
                CCO_NUMDOC: SELFBILLEDINVOICE['N2:ID'][0]['_'].split('-')[0] + this.completarCerosIzquierda(SELFBILLEDINVOICE['N2:ID'][0]['_'].split('-')[1]),
                CCO_FECEMI: SELFBILLEDINVOICE['N2:ISSUEDATE'][0]['_'] ? new Date(SELFBILLEDINVOICE['N2:ISSUEDATE'][0]['_']) : new Date(SELFBILLEDINVOICE['N2:ISSUEDATE'][0]),
                TMO_CODTMO: tmo == 'PEN' ? 'SO' : 'DO',
                TDO_CODTDO: this.tiposDocumento.find(item => item.TDO_CODOFI == SELFBILLEDINVOICE['N2:INVOICETYPECODE'][0]['_']).TDO_CODTDO,
                CCO_GLOCCO: SELFBILLEDINVOICE['N1:INVOICELINE'][0]['N1:ITEM'][0]['N2:DESCRIPTION'][0],
                CCO_TASIGV: parseFloat((SELFBILLEDINVOICE['N1:INVOICELINE'][0]['N1:TAXTOTAL'][0]['N1:TAXSUBTOTAL'][0]['N1:TAXCATEGORY'][0]['N2:PERCENT'][0]).toString()),
                CCO_IMPIGV: parseFloat((SELFBILLEDINVOICE['N1:TAXTOTAL'][0]['N2:TAXAMOUNT'][0]['_']).toString()),
                CCO_IMPDOC: parseFloat((SELFBILLEDINVOICE['N1:LEGALMONETARYTOTAL'][0]['N2:PAYABLEAMOUNT'][0]['_']).toString()),
                CCO_IMPAFE,
                CCO_IMPINA,
                valvta,
                CCO_CODUSE: this._configurationService.obtenerIdUsuario(),
                CCO_FECUPD: new Date()
              }

            }

          }

          if (document.CREDITNOTE) {
            const CREDITNOTE = document.CREDITNOTE;

            if (true) {
              let CCO_IMPAFE = CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_'] : CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_'];
              CCO_IMPAFE = parseFloat(CCO_IMPAFE.toString());

              let CCO_IMPIGV = parseFloat((CREDITNOTE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_']).toString());

              let CCO_IMPDOC = parseFloat((CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString());

              let CCO_IMPINA = 0;
              let dpc_impfis = 0;
              let dpc_impsis = 0;

              if (CCO_IMPIGV == 0) {
                CCO_IMPINA = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPAFE = 0;
              } else {
                CCO_IMPAFE = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPINA = 0;

                if (CCO_IMPAFE + CCO_IMPIGV != CCO_IMPDOC) {
                  if (CREDITNOTE["CAC:ALLOWANCECHARGE"]) {
                    dpc_impfis = parseFloat(CREDITNOTE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
                  } else {
                    CCO_IMPINA = parseFloat((CCO_IMPDOC - (CCO_IMPAFE + CCO_IMPIGV)).toFixed(2))
                  }
                }
              }

              let valvta = CCO_IMPINA + CCO_IMPAFE + dpc_impfis + dpc_impsis;
              CCO_IMPDOC = valvta + CCO_IMPIGV

              let CCO_CODUSE = this._configurationService.obtenerIdUsuario();
              let CCO_FECUPD = new Date();

              const maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

              let CCO_FECREG = maxDate;
              let CCO_FECEMI = new Date(CREDITNOTE['CBC:ISSUEDATE'][0]);
              let CCO_FECVEN = CCO_FECEMI;
              let CPA_CODCPA = null;
              let TMO_CODTMO = CREDITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? CREDITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : CREDITNOTE['CBC:DOCUMENTCURRENCYCODE'][0];

              if (CCO_FECEMI == CCO_FECVEN) CPA_CODCPA = '112';

              TMO_CODTMO = TMO_CODTMO == "PEN" ? "SO" : "DO";

              if (CREDITNOTE["CAC:PAYMENTTERMS"]) {
                const formaPago = CREDITNOTE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
                if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
                  CPA_CODCPA = '112';
                }

                if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

                  console.log('formapago', formaPago)
                  const fecVen = new Date(CREDITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
                  const fecToday = new Date().toUTCString();
                  CCO_FECVEN = new Date(CREDITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]);
                  console.log('fecha hoy', fecToday)
                  console.log('fecha ven', fecVen)

                  const dias = retornaDiferenciaDias(fecVen, fecToday);
                  console.log('dias', dias)
                }

              }
              return {
                estado: 'En recepción',
                correlativo: (index + 1) > 9 ? index + 1 : `0${index + 1}`,
                CLI_CODCLI: CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'] ? CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'],
                CLI_CODDOC: CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'] ? CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : CREDITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'],
                sad_codsad: CREDITNOTE['CBC:ID'][0].split('-')[0],
                CCO_NUMDOC: CREDITNOTE['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(CREDITNOTE['CBC:ID'][0].split('-')[1]),
                TMO_CODTMO,
                TDO_CODTDO: 'NCR',
                CCO_GLOCCO: (CREDITNOTE['CAC:CREDITNOTELINE'][0]['CAC:ITEM'][0]['CBC:DESCRIPTION'][0]).slice(0, 200),
                CCO_TASIGV: parseFloat((CREDITNOTE['CAC:CREDITNOTELINE'][0]['CAC:TAXTOTAL'][0]['CAC:TAXSUBTOTAL'][0]['CAC:TAXCATEGORY'][0]['CBC:PERCENT'][0]).toString()),
                CCO_IMPIGV: parseFloat((CREDITNOTE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_']).toString()),
                CCO_IMPDOC: parseFloat((CREDITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString()),
                TDO_DOCREF: this.tiposDocumento.find(item => item.TDO_CODOFI == CREDITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:DOCUMENTTYPECODE'][0]['_']).TDO_CODTDO,
                CCO_NUMREF: CREDITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(CREDITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:ID'][0].split('-')[1]),
                CCO_IMPAFE,
                CCO_IMPINA,
                CCO_FECREG,
                CCO_FECEMI,
                CCO_FECVEN,
                CPA_CODCPA,
                valvta,
                CCO_CODUSE: this._configurationService.obtenerIdUsuario(),

                CCO_FECUPD: new Date()
              }
            } else {

            }
          }

          if (document.DEBITNOTE) {
            const DEBITNOTE = document.DEBITNOTE;

            if (true) {
              let CCO_IMPAFE = DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_'];
              let CCO_IMPIGV = DEBITNOTE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_']
              let CCO_IMPDOC = parseFloat((DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString());
              let CCO_IMPINA = 0;

              if (CCO_IMPIGV == 0) {
                CCO_IMPINA = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPAFE = 0;
              } else {
                CCO_IMPAFE = parseFloat(CCO_IMPAFE.toString());
                CCO_IMPINA = 0;

                if (CCO_IMPAFE + CCO_IMPIGV != CCO_IMPDOC) {
                  CCO_IMPAFE = parseFloat((CCO_IMPDOC - (CCO_IMPAFE + CCO_IMPIGV)).toFixed(2))
                }
              }

              let valvta = CCO_IMPINA + CCO_IMPAFE;
              CCO_IMPDOC = CCO_IMPAFE + CCO_IMPINA + CCO_IMPIGV;

              const tmo = DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0];

              return {
                estado: 'En recepción',
                correlativo: (index + 1) > 9 ? index + 1 : `0${index + 1}`,
                CLI_CODCLI: DEBITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0],
                sad_codsad: DEBITNOTE['CBC:ID'][0].split('-')[0],
                CCO_NUMDOC: DEBITNOTE['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(DEBITNOTE['CBC:ID'][0].split('-')[1]),
                CCO_FECEMI: new Date(DEBITNOTE['CBC:ISSUEDATE'][0]),
                TMO_CODTMO: tmo == 'PEN' ? 'SO' : 'DO',
                TDO_CODTDO: this.tiposDocumento.find(item => item.TDO_CODOFI == DEBITNOTE['CBC:CREDITNOTETYPECODE'][0]['_']).TDO_CODTDO,
                CCO_GLOCCO: (DEBITNOTE['CAC:CREDITNOTELINE'][0]['CAC:ITEM'][0]['CBC:DESCRIPTION'][0]).slice(0, 200),
                CCO_TASIGV: parseFloat((DEBITNOTE['CAC:CREDITNOTELINE'][0]['CAC:TAXTOTAL'][0]['CAC:TAXSUBTOTAL'][0]['CAC:TAXCATEGORY'][0]['CBC:PERCENT'][0]).toString()),
                CCO_IMPIGV: parseFloat((DEBITNOTE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_']).toString()),
                CCO_IMPDOC: parseFloat((DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']).toString()),
                TDO_DOCREF: this.tiposDocumento.find(item => item.TDO_CODOFI == DEBITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:DOCUMENTTYPECODE'][0]['_']).TDO_CODTDO,
                CCO_NUMREF: DEBITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:ID'][0].split('-')[0] + this.completarCerosIzquierda(DEBITNOTE["CAC:BILLINGREFERENCE"][0]["CAC:INVOICEDOCUMENTREFERENCE"][0]['CBC:ID'][0].split('-')[1]),
                CCO_IMPAFE,
                CCO_IMPINA,
                valvta,
                CCO_CODUSE: this._configurationService.obtenerIdUsuario(),

                CCO_FECUPD: new Date()
              }
            }

          }
        } catch (error) {
          console.log('error', error.message  )
          console.log('error', error  )
          return {
            estado: 'error',
            mensaje: `${error.message}. Documento ubicado en la fila ${index + 1} de la tabla`
          };
        }

      });

      this.dataSourceImagenes = fillTable([], this.paginatorImagenes, this.sortImagenes)

      this.movingFilesXMLToReception = false;
      this.loaderEnviarRecepcion = false;
      console.log('estandarJSON', estandarJSON)
      this.documentosValidos = estandarJSON.filter(item => item.estado != 'error');
      this.documentosNoValidos = estandarJSON.filter(item => item.estado == 'error');
      console.log('documentosValidos', this.documentosValidos)
      console.log('documentosNoValidos', this.documentosNoValidos)
      this.onMoverXMLARecepcion.emit(this.documentosValidos);

    }, 2000);

  }

  cargarXmls(e: any): void {
    console.log(e.target.files)
    this.dataSourceImagenes = fillTable(Array.from(e.target.files), this.paginatorImagenes, this.sortImagenes)

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.consultaExistenciaRucs$
    ])
  }
}
