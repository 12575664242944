import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContabilidadService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { TipoCambioService } from 'src/app/services/api/contabilidad/maestros/tipo-cambio.service';

@Component({
  selector: 'tipo-cambio-list',
  templateUrl: './tipo-cambio-list.component.html',
  styleUrls: ['./tipo-cambio-list.component.css']
})
export class TipoCambioListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'TCA_FECTCA', 'TMO_MONNAC', 'TMO_MONEXT', 'TCA_COMOFI', 'TCA_VENOFI', 'TCA_CODUSE'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tipoCambio$: Subscription;
  periodo$: Subscription;
  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  anioPeriodo: string;
  mesPeriodo: string;

  constructor(
    private _tipoCambioService: TipoCambioService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerTiposDeCambio();
    })

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })
  }

  obtenerTiposDeCambio(): void {
    this.loaderData = true;

    this.tipoCambio$ = this._tipoCambioService.obtenerTiposCambio().subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort)
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoCambio$
    ])
  }
}
