import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { TipoInventario } from 'src/app/models/almacen/tipo_inventario';
import { UnidadMedida } from 'src/app/models/almacen/unidad_medida';
import { Storehouse } from 'src/app/models';
import { UbicacionAlmacen } from 'src/app/models/almacen/ubicacion-almacen';
import { map } from 'rxjs/operators';
import { FiltroMovimientos } from 'src/app/models/almacen/filtro-movimientos';
import { IParam, generarParams } from './utilities/params';

@Injectable({
  providedIn: 'root',
})
export class AlmacenService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @returns
   */
  obtenerConfiguraEnlaceMotivoAlmacen(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/configuracion-enlace-motivo-almacen/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param alm
   * @param tdo
   * @param mmo
   * @returns
   */
  verConfiguraEnlaceMotivoAlmacen(alm, tdo, mmo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/ver-configuracion-enlace-motivo-almacen/${this._configurationService.obtenerCompaniaCliente()}/${alm}/${tdo}/${mmo}`
    );
  }

  /**
   *
   * @returns
   */
  listarMotivoCreditoSunat(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/motivo-credito-sunat`
    );
  }

  /**
   *
   * @returns
   */
  listarMotivoDebitoSunat(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/motivo-debito-sunat`
    );
  }

  /**
   *
   * @returns
   */
  obtenerEstablecimientos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listarestablecimiento/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerTiposDeAlmacen(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listartipoalmacen/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }



  /**
   *
   * @param id
   * @returns
   */
  mostrarMotivo(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/mostrarmotivo/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  registrarMotivo(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/creareditarmotivo`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarMotivo(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/creareditarmotivo`,
      data
    );
  }

  /**
   *
   * @returns
   */
  obtenerMotivos(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/motivos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param nro_orden
   * @returns
   */
  obtenerProductosDesdeOrden(nro_orden): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/productosorden/${this._configurationService.obtenerCompaniaCliente()}/${nro_orden}`
    );
  }

  /**
   *
   * @param nro_liq
   * @returns
   */
  obtenerProductosDesdeLiq(nro_liq): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/productosorden/${this._configurationService.obtenerCompaniaCliente()}/${nro_liq}`
    );
  }

  /**
   *
   * @param nro_req
   * @returns
   */
  obtenerProductosDesdeReq(nro_req): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/productosrequerimiento/${this._configurationService.obtenerCompaniaCliente()}/${nro_req}`
    );
  }

  /**
   *
   * @param tipo
   * @param nro
   * @returns
   */
  validarGuiaRemisión(tipo, nro): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/validaguiaremision/01/${tipo}/${nro}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerLineasDeProducto(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listalineaproducto/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerMarcasDeProducto(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listamarcaproducto/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarGuiasRemision() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/listarguiasremision/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  obtenerMovimientos(anio, mes, filtro: FiltroMovimientos): Observable<any> {

    let params = '';

    console.log('filtro', filtro)

    if (filtro.tipoMovimiento) params += `?tipodocumento=${filtro.tipoMovimiento}`;
    if (filtro.almacenes) params += `?almacen=${filtro.almacenes}`;
    if (filtro.auxiliares) params += `?auxiliar=${filtro.auxiliares}`;
    if (filtro.motivos) params += `?motivo=${filtro.motivos}`;

    console.log('params', params)

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/kardex/listar/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}${params}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param almacen
   * @param tipo
   * @param numeroParte
   * @returns
   */
  obtenerMovimiento(year, month, almacen, tipo, numeroParte): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/mostrarmovimientos/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}/${almacen}/${tipo}/${numeroParte}`
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  agregarMovimiento(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/creareditarparte`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarMovimiento(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/creareditarparte`,
      data
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param almacen
   * @param tipo
   * @param numeroParte
   * @returns
   */
  mostrarPdfMovimiento(
    year,
    month,
    almacen,
    tipo,
    numeroParte
  ): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/mostrarpartepdf/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}/${almacen}/${tipo}/${numeroParte}`
    );
  }

  /**
   *
   * @param almacen
   * @returns
   */
  listarMotivosParteMovimientoIngreso(almacen) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listarmotivo/${this._configurationService.obtenerCompaniaCliente()}/${almacen}/ING`
    );
  }

  /**
   *
   * @param almacen
   * @returns
   */
  listarMotivosParteMovimientoSalida(almacen) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listarmotivo/${this._configurationService.obtenerCompaniaCliente()}/${almacen}/SAL`
    );
  }

  /**
   *
   * @returns
   */
  listarChoferes(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listarchoferes/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((choferes: any) => {
        return choferes.map(chofer => {
          chofer['description'] = `${chofer.CHO_CODCHO} | ${chofer.CHO_NOMBRE}`
          return chofer;
        })
      })
    );
  }

  /**
   *
   * @returns
   */
  listarAuxiliares() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/listar/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((clientes: any) => {
        return clientes.filter(cliente => {
          return cliente.cli_indtra == 1;
        })
      })
    )
  }

  /**
   *
   * @returns
   */
  obtenerTipoDocumentoMov() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listadocumento`
    );
  }

  /**
   *
   * @returns
   */
  listarOrdenesCompra() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listarordenescompra/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarRequerimientoMateriales() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listareqmateriales/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param serie
   * @returns
   */
  obtenerCorrelativoGuia(serie) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/correlativoguia/${this._configurationService.obtenerCompaniaCliente()}/${serie}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerInvoices() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listanroinvoice/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerLiquidaciones() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listaliquidacionproduccion/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerTransportistas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/transportistas/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((transportista: any) => {
        return transportista.map(transportistas => {
          transportistas['description'] = `${transportistas.cli_codcli} | ${transportistas.cli_nomcli}`
          return transportistas;
        })
      })
    );
  }

  /**
   *
   * @returns
   */
  obtenerAlamacenes(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/almacenes/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((almacenes: any) => {
        return almacenes.map(almacen => {
          almacen['description'] = `${almacen.ALM_CODALM} | ${almacen.ALM_DESCRI} `
          almacen['description_reverse'] = `${almacen.ALM_DESCRI} | ${almacen.ALM_CODALM}`
          return almacen;
        })
      })
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  listarSerieDocumentos(tipo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/listaseriedocumento/${this._configurationService.obtenerCompaniaCliente()}/${tipo}`
    );
  }

  /**
   *
   * @returns
   */
  listarSucursales(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/sucursal/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarTiposDeInventario(): Observable<Array<TipoInventario>> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/tipos-de-inventario/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  listarUnidadesDeMedida(): Observable<Array<UnidadMedida>> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/unidades-de-medida/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  revisarSaldoNegativo(year, month): Observable<Array<UnidadMedida>> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/revisa-saldo-negativo/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    );
  }

  /**
   *
   * @param tipoInventario
   * @returns
   */
  registrarTipoInventario(tipoInventario: TipoInventario): Observable<Array<UnidadMedida>> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/tipo-inventario/${this._configurationService.obtenerCompaniaCliente()}`,
      tipoInventario
    );
  }

  /**
   *
   * @param tipoInventario
   * @returns
   */
  actualizarTipoInventario(tipoInventario: TipoInventario): Observable<Array<UnidadMedida>> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/tipo-inventario/${this._configurationService.obtenerCompaniaCliente()}`,
      tipoInventario
    );
  }

  /**
   *
   * @param almacen
   * @returns
   */
  registrarAlmacen(almacen: Storehouse): Observable<Array<UnidadMedida>> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/almacen/${this._configurationService.obtenerCompaniaCliente()}`,
      almacen
    );
  }

  /**
   *
   * @param almacen
   * @returns
   */
  actualizarAlmacen(almacen: Storehouse): Observable<Array<UnidadMedida>> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/almacen/${this._configurationService.obtenerCompaniaCliente()}`,
      almacen
    );
  }

  /**
   *
   * @param unidadMedida
   * @returns
   */
  registrarUnidadMedida(unidadMedida: UnidadMedida): Observable<Array<UnidadMedida>> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/unidad-de-medida/${this._configurationService.obtenerCompaniaCliente()}`,
      unidadMedida
    );
  }

  /**
   *
   * @param unidadMedida
   * @returns
   */
  actualizarUnidadMedida(unidadMedida: UnidadMedida): Observable<Array<UnidadMedida>> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/unidad-de-medida/${this._configurationService.obtenerCompaniaCliente()}`,
      unidadMedida
    );
  }

  /**
   *
   * @returns
   */
  obtenerUbicacionesAlmacen(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/ubicacion/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param ubicacion
   * @returns
   */
  registrarUbicacionAlmacen(ubicacion: UbicacionAlmacen): Observable<Array<UnidadMedida>> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/ubicacion/${this._configurationService.obtenerCompaniaCliente()}`,
      ubicacion
    );
  }

  /**
   *
   * @param ubicacion
   * @returns
   */
  actualizarUbicacionAlmacen(ubicacion: UbicacionAlmacen): Observable<Array<UnidadMedida>> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/almacen/ubicacion/${this._configurationService.obtenerCompaniaCliente()}`,
      ubicacion
    );
  }

  /**
   * Obtener informe de saldo por producto
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeSaldoPorProducto(year, month, payload, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.post(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-saldo-producto/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`,
      payload
    );
  }

  /**
  * Obtener informe de saldo por producto
  * @param year
  * @param month
  * @returns
  */
  obtenerInformeSaldoPorLote(year, month, payload, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.post(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-saldo-lote/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`,
      payload
    );
  }

  /**
   * Obtener informe de saldo por producto valorizado
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeSaldoPorProductoValorizado(year, month, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-saldo-producto-valorizado/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`
    );
  }

  /**
   * Obtener informe Kardex Mensual
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeKardexMensual(year, month, payload, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);


    return this.http.post(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-kardex-mensual/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`,
      payload
    )
  }

  /**
   * Obtener informe Kardex Lote
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeKardexLote(year, month, payload, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.post(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-kardex-lote/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`,
      payload
    )
  }

  /**
   * Obtener informe Resumen de productos por clasificados
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeResumenProductosClasificados(year, month, payload, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.post(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-resumen-productos-clasificados/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`,
      payload
    )
  }

  /**
   * Obtener informe de movimiento por motivo
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeMovimientoPorMotivo(year, month, payload, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.post(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-movimiento-por-motivo/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`,
      payload
    )
  }

  /**
   * Obtener informe de movimiento por motivo valorizado
   * @param year
   * @param month
   * @returns
   */
  obtenerInformeMovimientoPorMotivoValorizado(year, month, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-movimiento-por-motivo-valorizado/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`
    )
  }

  /**
   * Obtener informe de resumen anual por tipo de inventario
   * @param year
   * @returns
   */
  obtenerInformeResumenAnualTipoInventario(year, {params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-resumen-anual-por-tipo-inventario/${this._configurationService.obtenerCompaniaCliente()}/${year}${paramsStr}`
    )
  }

  /**
   * Obtener data filtro almacén
   * @returns
   */
  obtenerDataFiltroAlmacen(): Observable<any> {
    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/filtro-reporte/almacen/${this._configurationService.obtenerCompaniaCliente()}`
    )
  }

  /**
   * Obtener data filtro consultar stock
   * @returns
   */
  obtenerDataFiltroConsultarStock(): Observable<any> {
    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/almacen/data-filtro-consultar-stock/${this._configurationService.obtenerCompaniaCliente()}`
    )
  }

  consultarStock(payload, year: string, month: string): Observable<any> {
    return this.http.post(
      `${this._configurationService.obtenerUrl()}/api/almacen/consultar-stock/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`, payload
    )
  }

  consultarStockProductosConsolidado(year: string, month: string, { params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/stock/listarsaldooproducto/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`
    )

  }

  consultarStockKardexLote(year: string, month: string, { params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/kardex/listarkardexlote/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`
    );

  }

  consultarStockDetalleAlmacen(): void {

  }

  consultarStockDetalleLote(year: string, month: string, { params = [] }: { params?: Array<IParam> } = {}): Observable<any> {
    const paramsStr = generarParams(params);

    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/stock/listarsaldolote/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`
    )
  }

  obtenerInformeDinamicoSaldoPorProducto(year, month, { params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-dinamico-saldo-producto/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}${paramsStr}`
    );
  }

  obtenerInformeDinamicoMovimientoPorMotivo(year, month): Observable<any> {
    return this.http.get(
      `${this._configurationService.obtenerUrl()}/api/almacen/informe-dinamico-movimiento-por-motivo/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }
}
