import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { AuthenticationService, ContabilidadService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { MatRipple } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { REPORTES_PLANILLA_MENSUALES } from '@data/json/reportes';
import { HeaderReport } from '@data/interfaces/headers.report';
import { HeaderReportService } from 'src/app/services/reports/config/header-report.service';
import { NominaReportService } from 'src/app/services/reports/modules/nomina-report.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { FiltroReportePlanilla } from 'src/app/models/planilla/filtro-reporte-planilla';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-informes-mensuales',
  templateUrl: './informes-mensuales.component.html',
  styleUrls: ['./informes-mensuales.component.css']
})
export class InformesMensualesComponent implements OnInit, OnDestroy {

  trabajadores: any[] = [];
  centros: any[] = [];
  conceptos: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: any;
  month: any;

  yearIni: number;
  monthIni: string;
  yearFin: number;
  monthFin: string;

  months = ["01","02","03","04","05","06","07","08","09","10","11","12"];

  reportes: any[] = REPORTES_PLANILLA_MENSUALES;

  reporteSeleccionado: any = [REPORTES_PLANILLA_MENSUALES[0].id];

  mostrarFiltroAuxiliar: 0 | 1 = 0;
  mostrarFiltroCentro: 0 | 1 = 0;
  mostrarFiltroConcepto: 0 | 1 = 0;
  mostrarFiltroRangoFecha: 0 | 1 = 0;

  @ViewChild(MatRipple) ripple: MatRipple;

  loading: boolean = false;

  period$: Subscription;
  loading$: Subscription;
  indicadores$: Subscription;
  listaColab$: Subscription;
  listaConceptos$: Subscription;
  listaCentros$: Subscription;

  filtro: FiltroReportePlanilla;

  constructor(
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _headerReportService: HeaderReportService,
    private _nominaService: NominaService,
    private _nominaReportService: NominaReportService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.filtro = new FiltroReportePlanilla();

    this.period$ = this.store.select('period').subscribe(
      state => {
        this.year = state.year;
        this.month = state.month;

        this.monthIni = "01";
        this.yearIni = Number(this.year);
        this.monthFin = this.month;
        this.yearFin = Number(this.year);
      }
    )

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
    this.seleccionarReporte(REPORTES_PLANILLA_MENSUALES[0].id);
  }

  loadData(): void {

    forkObs(
      this._nominaService.listarColaboradores(),
      this._nominaService.listarConceptos(),
      this._contabilidadService.listarCentroCostos()
    ).then(data => {
      this.trabajadores = data[0];
      this.conceptos = data[1].filter(item => item.ind_estado == "1");
      this.centros = data[2];
      this._snackBarService.showError("Error al obtener centro de costos", "OK")
    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK")
    })

  }

  verReporte(): void {
    this.loading = true;
    const headers: HeaderReport = this._headerReportService.obtenerCabecera()

    const payload = {
      colaboradores: (this.filtro.colaboradores)? this.filtro.colaboradores: [],
      centros: (this.filtro.centros)? this.filtro.centros: [],
      conceptos: (this.filtro.conceptos)? this.filtro.conceptos: [],
    };

    const lastDate = new Date(Number(this.yearFin), Number(this.monthFin), 0);

    const otherData = {
      dateIni: `01/${this.monthIni}/${this.yearIni}`,
      dateFin: `${lastDate.getDate()}/${this.monthFin}/${this.yearFin}`,
      lastDay: (new Date(Number(this.year), Number(this.month), 0)).getDate()
    }

    this._nominaReportService.verReporteMensual(this.reporteSeleccionado[0], headers, payload, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)
  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;

    let indicador = "";
    this.mostrarFiltroRangoFecha = 0;
    switch (this.reporteSeleccionado[0]) {
      case "PLANILLA_PRA":
        indicador = "51002";
        break;
      case "PLANILLA_PL":
        indicador = "51001"
        break;
      case "PLANILLA_PCAA":
        this.mostrarFiltroAuxiliar = 1;
        this.mostrarFiltroCentro = 0;
        this.mostrarFiltroConcepto = 0;
        break;
      case "PLANILLA_RP":
        indicador = "51003"
        break;
      case "PLANILLA_RPL":
        indicador = "51003"
        break;
      default:
        break;
    }

    this.indicadores$ = this._contabilidadService.obtenerInidicadoresFiltro(indicador).subscribe(
      ([response]) => {
        if(response) {
          this.mostrarFiltroAuxiliar = response.ind_auxiliar;
          this.mostrarFiltroCentro = response.ind_centrocosto;
          this.mostrarFiltroConcepto = response.ind_conceptoplanilla;
        }
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.indicadores$,
      this.listaColab$,
      this.listaConceptos$,
      this.listaCentros$
    ]);
  }

}
