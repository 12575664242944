import { formatDateWithDash } from "@utils/formats/date.format";

export class FiltroConsultaSeguimiento {

  responsables: Array<any>;
  lineas: Array<any>;
  tiposNegociacion: Array<any>;
  clientes: Array<any>;
  etapas: Array<any>;
  canales: Array<any>;
  desde: Date;
  hasta: Date;
  fecini: string;
  fecter: string;
  situacion: string;
  situaciones: Array<any>;
  moneda: string;

  constructor() {
    this.responsables = [];
    this.lineas = [];
    this.tiposNegociacion = [];
    this.clientes = [];
    this.etapas = [];
    this.canales = [];
    this.situaciones = [];
    const now = new Date();
    this.desde = new Date(now.getUTCFullYear() - 1, 0, 1);
    this.hasta = new Date();
    this.fecini = formatDateWithDash(this.desde);
    this.fecter = formatDateWithDash(this.hasta);
    this.situacion = 'AMBOS';
    this.moneda = 'AMBOS'
  }

}
