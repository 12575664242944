import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription, of } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { LineaProductoService } from 'src/app/services/api/produccion/maestros/linea-producto.service';
import { OperarioService } from 'src/app/services/api/produccion/maestros/operario.service';
import { forkObs } from '@utils/observables/fork';
import { LineaOperacionService } from 'src/app/services/api/produccion/maestros/linea-operacion.service';
import { LineaOperacion } from 'src/app/models/produccion/maestros/linea-operacion';

@Component({
  selector: 'app-linea-prod-operario-form',
  templateUrl: './linea-prod-operario-form.component.html',
  styleUrls: ['./linea-prod-operario-form.component.css']
})
export class LineaProdOperarioFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = true;

  lineaOperacion: LineaOperacion;
  lineasProducto: any[] = [];
  operarios: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  loading$: Subscription;
  sendForm$: Subscription;
  uid: string;
  idsec: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _lineaOperacionService: LineaOperacionService,
    private _lineaProductoService: LineaProductoService,
    private _operarioService: OperarioService,
    private store: Store<PavsoState>,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _snackbarService: SnackBarService
  ) {
    this.lineaOperacion = new LineaOperacion();
    this.lineaOperacion.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.lineaOperacion.lpo_coduse = this._configurationService.obtenerIdUsuario();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({id,idsec})=> {
      this.uid = idsec
      this.idsec = id
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      this.loadMaestros();
    })
  }

  validarCampos(){
    let existeOperario = this.operarios.some(
      operario => operario.cli_codcli === this.lineaOperacion.cli_codcli);
    if(!existeOperario){
      this._snackBarService.showError('El operario está inactivo, escoga otro operario', 'Ok');
    }
  }

  loadMaestros():void{
    forkObs(
      this._lineaProductoService.obtenerLineasProducto(),
      this._operarioService.obtenerOperarios(),
      this.uid == '0' ? of([]) : this._lineaOperacionService.mostrarLineaOperacion(this.idsec,this.uid)
    )
    .then(
      combos => {
        this.lineasProducto = combos[0].filter(linea => linea.lpd_indsta === '1');
        this.operarios = combos[1].filter(linea => linea.cli_indsta === '1');
        if(this.uid!=='0'){
          this.lineaOperacion = combos[2];
          this.validarCampos();
        };
        console.log(combos)
        this.loaderData = false;

      }
    ).catch(error => {error => {
      this._snackbarService.showError('Error al obtener maestros', 'Ok');
    }});
  }


  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarLinea(f): this.actualizarLinea(f);
  }

  registrarLinea(f: NgForm): void {
    this.sendForm$=this._lineaOperacionService.registrarLineaOperacion(this.lineaOperacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Línea de producción registrado', '400px', '400px', '');
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarLinea(f: NgForm): void {
    this.sendForm$=this._lineaOperacionService.actualizarLineaOperacion(this.lineaOperacion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Línea de producción actualizado', '400px', '400px', '');
        this.volver();
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-produccion/lineas-de-produccion-operario']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.sendForm$,this.loading$]);
  }
}
