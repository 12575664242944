<app-pavso-title-section [title]="'Motivos de movimiento'" [module]="'Ventas'" [usuario]="" [fecha]=""></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
    <div class="pav-table-search-container">
      <div class="pav-separator"></div>
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
      </div>
    </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los Motivos de Movimiento" url="/modulo-ventas/motivo-movimiento" entity="" [params]="['0']"></table-title-action>

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <div class="pav-btns-container">
                  <a matTooltip="Editar Motivo de Movimiento"
                    class="pav-btn-circle-edit"
                    [routerLink]="['/modulo-ventas/motivo-movimiento', row.mmo_codmmo]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </a>
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="mmo_codmmo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.mmo_codmmo}} </td>
          </ng-container>

          <ng-container matColumnDef="mmo_descri">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivos de Movimiento </th>
              <td mat-cell *matCellDef="let row" data-label="Motivo de Movimiento"> {{row.mmo_descri}} </td>
          </ng-container>

          <ng-container matColumnDef="mmo_indsta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.mmo_indsta == '1'? 'ACTIVO':'INACTIVO'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </div>
