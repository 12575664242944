import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
    providedIn: "root"
})
export class LugarDespachoCIAService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerLugaresDespachoCIA(): Observable<any> {
    return this.http.get(`${this._configurationService.obtenerUrl()}/api/guias/lugardespachocia/listar/${this._configurationService.obtenerCompaniaCliente()}`)
  }

}
