export class ActividadCRM {
  idass   : string;
  descri  : string;
  fecupd  : Date;
  indsta  : string;
  coduse  : string;
  idgac   : string;
  codoet  : string;

  constructor() {
    this.fecupd = new Date();
    this.indsta = '1';
  }
}
