<ng-container>
  <app-pavso-title-section [title]="'Activos fijo'" [module]="'Activo fijo'"></app-pavso-title-section>
  <div class="pavso-content-form fade-in-image">
    <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>

    <div align="center">
      <button class="pav-button-action" mat-flat-button color="primary" [class.spinner]="loading" [disabled]="loading" (click)="verReporte()">{{LABELS_NAME.BTN_SHOW_REPORT}}</button>
    </div>

  </div>

</ng-container>
