<app-pavso-title-section
  [title]="'apps.crm.operaciones.oportunidad.title' | translate"
  [module]="'CRM'"
></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input
        type="text"
        (keyup)="applyFilter($event)"
        [placeholder]="'general.placeholder.search' | translate"
        #input
      />
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action
      [title]="'apps.crm.operaciones.oportunidad.table.header' | translate"
      url="/modulo-crm/registro-de-oportunidad"
      entity=""
      [params]="['0']"
    ></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.oportunidad.table.th.actions' | translate}}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [style.color]="row.color"
            data-label="Acciones"
          >
            <div class="pav-btns-container">
              <a class="pav-btn-circle-edit" matTooltip="Editar oportunidad" [routerLink]="['/modulo-crm/registro-de-oportunidad', row.idtkt]">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </a>
              <div class="pav-btn-circle-follows" matTooltip="Seguimientos" [routerLink]="['/modulo-crm/seguimientos-de-registro-de-oportunidad', row.idtkt]">
                <mat-icon svgIcon="seguimientos"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="idtkt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.oportunidad.table.th.code' | translate}}</th>
          <td mat-cell *matCellDef="let row" data-label="Código">
            {{ row.idtkt }}
            <!-- <mat-chip-list>
              <mat-chip color="accent" selected>{{ row.idtkt }}</mat-chip>
            </mat-chip-list> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="nomcli">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.oportunidad.table.th.client' | translate}}</th>
          <td mat-cell *matCellDef="let row" data-label="Cliente">
            {{ row.nomcli }}
          </td>
        </ng-container>

        <ng-container matColumnDef="asunto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.oportunidad.table.th.subject' | translate}}</th>
          <td mat-cell *matCellDef="let row" data-label="Cliente">
            {{ row.asunto }}
          </td>
        </ng-container>

        <ng-container matColumnDef="detalle">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.oportunidad.table.th.detail' | translate}}</th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono">
            {{ row.detalle }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            {{'general.table.data-not-found' | translate}} "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>

    <mat-paginator
      showFirstLastButtons
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
  </div>
</div>
