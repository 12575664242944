import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { ChartComponent } from "ng-apexcharts";
import { Subscription } from "rxjs";
import { DashboardService } from "src/app/services";

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  noData: ApexNoData;
};

@Component({
  selector: 'app-panel-planilla',
  templateUrl: './panel-planilla.component.html',
  styleUrls: ['./panel-planilla.component.scss']
})
export class PanelPlanillaComponent implements OnInit, OnDestroy {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;

  year: string;
  month: string;

  apexChart: ApexCharts;

  colaboradoresActivos: number;
  colaboradoresCesados: number;
  contratosVencidos: number;
  contratosPorVencer: number;
  vacacionesVencidas: number;
  vacacionesPorVencer: number;
  documentosLegajoVencidos: number;
  documentosLegajoPorVencer: number;

  period$: Subscription;
  loading$: Subscription;
  dashboard$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _dashboardService: DashboardService
  ) {
    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function(chart, w, e) {
          }
        }
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#00E396",
        "#FEB019",
        "#FF4560",
      ],
      noData: {
        text: 'Cargando...'
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        show: true,
        position: "right"
      },
      grid: {
        show: true
      },
      yaxis: {
        title: {
          text: "# Colaboradores",
        },
        labels: {
          style: {
            fontSize: "12px"
          },
        }
      },
      xaxis: {
        categories: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Oct",
          "Nov",
          "Dic",
        ],
        labels: {
          style: {
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#00E396",
              "#FEB019",
              "#FF4560",
            ],
            fontSize: "12px"
          },
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " ";
          }
        }
      },
      title: {
        text: `Cantidad de colaboradores: ...  - Período ...`,
        align: "center",
        style: {
          color: "#444"
        }
      }
    }

    this.apexChart = new ApexCharts(document.querySelector("#chartCantidadTrabajadores"), this.chartOptions);

    this.apexChart.render();

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this.dashboard$ = this._dashboardService.obtenerDasboardNomina(this.year).subscribe(
      response => {
        console.log('chart colaboradores', response)
        this.colaboradoresActivos = response.active_collaborators;
        this.colaboradoresCesados = response.dismissed_collaborators;
        this.contratosVencidos = response.contract_defeated;
        this.contratosPorVencer = response.contract_to_expire;
        this.vacacionesVencidas = response.vacaciones[0].vencidos;
        this.vacacionesPorVencer = response.vacaciones[0].por_vencer;
        this.documentosLegajoVencidos = response.tiposDocumentoLegajo[0]? response.tiposDocumentoLegajo[0].vencidos: 0;
        this.documentosLegajoPorVencer = response.tiposDocumentoLegajo[0]? response.tiposDocumentoLegajo[0].por_vencer: 0;

        let total = 0;

        response.chart.data.forEach(element => {
          total += Number(element);
        });

        this.apexChart.updateSeries([response.chart]);

        this.apexChart.updateOptions({
          title: {
            text: `Cantidad de colaboradores | Período: ${this.year}`,
            align: "center",
            style: {
              color: "#444"
            }
          }
        });
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')

    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.dashboard$]);
  }
}
