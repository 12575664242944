import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';
@Injectable({
    providedIn: 'root',
  })
  export class OperarioService {
  
    constructor(
      private http: HttpClient,
      private _configurationService: ConfigurationService
    ) {}
obtenerOperarios(): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/clientes/listar/${this._configurationService.obtenerCompaniaCliente()}?es_trabajador=true`
    ).pipe(
      map((operarios: any) => {
        return operarios.map(operario => {
          operario['description'] = `${operario.cli_codcli} | ${operario.cli_nomcli}`
          return operario;
        })
      })
    )
  }
}