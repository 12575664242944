import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_LINEA_NEGOCIO } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { LineaNegocioService } from "src/app/services/api/crm/maestros/linea-negocio.service";

@Component({
  selector: 'linea-negocio-list',
  templateUrl: 'linea-negocio-list.component.html',
  styleUrls: ['linea-negocio-list.component.scss']
})
export class LineaNegocioListComponent implements OnInit, OnDestroy {

  lineasNegocio$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_LINEA_NEGOCIO;

  lineasNegocio: Array<IHeaderKey> = [];

  constructor(
    private store: Store<PavsoState>,
    private _lineaNegocioService: LineaNegocioService,
    private _snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerLineasNegocio();
      }
    })
  }

  obtenerLineasNegocio(): void {
    this.lineasNegocio$ = this._lineaNegocioService.obtenerLineasNegocio().subscribe(
      lineas => this.lineasNegocio = lineas,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.lineasNegocio$,
      this.loading$,
    ])
  }
}
