export class TipoDocumentoIdentidad {
  DID_CODDID: string;
  DID_CODOFI: string;
  DID_CODSUN: string;
  DID_CODUSE: string;
  DID_DESABR: string;
  DID_DESCRI: string;
  DID_FECUPD: Date;
  DID_INDSTA: string;
  DID_MAXLEN: number;
  DID_MINLEN: number;
  did_codafp: string;

  constructor() {
    this.DID_INDSTA = '1';
    this.DID_FECUPD = new Date();
  }
}


