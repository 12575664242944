import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Driver } from 'src/app/models';
import { AuthenticationService, ConfigurationService, GeneralService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-interface-plame',
  templateUrl: './interface-plame.component.html',
  styleUrls: ['./interface-plame.component.css']
})
export class InterfacePlameComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;

  loaderRem: boolean = false;
  loaderJor: boolean = false;
  loaderSnl: boolean = false;
  loaderToc: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  tipoDoc$: Subscription;
  loading$: Subscription;
  period$: Subscription;
  nomina$: Subscription;

  year: string;
  month: string;
  npe: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _authService: AuthenticationService,
    private _configurationService: ConfigurationService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.nomina$ = this.store.select('nomina').subscribe(state => {
      this.npe = `${state.typeNomina}${state.secNomina}`;
    })

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this.driver.CHO_INDSTA = "1";

    this.tipoDoc$ = this._generalService.listarTipoDocumentoIdentidad().subscribe(
      tiposDocumento => this.tiposDocumento = tiposDocumento,
      error => this._snackBarService.showError('Error al obtener tipos de documento', 'OK')
    )
  }

  registrarChofer(f: NgForm): void {

  }

  subirFolder(event): void {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  guardarArchivoRem(): void {
    this.loaderRem = true;
    this._nominaService.obtenerDataRemuneracionColaborador(this.year, this.month).subscribe(
      response => {
        if(response.length == 0) {
          this._snackBarService.showError('No se encontró información para el presente período', 'Ok');
          return;
        }

        this._snackBarService.showSuccess('Archivo .REM generado', 'OK');

        let documentContent = '';

        response.forEach(element => {
          documentContent += `${element.did_codofi}|${element.cli_numdni}|${element.nco_codofi}|${element.npc_impnpc}\n`;
        });

        this.crearDocumento('.REM', documentContent);
        this.loaderRem = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderRem = false;
      }
    )

  }

  guardarArchivoJor(): void {
    this.loaderJor = true;

    this._nominaService.obtenerDataJornadaLaboralColaborador(this.year, this.month, this.npe).subscribe(
      response => {
        if(response.length == 0) {
          this._snackBarService.showError('No se encontró información para el presente período', 'Ok');
          return;
        }
        this._snackBarService.showSuccess('Archivo generado', 'Ok');

        let documentContent = '';

        response.forEach(element => {
          documentContent += `${element.did_codofi}|${element.cli_numdni}|${element.cli_horord}|${element.cli_minord}|${element.cli_horsob}|${element.cli_minsob}\n`;
        });

        this.crearDocumento('.JOR', documentContent);

        this.loaderJor = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderJor = false;
      }
    )
  }

  guardarArchivoSnl(): void {
    this.loaderSnl = true;

    this._nominaService.obtenerDataInfoDiasSubsidiadosNoLaborados(this.year, this.month).subscribe(
      response => {
        if(response.length == 0) {
          this._snackBarService.showError('No se encontró información para el presente período', 'Ok');
          return;
        }
        this._snackBarService.showSuccess('Archivo generado', 'Ok');

        let documentContent = '';

        response.forEach(element => {
          documentContent += `01|${element.cli_codcli}|${element.subsidio}|${element.npc_cannut}\n`;
        });

        this.crearDocumento('.SNL', documentContent);

        this.loaderSnl = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderSnl = false;
      }
    )
  }

  guardarArchivoToc(): void {
    this.loaderToc = true;
    this._nominaService.obtenerDataSeguroVitalLey(this.year, this.month).subscribe(
      response => {
        if(response.length == 0) {
          this._snackBarService.showError('No se encontró información para el presente período', 'Ok');
          return;
        }
        this._snackBarService.showSuccess('Archivo generado', 'Ok');
        this.crearDocumento('.TOC', response);

        this.loaderToc = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderToc = false;
      }
    )
  }

  crearDocumento(extension, documentContent): void {

    const filename = `${this.generarNombreArchivo()}${extension}`;
    const file = new Blob([documentContent], {type: "text/plain"});

    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = filename;
    link.click();
    link.remove();

  }

  generarNombreArchivo(): string {

    return `0601${this.year}${this.month}${this._configurationService.obtenerClienteId()}`;

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoDoc$, this.loading$, this.nomina$, this.period$]);
  }

}
