<app-pavso-title-section [title]="'Concepto descuento'" [module]="'Ventas'" [usuario]="concepto.dco_coduse" [fecha]="concepto.dco_fecupd" [estado]="concepto.dco_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="concepto.dco_coddco" matInput placeholder="Código" name="coddco" required [maxlength]="ml.dco_coddco" [readonly]="uid!='0'" soloNumerosText="numeros">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6"></div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="concepto.dco_desdco" matInput placeholder="Descripción" name="desdco" required [maxlength]="ml.dco_desdco">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Descri. Abrevi.</mat-label>
            <input type="text" [(ngModel)]="concepto.dco_obsdco" matInput placeholder="Descri. Abrevi." name="obsdco" required [maxlength]="ml.dco_obsdco">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input required [(ngModel)]="concepto.dco_fecdes" name="fecdes" matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-ventas/conceptos-descuento">
      <div id="botonesAdicionales">

      </div>
    </pav-form-actions>

  </form>
</div>
