import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_TIPO_CAMPANIA } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { CrmService } from "src/app/services";
import { TipoCampaniaService } from "src/app/services/api/crm/maestros/tipo-campania.service";

@Component({
  selector: 'tipo-campania-list',
  templateUrl: 'tipo-campania-list.component.html',
  styleUrls: ['tipo-campania-list.component.scss']
})
export class TipoCampaniaListComponent implements OnInit, OnDestroy {

  tiposCampania$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_TIPO_CAMPANIA;

  tiposCampania: Array<any> = [];

  constructor(
    private store: Store<PavsoState>,
    private _crmService: CrmService,
    private _tipoCampaniaService: TipoCampaniaService,
    private _snackBarService: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerTiposCompania();
      }
    })
  }

  obtenerTiposCompania(): void {
    this.tiposCampania$ = this._tipoCampaniaService.obtenerTiposCampania().subscribe(
      tiposCampania => this.tiposCampania = tiposCampania,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tiposCampania$,
      this.loading$,
    ])
  }

}
