import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CentroCosto } from "src/app/models/contabilidad";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class CentroCostoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  registrarCentroCosto(centroCosto: CentroCosto): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/centrocostos/crear`,
      centroCosto
    )
  }

  actualizarCentroCosto(centroCosto: CentroCosto): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/centrocostos/modificar`,
      centroCosto
    )
  }

  obtenerCentroCosto(id): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/centrocostos/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    )
  }

  obtenerCentrosCosto(): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/centrocostos/listar/${this._configurationService.obtenerCompaniaCliente()}`
    )/*.pipe(
      map((clientes: any) => {
        return clientes.map(cliente => {
          cliente['description'] = `${cliente.cli_codcli} | ${cliente.cli_nomcli}`
          cliente['description_reverse'] = `${cliente.cli_nomcli} | ${cliente.cli_codcli}`
          return cliente;
        }).filter(cliente => cliente.cli_indtra === 1)
      })
    )*/
  }

}
