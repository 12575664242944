<app-pavso-title-section [title]="'Consulta Despacho'" [module]="'Almacén'"
  [ngClass]="'pav-form'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="realizarConsulta(f)" (keydown.enter)="$event.preventDefault()">
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="">
      <div id="botonesAdicionales">
      </div>
    </pav-form-actions>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Almacen</mat-label>
            <mat-select required name="almacen" [disabled]="loaderData">
              <mat-option *ngFor="let almacen of almacenes" [value]="almacen.ALM_CODALM">
                {{almacen.ALM_DESCRI}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Pedido</mat-label>
            <mat-select required name="pedido" [disabled]="loaderData">
              <mat-option *ngFor="let pedido of pedidos" [value]="pedido.PCC_NUMPCC">
                {{pedido.PCC_NUMPCC}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <select-search [label]="'Transportista'" [placeholder]="'Ej. Perez'" [name]="'cli_codcli'"
            [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="transportistas"
            >
          </select-search>
        </div>

      </div>
      <div class="row">
        <div class="col s12 m12 l6">
          <mat-checkbox [ngStyle]="{marginTop: '10px'}" class="example-margin" name="tieneFiltroFecha">Fecha
            emisión</mat-checkbox>
          <div [ngStyle]="{display: 'flex'}">

            <mat-form-field [ngStyle]="{marginLeft: '5px'}">
              <mat-label>Desde</mat-label>
              <input matInput [matDatepicker]="pickerDesde">
              <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
              <mat-datepicker #pickerDesde></mat-datepicker>
            </mat-form-field>

            <mat-form-field [ngStyle]="{marginLeft: '5px'}">
              <mat-label>Hasta</mat-label>
              <input matInput [matDatepicker]="pickerHasta">
              <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
              <mat-datepicker #pickerHasta></mat-datepicker>
            </mat-form-field>
          </div>

        </div>
        <div class="col s12 m12 l6">
          <mat-checkbox [ngStyle]="{marginTop: '10px'}" class="example-margin" name="tieneFiltroFecha">Fecha de
            entrega</mat-checkbox>
          <div [ngStyle]="{display: 'flex'}">

            <mat-form-field [ngStyle]="{marginLeft: '5px'}">
              <mat-label>Desde</mat-label>
              <input matInput [matDatepicker]="pickerDesde02">
              <mat-datepicker-toggle matSuffix [for]="pickerDesde02"></mat-datepicker-toggle>
              <mat-datepicker #pickerDesde02></mat-datepicker>
            </mat-form-field>

            <mat-form-field [ngStyle]="{marginLeft: '5px'}">
              <mat-label>Hasta</mat-label>
              <input matInput [matDatepicker]="pickerHasta02">
              <mat-datepicker-toggle matSuffix [for]="pickerHasta02"></mat-datepicker-toggle>
              <mat-datepicker #pickerHasta02></mat-datepicker>
            </mat-form-field>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col s12 m12 l6">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="1">Todos</mat-radio-button>
            <mat-radio-button value="2">Activos</mat-radio-button>
            <mat-radio-button value="2">Anulados</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col s12 m12 l6">
          <mat-checkbox class="example-margin">Guías pendientes de factura</mat-checkbox>

        </div>
      </div>

    </mat-card>
    <br>
    <div class="container-stock">
      <div class="pav-filtro-header">
        <div class="pav-filtro-header-icon"></div>
        <div class="pav-filtro-header-title">Guía de remisión</div>
        <div class="pav-separator"></div>
        <div class="pav-table-search">
          <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
        </div>
      </div>
      <div class="mat-elevation-z0 overflow-x">

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="guia_nro">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Guía Nro. </th>
            <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha_despacho">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Desp, </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="almacen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="nro_parte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> # Parte </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="nro_pedido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Pedido </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="ruc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> RUC </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="factura">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Factura </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="transportista">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Transportista </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha_entrega">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de entrega </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="12">No se encontraron registros "{{input.value}}"</td>
          </tr>

        </table>

      </div>
      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <div class="container-stock">
      <div class="row">
        <div class="col s12 m12 l12 xl4">
          <div class="pav-filtro-header">
            <div class="pav-filtro-header-icon"></div>
            <div class="pav-filtro-header-title">Por producto</div>
          </div>
          <div class="mat-elevation-z0 overflow-x">

            <table mat-table [dataSource]="dataSource1" matSort>

              <ng-container matColumnDef="producto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
              </ng-container>

              <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
              </ng-container>

              <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No se encontraron registros</td>

              </tr>

            </table>

          </div>
          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
        <div class="col s12 m12 l12 xl4" *ngIf="configuracionTecnica.s70_indlot">
          <div class="pav-filtro-header">
            <div class="pav-filtro-header-icon"></div>
            <div class="pav-filtro-header-title">Por lote</div>
          </div>
          <div class="mat-elevation-z0 overflow-x">

            <table mat-table [dataSource]="dataSource2" matSort>

              <ng-container matColumnDef="lote">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
              </ng-container>

              <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
              </ng-container>

              <ng-container matColumnDef="fec_fabricacion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Fabricación </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
              </ng-container>

              <ng-container matColumnDef="fec_vencimiento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vencimiento </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros</td>

              </tr>

            </table>

          </div>
          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
        <div class="col s12 m12 l12 xl4" *ngIf="configuracionTecnica.s70_indser">
          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">
            </div>
            <div class="pav-table-header-title">
              Series
            </div>
            &nbsp;
            &nbsp;
            <ng-container *ngIf="selected">
              <div class="pav-button-icon-add" [matTooltip]="selected.prd_indser == 1 ? 'Agregar serie': ''"
                (click)="agregarSerie()"
                [ngStyle]="{display: loaderData? 'none': '', background: selected.prd_indser == 1 ? 'rgb(247, 201, 27)': 'rgb(247, 201, 27, .5)'}">
                <mat-icon svgIcon="add-circle"></mat-icon>
              </div>
              &nbsp;
              <div class="pav-button-icon-delete" [matTooltip]="selected.prd_indser == 1 ? 'Eliminar serie': ''"
                (click)="eliminarSerie()"
                [ngStyle]="{display: loaderData? 'none': '', background: selected.prd_indser == 1 ? 'rgb(235, 105, 69)': 'rgb(235, 105, 69, .5)'}">
                <mat-icon svgIcon="remove"></mat-icon>
              </div>
              &nbsp;
              <div class="pav-button-icon-insert" [matTooltip]="selected.prd_indser == 1 ? 'Insertar serie': ''"
                (click)="insertarSerie()"
                [ngStyle]="{display: loaderData? 'none': '', background: selected.prd_indser == 1 ? 'rgb(35, 96, 255)': 'rgb(35, 96, 255, .5)' }">
                <mat-icon svgIcon="add-outline"></mat-icon>
              </div>
            </ng-container>
          </div>

          <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSourceSerie" matSort>

              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                  <div class="pav-btns-container">
                    <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarSerie()">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="serie">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie /Pasaporte </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                  <mat-form-field [class.mat-form-field-row]="row === selectedSerie"
                    class="form-field-small form-field-cell">
                    <mat-label>Serie /Pasaporte</mat-label>
                    <input matInput placeholder="Serie /Pasaporte" [(ngModel)]="row.spr_numser"
                      [name]="'spr_numser' + i" required>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="fec_fabric">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Fabric. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Cliente">
                  <mat-form-field [class.mat-form-field-row]="row === selectedSerie"
                    class="form-field-small form-field-cell">
                    <mat-label>Fecha Fabricación</mat-label>
                    <input matInput [matDatepicker]="pickerFab" [(ngModel)]="row.mas_fecfab" [name]="'fechaFab'+i"
                      required>
                    <mat-datepicker-toggle matSuffix [for]="pickerFab"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFab></mat-datepicker>
                  </mat-form-field>
                </td>

              </ng-container>

              <ng-container matColumnDef="fec_vcto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. de Vcto. </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                  <mat-form-field [class.mat-form-field-row]="row === selectedSerie"
                    class="form-field-small form-field-cell">
                    <mat-label>Fecha Vencimiento</mat-label>
                    <input matInput [matDatepicker]="pickerVcto" [(ngModel)]="row.mas_fecven" [name]="'mas_fecven'+i"
                      required>
                    <mat-datepicker-toggle matSuffix [for]="pickerVcto"></mat-datepicker-toggle>
                    <mat-datepicker #pickerVcto></mat-datepicker>
                  </mat-form-field>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsSerie"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsSerie;"
                [class.selected-row]="row === selectedSerie" (click)="selectRowSerie(row)"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros</td>
              </tr>

            </table>
          </div>
          <mat-paginator #paginatorSerie [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>

  </form>
</div>
