import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";

import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDropzoneModule } from "ngx-dropzone";
import { LoginGuard } from '../guards/login.guard';
import { MaterialModule } from '../material.module';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './apps/dashboard/dashboard.component';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';
import { ProfileComponent } from './profile/profile.component';

import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFonts from "../../../node_modules/pdfmake/build/vfs_fonts";

import { NgApexchartsModule } from "ng-apexcharts";

import { MomentDateModule } from "@angular/material-moment-adapter";
import { MatNativeDateModule } from "@angular/material/core";

import { SharedModule } from "@shared/shared.module";

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { NgxFileHelpersModule } from "ngx-file-helpers";
import { HttpLoaderFactory } from "../app.module";
import { CanDeactivateGuard } from "../guards/return.guard";
import { AdminRoutingModule } from "./admin-routing.module";
import { appsModule } from "./apps";
import { EnterParametersComponent } from './error/enter-parameters/enter-parameters.component';
import { LoaderInterceptor } from "./interceptors/loader-interceptor.service";
import { NotificationsComponent } from "./notifications/notifications.component";
import { MyLogoComponent } from './my-logo/my-logo.component';
import { componentsAdmin } from './components';
import { UploadDocumentsBottomSheet } from './bottom-sheets/upload-documents/upload-documents.bottomsheet';

PdfMakeWrapper.setFonts(pdfFonts);

@NgModule({
    declarations: [
        AdminComponent,

        DashboardComponent,
        ProfileComponent,
        MyLogoComponent,
        NotificationsComponent,
        EnterParametersComponent,
        UploadDocumentsBottomSheet,
    ],
    exports: [
        AdminComponent,

    ],
    imports: [
        ...appsModule,
        SharedModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        LayoutModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxDropzoneModule,
        NgxMatSelectSearchModule,
        NgApexchartsModule,
        MatNativeDateModule,
        MomentDateModule,
        AdminRoutingModule,
        NgxFileHelpersModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

    ],
    providers: [LoginGuard,
        CanDeactivateGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' }
        }
    ]
})

export class AdminModule { }
