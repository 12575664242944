import { NominaState } from "@data/interfaces/state/nomina.state";
import { createReducer, on } from "@ngrx/store";
import { cambiarDatosNomina } from "../actions/nomina.actions";

export const initialState: NominaState = { typeNomina: "1" , secNomina: "", nameTypeNomina: ""};

const _nominaReducer = createReducer(
  initialState,
  on(cambiarDatosNomina, (_, {typeNomina, secNomina, nameTypeNomina}) => ({typeNomina, secNomina, nameTypeNomina})),
);

export function nominaReducer(state, action) {
  return _nominaReducer(state, action)
}
