<app-pavso-title-section [title]="'Colaborador'" [module]="'Planilla'" [estado]="colaborador.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input [readonly]="uid != '0'" [(ngModel)]="colaborador.codcli" name="codcli" matInput placeholder="Código"
              type="text" (focusout)="verificarCodigo()" (keyup.enter)="verificarCodigo()" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Ap. Paterno</mat-label>
            <input [disabled]="!formValidated" [(ngModel)]="colaborador.apepat" name="apepat" matInput
              placeholder="Ap. Paterno" type="text" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Ap. Materno</mat-label>
            <input [disabled]="!formValidated" [(ngModel)]="colaborador.apemat" name="apemat" matInput
              placeholder="Ap. Materno" type="text" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-form-field>
            <mat-label>Nombres</mat-label>
            <input [disabled]="!formValidated" [(ngModel)]="colaborador.nombre" name="nombre" matInput
              placeholder="Nombres" type="text" required>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br />
    <mat-card class="mat-elevation-z0">
      <mat-tab-group>
        <mat-tab class="datos-generales" labelClass="mat-tab-label-0-0" label="Datos Generales"
          [disabled]="!formValidated">
          <br>
          <div class="row">
            <div class="col s12 m12 l3">
              <mat-card class="mat-elevation-z3">
                <div align="center">
                  <div class="pavso-container-img">
                    <img class="pavso-img-profile" [src]="imageUrl?imageUrl: 'assets/images/user/default.png'" alt="">
                  </div>
                </div>
                <br>
                <div align="center">
                  <button [disabled]="!formValidated" mat-button color="primary" type="button"
                    (click)="photoFile.click()">
                    Subir
                  </button>

                  <button [disabled]="!imageUrl && !formValidated" mat-button color="primary" type="button"
                    (click)="quitarImagen()">
                    Quitar
                  </button>
                  <input hidden (change)="subirImagen($event)" #photoFile type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg">
                </div>
                <div class="pavso-button-img" *ngIf="uid != '0'">
                  <button type="button" class="pav-button-print" (click)="imprimirCertificadoTrabajo()" mat-flat-button
                    color="primary" [class.spinner]="loadingCertificado" [disabled]="loadingCertificado && loaderData">
                    <mat-icon>print</mat-icon> Certificado de Trabajo
                  </button>
                  <br>
                  <button type="button" class="pav-button-print" (click)="imprimirConstanciaTrabajo()" mat-flat-button
                    color="primary" [class.spinner]="loadingConstancia" [disabled]="loadingConstancia && loaderData">
                    <mat-icon>print</mat-icon> Constancia de Trabajo
                  </button>
                </div>
                <div id="whatsapp">
                  <a [href]="'https://api.whatsapp.com/send?phone=+51'+colaborador.numtlf+'&text=Hola,%20Somos%20Pavsolutions%20te%20asesoramos%20por%20whatsapp'"
                    target="_blank">
                    <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg"
                      xmlns:svg="http://www.w3.org/2000/svg">
                      <!-- Created with SVG-edit - https://github.com/SVG-Edit/svgedit-->
                      <g class="layer">
                        <title>Layer 1</title>
                        <circle cx="40" cy="40" fill="#fff" id="svg_1" r="38" stroke="#000000" stroke-width="0" />
                        <path
                          d="m57.81072,21.975c-4.48928,-4.5 -10.46786,-6.975 -16.82142,-6.975c-13.11429,0 -23.78571,10.67143 -23.78571,23.78571c0,4.18928 1.09286,8.28215 3.17143,11.89286l-3.375,12.32142l12.61072,-3.31072c3.47143,1.89642 7.38215,2.89286 11.36786,2.89286l0.01072,0c13.10358,0 24.01072,-10.67143 24.01072,-23.78571c0,-6.35357 -2.7,-12.32142 -7.18928,-16.82142l-0.00001,-0.00001l-0.00001,0l-0.00002,0.00001zm-16.82142,36.6c-3.55714,0 -7.03928,-0.95357 -10.07143,-2.75357l-0.71785,-0.42857l-7.47858,1.96072l1.99286,-7.29642l-0.47143,-0.75c-1.98215,-3.15 -3.02142,-6.78215 -3.02142,-10.52142c0,-10.89642 8.87143,-19.76786 19.77858,-19.76786c5.28215,0 10.24286,2.05714 13.97143,5.79642c3.72857,3.73928 6.02142,8.7 6.01072,13.98215c0,10.90714 -9.09642,19.77858 -19.99286,19.77858l0,-0.00002l-0.00001,0l-0.00001,-0.00001zm10.84286,-14.80714c-0.58928,-0.3 -3.51429,-1.73572 -4.06072,-1.92857c-0.54643,-0.20358 -0.94286,-0.3 -1.33928,0.3c-0.39642,0.6 -1.53214,1.92857 -1.88571,2.33572c-0.34286,0.39642 -0.69642,0.45 -1.28571,0.15c-3.49286,-1.74643 -5.78571,-3.11785 -8.08928,-7.07143c-0.61072,-1.05 0.61072,-0.975 1.74643,-3.24643c0.19286,-0.39642 0.09642,-0.73928 -0.05357,-1.03928c-0.15,-0.3 -1.33928,-3.225 -1.83214,-4.41429c-0.48215,-1.15714 -0.975,-0.99642 -1.33928,-1.01785c-0.34286,-0.02142 -0.73928,-0.02142 -1.13572,-0.02142c-0.39642,0 -1.03928,0.15 -1.58571,0.73928c-0.54643,0.6 -2.07858,2.03572 -2.07858,4.96072c0,2.925 2.13214,5.75357 2.42142,6.15c0.3,0.39642 4.18928,6.39642 10.15714,8.97858c3.77143,1.62857 5.25,1.76786 7.13572,1.48928c1.14643,-0.17143 3.51429,-1.43572 4.00714,-2.82857c0.49286,-1.39286 0.49286,-2.58215 0.34286,-2.82857c-0.13928,-0.26786 -0.53572,-0.41785 -1.125,-0.70714l-0.00001,-0.00001l0.00002,-0.00001l-0.00002,-0.00001z"
                          fill="currentColor" id="svg_2" />
                      </g>
                    </svg>
                    <!-- <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="whatsapp" class="svg-inline--fa fa-whatsapp fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                    </svg> -->
                  </a>
                </div>
              </mat-card>
            </div>
            <div class="col s12 m12 l9">
              <div class="row">
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Tipo Doc.</mat-label>
                    <mat-select #tipoDocumentoSelect [disabled]="!formValidated" [(ngModel)]="colaborador.coddid"
                      name="coddid" required>
                      <mat-option *ngFor="let tipo of tiposDocumento; trackBy: trackByTipoDoc"
                        [value]="tipo.DID_CODDID">
                        {{tipo.DID_DESCRI}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Nro. Doc.</mat-label>
                    <input [disabled]="!formValidated" [(ngModel)]="colaborador.numdni" name="numdni" matInput
                      placeholder="Nro. Doc." type="text" required>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Sexo</mat-label>
                    <mat-select #sexoSelect [disabled]="!formValidated" [(ngModel)]="colaborador.indsex" name="indsex"
                      required>
                      <mat-option *ngFor="let sexo of sexos; trackBy: trackBySexo" [value]="sexo.gpe_codgpe">
                        {{sexo.gpe_descri}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Grado Académico</mat-label>
                    <mat-select #gradoAcademicoSelect [disabled]="!formValidated" name="t09_coddoc"
                      [(ngModel)]="colaborador.MAES_TRAB_TRA[0]['coddoc']" required>
                      <mat-option value=''>NO DEFINIDO</mat-option>
                      <mat-option *ngFor="let grado of grados; trackBy: trackByGrado" [value]="grado.t09_coddoc">
                        {{grado.t09_descri}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Estado Civil</mat-label>
                    <mat-select #estadoCivilSelect [disabled]="!formValidated" [(ngModel)]="colaborador.indciv"
                      name="indciv" required>
                      <mat-option *ngFor="let estado of estados; trackBy: trackByEstadoCivil"
                        [value]="estado.civ_indciv">
                        {{estado.civ_descri}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Email Personal</mat-label>
                    <input [disabled]="!formValidated" name="cli_emaper" [(ngModel)]="colaborador.emaper" matInput
                      placeholder="Email Personal" type="email" required>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl12">
                  <mat-form-field>
                    <mat-label>Dirección</mat-label>
                    <input [disabled]="!formValidated" [(ngModel)]="colaborador.dircli" name="dircli" matInput
                      placeholder="Dirección" type="text" required>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Distrito</mat-label>
                    <input [disabled]="!formValidated" [(ngModel)]="colaborador.discli" name="discli" matInput
                      placeholder="Distrito" type="text" required>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>País</mat-label>
                    <mat-select #paisSelect (selectionChange)="seleccionarPais($event)" [(ngModel)]="colaborador.codpai"
                      name="codpai" [disabled]="!formValidated" required>
                      <mat-option *ngFor="let pais of paises; trackBy: trackByPais" [value]="pais.PAI_CODPAI">
                        {{pais.pai_nomlar}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Ubicaciones</mat-label>
                    <mat-select #ubicacionSelect [(ngModel)]="colaborador.MAES_TRAB_TRA[0]['lugnac']" name="tra_lugnac"
                      [disabled]="!formValidated" required>
                      <mat-option *ngFor="let ubicacion of ubicaciones; trackBy: trackByUbicacion"
                        [value]="ubicacion.codubi">
                        {{ubicacion.desubi}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Fecha Nac.</mat-label>
                    <input [(ngModel)]="colaborador.fecnac" name="fecnac" matInput [matDatepicker]="picker"
                      [disabled]="!formValidated" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Teléfono 1</mat-label>
                    <input [(ngModel)]="colaborador.numtlf" name="numtlf" matInput placeholder="Teléfono 1" type="tel"
                      [disabled]="!formValidated" required>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Teléfono 2</mat-label>
                    <input [(ngModel)]="colaborador.numcel" name="numcel" matInput placeholder="Teléfono 2" type="tel"
                      [disabled]="!formValidated">
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Email Corporativo</mat-label>
                    <input [(ngModel)]="colaborador.corele" name="corele" matInput placeholder="Email Corporativo"
                      type="email" [disabled]="!formValidated" required>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Contacto</mat-label>
                    <input [(ngModel)]="colaborador.nomcon" name="nomcon" matInput placeholder="Contacto" type="text"
                      [disabled]="!formValidated">
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Tlf. Contacto</mat-label>
                    <input [(ngModel)]="colaborador.numfax" name="numfax" matInput placeholder="Tlf. Contacto"
                      type="text" [disabled]="!formValidated">
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l12 xl8">
                  <section class="example-section">
                    <mat-checkbox [disabled]="!formValidated" class="example-margin" [(ngModel)]="colaborador.inddom"
                      name="cli_inddom">Domiciliado
                    </mat-checkbox>
                    <mat-checkbox [disabled]="!formValidated" class="example-margin"
                      [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['indper']" name="trp_indper">Personal de
                      Confianza</mat-checkbox>
                  </section>
                </div>

              </div>
            </div>

          </div>
        </mat-tab>
        <mat-tab class="datos-periodo" labelClass="mat-tab-label-0-1" [style]="{backgroundColor: '#EAF0F9 !important'}"
          label="Datos del Período" [disabled]="!formValidated">
          <br>
          <div class="row">
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>AFP</mat-label>
                <mat-select #afpSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['codafp']" name="afp_codafp"
                  [disabled]="!formValidated" required>
                  <mat-option *ngFor="let afp of afps; trackBy: trackByAFP" [value]="afp.codafp">
                    {{afp.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Tipo de personal</mat-label>
                <mat-select #tipoPersonalSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['ctlmar']"
                  name="trp_ctlmar" [disabled]="!formValidated" required>
                  <mat-option *ngFor="let tipo of tiposPersonal" [value]="tipo.ntp_codntp">
                    {{tipo.ntp_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Tipo de nómina</mat-label>
                <mat-select #tipoNominaSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['codnti']"
                  name="nti_codnti" [disabled]="!formValidated" required>
                  <mat-option *ngFor="let tipoNomina of tiposNomina; trackBy: trackByTipoNomina"
                    [value]="tipoNomina.nti_codnti">
                    {{tipoNomina.nti_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Fecha ingreso</mat-label>
                <input [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['fecing']" name="trp_fecing" matInput
                  [matDatepicker]="fechaing" [min]="minDate" [max]="maxDate" [disabled]="!formValidated" required>
                <mat-datepicker-toggle matSuffix [for]="fechaing"></mat-datepicker-toggle>
                <mat-datepicker #fechaing></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Fecha cese</mat-label>
                <input [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['fecces']" name="trp_fecces" matInput
                  [matDatepicker]="fechacese" [disabled]="!formValidated">
                <mat-datepicker-toggle matSuffix [for]="fechacese"></mat-datepicker-toggle>
                <mat-datepicker #fechacese></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>CUSPP</mat-label>
                <input [(ngModel)]="colaborador.MAES_TRAB_TRA[0]['numcus']" name="numcus" matInput placeholder="CUSPP"
                  type="text" [disabled]="!formValidated" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Comisión AFP</mat-label>
                <mat-select #comisionAFPSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['comafp']"
                  name="trp_comafp" [disabled]="!formValidated" required>
                  <mat-option *ngFor="let comision of comisiones; trackBy: trackByComision"
                    [value]="comision.caf_comafp">
                    {{comision.caf_descom}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Cargo</mat-label>
                <mat-select #cargoSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['codnca']" name="nca_codnca"
                  [disabled]="!formValidated" required>
                  <mat-option *ngFor="let cargo of cargos; trackBy: trackByCargo" [value]="cargo.codnca">
                    {{cargo.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>ESSALUD</mat-label>
                <input [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['numaut']" name="trp_numaut" matInput
                  placeholder="ESSALUD" type="text" [disabled]="!formValidated" required>
                <button [matMenuTriggerFor]="infoInputEssalud" mat-icon-button matSuffix
                  [attr.aria-label]="'Hide password'" type="button">
                  <mat-icon>info</mat-icon>
                </button>
                <mat-menu #infoInputEssalud="matMenu" yPosition="above">
                  <div class="row">
                    <div class="col s12">
                      <!-- <h1>Ayuda</h1> -->
                      <p [style]="{fontSize: '12px'}">Ingrese Número de ESSALUD</p>
                    </div>
                  </div>
                </mat-menu>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Centro de costos</mat-label>
                <mat-select #centroCostoSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['codccs']"
                  name="ccs_codccs" [disabled]="!formValidated" required>
                  <mat-option *ngFor="let centro of centrosCosto; trackBy: trackByCentroCosto"
                    [value]="centro.ccs_codccs">
                    {{centro.ccs_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Excepción aportar</mat-label>
                <mat-select #excepcionAportarSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['excapo']"
                  name="trp_excapo" [disabled]="!formValidated" required>
                  <mat-option *ngFor="let excepcion of excepciones; trackBy: trackByExcepcion"
                    [value]="excepcion.nex_codnex">
                    {{excepcion.nex_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Lugar de trabajo</mat-label>
                <mat-select #lugarTrabajoSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['codpla']"
                  name="pla_codpla" [disabled]="!formValidated" required>
                  <mat-option *ngFor="let lugar of lugaresTrabajo; trackBy: trackByLugar" [value]="lugar.pla_codpla">
                    {{lugar.pla_despla}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Situación</mat-label>
                <mat-select #situacionSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['tipcon']" name="trp_tipcon"
                  [disabled]="!formValidated" required>
                  <mat-option *ngFor="let situacion of situaciones; trackBy: trackBySituacion"
                    [value]="situacion.nsc_codnsc">
                    {{situacion.nsc_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>Jornada laboral</mat-label>
                <mat-select #jornadaLaboralSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['codper']"
                  name="trp_codper" [disabled]="!formValidated" required>
                  <mat-option value="0">
                    Completo
                  </mat-option>
                  <mat-option value="1">
                    Parcial
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l12 xl3">
              <mat-form-field>
                <mat-label>% Adelanto</mat-label>
                <input min="0" max="100" class="pav-input-align-right"
                  [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['porade']" name="trp_porade" matInput
                  placeholder="% Adelanto" type="number" [disabled]="!formValidated" required>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12 l12 xl4">
              <div class="pav-card-header yellow">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Seleccione
                </div>
              </div>
              <mat-card class="mat-elevation-z0">
                <section>
                  <mat-checkbox [style]="{fontFamily: 'Figtree'}"
                    [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['excren']" name="excren" [disabled]="!formValidated">
                    Excepción renta de quinta</mat-checkbox>
                  <br>
                  <mat-checkbox [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['indsub']" name="indsub"
                    [disabled]="!formValidated">
                    Indica subsidio</mat-checkbox>
                  <br>
                  <mat-checkbox [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['indeps']" name="indeps"
                    [disabled]="!formValidated">
                    Indica EPS</mat-checkbox>
                  <br>
                  <mat-checkbox [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['exopen']" name="exopen"
                    [disabled]="!formValidated">
                    Exonerado reg. pensionario</mat-checkbox>
                  <br>
                  <mat-checkbox [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['excseg']" name="excseg"
                    [disabled]="!formValidated">
                    Exonerado seguro AFP</mat-checkbox>
                  <br>
                  <mat-checkbox [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['indbon']" name="indbon"
                    [disabled]="!formValidated">PASI
                    fijo</mat-checkbox>
                  <br>
                  <mat-checkbox [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['indq50']" name="indq50"
                    [disabled]="!formValidated">
                    Adelanto quincena</mat-checkbox>

                </section>
              </mat-card>
            </div>
            <div class="col s12 m12 l12 xl4">
              <div class="pav-card-header green">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Pago de haberes
                </div>
              </div>
              <mat-card class="mat-elevation-z0">
                <mat-form-field>
                  <mat-label>Banco</mat-label>
                  <mat-select #bancoHaberSelect [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['codhab']"
                    name="ban_codhab" [disabled]="!formValidated" required>
                    <mat-option *ngFor="let banco of bancos; trackBy: trackByBanco" [value]="banco.BAN_CODBAN">
                      {{banco.BAN_NOMABR}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Nro. cuenta</mat-label>
                  <input [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['ctahab']" name="trp_ctahab" matInput
                    placeholder="Nro. Cuenta" type="text" [disabled]="!formValidated" required>
                </mat-form-field>
                <app-currency-select [moneda]="colaborador.MAES_TRAB_PERI_TRP[0]['codtmo']" [disable]="!formValidated"
                  (cambioMoneda)="colaborador.MAES_TRAB_PERI_TRP[0]['codtmo'] = $event" required>
                </app-currency-select>
              </mat-card>
            </div>
            <div class="col s12 m12 l12 xl4">
              <div class="pav-card-header blue">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  CTS
                </div>
              </div>
              <mat-card class="mat-elevation-z0">
                <mat-form-field>
                  <mat-label>Banco</mat-label>
                  <mat-select [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['codcts']" name="codcts"
                    [disabled]="!formValidated">
                    <mat-option *ngFor="let banco of bancos; trackBy: trackByBanco" [value]="banco.BAN_CODBAN">
                      {{banco.BAN_NOMABR}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Nro. cuenta</mat-label>
                  <input [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['ctacts']" name="ctacts" matInput
                    placeholder="Nro. Cuenta" type="text" [disabled]="!formValidated">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Moneda</mat-label>
                  <mat-select [(ngModel)]="colaborador.MAES_TRAB_PERI_TRP[0]['tmocts']" name="tmocts"
                    [disabled]="!formValidated">
                    <mat-option value="SO">
                      SOLES
                    </mat-option>
                    <mat-option value="DO">
                      DÓLARES
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </mat-card>
            </div>
          </div>
        </mat-tab>
        <mat-tab class="control-vacacional" labelClass="mat-tab-label-0-2" label="Control Vacacional">

          <div class="row">
            <div class="col s12 m12 l12 xl3">
              <h3>Vacaciones</h3>

              <mat-form-field>
                <mat-label>Período</mat-label>
                <input [disabled]="!formValidated" type="text" name="trp_vacper" matInput>
              </mat-form-field>
              <br>
              <button mat-flat-button color="primary">Reingreso</button>

            </div>
            <div class="col s12 m12 l12 xl6 pavso-container-table">

              <div class="pavso-button-calendar">
                <table mat-table [dataSource]="dataSourcePeriodo" matSort class="mat-elevation-z0">

                  <ng-container matColumnDef="periodo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Período </th>
                    <td mat-cell *matCellDef="let row" data-label="Período" class="td-responsive"> {{row.cli_codcli}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dias_pago">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Días pago </th>
                    <td mat-cell *matCellDef="let row" data-label="Días pago" class="td-responsive"> {{row.cli_nomcli}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dias_fisico">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Días físico </th>
                    <td mat-cell *matCellDef="let row" data-label="Días físico" class="td-responsive">
                      {{row.cli_numtlf}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsPeriodo"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsPeriodo;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4" *ngIf="!loaderPeriodo">No se encontraron registros</td>
                    <td class="mat-cell" colspan="4" *ngIf="loaderPeriodo">
                      <mat-spinner diameter="40"></mat-spinner>
                    </td>
                  </tr>

                </table>
              </div>

              <div class="pavso-button-calendar">
                <button [disabled]="!formValidated" matTooltip="Info about the action" mat-mini-fab color="primary">
                  <mat-icon>calendar_today</mat-icon>
                </button>
              </div>

            </div>
            <div class="col s12 m12 l12 xl3">
              <h3>Otros</h3>

              <mat-form-field>
                <mat-label>Salida vacaciones</mat-label>
                <input name="trp_vacini" matInput placeholder="Salida Vacaciones" type="text"
                  [disabled]="!formValidated">
              </mat-form-field>

              <mat-form-field>
                <mat-label>Término vacaciones</mat-label>
                <input name="trp_vacfin" matInput placeholder="Salida Vacaciones" type="text"
                  [disabled]="!formValidated">
              </mat-form-field>
            </div>
          </div>
          <br>
          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">
            </div>
            <div class="pav-table-header-title">
              Tabla
            </div>
            <div class="pav-button-add-item">
              <button #btnAdd type="button" (click)="agregarItemDependiente()" (keyup.enter)="agregarItemDependiente()"
                mat-flat-button [disabled]="!formValidated">Agregar item</button>
            </div>
          </div>
          <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSourceControl" matSort>

              <ng-container matColumnDef="mcv_cormcv">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.mcv_cormcv}} </td>
              </ng-container>

              <ng-container matColumnDef="mcv_fecing">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha ing. </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.mcv_fecing | date: 'dd/MM/yyyy'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="mcv_permcv">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Período </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.mcv_permcv}} </td>
              </ng-container>

              <ng-container matColumnDef="mcv_fecini">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Inicial </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.mcv_fecini | date:
                  'dd/MM/yyyy'}} </td>
              </ng-container>

              <ng-container matColumnDef="mcv_fecfin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Final </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.mcv_fecfin | date: 'dd/MM/yyyy'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="mcv_vacgan">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Vac. Ganadas </th>
                <td mat-cell class="format-importe" *matCellDef="let row" data-label="Código">
                  {{row.mcv_vacgan}} </td>
              </ng-container>

              <ng-container matColumnDef="mcv_vacpag">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Vac. Pagadas </th>
                <td mat-cell class="format-importe" *matCellDef="let row" data-label="Código">
                  {{row.mcv_vacpag}} </td>
              </ng-container>

              <ng-container matColumnDef="mcv_vacade">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Vac. Adelantadas </th>
                <td mat-cell class="format-importe" *matCellDef="let row" data-label="Código">
                  {{row.mcv_vacade}} </td>
              </ng-container>

              <ng-container matColumnDef="mcv_venvac">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Venta Vac. </th>
                <td mat-cell class="format-importe" *matCellDef="let row" data-label="Código">
                  {{row.mcv_venvac}} </td>
              </ng-container>

              <ng-container matColumnDef="mcv_saldvac">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Solo Vac. </th>
                <td mat-cell class="format-importe" *matCellDef="let row" data-label="Código">
                  {{row.mcv_saldvac}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsControl"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsControl;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10" *ngIf="!loaderControl">No se encontraron registros</td>
                <td class="mat-cell" colspan="10" *ngIf="loaderControl">
                  <mat-spinner diameter="40"></mat-spinner>
                </td>
              </tr>

            </table>
          </div>
          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"
            [disabled]="!formValidated"></mat-paginator>

        </mat-tab>
        <mat-tab class="legajo" labelClass="mat-tab-label-0-3" label="Legajo" [disabled]="!formValidated">

          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">
            </div>
            <div class="pav-table-header-title">
              Tabla
            </div>
            <div class="pav-button-add-item">
              <div class="isMobile">
                <div class="pav-button-icon-add" (click)="agregarItemLegajo()">
                  <mat-icon svgIcon="add"></mat-icon>
                </div>
              </div>
              <div class="isWeb">
                <button type="button" #btnAdd (click)="agregarItemLegajo()" (keyup.enter)="agregarItemLegajo()"
                  mat-flat-button [disabled]="!formValidated">Agregar item</button>
              </div>
            </div>
          </div>
          <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSourceLegajo" matSort>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones" class="td-responsive">
                  <div class="pav-btns-container">
                    <div *ngIf="!row.isEditing" color="primary" type="button" matTooltip="Editar Ítem"
                      class="pav-btn-circle-edit" (click)="row.isEditing = !row.isEditing">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                    <div *ngIf="row.isEditing" color="primary" type="button" matTooltip="Actualizar Ítem"
                      class="pav-btn-circle-confirm" (click)="confirmarNuevoItemLegajo(row)">
                      <mat-icon svgIcon="confirm"></mat-icon>
                    </div>
                    <div color="primary" type="button" matTooltip="Eliminar Ítem" class="pav-btn-circle-delete"
                      (click)="quitarItemLegajo(row.cornle)">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="nle_cornle">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Item" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput placeholder="Item" [(ngModel)]="row.cornle" [name]="'cornle' + i.toString()"
                        disabled>
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="TDL_CODTDL">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Tipo Doc." class="td-responsive">
                  <select-search
                    [clase]="!row? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [label]="''" [placeholder]="'Ej. TORRES'" [name]="'tdl_codtdl' + i" [value]="'tdl_codtdl'"
                    [description]="'tdl_descri'" [data]="tiposDocumentoLegajo" (cambioSelect)="row.tdl_codtdl = $event"
                    [model]="row.tdl_codtdl">
                  </select-search>

                  <!-- <mat-form-field class="mff-table pav-mat-form-margin-top">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="row.codtdl" [name]="'codtdl' + i.toString()" [disabled]="!row.isEditing">
                      <mat-option *ngFor="let tipo of tiposDocumento; trackBy: trackByTipoDoc"
                        [value]="tipo.DID_CODDID">
                        {{tipo.DID_DESCRI}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                </td>
              </ng-container>

              <ng-container matColumnDef="tdl_descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Descripción" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput placeholder="Descripción" [(ngModel)]="row.glosa" [name]="'glosa' + i.toString()"
                        [disabled]="!row.isEditing">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="nle_fecing">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Ingreso </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Fec. Ingreso" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput [matDatepicker]="picker" placeholder="Fec. Ingreso" [(ngModel)]="row.fecing"
                        [name]="'fecing' + i.toString()" [disabled]="!row.isEditing">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="nle_fecvig">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vigencia </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Fec. Vigencia" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput [matDatepicker]="picker" placeholder="Fec. Vigencia" [(ngModel)]="row.fecvig"
                        [name]="'fecvig' + i.toString()" [disabled]="!row.isEditing">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="nle_fecven">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vcto. </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Vcto." class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput [matDatepicker]="picker" placeholder="Fec. Vcto." [(ngModel)]="row.fecven"
                        [name]="'fecven' + i.toString()" [disabled]="!row.isEditing">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="nle_indsta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Estado" class="td-responsive">
                  <mat-form-field class="mff-table pav-mat-form-margin-top">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="row.indsta" [name]="'indsta' + i.toString()" [disabled]="!row.isEditing">
                      <mat-option value="1">
                        VIGENTE
                      </mat-option>
                      <mat-option value="0">
                        ANULADO
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="adjuntar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjuntar </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Estado" class="td-responsive">
                  <div [ngStyle]="{display: 'flex', alignItems: 'center', marginLeft: '20px'}">
                    <ng-container *ngIf="row.formato">
                      <ng-container *ngIf="row.formato.includes('image/')">

                        <div
                          [ngStyle]="{marginRight: '5px', border: '2px solid #222', width: '50px', height: '50px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                          <img [src]="row.documento" width="40" height="40" alt="Imagen"
                            [ngStyle]="{cursor: 'pointer'}">
                        </div>
                      </ng-container>
                      <ng-container *ngIf="row.formato == 'application/pdf'">
                        <div
                          [ngStyle]="{marginRight: '5px', border: '2px solid #222', width: '50px', height: '50px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                          <mat-icon>picture_as_pdf</mat-icon>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="row.formato == 'application/zip'">
                        <div
                          [ngStyle]="{marginRight: '5px', border: '2px solid #222', width: '50px', height: '50px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                          <mat-icon>folder_zip</mat-icon>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="row.formato == 'text/xml'">
                        <div
                          [ngStyle]="{marginRight: '5px', border: '2px solid #222', width: '50px', height: '50px', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}">
                          <span [ngStyle]="{fontWeight: 'bold', fontSize: '14px'}">XML</span>
                        </div>
                      </ng-container>
                    </ng-container>

                    <pav-button-upload-file accept="image/*, application/pdf, .xml"
                      (onUploadFile)="handleOnUploadFile(row, $event)"></pav-button-upload-file>

                    <ng-container *ngIf="row.documento">
                      <button type="button" (click)="openFile(row.documento, row.formato)"
                        [ngStyle]="{marginLeft: '5px'}" mat-mini-fab color="primary"
                        aria-label="Example icon button with a menu icon">
                        <mat-icon>visibility</mat-icon>
                      </button>
                    </ng-container>

                    <ng-container *ngIf="row.ccd_idccd">
                      <button type="button" (click)="quitarDocumento(row.ccd_idccd)" [ngStyle]="{marginLeft: '5px'}"
                        mat-mini-fab color="warn" aria-label="Example icon button with a menu icon">
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                    </ng-container>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsLegajo"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsLegajo;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10">No se encontraron registros</td>

              </tr>

            </table>
          </div>
          <mat-paginator #pagLegajo [pageSizeOptions]="[5, 10, 25, 100]" [disabled]="!formValidated"></mat-paginator>
        </mat-tab>
        <mat-tab class="dependientes-colaborador" labelClass="mat-tab-label-0-4" label="Dependientes del Colaborador"
          [disabled]="!formValidated">
          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">
            </div>
            <div class="pav-table-header-title">
              Tabla
            </div>
            <div class="pav-button-add-item">
              <div class="isMobile">
                <div class="pav-button-icon-add" (click)="agregarItemDependiente()">
                  <mat-icon svgIcon="add"></mat-icon>
                </div>
              </div>
              <div class="isWeb">
                <button type="button" #btnAdd (click)="agregarItemDependiente()"
                  (keyup.enter)="agregarItemDependiente()" mat-flat-button>Agregar item</button>
              </div>
            </div>
          </div>
          <div class="mat-elevation-z0 overflow-x">
            <table mat-table [dataSource]="dataSourceDependiente" matSort>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones" class="td-responsive">
                  <div class="pav-btns-container">
                    <div *ngIf="!row.isEditing" color="primary" type="button" class="pav-btn-circle-edit"
                      matTooltip="Editar Ítem" (click)="row.isEditing = !row.isEditing">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                    <div *ngIf="row.isEditing" color="primary" type="button" class="pav-btn-circle-confirm"
                      matTooltip="Actualizar Ítem" (click)="confirmarNuevoItemDependiente(row)">
                      <mat-icon svgIcon="confirm"></mat-icon>
                    </div>
                    <div color="primary" type="button" matTooltip="Eliminar Ítem" class="pav-btn-circle-delete"
                      (click)="quitarItemDependiente(row.cordtr)">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Item" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput placeholder="Item" [(ngModel)]="row.cordtr" [name]="'cordtr' + i.toString()"
                        disabled>
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="apellido_paterno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido Paterno </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Ape. Paterno" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput placeholder="Apellido Paterno" [(ngModel)]="row.apepat"
                        [disabled]="!row.isEditing" [name]="'apepat' + i.toString()">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="apellido_materno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Apellido Materno </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Ape. Materno" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput placeholder="Apellido Materno" [(ngModel)]="row.apemat"
                        [name]="'apemat' + i.toString()" [disabled]="!row.isEditing">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="nombres">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombres </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Nombres" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput placeholder="Nombres" [(ngModel)]="row.nombre" [disabled]="!row.isEditing"
                        [name]="'nombre' + i.toString()">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="tipo_doc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Tipo Doc." class="td-responsive">
                  <mat-form-field class="mff-table pav-mat-form-margin-top">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="row.coddoc" [name]="'coddoc' + i.toString()" [disabled]="!row.isEditing">
                      <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.DID_CODDID">
                        {{tipo.DID_DESCRI}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="nro_documento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de documento </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Número de documento"
                  class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput placeholder="Número de documento" [(ngModel)]="row.numdoc"
                        [name]="'numdoc' + i.toString()" [disabled]="!row.isEditing">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="fecha_nacimiento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Nac. </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Fecha de Nac." class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="row.fecnac" placeholder="Fecha de Nac."
                        [name]="'fecnac' + i.toString()" [disabled]="!row.isEditing">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="sexo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexo </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Sexo" class="td-responsive">
                  <mat-form-field class="pav-mat-form-margin-top">
                    <mat-label></mat-label>
                    <mat-select [disabled]="!row.isEditing" [(ngModel)]="row.indsex" [name]="'indsex' + i.toString()">
                      <mat-option *ngFor="let sexo of sexos" [value]="sexo.gpe_codgpe">
                        {{sexo.gpe_descri}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="vinculo_familiar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Vínculo Familiar </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Vínculo Familiar" class="td-responsive">
                  <mat-form-field class="pav-mat-form-margin-top">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="row.codvin" [name]="'codvin' + i.toString()" [disabled]="!row.isEditing">
                      <mat-option *ngFor="let vinculo of vinculos" [value]="vinculo.vfa_codvfa">
                        {{vinculo.vfa_descri}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="glosa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input matInput placeholder="Glosa" [(ngModel)]="row.glosa" [name]="'dtr_glosa' + i.toString()"
                        [disabled]="!row.isEditing">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsDependiente"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsDependiente;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="11">No se encontraron registros</td>

              </tr>

            </table>
          </div>
          <mat-paginator [disabled]="!formValidated" #pagDependiente
            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </mat-tab>
        <mat-tab class="remuneracion" labelClass="mat-tab-label-0-5" label="Remuneración" [disabled]="!formValidated">
          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">
            </div>
            <div class="pav-table-header-title">
              Tabla
            </div>
            <div class="pav-separt"></div>
            <div class="pav-button-add-item">
              <div class="isMobile">
                <div class="pav-button-icon-add" (click)="agregarItemRemuneracion()">
                  <mat-icon svgIcon="add"></mat-icon>
                </div>
              </div>
              <div class="isWeb">
                <button type="button" #btnAdd (click)="agregarItemRemuneracion()"
                  (keyup.enter)="agregarItemRemuneracion()" mat-flat-button [disabled]="!formValidated">Agregar
                  item</button>
              </div>
            </div>
          </div>
          <div class="mat-elevation-z0 overflow-x">

            <table mat-table [dataSource]="dataSourceRemuneracion" matSort>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones" class="td-responsive">
                  <div class="pav-btns-container">
                    <div *ngIf="!row.isEditing" color="primary" type="button" class="pav-btn-circle-edit"
                      matTooltip="Editar Ítem" (click)="row.isEditing = !row.isEditing">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </div>
                    <div *ngIf="row.isEditing" color="primary" type="button" class="pav-btn-circle-confirm"
                      matTooltip="Actualizar Ítem" (click)="confirmarNuevoItemRemuneracion(row)">
                      <mat-icon svgIcon="confirm"></mat-icon>
                    </div>
                    <div color="primary" type="button" matTooltip="Eliminar Ítem" class="pav-btn-circle-delete"
                      (click)="quitarItemRemuneracion(row.itemR)">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="nco_codnco">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Concepto </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Concepto" class="td-responsive">
                  <ng-container>
                    <mat-form-field class="pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <mat-select [(ngModel)]="row.codnco" (selectionChange)="seleccionarConceptoRem(row)"
                        [name]="'nco_codnco' + i.toString()" [disabled]="!row.isEditing">
                        <mat-option *ngFor="let concepto of conceptos" [value]="concepto.codnco">
                          {{concepto.descri}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                <td mat-cell class="format-importe" *matCellDef="let row; let i = index;" data-label="Importe"
                  class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input class="pav-input-align-right" min="0" type="number" matInput placeholder="Importe"
                        [(ngModel)]="row.imprem" [name]="'imprem' + i.toString()" [disabled]="!row.isEditing">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiempo </th>
                <td mat-cell class="format-importe" *matCellDef="let row; let i = index;" data-label="Tiempo"
                  class="td-responsive">
                  <ng-container>
                    <mat-form-field class="mff-table pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <input class="pav-input-align-right" type="number" min="0" matInput placeholder="Tiempo"
                        [(ngModel)]="row.cannut" [name]="'cannut' + i.toString()" [disabled]="!row.isEditing">
                    </mat-form-field>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="unidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad </th>
                <td mat-cell class="format-importe" *matCellDef="let row; let i = index;" data-label="Unidad"
                  class="td-responsive">

                  <ng-container>
                    <mat-form-field class="pav-mat-form-margin-top">
                      <mat-label></mat-label>
                      <mat-select [(ngModel)]="row.codnut" [name]="'codnut' + i.toString()" disabled>
                        <mat-option *ngFor="let unidad of unidades" [value]="unidad.nut_codnut">
                          {{unidad.nut_descri}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsRemuneracion"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsRemuneracion;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">No se encontraron registros</td>
              </tr>

            </table>

          </div>
          <mat-paginator #pagRemuneracion [disabled]="!formValidated"
            [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-planilla/trabajadores">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
