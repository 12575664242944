export class MotivoRechazo{
    cia_codcia:string;
    mre_codmre:string;
    mre_desmre:string;
    mre_indsta:string;
    mre_fecupd:Date;
    mre_coduse:string;
    constructor(){
        this.mre_codmre = '';
        this.mre_indsta = '1',
        this.mre_fecupd = new Date(); 
    }
}