<!-- <div id="chart">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [xaxis]="chartOptions.xaxis"
    [stroke]="chartOptions.stroke"
  ></apx-chart>
</div> -->

<div id="chartProject">
</div>
