import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ProveedorService } from "src/app/services/api/compras/maestros/proveedor.service";

@Component({
  selector: 'proveedor-modal-list',
  templateUrl: './proveedor-modal-list.component.html',
  styleUrls: ['./proveedor-modal-list.component.scss']
})
export class ProveedorModalListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'CODIGO', 'NOMBRE', 'TELEFONO'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading$: Subscription;
  proveedores$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _proveedorService: ProveedorService,
    private _snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerProveedores();
    })
  }

  obtenerProveedores(): void {
    this.loaderData = true;
    this.proveedores$ = this._proveedorService.obtenerProveedores().subscribe(
      proveedores => {
        this.dataSource = fillTable(proveedores, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener proveedores', 'Ok');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([])
  }
}
