import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: 'mover-a-orden-dialog',
  templateUrl: './mover-a-orden-compra.dialog.html',
  styleUrls: ['./mover-a-orden-compra.dialog.scss']
})
export class MoverAOrdenCompraDialog {

  constructor(
    public dialogRef: MatDialogRef<MoverAOrdenCompraDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _router: Router
  ) {}

  cancelar(): void {
    this.dialogRef.close(false);
  }

  mover(): void {
    this._router.navigate(['/modulo-compras/orden-de-compra/0']);
    this.dialogRef.close(true);
  }

}
