export class FiltroReporteCRM {
  canal: Array<any>;
  vendedor: Array<any>;
  linea: Array<any>;
  tipoNegociacion: Array<any>;
  cliente: Array<any>;
  bienServicio: Array<any>;
  responsable: Array<any>;
  etapa: Array<any>;
  tipoAtencion: Array<any>;

  constructor() {
    this.canal = [];
    this.vendedor = [];
    this.linea = [];
    this.tipoNegociacion = [];
    this.cliente = [];
    this.bienServicio = [];
    this.responsable = [];
    this.etapa = [];
    this.tipoAtencion = [];
  }
}
