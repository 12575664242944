interface Linea{
    lpd_descri:string;
}
interface Operario{
    cli_nomcli:string;
}
export class LineaOperacion{
        cia_codcia:string;
        lpd_codlpd:string;
        lpo_seclpo:string;
        cli_codcli:string;
        lpo_fecupd:Date;
        lpo_coduse:string;
        linea:Linea;
        operario: Operario;
        constructor(){
            this.lpo_seclpo = '';
            this.lpo_fecupd = new Date(); 
        }
}