import { Component, Input } from "@angular/core";
import { IBreadcrumbItem } from "./breadcrumb-crm.interface";

@Component({
  selector: 'breadcrumb-crm',
  templateUrl: './breadcrumb-crm.component.html',
  styleUrls: ['./breadcrumb-crm.component.scss']
})
export class BreadcrumbCRMComponent {

  @Input() items: Array<IBreadcrumbItem>;

  constructor() {

  }


}
