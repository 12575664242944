import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ExchangeRateAddComponent } from '@shared/components/dialogs/exchange-rate-add/exchange-rate-add.component';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ExchangeRate } from 'src/app/models';
import { AuthenticationService, ContabilidadService } from 'src/app/services';
import { TipoCambioService } from 'src/app/services/api/contabilidad/maestros/tipo-cambio.service';

@Component({
  selector: 'tipo-cambio-create',
  templateUrl: './tipo-cambio-form.component.html',
  styleUrls: ['./tipo-cambio-form.component.css']
})
export class TipoCambioFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;
  btnName: string;

  displayedColumns: string[] = ['mon', 'descripcion', 'compra', 'venta', 'usuario'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  exchangeRate: ExchangeRate;

  monedas: any[] = [];

  usuario = null;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tipoCambio$: Subscription;
  loading$: Subscription;

  fecha: string;
  monedaNacional: string;
  monedaExtranjera: string;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    private _authService: AuthenticationService,
    private _tipoCambioService: TipoCambioService,

    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute
  ) {

    this.initialize();

  }

  initialize(): void {
    this.exchangeRate = new ExchangeRate();

    this.dataSource = fillTable([1], this.paginator, this.sort);

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.laodData();
    })
  }

  laodData(): void {
    this._activatedRoute.params.subscribe(({fecha, mn, me}) => {
      this.fecha = fecha;
      this.monedaNacional = mn;
      this.monedaExtranjera = me;

      if(this.fecha != '0') {
        this.obtenerTipoCambio();
        return
      };
    })
  }

  obtenerTipoCambio(): void {
    this._tipoCambioService.obtenerTipoCambio(this.fecha, this.monedaNacional, this.monedaExtranjera).subscribe(
      response => {
        this.exchangeRate = response;
      }),
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'Ok');
      }
  }

  cambioDeFecha(value): void {
  }

  agregarTipoCambio(): void {
    const dialogRef = this.dialog.open(ExchangeRateAddComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.exchangeRate.TCA_COMOFI = result.compra;
      this.exchangeRate.TCA_VENOFI = result.venta;
    });
  }

  enviarFormulario(f: NgForm): void {
    this.fecha == '0' ? this.registrarTipoCambio(f) : this.actualizarTipoCambio(f);
  }

  registrarTipoCambio(f: NgForm): void {
    this.loaderReg = true;
    this._tipoCambioService.registrarTipoCambio(this.exchangeRate).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Tipo de Cambio Registrado', '300px', '', '');

      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  actualizarTipoCambio(f: NgForm): void {
    this.loaderReg = true;
    this._tipoCambioService.actualizarTipoOperacion(this.exchangeRate).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Tipo de Cambio Actualizado', '300px', '', '');

      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  nuevoRegistro(): void {
    this.initialize();

    this.fecha = '0';
    this.monedaNacional = '0';
    this.monedaExtranjera = '0';
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/tipos-de-cambio']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoCambio$]);
  }

}
