export class FiltroReporteVenta {
  moneda: string;
  documento: Array<any>;
  sectorista: Array<any>;
  supervisor: Array<any>;
  tienda: Array<any>;
  serie: Array<any>;
  motivo: Array<any>;
  vendedor: Array<any>;
  cliente: Array<any>;
  condicion: Array<any>;
  producto: Array<any>;
  inventario: Array<any>;
  canal: Array<any>;
  linea: Array<any>;
  marca: Array<any>;
  color: Array<any>;
  modelo: Array<any>;
  zona: Array<any>;
  periodos: Array<any>;
  incluidoIGV: boolean;
  agrupador1: string;
  agrupador2: string;
  agrupador3: string;
  verDetalle: boolean;


  constructor() {
    this.moneda = "SO";
    this.documento = [];
    this.sectorista = [];
    this.supervisor = [];
    this.tienda = [];
    this.serie = [];
    this.motivo = [];
    this.vendedor = [];
    this.cliente = [];
    this.condicion = [];
    this.producto = [];
    this.inventario = [];
    this.canal = [];
    this.linea = [];
    this.marca = [];
    this.color = [];
    this.modelo = [];
    this.zona = [];
    this.periodos = [];
    this.incluidoIGV = false;
    this.verDetalle = true;
  }
}
