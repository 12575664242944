<app-pavso-title-section
  [title]="'apps.crm.operaciones.presupuesto.title' | translate"
  [module]="'CRM'"
></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input
        type="text"
        (keyup)="applyFilter($event)"
        [placeholder]="buttonsName.BTN_SEARCH"
        #input
      />
    </div>
  </div>

  <table-title-action
      [title]="'apps.crm.operaciones.presupuesto.title' | translate"
      url="/modulo-crm/presupuesto"
      entity=""
      [params]="['0', '0', '0']"
  ></table-title-action>

  <div class="mat-elevation-z0">
    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
          <td
            mat-cell
            *matCellDef="let row"
            [style.color]="row.color"
            data-label="Acciones">
            <div class="pav-btns-container">
              <a
                class="pav-btn-circle-edit"
                [routerLink]="[row.idtkt, row.idset, row.numopc]">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </a>
              <div
                (click)="generarPDF()"
                class="pav-btn-circle-edit">
                <mat-icon svgIcon="pdf"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="idtkt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Nro. Oportunidad
          </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Oportunidad">
            {{ row.idtkt }}
          </td>
        </ng-container>

        <ng-container matColumnDef="idset">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nro. Seguimiento</th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Seguimiento">
            {{ row.idset }}
          </td>
        </ng-container>

        <ng-container matColumnDef="numopc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nro. Presupuesto</th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Presupuesto">
            {{ row.numopc }}
          </td>
        </ng-container>

        <ng-container matColumnDef="obsopc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</th>
          <td mat-cell *matCellDef="let row" data-label="Descripción">
            {{ row.obsopc }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No se encontraron registros "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>

    <mat-paginator
      showFirstLastButtons
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
  </div>
</div>

