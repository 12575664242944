<app-pavso-title-section [title]="'Conceptos remunerativo'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="conceptos"
    [tHeaders]="tHeaders"

    title="Todos los conceptos"
    url="/modulo-planilla/concepto-remunerativo"
    entity=""
    [params]="['0']"
  ></pav-table-list>

</div>
