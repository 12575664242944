export class ZonaVenta {
  CIA_CODCIA: string;
  ZVE_CODZVE: string;
  ZVE_NOMZVE: string;
  ZVE_OBSZVE: string;
  ZVE_INDSTA: string;
  ZVE_CODUSE: string;
  ZVE_FECUPD: Date;

  constructor() {
    this.ZVE_FECUPD = new Date();
    this.ZVE_INDSTA = '1';
  }
}
