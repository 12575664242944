<app-pavso-title-section [title]="'Procesos generales'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <mat-tab-group #tabGroup>
    <mat-tab label="Genera Reportes Contables - Analíticos (1)" labelClass="mat-tab-label-left">
      <mat-card>
        <div class="pavso-content-stepper" align="center">
          <button mat-flat-button color="primary" (click)="generar01()">Generar</button>
        </div>
      </mat-card>
    </mat-tab>
    <mat-tab label="Diferencia de Cambio - Ctas Analíticas (2)" labelClass="mat-tab-label-center">
      <mat-card>
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Fecha</mat-label>
              <input matInput [matDatepicker]="picker2" [(ngModel)]="paso02.fecha" name="fecha">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Compra</mat-label>
              <input matInput placeholder="Ej. 23" [(ngModel)]="paso02.compra" name="compra" readonly>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Venta</mat-label>
              <input matInput placeholder="Ej. 23" [(ngModel)]="paso02.venta" name="venta" readonly>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-checkbox class="example-margin" [(ngModel)]="paso02.eliminar" name="eliminar">Eliminar</mat-checkbox>
          </div>

        </div>
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Dif. Cambio Perdida</mat-label>
              <input matInput placeholder="Ej. 23" [(ngModel)]="paso02.difCambioPerdida" name="difCambioPerdida">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Dif. Cambio Ganancia</mat-label>
              <input matInput placeholder="Ej. 23" [(ngModel)]="paso02.difCambioGanancia" name="difCambioGanancia">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Traslación Perdida</mat-label>
              <input matInput placeholder="Ej. 23" [(ngModel)]="paso02.trasPerdida" name="trasPerdida">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Traslación Ganancia</mat-label>
              <input matInput placeholder="Ej. 23" [(ngModel)]="paso02.trasGanancia" name="trasGanancia">
            </mat-form-field>
          </div>

        </div>
        <div class="pavso-content-stepper" align="center">
          <button mat-flat-button color="primary" (click)="generar02()">Generar</button>
        </div>
      </mat-card>
    </mat-tab>
    <mat-tab label="Diferencia de Cambio, Cta (3)" labelClass="mat-tab-label-right">
      <mat-card>
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Fecha</mat-label>
              <input matInput [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Compra</mat-label>
              <input matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Venta</mat-label>
              <input matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-checkbox class="example-margin">Eliminar</mat-checkbox>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Dif. Cambio Perdida</mat-label>
              <input matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Dif. Cambio Ganancia</mat-label>
              <input matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Traslación Perdida</mat-label>
              <input matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field class="example-full-width">
              <mat-label>Traslación Ganancia</mat-label>
              <input matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
        </div>
        <div class="pavso-content-stepper" align="center">
          <button mat-flat-button color="primary" (click)="generar03()">Generar</button>
        </div>
      </mat-card>
    </mat-tab>
  </mat-tab-group>

  <!-- <mat-horizontal-stepper>
    <mat-step label="Genera Reportes Contables - Analíticos (1)">
      <div class="pavso-content-stepper" align="center">
        <button mat-flat-button color="primary">Generar</button>
      </div>

      <br>
      <div>
        <button mat-stroked-button matStepperNext>Siguiente</button>
      </div>
    </mat-step>

    <mat-step label="Diferencia de Cambio - Ctas Analíticas (2)">
      <div class="pavso-content-stepper" align="center">
        <button mat-flat-button color="primary">Realizar</button>
      </div>
      <br>
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-stroked-button matStepperNext>Siguiente</button>
      </div>
    </mat-step>

    <mat-step label="Diferencia de Cambio, Cta (3)">
      <div class="pavso-content-stepper" align="center">
        <button mat-flat-button color="primary">Realizar</button>
      </div>
      <br>
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-stroked-button matStepperNext>Siguiente</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <button mat-stroked-button color="primary">Realizar Proceso Global</button>
  &nbsp;
  <button mat-stroked-button color="primary">Verificar Tipo de Cambio</button> -->

</div>
