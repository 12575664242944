<h1 mat-dialog-title>
    Detalle Producto
    <button mat-icon-button class="btn-close" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h1>


<mat-list>
    <mat-list-item class="mat-elevation-z0" *ngIf="itemsProducto.length == 0">
        <span mat-line>No existen datos</span>
    </mat-list-item>
    <mat-list-item class="mat-elevation-z0" *ngFor="let item of itemsProducto">
        <span mat-line>{{item.ocf_descri}}</span>

        <button mat-icon-button color="warn" (click)="eliminarItem(item.id)">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-list-item>
</mat-list>

<br>
<form #f="ngForm" ngNativeValidate (ngSubmit)="agregarItem(f)" (keydown.enter)="$event.preventDefault()">
    <div class="row">
        <div class="col s12">
            <mat-form-field>
                <mat-label>Descripción</mat-label>
                <textarea matInput placeholder="Descripción" [(ngModel)]="description" name="description" required></textarea>
            </mat-form-field>
        </div>
        <div class="col s12">
            <button mat-flat-button type="submit">Agregar</button>
        </div>
    </div>
</form>
