import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class RegistroCompraService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @param data
   * @returns
   */
  obtenerDatosFactura(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('enctype', 'multipart/form-data');
    return this.http.post<FormData>(
      `https://pavservice.com:3003/api/invoice/upload`,
      data, {headers}
    );
  }

  /**
   * Obtener compras desde el SIRE
   * @param payload
   * @returns
   */
  obtenerComprasSIRE(payload: {codcia: string, codano: string, codmes: string, coduse: string, codtmo: string}): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/compras/sire`,
      payload
    )
  }

  obtenerEstadoSIRE(codano, codmes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/compras/sire/consulta-ticket/${this._configurationService.obtenerCompaniaCliente()}/${codano}/${codmes}`,
    )
  }

}
