import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { LineaProductoService } from "src/app/services/api/produccion/maestros/linea-producto.service";

@Component({
  selector: 'linea-producto-list',
  templateUrl: './linea-producto-list.component.html',
  styleUrls: ['./linea-producto-list.component.scss']
})
export class LineaProductoListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'lpd_codlpd', 'lpd_descri', 'lpd_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  lineas$: Subscription;
  loading$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _lineaService: LineaProductoService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerLineasProducto();
    })
  }

  obtenerLineasProducto(): void {

    this.loaderData = true;

    this.lineas$ = this._lineaService.obtenerLineasProducto().subscribe(
      response => {
        console.log('lineas producto', response)
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.lineas$
    ])
  }

}
