import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Subscription } from 'rxjs';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { CuentaCobrarService } from 'src/app/services';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { LineaDocumento } from 'src/app/models';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { LineaDocumentoService } from 'src/app/services/api/cuentas-por-cobrar/maestros/linea-documento.service';

@Component({
  selector: 'app-linea-documento-form',
  templateUrl: './linea-documento-form.component.html',
  styleUrls: ['./linea-documento-form.component.css']
})
export class LineaDocumentoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  lineaDoc$: Subscription;
  loading$: Subscription;

  linea: LineaDocumento;

  uid: string;
  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _lineaDocumentoService: LineaDocumentoService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {
    this.linea = new LineaDocumento();
    this.linea.lcc_coduse = this._configurationService.obtenerIdUsuario();

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      if(id=='0') return;

      this.lineaDoc$ = this._lineaDocumentoService.listarLineaDocumento().subscribe(
        lineas => {

          const linea = lineas.find(item => item.codigo_lineacod == id);
          this.linea = linea

          this.loaderData = false;
        },
        error => {
          this.loaderData = false;
          this._snackBarService.showError(error.error.msg, 'OK');
        }
      )

    })

  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarLineaDocumento(f): this.actualizarLineaDocumento(f);
  }

  registrarLineaDocumento(f: NgForm): void {
    this._lineaDocumentoService.registrarLineaDocumento(this.linea).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Linea de documento registrado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarLineaDocumento(f: NgForm): void {
    this._lineaDocumentoService.registrarLineaDocumento(this.linea).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Linea de documento registrado', '400px', '400px', '');
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-cobranzas/lineas-de-documentos']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.lineaDoc$,
      this.loading$
    ])
  }
}
