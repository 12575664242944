<div [ngStyle]="{display: 'flex', alignItems: 'center', marginBottom: '5px'}">
  <!-- <button mat-flat-button color="primary" (click)="agregarProveedor()">Agregar Proveedor</button> -->
  <!-- <h3 [ngStyle]="{fontSize: '16px'}">Nuevos proveedores</h3> -->

  <div class="pav-separator"></div>

  <button mat-flat-button [ngStyle]="{height: '45px', background: '#2360ff', color: 'white'}" (click)="agregarProveedor()">
    <mat-icon>add</mat-icon>
    Agregar
  </button>

</div>

<div class="overflow-x overflow-y">
  <table mat-table [dataSource]="dataSourceProveedores" matSort
    [ngStyle]="{border: '4px #ddd solid', borderRadius: '15px'}">


    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td mat-cell *matCellDef="let row" data-label="Acciones" class="td-responsive">
        <div class="pav-btns-container">
          <!-- <div class="pav-btn-circle-edit" matTooltip="Confirmar" (click)="confirmarRegistroProveedor(row)">
            <mat-icon svgIcon="active"></mat-icon>
          </div> -->
          <div class="pav-btn-circle-edit" matTooltip="Editar" (click)="editarDatos(row)">
            <mat-icon svgIcon="edit-white"></mat-icon>
          </div>
        </div>
        <!-- <button mat-icon-button matTooltip="Confirmar" aria-label="Example icon button with a vertical three dot icon" (click)="confirmarRegistroProveedor(row)">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Editar" aria-label="Example icon button with a vertical three dot icon" (click)="editarDatos(row)">
          <mat-icon>edit</mat-icon>
        </button> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="razon">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
      <td mat-cell *matCellDef="let row" data-label="Razón Social" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.razon}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="ruc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> RUC </th>
      <td mat-cell *matCellDef="let row" data-label="RUC" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.ruc}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comercial">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom. Comercial </th>
      <td mat-cell *matCellDef="let row" data-label="Nom. Comercial" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.comercial}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="condicion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Condición </th>
      <td mat-cell *matCellDef="let row" data-label="Condición" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.condicion}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
      <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.estado}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="fecha_insc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Inscripción </th>
      <td mat-cell *matCellDef="let row" data-label="Fecha de emisión" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.fecha_insc}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="direccion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
      <td mat-cell *matCellDef="let row" data-label="Dirección" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.direccion}}

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="telefono">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
      <td mat-cell *matCellDef="let row" data-label="Teléfono" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.telefono}}

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="ubicacion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubicación </th>
      <td mat-cell *matCellDef="let row" data-label="Departamento" class="td-responsive"> {{row.departamento}} -
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.provincia}} - {{row.distrito}}

        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="observaciones">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Observaciones </th>
      <td mat-cell *matCellDef="let row" data-label="Observaciones" class="td-responsive">
        <div [ngStyle]="!row.ubigeo ?{color: 'red'}: {}">
          {{row.ubigeo? 'Ubigeo encontrado': 'Ubigeo no encontrado'}}

        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsProveedores"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsProveedores;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="10">No se encontraron registros</td>
    </tr>

  </table>
</div>


<mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
