import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { Subscription } from "rxjs";
import { CanalVenta } from "src/app/models/ventas/canal-venta";
import { VentasService } from "src/app/services";

@Component({
  selector: 'canal-venta-form',
  templateUrl: './canal-venta-form.component.html',
  styleUrls: ['./canal-venta-form.component.scss']
})
export class CanalVentaFormComponent implements OnInit {
  loaderReg: boolean;
  loaderData: boolean;

  uid: string;
  canal: CanalVenta;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  loading$: Subscription;
  canalesVenta$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private store: Store<PavsoState>
  ) {
    this.canal = new CanalVenta();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      if(this.uid == '0') return;
      this.obtenerCanalVenta();
    })
  }

  /**
   *
   */
  obtenerCanalVenta(): void {
    this.canalesVenta$ = this._ventasService.obtenerCanalesVenta().subscribe(
      canales => this.canal = canales.find(item => item.vds_codvds == this.uid),
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  /**
   *
   * @param f
   */
  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarCanalVenta(f): this.actualizarCanalVenta(f);
  }

  /**
   *
   * @param f
   */
  registrarCanalVenta(f: NgForm): void {
    this.loaderReg = true;
    this._ventasService.registrarCanalVenta(this.canal).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Canal de venta registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  /**
   *
   * @param f
   */
  actualizarCanalVenta(f: NgForm): void {
    this.loaderReg = true;

    this._ventasService.actualizarCanalVenta(this.canal).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Canal de venta actualizada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/canales-de-venta']);
  }

}
