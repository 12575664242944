<app-pavso-title-section [title]="'Tipo de documento legajo'" [module]="'Planilla'" [estado]="tipoDocLegajo.indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Código</mat-label>
                      <input type="text" [(ngModel)]="tipoDocLegajo.codtdl" matInput placeholder="Código" name="codtdl" disabled>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6"></div>

              <div class="col s12 m12">
                  <mat-form-field>
                      <mat-label>Descripción</mat-label>
                      <input type="text" [(ngModel)]="tipoDocLegajo.descri" matInput placeholder="Descripción" name="descri" required>
                  </mat-form-field>
              </div>
          </div>

      </mat-card>

    </div>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-planilla/tipos-de-documento-legajo">
      <div id="botonesAdicionales">
      </div>
    </pav-form-actions>
  </form>
