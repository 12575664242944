import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class TipoGastoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  registrarTipoGasto(tipoOperacion: any): Observable<any>{
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/tipogasto/crear`,
      tipoOperacion
    )
  }

  actualizarTipoGasto(tipoOperacion: any): Observable<any>{
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/tipogasto/modificar`,
      tipoOperacion
    )
  }

  obtenerTipoGasto(id): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tipogasto/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    )
  }

  obtenerTiposGasto(): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listatipoope/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((tiposOperacion: any) => {
        return tiposOperacion.map(tipoOperacion => {
          tipoOperacion['description'] = `${tipoOperacion.tga_codtga} | ${tipoOperacion.tga_descri}`
          tipoOperacion['description_reverse'] = `${tipoOperacion.tga_descri} | ${tipoOperacion.tga_codtga}`
          return tipoOperacion;
        })
      })
    )
  }


}
