<app-pavso-title-section [title]="'Cuenta corriente préstamos'" [module]="'Planilla'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Nro. operación</mat-label>
                      <input type="text" matInput placeholder="Nro. Operación" name="nro_operacion" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Fecha</mat-label>
                      <input

                      matInput [matDatepicker]="fechahoy">
                      <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
                      <mat-datepicker #fechahoy></mat-datepicker>
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Cta. Cte.</mat-label>
                      <input type="text" matInput placeholder="Cta. Cte." name="cta_cte" required>
                  </mat-form-field>
              </div>

          </div>
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Nro. de Cuotas</mat-label>
                      <input class="pav-input-align-right" type="number" min="0" matInput placeholder="Nro. de Cuotas" name="nro_cuotas" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <app-currency-select [moneda]="'SO'" (cambioMoneda)="$event"></app-currency-select>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Importe S/.</mat-label>
                      <input [(ngModel)]="importeS" (blur)="blurImporteS()" #impS (focus)="impS.select()" class="pav-input-align-right" type="number" min="0" matInput placeholder="Importe S/." name="importe_soles" required>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Importe $.</mat-label>
                      <input [(ngModel)]="importeD" (blur)="blurImporteD()" #impD (focus)="impD.select()" class="pav-input-align-right" type="number" min="0" matInput placeholder="Importe $." name="importe_dolares" required>
                  </mat-form-field>
              </div>
          </div>

      </mat-card>
      <br>
      <div class="mat-elevation-z0">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Programar cuotas
          </div>
          <div class="pav-table-header-add">
            <div class="isMobile">
              <div class="pav-button-icon-add" (click)="agregarItem()">
                <mat-icon svgIcon="add"></mat-icon>
              </div>
            </div>
            <div class="isWeb">
              <button type="button" mat-flat-button class="pav-button-add-item" (click)="agregarItem()">Agregar ítem</button>
            </div>
          </div>
        </div>
        <div class="overflow-x">
          <table class="overflow-x" mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" data-label="Acciones" class="td-responsive">
                <div class="pav-btns-container">
                  <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                  <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                    <mat-icon svgIcon="confirm"></mat-icon>
                  </div>
                  <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

              <ng-container matColumnDef="anio">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Año" class="td-responsive">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                        <mat-label></mat-label>
                        <input class="pav-input-align-right" required min="0" type="number" matInput placeholder="Año" [(ngModel)]="row.ano"
                        [name]="'ano' + i.toString()">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.ano}}
                    </ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="mes">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Mes" class="td-responsive">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                        <mat-label></mat-label>
                        <input class="pav-input-align-right" required min="0" type="number" matInput placeholder="Mes" [(ngModel)]="row.mes"
                        [name]="'mes' + i.toString()">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.mes}}
                    </ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="frecuencia">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Frecuencia </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Frecuencia" class="td-responsive">
                  <ng-container *ngIf="row.isEditing">
                    <mat-form-field class="pav-mat-form-margin-top">
                      <mat-label>Frecuencia</mat-label>
                      <mat-select [(ngModel)]="row.frecuencia" name="codnfr" required>
                        <mat-option *ngFor="let frecuencia of frecuencias; trackBy: trackByFrecuencia" [value]="frecuencia.nfr_codnfr">
                          {{frecuencia.nfr_descri}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!row.isEditing">
                    {{row.frecuencia}}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="imp_cuota">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. cuota </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. cuota" class="td-responsive">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item pav-mat-form-margin-top">
                        <mat-label></mat-label>
                        <input class="pav-input-align-right" required min="0" type="number" matInput placeholder="Imp. cuota" [(ngModel)]="row.impCuota"
                        [name]="'impCuota' + i.toString()">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.impCuota}}
                    </ng-container>
                  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="5">No se encontraron registros</td>

              </tr>

          </table>

        </div>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <br>
      <div align="end">
          <mat-form-field class="pav-total">
              <mat-label>Total Cuotas</mat-label>
              <input readonly type="number" [value]="detalle.length" matInput placeholder="Total Cuotas" name="total_cuotas" required>
          </mat-form-field>
      </div>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
