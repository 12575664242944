import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { SituacionActivo } from "src/app/models/activo-fijo/situacion-activo";
import { SituacionActivoService } from "src/app/services/api/activo-fijo/maestros/situacion-activo.service";

@Component({
  selector: 'situacion-activo-form',
  templateUrl: './situacion-activo-form.component.html',
  styleUrls: ['./situacion-activo-form.component.scss']
})
export class SituacionActivoFormComponent implements OnInit, OnDestroy {
  loaderReg: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  situacionActivo: SituacionActivo;

  uid: string;

  loading$: Subscription;
  sendForm$: Subscription;

  constructor(
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _situacionActivoService: SituacionActivoService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {
    this.situacionActivo = new SituacionActivo();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(this.uid == '0') return;
      this.obtenerSituacionActivo();
    })
  }

  obtenerSituacionActivo(): void {}

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarSituacionActivo(f): this.actualizarSituacionActivo(f);
  }

  registrarSituacionActivo(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._situacionActivoService.registrarSituacionActivo(this.situacionActivo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Situación activo registrado', '400px', '400px', '0');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarSituacionActivo(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._situacionActivoService.actualizarSituacionActivo(this.situacionActivo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Situación activo actualizado', '400px', '400px', '0');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$
    ])
  }
}
