import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_ALMACEN_DESPACHO } from "@data/json/reportes/almacen.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subject, Subscription } from "rxjs";
import { FiltroReporteAlmacen } from "src/app/models/almacen";
import { AlmacenService } from "src/app/services";
import { InventariosReportService } from "src/app/services/reports/modules/inventarios-report.service";
import { OtherDataFiltroAlmacen } from "../informes-unidades/informes-unidades.component";

@Component({
  selector: 'informes-de-despacho',
  templateUrl:'./informes-despacho.component.html',
  styleUrls: ['./informes-despacho.component.scss']
})
export class InformesDespachoComponent implements OnInit, OnDestroy {

  loaderFields: boolean = false;

  familias: any[] = [];
  subfamilias: any[] = [];
  almacenes: any[] = [];
  responsables: any[] = [];
  motivos: any[] = [];
  productos: any[] = [];
  inventarios: any[] = [];
  lineas: any[] = [];
  documentos: any[] = [];

  marcas: any[] = [];
  monedas: any[] = [];

  displayedColumns: string[] = ['acciones', 'mac_nummac', 'mac_fecmac', 'mac_glomac'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  tipoMovimiento = "ING";

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  range: FormGroup;

  familia = "0001";

  movimiento$: Subscription;
  columnas$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  dataForm$: Subscription;

  reportes: any[] = REPORTES_ALMACEN_DESPACHO

  reporteSeleccionado: any = ['ALMACEN_MM'];

  filtroAlmacen: FiltroReporteAlmacen;

  loading: boolean = true;

  year: string;
  month: string;

  protected _onDestroy = new Subject<void>();
  isMobile: boolean = false;

  constructor(
    private _snackBarService: SnackBarService,
    private _router: Router,
    private _almacenService: AlmacenService,
    private _almacenReporteService: InventariosReportService,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver

  ) {
    this.filtroAlmacen = new FiltroReporteAlmacen();

    this.dataSource = fillTable([], this.paginator, this.sort);

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        console.log('Is Mobile:', this.isMobile);
      });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    });
  }

  loadData(): void {
    this.loaderFields = true;
    this.loading = true;

    this.dataForm$ = this._almacenService.obtenerDataFiltroAlmacen().subscribe(
      dataForm => {
        this.familias = dataForm.familias;
        this.subfamilias = dataForm.subFamilias;
        this.almacenes = dataForm.almacenes;
        this.responsables = dataForm.responsables;
        this.motivos = dataForm.motivos;
        this.productos = dataForm.productos;
        this.inventarios = dataForm.tiposInventario;
        this.lineas = dataForm.lineas;
        this.documentos = [{tipo: 'INGRESO', code: 'ING'},{tipo: 'SALIDA', code: 'SAL'}];
        this.monedas = dataForm.monedas;
        this.marcas = dataForm.marcas;
        console.log('almacenes', this.almacenes)
        this.loaderFields = false;
        this.loading = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderFields = false;
        this.loading = false;
      }
    )
  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;
    // let indicador = "";
    switch (this.reporteSeleccionado[0]) {
      case "ALMACEN_M":
        break;
      case "ALMACEN_SA":
        break;
      case "ALMACEN_P":
        break;
      default:
        break;
    }

  }

  editarMovimiento(year, month, almacen, tipo, numeroParte) {

    if(this.tipoMovimiento == "ING") {
      this._router.navigate(['/modulo-almacen/editar-parte-de-ingreso', year, month, almacen, tipo, numeroParte])
      return;
    }

    this._router.navigate(['/modulo-almacen/editar-parte-de-salida', year, month, almacen, tipo, numeroParte])

  }

  verPdf(mac_nummac): void {}

  nuevoMovimiento(): void {
    if(this.tipoMovimiento == "ING") {
      this._router.navigate(['/parte-de-ingreso']);
      return;
    }

    this._router.navigate(['/parte-de-salida']);

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  verReporte(): void {

    // this.loading = true;

    let headers = {
      codano: this.year,
      codmes: this.month,
      currency: this.filtroAlmacen.moneda
    };

    let otherData: OtherDataFiltroAlmacen = {
      params: [],
      filtro: this.filtroAlmacen,
    };

    this._almacenReporteService.verReporte(this.reporteSeleccionado[0], headers, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.movimiento$,
      this.columnas$,
      this.loading$
    ])
  }

}
