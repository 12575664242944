import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_NOMINA_FIRMA_DIGITAL } from '@data/constants/table/theader.nomina';
import { ApiNominaMaestrosFirmaDigitalService } from 'src/app/services/api/nomina/maestros/nomina.maestros.firma-digital.service';

@Component({
  selector: 'app-firma-digital-list',
  templateUrl: './firma-digital-list.component.html',
  styleUrls: ['./firma-digital-list.component.css']
})
export class FirmaDigitalListComponent implements OnInit, OnDestroy {

  firma$: Subscription;
  loading$: Subscription;

  firmas: any[] = [];
  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_FIRMA_DIGITAL;

  constructor(
    private _apiFirmaNominaService: ApiNominaMaestrosFirmaDigitalService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.obtenerFirmasDigitales();
    })
  }

  obtenerFirmasDigitales(): void {
    this.firma$ = this._apiFirmaNominaService.listarFirmasDigitales().subscribe(
      response => this.firmas = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.firma$, this.loading$]);
  }

}
