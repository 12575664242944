import { AuxiliarFormComponent, AuxiliarListComponent } from "./auxiliar";
import { AuxiliarModule } from "./auxiliar/auxiliar.module";
import { CategoriaCentroCostoFormComponent, CategoriaCentroCostoListComponent } from "./categoria-centro-costo";
import { CentroCostoFormComponent, CentroCostoListComponent } from "./centro-costo";
import { CentroCostoModule } from "./centro-costo/centro-costo.module";
import { ConfigTipodocCreateComponent } from "./config-por-tipo/config-tipodoc-form/config-tipodoc-create.component";
import { ConfigTipodocListComponent } from "./config-por-tipo/config-tipodoc-list/config-tipodoc-list.component";
import { ConfiguracionAsientoCierreCreateComponent } from "./configuracion-asiento-cierre/configuracion-asiento-cierre-form/configuracion-asiento-cierre-create.component";
import { ConfiguracionAsientoCierreListComponent } from "./configuracion-asiento-cierre/configuracion-asiento-cierre-list/configuracion-asiento-cierre-list.component";
import { ConfigControlCreateComponent } from "./configuracion-con-control/config-control-form/config-control-create.component";
import { ConfigControlListComponent } from "./configuracion-con-control/config-control-list/config-control-list.component";
import { contableComponents } from "./contable";
import { ConfiguracionEefComponent } from "./eeff/configuracion-eef/configuracion-eef.component";
import { EeffListComponent } from "./eeff/eeff-list/eeff-list.component";
import { PlanCuentaFormComponent, PlanCuentaListComponent } from "./plan-cuenta";
import { ModificacionesDialog, ValidacionCtaDiferenciaCambio } from "./plan-cuenta/dialogs";
import { SubCuentaFormComponent, SubCuentaListComponent } from "./sub-cuenta";
import { TipoCambioImportacionFormComponent, TipoCambioImportacionListComponent } from "./tipo-cambio-importacion";
import { TipoDocumentoContaCreateComponent } from "./tipo-documento/tipo-documento-conta-create/tipo-documento-conta-create.component";
import { TipoDocumentoContaListComponent } from "./tipo-documento/tipo-documento-conta-list/tipo-documento-conta-list.component";
import { TipoImpuestoFormComponent, TipoImpuestoListComponent } from "./tipo-impuesto";
import { TipoOperacionFormComponent, TipoOperacionListComponent } from "./tipo-operacion";

export const maestrosComponentContabilidad = [
  CategoriaCentroCostoFormComponent,
  CategoriaCentroCostoListComponent,
  ConfigTipodocCreateComponent,
  ConfigTipodocListComponent,
  ConfiguracionAsientoCierreCreateComponent,
  ConfiguracionAsientoCierreListComponent,
  ConfigControlCreateComponent,
  ConfigControlListComponent,
  ConfiguracionEefComponent,
  EeffListComponent,
  PlanCuentaFormComponent,
  PlanCuentaListComponent,
  SubCuentaListComponent,
  SubCuentaFormComponent,
  TipoCambioImportacionFormComponent,
  TipoCambioImportacionListComponent,
  TipoDocumentoContaCreateComponent,
  TipoDocumentoContaListComponent,
  TipoImpuestoFormComponent,
  TipoImpuestoListComponent,
  TipoOperacionFormComponent,
  TipoOperacionListComponent,
  ModificacionesDialog,
  ValidacionCtaDiferenciaCambio,
  ...contableComponents
]

export const maestrosModulesContabilidad = [
  AuxiliarModule,
  CentroCostoModule
]
