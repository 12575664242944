import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { SnackBarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-producto-list',
  templateUrl: './producto-list.component.html',
  styleUrls: ['./producto-list.component.css']
})
export class ProductoListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'prd_codprd', 'DESC_ESPA', 'UNIDAD', 'COD_INVENTARIO', 'DESC_INVENTARIO', 'ESTADO'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  products$: Subscription;
  loading$: Subscription;
  loaderData: boolean;

  productos$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _productoService: ProductoService,
    private _snackBarService: SnackBarService
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  columnaActiva: string;
  direccionColumna: string;

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        // this.products$ = this.store.select('products').subscribe(({products})=> {
        //   console.log('productos', products)
        //   this.dataSource = fillTable(products, this.paginator, this.sort);
        // })

        this.obtenerProductos();
      }
    })

    this.sort.sortChange.subscribe((event) => {

      this.columnaActiva = event.active
      this.direccionColumna = event.direction
      this.obtenerProductos();

    });

  }

  obtenerProductos(): void {
    this.productos$ = this._productoService.obtenerProductosConPaginacion(this.currentPage, this.pageSize, this.direccionColumna, this.columnaActiva, this.buscador).subscribe(
      response => {
        console.log('productos con paginacion', response)

        this.pageSize = response.registrosPagina;
        this.totalRegistros = response.totalRegistros;
        this.dataSource.data = response.registros;
        this.loaderData = false;
      },
      error => {
        console.log('error', error)
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
    // this.store.dispatch(cargarProductos());
  }

  pageSize: any;
  currentPage: any = 1;
  totalRegistros: number;

  buscador: string;

  buscarEnProductos(event) {
    console.log('event', event)
    this.buscador = event.target.value;
    this.obtenerProductos();
  }

  descargarXML(): void {
    console.log('descargar xml')
  }

  onPageChange(event): void {
    console.log('event page',event)
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.obtenerProductos();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.products$
    ])
  }

}
