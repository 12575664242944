import { NgModule } from "@angular/core";
import { FirmaDigitalDialog } from "./firma-digital-dialog/firma-digital-dialog.component";
import { FirmaDigitalFormComponent } from "./firma-digital-form/firma-digital-form.component";
import { FirmaDigitalListComponent } from "./firma-digital-list/firma-digital-list.component";
import { FirmaDigitalSelect } from "./firma-digital-select/firma-digital-select.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from "@shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { NgxDropzoneModule } from "ngx-dropzone";

@NgModule({
  declarations: [FirmaDigitalFormComponent, FirmaDigitalListComponent, FirmaDigitalSelect, FirmaDigitalDialog],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    NgxDropzoneModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    FirmaDigitalFormComponent, FirmaDigitalListComponent, FirmaDigitalSelect, FirmaDigitalDialog
  ]
})
export class FirmaDigitalModule {}
