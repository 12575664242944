export interface ContactI {

  ccl_codccl: string;
  ccl_corele:string;
  ccl_nomccl: string;
}

export class Contact {
  cia_codcia: string | number;
  cli_codcli: string;
  ccl_codccl: string | number;
  cca_codcca: string;
  cca_descri: string;
  cca_desabr: string;
  ccl_numtlf: string;
  ccl_corele: string;
  ccl_cumple: string;
  ccl_glosa: string;
  ccl_nomccl: string;
  ccl_indcob: number;
  isEditing: boolean;
  constructor(
  ){
    //this.isEditing = true;
  }
}
