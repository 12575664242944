import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberFormat]'
})
export class NumberFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = parseFloat(input.value.replace(/[^\d.-]/g, '')); // Extraer solo dígitos y puntos

    if (!isNaN(value)) {
      const formattedValue = this.formatNumber(value);
      input.value = formattedValue;
    }
  }

  private formatNumber(value: number): string {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
}
