import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IParam, generarParams } from '../../utilities/params';

@Injectable({
  providedIn: 'root'
})
export class LoteService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  obtenerLotesProducto({ params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get(`${this._configurationService.obtenerUrl()}/api/producto/lote/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`)

  }

}
