import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithDash } from "@utils/formats/date.format";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { CotiComeEspeItemCci, CotiComePcd, CotiComeProdDescPds, OrdenPedido } from "src/app/models/crm/orden-pedido";
import { ComprasService, ConfigurationService, VentasService } from "src/app/services";
import { CondicionPagoService } from "src/app/services/api/compras/maestros/condicion-pago.service";
import { CanalService } from "src/app/services/api/crm/maestros";
import { ApiCRMOperacionesOrdenPedidoService } from "src/app/services/api/crm/operaciones/crm.operaciones.orden-pedido.service";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";

@Component({
  selector: 'orden-pedido-form-crm',
  templateUrl: './orden-pedido-form.component.html',
  styleUrls: ['./orden-pedido-form.component.scss']
})
export class OrdenPedidoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean;
  loaderData: boolean;

  orden: OrdenPedido;

  displayedColumns: string[] = ['acciones', 'campo1', 'campo2', 'campo3', 'campo4', 'campo5', 'campo6', 'campo7','campo8','campo9','campo10','campo11','campo12','campo13','campo14','campo15'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsDescuento: string[] = ['acciones', 'descuento', 'porcentaje'];
  dataSourceDescuento: MatTableDataSource<any>;
  @ViewChild('paginatorDescuento') paginatorDescuento: MatPaginator;
  @ViewChild(MatSort) sortDescuento: MatSort;

  displayedColumnsEspecificacion: string[] = ['acciones', 'item', 'descripcion'];
  dataSourceEspecificacion: MatTableDataSource<any>;
  @ViewChild('paginatorEspecificacion') paginatorEspecificacion: MatPaginator;
  @ViewChild(MatSort) sortEspecificacion: MatSort;

  canales: any[] = [];
  clientes: any[] = [];
  formas: any[] = [];
  tiposPrecio: any[] = [];
  descuentos: any[] = [];

  buttonsName: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  vendedores$: Subscription;
  condiciones$: Subscription;
  canales$: Subscription;
  clientes$: Subscription;
  formas$: Subscription;
  tiposPrecio$: Subscription;
  productos$: Subscription;
  descuentos$: Subscription;
  sendForm$: Subscription;
  ordenPedido$: Subscription;

  vendedores: any[] = [];
  condiciones: any[] = [];
  productos: any[] = [];

  periodo$: Subscription;

  uid: string;
  btnName: string;
  nroOrden: any;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService,
    private _productoService: ProductoService,
    private _comprasService: ComprasService,
    private _condicionPagoService: CondicionPagoService,
    private _configurationService: ConfigurationService,
    private _apiOrdenPedidoCrmService: ApiCRMOperacionesOrdenPedidoService,
    private _canalService: CanalService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
  ) {

    this.orden = new OrdenPedido();

    this.orden.coduse = this._configurationService.obtenerIdUsuario();

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.orden.codano = state.year;
      this.orden.codmes = state.month;
    });

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);
    this.dataSourceEspecificacion = fillTable([], this.paginatorEspecificacion, this.sortEspecificacion);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {

      this.uid = id;

      this.loadMaestros();

      if(this.uid == '0') {

        const orderFromQuote = localStorage.getItem('order-from-quote');

        if(orderFromQuote) {

          this.orden = JSON.parse(orderFromQuote);
          localStorage.removeItem('order-from-quote');

          this.dataSource = fillTable(this.orden.COTI_COME_PCD, this.paginator, this.sort);

        }

        this.btnName = 'general.button.store';

        return;
      }

      this.btnName = 'general.button.update';


    })
  }

  seleccionarFila(row): void {

  }

  obtenerOrden(): void {
    this.ordenPedido$ = this._apiOrdenPedidoCrmService.obtenerOrdenPedido(this.uid).subscribe(
      orden => {
        this.orden = orden[0];
        this.dataSource = fillTable(this.orden.COTI_COME_PCD, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  seleccionarCliente(clienteForm): void {
    const cliente = this.clientes.find(item => item.cli_codcli == clienteForm.value);
    this.orden.codcli = cliente.cli_codcli;
    this.orden.dircli = cliente.cli_dircli;
  }

  loadMaestros(): void {
    forkObs(
      this._ventasService.obtenerVendedores(),
      this._condicionPagoService.obtenerCondicionesPago(),
      this._canalService.obtenerCanales(),
      this._ventasService.obtenerClientes(),
      this._ventasService.obtenerFormaCalculaItem(),
      this._ventasService.obtenerTiposPrecio(),
      this._productoService.obtenerProductos(),
      this._ventasService.obtenerDescuentos()
    ).then(data => {
      this.vendedores = data[0];
      this.condiciones = data[1];
      this.canales = data[2];
      this.clientes = data[3];
      this.formas = data[4];
      this.tiposPrecio = data[5];
      this.productos = data[6];
      this.descuentos = data[7];

      if(this.uid != '0') this.obtenerOrden()

    })
    .catch(error => this._snackBarService.showError('Error al obtener maestros', 'Ok'));
  }

  trackByVendedor(index, vendedor): string {
    return vendedor? vendedor.VDE_CODVDE: undefined;
  }

  trackByCondicion(index, condicion): string {
    return condicion? condicion.CPA_CODCPA: undefined;
  }

  trackByForma(index, forma): string {
    return forma? forma.fci_codfci: undefined;
  }

  trackByTipoPrecio(index, tipo): string {
    return tipo? tipo.TLP_CODTLP: undefined;
  }

  trackByProducto(index, producto): string {
    return producto? producto.prd_codprd: undefined;
  }

  trackByDescuento(index, descuento): string {
    return descuento? descuento.dco_coddco: undefined;
  }

  cambiarTasaIGV(): void {
    this.orden.COTI_COME_PCD.forEach(item => {
      item.pruigv = item.preuni  * (1 + this.orden.tasigv / 100);
      item.impigv = item.pruigv * item.cansol;
    });

    this.calcularTotales();
  }

  cambiarCantidad(row: CotiComePcd): void {
    row.impbru = row.cansol * row.preuni;
    row.valvta =  row.impbru - row.impdes;
    row.imptot = row.cansol * row.pruigv - row.impdes;
    // row.IMPIGV = row.IMPTOT - row.VALVTA;
    row.impigv = row.pruigv * row.cansol;

    this.calcularTotales();
  }

  cambiarPrecio(row): void {
    row.pruigv = row.preuni  * (1 + this.orden.tasigv / 100);
    row.impbru = row.cansol * row.preuni;
    row.valvta =  row.impbru - row.impdes;
    row.imptot = row.cansol * row.pruigv - row.impdes;
    // row.IMPIGV = row.IMPTOT - row.VALVTA;
    row.impigv = row.pruigv * row.cansol;

    this.calcularTotales();
  }

  agregarItem(): void {

    let producto = new CotiComePcd();
    producto.secpcd = '01';

    this.orden.COTI_COME_PCD.push(producto);

    this.orden.COTI_COME_PCD.forEach((element, key) => {
      element['corpcd'] = key + 1 > 9 ? `0${(key+1)}`:  `00${key + 1}`;
    });

    this.dataSource = fillTable(this.orden.COTI_COME_PCD, this.paginator, this.sort);

  }

  editarItem(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarItem(row: CotiComePcd): void {
    if(!row.codprd) {
      this._snackBarService.showError('Establecer un producto', 'Ok')
      return;
    }

    if(!row.codven) {
      this._snackBarService.showError('Establecer unidad de medida', 'Ok')
      return;
    }

    if(!row.cansol) {
      this._snackBarService.showError('Establecer cantidad', 'Ok')
      return;
    }

    if(!row.preuni) {
      this._snackBarService.showError('Establecer precio unitario', 'Ok')
      return;
    }

    if(!row.pordes) {
      this._snackBarService.showError('Establecer porcentaje de descuento', 'Ok')
      return;
    }

    if(!row.glopcd) {
      this._snackBarService.showError('Establecer glosa', 'Ok')
      return;
    }
    row.isEditing = !row.isEditing;
  }

  quitarItem(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSource.data.filter(item => item.corpcd != row.corpcd);
        filtered.forEach((element, key) => {
          element['corpcd'] = key + 1 > 9 ? `0${(key+1)}`:  `00${key + 1}`;
        })
        this.orden.COTI_COME_PCD = filtered;
        this.dataSource = fillTable(filtered, this.paginator, this.sort);
      }
    })
  }

  seleccionarCB(value, index, row): void {
    this.dataSourceDescuento = fillTable(row.COTI_COME_PROD_DESC_PDS, this.paginatorDescuento, this.sortDescuento);
    this.dataSourceEspecificacion = fillTable(row.COTI_COME_ESPE_ITEM_CCI, this.paginatorEspecificacion, this.sortEspecificacion);

    if(value.checked) {
      this.orden.COTI_COME_PCD.forEach((item, key) => {

        if(key != index) {
          item['isSelect'] = false;
        }
      })
    }
  }

  cambiarFecha(): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.orden.fecdoc)).subscribe(
      tipoCambio => {
        this.orden.tipcam = tipoCambio[0].tipcam;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  agregarDescuento(): void {
    let isSelected = false;
    let itemSelected: CotiComePcd;
    this.orden.COTI_COME_PCD.forEach(item => {
      if(item.isSelect) {
        isSelected = true;
        itemSelected = item;
      };
    });

    if(!isSelected) {
      this._snackBarService.showError('Se debe de seleccionar un producto', 'Ok');
      return;
    }

    itemSelected.COTI_COME_PROD_DESC_PDS.push(new CotiComeProdDescPds());
    itemSelected.COTI_COME_PROD_DESC_PDS.forEach((element, key) => {
      element['corpds'] = key + 1 > 9 ? `0${(key+1)}`:  `00${key + 1}`;
    })

    this.dataSourceDescuento = fillTable(itemSelected.COTI_COME_PROD_DESC_PDS, this.paginatorDescuento, this.sortDescuento);
  }

  editarDescuento(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarDescuento(row): void {
    if(!row.coddco) {
      this._snackBarService.showError('Establecer descuento', 'Ok')
      return;
    }

    if(!row.pordes) {
      this._snackBarService.showError('Establecer porcentaje de descuento', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarDescuento(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceDescuento.data.filter(item => item.corpds != row.corpds);
        filtered.forEach((element, key) => {
          element['corpds'] = key + 1 > 9 ? `0${(key+1)}`:  `00${key + 1}`;
        })

        const producto = this.orden.COTI_COME_PCD.find(item => item.isSelect);

        producto.COTI_COME_PROD_DESC_PDS = filtered;

        this.dataSourceDescuento = fillTable(filtered, this.paginatorDescuento, this.sortDescuento);
      }
    })
  }

  agregarEspecificacion(): void {
    let isSelected = false;
    let itemSelected: CotiComePcd;
    this.orden.COTI_COME_PCD.forEach(item => {
      if(item.isSelect) {
        isSelected = true;
        itemSelected = item;
      };
    });

    if(!isSelected) {
      this._snackBarService.showError('Se debe de seleccionar un producto', 'Ok');
      return;
    }

    itemSelected.COTI_COME_ESPE_ITEM_CCI.push(new CotiComeEspeItemCci());
    itemSelected.COTI_COME_ESPE_ITEM_CCI.forEach((element, key) => {
      element['seccci'] = key + 1 > 9 ? (key+1).toString():  `0${key + 1}`;
    })

    this.dataSourceEspecificacion = fillTable(itemSelected.COTI_COME_ESPE_ITEM_CCI, this.paginatorEspecificacion, this.sortEspecificacion);

  }

  editarEspecificacion(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarEspecificacion(row): void {
    if(!row.descri) {
      this._snackBarService.showError('Establecer descripción', 'Ok')
      return;
    }
    row.isEditing = !row.isEditing;

  }

  quitarEspecificacion(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceEspecificacion.data.filter(item => item.seccci != row.seccci);
        filtered.forEach((element, key) => {
          element['seccci'] = key + 1 > 9 ? (key+1).toString():  `0${key + 1}`;
        })
        const producto = this.orden.COTI_COME_PCD.find(item => item.isSelect);

        producto.COTI_COME_ESPE_ITEM_CCI = filtered;

        this.dataSourceEspecificacion = fillTable(filtered, this.paginatorEspecificacion, this.sortEspecificacion);
      }
    })
  }

  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;

    this.orden.COTI_COME_PCD.forEach(element => {
      valorVenta += Number(element.valvta);
      impBruto += Number(element.impbru);
      impIGV += Number(element.impigv);
      descuento += Number(element.impdes);
      impTotal += Number(element.imptot);
    })

    this.orden.valvta = valorVenta;

    this.orden.impbru = impBruto;

    this.orden.impigv = impIGV;

    this.orden.impdes = descuento;

    this.orden.imptot = impTotal;
  }

  copiarOrden(): void {
    if(this.nroOrden.trim()) {
      this._apiOrdenPedidoCrmService.obtenerOrdenPedido(this.nroOrden.trim()).subscribe(
        orden => {
          this.orden = orden[0];
          this.dataSource = fillTable(this.orden.COTI_COME_PCD, this.paginator, this.sort);
        },
        error => this._snackBarService.showError(error.error.msg, 'Ok')
      )
    }
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.orden.indter ? '1': '0';

    this.orden.COTI_COME_PCD.forEach(item => {
      item.coduse = this._configurationService.obtenerIdUsuario();

      item.COTI_COME_PROD_DESC_PDS.forEach(el => {
        el.porori = 0;
        el.impdes = 10;
        el.impcom = 10;
        el.incdes = 10,
        el.coduse = this._configurationService.obtenerIdUsuario();
      })
    })

    this.uid == '0' ? this.registrarOrden(f): this.actualizarOrden(f);

  }

  generarOrdenPedido(): void {}

  /**
   * Método para registrar una nueva orden
   * @param f
   */
  registrarOrden(f: NgForm): void {
    this.sendForm$ = this._apiOrdenPedidoCrmService.registrarOrdenPedido(this.orden).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.operaciones.orden-pedido.success-store', '400px', 'auto', '');
        f.resetForm();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar orden
   * @param f
   */
  actualizarOrden(f: NgForm): void {
    this.sendForm$ = this._apiOrdenPedidoCrmService.actualizarOrdenPedido(this.orden).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.operaciones.orden-pedido.success-update', '400px', 'auto', '');
        f.resetForm();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-crm/orden-pedido']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.vendedores$,
      this.condiciones$,
      this.canales$,
      this.clientes$,
      this.formas$,
      this.tiposPrecio$,
      this.productos$,
      this.sendForm$,
      this.descuentos$,
      this.ordenPedido$
    ])

  }
}
