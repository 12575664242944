import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';

@Injectable({
  providedIn: 'root',
})
export class CrmService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @param idtkt
   * @returns
   */
  obtenerEspecificacionesPorOportunidad(idtkt): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/oportunidad-especificaciones/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param codcli
   * @returns
   */
  obtenerContactosPorCliente(codcli): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/contactos/cliente/${this._configurationService.obtenerCompaniaCliente()}/${codcli}`
    )
  }

  /**
   *
   * @param payload
   * @returns
   */
  obtenerRegistrosOportunidadConFiltro(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/registros-oportunidad-filtro/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   *
   * @returns
   */
  obtenerConsultaSeguimientoOportunidad(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/consulta-seguimiento-oportunidad/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param payload
   * @returns
   */
  obtenerReporteRegistrosOportunidad(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/reporte-registros-oportunidad/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  /**
   *
   * @returns
   */
  obtenerReporteSeguimientoOportunidad(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/reporte-seguimiento-oportunidad-2/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerReporteVentasPerdidas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/reporte-ventas-perdidas/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerMotivosVentaSuspendida(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/motivos-venta-suspendida/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerGruposPresupuesto(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/grupos-presupuesto/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerPDFPresupuesto(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/presupuesto/pdf/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param numpcc
   * @returns
   */
  obtenerPDFCotizacion(numpcc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/cotizacion/pdf/${this._configurationService.obtenerCompaniaCliente()}/${numpcc}`
    );
  }

  /**
   *
   * @param numpcc
   * @returns
   */
  obtenerPDFOrdenPedido(numpcc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/orden-pedido/pdf/${this._configurationService.obtenerCompaniaCliente()}/${numpcc}`
    );
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerCuadroEstadoOportunidades(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/cuadro/estado-oportunidad/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerEstadisticoCantidadOportunidadesMes(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/cantidad-oportunidad-mes/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerEstadisticoOportunidadesCerradasAgente(anio): Observable<any> {
    return this.http.get<any> (
      `${this._configurationService.obtenerUrl()}/api/crm/chart/oportunidad-cerrada/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerEstadisticoOportunidadesEnCursoAgente(anio): Observable<any> {
    return this.http.get<any> (
      `${this._configurationService.obtenerUrl()}/api/crm/chart/oportunidad-en-curso/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerEstadisticoOportunidadesLineaNegocio(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/oportunidad-por-linea-negocio/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerEstadisticoOportunidadesTipoNegociacion(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/oportunidad-por-tipo-negociacion/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerEstadisticoOportunidadesResponsable(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/oportunidades-por-responsable/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerEstadisticoOportunidadesEtapa(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/oportunidades-por-etapa/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerDataFormReporteCRM(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/forms/reporte/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerDataFormPanelCRM(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/forms/panel/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    )
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerGrafico01(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/oportunidades-por-pais/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerGrafico02(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/oportunidades-por-cliente/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerGrafico05(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/cotizaciones-por-pais/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  obtenerGrafico06(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/chart/cotizaciones-por-marca/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}`
    )
  }
}
