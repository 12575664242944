import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class AlmacenService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @param data
   * @returns
   */
  registrarAlmacen2(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/creareditaralmacen`,
      data
    );
  }

  /**
   *
   * @param data
   * @returns
   */
  editarAlmacen(data): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/creareditaralmacen`,
      data
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  mostrarAlmacen(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/mostraralmacen/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerAlmacenes(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/almacenes/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }
}
