export function sonCorreosValidos(mails: Array<string>): boolean {
  // Expresión regular para validar el formato del correo electrónico
  const expresionRegularCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verificar si la cadena cumple con la expresión regular
  let validacion = true;

  mails.forEach(correo => {
    console.log('validacion', expresionRegularCorreo.test(correo))
    if(!expresionRegularCorreo.test(correo)) {
      validacion = false;
    }

  })

  return validacion;
}

export function sonCelularesValidos(phones: Array<string>): boolean {

  return true;
}
