<app-pavso-title-section [title]="'Promoción de venta'" [module]="'Ventas'"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Num. Prom</mat-label>
          <input type="text" matInput placeholder="Código" [(ngModel)]="promocionVenta.numProm" name="numProm">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">

        <mat-form-field>
          <mat-label>Entra en Vigencia</mat-label>
          <input matInput [matDatepicker]="entraVigencia" [(ngModel)]="promocionVenta.entraVigencia" name="entraVigencia">
          <mat-datepicker-toggle matSuffix [for]="entraVigencia"></mat-datepicker-toggle>
          <mat-datepicker #entraVigencia></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Fecha de Término</mat-label>
          <input matInput [matDatepicker]="fechaTermino" [(ngModel)]="promocionVenta.fechaTermino" name="fechaTermino">
          <mat-datepicker-toggle matSuffix [for]="fechaTermino"></mat-datepicker-toggle>
          <mat-datepicker #fechaTermino></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select [(ngModel)]="promocionVenta.estado" name="estado">
            <mat-option value="1">
              ACTIVO
            </mat-option>
            <mat-option value="2">
              INACTIVO
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12">
        <mat-form-field class="example-full-width">
          <mat-label>Descripción</mat-label>
          <input type="text" matInput placeholder="Ej. descripcion" [(ngModel)]="promocionVenta.descripcion" name="descripcion">
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">

    </div>
    <div class="pav-table-header-title">
      Productos Promoción
    </div>
    <div class="pav-table-header-add">
      <div class="isMobile">
        <div class="pav-button-icon-add" (click)="agregarProducto()">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregarProducto()">Agregar ítem</button>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSourceProductos" matSort>

        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" data-label="Acciones">
              <div class="pav-btns-container">
                <div [ngStyle]="{'pointerEvents':row.codigo == null || row.codigo == undefined ? 'none' : '' }" class="pav-btn-circle-delete" (click)="quitarProducto(row)" matTooltip="Eliminar">
                  <mat-icon svgIcon="delete"></mat-icon>
                </div>
              </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
            <ng-container>
              {{row.codigo}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
            <ng-container>

              <select-search
                [label]="'Producto'"
                [placeholder]="'Ej. CANASTA'"
                [name]="'prd_codprd' + i"
                [value]="'prd_codprd'"
                [description]="'DESC_ESPA'"
                [data]="productos"
                (cambioSelect)="seleccionarProducto($event, row)"
                [model]="row.codigo">
              </select-search>

            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="um">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
            <ng-container>
              <mat-form-field class="example-full-width">
                <mat-label>U.M.</mat-label>
                <input type="text" [(ngModel)]="row.um" matInput placeholder="Ex. UND" [name]="'um' + i">
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="cantidad">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
            <ng-container>
              <ng-container>
                <mat-form-field class="example-full-width">
                  <mat-label>Cantidad</mat-label>
                  <input type="number" [(ngModel)]="row.cantidad" matInput placeholder="Ex. 2" [name]="'cantidad' + i">
                </mat-form-field>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="descuento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> % Descuento </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="% Descuento">
            <ng-container>
              <mat-form-field class="example-full-width">
                <mat-label>% Descuento</mat-label>
                <input type="number" [(ngModel)]="row.descuento" matInput placeholder="Ex. 10" [name]="'descuento' + i">
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsProductos"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsProductos;"></tr>

        <tr class="mat-row" *matNoDataRow>

            <td class="mat-cell" colspan="6">No se encontraron registros</td>

        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>

  </div>
  <br>
  <div class="pav-table-header-container">
    <div class="pav-table-header-icon">

    </div>
    <div class="pav-table-header-title">
      Productos Auspiciadores
    </div>
    <div class="pav-table-header-add">
      <div class="isMobile">
        <div class="pav-button-icon-add" (click)="agregarProducto()">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <button  mat-flat-button class="pav-button-add-item" type="button" (click)="agregarProductoAuspiciador()">Agregar ítem</button>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSourceAuspiciadores" matSort>

        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" data-label="Acciones">
              <div class="pav-btns-container">
                <div [ngStyle]="{'pointerEvents':row.codigo == null || row.codigo == undefined ? 'none' : '' }" class="pav-btn-circle-delete" (click)="quitarProductoAuspiciador(row)" matTooltip="Eliminar">
                  <mat-icon svgIcon="delete"></mat-icon>
                </div>
              </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
            <ng-container>
              {{row.codigo}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
            <ng-container>

              <select-search
                [label]="'Producto'"
                [placeholder]="'Ej. CANASTA'"
                [name]="'prd_codprd'"
                [value]="'prd_codprd'"
                [description]="'DESC_ESPA'"
                [data]="productos"
                (cambioSelect)="seleccionarProducto($event, row)"
                [model]="row.codigo">
              </select-search>

            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="um">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
            <ng-container>
              <mat-form-field class="example-full-width">
                <mat-label>U.M.</mat-label>
                <input type="text" [(ngModel)]="row.um" matInput placeholder="Ex. UND" [name]="'um' + i">
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="cantidad">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
            <ng-container>
              <ng-container>
                <mat-form-field class="example-full-width">
                  <mat-label>Cantidad</mat-label>
                  <input type="number" [(ngModel)]="row.cantidad" matInput placeholder="Ex. 2" [name]="'cantidad' + i">
                </mat-form-field>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="descuento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> % Descuento </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="% Descuento">
            <ng-container>
              <mat-form-field class="example-full-width">
                <mat-label>% Descuento</mat-label>
                <input type="number" [(ngModel)]="row.descuento" matInput placeholder="Ex. 10" [name]="'descuento' + i">
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsAuspiciadores"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsAuspiciadores;"></tr>

        <tr class="mat-row" *matNoDataRow>

            <td class="mat-cell" colspan="6">No se encontraron registros</td>

        </tr>
      </table>

      <mat-paginator #paginatorAuspiciador [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>

  </div>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-ventas/promociones-de-venta">
    <div id="botonesAdicionales">

    </div>
  </pav-form-actions>
</form>
