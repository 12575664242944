import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComprasService, ConfigurationService } from 'src/app/services';
import { verPdf2 } from '@utils/pdfmaker/builder/compras.pdfmaker';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { OrdenCompraService } from 'src/app/services/api/compras/operaciones/orden-compra.service';
import { generarFormatoOrdenCompra } from '@utils/pdfmaker/builder/compras/operaciones/orden-compra.pdfmaker';
import { IParam } from 'src/app/services/api/utilities/params';

@Component({
  selector: 'app-orden-compra-list',
  templateUrl: './orden-compra-list.component.html',
  styleUrls: ['./orden-compra-list.component.css']
})
export class OrdenCompraListComponent implements OnInit, OnDestroy {

  loader: boolean;

  displayedColumns: string[] = ['acciones', 'ocm_numocm', 'ocm_fecocm', 'cli_nomcli', 'tmo_codtmo', 'ocm_imptot', 'ocm_aprcom'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['ver_req', 'num_req', 'fec_emision', 'fec_pago', 'moneda', 'importe'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild("matPaginator1") paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  loader1: boolean = false;

  displayedColumns2: string[] = ['ver_doc', 'tipo_doc', 'num_doc', 'fec_emision', 'moneda', 'importe', 'abono', 'saldo', 'codigo'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild("matPaginator2") paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  loader2: boolean = false;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  ordenes$: Subscription;
  loading$: Subscription;
  config$: Subscription;
  period$: Subscription;

  logo: string;

  anioPeriodo: string;
  mesPeriodo: string;

  constructor(
    private _snackBarService: SnackBarService,
    private _ordenCompraService: OrdenCompraService,
    private _comprasService: ComprasService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);
  }

  ngOnInit(): void {
    this.period$ = this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerOrdenesDeCompra();
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
    })


  }

  obtenerOrdenesDeCompra(): void {
    this.loader = true;

    const params: Array<IParam> = [
      {
        param: 'anio',
        value: this.anioPeriodo,
      },
      {
        param: 'mes',
        value: this.mesPeriodo,
      }
    ]

    this.ordenes$ = this._ordenCompraService.obtenerOrdenesCompra({params}).subscribe(
      response => {
        response.forEach(item => {
          item.cli_nomcli = item.cliente.cli_nomcli;
        })

        const filtrados = response.filter(item => item.ocm_indgen != 1)
        this.dataSource = fillTable(filtrados, this.paginator, this.sort);

        this.loader = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loader = false;
      }
    )
  }

  mostrarPdf(id): void {
    this._comprasService.obtenerPdfOrdenCompra(id).subscribe(
      response => {
        const headers = {
          username: this._configurationService.obtenerIdUsuario(),
          logo: this.logo,
          moneda: response[0].tmo_codtmo
        }

        response[0].detalle_produc = response[0].detalle_produc ? response[0].detalle_produc : []

        if(response.length > 0 && response != null) generarFormatoOrdenCompra(headers, response[0])
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.ordenes$,
      this.loading$,
      this.config$
    ])
  }
}
