export class Proceso {
  cia_codcia: string;
  prp_codprp: string;
  prp_desprp: string;
  prp_indsta: string;
  prp_fecupd: Date;
  prp_coduse: string;
  constructor() {
    this.prp_codprp = '';
    this.prp_indsta = '1';
    this.prp_fecupd = new Date();
  }
}
