import { Injectable, OnDestroy } from "@angular/core";
import { HeaderReport } from "@data/interfaces/headers.report";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import * as pdfBuilder from "@utils/pdfmaker/builder/contabilidad.pdfmaker";
import { Subscription } from "rxjs";
import { ContabilidadService } from "../../api/contabilidad.service";
import { ordenerArregloJSONxLlave } from "@utils/array/order";

@Injectable({
  providedIn: 'root'
})
export class ContabilidadReportService implements OnDestroy {

  reporte$: Subscription;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService
  ) {}

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporte(reportSelected: string, headers: HeaderReport, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "CONTABILIDAD_LDR":
          this.reporte$ = this._contabilidadService.obtenerInformeLibroDiarioResumen(headers.year, headers.month, otherData.cuenta).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.librodiarioresumentReport(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener libro diario por resumen", "OK")
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_LCB":

          this.reporte$ = this._contabilidadService.obtenerInformeLibroCajayBanco(headers.year, headers.month, otherData.cuenta).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.librocajaybancoReport(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener libro de caja y banco", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_AG633":

          pdfBuilder.analisisgastoReport(headers, {});
          resolve(true);
          break;
        case "CONTABILIDAD_MA":

          this.reporte$ = this._contabilidadService.obtenerReporteMayorContable(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.mayorAnalitico(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte mayor contable", "OK");
              reject(false);
            }
          )
        break;
        case "CONTABILIDAD_RH":

          this.reporte$ = this._contabilidadService.obtenerRegistroHonorarios(headers.year, headers.month, "1501").subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.registroDeHonorarios(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de registro de honorarios", "OK");
              reject(false);
            }
          )
        break;
        case "CONTABILIDAD_PC":

          this.reporte$ = this._contabilidadService.obtenerCuentasContable().subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.planDeCuentas(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener Plan Contable', 'OK');
              reject(false);
            }
          )
          break;

        case "CONTABILIDAD_SAR":

          this.reporte$ = this._contabilidadService.obtenerReporteSaldoAnaliticoResumen(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.saldosanaliticosresumentReport(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener Saldo Analítico Resumen', "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_ACC":

          this.reporte$ = this._contabilidadService.obtenerReporteAnaliticoCuentaContable(headers.year, headers.month, '121201').subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.analiticoPorCuentaContable(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte analítico cuenta contable", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_RAC":

          this.reporte$ = this._contabilidadService.obtenerReporteResumenAuxiliarVsCuenta(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.resumenAuxiliarCuenta(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte resumen auxiliar vs cuenta", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_RCD":

          this.reporte$ = this._contabilidadService.obtenerReporteResumenCuentaVsDocumento(headers.year, headers.month, '121201').subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.resumenCuentaDocumento(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte resumen cuenta vs documento", "OK");
              reject(false);
            }
          )
          break;

        case "CONTABILIDAD_RCCO":

          this.reporte$ = this._contabilidadService.obtenerInformeCentroCostoAnual(headers.year).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.resumencentrocostoReport(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            _ => {
              this._snackBarService.showError('Error al obtener reporte de centro de costo anual', 'OK');
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_RMCCC":
          resolve(true);
          pdfBuilder.centrocostovscuentaReport(headers, null);
          break;

        case "CONTABILIDAD_BCEEFF":

          this.reporte$ = this._contabilidadService.obtenerInformeBalanceEEFF(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.balancecomprobacioneeffReport(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener informe de Balance EE.FF.', 'OK');
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_BCEEFFA":
          this.reporte$ = this._contabilidadService.obtenerInformeBalanceEEFFAnual(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.balancecomprobacioneeffAnualReport(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener informe de Balance EE.FF.', 'OK');
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_BCEEFFS":

          this.reporte$ = this._contabilidadService.obtenerInformeBalanceEEFFSunat(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.balancecomprobacioneeffsunatReport(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener informe de Balance EE.FF.', 'OK');
              reject(false);
            }
          )
          break;

        case "CONTABILIDAD_BG":
          this.reporte$ = this._contabilidadService.obtenerInformeBalanceGeneralAnalitico(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.balanceGeneral(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe de balance general", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_BGA":
          this.reporte$ = this._contabilidadService.obtenerInformeBalanceGeneralAnalitico(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.balanceGeneralAnalitico(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe de balance general", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_EGPF":
          this.reporte$ = this._contabilidadService.obtenerReporteGPporFuncion(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.estadoGPporFuncion(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de gastos y perdidas por función", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_EGPFA":
          this.reporte$ = this._contabilidadService.obtenerReporteGPporFuncionAnalitica(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.estadoGPporFuncionAnalitico(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de gastos y perdidas por función", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_EGPN":
          this.reporte$ = this._contabilidadService.obtenerReporteGPporNaturaleza(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.estadoGPporNaturaleza(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de gastos y perdidas por función", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_EGPNA":

          this.reporte$ = this._contabilidadService.obtenerReporteGPporNaturalezaAnalitica(headers.year, headers.month, headers.currency).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.estadoGPporNaturalezaAnalitico(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de gastos y perdidas por función", "OK")
              reject(false);
            }
          )
          break;

        case "CONTABILIDAD_FLIGR4C":
          resolve(true);
          pdfBuilder.libroingresoygastosReport(headers, null);
          break;
        case "CONTABILIDAD_FC10CB":
          resolve(true);
          pdfBuilder.cuenta10Report(headers, null);
          break;
        case "CONTABILIDAD_FC12C":
          resolve(true);
          pdfBuilder.cuenta12Report(headers, null);
          break;
        case "CONTABILIDAD_FC40TP":
          resolve(true);
          pdfBuilder.cuenta40Report(headers, null);
          break;
        case "CONTABILIDAD_FLD":

          this.reporte$ = this._contabilidadService.obtenerInformeLibroDiario(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.librodiarioReport(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al generar Informe Libro Diario", 'OK');
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_CB":

          this.reporte$ = this._contabilidadService.obtenerReporteConciliacionBancaria(headers.year, headers.month).subscribe(
            response => {
              if(response.length) {
                pdfBuilder.reporteConciliacionBancaria(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al Obtener Reporte de Conciliación Bancaria", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_MCBSI":

          this.reporte$ = this._contabilidadService.obtenerReporteMovimientosSaldosBancos(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.movimientoCajaBancoSaldoInicial(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al generar reporte movimientos saldos bancos", "OK");
              reject(false);
            }
          )
          break;
        case "CONTABILIDAD_RDI":
          resolve(true);
          pdfBuilder.reporteDaotIngresos(headers, null);
          break;
        case "CONTABILIDAD_RDC":
          resolve(true);
          pdfBuilder.reporteDaotCostos(headers, null);
          break;
        case "CONTABILIDAD_CCLAC":
          pdfBuilder.reporteConsolidadoComprasLocales(headers, null);
          resolve(true);
          break;
        case "CONTABILIDAD_RCC":

          this.reporte$ = this._contabilidadService.obtenerRegCompraPdf(headers.year, headers.month).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.registroDeComprasReport(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte PDF de Registro de compras", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.reporte$
    ])
  }

}
