import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { RentaAcumulada } from "src/app/models/planilla/datos-periodo/renta-acumulada";
import { NominaService } from "src/app/services";
@Component({
  selector: 'registro-renta-acumulada',
  templateUrl: './renta-acumulada-create.component.html',
  styleUrls: ['./renta-acumulada-create.component.scss']
})
export class RentaAcumuladaCreateComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  rentaAcumulada: RentaAcumulada;
  loaderReg: boolean;
  buttonsName: INameConstant = NAMES_CONSTANTS;

  colaboradores: any[] = [];

  sendForm$: Subscription;
  listarColab$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  declaracionMensual$: Subscription;

  year: string;
  month: string;

  constructor(
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>
  ) {
    this.rentaAcumulada = new RentaAcumulada();

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.rentaAcumulada.ANO_CODANO = state.year;
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) {
        this.loadMaestros();
      }
    })
  }

  obtenerIngresoRentaQuinta(): void {
    this.declaracionMensual$ = this._nominaService.obtenerDeclaracionMensualIngreso().subscribe(
      response => {
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Obtiene el maestro de :
   * . colaboradores
   */
  loadMaestros(): void {
    this.listarColab$ = this._nominaService.listarColaboradores().subscribe(
      colaboradores => {
        this.colaboradores = colaboradores;
        this.obtenerIngresoRentaQuinta();
      },
      error => {
        this._snackBarService.showError('Error al listar colaboradores', 'Ok');
      }
    )
  }

  registrarRentaAcumulada(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._nominaService.registrarDeclaracionMensualIngreso(this.rentaAcumulada).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Renta acumulada registrada', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.sendForm$,
      this.listarColab$,
      this.loading$,
      this.declaracionMensual$,
      this.periodo$
    ])
  }

}
