import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { STATE_CONSTANT } from '@data/constants/component/state.constant';

@Component({
  selector: 'app-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.css']
})
export class StateSelectComponent implements OnInit, OnDestroy {

  estados: Array<any>;

  @Input() disable: boolean;
  @Input() state: string | number;
  @Output() cambioEstado: EventEmitter<string>;

  constructor(
  ) {
    this.estados = STATE_CONSTANT;
    this.cambioEstado = new EventEmitter<string>();
    this.state = "1";
  }

  ngOnInit(): void {
  }

  seleccionarEstado(event): void {
    this.cambioEstado.emit(event.value);
  }

  trackByEstado(index, estado): any {
    return estado.id;
  }

  ngOnDestroy(): void {}
}
