import { Component, OnDestroy, OnInit } from '@angular/core';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ExportExcelService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_NOMINA_COLABORADOR } from '@data/constants/table/theader.nomina';

@Component({
  selector: 'app-colaborador-list',
  templateUrl: './colaborador-list.component.html',
  styleUrls: ['./colaborador-list.component.css']
})
export class ColaboradorListComponent implements OnInit, OnDestroy {

  colaboradores: any[] = [];

  colaborador$: Subscription;
  loading$: Subscription;
  period$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_COLABORADOR;

  year: string;
  month: string;

  constructor(
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    public ete: ExportExcelService
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this.colaborador$ = this._nominaService.listarColaboradoresPeriodo(this.year, this.month).subscribe(
      response => this.colaboradores = response,
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.colaborador$,
      this.loading$,
      this.period$
    ]);
  }
}
