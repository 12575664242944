import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyClientService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @param url
   * @returns
   */
  obtenerCompanias(url): Observable<any> {
    return this.http.get<any>(`${url}/api/comprobantes/listacompania`);
  }
}
