export class TipoCampania {
  codcoc: string;
  descri: string;
  fecreg: string;
  indsta: string;
  coduse: string;
  fecupd: Date;

  constructor() {
    this.fecupd = new Date();
  }
}
