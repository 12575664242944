import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Brand } from "src/app/models/ventas/brand";
import { ConfigurationService } from "src/app/services";
import { MarcaService } from "src/app/services/api/almacen/maestros/marca.service";
@Component({
  selector: 'marca-form',
  templateUrl: './marca-form.component.html',
  styleUrls: ['./marca-form.component.scss']
})
export class MarcaFormComponent implements OnInit, OnDestroy {

  loaderData: boolean;
  loaderReg: boolean;

  brand: Brand;

  uid: string;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  loading$: Subscription;
  sendForm$: Subscription;
  brand$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _marcaService: MarcaService,
    private _configurationService: ConfigurationService,
    private _router: Router,
    private store: Store<PavsoState>
  ) {
    this.brand = new Brand();
    this.brand.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.brand.MPR_CODUSE = this._configurationService.obtenerIdUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      if(this.uid == '0') {
        this.loaderData = false;
        return
      };
      this.obtenerMarca();
    })
  }

  obtenerMarca(): void {
    this.brand$ = this._marcaService.obtenerMarca(this.uid).subscribe(
      marca => {
        this.brand = marca;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarMarca(f): this.actualizarMarca(f);
  }

  registrarMarca(f: NgForm): void {

    this.sendForm$ = this._marcaService.registrarMarca(this.brand).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Marca registrada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )

  }

  actualizarMarca(f: NgForm): void {

    this.sendForm$ = this._marcaService.actualizarMarca(this.brand).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Marca actualizada', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-almacen/marcas']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.brand$
    ])
  }
}
