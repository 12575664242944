import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Turno } from 'src/app/models/produccion/turno';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { TurnoService } from 'src/app/services/api/produccion/maestros/turnos.service';
import { formatoHoras, parseHoras } from '@utils/conversions/number2time.conversion';

@Component({
  selector: 'app-turno-form',
  templateUrl: './turno-form.component.html',
  styleUrls: ['./turno-form.component.css']
})
export class TurnoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = true;

  turno: Turno;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  sendForm$: Subscription;
  turno$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _turnoService: TurnoService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService
  ) {
    this.turno = new Turno();
    this.turno.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.turno.tur_coduse = this._configurationService.obtenerIdUsuario();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({id})=> {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
      if(id == '0') {
        this.loaderData = false;
        return
      };

      this.obtenerTurno();
    })
  }

  obtenerTurno(): void {
    this.turno$ = this._turnoService.obtenerTurno(this.uid).subscribe(
      (turno) => {
        this.turno = turno
        this.turno.tur_horini = formatoHoras(turno.tur_horini);
        this.turno.tur_horfin = formatoHoras(turno.tur_horfin);
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )

  }


  formateoTiempoEnviar(){
    this.turno.tur_horini = parseHoras(this.turno.tur_horini);
    this.turno.tur_horfin = parseHoras(this.turno.tur_horfin);
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.formateoTiempoEnviar();
    this.uid == '0'? this.registrarTurno(f): this.actualizarTurno(f);
  }

  registrarTurno(f: NgForm): void {

    this.sendForm$ =this._turnoService.registrarTurno(this.turno).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Turno registrado', '400px', '400px', '');
        this.volver()
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarTurno(f: NgForm): void {
    this.sendForm$ =this._turnoService.actualizarTurno(this.turno).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Turno actualizado', '400px', '400px', '');
        this.volver()
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-produccion/turnos']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.turno$, this.loading$, this.sendForm$]);
  }

}
