import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { CopiarPresupuestoDialog } from "../dialogs/copiar-presupuesto/copiar-presupuesto.dialog";

@Component({
  selector: 'presupuesto-cotizacion-decoracion-form',
  templateUrl: './presupuesto-cotizacion-decoracion-form.component.html',
  styleUrls: ['./presupuesto-cotizacion-decoracion-form.component.scss']
})
export class PresupuestoCotizacionDecoracionFormComponent implements OnInit, OnDestroy {

  uid: string;

  loading$: Subscription;
  clientes$: Subscription;
  vendedores$: Subscription;
  presupuesto$: Subscription;

  clientes: any[] = [];
  vendedores: any[] = [];

  loaderData: boolean = false;
  loaderReg: boolean = false;

  displayedColumns: string[] = ['item', 'codigo', 'descripcion', 'cod_producto', 'desc_producto', 'ambiente', 'ref_ambiente', 'texto', 'obs_caida', 'cantidad', 'factor', 'ancho', 'alto', 'ajuste_alto', 'panos', 'panos_mandos', 'apertura', 'instalacion', 'parcial'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns2: string[] = ['item', 'familia', 'des_familia', 'subfamilia', 'desc_subfamilia', 'codigo', 'nombre', 'ume', 'factor', 'npt', 'calc_tela', 'cant_digit', 'cant_calc', 'precio_unitario', 'parcial', 'descripcion'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private readonly _clienteService: ClienteService,
    private readonly _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSource2 = fillTable([], this.paginator2, this.sort2);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    });
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      params => {
        this.uid = params.id;
        this.loadMaestro();
      }
    )
  }

  loadMaestro(): void {
    this.loaderData = true;
    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes;

        this.vendedores$ = this._ventasService.obtenerVendedores().subscribe(
          vendedores => {
            this.vendedores = vendedores;
            if(this.uid != '0') {
              this.obtenerPresupuesto();
              return;
            }
            this.loaderData = false;
          },
          error => {
            this._snackBarService.showError('Error al obtener presupuesto de cotización', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener presupuesto de cotización', 'Ok');
        this.loaderData = false;
      }
    )
  }

  obtenerPresupuesto(): void {

  }

  copiarPresupuesto(): void {

    const dialogRef = this.dialog.open(CopiarPresupuestoDialog, {
      width: '250px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }
  verCotizacion(): void {}
  moverCotizacion(): void {}

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {

  }


  enviarFormulario(f: NgForm): void {}

  registrar(f: NgForm): void {}

  actualizar(f: NgForm): void {}

}
