<app-pavso-title-section [title]="'Tienda'" [module]="'Ventas'" [estado]="tienda.tie_indsta"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Código</mat-label>
                      <input type="text" [(ngModel)]="tienda.tie_codtie" matInput placeholder="Código" name="tie_codtie" required >
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Descripción</mat-label>
                    <input type="text" [(ngModel)]="tienda.tie_destie" matInput placeholder="Descripción" name="tie_destie" required >
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Descri. Abrev.</mat-label>
                    <input type="text" [(ngModel)]="tienda.tie_desabr" matInput placeholder="Descri. Abrev." name="tie_desabr" required >
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Dirección</mat-label>
                    <input type="text" [(ngModel)]="tienda.tie_dirtie" matInput placeholder="Dirección" name="tie_dirtie" required >
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
              <select-search [label]="'País'" [placeholder]="'Ej. PERÚ'" [name]="'PAI_CODPAI'" [description]="'pai_nomlar'" [data]="paises" (cambioSelect)="tienda.pai_codpai = $event;" [value]="tienda.pai_codpai"></select-search>

                <!-- <mat-form-field>
                  <mat-label>País</mat-label>
                  <mat-select [(ngModel)]="tienda.pai_codpai" name="pai_codpai">
                    <mat-option *ngFor="let pais of paises" [value]="pais.PAI_CODPAI">
                      {{pais.pai_nomlar}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Ubicación</mat-label>
                  <mat-select [(ngModel)]="tienda.ubi_codubi" name="ubi_codubi">
                    <mat-option *ngFor="let ubicacion of ubicaciones" value="food.value">
                      {{ubicacion}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12">
                <p>Parámetros predeterminados para la pantalla comprobante de ventas en tienda.</p>
              </div>

              <div class="col s12">
                <section class="example-section">
                  <mat-checkbox class="example-margin" [(ngModel)]="tienda.tie_indcal" name="tie_indcal">Mostrar precio unitario sin IGV.</mat-checkbox>
                  <mat-checkbox class="example-margin" [(ngModel)]="tienda.tie_preuni" name="tie_preuni">Mostrar precio inc. IGV.</mat-checkbox>
                  <mat-checkbox class="example-margin" [(ngModel)]="tienda.tie_pruigv" name="tie_pruigv">Mostrar el importe bruto en los items.</mat-checkbox>
                  <mat-checkbox class="example-margin" [(ngModel)]="tienda.tie_impbru" name="tie_impbru">Mostrar porcentaje de descuento en los items.</mat-checkbox>
                  <mat-checkbox class="example-margin" [(ngModel)]="tienda.tie_pordes" name="tie_pordes">Mostrar importe total de descuento en los items</mat-checkbox>
                  <mat-checkbox class="example-margin" [(ngModel)]="tienda.tie_impdes" name="tie_impdes">Mostrar valor de venta en los items.</mat-checkbox>
                  <mat-checkbox class="example-margin" [(ngModel)]="tienda.tie_valvta" name="tie_valvta">Mostrar el importe IGV en los items.</mat-checkbox>
                  <!-- tie_impigv -->
                </section>
              </div>

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Moneda predeterminada</mat-label>
                  <mat-select [(ngModel)]="tienda.tmo_codtmo" name="tmo_codtmo">
                    <mat-option value="SO">
                      SOLES
                    </mat-option>
                    <mat-option value="DO">
                      DÓLARES
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Calcular en el detalle desde:</mat-label>
                  <mat-select>
                    <mat-option value="food.value">
                      pureba
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12">
                <p>Serie facturación predeterminada</p>
              </div>
              <div class="col s12">
                <p>Serie guía remisión predeterminada</p>
              </div>

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Motivo predeterminado</mat-label>
                  <mat-select [(ngModel)]="tienda.mmo_codmmo" name="mmo_codmmo">
                    <mat-option value="food.value">
                      pureba
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Cliente para la boleta de venta</mat-label>
                  <mat-select>
                    <mat-option value="food.value">
                      pureba
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Condi. pago predeterminado</mat-label>
                  <mat-select [(ngModel)]="tienda.cpa_codcpa" name="cpa_codcpa">
                    <mat-option value="food.value">
                      pureba
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Almacén</mat-label>
                  <mat-select [(ngModel)]="tienda.alm_codalm" name="alm_codalm">
                    <mat-option *ngFor="let almacen of almacenes" [value]="almacen.ALM_CODALM">
                      {{almacen.ALM_DESABR}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Tipo de lista</mat-label>
                  <mat-select [(ngModel)]="tienda.tlp_codtlp" name="tlp_codtlp">
                    <mat-option value="food.value">
                      pureba
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
          </div>

      </mat-card>
      <br>
      <br>
      <br>
      <br>
      <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
        urlBack="/modulo-ventas/tiendas">
        <div id="botonesAdicionales">

        </div>
      </pav-form-actions>

  </form>
</div>
