<h1 mat-dialog-title>Vigencia de Impuesto</h1>
<div mat-dialog-content>

  <div class="row">
    <div class="col s12 m12 l4">
      <mat-form-field>
        <mat-label>Código</mat-label>
        <mat-select [(ngModel)]="vigenciaImpuesto.PAI_CODPAI" required name="codigo">
          <mat-option value="1">
            VIGENTE
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col s12 m12 l4">
      <mat-form-field>
        <mat-label>Fecha de Vigencia</mat-label>
        <input [(ngModel)]="vigenciaImpuesto.TIM_CODTIM" name="TIM_CODTIM" matInput [matDatepicker]="fechavigencia">
        <mat-datepicker-toggle matSuffix [for]="fechavigencia"></mat-datepicker-toggle>
        <mat-datepicker #fechavigencia></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col s12 m12 l4">
      <mat-form-field>
        <mat-label>Hora de Vigencia</mat-label>
        <input [(ngModel)]="vigenciaImpuesto.TIV_FECVIG" name="TIV_FECVIG" type="time" matInput placeholder="Hora" min="06:00" max="18:00" required>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col s12 m12 l6">
      <mat-form-field>
        <mat-label>Porcentaje</mat-label>
        <input type="number" [(ngModel)]="vigenciaImpuesto.TIV_PORTIM" matInput placeholder="Porcentaje" name="TIV_PORTIM" required>
      </mat-form-field>
    </div>
    <div class="col s12 m12 l6">
      <mat-form-field>
        <mat-label>Importe</mat-label>
        <input type="number" [(ngModel)]="vigenciaImpuesto.TIV_IMPAPL" matInput placeholder="Importe" name="TIV_IMPAPL" required>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col s12">
      <button mat-stroked-button color="primary">Agregar</button>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col s12">
      <div class="mat-elevation-z0">

        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <button matTooltip="Editar" [routerLink]="['/modulo-ventas/editar-chofer', row.cli_codcli]"
                mat-icon-button color="primary">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="cli_codcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row" data-label="Código"> {{row.cli_codcli}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_numtlf">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
            <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros</td>
          </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
  <br>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
  <button mat-button cdkFocusInitial>{{buttonName.BTN_ACCEPT}}</button>
</div>
