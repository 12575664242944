export class Item {
  PCD_SECPCD: any;
  PRD_CODPRD: any;
  PCD_CORPCD: any;
  PCD_CANSOL: any;
  PCD_CANAUT: any;
  PCD_CANTRA: any;
  PCD_CANATE: any;
  PCD_CANFAS: any;
  PCD_CANATS: any;
  PCD_CANFAC: any;
  PCD_CANSUS: any;
  PCD_CANDIS: any;
  PCD_CANVEN: any;
  PCD_CANDES: any;
  PCD_PREUNI: any;
  PCD_PRUIGV: any;
  PCD_IMPBRU: any;
  PCD_PORDES: any;
  PCD_IMPDES: any;
  PCD_VALVTA: any;
  PCD_IMPIGV: any;
  PCD_IMPTOT: any;
  PCD_VTASUS: any;
  PCD_TOTSUS: any;
  PCD_GLOPCD: any;
  PCD_OBSPCD: any;
  PCD_INDODE: any;
  PCD_INDDES: any;
  PCD_OBSPRE: any;
  PCD_USUPRE: any;
  PCD_FECPRE: any;
  PCD_INDSOF: any;
  PCD_INDSOD: any;
  PCD_INDAUT: any;
  PCD_INDATE: any;
  PCD_INDFAC: any;
  UME_CODVEN: any;
  PCD_ATSREG: any;
  PCD_AUTREG: any;
  PCD_FASREG: any;
  PCD_FACREG: any;
  PCD_DESREG: any;
  PCD_OBSADI: any;
  DCM_CODDCM: any;
  PCD_TIPDES: any;
  PCD_PRELIS: any;
  PCD_INDDPR: any;
  PRM_CODPRM: any;
  PCD_CANUNI: any;
  PCD_MEDUNI: any;
  PCD_UNISUS: any;
  PCD_VENFAC: any;
  PCD_CODUSE: string;
  PCD_FECUPD: any;
  PCD_INDSTA: string;
  PCD_DESGLO: any;
  PCD_VENAUT: any;
  pcd_indesp: any;
  pcd_entrega: any;
  descuento: any;
  FPR_DESCRI: any;
  LDP_DESCRI: any;
  MPR_DESCRI: any;
  PRD_DESESP: any;
  PRD_PARNUM: any;
  TIN_DESCRI: any;
  constructor(
  ){}
}
