import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'unidad-transporte-list',
  templateUrl: './unidad-transporte-list.component.html',
  styleUrls: ['./unidad-transporte-list.component.scss']
})
export class UnidadTransporteListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  constructor() {

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
