import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { Colaborador, DepeTrabDtr, LegaTrabNle, MaesTrabPeriTrp, MaesTrabTra, RemuTrabRtr } from 'src/app/models/planilla/maestros/colaborador';
import { NominaService, ConfigurationService, GeneralService, AuthenticationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import * as documentBuilder from '@utils/documents/nomina.document';
import { HeaderReport } from '@data/interfaces/headers.report';
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from '@utils/formats/date.format';
import { DialogService } from '@shared/services/dialog.service';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { MatSelect } from '@angular/material/select';
import { ApiNominaMaestrosColaboradorService } from 'src/app/services/api/nomina/maestros/nomina.maestros.colaborador.service';
import { TipoLegajoServie } from 'src/app/services/api/nomina/maestros/tipo-legajo.service';

@Component({
  selector: 'app-colaborador-form',
  templateUrl: './colaborador-form.component.html',
  styleUrls: ['./colaborador-form.component.css']
})
export class ColaboradorFormComponent implements OnInit, OnDestroy {

  displayedColumnsPeriodo: string[] = ['periodo', 'dias_pago', 'dias_fisico'];
  dataSourcePeriodo: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorPeriodo: MatPaginator;
  @ViewChild(MatSort) sortPeriodo: MatSort;

  loaderPeriodo: boolean = false;

  displayedColumnsControl: string[] = ['mcv_cormcv', 'mcv_fecing', 'mcv_permcv', 'mcv_fecini', 'mcv_fecfin', 'mcv_vacgan', 'mcv_vacpag', 'mcv_vacade', 'mcv_venvac', 'mcv_saldvac'];
  dataSourceControl: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorControl: MatPaginator;
  @ViewChild(MatSort) sortControl: MatSort;

  loaderControl: boolean = false;

  displayedColumnsLegajo: string[] = ['actions', 'nle_cornle', 'TDL_CODTDL', 'tdl_descri', 'nle_fecing', 'nle_fecvig', 'nle_fecven', 'nle_indsta', 'adjuntar'];
  dataSourceLegajo: MatTableDataSource<LegaTrabNle>;

  @ViewChild('pagLegajo') paginatorLegajo: MatPaginator;
  @ViewChild(MatSort) sortLegajo: MatSort;

  loaderLegajo: boolean = false;

  displayedColumnsDependiente: string[] = ['actions', 'item', 'apellido_paterno', 'apellido_materno', 'nombres', 'tipo_doc', 'nro_documento', 'fecha_nacimiento', 'sexo', 'vinculo_familiar', 'glosa'];
  dataSourceDependiente: MatTableDataSource<any>;

  @ViewChild('pagDependiente') paginatorDependiente: MatPaginator;
  @ViewChild(MatSort) sortDependiente: MatSort;

  loaderDependiente: boolean = false;

  displayedColumnsRemuneracion: string[] = ['actions', 'nco_codnco', 'importe', 'cantidad', 'unidad'];
  dataSourceRemuneracion: MatTableDataSource<any>;

  @ViewChild('pagRemuneracion') paginatorRemuneracion: MatPaginator;
  @ViewChild(MatSort) sortRemuneracion: MatSort;

  loaderRemuneracion: boolean = false;

  loaderReg: boolean = false;
  loaderData: boolean = false;
  formValidated: boolean = false;

  afps: any[] = [];
  gradosAcademicos: any[] = [];
  tiposDocumento: any[] = [];
  tiposDocumentoLegajo: any[] = [];
  tiposNomina: any[] = [];
  centrosCosto: any[] = [];
  paises: any[] = [];
  monedas: any[] = [];
  cargos: any[] = [];
  estados: any[] = [];
  ubicaciones: any[] = [];
  tiposPersonal: any[] = [];
  sexos: any[] = [];
  excepciones: any[] = [];
  comisiones: any[] = [];
  grados: any[] = [];
  lugaresTrabajo: any[] = [];
  situaciones: any[] = [];
  bancos: any[] = [];
  vinculos: any[] = [];

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  colaborador: Colaborador;

  year: string;
  month: string

  loadingConstancia: boolean = false;
  loadingCertificado: boolean = false;
  imageUrl: any;

  usuario: string;

  period$: Subscription;
  loading$: Subscription;
  constancia$: Subscription;
  certificado$: Subscription;
  sendForm$: Subscription;
  ubicaciones$: Subscription;
  verColaborador$: Subscription;
  formColaborador$: Subscription;
  tiposDocumentoLegajo$: Subscription;

  colaboradores: any[] = [];
  conceptos: any[] = [];
  unidades: any[] = [];

  uid: string;

  @ViewChild('tipoDocumentoSelect') tipoDocumentoSelect: MatSelect;
  @ViewChild('sexoSelect') sexoSelect: MatSelect;
  @ViewChild('gradoAcademicoSelect') gradoAcademicoSelect: MatSelect;
  @ViewChild('estadoCivilSelect') estadoCivilSelect: MatSelect;
  @ViewChild('paisSelect') paisSelect: MatSelect;
  @ViewChild('ubicacionSelect') ubicacionSelect: MatSelect;
  @ViewChild('afpSelect') afpSelect: MatSelect;
  @ViewChild('tipoPersonalSelect') tipoPersonalSelect: MatSelect;
  @ViewChild('tipoNominaSelect') tipoNominaSelect: MatSelect;
  @ViewChild('comisionAFPSelect') comisionAFPSelect: MatSelect;
  @ViewChild('cargoSelect') cargoSelect: MatSelect;
  @ViewChild('centroCostoSelect') centroCostoSelect: MatSelect;
  @ViewChild('excepcionAportarSelect') excepcionAportarSelect: MatSelect;
  @ViewChild('lugarTrabajoSelect') lugarTrabajoSelect: MatSelect;
  @ViewChild('situacionSelect') situacionSelect: MatSelect;
  @ViewChild('jornadaLaboralSelect') jornadaLaboralSelect: MatSelect;
  @ViewChild('bancoHaberSelect') bancoHaberSelect: MatSelect;
  @ViewChild('monedaHaberSelect') monedaHaberSelect: MatSelect;
  @ViewChild('monedaCTSSelect') monedaCTSSelect: MatSelect;

  minDate: Date;
  maxDate: Date;

  textDialog: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _nominaService: NominaService,
    private _apiColaboradorNominaService: ApiNominaMaestrosColaboradorService,
    private _generalService: GeneralService,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService,
    private _dialogService: DialogService,
    private _tipoLegajoService: TipoLegajoServie
  ) {

    this.period$ = this.store.select('period').subscribe(state => {

      this.year = state.year;
      this.month = state.month;

      this.minDate = new Date(Number(this.year), Number(this.month) - 1, 1);
      this.maxDate = new Date(Number(this.year), Number(this.month), 0);

    })


    this.dataSourcePeriodo = fillTable([], this.paginatorPeriodo, this.sortPeriodo);

    this.dataSourceControl = fillTable([], this.paginatorControl, this.sortControl);

    this.dataSourceLegajo = fillTable([], this.paginatorLegajo, this.sortLegajo);

    this.dataSourceDependiente = fillTable([], this.paginatorDependiente, this.sortDependiente);

    this.dataSourceRemuneracion = fillTable([], this.paginatorRemuneracion, this.sortRemuneracion);

    this.colaborador = new Colaborador();

    this.colaborador.codcia = this._configurationService.obtenerCompaniaCliente();
    this.colaborador.coduse = this._configurationService.obtenerIdUsuario();

    this.usuario = this._configurationService.obtenerNombreUsuario();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({ id }) => {
        this.uid = id;
        this.loadMaestro();
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;
        this.uid == '0' ? this.textDialog = 'registrado' : this.textDialog = 'actualizado';
        this.formValidated = true;
        if (id == '0') return;
      }
    )
  }

  verColaborador(): void {
    this.verColaborador$ = this._apiColaboradorNominaService.verColaborador(this.uid, this.year, this.month).subscribe(
      colaborador => {
        console.log('COLABORADOR', colaborador)
        this.colaborador = colaborador;

        this.colaborador.coduse = this._configurationService.obtenerIdUsuario();
        this.colaborador.tipper = "N";
        this.colaborador.fecupd = new Date();
        this.colaborador.indtra = 1;
        this.colaborador.indpnj = 0;

        this.colaborador.MAES_TRAB_PERI_TRP = colaborador.MAES_TRAB_PERI_TRP;
        this.colaborador.MAES_TRAB_TRA = colaborador.MAES_TRAB_TRA;
        this.colaborador.ASIG_VACA_NAV = colaborador.ASIG_VACA_NAV;
        this.colaborador.LEGA_TRAB_NLE = colaborador.LEGA_TRAB_NLE;
        this.colaborador.DEPE_TRAB_DTR = colaborador.DEPE_TRAB_DTR;
        this.colaborador.REMU_TRAB_RTR = colaborador.REMU_TRAB_RTR;

        console.log('fecha cese prev', colaborador.MAES_TRAB_PERI_TRP[0].fecces)
        console.log('fecha ing prev', colaborador.MAES_TRAB_PERI_TRP[0].fecing)

        this.colaborador.MAES_TRAB_PERI_TRP[0].fecces = new Date(colaborador.MAES_TRAB_PERI_TRP[0].fecces);
        this.colaborador.MAES_TRAB_PERI_TRP[0].fecces.setDate(this.colaborador.MAES_TRAB_PERI_TRP[0].fecces.getDate() + 1)

        this.colaborador.MAES_TRAB_PERI_TRP[0].fecing = new Date(colaborador.MAES_TRAB_PERI_TRP[0].fecing);
        this.colaborador.MAES_TRAB_PERI_TRP[0].fecing.setDate(this.colaborador.MAES_TRAB_PERI_TRP[0].fecing.getDate() + 1)

        console.log('fecha cese', this.colaborador.MAES_TRAB_PERI_TRP[0].fecces)
        console.log('fecha ing', this.colaborador.MAES_TRAB_PERI_TRP[0].fecing)

        colaborador.REMU_TRAB_RTR.forEach((item, key) => {
          item["itemR"] = `000${key + 1}`;

          const concepto = this.conceptos.find(ele => ele.codnco == item.codnco);
          item.codnut = concepto.codnut;
        });

        this.dataSourceControl = fillTable(colaborador.ASIG_VACA_NAV, this.paginatorControl, this.sortControl);

        this.dataSourceLegajo = fillTable(colaborador.LEGA_TRAB_NLE, this.paginatorLegajo, this.sortLegajo);

        this.dataSourceDependiente = fillTable(colaborador.DEPE_TRAB_DTR, this.paginatorDependiente, this.sortDependiente);

        this.dataSourceRemuneracion = fillTable(colaborador.REMU_TRAB_RTR, this.paginatorRemuneracion, this.sortRemuneracion);

        // if(colaborador.situacion == 'CESADO') {
        //   this.colaborador.MAES_TRAB_PERI_TRP = [ new MaesTrabPeriTrp() ];
        //   this.colaborador.MAES_TRAB_TRA = [ new MaesTrabTra() ];
        //   this.colaborador.ASIG_VACA_NAV = [];
        //   this.colaborador.LEGA_TRAB_NLE = [];
        //   this.colaborador.DEPE_TRAB_DTR = [];
        //   this.colaborador.REMU_TRAB_RTR = [];

        //   this.dataSourceControl = fillTable([], this.paginatorControl, this.sortControl);
        //   this.dataSourceLegajo = fillTable([], this.paginatorLegajo, this.sortLegajo);
        //   this.dataSourceDependiente = fillTable([], this.paginatorDependiente, this.sortDependiente);
        //   this.dataSourceRemuneracion = fillTable([], this.paginatorRemuneracion, this.sortRemuneracion);
        // }

        this._activatedRoute.params.subscribe(params => {
          if (params['id'] == '0') this.restablecerDatosColaboradorReingresante()
        });

        this.seleccionarPais("01");

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError("Error al obtener colaborador", "OK")
      }
    )
  }

  /**
   * Obtiene los maestros de los formularios
   *
   */
  loadMaestro(): void {
    this.loaderData = true;

    this.tiposDocumentoLegajo$ = this._tipoLegajoService.obtenerTiposLegajo().subscribe(
      tiposDocLegajo => {
        this.tiposDocumentoLegajo = tiposDocLegajo;

        this.formColaborador$ = this._nominaService.obtenerDataFormColaborador(this.year, this.month).subscribe(
          data => {

            console.log('colaborador form', data)


            this.afps = data.afps;
            this.centrosCosto = data.centros;
            this.paises = data.paises;
            this.colaborador.codpai = "01";

            this.tiposDocumento = data.tiposDoc;
            this.tiposNomina = data.tiposNomina;
            this.cargos = data.cargos;

            this.estados = data.estadosCivil;
            this.ubicaciones = [];
            this.tiposPersonal = data.tiposPer;
            this.sexos = data.sexos;
            this.excepciones = data.excepciones;

            this.comisiones = data.comisiones;
            this.grados = data.gradosInstruccion;
            this.lugaresTrabajo = data.lugaresTrabajo;
            this.bancos = data.bancos;
            this.situaciones = data.situaciones;
            this.vinculos = data.vinculos;

            this.colaboradores = data.colaboradores;
            this.conceptos = data.conceptos;
            this.unidades = data.unidades;

            if (this.uid != '0') {
              this.verColaborador();
              return;
            }

            this.loaderData = false;

          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de documento legajo', "Ok");
      }
    )
  }

  trackByTipoDoc(index, tipoDoc): string {
    return tipoDoc ? tipoDoc.DID_CODDID : undefined;
  }

  trackBySexo(index, sexo): string {
    return sexo ? sexo.gpe_codgpe : undefined;
  }

  trackByGrado(index, grado): string {
    return grado ? grado.t09_coddoc : undefined;
  }

  trackByEstadoCivil(index, estadoCivil): string {
    return estadoCivil ? estadoCivil.civ_indciv : undefined;
  }

  trackByPais(index, pais): string {
    return pais ? pais.PAI_CODPAI : undefined;
  }

  trackByUbicacion(index, ubicacion): string {
    return ubicacion ? ubicacion.codubi : undefined;
  }

  trackByAFP(index, afp): string {
    return afp ? afp.codafp : undefined;
  }

  trackByTipoPersonal(index, tipoPersonal): string {
    return tipoPersonal ? tipoPersonal.ntp_codntp : undefined;
  }

  trackByTipoNomina(index, tipoNomina): string {
    return tipoNomina ? tipoNomina.nti_codnti : undefined;
  }

  trackByComision(index, comision): string {
    return comision ? comision.caf_comafp : undefined;
  }

  trackByCargo(index, cargo): string {
    return cargo ? cargo.codnca : undefined;
  }

  trackByCentroCosto(index, centroCosto): string {
    return centroCosto ? centroCosto.ccs_codccs : undefined;
  }

  trackByExcepcion(index, excepcion): string {
    return excepcion ? excepcion.nex_codnex : undefined;
  }

  trackByLugar(index, lugar): string {
    return lugar ? lugar.pla_codpla : undefined;
  }

  trackBySituacion(index, situacion): string {
    return situacion ? situacion.nsc_codnsc : undefined;
  }

  trackByBanco(index, banco): string {
    return banco ? banco.BAN_CODBAN : undefined;
  }

  seleccionarPais(event): void {
    this.ubicaciones$ = this._generalService.listarUbicacion(this.colaborador.codpai).subscribe(
      ubicaciones => {
        this.ubicaciones = ubicaciones;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    if (!this.estaVerificadoDetalle()) {
      this._snackBarService.showError('Ninguna fila de las tablas debe de estar en modo edición', 'Ok');
      return;
    }

    if (!this.validarFormulario()) return;

    this.loaderReg = true;
    this.validarIndicadores();
    this.colaborador.nomcli = `${this.colaborador.apepat} ${this.colaborador.apemat} ${this.colaborador.nombre}`;

    this.uid == '0' ? this.registrarColaborador(f) : this.actualizarColaborador(f);
  }

  /**
   * Método para registrar un nuevo Colaborador
   * @param f
   */
  registrarColaborador(f: NgForm): void {
    this.sendForm$ = this._apiColaboradorNominaService.registrarColaborador(this.colaborador, this.year, this.month).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Colaborador Registrado', '350px', '350px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )
  }

  /**
   * Método para actualizar colaborador
   * @param f
   */
  actualizarColaborador(f: NgForm): void {

    this.sendForm$ = this._apiColaboradorNominaService.actualizarColaborador(this.colaborador, this.year, this.month).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Colaborador Actualizado', '350px', '350px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )

  }

  validarIndicadores(): void {
    this.colaborador.inddom ? this.colaborador.inddom = 1 : this.colaborador.inddom = 0;
    this.colaborador.MAES_TRAB_PERI_TRP[0]['indper'] ? this.colaborador.MAES_TRAB_PERI_TRP[0]['indper'] = 1 : this.colaborador.MAES_TRAB_PERI_TRP[0]['indper'] = 0;
    this.colaborador.MAES_TRAB_PERI_TRP[0]['excren'] ? this.colaborador.MAES_TRAB_PERI_TRP[0]['excren'] = 1 : this.colaborador.MAES_TRAB_PERI_TRP[0]['excren'] = 0;
    this.colaborador.MAES_TRAB_PERI_TRP[0]['indsub'] ? this.colaborador.MAES_TRAB_PERI_TRP[0]['indsub'] = 1 : this.colaborador.MAES_TRAB_PERI_TRP[0]['indsub'] = 0;
    this.colaborador.MAES_TRAB_PERI_TRP[0]['indeps'] ? this.colaborador.MAES_TRAB_PERI_TRP[0]['indeps'] = 1 : this.colaborador.MAES_TRAB_PERI_TRP[0]['indeps'] = 0;
    this.colaborador.MAES_TRAB_PERI_TRP[0]['exopen'] ? this.colaborador.MAES_TRAB_PERI_TRP[0]['exopen'] = 1 : this.colaborador.MAES_TRAB_PERI_TRP[0]['exopen'] = 0;
    this.colaborador.MAES_TRAB_PERI_TRP[0]['excseg'] ? this.colaborador.MAES_TRAB_PERI_TRP[0]['excseg'] = 1 : this.colaborador.MAES_TRAB_PERI_TRP[0]['excseg'] = 0;
    this.colaborador.MAES_TRAB_PERI_TRP[0]['indbon'] ? this.colaborador.MAES_TRAB_PERI_TRP[0]['indbon'] = 1 : this.colaborador.MAES_TRAB_PERI_TRP[0]['indbon'] = 0;
    this.colaborador.MAES_TRAB_PERI_TRP[0]['indq50'] ? this.colaborador.MAES_TRAB_PERI_TRP[0]['indq50'] = 1 : this.colaborador.MAES_TRAB_PERI_TRP[0]['indq50'] = 0;
  }

  estaVerificadoDetalle(): boolean {
    let isValid = true;

    this.colaborador.REMU_TRAB_RTR.forEach(item => {
      if (item['isEditing']) isValid = false;
    })

    this.colaborador.DEPE_TRAB_DTR.forEach(item => {
      if (item['isEditing']) isValid = false;
    })

    this.colaborador.LEGA_TRAB_NLE.forEach(item => {
      if (item['isEditing']) isValid = false;
    })

    return isValid;
  }

  subirImagen(event): void {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
      }
      // ChangeDetectorRef since file is loading outside the zone
    }
  }

  quitarImagen(): void {
    this.imageUrl = null;
  }

  verificarCodigo(): void {
    if (this.uid != '0') {
      this.formValidated = true;
      return;
    }

    if (!this.colaborador.codcli) return;

    const colaborador = this.colaboradores.find(item => item.codcli == this.colaborador.codcli)
    if (!colaborador) {
      this.formValidated = true;
      return
    }

    this.formValidated = false;

    this._dialogService.openDialog(ConfirmationComponent, 'Colaborador registrado ¿Es reingresante?', '400px', 'auto', '').subscribe(response => {

      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;

      if (response) {
        this.uid = this.colaborador.codcli;
        this.verColaborador();
        this.formValidated = true;
        return;
      }

      this._snackBarService.showError('Es necesario reesblecer los datos del cliente', 'Ok')
      this.formValidated = false;

    })

  }

  restablecerDatosColaboradorReingresante(): void {
    this.colaborador.MAES_TRAB_PERI_TRP = [new MaesTrabPeriTrp()];
    this.colaborador.MAES_TRAB_TRA = [new MaesTrabTra()];
    this.colaborador.REMU_TRAB_RTR = [];
    this.dataSourceRemuneracion = fillTable(this.colaborador.REMU_TRAB_RTR, this.paginatorRemuneracion, this.sortRemuneracion);
    this.colaborador.LEGA_TRAB_NLE = [];
    this.dataSourceLegajo = fillTable(this.colaborador.LEGA_TRAB_NLE, this.paginatorLegajo, this.sortLegajo);
    this.colaborador.ASIG_VACA_NAV = [];
    this.colaborador.DEPE_TRAB_DTR = [];
    this.dataSourceDependiente = fillTable(this.colaborador.DEPE_TRAB_DTR, this.paginatorDependiente, this.sortDependiente);
  }

  validarFormulario(): boolean {

    if (!this.colaborador.coddid) {
      this.tipoDocumentoSelect.focus();
      return false;
    }
    if (!this.colaborador.indsex) {
      this.sexoSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_TRA[0]['coddoc']) {
      this.gradoAcademicoSelect.focus();
      return false;
    }
    if (!this.colaborador.indciv) {
      this.estadoCivilSelect.focus();
      return false;
    }
    if (!this.colaborador.codpai) {
      this.paisSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_TRA[0]['lugnac']) {
      this.ubicacionSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['codafp']) {
      this.afpSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['ctlmar']) {
      this.tipoPersonalSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['codnti']) {
      this.tipoNominaSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['comafp']) {
      this.comisionAFPSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['codnca']) {
      this.cargoSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['codccs']) {
      this.centroCostoSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['excapo']) {
      this.excepcionAportarSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['codpla']) {
      this.lugarTrabajoSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['tipcon']) {
      this.situacionSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['tipcon']) {
      this.jornadaLaboralSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['codhab']) {
      this.bancoHaberSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['codtmo']) {
      this.monedaHaberSelect.focus();
      return false;
    }
    if (!this.colaborador.MAES_TRAB_PERI_TRP[0]['codcts']) {
      this.monedaCTSSelect.focus();
      return false;
    }

    return true;
  }

  imprimirConstanciaTrabajo(): void {
    this.loadingConstancia = true;
    this.constancia$ = this._nominaService.obtenerDataConstanciaTrabajo(this.uid, this.year, this.month).subscribe(
      response => {
        if (response.length > 0) {
          let hearder: HeaderReport = {
            year: "",
            month: "",
            clientName: this._configurationService.obtenerNombreCompaniaCliente(),
            client: this._configurationService.obtenerClienteId(),
            date: formatDateWithSlash(new Date()),
            time: getHourMinuteSecondsCurrent(new Date()),
            currency: "",
            fecini: '',
            fecter: '',
            username: this._configurationService.obtenerNombreUsuario(),
            codePage: this._configurationService.obtenerCodigoPagina()
          }
          documentBuilder.documentoConstanciaTrabajo(hearder, response[0]);
          this.loadingConstancia = false;
          return;
        }
        this._snackBarService.showError('Seleccionar otro período para obtener la constancia', 'OK');
        this.loadingConstancia = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loadingConstancia = false;
      }
    )
  }

  imprimirCertificadoTrabajo(): void {
    this.loadingCertificado = true;
    this.certificado$ = this._nominaService.obtenerDataCertificadoTrabajo(this.uid, this.year, this.month).subscribe(
      response => {
        if (response.length > 0) {
          let hearder: HeaderReport = {
            year: "",
            month: "",
            clientName: this._configurationService.obtenerNombreCompaniaCliente(),
            client: this._configurationService.obtenerClienteId(),
            date: formatDateWithSlash(new Date()),
            time: getHourMinuteSecondsCurrent(new Date()),
            currency: "",
            fecini: '',
            fecter: '',
            username: this._configurationService.obtenerNombreUsuario(),
            codePage: this._configurationService.obtenerCodigoPagina()
          }
          documentBuilder.documentoCertificadoTrabajo(hearder, response[0]);
          this.loadingCertificado = false;
          return;
        }
        this._snackBarService.showError('Seleccionar otro período para obtener el certificado', 'OK');
        this.loadingCertificado = false;
        return;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loadingCertificado = false;
      }
    )
  }

  /**
   * Agragar una fila a la Tabla de Legajo
   */
  agregarItemLegajo(): void {
    let legajoItem = new LegaTrabNle()
    legajoItem.coduse = this._authService.getUsuarioSistema();
    legajoItem.cornle = `000${this.colaborador.LEGA_TRAB_NLE.length + 1}`

    this.colaborador.LEGA_TRAB_NLE.push(legajoItem);

    this.dataSourceLegajo = fillTable(this.colaborador.LEGA_TRAB_NLE, this.paginatorLegajo, this.sortLegajo);
  }

  /**
   * Confirmar la edición de la columna de la Tabla Legajo
   * @param row
   */
  confirmarNuevoItemLegajo(row: LegaTrabNle): void {
    if (!row.glosa) {
      this._snackBarService.showError('Completar todos los campos de la fila', 'OK');
      return;
    }
    row.isEditing = !row.isEditing;
  }

  /**
   * Quitar un columna de la Tabla Legajo
   * @param id
   */
  quitarItemLegajo(id): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Esta seguro de quitar este item?', '', '', '').subscribe(result => {
      if (result) {
        let data: Array<LegaTrabNle> = [];
        this.dataSourceLegajo.data.forEach((item, key) => {
          if (item.cornle != id) data.push(item)
        });

        data.forEach((item, key) => {
          item.cornle = `000${key + 1}`;
        })

        this.dataSourceLegajo = fillTable(data, this.paginatorLegajo, this.sortLegajo);
        this.colaborador.LEGA_TRAB_NLE = data;
      }
    });


  }

  /**
   * Agragar una fila a la Tabla de Dependiente
   */
  agregarItemDependiente(): void {
    let dependienteItem = new DepeTrabDtr(this.usuario);
    dependienteItem.cordtr = `0${this.colaborador.DEPE_TRAB_DTR.length + 1}`

    this.colaborador.DEPE_TRAB_DTR.push(dependienteItem);

    this.dataSourceDependiente = fillTable(this.colaborador.DEPE_TRAB_DTR, this.paginatorDependiente, this.sortDependiente);
  }

  /**
  * Confirmar la edición de la columna de la Tabla Dependiente
  * @param row
  */
  confirmarNuevoItemDependiente(row: DepeTrabDtr): void {

    if (!row.apepat || !row.apemat || !row.nombre || !row.coddoc || !row.fecnac || !row.indsex || !row.codvin) {
      this._snackBarService.showError('Completar todos los campos de la fila', 'OK');
      return;
    }

    row.isEditing = !row.isEditing;
  }

  /**
   * Quitar un columna de la Tabla Dependiente
   * @param id
   */
  quitarItemDependiente(id): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Esta seguro de quitar este item?', '', '', '').subscribe(result => {
      if (result) {
        let data: Array<DepeTrabDtr> = [];
        this.dataSourceDependiente.data.forEach(item => {
          if (item.cordtr != id) {
            data.push(item)
          }
        });

        data.forEach((item, key) => {
          item.cordtr = `000${key + 1}`;
        })

        this.dataSourceDependiente = fillTable(data, this.paginatorDependiente, this.sortDependiente);
        this.colaborador.DEPE_TRAB_DTR = data;
      }
    });
  }

  seleccionarConceptoRem(row): void {

    const concepto = this.conceptos.find(item => item.codnco == row.codnco);

    row.codnut = concepto.codnut;
  }

  /**
   * Agragar una fila a la Tabla de Remuneración
   */
  agregarItemRemuneracion(): void {
    let remuneracionItem = new RemuTrabRtr();
    remuneracionItem.cannut = 30;

    remuneracionItem.itemR = `000${this.colaborador.REMU_TRAB_RTR.length + 1}`
    this.colaborador.REMU_TRAB_RTR.push(remuneracionItem);

    this.dataSourceRemuneracion = fillTable(this.colaborador.REMU_TRAB_RTR, this.paginatorRemuneracion, this.sortRemuneracion);
  }

  /**
   * Confirmar la edición de la columna de la Tabla Remuneración
   * @param row
   */
  confirmarNuevoItemRemuneracion(row: RemuTrabRtr): void {
    if (!row.codnco || !row.imprem || !row.cannut || !row.codnut) {
      this._snackBarService.showError('Completar todos los campos de la fila', 'Ok');
      return;
    }

    row.isEditing = !row.isEditing;
  }

  /**
   * Quitar un columna de la Tabla Remuneración
   * @param id
   */
  quitarItemRemuneracion(id): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Esta seguro de quitar este item?', '', '', '').subscribe(result => {
      if (result) {
        let data: Array<RemuTrabRtr> = [];
        this.dataSourceRemuneracion.data.forEach(item => {
          if (item.itemR != id) data.push(item)

        });

        data.forEach((item, key) => {
          item.itemR = `000${key + 1}`;
        })

        this.dataSourceRemuneracion = fillTable(data, this.paginatorRemuneracion, this.sortRemuneracion);
        this.colaborador.REMU_TRAB_RTR = data;
      }
    });
  }

  handleOnUploadFile(row: LegaTrabNle, event): void {
    console.log('event', event)
    row.documento = event.base64;
    row.formato = event.typeFile;
  }

  openFile(fileBase64, fileType): void {
    if (!fileBase64) {
      return;
    }

    console.log('fileBase64', fileBase64)
    console.log('fileBase64', fileType)
    if (fileType.includes('image/') || fileType == 'application/pdf') {
      const byteCharacters = atob((fileBase64.split('base64,')[1]));

      try {
        // Convert the Base64 data to a Blob
        const byteCharacters = atob((fileBase64.split('base64,')[1]))
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Open the URL in a new tab
        const newWindow = window.open();

        if (fileType.includes('image/')) {
          newWindow.document.write('<html><head><title>Image</title></head><body><img src="' + blobUrl + '" /></body></html>');
          return;
        }

        if (fileType == 'application/pdf') {
          newWindow.document.write('<html><head><title>PDF</title></head><body><embed src="' + blobUrl + '" type="application/pdf" width="100%" height="100%"/></body></html>');

        }

      } catch (error) {
        console.error('Error opening file:', error);
      }

    }

  }

  quitarDocumento(id): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        /*
        this.quitarDocumento$ = this._receptionService.eliminarDocumentoRecepcion(id).subscribe(
          documento => {
            const documentosFiltrados = this.documentos.filter(documento => documento.ccd_idccd != id)
            this.documentos = documentosFiltrados;
            this.dataSourceDocumentos = fillTable(documentosFiltrados, this.paginatorDocumentos, this.sortDocumentos);
            console.log('documento', documento)
          },
          error => {
            this._snackBarService.showError('Error al eliminar documento en recepción', 'Ok');
          }
        )
        */
      }
    })
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/trabajadores']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.certificado$,
      this.constancia$,
      this.sendForm$,
      this.verColaborador$,
      this.ubicaciones$,
    ])
  }

}
