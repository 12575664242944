import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { ExplosionMateriales } from 'src/app/models/produccion/operaciones/explosion-material';
import { ProductoProcesado } from 'src/app/models/produccion/operaciones/liquidacion-fabricacion';
import { DialogService } from '@shared/services/dialog.service';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';

@Component({
  selector: 'app-explosion-materiales',
  templateUrl: './explosion-materiales.component.html',
  styleUrls: ['./explosion-materiales.component.css']
})
export class ExplosionMaterialesComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['acciones', 'tipo_inventario', 'producto', 'descripcion', 'um', 'cantidad_requerida', 'cantidad_stock', 'cantidad_produccion', 'cantidad_compra', 'cantidad_final'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tipoDoc$: Subscription;
  loading$: Subscription;

  explosionMaterial: ExplosionMateriales;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _authService: AuthenticationService,
    private _dialogService: DialogService
  ) {

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();
    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.driver.CHO_INDSTA = "1";

    this.tipoDoc$ = this._generalService.listarTipoDocumentoIdentidad().subscribe(
      tiposDocumento => {
        this.tiposDocumento = tiposDocumento;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  agregarProducto(): void {
    const producto = new ProductoProcesado();

    this.explosionMaterial.detalle_productos.push(producto);

    this.dataSource = fillTable(this.explosionMaterial.detalle_productos, this.paginator, this.sort);
  }

  eliminarProducto(index): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const productos = this.explosionMaterial.detalle_productos.filter((_, i) => index != i );

        this.explosionMaterial.detalle_productos = productos;

        this.dataSource = fillTable(this.explosionMaterial.detalle_productos, this.paginator, this.sort);
      }

    })
  }

  enviarFormulario(f: NgForm): void {}

  registrarExplosion(f: NgForm): void {

  }

  editarExplosion(f: NgForm): void {

  }

  editaqr

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoDoc$])
  }
}
