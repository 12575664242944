import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'validacion-cta-diferencia-cambio',
  templateUrl: 'validacion-cta-diferencia-cambio.dialog.html',
  styleUrls: ['validacion-cta-diferencia-cambio.dialog.scss']
})

export class ValidacionCtaDiferenciaCambio implements OnInit {

  displayedColumns: string[] = ['acciones', 'cli_codcli', 'cli_nomcli', 'cli_numtlf'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() { }

  ngOnInit() { }
}
