<app-pavso-title-section [title]="'Establecimiento de cliente'" [module]="'Ventas'" [ngClass]="'pav-form'"
  [estado]="'1'"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <mat-card class="mat-elevation-z0">
    <div class="pavso-content-form fade-in-image">
      <div class="row">
        <div class="col s12 m12 l4">
          <mat-checkbox class="example-margin" [(ngModel)]="establecimiento.LDE_INDPRI" name="LDE_INDPRI">Es
            Principal</mat-checkbox>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Código</mat-label>
            <input [(ngModel)]="establecimiento.LDE_CODLDE" maxlength="4" type="text" name="LDE_CODLDE" matInput
              placeholder="Ej. 0001">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Nombre</mat-label>
            <input [(ngModel)]="establecimiento.LDE_NOMLDE" required name="LDE_NOMLDE" matInput
              placeholder="Ej. PVEA SAN ISIDRO">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Dirección</mat-label>
            <input [(ngModel)]="establecimiento.LDE_DIRLDE" required name="LDE_DIRLDE" matInput
              placeholder="Ej. Av. Los Heroes 389">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field class="example-full-width">
            <mat-label>Referencia</mat-label>
            <input [(ngModel)]="establecimiento.LDE_REFDIR" name="LDE_REFDIR" matInput placeholder="Ej. Al costado de..">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <select-search [label]="'País'" [placeholder]="'Ej. Perú'" [name]="'PAI_CODPAI'" [value]="'PAI_CODPAI'"
            [description]="'pai_nomlar'" [data]="paises" (cambioSelect)="establecimiento.PAI_CODPAI = $event"
            [model]="establecimiento.PAI_CODPAI">
          </select-search>
        </div>
        <div class="col s12 m12 l4">
          <select-search [label]="'Ubicación'" [placeholder]="'Ej. Lima-Lima-Pueblo Libre'" [name]="'codubi'"
            [value]="'codubi'" [description]="'desubi'" [data]="ubicaciones"
            (cambioSelect)="establecimiento.UBI_CODUBI = $event" [model]="establecimiento.UBI_CODUBI">
          </select-search>
        </div>
        <div class="col s12 m12 l4">
          <select-search [label]="'Zona de Venta'" [placeholder]="'Ej. LIMA CENTRO'" [name]="'ZVE_CODZVE'"
            [value]="'ZVE_CODZVE'" [description]="'ZVE_NOMZVE'" [data]="zonas"
            (cambioSelect)="establecimiento.ZVE_CODZVE = $event" [model]="establecimiento.ZVE_CODZVE">
          </select-search>
        </div>
        <div class="row">
          <div class="col s10 m10 l10">
            <h3 class="pav-dialog-title">Dias de Entrega</h3>
          </div>
          <div class="col s2 m2 l2" style="display: flex;align-items: flex-end;justify-content:right;">
            <div class="pav-container-button" (click)="agregarDiasEntrega()" style="background-color:#121D44;">
              <mat-icon svgIcon="add"></mat-icon>
            </div>
          </div>

        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSourceDiasEntrega" matSort>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                <div class="pav-btns-container">
                  <div class="pav-btn-circle-delete" (click)="quitarDiasEntrega(row,i)" matTooltip="Eliminar">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="dia">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Día</th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container>
                  <mat-form-field class="mat-form-field-item">
                    <mat-label></mat-label>
                    <mat-select required [(ngModel)]="row.dia_coddia" [name]="'dia_coddia'+i"
                      (selectionChange)="seleccionarDiasEntrega($event, row)">
                      <mat-option *ngFor="let dia of diasEntrega" [value]="dia.dia_coddia">{{dia.dia_desesp}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <!-- <ng-container *ngIf="!row.isEditing">
                  {{row.dia_desesp}}
                </ng-container> -->
              </td>
            </ng-container>
            <ng-container matColumnDef="datosAdicionales">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Datos Adicionales</th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="datosAdicionales">
                <ng-container *ngIf="row.isEditing ">
                  <mat-checkbox [(ngModel)]="row.isAditionalActive" [name]="'isAditionalActive'" class="example-margin"
                    style="display: flex;flex-direction: column;align-items: center;justify-content:center;"
                    (change)="hayDatosAdicionales($event,row)"></mat-checkbox>
                </ng-container>

              </td>
            </ng-container>
            <ng-container matColumnDef="hora_inicio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Hora de Inicio</th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container *ngIf="row.isAditionalActive">
                  <input type="time" required name="row.dia_horini" [(ngModel)]="row.dia_horini">
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="hora_maxima">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Hora Máxima</th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container *ngIf="row.isAditionalActive">
                  <input type="time" required name="row.dia_horfin" [(ngModel)]="row.dia_horfin">
                </ng-container>

              </td>
            </ng-container>
            <ng-container matColumnDef="referencia">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Referencia</th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <ng-container *ngIf="row.isAditionalActive">
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Referencia" [(ngModel)]="row.dia_refest" name="dia_refest">
                  </mat-form-field>
                </ng-container>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDiasEntrega"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDiasEntrega;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6">No se encontraron registros</td>
            </tr>
          </table>
        </div>
      </div>

    </div>

  </mat-card>
  <pav-form-actions [disabled]="loaderData" [btnName]="'Guardar'" [isLoading]="loaderReg"
    urlBack="/modulo-ventas/clientes" (clickMasOpciones)="verMasOpciones()">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
