import { ColorFormComponent, ColorListComponent } from "./color";
import { ConductorAlmacenFormComponent, ConductorListComponent } from "./conductor";
import { ConfigureMotiveCreateComponent } from "./configure-motive/configure-motive-create/configure-motive-create.component";
import { ConfigureMotiveListComponent } from "./configure-motive/configure-motive-list/configure-motive-list.component";
import { UbicacionFormComponent, UbicacionListComponent } from "./ubicacion";
import { UnidadTransporteFormComponent, UnidadTransporteListComponent } from "./unidad-transporte";
import { ProductoCompuestoFormComponent, ProductoCompuestoListComponent } from "./producto-compuesto";
import { ProductoModule } from "./producto/producto.module";
import { ModeloFormComponent, ModeloListComponent } from "./modelo";

export const maestrosComponentAlmacen = [
  ConfigureMotiveCreateComponent,
  ConfigureMotiveListComponent,
  ColorFormComponent,
  ColorListComponent,
  UbicacionListComponent,
  UbicacionFormComponent,
  ConductorListComponent,
  ConductorAlmacenFormComponent,
  UnidadTransporteFormComponent,
  UnidadTransporteListComponent,
  ProductoCompuestoFormComponent,
  ProductoCompuestoListComponent,
  ModeloListComponent,
  ModeloFormComponent
]

export const maestrosModuleAlmacen = [
  ProductoModule
]
