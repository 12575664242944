import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";

@Component({
  selector: 'modelo-list',
  templateUrl: './modelo-list.component.html',
  styleUrls: ['./modelo-list.component.scss']
})
export class ModeloListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'Cod_Vendedor', 'Nom_vendedor', 'Telef_vendedor', 'Mail_Vendedor', 'Usuario_Reg'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  modelos$: Subscription;

  constructor(
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerModelos();
    })
  }

  obtenerModelos(): void {

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }


  ngOnDestroy(): void {
    unsubscribeSubscription([this.loading$])
  }

}
