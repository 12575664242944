<app-pavso-title-section [title]="'Saldo inicial bancos - conciliación bancaria'" [module]="'Utilitarios'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="saldo(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l1">
                  <mat-form-field>
                      <mat-label>Año</mat-label>
                      <input type="text" matInput placeholder="Año" name="anio" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l1">
                  <mat-form-field>
                      <mat-label>Mes</mat-label>
                      <input type="text" matInput placeholder="Mes" name="mes" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Cuenta Contable</mat-label>
                      <input type="text" matInput placeholder="Cuenta Contable" name="cuenta_contable" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Debe Soles</mat-label>
                      <input type="text" matInput placeholder="Debe Soles" name="debe_soles" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Debe Dolares</mat-label>
                      <input type="text" matInput placeholder="Debe Dolares" name="debe_dolares" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Haber Soles</mat-label>
                      <input type="text" matInput placeholder="Haber Soles" name="haber_soles" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Haber Soles</mat-label>
                      <input type="text" matInput placeholder="Haber Dolares" name="haber_dolares" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
          </div>

      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
