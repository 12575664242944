import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[configText]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfigTextDirective),
      multi: true
    }
  ]
})
export class ConfigTextDirective implements ControlValueAccessor {
  @Input() configText: string[];

  private onChange = (value: string) => {};
  private onTouched = () => {};

  constructor(private el: ElementRef) {}

  writeValue(value: string): void {
    if(value == null || value == undefined) return;
    this.el.nativeElement.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event'])
  @HostListener('change', ['$event'])
  onInput(evento: Event) {
    const input = evento.target as HTMLInputElement;
    let value = input.value;
    console.log("soy el valor", value)
    if (value){
      if (this.configText.includes('numeros') && this.configText.includes('letras')) {
        // Permitir números y letras
        value = value.replace(/[^a-zA-Z0-9]/g, '');
      } else if (this.configText.includes('numeros')) {
        // Solo permitir números
        value = value.replace(/[^0-9]/g, '');
      } else if (this.configText.includes('letras')) {
        // Permitir letras
        value = value.replace(/[^a-zA-Z]/g, '');
      }
  
      if (this.configText.includes('upper')) {
        value = value.toUpperCase();
      } else if (this.configText.includes('lower')) {
        value = value.toLowerCase();
      }
  
      input.value = value;
      this.onChange(value);
      this.onTouched();
    } else {
      this.onChange('');
      this.onTouched();
    }
  }
  
}
