<app-pavso-title-section [title]="'Grupo flujo de caja'" [module]="'Caja y bancos'" [estado]="grupoFlujoCaja.CFU_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="grupoFlujoCaja.CFU_CODCFU" matInput placeholder="Código" name="CFU_CODCFU" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="grupoFlujoCaja.CFU_DESCRI" matInput placeholder="Descripción" name="CFU_DESCRI" required>
          </mat-form-field>
        </div>
      </div>

    </mat-card>

  </div>

  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-cajabanco/grupos-flujo-de-caja">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
