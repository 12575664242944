import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { generarFormatoParte } from '@utils/pdfmaker/builder/almacen/operaciones/kardex.pdfmaker';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { FiltroMovimientos } from 'src/app/models/almacen/filtro-movimientos';
import { ConfigurationService, AlmacenService as ModuloAlmacenService } from 'src/app/services';
import { KardexService } from 'src/app/services/api/almacen/operaciones/kardex.service';
import { AlmacenService } from 'src/app/services/api/almacen/tabla-apoyo/almacen.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';

@Component({
  selector: 'app-kardex-list',
  templateUrl: './kardex-list.component.html',
  styleUrls: ['./kardex-list.component.css']
})
export class KardexListComponent implements OnInit, OnDestroy {
  title: string = "";

  displayedColumns: string[] = ['acciones', 'ALM_DESCRI', 'mac_nummac', 'mac_fecmac', 'mmo_codmmo', 'mmo_descri', 'cli_nomcli', 'mac_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loading$: Subscription;
  periodo$: Subscription;
  movimiento$: Subscription;
  zip$: Subscription;
  establecimiento$: Subscription;
  config$: Subscription;
  kardex$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loaderData: boolean;
  movimientos: any[] = [];
  almacenes: any[] = [];
  motivos: any[] = [];
  auxiliares: any[] = [];

  filtro: FiltroMovimientos;

  anioPeriodo: string;
  mesPeriodo: string;

  establecimiento: string;
  logo: string;

  constructor(
    private _snackBarService: SnackBarService,
    private _kardexService: KardexService,
    private _moduloAlmacenService: ModuloAlmacenService,
    private _almacenService: AlmacenService,
    private _configurationService: ConfigurationService,
    private _clienteService: ClienteService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
  ) {
    this.filtro = new FiltroMovimientos();

    this.dataSource = fillTable([], this.paginator, this.sort);
    this._activatedRoute.data.subscribe(data => this.title = data.titulo);

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
      console.log('codigo de establecimiento', this.establecimiento)
    })

    this.periodo$ = this.store.select('period').subscribe(({ month, year }) => {
      this.anioPeriodo = year;
      this.mesPeriodo = month;
    });

    // this.config$ = this.store.select('config').subscribe(state => {
    //   this.logo = state.logo;
    // })


    (this._router.url == '/modulo-almacen/parte-de-salida') ? this.filtro.tipoMovimiento = 'SAL' : this.filtro.tipoMovimiento = 'ING';
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadMaestros();
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
    })
  }

  almacenes$: Subscription;
  motivos$: Subscription;
  clientes$: Subscription;
  kardexs$: Subscription;

  ordenarArregloJSONxLlave(key: string, arr: any[]) {
    const ordered = arr.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
    });

    return ordered;
  }

  loadMaestros(): void {
    this.loaderData = true;
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.almacenes$ = this._almacenService.obtenerAlmacenes().subscribe(
      almacenes => {

        this.almacenes = this.ordenarArregloJSONxLlave('ALM_DESCRI', almacenes);

        // filtrar almacenes por tienda
        if (this.establecimiento && this.establecimiento.trim() != '') {
          this.almacenes = this.almacenes.filter(item => item.TIE_CODTIE == this.establecimiento);
        }

        this.motivos$ = this._moduloAlmacenService.obtenerMotivos().subscribe(
          motivos => {
            this.motivos = this.ordenarArregloJSONxLlave('mmo_descri', motivos);

            this.clientes$ = this._clienteService.obtenerClientes().subscribe(
              clientes => {
                this.auxiliares = this.ordenarArregloJSONxLlave('cli_nomcli', clientes);
                this.kardexs$ = this._kardexService.listarKardex(this.anioPeriodo, this.mesPeriodo, this.filtro).subscribe(
                  kardex => {


                    console.log('partes', kardex)

                    console.log('this.establecimiento', this.establecimiento)
                    console.log('this.establecimiento', this.establecimiento.trim() != '')
                    if (this.establecimiento.trim() != '') {
                      this.movimientos = kardex.filter(item => item.almacen.tie_codtie == this.establecimiento);
                    } else {
                      console.log('sin filtro')
                      this.movimientos = kardex;
                    }

                    if (kardex.length > 0) {
                      kardex.forEach(item => {
                        item['ALM_DESCRI'] = item.almacen.ALM_DESCRI;
                        item['mmo_descri'] = item.motivo.mmo_descri;
                      });
                    }


                    this.dataSource = fillTable(this.movimientos, this.paginator, this.sort);
                    this.loaderData = false;
                  },
                  error => {
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this.loaderData = false;
              }
            )
          },
          error => {
            this.loaderData = false;
          }
        )
      },
      error => {
        this.loaderData = false;
      }
    )

  }

  obtenerMovimientos(): void {
    this.loaderData = true;
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.movimiento$ = this._kardexService.listarKardex(this.anioPeriodo, this.mesPeriodo, this.filtro).subscribe(
      response => {
        if (response.length > 0) {
          response.forEach(item => {
            item['ALM_DESCRI'] = item.almacen.ALM_DESCRI;
            item['mmo_descri'] = item.motivo.mmo_descri;
          });
        }
        console.log('movimientos', response)
        this.movimientos = response;
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  editarMovimiento(year, month, almacen, tipo, numeroParte) {
    if (this.filtro.tipoMovimiento == "ING") {
      this._router.navigate(['/modulo-almacen/kardex', year, month, almacen, tipo, numeroParte]);
      return;
    }

    this._router.navigate(['/modulo-almacen/kardex', year, month, almacen, tipo, numeroParte])

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.periodo$,
      this.zip$,
      this.almacenes$,
      this.motivos$,
      this.clientes$,
      this.movimiento$,
      this.kardexs$,
      this.establecimiento$,
      this.kardex$
    ])

  }

  verPdf(ano_codano, mes_codmes, alm_codalm, tdo_codtdo, mac_nummac): void {
    const headers = {
      ruc: this._configurationService.obtenerClienteId(),
      logo: this.logo,
      motivo: '',// this.kardex.mmo_codmmo,
      indExportacion: '',//this.indMotivoExportacion,
      indPedido: '',//this.indPedido,
      indOrdCompra: '',//this.indOrdCompra,
      indRegInvoice: '',//this.indRegInvoice,
      indReqMat: '',//this.indReqMat,
      indOrdProd: '',//this.indOrdProd,
      nomcia: this._configurationService.obtenerNombreCompaniaCliente(),
      dircia: '',//this.dircia,
      modalidadTraslado: '',//this.modalidadTraslado,
      glosa2: '',//this.kardex.mac_gloma2,
      ubicacion2: '',//this.ubicacionAdicional,
      coduse: this._configurationService.obtenerIdUsuario()
    }


    this.kardex$ = this._kardexService.obtenerKardex(ano_codano, mes_codmes, alm_codalm, tdo_codtdo, mac_nummac).subscribe(
      kardex => {

        generarFormatoParte(headers, kardex)

      },
      error => {
        this._snackBarService.showError('Error al obtener kardex', 'Ok');

      }
    )


  }
}
