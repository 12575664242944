import { Component, OnInit } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { reporteDaotCostos, reporteDaotIngresos } from '@utils/pdfmaker/builder/contabilidad.pdfmaker';

@Component({
  selector: 'app-interface-daot-general',
  templateUrl: './interface-daot-general.component.html',
  styleUrls: ['./interface-daot-general.component.css']
})
export class InterfaceDaotGeneralComponent implements OnInit {

  tipo: any;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;

  constructor(
    private store: Store<PavsoState>
  ) {
    this.tipo = "1";
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  generaReporte(): void {
    let response;
    let data;
    (this.tipo == "1")? reporteDaotIngresos(response, data): reporteDaotCostos(response, data);
  }

  generaReporteExcel(): void {
    let response;
    let data;
    (this.tipo == "1")? reporteDaotIngresos(response, data): reporteDaotCostos(response, data);
  }
}
