import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'pav-image-zoom-dialog',
  templateUrl: './pav-image-zoom.dialog.html',
  styleUrls: ['./pav-image-zoom.dialog.scss']
})
export class PavImageZoomDialog {

  constructor(
    public dialogRef: MatDialogRef<PavImageZoomDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  cerrar() {
    this.dialogRef.close()
  }
}
