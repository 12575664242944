import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFormatoAnoMes]'
})
export class FormatoInputPeriodoDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value.replace(/[^0-9/]/g, '');

    if (inputValue.length > 7) {
      inputValue = inputValue.slice(0, 7);
    }

    inputValue = this.formatYearMonth(inputValue);

    this.renderer.setProperty(inputElement, 'value', inputValue);
  }

  private formatYearMonth(value: string): string {
    const parts = value.split('/').filter(Boolean);

    if (parts.length > 0) {
      const year = parts[0].slice(0, 4);
      const month = parts[1] ? '/' + parts[1].slice(0, 2) : '';

      // Agregar automáticamente "/" después de los cuatro caracteres del año
      if (year.length === 4 && !month) {
        return `${year}/`;
      }

      return `${year}${month}`;
    }

    return '';
  }

}
