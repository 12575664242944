import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class AsientoContableService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerAsientosContables(codano: string, codmes: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/asientos/listar/${this._configurationService.obtenerCompaniaCliente()}/${codano}/${codmes}`
    );
  }

  obtenerAsientoContable(codano: string, codmes: string, subdiario: string, correlativo: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/asientos/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codano}/${codmes}/${subdiario}/${correlativo}`
    );
  }

  registrarAsientoContable(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/asientos/crear`,
      payload
    );
  }

  actualizarAsientoContable(payload): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/asientos/modificar`,
      payload
    );
  }
}
