<app-pavso-title-section [title]="'Requerimiento de pago'" [module]="'Caja y bancos'" [estado]="'1'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarRequerimiento(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Num. Requerimiento</mat-label>
            <input type="text" matInput placeholder="Num. Requerimiento" name="num_req" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha Emisión</mat-label>
            <input matInput [matDatepicker]="fechaemi">
            <mat-datepicker-toggle matSuffix [for]="fechaemi"></mat-datepicker-toggle>
            <mat-datepicker #fechaemi></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Req.</mat-label>
            <mat-select required name="tipo_req">
              <mat-option value="1">
                tipo req 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <div [ngStyle]="{display: 'flex'}">
            <select-search
              [label]="'Orden de compra'"
              [placeholder]="'Ej. 0000000'"
              [name]="'ocm_numocm'"
              [value]="'ocm_numocm'"
              [description]="'ocm_numocm'"
              [data]="ordenesCompra"

            ></select-search>
            <button type="button" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
              <mat-icon>picture_as_pdf</mat-icon>
            </button>
          </div>

        </div>

        <div class="col s12 m12 l3">
          <select-search
            [label]="'Proveedor'"
            [placeholder]="'Ej. PERALTA'"
            [name]="'cli_codcli'"
            [value]="'cli_codcli'"
            [description]="'cli_nomcli'"
            [data]="proveedores"
          ></select-search>
        </div>
        <div class="col s12 m12 l3">
          <select-search
            [label]="'Pagar a la orden de'"
            [placeholder]="'Ej. PERALTA'"
            [name]="'cli_codcli'"
            [value]="'cli_codcli'"
            [description]="'cli_nomcli'"
            [data]="ordenDe"

          ></select-search>

        </div>

        <div class="col s12">
          <fieldset>
            <legend>Euros</legend>
            <div class="row">
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Moneda Original</mat-label>
                  <input type="text" matInput placeholder="Moneda Original" name="moneda_original" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Importe Euros</mat-label>
                  <input type="text" matInput placeholder="Importe Euros" name="importe_euros" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>T.C. Euros</mat-label>
                  <input type="text" matInput placeholder="T.C. Euros" name="tc_euros" required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

        </div>

      </div>

      <div class="row" [ngStyle]="{marginTop: '15px'}">
        <div class="col s12 m12 l3">
          <app-currency-select [moneda]="'SO'" [disable]="loaderData"></app-currency-select>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha Solicitada de Pago</mat-label>
            <input matInput [matDatepicker]="fechasol">
            <mat-datepicker-toggle matSuffix [for]="fechasol"></mat-datepicker-toggle>
            <mat-datepicker #fechasol></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Importe</mat-label>
            <input type="text" matInput placeholder="Importe" name="importe" required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Centro de costos</mat-label>
            <mat-select required name="categoria">
              <mat-option *ngFor="let centro of centros" [value]="centro.ccs_codccs">
                {{centro.ccs_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Referencia</mat-label>
            <input type="text" matInput placeholder="Referencia" name="referencia" required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Solicitado por</mat-label>
            <mat-select required name="solicitado">
              <mat-option *ngFor="let cliente of clientes" [value]="cliente.cli_codcli">
                {{cliente.cli_nomcli}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Autorización</mat-label>
            <mat-select required name="autorizacion">
              <mat-option value="1">
                autorizacion 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Aprobado por</mat-label>
            <mat-select required name="aprobado_por">
              <mat-option value="1">
                aprobado por por 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fec. Aprobacion</mat-label>
            <input matInput [matDatepicker]="fechaapro">
            <mat-datepicker-toggle matSuffix [for]="fechaapro"></mat-datepicker-toggle>
            <mat-datepicker #fechaapro></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l12 xl3">
          <h3>APROBADO</h3>
        </div>

        <div class="col s12 m12 l12 xl3">
          <button matTooltip="Ver cambio del requerimiento" mat-mini-fab color="primary" type="button" (click)="logsRequerimiento()">
            <mat-icon>list</mat-icon>
          </button>
        </div>

      </div>

    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l12 xl3">
          <mat-checkbox class="example-margin">Detracción</mat-checkbox>

        </div>
        <div class="col s12 m12 l3">

          <select-search
              [label]="'Tipo'"
              [placeholder]="'Ej. DETRACCIÓN'"
              [name]="'IRI_CODIRI'"
              [value]="'IRI_CODIRI'"
              [description]="'IRI_DESCRI'"
              [data]="tiposRetencion"

            ></select-search>

        </div>

        <div class="col s12 m12 l12 xl3">
          <div class="col s6">
            <mat-form-field>
              <mat-label>% Det.</mat-label>
              <input type="text" matInput placeholder="% Det." name="det_1" required>
            </mat-form-field>
          </div>
          <div class="col s6">
            <mat-form-field>
              <mat-label>Imp. Det.</mat-label>
              <input type="text" matInput placeholder="Imp. Det." name="det_2" required>
            </mat-form-field>
          </div>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Fecha Pago Tesorería</mat-label>
            <input matInput [matDatepicker]="fechapagotes">
            <mat-datepicker-toggle matSuffix [for]="fechapagotes"></mat-datepicker-toggle>
            <mat-datepicker #fechapagotes></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales" (clickNuevo)="registroNuevo()">
      </div>

    </pav-form-actions>

  </form>
</div>
