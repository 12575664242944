import { Injectable, OnDestroy } from "@angular/core";
import { HeaderReport } from "@data/interfaces/headers.report";
import { NominaService } from "../../api/nomina.service";
import * as pdfBuilder from '@utils/pdfmaker/builder/planilla.pdfmaker';
import { SnackBarService } from "@shared/services/snackbar.service";
import { Subscription } from "rxjs";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { number2month } from "@utils/conversions/number2month.conversion";
import { PlanillaExcelService } from "../../reporte-excel/planilla-excel.service";
import { ConfigurationService } from "../../configuration.service";
import { formatDateWithSlash } from "@utils/formats/date.format";

@Injectable({
  providedIn: 'root'
})
export class NominaReportService implements OnDestroy {

  reporte$: Subscription;

  constructor(
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private ete: PlanillaExcelService,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @returns
   */
  verReporteDependiente(reportSelected: string, headers: HeaderReport, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "PLANILLA_DRES":
          this.reporte$ = this._nominaService.obtenerInformeDependienteEdades(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.totalRegistros == 0) {
                this._snackBarService.showError('No se encontraron registros para este período o para el filtro.', 'Ok');
                reject(false);
                return;
              }
              pdfBuilder.generarPDFDependientesRangoEdadesSexo(headers, response);
              resolve(true);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe", "OK");
              reject(false);
            }
          )
          break;
        case "PLANILLA_DC":
          this.reporte$ = this._nominaService.obtenerInformeDependienteColaborador(headers.year, headers.month, payload).subscribe(
            response => {
              console.log('deependieentes colaborador', response)
              if (response.length > 0) {
                pdfBuilder.generarPDFDependientesColaborador(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError("No se encontró información para este período o para el filtro.", "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporteGestion(reportSelected: string, headers: HeaderReport, payload: any, otherData: any): Promise<any> {
    const [monthIni, yearIni, monthFin, yearFin] = ["01", Number(headers.year), headers.month, Number(headers.year)];

    return new Promise((resolve, reject) => {

      switch (reportSelected) {
        case "PLANILLA_CV":
          this.reporte$ = this._nominaService.obtenerInformeControlVacacional(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFControlVacacional(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError("No se encontró información para este período", "OK")
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al Obtener el Control Vacacional", "OK");
              reject(false);
            }
          )
          break;
        case "PLANILLA_CVF":

          this.reporte$ = this._nominaService.obtenerInformeControlVacacionalFisico(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFControlVacacionalFisico(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError("No se encontró información para este período", "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener el Control Vacional Físico", "OK");
              reject(false);
            }
          )
          break;
        case "PLANILLA_ACC":
          const other = {
            periodoini: `${yearIni} / ${monthIni}`,
            periodofin: `${yearFin} / ${monthFin}`,
          };

          this.reporte$ = this._nominaService.obtenerInformeAcumuladoConceptoTrabajador(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFAcumuladosConceptoColaborador(headers, other, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError("No se encontró información para este período", "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe de acumulado concepto trabajador", "OK")
              reject(false);
            }
          )
          break;
        case "PLANILLA_DEL":
          this.reporte$ = this._nominaService.obtenerInformeDiasEfectivosLaborales(headers.year, headers.month).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFDiasEfectivosLaborales(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError("No se encontró información para este período", "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe", "OK");
              reject(false);
            }
          )
          break;
        case "PLANILLA_ACCA":
          this.reporte$ = this._nominaService.obtenerInformeAcumuladoConceptoTrabajadorAnual(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFAcumuladosConceptoColaboradorAnual(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError("No se encontró información para este período", "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener Informe Acumulados de Concepto Colaborador Anual", "OK");
              reject(false);
            }
          )
          break;
        case "PLANILLA_ACCol":
          const others = {
            periodoini: `${yearIni} / ${monthIni}`,
            periodofin: `${yearFin} / ${monthFin}`,
          };
          this.reporte$ = this._nominaService.obtenerInformeAcumuladoColaboradorConcepto(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFAcumuladosColaboradorConcepto(headers, others, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError("No se encontró información para este período", "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe de acumulado concepto trabajador", "OK")
              reject(false);
            }
          )
          break;
        case "PLANILLA_ACG":
          this.reporte$ = this._nominaService.obtenerInformeAcumuladoConceptoGrupo(`${yearIni}${monthIni}`, `${yearFin}${monthFin}`, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFAcumuladoConceptoGrupo(headers, otherData, response);
                resolve(true)
                return;
              }
              this._snackBarService.showError("No se encontró información para este período", "OK");
              reject(false);
            },
            _ => {
              this._snackBarService.showError("Error al obtener informe", "OK");
              reject(false);
            }
          )
          break;
        case "PLANILLA_C":
          this.reporte$ = this._nominaService.obtenerReporteContrato(payload).subscribe(
            response => {
              let data: any;
              let title: string = "Listado de Contratos";
              if (otherData.estadoContrato == "TODOS") data = response.data;

              if (otherData.estadoContrato == "VENCIDOS") {
                data = response.defeated;
                title = "Contratos Vencidos";
              }
              if (otherData.estadoContrato == "POR_VENCER") {
                data = response.to_expire;
                title = "Contratos por Vencer"
              }

              const anotherData = { title }

              if (data.length > 0) {
                pdfBuilder.generarPDFContratos(headers, data, anotherData);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontraron Contratos ${otherData.estadoContrato}`, 'OK');
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de contratos', 'OK');
              reject(false);
            }
          )
          break;
        case "PLANILLA_TDL":
          this.reporte$ = this._nominaService.obtenerInformeDocumentoLegajo(payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFTipoDocumentoLegajo(headers, response)
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontraron datos para el informe`, 'OK');
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de documentos legajo', 'OK');
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    });
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporteMensual(reportSelected: string, headers: HeaderReport, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {

      const [monthIni, yearIni, monthFin, yearFin] = ["01", Number(headers.year), headers.month, Number(headers.year)];

      switch (reportSelected) {
        case "PLANILLA_PRA":

          this.reporte$ = this._nominaService.obtenerInformePDFPlanillaRemuneracionAFP(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFRemuneracionPorAFP(headers, otherData, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError("No se encontró información para este período", "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener Informe", "OK");
              reject(false);
            }
          )
          break;

        case "PLANILLA_PL":

          this.reporte$ = this._nominaService.obtenerInformePDFPlanillaLineal(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.data.length > 0) {
                pdfBuilder.generarPDFPlanillaLineal(headers, otherData, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            _ => {
              this._snackBarService.showError("Error al obtener informe PDF de planilla lineal", "OK")
              reject(false);
            }
          )
          break;

        case "PLANILLA_PCAA":

          this.reporte$ = this._nominaService.obtenerInformePDFPlanillaConceptoAfectoAFP(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.data.length > 0) {
                pdfBuilder.generarPDFPlanillaDeConceptoAfectoAFP(headers, otherData, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener Informe", "OK");
              reject(false);
            }
          )
          break;

        case "PLANILLA_RP":

          this.reporte$ = this._nominaService.obtenerInformePDFResumenPlanilla(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFResumenDePlanilla(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe", "OK");
              reject(false);
            }
          )
          break;

        case "PLANILLA_RPL":

          this.reporte$ = this._nominaService.obtenerInformePDFResumenPlanillaLiquidados(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFResumenPlanillaLiquidados(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener informe", "OK");
              reject(false);
            }
          )
          break;

        default:
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporteNomina(reportSelected: string, headers: HeaderReport, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "PLANILLA_BP":
          this.reporte$ = this._nominaService.obtenerInformePDFBoletaPago(`${headers.year}${headers.month}`, `${Number(headers.year) - 1}${Number(headers.month) - 1}`, otherData.tipoNomina, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFBoletaPago(headers, response);
                resolve(true);
                return;
              }

              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, 'OK');
              reject(false)
            },
            error => {
              this._snackBarService.showError('Error al obtener Informe PDF de Boleta de Pago', 'OK');
              reject(false)
            }
          )
          break;
        case "PLANILLA_PC":
          if (!otherData.secNomina) {
            this._snackBarService.showError("Seleccionar la secuencia de nómina", "OK");
            reject(false)
            return;
          }
          this.reporte$ = this._nominaService.obtenerInformePDFPlanillaCalculada(`${headers.year}${headers.month}${otherData.tipoNomina}${otherData.secNomina}`).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.obtenerPDFPlanillaCalculada(headers, response)
                resolve(true)
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false)
            }, // USAR EL SERVICIO DE BOLETA DE PAGO,
            error => {
              this._snackBarService.showError("Error al obtener Informe PDF de Planilla Calculada", "OK")
              reject(false)
            }
          )
          break;
        case "PLANILLA_NP":
          if (otherData.secNomina) {

            this.reporte$ = this._nominaService.obtenerInformePDFNetAPagar(`${headers.year}${headers.month}${otherData.tipoNomina}${otherData.secNomina}`, payload).subscribe(
              response => {
                if (response.length > 0) {
                  pdfBuilder.obtenerPDFNetoAPagar(headers, otherData, response);
                  resolve(true)
                  return;
                }
                this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
                reject(false)
              },
              error => {
                this._snackBarService.showError("Error al obtener Informe de Neto a Pagar", 'OK');
                reject(false)
              }
            )
            return;
          }
          this._snackBarService.showError("Seleccione la secuencia de nómina", "OK");
          reject(false)
          break;
        case "PLANILLA_RRC":
          if (otherData.secNomina) {

            this.reporte$ = this._nominaService.obtnerInformePDFRevisaRentaCalculada(`${headers.year}${headers.month}${otherData.tipoNomina}${otherData.secNomina}`, payload).subscribe(
              response => {
                console.log('renta calculada', response)
                if (response.length > 0) {
                  pdfBuilder.obtenerPDFRevisaRenta(headers, otherData, response);
                  resolve(true)
                  return;
                }
                this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
                reject(false)
              },
              error => {
                this._snackBarService.showError("Error al obtener Informe Revisa Renta Calculada", "OK");
                reject(false)
              }
            )
            return;
          }
          this._snackBarService.showError("Seleccione la secuencia de nómina", "OK");
          reject(false)
          break;
        case "PLANILLA_RC":

          this.reporte$ = this._nominaService.obtenerRemuneracionesTrabajador(headers.year, headers.month, payload).subscribe(
            response => {
              console.log('remuneraciones colaborador', response)
              if (response.data.length > 0) {
                pdfBuilder.obtenerPDFRemuneracionesAlTrabajador(headers, response);
                resolve(true)
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false)
            },
            _ => {
              this._snackBarService.showError("Error al obtener Informe de Remuneraciones de Trabajadores", "OK");
              reject(false)
            }
          )
          break;
        case "PLANILLA_PLN":
          if (!otherData.secNomina) {
            this._snackBarService.showError("Seleccionar secuencia nómina", "OK");
            reject(false);
            return;
          }
          this.reporte$ = this._nominaService.obtenerInformePDFPlanillaLinealNomina(`${headers.year}${headers.month}${otherData.tipoNomina}${otherData.secNomina}`, payload).subscribe(
            response => {
              if (response.data.length > 0) {
                pdfBuilder.generarPDFPlanillaLinealNomina(headers, otherData, response);
                resolve(true)
                return;
              }

              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener el reporte de planilla lineal nómina", "OK");
              reject(false);
            }
          )
          break;

        case "PLANILLA_PRC":
          this.reporte$ = this._nominaService.obtenerInformePDFPlanillaResumenPorConcepto(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFPlanillaResumenPorConcepto(headers, response);
                resolve(true)
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            _ => {
              this._snackBarService.showError("Error al obtener Informe de Planilla Resumen por Concepto", 'OK');
              reject(false);
            }
          )
          break;
        case "PLANILLA_GP":
          this.reporte$ = this._nominaService.obtenerInformeGratificacionesAPagar(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFGratificacionAPagar(headers, response);
                resolve(true)
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener Informe de Gratificaciones a Pagar", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporteRemuneracion(reportSelected: string, headers: HeaderReport, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {

      const [monthIni, yearIni, monthFin, yearFin] = ["01", Number(headers.year), headers.month, Number(headers.year)]
      switch (reportSelected) {
        case "PLANILLA_HRC":

          this.reporte$ = this._nominaService.obtenerInformeRemuneracionesNomina(`${yearIni}${monthIni}`, `${yearFin}${monthFin}`, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFHistorialRemuneracionColaborador(headers, otherData, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener Informe de Remuneraciones al Colaborador", "OK");
              reject(false);
            }
          )
          break;
        case "PLANILLA_CRP":
          this.reporte$ = this._nominaService.obtenerInformeCambiosRemuneraciones(headers.year, headers.month).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFCambiosRemuneracionPeriodo(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener Informe de Cambios de Remuneraciones", "OK")
              reject(false);
            }
          )
          break;
        case "PLANILLA_CBC":

          this.reporte$ = this._nominaService.obtenerInformeCuentasBancoTrabajador(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFCuentasBancoColaborador(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener Informe de Cuentas Banco por Trabajador", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporteInterfaceContable(reportSelected: string, headers: HeaderReport, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "PLANILLA_VTAP":
          this.reporte$ = this._nominaService.obtenerReporteValidacionTrabajadorAsientoPlanilla(headers.year, headers.month, payload).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFValidaciónTrabajadorAsientoPlanilla(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de validadión de trabajador asiento planilla', 'OK');
              reject(false);
            }
          )
          break;
        case "PLANILLA_VPV":
          if (otherData.rbAnualMensual == "M") {
            this.reporte$ = this._nominaService.obtenerReporteProvisionVacaciones(headers.year, headers.month, payload).subscribe(
              response => {
                if (response.length > 0) {
                  pdfBuilder.generarPDFValidacionProvisionVacaciones(headers, response);
                  resolve(true);
                  return;
                }
                this._snackBarService.showError(`No se encontró información para este período ${headers.year} ${headers.month}`, "OK");
                reject(false);
              },
              error => {
                this._snackBarService.showError("Error al obtener reporte de provisión vacaciones", 'OK');
                reject(false);
              }
            )
          } else {
            this.reporte$ = this._nominaService.obtenerReporteProvisionVacacionesAnual(headers.year, payload).subscribe(
              response => {
                if (response.length > 0) {
                  pdfBuilder.generarPDFValidacionProvisionVacacionesAnual(headers, otherData, response);
                  resolve(true);
                  return;
                }
                this._snackBarService.showError(`No se encontró información para este período ${headers.year} ${headers.month}`, "OK");
                reject(false);
              },
              error => {
                this._snackBarService.showError("Error al obtener reporte de provisión vacaciones anual", 'OK');
                reject(false);
              }
            )
          }

          break;
        case "PLANILLA_VPC":
          if (otherData.rbAnualMensual == "M") {
            this.reporte$ = this._nominaService.obtenerReporteProvisionCTS(headers.year, headers.month, payload).subscribe(
              response => {
                if (response.length > 0) {
                  pdfBuilder.generarPDFValidacionProvisionCTS(headers, response);
                  resolve(true);
                  return;
                }
                this._snackBarService.showError(`No se encontró información para este período ${headers.year} ${headers.month}`, "OK");
                reject(false);
              },
              error => {
                this._snackBarService.showError("Error al obtener reporte de provisión CTS", 'OK');
                reject(false);
              }
            )
          } else {
            this.reporte$ = this._nominaService.obtenerReporteProvisionCTSAnual(headers.year, payload).subscribe(
              response => {
                if (response.length > 0) {
                  pdfBuilder.generarPDFValidacionProvisionCTSAnual(headers, otherData, response);
                  resolve(true);
                  return;
                }
                this._snackBarService.showError(`No se encontró información para este período ${headers.year} ${headers.month}`, "OK");
                reject(false);
              },
              error => {
                this._snackBarService.showError("Error al obtener reporte de provisión CTS Anual", 'OK');
                reject(false);
              }
            )
          }
          break;
        case "PLANILLA_VPG":
          if (otherData.rbAnualMensual == "M") {
            this.reporte$ = this._nominaService.obtenerReporteProvisionGratificacion(headers.year, headers.month, payload).subscribe(
              response => {
                if (response.length > 0) {
                  pdfBuilder.generarPDFValidacionProvisionGratificacion(headers, response);
                  resolve(true);
                  return;
                }
                this._snackBarService.showError(`No se encontró información para este período ${headers.year} ${headers.month}`, "OK");
                reject(false);
              },
              error => {
                this._snackBarService.showError("Error al obtener reporte de provisión gratificación", 'OK');
                reject(false);
              }
            )
          } else {
            this.reporte$ = this._nominaService.obtenerReporteProvisionGratificacionAnual(headers.year, payload).subscribe(
              response => {
                if (response.length > 0) {
                  pdfBuilder.generarPDFValidacionProvisionGratificacionAnual(headers, otherData, response);
                  resolve(true);
                  return;
                }
                this._snackBarService.showError(`No se encontró información para este período ${headers.year} ${headers.month}`, "OK");
                reject(false);
              },
              error => {
                this._snackBarService.showError("Error al obtener reporte de provisión gratificación anual", 'OK');
                reject(false);
              }
            )
          }
          break;
        // case "PLANILLA_APGCV":
        //   pdfBuilder.generarPDFAcumuladoProvisionCTS(headers, null);
        //   resolve(true);
        //   break;
        default:
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   */
  verReporteDinamico(reportSelected: string, headers, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {

        case "PLANILLA_MC":
          this.reporte$ = this._nominaService.obtenerReporteColaboradores(headers.codano, headers.codmes, payload).subscribe(
            response => {
              if (response.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener información', 'Ok');
                reject(false);
                return;
              }

              let dataForExcel = [];

              response.forEach((row: any) => {
                dataForExcel.push(Object.values(row))
              })

              let reportData = {
                title: `Informe de colaboradores - ${number2month(headers.codmes)} ${headers.codano}`,
                data: dataForExcel,
                headers: Object.keys(response[0]),
                razonSocial: this._configurationService.obtenerNombreCompaniaCliente(),
                ruc: this._configurationService.obtenerClienteId()
              }

              this.ete.generateDinamicoMaestroColaboradores(reportData);
              resolve(true);

            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de colaboradores", "OK");
              reject(false);
            }
          )
          break;
        case "PLANILLA_PM":

          this.reporte$ = this._nominaService.obtenerReportePlanillaMensual(headers.codano, headers.codmes, payload).subscribe(
            response => {

              if (response.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              if (response.data.length > 0) {
                const contentReport = {
                  title: `Planilla Mensual ${headers.codano} ${headers.codmes}`,
                  client: `${this._configurationService.obtenerNombreCompaniaCliente().trim()}`,
                  date: formatDateWithSlash(new Date()),
                  header: [],
                  data: response
                }
                this.ete.generateDinamicReportPlanillaMensual(contentReport);
                resolve(true);
                return;
              }

            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de planilla mensual", "OK");
              reject(false);
            }
          )

          break;
        case "PLANILLA_PS":

          if (!headers.secNomina) {
            this._snackBarService.showError('Seleccionar una secuencia', 'OK');
            reject(false);
            return;
          }
          this.reporte$ = this._nominaService.obtenerReportePlanillaSecuencia(`${headers.codano}${headers.codmes}${headers.tipNomina}${headers.secNomina}`, payload).subscribe(
            response => {

              if(response.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              if (response.data.length > 0) {
                const contentReport = {
                  title: `Planilla Secuencia ${headers.codano}${headers.codmes}${headers.tipoNomina}${headers.secNomina}`,
                  client: `${this._configurationService.obtenerNombreCompaniaCliente().trim()}`,
                  date: formatDateWithSlash(new Date()),
                  header: [],
                  data: response
                }
                this.ete.generateDinamicReportPlanillaSecuencia(contentReport);
                resolve(true);
                return;
              }

            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de planilla secuencia', 'OK');
              reject(false)
            }
          )
          break;
        case "PLANILLA_HP":

          this.reporte$ = this._nominaService.obtenerReporteProvisionVacaciones(headers.codano, headers.codmes, payload).subscribe(
            response => {

              if(response.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              if (response.length > 0) {
                const contentReport = {
                  title: `Historial de Provisiones Período ${headers.codano} ${headers.codmes}`,
                  client: `${this._configurationService.obtenerNombreCompaniaCliente().trim()}`,
                  date: formatDateWithSlash(new Date()),
                  header: Object.keys({
                    "CÓDIGO": null,
                    "COLABORADOR": null,
                    "REMUNERACIÓN CONTABLE": null,
                    "DÍAS COMPUTABLES": null,
                    "IMPORTE PROVISIÓN": null,
                    "CUENTA GASTO": null,
                    "CENTRO DE COSTO": null,
                    "CUENTA PROVISIÓN": null,
                  }),
                  data: response.map(item => {
                    return Object.values({
                      cli_codcli: item.cli_codcli,
                      cli_nomcli: item.cli_nomcli,
                      npv_impcom: item.npv_impcom,
                      npv_diacom: item.npv_diacom,
                      npv_impprv: item.npv_impprv,
                      ccn_codccn: item.ccn_codccn,
                      ccs_codccs: item.ccs_codccs,
                      ccn_codprv: item.ccn_codprv,
                    })
                  })
                }

                this.ete.generateDinamicReportHistorialProvisiones(contentReport);
                resolve(true)
                return;
              }

            },
            error => {
              reject(false)
            }
          )
          break;
        default:
          break;
      }
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.reporte$
    ])
  }
}
