import { NgModule } from "@angular/core";
import { OperacionesComponent } from './operaciones.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { informesComponentsOperaciones } from "./pages/informes";
import { operacionesComponentsOperaciones } from "./pages/operaciones";
import { SharedModule } from "@shared/shared.module";
import { MaterialModule } from "src/app/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PanelOperacionesComponent } from "./pages/panel/panel-operaciones.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { maestrosModuleAlmacen } from "../almacen/pages/maestros";

@NgModule({
  declarations: [
    OperacionesComponent,
    PanelOperacionesComponent,
    ...informesComponentsOperaciones,
    ...operacionesComponentsOperaciones
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    ...maestrosModuleAlmacen,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class OperacionesModule {}
