import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'unidad-transporte-form',
  templateUrl: './unidad-transporte-form.component.html',
  styleUrls: ['./unidad-transporte-form.component.scss']
})
export class UnidadTransporteFormComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
