import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PlanCuenta } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";
import { IParam, generarParams } from "../../utilities/params";

@Injectable({
  providedIn: 'root'
})
export class PlanCuentaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerPlanCuentas({ params = [] }: { params?: Array<IParam> } = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/plancuentas/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    ).pipe(
      map((cuentas: any) => {
        return cuentas.map(cuenta => {
          cuenta['description'] = `${cuenta.ccn_codccn} | ${cuenta.ccn_descri} `
          cuenta['codigo'] = `${cuenta.ccn_codccn}`
          cuenta['descripcion'] = `${cuenta.ccn_descri}`
          cuenta['description_reverse'] = `${cuenta.ccn_descri} | ${cuenta.ccn_codccn}`
          return cuenta;
        })
      })
    );;
  }

  obtenerPlanCuenta(codigo: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/plancuentas/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`
    );
  }

  registrarPlanCuenta(payload: PlanCuenta): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/plancuentas/crear`,
      payload
    );
  }

  actualizarPlanCuenta(payload: PlanCuenta): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/plancuentas/modificar`,
      payload
    );
  }

  obtenerCuentaPorTipoDocumento(tipoDocumento, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/plancuentas/tipodocumento/${this._configurationService.obtenerCompaniaCliente()}/${tipoDocumento}/${moneda}`
    );
  }

}
