import { Component, Input, OnDestroy, EventEmitter, OnInit, Output, ViewChild, SimpleChanges, OnChanges, AfterViewInit, ChangeDetectionStrategy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { filterMultiCustom } from "@utils/select/select";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "select-search",
  templateUrl: "./select-search.component.html",
  styleUrls: ["./select-search.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSearchComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() appearance: MatFormFieldAppearance;
  @Input() disabled: boolean;
  @Input() required: boolean = false;
  @Input() placeholder: string;
  @Input() clase: string;
  @Input() label: string;
  @Input() value: any;
  @Input() model: any;
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() multiple: boolean = false;
  @Input() iconAction: string;
  @Input() tooltipAction: string;
  @Input() setColorSelected: boolean;
  @Input() setFocus: boolean;
  @Input() showTooltip: boolean = false;
  @Output() cambioSelect: EventEmitter<any>;
  @Output() clickAction: EventEmitter<any>;
  refreshing: boolean = false;

  @Input() data: any[] = [];
  public ctrl: FormControl = new FormControl();
  public filterCtrl: FormControl = new FormControl();
  public filtered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('inputSelect', { static: true }) inputSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  moduleStore$: Subscription;
  codigoModulo: string;
  menu: any;

  constructor() {

    this.cambioSelect = new EventEmitter<string>();
    this.clickAction = new EventEmitter<any>();

  }

  ngOnInit(): void {
    this.filtered.next(this.data.slice());

    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes', changes)
    if(changes.model) {
      // console.log('changes model', changes.model)
      this.model = changes.model.currentValue;
      this.filtered.next(this.data.slice());

      this.filterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filter();
        });

    }

    if(!changes.model) {
      // console.log('changes model', changes.model)
      this.filtered.next(this.data.slice());

      this.filterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filter();
        });

    }

    if (changes.setFocus) {
      if (changes.setFocus.currentValue) {
        // this.inputSelect.focus();
      }
    }
  }


  seleccionar(event): void {

    this.cambioSelect.emit(event.value);

  }

  getFormFieldStyle() {
    return this.tooltipAction && this.iconAction ? { width: '80%' } : {};
  }

  clickButton(): void {
    this.clickAction.emit(true);
  }

  trackByFn(index, item) {
    return item[this.value]; // Cambia 'id' por la propiedad única de tu objeto
  }

  protected filter(): void {
    this.filtered.next(
      filterMultiCustom(this.data, this.filterCtrl.value, this.description)
    )
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onFocus() {
    this.inputSelect.focus();
  }

  onSelect() {

    this.inputSelect.options.toArray();
  }

  ngAfterViewInit() {
    this.filtered.next(this.data.slice());

    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filter();
      });
    // Set focus on the mat-select element after it has been initialized
    // setTimeout(() => {
    //   this.onFocus();
    // });
  }

}
