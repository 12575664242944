import { Component, Inject, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ConsultaNroDocumento, SunatService } from "src/app/services/api/sunat/sunat.service";

@Component({
  selector: 'app-auxiliar-consulta-doc-dialog',
  template: `
    <h1 mat-dialog-title>Consulta Nro. Documento</h1>
    <form #f="ngForm" ngNativeValidate (ngSubmit)="consultar(f)" (keydown.enter)="$event.preventDefault()">
      <div mat-dialog-content [ngStyle]="{paddingTop: '10px'}">
        <mat-form-field>
          <mat-label>Nro. de Documento</mat-label>
          <input matInput [(ngModel)]="nroDocumento" name="nroDocumento">
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="cancelar()" type="button">Cancelar</button>
        <button mat-button type="submit">Consultar</button>
      </div>
    </form>
  `,
  styleUrls: ['./auxiliar-consulta-doc-dialog.component.scss']
})
export class AuxiliarConsultaDocDialog implements OnDestroy{

  nroDocumento: string;

  consultaNroDocumento$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<AuxiliarConsultaDocDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sunatService: SunatService,
    private _snackBarservice: SnackBarService
  ) {}

  cancelar(): void {
    this.dialogRef.close(null);
  }

  consultar(f: NgForm): void {
    console.log('this.data.cli_tipper', this.data.cli_tipper)
    if(this.data.cli_tipper == 'J') {
      this.consultaNroDocumento$ = this._sunatService.obtenerDatosDesdeRUC(this.nroDocumento).subscribe(
        datos => {
          this.dialogRef.close(ConsultaNroDocumento.obtenerAuxiliar(datos))
        },
        error => {
          this._snackBarservice.showError(error.error.msg, 'OK');
        }
      )
    } else {
      this.consultaNroDocumento$ = this._sunatService.obtenerDatosDesdeDNI(this.nroDocumento).subscribe(
        datos => {
          this.dialogRef.close(ConsultaNroDocumento.obtenerAuxiliar(datos))
        },
        error => {
          this._snackBarservice.showError(error.error.msg, 'OK');
        }
      )
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.consultaNroDocumento$])
  }
}
