<app-pavso-title-section [title]="'Lista de precio'" [module]="'Ventas'"
  [estado]="priceList.LPC_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFomulario(f)" (keydown.enter)="$event.preventDefault()">
    <div class="row">
      <div class="col s12 m12 l7">
        <mat-card class="mat-elevation-z0">
          <mat-form-field>
            <mat-label>Tipo de lista</mat-label>
            <mat-select required [(ngModel)]="priceList.TLP_CODTLP" name="TLP_CODTLP">
              <mat-option *ngFor="let tipo of tiposListaDeprecio" [value]="tipo.tlp_codtlp">
                {{tipo.tlp_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Num. Lista</mat-label>
            <input [(ngModel)]="priceList.LPC_CODLPC" matInput placeholder="Número" name="LPC_CODLPC" disabled>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Entra vigencia</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="priceList.LPC_FECVIG" name="LPC_FECVIG" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Observación</mat-label>
            <textarea matInput placeholder="Observación" [(ngModel)]="priceList.LPC_GLOLPC" name="LPC_GLOLPC"
              required></textarea>
          </mat-form-field>

          <div class="row">
            <div class="col s12 m12 l8 p-l-0">
              <div class="rg-container">
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button value="1" class="rb-price">Ingresar precio sin / igv</mat-radio-button>
                  &nbsp;
                  <mat-radio-button value="2" class="rb-price">Ingresar precio con / igv</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </mat-card>

      </div>

      <div class="col s12 m12 l5">
        <mat-card class="mat-elevation-z0">
          <!-- <button type="button" mat-flat-button color="primary" class="btn-actions"><mat-icon>drive_folder_upload</mat-icon> Cargar lista desde Excel/precio1.xlx</button>
                    <br> -->
          <button type="button" mat-flat-button color="primary"
            class="btn-actions"><mat-icon>content_copy</mat-icon>Copia productos</button>
          <br>
          <button type="button" mat-flat-button color="primary"
            class="btn-actions"><mat-icon>content_copy</mat-icon>Copia de otra lista</button>
          <br>
          <button type="button" mat-flat-button color="primary"
            class="btn-actions"><mat-icon>monetization_on</mat-icon>Calcula Soles / Dólares</button>
        </mat-card>

        <br>

      </div>



    </div>
    <br>

    <div>

      <button class="btn-add-product" type="button" mat-flat-button (click)="agregarProducto()">Agregar
        Producto</button>
      &nbsp;
      <button type="button" mat-flat-button (click)="fileInput.click()">Cargar Excel (.xlsx)</button>
      <input hidden (change)="onFileChange($event)" #fileInput type="file" id="file">
      <br>
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <button matTooltip="Eliminar producto" type="button" (click)="eliminarItem(row.prd_codprd)"
                mat-icon-button color="warn">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row" data-label="Código"> {{row.prd_codprd}} </td>
          </ng-container>

          <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.prd_desesp}} </td>
          </ng-container>

          <ng-container matColumnDef="um">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
            <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.um}} </td>
          </ng-container>

          <ng-container matColumnDef="precio_soles">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio S/. </th>
            <td mat-cell *matCellDef="let row" data-label="Precio S/.">
              <mat-form-field class="form-field-small form-field-cell">
                <mat-label></mat-label>
                <input type="number" matInput [(ngModel)]="row.LPD_PRENAC" placeholder=""
                  [name]="'LPD_PRENAC' + row.prd_codprd" [ngStyle]="{textAlign: 'right'}">
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="precio_vta_soles">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio Vta. S/. </th>
            <td mat-cell *matCellDef="let row" data-label="Precio Vta. S/.">
              <mat-form-field class="form-field-small form-field-cell">
                <mat-label></mat-label>
                <input type="number" matInput [(ngModel)]="row.LPD_VTANAC" placeholder=""
                  [name]="'LPD_VTANAC' + row.prd_codprd" [ngStyle]="{textAlign: 'right'}">
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="precio_dolar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio US $ </th>
            <td mat-cell *matCellDef="let row" data-label="Precio US $">
              <mat-form-field class="form-field-small form-field-cell">
                <mat-label></mat-label>
                <input type="number" matInput [(ngModel)]="row.LPD_PREDOL" placeholder=""
                  [name]="'LPD_PREDOL' + row.prd_codprd" [ngStyle]="{textAlign: 'right'}">
              </mat-form-field>

            </td>
          </ng-container>

          <ng-container matColumnDef="precio_vta_dolar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio Vta. US $ </th>
            <td mat-cell *matCellDef="let row" data-label="Precio Vta. US $">
              <mat-form-field class="form-field-small form-field-cell">
                <mat-label></mat-label>
                <input type="number" matInput [(ngModel)]="row.LPD_VTADOL" placeholder=""
                  [name]="'LPD_VTADOL' + row.prd_codprd" [ngStyle]="{textAlign: 'right'}">
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" *ngIf="!loaderProductos">No se agregaron productos </td>
            <td class="mat-cell" colspan="8" *ngIf="loaderProductos">
              <mat-spinner diameter="40"></mat-spinner>
            </td>
          </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
    </div>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-ventas/lista-de-precio">
      <div id="botonesAdicionales">

      </div>
    </pav-form-actions>
  </form>

</div>
