<app-pavso-title-section [title]="'Actividades'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">

      <table-title-action title="Todas las actividades" url="/modulo-produccion/actividad" entity="" [params]="['0']"></table-title-action>

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>


      <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <div class="pav-btns-container">
                  <a matTooltip="Editar turno"
                    class="pav-btn-circle-edit"
                    [routerLink]="['/modulo-produccion/actividad', row.act_codact]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </a>
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="act_codact">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.act_codact}} </td>
          </ng-container>

          <ng-container matColumnDef="act_desact">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.act_desact}} </td>
          </ng-container>

          <ng-container matColumnDef="act_indsta">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.act_indsta == '1' ? 'VIGENTE': 'ANULADO'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
