<app-pavso-title-section [title]="'Composición por cargo de colaboradores'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-chart-container">
    <div class="pav-chart-header-icon"></div>
    <div class="pav-chart-header-text">Período {{year}} - {{month}}</div>
    <div class="pav-separator"></div>
    <div class="pav-chart-header-more">
    </div>
  </div>
    <div class="pav-chart-content">
      <div class="row">
        <div class="col s1"></div>
        <div class="col s10">
          <div id="chartCargoTrabajadores">
          </div>
        </div>
        <div class="col s1"></div>
      </div>
    </div>
</div>

<mat-card class="mat-elevation-z0">

  <button class="pav-button-cancel" mat-flat-button (click)="volver()" type="button"
    >{{buttonsName.BTN_BACK | translate}}</button>
</mat-card>
