import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pavso-chat',
  templateUrl: 'pavso-chat.component.html',
  styleUrls: ['pavso-chat.component.scss'],
})

export class PavsoChatComponent implements OnInit {

  inputMessage: string = '';

  messages: Array<any> = [
    {
      message: 'Hola',
      isGpt: true
    },
    {
      message: 'Hola',
      isGpt: false
    },
    {
      message: 'Hola',
      isGpt: true
    },{
      message: 'Hola',
      isGpt: true
    },{
      message: 'Hola',
      isGpt: false
    }
  ];

  constructor() { }

  ngOnInit() { }

  enviarMensaje(): void {

    if(this.inputMessage.trim() == '') return;

    this.messages.push({
      message: this.inputMessage,
      isGpt: false
    })

    this.inputMessage = '';
  }
}
