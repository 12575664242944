import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Driver } from 'src/app/models';
import { AuthenticationService } from 'src/app/services';
import { Store } from '@ngrx/store';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-worker-movements',
  templateUrl: './worker-movements.component.html',
  styleUrls: ['./worker-movements.component.css']
})
export class WorkerMovementsComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  displayedColumns: string[] = ['codigo', 'nombre_trabajador', 'importe', 'q_tiempo', 'unidad', 'horas', 'minutos', 'valor', 'valor_2'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  fecha: Date = new Date();

  usuario: any;

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.driver = new Driver();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this.driver.CHO_INDSTA = "1";
  }

  registrarChofer(f: NgForm): void {
    if(validarPeriodo(this.fecha, this.year, this.month)) {
      this._dialogService.openDialog(SuccessComponent, 'Movimiento de Trabajador Realizado', '300px', '', '');
      return;
    }

    this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK');

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$]);
  }
}
