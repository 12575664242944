import { Injectable, OnDestroy } from "@angular/core";
import { HeaderReport } from "@data/interfaces/headers.report";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import * as pdfBuilder from "@utils/pdfmaker/builder/crm.pdfmaker";
import { Subscription } from "rxjs";
import { CrmService } from "../../api/crm.service";

@Injectable({
  providedIn: 'root'
})
export class CrmReportService implements OnDestroy {

  reporte$: Subscription;

  constructor(
    private _crmService: CrmService,
    private _snackBarService: SnackBarService
  ) {}

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporte(reportSelected: string, headers: HeaderReport, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "CRM_RO":
          this.reporte$ = this._crmService.obtenerReporteRegistrosOportunidad(payload).subscribe(
            data => {
              console.log('registros de oportunidad data', data)
              pdfBuilder.reporteRegistrosOportunidad(headers, data)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de registros de oportunidad", "Ok");
              reject(false)
            }
          )
          break;
        case "CRM_SO":
          this.reporte$ = this._crmService.obtenerReporteSeguimientoOportunidad().subscribe(
            data => {
              pdfBuilder.reporteSeguimientoDeProyecto(headers, data)
              resolve(true);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de seguimiento de oportunidad", "OK");
              reject(false);
            }
          )
          break;
        case "CRM_VP":
          this.reporte$ = this._crmService.obtenerReporteVentasPerdidas().subscribe(
            data => {
              pdfBuilder.reporteVentasPerdidas(headers, data);
              resolve(true);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de ventas perdidas", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.reporte$
    ])
  }

}
