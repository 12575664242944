<form #f="ngForm" ngNativeValidate (ngSubmit)="addDocumento(f)" (keydown.enter)="$event.preventDefault()">
  <div class="row">
    <div class="col s12">
      <mat-form-field>
        <mat-label>RUC</mat-label>
        <input matInput placeholder="EJ. 20121212121" [(ngModel)]="documento.ruc" name="ruc" [disabled]="loaderData">
      </mat-form-field>
    </div>
    <div class="col s12">
      <mat-form-field>
        <mat-label>Tipo Doc.</mat-label>
        <mat-select [(ngModel)]="documento.tipo" name="tipo" (selectionChange)="seleccionarTipoDoc()" [disabled]="loaderData">
          <mat-option *ngFor="let tipo of tiposDoc" [value]="tipo.TDO_CODTDO">
            {{tipo.TDO_DESCOR}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="col s12">
      <mat-form-field>
        <mat-label>Serie</mat-label>
        <input matInput placeholder="EJ. F001" [(ngModel)]="documento.serie" name="serie" [disabled]="loaderData">
      </mat-form-field>
    </div>
    <div class="col s12">
      <mat-form-field>
        <mat-label>Número</mat-label>
        <input matInput placeholder="EJ. 000414474747" [(ngModel)]="documento.numeroDoc" name="numeroDoc" [disabled]="loaderData">
      </mat-form-field>
    </div>
    <div class="col s12">
      <button mat-flat-button type="submit" [disabled]="loaderData" color="primary">
        <mat-icon>add</mat-icon>
        Agregar
      </button>
    </div>
  </div>
</form>
