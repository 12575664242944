export class Familia {
  CIA_CODCIA: string;
  FPR_CODFPR: string;
  FPR_DESCRI: string;
  FPR_DESABR: string;
  TIN_CODTIN: any;
  FPR_INDSTA: string;
  FPR_FECUPD: Date;
  FPR_CODUSE: string;
  fpr_codlin: any;
  ccn_codccn: any;
  frp_codonu: any;
  frp_clarie: any;
  frp_gruemp: any;

  tiposInventario: TipoInventario[];
  subfamilia: Subfamilia[];

  constructor() {
    this.tiposInventario = [];
    this.subfamilia = [];
  }
}

export class TipoInventario {
  codigo: string;
  descripcion: string;
}

export class Subfamilia {
  cia_codcia: string;
  fpr_codfpr: string;
  sfp_codsfp: string;
  sfp_descri: string;
  sfp_indsta: string;
  sfp_fecupd: string;
  sfp_coduse: string;

  constructor() {
    this.sfp_indsta = '1';
  }
}
