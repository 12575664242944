
<mat-sidenav-container class="example-container">
  <mat-sidenav #drawer mode="over" position="end">

  </mat-sidenav>
  <mat-sidenav-content class="pavso-container">
    <!-- <pav-sliver-actions></pav-sliver-actions> -->
    <br>
    <mat-card class="mat-elevation-z0" style="background: #EAF0F9;">
      <div class="row">
        <div class="col s12 m12 l6">
          <img src="assets/images/imagen-inicio.png" class="pav-welcome-image" alt="">
        </div>
        <div class="col s12 m12 l6">
          <h1 class="pav-title-welcome">{{'home.title' | translate}}</h1>
          <p class="pav-description-welcome">{{'home.description' | translate}}</p>
          <!-- <p class="pav-title-parameters">Ingresa los parámetros y compañía del Cliente</p> -->

          <!-- <mat-form-field appearance="outline">
            <mat-label>Selecciona una URL</mat-label>
            <mat-select>
              <mat-option value="food.value">
                aa
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <!-- <mat-form-field appearance="outline">
            <mat-label>Selecciona compañía cliente</mat-label>
            <mat-select>
              <mat-option value="food.value">
                aa
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
      </div>
    </mat-card>
    <br>
  </mat-sidenav-content>
</mat-sidenav-container>
