import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_NOMINA_CARGO } from '@data/constants/table/theader.nomina';
import { CargoService } from 'src/app/services/api/nomina/maestros/cargo.service';

@Component({
  selector: 'app-cargo-list',
  templateUrl: './cargo-list.component.html',
  styleUrls: ['./cargo-list.component.css']
})
export class CargoListComponent implements OnInit, OnDestroy {

  cargo$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_CARGO;

  cargos: Array<any> = [];

  constructor(
    private _cargoService: CargoService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
      this.loading$ = this.store.select('loading').subscribe(state => {
        if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) {
          this.obtenerCargos();
        }
      })
  }

  obtenerCargos(): void {
    this.cargo$ = this._cargoService.listarCargos().subscribe(
      response => this.cargos = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.cargo$,
      this.loading$
    ])
  }
}
