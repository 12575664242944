import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { Banco } from "src/app/models/general/banco";
import { ProductoAuspiciador, ProductoPromocion, PromocionVenta } from "src/app/models/ventas/promocion-venta";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";

@Component({
  selector: 'promocion-venta-form',
  templateUrl: './promocion-venta-form.component.html',
  styleUrls: ['./promocion-venta-form.component.scss']
})
export class PromocionVentaFormComponent implements OnInit, OnDestroy {

  uid: string;

  productos: any[] = [];

  displayedColumnsProductos: string[] = ['acciones', 'codigo', 'descripcion', 'um', 'cantidad', 'descuento'];
  dataSourceProductos: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorProductos: MatPaginator;
  @ViewChild(MatSort) sortProductos: MatSort;

  displayedColumnsAuspiciadores: string[] = ['acciones', 'codigo', 'descripcion', 'um', 'cantidad', 'descuento'];
  dataSourceAuspiciadores: MatTableDataSource<any>;

  @ViewChild("paginatorAuspiciador") paginatorAuspiciadores: MatPaginator;
  @ViewChild(MatSort) sortAuspiciadores: MatSort;

  loaderReg: boolean;
  loaderData: boolean;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  btnName: string = 'general.button.store';

  loading$: Subscription;
  productos$: Subscription;
  promocionVenta$: Subscription;
  sendForm$: Subscription;

  promocionVenta: PromocionVenta;

  constructor(
    private store: Store<PavsoState>,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _productoService: ProductoService,
    private _snackBarService: SnackBarService,
    private _router: Router
  ) {
    this.promocionVenta = new PromocionVenta();
    this.dataSourceProductos = fillTable([], this.paginatorProductos, this.sortProductos);
    this.dataSourceAuspiciadores = fillTable([], this.paginatorAuspiciadores, this.sortAuspiciadores);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({id}) => this.uid = id)

    this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE: this.buttonsName.BTN_UPDATE;

    this.loadMaestros();

  }

  loadMaestros(): void {
    this.productos$ = this._productoService. obtenerProductos().subscribe(
      productos => {
        this.productos = productos;

        if(this.uid != '0') {
          this.obtenerPromocionVenta();
          return;
        }

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener productos', 'Ok')
      }
    )
  }

  obtenerPromocionVenta(): void {

  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;
    this.uid == '0' ? this.registrarPromocionVenta(f): this.editarPromocionVenta(f);

  }

  registrarPromocionVenta(f: NgForm): void {}

  editarPromocionVenta(f: NgForm): void {}

  agregarProducto(): void {
    const producto = new ProductoPromocion();

    this.promocionVenta.detalle_productos.push(producto);
    this.dataSourceProductos = fillTable(this.promocionVenta.detalle_productos, this.paginatorProductos, this.sortProductos);
  }

  quitarProducto(row): void  {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe( result => {
      if(result) {
        const productos = this.promocionVenta.detalle_productos.filter(item => item.codigo != row.codigo);

        console.log('productos', productos)
        this.promocionVenta.detalle_productos = productos;
        this.dataSourceProductos = fillTable(this.promocionVenta.detalle_productos, this.paginatorProductos, this.sortProductos);

      }
    })

  }

  agregarProductoAuspiciador(): void {
    const producto = new ProductoAuspiciador();

    this.promocionVenta.detalle_auspiciadores.push(producto);
    this.dataSourceAuspiciadores = fillTable(this.promocionVenta.detalle_auspiciadores, this.paginatorAuspiciadores, this.sortAuspiciadores);
  }

  quitarProductoAuspiciador(row): void  {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const productos = this.promocionVenta.detalle_auspiciadores.filter(item => item.codigo != row.codigo);

        this.promocionVenta.detalle_auspiciadores = productos;
        this.dataSourceAuspiciadores = fillTable(this.promocionVenta.detalle_auspiciadores, this.paginatorAuspiciadores, this.sortAuspiciadores);
      }
    })

  }

  seleccionarProducto(codigo, row): void {

    const encontrado = this.promocionVenta.detalle_productos.filter(item => item.codigo == codigo);

    if(encontrado.length > 1) {

      row.codigo = null;
      this.dataSourceProductos = fillTable(this.promocionVenta.detalle_productos, this.paginatorProductos, this.sortProductos);
      this._snackBarService.showError('Este producto ya se encuentra ingresado', 'Ok');

    }

  }

  volver(): void {

    this._router.navigate(['/modulo-ventas/promociones-de-venta'])

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.promocionVenta$,
      this.sendForm$,
      this.productos$,
      this.loading$
    ])
  }

}
