import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LineaProducto } from "src/app/models/almacen/linea-producto";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class LineaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerLineasProducto(): Observable<any> {
    return this.http.get(`${this._configurationService.obtenerUrl()}/api/producto/linea/listar/${this._configurationService.obtenerCompaniaCliente()}`)
  }

  obtenerLineaProducto(id): Observable<any> {
    return this.http.get(`${this._configurationService.obtenerUrl()}/api/producto/linea/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${id}`)
  }

  registrarLineaProducto(payload: LineaProducto): Observable<any> {
    return this.http.post(`${this._configurationService.obtenerUrl()}/api/producto/linea/crear`, payload);
  }

  actualizarLineaProducto(payload: LineaProducto): Observable<any> {
    return this.http.put(`${this._configurationService.obtenerUrl()}/api/producto/linea/modificar`, payload)
  }

}
