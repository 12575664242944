import { Component, OnDestroy, OnInit } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { AuthenticationService, ContabilidadService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subject, Subscription } from 'rxjs';
import { REPORTES_PLANILLA_GESTION } from '@data/json/reportes';
import { HeaderReport } from '@data/interfaces/headers.report';
import { HeaderReportService } from 'src/app/services/reports/config/header-report.service';
import { NominaReportService } from 'src/app/services/reports/modules/nomina-report.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { ApiNominaMaestrosTipoDocumentoLegajoService } from 'src/app/services/api/nomina/maestros/nomina.maestros.tipo-documento-legajo.service';
import { FiltroReportePlanilla } from 'src/app/models/planilla/filtro-reporte-planilla';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-informes-gestion',
  templateUrl: './informes-gestion.component.html',
  styleUrls: ['./informes-gestion.component.css']
})
export class InformesGestionComponent implements OnInit, OnDestroy {

  trabajadores: any[] = [];
  centros: any[] = [];
  conceptos: any[] = [];
  tiposDoc: any[] = [];
  contratos: any[] = [];


  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  yearIni: number;
  monthIni: string;
  yearFin: number;
  monthFin: string;

  months = ["01","02","03","04","05","06","07","08","09","10","11","12"];

  reportes: any[] = REPORTES_PLANILLA_GESTION;

  reporteSeleccionado: any = [REPORTES_PLANILLA_GESTION[0].id];

  mostrarFiltroAuxiliar: 0 | 1 = 0;
  mostrarFiltroCentro: 0 | 1 = 0;
  mostrarFiltroConcepto: 0 | 1 = 0;
  mostrarFiltroRangoFecha: 0 | 1 = 0;
  mostrarFiltroEstadoContrato: 0 | 1 = 0;
  mostrarFiltroEstadoVacacion: 0 | 1 = 0;
  mostrarFiltroTipoDocumento: 0 | 1 = 0;
  mostrarFiltroEstadoDocLegajo: 0 | 1 = 0;

  loading: boolean = false;

  estadoContrato: string = "TODOS";
  estadoVacacion: string = "TODOS";

  period$: Subscription;
  loading$: Subscription;
  indicadores$: Subscription;
  colaboradores$: Subscription;
  conceptos$: Subscription;
  tiposDocLegajo$: Subscription;
  centros$: Subscription;

  filtro: FiltroReportePlanilla;

  constructor(
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private _apiTipoDocumentoLegajoNominaService: ApiNominaMaestrosTipoDocumentoLegajoService,
    private _snackBarService: SnackBarService,
    private _contabilidadService: ContabilidadService,
    private _headerReportService: HeaderReportService,
    private _nominaReportService: NominaReportService,
    private store: Store<PavsoState>
  ) {
    this.filtro = new FiltroReportePlanilla();

    this.period$ = this.store.select('period').subscribe(
      state => {
        this.year = state.year;
        this.month = state.month;

        this.monthIni = "01";
        this.yearIni = Number(this.year);
        this.monthFin = this.month;
        this.yearFin = Number(this.year);
      }
    )

    this.usuario = this._authService.getUsuarioSistema();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  /**
   * Obtiene los maestros de :
   * . colaboradores
   * . conceptos
   * . centros de costo
   * . tipos de documento legajo
   */
  loadData(): void {

    forkObs(
      this._nominaService.listarColaboradores(),
      this._nominaService.listarConceptos(),
      this._contabilidadService.listarCentroCostos(),
      this._apiTipoDocumentoLegajoNominaService.listarTipoDocumentosLegajo()
    ).then(data => {
      this.trabajadores = data[0];
      this.conceptos = data[1].filter(item => item.ind_estado == "1");
      this.centros = data[2];
      this.tiposDoc = data[3];

      this.seleccionarReporte(REPORTES_PLANILLA_GESTION[0].id);

    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "Ok")
    })

  }

  verReporte(): void {
    this.loading = true;
    const headers: HeaderReport = this._headerReportService.obtenerCabecera();

    const payload = {
      colaboradores: (this.filtro.colaboradores)? this.filtro.colaboradores: [],
      conceptos: (this.filtro.conceptos)? this.filtro.conceptos: [],
      centros: (this.filtro.centros)? this.filtro.centros: [],
      tiposDoc: (this.filtro.tiposDocumento)? this.filtro.tiposDocumento: [],
      yearIni: Number(this.yearIni),
      monthIni: Number(this.monthIni),
      yearFin: Number(this.yearFin),
      monthFin: Number(this.monthFin),
    };

    const otherData = {
      periodoini:`${this.yearIni} / ${this.monthIni}`,
      periodofin:`${this.yearFin} / ${this.monthFin}`,
      estadoContrato: this.estadoContrato
    }
    this._nominaReportService.verReporteGestion(this.reporteSeleccionado[0], headers, payload, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)

  }

  seleccionarReporte(event): void {
    this.reporteSeleccionado[0] = event.id;

    let indicador = "";
    this.mostrarFiltroRangoFecha = 0;
    this.mostrarFiltroEstadoContrato = 0;
    this.mostrarFiltroCentro = 0;
    this.mostrarFiltroConcepto = 0;
    this.mostrarFiltroAuxiliar = 0;
    this.mostrarFiltroEstadoVacacion = 0;
    this.mostrarFiltroTipoDocumento = 0;
    this.mostrarFiltroEstadoDocLegajo = 0;

    switch (this.reporteSeleccionado[0]) {
      case "PLANILLA_CV":
        indicador = "51004";
        this.mostrarFiltroEstadoVacacion = 1;
        break;
      case "PLANILLA_CVF":
        indicador = "51004";
        this.mostrarFiltroEstadoVacacion = 1;
        break;
      case "PLANILLA_DEL":
        break;
      case "PLANILLA_ACC":
        indicador = "51001";
        this.mostrarFiltroRangoFecha = 1;
        break;
      case "PLANILLA_ACC":
          indicador = "51001";
        break;
      case "PLANILLA_ACCA":
        indicador = "51001";
        break;
      case "PLANILLA_ACG":
        indicador = "51001";
        this.mostrarFiltroRangoFecha = 1;
        break;
      case "PLANILLA_ACCol":
          indicador = "51001";
          this.mostrarFiltroRangoFecha = 1;
        break;
      case "PLANILLA_C":
        indicador = "51002";
        this.mostrarFiltroEstadoContrato = 1;
        break;
      case "PLANILLA_TDL":
        this.mostrarFiltroTipoDocumento = 1;
        this.mostrarFiltroAuxiliar = 1;
        this.mostrarFiltroEstadoDocLegajo = 1;
        break;
      default:
        break;
    }

    this.indicadores$ = this._contabilidadService.obtenerInidicadoresFiltro(indicador).subscribe(
      ([response]) => {
        if(response) {
          this.mostrarFiltroAuxiliar = response.ind_auxiliar;
          this.mostrarFiltroCentro = response.ind_centrocosto;
          this.mostrarFiltroConcepto = response.ind_conceptoplanilla;
        }
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.indicadores$,
      this.colaboradores$,
      this.conceptos$,
      this.centros$,
      this.tiposDocLegajo$
    ]);

  }
}
