import { Component, Input, OnInit, ViewChild } from "@angular/core";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexLegend,
  ApexDataLabels
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  labels: any;
};

@Component({
  selector: 'pavso-pie-chart-simple',
  templateUrl: './pie-chart-simple.component.html',
  styleUrls: ['./pie-chart-simple.component.scss']
})
export class PieChartSimpleComponent implements OnInit {

  @Input() labels: Array<string> = [];
  @Input() series: Array<number> = [];

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {}

  ngOnInit(): void {
    this.chartOptions = {
      series: this.series,
      chart: {
        type: "pie"
      },
      legend: {
        show: false,
        position: 'bottom',
      },
      dataLabels: {
        enabled: false
      },
      labels: this.labels,
      responsive: [
        {
          breakpoint: 480,
          options: {

            legend: {
              position: "bottom",
              show: false
            },
          }
        },


      ]
    };
  }
}
