import { Component } from "@angular/core";
import { ConfigurationService } from "src/app/services";

@Component({
  selector: 'side-greeting',
  templateUrl: './side-greeting.component.html',
  styleUrls: ['./side-greeting.component.scss']
})
export class SideGreetingComponent {

  usuario: string;

  constructor(
    private _configurationService: ConfigurationService,
  ) {
    this.usuario = this._configurationService.obtenerNameUsuario();
  }
}
