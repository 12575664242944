export class Planta {

  cia_codcia: string;
  pla_codpla: string;
  pla_despla: string;
  pla_indsta: string;
  pla_fecupd: Date;
  pla_coduse: string;

  constructor() {
    this.pla_codpla = '';
    this.pla_indsta = '1';
    this.pla_fecupd = new Date();
  }
}
