  <app-pavso-title-section [title]="'apps.crm.maestros.responsable.title' | translate" [module]="'CRM'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="responsables"
    [tHeaders]="tHeaders"

    title="Todos los responsables"
    url="/modulo-crm/responsable"
    entity=""
    [pageSize]="10"
    [params]="['0']"
  ></pav-table-list>

</div>
