import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, Inject, OnInit } from "@angular/core";
import { NgForm, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Contact } from "src/app/models";
import { ContactI } from "src/app/models/ventas/contact";
import { VentasService } from "src/app/services";

@Component({
  selector: 'cliente-contacto-form-dialog',
  templateUrl: './cliente-contacto-form.dialog.html',
  styleUrls: ['./cliente-contacto-form.dialog.css']
})
export class ClienteContactoFormDialog implements OnInit {

  cargos: any[] = [];
  contacto: Contact;
  existeContacto: boolean = false;
  errorControl:any={};
  errorPropiedades:any={
    required: 'es campo requerido',
    pattern: "no cumple con el formato, intente 'micorreo@dominio.com'"
  };
  fieldsName:ContactI={
    ccl_nomccl:'Nombre',
    ccl_codccl:'Código',
    ccl_corele:'Email'
  };

  uid: any;

  sendForm$: Subscription;
  loaderReg: boolean;

  isTablet: boolean;
  isMobile: boolean;

  constructor(
    public dialogRef: MatDialogRef<ClienteContactoFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.contacto = new Contact();

    this.cargos = data.cargos;
    console.log('cargos', this.cargos)
    console.log("soy contacto",this.contacto)
      console.log("soy data",data.cliente)

    if(data.contacto) {
      console.log("soy data contactos",data.contacto)
      this.existeContacto = true;
      this.contacto = data.contacto;

    }else{
      console.log("existecontacto",this.existeContacto);
      this.contacto.cli_codcli = data.cliente.cli_codcli;
      this.contacto.cia_codcia = data.cliente.cia_codcia;
      console.log("soy contacto",this.contacto);

    }

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(() => {
      // this.wasClosedByBackdropClick = true;
      console.log('Dialog closed by clicking outside (backdrop)');
    });

    if(this.existeContacto) {

      const contacto = this.data.cargos.find(item => item.cca_codcca == this.contacto.cca_codcca);
      this.contacto.cca_descri = contacto.cca_descri;
      this.contacto.cca_desabr = contacto.cca_desabr;
    }

  }

  cancelar(): void {
    this.dialogRef.close(this.existeContacto? {contacto:this.contacto, esNuevo:false} : {contacto:this.contacto, esNuevo:true});
  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;

    this.existeContacto ? this.actualizarContacto(f): this.registrarContacto(f)

  }

  registrarContacto(f: NgForm): void {

    this.sendForm$ = this._ventasService.registrarContacto(this.contacto).subscribe(
      response => {
        this._snackBarService.showSuccess( 'Contacto registrado', 'Ok');
        this.dialogRef.close({contacto:this.contacto, esNuevo:true});
        this.loaderReg = false;
      },
      error =>{
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  actualizarContacto(f: NgForm): void {
    this.sendForm$ = this._ventasService.actualizarContacto(this.contacto).subscribe(
      response => {
        this._snackBarService.showSuccess( 'Contacto actualizado', 'Ok');
        this.dialogRef.close({contacto:this.contacto, esNuevo:false});
        this.loaderReg = false;
      },
      error =>{
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )
  }

  seleccionarCargo(): void {
    const contacto = this.data.cargos.find(item => item.cca_codcca == this.contacto.cca_codcca);
    this.contacto.cca_descri = contacto.cca_descri;
    this.contacto.cca_desabr = contacto.cca_desabr;
  }

  ngOnDestroy():void{
    unsubscribeSubscription([this.sendForm$]);
  }

  onSubmit(myForm:NgForm) {
    if (myForm.invalid) {
      //const errors = [];
      const errorsDisplayed = [];
      for (const controlName in myForm.controls) {
        const control = myForm.form.get(controlName);
        this.errorControl = {}
        if (control.invalid) {
          this.errorControl[controlName]=control.errors
          //errors.push(this.errorControl);
          errorsDisplayed.push(this.fieldsName[controlName] + ' ' + this.errorPropiedades[Object.keys(control.errors)[0]] );

        }
      }
      this._snackBarService.showError(errorsDisplayed.join('\n & '), 'Ok')
      console.log(errorsDisplayed);
      //console.log(errors);
      return true
    }
}
}
