import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class GrupoActivoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarGruposActivo(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/activofijo/listargrupoactivo/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param code
   * @returns
   */
  obtenerGrupoActivo(code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/activofijo/mostrargrupoactivo/${this._configurationService.obtenerCompaniaCliente()}/${code}`
    );
  }

}
