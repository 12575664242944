export function number2month(number: string): string {
  switch (number) {
    case "01":
      return "Enero";
    case "02":
      return "Febrero";
    case "03":
      return "Marzo";
    case "04":
      return "Abril";
    case "05":
      return "Mayo";
    case "06":
      return "Junio";
    case "07":
      return "Julio";
    case "08":
      return "Agosto";
    case "09":
      return "Setiembre";
    case "10":
      return "Octubre";
    case "11":
      return "Noviembre";
    case "12":
      return "Diciembre";
    default:
      break;
  }
}

export function month2number(month: string): string {
  const monthLC = month.toLowerCase();
  switch (monthLC) {
    case "enero":
      return "01";
    case "febrero":
      return "02";
    case "marzo":
      return "03";
    case "abril":
      return "04";
    case "mayo":
      return "05";
    case "junio":
      return "06";
    case "julio":
      return "07";
    case "agosto":
      return "08";
    case "setiembre":
      return "09";
    case "octubre":
      return "10";
    case "noviembre":
      return "11";
    case "diciembre":
      return "12";
    default:
      break;
  }
}
