import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appImgBroken]'
})
export class ImgBrokenDirective {

  @HostListener('error') handleError() {
    const el = this.elHost.nativeElement;
    el.src = "https://image.shutterstock.com/image-vector/picture-vector-icon-no-image-260nw-1732584341.jpg";
  }

  constructor(
    private elHost: ElementRef
  ) { }

}
