<app-pavso-title-section [title]="'Invoices'" [module]="'Importaciones'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los invoices" url="/modulo-importaciones/invoice" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <button matTooltip="Editar" [routerLink]="['/modulo-importaciones/invoice', row.cli_codcli]" mat-icon-button color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="Corre_Invoice">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo </th>
                <td mat-cell *matCellDef="let row" data-label="Correlativo"> {{row.Corre_Invoice}} </td>
            </ng-container>

            <ng-container matColumnDef="orden_compra">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Orden Compra </th>
                <td mat-cell *matCellDef="let row" data-label="Orden Compra"> {{row.orden_compra}} </td>
            </ng-container>

            <ng-container matColumnDef="glosa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.glosa}} </td>
            </ng-container>

            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.fecha | date: 'dd/MM/yyyy': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="codigo_proveedor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cód. Proveedor </th>
                <td mat-cell *matCellDef="let row" data-label="Cód. Proveedor"> {{row.codigo_proveedor}} </td>
            </ng-container>

            <ng-container matColumnDef="Nombre_Proveedor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom. Proveedor </th>
                <td mat-cell *matCellDef="let row" data-label="Nom. Proveedor"> {{row.Nombre_Proveedor}} </td>
            </ng-container>

            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Estado"> {{(row.estado == "1")? "VIGENTE": "ANULADO"}} </td>
            </ng-container>

            <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                <td mat-cell *matCellDef="let row" data-label="Importe"> {{row.importe}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9">No se encontraron registros "{{input.value}}"</td>
            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
