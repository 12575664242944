import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayRef, Overlay, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { CuentaCobrarDialogComponent } from '@shared/components/dialogs/cuenta-por-pagar/cuenta-cobrar-dialog/cuenta-cobrar-dialog.component';
import { CuentaPagarDialogComponent } from '@shared/components/dialogs/cuenta-por-pagar/cuenta-pagar-dialog/cuenta-pagar-dialog.component';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { SelectSearchComponent } from '@shared/components/select/select-search/select-search.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { ordenerArregloJSONxLlave } from '@utils/array/order';
import { formatDateWithDash } from '@utils/formats/date.format';
import { numberWithCommas } from '@utils/formats/number.format';
import { roundToDecimals } from '@utils/formats/round.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { OrdenPago } from 'src/app/models/caja-banco';
import { OperacionFrecuente } from 'src/app/models/caja-banco/operacion-frecuente';
import { ItemDetalleOrdenPago } from 'src/app/models/caja-banco/orden-pago';
import { ConfigurationService, AuthenticationService, VentasService, CajaBancoService, AlmacenService, ContabilidadService } from 'src/app/services';
import { OrdenCompraService } from 'src/app/services/api/compras/operaciones/orden-compra.service';
import { PlanCuentaService } from 'src/app/services/api/contabilidad/maestros/plan-cuenta.service';
import { OperacionFrecuenteService } from 'src/app/services/api/tesoreria/operacion-frecuente.service';
import { OrdenPagoService } from 'src/app/services/api/tesoreria/orden-pago.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { GrabarOperacionFrecuenteDialog, AgregarItemModalDialog } from '../../egresos-banco';
import { generarFormatoOrdenPago } from '@utils/pdfmaker/builder/tesoreria/orden-pago.pdfmaker';
import { FlujoCajaService } from 'src/app/services/api/tesoreria/maestros/fljujo-caja.service';

interface RegistroExcel {
  ind: string;
  proveedor: string;
  td: string;
  nro_doc: string;
  cuenta: string;
  centro_costo: string;
  moneda: string;
  tc: number;
  dh: string;
  importe_mn: number;
  importe_me: number;
  saldo_doc: number;
  glosa: string;
  cta_cte: string;
  ord_compra: string;
  flujo_caja: string;
  dias: number;
  fec_ven: string;
  req_pago: string;
}

@Component({
  selector: 'ingreso-banco-form',
  templateUrl: 'ingreso-banco-form.component.html',
  styleUrls: ['ingreso-banco-form.component.scss']
})

export class IngresoBancoFormComponent implements OnInit {
  loaderReg: boolean = false;
  loaderFormato: boolean = false;

  displayedColumns: string[] = ['ldi_corldi', 'indicador', 'cli_codcli', 'tdo_codtdo', 'ldi_docref', 'ccn_codccn', 'ccs_codccs', 'tmo_codtmo', 'ldi_tipcam', 'ldi_inddha', 'ldi_impnac', 'ldi_impmex', 'ldi_impsal', 'ldi_gloldi', 'cco_codcco', 'orden_compra', 'flujo_caja', 'dias', 'fecha_vencimiento', 'req_pago'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns3: string[] = ['name', 'mn', 'me'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;

  movimientoBanco: OrdenPago;

  monedas: any[] = [];

  loaderData: boolean = false;

  colaboradores = [];
  cuentasBancaria = [];
  cuentasBancariaDestino = [];
  cuentasContabilidad = [];
  clientes = [];
  vendedores = [];
  tiposCambio = [];
  cheques = [];
  subdiarios = [];
  proveedores = [];

  minDate: Date;
  maxDate: Date;

  anioPeriodo: string;
  mesPeriodo: string;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  tiposDoc: any[] = [];
  auxiliares: any[] = [];
  cuentasTotales: any[] = [];

  @ViewChild('iNroDoc') nroDocElement: ElementRef;
  @ViewChild('iSerie') serieElement: ElementRef;
  @ViewChild('iTc') tcElement: ElementRef;
  @ViewChild('iMoneda') monedaElement: MatSelect;
  @ViewChild('iFechaCheque') fechaCheque: MatSelect;
  @ViewChild('btnAdd') buttonAddRefElement: MatButton;

  @ViewChildren("cuentaTotalSelect") selectCuentas: QueryList<MatSelect>;
  @ViewChildren("descripcionInput") inputDescripcion: QueryList<ElementRef>;
  @ViewChildren("costoSelect") selectCosto: QueryList<MatSelect>;

  montosDebeHaber: any;

  btnName: string = "Registrar";

  centros: any[] = [];
  tiposDocumento: any[] = [];
  ordenes: any[] = [];
  centrosCosto: any[] = [];
  flujos: any[] = [];
  operacionesFrecuentes: any[] = [];

  period$: Subscription;
  clientes$: Subscription;
  tiposDocumento$: Subscription;
  cuentas$: Subscription;
  cuentasBancarias$: Subscription;
  vendedores$: Subscription;
  cheques$: Subscription;
  subdiarios$: Subscription;
  ordenes$: Subscription;
  centros$: Subscription;
  flujos$: Subscription;
  tipoCambio$: Subscription;
  serieDoc$: Subscription;
  configuraTipDocCta$: Subscription;
  loading$: Subscription;
  enviarFormulario$: Subscription;
  operacionesFrecuentes$: Subscription;
  config$: Subscription;
  ordenPago$: Subscription;
  selected: ItemDetalleOrdenPago;

  selectRow(row: any) {
    this.selected = row;
  }

  uid: string = '0';
  codano: string;
  codmes: string;
  codsco: string;
  corldc: string;

  tablaSeleccionada: boolean = false;

  @ViewChild('iTC') inputTC: ElementRef;
  @ViewChild('iImporteDol') inputImporteDol: ElementRef;
  @ViewChild('iImporteSol') inputImporteSol: ElementRef;
  @ViewChild('sSubdiario') subdiarioSelectComponent: SelectSearchComponent;
  isMobile: boolean = false;
  isTablet: boolean = false;
  estaConciliado: boolean = false;

  logo: string;
  dircia: string;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('matMenu') matMenu: any;  // `any` puede ser reemplazado por `TemplateRef` si se usa correctamente

  private overlayRef: OverlayRef;

  constructor(
    public dialog: MatDialog,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private _clienteService: ClienteService,
    private readonly _planCuentaService: PlanCuentaService,
    private _router: Router,
    private _cuentaPagarService: CajaBancoService,
    private _flujoCajaService: FlujoCajaService,
    private _ordenCompraService: OrdenCompraService,
    private _almacenService: AlmacenService,
    private _contabilidadService: ContabilidadService,
    private _ordenPagoService: OrdenPagoService,
    private _operacionFrecuenteService: OperacionFrecuenteService,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private overlay: Overlay, private viewContainerRef: ViewContainerRef
  ) {

    this.initialize();

  }

  initialize(): void {
    this.movimientoBanco = new OrdenPago();
    this.movimientoBanco.PBA_CODUSE = this._authService.getUsuarioSistema()

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.anioPeriodo = year;
      this.mesPeriodo = month;
      this.movimientoBanco.ANO_CODANO = this.anioPeriodo;
      this.movimientoBanco.MES_CODMES = this.mesPeriodo;
      this.movimientoBanco.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

      this.dataSource = fillTable([], this.paginator, this.sort);

      this.movimientoBanco.PBA_INDSTA = "1";
      this.movimientoBanco.detalle_ldi = [];
      // this.egresoBanco.ordenes = [];

      this.minDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo) - 1, 1);
      this.maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

      const now = new Date();
      const mes = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
      const anio = now.getFullYear().toString();

      this.movimientoBanco.PBA_FECORD = this.maxDate;

      console.log('this.mesPeriodo', this.mesPeriodo)
      console.log('mes', mes)
      console.log('this.anioPeriodo', this.anioPeriodo)
      console.log('anio', anio)
      if (mes == this.mesPeriodo && anio == this.anioPeriodo) {
        this.movimientoBanco.PBA_FECORD = new Date();
      }

      this.montosDebeHaber = [
        {
          name: "Debe",
          mn: 0.00,
          me: 0.00
        },
        {
          name: "Haber",
          mn: 0.00,
          me: 0.00
        },
        {
          name: "Diferencia",
          mn: 0.00,
          me: 0.00
        }
      ];

      this.dataSource3 = fillTable(this.montosDebeHaber, this.paginator, this.sort);
    })

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });
  }

  ngOnInit(): void {

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
      this.dircia = state.dircia;
    })

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this._activatedRoute.params.subscribe(
      params => {
        this.uid = params.id;
        this.codano = params.codano;
        this.codmes = params.codmes;
        this.codsco = params.codsco;
        this.corldc = params.id;
        console.log({ codano: this.codano, codmes: this.codmes, codsco: this.codsco, corldc: this.corldc })

        // this.egresoBanco.ANO_CODANO = params.codano;
        // this.egresoBanco.MES_CODMES = params.codemes;
        this.movimientoBanco.SCO_CODSCO = params.codsco;
        // this.egresoBanco.LDC_CORLDC = params.id;
      }
    )
  }

  ngAfterViewInit(): void {
    if (this.uid == '0') {
      setTimeout(() => {
        try {
          this.subdiarioSelectComponent.onFocus();
          this.subdiarioSelectComponent.onSelect();
        } catch (error) {
          console.error('Error while calling methods:', error);
        }
      });
    }
  }

  focusSubdiario(): void {

  }

  onPaste(event: ClipboardEvent): void {

    if (!this.tablaSeleccionada) return;
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.processPastedData(pastedText);

  }

  processPastedData(pastedText: string): void {

    if (!this.movimientoBanco.SCO_CODSCO) {
      this._snackBarService.showError('Debe seleccionar un Subdiario', 'OK');
      return;
    }

    if (!this.movimientoBanco.fca_codfca) {
      this._snackBarService.showError('Debe seleccionar un Flujo de Caja', 'OK');
      return;
    }

    if (!this.movimientoBanco.CBA_CODCBA) {
      this._snackBarService.showError('Debe seleccionar un Banco', 'OK');
      return;
    }

    if (!this.movimientoBanco.TDO_CODTDO) {
      this._snackBarService.showError('Debe seleccionar un Tipo de Documento', 'OK');
      return;
    }

    if (!this.movimientoBanco.PBA_NUMDOC) {
      this._snackBarService.showError('Debe ingresar un Número de Documento', 'OK');
      return;
    }

    if (!this.movimientoBanco.CLI_CODCLI) {
      this._snackBarService.showError('Debe seleccionar un Auxiliar', 'OK');
      return;
    }

    if (!this.movimientoBanco.PBA_CODCLI) {
      this._snackBarService.showError('Debe seleccionar un a la orden de', 'OK');
      return;
    }

    const rows = pastedText.split('\n').filter(row => row.trim().length > 0);
    const newData: RegistroExcel[] = rows.map(row => {
      const cols = row.split('\t');
      return {
        ind: cols[0],
        proveedor: cols[1],
        td: cols[2],
        nro_doc: cols[3],
        cuenta: cols[4],
        centro_costo: cols[5],
        moneda: cols[6],
        tc: +cols[7],
        dh: cols[8],
        importe_mn: +cols[9],
        importe_me: +cols[10],
        saldo_doc: +cols[11],
        glosa: cols[12],
        cta_cte: cols[13],
        ord_compra: cols[14],
        flujo_caja: cols[15],
        dias: +cols[16],
        fec_ven: cols[17],
        req_pago: cols[18],
      };
    });

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    };

    if (newData[0].ind == "IND") {
      newData.shift();
    }

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    }

    console.log('newData', newData)

    if ((Object.keys(newData[0]).length) != 19) {
      this._snackBarService.showError('Debe de copiar todo el contenido de las 19 filas', 'Ok');
      return
    };

    this.movimientoBanco.detalle_ldi = [];
    this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);

    newData.forEach(registro => {

      const item = new ItemDetalleOrdenPago();

      item.LDI_INDCCO = registro.ind;
      item.CLI_CODCLI = registro.proveedor;
      item.TDO_CODTDO = registro.td;
      item.LDI_DOCREF = registro.nro_doc;
      item.CCN_CODCCN = registro.cuenta;
      item.CCS_CODCCS = registro.centro_costo;
      item.TMO_CODTMO = registro.moneda;
      item.LDI_TIPCAM = registro.tc;
      item.LDI_INDDHA = registro.dh;
      item.LDI_IMPNAC = registro.importe_mn;
      item.LDI_IMPMEX = registro.importe_me;
      item.ldi_impsal = registro.saldo_doc;
      item.LDI_GLOLDI = registro.glosa;
      item.CCO_CODCCO = registro.cta_cte;
      item.OCM_NUMOCM = registro.ord_compra;
      item.fca_codfca = registro.flujo_caja;
      item.dias = registro.dias;
      item.LDI_FECVEN = registro.fec_ven;



      item.ANO_CODANO = this.anioPeriodo;
      item.MES_CODMES = this.mesPeriodo;
      item.ldi_anoccl = this.anioPeriodo;
      item.ldi_mesccl = this.mesPeriodo;
      item.LDI_FECEMI = this.movimientoBanco.PBA_FECORD;
      item.SCO_CODSCO = this.movimientoBanco.SCO_CODSCO;
      item.LDI_CODUSE = this._authService.getUsuarioSistema();
      item.SUC_CODSUC = '01';
      item.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

      this.movimientoBanco.detalle_ldi.push(item);

    });

    this.selectRow(this.movimientoBanco.detalle_ldi[0]);

    this.movimientoBanco.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);

    this.tablaSeleccionada = false;

    this.montosDebeHaber()
  }

  calculoImportesMN(row: ItemDetalleOrdenPago): void {
    row.LDI_IMPMEX = Number((row.LDI_IMPNAC / row.LDI_TIPCAM).toFixed(2));
    this.establecerDebeHaber();
  }

  calculoImportesME(row: ItemDetalleOrdenPago): void {
    row.LDI_IMPNAC = Number((row.LDI_IMPMEX * row.LDI_TIPCAM).toFixed(2));
    this.establecerDebeHaber();
  }

  filaSeleccionada(row, event): void { }

  establecerValoresPorDefecto(): void { }

  loadMaestros(): void {
    this.loaderData = true;
    console.log('fecha', formatDateWithDash(this.movimientoBanco.PBA_FECORD))
    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.movimientoBanco.PBA_FECORD)).subscribe(
      tipoCambio => {
        this.movimientoBanco.PBA_TIPCAM = tipoCambio[0].tipcam;

        this.clientes$ = this._clienteService.obtenerClientes().subscribe(
          clientes => {
            this.clientes = ordenerArregloJSONxLlave('cli_nomcli', clientes);
            this.auxiliares = this.clientes;
            this.proveedores = this.clientes.filter(item => item.cli_indpro == 1);

            this.tiposDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
              tiposDocumento => {
                this.tiposDoc = ordenerArregloJSONxLlave('TDO_DESLAR', tiposDocumento)
                this.tiposDoc = this.tiposDoc.filter(item => item.tdo_indban == 1);
                this.tiposDocumento = ordenerArregloJSONxLlave('TDO_DESLAR', tiposDocumento);
                console.log('tiposDocumento', this.tiposDocumento)

                this.cuentas$ = this._planCuentaService.obtenerPlanCuentas().subscribe(
                  cuentas => {
                    this.cuentasTotales = ordenerArregloJSONxLlave('ccn_descri', cuentas);
                    this.cuentasContabilidad = this.cuentasTotales;
                    console.log('cuentas', this.cuentasTotales);

                    this.cuentasBancarias$ = this._cuentaPagarService.obtenerCuentasBancarias().subscribe(
                      cuentasBancaria => {
                        this.cuentasBancaria = ordenerArregloJSONxLlave('CBA_DESCRI', cuentasBancaria);
                        this.cuentasBancariaDestino = ordenerArregloJSONxLlave('CBA_DESCRI', cuentasBancaria);
                        console.log('cuentasBancaria', cuentasBancaria)

                        this.vendedores$ = this._ventasService.obtenerVendedores().subscribe(
                          vendedores => {
                            this.vendedores = vendedores;

                            this.cheques$ = this._cuentaPagarService.obtenerChequesBanco().subscribe(
                              cheques => {
                                this.cheques = cheques;

                                this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
                                  subdiarios => {
                                    console.log('todos los subdiarios', subdiarios)

                                    this.subdiarios = subdiarios.filter(item => item.sco_indtes == 1 && item.sco_indtei == 1);

                                    console.log('subdiarios', this.subdiarios)

                                    if (this.subdiarios.length == 1 && !this.movimientoBanco.LDC_CORLDC) {
                                      console.log('subdiarios', this.subdiarios[0].sco_codsco)
                                      this.movimientoBanco.SCO_CODSCO = this.subdiarios[0].sco_codsco;
                                    }

                                    this.ordenes$ = this._ordenCompraService.obtenerOrdenesCompra().subscribe(
                                      ordenes => {
                                        this.ordenes = ordenes;
                                        console.log('ordenes', this.ordenes)

                                        this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
                                          centros => {
                                            this.centrosCosto = centros;
                                            this.centrosCosto.forEach(item => item.ccs_codccs = item.ccs_codccs.trim())
                                            console.log('centros de costo', centros)

                                            this.flujos$ = this._flujoCajaService.obtenerFlujoCajas().subscribe(
                                              flujos => {
                                                this.flujos = flujos.map(item => {
                                                  item['fca_codfca'] = item['fca_codfca'].trim();
                                                  return item;
                                                });
                                                this.flujos = this.flujos.filter(item => item.tipo_flujo == 'I');
                                                this.flujos = ordenerArregloJSONxLlave('fca_descri', this.flujos);

                                                console.log('flujos', this.flujos)

                                                this.operacionesFrecuentes$ = this._operacionFrecuenteService.obtenerOperacionesFrecuentes().subscribe(
                                                  operaciones => {
                                                    this.operacionesFrecuentes = ordenerArregloJSONxLlave('description', operaciones);
                                                    console.log('operaciones frecuentes', operaciones)
                                                    if (this.uid == '0') {
                                                      setTimeout(() => {
                                                        this.subdiarioSelectComponent.onSelect();
                                                      });

                                                    } else {
                                                      this.obtenerEgresoBanco();
                                                    }
                                                    this.loaderData = false;

                                                  },
                                                  error => {
                                                    this._snackBarService.showError('Error al obtener operaciones frecuentes', 'Ok');
                                                    this.loaderData = false;
                                                  }
                                                )
                                              },
                                              error => {
                                                this._snackBarService.showError('Error al obtener flujos de caja', 'Ok');
                                                this.loaderData = false;
                                              }
                                            )
                                          },
                                          error => {
                                            this._snackBarService.showError('Error al obtener centros de costo', 'Ok');
                                            this.loaderData = false;
                                          }
                                        )
                                      },
                                      error => {
                                        this._snackBarService.showError('Error al obtener ordenes de compra', 'Ok');
                                        this.loaderData = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener cheques', 'Ok');
                                this.loaderData = false;
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener vendedores', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener cuentas bancarias', 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener planes de cuenta', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener clientes', 'Ok');
            this.loaderData = false;
          }
        )
      }, error => {
        this._snackBarService.showError('Error al obtener tipos de cambio', 'Ok');
        this.loaderData = false;
      }
    )

  }

  seleccionarCuentaBancoDestino(): void {
    this.movimientoBanco.CBA_CODDES = null;
    this.cuentasBancariaDestino = this.cuentasBancaria.filter(item => item.CBA_CODCBA != this.movimientoBanco.CBA_CODCBA);
  }

  obtenerEgresoBanco(): void {
    console.log('obteniendo egreso banco')
    this._ordenPagoService.obtenerOrdenPago(this.codano, this.codmes, this.codsco, this.corldc).subscribe(
      ordenPago => {

        this.movimientoBanco = ordenPago;
        // this.verificarSiEstaConciliado();
        this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);



        // const detalle = this.egresoBanco.detalle_ldi.filter(item => item.LDI_INDCCO == "A")
        this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort)


        this.establecerDebeHaber();

      },
      error => {
        this._snackBarService.showError('Error al obtener egreso banco', 'Ok')
      }
    )
  }

  registrar$: Subscription;

  grabarOperacionFrecuente(): void {

    this.loaderReg = true;

    const operacionFrecuente = new OperacionFrecuente()
    operacionFrecuente.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    operacionFrecuente.ofb_coduse = this._configurationService.obtenerIdUsuario();
    operacionFrecuente.ano_codano = this.anioPeriodo;
    operacionFrecuente.mes_codmes = this.mesPeriodo;

    this.registrar$ = this._operacionFrecuenteService.registrarOperacionFrecuente(operacionFrecuente).subscribe(
      operacionFrecuente => {
        this._snackBarService.showSuccess('Operación frecuente registrada', 'Ok');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al registrar operación frecuente', 'Ok');
        this.loaderReg = false;
      }
    );
  }

  generarFormato(): void {

    if (!this.movimientoBanco.LDC_CORLDC || this.movimientoBanco.LDC_CORLDC == '0') return;

    this.loaderFormato = true;

    const headers = {
      logo: this.logo,
      nomcia: this._configurationService.obtenerNombreCompaniaCliente(),
      dircia: this.dircia,
      username: this._configurationService.obtenerIdUsuario()
    }

    this.ordenPago$ = this._ordenPagoService.obtenerOrdenPago(this.movimientoBanco.ANO_CODANO, this.movimientoBanco.MES_CODMES, this.movimientoBanco.SCO_CODSCO, this.movimientoBanco.LDC_CORLDC).subscribe(
      ordenPago => {

        generarFormatoOrdenPago(headers, ordenPago)

        this.loaderFormato = false;

      },
      error => {
        this._snackBarService.showError('Error al obtener orden de pago', 'Ok')
        this.loaderFormato = false;
      }
    )


    this.loaderFormato = false;

  }

  seleccionarTipoDocRow(row: ItemDetalleOrdenPago): void {
    const tipoDoc = this.tiposDocumento.find(item => row.TDO_CODTDO == item.TDO_CODTDO);

    this._planCuentaService.obtenerCuentaPorTipoDocumento(row.TDO_CODTDO, row.TMO_CODTMO).subscribe(
      cuenta => {
        row.CCN_CODCCN = cuenta.ccn_codccn
      }
    )
  }

  verificarSiEstaConciliado(): void {

    const item = this.movimientoBanco.detalle_ldi.find(item => {
      return item.LDI_INDCCO == "A";
    })

    if (item) {
      this.estaConciliado = (item.ldi_indccl == 1 ? true : false);
    }

  }

  formatoComaDecimal(monto): string {
    return numberWithCommas(monto);
  }

  egresoBanco$: Subscription;
  seleccionarOperacionFrecuente(): void {
    const operacionFrecuente: OperacionFrecuente = this.operacionesFrecuentes.find(item => item.code == this.movimientoBanco.operacionFrecuente);
    console.log('operacionFrecuente', operacionFrecuente)
    this.egresoBanco$ = this._ordenPagoService.obtenerOrdenPago(operacionFrecuente.ano_codano, operacionFrecuente.mes_codmes, operacionFrecuente.sco_codsco, operacionFrecuente.ldc_corldc).subscribe(
      (ordenPago: OrdenPago) => {
        this.movimientoBanco.CBA_CODCBA = ordenPago.CBA_CODCBA;
        this.movimientoBanco.TDO_CODTDO = ordenPago.TDO_CODTDO;
        this.movimientoBanco.PBA_NUMDOC = ordenPago.PBA_NUMDOC;
        this.movimientoBanco.CLI_CODCLI = ordenPago.CLI_CODCLI;
        this.movimientoBanco.PBA_CODCLI = ordenPago.PBA_CODCLI;
        this.movimientoBanco.fca_codfca = ordenPago.fca_codfca;
        this.movimientoBanco.PBA_GLOPBA = ordenPago.PBA_GLOPBA;
        this.movimientoBanco.TMO_CODTMO = ordenPago.TMO_CODTMO;

        this.movimientoBanco.PBA_IMPSOL = ordenPago.PBA_IMPSOL;
        this.movimientoBanco.PBA_IMPDOL = ordenPago.PBA_IMPDOL;

        if (this.movimientoBanco.TMO_CODTMO == 'SO') {
          this.movimientoBanco.PBA_IMPDOL = parseFloat((ordenPago.PBA_IMPSOL / this.movimientoBanco.PBA_TIPCAM).toFixed(2));
        } else {
          this.movimientoBanco.PBA_IMPSOL = parseFloat((ordenPago.PBA_IMPDOL * this.movimientoBanco.PBA_TIPCAM).toFixed(2));
        }

        this.movimientoBanco.detalle_ldi = ordenPago.detalle_ldi.map(item => {
          item['LDI_TIPCAM'] = this.movimientoBanco.PBA_TIPCAM;

          if (this.movimientoBanco.TMO_CODTMO == 'SO') {
            item['LDI_IMPMEX'] = parseFloat((item.LDI_IMPNAC / this.movimientoBanco.PBA_TIPCAM).toFixed(2));
          } else {
            item['LDI_IMPNAC'] = parseFloat((item.LDI_IMPMEX * this.movimientoBanco.PBA_TIPCAM).toFixed(2));
          }

          if (item.LDI_INDCCO == 'P') {
            item['TDO_CODTDO'] = null;
            item['LDI_DOCREF'] = null;
            item['CCO_CODCCO'] = null;
          }

          return item;
        });

        this.movimientoBanco.detalle_ldi = this.movimientoBanco.detalle_ldi.filter(item => item.LDI_INDCCO != 'A');

        this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);

        console.log('this.egresoBanco', this.movimientoBanco)

        this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);
        this.establecerDebeHaber();
      },
      error => {
        this._snackBarService.showError('Error al obtener egreso banco', 'Ok')
      }
    )
  }

  refrescarOperacionesFrecuentes(): void {
    this._operacionFrecuenteService.obtenerOperacionesFrecuentes().subscribe(
      operaciones => {
        this.operacionesFrecuentes = operaciones;
      },
      error => {
        this._snackBarService.showError('Error al obtener operaciones frecuentes', 'Ok');
      }
    )
  }

  seleccionarTabla(): void {

    this.tablaSeleccionada = !this.tablaSeleccionada;

  }

  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Evita el menú contextual por defecto

    // Cerrar cualquier overlay existente
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    // Crear y configurar la estrategia de posicionamiento
    const positionStrategy: PositionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.clientX, y: event.clientY })
      .withPositions([{
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top'
      }]);

    // Crear el overlay con la estrategia de posicionamiento
    this.overlayRef = this.overlay.create({ positionStrategy });

    // Crear un portal con el mat-menu y adjuntarlo al overlay
    const menuPortal = new TemplatePortal(this.matMenu, this.viewContainerRef);
    this.overlayRef.attach(menuPortal);
  }

  agregarOperacionFrecuente(): void {
    console.log('this.ingresoBanco.SCO_CODSCO', this.movimientoBanco.SCO_CODSCO)
    if (!this.movimientoBanco.SCO_CODSCO || this.movimientoBanco.SCO_CODSCO == '0') {
      this._snackBarService.showError('Seleccionar un subdiario', 'Ok');
      return;
    }

    const dialogRef = this.dialog.open(GrabarOperacionFrecuenteDialog, {
      width: '350px',
      data: {
        subdiario: this.movimientoBanco.SCO_CODSCO,
        anio: this.movimientoBanco.ANO_CODANO,
        mes: this.movimientoBanco.MES_CODMES,
        voucher: this.movimientoBanco.LDC_CORLDC
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.operacionesFrecuentes.push(result);
        this.movimientoBanco.operacionFrecuente = result.ldc_corldc;
        this._operacionFrecuenteService.obtenerOperacionesFrecuentes().subscribe(
          operaciones => {
            this.operacionesFrecuentes = operaciones;
          },
          error => {
            this._snackBarService.showError('Error al obtener operaciones frecuentes', 'Ok');
          }
        )
      }
    });
  }

  agregarItem(): void {

    if (!this.movimientoBanco.SCO_CODSCO) {
      this._snackBarService.showError('Establecer un subdiario', 'Ok');
      return;
    }

    if (!this.movimientoBanco.TDO_CODTDO) {
      this._snackBarService.showError('Establecer un tipo de documento', 'Ok');
      return;
    }

    if (!this.movimientoBanco.PBA_NUMDOC) {
      this._snackBarService.showError('Establecer un número de documento', 'Ok');
      return;
    }

    if (!this.movimientoBanco.CLI_CODCLI) {
      this._snackBarService.showError('Establecer un auxiliar', 'Ok');
      return;
    }

    if (!this.movimientoBanco.PBA_TIPCAM || this.movimientoBanco.PBA_TIPCAM == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.movimientoBanco.fca_codfca) {
      this._snackBarService.showError("Establecer Flujo de Caja", "OK");
      return;
    }

    if (this.movimientoBanco.PBA_IMPDOL == 0 || this.movimientoBanco.PBA_IMPSOL == 0) {
      this._snackBarService.showError("Establecer Monto", "OK");
      return;
    }

    const producto = new ItemDetalleOrdenPago();

    producto.TMO_CODTMO = this.movimientoBanco.TMO_CODTMO;
    producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
    producto.ANO_CODANO = this.anioPeriodo;
    producto.MES_CODMES = this.mesPeriodo;

    producto.ldi_anoccl = this.anioPeriodo;
    producto.ldi_mesccl = this.mesPeriodo;
    producto.LDI_FECEMI = this.movimientoBanco.PBA_FECORD;
    producto.LDI_FECVEN = this.movimientoBanco.PBA_FECVEN;

    producto.SCO_CODSCO = this.movimientoBanco.SCO_CODSCO;

    producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
    producto.LDI_GLOLDI = this.movimientoBanco.PBA_GLOPBA;
    producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;

    producto.fca_codfca = this.movimientoBanco.fca_codfca;
    producto.CLI_CODCLI = this.movimientoBanco.CLI_CODCLI;
    producto.LDI_FECVEN = this.movimientoBanco.PBA_FECVEN;
    producto.LDI_CODUSE = this._authService.getUsuarioSistema();

    producto.LDI_INDCCO = 'N';
    producto.LDI_INDDHA = 'H';
    producto.SUC_CODSUC = '01';
    producto.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    producto.LDI_FECVEN = this.movimientoBanco.PBA_FECORD;

    this.movimientoBanco.detalle_ldi.push(producto);
    debugger;

    this.movimientoBanco.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);
    this.establecerDebeHaber();
  }

  agregarItemModal(): void {

    if (!this.movimientoBanco.TDO_CODTDO) {
      this._snackBarService.showError('Establecer un tipo de documento', 'Ok');
      return;
    }

    if (!this.movimientoBanco.PBA_NUMDOC) {
      this._snackBarService.showError('Establecer un número de documento', 'Ok');
      return;
    }

    if (!this.movimientoBanco.CLI_CODCLI) {
      this._snackBarService.showError('Establecer un auxiliar', 'Ok');
      return;
    }

    if (!this.movimientoBanco.PBA_TIPCAM || this.movimientoBanco.PBA_TIPCAM == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.movimientoBanco.fca_codfca) {
      this._snackBarService.showError("Establecer Flujo de Caja", "OK");
      return;
    }

    if (this.movimientoBanco.PBA_IMPDOL == 0 || this.movimientoBanco.PBA_IMPSOL == 0) {
      this._snackBarService.showError("Establecer Monto", "OK");
      return;
    }

    const dialogRef = this.dialog.open(AgregarItemModalDialog, {
      width: '350px',
      data: {
        proveedores: this.proveedores,
        tiposDocumento: this.tiposDocumento,
        cuentasTotales: this.cuentasTotales,
        ordenes: this.ordenes,
        centrosCosto: this.centrosCosto,
        flujos: this.flujos,
        egresoBanco: this.movimientoBanco
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        const producto = new ItemDetalleOrdenPago();
        producto.CCN_CODCCN = result.cuenta;
        producto.LDI_INDDHA = result.dh;
        producto.dias = result.dias;
        producto.LDI_FECVEN = result.fechaVencimiento;
        producto.fca_codfca = result.flujo;
        producto.LDI_GLOLDI = result.glosa;
        producto.LDI_IMPNAC = result.importeMN;
        producto.LDI_IMPMEX = result.importeME;
        producto.TMO_CODTMO = result.moneda;
        producto.LDI_DOCREF = result.nroDocumento;
        producto.CLI_CODCLI = result.proveedor;
        producto.LDI_TIPCAM = result.tipoCambio;
        producto.TDO_CODTDO = result.tipoDocumento;

        producto.TMO_CODTMO = this.movimientoBanco.TMO_CODTMO;
        producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
        producto.ANO_CODANO = this.anioPeriodo;
        producto.MES_CODMES = this.mesPeriodo;

        producto.ldi_anoccl = this.anioPeriodo;
        producto.ldi_mesccl = this.mesPeriodo;
        producto.LDI_FECEMI = this.movimientoBanco.PBA_FECORD;
        producto.LDI_FECVEN = this.movimientoBanco.PBA_FECVEN;

        producto.SCO_CODSCO = this.movimientoBanco.SCO_CODSCO;

        producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
        producto.LDI_GLOLDI = this.movimientoBanco.PBA_GLOPBA;
        producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;

        producto.fca_codfca = this.movimientoBanco.fca_codfca;
        producto.CLI_CODCLI = this.movimientoBanco.CLI_CODCLI;
        producto.LDI_FECVEN = this.movimientoBanco.PBA_FECVEN;
        producto.LDI_CODUSE = this._authService.getUsuarioSistema();

        producto.LDI_INDCCO = 'N';
        producto.LDI_INDDHA = 'H';
        producto.SUC_CODSUC = '01';
        producto.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
        producto.LDI_FECVEN = this.movimientoBanco.PBA_FECORD;

        this.movimientoBanco.detalle_ldi.push(producto);

        this.movimientoBanco.detalle_ldi.forEach((element, key) => {
          element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
        })

        this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);
        this.establecerDebeHaber();
      }
    });

  }

  seleccionarCuenta(row: ItemDetalleOrdenPago): void {
    const cuenta = this.cuentasTotales.find(item => item.ccn_codccn == row.CCN_CODCCN);
    console.log('cuenta', cuenta)

    if (cuenta.tmo_codtmo) {
      row.TMO_CODTMO = cuenta.tmo_codtmo;
      return;
    }

    if(cuenta.ccs_codccs) {
      row.CCS_CODCCS = cuenta.ccs_codccs;
    }

    row.TMO_CODTMO = this.movimientoBanco.TMO_CODTMO;
  }

  seleccionarBanco(): void {
    console.log('cuentasbancarias', this.cuentasBancaria)
    console.log('cba', this.movimientoBanco.CBA_CODCBA)
    const banco = this.cuentasBancaria.find(item => item.CBA_CODCBA == this.movimientoBanco.CBA_CODCBA)
    console.log('banco', banco)
    if (banco) {
      this.movimientoBanco.TMO_CODTMO = banco.TMO_CODTMO;
      return;
    }

    this.movimientoBanco.TDO_CODTDO = 'TRB';

    this.movimientoBanco.TMO_CODTMO = null;
  }

  seleccionarAuxiliar(): void {
    this.movimientoBanco.PBA_CODCLI = this.movimientoBanco.CLI_CODCLI;

  }

  eliminarItem(): void {
    let row = this.movimientoBanco.detalle_ldi.find(item => item == this.selected);

    if (!row && this.movimientoBanco.detalle_ldi.length > 0) row = this.movimientoBanco.detalle_ldi[0];

    let details = [];
    this.movimientoBanco.detalle_ldi.forEach(element => {
      if (element.LDI_CORLDI != row.LDI_CORLDI) {
        details.push(element)
      }
    });

    this.movimientoBanco.detalle_ldi = details;

    this.movimientoBanco.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;

    this.establecerDebeHaber();

  }

  insertarItem(): void {

    if (!this.movimientoBanco.PBA_TIPCAM || this.movimientoBanco.PBA_TIPCAM == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (this.movimientoBanco.PBA_IMPDOL == 0 || this.movimientoBanco.PBA_IMPSOL == 0) {
      this._snackBarService.showError("Establecer Monto", "OK");
      return;
    }

    if (!this.movimientoBanco.fca_codfca) {
      this._snackBarService.showError("Establecer Flujo de Caja", "OK");
      return;
    }

    this.dataSource = fillTable([], this.paginator, this.sort);

    const producto = new ItemDetalleOrdenPago();

    producto.TMO_CODTMO = this.movimientoBanco.TMO_CODTMO;
    producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
    producto.ANO_CODANO = this.anioPeriodo;
    producto.MES_CODMES = this.anioPeriodo;

    producto.ldi_anoccl = this.anioPeriodo;
    producto.ldi_mesccl = this.anioPeriodo;
    producto.LDI_FECEMI = this.movimientoBanco.PBA_FECORD;
    producto.LDI_FECVEN = this.movimientoBanco.PBA_FECVEN;

    producto.SCO_CODSCO = this.movimientoBanco.SCO_CODSCO;

    producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
    producto.LDI_GLOLDI = this.movimientoBanco.PBA_GLOPBA;
    producto.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;

    producto.fca_codfca = this.movimientoBanco.fca_codfca;
    producto.CLI_CODCLI = this.movimientoBanco.CLI_CODCLI;
    producto.LDI_FECVEN = this.movimientoBanco.PBA_FECORD;
    producto.LDI_CODUSE = this._authService.getUsuarioSistema();

    producto.LDI_INDCCO = 'N';

    let i = 0;

    if (this.selected) {

      this.movimientoBanco.detalle_ldi.forEach((item, index) => {
        if (item.LDI_CORLDI == this.selected.LDI_CORLDI) i = index;
      })

      this.movimientoBanco.detalle_ldi.splice(i, 0, producto);

    } else {

      this.movimientoBanco.detalle_ldi.push(producto);

    }

    this.movimientoBanco.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    console.log('detalle', this.movimientoBanco.detalle_ldi)

    this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);
    this.establecerDebeHaber();
    console.log('detalle', this.movimientoBanco.detalle_ldi)

  }

  loadData(): void {
    this.loadMaestros();
  }

  borrarAsientosDestino(): void {
    console.log('borrar destino')
    let detalleNuevo = [];
    this.movimientoBanco.detalle_ldi.forEach(item => {
      if (item.LDC_CORLDC[0] != '6' && item.LDC_CORLDC[0] != '7') {
        detalleNuevo.push(item);
      }
    });

    this.movimientoBanco.detalle_ldi = detalleNuevo;
    this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);
  }

  documentosPorPagar(): void {

    if (!this.movimientoBanco.TDO_CODTDO) {
      this._snackBarService.showError('Establecer un tipo de documento', 'Ok');
      return;
    }

    if (!this.movimientoBanco.PBA_NUMDOC) {
      this._snackBarService.showError('Establecer un número de documento', 'Ok');
      return;
    }

    if (!this.movimientoBanco.CLI_CODCLI) {
      this._snackBarService.showError('Establecer un auxiliar', 'Ok');
      return;
    }

    if (!this.movimientoBanco.PBA_TIPCAM || this.movimientoBanco.PBA_TIPCAM == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.movimientoBanco.fca_codfca) {
      this._snackBarService.showError("Establecer Flujo de Caja", "OK");
      return;
    }

    if (this.movimientoBanco.PBA_IMPDOL == 0 || this.movimientoBanco.PBA_IMPSOL == 0) {
      this._snackBarService.showError("Establecer Monto", "OK");
      return;
    }

    const dialogRef = this.dialog.open(CuentaPagarDialogComponent, {
      width: '1000px',
      data: {
        cli_codcli: this.movimientoBanco.CLI_CODCLI ? this.movimientoBanco.CLI_CODCLI : '',
        periodo: `${this.anioPeriodo}${this.mesPeriodo}`,
        clientes: this.auxiliares,
        tipo_cambio: this.movimientoBanco.PBA_TIPCAM
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result)
        result.forEach(item => {

          let detailItem = new ItemDetalleOrdenPago();

          detailItem.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          detailItem.ANO_CODANO = this.anioPeriodo;
          detailItem.MES_CODMES = this.mesPeriodo;
          detailItem.LDI_CORLDI = `0000${this.movimientoBanco.detalle_ldi.length + 1}`;
          detailItem.TMO_CODTMO = item.moneda;

          detailItem.LDI_INDDHA = (item.importe_saldo * item.ind_signo) < 0 ? "H" : "D";
          detailItem.TDO_CODTDO = item.tip_doc;
          detailItem.LDI_IMPNAC = (item.moneda == "SO") ? Math.abs(Number(item.importe_saldo.toFixed(2))) : Math.abs(Number((item.importe_saldo * this.movimientoBanco.PBA_TIPCAM).toFixed(2)));
          detailItem.LDI_IMPMEX = (item.moneda == "SO") ? Math.abs(Number((item.importe_saldo / this.movimientoBanco.PBA_TIPCAM).toFixed(2))) : Math.abs(Number(item.importe_saldo.toFixed(2)));
          detailItem.LDI_TCAMEU = 0;
          detailItem.LDI_INDDES = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.LDI_FECEMI = item.fec_emision;
          detailItem.LDI_FECVEN = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.LDI_INDCCO = "P";
          detailItem.LDI_INDDES = 0;
          detailItem.ldi_mesccl = "";
          detailItem.SCO_CODSCO = item.subdiario;
          detailItem.SUC_CODSUC = "01";
          detailItem.TDO_DOCREF = "";
          detailItem.VDE_CODVDE = "";
          detailItem.CCO_CODCCO = item.cta_cte;
          detailItem.CCN_CODCCN = item.cuenta;
          detailItem.CLI_CODCLI = item.auxiliar;
          detailItem.ldi_impsal = item.importe_saldo;
          detailItem.LDI_DOCREF = item.nro_docu;
          detailItem.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
          detailItem.LDI_GLOLDI = item.glosa;
          detailItem.fca_codfca = this.movimientoBanco.fca_codfca;
          detailItem.LDI_CODUSE = this._authService.getUsuarioSistema();

          this.movimientoBanco.detalle_ldi.push(detailItem);

          this.movimientoBanco.detalle_ldi.forEach((element, key) => {
            element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
          })

          this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);
          this.establecerDebeHaber();
        });
      }
    })
  }

  documentosPorCobrar(): void {

    if (!this.movimientoBanco.TDO_CODTDO) {
      this._snackBarService.showError('Establecer un tipo de documento', 'Ok');
      return;
    }

    if (!this.movimientoBanco.PBA_NUMDOC) {
      this._snackBarService.showError('Establecer un número de documento', 'Ok');
      return;
    }

    if (!this.movimientoBanco.CLI_CODCLI) {
      this._snackBarService.showError('Establecer un auxiliar', 'Ok');
      return;
    }

    if (!this.movimientoBanco.PBA_TIPCAM || this.movimientoBanco.PBA_TIPCAM == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.movimientoBanco.fca_codfca) {
      this._snackBarService.showError("Establecer Flujo de Caja", "OK");
      return;
    }

    if (this.movimientoBanco.PBA_IMPDOL == 0 || this.movimientoBanco.PBA_IMPSOL == 0) {
      this._snackBarService.showError("Establecer Monto", "OK");
      return;
    }

    const dialogRef = this.dialog.open(CuentaCobrarDialogComponent, {
      width: '1000px',
      data: {
        cli_codcli: this.movimientoBanco.CLI_CODCLI ? this.movimientoBanco.CLI_CODCLI : '',
        periodo: `${this.anioPeriodo}${this.mesPeriodo}`,
        clientes: this.auxiliares
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        result.forEach(item => {
          let detailItem = new ItemDetalleOrdenPago();

          detailItem.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          detailItem.ANO_CODANO = this.anioPeriodo;
          detailItem.MES_CODMES = this.mesPeriodo;
          detailItem.LDI_CORLDI = `0000${this.movimientoBanco.detalle_ldi.length + 1}`;
          detailItem.TMO_CODTMO = item.moneda;
          detailItem.LDI_INDDHA = "D";
          detailItem.TDO_CODTDO = item.tip_doc;
          detailItem.LDI_IMPNAC = (item.moneda == "SO") ? Math.abs(Number(item.importe_saldo.toFixed(2))) : Math.abs(Number((item.importe_saldo * this.movimientoBanco.PBA_TIPCAM).toFixed(2)));
          detailItem.LDI_IMPMEX = (item.moneda == "SO") ? Math.abs(Number((item.importe_saldo / this.movimientoBanco.PBA_TIPCAM).toFixed(2))) : Math.abs(Number(item.importe_saldo.toFixed(2)));
          detailItem.LDI_TCAMEU = 0;
          detailItem.LDI_INDDES = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.LDI_FECEMI = item.fec_emision;
          detailItem.LDI_FECVEN = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.LDI_INDCCO = "C";
          detailItem.LDI_INDDES = 0;
          detailItem.ldi_mesccl = "";
          detailItem.SCO_CODSCO = item.subdiario;
          detailItem.SUC_CODSUC = "01";
          detailItem.TDO_DOCREF = "";
          detailItem.VDE_CODVDE = "";
          detailItem.CCO_CODCCO = item.cta_cte;
          detailItem.CCN_CODCCN = item.cuenta;
          detailItem.CLI_CODCLI = item.auxiliar;
          detailItem.LDI_DOCREF = item.nro_docu;
          detailItem.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
          detailItem.LDI_GLOLDI = item.glosa;
          detailItem.fca_codfca = this.movimientoBanco.fca_codfca;
          detailItem.LDI_CODUSE = this._authService.getUsuarioSistema();

          this.movimientoBanco.detalle_ldi.push(detailItem);

          this.movimientoBanco.detalle_ldi.forEach((element, key) => {
            element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
          })

          this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);

          this.establecerDebeHaber();
        });
      }
    })
  }

  verificarImporte(row: ItemDetalleOrdenPago): void {

    let esValidoMonto = true;

    if (row.TMO_CODTMO == 'SO') {
      if (row.LDI_IMPNAC > row.ldi_impsal && row.LDI_INDCCO == 'P') {
        esValidoMonto = false;
      }
    } else {
      if (row.LDI_IMPMEX > row.ldi_impsal && row.LDI_INDCCO == 'P') {
        esValidoMonto = false;
      }
    }

    if (!esValidoMonto) {
      this._snackBarService.showError('El importe de pago no puede ser mayor al saldo', 'OK');
    }
  }

  anular(): void {
    this.movimientoBanco.PBA_INDSTA = '0';
    this._ordenPagoService.editarOrdenPago(this.movimientoBanco).subscribe(
      response => {
        this._snackBarService.showSuccess('Orden pago Anulado', 'Ok')
      },
      error => {
        this._snackBarService.showError('Error al anular', 'Ok')
      }
    )
  }

  copiarItem(): void {

    if (!this.selected) {
      this._snackBarService.showError('Debe seleccionar un item', "OK");
      return;
    }

    if (this.selected.LDC_CORLDC[0] == '6' || this.selected.LDC_CORLDC[0] == '7') {
      this._snackBarService.showError('No es permitido copiar un ítem que comience con el correlativo 6 o 7', "OK");
      return;
    }


    let item = new ItemDetalleOrdenPago();
    item.CIA_CODCIA = this.selected.CIA_CODCIA;
    item.ANO_CODANO = this.selected.ANO_CODANO;
    item.MES_CODMES = this.selected.MES_CODMES;
    item.ldi_anoccl = this.selected.ldi_anoccl;
    item.ldi_mesccl = this.selected.ldi_mesccl;
    item.TMO_CODTMO = this.selected.TMO_CODTMO;
    item.LDI_FECEMI = this.selected.LDI_FECEMI;
    item.LDI_FECVEN = this.selected.LDI_FECVEN;
    item.SCO_CODSCO = this.selected.SCO_CODSCO;
    item.LDI_TIPCAM = this.selected.LDI_TIPCAM;
    item.LDI_GLOLDI = this.selected.LDI_GLOLDI;
    item.CCN_CODCCN = this.selected.CCN_CODCCN;
    item.CLI_CODCLI = this.selected.CCN_CODCCN;
    item.TDO_CODTDO = this.selected.TDO_CODTDO;
    item.LDI_DOCREF = this.selected.LDI_DOCREF;
    item.TMO_CODTMO = this.selected.TMO_CODTMO;
    item.LDI_TIPCAM = this.selected.LDI_TIPCAM;
    item.LDI_INDDHA = this.selected.LDI_INDDHA;
    item.LDI_GLOLDI = this.selected.LDI_GLOLDI;
    item.CCO_CODCCO = this.selected.CCO_CODCCO;

    item.LDI_IMPNAC = 0;
    item.LDI_IMPMEX = 0;
    item.CCS_CODCCS = null;

    this.borrarAsientosDestino();

    this.movimientoBanco.detalle_ldi.push(item);

    this.movimientoBanco.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort)

  }

  seleccionarAuxiliarOrden(event): void {

    if (event) {
      // this.egresoBanco.coddo = event.value;
    }
    setTimeout(() => {
      // this.emisionElement.nativeElement.focus();
      // this.emisionElement.nativeElement.select();
    }, 1);
  }

  calcularMontos(event?: KeyboardEvent): void {

    if (this.movimientoBanco.PBA_TIPCAM <= 0) {
      this._snackBarService.showError('Establecer un tipo de cambio', 'Ok');
      return;
    }

    if (event) {
      console.log('event', (event.currentTarget as HTMLInputElement).name)

      const inputName = (event.currentTarget as HTMLInputElement).name;

      if (inputName == 'montoSoles') {
        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;

        this.movimientoBanco.PBA_IMPDOL = Number((this.movimientoBanco.PBA_IMPSOL / this.movimientoBanco.PBA_TIPCAM).toFixed(2));
        console.log('PBA_IMPSOL', this.movimientoBanco.PBA_IMPSOL)
        console.log('PBA_IMPDOL', this.movimientoBanco.PBA_IMPDOL)
        return;
      }

      if (inputName == 'montoDolares') {
        this.movimientoBanco.PBA_IMPSOL = Number((this.movimientoBanco.PBA_IMPDOL * this.movimientoBanco.PBA_TIPCAM).toFixed(2));
        console.log('PBA_IMPSOL', this.movimientoBanco.PBA_IMPSOL)
        console.log('PBA_IMPDOL', this.movimientoBanco.PBA_IMPDOL)
        return;
      }
    }
    if (this.movimientoBanco.PBA_IMPDOL == 0) {
      this.movimientoBanco.PBA_IMPDOL = Number((this.movimientoBanco.PBA_IMPSOL / this.movimientoBanco.PBA_TIPCAM).toFixed(2));
      return;
    }

    if (this.movimientoBanco.PBA_IMPSOL == 0) {
      this.movimientoBanco.PBA_IMPSOL = Number((this.movimientoBanco.PBA_IMPDOL * this.movimientoBanco.PBA_TIPCAM).toFixed(2));
      return;
    }

    this.movimientoBanco.PBA_IMPDOL = Number((this.movimientoBanco.PBA_IMPSOL / this.movimientoBanco.PBA_TIPCAM).toFixed(2));


  }

  establecerDebeHaber(): void {

    this.montosDebeHaber[0].mn = 0;
    this.montosDebeHaber[0].me = 0;

    this.montosDebeHaber[1].mn = 0;
    this.montosDebeHaber[1].me = 0;

    this.montosDebeHaber[2].mn = 0;
    this.montosDebeHaber[2].me = 0;

    this.movimientoBanco.detalle_ldi.forEach(element => {

      if (element.LDI_INDDHA == "D") {
        this.montosDebeHaber[0].mn += Number(element.LDI_IMPNAC);
        this.montosDebeHaber[0].me += Number(element.LDI_IMPMEX);
      } else {
        this.montosDebeHaber[1].mn += Number(element.LDI_IMPNAC);
        this.montosDebeHaber[1].me += Number(element.LDI_IMPMEX);
      }
    });

    this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
    this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

    this.dataSource3 = fillTable(this.montosDebeHaber, this.paginator, this.sort);

  }

  focusTC(): void {
    this.inputTC.nativeElement.focus();
    this.inputTC.nativeElement.select();
  }

  focusImporteDol(): void {
    this.inputImporteDol.nativeElement.focus();
    this.inputImporteDol.nativeElement.select();
  }

  focusImporteSol(): void {
    this.inputImporteSol.nativeElement.focus();
    this.inputImporteSol.nativeElement.select();
  }

  esFormularioValido(): boolean {

    if (!this.movimientoBanco.SCO_CODSCO) {
      this._snackBarService.showError('Debe seleccionar un Subdiario', 'OK');
      return false;
    }

    if (!this.movimientoBanco.fca_codfca) {
      this._snackBarService.showError('Debe seleccionar un Flujo de Caja', 'OK');
      return false;
    }

    if (!this.movimientoBanco.CBA_CODCBA) {
      this._snackBarService.showError('Debe seleccionar un Banco', 'OK');
      return false;
    }

    if (!this.movimientoBanco.TDO_CODTDO) {
      this._snackBarService.showError('Debe seleccionar un Tipo de Documento', 'OK');
      return false;
    }

    if (!this.movimientoBanco.PBA_NUMDOC) {
      this._snackBarService.showError('Debe ingresar un Número de Documento', 'OK');
      return false;
    }

    if (!this.movimientoBanco.CLI_CODCLI) {
      this._snackBarService.showError('Debe seleccionar un Auxiliar', 'OK');
      return false;
    }

    if (!this.movimientoBanco.PBA_CODCLI) {
      this._snackBarService.showError('Debe seleccionar un a la orden de', 'OK');
      return false;
    }

    if (this.movimientoBanco.detalle_ldi.length <= 0) {
      this._snackBarService.showError('Agregar items al detalle', 'OK');
      return false;
    }

    if (this.movimientoBanco.PBA_IMPSOL == 0 || this.movimientoBanco.PBA_IMPDOL == 0) {
      this._snackBarService.showError('Debe ingresar un monto en soles o dólares', 'OK');
      return false;
    }

    return true;
  }

  esDetalleValido(): boolean {
    let esValido = true;
    let row: ItemDetalleOrdenPago = null;

    this.movimientoBanco.detalle_ldi.forEach(item => {
      if (!item.TDO_CODTDO) {
        this._snackBarService.showError('Debe seleccionar un Tipo de Documento en el detalle', 'OK');
        esValido = false
      };

      if (!item.LDI_DOCREF) {
        this._snackBarService.showError('Debe establecer número Documento en el detalle', 'OK');
        esValido = false
      };

      if (!item.CCN_CODCCN) {
        this._snackBarService.showError('Debe seleccionar una cuenta en el detalle', 'OK');
        esValido = false
      };

      let esValidoMonto = true;

      if (item.TMO_CODTMO == 'SO') {
        if (item.LDI_IMPNAC > item.ldi_impsal && item.LDI_INDCCO == 'P') {
          esValidoMonto = false;
          row = item;
        }
      } else {
        if (item.LDI_IMPMEX > item.ldi_impsal && item.LDI_INDCCO == 'P') {
          esValidoMonto = false;
          row = item;
        }
      }

      if (!esValidoMonto) {
        this._snackBarService.showError('El importe de pago no puede ser mayor al saldo', 'OK');
        this.selectRow(row);
        esValido = false
      }

      const cuenta = this.cuentasTotales.find(cuenta => cuenta.ccn_codccn == item.CCN_CODCCN);

      if (cuenta.css_codcss == 1 && !item.CCS_CODCCS) {
        this._snackBarService.showError(`Es obligatorio ingresar el centro de costo para la cuenta ${cuenta.ccn_codccn}`, 'OK');
        esValido = false
      }

    })

    return esValido;
  }

  esValidoMonto(): boolean {

    let montoSoles = 0;
    let montoDolares = 0;

    this.movimientoBanco.detalle_ldi.forEach(item => {
      if (item.LDI_INDDHA == 'D') {
        montoSoles += item.LDI_IMPNAC;
        montoDolares += item.LDI_IMPMEX;
      } else {
        montoSoles -= item.LDI_IMPNAC;
        montoDolares -= item.LDI_IMPMEX;
      }
    })

    console.log('montosoles', montoSoles)
    console.log('this.egresoBanco.PBA_IMPSOL', this.movimientoBanco.PBA_IMPSOL)
    console.log('montoDolares', montoDolares)
    console.log('this.egresoBanco.PBA_IMPDOL', this.movimientoBanco.PBA_IMPDOL)

    if (montoSoles.toFixed(2) != this.movimientoBanco.PBA_IMPSOL.toFixed(2) || montoDolares.toFixed(2) != this.movimientoBanco.PBA_IMPDOL.toFixed(2)) {

      this._snackBarService.showError('El Monto principal no coincide con los montos del detalle', 'OK');
      return false;

    }

    const filtrados = this.movimientoBanco.detalle_ldi.filter(item => item.LDI_INDCCO != 'A')
    this.movimientoBanco.detalle_ldi = filtrados;

    const banco = this.cuentasBancaria.find(item => item.CBA_CODCBA == this.movimientoBanco.CBA_CODCBA);
    console.log('banco', banco)
    const item = new ItemDetalleOrdenPago();

    item.CCN_CODCCN = banco.CCN_CODCCN.trim();
    item.LDI_IMPNAC = this.movimientoBanco.PBA_IMPSOL;
    item.LDI_IMPMEX = this.movimientoBanco.PBA_IMPDOL;
    item.TDO_CODTDO = this.movimientoBanco.TDO_CODTDO;
    item.CLI_CODCLI = this.movimientoBanco.CLI_CODCLI;
    item.LDI_DOCREF = this.movimientoBanco.PBA_NUMDOC;
    item.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
    item.LDI_GLOLDI = this.movimientoBanco.PBA_GLOPBA;
    item.fca_codfca = this.movimientoBanco.fca_codfca;
    item.TMO_CODTMO = this.movimientoBanco.TMO_CODTMO;
    item.LDI_CORLDI = `0000${this.movimientoBanco.detalle_ldi.length + 1}`;
    item.LDI_CODUSE = this._authService.getUsuarioSistema();
    item.LDI_INDCCO = "A";
    item.LDI_INDDHA = "H";
    item.SUC_CODSUC = '01';
    item.ANO_CODANO = this.anioPeriodo;
    item.MES_CODMES = this.mesPeriodo;
    item.ldi_anoccl = this.anioPeriodo;
    item.ldi_mesccl = this.mesPeriodo;
    item.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    console.log('cuentasBancaria', this.cuentasBancaria)

    this.movimientoBanco.detalle_ldi.push(item);

    this.movimientoBanco.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);

    this.establecerDebeHaber();

    return true;

  }

  enviarFormulario(f: NgForm): void {

    this.movimientoBanco.detalle_ldi = this.dataSource.data.filter(item => item.LDI_INDCCO != "A");
    this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort)

    this.loaderReg = true;

    if (!this.esFormularioValido()) {
      this.loaderReg = false;
      return;
    }

    if (!this.esDetalleValido()) {
      this.loaderReg = false;
      return;
    }

    if (!this.esValidoMonto()) {
      this.loaderReg = false;
      return;
    }

    // console.log('orden de pago', this.egresoBanco)
    // this.loaderReg = false;
    // return;

    this.uid == '0' ? this.registrarEgresoBanco(f) : this.editarEgresoBanco(f)

  }

  registrarEgresoBanco(f: NgForm): void {
    console.log('ingreso a registrar', this.movimientoBanco)

    this.enviarFormulario$ = this._ordenPagoService.registrarOrdenPago(this.movimientoBanco).subscribe(
      (response: OrdenPago) => {
        this.movimientoBanco.LDC_CORLDC = response.LDC_CORLDC;
        this.uid = response.LDC_CORLDC;
        this.codano = response.ANO_CODANO;
        this.codmes = response.MES_CODMES;
        this.codsco = response.SCO_CODSCO;
        this.corldc = response.LDC_CORLDC;

        this.obtenerEgresoBanco();
        this._dialogService.openDialog(SuccessComponent, 'Orden de Pago Registrado', '350px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )
  }

  editarEgresoBanco(f: NgForm): void {

    if (this.estaConciliado) {
      this._snackBarService.showError('Esta orden de pago ya se encuentra conciliada', 'Ok');
    }

    this.enviarFormulario$ = this._ordenPagoService.editarOrdenPago(this.movimientoBanco).subscribe(
      response => {
        this.obtenerEgresoBanco();
        this._dialogService.openDialog(SuccessComponent, 'Orden de Pago Actualizado', '350px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderReg = false;
      }
    )

  }

  seleccionarTipoDocumentoDetalle(row: ItemDetalleOrdenPago): void {
    console.log('row', row);
  }

  seleccionarFecha(): void {
    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.movimientoBanco.PBA_FECORD)).subscribe(
      response => {
        this.movimientoBanco.PBA_TIPCAM = response[0].tipcam;
        this.calcularMontos();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
      }
    )
  }

  establecerTipoCambio(): void {
    if (this.movimientoBanco.PBA_TIPCAM <= 0) {
      return;
    }

    this.movimientoBanco.detalle_ldi.forEach(item => {
      item.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
    })

    this.calcularMontos();
    this.establecerDebeHaber();
  }

  seleccionarTipoDoc(): void {

    if (this.movimientoBanco.TDO_CODTDO) {

      if (this.movimientoBanco.TDO_CODTDO != 'CHE') {
        this.movimientoBanco.PBA_FECCHE = null;
      }

      this.serieDoc$ = this._almacenService.listarSerieDocumentos(this.movimientoBanco.TDO_CODTDO).subscribe(
        series => {
          console.log('serie', series)
          if (series && series.length > 0) {
            this.movimientoBanco.PBA_NUMDOC = `${series[0].sdo_codsdo}`;

            return;
          }


        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    } else {
      this.nroDocElement.nativeElement.focus()
      this.nroDocElement.nativeElement.select()
    }

  }

  seleccionarFlujoCaja(): void {
    // this.egresoBanco.detalle.forEach(item => item.fca_codfca = this.egresoBanco.flujoCaja)
  }

  ajusteDiferencia(): void {

    let montoSoles = 0;
    let montoDolares = 0;

    this.movimientoBanco.detalle_ldi.forEach(item => {
      if (item.LDI_INDDHA == 'D') {
        montoSoles += item.LDI_IMPNAC;
        montoDolares += item.LDI_IMPMEX;
      } else {
        montoSoles -= item.LDI_IMPNAC;
        montoDolares -= item.LDI_IMPMEX;
      }
    })

    // if (montoSoles != this.egresoBanco.PBA_IMPSOL || montoDolares != this.egresoBanco.PBA_IMPDOL) {

    //   this._snackBarService.showError('El Monto principal no coincide con los montos del detalle', 'OK');
    //   return false;

    // }

    const diferencia_mn = montoSoles - this.movimientoBanco.PBA_IMPSOL;
    const diferencia_me = montoDolares - this.movimientoBanco.PBA_IMPDOL;

    // console.table({ diferencia_mn, diferencia_me })

    if (this.movimientoBanco.detalle_ldi.length == 0) {
      this._snackBarService.showError("Agregar items en el detalle", "OK");
      return;
    }

    if (!((Math.abs(diferencia_mn) <= 0.05 && Math.abs(diferencia_mn) > 0) || (Math.abs(diferencia_me) <= 0.05 && Math.abs(diferencia_me) > 0))) {
      this._snackBarService.showError("La diferencia tiene que ser > 0 y <= a 0.05", "OK");
      return;
    }

    let ps_tmo_mn;
    let pn_impnac;
    let pn_impmex;
    let ps_codtdo;
    let ps_codccs;
    let ps_cuenta;
    let ls_cad;

    ps_tmo_mn = this.movimientoBanco.TMO_CODTMO;

    pn_impnac = Math.abs(diferencia_mn);
    pn_impmex = Math.abs(diferencia_me);

    ps_codtdo = (diferencia_mn < 0 || diferencia_me < 0) ? "AJP" : "AJG";
    ps_codccs = "";

    this._contabilidadService.configuraTipoDocCtaContable(ps_codtdo).subscribe(
      response => {
        console.log('response', response)
        ps_cuenta = response[0].ccn_codmn;
        ps_codccs = response[0].ccs_codccs;

        if (ps_cuenta) {

          let detailItem = new ItemDetalleOrdenPago();

          detailItem.LDI_INDDHA = (diferencia_mn < 0 || diferencia_me < 0) ? "D" : "H";
          detailItem.LDI_TCAMEU = 0;
          detailItem.LDI_INDDES = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.CLI_CODCLI = this.movimientoBanco.CLI_CODCLI;
          detailItem.LDI_INDCCO = "N";
          detailItem.LDI_INDDES = 0;
          detailItem.ldi_mesccl = "";
          detailItem.SCO_CODSCO = this.movimientoBanco.SCO_CODSCO;
          detailItem.SUC_CODSUC = "01";
          detailItem.TDO_DOCREF = "";
          detailItem.VDE_CODVDE = "";
          detailItem.LDI_TIPCAM = this.movimientoBanco.PBA_TIPCAM;
          detailItem.fca_codfca = this.movimientoBanco.fca_codfca;

          detailItem.CCN_CODCCN = ps_cuenta;
          detailItem.LDI_GLOLDI = this.movimientoBanco.PBA_GLOPBA;
          detailItem.TMO_CODTMO = ps_tmo_mn;
          detailItem.TDO_CODTDO = ps_codtdo;
          detailItem.LDI_DOCREF = this.movimientoBanco.PBA_NUMDOC;
          // detailItem.LDI_TIPCAM = 0;
          detailItem.LDI_INDDHA = (ps_codtdo == "AJP") ? "D" : "H";
          detailItem.LDI_IMPNAC = roundToDecimals(pn_impnac, 2);
          detailItem.LDI_IMPMEX = roundToDecimals(pn_impmex, 2);
          detailItem.LDI_INDCCO = "N";

          if (ps_codtdo == "AJP" && ps_codccs > 0) {
            detailItem.CCS_CODCCS = ps_codccs;
          }

          this.movimientoBanco.detalle_ldi.push(detailItem);

          this.dataSource = fillTable(this.movimientoBanco.detalle_ldi, this.paginator, this.sort);

          this.movimientoBanco.detalle_ldi.forEach((element, key) => {
            element.LDI_CORLDI = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
          })

          // setTimeout(() => {
          //   this.inputDescripcion.last.nativeElement.focus();
          //   this.inputDescripcion.last.nativeElement.slect();
          // }, 1);

          this.montosDebeHaber[0].mn = 0;
          this.montosDebeHaber[0].me = 0;
          this.montosDebeHaber[1].mn = 0;
          this.montosDebeHaber[1].me = 0;
          this.montosDebeHaber[2].mn = 0;
          this.montosDebeHaber[2].me = 0;

          this.movimientoBanco.detalle_ldi.forEach(element => {
            if (element.LDI_INDDHA == "D") {
              this.montosDebeHaber[0].mn += element.LDI_IMPNAC;
              this.montosDebeHaber[0].me += element.LDI_IMPMEX;
            } else {
              this.montosDebeHaber[1].mn += element.LDI_IMPNAC;
              this.montosDebeHaber[1].me += element.LDI_IMPMEX;
            }
          });

          this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
          this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

        }
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )

  }



  autocompletarNroDoc(): void {
    // this.egresoBanco.detalle.forEach(item => {
    //   item.ldi_docref = this.egresoBanco.cco_numdoc;
    // })
  }

  establecerNroDoc(event): void {

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    let numero = zeroPad(this.movimientoBanco.PBA_NUMDOC.substring(5, this.movimientoBanco.PBA_NUMDOC.length + 1), 8); // "000005"

    this.movimientoBanco.PBA_NUMDOC = this.movimientoBanco.PBA_NUMDOC.substring(0, 5) + numero;
    setTimeout(() => {
      this.monedaElement.focus();
      this.monedaElement.open();
    }, 1);
  }

  confirmarNuevoItem(item: ItemDetalleOrdenPago): void {
    if (
      item.CCN_CODCCN &&
      item.TDO_CODTDO &&
      item.LDI_DOCREF &&
      item.TMO_CODTMO &&
      item.LDI_INDDHA &&
      item.LDI_IMPNAC &&
      item.LDI_IMPMEX &&
      item.LDI_GLOLDI
    ) {
      item.isEditing = !item.isEditing;
      this.montosDebeHaber[0].mn = 0;
      this.montosDebeHaber[0].me = 0;
      this.montosDebeHaber[1].mn = 0;
      this.montosDebeHaber[1].me = 0;
      this.montosDebeHaber[2].mn = 0;
      this.montosDebeHaber[2].me = 0;

      this.movimientoBanco.detalle_ldi.forEach(element => {
        if (element.LDI_INDDHA == "D") {
          this.montosDebeHaber[0].mn += element.LDI_IMPNAC;
          this.montosDebeHaber[0].me += element.LDI_IMPMEX;
          return;
        }

        this.montosDebeHaber[1].mn += element.LDI_IMPNAC;
        this.montosDebeHaber[1].me += element.LDI_IMPMEX;

      });

      this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
      this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

      this.buttonAddRefElement.focus();
    } else {
      this._snackBarService.showError("Es necesario completar todos los campos", "OK");
    }
  }

  quitarItem(id): void {
    let details = [];
    this.movimientoBanco.detalle_ldi.forEach(element => {
      if (element.LDI_CORLDI != id) {
        details.push(element)
      }
    });

    this.movimientoBanco.detalle_ldi = details;
    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.movimientoBanco.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = `0000${key + 1}`;
    });
  }

  establecerDias(row: ItemDetalleOrdenPago): void {
    var newDate = new Date(this.movimientoBanco.PBA_FECORD);
    newDate.setDate(new Date(this.movimientoBanco.PBA_FECORD).getDate() + row.dias);

    row.LDI_FECVEN = newDate;
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  registroNuevo(): void {
    this.initialize();

    if (this.subdiarios.length == 1 && !this.movimientoBanco.LDC_CORLDC) {
      console.log('subdiarios', this.subdiarios[0].sco_codsco)
      this.movimientoBanco.SCO_CODSCO = this.subdiarios[0].sco_codsco;
    }

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.movimientoBanco.PBA_FECORD)).subscribe(
      tipoCambio => {
        this.movimientoBanco.PBA_TIPCAM = tipoCambio[0].tipcam;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )

    this.uid = '0';
  }

  volver(): void {
    this._router.navigate(['/egresos-banco']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoCambio$,
      this.serieDoc$,
      this.configuraTipDocCta$,
      this.period$,
      this.clientes$,
      this.tiposDocumento$,
      this.cuentas$,
      this.cuentasBancarias$,
      this.vendedores$,
      this.cheques$,
      this.subdiarios$,
      this.ordenes$,
      this.centros$,
      this.flujos$,
      this.loading$,
    ])
  }

}
