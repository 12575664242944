export class EditaContrato {
  codcia: string;
  codne0: any;
  descri: string;
  coduse: string;
  fecupd: Date;
  EDIC_CONT_NEC: Array<EditContNec>;

  constructor() {
    this.fecupd = new Date();
    this.EDIC_CONT_NEC = [];
  }
}

export class EditContNec {
  codne0: string;
  cornec: string;
  descri: string;
  codndc: string;
  descri1: string;
  codndc1: string;
  descri2: string;
  codndc2: string;
  descri3: string;
  codndc3: string;
  texto: string;

  constructor() {}
}
