import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationService } from "../../../configuration.service";
import { HttpClient } from "@angular/common/http";
import { FiltroMovimientos } from "src/app/models/almacen/filtro-movimientos";
import { Kardex } from "src/app/models";
import { ConsolidadoPartesDTO } from "./dto/consolidado-partes.dto";

@Injectable({
  providedIn: 'root',
})
export class KardexService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  listarKardex(anio, mes, filtro?: FiltroMovimientos): Observable<any> {

    let params = '';

    console.log('filtro', filtro)

    if( filtro.tipoMovimiento ) params += `?tipodocumento=${filtro.tipoMovimiento}`;
    if( filtro.almacenes ) params += `&almacen=${filtro.almacenes}`;
    if( filtro.auxiliares ) params += `&cliente=${filtro.auxiliares}`;
    if( filtro.motivos ) params += `&motivo=${filtro.motivos}`;
    if( filtro.fecha ) params += `&fecha=${filtro.fecha}`;
    if( filtro.tipoDocRef01 ) params += `&tipdocref1=${filtro.tipoDocRef01}`;
    if( filtro.referencia01 ) params += `&referencia1=${filtro.referencia01}`;
    if( filtro.tipoDocRef02 ) params += `&tipdocref2=${filtro.tipoDocRef02}`;
    if( filtro.referencia02 ) params += `&referencia2=${filtro.referencia02}`;
    // if( filtro.cliente ) params += `&cliente=${filtro.cliente}`;
    if( filtro.centroCosto ) params += `&centrocosto=${filtro.centroCosto}`;

    console.log('params', params)

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/kardex/listar/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}${params}`
    );
  }

  obtenerKardex(year, month, almacen, tipo, numeroParte): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/kardex/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}/${almacen}/${tipo}/${numeroParte}`
    );
  }

  // indlot
  obtenerConfiguracionTecnicaAlmacen(): Observable<any>{
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablaconta/listar/${this._configurationService.obtenerCompaniaCliente()}/CONFIGURACIONTECNICAALMACEN`
    );
  }

  registrarKardex(payload: Kardex): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/kardex/crear`,
      payload
    );
  }

  editarKardex(payload: Kardex): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/kardex/modificar`,
      payload
    );
  }

  consolidarPartes(payload: Array<ConsolidadoPartesDTO>): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/kardex/consolidado`,
      payload
    );
  }

  consultaStockPorLote(codeSuc, anioPeriodo, mesPeriodo, almacen, producto): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/stock/mostrar/LOTE/${this._configurationService.obtenerCompaniaCliente()}/${codeSuc}/${anioPeriodo}/${mesPeriodo}/${almacen}/${producto}/0000000000?lista=true`
    );
  }

}
