import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_GRUPO_ACTIVIDAD } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { GrupoActividadService } from "src/app/services/api/crm/maestros";

@Component({
  selector: 'grupo-actividad-list',
  templateUrl: './grupo-actividad-list.component.html',
  styleUrls: ['./grupo-actividad-list.component.scss']
})
export class GrupoActividadListComponent implements OnInit, OnDestroy {

  gruposActividad$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_GRUPO_ACTIVIDAD;

  gruposActividad: Array<any> = [];

  constructor(
    private store: Store<PavsoState>,
    private _grupoActividadService: GrupoActividadService,
    private _snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerGruposActividad();
      }
    })
  }

  obtenerGruposActividad(): void {
    this.gruposActividad$ = this._grupoActividadService.obtenerGruposActividad().subscribe(
      grupos => this.gruposActividad = grupos,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.gruposActividad$,
      this.loading$,
    ])
  }

}
