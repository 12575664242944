import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { CondicionPago } from "src/app/models/compras";
import { ApiCondicionPagoVentasService } from "src/app/services/api/ventas/maestros/condicion-pago.service";

@Component({
  selector: 'condicion-pago-form',
  templateUrl: './condicion-pago-form.component.html',
  styleUrls: ['./condicion-pago-form.component.scss']
})
export class CondicionPagoFormComponent implements OnInit, OnDestroy {

  condicion$: Subscription;
  loading$: Subscription;

  uid: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;

  btnName: string;

  condicion: CondicionPago;

  loaderReg: boolean;
  loaderData: boolean;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _apiCondicionVentasService: ApiCondicionPagoVentasService,
    private _snackBarService: SnackBarService,
    private _router: Router,
    private store: Store<PavsoState>
  ) {
    this.condicion = new CondicionPago();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => this.uid = id)

    if(this.uid == '0') {
      this.btnName = 'general.button.store';
      return;
    }

    this.btnName = 'general.button.update';
    this.obtenerCondicionPago();
  }

  obtenerCondicionPago(): void {
    this.condicion$ = this._apiCondicionVentasService.obtenerCondicionPago(this.uid).subscribe(
      condicion => {

        this.condicion = condicion[0];

      },
      error => {
        this._snackBarService.showError('Error al obtener condición de pago', 'Ok');
      }
    )
  }

  enviarFormulario(): void {}

  volver(): void {
    this._router.navigate(['/modulo-ventas/condiciones-de-pago'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.condicion$,
      this.loading$
    ])
  }

}
