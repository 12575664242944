import { NgModule } from "@angular/core";
import { LibroElectronicoComponent } from './libro-electronico.component'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { informesComponentsLibroElectronico } from "./pages/informes";
import { KardexLeComponent } from "./pages/kardex-le/kardex-le.component";
import { maestrosComponentLibroElectronico } from "./pages/maestros";
import { SharedModule } from "@shared/shared.module";
import { MaterialModule } from "src/app/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { consultaComponentLibroElectronico } from "./pages/consulta";
import { PanelLibroElectronicoComponent } from "./pages/panel/panel-libro-electronico.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";

@NgModule({
  declarations: [
    LibroElectronicoComponent,
    PanelLibroElectronicoComponent,
    ...consultaComponentLibroElectronico,
    ...informesComponentsLibroElectronico,
    ...informesComponentsLibroElectronico,
    KardexLeComponent,
    ...maestrosComponentLibroElectronico
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class LibroElectronicoModule {}
