<app-pavso-title-section [title]="'Máquina'" [module]="'Producción'" [estado]="maquina.maq_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="maquina.maq_codmaq" [configText]="['numeros','letras','upper']" matInput placeholder="Código" maxlength="4  " name="maq_codmaq" required [disabled]="uid!='0'">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="maquina.maq_desmaq" matInput placeholder="Descripción" name="maq_desmaq" required [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Capacidad</mat-label>
            <input type="number" [(ngModel)]="maquina.maq_capmaq" matInput placeholder="Ej. 1000" name="maq_capmaq" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
            <select-search
            [label]="'Unidad de Medida'"
            [placeholder]="'Ej. Juan Ibañez'"
            [name]="'ume_codume'"
            [value]="'ume_codume'"
            [description]="'ume_descri'"
            [data]="unidadesDeMedida"
            (cambioSelect)="maquina.ume_codume = $event"
            [model]="maquina.ume_codume"
            [required]="true">
          </select-search>
        </div>
      </div>

    </mat-card>
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-produccion/maquinas"
    ></pav-form-actions>


  </form>
</div>
