export class PriceList {
  CIA_CODCIA: any;
  TLP_CODTLP: any;
  LPC_CODLPC: any;
  LPC_FECVIG: any;
  LPC_GLOLPC: any;
  LPC_INDSTA: string;
  LPC_CODUSE: string;
  LPC_FECUPD: any;
  GCP_CODGCP: any;
  VPR_NUMVPR: any;
  LPC_INDNUE: any;
  detalle: any;
  constructor(
  ){
    this.LPC_FECUPD = new Date();
    this.LPC_FECVIG = new Date();
    this.LPC_INDNUE = 1;
    this.LPC_INDSTA = "1";
    this.detalle = [];
  }
}
