import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { DialogService } from "@shared/services/dialog.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { IParam } from "src/app/services/api/utilities/params";
import { PedidoService } from "src/app/services/api/ventas/operaciones/pedido.service";

@Component({
  selector: 'pedido-list-dialog',
  templateUrl: './pedido-list-dialog.html',
  styleUrls: ['./pedido-list-dialog.scss']
})
export class PedidoListDialog implements OnInit, OnDestroy {

  pedidos$: Subscription;
  pedidos: any[] = [];

  anioActividad: string;
  mesActividad: string;

  periodo$: Subscription;

  years: any[] = [];

  months =
  [
    {
      id: "01",
      name: "ENERO",
    },
    {
      id:"02",
      name: "FEBRERO",
    },
    {
      id: "03",
      name: "MARZO",
    },
    {
      id: "04",
      name: "ABRIL",
    },
    {
      id: "05",
      name: "MAYO",
    },
    {
      id: "06",
      name: "JUNIO",
    },
    {
      id: "07",
      name: "JULIO",
    },
    {
      id: "08",
      name: "AGOSTO",
    },
    {
      id: "09",
      name: "SETIEMBRE",
    },
    {
      id: "10",
      name: "OCTUBRE",
    },
    {
      id: "11",
      name: "NOVIEMBRE",
    },
    {
      id: "12",
      name: "DICIEMBRE"
    }
  ];

  displayedColumns: string[] = ['acciones', 'PCC_NUMPCC', 'CLI_CODCLI', 'cli_nomcli', 'PCC_FECDOC', 'tmo_codtmo', 'PCC_IMPTOT', 'vde_nomvde', 'PCC_INDSTA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderData: boolean;

  constructor(
    public dialogRef: MatDialogRef<PedidoListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _pedidoService: PedidoService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>
  ){
    this.periodo$ = this.store.select('period').subscribe(state => {
      this.anioActividad = state.year;
      this.mesActividad = state.month;
    })

  }

  ngOnInit(): void {
    this.establecerAnios();
    this.obtenerPedidos();
  }

  establecerAnios(): void {
    const today = new Date();

    for (let index = 2000; index <= today.getFullYear(); index++) {
      this.years.push({
        id: index.toString(),
        name: index.toString()
      })
    }
  }

  obtenerPedidos(): void {
    this.loaderData = true;

    let params: Array<IParam> = [
      {
        param: 'anio',
        value: this.anioActividad
      },
      {
        param: 'mes',
        value: this.mesActividad
      },
    ];

    this.pedidos$ = this._pedidoService.obtenerPedidos('01', {params}).subscribe(
      (data) => {
        this.dataSource = fillTable(data, this.paginator, this.sort);
        this.loaderData = false;

        console.log(data);
      }
    )
  }

  seleccionarPedido(pedidoId): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de copiar este pedido?', '', '', '').subscribe(result => {
      if(result) {
        this.dialogRef.close(pedidoId);
      }
    })

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.pedidos$]);
  }

}
