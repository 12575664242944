<app-pavso-title-section [title]="'apps.crm.maestros.plantilla.title' | translate" [module]="'CRM'" [estado]="plantilla.indsta"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
    <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
        <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>{{'apps.crm.maestros.etapa.form.code' | translate}}</mat-label>
                  <input type="text" [(ngModel)]="plantilla.codpoc" matInput [placeholder]="'apps.crm.maestros.etapa.form.code' | translate" name="codoet" readonly>
              </mat-form-field>
              </div>
              <div class="col s12 m12 l6">
                  <mat-form-field>
                      <mat-label>{{'apps.crm.maestros.etapa.form.description' | translate}}</mat-label>
                      <input type="text" [(ngModel)]="plantilla.descri" matInput [placeholder]="'apps.crm.maestros.etapa.form.description' | translate" name="descri" required>
                  </mat-form-field>
              </div>
          </div>
        </mat-card>
        <br>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> Órden </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="input-orden" appearance="legacy">
                        <mat-label>Órden</mat-label>
                        <input matInput placeholder="Órden" [value]="element.ordpod">
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Descripción </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="example-full-width" appearance="legacy">
                        <mat-label>Descripción</mat-label>
                        <input matInput placeholder="Descripción" [value]="element.pod_concep">
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef> Referencia </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="example-full-width" appearance="legacy">
                        <mat-label>Referencia</mat-label>
                        <input matInput placeholder="Referencia" [value]="element.refere">
                    </mat-form-field>
                 </td>
            </ng-container>

            <ng-container matColumnDef="symbol">
                <th mat-header-cell *matHeaderCellDef> Tipo de dato </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="example-full-width" appearance="legacy">
                        <mat-label>Tipo de dato</mat-label>
                        <input matInput placeholder="Tipo de dato" [value]="element.otr_descri">
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="ume">
                <th mat-header-cell *matHeaderCellDef> U.M. </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field class="example-full-width" appearance="legacy">
                        <mat-label>U.M.</mat-label>
                        <input matInput placeholder="U.M." [value]="element.codume">
                    </mat-form-field>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
          <div id="botonesAdicionales">
          </div>

        </pav-form-actions>
    </form>
</div>
