import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DocumentSerie } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class SerieDocumentoService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {

  }

  /**
   * Obtener series
   * @returns
   */
  obtenerSeriesDocumento(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/prueba/seriedocumentos/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   * Obtener serie
   * @param type
   * @param serie
   * @returns
   */
  obtenerSerieDocumento(type, serie): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/prueba/mostrarseriedocumento/${this._configurationService.obtenerCompaniaCliente()}/${type}/${serie}`
    );
  }

  /**
   *
   * @param serieDocumento
   * @returns
   */
  registrarSerieDocumento(serieDocumento: DocumentSerie) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/serie-documento`,
      serieDocumento
    );
  }

  /**
   *
   * @param serieDocumento
   * @returns
   */
  actualizarSerieDocumento(serieDocumento: DocumentSerie) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/ventas/serie-documento`,
      serieDocumento
    );
  }

}
