<app-pavso-title-section [title]="'Control de cierre contable'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s3">
        <mat-form-field>
          <mat-label>Código</mat-label>
          <input matInput placeholder="Código">
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select>
            <mat-option value="1">
              vigente
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s9">
        <mat-form-field>
          <mat-label>Nomb. Largo</mat-label>
          <input matInput placeholder="Nomb. Largo">
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field>
          <mat-label>Nomb. Corto</mat-label>
          <input matInput placeholder="Nomb. Corto">
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <div class="row">
    <div class="col s8">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" matSort >

            <ng-container matColumnDef="codigo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.CHO_CODCHO}} </td>
            </ng-container>

            <ng-container matColumnDef="nombre_largo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Largo </th>
                <td mat-cell *matCellDef="let row" data-label="Nombre Largo"> {{row.CHO_NOMBRE}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion_corta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción Corta </th>
                <td mat-cell *matCellDef="let row" data-label="Descripción Corta"> {{row.CHO_NROLIC}} </td>
            </ng-container>

            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.CHO_NROLIC}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
    <div class="col s4">
      <mat-card class="mat-elevation-z0">
        <fieldset>
          <legend>Período Real</legend>
          <mat-form-field>
            <mat-label>Año</mat-label>
            <mat-select>
              <mat-option value="1">
                2021
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mes</mat-label>
            <mat-select>
              <mat-option value="1">
                ENERO
              </mat-option>
            </mat-select>
          </mat-form-field>
        </fieldset>
        <br>
        <fieldset>
          <legend>Período Contable Abierto</legend>
          <mat-form-field>
            <mat-label>Año</mat-label>
            <mat-select>
              <mat-option value="1">
                2021
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Mes</mat-label>
            <mat-select>
              <mat-option value="1">
                ENERO
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-stroked-button color="primary">Genera Período Contable</button>
          <mat-form-field>
            <mat-label>Número</mat-label>
            <input matInput placeholder="Número">
          </mat-form-field>
        </fieldset>
      </mat-card>
    </div>
  </div>

</div>

