export class InteresBanco {
  periodo: any;
  detalle: Array<InteresBancoDetalle>
}

export class InteresBancoDetalle {
  codban: any;
  banco: any;
  codtmo: any;
  porint: any;
  isEditing: boolean;
  correlativo: any;

  constructor() {
    this.isEditing = true;
  }
}
