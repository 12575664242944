import { PavsoColors } from "@data/enums/color.enum";
import { inkaselect_logo } from "@shared/resources/inkaselect-logo";
import { logo } from "@shared/resources/logo";
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from "@utils/formats/date.format";
import { numberWithCommas } from "@utils/formats/number.format";
import { horizontalBorder, noBorders, openLeftBorder, openRightBorder, topBorder, verticalBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Canvas, Cell, Img, Line, PdfMakeWrapper, QR, Stack, Table, Txt } from "pdfmake-wrapper";

/**
 * PDF Movimientos
 * @param id
 */
export async function verPdfMovimiento(headers, data) {
  const pdf = new PdfMakeWrapper();
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [5, 2, 10, 10], alignment: 'right' },
    ];
  })
  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(logo).alignment('left').width(120).build(),
          ]).end
        ).end,

        new Cell(
          new Stack([
            new Txt(`COMERCIALIZADORA ASIA PERU S.A.C.`).fontSize(11).alignment('center').color('#000').bold().end,
            new Txt(`RUC: 20550453135 AV. SANTA ROSA DE COLLANAC P-64A - C.C. COLLANAC - MANCHAY - PACHACAMAC`).fontSize(9).alignment('center').color('#000').end,
            pdf.ln(2),
          ]).alignment('center').end,
        ).end,
        new Cell(
          new Stack([
            new Canvas([
              new Line([0, 10], [140, 10]).end
            ]).margin(0).end,
            new Table([
              [
                new Cell(
                  new Txt('Hora de impresión:').fontSize(8).end
                ).end,
                new Cell(
                  new Txt('13:09:50').fontSize(8).end
                ).end
              ],
              [
                new Cell(
                  new Txt('Fecha:').fontSize(8).end
                ).end,
                new Cell(
                  new Txt(formatDateWithSlash(new Date())).fontSize(8).end
                ).end
              ],
            ]).layout('noBorders').widths([70, 50]).end,
            new Canvas([
              new Line([0, 10], [140, 10]).end
            ]).end,
            new Table([
              [
                new Cell(
                  new Txt('Usuario de:').bold().fontSize(8).end
                ).end,
                new Cell(
                  new Txt('PAVSO').bold().fontSize(8).end
                ).end
              ],
            ]).layout('noBorders').widths([70, 50]).end,
          ]).end
        ).end,
      ]
    ]).layout('noBorders').widths([140, 225, 90]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`VALE DE INGRESO N° :`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(`AL0001ING2100001`).fontSize(11).bold().end
          ]).end
        ).end,
        new Cell(
          new Stack([
            pdf.ln(1),
            new Txt(``).fontSize(11).bold().end
          ]).end
        ).end,
        new Table([
          [

          ],
        ]).layout('noBorders').widths([20, 20, 20, 20]).end
      ]
    ]).layout('noBorders').widths([130, 120, 110, 120]).end
  )

  pdf.add(
    new Canvas([
      new Line([0, 10], [510, 10]).end
    ]).end,
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('Almacen:').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('ALMACEN DE PRODUCTOS').fontSize(8).end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Movimiento:').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('FE07 - DEVOLUCION DE PARCIAL').fontSize(8).end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Fecha:').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('02/07/2021').fontSize(8).end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Proveedor:').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('ACERIN S.A.C.').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('').fontSize(7).end
                ]).end
              ).end,
            ],
            [
              new Cell(
                new Txt('T. Documento:').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Txt('N° Documento :').bold().fontSize(7).end
              ).end,
            ],
            [
              new Cell(
                new Txt('Observaciones:').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('GENERADO DE CTA.CTE: 202107050100007 NCRF0010000000457').fontSize(8).end
              ).end,
              new Cell(
                new Stack([
                  new Txt('').fontSize(7).end
                ]).end
              ).end,
            ]
          ]).layout('noBorders').widths([80, 280, 120]).end
        ).end
      ],

    ]).layout('noBorders').widths([500]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('Item').bold().fontSize(8).end
              ).end,
              new Cell(
                new Txt('Código').bold().fontSize(8).end
              ).end,
              new Cell(
                new Txt('Descripción').bold().fontSize(8).end
              ).end,
              new Cell(
                new Txt('Modelo').bold().fontSize(8).end
              ).end,
              new Cell(
                new Txt('Unid.').alignment('right').bold().fontSize(8).end
              ).end,
              new Cell(
                new Txt('Cantidad').alignment('right').bold().fontSize(8).end
              ).end,
            ]
          ]).layout('noBorders').widths([20, 60, 170, 90, 50, 65]).end
        ).end
      ]
    ]).end
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Table([
            [
              new Cell(
                new Txt('0001').fontSize(8).end
              ).end,
              new Cell(
                new Txt('').fontSize(8).end
              ).end,
              new Cell(
                new Txt('BC COOLER(CONSERVADOR)PTA.DE VIDRIO VERTICAL 320LT. VERDE').fontSize(8).end
              ).end,
              new Cell(
                new Txt('BC-3200GR ').fontSize(8).end
              ).end,
              new Cell(
                new Txt('UND').fontSize(8).alignment('right').end
              ).end,
              new Cell(
                new Txt('2.000').fontSize(8).alignment('right').end
              ).end,
            ]
          ]).layout('noBorders').widths([20, 60, 170, 90, 50, 65]).end
        ).end
      ]
    ]).layout('noBorders').end
  )

  pdf.create().open();
}

/**
 * Generar el reporte de saldos por producto
 * @param headers
 * @param data
 */
export async function generarReporteSaldosPorProducto(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  data.forEach(item => {

    let [totIni, totIng, totSal, totSaldo] = [0, 0, 0, 0];

    item.detalle.forEach(el => {

      detalle.push([
        new Cell(
          new Txt(el.prd_codprd).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(el.prd_desesp).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ALM_DESCRI).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(el.ume_codume).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.spa_canini.toFixed(3))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.spa_caning.toFixed(3))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.spa_cansal.toFixed(3))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(el.spa_canfin.toFixed(3))).alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ],)

      totIni += el.spa_canini;
      totIng += el.spa_caning;
      totSal += el.spa_cansal;
      totSaldo += el.spa_canfin;

    })

    detalle.push([
      new Cell(
        new Txt('').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totIni.toFixed(3))).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totIng.toFixed(3))).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totSal.toFixed(3))).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totSaldo.toFixed(3))).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
    ])
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RINV_SALD_PROD').fontSize(7).end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('SALDOS POR PRODUCTO').alignment('center').fontSize(10).alignment('center').bold().end,
            new Txt(`${headers.codano} - ${headers.codmes}`).fontSize(8).bold().alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).alignment('right').fontSize(7).end,
            new Txt('17:39:31').alignment('right').fontSize(7).end,
          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt('Código').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Descripción').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Almacén').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('UND').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Inicial').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Ingreso').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Salida').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Saldo Final').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      ],
      ...detalle,

    ]).widths([50, 150, 95, 30, 40, 40, 40, 40]).headerRows(2).end
  )

  pdf.create().open();

}

/**
 * Generar el reporte de saldos por lote
 * @param headers
 * @param data
 */
export async function generarReporteSaldosPorLote(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  data.forEach(item => {

    detalle.push([
      new Cell(
        new Txt(`Producto: ${item.prd_codprd} |  ${item.prd_desesp}`).alignment('center').bold().fontSize(7).end
      ).colSpan(8).border(noBorders).alignment('right').end,
      ...obtenerNulls(7)
    ])

    let [totIni, totIng, totSal, totSaldo] = [0, 0, 0, 0];

    item.detalle.forEach(el => {
      let [totIniAlm, totIngAlm, totSalAlm, totSaldoAlm] = [0, 0, 0, 0];
      detalle.push([
        new Cell(
          new Txt(`Almacen: ${el.alm_codalm} |  ${el.alm_descri}`).bold().fontSize(7).end
        ).colSpan(8).border(noBorders).end,
        ...obtenerNulls(7)
      ])
      el.detalle.forEach(row => {


        detalle.push([
          new Cell(
            new Txt(row.spa_numlot).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(row.lot_feccre)).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(row.lot_fecven)).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(row.ume_codume).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(row.spa_canini.toFixed(3))).alignment('right').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(row.spa_caning.toFixed(3))).alignment('right').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(row.spa_cansal.toFixed(3))).alignment('right').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(row.spa_canfin.toFixed(3))).alignment('right').fontSize(6).end
          ).border(noBorders).end,
        ],)

        totIni += row.spa_canini;
        totIng += row.spa_caning;
        totSal += row.spa_cansal;
        totSaldo += row.spa_canfin;

        totIniAlm += row.spa_canini;
        totIngAlm += row.spa_caning;
        totSalAlm += row.spa_cansal;
        totSaldoAlm += row.spa_canfin;


      })
      detalle.push([
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(totIniAlm.toFixed(3))).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totIngAlm.toFixed(3))).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totSalAlm.toFixed(3))).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(numberWithCommas(totSaldoAlm.toFixed(3))).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
      ])
    })


    detalle.push([
      new Cell(
        new Txt('').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(totIni.toFixed(3))).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totIng.toFixed(3))).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totSal.toFixed(3))).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(totSaldo.toFixed(3))).alignment('right').bold().fontSize(6).end
      ).border(topBorder).end,
    ])
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RINV_SALD_PROD').fontSize(7).end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('SALDOS POR LOTE').alignment('center').fontSize(10).alignment('center').bold().end,
            new Txt(`${headers.codano} - ${headers.codmes}`).fontSize(8).bold().alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).alignment('right').fontSize(7).end,
            new Txt('17:39:31').alignment('right').fontSize(7).end,
          ]).end
        ).border(noBorders).end
      ],
      [
        new Cell(
          new Txt('Lote').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Fecha Creación').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Fecha Venc.').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('UND').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Inicial').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Ingreso').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Salida').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
        new Cell(
          new Txt('Saldo Final').end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      ],
      ...detalle,

    ]).widths([120, 65, 105, 30, 40, 40, 40, 40]).headerRows(2).end
  )

  pdf.create().open();

}

/**
 * Generar reporte de saldos por producto valorizado
 * @param headers
 * @param data
 */
export async function generarReporteSaldosPorProductoValorizado(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  let [tgSI1, tgIng1, tgSal1, tgSF1, tgSI2, tgIng2, tgSal2, tgSF2] = [0, 0, 0, 0, 0, 0, 0, 0]

  data.forEach(item => {

    detalle.push([
      new Cell(
        new Txt('ALMACEN:').end
      ).border(noBorders).decoration('underline').fontSize(7).bold().end,
      new Cell(
        new Txt(item.alm_codalm).end
      ).border(noBorders).decoration('underline').fontSize(7).bold().end,
      new Cell(
        new Txt(item.ALM_DESCRI).end
      ).border(noBorders).decoration('underline').fontSize(7).bold().end,
      new Cell(
        new Txt('').end
      ).border(noBorders).fontSize(7).bold().end,
      new Cell(
        new Txt('').end
      ).border(noBorders).fontSize(7).bold().end,
      new Cell(
        new Txt('').end
      ).border(noBorders).fontSize(7).bold().end,
      new Cell(
        new Txt('').end
      ).border(noBorders).fontSize(7).bold().end,
      new Cell(
        new Txt('').end
      ).border(noBorders).fontSize(7).bold().end,
      new Cell(
        new Txt('').end
      ).border(noBorders).fontSize(7).bold().end,
      new Cell(
        new Txt('').end
      ).border(noBorders).fontSize(7).bold().end,
      new Cell(
        new Txt('').end
      ).border(noBorders).fontSize(7).bold().end,
    ],)

    let [totSI1, totIng1, totSal1, totSF1, totSI2, totIng2, totSal2, totSF2] = [0, 0, 0, 0, 0, 0, 0, 0]

    item.detalle.forEach(element => {

      totSI1 += element.spa_canini;
      totIng1 += element.spa_caning;
      totSal1 += element.spa_cansal;
      totSF1 += element.spa_canfin;
      totSI2 += element.spa_mnpini;
      totIng2 += element.spa_mnping;
      totSal2 += element.spa_mnpsal;
      totSF2 += element.spa_mnpfin;

      detalle.push([
        new Cell(
          new Txt(element.prd_codprd).end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(element.prd_desesp).end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(numberWithCommas(element.spa_canini.toFixed(3))).alignment('right').end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(numberWithCommas(element.spa_caning.toFixed(3))).alignment('right').end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(numberWithCommas(element.spa_cansal.toFixed(3))).alignment('right').end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(numberWithCommas(element.spa_canfin.toFixed(3))).alignment('right').end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(numberWithCommas(element.spa_mnpini.toFixed(3))).alignment('right').end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(numberWithCommas(element.spa_mnping.toFixed(3))).alignment('right').end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(numberWithCommas(element.spa_mnpsal.toFixed(3))).alignment('right').end
        ).border(noBorders).fontSize(7).end,
        new Cell(
          new Txt(numberWithCommas(element.spa_mnpfin.toFixed(3))).alignment('right').end
        ).border(noBorders).fontSize(7).end,
      ],)

    })

    detalle.push([
      new Cell(
        new Txt('TOTAL ALMACÉN ').bold().end
      ).border(noBorders).fontSize(7).colSpan(2).end,
      new Cell(
        new Txt('').bold().end
      ).border(noBorders).fontSize(7).end,
      new Cell(
        new Txt(item.ALM_DESCRI).bold().end
      ).border(noBorders).fontSize(7).end,
      new Cell(
        new Txt(numberWithCommas(totSI1.toFixed(3))).alignment('right').bold().end
      ).border(topBorder).fontSize(7).end,
      new Cell(
        new Txt(numberWithCommas(totIng1.toFixed(3))).alignment('right').bold().end
      ).border(topBorder).fontSize(7).end,
      new Cell(
        new Txt(numberWithCommas(totSal1.toFixed(3))).alignment('right').bold().end
      ).border(topBorder).fontSize(7).end,
      new Cell(
        new Txt(numberWithCommas(totSF1.toFixed(3))).alignment('right').bold().end
      ).border(topBorder).fontSize(7).end,
      new Cell(
        new Txt(numberWithCommas(totSI2.toFixed(3))).alignment('right').bold().end
      ).border(topBorder).fontSize(7).end,
      new Cell(
        new Txt(numberWithCommas(totIng2.toFixed(3))).alignment('right').bold().end
      ).border(topBorder).fontSize(7).end,
      new Cell(
        new Txt(numberWithCommas(totSal2.toFixed(3))).alignment('right').bold().end
      ).border(topBorder).fontSize(7).end,
      new Cell(
        new Txt(numberWithCommas(totSF2.toFixed(3))).alignment('right').bold().end
      ).border(topBorder).fontSize(7).end,
    ],)

    tgSI1 += totSI1;
    tgIng1 += totIng1;
    tgSal1 += totSal1;
    tgSF1 += totSF1;
    tgSI2 += totSI2;
    tgIng2 += totIng2;
    tgSal2 += totSal2;
    tgSF2 += totSF2;

  })

  detalle.push([
    new Cell(
      new Txt('TOTAL GENERAL ').bold().end
    ).border(openRightBorder).fontSize(7).colSpan(2).end,
    new Cell(
      new Txt('').bold().end
    ).border(horizontalBorder).fontSize(7).end,
    new Cell(
      new Txt('').end
    ).border(horizontalBorder).fontSize(7).bold().end,
    new Cell(
      new Txt(numberWithCommas(tgSI1.toFixed(3))).alignment('right').bold().end
    ).border(horizontalBorder).fontSize(7).end,
    new Cell(
      new Txt(numberWithCommas(tgIng1.toFixed(3))).alignment('right').bold().end
    ).border(horizontalBorder).fontSize(7).end,
    new Cell(
      new Txt(numberWithCommas(tgSal1.toFixed(3))).alignment('right').bold().end
    ).border(horizontalBorder).fontSize(7).end,
    new Cell(
      new Txt(numberWithCommas(tgSF1.toFixed(3))).alignment('right').bold().end
    ).border(horizontalBorder).fontSize(7).end,
    new Cell(
      new Txt(numberWithCommas(tgSI2.toFixed(3))).alignment('right').bold().end
    ).border(horizontalBorder).fontSize(7).end,
    new Cell(
      new Txt(numberWithCommas(tgIng2.toFixed(3))).alignment('right').bold().end
    ).border(horizontalBorder).fontSize(7).end,
    new Cell(
      new Txt(numberWithCommas(tgSal2.toFixed(3))).alignment('right').bold().end
    ).border(horizontalBorder).fontSize(7).end,
    new Cell(
      new Txt(numberWithCommas(tgSF2.toFixed(3))).alignment('right').bold().end
    ).border(openLeftBorder).fontSize(7).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RINV_SALD_PRODV').fontSize(7).end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('SALDOS POR PRODUCTO - VALORIZADO ').bold().alignment('center').fontSize(10).end,
            new Txt(`${headers.codano} ${headers.codmes}`).bold().alignment('center').fontSize(8).end,
            new Txt(`${headers.currency == 'SO' ? 'Moneda Nacional' : 'Moneda Extranjera'}`).bold().fontSize(8).alignment('center').end
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).alignment('right').fontSize(7).end,
            new Txt('17:48:37').alignment('right').fontSize(7).end
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('PRODUCTOS').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).alignment('center').bold().colSpan(3).fontSize(8).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('UNIDADES').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).alignment('center').bold().colSpan(4).fontSize(8).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('VALORIZACIÓN').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).alignment('center').bold().colSpan(4).fontSize(8).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt('COD. PROD.').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('Nro. PARTE').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('DESCRIPCIÓN').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('SALDO INICIAL').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('INGRESO').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('SALIDA').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('SALDO FINAL').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('SALDO INICIAL').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('INGRESO').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('SALIDA').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
        new Cell(
          new Txt('SALDO FINAL').end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).fontSize(7).bold().end,
      ],
      ...detalle,



    ]).widths([60, 40, 165, 55, 55, 55, 55, 55, 55, 55, 55]).end
  );

  pdf.create().open();

}

/**
 * Generar reporte resumen anual por tipo de inventario
 * @param headers
 * @param data
 */
export async function generarReporteResumenAnualTipoInventario(headers, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`${element.alm_codalm} ${element.alm_descri}`).bold().alignment('center').fontSize(8).end
      ).border(noBorders).colSpan(15).end,
      ...obtenerNulls(14)
    ],)

    let [siEne, siFeb, siMar, siAbr, siMay, siJun, siJul, siAgo, siSet, siOct, siNov, siDic] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    element.detalle.forEach(el => {
      el.detalle.forEach(item => {
        item.detalle.forEach(it => {
          if (it.tdo_codtdo == 'INC') {
            siEne += headers.currency == 'SO' ? it.kad_valingsp01 : it.kad_valingdp01
            siFeb += headers.currency == 'SO' ? it.kad_valingsp02 : it.kad_valingdp02
            siMar += headers.currency == 'SO' ? it.kad_valingsp03 : it.kad_valingdp03
            siAbr += headers.currency == 'SO' ? it.kad_valingsp04 : it.kad_valingdp04
            siMay += headers.currency == 'SO' ? it.kad_valingsp05 : it.kad_valingdp05
            siJun += headers.currency == 'SO' ? it.kad_valingsp06 : it.kad_valingdp06
            siJul += headers.currency == 'SO' ? it.kad_valingsp07 : it.kad_valingdp07
            siAgo += headers.currency == 'SO' ? it.kad_valingsp08 : it.kad_valingdp08
            siSet += headers.currency == 'SO' ? it.kad_valingsp09 : it.kad_valingdp09
            siOct += headers.currency == 'SO' ? it.kad_valingsp10 : it.kad_valingdp10
            siNov += headers.currency == 'SO' ? it.kad_valingsp11 : it.kad_valingdp11
            siDic += headers.currency == 'SO' ? it.kad_valingsp12 : it.kad_valingdp12
          }

        })
      })
    })
    detalle.push([
      new Cell(
        new Txt(`Saldo Inicial`).fontSize(7).end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(siEne.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siFeb.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siMar.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siAbr.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siMay.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siJun.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siJul.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siAgo.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siSet.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siOct.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siNov.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(siDic.toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas((siEne + siFeb + siMar + siAbr + siMay + siJun + siJul + siAgo + siSet + siOct + siNov + siDic).toFixed(2))).alignment('right').fontSize(7).end
      ).border(noBorders).end,
    ])

    element.detalle.forEach(el => {

      let [totEne, totFeb, totMar, totAbr, totMay, totJun, totJul, totAgo, totSet, totOct, totNov, totDic, totTot] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      detalle.push([
        new Cell(
          new Txt(`${el.tin_codtin} ${el.tin_descri}`).bold().alignment('left').fontSize(7).end
        ).border(noBorders).colSpan(15).end,
        ...obtenerNulls(14)
      ],)

      el.detalle.forEach(item => {

        let [tEne, tFeb, tMar, tAbr, tMay, tJun, tJul, tAgo, tSet, tOct, tNov, tDic, tTot] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (item.tdo_codtdo != 'INC') {
          detalle.push([
            new Cell(
              new Txt(item.tdo_descri).bold().alignment('left').fontSize(8).end
            ).border(noBorders).colSpan(15).end,
            ...obtenerNulls(14)
          ],)

          item.detalle.forEach(it => {

            const val01 = headers.currency == 'SO' ? it.kad_valingsp01 : it.kad_valingdp01;
            const val02 = headers.currency == 'SO' ? it.kad_valingsp02 : it.kad_valingdp02;
            const val03 = headers.currency == 'SO' ? it.kad_valingsp03 : it.kad_valingdp03;
            const val04 = headers.currency == 'SO' ? it.kad_valingsp04 : it.kad_valingdp04;
            const val05 = headers.currency == 'SO' ? it.kad_valingsp05 : it.kad_valingdp05;
            const val06 = headers.currency == 'SO' ? it.kad_valingsp06 : it.kad_valingdp06;
            const val07 = headers.currency == 'SO' ? it.kad_valingsp07 : it.kad_valingdp07;
            const val08 = headers.currency == 'SO' ? it.kad_valingsp08 : it.kad_valingdp08;
            const val09 = headers.currency == 'SO' ? it.kad_valingsp09 : it.kad_valingdp09;
            const val10 = headers.currency == 'SO' ? it.kad_valingsp10 : it.kad_valingdp10;
            const val11 = headers.currency == 'SO' ? it.kad_valingsp11 : it.kad_valingdp11;
            const val12 = headers.currency == 'SO' ? it.kad_valingsp12 : it.kad_valingdp12;

            const total = val01 + val02 + val03 + val04 + val05 + val06 + val07 + val08 + val09 + val10 + val11 + val12;

            tEne += val01;
            tFeb += val02;
            tMar += val03;
            tAbr += val04;
            tMay += val05;
            tJun += val06;
            tJul += val07;
            tAgo += val08;
            tSet += val09;
            tOct += val10;
            tNov += val11;
            tDic += val12;
            tTot += total;

            detalle.push([
              new Cell(
                new Txt(`${it.mmo_codmmo} ${it.mmo_descri}`).end,
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Stack([
                  new Txt(it.ccn_coddeb).alignment('right').fontSize(6).end,
                  new Txt(it.ccn_codhab).alignment('right').fontSize(6).end,
                ]).end
              ).border(noBorders).end,
              new Cell(
                new Txt(numberWithCommas(val01.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val02.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val03.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val04.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val05.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val06.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val07.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val08.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val09.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val10.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val11.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(val12.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
              new Cell(
                new Txt(numberWithCommas(total.toFixed(2))).alignment('right').end
              ).border(noBorders).fontSize(6).end,
            ],)
          })

          detalle.push([
            new Cell(
              new Txt('TOTAL').bold().alignment('right').end
            ).colSpan(2).border(noBorders).fontSize(6).end,
            ...obtenerNulls(1),
            new Cell(
              new Txt(numberWithCommas(tEne.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tFeb.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tMar.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tAbr.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tMay.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tJun.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tJul.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tAgo.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tSet.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tOct.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tNov.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tDic.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
            new Cell(
              new Txt(numberWithCommas(tTot.toFixed(2))).alignment('right').end
            ).border(topBorder).fontSize(6).end,
          ],)

          totEne += tEne;
          totFeb += tFeb;
          totMar += tMar;
          totAbr += tAbr;
          totMay += tMay;
          totJun += tJun;
          totJul += tJul;
          totAgo += tAgo;
          totSet += tSet;
          totOct += tOct;
          totNov += tNov;
          totDic += tDic;
          totTot += tTot;
        }


      })

      detalle.push([
        new Cell(
          new Txt('TOTAL').bold().alignment('right').end
        ).colSpan(2).border(noBorders).fontSize(6).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(numberWithCommas(totEne.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totFeb.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totMar.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totAbr.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totMay.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totJun.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totJul.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totAgo.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totSet.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totOct.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totNov.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totDic.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
        new Cell(
          new Txt(numberWithCommas(totTot.toFixed(2))).alignment('right').end
        ).border(topBorder).fontSize(6).end,
      ],)
    })
  });

  pdf.add(new Table([
    [
      new Cell(
        new Stack([
          new Txt('DEMO PAVSO').fontSize(7).end,
          new Txt('RINV_RESU_ANTI').fontSize(7).end,
        ]).end,
      ).colSpan(2).border(noBorders).end,
      obtenerNulls(1),
      new Cell(
        new Stack([
          new Txt('Resumen Anual por Tipo de Inventario').alignment('center').fontSize(10).bold().end,
          new Txt(headers.currency == 'SO' ? 'En Soles' : 'En Dólares').fontSize(8).bold().alignment('center').end,
          new Txt(headers.codano).fontSize(8).bold().alignment('center').end,
        ]).end
      ).colSpan(11).border(noBorders).end,
      ...obtenerNulls(10),
      new Cell(
        new Stack([
          new Txt(formatDateWithSlash(new Date())).alignment('right').fontSize(7).end,
          new Txt('12:37:30').alignment('right').fontSize(7).end
        ]).end
      ).colSpan(2).border(noBorders).end,
      obtenerNulls(1)
    ],
    [
      new Cell(
        new Txt('Motivo').end
      ).colSpan(2).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt('Enero').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Febrero').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Marzo').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Abril').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Mayo').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Junio').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Julio').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Agosto').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Setiembre').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Octubre').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Noviembre').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Diciembre').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
      new Cell(
        new Txt('Total').end
      ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().fontSize(8).end,
    ],
    ...detalle,
    [
      new Cell(
        new Txt('SALDO INICIAL').end
      ).colSpan(2).border(noBorders).fontSize(6).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt('47,743.14').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('81,445.71').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('101,832.22').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('120,519.26').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('139,148.24').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('177,506.79').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('210,578.65').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(noBorders).fontSize(6).end,
      new Cell(
        new Txt('878,774.01').alignment('right').end
      ).border(noBorders).fontSize(6).end,
    ],


    [
      new Cell(
        new Txt('SALDO FINAL').bold().alignment('left').end
      ).colSpan(2).border(noBorders).fontSize(6).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt('47,743.14').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('81,445.71').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('101,832.22').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('120,519.26').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('139,148.24').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('177,506.79').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('210,578.65').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('0.00').alignment('right').end
      ).border(topBorder).fontSize(6).end,
      new Cell(
        new Txt('878,774.01').alignment('right').end
      ).border(topBorder).fontSize(6).end,
    ],
  ]).headerRows(2).widths([110, 30, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40]).end)

  pdf.create().open();

}

/**
 * Generar reporte de kardex valorizado mensual
 * @param headers
 * @param data
 */
export async function generarReporteKardexValorizadoMensual(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  let [tgIng1, tgSal1, tgSaldo1, tgIng2, tgSal2, tgSaldo2] = [0, 0, 0, 0, 0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`ALMACEN - ${element.alm_codalm} - ${element.alm_descri} `).bold().fontSize(8).alignment('center').decoration('underline').end
      ).border(noBorders).colSpan(15).end,
      ...obtenerNulls(14)
    ],)

    let [tIng1, tSal1, tSaldo1, tIng2, tSal2, tSaldo2] = [0, 0, 0, 0, 0, 0];

    element.detalle.forEach(item => {
      detalle.push([
        new Cell(
          new Txt(`${item.tin_codtin} - ${item.tin_descri}`).bold().fontSize(7).alignment('left').decoration('underline').end
        ).border(noBorders).colSpan(15).end,
        ...obtenerNulls(14)
      ],)


      item.detalle.forEach(el => {
        console.log('currency', headers.currency)
        console.log('spa_mnpini', el.spa_mnpini)
        console.log('spa_mepini', el.spa_mepini)
        const saldoInicial = headers.currency == 'SO' ? el.spa_mnpini : el.spa_mepini;
        detalle.push([
          new Cell(
            new Txt(el.prd_codprd).fontSize(6).end
          ).border(noBorders).colSpan(3).end,
          ...obtenerNulls(2),
          new Cell(
            new Txt(el.prd_desesp).fontSize(6).end
          ).border(noBorders).colSpan(3).end,
          ...obtenerNulls(2),
          new Cell(
            new Txt(el.ume_codume).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt('Saldo Inicial').fontSize(6).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(el.spa_canini ? el.spa_canini.toFixed(2) : '0.00').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt('Saldo Inicial').fontSize(6).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(saldoInicial.toFixed(2)).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt('').end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1)
        ],)

        let saldo = el.spa_canini;
        let saldoValorizacion = saldoInicial;

        let [totIng1, totSal1, totIng2, totoSal2] = [0, 0, 0, 0];

        el.detalle.forEach(it => {

          saldo = saldo + it.kad_caning - it.kad_cansal;
          saldoValorizacion = saldoValorizacion + it.kad_valingsp - it.kad_valsalsp;

          totIng1 += it.kad_caning;
          totSal1 += it.kad_cansal;
          totIng2 += it.kad_valingsp;
          totoSal2 += it.kad_valsalsp;

          detalle.push([
            new Cell(
              new Txt(formatDateWithSlash(it.mac_fecmac)).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.tdo_codtdo).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.mac_nummac).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.mic_ordpro).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.mmo_descri).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_refer1 ? it.tdo_refer1 : ''}-${it.kac_refer1 ? it.kac_refer1 : ''}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_refer2 ? it.tdo_refer : ''}-${it.kac_refer2 ? it.kac_refer2 : ''}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.kad_caning.toFixed(3)).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.kad_cansal.toFixed(3)).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt((saldo).toFixed(3)).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.kad_valingsp.toFixed(3)).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.kad_valsalsp.toFixed(3)).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(saldoValorizacion.toFixed(3)).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(saldo != 0 ? (saldoValorizacion / saldo).toFixed(3) : '0.000').alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.destino ? it.destino : ''} - ${it.destinoalma ? it.destinoalma : ''} / ${it.docucod ? it.docucod : ''} - ${it.docudes ? it.docudes : ''}`).fontSize(6).end
            ).border(noBorders).end,
          ],)
        })

        detalle.push([
          new Cell(
            new Txt('').end
          ).border(noBorders).colSpan(5).end,
          ...obtenerNulls(4),
          new Cell(
            new Txt(el.prd_codprd).fontSize(6).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(numberWithCommas(totIng1.toFixed(3))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(totSal1.toFixed(3))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
          new Cell(
            new Txt('').end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas(totIng2.toFixed(3))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(totoSal2.toFixed(3))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
          new Cell(
            new Txt('').end
          ).border(noBorders).colSpan(3).end,
          ...obtenerNulls(2)
        ],)

        tIng1 += totIng1;
        tSal1 += totSal1;
        tIng2 += totIng2;
        tSal2 += totoSal2;
        tSaldo1 += saldo;
        tSaldo2 += saldoValorizacion;
      })
    })

    detalle.push([
      new Cell(
        new Txt('').end
      ).border(noBorders).colSpan(5).end,
      ...obtenerNulls(4),
      new Cell(
        new Txt(`Total ${element.alm_codalm} - ${element.alm_descri}`).fontSize(6).bold().end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(tIng1.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSal1.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSaldo1.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tIng2.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSal2.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSaldo2.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt('').end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1)
    ],)

    tgIng1 += tIng1;
    tgSal1 += tSal1;
    tgIng2 += tIng2;
    tgSal2 += tSal2;

  });

  detalle.push([
    new Cell(
      new Txt('').end
    ).border(noBorders).colSpan(5).end,
    ...obtenerNulls(4),
    new Cell(
      new Txt('Total General').fontSize(6).bold().end
    ).border(noBorders).colSpan(2).end,
    ...obtenerNulls(1),
    new Cell(
      new Txt(numberWithCommas(tgIng1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgSal1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgSaldo1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgIng2.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgSal2.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgSaldo2.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt('').end
    ).border(noBorders).colSpan(2).end,
    ...obtenerNulls(1)
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').bold().fontSize(7).end,
            new Txt('RINV_KARD_MENSV').bold().fontSize(7).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('KARDEX VALORIZADO').bold().fontSize(9).alignment('center').end,
            new Txt(`${headers.codano}${headers.codmes}`).bold().fontSize(8).alignment('center').end,
            new Txt(`${headers.currency == 'SO' ? 'Moneda Nacional' : 'Moneda Extranjera'}`).bold().fontSize(7).alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(12).end,
        ...obtenerNulls(11),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).bold().fontSize(7).alignment('right').end,
            new Txt('12:26:39').bold().fontSize(7).alignment('right').end
          ]).end
        ).border(noBorders).end,
      ],
      // [
      //   new Cell(
      //     new Txt('Producto:TI01//').fontSize(7).end
      //   ).border(noBorders).colSpan(15).end,
      //   ...obtenerNulls(14)
      // ],
      [
        new Cell(
          new Txt('Producto').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Nro. Parte').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Descripción de producto').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('Cantidades - Unidades').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Valorización').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Costo Unitario').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().rowSpan(2).end,
        new Cell(
          new Txt('Destino y/o Origen').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().rowSpan(2).end,
      ],
      [
        new Cell(
          new Txt('Fecha').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('T/Doc.').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Nro. Parte').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Ord').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Motivo').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('1ra. Referencia').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('2da. Referencia').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Ingreso').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Salida').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Saldo').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Ingreso').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Salida').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Saldo').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        ...obtenerNulls(2)
      ],
      ...detalle,
    ]).headerRows(4).widths([40, 30, 30, 30, 45, 50, 50, 40, 40, 40, 40, 40, 40, 50, 100]).end
  )

  pdf.create().open();

}

/**
 * Generar reporte de kardex valorizado mensual
 * @param headers
 * @param data
 */
export async function generarReporteKardexMensual(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  let [tgIng1, tgSal1, tgSaldo1, tgIng2, tgSal2, tgSaldo2] = [0, 0, 0, 0, 0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`ALMACEN - ${element.alm_codalm} - ${element.alm_descri} `).bold().fontSize(8).alignment('center').decoration('underline').end
      ).border(noBorders).colSpan(12).end,
      ...obtenerNulls(11)
    ],)

    let [tIng1, tSal1, tSaldo1, tIng2, tSal2, tSaldo2] = [0, 0, 0, 0, 0, 0];

    element.detalle.forEach(item => {
      detalle.push([
        new Cell(
          new Txt(`${item.tin_codtin} - ${item.tin_descri}`).bold().fontSize(7).alignment('left').decoration('underline').end
        ).border(noBorders).colSpan(12).end,
        ...obtenerNulls(11)
      ],)


      item.detalle.forEach(el => {
        console.log('currency', headers.currency)
        console.log('spa_mnpini', el.spa_mnpini)
        console.log('spa_mepini', el.spa_mepini)
        const saldoInicial = headers.currency == 'SO' ? el.spa_mnpini : el.spa_mepini;
        detalle.push([
          new Cell(
            new Txt(el.prd_codprd).fontSize(6).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(el.prd_desesp).fontSize(6).end
          ).border(noBorders).colSpan(6).end,
          ...obtenerNulls(5),
          new Cell(
            new Txt(el.ume_codume).fontSize(6).end
          ).border(noBorders).end,
          // new Cell(
          //   new Txt('').fontSize(6).end
          // ).border(noBorders).colSpan(2).end,
          // ...obtenerNulls(1),
          // new Cell(
          //   new Txt(''/*saldoInicial.toFixed(2)*/).fontSize(6).end
          // ).border(noBorders).end,
          new Cell(
            new Txt('Saldo Inicial').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt(el.spa_canini ? el.spa_canini.toFixed(2) : '0.00').fontSize(6).alignment('right').end
          ).border(noBorders).end,
          new Cell(
            new Txt('').fontSize(6).end
          ).border(noBorders).end

        ],)

        let saldo = el.spa_canini;
        let saldoValorizacion = saldoInicial;

        let [totIng1, totSal1, totIng2, totoSal2] = [0, 0, 0, 0];

        el.detalle.forEach(it => {

          saldo = saldo + it.kad_caning - it.kad_cansal;
          saldoValorizacion = saldoValorizacion + it.kad_valingsp - it.kad_valsalsp;

          totIng1 += it.kad_caning;
          totSal1 += it.kad_cansal;
          totIng2 += it.kad_valingsp;
          totoSal2 += it.kad_valsalsp;

          detalle.push([
            new Cell(
              new Txt(formatDateWithSlash(it.mac_fecmac)).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.tdo_codtdo).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.mac_nummac).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.mic_ordpro).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.mmo_descri).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_refer1 ? it.tdo_refer1 : ''} ${it.kac_refer1 ? it.kac_refer1 : ''}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_refer2 ? it.tdo_refer2 : ''} ${it.kac_refer2 ? it.kac_refer2 : ''}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.kad_caning.toFixed(3)).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.kad_cansal.toFixed(3)).alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt((saldo).toFixed(3)).alignment('right').fontSize(6).end
            ).colSpan(2).border(noBorders).end,
            new Cell(
              new Txt(saldo != 0 ? (saldoValorizacion / saldo).toFixed(3) : '0.000').alignment('right').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.destino ? it.destino : ''} - ${it.destinoalma ? it.destinoalma : ''} / ${it.docucod ? it.docucod : ''} - ${it.docudes ? it.docudes : ''}`).alignment('center').fontSize(6).end
            ).border(noBorders).end,
          ],)
        })

        detalle.push([
          new Cell(
            new Txt('').end
          ).border(noBorders).colSpan(5).end,
          ...obtenerNulls(4),
          new Cell(
            new Txt(el.prd_codprd).fontSize(6).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(numberWithCommas(totIng1.toFixed(3))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(totSal1.toFixed(3))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
          new Cell(
            new Txt('').end
          ).border(noBorders).end,
          new Cell(
            new Txt('').end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1)
        ],)

        tIng1 += totIng1;
        tSal1 += totSal1;
        tIng2 += totIng2;
        tSal2 += totoSal2;
        tSaldo1 += saldo;
        tSaldo2 += saldoValorizacion;
      })
    })

    detalle.push([
      new Cell(
        new Txt('').end
      ).border(noBorders).colSpan(5).end,
      ...obtenerNulls(4),
      new Cell(
        new Txt(`Total ${element.alm_codalm} - ${element.alm_descri}`).fontSize(6).bold().end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(tIng1.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSal1.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSaldo1.toFixed(3))).fontSize(6).alignment('right').end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt('').end
      ).border(noBorders).end
    ],)

    tgIng1 += tIng1;
    tgSal1 += tSal1;
    tgIng2 += tIng2;
    tgSal2 += tSal2;

  });

  detalle.push([
    new Cell(
      new Txt('').end
    ).border(noBorders).colSpan(5).end,
    ...obtenerNulls(4),
    new Cell(
      new Txt('Total General').fontSize(6).bold().end
    ).border(noBorders).colSpan(2).end,
    ...obtenerNulls(1),
    new Cell(
      new Txt(numberWithCommas(tgIng1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgSal1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(''/*numberWithCommas(tgSaldo1.toFixed(3))*/).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt('').end
    ).border(noBorders).colSpan(2).end,
    ...obtenerNulls(1)
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').bold().fontSize(7).end,
            new Txt('RINV_KARD_MENSV').bold().fontSize(7).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('KARDEX MENSUAL').bold().fontSize(9).alignment('center').end,
            new Txt(`${headers.codano}${headers.codmes}`).bold().fontSize(8).alignment('center').end,
            new Txt(`${headers.currency == 'SO' ? 'Moneda Nacional' : 'Moneda Extranjera'}`).bold().fontSize(7).alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).bold().fontSize(7).alignment('right').end,
            new Txt('12:26:39').bold().fontSize(7).alignment('right').end
          ]).end
        ).border(noBorders).end,
      ],
      // [
      //   new Cell(
      //     new Txt('Producto:TI01//').fontSize(7).end
      //   ).border(noBorders).colSpan(12).end,
      //   ...obtenerNulls(11)
      // ],
      [
        new Cell(
          new Txt('Producto').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Nro. Parte').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Descripción de producto').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('Cantidades - Unidades').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(4).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Costo Unitario').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().rowSpan(2).end,
        new Cell(
          new Txt('Destino y/o Origen').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().rowSpan(2).end,
      ],
      [
        new Cell(
          new Txt('Fecha').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('T/Doc.').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Nro. Parte').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Ord').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Motivo').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('1ra. Referencia').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('2da. Referencia').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Ingreso').end
        ).fontSize(7).alignment('right').color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Salida').end
        ).fontSize(7).alignment('right').color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Saldo').end
        ).colSpan(2).fontSize(7).alignment('right').color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        ...obtenerNulls(2)
      ],
      ...detalle,
    ]).headerRows(4).widths([40, 25, 35, 30, 140, 70, 70, 50, 50, 35, 25, 100]).end
  )

  pdf.create().open();

}

/**
 * Generar reporte de kardex por lote
 * @param headers
 * @param data
 */
export async function generarReporteKardexPorLote(headers, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  let [tgIng1, tgSal1, tgSaldo1, tgIng2, tgSal2, tgSaldo2] = [0, 0, 0, 0, 0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(`ALMACEN - ${element.alm_codalm} - ${element.alm_descri} `).bold().fontSize(8).alignment('center').decoration('underline').end
      ).border(noBorders).colSpan(11).end,
      ...obtenerNulls(10)
    ],)

    let [tIng1, tSal1, tSaldo1, tIng2, tSal2, tSaldo2] = [0, 0, 0, 0, 0, 0];

    element.detalle.forEach(item => {
      detalle.push([
        new Cell(
          new Txt(`${item.tin_codtin} - ${item.tin_descri}`).bold().fontSize(7).alignment('left').decoration('underline').end
        ).border(noBorders).colSpan(11).end,
        ...obtenerNulls(10)
      ],)


      item.detalle.forEach(el => {
        console.log('currency', headers.currency)
        console.log('spa_mnpini', el.spa_mnpini)
        console.log('spa_mepini', el.spa_mepini)
        const saldoInicial = headers.currency == 'SO' ? el.spa_mnpini : el.spa_mepini;
        detalle.push([
          new Cell(
            new Txt(el.prd_codprd).fontSize(6).bold().color(PavsoColors.Blue).end
          ).border(noBorders).colSpan(3).end,
          ...obtenerNulls(2),
          new Cell(
            new Txt(el.prd_desesp).fontSize(6).bold().color(PavsoColors.Blue).end
          ).border(noBorders).colSpan(3).end,
          ...obtenerNulls(2),
          new Cell(
            new Txt(el.ume_codume).fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt('Saldo Inicial').fontSize(6).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(el.spa_canini ? el.spa_canini.toFixed(2) : '0.00').fontSize(6).end
          ).border(noBorders).end,
          new Cell(
            new Txt('').end
          ).border(noBorders).end,
        ],)

        let saldo = el.spa_canini;
        let saldoValorizacion = saldoInicial;

        let [totIng1, totSal1, totIng2, totoSal2] = [0, 0, 0, 0];

        el.detalle.forEach(it => {

          let [totIngLot1, totIngLot2] = [0, 0];

          detalle.push([
            new Cell(
              new Txt('').end
            ).border(noBorders).colSpan(5).end,
            ...obtenerNulls(4),
            new Cell(
              new Txt(` Lote: ${it.spl_numlot}`).bold().fontSize(6).end
              // new Txt(` Lote: ${it.spl_numlot} |  Fecha Vencimiento: ${formatDateWithSlash(it.lot_fecven)} `).bold().fontSize(6).end
            ).border(noBorders).colSpan(6).end,
            ...obtenerNulls(5)
          ])

          it.detalle.forEach(row => {
            saldo = saldo + row.map_caning - row.map_cansal;
            saldoValorizacion = saldoValorizacion + row.map_caning - row.map_cansal;

            totIng1 += row.map_caning;
            totSal1 += row.map_cansal;

            totIngLot1 += row.map_caning;
            totIngLot2 += row.map_cansal;

            detalle.push([
              new Cell(
                new Txt(formatDateWithSlash(row.mac_fecmac)).fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(row.tdo_codtdo).fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(row.mac_nummac).fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(row.mmo_descri).fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(row.uba_Descri ? row.uba_Descri : '').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(`${row.lot_glolot ? row.lot_glolot : ''}`).fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(`${row.tdo_refer2 ? row.tdo_refer : ''}-${row.kac_refer2 ? row.kac_refer2 : ''}`).fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(row.tdo_codtdo == 'ING' ? row.map_caning.toFixed(3) : '0.000').alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(row.tdo_codtdo == 'SAL' ? row.map_cansal.toFixed(3) : '0.000').alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(row.spa_canfin.toFixed(3)).alignment('right').fontSize(6).end
              ).border(noBorders).end,
              new Cell(
                new Txt(row.cli_nomcli).alignment('right').fontSize(6).end
              ).border(noBorders).end,

            ],)
          });

          detalle.push([
            new Cell(
              new Txt('').end
            ).border(noBorders).colSpan(5).end,
            ...obtenerNulls(4),
            new Cell(
              new Txt('').fontSize(6).end
            ).border(noBorders).colSpan(2).end,
            ...obtenerNulls(1),
            new Cell(
              new Txt(numberWithCommas(totIngLot1.toFixed(3))).alignment('right').fontSize(6).end
            ).border(topBorder).end,
            new Cell(
              new Txt(numberWithCommas(totIngLot2.toFixed(3))).alignment('right').fontSize(6).end
            ).border(topBorder).end,
            new Cell(
              new Txt('').end
            ).colSpan(2).border(noBorders).end,

            ...obtenerNulls(1)
          ],)
        })

        detalle.push([
          new Cell(
            new Txt('').end
          ).border(noBorders).colSpan(5).end,
          ...obtenerNulls(4),
          new Cell(
            new Txt(el.prd_codprd).fontSize(6).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(numberWithCommas(totIng1.toFixed(3))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
          new Cell(
            new Txt(numberWithCommas(totSal1.toFixed(3))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
          new Cell(
            new Txt('').end
          ).colSpan(2).border(noBorders).end,

          ...obtenerNulls(1)
        ],)

        tIng1 += totIng1;
        tSal1 += totSal1;
        tIng2 += totIng2;
        tSal2 += totoSal2;
        tSaldo1 += saldo;
        tSaldo2 += saldoValorizacion;
      })
    })

    detalle.push([
      new Cell(
        new Txt('').end
      ).border(noBorders).colSpan(5).end,
      ...obtenerNulls(4),
      new Cell(
        new Txt(`Total ${element.alm_codalm} - ${element.alm_descri}`).fontSize(6).bold().end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(numberWithCommas(tIng1.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSal1.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSaldo1.toFixed(3))).fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt('').end
      ).border(noBorders).end,
    ],)

    tgIng1 += tIng1;
    tgSal1 += tSal1;
    tgIng2 += tIng2;
    tgSal2 += tSal2;

  });

  detalle.push([
    new Cell(
      new Txt('').end
    ).border(noBorders).colSpan(5).end,
    ...obtenerNulls(4),
    new Cell(
      new Txt('Total General').fontSize(6).bold().end
    ).border(noBorders).colSpan(2).end,
    ...obtenerNulls(1),
    new Cell(
      new Txt(numberWithCommas(tgIng1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgSal1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgSaldo1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt('').end
    ).border(noBorders).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').bold().fontSize(7).end,
            new Txt('RINV_KARD_MENSV').bold().fontSize(7).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('KARDEX MENSUAL LOTE').bold().fontSize(9).alignment('center').end,
            new Txt(`${headers.codano}${headers.codmes}`).bold().fontSize(8).alignment('center').end,
            new Txt(`${headers.currency == 'SO' ? 'Moneda Nacional' : 'Moneda Extranjera'}`).bold().fontSize(7).alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).bold().fontSize(7).alignment('right').end,
            new Txt('12:26:39').bold().fontSize(7).alignment('right').end
          ]).end
        ).border(noBorders).end,
      ],
      // [
      //   new Cell(
      //     new Txt('Producto:TI01//').fontSize(7).end
      //   ).border(noBorders).colSpan(11).end,
      //   ...obtenerNulls(10)
      // ],
      [
        new Cell(
          new Txt('Producto').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Nro. Parte').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Descripción de producto').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Txt('Cantidades - Unidades').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Auxiliar').alignment('center').end
        ).rowSpan(2).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,

      ],
      [
        new Cell(
          new Txt('Fecha').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('T/Doc.').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Nro. Parte').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Motivo').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Destino').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('1ra. Referencia').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('2da. Referencia').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Ingreso').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Salida').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Saldo').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        ...obtenerNulls(1)
      ],
      ...detalle,
    ]).headerRows(4).widths([35, 25, 25, 55, 25, 50, 50, 35, 35, 35, 85]).end
  )

  pdf.create().open();

}

/**
 * Generar reporte de kardex por lote trazabilidad
 * @param headers
 * @param data
 */
export async function generarReporteKardexPorLoteTrazabilidad(headers, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  let [tgIng1, tgSal1, tgSaldo1, tgIng2, tgSal2, tgSaldo2] = [0, 0, 0, 0, 0, 0];

  data.forEach(element => {

    let [tIng1, tSal1, tSaldo1, tIng2, tSal2, tSaldo2] = [0, 0, 0, 0, 0, 0];

    detalle.push([
      new Cell(
        new Txt(element.mlo_numlot).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.prd_desesp).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.lot_fecven).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(formatDateWithSlash(element.mac_fecmac)).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.tdo_codtdo).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.alm_descri).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.mac_nummac).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.mmo_descri).fontSize(6).end
      ).border(noBorders).end,
      // new Cell(
      //   new Txt(`${element.lot_glolot ? element.lot_glolot : ''}`).fontSize(6).end
      // ).border(noBorders).end,
      new Cell(
        new Txt(element.tdo_codtdo == 'ING' ? `${element.map_caning.toFixed(3)}` : '0.000').alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.tdo_codtdo == 'SAL' ? `${element.map_cansal.toFixed(3)}` : '0.000').alignment('right').fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).alignment('right').fontSize(6).end
      ).border(noBorders).end,

    ],)

    tgIng1 += element.spa_caning;
    tgSal1 += element.spa_cansal;

  });

  detalle.push([
    new Cell(
      new Txt('').end
    ).border(noBorders).colSpan(5).end,
    ...obtenerNulls(4),
    new Cell(
      new Txt('Total General').fontSize(6).bold().end
    ).border(noBorders).colSpan(3).end,
    ...obtenerNulls(2),
    new Cell(
      new Txt(numberWithCommas(tgIng1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tgSal1.toFixed(3))).fontSize(6).alignment('right').end
    ).border(noBorders).end,

    new Cell(
      new Txt('').end
    ).border(noBorders).end,
  ])

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').bold().fontSize(7).end,
            new Txt('RINV_KARD_MENSV').bold().fontSize(7).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('TRAZABILIDAD POR LOTE').bold().fontSize(9).alignment('center').end,
            new Txt(`${headers.codano}${headers.codmes}`).bold().fontSize(8).alignment('center').end,
            new Txt(`${headers.currency == 'SO' ? 'Moneda Nacional' : 'Moneda Extranjera'}`).bold().fontSize(7).alignment('center').end,
          ]).end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).bold().fontSize(7).alignment('right').end,
            new Txt('12:26:39').bold().fontSize(7).alignment('right').end
          ]).end
        ).border(noBorders).end,
      ],
      // [
      //   new Cell(
      //     new Txt('Producto:TI01//').fontSize(7).end
      //   ).border(noBorders).colSpan(11).end,
      //   ...obtenerNulls(10)
      // ],
      [
        new Cell(
          new Txt('Lote').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Fecha').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().rowSpan(2).end,
        new Cell(
          new Txt('Nro. Parte').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt('Motivo').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().rowSpan(2).end,
        new Cell(
          new Txt('Cantidades - Unidades').alignment('center').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Auxiliar').alignment('center').end
        ).rowSpan(2).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,

      ],
      [
        new Cell(
          new Txt('Nro. Lote').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Producto').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Fec Ven.').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Fecha').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('T.D.').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Almacén').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Nro. Parte').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Motivo').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Ingreso').alignment('right').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        new Cell(
          new Txt('Salida').alignment('right').end
        ).fontSize(7).color(PavsoColors.White).fillColor(PavsoColors.Blue).bold().end,
        ...obtenerNulls(1)
      ],
      ...detalle,
    ]).headerRows(4).widths([85, 90, 35, 35, 20, 120, 25, 90, 35, 35, 135]).end
  )

  pdf.create().open();

}

/**
 * Generar reporte de movimientos por motivo
 * @param headers
 * @param data
 */
export async function generarReporteMovimientosPorMotivo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  data.forEach(item => {
    if (item.mmo_codmmo != '' && item.mmo_codmmo != '0000' && item.mmo_codmmo != null) {
      detalle.push([
        new Cell(
          new Txt(`MOTIVO : ${item.mmo_codmmo} ${item.mmo_descri}`).fontSize(8).alignment('center').decoration('underline').bold().end
        ).colSpan(12).border(noBorders).end,
        ...obtenerNulls(11)
      ],)

      item.detalle.forEach(element => {

        detalle.push([
          new Cell(
            new Txt('PRODUCTO :').fontSize(7).bold().end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(element.prd_codprd).fontSize(7).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(element.prd_desesp).fontSize(7).end
          ).border(noBorders).colSpan(8).end,
          ...obtenerNulls(7)
        ],)

        let tot = 0;

        element.detalle.forEach(it => {

          detalle.push([
            new Cell(
              new Txt(formatDateWithSlash(it.mac_fecmac)).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.alm_descri}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_codtdo} ${it.mac_nummac}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_refer1 ? it.tdo_refer1 : ''}-${it.kac_refer1 ? it.kac_refer1 : ''}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_refer2 ? it.tdo_refer2 : ''}-${it.kac_refer2 ? it.kac_refer2 : ''}`).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.mac_glomac ? it.mac_glomac : ''} `).fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.ume_codume ? it.ume_codume : '').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.ccs_codccs ? it.ccs_codccs : '').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.cli_nomcli ? it.cli_nomcli : '').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.ocm_numocm ? it.ocm_numocm : '').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.rma_numrma ? it.rma_numrma : '').fontSize(6).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.map_canprd ? numberWithCommas(it.map_canprd.toFixed(2)) : '0.00').alignment('right').fontSize(6).end
            ).border(noBorders).end,
          ],)

          tot += (it.map_canprd ? it.map_canprd : 0);

        })

        detalle.push([
          new Cell(
            new Txt('').fontSize(6).end
          ).colSpan(11).border(noBorders).end,
          ...obtenerNulls(10),
          new Cell(
            new Txt(numberWithCommas(tot.toFixed(2))).alignment('right').fontSize(6).end
          ).border(topBorder).end,
        ])

      })
    }
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RINV_MOVI_MOTI').fontSize(7).end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('MOVIMIENTOS POR MOTIVO').alignment('center').bold().fontSize(9).end,
            new Txt(`${headers.codano} - ${headers.codmes}`).alignment('center').bold().fontSize(8).end
          ]).end
        ).colSpan(8).border(noBorders).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).alignment('right').fontSize(7).end,
            new Txt('').alignment('right').end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Fecha').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Alm.').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Nro. de Parte').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Referencia 1 ').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Referencia 2').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Glosa').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('U/M').fontSize(7).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Centro de costo').fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Auxiliar').fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('# Orden de compra').fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('# Requerimiento').fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Cantidad').fontSize(7).end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,

    ]).headerRows(2).widths([40, 55, 50, 55, 50, 165, 50, 50, 50, 50, 40, 40]).end
  )

  pdf.create().open();

}

/**
 * Generar reporte de movimientos por motivo
 * @param headers
 * @param data
 */
export async function generarReporteMovimientosPorMotivoValorizados(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  console.log('data lista', data)

  data.forEach(item => {
    detalle.push([
      new Cell(
        new Txt(`MOTIVO : ${item.mmo_codmmo} ${item.mmo_descri}`).alignment('center').fontSize(7).bold().end
      ).border(noBorders).colSpan(11).end,
      ...obtenerNulls(10)
    ],)

    item.detalle.forEach(el => {
      detalle.push([
        new Cell(
          new Txt(`FAMILIA ${el.fpr_codfpr} ${el.fpr_descri}`).fontSize(7).bold().end
        ).border(noBorders).colSpan(11).end,
        ...obtenerNulls(10)
      ],)

      el.detalle.forEach(element => {
        detalle.push([
          new Cell(
            new Txt(element.prd_codprd).fontSize(7).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(element.prd_desesp).fontSize(7).end
          ).border(noBorders).colSpan(2).end,
          ...obtenerNulls(1),
          new Cell(
            new Txt(element.ume_codume).fontSize(7).end
          ).border(noBorders).colSpan(7).end,
          ...obtenerNulls(6)
        ],)

        let [tCantidad, tValor] = [0, 0];

        let saldo = element.spa_canini;

        element.detalle.forEach(it => {
          saldo = saldo + it.kad_caning - it.kad_cansal;

          detalle.push([
            new Cell(
              new Txt(formatDateWithSlash(it.mac_fecmac)).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.alm_codalm} ${it.tdo_codtdo} ${it.mac_nummac}`).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_refer1}-${it.kac_refer1}`).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(`${it.tdo_refer2}-${it.kac_refer2}`).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt('').fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.ccs_codccs).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.cli_codcli).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.ocm_numocm).fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt(it.rma_numrma).fontSize(7).end
            ).border(noBorders).end,

            new Cell(
              new Txt(saldo.toFixed(3)).alignment('right').fontSize(7).end
            ).border(noBorders).end,
            new Cell(
              new Txt('0.00000').alignment('right').fontSize(7).end
            ).border(noBorders).end,
          ])
        });
      })
    })
  })

  pdf.add(new Table([
    [
      new Cell(
        new Stack([
          new Txt('DEMO PAVSO ').fontSize(7).end,
          new Txt('RINV_MOVI_MODV').fontSize(7).end
        ]).end
      ).colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Stack([
          new Txt('MOVIMIENTOS POR MOTIVO VALORIZADO DETALLADO').alignment('center').bold().fontSize(9).end,
          new Txt(`${headers.codano} - ${headers.codmes}`).alignment('center').bold().fontSize(8).end,
          new Txt(`${headers.currency == 'SO' ? 'En Soles' : 'En Dólares'}`).alignment('center').bold().fontSize(8).end
        ]).end
      ).colSpan(8).border(noBorders).end,
      ...obtenerNulls(7),
      new Cell(
        new Stack([
          new Txt(formatDateWithSlash(new Date)).alignment('right').fontSize(7).end,
          new Txt('12:08:17').alignment('right').fontSize(7).end,
        ]).end
      ).border(noBorders).end,
    ],
    [
      new Cell(
        new Txt('Código').fontSize(7).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      new Cell(
        new Txt('Nª Parte').fontSize(7).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      new Cell(
        new Txt('Descripción').fontSize(7).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).colSpan(2).end,
      null,
      new Cell(
        new Txt('U.M.').fontSize(7).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      new Cell(
        new Txt('Centro de costo').fontSize(7).end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      new Cell(
        new Txt('Auxiliar').fontSize(7).end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      new Cell(
        new Txt('# Orden de compra').fontSize(7).end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      new Cell(
        new Txt('# Requerimiento').fontSize(7).end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      new Cell(
        new Txt('Cantidad').fontSize(7).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      new Cell(
        new Txt('Valor').fontSize(7).bold().end
      ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
    ],
    ...detalle

  ]).headerRows(2).widths([55, 70, 95, 95, 50, 50, 50, 50, 50, 65, 65]).end)

  pdf.create().open();
}


export async function listadoGuia(headers, data: Array<any>) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RINV_MOVI_MODV').fontSize(7).end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('LISTADO DE GUÍAS').alignment('center').bold().fontSize(9).end,
            new Txt(`${headers.codano} - ${headers.codmes}`).alignment('center').bold().fontSize(8).end,
          ]).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date)).alignment('right').fontSize(7).end,
            new Txt('12:08:17').alignment('right').fontSize(7).end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Incluye tipo de guía transporte`).fontSize(7).end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7)
      ],
      [
        new Cell(
          new Txt('Doc/Despacho').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Fec. Emi.').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Cod. Cli.').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Cliente').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Mov. Almacen').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Motivo').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Comprobante').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Fecha').fontSize(8).end
        ).end,
      ],

      [
        new Cell(
          new Txt('SERIE: 0001').bold().fontSize(9).alignment('center').end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7)
      ],
      [
        new Cell(
          new Txt('GRE/0001000001001010011').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('15/01/20').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('20383747744').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('PRUEBA SAC').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('ALMACEN PT/SAL-0000323').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('VENTA NACIONAL').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('BVE/B00139399393').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(8).end
        ).border(noBorders).end,

      ]
    ]).headerRows(1).widths([60, 60, 60, 60, 60, 60, 60, 60]).end
  )

  pdf.create().open();
}

export async function listadoGuiaDetallado(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').fontSize(7).end,
            new Txt('RINV_MOVI_MODV').fontSize(7).end
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Correlativo Guía - Detallado').alignment('center').bold().fontSize(9).end,
            new Txt(`${headers.codano} - ${headers.codmes}`).alignment('center').bold().fontSize(8).end,
          ]).end
        ).colSpan(7).border(noBorders).end,
        ...obtenerNulls(6),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date)).alignment('right').fontSize(7).end,
            new Txt('12:08:17').alignment('right').fontSize(7).end,
          ]).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(`Incluye tipo de guía transporte`).fontSize(7).end
        ).border(noBorders).colSpan(11).end,
        ...obtenerNulls(10)
      ],
      [
        new Cell(
          new Txt('Doc/Despacho').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Fec. Emi.').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Cod. Cli.').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Cliente').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Mov. Almacen').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Motivo').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Pedido').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Cantidad').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Producto').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Descripción').fontSize(8).end
        ).end,
        new Cell(
          new Txt('Ref. pedido').fontSize(8).end
        ).end,
      ],
      [
        new Cell(
          new Txt('GRE/0001000001001010011').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('15/01/20').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('20383747744').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('PRUEBA SAC').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('ALMACEN PT/SAL-0000323').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('VENTA NACIONAL').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('0111901034').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('20').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('T001').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('TORTILLA DE TRIGO').fontSize(8).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(8).end
        ).border(noBorders).end,
      ]
    ]).headerRows(1).widths([80, 60, 60, 80, 60, 60, 60, 60, 60, 60, 60]).end
  )

  pdf.create().open();

}

export async function generarReporteResumenProductoPorClasificados(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);

  let detalle = [];

  const cantidadCalibres = data.calibres.length;

  data.data.forEach(item => {
    detalle.push([
      new Cell(
        new Txt(item.TIN_DESCRI).fontSize(8).bold().end
      ).fillColor(PavsoColors.BlueLight).color(PavsoColors.White).colSpan(7 + cantidadCalibres).end,
      ...obtenerNulls(6 + cantidadCalibres)
    ])

    item.detalle.forEach(element => {
      detalle.push(
        [
          new Cell(
            new Txt(element.mmo_descri).fontSize(8).bold().end
          ).colSpan(7 + cantidadCalibres).end,
          ...obtenerNulls(6 + cantidadCalibres)
        ],
      )

      element.detalle.forEach(it => {

        /*
        detalle.push(
          [
            new Cell(
              new Txt(it.lpd_descri).fontSize(8).bold().end
            ).end,
            new Cell(
              new Txt('').fontSize(8).bold().end
            ).colSpan(2).end,
            ...obtenerNulls(1),
            new Cell(
              new Txt('').fontSize(8).bold().end
            ).colSpan(4 + cantidadCalibres).end,
            ...obtenerNulls(3 + cantidadCalibres)
          ],
        )
        */

        it.detalle.forEach(familia => {
          detalle.push(
            [
              new Cell(
                new Txt(it.lpd_descri).fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('FAMILIA').fontSize(8).bold().end
              ).colSpan(2).end,
              ...obtenerNulls(1),
              new Cell(
                new Txt(familia.FPR_DESCRI).fontSize(8).bold().end
              ).colSpan(4 + cantidadCalibres).end,
              ...obtenerNulls(3 + cantidadCalibres)
            ],
          )
          detalle.push(
            [
              new Cell(
                new Txt('CAT.').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('PRODUCTOR').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('LOTE').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('FECHA DE INGRESO').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('REFERENCIA').fontSize(8).bold().end
              ).end,
              new Cell(
                new Txt('0').fontSize(8).bold().end
              ).end,
              ...data.calibres.map(item => ([
                new Cell(
                  new Txt(item).fontSize(8).bold().end
                ).end,
              ])),
              new Cell(
                new Txt('TOTAL GENERAL').fontSize(8).bold().end
              ).end,
            ],
          )
          familia.detalle.forEach(el => {

            let totalFila = 0;

            detalle.push(
              [
                new Cell(
                  new Txt(el.col_codcol).fontSize(7).end
                ).end,
                new Cell(
                  new Txt(el.cli_nomcli).fontSize(7).end
                ).end,
                new Cell(
                  new Txt(el.spl_numlot).fontSize(7).end
                ).end,
                new Cell(
                  new Txt(formatDateWithSlash(el.mlo_fecfab)).fontSize(7).end
                ).end,
                new Cell(
                  new Txt(el.lot_glolot).fontSize(7).end
                ).end,
                new Cell(
                  new Txt('').fontSize(7).end
                ).end,
                ...data.calibres.map(calibreId => {
                  if (el.mod_codmod == calibreId) {
                    totalFila += el.map_canprd
                  }
                  return [
                    new Cell(
                      new Txt(el.mod_codmod == calibreId ? el.map_canprd : '').fontSize(7).bold().end
                    ).end,
                  ]
                }),
                new Cell(
                  new Txt(totalFila.toString()).fontSize(7).end
                ).end,
              ],
            )
          })
          detalle.push(
            [
              new Cell(
                new Stack([
                  pdf.ln(),

                ]).end

              ).border(noBorders).colSpan(7 + cantidadCalibres).end,
              ...obtenerNulls(6 + cantidadCalibres)
            ],
          )

        })

      })
    })
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('DEMO PAVSO').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('REPORTE DE PRODUCTOS POR CLASIFICADOS').alignment('center').bold().fontSize(9).end
        ).colSpan(5 + cantidadCalibres).border(noBorders).end,
        ...obtenerNulls(4 + cantidadCalibres),
        new Cell(
          new Stack([
            new Txt(formatDateWithSlash(new Date())).alignment('right').fontSize(7).end,
            new Txt(getHourMinuteSecondsCurrent(new Date())).alignment('right').fontSize(7).end,
          ]).end
        ).border(noBorders).end,

      ],
      ...detalle,

    ]).headerRows(1).end
  )

  pdf.create().open();

}

export async function verPDFGuiaRemision(headers, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  // pdf.footer(function (currentPage, pageCount, pageSize) {
  //   return [

  //     { text: `Representación impresa de la guía electrónica autorizado a ser emisor electrónico mediante Resolución de Superintendencia  N. 000123-2022/ SUNAT.`, fontSize: 7, bold: false, margin: [10, 2, 10, 10], alignment: 'center' },
  //   ];
  // })

  pdf.footer((currentPage, pageCount) => {
    return {
      stack: [
        {
          columns: [
            {
              stack: [

                {
                  columns: [
                    {
                      qr: 'https://example.com',
                      fit: 50,
                      alignment: 'right',
                      margin: [0, 5, 0, 0] // Position the QR code correctly on top of the green rectangle
                    },
                    {
                      stack: [
                        { text: `Código de seguridad (Hash).`, fontSize: 7, bold: false, margin: [10, 20, 0, 0], alignment: 'left' },
                        { text: `fadfsf2342f234afs234fa23232f===`, fontSize: 7, bold: false, margin: [10, 0, 0, 0], alignment: 'left' },
                      ]

                    }
                  ]
                },
                {
                  text: `Representación impresa de la guía electrónica autorizado a ser emisor electrónico mediante Resolución de Superintendencia  N. 000123-2022/ SUNAT.`,
                  fontSize: 7,
                  bold: false,
                  margin: [0, 5, 0, 20],
                  alignment: 'center'
                },

              ]
            }
            // { text: `${currentPage} of ${pageCount}`, alignment: 'right', margin: [0, 15, 10, 0] }
          ],
          margin: [0, -50, 0, 0] // Adjust the margin to position the text correctly
        }
      ]
    };
  });


  pdf.watermark({ text: 'No válido', opacity: 0.1, fontSize: 64 })


  let detalle = [];

  data.kardex_map.forEach((element, index) => {
    detalle.push([
      new Cell(
        new Txt(index + 1).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.prd_codprd).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.producto.prd_desesp).fontSize(6).end
      ).border(noBorders).colSpan(3).end,
      ...obtenerNulls(2),
      new Cell(
        new Txt(element.producto.prd_codsun).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.map_canprd).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.producto.ume_codume).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(`${element.map_canven ? element.map_canven : ''} ${element.ume_codven ? element.unidadventa.ume_descri : ''}`).fontSize(6).end
      ).border(noBorders).end,
    ],)
  });

  pdf.add(
    new Table([
      [

        new Cell(
          new Stack([
            await new Img(headers.logo).alignment('center').width(100).build(),
            new Txt('').fontSize(8).end
          ]).margin([0, 10, 0, 0]).alignment('center').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.nomcia).fontSize(8).bold().alignment('center').end,
            new Txt('CAR. PANAMERICANA SUR KM. 26 6 FND. SANTA MARAI CI A CI A SALAS').fontSize(7).bold().alignment('center').end,
            new Txt(headers.dircia).fontSize(6).bold().alignment('center').end,
            new Txt('Cel: +(51) 914 158 497 Telf: (+51) 10692 0620').fontSize(7).bold().alignment('center').end,
            new Txt('www.inkaselectfruit.com').fontSize(7).bold().alignment('center').end,
          ]).margin([0, 30, 0, 0]).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  pdf.ln(1)
                ]).end
              ).border(noBorders).end
            ],
            [
              new Cell(
                new Txt(`RUC Nº ${headers.ruc}`).alignment('center').bold().fontSize(10).end
              ).margin(5).end
            ],
            [
              new Cell(
                new Txt('GUÍA DE REMISIÓN ELECTRÓNICA - REMITENTE').bold().fontSize(10).end
              ).border(verticalBorder).margin(0).fillColor('#73bb6f').color('white').alignment('center').bold().end
            ],
            [
              new Cell(
                new Txt(`${data.grc_numdoc.slice(0, 4)}-${data.grc_numdoc.slice(4, 14)}`).bold().fontSize(10).alignment('center').end
              ).margin(5).end
            ],
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2)
      ],
      [
        new Cell(
          new Stack([
            pdf.ln()
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('DATOS DEL TRASLADO').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('Fecha de Emisión:').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(invertirFecha(data.grc_fecdoc)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`Fecha de Inicio de traslado:   ${formatDateWithSlash(data.grc_fectra)}`).bold().fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(headers.indExportacion == 1 ? `Doc. Relacionado: ${data.grc_codexp} ${data.grc_numexp}` : '').fontSize(7).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt('Motivo de traslado').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`${data.kardex.t20_codt20} ${data.kardex.t20_descri}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`Modalidad de traslado: ${headers.modalidadTraslado}`).bold().fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),

        new Cell(
          new Txt(headers.indExportacion == 1 && data.t64_codt64 ? `Aeropuerto: ${data.t64_codt64} ${data.tabla64.t64_descri}` : '').fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(headers.indExportacion == 1 ? `Bulto o Pallets: ${data.grc_numbul}` : '').fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(data.kardex.t20_codt20 == '13' ? `Descripción Motivo:` : '').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.kardex.t20_codt20 == '13' ? data.kardex.mmo_desabr : '').fontSize(7).end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),

      ],
      [
        new Cell(
          new Txt('DATOS DEL DESTINATARIO').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('Apellidos y nombres denominación o razón').fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(data.cliente.cli_nomcli).fontSize(7).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt('Documento de identidad').fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(data.cliente.docidentidad.DID_CODDID == '001' ? `${data.cliente.docidentidad.DID_DESCRI}: ${data.cliente.cli_numruc}` : `${data.cliente.docidentidad.DID_DESCRI}: ${data.cliente.cli_numdni}`).fontSize(7).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt('DATOS DEL PUNTO DE PARTIDA Y PUNTO DE LLEGADA').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('Dirección del punto de partida').fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.cli_codppt == '20605493603' ? data.lugarsalida.ubs_codubs : data.lugarpartida.ubs_codubs).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(data.cli_codppt == '20605493603' ? data.lugarsalida.LDC_DIRLDC : data.lugarpartida.LDE_DIRLDE).fontSize(7).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt('Dirección del punto de llegada').fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.lugarllegada.ubs_codubs).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(data.lugarllegada.LDE_DIRLDE).fontSize(7).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt('DATOS DEL TRANSPORTE').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt(`Transportista: ${data.transportista.cli_numruc}      ${data.transportista.cli_nomcli}`).fontSize(7).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('Datos de los vehîculos').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Nro. Placa:').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(data.grc_plaund).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('Marca:').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(data.grc_marund).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`Nro. Cert Inscripción: ${data.grc_nrocer}`).fontSize(7).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2)

      ],
      [
        new Cell(
          new Txt('Datos de los conductores').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.grc_nomcho).fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Nro. Lic. : ${data.grc_nrolic}`).fontSize(7).end
        ).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4)
      ],
      [
        new Cell(
          new Txt('BIENES A TRANSPORTAR').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt(`Peso Bruto: ${data.grc_pesbru}`).fontSize(7).end
        ).colSpan(9).border(noBorders).end,
        ...obtenerNulls(8),

      ],
      [
        new Cell(
          new Txt('Nro').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt('Código').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt('Descripción').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(headers.indExportacion == 1 ? 'Cód. prod. SUNAT' : '').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt('Cantidad').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt('Unidad').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt(data.kardex_map[0].ume_codven == 'KGS' ? 'Peso Neto' : 'Referencia').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
      ],
      ...detalle,
      [
        new Cell(
          new Stack([
            pdf.ln(1)
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Stack([
            new Txt(`OBSERVACIONES:`).fontSize(8).bold().end,
            new Txt(data.kardex.mac_glomac ? data.kardex.mac_glomac : '').fontSize(8).end,
            pdf.ln(),
            new Txt(data.grc_obsgen ? data.grc_obsgen : '').fontSize(8).end,
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],

      [
        new Cell(
          new Stack([
            pdf.ln(1)
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
    ]).widths([20, 65, 50, 60, 65, 70, 55, 40, 60]).end
  )


  pdf.create().open();


}

export async function verPDFGuiaRemisionV2(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('portrait');
  pdf.pageMargins([20, 20, 20, 20]);

  // pdf.footer(function (currentPage, pageCount, pageSize) {
  //   return [

  //     { text: `Representación impresa de la guía electrónica autorizado a ser emisor electrónico mediante Resolución de Superintendencia  N. 000123-2022/ SUNAT.`, fontSize: 7, bold: false, margin: [10, 2, 10, 10], alignment: 'center' },
  //   ];
  // })

  pdf.footer((currentPage, pageCount) => {
    return {
      stack: [
        {
          columns: [
            {
              stack: [

                {
                  columns: [
                    // {
                    //   qr: 'https://example.com',
                    //   fit: 50,
                    //   alignment: 'right',
                    //   margin: [0, 5, 0, 0]
                    // },
                    // {
                    //   stack: [
                    //     { text: `Código de seguridad (Hash).`, fontSize: 7, bold: false, margin: [10, 20, 0, 0], alignment: 'left' },
                    //     { text: `fadfsf2342f234afs234fa23232f===`, fontSize: 7, bold: false, margin: [10, 0, 0, 0], alignment: 'left' },
                    //   ]

                    // }
                  ]
                },
                // {
                //   text: `Representación impresa de la guía electrónica autorizado a ser emisor electrónico mediante Resolución de Superintendencia  N. 000123-2022/ SUNAT.`,
                //   fontSize: 7,
                //   bold: false,
                //   margin: [0, 5, 0, 5],
                //   alignment: 'center'
                // },

              ]
            }
            // { text: `${currentPage} of ${pageCount}`, alignment: 'right', margin: [0, 15, 10, 0] }
          ],
          margin: [0, -50, 0, 0] // Adjust the margin to position the text correctly
        }
      ]
    };
  });


  pdf.watermark({ text: 'No válido', opacity: 0.1, fontSize: 64 })


  let detalle = [];

  data.kardex_map.forEach((element, index) => {
    detalle.push([
      new Cell(
        new Txt(index + 1).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.prd_codprd).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.producto.prd_desesp).fontSize(6).end
      ).border(noBorders).colSpan(3).end,
      ...obtenerNulls(2),
      new Cell(
        new Txt(element.producto.prd_codsun).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.map_canprd).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.producto.ume_codume).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(`${element.map_canven ? element.map_canven : ''} ${element.ume_codven ? element.unidadventa.ume_descri : ''}`).fontSize(6).end
      ).border(noBorders).end,
    ],)
  });

  pdf.add(
    new Table([
      [

        new Cell(
          new Stack([
            await new Img(headers.logo).alignment('center').width(100).build(),
            new Txt('').fontSize(8).end
          ]).margin([0, 10, 0, 0]).alignment('center').end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt(headers.nomcia).fontSize(8).bold().alignment('center').end,
            new Txt('CAR. PANAMERICANA SUR KM. 26 6 FND. SANTA MARAI CI A CI A SALAS').fontSize(7).bold().alignment('center').end,
            new Txt(headers.dircia).fontSize(6).bold().alignment('center').end,
            new Txt('Cel: +(51) 914 158 497 Telf: (+51) 10692 0620').fontSize(7).bold().alignment('center').end,
            new Txt('www.inkaselectfruit.com').fontSize(7).bold().alignment('center').end,
          ]).margin([0, 30, 0, 0]).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  pdf.ln(1)
                ]).end
              ).border(noBorders).end
            ],
            [
              new Cell(
                new Txt(`RUC Nº ${headers.ruc}`).alignment('center').bold().fontSize(10).end
              ).margin(5).end
            ],
            [
              new Cell(
                new Txt('GUÍA DE REMISIÓN ELECTRÓNICA - REMITENTE').bold().fontSize(10).end
              ).border(verticalBorder).margin(0).fillColor('#73bb6f').color('white').alignment('center').bold().end
            ],
            [
              new Cell(
                new Txt(`${data.grc_numdoc.slice(0, 4)}-${data.grc_numdoc.slice(4, 14)}`).bold().fontSize(10).alignment('center').end
              ).margin(5).end
            ],
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2)
      ],
      [
        new Cell(
          new Stack([
            pdf.ln()
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('DATOS DEL TRASLADO').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('Fecha de Emisión:').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(invertirFecha(data.grc_fecdoc)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`Fecha de Inicio de traslado:   ${formatDateWithSlash(data.grc_fectra)}`).bold().fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(headers.indExportacion == 1 ? `Doc. Relacionado: ${data.grc_codexp} ${data.grc_numexp}` : '').fontSize(7).end
        ).colSpan(4).border(noBorders).end,
        ...obtenerNulls(3),
      ],
      [
        new Cell(
          new Txt('Motivo de traslado').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`${data.kardex.t20_codt20} ${data.kardex.t20_descri}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`Modalidad de traslado: ${headers.modalidadTraslado}`).bold().fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),

        new Cell(
          new Txt(headers.indExportacion == 1 && data.t64_codt64 ? `Aeropuerto: ${data.t64_codt64} ${data.tabla64.t64_descri}` : '').fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(headers.indExportacion == 1 ? `Bulto o Pallets: ${data.grc_numbul}` : '').fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1)
      ],
      [
        new Cell(
          new Txt(data.kardex.t20_codt20 == '13' ? `Descripción Motivo:` : '').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.kardex.t20_codt20 == '13' ? data.kardex.mmo_desabr : '').fontSize(7).end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),

      ],
      [
        new Cell(
          new Txt('DATOS DEL DESTINATARIO').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('Apellidos y nombres denominación o razón').fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(data.cliente.cli_nomcli).fontSize(7).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt('Documento de identidad').fontSize(7).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(data.cliente.docidentidad.DID_CODDID == '001' ? `${data.cliente.docidentidad.DID_DESCRI}: ${data.cliente.cli_numruc}` : `${data.cliente.docidentidad.DID_DESCRI}: ${data.cliente.cli_numdni}`).fontSize(7).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt('DATOS DEL PUNTO DE PARTIDA Y PUNTO DE LLEGADA').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('Dirección del punto de partida').fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(headers.ubicacion2).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(headers.glosa2).fontSize(7).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt('Dirección del punto de llegada').fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.lugarllegada.ubs_codubs).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(data.lugarllegada.LDE_DIRLDE).fontSize(7).end
        ).colSpan(6).border(noBorders).end,
        ...obtenerNulls(5)
      ],
      [
        new Cell(
          new Txt('DATOS DEL TRANSPORTE').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt(`Transportista: ${data.transportista.cli_numruc}      ${data.transportista.cli_nomcli}`).fontSize(7).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt('Datos de los vehîculos').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('Nro. Placa:').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(data.grc_plaund).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('Marca:').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(data.grc_marund).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`Nro. Cert Inscripción: ${data.grc_nrocer}`).fontSize(7).end
        ).colSpan(3).border(noBorders).end,
        ...obtenerNulls(2)

      ],
      [
        new Cell(
          new Txt('Datos de los conductores').bold().fontSize(7).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.grc_nomcho).fontSize(7).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`Nro. Lic. : ${data.grc_nrolic}`).fontSize(7).end
        ).colSpan(5).border(noBorders).end,
        ...obtenerNulls(4)
      ],
      [
        new Cell(
          new Txt('BIENES A TRANSPORTAR').bold().fontSize(8).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Txt(`Peso Bruto: ${data.grc_pesbru}`).fontSize(7).end
        ).colSpan(9).border(noBorders).end,
        ...obtenerNulls(8),

      ],
      [
        new Cell(
          new Txt('Nro').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt('Código').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt('Descripción').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt(headers.indExportacion == 1 ? 'Cód. prod. SUNAT' : '').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt('Cantidad').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt('Unidad').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
        new Cell(
          new Txt(data.kardex_map[0].ume_codven == 'KGS' ? 'Peso Neto' : 'Referencia').bold().fontSize(8).end
        ).border(noBorders).fillColor('#73bb6f').color('white').end,
      ],
      ...detalle,
      [
        new Cell(
          new Stack([
            pdf.ln(1)
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
      [
        new Cell(
          new Stack([
            new Txt(`OBSERVACIONES:`).fontSize(8).bold().end,
            new Txt(data.kardex.mac_glomac ? data.kardex.mac_glomac : '').fontSize(8).end,
            pdf.ln(),
            new Txt(data.grc_obsgen ? data.grc_obsgen : '').fontSize(8).end,
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],

      [
        new Cell(
          new Stack([
            pdf.ln(1)
          ]).end
        ).border(noBorders).colSpan(9).end,
        ...obtenerNulls(8)
      ],
    ]).widths([20, 65, 50, 60, 65, 70, 55, 40, 60]).end
  )


  pdf.create().open();
}

function invertirFecha(fecha: string): string {
  // Dividir la fecha en partes usando el carácter "-"
  const partes = fecha.split("-");

  // Reordenar las partes y unirlas con "/"
  const fechaInvertida = `${partes[2]}/${partes[1]}/${partes[0]}`;

  return fechaInvertida;
}
