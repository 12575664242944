import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'requerimiento-pago-list',
  templateUrl: './requerimiento-pago-list.component.html',
  styleUrls: ['./requerimiento-pago-list.component.scss']
})
export class RequerimientoPagoListComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }
}
