import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_NOMINA_CONCEPTOS } from "@data/constants/table/theader.nomina";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ApiNominaConfiguracionesConceptoRemuneracionService } from "src/app/services/api/nomina/configuraciones/nomina.configuraciones.concepto-remuneracion.service";

@Component({
  selector: 'app-concepto-remunerativo-list',
  templateUrl: './concepto-remunerativo-list.component.html',
  styleUrls: ['./concepto-remunerativo-list.component.scss']
})
export class ConceptoRemunerativoListComponent implements OnInit, OnDestroy{

  concepto$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_CONCEPTOS;

  conceptos: Array<any> = [];

  constructor(
    private _apiConceptoRemunerativoNominaService: ApiNominaConfiguracionesConceptoRemuneracionService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {

    this.concepto$ = this._apiConceptoRemunerativoNominaService.listarConceptosRemunerativos().subscribe(
      response => this.conceptos = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.concepto$, this.loading$]);
  }
}
