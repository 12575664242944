<div class="pav-snack-container">
  <div class="pav-snack-icon">
    <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>done</mat-icon>
    </button>
  </div>
  <div class="pav-snack-text">{{data.message}}</div>
  <div class="pav-separator"></div>
  <div class="pav-snack-action" (click)="closeSnackbar()">{{data.action}}</div>
</div>
