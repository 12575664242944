import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { LANGUAGE_CRM_EN, LANGUAGE_CRM_ES } from "@shared/resources/language.crm";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { geenerarPDFOrdenPedido } from "@utils/pdfmaker/builder/crm.pdfmaker";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ConfigurationService, CrmService } from "src/app/services";
import { ApiCRMOperacionesOrdenPedidoService } from "src/app/services/api/crm/operaciones/crm.operaciones.orden-pedido.service";

@Component({
  selector: 'orden-pedido-list',
  templateUrl: './orden-pedido-list.component.html',
  styleUrls: ['./orden-pedido-list.component.scss']
})
export class OrdenPedidoListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'idtkt', 'numpcc', 'fecdoc'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  ordenPedido$: Subscription;
  generatePDF$: Subscription;

  constructor(
    private _crmService: CrmService,
    private _apiOrdenPedidoCrmService: ApiCRMOperacionesOrdenPedidoService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });
  }

  loadData(): void {
    this.obtenerOrdenesPedido();
  }

  obtenerOrdenesPedido(): void {
    this.ordenPedido$ = this._apiOrdenPedidoCrmService.obtenerOrdenesPedido().subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  generarPDF(numpcc): void {
    const headers: any = {
      nomcia: this._configurationService.obtenerNombreCliente(),
      numruc: this._configurationService.obtenerClienteId(),
      coduse: this._configurationService.obtenerIdUsuario(),
      hour: getHourMinuteSecondsCurrent(new Date()),
      date: formatDateWithSlash(new Date())
    }

    this.generatePDF$ = this._crmService.obtenerPDFOrdenPedido(numpcc).subscribe(
      response => {

        const language = this._configurationService.obtenerIdioma();

        let languageJSON = LANGUAGE_CRM_ES;

        if(language == 'EN') languageJSON = LANGUAGE_CRM_EN;
        geenerarPDFOrdenPedido(headers, response[0], languageJSON);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.loading$, this.ordenPedido$])
  }
}
