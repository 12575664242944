import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@shared/services/snackbar.service';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';

@Component({
  selector: 'app-ilumi-product-list',
  templateUrl: './ilumi-product-list.component.html',
  styleUrls: ['./ilumi-product-list.component.css']
})
export class IlumiProductListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['nombre', 'description', 'um', 'tipo_inventario',  'linea_producto'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  producto$: Subscription;

  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    private _productoService: ProductoService,
    private _snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<IlumiProductListComponent>,

  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.obtenerProductos()
  }

  obtenerProductos(): void {
    this.producto$ = this._productoService.obtenerProductos().subscribe(
      response => this.dataSource = fillTable(response, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  seleccionarProducto(data) {

    console.log(data);
    this.dialogRef.close(data);

  }

  cancelar(): void {
    this.dialogRef.close();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  seleccionar(): void {
  }

  search(): void {
    if(this.dataSource.filteredData.length == 1) {
      this.dialogRef.close(this.dataSource.filteredData[0])
      return;
    }

    this._snackBarService.showError('Debe de encontrar solo un producto', 'OK');

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.producto$
    ])
  }

}
