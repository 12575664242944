export class TipoDocumentoLegajo {
  codtdl: string;
  descri: string;
  indsta: string;
  indafi: string;
  coduse: string;
  fecupd: Date;

  constructor() {
    this.fecupd = new Date();
    this.indsta = '1';
    this.indafi = '0';
  }
}
