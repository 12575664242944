<app-pavso-title-section [title]="'Control SIRE Ventas'" [module]="'Contabilidad'"
  [ngClass]="'pav-form'"></app-pavso-title-section>
<mat-tab-group>
  <mat-tab label="Propuesta SUNAT" labelClass="mat-tab-label-0-1">
    <form action="">
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l4">
            <div [ngStyle]="{display: 'flex'}">
              <mat-form-field class="example-full-width">
                <mat-label>Nro. Ticket</mat-label>
                <input matInput placeholder="Ej. 00000">
              </mat-form-field>
              <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
                <mat-icon>manage_search</mat-icon>
              </button>
            </div>
          </div>
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Moneda</mat-label>
              <mat-select [(ngModel)]="filtro.moneda" name="moneda">
                <mat-option [value]="'SO'">
                  Soles
                </mat-option>
                <mat-option [value]="'DO'">
                  Dólares
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col s12 m12">
            <button
              [ngStyle]="{height: '45px', width: isMobile || isTablet ? '100%': '180px', background: '#F7C91B', color: '#222'}"
              mat-stroked-button (click)="descargarSIRE()" color="primary" [class.spinner]="loaderSIRE"
              [disabled]="loaderSIRE || loaderData" matTooltip="Descargar SIRE">
              <mat-icon>cloud_download</mat-icon>
              Descargar Propuesta
            </button>

            <button
              [ngStyle]="{height: '45px', width: isMobile || isTablet ? 'auto': 'auto', background: '#6cd6df', color: '#222', marginLeft: '5px'}"
              mat-stroked-button (click)="errorRegistrosSIRESunat()" color="primary" [disabled]="loaderData"
              matTooltip="Error registros SIRE">
              <mat-icon>warning</mat-icon>
              Error registros SIRE SUNAT
            </button>
          </div>
        </div>
      </mat-card>

      <div class="mat-elevation-z0">
        <table-title-action title="Detalle" url="/modulo-contabilidad/centro-de-costo" entity="" [params]="['0']"
          (clickDescargar)="descargarExcel()"></table-title-action>
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <div class="overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <div class="pav-btns-container">
                  <div class="pav-btn-circle-edit" matTooltip="Editar"
                    [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col01">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código" class="td-responsive"> {{i + 1}}
              </td>
            </ng-container>

            <ng-container matColumnDef="col02">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecemi | date:
                'dd/MM/YYYY': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="col03">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecven | date:
                'dd/MM/YYYY': 'UTC'}} </td>
            </ng-container>

            <ng-container matColumnDef="col04">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipdoc}} </td>
            </ng-container>

            <ng-container matColumnDef="col05">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_serdoc}} </td>
            </ng-container>

            <ng-container matColumnDef="col06">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. Inicial </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numini}} </td>
            </ng-container>

            <ng-container matColumnDef="col07">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. Final </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numfin}} </td>
            </ng-container>

            <ng-container matColumnDef="col08">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento Identidad </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipide}} </td>
            </ng-container>

            <ng-container matColumnDef="col09">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento Identidad </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numide}} </td>
            </ng-container>

            <ng-container matColumnDef="col10">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_proraz}} </td>
            </ng-container>

            <ng-container matColumnDef="col11">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Facturado Exportación </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_bigrdg.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col12">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> BI Gravado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_bigrdg.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col13">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descuento BI </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_igvdg.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col14">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> BI Gravado DGNG </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_bigdgnd.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col15">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV / IPM </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_igvdgng.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col16">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descuento IGV </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_bigrdng.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col17">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Exonerado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_igvdng.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col18">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Inafecto </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_vaadng.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col19">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ISC </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_impisc.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col20">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> BI Grav. IVAP</th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_icbper.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col21">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IVAP </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_otrtri.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col22">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ICBPER </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_imptot.toFixed(2)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col23">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Tributos </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_codtmo}} </td>
            </ng-container>

            <ng-container matColumnDef="col24">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div [ngStyle]="{width: '70px', textAlign: 'right', paddingRight: '10px'}">
                  {{row.psc_tipcam.toFixed(3)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="col25">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecref}} </td>
            </ng-container>

            <ng-container matColumnDef="col26">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Cambio </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_docref}} </td>
            </ng-container>

            <ng-container matColumnDef="col27">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Doc. Modificado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_serref}} </td>
            </ng-container>

            <ng-container matColumnDef="col28">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. Modificado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_coddam}} </td>
            </ng-container>

            <ng-container matColumnDef="col29">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie Doc. Modificado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_coddam}} </td>
            </ng-container>

            <ng-container matColumnDef="col30">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. Modificado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_numref}} </td>
            </ng-container>

            <ng-container matColumnDef="col31">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Proyecto Operadores Atribución </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_idprop}} </td>
            </ng-container>

            <ng-container matColumnDef="col32">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Nota </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_porpar}} </td>
            </ng-container>

            <ng-container matColumnDef="col33">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_impimb}} </td>
            </ng-container>

            <ng-container matColumnDef="col34">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor FOB Embarcado </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_carori}} </td>
            </ng-container>

            <ng-container matColumnDef="col35">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor OP Gratuitas </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_detracc}} </td>
            </ng-container>

            <ng-container matColumnDef="col36">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de operación </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_tipnot}} </td>
            </ng-container>

            <ng-container matColumnDef="col37">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> DAM/CP </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_estcom}} </td>
            </ng-container>

            <ng-container matColumnDef="col38">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CLU </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_incal}} </td>
            </ng-container>

            <ng-container matColumnDef="col39">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CAR SUNAT </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_coduse}} </td>
            </ng-container>

            <ng-container matColumnDef="col40">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_fecupd}} </td>
            </ng-container>

            <ng-container matColumnDef="col41">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Act. </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{row.psc_clu1}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="81">No se encontraron registros </td>

            </tr>

          </table>

        </div>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Cantidad ítems</mat-label>
              <input readonly [(ngModel)]="dataSource.data.length" name="cantidad_items"
                [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total Valor Facturado Exportación</mat-label>
              <input readonly [(ngModel)]="totalBIGravadoDG" name="totalBIGravadoDG" [ngStyle]="{textAlign: 'right'}"
                matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Tot. BI Gravado</mat-label>
              <input readonly [(ngModel)]="totalIGVIPMDG" name="totalIGVIPMDG" [ngStyle]="{textAlign: 'right'}" matInput
                placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Tot. Descuento BI</mat-label>
              <input readonly [(ngModel)]="totalIGVIPMDG" name="totalIGVIPMDG" [ngStyle]="{textAlign: 'right'}" matInput
                placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total IGV / IPM</mat-label>
              <input readonly [(ngModel)]="totalBIGravadoDGNG" name="totalBIGravadoDGNG"
                [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Total Descuento IGV</mat-label>
              <input readonly [(ngModel)]="totalIGVIPMDGNG" name="totalIGVIPMDGNG" [ngStyle]="{textAlign: 'right'}"
                matInput placeholder="Ej. 23">
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Total Exonerado</mat-label>
              <input readonly [(ngModel)]="totalIGVIPMDGNG" name="totalIGVIPMDGNG" [ngStyle]="{textAlign: 'right'}"
                matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total Inafecto</mat-label>
              <input readonly [(ngModel)]="totalBIGravadoDNG" name="totalBIGravadoDNG" [ngStyle]="{textAlign: 'right'}"
                matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Total ISC</mat-label>
              <input readonly [(ngModel)]="totalIGVIPMDNG" name="totalIGVIPMDNG" [ngStyle]="{textAlign: 'right'}"
                matInput placeholder="Ej. 23">
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Total BI Grav. IVAP</mat-label>
              <input readonly [(ngModel)]="totalValorAdqNG" name="totalValorAdqNG" [ngStyle]="{textAlign: 'right'}"
                matInput placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total IVAP</mat-label>
              <input readonly [(ngModel)]="totalISC" name="totalISC" [ngStyle]="{textAlign: 'right'}" matInput
                placeholder="Ej. 23">
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Total ICBPER</mat-label>
              <input readonly [(ngModel)]="totalISC" name="totalISC" [ngStyle]="{textAlign: 'right'}" matInput
                placeholder="Ej. 23">
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Total Otros Trib.</mat-label>
              <input readonly [(ngModel)]="totalISC" name="totalISC" [ngStyle]="{textAlign: 'right'}" matInput
                placeholder="Ej. 23">
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field class="example-full-width">
              <mat-label>Total</mat-label>
              <input readonly [(ngModel)]="totalICPPER" name="totalICPPER" [ngStyle]="{textAlign: 'right'}" matInput
                placeholder="Ej. 23">
            </mat-form-field>

          </div>
        </div>
      </mat-card>
    </form>

  </mat-tab>
  <mat-tab label="Reemplazo PAV" labelClass="mat-tab-label-0-2">
    <div class="mat-elevation-z0">
      <table-title-action title="Todos los centros" url="/modulo-contabilidad/centro-de-costo" entity=""
        [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource2" matSort>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar"
                  [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="f01">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="f02">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CUO (1) </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.ccs_descri}}
            </td>
          </ng-container>

          <ng-container matColumnDef="f03">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo (2) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f04">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión comprobante (3) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f05">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento (4) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f06">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Comprobante (5) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f07">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie (6) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f08">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante (7) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f09">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Registro Tickets o Cintas Emitidos (8) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. Identidad (9) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. RUC / Doc. Identidad (10) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f12">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Razon Social (11) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f13">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f14">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Facturado de la Exportación (12) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f15">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imp. Oper. Gravadas (13) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f16">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe de la Oper. Exonerada (14) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f17">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe de la Oper. Inafecta (15) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f18">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Impuesto Selectivo al Consumo (16) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f19">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV / IPM (17) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f20">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Imponible Oper. Gravada Arroz Pilado (18) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f21">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Impuesto Arroz Pilado (19) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f22">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Tributos (20) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f23">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total (21) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f24">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Cambio (22) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f25">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión Comp. Pago (23) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f26">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Comprob. Modifica (24) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f27">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie Modifica o Dep. Aduanera (25) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f28">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Comprobante Modifica o Num. DUA (26) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f29">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado (27) </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f30">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f31">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Act. </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="32">No se encontraron registros </td>

          </tr>

        </table>

      </div>


      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Cantidad ítems</mat-label>
            <input readonly [(ngModel)]="dataSource.data.length" name="cantidad_items" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Tot. Bas. Imponible Gravadas</mat-label>
            <input readonly [(ngModel)]="totalBIGravadoDG" name="totalBIGravadoDG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Tot. Desc. Base Imponible</mat-label>
            <input readonly [(ngModel)]="totalIGVIPMDG" name="totalIGVIPMDG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Total IGV</mat-label>
            <input readonly [(ngModel)]="totalBIGravadoDGNG" name="totalBIGravadoDGNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Total Descuento IGV</mat-label>
            <input readonly [(ngModel)]="totalIGVIPMDGNG" name="totalIGVIPMDGNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Valor de Exportación</mat-label>
            <input readonly [(ngModel)]="totalBIGravadoDNG" name="totalBIGravadoDNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Inafecta</mat-label>
            <input readonly [(ngModel)]="totalIGVIPMDNG" name="totalIGVIPMDNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Exonerada</mat-label>
            <input readonly [(ngModel)]="totalValorAdqNG" name="totalValorAdqNG" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>ISC</mat-label>
            <input readonly [(ngModel)]="totalISC" name="totalISC" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Otros atributos</mat-label>
            <input readonly [(ngModel)]="totalICPPER" name="totalICPPER" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Arroz Pilado</mat-label>
            <input readonly [(ngModel)]="totalOtrosTributos" name="totalOtrosTributos" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Imp. Arroz Pilado</mat-label>
            <input readonly [(ngModel)]="totalOtrosTributos" name="totalOtrosTributos" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>

        </div>

        <div class="col s12 m12 l2">
          <div [ngStyle]="{display: 'flex'}">
            <mat-form-field class="example-full-width">
              <mat-label>Archivo</mat-label>
              <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
            </mat-form-field>
            <button [ngStyle]="{marginLeft: '5px'}" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
              <mat-icon>done_all</mat-icon>
            </button>
          </div>

          <button mat-button color="primary">Genera TXT SIRE</button>

        </div>
      </div>
    </mat-card>
  </mat-tab>
  <mat-tab label="Documentos en PAV y no en SUNAT" labelClass="mat-tab-label-0-3">
    <div class="mat-elevation-z0">
      <table-title-action title="Todos los centros" url="/modulo-contabilidad/centro-de-costo" entity=""
        [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource3" matSort>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar"
                  [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="f01">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="f02">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número Identidad </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.ccs_descri}}
            </td>
          </ng-container>

          <ng-container matColumnDef="f03">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f04">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f05">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f06">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f07">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f08">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f09">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CUO </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="12">No se encontraron registros </td>

          </tr>

        </table>

      </div>


      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Cantidad ítems</mat-label>
            <input readonly [(ngModel)]="dataSource.data.length" name="cantidad_items" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
        </div>

      </div>
    </mat-card>
  </mat-tab>
  <mat-tab label="Documentos en SUNAT y no en PAV" labelClass="mat-tab-label-0-4">
    <div class="mat-elevation-z0">
      <table-title-action title="Todos los centros" url="/modulo-contabilidad/centro-de-costo" entity=""
        [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource4" matSort>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <div class="pav-btn-circle-edit" matTooltip="Editar"
                  [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="f01">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fila </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="f02">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CAR SUNAT </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.ccs_descri}}
            </td>
          </ng-container>

          <ng-container matColumnDef="f03">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f04">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f05">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f06">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f07">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Doc. Identidad </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f08">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. Identidad </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f09">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Razon Social </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <ng-container matColumnDef="f11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")?
              "VIGENTE": "NO VIGENTE"}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="12">No se encontraron registros </td>

          </tr>

        </table>

      </div>


      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Cantidad ítems</mat-label>
            <input readonly [(ngModel)]="dataSource.data.length" name="cantidad_items" [ngStyle]="{textAlign: 'right'}" matInput placeholder="Ej. 23">
          </mat-form-field>
        </div>

      </div>
    </mat-card>
  </mat-tab>
</mat-tab-group>
