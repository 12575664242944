import { PavsoColors } from "@data/enums/color.enum";
import { noBorders, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, Img, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";

export async function generarFormatoOrdenPago(headers, data) {

  const pdf = new PdfMakeWrapper();

  pdf.pageMargins([20, 20, 20, 20]);

  pdf.header(function (currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });

  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [5, 2, 10, 10], alignment: 'right' },
    ];
  })

  let detalle = [];

  let totalDebeMN = 0;
  let totalHaberMN = 0;
  let totalDebeME = 0;
  let totalHaberME = 0;

  if(!data.detalle_ldi) {
    data.detalle_ldi = [];
  }

  data.detalle_ldi.forEach(item => {

    totalDebeMN += item.LDI_INDDHA == 'D'? item.LDI_IMPNAC: 0;
    totalHaberMN += item.LDI_INDDHA == 'H'? item.LDI_IMPNAC: 0;
    totalDebeME += item.LDI_INDDHA == 'D'? item.LDI_IMPMEX: 0;
    totalHaberME += item.LDI_INDDHA == 'H'? item.LDI_IMPMEX: 0;

    detalle.push([
      new Cell(
        new Txt(item.LDI_CORLDI).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.CCN_CODCCN).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.cuenta.ccn_descri).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.CLI_CODCLI).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.CCS_CODCCS).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.LDI_DOCREF).fontSize(6).end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.LDI_INDDHA == 'D'? item.LDI_IMPNAC: '').fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.LDI_INDDHA == 'H'? item.LDI_IMPNAC: '').fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.LDI_INDDHA == 'D'? item.LDI_IMPMEX: '').fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.LDI_INDDHA == 'H'? item.LDI_IMPMEX: '').fontSize(6).alignment('right').end
      ).border(noBorders).end,
      new Cell(
        new Txt(item.LDI_GLOLDI).fontSize(6).end
      ).border(noBorders).end,
    ],)
  })

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            await new Img(headers.logo).alignment('left').width(80).build(),
          ]).end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(headers.titulo).margin([0, 10]).fontSize(9).alignment('center').color('#000').bold().end,
            new Txt(headers.nomcia).fontSize(8).alignment('center').color('#000').end,
            new Txt(headers.dircia).fontSize(8).alignment('center').color('#000').end,
            pdf.ln(2),
          ]).alignment('center').end,
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt('').end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('PERIODO:').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`${data.ANO_CODANO} - ${data.MES_CODMES}`).fontSize(8).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`SUBDIARIO: ${data.SCO_CODSCO}`).bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`VOUCHER: ${data.LDC_CORLDC}`).bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('FECHA:').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.PBA_FECORD).fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt(headers.sco_indban == 1 ? 'CTA. BANCO:': '').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(headers.sco_indban == 1 ? data.cuentabanco.CBA_DESCRI: '').bold().fontSize(8).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt('TIP. CAMBIO:').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.PBA_TIPCAM).fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('AUXILIAR:').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.cliente.cli_nomcli).bold().fontSize(8).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt('IMPORTE:').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.TMO_CODTMO == 'SO'? `S/. ${data.PBA_IMPSOL}`: `$ ${data.PBA_IMPDOL}`).fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('GLOSA:').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.PBA_GLOPBA).bold().fontSize(8).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt('OPERACIÓN:').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(`${data.TDO_CODTDO} ${data.PBA_NUMDOC}`).fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('').bold().fontSize(8).end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt(data.TDO_CODTDO == 'CHE' ? 'FECHA CHEQUE:': '').bold().fontSize(8).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(data.TDO_CODTDO == 'CHE' ? data.PBA_FECCHE: '').fontSize(8).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('IT.').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).rowSpan(2).end,
        new Cell(
          new Txt('CUENTA').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).rowSpan(2).end,
        new Cell(
          new Txt('DESCRIPCIÓN').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).rowSpan(2).end,
        new Cell(
          new Txt('AUXILIAR').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).rowSpan(2).end,
        new Cell(
          new Txt('C. COSTO').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).rowSpan(2).end,
        new Cell(
          new Txt('NRO. DOC.').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).rowSpan(2).end,
        new Cell(
          new Txt('MONEDA NACIONAL').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('MONEDA EXTRANJERA').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt('GLOSA').alignment('center').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).rowSpan(2).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).colSpan(6).end,
        ...obtenerNulls(5),
        new Cell(
          new Txt('DEBE').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('HABER').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('DEBE').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('HABER').bold().fontSize(7).end
        ).fillColor(PavsoColors.Gray).color(PavsoColors.Black).end,
        new Cell(
          new Txt('').end
        ).end,
      ],
      ...detalle,
      [
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt('TOTAL GENERAL').fontSize(6).end
        ).colSpan(2).border(noBorders).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(totalDebeMN.toFixed(2)).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(totalHaberMN.toFixed(2)).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(totalDebeME.toFixed(2)).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt(totalHaberME.toFixed(2)).alignment('right').bold().fontSize(6).end
        ).border(topBorder).end,
        new Cell(
          new Txt('').fontSize(6).end
        ).border(noBorders).end,
      ]
    ]).layout({
      hLineColor: (i, node) => {
        return i === 0 || i === node.table.body.length ? '#000000' : '#000000';
      },
      vLineColor: (i, node) => {
        return i === 0 || i === node.table.widths.length ? '#000000' : '#000000';
      },
      hLineWidth: (i, node) => {
        return 1;
      },
      vLineWidth: (i, node) => {
        return 1;
      }
    }).widths([20, 30, 70, 40, 50, 45, 30, 30, 30, 30, 80]).end
  )

  pdf.add(
    pdf.ln(4)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Table([
            [
              new Cell(
                new Stack([
                  new Txt('').end,
                  new Cell(
                    new Txt('RESPONSABLE').fontSize(8).alignment('center').end
                  ).border(topBorder).end
                ]).end
              ).border(topBorder).end,
              new Cell(
                new Txt('').end
              ).border(noBorders).end,
              new Cell(
                new Stack([
                  new Txt('').end,
                  new Cell(
                    new Txt('RECIBÍ CONFORME').fontSize(8).alignment('center').end
                  ).border(topBorder).end
                ]).end
              ).border(topBorder).end,
            ]
          ]).widths([110, 210, 110]).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
      ]
    ]).widths([35, 470, 35]).end
  )

  pdf.create().open();

}
