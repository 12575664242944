<app-pavso-title-section [title]="'Despacho'" [module]="'Almacén'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <select-report [reportes]="reportes" [reporteSeleccionado]="reporteSeleccionado[0]" (seleccionarReporte)="seleccionarReporte($event)" [disabled]="loading"></select-report>
  <mat-card class="mat-elevation-z0" align="center">
    <button class="pav-button-action" mat-flat-button color="primary" [class.spinner]="loading" [disabled]="loading" (click)="verReporte()" [ngStyle]="{width: isMobile? '90%': '200px'}">{{LABELS_NAME.BTN_SHOW_REPORT}}</button>
  </mat-card>
  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtros</div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderFields"></mat-progress-bar>
  <div class="pav-filtro-container">
    <div class="row">
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Familias</mat-label>
          <mat-select multiple [disabled]="loaderFields" [(ngModel)]="filtroAlmacen.familias" name="familias">
            <mat-option *ngFor="let familia of familias" [value]="familia.FPR_CODFPR">{{familia.fpr_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Sub Familias</mat-label>
          <mat-select multiple [disabled]="loaderFields" [(ngModel)]="filtroAlmacen.subFamilias" name="subFamilias">
            <mat-option *ngFor="let subfamilia of subfamilias" [value]="subfamilia.sfp_codsfp">{{subfamilia.sfp_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Almacenes</mat-label>
          <mat-select multiple [disabled]="loaderFields" [(ngModel)]="filtroAlmacen.almacenes" name="almacenes">
            <mat-option *ngFor="let almacen of almacenes" [value]="almacen.ALM_CODALM">{{almacen.ALM_DESCRI}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <select-search
          [label]="'Responsable'"
          [placeholder]="'Ej. SANDRA'"
          [name]="'cli_codcli'"
          [value]="'cli_codcli'"
          [description]="'cli_nomcli'"
          [data]="responsables"
          (cambioSelect)="filtroAlmacen.responsables = $event"
          [model]="filtroAlmacen.responsables"
          [multiple]="true">
        </select-search>
      </div>
      <div class="col s12 m12 l4">
        <select-search
          [label]="'Motivo'"
          [placeholder]="'Ej. Motivo'"
          [name]="'mmo_codmmo'"
          [value]="'mmo_codmmo'"
          [description]="'mmo_descri'"
          [data]="motivos"
          (cambioSelect)="filtroAlmacen.motivos = $event"
          [model]="filtroAlmacen.motivos"
          [multiple]="true">
        </select-search>
      </div>
      <div class="col s12 m12 l4">
        <select-search
          [label]="'Producto'"
          [placeholder]="'Ej. Canasta'"
          [value]="'prd_codprd'"
          [name]="'prd_codprd'"
          [description]="'DESC_ESPA'"
          [data]="productos"
          (cambioSelect)="filtroAlmacen.productos = $event"
          [model]="filtroAlmacen.productos"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Inventarios</mat-label>
          <mat-select multiple [disabled]="loaderFields" [(ngModel)]="filtroAlmacen.tiposInventario" name="inventarios">
            <mat-option *ngFor="let inventario of inventarios" [value]="inventario.tin_codtin">{{inventario.tin_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Líneas</mat-label>
          <mat-select multiple [disabled]="loaderFields" [(ngModel)]="filtroAlmacen.lineas" name="lineas">
            <mat-option *ngFor="let linea of lineas" [value]="linea.lpd_codlpd">{{linea.lpd_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">

        <select-search
          [label]="'Tipo de Movimiento'"
          [placeholder]="'Ej. ING'"
          [name]="'code'"
          [value]="'code'"
          [description]="'tipo'"
          [data]="documentos"
          (cambioSelect)="filtroAlmacen.tiposDocumento = $event"
          [model]="filtroAlmacen.tiposDocumento"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Monedas</mat-label>
          <mat-select [disabled]="loaderFields" [(ngModel)]="filtroAlmacen.moneda" name="moneda">
            <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">{{moneda.tmo_deslar}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Marcas</mat-label>
          <mat-select multiple [disabled]="loaderFields" [(ngModel)]="filtroAlmacen.marcas" name="marcas">
            <mat-option *ngFor="let marca of marcas" [value]="marca.MPR_CODMPR">{{marca.MPR_DESCRI}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Rango de Fechas</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [disabled]="loaderFields">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>

    </div>
  </div>
</div>

