import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MaestrosLS } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private http: HttpClient) { }

  /**
   *
   * @returns
   */
  obtenerClientes(): Array<any> {
    return JSON.parse(localStorage.getItem('clientes'))
  }

  /**
   *
   * @param clientes
   */
  establecerClientes(clientes: Array<any>) {
    localStorage.setItem('clientes', JSON.stringify(clientes));
  }

  /**
   *
   * @returns
   */
  obtenerIdioma(): string {
    return JSON.parse(localStorage.getItem('language'))
  }

  /**
   *
   * @param idioma
   */
  establecerIdioma(idioma) {
    localStorage.setItem('language', JSON.stringify(idioma));
  }

  /**
   *
   * @returns
   */
  obtenerIdUsuario(): string {
    return JSON.parse(localStorage.getItem('user')).id;
  }

  /**
   *
   * @returns
   */
  obtenerNombreApellidoUsuario(): string {
    return JSON.parse(localStorage.getItem('user')).nombre + ' ' + JSON.parse(localStorage.getItem('user')).apellido;
  }

  obtenerNameUsuario(): string {
    return JSON.parse(localStorage.getItem('user')).nombre;
  }

  /**
   *
   * @param data
   */
  establecerIdUsuario(data: any): void {
    localStorage.setItem('user', data);
  }

  /**
   *
   * @returns
   */
  obtenerCodigoPagina(): string {
    return localStorage.getItem('code-page');
  }

  /**
   *
   * @param menu
   */
  establecerCodigoPagina(menu: string): void {
    localStorage.setItem('code-page', menu);
  }

  /**
  * Establecer tipo pagina
  * @returns
  */
  obtenerTipoPagina(): string {
    return localStorage.getItem('xfunction');
  }

  /**
   *
   * @param menu
   */
  establecerTipoPagina(menu: string): void {
    localStorage.setItem('xfunction', menu);
  }

  /**
   *
   * @returns
   */
  obtenerMenu() {
    return JSON.parse(localStorage.getItem('menu'));
  }

  obtenerModuloActivo(): string {
    return localStorage.getItem('codeModule');
  }

  establecerModuloActivo(modulo): void {
    localStorage.setItem('codeModule', modulo);
  }

  /**
   *
   * @param menu
   */
  establecerMenu(menu: Object): void {
    localStorage.setItem('menu', JSON.stringify(menu));
  }

  /**
   *
   * @returns
   */
  obtenerLlavesMenu(): string {
    return localStorage.getItem('llave-menu');
  }

  /**
   *
   * @param llaves
   */
  establecerLlavesMenu(llaves): void {
    localStorage.setItem('llave-menu', llaves);
  }

  /**
   *
   * @returns
   */
  obtenerTema(): string {
    return localStorage.getItem('tema');
  }

  /**
   *
   * @param tema
   */
  establecerTema(tema): void {
    localStorage.setItem('tema', tema);
  }

  /**
   *
   * @param modo
   */
  establecerModo(modo): void {
    localStorage.setItem('modo', modo);
  }

  /**
   *
   * @returns
   */
  obtenerModo(): string {
    return localStorage.getItem('modo');
  }

  /**
   *
   * @param modo
   */
  establecerClienteId(modo): void {
    localStorage.setItem('clientId', modo);
  }

  /**
   *
   * @returns
   */
  obtenerClienteId(): string {
    return localStorage.getItem('clientId');
  }

  /**
   *
   */
  obtenerPrograma(): string {
    return localStorage.getItem('code-page');
  }

  /**
   *
   * @param modo
   */
  establecerNombreCliente(modo): void {
    localStorage.setItem('name-client', modo);
  }

  /**
   *
   * @returns
   */
  obtenerNombreCliente(): string {
    return localStorage.getItem('name-client');
  }

  /**
   *
   * @param nombre
   */
  establecerNombreCompaniaCliente(nombre): void {
    localStorage.setItem('name-company-client', nombre);
  }

  /**
   *
   * @returns
   */
  obtenerNombreCompaniaCliente(): string {
    return localStorage.getItem('name-company-client');
  }

  /**
   *
   * @param codpai
   */
  establecerPais(codpai): void {
    localStorage.setItem('codpai', codpai);
  }

  /**
   *
   * @returns
   */
  obtenerPais(): string {
    return localStorage.getItem('codpai');
  }

  /**
   *
   * @param modo
   */
  establecerUrl(modo): void {
    localStorage.setItem('url', modo);
  }

  /**
   *
   * @returns
   */
  obtenerUrl(): string {
    return localStorage.getItem('url');
  }

  /**
   *
   */
  removerUrl(): void {
    localStorage.removeItem('url');
  }

  /**
  *
  * @param companies
  */
  establecerCompaniasCliente(companies): void {
    localStorage.setItem('companies-client', companies);
  }

  /**
   *
   * @returns
   */
  obtenerCompaniasCliente(): string {
    return localStorage.getItem('companies-client');
  }

  /**
   *
   * @param nombre
   */
  establecerCompaniaCliente(nombre): void {
    localStorage.setItem('company-client', nombre);
  }

  /**
   *
   * @returns
   */
  obtenerCompaniaCliente(): string {
    return localStorage.getItem('company-client')|| '01';
  }

  /**
   *
   */
  removerCompaniaCliente(): void {
    localStorage.removeItem('company-client');
  }

  /**
   *
   * @param ruc
   */
  establecerCiaNumRuc(ruc): void {
    localStorage.setItem('cianumruc', ruc);
  }

  /**
   *
   * @returns
   */
  obtenerCiaNumRuc(): string {
    return localStorage.getItem('cianumruc');
  }

  /**
   *
   * @param code
   */
  establecerTienda(code): void {
    localStorage.setItem('store', code);
  }

  /**
   *
   * @returns
   */
  obtenerTienda(): string {
    return localStorage.getItem('store');
  }

  /**
   *
   * @param ruc
   */
  establecerPeriodoActividad(ruc): void {
    localStorage.setItem('period_activity', ruc);
  }

  /**
   *
   * @returns
   */
  obtenerPeriodoActividad(): string {
    return localStorage.getItem('period_activity');
  }

  /**
   *
   * @returns
   */
  getUserPermisions(): Observable<any> {
    return this.http.get<any>(
      `${this.obtenerUrl()}/api/configurations/listarpermisos`
    );
  }

  /**
   *
   * @returns
   */
  obtenerNombreUsuario(): string {

    return JSON.parse(localStorage.getItem('user')).usuariosistema;

  }

  /**
   * Obtener token
   * @returns token String
   */
  obtenerToken(): string {
    return localStorage.getItem('token');
  }

  /**
  * Obtener token
  * @returns token String
  */
  obtenerTokenCliente(): string {
    return localStorage.getItem('token-client');
  }

  /**
   *
   * @returns
   */
  esUsuarioPavso(): boolean {
    const indicadorTipoUsuario = JSON.parse(localStorage.getItem('user')).usuariopav;
    const esUsuarioPavso = indicadorTipoUsuario == 1 ? true : false;
    return esUsuarioPavso;
  }

  /**
   *
   *
   */
  establecerMaestrosLS(maestros: MaestrosLS): void {
    localStorage.setItem('pav-maestros-ls', JSON.stringify(maestros));
  }

  /**
   *
   * @returns
   */
  obtenerMaestrosLS(): MaestrosLS {
    return JSON.parse(localStorage.getItem('pav-maestros-ls'));
  }
}

interface IMenu {
  codeMod: string,
  showSubmenu: boolean
}
