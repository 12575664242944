import { Component, OnDestroy, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { DialogService } from "@shared/services/dialog.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { CaracteristicaAdicional, Conversion, ProductoCompuesto, Ubicacion } from "src/app/models/ventas/producto-compuesto";

@Component({
  selector: 'producto-compuesto-form',
  templateUrl: './producto-compuesto-form.component.html',
  styleUrls: ['./producto-compuesto-form.component.scss'],
})
export class ProductoCompuestoFormComponent implements OnDestroy{

  uid: string;

  displayedColumnsConversiones: string[] = ['acciones', 'unidad', 'descripcion', 'cantidad', 'codigo_almacen'];
  dataSourceConversiones: MatTableDataSource<any>;

  @ViewChild('matPagConversiones') paginatorConversiones: MatPaginator;
  @ViewChild(MatSort) sortConversiones: MatSort;

  displayedColumnsCaracteristicas: string[] = ['acciones', 'sec', 'orden', 'descripcion'];
  dataSourceCaracteristicas: MatTableDataSource<any>;

  @ViewChild('matPagCaracteristicas') paginatorCaracteristicas: MatPaginator;
  @ViewChild(MatSort) sortCaracteristicas: MatSort;

  displayedColumnsUbicaciones: string[] = ['acciones', 'almacen', 'ubicacion'];
  dataSourceUbicaciones: MatTableDataSource<any>;

  @ViewChild('matPagUbicaciones') paginatorUbicaciones: MatPaginator;
  @ViewChild(MatSort) sortUbicaciones: MatSort;

  tiposInventario: any[] = [];
  lineas: any[] = [];
  familias: any[] = [];
  subfamilias: any[] = [];
  marcas: any[] = [];
  modelos: any[] = [];
  tecnologias: any[] = [];
  unidades: any[] = [];
  medidas: any[] = [];
  productos: any[] = [];

  producto: ProductoCompuesto;

  constructor(
    private _dialogService: DialogService
  ) {
    this.dataSourceCaracteristicas = fillTable([], this.paginatorCaracteristicas, this.sortCaracteristicas);
    this.dataSourceConversiones = fillTable([], this.paginatorConversiones, this.sortConversiones);
    this.dataSourceUbicaciones = fillTable([], this.paginatorUbicaciones, this.sortUbicaciones);
    this.producto = new ProductoCompuesto();
  }

  loadData(): void {}

  agregarCaracteristica(): void {
    const caracteristica = new CaracteristicaAdicional();

    this.producto.caracteristicasAdicionales.push(caracteristica);

    this.dataSourceCaracteristicas = fillTable(this.producto.caracteristicasAdicionales, this.paginatorCaracteristicas, this.sortCaracteristicas);
  }

  quitarCaracteristica(row, i): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const caracteristicas = this.producto.caracteristicasAdicionales.filter((item, index) => index != i);

        this.producto.caracteristicasAdicionales = caracteristicas;
        this.dataSourceCaracteristicas = fillTable(this.producto.caracteristicasAdicionales, this.paginatorCaracteristicas, this.sortCaracteristicas);
      }
    })
  }

  agregarConversion(): void {
    const conversion = new Conversion();

    this.producto.conversiones.push(conversion);

    this.dataSourceConversiones = fillTable(this.producto.conversiones, this.paginatorConversiones, this.sortConversiones);
  }

  quitarConversion(row, i): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const conversiones = this.producto.conversiones.filter((item, index) => index != i);

        this.producto.conversiones = conversiones;
        this.dataSourceConversiones = fillTable(this.producto.conversiones, this.paginatorConversiones, this.sortConversiones);
      }
    })
  }

  agregarUbicacion(): void {
    const ubicacion = new Ubicacion();

    this.producto.ubicaciones.push(ubicacion);

    this.dataSourceUbicaciones = fillTable(this.producto.ubicaciones, this.paginatorUbicaciones, this.sortUbicaciones);
  }

  quitarUbicacion(row, i): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const ubicaciones = this.producto.ubicaciones.filter((item, index) => index != i);

        this.producto.ubicaciones = ubicaciones;
        this.dataSourceUbicaciones = fillTable(this.producto.ubicaciones, this.paginatorUbicaciones, this.sortUbicaciones);
      }
    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0'? this.registrarProducto(f): this.editarProducto(f);
  }

  registrarProducto(f: NgForm): void {}

  editarProducto(f: NgForm): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([]);
  }

}
