import { Component, OnDestroy, OnInit } from "@angular/core";
import { THEADER_CRM_TIPO_ATENCION } from "@data/constants/table/theader.crm";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { IHeaderKey } from "@shared/components/table/table-content/table-content.component";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { CrmService } from "src/app/services";
import { TipoAtencionService } from "src/app/services/api/crm/maestros/tipo-atencion.service";

@Component({
  selector: 'tipo-atencion-list',
  templateUrl: 'tipo-atencion-list.component.html',
  styleUrls: ['tipo-atencion-list.component.scss']
})
export class TipoAtencionListComponent implements OnInit, OnDestroy {

  tiposAtencion$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_TIPO_ATENCION;

  tiposAtencion: Array<any> = [];

  constructor(
    private _crmService: CrmService,
    private _tipoAtencionService: TipoAtencionService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerTiposAtencion()
    })
  }

  obtenerTiposAtencion(): void {
    this.tiposAtencion$ = this._tipoAtencionService.obtenerTiposAtencion().subscribe(
      tiposAtencion => this.tiposAtencion = tiposAtencion,
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.tiposAtencion$,
    ])
  }
}
