<app-pavso-title-section [title]="'Informes de nómina'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>


  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtro</div>
  </div>

  <div class="pav-filtro-container">
    <div class="row">
      <div class="col s12 m12 l4" *ngIf="mostrarFiltroAuxiliar == 1">
        <select-search
          [label]="'Colaborador'"
          [placeholder]="'Ej. '"
          [name]="'codcli'"
          [value]="'codcli'"
          [description]="'nomcli'"
          [data]="trabajadores"
          (cambioSelect)="filtro.colaboradores = $event"
          [multiple]="true"
          [model]="filtro.colaboradores">
        </select-search>
      </div>
      <div class="col s12 m12 l4" *ngIf="mostrarFiltroCentro == 1">
        <select-search
          [label]="'Centro de costo'"
          [placeholder]="'Ej. '"
          [name]="'ccs_codccs'"
          [value]="'ccs_codccs'"
          [description]="'ccs_descri'"
          [data]="centros"
          (cambioSelect)="filtro.centros = $event"
          [multiple]="true"
          [model]="filtro.centros">
        </select-search>
      </div>
      <div class="col s12 m12 l4" *ngIf="mostrarFiltroConcepto == 1">
        <select-search
          [label]="'Concepto'"
          [placeholder]="'Ej. '"
          [name]="'codigo'"
          [value]="'codigo'"
          [description]="'nco_descri'"
          [data]="conceptos"
          (cambioSelect)="filtro.conceptos = $event"
          [multiple]="true"
          [model]="filtro.conceptos">
        </select-search>
      </div>
    </div>
  </div>
  <br>
  <div align="center">
    <button class="pav-button-action" mat-flat-button color="primary" (click)="verReporte()" [class.spinner]="loading" [disabled]="loading">{{LABELS_NAME.BTN_SHOW_REPORT}}</button>
  </div>

</div>
