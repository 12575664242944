import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "src/app/material.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from "@shared/shared.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { AfpFormComponent } from "./afp-form/afp-form.component";
import { AfpListComponent } from "./afp-list/afp-list.component";
import { AFPDialog } from "./afp-dialog/afp-dialog.component";
import { AFPSelect } from "./afp-select/afp-select.component";

@NgModule({
  declarations: [AfpFormComponent, AfpListComponent, AFPDialog, AFPSelect],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  exports: [
    AfpFormComponent, AfpListComponent, AFPDialog, AFPSelect
  ]
})
export class AFPModule {}
