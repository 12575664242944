import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { AddProviderComponent } from '@shared/components/dialogs/add-provider/add-provider.component';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { CuentaPagarDialogComponent } from '@shared/components/dialogs/cuenta-por-pagar/cuenta-pagar-dialog/cuenta-pagar-dialog.component';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { retornaDiferenciaDias } from '@utils/date/date';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ProveedorDialog } from 'src/app/admin/apps/compras/pages/maestros/proveedor';
import { RegistroCompraBottomSheet } from 'src/app/admin/apps/compras/pages/operaciones/registro-compra';
import { DocumentToUpload } from 'src/app/admin/apps/compras/pages/operaciones/registro-compra/registro-compra-form/registro-compra-form.component';
import { Driver, RegistroCompra } from 'src/app/models';
import { DetailSaleRecord, RegistroVentas } from 'src/app/models/contabilidad/sale_record';
import { PermisoForm } from 'src/app/models/permiso-form';
import { AlmacenService, AuthenticationService, ComprasService, ConfigurationService, ContabilidadService, VentasService } from 'src/app/services';
import { CondicionPagoService } from 'src/app/services/api/compras/maestros/condicion-pago.service';
import { ProveedorService } from 'src/app/services/api/compras/maestros/proveedor.service';
import { TipoGastoService } from 'src/app/services/api/contabilidad/maestros/tipo-operacion.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { roundToDecimals } from 'src/app/utils/formats/round.format';
import { orderKeyStringJSON } from 'src/app/utils/sorts/sort';
import { validarPeriodo } from 'src/app/utils/validations/period';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-registro-ventas-form',
  templateUrl: './registro-ventas-form.component.html',
  styleUrls: ['./registro-ventas-form.component.css'],
})
export class RegistroVentasFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  displayedColumns: string[] = ['ldi_corldi', 'ccn_codccn', 'cli_codcli', 'ccs_codccs', 'tdo_codtdo', 'ldi_docref', 'tmo_codtmo', 'ldi_tipcam', 'ldi_inddha', 'ldi_impnac', 'ldi_impmex', 'ldi_gloldi', 'cco_codcco'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns3: string[] = ['name', 'mn', 'me'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;

  displayedColumns2: string[] = ['acciones', 'ord_compra', 'fac_importacion', 'num_documento', 'monto'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  monedas: any[] = [];
  subdiarios: any[] = [];
  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  minDate: Date;

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  registroVentas: RegistroVentas;

  isNew: boolean = true;

  valCompra: number;

  selected: any;

  selectRow(row: any) {
    this.selected = row;
  }

  @ViewChild('iAno') anoElement: ElementRef;
  @ViewChild('iMes') mesElement: ElementRef;
  @ViewChild('iSubdiario') subdiarioElement: MatSelect;
  @ViewChild('iFecha') fechaElement: ElementRef;
  @ViewChild('iProveedor') proveedorElement: MatSelect;
  @ViewChild('iEmision') emisionElement: ElementRef;
  @ViewChild('iTc') tcElement: ElementRef;
  @ViewChild('iMoneda') monedaElement: MatSelect;
  @ViewChild('iIgv') igvElement: ElementRef;
  @ViewChild('iDerCredito') derCreditoElement: MatSelect;
  @ViewChild('iTipoOperacion') tipoOperacionElement: MatSelect;
  @ViewChild('iClasificacion') clasificacionElement: MatSelect;
  @ViewChild('iTipoDoc') tipoDocElement: MatSelect;
  @ViewChild('iNroDoc') nroDocElement: ElementRef;
  @ViewChild('iAnoDua') anoDuaElement: ElementRef;
  @ViewChild('iSerie') serieElement: ElementRef;
  @ViewChild('iCondicion') condicionElement: MatSelect;
  @ViewChild('iVcto') vctoElement: ElementRef;
  @ViewChild('iCuenta') cuentaElement: MatSelect;
  @ViewChild('iOrdenCompra') ordenCompraElement: MatSelect;
  @ViewChild('iTipoRenta') tipoRentaElement: MatSelect;
  @ViewChild('iGlosa') glosaElement: ElementRef;
  @ViewChild('iTipoDocRef') tipoDocRefElement: MatSelect;
  @ViewChild('iDocRef') docRefElement: ElementRef;
  @ViewChild('iFecRef') fecRefElement: ElementRef;

  // Importes
  @ViewChild('iAfectoCD') afectoCDRefElement: ElementRef;
  @ViewChild('iAfectoSD') afectoSDRefElement: ElementRef;
  @ViewChild('iRenta') rentaRefElement: ElementRef;
  @ViewChild('iValRenta') valRentaRefElement: ElementRef;
  @ViewChild('iAjusteIgv') ajusteIgvRefElement: ElementRef;
  @ViewChild('iImporteSise') importeSiseRefElement: ElementRef;
  @ViewChild('iImporteFise') importeFiseRefElement: ElementRef;
  @ViewChild('iInafecto') inafectoRefElement: ElementRef;
  @ViewChild('iAfecto') afectoRefElement: ElementRef;
  @ViewChild('iValCompra') valCompraRefElement: ElementRef;
  @ViewChild('iImpIgv') impIgvRefElement: ElementRef;
  @ViewChild('iTotal') totalRefElement: ElementRef;
  @ViewChild('iTipoRetencion') tipoRetencionElement: MatSelect;
  @ViewChild('iTipoImpuestoRet') tipoImpuestoRetElement: MatSelect;
  @ViewChild('iTasa') tasaRefElement: ElementRef;
  @ViewChild('iImporte') importeRefElement: ElementRef;
  @ViewChild('iImpPercepcion') impPercepcionRefElement: ElementRef;
  @ViewChild('iDeposito') depositoRefElement: ElementRef;
  @ViewChild('iFecha') fechaRefElement: ElementRef;
  @ViewChild('iFob') fobRefElement: ElementRef;
  @ViewChild('iFlete') fleteRefElement: ElementRef;
  @ViewChild('iSeguro') seguroRefElement: ElementRef;
  @ViewChild('iCif') cifRefElement: ElementRef;
  @ViewChild('iAdvalorem') advaloremRefElement: ElementRef;
  @ViewChild('btnAdd') buttonAddRefElement: MatButton;
  @ViewChild('btnGeneraAsiento') buttonGeneraAsientoRefElement: MatButton;

  cuentasTotales: any[] = [];
  cuentas: any[] = [];
  proveedores: any[] = [];
  auxiliares: any[] = [];
  tiposOperacion: any[] = [];
  tiposRenta: any[] = [];
  condiciones: any[] = [];
  tiposDoc: any[] = [];

  signosctacte: any[] = [];

  @ViewChildren("cuentaTotalSelect") selectCuentas: QueryList<MatSelect>;
  @ViewChildren("descripcionInput") inputDescripcion: QueryList<ElementRef>;
  @ViewChildren("costoSelect") selectCosto: QueryList<MatSelect>;

  montosDebeHaber: any;

  permision: any = { ind_Nuevo: '0' };
  btnName: string = "Registrar";

  cuentasContable: any[] = [];
  tiposImpRetencion: any[] = [];
  tempTiposImpRetencion: any[] = [];
  centros: any[] = [];

  subdiarios$: Subscription;
  tipoOperacion$: Subscription;
  cuentas$: Subscription;
  tiposBienServicio$: Subscription;
  tiposRenta$: Subscription;
  tiposImpuesto$: Subscription;
  tiposDocumento$: Subscription;
  proveedor$: Subscription;
  moneda$: Subscription;
  ordenesCompra$: Subscription;
  condiciones$: Subscription;
  voucher$: Subscription;
  centro$: Subscription;
  auxiliar$: Subscription;
  tipoRetencion$: Subscription;
  tipoImpRetencion$: Subscription;
  loading$: Subscription;

  nameXml: string;

  period$: Subscription;

  currentPage$: Subscription;
  permiso: PermisoForm = new PermisoForm();
  program: string;
  uid: string;

  loaderAnular: boolean;

  documentToUpload: DocumentToUpload;

  maxDate: Date;
  esPercepcion: boolean;
  disableImportaciones: boolean;

  constructor(
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private _clienteService: ClienteService,
    private _dialogService: DialogService,
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _tipoGastoService: TipoGastoService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private _comprasService: ComprasService,
    private _condicionPagoService: CondicionPagoService,
    private _ventasService: VentasService,
    private _bottomSheet: MatBottomSheet,
    private _proveedorService: ProveedorService,
    private _router: Router,
    private store: Store<PavsoState>,
    public dialog: MatDialog
  ) {
    this.registroVentas = new RegistroVentas();

    this.montosDebeHaber = [
      {
        name: "DEBE",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "HABER",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "DIFERENCIA",
        mn: 0.00,
        me: 0.00
      }
    ];

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource3 = fillTable(this.montosDebeHaber, this.paginator3, this.sort3);

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.registroVentas.ano_codano = year;
      this.month = month;
      this.registroVentas.mes_codmes = month;
    })

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.dataSource3 = new MatTableDataSource([
      {
        name: "Debe",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Haber",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Diferencia",
        mn: 0.00,
        me: 0.00
      }
    ]);
  }

  setup(): void {
    this._activatedRoute.params.subscribe(
      params => {
        if (!(params["id"] == "0")) {
          this.isNew = false;
          this.btnName = NAMES_CONSTANTS.BTN_UPDATE;
          this._comprasService.mostrarRegistroDeCompra(params["id"]).subscribe(
            registroCompra => {
              // ===== VALORES POR DEFECTO =======
              this.registroVentas.cco_coduse = this.usuario;
              this.registroVentas.cco_indsta = "1";
              this.registroVentas.iri_codiri = "00";

              this.registroVentas.ano_codano = this.year;
              this.registroVentas.mes_codmes = this.month;

              this.registroVentas.dpc_afcder = 0;
              this.registroVentas.dpc_afsder = 0;
              this.registroVentas.dpc_difigv = 0;
              this.registroVentas.dpc_impsis = 0;
              this.registroVentas.dpc_impfis = 0;
              this.registroVentas.cco_impina = 0;
              this.registroVentas.cco_impafe = 0;
              this.registroVentas.cco_impigv = 0;
              this.registroVentas.cco_impdoc = 0;

              this.registroVentas.dpc_tasdet = 0;
              this.registroVentas.dpc_impdet = 0;
              this.registroVentas.cco_impper = 0;
              this.registroVentas.dpc_depdet = 0;
              this.registroVentas.dpc_depdet = 0;

              this.registroVentas.dpc_impfob = 0;
              this.registroVentas.dpc_impfle = 0;
              this.registroVentas.dpc_impseg = 0;
              this.registroVentas.dpc_impcif = 0;
              this.registroVentas.dpc_impadv = 0;

              this.registroVentas.age_codage = null;
              this.registroVentas.cco_aboret = 0
              this.registroVentas.cco_abotra = 0
              this.registroVentas.cco_cbmoti = null
              this.registroVentas.cco_codant = null
              this.registroVentas.cco_code = null
              this.registroVentas.cco_codpag = "P"
              this.registroVentas.cco_condic = null
              this.registroVentas.cco_impabo = 0
              this.registroVentas.cco_impcco = this.registroVentas.cco_impdoc * (-1);
              this.registroVentas.cco_impcor = 0
              this.registroVentas.cco_impret = 0
              this.registroVentas.cco_indtca = ""
              this.registroVentas.cco_movil = ""
              this.registroVentas.cco_numcuo = ""
              this.registroVentas.cco_permna = null
              this.registroVentas.cco_retgas = null
              this.registroVentas.cco_salcco = null
              this.registroVentas.cco_sallba = 0
              this.registroVentas.cco_salres = 0
              this.registroVentas.cia_codcia = this._configurationService.obtenerCompaniaCliente()
              this.registroVentas.cli_coddoc = null
              this.registroVentas.dpc_afepol = 0
              this.registroVentas.dpc_anotri = null
              this.registroVentas.dpc_coduse = this._authService.getUsuarioSistema();
              this.registroVentas.dpc_fecrec = null
              this.registroVentas.dpc_fecupd = new Date();
              this.registroVentas.dpc_impdet = 0
              this.registroVentas.dpc_igvisc = 0
              this.registroVentas.dpc_impdif = 0
              this.registroVentas.dpc_impies = 0
              this.registroVentas.dpc_impisc = 0
              this.registroVentas.dpc_impore = 0
              this.registroVentas.dpc_impper = 0
              this.registroVentas.dpc_inddet = null
              this.registroVentas.dpc_inddet_c = null
              this.registroVentas.dpc_inddif = "0"
              this.registroVentas.dpc_indntc = 0
              this.registroVentas.dpc_indsta = "1"
              this.registroVentas.dpc_mestri = null
              this.registroVentas.dpc_numdua = null
              this.registroVentas.dpc_numpol = null
              this.registroVentas.dpc_otrtri = 0
              this.registroVentas.dpc_parara = null
              this.registroVentas.dpc_perini = null
              this.registroVentas.dpc_reipap = null
              this.registroVentas.dpc_tasies = null
              this.registroVentas.dpc_tasisc = null
              this.registroVentas.dpc_tasore = null
              this.registroVentas.dpc_valare = null
              this.registroVentas.ipi_codipi = null
              this.registroVentas.ldc_corasi = null
              this.registroVentas.ldc_corldc = "00001"
              this.registroVentas.mmo_codmmo = null
              this.registroVentas.oim_numoim = null
              this.registroVentas.sco_codasi = null
              this.registroVentas.suc_codsuc = "01"
              this.registroVentas.dpc_tasren = 0;
              this.registroVentas.dpc_impren = 0;
              this.registroVentas.tdo_docref = "";
              this.registroVentas.cco_numref = "";

              this.registroVentas.cia_codcia = registroCompra.cia_codcia;
              this.registroVentas.cco_codcco = registroCompra.cco_codcco;
              this.registroVentas.cli_codcli = registroCompra.cli_codcli;
              this.registroVentas.cli_coddoc = registroCompra.cli_coddoc;
              this.registroVentas.tdo_codtdo = registroCompra.tdo_codtdo;
              this.registroVentas.cco_numdoc = registroCompra.cco_numdoc;
              this.registroVentas.cco_glocco = registroCompra.cco_glocco;
              this.registroVentas.cco_fecemi = registroCompra.cco_fecemi;
              this.registroVentas.cco_fecven = registroCompra.cco_fecven;
              this.registroVentas.tmo_codtmo = registroCompra.tmo_codtmo;
              this.registroVentas.ccn_codccn = registroCompra.ccn_codccn;
              this.registroVentas.cco_impdoc = registroCompra.cco_impdoc;
              this.registroVentas.cco_impcco = registroCompra.cco_impcco;
              this.registroVentas.cco_salcco = registroCompra.cco_salcco;
              this.registroVentas.cco_sallba = registroCompra.cco_sallba;
              this.registroVentas.cco_salres = registroCompra.cco_salres;
              this.registroVentas.cco_impabo = registroCompra.cco_impabo;
              this.registroVentas.cco_impigv = registroCompra.cco_impigv;
              this.registroVentas.cco_tasigv = registroCompra.cco_tasigv;
              this.registroVentas.cco_impcor = registroCompra.cco_impcor;
              this.registroVentas.cco_impina = registroCompra.cco_impina;
              this.registroVentas.cco_impafe = registroCompra.cco_impafe;
              this.registroVentas.cco_indtca = registroCompra.cco_indtca;
              this.registroVentas.cco_tipcam = registroCompra.cco_tipcam;
              this.registroVentas.cco_codpag = registroCompra.cco_codpag;
              this.registroVentas.mmo_codmmo = registroCompra.mmo_codmmo;
              this.registroVentas.cpa_codcpa = registroCompra.cpa_codcpa;
              this.registroVentas.tdo_docref = registroCompra.tdo_docref;
              this.registroVentas.cco_numref = registroCompra.cco_numref;
              this.registroVentas.ano_codano = registroCompra.ano_codano;
              this.registroVentas.mes_codmes = registroCompra.mes_codmes;
              this.registroVentas.sco_codsco = registroCompra.sco_codsco;
              this.registroVentas.ldc_corldc = registroCompra.ldc_corldc;
              this.registroVentas.sco_codasi = registroCompra.sco_codasi;
              this.registroVentas.ldc_corasi = registroCompra.ldc_corasi;
              this.registroVentas.cco_fecreg = registroCompra.cco_fecreg;
              this.registroVentas.age_codage = registroCompra.age_codage;
              this.registroVentas.cco_abotra = registroCompra.cco_abotra;
              this.registroVentas.cco_codant = registroCompra.cco_codant;
              this.registroVentas.cco_impret = registroCompra.cco_impret;
              this.registroVentas.cco_aboret = registroCompra.cco_aboret;
              this.registroVentas.cco_retgas = registroCompra.cco_retgas;
              this.registroVentas.iri_codiri = registroCompra.iri_codiri;
              this.registroVentas.suc_codsuc = registroCompra.suc_codsuc;
              this.registroVentas.tga_codtga = registroCompra.tga_codtga;
              this.registroVentas.cco_fecref = registroCompra.cco_fecref;
              this.registroVentas.ocm_numocm = registroCompra.ocm_numocm;
              this.registroVentas.cco_numcuo = registroCompra.cco_numcuo;
              this.registroVentas.ipi_codipi = registroCompra.ipi_codipi;
              this.registroVentas.cco_impper = registroCompra.cco_impper;
              this.registroVentas.cco_permna = registroCompra.cco_permna;
              this.registroVentas.cco_movil = registroCompra.cco_movil;
              this.registroVentas.cco_cbmoti = registroCompra.cco_cbmoti;
              this.registroVentas.cco_condic = registroCompra.cco_condic;
              this.registroVentas.cco_code = registroCompra.cco_code;
              this.registroVentas.dpc_indder = registroCompra.dpc_indder;
              this.registroVentas.dpc_fecrec = registroCompra.dpc_fecrec;
              this.registroVentas.dpc_indsta = registroCompra.dpc_indsta;
              this.registroVentas.dpc_fecupd = registroCompra.dpc_fecupd;
              this.registroVentas.dpc_coduse = registroCompra.dpc_coduse;
              this.registroVentas.dpc_tasren = registroCompra.dpc_tasren;
              this.registroVentas.dpc_tasies = registroCompra.dpc_tasies;
              this.registroVentas.dpc_tasore = registroCompra.dpc_tasore;
              this.registroVentas.dpc_impren = registroCompra.dpc_impren;
              this.registroVentas.dpc_impies = registroCompra.dpc_impies;
              this.registroVentas.dpc_impore = registroCompra.dpc_impore;
              this.registroVentas.dpc_difigv = registroCompra.dpc_difigv;
              this.registroVentas.dpc_afcder = registroCompra.dpc_afcder;
              this.registroVentas.dpc_afsder = registroCompra.dpc_afsder;
              this.registroVentas.dpc_impisc = registroCompra.dpc_impisc;
              this.registroVentas.dpc_igvisc = registroCompra.dpc_igvisc;
              this.registroVentas.dpc_tasisc = registroCompra.dpc_tasisc;
              this.registroVentas.dpc_otrtri = registroCompra.dpc_otrtri;
              this.registroVentas.dpc_valare = registroCompra.dpc_valare;
              this.registroVentas.dpc_inddif = registroCompra.dpc_inddif;
              this.registroVentas.dpc_perini = registroCompra.dpc_perini;
              this.registroVentas.dpc_impdif = registroCompra.dpc_impdif;
              this.registroVentas.dpc_numpol = registroCompra.dpc_numpol;
              this.registroVentas.dpc_parara = registroCompra.dpc_parara;
              this.registroVentas.dpc_impfob = registroCompra.dpc_impfob;
              this.registroVentas.dpc_impfle = registroCompra.dpc_impfle;
              this.registroVentas.dpc_impseg = registroCompra.dpc_impseg;
              this.registroVentas.dpc_impcif = registroCompra.dpc_impcif;
              this.registroVentas.dpc_impadv = registroCompra.dpc_impadv;
              this.registroVentas.dpc_reipap = registroCompra.dpc_reipap;
              this.registroVentas.dpc_afepol = registroCompra.dpc_afepol;
              this.registroVentas.dpc_inddet = registroCompra.dpc_inddet;
              this.registroVentas.dpc_inddet_c = registroCompra.dpc_inddet_c;
              this.registroVentas.tim_codtim = registroCompra.tim_codtim;
              this.registroVentas.dpc_tasdet = registroCompra.dpc_tasdet;
              this.registroVentas.dpc_depdet = registroCompra.dpc_depdet;
              this.registroVentas.dpc_impdet = registroCompra.dpc_impdet;
              this.registroVentas.dpc_fecdet = registroCompra.dpc_fecdet;
              this.registroVentas.dpc_indntc = registroCompra.dpc_indntc;
              this.registroVentas.dpc_anotri = registroCompra.dpc_anotri;
              this.registroVentas.dpc_mestri = registroCompra.dpc_mestri;
              this.registroVentas.dpc_impper = registroCompra.dpc_impper;
              this.registroVentas.dpc_numdua = registroCompra.dpc_numdua;
              this.registroVentas.dpc_anodua = registroCompra.dpc_anodua;
              this.registroVentas.oim_numoim = registroCompra.oim_numoim;
              this.registroVentas.sad_codsad = registroCompra.sad_codsad;
              this.registroVentas.dpc_impsis = registroCompra.dpc_impsis;
              this.registroVentas.dpc_impfis = registroCompra.dpc_impfis;
              this.registroVentas.t30_codt30 = registroCompra.t30_codt30;
              this.registroVentas.t31_codt31 = registroCompra.t31_codt31;
              this.registroVentas.detalle = (registroCompra.detalle) ? registroCompra.detalle : [];
              this.registroVentas.ordenes = (registroCompra.ordenes) ? registroCompra.ordenes : [];

              this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);

              this.calculaTotal();

              this.establecerDebeHaber();

            },
            error => this._snackBarService.showError(error.error.msg, "OK")
          )

        } else {
          this.isNew = true;
          this.btnName = NAMES_CONSTANTS.BTN_STORE;
          this.setDefaultFieldsSaleRecord();
        }
      }
    )
  }

  permisos$: Subscription;
  signosctacte$: Subscription;
  tiposOperacion$: Subscription;

  loadData(): void {
    this.loaderData = true;

    this.permisos$ = this._configurationService.getUserPermisions().subscribe(
      permisos => {
        this.permiso = permisos.find(item => item.interfaz == this.program);

        if (!this.permiso) {
          this.permiso = new PermisoForm();
        }

        this.signosctacte$ = this._contabilidadService.listarSignoCtaCte().subscribe(
          signos => {
            this.signosctacte = signos;

            this.condiciones$ = this._condicionPagoService.obtenerCondicionesPago().subscribe(
              condiciones => {
                this.condiciones = condiciones;

                this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
                  subdiarios => {
                    this.subdiarios = subdiarios;

                    this.tiposOperacion$ = this._tipoGastoService.obtenerTiposGasto().subscribe(
                      tiposOperacion => {
                        this.tiposOperacion = tiposOperacion;

                        this.cuentas$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
                          cuentas => {
                            this.cuentasTotales = cuentas;
                            this.cuentasContable = cuentas.filter(cuenta => cuenta.ind_ctacte == "P");
                            this.cuentas = cuentas;

                            this.tiposDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
                              tiposDocumento => {
                                this.tiposDoc = tiposDocumento.filter(item => item.indcom == 1);
                                this.tiposDoc = tiposDocumento.sort(orderKeyStringJSON("TDO_CODTDO"));

                                this.centro$ = this._contabilidadService.listarCentroCostos().subscribe(
                                  centrosCosto => {
                                    this.centros = centrosCosto;

                                    this.proveedor$ = this._clienteService.obtenerClientes().subscribe(
                                      clientes => {
                                        this.auxiliares = clientes.filter(cliente => cliente.cli_indtra == 1);
                                        this.proveedores = clientes.filter(cliente => cliente.cli_indpro == 1);

                                        this.tiposImpuesto$ = this._comprasService.listarTiposImpuestoRetencion().subscribe(
                                          tiposImpuesto => {
                                            this.tiposImpRetencion = tiposImpuesto;
                                            this.tempTiposImpRetencion = tiposImpuesto;
                                            this.loaderData = false;
                                            this.setup();
                                          },
                                          error => {
                                            this._snackBarService.showError('Error al obtener tipos impuesto retención', 'Ok')
                                            this.loaderData = false;
                                          }
                                        )

                                      },
                                      error => {
                                        this._snackBarService.showError('Error al obtener clientes', 'Ok')
                                        this.loaderData = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener centros de costo', 'Ok')
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener tipos de documento', 'Ok')
                                this.loaderData = false;
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener plan de cuentas', 'Ok')
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener tipos de operacion', 'Ok')
                        this.loaderData = false;
                      }
                    )

                  },
                  error => {
                    this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
                    this.loaderData = false;
                  }
                )

              },
              error => {
                this._snackBarService.showError('Error al obtener condiciones de pago', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener permisos', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener permisos', 'Ok');
        this.loaderData = false;
      }
    )


  }

  sendForm$: Subscription;

  anularRegistroCompra(): void {
    this.loaderAnular = true;
    this.registroVentas.cco_indsta = '0';
    // this.sendForm$ = this._ventasService.editar(this.registroVentas).subscribe(
    //   response => {
    //     this.loaderAnular = false;
    //     this._dialogService.openDialog(SuccessComponent, 'Registro de Compra Anulado Exitosamente', '350px', '', '');
    //   },
    //   error => {
    //     this._snackBarService.showError(error.message, 'Ok');
    //     this.loaderAnular = false;
    //   }
    // )
  }

  focusInput(evento): void {
    evento.target.select();
  }

  establecerAjusteIGV(): void {
    // this.registro.cco_impigv = this.registro.cco_impigv + this.registro.dpc_difigv;
    this.calculaTotal();
    // this.registro.cco_impigv  = roundToDecimals(this.registro.cco_impafe * (this.registro.cco_tasigv / 100), 2) + this.registro.dpc_difigv
  }

  setDefaultFieldsSaleRecord(): void {
    const now = new Date();

    this.registroVentas.ano_codano = this.year;
    this.registroVentas.mes_codmes = this.month;
    this.registroVentas.cco_tasigv = this.tiposImpRetencion[0].porc_det;

    if (this.year == now.getFullYear().toString() && this.month == (now.getMonth() + 1).toString()) {
      this.registroVentas.cco_fecreg = formatDateWithDash(new Date());
    } else {
      this.registroVentas.cco_fecreg = formatDateWithDash(new Date(parseInt(this.year), parseInt(this.month), 0));
    }

    this.registroVentas.cco_fecemi = moment(); //formatDateWithDash(new Date());
    this.registroVentas.dpc_fecdet = moment();
    this.registroVentas.cco_fecven = moment();

    this.registroVentas.cco_coduse = this.usuario;

    this.registroVentas.detalle = [];
    this.registroVentas.ordenes = [];
    this.registroVentas.tmo_codtmo = "SO";
    this.registroVentas.cco_indsta = "1";
    this.registroVentas.iri_codiri = "00";

    this.registroVentas.ano_codano = this.year;
    this.registroVentas.mes_codmes = this.month;

    this.registroVentas.dpc_afcder = 0;
    this.registroVentas.dpc_afsder = 0;
    this.registroVentas.dpc_difigv = 0;
    this.registroVentas.dpc_impsis = 0;
    this.registroVentas.dpc_impfis = 0;
    this.registroVentas.cco_impina = 0;
    this.registroVentas.cco_impafe = 0;
    this.registroVentas.cco_impigv = 0;
    this.registroVentas.cco_impdoc = 0;

    this.registroVentas.dpc_tasdet = 0;
    this.registroVentas.dpc_impdet = 0;
    this.registroVentas.cco_impper = 0;
    this.registroVentas.dpc_depdet = 0;
    this.registroVentas.dpc_depdet = 0;

    this.registroVentas.dpc_impfob = 0;
    this.registroVentas.dpc_impfle = 0;
    this.registroVentas.dpc_impseg = 0;
    this.registroVentas.dpc_impcif = 0;
    this.registroVentas.dpc_impadv = 0;



    this.registroVentas.age_codage = null;
    this.registroVentas.cco_aboret = 0
    this.registroVentas.cco_abotra = 0
    this.registroVentas.cco_cbmoti = null
    this.registroVentas.cco_codant = null
    this.registroVentas.cco_code = null
    this.registroVentas.cco_codpag = "P"
    this.registroVentas.cco_condic = null
    this.registroVentas.cco_impcco = this.registroVentas.cco_impdoc * (-1);
    this.registroVentas.cco_impabo = 0
    this.registroVentas .cco_impcor = 0
    this.registroVentas .cco_impret = 0
    this.registroVentas .cco_indtca = ""
    this.registroVentas .cco_movil = ""
    this.registroVentas .cco_numcuo = ""
    this.registroVentas .cco_permna = null
    this.registroVentas .cco_retgas = null
    this.registroVentas .cco_salcco = null
    this.registroVentas .cco_sallba = 0
    this.registroVentas .cco_salres = 0
    this.registroVentas .cia_codcia = this._configurationService.obtenerCompaniaCliente()
    this.registroVentas .cli_coddoc = null
    this.registroVentas .dpc_afepol = 0
    this.registroVentas .dpc_anotri = null
    this.registroVentas .dpc_coduse = this._authService.getUsuarioSistema();
    this.registroVentas .dpc_fecrec = null
    this.registroVentas .dpc_fecupd = new Date();
    this.registroVentas .dpc_impdet = 0
    this.registroVentas .dpc_igvisc = 0
    this.registroVentas .dpc_impdif = 0
    this.registroVentas .dpc_impies = 0
    this.registroVentas .dpc_impisc = 0
    this.registroVentas .dpc_impore = 0
    this.registroVentas .dpc_impper = 0
    this.registroVentas .dpc_inddet = null
    this.registroVentas.dpc_inddet_c = null
    this.registroVentas.dpc_inddif = "0"
    this.registroVentas.dpc_indntc = 0
    this.registroVentas.dpc_indsta = "1"
    this.registroVentas.dpc_mestri = null
    this.registroVentas.dpc_numdua = null
    this.registroVentas.dpc_numpol = null
    this.registroVentas.dpc_otrtri = 0
    this.registroVentas.dpc_parara = null
    this.registroVentas.dpc_perini = null
    this.registroVentas.dpc_reipap = null
    this.registroVentas.dpc_tasies = null
    this.registroVentas.dpc_tasisc = null
    this.registroVentas.dpc_tasore = null
    this.registroVentas.dpc_valare = null
    this.registroVentas.ipi_codipi = null
    this.registroVentas.ldc_corasi = null
    this.registroVentas.ldc_corldc = "00001"
    this.registroVentas.mmo_codmmo = null
    this.registroVentas.oim_numoim = null
    this.registroVentas.sco_codasi = null
    this.registroVentas.suc_codsuc = "01"
    this.registroVentas.dpc_tasren = 0;
    this.registroVentas.dpc_impren = 0;
    this.registroVentas.tdo_docref = "";
    this.registroVentas.cco_numref = "";
    this.obtenerTasaCambio();

    setTimeout(() => {
      this.subdiarioElement.focus();
      this.subdiarioElement.open();
    }, 1);
  }

  tipoCambio$: Subscription;

  nuevoRegistro(): void {
    this.registroVentas = new RegistroVentas();
    this.uid = '0';
    this.initialize();

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.registroVentas.cco_fecemi)).subscribe(
      tipoCambio => {
        this.registroVentas.cco_tipcam = tipoCambio[0].tipcam;
      },
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'OK');
      }
    )
  }

  initialize(): void {
    this.registroVentas.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.registroVentas.cco_coduse = this._configurationService.obtenerIdUsuario();
    this.registroVentas.dpc_coduse = this._configurationService.obtenerIdUsuario();

    this.disableImportaciones = true;

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;
      this.registroVentas.ano_codano = year;
      this.registroVentas.mes_codmes = month;

      this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
      this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

      this.registroVentas.cco_fecreg = this.maxDate;
      this.registroVentas.cco_fecven = this.maxDate;
      this.registroVentas.cco_fecemi = this.maxDate;
    });

    this.esPercepcion = false;

    this.montosDebeHaber = [
      {
        name: "Debe",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Haber",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Diferencia",
        mn: 0.00,
        me: 0.00
      }
    ];

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);

    this.usuario = this._authService.getUsuarioSistema();

    this.documentToUpload = new DocumentToUpload();
  }

  generarFechaUTC(fecha): Date {
    const date = new Date(fecha)

    const day = date.getUTCDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return new Date(year, month, day);
  }

  listaFacturasDeProveedor$: Subscription;

  verMasOpciones(): void {

    if (!this.registroVentas.sco_codsco) {
      this._snackBarService.showError('Seleccione un subdiario', 'Ok');
      return;
    }

    this.documentToUpload.isNew = this.uid == '0';

    this._bottomSheet.open(RegistroCompraBottomSheet, { data: this.documentToUpload }).afterDismissed().subscribe((result: DocumentToUpload) => {

      if (result == undefined) return;

      if (result.invoiceFile) {
        this.resetAutocompleteDocument();

        this.documentToUpload.invoiceFile = result.invoiceFile;
        this.documentToUpload.invoiceJSON = result.invoiceJSON;

        console.log('generarFecha', result.invoiceJSON.InvoiceDate.value)
        console.log('generarFecha /', this.generarFechaFormato(result.invoiceJSON.InvoiceDate.content))
        console.log('generarFechaUTC', this.generarFechaUTC(result.invoiceJSON.InvoiceDate.value))
        this.registroVentas.cli_codcli = result.invoiceJSON.VendorTaxId ? result.invoiceJSON.VendorTaxId.value : null;
        this.registroVentas.cli_coddoc = result.invoiceJSON.VendorTaxId ? result.invoiceJSON.VendorTaxId.value : null;
        this.registroVentas.cco_fecemi = result.invoiceJSON.InvoiceDate ? this.generarFechaFormato(result.invoiceJSON.InvoiceDate.content) : null;
        this.registroVentas.cco_fecven = result.invoiceJSON.ServiceEndDate ? this.generarFechaFormato(result.invoiceJSON.ServiceEndDate.content) : null;
        this.registroVentas.sad_codsad = result.invoiceJSON.InvoiceId ? result.invoiceJSON.InvoiceId.value.replace(/\s/g, '').split('-')[0] : null;
        console.log('digitos completados', this.completarDigitosNumDoc(result.invoiceJSON.InvoiceId.value.replace(/\s/g, '').split('-')[1]))
        this.registroVentas.cco_numdoc = result.invoiceJSON.InvoiceId ? this.registroVentas.sad_codsad + this.completarDigitosNumDoc(result.invoiceJSON.InvoiceId.value.replace(/\s/g, '').split('-')[1]) : null;
        this.registroVentas.cco_impdoc = result.invoiceJSON.InvoiceTotal ? result.invoiceJSON.InvoiceTotal.value.amount : 0;
        this.registroVentas.cco_impigv = result.invoiceJSON.TotalTax ? result.invoiceJSON.TotalTax.value.amount : 0;

        if (result.invoiceJSON.SubTotal) {

          this.registroVentas.cco_impafe = result.invoiceJSON.SubTotal.value.amount;

        } else {

          this.registroVentas.cco_impafe = this.registroVentas.cco_impdoc - this.registroVentas.cco_impigv;

        }

        this.registroVentas.valvta = this.registroVentas.cco_impafe + this.registroVentas.cco_impina;

        if (!(this.registroVentas.cco_impdoc - this.registroVentas.cco_impigv == this.registroVentas.valvta)) {
          this.registroVentas.cco_impina = this.registroVentas.valvta - this.registroVentas.cco_impafe;
        }

        this.registroVentas.cco_fecven = result.invoiceJSON.DueDate ? this.generarFechaUTC(result.invoiceJSON.DueDate.value) : this.registroVentas.cco_fecemi;
        this.registroVentas.tdo_codtdo = 'FAC';
        this.registroVentas.ocm_numocm = '00000000000';
        this.registroVentas.tmo_codtmo = result.invoiceJSON.InvoiceTotal ? result.invoiceJSON.InvoiceTotal.value.currencyCode == 'USD' ? 'DO' : 'SO' : null;
        this.registroVentas.cco_glocco = result.invoiceJSON.Items ? result.invoiceJSON.Items.values[0].properties.Description ? result.invoiceJSON.Items.values[0].properties.Description.value : '' : '';

        this.registroVentas.cco_tasigv = this.registroVentas.cco_impigv > 0 ? Math.round((this.registroVentas.cco_impigv / this.registroVentas.cco_impafe) * 100) : 0;

        // validacion si la factura cargada en imagen tiene un inafecto


        const diasDiferencia = this.calcularDiferenciaEnDias(new Date(this.registroVentas.cco_fecemi), new Date(this.registroVentas.cco_fecven));

        const subdiario = this.subdiarios.find(item => item.sco_codsco == this.registroVentas.sco_codsco);

        this.registroVentas.ccn_codccn = this.registroVentas.tmo_codtmo == 'SO' ? subdiario.ccn_codmn : subdiario.ccn_codme;


        console.log('difeerencia', diasDiferencia)
        const condicionPago = this.condiciones.find(item => item.CPA_DIAPLA == diasDiferencia - 1);
        console.log('condicion', condicionPago)

        this.registroVentas.cpa_codcpa = condicionPago ? condicionPago.CPA_CODCPA : null;
        // this.registro.cpa_codcpa = '126';

        const proveedor = this.proveedores.find(item => item.cli_codcli == this.registroVentas.cli_codcli);

        this.listaFacturasDeProveedor$ = this._comprasService.listarFacturasProveedor(this.registroVentas.cli_codcli).subscribe(
          listaFacturasDeProveedor => {
            const encontrado = listaFacturasDeProveedor.find(item => item.cco_numdoc == this.registroVentas.cco_numdoc);

            if (encontrado) this._snackBarService.showError('Esta factura ya ha sido registrada por el proveedor', 'Ok')

            if (!proveedor && this.registroVentas.cli_codcli) {
              this._dialogService.openDialog(ConfirmationComponent, '¿El proveedor encontrado no existe, desea agregar al proveedor?', '', '', '').subscribe(
                result => {
                  if (result) {
                    const dialogRef = this.dialog.open(ProveedorDialog, {
                      data: {
                        id: '0',
                        idARegistrar: this.registroVentas.cli_codcli
                      }
                    })

                    dialogRef.afterClosed().subscribe(result => {
                      this._snackBarService.showSuccess('Actualizando lista de proveedores', 'Ok');

                      this.obtenerProveedores()
                    });
                  }
                }
              )
            } else {
              this.ultimoRegistroCompra$ = this._comprasService.obtenerUltimoRegistroCompra(this.registroVentas.sco_codsco, this.registroVentas.cli_codcli).subscribe(
                historial => {
                  this.registroVentas.tga_codtga = historial.length > 0 ? historial[0].tga_codtga : null;
                  // this.registro.cpa_codcpa = historial[0].cpa_codcpa;
                  // this.registro.tmo_codtmo = result.invoiceJSON.InvoiceTotal ? result.invoiceJSON.InvoiceTotal.value.currencyCode : historial[0].tmo_codtmo;
                  this.registroVentas.t30_codt30 = historial.length > 0 ? historial[0].T30_CODT30 : null;
                  // this.registro.cco_glocco = historial[0].cco_glocco;
                }
              )
            }
          },
          error => {
            if (!proveedor && this.registroVentas.cli_codcli) {
              this._dialogService.openDialog(ConfirmationComponent, '¿El proveedor encontrado no existe, desea agregar al proveedor?', '', '', '').subscribe(
                result => {
                  if (result) {
                    const dialogRef = this.dialog.open(ProveedorDialog, {
                      data: {
                        id: '0',
                        idARegistrar: this.registroVentas.cli_codcli
                      }
                    })

                    dialogRef.afterClosed().subscribe(result => {
                      this._snackBarService.showSuccess('Actualizando lista de proveedores', 'Ok');

                      this.obtenerProveedores()
                    });
                  }
                }
              )
            } else {
              this.ultimoRegistroCompra$ = this._comprasService.obtenerUltimoRegistroCompra(this.registroVentas.sco_codsco, this.registroVentas.cli_codcli).subscribe(
                historial => {
                  this.registroVentas.tga_codtga = historial[0].tga_codtga;
                  this.registroVentas.t30_codt30 = historial[0].T30_CODT30;
                }
              )
            }
          }
        )

      }

      const document = result.xmlJSON;

      if (document.INVOICE) {
        const INVOICE = document.INVOICE;
        this.resetAutocompleteDocument();
        this.documentToUpload.xmlFile = result.xmlFile;
        this.documentToUpload.xmlJSON = result.xmlJSON;
        this.documentToUpload.xmlStr = result.xmlStr;
        console.log('resultado de la carga del xml', result.xmlJSON)
        console.log('resultado de la carga del xml', JSON.stringify(result.xmlJSON))
        this.registroVentas.cli_codcli = INVOICE["CAC:ACCOUNTINGSUPPLIERPARTY"][0]["CBC:CUSTOMERASSIGNEDACCOUNTID"] ? INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'];
        this.registroVentas.sad_codsad = INVOICE["CBC:ID"][0].split('-')[0];
        this.registroVentas.cco_numdoc = INVOICE["CBC:ID"][0].split('-')[0] + INVOICE["CBC:ID"][0].split('-')[1];
        const invoiceTypeCode = INVOICE["CBC:INVOICETYPECODE"][0]["_"];
        console.log('invoiceTypeCode', invoiceTypeCode)
        console.log('this.tiposDocumento', this.tiposDocumento)
        this.registroVentas.tdo_codtdo = this.tiposDocumento.find(item => item.TDO_CODOFI == invoiceTypeCode).TDO_CODTDO;
        console.log('tipo documento ====', this.registroVentas.tdo_codtdo)

        this.registroVentas.cco_tasigv = INVOICE["CAC:INVOICELINE"][0]["CAC:TAXTOTAL"][0]["CAC:TAXSUBTOTAL"][0]["CAC:TAXCATEGORY"][0]["CBC:PERCENT"][0];
        this.registroVentas.cco_impigv = parseFloat(INVOICE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_'].toString());
        const monto = INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_']) : parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

        if (INVOICE["CAC:PAYMENTTERMS"]) {
          const formaPago = INVOICE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
            this.registroVentas.cpa_codcpa = '112';
          }

          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

            console.log('formapago', formaPago)
            console.log('fecha vencimiento', formaPago)
            const fecVen = new Date(INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
            const fecToday = new Date().toUTCString();
            this.registroVentas.cco_fecven = new Date(INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]);
            console.log('fecha hoy', fecToday)
            console.log('fecha ven', fecVen)

            const dias = retornaDiferenciaDias(fecVen, fecToday);
            console.log('dias', dias)
          }

        }

        if (this.registroVentas.cco_impigv == 0) {
          this.registroVentas.cco_impina = parseFloat(monto.toFixed(2));
          this.registroVentas.cco_impafe = 0;
        } else {
          this.registroVentas.cco_impafe = parseFloat(monto.toFixed(2));
          this.registroVentas.cco_impina = 0;

          this.registroVentas.cco_impdoc = parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

          if (this.registroVentas.cco_impafe + this.registroVentas.cco_impigv != this.registroVentas.cco_impdoc) {
            if (INVOICE["CAC:ALLOWANCECHARGE"]) {
              this.registroVentas.dpc_impfis = parseFloat(INVOICE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
            } else {
              this.registroVentas.cco_impina = parseFloat((this.registroVentas.cco_impdoc - (this.registroVentas.cco_impafe + this.registroVentas.cco_impigv)).toFixed(2))

            }
          }
        }

        this.registroVentas.cco_fecemi = new Date(INVOICE["CBC:ISSUEDATE"][0]);
        this.registroVentas.cco_fecemi.setDate(this.registroVentas.cco_fecemi.getDate() + 1);

        this.registroVentas.tmo_codtmo = INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : INVOICE['CBC:DOCUMENTCURRENCYCODE'][0];
        this.registroVentas.tmo_codtmo = this.registroVentas.tmo_codtmo == "USD" ? "DO" : "SO";

        this.registroVentas.valvta = this.registroVentas.cco_impina + this.registroVentas.cco_impafe + this.registroVentas.dpc_impfis + this.registroVentas.dpc_impsis;

        this.registroVentas.cco_impdoc = this.registroVentas.cco_impafe + this.registroVentas.cco_impina + this.registroVentas.cco_impigv;
        this.serieElement.nativeElement.add;

        const proveedor = this.proveedores.find(item => item.cli_codcli == this.registroVentas.cli_codcli);

        if (!proveedor) {
          this._dialogService.openDialog(ConfirmationComponent, '¿El proveedor encontrado no existe, desea agregar al proveedor?', '', '', '').subscribe(
            result => {
              if (result) {
                const dialogRef = this.dialog.open(ProveedorDialog, {
                  data: {
                    id: '0',
                    idARegistrar: this.registroVentas.cli_codcli
                  }
                })

                dialogRef.afterClosed().subscribe(result => {
                  this._snackBarService.showSuccess('Actualizando lista de proveedores', 'Ok');
                  this.obtenerProveedores()
                });
              }
            }
          )
        }
      }

      if (document.DEBITNOTE) {
        const DEBITNOTE = document.DEBITNOTE;
        this.resetAutocompleteDocument();
        this.documentToUpload.xmlFile = result.xmlFile;
        this.documentToUpload.xmlJSON = result.xmlJSON;
        this.documentToUpload.xmlStr = result.xmlStr;
        console.log('resultado de la carga del xml', result.xmlJSON)
        console.log('resultado de la carga del xml', JSON.stringify(result.xmlJSON))
        this.registroVentas.cli_codcli = DEBITNOTE["CAC:ACCOUNTINGSUPPLIERPARTY"][0]["CBC:CUSTOMERASSIGNEDACCOUNTID"] ? DEBITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : DEBITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'];
        this.registroVentas.sad_codsad = DEBITNOTE["CBC:ID"][0].split('-')[0];
        this.registroVentas.cco_numdoc = DEBITNOTE["CBC:ID"][0].split('-')[0] + DEBITNOTE["CBC:ID"][0].split('-')[1];
        const invoiceTypeCode = DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0];
        console.log('invoiceTypeCode', invoiceTypeCode)
        console.log('this.tiposDocumento', this.tiposDocumento)
        this.registroVentas.tdo_codtdo = this.tiposDocumento.find(item => item.TDO_CODOFI == invoiceTypeCode).TDO_CODTDO;
        console.log('tipo documento ====', this.registroVentas.tdo_codtdo)

        this.registroVentas.cco_tasigv = DEBITNOTE["CAC:INVOICELINE"][0]["CAC:TAXTOTAL"][0]["CAC:TAXSUBTOTAL"][0]["CAC:TAXCATEGORY"][0]["CBC:PERCENT"][0];
        this.registroVentas.cco_impigv = parseFloat(DEBITNOTE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_'].toString());
        const monto = DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? parseFloat(DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_']) : parseFloat(DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

        if (DEBITNOTE["CAC:PAYMENTTERMS"]) {
          const formaPago = DEBITNOTE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
            this.registroVentas.cpa_codcpa = '112';
          }

          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

            console.log('formapago', formaPago)
            console.log('fecha vencimiento', formaPago)
            const fecVen = new Date(DEBITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
            const fecToday = new Date().toUTCString();
            this.registroVentas.cco_fecven = new Date(DEBITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]);
            console.log('fecha hoy', fecToday)
            console.log('fecha ven', fecVen)

            const dias = retornaDiferenciaDias(fecVen, fecToday);
            console.log('dias', dias)
          }

        }

        if (this.registroVentas.cco_impigv == 0) {
          this.registroVentas.cco_impina = parseFloat(monto.toFixed(2));
          this.registroVentas.cco_impafe = 0;
        } else {
          this.registroVentas.cco_impafe = parseFloat(monto.toFixed(2));
          this.registroVentas.cco_impina = 0;

          this.registroVentas.cco_impdoc = parseFloat(DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

          if (this.registroVentas.cco_impafe + this.registroVentas.cco_impigv != this.registroVentas.cco_impdoc) {
            if (DEBITNOTE["CAC:ALLOWANCECHARGE"]) {
              this.registroVentas.dpc_impfis = parseFloat(DEBITNOTE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
            } else {
              this.registroVentas.cco_impina = parseFloat((this.registroVentas.cco_impdoc - (this.registroVentas.cco_impafe + this.registroVentas.cco_impigv)).toFixed(2))

            }
          }
        }

        this.registroVentas.cco_fecemi = new Date(DEBITNOTE["CBC:ISSUEDATE"][0]);
        this.registroVentas.cco_fecemi.setDate(this.registroVentas.cco_fecemi.getDate() + 1);

        this.registroVentas.tmo_codtmo = DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0];
        this.registroVentas.tmo_codtmo = this.registroVentas.tmo_codtmo == "USD" ? "DO" : "SO";

        this.registroVentas.valvta = this.registroVentas.cco_impina + this.registroVentas.cco_impafe + this.registroVentas.dpc_impfis + this.registroVentas.dpc_impsis;

        this.registroVentas.cco_impdoc = this.registroVentas.cco_impafe + this.registroVentas.cco_impina + this.registroVentas.cco_impigv;
        this.serieElement.nativeElement.add;

        const proveedor = this.proveedores.find(item => item.cli_codcli == this.registroVentas.cli_codcli);

        if (!proveedor) {
          this._dialogService.openDialog(ConfirmationComponent, '¿El proveedor encontrado no existe, desea agregar al proveedor?', '', '', '').subscribe(
            result => {
              if (result) {
                const dialogRef = this.dialog.open(ProveedorDialog, {
                  data: {
                    id: '0',
                    idARegistrar: this.registroVentas.cli_codcli
                  }
                })

                dialogRef.afterClosed().subscribe(result => {
                  this._snackBarService.showSuccess('Actualizando lista de proveedores', 'Ok');
                  this.obtenerProveedores()
                });
              }
            }
          )
        }
      }
    })

  }

  proveedores$: Subscription;
  ultimoRegistroCompra$: Subscription;

  obtenerProveedores(): void {
    this.loaderData = true;

    this.proveedores$ = this._proveedorService.obtenerProveedores().subscribe(
      proveedores => {
        this.proveedores = proveedores;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
      }
    )
  }

  resetAutocompleteDocument(): void {
    this.registroVentas.cli_codcli = null;
    this.registroVentas.sad_codsad = null;
    this.registroVentas.cco_numdoc = null;
    this.registroVentas.tdo_codtdo = null;
    this.registroVentas.cco_tasigv = 18;

    this.registroVentas.cco_impina = 0;
    this.registroVentas.cco_impafe = 0;
    this.registroVentas.cco_fecemi = new Date();
    this.registroVentas.cco_fecven = new Date();
    this.registroVentas.tmo_codtmo = null;
    this.registroVentas.cco_impigv = 0;
    this.registroVentas.valvta = 0;
    this.registroVentas.cco_impdoc = 0;

  }

  generarFechaFormato(fecha: string): Date {
    const [day, month, year] = fecha.split('/');

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  calcularDiferenciaEnDias(fecha1: Date, fecha2: Date) {
    const unDiaEnMilisegundos = 24 * 60 * 60 * 1000; // Milisegundos en un día
    const tiempo1 = fecha1.getTime(); // Obtener el tiempo en milisegundos
    const tiempo2 = fecha2.getTime();

    const diferenciaEnMilisegundos = Math.abs(tiempo2 - tiempo1);
    const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / unDiaEnMilisegundos);

    return diferenciaEnDias;
  }

  establecerDebeHaber(): void {
    this.registroVentas.detalle.forEach(element => {
      if (element.ldi_inddha == "D") {
        this.montosDebeHaber[0].mn += element.ldi_impnac;
        this.montosDebeHaber[0].me += element.ldi_impmex;
      } else {
        this.montosDebeHaber[1].mn += element.ldi_impnac;
        this.montosDebeHaber[1].me += element.ldi_impmex;
      }
    });

    this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
    this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

  }

  documentosPorPagar(): void {
    const dialogRef = this.dialog.open(CuentaPagarDialogComponent, {
      width: '1000px',
      data: { cli_codcli: (this.registroVentas.cli_codcli) ? this.registroVentas.cli_codcli : '', periodo: `${this.year}${this.month}`, clientes: this.proveedores }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        result.forEach(item => {
          let detailItem = new DetailSaleRecord();

          detailItem.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          detailItem.ano_codano = this.year;
          detailItem.mes_codmes = this.month;
          detailItem.ldi_corldi = `0000${this.registroVentas.detalle.length + 1}`;
          detailItem.tmo_codtmo = item.moneda;
          detailItem.ldi_inddha = "D";
          detailItem.tdo_codtdo = item.tip_doc;
          detailItem.ldi_impnac = (item.moneda == "SO") ? item.importe_saldo : item.importe_saldo / item.tipo_cambio;
          detailItem.ldi_impmex = (item.moneda == "SO") ? item.importe_saldo * item.tipo_cambio : item.importe_saldo;
          detailItem.ldi_tcameu = 0;
          detailItem.ldi_inddes = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.ldi_fecemi = item.fec_emision;
          detailItem.ldi_fecven = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.ldi_indcco = "N";
          detailItem.ldi_inddes = 0;
          detailItem.ldi_mesccl = "";
          detailItem.sco_codsco = item.subdiario;
          detailItem.suc_codsuc = "01";
          detailItem.tdo_docref = "";
          detailItem.vde_codvde = "";
          detailItem.cco_codcco = item.cta_cte;
          detailItem.ccn_codccn = item.cuenta;
          detailItem.cli_codcli = item.auxiliar;
          detailItem.ldi_docref = item.nro_docu;
          detailItem.ldi_tipcam = item.tipo_cambio;
          detailItem.ldi_gloldi = item.glosa;

          this.registroVentas.detalle.push(detailItem);
          this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);

          setTimeout(() => {
            this.selectCosto.first.open();
          }, 1);
        });
      }
    })
  }

  borrarAsientosDestino(): void {
    console.log('borrar destino')
    let detalleNuevo = [];
    this.registroVentas.detalle.forEach(item => {
      if (item.ldi_corldi[0] != '6' && item.ldi_corldi[0] != '7') {
        detalleNuevo.push(item);
      }
    });

    this.registroVentas.detalle = detalleNuevo;
    this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
  }

  completarDigitosNumDoc(numdoc: string): string {
    if (numdoc.length < 8) {
      const digitosFaltantes = 8 - numdoc.length;

      for (let index = 0; index < digitosFaltantes; index++) {
        numdoc = '0' + numdoc;
      }

      return numdoc;
    }

    return numdoc;
  }

  obtenerTasaCambio(): void {

    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.registroVentas.cco_fecemi)).subscribe(
      response => this.registroVentas.cco_tipcam = response[0].tipcam,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  registrarSeguimiento(f: NgForm): void {
    if (validarPeriodo(new Date(), this.year, this.month)) {

    }
  }

  seleccionarTipoOperacion(tipoOperacion): void {

    let tipoOpe;

    if (tipoOperacion) {
      tipoOpe = this.tiposOperacion.find(element => element.tga_codtga == tipoOperacion.value);
      if (tipoOpe && tipoOpe.ccn_codmna && tipoOpe.ccn_codmex && tipoOpe.ccn_codmna != "" && tipoOpe.ccn_codmex != "") {

        (this.registroVentas.tmo_codtmo == "SO") ? this.registroVentas.ccn_codccn = tipoOpe.ccn_codmna : this.registroVentas.ccn_codccn = tipoOpe.ccn_codmex;
      } else {
        let subdiario = this.subdiarios.find(item => item.sco_codsco == this.registroVentas.sco_codsco)
        if (subdiario && subdiario.ccn_codmn != "" && subdiario.ccn_codme != "" && subdiario.ccn_codme && subdiario.ccn_codmn) {
          (this.registroVentas.tmo_codtmo == "SO") ? this.registroVentas.ccn_codccn = subdiario.ccn_codmn : this.registroVentas.ccn_codccn = subdiario.ccn_codme;
        }
      }

    }

    if (tipoOpe) {
      this.registroVentas.t30_codt30 = tipoOpe["T30_CODT30"];
    }

    setTimeout(() => {
      this.tipoDocElement.open();
      this.tipoDocElement.focus();
    }, 1);
  }

  copiarItem(): void {

    if (!this.selected) {
      this._snackBarService.showError('Debe seleccionar un item', "OK");
      return;
    }

    if (this.selected.ldi_corldi[0] == '6' || this.selected.ldi_corldi[0] == '7') {
      this._snackBarService.showError('No es permitido copiar un ítem que comience con el correlativo 6 o 7', "OK");
      return;
    }

    let item = new DetailSaleRecord();
    item.cia_codcia = this.selected.cia_codcia;
    item.ano_codano = this.selected.ano_codano;
    item.mes_codmes = this.selected.mes_codmes;
    item.ldi_anoccl = this.selected.ldi_anoccl;
    item.ldi_mesccl = this.selected.ldi_mesccl;
    item.tmo_codtmo = this.selected.tmo_codtmo;
    item.ldi_fecemi = this.selected.ldi_fecemi;
    item.ldi_fecven = this.selected.ldi_fecven;
    item.sco_codsco = this.selected.sco_codsco;
    item.ldi_tipcam = this.selected.ldi_tipcam;
    item.ldi_gloldi = this.selected.ldi_gloldi;
    item.ccn_codccn = this.selected.ccn_codccn;
    item.cli_codcli = this.selected.ccn_codccn;
    item.tdo_codtdo = this.selected.tdo_codtdo;
    item.ldi_docref = this.selected.ldi_docref;
    item.tmo_codtmo = this.selected.tmo_codtmo;
    item.ldi_tipcam = this.selected.ldi_tipcam;
    item.ldi_inddha = this.selected.ldi_inddha;
    item.ldi_gloldi = this.selected.ldi_gloldi;
    item.cco_codcco = this.selected.cco_codcco;

    item.ldi_impnac = 0;
    item.ldi_impmex = 0;
    item.ccs_codccs = null;

    this.borrarAsientosDestino();

    this.registroVentas.detalle.push(item);

    this.registroVentas.detalle.forEach((element, key) => {
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort)

  }

  seleccionarProveedor(event): void {

    setTimeout(() => {
      this.emisionElement.nativeElement.focus();
      this.emisionElement.nativeElement.select();
    }, 1);

  }

  seleccionarCuenta(event): void {
    setTimeout(() => {
      this.glosaElement.nativeElement.focus();
      this.glosaElement.nativeElement.select();
    }, 1);
  }

  seleccionarTipoDoc(event): void {
    let valor;

    if (event) {
      valor = event.value;
      let tipo = this.tiposDoc.find(item => item.TDO_CODTDO == valor);

      this._almacenService.listarSerieDocumentos(valor).subscribe(
        series => {
          if (series && series.length > 0) {
            this.registroVentas.cco_numdoc = `${series[0].sdo_codsdo}-`;
            this.registroVentas.sad_codsad = series[0].sdo_codsdo;
            setTimeout(() => {
              this.nroDocElement.nativeElement.focus();
              this.nroDocElement.nativeElement.select();
            }, 1);
            return;
          }

          setTimeout(() => {
            this.serieElement.nativeElement.focus();
            this.serieElement.nativeElement.select();
          }, 1);

        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    } else {
      this.nroDocElement.nativeElement.focus()
      this.nroDocElement.nativeElement.select()
    }

  }

  seleccionarSubdiario(event): void {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.fechaElement.nativeElement.focus();
      this.fechaElement.nativeElement.select();
    }, 1);
  }

  establecerInafecto(): void {
    setTimeout(() => {
      this.afectoRefElement.nativeElement.select();
    }, 1);
  }

  establecerAfecto(): void {
    setTimeout(() => {
      this.tipoRetencionElement.focus();
    }, 1);
  }

  establecerSerie(): void {
    if (this.registroVentas.cco_numdoc && this.registroVentas.cco_numdoc.lenght > 4) {
      this.registroVentas.cco_numdoc = this.registroVentas.sad_codsad + '-' + this.registroVentas.cco_numdoc.substring(this.registroVentas.sad_codsad.length + 1, this.registroVentas.cco_numdoc.length + 1);
    } else {
      this.registroVentas.cco_numdoc = this.registroVentas.sad_codsad + '-'
    }

    setTimeout(() => {
      this.nroDocElement.nativeElement.focus();
      this.nroDocElement.nativeElement.select();
    }, 1);
  }

  cambiarFecha(event): void {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.proveedorElement.open();
      this.proveedorElement.focus();
    }, 1);
  }

  cambiarFechaEmision(event): void {

    this._ventasService.obtenerTipoCambio(formatDateWithDash(event.value._d)).subscribe(
      response => {
        this.registroVentas.cco_tipcam = response[0].tipcam;

        if (this.registroVentas.cco_tipcam == 0) {
          setTimeout(() => {
            this.tcElement.nativeElement.focus();
            this.tcElement.nativeElement.select();
          }, 1)
          return;
        }

        setTimeout(() => {
          this.monedaElement.open();
          this.monedaElement.focus();
        }, 1);

      },

    )
  }

  establecerEmision(): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.registroVentas.cco_fecemi)).subscribe(
      response => {
        this.registroVentas.cco_tipcam = response[0].tipcam;

        if (this.registroVentas.cco_tipcam == 0) {
          setTimeout(() => {
            this.tcElement.nativeElement.focus();
            this.tcElement.nativeElement.select();
          }, 1)
          return;
        }

        setTimeout(() => {
          this.monedaElement.open();
          this.monedaElement.focus();
        }, 1);

      },

    )

  }

  establecerFecha(): void {
    setTimeout(() => {
      this.proveedorElement.open();
      this.proveedorElement.focus();
    }, 1);
  }

  establecerTC(): void {
    setTimeout(() => {
      this.vctoElement.nativeElement.focus();
      this.vctoElement.nativeElement.select();
    }, 1);
  }

  cambiarFechaVcto(event): void {
    setTimeout(() => {
      this.monedaElement.open();
      this.monedaElement.focus();
    }, 1);
  }

  establecerVcto(): void {
    setTimeout(() => {
      this.cuentaElement.open();
      this.cuentaElement.focus();
    }, 1);
  }

  autocompletarNroDoc(): void {
    if (this.registroVentas.cco_numdoc.length == 4) {
      this.registroVentas.cco_numdoc = this.registroVentas.cco_numdoc + '-';
    }
  }

  establecerNroDoc(event): void {

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    let numero = zeroPad(this.registroVentas.cco_numdoc.substring(5, this.registroVentas.cco_numdoc.length + 1), 8); // "000005"

    this.registroVentas.cco_numdoc = this.registroVentas.cco_numdoc.substring(0, 5) + numero;
    setTimeout(() => {
      this.glosaElement.nativeElement.focus();
      this.glosaElement.nativeElement.select();
    }, 1);
  }

  seleccionarMoneda(event): void {
    setTimeout(() => {
      this.tipoOperacionElement.open();
      this.tipoOperacionElement.focus();
    }, 1);
  }

  establecerIgv(): void {
    setTimeout(() => {
      this.derCreditoElement.open();
      this.derCreditoElement.focus();
    }, 1);
  }

  establecerGlosa(): void {
    setTimeout(() => {
      this.inafectoRefElement.nativeElement.focus();
      this.inafectoRefElement.nativeElement.select();
    }, 1);
  }

  calculaTotal(): void {
    this.valCompra = this.registroVentas.cco_impafe + this.registroVentas.cco_impina + this.registroVentas.dpc_impadv;
    this.registroVentas.dpc_impcif = this.registroVentas.dpc_impfob + this.registroVentas.dpc_impfle + this.registroVentas.dpc_impseg;
    this.registroVentas.cco_impdoc = this.registroVentas.cco_impafe + this.registroVentas.cco_impina + this.registroVentas.cco_impigv - this.registroVentas.dpc_impren + this.registroVentas.cco_impper + this.registroVentas.dpc_impfis + this.registroVentas.dpc_impsis;
    const ln_importe = roundToDecimals(((this.registroVentas.cco_impdoc - this.registroVentas.cco_impina) * this.registroVentas.dpc_tasdet) / 100, 2);
    this.registroVentas.dpc_impdet = (this.registroVentas.tmo_codtmo == "SO") ? ln_importe : roundToDecimals(ln_importe * this.registroVentas.cco_tipcam, 1);
    this.registroVentas.cco_impigv = roundToDecimals(this.valCompra * (this.registroVentas.cco_tasigv / 100), 2)
    this.registroVentas.cco_impdoc = this.registroVentas.cco_impina + this.registroVentas.cco_impafe + this.registroVentas.cco_impigv;
  }

  agregarItem(): void {
    let detailItem = new DetailSaleRecord();

    detailItem.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    detailItem.ano_codano = this.year;
    detailItem.mes_codmes = this.month;
    detailItem.ldi_corldi = `0000${this.registroVentas.detalle.length + 1}`;
    detailItem.tmo_codtmo = "SO";
    detailItem.ldi_inddha = "D";
    detailItem.tdo_codtdo = "FAC";
    detailItem.ldi_impnac = 0;
    detailItem.ldi_impmex = 0;
    detailItem.ldi_tcameu = 0;
    detailItem.ldi_inddes = 0;
    detailItem.fca_codfca = "";
    detailItem.ldi_anoccl = this.year;
    detailItem.ldi_mesccl = this.month;
    detailItem.ldi_fecemi = this.registroVentas.cco_fecemi;
    detailItem.ldi_fecven = this.registroVentas.cco_fecven;
    detailItem.ldi_impsal = 0;
    detailItem.ldi_indccl = 0;
    detailItem.ldi_indcco = "N";
    detailItem.ldi_inddes = 0;
    detailItem.ldi_mesccl = "";
    detailItem.sco_codsco = this.registroVentas.sco_codsco;
    detailItem.suc_codsuc = "01";
    detailItem.tdo_docref = "";
    detailItem.vde_codvde = "";
    detailItem.ldi_tipcam = this.registroVentas.cco_tipcam;
    detailItem.ldi_gloldi = this.registroVentas.cco_glocco;
    detailItem.ldi_tipcam = this.registroVentas.cco_tipcam;

    this.registroVentas.detalle.push(detailItem);
    this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);

    this.registroVentas.detalle.forEach((element, key) => {
      element.ldi_corldi = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })
    setTimeout(() => {
      // this.cuentaTotalSelect.open();
      this.selectCuentas.last.open();
      this.selectCuentas.last.focus();
    }, 1);

  }

  confirmarNuevoItem(item: DetailSaleRecord): void {
    if (
      item.ccn_codccn &&
      item.tdo_codtdo &&
      item.ldi_docref &&
      item.tmo_codtmo &&
      item.ldi_inddha &&
      item.ldi_impnac &&
      item.ldi_impmex &&
      item.ldi_gloldi
    ) {
      item.isEditing = !item.isEditing;
      this.montosDebeHaber[0].mn = 0;
      this.montosDebeHaber[0].me = 0;
      this.montosDebeHaber[1].mn = 0;
      this.montosDebeHaber[1].me = 0;
      this.montosDebeHaber[2].mn = 0;
      this.montosDebeHaber[2].me = 0;

      this.registroVentas.detalle.forEach(element => {
        if (element.ldi_inddha == "D") {
          this.montosDebeHaber[0].mn += element.ldi_impnac;
          this.montosDebeHaber[0].me += element.ldi_impmex;
        } else {
          this.montosDebeHaber[1].mn += element.ldi_impnac;
          this.montosDebeHaber[1].me += element.ldi_impmex;
        }
      });

      this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
      this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

      this.buttonAddRefElement.focus();
    } else {
      this._snackBarService.showError("Es necesario completar todos los campos", "OK");
    }
  }

  nuevo(): void {
    this._router.navigate(['/registro-de-venta/0']);
    this.isNew = true;
    this.btnName = NAMES_CONSTANTS.BTN_STORE;
    this.registroVentas = new RegistroVentas();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource = fillTable(this.montosDebeHaber, this.paginator3, this.sort3);

    this.setDefaultFieldsSaleRecord();
  }


  filaSeleccionada(row, event): void {

  }

  seleccionarTipoDocRef(event): void { }

  xmlInputChange(fileInputEvent: any): void {
    this.loaderData = true;

    let file = fileInputEvent.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (evt) => {
      const xmlData: string = (evt as any).target.result;

      const parser = new xml2js.Parser({ strict: false, trim: true });
      parser.parseString(xmlData, (err, result) => {
        let key = Object.keys(result)[0];
        if (key == "INVOICE") {
          this.registroVentas.cli_codcli = result[key]["CAC:ACCOUNTINGSUPPLIERPARTY"][0]["CAC:PARTY"][0]["CAC:PARTYIDENTIFICATION"][0]["CBC:ID"][0]["_"];
          this.registroVentas.cco_fecemi = result[key]["CBC:ISSUEDATE"][0];
          this.registroVentas.cco_fecven = result[key]["CBC:ISSUEDATE"][0];
          this.registroVentas.tmo_codtmo = (result[key]["CBC:DOCUMENTCURRENCYCODE"][0] == 'PEN') ? 'SO' : 'DO';
          this.registroVentas.cco_impafe = result[key]["CAC:LEGALMONETARYTOTAL"][0]["CBC:LINEEXTENSIONAMOUNT"][0]["_"];
          this.registroVentas.cco_impigv = result[key]["CAC:TAXTOTAL"][0]["CBC:TAXAMOUNT"][0]["_"];

          this.registroVentas.cco_impdoc = result[key]["CAC:LEGALMONETARYTOTAL"][0]["CBC:PAYABLEAMOUNT"][0]["_"];
          this.registroVentas.cco_glocco = result[key]["CAC:INVOICELINE"][0]["CAC:ITEM"][0]["CBC:DESCRIPTION"][0];

          let data_to_validate = {
            cli_codcli: this.registroVentas.cli_codcli
          }
          this.validaciónCargaXML(data_to_validate);
          this.loaderData = false;
        } else {
          this.loaderData = false;
          this._snackBarService.showError("Debe de cargar un XML de Factura", "OK");
        }
      });
    };
    reader.readAsText(file);

    this.nameXml = fileInputEvent.target.files[0].name;
  }

  validaciónCargaXML({ cli_codcli }): void {
    const proveedor = this.proveedores.find(item => item.CODIGO == this.registroVentas.cli_codcli)

    if (!proveedor) {
      this._snackBarService.showError('No se encontró al proveedor en nuestros Registros', 'OK');
      fetch(`http://162.248.55.12/pav-facelectronica/public/api/consulta/ruc/${cli_codcli}`)
        .then(resolve => resolve.json())
        .then(result => {

          const dialogRef = this.dialog.open(AddProviderComponent, {
            width: 'auto',
            data: result,
          });

          dialogRef.afterClosed().subscribe(result => {
            this._clienteService.obtenerClientes().subscribe(
              auxiliares => {
                this.auxiliares = auxiliares;
                this.proveedores = auxiliares;

                setTimeout(() => {
                  this.tipoOperacionElement.focus();
                  this.tipoOperacionElement.open();
                }, 1);
              },
              error => this._snackBarService.showError(error.error.msg, "OK")
            )
          });

        })
    } else {
      setTimeout(() => {
        this.tipoOperacionElement.focus();
        this.tipoOperacionElement.open();
      }, 1);
    }
  }

  quitarItem(): void {

    let row = this.registroVentas.detalle.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.registroVentas.detalle.length > 0) row = this.registroVentas.detalle[0];

    let details = [];
    this.registroVentas.detalle.forEach(element => {
      if (element.ldi_corldi != row.ldi_corldi) {
        details.push(element)
      }
    });

    this.registroVentas.detalle = details;

    this.registroVentas.detalle.forEach((element, key) => {
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;

    this.establecerDebeHaber()
  }

  insertarItem(): void {

    if (!this.registroVentas.cco_tipcam || this.registroVentas.cco_tipcam == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.registroVentas.ccn_codccn) {
      this._snackBarService.showError("Establecer Cuenta", "OK");

      return;
    }

    const registro = new DetailSaleRecord();

    registro.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    registro.ano_codano = this.year;
    registro.mes_codmes = this.month;

    registro.ldi_anoccl = this.year;
    registro.ldi_mesccl = this.month;
    registro.tmo_codtmo = this.registroVentas.tmo_codtmo;
    registro.ldi_fecemi = this.registroVentas.cco_fecemi;
    registro.ldi_fecven = this.registroVentas.cco_fecven;
    registro.sco_codsco = this.registroVentas.sco_codsco;
    registro.ldi_tipcam = this.registroVentas.cco_tipcam;
    registro.ldi_gloldi = this.registroVentas.cco_glocco;
    registro.tdo_codtdo = this.registroVentas.tdo_codtdo;
    registro.ldi_docref = this.registroVentas.cco_numdoc;

    let i = 0;

    if (this.selected) {
      this.registroVentas.detalle.forEach((item, index) => {
        if (item.ldi_corldi == this.selected.ldi_corldi) i = index;
      })

      this.registroVentas.detalle.splice(i, 0, registro);
    } else {
      this.registroVentas.detalle.push(registro);
      this.registroVentas.detalle = this.registroVentas.detalle.reverse();
    }

    this.registroVentas.detalle.forEach((element, key) => {
      console.log('ids', key)
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    console.log('detalle', this.registroVentas.detalle)

    this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);

  }


  generarAsiento(): void {
    if (this.registroVentas.detalle.length == 0) {

      let [ps_cia, ps_suc, ps_sco, ps_codtga, ps_cliente] = [this._configurationService.obtenerCompaniaCliente(), "01", this.registroVentas.sco_codsco, this.registroVentas.tga_codtga, this.registroVentas.cli_codcli];

      // Verificar percepcion
      let li_perigv = 0;
      const tipoDoc = this.tiposDoc.find(item => item.TDO_CODTDO == this.registroVentas.tdo_codtdo);
      if (!tipoDoc) {
        this._snackBarService.showError("Establecer un Tipo de Documento", "OK")
        return;
      }
      li_perigv = tipoDoc.TDO_PERIGV;

      if (this.registroVentas.cco_codcco) {
        this.registroVentas.detalle.forEach(item => {
          if (item.tdo_codtdo == 'DPP' && item.cco_codcco) {
            this._snackBarService.showError("No se puede generar asiento, tiene cuenta corriente de Detracción ...Modifique manualmente.", "OK");
            return;
          }
        });
      }

      if (this.registroVentas.tga_codtga) {
        this.registroVentas.detalle = [];
      } else {
        this._snackBarService.showError("Es necesario ingresar el tipo de operación", "OK");
        return;
      }

      if (!this.registroVentas.sco_codsco) {
        this._snackBarService.showError("Ingresar el subdiario contable", "OK");
        return;
      }

      let tipoOp = this.tiposOperacion.find(element => element.tga_codtga == "0001");

      if (!tipoOp.detalle) {
        this._snackBarService.showError("Advertencia : No existe detalle de configuración de asiento automático para la transacción " + this.registroVentas.tga_codtga, "Ok");
        return;
      }

      let ps_inddom;

      if (!this.registroVentas.cli_codcli) {
        this._snackBarService.showError("Error: No existe el proveedor del documento", "OK");
        return;
      } else {
        const prov = this.proveedores.find(element => element.CODIGO == this.registroVentas.cli_codcli);
        if (prov) {
          ps_inddom = prov.IND_DOMICILIADO;
        }
      }

      // Si la cuenta contable de provisión está configurada propiamente para la transaccion
      let ps_ccn_codmn, ps_ccn_codme;

      let tipoOpe = this.tiposOperacion.find(element => element.tga_codtga == this.registroVentas.tga_codtga)

      const subdiario = this.subdiarios.find(element => element.sco_codsco == this.registroVentas.sco_codsco)

      if (tipoOpe.ccn_codmna && tipoOpe.ccn_codmex) {
        ps_ccn_codmn = tipoOpe.ccn_codmna;
        ps_ccn_codme = tipoOpe.ccn_codmex;
      } else {
        ps_ccn_codmn = subdiario.ccn_codmn;
        ps_ccn_codme = subdiario.ccn_codme;
      }

      if (this.registroVentas.ccn_codccn) {
        const cuenta = this.cuentas.find(element => element.cuenta == this.registroVentas.ccn_codccn)

        if (this.registroVentas.tmo_codtmo == cuenta.moneda) {
          ps_ccn_codmn = (this.registroVentas.tmo_codtmo == "SO") ? this.registroVentas.ccn_codccn : this.registroVentas.ccn_codccn;

        } else {
          this._snackBarService.showError("Error: Moneda de Cuenta por Pagar no corresponde Moneda de la Cuenta Contable " + this.registroVentas.ccn_codccn, "OK");
          return;
        }
      } else {

        this.registroVentas.ccn_codccn = (this.registroVentas.tmo_codtmo == "SO") ? ps_ccn_codmn : ps_ccn_codme;

      }

      // ** Valida cuenta contable de provision
      if (this.registroVentas.tmo_codtmo == "SO" && !ps_ccn_codmn) {
        this._snackBarService.showError(`Error: No existe la cuenta contable de registro de provisión
        Verifique la cuentas contables asignadas para el Subdiario ${this.registroVentas.sco_codsco}`, "OK");
        return;
      }

      if (this.registroVentas.tmo_codtmo == "DO" && !ps_ccn_codme) {
        this._snackBarService.showError(`Error: No existe la cuenta contable de registro de provisión.
        "Verifique la cuentas contables asignadas para el Subdiario ${this.registroVentas.sco_codsco}`, "OK");
      }

      // * por regularizar
      let ps_igvsde = '401121' //&&tmpval.cct_igvsde
      let ps_igvndo = '401121' //&&tmpval.cct_igvndo
      let ls_ndoapl = '401121' //&&tmpval.cct_ndoapl
      let ls_ndoret = '401141' //&&tmpval.cct_ndoret
      let ls_perigv = '401117' //&&tmpval.cct_perigv

      // *** Contabiliza cuenta 42
      let ps_cuenta, pn_impnac, pn_impmex;
      let ps_tmo_mn = "SO";

      if (this.registroVentas.tmo_codtmo == ps_tmo_mn) {
        ps_cuenta = ps_ccn_codmn
        pn_impnac = this.registroVentas.cco_impdoc;
        pn_impmex = pn_impnac / this.registroVentas.cco_tipcam;
      } else {
        ps_cuenta = ps_ccn_codme
        pn_impmex = this.registroVentas.cco_impdoc;
        pn_impnac = pn_impmex * this.registroVentas.cco_tipcam;
      }

      // *--- Valida documento
      let ps_valor = ps_cuenta.substring(0, 1);
      let ps_codtdo = this.registroVentas.tdo_codtdo;

      let signo = this.signosctacte.find(item => item.tdo_codtdo == this.registroVentas.tdo_codtdo && item.cla_codcla == ps_valor);

      if (!signo) {
        this._snackBarService.showError(`Error: Código de Documento ${ps_codtdo} no esta relacionado con la Clase Contable ${ps_valor}, cuenta ${ps_cuenta}`, "OK");
        return;
      }

      let li_item = 1;
      ps_cuenta = this.registroVentas.ccn_codccn;

      if (ps_cuenta) {

        ps_valor = this.registroVentas.cco_codcco;
        let itemDetalle = new DetailSaleRecord();
        itemDetalle.ldi_corldi = '00000' + li_item;
        itemDetalle.ccn_codccn = ps_cuenta;
        itemDetalle.ccs_codccs = '';
        itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
        itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle.ldi_inddha = (signo == -1) ? 'H' : 'D';
        itemDetalle.ldi_impnac = pn_impnac;
        itemDetalle.ldi_impmex = pn_impmex;
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
      }

      //  *** Contabiliza cuenta 40 de Igv
      if (li_perigv != 1) {

        ps_cuenta = (tipoOpe.ccn_impmna) ? tipoOpe.ccn_impmna : subdiario.ccn_codimp;

      } else {
        ps_cuenta = ls_perigv;
      }

      let ps_indder = this.registroVentas.dpc_indder;

      if (ps_cuenta && (ps_indder == '1' || ps_indder == '3') && this.registroVentas.cco_impigv > 0) {
        if (this.registroVentas.tmo_codtmo == ps_tmo_mn) {
          pn_impnac = ps_indder == '1' ? this.registroVentas.cco_impigv : this.registroVentas.cco_impigv * this.registroVentas.cco_tasigv / 100;

          pn_impmex = pn_impnac / this.registroVentas.cco_tipcam
        } else {

          pn_impmex = (ps_indder == '1') ? this.registroVentas.cco_impigv : this.registroVentas.cco_impigv * this.registroVentas.cco_tasigv / 100;

          pn_impnac = pn_impmex / this.registroVentas.cco_tipcam

        }

        li_item++;
        ps_valor = this.registroVentas.cco_codcco;
        let itemDetalle2 = new DetailSaleRecord();
        itemDetalle2.ldi_corldi = `0000${li_item}`;
        itemDetalle2.ccn_codccn = ps_cuenta;
        itemDetalle2.ccs_codccs = '';
        itemDetalle2.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle2.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle2.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle2.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle2.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle2.ldi_inddha = (signo == -1) ? 'D' : 'H';
        itemDetalle2.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle2.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle2.ldi_impnac = 'N';
        itemDetalle2.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle2);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
      }

      // *** Contabiliza cuenta de Igv sin derecho
      ps_cuenta = ps_igvsde

      if (ps_cuenta && (ps_indder == '2' || ps_indder == '3') && this.registroVentas.cco_impigv > 0) {
        if (this.registroVentas.tmo_codtmo == ps_tmo_mn) {
          if (ps_indder == '2') {
            pn_impnac = this.registroVentas.cco_impigv;
          } else {
            pn_impnac = this.registroVentas.cco_impigv - (this.registroVentas.dpc_afcder * this.registroVentas.cco_tasigv / 100); // redondesar a 2
          }
          pn_impmex = pn_impnac / this.registroVentas.cco_tipcam

        } else {
          if (ps_indder == '2') {
            pn_impmex = this.registroVentas.cco_impigv;
          } else {
            pn_impmex = this.registroVentas.cco_impigv - (this.registroVentas.dpc_afcder * this.registroVentas.cco_tasigv / 100); // redondesar a 2
          }
          pn_impnac = pn_impmex / this.registroVentas.cco_tipcam
        }

        li_item++;
        ps_valor = this.registroVentas.cco_codcco;
        let itemDetalle3 = new DetailSaleRecord();
        itemDetalle3.ldi_corldi = `0000${li_item}`;
        itemDetalle3.ccn_codccn = ps_cuenta;
        itemDetalle3.ccs_codccs = '';
        itemDetalle3.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle3.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle3.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle3.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle3.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle3.ldi_inddha = (signo == -1) ? 'D' : 'H';
        itemDetalle3.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle3.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle3.ldi_impnac = 'N';
        itemDetalle3.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle3);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);

      }

      let valCompra = this.registroVentas.cco_impina + this.registroVentas.cco_impafe;
      // *** Contabiliza cuenta 40 de Igv Para no domiciliados
      ps_cuenta = ps_igvndo;
      if (this.registroVentas.tmo_codtmo == ps_tmo_mn) {
        pn_impnac = valCompra * this.registroVentas.cco_tasigv / 100
        pn_impmex = pn_impnac / this.registroVentas.cco_tipcam
      } else {
        pn_impmex = valCompra * this.registroVentas.cco_tasigv / 100
        pn_impnac = pn_impmex / this.registroVentas.cco_tipcam
      }

      if (ps_cuenta && ps_inddom == 0 && (pn_impnac > 0 || pn_impmex > 0) && ps_indder == '4') {
        li_item++;

        ps_valor = this.registroVentas.cco_codcco;
        let itemDetalle4 = new DetailSaleRecord();

        itemDetalle4.ldi_corldi = `0000${li_item}`;
        itemDetalle4.ccn_codccn = ps_cuenta;
        itemDetalle4.ccs_codccs = '';
        itemDetalle4.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle4.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle4.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle4.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle4.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle4.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
        itemDetalle4.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle4.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle4.ldi_indcco = 'N';
        itemDetalle4.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle4);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);

      }

      // *** 2.-Contabiliza cuenta 401120 de Igv Por no domiciliados
      ps_cuenta = ls_ndoapl;
      if (this.registroVentas.tmo_codtmo == ps_tmo_mn) {
        pn_impnac = valCompra * this.registroVentas.cco_tasigv / 100;
        pn_impmex = pn_impnac / this.registroVentas.cco_tipcam;
      } else {
        pn_impmex = valCompra * this.registroVentas.cco_tasigv / 100;
        pn_impnac = pn_impmex * this.registroVentas.cco_tipcam;
      }

      if (ps_cuenta && ps_inddom == 0 && (pn_impnac > 0 || pn_impmex > 0) && ps_indder == '4') {
        li_item++;
        ps_valor = this.registroVentas.cco_codcco;
        let itemDetalle = new DetailSaleRecord();

        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = ps_cuenta;
        itemDetalle.ccn_codccn = '';
        itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'H' : 'D';
        itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);

      }

      // *** Contabiliza cuenta 40 de Renta


      ps_cuenta == '40172001';

      if (this.registroVentas.tmo_codtmo == ps_tmo_mn) {
        pn_impnac = this.registroVentas.dpc_impren;
        pn_impmex = pn_impnac / this.registroVentas.cco_tipcam;
      } else {
        pn_impmex = this.registroVentas.dpc_impren;
        pn_impnac = pn_impmex * this.registroVentas.cco_tipcam;
      }

      if (ps_cuenta && this.registroVentas.dpc_impren > 0) {
        li_item++;
        ps_valor = this.registroVentas.cco_codcco;

        let itemDetalle = new DetailSaleRecord();
        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = ps_cuenta;
        itemDetalle.ccs_codccs = '';
        itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'H' : 'D';
        itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle.ldi_indcco = 'N'
        itemDetalle.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
      }

      let ps_codccs, pn_porcen;

      // *** Contabiliza cuenta Gasto
      let pn_valvta = valCompra;
      if (pn_valvta != 0) {
        if (tipoOpe.detalle) {
          tipoOpe.detalle.forEach(item => {
            ps_cuenta = item.ccn_codccn;
            ps_codccs = item.ccs_codccs;
            pn_porcen = (item.aau_poraau) ? item.aau_poraau : 0;

            if (this.registroVentas.tmo_codtmo == ps_tmo_mn) {
              pn_impnac = pn_valvta * pn_porcen / 100;
              pn_impmex = pn_impnac / this.registroVentas.cco_tipcam;
            } else {
              pn_impmex = pn_valvta * pn_porcen / 100;
              pn_impmex = pn_impmex * this.registroVentas.cco_tipcam;
            }

            if (ps_cuenta) {
              li_item++;
              let itemDetalle = new DetailSaleRecord();

              itemDetalle.ldi_corldi = `0000${li_item}`;
              itemDetalle.ccn_codccn = ps_cuenta;
              itemDetalle.ccs_codccs = ps_codccs;
              itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
              itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
              itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
              itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
              itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
              itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
              itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
              itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
              itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
              itemDetalle.ldi_indcco = 'N';
              itemDetalle.isEditing = true;

              this.registroVentas.detalle.push(itemDetalle);
              this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
            }
          });
        }
      }

      // ***************DETRACCION*****************
      // ***Contabilidad cuenta 42 de Detracción***

      if (this.registroVentas.dpc_impdet > 0 && this.registroVentas.tim_codtim != 'PER') {
        li_item++;
        ps_cuenta = this.registroVentas.ccn_codccn;
        pn_impnac = this.registroVentas.dpc_impdet;
        pn_impmex = this.registroVentas.dpc_impdet / this.registroVentas.cco_tipcam;

        if (ps_cuenta.length > 0 && ps_cuenta) {
          ps_valor = this.registroVentas.cco_codcco;

          let itemDetalle = new DetailSaleRecord();
          itemDetalle.cco_codcco = ps_valor;
          itemDetalle.ldi_corldi = `0000${li_item}`;
          itemDetalle.ccn_codccn = ps_cuenta;
          itemDetalle.ccs_codccs = '';
          itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
          itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
          itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
          itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
          itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
          itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
          itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
          itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle.ldi_indcco = 'N';
          itemDetalle.isEditing = true;

          this.registroVentas.detalle.push(itemDetalle);
          this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
        }

        if (subdiario.ccn_coddet) {
          li_item++;
          ps_cuenta = subdiario.ccn_coddet;

          let itemDetalle = new DetailSaleRecord();
          itemDetalle.ldi_corldi = `0000${li_item}`;
          itemDetalle.ccn_codccn = ps_cuenta;
          itemDetalle.ccs_codccs = '';
          itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
          itemDetalle.tdo_codtdo = 'DPP';
          itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
          itemDetalle.tmo_codtmo = 'SO';
          itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
          itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
          itemDetalle.ldi_inddha = 'H';
          itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle.ldi_indcco = 'N';
          itemDetalle.isEditing = true;

          this.registroVentas.detalle.push(itemDetalle);
          this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
        }

      }


      if (this.registroVentas.dpc_impdet > 0 && this.registroVentas.tim_codtim != 'PER') {
        li_item++;
        ps_cuenta = this.registroVentas.ccn_codccn;
        pn_impnac = this.registroVentas.dpc_impdet;
        pn_impmex = this.registroVentas.dpc_impdet / this.registroVentas.cco_tipcam;

        if (ps_cuenta) {
          ps_valor = this.registroVentas.cco_codcco;

          let itemDetalle = new DetailSaleRecord();
          itemDetalle.cco_codcco = ps_valor;
          itemDetalle.ldi_corldi = `0000${li_item}`;
          itemDetalle.ccn_codccn = ps_cuenta;
          itemDetalle.ccs_codccs = '';
          itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
          itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
          itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
          itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
          itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
          itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
          itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
          itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle.ldi_indcco = 'N';
          itemDetalle.isEditing = true;

          this.registroVentas.detalle.push(itemDetalle);
          this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
        }

        if (subdiario.ccn_coddet) {
          li_item++;
          ps_cuenta = subdiario.ccn_coddet;

          let itemDetalle = new DetailSaleRecord();
          itemDetalle.ldi_corldi = `0000${li_item}`;
          itemDetalle.ccn_codccn = ps_cuenta;
          itemDetalle.ccs_codccs = '';
          itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
          itemDetalle.tdo_codtdo = 'DPP';
          itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
          itemDetalle.tmo_codtmo = 'SO';
          itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
          itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
          itemDetalle.ldi_inddha = 'H';
          itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle.ldi_indcco = 'N';
          itemDetalle.isEditing = true;

          this.registroVentas.detalle.push(itemDetalle);
          this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
        }

      }

      //       ************************************************

      // *!*         ***************PERCEPCION*****************
      // *!*         *** Contabilidad cuenta 40 de Percepción***

      let ls_cuenta = '401131';
      if (this.registroVentas.tmo_codtmo == ps_tmo_mn) {
        pn_impnac = this.registroVentas.dpc_impper;
        pn_impmex = pn_impnac / this.registroVentas.cco_tipcam;
      } else {
        pn_impmex = this.registroVentas.dpc_impper;
        pn_impnac = pn_impmex * this.registroVentas.cco_tipcam;
      }

      if (ls_cuenta && this.registroVentas.dpc_impper > 0) {
        li_item++;
        ps_valor = this.registroVentas.cco_codcco;

        let itemDetalle = new DetailSaleRecord();
        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = ls_cuenta;
        itemDetalle.cco_codcco = '';
        itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
        itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
        itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
      }

      if (this.registroVentas.dpc_impsis != 0) {
        li_item++;
        let itemDetalle = new DetailSaleRecord();
        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = '601801';
        itemDetalle.cco_codcco = '';
        itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
        itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
        itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle.ldi_impnac = (this.registroVentas.tmo_codtmo == 'SO') ? roundToDecimals(this.registroVentas.dpc_impsis, 2) : roundToDecimals(this.registroVentas.dpc_impsis * this.registroVentas.cco_tipcam, 2);
        itemDetalle.ldi_impmex = (this.registroVentas.tmo_codtmo == 'SO') ? roundToDecimals(this.registroVentas.dpc_impsis / this.registroVentas.cco_tipcam, 2) : roundToDecimals(this.registroVentas.dpc_impsis, 2);
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
      }

      if (this.registroVentas.dpc_impfis != 0) {
        li_item++;
        let itemDetalle = new DetailSaleRecord();

        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = '601802';
        itemDetalle.cco_codcco = '';
        itemDetalle.ldi_gloldi = this.registroVentas.cco_glocco;
        itemDetalle.cli_codcli = this.registroVentas.cli_codcli;
        itemDetalle.tmo_codtmo = this.registroVentas.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.registroVentas.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
        itemDetalle.tdo_codtdo = this.registroVentas.tdo_codtdo;
        itemDetalle.ldi_docref = this.registroVentas.cco_numdoc;
        itemDetalle.ldi_impnac = (this.registroVentas.tmo_codtmo == 'SO') ? roundToDecimals(this.registroVentas.dpc_impfis, 2) : roundToDecimals(this.registroVentas.dpc_impfis * this.registroVentas.cco_tipcam, 2);
        itemDetalle.ldi_impmex = (this.registroVentas.tmo_codtmo == 'SO') ? roundToDecimals(this.registroVentas.dpc_impfis / this.registroVentas.cco_tipcam, 2) : roundToDecimals(this.registroVentas.dpc_impfis, 2);
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.registroVentas.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);
      }

      if (this.registroVentas.detalle.length > 0) {
        setTimeout(() => {
          this.selectCosto.first.open();
          this.selectCosto.first.focus();
        }, 1);
      }
    } else {
      this._snackBarService.showError("Es necesario que la tabla se encuentre vacía.", "OK");
    }

  }

  ajusteDiferencia(): void {
    const diferencia_mn = this.dataSource3.data[2].mn;
    const diferencia_me = this.dataSource3.data[2].me;
    if (Math.abs(diferencia_mn) <= 0.05 && diferencia_mn > 0 && Math.abs(diferencia_me) <= 0.05 && diferencia_me > 0) {
      let ps_tmo_mn;
      let pn_impnac;
      let pn_impmex;
      let ps_codtdo;
      let ps_codccs;
      let ps_cuenta;
      let ls_cad;

      (Math.abs(diferencia_mn) != 0) ? ps_tmo_mn = "SO" : ps_tmo_mn = "DO";

      pn_impnac = Math.abs(diferencia_mn);
      pn_impmex = Math.abs(diferencia_me);

      ps_codtdo = (diferencia_mn < 0 || diferencia_me < 0) ? "AJP" : "AJP";
      ps_codccs = "";

      this._contabilidadService.configuraTipoDocCtaContable(ps_codtdo).subscribe(
        response => {

          ps_cuenta = response[0].ccn_codmn;
          ps_codccs = response[0].ccs_codccs;

          if (ps_cuenta) {

            let detailItem = new DetailSaleRecord();

            detailItem.ldi_inddha = "D";
            detailItem.ldi_tcameu = 0;
            detailItem.ldi_inddes = 0;
            detailItem.fca_codfca = "";
            detailItem.ldi_impsal = 0;
            detailItem.ldi_indccl = 0;
            detailItem.ldi_indcco = "N";
            detailItem.ldi_inddes = 0;
            detailItem.ldi_mesccl = "";
            detailItem.sco_codsco = this.registroVentas.sco_codsco;
            detailItem.suc_codsuc = "01";
            detailItem.tdo_docref = "";
            detailItem.vde_codvde = "";

            detailItem.ccn_codccn = ps_cuenta;
            detailItem.ldi_gloldi = this.registroVentas.cco_glocco;
            detailItem.tmo_codtmo = ps_tmo_mn;
            detailItem.tdo_codtdo = ps_codtdo;
            detailItem.ldi_docref = this.registroVentas.cco_numdoc;
            detailItem.ldi_tipcam = roundToDecimals(pn_impmex / pn_impnac, 3);
            detailItem.ldi_inddha = (ps_codtdo == "AJP") ? "D" : "H";
            detailItem.ldi_impnac = roundToDecimals(pn_impnac, 2);
            detailItem.ldi_impmex = roundToDecimals(pn_impmex, 2);
            detailItem.ldi_indcco = "N";
            detailItem.isEditing = true;



            if (ps_codtdo == "AJP" && ps_codccs > 0) {
              detailItem.ccs_codccs = ps_codccs;
            }

            this.registroVentas.detalle.push(detailItem);

            this.dataSource = fillTable(this.registroVentas.detalle, this.paginator, this.sort);

            this.registroVentas.detalle.forEach((element, key) => {
              element.ldi_corldi = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
            })

            setTimeout(() => {
              this.inputDescripcion.last.nativeElement.focus();
              this.inputDescripcion.last.nativeElement.slect();
            }, 1);

            this.montosDebeHaber[0].mn = 0;
            this.montosDebeHaber[0].me = 0;
            this.montosDebeHaber[1].mn = 0;
            this.montosDebeHaber[1].me = 0;
            this.montosDebeHaber[2].mn = 0;
            this.montosDebeHaber[2].me = 0;

            this.registroVentas.detalle.forEach(element => {
              if (element.ldi_inddha == "D") {
                this.montosDebeHaber[0].mn += element.ldi_impnac;
                this.montosDebeHaber[0].me += element.ldi_impmex;
              } else {
                this.montosDebeHaber[1].mn += element.ldi_impnac;
                this.montosDebeHaber[1].me += element.ldi_impmex;
              }
            });

            this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
            this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

          }
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    } else {
      this._snackBarService.showError("La diferencia tiene que ser > 0 y <= a 0.05", "OK");
    }

  }

  calculoImportesMN(row: DetailSaleRecord): void {
    row.ldi_impmex = roundToDecimals(row.ldi_impnac * row.ldi_tipcam, 2);
  }

  calculoImportesME(row: DetailSaleRecord): void {
    row.ldi_impnac = roundToDecimals(row.ldi_impmex / row.ldi_tipcam, 2);
  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  volver(): void {
    this._router.navigate(['/registros-de-venta']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.subdiarios$,
      this.tipoOperacion$,
      this.cuentas$,
      this.tiposBienServicio$,
      this.tiposRenta$,
      this.tiposImpuesto$,
      this.tiposDocumento$,
      this.proveedor$,
      this.moneda$,
      this.ordenesCompra$,
      this.condiciones$,
      this.voucher$,
      this.centro$,
      this.auxiliar$,
      this.tipoRetencion$,
      this.tipoImpRetencion$,
      this.period$
    ])

  }

}
// Cuando veas los espejos apuntando a tus espaldas sabras que los mitos no son para ti.
