import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Canal } from "src/app/models/crm/canal";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
    providedIn: 'root',
})
export class CanalService {
    constructor(
      private http: HttpClient,
      private _configurationService: ConfigurationService
    ) {}

    /**
     *
     * @returns
     */
    obtenerCanales(): Observable<any> {
      return this.http.get<any>(
        `${this._configurationService.obtenerUrl()}/api/crm/canal/${this._configurationService.obtenerCompaniaCliente()}`
      ).pipe(
        map((canales: any) => {
          return canales.map(canal => {
            canal['idmtk'] = canal[`idmtk`].toString()

            return canal;
          })
        })
      );
    }

    /**
     *
     * @param cod
     * @returns
     */
    obtenerCanal(cod): Observable<any> {
      return this.http.get<any>(
        `${this._configurationService.obtenerUrl()}/api/crm/canal/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
      );
    }

    /**
     *
     * @param canal
     * @returns
     */
    registrarCanal(canal: Canal): Observable<any> {
      return this.http.post<any>(
        `${this._configurationService.obtenerUrl()}/api/crm/canal`,
        canal
      );
    }

    /**
     *
     * @param canal
     * @returns
     */
    actualizarCanal(canal: Canal): Observable<any> {
      return this.http.put<any>(
        `${this._configurationService.obtenerUrl()}/api/crm/canal`,
        canal
      );
    }
}
