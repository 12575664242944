import { BINARY_TYPE } from "@data/interfaces/binary.type";
import { Order } from "./order";

export class Factura {
  CIA_CODCIA: string;
  CCO_CODCCO: string;
  CLI_CODCLI: string;
  CLI_NOMCLI: string;
  CLI_CODDOC: string;
  TDO_CODTDO: string;
  serie: string;
  CCO_NUMDOC: string;
  CCO_GLOCCO: string;
  CCO_FECEMI: Date;
  CCO_FECVEN: Date;
  TMO_CODTMO: string;
  CCN_CODCCN: string;
  CCO_IMPDOC: number | string;
  CCO_IMPSOLES: number | string;
  CCO_IMPDOLAR: number | string;
  CCO_IMPCCO: number;
  CCO_SALCCO: number;
  CCO_SALLBA: number;
  CCO_SALRES: number;
  CCO_IMPABO: number;
  CCO_IMPIGV: number | string;
  CCO_TASIGV: number;
  CCO_IMPCOR: number;
  CCO_IMPINA: number;
  CCO_IMPAFE: number;
  CCO_VALVTA: number | string;
  CCO_INDTCA: string;
  CCO_TIPCAM: number;
  CCO_CODPAG: string;
  MMO_CODMMO: string;
  CPA_CODCPA: string;
  TDO_DOCREF: any;
  CCO_NUMREF: string;
  ANO_CODANO: string;
  MES_CODMES: string;
  SCO_CODSCO: string;
  LDC_CORLDC: string;
  SCO_CODASI: any;
  LDC_CORASI: any;
  CCO_FECREG: Date;
  AGE_CODAGE: any;
  CCO_ABOTRA: number;
  CCO_CODANT: any;
  CCO_INDSTA: string;
  CCO_FECUPD: Date;
  CCO_CODUSE: string;
  CCO_IMPRET: number;
  CCO_ABORET: number;
  CCO_RETGAS: number;
  IRI_CODIRI: any;
  SUC_CODSUC: string;
  TGA_CODTGA: any;
  CCO_FECREF: any;
  OCM_NUMOCM: any;
  cco_numcuo: number;
  ipi_codipi: any;
  CCO_IMPPER: number;
  CCO_PERMNA: number;
  cco_movil: any;
  cco_cbcorr: any;
  cco_cbmoti: any;
  cco_rbcorr: any;
  cco_condic: any;
  cco_code: any;
  cco_impisc: number;
  cco_impexo: number;
  cco_impotc: number;
  cli_numruc: string;
  docu_dcc: DocuDCC;
  detalle_dcd: DetalleDcd[];
  cobranza_dcb: any[];
  adelanto_cav: any[];
  cuotas_ccu: CuotasCCU[];
  generaParteAlmacen: boolean;

  constructor() {
    this.detalle_dcd = [];
    this.cobranza_dcb = [];
    this.adelanto_cav = [];
    this.cuotas_ccu = [];
    this.docu_dcc = new DocuDCC();

    this.TMO_CODTMO = "SO";
    this.MMO_CODMMO = "0206";
    this.SCO_CODSCO = "0501";
    this.CCO_FECUPD = new Date();
    this.CCO_NUMREF = '';

    this.CCO_INDTCA = "0";

    this.CCO_INDSTA = "1";
    this.ANO_CODANO = ((new Date()).getFullYear()).toString();
    let mes = ((new Date()).getMonth() + 1).toString();
    this.MES_CODMES = (mes.length == 1) ? "0" + mes : mes;
    this.cco_numcuo = 0;
    this.CCO_FECREG = new Date();
    this.SUC_CODSUC = "01";
    this.CCO_GLOCCO = "";
    // this.PCC_PERFIN = 0;
    this.CCO_TASIGV = 18;
    this.CCO_TIPCAM = 0;
    this.CCO_VALVTA = 0;
    this.CCO_IMPIGV = 0;
    this.CCO_IMPDOC = 0;
    this.CCO_IMPSOLES = 0;
    this.CCO_IMPDOLAR = 0;
    this.TDO_CODTDO = "FAC";
    this.CPA_CODCPA = "112";
    this.CCO_CODPAG = "C";
  }

  static generarFacturaDesdePedido(pedido: Order): Factura {
    const factura = new Factura();

    factura.CCO_FECVEN = pedido.PCC_FECDOC;
    factura.CCO_FECREG = pedido.PCC_FECCRE;
    factura.CCO_FECUPD = pedido.PCC_FECUPD;
    factura.CCO_CODUSE = pedido.PCC_CODUSE;
    factura.CCO_CODANT = pedido.CCO_CODANT;
    factura.MMO_CODMMO = pedido.MMO_CODMMO;
    factura.CCO_TASIGV = pedido.PCC_TASIGV;
    factura.docu_dcc.VDE_CODVDE = pedido.VDE_CODVDE;
    factura.CLI_CODCLI = pedido.CLI_CODCLI;
    factura.TDO_CODTDO = pedido.TDO_CODTDO;
    factura.TMO_CODTMO = pedido.TMO_CODTMO;
    factura.CCO_CODPAG = pedido.CPA_CODCPA;
    factura.docu_dcc.ALM_CODALM = pedido.ALM_CODALM;
    factura.docu_dcc.LDE_CODLDE = pedido.LDE_CODLDE;
    factura.CCO_GLOCCO = pedido.PCC_OBSPCC;
    factura.docu_dcc.DCC_IMPBRU = pedido.PCC_IMPBRU;
    factura.docu_dcc.DCC_IMPDES = pedido.PCC_IMPDES;
    factura.CCO_VALVTA = pedido.PCC_VALVTA;
    factura.CCO_IMPIGV = pedido.PCC_IMPIGV;
    factura.CCO_IMPDOC = pedido.PCC_IMPTOT;


    pedido.detalle_pcd.forEach(item => {
      const itemDetalle = new DetalleDcd();
      itemDetalle.PRD_CODPRD = item.PRD_CODPRD;
      itemDetalle.DCD_CORDCD = item.PCD_CORPCD;
      itemDetalle.UME_CODVEN = item.UME_CODVEN;
      itemDetalle.DCD_CANDCD = item.PCD_CANSOL;
      itemDetalle.DCD_PREUNI = item.PCD_PREUNI;
      itemDetalle.DCD_PRUIGV = item.PCD_PRUIGV;
      itemDetalle.DCD_IMPBRU = item.PCD_IMPBRU;
      itemDetalle.DCD_PORDES = item.PCD_PORDES;
      itemDetalle.DCD_IMPDES = item.PCD_IMPDES;
      itemDetalle.DCD_VALVTA = item.PCD_VALVTA;
      itemDetalle.DCD_IMPIGV = item.PCD_IMPIGV;
      itemDetalle.DCD_IMPTOT = item.PCD_IMPTOT;
      itemDetalle.DCD_GLODCD = item.PCD_GLOPCD;

      factura.detalle_dcd.push(itemDetalle)
    });

    return factura;
  }
}

export class DetalleDcd {
  CIA_CODCIA: string;
  CCO_CODCCO: string;
  PRD_CODPRD: string;
  DCD_SECDCD: string;
  DCD_CORDCD: string;
  DCD_CANDCD: number;
  DCD_PREUNI: number;
  DCD_PRUIGV: number;
  DCD_IMPBRU: number;
  DCD_PORDES: number;
  DCD_IMPDES: number;
  DCD_VALVTA: number;
  DCD_IMPIGV: number;
  DCD_IMPTOT: number;
  DCD_GLODCD: string;
  DCD_OBSDES: any;
  PAR_CODPAR: any;
  DCD_CODTES: any;
  DCD_TIPPRE: any;
  SRV_CODSRV: any;
  DCM_CODDCM: any;
  DCD_CANDES: number;
  DCD_CANREG: number;
  DCD_PREPED: number;
  DCD_PRUPED: number;
  DCD_CANPED: number;
  DCD_VALDIF: number;
  DCD_IMPDIF: number;
  DCD_USUPRE: any;
  DCD_FECPRE: any;
  DCD_CODUSE: string;
  DCD_FECUPD: Date;
  DCD_CANVEN: number;
  UME_CODVEN: string;
  DCD_INDADE: number;
  PRM_CODPRM: any;
  prec: any;
  DCD_DESGLO: number;
  DCD_GLOESP: any;
  DCD_TASPER: number;
  DCD_IMPPER: number;
  DCD_CANDEV: number;
  DCD_VENDEV: number;
  DCD_INTPRD: any;
  DCD_INTDES: any;
  dcd_gasmnp: any;
  dcd_gasmep: any;
  dcd_pordis: number;
  dcd_disgen: number;
  dcd_indina: number;
  dcd_tasigv: number;
  descuento_ddd: DescuentoDDD[];
  talla_dct: any[];
  kardex_dck: any[];
  seleccionado: any;
  producto: any;

  constructor() {

    this.DCD_SECDCD = '01';
    this.DCD_CANVEN = 0;
    this.DCD_CANDES = 0;
    this.DCD_FECUPD = new Date();
    this.DCD_PRUIGV = 0;
    // this.DCD_CANDCD = null;
    this.DCD_IMPDES = 0;
    this.DCD_PORDES = 0;
    this.DCD_PREUNI = 0;

    this.descuento_ddd = [];
    this.talla_dct = [];

  }
}

export class DescuentoDDD {
  cia_codcia: string;
  cco_codcco: string;
  prd_codprd: string;
  dcd_secdcd: string;
  dco_coddco: string;
  ddd_corddd: string;
  ddd_pordes: number;
  ddd_impdes: number;
  ddd_impaju: number;
  ddd_incdes: number;
  dcm_numdcm: any;
  ddd_fecupd: Date;
  ddd_coduse: string;

  constructor() {
    this.ddd_fecupd = new Date();
    this.ddd_pordes = 0;
    this.ddd_impdes = 0;
    // this.ddd_indsta = '1';
  }
}

export class TallaDCT {
  cia_codcia: string;
  cco_codcco: string;
  prd_codprd: string;
  dcd_secdcd: string;
  tal_codtal: string;
  dct_candct: string;
  dcd_cordcd: string;
  dct_fecupd: Date;
  dct_coduse: string;

  constructor() {

    this.dct_fecupd = new Date();


  }
}

export class DocuDCC {
  CIA_CODCIA: string;
  CCO_CODCCO: string;
  CLI_CODAVA: any;
  CLI_CODAV1: any;
  DCC_INDCON: any;
  DCC_NUMOPE: any;
  DCC_TASISC: number;
  DCC_IMPISC: number;
  VDE_CODVDE: string;
  BCO_CODBCO: any;
  DCC_DOCDCC: any;
  CBA_CODCBA: any;
  DCC_INDCAL: string;
  DCC_IMPBRU: number | string;
  DCC_PORDES: number;
  DCC_IMPDES: number | string;
  DCC_SITDCC: any;
  DCC_ORDCOM: string;
  DCC_INDTEX: string;
  SUC_CODSUC: string;
  PCC_NUMPCC: string;
  DCC_VENDIF: any;
  DCC_IMPSEG: number;
  DCC_IMPFLE: number;
  DCC_INDEXP: number;
  ZVE_CODZVE: string;
  DCC_OBSFAC: string;
  EDO_CODEDO: string;
  DCC_NUMCUO: number;
  CCS_CODCCS: string;
  TLP_CODTLP: string;
  LPC_CODLPC: any;
  DCC_INDTCA: string;
  DCC_INDIMP: number;
  DCC_INDCOM: number;
  DCC_INDDPX: number;
  EGV_NUMEGV: number;
  DCC_FECDES: any;
  TIE_CODTIE: string;
  DCC_FECENT: Date;
  PAI_CODVEN: string;
  UBI_CODVEN: string;
  DCC_ATEFAC: number;
  DCC_DIAPLA: number;
  DCC_FECACE: any;
  MAU_CODPED: any;
  DCC_VALDIF: number;
  DCC_IMPDIF: number;
  CCO_CODOTR: any;
  DCC_INDPSA: number;
  ALM_CODALM: string;
  SDO_CODSDO: any;
  DCC_TCALIB: number;
  DCC_IMPABO: number;
  DCC_IMPVUE: number;
  DCC_INDSTA: string;
  TBD_NUMTBD: any;
  DCC_INDFGI: number;
  DCC_INDSER: number;
  DCC_PORCOM: number;
  DCC_IMPCOM: number;
  DCC_COMABO: number;
  DCC_COMABA: number;
  DCC_SALCOM: number;
  DCC_FECUPD: any;
  DCC_CODUSE: string;
  LDE_CODLDE: any;
  CCO_CODPLA: any;
  CCN_CUELBA: any;
  LDE_CODDES: any;
  DCC_VTAAJU: number;
  DCC_IGVAJU: number;
  DCC_TOTAJU: number;
  DCC_OBSANU: any;
  DCC_INDENT: number;
  DCC_ENTDES: number;
  UBD_CODUBD: any;
  SIT_CODSIT: string;
  DCC_INCOB: string;
  DCC_CODCON: any;
  DCC_DESGLO: number;
  DCC_NROBAN: any;
  lcc_codlcc: any;
  sdc_codsdc: any;
  dcc_tiplet: any;
  DCC_IMPANT: number;
  dcc_tdoref: any;
  dcc_nroref: any;
  tim_codtim: string;
  iri_codiri: any;
  dcc_tasdet: any;
  dcc_impdet: any;
  dcc_antbru: number;
  t59_codt59: any;
  dcc_fecwsp: null
  dcc_expnav: null
  dcc_expcon: null
  dcc_explin: null
  dcc_expfel: null
  dcc_expfee: null
  dcc_exppue: null
  dcc_exppud: null
  dcc_expnbl: null
  dcc_idioma: null
  tpa_codtpa: null

  constructor() {
    this.DCC_INDCAL = '1';
    this.TLP_CODTLP = '01'

    this.DCC_IMPBRU = 0;
    this.DCC_IMPDES = 0;

  }
}

export class CuotasCCU {
  CIA_CODCIA: string;
  CCO_CODCCO: string;
  CCU_CANDIA: BINARY_TYPE;
  CCU_FECSUG: Date;
  CCU_INDSTA: string;
  CCU_CODUSE: string;
  CCU_FECUPD: Date;
  CCU_CORCCU: string;
  CCU_IMPCCU: number;

  constructor() {
    this.CCU_FECSUG = new Date();
    this.CCU_FECUPD = new Date();
    this.CCU_IMPCCU = 0;
    this.CCU_INDSTA = '1';
  }
}
