
<app-pavso-title-section [title]="'Gráfico costo unitario productor día'" [module]="'Costos'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <mat-card class="mat-elevation-z0">
    <mat-card-header>
      <mat-card-title>Reporte Gráfico Costo Unitario Producto Día</mat-card-title>
      <mat-card-subtitle>PERÍODO: 2019-12</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

    </mat-card-content>
  </mat-card>

</div>
