import { NgModule } from "@angular/core";
import { ButtonAddItemTableComponent } from "./Buttons/button-add-item-table/button-add-item-table.component";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "src/app/material.module";
import { LayoutModule } from "@angular/cdk/layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ButtonRemoveItemTableComponent } from "./Buttons/button-remove-item-table/button-remove-item-table.component";
import { ButtonInsertItemTableComponent } from "./Buttons/button-insert-item-table/button-insert-item-table.component";

@NgModule({
  declarations: [
    ButtonAddItemTableComponent,
    ButtonRemoveItemTableComponent,
    ButtonInsertItemTableComponent
  ],
  exports: [
    ButtonAddItemTableComponent,
    ButtonRemoveItemTableComponent,
    ButtonInsertItemTableComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    LayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

  ],
})
export class GeneralComponentsModule { }
