import { Component, OnDestroy, OnInit } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-informes-composicion',
  templateUrl: './informes-composicion.component.html',
  styleUrls: ['./informes-composicion.component.css']
})
export class InformesComposicionComponent implements OnInit, OnDestroy {

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;

  constructor(
    private _authService: AuthenticationService,
    private store: Store<PavsoState>
  ) {

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([this.loading$])
  }

}
