import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { CustomerAddComponent } from "@shared/components/dialogs/customer-add/customer-add.component";
import { EstablishmentAddComponent } from "@shared/components/dialogs/establishment-add/establishment-add.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Observable, Subject, Subscription } from "rxjs";
import { Contact, Establishment } from "src/app/models";
import { ServiceSupplier } from "src/app/models/compras/provider";
import { Cliente, DetalleContacto, DetalleLugarDespacho, DetalleTipserProv } from "src/app/models/ventas/customer";
import { AuthenticationService, ConfigurationService, VentasService } from "src/app/services";

@Component({
  selector: 'cliente-crm-form',
  templateUrl: './cliente-crm-form.component.html',
  styleUrls: ['./cliente-crm-form.component.scss']
})
export class ClienteCrmFormComponent implements OnInit, OnDestroy{

  loaderReg: boolean = false;
  loaderData: boolean = false;
  cliente: Cliente;

  displayedColumnsServicios: string[] = ['acciones', 'codigo'];
  dataSourceServicios: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorServicios: MatPaginator;
  @ViewChild(MatSort) sortServicios: MatSort;

  displayedColumnsContactos: string[] = ['acciones', 'codigo', 'nombre', 'cargo', 'telefono', 'correo', 'fecha_nacimiento', 'referencia', 'cobranza'];
  dataSourceContactos: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorContactos: MatPaginator;
  @ViewChild(MatSort) sortContactos: MatSort;

  displayedColumnsEstablecimientos: string[] = ['acciones', 'codigo', 'principal', 'direccion', 'referencia', 'pais', 'ubicacion', 'zona'];
  dataSourceEstablecimientos: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorEstablecimientos: MatPaginator;
  @ViewChild(MatSort) sortEstablecimientos: MatSort;

  /** filtrar vendedores */
  public vendedores: any[] = [];
  // public vendedorCtrl: FormControl = new FormControl();
  // public vendedorFilterCtrl: FormControl = new FormControl();
  // public filteredVendedores: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  // @ViewChild('vendedorSelect', { static: true }) vendedorSelect: MatSelect;

  /** filtrar condicion pago */
  public condiciones: any[] = [];
  // public condicionCtrl: FormControl = new FormControl();
  // public condicionFilterCtrl: FormControl = new FormControl();
  // public filteredCondiciones: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  // @ViewChild('condicionSelect', { static: true }) condicionSelect: MatSelect;

  /** filtrar zonas */
  public zonas: any[] = [];

  /** filtrar paises */
  public paises: any[] = [];

  /** filtrar ubicaciones */
  public ubicaciones: any[] = [];

  public giros: any[] = [];

  public servicios: any[] = [];

  public ubicacionesSunat: any[] = [];

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  // tables
  contactos: Contact[] = [];
  establecimientos: Establishment[] = [];

  tiposDeLista: any[] = [];
  monedas: any[] = [];
  canales: any[] = [];

  tiposDePersona: any[] = [];
  tiposDeDocumento: any[] = [];
  sectoristas: any[] = [];
  productosComprobante: any[] = [];
  calificaciones: any[] = [];
  sucursales: any[] = [];
  cargos: any[] = [];

  serviciosTable: any[] = [];

  paisc = "01";

  apepaterno = "";
  apematerno = "";
  nombres = "";
  razonsocial = "";

  ruc: any;
  dni: any;

  isNatural = false;
  isRuc = false;

  totalTranscript?: string;

  transcript$?: Observable<string>;
  listening$?: Observable<boolean>;
  errorMessage$?: Observable<string>;
  defaultError$ = new Subject<string | undefined>();

  @ViewChild('sucursalSelect') sucursalSelect: MatSelect;

  loading$: Subscription;
  dataForm$: Subscription;
  cliente$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string = 'general.button.store';

  uid: string;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store<PavsoState>
  ) {
    this.cliente = new Cliente();
    this.cliente.cli_coduse = this._authService.getUsuarioSistema();

    this.cliente.dato_clc[0].codcia = this._configurationService.obtenerCompaniaCliente()

    this.dataSourceServicios = fillTable([], this.paginatorServicios, this.sortServicios);

    this.dataSourceContactos = fillTable([], this.paginatorContactos, this.sortContactos);

    this.dataSourceEstablecimientos = fillTable([], this.paginatorEstablecimientos, this.sortEstablecimientos);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({id}) => this.uid = id)

    if(this.uid == '0') {
      this.btnName = 'general.button.store';
    } else {
      this.btnName = 'general.button.update';
    }

    this.loadMaestros();

  }

  loadMaestros(): void {
    this.loaderData = true;

    this.dataForm$ = this._ventasService.obtenerDataFormCliente().subscribe(
      data => {
        console.log('data', data)

        this.loaderData = false;

        this.vendedores = data.vendedores;
        this.condiciones = data.condiciones;
        this.tiposDeLista = data.tiposPrecio;
        this.zonas = data.zonasVenta;
        this.canales = data.segmentos;
        this.tiposDePersona = data.tiposPersona;
        this.tiposDeDocumento = data.tiposDocumentoIdentidad;
        this.paises = data.paises;
        this.ubicaciones = data.ubicaciones;
        this.giros = data.girosNegocio;
        this.sectoristas = data.sectoristas;
        this.productosComprobante = data.productosComprobante;
        this.calificaciones = data.calificaciones;
        this.servicios = data.tiposServicioProveedor;
        this.sucursales = data.sucursales;
        this.cargos = data.cargos;

        if(this.uid != '0') this.obtenerCliente();
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  obtenerCliente(): void {

    this.loaderData = false;
    // this._apiCrmClienteService.obtenerClientesCRM().subscribe
    this.cliente$ = this._ventasService.obtenerClienteComercial(this.uid).subscribe(
      response => {
        console.log('===== CLIENTE =====', response)
        this.cliente = response;

        console.log('cliente ubi_codubi', this.cliente.ubi_codubi)
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )

  }

  validarRuc(): void {
    let rucStr = this.ruc.toString();

    if(rucStr.length > 11) this.ruc = rucStr.substr(0, rucStr.length - 1);

  }

  registrarCliente(f: NgForm): void {

    console.log('cliente', this.cliente)

    this.loaderReg = true;

    this.cliente.detalle_contacto.forEach((element, key) => {
      element.cli_codcli = this.cliente.cli_codcli;
      element.ccl_codccl = `${key + 1}`;
      // (element.ccl_codccl < 10)? element.ccl_codccl = `0${element.ccl_codccl}`:  element.ccl_codccl = element.ccl_codccl.toString()
    });

    this.cliente.detalle_lugar_despacho.forEach((element, key) => {
      element.VDE_CODVDE = this.cliente.dato_clc[0].codvde;
      element.TCC_CODTCC = this.cliente.dato_clc[0].codtcc;
      element.SUC_CODSUC = this.cliente.suc_codsuc;

      element.CLI_CODCLI = this.cliente.cli_codcli;
      element.LDE_CODLDE = `${key + 1}`;
      // (element.LDE_CODLDE < 10)? element.LDE_CODLDE = `000${element.LDE_CODLDE}`:  element.LDE_CODLDE = `00${element.LDE_CODLDE}`
    })

    this.cliente.detalle_tipser_prov.forEach(element => {
      element.cli_codcli = this.cliente.cli_codcli;
    })

    this.cliente.dato_clc[0].codcli = this.cliente.cli_codcli;


    if(this.cliente.cli_tipper == "J") {
      this.cliente.cli_nomcli = this.razonsocial;
      this.cliente.cli_numruc = this.ruc;


      if((this.ruc.toString())[0] != "2" || (this.ruc.toString())[1] != "0") {
        this._snackBarService.showError('Establecer correctamente el RUC', 'OK');
        this.loaderReg = false;
        return;
      }

    } else {
      this.cliente.cli_nomcli = `${this.cliente.cli_apepat} ${this.cliente.cli_apemat} ${this.cliente.cli_nombre}`;
      this.cliente.cli_numdni = this.dni;

    }

    this.cliente.cli_inddom = (this.cliente.pai_codpai == "01") ? 1: 0;


    (this.cliente.dato_clc[0].inoped)? this.cliente.dato_clc[0].inoped = 1: this.cliente.dato_clc[0].inoped = 0;

    this._ventasService.agregarClienteComercial(this.cliente).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Cliente Registrado', '300px', '', '');

        f.resetForm();
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK')
      }
    )
  }

  protected filterCondiciones(): void {
    // this.filteredCondiciones.next(
    //   filterMultiCustom(this.condiciones, this.condicionFilterCtrl.value, 'cpa_descri')
    // )
  }

  ingresoCodigo(value): void {
    if(this.cliente.cli_tipper == "J") {
      if(this.cliente.did_coddid == "001") {
        if(value.target.value.length == 11) {
          this.ruc = value.target.value;
        }
      }
      return;
    }

    this.dni = value.target.value;

  }

  seleccionarTipoDocumento(value): void {
    if(value == "001") {
      this.isRuc = false;
      this.cliente.cli_tipper = "J";
      this.isNatural = false;
      return;
    }

    this.isRuc = true;
    this.cliente.cli_tipper = "N";
    this.isNatural = true;

  }

  seleccionarUbicacion(value): void {

    this._ventasService.listarUbicacionSunat(value).subscribe(
      response => {
        this.ubicacionesSunat = response;
        this.cliente.ubs_codubs = response[0].ubs_codubs;
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  agregarServicio(): void {

    let item = new DetalleTipserProv();

    this.cliente.detalle_tipser_prov.push(item);

    this.cliente.detalle_tipser_prov.forEach((element, key) => {
      element['correlativo'] = key;
    })

    this.dataSourceServicios = fillTable(this.cliente.detalle_tipser_prov, this.paginatorServicios, this.sortServicios);
  }

  editarServicio(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarServicio(row: ServiceSupplier): void {
    if(!row.tse_codtse ) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarServicio(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceServicios.data.filter(item => item.correlativo != row.correlativo);
        filtered.forEach((element, key) => {
          element['correlativo'] = key;
        })
        this.cliente.detalle_tipser_prov = filtered;
        this.dataSourceServicios = fillTable(filtered, this.paginatorServicios, this.sortServicios);
      }
    })
  }

  agregarContacto(): void {

    let item = new DetalleContacto();

    this.cliente.detalle_contacto.push(item);

    this.cliente.detalle_contacto.forEach((element, key) => {
      element['ccl_codccl'] = key.toString();
    })

    this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
  }

  editarContacto(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarContacto(row): void {
    if(!row.ccl_nomccl || !row.cca_codcca || !row.ccl_numtlf || !row.ccl_corele || !row.ccl_cumple || !row.ccl_glosa) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;
  }

  quitarContacto(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceContactos.data.filter(item => item.ccl_codccl != row.ccl_codccl);
        filtered.forEach((element, key) => {
          element['ccl_codccl'] = key;
        })
        this.cliente.detalle_contacto = filtered;
        this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
      }
    })
  }

  agregarEstablecimiento(): void {

    let item = new DetalleLugarDespacho();

    this.cliente.detalle_lugar_despacho.push(item);

    this.cliente.detalle_lugar_despacho.forEach((element, key) => {
      element['LDE_CODLDE'] = `${key + 1}`;
    })

    this.dataSourceEstablecimientos = fillTable(this.cliente.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
  }

  editarEstablecimiento(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarEstablecimiento(row): void {
    if(!row.LDE_CODLDE || !row.LDE_DIRLDE || !row.LDE_REFDIR || !row.PAI_CODPAI || !row.UBI_CODUBI || !row.ZVE_CODZVE) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarEstablecimiento(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        const filtered = this.dataSourceEstablecimientos.data.filter(item => item.LDE_CODLDE != row.LDE_CODLDE);
        filtered.forEach((element, key) => {
          element['LDE_CODLDE'] = key;
        })
        this.cliente.detalle_lugar_despacho = filtered;
        this.dataSourceEstablecimientos = fillTable(this.cliente.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
      }
    })
  }

  seleccionarServicio($event, row: ServiceSupplier): void {
    const servicio = this.servicios.find(item => item.TSE_CODTSE == $event.value);
    row.tse_descri = servicio.TSE_DESTSE;
  }

  seleccionarCargo($event, row: Contact): void {
    const cargo = this.cargos.find(item => item.codnca == $event.value);
    row.cca_descri = cargo.descri;
  }

  seleccionarPais($event, row: Establishment): void {
    const establecimiento = this.paises.find(item => item.PAI_CODPAI == $event.value);
    row.PAI_NOMLAR = establecimiento.PAI_NOMLAR;
  }

  seleccionarUbicacionTabla($event, row: Establishment): void {
    const ubicacion = this.ubicaciones.find(item => item.codubi == $event.value);
    row.UBI_DESUBI = ubicacion.desubi;
  }

  seleccionarZona($event, row: Establishment): void {
    const zona = this.zonas.find(item => item.zve_codzve == $event.value);
    row.ZVE_DESZVE = zona.zve_nomzve;
  }

  agregarContacto2(): void {
    const dialogRef = this.dialog.open(CustomerAddComponent, {
      width: '700px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.cliente.detalle_contacto.push(result);
        this.cliente.detalle_contacto.forEach((element, key) => {
          element.ccl_codccl = `${key + 1}`;
          // (element.ccl_codccl < 10)? element.ccl_codccl = `0${element.ccl_codccl}`:  element.ccl_codccl = element.ccl_codccl.toString()
        });
        this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
      }
    })
  }

  eliminarContacto(row): void {

    let contacts = [];

    this.cliente.detalle_contacto.forEach(element => {
      if(element.cli_codcli != row.cli_codcli) {
        contacts.push(element)
      }
    });

    this.cliente.detalle_contacto = contacts;

    this.dataSourceContactos = fillTable(this.cliente.detalle_contacto, this.paginatorContactos, this.sortContactos);
  }

  agregarEstablecimiento2(): void {
    const dialogRef = this.dialog.open(EstablishmentAddComponent, {
      width: '700px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.cliente.detalle_lugar_despacho.push(result);
        this.cliente.detalle_lugar_despacho.forEach((element, key) => {
          element.LDE_CODLDE = `${key + 1}`;
          // (element.LDE_CODLDE < 10)? element.LDE_CODLDE = `000${element.LDE_CODLDE}`:  element.LDE_CODLDE = `00${element.LDE_CODLDE}`
        })
        this.dataSourceEstablecimientos = fillTable(this.cliente.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
      }
    })
  }

  eliminarEstablecimiento(row: Establishment): void {
    let establishments = [];

    this.cliente.detalle_lugar_despacho.forEach(element => {
      if(element.LDE_CODLDE != row.LDE_CODLDE) {
        establishments.push(element)
      }
    });

    this.cliente.detalle_lugar_despacho = establishments;

    this.dataSourceEstablecimientos = fillTable(this.cliente.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
  }

  agregarServicio2(): void {
    let servicio = new ServiceSupplier();
    servicio.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    servicio.cli_codcli = this.cliente.cli_codcli;
    servicio.pts_indsta = "";
    servicio.pts_coduse = "";
    servicio.pts_fecupd = new Date();

    let existe = false;

    if(existe) {
      this._snackBarService.showError('Este servicio ya ha sido agregado', 'OK');
    } else {

      this.dataSourceServicios = fillTable(this.cliente.detalle_tipser_prov, this.paginatorServicios, this.sortServicios);
    }
  }

  seleccionarTipoPersona(valor): void {

  }

  eliminarServicio(row): void {
    let services = [];

    this.cliente.detalle_tipser_prov.forEach(element => {
      if (element.tse_codtse != row.tse_codtse) services.push(element)

    });

    this.cliente.detalle_tipser_prov = services;
    this.dataSourceServicios = fillTable(this.cliente.detalle_tipser_prov, this.paginatorServicios, this.sortServicios);
  }

  volver(): void {
    this._router.navigate(['/modulo-crm/clientes-crm']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.dataForm$,
      this.cliente$
    ])
  }

}
