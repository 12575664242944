
import { HttpErrorResponse } from "@angular/common/http";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Observable, Subscription } from "rxjs";

let array$: Subscription[] = [];

/**
 * @params array de observables
 *
 * @return array con los resultados de los observables en orden de ejecución
 ** Ej. forkObs(
 **[observable1, observable2, observable3]
 ** ).then(
 **   response => console.log(response)
 ** ) // [suscripcion1,suscripcion2,suscripcion3]
 */
export function forkObs(...array: Observable<any>[]): Promise<any[]> {
  return new Promise((resolve, reject) => {
    // Utiliza recursividad para resolver el array de observables
    // let array$: Array<Subscription> = [];
    function ejecutar(array: Observable<any>[], index: number, resultado: any[]) {
      if (index < array.length) {
        const sub = array[index].subscribe(
          response => {
            resultado.push(response);
            sub.unsubscribe();
            ejecutar(array, index + 1, resultado);
          },
          error => {
            resultado.push(error)
            // reject(error);
          }
        );
        array$.push(sub);
      } else {

        // unsubscribeSubscription(array$);
        resolve(resultado);

      }
    }

    ejecutar(array, 0, []);
  });
}
