import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_NOMINA_AFP } from '@data/constants/table/theader.nomina';
import { ApiNominaMaestrosAfpService } from 'src/app/services/api/nomina/maestros/nomina.maestros.afp.service';

@Component({
  selector: 'app-afp-list',
  templateUrl: './afp-list.component.html',
  styleUrls: ['./afp-list.component.css']
})
export class AfpListComponent implements OnInit, OnDestroy {

  nomina$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_NOMINA_AFP;

  afps: Array<any> = [];

  constructor(
    private _apiAfpNominaService: ApiNominaMaestrosAfpService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) {
        this.obtenerAfps();
      }
    })
  }

  obtenerAfps(): void {
    this.nomina$ = this._apiAfpNominaService.listarAFP().subscribe(
      response => this.afps = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.nomina$,
      this.loading$
    ])
  }

}
