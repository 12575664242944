import { codificacionProductoClienteDetalle } from "./codificacion-producto-cliente-detalle";

export class codificacionProductoCliente{
    cia_codcia: string; // Código de compañía - char(2) - NO
    cli_codcli: string; // Código de cliente - char(12) - NO
    pca_indsta: number; // Indicador de estado - smallint - YES
    pca_coduse: string; // Código de usuario - varchar(30) - YES
    pca_fecupd: Date; // Fecha de actualización - datetime - YES
    detalle: Array<codificacionProductoClienteDetalle>;

    constructor(){
        this.pca_indsta = 1;
        this.pca_fecupd = new Date();
    }
}
