import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { LANGUAGE_CRM_PRESUPUESTO_REPORT_EN, LANGUAGE_CRM_PRESUPUESTO_REPORT_ES } from "@shared/resources/language.crm";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { generarPDFPresupuesto } from "@utils/pdfmaker/builder/crm.pdfmaker";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ConfigurationService } from "src/app/services";
import { ApiCRMOperacionesPresupuestoService } from "src/app/services/api/crm/operaciones/crm.operaciones.presupuesto.service";

@Component({
  selector: 'presupuesto-list',
  templateUrl: './presupuesto-list.component.html',
  styleUrls: ['./presupuesto-list.component.scss']
})
export class PresupuestoListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'idtkt', 'idset', 'numopc', 'obsopc'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  presupuestos$: Subscription;
  loading$: Subscription;

  idtkt: string;
  idset: string;

  constructor(
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _apiPresupuestoCrmService: ApiCRMOperacionesPresupuestoService,
    public dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({idtkt, idset}) => {
      this.idtkt = idtkt;
      this.idset = idset;

      this.obtenerPresupuestos();
    })
  }

  obtenerPresupuestos(): void {
    this.presupuestos$ = this._apiPresupuestoCrmService.obtenerPresupuestos().subscribe(
      presupuestos => this.dataSource = fillTable(presupuestos, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  generarPDF(): void {
    const headers = {
      hour: getHourMinuteSecondsCurrent(new Date()),
      date: formatDateWithSlash(new Date()),
      coduse: this._configurationService.obtenerIdUsuario()
    }

    const language = this._configurationService.obtenerIdioma();

    let languageJSON = LANGUAGE_CRM_PRESUPUESTO_REPORT_ES;

    if(language == 'EN') languageJSON = LANGUAGE_CRM_PRESUPUESTO_REPORT_EN;

    generarPDFPresupuesto(headers, {}, languageJSON);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.presupuestos$,
      this.loading$
    ]);
  }

}
