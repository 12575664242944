import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ActivoFijoService } from 'src/app/services/api/activo-fijo/maestros/activo-fijo.service';
import { GrupoActivoService } from 'src/app/services/api/activo-fijo/maestros/grupo-activo.service';
import { TipoActivoService } from 'src/app/services/api/activo-fijo/maestros/tipo-activo.service';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';

@Component({
  selector: 'app-activo-fijo-form',
  templateUrl: './activo-fijo-form.component.html',
  styleUrls: ['./activo-fijo-form.component.css']
})
export class ActivoFijoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumnsFinanciero: string[] = ['acciones', 'anio', 'valor_soles', 'valor_dolares', 'depre_soles', 'depre_dolares', 'valor_merc_soles', 'valor_merc_dolar'];
  dataSourceFinanciero: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorFinanciero: MatPaginator;
  @ViewChild(MatSort) sortFinanciero: MatSort;

  displayedColumnsTributario: string[] = ['acciones', 'anio', 'valor_soles', 'valor_dolares', 'depre_soles', 'depre_dolares', 'valor_merc_soles', 'valor_merc_dolar'];
  dataSourceTributario: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorTributario: MatPaginator;
  @ViewChild(MatSort) sortTributario: MatSort;

  displayedColumnsDocumento: string[] = ['acciones', 'item', 'tipo_doc', 'descripcion', 'referencia', 'fec_ingreso', 'fec_vigencia', 'fec_vcto', 'estado', 'adj', 'ver'];
  dataSourceDocumento: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDocumento: MatPaginator;
  @ViewChild(MatSort) sortDocumento: MatSort;

  files: File[] = [];

  loadUpload: boolean = false;

  proveedores: any[] = [];
  monedas: any[] = [];
  centros: any[] = [];
  paises: any[] = [];

  tiposActivo: any[] = [];
  gruposAtivo: any[] = [];

  activoFijo$: Subscription;
  grupo$: Subscription;
  tipo$: Subscription;
  loading$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  usuario: string;
  fecha: Date;
  uid: string;

  btnName: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _activoFijoService: ActivoFijoService,
    private _grupoActivoService: GrupoActivoService,
    private _tipoActivoService: TipoActivoService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {

    this.dataSourceFinanciero = fillTable([], this.paginatorFinanciero, this.sortFinanciero);

    this.dataSourceTributario = fillTable([], this.paginatorTributario, this.sortTributario);

    this.dataSourceDocumento = fillTable([], this.paginatorDocumento, this.sortDocumento);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.loadMaestros();
        if(id == '0') return;

        this.activoFijo$ = this._activoFijoService.listarActivosFijo().subscribe(
          activos => {
            // const activo = activos.find(item => item.codigo_activos == id);
          }
        )

      }
    )
  }

  loadMaestros(): void {
    this.loaderData = true;
    this.grupo$ = this._grupoActivoService.listarGruposActivo().subscribe(
      grupos => {
        this.gruposAtivo = grupos;

        this.tipo$ = this._tipoActivoService.listarTiposActivo().subscribe(
          tipos => {
            this.tiposActivo = tipos;
            this.loaderData = false;
          },
          error => {
            this._snackBarService.showError('Error al obtener tipos de activo', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener grupos de activo', 'Ok');
        this.loaderData = false;
      }
    )

  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarActivoFijo(f): this.actualizarActivoFijo(f);
  }

  registrarActivoFijo(f: NgForm): void {

  }

  actualizarActivoFijo(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-activo-fijo/maestro-activo-fijo']);
  }

  onSelect(event): void {
    this.loadUpload = true
    this.files.push(...event.addedFiles);

    this.files.forEach(element => {
      let reader = new FileReader;

      // let urlImageTemp = reader.readAsDataURL(element);

      reader.onloadend = () => {

      };
    });

    setTimeout(() => {
      this.loadUpload = false;

    }, 3000);

  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  prueba(event): void {
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.activoFijo$,
    ])

  }
}
