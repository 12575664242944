import { IProveedorSUNAT } from "@data/interfaces/modules/compras/proveedor-sunat.interface";
import { Proveedor } from "../models";

export class ProveedorMapper {

  constructor() {

  }

  static map(proveedor: IProveedorSUNAT): Proveedor {

    const proveedorPAVSO = new Proveedor();

    proveedorPAVSO.cli_codcli = proveedor.ruc;
    proveedorPAVSO.cli_numruc = proveedor.ruc;
    proveedorPAVSO.cli_nomcli = proveedor.razon;
    proveedorPAVSO.cli_dircli = proveedor.direccion;
    proveedorPAVSO.cli_numtlf = proveedor.telefono;
    proveedorPAVSO.pai_codpai = "01";
    proveedorPAVSO.cli_tipper = "J";
    proveedorPAVSO.cli_indsta = "1";
    proveedorPAVSO.did_coddid = "001";
    proveedorPAVSO.cli_doccom = "0";
    proveedorPAVSO.cli_indcli = 0;
    proveedorPAVSO.cli_indpro = 1;

    return proveedorPAVSO;
  }
}
