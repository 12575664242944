import { Component, OnDestroy, OnInit } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { AuthenticationService, ContabilidadService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subject, Subscription } from 'rxjs';
import { REPORTES_PLANILLA_DEPENDIENTES } from '@data/json/reportes';
import { HeaderReport } from '@data/interfaces/headers.report';
import { HeaderReportService } from 'src/app/services/reports/config/header-report.service';
import { NominaReportService } from 'src/app/services/reports/modules/nomina-report.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { FiltroReportePlanilla } from 'src/app/models/planilla/filtro-reporte-planilla';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-informes-dependientes',
  templateUrl: './informes-dependientes.component.html',
  styleUrls: ['./informes-dependientes.component.css']
})
export class InformesDependientesComponent implements OnInit, OnDestroy {

  loading: boolean = false;

  trabajadores: any[] = [];
  centros: any[] = [];
  generos: any[] = [];

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  year: string;
  month: string;

  desde1: number = 0;
  hasta1: number = 60;

  reportes: any[] = REPORTES_PLANILLA_DEPENDIENTES;

  reporteSeleccionado: any = [REPORTES_PLANILLA_DEPENDIENTES[0].id];

  mostrarFiltroAuxiliar: 0 | 1 = 0;
  mostrarFiltroCentro: 0 | 1 = 0;
  mostrarFiltroConcepto: 0 | 1 = 0;

  period$: Subscription;
  loading$: Subscription;
  colaboradores$: Subscription;
  conceptos$: Subscription;
  centros$: Subscription;
  sexos$: Subscription;
  indicadores$: Subscription;

  genero: string;

  filtro: FiltroReportePlanilla;

  constructor(
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private _headerReportService: HeaderReportService,
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService,
    private _nominaReportService: NominaReportService,
    private store: Store<PavsoState>
  ) {

    this.filtro = new FiltroReportePlanilla();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  /**
   * Obtiene los maestros de :
   * . colaboradores
   * . conceptos
   * . centros de costo
   * . sexos
   */
  loadData(): void {
    this.loading = true;

    forkObs(
      this._nominaService.listarColaboradores(),
      this._contabilidadService.listarCentroCostos(),
      this._nominaService.listarSexo()
    ).then(data => {
      this.trabajadores = data[0];
      this.centros = data[1];
      this.generos = data[2];
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      this._snackBarService.showError('Error al obtener maestros', 'Ok')
    })

  }

  verReporte(): void {
    this.loading = true;

    const headers: HeaderReport = this._headerReportService.obtenerCabecera()

    const payload = {
      desde1: this.desde1,
      hasta1: this.hasta1,
      centros: (this.filtro.centros)? this.filtro.centros: [],
      colaboradores: (this.filtro.colaboradores)? this.filtro.colaboradores: [],
      generos: (this.filtro.generos)? this.filtro.generos: []
    }

    this._nominaReportService.verReporteDependiente(this.reporteSeleccionado[0], headers, payload)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)
  }

  seleccionarReporte(event): void {
    this.reporteSeleccionado[0] = event.id;

    let indicador = "";
    switch (this.reporteSeleccionado[0]) {
      case "PLANILLA_DRES":
        this.mostrarFiltroAuxiliar = 0;
        this.mostrarFiltroCentro = 0;
        this.mostrarFiltroConcepto = 0;
        break;
      case "PLANILLA_DC":
        indicador = "51002"
        this.indicadores$ = this._contabilidadService.obtenerInidicadoresFiltro(indicador).subscribe(
          ([response]) => {
            if(response) {
              this.mostrarFiltroAuxiliar = response.ind_auxiliar;
              this.mostrarFiltroCentro = response.ind_centrocosto;
              this.mostrarFiltroConcepto = response.ind_conceptoplanilla;
            }
          }
        )
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.colaboradores$,
      this.conceptos$,
      this.centros$,
      this.sexos$,
      this.indicadores$
    ]);
  }
}
