export class LugarTrabajo {

  codcia: string;
  codpla: string;
  despla: string;
  indsta: string;
  fecupd: Date;
  coduse: string;

  constructor() {
    this.fecupd = new Date();
    this.indsta = '1';
  }
}
