export class OperacionFrecuente {
  cia_codcia: string;
  ano_codano: string;
  mes_codmes: string;
  sco_codsco: string;
  ldc_corldc: string;
  ofb_descri: string;
  ofb_coduse: string;
  ofb_fecupd: Date;
  ofb_indsta: string;

  constructor() {
    this.ofb_fecupd = new Date();
    this.ofb_indsta = '1';
  }
}
