export class ConfiguracionRemuneracion {

  codcia: string;
  codano: string;
  codmes: string;
  codnco: string;
  // codnut: string;
  cannut: number;
  factor: number;
  coduse: string;
  fecupd: Date;
  codnfc: string;
  indsta: string;
  NOMI_DEDU_DETA_NDD: Array<NomiDeduDetaNdd>;
  NOMI_REMU_BASE_NRB: Array<NomiRemuBaseNrb>;

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
    this.cannut = 0;

    this.NOMI_DEDU_DETA_NDD = [];
    this.NOMI_REMU_BASE_NRB = [];
  }

}

export class NomiDeduDetaNdd {
  codcia: string;
  codano: string;
  codmes: string;
  codnco: string;
  codnti: string;
  desnti: string;
  codccn: string;
  coduse: string;
  fecupd: Date;
  codcco: string;
  descco: string;
  codtra: string;
  destra: string;
  codliq: string;
  desliq: string;
  correlativo: number;
  isEditing: boolean;

  constructor() {
    this.fecupd = new Date();
    this.isEditing = true;
  }
}

export class NomiRemuBaseNrb {
  codcia: string;
  codano: string;
  codmes: string;
  codnco: string;
  codnrb: string;
  porrem: number;
  isEditing: boolean;
  correlativo: number;

  constructor() {
    this.isEditing = true;
  }
}
