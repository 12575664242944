export class GrupoFlujoCaja {
  CIA_CODCIA: string;
  CFU_CODCFU: string;
  CFU_DESCRI: string;
  CFU_INDSTA: string;
  CFU_CODUSE: string;
  CFU_FECUPD: Date;
  CCN_CODCCN: string;
  CCN_CODREL: string;

  constructor() {
    this.CFU_INDSTA = "1";
    this.CFU_FECUPD = new Date();
  }
}
