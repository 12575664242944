<app-pavso-title-section [title]="'Subdiario contable'" [module]="'Contabilidad'" [usuario]="subdiario.sco_coduse"
  [fecha]="subdiario.sco_fecupd" [ngClass]="'pav-form'" [estado]="subdiario.sco_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="subdiario.sco_codsco" matInput placeholder="Código" name="sco_codsco" required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput placeholder="Descripción" name="sco_descri" [(ngModel)]="subdiario.sco_descri">
          </mat-form-field>
        </div>

        <div class="col s12">

          <fieldset [ngStyle]="{borderRadius: '12px'}">
            <legend [ngStyle]="{fontSize: '12px'}">CONFIGURACIÓN DE CUENTAS PARA REGISTRO</legend>

            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Cta. M.N.</mat-label>
                <input type="number" min="0" matInput placeholder="Cta. M.N." [(ngModel)]="subdiario.ccn_codmn" name="ccn_codmn">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Cta. M.E.</mat-label>
                <input type="number" min="0" matInput placeholder="Cta. M.E." [(ngModel)]="subdiario.ccn_codme" name="ccn_codme">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Cta. Imp.</mat-label>
                <input type="number" min="0" matInput placeholder="Cta. Imp." [(ngModel)]="subdiario.ccn_codimp" name="ccn_codimp">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Cta. 2 M.N.</mat-label>
                <input type="number" min="0" matInput placeholder="Cta. 2 M.N." [(ngModel)]="subdiario.ccn_codmn1" name="ccn_codmn1">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Cta. 2 M.E.</mat-label>
                <input type="number" min="0" matInput placeholder="Cta. 2 M.E." [(ngModel)]="subdiario.ccn_codme1" name="ccn_codme1">
              </mat-form-field>
            </div>
          </fieldset>

        </div>

        <div class="col s12" [ngStyle]="{marginTop: '10px'}">
          <fieldset [ngStyle]="{borderRadius: '12px'}">
            <legend [ngStyle]="{fontSize: '12px'}">CONFIGURACIÓN DE CUENTAS PARA DETRACCIÓN</legend>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Cta. Haber M.N.</mat-label>
                <input type="number" min="0" matInput placeholder="Cta. Haber M.N." name="cta_haber_mn">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l10">
              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indban"
                name="sco_indban">Bancos</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indtes"
                name="sco_indtes">Tesorería</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.SCO_INDMOD" name="SCO_INDMOD">Control
                Módulo</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Compras</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Cobranzas</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Ingresos</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Caja Chica</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Canje Letras</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Egresos</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Cta. a Rendir</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Externo</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Transferencia</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Aplicaciones</mat-checkbox>

              <mat-checkbox class="example-margin" [(ngModel)]="subdiario.sco_indcom" name="sco_indcom">Reg.
                Tesorería Financiamiento</mat-checkbox>
            </div>

          </fieldset>
        </div>

      </div>
    </mat-card>
    <br>

  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/subdiarios-contable">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
