export class LiquidacionBeneficio {
  codcia: string;
  codano: string;
  codmes: string;
  codcli: string;
  fecreg: Date;
  codmce: string;
  tipcam: number;
  ultcts: Date;
  tsrano: number;
  tsrmes: number;
  tsrdia: number;
  tctano: number;
  tctmes: number;
  tctdia: number;
  tvtano: number;
  tvtmes: number;
  tvtdia: number;
  mesgra: number;
  rembas: number | string;
  asifam: number | string;
  otrrem: number | string;
  varcts: number;
  vargra: number;
  varvac: number;
  ultgra: number | string;
  impvol: number;
  rincts: number;
  ringra: number;
  rinvac: number;
  diavaa: number;
  impvaa: number;
  apldes: number;
  pctano: number;
  pctmes: number;
  pctdia: number;
  pvaano: number;
  pvames: number;
  pvadia: number;
  pgrmes: number;
  netpag: number;
  netpme: number;
  indsta: string;
  fecupd: Date;
  coduse: string;
  totvac: number;
  totgra: number;
  totcts: number;
  apovol: number;
  totded: number;
  bongra: number;
  totrem: number;
  tsrina: number;
  tctina: number;
  diagra: number;
  excreg: number;
  pgrdia: number;
  impgra: number;
  fecter: Date;
  sumgra: number;
  impafe: number;
  impind: number;
  incces: number;

  constructor(
  ){
    this.indsta = '1';
    this.fecupd = new Date();
    this.impvol = 0;
    this.tsrina = 0;
    this.tctina = 0;
    this.excreg = 0;
    this.pgrdia = 0;
    this.impgra = 0;
    this.sumgra = 0;
    this.impafe = 0;
  }
}
