export class TipoActivoFijo {
  cia_codcia: string;
  taf_codtaf: string;
  taf_descri: string;
  taf_desabr: string;
  taf_indsta: string;
  taf_fecupd: Date;
  taf_coduse: string;
  ccn_codccn: string;
  ccn_coddep: string;
  ccn_codgas: string;
  taf_tasdpc: string;
  taf_indcom: number;
  taf_indinm: number;
  taf_indutr: number;
  cta_codcta: string;

  constructor() {
    this.taf_indsta = '1';
    this.taf_fecupd = new Date();
  }
}
