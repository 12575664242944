import { SelectionModel } from "@angular/cdk/collections";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { generarFormatoOrdenCompra } from "@utils/pdfmaker/builder/compras/operaciones/orden-compra.pdfmaker";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ComprasService, ConfigurationService } from "src/app/services";
import { IParam } from "src/app/services/api/utilities/params";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { PedidoService } from "src/app/services/api/ventas/operaciones/pedido.service";
import { FiltroAprobacionPedido } from "./models/filtro-aprobacion-pedido";

@Component({
  selector: 'aprobacion-pedidos',
  templateUrl: './aprobacion-pedidos.component.html',
  styleUrls: ['./aprobacion-pedidos.component.scss']
})
export class AprobacionPedidosComponent implements OnInit, OnDestroy {


  displayedColumns: string[] = ['select', 'acciones', 'SUC_CODSUC', 'PCC_NUMPCC', 'PCC_REFPED', 'PCC_FECDOC', 'cli_nomcli', 'TMO_CODTMO', 'PCC_VALVTA', 'PCC_IMPIGV', 'PCC_IMPTOT', 'vde_nomvde', 'CPA_CODCPA', 'sdv_dessdv', 'PCC_CODUSE', 'PCC_FECUPD'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  loaderReg: boolean = false;
  loaderAprobar: boolean = false;
  loaderDesaprobar: boolean = false;
  loaderData: boolean = false;
  selection = new SelectionModel<any>(true, []);

  proveedores: any[] = [];

  btnName: string;

  loading$: Subscription;
  period$: Subscription;
  aprobacion$: Subscription;

  totalMN: number = 0;
  totalME: number = 0;

  filtro: FiltroAprobacionPedido;

  isMobile: boolean = false;
  isTablet: boolean = false;

  anioActividad: string;
  mesActividad: string;

  constructor(
    private _clienteService: ClienteService,
    private _comprasService: ComprasService,
    private _pedidoService: PedidoService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.filtro = new FiltroAprobacionPedido();

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadMaestros();
    })

    this.period$ = this.store.select('period').subscribe(state => {
      this.anioActividad = state.year;
      this.mesActividad = state.month;
    })
  }

  loadMaestros(): void {
    this.loaderData = true;
    this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.proveedores = clientes.filter(item => item.cli_indpro == 1);
        this.obtenerPedidos();
      },
      error => {
        this._snackBarService.showError('Error al obtener proveedores', 'OK');
        this.loaderData = false;
      }
    )

  }

  obtenerPedidos(): void {

    this.loaderData = true;

    let params: Array<IParam> = [
      {
        param: 'anio',
        value: this.anioActividad
      },
      {
        param: 'situacion',
        value: this.filtro.situacion
      }
    ];
    console.log('params', params);

    this._pedidoService.obtenerPedidos('01', { params }).subscribe(
      response => {

        this.totalMN = 0;
        this.totalME = 0;

        response.forEach(item => {
          item['cli_nomcli'] = item['cliente'].cli_nomcli;
          item['vde_nomvde'] = item['vendedor'].vde_nomvde;
          item['sdv_dessdv'] = item['situacion'].sdv_dessdv;
        });

        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  mostrarPdf(id): void {
    const headers = {
      username: this._configurationService.obtenerIdUsuario()
    }

    this._comprasService.obtenerPdfOrdenCompra(id).subscribe(
      response => {
        if (response.length > 0 && response != null) {
          // verPdfReport(headers, response[0]);
          generarFormatoOrdenCompra(headers, response[0]);

        }
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  buscar(): void {
    this.obtenerPedidos();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) return `${this.isAllSelected() ? 'deselect' : 'select'} all`;

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  verMasOpciones(): void { }

  aprobar(): void {

    this.loaderAprobar = true;

    if(this.selection.selected.length == 0) {
      this._snackBarService.showError('Seleccionar al menos un pedido', 'Ok');
      this.loaderAprobar = false;
      return;
    }

    const payload = this.selection.selected.map(item => ({
      CIA_CODCIA: this._configurationService.obtenerCompaniaCliente(),
      SUC_CODSUC: item.SUC_CODSUC,
      PCC_NUMPCC: item.PCC_NUMPCC,
      cdt_codcdt: null,
      sdv_codsdv: 1,
      SEP_NUMSEP: item.SEP_NUMSEP,
      pap_motrec: ""
    }));

    this.aprobacion$ = this._pedidoService.aprobacionPedidos(payload).subscribe(
      response => {
        this._snackBarService.showSuccess('Pedidos aprobados', 'Ok');
        this.loaderAprobar = false;
        this.selection.clear();
        this.obtenerPedidos();
      },
      error => {
        this._snackBarService.showError('Error al aprobar pedidos', 'Ok');
        this.selection.clear();
        this.loaderAprobar = false;
      }
    );

  }

  desaprobar(): void {

    this.loaderDesaprobar = true;

    if(this.selection.selected.length == 0) {
      this._snackBarService.showError('Seleccionar al menos un pedido', 'Ok');
      this.loaderDesaprobar = false;
      return;
    }

    const payload = this.selection.selected.map(item => ({
      CIA_CODCIA: this._configurationService.obtenerCompaniaCliente(),
      SUC_CODSUC: item.SUC_CODSUC,
      PCC_NUMPCC: item.PCC_NUMPCC,
      cdt_codcdt: null,
      sdv_codsdv: 0,
      SEP_NUMSEP: item.SEP_NUMSEP,
      pap_motrec: ""
    }));

    this.aprobacion$ = this._pedidoService.aprobacionPedidos(payload).subscribe(
      response => {
        this._snackBarService.showSuccess('Pedidos desaprobados', 'Ok');
        this.loaderDesaprobar = false;
        this.selection.clear();
        this.obtenerPedidos();
      },
      error => {
        this._snackBarService.showError('Error al desaprobar pedidos', 'Ok');
        this.selection.clear();
        this.loaderDesaprobar = false;
      }
    );

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }

}
