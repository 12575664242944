import { Component, OnDestroy, OnInit } from "@angular/core";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_PRODUCCION } from "@data/json/reportes";
import { Store } from "@ngrx/store";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";

@Component({
  selector: 'produccion-report',
  templateUrl: './produccion-report.component.html',
  styleUrls: ['./produccion-report.component.scss']
})
export class ProduccionReportComponent implements OnInit, OnDestroy {

  year: string;
  month: string;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  reportes: any[] = REPORTES_PRODUCCION;

  reporteSeleccionado: any = ['PRODUCCION_BM'];

  period$: Subscription;
  loading$: Subscription;

  constructor(
    private store: Store<PavsoState>
  ){
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  seleccionarReporte(event): void {
    this.reporteSeleccionado[0] = event.id;
  }

  verReporte(): void {

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }
}
