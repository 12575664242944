import { Component, OnDestroy, OnInit } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthenticationService, ContabilidadService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { REPORTES_PLANILLA_NOMINA, retornarCódigoFiltroInformeNomina } from '@data/json/reportes';
import { HeaderReport } from '@data/interfaces/headers.report';
import { HeaderReportService } from 'src/app/services/reports/config/header-report.service';
import { NominaReportService } from 'src/app/services/reports/modules/nomina-report.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { FiltroReportePlanilla } from 'src/app/models/planilla/filtro-reporte-planilla';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-informes-nomina',
  templateUrl: './informes-nomina.component.html',
  styleUrls: ['./informes-nomina.component.css']
})
export class InformesNominaComponent implements OnInit, OnDestroy {

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  year: any;
  month: any;

  trabajadores: any[] = [];
  centros: any[] = [];
  conceptos: any[] = [];

  tipoNomina: string;
  nameTipoNomina: string;
  secNomina: string;

  reportes: any[] = REPORTES_PLANILLA_NOMINA;

  reporteSeleccionado: any = [REPORTES_PLANILLA_NOMINA[0].id];

  mostrarFiltroAuxiliar: 0 | 1 = 0;
  mostrarFiltroCentro: 0 | 1 = 0;
  mostrarFiltroConcepto: 0 | 1 = 0;

  loading: boolean = false;

  period$: Subscription;
  nomina$: Subscription;
  loading$: Subscription;
  indicadores$: Subscription;
  colaboradores$: Subscription;
  conceptos$: Subscription;
  centros$: Subscription;

  filtro: FiltroReportePlanilla;

  constructor(
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private _headerReportService: HeaderReportService,
    private _contabilidadService: ContabilidadService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _nominaReportService: NominaReportService
  ) {
    this.filtro = new FiltroReportePlanilla();

    this.period$ = this.store.select('period').subscribe(
      state => {
        this.year = state.year;
        this.month = state.month;
      }
    )

    this.nomina$ = this.store.select('nomina').subscribe(
      state => {
        this.tipoNomina = state.typeNomina;
        this.nameTipoNomina = state.nameTypeNomina;
        this.secNomina = state.secNomina;
      }
    )

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })

  }

  /**
   * Obtiene los maestros de :
   * . colaboradores
   * . conceptos
   * . centros de costo
   */
  loadData(): void {
    this.loading = true;

    forkObs(
      this._nominaService.listarColaboradores(),
      this._nominaService.listarConceptos(),
      this._contabilidadService.listarCentroCostos()
    ).then(data => {
      this.trabajadores = data[0];
      this.conceptos = data[1].filter(item => item.ind_estado == "1");
      this.centros = data[2];
      this.loading = false;
    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK");
      this.loading = false;
    })

  }

  seleccionarReporte(event): void {
    this.reporteSeleccionado[0] = event.id;
    const indicador = retornarCódigoFiltroInformeNomina(this.reporteSeleccionado[0]);

    this.indicadores$ = this._contabilidadService.obtenerInidicadoresFiltro(indicador).subscribe(
      ([response]) => {
        console.log('INDICADORES', response)
        if(response) {
          this.mostrarFiltroAuxiliar = response.ind_auxiliar;
          this.mostrarFiltroCentro = response.ind_centrocosto;
          this.mostrarFiltroConcepto = response.ind_conceptoplanilla;
        }
      }
    )
  }

  verReporte(): void {
    this.loading = true;
    const headers: HeaderReport = this._headerReportService.obtenerCabecera()

    const otherData = {
      tipoNomina: this.tipoNomina,
      secNomina: this.secNomina,
      nameTipoNomina: this.nameTipoNomina,
      lastDay: (new Date(Number(this.year), Number(this.month), 0)).getDate(),
      npe: `${this.year}${this.month}${this.tipoNomina}${this.secNomina}`
    }

    const payload = {
      colaboradores: (this.filtro.colaboradores)? this.filtro.colaboradores: [],
      conceptos: (this.filtro.conceptos)? this.filtro.conceptos: [],
      centros: (this.filtro.centros)? this.filtro.centros: []
    };

    this._nominaReportService.verReporteNomina(this.reporteSeleccionado[0], headers, payload, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.nomina$,
      this.loading$,
      this.indicadores$,
      this.colaboradores$,
      this.conceptos$,
      this.centros$
    ]);
  }

}
