<app-pavso-title-section [title]="'apps.crm.maestros.responsable.title' | translate" [module]="'CRM'" [estado]="responsable.indsta"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>{{'apps.crm.maestros.responsable.form.code' | translate}}</mat-label>
                  <input type="text" [(ngModel)]="responsable.idres" matInput [placeholder]="'apps.crm.maestros.responsable.form.code' | translate" name="idres" required >
              </mat-form-field>
          </div>

          <div class="col s12 m12 l6">
              <mat-form-field>
                  <mat-label>{{'apps.crm.maestros.responsable.form.description' | translate}}</mat-label>
                  <input type="text" [(ngModel)]="responsable.nombre" matInput [placeholder]="'apps.crm.maestros.responsable.form.description' | translate" name="nombre" required>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
                <mat-label>{{'apps.crm.maestros.responsable.form.responsible' | translate}}</mat-label>
                <input type="email" [(ngModel)]="responsable.corele" matInput [placeholder]="'apps.crm.maestros.responsable.form.responsible' | translate" name="corele" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">

            <select-search
              [label]="'apps.crm.maestros.responsable.form.client' | translate"
              [placeholder]="'Ej. '"
              [name]="'cli_codcli'"
              [value]="'cli_codcli'"
              [description]="'cli_nomcli'"
              [data]="clientes"
              (cambioSelect)="responsable.codcli = $event"
              [multiple]="true"
              [model]="responsable.codcli">
            </select-search>

          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
                <mat-label>{{'apps.crm.maestros.responsable.form.user' | translate}}</mat-label>
                <input type="text" [(ngModel)]="responsable.usuario" matInput placeholder="Usuario" name="usuario" required>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
                <mat-label>{{'apps.crm.maestros.responsable.form.color' | translate}}</mat-label>
                <input type="color" [(ngModel)]="responsable.hexcol" matInput [placeholder]="'apps.crm.maestros.responsable.form.color' | translate" name="ors_hexcol">
            </mat-form-field>
          </div>
        </div>

    </mat-card>
    <log-updated *ngIf="uid!='0'" [coduse]="responsable.coduse" [fecupd]="responsable.fecupd"></log-updated>


  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-crm/responsables">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
