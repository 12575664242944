import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexTooltip,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexYAxis,
} from "ng-apexcharts";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'diagrama-pareto',
  templateUrl: './diagrama-pareto.component.html',
  styleUrls: ['./diagrama-pareto.component.scss']
})
export class DiagramaParetoComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  loading$: Subscription;
  informe$: Subscription;
  periodo$: Subscription;

  year: string;
  month: string;

  @Input() dataSeries: any;
  @Input() dataProductos: any;

  constructor(
    private store: Store<PavsoState>,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService
  ) {
    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.chartOptions = {
      series: [
        {
          name: "Importe",
          type: "column",
          data: [15, 19, 25, 23, 39, 38, 29, 47]
        },
        // {
        //   name: "Cashflow",
        //   type: "column",
        //   data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
        // },
        {
          name: "Porcentaje",
          type: "line",
          data: [20, 29, 37, 36, 44, 45, 50, 58]
        }
      ],
      chart: {
        height: 350,
        type: "line",
        stacked: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [1, 1, 2]
      },
      title: {
        text: "Diagrama de Pareto",
        align: "left",
        offsetX: 110
      },
      xaxis: {
        categories: []
      },
      yaxis: [
        {
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: "#008FFB"
          },
          labels: {
            style: {
              // color: "#008FFB"
            }
          },
          title: {
            text: "Importe",
            style: {
              color: "#008FFB"
            }
          },
          tooltip: {
            enabled: true
          }
        },
        {
          seriesName: "Income",
          opposite: true,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: "#00E396"
          },
          labels: {
            style: {
              // color: "#00E396"
            }
          },
          title: {
            text: "Porcentaje",
            style: {
              color: "#00E396"
            }
          }
        },

      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        }
      },
      // legend: {
      //   horizontalAlign: "left",
      //   offsetX: 40
      // }
    };

  }

  ngOnInit(): void {
    // this.loading$ = this.store.select('loading').subscribe(state => {
    //   if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerDiagramaPareto();
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('data diagrama de pareto', changes)
    if(changes.dataSeries.currentValue) {
      this.chartOptions.series = changes.dataSeries.currentValue;
    }

    if(changes.dataProductos.currentValue) {
      console.log('data diagrama de pareto productos', changes.dataProductos.currentValue)
      this.chartOptions.xaxis = {categories: changes.dataProductos.currentValue};
    }

  }

  /**
   * Renderiza el diagrama de pareto con la data oficial
   */
  obtenerDiagramaPareto(): void {

    this.informe$ = this._ventasService.obtenerInformeDiagramaPareto(this.year, this.month).subscribe(
      data => {
        this.chartOptions.series = data.series;
        this.chartOptions.xaxis.categories = data.productos;
      },
      error =>  {
        this._snackBarService.showError('Error al obtener informe de pareto', 'Ok');
      }
    )

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.informe$,
      this.periodo$
    ])
  }

}
