import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';

export class GenerateExcelModel {
  constructor(
    public typeDate: any,
    public desde: any,
    public hasta: any,
    public moneda: any,
    public filtro: any
  ) {}
}

@Component({
  selector: 'app-generate-excel',
  templateUrl: './generate-excel.component.html',
  styleUrls: ['./generate-excel.component.css']
})
export class GenerateExcelComponent implements OnInit {

  generateModel: GenerateExcelModel;

  seleccionar: boolean = true;
  seleccionarMoneda: boolean = true;

  task: any = {
    name: 'Campos de pedido',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Nro de pedido', completed: false},
      {name: 'Documento cliente', completed: false},
      {name: 'Cliente', completed: false},
      {name: 'Fecha', completed: false},
      {name: 'Tipo de moneda', completed: false},
      {name: 'Importe total', completed: false},
      {name: 'Vendedor', completed: false},
      {name: 'Estado', completed: false},
    ]
  };

  allComplete: boolean = false;

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => t.completed = completed);
  }

  year = new Date().getFullYear();
  month = new Date().getMonth();

  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date(this.year, this.month + 1, 0))
  });

  constructor(
    public dialogRef: MatDialogRef<GenerateExcelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.generateModel = new GenerateExcelModel("1",null,null,null,"todos")
  }

  buttonName: INameConstant = NAMES_CONSTANTS;

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
