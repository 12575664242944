import { Component, OnInit, ViewChild } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexPlotOptions, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from "ng-apexcharts";
import { Subscription } from "rxjs";
import { CrmService } from "src/app/services";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'crm-graph-two',
  templateUrl: './crm-graph-two.component.html',
  styleUrls: ['./crm-graph-two.component.scss']
})
export class CrmGraphTwoComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  displayedColumns: string[] = ['business', 'amount'];

  periodo$: Subscription;
  loading$: Subscription;
  estadistico$: Subscription;

  year: string;
  month: string;

  loaderData: boolean;

  dataSource = [
    {
      business: 'BACKUS',
      amount: 44
    },
    {
      business: 'CBN',
      amount: 76
    },
    {
      business: 'CERVECERA NACIONAL CN',
      amount: 35
    },
    {
      business: 'CERVECERA SAN JUAN',
      amount: 155
    },
    {
      business: 'MONTO TOTAL',
      amount: 155
    },
  ];

  constructor(
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _crmService: CrmService
  ) {

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.chartOptions = {
      series: [
        {
          name: "BACKUS",
          data: [44]
        },
        {
          name: "CBN",
          data: [76]
        },
        {
          name: "CERVECERA NACIONAL CN",
          data: [35]
        },
        {
          name: "CERVECERA SAN JUAN",
          data: [35]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct"
        ]
      },
      yaxis: {
        title: {
          text: "S/. (Monto)"
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "S/. " + val + " ";
          }
        }
      }
    };
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerDatosEstadistico()
    })
  }

  obtenerDatosEstadistico(): void {

    this.loaderData = true;
    this.estadistico$ = this._crmService.obtenerGrafico02(this.year, this.month).subscribe(
      response => {
        console.log('data', response)
        this.chartOptions = {
          series: response.series,
          chart: {
            type: "bar",
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              // endingShape: "rounded"
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: [
              "",
            ]
          },
          yaxis: {
            title: {
              text: "S/. (Monto)"
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return "S/. " + val + " ";
              }
            }
          }
        };

        this.dataSource = response.dataSource;

        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  ngOnDestroy(): void {
    if(this.periodo$) this.periodo$.unsubscribe();
  }

}
