import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Etapa } from "src/app/models/crm/etapa";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class EtapaService {

  constructor(
    private http: HttpClient,
      private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerEtapas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/etapa/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  obtenerEtapa(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/etapa/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param etapa
   * @returns
   */
  registrarEtapa(etapa: Etapa): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/etapa`,
      etapa
    );
  }

  /**
   *
   * @param etapa
   * @returns
   */
  actualizarEtapa(etapa: Etapa): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/etapa`,
      etapa
    );
  }
}
