import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { fillTable, searchInTable } from '@utils/tables/table';

@Component({
  selector: 'app-select-payment-request',
  templateUrl: './select-payment-request.component.html',
  styleUrls: ['./select-payment-request.component.css']
})
export class SelectPaymentRequestComponent implements OnInit {
  date: Date = new Date();

  loaderData: boolean;

  clientes: any[] = [];
  cliente: string = '';

  displayedColumns: string[] = ['cta_cte', 'td', 'nro_doc', 'fec_emis', 'fec_venc', 'glosa', 'mo', 'importe_documento', 'importe_abono', 'saldo', 'cuenta', 'auxiliar'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonName: INameConstant = NAMES_CONSTANTS;

  constructor(
    public dialogRef: MatDialogRef<SelectPaymentRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loaderData = false;

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.clientes = data.clientes;
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

}
