<h1 mat-dialog-title>{{'apps.crm.operaciones.lista-requisitos.title' | translate}}</h1>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div mat-dialog-content>
    <br>
      <div class="row">
          <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.lista-requisitos.form.opportunity' | translate}}</mat-label>
                  <input type="text" matInput placeholder="Oportunidad" [(ngModel)]="listaRequisito.numorc" name="numorc" readonly>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.lista-requisitos.form.date' | translate}}</mat-label>
              <input
              [(ngModel)]="listaRequisito.fecreg"
              name="fecreg"
              matInput [matDatepicker]="fechahoy">
              <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
              <mat-datepicker #fechahoy></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <app-state-select [state]="listaRequisito.indsta" [disable]="loaderData" (cambioEstado)="listaRequisito.indsta = $event"></app-state-select>
          </div>
          <div class="col s12 m12 l6">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.lista-requisitos.form.description' | translate}}</mat-label>
                  <input type="text" matInput placeholder="Descripción" [(ngModel)]="listaRequisito.desobj" name="descripcion"required>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
              <mat-form-field>
                  <mat-label>{{'apps.crm.operaciones.lista-requisitos.form.delivery-term' | translate}}</mat-label>
                  <input type="number" matInput placeholder="Plazo Entrega" [(ngModel)]="listaRequisito.plaent" name="plazo_entrega"required>
              </mat-form-field>
          </div>

          <div class="col s12 m12 l1">
              <mat-form-field>
                  <mat-label>Oportunidad</mat-label>
                  <input [(ngModel)]="listaRequisito.idtkt" type="text" matInput placeholder="Oportunidad" name="idtkt" readonly>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l1">
              <mat-form-field>
                  <mat-label>Seguimiento</mat-label>
                  <input [(ngModel)]="listaRequisito.idset" type="text" matInput placeholder="Seguimiento" name="idset" readonly>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l1">
              <mat-form-field>
                  <mat-label></mat-label>
                  <input [(ngModel)]="listaRequisito.indent" type="text" matInput placeholder="" name="item3" readonly>
              </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <mat-form-field>
                <mat-label>Lista Req.</mat-label>
                <input type="text" [(ngModel)]="listaRequisito.codolc" matInput placeholder="Lista Req." name="codolc">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l1">
            <button type="button" mat-mini-fab color="primary" matTooltip="Imprimir Lista">
                <mat-icon>list</mat-icon>
            </button>
        </div>
      </div>

      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">

        </div>
        <div class="pav-table-header-title">
          {{'apps.crm.operaciones.lista-requisitos.table.title' | translate}}
        </div>
        <div class="pav-table-header-add">
          <div class="isMobile">
            <div class="pav-button-icon-add" (click)="agregarItem()">
              <mat-icon svgIcon="add"></mat-icon>
            </div>
          </div>
          <div class="isWeb">
            <button [disabled]="loaderData" mat-flat-button class="pav-button-add-item" type="button" (click)="agregarItem()">Agregar ítem</button>
          </div>
        </div>
      </div>
      <div class="mat-elevation-z0">

          <table mat-table [dataSource]="dataSource" matSort >
              <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.lista-requisitos.table.header.actions' | translate}} </th>
                  <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <div class="pav-btns-container">
                      <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                        <mat-icon svgIcon="edit-white"></mat-icon>
                      </div>
                      <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                        <mat-icon svgIcon="confirm"></mat-icon>
                      </div>
                      <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </div>
                    </div>
                  </td>
              </ng-container>

              <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.lista-requisitos.table.header.item' | translate}} </th>
                  <td mat-cell *matCellDef="let row" data-label="Código"> {{row.corord}} </td>
              </ng-container>

              <ng-container matColumnDef="cod_req">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.lista-requisitos.table.header.cod_req' | translate}} </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                  <ng-container *ngIf="row.isEditing">
                    <mat-form-field class="mat-form-field-item">
                      <mat-label></mat-label>
                      <input min="0" required type="texts" matInput [placeholder]="'apps.crm.operaciones.lista-requisitos.table.header.cod_req' | translate" [(ngModel)]="row.codreq"
                      [name]="'codreq' + i">
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!row.isEditing">
                    {{row.codreq}}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="requisito">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.lista-requisitos.table.header.requirement' | translate}} </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                  <ng-container *ngIf="row.isEditing">
                    <mat-form-field class="mat-form-field-item">
                      <mat-label></mat-label>
                      <input min="0" required type="text" matInput [placeholder]="'apps.crm.operaciones.lista-requisitos.table.header.requirement' | translate" [(ngModel)]="row.desreq"
                      [name]="'desreq' + i">
                    </mat-form-field>
                  </ng-container>
                  <ng-container *ngIf="!row.isEditing">
                    {{row.desreq}}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="completo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.lista-requisitos.table.header.complete' | translate}} </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                    <mat-checkbox class="example-margin" [(ngModel)]="row.indter" [name]="'indter' + i">{{(row.indter == 1)?"SI": "NO"}}</mat-checkbox>
                  </td>
              </ng-container>

              <ng-container matColumnDef="observaciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.lista-requisitos.table.header.observations' | translate}} </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">
                    <ng-container *ngIf="row.isEditing">
                      <mat-form-field class="mat-form-field-item">
                        <mat-label></mat-label>
                        <input min="0" required type="text" matInput placeholder="Observaciones" [(ngModel)]="row.observ"
                        [name]="'observ' + i">
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!row.isEditing">
                      {{row.observ}}
                    </ng-container>
                  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="6">{{'general.table.data-not-found' | translate}}</td>
              </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
  </div>
  <br>
  <div align="right">
    <button mat-flat-button (click)="onNoClick()" class="pav-button-cancel" type="button">{{'general.button.cancel' | translate}}</button>
    &nbsp;
    <button mat-flat-button color="primary" [class.spinner]="loaderReg" class="pav-button-action" type="submit">{{btnName | translate}}</button>
  </div>
</form>
