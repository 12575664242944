import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { DetalleProd } from 'src/app/models';

@Component({
  selector: 'app-purchase-order-product-edit',
  templateUrl: './purchase-order-product-edit.component.html',
  styleUrls: ['./purchase-order-product-edit.component.css']
})
export class PurchaseOrderProductEditComponent implements OnInit {

  buttonName: INameConstant = NAMES_CONSTANTS;

  constructor(
    public dialogRef: MatDialogRef<PurchaseOrderProductEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DetalleProd
  ) { }

  ngOnInit(): void {
  }

  cancelar(): void {
    this.dialogRef.close(false);
  }

  actualizarProducto(f: NgForm): void {
    this.dialogRef.close(true);
  }

  cambioImportes() {
    this.data.ocd_imptot = this.data.ocd_canocd * this.data.ocd_preuni;
    this.data.ocd_imptot = parseFloat(this.data.ocd_imptot).toFixed(2);
    this.data.ocd_valvta = this.data.ocd_canocd * this.data.ocd_preuni;
    this.data.ocd_valvta = parseFloat(this.data.ocd_valvta).toFixed(2);
    this.data.ocd_impigv = this.data.ocd_canocd * this.data.ocd_pruigv;
    this.data.ocd_impigv = parseFloat(this.data.ocd_impigv).toFixed(2);
  }

}
