export class InteresBancoCTS {
  codban: string;
  nombic: string;
  codtmo: string;
  porint: number;
  isEditing: boolean;
  correlativo: number;

  constructor(){
    this.isEditing = true;
  }
}
