import { Component, OnDestroy, OnInit } from '@angular/core';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-kardex-le',
  templateUrl: './kardex-le.component.html',
  styleUrls: ['./kardex-le.component.css']
})
export class KardexLeComponent implements OnInit, OnDestroy {

  loading$: Subscription;

  constructor(
    private store: Store<PavsoState>
  ) { }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  ngOnDestroy(): void {

  }

}
