<mat-form-field [ngStyle]="{width: '150px'}">
  <mat-label>Establecimiento</mat-label>
  <mat-select
    [disabled]="loading"
    [(ngModel)]="establecimientoSeleccionada"
    name="establecimientoSeleccionada"
    (selectionChange)="seleccionarEstablecimiento($event.value)">
    <mat-option *ngFor="let establecimiento of establecimientos" [value]="establecimiento.TIE_CODTIE">
      {{establecimiento.TIE_DESTIE}}
    </mat-option>
  </mat-select>
</mat-form-field>
