import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AuthenticationService, CuentaCobrarService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Cobrador } from 'src/app/models/cobranzas/cobrador';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { CobradorService } from 'src/app/services/api/cuentas-por-cobrar/maestros/cobrador.service';

@Component({
  selector: 'app-cobrador-form',
  templateUrl: './cobrador-form.component.html',
  styleUrls: ['./cobrador-form.component.css']
})
export class CobradorFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  cobrador: Cobrador;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  tipoDoc$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _cuentaCobrarService: CuentaCobrarService,
    private _cobradorService: CobradorService
  ) {

    this.cobrador = new Cobrador();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=> {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(this.uid == '0') return;
      this.obtenerCobrador();
    })
  }

  obtenerCobrador(): void {
    this._cobradorService.obtenerCobradores().subscribe(
      cobradores => {
        this.cobrador = cobradores.find(item => item.COB_CODCOB == this.uid);
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarCobrador(f): this.actualizarCobrador(f);
  }

  registrarCobrador(f: NgForm): void {
    this.loaderReg = true;
    this._cobradorService.registrarCobrador(this.cobrador).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Cobrador registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarCobrador(f: NgForm): void {
    this.loaderReg = true;
    this._cobradorService.actualizarCobrador(this.cobrador).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Cobrador actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-cobranza/cobradores']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoDoc$,
      this.loading$
    ])

  }
}
