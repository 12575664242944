export class Actividad {
  cia_codcia: string;
  act_codact: string;
  tac_codtac: string;
  act_desact: string;
  act_indsta: string;
  act_fecupd: Date;
  act_coduse: string;

  constructor() {
    this.act_codact = '';
    this.act_indsta = '1';
    this.act_fecupd = new Date();
  }
}
