import { CurrentPageState } from "@data/interfaces/current-page.state";
import { createReducer, on } from "@ngrx/store";
import { cambiarPaginaActual } from "../actions/current-page.actions";

export const initialState: CurrentPageState = {
  program: localStorage.getItem('code-page'),
  xfunction: localStorage.getItem('xfunction')
};

const _currentPageReducer = createReducer(
  initialState,
  on(cambiarPaginaActual, (_, {program, xfunction}) =>  {
    return {program, xfunction}
  }),
);

export function currentPageReducer(state, action) {
  return _currentPageReducer(state, action)
}
