<app-pavso-title-section [title]="'apps.crm.maestros.grupo-actividad.title' | translate" [module]="'CRM'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <pav-table-list
    [data]="gruposActividad"
    [tHeaders]="tHeaders"

    title="Todos los grupos"
    url="/modulo-crm/grupo-de-actividad"
    entity=""
    [pageSize]="10"
    [params]="['0']"
  ></pav-table-list>

</div>
