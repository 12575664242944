import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VentasService } from 'src/app/services';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-auth-aprobar-solicitud-list',
  templateUrl: './auth-aprobar-solicitud-list.component.html',
  styleUrls: ['./auth-aprobar-solicitud-list.component.css']
})
export class AuthAprobarSolicitudListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'cli_codcli', 'cli_nomcli', 'cli_numtlf'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  cliente$: Subscription;
  loading$: Subscription;

  isCreate: boolean;
  isEdit: boolean;
  isDownLoadToExcel: boolean;

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.store.select('permissions').subscribe(state => {
      this.isCreate          = state.isCreate;
      this.isEdit            = state.isEdit;
      this.isDownLoadToExcel = state.isDownLoadToExcel;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerClienteComerciales();
    })
  }

  obtenerClienteComerciales(): void {
    this.loaderData = true;
    this.cliente$ = this._ventasService.obtenerClientesComerciales().subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.cliente$, this.loading$]);
  }
}
