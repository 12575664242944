export function agrupar(xs, key) {
  return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
  }, {})
}

export function tieneDuplicados(arr) {
  const set = new Set(arr);
  return set.size !== arr.length;
}

export function estanTodosPresentes(arrayOriginal, arrayAComparar) {
  return arrayAComparar.every(elemento => arrayOriginal.includes(elemento));
}
