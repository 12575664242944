import { MovimientoTipoDocumento } from "./motivo-movimiento-tipo-documento";

export class MotivoMovimiento {
    //descripcion del campo - tipo de dato - permite nulos
    cia_codcia: string; // Código de compañía - char(2) - NO
    mmo_codmmo: string; // Código de motivo de movimiento - char(4) - NO
    mmo_descri: string; // Descripción de motivo de movimiento - varchar(60) - NO
    mmo_desabr: string; // Descripción abreviada de motivo de movimiento - varchar(40) - NO
    mmo_codopc: number; // (campo sin especificaciones de tipo y largo) - smallint - NO
    mmo_pedido: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indtra: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indfac: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indncr: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indser: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indtrs: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indval: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indgui: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indpro: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indtco: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indexp: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    tdo_codref: string; // (campo sin especificaciones de tipo y largo) - char(3) - YES
    mmo_indrma: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indsta: string; // (campo sin especificaciones de tipo y largo) - char(1) - NO
    mmo_fecupd: Date; // (campo sin especificaciones de tipo y largo) - datetime - NO
    mmo_coduse: string; // (campo sin especificaciones de tipo y largo) - varchar(30) - NO
    mmo_indsdm: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    tga_codtga: string; // (campo sin especificaciones de tipo y largo) - char(4) - YES
    mmo_indoim: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indocm: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indoin: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indopr: number; // (campo sin especificaciones de tipo y largo) - tinyint - YES
    mmo_codofi: string; // (campo sin especificaciones de tipo y largo) - char(2) - YES
    mmo_inexpe: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_indgra: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_inddev: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_ncrsun: string; // (campo sin especificaciones de tipo y largo) - char(2) - YES
    mmo_ndbsun: string; // (campo sin especificaciones de tipo y largo) - char(2) - YES
    mmo_indcbo: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_asicos: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_asicon: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_asicoe: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_asicop: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_cospt: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    mmo_otrmov: number; // (campo sin especificaciones de tipo y largo) - tinyint - NO
    t51_codt51: string; // (campo sin especificaciones de tipo y largo) - char(4) - YES
    t07_codt07: string; // (campo sin especificaciones de tipo y largo) - char(2) - YES
    t20_codt20: string; // (campo sin especificaciones de tipo y largo) - char(2) - YES
    mmo_indpsa: number; // (campo sin especificaciones de tipo y largo) - smallint - NO
    ccn_codvin: string; // (campo sin especificaciones de tipo y largo) - char(8) - YES
    ccn_codccn: string; // (campo sin especificaciones de tipo y largo) - char(4) - YES
    mmo_indccs: number; // Centro de Costo, no esta en la tabla de motivo movimiento
    mmo_indade: number; //Adelante no esta en la tabla de motivo movimiento
    mmo_descom: number; //agregado recientemente smallint
    mmo_movalm: number; //tinyint
    mmo_reginv: number; //tinyint
    mmo_indots: number; //tinyint
    mmo_cmovin: number; //tinyint
    mmo_indvin: number; //tinyint
    referencias_mdr:Array<MovimientoTipoDocumento>;
//    tdo_codtdo: string; //char(3) codigo tipo de documento

    constructor() {
        this.mmo_indsta = '1';
        this.mmo_fecupd = new Date();
        this.mmo_indocm = 0;
        this.mmo_indopr = 0;
        this.mmo_indccs = 0;
        this.mmo_indrma = 0;
        this.mmo_indpro = 0;
        this.mmo_indval = 0;
        this.mmo_indsdm = 0;
        this.mmo_indcbo = 0;
        this.mmo_inddev = 0;
        this.mmo_indtra = 0;
        this.mmo_asicop = 0;
        this.mmo_asicoe = 0;
        this.mmo_asicos = 0;
        this.mmo_asicon = 0;
        this.mmo_cospt  = 0;
        this.mmo_otrmov = 0;
        this.mmo_pedido = 0;
        this.mmo_indgra = 0;
        this.mmo_indfac = 0;
        this.mmo_indexp = 0;
        this.mmo_indncr = 0;
        this.mmo_indade = 0;
        this.mmo_indgui = 0;
        this.mmo_inexpe = 0;
        this.referencias_mdr = [];
    }
}