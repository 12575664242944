import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import * as pdfBuilder from '@utils/pdfmaker/builder/compras/operaciones/solicitud-compra.pdfmaker';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ItemDetalleSolicitudCompra, SolicitudCompra } from 'src/app/models/compras/operaciones/solicitud-compra';
import { AuthenticationService, ComprasService } from 'src/app/services';
import { SolicitudCompraService } from 'src/app/services/api/compras/operaciones/solicitud-compra.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { CopiarSolicitudDialog } from '../dialog/copiar-solicitud-dialog/copiar-solicitud.dialog';
import { MoverAOrdenCompraDialog } from '../dialog/mover-a-orden-compra-dialog/mover-a-orden-compra.dialog';
import { DetalleOcd } from 'src/app/models/compras/purchase_order';
import { ConsultarStockMinimoDialog } from '../dialog/consultar-stock-minimo-dialog/consultar-stock-minimo.dialog';
import { FamiliaService } from 'src/app/services/api/almacen/maestros/familia.service';
import { LineaProductoService } from 'src/app/services/api/produccion/maestros/linea-producto.service';
import { SubfamiliaService } from 'src/app/services/api/almacen/tabla-apoyo/subfamilia.service';
import { MarcaService } from 'src/app/services/api/almacen/maestros/marca.service';

@Component({
  selector: 'app-solicitud-compra-form',
  templateUrl: './solicitud-compra-form.component.html',
  styleUrls: ['./solicitud-compra-form.component.scss']
})
export class SolicitudCompraFormComponent implements OnInit, OnDestroy {

  selected: any;

  selectRow(row: any) {
    this.selected = row;
  }

  displayedColumns: string[] = ['item', 'tipo', 'id_proveedor', 'nombre_proveedor', 'contacto', 'mo', 'importe_ref', 'comentario', 'adjunto', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsArticulo: string[] = ['prd_codprd', 'rcd_detalle', 'ume_codume', 'rcd_cansol', 'rcd_preuni', 'rcd_imptot', 'rcd_motcom', 'rcd_fecreq', 'rcd_pesrcd', 'rcd_codped', 'rcd_codcli', 'oc_cliente'];
  dataSourceArticulo: MatTableDataSource<any>;

  @ViewChild('paginatorArticulo') paginatorArticulo: MatPaginator;
  @ViewChild(MatSort) sortArticulo: MatSort;

  loaderArticulo: boolean = false;

  loaderReg = false;

  fecha: Date = new Date();
  usuario: any;

  loaderData: boolean = false;
  pickerArray: MatDatepicker<any>[] = [];
  monedas: any[] = [];
  clientes: any[] = [];
  aprobadores: any[] = [];
  proveedores: any[] = [];
  solicitantes: any[] = [];
  productos: any[] = [];
  lineas: any[] = [];
  tiposInventario: any[] = [];
  familias: any[] = [];
  subfamilias: any[] = [];
  marcas: any[] = [];

  minDate: Date;

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  period$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;
  clientes$: Subscription;
  aprobadores$: Subscription;
  solicitudCompra$: Subscription;
  productos$: Subscription;

  uid: string;

  solicitudCompra: SolicitudCompra;

  isTablet: boolean;
  isMobile: boolean;

  constructor(
    private _dialogService: DialogService,
    private readonly _solicitudCompraService: SolicitudCompraService,
    private readonly _clienteService: ClienteService,
    private readonly _authService: AuthenticationService,
    private readonly _comprasService: ComprasService,
    private readonly _productoService: ProductoService,
    private readonly _snackBarService: SnackBarService,
    private readonly _familiaService: FamiliaService,
    private readonly _lineaService: LineaProductoService,
    private readonly _subfamiliaService: SubfamiliaService,
    private readonly _marcaService: MarcaService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog
  ) {

    this.initialize();

  }

  initialize(): void {
    this.solicitudCompra = new SolicitudCompra();

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceArticulo = fillTable([], this.paginatorArticulo, this.sortArticulo);

    this.usuario = this._authService.getUsuarioSistema();

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());
  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });
  }

  /**
   * Obtiene los maestros de :
   * . clientes comerciales
   * . aprobadores
   */
  loadData(): void {

    this.loaderData = true;
    this._activatedRoute.params.subscribe(params => {
      this.uid = params['id'];
    })

    this.loadMaestros();

  }

  familias$: Subscription;
  subfamilias$: Subscription;
  lineas$: Subscription;
  marcas$: Subscription;

  loadMaestros(): void {
    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes;

        this.proveedores = this.clientes.filter(item => item.cli_indpro == 1);
        this.solicitantes = this.clientes.filter(item => item.cli_indtra == 1);

        this.aprobadores$ = this._comprasService.obtenerAprobadores().subscribe(
          aprobadores => {
            this.aprobadores = aprobadores;

            this.productos$ = this._productoService.obtenerProductos().subscribe(
              productos => {
                this.productos = productos;

                this.lineas$ = this._lineaService.obtenerLineasProducto().subscribe(
                  lineas => {

                    this.lineas = lineas;

                    this.familias$ = this._familiaService.obtenerFamiliasProducto().subscribe(
                      familias => {

                        this.familias = familias;

                        this.marcas$ = this._marcaService.obtenerMarcas().subscribe(
                          marcas => {

                            this.marcas = marcas;

                            if (this.uid != '0') {
                              this.obtenerSolicitudCompra();
                              return;

                            }

                            this.loaderData = false;
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener marcas', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener familias', 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener lineas de producto', 'Ok');
                    this.loaderData = false;
                  }
                )

              },
              error => {
                this._snackBarService.showError('Error al obtener productos', 'Ok');
                this.loaderData = false;
              }
            )

          },
          error => {
            this._snackBarService.showError('Error al obtener aprobadores', 'OK');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener clientes', 'OK');
        this.loaderData = false;
      }
    )

  }

  obtenerSolicitudCompra(): void {
    this.solicitudCompra$ = this._solicitudCompraService.obtenerSolicitudCompra(this.uid).subscribe(
      solicitudCompra => {
        this.loaderData = false;
        this.solicitudCompra = solicitudCompra;
        this.dataSourceArticulo = fillTable(this.solicitudCompra.detalle_rcd, this.paginatorArticulo, this.sortArticulo);
      },
      error => {
        this._snackBarService.showError('Error al obtener solicitud de compra', 'Ok');
        this.loaderData = false;
      }
    )
  }

  esValidoFormulario(): boolean {
    return true;
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;

    if (!this.esValidoFormulario()) {
      this.loaderReg = false;
    }

    this.uid == '0' ? this.registrarSolicitudCompra(f) : this.actualizarSolicitudCompra(f);
  }

  agregarArticulo(): void {
    const articulo = new ItemDetalleSolicitudCompra();

    this.solicitudCompra.detalle_rcd.push(articulo);

    this.solicitudCompra.detalle_rcd.forEach((element, key) => {
      element.rcd_corrcd = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSourceArticulo = fillTable(this.solicitudCompra.detalle_rcd, this.paginatorArticulo, this.sortArticulo);
  }

  eliminarArticulo(): void {

    let row = this.solicitudCompra.detalle_rcd.find(item => item == this.selected);

    if (!row && this.solicitudCompra.detalle_rcd.length > 0) row = this.solicitudCompra.detalle_rcd[0];

    let details = [];

    this.solicitudCompra.detalle_rcd.forEach(element => {
      if (element.rcd_corrcd != row.rcd_corrcd) {
        details.push(element)
      }
    });

    this.solicitudCompra.detalle_rcd = details;

    this.solicitudCompra.detalle_rcd.forEach((element, key) => {
      element.rcd_corrcd = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSourceArticulo = fillTable(details, this.paginatorArticulo, this.sortArticulo);

    this.selected = null;

  }

  insertarArticulo(): void {

    const articulo = new ItemDetalleSolicitudCompra();

    let i = 0;

    if (this.selected) {
      this.solicitudCompra.detalle_rcd.forEach((item, index) => {
        if (item.rcd_corrcd == this.selected.PCD_CORPCD) i = index;
      })

      this.solicitudCompra.detalle_rcd.splice(i, 0, articulo);
    } else {
      console.log('sin seleccionar')
      this.solicitudCompra.detalle_rcd.push(articulo);
      this.solicitudCompra.detalle_rcd = this.solicitudCompra.detalle_rcd.reverse();
    }

    this.solicitudCompra.detalle_rcd.forEach((element, key) => {
      console.log('ids', key)
      element.rcd_corrcd = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    console.log('detalle', this.solicitudCompra.detalle_rcd)

    this.dataSourceArticulo = fillTable(this.solicitudCompra.detalle_rcd, this.paginatorArticulo, this.sortArticulo);
  }

  registrarSolicitudCompra(f: NgForm): void {

    this.sendForm$ = this._solicitudCompraService.registrarSolicitudCompra(this.solicitudCompra).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Solicitud de compra registrado', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al registrar solicitud de compra', 'Ok');
        this.loaderReg = false;
      }
    )

  }

  actualizarSolicitudCompra(f: NgForm): void {
    this.sendForm$ = this._solicitudCompraService.registrarSolicitudCompra(this.solicitudCompra).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Solicitud de compra actualizada', '300px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al actualizar solicitud de compra', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  copiarSolicitud(): void {

    const dialogRef = this.dialog.open(CopiarSolicitudDialog, {
      width: '250px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.uid = result;
        this.obtenerSolicitudCompra();
      }
    });

  }

  nuevoRegistro(): void {
    this.initialize();
    this.uid = '0';
  }

  moverAOrdenCompra(): void {

    const dialogRef = this.dialog.open(MoverAOrdenCompraDialog, {
      width: '300px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {

        let detalleOrdenCompra: Array<DetalleOcd> = [];

        this.solicitudCompra.detalle_rcd.forEach(item => {
          const itemDetalleOrdenCompra = new DetalleOcd();

          itemDetalleOrdenCompra.prd_codprd = item.prd_codprd;
          itemDetalleOrdenCompra.ocd_canocd = item.rcd_cansol;
          itemDetalleOrdenCompra.ocd_descri = item.rcd_detalle;
          itemDetalleOrdenCompra.eum_codume = item.ume_codume;
          itemDetalleOrdenCompra.ocd_preprv = item.rcd_preuni;
          itemDetalleOrdenCompra.ocd_imptot = item.rcd_imptot;
          itemDetalleOrdenCompra.ocd_fecreq = item.rcd_fecreq;
          itemDetalleOrdenCompra.cia_codcia = item.cia_codcia;

          detalleOrdenCompra.push(itemDetalleOrdenCompra);
        })

        localStorage.setItem('detalle_solicitud', JSON.stringify(detalleOrdenCompra));

      }
    });
  }

  mostrarPdf(id): void { }

  consultarStockMinimo(): void {
    const dialogRef = this.dialog.open(ConsultarStockMinimoDialog, {
      width: 'auto',
      data: {
        tiposInventario: this.tiposInventario,
        lineas: this.lineas,
        familias: this.familias,
        subfamilias: this.subfamilias,
        marcas: this.marcas,
        proveedores: this.clientes.filter(item => item.cli_indpro == 1),
      },
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  generarFormatoPDFSolicitudCompra(): void {
    pdfBuilder.generarFormatoSolicitudCompra({}, {});
  }

  volver(): void {
    this._router.navigate(['/modulo-compras/solicitudes-de-compra']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.sendForm$,
      this.clientes$,
      this.aprobadores$,
      this.solicitudCompra$,
      this.productos$
    ]);
  }

}
