import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root',
})
export class SubfamiliaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @param codigo
   * @returns
   */
  obtenerSubFamilias(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablasalmacen/listarsubfamilia/${this._configurationService.obtenerCompaniaCliente()}/${codigo}`
    ).pipe(
      map((subfamilias: any) => {
        return subfamilias.map(subfamilia => {
          subfamilia['sfp_codsfp'] = subfamilia['sfp_codsfp'].trim()
          subfamilia['description'] = `${subfamilia.sfp_codsfp} | ${subfamilia.sfp_descri}`
          subfamilia['description_reverse'] = `${subfamilia.sfp_descri} | ${subfamilia.sfp_codsfp}`
          return subfamilia;
        })
      })
    )
  }
}
