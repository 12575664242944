export class Responsable {

  codcia: string;
  idres: string;
  nombre: string;
  fecupd: Date;
  indsta: string;
  coduse: string;
  color: number;
  corele: string;
  codcli: string;
  usuario: string;
  hexcol: string;

  constructor() {
    this.fecupd = new Date();
    this.indsta = '1';
  }
}
