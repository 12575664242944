<div class="pav-container-notifications">
  <div class="pav-content-notifications">
    <h1>{{'general.menu.notifications.title' | translate}}</h1>
    <div class="pav-notifications">
      <div class="pav-notification-item" *ngFor="let notifiaction of notificaciones">
        <div class="pav-notification-icon">
          <mat-icon svgIcon="email"></mat-icon>
        </div>
        <div class="pav-notification-text-content">
          <div class="pav-description">Nueva solicitad registrada desde un dispositivo nuevo</div>
          <div class="pav-time">Hace 20 min</div>
        </div>
      </div>
    </div>
  </div>
</div>
