<app-pavso-title-section [title]="'Ordenes de compra'" [module]="'Compras'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todas las órdenes" url="/modulo-compras/orden-de-compra" entity=""
      [params]="['0']"></table-title-action>
    <mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>
    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" class="td-responsive" [style.color]="row.color" data-label="Acciones">

            <div class="pav-btns-container">
              <div class="pav-btn-circle-edit" matTooltip="Editar"
                [routerLink]="['/modulo-compras/orden-de-compra', row.ocm_numocm]">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
              <div class="pav-btn-circle-pdf" matTooltip="Ver PDF" (click)="mostrarPdf(row.ocm_numocm)">
                <mat-icon svgIcon="pdf"></mat-icon>
              </div>
            </div>

          </td>
        </ng-container>

        <ng-container matColumnDef="ocm_numocm">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. O/C </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. O/C" class="td-responsive"> {{row.ocm_numocm}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="toc_codtoc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo O/C </th>
          <td mat-cell *matCellDef="let row" data-label="Tipo O/C" class="td-responsive"> {{row.toc_codtoc}} </td>
        </ng-container> -->

        <ng-container matColumnDef="ocm_fecocm">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
          <td mat-cell *matCellDef="let row" data-label="Fecha" class="td-responsive"> {{row.ocm_fecocm | date:
            'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="cli_nomcli">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
          <td mat-cell *matCellDef="let row" data-label="Dirección" class="td-responsive"> {{row.cli_nomcli}} </td>
        </ng-container>

        <ng-container matColumnDef="tmo_codtmo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
          <td mat-cell *matCellDef="let row" data-label="Dirección" class="td-responsive"> {{row.tmo_codtmo == 'SO' ?
            'SOLES': 'DÓLARES'}} </td>
        </ng-container>

        <ng-container matColumnDef="ocm_imptot">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
          <td mat-cell *matCellDef="let row" data-label="Dirección" class="td-responsive">
            <div [ngStyle]="{width: '60px', textAlign: 'right'}">

              {{row.ocm_imptot.toFixed(2)}}
            </div>
          </td>
        </ng-container>


        <ng-container matColumnDef="ocm_aprcom">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Está aprobado? </th>
          <td mat-cell *matCellDef="let row" data-label="Está aprobado?" class="td-responsive">
            <!-- {{row.ocm_aprcom == 1 ? 'APROBADO': 'NO APROBADO'}} -->
            <mat-chip-list aria-label="Fish selection">
              <mat-chip selected *ngIf="row.ocm_aprcom == 0" class="sc_no_vigente">NO APROBADO</mat-chip>
              <mat-chip selected *ngIf="row.ocm_aprcom == 1" class="sc_vigente">APROBADO</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

    <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>

  </div>
  <br>
  <div class="row">
    <!-- <div class="col s12">
      <div class="mat-elevation-z0">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Tabla
          </div>

          <div class="pav-input-search">
          </div>
        </div>
        <div class="overflow-x">
          <table mat-table [dataSource]="dataSource1" matSort matSort>

            <ng-container matColumnDef="ver_req">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver Req.</th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.ocm_numocm}} </td>
            </ng-container>

            <ng-container matColumnDef="num_req">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Req. </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.toc_codtoc}} </td>
            </ng-container>

            <ng-container matColumnDef="fec_emision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ocm_fecocm | date}} </td>
            </ng-container>

            <ng-container matColumnDef="fec_pago">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Pago </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="moneda">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="importe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
              <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6" *ngIf="!loader1">No se encontraron registros</td>
              <td class="mat-cell" colspan="6" *ngIf="loader1">
                <mat-spinner diameter="40"></mat-spinner>
              </td>
            </tr>

          </table>
        </div>

        <mat-paginator #matPaginator1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div> -->
    <!-- <div class="col s12">
      <div class="mat-elevation-z0">

        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Tabla
          </div>

          <div class="pav-input-search">
          </div>
        </div>
        <div class="overflow-x">

          <table mat-table [dataSource]="dataSource2" matSort matSort>

            <ng-container matColumnDef="ver_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Ver Doc."> {{row.ocm_numocm}} </td>
            </ng-container>

            <ng-container matColumnDef="tipo_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Tipo Doc."> {{row.toc_codtoc}} </td>
            </ng-container>

            <ng-container matColumnDef="num_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Documento </th>
              <td mat-cell *matCellDef="let row" data-label="Num. Documento"> {{row.ocm_fecocm | date}} </td>
            </ng-container>

            <ng-container matColumnDef="fec_emision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Fec. Emisión"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="moneda">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="importe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
              <td mat-cell *matCellDef="let row" data-label="Importe"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="abono">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Abono </th>
              <td mat-cell *matCellDef="let row" data-label="Abono"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="saldo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo </th>
              <td mat-cell *matCellDef="let row" data-label="Saldo"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <ng-container matColumnDef="codigo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código"> {{row.ldc_dirldc}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9" *ngIf="!loader2">No se encontraron registros</td>
              <td class="mat-cell" colspan="9" *ngIf="loader2">
                <mat-spinner diameter="40"></mat-spinner>
              </td>
            </tr>

          </table>

        </div>

        <mat-paginator #matPaginator2 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div> -->
  </div>
</div>
<br>
