<app-pavso-title-section [title]="'Condición de pago'" [module]="'Compras'" [usuario]="condicionPago.CPA_CODUSE" [fecha]="condicionPago.CPA_FECUPD" [ngClass]="'pav-form'" [estado]="condicionPago.CPA_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Código</mat-label>
                      <input [(ngModel)]="condicionPago.CPA_CODCPA" type="text" matInput placeholder="Código" name="CPA_CODCPA" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6"></div>
              <div class="col s12 m12 l6">
                  <mat-form-field>
                      <mat-label>Nombre</mat-label>
                      <input type="text" [(ngModel)]="condicionPago.CPA_DESCRI" matInput placeholder="Nombre" name="CPA_DESCRI" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6">
                  <mat-form-field>
                      <mat-label>Nom. Abreviado</mat-label>
                      <input type="text" [(ngModel)]="condicionPago.CPA_DESABR" matInput placeholder="Nom. Abreviado" name="CPA_DESABR" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                  <mat-form-field>
                      <mat-label>Plazo</mat-label>
                      <input type="text" [(ngModel)]="condicionPago.CPA_CODCPA" matInput placeholder="Plazo" name="plazo" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                  <mat-form-field>
                      <mat-label>Grupo</mat-label>
                      <mat-select [(ngModel)]="condicionPago.CPA_DIAPLA" required name="CPA_DIAPLA" [disabled]="loaderData">
                          <mat-option value="1">
                              CREDITO
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                  <mat-form-field>
                      <mat-label>Nro. Cuotas</mat-label>
                      <input type="text" matInput placeholder="Nro. Cuotas" name="nro_cuotas" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
          </div>
          <div class="row">
              <div class="col s12">
                  <section class="example-section">
                      <mat-checkbox [(ngModel)]="condicionPago.CPA_INDVTA" name="CPA_INDVTA">Venta</mat-checkbox>
                      &nbsp;
                      <mat-checkbox [(ngModel)]="condicionPago.CPA_INDCOM" name="CPA_INDCOM">Compra</mat-checkbox>
                      &nbsp;
                      <mat-checkbox [(ngModel)]="condicionPago.CPA_INDCUO" name="CPA_INDCUO">Cuota</mat-checkbox>
                      &nbsp;
                      <mat-checkbox [(ngModel)]="condicionPago.cpa_indper" name="cpa_indper">Apr. Pedido</mat-checkbox>
                      &nbsp;
                      <mat-checkbox [(ngModel)]="condicionPago.CPA_INDCRE" name="CPA_INDCRE">Crédito</mat-checkbox>
                  </section>
              </div>
          </div>

      </mat-card>

  </div>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-compras/condiciones-de-pago">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
