export class LugarDespachoCIA {
  codigo: string;
  estado: string;
  descripcion: string;
  direccion: string;
  ubicacion: string;
  coduse: string;
  fecupd: string;

  constructor() {
    this.estado = '1';
  }
}
