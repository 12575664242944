import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { SnackBarService } from "@shared/services/snackbar.service";
import { CuentaCobrarService } from "../../api/cuentaxcobrar.service";
import { Subscription } from "rxjs";
import { unsubscribeSubscription } from "@utils/others/subscription";
import * as pdfBuilder from "@utils/pdfmaker/builder/cobranzas.pdfmaker";
import { ConfigurationService } from "../../configuration.service";
import { CuentaCobrarExcelService } from "../../reporte-excel/cuenta-cobrar-excel.service";

@Injectable({
  providedIn: 'root'
})
export class CuentaCobrarReportService implements OnInit, OnDestroy {

  reporte$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _cuentaCobrarService: CuentaCobrarService,
    private _configurationService: ConfigurationService,
    public ete: CuentaCobrarExcelService,
  ) { }

  ngOnInit(): void { }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporte(reportSelected: string, headers: any, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "COBRANZAS_DC":

          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteDocumentoCobrarDocConSaldo(payload).subscribe(
            response => {

              // let filtrados = response.filter(item => item.cco_impsal > 0)
              // let filtrados = this.filtrarPorSaldoAbonado(response, headers.criterio)

              // console.log('response', filtrados)

              if (response.length > 0) {
                pdfBuilder.generarPDFDocumentoCobrarDocConSaldo(headers, response);
                resolve(true);
                return;
              }

              this._snackBarService.showError(`No se encontró información para este período o filtro`, "OK");
              reject(false);

            },
            error => {
              this._snackBarService.showError("Error al obtener reporte acumulado flujo de caja por categoría", "OK");
              reject(false);
            }
          )

          break;
        case "COBRANZAS_DCG":
        this.reporte$ = this._cuentaCobrarService.obtenerDataReporteDocumentoCobrarDocConSaldoPorGrupo(payload).subscribe(
          response => {

            // let filtrados = response.filter(item => item.cco_impsal > 0)
            // let filtrados = this.filtrarPorSaldoAbonado(response, headers.criterio)

            // console.log('response', filtrados)

            if (response.length > 0) {
              pdfBuilder.generarPDFDocumentoCobrarDocConSaldoPorGrupo(headers, response);
              resolve(true);
              return;
            }

            this._snackBarService.showError(`No se encontró información para este período o filtro`, "OK");
            reject(false);

          },
          error => {
            this._snackBarService.showError("Error al obtener reporte acumulado flujo de caja por categoría", "OK");
            reject(false);
          }
        )
          break;
        case "COBRANZAS_MC":

          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteMovimientosCobranza(headers.codano, headers.codmes).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFMovimientosDeCobranza(headers, response);
                resolve(true);
                return;
              }

              this._snackBarService.showError(`No se encontró información para este período ${headers.codano}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte movimientos de cobranza", 'OK');
              reject(false);
            }
          )

          break;
        case "COBRANZAS_PEC":
          pdfBuilder.generarPDFPagoEfectuadoCliente(headers, {});
          resolve(true)

          return;
          this.reporte$ = this._cuentaCobrarService.obtenerDataReportePagoEfectuadoCliente(headers.year).subscribe(
            response => {
              if (response.data.length > 0) {
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de detallado de flujo de caja', 'OK');
              reject(false);
            }
          )
          break;
        case "COBRANZAS_ECCli":

          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteEstadoCuentaCliente(headers).subscribe(
            response => {
              if (response.length > 0) {
                pdfBuilder.generarPDFEstadoCuentaCliente(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.codano} - ${headers.codmes}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de movimiento de caja y banco saldo inicial", "OK");
              reject(false);
            }
          )
          break;
        case "COBRANZAS_TCC":

          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteTabuladoCuentaPorCobrar(payload).subscribe(
            response => {

              if (response.length > 0) {
                pdfBuilder.generarPDFTabuladoCuentaPorCobrar(headers, response);
                resolve(true);
                return;
              }


              this._snackBarService.showError(`No se encontró información para este período ${headers.codano} - ${headers.codmes}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de cuenta por pagar tabulado con saldo", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param ohterData
   * @returns
   */
  verReporteDinamico(reportSelected: string, headers: any, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case 'COBRANZAS_ECC':
          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteDinamicoDocumentoCobrar().subscribe(
            data => {

              console.log('informe de documentos cobrar dinamico', data)
              if (data.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              const columnasSeleccionadas = data.map(item => {

                let obj = {}

                otherData.columns.forEach(element => {
                  if (element.completed) {
                    obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
                  }
                })

                return obj;

              })

              let reportData = {
                title: `Informe de documentos por cobrar ${headers.year} ${headers.month}`,
                data: columnasSeleccionadas,
              }

              this.ete.exportExcelDocumentosPorCobrar(reportData);

              resolve(true)
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte dinámico de documentos por cobrar", "OK");
              reject(false);
            }
          )
          break;
        case 'COBRANZAS_MC':
          console.log('headers.codcli', headers.codcli)
          console.log('headers.fecini', headers.fecini)

          if (!headers.codcli || headers.codcli.length == 0) {
            this._snackBarService.showError('Seleccionar un cliente', 'Ok');
            reject(false)
            return;
          }

          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteDinamicoMovimientosCobranza(headers).subscribe(
            data => {
              console.log('informe de documentos cobrar dinamico', data)
              if (data.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              const columnasSeleccionadas = data.map(item => {

                let obj = {}

                otherData.columns.forEach(element => {
                  if (element.completed) {
                    obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
                  }
                })

                return obj;

              })

              let reportData = {
                title: `Informe de movimientos cobranza ${headers.year} ${headers.month}`,
                data: columnasSeleccionadas,
              }

              this.ete.exportExcelMovimientosCobranza(reportData);

              resolve(true)
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte dinámico de documentos por cobrar", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  filtrarPorSaldoAbonado(arreglo: Array<any>, criterio: 'TODOS' | 'CON_SALDO' | 'ABONADO' | 'CANCELADOS'): Array<any> {

    let filtradoAbonadoSaldo = [];

    if (criterio == 'TODOS') {
      filtradoAbonadoSaldo = arreglo;
    }

    if (criterio == 'CON_SALDO') {
      filtradoAbonadoSaldo = arreglo.filter(item => item.cco_impsal != 0)
    }

    if (criterio == 'ABONADO') {
      filtradoAbonadoSaldo = arreglo.filter(item => item.cco_impabo != 0)
    }

    if (criterio == 'CANCELADOS') {
      filtradoAbonadoSaldo = arreglo.filter(item => item.cco_impsal == 0)
    }

    return filtradoAbonadoSaldo;
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.reporte$
    ])
  }
}
