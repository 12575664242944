<app-pavso-title-section [title]="'Bancos'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

  <div class="mat-elevation-z0">
    <table-title-action title="Todos los bancos" url="/modulo-general/banco" entity="" [params]="['0']"></table-title-action>

    <table mat-table [dataSource]="dataSource" matSort >

        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                <button matTooltip="Editar" [routerLink]="['/modulo-general/banco', row.BAN_CODBAN]" mat-icon-button color="primary">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="codigo_banco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.BAN_CODBAN}} </td>
        </ng-container>

        <ng-container matColumnDef="descri_banco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.BAN_NOMBRE}} </td>
        </ng-container>

        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
            <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.BAN_INDSTA == '1')? "VIGENTE": "ANULADO"}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>
        </tr>

    </table>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
