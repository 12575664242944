<app-pavso-title-section [title]="'Comprobantes básicos'" [module]="'Ventas'"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="buscarComprobante()" (keydown.enter)="$event.preventDefault()">
    <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m3">
                <mat-form-field>
                    <mat-label>Fecha Inicio - Fecha Final</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-form-field>
            </div>

            <div class="col s12 m3">
                <button mat-flat-button color="primary" type="submit">Buscar</button>
            </div>
        </div>
    </mat-card>
</form>
<br>
<div class="pavso-content-form fade-in-image">
    <mat-form-field class="pavso_input_search">
        <mat-label>{{LABELS_NAME.BTN_SEARCH}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

    &nbsp;

    <button (click)="seleccionarTipo()" class="pavso_btn_add" mat-flat-button color="primary" type="button">
        <mat-icon>add</mat-icon>
        {{LABELS_NAME.BTN_ADD}}
    </button>

    <div class="mat-elevation-z0 overflow-x">
        <mat-card class="mat-elevation-z0 pavso_card_more_options">
            Todos los comprobantes
            <button class="pavso_more_options" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    class="pavso_more_options"
                    color="primary"
                    >
                    <mat-icon>cloud_download</mat-icon>
                    <span>Exportar a Excel</span>
                </button>

            </mat-menu>
        </mat-card>
        <table mat-table [dataSource]="dataSource" matSort >

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <button type="button" (click)="editarComprobante(row.cco_codcco)" matTooltip="Editar Comprobante" mat-icon-button color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button matTooltip="Generar XML" mat-icon-button color="primary">
                        <span class="name-button">XML</span>
                    </button>
                    <button (click)="obtenerPdf()" matTooltip="Visualiza PDF" mat-icon-button color="warn">
                        <mat-icon>picture_as_pdf</mat-icon>
                    </button>
                    <button matTooltip="Enviar Sunat" mat-icon-button color="accent">
                        <span class="name-button">SUNAT</span>
                    </button>
                    <button matTooltip="Enviar Correo" mat-icon-button color="primary">
                        <mat-icon>email</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="cco_codcco">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo </th>
                <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.cco_codcco}} </td>
            </ng-container>

            <ng-container matColumnDef="Fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let row" data-label="Fecha" class="td-responsive"> {{row.Fecha}} </td>
            </ng-container>

            <ng-container matColumnDef="TipDoc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
                <td mat-cell *matCellDef="let row" data-label="Doc." class="td-responsive"> {{row.TipDoc}} </td>
            </ng-container>

            <ng-container matColumnDef="NumDoc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Doc. </th>
                <td mat-cell *matCellDef="let row" data-label="Num. Doc." class="td-responsive"> {{row.NumDoc}} </td>
            </ng-container>

            <ng-container matColumnDef="Mon">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
                <td mat-cell *matCellDef="let row" data-label="Moneda" class="td-responsive"> {{row.Mon}} </td>
            </ng-container>

            <ng-container matColumnDef="Importe">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                <td mat-cell *matCellDef="let row" data-label="Importe" class="td-responsive"> {{row.Importe}} </td>
            </ng-container>

            <ng-container matColumnDef="IdCliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RUC </th>
                <td mat-cell *matCellDef="let row" data-label="RUC" class="td-responsive"> {{row.IdCliente}} </td>
            </ng-container>

            <ng-container matColumnDef="Cliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Cliente </th>
                <td mat-cell *matCellDef="let row" data-label="Nombre Cliente" class="td-responsive"> {{row.Cliente}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
