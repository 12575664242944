import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexPlotOptions, ApexTitleSubtitle, ApexTooltip, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import { AuthenticationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  noData: ApexNoData;
};

@Component({
  selector: 'app-composicion-cantidad-personas',
  templateUrl: './composicion-cantidad-personas.component.html',
  styleUrls: ['./composicion-cantidad-personas.component.css']
})
export class ComposicionCantidadPersonasComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  displayedColumns1: string[] = ['initial', 'ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'set', 'oct', 'nov', 'dic'];
  dataSource1 = [];

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;
  public chartOptions1: any;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  nombreTipoNomina: string;

  apexChart: ApexCharts;
  apexChartAC: ApexCharts;

  period$: Subscription;
  nomina$: Subscription;
  loading$: Subscription;
  informe$: Subscription;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _nominaService: NominaService,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month, codMod}) => {
      this.year = year;
      this.month = month;
    })

    this.nomina$ = this.store.select('nomina').subscribe(state => {
      this.nombreTipoNomina = state.nameTypeNomina;
    })

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource1 = [
      {
        initial: "CESADOS (C)", ene: 0, feb: 0, mar: 0, abr: 0, may: 0, jun: 0, jul: 0, ago: 0, set: 0, oct: 0, nov: 0, dic: 0
      },
      {
        initial: "VIGENTES (V)", ene: 0, feb: 0, mar: 0, abr: 0, may: 0, jun: 0, jul: 0, ago: 0, set: 0, oct: 0, nov: 0, dic: 0
      },
      {
        initial: "TOTAL COLABORADORES", ene: 0, feb: 0, mar: 0, abr: 0, may: 0, jun: 0, jul: 0, ago: 0, set: 0, oct: 0, nov: 0, dic: 0
      }
    ]

  }

  ngOnInit(): void {

    // INICIALIZAR GRÁFICO
    this.chartOptions = {
      series: [],
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function(chart, w, e) {
          }
        }
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#00E396",
        "#FEB019",
        "#FF4560",
      ],
      noData: {
        text: 'Cargando...'
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        show: true,
        position: "right"
      },
      grid: {
        show: true
      },
      yaxis: {
        title: {
          text: "# Colaboradores",
        },
        labels: {
          style: {
            fontSize: "12px"
          },
        }
      },
      xaxis: {
        categories: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Oct",
          "Nov",
          "Dic",
        ],
        labels: {
          style: {
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#00E396",
              "#FEB019",
              "#FF4560",
            ],
            fontSize: "12px"
          },
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " ";
          }
        }
      },
      title: {
        text: `Cantidad Colaboradores`,
        align: "center",
        style: {
          color: "#444"
        }
      }
    }

    this.apexChart = new ApexCharts(document.querySelector("#chartCantidadTrabajadores"), this.chartOptions);

    this.apexChart.render();

    this.chartOptions1 = {
      series: [],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      noData: {
        text: 'Cargando...'
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Oct",
          "Nov",
          "Dic",
        ]
      },
      yaxis: {
        formatter: function(val) {
          return val.toFixed(0);
        },
        title: {
          text: "# Colaboradores"
        },

      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "" + val + " ";
          }
        }
      },
      title: {
        text: `# Colaboradores Vigentes y Cesados`,
        // offsetY: 320,
        align: "center",
        style: {
          color: "#444"
        }
      }
    }

    this.apexChartAC = new ApexCharts(document.querySelector("#chartCantidadVigenteCesado"), this.chartOptions1);

    this.apexChartAC.render();

    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) {
        this.loadData();
      }
    })

  }

  loadData(): void {

    this.informe$ = this._nominaService.obtenerInformeCantidadTrabajadores(this.year).subscribe(
      response => {
        this.dataSource1 = response.datatable2;
        this.apexChart.updateSeries([response.chart]);
        this.apexChart.updateOptions({
          title: {
            text: `Cantidad Colaboradores por mes`,
            align: "center",
            style: {
              color: "#444"
            }
          }
        });

        this.apexChartAC.updateSeries(response.chart2);
        this.apexChartAC.updateOptions({
          title: {
            text: `# Colaboradores Vigentes y Cesados`,
            align: "center",
            style: {
              color: "#444"
            }
          }
        });
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de documento', 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/informes-de-composicion']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.nomina$, this.loading$, this.informe$]);
  }

}
