export class Voucher {
  CIA_CODCIA: any;
  CCO_CODCCO: any;
  CLI_CODCLI: string;
  CLI_CODDOC: any;
  TDO_CODTDO: any;
  CCO_NUMDOC: any;
  CCO_GLOCCO: any;
  CCO_FECEMI: any;
  CCO_FECVEN: any;
  TMO_CODTMO: any;
  CCN_CODCCN: any;
  CCO_IMPDOC: any;
  CCO_IMPCCO: any;
  CCO_SALCCO: any;
  CCO_SALLBA: any;
  CCO_SALRES: any;
  CCO_IMPABO: any;
  CCO_IMPIGV: any;
  CCO_TASIGV: any;
  CCO_IMPCOR: any;
  CCO_IMPINA: any;
  CCO_IMPAFE: any;
  CCO_INDTCA: any;
  CCO_TIPCAM: any;
  CCO_CODPAG: any;
  MMO_CODMMO: any;
  CPA_CODCPA: any;
  TDO_DOCREF: any;
  CCO_NUMREF: any;
  ANO_CODANO: any;
  MES_CODMES: any;
  SCO_CODSCO: any;
  LDC_CORLDC: any;
  SCO_CODASI: any;
  LDC_CORASI: any;
  CCO_FECREG: any;
  AGE_CODAGE: any;
  CCO_ABOTRA: any;
  CCO_CODANT: any;
  CCO_INDSTA: string;
  CCO_FECUPD: any;
  CCO_CODUSE: string;
  CCO_IMPRET: any;
  CCO_ABORET: any;
  CCO_RETGAS: any;
  IRI_CODIRI: any;
  SUC_CODSUC: any;
  TGA_CODTGA: any;
  CCO_FECREF: any;
  OCM_NUMOCM: any;
  cco_numcuo: any;
  ipi_codipi: any;
  CCO_IMPPER: any;
  CCO_PERMNA: any;
  CCO_MOVIL: any;
  cco_cbmoti: any;
  cco_condic: any;
  cco_code: any;
  CCO_INDNUE: any;
  detalle_dcc: Array<DetalleDCCVoucher>;
  detalle_productos: Array<DetalleProductoVoucher>;
  detalle_cobranza: any;
  constructor(){
    this.detalle_dcc = [new DetalleDCCVoucher()]
    this.detalle_productos = [];
    this.CCO_FECREG = new Date();
    this.CCO_IMPAFE = 0;
    this.CCO_IMPINA = 0;
    this.CCO_TASIGV = 18;
    this.TMO_CODTMO = "SO";
    this.MMO_CODMMO = "0206";
    this.CCO_INDSTA = "1";
    this.ANO_CODANO = ((new Date()).getFullYear()).toString();
    let mes = ((new Date()).getMonth() + 1).toString();
    this.MES_CODMES = (mes.length == 1)? "0" + mes: mes;
    this.SUC_CODSUC = "01";
    this.CCO_ABORET = 0;
    this.CCO_ABOTRA = 0;
    this.CCO_FECEMI = new Date();
    this.CCO_FECUPD = new Date();
    this.CCO_FECVEN = new Date();
    this.CCO_IMPCOR = 0;
    this.CCO_IMPPER = 0;
    this.CCO_IMPRET = 0;
    this.CCO_INDNUE = 1;
    this.CCO_INDTCA = "0";
    this.CCO_NUMREF = "";
    this.CCO_PERMNA = 0;
    this.CCO_RETGAS = 0;
    this.CCO_SALCCO = 0;
    this.CCO_SALLBA = 0;
    this.CCO_SALRES = 0;
    this.CCO_CODPAG = "C";
    this.LDC_CORLDC = null;
    this.SCO_CODSCO = "0501";
  }
}

export class DetalleDCCVoucher {
  CLI_CODAVA: any;
  CLI_CODAV1: any;
  DCC_INDCON: any;
  DCC_NUMOPE: any;
  DCC_TASISC: any;
  DCC_IMPISC: any;
  VDE_CODVDE: any;
  DCC_DOCDCC: any;
  BCO_CODBCO: any;
  CBA_CODCBA: any;
  DCC_INDCAL: any;
  DCC_IMPBRU: any;
  DCC_PORDES: any;
  DCC_IMPDES: any;
  DCC_SITDCC: any;
  DCC_ORDCOM: any;
  DCC_INDTEX: any;
  SUC_CODSUC: any;
  PCC_NUMPCC: any;
  DCC_VENDIF: any;
  DCC_IMPSEG: any;
  DCC_IMPFLE: any;
  DCC_INDEXP: any;
  ZVE_CODZVE: any;
  DCC_OBSFAC: any;
  EDO_CODEDO: any;
  DCC_NUMCUO: any;
  CCS_CODCCS: any;
  TLP_CODTLP: any;
  LPC_CODLPC: any;
  DCC_INDTCA: any;
  DCC_INDIMP: any;
  DCC_INDCOM: any;
  DCC_INDDPX: any;
  EGV_NUMEGV: any;
  DCC_FECDES: any;
  TIE_CODTIE: any;
  DCC_FECENT: any;
  PAI_CODVEN: any;
  UBI_CODVEN: any;
  DCC_ATEFAC: any;
  DCC_DIAPLA: any;
  DCC_FECACE: any;
  MAU_CODPED: any;
  DCC_VALDIF: any;
  DCC_IMPDIF: any;
  CCO_CODOTR: any;
  DCC_INDPSA: any;
  ALM_CODALM: any;
  SDO_CODSDO: any;
  DCC_TCALIB: any;
  DCC_IMPABO: any;
  DCC_IMPVUE: any;
  DCC_INDSTA: string;
  TBD_NUMTBD: any;
  DCC_INDFGI: any;
  DCC_INDSER: any;
  DCC_PORCOM: any;
  DCC_IMPCOM: any;
  DCC_COMABO: any;
  DCC_COMABA: any;
  DCC_SALCOM: any;
  DCC_FECUPD: any;
  DCC_CODUSE: string;
  LDE_CODLDE: any;
  CCO_CODPLA: any;
  CCN_CUELBA: any;
  LDE_CODDES: any;
  DCC_VTAAJU: any;
  DCC_IGVAJU: any;
  DCC_TOTAJU: any;
  DCC_OBSANU: any;
  DCC_INDENT: any;
  DCC_ENTDES: any;
  UBD_CODUBD: any;
  SIT_CODSIT: any;
  DCC_INCOB: any;
  DCC_CODCON: any;
  DCC_DESGLO: any;
  DCC_NROBAN: any;
  lcc_codlcc: any;
  sdc_codsdc: any;
  dcc_tiplet: any;
  DCC_IMPANT: any;
  dcc_tdoref: any;
  dcc_nroref: any;
  tim_codtim: any;
  iri_codiri: any;
  dcc_Tasdet: any;
  dcc_impdet: any;
  t59_codt59: any;
  DCC_VALVTA: any;
  constructor(
  ){
    this.DCC_ATEFAC = 0;
    this.DCC_INDCAL = "1";
    this.DCC_COMABA = 0;
    this.DCC_COMABO = 0;
    this.DCC_DESGLO = 0;
    this.DCC_DIAPLA = 0;
    this.DCC_ENTDES = 0;
    this.DCC_FECENT = new Date();
    this.DCC_FECUPD = new Date();
    this.DCC_IGVAJU = 0;
    this.DCC_IMPABO = 0;
    this.DCC_IMPANT = 0;
    this.DCC_IMPCOM = 0;
    this.DCC_IMPDIF = 0;
    this.DCC_IMPFLE = 0;
    this.DCC_IMPISC = 0;
    this.DCC_IMPSEG = 0;
    this.DCC_IMPVUE = 0;
    this.DCC_INCOB = "0";
    this.DCC_INDCOM = 0;
    this.DCC_INDDPX = 0;
    this.DCC_INDENT = 0;
    this.DCC_INDPSA = 1;
    this.DCC_INDSER = 0;
    this.DCC_INDIMP = 0;
    this.DCC_INDSTA = "1";
    this.DCC_INDTCA = "0";
    this.DCC_NUMCUO = 0;
    this.DCC_OBSFAC = "";
    this.DCC_PORCOM = 0;
    this.DCC_PORDES = 0;
    this.DCC_SALCOM = 0;
    this.DCC_TASISC = 0;
    this.DCC_TOTAJU = 0;
    this.DCC_VALDIF = 0;
    this.DCC_VTAAJU = 0;
    this.EDO_CODEDO = "1";
    this.PAI_CODVEN = "01";
    this.SIT_CODSIT = "0";
    this.SUC_CODSUC = "01";
    this.DCC_IMPVUE = 0;
    this.DCC_IMPABO = 0;
  }
}

export class DetalleProductoVoucher {
  PRD_CODPRD: any;
  DCD_SECDCD: any;
  DCD_CORDCD: any;
  DCD_CANDCD: any;
  DCD_PREUNI: any;
  DCD_PRUIGV: any;
  DCD_IMPBRU: any;
  DCD_PORDES: any;
  DCD_IMPDES: any;
  DCD_VALVTA: any;
  DCD_IMPIGV: any;
  DCD_IMPTOT: any;
  DCD_GLODCD: any;
  DCD_OBSDES: any;
  PAR_CODPAR: any;
  DCD_CODTES: any;
  DCD_TIPPRE: any;
  SRV_CODSRV: any;
  DCM_CODDCM: any;
  DCD_CANDES: any;
  DCD_CANREG: any;
  DCD_PREPED: any;
  DCD_PRUPED: any;
  DCD_CANPED: any;
  DCD_VALDIF: any;
  DCD_IMPDIF: any;
  DCD_USUPRE: any;
  DCD_FECPRE: any;
  DCD_CODUSE: string;
  DCD_FECUPD: any;
  DCD_CANVEN: any;
  UME_CODVEN: any;
  DCD_INDADE: any;
  PRM_CODPRM: any;
  prec: any;
  DCD_DESGLO: any;
  DCD_GLOESP: any;
  DCD_TASPER: any;
  DCD_IMPPER: any;
  DCD_CANDEV: any;
  DCD_VENDEV: any;
  prd_desesp: any;
  detalle_descuento: Array<DetalleDescuentoVoucher>;
  detalle_serie: null;
  detalle_talla: null;
  constructor(
  ) {}
}

export class DetalleDescuentoVoucher {
  dco_coddco: any
  ddd_pordes: any // discount
  ddd_impdes: any // amount
  ddd_corddd: any // correlativo
  ddd_impaju: any // 0
  ddd_incdes: any // importe de descuento incluido IGV
  dcm_numdcm: any // NULL
  ddd_fecupd: any
  ddd_coduse: string
  ddd_indsta: string
  constructor(
  ) {}
}

export class DetalleCobranza {
    constructor() {}
}
