<div class="mat-elevation-z0 b-r-15">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row" data-label="Código"> {{row.name}} </td>
    </ng-container>

    <ng-container matColumnDef="mn">
      <th mat-header-cell *matHeaderCellDef> M.N. </th>
      <td mat-cell *matCellDef="let row" data-label="Código"> {{row.mn.toFixed(2)}} </td>
    </ng-container>

    <ng-container matColumnDef="me">
      <th mat-header-cell *matHeaderCellDef> M.E. </th>
      <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.me.toFixed(2)}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky" [ngStyle]="{background: 'rgb(108, 214, 223)'}"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No se encontraron registros</td>
    </tr>

  </table>
</div>
