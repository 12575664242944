<app-pavso-title-section [title]="'Lugar de despacho'" [module]="'Compras'" [usuario]="lugarDespacho.LDC_CODUSE" [fecha]="lugarDespacho.LDC_FECUPD" [ngClass]="'pav-form'" [estado]="lugarDespacho.LDC_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="lugarDespacho.LDC_CODLDC" matInput placeholder="Código" name="LDC_CODLDC" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6"></div>

        <div class="col s12 m12 l12">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput [(ngModel)]="lugarDespacho.LDC_NOMLDC" placeholder="Descripción" name="LDC_NOMLDC" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12">
          <mat-form-field>
            <mat-label>Dirección</mat-label>
            <input type="text" matInput [(ngModel)]="lugarDespacho.LDC_DIRLDC" placeholder="Dirección" name="LDC_DIRLDC" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l12">
          <mat-form-field>
            <mat-label>Ubicación</mat-label>
            <input type="text" matInput [(ngModel)]="lugarDespacho.UBI_CODUBI" placeholder="Ubicación" name="UBI_CODUBI" required [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
  </div>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-compras/lugares-de-despacho">
    <div id="botonesAdicionales">
    </div>
  </pav-form-actions>
</form>
