import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AlmacenService, VentasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';

@Component({
  selector: 'app-confirm-bill-create',
  templateUrl: './confirm-bill-create.component.html',
  styleUrls: ['./confirm-bill-create.component.css']
})
export class ConfirmBillCreateComponent implements OnInit, OnDestroy {

  tipoDocumentosVenta = [];

  generarDocumento = true;
  generarGuia = true;

  tipoDeDocumento: any;
  serie: any;
  nroDocumentoVenta:any;
  date: any = new Date();
  nroDocumento: any;

  serieDoc$: Subscription;
  correlativo$: Subscription;
  tipoDoc$: Subscription;

  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    public dialogRef: MatDialogRef<ConfirmBillCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _almacenService: AlmacenService,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.obtenerTipoDocumentoVenta();
    this.generarGuia = this.data.generarSalida;
    this.tipoDeDocumento  = this.data.pedido.pcc_codtdo;
    this.seleccionarTipo(this.tipoDeDocumento);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  registrarFactura(): void {

  }

  obtenerTipoDocumentoVenta(): void {
    this.tipoDoc$ = this._ventasService.listarTipoDocumentosVenta().subscribe(
      response => this.tipoDocumentosVenta = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  seleccionarTipo(tipo) {
    this.serieDoc$ = this._almacenService.listarSerieDocumentos(tipo).subscribe(
      response => {
        this.serie = response[1].sdo_codsdo;

        this.correlativo$ = this._ventasService.obtenerCorrelativoComprobante(tipo, this.serie).subscribe(
          response => this.nroDocumentoVenta = response[0].nro_doc
        )
      },
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.serieDoc$,
      this.correlativo$,
      this.tipoDoc$
    ])
  }

}
