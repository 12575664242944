<app-pavso-title-section [title]="'Informes dinámicos'" [module]="'Planilla'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>

  <div class="pav-filtro-header" *ngIf="indicadorCabecera">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Seleccionar columnas</div>
  </div>

  <mat-card class="mat-elevation-z0" *ngIf="indicadorCabecera">
    <mat-card-content>
      <div class="row">
        <div class="col s7">
          <mat-form-field>
            <mat-label>Selección registrada</mat-label>
            <mat-select multiple [disabled]="loading" [(ngModel)]="seleccion" name="seleccion">
              <mat-option *ngFor="let seleccion of selecciones"
                [value]="seleccion.FPR_CODFPR">{{seleccion.fpr_descri}}</mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-stroked-button (click)="grabar()" color="primary" [disabled]="loading"
            [ngStyle]="{background: '#6cd6df', width: isMobile || isTablet ? '100%': '180px', height: '45px', color: '#222'}"
            matTooltip="Agregar registro manualmente">
            <mat-icon>add</mat-icon>
            Grabar
          </button>
        </div>
        <div class="col s5">
          <section class="example-section">
            <span class="example-list-section">
              <mat-checkbox class="example-margin"
                [checked]="allComplete"
                color="accent"
                [indeterminate]="someComplete()"
                (change)="setAll($event.checked)">
                {{task.name}}
              </mat-checkbox>
            </span>
            <span class="example-list-section">
              <div class="row">
                <div *ngFor="let subtask of task.subtasks" class="col s6 m6 l3">
                  <div>
                    <mat-checkbox [(ngModel)]="subtask.completed"
                                  color="accent"
                                  (ngModelChange)="updateAllComplete()">
                      {{subtask.name | titlecase}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </span>
          </section>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <br>
  <div class="pav-filtro-header" *ngIf="!indicadorCabecera">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtro</div>
  </div>
  <div class="pav-filtro-container" *ngIf="!indicadorCabecera">
    <div class="row">
      <div class="col s12 m12 l3">

        <select-search
          [label]="'Trabajador'"
          [placeholder]="'Ej. TORRES'"
          [name]="'codcli'"
          [value]="'codcli'"
          [description]="'nomcli'"
          [data]="trabajadores"
          (cambioSelect)="filtro.colaboradores = $event"
          [model]="filtro.colaboradores"
          [multiple]="true">
        </select-search>

      </div>
      <div class="col s12 m12 l3">

        <select-search
          [label]="'Centro de costo'"
          [placeholder]="'Ej. 000001'"
          [name]="'ccs_codccs'"
          [value]="'ccs_codccs'"
          [description]="'ccs_descri'"
          [data]="centros"
          (cambioSelect)="filtro.centros = $event"
          [model]="filtro.centros"
          [multiple]="true">
        </select-search>

      </div>
    </div>
  </div>
  <br>
  <div align="center">

    <button class="pav-button-action" color="primary" mat-flat-button (click)="generarExcel()" [class.spinner]="loading" [disabled]="loading">{{LABELS_NAME.BTN_GENERATE_EXCEL}}</button>

  </div>
</div>
