import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { OrdenCompraService } from "src/app/services/api/compras/operaciones/orden-compra.service";

@Component({
  selector: 'orden-compra-modal-list',
  templateUrl: './orden-compra-modal-list.component.html',
  styleUrls: ['./orden-compra-modal-list.component.scss']
})
export class OrdenCompraModalListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'ocm_numocm', 'toc_codtoc', 'ocm_fecocm', 'ldc_dirldc'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ordenes$: Subscription;
  loading$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _ordenCompraService: OrdenCompraService,
    private store: Store<PavsoState>,
    public dialogRef: MatDialogRef<OrdenCompraModalListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerOrdenesCompra()
    })
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  obtenerOrdenesCompra(): void {
    this.ordenes$ = this._ordenCompraService.obtenerOrdenesCompraConPaginacion().subscribe(
      ordenes => {
        this.loaderData = false;
        this.dataSource = fillTable(this.data, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError('Error al obtener ordenes de compra', 'Ok');
        this.loaderData = false;
      }
    )
  }

  seleccionarFila(row): void {
    console.log('fila seleccionada', row)
    this.dialogRef.close(row);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([])
  }

}
