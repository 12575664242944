export class ConfiguracionDeduccion {
  codcia: string;
  codano: string;
  codmes: string;
  codnco: string;
  descri: string;
  coduse: string;
  fecupd: Date;
  indsta: string;
  pornde: any;
  basmin: any;
  porotr: any;
  DEDU_DETA_NDD: Array<DeduDetaNdd>

  constructor() {
    this.indsta = '1';
    this.fecupd = new Date();
    this.DEDU_DETA_NDD = [];
  }
}

export class DeduDetaNdd {
  codnti: string;
  codccn: string;
  desccn: string;
  coduse: string;
  fecupd: Date;
  codcco: string;
  descco: string;
  codtra: string;
  codliq: string;
  desliq: string;
  correlativo: number;
  isEditing: boolean;

  constructor() {
    this.fecupd = new Date();
    this.isEditing = true;
  }
}
