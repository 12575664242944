import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { TipoServicioFormComponent } from "../tipo-servicio-form/tipo-servicio-form.component";
import { TipoServicioDialog } from "../tipo-servicio-dialog/tipo-servicio-dialog.component";

@Component({
  selector: 'tipo-servicio-select',
  templateUrl: './tipo-servicio-select.component.html',
  styleUrls: ['./tipo-servicio-select.component.scss']
})
export class TipoServicioSelect {
  id = 'WCOM_TIPOSERVIC';
  dialogComponent: ComponentType<any> = TipoServicioDialog;

  @Input() disabled: boolean;
  @Input() required: boolean = false;
  @Input() placeholder: string;
  @Input() clase: string;
  @Input() label: string;
  @Input() value: any;
  @Input() model: any;
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() multiple: boolean = false;
  @Input() iconAction: string;
  @Input() tooltipAction: string;
  @Output() cambioSelect: EventEmitter<any>;
  @Output() clickAction: EventEmitter<any>;

  constructor(
  ) {

    this.cambioSelect = new EventEmitter<string>();
    this.clickAction = new EventEmitter<any>();

  }

}
