export const REPORTES_ACTIVO_FIJO = [
  {
    id: "ACTIVO_FIJO_H",
    name: "Activo fijo histórico",
    esDinamico: false,
  },
  {
    id: "ACTIVO_FIJO_HC",
    name: "Activo fijo histórico componetizado",
    esDinamico: false,
  },
  {
    id: "ACTIVO_FIJO_U",
    name: "Activo fijo por ubicación",
    esDinamico: false,
  },
  {
    id: "ACTIVO_FIJO_DMAF",
    name: "Depreciación mensual por activo - financiero",
    esDinamico: false,
  },
  {
    id: "ACTIVO_FIJO_DMA",
    name: "Detalle movimiento por activo",
    esDinamico: false,
  },
  {
    id: "ACTIVO_FIJO_RMC",
    name: "Resumen movimiento cuenta",
    esDinamico: false,
  },
  {
    id: "ACTIVO_FIJO_RMCL",
    name: "Resumen movimiento clase",
    esDinamico: false,
  },
  {
    id: "ACTIVO_FIJO_RMCG",
    name: "Resumen movimiento clase vs grupo",
    esDinamico: false,
  },
  {
    id: "ACTIVO_FIJO_RMD",
    name: "Resumen movimiento detallado",
    esDinamico: false,
  },
]
