import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { NominaService } from "src/app/services";

@Component({
  selector: 'cargar-colaboradores-nomina-dialog',
  templateUrl: './cargar-colaboradores-nomina.component.html',
  styleUrls: ['./cargar-colaboradores-nomina.component.scss']
})
export class CargarColaboradoresNominaDialog implements OnInit, OnDestroy {

  loaderData: boolean = false;

  task = {
    name: 'Colaboradores',
    completed: false,
    color: 'accent',
    subtasks: [

    ],
  };

  allComplete: boolean = false;

  buttonName: INameConstant = NAMES_CONSTANTS;

  colaborador$: Subscription;

  periodo$: Subscription;

  year: string;
  month: string;

  constructor(
    public dialogRef: MatDialogRef<CargarColaboradoresNominaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _nominaService: NominaService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ){
    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  ngOnInit(): void {
    this.obtenerColaboradores();
  }

  obtenerColaboradores(): void {
    this.loaderData = true;
    this.colaborador$ = this._nominaService.listarColaboradoresPeriodo(this.year, this.month).subscribe(
      colaboradores => {
        console.log('colaboradores', colaboradores)
        this.task.subtasks = colaboradores.map(item => {
          return {
            name: item.nomcli,
            codigo: item.codcli,
            completed: true,
            color: 'accent'
          }
        })
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError("Error al obtener colaboradores", "OK");
        this.loaderData = false;
      }
    )
  }

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => (t.completed = completed));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cargarColaboradores(): void {
    const colaboradores = this.task.subtasks.filter(item => item.completed);
    this.dialogRef.close(colaboradores);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.colaborador$])
  }
}
