<h1 mat-dialog-title>Cobranza</h1>
<div mat-dialog-content>

    <div class="row">
        <div class="col s12 m3">
            <mat-form-field>
                <mat-label>Importe</mat-label>
                <input matInput placeholder="Importe">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Abonado</mat-label>
                <input matInput placeholder="Abonado">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Saldo</mat-label>
                <input matInput placeholder="Saldo">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Tip. Cambio</mat-label>
                <input matInput placeholder="Tip. Cambio">
            </mat-form-field>
        </div>
        <div class="col s12 m3">
            <mat-form-field>
                <mat-label>En soles</mat-label>
                <input matInput placeholder="En soles">
            </mat-form-field>
            <mat-form-field>
                <mat-label>En dólares</mat-label>
                <input matInput placeholder="En dolares">
            </mat-form-field>

            <div class="row">
                <div class="col s12 m6">
                    <button mat-fab color="primary">
                        <mat-icon>local_atm</mat-icon>
                    </button>
                </div>
                <div class="col s12 m6">
                    <button mat-fab color="primary">
                        <mat-icon>payment</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col s12 m3">
            <mat-form-field>
                <mat-label>Abono soles</mat-label>
                <input matInput placeholder="Abono soles">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Abono dólares</mat-label>
                <input matInput placeholder="Abono dólares">
            </mat-form-field>
            <div class="row">
                <div class="col s12 m6">
                    <button mat-fab color="primary">
                        <mat-icon>local_atm</mat-icon>
                    </button>
                </div>
                <div class="col s12 m6">
                    <button mat-fab color="primary">
                        <mat-icon>payment</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col s12 m3">
            <mat-form-field>
                <mat-label>Saldo soles</mat-label>
                <input matInput placeholder="Saldo soles">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Saldo dólares</mat-label>
                <input matInput placeholder="Saldo dólares">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Vuelto soles</mat-label>
                <input matInput placeholder="Vuelto soles">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Vuelto dólares</mat-label>
                <input matInput placeholder="Vuelto dólares">
            </mat-form-field>
        </div>
    </div>
    <br>
    <div class="mat-elevation-z0">
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="item">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                    <td mat-cell *matCellDef="let row" data-label="Item"> {{row.cli_codcli}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_fp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod F.P. </th>
                    <td mat-cell *matCellDef="let row" data-label="Cod F.P."> {{row.cli_nomcli}} </td>
                </ng-container>

                <ng-container matColumnDef="forma_pago">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Forma Pago </th>
                    <td mat-cell *matCellDef="let row" data-label="Forma Pago"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="mon">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
                    <td mat-cell *matCellDef="let row" data-label="Mon."> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="importe">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                    <td mat-cell *matCellDef="let row" data-label="Importe"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="abono">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Abono </th>
                    <td mat-cell *matCellDef="let row" data-label="Abono"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_tc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. T.C. </th>
                    <td mat-cell *matCellDef="let row" data-label="Cod. T.C."> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="tarjeta_credito">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tarjeta de Crédito </th>
                    <td mat-cell *matCellDef="let row" data-label="Tarjeta de Crédito"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="nro_tarjeta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Tarjeta </th>
                    <td mat-cell *matCellDef="let row" data-label="Nro. Tarjeta"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_ven">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Ven. </th>
                    <td mat-cell *matCellDef="let row" data-label="Fec. Ven."> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="nombre_tarjeta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Tarjeta </th>
                    <td mat-cell *matCellDef="let row" data-label="Nombre Tarjeta"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="pos">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> #P.O.S. </th>
                    <td mat-cell *matCellDef="let row" data-label="#P.O.S."> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                        <button matTooltip="Editar" [routerLink]="['/modulo-ventas/editar-cliente-comercial', row.cli_codcli]" mat-icon-button color="edit">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No se encontraron registros</td>
                </tr>

            </table>

            <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-flat-button (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
    <button mat-flat-button type="button" color="primary">Realizar</button>
</div>
