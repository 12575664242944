<h1 mat-dialog-title>Grabar operación frecuente</h1>

<div mat-dialog-content>
  <mat-form-field class="example-full-width" [ngStyle]="{marginTop: '5px'}">
    <mat-label>Operación</mat-label>
    <input required [(ngModel)]="operacionFrecuente.ofb_descri" name="ofb_descri" matInput placeholder="Eg. Operacion 01">
  </mat-form-field>

  <div align="end">
    <button mat-flat-button color="primary" [class.spinner]="loaderReg" [disabled]="loaderReg" type="button" (click)="registrarOperacionFrecuente()">Registrar</button>
  </div>
</div>
