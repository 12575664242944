import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Familia } from "src/app/models/almacen/familia";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class FamiliaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerFamiliasProducto(): Observable<any> {
    return this.http.get(`${this._configurationService.obtenerUrl()}/api/producto/familia/listar/${this._configurationService.obtenerCompaniaCliente()}`)
    .pipe(
      map((familias: any) => {
        return familias.map(familia => {
          familia['description'] = `${familia.FPR_DESCRI} | ${familia.FPR_CODFPR}`
          familia['description_reverse'] = `${familia.FPR_CODFPR} | ${familia.FPR_DESCRI}`
          return familia;
        })
      })
    )
  }

  obtenerFamiliaProducto(id: string): Observable<any> {
    return this.http.get(`${this._configurationService.obtenerUrl()}/api/producto/familia/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${id}`)
  }

  registrarFamiliaProducto(payload: Familia): Observable<any> {
    return this.http.post(`${this._configurationService.obtenerUrl()}/api/producto/familia/crear`, payload)
  }

  actualizarFamiliaProducto(payload: Familia): Observable<any> {
    return this.http.put(`${this._configurationService.obtenerUrl()}/api/producto/familia/modificar`, payload)
  }

}
