import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComprasService } from 'src/app/services';
import { Store } from '@ngrx/store';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registro-compra-list',
  templateUrl: './registro-compra-list.component.html',
  styleUrls: ['./registro-compra-list.component.css']
})
export class RegistroCompraListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'periodo', 'subdiario', 'correlativo', 'tipo_documento', 'nro_documento', 'auxiliar', 'nombre_auxiliar', 'fecha_emision', 'glosa'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  year: any;
  month: any;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  period$: Subscription;
  loading$: Subscription;
  registrosCompra$: Subscription;

  loaderData: boolean;

  pathUrl: string;
  shortUrl: string;
  title: string;
  typeForm: 'RC' | 'RH';

  registrosCompra: any[] = [];

  constructor(
    private _comprasService: ComprasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private router: Router
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.pathUrl = this.router.url;
    console.log('path', this.pathUrl);
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerRegistrosDeCompra();
    })

  }

  validarUrl(): void {

    if(this.pathUrl == '/modulo-compras/registros-de-compra' || this.pathUrl == '/modulo-contabilidad/registros-de-compra') {
      this.title = 'Registros de Compra';
      const filtrados = this.registrosCompra.filter(item => item.subdiario.substring(0, 2) != '15')
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      this.shortUrl = 'registro-de-compra';
      this.typeForm = 'RC';
    }

    if(this.pathUrl == '/modulo-compras/recibos-de-honorarios' || this.pathUrl == '/modulo-contabilidad/recibos-de-honorarios') {
      this.title = 'Recibos por Honorario';
      const filtrados = this.registrosCompra.filter(item => item.subdiario.substring(0, 2) == '15')
      this.dataSource = fillTable(filtrados, this.paginator, this.sort);
      this.shortUrl = 'recibo-de-honorario';
      this.typeForm = 'RH';
    }

  }

  obtenerRegistrosDeCompra(): void {
    this.loaderData = true;
    this._comprasService.listaResumidaRegistroCompras(this.year, this.month).subscribe(
      response => {

        this.registrosCompra = response;
        this.loaderData = false;
        this.validarUrl();
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.loading$, this.registrosCompra$]);
  }
}
