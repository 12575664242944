export class FirmaDigital {

  codcia: string;
  codcli: string;
  descri: string;
  rescgf: string;
  logcgf: string;
  coduse: string;
  fecupd: Date;
  indsta: string;
  firma: string;
  telcgf: string;
  faxcgf: string;
  email: string;
  urlima: string;
  base64: string;

  constructor() {
    this.indsta = "1";
    this.fecupd = new Date();
  }
}
