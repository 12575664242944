<app-pavso-title-section [title]="'Proceso'" [module]="'Producción'" [estado]="proceso.prp_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="proceso.prp_codprp" matInput placeholder="Código" name="prp_codprp"  readonly [disabled]="true">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="proceso.prp_desprp" matInput placeholder="Descripción" name="prp_desprp" required [disabled]="loaderData">
          </mat-form-field>
        </div>

      </div>
    </mat-card>
    <!-- {{ proceso | json}} -->
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-produccion/procesos"
    ></pav-form-actions>
  </form>
</div>
