<app-pavso-title-section [title]="'Formulación y hoja de ruta'" [module]="'Producción'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarFormulacion(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3 xl3">
          <select-search
            [label]="'Producto'"
            [placeholder]="'Ej. CANASTA'"
            [name]="'prd_codprd'"
            [value]="'prd_codprd'"
            [description]="'DESC_ESPA'"
            [data]="productos"
            (cambioSelect)="formulacionHoja.producto = $event"
            [model]="formulacionHoja.producto">
          </select-search>
        </div>
        <div class="col s12 m12 l3 xl3">
          <mat-form-field>
            <mat-label>U. Medida</mat-label>
            <input type="text" matInput placeholder="U. Medida" name="unidad_medida" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3 xl3">
          <mat-form-field>
            <mat-label>Cantidad</mat-label>
            <input type="number" matInput placeholder="Cantidad" name="cantidad" required [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3 xl3">
          <select-search
            [label]="'Tipo de Inventario'"
            [placeholder]="'Ej. CANASTA'"
            [name]="'tin_codtin'"
            [value]="'tin_codtin'"
            [description]="'tin_descri'"
            [data]="tiposInventario"
            (cambioSelect)="formulacionHoja.tipoInventario = $event"
            [model]="formulacionHoja.tipoInventario">
          </select-search>
        </div>
      </div>

    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <mat-tab-group>
        <mat-tab label="Generales" labelClass="mat-tab-label-0-0">
          <div class="pavso-tab-container">
            <div class="row">
              <div class="col s12 m12 l12 xl6">

                <div class="pav-filtro-header">
                  <div class="pav-filtro-header-icon"></div>
                  <div class="pav-filtro-header-title">Fórmula</div>
                  <div class="pav-separator"></div>

                  <div>
                    <button mat-flat-button class="pav-button-add" (click)="agregarFormula()" type="button" color="primary">Agregar</button>
                  </div>
                </div>

                <div class="mat-elevation-z0">

                  <table mat-table [dataSource]="dataSourceFormula" matSort>
                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row; let i = index;" [style.color]="row.color" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarFormula(i)">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                          <div [ngStyle]="{marginLeft: '15px', marginTop: '4px'}">
                            <mat-checkbox [(ngModel)]="row.seleccionado" [name]="'seleccionado' + i" class="example-margin" (change)="seleccionarFormula($event.checked, row)"></mat-checkbox>
                          </div>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="numero">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Número </th>
                      <td mat-cell *matCellDef="let row" data-label="Número"> {{row.CHO_CODCHO}} </td>
                    </ng-container>

                    <ng-container matColumnDef="descripcion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                      <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.CHO_NOMBRE}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cantidad_prod">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Prod. </th>
                      <td mat-cell *matCellDef="let row" data-label="Cantidad Prod."> {{row.CHO_NROLIC}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                      <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.CHO_NROLIC}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsFormula"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsFormula;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="5">No se encontraron registros</td>

                    </tr>

                  </table>

                  <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
              </div>
              <div class="col s12 m12 l12 xl6">
                <div class="pav-filtro-header">
                  <div class="pav-filtro-header-icon"></div>
                  <div class="pav-filtro-header-title">Rutas</div>
                  <div class="pav-separator"></div>

                  <div>
                    <button mat-flat-button class="pav-button-add" (click)="agregarRuta()" type="button" color="primary">Agregar</button>
                  </div>
                </div>

                <div class="mat-elevation-z0">

                  <table mat-table [dataSource]="dataSourceRuta" matSort >
                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row; let i = index;" [style.color]="row.color" data-label="Acciones">
                        <div class="pav-btns-container">
                          <div class="pav-btn-circle-delete" matTooltip="Editar" (click)="eliminarRuta(i)">
                            <mat-icon svgIcon="delete"></mat-icon>
                          </div>
                          <div [ngStyle]="{marginLeft: '15px', marginTop: '4px'}">
                            <mat-checkbox [(ngModel)]="row.seleccionado" [name]="'seleccionado' + i" class="example-margin" (change)="seleccionarRuta($event.checked, row)"></mat-checkbox>
                          </div>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="nro">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. </th>
                      <td mat-cell *matCellDef="let row" data-label="Nro."> {{row.CHO_CODCHO}} </td>
                    </ng-container>

                    <ng-container matColumnDef="descripcion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                      <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.CHO_NOMBRE}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estado">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                      <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.CHO_NROLIC}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsRuta"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsRuta;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">No se encontraron registros</td>

                    </tr>

                  </table>

                  <mat-paginator #paginatorRuta showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
              </div>
              <br>
              <br>
              <div class="col s12 m12 l12 xl12">
                <mat-form-field>
                  <mat-label>Detalles a tomar en cuenta en la fabricación</mat-label>
                  <textarea matInput placeholder="Detalles a tomar en cuenta en la fabricación" cdkTextareaAutosize
                    cdkAutosizeMinRows="4"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Materiales" labelClass="mat-tab-label-0-1">
          <div class="pavso-tab-container">
            <div class="row">
              <div class="col s12 m12 l12 xl4">
                <mat-form-field>
                  <mat-label>Fórmula</mat-label>
                  <mat-select>
                    <mat-option value="food.value">
                      formula
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <div class="pav-filtro-header">
                  <div class="pav-filtro-header-icon"></div>
                  <div class="pav-filtro-header-title">Tabla</div>
                  <div class="pav-separator"></div>

                  <div>
                    <button mat-flat-button class="pav-button-add" (click)="agregarFormula()" type="button" color="primary">Agregar</button>
                  </div>
                </div>
                <div class="mat-elevation-z0">

                  <table mat-table [dataSource]="dataSourceMat" matSort >
                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                      <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                        <button matTooltip="Editar"
                          [routerLink]="['/modulo-produccion/editar-chofer', row.CHO_CODCHO]" mat-icon-button
                          color="primary">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="item">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                      <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                    </ng-container>

                    <ng-container matColumnDef="codigo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                      <td mat-cell *matCellDef="let row" data-label="Código"> {{row.codigo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="codigo_laboratorio">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Código Laboratorio</th>
                      <td mat-cell *matCellDef="let row" data-label="Código"> {{row.codigo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="descripcion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                      <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
                    </ng-container>

                    <ng-container matColumnDef="um">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
                      <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.um}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cantidad">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                      <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cantidad}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMat"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMat;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="7">No se encontraron registros</td>

                    </tr>

                  </table>

                  <mat-paginator #paginatorMat showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Hoja de ruta" labelClass="mat-tab-label-0-2">
          <div class="pavso-tab-container">
              <div class="row">
                <div class="col s12 m12 l12 xl4">
                  <mat-form-field>
                    <mat-label>Ruta</mat-label>
                    <mat-select>
                      <mat-option value="food.value">
                        ruta
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="pav-filtro-header">
                <div class="pav-filtro-header-icon"></div>
                <div class="pav-filtro-header-title">Tabla</div>
                <div class="pav-separator"></div>

                <div>
                  <button mat-flat-button class="pav-button-add" (click)="agregarFormula()" type="button" color="primary">Agregar</button>
                </div>
              </div>
              <div class="mat-elevation-z0">

                <table mat-table [dataSource]="dataSourceHojaRuta" matSort >
                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                      <button matTooltip="Editar"
                        [routerLink]="['/modulo-produccion/editar-chofer', row.CHO_CODCHO]" mat-icon-button
                        color="primary">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="item">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                    <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                  </ng-container>

                  <ng-container matColumnDef="area">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Área </th>
                    <td mat-cell *matCellDef="let row" data-label="Área"> {{row.codigo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="proceso">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Proceso </th>
                    <td mat-cell *matCellDef="let row" data-label="Proceso"> {{row.codigo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="actividad">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Actividad </th>
                    <td mat-cell *matCellDef="let row" data-label="Actividad"> {{row.descripcion}} </td>
                  </ng-container>

                  <ng-container matColumnDef="tiempo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiempo </th>
                    <td mat-cell *matCellDef="let row" data-label="Tiempo"> {{row.um}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsHojaRuta"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsHojaRuta;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="7">No se encontraron registros</td>

                  </tr>

                </table>

                <mat-paginator #paginatorHojaRuta showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
              </div>
            </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12">
          <mat-spinner diameter="40" *ngIf="loaderReg"></mat-spinner>
          <!-- <button *ngIf="!loaderReg" mat-flat-button (click)="volver()" type="button"
            [disabled]="loaderData">{{LABELS_NAME.BTN_BACK | translate}}</button>
          &nbsp; -->
          <button class="pav-button-action" *ngIf="!loaderReg" mat-flat-button color="primary" type="submit"
            [disabled]="loaderData">{{LABELS_NAME.BTN_STORE | translate}}</button>
        </div>
      </div>
    </mat-card>

  </form>
</div>
