export class UbicacionActivoFijo {
  cia_codcia: string;
  uac_coduac: string;
  uac_deslar: string;
  uac_descor: string;
  uac_indsta: string;
  uac_coduse: string;
  uac_fecupd: Date;

  constructor() {
    this.uac_indsta = '1';
    this.uac_fecupd = new Date();
  }
}
