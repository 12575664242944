import { Cell, PdfMakeWrapper, Table, Txt } from "pdfmake-wrapper";

export async function generarPdfOperaciones() {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageSize({width: 1200, height: 400})
  pdf.pageMargins([ 20, 20, 20, 20 ]);


  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("COMERCIALIZADORA ASIA PERU S.A.C.").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("13/09/2021").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("RCOM_REP_DET_VE").fontSize(8).alignment('left').end
        ).end,
        new Cell(
          new Txt("").end
        ).end,
        new Cell(
          new Txt("15:03:11").fontSize(8).alignment('right').end
        ).end
      ]
    ]).layout('noBorders').widths([220, 740, 160]).end
  );

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("INFORME OPERACIONES TEMPLATE").fontSize(12).bold().alignment('center').end
        ).end
      ]
    ]).layout('noBorders').widths([1140]).end
  );


  pdf.add(
    pdf.ln(2)
  )

  pdf.add(
    new Table([
      [
        new Cell(
          new Txt("Cliente").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("TD").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Número").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Fecha").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Línea").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cod. Pro.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Producto").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cantidad").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Precio").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Moneda").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Importe").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Usuario").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Referencia").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Pedido").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Guía Remisión").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Cond. Pago").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Vendedor").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Motivo").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Ind. Guía").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Almacén").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Costo Prod.").fontSize(8).bold().alignment('center').end
        ).end,
        new Cell(
          new Txt("Departamento").fontSize(8).bold().alignment('center').end
        ).end
      ]
    ]).widths([80,20,30,30,40,50,80,40,30,30,30,60,80,40,50,30,80,30,30,30,30,30]).end
  );

  pdf.add(
    new Table(this.dataDetalleFacturacion).layout('noBorders').widths([85,20,35,30,40,50,83,43,30,30,30,60,80,40,55,30,80,30,30,30,30,30]).end
  );

  pdf.create().open();

}
