import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { LugarTrabajo } from 'src/app/models/planilla/maestros/lugar-trabajo';
import { AuthenticationService, ConfigurationService, NominaService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ApiNominaMaestrosLugarTrabajoService } from 'src/app/services/api/nomina/maestros/nomina.maestros.lugar-trabajo.service';

@Component({
  selector: 'app-lugar-trabajo-form',
  templateUrl: './lugar-trabajo-form.component.html',
  styleUrls: ['./lugar-trabajo-form.component.css']
})
export class LugarTrabajoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  lugarTrabajo: LugarTrabajo;

  lugar$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthenticationService,
    private _configurationService: ConfigurationService,
    private _apiLugarTrabajoNominaService: ApiNominaMaestrosLugarTrabajoService,
    private store: Store<PavsoState>
  ) {
    this.usuario = this._authService.getUsuarioSistema();

    this.lugarTrabajo = new LugarTrabajo();
    this.lugarTrabajo.coduse = this._configurationService.obtenerIdUsuario();
    this.lugarTrabajo.codcia = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;
        if(id == '0') return

        this.obtenerLugarTrabajo();
      }
    )
  }

  obtenerLugarTrabajo(): void {
    this.lugar$ = this._apiLugarTrabajoNominaService.listarLugaresDeTrabajo().subscribe(
      lugares => {
        const lugarTrabajo = lugares.find(item => item.codpla == this.uid);

        this.lugarTrabajo = lugarTrabajo
      },
      error => this._snackBarService.showError('Error al obtener tipos de documento', 'OK')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarLugarTrabajo(f): this.actualizarLugarTrabajo(f);
  }

  /**
   * Método para registrar un nuevo lugar de trabajo
   * @param f
   */
  registrarLugarTrabajo(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._apiLugarTrabajoNominaService.registrarLugarDeTrabajo(this.lugarTrabajo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Lugar de trabajo registrado', '400px', '380px', '');
        f.resetForm();
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar lugar de trabajo
   * @param f
   */
  actualizarLugarTrabajo(f: NgForm): void {
    this.loaderReg = true;
    this.sendForm$ = this._apiLugarTrabajoNominaService.actualizarLugarDeTrabajo(this.lugarTrabajo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Lugar de trabajo actualizado', '400px', '380px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/lugares-de-trabajo']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([ this.lugar$, this.loading$, this.sendForm$ ])
  }

}
