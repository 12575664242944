import { Component, OnDestroy, OnInit } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-planilla-cobranza',
  templateUrl: './planilla-cobranza.component.html',
  styleUrls: ['./planilla-cobranza.component.css']
})
export class PlanillaCobranzaComponent implements OnInit, OnDestroy {

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;

  constructor(
    private store: Store<PavsoState>
  ) {

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {}

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }

}
