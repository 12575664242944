import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PartDetalleMap } from 'src/app/models';

@Component({
  selector: 'app-product-movement-edit',
  templateUrl: './product-movement-edit.component.html',
  styleUrls: ['./product-movement-edit.component.css']
})
export class ProductMovementEditComponent implements OnInit {

  disabledCantidad: boolean = false;
  disabledCost: boolean = false;
  tipoCambio: number = parseFloat(localStorage.getItem('tipo-cambio'));

  buttonName: INameConstant = NAMES_CONSTANTS;
  constructor(
    public dialogRef: MatDialogRef<ProductMovementEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PartDetalleMap
  ) {
    // (data.prd_indlot == 1)? this.disabledCantidad = true: this.disabledCantidad = false;
    (localStorage.getItem('mmo_indval') == "1")? this.disabledCost = true: this.disabledCost = false;
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  actualizar(): void {
    this.dialogRef.close();
  }

  cambioCostoUMN(): void {
    this.data.map_impmnp = this.data.map_cosmnp * this.data.map_canprd;
    // this.data.map_cosmep = (this.data.map_cosmnp * this.tipoCambio).toFixed(2);
    this.data.map_impmep = this.data.map_cosmep * this.data.map_canprd;
  }

  cambioCostoUME(): void {
    this.data.map_impmep = this.data.map_cosmep * this.data.map_canprd;
             // this.data.map_cosmnp = (this.data.map_cosmep / this.tipoCambio).toFixed(2);
    this.data.map_impmnp = this.data.map_cosmnp * this.data.map_canprd;
  }

  cambioCantidad(): void {
    // this.data.map_cosmnp = (this.data.map_cosmep / this.tipoCambio).toFixed(2);
    // this.data.map_cosmep = (this.data.map_cosmnp * this.tipoCambio).toFixed(2);
    this.data.map_impmnp = this.data.map_cosmnp * this.data.map_canprd;
    this.data.map_impmep = this.data.map_cosmep * this.data.map_canprd;
  }

}
