export enum PavsoColors {
  BlueDark = "#00205F",
  Blue = "#0368c1",
  BlueLight = "#4493FF",
  Sky = "#EAF0F9",
  Red = "#EB6945",
  Yellow = "#F7C91B",
  Green = "#63C99A",
  White = "#FFFFFF",
  Black = "#000000",
  Gray = "#b9b9b9"
}
