import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RecepcionFile } from "src/app/admin/apps/compras/pages/operaciones/registro-compra-masivo/dialog/upload-xml-recepcion/upload-xml-recepcion.component";
import { FileUpload } from "src/app/models/compras/file-upload";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   * Registrar documentos XML Masivo
   * @param payload
   * @returns
   */
  registrarDocumentosMasivo(payload: {files: Array<FileUpload>}): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/files/crear/compras/masivo`,
      payload
    )
  }

  registrarDocumento(payload: RecepcionFile): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/files/crear/compras`,
      payload
    )
  }

  listarDocumentos(recepcion: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/files/listar/compras/01?registro=${recepcion}`,
    )
  }

  eliminarDocumento(id: number): Observable<any> {
    return this.http.delete<any>(
      `${this._configurationService.obtenerUrl()}/api/files/eliminar/compras/${id}`,
    )
  }

}
