import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RegistroOportunidad } from "src/app/models/crm/registro_oportunidad";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class OportunidadService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  obtenerRegistrosOportunidad(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/registros-oportunidad/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  obtenerRegistrosOportunidadConFiltro(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/registros-oportunidad-filtro/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    )
  }

  obtenerRegistroOportunidad(cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/registros-oportunidad/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  registrarRegistroOportunidad(registro: RegistroOportunidad): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/registros-oportunidad`,
      registro
    );
  }

  actualizarRegistroOportunidad(registro: RegistroOportunidad): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/crm/registros-oportunidad`,
      registro
    );
  }
}
