<app-pavso-title-section [title]="'Tipo de inventario'" [module]="'Almacén'" [estado]="tipoInventario.TIN_INDSTA"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarTipoInventario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="tipoInventario.TIN_CODTIN" matInput placeholder="Grupo de Activo" name="TIN_CODTIN" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Código Sunat</mat-label>
            <input type="text" [(ngModel)]="tipoInventario.TIN_CODOFI" matInput placeholder="Código Sunat" name="TIN_CODOFI" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l8">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="tipoInventario.TIN_DESCRI" matInput placeholder="Descripción" name="TIN_DESCRI" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción Abreviada</mat-label>
            <input type="text" [(ngModel)]="tipoInventario.TIN_DESABR" matInput placeholder="Descripción Abreviada" name="TIN_DESABR" required
              [disabled]="loaderData">
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
