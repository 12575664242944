import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Afp } from "src/app/models";
import { ConfigurationService } from "src/app/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class ApiNominaMaestrosAfpService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  listarAFP(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/afp/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @param cod
   * @returns
   */
  verAFP(year, month, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/afp/${this._configurationService.obtenerCompaniaCliente()}/${year}/${month}/${cod}`
    );
  }

  /**
   *
   * @param afp
   * @returns
   */
  registrarAFP(afp: Afp): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/afp`,
      afp
    );
  }

  /**
   *
   * @param afp
   * @returns
   */
  actualizarAFP(afp: Afp): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/nomina/afp`,
      afp
    );
  }

}
