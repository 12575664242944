<h1 mat-dialog-title>Consulta de movimientos cuentas</h1>

<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <select-search [label]="'Cuenta Contable'" [placeholder]="'Ej. '" [name]="'ccn_codccn'"
          [description]="'ccn_descri'" [data]="cuentasContable" [value]="'ccn_codccn'" [required]="true"
          (cambioSelect)="filtro.cuenta = $event;" [model]="filtro.cuenta" [disabled]="true">
        </select-search>
      </div>

      <div class="col s12 m12 l3">
        <select-search [label]="'Auxiliar'" [placeholder]="'Ej. '" [name]="'cli_codcli'" [description]="'description'"
          [data]="auxiliares" [value]="'cli_codcli'" (cambioSelect)="filtro.auxiliar = $event;"
          [model]="filtro.auxiliar">
        </select-search>
      </div>

      <div class="col s12 m12 l3">
        <select-search [label]="'Subdiario'" [placeholder]="'Ej. '" [name]="'sco_codsco'" [description]="'description'"
          [data]="subdiarios" [value]="'sco_codsco'" (cambioSelect)="filtro.subdiario = $event"
          [model]="filtro.subdiario">
        </select-search>
      </div>

      <div class="col s12 m12 l3">
        <select-search [label]="'Centro Costo'" [placeholder]="'Ej. '" [name]="'TDO_CODTDO'"
          [description]="'TDO_DESCOR'" [data]="centrosCosto" [value]="'TDO_CODTDO'"
          (cambioSelect)="filtro.centroCosto = $event;" [model]="filtro.centroCosto">
        </select-search>
      </div>

      <div class="col s12 m12 l3">
        <select-search [label]="'Tipo Doc.'" [placeholder]="'Ej. '" [name]="'TDO_CODTDO'" [description]="'description'"
          [data]="tiposDocumento" [value]="'TDO_CODTDO'"
          (cambioSelect)="filtro.tipoDocumento = $event;" [model]="filtro.tipoDocumento">
        </select-search>
      </div>

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Nro. Documento</mat-label>
          <input matInput placeholder="Nro. Documento" [disabled]="loaderData" [(ngModel)]="filtro.nroDocumento"
            name="nroDocumento">
        </mat-form-field>
      </div>

      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Mes</mat-label>
          <mat-select [(ngModel)]="filtro.mes" name="mes" [disabled]="true">
            <mat-option value="">Ninguno</mat-option>
            <mat-option *ngFor="let mes of meses" [value]="mes.id">
              {{mes.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Moneda</mat-label>
          <mat-select [(ngModel)]="filtro.moneda" name="monedas" [disabled]="true">
            <mat-option value="SO">
              Soles
            </mat-option>
            <mat-option value="DO">
              Dólares
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l2">
        <button [disabled]="loaderData" type="button" mat-mini-fab color="primary" (click)="obtenerMovimientosCuenta()"
          [ngStyle]="{background: '#0368c1'}" aria-label="Example icon button with a menu icon" matTooltip="Buscar">
          <mat-icon>search</mat-icon>
        </button>
      </div>

    </div>

  </mat-card>

  <div class="mat-elevation-z0">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="mes">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes</th>
        <td mat-cell *matCellDef="let row" data-label="Mes" style="font-family: Figtree-SemiBold !important;"> {{row.MES_CODMES}}
        </td>
      </ng-container>

      <ng-container matColumnDef="subdiario">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> SubDiario - Correlativo </th>
        <td mat-cell *matCellDef="let row" data-label="SubDiario - Correlativo" style="font-family: Figtree-SemiBold !important;"> {{row.SCO_CODSCO}} - {{row.LDC_CORLDC}}
        </td>
      </ng-container>

      <ng-container matColumnDef="ldi_numdoc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
        <td mat-cell *matCellDef="let row" data-label="Comprobante" style="font-family: Figtree-SemiBold !important;"> {{row.TDO_CODTDO}} - {{row.LDI_DOCREF}} </td>
      </ng-container>

      <ng-container matColumnDef="cli_codcli">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Auxiliar </th>
        <td mat-cell *matCellDef="let row" data-label="Auxiliar" style="font-family: Figtree-SemiBold !important;"> {{row.CLI_CODCLI}} </td>
      </ng-container>

      <ng-container matColumnDef="cli_nomcli">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
        <td mat-cell *matCellDef="let row" data-label="Auxiliar" style="font-family: Figtree-SemiBold !important;"> {{row.cli_nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="ccn_codccn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Corriente </th>
        <td mat-cell *matCellDef="let row" data-label="Cuenta" style="font-family: Figtree-SemiBold !important;"> {{row.CCN_CODCCN}} </td>
      </ng-container>

      <ng-container matColumnDef="debe">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Debe </th>
        <td mat-cell *matCellDef="let row" data-label="Centro Costo" style="font-family: Figtree-SemiBold !important;"> {{ filtro.moneda == 'SO' ? (row.LDI_INDDHA == 'D' ? row.LDI_IMPNAC.toFixed(2): '0.00') : (row.LDI_INDDHA == 'D'? row.LDI_IMPMEX.toFixed(2): '0.00') }} </td>
      </ng-container>

      <ng-container matColumnDef="haber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Haber </th>
        <td mat-cell *matCellDef="let row" data-label="Centro Costo" style="font-family: Figtree-SemiBold !important;"> {{ filtro.moneda == 'SO' ? (row.LDI_INDDHA == 'H' ? row.LDI_IMPNAC.toFixed(2): '0.00') : (row.LDI_INDDHA == 'H'? row.LDI_IMPMEX.toFixed(2): '0.00') }} </td>
      </ng-container>

      <ng-container matColumnDef="cco_fecemi_103">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de emisión </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha de emisión" style="font-family: Figtree-SemiBold !important;"> {{row.cco_fecreg_103}} </td>
      </ng-container>

      <ng-container matColumnDef="cco_fecreg_103">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha de Registro" style="font-family: Figtree-SemiBold !important;"> {{row.LDC_FECDIA | date : 'dd/MM/YYYY'}} </td>
      </ng-container>

      <ng-container matColumnDef="ccs_codccs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro de costo </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha de Registro" style="font-family: Figtree-SemiBold !important;"> {{row.CCS_CODCCS}} </td>
      </ng-container>

      <ng-container matColumnDef="ldi_gloldi">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
        <td mat-cell *matCellDef="let row" data-label="Glosa" style="font-family: Figtree-SemiBold !important;"> {{row.LDI_GLOLDI}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        ></tr>

      <tr class="mat-row" *matNoDataRow>

        <td class="mat-cell" colspan="12" *ngIf="!loaderStandar">No se encontraron registros</td>

      </tr>

    </table>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 100]"></mat-paginator>
  </div>

  <div class="row">
    <div class="col s12 m12 l6"></div>
    <div class="col s12 m12 l3">
      <mat-form-field>
        <mat-label>Debe</mat-label>
        <input matInput placeholder="0.00" [disabled]="loaderData" [value]="totalDebe.toFixed(2)" [ngStyle]="{textAlign: 'right'}" readonly
          name="totalDebe">
      </mat-form-field>
    </div>
    <div class="col s12 m12 l3">
      <mat-form-field>
        <mat-label>Haber</mat-label>
        <input matInput placeholder="0.00" [disabled]="loaderData" [value]="totalHaber.toFixed(2)" [ngStyle]="{textAlign: 'right'}" readonly
          name="totalHaber">
      </mat-form-field>
    </div>
  </div>

</div>
