import { cambiarMenu } from "@shared/state/actions/menu-state.actions";
import { MenuState } from "@data/interfaces/state/menu-state";
import { createReducer, on } from "@ngrx/store";


export const initialState: MenuState = {active: false};

const _menuStateReducer = createReducer(
  initialState,
  on(cambiarMenu, (state, props) => state = {active: props.active}),
);

export function menuStateReducer(state, action) {
  return _menuStateReducer(state, action)
}
