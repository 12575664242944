import { Component, OnInit } from "@angular/core";
import { ConfigState } from "@data/interfaces/state/config-state";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { cambiarConfiguracionGeneral } from "@shared/state/actions/config.actions";
import { cambiarEstablecimiento } from "@shared/state/actions/establishment.actions";
import { cambiarLoadingEstablishment } from "@shared/state/actions/loading.actions";
import { Subscription } from "rxjs";
import { Tienda } from "src/app/models/ventas";
import { ConfigurationService, UserService } from "src/app/services";
import { TiendaService } from "src/app/services/api/sistema/tienda.service";


interface SistemaUsuarioResponse {
  S10_USUARIO: string;
  S10_NOMUSU: string;
  S10_NOMCOR: string;
  S10_NIVUSU: number;
  S10_TIPUSU: string;
  S19_CODIDI: string;
  CLI_CODCLI: string;
  S10_FIRUSU: any;
  S10_FOTUSU: any;
  S10_PERFIL: any;
  S10_INDSTA: string;
  S10_FECUPD: Date;
  S10_CODUSE: string;
  TIE_CODTIE: any;
  vendedor: any;
  tiendas_tie: TiendaUsuario []
}

interface TiendaUsuario {
  S10_USUARIO: string;
  CIA_CODCIA: string;
  TIE_CODTIE: string;
  S23_CODUSE: string;
  S23_FECACT: Date;
  S23_INDSTA: string;
  tienda: Tienda
}


@Component({
  selector: 'pav-establishment',
  templateUrl: './pav-establishment.component.html',
  styleUrls: ['./pav-establishment.component.scss']
})
export class PavEstablishmentComponent implements OnInit {

  establecimientos$: Subscription;
  loading$: Subscription;
  config$: Subscription;

  loading: boolean = true;

  establecimientos: any[] = [];

  establecimientoSeleccionada: string;
  establecimientoDescri: string = '';
  config: ConfigState;

  constructor(
    private _userService: UserService,
    private _configurationService: ConfigurationService,
    private _tiendaService: TiendaService,
    private store: Store<PavsoState>
  ) { }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && state.isLoadingEstablishment) {
        if (this._configurationService.obtenerCompaniaCliente()) this.obtenerTiendas();
      }
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.config = state;
    })

  }

  async seleccionarEstablecimiento(value) {

    const establecimiento = this.establecimientos.find(item => item.TIE_CODTIE === value);

    this.establecimientoDescri = establecimiento ? establecimiento.TIE_DESTIE : '';

    let tienda = new Tienda();

    tienda = await this._tiendaService.obtenerTienda(establecimiento.TIE_CODTIE).toPromise();
    console.log('tiendasss', tienda)

    this.store.dispatch(cambiarEstablecimiento({ code: value, establecimientos: this.establecimientos, establecimiento: this.establecimientoDescri, establecimientoObj: tienda }));

    this.store.dispatch(cambiarLoadingEstablishment({ isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: true, isLoadingTypeNominaDialog: false }))

    this._configurationService.establecerTienda(value);

  }

  obtenerTiendas(): void {

    this.loading = true;

    this.establecimientos$ = this._userService.obtenerEstablecimientoDeUsuario().subscribe(
      async (response) => {
        console.log('rseponse', response)

        // Verificar si existe un usuario vendedor
        if(response.vendedor) {
          console.log('response.vendedor', response.vendedor)
          console.log('supervisor', response.supervisor)

          const vendedor = response.vendedor.find(item => item.CIA_CODCIA == this._configurationService.obtenerCompaniaCliente())
          this.store.dispatch(cambiarConfiguracionGeneral({...this.config, vendedores: response.vendedor, vendedorId: vendedor.VDE_CODVDE? vendedor.VDE_CODVDE : '', supervisor: response.supervisor? response.supervisor[0]: null}))
        }

        let tienda = new Tienda();

        if (!response.tiendas_tie) {

          const tiendas = await this._tiendaService.obtenerTiendas().toPromise()

          if(tiendas.length > 0) tienda = tiendas[0]

          this.store.dispatch(cambiarEstablecimiento({ code: this.establecimientoSeleccionada, establecimientos: this.establecimientos, establecimiento: this.establecimientoDescri, establecimientoObj: tienda }))

          this.store.dispatch(cambiarLoadingEstablishment({ isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: true, isLoadingTypeNominaDialog: false }));

          this.loading = false;

          return;

        }

        this.establecimientos = response.tiendas_tie;
        this.establecimientos.forEach(item => item['TIE_DESTIE'] = `${item.tienda.tie_destie}`)

        this.establecimientoSeleccionada = this._configurationService.obtenerTienda();

        if (this.establecimientoSeleccionada) {
          if (response.tiendas_tie.length === 1) {

            this.establecimientoSeleccionada = response.tiendas_tie[0].TIE_CODTIE;

            this.establecimientoDescri = this.establecimientos.find(item => item.TIE_CODTIE === this.establecimientoSeleccionada).TIE_DESTIE
            tienda = await this._tiendaService.obtenerTienda(this.establecimientoSeleccionada).toPromise();

            this.store.dispatch(cambiarEstablecimiento({ code: this.establecimientoSeleccionada, establecimientos: this.establecimientos, establecimiento: this.establecimientoDescri, establecimientoObj: tienda }))
            this._configurationService.establecerTienda(this.establecimientoSeleccionada);

          } else {
            console.log('tiendasss sfdfd', tienda)
            console.log('this.establecimientoSeleccionada', this.establecimientoSeleccionada)
            tienda = await this._tiendaService.obtenerTienda(this.establecimientoSeleccionada).toPromise();
            console.log('mostrar tienda', tienda)

            if (this.establecimientoSeleccionada != '') {
              console.log('ingreso')
              this.establecimientoDescri = this.establecimientos.find(item => item.TIE_CODTIE === this.establecimientoSeleccionada).TIE_DESTIE
              this.store.dispatch(cambiarEstablecimiento({ code: this.establecimientoSeleccionada, establecimientos: this.establecimientos, establecimiento: this.establecimientoDescri, establecimientoObj: tienda }))
            } else {
              this.store.dispatch(cambiarEstablecimiento({ code: '', establecimientos: this.establecimientos, establecimiento: '', establecimientoObj: tienda }))
            }
          }

        } else {
          console.log('AAAA')
          if (response.tiendas_tie.length === 1) {

            this.establecimientoSeleccionada = response.tiendas_tie[0].TIE_CODTIE;
            tienda = await this._tiendaService.obtenerTienda(this.establecimientoSeleccionada).toPromise();
            console.log('tiendasss', tienda)
            this.establecimientoDescri = this.establecimientos.find(item => item.TIE_CODTIE === this.establecimientoSeleccionada).TIE_DESTIE
            this.store.dispatch(cambiarEstablecimiento({ code: this.establecimientoSeleccionada, establecimientos: this.establecimientos, establecimiento: this.establecimientoDescri, establecimientoObj: tienda }))
            this._configurationService.establecerTienda(this.establecimientoSeleccionada);

          } else if(response.tiendas_tie.length > 1) {
            const tiendaSeleccionada = response.tiendas_tie.find(item => item.CIA_CODCIA == this._configurationService.obtenerCompaniaCliente())
            this.establecimientoSeleccionada = tiendaSeleccionada.TIE_CODTIE;
            tienda = await this._tiendaService.obtenerTienda(this.establecimientoSeleccionada).toPromise();
            console.log('tiendasss ingreso', tienda)
            this.establecimientoDescri = this.establecimientos.find(item => item.TIE_CODTIE === this.establecimientoSeleccionada).TIE_DESTIE
            this.store.dispatch(cambiarEstablecimiento({ code: this.establecimientoSeleccionada, establecimientos: this.establecimientos, establecimiento: this.establecimientoDescri, establecimientoObj: tienda }))
            this._configurationService.establecerTienda(this.establecimientoSeleccionada);
          }
        }
        this.store.dispatch(cambiarLoadingEstablishment({ isLoadingCompany: false, isLoadingSidenav: false, isLoadingEstablishment: false, isLoadingModule: true, isLoadingTypeNominaDialog: false }))
        console.log('tiendas_tie', response.tiendas_tie)
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    )

  }

}
