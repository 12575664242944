<h1 mat-dialog-title>Agregar ítem</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col s12">
      <select-search [label]="'Proveedor'" [placeholder]="'Ej. TORRES'" [name]="'cli_codcli'" [value]="'cli_codcli'"
        [description]="'description'" [data]="data.proveedores" (cambioSelect)="dataToSend.proveedor = $event"
        [model]="dataToSend.proveedor">
      </select-search>
    </div>
    <div class="col s6">
      <select-search [label]="'T.D.'" [placeholder]="'Ej. DOC'" [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'"
        [description]="'description'" [data]="data.tiposDocumento" (cambioSelect)="dataToSend.tipoDocumento = $event"
        [model]="dataToSend.tipoDocumento">
      </select-search>
    </div>
    <div class="col s6">
      <mat-form-field>
        <mat-label>Nro. Doc.</mat-label>
        <input #iNroDoc type="text" maxlength="20" matInput placeholder="Nro. Doc."
          [(ngModel)]="dataToSend.nroDocumento" name="cco_numdoc" [required]="true">
      </mat-form-field>
    </div>
    <div class="col s12">
      <div [ngStyle]="{display: 'flex'}">
        <div [ngStyle]="{width: '30%'}">
          <mat-form-field>
            <mat-label>Días</mat-label>
            <input matInput placeholder="2" [name]="'dias'" type="number" min="0" [(ngModel)]="dataToSend.dias"
              (keyup)="establecerDias(dataToSend)">
          </mat-form-field>
        </div>
        <div [ngStyle]="{width: '68%', marginLeft: '5px'}">
          <mat-form-field>
            <mat-label>Fec. Vencimiento</mat-label>
            <input matInput [matDatepicker]="pickerFecVenc" [(ngModel)]="dataToSend.fechaVencimiento"
              [name]="'fecvencimiento'">
            <mat-datepicker-toggle matSuffix [for]="pickerFecVenc"></mat-datepicker-toggle>
            <mat-datepicker #pickerFecVenc></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col s8">
    </div>
    <div class="col s6">
      <select-search [label]="'Cuenta'" [placeholder]="'Ej. 42'" [name]="'cuenta'" [value]="'cuenta'"
        [description]="'description'" [data]="data.cuentasTotales"
        (cambioSelect)="dataToSend.cuenta = $event; seleccionarCuenta()" [model]="dataToSend.cuenta">
      </select-search>
    </div>
    <div class="col s6">
      <select-search [label]="'Orden de Compra'" [placeholder]="'Ej. 000001'" [name]="'ocm_numocm'"
        [value]="'ocm_numocm'" [description]="'description'" [data]="data.ordenes"
        (cambioSelect)="dataToSend.ordenCompra = $event" [model]="dataToSend.ordenCompra">
      </select-search>
    </div>
    <div class="col s6">
      <select-search [label]="'Centro de Costo'" [placeholder]="'Ej. Centro'" [name]="'ccs_codccs'"
        [value]="'ccs_codccs'" [description]="'ccs_codccs'" [data]="data.centrosCosto"
        (cambioSelect)="dataToSend.centroCosto = $event" [model]="dataToSend.centroCosto">
      </select-search>
    </div>
    <div class="col s3">
      <mat-form-field>
        <mat-label>Moneda</mat-label>
        <mat-select #iMoneda [(ngModel)]="dataToSend.moneda" name="TMO_CODTMO" [name]="'moneda'">
          <mat-option value="SO">
            S/
          </mat-option>
          <mat-option value="DO">
            $
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col s3">
      <mat-form-field>
        <mat-label>T/C</mat-label>
        <input min="0" type="number" matInput placeholder="T. Cambio" [(ngModel)]="dataToSend.tipoCambio"
          [name]="'t_cambio'" step="any">
      </mat-form-field>
    </div>

  </div>
  <div class="row">
    <div class="col s12">
      <div [ngStyle]="{display: 'flex'}">
        <div>
          <mat-form-field [ngStyle]="{width: '60px'}">
            <mat-label>D/H</mat-label>
            <mat-select [(ngModel)]="dataToSend.dh" [name]="'dh'">
              <mat-option value="D">
                D
              </mat-option>
              <mat-option value="H">
                H
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [ngStyle]="{width: '38%', marginLeft: '5px'}">
          <mat-form-field>
            <mat-label>Imp. MN</mat-label>
            <input [disabled]="dataToSend.moneda == 'DO'" [ngStyle]="{textAlign: 'right'}"
              (keyup)="calculoImportesMN(dataToSend)" min="0" type="number" matInput placeholder="Importe MN"
              [(ngModel)]="dataToSend.importeMN" [name]="'importe_mn'" step="any">
          </mat-form-field>
        </div>
        <div [ngStyle]="{width: '38%', marginLeft: '5px'}">
          <mat-form-field>
            <mat-label>Imp. ME</mat-label>
            <input [disabled]="dataToSend.moneda == 'SO'" [ngStyle]="{textAlign: 'right'}"
              (keyup)="calculoImportesME(dataToSend)" min="0" type="number" matInput placeholder="Importe ME"
              [(ngModel)]="dataToSend.importeME" [name]="'importe_me'" step="any">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col s12">
      <select-search [label]="'Flujo de casa'" [placeholder]="'Ej. Flujo'" [name]="'fca_codfca'" [value]="'fca_codfca'"
        [description]="'fca_descri'" [data]="data.flujos" (cambioSelect)="dataToSend.flujo = $event"
        [model]="dataToSend.flujo">
      </select-search>
    </div>
    <div class="col s12">
      <mat-form-field>
        <mat-label>Glosa</mat-label>
        <input type="text" matInput placeholder="Glosa" [(ngModel)]="dataToSend.glosa" [name]="'glosa'">
      </mat-form-field>
    </div>

  </div>
</div>
<div align="right">
  <button (click)="aceptar()" mat-raised-button color="primary">Aceptar</button>
</div>
