<h1 mat-dialog-title>Listado de pedidos</h1>

<div mat-dialog-content>
  <form #f="ngForm" ngNativeValidate (ngSubmit)="obtenerPedidos()" (keydown.enter)="$event.preventDefault()">
    <div class="row">
      <div class="col s12 m12 l4 p-l-0">
        <mat-form-field>
          <mat-label>{{'general.dialog.activity-period.form.year' | translate}}</mat-label>
          <mat-select [(ngModel)]="anioActividad" name="yearPeriod">
            <mat-option *ngFor="let year of years" [value]="year.id">
              {{year.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4 p-r-0">
        <mat-form-field>
          <mat-label>{{'general.dialog.activity-period.form.month' | translate}}</mat-label>
          <mat-select [(ngModel)]="mesActividad" name="monthPeriod">
            <div *ngFor="let month of months">
              <mat-option [value]="month.id">
                {{month.name}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <button mat-mini-fab color="primary" type="submit">
          <mat-icon>search</mat-icon>
        </button>
      </div>

    </div>
  </form>

  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <div class="overflow-x">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones" class="td-responsive">
          <div class="pav-btns-container">
            <a class="pav-btn-circle-edit" matTooltip="Editar" (click)="seleccionarPedido(row.PCC_NUMPCC)">
              <mat-icon svgIcon="copiar"></mat-icon>
            </a>
            <!-- <div class="pav-btn-circle-pdf" matTooltip="Editar" (click)="imprimirPDF(row.PCC_NUMPCC)">
                      <mat-icon svgIcon="pdf"></mat-icon>
                    </div> -->
            <!-- <div class="pav-btn-circle-more" (click)="cambiar()">
                      <mat-icon svgIcon="more"></mat-icon>
                      <span class="tooltiptext" [style.visibility]="visibility">
                        <div>
                          <mat-icon svgIcon="like"></mat-icon>
                        </div>
                        <div>
                          <mat-icon svgIcon="dislike"></mat-icon>
                        </div>
                        <div>
                          <mat-icon svgIcon="correo"></mat-icon>
                        </div>
                        <div>
                          <mat-icon svgIcon="paper"></mat-icon>
                        </div>
                        <div>
                          <mat-icon svgIcon="more-horizontal"></mat-icon>
                        </div>
                      </span>
                    </div> -->
            <!-- <button type="button" matTooltip="Imprimir PDF" (click)="imprimirPDF(row.pcc_numpcc)" mat-icon-button color="warn" aria-label="Imprimir PDF">
                      <mat-icon>picture_as_pdf</mat-icon>
                    </button> -->
          </div>

          <!-- <div class="pavso-container-action">
                        <button type="button" matTooltip="Editar pedido" [routerLink]="['/modulo-ventas/editar-pedido', row.pcc_numpcc]" mat-icon-button color="primary" aria-label="Editar pedido">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button type="button" matTooltip="Imprimir PDF" (click)="imprimirPDF(row.pcc_numpcc)" mat-icon-button color="warn" aria-label="Imprimir PDF">
                            <mat-icon>picture_as_pdf</mat-icon>
                        </button>
                        <button type="button" matTooltip="Enviar Correo" (click)="enviarCorreo(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Enviar correo">
                            <mat-icon>email</mat-icon>
                        </button>
                        <button type="button" [disabled]="row.ESTADO_APROBACION == 'APROBADO'" matTooltip="Aprobar" (click)="aprobar(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Aprobar">
                            <mat-icon>thumb_up_alt</mat-icon>
                        </button>
                        <button type="button" [disabled]="row.ESTADO_APROBACION == 'PENDIENTE'" matTooltip="Establecer a Pendiente" (click)="establecerPendiente(row.pcc_numpcc)" mat-icon-button color="primary">
                            <mat-icon>pending</mat-icon>
                        </button>
                        <button type="button" matTooltip="Desaprobar" (click)="desaprobar(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Desaprobar">
                            <mat-icon>thumb_down_alt</mat-icon>
                        </button>
                        <button *ngIf="row.ESTADO_APROBACION == 'APROBADO'" type="button" matTooltip="Generar Factura" (click)="generarFactura(row)" mat-icon-button color="primary">
                            <mat-icon>receipt</mat-icon>
                        </button>
                    </div> -->

        </td>
      </ng-container>

      <ng-container matColumnDef="PCC_NUMPCC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Pedido </th>
        <td mat-cell *matCellDef="let row" data-label="Nro. Pedido" class="td-responsive"> {{row.PCC_NUMPCC}} </td>
      </ng-container>

      <ng-container matColumnDef="CLI_CODCLI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento Cliente </th>
        <td mat-cell *matCellDef="let row" data-label="Documento Cliente" class="td-responsive"> {{row.CLI_CODCLI}}
        </td>
      </ng-container>

      <ng-container matColumnDef="cli_nomcli">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.cliente.cli_nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="PCC_FECDOC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha" class="td-responsive"> {{row.PCC_FECDOC | date:
          'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="tmo_codtmo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Moneda </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo de Moneda" class="td-responsive"> {{(row.tmo_codtmo ==
          'SO')? 'Soles': 'Dolares'}} </td>
      </ng-container>

      <ng-container matColumnDef="PCC_IMPTOT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
        <td mat-cell *matCellDef="let row" data-label="Importe Total" class="td-responsive"
          [ngStyle]="{textAlign: 'right'}">
          <div [ngStyle]="{paddingRight: '60px'}">
            {{row.PCC_IMPTOT.toFixed(2)}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="vde_nomvde">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
        <td mat-cell *matCellDef="let row" data-label="Vendedor" class="td-responsive"> {{row.vendedor.vde_nomvde}}
        </td>
      </ng-container>

      <ng-container matColumnDef="PCC_INDSTA">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
        <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">

          <div *ngIf="row.PCC_INDSTA == '0'" class="sc_pending">PENDIENTE</div>
          <div *ngIf="row.PCC_INDSTA == '1'" class="sc_approved">APROBADO</div>


        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">No se encontraron registros</td>

      </tr>

    </table>
  </div>

  <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
