import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { Subscription } from "rxjs";
import { Permisos } from "src/app/models/permisos";

@Component({
  selector: 'table-title-action',
  templateUrl: './table-title-action.component.html',
  styleUrls: ['./table-title-action.component.scss']
})
export class TableTitleActionComponent implements OnDestroy{
  @Input() title: string;
  @Input() url: string;
  @Input() entity: string;
  @Input() params: Array<string>;
  @Output() clickDescargar: EventEmitter<any>;
  @Input() validacionPeriodo: boolean;

  storeAuth$:Subscription;
  periodo$: Subscription;
  permisos: Permisos;

  buttonName: INameConstant = NAMES_CONSTANTS;

  estaValidado: boolean;

  obtenerParametros(): Array<string> {

    return [this.url, ...this.params];

  }

  constructor(
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _router: Router
  ) {
    this.permisos = new Permisos();
    this.storeAuth$ = this.store.select("permissions").subscribe(({isCreate,isDownLoadToExcel}) => {
      this.permisos.canCreate = true; // todo: activar el state cuando se encuentren ingresados los permisos
      this.permisos.canDownloadToExcel = true;
    })

    this.periodo$ = this.store.select("period").subscribe(({year, month, openingYear, openingMonth}) => {
      this.estaValidado = this.validarPeriodo(year, month, openingYear, openingMonth);
      console.log('esta validado', this.estaValidado)
    })

    this.clickDescargar = new EventEmitter<any>();
  }

  validarPeriodo(year, month, openingYear, openingMonth): boolean {

    return (year == openingYear && month >= openingMonth);

  }

  descargar(): void {
    this.clickDescargar.emit({});
  }

  nuevoRegistro(): void {
    if(this.validacionPeriodo) {
      if(this.estaValidado) {
        this._router.navigate(this.obtenerParametros());
        return;
      }
      this._snackBarService.showError("No se puede crear un registro en el periodo actual", "OK");
      return;
    }

    this._router.navigate(this.obtenerParametros());
  }

  ngOnDestroy(): void {}
}
