import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { TipoDocumentoLegajo } from 'src/app/models/planilla/maestros/tipo-documento-legajo';
import { AuthenticationService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ApiNominaMaestrosTipoDocumentoLegajoService } from 'src/app/services/api/nomina/maestros/nomina.maestros.tipo-documento-legajo.service';

@Component({
  selector: 'app-tipo-documento-legajo-form',
  templateUrl: './tipo-documento-legajo-form.component.html',
  styleUrls: ['./tipo-documento-legajo-form.component.css']
})
export class TipoDocumentoLegajoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  tipoDocLegajo: TipoDocumentoLegajo;

  tipoDoc$: Subscription;
  loading$: Subscription;
  sendForm$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    private _apiTipoDocumentoLegajoNominaService: ApiNominaMaestrosTipoDocumentoLegajoService,
    private _authService: AuthenticationService,
    private store: Store<PavsoState>
  ) {

    this.usuario = this._authService.getUsuarioSistema();

    this.tipoDocLegajo = new TipoDocumentoLegajo();
    this.tipoDocLegajo.coduse = this._configurationService.obtenerIdUsuario();

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule && !state.isLoadingTypeNominaDialog) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.uid = id;
        this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;
        if(id == '0') return;

        this.obtenerTipoDocumentoLegajo();

      }
    )
  }

  obtenerTipoDocumentoLegajo(): void {
    this.tipoDoc$ = this._apiTipoDocumentoLegajoNominaService.listarTipoDocumentosLegajo().subscribe(
      tiposDocumento => {
        const tipoDoc = tiposDocumento.find(item => item.codtdl == this.uid);

        this.tipoDocLegajo = tipoDoc;
      },
      error => this._snackBarService.showError('Error al obtener tipos de documento', 'OK')

    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.uid == '0' ? this.registrarTipoDocumentoLegajo(f): this.actualizarTipoDocumentoLegajo(f);
  }

  /**
   * Método para registrar un nuevo tipo de documento legajo
   * @param f
   */
  registrarTipoDocumentoLegajo(f: NgForm): void {
    this.sendForm$ = this._apiTipoDocumentoLegajoNominaService.registrarTipoDocumentoLegajo(this.tipoDocLegajo).subscribe(
      response => {
        this.loaderReg = false;
        f.resetForm();
        this._dialogService.openDialog(SuccessComponent, 'Tipo de documento legajo registrado', '400px', '380px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  /**
   * Método para actualizar tipo de documento legajo
   * @param f
   */
  actualizarTipoDocumentoLegajo(f: NgForm): void {
    this.sendForm$ = this._apiTipoDocumentoLegajoNominaService.actualizarTipoDocumentoLegajo(this.tipoDocLegajo).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Tipo de documento legajo actualizado', '400px', '380px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-planilla/tipos-de-documento-legajo']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.tipoDoc$, this.loading$, this.sendForm$])
  }
}
