<app-pavso-title-section [title]="'Lead Time'" [module]="'Compras'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarChofer(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Período</mat-label>
                      <input matInput [matDatepicker]="dp" [formControl]="date">
                      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                      <mat-datepicker #dp
                                      startView="multi-year"
                                      (yearSelected)="chosenYearHandler($event)"
                                      (monthSelected)="chosenMonthHandler($event, dp)"
                                      panelClass="example-month-picker">
                      </mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Fecha</mat-label>
                      <input matInput [matDatepicker]="fecha">
                      <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                      <mat-datepicker #fecha></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6">
                  <fieldset>
                      <mat-radio-group>
                          <mat-radio-button value="1">Controlado</mat-radio-button>
                          <mat-radio-button value="2">Límite</mat-radio-button>
                          <mat-radio-button value="3">Crítico</mat-radio-button>
                          <mat-radio-button value="4">Todo</mat-radio-button>
                      </mat-radio-group>
                  </fieldset>
              </div>
          </div>
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Inventario</mat-label>
                      <mat-select required name="inventario" [disabled]="loaderData">
                          <mat-option value="1">
                              inventario
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <button mat-flat-button color="primary">Actualizar</button>
              </div>
          </div>
          <br>
          <mat-tab-group>
              <mat-tab label="1. Extraer Stock de Seguridad" labelClass="mat-tab-label-0-0">
                  <div class="mat-elevation-z0">

                      <table mat-table [dataSource]="dataSourceExtraerStock" matSort >

                          <ng-container matColumnDef="item">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                              <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cod_producto">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Producto </th>
                              <td mat-cell *matCellDef="let row" data-label="Cod. Producto"> {{row.cod_producto}} </td>
                          </ng-container>

                          <ng-container matColumnDef="descripcion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                              <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cant_producto">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Producto </th>
                              <td mat-cell *matCellDef="let row" data-label="Cant. Producto"> {{row.cant_producto}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cant_adicional">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Adicional </th>
                              <td mat-cell *matCellDef="let row" data-label="Cant. Adicional"> {{row.cant_adicional}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cant_ajustada">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Ajustada </th>
                              <td mat-cell *matCellDef="let row" data-label="Cant. Ajustada"> {{row.cant_ajustada}} </td>
                          </ng-container>

                          <ng-container matColumnDef="frecuencia_anual">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Frecuencia Anual </th>
                              <td mat-cell *matCellDef="let row" data-label="Frecuencia Anual"> {{row.frecuencia_anual}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsExtraerStock"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsExtraerStock;"></tr>

                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="7">No se encontraron registros</td>
                          </tr>

                      </table>

                      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
                  <br>
                  <div class="row">
                      <div class="col s12 m12 l3">
                          <mat-form-field>
                              <mat-label>Nro. Productos</mat-label>
                              <input matInput placeholder="Nro. Productos" name="nro_productos">
                          </mat-form-field>
                      </div>
                      <div class="col s12 m12 l3">
                          <button mat-fab color="primary">
                              <mat-icon>settings</mat-icon>
                            </button>
                      </div>
                  </div>
              </mat-tab>
              <mat-tab label="2. Compra en Tránsito" labelClass="mat-tab-label-0-1">
                  <div class="mat-elevation-z0">

                      <table mat-table [dataSource]="dataSourceCompraTransito" matSort >

                          <ng-container matColumnDef="cod_producto">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Producto </th>
                              <td mat-cell *matCellDef="let row" data-label="Cod. Producto"> {{row.cod_producto}} </td>
                          </ng-container>

                          <ng-container matColumnDef="producto">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                              <td mat-cell *matCellDef="let row" data-label="Producto"> {{row.producto}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cantidad">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                              <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.cantidad}} </td>
                          </ng-container>

                          <ng-container matColumnDef="fecha_entrega">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Entrega </th>
                              <td mat-cell *matCellDef="let row" data-label="Fecha de Entrega"> {{row.fecha_entrega}} </td>
                          </ng-container>

                          <ng-container matColumnDef="ordenes_pendientes">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Órdenes pendientes </th>
                              <td mat-cell *matCellDef="let row" data-label="Órdenes pendientes"> {{row.ordenes_pendientes}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsCompraTransito"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsCompraTransito;"></tr>

                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="5">No se encontraron registros</td>
                          </tr>

                      </table>

                      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
                  <br>
                  <div class="row">
                      <div class="col s12 m12 l3">
                          <mat-form-field>
                              <mat-label>Nro. Proveedores</mat-label>
                              <input matInput placeholder="Nro. Proveedores" name="nro_proveedores">
                          </mat-form-field>
                      </div>
                      <div class="col s12 m12 l3">
                          <button mat-fab color="primary">
                              <mat-icon>settings</mat-icon>
                            </button>
                      </div>
                  </div>
              </mat-tab>
              <mat-tab label="3. Lote Económico de Compra(EOQ)" labelClass="mat-tab-label-0-2">
                  <div class="mat-elevation-z0">

                      <table mat-table [dataSource]="dataSourceLoteEconomico" matSort >

                          <ng-container matColumnDef="item">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                              <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cod_producto">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Producto </th>
                              <td mat-cell *matCellDef="let row" data-label="Cod. Producto"> {{row.cod_producto}} </td>
                          </ng-container>

                          <ng-container matColumnDef="descripcion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                              <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
                          </ng-container>

                          <ng-container matColumnDef="consumo_anual">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Consumo Anual </th>
                              <td mat-cell *matCellDef="let row" data-label="Consumo Anual"> {{row.consumo_anual}} </td>
                          </ng-container>

                          <ng-container matColumnDef="costo_precio">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Costo de Precio </th>
                              <td mat-cell *matCellDef="let row" data-label="Costo de Precio"> {{row.costo_precio}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cant_lote">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Lote </th>
                              <td mat-cell *matCellDef="let row" data-label="Cant. Lote"> {{row.cant_lote}} </td>
                          </ng-container>

                          <ng-container matColumnDef="ajuste_cantidad">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ajuste Cantidad </th>
                              <td mat-cell *matCellDef="let row" data-label="Ajuste Cantidad"> {{row.ajuste_cantidad}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cant_producto">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cant. Producto </th>
                              <td mat-cell *matCellDef="let row" data-label="Cant. Producto"> {{row.cant_producto}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsLoteEconomico"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsLoteEconomico;"></tr>

                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="8">No se encontraron registros</td>
                          </tr>

                      </table>

                      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
                  <br>
                  <div class="row">
                      <div class="col s12 m12 l3">
                          <mat-form-field>
                              <mat-label>Nro. Productos</mat-label>
                              <input matInput placeholder="Nro. Proveedores" name="nro_proveedores">
                          </mat-form-field>
                      </div>
                      <div class="col s12 m12 l2">
                          <button mat-fab color="primary">
                              <mat-icon>settings</mat-icon>
                          </button>
                      </div>
                      <div class="col s12 m12 l4">
                          <fieldset>
                              <legend>Datos solicitados</legend>
                              <mat-form-field>
                                  <mat-label>Costo de Pedir</mat-label>
                                  <input matInput placeholder="Costo de Pedir" name="costo_pedir">
                              </mat-form-field>
                              <mat-form-field>
                                  <mat-label>Porcentaje de posesión (%)</mat-label>
                                  <input matInput placeholder="Porcentaje de posesión (%)" name="porcentaje_posesion">
                              </mat-form-field>
                          </fieldset>
                      </div>
                  </div>
              </mat-tab>
              <mat-tab label="4. Actualiza Lead Time" labelClass="mat-tab-label-0-3">
                  <div class="mat-elevation-z0">

                      <table mat-table [dataSource]="dataSourceActualizaLead" matSort >

                          <ng-container matColumnDef="item">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                              <td mat-cell *matCellDef="let row" data-label="Item"> {{row.item}} </td>
                          </ng-container>

                          <ng-container matColumnDef="cod_producto">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Producto </th>
                              <td mat-cell *matCellDef="let row" data-label="Cod. Producto"> {{row.cod_producto}} </td>
                          </ng-container>

                          <ng-container matColumnDef="descripcion">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                              <td mat-cell *matCellDef="let row" data-label="Descripción"> {{row.descripcion}} </td>
                          </ng-container>

                          <ng-container matColumnDef="stock_seguridad">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock de Seguridad </th>
                              <td mat-cell *matCellDef="let row" data-label="Stock de Seguridad"> {{row.stock_seguridad}} </td>
                          </ng-container>

                          <ng-container matColumnDef="consumo_anual">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Consumo Anual </th>
                              <td mat-cell *matCellDef="let row" data-label="Consumo Anual"> {{row.consumo_anual}} </td>
                          </ng-container>

                          <ng-container matColumnDef="stock_almacen">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock Almacén </th>
                              <td mat-cell *matCellDef="let row" data-label="Stock Almacén"> {{row.stock_almacen}} </td>
                          </ng-container>

                          <ng-container matColumnDef="pedido_despachar">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Pedido sin Despachar </th>
                              <td mat-cell *matCellDef="let row" data-label="Pedido sin Despachar"> {{row.pedido_despachar}} </td>
                          </ng-container>

                          <ng-container matColumnDef="stock_disponible">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock Disponible </th>
                              <td mat-cell *matCellDef="let row" data-label="Stock Disponible"> {{row.stock_disponible}} </td>
                          </ng-container>

                          <ng-container matColumnDef="punto_pedido">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Punto de Pedido </th>
                              <td mat-cell *matCellDef="let row" data-label="Punto de Pedido"> {{row.punto_pedido}} </td>
                          </ng-container>
                          <ng-container matColumnDef="faltantes">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Faltantes </th>
                              <td mat-cell *matCellDef="let row" data-label="Faltantes"> {{row.faltantes}} </td>
                          </ng-container>

                          <ng-container matColumnDef="compras_transito">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Compras en Tránsito </th>
                              <td mat-cell *matCellDef="let row" data-label="Compras en Tránsito"> {{row.compras_transito}} </td>
                          </ng-container>

                          <ng-container matColumnDef="fecha_entrega">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Entrega </th>
                              <td mat-cell *matCellDef="let row" data-label="Fecha de Entrega"> {{row.fecha_entrega}} </td>
                          </ng-container>

                          <ng-container matColumnDef="dias">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Días </th>
                              <td mat-cell *matCellDef="let row" data-label="Días"> {{row.dias}} </td>
                          </ng-container>

                          <ng-container matColumnDef="lote_economico">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Lote Económico </th>
                              <td mat-cell *matCellDef="let row" data-label="Lote Económico"> {{row.lote_economico}} </td>
                          </ng-container>

                          <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                              <td mat-cell *matCellDef="let row" data-label="Status"> {{row.status}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumnsActualizaLead"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsActualizaLead;"></tr>

                          <tr class="mat-row" *matNoDataRow>
                              <td class="mat-cell" colspan="15">No se encontraron registros</td>
                          </tr>

                      </table>

                      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
                  <br>
                  <div class="row">
                      <div class="col s12 m12 l3">
                          <mat-form-field>
                              <mat-label>Nro. Productos</mat-label>
                              <input matInput placeholder="Nro. Proveedores" name="nro_proveedores">
                          </mat-form-field>
                      </div>
                      <div class="col s12 m12 l2">
                          <button mat-fab color="primary">
                              <mat-icon>settings</mat-icon>
                          </button>
                          &nbsp;
                          <button mat-fab color="primary">
                              <mat-icon>receipt</mat-icon>
                          </button>
                      </div>
                      <div class="col s12 m12 l4">
                          <fieldset>
                              <legend>Datos solicitados</legend>
                              <mat-form-field>
                                  <mat-label>Días a transcurrir</mat-label>
                                  <input matInput placeholder="Días a transcurrir" name="dias_transcurrir">
                              </mat-form-field>

                          </fieldset>
                      </div>
                  </div>
              </mat-tab>
          </mat-tab-group>
      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>
    </form>
</div>
