import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";

@Component({
  selector: 'canal-venta-list',
  templateUrl: './canal-venta-list.component.html',
  styleUrls: ['./canal-venta-list.component.scss']
})
export class CanalVentaListComponent implements OnInit{

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'vds_codvds', 'vds_descri', 'vds_indsta'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  activoFijo$: Subscription;
  loading$: Subscription;

  canales: any[];

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerCanalesDeVenta();
    })
  }

  obtenerCanalesDeVenta(): void {
    this.loaderData = true;
    this._ventasService.obtenerCanalesVenta().subscribe(
      canales => {
        this.canales = canales;
        this.dataSource = fillTable(canales, this.paginator,this.sort)
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }
}
