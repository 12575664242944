import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'button-remove-item-table',
  templateUrl: './button-remove-item-table.component.html',
  styleUrls: ['./button-remove-item-table.component.scss']
})

export class ButtonRemoveItemTableComponent implements OnInit {

  @Input() loader: boolean;
  @Output() removeItem = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  quitarItem() {
    this.removeItem.emit()
  }
}
