import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { HeaderReport } from "@data/interfaces/headers.report";
import { REPORTES_PLANILLA_INTERFACE_CONTABLE } from "@data/json/reportes/planilla.reporte.json";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { ContabilidadService } from "src/app/services";
import { HeaderReportService } from "src/app/services/reports/config/header-report.service";
import { NominaReportService } from "src/app/services/reports/modules/nomina-report.service";

@Component({
  selector: 'asiento-planilla',
  templateUrl: './asiento-planilla.component.html',
  styleUrls: ['./asiento-planilla.component.scss']
})
export class AsientoPlanillaComponent implements OnInit, OnDestroy {

  loading: boolean;

  reportes: any[] = [REPORTES_PLANILLA_INTERFACE_CONTABLE[0]];
  reporteSeleccionado: any = [REPORTES_PLANILLA_INTERFACE_CONTABLE[0].id];

  mostrarFiltroAuxiliar: 0 | 1 = 0;
  mostrarFiltroCentro: 0 | 1 = 0;
  mostrarFiltroConcepto: 0 | 1 = 0;
  mostrarFiltroRangoFecha: 0 | 1 = 0;

  indicadores$: Subscription;

  trabajadores: any[] = [];
  centros: any[] = [];

  buttonsName: INameConstant = NAMES_CONSTANTS;

  filtro: any;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _headerReportService: HeaderReportService,
    private _nominaReportService: NominaReportService
  ) {}

  ngOnDestroy(): void {
    unsubscribeSubscription([this.indicadores$])
  }

  ngOnInit(): void {}

  seleccionarReporte(): void {
    let indicador = "";
    this.mostrarFiltroAuxiliar = 1;
    this.mostrarFiltroCentro = 1;

    switch (this.reporteSeleccionado[0]) {
      case "PLANILLA_VTAP":
        indicador = "00000"
        break;
      default:
        break;
    }

    this.indicadores$ = this._contabilidadService.obtenerInidicadoresFiltro(indicador).subscribe(
      ([response]) => {
        if(response) {
          this.mostrarFiltroAuxiliar = response.ind_auxiliar;
          this.mostrarFiltroCentro = response.ind_centrocosto;
          this.mostrarFiltroConcepto = response.ind_conceptoplanilla;
        }
      }
    )
  }

  verReporte(): void {

    this.loading = true;

    const headers: HeaderReport = this._headerReportService.obtenerCabecera();

    const payload = {
      colaboradores: (this.filtro.colaborador)? this.filtro.colaborador: [],
      centros: (this.filtro.centro)? this.filtro.centro: [],
    };

    const otherData = {

    }
    this._nominaReportService.verReporteInterfaceContable(this.reporteSeleccionado[0], headers, payload, otherData)
      .then(_ => this.loading = false)
      .catch(_ => this.loading = false)
      .finally(() => this.loading = false)

  }

}
