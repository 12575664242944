<h1 mat-dialog-title>{{'general.dialog.search-module.title' | translate}}</h1>
<div mat-dialog-content>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <div>
    <mat-chip-list aria-label="Fish selection">
      <mat-chip>
        <mat-icon>tips_and_updates</mat-icon>
        &nbsp;
        {{'general.dialog.search-module.form.tip' | translate}}
      </mat-chip>
    </mat-chip-list>

    <mat-form-field class="pav-search-module-input">
      <mat-label>{{'general.dialog.search-module.form.module' | translate}}</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input
        type="text"
        [placeholder]="'general.dialog.search-module.form.type-module' | translate"
        aria-label="Number"
        matInput
        (keyup.enter)="buscar()"
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
<!-- <div mat-dialog-actions align="center">
  <button class="pav-button-cancel" mat-flat-button (click)="onNoClick()">
    {{ 'general.button.cancel' | translate }}
  </button>
  <br />
  <button
    [style.margin-top]="'15px'"
    [style.margin-bottom]="'10px'"
    class="pav-button-action"
    color="primary"
    mat-flat-button
    (click)="buscar()"
  >
    {{'general.button.search' | translate}}
  </button>
</div> -->
