export const STATE_CONSTANT = [
  {
    id: "1",
    name: "VIGENTE"
  },
  {
    id: "0",
    name: "ANULADO"
  }
]
