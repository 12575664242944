import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { SituacionOportunidad } from "src/app/models/crm/situacion_oportunidad";
import { ConfigurationService } from "src/app/services";
import { SituacionService } from "src/app/services/api/crm/maestros/situacion.service";

@Component({
  selector: 'situacion-oportunidad-form',
  templateUrl: 'situacion-oportunidad-form.component.html',
  styleUrls: ['situacion-oportunidad-form.component.scss']
})
export class SituacionOportunidadFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  situacion: SituacionOportunidad;

  loading$: Subscription;
  sendForm$: Subscription;
  situacion$: Subscription;

  constructor(
    private store: Store<PavsoState>,
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _configurationService: ConfigurationService,
    private _situacionService: SituacionService
  ) {
    this.situacion = new SituacionOportunidad();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadData()
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;

      this.uid == '0' ? this.btnName = 'general.button.store': this.btnName = 'general.button.update';

      if(this.uid == '0') return;

      this.obtenerSituacion();

    })
  }

  obtenerSituacion(): void {
    this.situacion$ = this._situacionService.obtenerSituacionOportunidad(this.uid).subscribe(
      situacion => this.situacion = situacion[0],
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if(!this.formularioValido()) return ;

    this.loaderReg = true;
    this.establecerIndicadores();
    this.uid == '0' ? this.registrarSituacionOportunidad(f): this.actualizarSituacionOportunidad(f);
  }

  establecerIndicadores(): void {
    this.situacion.indmot = this.situacion.indmot ? 1: 0;
  }
  /**
   * Método para registrar una nueva situación de oportunidad.
   * @param f
   */
  registrarSituacionOportunidad(f: NgForm): void {
    this.situacion.coduse = this._configurationService.obtenerIdUsuario();
    this.situacion.indmot = this.situacion.indmot? 1: 0;

    this.sendForm$ = this._situacionService.registrarSituacionOportunidad(this.situacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.situacion-oportunidad.success-store', '400px', 'auto', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar los datos de una situación de oportunidad
   * @param f
   */
  actualizarSituacionOportunidad(f: NgForm): void {
    this.sendForm$ = this._situacionService.actualizarSituacionOportunidad(this.situacion).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.maestros.situacion-oportunidad.success-update', '400px', 'auto', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para validar el formulario
   * @returns boolean
   */
  formularioValido(): boolean {
    if(!this.situacion.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    return true;
  }

  /**
   * Método para volver al listado de tipos de negociación
   */
  volver(): void {
    this._router.navigate(['/modulo-crm/situaciones-de-oportunidad']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.sendForm$,
      this.situacion$,
    ])
  }
}
