import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { SuccessComponent } from "@shared/components/dialogs/success/success.component";
import { DialogService } from "@shared/services/dialog.service";
import { SnackBarService } from "@shared/services/snackbar.service";
import { formatearCorrelativo } from "@utils/conversions/formatCorrelative";
import { forkObs } from "@utils/observables/fork";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable } from "@utils/tables/table";
import { Subscription, of } from "rxjs";
import { DescuentoProductoCabecera } from "src/app/models/ventas/tabla-descuento-producto";
import { DescuentoProductoDetalle } from "src/app/models/ventas/tabla-descuento-producto-detalle";
import { DescuentoProductoSubdetalle } from "src/app/models/ventas/tabla-descuento-producto-subdetalle";
import { ConfigurationService, VentasService } from "src/app/services";
import { BancosVentasService } from "src/app/services/api/ventas/maestros/bancos.service";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";
import { DescuentoProductoService } from "src/app/services/api/ventas/precio-descuento-promociones/descuento-producto";

@Component({
  selector: 'tabla-descuento-producto-form',
  templateUrl: './tabla-descuento-producto-form.component.html',
  styleUrls: ['./tabla-descuento-producto-form.component.scss']
})
export class TablaDescuentoProductoFormComponent implements OnInit, OnDestroy {

  uid: string;

  comboProductos: any[] = [];
  comboDescuento: any[]=[];

  banco$: Subscription;
  sendForm$: Subscription;

  loaderReg: boolean;
  loaderData: boolean = true;
  descuento:DescuentoProductoCabecera;


  buttonsName: INameConstant = NAMES_CONSTANTS;

  /*Tabla Productos */
  displayedColumns: string[] = ['actions', 'prd_codprd'];
  dataSourceProducto: MatTableDataSource<DescuentoProductoDetalle>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /*Tabla Descuento */
  displayedColumnsDescuento: string[] = ['actions', 'dco_coddco','tds_portds'];
  dataSourceDescuento: MatTableDataSource<any>;

  @ViewChild('paginatorDescuento') paginatorDescuento: MatPaginator;
  @ViewChild(MatSort) sortDescuento: MatSort;

  btnName: string = 'general.button.store';

  loading$: Subscription;
  producto$: Subscription;
  descuento$:Subscription;
  descuentoProducto$: Subscription;

  anioActividad: string;
  mesActividad: string;
  periodo$:Subscription;
  constructor(
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _apiBancosVentasService: BancosVentasService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private _productService: ProductoService,
    private _ventasService: VentasService,
    private _descuentoProductoService:DescuentoProductoService,
    private _router: Router
  ) {
    this.descuento = new DescuentoProductoCabecera();

    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id
      if(this.uid==='0'){
        this.periodo$ = this.store.select('period').subscribe(state => {
          this.anioActividad =state.year;
          this.mesActividad = state.month;
          //Setea el periodo si estan creando un nuevo registro sino no lo setea
          // por que ya existe la tabla a modificar
          if(this.anioActividad && this.mesActividad){
            this.descuento.periodo = this.anioActividad+this.mesActividad;
            this.descuento.tbd_numtbd = '';
          }
        })
      }
    })

    /*v ------------------------------------------------------------------- */
    this.dataSourceProducto = fillTable([], this.paginator, this.sort);
    this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);
    this.descuento.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.descuento.tbd_coduse = this._configurationService.obtenerIdUsuario();


  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;
    forkObs(
      this.uid =='0'?of([]):this._descuentoProductoService.mostrarDescuentoProducto(this.uid),
      this._productService.obtenerProductosPipe(),
      this._ventasService.obtenerDescuentosPipe()
    ).then(result=> {
      console.log("soy result: ", result);
      if(this.uid!='0')this.descuento = result[0];
      this.comboProductos = result[1];
      this.comboDescuento = result[2];
      //Los seteo en false porque vienen en true algunos
      if(this.uid!='0'){this.descuento.detalle.forEach(item => {item.isSelected = false;
      item.isEditing=true;})};
      this.dataSourceProducto = fillTable(this.descuento.detalle, this.paginator, this.sort);
      this.loaderData = false;
    }
      )


  }



  obtenerDescuentos(){
    this.descuento$  = this._ventasService.obtenerDescuentosPipe().subscribe(
      response => {
        console.log("tipos de operaciones", response);
        this.comboDescuento = response;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  obtenerDescuentoProductoSubdetalle(row:DescuentoProductoDetalle): void {
    //hacer un pequeño json con 3 productos que tengan como detalle el prd_codprd con sus respectivos descuentos
    let anotherSelecteds = this.descuento.detalle.filter(item => item.isSelected == true && item.prd_codprd !== row.prd_codprd).length > 0;
    if(row.isSelected == false && anotherSelecteds == false){
      this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);
      return;
    }
    else if(row.isSelected == true && (row.prd_codprd || row.prd_codprd!=='') ){

      let index = this.descuento.detalle.findIndex(item => item.prd_codprd === row.prd_codprd);
      this.dataSourceDescuento = fillTable(this.descuento.detalle[index].descuentos, this.paginatorDescuento, this.sortDescuento);

    }

  }

// Deseleccionar todos los checkboxes excepto el seleccionado
  onCheckboxChange(i: number, fila:any) {
    this.obtenerDescuentoProductoSubdetalle(fila);
    this.descuento.detalle.forEach((item, index) => {
      if (index !== i) {
        item.isSelected = false;
      }
    });
  }

  validarProductoRepetido(event,row:DescuentoProductoDetalle): void {

    const noAgregar = this.descuento.detalle.filter(item => item.prd_codprd === event).length >0;
    if (noAgregar) {
      row.prd_codprd = null;
      this._snackBarService.showError('No se puede agregar el mismo tipo de producto', 'Ok');
      return;
    }

    row.prd_codprd = event;
    this.obtenerDescuentoProductoSubdetalle(row)

  }


  /* Lógica tabla de Productos */
  agregarProducto():void{
    let item = new DescuentoProductoDetalle();
    /*le pasamos el id de la empresa y usuario*/
    item.tdd_coduse = this.descuento.tbd_coduse;
    item.cia_codcia = this.descuento.cia_codcia;
    // =================================================
    this.descuento.detalle.push(item)
   // this.descuento.detalle = this.descuentoDetalle;

    this.dataSourceProducto = fillTable(this.descuento.detalle, this.paginator, this.sort);
  }

  obtenerProductosCombo():void{
    this.producto$ = this._productService.obtenerProductosPipe().subscribe(
      productos => {
        console.log("soy productos: ", productos);
        this.comboProductos = productos.filter(producto => producto.ESTADO == 'VIGENTE');
        this.loaderData = false;

      }, error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }
  quitarProductoCombo(index: number): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if(result) {
        this.descuento.detalle.splice(index, 1);
        this.dataSourceProducto = fillTable(this.descuento.detalle, this.paginator, this.sort);
        this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);
      }
    })

  }
 /* Logica tabla de Descuentos */

 agregarDescuento():void{
  let index_prd = this.descuento.detalle.findIndex(item => item.isSelected == true);
  if(index_prd == -1){
    this._snackBarService.showError('Seleccione un producto en la casilla', 'Ok');
    return;
  }
  let item = new DescuentoProductoSubdetalle();


  /*le pasamos el id de la empresa y usuario*/
  item.tds_coduse = this.descuento.tbd_coduse;
  item.cia_codcia = this.descuento.cia_codcia;
  item.tbd_numtbd = this.descuento.tbd_numtbd;
  console.log("agregue descuento: ", item);
  // =================================================
  this.descuento.detalle.forEach(producto => {
    if(producto.isSelected == true){
      this.descuento.detalle[index_prd].descuentos.push(item);
      this.dataSourceDescuento = fillTable(this.descuento.detalle[index_prd].descuentos, this.paginatorDescuento, this.sortDescuento);
    }
  })
}

quitarDescuentoCombo(index: number): void {
  let index_prd = this.descuento.detalle.findIndex(item => item.isSelected == true);
  this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
    if(result) {
      this.descuento.detalle[index_prd].descuentos.splice(index, 1);
      this.dataSourceDescuento = fillTable(this.descuento.detalle[index_prd].descuentos, this.paginatorDescuento, this.sortDescuento);
    }
  })

}




  /*==================================================================================================================================== */

  enviarFormulario(f: NgForm): void {
    this.setearOrdenDescuentos();

    this.uid == '0' ? this.registrarDescuentoProducto(f): this.modificarDescuentoProducto(f);

  }



  setearOrdenDescuentos(): void {
    this.descuento.detalle.forEach((producto) => {
      producto.descuentos.forEach((descuento, j) => {
        descuento.tds_cortds = formatearCorrelativo(2, (j+1).toString());
      })
    })
  }

  registrarDescuentoProducto(f: NgForm): void {
    this.sendForm$ = this._descuentoProductoService.registrarDescuentoProducto(this.descuento).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Se registró la tabla de descuento producto con éxito', '', '', '').subscribe(result => {
          if(result) this._router.navigate(['/modulo-ventas/descuentos-por-producto'])
        })
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  modificarDescuentoProducto(f: NgForm): void {
    this.sendForm$ = this._descuentoProductoService.modificarDescuentoProducto(this.descuento).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Se actualizó la tabla de descuento producto con éxito', '', '', '').subscribe(result => {
          if(result) this._router.navigate(['/modulo-ventas/descuentos-por-producto'])
        })
      },error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/descuentos-por-producto'])
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.banco$,
      this.sendForm$,
      this.loading$,
      this.producto$,
      this.descuentoProducto$,
      this.periodo$,
      this.descuentoProducto$,

    ])
  }

}
