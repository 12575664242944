export class FileUpload {
  id: 0 | 1;
  cia_codcia: string;
  cco_codcco: string;
  cfe_codext: "xml" | "pdf";
  cfe_arcfis: string;
  cfe_nomarc: string;
  cfe_coduse: string;
  cfe_fecupd: Date;

  constructor() {
    this.cfe_fecupd = new Date;
  }

  static mapXMLFromDocument(documento): FileUpload {

    const fileUpload = new FileUpload();

    fileUpload.id = 0;
    fileUpload.cia_codcia = documento.cia_codcia;
    fileUpload.cco_codcco = "";
    fileUpload.cfe_codext = "xml";
    fileUpload.cfe_arcfis = documento.xml;
    fileUpload.cfe_nomarc = documento.cpe;
    fileUpload.cfe_coduse = documento.cfe_coduse;

    return fileUpload;

  }
}
