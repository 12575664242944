import { Component, OnDestroy, OnInit } from '@angular/core';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { IHeaderKey } from '@shared/components/table/table-content/table-content.component';
import { THEADER_CRM_CANAL } from '@data/constants/table/theader.crm';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { CanalService } from 'src/app/services/api/crm/maestros/canal.service';

@Component({
  selector: 'app-canal-list',
  templateUrl: './canal-list.component.html',
  styleUrls: ['./canal-list.component.css']
})
export class CanalListComponent implements OnInit, OnDestroy {

  canales$: Subscription;
  loading$: Subscription;

  tHeaders: Array<IHeaderKey> = THEADER_CRM_CANAL;

  canales: Array<any> = [];

  constructor(
    private _canalService: CanalService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
  ) {
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.obtenerCanales();
      }
    })
  }

  obtenerCanales(): void {
    this.canales$ = this._canalService.obtenerCanales().subscribe(
      canales => this.canales = canales,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.canales$,
      this.loading$,
    ])
  }
}
