export const REPORTES_CRM = [
  {
    id: "CRM_RO",
    name: "Registros de oportunidad",
    esDinamico: false,
  },
  {
    id: "CRM_SO",
    name: "Seguimientos de oportunidad",
    esDinamico: false,
  },
  {
    id: "CRM_VP",
    name: "Ventas perdidas",
    esDinamico: false,
  },
];
