import { AFPModule } from "./afp/afp.module";
import { CargoModule } from "./cargos/cargo.module";
import { ColaboradorModule } from "./colaborador/colaborador.module";
import { ContratoModule } from "./contrato/contrato.module";
import { FirmaDigitalModule } from "./firmas-digitales/firma-digital.module";
import { LugarTrabajoFormComponent, LugarTrabajoListPComponent } from "./lugar-trabajo";
import { TipoDocumentoLegajoFormComponent, TipoDocumentoLegajoListComponent } from "./tipo-documento-legajo";

export const maestrosComponentsPlanilla = [
  LugarTrabajoFormComponent,
  LugarTrabajoListPComponent,
  TipoDocumentoLegajoFormComponent,
  TipoDocumentoLegajoListComponent
]

export const maestrosModulosPlanilla = [
  ColaboradorModule,
  AFPModule,
  CargoModule,
  FirmaDigitalModule,
  ContratoModule
]
