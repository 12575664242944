<mat-card class="mat-elevation-z0">
  <mat-card-content>
    <div class="row">
      <div class="col s12 m3 l3">
        <mat-form-field>
          <mat-label>Importe Sise</mat-label>
          <input #iImporteSise min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
            placeholder="Importe Sise" appDecimalPlaces [disabled]="loaderData"
            [(ngModel)]="registroCompra.dpc_impsis" name="dpc_impsis" step="any" (keyup)="calculaTotal()">
        </mat-form-field>

      </div>
      <div class="col s12 m3 l3">
        <mat-form-field>
          <mat-label>Importe Fise</mat-label>
          <input #iImporteFise min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
            placeholder="Importe Fise" appDecimalPlaces [disabled]="loaderData"
            [(ngModel)]="registroCompra.dpc_impfis" name="dpc_impfis" step="any" (keyup)="calculaTotal()">
        </mat-form-field>

      </div>
    </div>
  </mat-card-content>
</mat-card>
