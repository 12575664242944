export class User {
    constructor(
        public cia_codcia: string,
        public eus_codeus: string,
        public eus_corele: string,
        public eus_nomeus: string,
        public eus_apeeus: string,
        public eus_paseus: string,
        public eus_ususis: string,
    ) {}
}