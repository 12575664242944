import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FacturaService, VentasService } from 'src/app/services';
import { TypeVoucherComponent } from '@shared/components/dialogs/type-voucher/type-voucher.component';
import { environment } from 'src/environments/environment';
import { generarPdfMonitoreo } from '@utils/pdfmaker/builder/ventas.pdfmaker';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { ApiVentasOperacionesFacturaService } from 'src/app/services/api/ventas/operaciones/ventas.operaciones.factura.service';
import { IParam } from 'src/app/services/api/utilities/params';
import { formatDateWithDash } from '@utils/formats/date.format';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { DialogService } from '@shared/services/dialog.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';

export class FiltroMonitoreoFactura {
  desde: string;
  hasta: string;
  periodo: string;
  tipodocumento: string;
  cliente: string;
  nrodocumento: string;
  tienda: string;
}
@Component({
  selector: 'app-monitoreo-factura',
  templateUrl: './monitoreo-factura.component.html',
  styleUrls: ['./monitoreo-factura.component.css']
})
export class MonitoreoFacturaComponent implements OnInit, OnDestroy {

  loaderData: boolean = false;
  loaderMonitoreo: boolean = false;
  loaderDataDetalle: boolean = false;

  displayedColumns: string[] = [ 'select', 'acciones', 'cco_codcco', 'cco_fecemi', 'tdo_codtdo', 'cco_numdoc', 'TMO_CODTMO', 'cco_impdoc', 'cli_numruc', 'cli_nomcli', 'rfe_estado', 'rfe_descri', 'cco_code', 'cco_condic'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsArchivo: string[] = ['rfe_estado', 'rfe_descri', 'rfe_coduse', 'rfe_fecupd', 'rfe_nomarc'];
  dataSourceArchivo: MatTableDataSource<any>;

  @ViewChild('paginatorArchivo') paginatorArchivo: MatPaginator;
  @ViewChild(MatSort) sortArchivo: MatSort;

  selection = new SelectionModel<any>(true, []);

  fecha_ini: any = null;
  fecha_fin: any = null;

  year: string;
  month: string;

  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
  });

  environment = environment;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  comprobante$: Subscription;
  tiendas$: Subscription;
  tiposDocumento$: Subscription;
  clientes$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;
  monitoreo$: Subscription;
  monitoreoDetalle$: Subscription;

  tiendas: Array<any> = [];
  tiposDocumento: Array<any> = [];
  clientes: Array<any> = [];

  enableRange: boolean = true;

  filtro: FiltroMonitoreoFactura;

  loaderReg: boolean;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _ventasService: VentasService,
    private _apiFacturaVentasService: ApiVentasOperacionesFacturaService,
    private _snackBarService: SnackBarService,
    private _facturaService: FacturaService,
    private _clienteService: ClienteService,
    private _dialogService: DialogService,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver,
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceArchivo = fillTable([], this.paginatorArchivo, this.sortArchivo);

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month
    })

    this.filtro = new FiltroMonitoreoFactura();

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });
  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.establecerFechaDefecto();
    });

  }

  establecerFechaDefecto(): void {
    let hoy = new Date()
    this.fecha_ini = new Date(`${hoy.getFullYear()}/${hoy.getMonth() + 1}/01`);
    this.fecha_fin = new Date();

    this.loadData();
  }



  loadData(): void {
    this.loaderData = true;

    this.tiendas$ = this._ventasService.listarTiendas().subscribe(
      tiendas => {
        this.tiendas = tiendas;
        this.tiposDocumento$ = this._ventasService.listarTipoDocumentosVenta().subscribe(
          tiposDocumento => {
            this.tiposDocumento = tiposDocumento;

            this.clientes$ = this._clienteService.obtenerClientes().subscribe(
              clientes => {
                this.clientes = clientes;
                this.loaderData = false;
              },
              error => {
                this._snackBarService.showError('Error al obtener clientes', 'Ok');
                this.loaderData = false;
              }
            )
            /*this.monitoreo$ = this._apiFacturaVentasService.obtenerMonitoreoFactel(`${this.year}${this.month}`).subscribe(
              response => {

                this.dataSource = fillTable(response, this.paginator, this.sort);

              },
              error => {
                this._snackBarService.showError('Error al obtener data', 'Ok');
                this.loaderData = false;
              }
            )*/
          },
          error => {
            this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener tiendas', 'Ok');
        this.loaderData = false;
      }
    )

  }

  seleccionarFila(row): void {

    this.loaderDataDetalle = true;
    this.dataSourceArchivo = fillTable([], this.paginatorArchivo, this.sortArchivo);
    console.log('row', row);

    this.monitoreoDetalle$ = this._apiFacturaVentasService.obtenerDetalleMonitoreoFactel(row.cco_codcco).subscribe(
      responseDetalle => {
        console.log('response detalle', responseDetalle)
        this.dataSourceArchivo = fillTable(responseDetalle, this.paginatorArchivo, this.sortArchivo);
        this.loaderDataDetalle = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener data de monitoreo', 'Ok');
        this.loaderDataDetalle = false;
      }
    )

  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  buscarComprobante(): void {
    this.loaderMonitoreo = true;

    if(!this.range.value.start) {
      this._snackBarService.showError('Debe seleccionar una fecha de inicio', 'Ok')
      this.loaderMonitoreo = false;
      return;
    }

    if(!this.range.value.end) {
      this._snackBarService.showError('Debe seleccionar una fecha de término', 'Ok')
      this.loaderMonitoreo = false;
      return;
    }

    const params: Array<IParam> = [
      {
        param: 'indicadorafecto',
        value: 'false'
      },
      {
        param: 'periodo',
        value: this.year + this.month
      },
      {
        param: 'desde',
        value: formatDateWithDash(this.range.value.start)
      },
      {
        param: 'hasta',
        value: formatDateWithDash(this.range.value.end)
      },
    ]

    if(this.filtro.cliente) {
      params.push({
        param: 'buscar',
        value: this.filtro.cliente
      })
    }

    if(this.filtro.tienda) {
      params.push({
        param: 'tienda',
        value: this.filtro.tienda
      })
    }

    if(this.filtro.tipodocumento) {
      params.push({
        param: 'tipodocumento',
        value: this.filtro.tipodocumento
      })
    }

    if(this.filtro.nrodocumento) {
      params.push({
        param: 'numerodocumento',
        value: this.filtro.nrodocumento
      })
    }

    this.comprobante$ = this._facturaService.obtenerMonitoreoFactura({ params }).subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderMonitoreo = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderMonitoreo = false;
      }

    )
    // this.comprobante$ = this._apiFacturaVentasService.obtenerMonitoreoFactel(`${this.year}${this.month}`).subscribe(
    //   response => {
    //     this.dataSource = fillTable(response, this.paginator, this.sort);

    //     this.dataSourceArchivo = fillTable([], this.paginatorArchivo, this.sortArchivo);

    //   },
    // )

  }

  seleccionarTipo(): void {
    const dialogRef = this.dialog.open(TypeVoucherComponent, {
      width: '400px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => { })
  }

  obtenerPdf(): void {
    generarPdfMonitoreo({}, {});
  }

  consultarGetStatusCDR(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de consultar?', '', '', '').subscribe(result => {
      if(result) {

      }
    })
  }

  actualizaMotivoBajaSUNAT(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de actualizar?', '', '', '').subscribe(result => {
      if(result) {

      }
    })
  }

  obtenerHora(): string {
    let date = new Date()
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    return `${hour}:${minute}:${seconds}`;
  }

  formatDate(date): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.comprobante$,
      this.tiendas$,
      this.tiposDocumento$,
      this.loading$,
      this.periodo$,
      this.monitoreo$,
      this.monitoreoDetalle$
    ])
  }

}
