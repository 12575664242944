import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { AutorizadoAprobarCompras } from 'src/app/models/compras/autorizado-aprobar-compras';
import { AuthenticationService, ComprasService } from 'src/app/services';

@Component({
  selector: 'app-auth-aprobar-compra-create',
  templateUrl: './auth-aprobar-compra-create.component.html',
  styleUrls: ['./auth-aprobar-compra-create.component.css']
})
export class AuthAprobarCompraCreateComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  autorizado: AutorizadoAprobarCompras;

  monedas: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  loadUpload: boolean = false;

  files: File[] = [];
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
    private _comprasService: ComprasService,
    private _activatedRoute: ActivatedRoute
    ) {

    this.autorizado = new AutorizadoAprobarCompras();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE: this.btnName = this.buttonsName.BTN_UPDATE;

      if(this.uid == '0') return;

      this.obtenerAutorizadoAprobarCompras();
    })
  }

  obtenerAutorizadoAprobarCompras(): void {

  }

  onSelect(event): void {
    this.loadUpload = true
    this.files.push(...event.addedFiles);

    this.files.forEach(element => {

      let reader = new FileReader;

      // let urlImageTemp = reader.readAsDataURL(element);

      reader.onloadend = () => {

      };

    })

    setTimeout(() => {
      this.loadUpload = false;

    }, 3000);

  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarAutorizado(f): this.actualizarAutorizado(f);
  }

  registrarAutorizado(f: NgForm): void {
    this.loaderReg = true;

    this._comprasService.registrarAutorizadoAprobarCompra(this.autorizado).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Autorizado a aprobar de compras registrado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  actualizarAutorizado(f: NgForm): void {
    this.loaderReg = true;

    this._comprasService.actualizarAutorizadoAprobarCompra(this.autorizado).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Autorizado a aprobar de compras actualizado', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-compras/autorizados-de-aprobar-compras']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }

}
