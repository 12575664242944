import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'log-updated',
  templateUrl: './log-updated.component.html',
  styleUrls: ['./log-updated.component.scss']
})
export class LogUpdatedComponent implements OnInit {

  @Input() coduse: string;
  @Input() fecupd: Date;

  constructor() {}

  ngOnInit(): void {

  }
}
