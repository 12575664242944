<div class="pav-settings">
  <div class="row">
    <div class="col s12">
      <h1 [matMenuTriggerFor]="menu" [ngClass]="{ 'animate__animated animate__bounce animate__infinite': !clientId }" class="pav-select-client">Seleccionar cliente</h1>
    </div>

    <mat-menu #menu="matMenu">
      <div style="height: 100px; width: 100%; background-color: red;"></div>
      <div class="pav-triangle"></div>
    </mat-menu>

    <div class="col s12">
      <select-search
        [label]="'general.select.client' | translate"
        [placeholder]="'Ej. TORRES'"
        [name]="'cli_codcli'"
        [value]="'cli_codcli'"
        [clase]="'select-ligth'"
        [description]="'cli_nomcli'"
        [data]="clients"
        (cambioSelect)="clientId = $event; seleccionarCliente($event)"
        [model]="clientId">
      </select-search>

    </div>
    <div class="col s12" *ngIf="urls && urls.length > 1">
      <mat-form-field>
        <mat-label>Seleccionar url</mat-label>
        <mat-select disabled [(ngModel)]="urlId" name="urlId" (selectionChange)="seleccionarUrl($event.value)">
          <mat-option *ngFor="let url of urls" [value]="url.csa_corcsa">
            {{ url.csa_descri }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col s12" *ngIf="companiesClient && companiesClient.length > 1">
      <mat-form-field >
        <mat-label>Empresa</mat-label>
        <mat-select [(ngModel)]="companyClient" name="companyClient" (selectionChange)="
            seleccionarCompaniaCliente($event.value)
          ">
          <mat-option *ngFor="let company of companiesClient" [value]="company.CIA_CODCIA">
            {{ company.cia_nomcia }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
