import { ComponentType } from "@angular/cdk/portal";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EntidadKey } from "@data/constants/component/entity-key.constant";
import { ProductoDialog } from "../producto-dialog/producto-dialog.component";

@Component({
  selector: "producto-select",
  template: `
    <select-search-crud
        [ngClass]="setColorSelected? 'selected-option': ''"
        [id]="id"
        [dialogComponent]="dialogComponent"
        [disabled]="disabled"
        [required]="required"
        [placeholder]="placeholder"
        [clase]="clase"
        [label]="label"
        [value]="value"
        [model]="model"
        [name]="name"
        [description]="description"
        [multiple]="multiple"
        [iconAction]="iconAction"
        [tooltipAction]="tooltipAction"
        [data]="data"
        (cambioSelect)="cambioSelect.emit($event)"
        (clickAction)="clickAction.emit($event)"
        >
    </select-search-crud>


  `,
  styleUrls: ['./producto-select.component.scss']
})
export class ProductoSelect {
  id: string = EntidadKey.producto_key;
  dialogComponent: ComponentType<any> = ProductoDialog;

  @Input() disabled: boolean;
  @Input() required: boolean = false;
  @Input() placeholder: string;
  @Input() clase: string;
  @Input() label: string;
  @Input() value: any;
  @Input() model: any;
  @Input() name: string = "";
  @Input() description: string = "";
  @Input() multiple: boolean = false;
  @Input() iconAction: string;
  @Input() tooltipAction: string;
  @Input() setColorSelected: boolean;
  @Output() cambioSelect: EventEmitter<any>;
  @Output() clickAction: EventEmitter<any>;

  @Input() data: any[] = [];

  constructor() {

    this.cambioSelect = new EventEmitter<string>();
    this.clickAction = new EventEmitter<any>();

  }
}
