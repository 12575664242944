import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { mergeMap, tap, map, catchError } from "rxjs/operators";
import { cargarProductos, cargarProductosError, cargarProductosSuccess } from "../actions";
import { ProductoService } from "src/app/services/api/ventas/maestros/producto.service";

@Injectable()
export class ProductEffects {

  constructor(
    private actions$: Actions,
    private _productoService: ProductoService
  ){}

  cargarProductos$ = createEffect(
    () => this.actions$.pipe(
      ofType(cargarProductos),
      tap(data => console.log(data)),
      mergeMap(
        () => this._productoService.obtenerProductos()
          .pipe(
            map(products => cargarProductosSuccess({products: products})),
            catchError(err => of(cargarProductosError({payload: err})))
          )
      )
    )
  )
}
