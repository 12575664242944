export class LugarDespacho {
  CIA_CODCIA: string;
  LDC_CODLDC: string;
  LDC_NOMLDC: string;
  LDC_DIRLDC: string;
  PAI_CODPAI: string;
  UBI_CODUBI: string;
  LDC_INDSTA: string;
  LDC_CODUSE: string;
  LDC_FECUPD: Date;

  constructor() {
    this.LDC_INDSTA = '1';
    this.LDC_FECUPD = new Date();
  }
}
