<div class="">
  <div>
    <p>Realizar doble clic para seleccionar la fila</p>
  </div>
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" placeholder="Buscar" #input>
  </div>
</div>

<div class="mat-elevation-z0">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <div class="overflow-x">
    <table mat-table [dataSource]="dataSource" matSort matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" class="td-responsive" [style.color]="row.color" data-label="Acciones">

          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-compras/orden-de-compra', row.ocm_numocm]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="ocm_numocm">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. O/C </th>
        <td mat-cell *matCellDef="let row" data-label="Nro. O/C" class="td-responsive"> {{row.ocm_numocm}} </td>
      </ng-container>

      <ng-container matColumnDef="toc_codtoc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo O/C </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo O/C" class="td-responsive"> {{row.toc_codtoc}} </td>
      </ng-container>

      <ng-container matColumnDef="ocm_fecocm">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha" class="td-responsive"> {{row.ocm_fecocm | date: 'dd/MM/yyyy': 'UTC'}} </td>
      </ng-container>

      <ng-container matColumnDef="ldc_dirldc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
        <td mat-cell *matCellDef="let row" data-label="Dirección" class="td-responsive"> {{row.ldc_dirldc}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="seleccionarFila(row)"></tr>

    </table>

  </div>

  <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>
