export const REPORTES_ALMACEN_UNIDADES = [
  {
    id: "ALMACEN_MM",
    name: "Movimientos por motivo",
    s26_grupo: "16002",
    esDinamico: false,
  },
  {
    id: "ALMACEN_KM",
    name: "Kardex Mensual",
    s26_grupo: "16002",
    esDinamico: false,
  },
  {
    id: "ALMACEN_KA",
    name: "Kardex Anual",
    s26_grupo: "16002",
    esDinamico: false,
  },
  {
    id: "ALMACEN_SP",
    name: "Saldos por producto",
    s26_grupo: "16001",
    esDinamico: false,
  },
  {
    id: "ALMACEN_SL",
    name: "Saldos por Lote",
    s26_grupo: "16005",
    esDinamico: false,
  },
  {
    id: "ALMACEN_KL",
    name: "Kardex por Lote",
    s26_grupo: "16006",
    esDinamico: false,
  },
  {
    id: "ALMACEN_TPL",
    name: "Trazabilidad por Lote",
    s26_grupo: "16006",
    esDinamico: false,
  },
  // {
  //   id: "ALMACEN_RPC",
  //   name: "Resumen de Productos por Clasificación",
  //   s26_grupo: "16002",
  //   esDinamico: false,
  // },

];

export const REPORTES_ALMACEN_VALORIZADOS = [
  {
    id: "ALMACEN_MMV",
    name: "Movimientos por motivo valorizado",
    s26_grupo: "16001",
    esDinamico: false,
  },
  {
    id: "ALMACEN_SPV",
    name: "Saldos por producto valorizado",
    s26_grupo: "16001",
    esDinamico: false,
  },
  {
    id: "ALMACEN_KMV",
    name: "Kardex Mensual Valorizado",
    s26_grupo: "16002",
    esDinamico: false,
  },
  {
    id: "ALMACEN_RA",
    name: "Resumen anual por tipo de inventario",
    s26_grupo: "16004",
    esDinamico: false,
  },
]
export const REPORTES_ALMACEN_DESPACHO = []

export const REPORTES_ALMACEN_DINAMICOS = [
  {
    id: "ALMACEN_KLA",
    name: "Movimientos por lote almacén",
    s26_grupo: "16006",
    codigo: '004',
    esDinamico: true,
  },
  {
    id: "ALMACEN_KUA",
    name: "Movimientos de unidades almacén",
    s26_grupo: "16002",
    codigo: '002',
    esDinamico: true,
  },
  {
    id: "ALMACEN_KSA",
    name: "Kardex por serie almacén",
    s26_grupo: "16008",
    codigo: "006",
    esDinamico: true,
  },
  {
    id: "ALMACEN_SUA",
    name: "Saldos por unidades almacén",
    s26_grupo: "16001",
    codigo: "001",
    esDinamico: true,
  },
  {
    id: "ALMACEN_SLA",
    name: "Saldos por lote almacén",
    s26_grupo: "16005",
    codigo: "003",
    esDinamico: true,
  },
  {
    id: "ALMACEN_SSA",
    name: "Saldos por serie almacén",
    s26_grupo: "16007",
    codigo: "005",
    esDinamico: true,
  },
]

export const REPORTES_DINAMICOS_GUIA = [
  {
    id: "ALMACEN_DLG",
    name: "Listado de Guías",
    s26_grupo: "16009",
    codigo: '007',
    esDinamico: true,
  },
]
