import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { estanTodosPresentes, tieneDuplicados } from '@utils/array/array';
import { formatDateWithDash } from '@utils/formats/date.format';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { VentasService } from 'src/app/services';
import { TipoGastoService } from 'src/app/services/api/contabilidad/maestros/tipo-operacion.service';

@Component({
  selector: 'migracion-registro-ventas',
  templateUrl: './migracion-registro-ventas.component.html',
  styleUrls: ['./migracion-registro-ventas.component.css']
})

export class MigracionRegistroVentasComponent implements OnInit {

  loaderData: boolean = false;
  tablaSeleccionada: boolean = false;

  displayedColumns: string[] = [
    'acciones',
    'ano_codano',
    'mes_codmes',
    'sco_codsco',
    'ldc_corldc',
    'cco_fecreg',
    'tga_codtga',
    'cli_codcli',
    'tdo_codtdo',
    'sad_codsad',
    'cco_numdoc',
    'tmo_codtmo',
    'cco_tasigv',
    'cco_glocco',
    'ccn_codccn',
    'cco_fecemi',
    'tdo_docref',
    'cco_numref',
    'cco_fecref',
    'cco_tipcam',
    'cco_fecven',
    'exportacion',
    'cco_impina',
    'exonerado',
    'cco_impafe',
    'ajuste_igv',
    'cco_impigv',
    'valvta',
    'valvta_retencion',
    'cco_impdoc',

  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isTablet: boolean = false;
  isMobile: boolean = false;

  tiposOperacion: any[] = [];

  tipoOperacion$: Subscription;
  loading$: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _snackBarService: SnackBarService,
    private _tipoGastoService: TipoGastoService,
    private _ventasService: VentasService,
    private store: Store<PavsoState>

  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

    this.dataSource = fillTable([], this.paginator, this.sort)
  }

  ngOnInit() {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderData = true;
    this.tipoOperacion$ = this._tipoGastoService.obtenerTiposGasto().subscribe(
      tiposOperacion => {
        console.log('tiposOperacion', tiposOperacion)
        this.tiposOperacion = tiposOperacion;
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error, 'Ok');
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  seleccionarTabla(): void {

    this.tablaSeleccionada = !this.tablaSeleccionada;

  }

  onPaste(event: ClipboardEvent): void {
    console.log('on paste')
    if (!this.tablaSeleccionada) return;
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.processPastedData(pastedText);

  }

  processPastedData(pastedText: string): void {

    const rows = pastedText.split('\n').filter(row => row.trim().length > 0);
    const newData: any[] = rows.map(row => {
      const cols = row.split('\t');
      return {
        ano_codano: cols[0],
        mes_codmes: cols[1],
        sco_codsco: cols[2],
        ldc_corldc: cols[3],
        cco_fecreg: cols[4],
        tga_codtga: cols[5],
        cli_codcli: cols[6],
        tdo_codtdo: cols[7],
        sad_codsad: cols[8],
        cco_numdoc: cols[9],
        tmo_codtmo: cols[10],
        cco_tasigv: cols[11],
        cco_glocco: cols[12],
        ccn_codccn: cols[13],
        cco_fecemi: cols[14],
        tdo_docref: cols[15],
        cco_numref: cols[16],
        cco_fecref: cols[17],
        cco_tipcam: cols[18],
        cco_fecven: cols[19],
        exportacion: cols[20],
        cco_impina: cols[21],
        exonerado: cols[22],
        cco_impafe: cols[23],
        ajuste_igv: cols[24],
        cco_impigv: cols[25],
        valvta: cols[26],
        valvta_retencion: cols[27],
        cco_impdoc: cols[28],
      };
    });

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    };

    if ((Object.keys(newData[0]).length) != 29) {
      this._snackBarService.showError('Debe de copiar todo el contenido de las 29 filas', 'Ok');
      return
    };

    // Verificar si existen correlativos duplicados
    const correlativos = newData.map(item => item.ldc_corldc);

    if(tieneDuplicados(correlativos)) {
      this._snackBarService.showError('No puede haber correlativos duplicados', 'OK');
      return;
    }

    // Verificar si los codigos de tipos de operacion existan
    const tiposOperacionesData = newData.map(item => item.tga_codtga);
    const tiposOperacionesTotales = this.tiposOperacion.map(item => item.tga_codtga);
    console.log('tipos de operacion data', tiposOperacionesData)
    if (!estanTodosPresentes(tiposOperacionesTotales, tiposOperacionesData)) {
      this._snackBarService.showError('No existe un tipo de operación', 'OK');
      return;
    }

    // Verificar si es obligatorio el año DUA
    let esValidoAnoDua = true;
    newData.forEach(item => {
      if (item.tdo_codtdo.trim() == 'DUA') {
        if(!item.dpc_anodua) {
          esValidoAnoDua = false;
        }
      };
    });

    if(!esValidoAnoDua) {
      this._snackBarService.showError('Año dua obligatorio', 'OK');
      return
    };

    // Verificar si es valido los montos
    let esValidoMontos = true;
    newData.forEach(item => {
      console.log('item.cco_impigv', item.cco_impigv)
      console.log('item.valvta', item.valvta)
      console.log('suma', (parseFloat(item.cco_impigv) + parseFloat(item.valvta)).toFixed(2))
      console.log('item.cco_impdoc', item.cco_impdoc)
      console.log('ddddsss', (parseFloat(item.cco_impigv) + parseFloat(item.valvta)).toFixed(2) != parseFloat(item.cco_impdoc).toFixed(2))
      if ((parseFloat(item.cco_impina) + parseFloat(item.cco_impafe)).toFixed(2) != parseFloat(item.valvta).toFixed(2)) {
        esValidoMontos = false;
      }

      if ((parseFloat(item.cco_impigv) + parseFloat(item.valvta)).toFixed(2) != parseFloat(item.cco_impdoc).toFixed(2)) {
        esValidoMontos = false;
      }
    });

    if(!esValidoMontos) {
      this._snackBarService.showError('La suma de montos no coincide con el total', 'OK');
      return
    };

    // Validar campos referencia
    // Verificar si es valido los montos
    let esValidoCamposReferencia = true;
    newData.forEach(item => {
      if(item.tdo_codtdo == 'NCR') {
        if (!item.tdo_docref) {
          esValidoCamposReferencia = false;
        }
        if (!item.cco_numref) {
          esValidoCamposReferencia = false;
        }
        if (!item.cco_fecref) {
          esValidoCamposReferencia = false;
        }
      }
    });

    if(!esValidoMontos) {
      this._snackBarService.showError('La suma de montos no coincide con el total', 'OK');
      return
    };

    // autocompletar 8 digitos al numero de documento
    newData.forEach(item => {
      item.cco_numdoc = item.cco_numdoc.padStart(8, '0');
    });

    console.log('newData', newData)
    this.dataSource = fillTable(newData, this.paginator, this.sort);

    this.tablaSeleccionada = false;
  }

  async obtenerTipoCambio() {

    console.log('inicio')

    const registros = this.dataSource.data;

    if(registros.length == 0) {
      this._snackBarService.showError('No hay registros para actualizar', 'OK');
      return;
    }

    console.log('nro registros', registros)

    let data = [];

    for (const element of registros) {
      try {
        let tipoCambio;

        if (element.tdo_codtdo == 'NCR') {
          tipoCambio = await this._ventasService.obtenerTipoCambio(formatDateWithDash(element.cco_fecref)).toPromise();
        } else {
          const fecha = element.cco_fecemi.split('/');
          const fechaEmision = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;
          tipoCambio = await this._ventasService.obtenerTipoCambio(fechaEmision).toPromise();
        }

        element.cco_tipcam = tipoCambio[0].tipcam;
        data.push(element);

        console.log('consulta', element);  // Imprime el elemento actual

      } catch (error) {
        console.log(error)
      }
    }

    this.dataSource = fillTable(data, this.paginator, this.sort);

  }


  limpiar(): void {
    this.dataSource = fillTable([], this.paginator, this.sort);
  }
}
