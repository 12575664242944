<app-pavso-title-section [title]="'Vendedores'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
    </div>
  </div>


    <div class="mat-elevation-z0">
      <table-title-action title="Todos los vendedores" url="/modulo-ventas/vendedor" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">

        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                  <div class="pav-btns-container">
                    <a matTooltip="Editar vendedor"
                      class="pav-btn-circle-edit"
                      [routerLink]="['/modulo-ventas/vendedor', row.VDE_CODVDE]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="VDE_CODVDE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.VDE_CODVDE}} </td>
            </ng-container>

            <ng-container matColumnDef="VDE_NOMVDE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
                <td mat-cell *matCellDef="let row" data-label="Vendedor"> {{row.VDE_NOMVDE}} </td>
            </ng-container>

            <ng-container matColumnDef="VDE_TELVDE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.VDE_TELVDE}} </td>
            </ng-container>

            <ng-container matColumnDef="VDE_CORELE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mail </th>
                <td mat-cell *matCellDef="let row" data-label="Mail"> {{row.VDE_CORELE}} </td>
            </ng-container>

            <ng-container matColumnDef="S10_USUARIO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
                <td mat-cell *matCellDef="let row" data-label="Usuario"> {{row.S10_USUARIO}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

      </div>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
