<app-pavso-title-section [title]="'Centro de costos'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todos los centros" url="/modulo-contabilidad/centro-de-costo" entity="" [params]="['0']"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort >
              <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-contabilidad/centro-de-costo', row.ccs_codccs]">
                          <mat-icon svgIcon="edit-white"></mat-icon>
                        </div>
                      </div>
                  </td>
              </ng-container>

              <ng-container matColumnDef="ccs_codccs">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                  <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.ccs_codccs}} </td>
              </ng-container>

              <ng-container matColumnDef="ccs_descri">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                  <td mat-cell *matCellDef="let row" data-label="Descripción" class="td-responsive"> {{row.ccs_descri}} </td>
              </ng-container>

              <ng-container matColumnDef="ccs_indsta">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                  <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive"> {{(row.ccs_indsta == "1")? "VIGENTE": "NO VIGENTE"}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

              </tr>

          </table>

      </div>


        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
