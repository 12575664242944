<app-pavso-title-section [title]="'Canal de venta'" [module]="'Ventas'" [usuario]="" [fecha]="" [estado]="canal.vds_indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="canal.vds_codvds" matInput placeholder="Código" name="vds_codvds" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="canal.vds_descri" matInput placeholder="Descripción" name="vds_descri" required>
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <br>
    <pav-form-actions
      [disabled]="loaderData"
      [btnName]="btnName"
      [isLoading]="loaderReg"
      urlBack="/modulo-ventas/canales-de-venta"
    >
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>
  </form>
</div>
