export class MaterialRequirement {
  cia_codcia: any;
  rma_numrma: any;
  rma_fecpro: any;
  cli_codcli: string;
  cli_nomcli: any;
  rma_obsrma: any;
  edu_codedu: any;
  rma_indsta: string;
  rma_indord: any;
  rma_coduse: string;
  rma_fecupd: any;
  ccs_codccs: any;
  oin_numoin: any;
  rma_aprind: any;
  rma_apruse: any;
  rma_aprfec: any;
  rma_tecind: any;
  rma_tecuse: any;
  rma_tecfec: any;
  rma_indtrs: any;
  prd_codprd: any;
  rma_glorma: any;
  rma_canprd: any;
  rma_nomarc: any;
  rma_imadoc: any;
  ano_codano: any;
  mes_codmes: any;
  rma_indnue: any;
  detalle_materiales: Array<DetalleMateriales>;
  constructor(
  ){
    this.detalle_materiales = [];
  }
}

export class DetalleMateriales {
  prd_codprd: any;
  rmd_corrmd: any;
  rmd_canpro: any;
  rmd_canate: any;
  rmd_obsrmd: any;
  rmd_fecupd: any;
  rmd_coduse: string;
  rmd_descri: any;
  rmd_cancom: any;
  rmd_indcom: any;
  rmd_indapr: any;
  rmd_parnum: any;
  rmd_partec: any;
  rmd_glosas: any;
  rmd_observ: any;
  detalle_ordenes: any;
  detalle_servicio: any;
  constructor(
  ){}
}
