import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
  ) { }

  convertirFechaALocal(fecha: Date): string {
    const fechaLocal = moment(fecha);
    return fechaLocal.format('YYYY-MM-DDTHH:mm:ss.SSS');
  }

}
