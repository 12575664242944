import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { AlmacenService } from "src/app/services";

@Component({
  selector: 'ubicacion-list',
  templateUrl: './ubicacion-list.component.html',
  styleUrls: ['./ubicacion-list.component.scss']
})
export class UbicacionListComponent implements OnInit {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'MPR_CODMPR', 'MPR_DESCRI', 'MPR_INDSTA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  marcas$: Subscription;
  loading$: Subscription;
  ubicaciones$: Subscription;

  constructor (
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private store: Store<PavsoState>
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      // if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerUbicaciones();
    })
  }

  obtenerUbicaciones(): void {
    this.loaderData = true;
    this.ubicaciones$ = this._almacenService.obtenerUbicacionesAlmacen().subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort)
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.marcas$, this.loading$, this.ubicaciones$]);
  }
}
