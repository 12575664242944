<mat-card class="pav-card-dashboard mat-elevation-z0">
  <mat-card-header [style.background]="color">
    <mat-card-title>
      <!-- <mat-icon>bookmark</mat-icon> -->
      # {{title}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    {{amount}}
  </mat-card-content>
</mat-card>
