<app-pavso-title-section [title]="'Unidad de transporte'" [module]="'Ventas'" [ngClass]="'pav-form'"
  [estado]="transportUnit.UTR_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input [(ngModel)]="transportUnit.UTR_CODUTR" type="text" matInput placeholder="Código" name="UTR_CODUTR"
              required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input [(ngModel)]="transportUnit.UTR_DESCRI" type="text" matInput placeholder="Descripción"
              name="UTR_DESCRI">
          </mat-form-field>
        </div>

      </div>

      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Placa</mat-label>
            <input [(ngModel)]="transportUnit.UTR_PLAUTR" maxlength="7" minlength="7" type="text" matInput placeholder="Placa" name="UTR_PLAUTR">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cod. Anterior</mat-label>
            <input [(ngModel)]="transportUnit.utr_codant" maxlength="7" type="text" matInput placeholder="Cod. Anterior"
              name="utr_codant">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nro. Constancia</mat-label>
            <input [(ngModel)]="transportUnit.UTR_NROCER" type="text" matInput placeholder="Nro. Constancia"
              name="UTR_NROCER">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Marca</mat-label>
            <input [(ngModel)]="transportUnit.UTR_MARUTR" type="text" matInput placeholder="Marca" name="UTR_MARUTR">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Chofer (Sugerido)</mat-label>
            <mat-select [(ngModel)]="transportUnit.CHO_CODCHO" required name="CHO_CODCHO">
              <mat-option *ngFor="let chofer of choferes" [value]="chofer.CHO_CODCHO">
                {{chofer.CHO_NOMBRE}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Transportista</mat-label>
            <mat-select [(ngModel)]="transportUnit.CLI_CODCLI" required name="CLI_CODCLI">
              <mat-option *ngFor="let transportista of transportistas" [value]="transportista.cli_codcli">
                {{transportista.cli_nomcli}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Registro MTC</mat-label>
            <input type="text" matInput placeholder="Código" name="registro_mtc" />
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <select-search [label]="'Entidad autorizadora'" [placeholder]="'Ej. SANDRA'" [name]="'cli_codcli'"
            [description]="'cli_nomcli'" [data]="[]" [value]="'cli_codcli'">
          </select-search>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Nro. Autorización</mat-label>
            <input type="text" matInput placeholder="Código" name="authorizacion" />
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-almacen/unidades-de-transporte" (clickNuevo)="registroNuevo()">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
