<h1 mat-dialog-title>Seleccione requerimiento de pago</h1>
<div class="row">
    <div class="col s12 m12 l4">
      <select-search
        [label]="'Cliente'"
        [placeholder]="'Ej. TORRES'"
        [name]="'cli_codcli'"
        [value]="'cli_codcli'"
        [description]="'cli_nomcli'"
        [data]="clientes"
        (cambioSelect)="cliente = $event"
        [model]="cliente">
      </select-search>
        <!-- <mat-form-field>
            <mat-label>Cliente</mat-label>
            <mat-select>
                <mat-option value="cliente1">
                    Cliente 1
                </mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>
    <div class="col s12 m12 l4">
      <app-currency-select [moneda]="'SO'" [disable]="loaderData" (cambioMoneda)="$event"></app-currency-select>

    </div>
    <div class="col s12 m12 l4">
        <mat-form-field>
            <mat-label>Tipo Req.</mat-label>
            <mat-select>
                <mat-option value="moneda1">
                    TODOS
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div class="row">
    <div class="col s12 m12 l9">
        <mat-radio-group>
            <mat-radio-button value="1">Nro. Doc.</mat-radio-button>
            &nbsp;
            <mat-radio-button value="2">Vencimiento</mat-radio-button>
            &nbsp;
            <mat-radio-button value="3">Emisión</mat-radio-button>
            &nbsp;
            <mat-radio-button value="4">Tipodoc + Nrodoc</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
<div mat-dialog-content>
    <br>

    <div class="row">
        <div class="col s12">
          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">

            </div>
            <div class="pav-table-header-title">
              Documentos
            </div>
            <div class="pav-separator"></div>
            <div class="pav-table-search">
              <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonName.BTN_SEARCH" #input>
            </div>
          </div>
          <div class="overflow-x">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

                <ng-container matColumnDef="cta_cte">
                    <th mat-header-cell *matHeaderCellDef> Cta. Cte </th>
                    <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                </ng-container>

                <ng-container matColumnDef="td">
                    <th mat-header-cell *matHeaderCellDef> TD </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="nro_doc">
                    <th mat-header-cell *matHeaderCellDef> Nro. Docum. </th>
                    <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_emis">
                    <th mat-header-cell *matHeaderCellDef> Fec. Emis. </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_venc">
                    <th mat-header-cell *matHeaderCellDef> Fec. Venc. </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <ng-container matColumnDef="glosa">
                    <th mat-header-cell *matHeaderCellDef> Glosa </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <ng-container matColumnDef="mo">
                    <th mat-header-cell *matHeaderCellDef> MO </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <ng-container matColumnDef="importe_documento">
                    <th mat-header-cell *matHeaderCellDef> Importe Documento </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <ng-container matColumnDef="importe_abono">
                    <th mat-header-cell *matHeaderCellDef> Importe Abono </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <ng-container matColumnDef="saldo">
                    <th mat-header-cell *matHeaderCellDef> Saldo </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <ng-container matColumnDef="cuenta">
                    <th mat-header-cell *matHeaderCellDef> Cuenta </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <ng-container matColumnDef="auxiliar">
                    <th mat-header-cell *matHeaderCellDef> Auxiliar </th>
                    <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="17">No se encontraron registros</td>

                </tr>
            </table>
          </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col s12 m12 l6"></div>
        <div class="col s12 m12 l3">
            <mat-form-field>
                <mat-label>Importe SO</mat-label>
                <input matInput placeholder="Importe SO">
            </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
            <mat-form-field>
                <mat-label>Importe DO</mat-label>
                <input matInput placeholder="Importe DO">
            </mat-form-field>
        </div>
    </div>
    <br>
</div>
<div mat-dialog-actions align="right">
    <button mat-flat-button class="pav-button-cancel" (click)="onNoClick()">{{buttonName.BTN_BACK | translate}}</button>
    <button mat-flat-button class="pav-button-action" color="primary">Confirmar</button>
</div>
